import React, { ReactNode } from 'react';
import { globalStyles } from 'assets/styles/global-styles';

interface LargeFontProps {
    children?: ReactNode;
}

export default function LargeFont(props: LargeFontProps): JSX.Element {
    return <span className={globalStyles.largeFont}>{props.children}</span>;
}
