import React from 'react';
import { ActionButton, TooltipHost } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';

interface IApplyFiltersActionButtonProps {
    text?: string; // Default: "Apply filters"
    onClick: () => void;
}

export default function ApplyFiltersActionButton(
    props: IApplyFiltersActionButtonProps,
): JSX.Element {
    return (
        <TooltipHost content='Apply all current search and filter settings'>
            <ActionButton
                iconProps={{ iconName: IconNames.FilterSettings }}
                onClick={props.onClick}>
                <span>{props.text ?? 'Apply filters'}</span>
            </ActionButton>
        </TooltipHost>
    );
}
