import React, { useState, useEffect, useContext } from 'react';

import { AccessDeniedURL } from 'assets/constants/global-constants';
import { AuthContext } from 'contexts/auth-context';
import CheckRole from 'components/common/check-role';
import FacilitiesClient, {
    IFacilityRecord,
    FacilitiesManageCategory,
} from 'clients/facilities-client';
import { FacilityUserType, FacilitiesMenuNavigationText } from 'utils/facilities-utils';
import FacilitiesManagementMetricsPage from 'components/facilities/facilities-management/facilities-management-metrics-page';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import Tabs, { TabbedContent } from 'components/common/tabs';
import { useIsMounted } from 'utils/misc-hooks';
import { UserContext } from 'contexts/user-context';
import FacilitiesManagementSeatsPage from 'components/facilities/facilities-management/facilities-management-seats-page';
import { useHistory } from 'react-router-dom';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { CustomBreadcrumb, ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import FacilitiesAuditPage from 'components/facilities/facilities-audit-page';
import FacilitiesManagementLogbook from 'components/facilities/facilities-management/facilities-management-logbook-page';
import FacilitiesManagementUsers from 'components/facilities/facilities-management/facilities-management-blockedusers-page';
import FacilitiesManagementEquipmentPage from 'components/facilities/facilities-management/facilities-management-equipment-page';

export interface FacilitiesManagementPageProps {
    match: {
        params: {
            category?: string;
        };
    };
}

const equipmentBreadcrumbs: ICustomBreadcrumbItem[] = [
    {
        title: FacilitiesMenuNavigationText.Facilities,
        link: '',
    },
    {
        title: FacilitiesMenuNavigationText.Manage,
        link: '',
    },
    {
        title: FacilitiesMenuNavigationText.Equipment,
        link: '',
    },
];

const seatsBreadcrumbs: ICustomBreadcrumbItem[] = [
    {
        title: FacilitiesMenuNavigationText.Facilities,
        link: '',
    },
    {
        title: FacilitiesMenuNavigationText.Manage,
        link: '',
    },
    {
        title: FacilitiesMenuNavigationText.Seats,
        link: '',
    },
];

const auditBreadcrumbs: ICustomBreadcrumbItem[] = [
    {
        title: FacilitiesMenuNavigationText.Facilities,
        link: '',
    },
    {
        title: FacilitiesMenuNavigationText.Manage,
        link: '',
    },
    {
        title: FacilitiesMenuNavigationText.Audit,
        link: '',
    },
];

const logBookBreadCrumbs: ICustomBreadcrumbItem[] = [
    {
        title: FacilitiesMenuNavigationText.Facilities,
        link: '',
    },
    {
        title: FacilitiesMenuNavigationText.Manage,
        link: '',
    },
    {
        title: FacilitiesMenuNavigationText.LogBook,
        link: '',
    },
];

const blockedUsersBreadCrumbs: ICustomBreadcrumbItem[] = [
    {
        title: FacilitiesMenuNavigationText.Facilities,
        link: '',
    },
    {
        title: FacilitiesMenuNavigationText.Manage,
        link: '',
    },
    {
        title: FacilitiesMenuNavigationText.BlockedUsers,
        link: '',
    },
];

const metricsBreadCrumbs: ICustomBreadcrumbItem[] = [
    {
        title: FacilitiesMenuNavigationText.Facilities,
        link: '',
    },
    {
        title: FacilitiesMenuNavigationText.Manage,
        link: '',
    },
    {
        title: FacilitiesMenuNavigationText.Metrics,
        link: '',
    },
];

const category = {
    Equipment: {
        text: FacilitiesManageCategory.Equipment,
        index: '0',
    },
    Seats: {
        text: FacilitiesManageCategory.Seats,
        index: '1',
    },
    Audit: {
        text: FacilitiesManageCategory.Audit,
        index: '2',
    },
    LogBook: {
        text: FacilitiesManageCategory.LogBook,
        index: '3',
    },
    BlockedUsers: {
        text: FacilitiesManageCategory.BlockedUsers,
        index: '4',
    },
    Metrics: {
        text: FacilitiesManageCategory.Metrics,
        index: '5',
    },
};

const categories = [
    category.Equipment,
    category.Seats,
    category.Audit,
    category.LogBook,
    category.BlockedUsers,
    category.Metrics,
];

export default function FacilitiesManagementPage(
    props: FacilitiesManagementPageProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const breadCrumbContext = useContext(BreadCrumbContext);
    const history = useHistory();
    const [facilities, setFacilities] = useState<IFacilityRecord[]>([]);
    const [isFacilityAdminOrManager, setIsFacilityAdminOrManager] = useState<boolean>();
    const [isFacilityEquipmentOfficer, setIsFacilityEquipmentOfficer] = useState<boolean>();
    const [isAccessChecked, setIsAccessChecked] = useState<boolean>(false);
    const isMounted = useIsMounted();

    const getFacilities = async (): Promise<void> => {
        try {
            let newFacilities = facilities;
            if (userContext.hasFacilitiesUserType(FacilityUserType.EquipmentOfficerRole)) {
                newFacilities = await FacilitiesClient.getFacilityRecordsEquipmentOfficer(
                    authContext,
                    userContext,
                );
            } else if (userContext.hasFacilitiesUserType(FacilityUserType.AdminService)) {
                newFacilities = await FacilitiesClient.getFacilityRecords(authContext, userContext);
            } else if (userContext.hasFacilitiesUserType(FacilityUserType.ManagerRole)) {
                newFacilities = await FacilitiesClient.getFacilityRecordsManager(
                    authContext,
                    userContext,
                );
            }

            if (isMounted()) {
                setFacilities(newFacilities);
            }
        } catch (error) {
            console.error('Failed to retrieve facilities for manager', error);
        }
    };

    useEffect(() => {
        updateBreadcrumbs(props.match.params.category);
    }, []);

    useEffect(() => {
        updateBreadcrumbs(props.match.params.category);
    }, [props.match.params.category]);

    useEffect(() => {
        if (userContext.isFacilitiesTokenLoaded) {
            setIsFacilityAdminOrManager(
                userContext.hasFacilitiesUserType(FacilityUserType.AdminService) ||
                    userContext.hasFacilitiesUserType(FacilityUserType.ManagerRole),
            );
            setIsFacilityEquipmentOfficer(
                userContext.hasFacilitiesUserType(FacilityUserType.EquipmentOfficerRole),
            );
            setIsAccessChecked(true);
            getFacilities();
        }
    }, [userContext.isFacilitiesTokenLoaded]);

    function getDefaultTabKey(): string {
        switch (props.match.params.category) {
            case category.Seats.text:
                return category.Seats.index;
            case category.Equipment.text:
            default:
                return category.Equipment.index;
            case category.Audit.text:
                return category.Audit.index;
            case category.LogBook.text:
                return category.LogBook.index;
            case category.BlockedUsers.text:
                return category.BlockedUsers.index;
            case category.Metrics.text:
                return category.Metrics.index;
        }
    }

    function onTabChange(key?: string): void {
        if (!key) {
            return;
        }

        const selectedCategory = categories.find((x) => x.index === key);
        if (selectedCategory && !history.location.pathname.includes(selectedCategory.text)) {
            history.push(selectedCategory.text);
            updateBreadcrumbs(selectedCategory.text);
        }
    }

    function updateBreadcrumbs(categoryText?: string): void {
        if (!categoryText) {
            return;
        }

        const currentBreadCrumb = getCurrentBreadCrumb(categoryText);

        breadCrumbContext.setBreadCrumbs(currentBreadCrumb);
    }

    return (
        <PageLoadingSpinner
            label='Loading facility management...'
            ariaLive='assertive'
            isLoading={facilities === undefined}
            labelPosition='left'>
            <CheckRole
                requiredRolesAny={[]}
                arePermissionsChecked={isAccessChecked}
                hasRequiredRolesAny={[
                    isFacilityAdminOrManager ?? false,
                    isFacilityEquipmentOfficer ?? false,
                ]}
                redirectNotInRole={AccessDeniedURL}>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                <Tabs selectedKey={getDefaultTabKey()} onChange={onTabChange}>
                    {(isFacilityAdminOrManager || isFacilityEquipmentOfficer) && (
                        <TabbedContent tabHeader='Equipment'>
                            <FacilitiesManagementEquipmentPage />
                        </TabbedContent>
                    )}
                    {isFacilityAdminOrManager && (
                        <TabbedContent tabHeader='Seats'>
                            <FacilitiesManagementSeatsPage facilities={facilities} />
                        </TabbedContent>
                    )}
                    {(isFacilityAdminOrManager || isFacilityEquipmentOfficer) && (
                        <TabbedContent tabHeader='Audit'>
                            <FacilitiesAuditPage />
                        </TabbedContent>
                    )}
                    {isFacilityAdminOrManager && (
                        <TabbedContent tabHeader='Log Book'>
                            <FacilitiesManagementLogbook facilities={facilities} />
                        </TabbedContent>
                    )}
                    {isFacilityAdminOrManager && (
                        <TabbedContent tabHeader='Blocked Users'>
                            <FacilitiesManagementUsers facilities={facilities} />
                        </TabbedContent>
                    )}
                    {isFacilityAdminOrManager && (
                        <TabbedContent tabHeader='Metrics'>
                            <FacilitiesManagementMetricsPage facilities={facilities} />
                        </TabbedContent>
                    )}
                </Tabs>
            </CheckRole>
        </PageLoadingSpinner>
    );

    function getCurrentBreadCrumb(categoryText: string) {
        switch (categoryText) {
            case category.Equipment.text:
                return equipmentBreadcrumbs;
            case category.Seats.text:
                return seatsBreadcrumbs;
            case category.Audit.text:
                return auditBreadcrumbs;
            case category.LogBook.text:
                return logBookBreadCrumbs;
            case category.BlockedUsers.text:
                return blockedUsersBreadCrumbs;
            case category.Metrics.text:
                return metricsBreadCrumbs;
            default:
                return equipmentBreadcrumbs;
        }
    }
}
