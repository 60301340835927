// To disable the following error message:
//    Property name REQUESTED_STATE must match one of the following formats: camelCase, PascalCase
//    And the same error for other var names that are all caps.
/* eslint-disable @typescript-eslint/naming-convention */
export const AppConstants = {
    padding: '20px',
    margin: '20px',
    NST_ROLE_NAME: 'Screening_Officer',
    NomineeNotAccept: 'NomineeNotAccept',
    RemovedFromProcessing: 'RemovedFromProcessing',
    ContractOwnerDeny: 'ContractOwnerDeny',
    ContractOwnerAccept: 'ContractOwnerAccept',
    NomineeApprove: 'NomineeApprove',
    AdjudicationResultDenied: 'Denied',
    AdjudicationResultInterim: 'Interim',
    AdjudicationResultFinal: 'Final',
};

export enum ScreeningPageNames {
    MyScreenings = 'My screenings',
    MyContracts = 'My contracts',
    MyNominees = 'My nominees',
    MyTeam = 'My team',
    MyOrg = 'My org',
    Manage = 'Manage',
    ClearanceRecords = 'Clearance records',
    SuitabilityRecords = 'Suitability records',
    Contracts = 'Contracts',
    Reports = 'Reports',
}

export const AbortError = 'AbortError';

export const DefaultPageSize = 5000;
