import React, { ReactNode } from 'react';
import { TableCell } from 'components/common/table';
import { IColumn, Stack, ActionButton, TooltipHost } from '@fluentui/react';
import { lastColumnStylesButton, detailsListStyles } from 'assets/styles/list-styles';
import { maxWidthCoeff, IconNames } from 'assets/constants/global-constants';
import { IAttribute } from 'clients/eligibility-client';
import { timeToString, TimeFormats } from 'utils/time-utils';
import DeleteAttributeModalButton from 'components/eligibilities/attributes/delete-attribute-modal-button';
import AddAttributeModalButton from 'components/eligibilities/attributes/add-attribute-modal-button';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { ModalMode } from 'components/eligibilities/eligibilities-constants';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { isValidEmail, returnEmailUserName } from 'utils/string-utils';
import { ISortableColumns } from 'utils/sort-utils';

interface IAttributesColumns extends ISortableColumns {
    hasAttributesModify: boolean;
    hasAttributesDelete: boolean;
    onAddEditAttributeConcluded: (
        mode: ModalMode,
        modalConclusion: ModalConclusion,
        result?: IAttribute,
    ) => void;
    onDeletetAttributeConcluded: (attribute: IAttribute, modalConclusion: ModalConclusion) => void;
}

export function getAttributesTableColumns(params: IAttributesColumns): IColumn[] {
    const actionButtonFuncs: ((row: IAttribute) => ReactNode)[] = [];

    if (params.hasAttributesModify) {
        actionButtonFuncs.push(
            (row: IAttribute): ReactNode => (
                <AddAttributeModalButton
                    key={`edit-${row.attributeCode}`}
                    mode={ModalMode.Update}
                    attribute={row}
                    buttonText='Edit'
                    onAddEditAttributeConcluded={params.onAddEditAttributeConcluded}
                />
            ),
        );
    }
    if (params.hasAttributesDelete) {
        actionButtonFuncs.push(
            (row: IAttribute): ReactNode => (
                <DeleteAttributeModalButton
                    key={`delete-${row.attributeCode}`}
                    attribute={row}
                    onDeletetAttributeConcluded={params.onDeletetAttributeConcluded}
                />
            ),
        );
    }

    const actionsFunc = (row: IAttribute): ReactNode[] => {
        return actionButtonFuncs.map((actionButtonFunc) => actionButtonFunc(row));
    };

    const columnWidths = {
        code: 160,
        description: 160,
        createdAt: 110,
        createdBy: 100,
        lastModifiedAt: 110,
        lastModifiedBy: 120,
        id: 300,
        action: 140,
    };

    const columns: IColumn[] = [
        {
            key: 'Code',
            name: 'Code',
            ariaLabel: 'Code',
            minWidth: columnWidths.code,
            maxWidth: columnWidths.code * maxWidthCoeff,
            isSorted: params.sortColumn === 'Code',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Code');
            },
            onRender: (row: IAttribute): JSX.Element => (
                <TableCell>
                    <span>
                        <EllipsisTextCss text={row.attributeCode} />
                    </span>
                </TableCell>
            ),
        },
        {
            key: 'Description',
            name: 'Description',
            ariaLabel: 'Description',
            minWidth: columnWidths.description,
            maxWidth: columnWidths.description * maxWidthCoeff,
            isSorted: params.sortColumn === 'Description',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Description');
            },
            onRender: (row: IAttribute): JSX.Element => {
                return (
                    <TableCell>
                        <span>
                            <EllipsisTextCss text={row.attributeDesc} />
                        </span>
                    </TableCell>
                );
            },
        },
        {
            key: 'Created At',
            name: 'Created At',
            ariaLabel: 'Created At',
            minWidth: columnWidths.createdAt,
            maxWidth: columnWidths.createdAt * maxWidthCoeff,
            isSorted: params.sortColumn === 'Created At',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Created At');
            },
            onRender: (row: IAttribute): JSX.Element => (
                <TableCell>
                    <span>{timeToString(row.createdAt, TimeFormats.MMMDYYYY_hmmssA)}</span>
                </TableCell>
            ),
        },
        {
            key: 'Created By',
            name: 'Created By',
            ariaLabel: 'Created By',
            minWidth: columnWidths.createdBy,
            maxWidth: columnWidths.createdBy * maxWidthCoeff,
            onRender: (row: IAttribute): JSX.Element => {
                return (
                    <TableCell>
                        {isValidEmail(row.createdBy) ? (
                            <EmployeeBasicHoverCard
                                alias={returnEmailUserName(row.createdBy)}
                                showMiniCardAlias={false}
                            />
                        ) : (
                            <span>{row.createdBy}</span>
                        )}
                    </TableCell>
                );
            },
        },
        {
            key: 'Last Modified At',
            name: 'Last Modified At',
            ariaLabel: 'Last Modified At',
            minWidth: columnWidths.lastModifiedAt,
            maxWidth: columnWidths.lastModifiedAt * maxWidthCoeff,
            isSorted: params.sortColumn === 'Last Modified At',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Last Modified At');
            },
            onRender: (row: IAttribute): JSX.Element => {
                return (
                    <TableCell>
                        {timeToString(row.lastModifiedAt, TimeFormats.MMMDYYYY_hmmssA)}
                    </TableCell>
                );
            },
        },
        {
            key: 'Last Modified By',
            name: 'Last Modified By',
            ariaLabel: 'Last Modified By',
            minWidth: columnWidths.lastModifiedBy,
            maxWidth:
                actionButtonFuncs.length === 0
                    ? undefined
                    : columnWidths.lastModifiedBy * maxWidthCoeff,
            onRender: (row: IAttribute): JSX.Element => {
                return (
                    <TableCell>
                        <span>
                            {isValidEmail(row.lastModifiedBy) ? (
                                <EmployeeBasicHoverCard
                                    alias={returnEmailUserName(row.lastModifiedBy)}
                                    showMiniCardAlias={false}
                                />
                            ) : (
                                <span>{row.lastModifiedBy}</span>
                            )}
                        </span>
                    </TableCell>
                );
            },
        },
        {
            key: 'Id',
            name: 'Id',
            ariaLabel: 'Id',
            minWidth: columnWidths.id,
            maxWidth: actionButtonFuncs.length > 0 ? undefined : columnWidths.id,
            onRender: (row: IAttribute): JSX.Element => {
                return (
                    <TableCell>
                        <Stack horizontal verticalAlign='center'>
                            <TooltipHost content='Copy to clipboard'>
                                <ActionButton
                                    className={detailsListStyles.icon}
                                    iconProps={{ iconName: IconNames.Copy }}
                                    onClick={(): void => {
                                        navigator.clipboard.writeText(row.id);
                                    }}
                                    ariaLabel='Copy to clipboard'
                                />
                            </TooltipHost>
                            <span>{row.id}</span>
                        </Stack>
                    </TableCell>
                );
            },
        },
    ];

    if (actionButtonFuncs.length > 0) {
        columns.push({
            key: 'Action',
            name: 'Action',
            ariaLabel: 'Action',
            minWidth: columnWidths.action,
            maxWidth: columnWidths.action,
            ...lastColumnStylesButton,
            onRender: (row: IAttribute): JSX.Element => {
                return (
                    <TableCell>
                        <Stack horizontal>{actionsFunc(row)}</Stack>
                    </TableCell>
                );
            },
        });
    }

    return columns;
}
