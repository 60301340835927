import UsGovScreeningClient, {
    IScreeningSummaryDataResponse,
    SummaryDataTypeEnum,
} from 'clients/screening/us-gov-screening-client';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import SearchFilterContainer from 'components/common/search-filter/search-filter-container';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import Spacer from 'components/common/spacer';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BrowserCacheKeys } from 'utils/browser-cache-utils';
import { doNothing } from 'utils/misc-utils';
import {
    IBarChartData,
    ICustomerMetricsSummaryData,
    IScreening,
} from 'components/screening/us-gov/IScreening';
import CommonScreeningChart from 'components/screening/screening-v2/metrics/common-screening-chart';
import ScreeningPageToolbar from 'components/screening/screening-v2/common/screening-page-toolbar';
import Metrics from 'components/screening/screening-v2/metrics';
import ScreeningRecords from 'components/screening/screening-v2/screening-records';
import { useParams } from 'react-router-dom';
import {
    ScreeningBreadcrumbs,
    ScreeningURLs,
} from 'components/screening/screening-v2/common/screening-constants';
import useEmployeeBasicRecords from 'components/common/employee/use-employee-basic-records';
import { useContracts } from 'components/screening/admin-contracts/contract-utils';
import { ContractType } from 'components/screening/us-gov/IContract';
import NominateCandidateModal from 'components/screening/screening-v2/common/nominate-candidate-modal';
import { ScreeningPageNames } from 'components/common/constants';
import ScreeningPageDataBar from 'components/screening/screening-v2/common/screening-contents-toolbar';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { CheckScrollReachedBottom } from 'components/common/scroll-event-listener';
import { dateToFormattedDateTimeStringFromMillis } from 'utils/time-utils';
import {
    InitFilterSettingsRecord,
    FilterSettingsRecordType,
} from 'components/common/search-filter/use-filter-settings';
import {
    RecordsPerFetch,
    RecordsPerFetchLoadAll,
} from 'components/common/search-filter/search-filter-constants';
import { replaceTemplatedTimeStringInDownloadReport } from 'utils/reporting-utils';

enum TabTitlesEnum {
    Records = 'Records',
    Metrics = 'Metrics',
}

export default function ScreeningV2(): JSX.Element {
    const breadCrumbContext = useContext(BreadCrumbContext);
    const authContext = useContext(AuthContext);

    const { tab } = useParams<{ tab?: string }>();

    const [isInitialPageLoad, setIsInitialPageLoad] = useState<boolean>(true);
    const [hasErrorOccurred, setErrorOccurred] = useState<boolean>(false);
    const [errorMessage, setMessage] = useState<string>('');

    // TODO - Remove if never used
    const [totalRecordCount, setTotalRecordCount] = useState<number>();
    const [filterSettingsRecord, setFilterSettingsRecord] = useState<FilterSettingsRecordType>(
        InitFilterSettingsRecord,
    );
    const [selectedTab, setSelectedTab] = useState<string>();
    const [barChartData, setBarChartData] = useState<IBarChartData | undefined>(undefined);
    const [isBarChartDataReady, setIsBarChartDataReady] = useState<boolean>(false);
    const [isLoadingScreeningRecords, setIsLoadingScreeningRecords] = useState<boolean>(false);
    // TODO - Consider using ICommonScreening for variable screeningRecords
    const [screeningRecords, setScreeningRecords] = useState<IScreening[]>([]);
    const [totalFilteredRecordsCount, setTotalFilteredRecordsCount] = useState<number>();
    const [screeningRecordsContinuationToken, setScreeningRecordsContinuationToken] = useState<
        string
    >();
    const [customerMetricsSummaryData, setCustomerMetricsSummaryData] = useState<
        ICustomerMetricsSummaryData | undefined
    >(undefined);
    // TODO - Remove if never used
    const [isLoadingCustomerMetrics, setIsLoadingCustomerMetrics] = useState<boolean>(false);
    const [errorGettingScreeningRecords, setErrorGettingScreeningRecords] = useState<string>('');
    const [isNominateModalOpen, setIsNominateModalOpen] = useState<boolean>(false);
    const [isColumnsExpanded, setIsColumnsExpanded] = useState<boolean>(false);
    const [shouldRefetchFilterDefinitions, setShouldRefetchFilterDefinitions] = useState<boolean>(
        false,
    );
    const [shouldFetchScreeningRecords, setShouldFetchScreeningRecords] = useState<boolean>(false);
    const [shouldLoadAll, setShouldLoadAll] = useState<boolean>(false);

    useEffect(() => {
        history.replaceState(
            {},
            '',
            ScreeningURLs.managePageUrl(selectedTab, filterSettingsRecord.urlParams),
        );
    }, [filterSettingsRecord, selectedTab]);

    const onMakeAndCopyUrlClick = () => {
        const url =
            window.location.origin +
            ScreeningURLs.managePageUrl(selectedTab, filterSettingsRecord.urlParams);
        navigator.clipboard.writeText(url);
    };

    const onTabSelected = useCallback((tabParam: string | undefined) => {
        const tabTitles = Object.keys(TabTitlesEnum);
        const tabTitle =
            tabTitles.find((t) => t.toLowerCase() === tabParam?.toLowerCase()) || tabTitles[0];
        setSelectedTab(tabTitle);
        breadCrumbContext.setBreadCrumbs(ScreeningBreadcrumbs.managePageTab(tabTitle));
    }, []);

    useEffect(() => {
        onTabSelected(tab);
    }, [tab]);

    const {
        getBasicEmployeeRecords,
        basicEmployeesMap,
        errorGettingRecords: errorGettingEmployeeRecords, // TODO - Remove if never used
        clearErrorMessage: clearErrorGettingEmployeeRecords, // TODO - Remove if never used
        employeesPictureMap: employeePicturesMap,
    } = useEmployeeBasicRecords();

    const {
        getContracts,
        contractsMap,
        errorGettingRecords: errorGettingContractRecords, // TODO - Remove if never used
        clearErrorMessage: clearErrorGettingContractRecords, // TODO - Remove if never used
    } = useContracts();

    const getScreeningRecords = useCallback(
        async (
            urlParams: URLSearchParams | undefined,
            continuationToken: string | undefined,
        ): Promise<void> => {
            try {
                setErrorGettingScreeningRecords('');
                setIsLoadingScreeningRecords(true);
                const searchResponse = await UsGovScreeningClient.searchScreenings(
                    authContext,
                    urlParams || new URLSearchParams(),
                    shouldLoadAll ? RecordsPerFetchLoadAll : RecordsPerFetch, // resultCount
                    SummaryDataTypeEnum.UsGovManage,
                    continuationToken,
                );
                const {
                    searchResults,
                    totalFilteredRecordsCount: totalFilteredRecordsCountVar,
                } = searchResponse;
                setTotalFilteredRecordsCount(totalFilteredRecordsCountVar);
                setScreeningRecordsContinuationToken(searchResults.continuationToken);
                getBasicEmployeeRecords(
                    searchResults.results
                        .map((r) => r.personnelId)
                        .concat(
                            searchResults.results
                                .filter((r) => !!r.processOwnerId)
                                .map((r) => r.processOwnerId),
                        ),
                );
                getContracts(
                    new Set(searchResults.results.map((r) => r.governmentContract)),
                    ContractType.USGovScreening,
                );
                if (continuationToken) {
                    setScreeningRecords((currentValue) => [
                        ...currentValue,
                        ...searchResults.results,
                    ]);
                } else {
                    setScreeningRecords(searchResults.results);
                }
                if (shouldLoadAll) {
                    if (searchResults.continuationToken) {
                        getScreeningRecords(urlParams, searchResults.continuationToken);
                    } else {
                        setIsLoadingScreeningRecords(false);
                        setShouldLoadAll(false);
                    }
                } else {
                    setIsLoadingScreeningRecords(false);
                }
            } catch (e) {
                setErrorGettingScreeningRecords(e.message);
            }
        },
        [shouldLoadAll],
    );

    useEffect(() => {
        if (shouldFetchScreeningRecords) {
            setShouldFetchScreeningRecords(false);
            getScreeningRecords(filterSettingsRecord.urlParams, screeningRecordsContinuationToken);
            getBarChart(filterSettingsRecord.urlParams);
            getCustomerMetrics(filterSettingsRecord.urlParams);
        }
    }, [
        filterSettingsRecord,
        getScreeningRecords,
        screeningRecordsContinuationToken,
        shouldFetchScreeningRecords,
    ]);

    const loadMoreScreeningRecords = (): void => {
        if (isLoadingScreeningRecords || !!errorGettingScreeningRecords) {
            return;
        }
        setShouldFetchScreeningRecords(true);
    };

    const getBarChart = useCallback(async (urlParams: URLSearchParams): Promise<void> => {
        try {
            const barChartData = await UsGovScreeningClient.getBarChartData(
                authContext,
                urlParams,
                SummaryDataTypeEnum.UsGovManage,
            );
            if (barChartData) {
                setBarChartData(barChartData);
                setIsBarChartDataReady(true);
            }
        } catch (e) {
            console.error('error with getting bar chart data', e);
        }
    }, []);

    const getCustomerMetrics = useCallback(async (urlParams: URLSearchParams): Promise<void> => {
        try {
            setIsLoadingCustomerMetrics(true);
            const newCustomerMetrics = await UsGovScreeningClient.getCustomerMetricsSummaryDataBasedOnParams(
                authContext,
                urlParams,
                SummaryDataTypeEnum.UsGovManage,
            );
            if (newCustomerMetrics) {
                setCustomerMetricsSummaryData(newCustomerMetrics);
            }
        } catch (e) {
            console.error('Failed to grab Customer Metrics:', e);
        } finally {
            setIsLoadingCustomerMetrics(false);
        }
    }, []);

    async function downloadUsGovScreeningReport(): Promise<string> {
        let data = '';
        try {
            let urlParams = new URLSearchParams();

            if (filterSettingsRecord) {
                urlParams = filterSettingsRecord.urlParams;
            }

            const downloadScreeningReport = await UsGovScreeningClient.downloadScreeningReport(
                authContext,
                urlParams,
                SummaryDataTypeEnum.UsGovManage,
            );

            data = replaceTemplatedTimeStringInDownloadReport(downloadScreeningReport);
        } catch (e) {
            console.error('Failed to generate and return US Gov Screenings Report:', e);
            setErrorOccurred(true);
            setMessage('Unable to download US Gov Screenings Report.');
        }
        return data;
    }

    const onCandidateNominated = useCallback((): void => {
        setShouldFetchScreeningRecords(true);
        setShouldRefetchFilterDefinitions(true);
    }, []);

    const onNominationError = (errorMessage: string): void => {
        console.error(errorMessage);
    };

    const updateMessage = (error: boolean, message: string): void => {
        setErrorOccurred(error);
        setMessage(message);
    };

    const onFilterSettingsChanged = useCallback(
        (settingsRecord: FilterSettingsRecordType) => {
            setFilterSettingsRecord(settingsRecord);
            if (isInitialPageLoad) {
                setShouldFetchScreeningRecords(true);
                setIsInitialPageLoad(false);
            }
        },
        [isInitialPageLoad],
    );

    const onApplyFilters = useCallback(() => {
        if (!isLoadingScreeningRecords) {
            setScreeningRecordsContinuationToken('');
            setShouldFetchScreeningRecords(true);
        }
    }, [isLoadingScreeningRecords]);

    const onLoadAll = useCallback(() => {
        if (!isLoadingScreeningRecords) {
            setShouldLoadAll(true);
            onApplyFilters();
        }
    }, [isLoadingScreeningRecords]);

    useEffect(() => {
        if (shouldRefetchFilterDefinitions) {
            setShouldRefetchFilterDefinitions(false);
        }
    }, [shouldRefetchFilterDefinitions]);

    const onSummaryDataReady = useCallback((summaryData: IScreeningSummaryDataResponse) => {
        setTotalRecordCount(summaryData.totalRecordCount);
    }, []);

    return (
        <>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <SidebarAndContents>
                <SidebarPane>
                    <SearchFilterContainer
                        onFilterDefinitionsChecked={doNothing}
                        cacheStorage={localStorage}
                        cacheKey={BrowserCacheKeys.usgovScreeningsManageSearchFilterSettings}
                        filterDefinitionsFetchFunction={() =>
                            UsGovScreeningClient.GetScreeningSummaryData(
                                authContext,
                                SummaryDataTypeEnum.UsGovManage,
                            )
                        }
                        onFilterSettingsChanged={onFilterSettingsChanged}
                        shouldRefetchFilterDefinitions={shouldRefetchFilterDefinitions}
                        onSummaryDataReady={onSummaryDataReady}
                        onApplyFiltersClick={onApplyFilters}
                        onLoadAllClick={onLoadAll}
                        onMakeAndCopyUrlClick={onMakeAndCopyUrlClick}
                        onSettingsCleared={onApplyFilters}
                    />
                </SidebarPane>
                <ContentPane>
                    {hasErrorOccurred && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel='Close'
                            onDismiss={(): void => updateMessage(false, '')}
                            styles={{ root: { marginBottom: 15 } }}>
                            {errorMessage}
                        </MessageBar>
                    )}
                    <ScreeningPageToolbar
                        showNominateButton={true}
                        tabTitles={Object.keys(TabTitlesEnum)}
                        selectedTab={selectedTab}
                        onTabSelected={onTabSelected}
                        onClickNominateCandidate={() => {
                            setIsNominateModalOpen(true);
                        }}></ScreeningPageToolbar>
                    <Spacer marginTop={10} />
                    <CommonScreeningChart
                        barChartData={barChartData}
                        isBarChartDataReady={isBarChartDataReady}
                    />
                    <ScreeningPageDataBar
                        pageName={ScreeningPageNames.Manage}
                        showExpandColumnsButton={true}
                        showDownloadReportButton={true}
                        getReportData={downloadUsGovScreeningReport}
                        isColumnsExpanded={isColumnsExpanded}
                        onExpandColumnsClick={() =>
                            setIsColumnsExpanded((currentValue) => !currentValue)
                        }
                        shownRecordCount={screeningRecords.length}
                        totalRecordCount={totalFilteredRecordsCount}
                    />
                    {selectedTab === TabTitlesEnum.Records && (
                        <ScreeningRecords
                            isColumnsExpanded={isColumnsExpanded}
                            basicEmployeesMap={basicEmployeesMap}
                            contractsMap={contractsMap}
                            employeesPictureMap={employeePicturesMap}
                            isLoadingScreeningRecords={isLoadingScreeningRecords}
                            errorGettingRecords={errorGettingScreeningRecords}
                            screeningRecords={screeningRecords}
                        />
                    )}
                    {selectedTab === TabTitlesEnum.Metrics && (
                        <Metrics customerMetricsSummaryData={customerMetricsSummaryData} />
                    )}
                </ContentPane>
            </SidebarAndContents>
            <NominateCandidateModal
                isModalOpen={isNominateModalOpen}
                closeModal={() => {
                    setIsNominateModalOpen(false);
                }}
                pageName={ScreeningPageNames.Manage}
                pathName={location.pathname}
                onCandidateNominated={onCandidateNominated}
                onNominationError={onNominationError}
            />
            <CheckScrollReachedBottom
                shouldCheck={!!screeningRecordsContinuationToken}
                onScrollReachedBottom={loadMoreScreeningRecords}
            />
        </>
    );
}
