import { CloudStatus } from 'components/staffing/staffing-constants';
import { FilterSettings } from 'components/staffing/contexts/staffing-status-filter-context';
import { AllocationFilter } from 'components/staffing/status-filters/allocation-filter-class';
import { O365AllocationFilter } from 'components/staffing/status-filters/o365-fitler';
import { CloudStatusAllocationFilter } from 'components/staffing/status-filters/no-op-filter';
import { RXM4BurndownAllocationFilter } from 'components/staffing/status-filters/m4-burndown-rx-filter';
import { EXM4BurndownAllocationFilter } from 'components/staffing/status-filters/m4-burndown-ex-filter';
import { EXTargetReadyAllocationFilter } from 'components/staffing/status-filters/target-ready-ex-filter';
import { RXTargetReadyAllocationFilter } from 'components/staffing/status-filters/target-ready-rx-filter';

export const FilterFactory = (
    statusView: CloudStatus,
    statusFilter: FilterSettings,
): AllocationFilter => {
    if (statusFilter === FilterSettings.Azure) {
        return new O365AllocationFilter(statusView);
    }

    if (statusFilter === FilterSettings.M4Burndown && statusView === CloudStatus.RX) {
        return new RXM4BurndownAllocationFilter(statusView);
    }

    if (statusFilter === FilterSettings.M4Burndown && statusView === CloudStatus.EX) {
        return new EXM4BurndownAllocationFilter(statusView);
    }

    if (statusFilter === FilterSettings.TargetReady && statusView === CloudStatus.RX) {
        return new RXTargetReadyAllocationFilter(statusView);
    }

    if (statusFilter === FilterSettings.TargetReady && statusView === CloudStatus.EX) {
        return new EXTargetReadyAllocationFilter(statusView);
    }

    // default
    return new CloudStatusAllocationFilter(statusView);
};
