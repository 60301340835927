import { ActionButton, IButtonStyles, IIconProps } from '@fluentui/react';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

/**
 * Overridden ActionButton to provide same styling for action button
 * but when clicked will call NavLink that will utilize the react-router
 * to navigate to new page.
 */
export interface ActionButtonNavProps {
    to: string;
    disabled?: boolean;
    title?: string;
    iconName?: string;
    styles?: IButtonStyles;
    children?: React.ReactNode;
    onClick?: () => void; //Do this function for the button click before the navlink() is clicked
    allowDisabledFocus?: boolean;
}

export default function ActionButtonNav(props: ActionButtonNavProps): JSX.Element {
    const navLink = useRef<HTMLAnchorElement>(null);
    const [iconProps, setIconProps] = useState<IIconProps | undefined>();

    function onClick(): void {
        if (props.onClick) {
            props.onClick();
        }
        if (navLink != null && navLink.current != null) {
            navLink.current.click();
        }
    }

    useEffect(() => {
        if (props.iconName) {
            setIconProps({ iconName: props.iconName });
        }
    }, []);

    return (
        <ActionButton
            styles={props.styles}
            title={props.title}
            disabled={props.disabled}
            iconProps={iconProps}
            allowDisabledFocus={props.allowDisabledFocus}
            onClick={onClick}>
            {props.children}
            <NavLink hidden={true} ref={navLink} to={props.to} />
        </ActionButton>
    );
}
