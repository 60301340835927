import { ActionButton, IColumn, mergeStyleSets } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import EligibilityClient, {
    IPersonnelEligibilities,
    IPersonnelEligibility,
} from 'clients/eligibility-client';
import { IEmployeeWithEditableData } from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import { dateToDateStringFormat } from 'utils/time-utils';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { EmployeeNameResolverAlias } from 'components/common/employee/employee-name-resolver';
import { Table, TableCell } from 'components/common/table';
import {
    firstColumnTableCellPadding,
    firstColumnTitlePadding,
} from 'components/personnel-profile/common/common-constants';
import {
    actionButtonStyles,
    commonPersonnelPersonnelStyles,
} from 'components/personnel-profile/common-personnel-profile-styles';
import { getDefaultColumnWidths } from 'utils/table-utils';

export interface PersonnelProfileEligibilityTableProps {
    employee: IEmployeeWithEditableData | undefined;
    resultSize: number;
}

export default function PersonnelProfileEligibilityTable(
    props: PersonnelProfileEligibilityTableProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [personnelEligibilities, setPersonnelEligibilities] = useState<IPersonnelEligibility[]>(
        [],
    );

    useEffect(() => {
        let isMounted = true;
        async function getPersonnelEligibilities(
            personnelId: string | undefined | null,
        ): Promise<void> {
            let eligibilitiesRes: IPersonnelEligibilities | undefined = undefined;

            if (personnelId) {
                eligibilitiesRes = await EligibilityClient.getPersonnelEligibilities(
                    authContext,
                    personnelId,
                );
            }

            if (isMounted && eligibilitiesRes) {
                if (eligibilitiesRes?.eligibilities?.length > 0) {
                    eligibilitiesRes.eligibilities.sort(
                        (a: IPersonnelEligibility, b: IPersonnelEligibility): number => {
                            return +new Date(b.startDate) - +new Date(a.startDate);
                        },
                    );
                    setPersonnelEligibilities(eligibilitiesRes.eligibilities);
                }
            }
        }
        getPersonnelEligibilities(props.employee?.data?.id);
        return (): void => {
            isMounted = false;
        };
    }, [props.employee, props.resultSize]);

    return (
        <>
            {props.employee && (
                <ContainerWithEtiquettes
                    leftEtiquetteLabel='Eligibilities'
                    bottomInfo={
                        personnelEligibilities.length > 0 && (
                            <ActionButton
                                styles={actionButtonStyles}
                                title='Manage All Eligibilities'
                                iconProps={{ iconName: 'PreviewLink' }}
                                href={`/profile/user/${props.employee.data.alias}/eligibilities`}>
                                View all
                            </ActionButton>
                        )
                    }>
                    {personnelEligibilities.length === 0 ? (
                        <p className={commonPersonnelPersonnelStyles.noDataParagraph}>
                            No eligibilities to display
                        </p>
                    ) : (
                        <Table<IPersonnelEligibility>
                            rows={personnelEligibilities}
                            isFetchingData={false}
                            tableColumns={columns}
                            tableName='Eligibilities Records'
                        />
                    )}
                </ContainerWithEtiquettes>
            )}
        </>
    );
}

const columnWidths = getDefaultColumnWidths(5, false);

const columns: IColumn[] = [
    {
        key: 'Name',
        name: 'Name',
        ariaLabel: 'Name',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        styles: { cellTitle: { paddingLeft: firstColumnTitlePadding } }, // DetailsList applies this to the header cell.
        onRender: (row: IPersonnelEligibility): JSX.Element => (
            <TableCell style={{ paddingLeft: firstColumnTableCellPadding }}>
                <EllipsisTextCss text={row.eligibilityName} />
            </TableCell>
        ),
    },
    {
        key: 'Starts',
        name: 'Starts',
        ariaLabel: 'Starts',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        onRender: (row: IPersonnelEligibility): JSX.Element => (
            <TableCell>
                <span>{row.startDate ? dateToDateStringFormat(new Date(row.startDate)) : ''}</span>
            </TableCell>
        ),
    },
    {
        key: 'Expires',
        name: 'Expires',
        ariaLabel: 'Expires',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        onRender: (row: IPersonnelEligibility): JSX.Element => (
            <TableCell>
                <span>{row.endDate ? dateToDateStringFormat(new Date(row.endDate)) : ''}</span>
            </TableCell>
        ),
    },
    {
        key: 'Granted By',
        name: 'Granted By',
        ariaLabel: 'Granted By',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        onRender: (row: IPersonnelEligibility): JSX.Element => (
            <TableCell>
                <EmployeeHoverCard personnelAlias={getAliasFromEligiblityAssignedBy(row)}>
                    <span className={styles.strong}>
                        <EmployeeNameResolverAlias alias={getAliasFromEligiblityAssignedBy(row)} />
                    </span>
                </EmployeeHoverCard>
            </TableCell>
        ),
    },
    {
        key: 'Granted On',
        name: 'Granted On',
        ariaLabel: 'Granted On',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        onRender: (row: IPersonnelEligibility): JSX.Element => (
            <TableCell>
                <span>
                    {row.assignedAt ? dateToDateStringFormat(new Date(row.assignedAt)) : ''}
                </span>
            </TableCell>
        ),
    },
];

function getAliasFromEligiblityAssignedBy(row: IPersonnelEligibility): string {
    if (!row?.assignedBy) {
        return '';
    }
    return row.assignedBy.substring(0, row.assignedBy.indexOf('@')) ?? '';
}

const styles = mergeStyleSets({
    strong: {
        fontWeight: '500',
    },
    status: {
        verticalAlign: 'middle',
        display: 'table-cell',
        padding: '2px 7px',
    },
});
