import { CloudStatus, ClearanceStatus } from 'components/staffing/staffing-constants';
import { getAllocationKey } from 'components/staffing/status-filters/filter-utility';
import { AllocationFilter } from 'components/staffing/status-filters/allocation-filter-class';
import { IStaffingClearanceRecordResponse } from 'clients/staffing-client';

const MIN_READY_COUNT = 1;
const MIN_INPROCESS_COUNT = 2;

// Filter out allocations that belong to teams with > MIN_READY_COUNT allocations with 'Ready' status and at least MIN_INPROCESS_COUNT in "In Process" state
export class EXM4BurndownAllocationFilter extends AllocationFilter {
    filter(allocations: IStaffingClearanceRecordResponse[]): IStaffingClearanceRecordResponse[] {
        // Group allocations by team name and track 'Ready' status
        const readyMap: { [key: string]: number } = {};
        const inProcessMap: { [key: string]: number } = {};

        const filteredStatuses = this.filterStatuses(allocations);
        filteredStatuses.forEach((allocation) => {
            const orgTeam: string = getAllocationKey(allocation);

            readyMap[orgTeam] = readyMap[orgTeam] || 0;
            inProcessMap[orgTeam] = inProcessMap[orgTeam] || 0;

            const foundCloud = allocation.cloudStatuses.find((x) => x.name === CloudStatus.EX);
            if (!foundCloud) {
                return;
            }

            if (foundCloud.status === ClearanceStatus.Ready) {
                readyMap[orgTeam]++;
            }

            if (foundCloud.status === ClearanceStatus.InProcess) {
                inProcessMap[orgTeam]++;
            }
        });

        // Filter all allocations belonging to those teams.
        return filteredStatuses.filter((value) => {
            const orgTeam: string = getAllocationKey(value);
            return (
                readyMap[orgTeam] < MIN_READY_COUNT || inProcessMap[orgTeam] < MIN_INPROCESS_COUNT
            );
        });
    }
}
