import config from 'environments/environment';
import { GetAadHttpOptions, JSON_CONTENT_TYPE, PutAadHttpBlobOptions } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';

export default class EmailClient {
    static async sendEmailByTemplate(
        authContext: IAuthContext,
        emailTemplate: IEmailTemplateRequest,
    ): Promise<void> {
        const { baseUrl, emailEndpoint, templateSuffix, aadScopes } = config.emailServiceConfig;
        const url = baseUrl + emailEndpoint + templateSuffix;
        const httpOptions = {
            body: JSON.stringify(emailTemplate),
            method: 'POST',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return;
        } else {
            throw response;
        }
    }

    static async sendEmail(authContext: IAuthContext, email: IEmailRequest): Promise<void> {
        const { baseUrl, emailEndpoint, aadScopes } = config.emailServiceConfig;
        const url = baseUrl + emailEndpoint;
        const httpOptions = {
            body: JSON.stringify(email),
            method: 'POST',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return;
        } else {
            throw response;
        }
    }

    static async resendEmail(authContext: IAuthContext, id: string): Promise<IEmailRecord> {
        const { baseUrl, emailResendEndpoint, aadScopes } = config.emailServiceConfig;
        const url = baseUrl + emailResendEndpoint + id;
        const httpOptions = {
            method: 'POST',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getEmailById(authContext: IAuthContext, id: string): Promise<IEmailResult> {
        const { baseUrl, emailEndpoint } = config.emailServiceConfig;
        const httpOptions = await GetAadHttpOptions(
            authContext,
            config.emailServiceConfig.aadScopes,
        );
        const url = baseUrl + emailEndpoint + id;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getEmailTemplates(authContext: IAuthContext): Promise<IEmailTemplate[]> {
        const { baseUrl, emailTemplateEndpoint, aadScopes } = config.emailServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes, JSON_CONTENT_TYPE);
        const url = baseUrl + emailTemplateEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getTemplateById(authContext: IAuthContext, id: string): Promise<ITemplateResult> {
        const { baseUrl, emailTemplateEndpoint } = config.emailServiceConfig;
        const httpOptions = await GetAadHttpOptions(
            authContext,
            config.emailServiceConfig.aadScopes,
        );
        const url = baseUrl + emailTemplateEndpoint + id;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getEmailPauseStatus(
        authContext: IAuthContext,
    ): Promise<{ emailsEnabled: boolean }> {
        const { baseUrl, emailPauseStatusEndpoint, aadScopes } = config.emailServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + emailPauseStatusEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getRegisteredEmailServices(
        authContext: IAuthContext,
    ): Promise<{ registeredServices: IRegisteredEmailService[] }> {
        const { baseUrl, emailServicesEndpoint, aadScopes } = config.emailServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + emailServicesEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async createTemplate(
        authContext: IAuthContext,
        formData: FormData,
    ): Promise<IEmailTemplate> {
        const { baseUrl, emailTemplateEndpoint, aadScopes } = config.emailServiceConfig;
        const url = baseUrl + emailTemplateEndpoint;
        const httpOptions = {
            body: formData,
            method: 'POST',
            ...(await PutAadHttpBlobOptions(authContext, aadScopes)),
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async updateTemplate(
        authContext: IAuthContext,
        id: string,
        formData: FormData,
    ): Promise<IEmailTemplate> {
        const { baseUrl, emailTemplateEndpoint, aadScopes } = config.emailServiceConfig;
        const url = baseUrl + emailTemplateEndpoint + id;

        const httpOptions = {
            body: formData,
            method: 'PUT',
            ...(await PutAadHttpBlobOptions(authContext, aadScopes)),
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async deleteTemplate(authContext: IAuthContext, id: string): Promise<void> {
        const { baseUrl, emailTemplateEndpoint, aadScopes } = config.emailServiceConfig;
        const url = baseUrl + emailTemplateEndpoint + id;
        const httpOptions = {
            method: 'DELETE',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return;
        } else {
            throw response;
        }
    }

    static async getEmailBySearch(
        authContext: IAuthContext,
        searchRequest: IEmailSearchRequest,
        continuationToken?: string,
        signal?: AbortSignal,
    ): Promise<ISearchEmailResponse> {
        const { baseUrl, emailSearchEndpoint, aadScopes } = config.emailServiceConfig;
        const url = baseUrl + emailSearchEndpoint;
        const httpOptions = {
            body: JSON.stringify(searchRequest),
            method: 'POST',
            ...(await GetAadHttpOptions(
                authContext,
                aadScopes,
                JSON_CONTENT_TYPE,
                continuationToken,
            )),
            signal,
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async pauseEmails(authContext: IAuthContext): Promise<boolean> {
        const { baseUrl, emailPauseEndpoint, aadScopes } = config.emailServiceConfig;
        const url = baseUrl + emailPauseEndpoint;
        const httpOptions = {
            method: 'PUT',
            ...(await PutAadHttpBlobOptions(authContext, aadScopes)),
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async pauseEmailsByService(
        authContext: IAuthContext,
        id: string,
        enableService: boolean,
    ): Promise<{ registeredService: IRegisteredEmailService }> {
        const { baseUrl, emailServicesEndpoint, aadScopes } = config.emailServiceConfig;
        const url =
            baseUrl + emailServicesEndpoint + `/action/${id}?enableService=${enableService}`;
        const httpOptions = {
            method: 'PUT',
            ...(await PutAadHttpBlobOptions(authContext, aadScopes)),
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async resumeEmails(authContext: IAuthContext): Promise<boolean> {
        const { baseUrl, emailResumeEndpoint, aadScopes } = config.emailServiceConfig;
        const url = baseUrl + emailResumeEndpoint;
        const httpOptions = {
            method: 'PUT',
            ...(await PutAadHttpBlobOptions(authContext, aadScopes)),
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async updateServiceName(
        authContext: IAuthContext,
        id: string,
        name: string,
    ): Promise<IRegisteredEmailService> {
        const { baseUrl, emailServicesEndpoint, aadScopes } = config.emailServiceConfig;
        const url = baseUrl + emailServicesEndpoint + `/${id}`;
        const httpOptions = {
            body: JSON.stringify({ name: name }),
            method: 'PUT',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }
}

export interface IEmailRequest {
    from: string;
    sendAsEmail: string;
    toRecipients: string[];
    ccRecipients: string[];
    bccRecipients: string[];
    replyTo: string[];
    message: IEmailMessage;
}

export interface IEmailTemplateRequest {
    templateId: string;
    from: string;
    sendAsEmail: string;
    toRecipients: string[];
    ccRecipients: string[];
    bccRecipients: string[];
    replyTo: string[];
    subject: string;
    model: any;
}

export enum SendResultCode {
    /* eslint-disable @typescript-eslint/naming-convention */
    MAIL_SEND_SUCCESS = 'MAIL_SEND_SUCCESS',
    MAIL_SENT_ALREADY = 'MAIL_SENT_ALREADY',
    SEND_AS_DENIED = 'SEND_AS_DENIED',
    NOT_SENT_AFTER_RETRIES = 'NOT_SENT_AFTER_RETRIES',
    INVALID_APP_CONFIG = 'INVALID_APP_CONFIG',
    INVALID_SMTP_CONFIG = 'INVALID_SMTP_CONFIG',
    INVALID_MSG_FORMAT = 'INVALID_MSG_FORMAT',
    UNKNOWN_REASON = 'UNKNOWN_REASON',
    /* eslint-enable @typescript-eslint/naming-convention */
}

export interface IEmailMessage {
    subject: string;
    body: IMessageBody;
}

export interface IMessageBody {
    isBodyHtml: boolean;
    content: string;
}

export interface ISearchEmailResponse {
    results: IEmailRecord[];
    continuationToken: string;
}

export interface IEmailResult {
    record: IEmailRecord;
    payload: IEmailPayload;
}

export interface ITemplateResult {
    content: string;
    record: IEmailTemplate;
}

export interface ITemplateRequest {
    templateLabel: string;
    modelFields: string[];
}

export interface IEmailTemplate {
    id: string;
    templateLabel: string;
    modelFields: string[];
    createdBy: string;
    createdTimeStamp: number;
    lastModifiedBy: string;
    lastModifiedTimeStamp: number;
}

export interface IEmailRecord {
    id: string;
    senderApplicationId: string;
    subject: string;
    from: string;
    sendAsEmail: string;
    toRecipients: Array<string>;
    ccRecipients: Array<string>;
    replyTo: Array<string>;
    isSent: boolean;
    createdTimeStamp: number;
    sentTimeStamp: number;
    sendResultCode: string;
    testRecord: boolean;
}

export interface IEmailPayload {
    id: string;
    message: IEmailMessage;
    body: IMessageBody;
}

export interface IEmailSearchRequest {
    id: string;
    subject: string;
    senderApplicationId: string;
    to: string;
    createdAfter: Date | null;
    sentAfter: Date | null;
    sendResultCode: keyof typeof SendResultCode | '';
}

export interface IRegisteredEmailService {
    name: string;
    lastModifiedTimeStamp: number;
    enabled: boolean;
    id: string;
}
