import React from 'react';
import { TextField, Stack } from '@fluentui/react';
export interface CandidateNominationStepPersonalContactsProps {
    phoneNumber?: string;
    emailAddress?: string;
    homeAddress?: string;
    onPhoneNumberChange: (newValue?: string) => void;
    onEmailAddressChange: (newValue?: string) => void;
    onHomeAddressChange: (newValue?: string) => void;
}

export default function CandidateNominationStepPersonalContacts(
    props: CandidateNominationStepPersonalContactsProps,
): JSX.Element {
    return (
        <Stack tokens={{ childrenGap: 20 }}>
            <TextField
                label='Personal phone number'
                required
                value={props.phoneNumber || ''}
                onChange={(ev, newValue): void => onFieldChange(ev, 'phone', newValue)}
            />
            <TextField
                label='Personal email address'
                required
                value={props.emailAddress || ''}
                onChange={(ev, newValue): void => onFieldChange(ev, 'email', newValue)}
            />
            <TextField
                label='Home address'
                multiline
                required
                value={props.homeAddress || ''}
                onChange={(ev, newValue): void => onFieldChange(ev, 'address', newValue)}
            />
        </Stack>
    );

    function onFieldChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: string,
        newValue?: string,
    ): void {
        if (key === 'phone') props.onPhoneNumberChange(newValue);
        if (key === 'email') props.onEmailAddressChange(newValue);
        if (key === 'address') props.onHomeAddressChange(newValue);
    }
}
