import { mergeStyles } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import { FormState, GenericFormRecord } from 'clients/forms-client';
import React from 'react';
import { formStateBadgeColors, getStateColor } from 'components/forms/forms-common';

export interface ReviewIndicatorProps {
    formRecord: GenericFormRecord;
}

const states: string[] = [
    FormState.submitted,
    FormState.underReview,
    FormState.rejected,
    FormState.accepted,
    FormState.completed,
];

const dotSize = '1rem';
const topValue = '1.85rem';
const spacing = '2px';
const marginSize = '1rem';
const indicatorDivStyle = mergeStyles({
    maxWidth: '75rem',
    marginLeft: 'min(17rem, 10%)',
    marginRight: 'auto',
});

const textAlignCenterStyle = mergeStyles({
    textAlign: 'center',
});

const listStyle = mergeStyles({
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '0px',
});
const lineWidth = '5px';

const itemStyleBefore = {
    ':before': {
        content: "''",
        position: 'relative',
        zIndex: '1',
        display: 'block',
        width: dotSize,
        height: dotSize,
        borderRadius: '50%',
        margin: `${marginSize} auto 0`,
        border: `0.25rem solid ${formStateBadgeColors.lightGrey}`,
    },
};

const itemStyleAfter = {
    ':not(:last-child)': {
        ':after': {
            content: "''",
            height: lineWidth,
            backgroundColor: formStateBadgeColors.lightGrey,
            position: 'relative',
            top: topValue,
            width: `calc(100% - ${dotSize} - calc(${spacing} * 2))`,
            left: `calc(50% + calc(${dotSize} / 2 + ${spacing}))`,
            order: '-1',
        },
    },
};

const itemStyleBeforeLast = {
    ':last-child': {
        marginTop: '0.35rem',
    },
};

const defaultItemStyle = mergeStyles(itemStyleBefore, itemStyleBeforeLast, itemStyleAfter, {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
});

export function FormReviewStatusIndicator(props: ReviewIndicatorProps): JSX.Element {
    const { formRecord } = props;

    const capitalizeText = (text: string): string => {
        return text
            .toLowerCase()
            .split('_')
            .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
            .join(' ');
    };

    const indicatorDot = (text: string, keyNum: number): JSX.Element => {
        const isCurrentItem = formRecord?.formState === text;
        const stepOrUndefined = isCurrentItem ? 'step' : undefined;
        const dotBg = isCurrentItem ? getStateColor(text) : formStateBadgeColors.darkGrey;
        const itemStyle = mergeStyles(defaultItemStyle, {
            ':before': { backgroundColor: dotBg },
        });
        return (
            <li
                aria-current={stepOrUndefined}
                className={mergeStyles(itemStyle, globalStyles.boldFont)}
                key={keyNum}>
                {capitalizeText(text)}
            </li>
        );
    };

    return (
        <div className={indicatorDivStyle}>
            <h4 className={textAlignCenterStyle}>Status</h4>
            <ul className={listStyle}>
                {states.map((state, index) => indicatorDot(state, index))}
            </ul>
        </div>
    );
}
