import { DatePicker, Separator, Slider, Stack, TextField } from '@fluentui/react';
import { globalFilterSeparatorStyles } from 'assets/styles/global-styles';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import Spacer from 'components/common/spacer';
import { ManageGroupsFilterContext } from 'components/groups/manage-groups/contexts/manage-groups-filter-context';
import { ManageGroupsVariableContext } from 'components/groups/manage-groups/contexts/manage-groups-variable-context';
import React, { FormEvent, useContext, useEffect } from 'react';

export default function ManageGroupsFilters(): JSX.Element {
    const filterContext = useContext(ManageGroupsFilterContext);
    const variableContext = useContext(ManageGroupsVariableContext);

    useEffect(() => {
        filterContext.setLowerPercentageCompliant(0);
        filterContext.setUpperPercentageCompliant(100);
    }, []);

    useEffect(() => {
        filterContext.setLowerMemberCount(0);
        filterContext.setUpperMemberCount(variableContext.maxMemberCount);
    }, [variableContext.maxMemberCount]);

    const clearFilters = (): void => {
        filterContext.setName('');
        filterContext.setDateCreated(undefined);
        filterContext.setLastModifiedDate(undefined);
        filterContext.setLowerPercentageCompliant(0);
        filterContext.setUpperPercentageCompliant(100);
        filterContext.setLowerMemberCount(0);
        filterContext.setUpperMemberCount(variableContext.maxMemberCount ?? 0);
    };

    return (
        <Stack>
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Group Name</span>
            </Separator>
            <TextField
                onChange={(
                    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                    newValue?: string | undefined,
                ): void => {
                    filterContext.setName(newValue);
                }}
                value={filterContext.name || ''}
                ariaLabel='Group Name'
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Date Created</span>
            </Separator>
            <DatePicker
                ariaLabel='Created Date'
                value={filterContext.dateCreated}
                placeholder={'Select a date'}
                allowTextInput={true}
                onSelectDate={(date: Date | null | undefined): void => {
                    // Type conversion from (Date | null | undefined) to (Date | undefined)
                    // DatePicker has defined type of "value" as (Date | undefined)
                    // but pass a (Date | null | undefined) to "onSelectDate".
                    filterContext.setDateCreated(!!date ? date : undefined);
                }}
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Last Modified Date</span>
            </Separator>
            <DatePicker
                ariaLabel='Last Modified Date'
                value={filterContext.lastModifiedDate}
                placeholder={'Select a date'}
                allowTextInput={true}
                onSelectDate={(date: Date | null | undefined): void => {
                    // Type conversion from (Date | null | undefined) to (Date | undefined)
                    // DatePicker has defined type of "value" as (Date | undefined)
                    // but pass a (Date | null | undefined) to "onSelectDate".
                    filterContext.setLastModifiedDate(!!date ? date : undefined);
                }}
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Percentage Compliant</span>
            </Separator>
            <Slider
                max={100}
                step={1}
                valueFormat={(value: number): string => (value !== undefined ? `${value}%` : '')}
                ranged
                value={filterContext.upperPercentageCompliant}
                lowerValue={filterContext.lowerPercentageCompliant}
                onChange={(e: unknown, range: [number, number] | undefined): void => {
                    if (range === undefined) {
                        filterContext.setLowerPercentageCompliant(0);
                        filterContext.setUpperPercentageCompliant(100);
                    } else {
                        filterContext.setLowerPercentageCompliant(range[0]);
                        filterContext.setUpperPercentageCompliant(range[1]);
                    }
                }}
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Number of Members</span>
            </Separator>
            <Slider
                max={variableContext.maxMemberCount}
                ranged
                value={filterContext.upperMemberCount}
                lowerValue={filterContext.lowerMemberCount}
                onChange={(e: unknown, range: [number, number] | undefined): void => {
                    if (range === undefined) {
                        filterContext.setLowerMemberCount(0);
                        filterContext.setUpperMemberCount(variableContext.maxMemberCount);
                    } else {
                        filterContext.setLowerMemberCount(range[0]);
                        filterContext.setUpperMemberCount(range[1]);
                    }
                }}
            />
            <Stack.Item>
                <Spacer marginTop={10} />
                <Stack horizontal horizontalAlign='end'>
                    <ClearFiltersActionButton clearFunc={clearFilters} />
                </Stack>
            </Stack.Item>
        </Stack>
    );
}
