/**
 * File Name: upload-document-modal
 * Team: Personnel - Screening Team
 * Summary: This button and modal is used when the User views a 'Request Document' request in the Document table of
 * Screening Details page and "Wants to upload a file to the Request Document Request". Or "NST wants to delete the
 * Request Document Request". Used in view-requested-document-modal
 * Last Updated Summary: 10/7/2022 by andreli
 **/
import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import {
    Separator,
    Label,
    mergeStyleSets,
    DefaultButton,
    FontWeights,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import Modal, { ModalProps } from 'components/common/modal';
import { containsNomineeUser, containsNSTUser } from 'components/screening/us-gov/IScreening';
import PublicTrustDocumentClient from 'clients/screening/public-trust-document-client';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import { IUserContext, UserContext } from 'contexts/user-context';
import TemplateClient, { IDocumentMetadata } from 'clients/template-client';
import { AuthContext, IAuthContext } from 'contexts/auth-context';
import DocumentsClient, {
    DocumentReferenceType,
    IDocumentRecord,
    IDocumentReference,
} from 'clients/documents-client';
import {
    changeFileName,
    isValidFileType,
    acceptedFileTypesForTemplate,
    acceptedFileTypesForUpload,
    acceptedFileTypesTextForTemplate,
    acceptedFileTypesTextForUpload,
    fileInputAcceptStringForTemplate,
    fileInputAcceptStringForUpload,
} from 'utils/file-utils';
import { ApplicationServices } from 'clients/screening/us-gov-screening-client';

export interface UploadDocumentModalProps {
    screeningPath: ScreeningPaths;
    isOpen: boolean;
    isUploadingTemplateRequest: boolean;
    onCancel: () => void;
    onRemove: () => void;
    onComplete(doc: IDocumentRecord): Promise<void>;
    children?: React.ReactNode;
    documentMetadata?: IDocumentMetadata;
    referenceId?: string;
    documentRequestId?: string;
    title?: string;
    targetFileName?: string;
    userTypes?: string[];
    nomineeId: string;
}

export function UploadDocumentModal(props: UploadDocumentModalProps): JSX.Element {
    const { isOpen, children, documentMetadata, referenceId, documentRequestId, title } = props;
    const [uploadFile, setUploadFile] = useState<File | undefined>();
    const [isInvalidFile, setIsInvalidFile] = useState<boolean>(true);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isRemoving, setIsRemoving] = useState<boolean>(false);
    const [uploadFailedError, setUploadFailedError] = useState<string | undefined>(undefined);
    const [removeFailedError, setRemoveFailedError] = useState<string | undefined>(undefined);
    const [isUploadError, setIsUploadError] = useState<boolean>(false);
    const [isRemoveError, setIsRemoveError] = useState<boolean>(false);
    const authContext: IAuthContext = useContext(AuthContext);
    const userContext: IUserContext = useContext(UserContext);

    useEffect(() => {
        if (isOpen) {
            reset();
        }
    }, [isOpen]);

    useEffect(() => {
        if (uploadFile) {
            resetErrors();
        }
    }, [uploadFile]);

    function reset(): void {
        resetErrors();
        resetUploadFile();
    }

    function resetErrors(): void {
        setIsUploadError(false);
        setUploadFailedError(undefined);
    }

    function resetUploadFile(): void {
        setIsUploading(false);
        setUploadFile(undefined);
        setIsInvalidFile(true);
    }

    function isActionBeingPerformed(): boolean {
        return isUploading || isRemoving;
    }

    /**
     * Uploading a file has 2 parts:
     * 1) uploading the file to the Document Service
     * 2) sharing the file to the Profile Service (so profile page of the user, you can see all the documents uploaded to the user)
     *  and the Screening Service (we are uploading to a screening)
     */
    async function upload(): Promise<void> {
        if (uploadFile) {
            try {
                setIsUploading(true);
                const uploadedFile: IDocumentRecord = await performUploadFile(uploadFile);
                const sharedFile: IDocumentReference = await performShare(uploadedFile);

                // check if shared doc is properly shared before calling complete
                if (
                    sharedFile.documentId === uploadedFile.id &&
                    sharedFile.referenceId === documentMetadata?.screeningId
                ) {
                    await props.onComplete(uploadedFile);
                    setIsUploadError(false);
                } else {
                    setIsUploadError(true);
                    setUploadFailedError('Unable to upload your file properly, please try again.');
                }
            } catch (error) {
                setIsUploadError(true);
                setUploadFailedError(error);
            } finally {
                resetUploadFile();
            }
        }
    }

    async function performUploadFile(uploadFile: File): Promise<IDocumentRecord> {
        try {
            // upload to document service for Screening is using the screening Id plus appended "fulfiled" as the associatedUserId
            return await DocumentsClient.uploadDocument(
                authContext,
                uploadFile,
                props.documentMetadata?.notes,
                undefined,
                props.documentMetadata?.documentType,
                documentMetadata?.screeningId ? documentMetadata?.screeningId + '-fulfilled' : '',
            );
        } catch (error) {
            throw 'Error with uploading file.';
        }
    }

    async function remove(): Promise<void> {
        if (documentRequestId) {
            setIsRemoving(true);
            try {
                if (props.screeningPath === ScreeningPaths.UsGov) {
                    await TemplateClient.deleteDocumentRequest(authContext, documentRequestId);
                } else {
                    await PublicTrustDocumentClient.deleteDocumentRequest(
                        authContext,
                        documentRequestId,
                    );
                }

                props.onRemove();
            } catch (error) {
                setIsRemoveError(true);
                setRemoveFailedError('Error with deleting request.');
                setIsRemoving(false);
            }
        }
    }

    async function performShare(response: IDocumentRecord): Promise<IDocumentReference> {
        try {
            if (!referenceId) {
                throw '';
            } else {
                const documentReferenceType =
                    props.screeningPath === ScreeningPaths.UsGov
                        ? DocumentReferenceType.ScreeningIdUSGov
                        : DocumentReferenceType.ScreeningIdPublicTrust;

                const request: IDocumentReference = {
                    referenceId: referenceId,
                    application: ApplicationServices.screening,
                    documentId: response.id,
                    allowedPermissions: ['ViewMetadata', 'Download', 'Lock', 'ManageLifecycle'],
                    metadata: {
                        documentRequestId: documentRequestId,
                        documentReferenceType: `${DocumentReferenceType[documentReferenceType]}`,
                        documentServiceAuthorPersonnelId: response.authorPersonnelId,
                    },
                };
                return await DocumentsClient.shareDocument(authContext, request);
            }
        } catch (error) {
            throw `Error with sharing uploaded file for this screening record with referenceId '${referenceId}'.`;
        }
    }

    async function onFileChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        if (event.target.files) {
            let file: File = event.target.files[0];
            if (file) {
                if (props.targetFileName) {
                    file = changeFileName(file, props.targetFileName);
                }

                const isValid: boolean = await isValidFileType(
                    file,
                    props.isUploadingTemplateRequest
                        ? acceptedFileTypesForTemplate
                        : acceptedFileTypesForUpload,
                );
                setUploadFile(file);
                setIsInvalidFile(!isValid);
            }
        } else {
            setIsInvalidFile(true);
        }
    }

    const userTypes = props.userTypes ?? [];

    const onCancel = (): void => {
        setUploadFile(undefined);
        props.onCancel();
    };

    const modalProps: ModalProps = {
        isOpen: isOpen,
        fluentModalProps: {
            isDarkOverlay: false,
            isBlocking: false,
            dragOptions: undefined,
            styles: modalStyles,
        },
        title: title || '',
        footerIconName: 'Encryption',
        footerText: 'Documents are securely transmitted and encrypted.',
        cancelButtonText: 'Close',
        submitButton2Text: 'Delete request',
        submitButtonText: 'Upload',
        isSubmitButton2Disabled:
            !containsNSTUser(userTypes) ||
            userContext.employeeRecord.id === props.nomineeId ||
            isActionBeingPerformed(),
        isSubmitButtonDisabled:
            isInvalidFile ||
            !containsNomineeUser(userTypes) ||
            isActionBeingPerformed() ||
            documentMetadata?.authorPersonnelId === userContext?.employeeRecord?.id ||
            userContext.employeeRecord.id !== props.nomineeId,
        showProgressIndicator: isActionBeingPerformed(),
        onCancel: onCancel,
        onDismiss: onCancel,
        onSubmit2: remove,
        onSubmit: upload,
    };

    return (
        <Modal {...modalProps}>
            {children}
            <Separator styles={separatorStyles} alignContent='start'>
                <Label>Upload</Label>
            </Separator>
            {isUploadError && (
                <MessageBar
                    styles={errorStyles}
                    messageBarType={MessageBarType.error}
                    isMultiline={true}>
                    File upload failed: {uploadFailedError}
                </MessageBar>
            )}
            {isRemoveError && (
                <MessageBar
                    styles={errorStyles}
                    messageBarType={MessageBarType.error}
                    isMultiline={true}>
                    File removal failed: {removeFailedError}
                </MessageBar>
            )}
            {documentMetadata?.title && (
                <p id='upload-description' className={styles.description}>
                    Upload your{' '}
                    <span className={styles.documentName}>{documentMetadata.title}</span> document.
                </p>
            )}
            <div className={styles.grid}>
                {uploadFile && isInvalidFile && (
                    <div className={styles.indent}>
                        <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
                            Invalid file type.{' '}
                            {props.isUploadingTemplateRequest
                                ? acceptedFileTypesTextForTemplate
                                : acceptedFileTypesTextForUpload}
                        </MessageBar>
                    </div>
                )}

                <Label>Document</Label>
                <div className={styles.uploadButton}>
                    <DefaultButton
                        className={styles.button}
                        iconProps={{ iconName: 'OpenFile' }}
                        type='file'>
                        Choose File
                    </DefaultButton>
                    <input
                        className={styles.input}
                        type='file'
                        id='fileInput'
                        onChange={onFileChange}
                        accept={
                            props.isUploadingTemplateRequest
                                ? fileInputAcceptStringForTemplate
                                : fileInputAcceptStringForUpload
                        }
                    />
                    <span className={styles.fileName}>{uploadFile?.name || 'No file chosen'}</span>
                </div>
                <div className={styles.indent}>
                    <span className={styles.value}>
                        {props.isUploadingTemplateRequest
                            ? acceptedFileTypesTextForTemplate
                            : acceptedFileTypesTextForUpload}
                    </span>
                </div>
            </div>
        </Modal>
    );
}

const modalStyles = mergeStyleSets({
    main: {
        width: '650px',
        color: '#323130',
    },
});

const separatorStyles = mergeStyleSets({
    content: {
        paddingLeft: '0',
        marginTop: '20px',
        marginBottom: '10px',
        selectors: {
            label: {
                paddingTop: '0',
            },
        },
    },
});

const errorStyles = mergeStyleSets({
    root: {
        marginBottom: '10px',
    },
});

const styles = mergeStyleSets({
    description: {
        marginTop: '0',
        marginBottom: '20px',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '120px auto',
        gridGap: '0.5em',
    },
    value: {
        paddingTop: '5px',
    },
    button: {
        width: '140px',
    },
    fileName: {
        marginLeft: '10px',
    },
    uploadButton: {
        position: 'relative',
        overflow: 'hidden',
        display: 'inline-block',
    },
    input: {
        position: 'absolute',
        left: '0',
        top: '0',
        opacity: '0',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
    },
    acceptedFiles: {
        paddingTop: '10px',
    },
    documentName: {
        fontWeight: FontWeights.semibold,
    },
    invalidFileText: {
        color: '#F00',
    },
    indent: {
        gridColumnStart: '2',
    },
});

export default UploadDocumentModal;
