import React, { useContext, useState, useEffect, useMemo } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import FacilitiesClient, {
    ISeatRecord,
    IFacilityRecord,
    IProvisionInfo,
    ProvisionType,
    IHierarchyProvisionInfo,
    SeatQualifiers,
} from 'clients/facilities-client';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { Dropdown, IDropdownOption, Label, MessageBarType } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import {
    useEmployeePickerMultiSelect,
    IInitEmployeePickerParams as IInitEmployeePickerParamsMultiSelect,
} from 'components/facilities/facilities-seats/util/employee-picker-for-hierarchy-ids';
import { doNothing } from 'utils/misc-utils';
import { useTextField } from 'components/common/use-input/use-textfield';
import { getEmployeeFromPersona } from 'utils/internal-persona-utils';
import { useEmployeePicker } from 'components/common/use-input/use-employee-picker';
import useMessageBar from 'components/common/use-message-bar';
import { useCheckboxArrayV1 } from 'components/common/use-input/use-checkbox-array-v1';
import { removeWhiteSpaceFromString } from 'utils/string-utils';

export interface IAddEditSeatModal {
    facility: IFacilityRecord;
    seatRecord?: ISeatRecord;
    upsertSeat: (result: ISeatRecord[]) => void;
}

const emptySeatProvisionInfo: IProvisionInfo = {
    provisionType: '',
};

export default function AddEditSeatModal(props: IAddEditSeatModal): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    const [seat, setSeat] = useState<ISeatRecord>(
        props.seatRecord ?? {
            id: '',
            facilityId: props.facility.id,
            recordType: '',
            seatName: '',
            isOutOfOrder: false,
            provisionInfo: emptySeatProvisionInfo,
            createdTimestamp: { by: '', atUtc: 0 },
            lastModified: { by: '', atUtc: 0 },
            lastOperation: '',
            qualifiers: [],
        },
    );
    const provisionTypes: IDropdownOption[] = [
        { key: ProvisionType.General, text: 'General' },
        { key: ProvisionType.Admin, text: 'Admin' },
        { key: ProvisionType.LiveSite, text: 'Live Site' },
        { key: ProvisionType.Hierarchy, text: 'Hierarchy' },
    ];

    const isOutOfOrderStatuses: IDropdownOption[] = [
        { key: 'false', text: 'No' },
        { key: 'true', text: 'Yes' },
    ];

    const onAddEditRecordSubmit = async (): Promise<void> => {
        let seatRecords: ISeatRecord[];

        if (isAdd()) {
            try {
                seatRecords = await FacilitiesClient.createSeatRecords(authContext, userContext, [
                    {
                        seatName: seat.seatName,
                        isOutOfOrder: seat.isOutOfOrder,
                        facilityId: seat.facilityId,
                        provisionInfo: seat.provisionInfo,
                        unitId: seat.unitId,
                        qualifiers: seat.qualifiers,
                    },
                ]);
                if (props.upsertSeat) {
                    props.upsertSeat(seatRecords);
                }
            } catch (e) {
                throw 'An error occurred when creating a seat.';
            }
        } else {
            try {
                seatRecords = await FacilitiesClient.updateSeatRecords(authContext, userContext, [
                    {
                        seatId: seat.id,
                        seatName: seat.seatName,
                        isOutOfOrder: seat.isOutOfOrder,
                        provisionInfo: seat.provisionInfo,
                        unitId: seat.unitId,
                        qualifiers: seat.qualifiers,
                        occupiedBy: occupiedByEmployeeValue?.id ?? '', // if no employee selected we want to clear out occupiedBy by giving empty string
                    },
                ]);
                if (props.upsertSeat) {
                    props.upsertSeat(seatRecords);
                }
            } catch (e) {
                throw 'An error occurred when editing the seat.';
            }
        }
    };

    function isEnabled(): boolean {
        return seat.seatName !== '' && seat.provisionInfo.provisionType !== '';
    }

    const iconName = isAdd() ? IconNames.Add : IconNames.Edit;
    const buttonText = isAdd() ? 'Add New Seat' : 'Edit';
    const submitButtonText = isAdd() ? 'Add' : 'Save';
    const titleText = isAdd() ? 'Add New Seat' : 'Edit Seat';

    function isAdd(): boolean {
        return props.seatRecord === undefined;
    }

    const {
        value: seatNameValue,
        initialize: initSeatNameValue,
        theElement: seatNameTextField,
    } = useTextField({
        label: 'Seat Name (no spaces; letters and numbers only):',
        resizable: false,
        placeholder: seat.seatName,
        required: true,
    });

    const {
        value: seatUnitIdValue,
        initialize: initSeatUnitIdValue,
        theElement: seatUnitIdTextField,
    } = useTextField({
        label: 'Seat Unit Id:',
        resizable: false,
        placeholder: seat.unitId,
    });

    const {
        values: selectedHierarchyEmployees,
        theElement: employeeHierarchyElement,
        initialize: initializeHierarchyEmployees,
    } = useEmployeePickerMultiSelect({
        label: 'Select employee(s) to create hierarchy:',
    });

    const {
        value: occupiedByEmployeeValue,
        theElement: seatOccupiedByPicker,
        initialize: initializeOccupiedByEmployee,
    } = useEmployeePicker({
        label: 'Select employee occupying seat. Leave empty if vacant:',
    });

    const employeeHierarchyIds = useMemo(
        () =>
            selectedHierarchyEmployees?.map(function (e) {
                return getEmployeeFromPersona(e)?.id;
            }),
        [selectedHierarchyEmployees],
    );

    const {
        theMessage: warningMessage,
        theElement: MessageBarElement,
        setMessage: setMessageBarMessage,
    } = useMessageBar({
        type: MessageBarType.warning,
        disableClear: true,
        alwaysShow: true,
    });

    const {
        value: seatQualifiers,
        initialize: initSeatQualifiers,
        theElement: seatQualifiersElement,
    } = useCheckboxArrayV1({
        label: 'Seat Qualifiers',
        options: [
            {
                label: SeatQualifiers.CustomerFacingAVC.value,
                isChecked: isQualifierChecked(SeatQualifiers.CustomerFacingAVC.key),
            },
            {
                label: SeatQualifiers.CustomerFacingMPO.value,
                isChecked: isQualifierChecked(SeatQualifiers.CustomerFacingMPO.key),
            },
            {
                label: SeatQualifiers.CustomerFacingSIPR.value,
                isChecked: isQualifierChecked(SeatQualifiers.CustomerFacingSIPR.key),
            },
            {
                label: SeatQualifiers.SupportDesk.value,
                isChecked: isQualifierChecked(SeatQualifiers.SupportDesk.key),
            },
            {
                label: SeatQualifiers.PhoneAtDesk.value,
                isChecked: isQualifierChecked(SeatQualifiers.PhoneAtDesk.key),
            },
        ],
    });

    function isQualifierChecked(seatQualifierKey: string): boolean | undefined {
        return isAdd()
            ? false
            : props.seatRecord?.qualifiers?.some((sr) => sr === seatQualifierKey);
    }

    useEffect(() => {
        setMessageBarMessage('Editing, or deleting a seat may affect current reservations.');
        initSeatNameValue(seat.seatName);
        initSeatUnitIdValue(seat.unitId);
        const initOccupiedByEmployee = !!seat.occupiedBy?.by
            ? { personnelId: seat.occupiedBy?.by }
            : undefined;
        initializeOccupiedByEmployee(initOccupiedByEmployee);
        if (seat.provisionInfo.provisionType === ProvisionType.Hierarchy) {
            const initData: IInitEmployeePickerParamsMultiSelect = {
                personnelId: (seat.provisionInfo as IHierarchyProvisionInfo).hierarchyIds,
            };
            initializeHierarchyEmployees(initData);
        }
    }, []);

    useEffect(() => {
        if (seat.provisionInfo.provisionType === ProvisionType.Hierarchy) {
            const newProvisionInfo: IHierarchyProvisionInfo = {
                provisionType: ProvisionType.Hierarchy,
                hierarchyIds: employeeHierarchyIds as string[],
            };
            setSeat((prevSeat) => {
                return {
                    ...prevSeat,
                    seatName: seatNameValue ?? '',
                    unitId: seatUnitIdValue ?? '',
                    provisionInfo: newProvisionInfo,
                };
            });
        } else {
            setSeat((prevSeat) => {
                return {
                    ...prevSeat,
                    seatName: seatNameValue ?? '',
                    unitId: seatUnitIdValue ?? '',
                };
            });
        }
    }, [
        seatNameValue,
        seatUnitIdValue,
        selectedHierarchyEmployees,
        occupiedByEmployeeValue,
        employeeHierarchyIds,
        seat.provisionInfo.provisionType,
    ]);

    useEffect(() => {
        const updatedSeat = { ...seat };
        updatedSeat.qualifiers = seatQualifiers
            ? seatQualifiers
                  .filter((seatQualifier) => seatQualifier.isChecked)
                  .map((seatQualifier) => {
                      return removeWhiteSpaceFromString(seatQualifier.label);
                  })
            : [];
        setSeat(updatedSeat);
    }, [seatQualifiers]);

    return (
        <>
            <ModalActionButton<ISeatRecord[]>
                text={buttonText}
                iconName={iconName}
                modalTitle={titleText}
                modalTitleIcon={iconName}
                enableSubmit={isEnabled()}
                submitButtonText={submitButtonText}
                submitButtonIcon={iconName}
                onSubmit={onAddEditRecordSubmit}
                onModalConcluded={doNothing}>
                {warningMessage && !isAdd() && <div>{MessageBarElement()}</div>}
                <div>
                    <Label>Facility Name</Label>
                    {props.facility.facilityName}
                </div>
                <div>{seatNameTextField()}</div>
                <div>{seatUnitIdTextField()}</div>
                {!isAdd() && <div>{seatOccupiedByPicker()}</div>}
                <Dropdown
                    label='Provision Type:'
                    styles={{ dropdownItems: globalStyles.dropdownItems }}
                    placeholder={'Select Provision Type'}
                    onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        option: IDropdownOption | undefined,
                    ): void => {
                        if (option) {
                            const newProvisionInfo: IProvisionInfo = {
                                provisionType: option.key as string,
                            };
                            setSeat((prevSeat) => {
                                return { ...prevSeat, provisionInfo: newProvisionInfo };
                            });
                        }
                    }}
                    options={provisionTypes}
                    selectedKey={seat.provisionInfo.provisionType}
                    required={isAdd()}
                />
                {seat.provisionInfo.provisionType === ProvisionType.Hierarchy &&
                    employeeHierarchyElement()}
                <Dropdown
                    label='Is Out of Order:'
                    styles={{ dropdownItems: globalStyles.dropdownItems }}
                    placeholder={'Yes or No'}
                    onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        option: IDropdownOption | undefined,
                    ): void => {
                        if (option) {
                            setSeat((prevSeat) => {
                                return { ...prevSeat, isOutOfOrder: option.key === 'true' };
                            });
                        }
                    }}
                    options={isOutOfOrderStatuses}
                    selectedKey={seat.isOutOfOrder ? 'true' : 'false'}
                />
                {seatQualifiersElement()}
            </ModalActionButton>
        </>
    );
}
