import { IconNames } from 'assets/constants/global-constants';
import GroupClient, { IGroupMembership, IGroupPolicyViolation } from 'clients/group-client';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import BoldFont from 'components/common/misc/bold-font';
import Spacer from 'components/common/spacer';
import { useTextField } from 'components/common/use-input/use-textfield';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext } from 'react';

interface IMemberDetailsModalActionButtonProps {
    enable: boolean;
    memberDetails: IGroupMembership | IGroupPolicyViolation;
    personnelId: string;
    onGroupMemberDeleted: (personnelId: string, ruleId?: string) => void;
}

function ManageGroupMemberDeleteModalActionButton(props: IMemberDetailsModalActionButtonProps) {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);

    const isJustificationRequired = !!groupContext.group?.enableMemberLeaveJustification;

    const {
        value: businessJustification,
        initialize: initBusinessJustification,
        theElement: theBusinessJustificationElement,
    } = useTextField({
        label: 'Provide a business justification for deleting this member',
        maxLength: 500,
        multiline: true,
        required: isJustificationRequired,
        resizable: false,
        placeholder: isJustificationRequired
            ? 'Justification is required'
            : 'Justification is optional',
        rows: 4,
    });

    const enableSubmit = (): boolean => {
        return (
            props.enable &&
            (!!groupContext.group?.enableMemberLeaveJustification ? !!businessJustification : true)
        );
    };

    const onButtonClick = (): void => {
        initBusinessJustification();
    };

    const onHandleDelete = async (): Promise<void> => {
        try {
            if (groupContext.group) {
                await GroupClient.deleteGroupMember(
                    authContext,
                    groupContext.group?.id,
                    props.personnelId,
                    businessJustification,
                );
            }
        } catch (e) {
            console.error('Error deleting member from table', e);
            throw e;
        } finally {
        }
    };

    const onDeleteMemberConcluded = (conclusion: ModalConclusion): void => {
        if (conclusion === ModalConclusion.Done) {
            props.onGroupMemberDeleted(props.memberDetails.personnelId);
        }
    };

    return (
        <ModalActionButton<void>
            text={'Delete'}
            iconName={IconNames.Delete}
            enable={props.enable}
            modalTitle={'Delete'}
            enableSubmit={enableSubmit()}
            submitButtonText={'Delete'}
            onSubmit={onHandleDelete}
            onButtonClick={onButtonClick}
            onModalConcluded={onDeleteMemberConcluded}>
            Are you sure you want to remove{' '}
            <BoldFont>
                {props.memberDetails.firstName} {props.memberDetails.lastName}
            </BoldFont>{' '}
            from the group <BoldFont>{groupContext.group?.name}</BoldFont>?
            <Spacer marginTop={10} />
            {theBusinessJustificationElement()}
        </ModalActionButton>
    );
}

export default ManageGroupMemberDeleteModalActionButton;
