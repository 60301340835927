import React, { useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { IEmployeeReview, IReviewPeriod } from 'clients/sca-client';
import { generalIsMountedCode } from 'utils/misc-utils';
import ScaClient from 'clients/sca-client';
import EmployeeClient from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import { Dictionary } from 'assets/constants/global-constants';
import { IBasicEmployee } from 'clients/employee-client';
import { Table } from 'components/common/table';
import { getScaEmployeeHistoryTableColumns } from 'components/sca/history/get-sca-employee-history-table-columns';
import { SortDescending, useSortColumnHandler, strCmp } from 'utils/sort-utils';
import { ProblemLoadingData } from 'components/common/problem-loading/problem-loading-data';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import { IEmployee } from 'clients/employee-client';
import { fetchAllReviewPeriods } from 'components/sca/sca-utils';

type IScaViewEmployeeHistoryProps = {
    employee: IEmployee | IBasicEmployee | undefined;
    // Show only this many history records
    displayThisMany?: number;
    // When false, performs one fetch.
    // When true, keeps fetching until continuation token is empty.
    shouldFetchAllHistory: boolean;
    shouldHideReviewComments: boolean;
};

export default function EmployeeHistoryTable(props: IScaViewEmployeeHistoryProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler(
        'Period',
        SortDescending,
    );

    const [employeeHistory, setEmployeeHistory] = useState<IEmployeeReview[]>([]);
    const [reviewPeriodsDict, setReviewPeriodsDict] = useState<Dictionary<IReviewPeriod>>({});
    const [employees, setEmployees] = useState<Dictionary<IBasicEmployee>>({});
    const [isProblemLoading, setIsProblemLoading] = useState<boolean>(false);
    const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
    const [redirectTo, setRedirectTo] = useState<string>();

    useEffect(() => {
        return generalIsMountedCode(fetchEmployeeRecord);
    }, [props.employee?.id]);

    useEffect(() => {
        if (redirectTo) {
            setRedirectTo('');
            setIsInitialLoading(true);
        }
    }, [redirectTo]);

    const fetchEmployeeRecord = async (isMountedFunc: () => boolean): Promise<void> => {
        if (!props.employee?.id) {
            return;
        }
        try {
            let employeeHistoryVar: IEmployeeReview[];
            if (props.shouldFetchAllHistory) {
                employeeHistoryVar = await ScaClient.getAllScaReviewsForPersonnel(
                    authContext,
                    props?.employee?.id,
                    props.shouldHideReviewComments,
                );
            } else {
                // The first intended application of this part of the code is the
                // "View Details" part of "SCA -> My Team" page.
                // The reason it doesn't take continuation token into account is
                // the intended application which is to show the first few lines of
                // history on a modal for user's quick reference. Then, if the user
                // wants to see the full history, they'll click on an appropriate
                // link on the modal, which will take them to the full history page
                // where the then clause of this if statement will run and will
                // take continuation token into account.
                const pagedResult = await ScaClient.getScaReviewsForPersonnel(
                    authContext,
                    props?.employee?.id,
                    '',
                    props.shouldHideReviewComments,
                );
                employeeHistoryVar = pagedResult.results;
            }

            const employeesAliasesResults = employeeHistoryVar.map((r) =>
                r.reviewedBy?.replace(/@.*/, ''),
            );
            const fetchTheseEmployees = employeesAliasesResults.filter(
                (e, ix, arr) => !employees[e] && arr.indexOf(e) === ix,
            );
            const employeesResults = await EmployeeClient.getBasicEmployeesByAlias(
                authContext,
                fetchTheseEmployees,
            );
            const employeesVar = employeesResults.reduce((employeesVar, e) => {
                employeesVar[e.userPrincipalName?.replace(/@.*/, '').toLowerCase()] = e;
                return employeesVar;
            }, employees);

            if (isMountedFunc()) {
                if (props.displayThisMany && props.displayThisMany > 0) {
                    setEmployeeHistory(employeeHistoryVar.slice(0, props.displayThisMany));
                } else {
                    setEmployeeHistory(employeeHistoryVar);
                }
                setEmployees(employeesVar);
            }
        } catch (e) {
            console.error(e);
            if (isMountedFunc()) setIsProblemLoading(true);
        } finally {
            if (isMountedFunc()) setIsInitialLoading(false);
        }
    };

    const fetchReviewPeriods = async (isMountedFunc: () => boolean): Promise<void> => {
        try {
            const periods = await fetchAllReviewPeriods(authContext);
            const periodsDictVar = periods.reduce((curPeriodDict, period) => {
                curPeriodDict[period.id] = period;
                return curPeriodDict;
            }, {} as Dictionary<IReviewPeriod>);
            if (isMountedFunc()) {
                setReviewPeriodsDict(periodsDictVar);
            }
        } catch {
            console.error('Could not fetch review periods');
        }
    };

    useEffect(() => {
        return generalIsMountedCode(fetchReviewPeriods);
    }, []);

    const sortTableRows = (): IEmployeeReview[] => {
        type R = IEmployeeReview;
        const determineSortFunc = (): ((r1: R, r2: R) => number) => {
            switch (sortColumn) {
                case 'Period':
                    return (r1: R, r2: R) =>
                        sortAscending *
                        (reviewPeriodsDict[r1.reviewPeriodId]?.startDateUTC -
                            reviewPeriodsDict[r2.reviewPeriodId]?.startDateUTC);
                case 'Status':
                    return (r1: R, r2: R) => sortAscending * strCmp(r1.reviewState, r2.reviewState);
                case 'Rate':
                    return (r1: R, r2: R) => sortAscending * (r1.rate - r2.rate);
                case 'Reviewed On':
                    return (r1: R, r2: R) =>
                        sortAscending * (r1.reviewedTimestampUTC - r2.reviewedTimestampUTC);
                case 'Reviewed By':
                    return (r1: R, r2: R) => {
                        const r1ReviewedByAlias = r1.reviewedBy?.replace(/@.*/, '').toLowerCase();
                        const r2ReviewedByAlias = r2.reviewedBy?.replace(/@.*/, '').toLowerCase();
                        const r1ReviewedBy = employees[r1ReviewedByAlias]?.displayName ?? '';
                        const r2ReviewedBy = employees[r2ReviewedByAlias]?.displayName ?? '';
                        return sortAscending * strCmp(r1ReviewedBy, r2ReviewedBy);
                    };
                default:
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: R, r2: R) => 1;
            }
        };
        const sortFunc = determineSortFunc();
        return (employeeHistory || []).sort(sortFunc);
    };

    const tableColumns = getScaEmployeeHistoryTableColumns({
        sortColumn,
        sortAscending: sortAscending === 1,
        reviewPeriodsDict,
        hideReviewComments: props.shouldHideReviewComments,
        sortColumnHandler,
    });

    if (redirectTo) {
        return <Redirect push={true} to={redirectTo} />;
    } else {
        return (
            // SCA Auth
            <PageLoadingSpinner
                label='Loading history ...'
                ariaLive='assertive'
                isLoading={isInitialLoading}
                labelPosition='left'>
                <ProblemLoadingData isProblemLoadingData={isProblemLoading}>
                    <Table
                        rows={sortTableRows()}
                        tableColumns={tableColumns}
                        isFetchingData={false}
                        tableName='Employee History'
                    />
                </ProblemLoadingData>
            </PageLoadingSpinner>
        );
    }
}
