import React, { ReactNode, useState } from 'react';
import {
    ActionButton,
    PrimaryButton,
    IDialogContentProps,
    DialogFooter,
    DefaultButton,
    Dialog,
} from '@fluentui/react';

interface IDialogActionButtonProps {
    text: string; // The text that appears on the button, eg, "Add", "Delete", "View History".
    iconName?: string; // The icon that will appear next to the the text (props.text) on the button.
    buttonType?: 'primary'; // Default ActionButton
    children: ReactNode; // These will be shown on the dialog box.
    dialogContentProps?: IDialogContentProps;
    onPerformTask?: () => Promise<void>;
    performTaskText?: string;
    performTaskIcon?: string;
    onDontPerformTask?: () => void;
    dontPerformTaskText: string;
    dontPerformTaskIcon?: string;
}

export default function DialogActionButton(props: IDialogActionButtonProps): JSX.Element {
    const [isHideDialog, setIsHideDialog] = useState<boolean>(true);

    const onButtonClick = (): void => {
        setIsHideDialog(false);
    };

    const onDismissDialog = (): void => {
        setIsHideDialog(true);
        if (props.onDontPerformTask) {
            props.onDontPerformTask();
        }
    };

    const renderDialogActivationButton = (): ReactNode => {
        return React.createElement(props.buttonType === 'primary' ? PrimaryButton : ActionButton, {
            text: props.text,
            onClick: onButtonClick,
            iconProps: { iconName: props.iconName },
        });
    };

    return (
        <>
            {/* User needs to click on this button to open the dialog box. */}
            {/* Example usage: Clicking a delete button will open a dialog */}
            {/* box asking the user if they are sure they want to delete.  */}
            {renderDialogActivationButton()}
            <Dialog
                hidden={isHideDialog}
                onDismiss={onDismissDialog}
                dialogContentProps={props.dialogContentProps}>
                {props.children}
                <DialogFooter>
                    <DefaultButton
                        onClick={onDismissDialog}
                        text={props.dontPerformTaskText}
                        iconProps={{ iconName: props.dontPerformTaskIcon }}
                    />
                    {props.onPerformTask && props.performTaskText && (
                        <PrimaryButton
                            onClick={props.onPerformTask}
                            text={props.performTaskText}
                            iconProps={{ iconName: props.performTaskIcon }}
                        />
                    )}
                </DialogFooter>
            </Dialog>
        </>
    );
}
