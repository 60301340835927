import { IconNames } from 'assets/constants/global-constants';
import { IFacilityRecord } from 'clients/facilities-client';
import ModalActionButton, {
    ModalConclusion,
    onModalConcludeType,
} from 'components/common/buttons/modal-action-button';
import { TextField } from '@fluentui/react';
import React, { useState } from 'react';
export interface FacilitiesReservationCalendarModalProps<T> {
    renderWithoutButton?: boolean;
    selectedFacility: IFacilityRecord;
    onModalConcluded: onModalConcludeType<T>;
    icmDescription: string | undefined;
    setIcmDescription: (icm: string | undefined) => void;
}

export default function FacilitiesReservationCalendarModal<T>(
    props: FacilitiesReservationCalendarModalProps<T>,
): JSX.Element {
    const [errorMsg, setErrorMsg] = useState<string>();

    const onSubmit = async (): Promise<void> => {
        // do nothing
    };

    function isSubmitButtonEnabled(): boolean {
        return props.icmDescription !== undefined && props.icmDescription.trim() !== '';
    }

    function getIcmDescriptionTextBox(): JSX.Element {
        return (
            <TextField
                required
                label='ICM # / Description'
                value={props.icmDescription || ''}
                maxLength={255}
                onChange={(e, value): void => {
                    props.setIcmDescription(value);
                }}
            />
        );
    }

    function onModalConcluded(modalConclusion: ModalConclusion): void {
        props.onModalConcluded(modalConclusion);
    }

    return (
        <ModalActionButton
            text={'Reserve Seat with ICM #'}
            iconName={IconNames.AirTickets}
            submitButtonIcon={IconNames.AirTickets}
            modalTitle={'Confirming your reservation'}
            renderWithoutButton={props.renderWithoutButton}
            isBlocking={true}
            onSubmit={onSubmit}
            enableSubmit={isSubmitButtonEnabled()}
            errorMsg={errorMsg}
            onModalConcluded={onModalConcluded}>
            {getIcmDescriptionTextBox()}
        </ModalActionButton>
    );
}
