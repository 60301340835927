import React, { useContext, useEffect, useState } from 'react';
import {
    IDropdownOption,
    ComboBox,
    IComboBoxOption,
    IComboBox,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import { noDataText } from 'assets/constants/global-constants';
import EmployeeClient, { IBasicEmployee } from 'clients/employee-client';
import { AuthContext, IAuthContext } from 'contexts/auth-context';
import { FiltersContext } from 'contexts/filters-context';
import { dropdownComboBox } from 'components/screening/common/filters/common-filter-styling';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
export interface ProcessOwnerDropdownFilterProps {
    rawCandidates: ICommonScreening[];
    onFilterStateChange: (processOwnerSelectedArr: string[], adding: boolean) => void;
}

export function ProcessOwnerDropdownFilter(props: ProcessOwnerDropdownFilterProps): JSX.Element {
    const [message, setMessage] = useState<string>('');
    const [hasMessage, setHasMessage] = useState(false);
    const [processOwnerOptions, setProcessOwnerOptions] = useState<IDropdownOption[]>([]);
    const filtersContext = useContext(FiltersContext);
    const authContext = useContext(AuthContext);
    const placeHolder = 'Process Owner';
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    useEffect(() => {
        if (filtersContext.filterCleared) setSelectedKeys([]);
    }, [filtersContext.filterCleared]);

    useEffect(() => {
        let isMounted = true;

        async function setupProcessOwners(
            authContext: IAuthContext,
            screenings: ICommonScreening[],
        ): Promise<void> {
            // Several screenings could have the same process owner
            const uniqueProcessOwnerIds = new Set<string>();
            screenings.forEach((x) => {
                // A screening may not have a process owner assigned
                if (x.processOwnerId) {
                    uniqueProcessOwnerIds.add(x.processOwnerId);
                }
            });

            const result = await EmployeeClient.getBasicEmployeesById(
                authContext,
                Array.from(uniqueProcessOwnerIds),
            );
            // exclude process owners that are not active from the list and sort it alphabetically
            const filteredResult = result.filter((x) => x.isActiveEmployee);
            filteredResult.sort(compareProcessOwnerNames);

            const newProcessOwnerOptions: IDropdownOption[] = [];
            filteredResult.forEach((po) => {
                newProcessOwnerOptions.push({
                    key: po.id,
                    text: po.displayName,
                    data: po,
                });
            });
            newProcessOwnerOptions.push({
                key: noDataText,
                text: noDataText,
                data: null,
            });

            if (isMounted) {
                setProcessOwnerOptions(newProcessOwnerOptions);
            }
        }

        if (props?.rawCandidates?.length > 0) {
            setupProcessOwners(authContext, props.rawCandidates);
        }

        return (): void => {
            isMounted = false;
        };
    }, [props.rawCandidates]);

    return (
        <>
            <ComboBox
                ariaLabel='Process Owners'
                multiSelect
                selectedKey={selectedKeys}
                placeholder={placeHolder}
                multiSelectDelimiter='; '
                autoComplete='on'
                options={processOwnerOptions}
                onChange={onSelectedCandidateChange}
                useComboBoxAsMenuWidth
                allowFreeform
                styles={dropdownComboBox}
            />
            {hasMessage && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    dismissButtonAriaLabel='Close'
                    overflowButtonAriaLabel='See more'
                    onDismiss={onDismissMessage}>
                    {message}
                </MessageBar>
            )}
        </>
    );

    function compareProcessOwnerNames(a: IBasicEmployee, b: IBasicEmployee): number {
        const aUpper = a.displayName.toUpperCase();
        const bUpper = b.displayName.toUpperCase();
        if (aUpper < bUpper) return -1;
        if (aUpper > bUpper) return 1;
        return 0;
    }

    function onDismissMessage() {
        setHasMessage(false);
        setMessage('');
    }

    function onSelectedCandidateChange(
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
    ): void {
        if (option) {
            try {
                const isAdding = !!option.selected;
                let newlySelected: string[] = [];
                setSelectedKeys((prevSelectedKeys) => {
                    newlySelected = isAdding
                        ? [...prevSelectedKeys, option!.key as string]
                        : prevSelectedKeys.filter((k) => k !== option!.key);
                    return newlySelected;
                });
                const processOwnerID = option?.key === noDataText ? option.key : option.data.id;
                // we need to set this to something unique in the event that the
                // same process owner is toggled on/off in succession
                filtersContext.setProcessOwnerId(`${processOwnerID} ${Date.now()}`);
                props.onFilterStateChange(newlySelected, !!option.selected);
            } catch (error) {
                setMessage(
                    'An error occurred while loading the selected ' +
                        { placeHolder } +
                        ' information.',
                );
                setHasMessage(true);
            }
        } else {
            filtersContext.setProcessOwnerId('');
            props.onFilterStateChange([], false);
        }
    }
}

export default ProcessOwnerDropdownFilter;
