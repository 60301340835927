import React, { useContext, useState } from 'react';
import {
    mergeStyleSets,
    PrimaryButton,
    FontWeights,
    FontSizes,
    DefaultButton,
    Separator,
} from '@fluentui/react';

import { SharedColors } from '@fluentui/theme';
import CloudScreeningClient, {
    ICloudScreening,
    IScopeRecord,
} from 'clients/cloud-screening-client';
import { AuthContext } from 'contexts/auth-context';
import { IEmployee } from 'clients/employee-client';
import { useFeatureFlag } from 'utils/use-feature-flags';

export interface RequestCloudScreeningModalProps {
    onClose: (screen?: ICloudScreening) => void;
    isFirstAdvantage: boolean;
    myScopes: IScopeRecord[];
    viewedEmployeeSecure: IEmployee | undefined;
}

export default function RequestCloudScreeningModal(
    props: RequestCloudScreeningModalProps,
): JSX.Element {
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
    const authContext = useContext(AuthContext);

    const isBackgroundCheckEnabled = useFeatureFlag('cloudScreening/hireRightApi').enabled;

    const isFirstAdvantage = props.isFirstAdvantage;

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <span>Request Cloud Screening</span>
            </div>
            <Separator styles={separatorStyles} alignContent='start'>
                Attention!
            </Separator>
            {!isFirstAdvantage && (
                <div className={styles.body}>
                    You MUST submit your background check information within 10 days of receipt or
                    your request will be canceled. The email is sent from HireRight Customer Support
                    (<a href='mailto:noreply@hireright.com'>noreply@hireright.com</a>) with
                    instructions on how to complete your application. Please submit your form within
                    10 days!
                </div>
            )}
            {isFirstAdvantage && (
                <div className={styles.body}>
                    You MUST submit your background check information within 10 days of receipt or
                    your request will be canceled. The email is sent from First Advantage (
                    <a href='mailto:MicrosoftBackgroundCheck@fadv.com'>
                        MicrosoftBackgroundCheck@fadv.com
                    </a>
                    ) with instructions on how to complete your application. Please submit your form
                    within 10 days!
                </div>
            )}

            <div className={styles.footer}>
                <DefaultButton
                    className={styles.closeBtn}
                    onClick={(): void => {
                        props.onClose();
                    }}
                    text='Cancel'
                    allowDisabledFocus
                />
                <PrimaryButton
                    iconProps={{ iconName: 'ReminderPerson' }}
                    onClick={async (): Promise<void> => {
                        if (!props.viewedEmployeeSecure) {
                            console.error(
                                `requestCloudScreeningModal: props.viewedEmployeeSecure was undefined`,
                            );
                            return;
                        }
                        setIsSubmitDisabled(true);

                        try {
                            await CloudScreeningClient.createBackgroundCheckRequest(
                                authContext,
                                props.viewedEmployeeSecure.id,
                                isBackgroundCheckEnabled,
                            );
                        } catch (err) {
                            console.error('Error creating background check request', err);
                            // Prevent screening record from being created if a background check request
                            // couldn't be created because submitting a background check request dependends on
                            // visibility of the button. If background check request is not created and screening
                            // request is, the UI will no longer show the button, making it difficult to realize
                            // that one needs to be created, and also making it difficult to create one.
                            props.onClose();
                            return;
                        }

                        try {
                            const scopes = props.myScopes.map((x) => x.scope);
                            const screen = await CloudScreeningClient.createScreening(
                                authContext,
                                props.viewedEmployeeSecure.id,
                                scopes,
                            );
                            props.onClose(screen);
                        } catch (err) {
                            console.error('Error creating screening', err);
                            props.onClose();
                        }
                    }}
                    text='Request Screening'
                    disabled={isSubmitDisabled}
                    allowDisabledFocus
                />
            </div>
        </div>
    );
}

const separatorStyles = {
    root: {
        margin: '5px 30px 5px 19px',
        fontWeight: FontWeights.semibold,
    },
};

const styles = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 870,
    },
    header: {
        flex: '1 1 auto',
        borderTop: `4px solid ${SharedColors.cyanBlue10}`,
        display: 'flex',
        fontSize: FontSizes.xLargePlus,
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '25px 30px 0px 30px',
        boxSizing: 'border-box',
    },
    body: {
        padding: '0 30px 30px 30px',
        selectors: {
            'a': {
                'color': '#0078d4',
            },
        },
    },
    footer: {
        backgroundColor: 'rgb(253, 253, 253)',
        padding: '14px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    closeBtn: {
        marginRight: '10px',
    },
});
