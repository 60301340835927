import React, { useCallback, useContext, useState } from 'react';
import Modal, { ModalSizeType } from 'components/common/modal';
import { IFileInfo } from 'components/common/buttons/upload-csv-button';
import { MessageBar, MessageBarType } from '@fluentui/react';
import Spacer from 'components/common/spacer';
import pluralize from 'pluralize';
import { AuthContext } from 'contexts/auth-context';
import { convertCSVToArray } from 'utils/string-utils';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { replaceTemplatedTimeStringInDownloadReport } from 'utils/reporting-utils';
import { ChooseFileButton } from 'components/common/buttons/choose-file-button';
import {
    UPLOAD_STATUS_COLUMN_NAME,
    UploadColumnParams,
    BulkUploadResult,
    checkUploadFileData,
    convertDateStringsToLocaleDateString,
} from 'utils/upload-utils';
import { ClearanceClient, IClearanceRecordUploadRequest } from 'clients/clearance-client';
import { dateStringToIsoString, validateDateString } from 'utils/time-utils';

type ClearanceBulkUploadModalPropsType = {
    hideUploadModal: () => void;
};

type MessagesType = {
    infoMessage?: string;
    errorMessage?: string;
    successMessage?: string;
};

enum DateHeaders {
    investigatedOn = 'Investigated on',
    grantedOn = 'Granted on',
    briefedOn = 'Briefed on',
    nextBriefedOn = 'Next briefed on',
    sF312BriefedOn = 'SF312 briefed on',
    debriefedOn = 'Debriefed on',
    ceEnrollmentDate = 'Ce enrollment date',
    ceUnenrollmentDate = 'Ce unenrollment date',
}

const clearanceUploadColumnParams: UploadColumnParams<IClearanceRecordUploadRequest>[] = [
    {
        name: 'microsoftEmployeeOrVendor',
        header: 'Microsoft employee or vendor',
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest) =>
            ['true', 'false', 'yes', 'no'].includes(row.microsoftEmployeeOrVendor.toLowerCase()),
    },
    { name: 'id', header: 'Id', isColumnRequired: false, isValid: () => true },
    {
        name: 'personnelId',
        header: 'Personnel id',
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest): boolean => {
            return !!row.personnelId;
        },
    },
    {
        name: 'microsoftAlias',
        header: 'Microsoft alias',
        isColumnRequired: true,
        isValid: () => true,
    },
    { name: 'firstName', header: 'First name', isColumnRequired: true, isValid: () => true },
    { name: 'lastName', header: 'Last name', isColumnRequired: true, isValid: () => true },
    {
        name: 'clearanceLevel',
        header: 'Clearance level',
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest): boolean => {
            return !!row.clearanceLevel;
        },
    },
    {
        name: 'agency',
        header: 'Agency',
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest): boolean => {
            return !!row.agency;
        },
    },
    {
        name: 'status',
        header: 'Status',
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest): boolean => {
            return !!row.status;
        },
    },
    {
        name: 'clearanceType',
        header: 'Clearance type',
        isColumnRequired: true,
        isValid: () => true,
    },
    {
        name: 'clearanceBasis',
        header: 'Clearance basis',
        isColumnRequired: true,
        isValid: () => true,
    },
    {
        name: 'investigatedOn',
        header: DateHeaders.investigatedOn,
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest) =>
            validateDateString(row.investigatedOn, true),
        transform: (value: string): string => dateStringToIsoString(value),
    },
    {
        name: 'grantedOn',
        header: DateHeaders.grantedOn,
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest) => validateDateString(row.grantedOn, true),
        transform: (value: string): string => dateStringToIsoString(value),
    },
    {
        name: 'briefedOn',
        header: DateHeaders.briefedOn,
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest) => validateDateString(row.briefedOn, true),
        transform: (value: string): string => dateStringToIsoString(value),
    },
    {
        name: 'nextBriefedOn',
        header: DateHeaders.nextBriefedOn,
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest) =>
            validateDateString(row.nextBriefedOn, true),
        transform: (value: string): string => dateStringToIsoString(value),
    },
    {
        name: 'sF312BriefedOn',
        header: DateHeaders.sF312BriefedOn,
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest) =>
            validateDateString(row.sF312BriefedOn, true),
        transform: (value: string): string => dateStringToIsoString(value),
    },
    {
        name: 'debriefedOn',
        header: DateHeaders.debriefedOn,
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest) => validateDateString(row.debriefedOn, true),
        transform: (value: string): string => dateStringToIsoString(value),
    },
    {
        name: 'debriefJustification',
        header: 'Debrief justification',
        isColumnRequired: true,
        isValid: () => true,
    },
    {
        name: 'contract',
        header: 'Contract',
        isColumnRequired: true,
        isValid: () => true,
    },
    {
        name: 'jPASUpdateIneligible',
        header: 'JPAS update ineligible',
        isColumnRequired: true,
        isValid: () => true,
    },
    {
        name: 'continuousEvaluationType',
        header: 'Continuous evaluation type',
        isColumnRequired: true,
        isValid: () => true,
    },
    {
        name: 'ceEnrollmentDate',
        header: DateHeaders.ceEnrollmentDate,
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest) =>
            validateDateString(row.ceEnrollmentDate, true),
        transform: (value: string): string => dateStringToIsoString(value),
    },
    {
        name: 'ceUnenrollmentDate',
        header: DateHeaders.ceUnenrollmentDate,
        isColumnRequired: true,
        isValid: (row: IClearanceRecordUploadRequest) =>
            validateDateString(row.ceUnenrollmentDate, true),
        transform: (value: string): string => dateStringToIsoString(value),
    },
    {
        name: 'uploadStatus',
        header: 'Upload status',
        isColumnRequired: false,
        isValid: (row: IClearanceRecordUploadRequest) =>
            // Should not use !row.uploadStatus because this column a string and could be empty.
            // And the API endpoint cannot convert an empty string to the corresponding enum,
            // returning a 400 error.
            // The following checks for null or undefined, thus allowing a user to NOT specify the column in the
            // CSV file. But if the column is specified, the value must be one of "Success", "Failed", or "Skipped".
            row.uploadStatus === undefined ||
            row.uploadStatus === null ||
            ['success', 'failed', 'skipped'].includes(row.uploadStatus?.toLowerCase() ?? ''),
    },
    {
        name: 'uploadComment',
        header: 'Upload comment',
        isColumnRequired: false,
        isValid: () => true,
    },
];

export default function ClearanceBulkUploadModal(
    props: ClearanceBulkUploadModalPropsType,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const [messages, setMessages] = useState<MessagesType>({});
    const [requestData, setRequestData] = useState<IClearanceRecordUploadRequest[]>([]);
    const [isReadyToSubmit, setIsReadyToSubmit] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [showDownloadButton, setShowDownloadButton] = useState<boolean>(false);
    const [uploadResult, setUploadResult] = useState<BulkUploadResult[]>([]);

    const initialize = useCallback((): void => {
        setMessages({});
        setIsReadyToSubmit(false);
    }, []);

    const onFileLoadClick = useCallback((): void => {
        initialize();
    }, [initialize]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    const onFileLoaded = useCallback((fileContents: any[], fileInfo: IFileInfo): void => {
        const [row1, ...otherRows] = fileContents;
        const csvFileColumnHeaders = row1 as string[];
        const data = otherRows as string[][];
        if (data.length >= 1) {
            const lastRow = data[data.length - 1];
            if (lastRow.length <= 1) {
                data.pop(); // Turns out the last row is empty. Remove it.
            }
        }

        const {
            isDataItemsValid,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            columnIndexesResult: columnIndexes,
            errorMessage,
            badRowIx,
            badColumnHeader,
            requestData: requestDataVar,
        } = checkUploadFileData(
            data,
            clearanceUploadColumnParams,
            csvFileColumnHeaders,
            clearanceUploadColumnParams,
        );

        if (!isDataItemsValid) {
            if (badRowIx === -1) {
                setMessages({ errorMessage });
            } else {
                // badRowIx + 2 because rowIx is zero-based and we need to account for the header row.
                setMessages({
                    errorMessage: `Invalid data item in row ${
                        badRowIx + 2
                    } column "${badColumnHeader}".`,
                });
            }
            return;
        }

        const len = requestDataVar.length;
        setMessages({
            infoMessage: `Read ${len} ${pluralize('row', len)} of data, ready to upload.`,
        });
        setRequestData(requestDataVar);
        setIsReadyToSubmit(true);
    }, []);

    const onSubmit = useCallback(async (): Promise<void> => {
        if (hasSubmitted) {
            props.hideUploadModal();
            return;
        }
        setMessages({});
        try {
            setIsSubmitting(true);
            const submitResult = await ClearanceClient.uploadClearanceRecords(
                authContext,
                requestData,
            );
            const csvReplaced = replaceTemplatedTimeStringInDownloadReport(submitResult ?? '');
            const result = convertCSVToArray<BulkUploadResult>(csvReplaced);
            convertDateStringsToLocaleDateString(result, Object.values(DateHeaders));
            setUploadResult(result);
            if (result.length === 0) {
                setMessages({ successMessage: 'All records have already been uploaded.' });
            } else if (
                result.every((r) => {
                    return r[UPLOAD_STATUS_COLUMN_NAME] === 'Success';
                })
            ) {
                setMessages({
                    successMessage: `${result.length} ${pluralize(
                        'record',
                        result.length,
                    )} uploaded successfully`,
                });
            } else {
                const successCount = result.filter(
                    (r) => r[UPLOAD_STATUS_COLUMN_NAME] === 'Success',
                ).length;
                const skipCount = result.filter((r) => r[UPLOAD_STATUS_COLUMN_NAME] === 'Skipped')
                    .length;
                const failureCount = result.filter((r) => r[UPLOAD_STATUS_COLUMN_NAME] === 'Failed')
                    .length;
                setMessages(() => {
                    const messages = {
                        successMessage: '',
                        errorMessage: '',
                    };
                    if (successCount > 0) {
                        messages.successMessage = `${successCount} ${pluralize(
                            'record',
                            successCount,
                        )} uploaded successfully. `;
                    }
                    if (skipCount > 0) {
                        messages.successMessage += `${skipCount} ${pluralize(
                            'record',
                            skipCount,
                        )} skipped because they were marked "Success" or "Skipped" in the upload file.`;
                    }
                    if (failureCount > 0) {
                        messages.errorMessage = `${failureCount} ${pluralize(
                            'record',
                            failureCount,
                        )} failed to upload.`;
                    }
                    return messages;
                });
            }
            setShowDownloadButton(true);
        } catch (error) {
            setMessages({ errorMessage: `Error submitting bulk upload: ${error}` });
        } finally {
            setIsSubmitting(false);
            setHasSubmitted(true);
        }
    }, [authContext, hasSubmitted, props, requestData]);

    const getUploadResult = useCallback(async (): Promise<BulkUploadResult[]> => {
        return uploadResult;
    }, [uploadResult]);

    const onFileOpenError = useCallback((error: Error): void => {
        setMessages({ errorMessage: error.message });
    }, []);

    return (
        <Modal
            isOpen={true}
            size={ModalSizeType.size500}
            fixWidth={true}
            title='Upload Clearance'
            onCancel={props.hideUploadModal}
            isSubmitButtonDisabled={!isReadyToSubmit || isSubmitting}
            showProgressIndicator={isSubmitting}
            submitButtonText={hasSubmitted ? 'Close' : 'Upload'}
            shouldHideCancelButton={hasSubmitted}
            onSubmit={onSubmit}>
            <ChooseFileButton
                disabled={isSubmitting || hasSubmitted}
                acceptedFileTypes={['text/csv']}
                onFileLoaded={onFileLoaded}
                onError={onFileOpenError}
                onClick={onFileLoadClick}
            />
            <div>Upload CSV, max 1 file with 500 rows</div>
            {!!messages.infoMessage && (
                <div>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.info}>
                        <div>{messages.infoMessage}</div>
                    </MessageBar>
                </div>
            )}
            {!!messages.successMessage && (
                <div>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.success}>
                        <div>{messages.successMessage}</div>
                    </MessageBar>
                </div>
            )}
            {!!messages.errorMessage && (
                <div>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.error}>
                        <div>{messages.errorMessage}</div>
                    </MessageBar>
                </div>
            )}
            {showDownloadButton && uploadResult.length > 0 && (
                <>
                    <Spacer marginTop={10} />
                    <ExportToExcelButton<BulkUploadResult>
                        buttonTitle='Download report'
                        buttonType='default'
                        getData={getUploadResult}
                        fileNamePrefix='clearance-upload'
                        formatHeader={false}
                    />
                </>
            )}
        </Modal>
    );
}
