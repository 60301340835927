import {
    FontSizes,
    FontWeights,
    IContextualMenuProps,
    IconButton,
    getTheme,
    mergeStyleSets,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import formThumb from 'assets/img/form.png';
import { GenericForm, GenericFormRecord, formRespondBaseUrl } from 'clients/forms-client';
import {
    userDefinedGenericFormNames,
    formStateBadgeColors,
    genericFormRecordFormStates,
} from 'components/forms/forms-common';
import { UserContext } from 'contexts/user-context';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { BrowserCacheKeys } from 'utils/browser-cache-utils';

const extractInnerContent = (html: string): string => {
    return new DOMParser().parseFromString(html, 'text/html').body.textContent ?? '';
};

type FormTileProps = {
    form: GenericForm;
    formRecords?: GenericFormRecord[];
    teachingBubbleId: string;
    setSelectedForm: (selectedForm: GenericForm) => void;
    setShowDuplicateModal: (showDuplicateModal: boolean) => void;
    setShowDecisionModal: (showDecisionModal: boolean) => void;
    setShowUnavailableFormError: (showUnavailableFormError: boolean) => void;
    setShowBubble: (showBubble: boolean) => void;
    showBubble: boolean;
};

export function FormTile(props: FormTileProps): JSX.Element {
    const {
        form,
        formRecords,
        teachingBubbleId,
        setSelectedForm,
        setShowDuplicateModal,
        setShowDecisionModal,
        setShowUnavailableFormError,
        setShowBubble,
        showBubble,
    } = props;

    const userContext = useContext(UserContext);
    const history = useHistory();

    const tileTeachingBubbleId = 'tileTeachingBubbleId';
    const hasDraft = useMemo((): boolean => {
        return formRecords
            ? formRecords.some(
                  (x) =>
                      x.name === form.name &&
                      x.formState.toLowerCase() === genericFormRecordFormStates.draft,
              )
            : false;
    }, [form.name, formRecords]);

    const previousRecords = useMemo((): GenericFormRecord[] | undefined => {
        return formRecords?.filter(
            (record) =>
                record.name === form.name &&
                (record.formState.toLowerCase() === genericFormRecordFormStates.completed ||
                    record.formState.toLowerCase() === genericFormRecordFormStates.accepted),
        );
    }, [form.name, formRecords]);

    const createNew = (): void => {
        history.push(`${formRespondBaseUrl}/${form?.name}`);
    };

    const navigateToDraft = (): void => {
        const draft = formRecords?.find(
            (x) =>
                x.name === form.name &&
                x.formState.toLowerCase() === genericFormRecordFormStates.draft,
        );
        if (draft) {
            history.push(`${formRespondBaseUrl}/${draft?.name}/${draft?.id}`);
        }
    };

    const hasPreviousRecords = useMemo((): boolean => {
        return previousRecords ? previousRecords?.length > 0 : false;
    }, [previousRecords]);

    const menuItems = (): IContextualMenuProps => {
        const menuItems = {
            items: [
                {
                    key: 'new',
                    text: 'New',
                    onClick: createNew,
                    iconProps: { iconName: 'Glimmer' },
                },
                {
                    key: 'continue',
                    text: 'Continue draft',
                    disabled: !hasDraft,
                    onClick: navigateToDraft,
                    iconProps: { iconName: 'Edit' },
                },
                {
                    key: 'duplicate',
                    text: 'Duplicate',
                    disabled: !hasPreviousRecords,
                    onClick: (): void => {
                        setSelectedForm(form);
                        setShowDuplicateModal(true);
                    },
                    iconProps: { iconName: IconNames.Copy },
                },
            ],
        };

        return menuItems;
    };

    const onTileClicked = (): void => {
        // TODO: Migrate to long term solution documented here:
        // Forms: Settings / Block v-/b-/t-/a- users from submitting form
        // https://msazure.visualstudio.com/Microsoft%20Personnel/_workitems/edit/25160328
        if (
            form.name.toLowerCase() === userDefinedGenericFormNames.citizenshipVerification &&
            userContext.employeeRecord.alias.toLowerCase().includes('v-')
        ) {
            setShowUnavailableFormError(true);
            return;
        }

        if (hasDraft) {
            setSelectedForm(form);
            setShowDecisionModal(true);
        } else {
            history.push(`${formRespondBaseUrl}/${form.name}`);
        }
    };

    return (
        <>
            <div key={form.id} className={styles.tileStyle} onClick={onTileClicked} tabIndex={0}>
                <div className={styles.imageContainer}>
                    <img
                        title={form.title ?? 'Form title'}
                        className={styles.image}
                        src={form.image ?? formThumb}
                        alt={form.title ?? 'Form title'}
                    />
                    {hasDraft && <div className={styles.draftBadge}>Draft started</div>}
                </div>

                <div className={styles.innerTileContainer}>
                    <h3 className={styles.title}>{form.title}</h3>
                    <IconButton
                        id={teachingBubbleId}
                        iconProps={{
                            iconName: 'More',
                            className: styles.icon,
                        }}
                        onRenderMenuIcon={(): JSX.Element => {
                            return <></>;
                        }}
                        menuProps={menuItems()}
                        ariaLabel='More'
                        onMenuClick={(): void => {
                            // workaround: count and dismiss teaching bubble on menu click
                            if (teachingBubbleId === tileTeachingBubbleId && showBubble) {
                                const showTeachingBubbleCount =
                                    localStorage.getItem(BrowserCacheKeys.formsTeachingBubble) ??
                                    '0';
                                localStorage.setItem(
                                    BrowserCacheKeys.formsTeachingBubble,
                                    `${parseInt(showTeachingBubbleCount) + 1}`,
                                );
                                setShowBubble(false);
                            }
                        }}
                    />
                </div>
                <div className={styles.subTitle} style={{ WebkitLineClamp: 2 }}>
                    {form.subtitle ?? extractInnerContent(form.description)}
                </div>
            </div>
        </>
    );
}

const styles = mergeStyleSets({
    icon: {
        color: '#000',
        fontWeight: 'bold',
    },
    innerTileContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '5px',
    },
    imageContainer: {
        display: 'flex',
    },
    createNewFormWarning: {
        color: 'red',
        fontWeight: 'bold',
    },
    title: {
        fontSize: '16px',
        fontWeight: FontWeights.bold,
        marginBottom: 0,
        marginTop: '.75rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    subTitle: {
        fontSize: FontSizes.medium,
        marginTop: '1rem',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
    },
    image: {
        maxHeight: '10.3rem',
        width: '100%',
        aspectRatio: '3 / 2',
        objectFit: 'cover',
    },
    availableContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem',
        justifyContent: 'flex-start',
    },
    searchBox: {
        margin: '1rem 0 1rem auto',
        width: '17rem',
    },
    containerWidthOnViewport: {
        width: '1485px',
        selectors: {
            '@media (max-width: 1528px)': {
                width: '1106px',
            },
            '@media (max-width: 1152px)': {
                width: '727px',
            },
            '@media (max-width: 824px)': {
                width: '348px',
            },
        },
    },
    centered: {
        margin: '0 auto',
    },
    oldTileStyle: {
        padding: '.75rem',
        width: '20.2rem',
        maxHeight: '16.87rem',
        cursor: 'pointer',
        paddingBottom: '.25rem',
        selectors: {
            ':hover': {
                position: 'relative',
                boxShadow: getTheme().effects['elevation8'],
                transition: 'box-shadow 300ms ease 0s',
                borderRadius: '2px',
                backgroundColor: 'white',
            },
        },
    },
    tileStyle: {
        width: '20.2rem',
        cursor: 'pointer',
        padding: '0.75rem',
        borderRadius: '4px',
        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.15), 0px 3px 8px 0px rgba(0, 0, 0, 0.14)',
        selectors: {
            ':hover': {
                background: '#f5f5f5',
                boxShadow: '0 4px 8px #00000024, 0 0 2px #0000001f',
            },
        },
    },
    draftBadge: {
        backgroundColor: formStateBadgeColors.yellow,
        color: '#201F1E',
        width: '6.7rem',
        textAlign: 'center',
        borderRadius: '4px',
        height: '1.69rem',
        lineHeight: '1.69rem',
        fontWeight: 500,
        position: 'absolute',
        margin: '0.5rem',
    },
});
