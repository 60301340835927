import React from 'react';

import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { IColumn, mergeStyles } from '@fluentui/react';
import { getProvisionTypeColor, IFacilityRecord, ISeatRecord } from 'clients/facilities-client';
import { maxWidthCoeff } from 'assets/constants/global-constants';
import { TableCell } from 'components/common/table';
import { ISortableColumns } from 'utils/sort-utils';
import Badge from 'components/common/badge';
import { TimeFormats } from 'utils/time-utils';
import { handleUTCToFacilityTimeZone } from 'components/facilities/common/facility-time-utils';
import { lastColumnStylesButton } from 'assets/styles/list-styles';
import AddEditSeatModal from 'components/facilities/facilities-seats/modals/add-edit-seats-modal';
import { onModalConcludeType } from 'components/common/buttons/modal-action-button';
import DeleteSeatModal from 'components/facilities/facilities-seats/modals/delete-seats-modal';

export interface SeatsColumnProps extends ISortableColumns {
    facility?: IFacilityRecord;
    seats?: ISeatsColumns[];
    onModalConcluded?: onModalConcludeType<ISeatRecord | undefined>;
    onDeleteRecordConcluded: (attribute: ISeatRecord) => void;
    onAddEditRecordConcluded: (results: ISeatRecord[]) => void;
}

export interface ISeatsColumns {
    seatRecord: ISeatRecord;
}

export const seatsColumnNames = {
    Name: 'Name',
    IsOutOfOrder: 'Is Out of Order',
    ProvisionType: 'Provision Type',
    OccupiedBy: 'Occupied By',
    OccupiedAt: 'Occupied At',
    LastModifiedBy: 'Last Modified By',
    LastModifiedAt: 'Last Modified At',
    Actions: 'Actions',
};

export function seatsTableColumns(props: SeatsColumnProps): IColumn[] {
    const columnWidths = {
        name: 100,
        isOutOfOrder: 100,
        provisionType: 100,
        occupiedBy: 100,
        occupiedAt: 150,
        hasReservation: 100,
        lastModifiedBy: 100,
        lastModifiedAt: 200,
        actions: 200,
    };

    const columns: IColumn[] = [
        {
            key: seatsColumnNames.Name,
            name: seatsColumnNames.Name,
            ariaLabel: seatsColumnNames.Name,
            minWidth: columnWidths.name,
            maxWidth: columnWidths.name * maxWidthCoeff,
            isSorted: props.sortColumn === seatsColumnNames.Name,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(seatsColumnNames.Name);
            },
            onRender: (row: ISeatsColumns): JSX.Element => {
                return <TableCell>{row.seatRecord.seatName}</TableCell>;
            },
        },
        {
            key: seatsColumnNames.IsOutOfOrder,
            name: seatsColumnNames.IsOutOfOrder,
            ariaLabel: seatsColumnNames.IsOutOfOrder,
            minWidth: columnWidths.isOutOfOrder,
            maxWidth: columnWidths.isOutOfOrder * maxWidthCoeff,
            isSorted: props.sortColumn === seatsColumnNames.IsOutOfOrder,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(seatsColumnNames.IsOutOfOrder);
            },
            onRender: (row: ISeatsColumns): JSX.Element => {
                return <TableCell>{row.seatRecord.isOutOfOrder ? 'YES' : 'NO'}</TableCell>;
            },
        },
        {
            key: seatsColumnNames.ProvisionType,
            name: seatsColumnNames.ProvisionType,
            ariaLabel: seatsColumnNames.ProvisionType,
            minWidth: columnWidths.provisionType,
            maxWidth: columnWidths.provisionType * maxWidthCoeff,
            isSorted: props.sortColumn === seatsColumnNames.ProvisionType,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(seatsColumnNames.ProvisionType);
            },
            onRender: (row: ISeatsColumns): JSX.Element => {
                return (
                    <TableCell>
                        <Badge
                            text={row.seatRecord.provisionInfo.provisionType}
                            backgroundColor={getProvisionTypeColor(
                                row.seatRecord.provisionInfo.provisionType,
                            )}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: seatsColumnNames.OccupiedBy,
            name: seatsColumnNames.OccupiedBy,
            ariaLabel: seatsColumnNames.OccupiedBy,
            minWidth: columnWidths.occupiedBy,
            maxWidth: columnWidths.occupiedBy * maxWidthCoeff,
            isSorted: props.sortColumn === seatsColumnNames.OccupiedBy,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(seatsColumnNames.OccupiedBy);
            },
            onRender: (row: ISeatsColumns): JSX.Element => {
                return (
                    <TableCell>
                        {row.seatRecord.occupiedBy?.by && (
                            <EmployeeBasicHoverCard
                                key={row.seatRecord.occupiedBy.by}
                                personnelId={row.seatRecord.occupiedBy.by}
                            />
                        )}
                        {!row.seatRecord.occupiedBy?.by && <span>VACANT</span>}
                    </TableCell>
                );
            },
        },
        {
            key: seatsColumnNames.OccupiedAt,
            name: seatsColumnNames.OccupiedAt,
            ariaLabel: seatsColumnNames.OccupiedAt,
            minWidth: columnWidths.occupiedAt,
            maxWidth: columnWidths.occupiedAt * maxWidthCoeff,
            isSorted: props.sortColumn === seatsColumnNames.OccupiedAt,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(seatsColumnNames.OccupiedAt);
            },
            onRender: (row: ISeatsColumns): JSX.Element => {
                return (
                    <TableCell>
                        {row.seatRecord.occupiedBy?.atUtc &&
                            handleUTCToFacilityTimeZone(
                                row.seatRecord.occupiedBy.atUtc,
                                props.facility,
                                TimeFormats.MMMDDYYYY_hmmA,
                            )}
                    </TableCell>
                );
            },
        },
        {
            key: seatsColumnNames.LastModifiedBy,
            name: seatsColumnNames.LastModifiedBy,
            ariaLabel: seatsColumnNames.LastModifiedBy,
            minWidth: columnWidths.lastModifiedBy,
            maxWidth: columnWidths.lastModifiedBy * maxWidthCoeff,
            isSorted: props.sortColumn === seatsColumnNames.LastModifiedBy,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(seatsColumnNames.LastModifiedBy);
            },
            onRender: (row: ISeatsColumns): JSX.Element => {
                return (
                    <TableCell>
                        <EmployeeBasicHoverCard
                            key={row.seatRecord.lastModified.by}
                            personnelId={row.seatRecord.lastModified.by}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: seatsColumnNames.LastModifiedAt,
            name: seatsColumnNames.LastModifiedAt,
            ariaLabel: seatsColumnNames.LastModifiedAt,
            minWidth: columnWidths.lastModifiedAt,
            maxWidth: columnWidths.lastModifiedAt * maxWidthCoeff,
            isSorted: props.sortColumn === seatsColumnNames.LastModifiedAt,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(seatsColumnNames.LastModifiedAt);
            },
            onRender: (row: ISeatsColumns): JSX.Element => {
                return (
                    <TableCell>
                        {handleUTCToFacilityTimeZone(
                            row.seatRecord.lastModified.atUtc,
                            props.facility,
                            TimeFormats.MMMDDYYYY_hmmA,
                        )}
                    </TableCell>
                );
            },
        },
        {
            key: seatsColumnNames.Actions,
            name: seatsColumnNames.Actions,
            ariaLabel: seatsColumnNames.Actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions * maxWidthCoeff,
            ...lastColumnStylesButton,
            onRender: (row: ISeatsColumns): JSX.Element => {
                return (
                    <TableCell>
                        <div className={mergeStyles({ display: 'inline-block' })}>
                            {props.facility && (
                                <AddEditSeatModal
                                    key={row.seatRecord.id}
                                    facility={props.facility}
                                    seatRecord={row.seatRecord}
                                    upsertSeat={props.onAddEditRecordConcluded}
                                />
                            )}
                            <DeleteSeatModal
                                key={`delete-${row.seatRecord.id}`}
                                seat={row.seatRecord}
                                deleteSeat={props.onDeleteRecordConcluded}
                            />
                        </div>
                    </TableCell>
                );
            },
        },
    ];

    return columns;
}
