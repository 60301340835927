import {
    ActionButton,
    Dropdown,
    FontIcon,
    IDropdownOption,
    mergeStyles,
    Separator,
    TextField,
    Toggle,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import { globalSeparatorStyles } from 'assets/styles/global-styles';
import EllipsisText from 'components/common/ellipsis-text';
import { FocusContainer } from 'components/common/misc/focus-container';
import React, { useEffect, useMemo, useState } from 'react';
import {
    DragDropContext,
    Draggable,
    DraggableStateSnapshot,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';
import ElementFooter from 'components/forms/element-footer';
import { ElementTypeProps, TableOption, TableOptionTypes } from 'components/forms/forms-common';
import { reorder } from 'components/forms/forms-edit';

const baseTableElementStyles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '140px',
    padding: '10px 2px',
    cursor: 'pointer',
});

const selectedElementStyles = mergeStyles(baseTableElementStyles, {
    border: '1px rgb(0, 120, 212) solid',
    background: 'rgb(230, 237, 242)',
});

const nonSelectedElementStyles = mergeStyles(baseTableElementStyles, {
    border: '1px transparent solid',
});

const tableContainerStyles = mergeStyles({
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    height: '185px',
    gap: '15px',
    marginBottom: '2rem',
    marginTop: '1rem',
});

const nameTypeStyles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 'bold',
    gap: '25px',
    paddingTop: '82px',
});

const columnLabelStyles = mergeStyles({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingBottom: '1.5rem',
});

const columnOptionsStyles = mergeStyles({
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '1rem',
});

const horizontalDnDIconStyles = mergeStyles({
    transform: 'rotate(90deg)',
    marginLeft: '45%',
});

export default function TableElement(props: ElementTypeProps): JSX.Element {
    const { element, updateForm } = props;
    const [editingItem, setEditingItem] = useState<TableOption>();

    const getDefaultOption = () =>
        ({
            key: crypto.randomUUID(),
            text: 'Name',
            description: '',
            type: 'textfield',
            required: false,
        } as TableOption);

    useEffect(() => {
        if (!element.options) {
            const defaultOption = getDefaultOption();
            const initialOptions = [defaultOption];
            setEditingItem(defaultOption);
            updateForm(element.id, initialOptions, 'options');
        }
    }, [element.options]);

    const options = (element.options ?? []) as TableOption[];
    const editingItemIndex = options.findIndex((el) => el.key === editingItem?.key);

    const typeDropdownOptions: IDropdownOption[] = useMemo(
        () =>
            Object.keys(TableOptionTypes).map((type) => ({
                key: type,
                text: type,
            })),
        [],
    );

    const onDragEnd = (result: DropResult): void => {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        const items = reorder(options, result.source.index, result.destination.index);

        updateForm(element.id, items, 'options');
    };

    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        userSelect: 'none',
        // change background color if dragging
        background: isDragging ? 'rgba(0, 0, 0, .06)' : 'inherit',
        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const TableCell = (option: TableOption, index: number) => {
        return (
            <React.Fragment key={option.key}>
                <Separator styles={globalSeparatorStyles} vertical />
                <Draggable draggableId={option.key} index={index}>
                    {(provided: any, snapshot: DraggableStateSnapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                            )}>
                            <div {...provided.dragHandleProps}>
                                <FontIcon
                                    className={horizontalDnDIconStyles}
                                    title='Drag element'
                                    aria-label='Drag'
                                    iconName='GripperDotsVertical'
                                />
                            </div>
                            <div
                                className={
                                    option.key === editingItem?.key
                                        ? selectedElementStyles
                                        : nonSelectedElementStyles
                                }
                                tabIndex={0}
                                onFocus={() => setEditingItem(option)}
                                onClick={() => setEditingItem(option)}>
                                <div style={{ fontWeight: 'bold', marginBottom: '25px' }}>
                                    <span>Column {index + 1}</span>
                                    {option.required && (
                                        <span style={{ color: '#e50000' }}> *</span>
                                    )}
                                </div>
                                <div className={columnLabelStyles}>
                                    <EllipsisText
                                        text={option.text}
                                        textLengthBeforeEllipsis={18}
                                    />
                                </div>
                                <div>
                                    <span>{option.type}</span>
                                    {option.options?.length && (
                                        <span>{` (${option.options?.length})`}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </Draggable>
            </React.Fragment>
        );
    };

    const onLoseFocus = () => {
        if (editingItem) {
            const updatedItem = { ...editingItem };
            options[editingItemIndex] = updatedItem;
            updateForm(element.id, options, 'options');
        }
    };

    return (
        <div style={{ marginBottom: '1.5rem' }}>
            <TextField
                value={element.label}
                onChange={(ev, newValue): void => updateForm(element.id, newValue, 'label')}
                placeholder='Enter a label...'
                underlined
            />
            <TextField
                value={element.description}
                onChange={(ev, newValue): void => updateForm(element.id, newValue, 'description')}
                placeholder='Enter a description...'
            />

            <div className={tableContainerStyles}>
                <div className={nameTypeStyles}>
                    <span>Name</span>
                    <span>Type</span>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='list' direction='horizontal'>
                        {(provided: any) => (
                            <div
                                style={{ display: 'flex' }}
                                ref={provided.innerRef}
                                {...provided.droppableProps}>
                                {options.map((option, index) => TableCell(option, index))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <Separator styles={globalSeparatorStyles} vertical />
                <ActionButton
                    title='Add column'
                    aria-label='Add column'
                    iconProps={{ iconName: IconNames.Add }}
                    style={{ marginTop: '50px' }}
                    onClick={() => {
                        const newOption: TableOption = getDefaultOption();
                        const newOptions = options.concat(newOption);
                        updateForm(element.id, newOptions, 'options');
                        setEditingItem(newOption);
                    }}>
                    Add Column
                </ActionButton>
            </div>
            {editingItem && (
                <FocusContainer onLoseFocus={onLoseFocus}>
                    <div
                        style={{
                            display: 'flex',
                            gap: '2.5rem',
                            flexWrap: 'wrap',
                        }}>
                        <p style={{ fontStyle: 'italic' }}>{`Editing Column ${
                            editingItemIndex + 1
                        }`}</p>
                        <Toggle
                            checked={editingItem.required}
                            label={<div style={{ fontWeight: 400 }}>Required</div>}
                            onChange={(ev, checked) => {
                                if (checked !== undefined) {
                                    setEditingItem((prev) => {
                                        if (!prev) {
                                            return;
                                        }
                                        const newItem = { ...prev };
                                        newItem.required = checked;
                                        return newItem;
                                    });
                                }
                            }}
                        />
                        <ActionButton
                            style={{ marginLeft: 'auto' }}
                            title='Delete column'
                            aria-label='Delete column'
                            iconProps={{ iconName: 'Delete' }}
                            onClick={() => {
                                const newOptions = options.filter(
                                    (el) => el.key !== editingItem.key,
                                );
                                setEditingItem(undefined);
                                updateForm(element.id, newOptions, 'options');
                            }}
                        />
                    </div>
                    <div style={{ maxWidth: '400px' }}>
                        <TextField
                            label='Column Name'
                            value={editingItem.text}
                            onChange={(ev, newValue) =>
                                setEditingItem((prev) => {
                                    if (!prev) {
                                        return;
                                    }
                                    const newItem = { ...prev };
                                    newItem.text = newValue ?? '';
                                    return newItem;
                                })
                            }
                        />
                        <TextField
                            label='Column Description'
                            value={editingItem.description ?? ''}
                            onChange={(ev, newValue) =>
                                setEditingItem((prev) => {
                                    if (!prev) {
                                        return;
                                    }
                                    const newItem = { ...prev };
                                    newItem.description = newValue ?? '';
                                    return newItem;
                                })
                            }
                        />
                        <div
                            style={{
                                display: 'flex',
                                gap: '15px',
                                flexWrap: 'wrap',
                            }}>
                            <Dropdown
                                styles={{ root: { flexGrow: '2' } }}
                                label='Column Type'
                                placeholder='Select an option'
                                selectedKey={editingItem.type}
                                options={typeDropdownOptions}
                                onChange={(ev, newValue) =>
                                    setEditingItem((prev) => {
                                        if (
                                            !prev ||
                                            !newValue ||
                                            !(newValue.key in TableOptionTypes)
                                        ) {
                                            return;
                                        }
                                        const newItem: TableOption = { ...prev };
                                        newItem.type = newValue.key as keyof typeof TableOptionTypes;
                                        if (newItem.type === 'dropdown') {
                                            newItem.options = [
                                                {
                                                    key: crypto.randomUUID(),
                                                    text: 'Option',
                                                },
                                            ];
                                        } else {
                                            newItem.options = undefined;
                                        }
                                        return newItem;
                                    })
                                }
                            />
                            {editingItem.type === 'date' && (
                                <Toggle
                                    checked={editingItem.hasTime ?? false}
                                    label={<div style={{ fontWeight: 400 }}>Time</div>}
                                    onChange={(ev, checked): void => {
                                        const newItem = { ...editingItem };
                                        newItem.hasTime = checked;
                                        setEditingItem(newItem);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    {editingItem.options !== undefined && (
                        <>
                            {editingItem.options.map((option) => (
                                <div key={option.key} className={columnOptionsStyles}>
                                    <FontIcon
                                        style={{ padding: '4px 5px 0 0' }}
                                        iconName={IconNames.CaretDownSolid8}
                                    />
                                    <TextField
                                        style={{ width: '275px' }}
                                        value={option.text}
                                        onChange={(ev, newValue) => {
                                            const newOption = { ...option };

                                            newOption.text = newValue ?? '';
                                            const optionIndex = editingItem.options!.findIndex(
                                                (el) => el.key === option.key,
                                            );
                                            const updatedItem = { ...editingItem };
                                            updatedItem.options![optionIndex] = newOption;
                                            setEditingItem(updatedItem);
                                        }}
                                        underlined
                                        placeholder='Enter a label...'
                                    />
                                    <ActionButton
                                        title='Delete element'
                                        aria-label='Delete'
                                        iconProps={{ iconName: IconNames.Trash }}
                                        style={{ padding: '4px 5px 0 0' }}
                                        onClick={() => {
                                            const newOptions = editingItem.options!.filter(
                                                (el) => el.key !== option.key,
                                            );
                                            const updatedItem = { ...editingItem };
                                            updatedItem.options = newOptions;
                                            setEditingItem(updatedItem);
                                        }}
                                    />
                                </div>
                            ))}
                            <ActionButton
                                title='Add Option'
                                aria-label='Add Option'
                                style={{ marginBottom: '1rem' }}
                                iconProps={{ iconName: IconNames.Add }}
                                onClick={() => {
                                    const newOption = {
                                        key: crypto.randomUUID(),
                                        text: 'Option',
                                    };
                                    const newOptions = editingItem.options!.concat(newOption);
                                    const updatedItem = { ...editingItem };
                                    updatedItem.options = newOptions;
                                    setEditingItem(updatedItem);
                                }}>
                                Add Option
                            </ActionButton>
                        </>
                    )}

                    <div style={{ marginTop: '1rem' }}>
                        <ActionButton
                            iconProps={{ iconName: IconNames.CheckMark }}
                            onClick={() => {
                                onLoseFocus();
                                setEditingItem(undefined);
                            }}>
                            Accept
                        </ActionButton>
                    </div>
                </FocusContainer>
            )}
            <ElementFooter element={element} updateForm={updateForm} hasRequiredToggle={true} />
        </div>
    );
}
