import React, { useEffect, useState, useContext } from 'react';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { IconNames, Dictionary } from 'assets/constants/global-constants';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import ScaClient, {
    IEmployeeReview,
    EmployeeReviewStatusDisplayTexts,
    ReviewState,
    reviewStatusDisplayText,
} from 'clients/sca-client';
import { IBasicEmployee } from 'clients/employee-client';
import { IPersonaProps, Dropdown, IDropdownOption, TextField, mergeStyles } from '@fluentui/react';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { globalStyles } from 'assets/styles/global-styles';
import {
    translateReviewState,
    MinValidRate,
    MaxValidRate,
    OtherRate,
    rateSelectionOptions,
    encodeEmployeeReviewStateDisplayText,
    isSelectedStatusValid,
    employeeEligibleOption,
    employeeReviewStateOptions,
} from 'components/sca/sca-constants';
import ScaEmployeeEligibilityDialog, {
    useScaEmployeeEligibilityDialogUtils,
} from 'components/sca/my-team/sca-employee-eligibility-dialog';
import deepEqual from 'deep-equal';

interface IScaAddEmployeeModalProps {
    mode: 'add' | 'update';
    review?: IEmployeeReview;
    employee?: IBasicEmployee; // Either provide this one, or props.personnelId. This one takes precedence.
    noHoverCard?: boolean; // Default true
    personnelId?: string; // Either provide this one, or props.employee. props.employee takes precedence.
    showRateUpdate?: boolean;
    showStatusUpdate?: boolean;
    showEmployeeCard?: boolean;
    buttonText?: string;
    modalTitle?: JSX.Element | string;
    submitButtonText?: string;
    onAddEditEmployeeConcluded: (
        mode: 'add' | 'update',
        modalConclusion: ModalConclusion,
        result?: IEmployeeReview,
    ) => void;
}

export function ScaViewAddEmployeeModalButton(props: IScaAddEmployeeModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [rate, setRate] = useState<string | undefined>();
    const [comment, setComment] = useState<string | undefined>();
    const [errorMsg, setErrorMsg] = useState<string>();
    const [employee, setEmployee] = useState<Dictionary<string> | undefined>();
    const [selectedRate, setSelectedRate] = useState<IDropdownOption | undefined>();
    const [selectedStatus, setSelectedStatus] = useState<IDropdownOption | undefined>();
    const {
        isEmpEligNoticeDialogOpen,
        hideEligibilityNoticeDialog,
        showEligibilityNoticeDialog,
    } = useScaEmployeeEligibilityDialogUtils();

    const showRateUpdate = props.showRateUpdate ?? true;
    const showNoHoverCard = props.noHoverCard ?? true;
    const showStatusUpdate = props.showStatusUpdate ?? true;
    const showEmployeeCard = props.showEmployeeCard ?? true;

    const addMode = (): boolean => props.mode === 'add';
    const updateMode = (): boolean => props.mode === 'update';

    useEffect(() => {
        if (updateMode() && !props.employee && !props.personnelId) {
            setErrorMsg('Employee record not provided');
        }
    }, []);

    const initInputs = (): void => {
        if (props.review && (props.employee || props.personnelId)) {
            const rateStr = props.review.rate.toString();
            const rateOption =
                rateSelectionOptions.find((r) => r.key === rateStr) ||
                rateSelectionOptions.find((r) => r.key === OtherRate);
            const reviewState = EmployeeReviewStatusDisplayTexts().find(
                (thisReviewState) =>
                    translateReviewState(props?.review?.reviewState) === thisReviewState,
            );
            const selectedStatusVar = employeeReviewStateOptions.find(
                (thisSelection) => reviewState === thisSelection.key,
            );
            setRate(rateStr);
            setComment(props.review.reviewComment);
            setSelectedRate(rateOption);
            setSelectedStatus(selectedStatusVar);
        }
    };

    useEffect(() => {
        initInputs();
    }, [props.employee, props.personnelId, props.review]);

    const personnelId = (): string | undefined =>
        addMode() ? (employee?.id as string | undefined) : props.employee?.id ?? props.personnelId;

    const isRateValidForEntry = (rateStr: string): boolean => {
        const value = parseFloat(rateStr);
        return (
            typeof value === 'number' &&
            value <= MaxValidRate &&
            value >= 0 &&
            /^[0-9\.]+$/.test(rateStr)
        );
    };

    const isRateValidForSubmit = (rateStr: string | undefined): boolean => {
        const value = parseFloat(rateStr ?? '');
        return typeof value === 'number' && value <= MaxValidRate && value >= MinValidRate;
    };

    const clearInputs = (): void => {
        setRate(undefined);
        setEmployee(undefined);
        setSelectedRate(undefined);
        setSelectedStatus(undefined);
    };

    const disableSubmit = (): boolean => {
        const isDisable1 = addMode() && (employee === undefined || personnelId() === undefined);
        const isDisable2 =
            updateMode() &&
            rate === props.review?.rate?.toString() &&
            comment === props.review?.reviewComment &&
            (selectedStatus?.key === translateReviewState(props?.review?.reviewState) ||
                translateReviewState(props.review?.reviewState) ===
                    props?.review?.rate?.toString() ||
                // The following type cast is safe because the function
                // isSelectedStatusValid will return false if the value
                // is invalid.
                !isSelectedStatusValid(selectedStatus?.key as string));
        const isDisable3 =
            !isRateValidForSubmit(rate) ||
            selectedRate?.key === undefined ||
            (selectedRate?.key === OtherRate && rate === undefined);

        return isDisable1 || isDisable2 || isDisable3;
    };

    const onAddEmployeeSubmit = async (): Promise<IEmployeeReview> => {
        // Tech Debt
        // The following code relies on ModalActionButton to catch error.
        // A better way to handle the error is to get text of the error
        // and throw it. ModalActionButton will then catch and display it
        // on a banner on the modal.
        // The following type casts are safe because checking of disableSubmit()
        // ensures that personnelId() and rate are not undefined.
        return await ScaClient.addOrEditSCAEmployee(
            authContext,
            personnelId() as string,
            parseFloat(rate as string),
            comment,
            encodeEmployeeReviewStateDisplayText(selectedStatus?.key as string),
            props.mode,
        );
    };

    const onAddEditEmployeeConcluded = (
        conclusion: ModalConclusion,
        result?: IEmployeeReview,
    ): void => {
        if (props.onAddEditEmployeeConcluded) {
            props.onAddEditEmployeeConcluded(props.mode, conclusion, result);
        }
        switch (conclusion) {
            case ModalConclusion.Done:
                clearInputs();
                break;
            case ModalConclusion.Cancel:
                if (addMode()) clearInputs();
                else initInputs();
                break;
            default:
                break;
        }
    };

    const onCandidateSelected = (info?: IPersonaProps): void => {
        const itemProp = JSON.parse(info?.itemProp || '{}');
        setEmployee(itemProp);
    };

    const onRateSelect = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): void => {
        if (/^click|focus|keydown$/.test(event.type)) {
            // The following typecasts are safe because at this
            // point, value of option?.key is known to be a string.
            setSelectedRate(option);
            const value = parseFloat((option?.key as string) ?? '');
            if (typeof value === 'number') {
                setRate(option?.key as string);
            } else {
                setRate(undefined);
            }
        }
    };

    const onStatusSelect = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): void => {
        if (/^click|focus|keydown$/.test(event.type)) {
            if (
                option?.key === reviewStatusDisplayText(ReviewState.Eligible) &&
                !deepEqual(selectedStatus, employeeEligibleOption)
            ) {
                showEligibilityNoticeDialog();
            } else {
                setSelectedStatus(option);
            }
        }
    };

    const onRateInput = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        rateStr?: string,
    ): void => {
        if (rateStr === '' || isRateValidForEntry(rateStr ?? '')) {
            setRate(rateStr);
        }
    };

    const onCommentChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined,
    ): void => {
        setComment(newValue);
    };

    const onAcceptEligibilityDialog = (): void => {
        hideEligibilityNoticeDialog();
        setSelectedStatus(employeeEligibleOption);
    };

    const iconName = addMode() ? IconNames.Add : IconNames.Edit;
    const buttonText = props.buttonText ?? (addMode() ? 'Add Employee' : 'Edit');
    const submitButtonText = props.submitButtonText ?? (addMode() ? 'Add Employee' : 'Save');

    return (
        <ModalActionButton<IEmployeeReview>
            text={buttonText}
            iconName={iconName}
            errorMsg={errorMsg}
            modalTitle={props.modalTitle ?? buttonText}
            modalTitleIcon={iconName}
            enableSubmit={!disableSubmit()}
            submitButtonText={submitButtonText}
            submitButtonIcon={iconName}
            onSubmit={onAddEmployeeSubmit}
            onModalConcluded={onAddEditEmployeeConcluded}>
            {updateMode() && (props.employee || props.personnelId) && showEmployeeCard && (
                <>
                    <br />
                    <div className={globalStyles.boxShadow}>
                        <div className={showNoHoverCard ? '' : miniCardPadding}>
                            {props.employee ? (
                                <EmployeeBasicHoverCard
                                    key={props.employee?.id}
                                    noHoverCard={showNoHoverCard}
                                    employeeBasicData={props.employee}
                                />
                            ) : (
                                <EmployeeBasicHoverCard
                                    key={props.personnelId}
                                    noHoverCard={showNoHoverCard}
                                    personnelId={props.personnelId}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}
            {addMode() && (
                <>
                    <br />
                    <EmployeePickerTypeaheadSearch
                        label='Employee'
                        placeHolder='Employee Name or Alias'
                        onCandidateSelected={onCandidateSelected}
                    />
                </>
            )}
            {showRateUpdate && (
                <>
                    <br />
                    <Dropdown
                        label='Rate'
                        placeholder='Select a rate'
                        selectedKey={selectedRate?.key || ''}
                        onChange={onRateSelect}
                        options={rateSelectionOptions}
                    />
                    {selectedRate?.key === OtherRate && (
                        <>
                            <br />
                            <TextField
                                label={`Enter a rate between ${MinValidRate}% and ${MaxValidRate}%`}
                                title={`Enter a rate between ${MinValidRate}% and ${MaxValidRate}%`}
                                type='number'
                                value={`${rate}` ?? ''}
                                onChange={onRateInput}
                            />
                        </>
                    )}
                </>
            )}
            {updateMode() && showStatusUpdate && (
                <>
                    <br />
                    <Dropdown
                        label='Status'
                        placeholder='Select a Status'
                        selectedKey={selectedStatus?.key}
                        onChange={onStatusSelect}
                        options={employeeReviewStateOptions}
                    />
                </>
            )}
            {updateMode() && (
                <>
                    <br />
                    <TextField
                        label='Comment'
                        value={comment ?? ''}
                        ariaLabel='Comment'
                        placeholder='Enter a comment'
                        onChange={onCommentChange}
                    />
                </>
            )}
            <ScaEmployeeEligibilityDialog
                employeeName={props.review?.name ?? ''}
                isEmpEligNoticeDialogOpen={isEmpEligNoticeDialogOpen}
                onAcceptEligibilityDialog={onAcceptEligibilityDialog}
                hideEligibilityNoticeDialog={hideEligibilityNoticeDialog}
            />
        </ModalActionButton>
    );
}

const miniCardPadding = mergeStyles({
    padding: 10,
});
