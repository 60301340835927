import React, { ReactNode, useState, useMemo } from 'react';
import {
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    IDetailsHeaderProps,
    ActionButton,
    mergeStyleSets,
    IGroup,
    CollapseAllVisibility,
    IDetailsRowStyles,
    IDetailsRowProps,
    IGroupRenderProps,
    IDetailsHeaderStyles,
    ConstrainMode,
    ISelection,
    IObjectWithKey,
    ShimmeredDetailsList,
    StickyPositionType,
    Sticky,
    IRenderFunction,
    IStyle,
    mergeStyles,
    CommandBar,
    IContextualMenuItem,
    ITooltipHostStyles,
    TooltipHost,
} from '@fluentui/react';
import { columnHeaderStyles, detailsListStyles } from 'assets/styles/list-styles';
import { generateRandomKey } from 'utils/misc-utils';
import { globalStyles } from 'assets/styles/global-styles';
import { IconNames } from 'assets/constants/global-constants';
import { useIsMounted } from 'utils/misc-hooks';

export const DEFAULT_SHIMMER_LINE_COUNT = 10;

export interface TableProps<T> {
    isFetchingData: boolean;
    isColumnHeader?: boolean;
    collapseButton?: ICollapseButton;
    shimmerLabel?: string;
    groupProps?: IGroupRenderProps;
    groups?: IGroup[];
    groupNestingDepth?: number;
    rootStyle?: IStyle;
    noDataTextStyle?: IStyle;
    detailsRowStyles?: Partial<IDetailsRowStyles>;
    detailsHeaderStyles?: Partial<IDetailsHeaderStyles>;
    detailsListLayoutMode?: DetailsListLayoutMode;
    constrainMode?: ConstrainMode;
    indentWidth?: number;
    rows: T[];
    tableColumns: IColumn[];
    noDataText?: string; // Will show this if the table has no rows.
    isHeaderVisible?: boolean;
    tableName?: string;
    compact?: boolean;
    selectionMode?: SelectionMode;
    shimmerLines?: number;
    selectedItemCallback?: (selection: IObjectWithKey) => void;
    allSelectedItemsCallback?: (selection: IObjectWithKey[]) => void;
}

export interface ICollapseButton {
    isVisible: boolean;
    isCollapsing?: boolean;
    style?: IStyle;
    onToggleCollapseAll?: (isAllCollapsed: boolean) => void;
}

function getOnRenderDetailsHeader<T>(props: TableProps<T>): IRenderFunction<IDetailsHeaderProps> {
    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (
        headerProps,
        defaultRender,
    ) => {
        if (!headerProps) {
            return null;
        }

        if (props.collapseButton) {
            headerProps.collapseAllVisibility = props.collapseButton.isVisible
                ? CollapseAllVisibility.visible
                : CollapseAllVisibility.hidden;
            headerProps.isAllCollapsed = props.collapseButton.isCollapsing;
            headerProps.onToggleCollapseAll = props.collapseButton.onToggleCollapseAll;
        }

        if (props.groupNestingDepth) {
            headerProps.groupNestingDepth = props.groupNestingDepth;
        }

        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...headerProps,
                    styles: {
                        ...props.detailsHeaderStyles,
                        collapseButton: props.collapseButton?.style,
                    },
                })}
            </Sticky>
        );
    };

    return onRenderDetailsHeader;
}

export function Table<T>(props: TableProps<T>): JSX.Element {
    const { tableColumns } = props;
    /**
     * Generate "key" only once otherwise, if it's generated on
     * instantiation of <DetailsList>, it will update every time
     * the table re-renders. This will cause the whole table to
     * clear and be created again, causing the UI to jump to top
     * of the page and possibly flicker. This will particularly
     * be undesirable if user is scrolling through a long table
     * that keeps loading and increasing in length.
     */
    const [key] = useState<string>(generateRandomKey('DetailsList'));
    const [selectedItems, setSelectedItems] = useState<Map<number, IObjectWithKey>>(new Map());
    const [tableItems, setTableItems] = useState<IObjectWithKey[]>([]);
    const isMounted = useIsMounted();

    function onItemSelectionChange(item?: IObjectWithKey, index?: number): void {
        if (index !== undefined) {
            const selectedItem = selectedItems?.get(index);

            if (selectedItem) {
                selectedItems.delete(index);
            } else if (item) {
                selectedItems?.set(index, item);
            }
        }

        if (props.selectedItemCallback && item) {
            props.selectedItemCallback(item);
        }

        if (props.allSelectedItemsCallback) {
            props.allSelectedItemsCallback(Array.from(selectedItems?.values()));
        }

        if (isMounted()) {
            setSelectedItems(selectedItems);
        }
    }

    const selection: ISelection = {
        count: tableItems.length,
        mode: props.selectionMode ?? SelectionMode.single,
        canSelectItem: function (item: IObjectWithKey, index?: number): boolean {
            return true; // Set everything to enabled for right now
        },
        setChangeEvents: function (isEnabled: boolean, suppressChange?: boolean): void {
            // Do nothing for right now until this is needed
        },
        setItems: function (items: IObjectWithKey[], shouldClear: boolean): void {
            if (isMounted()) {
                setTableItems(items);
            }
        },
        getItems: function (): IObjectWithKey[] {
            return tableItems;
        },
        getSelection: function (): IObjectWithKey[] {
            return Array.from(selectedItems?.values());
        },
        getSelectedIndices: function (): number[] {
            return Array.from(selectedItems?.keys());
        },
        getSelectedCount: function (): number {
            return selectedItems.size;
        },
        isRangeSelected: function (fromIndex: number, count: number): boolean {
            return false; // Default false for right now until this is needed
        },
        isAllSelected: function (): boolean {
            return false; // Disable select all button for right now
        },
        isKeySelected: function (key: string): boolean {
            return false; // Default false for right now until this is needed
        },
        isIndexSelected: function (index: number): boolean {
            return selectedItems.has(index);
        },
        setAllSelected: function (isAllSelected: boolean): void {
            // Do nothing for right now until this is needed
        },
        setKeySelected: function (key: string, isSelected: boolean, shouldAnchor: boolean): void {
            // Do nothing for right now until this is needed
        },
        setIndexSelected: function (
            index: number,
            isSelected: boolean,
            shouldAnchor: boolean,
        ): void {
            // Do nothing for right now until this is needed
        },
        selectToKey: function (key: string, clearSelection?: boolean): void {
            // Do nothing for right now until this is needed
        },
        selectToIndex: function (index: number, clearSelection?: boolean): void {
            // Do nothing for right now until this is needed
        },
        toggleAllSelected: function (): void {
            // Do nothing for right now until this is needed
        },
        toggleKeySelected: function (key: string): void {
            // Do nothing for right now until this is needed
        },
        toggleIndexSelected: function (index: number): void {
            // Do nothing for right now until this is needed
        },
        toggleRangeSelected: function (fromIndex: number, count: number): void {
            // Do nothing for right now until this is needed
        },
    };

    /**
     * Determine if props.noDataText should be shown, or the table.
     */
    const showEmptyMsg = useMemo(
        () => !!props.noDataText && !props?.rows?.length && !props.isFetchingData,
        [props.noDataText, props?.rows?.length, props.isFetchingData],
    );

    return (
        <div className={detailsListStyles.wrapper}>
            {showEmptyMsg ? (
                <EmptyTableMessage noDataTextStyle={props.noDataTextStyle}>
                    <span>{props.noDataText}</span>
                </EmptyTableMessage>
            ) : (
                <ShimmeredDetailsList
                    ariaLabelForGrid={props.tableName || 'Table'}
                    className={mergeStyles(detailsListStyles.theList, props.rootStyle)}
                    compact={props.compact}
                    groupProps={props.groupProps}
                    groups={props.groups}
                    items={props.rows}
                    ariaLabelForShimmer={props.shimmerLabel || 'Content is being fetched'}
                    enableShimmer={props.isFetchingData}
                    columns={tableColumns}
                    setKey='candidates'
                    layoutMode={props.detailsListLayoutMode ?? DetailsListLayoutMode.justified}
                    indentWidth={props.indentWidth}
                    onActiveItemChanged={
                        props.selectionMode &&
                        props.selectionMode !== (SelectionMode.none as SelectionMode)
                            ? onItemSelectionChange
                            : undefined
                    }
                    selection={props.selectionMode ? selection : undefined}
                    selectionMode={props.selectionMode ?? SelectionMode.none}
                    shimmerLines={props.shimmerLines || DEFAULT_SHIMMER_LINE_COUNT}
                    constrainMode={props.constrainMode}
                    isHeaderVisible={
                        (props.isHeaderVisible ?? true) &&
                        (props.isColumnHeader === undefined || props.isColumnHeader)
                    }
                    onRenderDetailsHeader={getOnRenderDetailsHeader(props)}
                    onRenderRow={
                        props.detailsRowStyles
                            ? (prop, defaultRender): JSX.Element => (
                                  <div>
                                      {defaultRender!(
                                          prop
                                              ? (({
                                                    ...prop,
                                                    styles: props.detailsRowStyles,
                                                } as unknown) as IDetailsRowProps)
                                              : prop,
                                      )}
                                  </div>
                              )
                            : undefined
                    }
                    key={key}
                />
            )}
        </div>
    );
}

interface ITableCellContainer {
    children: ReactNode;
    className?: string;
    style?: React.CSSProperties;
    toolTipText?: string;
    ariaLabel?: string;
}

export function TableCell(props: ITableCellContainer): JSX.Element {
    const classNames = useMemo(
        () =>
            mergeStyleSets({
                root: [detailsListStyles.dataCellContainer, props.className, props.style],
            }),
        [props.className, props.style],
    );

    if (!props.toolTipText) {
        return (
            <div className={classNames.root}>
                <div className={detailsListStyles.dataCell} aria-label={props.ariaLabel ?? ''}>
                    {props.children}
                </div>
            </div>
        );
    }
    return (
        <div className={classNames.root}>
            <div className={detailsListStyles.dataCell} aria-label={props.ariaLabel ?? ''}>
                <TooltipHost
                    content={props.toolTipText}
                    styles={
                        {
                            root: {
                                selectors: {
                                    ':first-child': {
                                        marginRight: '10px',
                                    },
                                },
                            },
                        } as Partial<ITooltipHostStyles>
                    }>
                    {props.children}
                </TooltipHost>
            </div>
        </div>
    );
}

interface ITableActionDropdownProps {
    actions: IContextualMenuItem[];
    actionsColumnWidth: number;
    buttonText?: string;
}

/**
 * Table action dropdown component
 * Facilitates adding action dropdown buttons to a table.
 * Hint: Use it as a child of <TableCell>.
 * Example:
 *      <TableCell>
 *          <TableActionDropdown
 *              actions={array of action buttons}
 *              actionsColumnWidth={width of the table column}
 *          />
 *      </TableCell>
 */
export function TableActionDropdown(props: ITableActionDropdownProps): JSX.Element {
    // The variable actionsButtonWidth below is added in an effort
    // to fix this problem: Browser Chrome may show overflow dots "..."
    // on "Actions" column.
    // My observation was that, the button used less space than the
    // column provided it. Making the button itself a little wider
    // enabled it to take advantage of the space it had at its disposal.
    // First implemented by the PR https://msazure.visualstudio.com/Microsoft%20Personnel/_git/personnel-app-usgov/pullrequest/4705462.
    const actionsButtonWidth = props.actionsColumnWidth + 20;

    const buttonText = props.buttonText ?? 'Actions';

    return (
        <CommandBar
            styles={{
                root: {
                    paddingLeft: 0,
                    background: globalStyles.backgroundColor,
                    selectors: {
                        // Make the second icon that would be shown on the right
                        // side of the button disappear. We'll place our own icon
                        // at the left side.
                        ['i:nth-of-type(2)']: { display: 'none' },
                    },
                },
            }}
            items={[
                {
                    key: buttonText,
                    text: buttonText,
                    buttonStyles: {
                        root: {
                            background: `${globalStyles.backgroundColor}`,
                            width: actionsButtonWidth,
                            textAlign: 'left',
                        },
                    },
                    iconProps: { iconName: IconNames.ChevronDown },
                    subMenuProps: {
                        items: props.actions,
                    },
                },
            ]}
            ariaLabel={buttonText}
        />
    );
}

export interface ITableCertainActionButton {
    text?: string;
    onClick(): void;
    iconName?: string;
}

export function TableAddActionButton(props: ITableCertainActionButton): JSX.Element {
    return (
        <ActionButton
            className={styles.actionButton}
            text={props.text ?? 'Add'}
            onClick={props.onClick}
            iconProps={{ iconName: props.iconName ?? 'AddToShoppingList' }}></ActionButton>
    );
}

export function TableEditActionButton(props: ITableCertainActionButton): JSX.Element {
    return (
        <ActionButton
            className={styles.actionButton}
            text={props.text ?? 'Edit'}
            onClick={props.onClick}
            iconProps={{ iconName: props.iconName ?? 'EditSolid12' }}></ActionButton>
    );
}

export function TableDeleteActionButton(props: ITableCertainActionButton): JSX.Element {
    return (
        <ActionButton
            className={styles.actionButton}
            text={props.text ?? 'Delete'}
            onClick={props.onClick}
            iconProps={{ iconName: props.iconName ?? 'Delete' }}></ActionButton>
    );
}

interface IEmptyTableMessageProps {
    noDataTextStyle?: IStyle;
    children: ReactNode;
}

/**
 * Originally developed to be used where the table has no data rows,
 * and you want to show a message instead of an empty table.
 */
export function EmptyTableMessage(props: IEmptyTableMessageProps): JSX.Element {
    const style = mergeStyles(emptyTableStyles, props.noDataTextStyle);
    return <div className={style}>{props.children}</div>;
}

const styles = mergeStyleSets({
    actionButton: {
        display: 'table-cell',
        verticalAlign: 'middle',
    },
});

export const emptyTableStyles = mergeStyles({
    paddingTop: 16,
    paddingLeft: 20,
    fontSize: 16,
});

export const renderDetailsHeaderWithMultiLine = (): IRenderFunction<IDetailsHeaderProps> => (
    headerProps,
    defaultRender,
) => {
    if (!headerProps) {
        return null;
    }

    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
                ...headerProps,
                styles: columnHeaderStyles,
            })}
        </Sticky>
    );
};
