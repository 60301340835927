import React, { useContext, useState } from 'react';

import config from 'environments/environment';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import { MessageBar, MessageBarType, Stack } from '@fluentui/react';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { dateToFormattedDateTimeString, TimeFormats } from 'utils/time-utils';
import FacilitiesClient, {
    ICreateSeatRequest,
    IFacilityRecord,
    IHierarchyProvisionInfo,
    IProvisionInfo,
    ISeatRecord,
    IUpdateSeatRequestWithId,
    ProvisionType,
} from 'clients/facilities-client';
import EmployeeClient, { IBasicEmployee } from 'clients/employee-client';
import { Dictionary } from 'assets/constants/global-constants';
import { AuthContext } from 'contexts/auth-context';
import pluralize from 'pluralize';
import { UserContext } from 'contexts/user-context';
import { camelCaseToTitleCase, titleCaseToCamelCase } from 'utils/string-utils';
import AddEditSeatModal from 'components/facilities/facilities-seats/modals/add-edit-seats-modal';
import { generateRandomKey } from 'utils/misc-utils';
import { ISeatsColumns } from 'components/facilities/facilities-seats/seats-table-columns';
import UploadCsvButton, { IFileInfo } from 'components/common/buttons/upload-csv-button';

export interface SeatsTableHeaderProps {
    facility?: IFacilityRecord;
    seatsColumns?: ISeatsColumns[];
    updateSeat?: (seats: ISeatRecord[]) => void;
}

type ExcelRecordType = {
    id: string;
    facilityId: string;
    seatName: string;
    isOutOfOrder: string;
    provisionType: string;
    hierarchyIds: string; // only provided for provision type hierarchy
    occupiedByPersonnelId: string;
    occupiedByAlias: string;
    occupiedAt: string;
    lastModifiedBy: string;
    lastModifiedAlias: string;
    lastModifiedTimestamp: string;
    unitId: string;
    qualifiers: string;
};

enum ExpectedColumnHeader {
    Id = 'id',
    FacilityId = 'facilityId',
    SeatName = 'seatName',
    IsOutOfOrder = 'isOutOfOrder',
    ProvisionType = 'provisionType',
    HierarchyIds = 'hierarchyIds',
    OccupiedByPersonnelId = 'occupiedByPersonnelId',
    OccupiedByAlias = 'occupiedByAlias',
    OccupiedAt = 'occupiedAt',
    LastModifiedBy = 'lastModifiedBy',
    LastModifiedAlias = 'lastModifiedAlias',
    LastModifiedTimestamp = 'lastModifiedTimestamp',
    UnitId = 'unitId',
    SeatQualifiers = 'seatQualifiers',
}

const expectedColumnHeaders = Object.values(ExpectedColumnHeader).map((x) => `${x}`);
const emptyRowString = '[""]';

export default function SeatsTableHeader(props: SeatsTableHeaderProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState<string>();

    async function getExcelRecords(seatsColumns?: ISeatsColumns[]): Promise<ExcelRecordType[]> {
        if (!seatsColumns) {
            return [];
        } else if (seatsColumns.length === 0) {
            // provide template excel document when initially no seats exists
            return [
                {
                    id: '',
                    facilityId: '',
                    seatName: '',
                    isOutOfOrder: '',
                    provisionType: '',
                    hierarchyIds: '',
                    occupiedByPersonnelId: '',
                    occupiedByAlias: '',
                    occupiedAt: '',
                    lastModifiedBy: '',
                    lastModifiedAlias: '',
                    lastModifiedTimestamp: '',
                    unitId: '',
                    qualifiers: '',
                },
            ];
        }

        try {
            const personnelIds = new Set<string>();
            for (let i = 0; i < seatsColumns.length; i++) {
                [
                    seatsColumns[i].seatRecord.occupiedBy?.by,
                    seatsColumns[i].seatRecord.lastModified.by,
                ].forEach((x) => {
                    if (!!x) personnelIds.add(x);
                });
            }

            const employees = await EmployeeClient.getBasicEmployeesById(
                authContext,
                Array.from(personnelIds),
            );
            const employeeDict: Dictionary<IBasicEmployee> = {};
            employees.forEach((x) => (employeeDict[x.id] = x));

            return seatsColumns.map((x) => ({
                id: x.seatRecord.id,
                facilityId: x.seatRecord.facilityId,
                seatName: x.seatRecord.seatName,
                isOutOfOrder: x.seatRecord.isOutOfOrder.toString(),
                provisionType: x.seatRecord.provisionInfo.provisionType,
                hierarchyIds:
                    x.seatRecord.provisionInfo.provisionType === ProvisionType.Hierarchy
                        ? (x.seatRecord.provisionInfo as IHierarchyProvisionInfo).hierarchyIds.join(
                              ';',
                          )
                        : '',
                occupiedByPersonnelId: x.seatRecord.occupiedBy?.by ?? '',
                occupiedByAlias: x.seatRecord.occupiedBy?.by
                    ? employeeDict[x.seatRecord.occupiedBy.by].onPremisesSamAccountName
                    : '',
                occupiedAt: dateToFormattedDateTimeString(x.seatRecord.occupiedBy?.atUtc), // TODO: make this be in local time zone
                lastModifiedBy: x.seatRecord.lastModified.by,
                lastModifiedAlias:
                    employeeDict[x.seatRecord.lastModified.by].onPremisesSamAccountName,
                lastModifiedTimestamp: dateToFormattedDateTimeString(
                    x.seatRecord.lastModified.atUtc,
                ), // TODO: make this be in local time zone
                unitId: x.seatRecord.unitId ?? '',
                qualifiers: x.seatRecord.qualifiers.join(';'),
            }));
        } catch (error) {
            console.log('Failed to retrieve excel document for facility seats');
        }

        return [];
    }

    function getProvisionInfo(provisionType?: any, hierarchyIds?: any): IProvisionInfo {
        if (!provisionType) {
            return { provisionType: '' };
        }

        const lowerCasedProvisionType = provisionType.toLowerCase();

        if (lowerCasedProvisionType === ProvisionType.General.toLowerCase()) {
            return { provisionType: ProvisionType.General };
        } else if (lowerCasedProvisionType === ProvisionType.Admin.toLowerCase()) {
            return { provisionType: ProvisionType.Admin };
        } else if (
            lowerCasedProvisionType === ProvisionType.LiveSite.toLowerCase() ||
            lowerCasedProvisionType === ProvisionType.LiveSite.replace(' ', '').toLowerCase()
        ) {
            return { provisionType: ProvisionType.LiveSite };
        } else if (lowerCasedProvisionType === ProvisionType.Hierarchy.toLowerCase()) {
            return {
                provisionType: ProvisionType.Hierarchy,
                hierarchyIds: hierarchyIds ? (hierarchyIds as string).split(';') : [],
            } as IHierarchyProvisionInfo;
        }

        return { provisionType: provisionType };
    }

    function getMissingColumnHeaders(columnHeaders: string[]): string[] {
        const expectedColumnHeaderSet = new Set(expectedColumnHeaders);
        columnHeaders.forEach((x) => expectedColumnHeaderSet.delete(x));
        return Array.from(expectedColumnHeaderSet).map((x) => `"${camelCaseToTitleCase(x)}"`);
    }

    async function onFileLoaded(data: Array<any[]>, fileInfo: IFileInfo): Promise<void> {
        if ((fileInfo.type.includes('excel') || fileInfo.type.includes('csv')) && data.length > 1) {
            const columnHeaders = data[0].map((x) => titleCaseToCamelCase(x));

            const missingColumnHeaders = getMissingColumnHeaders(columnHeaders);
            if (missingColumnHeaders.length > 0) {
                setErrorMessage(
                    `Expected column headers ${missingColumnHeaders.join(
                        ',',
                    )} weren't provided so we cannot upload seats`,
                );
                return;
            }

            // filter out empty rows
            data = data.filter((x) => JSON.stringify(x) !== emptyRowString);

            const columnIndexDict: Dictionary<number> = {};
            columnHeaders.forEach((x, indx) => (columnIndexDict[x] = indx));

            const createRequest: ICreateSeatRequest[] = [];
            const updateRequest: IUpdateSeatRequestWithId[] = [];

            for (let i = 1; i < data.length; i++) {
                const id = data[i][columnIndexDict[ExpectedColumnHeader.Id]];
                if (id) {
                    updateRequest.push({
                        seatId: id,
                        seatName: data[i][columnIndexDict[ExpectedColumnHeader.SeatName]]
                            ? data[i][columnIndexDict[ExpectedColumnHeader.SeatName]]
                            : undefined,
                        isOutOfOrder: data[i][columnIndexDict[ExpectedColumnHeader.IsOutOfOrder]]
                            ? data[i][
                                  columnIndexDict[ExpectedColumnHeader.IsOutOfOrder]
                              ].toLowerCase() === 'true'
                            : undefined,
                        provisionInfo: data[i][columnIndexDict[ExpectedColumnHeader.ProvisionType]]
                            ? getProvisionInfo(
                                  data[i][columnIndexDict[ExpectedColumnHeader.ProvisionType]],
                                  data[i][columnIndexDict[ExpectedColumnHeader.HierarchyIds]],
                              )
                            : undefined,
                        unitId: data[i][columnIndexDict[ExpectedColumnHeader.UnitId]]
                            ? data[i][columnIndexDict[ExpectedColumnHeader.UnitId]]
                            : undefined,
                        occupiedBy: data[i][
                            columnIndexDict[ExpectedColumnHeader.OccupiedByPersonnelId]
                        ]
                            ? data[i][columnIndexDict[ExpectedColumnHeader.OccupiedByPersonnelId]]
                            : 'VACANT',
                        qualifiers: data[i][columnIndexDict[ExpectedColumnHeader.SeatQualifiers]]
                            ? data[i][columnIndexDict[ExpectedColumnHeader.SeatQualifiers]].split(
                                  ';',
                              )
                            : undefined,
                    });
                } else {
                    createRequest.push({
                        seatName: data[i][columnIndexDict[ExpectedColumnHeader.SeatName]],
                        isOutOfOrder: data[i][columnIndexDict[ExpectedColumnHeader.IsOutOfOrder]]
                            ? data[i][
                                  columnIndexDict[ExpectedColumnHeader.IsOutOfOrder]
                              ]?.toLowerCase() === 'true'
                            : false,
                        facilityId: data[i][columnIndexDict[ExpectedColumnHeader.FacilityId]],
                        provisionInfo: getProvisionInfo(
                            data[i][columnIndexDict[ExpectedColumnHeader.ProvisionType]],
                            data[i][columnIndexDict[ExpectedColumnHeader.HierarchyIds]],
                        ),
                        unitId: data[i][columnIndexDict[ExpectedColumnHeader.UnitId]]
                            ? data[i][columnIndexDict[ExpectedColumnHeader.UnitId]]
                            : undefined,
                        qualifiers: data[i][columnIndexDict[ExpectedColumnHeader.SeatQualifiers]]
                            ? data[i][columnIndexDict[ExpectedColumnHeader.SeatQualifiers]].split(
                                  ';',
                              )
                            : undefined,
                    });
                }
            }

            resetErrorMessage();

            const [createdSeats, updatedSeats] = await Promise.all([
                createSeats(createRequest),
                updateSeats(updateRequest),
            ]);

            if (props.updateSeat) {
                props.updateSeat(createdSeats);
                props.updateSeat(updatedSeats);
            }
        }
    }

    async function createSeats(request: ICreateSeatRequest[]): Promise<ISeatRecord[]> {
        if (!request || request.length === 0) {
            return [];
        }

        try {
            return await FacilitiesClient.createSeatRecords(authContext, userContext, request);
        } catch (error) {
            const response = error as Response;

            if (response.status === 400) {
                const json = await response.json();
                if (Array.isArray(json)) {
                    const badRequestSeatNames = (json as ICreateSeatRequest[]).map(
                        (x) => x.seatName,
                    );
                    const message = `Invalid values were provided for the ${pluralize(
                        'seat',
                        badRequestSeatNames.length,
                    )} with the ${pluralize(
                        'name',
                        badRequestSeatNames.length,
                    )} ${badRequestSeatNames.join(',')} please correct them`;
                    appendMessage(message);
                } else {
                    const message = `${json.message} for creating seats`;
                    appendMessage(message);
                }
            } else if (response.status === 403) {
                const message = await response.json();
                appendMessage(message);
            } else if (response.status === 500) {
                const internalServerErrorSeatNames = ((await response.json()) as ICreateSeatRequest[]).map(
                    (x) => x.seatName,
                );
                const message = `Failed to create ${pluralize(
                    'seat',
                    internalServerErrorSeatNames.length,
                )} with the ${pluralize(
                    'name',
                    internalServerErrorSeatNames.length,
                )} ${internalServerErrorSeatNames.join(',')} please try again`;
                appendMessage(message);
            } else {
                appendMessage(
                    'Failed to create seats, please ensure provided data is valid and try again',
                );
            }
        }

        return [];
    }

    async function updateSeats(request: IUpdateSeatRequestWithId[]): Promise<ISeatRecord[]> {
        if (!request || request.length === 0) {
            return [];
        }

        try {
            return await FacilitiesClient.updateSeatRecords(authContext, userContext, request);
        } catch (error) {
            const response = error as Response;

            if (response.status === 400) {
                const json = await response.json();
                if (Array.isArray(json)) {
                    const badRequestSeatIds = (json as IUpdateSeatRequestWithId[]).map(
                        (x) => x.seatId,
                    );
                    const message = `Invalid values were provided for the ${pluralize(
                        'seat',
                        badRequestSeatIds.length,
                    )} with ${pluralize('id', badRequestSeatIds.length)} ${badRequestSeatIds.join(
                        ',',
                    )} please correct them`;
                    appendMessage(message);
                } else {
                    const message = `${json.message} for updating seats`;
                    appendMessage(message);
                }
            } else if (response.status === 403) {
                const message = await response.json();
                appendMessage(message);
            } else if (response.status === 404) {
                const invalidSeatIds = (await response.json()) as string[];
                const message = `Seats with ${pluralize(
                    'id',
                    invalidSeatIds.length,
                )} ${invalidSeatIds.join(',')} doesn't exist`;
                appendMessage(message);
            } else if (response.status === 500) {
                const internalServerErrorSeatIds = ((await response.json()) as IUpdateSeatRequestWithId[]).map(
                    (x) => x.seatId,
                );
                const message = `Failed to update ${pluralize(
                    'seat',
                    internalServerErrorSeatIds.length,
                )} with ${pluralize(
                    'id',
                    internalServerErrorSeatIds.length,
                )} ${internalServerErrorSeatIds.join(',')} please try again`;
                appendMessage(message);
            } else {
                appendMessage(
                    'Failed to update seats, please ensure provided data is valid and try again',
                );
            }
        }

        return [];
    }

    function appendMessage(message: string): void {
        setErrorMessage((prevMsg) => (prevMsg ? prevMsg + ` ${message}.` : `${message}.`));
    }

    function onError(error: Error): void {
        setErrorMessage(error.message);
    }

    function resetErrorMessage(): void {
        setErrorMessage(undefined);
    }

    const upsertSeat = (result: ISeatRecord[]): void => {
        if (props.updateSeat) {
            props.updateSeat(result);
        }
    };

    return (
        <>
            <HorizontalBar>
                <Stack.Item grow={100}>
                    <span className={horizontalBarTitleStyle}>Seat Management</span>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal horizontalAlign='center' verticalAlign='center'>
                        {config.facilitiesServiceConfig.management.extras.enabled && (
                            <Stack.Item>
                                <UploadCsvButton
                                    label='Upload Seats from CSV: '
                                    onFileLoaded={onFileLoaded}
                                    onError={onError}
                                />
                            </Stack.Item>
                        )}
                        {config.facilitiesServiceConfig.management.extras.enabled && (
                            <Stack.Item>
                                <ExportToExcelButton<ExcelRecordType>
                                    getData={async () => getExcelRecords(props.seatsColumns)}
                                    buttonTitle='Download report'
                                    fileNamePrefix={`facility-${props.facility?.facilityName}-seats`}
                                    fileNameTimeFormat={TimeFormats.MM_DD_YYYY_HHmm}
                                    formatHeader={true}
                                    disabled={!props.facility || !props.seatsColumns}
                                />
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
            </HorizontalBar>
            {errorMessage && (
                <MessageBar
                    styles={{
                        root: { marginTop: '5px' },
                    }}
                    onDismiss={resetErrorMessage}
                    messageBarType={MessageBarType.error}
                    dismissButtonAriaLabel='Close'>
                    {errorMessage}
                </MessageBar>
            )}
            {config.facilitiesServiceConfig.management.extras.enabled && props.facility && (
                <AddEditSeatModal
                    key={generateRandomKey('AddEditEquipmentModal')}
                    facility={props.facility}
                    upsertSeat={upsertSeat}
                />
            )}
        </>
    );
}
