import React from 'react';
import { IconNames, xxxLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { IEmailRecord } from 'clients/email-client';
import { TableCell } from 'components/common/table';
import { dateToFormattedDateTimeStringFromSeconds } from 'utils/time-utils';
import { Link } from 'react-router-dom';
import { IColumn, ActionButton, Spinner } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import CopyToClipboardButton from 'components/common/buttons/copy-to-clipboard-button';
import Spacer from 'components/common/spacer';
import { getResultCodeBadge } from 'components/email/email-utils';

export default function tableColumnsEmailSearch({
    resendEmail,
    emailResending,
    canResendEmails,
}: {
    emailResending: string;
    canResendEmails: boolean;
    resendEmail: (id: string) => Promise<void>;
}): IColumn[] {
    enum EmailTemplateNames {
        emailId = 'Email ID',
        subject = 'Subject',
        createdAt = 'Created At (Local)',
        sentAt = 'Sent At (Local)',
        isSent = 'Result',
        actions = 'Actions',
    }

    const minColumnWidths = {
        emailId: 40,
        subject: 80,
        createdAt: 125,
        sentAt: 125,
        isSent: 80,
        actions: 90,
    };
    return [
        {
            key: EmailTemplateNames.emailId,
            name: EmailTemplateNames.emailId,
            ariaLabel: EmailTemplateNames.emailId,
            minWidth: minColumnWidths.emailId,
            maxWidth: 290,

            onRender: (row: IEmailRecord): JSX.Element => (
                <TableCell key={`${row.id}-${EmailTemplateNames.emailId}`}>
                    <CopyToClipboardButton text={row.id} />
                    <Spacer display={'inline'} />
                    <Link
                        to={(location: any) => `${location.pathname}/${row.id}`}
                        className={globalStyles.link}>
                        {row.id}
                    </Link>
                </TableCell>
            ),
        },
        {
            key: EmailTemplateNames.subject,
            name: EmailTemplateNames.subject,
            ariaLabel: EmailTemplateNames.subject,
            minWidth: minColumnWidths.subject,
            maxWidth: 300,

            onRender: (row: IEmailRecord): JSX.Element => (
                <TableCell
                    key={`${row.id}-${EmailTemplateNames.subject}`}
                    toolTipText={row.subject}>
                    {row.subject}
                </TableCell>
            ),
        },
        {
            key: EmailTemplateNames.createdAt,
            name: EmailTemplateNames.createdAt,
            ariaLabel: EmailTemplateNames.createdAt,
            minWidth: minColumnWidths.createdAt,
            maxWidth: 150,

            onRender: (row: IEmailRecord): JSX.Element => (
                <TableCell key={`${row.id}-${EmailTemplateNames.createdAt}`}>
                    {dateToFormattedDateTimeStringFromSeconds(row.createdTimeStamp)}
                </TableCell>
            ),
        },
        {
            key: EmailTemplateNames.sentAt,
            name: EmailTemplateNames.sentAt,
            ariaLabel: EmailTemplateNames.sentAt,
            minWidth: minColumnWidths.sentAt,
            maxWidth: 150,

            onRender: (row: IEmailRecord): JSX.Element => (
                <TableCell key={`${row.id}-${EmailTemplateNames.sentAt}`}>
                    {dateToFormattedDateTimeStringFromSeconds(row.sentTimeStamp)}
                </TableCell>
            ),
        },
        {
            key: EmailTemplateNames.isSent,
            name: EmailTemplateNames.isSent,
            ariaLabel: EmailTemplateNames.isSent,
            minWidth: minColumnWidths.isSent,
            maxWidth: minColumnWidths.isSent * xxxLargeMaxWidthCoeff,

            onRender: (row: IEmailRecord): JSX.Element => (
                <TableCell key={`${row.id}-${EmailTemplateNames.isSent}`}>
                    {getResultCodeBadge(row.sendResultCode, row.isSent)}
                </TableCell>
            ),
        },
        {
            key: EmailTemplateNames.actions,
            name: EmailTemplateNames.actions,
            ariaLabel: EmailTemplateNames.actions,
            minWidth: minColumnWidths.actions,
            maxWidth: minColumnWidths.actions * xxxLargeMaxWidthCoeff,

            onRender: (row: IEmailRecord): JSX.Element => (
                <TableCell key={`${row.id}-${EmailTemplateNames.isSent}`}>
                    {emailResending === row.id ? (
                        <Spinner />
                    ) : (
                        <ActionButton
                            disabled={!canResendEmails || emailResending !== ''}
                            iconProps={{ iconName: IconNames.Send }}
                            onClick={() => resendEmail(row.id)}>
                            <span>Resend</span>
                        </ActionButton>
                    )}
                </TableCell>
            ),
        },
    ];
}
