import { FontIcon, HoverCard, HoverCardType, TimePicker, mergeStyles } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

export interface DateElementTimePickerProps {
    value: string;
    onChange: (newValue: string) => void;
}

const timePickerContainer = mergeStyles({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '0.5rem',
});

export function DateElementTimePicker(props: DateElementTimePickerProps): JSX.Element {
    const { value, onChange: callUpdate } = props;
    const [time, setTime] = useState<string>(value || '');
    const isFluentUITimePickerEnabled = useFeatureFlag(FeatureFlagKeys.formsUseFluentUITimePicker)
        .enabled;

    useEffect(() => {
        if (value !== time) {
            setTime(value);
        }
    }, [value]);

    const onTimeChangedFluentUI = (ev: any, time: Date): void => {
        if (!time || isNaN(time.getTime())) {
            return;
        }
        const timeOnly = time.toLocaleTimeString('en', { hourCycle: 'h24' }).slice(0, -3);
        setTime(timeOnly);
        callUpdate(timeOnly);
    };

    const onTimeChangedHTMLInputTime = (ev: any): void => {
        const newValue = ev?.target.value;
        setTime(newValue);
        callUpdate(newValue);
    };

    if (isFluentUITimePickerEnabled) {
        return (
            <div className={timePickerContainer}>
                <TimePicker
                    dateAnchor={new Date('2024-01-09T00:00:00')}
                    useHour12
                    autoComplete='on'
                    ariaLabel='TimePicker'
                    onChange={onTimeChangedFluentUI}
                    value={value ? new Date(`2024-01-09T${value}`) : undefined}
                    placeholder='Select a time...'
                    strings={{
                        invalidInputErrorMessage: 'Invalid time format (example: 12:00 PM)',
                        timeOutOfBoundsErrorMessage:
                            'Invalid time. Time must be between 12:00 AM and 11:59 PM',
                    }}
                />
                <HoverCard
                    type={HoverCardType.plain}
                    plainCardProps={{
                        onRenderPlainCard: (): JSX.Element => {
                            return (
                                <p style={{ margin: '10px' }}>
                                    Type to enter the exact time down to the minute (example: 12:01
                                    PM)
                                </p>
                            );
                        },
                    }}>
                    <FontIcon iconName='Info' style={{ marginTop: '5px' }} />
                </HoverCard>
            </div>
        );
    } else {
        return (
            <input
                type='time'
                style={{ fontFamily: 'Segoe UI', padding: '4px', marginBottom: '5px' }}
                onChange={onTimeChangedHTMLInputTime}
                title='time'
                value={time ?? ''}
            />
        );
    }
}
