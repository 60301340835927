import React, { useContext } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import { doNothing } from 'utils/misc-utils';
import EmailClient from 'clients/email-client';
import BoldFont from 'components/common/misc/bold-font';
import { useIsMounted } from 'utils/misc-hooks';

interface IDeleteTemplateModalProps {
    templateId: string;
    templateName: string;
    handleDeleteTemplate: (id: string) => void;
}

export default function DeleteTemplateModalButton(props: IDeleteTemplateModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const isMounted = useIsMounted();

    const onDeleteTemplateSubmit = async (): Promise<void> => {
        if (isMounted()) {
            try {
                await EmailClient.deleteTemplate(authContext, props.templateId);
                props.handleDeleteTemplate(props.templateId);
            } catch (error) {
                throw error;
            }
        }
    };

    return (
        <ModalActionButton
            text={'Delete'}
            iconName={IconNames.Delete}
            modalTitle={'Delete template'}
            modalTitleIcon={IconNames.Delete}
            submitButtonText={'Delete'}
            onSubmit={onDeleteTemplateSubmit}
            enableSubmit={true}
            onButtonClick={doNothing}
            onModalConcluded={doNothing}>
            Are you sure you want to delete the template: <BoldFont>{props.templateName}?</BoldFont>
        </ModalActionButton>
    );
}
