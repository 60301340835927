import { GetAadHttpOptions } from 'clients/http-options';
import config from 'environments/environment';
import { ClearanceStatus, CloudMapStrings } from 'components/staffing/staffing-constants';
import { IAuthContext } from 'contexts/auth-context';
import { ICacheContext } from 'contexts/cache-context';
import { Role, StaffingOrgRolesType } from 'configs/roles';
import { StaffingCache } from 'components/staffing/staffing-cache';
import { Projects } from 'components/staffing/staffing-constants';
import ClientUtils, { DefaultMaxRetryCount } from 'clients/client-utils';

const staffingConfig = config.staffingServiceConfig;

export default class StaffingClient {
    // eslint-disable-next-line @typescript-eslint/ban-types
    static constructURL(url: string, params?: object): string {
        const fullUrl = new URL(`${staffingConfig.baseUrl}${url}`);
        Object.entries(params || {}).map(([key, value]) => fullUrl.searchParams.append(key, value));
        return fullUrl.href;
    }

    /**
     * @param orgName
     */
    static async getOrganization(
        authContext: IAuthContext,
        orgName: string,
    ): Promise<IStaffingOrganizationResponse> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        const url =
            staffingConfig.baseUrl +
            staffingConfig.organizationEndpoint +
            this.encodeOrgNameToUrlAcceptableFormat(orgName);
        return ClientUtils.doHttpRequest(url, httpOptions);
    }

    static async getAllocationsByOrganization(
        authContext: IAuthContext,
        cacheContext: ICacheContext,
        organizationName: string,
    ): Promise<IStaffingAllocationResponse[]> {
        const allocations = StaffingCache.getAllocationsByOrg(
            cacheContext,
            this.encodeOrgNameToUrlAcceptableFormat(organizationName),
        );
        if (allocations !== undefined) {
            return allocations;
        }

        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        const url = this.constructURL(staffingConfig.allocationPrefix, {
            organizationName: this.encodeOrgNameToUrlAcceptableFormat(organizationName),
        });
        const result = await ClientUtils.doHttpRequest<IStaffingAllocationResponse[]>(
            url,
            httpOptions,
        );
        StaffingCache.setAllocationsByOrg(cacheContext, organizationName, result);
        return result;
    }

    static async createAllocation(
        authContext: IAuthContext,
        allocationChangeRequest: IStaffingAllocationChangeRequest,
    ): Promise<void> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(allocationChangeRequest);
        const url = this.constructURL(staffingConfig.allocationPrefix);

        const res = await ClientUtils.doHttpRequestResponse(url, httpOptions);
        if (res.status >= 400) {
            throw res;
        }
    }

    static async editAllocation(
        authContext: IAuthContext,
        allocationChangeRequest: IStaffingAllocationChangeRequest,
    ): Promise<void> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(allocationChangeRequest);
        const url = this.constructURL(
            staffingConfig.allocationEndpoint + allocationChangeRequest.positionControlNumber,
        );
        return ClientUtils.doHttpRequest<void>(url, httpOptions);
    }

    static async deleteAllocation(
        authContext: IAuthContext,
        positionControlNumber: string,
    ): Promise<void> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        httpOptions.method = 'DELETE';
        const url = this.constructURL(staffingConfig.allocationEndpoint + positionControlNumber);
        return ClientUtils.doHttpRequest<void>(url, httpOptions);
    }

    static async createTeam(
        authContext: IAuthContext,
        organizationName: string,
        teamChangeRequest: IStaffingTeamChangeRequest,
    ): Promise<IStaffingTeamResponse> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(teamChangeRequest);
        const url = this.constructURL(this._getTeamUrl(organizationName));
        return ClientUtils.doHttpRequest<IStaffingTeamResponse>(url, httpOptions);
    }

    static async deleteTeam(
        authContext: IAuthContext,
        organizationName: string,
        teamChangeRequest: IStaffingTeamChangeRequest,
    ): Promise<IStaffingTeamResponse> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        httpOptions.method = 'DELETE';
        httpOptions.body = JSON.stringify(teamChangeRequest);
        const url = this.constructURL(this._getTeamUrl(organizationName));
        return ClientUtils.doHttpRequest<IStaffingTeamResponse>(url, httpOptions);
    }

    static async getTeamsFromOrganization(
        authContext: IAuthContext,
        organizationName: string,
    ): Promise<IStaffingTeamResponse[]> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        const url = this.constructURL(this._getTeamUrl(organizationName));
        return ClientUtils.doHttpRequest<IStaffingTeamResponse[]>(url, httpOptions);
    }

    static async getClouds(authContext: IAuthContext): Promise<IStaffingCloudResponse[]> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        const url = this.constructURL(staffingConfig.cloudEndpoint);
        return ClientUtils.doHttpRequest<IStaffingCloudResponse[]>(url, httpOptions);
    }

    static async getProjects(authContext: IAuthContext): Promise<IStaffingProjectResponse[]> {
        return Projects; // For now, return a constant value.
        /* Was:
        const url = staffingConfig.projectEndpoint;
        return this._doHttpRequest<IStaffingProjectResponse[]>(authContext, url);
        */
    }

    static async checkOrgPermission(
        authContext: IAuthContext,
        cacheContext: ICacheContext,
        organizationName: string,
        permissionRole: StaffingOrgRolesType,
    ): Promise<boolean> {
        // Check if user has 'StaffingAdminEdit' role which automatically has access.
        if (authContext.isInRole(Role.StaffingAdminEdit)) {
            return true;
        }
        const isPermittedCached = StaffingCache.getOrgPermission(
            cacheContext,
            organizationName,
            permissionRole,
        );
        if (isPermittedCached !== undefined) {
            return isPermittedCached;
        }

        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        // Call Staffing service to check if user has role edge for the organization
        const alias = authContext.getUserProfile()?.alias;
        const url = this.constructURL(
            staffingConfig.userAuthorizationEndpoint +
                alias +
                staffingConfig.permissionSuffix +
                permissionRole.toString() +
                staffingConfig.organizationSuffix +
                this.encodeOrgNameToUrlAcceptableFormat(organizationName),
        );
        let isPermitted: boolean;
        try {
            isPermitted = await ClientUtils.doHttpRequest<boolean>(
                url,
                httpOptions,
                DefaultMaxRetryCount,
            );
        } catch (e) {
            if (e?.status === 401) isPermitted = false;
            else throw e;
        }
        StaffingCache.setOrgPermission(cacheContext, organizationName, permissionRole, isPermitted);
        return isPermitted;
    }

    static async getAllocationsWithClearance(
        authContext: IAuthContext,
        cacheContext: ICacheContext,
        projectName: string,
    ): Promise<IStaffingClearanceRecordResponse[]> {
        const allocationsWithClearance = StaffingCache.getAllocationsWithClearance(cacheContext);
        if (allocationsWithClearance !== undefined) {
            return allocationsWithClearance;
        }

        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        const url = this.constructURL(
            staffingConfig.allocationPrefix + staffingConfig.clearancePrefix,
            {
                projectName: projectName,
            },
        );

        const results = await ClientUtils.doHttpRequest<IStaffingClearanceRecordResponse[]>(
            url,
            httpOptions,
        );
        StaffingCache.setAllocationsWithClearance(cacheContext, results);
        return results;
    }

    private static _getTeamUrl(organizationName: string): string {
        return (
            staffingConfig.organizationEndpoint +
            this.encodeOrgNameToUrlAcceptableFormat(organizationName) +
            staffingConfig.teamSuffix
        );
    }

    static async getUserAccessibleOrganizations(
        authContext: IAuthContext,
    ): Promise<IStaffingOrganizationResponse[]> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        const url = this.constructURL(staffingConfig.accessibleOrganizationsEndPoint);
        return ClientUtils.doHttpRequest<IStaffingOrganizationResponse[]>(
            url,
            httpOptions,
            DefaultMaxRetryCount,
        );
    }

    static async getOrganizationAccessDetailsForUser(
        authContext: IAuthContext,
    ): Promise<IOrganizationAccess[]> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        const url = this.constructURL(staffingConfig.organizationAccessDetailEndpoint);
        return ClientUtils.doHttpRequest<IOrganizationAccess[]>(
            url,
            httpOptions,
            DefaultMaxRetryCount,
        );
    }

    static async getAzureServices(authContext: IAuthContext): Promise<IAzureService[]> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        const url = this.constructURL(staffingConfig.azureServicesEndpoint + '?lifecycle=GA');
        return ClientUtils.doHttpRequest<IAzureService[]>(url, httpOptions);
    }

    static async getTeamAzureServices(
        authContext: IAuthContext,
        organizationName: string,
    ): Promise<ITeamAzureService[]> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        const url = this.constructURL(
            staffingConfig.teamAzureServiceEndpoint +
                '?organizationName=' +
                this.encodeOrgNameToUrlAcceptableFormat(organizationName),
        );
        return ClientUtils.doHttpRequest<ITeamAzureService[]>(url, httpOptions);
    }

    static async createTeamAzureServices(
        authContext: IAuthContext,
        organizationName: string,
        teamName: string,
        azureServices: IAzureService[],
    ): Promise<void> {
        if (!teamName || !azureServices || azureServices.length === 0) {
            // These shenanigans are to prevent VSCode warning wave lines.
            return new Promise<void>((r) => {
                r();
            });
        }

        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        httpOptions.method = 'POST';
        const url = this.constructURL(staffingConfig.teamAzureServiceEndpoint);
        const addServiceTeam: ITeamAzureService[] = azureServices.map((azs) => {
            return {
                teamName: teamName,
                organizationName: organizationName,
                serviceOid: azs.oid,
                serviceName: azs.name,
            } as ITeamAzureService;
        });
        httpOptions.body = JSON.stringify(addServiceTeam);
        return ClientUtils.doHttpRequest(url, httpOptions);
    }

    static async deleteAzureService(
        authContext: IAuthContext,
        deleteAzureService: ITeamAzureService,
    ): Promise<boolean> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        httpOptions.method = 'DELETE';
        const url = this.constructURL(
            staffingConfig.teamAzureServiceEndpoint + '/' + deleteAzureService.serviceOid,
        );
        return ClientUtils.doHttpRequest<boolean>(url, httpOptions);
    }

    static async getKpisByOrganization(
        authContext: IAuthContext,
        organizationName: string,
    ): Promise<IAzureServiceKpi[]> {
        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        const url = this.constructURL(
            staffingConfig.azureServiceKpisEndpoint +
                '?organizationName=' +
                this.encodeOrgNameToUrlAcceptableFormat(organizationName),
        );
        return ClientUtils.doHttpRequest<IAzureServiceKpi[]>(url, httpOptions);
    }

    static encodeOrgNameToUrlAcceptableFormat(organizationName: string): string {
        return (organizationName || '').replace(/\+/g, '_'); // Organization names such as "C+E" and "CO+I" won't work in URLs
    }

    static async changeTeamPeopleTarget(
        authContext: IAuthContext,
        organizationName: string,
        teamName: string,
        peopleTarget: string,
    ): Promise<void> {
        if (!teamName || !organizationName || peopleTarget === undefined) {
            throw 'Invalid parameter(s)';
        }

        const httpOptions = await GetAadHttpOptions(authContext, staffingConfig.aadScopes);
        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify({
            name: teamName,
            peopleTarget: parseInt(peopleTarget, 10),
            newName: teamName,
            force: true,
        });
        const url = this.constructURL(
            staffingConfig.organizationEndpoint +
                `${this.encodeOrgNameToUrlAcceptableFormat(organizationName)}` +
                staffingConfig.teamSuffix,
        );

        return ClientUtils.doHttpRequest(url, httpOptions);
    }

    static invalidateCache(cacheContext: ICacheContext): void {
        StaffingCache.invalidateCache(cacheContext);
    }
}

export interface IAzureService {
    name: string;
    oid: string;
    lifecycle: string;
}

export interface ITeamAzureService {
    teamName: string;
    organizationName: string;
    serviceName: string;
    serviceOid: string;
}

export interface IStaffingCloudStatusResponse {
    name: CloudMapStrings;
    status: ClearanceStatus;
}

export interface IStaffingAllocationChangeRequest {
    positionControlNumber: string;
    personnelId: string;
    preHireName: string;
    teamName: string;
    organizationName: string;
    projects: string[];
    clouds: string[];
}

export interface IStaffingTeamResponse {
    name: string;
    peopleTarget: number;
}

export interface IStaffingTeamChangeRequest {
    name: string;
    peopleTarget: number;
}

export interface IStaffingOrganizationResponse {
    id: string;
    label: string;
    name: string;
    partitionKey: string;
}

export interface IOrganizationAccess {
    organization: IOrganization;
    roles: string[];
    alias: string;
}

export interface IOrganization {
    id: string;
    label: string;
    name: string;
    partitionKey: string;
}

export interface IStaffingCloudResponse {
    name: CloudMapStrings;
}

export interface IStaffingProjectResponse {
    name: string;
}

/*export enum OrganizationPermissionsRole {
  READ_WRITE = 'Staffing_Org_Edit',
  READ = 'Staffing_Org_Read'
}*/

export interface IStaffingClearanceRecordResponse {
    cloudStatuses: IStaffingCloudStatusResponse[];
    organization: string;
    pcn: string;
    personnelId: string;
    preHireName: string;
    team: string;
}

export interface IStaffingAllocationResponse {
    cloudStatuses: IStaffingCloudStatusResponse[];
    organization: IStaffingOrganizationResponse;
    positionControlNumber: string;
    personnelId: string;
    preHireName: string;
    team: IStaffingTeamResponse;
    projects: IStaffingProjectResponse[];
}

export interface IAzureServiceKpi {
    kpi: string;
    value: string;
    teamName: string;
    organizationName: string;
    serviceName: string;
    serviceOid: string;
}
