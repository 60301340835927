import React, { useContext, useEffect } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import FacilitiesClient, { ISeatRecord } from 'clients/facilities-client';
import { doNothing } from 'utils/misc-utils';
import useMessageBar from 'components/common/use-message-bar';
import { MessageBarType, Label } from '@fluentui/react';
interface IDeleteSeatModalProps {
    seat: ISeatRecord;
    deleteSeat: (record: ISeatRecord) => void;
}

export default function DeleteSeatModal(props: IDeleteSeatModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    const deleteSeat = async (): Promise<void> => {
        try {
            await FacilitiesClient.deleteSeatRecord(authContext, userContext, props.seat.id);
            if (props.deleteSeat) {
                props.deleteSeat(props.seat);
            }
        } catch (e) {
            throw 'Error occurred when deleting seat.';
        }
    };

    const {
        theMessage: warningMessage,
        theElement: MessageBarElement,
        setMessage: setMessageBarMessage,
    } = useMessageBar({
        type: MessageBarType.warning,
        disableClear: true,
        alwaysShow: true,
    });

    useEffect(() => {
        setMessageBarMessage('Editing or deleting a seat may affect current reservations.');
    }, []);

    return (
        <ModalActionButton<void>
            text={'Delete'}
            iconName={IconNames.Delete}
            modalTitle={'Delete Seat'}
            modalTitleIcon={IconNames.Delete}
            enableSubmit={true}
            submitButtonText={'Delete'}
            submitButtonIcon={IconNames.Delete}
            onSubmit={deleteSeat}
            onModalConcluded={doNothing}>
            {warningMessage && <div>{MessageBarElement()}</div>}
            <div>Are you sure you want to delete the following seat?</div>
            <div>
                <Label>Seat Name</Label>
                {props.seat.seatName}
            </div>
            <div>
                <Label>Provision Type</Label>
                {props.seat.provisionInfo.provisionType}
            </div>
            <div>
                <Label>Is Out of Order</Label>
                {props.seat.isOutOfOrder ? 'Yes' : 'No'}
            </div>
        </ModalActionButton>
    );
}
