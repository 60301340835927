import React, { useEffect, useContext } from 'react';
import { UserContext } from 'contexts/user-context';
import ScaViewEmployeeHistory from 'components/sca/history/sca-view-employee-history';
import { HistoryPageValidTabs } from 'components/sca/sca-constants';
import { HistoryPageTitle } from 'components/sca/sca-constants';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { scaHistoryBreadcrumbs } from 'components/sca/sca-breadcrumbs';

interface IScaViewMyHistoryProps {
    defaultTab?: HistoryPageValidTabs;
}

export default function ScaViewMyHistory(props: IScaViewMyHistoryProps): JSX.Element {
    const userContext = useContext(UserContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs(scaHistoryBreadcrumbs(HistoryPageTitle.MyHistory));
    }, [props.defaultTab]);

    return (
        <ScaViewEmployeeHistory
            employee={userContext.employeeRecord}
            hasTabs={{ myHistory: true, myTeam: true, myOrg: true }}
            defaultTab={props.defaultTab}
            shouldHideReviewComments={true}
            shouldHideEmployeeSearchBox={true}
        />
    );
}
