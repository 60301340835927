/**
 * Calculates the widths of columns based on the number of columns and whether there is an action header.
 * @param numberOfColumns - The total number of columns.
 * @param hasActionHeader - Indicates whether there is an action header.
 * @returns An object containing the calculated column width and action column width.
 */
export function getDefaultColumnWidths(
    numberOfColumns: number,
    hasActionHeader = true,
    totalWidthValue = 700,
    totalActionColumnWidth = 50,
): { defaultColumnWidth: number; actionColumnWidth: number } {
    const totalWidth = totalWidthValue;
    const actionColumnWidth = totalActionColumnWidth;

    let defaultColumnWidth: number;

    if (hasActionHeader) {
        // Calculate column width when there is an action header
        defaultColumnWidth =
            (totalWidth - actionColumnWidth - numberOfColumns * 20) / (numberOfColumns - 1);
    } else {
        // Calculate column width when there is no action header
        defaultColumnWidth = totalWidth / numberOfColumns;
    }

    return {
        defaultColumnWidth: defaultColumnWidth,
        actionColumnWidth: actionColumnWidth,
    };
}
