import { IColumn } from '@fluentui/react';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import { AttributeDataType } from 'personnel-core-clients';
import React from 'react';

export interface IAssignmentValueTableCellProps {
    value: any;
    attributeType?: AttributeDataType;
    column?: IColumn; // The column that the table cell is being rendered into
}

export function AssignmentValueTableCell(props: IAssignmentValueTableCellProps): JSX.Element {
    const { value, attributeType, column } = props;

    if (Array.isArray(value)) {
        if (attributeType === AttributeDataType.Person) {
            return (
                <>
                    {value.map((x) => (
                        <CoreEmployeeHoverCardFromPrincipalId
                            key={x}
                            principalId={String(x)}
                            showMiniCardAlias={false}
                            showFullName={false}
                        />
                    ))}
                </>
            );
        } else {
            return <EllipsisTextCss width={column?.currentWidth} text={value.join(', ')} />;
        }
    } else {
        if (attributeType === AttributeDataType.Person) {
            return (
                <CoreEmployeeHoverCardFromPrincipalId
                    principalId={String(value)}
                    showMiniCardAlias={false}
                />
            );
        } else {
            return <EllipsisTextCss width={column?.currentWidth} text={String(value)} />;
        }
    }
}
