import React, { useState, useContext } from 'react';
import StaffingClient, { IStaffingTeamResponse } from 'clients/staffing-client';
import { TextField, Dropdown, IDropdownOption } from '@fluentui/react';
import { AuthContext } from 'contexts/auth-context';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { IconNames } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';

interface IEditTeamModalProps {
    teams: IStaffingTeamResponse[];
    orgName: string | undefined;
    editTeamConclusion: (modalConclusion: ModalConclusion) => void;
}

export default function EditTeamModalButton(props: IEditTeamModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [peopleTarget, setPeopleTarget] = useState<string>();
    const [selectedTeam, setSelectedTeam] = useState<IStaffingTeamResponse>();

    const isEditDisabled = (): boolean =>
        !props.orgName ||
        !selectedTeam?.name ||
        !peopleTarget ||
        peopleTarget === `${selectedTeam?.peopleTarget}` ||
        parseInt(peopleTarget, 10) < 0;

    const editTeam = async (): Promise<void> => {
        if (isEditDisabled()) {
            return;
        }
        try {
            await StaffingClient.changeTeamPeopleTarget(
                authContext,
                // The call to function isEditDisabled() above guarantees that orgName,
                // selectedTeam and peopleTarget are not undefined. Therefore, the following
                // type casts are safe.
                props.orgName as string,
                selectedTeam?.name as string,
                `${peopleTarget}` as string,
            );
        } catch (e) {
            throw 'Some error occurred. Could not edit the team.';
        }
    };

    const onPeopleTargetChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        peopleTargetVar?: string,
    ): void => {
        if (parseInt(peopleTargetVar || '', 10) >= 0) {
            setPeopleTarget(peopleTargetVar);
        }
    };

    const selectTeam = (a: React.FormEvent<HTMLDivElement>, team?: IDropdownOption): void => {
        if (a.type === 'click') {
            const selectedTeam = props.teams.find((t) => t.name === team?.key);
            setSelectedTeam(selectedTeam);
            setPeopleTarget(`${selectedTeam?.peopleTarget}`);
        }
    };

    const onButtonClick = () => {
        // Forget the information user entered the previous time modal was open.
        setSelectedTeam(undefined);
        setPeopleTarget(undefined);
    };

    const teamsOptions: IDropdownOption[] = (props.teams || []).map(
        (team): IDropdownOption => ({
            key: team.name,
            text: team.name,
        }),
    );

    return (
        <ModalActionButton<void>
            text={'Edit Teams'}
            iconName={IconNames.Edit}
            onSubmit={editTeam}
            modalTitle={`Edit Team ${selectedTeam?.name ?? ''}`}
            enableSubmit={!isEditDisabled()}
            onButtonClick={onButtonClick}
            submitErrorMsg={`Some error occurred. Could not edit team ${selectedTeam?.name}.`}
            onModalConcluded={props.editTeamConclusion}
            submitButtonText={'Update'}>
            <div>
                <Dropdown
                    label='Select Team'
                    styles={{ dropdownItems: globalStyles.dropdownItems }}
                    options={teamsOptions}
                    onChange={selectTeam}
                    placeholder='Select a team'
                    selectedKey={selectedTeam?.name || ''}
                />
                <br />
                <TextField
                    label='People Target'
                    title='People Target'
                    type='number'
                    value={`${peopleTarget}`}
                    onChange={onPeopleTargetChange}
                />
            </div>
        </ModalActionButton>
    );
}
