import { AppConstants } from 'components/common/constants';
import { MultiChoiceFilter } from 'components/common/filters';
import { getEvaluatedCommonScreeningState } from 'components/screening/public-trust/public-trust-screening-result';
import { filterContextKeys, IFiltersContext } from 'contexts/filters-context';
import { Separator, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { IMultiChoiceFilterItem } from 'types/multi-choice-filter-Item';
import { DeclinedStatusOptions, StateName } from 'components/screening/common/common-constants';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import {
    separatorStyles,
    filterMargin,
} from 'components/screening/common/filters/common-filter-styling';
import {
    labelFunction,
    onDeclinedStatusOptionChange,
} from 'components/screening/common/filters/filter-help';

export interface DeclinedStatusFilterProps {
    rawCandidates: ICommonScreening[];
    filterContext: IFiltersContext;
    showCounts: boolean;
    // screeningPath: ScreeningPaths;
}

export function DeclinedStatusFilter(props: DeclinedStatusFilterProps): JSX.Element {
    const [selectedDeclinedStates, setSelectedDeclinedStates] = useState<string[]>([]);

    useEffect(() => {
        if (props.filterContext.filterCleared) {
            setSelectedDeclinedStates([]);
        }
    }, [props.filterContext.filterCleared]);

    useEffect(() => {
        const newFilterFunctions: {
            func: (arg: ICommonScreening) => boolean;
            undefined: undefined;
        }[] = [];

        for (const declinedState of selectedDeclinedStates) {
            const func = (screening: ICommonScreening): boolean =>
                filterOnDeclinedState(screening, declinedState);

            newFilterFunctions.push({ func, undefined });
        }

        props.filterContext.setFilterFunctions(
            filterContextKeys.declinedStatus,
            newFilterFunctions,
        );
    }, [selectedDeclinedStates]);

    function filterOnDeclinedState(screening: ICommonScreening, declinedState: string): boolean {
        return declinedState === getEvaluatedCommonScreeningState(screening);
    }

    const declinedStatusOptions: IMultiChoiceFilterItem[] = [
        {
            label: DeclinedStatusOptions.RemovedFromProcessing,
            isChecked: selectedDeclinedStates.includes(AppConstants.RemovedFromProcessing),
            filterFunction(data: ICommonScreening): boolean {
                return filterOnDeclinedState(data, AppConstants.RemovedFromProcessing);
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
            onChange(
                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                checked?: boolean,
            ): void {
                onDeclinedStatusOptionChange(
                    selectedDeclinedStates,
                    AppConstants.RemovedFromProcessing,
                    setSelectedDeclinedStates,
                    checked,
                );
            },
        },
        {
            // TODO unify ContractOwnerDeny & ContractOwnerDenied state name
            // right now there are two cases to support US Gov and Public Trust naming
            label: DeclinedStatusOptions.ContractOwnerDeny,
            isChecked:
                selectedDeclinedStates.includes(AppConstants.ContractOwnerDeny) ||
                selectedDeclinedStates.includes(StateName.ContractOwnerDenied),
            filterFunction(data: ICommonScreening): boolean {
                return (
                    filterOnDeclinedState(data, AppConstants.ContractOwnerDeny) ||
                    filterOnDeclinedState(data, StateName.ContractOwnerDenied)
                );
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
            onChange(
                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                checked?: boolean,
            ): void {
                onDeclinedStatusOptionChange(
                    selectedDeclinedStates,
                    AppConstants.ContractOwnerDeny,
                    setSelectedDeclinedStates,
                    checked,
                );

                onDeclinedStatusOptionChange(
                    selectedDeclinedStates,
                    StateName.ContractOwnerDenied,
                    setSelectedDeclinedStates,
                    checked,
                );
            },
        },
        {
            // TODO unify NomineeNotAccept & NomineeDeclined state name
            // right now there are two cases to support US Gov and Public Trust naming
            label: DeclinedStatusOptions.NomineeDeclined,
            isChecked:
                selectedDeclinedStates.includes(AppConstants.NomineeNotAccept) ||
                selectedDeclinedStates.includes(StateName.NomineeDeclined),
            filterFunction(data: ICommonScreening): boolean {
                return (
                    filterOnDeclinedState(data, AppConstants.NomineeNotAccept) ||
                    filterOnDeclinedState(data, StateName.NomineeDeclined)
                );
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
            onChange(
                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                checked?: boolean,
            ): void {
                onDeclinedStatusOptionChange(
                    selectedDeclinedStates,
                    AppConstants.NomineeNotAccept,
                    setSelectedDeclinedStates,
                    checked,
                );

                onDeclinedStatusOptionChange(
                    selectedDeclinedStates,
                    StateName.NomineeDeclined,
                    setSelectedDeclinedStates,
                    checked,
                );
            },
        },
        {
            label: DeclinedStatusOptions.AdjudicatedDenied,
            isChecked: selectedDeclinedStates.includes(AppConstants.AdjudicationResultDenied),
            filterFunction(data: ICommonScreening): boolean {
                return filterOnDeclinedState(data, AppConstants.AdjudicationResultDenied);
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
            onChange(
                ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                checked?: boolean,
            ): void {
                onDeclinedStatusOptionChange(
                    selectedDeclinedStates,
                    AppConstants.AdjudicationResultDenied,
                    setSelectedDeclinedStates,
                    checked,
                );
            },
        },
    ];

    return (
        <>
            <Separator styles={separatorStyles} alignContent='start'>
                Declined Status
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Declined Status Group'>
                <MultiChoiceFilter
                    unfilteredData={props.rawCandidates}
                    filterItems={declinedStatusOptions}
                />
            </Stack>
        </>
    );
}
export default DeclinedStatusFilter;
