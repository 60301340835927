import React, { useState, useEffect } from 'react';
import { ICollapseButton, Table } from 'components/common/table';
import { NavLink } from 'react-router-dom';
import {
    Modal,
    IColumn,
    Stack,
    IDragOptions,
    ContextualMenu,
    IButtonStyles,
    IIconProps,
    IconButton,
    Icon,
    IGroup,
    IStyleFunctionOrObject,
    IDetailsColumnStyleProps,
    IDetailsColumnStyles,
    IGroupRenderProps,
    IGroupHeaderProps,
    Label,
    getTheme,
    IStyle,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import { generateRandomKey } from 'utils/misc-utils';
import { IAuthContext } from 'contexts/auth-context';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import { renderCorrectCandidateColumnOption } from 'components/screening/us-gov/candidates/listing/get-candidates-table-columns';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { ScreeningPageNames } from 'components/common/constants';
import {
    customerMetricsStyles,
    expandButtonStyle,
    noExpandButtonStyle,
} from 'components/screening/common/common-tab-styling';
import {
    ICustomerMetricsBackend,
    substatesMetrics,
    metricSubStatesNameMapping,
} from 'components/screening/common/ICustomerMetrics';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';

export interface DetailCustomerMetricModalProps {
    isDetailWindowOpen: boolean;
    closeModal: () => void;
    authContext: IAuthContext;
    screening: ICommonScreening | undefined;
    pageName: string;
}

const totalDaysRowKey = 'totalDaysRow';

export default function DetailCustomerMetricModal(
    props: DetailCustomerMetricModalProps,
): JSX.Element {
    const [isCollapsing, setCollapsing] = useState<boolean>(true);
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
    const [errorMessage, setMessage] = useState<string>('');
    const [hasErrorOccurred, setErrorOccurred] = useState<boolean>(false);

    function collapseManagerDirectReports(isAllCollapsed: boolean): void {
        const newGroups: IGroup[] = [];

        groups.forEach((x) => {
            const copiedGroup: IGroup = {
                ...x,
                isCollapsed: isAllCollapsed,
            };
            newGroups.push(copiedGroup);
        });
        setGroups(newGroups);
        setCollapsing(!isCollapsing);
    }

    const collapseButton: ICollapseButton = {
        isVisible: true,
        isCollapsing: isCollapsing,
        style: { color: 'rgb(0, 120, 212)' },
        onToggleCollapseAll: (isAllCollapsed: boolean) => {
            collapseManagerDirectReports(isAllCollapsed);
        },
    };

    function buildGroups(customerMetrics: ICustomerMetricsBackend): void {
        const newGroups: IGroup[] = [];
        let total = 0;
        for (const [state, substates] of Object.entries(substatesMetrics)) {
            // main header group like 'Preparation'
            const firstKeyTyped = substates[0] as keyof typeof customerMetrics;
            const data = `${customerMetrics[firstKeyTyped]}`;
            const group: IGroup = {
                key: state,
                name: state,
                startIndex: 0,
                count: substates.length,
                level: 0,
                isCollapsed: true,
                children: [],
                data,
            };
            total = total + parseInt(data);
            // substates groups like 'AwaitingPoly'
            for (let i = 1; i < substates.length; i++) {
                const substateString = substates[i];
                const keyTyped = substateString as keyof typeof customerMetrics;
                const substateNameKey = substateString as keyof typeof metricSubStatesNameMapping;
                const subStateName = metricSubStatesNameMapping[substateNameKey];
                if (keyTyped) {
                    const value = `${customerMetrics[keyTyped]}`;
                    group.children?.push({
                        key: keyTyped,
                        name: subStateName,
                        startIndex: 0,
                        count: 0,
                        level: 1,
                        isCollapsed: true,
                        data: value,
                    });
                }
            }
            newGroups.push(group);
        }
        // add Total row at the bottom
        newGroups.push({
            key: totalDaysRowKey,
            name: 'Total',
            startIndex: 0,
            count: 0,
            level: 0,
            isCollapsed: false,
            data: total,
        });
        setGroups(newGroups);
    }

    function getColumns(): IColumn[] {
        const statusStr = 'Status';
        const dayStr = 'Days';
        return [
            {
                key: statusStr,
                name: statusStr,
                ariaLabel: statusStr,
                minWidth: 125,
                maxWidth: 125,
                isRowHeader: true,
                isMultiline: true,
                fieldName: statusStr,
                styles: {
                    root: { alignItems: 'flex-start', minWidth: 375, maxWidth: 375 },
                } as IStyleFunctionOrObject<IDetailsColumnStyleProps, IDetailsColumnStyles>,
            },
            {
                key: dayStr,
                name: dayStr,
                ariaLabel: dayStr,
                minWidth: 55,
                maxWidth: 55,
                isMultiline: true,
                isRowHeader: false,
                styles: {
                    root: { alignItems: 'flex-start', minWidth: 55, maxWidth: 55 },
                } as IStyleFunctionOrObject<IDetailsColumnStyleProps, IDetailsColumnStyles>,
            },
        ];
    }
    async function buildCustomerMetrics(screeningId: string): Promise<void> {
        if (!screeningId) {
            setErrorOccurred(true);
            setMessage('Non-valid Screening');
            return;
        }
        try {
            setErrorOccurred(false);
            setIsFetchingData(true);
            const results: ICustomerMetricsBackend = await UsGovScreeningClient.getCustomerMetrics(
                props.authContext,
                screeningId,
            );
            buildGroups(results);
            setIsFetchingData(false);
        } catch (e) {
            setErrorOccurred(true);
            setMessage('No Data to show');
            setIsFetchingData(false);
        }
    }

    useEffect(() => {
        if (props.screening) {
            // reset groups between different metrics
            setGroups([]);
            buildCustomerMetrics(props.screening.id);
        }
    }, [props.screening?.id]);

    const groupProps: IGroupRenderProps = {
        showEmptyGroups: true,
        onRenderHeader: renderHeaderRow,
    };

    function isManager(localProps: IGroupHeaderProps): boolean {
        return localProps.group !== undefined && localProps.group.count > 0;
    }

    function renderHeaderRow(
        prop?: IGroupHeaderProps,
        defaultRender?: (props?: IGroupHeaderProps) => JSX.Element | null,
    ): JSX.Element | null {
        {
            if (prop) {
                prop.styles = {
                    expand: isManager(prop) ? expandButtonStyle : noExpandButtonStyle,
                    expandIsCollapsed: isManager(prop) ? expandButtonStyle : noExpandButtonStyle,
                };
            }

            return (
                <>
                    {defaultRender!({
                        ...prop,
                        onRenderTitle: renderDirectReportsTitleRows,
                    })}
                </>
            );
        }
    }

    // adjust the name (header) width based on the space taken by nested grouping indentation
    function getHeaderWidth(level: number | undefined, initialWidth: number): number {
        const calLevel = level !== undefined ? level : 0;
        return calLevel > 0 ? initialWidth - calLevel * tableIndentWidth : initialWidth;
    }

    function renderDirectReportsTitleRows(prop?: IGroupHeaderProps): JSX.Element {
        if (prop?.group?.key) {
            return (
                <Stack horizontal>
                    <Stack.Item
                        styles={{
                            root: {
                                alignSelf: 'center',
                                minWidth: getHeaderWidth(
                                    prop.groupLevel,
                                    directReportColumnStyles.name.width,
                                ),
                                maxWidth: getHeaderWidth(
                                    prop.groupLevel,
                                    directReportColumnStyles.name.width,
                                ),
                                // bold only 'Total'
                                fontWeight: prop.group.key === totalDaysRowKey ? 'bold' : 'normal',
                            },
                        }}>
                        {prop.group.name}
                    </Stack.Item>

                    <Stack.Item styles={directReportColumnStyles.clearance.stackStyles}>
                        {prop.group.data}
                    </Stack.Item>
                </Stack>
            );
        }
        return <></>;
    }

    function closeMessage(): void {
        setErrorOccurred(false);
        setMessage('');
    }

    return (
        <Modal
            isOpen={props.isDetailWindowOpen}
            onDismiss={props.closeModal}
            containerClassName={customerMetricsStyles.container}
            dragOptions={dragOptions}>
            <div className={customerMetricsStyles.header}>
                <span>Metrics</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel='Close popup modal'
                    onClick={props.closeModal}
                />
            </div>
            <div className={customerMetricsStyles.row}>
                <div className={customerMetricsStyles.innerRowModal}>
                    <div>
                        <Label>Candidate</Label>
                    </div>
                    <div>{renderCorrectCandidateColumnOption(props.screening)}</div>
                </div>
                {props.screening &&
                    props.pageName !== ScreeningPageNames.MyContracts &&
                    props.pageName !== ScreeningPageNames.MyNominees &&
                    props.pageName !== ScreeningPageNames.MyTeam &&
                    props.pageName !== ScreeningPageNames.MyOrg && (
                        <div className={customerMetricsStyles.innerRow2Modal}>
                            <div>
                                <NavLink
                                    key={`preview-link-${
                                        props.screening?.id ?? generateRandomKey()
                                    }`}
                                    className={customerMetricsStyles.viewScreeningLink}
                                    to={`/screening/us-gov/${props.screening?.id}`}>
                                    <Icon
                                        iconName='PreviewLink'
                                        className={customerMetricsStyles.viewScreeningIcon}
                                    />{' '}
                                    Details
                                </NavLink>
                            </div>
                        </div>
                    )}
            </div>
            <div className={customerMetricsStyles.body}>
                <Table
                    key={`directReport_table`}
                    collapseButton={collapseButton}
                    rows={[]} // don't need rows when there are groups and groupProps
                    isFetchingData={isFetchingData}
                    tableColumns={getColumns()}
                    shimmerLabel='Loading Metrics...'
                    groups={groups}
                    groupProps={groupProps}
                    groupNestingDepth={2}
                    indentWidth={15}
                    rootStyle={tableRootStyle}
                    compact={true}
                />
            </div>
            <IsLoadingIndicator isLoading={isFetchingData} />;
            {hasErrorOccurred && (
                <div className={customerMetricsStyles.errorMessageBarDiv}>
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        dismissButtonAriaLabel='Close'
                        onDismiss={closeMessage}>
                        {errorMessage}
                    </MessageBar>
                </div>
            )}
        </Modal>
    );
}

const tableIndentWidth = 15;
const directReportColumnStyles = {
    name: {
        width: 385,
        styles: {
            root: { alignItems: 'flex-start', minWidth: 385, maxWidth: 385 },
        } as IStyleFunctionOrObject<IDetailsColumnStyleProps, IDetailsColumnStyles>,
    },
    clearance: {
        width: 65,
        styles: {
            root: { alignItems: 'flex-start', minWidth: 100, maxWidth: 100 },
        } as IStyleFunctionOrObject<IDetailsColumnStyleProps, IDetailsColumnStyles>,
        stackStyles: {
            root: {
                minWidth: 65,
                maxWidth: 65,
                alignSelf: 'center',
                paddingLeft: 15,
            },
        },
    },
};

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const tableRootStyle: IStyle = {
    overflow: 'hidden',
    selectors: {
        '& [role=row]': {
            backgroundColor: 'transparent',
        },
        '& [role=gridcell], & [role=rowheader]': {
            minHeight: '49px',
            display: 'flex',
            alignItems: 'center',
            padding: '4px 4px 4px 0px',
            borderBottom: 'none',
        },
        '& [role=columnheader]': {
            paddingLeft: '0px',
        },
        '& .ms-DetailsHeader > .ms-GroupSpacer': {
            width: '0px !important',
            padding: '0px !important',
        },
        '& .ms-DetailsHeader-cellTitle': {
            paddingLeft: '0px',
        },
        '& .ms-StackItem': {
            paddingLeft: '0px',
        },
    },
};

const theme = getTheme();

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    keepInBounds: false,
};
