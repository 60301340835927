import React, { createContext, useState, useEffect } from 'react';
import {
    FilterDataType,
    FilterCategorySettingType,
    DefinedFilterType,
} from 'components/staffing/staffing-page-types';
import { Dictionary } from 'assets/constants/global-constants';
import deepcopy from 'deepcopy';

type FilterType = Dictionary<Dictionary<FilterDataType>>;

interface IStaffingFiltersContextProviderProps {
    initFilterValues: FilterType;
    children: JSX.Element[] | JSX.Element;
}

export default function StaffingFiltersContextProvider(
    props: IStaffingFiltersContextProviderProps,
): JSX.Element {
    const [filters, setFilters] = useState<FilterType>({});

    function setContextFilter(
        key: string,
        subKey: string | undefined,
        value: FilterDataType,
    ): void {
        if (subKey === undefined) {
            return;
        }
        /**
         * Example:
         *      filters = {
         *          cloud: {
         *              RX: false,
         *              TX: true,
         *          },
         *          status: {
         *              Ready: true,
         *              InProcess: false,
         *          }
         *      }
         */
        setFilters((currentValue) => {
            const filtersVar = deepcopy(currentValue);
            if (filtersVar[key] === undefined) {
                filtersVar[key] = {};
            }
            filtersVar[key][subKey] = value;
            return filtersVar;
        });
    }

    useEffect(() => {
        setFilters((currentValue) => {
            const filtersVar = deepcopy(currentValue);
            Object.entries(props.initFilterValues ?? {}).forEach(
                ([filterCategory, categorySettings]) => {
                    if (filtersVar[filterCategory] === undefined) {
                        filtersVar[filterCategory] = {};
                    }
                    Object.entries(categorySettings).forEach(([key, value]) => {
                        filtersVar[filterCategory][key] = value as FilterDataType;
                    });
                },
            );
            return filtersVar;
        });
    }, [props.initFilterValues]);

    return (
        <StaffingFiltersContext.Provider
            value={{
                filters,
                setFilter: (
                    key: string,
                    subKey: string | undefined,
                    value: FilterDataType,
                ): void => {
                    setContextFilter(key, subKey, value);
                },
            }}>
            {props.children}
        </StaffingFiltersContext.Provider>
    );
}

export const StaffingFiltersContext = createContext<IStaffingFiltersContext>(null!);

export interface IStaffingFiltersContext {
    filters: FilterCategorySettingType;
    setFilter: (key: string, subKey: string | undefined, value: FilterDataType) => void;
}

export function extractDefinedFilters(filters: FilterCategorySettingType): DefinedFilterType[] {
    /**
     * Example: Returned result will be similar to the following:
     *
        [
            ['status', 'READY', true],
            ['status', 'PRE-HIRE', true],
            ['cloud', 'RX', true],
            ['team', 'Team1', true],
            [
                'employee',
                'info',
                {
                    imageInitials: 'AN',
                    text: 'Ali Najafi (Apex Systems  LLC)',
                    secondaryText: 'v-alinajafi@microsoft.com',
                    tertiaryText: 'V-ALINAJAFI',
                    showInitialsUntilImageLoads: true,
                    itemProp: '{"id":"1456413","alias":"V-ALINAJAFI","firstName":"Ali","middleName":null,"lastName":"Najafi","fullName":"Najafi, Ali","buildingId":99997,"buildingName":"NO WORKSPACE","locationAreaCity":null,"locationAreaCode":"US","locationAreaDetail":null,"country":"United States","companyCode":"1010","costCenterCode":"10088909","positionNumber":92037377,"reportsToEmailName":"MKEATING","addressBookTitleDesc":null,"standardTitle":"XR - Service Engineering","profession":"Engineering","discipline":"Service Engineering","personStatusCode":"Z","csCompany":"Apex Systems  LLC","csCompanyNumber":"0002211505","csPersonnelNumber":1456413,"isFTE":false,"startDate":"2020-11-23T00:00:00","positionStartDate":"2020-11-23T00:00:00","terminationDate":null,"employeeLvl":9,"hierarchyLvl1":30958,"hierarchyLvl2":43368,"hierarchyLvl3":29852,"hierarchyLvl4":156333,"hierarchyLvl5":6101838,"hierarchyLvl6":189334,"hierarchyLvl7":234144,"hierarchyLvl8":146354,"hierarchyLvl9":-1,"hierarchyLvl10":-1,"hierarchyLvl11":-1,"hierarchyLvl12":-1,"hierarchyLvl13":-1,"hierarchyLvl14":-1,"hierarchyLvl15":-1,"hierarchyLvl16":-1,"hierarchyLvl17":-1,"isActiveEmployee":true,"oid":"3ebee425-e6ef-4d97-8848-0d9cfccd8b30","preferredFirstName":"Ali","preferredLastName":"Najafi","displayName":"Ali Najafi (Apex Systems  LLC)","email":"v-alinajafi@microsoft.com","userPrincipalName":"v-alinajafi@microsoft.com","jobTitle":null,"department":"Azure Global Core - US 1010","companyName":"MICROSOFT","officeLocation":"No WorkSpace","businessPhones":[],"accountEnabled":true,"onPremisesSamAccountName":"v-alinajafi","onPremisesDomainName":"redmond.corp.microsoft.com","hasGraphRecord":true}'
                }
            ],
        ]
     *
     */

    const result = [] as DefinedFilterType[];
    Object.entries(filters || {}).forEach(([filterCategory, filterSettings]) => {
        Object.entries(filterSettings).forEach(([filterName, filterValue]) => {
            if (filterValue) {
                result.push([filterCategory, filterName, filterValue]);
            }
        });
    });
    return result;
}

export const initializeFilter = (
    currentValue: any,
    key: string,
    subKey: string | undefined,
    value: FilterDataType,
): void => {
    if (subKey === undefined) {
        return;
    }
    if (currentValue[key] === undefined) {
        currentValue[key] = {} as Dictionary<FilterDataType>;
    }
    currentValue[key][subKey] = value;
};
