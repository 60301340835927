// Screenings service calls this record type "ContractDetail".
export interface IContract {
    id: string;
    project: string;
    customer: string;
    lastModified?: {
        atUtc: number;
        by: string;
    };
    officer?: IContractOfficer;
    owners?: string[];
    contractType?: keyof typeof ContractType;
    contractStatus?: string;
}

export enum ContractStatus {
    Active = 'Active',
    Inactive = 'Inactive',
    Disabled = 'Disabled',
}

export enum ContractType {
    USGovScreening = 'US Gov',
    PublicTrust = 'Public Trust',
}

/**
 * When sending a request for contracts, the parameter names
 * can be either of these values.
 */
export enum ContractRequestParamNames {
    ContractTypes = 'contractTypes',
    Search = 'search',
    ResultCount = 'resultCount',
}

export interface IContractRequest extends IContract {
    lastOperation?: string;
}

export interface IContractOfficer {
    name: string;
    phone: string;
}

export enum ContractsPageModalTypes {
    add = 'Add',
    edit = 'Edit',
    changeOwner = 'ChangeOwner',
}

export function getContractTypeEnumValueFromKey(key: string | undefined): string {
    if (key) {
        const value = ContractType[key as keyof typeof ContractType];

        if (value) {
            return value;
        }
        return key;
    }
    return '';
}

export function deepCopyContract(contract: IContract): IContract {
    const copiedContract: IContract = {
        id: '',
        project: '',
        customer: '',
    };

    if (!contract) {
        return copiedContract;
    }

    // TODO: Trying to find a way to iterate over IContract interface for easier integration in future...
    // - Will continue to look at in a future PR
    // const headers: Array<Object> = Object.keys(IContract).map((key) => {
    //     return { text: key, value: key };
    // });

    copiedContract.id = contract.id;
    copiedContract.project = contract.project;
    copiedContract.customer = contract.customer;
    copiedContract.lastModified = contract.lastModified ?? undefined;
    copiedContract.officer = contract.officer ?? undefined;
    copiedContract.owners = contract.owners ?? undefined;
    copiedContract.contractType = contract.contractType ?? undefined;
    copiedContract.contractStatus = contract.contractStatus ?? undefined;

    return copiedContract;
}
