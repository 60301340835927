import React from 'react';
import { tableColumnsEligibilities } from 'components/user-assignments/manage-eligibilities/table-columns-eligibilities';
import { IEligibilityAssignment, IEligibilityAssignmentDetail } from 'clients/eligibility-client';
import { useSortColumnHandler, strCmp, numCmp } from 'utils/sort-utils';
import moment from 'moment';
import { Table } from 'components/common/table';
import { IEmployee } from 'clients/employee-client';
import HorizontalBar from 'components/common/horizontal-bar';
import AddEligibilityModalActionButton from 'components/user-assignments/manage-eligibilities/add-eligibility-modal-action-button';
import { mergeStyleSets } from '@fluentui/react';
import { IVisitorProfile } from 'clients/visitor-client';

interface IManageEligibilitiesProps {
    isLoading: boolean;
    eligibilityAssignment: IEligibilityAssignment;
    visitorOrEmployee: IEmployee | IVisitorProfile;
    updateAddEligibilityAssignment: () => void;
    deleteEligibilityFromTable: (eligibility: IEligibilityAssignmentDetail) => void;
    personnelId: string;
    showAddEligibilityButton: boolean;
    showDeleteEligibilityButton: boolean;
}

export default function ManageEligibilities(props: IManageEligibilitiesProps): JSX.Element {
    const { visitorOrEmployee, deleteEligibilityFromTable } = props;

    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler('Code', 1);

    const sortEligibilities = (
        eligibilities: IEligibilityAssignmentDetail[],
    ): IEligibilityAssignmentDetail[] => {
        type A = IEligibilityAssignmentDetail;

        const chooseSortCmp = (
            sortColumn: string,
        ): ((r1: IEligibilityAssignmentDetail, r2: IEligibilityAssignmentDetail) => number) => {
            switch (sortColumn) {
                case 'Code':
                    return (r1: A, r2: A): number =>
                        sortAscending * strCmp(r1.eligibilityCode, r2.eligibilityCode);
                case 'Name':
                    return (r1: A, r2: A): number =>
                        sortAscending * strCmp(r1.eligibilityName, r2.eligibilityName);
                case 'Starts':
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(moment(r1.startDate).valueOf(), moment(r2.startDate).valueOf());
                case 'Expires':
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(moment(r1.endDate).valueOf(), moment(r2.endDate).valueOf());
                case 'Granted on':
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(moment(r1.assignedAt).valueOf(), moment(r2.assignedAt).valueOf());
                default:
                    // Sort column not recognized. No sorting performed.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp(sortColumn);
        return [...eligibilities].sort(sortCmp);
    };

    const tableColumns = tableColumnsEligibilities({
        visitorOrEmployee,
        sortColumn,
        sortAscending: sortAscending === 1,
        showDeleteEligibilityButton: props.showDeleteEligibilityButton,
        sortColumnHandler,
        deleteEligibilityFromTable,
    });

    return (
        <>
            {props.showAddEligibilityButton && (
                <HorizontalBar>
                    <AddEligibilityModalActionButton {...props} />
                </HorizontalBar>
            )}
            {props.eligibilityAssignment.eligibilities.length < 1 ? (
                <div className={styles.helpText}>
                    No personnel eligibilities have been added. Please reach out to{' '}
                    <a href='mailto:personnel_help@microsoft.com'>personnel_help@microsoft.com</a>{' '}
                    if you need eligibilities added.
                </div>
            ) : (
                <Table
                    tableColumns={tableColumns}
                    rows={sortEligibilities(props.eligibilityAssignment?.eligibilities ?? [])}
                    isFetchingData={false}
                    tableName='Manage Eligibilities'
                />
            )}
        </>
    );
}

const styles = mergeStyleSets({
    helpText: {
        paddingTop: 16,
        paddingLeft: 20,
        fontSize: 16,
    },
});
