import React, { useContext, useEffect, useState } from 'react';
import { mergeStyleSets, FontWeights, Separator, Icon } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import ContainerWithEtiquettes, {
    IContainerWithEtiquettesProps,
} from 'components/common/container-with-etiquettes';
import {
    IEmployee,
    IEmployeeEditableInfo,
    IEmployeeWithEditableData,
    AllEmployeeEditableFields,
} from 'clients/employee-client';
import { isGUID, toTitleCase } from 'utils/string-utils';
import EllipsisText from 'components/common/ellipsis-text';
import {
    getJobTitleOrDefault,
    getEmployeeTypeLabel,
} from 'components/common/employee/internal-employee-utils';
import EditableEmployeeData, {
    UpdateKeyVauleType,
} from 'components/common/employee/editable-employee-data';
import { UserContext } from 'contexts/user-context';
import { AuthContext } from 'contexts/auth-context';
import { REGEX_EMAIL, REGEX_PHONE, REGEX_SSN } from 'utils/misc-utils';
import { noDataText } from 'assets/constants/global-constants';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';

export interface EmployeeDataProps {
    employee: IEmployeeWithEditableData;
    allowEditableEmployeeData?: boolean;
    updateEmployeeData: (newEmployeeData?: IEmployeeWithEditableData) => void;
}

//TODO: Find a way to render this component without a fix width on the first column
export function EmployeeData(props: EmployeeDataProps): JSX.Element {
    const userContext = useContext(UserContext);
    const authContext = useContext(AuthContext);
    const [editableEmployeeInfo, setEditableEmployeeInfo] = useState<
        IEmployeeEditableInfo | undefined
    >();
    const [editableDataProvidedByHR, seteditableDataProvidedByHR] = useState<string[] | undefined>(
        props.employee.editablePropertiesProvidedByHR,
    );

    const [containerProps, setContainerProps] = useState<IContainerWithEtiquettesProps>({
        leftEtiquetteLabel: 'Employee Data',
        rightEtiquette: {
            label: 'FTE',
            backgroundColor: SharedColors.cyanBlue10,
        },
    });

    useEffect(() => {
        setContainerProps({
            leftEtiquetteLabel: 'Employee Data',
            rightEtiquette: getEmployeeTypeLabel(
                props.employee.data,
                props.employee.employeeStatus,
            ),
        });
    }, [props.employee]);

    useEffect(() => {
        if (
            props.allowEditableEmployeeData &&
            props.allowEditableEmployeeData === true &&
            userContext &&
            authContext &&
            props.employee &&
            (userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST) ||
                userContext.hasPublicTrustUserType(UsGovScreeningUserType.NST))
        ) {
            setEditableEmployeeInfo(props.employee.data);
            seteditableDataProvidedByHR(props.employee.editablePropertiesProvidedByHR);
        }
    }, [props.allowEditableEmployeeData, userContext, props.employee, authContext]);

    function isDataProvidedByHR(secureField: AllEmployeeEditableFields): boolean {
        if (editableDataProvidedByHR) {
            return (
                editableDataProvidedByHR.findIndex(
                    (item) => secureField.toLowerCase() === item.toLowerCase(),
                ) > -1
            );
        }
        return false;
    }

    const updateEditableEmployeeData: UpdateKeyVauleType = (key, value) => {
        if (editableEmployeeInfo) {
            const newEditableEmployeeData = { ...editableEmployeeInfo };
            newEditableEmployeeData[key] = value;
            setEditableEmployeeInfo(newEditableEmployeeData);

            // if profile is not a FTE and the updated editable value needs to trigger the parent component to re-render
            // via the updateEmployeeDate. This is to make the employee card info to match the newly updated editable value
            if (isGUID(props.employee.data?.id)) {
                const newEmployeeData = {
                    data: { ...props.employee.data, ...newEditableEmployeeData },
                    employeeStatus: props.employee.employeeStatus,
                    editablePropertiesProvidedByHR: props.employee.editablePropertiesProvidedByHR,
                };
                props.updateEmployeeData(newEmployeeData);
            }
        }
    };

    function allowShowElseShow(allowShow: JSX.Element, elseShow: JSX.Element): JSX.Element {
        if (
            editableEmployeeInfo &&
            props.allowEditableEmployeeData &&
            (userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST) ||
                userContext.hasPublicTrustUserType(UsGovScreeningUserType.NST))
        ) {
            return allowShow;
        }
        return elseShow;
    }

    function showNonEdit(secureField: AllEmployeeEditableFields): JSX.Element {
        return (
            <>
                {editableEmployeeInfo && editableEmployeeInfo[secureField]
                    ? editableEmployeeInfo[secureField]
                    : ''}
            </>
        );
    }

    return (
        <ContainerWithEtiquettes {...containerProps}>
            <div className={styles.employeeData}>
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>First Name</div>
                        <div className={styles.value}>
                            {props.employee.data.firstName &&
                            isDataProvidedByHR(AllEmployeeEditableFields.firstName)
                                ? props.employee.data.firstName
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.firstName}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'First Name'}
                                          dialogTitle={'Edit First Name'}
                                          dialogLabel={'First Name'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.firstName),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Middle Name</div>
                        <div className={styles.value}>
                            {props.employee.data.middleName &&
                            isDataProvidedByHR(AllEmployeeEditableFields.middleName)
                                ? props.employee.data.middleName
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.middleName}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Middle Name'}
                                          dialogTitle={'Edit Middle Name'}
                                          dialogLabel={'Middle Name'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.middleName),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Last Name</div>
                        <div className={styles.value}>
                            {props.employee.data.lastName &&
                            isDataProvidedByHR(AllEmployeeEditableFields.lastName)
                                ? props.employee.data.lastName
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.lastName}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Last Name'}
                                          dialogTitle={'Edit Last Name'}
                                          dialogLabel={'Last Name'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.lastName),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Suffix</div>
                        <div className={styles.value}>
                            {props.employee.data.suffix &&
                            isDataProvidedByHR(AllEmployeeEditableFields.suffix)
                                ? props.employee.data.suffix
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.suffix}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Suffix'}
                                          dialogTitle={'Edit Suffix'}
                                          dialogLabel={'Suffix'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.suffix),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Maiden Name</div>
                        <div className={styles.value}>
                            {props.employee.data.maidenName &&
                            isDataProvidedByHR(AllEmployeeEditableFields.maidenName)
                                ? props.employee.data.maidenName
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.maidenName}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Maiden Name'}
                                          dialogTitle={'Edit Maiden Name'}
                                          dialogLabel={'Maiden Name'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.maidenName),
                                  )}
                        </div>
                    </div>
                </div>
                <Separator styles={separatorStyles} />
                <div className={styles.dataLine}>
                    <div className={styles.key}>Microsoft Id</div>
                    <div className={styles.value}>
                        {!isGUID(props.employee.data?.id) && props.employee.data?.id
                            ? props.employee.data?.id
                            : allowShowElseShow(
                                  <EditableEmployeeData
                                      employee={props.employee}
                                      secureField={AllEmployeeEditableFields.microsoftId}
                                      editableData={editableEmployeeInfo}
                                      placeholder={'Microsoft Id'}
                                      dialogTitle={'Edit Microsoft Id'}
                                      dialogLabel={'Microsoft Id'}
                                      type={'Text'}
                                      updateKeyValue={updateEditableEmployeeData}
                                  />,
                                  showNonEdit(AllEmployeeEditableFields.microsoftId),
                              )}
                    </div>
                </div>
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>US Citizenship</div>
                        {!isGUID(props.employee.data?.id) ? (
                            <div className={styles.multivalue}>
                                <div>{displayCheckBox(props.employee.data.isUSCitizen)}</div>
                                <div>
                                    <span
                                        style={{
                                            opacity: isTrue(props.employee.data.isUSCitizen)
                                                ? undefined
                                                : 0.67,
                                        }}>
                                        Verified
                                    </span>
                                </div>
                            </div>
                        ) : (
                            allowShowElseShow(
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.isUSCitizen}
                                    editableData={editableEmployeeInfo}
                                    dialogTitle={'Edit US Citizenship'}
                                    dialogLabel={'Verified'}
                                    type={'Checkbox'}
                                    updateKeyValue={updateEditableEmployeeData}
                                />,
                                <>
                                    <div>{displayCheckBox(props.employee.data.isUSCitizen)}</div>
                                    <div>
                                        <span
                                            style={{
                                                opacity: isTrue(props.employee.data.isUSCitizen)
                                                    ? undefined
                                                    : 0.5,
                                            }}>
                                            Verified
                                        </span>
                                    </div>
                                </>,
                            )
                        )}
                    </div>
                    {(userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST) ||
                        userContext.hasPublicTrustUserType(UsGovScreeningUserType.NST)) && (
                        <>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>Social Security Number</div>
                                <div className={styles.value}>
                                    {props.employee.data.maidenName &&
                                    isDataProvidedByHR(AllEmployeeEditableFields.nationalIdNumber)
                                        ? props.employee.data.maidenName
                                        : allowShowElseShow(
                                              <EditableEmployeeData
                                                  employee={props.employee}
                                                  secureField={
                                                      AllEmployeeEditableFields.nationalIdNumber
                                                  }
                                                  editableData={editableEmployeeInfo}
                                                  placeholder={'###-##-####'}
                                                  regex={REGEX_SSN}
                                                  errorMessage={
                                                      'Must be a nine digit number in the format ###-##-####'
                                                  }
                                                  dialogTitle={'Edit Social Security Number'}
                                                  dialogLabel={'SSN'}
                                                  type={'Text'}
                                                  updateKeyValue={updateEditableEmployeeData}
                                              />,
                                              showNonEdit(AllEmployeeEditableFields.maidenName),
                                          )}
                                </div>
                            </div>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>Birth Place</div>
                                <div className={styles.value}>
                                    {props.employee.data.birthPlace &&
                                    isDataProvidedByHR(AllEmployeeEditableFields.birthPlace)
                                        ? props.employee.data.birthPlace
                                        : allowShowElseShow(
                                              <EditableEmployeeData
                                                  employee={props.employee}
                                                  secureField={AllEmployeeEditableFields.birthPlace}
                                                  editableData={editableEmployeeInfo}
                                                  placeholder={'City, State, Country'}
                                                  dialogTitle={'Edit Birth Place'}
                                                  dialogLabel={'Birth Place'}
                                                  type={'Text'}
                                                  updateKeyValue={updateEditableEmployeeData}
                                              />,
                                              showNonEdit(AllEmployeeEditableFields.birthPlace),
                                          )}
                                </div>
                            </div>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>Birth Date</div>
                                <div className={styles.value}>
                                    <>
                                        {props.employee.data.birthDate &&
                                        isDataProvidedByHR(AllEmployeeEditableFields.birthDate)
                                            ? props.employee.data.birthDate
                                            : allowShowElseShow(
                                                  <EditableEmployeeData
                                                      employee={props.employee}
                                                      secureField={
                                                          AllEmployeeEditableFields.birthDate
                                                      }
                                                      editableData={editableEmployeeInfo}
                                                      placeholder={'Select date'}
                                                      dialogTitle={'Edit Birth Date'}
                                                      dialogLabel={'Birth Date'}
                                                      type={'Date'}
                                                      updateKeyValue={updateEditableEmployeeData}
                                                  />,
                                                  showNonEdit(AllEmployeeEditableFields.birthDate),
                                              )}
                                    </>
                                </div>
                            </div>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>Passport Number</div>
                                <div className={styles.value}>
                                    {props.employee.data.passportId &&
                                    isDataProvidedByHR(AllEmployeeEditableFields.passportId)
                                        ? props.employee.data.passportId
                                        : allowShowElseShow(
                                              <EditableEmployeeData
                                                  employee={props.employee}
                                                  secureField={AllEmployeeEditableFields.passportId}
                                                  editableData={editableEmployeeInfo}
                                                  placeholder={'Passport Number'}
                                                  dialogTitle={'Edit Passport Number'}
                                                  dialogLabel={'Passport Number'}
                                                  type={'Text'}
                                                  updateKeyValue={updateEditableEmployeeData}
                                              />,
                                              showNonEdit(AllEmployeeEditableFields.passportId),
                                          )}
                                </div>
                            </div>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>Passport Issue Date</div>
                                <div className={styles.value}>
                                    <>
                                        {props.employee.data.passportIssueDate &&
                                        isDataProvidedByHR(
                                            AllEmployeeEditableFields.passportIssueDate,
                                        )
                                            ? props.employee.data.passportIssueDate
                                            : allowShowElseShow(
                                                  <EditableEmployeeData
                                                      employee={props.employee}
                                                      secureField={
                                                          AllEmployeeEditableFields.passportIssueDate
                                                      }
                                                      editableData={editableEmployeeInfo}
                                                      placeholder={'Select date'}
                                                      dialogTitle={'Edit Passport Issue Date'}
                                                      dialogLabel={'Passport Issue Date'}
                                                      type={'Date'}
                                                      updateKeyValue={updateEditableEmployeeData}
                                                  />,
                                                  showNonEdit(
                                                      AllEmployeeEditableFields.passportIssueDate,
                                                  ),
                                              )}
                                    </>
                                </div>
                            </div>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>Passport Expiration Date</div>
                                <div className={styles.value}>
                                    <>
                                        {props.employee.data.passportExpirationDate &&
                                        isDataProvidedByHR(
                                            AllEmployeeEditableFields.passportExpirationDate,
                                        )
                                            ? props.employee.data.passportExpirationDate
                                            : allowShowElseShow(
                                                  <EditableEmployeeData
                                                      employee={props.employee}
                                                      secureField={
                                                          AllEmployeeEditableFields.passportExpirationDate
                                                      }
                                                      editableData={editableEmployeeInfo}
                                                      placeholder={'Select date'}
                                                      dialogTitle={'Edit Passport Expiration Date'}
                                                      dialogLabel={'Passport Expiration Date'}
                                                      type={'Date'}
                                                      updateKeyValue={updateEditableEmployeeData}
                                                  />,
                                                  showNonEdit(
                                                      AllEmployeeEditableFields.passportExpirationDate,
                                                  ),
                                              )}
                                    </>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <Separator styles={separatorStyles} />
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>KMP</div>
                        {!isGUID(props.employee.data?.id) ? (
                            <div className={styles.multivalue}>
                                <div>{displayCheckBox(props.employee.data.isKmp)}</div>
                                <div>
                                    <span
                                        style={{
                                            opacity: isTrue(props.employee.data.isKmp)
                                                ? undefined
                                                : 0.67,
                                        }}>
                                        Designated
                                    </span>
                                </div>
                            </div>
                        ) : (
                            allowShowElseShow(
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.isKmp}
                                    editableData={editableEmployeeInfo}
                                    dialogTitle={'Edit KMP'}
                                    dialogLabel={'Designated'}
                                    type={'Checkbox'}
                                    updateKeyValue={updateEditableEmployeeData}
                                />,
                                <>
                                    <div>{displayCheckBox(props.employee.data.isKmp)}</div>
                                    <div>
                                        <span
                                            style={{
                                                opacity: isTrue(props.employee.data.isKmp)
                                                    ? undefined
                                                    : 0.5,
                                            }}>
                                            Designated
                                        </span>
                                    </div>
                                </>,
                            )
                        )}
                    </div>
                </div>
                <Separator styles={separatorStyles} />
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Company</div>
                        <div className={styles.value}>
                            {props.employee.data.employeeType &&
                            isDataProvidedByHR(AllEmployeeEditableFields.employeeType)
                                ? props.employee.data.suffix
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.employeeType}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Company'}
                                          dialogTitle={'Edit Company'}
                                          dialogLabel={'Company'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.employeeType),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Company Code</div>
                        <div className={styles.value}>
                            {props.employee.data.companyCode &&
                            isDataProvidedByHR(AllEmployeeEditableFields.companyCode)
                                ? props.employee.data.companyCode
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.companyCode}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Company Code'}
                                          dialogTitle={'Edit Company Code'}
                                          dialogLabel={'Company Code'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.companyCode),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Cost Center</div>
                        <div className={styles.value}>
                            {props.employee.data.costCenterCode &&
                            props.employee.data.department &&
                            isDataProvidedByHR(AllEmployeeEditableFields.costCenterCode) ? (
                                <span className={styles.costCenter}>
                                    <EllipsisText
                                        text={`${props.employee.data.costCenterCode} - ${props.employee.data.department}`}
                                        textLengthBeforeEllipsis={25}
                                    />
                                </span>
                            ) : (
                                allowShowElseShow(
                                    <EditableEmployeeData
                                        employee={props.employee}
                                        secureField={AllEmployeeEditableFields.costCenterCode}
                                        editableData={editableEmployeeInfo}
                                        placeholder={'Cost Center'}
                                        dialogTitle={'Edit Cost Center'}
                                        dialogLabel={'Cost Center'}
                                        type={'Text'}
                                        updateKeyValue={updateEditableEmployeeData}
                                    />,
                                    showNonEdit(AllEmployeeEditableFields.costCenterCode),
                                )
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>PCN</div>
                        <div className={styles.value}>
                            {props.employee.data.positionNumber &&
                            isDataProvidedByHR(AllEmployeeEditableFields.positionNumber)
                                ? props.employee.data.positionNumber
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.positionNumber}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Position Control Number'}
                                          dialogTitle={'Edit Position Control Number'}
                                          dialogLabel={'PCN'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.positionNumber),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Start Date</div>
                        <div className={styles.value}>
                            {props.employee.data.startDate &&
                            isDataProvidedByHR(AllEmployeeEditableFields.startDate)
                                ? new Date(props.employee.data.startDate).toDateString()
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.startDate}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Select date'}
                                          dialogTitle={'Edit Start Date'}
                                          dialogLabel={'Start Date'}
                                          type={'Date'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.startDate),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>End Date</div>
                        <div className={styles.value}>
                            {!isGUID(props.employee?.data?.id)
                                ? props.employee?.data.terminationDate
                                    ? new Date(props.employee.data.terminationDate).toDateString()
                                    : noDataText
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.terminationDate}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Select date'}
                                          dialogTitle={'Edit End Date'}
                                          dialogLabel={'End Date'}
                                          type={'Date'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.terminationDate),
                                  )}
                        </div>
                    </div>
                </div>
                <Separator styles={separatorStyles} />
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Profession</div>
                        <div className={styles.value}>
                            {props.employee.data.profession &&
                            isDataProvidedByHR(AllEmployeeEditableFields.profession)
                                ? props.employee.data.profession
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.profession}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Profession'}
                                          dialogTitle={'Edit Profession'}
                                          dialogLabel={'Profession'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.profession),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Discipline</div>
                        <div className={styles.value}>
                            {props.employee.data.discipline &&
                            isDataProvidedByHR(AllEmployeeEditableFields.discipline)
                                ? props.employee.data.discipline
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.discipline}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Discipline'}
                                          dialogTitle={'Edit Discipline'}
                                          dialogLabel={'Discipline'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.discipline),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Title</div>
                        <div className={styles.value}>
                            {!isGUID(props.employee.data?.id)
                                ? getJobTitleOrDefault(
                                      props.employee.data as IEmployee,
                                  ).toUpperCase()
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.standardTitle}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Title'}
                                          dialogTitle={'Edit Title'}
                                          dialogLabel={'Title'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.standardTitle),
                                  )}
                        </div>
                    </div>
                </div>
                <Separator styles={separatorStyles} />
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>City</div>
                        <div className={styles.value}>
                            {props.employee.data.locationAreaCity &&
                            isDataProvidedByHR(AllEmployeeEditableFields.locationCity)
                                ? toTitleCase(props.employee.data.locationAreaCity)
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.locationCity}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'City'}
                                          dialogTitle={'Edit City'}
                                          dialogLabel={'City'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.locationCity),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>State</div>
                        <div className={styles.value}>
                            {props.employee.data.locationAreaDetail &&
                            isDataProvidedByHR(AllEmployeeEditableFields.locationAreaDetail)
                                ? props.employee.data.locationAreaDetail
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.locationAreaDetail}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'State'}
                                          dialogTitle={'Edit State'}
                                          dialogLabel={'State'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.locationAreaDetail),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Country</div>
                        <div className={styles.value}>
                            {props.employee.data.country &&
                            props.employee.data.locationAreaCode &&
                            isDataProvidedByHR(AllEmployeeEditableFields.locationCountry)
                                ? `${props.employee.data.country} (${props.employee.data.locationAreaCode})`
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.locationCountry}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Country'}
                                          dialogTitle={'Edit Country'}
                                          dialogLabel={'Country'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.locationCountry),
                                  )}
                        </div>
                    </div>
                </div>
                <Separator styles={separatorStyles} />
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Work Phone</div>
                        <div className={styles.value}>
                            {props.employee.data.businessPhones &&
                            props.employee.data.businessPhones.length > 0 &&
                            isDataProvidedByHR(AllEmployeeEditableFields.workPhone)
                                ? props.employee.data.businessPhones
                                      .filter(
                                          (value: string, index: number, self: string[]) =>
                                              self.indexOf(value) === index,
                                      )
                                      .map((x) => {
                                          return <div key={x}>{x}</div>;
                                      })
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.workPhone}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Work Phone'}
                                          dialogTitle={'Edit Work Phone'}
                                          dialogLabel={'Work Phone'}
                                          regex={REGEX_PHONE}
                                          errorMessage={'Must be a valid phone number format'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.workPhone),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Work Email</div>
                        <div className={styles.value}>
                            {props.employee.data.email &&
                            isDataProvidedByHR(AllEmployeeEditableFields.workEmail)
                                ? props.employee.data.email
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.workEmail}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Work Email'}
                                          dialogTitle={'Edit Work Email'}
                                          dialogLabel={'Work Email'}
                                          regex={REGEX_EMAIL}
                                          errorMessage={'Must be a valid email format'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.workEmail),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Home Phone</div>
                        <div className={styles.value}>
                            {props.employee.data.homePhone &&
                            isDataProvidedByHR(AllEmployeeEditableFields.homePhone)
                                ? props.employee.data.homePhone
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.homePhone}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Home Phone'}
                                          dialogTitle={'Edit Home Phone'}
                                          dialogLabel={'Home Phone'}
                                          regex={REGEX_PHONE}
                                          errorMessage={'Must be a valid phone number format'}
                                          type={'Text'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.homePhone),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Personal Cell Phone</div>
                        <div className={styles.value}>
                            {props.employee.data.personalCellPhone &&
                            isDataProvidedByHR(AllEmployeeEditableFields.personalCellPhone)
                                ? props.employee.data.personalCellPhone
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.personalCellPhone}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Personal Cell Phone'}
                                          dialogTitle={'Edit Personal Cell Phone'}
                                          dialogLabel={'Personal Cell Phone'}
                                          regex={REGEX_PHONE}
                                          type={'Text'}
                                          errorMessage={'Must be a valid phone number format'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.personalCellPhone),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Personal Email</div>
                        <div className={styles.value}>
                            {props.employee.data.personalEmail &&
                            isDataProvidedByHR(AllEmployeeEditableFields.personalEmail)
                                ? props.employee.data.personalEmail
                                : allowShowElseShow(
                                      <EditableEmployeeData
                                          employee={props.employee}
                                          secureField={AllEmployeeEditableFields.personalEmail}
                                          editableData={editableEmployeeInfo}
                                          placeholder={'Personal Email'}
                                          dialogTitle={'Edit Personal Email'}
                                          dialogLabel={'Personal Email'}
                                          regex={REGEX_EMAIL}
                                          type={'Text'}
                                          errorMessage={'Must be a valid email format'}
                                          updateKeyValue={updateEditableEmployeeData}
                                      />,
                                      showNonEdit(AllEmployeeEditableFields.personalEmail),
                                  )}
                        </div>
                    </div>
                    {props.employee.data.homeAddress &&
                    isDataProvidedByHR(AllEmployeeEditableFields.homeAddress) ? (
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Home Address</div>
                            <div className={styles.value}>{props.employee.data.homeAddress}</div>
                        </div>
                    ) : (
                        allowShowElseShow(
                            <>
                                <div className={styles.dataLine}>
                                    <div className={styles.key}>Home Address</div>
                                    <div className={styles.value}>
                                        <EditableEmployeeData
                                            employee={props.employee}
                                            secureField={AllEmployeeEditableFields.homeAddress}
                                            editableData={editableEmployeeInfo}
                                            placeholder={'Home Address'}
                                            dialogTitle={'Edit Home Address'}
                                            dialogLabel={'Home Address'}
                                            type={'Text'}
                                            updateKeyValue={updateEditableEmployeeData}
                                        />
                                    </div>
                                </div>
                                <div className={styles.dataLine}>
                                    <div className={styles.key}>Home City</div>
                                    <div className={styles.value}>
                                        {props.employee.data.homeCity &&
                                        isDataProvidedByHR(AllEmployeeEditableFields.homeCity)
                                            ? props.employee.data.homeCity
                                            : allowShowElseShow(
                                                  <EditableEmployeeData
                                                      employee={props.employee}
                                                      secureField={
                                                          AllEmployeeEditableFields.homeCity
                                                      }
                                                      editableData={editableEmployeeInfo}
                                                      placeholder={'Home City'}
                                                      dialogTitle={'Edit Home City'}
                                                      dialogLabel={'Home City'}
                                                      type={'Text'}
                                                      updateKeyValue={updateEditableEmployeeData}
                                                  />,
                                                  showNonEdit(AllEmployeeEditableFields.homeCity),
                                              )}
                                    </div>
                                </div>
                                <div className={styles.dataLine}>
                                    <div className={styles.key}>Home State</div>
                                    <div className={styles.value}>
                                        {props.employee.data.homeState &&
                                        isDataProvidedByHR(AllEmployeeEditableFields.homeState)
                                            ? props.employee.data.homeState
                                            : allowShowElseShow(
                                                  <EditableEmployeeData
                                                      employee={props.employee}
                                                      secureField={
                                                          AllEmployeeEditableFields.homeState
                                                      }
                                                      editableData={editableEmployeeInfo}
                                                      placeholder={'Home State'}
                                                      dialogTitle={'Edit Home State'}
                                                      dialogLabel={'Home State'}
                                                      type={'Text'}
                                                      updateKeyValue={updateEditableEmployeeData}
                                                  />,
                                                  showNonEdit(AllEmployeeEditableFields.homeState),
                                              )}
                                    </div>
                                </div>
                                <div className={styles.dataLine}>
                                    <div className={styles.key}>Home Zip</div>
                                    <div className={styles.value}>
                                        {props.employee.data.homeZip &&
                                        isDataProvidedByHR(AllEmployeeEditableFields.homeZip)
                                            ? props.employee.data.homeZip
                                            : allowShowElseShow(
                                                  <EditableEmployeeData
                                                      employee={props.employee}
                                                      secureField={
                                                          AllEmployeeEditableFields.homeZip
                                                      }
                                                      editableData={editableEmployeeInfo}
                                                      placeholder={'Home Zip Code'}
                                                      dialogTitle={'Edit Home Zip Code'}
                                                      dialogLabel={'Home Zip Code'}
                                                      type={'Text'}
                                                      updateKeyValue={updateEditableEmployeeData}
                                                  />,
                                                  showNonEdit(AllEmployeeEditableFields.homeZip),
                                              )}
                                    </div>
                                </div>
                                <div className={styles.dataLine}>
                                    <div className={styles.key}>Home Country</div>
                                    <div className={styles.value}>
                                        {props.employee.data.homeCountry &&
                                        isDataProvidedByHR(AllEmployeeEditableFields.homeCountry)
                                            ? props.employee.data.homeCountry
                                            : allowShowElseShow(
                                                  <EditableEmployeeData
                                                      employee={props.employee}
                                                      secureField={
                                                          AllEmployeeEditableFields.homeCountry
                                                      }
                                                      editableData={editableEmployeeInfo}
                                                      placeholder={'Home Country'}
                                                      dialogTitle={'Edit Country'}
                                                      dialogLabel={'Home Country'}
                                                      type={'Text'}
                                                      updateKeyValue={updateEditableEmployeeData}
                                                  />,
                                                  showNonEdit(
                                                      AllEmployeeEditableFields.homeCountry,
                                                  ),
                                              )}
                                    </div>
                                </div>
                            </>,
                            <>
                                <div className={styles.dataLine}>
                                    <div className={styles.key}>Home Address</div>
                                    <div className={styles.value}>
                                        {props.employee.data.homeAddress}
                                    </div>
                                </div>
                            </>,
                        )
                    )}
                </div>
            </div>
        </ContainerWithEtiquettes>
    );
}

function isTrue(checked: boolean | string | undefined): boolean {
    return /true/i.test(`${checked}`);
}

function displayCheckBox(checked: boolean | string | undefined): JSX.Element {
    const isChecked = isTrue(checked);
    const checkboxStyles = {
        root: {
            fontSize: 18,
            color: isChecked ? SharedColors.cyanBlue10 : undefined,
            opacity: isChecked ? undefined : 0.5,
        },
    };
    return isChecked ? (
        <Icon styles={checkboxStyles} iconName='CheckboxCompositeReversed' />
    ) : (
        <Icon styles={checkboxStyles} iconName='Checkbox' />
    );
}

const separatorStyles = {
    root: {
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                opacity: 0.7,
            },
        },
    },
};

const styles = mergeStyleSets({
    employeeData: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 5,
        paddingTop: 5,
    },
    dataLine: {
        display: 'flex',
        alignItems: 'center',
        selectors: {
            '&:not(:last-child):not(:first-child)': {
                marginTop: 7,
                marginBottom: 7,
            },
        },
    },
    key: {
        width: '120px',
        fontWeight: FontWeights.semibold,
    },
    value: {
        paddingLeft: '10px',
    },
    dataGroup: {
        paddingTop: 2,
        paddingBottom: 2,
    },
    multivalue: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        selectors: {
            '& div:nth-child(2)': {
                marginLeft: 10,
            },
        },
    },
    manager: {
        fontWeight: FontWeights.semibold,
    },
    title: {
        paddingLeft: '10px',
        width: '140px',
    },
    costCenter: {
        whiteSpace: 'pre',
    },
});
export default EmployeeData;
