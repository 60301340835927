import { Stack } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import GroupClient, {
    IGroup,
    IPointOfContact,
    PocTypeEnum,
    POC_JUSTIFICATION_MAX_LENGTH,
} from 'clients/group-client';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import Spacer from 'components/common/spacer';
import { useTextField } from 'components/common/use-input/use-textfield';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useMemo } from 'react';

interface IDeletePocModalActionButtonProps {
    group: IGroup;
    pointOfContact: IPointOfContact;
    showPoc: (poc: IPointOfContact) => JSX.Element;
    onPocDeleted: (updatedGroup: IGroup) => void;
    canDeletePoc: boolean;
}

export default function DeletePocModalActionButton(props: IDeletePocModalActionButtonProps) {
    const authContext = useContext(AuthContext);

    const {
        value: justification,
        isValid: isJustificationValid,
        initialize: initJustification,
        theElement: justificationElement,
    } = useTextField({
        label: ' Justification for removing the point of contanct',
        maxLength: POC_JUSTIFICATION_MAX_LENGTH,
        multiline: true,
        placeholder: 'Justification',
        required: props.group.enableMemberLeaveJustification,
        resizable: false,
        rows: 5,
        ariaLabel: 'Justification for removing a point of contact',
        validate: () => !props.group.enableMemberLeaveJustification || !!justification,
    });

    const onButtonClick = (): void => {
        initJustification('');
    };

    const isSubmitEnabled = useMemo((): boolean => {
        return !!isJustificationValid;
    }, [isJustificationValid]);

    const onHandleDelete = async (): Promise<IGroup> => {
        try {
            return GroupClient.deletePoc(authContext, props.group.id, {
                contactValue: props.pointOfContact.contactValue,
                contactValueType: props.pointOfContact.contactValueType,
                justification,
            });
        } catch (e) {
            if (typeof e === 'string') {
                throw e;
            } else {
                throw 'Error deleting point of contact from the group';
            }
        }
    };

    const onPocDeleted = (conclusion: ModalConclusion, result?: IGroup): void => {
        if (conclusion === ModalConclusion.Done && !!result) {
            props.onPocDeleted(result);
        }
    };

    const isDeleteEnabled = useMemo(
        () => props.canDeletePoc && props.group.pointsOfContact.length > 1,
        [props.canDeletePoc, props.group],
    );

    return (
        <ModalActionButton<IGroup>
            text={'Delete'}
            enable={isDeleteEnabled}
            iconName={IconNames.Delete}
            modalTitle={'Delete Point of Contact'}
            modalTitleIcon={IconNames.Delete}
            enableSubmit={isSubmitEnabled}
            submitButtonText={'Delete'}
            onSubmit={onHandleDelete}
            onModalConcluded={onPocDeleted}
            onButtonClick={onButtonClick}>
            <Stack horizontal>
                Are you sure you want to remove the point of contact
                <span>&nbsp;</span> {props.showPoc(props.pointOfContact)} <span>&nbsp;</span>
                from group <strong>{props.group.name}</strong>?
            </Stack>
            {props.pointOfContact.contactValueType === PocTypeEnum.Employee && (
                <Stack horizontal>
                    Please note that this will not change the role or membership of &nbsp;
                    {props.showPoc(props.pointOfContact)}.
                </Stack>
            )}
            <Spacer marginTop={10} />
            {justificationElement()}
        </ModalActionButton>
    );
}
