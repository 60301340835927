import { Stack } from '@fluentui/react';
import { IBasicEmployee } from 'clients/employee-client';
import React from 'react';
import DisplayFacepileInHoverCard from 'components/common/employee/display-facepile-in-hoverCard';
import EmployeeBasicHoverCardSimple from 'components/common/employee/employee-basic-hover-card-simple';

interface IFacepileHovercardProps {
    maxCount: number;
    personnelIds: string[];
    basicEmployeesMap: Map<string, IBasicEmployee>;
    employeePicturesMap: Map<string, string>;
}

export default function DisplayFacepile(props: IFacepileHovercardProps): JSX.Element {
    const { maxCount, personnelIds, basicEmployeesMap, employeePicturesMap } = props;
    if (personnelIds.length === 1) {
        return (
            <EmployeeBasicHoverCardSimple
                key={`facepile-${personnelIds[0]}`}
                employeeBasicData={basicEmployeesMap.get(personnelIds[0])}
                showMiniCardAlias={true}
                showFullName={true}
                displayActions={true}
                image={employeePicturesMap.get(personnelIds[0]) ?? ''}
            />
        );
    } else if (personnelIds.length > 1) {
        return (
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 4 }}>
                {personnelIds.slice(0, maxCount).map((personnelId: string) => (
                    <EmployeeBasicHoverCardSimple
                        key={`facepile-${personnelId}`}
                        employeeBasicData={basicEmployeesMap.get(personnelId)}
                        image={employeePicturesMap.get(personnelId) ?? ''}
                        showFullName={false}
                        showMiniCardAlias={false}
                    />
                ))}
                {personnelIds.slice(maxCount).length > 0 && (
                    <DisplayFacepileInHoverCard
                        personnelIds={personnelIds.slice(maxCount)}
                        basicEmployeesMap={basicEmployeesMap}
                        employeePicturesMap={employeePicturesMap}
                        showFullName={false}
                        showMiniCardAlias={false}
                        displayActions={true}
                    />
                )}
            </Stack>
        );
    } else {
        return <></>;
    }
}
