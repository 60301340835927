import React, { useContext, useState } from 'react';
import Modal, { ModalSizeType } from 'components/common/modal';
import { IFileInfo } from 'components/common/buttons/upload-csv-button';
import { MessageBar, MessageBarType } from '@fluentui/react';
import Spacer from 'components/common/spacer';
import pluralize from 'pluralize';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { convertCSVToArray } from 'utils/string-utils';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { replaceTemplatedTimeStringInDownloadReport } from 'utils/reporting-utils';
import { ChooseFileButton } from 'components/common/buttons/choose-file-button';
import {
    SuitabilityAgencies,
    SuitabilityLevelsV2,
    SuitabilityStatuses,
    SuitabilityTypes,
} from 'components/personnel-profile/suitability/profile-suitability-types';
import { UploadStatusEnum } from 'clients/screening/public-trust-screening-client';
import {
    ISuitabilityUploadRequest,
    SuitabilityClient,
    UploadSuitabilityColumns,
    UploadSuitabilityRecordResult,
} from 'clients/suitability-client';
import { ContinuousEvaluationTypes } from 'components/personnel-profile/clearance/profile-clearance-constants';

type SuitabilityBulkUploadModalPropsType = {
    setShowUploadModal: (showUploadModal: boolean) => void;
};

const MAX_RECORDS = 500;

type MessagesType = {
    infoMessage?: string;
    errorMessage?: string;
    successMessage?: string;
};

// The following column headers are required to be present in the CSV file,
// all except for the ones tagged with "not required" must have a value
const expectedColumnNames = [
    UploadSuitabilityColumns.personnelId, //required
    UploadSuitabilityColumns.microsoftAlias,
    UploadSuitabilityColumns.firstName,
    UploadSuitabilityColumns.lastName,
    UploadSuitabilityColumns.contract, //required
    UploadSuitabilityColumns.suitabilityLevel, //required
    UploadSuitabilityColumns.agency, //required
    UploadSuitabilityColumns.suitabilityStatus, //required
    UploadSuitabilityColumns.suitabilityType, //required
    UploadSuitabilityColumns.suitabilityBasis,
    UploadSuitabilityColumns.investigatedOn,
    UploadSuitabilityColumns.grantedOn,
    UploadSuitabilityColumns.briefedOn,
    UploadSuitabilityColumns.ndaSignedOn,
    UploadSuitabilityColumns.continuousEvaluationType,
    UploadSuitabilityColumns.ceEnrollmentDate,
    UploadSuitabilityColumns.ceUnenrollmentDate,
];

type ValidColumnNamesType = string;

export default function SuitabilityBulkUploadModal(
    props: SuitabilityBulkUploadModalPropsType,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const [messages, setMessages] = useState<MessagesType>({});
    const [requestData, setRequestData] = useState<ISuitabilityUploadRequest[]>([]);
    const [isReadyToSubmit, setIsReadyToSubmit] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [showDownloadButton, setShowDownloadButton] = useState<boolean>(false);
    const [uploadResult, setUploadResult] = useState<UploadSuitabilityRecordResult[]>([]);

    const initialize = (): void => {
        setMessages({});
        setIsReadyToSubmit(false);
    };

    const onFileLoadClick = (): void => {
        initialize();
    };

    function onFileLoaded(fileContents: any[], fileInfo: IFileInfo): void {
        const [row1, ...otherRows] = fileContents;
        const columnNames = row1 as string[];
        const data = otherRows as string[][];
        data.pop(); // remove the last row because it's empty.

        const {
            isDataItemsValid,
            columnIndexesResult: columnIndexes,
            errorMessage,
            badRowIx,
            badColumnIx,
        } = checkFileData(data, columnNames);

        if (!isDataItemsValid) {
            if (badRowIx === -1) {
                setMessages({ errorMessage });
            } else {
                // badRowIx + 2 because rowIx is zero-based and we need to account for the header row.
                setMessages({
                    errorMessage: `Invalid data item in row ${badRowIx + 2} column "${
                        columnNames[badColumnIx]
                    }".`,
                });
            }
            return;
        }

        const requestDataVar = prepareRequestData(data, columnIndexes);

        const len = requestDataVar.length;
        setMessages({
            infoMessage: `Read ${len} ${pluralize('row', len)} of data, ready to upload.`,
        });
        setRequestData(requestDataVar);
        setIsReadyToSubmit(true);
    }

    function checkFileData(
        data: string[][],
        columnNames: string[],
    ): {
        isDataItemsValid: boolean;
        columnIndexesResult: Record<string, number>;
        errorMessage: string;
        badRowIx: number;
        badColumnIx: number;
    } {
        const result = {
            isDataItemsValid: false,
            columnIndexesResult: {} as Record<string, number>,
            errorMessage: '',
            badRowIx: -1,
            badColumnIx: -1,
        };

        // Check there is at least some data
        if (data.length === 0) {
            result.errorMessage = 'No data found in file';
            return result;
        }
        // check there are at most MAX_RECORDS in the file
        if (data.length > MAX_RECORDS) {
            result.errorMessage = `Too many records in file. Maximum is ${MAX_RECORDS}`;
            return result;
        }

        // Check if the header row has provided all the expected column names.
        const theProvidedColumnNames: Set<string> = new Set<string>(columnNames);
        if (!expectedColumnNames.every((item) => theProvidedColumnNames.has(item))) {
            const themissingColumnNames = expectedColumnNames.filter(
                (columnName) => !theProvidedColumnNames.has(columnName),
            );
            result.errorMessage = `The following required columns are missing in the CSV file: ${Array.from(
                themissingColumnNames,
            ).join(', ')}.`;
            return result;
        }

        // Column headers are ok.
        const columnIndexesVar: Record<string, number> = {} as Record<ValidColumnNamesType, number>;
        columnNames.forEach((columnName, index) => {
            columnIndexesVar[columnName] = index;
        });

        // Where possible, check if the provided data is valid.
        let isDataItemsValid = true;
        let badRowIx = -1;
        let badColumnIx = -1;
        for (let rowIx = 0; isDataItemsValid && rowIx < data.length; rowIx++) {
            const row = data[rowIx];
            for (let columnIx = 0; isDataItemsValid && columnIx < row.length; columnIx++) {
                switch (columnIx) {
                    case columnIndexesVar[UploadSuitabilityColumns.personnelId]:
                    case columnIndexesVar[UploadSuitabilityColumns.contract]:
                        if (!row[columnIx]) {
                            isDataItemsValid = false;
                            [badRowIx, badColumnIx] = [rowIx, columnIx];
                        }
                        break;
                    case columnIndexesVar[UploadSuitabilityColumns.agency]:
                        if (
                            Object.values(SuitabilityAgencies).findIndex(
                                (x) =>
                                    x.toLocaleLowerCase() ===
                                    (row[columnIx] as SuitabilityAgencies).toLocaleLowerCase(),
                            ) === -1
                        ) {
                            isDataItemsValid = false;
                            [badRowIx, badColumnIx] = [rowIx, columnIx];
                        }
                        break;
                    case columnIndexesVar[UploadSuitabilityColumns.suitabilityType]:
                        if (
                            Object.values(SuitabilityTypes).findIndex(
                                (x) =>
                                    x.toLocaleLowerCase() ===
                                    (row[columnIx] as SuitabilityTypes).toLocaleLowerCase(),
                            ) === -1
                        ) {
                            isDataItemsValid = false;
                            [badRowIx, badColumnIx] = [rowIx, columnIx];
                        }
                        break;
                    case columnIndexesVar[UploadSuitabilityColumns.suitabilityLevel]:
                        if (
                            Object.values(SuitabilityLevelsV2).findIndex(
                                (x) =>
                                    x.toLocaleLowerCase() ===
                                    (row[columnIx] as SuitabilityLevelsV2).toLocaleLowerCase(),
                            ) === -1
                        ) {
                            isDataItemsValid = false;
                            [badRowIx, badColumnIx] = [rowIx, columnIx];
                        }
                        break;
                    case columnIndexesVar[UploadSuitabilityColumns.suitabilityStatus]:
                        if (
                            Object.values(SuitabilityStatuses).findIndex(
                                (x) =>
                                    x.toLocaleLowerCase() ===
                                    (row[columnIx] as SuitabilityStatuses).toLocaleLowerCase(),
                            ) === -1
                        ) {
                            isDataItemsValid = false;
                            [badRowIx, badColumnIx] = [rowIx, columnIx];
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        return {
            isDataItemsValid,
            columnIndexesResult: columnIndexesVar,
            errorMessage: '',
            badRowIx,
            badColumnIx,
        };
    }

    function prepareRequestData(
        data: string[][],
        columnIndexesParam: Record<ValidColumnNamesType, number>,
    ) {
        const requestDataResult = data.map((row) => {
            const suitabilityUploadRequest: ISuitabilityUploadRequest = {
                id: row[columnIndexesParam[UploadSuitabilityColumns.id]],
                contractId: row[columnIndexesParam[UploadSuitabilityColumns.contract]],
                personnelId: row[columnIndexesParam[UploadSuitabilityColumns.personnelId]],
                suitabilityBasis:
                    row[columnIndexesParam[UploadSuitabilityColumns.suitabilityBasis]],
                debriefJustification:
                    row[columnIndexesParam[UploadSuitabilityColumns.debriefJustification]],
                suitabilityLevel: row[
                    columnIndexesParam[UploadSuitabilityColumns.suitabilityLevel]
                ] as SuitabilityLevelsV2, // Type assertion is safe. Value has been checked.
                requestingAgency: row[
                    columnIndexesParam[UploadSuitabilityColumns.agency]
                ] as SuitabilityAgencies, // Type assertion is safe. Value has been checked.
                status: row[
                    columnIndexesParam[UploadSuitabilityColumns.suitabilityStatus]
                ] as SuitabilityStatuses, // Type assertion is safe. Value has been checked.
                suitabilityType: row[
                    columnIndexesParam[UploadSuitabilityColumns.suitabilityType]
                ] as SuitabilityTypes,
                continuousEvaluationType:
                    row[columnIndexesParam[UploadSuitabilityColumns.continuousEvaluationType]] ===
                    ''
                        ? undefined
                        : (row[
                              columnIndexesParam[UploadSuitabilityColumns.continuousEvaluationType]
                          ] as ContinuousEvaluationTypes),
                investigationDateUTCMilliseconds: !!row[
                    columnIndexesParam[UploadSuitabilityColumns.investigatedOn]
                ]
                    ? new Date(
                          row[columnIndexesParam[UploadSuitabilityColumns.investigatedOn]],
                      ).getTime()
                    : undefined,
                grantDateUTCMilliseconds: !!row[
                    columnIndexesParam[UploadSuitabilityColumns.grantedOn]
                ]
                    ? new Date(
                          row[columnIndexesParam[UploadSuitabilityColumns.grantedOn]],
                      ).getTime()
                    : undefined,
                briefDateUTCMilliseconds: !!row[
                    columnIndexesParam[UploadSuitabilityColumns.briefedOn]
                ]
                    ? new Date(
                          row[columnIndexesParam[UploadSuitabilityColumns.briefedOn]],
                      ).getTime()
                    : undefined,
                nextBriefDateUTCMilliseconds: !!row[
                    columnIndexesParam[UploadSuitabilityColumns.nextBriefedOn]
                ]
                    ? new Date(
                          row[columnIndexesParam[UploadSuitabilityColumns.nextBriefedOn]],
                      ).getTime()
                    : undefined,
                ndaSignedDateUTCMilliseconds: !!row[
                    columnIndexesParam[UploadSuitabilityColumns.ndaSignedOn]
                ]
                    ? new Date(
                          row[columnIndexesParam[UploadSuitabilityColumns.ndaSignedOn]],
                      ).getTime()
                    : undefined,
                debriefDateUTCMilliseconds: !!row[
                    columnIndexesParam[UploadSuitabilityColumns.debriefedOn]
                ]
                    ? new Date(
                          row[columnIndexesParam[UploadSuitabilityColumns.debriefedOn]],
                      ).getTime()
                    : undefined,
                continuousEvaluationEnrollmentDateUTCMilliseconds: !!row[
                    columnIndexesParam[UploadSuitabilityColumns.ceEnrollmentDate]
                ]
                    ? new Date(
                          row[columnIndexesParam[UploadSuitabilityColumns.ceEnrollmentDate]],
                      ).getTime()
                    : undefined,
                continuousEvaluationUnenrollmentDateUTCMilliseconds: !!row[
                    columnIndexesParam[UploadSuitabilityColumns.ceUnenrollmentDate]
                ]
                    ? new Date(
                          row[columnIndexesParam[UploadSuitabilityColumns.ceUnenrollmentDate]],
                      ).getTime()
                    : undefined,
                uploadStatus: row[
                    columnIndexesParam[UploadSuitabilityColumns.uploadStatus]
                ] as UploadStatusEnum,
            };
            return suitabilityUploadRequest;
        });
        return requestDataResult;
    }

    const onSubmit = async (): Promise<void> => {
        if (hasSubmitted) {
            props.setShowUploadModal(false);
            return;
        }
        setMessages({});
        try {
            setIsSubmitting(true);
            const submitResult = await SuitabilityClient.uploadSuitabilityRecords(
                authContext,
                requestData,
            );
            const csvReplaced = replaceTemplatedTimeStringInDownloadReport(submitResult ?? '');
            const result = convertCSVToArray<UploadSuitabilityRecordResult>(csvReplaced);
            setUploadResult(result);
            if (result.length === 0) {
                setMessages({ successMessage: 'All records have already been uploaded.' });
            } else if (
                result.every((r) => {
                    return r[UploadSuitabilityColumns.uploadStatus] === 'Success';
                })
            ) {
                setMessages({
                    successMessage: `result.length ${pluralize(
                        'record',
                        result.length,
                    )} uploaded successfully`,
                });
            } else {
                const successCount = result.filter(
                    (r) => r[UploadSuitabilityColumns.uploadStatus] === 'Success',
                ).length;
                const skipCount = result.filter(
                    (r) => r[UploadSuitabilityColumns.uploadStatus] === 'Skipped',
                ).length;
                const failureCount = result.filter(
                    (r) => r[UploadSuitabilityColumns.uploadStatus] === 'Failed',
                ).length;
                setMessages(() => {
                    const messages = {
                        successMessage: '',
                        errorMessage: '',
                    };
                    if (successCount > 0) {
                        messages.successMessage = `${successCount} ${pluralize(
                            'record',
                            successCount,
                        )} uploaded successfully. `;
                    }
                    if (skipCount > 0) {
                        messages.successMessage += `${skipCount} ${pluralize(
                            'record',
                            skipCount,
                        )} not uploaded.`;
                    }
                    if (failureCount > 0) {
                        messages.errorMessage = `${failureCount} ${pluralize(
                            'record',
                            failureCount,
                        )} failed to upload.`;
                    }
                    return messages;
                });
            }
            setShowDownloadButton(true);
        } catch (error) {
            setMessages({ errorMessage: `Error submitting bulk upload: ${error}` });
        } finally {
            setIsSubmitting(false);
            setHasSubmitted(true);
        }
    };

    const getUploadResult = async (): Promise<UploadSuitabilityRecordResult[]> => {
        return uploadResult;
    };

    function onFileOpenError(error: Error): void {
        setMessages({ errorMessage: error.message });
    }

    return (
        <Modal
            isOpen={true}
            size={ModalSizeType.size500}
            fixWidth={true}
            title='Upload Suitability'
            onCancel={(): void => props.setShowUploadModal(false)}
            isSubmitButtonDisabled={!isReadyToSubmit || isSubmitting}
            showProgressIndicator={isSubmitting}
            submitButtonText={hasSubmitted ? 'Close' : 'Upload'}
            shouldHideCancelButton={hasSubmitted}
            onSubmit={onSubmit}>
            <ChooseFileButton
                disabled={isSubmitting || hasSubmitted}
                acceptedFileTypes={['text/csv']}
                onFileLoaded={onFileLoaded}
                onError={onFileOpenError}
                onClick={onFileLoadClick}
            />
            <div>Upload Suitability CSV, max 1 file with {MAX_RECORDS} rows</div>
            {!!messages.infoMessage && (
                <div>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.info}>
                        <div>{messages.infoMessage}</div>
                    </MessageBar>
                </div>
            )}
            {!!messages.successMessage && (
                <div>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.success}>
                        <div>{messages.successMessage}</div>
                    </MessageBar>
                </div>
            )}
            {!!messages.errorMessage && (
                <div>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.error}>
                        <div>{messages.errorMessage}</div>
                    </MessageBar>
                </div>
            )}
            {showDownloadButton && uploadResult.length > 0 && (
                <>
                    <Spacer marginTop={10} />
                    <ExportToExcelButton<UploadSuitabilityRecordResult>
                        buttonTitle='Download report'
                        buttonType='default'
                        getData={getUploadResult}
                        fileNamePrefix='suitability-upload'
                        formatHeader={false}
                    />
                </>
            )}
        </Modal>
    );
}
