import config from 'environments/environment';
import {
    GetDocumentByDocumentTokenHttpOptions,
    GetAadHttpOptions,
    GetDefaultHttpOptions,
    JSON_CONTENT_TYPE,
    BLOB_CONTENT_TYPE,
    AUTH_PREFIX,
} from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import ClientUtils from 'clients/client-utils';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import { readFile } from 'utils/file-utils';

const documentServiceConfig = config.documentsServiceConfig;

class DocumentsClient {
    static async uploadDocument(
        authContext: IAuthContext,
        file: File,
        documentNotes?: string,
        documentTitle?: string,
        documentType?: string,
        associatedId?: string,
    ): Promise<IDocumentRecord> {
        const url = documentServiceConfig.baseUrl + documentServiceConfig.documentRecord;
        const formData = new FormData();
        formData.append('DocumentFile', file, file.name);
        formData.append('DocumentName', file.name);

        if (!!documentNotes) {
            formData.append('DocumentNotes', documentNotes);
        }

        if (!!documentTitle) {
            formData.append('DocumentTitle', documentTitle);
        }

        if (!!documentType) {
            formData.append('DocumentType', documentType);
        }

        // This can be any reference Id but was first used as Associated User ID
        // by default if this field isn't provided the associated user Id is set to the authPersonnelId on the authContext
        if (!!associatedId) {
            formData.append('AssociatedUserId', associatedId);
        }

        const httpOptions = {
            ...(await DocumentsClient._getAadHttpOptionsWithoutContentType(
                authContext,
                config.documentsServiceConfig.aadScopes,
            )),
            method: 'POST',
            body: formData,
        };
        return ClientUtils.doHttpRequest<IDocumentRecord>(url, httpOptions);
    }

    static async updateDocument(
        authContext: IAuthContext,
        file: File,
        documentId: string,
    ): Promise<IDocumentRecord> {
        const url =
            documentServiceConfig.baseUrl +
            documentServiceConfig.documentRecordById.replace('{id}', documentId);
        const formData = new FormData();
        formData.append('DocumentFile', file, file.name);
        formData.append('NewDocumentName', file.name);
        const httpOptions = {
            ...(await DocumentsClient._getAadHttpOptionsWithoutContentType(
                authContext,
                config.documentsServiceConfig.aadScopes,
            )),
            method: 'PUT',
            body: formData,
        };
        return ClientUtils.doHttpRequest<IDocumentRecord>(url, httpOptions);
    }

    static async sendDocumentNotification(
        authContext: IAuthContext,
        request: IDocumentNotificationRequest,
    ): Promise<boolean> {
        const url = documentServiceConfig.baseUrl + documentServiceConfig.sendDocumentNotification;
        const httpOptions = {
            ...(await GetAadHttpOptions(
                authContext,
                config.documentsServiceConfig.aadScopes,
                JSON_CONTENT_TYPE,
            )),
            method: 'POST',
            body: JSON.stringify(request),
        };

        const respone = await fetch(url, httpOptions);
        if (respone.status === 200) {
            return true;
        } else {
            throw respone;
        }
    }

    static async shareDocument(
        authContext: IAuthContext,
        request: IDocumentReference,
    ): Promise<IDocumentReference> {
        const url =
            documentServiceConfig.baseUrl +
            documentServiceConfig.shareDocument.replace('{id}', request.documentId);
        const httpOptions = {
            ...(await GetAadHttpOptions(
                authContext,
                config.documentsServiceConfig.aadScopes,
                JSON_CONTENT_TYPE,
            )),
            method: 'POST',
            body: JSON.stringify(request),
        };
        return ClientUtils.doHttpRequest<IDocumentReference>(url, httpOptions);
    }

    // TODO: Move to nomination/screening's client and generate a document token at the main page to pass into document components rather than within
    static async getDocumentToken(
        authContext: IAuthContext,
        referenceId: string,
        screeningPath: ScreeningPaths,
    ): Promise<IDocumentToken> {
        let url: string = config.nominationServiceConfig.baseUrl;
        url +=
            screeningPath === ScreeningPaths.UsGov
                ? documentServiceConfig.usGovDocumentToken.replace('{id}', referenceId)
                : documentServiceConfig.publicTrustDocumentToken.replace('{id}', referenceId);
        const httpOptions = await GetDefaultHttpOptions(authContext);
        return ClientUtils.doHttpRequest<IDocumentToken>(url, httpOptions);
    }

    static async getDocumentTypeItems(
        authContext: IAuthContext,
        associationType: string | undefined,
        associationValue: string | undefined,
    ): Promise<IDocumentTypeItem[]> {
        if (!associationType && !associationValue) {
            return [];
        }

        let url: string =
            config.nominationServiceConfig.baseUrl +
            config.nominationServiceConfig.document.documentTypeItems;
        const urlParams = new URLSearchParams();
        associationType && urlParams.append('associationType', associationType);
        associationValue && urlParams.append('associationValue', associationValue);

        const urlParamsString = urlParams.toString();
        if (urlParamsString) {
            url = `${url}?${urlParamsString}`;
        }

        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                const res = (await result.json()) as IDocumentTypeItem[];
                return res;
            case 404:
                throw `Failed to get Documents type items`;
            default:
                throw result;
        }
    }

    static async downloadDocument(
        authContext: IAuthContext,
        documentId: string,
        documentToken?: string,
    ): Promise<Blob> {
        const url =
            documentServiceConfig.baseUrl +
            documentServiceConfig.downloadDocument.replace('{id}', documentId);
        const httpOptions = await (documentToken
            ? GetDocumentByDocumentTokenHttpOptions(authContext, documentToken, BLOB_CONTENT_TYPE)
            : GetAadHttpOptions(
                  authContext,
                  config.documentsServiceConfig.aadScopes,
                  BLOB_CONTENT_TYPE,
              ));
        const res = await fetch(url, httpOptions);
        const blob = await readFile(res);
        if (res.status === 200 && blob) {
            return blob;
        } else {
            throw res;
        }
    }

    static async lockDocument(
        authContext: IAuthContext,
        documentId: string,
        lock: boolean,
        documentToken: IDocumentToken,
    ): Promise<IDocumentRecord> {
        const httpOptions = await GetDocumentByDocumentTokenHttpOptions(
            authContext,
            documentToken.token,
            JSON_CONTENT_TYPE,
        );
        httpOptions.method = 'PUT';
        let url = documentServiceConfig.baseUrl;
        if (lock) {
            url = url + documentServiceConfig.lock.replace('{documentId}', documentId);
        } else {
            url = url + documentServiceConfig.unlock.replace('{documentId}', documentId);
        }

        return ClientUtils.doHttpRequest<IDocumentRecord>(url, httpOptions);
    }

    static async getDocuments(
        authContext: IAuthContext,
        referenceId: string,
        documentToken?: string,
    ): Promise<IDocumentRecord[]> {
        const url =
            documentServiceConfig.baseUrl +
            documentServiceConfig.documentRecord +
            `?referenceId=${referenceId}`;
        const httpOptions = await (documentToken
            ? GetDocumentByDocumentTokenHttpOptions(authContext, documentToken, JSON_CONTENT_TYPE)
            : GetAadHttpOptions(
                  authContext,
                  config.documentsServiceConfig.aadScopes,
                  JSON_CONTENT_TYPE,
              ));
        return ClientUtils.doHttpRequest<IDocumentRecord[]>(url, httpOptions);
    }

    static async getDocumentById(
        authContext: IAuthContext,
        documentId: string,
        documentToken?: string,
    ): Promise<IDocumentRecord> {
        const url =
            documentServiceConfig.baseUrl + documentServiceConfig.documentRecord + `/${documentId}`;
        const httpOptions = await (documentToken
            ? GetDocumentByDocumentTokenHttpOptions(authContext, documentToken, JSON_CONTENT_TYPE)
            : GetAadHttpOptions(
                  authContext,
                  config.documentsServiceConfig.aadScopes,
                  JSON_CONTENT_TYPE,
              ));
        return ClientUtils.doHttpRequest<IDocumentRecord>(url, httpOptions);
    }

    static async getDocumentsByAssociatedUserId(
        authContext: IAuthContext,
        associatedUserId: string,
        associatedUserToken?: string,
    ): Promise<IDocumentRecord[]> {
        const url =
            documentServiceConfig.baseUrl +
            documentServiceConfig.documentsByAssociatedUser.replace('{userId}', associatedUserId);
        const httpOptions = await (associatedUserToken
            ? GetDocumentByDocumentTokenHttpOptions(
                  authContext,
                  associatedUserToken,
                  JSON_CONTENT_TYPE,
              )
            : GetAadHttpOptions(
                  authContext,
                  config.documentsServiceConfig.aadScopes,
                  JSON_CONTENT_TYPE,
              ));
        return ClientUtils.doHttpRequest<IDocumentRecord[]>(url, httpOptions);
    }

    static async updateTTLForAssociatedDocument(
        authContext: IAuthContext,
        documentId: string,
        ttlOffSeconds: number,
        businessJustification: string,
        associatedUserToken?: string,
    ) {
        const url =
            documentServiceConfig.baseUrl +
            documentServiceConfig.updateTTLForAssociatedUser
                .replace('{documentId}', documentId)
                .replace('{ttlOffSeconds}', ttlOffSeconds.toString());
        const httpOptions = await (associatedUserToken
            ? GetDocumentByDocumentTokenHttpOptions(
                  authContext,
                  associatedUserToken,
                  JSON_CONTENT_TYPE,
              )
            : GetAadHttpOptions(
                  authContext,
                  config.documentsServiceConfig.aadScopes,
                  JSON_CONTENT_TYPE,
              ));

        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(businessJustification);
        return await fetch(url, httpOptions);
    }

    static isDocumentLocked(documentRecord: IDocumentRecord): boolean {
        return documentRecord.lock?.isLocked ? true : false;
    }

    static isDocumentDeleted(documentRecord: IDocumentRecord): boolean {
        return documentRecord.status?.toLowerCase() === 'deleted' ? true : false;
    }

    private static async _getAadHttpOptionsWithoutContentType(
        context: IAuthContext,
        aadScopes: (string | undefined)[],
    ): Promise<RequestInit> {
        const token = await context.getToken(aadScopes);
        return {
            headers: {
                Authorization: AUTH_PREFIX + token,
            },
        };
    }
}

export default DocumentsClient;

export enum DocumentEvent {
    Empty,
    Created,
    Deleted,
    Shared,
    Updated,
    Locked,
    Unlocked,
}

export enum DocumentReferenceType {
    None,
    PersonnelId,
    ScreeningDocumentIdUsGov,
    ScreeningDocumentRequestRecordIdUsGov,
    ScreeningDocumentIdPublicTrust,
    ScreeningDocumentRequestRecordIdPublicTrust,
    ScreeningIdUSGov,
    ScreeningIdPublicTrust,
}

export interface IDocumentNotificationRequest {
    application: string;
    documentId: string;
    event: DocumentEvent;
    metadata?: Record<string, string>;
    referenceId: string;
}

export interface IDocumentRecord {
    id: string;
    authorPersonnelId: string;
    createdDate: number;
    lastModifiedDate: number;
    fileName: string;
    documentNotes: string;
    documentTitle: string;
    documentType: string;
    documentTypeName?: string;
    fileMimeType: string;
    lock?: ILock;
    metadata?: any[];
    status?: string;
    expiryTime?: number;
    associatedUserId?: string;
}

export interface IDocumentReference {
    referenceId: string;
    application: string;
    documentId: string;
    allowedPermissions: string[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    metadata?: object;
}

export interface IDocumentToken {
    token: string;
    expires: string;
}

export const emptyNoContentIDocumentToken: IDocumentToken = {
    token: '',
    expires: '0001-01-01T00:00:00',
};

export interface ILock {
    applicationContext: string;
    isLocked: boolean;
}

export interface IDocumentTypeItem {
    displayName: string;
    documentTypeName: string;
    documentType: string;
}
