import React, { createContext, useState } from 'react';
import { Location } from 'history';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';

export enum filterContextKeys {
    firstNameKey = 'firstName',
    lastNameKey = 'lastName',
    employeeStatus = 'employeeStatus',
    contractIdKey = 'contractId',
    reportsToKey = 'reportsTo',
    orgLeaderAliasKey = 'orgLeaderAlias',
    agencyIdKey = 'agencyId',
    personnelIdKey = 'personnelId',
    processOwnerIdKey = 'processOwnerId',
    pcnKey = 'pcn',
    statusChangedKey = 'statusChanged',
    status = 'status',
    declinedStatus = 'declinedStatus',
    // public trust
    publicTrustAgency = 'publicTrustAgency',
    suitabilityLevel = 'suitabilityLevel',
}

interface FiltersContextProviderProps {
    agencyId?: string;
    children: React.ReactNode;
    clearance?: string;
    contractId?: string;
    firstName?: string;
    lastName?: string;
    employeeStatus?: string;
    location: Location;
    orgLeaderAlias?: string;
    pcn?: string;
    personnelId?: string;
    processOwnerId?: string;
    reportsTo?: string;
    requestType?: string;
    status?: string;
    statusChanged?: {
        atUtc: string;
    };
    userType: string;
}

//review this filter for possible improvement
function FiltersContextProvider(props: FiltersContextProviderProps): JSX.Element {
    const [userType] = useState<string>(props.userType || '');
    const [pcn, setPcn] = useState<string>(props.pcn || '');
    const [processOwnerId, setProcessOwnerId] = useState<string>(props.processOwnerId || '');
    const [personnelId, setPersonnelId] = useState<string>(props.personnelId || '');
    const [contractId, setContractId] = useState<string>(props.contractId || '');
    const [reportsTo, setReportsTo] = useState<string>(props.reportsTo || '');
    const [orgLeaderAlias, setOrgLeaderAlias] = useState<string>(props.orgLeaderAlias || '');
    const [agencyId, setAgencyId] = useState<string>(props.agencyId || '');
    const [status, setStatus] = useState<string>(props.status || '');
    const [clearance, setClearance] = useState<string>(props.clearance || '');
    const [requestType, setRequestType] = useState<string>(props.requestType || '');
    const [filterFunctionsMap, setFilterFunctionsMap] = useState<Map<string, any[]>>(
        new Map<string, any[]>(),
    );
    const [location] = useState<Location>(props.location);
    const [firstName, setFirstName] = useState<string>(props.firstName || '');
    const [lastName, setLastName] = useState<string>(props.lastName || '');
    const [employeeStatus, setEmployeeStatus] = useState<string>(props.employeeStatus || '');
    const [statusChanged, setStatusChanged] = useState<string>(props.statusChanged?.atUtc || '');
    const [filterCleared, setFilterCleared] = useState<string>('');

    function clearFilters(): void {
        // all these variables above ^^ need to get cleared
        filterFunctionsMap.clear();
        [
            setPcn,
            setProcessOwnerId,
            setPersonnelId,
            setContractId,
            setReportsTo,
            setOrgLeaderAlias,
            setAgencyId,
            setStatus,
            setClearance,
            setRequestType,
            setFirstName,
            setLastName,
            setStatusChanged,
            setEmployeeStatus,
        ].forEach((set) => set(''));
        setFilterCleared(Date.now().toString());
    }

    function updateFilter(
        filterFunctionsMap: Map<string, any[]>,
        key: string,
        func: (arg: ICommonScreening) => boolean,
        adding: boolean,
        name?: string,
    ): void {
        if (
            [
                filterContextKeys.processOwnerIdKey.valueOf(),
                filterContextKeys.contractIdKey.valueOf(),
                filterContextKeys.agencyIdKey.valueOf(),
                filterContextKeys.statusChangedKey.valueOf(),
            ].includes(key)
        ) {
            filterFunctionsMap.set(key, [{ func, name }]);
        } else if (filterFunctionsMap && adding) {
            let filters = filterFunctionsMap.get(key);
            if (!filters) {
                filters = [];
            }
            filters.push({ func, name });
            filterFunctionsMap.set(key, filters);
        } else if (filterFunctionsMap && !adding) {
            if (
                [
                    filterContextKeys.personnelIdKey.valueOf(),
                    filterContextKeys.pcnKey.valueOf(),
                    filterContextKeys.firstNameKey.valueOf(),
                    filterContextKeys.lastNameKey.valueOf(),
                    filterContextKeys.reportsToKey.valueOf(),
                    filterContextKeys.orgLeaderAliasKey.valueOf(),
                ].includes(key)
            ) {
                if (name) {
                    let filters = filterFunctionsMap.get(key);
                    if (!filters) filters = [];
                    filterFunctionsMap.set(
                        key,
                        filters.filter((f) => f.name !== name),
                    );
                } else {
                    filterFunctionsMap.set(key, []);
                }
            } else {
                let filters = filterFunctionsMap.get(key);
                if (!filters) {
                    filters = [];
                }
                filters = filters.filter((x) => {
                    //Turning the function into strings and comparing them as such
                    //to figure out if it should be removed or not.
                    return '' + func !== '' + x.func;
                });
                filterFunctionsMap.set(key, filters);
            }
        }
    }

    function updateFilterPublicTrust(
        filterFunctionsMap: Map<string, any[]>,
        key: string,
        func: (arg: ICommonScreening) => boolean,
        adding: boolean,
        name?: string,
    ): void {
        if (
            [
                filterContextKeys.processOwnerIdKey.valueOf(),
                filterContextKeys.contractIdKey.valueOf(),
                filterContextKeys.agencyIdKey.valueOf(),
                filterContextKeys.statusChangedKey.valueOf(),
            ].includes(key)
        ) {
            filterFunctionsMap.set(key, [{ func, name }]);
        } else if (filterFunctionsMap && adding) {
            let filters = filterFunctionsMap.get(key);
            if (!filters) {
                filters = [];
            }
            filters.push({ func, name });
            filterFunctionsMap.set(key, filters);
        } else if (filterFunctionsMap && !adding) {
            if (
                [
                    filterContextKeys.personnelIdKey.valueOf(),
                    filterContextKeys.pcnKey.valueOf(),
                    filterContextKeys.firstNameKey.valueOf(),
                    filterContextKeys.lastNameKey.valueOf(),
                    filterContextKeys.reportsToKey.valueOf(),
                    filterContextKeys.orgLeaderAliasKey.valueOf(),
                ].includes(key)
            ) {
                if (name) {
                    let filters = filterFunctionsMap.get(key);
                    if (!filters) filters = [];
                    filterFunctionsMap.set(
                        key,
                        filters.filter((f) => f.name !== name),
                    );
                } else {
                    filterFunctionsMap.set(key, []);
                }
            } else {
                let filters = filterFunctionsMap.get(key);
                if (!filters) {
                    filters = [];
                }
                filters = filters.filter((x) => {
                    //Turning the function into strings and comparing them as such
                    //to figure out if it should be removed or not.
                    return '' + func !== '' + x.func;
                });
                filterFunctionsMap.set(key, filters);
            }
        }
    }

    function setFilter(key: string, functions: any[]): void {
        setFilterFunctionsMap((prev) => new Map(prev).set(key, functions));
    }

    return (
        <FiltersContext.Provider
            value={{
                userType,
                personnelId,
                contractId,
                agencyId,
                processOwnerId,
                pcn,
                reportsTo,
                orgLeaderAlias,
                status,
                clearance,
                requestType,
                firstName,
                lastName,
                employeeStatus,
                setContractId,
                setAgencyId,
                setProcessOwnerId,
                setPcn,
                setReportsTo,
                setOrgLeaderAlias,
                setStatus,
                setClearance,
                setRequestType,
                setEmployeeId: (value: string): void => setPersonnelId(value),
                setFirstName,
                setLastName,
                setEmployeeStatus,
                clearFilters,
                statusChanged,
                filterCleared,
                setStatusChanged: (value: string): void => setStatusChanged(value),
                filterFunctionsMap,
                updateFilterFunctions: (
                    key: string,
                    func: (data: ICommonScreening) => boolean,
                    adding: boolean,
                    name?: string,
                ): void => updateFilter(filterFunctionsMap, key, func, adding, name),
                updateFilterFunctionsPublicTrust: (
                    key: string,
                    func: (data: ICommonScreening) => boolean,
                    adding: boolean,
                    name?: string,
                ): void => updateFilterPublicTrust(filterFunctionsMap, key, func, adding, name),
                setFilterFunctions: (key: string, functions: any[]): void =>
                    setFilter(key, functions),
                location,
            }}>
            {props.children}
        </FiltersContext.Provider>
    );
}
export default FiltersContextProvider;

// null! needed for creating context without specifying default values required by typescript
export const FiltersContext = createContext<IFiltersContext>(null!);

export interface IFiltersContext {
    userType: string;
    personnelId: string;
    contractId: string;
    agencyId: string;
    processOwnerId: string;
    pcn: string;
    reportsTo: string;
    orgLeaderAlias: string;
    status: string;
    clearance: string;
    requestType: string;
    firstName: string;
    lastName: string;
    employeeStatus: string;
    setContractId: (value: string) => void;
    setAgencyId: (value: string) => void;
    setEmployeeId: (value: string) => void;
    setProcessOwnerId: (value: string) => void;
    setPcn: (value: string) => void;
    setReportsTo: (value: string) => void;
    setOrgLeaderAlias: (value: string) => void;
    setStatus: (value: string) => void;
    setClearance: (value: string) => void;
    setRequestType: (value: string) => void;
    setFirstName: (value: string) => void;
    setLastName: (value: string) => void;
    setEmployeeStatus: (value: string) => void;
    clearFilters: () => void;
    filterCleared?: string;
    statusChanged?: string;
    setStatusChanged: (value: string) => void;
    filterFunctionsMap: Map<string, any[]>;
    updateFilterFunctions: (
        key: string,
        func: (data: ICommonScreening) => boolean,
        adding: boolean,
        name?: string,
    ) => void;
    updateFilterFunctionsPublicTrust: (
        key: string,
        func: (data: ICommonScreening) => boolean,
        adding: boolean,
        name?: string,
    ) => void;
    setFilterFunctions: (key: string, functions: any[]) => void;
    location: Location;
}
