// Creates URL parameters based on filter settings and copies it to clipboard
import { ActionButton, TooltipHost } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import { detailsListStyles } from 'assets/styles/list-styles';
import React from 'react';

interface IMakeUrlButtonProps {
    text?: string; // Default: "Copy URL"
    onClick: () => void;
}

export default function MakeAndCopyUrlButton(props: IMakeUrlButtonProps): JSX.Element {
    return (
        <TooltipHost content='Copy URL based on current search and filter settings'>
            <ActionButton
                className={detailsListStyles.icon}
                iconProps={{ iconName: IconNames.PageLink }}
                onClick={props.onClick}
                ariaLabel='Copy'>
                <span>{props.text ?? 'Copy URL'}</span>
            </ActionButton>
        </TooltipHost>
    );
}
