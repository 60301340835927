import { Stack } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import GroupClient, {
    GroupMemberStatus,
    GroupRole,
    GroupRuleType,
    IGroup,
    IGroupMembership,
    IGroupPolicyViolationResponse,
    IUarEvaluation,
} from 'clients/group-client';
import { IPagedResults } from 'clients/http-options';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';
import { Table } from 'components/common/table';
import tableColumnsUserAccessReview from 'components/groups/manage-group/actions/user-access-review/table-columns-user-access-review';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import config from 'environments/environment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFetchSimple } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';

const featureFlags = config.groupServiceConfig.featureFlags;

interface IGroupActionsTabProps {
    group: IGroup;
    onGroupMemberDeleted: (personnelId: string, ruleId?: string) => void;
}

const initialFilterState: GroupMemberStatus[] = [
    GroupMemberStatus.APPROVED,
    GroupMemberStatus.QUARANTINED,
];

export default function UserAccessReviewsTable(props: IGroupActionsTabProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);
    const [policiesContinuation, setPoliciesContinuation] = useState<string | undefined>('');
    const [conditionalFilterState] = useState(initialFilterState);
    const [isInGracePeriod] = useState<boolean | null>(null);
    const [shouldFetchPolicies, setShouldFetchPolicies] = useState<boolean>(false);
    const [fetchedPolicies, setFetchedPolicies] = useState<IUarEvaluation[]>([]);
    const [accessContinuation, setAccessContinuation] = useState<string | undefined>('');
    const [shouldFetchAccess, setShouldFetchAccess] = useState<boolean>(false);
    const [userAccessReviews, setUserAccessReviews] = useState<IGroupMembership[]>([]);
    const [errorFetchingUserAccessReviews, setErrorFetchingUserAccessReviews] = useState<string>(
        '',
    );

    useEffect(() => {
        if (!!props.group.id) {
            setShouldFetchPolicies(true);
            setShouldFetchAccess(true);
            setPoliciesContinuation('');
            setAccessContinuation('');
        }
    }, [props.group.id]);

    const { isFetching: isFetchingPolicies } = useFetchSimple<IGroupPolicyViolationResponse>({
        dependencies: [shouldFetchPolicies, !!policiesContinuation, props.group.id],
        canPerformFetch: shouldFetchPolicies,
        fetchFunc: async () => {
            setShouldFetchPolicies(false);
            return GroupClient.getPolicyViolations(
                authContext,
                props.group.id,
                policiesContinuation,
                true,
            );
        },
        onSuccess: (result) => {
            const uarViolations = result.results.filter(
                (x) =>
                    x.failed.some(
                        (y) => y.ruleType === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE,
                    ) ||
                    (featureFlags.allowApproveUnsigned &&
                        x.passed.some(
                            // If the following violation goes through, it will show
                            // up in the UAR table as "Grace Period".
                            (y) =>
                                y.ruleType === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE &&
                                y.isInGracePeriod,
                        )),
            );
            const projectedViolations = uarViolations.flatMap((evaluation) => {
                const gracePolices = evaluation.passed
                    .filter(
                        (passedEvaluation) =>
                            passedEvaluation.isInGracePeriod &&
                            passedEvaluation.ruleType ===
                                GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE,
                    )
                    .map((grace) => ({ ...evaluation, ...grace }));
                const failedPolicies = evaluation.failed
                    .filter(
                        (failedEvaluation) =>
                            failedEvaluation.ruleType ===
                            GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE,
                    )
                    .map((failed) => ({
                        ...evaluation,
                        ...failed,
                    }));
                const combinedPolicies = [...gracePolices, ...failedPolicies];
                return combinedPolicies.map((policy) => ({
                    nonComplianceTimestampUTC: policy.nonComplianceTimestampUTC,
                    personnelId: policy.personnelId,
                    evaluationState: policy.evaluationState,
                    ruleName: policy.ruleName,
                    metadata: policy.metadata,
                    firstFailTimeStampUTC: policy.firstFailTimeStampUTC,
                    isInGracePeriod: policy.isInGracePeriod,
                    ruleId: policy.ruleId,
                    ruleType: policy.ruleType,
                })) as IUarEvaluation[];
            });

            if (!!policiesContinuation) {
                setFetchedPolicies((currentValue) => [...currentValue].concat(projectedViolations));
            } else {
                setFetchedPolicies(projectedViolations);
            }
            setPoliciesContinuation(result.continuationToken);
        },

        onError: () => {
            setErrorFetchingUserAccessReviews(
                'Error approving member. Member must sign the UAR Contract Text for initial approval.',
            );
        },
        onFinally: doNothing,
    });

    useEffect(() => {
        if (!!policiesContinuation && !isFetchingPolicies) {
            setShouldFetchPolicies(true);
        }
    }, [policiesContinuation, isFetchingPolicies]);

    const { isFetching: isFetchingUserAccessReview } = useFetchSimple<
        IPagedResults<IGroupMembership>
    >({
        dependencies: [
            conditionalFilterState,
            accessContinuation,
            shouldFetchAccess,
            props.group.id,
            isInGracePeriod,
        ],
        canPerformFetch: shouldFetchAccess,
        fetchFunc: async () => {
            setShouldFetchAccess(false);
            return GroupClient.getGroupMembers(
                authContext,
                props.group.id,
                accessContinuation,
                conditionalFilterState,
                isInGracePeriod,
            );
        },
        onSuccess: (result) => {
            if (!!accessContinuation) {
                setUserAccessReviews((currentValue) => [...currentValue].concat(result.results));
            } else {
                setUserAccessReviews(result.results);
            }
            setAccessContinuation(result.continuationToken);
        },
        onError: () => {
            setErrorFetchingUserAccessReviews('Error loading user access reviews');
        },
        onFinally: doNothing,
    });

    useEffect(() => {
        if (!!accessContinuation && !isFetchingUserAccessReview) {
            setShouldFetchAccess(true);
        }
    }, [accessContinuation, isFetchingUserAccessReview]);

    const onDeleteMemberFromUARTable = (personnelId: string, ruleId?: string): void => {
        setFetchedPolicies((currentValue) =>
            currentValue.filter((v) =>
                ruleId
                    ? !(v.personnelId === personnelId && v.ruleId === ruleId)
                    : v.personnelId !== personnelId,
            ),
        );
    };

    const userAccessReviewTableColumns = useMemo(() => {
        const { group } = props;
        const cannotDeleteOrApprove = [GroupRole.AUDITOR, GroupRole.MEMBER].find(
            (role) => role === groupContext.groupMembershipVar?.value?.role,
        );
        const canDeleteOrApprove = authContext.isInRole(Role.GroupAdmin) || !cannotDeleteOrApprove;

        return tableColumnsUserAccessReview({
            groupOwners: groupContext.groupOwners,
            enableOwnerDemote: groupContext.isEnableOwnerDemote,
            onGroupMemberDeleted: props.onGroupMemberDeleted,
            onDeleteMemberFromUARTable,
            userAccessReviews,
            canDeleteOrApprove,
            group,
        });
    }, [
        props,
        authContext,
        groupContext.groupOwners,
        groupContext.isEnableOwnerDemote,
        groupContext.groupMembershipVar?.value?.role,
        userAccessReviews,
    ]);

    const displayUserAccessReviewsTable = (): JSX.Element => {
        return (
            <>
                <HorizontalBar>
                    <Stack.Item className={horizontalBarTitleStyle} grow={100}>
                        <h1
                            className={`${globalStyles.boldFont} ${globalStyles.mediumLargeFont} ${globalStyles.removeTopBottomMargins}`}>
                            User Access Reviews
                        </h1>
                    </Stack.Item>
                </HorizontalBar>
                <ProblemLoadingMsg problemLoadingMsg={errorFetchingUserAccessReviews}>
                    <Table
                        rows={fetchedPolicies}
                        tableColumns={userAccessReviewTableColumns}
                        shimmerLabel={isFetchingUserAccessReview ? 'Loading requests...' : ''}
                        isFetchingData={isFetchingUserAccessReview}
                        noDataText={'There are currently no user access reviews.'}
                    />
                </ProblemLoadingMsg>
            </>
        );
    };

    return displayUserAccessReviewsTable();
}
