import { Dictionary } from 'assets/constants/global-constants';
import { IClearanceRecord, isIClearanceRecord } from 'clients/clearance-client';
import useMessageBar from 'components/common/use-message-bar';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/clearance/profile-clearance-constants';
import { getAgencyEnumValueFromKey as getSuitabilityAgencyEnumValueFromKey } from 'components/personnel-profile/suitability/profile-suitability-utils';
import { IContract } from 'components/screening/us-gov/IContract';
import { FiltersClearanceRecords } from 'contexts/filters-clearance-records';
import { FiltersContext } from 'contexts/filters-context';
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IDropdownOption,
    MessageBarType,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import { ICommonScreening, isICommonScreening } from 'components/screening/common/ICommonScreening';
import { dropdownComboBox } from 'components/screening/common/filters/common-filter-styling';
import { ISuitabilityRecord, isISuitabilityRecord } from 'clients/suitability-client';
import { FiltersSuitabilityRecords } from 'contexts/filters-suitability-records';

export interface AgencyDropdownFilterProps {
    onFilterStateChange: (selectedAgencies: string[], adding: boolean) => void;
    rawCandidates: (IClearanceRecord | ICommonScreening | ISuitabilityRecord)[];
    placeHolder?: string;
    contracts?: Dictionary<IContract>;
}

const agencyTypes = {
    clearance: 'clearance',
    suitability: 'suitability',
} as const;

export function AgencyDropdownFilter(props: AgencyDropdownFilterProps): JSX.Element {
    const {
        theMessage: message,
        theElement: messageElement,
        setMessage: setMessage,
    } = useMessageBar({
        type: MessageBarType.error,
    });
    const filtersClearanceRecordsContext = useContext(FiltersClearanceRecords);
    const filtersSuitabilityRecordsContext = useContext(FiltersSuitabilityRecords);
    const filtersCandidateContext = useContext(FiltersContext);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const agencyOptions = getAgencyOptionsFromCandidates(props.rawCandidates) ?? [];

    useEffect(() => {
        if (filtersCandidateContext?.filterCleared || filtersClearanceRecordsContext?.filterCleared)
            setSelectedKeys([]);
    }, [filtersCandidateContext?.filterCleared, filtersClearanceRecordsContext?.filterCleared]);

    return (
        <>
            <ComboBox
                ariaLabel='Agencies'
                multiSelect
                selectedKey={selectedKeys}
                placeholder={props.placeHolder || 'Agency'}
                autoComplete='on'
                options={agencyOptions}
                onChange={onAgencySelect}
                useComboBoxAsMenuWidth
                allowFreeform
                styles={dropdownComboBox}
            />
            {!!message && messageElement()}
        </>
    );

    function onAgencySelect(event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void {
        if (option && option.key) {
            const agency = option.key;
            try {
                const isAdding = !!option.selected;
                let newlySelected: string[] = [];
                setSelectedKeys((prevSelectedKeys) => {
                    newlySelected = isAdding
                        ? [...prevSelectedKeys, option!.key as string]
                        : prevSelectedKeys.filter((k) => k !== option!.key);
                    return newlySelected;
                });
                // we need to set this to something unique in the event that the
                // same agency is toggled on/off in succession
                if (isIClearanceRecord(props.rawCandidates[0])) {
                    filtersClearanceRecordsContext.setAgency(`${agency} ${Date.now()}`);
                } else if (isISuitabilityRecord(props.rawCandidates[0])) {
                    filtersSuitabilityRecordsContext.setRequestingAgency(`${agency} ${Date.now()}`);
                } else {
                    filtersCandidateContext.setAgencyId(`${agency} ${Date.now()}`);
                }
                props.onFilterStateChange(newlySelected, isAdding);
            } catch (error) {
                setMessage(
                    `An error occurred while loading the selected ${
                        props.placeHolder || 'Agency'
                    } information.`,
                );
            }
        } else {
            if (isIClearanceRecord(props.rawCandidates[0])) {
                filtersClearanceRecordsContext.setAgency('');
            } else {
                filtersCandidateContext.setAgencyId('');
            }
            props.onFilterStateChange([], false);
        }
    }

    function getOptionsFromValidAgencies(
        validAgency: Set<string>,
        type: string,
    ): IDropdownOption[] {
        let options: IDropdownOption[] = [];
        validAgency.forEach((agency) => {
            if (agency) {
                let agencyFullText = getAgencyEnumValueFromKey(agency);
                if (type === 'suitability') {
                    agencyFullText = getSuitabilityAgencyEnumValueFromKey(agency, true);
                }
                options.push({ key: agency, text: agencyFullText });
            }
        });
        options = options.sort((a, b) => {
            const aUpper = a.text.toUpperCase();
            const bUpper = b.text.toUpperCase();
            if (aUpper < bUpper) return -1;
            if (aUpper > bUpper) return 1;
            return 0;
        });
        return options;
    }

    function getAgencyOptionsFromCandidates(
        rawCandidates: (IClearanceRecord | ICommonScreening | ISuitabilityRecord)[],
    ): IDropdownOption[] {
        let validAgency = new Set<string>();
        const firstElement = rawCandidates[0];
        let type: keyof typeof agencyTypes = 'clearance';
        if (isIClearanceRecord(firstElement)) {
            validAgency = new Set(
                rawCandidates
                    .filter((clearanceRecord): boolean =>
                        isIClearanceRecord(clearanceRecord) && clearanceRecord?.agency
                            ? true
                            : false,
                    )
                    .map((x) => {
                        if (isIClearanceRecord(x)) return x.agency || '';
                        return '';
                    }),
            );
        } else if (isISuitabilityRecord(firstElement)) {
            type = 'suitability';
            validAgency = new Set(
                rawCandidates
                    .filter((suitabilityRecord): boolean =>
                        isISuitabilityRecord(suitabilityRecord) &&
                        suitabilityRecord?.requestingAgency
                            ? true
                            : false,
                    )
                    .map((x) => {
                        if (isISuitabilityRecord(x)) return x.requestingAgency || '';
                        return '';
                    }),
            );
        } else if (isICommonScreening(firstElement)) {
            validAgency = new Set(
                rawCandidates
                    .filter((record): boolean =>
                        isICommonScreening(record) && record.contractId ? true : false,
                    )
                    .map((x) => {
                        if (props.contracts && isICommonScreening(x))
                            return props.contracts[x.contractId]?.customer ?? '';
                        return isICommonScreening(x) ? x.contractId : '';
                    }),
            );
        }

        return getOptionsFromValidAgencies(validAgency, type);
    }
}
export default AgencyDropdownFilter;
