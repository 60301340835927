import React from 'react';
import { TableCell } from 'components/common/table';
import { IColumn, mergeStyleSets, Stack } from '@fluentui/react';
import { xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { getDaysSinceDate, TimeFormats, timeToString } from 'utils/time-utils';
import {
    GroupRole,
    IGroupMembership,
    IGroupPolicyViolation,
    IGroupPolicyViolationRule,
} from 'clients/group-client';
import { ChartLegendDot } from 'components/common/charts/chart-legend';
import { BadgeColorHex } from 'assets/constants/global-colors';
import ManageGroupMemberDeleteModalActionButton from 'components/groups/common/manage-group-member-delete-modal-action-button';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';

enum ColumnKeys {
    Name = 'Name',
    TotalFailed = 'Total Failed',
    RulesFailed = 'Rules Failed',
    TotalPassed = 'Total Passed',
    TotalGracePeriod = 'Total Grace Period',
    PercentCompliant = '% Compliant',
    NonComplianceDate = 'Noncompliance Date',
    DaysNonCompliant = 'Days Non-compliant',
    Actions = 'Actions',
}

interface IManagePoliciesTableColumnsProps {
    groupOwners: IGroupMembership[];
    enableOwnerDemote: boolean;
    onGroupMemberDeleted: (personnelId: string) => void;
    isDynamicGroup: boolean;
}

function managePoliciesTableColumns(props: IManagePoliciesTableColumnsProps) {
    const displayOneStack = (violationOrPassingRules: IGroupPolicyViolationRule[]): JSX.Element => {
        return (
            <Stack horizontal wrap verticalAlign='start' className={styles.tableStack}>
                <Stack.Item verticalFill={true}>
                    <ul className={styles.listWrapper}>
                        {violationOrPassingRules.map((violation: IGroupPolicyViolationRule) => (
                            <li className={styles.listItem} key={violation.ruleId}>
                                <Stack horizontal wrap key={violation.ruleId}>
                                    <ChartLegendDot backgroundColor={BadgeColorHex.RED} />
                                    {violation.ruleName}
                                </Stack>
                            </li>
                        ))}
                    </ul>
                </Stack.Item>
            </Stack>
        );
    };

    const getPercentCompliant = (violation: IGroupPolicyViolation) => {
        const total = violation.passed.length + violation.failed.length;
        return total > 0 ? Math.floor((violation.passed.length / total) * 100) : 0;
    };

    const isOwner = (personnelId: string): boolean =>
        !!props.groupOwners.find(
            (o) => o.personnelId === personnelId && o.role === GroupRole.OWNER,
        );

    const columnWidths = {
        name: 100,
        totalFailed: 30,
        rulesFailed: 90,
        totalPassed: 30,
        totalGracePeriod: 30,
        percentCompliant: 40,
        nonComplianceDate: 80,
        daysNonCompliant: 30,
        actions: 100,
    };

    const columns: IColumn[] = [
        {
            key: ColumnKeys.Name,
            name: ColumnKeys.Name,
            ariaLabel: ColumnKeys.Name,
            minWidth: columnWidths.name,
            maxWidth: columnWidths.name * xLargeMaxWidthCoeff,

            onRender: (row: IGroupPolicyViolation): JSX.Element => (
                <TableCell>
                    <CoreEmployeeHoverCardFromPrincipalId
                        key={row.personnelId}
                        principalId={row.personnelId}
                    />
                </TableCell>
            ),
        },
        {
            key: ColumnKeys.TotalFailed,
            name: ColumnKeys.TotalFailed,
            ariaLabel: ColumnKeys.TotalFailed,
            minWidth: columnWidths.totalFailed,
            maxWidth: columnWidths.totalFailed * xLargeMaxWidthCoeff,

            onRender: (row: IGroupPolicyViolation): JSX.Element => (
                <TableCell>{row.failed.length}</TableCell>
            ),
        },
        {
            key: ColumnKeys.RulesFailed,
            name: ColumnKeys.RulesFailed,
            ariaLabel: ColumnKeys.RulesFailed,
            minWidth: columnWidths.rulesFailed,
            maxWidth: columnWidths.rulesFailed * xLargeMaxWidthCoeff,

            onRender: (row: IGroupPolicyViolation): JSX.Element => displayOneStack(row.failed),
        },
        {
            key: ColumnKeys.TotalPassed,
            name: ColumnKeys.TotalPassed,
            ariaLabel: ColumnKeys.TotalPassed,
            minWidth: columnWidths.totalPassed,
            maxWidth: columnWidths.totalPassed * xLargeMaxWidthCoeff + 5,

            onRender: (row: IGroupPolicyViolation): JSX.Element => (
                <TableCell>{row.passed.length}</TableCell>
            ),
        },
        {
            key: ColumnKeys.TotalGracePeriod,
            name: ColumnKeys.TotalGracePeriod,
            ariaLabel: ColumnKeys.TotalGracePeriod,
            minWidth: columnWidths.totalGracePeriod,
            maxWidth: columnWidths.totalGracePeriod * xLargeMaxWidthCoeff + 50,

            onRender: (row: IGroupPolicyViolation): JSX.Element => {
                const inGracePeriod = row.passed.filter((r) => r.isInGracePeriod);
                return <TableCell>{inGracePeriod.length}</TableCell>;
            },
        },
        {
            key: ColumnKeys.PercentCompliant,
            name: ColumnKeys.PercentCompliant,
            ariaLabel: ColumnKeys.PercentCompliant,
            minWidth: columnWidths.percentCompliant,
            maxWidth: columnWidths.percentCompliant * xLargeMaxWidthCoeff,

            onRender: (row: IGroupPolicyViolation): JSX.Element => (
                <TableCell>{getPercentCompliant(row)} %</TableCell>
            ),
        },
        {
            key: ColumnKeys.NonComplianceDate,
            name: ColumnKeys.NonComplianceDate,
            ariaLabel: ColumnKeys.NonComplianceDate,
            minWidth: columnWidths.nonComplianceDate,
            maxWidth: columnWidths.nonComplianceDate * xLargeMaxWidthCoeff,

            onRender: (row: IGroupPolicyViolation): JSX.Element => (
                <TableCell>
                    {timeToString(
                        row?.nonComplianceTimestampUTC * 1000,
                        TimeFormats.MMMDYYYY_hmmssA,
                    )}
                </TableCell>
            ),
        },
        {
            key: ColumnKeys.DaysNonCompliant,
            name: ColumnKeys.DaysNonCompliant,
            ariaLabel: ColumnKeys.DaysNonCompliant,
            minWidth: columnWidths.daysNonCompliant,
            maxWidth: undefined,

            onRender: (row: IGroupPolicyViolation): JSX.Element => (
                <TableCell>{getDaysSinceDate(row.nonComplianceTimestampUTC)}</TableCell>
            ),
        },
        {
            key: ColumnKeys.Actions,
            name: ColumnKeys.Actions,
            ariaLabel: ColumnKeys.Actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions * xLargeMaxWidthCoeff,

            onRender: (row: IGroupPolicyViolation): JSX.Element => (
                <TableCell>
                    <ManageGroupMemberDeleteModalActionButton
                        enable={
                            !props.isDynamicGroup &&
                            (!isOwner(row.personnelId) || props.enableOwnerDemote)
                        }
                        memberDetails={row}
                        personnelId={row.personnelId}
                        onGroupMemberDeleted={props.onGroupMemberDeleted}
                    />
                </TableCell>
            ),
        },
    ];
    return columns;
}
export default managePoliciesTableColumns;

const styles = mergeStyleSets({
    tableStack: {
        lineHeight: 25,
        marginTop: 5,
        marginBottom: 5,
    },
    listWrapper: {
        margin: 0,
        padding: 0,
    },
    listItem: {
        listStyle: 'none',
    },
});
