import React from 'react';
import { TagPicker, IBasePicker, ITag, IInputProps } from '@fluentui/react';

interface IPickerProps {
    /* definition of ITag:
       {
           key: string | number; // Unique key for the item.
           name: string          // Name of the item.
       }
    */
    tags: ITag[];

    onSelectionChange: (selectedItems?: ITag[]) => void;

    itemLimit?: number; // Maximum allowed number of selected items
    ariaLabel?: string;
    noResultsFoundText?: string;
    suggestionsHeaderText?: string;
}

export default function Picker(props: IPickerProps): JSX.Element {
    // Picker is copied from the following Fluent example and modified:
    //     https://developer.microsoft.com/en-us/fluentui#/controls/web/pickers
    // All pickers extend from BasePicker specifying the item type.
    const picker = React.useRef<IBasePicker<ITag>>(null);

    const filterSelectedTags = (filterText?: string): ITag[] => {
        const selectedTags = filterText
            ? (props.tags ?? []).filter(
                  (tag) => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0,
              )
            : [];
        return selectedTags;
    };

    const inputProps: IInputProps = {
        // If you need to expand the props in the future ...
        //    onBlur: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onBlur called'),
        //    onFocus: (ev: React.FocusEvent<HTMLInputElement>) => console.log('onFocus called'),
        'aria-label': props.ariaLabel ?? 'Item picker',
    };

    return (
        <TagPicker
            itemLimit={props.itemLimit ?? 2}
            inputProps={inputProps}
            componentRef={picker}
            onResolveSuggestions={filterSelectedTags}
            removeButtonAriaLabel='Remove'
            pickerSuggestionsProps={{
                noResultsFoundText: props.noResultsFoundText ?? 'No matching items found',
                suggestionsHeaderText: props.suggestionsHeaderText ?? 'Matching items',
            }}
            onChange={props.onSelectionChange}
        />
    );
}
