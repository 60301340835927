import React, {
    Checkbox,
    ComboBox,
    Dropdown,
    IDropdownOption,
    IconButton,
    Label,
    Stack,
    TextField,
    mergeStyleSets,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import {
    ComparisonOperatorType,
    IPrincipalAttributeExpression,
    LogicalOperatorType,
} from 'clients/group-client';
import { CoreSinglePrincipalIdPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import AndOrToggle from 'components/groups/manage-group/policy/builder/and-or-toggle';
import { GlobalCollapseState } from 'components/groups/manage-group/policy/builder/attribute-policy-builder';
import { AttributeDataType, IGetVisibleAttributeResult } from 'personnel-core-clients';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';

export interface IAttributeRuleExpressionProps {
    ruleName: string;
    attributes: IGetVisibleAttributeResult[];
    expression: IPrincipalAttributeExpression;
    globalCollapseState: GlobalCollapseState | null;

    onCheckboxChange: (checked: boolean) => void;
    onExpressionChange: (ex: IPrincipalAttributeExpression) => void;
    onCollapseToggle: () => void;
}

export default function AttributeRuleExpression(props: IAttributeRuleExpressionProps): JSX.Element {
    const [checkBoxClassName, setCheckBoxClassName] = useState<string>(styles.checkboxHidden);
    const [divClassName, setDivClassName] = useState<string>(styles.divUnselected);
    const [selectedAttributeSet, setSelectedAttributeSet] = useState<string | undefined>(
        props.expression.attributeId?.split('_')[0],
    );
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

    useEffect(() => {
        if (props.globalCollapseState !== null) {
            setIsCollapsed(props.globalCollapseState === GlobalCollapseState.Collapsed);
        }
    }, [props.globalCollapseState]);

    const selectedAttribute = useMemo(() => {
        return props.attributes.find((x) => x.id === props.expression.attributeId);
    }, [props.attributes, props.expression]);

    const attributeSetOptions = useMemo(() => {
        const seenAttributeSetIds = new Set<string>();

        // Filter out duplicate attribute sets
        return props.attributes
            .filter(
                (x) =>
                    !seenAttributeSetIds.has(x.attributeSetId) &&
                    seenAttributeSetIds.add(x.attributeSetId),
            )
            .map((x) => {
                return {
                    key: x.attributeSetId,
                    text: x.attributeSetName ?? x.attributeSetId,
                } as IDropdownOption;
            });
    }, [props.attributes]);

    const attributeOptions = useMemo(() => {
        return props.attributes
            .filter((x) => x.attributeSetId === selectedAttributeSet)
            .map((x) => {
                return {
                    key: x.id,
                    text: x.name,
                    data: x,
                } as IDropdownOption;
            });
    }, [props.attributes, selectedAttributeSet]);

    const operationOptions = useMemo(() => {
        if (!selectedAttribute) {
            return [] as IDropdownOption[];
        }

        return getDropdownOptionsForAttribute(selectedAttribute);
    }, [selectedAttribute]);

    const handleChangedAttributeSet = useCallback(
        (attributeSetId: string) => {
            setSelectedAttributeSet(attributeSetId);
            props.onExpressionChange({
                id: props.expression.id,
                op: props.expression.op,
            });
        },
        [props.onExpressionChange, props.expression],
    );

    return (
        <div id={props.ruleName} className={divClassName}>
            <Stack style={{ marginTop: '5px' }}>
                <Stack.Item style={{ display: 'flex' }}>
                    <Checkbox
                        className={checkBoxClassName}
                        styles={{ checkbox: { borderRadius: '50%' } }}
                        onChange={(ev, checked) => {
                            props.onCheckboxChange(!!checked);
                            setCheckBoxClassName(
                                checked ? styles.checkboxShown : styles.checkboxHidden,
                            );
                            setDivClassName(checked ? styles.divSelected : styles.divUnselected);
                        }}
                    />
                    <IconButton
                        aria-label={isCollapsed ? 'Expand expression' : 'Collapse expression'}
                        iconProps={{
                            iconName: isCollapsed ? IconNames.ChevronRight : IconNames.ChevronDown,
                        }}
                        style={{ marginRight: '5px', color: '#242424' }}
                        onClick={() => {
                            props.onCollapseToggle();
                            setIsCollapsed((prev) => !prev);
                        }}
                    />
                    {renderExpressionTitle(
                        isCollapsed,
                        props.ruleName,
                        attributeSetOptions.find((x) => x.key === selectedAttributeSet)?.text ??
                            selectedAttributeSet,
                        selectedAttribute?.name,
                        props.expression.operation,
                        props.expression.value,
                    )}
                </Stack.Item>
            </Stack>
            <Stack
                style={{
                    // This hides the expression without removing it from the DOM for better performance
                    display: isCollapsed ? 'none' : 'block',
                    marginBottom: '15px',
                }}>
                <Stack.Item style={{ display: 'flex', marginLeft: '5px' }}>
                    <div style={{ width: '190px' }}>
                        {props.expression?.op && (
                            <AndOrToggle
                                selected={props.expression.op}
                                onChange={(newOp: LogicalOperatorType) =>
                                    props.onExpressionChange({ ...props.expression, op: newOp })
                                }
                            />
                        )}
                    </div>
                    <Dropdown
                        className={styles.dropDown}
                        label='Attribute set'
                        placeholder='Select set'
                        options={attributeSetOptions}
                        selectedKey={selectedAttributeSet}
                        onChange={(ev, opt) => handleChangedAttributeSet(opt?.key as string)}
                    />
                    <Dropdown
                        className={styles.dropDown}
                        label='Attribute'
                        placeholder='Select attribute'
                        options={attributeOptions}
                        selectedKey={props.expression.attributeId ?? null}
                        onChange={(ev, opt) =>
                            props.onExpressionChange({
                                id: props.expression.id,
                                op: props.expression.op,
                                attributeId: opt?.key as string,
                            })
                        }
                    />
                    <Dropdown
                        className={styles.dropDown}
                        label='Operation'
                        placeholder='Select operation'
                        disabled={selectedAttribute === undefined}
                        options={operationOptions}
                        selectedKey={props.expression.operation ?? null}
                        onChange={(ev, opt) =>
                            props.onExpressionChange({
                                ...props.expression,
                                operation: opt?.key as ComparisonOperatorType,
                            })
                        }
                    />
                    <AttributeExpressionValueSelector
                        style={{ minWidth: '230px' }}
                        type={selectedAttribute?.dataType ?? AttributeDataType.String}
                        freeform={selectedAttribute?.allValuesAllowed ?? true}
                        allowedValues={selectedAttribute?.visibleValues ?? []}
                        selectedValue={props.expression.value}
                        disabled={selectedAttribute === undefined}
                        onChange={(val) =>
                            props.onExpressionChange({
                                ...props.expression,
                                value: val,
                            })
                        }
                    />
                </Stack.Item>
            </Stack>
        </div>
    );
}

function getDropdownOptionsForAttribute(attr: IGetVisibleAttributeResult): IDropdownOption[] {
    if (attr.isMultiValued) {
        if (attr.dataType === AttributeDataType.Integer) {
            return [
                {
                    key: ComparisonOperatorType.NumericArrayContains,
                    text: ComparisonOperatorNatural.Contains,
                },
            ];
        } else {
            return [
                {
                    key: ComparisonOperatorType.StringArrayContains,
                    text: ComparisonOperatorNatural.Contains,
                },
            ];
        }
    }

    if (attr.dataType === AttributeDataType.Integer) {
        if (!attr.allValuesAllowed) {
            return [
                {
                    key: ComparisonOperatorType.NumericEquals,
                    text: ComparisonOperatorNatural.Equals,
                },
            ];
        } else {
            return [
                {
                    key: ComparisonOperatorType.NumericEquals,
                    text: ComparisonOperatorNatural.Equals,
                },
                {
                    key: ComparisonOperatorType.NumericNotEquals,
                    text: ComparisonOperatorNatural.NotEquals,
                },
                {
                    key: ComparisonOperatorType.NumericGreaterThanOrEquals,
                    text: ComparisonOperatorNatural.GreaterThanOrEquals,
                },
                {
                    key: ComparisonOperatorType.NumericLessThanOrEquals,
                    text: ComparisonOperatorNatural.LessThanOrEquals,
                },
                {
                    key: ComparisonOperatorType.NumericGreaterThan,
                    text: ComparisonOperatorNatural.GreaterThan,
                },
                {
                    key: ComparisonOperatorType.NumericLessThan,
                    text: ComparisonOperatorNatural.LessThan,
                },
            ];
        }
    } else if (attr.dataType === AttributeDataType.Boolean) {
        return [{ key: ComparisonOperatorType.BoolEquals, text: ComparisonOperatorNatural.Equals }];
    } else {
        if (!attr.allValuesAllowed) {
            return [
                {
                    key: ComparisonOperatorType.StringEquals,
                    text: ComparisonOperatorNatural.Equals,
                },
            ];
        } else {
            return [
                {
                    key: ComparisonOperatorType.StringEquals,
                    text: ComparisonOperatorNatural.Equals,
                },
                {
                    key: ComparisonOperatorType.StringNotEquals,
                    text: ComparisonOperatorNatural.NotEquals,
                },
            ];
        }
    }
}

enum ComparisonOperatorNatural {
    Equals = 'Equals',
    NotEquals = 'Not Equals',
    GreaterThanOrEquals = 'Greater Than or Equals',
    LessThanOrEquals = 'Less Than or Equals',
    GreaterThan = 'Greater Than',
    LessThan = 'Less Than',
    Contains = 'Contains',
}
interface IAttributeExpressionValueSelector {
    style?: CSSProperties;
    type: AttributeDataType;
    freeform: boolean;
    allowedValues: any[];
    selectedValue?: any;
    onChange: (val: any) => void;
    disabled?: boolean;
}

function AttributeExpressionValueSelector(props: IAttributeExpressionValueSelector): JSX.Element {
    const dropdownOptions: IDropdownOption[] | undefined = useMemo(() => {
        if (props.allowedValues && props.allowedValues.length > 0) {
            return props.allowedValues.map((value, index) => ({
                key: index,
                text: String(value),
                data: value,
            }));
        } else if (props.type === AttributeDataType.Boolean) {
            return [
                { key: 'true', text: 'true', data: true },
                { key: 'false', text: 'false', data: false },
            ];
        }
        return undefined;
    }, [props.allowedValues, props.type]);

    const handleDropdownChange = (option?: IDropdownOption): void => {
        handlePropsOnChange(option?.data);
    };

    const handleTextFieldChange = (newValue?: string): void => {
        handlePropsOnChange(newValue);
    };

    function handlePropsOnChange(val?: any): void {
        let data: any = `${val}`;
        if (props.type === AttributeDataType.Boolean) {
            data = data.toLowerCase() === 'true';
        } else if (props.type === AttributeDataType.Integer) {
            data = parseInt(data || '', 10);
        }
        props.onChange(data);
    }

    if (dropdownOptions) {
        return (
            <ComboBox
                aria-label='value'
                style={props.style}
                disabled={props.disabled}
                label='Value'
                selectedKey={
                    dropdownOptions.find((opt) => opt.data === props.selectedValue)?.key ?? null
                }
                options={dropdownOptions}
                allowFreeInput
                allowFreeform={false}
                onChange={(ev, opt): void => handleDropdownChange(opt)}
                useComboBoxAsMenuWidth
            />
        );
    }

    if (props.type === AttributeDataType.Person) {
        return (
            <>
                <div style={props.style}>
                    <Label>Value</Label>
                    <CoreSinglePrincipalIdPickerTypeaheadSearch
                        ariaLabel='value'
                        disabled={props.disabled}
                        selectedItem={
                            props.selectedValue !== undefined
                                ? String(props.selectedValue)
                                : undefined
                        }
                        onChange={(principalId): void => handleTextFieldChange(principalId)}
                    />
                </div>
            </>
        );
    }

    if (props.type === AttributeDataType.Integer) {
        return (
            <TextField
                aria-label='value'
                style={props.style}
                disabled={props.disabled}
                label='Value'
                type={'number'}
                value={String(props.selectedValue || '')}
                onChange={(ev, val): void => handleTextFieldChange(val)}
            />
        );
    }

    return (
        <TextField
            aria-label='value'
            style={props.style}
            disabled={props.disabled}
            label='Value'
            type={'text'}
            value={String(props.selectedValue || '')}
            onChange={(ev, val): void => handleTextFieldChange(val)}
        />
    );
}

function renderExpressionTitle(
    isCollapsed: boolean,
    expressionName: string,
    selectedAttributeSet?: string,
    selectedAttribute?: string,
    operation?: ComparisonOperatorType,
    value?: any,
): JSX.Element {
    let titleSpan = <span>Rule {expressionName}</span>;
    let displayedOperation = '';
    if (
        operation === ComparisonOperatorType.StringEquals ||
        operation === ComparisonOperatorType.NumericEquals ||
        operation === ComparisonOperatorType.BoolEquals
    ) {
        displayedOperation = ComparisonOperatorNatural.Equals;
    } else if (
        operation === ComparisonOperatorType.StringNotEquals ||
        operation === ComparisonOperatorType.NumericNotEquals
    ) {
        displayedOperation = ComparisonOperatorNatural.NotEquals;
    } else if (operation === ComparisonOperatorType.NumericGreaterThan) {
        displayedOperation = ComparisonOperatorNatural.GreaterThan;
    } else if (operation === ComparisonOperatorType.NumericLessThan) {
        displayedOperation = ComparisonOperatorNatural.LessThan;
    } else if (operation === ComparisonOperatorType.NumericGreaterThanOrEquals) {
        displayedOperation = ComparisonOperatorNatural.GreaterThanOrEquals;
    } else if (operation === ComparisonOperatorType.NumericLessThanOrEquals) {
        displayedOperation = ComparisonOperatorNatural.LessThanOrEquals;
    } else {
        displayedOperation = ComparisonOperatorNatural.Contains;
    }
    if (isCollapsed) {
        titleSpan = (
            <span>
                <span>Rule {expressionName}</span>
                <span className={styles.titleDivider}>|</span>
                <span>{selectedAttributeSet}</span>
                <span className={styles.titleDivider}>۰</span>
                <span>{selectedAttribute}</span>
                <span className={styles.titleDivider}>۰</span>
                <span>{displayedOperation}</span>
                <span className={styles.titleDivider}>۰</span>
                <span>{String(value)}</span>
            </span>
        );
    }

    return <div className={styles.titleContainer}>{titleSpan}</div>;
}

const styles = mergeStyleSets({
    divUnselected: {
        borderBottom: '1px solid #E0E0E0',
        ':hover': {
            background: '#F3F2F1',
            transition: 'border 100ms linear, background 200ms linear',
            '.ms-Checkbox': {
                opacity: 1,
            },
        },
        marginTop: '-5px',
    },
    divSelected: {
        borderBottom: '1px solid #E0E0E0',
        background: '#F3F2F1',
        marginTop: '-5px',
    },
    checkboxHidden: {
        opacity: 0,
        marginTop: '5px',
        marginLeft: '5px',
    },
    checkboxShown: { opacity: 1, marginTop: '5px', marginLeft: '5px' },
    dropDown: {
        width: '230px',
        marginRight: '25px',
        marginBottom: '3px',
    },
    titleContainer: {
        paddingTop: '5px',
    },
    titleDivider: {
        marginLeft: '10px',
        marginRight: '10px',
    },
});
