import React, { useContext, useEffect, useMemo, useState } from 'react';
import GraphClient, { IGraphUserExtended } from 'clients/graph-client';
import { AuthContext } from 'contexts/auth-context';
import { CacheContext } from 'contexts/cache-context';
import { useDetermineImage } from 'components/common/employee/employee-utils';
import { IRenderFunction } from '@fluentui/react';
import ICardPrincipal from 'components/core/common/employee-card/card-principal';
import { getAppInsights } from 'utils/telemetry-utils';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { SystemGuid } from 'utils/cor-utils';

export interface ICardPrincipalProviderFromGraphProps {
    oid?: string;
    cardPrincipal?: ICardPrincipal;
    onRender: IRenderFunction<ICardPrincipal>;
    onCardPrincipalProvided?: (data: ICardPrincipal | undefined) => void;
}

export default function CardPrincipalProviderFromGraph(
    props: ICardPrincipalProviderFromGraphProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const cacheContext = useContext(CacheContext);
    const EmployeeGraphDataCacheName = 'employeeGraphData';

    const { oid, cardPrincipal, onRender, onCardPrincipalProvided } = props;

    const [graphDataCardPrincipal, setGraphDataCardPrincipal] = useState<Partial<ICardPrincipal>>();

    function employeeGraphCacheId(oid: string): string {
        return `${EmployeeGraphDataCacheName}-${oid}`;
    }

    useEffect(() => {
        (async (): Promise<void> => {
            if (oid && oid !== SystemGuid) {
                try {
                    let graphData;
                    graphData = cacheContext.retrieve<IGraphUserExtended>(
                        employeeGraphCacheId(oid),
                        true,
                        EmployeeGraphDataCacheName,
                    );
                    if (!graphData) {
                        graphData = await GraphClient.getUserByOidQuery(
                            authContext,
                            oid,
                            '$select=displayName,jobTitle,officeLocation,userPrincipalName,mail,department&$expand=manager($levels=1;$select=displayName,userPrincipalName)',
                        );
                        if (graphData) {
                            cacheContext.store<IGraphUserExtended>(
                                employeeGraphCacheId(oid),
                                graphData,
                                true,
                                EmployeeGraphDataCacheName,
                            );
                        }
                    }

                    setGraphDataCardPrincipal({
                        oid: props.oid,
                        displayName: graphData?.displayName,
                        jobTitle: graphData?.jobTitle,
                        officeLocation: graphData?.officeLocation,
                        upn: graphData?.userPrincipalName,
                        email: graphData?.mail,
                        organization: graphData?.department,
                        manager: graphData?.manager?.displayName,
                        managerUpn: graphData?.manager?.userPrincipalName,
                    });
                } catch (e) {
                    getAppInsights()?.trackException({
                        exception: e,
                        severityLevel: SeverityLevel.Error,
                    });
                    setGraphDataCardPrincipal({});
                }
            } else {
                setGraphDataCardPrincipal({});
            }
        })();
    }, [authContext, oid]);

    const image = useDetermineImage({
        authContext: authContext,
        employee: oid
            ? {
                  id: oid,
                  oid: oid,
              }
            : undefined,
        cacheContext: cacheContext,
    });

    // Prefer graph data, then provided props, then an empty card principal with a blank display name
    const combinedCardPrincipal = useMemo(
        () =>
            ({
                imageUrl: image,
                ...cardPrincipal,
                ...graphDataCardPrincipal,
            } as ICardPrincipal),
        [cardPrincipal, graphDataCardPrincipal, image],
    );

    useEffect(() => {
        if (onCardPrincipalProvided) {
            onCardPrincipalProvided(combinedCardPrincipal);
        }
    }, [combinedCardPrincipal, onCardPrincipalProvided]);

    return <>{onRender(combinedCardPrincipal)}</>;
}
