import React, { useEffect, useState, useContext } from 'react';
import { IconNames, Dictionary } from 'assets/constants/global-constants';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import EligibilityClient, {
    IEligibility,
    IAttribute,
    IEligibilityHttpRequest,
} from 'clients/eligibility-client';
import {
    ActionButton,
    TextField,
    Dropdown,
    IDropdownOption,
    Checkbox,
    Stack,
    IStackTokens,
    Separator,
    mergeStyleSets,
    mergeStyles,
} from '@fluentui/react';
import { ModalMode } from 'components/eligibilities/eligibilities-constants';
import { distinct, readErrorMessageBody } from 'utils/misc-utils';
import {
    globalCheckboxStyles,
    globalSeparatorStyles,
    globalStyles,
} from 'assets/styles/global-styles';
import deepEqual from 'deep-equal';
import deepcopy from 'deepcopy';
import { strCmp } from 'utils/sort-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface IAddEligibilityModalProps {
    mode: ModalMode;
    attributes: IAttribute[];
    buttonText?: string;
    eligibility?: IEligibility;
    onAddEditEligibilityConcluded?: (
        mode: ModalMode,
        modalConclusion: ModalConclusion,
        result?: IEligibility,
    ) => void;
}

type RequiredAttributesDictType = Dictionary<number>; // key is attribute id, number is groupKey

export default function AddEligibilityModalButton(props: IAddEligibilityModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const canAutoAssignNewEligibility = useFeatureFlag(FeatureFlagKeys.eligibilityAutoAssign)
        .enabled;
    const [eligibilityCode, setEligibilityCode] = useState<string | undefined>('');
    const [eligibilityName, setEligibilityName] = useState<string | undefined>('');
    const [isAutoAssign, setAutoAssign] = useState<boolean>(false);
    const [isUserCanRequest, setUserCanRequest] = useState<boolean>(false);
    const [isAutoProvisionRequest, setAutoProvisionRequest] = useState<boolean>(false);
    const [requiredAttributesDict, setRequiredAttributesDict] = useState<
        RequiredAttributesDictType
    >({});

    const [errorMsg, setErrorMsg] = useState<string>('');

    const addMode = (): boolean => props.mode === ModalMode.Add;
    const updateMode = (): boolean => props.mode === ModalMode.Update;

    useEffect(() => {
        if (updateMode() && !props.eligibility) {
            setErrorMsg('Eligibility not provided');
        }
    }, []);

    const determineRequiredAttributesDict = (
        eligibility: IEligibility | undefined,
    ): RequiredAttributesDictType => {
        const dictVar: RequiredAttributesDictType = {};
        let groupKey: number | undefined = undefined;
        if (eligibility) {
            eligibility.requiredAttributes.forEach((requiredAttributesGroup, ix) => {
                groupKey = ix + 1;
                requiredAttributesGroup.forEach((requiredAttributeId) => {
                    // groupKey starts from 1 so that if its value
                    // is defined, we can consider it a truthy value.
                    dictVar[requiredAttributeId] = groupKey as number;
                });
            });
        }
        return dictVar;
    };

    const initInputs = (): void => {
        if (addMode()) {
            clearInputs();
        } else {
            setEligibilityCode(props?.eligibility?.eligibilityCode);
            setEligibilityName(props?.eligibility?.eligibilityName);
            setAutoAssign(!!props?.eligibility?.autoAssign);
            setUserCanRequest(!!props.eligibility?.userCanRequest);
            setAutoProvisionRequest(!!props.eligibility?.autoProvisionRequest);
            const requiredAttributesDictVar = determineRequiredAttributesDict(props.eligibility);
            setRequiredAttributesDict(requiredAttributesDictVar);
        }
    };

    const isEligibilityValidNonBlank = (str: string | undefined): boolean => {
        // True if:
        //   It doesn't start with underscore "_".
        //   It only contains letters and digits including underscore "_".
        return /^[0-9a-z]+([_0-9a-z]+)*$/i.test(str ?? '');
    };

    const isEligibilityValidForEntry = (str: string | undefined): boolean => {
        // True if:
        //   It doesn't start or end with underscore "_".
        //   It only contains letters and digits including underscore "_".
        //   Or if it's empty.
        return /^$/.test(str ?? '') || isEligibilityValidNonBlank(str);
    };

    const isEligibilityValidForSubmit = (str: string | undefined): boolean => {
        // True if:
        //   It doesn't start or end with underscore "_".
        //   It only contains letters and digits including underscore "_".
        return /^[0-9a-z]+(_[0-9a-z]+)*$/i.test(str ?? '');
    };

    const isDescriptionValidNonBlank = (str: string | undefined): boolean => {
        return /\S/.test(str ?? '');
    };

    const isDescriptionValidForEntry = (str: string | undefined): boolean => {
        return /^$/.test(str ?? '') || isDescriptionValidNonBlank(str);
    };

    const isDescriptionValidForSubmit = (str: string | undefined): boolean => {
        return isDescriptionValidNonBlank(str);
    };

    const clearInputs = (): void => {
        setEligibilityCode('');
        setEligibilityName('');
        setAutoAssign(false);
        setUserCanRequest(false);
        setAutoProvisionRequest(false);
        setRequiredAttributesDict({});
    };

    const sortRequiredAttributes = (
        requiredAttributes: string[][] | undefined,
    ): string[][] | void => {
        if (!requiredAttributes) return;
        // deep copy because sort works inplace and
        // I don't want to change the original array.
        const copy = deepcopy(requiredAttributes);
        // first sort the inner arrays
        copy.forEach((attributeGroupArray) => {
            attributeGroupArray.sort(strCmp);
        });
        // then sort the outer array based on
        // the first element of the inner arrays.
        copy.sort((a1, a2) => strCmp(a1[0], a2[0]));
        return copy;
    };

    const disableSubmit = (): boolean => {
        const requiredAttributesArray = requiredAttributesDictToArray(requiredAttributesDict);

        const isDisable1 = !isEligibilityValidForSubmit(eligibilityCode);
        const isDisable2 = !isDescriptionValidForSubmit(eligibilityName);
        const isDisable3 =
            eligibilityCode === props.eligibility?.eligibilityCode &&
            eligibilityName === props.eligibility?.eligibilityName &&
            isAutoAssign === !!props.eligibility?.autoAssign &&
            isUserCanRequest === !!props.eligibility?.userCanRequest &&
            isAutoProvisionRequest === !!props.eligibility?.autoProvisionRequest &&
            deepEqual(
                // Sorting will catch if the user specifies the same
                // required attributes but only in a different order.
                sortRequiredAttributes(props.eligibility?.requiredAttributes),
                sortRequiredAttributes(requiredAttributesArray),
            );
        const isDisable4 = requiredAttributesArray.length === 0;

        return isDisable1 || isDisable2 || isDisable3 || isDisable4;
    };

    const requiredAttributesDictToArray = (dict: Dictionary<number>): string[][] => {
        const requiredAttributesParam: Dictionary<string[]> = {};
        Object.entries(dict).forEach(([attributeId, groupKey]) => {
            requiredAttributesParam[groupKey] = requiredAttributesParam[groupKey] ?? [];
            requiredAttributesParam[groupKey].push(attributeId);
        });
        return Object.values(requiredAttributesParam);
    };

    const onAddEligibilitySubmit = async (): Promise<IEligibility> => {
        const requiredAttributesParam: Dictionary<string[]> = {};
        Object.entries(requiredAttributesDict).forEach(([attributeId, groupKey]) => {
            requiredAttributesParam[groupKey] = requiredAttributesParam[groupKey] ?? [];
            requiredAttributesParam[groupKey].push(attributeId);
        });
        // The following type casts are safe because checking of disableSubmit()
        // ensures that eligibilityCode and eligibilityName are not undefined.
        const request: IEligibilityHttpRequest = {
            eligibilityCode: eligibilityCode as string,
            eligibilityName: eligibilityName as string,
            requiredAttributes: requiredAttributesDictToArray(requiredAttributesDict),
            autoAssign: isAutoAssign,
            userCanRequest: isUserCanRequest,
            autoProvisionRequest: isAutoProvisionRequest,
        };
        if (props.mode === 'update') {
            request.id = props.eligibility?.id;
        }
        try {
            switch (props.mode) {
                case 'add':
                    return await EligibilityClient.createEligibility(authContext, request);
                case 'update':
                    return await EligibilityClient.updateEligibility(authContext, request);
                default:
                    throw `Invalid value "${props.mode}" provided for the prop 'mode'.`;
            }
        } catch (e) {
            // TODO: Modal Error Display
            // Tech Debt
            // The following is the preferred method to deal with errors when
            // using ModalActionButton, which is get text of the error and throw the text.
            // The modal will catch and display it by using component MessageBar.
            // It's best if those modals that rely on ModalActionButton error
            // processing use the following code as a reference to process the
            // error and throw text of the error.
            const submitErrorEventText = await readErrorMessageBody(e);
            if (submitErrorEventText) {
                // Throw the error message text. The modal will catch and display it.
                throw submitErrorEventText;
            } else {
                console.error('Error processing - AddEligibility');
                console.error(e);
                // I don't know what the error is.
                // Throw it and let the modal catch it.
                throw e;
            }
        }
    };

    const onAddEditEligibilityConcluded = (
        conclusion: ModalConclusion,
        result?: IEligibility,
    ): void => {
        if (props.onAddEditEligibilityConcluded) {
            props.onAddEditEligibilityConcluded(props.mode, conclusion, result);
        }
    };

    const onEligibilityChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        str?: string,
    ): void => {
        if (isEligibilityValidForEntry(str ?? '')) {
            setEligibilityCode(str?.toUpperCase());
        }
    };

    const onDescriptionChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        str?: string,
    ): void => {
        if (isDescriptionValidForEntry(str ?? '')) {
            setEligibilityName(str);
        }
    };

    const iconName = addMode() ? IconNames.Add : IconNames.Edit;
    const buttonText = props.buttonText ?? (addMode() ? 'Add New Eligibility' : 'Edit');
    const submitButtonText = addMode() ? 'Add' : 'Save';

    const renderDropdowns = (): JSX.Element[] => {
        const groupKeysTmp = (distinct(Object.values(requiredAttributesDict)) as number[]).sort(
            (n1, n2) => n1 - n2,
        );
        const maxGroupKey = groupKeysTmp.length ? groupKeysTmp[groupKeysTmp.length - 1] : 0;
        const lastGroupKey = maxGroupKey + 1;
        /**
         * Role of the following additional group key item, ie, the
         * term "maxGroupKey + 1", in the array:
         *
         * If there are dropdowns with some selected options, draw one
         * additional dropdown with nothing selected. Therefore, in the
         * beginning no option is selected and one dropdown appears.
         * The moment user selects an option, a new dropdown will appear
         * underneath.
         **/
        const groupKeys: number[] = [...groupKeysTmp, lastGroupKey];

        return groupKeys.map(
            (groupKey, ix): JSX.Element => {
                const options = Object.values(props.attributes)
                    .filter(
                        (a) =>
                            !requiredAttributesDict[a.id] ||
                            requiredAttributesDict[a.id] === groupKey,
                    )
                    .map((attribute) => ({
                        key: attribute.id,
                        text: attribute.attributeCode,
                        groupKey: groupKey,
                        selected: requiredAttributesDict[attribute.id] === groupKey,
                    }));

                const selectedKeys = Object.values(props.attributes)
                    .filter((attribute) => requiredAttributesDict[attribute.id] === groupKey)
                    .map((attribute) => attribute.id);

                let separatorText: string;
                switch (groupKey) {
                    case groupKeysTmp[0]:
                        separatorText = 'Attribute Requirements';
                        break;
                    case 1: // This case will happen only if there is no attribute requirement.
                        separatorText = 'Add Attribute Requirements';
                        break;
                    case lastGroupKey:
                        separatorText = 'Add Additional Attribute Requirements';
                        break;
                    default:
                        separatorText = '';
                        break;
                }

                const showAndBox = groupKeys.length > 2 && groupKey !== lastGroupKey;
                const showAndText = ix !== 0;
                const showDeleteButton = lastGroupKey > 1 && groupKey !== lastGroupKey;

                const dropdownStyle =
                    groupKey === lastGroupKey
                        ? lastDropdownStyle
                        : groupKeys.length === 2
                        ? onlyOneDropdownWithAttributeRequirements
                        : styles.dropdown;

                return (
                    <Stack key={groupKey}>
                        {!!options.length && (
                            <>
                                {separatorText && (
                                    <Separator styles={separatorStyles} alignContent='start'>
                                        {separatorText}
                                    </Separator>
                                )}

                                <div className={styles.dialogboxRow}>
                                    {renderOneDropdown({
                                        groupKey,
                                        options,
                                        selectedKeys,
                                        onlyShowDropdown: groupKey === lastGroupKey,
                                        showAndBox,
                                        showAndText,
                                        showDeleteButton,
                                        dropdownStyle,
                                    })}
                                </div>
                            </>
                        )}
                    </Stack>
                );
            },
        );
    };

    interface IRenderOneDropdown {
        groupKey: number;
        options: IDropdownOption[];
        selectedKeys: string[];
        onlyShowDropdown: boolean;
        showAndBox: boolean;
        showAndText: boolean;
        showDeleteButton: boolean;
        dropdownStyle: string;
    }
    const renderOneDropdown = (params: IRenderOneDropdown): JSX.Element => {
        return (
            <>
                {params.showAndBox && (
                    <div className={styles.textAND}>
                        <span className={globalStyles.boldFont}>
                            {!params.onlyShowDropdown && params.showAndText && 'AND'}
                        </span>
                    </div>
                )}
                <Dropdown
                    className={params.dropdownStyle}
                    placeholder='Select attributes'
                    selectedKeys={params.selectedKeys}
                    multiSelect
                    onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        item?: IDropdownOption,
                    ): void => {
                        onEligibilitySelection(event, item, params.groupKey);
                    }}
                    options={params.options}
                    multiSelectDelimiter={' or '}
                />
                {params.showDeleteButton && (
                    <div className={styles.deleteButton}>
                        {!params.onlyShowDropdown && (
                            <ActionButton
                                iconProps={{ iconName: IconNames.Delete }}
                                onClick={() => onDeleteRequirementGroup(params.groupKey)}
                            />
                        )}
                    </div>
                )}
            </>
        );
    };

    const onDeleteRequirementGroup = (groupKeyParam: number): void => {
        const requiredAttributesDictVar = { ...requiredAttributesDict };
        Object.entries(requiredAttributesDict).forEach(([attributeId, groupKey]) => {
            if (groupKey === groupKeyParam) {
                delete requiredAttributesDictVar[attributeId];
            }
        });
        setRequiredAttributesDict(requiredAttributesDictVar);
    };

    const onEligibilitySelection = (
        event: React.FormEvent<HTMLDivElement>,
        item: IDropdownOption | undefined,
        groupKey: number,
    ): void => {
        if (item === undefined) {
            return;
        }
        const newDict = { ...requiredAttributesDict };
        if (item.selected) {
            newDict[item.key] = groupKey;
        } else {
            delete newDict[item.key];
        }
        setRequiredAttributesDict(newDict);
    };

    const onClickAutoAssign = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean,
    ): void => {
        setAutoAssign(!!checked);
    };

    const onClickUserCanRequest = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean,
    ): void => {
        setUserCanRequest(!!checked);
        if (!checked) {
            setAutoProvisionRequest(false);
        }
    };

    const onClickAutoProvisionRequest = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean,
    ): void => {
        setAutoProvisionRequest(!!checked);
    };

    const onModalOpen = (): void => {
        initInputs();
    };

    return (
        <ModalActionButton<IEligibility>
            text={buttonText}
            iconName={iconName}
            errorMsg={errorMsg}
            modalTitle={buttonText}
            modalTitleIcon={iconName}
            enableSubmit={!disableSubmit()}
            submitButtonText={submitButtonText}
            submitButtonIcon={iconName}
            onSubmit={onAddEligibilitySubmit}
            onButtonClick={onModalOpen}
            onModalConcluded={onAddEditEligibilityConcluded}>
            <Stack tokens={filterStackTokens}>
                <TextField
                    className={styles.textField}
                    label='Eligibility Code'
                    value={eligibilityCode || ''}
                    ariaLabel='Eligibility Code'
                    placeholder='Enter a code'
                    onChange={onEligibilityChange}
                />
                <TextField
                    className={styles.textField}
                    label='Eligibility Description'
                    value={eligibilityName || ''}
                    ariaLabel='Eligibility Description'
                    placeholder='Enter a description'
                    onChange={onDescriptionChange}
                />
                {renderDropdowns()}
                <div>
                    <Separator styles={globalSeparatorStyles} />
                    {canAutoAssignNewEligibility && (
                        <Checkbox
                            styles={globalCheckboxStyles}
                            label='Auto Assign'
                            onChange={onClickAutoAssign}
                            checked={isAutoAssign}
                        />
                    )}
                    <Checkbox
                        styles={globalCheckboxStyles}
                        label='User Can Request'
                        onChange={onClickUserCanRequest}
                        checked={isUserCanRequest}
                    />
                    <Checkbox
                        styles={globalCheckboxStyles}
                        label='Auto Provision Request'
                        onChange={onClickAutoProvisionRequest}
                        checked={isAutoProvisionRequest}
                        disabled={!isUserCanRequest}
                    />
                </div>
            </Stack>
        </ModalActionButton>
    );
}

const filterStackTokens: IStackTokens = {
    childrenGap: 5,
};

const styles = mergeStyleSets({
    textField: {
        width: 534,
    },
    dialogboxRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    dropdown: {
        display: 'inline-block',
        flexGrow: 1,
        marginTop: 5,
        width: 450,
    },
    deleteButton: {
        display: 'inline-block',
        flexGrow: 0,
        marginRight: -7,
        marginBottom: -3,
        width: 34,
    },
    textAND: {
        display: 'inline-block',
        flexGrow: 0,
        paddingRight: 5,
        paddingBottom: 5,
        width: 50,
        textAlign: 'end',
    },
});

const onlyOneDropdownWithAttributeRequirements = mergeStyles(styles.dropdown, {
    width: 504,
});

const lastDropdownStyle = mergeStyles(styles.dropdown, {
    maxWidth: '100%',
});

const separatorStyles = mergeStyleSets({
    ...globalSeparatorStyles,
    root: {
        marginTop: 5,
        marginBottom: 5,
    },
});
