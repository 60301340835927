import React from 'react';
import Visitors from 'components/visitors/visitors';
import VisitorsDataProvider from 'components/visitors/visitors-data-context';
import VisitorsFilterProvider from 'components/visitors/visitors-filter-context';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function VisitorsWrapper(routeProps: any): JSX.Element {
    return (
        <VisitorsFilterProvider>
            <VisitorsDataProvider>
                <Visitors {...routeProps} />
            </VisitorsDataProvider>
        </VisitorsFilterProvider>
    );
}
