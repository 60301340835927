import { IPersonaProps, PersonaInitialsColor, IDropdownOption } from '@fluentui/react';
import { IPerson } from 'clients/graph-client';
import { IBasicEmployee, IEmployee, IEmployeeEditableResponse } from 'clients/employee-client';
import {
    getCachedPicture,
    getDisplayNameOrDefault,
    getEmployeeInitials,
} from 'components/common/employee/employee-utils';
import { IPreHire } from 'components/screening/us-gov/IScreening';
import { IContract } from 'components/screening/us-gov/IContract';
import { ICacheContext } from 'contexts/cache-context';

export function transformPersonToPersona(member: IPerson): IPersonaProps {
    return {
        imageInitials: `${(member.givenName ?? ' ')[0]}${(member.surname ?? ' ')[0]}`
            ?.trim()
            ?.toUpperCase(),
        text: member.displayName,
        secondaryText: member.mail,
        tertiaryText: member.userPrincipalName,
        showInitialsUntilImageLoads: true,
        itemProp: JSON.stringify(member),
    };
}

export function transformEmployeeToPersona(
    emp: IEmployee,
    customSecondaryText?: string,
    cacheContext?: ICacheContext,
): IPersonaProps {
    const imageUrl = cacheContext ? getCachedPicture(emp, cacheContext) : undefined;
    return {
        imageInitials: getEmployeeInitials(emp),
        imageUrl: imageUrl,
        imageShouldFadeIn: !imageUrl,
        imageShouldStartVisible: !!imageUrl,
        text: getDisplayNameOrDefault(emp),
        secondaryText: customSecondaryText ?? emp.email ?? `${emp.alias}@microsoft.com`,
        tertiaryText: emp.alias,
        optionalText: emp.userPrincipalName, //  this is added to address bug 25350335, where emp.userPrincipalName can differe from emp.email
        showInitialsUntilImageLoads: true,
        itemProp: JSON.stringify(emp),
    };
}

export function transformBasicEmployeeToPersona(
    emp: IBasicEmployee,
    customSecondaryText?: string,
): IPersonaProps {
    return {
        imageInitials: getEmployeeInitials(emp),
        text: getDisplayNameOrDefault(emp),
        secondaryText: customSecondaryText ?? emp.mail,
        tertiaryText: emp.mail,
        showInitialsUntilImageLoads: true,
        itemProp: JSON.stringify(emp),
    };
}

export function transformEmailToPersona(email: string): IPersonaProps {
    const secondChar = email.charAt(1);
    const secondInitial = secondChar !== undefined && secondChar !== '@' ? secondChar : '';
    const initial = `${email.charAt(0)}${secondInitial}`;
    return {
        imageInitials: initial,
        text: email,
        secondaryText: email,
        tertiaryText: email,
        showInitialsUntilImageLoads: true,
    };
}

// Can be used together with onCandidateSelected of EmployeePickerTypeaheadSearch.
export const getEmployeeFromPersona = (persona?: IPersonaProps): IEmployee | undefined => {
    if (persona?.itemProp) {
        return JSON.parse(persona.itemProp);
    }
    return undefined;
};

export function transformPrehireToPersona(prehire: IPreHire): IPersonaProps {
    return {
        imageInitials: `${(prehire.firstName ?? ' ')[0]}${(prehire.lastName ?? ' ')[0]}`
            ?.trim()
            ?.toUpperCase(),
        text: `${prehire.firstName} ${prehire.lastName}`,
        secondaryText: prehire.pcn,
        itemProp: JSON.stringify(prehire),
    };
}

export function transformEditableEmployeeInfoToPersona(
    prehire: IEmployeeEditableResponse,
): IPersonaProps {
    return {
        imageInitials: getEmployeeInitials({
            firstName: prehire.employeeEditableInfo.firstName,
            lastName: prehire.employeeEditableInfo.lastName,
        }),
        text: getDisplayNameOrDefault({
            firstName: prehire.employeeEditableInfo.firstName,
            lastName: prehire.employeeEditableInfo.lastName,
        }),
        secondaryText: undefined,
        tertiaryText: undefined,
        showInitialsUntilImageLoads: true,
        itemProp: JSON.stringify(prehire),
    };
}

export function transformPersonToDropdownOption(
    member: IPerson,
    keyPrefix: string,
): IDropdownOption {
    return {
        key: keyPrefix + member.id,
        id: member.id,
        data: member,
        text: `${member.surname}, ${member.givenName}`,
        title: member.displayName,
        itemType: 0,
    };
}

export function transformContractsToPersonas(contracts: IContract[]): IPersonaProps[] {
    const retContracts: IPersonaProps[] = [];
    for (const contract of contracts) {
        const persona = transformContractToPersona(contract);
        if (persona) {
            retContracts.push(persona);
        }
    }
    return retContracts;
}

export function transformContractToPersona(
    contract: IContract | undefined,
): IPersonaProps | undefined {
    if (contract) {
        return {
            initialsColor: PersonaInitialsColor.darkGreen,
            text: contract.id,
            secondaryText: contract.project,
            itemProp: JSON.stringify(contract),
            styles: {
                root: { maxWidth: '280px', minWidth: '150px' },
                primaryText: { overflow: 'unset', textOverflow: 'unset', overflowX: 'unset' },
            },
        } as IPersonaProps;
    }
}

export const transformEmployeePersonasToIds = (personas: IPersonaProps[]): string[] => {
    // Converts employee Personas to employee Ids. If persona is undefined or null, it is ignored.
    return personas.map((persona) => {
        if (persona?.itemProp) {
            const employee = JSON.parse(persona?.itemProp);
            if (!!employee?.id) {
                return employee.id;
            }
        }
    });
};
