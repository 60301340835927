import { BadgeColorHex } from 'assets/constants/global-colors';
import Badge from 'components/common/badge';
import React from 'react';

export const setFormData = (
    htmlBody: string,
    templateLabelValue: string,
    modelFields: string[],
) => {
    const blob = new Blob([htmlBody], { type: 'plain/text', endings: 'transparent' });
    const formData = new FormData();
    formData.append('file', blob);
    formData.append('templateLabel', templateLabelValue);
    formData.append('modelFields', JSON.stringify(modelFields));
    return formData;
};

export const getResultCodeBadge = (resultCode: string, isSent: boolean): JSX.Element => {
    if (!resultCode) {
        return <Badge text={'PENDING'} backgroundColor={BadgeColorHex.YELLOW} />;
    }
    return (
        <Badge
            text={`${resultCode}`}
            backgroundColor={isSent ? BadgeColorHex.GREEN : BadgeColorHex.RED}
        />
    );
};
