import React, { ReactNode, useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import ModalActionButton, {
    ModalConclusion,
    onModalConcludeType,
} from 'components/common/buttons/modal-action-button';
import { ModalSizeType } from 'components/common/modal';
import { IconNames } from 'assets/constants/global-constants';
import GroupClient, { IGroup } from 'clients/group-client';
import { useCheckMountedState } from 'utils/misc-hooks';
import { MessageBar, MessageBarType } from '@fluentui/react';
import BoldFont from 'components/common/misc/bold-font';
import { doNothingAsync } from 'utils/misc-utils';
import { ManageGroupsVariableContext } from 'components/groups/manage-groups/contexts/manage-groups-variable-context';
import { Redirect } from 'react-router-dom';

enum ModalStages {
    confirmDeletion,
    groupDeleted,
}

interface DeleteGroupModalActionButtonProps {
    group: IGroup;
}

export default function DeleteGroupModalActionButton(
    props: DeleteGroupModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const allGroupsContext = useContext(ManageGroupsVariableContext);

    const [modalStage, setModalStage] = useCheckMountedState<ModalStages>(
        ModalStages.confirmDeletion,
    );
    const [shouldRedirect, setShouldRedirect] = useCheckMountedState<boolean>(false);

    const onButtonClick = (): void => {
        setModalStage(ModalStages.confirmDeletion);
        setShouldRedirect(false);
    };

    const deleteGroup = async (): Promise<void> => {
        try {
            await GroupClient.deleteGroup(authContext, props.group?.id);
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (((e as unknown) as any).status === 405) {
                throw 'Cannot delete this group because some other group(s) depend on it';
            } else {
                throw 'Error trying to delete the group';
            }
        }
    };

    const onDeleteReturned = (conclusion: ModalConclusion): void => {
        if (conclusion === ModalConclusion.Done) {
            setModalStage(ModalStages.groupDeleted);
        }
    };

    const onCloseDialog = (conclusion: ModalConclusion): void => {
        if (conclusion === ModalConclusion.Done) {
            allGroupsContext.deleteRow(props.group?.id);
            setShouldRedirect(true);
        }
    };

    const determineModalProps = (): ModalPropsType => {
        switch (modalStage) {
            case ModalStages.confirmDeletion:
                return {
                    keepOpenAfterSubmit: true,
                    shouldHideCancelButton: false,
                    submitButtonText: 'Delete',
                    contents: (
                        <span>
                            {' '}
                            Are you sure you want to delete group{' '}
                            <BoldFont>{props.group?.name}</BoldFont>?
                        </span>
                    ),
                    onSubmit: deleteGroup,
                    onModalConcluded: onDeleteReturned,
                };
            case ModalStages.groupDeleted:
            default:
                return {
                    keepOpenAfterSubmit: false,
                    shouldHideCancelButton: true,
                    submitButtonText: 'Close',
                    contents: (
                        <MessageBar messageBarType={MessageBarType.success}>
                            group <BoldFont>{props.group?.name}</BoldFont> deleted.
                        </MessageBar>
                    ),
                    onSubmit: doNothingAsync,
                    onModalConcluded: onCloseDialog,
                };
        }
    };

    const modalProps = determineModalProps();

    if (!!shouldRedirect) {
        // After deleting the group, redirect the user to My Groups page.
        // My Groups page is the URL path '/groups'.
        return <Redirect to={'/groups'} push={false} />;
    } else {
        return (
            <ModalActionButton
                enable={true}
                text='Delete Group'
                iconName={IconNames.Delete}
                modalTitle={`Delete Group ${props.group?.name}`}
                modalTitleIcon=''
                enableSubmit={true}
                size={ModalSizeType.mediumLarge}
                onButtonClick={onButtonClick}
                {...modalProps}>
                {modalProps.contents}
            </ModalActionButton>
        );
    }
}

type ModalPropsType = {
    keepOpenAfterSubmit: boolean;
    shouldHideCancelButton: boolean;
    submitButtonText: string;
    contents: ReactNode;
    onSubmit: () => Promise<void>;
    onModalConcluded: onModalConcludeType<void>;
};
