import { mergeStyleSets } from '@fluentui/react';
import {
    FeedbackButton,
    FeedbackDataType,
    FeedbackTypeKeys,
} from 'components/common/page-shell/feedback-notifications-profile/feedback/feedback-common';
import React from 'react';

type FeedbackActionsPropsType = {
    feedback: FeedbackDataType;
    setFeedback: (feedback: FeedbackDataType) => void;
};

export default function FeedbackActions({
    feedback,
    setFeedback,
}: FeedbackActionsPropsType): JSX.Element {
    const actionClicked = (feedbackType: keyof typeof FeedbackTypeKeys): void => {
        setFeedback({ ...feedback, type: feedbackType });
    };

    return (
        <div className={styles.buttonFlexColumn}>
            <div>
                {FeedbackButton(FeedbackTypeKeys.compliment, () =>
                    actionClicked(FeedbackTypeKeys.compliment),
                )}
            </div>
            <div>
                {FeedbackButton(FeedbackTypeKeys.suggestion, () =>
                    actionClicked(FeedbackTypeKeys.suggestion),
                )}
            </div>
            <div>
                {FeedbackButton(FeedbackTypeKeys.problem, () =>
                    actionClicked(FeedbackTypeKeys.problem),
                )}
            </div>
        </div>
    );
}

const styles = mergeStyleSets({
    buttonFlexColumn: {
        display: 'flex',
        gap: '20px',
        flexDirection: 'column',
    },
});
