import {
    ActionButton,
    FontWeights,
    mergeStyles,
    MessageBarType,
    Separator,
    Spinner,
    TextField,
    Toggle,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import { globalSeparatorStyles } from 'assets/styles/global-styles';
import EmailClient, { IRegisteredEmailService } from 'clients/email-client';
import useMessageBar from 'components/common/use-message-bar';
import { AuthContext } from 'contexts/auth-context';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { dateToFormattedDateTimeStringFromSeconds } from 'utils/time-utils';

function EmailManage(): JSX.Element {
    const authContext = useContext(AuthContext);
    const [isSendingOn, setIsSendingOn] = useState<boolean>();
    const [services, setServices] = useState<IRegisteredEmailService[]>([]);
    const [serviceSaving, setServiceSaving] = useState<string>();
    const [serviceEditing, setServiceEditing] = useState<IRegisteredEmailService>();

    const [isLoadingServices, setLoadingServices] = useState<boolean>(true);
    const errorMessageBar = useMessageBar({ type: MessageBarType.error });

    const getRegisteredEmailServices = useCallback(async () => {
        try {
            const registeredServices = (await EmailClient.getRegisteredEmailServices(authContext))
                .registeredServices;
            setServices(registeredServices);
        } catch (e) {
            console.error(e);
            errorMessageBar.setMessage(
                'There was an error fetching registered services. Please refresh to try again.',
            );
        } finally {
            setLoadingServices(false);
        }
    }, [authContext]);

    const setPauseStatus = useCallback(async () => {
        try {
            const isEmailPaused = await EmailClient.getEmailPauseStatus(authContext);
            setIsSendingOn(isEmailPaused.emailsEnabled);
        } catch (e) {
            errorMessageBar.setMessage(
                'There was an error fetching email pause status. Please refresh to try again.',
            );
        }
    }, [authContext, errorMessageBar]);

    useEffect(() => {
        setPauseStatus();
        getRegisteredEmailServices();
    }, []);

    const toggleGlobalEmailSending = useCallback(
        async (shouldSend: boolean): Promise<void> => {
            const gerund = shouldSend ? 'resuming' : 'pausing';
            setIsSendingOn(undefined);
            errorMessageBar.clearMessage();
            try {
                if (shouldSend) {
                    await EmailClient.resumeEmails(authContext);
                } else {
                    await EmailClient.pauseEmails(authContext);
                }
                setPauseStatus();
            } catch (e) {
                console.error(e);
                errorMessageBar.setMessage(`There was an error ${gerund} emails.`);
            }
        },
        [authContext, errorMessageBar, setPauseStatus],
    );

    const toggleServiceEmailSending = useCallback(
        async (id: string, checked: boolean) => {
            try {
                errorMessageBar.clearMessage();
                setServiceSaving(id);
                const updateServiceResponse = await EmailClient.pauseEmailsByService(
                    authContext,
                    id,
                    checked,
                );
                setServices((prev) => {
                    const index = prev.findIndex((el) => el.id === id);
                    const newServices = [...prev];
                    newServices[index] = updateServiceResponse.registeredService;
                    return newServices;
                });
            } catch (e) {
                console.error(e);
                errorMessageBar.setMessage(`There was an error toggling emails for service ${id}.`);
            } finally {
                setServiceSaving(undefined);
            }
        },
        [authContext, errorMessageBar],
    );

    const updateServiceName = useCallback(async () => {
        if (!serviceEditing?.id) {
            return;
        }
        try {
            setServiceSaving(serviceEditing.id);
            const updateService = await EmailClient.updateServiceName(
                authContext,
                serviceEditing.id,
                serviceEditing.name,
            );
            setServices((prev) => {
                const index = prev.findIndex((el) => el.id === serviceEditing.id);
                const newServices = [...prev];
                newServices[index] = updateService;
                return newServices;
            });
        } catch (e) {
            console.error(e);
            errorMessageBar.setMessage(
                `There was an error editing name for service ${serviceEditing.id}.`,
            );
        } finally {
            setServiceSaving(undefined);
            setServiceEditing(undefined);
        }
    }, [authContext, errorMessageBar, serviceEditing]);

    const mainEmailToggle = useMemo(() => {
        return (
            <Toggle
                label={<h1 className={headerStyle}>Email Status</h1>}
                disabled={isSendingOn === undefined}
                checked={isSendingOn ?? false}
                onText='Sending'
                offText='Paused'
                inlineLabel
                onChange={(ev, checked) => {
                    if (checked !== undefined) {
                        toggleGlobalEmailSending(checked);
                    }
                }}
            />
        );
    }, [isSendingOn, toggleGlobalEmailSending]);

    const editServiceTextField = useCallback(() => {
        return (
            <div style={{ width: '370px', marginBottom: '2rem', marginTop: '1rem' }}>
                <TextField
                    value={serviceEditing?.name || ''}
                    onChange={(ev, value) =>
                        setServiceEditing((prev) => {
                            if (!prev) {
                                return undefined;
                            }
                            const updatedService = { ...prev };
                            updatedService.name = value ?? '';
                            return updatedService;
                        })
                    }
                />
            </div>
        );
    }, [serviceEditing]);

    const createToggleElement = useCallback(
        (service: IRegisteredEmailService) => {
            const isServicePausing = service.id === serviceSaving;
            const isServiceEditing = serviceEditing?.id === service.id;
            return (
                <div style={{ display: 'flex', marginLeft: '4rem' }}>
                    <div style={{ display: 'flex', width: '480px' }}>
                        <Toggle
                            label={
                                isServiceEditing ? (
                                    editServiceTextField()
                                ) : (
                                    <h2 className={serviceHeaderStyle}>
                                        {service.name || service.id}
                                    </h2>
                                )
                            }
                            checked={service.enabled}
                            disabled={!isSendingOn || serviceSaving !== undefined}
                            className={isServicePausing ? pausingStyle : ''}
                            onText='Sending'
                            offText='Paused'
                            onChange={(ev, checked) =>
                                toggleServiceEmailSending(service.id, checked ?? false)
                            }
                        />
                        {isServiceEditing ? (
                            <div className={cancelSaveButtonStyles}>
                                <ActionButton
                                    disabled={!!serviceSaving}
                                    aria-label='Save service name'
                                    iconProps={{ iconName: IconNames.CheckMark }}
                                    onClick={updateServiceName}
                                />
                                <ActionButton
                                    disabled={!!serviceSaving}
                                    aria-label='Cancel'
                                    iconProps={{ iconName: IconNames.Cancel }}
                                    onClick={() => setServiceEditing(undefined)}
                                />
                            </div>
                        ) : (
                            <ActionButton
                                aria-label='Edit service name'
                                iconProps={{ iconName: IconNames.Edit }}
                                onClick={() => setServiceEditing(service)}
                            />
                        )}
                    </div>
                    {isServicePausing && <Spinner style={{ paddingBottom: '5px' }} />}
                    <span style={{ fontStyle: 'italic', marginTop: 'auto', marginLeft: '10%' }}>
                        last updated:{' '}
                        {service.lastModifiedTimeStamp
                            ? dateToFormattedDateTimeStringFromSeconds(
                                  service.lastModifiedTimeStamp,
                              )
                            : 'never'}
                    </span>
                </div>
            );
        },
        [isSendingOn, serviceEditing, serviceSaving, toggleServiceEmailSending],
    );

    return (
        <div style={{ marginTop: '1rem' }}>
            {errorMessageBar.theElement()}
            {mainEmailToggle}
            {isLoadingServices ? (
                <Spinner />
            ) : (
                <>
                    {services.map((service) => (
                        <div key={service.id}>
                            <Separator styles={globalSeparatorStyles} />
                            {createToggleElement(service)}
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default React.memo(EmailManage);

const headerStyle = mergeStyles({
    fontWeight: FontWeights.semilight,
    width: '150px',
    paddingBottom: '7px',
});

const serviceHeaderStyle = mergeStyles({
    fontWeight: FontWeights.semilight,
    paddingBottom: '7px',
    marginRight: '.5rem',
    cursor: 'pointer',
});

const pausingStyle = mergeStyles({
    opacity: '.75',
});

const cancelSaveButtonStyles = mergeStyles({
    '& i': {
        fontSize: 'large',
    },
});
