import React, { ReactNode } from 'react';
import { largeAtTheCenter } from 'assets/styles/global-styles';

/**
 * Do not directly use this component. Instead, use either of the modules
 * listed further below. Or, if necessary, develop a new one that uses
 * this module, and again, if necessary, add compatible props to this
 * module to support the new module you wish to have.
 *
 * List of available modules which are recommended to use:
 *      ProblemLoadingData
 *            Accepts a boolean as indicator of problem loading.
 *            Shows value of constant problemLoadingPageMsg (Note (±) below) as the error message.
 *      ProblemLoadingPage
 *            Accepts a boolean as indicator of problem loading.
 *            Shows value of constant problemLoadingDataMsg (Note (±) below) as the error message.
 *      ProblemLoadingMsg
 *            Accepts a string as the error message.
 *            Shows the string as the error message.
 *
 *
 * The above modules are designed to be least intrusive in the
 * sense that with minimal typing, you can use either one. You
 * will first decide what criteria best describes a 'page load
 * problem' - a boolean flag or a text string. Then decide if
 * you want to write either of the messages defined by constants
 * of Note (±) below or determine your own message, and accordingly
 * decide which one of the above modules to use. If neither of
 * them works, develop a new one that uses this module, and if
 * necessary, add props to this module to support the new one
 * you wish to have. Try to make the new props work in the same
 * fashion as this module works.
 *
 * Examples:
 *      <ProblemLoading isProblemLoadingPage={true | false} />
 *      <ProblemLoading isProblemLoadingData={true | false} />
 *      <ProblemLoading problemLoadingMsg='Problem loading page. />
 *      <ProblemLoading problemLoadingMsg='Problem loading data. />
 */

export interface IProblemLoadingProps {
    isProblemLoadingPage?: boolean;
    isProblemLoadingData?: boolean;
    problemLoadingMsg?: string;
    children?: ReactNode;
}

// Note (±)
const problemLoadingPageMsg = 'There was a problem loading the page.';
const problemLoadingDataMsg = 'There was a problem loading data.';

export function ProblemLoading(props: IProblemLoadingProps): JSX.Element {
    if (props.isProblemLoadingPage) {
        return <div className={largeAtTheCenter}>{problemLoadingPageMsg}</div>;
    } else if (props.isProblemLoadingData) {
        return <div className={largeAtTheCenter}>{problemLoadingDataMsg}</div>;
    } else if (props.problemLoadingMsg) {
        return <div className={largeAtTheCenter}>{props.problemLoadingMsg}</div>;
    } else {
        return <>{props.children}</>;
    }
}
