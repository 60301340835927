import {
    DefaultButton,
    MessageBarType,
    PrimaryButton,
    Stack,
    mergeStyleSets,
    mergeStyles,
} from '@fluentui/react';
import { inputFieldErrorColor } from 'assets/constants/global-colors';
import { IconNames } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import GroupClient, { IGroupBasic, IMyGroup } from 'clients/group-client';
import { IPagedResults } from 'clients/http-options';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import HorizontalBar from 'components/common/horizontal-bar';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';
import Spacer from 'components/common/spacer';
import { useCorePrincipalIdPicker } from 'components/common/use-input/use-core-employee-picker';
import { useTextField } from 'components/common/use-input/use-textfield';
import useMessageBar from 'components/common/use-message-bar';
import { requestToJoinBreadcrumbs } from 'components/groups/request-to-join/request-to-join-utils';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import { Location } from 'history';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useFetchSimple } from 'utils/misc-hooks';
import { doNothing, readErrorMessageBody } from 'utils/misc-utils';

interface IRequestToJoinGroupProps {
    location: Location;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function RequestToJoinGroup(props: IRequestToJoinGroupProps): JSX.Element {
    const params = useParams<{ groupId: string }>();
    const authContext = useContext(AuthContext);
    const principalUserContext = useContext(PrincipalUserContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const [groupBasic, setGroupBasic] = useState<IGroupBasic>();
    const [errorGettingGroup, setEerrorGettingGroup] = useState<string>();

    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [isFetchComplete, setIsFetchComplete] = useState<boolean>(false);
    const [myGroups, setMyGroups] = useState<IMyGroup[]>([]);
    const [myGroupsContinuation, setMyGroupsContinuation] = useState<string>();
    const [hasAlreadyRequested, setHasAlreadyRequested] = useState<boolean>();
    const [errorCheckingJoinRequest, setErrorCheckingJoinRequest] = useState<string>();
    const [hasCheckedRequestStatus, setHasCheckedRequestStatus] = useState<boolean>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    /**
     * 1) Fetch group.
     *      Check group.allowJoin.
     * 2) Fetch this address: grp/api/v1/group/{groupId}/memberships/request
     *      Return type: Boolean.
     *          If true, already requested to join
     * 3) If not already requested to join, show request form
     *      group.requireSponsor
     *      group.requireJustification
     */

    const {
        theMessage: successMsg,
        theElement: successMsgBar,
        setMessage: setSuccessMsg,
    } = useMessageBar({ type: MessageBarType.success, disableClear: true });

    const {
        theMessage: requestErrMsg,
        theElement: requestErrMsgBar,
        setMessage: setRequestErrMsg,
        clearMessage: clearRequestErrMsg,
    } = useMessageBar({ type: MessageBarType.error });

    // Fetches group memberships for current user
    // to check if he/she is already a member.
    useFetchSimple({
        dependencies: [params.groupId, isInitialLoad, myGroupsContinuation],
        canPerformFetch: !!params.groupId && (isInitialLoad || !!myGroupsContinuation),
        fetchFunc: async (): Promise<IPagedResults<IMyGroup>> => {
            setIsInitialLoad(false);
            return GroupClient.getMyGroups(authContext, myGroupsContinuation);
        },
        onSuccess: (result: IPagedResults<IMyGroup>) => {
            const { results, continuationToken } = result;
            setMyGroups((currentValue) => [...currentValue].concat(results));
            setMyGroupsContinuation(continuationToken);
            if (!continuationToken) {
                setIsFetchComplete(true);
            }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any) => {
            if (typeof error === 'string') {
                setEerrorGettingGroup(error);
            } else {
                setEerrorGettingGroup('Error getting group information');
            }
        },
        onFinally: doNothing,
    });

    const isAlreadyAmember = useMemo(() => {
        if (!isFetchComplete) {
            return false;
        }
        return !!myGroups.find((group) => group.id === params.groupId);
    }, [isFetchComplete, myGroups, params.groupId]);

    useFetchSimple({
        dependencies: [params.groupId],
        canPerformFetch: !!params.groupId,
        fetchFunc: async (): Promise<IGroupBasic> => {
            return GroupClient.getGroupBasic(authContext, params.groupId);
        },
        onSuccess: (result: IGroupBasic) => {
            setGroupBasic(result);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any) => {
            if (typeof error === 'string') {
                setEerrorGettingGroup(error);
            } else {
                setEerrorGettingGroup('Error getting group information');
            }
        },
        onFinally: doNothing,
    });

    const { isFetching: isCheckingRequestStatus } = useFetchSimple({
        dependencies: [params.groupId],
        canPerformFetch: !!params.groupId,
        fetchFunc: async (): Promise<boolean> => {
            return GroupClient.checkJoinRequest(authContext, params.groupId);
        },
        onSuccess: (joinRequest: boolean): void => {
            setHasAlreadyRequested(joinRequest);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (error: any): void => {
            if (typeof error === 'string') {
                setErrorCheckingJoinRequest(error);
            } else {
                setErrorCheckingJoinRequest('Error checking request status');
            }
        },
        onFinally: () => {
            setHasCheckedRequestStatus(true);
        },
    });

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs(requestToJoinBreadcrumbs(groupBasic?.name));
    }, [groupBasic]);

    const {
        value: sponsor,
        theElement: pickSponsor,
        initialize: initSponsor,
    } = useCorePrincipalIdPicker({
        label: 'Sponsor',
        required: groupBasic?.requireSponsor,
        disabled: !!successMsg,
    });

    const justificationMaxLen = 500;

    const {
        value: justification,
        theElement: justificationInputField,
        initialize: initJustification,
    } = useTextField({
        maxLength: justificationMaxLen,
        multiline: true,
        rows: 4,
        disabled: !!successMsg,
        onRenderLabel: (): JSX.Element => {
            return (
                <div className={styles.justificationLength}>
                    <Spacer marginTop={20} />
                    <Stack horizontal horizontalAlign='space-between'>
                        <span>
                            <span className={globalStyles.boldFont}>
                                Provide a business justification for this group member join
                            </span>
                            <span>&nbsp;</span>
                            {groupBasic?.requireJustification && (
                                <span className={mergeStyles({ color: inputFieldErrorColor })}>
                                    *&nbsp;
                                </span>
                            )}
                        </span>
                        <span>
                            {justification?.length ?? 0}/{justificationMaxLen}
                        </span>
                    </Stack>
                    <Spacer marginTop={10} />
                </div>
            );
        },
    });

    const isSubmitEnabled = (): boolean => {
        if (
            !groupBasic ||
            (groupBasic.requireSponsor && !sponsor) ||
            (groupBasic.requireJustification && !justification) ||
            !!successMsg ||
            isSubmitting
        ) {
            return false;
        }
        return true;
    };

    const onSubmit = async (): Promise<void> => {
        if (!isSubmitEnabled()) {
            return;
        }
        try {
            setIsSubmitting(true);
            clearRequestErrMsg();
            await GroupClient.createJoinRequest(authContext, {
                groupId: params.groupId,
                personnelId: principalUserContext.principalRecord.id,
                sponsorId: sponsor ?? null,
                justification: justification ?? '',
            });
            setSuccessMsg('Successfully requested to join');
        } catch (e) {
            const msg = (await readErrorMessageBody(e)) || 'Error sending request to join';
            setRequestErrMsg(msg);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isFetchComplete) {
        // Don't show anything until current user's membership status is checked.
        return <IsLoadingIndicator isLoading={!isFetchComplete} marginTop={20} />;
    } else if (isAlreadyAmember) {
        // User is already a member. Send them to "My Groups"
        return <Redirect to='/groups' />;
    }
    return (
        <div>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <div>
                <div className={styles.container}>
                    <ProblemLoadingMsg
                        problemLoadingMsg={
                            !!errorGettingGroup ? 'Error getting group information' : ''
                        }>
                        {/* I know, I know, the following message is not really a problem,
                    but the component ProblemLoadingMsg works very well for this usage.*/}
                        <ProblemLoadingMsg
                            problemLoadingMsg={
                                !groupBasic || groupBasic?.allowJoin
                                    ? ''
                                    : 'You cannot request to join this group.'
                            }>
                            <ProblemLoadingMsg
                                problemLoadingMsg={
                                    !!errorCheckingJoinRequest ? 'Error checking request' : ''
                                }>
                                <IsLoadingIndicator
                                    isLoading={isCheckingRequestStatus}
                                    msg='Checking request status...'
                                />
                                {hasCheckedRequestStatus && hasAlreadyRequested && (
                                    <ProblemLoadingMsg problemLoadingMsg='Request to join this group is currently under review' />
                                )}
                                {hasCheckedRequestStatus && !hasAlreadyRequested && (
                                    <div>
                                        <span className={mergeStyles(globalStyles.mediumLargeFont)}>
                                            Join This Group
                                        </span>
                                        <Spacer marginTop={20} />
                                        {pickSponsor()}
                                        {justificationInputField()}
                                        <Spacer marginTop={20} />
                                        {!!requestErrMsg && requestErrMsgBar()}
                                        {!!successMsg && successMsgBar()}
                                        <Spacer marginTop={20} />
                                        <HorizontalBar
                                            styles={{
                                                justifyContent: 'space-between',
                                            }}>
                                            <DefaultButton
                                                iconProps={{ iconName: '' }}
                                                onClick={(): void => {
                                                    initSponsor();
                                                    initJustification();
                                                }}>
                                                Cancel
                                            </DefaultButton>

                                            <PrimaryButton
                                                iconProps={{ iconName: IconNames.Edit }}
                                                disabled={!isSubmitEnabled()}
                                                onClick={onSubmit}>
                                                Request to Join
                                            </PrimaryButton>
                                        </HorizontalBar>
                                    </div>
                                )}
                            </ProblemLoadingMsg>
                        </ProblemLoadingMsg>
                    </ProblemLoadingMsg>
                </div>
            </div>
        </div>
    );
}

const styles = mergeStyleSets({
    container: {
        width: 500,
        marginTop: 20,
        marginLeft: 8,
    },
    justificationLength: {
        width: '100%',
        textAlign: 'right',
        marginRight: 1,
    },
});
