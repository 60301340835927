import React from 'react';
import { TableCell } from 'components/common/table';
import { IColumn, Stack } from '@fluentui/react';
import { xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { GroupRuleType, IGroupRule } from 'clients/group-client';
import DeletePolicyRuleModalButton from 'components/groups/manage-group/policy/buttons/delete-policy-rule-modal-action-button';
import CreateEditPolicyRuleModalActionButton from 'components/groups/manage-group/policy/buttons/create-policy-rule/create-edit-policy-rule-modal-action-button';
import { globalStyles } from 'assets/styles/global-styles';

interface IPolicyRulesTableColumnsParams {
    deleteRuleFromList: (ruleId: string) => void;
    isAdmin: boolean;
    isManager: boolean;
    isAuditor: boolean;
    handleOpenViewRuleModal: (rule: IGroupRule) => void;
    updateListAfterEdit?: (rule: IGroupRule) => void;
    updatePolicyRulesTableAfterEdit?: () => void;
    basicRules: IGroupRule[];
}

function policyRulesTableColumns(params: IPolicyRulesTableColumnsParams): IColumn[] {
    const columnWidths = {
        name: 380,
        actions: 150,
    };

    enum ColumnKeys {
        Name = 'Name',
        Actions = 'Actions',
    }

    const editAndDeleteButtons = (row: IGroupRule): JSX.Element => (
        <Stack horizontal>
            <Stack.Item>
                {params.basicRules && (
                    <CreateEditPolicyRuleModalActionButton
                        updateListAfterEdit={params.updateListAfterEdit}
                        isEditing={true}
                        ruleData={row}
                        rules={params.basicRules}
                        updatePolicyRulesTableAfterEdit={params.updatePolicyRulesTableAfterEdit}
                    />
                )}
            </Stack.Item>
            <Stack.Item>
                <DeletePolicyRuleModalButton
                    deleteRuleFromList={params.deleteRuleFromList}
                    ruleName={row.name}
                    ruleId={row.id}
                    updatePolicyRulesTableAfterEdit={params.updatePolicyRulesTableAfterEdit}
                />
            </Stack.Item>
        </Stack>
    );

    const findPrettyName = (name: string): string => {
        const prettyName = params.basicRules.find((rule) => {
            return rule.type === name;
        });
        if (prettyName) {
            return prettyName.name;
        }

        return name;
    };

    const columns: IColumn[] = [
        {
            key: ColumnKeys.Name,
            name: ColumnKeys.Name,
            ariaLabel: ColumnKeys.Name,
            minWidth: columnWidths.name,
            maxWidth: columnWidths.name * xLargeMaxWidthCoeff,

            onRender: (row: IGroupRule): JSX.Element => (
                <TableCell>
                    {findPrettyName(row.type)}
                    <br />
                    <a
                        className={globalStyles.link}
                        onClick={(): void => params.handleOpenViewRuleModal(row)}>
                        {row.name}
                    </a>
                    <br />
                    {row.description}
                </TableCell>
            ),
        },
        {
            key: ColumnKeys.Actions,
            name: ColumnKeys.Actions,
            ariaLabel: ColumnKeys.Actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions * xLargeMaxWidthCoeff,

            onRender: (row: IGroupRule): JSX.Element => (
                <>
                    {(!params.isManager && !params.isAuditor) || params.isAdmin ? (
                        <TableCell>
                            {row.type !== GroupRuleType.GROUP_MEMBERSHIP_CHECK_RULE ? (
                                <>{editAndDeleteButtons(row)}</>
                            ) : (
                                params.isAdmin && <>{editAndDeleteButtons(row)}</>
                            )}
                        </TableCell>
                    ) : (
                        <></>
                    )}
                </>
            ),
        },
    ];
    return columns;
}
export default policyRulesTableColumns;
