import React from 'react';
import Badge from 'components/common/badge';
import { IEquipmentRecord, EquipmentTypes, Regions, Status } from 'clients/facilities-client';
import { BadgeColorHex } from 'assets/constants/global-colors';
import { separateWordsByCapitalLetter } from 'utils/string-utils';
import { nameof } from 'utils/object-utils';

export const reviewEquipmentStatusBadge = (
    equipmentResult: IEquipmentRecord | undefined,
): JSX.Element => (
    <Badge
        text={separateWordsByCapitalLetter(equipmentResult?.status ?? '').toLocaleUpperCase()}
        backgroundColor={getEquipmentStatusBackground(equipmentResult?.status)}
    />
);

function getEquipmentStatusBackground(equipmentStatus: string | undefined): string {
    if (equipmentStatus) {
        switch (equipmentStatus) {
            case Status.CheckedIn:
            case Status.Active:
            case Status.Returned:
                return BadgeColorHex.GREEN;
            case Status.CheckedOut:
            case Status.Stolen:
            case Status.Lost:
                return BadgeColorHex.RED;
            case Status.NotApplicable:
                return BadgeColorHex.BLUE;
            case Status.Inactive:
                return BadgeColorHex.GRAY;
        }
    }
    return BadgeColorHex.GRAY;
}

export function getEquipmentTypeDisplayText(equipmentType: string): string {
    if (equipmentType) {
        switch (equipmentType) {
            case nameof<typeof EquipmentTypes>('CAC'):
                return EquipmentTypes.CAC;
            case nameof<typeof EquipmentTypes>('SIPR'):
                return EquipmentTypes.SIPR;
            case nameof<typeof EquipmentTypes>('RSTARME'):
                return EquipmentTypes.RSTARME;
            case nameof<typeof EquipmentTypes>('FacilityBadge'):
                return EquipmentTypes.FacilityBadge;
            case nameof<typeof EquipmentTypes>('AccessFob'):
                return EquipmentTypes.AccessFob;
            case nameof<typeof EquipmentTypes>('ESTARME'):
                return EquipmentTypes.ESTARME;
            case nameof<typeof EquipmentTypes>('ICBadge'):
                return EquipmentTypes.ICBadge;
            case nameof<typeof EquipmentTypes>('Laptop'):
                return EquipmentTypes.Laptop;
            case nameof<typeof EquipmentTypes>('PIV'):
                return EquipmentTypes.PIV;
            case nameof<typeof EquipmentTypes>('RSA'):
                return EquipmentTypes.RSA;
            case nameof<typeof EquipmentTypes>('Yubikey'):
                return EquipmentTypes.Yubikey;
        }
    }
    return equipmentType;
}

export function getEquipmentRegionDisplayText(region: string): string {
    if (region) {
        switch (region) {
            case nameof<typeof Regions>('West'):
                return Regions.West;
            case nameof<typeof Regions>('West_Redmond'):
                return Regions.West_Redmond;
            case nameof<typeof Regions>('East'):
                return Regions.East;
            case nameof<typeof Regions>('East_Reston_Elkridge'):
                return Regions.East_Reston_Elkridge;
            case nameof<typeof Regions>('South_Atlanta'):
                return Regions.South_Atlanta;
            case nameof<typeof Regions>('Other'):
                return Regions.Other;
        }
    }
    return region;
}
