import { Dropdown, IDropdownOption, mergeStyles, Stack, TextField } from '@fluentui/react';
import { inputFieldErrorColor } from 'assets/constants/global-colors';
import { IconNames } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import GroupClient, {
    GroupRole,
    GroupRuleType,
    IGroup,
    IGroupMembership,
} from 'clients/group-client';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { CoreSinglePrincipalRecordPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import { ModalSizeType } from 'components/common/modal';
import Spacer from 'components/common/spacer';
import DisplayViolationCheck from 'components/groups/groups-common-components/display-violation-record';
import { ModalAddEditType } from 'components/groups/groups-utils';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { toTitleCase } from 'utils/string-utils';

export interface IAddEditRoleModalActionButtonProps {
    enableOwnerDemote: boolean;
    mode: ModalAddEditType;
    group: IGroup;
    // The prop member is only driven during "update" mode.
    member?: IGroupMembership;
    canTouchRoleOrApp: boolean;
    onRoleAddOrUpdate: (role: IGroupMembership, mode: ModalAddEditType) => void;
}

const dropdownOption = (role: GroupRole, enableOwnerDemote: boolean): IDropdownOption => {
    return {
        key: role,
        text: toTitleCase(role),
        // If demoting is not allowed, only enable the option "OWNER".
        disabled: enableOwnerDemote ? false : role !== GroupRole.OWNER,
    };
};

const justificationMaxLen = 500;

type PolicyViolationType = {
    isChecking?: boolean;
    violations?: string[];
    errorMsg?: string;
};

export default function AddEditRoleModalActionButton(
    props: IAddEditRoleModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const [principal, setPrincipal] = useState<IPrincipalRecord>();
    const [selectedRole, setSelectedRole] = useState<IDropdownOption>();
    const [justification, setJustification] = useState<string>('');
    const [violationCheck, setViolationCheck] = useState<PolicyViolationType>({});
    const [isDisable, setIsDisable] = useState(false);
    const [member, setMember] = useState<IGroupMembership | undefined>();

    const isAddMode = props.mode === ModalAddEditType.add;

    const isSubmitEnabled = useMemo(() => {
        const isJustificationOk = props.group.requireJustification ? !!justification : true;
        if (isAddMode) {
            const isEnable1 = !!principal && !!selectedRole;
            return isEnable1 && isJustificationOk;
        } else if (member) {
            const isEnable1 = !!member;
            const isRoleModified = (selectedRole?.key as GroupRole) !== member.role;
            const isJustificationModified = justification !== member.justification;
            return isEnable1 && isJustificationOk && (isRoleModified || isJustificationModified);
        }
        return false;
    }, [
        props.group.requireJustification,
        member,
        justification,
        isAddMode,
        principal,
        selectedRole,
    ]);

    const dropdownOptions = useMemo(() => {
        const isEnableOwnerDemote =
            props.mode === ModalAddEditType.add ||
            props.enableOwnerDemote ||
            member?.role !== GroupRole.OWNER;
        return [GroupRole.OWNER, GroupRole.MANAGER, GroupRole.AUDITOR].map((role) => {
            return dropdownOption(role, isEnableOwnerDemote);
        });
    }, [props.enableOwnerDemote, props.mode, member]);

    const onButtonClick = (): void => {
        setPrincipal(undefined);
        switch (props.mode) {
            case ModalAddEditType.add:
                setSelectedRole(undefined);
                setJustification('');
                break;
            case ModalAddEditType.update:
                // On update mode, props.member is driven by parent. The following
                // type cast is therefore safe.
                if (props.member) {
                    setIsDisable(true);
                    GroupClient.searchGroupMembers(
                        authContext,
                        props.member.groupId,
                        props.member.personnelId,
                    )
                        .then((result) => {
                            setMember(result);
                            setSelectedRole(
                                dropdownOption(result?.role as GroupRole, props.enableOwnerDemote),
                            );
                            setJustification(result?.justification as string);
                            setIsDisable(false);
                        })
                        .catch((e) => {
                            console.error(e);
                            throw 'Error: Failed to retrieve group member';
                        });
                }
                break;
        }
        setViolationCheck({});
    };

    const onCandidateSelectedHandler = (info?: IPrincipalRecord): void => {
        setPrincipal(info);
    };

    useEffect(() => {
        const fetchPolicyViolation = async (pId: string, groupId: string): Promise<void> => {
            setViolationCheck({});
            if (!pId) {
                return;
            }
            try {
                setViolationCheck({ isChecking: true });
                const policyViolationVar = await GroupClient.getPolicyViolation(
                    authContext,
                    groupId,
                    pId,
                );
                const filteredPolicyViolation = policyViolationVar.failed
                    .filter(
                        (violation) => violation.ruleType !== GroupRuleType.STAND_DOWN_CHECK_RULE,
                    )
                    .map((x) => x.errorMessage);
                setViolationCheck({
                    violations: filteredPolicyViolation,
                });
            } catch (e) {
                setViolationCheck({ errorMsg: 'Error: Failed to retrieve policy violation' });
            }
        };

        if (principal && props.group.id) {
            fetchPolicyViolation(principal.id, props.group.id);
        }
    }, [authContext, principal, props.group]);

    const onSubmit = async (): Promise<IGroupMembership> => {
        if (!selectedRole?.key) {
            // modal action button will catch the error and display it.
            throw 'Role is not selected';
        }
        if (props.group.requireJustification && !justification) {
            throw 'Justification must be entered for this group';
        }
        if (isAddMode) {
            if (!principal?.id) {
                throw 'Employee is not selected';
            }
            return GroupClient.addGroupMember(authContext, {
                groupId: props.group.id,
                // The following type cast is safe because the dropdown
                // options are all GroupRole.
                role: selectedRole.key as GroupRole,
                justification,
                sponsorId: '',
                personnelId: principal.id,
            });
        } else if (member) {
            return GroupClient.updateGroupMember(authContext, {
                groupId: props.group.id,
                // The following type cast is safe because the dropdown
                // options are all GroupRole.
                role: selectedRole.key as GroupRole,
                justification,
                // The following type cast is safe because if props.member was
                // undefined, submit button would've been disabled and the
                // code would never reach here.
                // Besides, for update mode it's the parent that determines value
                // of this prop, not the user.
                personnelId: (props.member as IGroupMembership).personnelId,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                _etag: member._etag,
            });
        }
        return Promise.reject('Not in correct state to submit.');
    };

    const onRoleSelect = (a: React.FormEvent<HTMLDivElement>, role?: IDropdownOption): void => {
        if (a.type === 'click') {
            setSelectedRole(role);
        }
    };

    const onJustificationInput = (a?: React.FormEvent<HTMLElement>, str?: string): void => {
        if (a?.type === 'input' && (!str || str?.length <= justificationMaxLen)) {
            setJustification(str ?? '');
        }
    };

    const onModalConcluded = async (
        conclusion: ModalConclusion,
        result?: IGroupMembership,
    ): Promise<void> => {
        if (conclusion === ModalConclusion.Done && !!result) {
            props.onRoleAddOrUpdate(result, props.mode);
        }
    };

    return (
        <ModalActionButton<IGroupMembership>
            text={isAddMode ? 'Add Role' : 'Edit'}
            size={ModalSizeType.mediumLarge}
            fixWidth={true}
            enable={!!props.group.id && !!props.group.name && props.canTouchRoleOrApp}
            iconName={isAddMode ? IconNames.PeopleAdd : IconNames.Edit}
            modalTitle={isAddMode ? 'Add Role' : 'Edit Role'}
            modalSubtitle={props.group.name}
            enableSubmit={isSubmitEnabled}
            submitButtonText={isAddMode ? 'Add' : 'Update'}
            onSubmit={onSubmit}
            onButtonClick={onButtonClick}
            onModalConcluded={onModalConcluded}>
            {isAddMode && (
                <>
                    <CoreSinglePrincipalRecordPickerTypeaheadSearch
                        required
                        label='Employee'
                        placeHolder='Employee Name or Alias'
                        selectedItem={principal}
                        onChange={(item): void => {
                            onCandidateSelectedHandler(item);
                        }}
                    />
                    <Spacer marginTop={10} />
                    {<DisplayViolationCheck violationCheck={violationCheck} />}
                    <Spacer marginTop={5} />
                </>
            )}
            <Dropdown
                required
                label='Role'
                placeholder='Select role'
                styles={{ dropdownItems: globalStyles.dropdownItems }}
                selectedKey={selectedRole?.key}
                onChange={onRoleSelect}
                options={dropdownOptions}
                disabled={isDisable}
            />
            <Spacer marginTop={10} />
            <TextField
                disabled={isDisable}
                onRenderLabel={(): JSX.Element => {
                    return (
                        <Stack horizontal horizontalAlign='space-between'>
                            <span>
                                <span>
                                    Provide a business justification for this group role addition
                                </span>
                                <span>&nbsp;</span>
                                {props.group.requireJustification && (
                                    <span className={mergeStyles({ color: inputFieldErrorColor })}>
                                        *&nbsp;
                                    </span>
                                )}
                            </span>
                            <span className={justificationLengthStyle}>
                                {justification?.length ?? 0}/{justificationMaxLen}
                            </span>
                        </Stack>
                    );
                }}
                rows={5}
                maxLength={justificationMaxLen}
                multiline
                resizable={false}
                value={justification}
                onChange={onJustificationInput}
                ariaLabel='Business Justification'
            />
        </ModalActionButton>
    );
}

const justificationLengthStyle = mergeStyles({
    width: '55px',
    textAlign: 'right',
    marginRight: 1,
});
