import { IconNames } from 'assets/constants/global-constants';
import GroupClient, { IApplicationGroupMembership, IGroup } from 'clients/group-client';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext } from 'react';

interface IDeleteAppModalActionButtonProps {
    app: IApplicationGroupMembership;
    group: IGroup;
    onAppDeleted: (app: IApplicationGroupMembership) => void;
}

export default function DeleteAppModalActionButton(props: IDeleteAppModalActionButtonProps) {
    const authContext = useContext(AuthContext);

    const onHandleDelete = async (): Promise<void> => {
        try {
            return await GroupClient.deleteApplicationGroupMembership(
                authContext,
                props.app.groupId,
                props.app.applicationId,
            );
        } catch (e) {
            console.error(e);
            throw 'Error deleting app from group';
        }
    };

    const onDeleteAppConcluded = (conclusion: ModalConclusion): void => {
        if (conclusion === ModalConclusion.Done) {
            props.onAppDeleted(props.app);
        }
    };

    return (
        <ModalActionButton<void>
            text={'Delete'}
            iconName={IconNames.Delete}
            modalTitle={'Delete'}
            modalTitleIcon={IconNames.Delete}
            enableSubmit={true}
            submitButtonText={'Delete'}
            submitButtonIcon={IconNames.Delete}
            onSubmit={onHandleDelete}
            onModalConcluded={onDeleteAppConcluded}>
            Are you sure you want to remove the app <strong>{props.app.applicationName}</strong>{' '}
            from the group <strong>{props.group.name}</strong>?
        </ModalActionButton>
    );
}
