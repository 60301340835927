import { CloudStatus } from 'components/staffing/staffing-constants';
import { OrgTeamsObjectType } from 'components/staffing/staffing-page-types';
import { IStaffingClearanceRecordResponse } from 'clients/staffing-client';

export abstract class AllocationFilter {
    statusView: CloudStatus;

    constructor(statusView: CloudStatus) {
        this.statusView = statusView;
    }

    abstract filter(
        allocations: IStaffingClearanceRecordResponse[],
        orgTeamData: OrgTeamsObjectType,
    ): IStaffingClearanceRecordResponse[];

    filterStatuses(
        allocations: IStaffingClearanceRecordResponse[],
    ): IStaffingClearanceRecordResponse[] {
        return allocations.map((a) => {
            return {
                ...a,
                // The following assumes each allocation specifies both clouds RX and EX.
                // One way to ensure that is that if the server has not sent status for
                // one cloud, the code has injected a NoStatus in its place before using
                // this class.
                cloudStatuses: a.cloudStatuses.filter((c) => c.name === this.statusView),
            };
        });
    }
}
