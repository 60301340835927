import {
    DetailsList,
    SelectionMode,
    DetailsRow,
    IDetailsRowProps,
    MessageBar,
    MessageBarType,
    ActionButton,
    Stack,
    mergeStyles,
    StackItem,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import FormsClient, {
    FormState,
    GenericFormRecord,
    GenericFormRecordQueryRequest,
    GenericFormRecordQueryResults,
} from 'clients/forms-client';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import useEmployeeBasicRecordsByAlias from 'components/common/employee/use-employee-basic-records-by-alias';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import { renderDetailsHeaderWithMultiLine, TableCell } from 'components/common/table';
import { AuthContext } from 'contexts/auth-context';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { returnEmailUserName } from 'utils/string-utils';
import config from 'environments/environment';
import {
    dateToFormattedDateTimeString,
    dateToLocalShortDateFormat,
    DAY_IN_MILLISECONDS,
    secondsToMilliseconds,
    TimeFormats,
} from 'utils/time-utils';
import { FormsFilter } from 'components/forms/forms-filter';
import { Link } from 'react-router-dom';
import { createBadge } from 'components/forms/forms-common';
import { useFetchSimple } from 'utils/misc-hooks';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import Spacer from 'components/common/spacer';
import { AbortError } from 'components/common/constants';
import { globalStyles } from 'assets/styles/global-styles';

const managePageQueryableStates: string[] = [
    FormState.submitted,
    FormState.accepted,
    FormState.completed,
    FormState.rejected,
    FormState.underReview,
];
type ExcelFormRecordType = {
    actionUrl: string;
    formType: string;
    label: string;
    reviewStatusChangedBy: string;
    reviewStatusChangeAtUTC: string;
    submittedBy: string;
    submittedOn: string;
    status: string;
    statusChangedOn: string;
    statusChangedBy: string;
    // form Record Creator info
    personnelId: string;
    lastName: string;
    firstName: string;
    middleName: string;
    email: string;
    employeeTitle: string;
    organization: string;
    geographicLocation: string;
    officeLocation: string;
    reportTo: string;
    hierarchyLevel1: string;
    hierarchyLevel2: string;
    hierarchyLevel3: string;
    hierarchyLevel4: string;
    hierarchyLevel5: string;
    hierarchyLevel6: string;
};
export interface IFormsFilterCriteria {
    submittedFrom?: Date;
    submittedTo?: Date;
    createdBy?: string;
    lastModifiedBy?: string;
    title?: string;
    reviewStatus?: Set<string>;
}

const pageTitleStyle = mergeStyles(globalStyles.boldFont, globalStyles.mediumLargeFont, {
    marginLeft: '9px',
});

const verticalCenterStyle = mergeStyles({
    marginTop: 'auto',
    marginBottom: 'auto',
});

export function FormsManageSearch(): JSX.Element {
    const authContext = useContext(AuthContext);
    const [displayedFormSubmissions, setDisplayedFormSubmissions] = useState<GenericFormRecord[]>(
        [],
    );
    const [filterCriteria, setFilterCriteria] = useState<IFormsFilterCriteria>();
    const [errorLoadingFormItemsMessage, setErrorLoadingFormItemsMessage] = useState<string>('');
    const [shouldFetchFormRecords, setShouldFetchFormRecords] = useState<boolean>(false);
    const [continuationToken, setContinuationToken] = useState<string>('');
    const [hasLoadedAll, setHasLoadedAll] = useState<boolean>(false);
    const [isExcelLoading, setIsExcelLoading] = useState<boolean>(true);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const abortController = useRef<undefined | AbortController>();

    const fetchCount = useRef(0);

    useEffect(() => {
        if (fetchCount.current > 0) {
            // already fetching, abort current and allow new one to proceed
            abortController.current?.abort();
            abortController.current = new AbortController();
        }

        if (!isCriteriaUndefinedOrEmpty()) {
            resetListAndReload();
        }
    }, [filterCriteria]);

    const employeesToFetch = useMemo(
        () =>
            displayedFormSubmissions.flatMap((item) => [
                returnEmailUserName(item.createdBy),
                returnEmailUserName(item.lastModifiedBy),
            ]),
        [displayedFormSubmissions],
    );

    const { basicEmployeesMap, employeesPictureMap } = useEmployeeBasicRecordsByAlias(
        employeesToFetch,
    );

    const isCriteriaUndefinedOrEmpty = (): boolean => {
        return filterCriteria === undefined || Object.keys(filterCriteria).length < 1;
    };

    const resetListAndReload = useCallback(() => {
        setContinuationToken('');
        setDisplayedFormSubmissions([]);
        setHasLoadedAll(false);
        setShouldFetchFormRecords(true);
    }, []);

    const onClearFilters = (): void => {
        setFilterCriteria({});
        resetListAndReload();
    };

    async function getExcelRecords(): Promise<ExcelFormRecordType[]> {
        if (!displayedFormSubmissions) {
            return [];
        } else if (displayedFormSubmissions.length === 0) {
            // provide template excel document when initially no equipment exists
            return [
                {
                    personnelId: '',
                    submittedBy: '',
                    lastName: '',
                    firstName: '',
                    middleName: '',
                    email: '',
                    employeeTitle: '',
                    organization: '',
                    geographicLocation: '',
                    officeLocation: '',
                    reportTo: '',
                    hierarchyLevel1: '',
                    hierarchyLevel2: '',
                    hierarchyLevel3: '',
                    hierarchyLevel4: '',
                    hierarchyLevel5: '',
                    hierarchyLevel6: '',
                    status: '',
                    formType: '',
                    label: '',
                    submittedOn: '',
                    statusChangedOn: '',
                    statusChangedBy: '',
                    actionUrl: '',
                    reviewStatusChangedBy: '',
                    reviewStatusChangeAtUTC: '',
                },
            ];
        }

        const isNotWithinYear =
            filterCriteria &&
            filterCriteria.submittedFrom &&
            filterCriteria.submittedTo &&
            (filterCriteria.submittedTo.getTime() - filterCriteria.submittedFrom.getTime()) /
                DAY_IN_MILLISECONDS >
                365;
        if (
            filterCriteria?.submittedFrom === undefined ||
            filterCriteria?.submittedTo === undefined ||
            isNotWithinYear
        ) {
            setErrorLoadingFormItemsMessage(
                'Need to set Submitted From and To range to within 1 year',
            );
            return [];
        } else if (filterCriteria.title === undefined) {
            setErrorLoadingFormItemsMessage('Need to set Form Type filter');
            return [];
        } else {
            setIsExcelLoading(false);
            try {
                const exportToExcel = await FormsClient.ExportFormReportToExcel(
                    authContext,
                    createSearchRequest(),
                );
                setErrorLoadingFormItemsMessage('');
                setIsExcelLoading(true);
                return exportToExcel.map((x) => {
                    const actionUrl = `${config.aadConfig.baseUri}forms/review/${x.formName}/${x.id}`;
                    return {
                        // form creator info section
                        personnelId: x.personnelId,
                        submittedBy: x.submittedBy,
                        lastName: x.lastName,
                        firstName: x.firstName,
                        middleName: x.middleName ?? '',
                        email: x.email,
                        employeeTitle: x.employeeTitle,
                        organization: x.organization,
                        geographicLocation: x.geographicLocation,
                        officeLocation: x.officeLocation,
                        reportTo: x.reportTo,
                        hierarchyLevel1: x.hierarchyLevel1,
                        hierarchyLevel2: x.hierarchyLevel2,
                        hierarchyLevel3: x.hierarchyLevel3,
                        hierarchyLevel4: x.hierarchyLevel4,
                        hierarchyLevel5: x.hierarchyLevel5,
                        hierarchyLevel6: x.hierarchyLevel6,
                        // End of form creator info section
                        status: x.status,
                        formType: x.formType,
                        label: x.label,
                        submittedOn: dateToFormattedDateTimeString(x.submittedOn * 1000),
                        statusChangedOn: dateToFormattedDateTimeString(x.statusChangedOn * 1000),
                        statusChangedBy: x.statusChangedBy,
                        actionUrl: actionUrl,
                        reviewStatusChangedBy: x.reviewStatusChangedBy,
                        reviewStatusChangeAtUTC: dateToFormattedDateTimeString(
                            x.reviewStatusChangeAtUTC * 1000,
                        ),
                    };
                });
            } catch (error) {
                console.error(
                    'Failed to retrieve excel document for Forms',
                    error.message,
                    error.status,
                );
                setErrorLoadingFormItemsMessage(
                    `Failed to retrieve excel document for Forms. ${error.message} - ${error.statis}`,
                );
                setIsExcelLoading(true);
            }
            return [];
        }
    }

    const createReviewButton = (item: GenericFormRecord): JSX.Element => {
        return (
            <Link to={`/forms/review/${item.name}/${item.id}`}>
                <ActionButton iconProps={{ iconName: IconNames.ComplianceAudit }}>
                    Review
                </ActionButton>
            </Link>
        );
    };

    const createDateTimeString = (date: number): string => {
        const dateStr = dateToLocalShortDateFormat(secondsToMilliseconds(date) ?? 0); // "Jan 18, 2023"
        const timeStr = new Date(secondsToMilliseconds(date) ?? 0).toLocaleTimeString(); // "2:42:04 PM"
        return `${dateStr} ${timeStr}`;
    };

    const minColumnWidths = {
        status: 60,
        title: 130,
        label: 100,
        submittedBy: 100,
        submittedOn: 120,
        statusChangedBy: 90,
        statusChangedOn: 120,
        actions: 80,
    };

    const maxColumnWidths = {
        status: 120,
        title: 160,
        label: 140,
        submittedBy: 230,
        submittedOn: 160,
        statusChangedBy: 230,
        statusChangedOn: 160,
        actions: 100,
    };

    const columns = useMemo(
        () => [
            {
                key: 'number',
                name: '#',
                fieldName: 'number',
                minWidth: minColumnWidths.status,
                maxWidth: 32,
            },
            {
                key: 'status',
                name: 'Status',
                fieldName: 'Status',
                minWidth: minColumnWidths.status,
                maxWidth: maxColumnWidths.status,
                onRender: (item: GenericFormRecord) => createBadge(item.formState),
            },
            {
                key: 'title',
                name: 'Form type',
                fieldName: 'title',
                minWidth: minColumnWidths.title,
                maxWidth: maxColumnWidths.title,
                onRender: (item: GenericFormRecord): JSX.Element => {
                    return <TableCell toolTipText={item.title}>{item.title}</TableCell>;
                },
            },
            {
                key: 'label',
                name: 'Form unique name',
                fieldName: 'label',
                minWidth: minColumnWidths.label,
                maxWidth: maxColumnWidths.label,
                onRender: (item: GenericFormRecord): JSX.Element => {
                    return <>{item.label}</>;
                },
            },
            {
                key: 'submittedBy',
                name: 'Submitted by',
                fieldName: 'submittedBy',
                minWidth: minColumnWidths.submittedBy,
                maxWidth: maxColumnWidths.submittedBy,
                onRender: (item: GenericFormRecord): JSX.Element => {
                    const createdBy = returnEmailUserName(item.createdBy).toLowerCase();
                    const employeeBasicData = basicEmployeesMap.get(createdBy);
                    const base64ImageStr = employeesPictureMap.get(createdBy) ?? undefined;
                    return (
                        <div style={{ marginTop: '5px' }}>
                            <EmployeeBasicHoverCard
                                employeeBasicData={employeeBasicData}
                                base64ImageString={base64ImageStr}
                                defaultDisplayName={createdBy}
                                personnelId={
                                    employeeBasicData === undefined ? item.personnelId : ''
                                }
                            />
                        </div>
                    );
                },
            },
            {
                key: 'submittedOn',
                name: 'Submitted on',
                fieldName: 'submittedOn',
                minWidth: minColumnWidths.submittedOn,
                maxWidth: maxColumnWidths.submittedOn,
                onRender: (item: GenericFormRecord): JSX.Element => {
                    const value = createDateTimeString(item['submittedAtUTC']);
                    return <TableCell toolTipText={value}>{value}</TableCell>;
                },
            },
            {
                key: 'StatusChangedBy',
                name: 'Status changed by',
                fieldName: 'statusChangedBy',
                minWidth: minColumnWidths.statusChangedBy,
                maxWidth: maxColumnWidths.statusChangedBy,
                onRender: (item: GenericFormRecord): JSX.Element => {
                    const lastModifiedBy = returnEmailUserName(item.lastModifiedBy).toLowerCase();
                    return (
                        <div style={{ marginTop: '5px' }}>
                            <EmployeeBasicHoverCard
                                employeeBasicData={basicEmployeesMap.get(lastModifiedBy)}
                                base64ImageString={employeesPictureMap.get(lastModifiedBy) ?? ' '}
                                defaultDisplayName={lastModifiedBy}
                            />
                        </div>
                    );
                },
            },
            {
                key: 'statusChangedOn',
                name: 'Status changed on',
                fieldName: 'statusChangedOn',
                minWidth: minColumnWidths.statusChangedOn,
                maxWidth: maxColumnWidths.statusChangedOn,
                onRender: (item: GenericFormRecord): JSX.Element => {
                    if (item['reviewStatusChangeAtUTC'] === undefined) {
                        return <></>;
                    } else {
                        const value = createDateTimeString(item['reviewStatusChangeAtUTC']);
                        return <TableCell toolTipText={value}>{value}</TableCell>;
                    }
                },
            },
            {
                key: 'actions',
                name: 'Actions',
                fieldName: 'actions',
                minWidth: minColumnWidths.actions,
                maxWidth: maxColumnWidths.actions,
                onRender: (item: GenericFormRecord): JSX.Element => createReviewButton(item),
            },
        ],
        [basicEmployeesMap, employeesPictureMap],
    );

    useEffect(() => {
        setShouldFetchFormRecords(true);
    }, []);

    const onScrollReachedBottom = useCallback(() => {
        setShouldFetchFormRecords(true);
    }, [setShouldFetchFormRecords]);

    const customRenderRow = (props?: IDetailsRowProps): JSX.Element => {
        return props ? (
            <DetailsRow
                {...props}
                styles={{
                    fields: {
                        alignItems: 'center',
                        color: '#323130',
                        fontSize: '14px',
                        fontWeight: 400,
                    },
                }}
            />
        ) : (
            <></>
        );
    };

    const createSearchRequest = (): GenericFormRecordQueryRequest => {
        const request: GenericFormRecordQueryRequest = {
            formStates: managePageQueryableStates,
        };
        // for this page we don't need schema data field
        request.noSchema = true;

        if (filterCriteria?.submittedFrom) {
            request.submittedFrom = filterCriteria?.submittedFrom?.toISOString();
        }
        if (filterCriteria?.submittedTo) {
            request.submittedTo = filterCriteria?.submittedTo?.toISOString();
        }
        if (filterCriteria?.createdBy) {
            request.createdBy = filterCriteria?.createdBy;
        }
        if (filterCriteria?.lastModifiedBy) {
            request.lastModifiedBy = filterCriteria?.lastModifiedBy;
        }
        if (filterCriteria?.title) {
            request.title = filterCriteria.title;
        }
        if (filterCriteria?.reviewStatus) {
            request.formStates = [...filterCriteria.reviewStatus];
        }

        return request;
    };

    useFetchSimple<GenericFormRecordQueryResults>({
        dependencies: [shouldFetchFormRecords, filterCriteria],
        canPerformFetch: shouldFetchFormRecords,
        fetchFunc: (): Promise<GenericFormRecordQueryResults> => {
            fetchCount.current += 1;
            const searchRequest = createSearchRequest();
            return FormsClient.QueryGenericFormRecords(
                authContext,
                searchRequest,
                continuationToken,
                abortController.current?.signal,
            );
        },
        onSuccess: (response: GenericFormRecordQueryResults) => {
            setShouldFetchFormRecords(false);
            if (response && response.results.length > 0) {
                if (!response.continuationToken) {
                    setHasLoadedAll(true);
                }
                setContinuationToken(response.continuationToken);

                const rowNumberStart = displayedFormSubmissions
                    ? displayedFormSubmissions.length + 1
                    : 1;
                const mapped = response.results.map((item, index) => {
                    return { ...item, number: index + rowNumberStart };
                });
                setDisplayedFormSubmissions((curr) => {
                    return [...curr, ...mapped];
                });
            } else {
                setDisplayedFormSubmissions([]);
            }
        },
        onError: (error): void => {
            if (error.name !== AbortError) {
                setErrorLoadingFormItemsMessage('An error occurred loading items');
                console.error(error);
            }
        },
        onFinally: () => {
            fetchCount.current -= 1;
        },
    });

    const onScroll = (ev: React.UIEvent<HTMLElement>): void => {
        const target = ev.target as HTMLInputElement;
        if (!hasLoadedAll && target.scrollHeight - target.scrollTop <= 1.05 * target.clientHeight) {
            onScrollReachedBottom();
        }
    };

    return (
        <>
            <Stack horizontal>
                <StackItem grow={100}>
                    <h2 className={pageTitleStyle}>Manage submitted forms</h2>
                </StackItem>
                <Stack.Item className={verticalCenterStyle}>
                    <ActionButton
                        iconProps={{ iconName: IconNames.Filter }}
                        onClick={() => setShowFilter(!showFilter)}>
                        {showFilter ? 'Hide Filters' : 'Show Filters'}
                    </ActionButton>
                </Stack.Item>
                <Stack.Item className={verticalCenterStyle}>
                    <ExportToExcelButton<ExcelFormRecordType>
                        getData={async () => getExcelRecords()}
                        buttonTitle='Download report'
                        fileNamePrefix={`forms-report`}
                        fileNameTimeFormat={TimeFormats.MM_DD_YYYY_HHmm}
                        formatHeader={true}
                        hidden={!isExcelLoading}
                    />
                    {/* this is so that it is hidden but still generates the excel */}
                    {!isExcelLoading && (
                        <IsLoadingIndicator isLoading={!isExcelLoading} msg='Loading Report...' />
                    )}
                </Stack.Item>
            </Stack>
            {errorLoadingFormItemsMessage !== '' && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    onDismiss={(): void => setErrorLoadingFormItemsMessage('')}
                    dismissButtonAriaLabel='Close'>
                    {errorLoadingFormItemsMessage}
                </MessageBar>
            )}
            <SidebarAndContents>
                <SidebarPane
                    sidebarPaneOuterStyle={{
                        width: '240px',
                        display: showFilter ? 'block' : 'none',
                    }}>
                    <FormsFilter
                        filterCriteria={filterCriteria}
                        setFilterCriteria={setFilterCriteria}
                        availableReviewStatuses={managePageQueryableStates}
                        onClearFilters={onClearFilters}
                    />
                </SidebarPane>
                <ContentPane
                    contentPaneOuterStyle={{ width: showFilter ? 'calc(100% - 260px)' : '100%' }}>
                    <div
                        onScroll={onScroll}
                        style={{
                            overflow: 'auto',
                            width: '100%',
                            height: '78vh',
                        }}>
                        <DetailsList
                            items={displayedFormSubmissions}
                            selectionMode={SelectionMode.none}
                            columns={columns}
                            onRenderRow={customRenderRow}
                            onRenderDetailsHeader={renderDetailsHeaderWithMultiLine()}
                        />
                        <IsLoadingIndicator
                            isLoading={fetchCount.current > 0}
                            before={<Spacer marginTop={20} />}
                            msg='Loading form submissions...'
                        />
                        {displayedFormSubmissions.length === 0 && fetchCount.current === 0 && (
                            <Stack horizontalAlign='center'>
                                <Spacer marginTop={20} />
                                <Stack>No form submissions to display</Stack>
                            </Stack>
                        )}
                    </div>
                </ContentPane>
            </SidebarAndContents>
        </>
    );
}
