import {
    ActionButton,
    DatePicker,
    DefaultButton,
    Dropdown,
    FluentTheme,
    FontIcon,
    IPersonaProps,
    Label,
    Panel,
    PanelType,
    PrimaryButton,
    mergeStyleSets,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IFilter } from 'components/core/common/filterableTable';
import { CoreSinglePrincipalPersonaPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import { datePlusDays, dateToDateStringFormat, localDateToUTCDate } from 'utils/time-utils';
import { AuthContext } from 'contexts/auth-context';
import { CoreAttributesClient } from 'clients/core/personnel-core-client-wrappers';

export interface IUserAssignmentsFilterPanelProps {
    isOpen?: boolean;
    onDismiss: () => void;
    onSuccess: (filters: Map<string, IFilter>) => void;
    onClearFilters: () => void;
    onMinimizePanel: () => void;
}

export default function UserAssignmentsFilterPanel(
    props: IUserAssignmentsFilterPanelProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [attributeSet, setAttributeSet] = useState<string>();
    const [attribute, setAttribute] = useState<string>();
    const [modifiedAfter, setModifiedAfter] = useState<Date | undefined>();
    const [modifiedBefore, setModifiedBefore] = useState<Date | undefined>();
    const [modifiedBy, setModifiedBy] = useState<IPersonaProps | undefined>();
    const [filters, setFilters] = useState<Map<string, IFilter>>(new Map<string, IFilter>());
    const [attributeSets, setAttributeSets] = useState<Map<string, string>>(
        new Map<string, string>(),
    );
    const [attributes, setAttributes] = useState<
        Map<string, { name: string; attributeSetId: string }>
    >(new Map<string, { name: string; attributeSetId: string }>());
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const buttonStyles = { root: { marginRight: 8 } };

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const attributesClient = new CoreAttributesClient(authContext);
                const visibleAttributes = await attributesClient.getVisible();

                if (visibleAttributes.length > 0) {
                    const sorteVisibleAttributes = visibleAttributes.sort((a, b) => {
                        if (
                            (a.attributeSetName ?? a.attributeSetId) <
                            (b.attributeSetName ?? b.attributeSetId)
                        )
                            return -1;
                        if (
                            (a.attributeSetName ?? a.attributeSetId) >
                            (b.attributeSetName ?? b.attributeSetId)
                        )
                            return 1;
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0;
                    });

                    const attributeSets = new Map(
                        sorteVisibleAttributes.map((a) => [
                            a.attributeSetId,
                            a.attributeSetName ?? a.attributeSetId,
                        ]),
                    );
                    setAttributeSets(attributeSets);

                    const attributes = new Map(
                        sorteVisibleAttributes.map((a) => [
                            a.id,
                            { name: a.name, attributeSetId: a.attributeSetId },
                        ]),
                    );

                    setAttributes(attributes);
                }
            } catch (e) {
                setShowErrors(true);
                setErrorMessage(
                    'An error occurred retrieving attributes or attribute sets for filtering. Please refresh and try again.',
                );
            }
        };
        fetchData();
    }, []);

    const onClick = (): void => {
        props.onSuccess(filters);
    };

    const onCancel = (): void => {
        clearFilters();
        props.onDismiss();
    };

    function updateFilter(columnName: string, filter: IFilter) {
        setFilters((filters) => {
            const newFilters = new Map<string, IFilter>(filters);
            newFilters.set(columnName, filter);
            return newFilters;
        });
    }

    function clearFilters(): void {
        setFilters(new Map<string, IFilter>());
        setAttributeSet('');
        setAttribute('');
        setModifiedAfter(undefined);
        setModifiedBefore(undefined);
        setModifiedBy(undefined);
        props.onClearFilters();
    }

    const onModifiedBySelected = (persona?: IPersonaProps | undefined): void => {
        if (persona) {
            const v = persona?.itemID;
            updateFilter('modifiedby', { values: [v ?? ''] });
            setModifiedBy(persona);
        } else {
            updateFilter('modifiedby', { values: [] });
            setModifiedBy(undefined);
        }
    };

    const attributeSetDropDownOptions = Array.from(attributeSets).map((a) => {
        return { key: a[0], text: a[1] };
    });

    const attributeDropDownOptions =
        attributeSet !== undefined
            ? Array.from(attributes)
                  .filter((a) => a[1].attributeSetId === attributeSet)
                  .map((a) => {
                      return { key: a[0], text: a[1].name };
                  })
            : Array.from(attributes).map((a) => {
                  return { key: a[0], text: a[1].name };
              });

    const onRenderFooterContent = React.useCallback(
        () => (
            <div>
                <PrimaryButton onClick={onClick} styles={buttonStyles}>
                    {'Apply'}
                </PrimaryButton>
                <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
            </div>
        ),
        [onCancel],
    );

    const createAttributeSetsDropdownElement = useCallback(() => {
        return (
            <Dropdown
                options={attributeSetDropDownOptions ?? []}
                placeholder='Filter by attribute set'
                onChange={(ev, newVal) => {
                    setAttributeSet(newVal?.key.toString());
                    updateFilter('attributeset', { values: [newVal?.key.toString() || ''] });
                }}
                selectedKey={attributeSet ?? ''}
                disabled={attributeSets.size === 0}
            />
        );
    }, [attributeSet, attributeSetDropDownOptions, attributeSets]);

    return (
        <Panel
            headerText={'Filter'}
            isOpen={props.isOpen}
            closeButtonAriaLabel='Close'
            onRenderFooterContent={onRenderFooterContent}
            onDismiss={(): void => onCancel()}
            isLightDismiss
            onLightDismissClick={(): void => props.onMinimizePanel()}
            isFooterAtBottom={true}
            type={PanelType.custom}
            customWidth={'360px'}
            styles={styles.panel}>
            {showErrors && (
                <span
                    style={{
                        color: FluentTheme.semanticColors.errorText,
                        fontSize: 12,
                    }}>
                    {errorMessage}
                </span>
            )}
            <Label style={styles.label}>Attribute set</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                {createAttributeSetsDropdownElement()}
            </div>
            <Label style={styles.label}>Attribute</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <Dropdown
                    options={attributeDropDownOptions ?? []}
                    placeholder='Filter by attribute'
                    onChange={(ev, newVal) => {
                        setAttribute(newVal?.key.toString());
                        updateFilter('attribute', { values: [newVal?.key.toString() || ''] });
                    }}
                    selectedKey={attribute ?? ''}
                    disabled={attributeSet === undefined}
                />
            </div>
            <Label style={styles.label}>Modified by</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <CoreSinglePrincipalPersonaPickerTypeaheadSearch
                    ariaLabel='Modified by'
                    placeHolder='Filter by user name or alias'
                    required
                    onChange={onModifiedBySelected}
                    selectedItem={modifiedBy}
                />
                <FontIcon
                    aria-label='Search'
                    iconName='Search'
                    className={mergedstyles.searchIcon}
                />
            </div>
            <Label style={styles.label}>Modified after</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <DatePicker
                    placeholder={'Select a date...'}
                    allowTextInput={true}
                    value={modifiedAfter}
                    maxDate={datePlusDays(new Date(), -1)}
                    onSelectDate={(date): void => {
                        setModifiedAfter(date ?? undefined);
                        updateFilter('lastmodified', {
                            values: [
                                date ? dateToDateStringFormat(localDateToUTCDate(date)) : '',
                                modifiedBefore
                                    ? dateToDateStringFormat(localDateToUTCDate(modifiedBefore))
                                    : '',
                            ],
                        });
                    }}
                />
            </div>
            <Label style={styles.label}>Modified before</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <DatePicker
                    placeholder={'Select a date...'}
                    allowTextInput={true}
                    value={modifiedBefore}
                    onSelectDate={(date): void => {
                        setModifiedBefore(date ?? undefined);
                        updateFilter('lastmodified', {
                            values: [
                                modifiedAfter
                                    ? dateToDateStringFormat(localDateToUTCDate(modifiedAfter))
                                    : '',
                                date ? dateToDateStringFormat(localDateToUTCDate(date)) : '',
                            ],
                        });
                    }}
                />
            </div>
            <div style={{ marginTop: '10px' }}>
                <ActionButton
                    iconProps={{ iconName: IconNames.ClearFilter }}
                    onClick={() => clearFilters()}>
                    Clear filters
                </ActionButton>
            </div>
        </Panel>
    );
}

const MaxLens = {
    employees: 1,
    name: 32,
    description: 128,
    support: 250,
};

const styles = {
    label: {
        marginTop: '25px',
        padding: 0,
        height: '20px',
    },
    button: {
        marginTop: '20px',
        marginBottom: '5px',
        padding: 0,
    },
    element: {
        root: {
            marginTop: '5px',
            width: '312px',
            borderRadius: '4px',
            background: '#FFF',
            borderColor: '#D1D1D1',
        },
    },
    panel: {
        root: {
            marginTop: '65px',
        },
    },
};

const mergedstyles = mergeStyleSets({
    element: { position: 'relative' },
    searchIcon: {
        position: 'absolute',
        right: '6px',
        top: '2.5px',
        fontSize: '20px',
        transform: 'scaleX(-1)',
        color: '#616161',
    },
});
