import React, { useState, useContext, useEffect } from 'react';
import {
    Checkbox,
    DatePicker,
    DayOfWeek,
    FontWeights,
    FontSizes,
    IPersonaProps,
    mergeStyleSets,
    mergeStyles,
    MessageBar,
    MessageBarType,
    Stack,
} from '@fluentui/react';
import { IEmployee } from 'clients/employee-client';
import { FiltersClearanceRecords } from 'contexts/filters-clearance-records';
import { FiltersContext } from 'contexts/filters-context';
import { IMultiChoiceFilterItem } from 'types/multi-choice-filter-Item';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import PreHireFilterListPicker from 'components/common/prehire-filter-list-picker';
import { IClearanceRecord, isIClearanceRecord } from 'clients/clearance-client';
import { transformPrehireToPersona } from 'utils/internal-persona-utils';
import { globalCheckboxStyles } from 'assets/styles/global-styles';
import { hierarchyLvlsAlias, IHierarchy } from 'clients/reports-client';
import { Dictionary } from 'assets/constants/global-constants';
import ContractDropdown from 'components/screening/common/filters/contract-dropdown';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import { IPreHire } from 'components/screening/us-gov/IScreening';
import { IContract } from 'components/screening/us-gov/IContract';
import { ICommonScreening, isICommonScreening } from 'components/screening/common/ICommonScreening';
import { ISuitabilityRecord, isISuitabilityRecord } from 'clients/suitability-client';
import { FiltersSuitabilityRecords } from 'contexts/filters-suitability-records';

interface MultiChoiceFilterProps {
    title?: string;
    filterItems: IMultiChoiceFilterItem[];
    unfilteredData: IClearanceRecord[] | ICommonScreening[] | IContract[] | ISuitabilityRecord[];
    onChildStateChange?(value: IMultiChoiceFilterItem, selected: boolean): void;
}

const styles = mergeStyleSets({
    title: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.medium,
        paddingBottom: '5px',
    },
});

const defaultIContract: [string, IContract] = [
    '',
    {
        id: '',
        project: '',
        customer: '',
    },
];

export function MultiChoiceFilter(props: MultiChoiceFilterProps): JSX.Element {
    if (props && props.filterItems) {
        if (props.filterItems.length > 0) {
            return (
                <div>
                    <div className={styles.title}>{props.title}</div>
                    {props.filterItems
                        .filter((filterItem: IMultiChoiceFilterItem) => !filterItem.isHidden)
                        .map((filterItem: IMultiChoiceFilterItem) => {
                            return (
                                <Checkbox
                                    key={filterItem.key ?? filterItem.label}
                                    styles={
                                        filterItem.styles
                                            ? mergeStyleSets(
                                                  globalCheckboxStyles,
                                                  filterItem.styles,
                                              )
                                            : globalCheckboxStyles
                                    }
                                    label={
                                        filterItem.generateLabel && props.unfilteredData
                                            ? filterItem.generateLabel(props.unfilteredData)
                                            : filterItem.label
                                    }
                                    checked={filterItem.isChecked ?? false}
                                    onChange={(ev, isChecked): void => {
                                        if (filterItem.onChange) {
                                            filterItem.onChange(ev, isChecked);
                                        }

                                        filterStateChanged(filterItem, isChecked);
                                    }}
                                />
                            );
                        })}
                </div>
            );
        } else {
            return <></>;
        }
    } else return <></>;
    function filterStateChanged(filterItem: IMultiChoiceFilterItem, isChecked?: boolean): void {
        if (props.onChildStateChange) {
            props.onChildStateChange(filterItem, isChecked ? isChecked : false);
        }
    }
}

export interface EmployeePickerFilterProps {
    onFilterStateChange: (value: string, adding: boolean) => void;
    isClearanceRecords?: boolean;
    isSuitabilityRecords?: boolean;
}

export function EmployeePickerFilter(props: EmployeePickerFilterProps): JSX.Element {
    const placeholder = 'Employee';
    const [message, setMessage] = useState<string>('');
    const [hasMessage, setHasMessage] = useState(false);
    const [selectedPersona, setSelectedPersona] = useState<IPersonaProps | undefined>();
    const [selectedEmployee, setSelectedEmployee] = useState<IEmployee | undefined>();
    const [triggerFilterCleared, setTriggerFilterCleared] = useState<number>(0);
    const filtersContext = useContext(FiltersContext);
    const filtersClearanceRecordsContext = useContext(FiltersClearanceRecords);
    const filtersSuitabilityRecordsContext = useContext(FiltersSuitabilityRecords);

    // Picker needs to have the key cycled at a higher level component in order to be cleared
    useEffect(() => {
        if (
            filtersContext?.filterCleared ||
            filtersClearanceRecordsContext?.filterCleared ||
            filtersSuitabilityRecordsContext?.filterCleared
        ) {
            setSelectedPersona(undefined);
            setTriggerFilterCleared((currentValue) => currentValue + 1);
        }
    }, [
        filtersContext?.filterCleared,
        filtersClearanceRecordsContext?.filterCleared,
        filtersSuitabilityRecordsContext?.filterCleared,
    ]);

    function onSelectedCandidateChange(persona?: IPersonaProps): void {
        if (persona && persona.itemProp) {
            try {
                const emp: IEmployee = JSON.parse(persona.itemProp);
                setSelectedPersona(persona);
                setSelectedEmployee(emp);
                if (props.isClearanceRecords) {
                    filtersClearanceRecordsContext.setEmployeeId(emp.id);
                } else if (props.isSuitabilityRecords) {
                    filtersSuitabilityRecordsContext.setEmployeeId(emp.id);
                } else {
                    filtersContext.setEmployeeId(emp.id);
                }
                props.onFilterStateChange(emp.id, true);
            } catch (error) {
                setMessage("An error occurred while loading the selected employee's information.");
                setHasMessage(true);
            }
        } else {
            setSelectedPersona(undefined);
            setSelectedEmployee(undefined);
            if (props.isClearanceRecords) {
                filtersClearanceRecordsContext.setEmployeeId('');
            } else if (props.isSuitabilityRecords) {
                filtersSuitabilityRecordsContext.setEmployeeId('');
            } else {
                filtersContext.setEmployeeId('');
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            props.onFilterStateChange(selectedEmployee?.id!, false);
        }
    }

    const PickerProps = {
        message,
        setMessage,
        hasMessage,
        setHasMessage,
        selectedPersona,
        onSelectedCandidateChange,
        placeholder,
    };

    return <PickerFilterBase {...PickerProps} key={triggerFilterCleared} />;
}
export interface PcnPickerFilterProps {
    rawCandidates: ICommonScreening[];
    onFilterStateChange: (value: string, adding: boolean) => void;
}

export function PcnPickerFilter(props: PcnPickerFilterProps): JSX.Element {
    const [personaChoiceList, setPersonaChoiceList] = useState<IPersonaProps[]>();
    const [selectedPersona, setSelectedPersona] = useState<IPersonaProps>();
    const [triggerFilterCleared, setTriggerFilterClear] = useState<number>(0);
    const filtersContext = useContext(FiltersContext);

    // Picker needs to have the key cycled at a higher level component in order to be cleared
    useEffect(() => {
        if (filtersContext.filterCleared) {
            setSelectedPersona(undefined);
            setTriggerFilterClear((currentValue) => currentValue + 1);
        }
    }, [filtersContext.filterCleared]);

    useEffect(() => {
        const transformedPersonaList = props.rawCandidates
            .filter((screen) => !!screen.preHire)
            .map((screen) => transformPrehireToPersona(screen.preHire!));
        setPersonaChoiceList(transformedPersonaList);
    }, [props.rawCandidates]);

    return (
        <PreHireFilterListPicker
            key={triggerFilterCleared}
            onCandidateSelected={onSelectedCandidateChange}
            selectedPersona={selectedPersona}
            personaChoiceList={personaChoiceList}
            placeHolder={'PCN/Prehire'}
        />
    );

    function onSelectedCandidateChange(persona?: IPersonaProps): void {
        if (persona && persona.itemProp) {
            const preHire: IPreHire = JSON.parse(persona.itemProp);
            setSelectedPersona(persona);
            if (preHire.pcn) {
                filtersContext.setPcn(preHire.pcn);
                props.onFilterStateChange(preHire.pcn, true);
            }
            filtersContext.setFirstName(preHire.firstName);
            filtersContext.setLastName(preHire.lastName);
            props.onFilterStateChange(preHire.firstName, true);
            props.onFilterStateChange(preHire.lastName, true);
        } else {
            setSelectedPersona(undefined);
            if (filtersContext.pcn) {
                filtersContext.setPcn('');
            }
            filtersContext.setFirstName('');
            filtersContext.setLastName('');
            props.onFilterStateChange('', false);
        }
    }
}

export interface ContractDropdownFilterProps {
    rawCandidates: (IClearanceRecord | ICommonScreening | ISuitabilityRecord)[];
    onFilterStateChange: (contractIdArr: string[], adding: boolean) => void;
    contractMap?: Map<string, string>;
    screeningPath: ScreeningPaths;
    contracts?: Dictionary<IContract>;
}

export function ContractDropdownFilter(props: ContractDropdownFilterProps): JSX.Element {
    const filtersContext = useContext(FiltersContext);
    const filtersClearanceRecordsContext = useContext(FiltersClearanceRecords);
    const filtersSuitabilityRecordsContext = useContext(FiltersSuitabilityRecords);
    const [contractPickerFilterMessage, setContractPickerFilterMessage] = useState<string>('');
    const [hasContractPickerFilterMessage, setHasContractPickerFilterMessage] = useState(false);
    const [contracts, setContracts] = useState<IContract[]>([]);

    useEffect(() => {
        setContracts(getUniqueContracts(props.rawCandidates));
    }, [props.rawCandidates, props.contractMap, props.contracts]);

    return (
        <>
            <ContractDropdown
                placeHolder='Contract ID'
                contracts={contracts}
                onContractSelected={onSelectedContractChange}
                filterCleared={
                    props.rawCandidates.length !== 0
                        ? isIClearanceRecord(props.rawCandidates[0])
                            ? filtersClearanceRecordsContext?.filterCleared
                            : filtersContext?.filterCleared
                        : Date.now().toString()
                }
            />
            {hasContractPickerFilterMessage && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    dismissButtonAriaLabel='Close'
                    overflowButtonAriaLabel='See more'
                    onDismiss={onDismissMessage}>
                    {contractPickerFilterMessage}
                </MessageBar>
            )}
        </>
    );

    function onDismissMessage(): void {
        setHasContractPickerFilterMessage(false);
        setContractPickerFilterMessage('');
    }

    function onSelectedContractChange(
        contractIdArr: string[],
        contractId: string,
        adding: boolean,
    ): void {
        if (contractIdArr) {
            try {
                // we need to set this to something unique in the event that the
                // same contract owner is toggled on/off in succession
                if (isIClearanceRecord(props.rawCandidates[0])) {
                    filtersClearanceRecordsContext.setContractId(`${contractId} ${Date.now()}`);
                } else if (isISuitabilityRecord(props.rawCandidates[0])) {
                    filtersSuitabilityRecordsContext.setContractId(`${contractId} ${Date.now()}`);
                } else {
                    filtersContext.setContractId(`${contractId} ${Date.now()}`);
                }
                props.onFilterStateChange(contractIdArr, adding);
            } catch (error) {
                setContractPickerFilterMessage(
                    "An error occurred while loading the selected employee's information.",
                );
                setHasContractPickerFilterMessage(true);
            }
        } else {
            if (isIClearanceRecord(props.rawCandidates[0])) {
                filtersClearanceRecordsContext.setContractId('');
            } else {
                filtersContext.setContractId('');
            }
            props.onFilterStateChange([], false);
        }
    }

    function getUniqueContracts(
        candidates: (IClearanceRecord | ICommonScreening | ISuitabilityRecord)[],
    ): IContract[] {
        if (!candidates || candidates.length === 0) {
            return [];
        }
        const firstElement = candidates[0];
        if (isICommonScreening(firstElement)) {
            return Array.from(
                new Map(
                    // Screening may not have a contract
                    candidates
                        .filter((x) => isICommonScreening(x) && !!x.contractId)
                        .map((candidate) => {
                            if (isICommonScreening(candidate)) {
                                let contract: IContract | null = null;
                                if (props.contracts) {
                                    contract = props.contracts[candidate.contractId];
                                }
                                const project = contract ? contract.project : candidate.contractId;
                                const customer = contract
                                    ? contract.customer
                                    : candidate.contractId;
                                return [
                                    candidate.contractId,
                                    {
                                        id: candidate.contractId,
                                        project,
                                        customer,
                                    },
                                ];
                            }
                            return defaultIContract;
                        }),
                ).values(),
            );
        } else if (isIClearanceRecord(firstElement)) {
            return Array.from(
                new Map(
                    candidates
                        .filter((x) => isIClearanceRecord(x) && !!x.contractId)
                        .map((candidate) => {
                            if (isIClearanceRecord(candidate) && props.contractMap) {
                                const contract: IContract = {
                                    id: candidate.contractId || '',
                                    customer: '',
                                    project: candidate.contractId
                                        ? props.contractMap.get(candidate.contractId) || ''
                                        : '',
                                };
                                return [candidate.contractId, contract];
                            }
                            return defaultIContract;
                        }),
                ).values(),
            );
        } else if (isISuitabilityRecord(firstElement)) {
            return Array.from(
                new Map(
                    candidates
                        .filter((x) => isISuitabilityRecord(x) && !!x.contractId)
                        .map((candidate) => {
                            if (isISuitabilityRecord(candidate) && props.contractMap) {
                                const contract: IContract = {
                                    id: candidate.contractId || '',
                                    customer: '',
                                    project: candidate.contractId
                                        ? props.contractMap.get(candidate.contractId) || ''
                                        : '',
                                };
                                return [candidate.contractId, contract];
                            }
                            return defaultIContract;
                        }),
                ).values(),
            );
        }
        return [];
    }
}
export interface OrgLeaderPickerProps {
    hierarchyRecords: Dictionary<IHierarchy> | undefined;
    isClearanceRecords?: boolean;
    isSuitabilityRecords?: boolean;
    onFilterStateChange: (value: string, adding: boolean, level: hierarchyLvlsAlias) => void;
}

export function OrgLeaderPicker(props: OrgLeaderPickerProps): JSX.Element {
    const placeholder = 'Org Leader';
    const [message, setMessage] = useState<string>('');
    const [hasMessage, setHasMessage] = useState(false);
    const [selectedPersona, setSelectedPersona] = useState<IPersonaProps | undefined>();
    const [triggerFilterCleared, setTriggerFilterCleared] = useState<number>(0);
    const [hierarchyMap, setHierarchyMap] = useState<Map<string, hierarchyLvlsAlias>>(
        new Map<string, hierarchyLvlsAlias>(),
    );
    const filtersContext = useContext(FiltersContext);
    const filtersClearanceRecordsContext = useContext(FiltersClearanceRecords);
    const filtersSuitabilityRecordsContext = useContext(FiltersSuitabilityRecords);

    // Picker needs to have the key cycled at a higher level component in order to be cleared
    useEffect(() => {
        if (
            filtersContext?.filterCleared ||
            filtersClearanceRecordsContext?.filterCleared ||
            filtersSuitabilityRecordsContext?.filterCleared
        ) {
            setSelectedPersona(undefined);
            setTriggerFilterCleared((currentValue) => currentValue + 1);
        }
    }, [
        filtersContext?.filterCleared,
        filtersClearanceRecordsContext?.filterCleared,
        filtersSuitabilityRecordsContext?.filterCleared,
    ]);

    useEffect(() => {
        transformHierarchyMapFromReport();
    }, [props.hierarchyRecords]);

    // function to pre-process a hierarchy map to quickly lookup if someone is an org leader to a screening user
    function transformHierarchyMapFromReport(): void {
        if (props.hierarchyRecords) {
            const newHierarchyMap = new Map<string, hierarchyLvlsAlias>();
            const Ls = Object.values(hierarchyLvlsAlias);
            Object.values(props.hierarchyRecords).forEach((report) => {
                for (const level of Ls) {
                    const key = report[level];
                    if (key && !newHierarchyMap.get(key)) {
                        newHierarchyMap.set(key, level);
                    }
                }
            });
            setHierarchyMap(newHierarchyMap);
        }
    }

    function onSelectedCandidateChange(persona?: IPersonaProps): void {
        if (persona && persona.itemProp) {
            try {
                const emp: IEmployee = JSON.parse(persona.itemProp);
                setSelectedPersona(persona);
                if (props.isClearanceRecords) {
                    filtersClearanceRecordsContext.setOrgLeaderAlias(emp.alias);
                } else if (props.isSuitabilityRecords) {
                    filtersSuitabilityRecordsContext.setOrgLeaderAlias(emp.alias);
                } else {
                    filtersContext.setOrgLeaderAlias(emp.alias);
                }
                props.onFilterStateChange(
                    emp.alias,
                    true,
                    hierarchyMap.get(emp.alias) || hierarchyLvlsAlias.L1,
                );
            } catch (error) {
                setMessage("An error occurred while loading the selected employee's information.");
                setHasMessage(true);
            }
        } else {
            setSelectedPersona(undefined);
            if (props.isClearanceRecords) {
                filtersClearanceRecordsContext.setOrgLeaderAlias('');
            } else if (props.isSuitabilityRecords) {
                filtersSuitabilityRecordsContext.setOrgLeaderAlias('');
            } else {
                filtersContext.setOrgLeaderAlias('');
            }
            props.onFilterStateChange('', false, hierarchyLvlsAlias.L1);
        }
    }

    const PickerProps = {
        message,
        setMessage,
        hasMessage,
        setHasMessage,
        selectedPersona,
        onSelectedCandidateChange,
        placeholder,
    };

    return <PickerFilterBase {...PickerProps} key={triggerFilterCleared} />;
}

export interface ReportsToPickerProps {
    onFilterStateChange: (value: string, adding: boolean) => void;
}

export function ReportsToPicker(props: ReportsToPickerProps): JSX.Element {
    const placeholder = 'Reports To';
    const [message, setMessage] = useState<string>('');
    const [hasMessage, setHasMessage] = useState(false);
    const [triggerFilterCleared, setTriggerFilterCleared] = useState<number>(0);
    const [selectedPersona, setSelectedPersona] = useState<IPersonaProps | undefined>();
    const filtersContext = useContext(FiltersContext);

    // Picker needs to have the key cycled at a higher level component in order to be cleared
    useEffect(() => {
        if (filtersContext.filterCleared) {
            setSelectedPersona(undefined);
            setTriggerFilterCleared((currentValue) => currentValue + 1);
        }
    }, [filtersContext.filterCleared]);

    function onSelectedCandidateChange(persona?: IPersonaProps): void {
        if (persona && persona.itemProp) {
            try {
                const emp: IEmployee = JSON.parse(persona.itemProp);
                setSelectedPersona(persona);
                filtersContext.setReportsTo(emp.alias);
                props.onFilterStateChange(emp.alias, true);
            } catch (error) {
                setMessage("An error occurred while loading the selected employee's information.");
                setHasMessage(true);
            }
        } else {
            setSelectedPersona(undefined);
            filtersContext.setReportsTo('');
            props.onFilterStateChange('', false);
        }
    }

    const PickerProps = {
        message,
        setMessage,
        hasMessage,
        setHasMessage,
        selectedPersona,
        onSelectedCandidateChange,
        placeholder,
    };

    return <PickerFilterBase {...PickerProps} key={triggerFilterCleared} />;
}

interface PickerFilterBaseProps {
    onSelectedCandidateChange: (persona?: IPersonaProps) => void;
    placeholder: string;
    hasMessage: boolean;
    setHasMessage: (value: boolean) => void;
    message: string;
    setMessage: (value: string) => void;
    selectedPersona: IPersonaProps | undefined;
}

function PickerFilterBase(props: PickerFilterBaseProps): JSX.Element {
    function onDismissMessage(): void {
        props.setHasMessage(false);
        props.setMessage('');
    }

    return (
        <>
            <EmployeePickerTypeaheadSearch
                ariaLabel='Filter Picker'
                onCandidateSelected={props.onSelectedCandidateChange}
                selectedPersona={props.selectedPersona}
                placeHolder={props.placeholder}
            />
            {props.hasMessage && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    dismissButtonAriaLabel='Close'
                    overflowButtonAriaLabel='See more'
                    onDismiss={onDismissMessage}>
                    {props.message}
                </MessageBar>
            )}
        </>
    );
}
interface DateTimeStartEndPickerProps {
    startDate: Date | undefined;
    endDate: Date | undefined;
    changeDate: (isStart: boolean, date: Date | null | undefined) => void;
    filterCleared: string | undefined;
}

const rootClass = mergeStyles({ maxWidth: 300 });
const startDateText = 'Start Date';
const endDateText = 'End Date';
// this is a filter with a top down filter for start time and end time ranges EX: in the Clearance Records filter
export function DateTimeStartEndPicker(props: DateTimeStartEndPickerProps): JSX.Element {
    useEffect(() => {
        if (props.filterCleared) {
            props.changeDate(true, undefined);
            props.changeDate(false, undefined);
        }
    }, [props.filterCleared]);

    return (
        <Stack styles={{ root: { marginBottom: 15, flexFlow: 'row' } }}>
            <div className={rootClass}>
                <DatePicker
                    value={props.startDate}
                    maxDate={props.endDate}
                    firstDayOfWeek={DayOfWeek.Sunday}
                    placeholder={startDateText}
                    ariaLabel={startDateText}
                    allowTextInput
                    showCloseButton
                    onSelectDate={(date): void => props.changeDate(true, date)}
                />
            </div>
            <div className={rootClass} style={{ marginLeft: 5 }}>
                <DatePicker
                    value={props.endDate}
                    minDate={props.startDate}
                    allowTextInput
                    firstDayOfWeek={DayOfWeek.Sunday}
                    placeholder={endDateText}
                    ariaLabel={endDateText}
                    showCloseButton
                    onSelectDate={(date): void => props.changeDate(false, date)}
                />
            </div>
        </Stack>
    );
}
