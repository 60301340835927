import React from 'react';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import { ScreeningPaths } from 'components/screening/common/common-constants';

const FTE_LABEL = 'Current Microsoft FTE or vendor';
const VENDOR_LABEL = 'Pre-hire or external candidate';

const FTE_KEY = 'A';
const PRE_HIRE_KEY = 'P';

export interface CandidateNominationStepEmploymentTypeProps {
    onChange: (selectedKey: string) => void;
    screeningPath: ScreeningPaths;
    selectedKey?: string;
}
function CandidateNominationStepEmploymentType(
    props: CandidateNominationStepEmploymentTypeProps,
): JSX.Element {
    // TODO: Simplify this when Public Trust supports Pre-hire
    let CHOICES: IChoiceGroupOption[] = [];

    const FTE_CHOICE: IChoiceGroupOption = {
        key: FTE_KEY,
        text: FTE_LABEL,
        value: FTE_KEY,
    };

    const PRE_HIRE_CHOICE: IChoiceGroupOption = {
        key: PRE_HIRE_KEY,
        text: VENDOR_LABEL,
        value: PRE_HIRE_KEY,
    };

    if (props.screeningPath === ScreeningPaths.UsGov) CHOICES = [FTE_CHOICE, PRE_HIRE_CHOICE];
    else CHOICES = [FTE_CHOICE];

    return (
        <ChoiceGroup
            options={CHOICES}
            selectedKey={props.selectedKey}
            onChange={onChange}
            required={true}
        />
    );
    function onChange(
        event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ): void {
        if (option) {
            props.onChange(option.key);
        }
    }
}
export default CandidateNominationStepEmploymentType;
