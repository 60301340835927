import React, { useEffect, useState } from 'react';
import {
    IPersonaProps,
    mergeStyleSets,
    IBasePickerSuggestionsProps,
    NormalPeoplePicker,
    ValidationState,
    Label,
} from '@fluentui/react';

const REGEX_SEARCH_EMPLOYEE = new RegExp(/^[a-zA-Z0-9-_]+$/);
export interface PrehireFilterListPickerProps {
    label?: string;
    placeHolder?: string;
    selectedItems?: IPersonaProps[];
    selectedPersona?: IPersonaProps;
    personaChoiceList?: IPersonaProps[];
    onCandidateSelected: (persona?: IPersonaProps) => void;
    loadCandidatesOnFilterChange?: (filterText: string) => Promise<IPersonaProps[]>;
}

const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested Employees',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading employees...',
    showRemoveButtons: false,
    suggestionsAvailableAlertText: 'Employees Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested Employees',
};

const labelStyle = {
    fontWeight: 600,
    marginBottom: 5,
    display: 'inline-block',
};

export default function PrehireFilterListPicker(props: PrehireFilterListPickerProps) {
    const defaultSelectedPersonas = props.selectedPersona ? [props.selectedPersona] : [];
    const [personaChoiceList, setPersonaChoiceList] = useState<IPersonaProps[]>([]);

    useEffect(() => {
        if (props.personaChoiceList) {
            setPersonaChoiceList([...props.personaChoiceList]);
        }
    }, [props.personaChoiceList]);

    const placeholder =
        props.placeHolder && props.selectedPersona === undefined ? props.placeHolder : '';

    return (
        <div className={style.body}>
            {props.label && <Label style={labelStyle}>{props.label}</Label>}
            {defaultSelectedPersonas && (
                <NormalPeoplePicker
                    aria-label='Employee Picker'
                    searchingText='Searching Employees'
                    pickerSuggestionsProps={suggestionProps}
                    onValidateInput={validateInput}
                    itemLimit={1}
                    defaultSelectedItems={defaultSelectedPersonas}
                    onResolveSuggestions={(filterText: string) => onFilterChanged(filterText)}
                    className={'ms-PeoplePicker'}
                    onItemSelected={onItemSelected}
                    onChange={onItemsChange}
                    inputProps={{ placeholder }}
                    selectedItems={props?.selectedItems}
                />
            )}
        </div>
    );
    function onItemsChange(items?: IPersonaProps[]): void {
        if (!items || items.length === 0) {
            props.onCandidateSelected(undefined);
        }
    }

    function onItemSelected(selectedItem?: IPersonaProps): IPersonaProps | null {
        if (selectedItem) {
            props.onCandidateSelected(selectedItem);
            return selectedItem;
        } else {
            return null;
        }
    }

    async function onFilterChanged(filterText: string): Promise<IPersonaProps[]> {
        if (filterText && personaChoiceList) {
            let listToFilter = personaChoiceList;
            if (props.loadCandidatesOnFilterChange) {
                listToFilter = await props.loadCandidatesOnFilterChange(filterText);
                setPersonaChoiceList(listToFilter);
            }
            return filterPersonaList(filterText, listToFilter);
        } else {
            return [];
        }
    }
}

function filterPersonaList(filterText: string, personaChoiceList: IPersonaProps[]) {
    try {
        return personaChoiceList.filter((persona: IPersonaProps) => {
            const personaSearchableText = `${persona.text || ''},
            ${persona.secondaryText || ''},
            ${persona.tertiaryText || ''}`;
            const filterRegex = new RegExp(filterText, 'i');
            return filterRegex.test(personaSearchableText);
        });
    } catch {
        return [];
    }
}

function validateInput(input: string): ValidationState {
    if (REGEX_SEARCH_EMPLOYEE.test(input)) {
        return ValidationState.valid;
    } else {
        return ValidationState.invalid;
    }
}

const style = mergeStyleSets({
    body: {
        flexGrow: 1,
    },
});
