import { Dropdown, IDropdownStyles, mergeStyles } from '@fluentui/react';
import React from 'react';
import { Form, formImages } from 'components/forms/forms-common';
import { UpdatableSettings } from 'components/forms/settings/forms-edit-settings';

const imageStyles = mergeStyles({
    width: '30%',
    aspectRatio: '3 / 2',
    objectFit: 'cover',
});

const dropdownStyles: Partial<IDropdownStyles> = { root: { maxWidth: 300 } };

type ImageSettingsProps = {
    form: Form;
    updateAndSaveForm: (
        property: keyof UpdatableSettings,
        value: string | string[] | boolean | string[][],
    ) => void;
};

export function ImageSettings(props: ImageSettingsProps): JSX.Element {
    const { form, updateAndSaveForm } = props;

    return (
        <div style={{ gridArea: 'imageVal', display: 'flex', gap: '5rem', alignItems: 'center' }}>
            <div style={{ flexBasis: '50%' }}>
                <Dropdown
                    selectedKey={form.image}
                    placeholder='Select an image'
                    options={formImages}
                    styles={dropdownStyles}
                    aria-labelledby={'imageLabel'}
                    onChange={(ev, selectedOption): void => {
                        if (!selectedOption) {
                            return;
                        }
                        updateAndSaveForm('image', selectedOption.key as string);
                    }}
                />
            </div>
            {form.image && <img className={imageStyles} alt='' src={form.image} />}
        </div>
    );
}
