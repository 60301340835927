import React, { useEffect } from 'react';

import {
    ISeatsColumns,
    seatsColumnNames,
    seatsTableColumns,
} from 'components/facilities/facilities-seats/seats-table-columns';
import { ISeatRecord, IFacilityRecord } from 'clients/facilities-client';
import { Table } from 'components/common/table';
import { numCmp, strCmp, useSortColumnHandler } from 'utils/sort-utils';
import { onModalConcludeType } from 'components/common/buttons/modal-action-button';

export interface SeatsTableProps {
    facility?: IFacilityRecord;
    onModalConcluded?: onModalConcludeType<ISeatRecord | undefined>;
    enableSelectionMode?: boolean;
    allSelectedItemsCallback?: (selection: ISeatRecord[]) => void;
    selectedItemCallback?: (selection: ISeatRecord) => void;
    updateSeat: (record: ISeatRecord) => void;
    addEditSeat: (result: ISeatRecord[]) => void;
    seats: ISeatsColumns[] | undefined;
    setSeatsColumns: (seatColumns?: ISeatsColumns[]) => void;
}

export default function SeatsTable(props: SeatsTableProps): JSX.Element {
    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler(
        seatsColumnNames.Name,
        1,
    );

    useEffect(() => {
        if (props.seats) {
            const seatColumns = sortSeats(props.seats);
            props.setSeatsColumns(seatColumns);
        }
    }, [sortColumn, sortAscending]);
    const onDeleteRecordConcluded = (record: ISeatRecord): void => {
        if (props.updateSeat) {
            props.updateSeat(record);
        }
    };

    const onAddEditRecordConcluded = (result: ISeatRecord[]): void => {
        if (props.addEditSeat) {
            props.addEditSeat(result);
        }
    };
    const tableColumns = seatsTableColumns({
        facility: props.facility,
        seats: props.seats,
        sortColumn: sortColumn,
        sortAscending: sortAscending === 1,
        sortColumnHandler: sortColumnHandler,
        onModalConcluded: props.onModalConcluded,
        onDeleteRecordConcluded: onDeleteRecordConcluded,
        onAddEditRecordConcluded,
    });

    const sortSeats = (seats: ISeatsColumns[]): ISeatsColumns[] => {
        type A = ISeatsColumns;

        const chooseSortCmp = (
            sortColumn: string,
        ): ((r1: ISeatsColumns, r2: ISeatsColumns) => number) => {
            switch (sortColumn) {
                case seatsColumnNames.Name:
                    return (r1: A, r2: A): number =>
                        sortAscending * strCmp(r1.seatRecord.seatName, r2.seatRecord.seatName);
                case seatsColumnNames.IsOutOfOrder:
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        strCmp(
                            r1.seatRecord.isOutOfOrder?.toString(),
                            r2.seatRecord.isOutOfOrder?.toString(),
                        );
                case seatsColumnNames.ProvisionType:
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        strCmp(
                            r1.seatRecord.provisionInfo?.provisionType,
                            r2.seatRecord.provisionInfo?.provisionType,
                        );
                case seatsColumnNames.OccupiedBy:
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        (r1.seatRecord.occupiedBy || r2.seatRecord.occupiedBy
                            ? strCmp(r1.seatRecord.occupiedBy?.by, r2.seatRecord.occupiedBy?.by)
                            : 0);
                case seatsColumnNames.OccupiedAt:
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        (r1.seatRecord.occupiedBy || r2.seatRecord.occupiedBy
                            ? numCmp(
                                  r1.seatRecord.occupiedBy?.atUtc,
                                  r2.seatRecord.occupiedBy?.atUtc,
                              )
                            : 0);
                case seatsColumnNames.LastModifiedBy:
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        strCmp(r1.seatRecord.lastModified?.by, r2.seatRecord.lastModified?.by);
                case seatsColumnNames.LastModifiedAt:
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(
                            r1.seatRecord.lastModified?.atUtc,
                            r2.seatRecord.lastModified?.atUtc,
                        );
                default:
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp(sortColumn);
        return [...seats].sort(sortCmp);
    };

    return (
        <Table
            isFetchingData={false}
            rows={props.seats ?? []}
            tableColumns={tableColumns}
            tableName='Seat Management'
            shimmerLines={2}
            shimmerLabel='Loading seats...'
        />
    );
}
