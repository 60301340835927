export enum SuitabilityLevels {
    IT1 = 'IT1',
    IT2 = 'IT2',
    IT3 = 'IT3',
    Tier1 = 'Tier 1',
    Tier2 = 'Tier 2',
    Tier3 = 'Tier 3',
    Tier4 = 'Tier 4',
    Tier5 = 'Tier 5',
}

// TODO 24543473 refactor this V2 enum type with SuitabilityLevels once suitability backend is refactored
export enum SuitabilityLevelsV2 {
    IT1 = 'IT1',
    IT2 = 'IT2',
    IT3 = 'IT3',
    Tier1 = 'Tier1',
    Tier2 = 'Tier2',
    Tier3 = 'Tier3',
    Tier4 = 'Tier4',
    Tier5 = 'Tier5',
}

export enum SuitabilityTypes {
    Final = 'Final',
    Interim = 'Interim',
    None = 'None',
}

export enum SuitabilityStatuses {
    Active = 'Active',
    Denied = 'Denied',
    Inactive = 'Inactive',
    LossOfJurisdiction = 'Loss of jurisdiction',
    Pending = 'Pending',
    PendingCSR = 'Pending CSR',
    RemovedFromProcessing = 'Removed from processing',
}

export const suitabilityStatusesConsideredActive: string[] = [SuitabilityStatuses.Active];

export enum SuitabilityAgencies {
    DHS = 'DHS',
    DHSCBP = 'DHS_CBP',
    DHSCISA = 'DHS_CISA',
    DHSFEMA = 'DHS_FEMA',
    DHSFPS = 'DHS_FPS',
    DHSICE = 'DHS_ICE',
    DHSTSA = 'DHS_TSA',
    DHSUSCIS = 'DHS_USCIS',
    DOCCensus = 'DOC_Census',
    DOD = 'DOD',
    DODHumana = 'DOD_Humana',
    DODIT = 'DOD_IT',
    DOJ = 'DOJ',
    DOJDEA = 'DOJ_DEA',
    DOJEOD = 'DOJ_EOD',
    DOJEOIR = 'DOJ_EOIR',
    DOS = 'DOS',
    DOT = 'DOT',
    FDIC = 'FDIC',
    FederalReserveBoard = 'Federal_Reserve_Board',
    HHS = 'HHS',
    HouseofRep = 'House_of_Rep',
    IRS = 'IRS',
    Maritime = 'Maritime',
    NCUA = 'NCUA',
    RailroadRetirementBoard = 'Railroad_Retirement_Board',
    SEC = 'SEC',
    SSA = 'SSA',
    TVA = 'TVA',
    TreasuryIRS = 'Treasury_IRS',
    USDA = 'USDA',
    USPS = 'USPS',
    UnitedStatesPostalServices = 'United_States_Postal_Services',
    USCommerceDept = 'US_Commerce_Dept',
    USCongress = 'US_Congress',
    USCourts = 'US_Courts',
    USSenate = 'US_Senate',
    USTreasuryDept = 'US_Treasury_Dept',
    VA = 'VA',
    Other = 'Other',
}
