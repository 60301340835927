import React from 'react';
import { TooltipHost, ActionButton, mergeStyles, IStyle } from '@fluentui/react';
import { detailsListStyles } from 'assets/styles/list-styles';
import { IconNames } from 'assets/constants/global-constants';

interface ICopyToClipboardButtonProps {
    text: string;
    iconStyles?: IStyle;
}

export default function CopyToClipboardButton(props: ICopyToClipboardButtonProps): JSX.Element {
    return (
        <TooltipHost content='Copy to clipboard'>
            <ActionButton
                className={detailsListStyles.icon}
                iconProps={{ iconName: IconNames.Copy, className: mergeStyles(props.iconStyles) }}
                onClick={(): void => {
                    navigator.clipboard.writeText(props.text);
                }}
                ariaLabel='Copy'
            />
        </TooltipHost>
    );
}
