import React, { useContext, useState } from 'react';
import Modal from 'components/common/modal';
import FacilitiesClient, { IEquipmentRecord } from 'clients/facilities-client';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { TextField } from '@fluentui/react';

type DeleteEquipmentModalPropsType = {
    itemToDelete: IEquipmentRecord;
    setItemToDelete: (item: IEquipmentRecord | undefined) => void;
    setShouldRefresh: (shouldRefresh: boolean) => void;
};

export default function DeleteEquipmentModal(props: DeleteEquipmentModalPropsType): JSX.Element {
    const { itemToDelete, setItemToDelete, setShouldRefresh } = props;
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [justification, setJustification] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const deleteEquipmentById = async (): Promise<void> => {
        try {
            if (!justification) return;
            setIsBusy(true);
            await FacilitiesClient.deleteEquipmentRecord(
                authContext,
                userContext,
                itemToDelete.id,
                justification,
            );
        } catch (err) {
            console.error(err);
        } finally {
            setItemToDelete(undefined);
            setShouldRefresh(true);
            setIsBusy(false);
        }
    };

    return (
        <Modal
            title='Delete equipment'
            isOpen={true}
            onCancel={(): void => setItemToDelete(undefined)}
            onSubmit={(): Promise<void> => deleteEquipmentById()}
            submitButtonText='Delete'
            showProgressIndicator={isBusy}
            isSubmitButtonDisabled={!justification || justification === '' || isBusy}>
            Are you sure you want to delete equipment: {itemToDelete.type}{' '}
            {itemToDelete.assetNumber}?
            <TextField
                required
                multiline
                label='Business justification'
                placeholder='Explain why you are deleting this equipment from Personnel.'
                onChange={(ev, nv): void => setJustification(nv)}
                value={justification}></TextField>
        </Modal>
    );
}
