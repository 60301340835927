import {
    ActionButton,
    DetailsList,
    DetailsRow,
    IColumn,
    IDetailsRowProps,
    mergeStyles,
    SelectionMode,
    ShimmeredDetailsList,
    Spinner,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import FormsClient, { FormState, GenericFormRecord } from 'clients/forms-client';
import { TableCell } from 'components/common/table';
import { AuthContext } from 'contexts/auth-context';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { secondsToMilliseconds } from 'utils/time-utils';
import { createBadge, genericFormRecordFormStates } from 'components/forms/forms-common';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import { FormsTeachingBubble } from 'components/forms/forms-teaching-bubble';
import { getAppInsights } from 'utils/telemetry-utils';

const linkStyles = mergeStyles(globalStyles.link, {
    textDecoration: 'none',
});

const containerStyles = mergeStyles(globalStyles.antiGutter, {
    background: 'white',
    padding: '0 1.75rem 0 4.5rem',
});

const tableHeaderStyles = mergeStyles(globalStyles.boldFont, globalStyles.mediumLargeFont, {
    marginTop: '3rem',
});

type FormsMySubmissionsProps = {
    isTableLoading: boolean;
    setTableLoading: React.Dispatch<React.SetStateAction<boolean>>;
    formRecords: GenericFormRecord[];
    setFormRecords: React.Dispatch<React.SetStateAction<GenericFormRecord[] | undefined>>;
    showBubble: boolean;
    setShowBubble: (showBubble: boolean) => void;
};
export function FormsMySubmissions(props: FormsMySubmissionsProps): JSX.Element {
    const {
        isTableLoading,
        setTableLoading,
        formRecords,
        setFormRecords,
        showBubble,
        setShowBubble,
    } = props;
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const hasEditFeatureFlagEnabled = useFeatureFlag(FeatureFlagKeys.formsEdit).enabled;
    const [cloningId, setCloningId] = useState<string>();

    // Only intended to be used once for teaching bubble callout
    const duplicateButtonTeachingBubbleId = 'duplicateButtonTeachingBubbleId';

    const drafts = useMemo(() => {
        return formRecords
            .filter(
                (record) => record.formState.toLowerCase() === genericFormRecordFormStates.draft,
            )
            .sort((a, b) => b.lastModifiedAtUTC - a.lastModifiedAtUTC);
    }, [formRecords]);

    const submitted = useMemo(() => {
        return formRecords
            .filter(
                (record) => record.formState.toLowerCase() !== genericFormRecordFormStates.draft,
            )
            .sort((a, b) => b.submittedAtUTC - a.submittedAtUTC);
    }, [formRecords]);

    const duplicableFormRecords = useMemo((): GenericFormRecord[] => {
        return submitted
            ? submitted.filter(
                  (record) =>
                      record.formState.toLowerCase() === genericFormRecordFormStates.completed ||
                      record.formState.toLowerCase() === genericFormRecordFormStates.accepted,
              )
            : [];
    }, [submitted]);

    const createIdForBubble = useCallback(
        (formRecord: GenericFormRecord, index: number): string => {
            if (duplicableFormRecords.length > 0 && formRecord.id === duplicableFormRecords[0].id) {
                return duplicateButtonTeachingBubbleId;
            } else {
                return `duplicate-button-${index}`;
            }
        },
        [duplicableFormRecords],
    );

    const deleteFormById = async (id: string): Promise<void> => {
        try {
            setTableLoading(true);
            const didDelete = await FormsClient.DeleteGenericFormRecordById(authContext, id);
            if (didDelete) {
                const newFormRecords = formRecords.filter((record) => record.id !== id);
                setFormRecords(newFormRecords);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setTableLoading(false);
        }
    };

    const cloneForm = async (genericFormRecord: GenericFormRecord): Promise<void> => {
        setCloningId(genericFormRecord.id);
        try {
            const cloneId = await FormsClient.CloneGenericFormRecord(
                authContext,
                genericFormRecord.id,
            );
            if (cloneId !== '' || cloneId !== undefined) {
                getAppInsights()?.trackEvent(
                    {
                        name: 'DuplicateFormMySubmissionsPage',
                    },
                    {
                        formName: genericFormRecord.name,
                    },
                );
                history.push(`/forms/respond/${genericFormRecord.name}/${cloneId}`);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setCloningId(undefined);
        }
    };

    const canCloneForm = (item: GenericFormRecord): boolean => {
        if (cloningId !== undefined || item === undefined || item.submittedAtUTC === undefined) {
            return false;
        }

        const submittedDate = new Date(secondsToMilliseconds(item.submittedAtUTC) as number);
        const migrationDate = new Date(2023, 0, 28);
        return migrationDate < submittedDate;
    };

    const draftColumns: IColumn[] = [
        {
            key: 'type',
            name: 'Form Type',
            fieldName: 'type',
            minWidth: 100,
            maxWidth: 400,
            onRender: (item: GenericFormRecord): JSX.Element => {
                return (
                    <TableCell toolTipText={item.title}>
                        <Link to={`respond/${item.name}/${item.id}`} className={linkStyles}>
                            {item.title}
                        </Link>
                    </TableCell>
                );
            },
        },
        {
            key: 'label',
            name: 'Label',
            fieldName: 'label',
            minWidth: 100,
            maxWidth: 250,
            onRender: (item: GenericFormRecord): JSX.Element => {
                return <TableCell toolTipText={item.label}>{item.label}</TableCell>;
            },
        },
        {
            key: 'createdAtUTC',
            name: 'Created',
            fieldName: 'createdAtUTC',
            minWidth: 140,
            maxWidth: 300,
            onRender: (item: GenericFormRecord): JSX.Element => {
                const createdDate = new Date(
                    secondsToMilliseconds(item['createdAtUTC']) ?? 0,
                ).toLocaleString();
                return <TableCell toolTipText={createdDate}>{createdDate}</TableCell>;
            },
        },
        {
            key: 'lastModifiedAtUTC',
            name: 'Last Modified',
            fieldName: 'lastModifiedAtUTC',
            minWidth: 140,
            maxWidth: 290,
            isSortedDescending: true,
            isSorted: true,
            onRender: (item: GenericFormRecord): JSX.Element => {
                const modifiedDate = new Date(
                    secondsToMilliseconds(item['lastModifiedAtUTC']) ?? 0,
                ).toLocaleString();
                return <TableCell toolTipText={modifiedDate}>{modifiedDate}</TableCell>;
            },
        },
        {
            key: 'status',
            name: 'Status',
            fieldName: 'Status',
            minWidth: 100,
            maxWidth: 200,
            onRender: (item: GenericFormRecord) => createBadge(item.formState),
        },
        {
            key: 'discard',
            name: 'Action',
            minWidth: 100,
            maxWidth: 140,
            onRender: (item: GenericFormRecord): JSX.Element => {
                return (
                    <ActionButton
                        iconProps={{ iconName: 'Trash' }}
                        onClick={(): void => {
                            deleteFormById(item.id);
                        }}>
                        Delete
                    </ActionButton>
                );
            },
        },
    ];

    const submittedColumns: IColumn[] = [
        {
            key: 'type',
            name: 'Form Type',
            fieldName: 'type',
            minWidth: 100,
            maxWidth: 400,
            onRender: (item: GenericFormRecord): JSX.Element => {
                return (
                    <TableCell toolTipText={item.title}>
                        <Link to={`review/${item.name}/${item.id}`} className={linkStyles}>
                            {item.title}
                        </Link>
                    </TableCell>
                );
            },
        },
        {
            key: 'label',
            name: 'Label',
            fieldName: 'label',
            minWidth: 100,
            maxWidth: 250,
            onRender: (item: GenericFormRecord): JSX.Element => {
                return <TableCell toolTipText={item.label}>{item.label}</TableCell>;
            },
        },
        {
            key: 'createdAtUTC',
            name: 'Created',
            fieldName: 'createdAtUTC',
            minWidth: 130,
            maxWidth: 300,
            onRender: (item: GenericFormRecord): JSX.Element => {
                const createdDate = new Date(
                    secondsToMilliseconds(item['createdAtUTC']) ?? 0,
                ).toLocaleString();
                return <TableCell toolTipText={createdDate}>{createdDate}</TableCell>;
            },
        },
        {
            key: 'submittedAtUTC',
            name: 'Submitted On',
            minWidth: 130,
            maxWidth: 290,
            isSorted: true,
            isSortedDescending: true,
            onRender: (item: GenericFormRecord): JSX.Element => {
                const submittedAtUTC = new Date(
                    secondsToMilliseconds(item.submittedAtUTC) ?? 0,
                ).toLocaleString();
                return <TableCell toolTipText={submittedAtUTC}>{submittedAtUTC}</TableCell>;
            },
        },
        {
            key: 'status',
            name: 'Status',
            fieldName: 'Status',
            minWidth: 100,
            maxWidth: 200,
            onRender: (item: GenericFormRecord) => createBadge(item.formState),
        },
        {
            key: 'view',
            name: 'Action',
            minWidth: 100,
            maxWidth: 140,
            onRender: (item: GenericFormRecord, index?: number): JSX.Element => {
                if (hasEditFeatureFlagEnabled) {
                    if (item.formState === FormState.rejected && item.requestUpdate) {
                        return (
                            <ActionButton
                                iconProps={{ iconName: IconNames.Edit }}
                                onClick={(): void => {
                                    history.push(`/forms/respond/${item.name}/${item.id}`);
                                }}>
                                Edit
                            </ActionButton>
                        );
                    } else if (
                        (!cloningId || item.id !== cloningId) &&
                        (item.formState === FormState.completed ||
                            item.formState === FormState.accepted)
                    ) {
                        return (
                            <>
                                <ActionButton
                                    id={createIdForBubble(item, index ?? 0)}
                                    iconProps={{ iconName: IconNames.Copy }}
                                    disabled={!canCloneForm(item)}
                                    onClick={(): void => {
                                        cloneForm(item);
                                    }}>
                                    Duplicate
                                </ActionButton>
                            </>
                        );
                    } else {
                        return <></>;
                    }
                } else {
                    if (!cloningId || item.id !== cloningId) {
                        return (
                            <ActionButton
                                id={createIdForBubble(item, index ?? 0)}
                                iconProps={{ iconName: IconNames.Copy }}
                                disabled={!canCloneForm(item)}
                                onClick={(): void => {
                                    cloneForm(item);
                                }}>
                                Duplicate
                            </ActionButton>
                        );
                    } else {
                        return <Spinner style={{ marginRight: '100%' }} />;
                    }
                }
            },
        },
    ];

    const customRenderRow = (props?: IDetailsRowProps): JSX.Element => {
        return props ? (
            <DetailsRow
                {...props}
                styles={{
                    fields: {
                        alignItems: 'center',
                        color: '#323130',
                        fontSize: '14px',
                        fontWeight: 400,
                    },
                }}
            />
        ) : (
            <></>
        );
    };

    return (
        <div className={containerStyles}>
            <h2 className={tableHeaderStyles}>Drafts</h2>
            <ShimmeredDetailsList
                columns={draftColumns}
                items={drafts ?? []}
                enableShimmer={isTableLoading}
                shimmerLines={10}
                selectionMode={SelectionMode.none}
                onRenderRow={customRenderRow}
            />

            {drafts.length < 1 && <div style={{ margin: '20px' }}>No drafts</div>}
            <h2 className={tableHeaderStyles}>Submitted</h2>
            <DetailsList
                columns={submittedColumns}
                items={submitted ?? []}
                selectionMode={SelectionMode.none}
                onRenderRow={customRenderRow}
            />
            {duplicableFormRecords.length > 0 && !isTableLoading && (
                <FormsTeachingBubble
                    idTarget={`#${duplicateButtonTeachingBubbleId}`}
                    headline='Duplicate a form'
                    bodyText='Duplicate and reuse a form from a previous submission. Once completed, this will become a new form for submission.'
                    showBubble={showBubble}
                    setShowBubble={setShowBubble}
                />
            )}
            {submitted.length < 1 && <div style={{ margin: '20px' }}>No submissions</div>}
        </div>
    );
}
