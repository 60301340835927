import React, { useContext, useEffect, useState } from 'react';
import {
    MultiChoiceFilter,
    EmployeePickerFilter,
    ContractDropdownFilter,
    PcnPickerFilter,
    OrgLeaderPicker,
    ReportsToPicker,
    DateTimeStartEndPicker,
} from 'components/common/filters';
import { Stack, Separator } from '@fluentui/react';
import { filterContextKeys, FiltersContext } from 'contexts/filters-context';
import { IMultiChoiceFilterItem } from 'types/multi-choice-filter-Item';
import { hierarchyLvlsAlias, getReportToFromHierarchy, IHierarchy } from 'clients/reports-client';
import { UserContext } from 'contexts/user-context';
import { Dictionary, noDataText } from 'assets/constants/global-constants';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import { getEnumFromString } from 'utils/enum-utils';
import {
    IEmployeeNameAndType,
    PersonnelTypes,
} from 'components/common/employee/internal-employee-utils';
import { toTitleCase } from 'utils/string-utils';
import AgencyDropdownFilter from 'components/screening/common/filters/agency-dropdown';
import ProcessOwnerDropdownFilter from 'components/screening/common/filters/processOwner-dropdown';
import {
    ClearanceLevelType,
    clearanceStr,
    employeeStatusStr,
    ScreeningParentStateType,
    ScreeningPaths,
    PublicTrustParentStateToChildrenMap,
    StateName,
    PublicTrustScreeningStateLabels,
    USGovParentStateToChildrenMap,
} from 'components/screening/common/common-constants';
import {
    filterOnParentState,
    getFilterfunction,
    IsCheckboxChecked,
    isUserContractOwnerOrNST,
    updateFilterContext,
} from 'components/screening/common/filters/filter-help';
import {
    filterMargin,
    filterMarginMarginTop10,
    separatorStyles,
    stackTokensChildSpace,
} from 'components/screening/common/filters/common-filter-styling';
import RequestTypeFilter from 'components/screening/common/filters/request-type-filter';
import DeclinedStatusFilter from 'components/screening/common/filters/declined-status-filter';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { IContract } from 'components/screening/us-gov/IContract';
import { ScreeningPageNames } from 'components/common/constants';

interface CandidateFilterProps {
    rawCandidates: ICommonScreening[];
    hierarchyRecords: Dictionary<IHierarchy> | undefined;
    showCounts: boolean;
    employeeNames: Map<string, IEmployeeNameAndType>;
    contracts: Dictionary<IContract>;
    employeeIdsWithFutureTerminationDates: string[];
    pageName: string;
}

export function CandidateFilter(props: CandidateFilterProps): JSX.Element {
    const filtersContext = useContext(FiltersContext);
    const userContext = useContext(UserContext);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [selectedParentStates, setSelectedParentStates] = useState<ScreeningParentStateType[]>(
        [],
    );
    const [selectedChildStates, setSelectedChildStates] = useState<StateName[]>([]);

    useEffect(() => {
        if (filtersContext.filterCleared) {
            setSelectedChildStates([]);
            setSelectedParentStates([]);
        }
    }, [filtersContext.filterCleared]);

    function changeDate(isStart: boolean, date: Date | null | undefined): void {
        const finalDate = date || undefined;
        const filterFunction = getFilterfunction(isStart, finalDate, startDate, endDate, false);
        if (isStart) {
            setStartDate(finalDate);
        } else {
            setEndDate(finalDate);
        }
        filtersContext.setStatusChanged(`${filterContextKeys.statusChangedKey}${Date.now()}`);
        filtersContext.updateFilterFunctions(
            filterContextKeys.statusChangedKey,
            filterFunction,
            true,
        );
    }
    // end of for 'Last Status Change' filter helper functions

    // function getStatusFilterOptions(): IMultiChoiceFilterItem[] {
    //     const filterItems: IMultiChoiceFilterItem[] = [];

    //     for (const parentStateString in ScreeningParentStateType) {
    //         const parentState = getEnumFromString(ScreeningParentStateType, parentStateString);
    //         if (!parentState) {
    //             continue;
    //         }

    //         const childStates: ScreeningStateType[] =
    //             ScreeningParentStateToChildrenMap[parentState];

    //         filterItems.push({
    //             key: parentState,
    //             label: parentStateString,
    //             isChecked: selectedParentStates.includes(parentState),
    //             filterFunction(data: ICommonScreening): boolean {
    //                 return filterOnParentState(data, parentState);
    //             },
    //             generateLabel(dataArray: ICommonScreening[]): string {
    //                 return labelFunction(
    //                     this.label,
    //                     dataArray,
    //                     this.filterFunction,
    //                     props.showCounts,
    //                 );
    //             },
    //             onChange(
    //                 ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    //                 checked?: boolean,
    //             ): void {
    //                 const isCurrentlySelected: boolean = selectedParentStates.includes(parentState);

    //                 if (checked && !isCurrentlySelected) {
    //                     setSelectedParentStates((arr) => [...arr, parentState]);
    //                     setSelectedChildStates((arr) => [...arr, ...childStates]);
    //                 } else if (!checked && isCurrentlySelected) {
    //                     setSelectedParentStates((arr) => arr.filter((x) => x != parentState));
    //                     setSelectedChildStates((arr) =>
    //                         arr.filter((x) => !childStates.includes(x)),
    //                     );
    //                 }
    //             },
    //         });

    //         if (selectedParentStates.includes(parentState)) {
    //             for (const childState of childStates) {
    //                 filterItems.push({
    //                     key: parentState + '.' + childState,
    //                     label: ScreeningStateLabels[childState],
    //                     isChecked: selectedChildStates.includes(childState),
    //                     filterFunction(data: ICommonScreening): boolean {
    //                         return filterOnChildState(data, childState);
    //                     },
    //                     generateLabel(dataArray: ICommonScreening[]): string {
    //                         return labelFunction(
    //                             this.label,
    //                             dataArray,
    //                             this.filterFunction,
    //                             props.showCounts,
    //                         );
    //                     },
    //                     styles: { root: { paddingLeft: '20px' } },
    //                     onChange(
    //                         ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    //                         checked?: boolean,
    //                     ): void {
    //                         const isCurrentlySelected: boolean = selectedChildStates.includes(
    //                             childState,
    //                         );

    //                         if (checked && !isCurrentlySelected) {
    //                             setSelectedChildStates((arr) => [...arr, childState]);
    //                         } else if (!checked && isCurrentlySelected) {
    //                             setSelectedChildStates((arr) => arr.filter((x) => x != childState));
    //                         }
    //                     },
    //                 });
    //             }
    //         }
    //     }

    //     return filterItems;
    // }

    function getStatusFilterOptions(): IMultiChoiceFilterItem[] {
        const filterItems: IMultiChoiceFilterItem[] = [];

        for (const parentStateString in ScreeningParentStateType) {
            const parentState = getEnumFromString(ScreeningParentStateType, parentStateString);
            if (!parentState) {
                continue;
            }
            const childStates: StateName[] = USGovParentStateToChildrenMap[parentState];

            filterItems.push({
                key: parentState,
                label: parentStateString,
                isChecked: selectedParentStates.includes(parentState),
                filterFunction(data: ICommonScreening): boolean {
                    if (parentStateString === ScreeningParentStateType.Completed) {
                        return data.stateName === StateName.Completed;
                    } else if (parentStateString === ScreeningParentStateType.Withdrawn) {
                        return data.stateName === StateName.Withdrawn;
                    }
                    return filterOnParentState(data, childStates);
                },
                generateLabel(dataArray: ICommonScreening[]): string {
                    return labelFunction(
                        this.label,
                        dataArray,
                        this.filterFunction,
                        props.showCounts,
                    );
                },
                onChange(
                    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                    checked?: boolean,
                ): void {
                    const isCurrentlySelected: boolean = selectedParentStates.includes(parentState);

                    if (checked && !isCurrentlySelected) {
                        setSelectedParentStates((arr) => [...arr, parentState]);
                        setSelectedChildStates((arr) => [...arr, ...childStates]);
                    } else if (!checked && isCurrentlySelected) {
                        setSelectedParentStates((arr) => arr.filter((x) => x !== parentState));
                        setSelectedChildStates((arr) =>
                            arr.filter((x) => !childStates.includes(x)),
                        );
                    }
                },
            });

            if (selectedParentStates.includes(parentState)) {
                for (const childState of childStates) {
                    filterItems.push({
                        key: parentState + '.' + childState,
                        label: PublicTrustScreeningStateLabels[childState],
                        isChecked: selectedChildStates.includes(childState),
                        filterFunction(data: ICommonScreening): boolean {
                            return filterOnChildState(data, childState);
                        },
                        generateLabel(dataArray: ICommonScreening[]): string {
                            return labelFunction(
                                this.label,
                                dataArray,
                                this.filterFunction,
                                props.showCounts,
                            );
                        },
                        styles: { root: { paddingLeft: '20px' } },
                        onChange(
                            ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                            checked?: boolean,
                        ): void {
                            const isCurrentlySelected: boolean = selectedChildStates.includes(
                                childState,
                            );

                            if (checked && !isCurrentlySelected) {
                                setSelectedChildStates((arr) => [...arr, childState]);
                            } else if (!checked && isCurrentlySelected) {
                                setSelectedChildStates((arr) =>
                                    arr.filter((x) => x !== childState),
                                );
                            }
                        },
                    });
                }
            }
        }

        return filterItems;
    }

    const clearanceOptions: IMultiChoiceFilterItem[] = [
        {
            label: ClearanceLevelType.S,
            isChecked: IsCheckboxChecked(filtersContext, clearanceStr, ClearanceLevelType.S),
            filterFunction(data: ICommonScreening): boolean {
                if (data.clearance) {
                    return ClearanceLevelType.S === data.clearance.toUpperCase();
                }
                return false;
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: ClearanceLevelType.TS,
            isChecked: IsCheckboxChecked(filtersContext, clearanceStr, ClearanceLevelType.TS),
            filterFunction(data: ICommonScreening): boolean {
                if (data.clearance) {
                    return ClearanceLevelType.TS === data.clearance.toUpperCase();
                }
                return false;
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: ClearanceLevelType.SCI,
            isChecked: IsCheckboxChecked(filtersContext, clearanceStr, ClearanceLevelType.SCI),
            filterFunction(data: ICommonScreening): boolean {
                if (data.clearance) {
                    return ClearanceLevelType.SCI === data.clearance.toUpperCase();
                }
                return false;
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: ClearanceLevelType.SAP,
            isChecked: IsCheckboxChecked(filtersContext, clearanceStr, ClearanceLevelType.SAP),
            filterFunction(data: ICommonScreening): boolean {
                if (data.clearance) {
                    return ClearanceLevelType.SAP === data.clearance.toUpperCase();
                }
                return false;
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
    ];

    function getEmployeeStatusOptions(): IMultiChoiceFilterItem[] {
        const filterItems: IMultiChoiceFilterItem[] = [];

        filterItems.push(
            {
                label: toTitleCase(PersonnelTypes.PreHire),
                isChecked: IsCheckboxChecked(
                    filtersContext,
                    employeeStatusStr,
                    toTitleCase(PersonnelTypes.PreHire),
                ),
                filterFunction(data: ICommonScreening): boolean {
                    return (
                        props.employeeNames.get(data.personnelId)?.type === PersonnelTypes.PreHire
                    );
                },
                generateLabel(dataArray: ICommonScreening[]): string {
                    return labelFunction(
                        this.label,
                        dataArray,
                        this.filterFunction,
                        props.showCounts,
                    );
                },
            },
            {
                label: toTitleCase(PersonnelTypes.Active),
                isChecked: IsCheckboxChecked(
                    filtersContext,
                    employeeStatusStr,
                    toTitleCase(PersonnelTypes.Active),
                ),
                filterFunction(data: ICommonScreening): boolean {
                    return (
                        props.employeeNames.get(data.personnelId)?.type === PersonnelTypes.Active
                    );
                },
                generateLabel(dataArray: ICommonScreening[]): string {
                    return labelFunction(
                        this.label,
                        dataArray,
                        this.filterFunction,
                        props.showCounts,
                    );
                },
            },
        );

        if (
            props.pageName === ScreeningPageNames.MyContracts ||
            props.pageName === ScreeningPageNames.Manage
        ) {
            filterItems.push({
                label: toTitleCase(PersonnelTypes.FutureTermination),
                isChecked: IsCheckboxChecked(
                    filtersContext,
                    employeeStatusStr,
                    toTitleCase(PersonnelTypes.FutureTermination),
                ),
                filterFunction(data: ICommonScreening): boolean {
                    return (
                        props.employeeIdsWithFutureTerminationDates.findIndex(
                            (employeeId) => employeeId === data.personnelId,
                        ) !== -1
                    );
                },
                generateLabel(dataArray: ICommonScreening[]): string {
                    return !props.showCounts
                        ? this.label
                        : this.label +
                              ' (' +
                              dataArray.filter((screeningData) =>
                                  props.employeeIdsWithFutureTerminationDates.find(
                                      (employeeId) => employeeId === screeningData.personnelId,
                                  ),
                              ).length +
                              ')';
                },
                styles: { root: { paddingLeft: '20px' } },
            });
        }

        filterItems.push({
            label: toTitleCase(PersonnelTypes.Terminated),
            isChecked: IsCheckboxChecked(
                filtersContext,
                employeeStatusStr,
                toTitleCase(PersonnelTypes.Terminated),
            ),
            filterFunction(data: ICommonScreening): boolean {
                return (
                    props.employeeNames.get(data.personnelId)?.type === PersonnelTypes.Terminated
                );
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        });

        return filterItems;
    }

    useEffect(() => {
        const newFilterFunctions: {
            func: (arg: ICommonScreening) => boolean;
            undefined: undefined;
        }[] = [];
        for (const parentState of selectedParentStates) {
            if (parentState === ScreeningParentStateType.Completed) {
                const func = (screening: ICommonScreening): boolean =>
                    screening.stateName === StateName.Completed;

                newFilterFunctions.push({ func, undefined });
            } else if (parentState === ScreeningParentStateType.Withdrawn) {
                const func = (screening: ICommonScreening): boolean =>
                    screening.stateName === StateName.Withdrawn;

                newFilterFunctions.push({ func, undefined });
            } else {
                const childStates = selectedChildStates.filter((x) =>
                    PublicTrustParentStateToChildrenMap[parentState]?.includes(x),
                );
                if (childStates.length === 0) {
                    const func = (screening: ICommonScreening): boolean =>
                        filterOnParentState(screening, childStates);

                    newFilterFunctions.push({ func, undefined });
                } else {
                    for (const childState of childStates) {
                        const func = (screening: ICommonScreening): boolean =>
                            filterOnChildState(screening, childState);

                        newFilterFunctions.push({ func, undefined });
                    }
                }
            }
        }

        filtersContext.setFilterFunctions(filterContextKeys.status, newFilterFunctions);
    }, [selectedParentStates, selectedChildStates]);

    return (
        <div>
            <Separator styles={separatorStyles} alignContent='start'>
                Screenings
                {props.showCounts && props.rawCandidates && props.rawCandidates.length > 0
                    ? ' (' + props.rawCandidates.length + ')'
                    : ''}
            </Separator>
            <Stack tokens={stackTokensChildSpace} styles={filterMarginMarginTop10}>
                <ContractDropdownFilter
                    screeningPath={ScreeningPaths.UsGov}
                    rawCandidates={props.rawCandidates}
                    contracts={props.contracts}
                    onFilterStateChange={(selectedContracts: string[], adding: boolean): void => {
                        filtersContext.updateFilterFunctions(
                            filterContextKeys.contractIdKey,
                            (data: ICommonScreening): boolean => {
                                if (data?.contractId && selectedContracts.length > 0) {
                                    return selectedContracts.includes(data.contractId);
                                } else if (selectedContracts.length === 0) {
                                    // nothing to filter
                                    return true;
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                {isUserContractOwnerOrNST(userContext, ScreeningPaths.UsGov) && (
                    <AgencyDropdownFilter
                        contracts={props.contracts}
                        placeHolder='Customer'
                        rawCandidates={props.rawCandidates}
                        onFilterStateChange={(
                            selectedAgencies: string[],
                            adding: boolean,
                        ): void => {
                            filtersContext.updateFilterFunctions(
                                filterContextKeys.agencyIdKey,
                                (data: ICommonScreening): boolean => {
                                    const customer = props.contracts[data.contractId]?.customer;
                                    if (customer && selectedAgencies.length > 0) {
                                        return selectedAgencies.includes(customer);
                                    } else if (selectedAgencies.length === 0) {
                                        // nothing to filter
                                        return true;
                                    }
                                    return false;
                                },
                                adding,
                            );
                        }}
                    />
                )}
                <EmployeePickerFilter
                    onFilterStateChange={(value: string, adding: boolean): void => {
                        filtersContext.updateFilterFunctions(
                            filterContextKeys.personnelIdKey,
                            (data: ICommonScreening): boolean => {
                                if (data?.personnelId) {
                                    return value === data.personnelId;
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                <ProcessOwnerDropdownFilter
                    rawCandidates={props.rawCandidates}
                    onFilterStateChange={(
                        processOwnerSelectedArr: string[],
                        adding: boolean,
                    ): void => {
                        filtersContext.updateFilterFunctions(
                            filterContextKeys.processOwnerIdKey,
                            (data: ICommonScreening): boolean => {
                                if (processOwnerSelectedArr.includes(noDataText)) {
                                    if (data?.processOwnerId) {
                                        // if there is data for the process owner then we need to check if the data is selected in the
                                        // processOwnerSelectedArr
                                        return processOwnerSelectedArr.includes(
                                            data.processOwnerId,
                                        );
                                    } else {
                                        // if data.processOwner is null or undefined then just return it cause it will show as n/a
                                        return true;
                                    }
                                } else if (
                                    data?.processOwnerId &&
                                    processOwnerSelectedArr.length > 0
                                ) {
                                    return processOwnerSelectedArr.includes(data.processOwnerId);
                                } else if (processOwnerSelectedArr.length === 0) {
                                    return true;
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                <PcnPickerFilter
                    rawCandidates={props.rawCandidates}
                    onFilterStateChange={(value: string, adding: boolean): void => {
                        filtersContext.updateFilterFunctions(
                            filterContextKeys.firstNameKey,
                            (data: ICommonScreening): boolean => {
                                if (data?.preHire?.firstName) {
                                    return value === data.preHire.firstName;
                                }
                                return false;
                            },
                            adding,
                        );
                        filtersContext.updateFilterFunctions(
                            filterContextKeys.lastNameKey,
                            (data: ICommonScreening): boolean => {
                                if (data?.preHire?.lastName) {
                                    return value === data.preHire.lastName;
                                }
                                return false;
                            },
                            adding,
                        );
                        filtersContext.updateFilterFunctions(
                            filterContextKeys.pcnKey,
                            (data: ICommonScreening): boolean => {
                                if (data?.preHire?.pcn) {
                                    return value === data.preHire.pcn;
                                } else if (data?.preHire?.firstName && data?.preHire?.lastName) {
                                    return (
                                        value === data.preHire.firstName ||
                                        value === data.preHire.lastName
                                    );
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                {isUserContractOwnerOrNST(userContext, ScreeningPaths.UsGov) && (
                    <>
                        <OrgLeaderPicker
                            hierarchyRecords={props.hierarchyRecords}
                            onFilterStateChange={(
                                value: string,
                                adding: boolean,
                                level: hierarchyLvlsAlias,
                            ): void => {
                                filtersContext.updateFilterFunctions(
                                    filterContextKeys.orgLeaderAliasKey,
                                    (data: ICommonScreening): boolean => {
                                        if (props.hierarchyRecords) {
                                            const hierarchyRecord =
                                                props.hierarchyRecords[data.personnelId];
                                            if (hierarchyRecord && hierarchyRecord[level]) {
                                                return value === hierarchyRecord[level];
                                            }
                                        }
                                        return false;
                                    },
                                    adding,
                                );
                            }}
                        />
                        <ReportsToPicker
                            onFilterStateChange={(value: string, adding: boolean): void => {
                                filtersContext.updateFilterFunctions(
                                    filterContextKeys.reportsToKey,
                                    (data: ICommonScreening): boolean => {
                                        if (data?.personnelId && props.hierarchyRecords) {
                                            const reportTo =
                                                props.hierarchyRecords[data.personnelId];
                                            return reportTo
                                                ? value === getReportToFromHierarchy(reportTo)
                                                : false;
                                        }
                                        return false;
                                    },
                                    adding,
                                );
                            }}
                        />
                    </>
                )}
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Status
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Status Group'>
                <MultiChoiceFilter
                    unfilteredData={props.rawCandidates}
                    filterItems={getStatusFilterOptions()}
                />
            </Stack>
            <DeclinedStatusFilter
                filterContext={filtersContext}
                rawCandidates={props.rawCandidates}
                showCounts
            />
            <Separator styles={separatorStyles} alignContent='start'>
                Clearance Level
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Clearance Level Group'>
                <MultiChoiceFilter
                    unfilteredData={props.rawCandidates}
                    filterItems={clearanceOptions}
                    onChildStateChange={(value: IMultiChoiceFilterItem, adding: boolean): void => {
                        updateFilterContext(
                            value,
                            adding,
                            clearanceStr,
                            filtersContext.setClearance,
                            filtersContext,
                            ScreeningPaths.UsGov,
                        );
                    }}
                />
            </Stack>
            <RequestTypeFilter
                rawCandidates={props.rawCandidates}
                filterContext={filtersContext}
                showCounts
                screeningPath={ScreeningPaths.UsGov}
            />
            <Separator styles={separatorStyles} alignContent='start'>
                Employee Status
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Employee Type Group'>
                <MultiChoiceFilter
                    unfilteredData={props.rawCandidates}
                    filterItems={getEmployeeStatusOptions()}
                    onChildStateChange={(value: IMultiChoiceFilterItem, adding: boolean): void => {
                        updateFilterContext(
                            value,
                            adding,
                            employeeStatusStr,
                            filtersContext.setEmployeeStatus,
                            filtersContext,
                            ScreeningPaths.UsGov,
                        );
                    }}
                />
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Last Status Change
            </Separator>
            <DateTimeStartEndPicker
                startDate={startDate}
                endDate={endDate}
                changeDate={(isStart: boolean, date: Date | null | undefined): void =>
                    changeDate(isStart, date)
                }
                filterCleared={filtersContext.filterCleared}
            />
            <Stack.Item styles={{ root: { display: 'flex', flexDirection: 'row-reverse' } }}>
                <ClearFiltersActionButton clearFunc={filtersContext.clearFilters} />
            </Stack.Item>
        </div>
    );
}

function filterOnChildState(screening: ICommonScreening, state: StateName): boolean {
    return screening.stateName === state;
}

function labelFunction(
    label: string,
    dataArray: ICommonScreening[],
    filterFunction: (data: ICommonScreening) => boolean,
    showCount: boolean,
): string {
    let retLabel = label;
    if (showCount) {
        let count = 0;
        if (Array.isArray(dataArray)) {
            count = dataArray.filter((data) => {
                return filterFunction(data);
            }).length;
        }
        retLabel = retLabel + ' (' + count + ')';
    }
    return retLabel;
}
