import config from 'environments/environment';
import { IAuthContext } from 'contexts/auth-context';
import { IEmployee } from 'clients/employee-client';
import { GetDefaultHttpOptions, HttpContentTypeEnum, IPagedResults } from 'clients/http-options';
import {
    SuitabilityAgencies,
    SuitabilityLevels,
    SuitabilityLevelsV2,
    SuitabilityStatuses,
    SuitabilityTypes,
} from 'components/personnel-profile/suitability/profile-suitability-types';
import { ContinuousEvaluationTypes } from 'components/personnel-profile/common/common-types';
import { IFiltersSuitabilityRecords } from 'contexts/filters-suitability-records';
import { UploadStatusEnum } from 'clients/screening/public-trust-screening-client';

const { suitabilityServiceConfig } = config;

export function isISuitabilityRecord(object: any): object is ISuitabilityRecord {
    if (!object) {
        return false;
    }
    return (object as ISuitabilityRecord).suitabilityLevel !== undefined;
}

export class SuitabilityClient {
    static async getSuitabilitiesForEmployee(
        authContext: IAuthContext,
        employee: IEmployee,
        continuationToken?: string,
    ): Promise<IPagedResults<ISuitabilityRecord>> {
        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        httpOptions.method = 'GET';

        const { baseUrl, suitabilitiesEndpoint } = suitabilityServiceConfig;
        const url = baseUrl + suitabilitiesEndpoint.replace('{id}', employee.id);

        const res = await fetch(url, httpOptions);

        if (res.status === 200) {
            const ret = await res.json();
            return ret;
        } else {
            throw res;
        }
    }

    static async deleteSuitability(authContext: IAuthContext, id: string): Promise<Response> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';

        const { baseUrl, suitabilitiesDeleteEndpoint } = suitabilityServiceConfig;
        const url = baseUrl + suitabilitiesDeleteEndpoint.replace('{id}', id);

        const res = await fetch(url, httpOptions);
        if (res.status === 200 || res.status === 500) {
            return res;
        }
        throw res;
    }

    static async upsertSuitability(
        authContext: IAuthContext,
        suitability: ISuitabilityRecord,
    ): Promise<ISuitabilityRecord[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        // Validate=false flag added for card - https://msazure.visualstudio.com/Microsoft%20Personnel/_boards/board/t/Screening/Stories/?workitem=10073448
        const urlParams = new URLSearchParams();
        urlParams.append('validate', 'false');
        const { baseUrl, suitabilitiesUpsertEndpoint } = suitabilityServiceConfig;
        const url = `${baseUrl}${suitabilitiesUpsertEndpoint}?${urlParams.toString()}`;

        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify([suitability]);
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return await response.json();
        } else {
            throw await response.text();
        }
    }

    static async getSuitabilityReportForEmployee(
        authContext: IAuthContext,
        employeeId: string,
        continuationToken?: string,
    ): Promise<string> {
        const httpOptions = await GetDefaultHttpOptions(
            authContext,
            continuationToken,
            HttpContentTypeEnum.TextPlain,
        );
        const { baseUrl, suitabilitiesDownloadReportEndpoint } = suitabilityServiceConfig;
        const url = baseUrl + suitabilitiesDownloadReportEndpoint.replace('{id}', employeeId);

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return await response.json();
        } else {
            throw await response.text();
        }
    }

    static async getSuitabilityRecordsByStatuses(
        authContext: IAuthContext,
        filter: IFiltersSuitabilityRecords,
        continuationToken?: string,
    ): Promise<ISuitabilityRecordResult> {
        const urlParams = new URLSearchParams();
        if (filter.status) {
            urlParams.append('userType', filter.status);
        }
        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        const { baseUrl, statusesEndpoint } = suitabilityServiceConfig;
        const url = baseUrl + statusesEndpoint;

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return await response.json();
        } else {
            throw await response.text();
        }
    }

    static async getSuitabilityReport(
        authContext: IAuthContext,
        suitabilities: ISuitabilityRecord[],
    ): Promise<string> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const { baseUrl, suitabilityRecordsExcelDataEndpoint } = suitabilityServiceConfig;
        const url = baseUrl + suitabilityRecordsExcelDataEndpoint;

        const suitabilityIds = suitabilities.map((suitability) => suitability.id);

        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(suitabilityIds);
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return await response.json();
        } else {
            throw await response.text();
        }
    }

    static async getSuitabilityHoldersReport(
        authContext: IAuthContext,
        suitabilities: ISuitabilityRecord[],
    ): Promise<string> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const { baseUrl, suitabilityHolderExcelDataEndpoint } = suitabilityServiceConfig;
        const url = baseUrl + suitabilityHolderExcelDataEndpoint;

        const personnelIds = suitabilities.map((suitability) => suitability.personnelId);
        const uniquePersonnelIds = personnelIds.filter(
            (personnelId, index) => personnelIds.indexOf(personnelId) === index,
        );

        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(uniquePersonnelIds);
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return await response.json();
        } else {
            throw await response.text();
        }
    }

    static async uploadSuitabilityRecords(
        authContext: IAuthContext,
        request: ISuitabilityUploadRequest[],
    ): Promise<string | undefined> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const { baseUrl, suitabilityUploadEndpoint } = suitabilityServiceConfig;
        const url = baseUrl + suitabilityUploadEndpoint;

        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(request);

        const result = await fetch(url, httpOptions);

        switch (result.status) {
            case 200:
                return result.json();
            case 204:
                return;
            default:
                const text = await result.text();
                console.error(text);
                let json;
                try {
                    json = JSON.parse(text);
                } catch {}
                throw new Error(json?.message || result);
        }
    }
}

export interface ISuitabilityRecord {
    id: string;
    personnelId: string;
    suitabilityLevel: SuitabilityLevels;
    requestingAgency: SuitabilityAgencies;
    status: SuitabilityStatuses;
    suitabilityType: SuitabilityTypes;
    suitabilityBasis: string;
    investigationDateUTCMilliseconds: number;
    grantDateUTCMilliseconds: number;
    briefDateUTCMilliseconds: number;
    nextBriefDateUTCMilliseconds: number;
    ndaSignedDateUTCMilliseconds: number;
    debriefDateUTCMilliseconds: number;
    debriefJustification: string;
    contractId: string;
    continuousEvaluationType: ContinuousEvaluationTypes;
    continuousEvaluationEnrollmentDateUTCMilliseconds: number;
    continuousEvaluationUnenrollmentDateUTCMilliseconds: number;
    originalData: {
        additionalProp1: string;
        additionalProp2: string;
        additionalProp3: string;
    };
    lastModified?: {
        by: string;
        atUtc: number;
    };
    creationDateTimeUTCMilliseconds?: number;
    suitabilityExpirationDateTimeUTCMilliseconds?: number;
}

export interface ISuitabilityRecordResult {
    results: ISuitabilityRecord[];
    continuationToken?: string;
}

export enum UploadSuitabilityColumns {
    id = 'Record id',
    currentMicrosoftFTE = 'Current microsoft fte',
    personnelId = 'Personnel id',
    microsoftAlias = 'Microsoft alias',
    firstName = 'First name',
    lastName = 'Last name',
    suitabilityLevel = 'Suitability level',
    agency = 'Agency',
    suitabilityStatus = 'Status',
    suitabilityType = 'Suitability type',
    suitabilityBasis = 'Suitability basis',
    investigatedOn = 'Investigated on',
    grantedOn = 'Granted on',
    briefedOn = 'Briefed on',
    nextBriefedOn = 'Next briefed on',
    ndaSignedOn = 'NDA signed on',
    debriefedOn = 'Debriefed on',
    debriefJustification = 'Debrief justification',
    contract = 'Contract',
    continuousEvaluationType = 'Continuous evaluation type',
    ceEnrollmentDate = 'CE enrollment date',
    ceUnenrollmentDate = 'CE unenrollment date',
    uploadStatus = 'Upload status',
}

// This type can be replaced with type BulkUploadResult from upload-utils.
export type UploadSuitabilityRecordResult = {
    // This record has many more fields, but at this moment (Jun,15,2023)
    // the code only needs to access "Upload status".
    [UploadSuitabilityColumns.uploadStatus]: string;
};

export interface ISuitabilityUploadRequest {
    id?: string;
    personnelId: string;
    suitabilityLevel: SuitabilityLevelsV2;
    requestingAgency: SuitabilityAgencies;
    status: SuitabilityStatuses;
    suitabilityType: SuitabilityTypes;
    suitabilityBasis: string;
    investigationDateUTCMilliseconds?: number;
    grantDateUTCMilliseconds?: number;
    briefDateUTCMilliseconds?: number;
    nextBriefDateUTCMilliseconds?: number;
    ndaSignedDateUTCMilliseconds?: number;
    debriefDateUTCMilliseconds?: number;
    debriefJustification?: string;
    contractId: string;
    continuousEvaluationType?: ContinuousEvaluationTypes;
    continuousEvaluationEnrollmentDateUTCMilliseconds?: number;
    continuousEvaluationUnenrollmentDateUTCMilliseconds?: number;
    uploadStatus?: UploadStatusEnum;
}
