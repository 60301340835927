import { useState, useEffect } from 'react';

export interface IWindowSize {
    width: number;
    height: number;
}

export function useWindowSize(): IWindowSize {
    const [windowSize, setWindowSize] = useState<IWindowSize>({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        function resize() {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        }
        resize();
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize);
    }, []);
    return windowSize;
}
