import { mergeStyleSets, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import CloudScreeningClient, { ICloudScreeningMonthlyReport } from 'clients/cloud-screening-client';
import { CloudScreeningUserType } from 'utils/cloud-screening-utils';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { generalIsMountedCode, useQuery } from 'utils/misc-utils';

export interface ICloudScreeningMonthlyReportPage {
    pageName: string;
}

export function CloudScreeningMonthlyReport(props: ICloudScreeningMonthlyReportPage): JSX.Element {
    const query = useQuery();

    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    const [monthlyReport, setMonthlyReport] = useState<ICloudScreeningMonthlyReport | undefined>();
    const [dateParam, setDateParam] = useState<Date>();
    const [errorMessage, setErrorMessage] = useState<string>();

    function validateDateURLParams(): Date | undefined {
        const monthParam = query.get('month'),
            yearParam = query.get('year');
        if (!monthParam || !yearParam) {
            setErrorMessage('Date parameters are missing from URL.');
            return;
        }

        const month = +monthParam,
            year = +yearParam;
        if (isNaN(month) || isNaN(year)) {
            setErrorMessage('Invalid date parameter present in URL.');
            return;
        }

        return new Date(year, month - 1, 1, 0, 0, 0, 0);
    }

    useEffect(() => {
        return generalIsMountedCode(async (isMountedFunc: () => boolean) => {
            if (dateParam) {
                const monthlyReportResult: ICloudScreeningMonthlyReport = await CloudScreeningClient.getMonthlyReport(
                    authContext,
                    dateParam,
                );
                if (isMountedFunc()) {
                    setMonthlyReport(monthlyReportResult);
                }
            }
        });
    }, [dateParam]);

    useEffect(() => {
        function setupDateParams(): void {
            const date = validateDateURLParams();
            if (!date) return;
            setDateParam(date);
        }

        setupDateParams();
    }, []);

    return userContext.hasCloudScreeningUserType(CloudScreeningUserType.CloudSRT) ||
        userContext.hasCloudScreeningUserType(CloudScreeningUserType.Admin) ? (
        <>
            {errorMessage && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    overflowButtonAriaLabel='See more'>
                    {errorMessage}
                </MessageBar>
            )}
            {dateParam && (
                <Stack className={styles.mainContainer}>
                    <Stack horizontal>
                        <Stack.Item>
                            <h2>
                                Cloud Screening Monthly Report -{' '}
                                {`${dateParam.getMonth() + 1}/${dateParam.getFullYear()}`}
                            </h2>
                        </Stack.Item>
                    </Stack>

                    {monthlyReport && (
                        <div>
                            <Stack.Item>
                                <div>
                                    <table className={styles.tableStyle}>
                                        <thead>
                                            <tr>
                                                <th>Azure Region</th>
                                                <th>Request Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {monthlyReport.items.map(
                                                (item, i): JSX.Element => {
                                                    return (
                                                        <tr key={`table_content_${i}`}>
                                                            <td>{item.region}</td>
                                                            <td>{item.count}</td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Stack.Item>
                        </div>
                    )}
                </Stack>
            )}
        </>
    ) : (
        <></>
    );
}

const styles = mergeStyleSets({
    tableStyle: {
        borderCollapse: 'collapse',
        border: '1px solid black',
        width: '100%',
        selectors: {
            'th,td': {
                border: '1px solid black',
                padding: '5px',
            },
            'th': {
                backgroundColor: '#000066',
                color: 'white',
            },
        },
    },
    mainContainer: {
        padding: '0 10px 0 10px',
    },
});
