import {
    IPersonaProps,
    PrimaryButton,
    DefaultButton,
    Panel,
    PanelType,
    Label,
    TextField,
    Dropdown,
    ChoiceGroup,
    Checkbox,
    Stack,
    IDropdownOption,
    mergeStyleSets,
    FontIcon,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import { FluentTheme } from '@fluentui/theme';
import {
    GetAttributeSetByIdResult,
    GetAttributeResult,
    AttributeDataType,
    AttributeVisibilityLevel,
    CreateAttributeRequest,
    EditAttributeRequest,
    CreateAttributeResponse,
    EditAttributeResponse,
    GetDataSourceFieldsResult,
} from 'personnel-core-clients';
import {
    CorePrincipalsClient,
    CoreAttributesClient,
} from 'clients/core/personnel-core-client-wrappers';
import { CoreMultiOidFromGraphPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useState, useEffect } from 'react';
import { AttributeNameRegex } from 'utils/cor-utils';
import { AttributeModalType } from 'components/core/attributes/add-edit-attribute';
import { newGuid } from '@microsoft/applicationinsights-core-js';
import { useBoolean } from '@fluentui/react-hooks';
import { isCsaSyncEnabled } from 'components/core/common/attribute-utils';
import LabelInfoIcon from 'components/common/use-input/info-icon-label';
import { IconNames } from 'assets/constants/global-constants';
import Spacer from 'components/common/spacer';
import { IGraphPrincipal, isGraphServicePrincipal } from 'clients/graph-client';

export interface IAttributeMetadataPanelProps {
    isOpen?: boolean;
    modalType: AttributeModalType;
    attributeSet?: GetAttributeSetByIdResult | undefined;
    initAttribute?: GetAttributeResult | undefined;
    initAttributes?: GetAttributeResult[] | undefined;
    autoAssignedDataSourceFields?: GetDataSourceFieldsResult | undefined;
    isAdmin?: boolean;
    onSuccess: (attribute: CreateAttributeResponse | EditAttributeResponse) => void;
    onDismiss: () => void;
}
export default function AttributeMetadataPanel(props: IAttributeMetadataPanelProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const [name, setName] = useState<string>();
    const [attributeId, setAttributeId] = useState<string>();
    const [shouldUseAutoID, { toggle: setUseAutoID }] = useBoolean(true);
    const [type, setType] = useState<string>(AttributeDataType.String);
    const [isManualAssigned, setIsManualAssigned] = useState<boolean>(true);
    const [isMultiValued, setIsMultiValued] = useState<boolean>(false);
    const [description, setDescription] = useState<string>();
    const [support, setSupport] = useState<string>();
    const [owners, setOwners] = useState<string[] | undefined>([]);
    const [managers, setManagers] = useState<string[] | undefined>([]);
    const [readers, setReaders] = useState<string[] | undefined>([]);
    const [ownerPrincipals, setOwnerPrincipals] = useState<IGraphPrincipal[]>();
    const [visibilityLevel, setVisibilityLevel] = useState<AttributeVisibilityLevel>(
        AttributeVisibilityLevel.Restricted,
    );
    const [isCsaEnabled, setCsaEnabled] = useState<boolean>(false);

    const [shouldUsePredefinedValues, setShouldUsePredefinedValues] = useState<boolean>(false);

    const [dataSourceOptions, setDataSourceOptions] = useState<
        IDropdownOption<any>[] | undefined
    >();
    const [autoAssignedDataSource, setAutoAssignedDataSource] = useState<string | undefined>(
        props.initAttribute?.autoAssignedDataSource,
    );
    const [dataFieldOptions, setDataFieldOptions] = useState<IDropdownOption<any>[] | undefined>();
    const [autoAssignedField, setAutoAssignedField] = useState<string | undefined>(
        props.initAttribute?.autoAssignedField,
    );

    const attributeSetId = props.attributeSet?.id ?? 'default';
    const attributeSetName = props.attributeSet?.name ?? 'Default';

    useEffect(() => {
        if (props.modalType === AttributeModalType.Edit) {
            const client = new CorePrincipalsClient(authContext);

            setName(props.initAttribute?.name);
            setAttributeId(props.initAttribute?.id);
            setDescription(props.initAttribute?.description);
            setVisibilityLevel(
                props.initAttribute?.visibilityLevel ?? AttributeVisibilityLevel.Restricted,
            );
            setType(props.initAttribute?.dataType ?? AttributeDataType.String);
            setSupport(props.initAttribute?.remediationSteps);
            setIsMultiValued(props.initAttribute?.isMultiValued ?? false);
            setIsManualAssigned(!props.initAttribute?.isAutoAssigned ?? false);
            setShouldUsePredefinedValues(props.initAttribute?.usePredefinedValues ?? false);
            setAutoAssignedDataSource(props.initAttribute?.autoAssignedDataSource);
            setAutoAssignedField(props.initAttribute?.autoAssignedField);
            setCsaEnabled(
                props.initAttribute?.csaSyncStatus.status !== undefined
                    ? isCsaSyncEnabled(props.initAttribute.csaSyncStatus.status)
                    : false,
            );
            setOwners(props.initAttribute?.owners);
            setManagers(props.initAttribute?.managers);
            setReaders(props.initAttribute?.readers);
        } else {
            resetFormFields();
        }
    }, [props.initAttribute, props.modalType]);

    const [showErrors, setShowErrors] = useState(false);
    const [error, setError] = useState<string>();
    const [isPrimaryDisabled, setIsPrimaryDisabled] = useState<boolean>(false);

    const EmployeePickerListLimit = 50;

    const isNameValid = (): boolean => {
        return (
            !!name &&
            name.length >= MinLens.name &&
            name.length <= MaxLens.name &&
            AttributeNameRegex.test(name)
        );
    };

    const isAttributeIdValid = (): boolean => {
        let attributeIds: string[] = [];
        if (props.initAttributes) {
            attributeIds = props.initAttributes.map((a) => a.id);
        }
        return (
            props.modalType !== AttributeModalType.Add || // In edit mode unique identifier will be valid as it cannot be updated
            (!!attributeId && !attributeIds.includes(attributeId) && !attributeId.match(/^[0-9]/g))
        );
    };

    const isDescriptionValid = (): boolean => {
        return (
            !!description &&
            description.length >= MinLens.description &&
            description.length <= MaxLens.description
        );
    };

    const isDataTypeValid = (): boolean => {
        return (
            type === AttributeDataType.Boolean ||
            type === AttributeDataType.Integer ||
            type === AttributeDataType.String ||
            type === AttributeDataType.Person
        );
    };

    const isVisibilityLevelValid = (): boolean => {
        return !!visibilityLevel;
    };

    const isAutoAssignedDataSourceValid = (): boolean => {
        return !isManualAssigned && !!autoAssignedDataSource;
    };

    const isAutoAssignedFieldValid = (): boolean => {
        return !isManualAssigned && !!autoAssignedField;
    };

    useEffect(() => {
        if (props.modalType === AttributeModalType.Add && shouldUseAutoID) {
            updateUniqueIdentifier();
        }
    }, [name, props.modalType]);

    useEffect(() => {
        updateUniqueIdentifier();
    }, [shouldUseAutoID]);

    useEffect(() => {
        if (props.autoAssignedDataSourceFields && !isManualAssigned) {
            const dataSourceOptions = props.autoAssignedDataSourceFields.dataSources
                .map((x) => {
                    return { key: x.name, text: x.displayName };
                })
                .sort((a, b) => a.text.localeCompare(b.text));

            setDataSourceOptions(dataSourceOptions);
            return;
        }

        setDataSourceOptions(undefined);
    }, [props.autoAssignedDataSourceFields, isManualAssigned]);

    useEffect(() => {
        if (props.autoAssignedDataSourceFields && !isManualAssigned && autoAssignedDataSource) {
            const dataSource = props.autoAssignedDataSourceFields.dataSources.find(
                (ds) => ds.name === autoAssignedDataSource,
            );

            if (dataSource) {
                const dataFieldOptions = dataSource.dataSourceFields
                    .map((x) => {
                        return {
                            key: x.name,
                            text: x.displayName,
                            disabled:
                                x.isInUse && x.name !== props.initAttribute?.autoAssignedField,
                            dataType: x.dataType,
                            isMultiValued: x.isMultiValued,
                        };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text));

                setDataFieldOptions(dataFieldOptions);

                const selectedField = dataFieldOptions.find(
                    (field) => field.key === autoAssignedField,
                );
                if (selectedField) {
                    setType(selectedField.dataType);
                    setIsMultiValued(selectedField.isMultiValued);
                }
                return;
            }
        }

        setDataFieldOptions(undefined);
    }, [
        props.autoAssignedDataSourceFields,
        isManualAssigned,
        autoAssignedDataSource,
        autoAssignedField,
    ]);

    const updateUniqueIdentifier = (): void => {
        if (!shouldUseAutoID) {
            let generatedId = newGuid();
            while (!generatedId.match(/^[a-zA-Z]/g)) {
                generatedId = newGuid();
            }
            generatedId = generatedId.replace(/[^a-zA-Z0-9]/g, '');
            setAttributeId(`${attributeSetId}_${generatedId}`);
        } else if (name) {
            const sanitizedName = name.replace(/[^a-zA-Z0-9]/g, '');
            setAttributeId(`${attributeSetId}_${sanitizedName}`);
        } else {
            setAttributeId(undefined);
        }
    };

    const buttonStyles = { root: { marginRight: 8 } };

    const isOwnersValid = (): boolean => {
        if (!owners) {
            return false;
        }

        const hasServicePrincipals = ownerPrincipals?.some((p) => isGraphServicePrincipal(p));
        if (ownerPrincipals === undefined || hasServicePrincipals) {
            return owners.length >= MinLens.servicePrincipalOwners;
        } else {
            return owners.length >= MinLens.userOwners;
        }
    };

    const onOwnersSelected = (oids?: string[], graphPrincipals?: IGraphPrincipal[]): void => {
        if (oids && graphPrincipals) {
            setOwners(oids);
            setOwnerPrincipals(graphPrincipals);
        }
    };

    const onManagersSelected = (info?: string[]): void => {
        if (info && info.length <= EmployeePickerListLimit) {
            setManagers(info);
        }
    };

    const onReadersSelected = (info?: string[]): void => {
        if (info && info.length <= EmployeePickerListLimit) {
            setReaders(info);
        }
    };

    const VisibilityLevelOptions = (Object.keys(AttributeVisibilityLevel) as Array<
        AttributeVisibilityLevel
    >).map((v) => {
        return { key: v, text: v.toString(), data: v };
    });

    const DataTypeOptions = (Object.keys(AttributeDataType) as Array<AttributeDataType>).map(
        (v) => {
            return { key: v, text: v.toString() };
        },
    );

    const isMultiValuedValid = (): boolean => {
        return isMultiValued !== undefined;
    };

    const validateAttributeFields = (): void => {
        if (
            !isNameValid() ||
            !isDescriptionValid() ||
            !isDataTypeValid() ||
            !isOwnersValid() ||
            !isMultiValuedValid() ||
            !isVisibilityLevelValid() ||
            !isAttributeIdValid()
        ) {
            throw new Error('Please check fields for errors and try again.');
        }

        // if auto assigned, check if data source and field are valid
        if (!isManualAssigned && (!autoAssignedDataSource || !autoAssignedField)) {
            throw new Error('If auto assigned, data source and data field must be selected.');
        }
    };

    const onClick = async (): Promise<void> => {
        try {
            setIsPrimaryDisabled(true);
            validateAttributeFields();
            const client = new CoreAttributesClient(authContext);
            const ownerOids = owners?.filter((o) => !!o);
            const managerOids = managers?.filter((o) => !!o);
            const readerOids = readers?.filter((o) => !!o);

            const attributeIdSplit = attributeId?.split('_');
            if (attributeIdSplit === undefined || attributeIdSplit.length < 2) {
                throw new Error(
                    'Attribute id is incorrectly formatted. Please provide a valid unique identifier and try again',
                );
            }

            const createAttributeRequest: CreateAttributeRequest = new CreateAttributeRequest({
                id: attributeIdSplit[1],
                attributeSetId: attributeSetId,
                name: name ?? '',
                description: description ?? '',
                owners: ownerOids!,
                managers: managerOids!,
                readers: readerOids!,
                visibilityLevel: visibilityLevel,
                isMultiValued: isMultiValued,
                remediationSteps: support,
                dataType: type! as AttributeDataType,
                usePredefinedValues: shouldUsePredefinedValues,
                isAutoAssigned: !isManualAssigned,
                autoAssignedDataSource: autoAssignedDataSource,
                autoAssignedField: autoAssignedField,
                csaEnabled: isCsaEnabled,
            });

            const editAttributeRequest: EditAttributeRequest = new EditAttributeRequest({
                name: name ?? '',
                description: description ?? '',
                owners: ownerOids!,
                managers: managerOids!,
                readers: readerOids!,
                visibilityLevel: visibilityLevel,
                remediationSteps: support,
                usePredefinedValues: shouldUsePredefinedValues,
                autoAssignedDataSource: autoAssignedDataSource,
                autoAssignedField: autoAssignedField,
                csaEnabled: isCsaEnabled,
            });

            try {
                const response =
                    props.modalType === AttributeModalType.Add
                        ? await client.create(createAttributeRequest)
                        : await client.edit(attributeId!, editAttributeRequest);

                onSuccess(response);
            } catch (e) {
                let errorJson;
                let status;
                try {
                    errorJson = JSON.parse(e.response);
                    status = errorJson.status;
                } catch (errorJson) {
                    throw new Error(
                        `An error occurred ${
                            props.modalType === AttributeModalType.Add ? 'creating' : 'saving'
                        } the attribute. Please try again.`,
                    );
                }

                if (status === 409) {
                    throw new Error(
                        'An attribute with that unique identifier already exists, generate a new secure ID and try again.',
                    );
                }

                throw new Error(
                    `An error occurred ${
                        props.modalType === AttributeModalType.Add ? 'creating' : 'saving'
                    } the attribute. Please try again.`,
                );
            }
        } catch (e) {
            setShowErrors(true);
            setError(e.message);
            setIsPrimaryDisabled(false);
        }
    };

    const onSuccess = async (
        attribute: CreateAttributeResponse | EditAttributeResponse,
    ): Promise<void> => {
        props.onSuccess(attribute);
        resetFormFields();
        setIsPrimaryDisabled(false);
    };

    const onCancel = (): void => {
        props.onDismiss();
        resetFormFields();
        setIsPrimaryDisabled(false);
    };
    const resetFormFields = (): void => {
        setName('');
        setAttributeId(undefined);
        setDescription('');
        setOwners([]);
        setManagers([]);
        setReaders([]);
        setOwnerPrincipals(undefined);
        setSupport('');
        setIsMultiValued(false);
        setType(AttributeDataType.String);
        setVisibilityLevel(AttributeVisibilityLevel.Restricted);
        setShowErrors(false);
        setError(undefined);
        setIsManualAssigned(true);
        setAutoAssignedDataSource(undefined);
        setAutoAssignedField(undefined);
    };

    const onRenderFooterContent = React.useCallback(
        () => (
            <Stack>
                {showErrors && (
                    <div>
                        <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
                        <Spacer marginTop={10} />
                    </div>
                )}
                <div>
                    <PrimaryButton
                        onClick={onClick}
                        styles={buttonStyles}
                        disabled={isPrimaryDisabled}>
                        {shouldUsePredefinedValues
                            ? 'Next'
                            : props.modalType === AttributeModalType.Add
                            ? 'Add'
                            : 'Save'}
                    </PrimaryButton>
                    <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
                </div>
            </Stack>
        ),
        [props.modalType, isPrimaryDisabled, shouldUsePredefinedValues, onClick, onCancel],
    );

    return (
        <Panel
            headerText={
                props.modalType === AttributeModalType.Add ? 'Add attribute' : 'Edit attribute'
            }
            isOpen={props.isOpen}
            closeButtonAriaLabel='Close'
            onRenderFooterContent={onRenderFooterContent}
            onDismiss={(): void => onCancel()}
            isLightDismiss
            onLightDismissClick={(): void => onCancel()}
            isFooterAtBottom={true}
            type={PanelType.custom}
            customWidth={'400px'}
            styles={styles.panel}>
            <Label style={styles.label}>Attribute set</Label>
            <TextField disabled={true} defaultValue={attributeSetName} ariaLabel='Attribute set' />
            <TextField
                label='Attribute name'
                required
                placeholder='New Attribute Name'
                maxLength={MaxLens.name}
                minLength={MinLens.name}
                validateOnFocusOut={true}
                value={name}
                onChange={(ev, newVal): void => {
                    setName(newVal);
                }}
                errorMessage={
                    showErrors && !isNameValid()
                        ? 'Attribute name must contain only letters and numbers (6-32 characters and cannot start with a number)'
                        : ''
                }
                styles={styles.element}
            />
            <TextField
                onRenderLabel={(): JSX.Element => {
                    return (
                        <LabelInfoIcon
                            iconHoverContent={'Assigned to each attribute as a permanent ID.'}
                            iconName={IconNames.Info}>
                            Unique identifier
                            <span style={{ color: FluentTheme.palette.red }}> *</span>
                        </LabelInfoIcon>
                    );
                }}
                placeholder={!shouldUseAutoID ? attributeId : 'Auto-generated ID'}
                maxLength={MaxLens.name}
                value={attributeId}
                disabled
                errorMessage={
                    showErrors && !isAttributeIdValid()
                        ? 'Unique Identifier must be unique and not start with a number'
                        : ''
                }
                styles={styles.element}
            />
            <div style={styles.button}>
                <DefaultButton
                    toggle
                    text={shouldUseAutoID ? 'Generate secure ID' : 'Use formatted name'}
                    disabled={props.modalType === AttributeModalType.Edit}
                    onClick={(): void => {
                        setUseAutoID();
                    }}
                />
            </div>
            <TextField
                placeholder='New Attribute Description'
                label='Description'
                maxLength={MaxLens.description}
                minLength={MinLens.description}
                value={description}
                onChange={(ev, newVal): void => setDescription(newVal)}
                errorMessage={
                    showErrors && !isDescriptionValid()
                        ? 'Description must be between 6-128 characters'
                        : ''
                }
                required
                styles={styles.element}
            />
            <div className={mergedstyles.searchBoxWrapper}>
                <div className={mergedstyles.searchBox}>
                    <CoreMultiOidFromGraphPickerTypeaheadSearch
                        label='Owners'
                        itemLimit={EmployeePickerListLimit}
                        placeHolder='Select Owners'
                        onChange={onOwnersSelected}
                        selectedItems={owners}
                        required
                    />
                    <FontIcon
                        aria-label='Search'
                        iconName='Search'
                        className={mergedstyles.searchIcon}
                    />
                </div>
            </div>
            {showErrors && !isOwnersValid() && (
                <span
                    style={{
                        color: FluentTheme.semanticColors.errorText,
                        fontSize: 12,
                    }}>
                    A minimum of {MinLens.userOwners} user owners or{' '}
                    {MinLens.servicePrincipalOwners} service principal owners are required.
                </span>
            )}
            <div className={mergedstyles.searchBoxWrapper}>
                <div className={mergedstyles.searchBox}>
                    <CoreMultiOidFromGraphPickerTypeaheadSearch
                        label='Managers'
                        itemLimit={EmployeePickerListLimit}
                        placeHolder='Select Managers'
                        onChange={onManagersSelected}
                        selectedItems={managers}
                    />
                    <FontIcon
                        aria-label='Search'
                        iconName='Search'
                        className={mergedstyles.searchIcon}
                    />
                </div>
            </div>
            <div className={mergedstyles.searchBoxWrapper}>
                <div className={mergedstyles.searchBox}>
                    <CoreMultiOidFromGraphPickerTypeaheadSearch
                        label='Readers'
                        itemLimit={EmployeePickerListLimit}
                        placeHolder='Select Readers'
                        onChange={onReadersSelected}
                        selectedItems={readers}
                    />
                    <FontIcon
                        aria-label='Search'
                        iconName='Search'
                        className={mergedstyles.searchIcon}
                    />
                </div>
            </div>
            <Dropdown
                label='Visibility level'
                defaultSelectedKey={visibilityLevel}
                options={VisibilityLevelOptions}
                placeholder='Select Level of Visibility'
                onChange={(ev, newVal): void =>
                    setVisibilityLevel(newVal?.data ?? AttributeVisibilityLevel.Restricted)
                }
                errorMessage={
                    showErrors && !isVisibilityLevelValid()
                        ? 'You must select a visibility level'
                        : ''
                }
                required
                styles={styles.element}
            />
            <TextField
                label='Support information'
                placeholder='Add guidance and/or hyperlink for more information'
                maxLength={MaxLens.support}
                onChange={(ev, newVal): void => setSupport(newVal)}
                value={support}
                styles={styles.element}
            />
            <ChoiceGroup
                style={{ marginTop: '20px' }}
                label='Data source'
                name='dataSources'
                selectedKey={isManualAssigned ? 'M' : 'A'}
                options={[
                    {
                        key: 'M',
                        text: 'Manually assign attributes',
                    },
                    {
                        key: 'A',
                        text: 'Auto-assign attributes',
                        disabled: !props.isAdmin,
                    },
                ]}
                onChange={(ev, newVal): void => {
                    const isManual = newVal?.key === 'M';
                    setIsManualAssigned(isManual);
                    setAutoAssignedDataSource(undefined);
                    setAutoAssignedField(undefined);
                    if (!isManual) {
                        setIsMultiValued(false);
                        setShouldUsePredefinedValues(false);
                    }
                }}
            />
            <div
                style={{
                    display: isManualAssigned ? 'none' : 'block',
                }}>
                <Dropdown
                    aria-label='Data source'
                    options={dataSourceOptions ?? []}
                    defaultSelectedKey={autoAssignedDataSource ?? null}
                    placeholder='Select data source'
                    required
                    styles={{ root: { marginTop: '5px' } }}
                    onChange={(ev, newVal): void => {
                        setAutoAssignedDataSource(newVal?.key.toString());
                    }}
                    errorMessage={
                        showErrors && !isAutoAssignedDataSourceValid()
                            ? 'You must select a data source for the auto-assigned attribute'
                            : ''
                    }
                    disabled={!props.isAdmin}
                />
                <Dropdown
                    aria-label='Data field'
                    options={dataFieldOptions ?? []}
                    defaultSelectedKey={autoAssignedField ?? null}
                    placeholder='Select data field'
                    required
                    styles={{ root: { marginTop: '5px' } }}
                    onChange={(ev, newVal): void => {
                        setAutoAssignedField(newVal?.key.toString());
                    }}
                    errorMessage={
                        showErrors && !isAutoAssignedFieldValid()
                            ? 'You must select a data field for the auto-assigned attribute'
                            : ''
                    }
                    disabled={!props.isAdmin}
                />
            </div>
            <Label style={styles.label}>Azure Active Directory sync</Label>
            <Checkbox
                label='Enable CSA/AAD sync'
                checked={isCsaEnabled}
                disabled={!props.isAdmin}
                onChange={(ev, checked): void => setCsaEnabled(!!checked)}
            />
            <Dropdown
                label='Data type'
                options={DataTypeOptions}
                placeholder='Select Attribute Data Type'
                selectedKey={type}
                disabled={props.modalType === AttributeModalType.Edit || !isManualAssigned}
                onChange={(ev, newVal): void =>
                    setType(newVal?.key.toString() ?? AttributeDataType.String)
                }
                errorMessage={showErrors && !isDataTypeValid() ? 'You must select a data type' : ''}
                required
                styles={styles.element}
            />
            <Stack horizontal styles={styles.element} tokens={{ childrenGap: 50 }}>
                <div>
                    <ChoiceGroup
                        label='Allow multiple values'
                        selectedKey={isMultiValued ? 'Y' : 'N'}
                        options={[
                            {
                                key: 'Y',
                                text: 'Yes',
                            },
                            {
                                key: 'N',
                                text: 'No',
                            },
                        ]}
                        onChange={(ev, opt): void => setIsMultiValued(opt?.key === 'Y')}
                        disabled={
                            type === AttributeDataType.Boolean ||
                            !isManualAssigned ||
                            props.modalType === AttributeModalType.Edit
                        }
                    />
                </div>
                <div>
                    <ChoiceGroup
                        label='Add predefined values'
                        selectedKey={shouldUsePredefinedValues ? 'Y' : 'N'}
                        options={[
                            {
                                key: 'Y',
                                text: 'Yes',
                            },
                            {
                                key: 'N',
                                text: 'No',
                            },
                        ]}
                        onChange={(ev, opt): void => setShouldUsePredefinedValues(opt?.key === 'Y')}
                        disabled={
                            (type !== AttributeDataType.String &&
                                type !== AttributeDataType.Integer) ||
                            !isManualAssigned ||
                            props.modalType === AttributeModalType.Edit
                        }
                    />
                </div>
            </Stack>
        </Panel>
    );
}

const MaxLens = {
    name: 32,
    description: 128,
    support: 250,
};

const MinLens = {
    name: 6,
    description: 6,
    userOwners: 2,
    servicePrincipalOwners: 1,
};

const styles = {
    label: {
        marginTop: '25px',
        marginBottom: '10px',
        padding: 0,
        height: '20px',
    },
    button: {
        marginTop: '10px',
        marginBottom: '5px',
        padding: 0,
    },
    element: {
        root: {
            marginTop: '20px',
        },
    },
    panel: {
        root: {
            marginTop: '65px',
        },
    },
};

const mergedstyles = mergeStyleSets({
    searchIcon: {
        position: 'absolute',
        right: '5px',
        top: '37px',
        fontSize: '20px',
        transform: 'scaleX(-1)',
        color: '#616161',
    },
    searchBoxWrapper: {
        marginTop: '20px',
        marginBottom: '5px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    searchBox: {
        position: 'relative',
        display: 'flex',
        width: '661px',
    },
});

function createUnknownPersona(oid: string): IPersonaProps {
    return {
        imageInitials: '?',
        text: oid,
        itemID: oid,
    };
}
