/**
 * Shows content, as well as a Cancel and Submit button.
 *      ---------------------------------
 *      |                               |
 *      |            Content            |
 *      |                               |
 *      ---------------------------------
 *                 ----------  ----------
 *                 | Cancel |  | Submit |
 *                 ----------  ----------
 */
import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react';
import React, { ReactNode } from 'react';
import Spacer from 'components/common/spacer';

interface ContentCancelSubmitProps {
    children: ReactNode;
    cancelText?: string;
    onCancelClick: () => void;
    submitText?: string;
    enableSubmit: boolean;
    submitIconName?: string;
    onSubmitClick: () => void | Promise<void>;
}

export default function ContentCancelSubmit(props: ContentCancelSubmitProps): JSX.Element {
    return (
        <Stack>
            <Stack.Item>{props.children}</Stack.Item>
            <Stack.Item>
                <Spacer marginTop={10} />
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal horizontalAlign='end'>
                    <Stack.Item>
                        <DefaultButton onClick={props.onCancelClick}>
                            {props.cancelText ?? 'Cancel'}
                        </DefaultButton>
                    </Stack.Item>
                    <Stack.Item>
                        <Spacer marginLeft={20} />
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton onClick={props.onSubmitClick} disabled={!props.enableSubmit}>
                            {props.submitText ?? 'Submit'}
                        </PrimaryButton>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>
    );
}
