import React, { useState, useContext } from 'react';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import EligibilityClient, { IEligibilityAssignment } from 'clients/eligibility-client';
import { AuthContext } from 'contexts/auth-context';
import { IDropdownOption } from '@fluentui/react';
import AssignEligibility from 'components/user-assignments/manage-eligibilities/assign-eligibility';

interface IAddEligibilityModalActionButtonProps {
    personnelId: string;
    eligibilityAssignment: IEligibilityAssignment;
    updateAddEligibilityAssignment: () => void;
}

function AddEligibilityModalActionButton(
    props: IAddEligibilityModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [selectedItem, setSelectedItem] = useState<IDropdownOption>();

    const handleSubmit = async (): Promise<void> => {
        if (selectedItem) {
            try {
                await EligibilityClient.addPersonnelEligibility(
                    authContext,
                    props.personnelId,
                    `${selectedItem.key}`, // convert to string
                );
                setSelectedItem(undefined);
            } catch (error) {
                throw 'Error adding eligibility';
            }
        }
    };

    return (
        <ModalActionButton<void>
            text={'Add eligibility'}
            modalTitle={'Add Eligibility'}
            enableSubmit={selectedItem !== undefined}
            iconName={'Add'}
            submitButtonText={'Assign Eligibility'}
            onSubmit={handleSubmit}
            onModalConcluded={(conclusion: ModalConclusion): void => {
                if (conclusion === ModalConclusion.Done) {
                    props.updateAddEligibilityAssignment();
                } else {
                    setSelectedItem(undefined);
                }
            }}>
            {props.eligibilityAssignment && (
                <AssignEligibility
                    handleSetSelectedItem={(item: IDropdownOption): void => {
                        setSelectedItem(item);
                    }}
                    eligibilityOptions={props.eligibilityAssignment}
                    personnelId={props.personnelId}
                />
            )}
        </ModalActionButton>
    );
}

export default AddEligibilityModalActionButton;
