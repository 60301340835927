import { maxWidthCoeff } from 'assets/constants/global-constants';
import FacilitiesClient, {
    IBlockedFacilityUserRecord,
    IFacilityRecord,
} from 'clients/facilities-client';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import { Table, TableCell } from 'components/common/table';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { IColumn, IDropdownOption, mergeStyles, Stack } from '@fluentui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIsMounted } from 'utils/misc-hooks';
import { TimeFormats } from 'utils/time-utils';
import { handleUTCToFacilityTimeZone } from 'components/facilities/common/facility-time-utils';
import SecureWorkAreaDropdownSelector from 'components/facilities/common/secure-work-area-dropdown-selector';
import AddBlockedUser from 'components/facilities/facilities-users/modals/add-blocked-user-modal';
import UnblockUserModal from 'components/facilities/facilities-users/modals/unblock-user-modal';

export interface FacilitiesManagementUsersPageProps {
    facilities: IFacilityRecord[] | undefined;
}

export default function FacilitiesManagementUsers(
    props: FacilitiesManagementUsersPageProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [selectedFacilityId, setSelectedFacilityId] = useState<string>();
    const [blockedUsers, setBlockedUsers] = useState<IBlockedFacilityUserRecord[]>();
    const isMounted = useIsMounted();

    const facilities = useMemo(() => props.facilities?.filter((facility) => !!facility?.svgMap), [
        props.facilities,
    ]);

    useEffect(() => {
        if (facilities && facilities.length > 0) {
            setSelectedFacilityId(facilities[0].facilityId);

            getBlockedUsers(facilities[0].facilityId);
        }
    }, facilities);

    async function facilitySelectionChange(
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number,
    ): Promise<void> {
        if (option) {
            setSelectedFacilityId(option.key.toString());
            await getBlockedUsers(option.key.toString());
        }
    }

    async function updateBlockedUsersOnDelete(
        blockedFacilityUserRecord: IBlockedFacilityUserRecord,
    ) {
        if (!blockedFacilityUserRecord) {
            return;
        }

        if (isMounted()) {
            setBlockedUsers((blockedUsers) => {
                const newRecords = blockedUsers?.filter(
                    (x) => x.id !== blockedFacilityUserRecord.id,
                );
                return newRecords;
            });
        }
    }

    async function updateBlockedUsersOnAdd(blockedUser: IBlockedFacilityUserRecord) {
        if (!blockedUser) {
            return;
        }

        if (isMounted()) {
            setBlockedUsers((oldBlockedUsers) => {
                const newBlockedUsers: IBlockedFacilityUserRecord[] = [...(oldBlockedUsers ?? [])];
                newBlockedUsers.push(blockedUser);
                return newBlockedUsers;
            });
        }
    }

    async function getBlockedUsers(facilityId: string): Promise<void> {
        const blockedFacilityUserRecords: IBlockedFacilityUserRecord[] = [];

        let continuationToken = '';
        do {
            const blockedUserRecordsResults = await FacilitiesClient.getAllBlockedFacilityUserRecordsByFacility(
                authContext,
                userContext,
                facilityId,
                continuationToken,
            );

            blockedUserRecordsResults.results.forEach((x) => {
                blockedFacilityUserRecords.push(x);
            });

            continuationToken = blockedUserRecordsResults.continuationToken ?? '';
        } while (continuationToken);
        if (isMounted()) {
            setBlockedUsers(blockedFacilityUserRecords);
        }
    }

    enum logBookColumnNames {
        Employee = 'Blocked Employee',
        BlockedAt = 'Blocked At',
        BlockedBy = 'Blocked By',
        Actions = 'Actions',
    }

    const columnWidths = {
        employee: 250,
        blockedAt: 100,
        blockedBy: 250,
        actions: 100,
    };

    const columns: IColumn[] = [
        {
            key: logBookColumnNames.Employee,
            name: logBookColumnNames.Employee,
            ariaLabel: logBookColumnNames.Employee,
            minWidth: columnWidths.employee,
            maxWidth: columnWidths.employee * maxWidthCoeff,
            onRender: (row: IBlockedFacilityUserRecord): JSX.Element => {
                if (!row.personnelId) {
                    return <></>;
                }

                return (
                    <EmployeeBasicHoverCard key={row.personnelId} personnelId={row.personnelId} />
                );
            },
        },
        {
            key: logBookColumnNames.BlockedAt,
            name: logBookColumnNames.BlockedAt,
            ariaLabel: logBookColumnNames.BlockedAt,
            minWidth: columnWidths.blockedAt,
            maxWidth: columnWidths.blockedAt * maxWidthCoeff,
            onRender: (row: IBlockedFacilityUserRecord): JSX.Element => {
                return (
                    <TableCell>
                        {handleUTCToFacilityTimeZone(
                            row.createdTimestamp.atUtc,
                            facilities?.find((x) => x.id === selectedFacilityId),
                            TimeFormats.MMMDDYYYY_hmmA,
                        )}
                    </TableCell>
                );
            },
        },
        {
            key: logBookColumnNames.BlockedBy,
            name: logBookColumnNames.BlockedBy,
            ariaLabel: logBookColumnNames.BlockedBy,
            minWidth: columnWidths.blockedBy,
            maxWidth: columnWidths.blockedBy * maxWidthCoeff,
            onRender: (row: IBlockedFacilityUserRecord): JSX.Element => {
                if (!row.createdTimestamp.by) {
                    return <></>;
                } else if (row.createdTimestamp.by === 'service') {
                    return <div>Automatically Blocked by Facilities Service</div>;
                } else {
                    return (
                        <EmployeeBasicHoverCard
                            key={row.createdTimestamp.by}
                            personnelId={row.createdTimestamp.by}
                        />
                    );
                }
            },
        },
        {
            key: logBookColumnNames.Actions,
            name: logBookColumnNames.Actions,
            ariaLabel: logBookColumnNames.Actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions * maxWidthCoeff,
            onRender: (row: IBlockedFacilityUserRecord): JSX.Element => {
                return (
                    <TableCell>
                        <div className={mergeStyles({ display: 'inline-block' })}>
                            <UnblockUserModal
                                facility={facilities?.find((x) => x.id === selectedFacilityId)}
                                blockedFacilityUserRecord={row}
                                updateBlockedUsersOnDelete={updateBlockedUsersOnDelete}
                            />
                        </div>
                    </TableCell>
                );
            },
        },
    ];
    return (
        <>
            <SidebarAndContents>
                <SidebarPane>
                    <SecureWorkAreaDropdownSelector
                        onChange={facilitySelectionChange}
                        placeholder='Select a facility'
                        selectedKey={selectedFacilityId}
                        options={facilities?.map((f) => {
                            return {
                                key: f.id,
                                text: f.facilityName,
                            };
                        })}
                    />
                </SidebarPane>
                <ContentPane>
                    <HorizontalBar>
                        <Stack.Item className={horizontalBarTitleStyle} grow={100}>
                            Blocked Users
                        </Stack.Item>
                    </HorizontalBar>
                    <AddBlockedUser
                        facilityId={selectedFacilityId}
                        updateBlockedUsersOnAdd={updateBlockedUsersOnAdd}
                    />
                    <Table
                        isFetchingData={false}
                        rows={blockedUsers ?? []}
                        tableName='Facility Blocked Users'
                        tableColumns={columns}
                        shimmerLines={2}
                        shimmerLabel='Loading blocked users...'
                    />
                </ContentPane>
            </SidebarAndContents>
        </>
    );
}
