import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { globalStyles } from 'assets/styles/global-styles';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { scaManageUrl, scaManageViewBreadcrumbs } from 'components/sca/sca-breadcrumbs';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import ScaClient, {
    IReviewPeriod,
    IEmployeeReview,
    reviewStates,
    IScaReportRecord,
    IScaRecord,
} from 'clients/sca-client';
import { getScaReviewIdTableColumns } from 'components/sca/manage/get-sca-manage-review-id-columns';
import { useParams } from 'react-router-dom';
import { useSortColumnHandler, strCmp, numCmp } from 'utils/sort-utils';
import EmployeeClient, { IBasicEmployee } from 'clients/employee-client';
import { Dictionary } from 'assets/constants/global-constants';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import Tabs, { TabbedContent } from 'components/common/tabs';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { ScaViewAddEmployeeModalButton } from 'components/sca/manage/sca-view-add-employee-modal-button';
import { ScaReviewPeriodFilterContext } from 'components/sca/contexts/sca-review-period-filter-context';
import SidebarAndContents, { ContentPane } from 'components/common/sidebar-and-contents';
import ScaManageViewIdFilters from 'components/sca/manage/sca-manage-view-id-filters';
import { Redirect } from 'react-router-dom';
import { reviewIdUrl } from 'components/sca/sca-breadcrumbs';
import { generalIsMountedCodes, uniquifyArray } from 'utils/misc-utils';
import { useToggle } from 'utils/misc-hooks';
import CreateReviewModal from 'components/sca/manage/create-review/create-review-modal';
import ButtonBar from 'components/common/button-bar';
import { IconNames } from 'assets/constants/global-constants';
import MessageBar from 'components/common/message-bar';
import DisplayPeriodDetails from 'components/sca/manage/display-period-details';
import UpdateReviewPeriodStatusModalButton from 'components/sca/manage/update-review-period-status-modal-button';
import { DetailsList, mergeStyleSets, SelectionMode } from '@fluentui/react';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { TimeFormats } from 'utils/time-utils';
import moment from 'moment';
import HorizontalBar from 'components/common/horizontal-bar';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import { IReviewIdResult } from 'clients/sca-client';
import { Location } from 'history';
import ScaDeleteReviewModalButton from 'components/sca/manage/sca-delete-review-modal-button';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { useIsMounted } from 'utils/misc-hooks';
import Spacer from 'components/common/spacer';
import { generalIsMountedCode } from 'utils/misc-utils';

interface IProps {
    location: Location;
}

export default function ScaManageViewId(props: IProps): JSX.Element {
    const { reviewId } = useParams<{ reviewId: string }>();
    const { execId } = useParams<{ execId: string }>();

    const filters = useContext(ScaReviewPeriodFilterContext);
    const authContext = useContext(AuthContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const [tableRows, setTableRows] = useState<IEmployeeReview[]>([]);
    const [tableAliases, setTableAliases] = useState<string[]>([]);
    const [reviewPeriod, setReviewPeriod] = useState<IReviewPeriod | undefined>();
    const [employees, setEmployees] = useState<IEmployeeReview[]>([]);
    const [imageUrls, setImageUrls] = useState<Dictionary<string>>({});
    const [
        { sortColumn, sortAscending, clearSortColumn },
        sortColumnHandler,
    ] = useSortColumnHandler('', 1);
    const [basicEmployeesInfo, setBasicEmployeesInfo] = useState<Dictionary<IBasicEmployee>>({});
    const [reviewedByBasicEmployeesInfo, setReviewedByBasicEmployeesInfo] = useState<
        Dictionary<IBasicEmployee>
    >({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(true);
    const [loadingErrorMsg, setLoadingErrorMsg] = useState<string>('');
    const [isTriggerReload, triggerReload] = useToggle(true);
    const [redirectTo, setRedirectTo] = useState<string>();
    const [continuationToken, setContinuationToken] = useState<string>('');
    const [shouldLoadMore, setShouldLoadMore] = useState<boolean>();

    const [execIdStateVar, setExecIdStateVar] = useState<string>();
    const [selectedTabKey, setSelectedTabKey] = useState<string>();

    // If user filters on an employee, and if the function fetchEmployeeRecordInReview
    // fetches that one employee's record, remember those employees here.
    // The following variable will be used to avoid showing duplicate employee records
    // which will happen if, due to page "load more"s, record of the fetched employee
    // is fetched again from the server.
    const [singleFetchedPersonnelIdsSet, setSingleFetchedPersonnelIdsSet] = useState<Set<string>>(
        new Set(),
    );

    const prepareForRedirect = (redirectToUrl: string): void => {
        setRedirectTo(redirectToUrl);
        setContinuationToken('');
        setSingleFetchedPersonnelIdsSet(new Set<string>());
    };

    const isMounted = useIsMounted();

    const isCurrent = (): boolean => !!reviewPeriod?.isCurrent;

    useEffect(() => {
        const breadcrumb = scaManageViewBreadcrumbs(reviewId);
        breadCrumbContext.setBreadCrumbs([...breadcrumb]);
    }, [reviewId]);

    useEffect(() => {
        return generalIsMountedCodes([fetchReviewPeriod, callFetchPeriodRecords]);
    }, [isTriggerReload]);

    useEffect(() => {
        const redirectToUrl = reviewIdUrl(reviewId, execId);
        setExecIdStateVar(execId);
        if (redirectToUrl.toLowerCase() !== props.location.pathname.toLowerCase()) {
            prepareForRedirect(redirectToUrl);
        }
    }, []);

    useEffect(() => {
        const period = filters.period?.key as string;
        if (!period) return;
        const redirectToUrl = reviewIdUrl(period, execId);
        if (redirectToUrl.toLowerCase() !== props.location.pathname.toLowerCase()) {
            prepareForRedirect(redirectToUrl);
        }
    }, [filters.period, execId]);

    useEffect(() => {
        const redirectToUrl = reviewIdUrl(reviewId, execIdStateVar);
        if (redirectToUrl.toLowerCase() !== props.location.pathname.toLowerCase()) {
            prepareForRedirect(redirectToUrl);
        }
    }, [execIdStateVar]);

    useEffect(() => {
        if (filters.orgLeader?.itemPropObj?.id !== execIdStateVar)
            setExecIdStateVar(filters.orgLeader?.itemPropObj?.id);
    }, [filters]);

    useEffect(() => {
        const redirectToUrl = reviewIdUrl(reviewId, execId);
        if (redirectToUrl.toLowerCase() !== props.location.pathname.toLowerCase()) {
            prepareForRedirect(redirectToUrl);
        }
    }, [execId, reviewId]);

    useEffect(() => {
        if (redirectTo) {
            triggerReload();
            setRedirectTo('');
        }
    }, [redirectTo]);

    const fetchReviewPeriod = async (isMountedFunc: () => boolean): Promise<void> => {
        try {
            const reviewPeriodVar = await ScaClient.getReviewPeriodById(authContext, reviewId);
            if (isMountedFunc()) {
                setReviewPeriod(reviewPeriodVar);
            }
        } catch (e) {
            console.error(e);
            if (isMountedFunc()) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((e as any)?.status === 404) {
                    setLoadingErrorMsg('The specified review period not found');
                } else {
                    setLoadingErrorMsg('Error loading the specified review period');
                }
                setReviewPeriod(undefined);
            }
        }
    };

    const callFetchPeriodRecords = async (isMountedFunc: () => boolean): Promise<void> => {
        fetchPeriodRecords(isMountedFunc, '');
    };

    useEffect(() => {
        const continueFetching = async (isMountedFunc: () => boolean): Promise<void> => {
            if (shouldLoadMore && continuationToken) {
                setShouldLoadMore(false);
                return fetchPeriodRecords(isMountedFunc, continuationToken);
            }
        };

        continueFetching(isMounted);
    }, [continuationToken, shouldLoadMore]);

    const fetchPeriodRecords = async (
        isMountedFunc: () => boolean,
        continuationTokenParam: string,
    ): Promise<void> => {
        // If not a "load more" operation, specifically
        // clear the variables because in case of a
        // redirect, the page keeps showing the old review
        // period until data of the new page finishes loading.
        if (isMountedFunc()) {
            if (continuationTokenParam) {
                setIsLoadingMore(true);
            } else {
                setIsLoading(true);
                clearSortColumn();
                setEmployees([]);
            }
        }
        setLoadingErrorMsg('');
        try {
            let reviewIdResults: IReviewIdResult;
            if (execId) {
                reviewIdResults = await fetchExecOrgPromise();
            } else {
                reviewIdResults = await ScaClient.getSCAEmployeesByReviewId(
                    authContext,
                    reviewId,
                    continuationTokenParam,
                );
            }
            // User may have filtered on an employee. If so, Review record of that
            // employee is already in the array. Check that here to prevent a duplicate.
            const employeesInReview = reviewIdResults.results.filter(
                (employee) => !singleFetchedPersonnelIdsSet.has(employee.personnelId),
            );
            const continuationTokenVar = reviewIdResults.continuationToken;

            const [basicEmployees, reviewedByEmployees] = await Promise.all([
                EmployeeClient.getBasicEmployeesById(
                    authContext,
                    (employeesInReview ?? []).map((e) => e.personnelId),
                ),
                EmployeeClient.getBasicEmployeesByAlias(
                    authContext,
                    (employeesInReview ?? [])
                        .map((e) => (e.reviewedBy ?? '').replace(/@.*/, ''))
                        // Filter to avoid fetching someone who's already been fetched
                        .filter((alias) => !reviewedByBasicEmployeesInfo[alias]),
                ),
            ]);

            if (
                basicEmployees &&
                employeesInReview &&
                employeesInReview.length !== basicEmployees.length
            ) {
                // This happened, but not consistently.
                const notFound: IEmployeeReview[] = [];
                employeesInReview.forEach((employee) => {
                    if (!basicEmployees.find((e) => e.id === employee.personnelId)) {
                        notFound.push(employee);
                    }
                });
                console.error('Could not fetch basic employee data for the following');
                console.error(notFound);
            }

            const basicEmployeesInfoVar = basicEmployees?.reduce(
                (basicEmployeesInfoVar, employeeInfo) => {
                    basicEmployeesInfoVar[employeeInfo.id] = employeeInfo;
                    return basicEmployeesInfoVar;
                },
                basicEmployeesInfo,
            );

            const reviewedByBasicEmployeesInfoVar = reviewedByEmployees.reduce((acc, e) => {
                acc[e.onPremisesSamAccountName] = e;
                return acc;
            }, reviewedByBasicEmployeesInfo);

            if (isMountedFunc()) {
                setEmployees(
                    continuationTokenParam
                        ? employees.concat(employeesInReview)
                        : employeesInReview,
                );
                setBasicEmployeesInfo(basicEmployeesInfoVar || {});
                setContinuationToken(continuationTokenVar);
                setReviewedByBasicEmployeesInfo(reviewedByBasicEmployeesInfoVar || {});
                if (!!continuationTokenVar) {
                    // Keep loading while there are more records
                    setShouldLoadMore(true);
                }
            }
        } catch (e) {
            if (isMountedFunc()) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if ((e as any)?.status === 404) {
                    setLoadingErrorMsg(
                        'No record found for the specified review period or org lead',
                    );
                } else {
                    setLoadingErrorMsg('Error loading data');
                }
                setEmployees([]);
                setContinuationToken('');
            }
        } finally {
            if (isMountedFunc()) {
                setIsLoading(false);
                setIsLoadingMore(false);
            }
        }
        const imageUrls: Dictionary<string> = {};
        setImageUrls(imageUrls);
    };

    const fetchExecOrgPromise = async (): Promise<IReviewIdResult> => {
        const execRecordsVar = await ScaClient.getScaEmployeeRecordsForExec(
            authContext,
            execId,
            reviewId,
        );
        const employeesVar: IEmployeeReview[] = [];
        execRecordsVar.managerGroups.forEach((records) => {
            records.directReports.forEach((record) => {
                employeesVar.push(record);
            });
        });
        return {
            results: employeesVar,
            continuationToken: '',
        };
    };

    useEffect(() => {
        const tableRowsVar = determineTableRows(employees);
        setTableRows(tableRowsVar);
        setTableAliases(
            tableRowsVar
                .map((t) => t.alias)
                .concat(tableRowsVar.map((t) => t.reviewCommentBy))
                .concat(
                    uniquifyArray(
                        tableRowsVar.map((e) =>
                            basicEmployeesInfo[e.personnelId]?.reportsToEmailName
                                ?.replace(/@.*/, '')
                                ?.toLocaleLowerCase(),
                        ),
                    ) as string[],
                ),
        );
    }, [sortAscending, sortColumn, employees, basicEmployeesInfo, filters]);

    const fetchEmployeeRecordInReview = async (isMountedFunc: () => boolean): Promise<void> => {
        if (!filters.employee) return;
        // User is searching for an employee.
        // Check if that employee is already loaded.
        const itemProp = JSON.parse(filters.employee.itemProp || '{}');
        const searchEmployees = employees.find((employee) => itemProp.id === employee.personnelId);
        if (!!searchEmployees) return;
        // The employee is not yet loaded. Fetch their record.
        try {
            const [record, basicEmployee] = await Promise.all([
                ScaClient.getSCAEmployeeReviewInPeriod(authContext, itemProp.id, reviewId),
                EmployeeClient.getBasicEmployeesById(authContext, [itemProp.id]),
            ]);
            // Search again because while the above code was fetching, a page "load more"
            // may have already loaded the employee record.
            const searchAgain = employees.find((employee) => itemProp.id === employee.personnelId);
            if (!searchAgain && isMountedFunc()) {
                setEmployees((employees) => employees.concat(record));
                setBasicEmployeesInfo((currentValue) => ({
                    ...currentValue,
                    [itemProp.id]: basicEmployee[0],
                }));
                setSingleFetchedPersonnelIdsSet((currentValue) =>
                    new Set(currentValue).add(itemProp.id),
                );
            }
        } catch (e) {
            // No need to log the error. It's already logged.
        }
    };

    useEffect(() => {
        return generalIsMountedCode(fetchEmployeeRecordInReview);
    }, [filters.employee, employees]);

    const determineTableRows = (employees: IEmployeeReview[]): IEmployeeReview[] => {
        return sortEmployees(filterEmployees(employees));
    };

    const filterEmployees = (employees: IEmployeeReview[]): IEmployeeReview[] => {
        const letThrough = (employee: IEmployeeReview): boolean => {
            if (filters?.employee) {
                const itemProp = JSON.parse(filters.employee.itemProp || '{}');
                if (itemProp.id !== employee.personnelId) {
                    return false;
                }
            }
            // If the employee matches either of the set
            // status filters, let the employee go through.
            let isDisplayed = true;
            const isEmployeeMatchesNoStatusFilter = !(filters?.status ?? {})[employee.reviewState];
            reviewStates().forEach((reviewState) => {
                const hasFilterSetting = (filters?.status ?? {})[reviewState];
                if (hasFilterSetting && isEmployeeMatchesNoStatusFilter) {
                    // This status filter is set, but the employee
                    // matches none of the set status filters.
                    isDisplayed = false;
                }
            });
            if (!isDisplayed) {
                return false;
            }

            if (filters.reportsTo) {
                if (
                    filters.reportsTo?.itemPropObj?.alias.toLocaleLowerCase() !==
                    basicEmployeesInfo[employee.personnelId].reportsToEmailName?.toLocaleLowerCase()
                ) {
                    return false;
                }
            }

            if (filters.reviewer) {
                if (
                    filters.reviewer?.itemPropObj?.alias.toLocaleLowerCase() !==
                    employee.reviewedBy?.replace(/@.*/, '').toLocaleLowerCase()
                ) {
                    return false;
                }
            }

            if (filters.reviewedOn) {
                const reviewDate = new Date(employee.reviewedTimestampUTC * 1000).toDateString();
                if (reviewDate !== filters.reviewedOn.toDateString()) {
                    return false;
                }
            }

            const checkRate = (
                selectedRateKey: string | number | undefined,
                rate: string | undefined,
                employeeRate: number,
            ): boolean => {
                if (selectedRateKey !== undefined) {
                    if (/other/i.test(selectedRateKey as string)) {
                        if (
                            rate !== undefined &&
                            rate !== '' &&
                            parseFloat(rate) !== employeeRate
                        ) {
                            return false;
                        }
                    } else {
                        if (parseFloat(selectedRateKey as string) !== employeeRate) {
                            return false;
                        }
                    }
                }
                return true;
            };

            if (!checkRate(filters.selectedRate?.key, filters.rate, employee.rate)) {
                return false;
            }

            if (
                !checkRate(
                    filters.selectedPreviousRate?.key,
                    filters.previousRate,
                    employee.previousRate,
                )
            ) {
                return false;
            }

            return true;
        };
        const filtered: IEmployeeReview[] = employees.filter(letThrough);
        return filtered;
    };

    const sortEmployees = (employees: IEmployeeReview[]): IEmployeeReview[] => {
        type R = IEmployeeReview;
        const b = basicEmployeesInfo;

        const chooseSortCmp = (
            sortColumn: string,
        ): ((r1: IEmployeeReview, r2: IEmployeeReview) => number) => {
            switch (sortColumn) {
                case 'Employee':
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        strCmp(b[r1.personnelId]?.displayName, b[r2.personnelId]?.displayName);
                case 'Current Rate':
                    return (r1: R, r2: R): number => sortAscending * numCmp(r1.rate, r2.rate);
                case 'Previous Rate':
                    return (r1: R, r2: R): number =>
                        sortAscending * numCmp(r1.previousRate, r2.previousRate);
                default:
                    // Sort column not recognized. No sorting performed.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: R, r2: R): number => 0;
            }
        };
        const sortCmp = chooseSortCmp(sortColumn);
        return employees.sort(sortCmp);
    };

    const onDeleteEmployeeConcluded = async (
        modalConclusion: ModalConclusion,
        employeeId: string,
    ): Promise<void> => {
        if (modalConclusion === ModalConclusion.Done) {
            const employeesVar = [...employees];
            const deleteIndex = employees.findIndex(
                (employee) => employee.personnelId === employeeId,
            );
            if (deleteIndex !== undefined && deleteIndex >= 0) {
                employeesVar.splice(deleteIndex, 1);
                setEmployees(employeesVar);
            }
        }
    };

    const onAddEditEmployeeConcluded = async (
        mode: 'add' | 'update',
        modalConclusion: ModalConclusion,
        result?: IEmployeeReview,
    ): Promise<void> => {
        if (modalConclusion === ModalConclusion.Done && result) {
            /**
             * Update the array "employees"
             */
            const employeesVar = [...employees];
            if (mode === 'add') {
                employeesVar.splice(0, 0, result);
            } else {
                const currentRecordIx = employeesVar.findIndex(
                    (employee) => employee.personnelId === result.personnelId,
                );
                if (currentRecordIx > -1) employeesVar.splice(currentRecordIx, 1, result);
            }
            setEmployees(employeesVar);
            /**
             * Update the object variable basicEmployeesInfo
             */
            if (mode === 'add') {
                try {
                    const [basicEmployee] = await EmployeeClient.getBasicEmployeesById(
                        authContext,
                        [result.personnelId],
                    );
                    const basicEmployeesInfoVar = {
                        ...basicEmployeesInfo,
                    };
                    basicEmployeesInfoVar[result.personnelId] = basicEmployee;
                    setBasicEmployeesInfo(basicEmployeesInfoVar);
                } catch (e) {
                    console.error(e);
                }
            }
        }
    };

    const onReviewPeriodUpdated = (result: IReviewPeriod): void => {
        setReviewPeriod(result);
    };

    const onReviewPeriodDeleted = (): void => {
        setRedirectTo(scaManageUrl());
    };

    /**
     *
     */
    type ShowHideType = {
        showAddEmployee?: boolean;
        showEditReview?: boolean;
        showUpdateStatus?: boolean;
        showDeleteReview?: boolean;
    };

    const renderButtonBar = (showHide: ShowHideType = {}): JSX.Element => {
        return (
            <ButtonBar>
                {showHide.showAddEmployee && isCurrent() && (
                    <ScaViewAddEmployeeModalButton
                        mode='add'
                        onAddEditEmployeeConcluded={onAddEditEmployeeConcluded}
                    />
                )}
                {showHide.showUpdateStatus && (
                    <UpdateReviewPeriodStatusModalButton
                        reviewPeriod={reviewPeriod}
                        onReviewPeriodUpdated={onReviewPeriodUpdated}
                    />
                )}
                {showHide.showEditReview && (
                    <CreateReviewModal
                        mode='edit'
                        onNewReviewPeriod={triggerReload}
                        currentReviewPeriod={isCurrent() ? reviewPeriod : undefined}
                    />
                )}
                {showHide.showDeleteReview && (
                    <ScaDeleteReviewModalButton
                        reviewPeriod={reviewPeriod}
                        onReviewPeriodDeleted={onReviewPeriodDeleted}
                    />
                )}
            </ButtonBar>
        );
    };

    const getReviewReport = async (): Promise<ExcelRecordType[]> => {
        try {
            const report = await ScaClient.getReviewReport(authContext, reviewId);
            const csv = reportToCsv(report);
            return csv;
        } catch (e) {
            console.error(`Error generating report for review period '${reviewId}'`, e);
            return [];
        }
    };

    const reportToCsv = (rows: IScaReportRecord[]): ExcelRecordType[] => {
        const returnResult = rows.map((row) => ({
            personnelID: row.personnelId,
            alias: row.alias,
            lastName: row.lastName,
            firstName: row.firstName,
            middleName: row.middleName,
            employeeTitle: row.title,
            organization: row.organization,
            geographicLocation: row.geographicLocation,
            officeLocation: row.officeLocation,
            level1: row.l1,
            level2: row.l2,
            level3: row.l3,
            level4: row.l4,
            level5: row.l5,
            level6: row.l6,
            reportsTo: row.reportsTo,
            reviewPeriodID: row.reviewPeriodId,
            rate: row.rate,
            previousRate: row.previousRate,
            createdBy: row.createdBy,
            createdTimestampUTC: row.createdTimestampUTC
                ? moment(row.createdTimestampUTC).format(TimeFormats.MDYYYY_24hour)
                : '',
            rateChangedBy: row.rateChangedBy,
            rateChangedTimestampUTC: row.rateChangedTimestampUTC
                ? moment(row.rateChangedTimestampUTC).format(TimeFormats.MDYYYY_24hour)
                : '',
            reviewState: row.reviewState,
            reviewedBy: row.reviewedBy,
            reviewedTimestampUTC: row.reviewedTimestampUTC
                ? moment(row.reviewedTimestampUTC).format(TimeFormats.MDYYYY_24hour)
                : '',
            reviewComment: row.reviewComment,
            reviewCommentBy: row.reviewCommentBy,
            reviewCommentTimestampUTC: row.reviewCommentTimestampUTC
                ? moment(row.reviewCommentTimestampUTC).format(TimeFormats.MDYYYY_24hour)
                : '',
        }));
        return returnResult;
    };

    const onTabChange = (itemKey: string | undefined): void => {
        setSelectedTabKey(itemKey);
    };

    const getKey = (item: IScaRecord, index?: number): string => {
        return item.id;
    };

    const tableColumns = getScaReviewIdTableColumns({
        imageUrls,
        isCurrent: isCurrent(),
        sortColumn: sortColumn,
        hasEditRoles: true,
        sortAscending: sortAscending === 1,
        sortColumnHandler: sortColumnHandler,
        basicEmployeesInfo,
        reviewedByBasicEmployeesInfo,
        onDeleteEmployeeConcluded,
        onAddEditEmployeeConcluded: onAddEditEmployeeConcluded,
    });

    if (redirectTo) {
        return <Redirect push={true} to={redirectTo} />;
    } else {
        return (
            <>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                <div>
                    <Tabs onChange={onTabChange} defaultSelectedKey={selectedTabKey}>
                        <TabbedContent tabHeader='Employees' itemKey='employees'>
                            {renderButtonBar({ showAddEmployee: true })}
                            <SidebarAndContents>
                                {/**
                                 * Here the code is not using <SidebarPane> because
                                 * <ScaManageViewIdFilters> needs two panes and it
                                 * will, instead, use two instances of <SidebarPane>
                                 * to create them.
                                 */}
                                <ScaManageViewIdFilters
                                    reviewId={reviewId}
                                    aliases={tableAliases}
                                    reviewPeriod={reviewPeriod}
                                    showRate={true}
                                    rateFilterLabel='Current Rate'
                                    showOrgleader={true}
                                    showReportsTo={true}
                                    showReviewedOn={true}
                                    showReviewedBy={true}
                                    showPreviousRate={true}
                                    showClearFilters={true}
                                />
                                <ContentPane>
                                    {/* "Loading more" spinner, and "Export to excel" button */}
                                    <HorizontalBar>
                                        <table className={tableStyles.table}>
                                            <tbody>
                                                <tr>
                                                    <td className={tableStyles.td}>
                                                        <ClearFiltersActionButton
                                                            clearFunc={filters.resetAll}
                                                        />
                                                    </td>
                                                    <td className={tableStyles.tdEnd}>
                                                        <ExportToExcelButton<ExcelRecordType>
                                                            getData={getReviewReport}
                                                            fileNamePrefix={`${reviewId}_${reviewPeriod?.state}`}
                                                            formatHeader={true}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </HorizontalBar>
                                    <PageLoadingSpinner
                                        label='Loading records ...'
                                        ariaLive='assertive'
                                        isLoading={isLoading}
                                        labelPosition='left'>
                                        <ProblemLoadingMsg problemLoadingMsg={loadingErrorMsg}>
                                            <DetailsList
                                                items={tableRows || []}
                                                columns={tableColumns}
                                                selectionMode={SelectionMode.none}
                                                getKey={getKey}
                                            />
                                        </ProblemLoadingMsg>
                                    </PageLoadingSpinner>
                                    <IsLoadingIndicator
                                        msg='Loading more records...'
                                        before={<Spacer marginTop={10} />}
                                        isLoading={!!continuationToken && isLoadingMore}
                                    />
                                </ContentPane>
                            </SidebarAndContents>
                        </TabbedContent>
                        <TabbedContent tabHeader='Details' itemKey='details'>
                            {renderButtonBar({
                                showEditReview: true,
                                showUpdateStatus: true,
                                showDeleteReview: true,
                            })}
                            <SidebarAndContents>
                                {/**
                                 * Here the code is not using <SidebarPane> because
                                 * <ScaManageViewIdFilters> needs two panes and it
                                 * will, instead, use two instances of <SidebarPane>
                                 * to create them.
                                 */}
                                <ScaManageViewIdFilters
                                    reviewId={reviewId}
                                    aliases={tableAliases}
                                    reviewPeriod={reviewPeriod}
                                    hideFilters={true}
                                />
                                <ContentPane>
                                    <div className={globalStyles.TabHeaderPadding}>
                                        {!reviewPeriod?.isCurrent && (
                                            <MessageBar
                                                iconName={IconNames.Info}
                                                message='This review is closed and cannot be modified'
                                            />
                                        )}
                                        <DisplayPeriodDetails reviewPeriod={reviewPeriod} />
                                    </div>
                                </ContentPane>
                            </SidebarAndContents>
                        </TabbedContent>
                    </Tabs>
                </div>
            </>
        );
    }
}

type ExcelRecordType = {
    personnelID: string;
    alias: string;
    lastName: string;
    firstName: string;
    middleName: string;
    employeeTitle: string;
    organization: string;
    geographicLocation: string;
    officeLocation: string;
    level1: number;
    level2: number;
    level3: number;
    level4: number;
    level5: number;
    level6: number;
    reportsTo: string;
    reviewPeriodID: string;
    rate: number;
    previousRate: number;
    createdBy: string;
    createdTimestampUTC: string;
    rateChangedBy: string;
    rateChangedTimestampUTC: string;
    reviewState: string;
    reviewedBy: string;
    reviewedTimestampUTC: string;
    reviewComment: string;
    reviewCommentBy: string;
    reviewCommentTimestampUTC: string;
};

const tableStyles = mergeStyleSets({
    table: {
        width: '100%',
    },
    td: {
        width: '33%',
    },
    tdCenter: {
        width: '33%',
        textAlign: 'center',
    },
    tdEnd: {
        width: '33%',
        textAlign: 'end',
    },
});
