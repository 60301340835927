import React, { useState, useEffect, useContext } from 'react';
import {
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    ShimmeredDetailsList,
    mergeStyleSets,
} from '@fluentui/react';
import TemplateClient, {
    IScreeningTemplateRequestRecord,
    IDocumentMetadata,
    ScreeningTemplateRequestedState,
} from 'clients/template-client';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { EmployeeNameResolverId } from 'components/common/employee/employee-name-resolver';
import { AuthContext } from 'contexts/auth-context';
import { dateToFormattedDateTimeString } from 'utils/time-utils';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import PublicTrustDocumentClient from 'clients/screening/public-trust-document-client';
import { ViewRequestedDocumentModalFlag } from 'components/screening/common/screening-document-feature-flag/modals/view-requested-document-modal-flag';

export interface RequestedDocumentTableProps {
    screeningId: string;
    updateUploadedDocumentsPanel: (didUpload?: boolean) => void;
    requestedDocuments: IScreeningTemplateRequestRecord[] | undefined;
    documentMetadata: IDocumentMetadata[] | undefined;
    updateDocumentRequest(request: IScreeningTemplateRequestRecord): void;
    removeDocumentRequest(request: IScreeningTemplateRequestRecord): void;
    userTypes: string[];
    screeningPath: ScreeningPaths;
    nomineeId: string;
}

function RequestedDocumentTable(props: RequestedDocumentTableProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [outstanding, setOutstanding] = useState<number>(-1);

    async function onUploadComplete(request: IScreeningTemplateRequestRecord): Promise<void> {
        request.requestedState = ScreeningTemplateRequestedState.UploadedState;
        let updatedRequest: IScreeningTemplateRequestRecord[] = [];
        if (props.screeningPath === ScreeningPaths.UsGov) {
            updatedRequest = await TemplateClient.updateMultipleDocuments(authContext, [request]);
        } else {
            updatedRequest = await PublicTrustDocumentClient.updateMultipleDocuments(authContext, [
                request,
            ]);
        }
        props.updateDocumentRequest(updatedRequest[0]);
    }

    function getDocumentTitle(documentId: string, documentMetadata: IDocumentMetadata[]): string {
        if (documentMetadata) {
            const document = documentMetadata.filter((x) => x.documentId === documentId).pop();
            return document ? document?.title : '';
        }
        return '';
    }

    function getDocumentMetadataByDocId(
        documentId: string,
        documentMetadata: IDocumentMetadata[] | undefined,
    ): IDocumentMetadata | undefined {
        return documentMetadata
            ? documentMetadata.filter((x) => x.documentId === documentId).pop()
            : undefined;
    }

    useEffect(() => {
        if (props.requestedDocuments) {
            setOutstanding(props.requestedDocuments.length);
        }
    }, [props.requestedDocuments]);

    const columns: IColumn[] = [
        {
            key: 'title',
            name: 'Title',
            minWidth: 100,
            isRowHeader: false,
            ariaLabel: 'Document title',
            data: 'string',
            onRender: (item: IScreeningTemplateRequestRecord): JSX.Element => {
                return (
                    <div className={styles.columnPadding}>
                        <span className={styles.stringContent}>
                            {props.documentMetadata
                                ? getDocumentTitle(item.documentId, props.documentMetadata)
                                : ''}
                        </span>
                    </div>
                );
            },
        },
        {
            key: 'requestedBy',
            name: 'Requested By',
            minWidth: 100,
            isRowHeader: false,
            ariaLabel: 'Document Uploaded By',
            data: 'string',
            onRender: (item: IScreeningTemplateRequestRecord): JSX.Element => {
                return (
                    <>
                        {item.requesterPersonnelId && (
                            <div className={styles.columnPadding}>
                                <EmployeeHoverCard personnelId={item.requesterPersonnelId}>
                                    <span className={styles.stringContent}>
                                        <EmployeeNameResolverId
                                            personnelId={item.requesterPersonnelId}
                                        />
                                    </span>
                                </EmployeeHoverCard>
                            </div>
                        )}
                    </>
                );
            },
        },
        {
            name: 'Requested On',
            minWidth: 140,
            key: 'lastAction',
            data: 'string',
            isRowHeader: false,
            ariaLabel: 'Requested On',
            onRender: (item: IScreeningTemplateRequestRecord): JSX.Element => {
                return item.createdDateTime ? (
                    <div className={styles.columnPadding}>
                        <span className={styles.stringContent}>
                            {dateToFormattedDateTimeString(new Date(item.createdDateTime))}
                        </span>
                    </div>
                ) : (
                    <></>
                );
            },
        },
        {
            key: 'actions',
            name: 'Actions',
            minWidth: 130,
            maxWidth: 140,
            isRowHeader: false,
            isPadded: false,
            ariaLabel: 'Actions',
            onRender: (item: IScreeningTemplateRequestRecord): JSX.Element => {
                return (
                    <ViewRequestedDocumentModalFlag
                        title='View Request'
                        iconName='FileRequest'
                        documentId={item.documentId}
                        referenceId={props.screeningId}
                        documentRequestId={item.id}
                        documentMetadata={getDocumentMetadataByDocId(
                            item.documentId,
                            props.documentMetadata,
                        )}
                        updateUploadedDocumentsPanel={props.updateUploadedDocumentsPanel}
                        onRequestRemove={(): void => props.removeDocumentRequest(item)}
                        onUploadComplete={(): Promise<void> => onUploadComplete(item)}
                        userTypes={props.userTypes}
                        isUploadingTemplateRequest={false}
                        screeningPath={props.screeningPath}
                        nomineeId={props.nomineeId}
                    />
                );
            },
        },
    ];

    return (
        <ContainerWithEtiquettes
            leftEtiquetteLabel='Requested Documents'
            rightEtiquette={
                outstanding > 0
                    ? {
                          label: `${outstanding} OUTSTANDING`,
                          backgroundColor: 'rgb(212, 0, 0)',
                      }
                    : undefined
            }>
            {props.requestedDocuments && (
                <ShimmeredDetailsList
                    columns={columns}
                    items={props.requestedDocuments}
                    setKey='requested_documents'
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                />
            )}
        </ContainerWithEtiquettes>
    );
}
const styles = mergeStyleSets({
    stringContent: {
        color: 'rgb(50, 49, 48)',
        fontSize: 14,
    },
    columnPadding: {
        paddingTop: '11px',
    },
});

export default RequestedDocumentTable;
