import ManageGroupsFilterProvider from 'components/groups/manage-groups/contexts/manage-groups-filter-context';
import ManageGroupsVariableProvider from 'components/groups/manage-groups/contexts/manage-groups-variable-context';
import ManageGroups from 'components/groups/manage-groups/manage-groups';
import { Location } from 'history';
import React from 'react';

interface IManageGroupsWrapperProps {
    location: Location;
}

export default function ManageGroupsWrapper(props: IManageGroupsWrapperProps): JSX.Element {
    return (
        <ManageGroupsFilterProvider>
            <ManageGroupsVariableProvider>
                <ManageGroups />
            </ManageGroupsVariableProvider>
        </ManageGroupsFilterProvider>
    );
}
