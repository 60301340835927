import { IRenderFunction } from '@fluentui/react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import { CorePrincipalsClient } from 'clients/core/personnel-core-client-wrappers';
import ICardPrincipal from 'components/core/common/employee-card/card-principal';
import CardPrincipalProviderFromPrincipalRecord from 'components/core/common/employee-card/card-principal-provider-from-principal-record';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useEffect, useState } from 'react';
import { getAppInsights } from 'utils/telemetry-utils';

export interface ICardPrincipalProviderFromPrincipalIdProps {
    principalId?: string;
    displayName?: string;
    cardPrincipal?: ICardPrincipal;
    onRender: IRenderFunction<ICardPrincipal>;
}

export default function CardPrincipalProviderFromPrincipalId(
    props: ICardPrincipalProviderFromPrincipalIdProps,
): JSX.Element {
    const { principalId, cardPrincipal, onRender } = props;
    const [principalRecord, setPrincipalRecord] = useState<IPrincipalRecord | undefined>();
    const authContext = useContext(AuthContext);

    useEffect(() => {
        (async (): Promise<void> => {
            try {
                const client = new CorePrincipalsClient(authContext);

                const principalRecordResult = principalId
                    ? await client.getById(principalId)
                    : undefined;
                setPrincipalRecord(principalRecordResult);
            } catch (e) {
                getAppInsights()?.trackException({
                    exception: e,
                    severityLevel: SeverityLevel.Error,
                });
                setPrincipalRecord(undefined);
            }
        })();
    }, [authContext, principalId, setPrincipalRecord]);

    return (
        <CardPrincipalProviderFromPrincipalRecord
            cardPrincipal={cardPrincipal}
            principalRecord={principalRecord}
            onRender={onRender}
        />
    );
}
