import React from 'react';
import { Location } from 'history';
import EligibilitiesFilterProvider from 'components/eligibilities/eligibilities/contexts/eligibilities-filter-context';
import ViewEligibility from 'components/eligibilities/eligibilities/view/view-eligibility';

interface IViewEligibilityFilterWrapperProps {
    location: Location;
}

export default function ViewEligibilityFilterWrapper(
    props: IViewEligibilityFilterWrapperProps,
): JSX.Element {
    return (
        <EligibilitiesFilterProvider>
            <ViewEligibility {...props} />
        </EligibilitiesFilterProvider>
    );
}
