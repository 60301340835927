import React, { useRef, useState, useEffect } from 'react';
import { FontSizes, FontWeights, getTheme, mergeStyleSets } from '@fluentui/react';
import { useWindowSize } from 'components/common/use-window-size';

export interface IStep {
    label: string;
    description?: React.ReactNode;
    time: 'past' | 'current' | 'future';
    error?: boolean;
}

interface IProcessOverviewProps {
    steps: IStep[];
}

export function ProcessOverview(props: IProcessOverviewProps) {
    const { steps } = props;
    const [height, setHeight] = useState(0);
    const [heights, setHeights] = useState<number[]>();
    const windowSize = useWindowSize();
    const ref = useRef<HTMLDivElement>(null);
    const refs = useRef<(HTMLDivElement | null)[]>(Array(steps.length));
    const marginBottom = 20;

    useEffect(() => {
        if (!ref.current || !refs.current || refs.current.includes(null)) return;
        setHeight(ref.current.offsetHeight);
        setHeights(refs.current.map((e) => e!.offsetHeight + marginBottom));
    }, [windowSize, ref, refs]);

    return (
        <div className={styles.container} ref={ref}>
            <div className={styles.notchesContainer}>
                {height > 0 && heights && (
                    <Notches
                        steps={steps.map((step, index) => ({ ...step, height: heights[index] }))}
                    />
                )}
            </div>
            <div className={styles.stepsContainer}>
                {steps.map((step, index) => (
                    <div
                        ref={(ref) => {
                            refs.current[index] = ref;
                        }}
                        key={index}
                        className={styles.step}
                        style={{ marginBottom }}>
                        <div>
                            <h3 className={styles.label}>{step.label}</h3>
                        </div>
                        {step.description && (
                            <p className={styles.description}>{step.description}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

function Notches(props: { steps: (IStep & { height: number })[] }) {
    const { steps } = props;
    const height = steps.reduce((s, step) => s + step.height, 0);
    const width = 35;
    const color = getTheme().palette.themePrimary;
    const stroke = 4;
    const radius = width / 2;
    const cx = (width + stroke) / 2;

    const tops: number[] = [];
    steps.forEach(
        (step, index) =>
            (tops[index] = index === 0 ? 0 : tops[index - 1] + steps[index - 1].height),
    );

    return (
        <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg'>
            <g>
                <line
                    x1={(width + stroke) / 2}
                    y1={radius}
                    x2={(width + stroke) / 2}
                    y2={height}
                    strokeWidth={stroke}
                    stroke={color}
                    fill='none'
                />

                {steps.map((step, index) => (
                    <Notch
                        key={index}
                        color={color}
                        cx={cx}
                        cy={radius + tops[index]}
                        radius={radius}
                        step={step}
                        stroke={stroke}
                    />
                ))}
            </g>
        </svg>
    );
}

function Notch(props: {
    cx: number;
    cy: number;
    color: string;
    stroke: number;
    radius: number;
    step: IStep;
}) {
    const { cx, cy, color, radius, step } = props;
    const stroke = bound(props.stroke, 1, 5);
    const common = { cx, cy, strokeWidth: 0 };
    return (
        <g>
            <ellipse {...common} fill='#eee' rx={radius} ry={radius} />
            <ellipse {...common} fill={color} rx={radius - stroke} ry={radius - stroke} />
            {['current', 'future'].includes(step.time) && (
                <ellipse
                    {...common}
                    fill='#eee'
                    rx={radius - stroke * 2}
                    ry={radius - stroke * 2}
                />
            )}
            {step.time === 'current' && (
                <ellipse
                    {...common}
                    fill={color}
                    rx={radius - stroke * 3}
                    ry={radius - stroke * 3}
                />
            )}
        </g>
    );
}

function bound(value: number, min: number, max: number) {
    return Math.min(max, Math.max(value, min));
}

const styles = mergeStyleSets({
    container: {
        display: 'flex',
    },
    notchesContainer: {
        marginRight: '10px',
        marginTop: '2px',
    },
    stepsContainer: {
        flexGrow: '1',
    },
    step: {
        marginBottom: '20px',
        maxWidth: 600,
    },
    label: {
        marginTop: '5px',
        marginBottom: '5px',
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.large,
    },
    description: {
        fontSize: FontSizes.medium,
    },
});
