import {
    IPersonaProps,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Separator,
    Stack,
} from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import { globalSeparatorStyles } from 'assets/styles/global-styles';
import CloudScreeningClient from 'clients/cloud-screening-client';
import { CloudScreeningUserType } from 'utils/cloud-screening-utils';
import { IEmployee } from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { EmployeeNameResolverId } from 'components/common/employee/employee-name-resolver';

export interface ManagerDelegateAddRemoveProps {
    managerId: string;
}

export default function ManagerDelegateAddRemove(
    props: ManagerDelegateAddRemoveProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
    const [isRemoveDisabled, setIsRemoveDisabled] = useState<boolean>(false);
    const [canModifyDelegates, setCanModifyDelegates] = useState<boolean>(false);
    const [delegateCandidate, setDelegateCandidate] = useState<IEmployee>();
    const [triggerEmployeeSearchClear, setTriggerEmployeeSearchClear] = useState<number>(0);
    const [managerDelegateIds, setManagerDelegateIds] = useState<string[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        let isMounted = true;

        async function populateManagerDelegateIds(): Promise<void> {
            try {
                const assignments = await CloudScreeningClient.getDelegateAssignmentsByManagerId(
                    authContext,
                    props.managerId,
                );

                const delegateIds = assignments.map((assignment) => {
                    return assignment.delegatePersonnelId;
                });

                if (isMounted) {
                    setManagerDelegateIds(delegateIds);
                }
            } catch (err) {
                setErrorMessage('Error retrieving delegates.');
            }
        }

        if (props.managerId) {
            populateManagerDelegateIds();
        }

        return (): void => {
            isMounted = false;
        };
    }, [props.managerId, authContext]);

    useEffect(() => {
        const canModify =
            userContext.hasCloudScreeningUserType(CloudScreeningUserType.Admin) ||
            (userContext.hasCloudScreeningUserType(CloudScreeningUserType.Manager) &&
                props.managerId === userContext.employeeRecord.id);
        setCanModifyDelegates(canModify);
    }, [props.managerId, userContext]);

    function onCandidateSelected(persona: IPersonaProps | undefined): void {
        if (persona?.itemProp) {
            const employee = JSON.parse(persona.itemProp) as IEmployee;
            setDelegateCandidate(employee);
        } else {
            setDelegateCandidate(undefined);
        }
    }

    async function onRemoveClick(delegateId: string): Promise<void> {
        try {
            setIsRemoveDisabled(true);
            await CloudScreeningClient.deleteDelegateAssignment(
                authContext,
                delegateId,
                props.managerId,
            );
            const updatedDelegateIds = managerDelegateIds.filter((id) => id !== delegateId);
            setManagerDelegateIds(updatedDelegateIds);
        } catch (e) {
            setErrorMessage(
                'There was an error removing the delegate assignment.  Refresh the page and try again.',
            );
        } finally {
            setIsRemoveDisabled(false);
        }
    }

    return (
        <Stack>
            <Stack.Item>
                {errorMessage && (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={true}
                        dismissButtonAriaLabel='Close'
                        overflowButtonAriaLabel='See more'
                        onDismiss={(): void => setErrorMessage('')}>
                        {errorMessage}
                    </MessageBar>
                )}
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    <EmployeeNameResolverId personnelId={props.managerId} /> Delegates
                </Separator>
                <div className={styles.delegateInfo}>
                    Appointing a delegate allows them to view and request a screen for all employees
                    in your organization.
                </div>

                <div className={styles.delegatesContainer}>
                    {managerDelegateIds.map(
                        (delegateId): JSX.Element => {
                            return (
                                <div
                                    className={styles.delegateRow}
                                    key={`delegate_row_${delegateId}`}>
                                    <EmployeeBasicHoverCard
                                        key={`delegate_row_hovercard_${delegateId}`}
                                        personnelId={delegateId}
                                    />
                                    {canModifyDelegates && (
                                        <ModalActionButton<void>
                                            text={'Remove'}
                                            iconName={IconNames.Trash}
                                            errorMsg={errorMessage}
                                            modalTitle={'Remove Delegate'}
                                            enableSubmit={!isRemoveDisabled}
                                            submitButtonText={'Remove'}
                                            onButtonClick={() => setErrorMessage('')}
                                            onSubmit={(): Promise<void> => {
                                                setIsRemoveDisabled(true);
                                                return onRemoveClick(delegateId);
                                            }}
                                            onModalConcluded={() => {
                                                setIsRemoveDisabled(false);
                                                setErrorMessage('');
                                            }}>
                                            <span>
                                                Are you sure you want to remove the delegate?
                                            </span>
                                        </ModalActionButton>
                                    )}
                                </div>
                            );
                        },
                    )}
                </div>
                {canModifyDelegates && (
                    <>
                        <div className={styles.employeePickerContainer}>
                            <EmployeePickerTypeaheadSearch
                                ariaLabel='Delegate Picker'
                                key={triggerEmployeeSearchClear}
                                placeHolder={'Employee name or alias'}
                                onCandidateSelected={onCandidateSelected}
                            />
                        </div>

                        <div className={styles.assignBtnContainer}>
                            <PrimaryButton
                                text={'Assign'}
                                iconProps={{ iconName: 'ReminderPerson' }}
                                disabled={isSubmitDisabled}
                                onClick={async (): Promise<void> => {
                                    if (delegateCandidate) {
                                        if (
                                            managerDelegateIds.find(
                                                (id) => id === delegateCandidate.id,
                                            )
                                        ) {
                                            setErrorMessage('Delegate is already assigned.');
                                            return;
                                        }
                                        setIsSubmitDisabled(true);
                                        try {
                                            const assignment = await CloudScreeningClient.createDelegateAssignment(
                                                authContext,
                                                delegateCandidate.id,
                                                props.managerId,
                                            );
                                            setManagerDelegateIds([
                                                delegateCandidate.id,
                                                ...managerDelegateIds,
                                            ]);
                                        } catch (err) {
                                            setErrorMessage(
                                                'Error assigning delegate.  Refresh the page and try again.',
                                            );
                                        }
                                        setTriggerEmployeeSearchClear(
                                            triggerEmployeeSearchClear + 1,
                                        );
                                        setIsSubmitDisabled(false);
                                    } else {
                                        setErrorMessage('Please choose a delegate to assign.');
                                    }
                                }}
                                allowDisabledFocus
                            />
                        </div>
                    </>
                )}
            </Stack.Item>
        </Stack>
    );
}

const styles = mergeStyleSets({
    assignBtnContainer: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    employeePickerContainer: {
        marginBottom: '10px',
    },
    delegateInfo: {
        fontStyle: 'italic',
        marginBottom: '10x',
    },
    delegatesContainer: {
        margin: '14px 0 5px 0',
    },
    delegateRow: {
        display: 'flex',
        marginBottom: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});
