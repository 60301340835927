import React, { useEffect, useMemo, useState } from 'react';
import {
    CommandBar,
    ICommandBarItemProps,
    IDropdownOption,
    Dropdown,
    mergeStyleSets,
} from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';

export interface IPaginationProps {
    pageCount: number;
    totalItems: number;
    selectedPageIndex: number;
    onPageChange: (index: number) => void;
    onItemsPerPageChange: (count: number) => void;
}

export function Pagination(props: IPaginationProps): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [key, setKey] = useState<boolean>(true);
    const [itemsPerPage, setItemsPerPage] = useState<number>(10);

    useEffect(() => {
        setKey((prev) => !prev);
    }, [props.totalItems]);

    const commandBarPaginationItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        const paginationButtons: ICommandBarItemProps[] = [];
        let startingPage = 2;
        let endingPage = props.pageCount - 1;

        // Ensures max of 9 buttons are displayed at a time
        if (props.pageCount > 7) {
            if (props.selectedPageIndex <= 4) {
                endingPage = 5;
            } else if (props.selectedPageIndex >= props.pageCount - 3) {
                startingPage = props.pageCount - 4;
            } else {
                startingPage = props.selectedPageIndex - 1;
                endingPage = props.selectedPageIndex + 1;
            }
        }

        // Previous page button
        paginationButtons.push({
            style: { ...prevNextButtonStyle },
            key: 'prevPage',
            iconProps: { iconName: 'ChevronLeft' },
            onClick: () => props.onPageChange(props.selectedPageIndex - 1),
            disabled: props.selectedPageIndex === 1,
        });

        // First page button
        paginationButtons.push({
            key: 'firstPage',
            text: '1',
            onClick: () => props.onPageChange(1),
            disabled: props.selectedPageIndex === 1,
            className:
                1 === props.selectedPageIndex
                    ? style.numberedButtonClickedStyle
                    : style.numberedButtonStyle,
        });

        // Ellipsis
        if (startingPage - 1 > 1) {
            paginationButtons.push({
                key: 'firstEllipsis',
                iconProps: { iconName: 'More', styles: { root: { color: 'black' } } },
                disabled: false,
                className: style.ellipsisStyle,
            });
        }

        // Numbered intermediate page buttons
        for (let i = startingPage; i <= endingPage; i++) {
            paginationButtons.push({
                key: `page_${i}`,
                text: i.toString(),
                onClick: () => props.onPageChange(i),
                disabled: i === props.selectedPageIndex,
                className:
                    i === props.selectedPageIndex
                        ? style.numberedButtonClickedStyle
                        : style.numberedButtonStyle,
            });
        }

        // Ellipsis
        if (endingPage + 1 < props.pageCount) {
            paginationButtons.push({
                key: 'lastEllipsis',
                iconProps: {
                    iconName: 'More',
                    styles: { root: { color: 'black' } },
                },
                disabled: false,
                className: style.ellipsisStyle,
            });
        }

        // Last page button
        if (props.pageCount > 1) {
            paginationButtons.push({
                key: 'lastPage',
                text: props.pageCount.toString(),
                onClick: () => props.onPageChange(props.pageCount),
                disabled: props.selectedPageIndex === props.pageCount,
                className:
                    props.pageCount === props.selectedPageIndex
                        ? style.numberedButtonClickedStyle
                        : style.numberedButtonStyle,
            });
        }

        // Next page button
        paginationButtons.push({
            style: { ...prevNextButtonStyle },
            key: 'nextPage',
            iconProps: { iconName: 'ChevronRight' },
            onClick: () => props.onPageChange(props.selectedPageIndex + 1),
            disabled: props.selectedPageIndex === props.pageCount,
        });

        // Items per page dropdown
        const itemsPerPageOptions: IDropdownOption[] = [
            { key: 'itemsPerPage_10', text: '10', selected: itemsPerPage === 10 },
            { key: 'itemsPerPage_25', text: '25', selected: itemsPerPage === 25 },
            { key: 'itemsPerPage_50', text: '50', selected: itemsPerPage === 50 },
            { key: 'itemsPerPage_75', text: '75', selected: itemsPerPage === 75 },
            { key: 'itemsPerPage_100', text: '100', selected: itemsPerPage === 100 },
        ];
        paginationButtons.push({
            key: 'itemsPerPage',
            onRender: () => (
                <Dropdown
                    styles={{ ...dropdownStyles }}
                    options={itemsPerPageOptions}
                    onChange={(t, item) => {
                        props.onItemsPerPageChange(parseInt(item!.text));
                        setItemsPerPage(parseInt(item!.text));
                        setKey((prev) => !prev);
                    }}
                    placeholder='Items per page'
                />
            ),
        });

        return paginationButtons;
    }, [itemsPerPage, props]);

    return (
        <div style={{ marginTop: '60px' }}>
            <CommandBar
                styles={{
                    root: {
                        paddingLeft: 0,
                        background: globalStyles.backgroundColor,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    },
                }}
                key={`${key}`}
                items={commandBarPaginationItems}
            />
        </div>
    );
}

const prevNextButtonStyle = {
    border: '1px solid #D1D1D1',
    borderRadius: '4px',
    margin: '0 14px 0 14px',
    minWidth: '10px',
    height: '50%',
    width: '25px',
};

const dropdownStyles = {
    title: {
        height: '23px',
        borderRadius: '4px',
        border: '1px solid #D1D1D1',
        lineHeight: '22.4px',
        marginLeft: '20px',
    },
    caretDownWrapper: {
        lineHeight: '25px',
    },
};

const style = mergeStyleSets({
    ellipsisStyle: {
        borderRadius: '4px',
        margin: '0 0 0 0',
        minWidth: '10px',
        height: '50%',
        backgroundColor: 'transparent',
        ':hover': {
            backgroundColor: 'transparent',
            cursor: 'default',
            '.ms-Button-icon': {
                color: 'black',
            },
        },
        '.ms-Button-flexContainer': {
            cursor: 'default',
        },
        '.ms-CommandBarItem-link': {
            cursor: 'default',
        },
    },
    numberedButtonStyle: {
        border: '1px solid #D1D1D1',
        borderRadius: '4px',
        margin: '0 6px 0 6px',
        minWidth: '10px',
        height: '50%',
        color: 'black',
        width: '25px',
    },
    numberedButtonClickedStyle: {
        border: '1px solid',
        borderColor: '#D1D1D1',
        borderRadius: '4px',
        margin: '0 6px 0 6px',
        minWidth: '10px',
        height: '50%',
        color: 'black',
        backgroundColor: '#EBEBEB',
        width: '25px',
    },
});
