import React, { useContext, useState, ReactNode, createContext } from 'react';
import VisitorClient, { ISearchVisitorRequest, IVisitorProfile } from 'clients/visitor-client';
import { AuthContext } from 'contexts/auth-context';
import { IPagedResults } from 'clients/http-options';
import { VisitorsFilterContext } from 'components/visitors/visitors-filter-context';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { SetStateFunc } from 'types/global-types';

interface IContextValue {
    visitors: IVisitorProfile[];
    isLoading: boolean;
    continuationToken: string | undefined;
    warningLoadingVisitor: string | undefined;
    hasErrorLoadingVisitors: boolean;
    setVisitors: SetStateFunc<IVisitorProfile[]>;
    fetchVisitors: (isMounted: () => boolean, params?: IFetchVisitors) => Promise<void>;
    setTheAddedVisitorsSet: SetStateFunc<Set<string>>;
    setWarningLoadingVisitor: SetStateFunc<string | undefined>;
    setHasErrorLoadingVisitors: SetStateFunc<boolean>;
}

interface IFetchVisitors {
    replaceTable?: boolean; // Defalut: false
    withClearFilters?: boolean; // Default: false
}

export const VisitorsDataContext = createContext<IContextValue>(null!);

export interface IVisitorsProviderProps {
    children: ReactNode;
}

const initVisitorSearchFields = (): ISearchVisitorRequest => ({
    id: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    company: '',
    title: '',
    sponsor: '',
});

export default function VisitorsFetchContextProvider(props: IVisitorsProviderProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const filterContext = useContext(VisitorsFilterContext);

    const [visitors, setVisitors] = useState<IVisitorProfile[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [continuationToken, setContinuationToken] = useState<string>();
    const [theAddedVisitorsSet, setTheAddedVisitorsSet] = useState<Set<string>>(new Set());
    const [warningLoadingVisitor, setWarningLoadingVisitor] = useState<string | undefined>();
    const [hasErrorLoadingVisitors, setHasErrorLoadingVisitors] = useState<boolean>(false);

    const fetchVisitors = async (
        isMounted: () => boolean,
        params?: IFetchVisitors,
    ): Promise<void> => {
        if (!isMounted() || isLoading) return;

        setWarningLoadingVisitor('');
        try {
            setIsLoading(true);

            let visitorsResults: IPagedResults<IVisitorProfile>;

            if (!!params?.withClearFilters) {
                visitorsResults = await VisitorClient.getVisitorSearchResults(
                    authContext,
                    initVisitorSearchFields(),
                    '',
                );
            } else {
                visitorsResults = await VisitorClient.getVisitorSearchResults(
                    authContext,
                    {
                        id: filterContext.visitorId,
                        firstName: filterContext.firstName,
                        middleName: filterContext.middleName,
                        lastName: filterContext.lastName,
                        email: filterContext.email,
                        company: filterContext.company,
                        title: filterContext.title,
                        sponsor: filterContext.sponsor?.id ?? '',
                    },
                    params?.replaceTable ? '' : continuationToken,
                );
            }

            if (isMounted()) {
                if (!!params?.replaceTable) {
                    setVisitors(visitorsResults.results);
                    setTheAddedVisitorsSet(new Set());
                    if (visitorsResults.results?.length === 0) {
                        setWarningLoadingVisitor('No matching visitor found');
                    }
                } else {
                    const { results } = visitorsResults;
                    const filtered = results.filter(
                        (visitor) => !theAddedVisitorsSet.has(visitor.id),
                    );
                    setVisitors((currentValue) => [...currentValue].concat(filtered));
                }
                setContinuationToken(visitorsResults.continuationToken);
            }
        } catch (e) {
            setHasErrorLoadingVisitors(true);
        } finally {
            if (isMounted()) {
                setIsLoading(false);
            }
        }
    };

    return (
        <VisitorsDataContext.Provider
            value={{
                visitors,
                isLoading,
                continuationToken,
                warningLoadingVisitor,
                hasErrorLoadingVisitors,
                setVisitors,
                fetchVisitors,
                setTheAddedVisitorsSet,
                setWarningLoadingVisitor,
                setHasErrorLoadingVisitors,
            }}>
            {props.children}
        </VisitorsDataContext.Provider>
    );
}

export function VisitorLoadWarningMsgBar(): JSX.Element {
    const dataContext = useContext(VisitorsDataContext);
    return !!dataContext.warningLoadingVisitor ? (
        <MessageBar
            onDismiss={(): void => dataContext.setWarningLoadingVisitor(undefined)}
            messageBarType={MessageBarType.warning}
            dismissButtonAriaLabel='Close'>
            {dataContext.warningLoadingVisitor}
        </MessageBar>
    ) : (
        <></>
    );
}

export function VisitorLoadErrorMsgBar(): JSX.Element {
    const dataContext = useContext(VisitorsDataContext);
    return dataContext.hasErrorLoadingVisitors ? (
        <MessageBar
            onDismiss={(): void => dataContext.setHasErrorLoadingVisitors(false)}
            messageBarType={MessageBarType.error}
            dismissButtonAriaLabel='Close'>
            {'Encountered error loading visitor data'}
        </MessageBar>
    ) : (
        <></>
    );
}
