import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    Icon,
    mergeStyleSets,
    Persona,
    PersonaInitialsColor,
    PersonaPresence,
    PersonaSize,
    ActionButton,
    Stack,
    NeutralColors,
} from '@fluentui/react';
import { AppConstants } from 'components/common/constants';
import { dateToFormattedTimeString } from 'utils/time-utils';
import { IActivity, IActivityValue } from 'clients/activities-client';
import { AuthContext } from 'contexts/auth-context';
import EmployeeClient, { PublicEmployeeEditableFields } from 'clients/employee-client';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import TemplateClient, {
    IDocumentMetadata,
    IScreeningTemplateRequestRecord,
} from 'clients/template-client';
import DocumentsClient, { IDocumentRecord } from 'clients/documents-client';
import { getImageUrlFromAlias } from 'utils/photo-utils';
import { isGUID } from 'utils/string-utils';
import {
    ScreeningStateLabels,
    ScreeningParentStateType,
    ScreeningPaths,
} from 'components/screening/common/common-constants';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import BoldFont from 'components/common/misc/bold-font';
import PublicTrustDocumentClient from 'clients/screening/public-trust-document-client';
import { IconNames } from 'assets/constants/global-constants';
import { getEquipmentTypeDisplayText } from 'components/facilities/facilities-equipment/util/facilities-equipment-utils';

//Find icons at https://developer.microsoft.com/en-us/fluentui#/styles/web/icons
//'key' is the truncated date of the activities contained in its activity[]
// ex: key: 1601449200000 -- would contain activities from just the day of 9 30, 2020
export type ActivityEventBin = { key: number; activities: IActivity[] };

export function findDocumentViewerEvents(
    bins: ActivityEventBin[],
    template: IScreeningTemplateRequestRecord,
    document: IDocumentRecord,
): ActivityEventBin[] {
    return bins
        .map((bin: ActivityEventBin) => {
            const filteredActivities = bin.activities.filter((x: IActivity) => {
                if (x.directObject.type === 'templateId') {
                    return x.directObject.value === template.documentId;
                } else if (x.directObject.type === 'DocumentId') {
                    return x.directObject.value === document.id;
                }
                return false;
            });
            return { key: bin.key, activities: filteredActivities };
        })
        .filter((bin: ActivityEventBin) => {
            return bin.activities.length > 0;
        });
}

function defaultToDate(activity: IActivity): number {
    const date = new Date(activity.eventTimestampUTC);
    date.setHours(0);
    date.setMilliseconds(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date.valueOf();
}

function defaultToTime(activity: IActivity): number {
    return activity.eventTimestampUTC;
}

export function binSortEvents(
    events: IActivity[],
    dateActivityBin: ActivityEventBin[] = [],
    fnToDate: (activity: IActivity) => number = defaultToDate,
    fnToTime: (activity: IActivity) => number = defaultToTime,
): ActivityEventBin[] {
    for (let i = 0; i < events.length; i++) {
        const activity = events[i];
        const key = fnToDate(activity);

        let keyActivitiesObject = dateActivityBin.filter((x) => x.key === key)?.pop();
        if (!keyActivitiesObject) {
            keyActivitiesObject = { key: key, activities: [] };
            dateActivityBin.push(keyActivitiesObject);
        }
        keyActivitiesObject.activities.push(activity);
    }

    // Sort buckets by date
    dateActivityBin.sort((bin1: ActivityEventBin, bin2: ActivityEventBin): number => {
        return bin1.key - bin2.key;
    });

    // Sort activities in each bucket by time
    const activitySortFn = (act1: IActivity, act2: IActivity): number => {
        return fnToTime(act1) - fnToTime(act2);
    };

    dateActivityBin.forEach((bin) => bin.activities.sort(activitySortFn));

    return dateActivityBin;
}

interface EventProps {
    activity: IActivity;
    screeningId: string;
    provideDocumentsNameForDocumentAppActivities?: boolean;
    screeningPath?: ScreeningPaths;
    timeZone?: string;
    displayTimeZone?: boolean;
}

export default function Event(props: EventProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [subjectDisplay, setSubjectDisplay] = useState<string>('');
    const [directDisplay, setDirectDisplay] = useState<string>('');
    const [additionalPositionDisplay, setAdditionalPositionDisplay] = useState<string[]>([]);
    const [subjectAlias, setSubjectAlias] = useState<string>('');

    const eventDocumentId = useMemo((): string | undefined => {
        const documentIdFromAdditionalObjects = props.activity.additionalObjects?.find(
            (x) => x.type.toLowerCase() === 'documentid',
        );

        if (documentIdFromAdditionalObjects) {
            return documentIdFromAdditionalObjects?.value;
        }

        const documentIdFromMetadata = props.activity.metadata?.documentId;
        if (documentIdFromMetadata) {
            return documentIdFromMetadata;
        }

        return undefined;
    }, [props.activity.additionalObjects, props.activity.metadata?.documentId]);

    useEffect(() => {
        async function populateEventForScreeningActivity(): Promise<void> {
            try {
                if (props.activity.subject) {
                    setSubjectDisplay(await getValue(props.activity.subject));
                }
                if (props.activity.directObject) {
                    setDirectDisplay(await getValue(props.activity.directObject));
                }
                if (props.activity.additionalObjects) {
                    const additionalArray = [];
                    for (let i = 0; i < props.activity.additionalObjects.length; i++) {
                        additionalArray[i] = await getValue(props.activity.additionalObjects[i]);
                    }
                    setAdditionalPositionDisplay(additionalArray);
                }
            } catch (e) {
                console.log('ScreeningApp --- Error getting something in events', e);
            }
        }

        async function populateEventForFacilitiesActivity(): Promise<void> {
            try {
                if (props.activity.subject) {
                    setSubjectDisplay(await getValue(props.activity.subject));
                }
                if (props.activity.directObject) {
                    setDirectDisplay(await getValue(props.activity.directObject));
                }
                if (props.activity.additionalObjects) {
                    const additionalArray = [];
                    for (let i = 0; i < props.activity.additionalObjects.length; i++) {
                        additionalArray[i] = await getValue(props.activity.additionalObjects[i]);
                    }
                    setAdditionalPositionDisplay(additionalArray);
                }
            } catch (e) {
                console.log('FacilitiesApp --- Error getting something in events', e);
            }
        }

        async function popuplateEventForProfileActivity(): Promise<void> {
            try {
                if (props.activity.subject) {
                    setSubjectDisplay(await getValue(props.activity.subject));
                    const employee = await EmployeeClient.getEmployeeByAliasOrId(
                        authContext,
                        props.activity.subject.value,
                    );
                    setSubjectAlias(employee.alias);
                }
                if (props.activity.directObject) {
                    setDirectDisplay(await getValue(props.activity.directObject));
                }
                if (props.activity.additionalObjects) {
                    const additionalArray = [];
                    for (let i = 0; i < props.activity.additionalObjects.length; i++) {
                        additionalArray[i] = await getValue(props.activity.additionalObjects[i]);
                    }
                    setAdditionalPositionDisplay(additionalArray);
                }
            } catch (e) {
                console.log('ProfileApp --- Error getting something in events', e);
            }
        }

        switch (props.activity.appName) {
            case 'profileApp':
                popuplateEventForProfileActivity();
                break;
            case 'facilitiesApp':
                populateEventForFacilitiesActivity();
                break;
            case 'documentApp':
            case 'screeningApp':
            default:
                populateEventForScreeningActivity();
                break;
        }
    }, [authContext]);

    /**
     * At some point this should be refactored to be return renderable jsx things and not strings
     * List an employeehover card when personnel is referenced.
     */
    async function getValue(activityValue: IActivityValue): Promise<string> {
        let retStr = activityValue.value;

        // If the Metadata has the value looking for then use that
        if (props.activity.metadata && props.activity.metadata[activityValue.value]) {
            return props.activity.metadata[activityValue.value];
        }

        if (activityValue.type) {
            switch (activityValue.type.toLowerCase().trim()) {
                case 'personnelid':
                case 'personid':
                case 'ownerpersonnelid':
                    if (activityValue.value) {
                        retStr = activityValue.value;
                        if (isGUID(activityValue.value)) {
                            const editableEmployeeRecord = await EmployeeClient.getEditableEmployeeDataByIdOrAliasOrGUID(
                                authContext,
                                activityValue.value,
                                Object.values(PublicEmployeeEditableFields),
                            );
                            if (editableEmployeeRecord) {
                                retStr = [
                                    editableEmployeeRecord.employeeEditableInfo.firstName,
                                    editableEmployeeRecord.employeeEditableInfo.lastName,
                                ].join(' ');
                            }
                        } else {
                            const basicResult = await EmployeeClient.getBasicEmployeesById(
                                authContext,
                                [activityValue.value],
                            );
                            const employee = basicResult[0];
                            if (employee) {
                                retStr = employee.displayName
                                    ? employee.displayName
                                    : employee.onPremisesSamAccountName;
                            }
                        }
                    } else {
                        retStr = 'Unassigned';
                    }
                    break;
                case 'templateid':
                    if (activityValue.value) {
                        const documentIds: Set<string> = new Set<string>();
                        documentIds.add(activityValue.value);
                        let metadatas: IDocumentMetadata[] = [];
                        try {
                            if (props.screeningPath === ScreeningPaths.UsGov) {
                                metadatas = await TemplateClient.getDocumentMetadataFromDocumentIds(
                                    authContext,
                                    documentIds,
                                );
                            } else {
                                metadatas = await PublicTrustDocumentClient.getDocumentMetadataFromDocumentIds(
                                    authContext,
                                    documentIds,
                                );
                            }
                            if (metadatas) {
                                const metadata = metadatas.pop();
                                if (metadata) {
                                    retStr = metadata.title;
                                }
                            }
                        } catch (e) {
                            console.error('failed to get meta details');
                        }
                    }
                    break;
                case 'user':
                    if (activityValue.value) {
                        retStr = activityValue.value;
                        if (isGUID(activityValue.value)) {
                            const editableEmployeeRecord = await EmployeeClient.getEditableEmployeeDataByIdOrAliasOrGUID(
                                authContext,
                                activityValue.value,
                                Object.values(PublicEmployeeEditableFields),
                            );
                            if (editableEmployeeRecord) {
                                retStr = [
                                    editableEmployeeRecord.employeeEditableInfo.firstName,
                                    editableEmployeeRecord.employeeEditableInfo.lastName,
                                ].join(' ');
                            }
                        } else {
                            const basicResult = await EmployeeClient.getBasicEmployeesByAlias(
                                authContext,
                                [activityValue.value],
                            );
                            const employee = basicResult[0];
                            if (employee) {
                                retStr = employee.displayName
                                    ? employee.displayName
                                    : employee.onPremisesSamAccountName;
                            }
                        }
                    } else {
                        retStr = 'Unassigned';
                    }
                    break;
                case 'documentid':
                    if (props.provideDocumentsNameForDocumentAppActivities) {
                        if (
                            props.activity?.additionalObjects[0]?.type?.toLowerCase() ===
                            'documentname'
                        ) {
                            retStr = props.activity.additionalObjects[0].value;
                        } else {
                            if (activityValue.value) {
                                try {
                                    const documentToken = await DocumentsClient.getDocumentToken(
                                        authContext,
                                        props.screeningId,
                                        props.screeningPath ?? ScreeningPaths.UsGov,
                                    );
                                    const documentData = await DocumentsClient.getDocumentById(
                                        authContext,
                                        activityValue.value,
                                        documentToken.token,
                                    );
                                    retStr = documentData.fileName;
                                } catch (e) {
                                    console.log(
                                        'Error getting document data',
                                        activityValue,
                                        ' -- ',
                                        e,
                                    );
                                }
                            }
                        }
                    } else {
                        retStr = '';
                    }
                    break;
            }
        }
        return retStr;
    }

    function defaultByIdSpan(icon: string, text: string): JSX.Element {
        return (
            <>
                <Icon iconName={icon} className={styles.icon} />
                <span className={styles.text}>
                    <EmployeeHoverCard personnelId={props.activity.subject.value}>
                        <span className={styles.strong}>{subjectDisplay}</span>
                    </EmployeeHoverCard>{' '}
                    {text}
                    {'.'}
                </span>
            </>
        );
    }

    function defaultSpanForDocumentEvent(icon: string, text: string): JSX.Element {
        return (
            <>
                <Icon iconName={icon} className={styles.icon} />
                <span className={styles.text}>
                    <EmployeeHoverCard personnelId={props.activity.subject.value}>
                        <span className={styles.strong}>{subjectDisplay}</span>
                    </EmployeeHoverCard>{' '}
                    {text}{' '}
                    {props.provideDocumentsNameForDocumentAppActivities &&
                    additionalPositionDisplay &&
                    additionalPositionDisplay[0] ? (
                        <span className={styles.strong}>{additionalPositionDisplay[0]}</span>
                    ) : (
                        ''
                    )}
                    {'.'}
                    {eventDocumentId && (
                        <span>
                            {' ('}
                            {eventDocumentId}
                            {')'}
                        </span>
                    )}
                </span>
            </>
        );
    }

    type EventStringToElement = { [key: string]: { span(): JSX.Element } };

    const documentEventToSpan: EventStringToElement = {
        'empty': {
            span: (): JSX.Element => {
                return defaultSpanForDocumentEvent('FieldEmpty', 'made empty document');
            },
        },
        'created': {
            span: (): JSX.Element => {
                return defaultSpanForDocumentEvent('CloudUpload', 'uploaded');
            },
        },
        'document-added-notes': {
            span: (): JSX.Element => {
                return documentNotesTextBlock();
            },
        },
        'shared': {
            span: (): JSX.Element => {
                return defaultSpanForDocumentEvent('TextDocumentShared', 'shared document');
            },
        },
        'updated': {
            span: (): JSX.Element => {
                return defaultSpanForDocumentEvent('PageEdit', 'updated');
            },
        },
        'locked': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='lock' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            locked{' '}
                            {props.provideDocumentsNameForDocumentAppActivities ? (
                                <span className={styles.strong}>{directDisplay}</span>
                            ) : (
                                ''
                            )}
                            {'.'}
                        </span>
                    </>
                );
            },
        },
        'unlocked': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='unlock' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            unlocked{' '}
                            {props.provideDocumentsNameForDocumentAppActivities ? (
                                <span className={styles.strong}>{directDisplay}</span>
                            ) : (
                                ''
                            )}
                            {'.'}
                        </span>
                    </>
                );
            },
        },
    };

    const documentRestoreTextBlock = (): JSX.Element => {
        return (
            <span className={styles.text}>
                Document <BoldFont>{directDisplay}</BoldFont>
                {' was recovered by '}
                <EmployeeHoverCard personnelId={props.activity.subject.value}>
                    <span className={styles.strong}>{subjectDisplay}</span>
                </EmployeeHoverCard>{' '}
                {eventDocumentId && (
                    <>
                        {' ('}
                        {eventDocumentId}
                        {')'}
                    </>
                )}
            </span>
        );
    };

    const documentRetentionPolicyTextBlock = (): JSX.Element => {
        return (
            <span className={styles.text}>
                Document <BoldFont>{subjectDisplay}</BoldFont>
                {' was deleted by Personnel based on the '}
                <BoldFont>{directDisplay}</BoldFont>
                {' retention policy.'}
                {eventDocumentId && (
                    <>
                        {' ('}
                        {eventDocumentId}
                        {')'}
                    </>
                )}
            </span>
        );
    };

    const documentNotesTextBlock = (): JSX.Element => {
        return (
            <>
                <Icon iconName='AddNotes' className={styles.icon} />
                <span className={styles.text}>
                    <EmployeeHoverCard personnelId={props.activity.subject.value}>
                        <span className={styles.strong}>{subjectDisplay}</span>
                    </EmployeeHoverCard>{' '}
                    added notes:{' '}
                    {props.provideDocumentsNameForDocumentAppActivities && (
                        <span>{directDisplay}</span>
                    )}
                </span>
            </>
        );
    };

    const profileEventToSpan: EventStringToElement = {
        'document-restore': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Persona
                            className={styles.icon}
                            size={PersonaSize.size24}
                            initialsColor={PersonaInitialsColor.purple}
                            presence={PersonaPresence.none}
                            hidePersonaDetails={true}
                            key={subjectAlias}
                            showInitialsUntilImageLoads={false}
                        />
                        <div className={styles.noteBox}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            <span className={styles.noteBoxTime}>
                                {dateToFormattedTimeString(props.activity.eventTimestampUTC)}
                            </span>
                            <div>{documentRestoreTextBlock()}</div>
                        </div>
                    </>
                );
            },
        },
        'document-retention-policy-delete': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Persona
                            className={styles.icon}
                            size={PersonaSize.size24}
                            initialsColor={PersonaInitialsColor.purple}
                            presence={PersonaPresence.none}
                            hidePersonaDetails={true}
                            key={subjectAlias}
                            showInitialsUntilImageLoads={false}
                        />
                        <div className={styles.noteBox}>
                            <span className={styles.strong}>Personnel</span>{' '}
                            <span className={styles.noteBoxTime}>
                                {dateToFormattedTimeString(props.activity.eventTimestampUTC)}
                            </span>
                            <div>{documentRetentionPolicyTextBlock()}</div>
                        </div>
                    </>
                );
            },
        },
        'noted': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Persona
                            className={styles.icon}
                            size={PersonaSize.size24}
                            initialsColor={PersonaInitialsColor.purple}
                            presence={PersonaPresence.none}
                            hidePersonaDetails={true}
                            key={subjectAlias}
                            showInitialsUntilImageLoads={false}
                            imageUrl={getImageUrlFromAlias(subjectAlias)}
                        />
                        <div className={styles.noteBox}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            <span className={styles.noteBoxTime}>
                                {dateToFormattedTimeString(props.activity.eventTimestampUTC)}
                            </span>
                            <div>
                                {props.activity.message}
                                {eventDocumentId && (
                                    <>
                                        {' ('}
                                        {eventDocumentId}
                                        {')'}
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                );
            },
        },
    };

    const facilitiesEventToSpan: EventStringToElement = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'facility_created': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Add' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                created facility <BoldFont>{directDisplay}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'facility_updated': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Edit' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                updated facility <BoldFont>{directDisplay}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'facility_deleted': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Delete' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                deleted facility <BoldFont>{directDisplay}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'seat_created': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Add' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                created seat <BoldFont>{directDisplay}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'seat_updated': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Edit' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                updated seat <BoldFont>{directDisplay}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'seat_deleted': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Delete' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                deleted seat <BoldFont>{directDisplay}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'reservation_preclaimed': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='UserOptional' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                preclaimed reservation for seat{' '}
                                <BoldFont>{additionalPositionDisplay[3]}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'reservation_confirmed': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='UserFollowed' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                confirmed reservation for seat{' '}
                                <BoldFont>{additionalPositionDisplay[3]}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'reservation_checked_in': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Door' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                checked in to their reservation for seat{' '}
                                <BoldFont>{additionalPositionDisplay[3]}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'reservation_checked_out': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Door' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                checked out of their reservation for seat{' '}
                                <BoldFont>{additionalPositionDisplay[3]}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'reservation_missed': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='UserWarning' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                missed their reservation for seat{' '}
                                <BoldFont>{additionalPositionDisplay[3]}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'reservation_cancelled': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='UserRemove' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                cancelled their reservation for seat{' '}
                                <BoldFont>{additionalPositionDisplay[3]}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'reservation_seat_changed': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='UserSync' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                changed their reservation from seat{' '}
                                <BoldFont>{additionalPositionDisplay[1]}</BoldFont> to seat{' '}
                                <BoldFont>{additionalPositionDisplay[2]}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'equipment_created': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Add' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                created equipment{' '}
                                <BoldFont>{getEquipmentTypeDisplayText(directDisplay)}</BoldFont>
                                {props.activity.additionalObjects.length > 1 && (
                                    <span> for&nbsp;</span>
                                )}
                            </Stack.Item>
                            {props.activity.additionalObjects.length > 1 && (
                                <Stack.Item>
                                    <EmployeeBasicHoverCard
                                        personnelId={props.activity.additionalObjects[1].value}
                                        key={props.activity.additionalObjects[1].value}
                                    />
                                </Stack.Item>
                            )}
                            <Stack.Item className={styles.adjustedText}>.</Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'equipment_updated': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Edit' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                updated equipment{' '}
                                <BoldFont>{getEquipmentTypeDisplayText(directDisplay)}</BoldFont>
                                {props.activity.additionalObjects.length > 1 && (
                                    <span> owned by&nbsp;</span>
                                )}
                            </Stack.Item>
                            {props.activity.additionalObjects.length > 1 && (
                                <Stack.Item>
                                    <EmployeeBasicHoverCard
                                        personnelId={props.activity.additionalObjects[1].value}
                                        key={props.activity.additionalObjects[1].value}
                                    />
                                </Stack.Item>
                            )}
                            <Stack.Item className={styles.adjustedText}>.</Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'equipment_deleted': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Delete' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                deleted equipment{' '}
                                <BoldFont>{getEquipmentTypeDisplayText(directDisplay)}</BoldFont>
                                {props.activity.additionalObjects.length > 1 && (
                                    <span> owned by&nbsp;</span>
                                )}
                            </Stack.Item>
                            {props.activity.additionalObjects.length > 1 && (
                                <Stack.Item>
                                    <EmployeeBasicHoverCard
                                        personnelId={props.activity.additionalObjects[1].value}
                                        key={props.activity.additionalObjects[1].value}
                                    />
                                </Stack.Item>
                            )}
                            <Stack.Item className={styles.adjustedText}>.</Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'equipment_checked_out': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='PaymentCard' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                checked out their equipment{' '}
                                <BoldFont>{getEquipmentTypeDisplayText(directDisplay)}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'equipment_checked_in': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='PaymentCard' className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                checked in their equipment{' '}
                                <BoldFont>{getEquipmentTypeDisplayText(directDisplay)}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'book_log_created': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName={IconNames.Signin} className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                signed into log book for facility{' '}
                                <BoldFont>{additionalPositionDisplay[0]}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'book_log_deleted': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName={IconNames.SignOut} className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                signed out of log book for facility{' '}
                                <BoldFont>{additionalPositionDisplay[0]}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'blocked_facility_user_record_created': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName={IconNames.Cancel} className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                blocked from reserving ahead at facility{' '}
                                <BoldFont>{additionalPositionDisplay[0]}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'blocked_facility_user_record_deleted': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName={IconNames.CheckMark} className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item>
                                <EmployeeBasicHoverCard
                                    personnelId={props.activity.subject.value}
                                    key={props.activity.subject.value}
                                />
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item className={styles.adjustedText}>
                                unblocked from reserving ahead at facility{' '}
                                <BoldFont>{additionalPositionDisplay[0]}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'user_feedback_record_created': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName={IconNames.FavoriteStar} className={styles.icon} />
                        <Stack horizontal verticalAlign='center'>
                            <Stack.Item className={styles.adjustedText}>
                                {props.activity.subject.value} star user feedback submitted for
                                facility <BoldFont>{additionalPositionDisplay[0]}</BoldFont>.
                            </Stack.Item>
                        </Stack>
                    </>
                );
            },
        },
    };

    const screeningEventToSpan: EventStringToElement = {
        'edited': {
            span: (): JSX.Element => {
                return defaultByIdSpan('ChangeEntitlements', 'edited screening data');
            },
        },
        'nominated': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='AddFriend' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            nominated{' '}
                            <EmployeeHoverCard personnelId={props.activity.directObject.value}>
                                <span className={styles.strong}>{directDisplay}</span>
                            </EmployeeHoverCard>
                            {'.'}
                        </span>
                    </>
                );
            },
        },
        'converted': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='DoubleDownArrow' className={styles.convertIcon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard
                                personnelId={props.activity.additionalObjects[0].value}>
                                <span className={styles.strong}>
                                    {additionalPositionDisplay[0]}
                                </span>
                            </EmployeeHoverCard>{' '}
                            converted{' '}
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            to{' '}
                            <EmployeeHoverCard personnelId={props.activity.directObject.value}>
                                <span className={styles.strong}>{directDisplay}</span>
                            </EmployeeHoverCard>
                            {'.'}
                        </span>
                    </>
                );
            },
        },
        'accepted': {
            span: (): JSX.Element => {
                return defaultByIdSpan('InsertSignatureLine', 'accepted screening');
            },
        },
        'declined': {
            span: (): JSX.Element => {
                return defaultByIdSpan('drm', 'declined the nomination');
            },
        },
        'approved': {
            span: (): JSX.Element => {
                return defaultByIdSpan('CheckboxComposite', 'approved the nomination');
            },
        },
        'denied': {
            span: (): JSX.Element => {
                return defaultByIdSpan('StatusErrorFull', 'denied the nomination');
            },
        },
        'state-change': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='switch' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            changed status to{' '}
                            <span className={styles.strong}>
                                {getStateDisplayLabel(props.activity)}
                            </span>
                            {'.'}
                        </span>
                    </>
                );
            },
        },
        'sub-state-change': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='switch' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            changed sub-status to{' '}
                            <span className={styles.strong}>
                                {getSubstateDisplayLabel(props.activity)}
                            </span>
                            {'.'}
                        </span>
                    </>
                );
            },
        },
        'adjudicated-change': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='switch' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            changed adjudicated decision to{' '}
                            <span className={styles.strong}>
                                {getSubstateDisplayLabel(props.activity)}
                            </span>
                            {'.'}
                        </span>
                    </>
                );
            },
        },
        'change-status': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='FollowUser' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            changed status to{' '}
                            <span className={styles.strong}>
                                {additionalPositionDisplay && additionalPositionDisplay[0]
                                    ? additionalPositionDisplay[0]
                                    : 'notset'}
                            </span>
                            {'.'}
                        </span>
                    </>
                );
            },
        },
        'change-processowner': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='FabricUserFolder' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            changed process owner to{' '}
                            <span className={styles.strong}>
                                {additionalPositionDisplay && additionalPositionDisplay[0]
                                    ? additionalPositionDisplay[0]
                                    : 'notset'}
                            </span>
                            {'.'}
                        </span>
                    </>
                );
            },
        },
        'document-added-notes': {
            span: (): JSX.Element => {
                return documentNotesTextBlock();
            },
        },
        'document-request': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='FileRequest' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            requested <span className={styles.strong}>{directDisplay}</span>
                            {'.'}
                        </span>
                    </>
                );
            },
        },
        'document-request-update': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Edit' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            requested updates on{' '}
                            <span className={styles.strong}>{directDisplay}</span>
                            {'.'}
                        </span>
                    </>
                );
            },
        },
        'document-request-update-cancel': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='Uneditable' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            cancelled requested updates on{' '}
                            <span className={styles.strong}>{directDisplay}</span>
                            {'.'}
                        </span>
                    </>
                );
            },
        },
        'document-request-delete': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='PageRemove' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>{' '}
                            deleted request <span className={styles.strong}>{directDisplay}</span>
                            {'.'}
                        </span>
                    </>
                );
            },
        },
        'document-retention-policy-delete': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='PageRemove' className={styles.icon} />
                        {documentRetentionPolicyTextBlock()}
                    </>
                );
            },
        },
        'document-restore': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='DocumentReply' className={styles.icon} />
                        {documentRestoreTextBlock()}
                    </>
                );
            },
        },
        'adhoc-document-uploaded': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='CloudUpload' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>
                            {' uploaded '}
                            <span className={styles.strong}>{directDisplay}</span>
                            {'.'}
                            {eventDocumentId && (
                                <>
                                    {' ('}
                                    {eventDocumentId}
                                    {')'}
                                </>
                            )}
                        </span>
                    </>
                );
            },
        },
        'document-uploaded': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='CloudUpload' className={styles.icon} />
                        <span className={styles.text}>
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>
                            {' uploaded '}
                            <span className={styles.strong}>{directDisplay}</span>
                            {'.'}
                            {eventDocumentId && (
                                <>
                                    {' ('}
                                    {eventDocumentId}
                                    {')'}
                                </>
                            )}
                        </span>
                    </>
                );
            },
        },
        // this event is for when the user deletes an adhoc document in the Screening Details page (details of a screening record)
        // EX: go to documents-profile-delete-modal.tsx as of 10/7/22 for example of how we use it
        'uploaded-document-delete': {
            span: (): JSX.Element => {
                return (
                    <>
                        <Icon iconName='PageRemove' className={styles.icon} />
                        <span className={styles.text}>
                            {'Document '}
                            <span className={styles.strong}>{directDisplay}</span> was deleted by{' '}
                            <EmployeeHoverCard personnelId={props.activity.subject.value}>
                                <span className={styles.strong}>{subjectDisplay}</span>
                            </EmployeeHoverCard>
                            {'. '}
                            {additionalPositionDisplay && additionalPositionDisplay[0] ? (
                                <>
                                    {'Business justification: '}
                                    <span className={styles.strong}>
                                        {additionalPositionDisplay[0]}
                                    </span>
                                </>
                            ) : (
                                ''
                            )}
                            {eventDocumentId && (
                                <>
                                    {' ('}
                                    {eventDocumentId}
                                    {')'}
                                </>
                            )}
                        </span>
                    </>
                );
            },
        },
        'import': {
            span: (): JSX.Element => {
                return defaultByIdSpan('importmirrored', 'imported record');
            },
        },
        'uploaded': {
            span: (): JSX.Element => {
                return defaultByIdSpan('upload', 'uploaded record');
            },
        },
    };

    function getStateDisplayLabel(activity: IActivity, defaultDisplayLabel = 'UNKNOWN'): string {
        const additionalObjects: IActivityValue[] = props?.activity?.additionalObjects;

        if (additionalObjects && additionalObjects.length > 0) {
            return ScreeningStateLabels[additionalObjects[0].value];
        }

        return defaultDisplayLabel;
    }

    function getSubstateDisplayLabel(activity: IActivity, defaultDisplayLabel = 'UNKNOWN'): string {
        const additionalObjects: IActivityValue[] = props?.activity?.additionalObjects;

        if (
            additionalObjects &&
            additionalObjects.length > 0 &&
            additionalObjects[0].value in ScreeningStateLabels
        ) {
            return ScreeningStateLabels[additionalObjects[0].value];
        }

        return defaultDisplayLabel;
    }

    function sentenceMethod(activity: IActivity): JSX.Element {
        if (activity.event) {
            const event = activity.event.toLowerCase();
            let renderObj;
            switch (props.activity.appName) {
                case 'profileApp':
                    renderObj = profileEventToSpan[event];
                    break;
                case 'screeningApp':
                    renderObj = screeningEventToSpan[event];
                    break;
                case 'documentApp':
                    renderObj = documentEventToSpan[event];
                    break;
                case 'facilitiesApp':
                    renderObj = facilitiesEventToSpan[event];
                    break;
            }

            if (renderObj) {
                return renderObj.span();
            }
        }
        return defaultByIdSpan('', props.activity.event);
    }

    // hide all adjudicated substate change logs from the past. in past activities it would store the parent status as the status
    // and log it as an event even though there is a Adjudicated Decision field for adjudicated
    if (
        !(
            props.activity.event.toLowerCase() === 'sub-state-change' &&
            props.activity.additionalObjects[0].value === ScreeningParentStateType.Adjudicated
        )
    ) {
        return (
            <>
                <ActionButton
                    // The purpose of this button is to catch the initial propagated click event
                    // that caused to modal to open. Without this, the first interactive item's
                    // onHover event will trigger, which is, in this case, the employee hover card,
                    // making it assume the mouse is hovering on it and causing it to open.
                    ariaLabel={'hidden'}
                    style={{ maxHeight: 0, maxWidth: 0 }}
                    hidden={true}
                    onClick={(event): void => {
                        event.stopPropagation();
                    }}
                />
                {props.activity.appName === 'profileApp' ? (
                    <div className={styles.profileEvent}>{sentenceMethod(props.activity)}</div>
                ) : (
                    <div className={styles.event}>
                        {sentenceMethod(props.activity)}
                        <span className={styles.time}>
                            {dateToFormattedTimeString(
                                props.activity.eventTimestampUTC,
                                props.timeZone,
                            )}
                            {props.displayTimeZone && ` ${props.timeZone}`}
                        </span>
                    </div>
                )}
            </>
        );
    }
    return <></>;
}

const styles = mergeStyleSets({
    event: {
        display: 'grid',
        gridTemplateColumns: '1rem auto 4rem',
        gridColumnGap: '1rem',
        fontSize: '0.75rem',
        lineHeight: '1.5',
    },
    icon: {
        fontSize: '1rem',
        marginRight: AppConstants.margin,
    },
    convertIcon: {
        fontSize: '1rem',
        margin: 'auto',
        transform: 'rotate(270deg)',
    },
    text: {
        paddingTop: '2px',
    },
    adjustedText: {
        // align text vertically with EmployeeBasicHoverCard
        marginTop: '-5px',
    },
    profileEvent: {
        display: 'grid',
        gridTemplateColumns: '1rem auto',
        gridColumnGap: '1rem',
        fontSize: '13px',
        lineHeight: '1.5',
        padding: '5px 0 5px 0',
    },
    noteBox: {
        backgroundColor: NeutralColors.white,
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
        position: 'relative',
        borderRadius: 2,
        padding: 8,
    },
    noteBoxTime: {
        color: 'rgba(0, 0, 0, 0.467)',
        float: 'right',
    },
    strong: {
        fontWeight: '500',
    },
    time: {
        color: 'rgba(0, 0, 0, 0.467)',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '2px',
    },
});
