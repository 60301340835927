import { Dictionary } from 'assets/constants/global-constants';
import { AUTH_PREFIX, IPagedResults, JSON_CONTENT_TYPE } from 'clients/http-options';
import { DefaultPageSize } from 'components/common/constants';
import { IAuthContext, IAuthProfile } from 'contexts/auth-context';
import config from 'environments/environment';
import pluralize from 'pluralize';
import { extractErrorMessage, readErrorMessageBody } from 'utils/misc-utils';

export default class GroupClient {
    static groupApplicationIdToName: Dictionary<Dictionary<string>> = {};

    static async isUserInAnyGroup(authContext: IAuthContext): Promise<boolean> {
        const { baseUrl, myGroupsEndpoint, aadScopes } = config.groupServiceConfig;
        const url = baseUrl + myGroupsEndpoint;

        let continuationToken = '';
        // Dangerous but necessary
        while (true) {
            const httpOptions = await GetAadHttpOptions(
                authContext,
                aadScopes,
                JSON_CONTENT_TYPE,
                continuationToken,
            );
            const res = await fetch(url, httpOptions);
            if (res.status === 200) {
                const ret = await res.json();
                const { results } = ret;
                continuationToken = ret?.continuationToken;
                if (!!results && (results?.length ?? 0) > 0) {
                    return true;
                }
                if (!continuationToken) {
                    return false;
                }
            } else {
                throw res;
            }
        }
    }

    static async getGroups(
        authContext: IAuthContext,
        continuationToken?: string,
    ): Promise<IPagedResults<IGroup>> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(
            authContext,
            aadScopes,
            JSON_CONTENT_TYPE,
            continuationToken,
        );
        const url = baseUrl + groupsEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getGroupBasic(authContext: IAuthContext, groupId: string): Promise<IGroupBasic> {
        const { baseUrl, groupsEndpoint } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(
            authContext,
            config.groupServiceConfig.aadScopes,
        );
        const url = baseUrl + groupsEndpoint + groupId + '/basic';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getGroup(authContext: IAuthContext, groupId: string): Promise<IGroup> {
        const { baseUrl, groupsEndpoint } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(
            authContext,
            config.groupServiceConfig.aadScopes,
        );
        const url = baseUrl + groupsEndpoint + groupId;

        const response = await fetch(url, httpOptions);
        switch (response.status) {
            case 200:
                return await response.json();
            case 400:
                throw readErrorMessageBody(response);
            case 404:
                throw 'Group not found';
            default:
                throw 'Error getting group information';
        }
    }

    static async createGroup(authContext: IAuthContext, group: IGroupRequest): Promise<IGroup> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            body: JSON.stringify(group),
            method: 'POST',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupsEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async editGroup(
        authContext: IAuthContext,
        groupId: string,
        group: IGroupRequest,
    ): Promise<IGroup> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            body: JSON.stringify(group),
            method: 'PUT',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupsEndpoint + groupId;
        const response = await fetch(url, httpOptions);
        switch (response.status) {
            case 200:
                return response.json();
            case 409:
                throw 'Please refresh page to update group settings';
            default:
                const errorMsg = await readErrorMessageBody(response);
                throw errorMsg || 'Error updating group';
        }
    }

    static async deleteGroup(authContext: IAuthContext, groupId: string): Promise<void> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            method: 'DELETE',
        };
        const url = baseUrl + groupsEndpoint + groupId;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return;
        } else {
            throw response;
        }
    }

    static async removeGroupRule(
        authContext: IAuthContext,
        groupId: string,
        ruleId: string,
    ): Promise<void> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            method: 'DELETE',
        };
        const url = baseUrl + groupsEndpoint + groupId + '/rules/' + ruleId;
        const response = await fetch(url, httpOptions);
        if (response.status !== 200) {
            throw response;
        }
    }

    static async getGroupRules(authContext: IAuthContext, groupId: string): Promise<IGroupRule[]> {
        const { baseUrl, groupsEndpoint } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(
            authContext,
            config.groupServiceConfig.aadScopes,
        );
        const url = baseUrl + groupsEndpoint + groupId + '/rules';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getGroupRulesBasic(authContext: IAuthContext): Promise<IGroupRule[]> {
        const { baseUrl, groupsEndpoint } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(
            authContext,
            config.groupServiceConfig.aadScopes,
        );
        const url = baseUrl + groupsEndpoint + 'rules';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async addGroupRule(
        authContext: IAuthContext,
        groupId: string,
        group: IGroupRuleRequest,
    ): Promise<IGroup> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            body: JSON.stringify(group),
            method: 'POST',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupsEndpoint + groupId + '/rules';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            const errorMsg = await readErrorMessageBody(response);
            throw errorMsg || 'Error adding rule';
        }
    }

    static async updateGroupRule(
        authContext: IAuthContext,
        groupId: string,
        ruleId: string,
        group: IGroupRuleRequest,
    ): Promise<IGroupRule> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            body: JSON.stringify(group),
            method: 'PUT',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupsEndpoint + groupId + '/rules/' + ruleId;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            const errorMsg = await readErrorMessageBody(response);
            throw errorMsg || 'Error adding rule';
        }
    }

    static async getLinkedSecurityGroups(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<IGroupSecurityGroup[]> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupEndpoint + groupId + '/securitygroups';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getSecurityGroup(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<IGroupSecurityGroup> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + groupEndpoint + groupId + '/securitygroup';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async checkSecurityGroupLink(
        authContext: IAuthContext,
        securityGroupId: string,
    ): Promise<boolean> {
        const { baseUrl, securityGroupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + securityGroupsEndpoint + securityGroupId + '/check';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async linkSecurityGroup(
        authContext: IAuthContext,
        groupRequest: IGroupSecurityGroupRequest,
    ): Promise<IGroupSecurityGroup> {
        const { baseUrl, securityGroupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            body: JSON.stringify(groupRequest),
            method: 'POST',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + securityGroupsEndpoint + 'link';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw await readErrorMessageBody(response);
        }
    }

    static async unlinkSecurityGroup(
        authContext: IAuthContext,
        groupId: string,
        securityGroupId: string,
    ): Promise<boolean> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            method: 'DELETE',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupEndpoint + groupId + '/securitygroup/link/' + securityGroupId;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    // Get group ownerships - Returns managers and owners
    static async getGroupOwners(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<IGroupMembership[]> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + groupEndpoint + groupId + '/ownerships';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    // Get group ownerships - Returns managers, owners and auditors
    static async getSpecialRoleMembers(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<IGroupMembership[]> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + groupEndpoint + groupId + '/specialroles';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getMyGroupMembership(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<IMyGroupMembership> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + groupEndpoint + groupId + '/memberships/me/';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getGroupMembers(
        authContext: IAuthContext,
        groupId: string,
        continuationToken?: string,
        statuses?: GroupMemberStatus[],
        onGracePeriod?: null | boolean,
        inAllowList?: null | boolean,
        pageSize?: null | number,
    ): Promise<IGroupMembershipResponse> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(
            authContext,
            aadScopes,
            JSON_CONTENT_TYPE,
            continuationToken,
        );
        let url = baseUrl + groupEndpoint + groupId + '/memberships';
        if (statuses) {
            url += `?statuses=${statuses.join(
                ',',
            )}&onGracePeriod=${onGracePeriod}&inAllowList=${inAllowList}&pageSize=${
                pageSize ?? 10000
            }`;
        }
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getApplicationGroupMembershipInGroup(
        authContext: IAuthContext,
        groupId: string,
        applicationId: string,
        includeDeleted?: boolean,
    ): Promise<IApplicationGroupMembership> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        let url = baseUrl + groupEndpoint + groupId + '/memberships/application/' + applicationId;
        if (!!includeDeleted) {
            url += `?includeDeleted=${includeDeleted}`;
        }
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getGroupRuleById(
        authContext: IAuthContext,
        groupId: string,
        ruleId: string,
        ruleType?: string,
    ): Promise<IGroupRule> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url =
            baseUrl +
            groupsEndpoint +
            groupId +
            '/rules/' +
            ruleId +
            (ruleType ? '?ruleType=' + ruleType : '');

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async listGroupRules(authContext: IAuthContext, groupId: string): Promise<IGroupRule[]> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + groupsEndpoint + groupId + '/rules/';

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getAgreement(
        authContext: IAuthContext,
        groupId: string,
        ruleId: string,
        principalId: string,
    ): Promise<ICANDAMetadata> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const url =
            baseUrl + groupsEndpoint + groupId + '/rules/' + ruleId + '/agreement/' + principalId;
        const httpOptions = {
            method: 'GET',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async signAgreement(
        authContext: IAuthContext,
        groupId: string,
        ruleId: string,
        principalId?: string,
    ): Promise<void> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        let url = baseUrl + groupsEndpoint + groupId + '/rules/' + ruleId + '/sign';
        if (!!principalId) {
            url += `?personnelId=${principalId}`;
        }

        const httpOptions = {
            method: 'POST',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return;
        } else {
            throw response;
        }
    }

    static async listApplicationGroupMembershipsInGroup(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<IApplicationGroupMembership[]> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + groupEndpoint + groupId + '/memberships/application';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async addApplicationGroupMembership(
        authContext: IAuthContext,
        request: ICreateApplicationGroupMembershipRequest,
    ): Promise<IApplicationGroupMembership> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            body: JSON.stringify(request),
            method: 'POST',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupEndpoint + 'memberships/application';
        const response = await fetch(url, httpOptions);
        switch (response.status) {
            case 200:
                return response.json();
            case 409: // Happens when adding an app that aleady exists in the group.
                throw await readErrorMessageBody(response);
            default:
                throw 'Error adding application, it must be allow listed by the Personnel team.';
        }
    }

    static async updateMyGroupMembership(
        authContext: IAuthContext,
        request: IMyGroupMembershipRequest,
    ): Promise<IMyGroupMembership> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            body: JSON.stringify(request),
            method: 'PUT',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupEndpoint + 'memberships/me/';
        const response = await fetch(url, httpOptions);
        switch (response.status) {
            case 200:
                return response.json();
            case 400:
                throw await readErrorMessageBody(response);
            case 403:
                throw 'Member is not allowed to modify own record';
            case 404:
                throw (await readErrorMessageBody(response)) ?? ErrorReponses.employeeNotFound;
            case 409:
                throw 'Conflict error while updating... Please try again';
            default:
                throw 'Error updating my own member record';
        }
    }

    static async updateApplicationGroupMembership(
        authContext: IAuthContext,
        request: IUpdateApplicationGroupMembershipRequest,
    ): Promise<IApplicationGroupMembership> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            body: JSON.stringify(request),
            method: 'PUT',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupEndpoint + 'memberships/application';
        const response = await fetch(url, httpOptions);
        switch (response.status) {
            case 200:
                return response.json();
            case 400:
                throw await readErrorMessageBody(response);
            case 409:
                throw 'The record has been modified since you selected it. Please refresh the page and apply your changes again.';
            default:
                throw 'Error updating app member';
        }
    }

    static async deleteApplicationGroupMembership(
        authContext: IAuthContext,
        groupId: string,
        applicationId: string,
    ): Promise<void> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            method: 'DELETE',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupEndpoint + groupId + '/memberships/application/' + applicationId;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return;
        } else {
            throw response;
        }
    }

    static async getMyGroups(
        authContext: IAuthContext,
        continuationToken?: string,
        evaluateGroupRules?: boolean,
        pageSize?: number,
    ): Promise<IPagedResults<IMyGroup>> {
        const { baseUrl, myGroupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(
                authContext,
                aadScopes,
                JSON_CONTENT_TYPE,
                continuationToken,
            )),
        };

        const urlParams = new URLSearchParams();
        evaluateGroupRules &&
            urlParams.append('isEvaluateGroupRules', evaluateGroupRules.toString());
        pageSize && urlParams.append('pageSize', pageSize.toString());

        const url = baseUrl + myGroupsEndpoint;
        const response = await fetch(`${url}?${urlParams}`, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async searchGroupMembers(
        authContext: IAuthContext,
        groupId: string,
        personnelId: string,
        statuses?: GroupMemberStatus[],
    ): Promise<IGroupMembership> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        let url = baseUrl + groupEndpoint + groupId + '/memberships/' + personnelId;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);

        if (statuses) {
            url += `?statuses=${statuses.join(',')}`;
        }
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async checkMembership(
        authContext: IAuthContext,
        groupId: string,
        personnelId: string,
    ): Promise<boolean> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + groupEndpoint + groupId + '/membercheck/' + personnelId;

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return true;
        } else if (response.status === 404) {
            return false;
        } else {
            throw response;
        }
    }

    static async checkMembershipBulk(
        authContext: IAuthContext,
        groupIds: string[],
        personnelId: string,
    ): Promise<object> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            body: JSON.stringify(groupIds),
            method: 'POST',
        };
        const url = baseUrl + groupEndpoint + 'bulkmembercheck/' + personnelId;

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else if (response.status === 404) {
            throw (await readErrorMessageBody(response)) ?? ErrorReponses.employeeNotFound;
        } else {
            throw response;
        }
    }

    static async addGroupMember(
        authContext: IAuthContext,
        groupMembership: ICreateGroupMembershipRequest,
    ): Promise<IGroupMembership> {
        const { baseUrl, membershipsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            body: JSON.stringify(groupMembership),
            method: 'POST',
        };
        const url = baseUrl + membershipsEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        }
        let errorMessage;
        switch (response.status) {
            case 404:
                throw (await readErrorMessageBody(response)) ?? ErrorReponses.employeeNotFound;
            case 400:
                errorMessage = await readErrorMessageBody(response);
                if (!!errorMessage) {
                    throw errorMessage;
                } else {
                    throw ErrorReponses.addMemberUnknown;
                }
            case 409:
                errorMessage = await readErrorMessageBody(response);
                if (errorMessage) {
                    const json = JSON.parse(errorMessage ?? '{}');
                    if (json.errorCode && json.errorCode === 'GRP_0001') {
                        throw ErrorReponses.addMemberDuplicate;
                    } else if (json.errorCode === 'GRP_0002') {
                        throw ErrorReponses.addMemberJoinRequestExists;
                    } else {
                        throw ErrorReponses.addMemberUnknown;
                    }
                }
            case 403:
                throw ErrorReponses.blockedMember;
            default:
                throw ErrorReponses.addMemberUnknown;
        }
    }

    static async updateGroupMember(
        authContext: IAuthContext,
        groupMembership: IUpdateGroupMembershipRequest,
    ): Promise<IGroupMembership> {
        const { baseUrl, membershipsEndpoint, aadScopes } = config.groupServiceConfig;
        const url = baseUrl + membershipsEndpoint;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            body: JSON.stringify(groupMembership),
            method: 'PUT',
        };
        const response = await fetch(url, httpOptions);
        switch (response.status) {
            case 200:
                return response.json();
            case 400:
                throw (
                    (await readErrorMessageBody(response)) ??
                    'Invalid Group Join Request was passed in or Group Record was not found.'
                );
            case 403:
                throw 'Request is not permitted.';
            case 404:
                throw (await readErrorMessageBody(response)) ?? ErrorReponses.employeeNotFound;
            default:
                throw 'Issue updating Group Record.'; // Note: This also includes case 500
        }
    }

    static async checkJoinRequest(authContext: IAuthContext, groupId: string): Promise<boolean> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const url = baseUrl + groupEndpoint + groupId + '/memberships/request';
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);

        const response = await fetch(url, httpOptions);
        switch (response.status) {
            case 200:
                return response.json();
            case 404:
                throw 'No such group';
            default:
                throw 'Error checking join request';
        }
    }

    static async deleteGroupMember(
        authContext: IAuthContext,
        groupId: string,
        personnelId: string,
        businessJustification?: string,
    ): Promise<void> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const url = baseUrl + groupEndpoint + groupId + '/memberships/' + personnelId;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            method: 'DELETE',
            body: JSON.stringify({ businessJustification: businessJustification }),
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return; // No news is good news.
        } else {
            throw response;
        }
    }

    static async createJoinRequest(
        authContext: IAuthContext,
        groupJoin: IGroupJoinRequest,
    ): Promise<boolean> {
        const { baseUrl, membershipsEndpoint, aadScopes } = config.groupServiceConfig;
        const url = baseUrl + membershipsEndpoint + 'request/';
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            body: JSON.stringify(groupJoin),
            method: 'POST',
        };

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async createNominateRequest(
        authContext: IAuthContext,
        groupJoin: IGroupJoinRequest,
    ): Promise<boolean> {
        const { baseUrl, membershipsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            body: JSON.stringify(groupJoin),
            method: 'POST',
        };
        const url = baseUrl + membershipsEndpoint + 'nominate/';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async createBulkNominateRequest(
        authContext: IAuthContext,
        groupNomination: IGroupNominationRequest,
    ): Promise<IGroupNominationResponse[]> {
        const { baseUrl, membershipsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            body: JSON.stringify(groupNomination),
            method: 'POST',
        };
        const url = baseUrl + membershipsEndpoint + 'bulknominate';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async updateGroupRequest(
        authContext: IAuthContext,
        groupJoinReview: IGroupJoinReviewRequest,
    ): Promise<boolean> {
        const { baseUrl, membershipsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            body: JSON.stringify(groupJoinReview),
            method: 'PUT',
        };
        const url = baseUrl + membershipsEndpoint + 'request/';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getPolicyViolations(
        authContext: IAuthContext,
        groupId: string,
        continuationToken?: string, // leaving optional until filters are implemented
        includePassed?: boolean,
    ): Promise<IGroupPolicyViolationResponse> {
        const { baseUrl, violationsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(
                authContext,
                aadScopes,
                JSON_CONTENT_TYPE,
                continuationToken,
            )),
        };
        const urlParams = new URLSearchParams({ 'pageSize': DefaultPageSize.toString() });
        includePassed && urlParams.append('includePassed', includePassed.toString());

        const url = baseUrl + violationsEndpoint + groupId + '/violations';
        const response = await fetch(`${url}?${urlParams}`, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getPolicyViolation(
        authContext: IAuthContext,
        groupId: string,
        personnelId: string,
    ): Promise<IGroupPolicyViolation> {
        const { baseUrl, violationsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + violationsEndpoint + groupId + '/personnel/' + personnelId;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async extendAgreement(
        authContext: IAuthContext,
        groupId: string,
        personnelId: string,
        ruleId: string,
    ): Promise<void> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            method: 'POST',
        };
        const urlParams = new URLSearchParams();
        personnelId && urlParams.append('personnelId', personnelId.toString());

        const url = baseUrl + groupsEndpoint + groupId + '/rules/' + ruleId + '/extend';
        const response = await fetch(`${url}?${urlParams}`, httpOptions);
        if (response.status === 200) {
            return;
        } else {
            throw response;
        }
    }

    static async createPolicyViolation(
        authContext: IAuthContext,
        groupId: string,
        personelId: string,
    ): Promise<IGroupPolicyViolation> {
        const { baseUrl, violationsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            method: 'POST',
        };
        const url = baseUrl + violationsEndpoint + groupId + '/personnel/' + personelId;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async downloadGroupsReport(authContext: IAuthContext, type = 'csv'): Promise<string> {
        const { baseUrl, reportsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await this.getReportHeaders(authContext, aadScopes, type);
        const url = baseUrl + reportsEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.text();
        } else {
            throw response;
        }
    }

    static async downloadGroupMembersReport(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<GroupMemberReport[]> {
        let memberReport: GroupMemberReport[] = [];
        const { baseUrl, reportsEndpoint, aadScopes } = config.groupServiceConfig;
        const urlParams = new URLSearchParams({ 'pageSize': DefaultPageSize.toString() });
        const url = baseUrl + reportsEndpoint + groupId + '/members';
        let continuationToken = '';
        do {
            const httpOptions = await GetAadHttpOptions(
                authContext,
                aadScopes,
                JSON_CONTENT_TYPE,
                continuationToken,
            );
            const result = await fetch(`${url}?${urlParams}`, httpOptions);
            if (result.status === 200) {
                const batch = await result.json();
                memberReport = batch.results
                    ? [...memberReport, ...(batch.results as GroupMemberReport[])]
                    : memberReport;
                continuationToken = batch.continuationToken;
            } else {
                throw result;
            }
        } while (continuationToken);
        return memberReport;
    }

    static async downloadGroupViolationsReport(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<GroupMemberViolationReport[]> {
        let violationReport: GroupMemberViolationReport[] = [];
        const { baseUrl, reportsEndpoint, aadScopes } = config.groupServiceConfig;
        const urlParams = new URLSearchParams({ 'pageSize': DefaultPageSize.toString() });
        const url = baseUrl + reportsEndpoint + groupId + '/violations';
        let continuationToken = '';
        do {
            const httpOptions = await GetAadHttpOptions(
                authContext,
                aadScopes,
                JSON_CONTENT_TYPE,
                continuationToken,
            );
            const result = await fetch(`${url}?${urlParams}`, httpOptions);
            if (result.status === 200) {
                const batch = await result.json();
                violationReport = batch.results
                    ? [...violationReport, ...(batch.results as GroupMemberViolationReport[])]
                    : violationReport;
                continuationToken = batch.continuationToken;
            } else {
                throw result;
            }
        } while (continuationToken);
        return violationReport;
    }

    static async getGroupEvaluationSchedule(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<IGroupEvaluationSchedule> {
        const { baseUrl, violationsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + violationsEndpoint + groupId + '/schedule';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async scheduleGroupEvaluation(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<IGroupEvaluationSchedule> {
        const { baseUrl, violationsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            method: 'POST',
        };
        const url = baseUrl + violationsEndpoint + groupId + '/schedule';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getActivitiesReport(
        authContext: IAuthContext,
        groupId: string,
        filters?: IGroupActivityFilters,
    ): Promise<string> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await this.getReportHeaders(authContext, aadScopes, 'csv');

        const url = baseUrl + groupEndpoint + groupId + '/activities';

        const urlParams = new URLSearchParams();
        this.setActivitiesFilterOnUrlParams(urlParams, filters);

        const response = await fetch(`${url}?${urlParams}`, httpOptions);
        if (response.status === 200) {
            return response.text();
        } else {
            throw response;
        }
    }

    static async getActivities(
        authContext: IAuthContext,
        groupId: string,
        filters?: IGroupActivityFilters,
        pageSize?: number,
        continuationToken?: string,
    ): Promise<IGroupActivitiesResponse> {
        const { baseUrl, groupEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(
            authContext,
            aadScopes,
            JSON_CONTENT_TYPE,
            continuationToken,
        );

        const urlParams = new URLSearchParams();
        pageSize !== undefined && urlParams.append('pageSize', pageSize.toString());

        this.setActivitiesFilterOnUrlParams(urlParams, filters);

        const url = baseUrl + groupEndpoint + groupId + '/activities';
        const response = await fetch(`${url}?${urlParams}`, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getGroupWelcomeMessageTemplate(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<IGroupWelcomeMessageTemplate> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + groupsEndpoint + groupId + '/welcomeMessageTemplate';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async setGroupCustomWelcomeMessage(
        authContext: IAuthContext,
        groupId: string,
        request: IGroupCustomWelcomeMessageRequest,
    ): Promise<void> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            body: JSON.stringify(request),
            method: 'POST',
        };
        const url = baseUrl + groupsEndpoint + groupId + '/customWelcomeMessage';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return;
        } else {
            throw response;
        }
    }

    static async getGroupOffboardMessageTemplate(
        authContext: IAuthContext,
        groupId: string,
    ): Promise<IGroupOffboardMessageTemplate> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url = baseUrl + groupsEndpoint + groupId + '/offboardMessageTemplate';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async setGroupCustomOffboardMessage(
        authContext: IAuthContext,
        groupId: string,
        request: IGroupCustomOffboardMessageRequest,
    ): Promise<void> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
            body: JSON.stringify(request),
            method: 'POST',
        };
        const url = baseUrl + groupsEndpoint + groupId + '/customOffboardMessage';
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return;
        } else {
            throw response;
        }
    }

    static async getReportHeaders(
        authContext: IAuthContext,
        aadScopes: string[],
        type = 'csv',
    ): Promise<RequestInit> {
        let httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        if (type === 'csv') {
            httpOptions = {
                headers: {
                    ...httpOptions.headers,
                    Accept: 'text/csv',
                },
            };
        }
        return httpOptions;
    }

    static setActivitiesFilterOnUrlParams(
        // was setActivitiesFilterOnHttpOptions in angular
        urlParams: URLSearchParams,
        filters?: IGroupActivityFilters,
    ): void {
        if (filters) {
            if (filters.categories && filters.categories.length > 0) {
                urlParams.append('categories', filters.categories.join(','));
            }
            if (filters.startDate) {
                urlParams.append('startDate', filters.startDate.toString());
            }
            if (filters.endDate) {
                urlParams.append('endDate', filters.endDate.toString());
            }
            if (filters.personnelId) {
                // todo: remove after "groups-internal" components are cleaned-up
                urlParams.append('personnelIdFilter', filters.personnelId);
            }
            if (filters.principalId) {
                urlParams.append('personnelIdFilter', filters.principalId);
            }
            if (filters.subjectsOnly) {
                urlParams.append('onlySubjects', filters.subjectsOnly ? 'true' : 'false');
            }
        }
    }

    static async getOwnedGroups(
        authContext: IAuthContext,
        msalUser: IAuthProfile | null,
    ): Promise<IOwnedAdGroupResponse[]> {
        const { baseUrl, securityGroupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        if (msalUser && msalUser.alias) {
            const url = baseUrl + securityGroupsEndpoint + `owner/${msalUser.alias}`;
            const response = await fetch(url, httpOptions);
            if (response.status === 200) {
                return await response.json();
            } else {
                throw response;
            }
        }
        return [];
    }

    static async verifyGroupIsOwnedByManagementApp(
        authContext: IAuthContext,
        domainName: string,
        groupName: string,
    ): Promise<boolean> {
        const { baseUrl, securityGroupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = await GetAadHttpOptions(authContext, aadScopes);
        const url =
            baseUrl +
            securityGroupsEndpoint +
            `verifyowner/?domainName=${domainName}&groupName=${groupName}`;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getApplicationName(
        authContext: IAuthContext,
        groupId: string,
        applicationId: string,
    ): Promise<string> {
        if (!(groupId in this.groupApplicationIdToName)) {
            this.groupApplicationIdToName[groupId] = {};
        }
        if (!(applicationId in this.groupApplicationIdToName[groupId])) {
            try {
                const response = await this.getApplicationGroupMembershipInGroup(
                    authContext,
                    groupId,
                    applicationId,
                    true,
                );
                this.groupApplicationIdToName[groupId][applicationId] = response.applicationName;
            } catch {
                console.error(`Could not fetch information about application "${applicationId}"`);
            }
        }
        return this.groupApplicationIdToName[groupId][applicationId];
    }

    static async getCourseDetails(
        authContext: IAuthContext,
        courseIds: string[],
    ): Promise<ICourseDetail[]> {
        const { baseUrl, courseDetailEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            body: JSON.stringify(courseIds),
            method: 'POST',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + courseDetailEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async addPoc(
        authContext: IAuthContext,
        groupId: string,
        request: IAddPocRequest,
    ): Promise<IGroup> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            body: JSON.stringify(request),
            method: 'POST',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupsEndpoint + `${groupId}/poc`;
        const response = await fetch(url, httpOptions);

        if (response.status === 200) {
            return response.json();
        }
        const errorBody = await extractErrorMessage(response);

        switch (response.status) {
            case 400:
                if (typeof errorBody === 'string') {
                    throw errorBody;
                } else if (typeof errorBody === 'object') {
                    const keys = Object.keys(errorBody);
                    if (keys.length > 0) {
                        throw `Error in ${pluralize('parameter', keys.length)} ${keys.join(
                            ' and ',
                        )}`;
                    }
                }
                throw 'Error in input parameter(s)';
            case 403:
            case 404:
                throw errorBody;
            default:
                throw 'Error adding point of contact to the group';
        }
    }

    static async deletePoc(
        authContext: IAuthContext,
        groupId: string,
        request: IAddPocRequest,
    ): Promise<IGroup> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            body: JSON.stringify(request),
            method: 'DELETE',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupsEndpoint + `${groupId}/poc`;
        const response = await fetch(url, httpOptions);

        if (response.status === 200) {
            return response.json();
        }
        const errorBody = await extractErrorMessage(response);

        switch (response.status) {
            case 400:
                if (typeof errorBody === 'string') {
                    throw errorBody;
                } else if (typeof errorBody === 'object') {
                    const keys = Object.keys(errorBody);
                    if (keys.length > 0) {
                        throw `Error in ${pluralize('parameter', keys.length)} ${keys.join(
                            ' and ',
                        )}`;
                    }
                }
                throw 'Error in input parameter(s)';
            case 403:
            case 404:
                throw errorBody;
            default:
                throw 'Error removing point of contact from the group';
        }
    }

    static async getPocEmails(authContext: IAuthContext, groupId: string): Promise<string[]> {
        const { baseUrl, groupsEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            method: 'GET',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + groupsEndpoint + `${groupId}/poc/emails`;
        const response = await fetch(url, httpOptions);

        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async isFTE(
        authContext: IAuthContext,
        personnelIds: string[],
    ): Promise<Dictionary<boolean>> {
        const { baseUrl, isFteEndpoint, aadScopes } = config.groupServiceConfig;
        const httpOptions = {
            body: JSON.stringify(personnelIds),
            method: 'POST',
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url = baseUrl + isFteEndpoint;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }
}

export interface IBaseGroupRecord {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _etag: string;
}

export interface IGroupRequest {
    name: string;
    description: string;
    requireJustification: boolean;
    requireSponsor: boolean;
    allowJoin: boolean;
    allowMemberNomination: boolean;
    autoApproveJoin: boolean;
    enableMemberNoncomplianceNotification: boolean;
    enableMemberRoleNotification: boolean;
    enableWelcomeMessage: boolean;
    rules: IGroupRuleRequest[];
    ownerList: string[];
    blockList: string[];
    enableMemberLeaveJustification: boolean;
    enableOffboardMessage: boolean;
    memberNonComplianceNotificationDays: string[];
    groupType: keyof typeof GroupType;
    sensitivityLevel: keyof typeof SensitivityLevel;
    primaryCategorization?: keyof typeof PrimaryCategorization;
    division?: keyof typeof Division;
    cvpApprovedBy: string | undefined;
    agcApprovedBy: string | undefined;
    enableHidden: boolean;
    enableDynamic: boolean;
}

export interface IGroupBasic extends IBaseGroupRecord {
    id: string;
    name: string;
    description: string;
    groupType: keyof typeof GroupType;
    requireJustification: boolean;
    requireSponsor: boolean;
    allowJoin: boolean;
    allowMemberNomination: boolean;
    enableMemberLeaveJustification: boolean;
    enableHidden: boolean;
    enableDynamic: boolean;
}

export interface IGroup extends IGroupBasic {
    autoApproveJoin: boolean;
    enableMemberNoncomplianceNotification: boolean;
    enableMemberRoleNotification: boolean;
    memberNonComplianceNotificationDays: DayOfWeek[];
    enableWelcomeMessage: boolean;
    customWelcomeMessage: string;
    customWelcomeMessageSignature: string;
    enableOffboardMessage: boolean;
    customOffboardMessage: string;
    customOffboardMessageSignature: string;
    metrics?: IGroupMetric;
    actions: IGroupAction[];
    createdBy: string;
    creationTimestampUTC: number;
    lastModifiedBy: string;
    lastModifiedTimestampUTC: number;
    dependentGroups: string[]; // Group Id's
    securityGroup: IGroupSecurityGroupBasic;
    linkedSecurityGroups: IGroupSecurityGroup[];
    blockList: string[];
    sensitivityLevel: keyof typeof SensitivityLevel;
    primaryCategorization?: keyof typeof PrimaryCategorization;
    division?: keyof typeof Division;
    cvpApprovedBy: string;
    agcApprovedBy: string;
    pointsOfContact: IPointOfContact[];
    rules?: IGroupRule[];
}

export interface IMyGroup extends IBaseGroupRecord {
    id: string;
    name: string;
    role: GroupRole;
    status: GroupMemberStatus;
    compliant?: boolean;
    hasViolationOnGracePeriod: boolean;
    hasViolationButInAllowList: boolean;
    requireSponsor: boolean;
    requireJustification: boolean;
    allowMemberNomination: boolean;
    enableHidden: boolean;
    isMember?: boolean; // Used by Bulk Member Check/Nominate, to indicate if the selected employee is a member of the group
    enableDynamic: boolean;
}

export interface IGroupSecurityGroupBasic {
    id: string;
    syncTimestampUTC?: number;
    syncSuccessTimestampUTC?: number;
}

export interface IGroupSecurityGroup {
    id: string;
    name: string;
    mail: string;
    securityGroupType: SecurityGroupRequestType;
    domainName: SecurityGroupDomain;
    syncTimestampUTC: Date;
    syncSuccessTimestampUTC: number;
}

export interface IGroupSecurityGroupValidation {
    id: string;
    name: string;
    isInProgress: boolean;
    isSuccess: boolean;
    errorMessage?: string;
}

export interface IGroupSecurityGroupRequest {
    groupId: string;
    securityGroupId: string;
    securityGroupAlias: string;
    securityGroupType: SecurityGroupRequestType;
    domainName: SecurityGroupDomain;
}

export interface IGroupCustomWelcomeMessageRequest extends IBaseGroupRecord {
    customWelcomeMessage: string;
    customWelcomeMessageSignature?: string;
}

export interface IGroupCustomOffboardMessageRequest extends IBaseGroupRecord {
    customOffboardMessage: string;
    customOffboardMessageSignature?: string;
}

export interface IGroupAccessLevel {
    Admin: boolean;
    Manager: boolean;
    Owner: boolean;
    Member: boolean;
}

export interface IGroupRule {
    allowList: string[];
    approverType?: UserAccessReviewApproverType;
    attributeIds?: string[];
    CANDAText: ICANDAText;
    companyCodes: string[];
    countryCodes: string[];
    courseIds: string[];
    costCenterCodes: string[];
    createdBy?: string;
    creationTimestampUTC: Date;
    description: string;
    durationDays: number;
    effectiveStartTimestampUTC?: number;
    effectiveEndTimestampUTC?: number;
    eligibilityIds?: string[];
    employeeIds?: string[];
    evaluationType: string;
    gracePeriod: number;
    groupIds: string[];
    id: string;
    lastModifiedBy: string;
    lastModifiedTimestampUTC: Date;
    metadata: IGroupRuleMetadata;
    managerCheck: boolean;
    name: string;
    optOutFromPolicyViolationNotifications: boolean;
    suspended: boolean;
    type: GroupRuleType;
    attributeExpression?: IPrincipalAttributeExpression;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _etag: string;
}

export interface IUserAccessReviewCheckRule extends IGroupRule {
    subtype: IUserAccessReviewSubtype;
    approverType: UserAccessReviewApproverType;
    CANDAText: ICANDAText;
    CANDATextHistory: ICANDAText[];
}

// Was AttributeCheckRule in Angular
export interface IAttributeCheckRule extends IGroupRule {
    attributeIds: string[];
}

export interface IGroupRuleMetadata {
    defaultText: string;
}

// Was TrainingCheckRule in Angular
export interface ITrainingCheckRule extends IGroupRule {
    courseIds: string[];
}

// Was EligibilityCheckRule in Angular
export interface IEligibilityCheckRule extends IGroupRule {
    eligibilityIds: string[];
}

// Was USCitizenshipCheckRule in Angular
export interface IUSCitizenshipCheckRule extends IGroupRule {
    managerCheck: boolean;
}

// Was LocationCheckRule in Angular
export interface ILocationCheckRule extends IGroupRule {
    managerCheck: boolean;
    countryCode: string;
}

// Was CloudScreeningCheckRule in Angular
export interface ICloudScreeningCheckRule extends IGroupRule {
    managerCheck: boolean;
}

// Was FTECheckRule in Angular
export interface IFTECheckRule extends IGroupRule {
    managerCheck: boolean;
}

// Was CANDACheckRule in Angular
export interface ICANDACheckRule extends IGroupRule {
    CANDAText: ICANDAText;
    CANDATextHistory: ICANDAText[];
}

// Was CANDAText in Angular
export interface ICANDAText {
    text: string;
    version: number;
    timestamp: number;
    invalidatePriorAgreementCheck: boolean;
}

export interface ICANDAMetadata {
    groupId: string;
    groupName: string;
    ruleId: string;
    ruleName: string;
    CANDAText: ICANDAText;
    ruleType: GroupRuleType;
    approverType: UserAccessReviewApproverType;
    allowedSignerOids: string[];
    isSigned: boolean;
    signedTimestampUTC?: number;
    signerOid?: string;
    extensionCount?: number;
    lastExtensionTimestampUTC?: number;
}

// Was GroupMembershipCheckRule in Angular
export interface IGroupMembershipCheckRule extends IGroupRule {
    groupIds: string[];
}

// Was HRDataCheckRule in Angular
export interface IHRDataCheckRule extends IGroupRule {
    field: IHRDataCheckField;
}

// Was CountryCheckRule in Angular
export interface ICountryCheckRule extends IHRDataCheckRule {
    countryCodes: string[];
}

// Was CompanyCodeCheckRule in Angular
export interface ICompanyCodeCheckRule extends IHRDataCheckRule {
    companyCodes: string[];
}

// Was StandDownCheckRule in Angular
export interface IStandDownCheckRule extends IGroupRule {
    standDownPeriodDays: number;
}

export interface IPrincipalAttributeCheckRule extends IGroupRule {
    attributeExpression: IPrincipalAttributeExpression;
}

export interface IPrincipalAttributeExpression {
    id: string;
    op?: LogicalOperatorType;
    attributeId?: string;
    operation?: ComparisonOperatorType;
    value?: any;
    children?: IPrincipalAttributeExpression[];
    expressionId?: string;
}

export enum LogicalOperatorType {
    And = 'And',
    Or = 'Or',
}

export enum ComparisonOperatorType {
    StringEquals = 'StringEquals',
    StringNotEquals = 'StringNotEquals',

    NumericEquals = 'NumericEquals',
    NumericNotEquals = 'NumericNotEquals',
    NumericGreaterThanOrEquals = 'NumericGreaterThanOrEquals',
    NumericLessThanOrEquals = 'NumericLessThanOrEquals',
    NumericGreaterThan = 'NumericGreaterThan',
    NumericLessThan = 'NumericLessThan',

    BoolEquals = 'BoolEquals',

    StringArrayContains = 'ForAnyOfAnyValues:StringEquals',
    NumericArrayContains = 'ForAnyOfAnyValues:NumericEquals',
}

export interface IGroupAction {
    name: string;
    description: string;
    eventType: GroupActionType;
}

export interface IGroupMetric {
    memberCount: number;
    violationCount: number;
    requestCount: number;
    onGracePeriodCount: number;
    inAllowListCount: number;
}

export interface IGroupJoinRequest {
    groupId: string;
    personnelId: string;
    sponsorId: string | null;
    justification: string;
}

export interface IGroupNominationRequest {
    groupIds: string[];
    personnel: string;
    sponsorId: string | null;
    justification: string;
}

export interface IGroupNominationResponse {
    groupId: string;
    personnelId: string;
    isSuccess: boolean;
    message: string;
}

export interface IGroupJoinReviewRequest {
    groupId: string;
    personnelId: string;
    isApproved: boolean;
    comment?: string;
}

export interface IGroupMembershipRequest {
    groupId: string;
    role: GroupRole;
    justification: string;
    notes?: string;
}

export interface ICreateApplicationGroupMembershipRequest extends IGroupMembershipRequest {
    sponsorId: string;
    applicationId: string;
    applicationName: string;
}

export interface IUpdateApplicationGroupMembershipRequest extends IGroupMembershipRequest {
    applicationId: string;
    applicationName: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _etag: string;
}

export interface ICreateGroupMembershipRequest extends IGroupMembershipRequest {
    sponsorId: string;
    personnelId: string;
}

export interface IUpdateGroupMembershipRequest extends IGroupMembershipRequest {
    personnelId: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _etag: string;
}

export interface IGroupMembership {
    addedBy: string;
    addedByType: AddedByType;
    addedTimestampUTC: number;
    alias: string;
    compliant?: boolean;
    email: string;
    firstName: string;
    fullName: string;
    groupId: string;
    hasViolationOnGracePeriod: boolean;
    id: string;
    inAllowList: boolean;
    justification: string;
    lastName: string;
    middleName: string;
    modifiedBy: string;
    modifiedByType: AddedByType;
    modifiedTimestampUTC: number;
    notes: string;
    personnelId: string;
    principalId: string;
    requestedBy: string;
    requestedByType: AddedByType;
    requestedTimestampUTC: number;
    requestedType?: GroupMemberRequestType;
    role: GroupRole;
    sponsorAlias: string;
    sponsorId: string;
    status: GroupMemberStatus;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _etag: string;
}

export interface IMyGroupMembership {
    sponsorId: string;
    sponsorAlias: string;
    addedBy: string;
    hasViolationOnGracePeriod: boolean;
    hasViolationButInAllowList: boolean;
    nonComplianceTimestampUTC: number;
    justification: string;
    id: string;
    role: GroupRole;
    groupId: string;
    personnelId: string;
    alias: string;
    compliant?: boolean;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    hierarchyLvl2: string;
    hierarchyLvl3: string;
    hierarchyLvl4: string;
    hierarchyLvl5: string;
    middleName: string;
    addedTimestampUTC: number;
    etag: string;
}

export interface IMyGroupMembershipRequest {
    sponsorId: string;
    justification: string;
    etag: string;
}

export interface IApplicationGroupMembership {
    id: string;
    groupId: string;
    applicationId: string;
    applicationName: string;
    role: GroupRole;
    justification: string;
    addedBy: string;
    addedTimestampUTC: number;
    modifiedBy: string;
    modifiedTimestampUTC: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _etag: string;
}

export interface IGroupMembershipResponse {
    results: IGroupMembership[];
    continuationToken: string;
}

export interface IGroupPolicyViolationResponse {
    results: IGroupPolicyViolation[];
    continuationToken: string;
}

// Was ActivitiesResponse in Angular
export interface IGroupActivitiesResponse {
    results: IGroupActivity[];
    continuationToken: string;
}

export interface IGroupPolicyViolation {
    alias: string;
    creationTimestampUTC: Date;
    failed: IGroupPolicyViolationRule[];
    firstName: string;
    fullName: string;
    groupId: string;
    lastName: string;
    middleName: string;
    nonComplianceTimestampUTC: number;
    passed: IGroupPolicyViolationRule[];
    personnelId: string;
    percentCompliant?: number;
}

export interface IGroupPolicyViolationRule {
    errorMessage: string;
    evaluationState: string;
    ruleDescription: string;
    ruleName: string;
    remediationSteps: string;
    metadata: IGroupPolicyViolationRuleMetadata;
    daysInViolationDuringGracePeriod: number;
    daysUntilGracePeriodEnd: number;
    firstFailTimeStampUTC: number;
    gracePeriodInDays: number;
    isInGracePeriod: boolean;
    ruleId: string;
    ruleDetails: RuleDetails;
    ruleType: GroupRuleType;
}

export interface IUarEvaluation {
    nonComplianceTimestampUTC: number;
    personnelId: string;
    evaluationState: string;
    ruleName: string;
    metadata: IGroupPolicyViolationRuleMetadata;
    firstFailTimeStampUTC: number;
    isInGracePeriod: boolean;
    ruleId: string;
    ruleType: GroupRuleType;
}

export interface IGroupPolicyViolationRuleMetadata {
    daysOver: number;
    approverType?: UserAccessReviewApproverType;
}

export type RuleDetails = IGroupPolicyViolationCANDARuleDetails;

export interface IGroupPolicyViolationCANDARuleDetails {
    signedVersions: IGroupPolicyViolationCANDARuleDetailsSignedVersion[];
    ruleId: string;
}

export interface IGroupPolicyViolationCANDARuleDetailsSignedVersion {
    version: number;
    signedTimestampUTC: number | undefined; // undefined because UAR may be approved or extended before user signing it
    signerOid: string | undefined;
}

export interface ISecurityGroup {
    id: string;
    name: string;
    mail: string;
    type: SecurityGroupTableType;
    options: SecurityGroupOption[];
    domain: SecurityGroupDomain;
}

export interface IGroupEvaluationSchedule {
    lastExecutedTimestampUTC: number;
    latestScheduledTimestampUTC: number;
    lastExecutedStatus: GroupEvaluationRunStatus;
    scheduleStatus: GroupEvaluationScheduleStatus;
}

export interface IDeleteMemberRequest {
    groupId: string;
    groupName: string;
    personnelId: string;
    firstName: string;
    lastName: string;
    isSelfDelete: boolean;
}

export interface IGroupWelcomeMessageTemplate {
    templateHeader: string;
    templateCompliant: string;
    templateNonCompliant: string;
    templateInstructions: string;
    templateDefaultSignature: string;
    templateClosing: string;
    templateFooter: string;
}

export interface IGroupOffboardMessageTemplate {
    templateHeader: string;
    templateDefaultSignature: string;
    templateClosing: string;
}

export enum SecurityGroupTableType {
    OFFICE = 'Office',
    SECURITY = 'Security',
    CoreIdentity = 'CoreIdentity',
    MICROSOFTFEDERAL = 'MicrosoftFederal',
}

export enum SecurityGroupRequestType {
    UNDEFINED = 'Undefined',
    OFFICE = 'Office',
    AZUREACTIVEDIRECTORY = 'AzureActiveDirectory',
    ACTIVEDIRECTORY = 'ActiveDirectory',
    MICROSOFTFEDERAL = 'MicrosoftFederal',
}

export enum SecurityGroupOption {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SECURITY_ENABLED = 'Security Enabled',
    PUBLIC = 'Public',
    PRIVATE = 'Private',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    HIGHLY_CONFIDENTIAL = 'Highly Confidential',
    CONFIDENTIAL = 'Confidential',
    GENERAL = 'General',
    // The value "HiddenMembership" may be added in the future.
}

export enum SecurityGroupDomain {
    UNDEFINED = 'Undefined',
    REDMOND = 'Redmond',
    CORP = 'CORP',
    MICROSOFT = 'Microsoft',
    NORTHAMERICA = 'NorthAmerica',
}

export interface IOwnedAdGroupResponse {
    domain: string;
    name: string;
    guid: string;
}

export enum GroupType {
    Project = 'Project',
    Tent = 'Tent',
    ConfidentialProject = 'Confidential Project',
    Group = 'Group',
}

export enum SensitivityLevel {
    HighlyConfidential = 'Highly Confidential',
    Confidential = 'Confidential',
    General = 'General',
}

export enum Division {
    BusinessDevelopmentStrategyAndVentures = 'Business Development, Strategy, & Ventures (Chris Young)',
    CELA = 'Corporate, External, & Legal Affairs (Brad Smith)',
    CloudAndAI = 'Cloud & AI (Scott Guthrie)',
    CTOTechnologyAndResearch = 'CTO/Technology & Research (Kevin Scott)',
    ExperiencesAndDevices = 'Experiences & Devices (Rajesh Jha)',
    Finance = 'Finance (Amy Hood)',
    HumanResources = 'Human Resources (Kathleen Hogan)',
    LinkedIn = 'LinkedIn (Ryan Roslansky)',
    MarketingAndConsumerBusinessManagement = 'Marketing & Consumer Business Management (Chris Capossela)',
    MicrosoftGaming = 'Microsoft Gaming (Phil Spencer)',
    MCAPS = 'Microsoft Customer & Partner Solutions (Judson Althoff)',
    SCIM = 'Security, Compliance, Identity, & Management (Charlie Bell)',
    StrategicMissionsAndTechnologies = 'Strategic Missions & Technologies (Jason Zander)',
}

export enum PrimaryCategorization {
    USGovernment = 'US Government',
    InternationalGovernment = 'International Government',
    HardwareDeviceGamingOrConfidentialIntellectualProperty = 'Hardware, Device, Gaming, or Confidential Intellectual Property',
    CriticalInfrastructure = 'Critical Infrastructure',
    Other = 'Other',
}

export enum GroupRole {
    MEMBER = 'MEMBER',
    MANAGER = 'MANAGER',
    OWNER = 'OWNER',
    ADMIN = 'ADMIN',
    AUDITOR = 'AUDITOR',
}

export enum AddedByType {
    USER = 'USER',
    APPLICATION = 'APPLICATION',
    SYSTEM = 'SYSTEM',
}

export enum GroupMemberStatus {
    /* eslint-disable @typescript-eslint/naming-convention */
    NOT_APPROVED = 'NOT_APPROVED',
    APPROVED = 'APPROVED',
    QUARANTINED = 'QUARANTINED',
    /* eslint-disable @typescript-eslint/naming-convention */
}

export enum GroupMemberRequestType {
    REQUESTED = 'REQUESTED',
    NOMINATED = 'NOMINATED',
}

export enum GroupActionType {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MEMBER_ADDED = 'MEMBER_ADDED',
}

export enum GroupFilterType {
    /* eslint-disable @typescript-eslint/naming-convention */
    COMPLIANT = 'COMPLIANT',
    NOT_COMPLIANT = 'NOT COMPLIANT',
    GRACE_PERIOD = 'GRACE PERIOD',
    ALLOW_LIST = 'ALLOW LIST',
    NO_FILTER = 'NO FILTER',
    /* eslint-enable @typescript-eslint/naming-convention */
}

export enum CompliantStatus {
    COMPLIANT = 'COMPLIANT',
    /* eslint-disable @typescript-eslint/naming-convention */
    NOT_COMPLIANT = 'NOT COMPLIANT',
    GRACE_PERIOD = 'GRACE PERIOD',
    ALLOW_LIST = 'ALLOW LIST',
    /* eslint-enable @typescript-eslint/naming-convention */
}

export enum DayOfWeek {
    SUNDAY = 'Sunday',
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
}

export enum MembershipCheck {
    MEMBER = 'MEMBER',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    NON_MEMBER = 'NOT A MEMBER',
}

export enum GroupEvaluationRunStatus {
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
}

export enum GroupEvaluationScheduleStatus {
    COMPLETED = 'COMPLETED',
    SCHEDULED = 'SCHEDULED',
    RUNNING = 'RUNNING',
}

/**
 * group-activities.model.ts
 */

export enum GroupActivityTag { // Was ActivityTag in group-activities.model.ts
    MEMBER = 'Member',
    POLICY = 'Policy',
    SETTING = 'Setting',
    SYSTEM = 'System',
}

export enum GroupActivityEvent { // Was ActivityEvent is Angular
    /* eslint-disable @typescript-eslint/naming-convention */
    CREATE_GROUP_RULE = 'CreateGroupRule',
    UPDATE_GROUP_RULE = 'UpdateGroupRule',
    DELETE_GROUP_RULE = 'DeleteGroupRule',
    NONCOMPLIANT_NOTICE_GROUP_RULE = 'NoncompliantNoticeGroupRule',
    ADD_GROUP_MEMBER = 'AddGroupMember',
    UPDATE_GROUP_MEMBER = 'UpdateGroupMember',
    REMOVE_GROUP_MEMBER = 'RemoveGroupMember',
    REQUEST_JOIN_GROUP_MEMBER = 'RequestJoinGroupMember',
    NOMINATE_GROUP_MEMBER = 'NominateGroupMember',
    APPROVE_GROUP_MEMBER = 'ApproveGroupMember',
    DENY_GROUP_MEMBER = 'DenyGroupMember',
    AUTO_APPROVE_GROUP_MEMBER = 'AutoApproveGroupMember',
    CREATE_GROUP = 'CreateGroup',
    UPDATE_GROUP = 'UpdateGroup',
    DELETE_GROUP = 'DeleteGroup',
    SCHEDULE_POLICY_VIOLATION = 'SchedulePolicyViolation',
    DOWNLOAD_GROUP_MEMBERS = 'DownloadGroupMembers',
    DOWNLOAD_GROUP_POLICY_VIOLATIONS = 'DownloadGroupPolicyViolations',
    LINK_SECURITY_GROUP = 'LinkSecurityGroup',
    UNLINK_SECURITY_GROUP = 'UnlinkSecurityGroup',
    UPDATE_CUSTOM_WELCOME_MESSAGE = 'UpdateCustomWelcomeMessage',
    UPDATE_CUSTOM_OFFBOARD_MESSAGE = 'UpdateCustomOffboardMessage',
    EMAIL_NOTIFICATION = 'EmailNotification',
    ADD_POINT_OF_CONTACT = 'AddPointOfContact',
    REMOVE_POINT_OF_CONTACT = 'RemovePointOfContact',
    /* eslint-enable @typescript-eslint/naming-convention */
}

export enum GroupActivityObjectType { // Is ActivityObjectType in group service. // Was ActivityObjectType in Angular app.
    AddedAllowListPersonnelId = 'AddedAllowListPersonnelId', // Was ADDED_ALLOW_LIST_PERSONNEL_ID in Angular.
    AddedBlockListPersonnelId = 'AddedBlockListPersonnelId', // Was ADDED_BLOCK_LIST_PERSONNEL_ID in Angular.
    AddedDependentGroupId = 'AddedDependentGroupId', // Was ADDED_DEPENDENT_GROUP_ID in Angular.
    ApplicationId = 'ApplicationId', // Was APPLICATION_ID in Angular.
    BusinessJustification = 'BusinessJustification', // Was BUSINESS_JUSTIFICATION in Angular.
    EffectiveEndTimestampUTC = 'EffectiveEndTimestampUTC', // Was EFFECTIVE_END_TIME in Angular
    EffectiveStartTimestampUTC = 'EffectiveStartTimestampUTC', // Was EFFECTIVE_START_TIME in Angular
    GracePeriod = 'GracePeriod', // Was GRACE_PERIOD in Angular
    GroupId = 'GroupId', // Was GROUP_ID in Angular.
    GroupRuleDescription = 'GroupRuleDescription', // Was GROUP_RULE_DESCRIPTION in Angular
    GroupRuleId = 'GroupRuleId', // Was GROUP_RULE_ID in Angular.
    GroupRuleName = 'GroupRuleName', // Was GROUP_RULE_NAME in Angular
    GroupRuleViolationId = 'GroupRuleViolationId',
    PersonnelId = 'PersonnelId', // Was PERSONNEL_ID in Angular.
    RemovedAllowListPersonnelId = 'RemovedAllowListPersonnelId', // Was REMOVED_ALLOW_LIST_PERSONNEL_ID in Angular.
    RemovedBlockListPersonnelId = 'RemovedBlockListPersonnelId', // Was REMOVED_BLOCK_LIST_PERSONNEL_ID in Angular.
    RemovedDependentGroupId = 'RemovedDependentGroupId', // Was REMOVED_DEPENDENT_GROUP_ID in Angular.
    SecurityGroupId = 'SecurityGroupId', // Was SECURITY_GROUP_ID in Angular.
    SecurityGroupType = 'SecurityGroupType',
    SuspendGroupRuleEnforcement = 'SuspendGroupRuleEnforcement', // Was SUSPEND_RULE_ENFORCEMENT in Angular
    SuspendGroupRuleNotifications = 'SuspendGroupRuleNotifications', // Was SUSPEND_NOTIFICATIONS in Angular
    System = 'System', // Was SYSTEM in Angular.
    DistributionList = 'DistributionList', // For the case POC type is PocTypeEnum.DistributionList
    User = 'User', // Common-event type whic is repalcing PersonnelId
    Application = 'Application', // Common-event type which is replacing ApplicationId
}

// Was ActivityObject in Angular
export interface IGroupActivityObject {
    type: GroupActivityObjectType;
    value: string;
}

// Was Activity in Angular
export interface IGroupActivity {
    id: string;
    receivedTimestampUTC: number;
    event: GroupActivityEvent;
    eventTimestampUTC: number;
    subject: IGroupActivityObject;
    directObject: IGroupActivityObject;
    additionalObjects: IGroupActivityObject[] | null | undefined;
    message: string;
    // metadata is declared as "dynamic" on the backend.
    // I have added the values that I have discovered so far to
    // IGroupActivityMetaData. I am not sure if it's comprehensive,
    metadata: IGroupActivityMetaData;
    tags: GroupActivityTag[];
    securityIds: string[];
    referenceId: string;
}

interface IGroupActivityMetaData {
    // From backend GroupMembershipMetadata
    groupMemberRole: string;
    groupMemberRoleBefore: string;
    justification: string;
    justificationBefore: string;
    sponsor: string;
    sponsorBefore: string;
    notes: string;
    notesBefore: string;
    groupMemberStatus: GroupMemberStatus;
    groupMemberStatusBefore: GroupMemberStatus;
    // From backend GroupSettingMetadata
    groupName: string;
    groupSettingName: string;
    groupSettingCurrentValue: string;
    groupSettingPreviousValue: string;
    // From backend GroupRuleMetadata
    groupRuleName: string;
    groupRuleType: GroupRuleType;
    // From backend EmailNotificationMetadata
    emailNotificationType: EmailNotificationType;
}

// Was ActivityFilters in Angular
export interface IGroupActivityFilters {
    startDate?: number;
    endDate?: number;
    personnelId?: string; // todo: remove after "groups-internal" components are cleaned-up
    principalId?: string;
    categories?: GroupActivityTag[]; // Was ActivityTag in Angular
    subjectsOnly?: boolean;
}

export enum GroupActivityEventUpdateGroupSettings {
    /* eslint-disable @typescript-eslint/naming-convention */
    requireSponsor = 'requireSponsor',
    requireJustification = 'requireJustification',
    requireMemberRemovalJustification = 'requireMemberRemovalJustification',
    allowJoin = 'allowJoin',
    enableHidden = 'enableHidden',
    enableDynamic = 'enableDynamic',
    allowMemberNomination = 'allowMemberNomination',
    autoApproveJoin = 'autoApproveJoin',
    enableMemberNoncomplianceNotification = 'enableMemberNoncomplianceNotification',
    enableMemberRoleNotification = 'enableMemberRoleNotification',
    memberNonComplianceNotificationDays = 'memberNonComplianceNotificationDays',
    enableWelcomeMessage = 'enableWelcomeMessage',
    enableOffboardMessage = 'enableOffboardMessage',
    groupName = 'groupName',
    groupDescription = 'groupDescription',
    groupType = 'type',
    primaryCategorization = 'primaryCategorization',
    sensitivityLevel = 'sensitivityLevel',
    division = 'division',
    cvpApprovedBy = 'cvpApprovedBy',
    agcApprovedBy = 'agcApprovedBy',

    /* eslint-enable @typescript-eslint/naming-convention */
}
/**
 * group-rules.model.ts
 */

// Was GroupRuleRequest in Angular
export interface IGroupRuleRequest extends IGroupRuleDefinition {
    ruleData: GroupRuleRequestRuleData;
    suspended?: boolean;
    optOutFromPolicyViolationNotifications?: boolean;
    enableNewPolicyMessage?: boolean;
    effectiveStartTimestampUTC?: number | null;
    effectiveEndTimestampUTC?: number | null;
    allowList: string[];
    metadata: string | null;
    standDownPeriodDays?: number;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _etag: string;
}

// Was GroupRuleDefinition in Angular
export interface IGroupRuleDefinition {
    name: string;
    description: string;
    gracePeriod: string;
    type: GroupRuleType;
}

export type GroupRuleRequestRuleData =
    | IAttributeCheckRuleData
    | ITrainingCheckRuleData
    | IEligibilityCheckRuleData
    | IUSCitizenshipCheckRuleData
    | ILocationCheckRuleData
    | ICloudScreeningCheckRuleData
    | IFTECheckRuleData
    | IAttributeHRDataCheckCompanyCodesRuleData
    | IAttributeHRDataCheckCountryCodesRuleData
    | IAttributeHRDataCheckCostCenterCodesCodesRuleData
    | IAttributeGroupIdsRuleData
    | IAttributeStandDownCheckRuleData
    | ICANDARuleData
    | IUARRuleCheckData
    | IOrgCheckRuleData
    | IPrincipalAttributeExpression;

interface IUARRuleCheckData {
    CANDAText: string;
    durationDays: number;
    subtype: lifecycleEventOptionsKeys;
    approverType: UserAccessReviewApproverType;
    invalidatePriorAgreementCheck: boolean;
}

// Was AttributeCheckRuleData in Angular
export interface IAttributeCheckRuleData {
    attributeIds: string[];
}

export interface IAttributeGroupIdsRuleData {
    groupIds: string[];
}
export interface IAttributeHRDataCheckCompanyCodesRuleData {
    field: string;
    companyCodes: string[];
}
export interface IAttributeHRDataCheckCountryCodesRuleData {
    field: string;
    countryCodes: string[];
}
export interface IAttributeHRDataCheckCostCenterCodesCodesRuleData {
    field: string;
    costCenterCodes: string[];
    evaluationType: string;
}
export interface IAttributeStandDownCheckRuleData {
    standDownDays: number;
}

// Was TrainingCheckRuleData in Angular
export interface ITrainingCheckRuleData {
    courseIds: string[];
}

// Was EligibilityCheckRuleData in Angular
export interface IEligibilityCheckRuleData {
    eligibilityIds: string[];
}

// Was USCitizenshipCheckRuleData in Angular
export interface IUSCitizenshipCheckRuleData {
    managerCheck: boolean;
}

// Was LocationCheckRuleData in Angular
export interface ILocationCheckRuleData {
    managerCheck: boolean;
    countryCode: string;
}

// Was CloudScreeningCheckRuleData in Angular
export interface ICloudScreeningCheckRuleData {
    managerCheck: boolean;
}

// Was FTECheckRuleData in Angular
export interface IFTECheckRuleData {
    managerCheck: boolean;
}

// Was CANDARuleData in Angular
export interface ICANDARuleData {
    CANDAText: string;
    invalidatePriorAgreementCheck: boolean;
}

// Was StandDownRuleData in Angular
export interface IStandDownRuleData {
    standDownPeriodDays: number;
}

export interface IOrgCheckRuleData {
    employeeIds: string[];
}

// Deprecated enum values are rules that are not supported by principalCore.
export enum GroupRuleType {
    /* eslint-disable @typescript-eslint/naming-convention */
    /** @deprecated */
    ATTRIBUTE_CHECK_RULE = 'ATTRIBUTE_CHECK_RULE',
    TRAINING_CHECK_RULE = 'TRAINING_CHECK_RULE',
    /** @deprecated */
    CLOUD_SCREENING_CHECK_RULE = 'CLOUD_SCREENING_CHECK_RULE',
    /** @deprecated */
    ELIGIBILITY_CHECK_RULE = 'ELIGIBILITY_CHECK_RULE',
    /** @deprecated */
    FTE_CHECK_RULE = 'FTE_CHECK_RULE',
    /** @deprecated */
    LOCATION_CHECK_RULE = 'LOCATION_CHECK_RULE',
    /** @deprecated */
    US_CITIZENSHIP_CHECK_RULE = 'US_CITIZENSHIP_CHECK_RULE',
    CA_NDA_CHECK_RULE = 'CA_NDA_CHECK_RULE',
    /** @deprecated */
    GROUP_MEMBERSHIP_CHECK_RULE = 'GROUP_MEMBERSHIP_CHECK_RULE',
    /** @deprecated */
    HR_DATA_CHECK_RULE = 'HR_DATA_CHECK_RULE',
    STAND_DOWN_CHECK_RULE = 'STAND_DOWN_CHECK_RULE',
    USER_ACCESS_REVIEW_CHECK_RULE = 'USER_ACCESS_REVIEW_CHECK_RULE',
    /** @deprecated */
    ORGANIZATION_CHECK_RULE = 'ORGANIZATION_CHECK_RULE',
    PRINCIPAL_ATTRIBUTE_CHECK_RULE = 'PRINCIPAL_ATTRIBUTE_CHECK_RULE',
    /* eslint-enable @typescript-eslint/naming-convention */
}

export enum lifecycleEventOptionsKeys {
    DurationLifecycle = 'DurationLifecycle',
}

// Was HRDataCheckField in Angular
export enum IHRDataCheckField {
    CompanyCode = 'Company Code',
    CountryCode = 'Country Code',
    CostCenterCode = 'Cost Center Code',
}

export interface ICourseDetail {
    id: string;
    name: string;
}

export enum IUserAccessReviewSubtype {
    DurationLifecycle = 'Duration',
}

export enum UserAccessReviewApproverType {
    Member = 'Member',
    Manager = 'Manager',
    Sponsor = 'Sponsor',
    GroupOwner = 'GroupOwner',
}

export const EnabledUserAccessReviewApproverTypes = [
    UserAccessReviewApproverType.Member,
    UserAccessReviewApproverType.Manager,
    UserAccessReviewApproverType.Sponsor,
];

enum ErrorReponses {
    employeeNotFound = 'Unable to locate the employee provided',
    addMemberDuplicate = 'The employee is already part of this group',
    addMemberJoinRequestExists = 'The employee already has a pending join request',
    addMemberUnknown = 'Unknown error occured while attempting to add member to group',
    blockedMember = 'You must remove this member from the Block List in order to add them to the group',
}

export interface ICANDAText {
    text: string;
    version: number;
    timestamp: number;
    invalidatePriorAgreementCheck: boolean;
}

export type GroupMemberReport = {
    alias: string;
    personnelId: string;
    role: string;
    status: string;
    compliant: boolean;
    inGracePeriod: boolean;
    inAllowList: boolean;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
    hierarchyLvl2: string;
    hierarchyLvl3: string;
    hierarchyLvl4: string;
    hierarchyLvl5: string;
    justification: string;
    sponsorId: string;
    sponsorAlias: string;
    addedBy: string;
    addedByType: string;
    costCenterCode: string;
    addedTimestampPST: string;
    requestedType: string;
    requestedBy: string;
    requestedTimestampPST: string;
};

export type GroupMemberReportExternal = {
    alias: string;
    personnelId: string;
    role: string;
    status: string;
    compliant: boolean;
    inGracePeriod: boolean;
    inAllowList: boolean;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
    justification: string;
    sponsorId: string;
    sponsorAlias: string;
    addedBy: string;
    addedByType: string;
    addedTimestampPST: string;
    requestedType: string;
    requestedBy: string;
    requestedTimestampPST: string;
};

export type GroupMemberViolationReport = {
    personnelId: string;
    alias: string;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
    ruleName: string;
    ruleDescription: string;
    evaluationState: GroupRuleEvaluationStateReporting;
    errorMessage: string;
    creationTimestampPST?: number;
    nonComplianceTimestampPST?: number;
    daysNonCompliant?: number;
};

enum GroupRuleEvaluationStateReporting {
    Pass = 'PASS',
    Fail = 'FAIL',
    Grace = 'GRACE',
    AllowList = 'ALLOW_LIST',
}

enum EmailNotificationType {
    RoleAdded = 'RoleAdded',
    RoleUpdated = 'RoleUpdated',
    RoleDeleted = 'RoleDeleted',
}

export enum PocTypeEnum {
    Employee = 'Employee',
    DistributionList = 'DistributionList',
}

export const POC_JUSTIFICATION_MAX_LENGTH = 2000;

export interface IAddPocRequest {
    contactValueType: PocTypeEnum;
    contactValue: string;
    justification?: string;
}

export interface IPointOfContact {
    contactValueType: PocTypeEnum;
    contactValue: string;
    createdBy: string;
    createdTimestampUTC: number;
    justification: string;
}

export interface IMemberCheckStatus {
    groupId: string;
    groupName: string;
    isMember: boolean;
}

// TODO remove this once Groups backend no longer supports personnel ids
export async function GetAadHttpOptions(
    context: IAuthContext,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    aadScopes: any,
    contentType?: string,
    continuationToken?: string,
): Promise<RequestInit> {
    const token = await context.getToken(aadScopes);
    return {
        headers: {
            Authorization: AUTH_PREFIX + token,
            'Content-Type': contentType ? contentType : JSON_CONTENT_TYPE,
            'x-continuation-token': continuationToken ? continuationToken : '',
            'x-use-principalid': `true`,
        },
    };
}
