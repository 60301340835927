/**
 * Shows a label, followed by an edit icon
 */
import { IconNames } from 'assets/constants/global-constants';
import { ActionButton, Label, Stack } from '@fluentui/react';
import React from 'react';

interface LabelAndEditProps {
    label: string;
    required?: boolean;
    canEdit: boolean;
    hideEditButton: boolean;
    onClickEdit: () => void;
}

export default function LabelAndEditButton(props: LabelAndEditProps): JSX.Element {
    return (
        <Stack horizontal horizontalAlign='start' verticalAlign='center'>
            <Stack.Item>
                <Label required={props.required}>{props.label}</Label>
            </Stack.Item>
            {props.canEdit && !props.hideEditButton && (
                <Stack.Item>
                    <ActionButton
                        ariaLabel={props.label}
                        iconProps={{ iconName: IconNames.Edit }}
                        onClick={props.onClickEdit}
                    />
                </Stack.Item>
            )}
        </Stack>
    );
}
