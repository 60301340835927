import { ICustomBreadcrumbItem } from 'components/common/bread-crumb';

export const staffingPageBreadcrumbs: ICustomBreadcrumbItem[] = [
    {
        title: 'Staffing',
        link: '/staffing',
    },
];

export const staffingOrgBreadcrumb = (urlOrgName: string): ICustomBreadcrumbItem[] => [
    ...staffingPageBreadcrumbs,
    {
        title: 'Org',
        link: '/staffing/org',
    },
    {
        title: urlOrgName,
        link: '/staffing/org/:urlOrgName',
    },
];

export const staffingStatusBreadcrumb: ICustomBreadcrumbItem[] = [
    ...staffingPageBreadcrumbs,
    {
        title: 'Status',
        link: '/staffing/status',
    },
];
