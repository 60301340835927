import {
    ActionButton,
    Selection,
    IColumn,
    IDragDropEvents,
    MarqueeSelection,
    MessageBar,
    MessageBarType,
    SelectionMode,
    mergeStyleSets,
    IColumnReorderOptions,
    mergeStyles,
    DetailsRow,
    IDetailsRowProps,
    ShimmeredDetailsList,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import FacilitiesClient, {
    IEquipmentRecord,
    EquipmentTypes,
    IFacilityRecord,
    Regions,
} from 'clients/facilities-client';
import EmployeeBasicHoverCardSimple from 'components/common/employee/employee-basic-hover-card-simple';
import useEmployeeBasicRecords from 'components/common/employee/use-employee-basic-records';
import HorizontalBar from 'components/common/horizontal-bar';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TimeFormats } from 'utils/time-utils';
import AddEditEquipmentModal from 'components/user-assignments/equipment/add-edit-equipment-modal';
import { IEmployee } from 'clients/employee-client';
import DeleteEquipmentModal from 'components/user-assignments/equipment/delete-equipment-modal';
import { handleUTCToFacilityTimeZone } from 'components/facilities/common/facility-time-utils';
import { FacilityUserType } from 'utils/facilities-utils';

const dragEnterClass = mergeStyles({
    backgroundColor: '#777',
});

type EquipmentProps = {
    employee: IEmployee;
    isAdminPage: boolean;
};

export type PartialEquipmentRecord = Partial<IEquipmentRecord>;

export default function Equipment(props: EquipmentProps): JSX.Element {
    const { employee, isAdminPage } = props;
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const selection = useRef<Selection>(new Selection());
    const dragDropEvents = useRef<IDragDropEvents>(getDragDropEvents());
    const [equipmentRecords, setEquipmentRecords] = useState<IEquipmentRecord[]>();
    const [facilities, setFacilities] = useState<IFacilityRecord[]>([]);
    const [draggedItem, setDraggedItem] = useState<any>();
    const [draggedIndex, setDraggedIndex] = useState<number>(-1);
    const [columnOrder, setColumnOrder] = useState<number[]>();
    const [showAddEditModal, setShowAddEditModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState<IEquipmentRecord | undefined>();
    const [facilityMap, setFacilityMap] = useState<Map<string, IFacilityRecord>>(
        new Map<string, IFacilityRecord>(),
    );

    const newEquipmentItem: PartialEquipmentRecord = {
        ownerPersonnelId: employee.id,
    };

    const [selectedRecord, setSelectedRecord] = useState<PartialEquipmentRecord>(newEquipmentItem);
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [isWorking, setIsWorking] = useState(true);
    const [showInfoMessageBar, setShowInfoMessageBar] = useState(true);

    const isColumnReorderEnabled = true;

    function getDragDropEvents(): IDragDropEvents {
        return {
            canDrop: (): boolean => {
                return true;
            },
            canDrag: (): boolean => {
                return true;
            },
            onDragEnter: (): string => {
                return dragEnterClass;
            },
            onDrop: (item?: any): void => {
                if (draggedItem) {
                    insertBeforeItem(item);
                }
            },
            onDragStart: (item?: IEquipmentRecord, itemIndex?: number): void => {
                setDraggedItem(item);
                setDraggedIndex(itemIndex!);
            },
            onDragEnd: (): void => {
                setDraggedItem(undefined);
                setDraggedIndex(-1);
            },
        };
    }

    const insertBeforeItem = (item: IEquipmentRecord): void => {
        if (!equipmentRecords) {
            return;
        }

        const draggedItems = selection.current.isIndexSelected(draggedIndex)
            ? (selection.current.getSelection() as IEquipmentRecord[])
            : [draggedItem!];

        const insertIndex = equipmentRecords.indexOf(item);
        const itemsVar = equipmentRecords.filter(
            (item: IEquipmentRecord) => draggedItems.indexOf(item) === -1,
        );

        itemsVar.splice(insertIndex, 0, ...draggedItems);

        setEquipmentRecords(itemsVar);
    };

    function handleColumnReorder(draggedIndex: number, targetIndex: number): void {
        if (!columnOrder) return;
        const target = columnOrder[targetIndex];
        const dragged = columnOrder[draggedIndex];
        const newColumnOrder = [...columnOrder];
        newColumnOrder[targetIndex] = dragged;
        newColumnOrder[draggedIndex] = target;
        setColumnOrder(newColumnOrder);
    }

    function getColumnReorderOptions(): IColumnReorderOptions {
        return {
            frozenColumnCountFromStart: 0,
            frozenColumnCountFromEnd: 0,
            handleColumnReorder: handleColumnReorder,
        };
    }

    const {
        getBasicEmployeeRecords,
        basicEmployeesMap,
        employeesPictureMap,
    } = useEmployeeBasicRecords();

    const columns: IColumn[] = useMemo(() => {
        const defaultColumns = [
            {
                position: 0,
                key: 'region',
                name: 'Region',
                minWidth: 50,
                maxWidth: 150,
                isResizable: true,
                onRender: (item: IEquipmentRecord): JSX.Element => <>{Regions[item.region]}</>,
            },
            {
                position: 1,
                key: 'facility',
                name: 'Facility',
                minWidth: 25,
                maxWidth: 50,
                isResizable: true,
                onRender: (item: IEquipmentRecord): JSX.Element => (
                    <>{facilities.find((x) => x.facilityId === item.facilityId)?.facilityName}</>
                ),
            },
            {
                position: 2,
                key: 'type',
                name: 'Type',
                minWidth: 50,
                maxWidth: 80,
                isResizable: true,
                onRender: (item: IEquipmentRecord): JSX.Element => <>{EquipmentTypes[item.type]}</>,
            },
            {
                position: 3,
                key: 'assetNumber',
                name: 'Asset/card number',
                fieldName: 'assetNumber',
                minWidth: 25,
                maxWidth: 130,
                isResizable: true,
            },
            {
                position: 4,
                key: 'status',
                name: 'Status',
                fieldName: 'status',
                minWidth: 25,
                maxWidth: 50,
                isResizable: true,
            },
            {
                position: 5,
                key: 'issuedOn',
                name: 'Issued on',
                minWidth: 50,
                maxWidth: 160,
                isResizable: true,
                onRender: (item: IEquipmentRecord): JSX.Element => {
                    return (
                        <>
                            {item.issued.atUtc
                                ? handleUTCToFacilityTimeZone(
                                      item.issued.atUtc,
                                      facilityMap.get(item.facilityId),
                                      TimeFormats.MMMDDYYYY,
                                  )
                                : ''}
                        </>
                    );
                },
            },
            {
                position: 6,
                key: 'issuedBy',
                name: 'Issued by',
                minWidth: 100,
                maxWidth: 230,
                isResizable: true,
                onRender: (item: IEquipmentRecord): JSX.Element => {
                    const employeeBasicData = basicEmployeesMap.get(item.issued.by);
                    if (employeeBasicData === undefined) {
                        return <></>;
                    } else {
                        const base64ImageStr = employeesPictureMap.get(item.issued.by);
                        return (
                            <div style={{ marginTop: '5px' }}>
                                <EmployeeBasicHoverCardSimple
                                    employeeBasicData={employeeBasicData}
                                    image={base64ImageStr}
                                />
                            </div>
                        );
                    }
                },
            },
            {
                position: 7,
                key: 'expiration',
                name: 'Expiration',
                minWidth: 50,
                maxWidth: 160,
                isResizable: true,
                onRender: (item: IEquipmentRecord): JSX.Element => {
                    return (
                        <>
                            {item.expirationOnUtcMilliseconds
                                ? handleUTCToFacilityTimeZone(
                                      item.expirationOnUtcMilliseconds,
                                      facilityMap.get(item.facilityId),
                                      TimeFormats.MMMDDYYYY,
                                  )
                                : ''}
                        </>
                    );
                },
            },
        ];

        const actionsColumn = {
            position: 8,
            key: 'action',
            name: 'Actions',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            onRender: (item: IEquipmentRecord): JSX.Element => {
                return (
                    <span>
                        <ActionButton
                            iconProps={{ iconName: IconNames.IDBadge }}
                            onClick={(): void => {
                                setSelectedRecord(item);
                                setShowAddEditModal(true);
                            }}>
                            Manage
                        </ActionButton>
                        <ActionButton
                            iconProps={{ iconName: IconNames.Delete }}
                            onClick={(): void => {
                                setItemToDelete(item);
                            }}>
                            Delete
                        </ActionButton>
                    </span>
                );
            },
        };

        if (
            isAdminPage &&
            (userContext.hasFacilitiesUserType(FacilityUserType.EquipmentOfficerRole) ||
                userContext.hasFacilitiesUserType(FacilityUserType.ManagerRole) ||
                userContext.hasFacilitiesUserType(FacilityUserType.AdminService))
        ) {
            defaultColumns.push(actionsColumn);
        }

        if (columnOrder && columnOrder?.length > 0) {
            return columnOrder.map((columnIndex) => defaultColumns[columnIndex]);
        }
        return defaultColumns;
    }, [columnOrder, basicEmployeesMap, employeesPictureMap]);

    const loadData = async (): Promise<void> => {
        const [fetchedEquipment, tempFacilities] = await Promise.all([
            getEquipment(),
            getFacilityNamesByIds(),
        ]);

        setEquipmentRecords(fetchedEquipment);
        setFacilities(tempFacilities);
        const facilityMapVar = new Map<string, IFacilityRecord>();
        tempFacilities.forEach((f) => {
            facilityMapVar.set(f.facilityId, f);
        });
        setFacilityMap(facilityMapVar);

        const personnelIds = fetchedEquipment.flatMap((item: IEquipmentRecord) => [item.issued.by]);
        getBasicEmployeeRecords(personnelIds);
        setIsWorking(false);
    };

    const getFacilityNamesByIds = async (): Promise<IFacilityRecord[]> => {
        try {
            const response = await FacilitiesClient.getFacilityRecordsEquipmentOfficer(
                authContext,
                userContext,
            );
            return response;
        } catch (error) {
            console.error(error + 'error getting facility names');
        }
        return [];
    };

    const getEquipment = async (): Promise<IEquipmentRecord[]> => {
        try {
            if (!employee?.id) {
                throw new Error('No person id found');
            }
            const response = await FacilitiesClient.getAllEquipmentByPersonnelId(
                authContext,
                userContext,
                employee.id,
            );
            return response.results;
        } catch (error) {
            console.error(error);
        }
        return [];
    };

    useEffect(() => {
        if (employee) {
            loadData();
        }
    }, []);

    useEffect(() => {
        if (equipmentRecords && equipmentRecords.length > 0) {
            setColumnOrder(columns.map((column) => columns.indexOf(column)));
        }
    }, [equipmentRecords]);

    useEffect(() => {
        if (shouldRefresh) {
            loadData();
            setShouldRefresh(false);
        }
    }, [shouldRefresh]);

    const customRenderRow = (props?: IDetailsRowProps): JSX.Element => {
        return props ? (
            <DetailsRow
                {...props}
                styles={{
                    fields: {
                        alignItems: 'center',
                        color: '#323130',
                        fontSize: '14px',
                        fontWeight: 400,
                    },
                }}
            />
        ) : (
            <></>
        );
    };

    return (
        <>
            {showInfoMessageBar && (
                <MessageBar
                    messageBarType={MessageBarType.warning}
                    onDismiss={(): void => setShowInfoMessageBar(false)}
                    dismissButtonAriaLabel='Dismiss'>
                    Questions? Contact the
                    <a href='mailto:NSTcompliance@microsoft.com'>National Security Team</a> for
                    support.
                </MessageBar>
            )}
            {isAdminPage &&
                (userContext.hasFacilitiesUserType(FacilityUserType.EquipmentOfficerRole) ||
                    userContext.hasFacilitiesUserType(FacilityUserType.ManagerRole) ||
                    userContext.hasFacilitiesUserType(FacilityUserType.AdminService)) && (
                    <HorizontalBar>
                        <ActionButton
                            iconProps={{ iconName: IconNames.Add }}
                            onClick={(): void => {
                                setSelectedRecord(newEquipmentItem);
                                setShowAddEditModal(true);
                            }}>
                            Add
                        </ActionButton>
                    </HorizontalBar>
                )}
            {showAddEditModal && (
                <AddEditEquipmentModal
                    existingEquipment={selectedRecord}
                    setShowAddEditModal={setShowAddEditModal}
                    facilities={facilities ?? []}
                    setShouldRefresh={setShouldRefresh}
                />
            )}
            {itemToDelete && (
                <DeleteEquipmentModal
                    itemToDelete={itemToDelete}
                    setItemToDelete={setItemToDelete}
                    setShouldRefresh={setShouldRefresh}
                />
            )}
            {(isWorking || (equipmentRecords && equipmentRecords.length > 0)) && (
                <MarqueeSelection selection={selection.current}>
                    <ShimmeredDetailsList
                        selectionMode={SelectionMode.none}
                        items={equipmentRecords ?? []}
                        columns={columns}
                        selection={selection.current}
                        dragDropEvents={dragDropEvents.current}
                        selectionPreservedOnEmptyClick={true}
                        columnReorderOptions={
                            isColumnReorderEnabled ? getColumnReorderOptions() : undefined
                        }
                        onRenderRow={customRenderRow}
                        enableShimmer={isWorking}
                    />
                </MarqueeSelection>
            )}
            {equipmentRecords && equipmentRecords.length < 1 && (
                <div className={styles.helpText}>
                    No equipment has been added, reach out to the{' '}
                    <a href='mailto:NSTcompliance@microsoft.com'>National Security Team</a> if you
                    need equipment added.
                </div>
            )}
        </>
    );
}

const styles = mergeStyleSets({
    helpText: {
        paddingTop: 16,
        paddingLeft: 20,
        fontSize: 16,
    },
});
