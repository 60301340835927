import React from 'react';
import { ActionButton, TooltipHost } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';

interface ILoadAllActionButtonProps {
    text?: string; // Default: "Load all"
    onClick: () => void;
}

export default function LoadAllActionButton(props: ILoadAllActionButtonProps): JSX.Element {
    return (
        <TooltipHost content='Load all records into table based on current search and filter settings'>
            <ActionButton iconProps={{ iconName: IconNames.Download }} onClick={props.onClick}>
                <span>{props.text ?? 'Load all'}</span>
            </ActionButton>
        </TooltipHost>
    );
}
