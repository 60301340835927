import React from 'react';
import { IStep, StepTime } from 'types/screening-step';

export function VerticalNotches(props: {
    steps: IStep[];
    height: number;
    radius: number;
    color: string;
    innerFillColor?: string;
    onNotchClick?: (s: string) => void;
}) {
    const { height, steps, radius, color } = props;
    const innerFillColor = props.innerFillColor ?? '#ffffff';
    const strokeWidth = 3;
    const cx = radius + strokeWidth;
    return (
        <svg width={radius * 2} height={height} xmlns='http://www.w3.org/2000/svg'>
            <g>
                <SegmentedLines {...props} />

                {steps.map((step, index) => (
                    <Notch
                        key={index}
                        color={color}
                        innerFillColor={innerFillColor}
                        cx={cx}
                        cy={radius + getNotchTopYCoordinate(height, radius, index, steps)}
                        radius={radius}
                        step={step}
                        strokeWidth={strokeWidth}
                        onNotchClick={step.label === 'NOMINATION' ? undefined : props.onNotchClick}
                    />
                ))}
            </g>
        </svg>
    );
}

function Notch(props: {
    cx: number;
    cy: number;
    color: string;
    innerFillColor: string;
    strokeWidth: number;
    radius: number;
    step: IStep;
    onNotchClick?: (s: string) => void;
}) {
    const { cx, cy, color, radius, step } = props;
    const strokeWidth = props.strokeWidth < 1 || props.strokeWidth > 5 ? 1 : props.strokeWidth;
    const common = { cx, cy };

    const onNotchClick = props.onNotchClick
        ? props.onNotchClick
        : () => {
              return;
          };

    return (
        <g>
            <circle
                {...common}
                fill={color}
                r={radius - strokeWidth}
                onClick={() => {
                    onNotchClick(step.label);
                }}
            />
            {[StepTime.CURRENT, StepTime.FUTURE].includes(step.time) && (
                <circle
                    {...common}
                    fill={props.innerFillColor}
                    r={radius - strokeWidth * 2.3}
                    onClick={() => {
                        if (step.time === StepTime.FUTURE) {
                            onNotchClick(step.label);
                        }
                    }}
                />
            )}
            {step.time === StepTime.CURRENT && (
                <circle {...common} fill={color} r={radius - strokeWidth * 3.5} />
            )}
        </g>
    );
}

function SegmentedLines(props: { steps: IStep[]; height: number; radius: number; color: string }) {
    const { height, steps, radius, color } = props;
    const strokeWidth = 4;
    const NOTCH_TO_LINE_GAP = 2;
    return (
        <g>
            {steps.map((step, index) => {
                if (index !== 0) {
                    const curNotchTopYCoord = getNotchTopYCoordinate(height, radius, index, steps);
                    const prevNotchTopYCoord = getNotchTopYCoordinate(
                        height,
                        radius,
                        index - 1,
                        steps,
                    );
                    const lineTopYCoord = prevNotchTopYCoord + radius * 2 + NOTCH_TO_LINE_GAP;
                    const lineBottomYCoord = curNotchTopYCoord - NOTCH_TO_LINE_GAP;

                    return (
                        <line
                            key={index}
                            x1={radius + strokeWidth - 1}
                            y1={lineTopYCoord}
                            x2={radius + strokeWidth - 1}
                            y2={lineBottomYCoord}
                            strokeWidth={strokeWidth}
                            stroke={color}
                        />
                    );
                }
            })}
        </g>
    );
}

function getNotchTopYCoordinate(
    height: number,
    radius: number,
    index: number,
    steps: any[],
): number {
    let cy = index * ((height - radius * 2) / (steps.length - 1));
    if (steps[index].label === 'PREPARATION') {
        cy *= 0.9;
    } else if (steps[index].label === 'SUBMITTED') {
        cy *= 0.97;
    } else if (steps[index].label === 'ADJUDICATED') {
        cy *= 0.89;
    } else if (steps[index].label === 'INDOC') {
        cy *= 0.89;
    } else if (steps[index].label === 'COMPLETED') {
        cy *= 0.955;
    }
    return cy;
}
