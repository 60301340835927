import {
    ICalloutProps,
    IRectangle,
    ITeachingBubbleStyles,
    Icon,
    TeachingBubble,
    mergeStyleSets,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { BrowserCacheKeys } from 'utils/browser-cache-utils';

type FormsTeachingBubbleProps = {
    idTarget: string;
    headline: string;
    bodyText: string;
    showBubble: boolean;
    setShowBubble: (showBubble: boolean) => void;
};

export function FormsTeachingBubble(props: FormsTeachingBubbleProps): JSX.Element {
    const { idTarget, headline, bodyText, showBubble, setShowBubble } = props;
    const hideLimitCount = 6;

    // hack to rerender the teaching bubble when the user scrolls while allowing dismiss
    // https://github.com/microsoft/fluentui/issues/3809
    // https://github.com/microsoft/fluentui/pull/3810
    // https://codepen.io/ling1726/pen/XWRpWxj
    const [, setRerenderCount] = useState<number>(0);
    const rerender = (): void => setRerenderCount((s) => s + 1);

    const canShowBubble = (): boolean =>
        parseInt(localStorage.getItem(BrowserCacheKeys.formsTeachingBubble) ?? '0') <
        hideLimitCount;

    useEffect(() => {
        if (!canShowBubble()) {
            setShowBubble(false);
        }
    }, [setShowBubble, showBubble]);

    const onClose = (ev: any): void => {
        const closedByCloseButton = ev?.currentTarget?.className.includes('close');
        if (closedByCloseButton && canShowBubble()) {
            localStorage.setItem(
                BrowserCacheKeys.formsTeachingBubble,
                `${
                    parseInt(localStorage.getItem(BrowserCacheKeys.formsTeachingBubble) ?? '0') + 1
                }`,
            );
        }
        setShowBubble(false);
    };

    const preventDismissWorkaround = (
        ev: Event | React.FocusEvent | React.KeyboardEvent | React.MouseEvent,
    ): boolean => {
        if (ev.type === 'scroll' || ev.type === 'resize') {
            rerender();
            return true;
        } else {
            return false;
        }
    };

    const calloutBounds: IRectangle = {
        top: 64 + 8, // Prevents callout from overlapping header and nav
        left: 8,
        right: document.body.clientWidth - 8,
        bottom: window.innerHeight - 8,
        width: document.body.clientWidth - 8,
        height: window.innerHeight,
    };

    const calloutProps: ICalloutProps = {
        preventDismissOnEvent: preventDismissWorkaround,
        bounds: calloutBounds,
    };

    return (
        <>
            {showBubble && canShowBubble() && (
                <TeachingBubble
                    styles={teachingBubbleStyle}
                    calloutProps={calloutProps}
                    hasCloseButton={true}
                    target={idTarget}
                    onDismiss={onClose}
                    hasCondensedHeadline>
                    <p className={styles.topHeadline}>
                        <Icon iconName='Lightbulb' className={styles.bulb} />
                        New feature
                    </p>
                    <p className={styles.middleHeadline}>{headline}</p>
                    <p className={styles.bottomText}>{bodyText}</p>
                </TeachingBubble>
            )}
        </>
    );
}
const teachingBubbleStyle: Partial<ITeachingBubbleStyles> = {
    root: {
        boxShadow: '0 0 0px #000',
    },
};

const styles = mergeStyleSets({
    bulb: {
        paddingRight: '0.5rem',
    },
    topHeadline: {
        fontWeight: 600,
        marginBlockStart: 0,
    },
    middleHeadline: {
        fontWeight: 600,
        fontSize: '20px',
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    bottomText: {
        fontSize: '14px',
    },
});
