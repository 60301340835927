import React, { useEffect, useState } from 'react';
import {
    mergeStyleSets,
    FontWeights,
    Dropdown,
    IDropdownOption,
    DatePicker,
} from '@fluentui/react';
import ContainerWithEtiquettes, {
    IContainerWithEtiquettesProps,
} from 'components/common/container-with-etiquettes';
import { nameof, setNestedKeyValueOfCloneObject } from 'utils/object-utils';
import { SharedColors } from '@fluentui/theme';
import { IPerson } from 'clients/graph-client';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import {
    PublicTrustRequestTypes,
    PUBLIC_TRUST_REQUEST_TYPE_OPTIONS,
    PublicTrustAgenciesOptions,
} from 'components/screening/public-trust/public-trust-screening-result';
import {
    getParentState,
    ScreeningParentStateType,
    ScreeningRequestTypesLabels,
    TARGET_LEVEL_OPTIONS,
} from 'components/screening/common/common-constants';
import { dateToLocalLongDateFormat } from 'utils/time-utils';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { AdjudicatedSubStates } from 'components/screening/us-gov/IScreening';
import { SuitabilityLevels } from 'components/personnel-profile/suitability/profile-suitability-types';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/suitability/profile-suitability-utils';

export const ADJUDICATED_OPTIONS_PUBLIC_TRUST: IDropdownOption[] = [
    { key: AdjudicatedSubStates.Final, text: AdjudicatedSubStates.Final, index: 0 },
    { key: AdjudicatedSubStates.Interim, text: AdjudicatedSubStates.Interim, index: 1 },
];

export interface EmployeePublicTrustDataProps {
    screening: ICommonScreening;
    processOwner?: IPerson;
    isDataEditable: boolean;
    onEditableFieldChange(screen: ICommonScreening): void;
    setShowInvalidAgencyChangeMessageBar(showMessage: boolean): void;
}

const isPIVRequiredString = 'isPIVRequired';
const requiredString = 'Required';

enum publicTrustEditableFields {
    requestType = 'requestType',
    publicTrustAgency = 'publicTrustAgency',
    isPIVRequired = 'isPIVRequired',
    adjudicatedResult = 'adjudicatedResult',
    adjudicatedUtcMillis = 'adjudicatedUtcMillis',
    indocBriefingUtcMillis = 'indocBriefingUtcMillis',
    indocTrainingUtcMillis = 'indocTrainingUtcMillis',
    suitabilityLevel = 'suitabilityLevel',
}

function EmployeePublicTrustData(props: EmployeePublicTrustDataProps): JSX.Element {
    const containerProps: IContainerWithEtiquettesProps = {
        leftEtiquetteLabel: 'Screening',
        rightEtiquette: {
            label: getParentState(props.screening.stateName),
            backgroundColor: SharedColors.cyanBlue10,
        },
    };

    const [screening, setScreening] = useState<ICommonScreening>(props.screening);

    const publicTrustRequestTypeOptions = PUBLIC_TRUST_REQUEST_TYPE_OPTIONS.map((option) => {
        return {
            ...option,
            disabled:
                option.key === nameof<typeof PublicTrustRequestTypes>('PeriodicReinvestigation'),
        };
    });

    useEffect(() => {
        if (props.screening !== screening) {
            setScreening(props.screening);
        }
    }, [props.screening]);

    function onDropdownChange(
        fieldNames: string[],
        options: IDropdownOption[],
        index?: number,
        isNestedProp = false,
    ): void {
        if (index !== undefined && index < options.length) {
            let updatedScreening;
            if (!isNestedProp) {
                // if fieldname is isPIVRequiredString then it needs to be converted to a boolean value
                const value =
                    fieldNames.indexOf(isPIVRequiredString) === 0
                        ? options[index].key === requiredString
                        : options[index].key;

                updatedScreening = setNestedKeyValueOfCloneObject<ICommonScreening>(
                    screening,
                    value,
                    fieldNames[0],
                );
            } else {
                const value = options[index].key;
                updatedScreening = setNestedKeyValueOfCloneObject<ICommonScreening>(
                    screening,
                    value,
                    ...fieldNames,
                );
            }
            if (updatedScreening) {
                // if we update the public trust agency then reset the public trust type
                setScreening(updatedScreening);
                props.onEditableFieldChange(updatedScreening);
            }
        }
    }

    function getTextForIsPIVRequired(): string {
        return screening.isPIVRequired ? requiredString : 'Not Required';
    }

    function isInEditableScreeningState(screening: ICommonScreening): boolean {
        return (
            getParentState(screening.stateName) === ScreeningParentStateType.Nomination ||
            getParentState(screening.stateName) === ScreeningParentStateType.Preparation
        );
    }

    function onDateInputChange(
        fieldNames: string[],
        newDate?: Date | null,
        isNestedProp = false,
    ): void {
        let updatedScreening;
        if (!isNestedProp) {
            updatedScreening = setNestedKeyValueOfCloneObject<ICommonScreening>(
                screening,
                newDate?.getTime(),
                fieldNames[0],
            );
        } else {
            updatedScreening = setNestedKeyValueOfCloneObject<ICommonScreening>(
                screening,
                newDate?.getTime(),
                ...fieldNames,
            );
        }
        if (updatedScreening) {
            setScreening(updatedScreening);
            props.onEditableFieldChange(updatedScreening);
        }
    }

    return (
        <ContainerWithEtiquettes {...containerProps}>
            <div className={styles.container}>
                <div className={styles.colGroup}>
                    <div className={styles.row}>
                        <div className={styles.keyCell}>Request Type</div>
                        <div className={styles.valueCell}>
                            {props.isDataEditable && isInEditableScreeningState(screening) ? (
                                <Dropdown
                                    key={publicTrustEditableFields.requestType}
                                    options={publicTrustRequestTypeOptions}
                                    onChange={(
                                        event: React.FormEvent<HTMLDivElement>,
                                        option?: IDropdownOption,
                                        index?: number,
                                    ): void => {
                                        onDropdownChange(
                                            [publicTrustEditableFields.requestType],
                                            publicTrustRequestTypeOptions,
                                            index,
                                            true,
                                        );
                                    }}
                                    defaultSelectedKey={
                                        publicTrustRequestTypeOptions.find(
                                            (x) => x.key === screening.requestType,
                                        )?.key
                                    }
                                />
                            ) : (
                                ScreeningRequestTypesLabels[screening.requestType]
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.keyCell}>Request Agency</div>
                        <div className={styles.valueCell}>
                            {props.isDataEditable && isInEditableScreeningState(screening) ? (
                                <Dropdown
                                    key={publicTrustEditableFields.publicTrustAgency}
                                    options={PublicTrustAgenciesOptions}
                                    onChange={(
                                        event: React.FormEvent<HTMLDivElement>,
                                        option?: IDropdownOption,
                                        index?: number,
                                    ): void => {
                                        onDropdownChange(
                                            [publicTrustEditableFields.publicTrustAgency],
                                            PublicTrustAgenciesOptions,
                                            index,
                                            true,
                                        );
                                    }}
                                    defaultSelectedKey={
                                        PublicTrustAgenciesOptions.find(
                                            (x) => x.text === screening.publicTrustAgency,
                                        )?.key
                                    }
                                />
                            ) : screening.publicTrustAgency ? (
                                getAgencyEnumValueFromKey(screening.publicTrustAgency, true)
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.keyCell}>Process Owner</div>
                        <div className={styles.keyCell}>
                            {props.processOwner && (
                                <EmployeeHoverCard
                                    personnelAlias={
                                        props.processOwner.userPrincipalName.split('@')[0]
                                    }>
                                    {props.processOwner.displayName}
                                </EmployeeHoverCard>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.colGroup}>
                    <div className={styles.row}>
                        <div className={styles.keyCell}>Suitability Level</div>
                        <div className={styles.valueCell}>
                            {props.isDataEditable && isInEditableScreeningState(screening) ? (
                                <Dropdown
                                    key={publicTrustEditableFields.suitabilityLevel}
                                    options={Object.keys(SuitabilityLevels).map((level) => ({
                                        key: level,
                                        text:
                                            SuitabilityLevels[
                                                level as keyof typeof SuitabilityLevels
                                            ],
                                    }))}
                                    onChange={(
                                        event: React.FormEvent<HTMLDivElement>,
                                        option?: IDropdownOption,
                                        index?: number,
                                    ): void => {
                                        if (screening.suitabilityLevel) {
                                            onDropdownChange(
                                                [publicTrustEditableFields.suitabilityLevel],
                                                Object.keys(SuitabilityLevels).map((level) => ({
                                                    key: level,
                                                    text:
                                                        SuitabilityLevels[
                                                            level as keyof typeof SuitabilityLevels
                                                        ],
                                                })),
                                                index,
                                            );
                                        }
                                    }}
                                    selectedKey={
                                        screening.suitabilityLevel ??
                                        Object.keys(SuitabilityLevels)[0]
                                    }
                                />
                            ) : (
                                screening.suitabilityLevel &&
                                (SuitabilityLevels[
                                    props.screening
                                        .suitabilityLevel as keyof typeof SuitabilityLevels
                                ] ||
                                    '') // empty string when null for: VA
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.keyCell}>PIV</div>
                        <div className={styles.valueCell}>
                            {props.isDataEditable && isInEditableScreeningState(screening) ? (
                                <Dropdown
                                    key={publicTrustEditableFields.isPIVRequired}
                                    options={TARGET_LEVEL_OPTIONS}
                                    onChange={(
                                        event: React.FormEvent<HTMLDivElement>,
                                        option?: IDropdownOption,
                                        index?: number,
                                    ): void => {
                                        onDropdownChange(
                                            [publicTrustEditableFields.isPIVRequired],
                                            TARGET_LEVEL_OPTIONS,
                                            index,
                                        );
                                    }}
                                    defaultSelectedKey={getTextForIsPIVRequired()}
                                />
                            ) : (
                                getTextForIsPIVRequired()
                            )}
                        </div>
                    </div>
                    {(getParentState(screening.stateName) ===
                        ScreeningParentStateType.Adjudicated ||
                        screening.adjudicatedResult) && (
                        <>
                            <div className={styles.row}>
                                <div className={styles.keyCell}>Adjudicated Decision</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <Dropdown
                                            key={publicTrustEditableFields.adjudicatedResult}
                                            options={ADJUDICATED_OPTIONS_PUBLIC_TRUST}
                                            onChange={(
                                                event: React.FormEvent<HTMLDivElement>,
                                                option?: IDropdownOption,
                                                index?: number,
                                            ): void => {
                                                onDropdownChange(
                                                    [publicTrustEditableFields.adjudicatedResult],
                                                    ADJUDICATED_OPTIONS_PUBLIC_TRUST,
                                                    index,
                                                    true,
                                                );
                                            }}
                                            defaultSelectedKey={
                                                ADJUDICATED_OPTIONS_PUBLIC_TRUST.find(
                                                    (x) => x.text === screening.adjudicatedResult,
                                                )?.key
                                            }
                                        />
                                    ) : (
                                        screening.adjudicatedResult
                                    )}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.keyCell}>Adjudicated Date</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <DatePicker
                                            ariaLabel='Adjudicated Date'
                                            key={publicTrustEditableFields.adjudicatedUtcMillis}
                                            value={
                                                screening?.adjudicatedUtcMillis
                                                    ? new Date(screening.adjudicatedUtcMillis)
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void =>
                                                onDateInputChange(
                                                    [
                                                        publicTrustEditableFields.adjudicatedUtcMillis,
                                                    ],
                                                    newDate,
                                                    true,
                                                )
                                            }
                                        />
                                    ) : (
                                        dateToLocalLongDateFormat(screening.adjudicatedUtcMillis)
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {(getParentState(screening.stateName) === ScreeningParentStateType.Indoc ||
                        screening.indocBriefingUtcMillis > 0 ||
                        screening.indocTrainingUtcMillis > 0) && (
                        <>
                            <div className={styles.row}>
                                <div className={styles.keyCell}>Briefing Date</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <DatePicker
                                            ariaLabel='Indoc Briefing Date'
                                            key={publicTrustEditableFields.indocBriefingUtcMillis}
                                            value={
                                                screening.indocBriefingUtcMillis
                                                    ? new Date(screening.indocBriefingUtcMillis)
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void =>
                                                onDateInputChange(
                                                    [
                                                        publicTrustEditableFields.indocBriefingUtcMillis,
                                                    ],
                                                    newDate,
                                                    true,
                                                )
                                            }
                                        />
                                    ) : (
                                        dateToLocalLongDateFormat(screening.indocBriefingUtcMillis)
                                    )}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.keyCell}>Training Date</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <DatePicker
                                            ariaLabel='Indoc Training Date'
                                            key={publicTrustEditableFields.indocTrainingUtcMillis}
                                            value={
                                                screening.indocTrainingUtcMillis
                                                    ? new Date(screening.indocTrainingUtcMillis)
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void =>
                                                onDateInputChange(
                                                    [
                                                        publicTrustEditableFields.indocTrainingUtcMillis,
                                                    ],
                                                    newDate,
                                                    true,
                                                )
                                            }
                                        />
                                    ) : (
                                        dateToLocalLongDateFormat(screening.indocTrainingUtcMillis)
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </ContainerWithEtiquettes>
    );
}
const styles = mergeStyleSets({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    colGroup: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '48%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '3px',
        paddingBottom: '3px',
        alignItems: 'center',
    },
    keyCell: {
        flexBasis: '50%',
        minWidth: 130,
        fontWeight: FontWeights.semibold,
    },
    valueCell: {
        flexBasis: '50%',
        minWidth: 130,
        maxWidth: 130,
    },
    badge: {
        padding: '2px 7px',
        color: 'rgba(255, 255, 255, 0.867)',
        fontSize: 13,
        borderRadius: 1.5,
        backgroundColor: SharedColors.cyanBlue10,
        selectors: {
            '&:not(:last-child):not(:first-child)': {
                marginLeft: 5,
                marginRight: 5,
            },
        },
    },
});
export default EmployeePublicTrustData;
