import StaffingClient, { IOrganizationAccess } from 'clients/staffing-client';
import { Role } from 'configs/roles';
import { IAuthContext } from 'contexts/auth-context';
import { IUserContext } from 'contexts/user-context';
import { ICacheContext } from 'contexts/cache-context';
import ClientUtils from 'clients/client-utils';
import { OrgPermissionType } from 'components/staffing/staffing-page-types';
import { Dictionary } from 'assets/constants/global-constants';
import {
    IStaffingCloudStatusResponse,
    IStaffingOrganizationResponse,
} from 'clients/staffing-client';
import { ChartItem } from 'components/common/charts/bar-chart';
import {
    CloudStatus,
    getStatusTitle,
    getStatusNames,
    EmployeeBoxBackground,
    getStatusBackgroundColor,
    getStatusBarChartLegendBorderColor,
} from 'components/staffing/staffing-constants';

/**
 * Determine user's organization permissions
 */
export const determineOrgPermissions = async (
    authContext: IAuthContext,
    userContext: IUserContext,
    cacheContext: ICacheContext,
    organizations: IStaffingOrganizationResponse[],
): Promise<Dictionary<OrgPermissionType>> => {
    const fetchOrgPermissions = async (
        authContext: IAuthContext,
        userContext: IUserContext,
        cacheContext: ICacheContext,
        orgName: string, // both organization.name and organization.id would be ok for this parameter.
    ): Promise<OrgPermissionType> => {
        let hasPermissions: boolean[] = [];
        try {
            hasPermissions = (await ClientUtils.withRetry(() =>
                Promise.all([
                    StaffingClient.checkOrgPermission(
                        authContext,
                        cacheContext,
                        orgName,
                        Role.StaffingOrgRead,
                    ),
                    StaffingClient.checkOrgPermission(
                        authContext,
                        cacheContext,
                        orgName,
                        Role.StaffingOrgEdit,
                    ),
                ]),
            )) || [false, false];
        } catch (error) {
            // No need to log the error because the retry function has already done that
            hasPermissions = [false, false];
        } finally {
            const hasOrgRead =
                authContext.isInRole(Role.StaffingOrgEdit) ||
                authContext.isInRole(Role.StaffingOrgRead) ||
                authContext.isInRole(Role.StaffingAdminEdit) ||
                authContext.isInRole(Role.StaffingAdminRead);
            const hasOrgEdit =
                authContext.isInRole(Role.StaffingOrgEdit) ||
                authContext.isInRole(Role.StaffingAdminEdit);
            return {
                [Role.StaffingOrgRead]: hasPermissions[0] && hasOrgRead,
                [Role.StaffingOrgEdit]: hasPermissions[1] && hasOrgEdit,
            };
        }
    };

    const orgPermissionsVar: Dictionary<OrgPermissionType> = {};
    await Promise.all(
        organizations.map(
            async (org): Promise<void> => {
                orgPermissionsVar[org.id] = await fetchOrgPermissions(
                    authContext,
                    userContext,
                    cacheContext,
                    org.name, // Both organization.name and organization.id would be ok for this parameter.
                );
            },
        ),
    );

    return orgPermissionsVar;
};

export const convertToOrgPermissionType = (
    organizationAccessDetails: IOrganizationAccess[],
): Dictionary<OrgPermissionType> => {
    const orgPermissions: Dictionary<OrgPermissionType> = {};
    organizationAccessDetails.forEach((item) => {
        orgPermissions[item.organization.id] = {
            [Role.StaffingOrgRead]: item.roles.includes(Role.StaffingOrgRead),
            [Role.StaffingOrgEdit]: item.roles.includes(Role.StaffingOrgEdit),
        };
    });

    return orgPermissions;
};

export const generateStatusBarChartProps = (
    allocationsCloudStatuses: IStaffingCloudStatusResponse[][],
    calculationMode: 'forStatusTables' | 'forAllocationMap',
): ChartItem[] => {
    const chartData = {} as { [key: string]: ChartItem };

    chartData['Employees'] = {
        xAxis: 'Employees',
        yAxis: 0, // The value will be calculated below. See (*) below.
        color: EmployeeBoxBackground,
    };

    getStatusNames().forEach((status) => {
        chartData[status] = {
            xAxis: getStatusTitle(status),
            yAxis: 0,
            color: getStatusBackgroundColor(status),
            borderColor: getStatusBarChartLegendBorderColor(status),
        };
    });

    const employeeCount = allocationsCloudStatuses.length; // Calculating employee count as promised. See (*) above.
    allocationsCloudStatuses.forEach((cloudStatuses) => {
        let cloudStatus: IStaffingCloudStatusResponse | undefined;
        switch (calculationMode) {
            case 'forAllocationMap':
                cloudStatus = cloudStatuses.find(
                    (s) => s.name === CloudStatus.EX || s.name === CloudStatus.RX,
                );
                break;
            case 'forStatusTables':
                cloudStatus = cloudStatuses[0];
                break;
        }
        if (cloudStatus) {
            const { status } = cloudStatus;
            if (typeof (chartData[status] ?? {}).yAxis === 'number') {
                (chartData[status] ?? {}).yAxis++;
            }
        }
    });

    (chartData['Employees'] ?? {}).yAxis = employeeCount;

    return Object.values(chartData);
};

interface IOrganizationInfo {
    accessibleOrgNames: string[];
    accessibleOrgs: IStaffingOrganizationResponse[];
    orgPermissions: Dictionary<OrgPermissionType>;
}

export const determineOrgsInfo = async (authContext: IAuthContext): Promise<IOrganizationInfo> => {
    const orgAccessResponse = await StaffingClient.getOrganizationAccessDetailsForUser(authContext);
    const orgPermissions = convertToOrgPermissionType(orgAccessResponse);
    const accessibleOrgs: IStaffingOrganizationResponse[] = orgAccessResponse.map((o) => {
        return {
            id: o.organization.id,
            name: o.organization.name,
            label: o.organization.label,
            partitionKey: o.organization.partitionKey,
        } as IStaffingOrganizationResponse;
    });
    const accessibleOrgNames = accessibleOrgs.map((org) => org.name);
    return { accessibleOrgs, accessibleOrgNames, orgPermissions };
};
