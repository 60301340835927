import { CommunicationColors } from '@fluentui/theme';
import { Dictionary } from 'assets/constants/global-constants';
import { ChartItem } from 'components/common/charts/bar-chart';
import { ScreeningPageNames } from 'components/common/constants';
import { IDropdownOption } from '@fluentui/react';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { IScreening } from 'components/screening/us-gov/IScreening';

export const metricsTitle = 'Metrics';
export const recordsTitle = 'Records';

// Matches parent state name definitions in the personnel-screening-service (ScreeningParentStateNames.cs)
export enum ScreeningParentStateType {
    Nomination = 'Nomination',
    Preparation = 'Preparation',
    Submitted = 'Submitted',
    Adjudicated = 'Adjudicated',
    Indoc = 'Indoc',
    Withdrawn = 'Withdrawn',
    Completed = 'Completed',
}

// Matches state name definitions in the personnel-screening-service (ScreeningStateLabels.cs)
export enum ScreeningStateType {
    // Nominated sub-states
    Nomination = 'Nomination',
    Nominated = 'Nominated',
    ContractOwnerApproved = 'ContractOwnerApproved',
    ContractOwnerDeny = 'ContractOwnerDeny',
    NomineeNotAccept = 'NomineeNotAccept',

    // Preparation sub-states
    Preparation = 'Preparation',
    // old sub states - we need to keep track of them
    PreparationDocumentReceived = 'PreparationDocumentReceived', // old substate of ReceivedFromNominee
    PreparationSentToUSG = 'PreparationSentToUSG', // old substate replaced with ApprovalRequestedFromUSG
    ReceivedFromNominee = 'ReceivedFromNominee', // old substate of WithNST
    // new Substates
    WithNominee = 'WithNominee',
    WithNST = 'WithNST',
    ApprovalRequestedFromUSG = 'ApprovalRequestedFromUSG',
    RemovedFromProcessing = 'RemovedFromProcessing',

    // Submitted sub-states
    Submitted = 'Submitted',
    SubmittedToUSG = 'SubmittedToUSG',
    ReceivedFromUSG = 'ReceivedFromUSG',
    AwaitingPolyResult = 'AwaitingPolyResult', // old substate of AwaitingPoly but keeping incase due to data
    AwaitingPoly = 'AwaitingPoly',
    AwaitingMedical = 'AwaitingMedical',
    AwaitingAdjudication = 'AwaitingAdjudication',

    // Adjudicated sub-states
    Adjudicated = 'Adjudicated',
    Interim = 'Interim',
    Final = 'Final',
    Denied = 'Denied',

    // Indoc sub-states
    Indoc = 'Indoc',
    IndocAwaitingNominee = 'IndocAwaitingNominee',
    IndocAwaitingUSG = 'IndocAwaitingUSG',
    IndocAwaitingNST = 'IndocAwaitingNST',
    Withdrawn = 'Withdrawn',
    Completed = 'Completed',
}

// Convenient way to get the list of children states for a given parent state us gov
export const ScreeningParentStateToChildrenMap: {
    [key in ScreeningParentStateType]: ScreeningStateType[];
} = {
    Nomination: [ScreeningStateType.Nomination, ScreeningStateType.ContractOwnerApproved],
    Preparation: [
        ScreeningStateType.Preparation,
        ScreeningStateType.WithNominee,
        ScreeningStateType.WithNST,
        ScreeningStateType.ApprovalRequestedFromUSG,
        ScreeningStateType.RemovedFromProcessing,
    ],
    Submitted: [
        ScreeningStateType.Submitted,
        ScreeningStateType.SubmittedToUSG,
        ScreeningStateType.ReceivedFromUSG,
        ScreeningStateType.AwaitingPoly,
        ScreeningStateType.AwaitingMedical,
        ScreeningStateType.AwaitingAdjudication,
    ],
    Adjudicated: [ScreeningStateType.Final, ScreeningStateType.Interim],
    Indoc: [
        ScreeningStateType.Indoc,
        ScreeningStateType.IndocAwaitingUSG,
        ScreeningStateType.IndocAwaitingNominee,
        ScreeningStateType.IndocAwaitingNST,
    ],
    Completed: [],
    Withdrawn: [],
};

// Human readable labels for sub states US GOV and Public Trust
export const ScreeningStateLabels: Dictionary<string> = {
    Nomination: 'Nomination',
    // TODO: This is a temporary fix for issue with duplicate state machine entries
    // https://msazure.visualstudio.com/Microsoft%20Personnel/_workitems/edit/13002319
    Nominated: 'Nomination', // us gov specific
    WaitingForContractOwnerApproval: 'Waiting for Contract Owner Approval', // public trust specific
    ContractOwnerApproved: 'Waiting for Nominee Approval', // us gov specific
    WaitingForNomineeApproval: 'Waiting for Nominee Approval', // public trust specific
    ContractOwnerDeny: 'Contract Owner Denied', // us gov specific
    NomineeNotAccept: 'Nominee Declined', // us gov specific
    NomineeDeclined: 'Nominee Declined', // public trust specific
    ContractOwnerDenied: 'Contract Owner Denied', // public trust specific
    // preparation
    Preparation: 'Preparation',
    PreparationDocumentReceived: 'Removed from Processing', // old mapping for ReceivedFromNominee
    PreparationSentToUSG: 'Approval Requested from USG', // old mapping for ApprovalRequestedFromUSG
    RemovedFromProcessing: 'Removed from Processing',
    ReceivedFromNominee: 'Received from Nominee', // old mapping for WithNST
    WithNominee: 'With Nominee',
    WithNST: 'With NST',
    ApprovalRequestedFromUSG: 'Approval Requested from USG',
    // Prepartion specific Public trust
    PreparationWithNominee: 'With Nominee',
    PreparationWithNST: 'With NST',
    NominationSentToReseller: 'Nomination sent to Reseller',
    // submitted
    Submitted: 'Submitted',
    SubmittedToUSG: 'Submitted to USG',
    ReceivedFromUSG: 'Received from USG',
    AwaitingPolyResults: 'Awaiting Poly', // old mapping for AwaitingPoly
    AwaitingPoly: 'Awaiting Poly',
    AwaitingMedical: 'Awaiting Medical',
    AwaitingAdjudication: 'Awaiting Adjudication', // us gov specific
    // Submitted sepecifc public trust:
    ResellerSentNominationToUSG: 'Reseller sent Nomination to USG',
    // Adjudicated
    Adjudicated: 'Adjudication', // us gov specific?
    AdjudicatedDenied: 'Adjudication Denied',
    AdjudicatedInterim: 'Interim',
    AdjudicatedFinal: 'Final',
    // indoc
    Indoc: 'Indoc',
    IndocAwaitingNominee: 'Awaiting Nominee',
    IndocAwaitingUSG: 'Awaiting USG',
    IndocAwaitingNST: 'Awaiting NST',
    // done
    Withdrawn: 'Withdrawn',
    Completed: 'Completed',
    // for Adjudicated us gov
    Final: 'Final',
    Interim: 'Interim',
    Denied: 'Denied',
};

// Human readable labels for sub states
export const PublicTrustScreeningStateLabels: Dictionary<string> = {
    Nomination: 'Nomination',
    WaitingForContractOwnerApproval: 'Waiting for Contract Owner Approval',
    WaitingForNomineeApproval: 'Waiting for Nominee Approval',
    ContractOwnerDenied: 'Contract Owner Denied',
    NomineeDeclined: 'Nominee Declined',
    Preparation: 'Preparation',
    PreparationWithNominee: 'With Nominee',
    PreparationWithNST: 'With NST',
    ApprovalRequestedFromUSG: 'Approval Requested From USG',
    RemovedFromProcessing: 'Removed from Processing',
    NominationSentToReseller: 'Nomination Sent To Reseller',

    Submitted: 'Submitted',
    SubmittedToUSG: 'Submitted to USG',
    ReceivedFromUSG: 'Received from USG',
    AwaitingPoly: 'Awaiting Poly', // not needed for public trust
    AwaitingMedical: 'Awaiting Medical', // not needed for public trust
    AwaitingAdjudication: 'Awaiting Adjudication',
    ResellerSentNominationToUSG: 'Reseller Sent Nomination To USG',

    Adjudicated: 'Adjudication',
    AdjudicatedFinal: 'Final',
    AdjudicatedInterim: 'Interim',
    AdjudicatedDenied: 'Adjudication Denied',

    Indoc: 'Indoc',
    IndocAwaitingNominee: 'Awaiting Nominee',
    IndocAwaitingUSG: 'Awaiting USG',
    IndocAwaitingNST: 'Awaiting NST',
    Withdrawn: 'Withdrawn',
    Completed: 'Completed',
};

export enum UsGovScreeningUserType {
    None = 'None',
    Nominee = 'Nominee',
    Nominator = 'Nominator',
    ContractOwner = 'ContractOwner',
    ContractAdmin = 'ContractAdmin',
    Manager = 'Manager',
    NST = 'NST',
    ProcessOwner = 'ProcessOwner',
    AdminService = 'AdminService',
}

export enum ClearanceLevelType {
    S = 'S',
    TS = 'TS',
    SCI = 'SCI',
    SAP = 'SAP',
}

export const CURRENT_LEVEL_OPTIONS: IDropdownOption[] = [
    { key: ClearanceLevelType.S, text: ClearanceLevelType.S, index: 0 },
    { key: ClearanceLevelType.TS, text: ClearanceLevelType.TS, index: 1 },
    { key: ClearanceLevelType.SCI, text: ClearanceLevelType.SCI, index: 2 },
    { key: ClearanceLevelType.SAP, text: ClearanceLevelType.SAP, index: 3 },
];

export enum ScreeningPaths {
    UsGov,
    PublicTrust,
}

export enum ScreeningPathsTitles {
    UsGov = 'US Gov',
    PublicTrust = 'Public Trust',
}

export const pageNameMap = {
    [ScreeningPageNames.Manage]: 'manage',
    [ScreeningPageNames.MyContracts]: 'my-contracts',
    [ScreeningPageNames.MyNominees]: 'my-nominees',
    [ScreeningPageNames.MyOrg]: 'my-org',
};

export interface IEmployeeIdentifier {
    alias?: string;
    lastName?: string;
    firstName?: string;
    middleName?: string;
    l1?: string;
    l2?: string;
    l3?: string;
    l4?: string;
    l5?: string;
}

export interface IMetaData {
    filteredRecords: number;
    completedRecords: number;
    withdrawnRecords: number;
    percentCompletedRecords: string;
}

export const stateLabels: string[] = [
    ScreeningParentStateType.Nomination,
    ScreeningParentStateType.Preparation,
    ScreeningParentStateType.Submitted,
    ScreeningParentStateType.Adjudicated,
    ScreeningParentStateType.Indoc,
];

export const initialMetaData: IMetaData = {
    completedRecords: 0,
    filteredRecords: 0,
    withdrawnRecords: 0,
    percentCompletedRecords: '0%',
};

export const initialBarChart: ChartItem[] = new Array<ChartItem>(stateLabels.length)
    .fill({
        xAxis: '',
        yAxis: 0,
        color: CommunicationColors.primary,
    })
    .map((item: ChartItem, index: number) => {
        return {
            ...item,
            xAxis: stateLabels[index],
        };
    });

export enum AdjudicatedDecision {
    Final = 'Final',
    Interim = 'Interim',
    Denied = 'Denied',
}

export enum AcceptanceChoice {
    UNSET,
    ACCEPT,
    DECLINE,
}
export const clearanceStr = 'clearance';
export const employeeStatusStr = 'employeeStatus';
export const requestTypeStr = 'requestType';

export function getPublicTrustStatusDisplay(publicTrust: ICommonScreening): string {
    return getStatusDisplayByStateName(publicTrust.stateName);
}

export function getStatusDisplayByStateName(stateName: StateName): string {
    if (
        stateName === StateName.RemovedFromProcessing ||
        stateName === StateName.AdjudicatedDenied ||
        stateName === StateName.ContractOwnerDenied ||
        stateName === StateName.NomineeDeclined
    ) {
        return DeclinedPublicTrustLabels[stateName];
    }

    return getParentState(stateName) ?? stateName;
}

export function getStatusText(publicTrust: ICommonScreening, isParentState: boolean): string {
    return getStatusTextByStateName(publicTrust.stateName, isParentState);
}

export function getStatusTextByStateName(stateName: StateName, isParentState: boolean): string {
    if (isParentState) {
        return getParentState(stateName) ?? stateName;
    } else {
        return ScreeningStateLabels[stateName] ?? stateName;
    }
}

export function getSubStatusName(screeningRecord: IScreening): string {
    const stateName = GetUSGovStateName(screeningRecord.parentState, screeningRecord.state);
    return ScreeningStateLabels[stateName] ?? stateName;
}

// right now for public trust can add for more
export function getParentState(stateName: StateName): string {
    switch (stateName) {
        case StateName.Nomination:
        case StateName.WaitingForContractOwnerApproval:
        case StateName.WaitingForNomineeApproval:
        case StateName.NomineeDeclined:
        case StateName.ContractOwnerDenied:
            return ScreeningParentStateType.Nomination;
        case StateName.Preparation:
        case StateName.PreparationWithNominee:
        case StateName.PreparationWithNST:
        case StateName.ApprovalRequestedFromUSG:
        case StateName.NominationSentToReseller:
        case StateName.RemovedFromProcessing:
            return ScreeningParentStateType.Preparation;
        case StateName.Submitted:
        case StateName.SubmittedToUSG:
        case StateName.ReceivedFromUSG:
        case StateName.AwaitingAdjudication:
        case StateName.ResellerSentNominationToUSG:
        case StateName.AwaitingPoly: // us Gov
        case StateName.AwaitingMedical: // us Gov
        case StateName.AwaitingAdjudication: // us Gov
            return ScreeningParentStateType.Submitted;
        case StateName.AdjudicatedInterim:
        case StateName.AdjudicatedFinal:
        case StateName.AdjudicatedDenied:
            return ScreeningParentStateType.Adjudicated;
        case StateName.Indoc:
        case StateName.IndocAwaitingUSG:
        case StateName.IndocAwaitingNominee:
        case StateName.IndocAwaitingNST:
            return ScreeningParentStateType.Indoc;
        case StateName.Completed:
            return ScreeningParentStateType.Completed;
        case StateName.Withdrawn:
            return ScreeningParentStateType.Withdrawn;
        default:
            return stateName;
    }
}

// Currently ICommonScreening state names in future just add more stateNames
export enum StateName {
    // Nomination parent state,
    Nomination = 'Nomination',
    WaitingForContractOwnerApproval = 'WaitingForContractOwnerApproval',
    WaitingForNomineeApproval = 'WaitingForNomineeApproval',
    // Preparation parent state,
    Preparation = 'Preparation',
    PreparationWithNominee = 'PreparationWithNominee',
    PreparationWithNST = 'PreparationWithNST',
    ApprovalRequestedFromUSG = 'ApprovalRequestedFromUSG',
    RemovedFromProcessing = 'RemovedFromProcessing',
    NominationSentToReseller = 'NominationSentToReseller',
    // Submitted parent state
    Submitted = 'Submitted',
    SubmittedToUSG = 'SubmittedToUSG',
    ReceivedFromUSG = 'ReceivedFromUSG',
    AwaitingAdjudication = 'AwaitingAdjudication',
    ResellerSentNominationToUSG = 'ResellerSentNominationToUSG',
    AwaitingPoly = 'AwaitingPoly', // US GOV only
    AwaitingMedical = 'AwaitingMedical', // US GOV only
    // Adjudicated parent state
    Adjudication = 'Adjudication', // US Gov
    AdjudicatedInterim = 'AdjudicatedInterim',
    AdjudicatedFinal = 'AdjudicatedFinal',
    // Indoc parent state
    Indoc = 'Indoc',
    IndocAwaitingUSG = 'IndocAwaitingUSG',
    IndocAwaitingNominee = 'IndocAwaitingNominee',
    IndocAwaitingNST = 'IndocAwaitingNST',
    // Completed / Withdrawn
    Completed = 'Completed',
    Withdrawn = 'Withdrawn',
    // Declined Statuses
    NomineeDeclined = 'NomineeDeclined',
    ContractOwnerDenied = 'ContractOwnerDenied',
    AdjudicatedDenied = 'AdjudicatedDenied',

    None = 'none', // temp
}

export function GetUSGovStateName(parentState: string, state: string): StateName {
    switch (parentState) {
        case ScreeningParentStateType.Nomination:
            switch (state) {
                case ScreeningStateType.ContractOwnerDeny:
                    return StateName.ContractOwnerDenied; // ContractOwnerDeny
                case ScreeningStateType.NomineeNotAccept:
                    return StateName.NomineeDeclined; // NomineeNotAccept
                case ScreeningStateType.ContractOwnerApproved:
                    return StateName.WaitingForNomineeApproval;
                case ScreeningStateType.Nominated:
                case ScreeningStateType.Nomination:
                    return StateName.WaitingForContractOwnerApproval; // ContractOwnerApproved
            }
        case ScreeningParentStateType.Preparation:
            switch (state) {
                case ScreeningStateType.WithNominee:
                    return StateName.PreparationWithNominee; //ScreeningStateType.WithNominee;
                case ScreeningStateType.PreparationDocumentReceived:
                case ScreeningStateType.ReceivedFromNominee:
                case ScreeningStateType.WithNST:
                    return StateName.PreparationWithNST; // ScreeningStateType.WithNST;
                case ScreeningStateType.PreparationSentToUSG:
                case ScreeningStateType.ApprovalRequestedFromUSG:
                    return StateName.ApprovalRequestedFromUSG;
                case ScreeningStateType.RemovedFromProcessing:
                    return StateName.RemovedFromProcessing;
                case ScreeningStateType.Preparation:
                    return StateName.Preparation;
            }
        case ScreeningParentStateType.Submitted:
            switch (state) {
                case ScreeningStateType.SubmittedToUSG:
                    return StateName.SubmittedToUSG;
                case ScreeningStateType.ReceivedFromUSG:
                    return StateName.ReceivedFromUSG;
                // due to renaming we need to still track the old AwaitingPolyResult
                case ScreeningStateType.AwaitingPolyResult:
                case ScreeningStateType.AwaitingPoly:
                    return StateName.AwaitingPoly;
                case ScreeningStateType.AwaitingMedical:
                    return StateName.AwaitingMedical;
                case ScreeningStateType.AwaitingAdjudication:
                    return StateName.AwaitingAdjudication;
                case ScreeningStateType.Submitted:
                    return StateName.Submitted;
            }
        // adjudicated?
        case ScreeningParentStateType.Adjudicated:
            switch (state) {
                case ScreeningStateType.Final:
                    return StateName.AdjudicatedFinal;
                case ScreeningStateType.Interim:
                    return StateName.AdjudicatedInterim;
                case ScreeningStateType.Denied:
                    return StateName.AdjudicatedDenied;
            }
        case ScreeningParentStateType.Indoc:
            switch (state) {
                case ScreeningStateType.IndocAwaitingNominee:
                    return StateName.IndocAwaitingNominee;
                case ScreeningStateType.IndocAwaitingNST:
                    return StateName.IndocAwaitingNST;
                case ScreeningStateType.IndocAwaitingUSG:
                    return StateName.IndocAwaitingUSG;
                case ScreeningStateType.Indoc:
                    return StateName.Indoc;
            }
        case ScreeningParentStateType.Completed:
            return StateName.Completed;
        case ScreeningParentStateType.Withdrawn:
            return StateName.Withdrawn;
        default:
            return StateName.None;
    }
}
// convert back to us gov string
export function GetUSGovStatusString(state: StateName): string {
    switch (state) {
        case StateName.ContractOwnerDenied:
            return ScreeningStateType.ContractOwnerDeny; // ContractOwnerDeny
        case StateName.NomineeDeclined:
            return ScreeningStateType.NomineeNotAccept; // NomineeNotAccept
        case StateName.WaitingForNomineeApproval:
            return ScreeningStateType.ContractOwnerApproved;
        case StateName.WaitingForContractOwnerApproval:
            return ScreeningStateType.Nomination; // ContractOwnerApproved
        case StateName.PreparationWithNominee:
            return ScreeningStateType.WithNominee; //ScreeningStateType.WithNominee;:
        case StateName.PreparationWithNST:
            return ScreeningStateType.WithNST;
        case StateName.ApprovalRequestedFromUSG:
            return ScreeningStateType.ApprovalRequestedFromUSG;
        case StateName.RemovedFromProcessing:
            return ScreeningStateType.RemovedFromProcessing;
        case StateName.Preparation:
            return ScreeningStateType.Preparation;
        case StateName.SubmittedToUSG:
            return ScreeningStateType.SubmittedToUSG;
        case StateName.ReceivedFromUSG:
            return ScreeningStateType.ReceivedFromUSG;
        case StateName.AwaitingPoly:
            return ScreeningStateType.AwaitingPoly;
        case StateName.AwaitingMedical:
            return ScreeningStateType.AwaitingMedical;
        case StateName.AwaitingAdjudication:
            return ScreeningStateType.AwaitingAdjudication;
        case StateName.Submitted:
            return ScreeningStateType.Submitted;
        case StateName.AdjudicatedFinal:
            return ScreeningStateType.Final;
        case StateName.AdjudicatedInterim:
            return ScreeningStateType.Interim;
        case StateName.AdjudicatedDenied:
            return ScreeningStateType.Denied;
        case StateName.Adjudication:
            return ScreeningStateType.Adjudicated;
        case StateName.IndocAwaitingNominee:
            return ScreeningStateType.IndocAwaitingNominee;
        case StateName.IndocAwaitingNST:
            return ScreeningStateType.IndocAwaitingNST;
        case StateName.IndocAwaitingUSG:
            return ScreeningStateType.IndocAwaitingUSG;
        case StateName.Indoc:
            return ScreeningStateType.Indoc;
        case StateName.Completed:
            return ScreeningStateType.Completed;
        case StateName.Withdrawn:
            return ScreeningStateType.Withdrawn;
        default:
            return state;
    }
}

export enum DeclinedPublicTrustLabels {
    // Declined Statuses
    NomineeDeclined = 'NOMINEE DECLINED',
    ContractOwnerDenied = 'CONTRACT OWNER DENIED',
    AdjudicatedDenied = 'ADJUDICATED DENIED',
    RemovedFromProcessing = 'REMOVED FROM PROCESSING',
}

// Convenient way to get the list of children states for a given parent state Public trust
export const PublicTrustParentStateToChildrenMap: {
    [key in ScreeningParentStateType]: StateName[];
} = {
    Nomination: [
        StateName.Nomination,
        StateName.WaitingForContractOwnerApproval,
        StateName.WaitingForNomineeApproval,
    ],
    Preparation: [
        StateName.Preparation,
        StateName.PreparationWithNominee,
        StateName.PreparationWithNST,
        StateName.ApprovalRequestedFromUSG,
        StateName.RemovedFromProcessing,
        StateName.NominationSentToReseller,
    ],
    Submitted: [
        StateName.Submitted,
        StateName.SubmittedToUSG,
        StateName.ReceivedFromUSG,
        StateName.AwaitingAdjudication,
        StateName.ResellerSentNominationToUSG,
    ],
    Adjudicated: [
        StateName.AdjudicatedFinal,
        StateName.AdjudicatedInterim,
        StateName.AdjudicatedDenied,
    ],
    Indoc: [
        StateName.Indoc,
        StateName.IndocAwaitingUSG,
        StateName.IndocAwaitingNominee,
        StateName.IndocAwaitingNST,
    ],
    Completed: [],
    Withdrawn: [],
};

// Convenient way to get the list of children states for a given parent state US GOV
export const USGovParentStateToChildrenMap: {
    [key in ScreeningParentStateType]: StateName[];
} = {
    Nomination: [StateName.WaitingForContractOwnerApproval, StateName.WaitingForNomineeApproval],
    Preparation: [
        StateName.Preparation,
        StateName.PreparationWithNominee,
        StateName.PreparationWithNST,
        StateName.ApprovalRequestedFromUSG,
        StateName.RemovedFromProcessing,
    ],
    Submitted: [
        StateName.Submitted,
        StateName.SubmittedToUSG,
        StateName.ReceivedFromUSG,
        StateName.AwaitingPoly,
        StateName.AwaitingMedical,
        StateName.AwaitingAdjudication,
    ],
    Adjudicated: [StateName.AdjudicatedFinal, StateName.AdjudicatedInterim],
    Indoc: [
        StateName.Indoc,
        StateName.IndocAwaitingUSG,
        StateName.IndocAwaitingNominee,
        StateName.IndocAwaitingNST,
    ],
    Completed: [],
    Withdrawn: [],
};

export enum DeclinedStatusOptions {
    RemovedFromProcessing = 'Removed From Processing',
    NomineeDeclined = 'Nominee Declined',
    ContractOwnerDeny = 'Contract Owner Denied',
    AdjudicatedDenied = 'Adjudicated Denied',
}

export enum ScreeningRequestTypes {
    Initial = 'Initial',
    Reciprocity = 'Reciprocity',
    PeriodicReinvestigation = 'PeriodicReinvestigation',
    ContinuousEvaluationContinuousVetting = 'ContinuousEvaluationContinuousVetting',
    Reinstatement = 'Reinstatement',
    Crossover = 'Crossover', // US GOV Only
    ContractChangeOrAdd = 'ContractChangeOrAdd',
    RequestForAction = 'RequestForAction',
}

export enum ScreeningRequestTypesLabels {
    Initial = 'Initial',
    ContinuousEvaluationContinuousVetting = 'CE/CV',
    ContractChangeOrAdd = 'Contract change/add',
    Crossover = 'Crossover', // US GOV Only
    PeriodicReinvestigation = 'Periodic Reinvestigation',
    Reciprocity = 'Reciprocity',
    Reinstatement = 'Reinstatement',
    RequestForAction = 'Request for Action (RFA)',
}
// this is a default value when the person doesn't report to anyone (CEO) or if there is no hierarchy level at certain levels
// ie lvl 5 person will not have a level 5-7 hierarchy lvl
export const defaultNoReportToPersonnelId = -1;

export enum ScreeningTargetLevelLabel {
    Required = 'Required',
    NotRequired = 'Not Required',
}

export const TARGET_LEVEL_OPTIONS: IDropdownOption[] = [
    {
        key: ScreeningTargetLevelLabel.Required,
        text: ScreeningTargetLevelLabel.Required,
        index: 0,
    },
    {
        key: ScreeningTargetLevelLabel.NotRequired,
        text: ScreeningTargetLevelLabel.NotRequired,
        index: 1,
    },
];

export const DECLINED_SCREENING_STATES = [
    StateName.RemovedFromProcessing,
    StateName.AdjudicatedDenied,
    StateName.ContractOwnerDenied,
    StateName.NomineeDeclined,
];

export enum EmploymentType {
    FteKey = 'A',
    PrehireKey = 'P',
}
