import React, {
    FormEvent,
    MutableRefObject,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Modal from 'components/common/modal';
import { IUserContext, UserContext } from 'contexts/user-context';
import { AuthContext } from 'contexts/auth-context';
import { getDate } from 'utils/time-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import {
    DefaultButton,
    Dropdown,
    IDropdownOption,
    Label,
    DatePicker,
    IChoiceGroupOption,
    mergeStyleSets,
} from '@fluentui/react';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import {
    CloudReadinessReport,
    CloudReadinessReportType,
    CustomerReportType,
} from 'clients/reports-client';
import useChoiceGroup from 'components/common/use-input/use-choice-group';
import { downloadCloudReadinessReport, downloadReport } from 'utils/internal-reporting-utils';
import ScreeningAttentionMessage from 'components/screening/common/components/screening-attention-message';
import Spacer from 'components/common/spacer';
import useHasMyOrgAccess from 'components/screening/common/use-has-my-org-access';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';

interface IScreeningReportsModalProps {
    isOpen: boolean;
    close: () => void;
}

export default function ScreeningReportsModal(props: IScreeningReportsModalProps): JSX.Element {
    const userContext = useContext(UserContext) as IUserContext | null;
    const authContext = useContext(AuthContext);

    const cloudReadinessReportDownload = useFeatureFlag(
        FeatureFlagKeys.cloudReadinessReportDownload,
    );

    const [reportDate, setReportDate] = useState<Date | null | undefined>(getDate().Today());
    const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
    const [reportTypeDropdownOptions, setReportTypeDropdownOptions] = useState<IDropdownOption[]>();
    const [selectedReportType, setSelectedReportType] = useState<IDropdownOption>();
    const [errorMessage, setErrorMessage] = useState<undefined | string>();
    const [cloudReadinessChoices, setCloudReadinessChoices] = useState<IChoiceGroupOption[]>([]);
    const [isContractOwner, setIsContractOwner] = useState<boolean>(false);
    const downloadLink: MutableRefObject<HTMLAnchorElement | null> = useRef(null);

    const { hasMyOrgAccess, hasMyOrgAccessIsReady } = useHasMyOrgAccess();

    const shouldShowMyOrg = useMemo(
        () =>
            cloudReadinessReportDownload.enabled &&
            selectedReportType?.data === CloudReadinessReportType.CloudReadiness &&
            hasMyOrgAccessIsReady &&
            hasMyOrgAccess,
        [
            hasMyOrgAccess,
            hasMyOrgAccessIsReady,
            selectedReportType?.data,
            cloudReadinessReportDownload.enabled,
        ],
    );

    const shouldShowMyContracts = useMemo(
        () =>
            cloudReadinessReportDownload.enabled &&
            selectedReportType?.data === CloudReadinessReportType.CloudReadiness &&
            isContractOwner,
        [isContractOwner, selectedReportType?.data, cloudReadinessReportDownload.enabled],
    );

    useEffect(() => {
        async function determineIsContractOwner(): Promise<void> {
            if (!userContext) return;
            try {
                const response = await UsGovScreeningClient.getContractsByOwnerId(
                    authContext,
                    userContext?.employeeRecord.id,
                    undefined,
                );
                if (response.results.length > 0) {
                    setIsContractOwner(true);
                }
            } catch (e) {
                console.error('Cannot determine whether user is a contract owner:', e);
            }
        }
        determineIsContractOwner();
    }, [userContext, authContext]);

    useEffect(() => {
        if (!userContext || !cloudReadinessReportDownload.enabled) {
            return;
        }

        const cloudReadinessChoicesVar: IChoiceGroupOption[] = [];

        if (shouldShowMyOrg) {
            cloudReadinessChoicesVar.push({
                ...CloudReadinessReport['MyOrgCloudReadiness'], // The string index is strongly typed and is safe to use.
            });
        }

        if (shouldShowMyContracts) {
            cloudReadinessChoicesVar.push({
                ...CloudReadinessReport['MyContractsCloudReadiness'], // The string index is strongly typed and is safe to use.
            });
        }

        cloudReadinessChoicesVar.push(CloudReadinessReport['MyCloudReadiness']); // The string index is strongly typed and is safe to use.

        setCloudReadinessChoices(cloudReadinessChoicesVar);
    }, [userContext, cloudReadinessReportDownload.enabled, shouldShowMyOrg, shouldShowMyContracts]);

    const canShowScreeningAttentionMsg = useMemo(
        () =>
            (shouldShowMyOrg || shouldShowMyContracts) &&
            cloudReadinessReportDownload.enabled &&
            selectedReportType?.data === CloudReadinessReportType.CloudReadiness,
        [
            shouldShowMyOrg,
            shouldShowMyContracts,
            selectedReportType,
            cloudReadinessReportDownload.enabled,
        ],
    );

    const shouldShowScreeningAttentionMsg = useMemo(
        () =>
            canShowScreeningAttentionMsg &&
            selectedReportType?.data === CloudReadinessReportType.CloudReadiness,
        [canShowScreeningAttentionMsg, selectedReportType?.data],
    );

    const {
        value: selectedCloudReadiness,
        theElement: CloudReadinessTypeChoiceGroup,
        initialize: initSelectedCloudReadinessReport,
    } = useChoiceGroup({
        label: 'Select data type',
        options: cloudReadinessChoices,
        horizontal: true,
        shouldRender:
            cloudReadinessReportDownload.enabled &&
            selectedReportType?.data === CloudReadinessReportType.CloudReadiness,
    });

    const clearAndClose = useCallback((): void => {
        setErrorMessage(undefined);
        setIsDownloadingReport(false);
        props.close();
        initSelectedCloudReadinessReport();
    }, [initSelectedCloudReadinessReport, props]);

    useEffect(() => {
        if (selectedReportType?.data !== CloudReadinessReportType.CloudReadiness) {
            initSelectedCloudReadinessReport();
        }
    }, [selectedReportType, initSelectedCloudReadinessReport]);

    const onSubmit = useCallback(async (): Promise<void> => {
        setIsDownloadingReport(true);
        try {
            if (selectedReportType?.data === CloudReadinessReportType.CloudReadiness) {
                if (selectedCloudReadiness) {
                    await downloadCloudReadinessReport(
                        authContext,
                        selectedCloudReadiness,
                        downloadLink?.current,
                    );
                }
            } else {
                await downloadReport(
                    authContext,
                    selectedReportType?.data ?? CustomerReportType.Aggregate,
                    downloadLink?.current,
                    reportDate,
                );
            }
            clearAndClose();
        } catch (e) {
            setErrorMessage('Error occurred. Could not download report.');
            setIsDownloadingReport(false);
        }
    }, [authContext, selectedReportType, selectedCloudReadiness, reportDate, clearAndClose]);

    const shouldDisplayDatePicker =
        selectedReportType?.data !== CustomerReportType.Prehire &&
        selectedReportType?.data !== CloudReadinessReportType.CloudReadiness;

    const shouldDisableSubmit =
        isDownloadingReport ||
        (selectedReportType?.data === CloudReadinessReportType.CloudReadiness &&
            !selectedCloudReadiness);

    useEffect(() => {
        const options: IDropdownOption[] = [];
        // Customer reports
        if (userContext?.hasUsGovScreeningUserType(UsGovScreeningUserType.NST)) {
            for (const type in CustomerReportType) {
                options.push({
                    key: `reportTypeDropdown_${type}`,
                    id: `reportTypeDropdown_${type}`,
                    data: type,
                    text: type,
                    itemType: 0,
                });
            }
        }
        // Cloud readiness reports
        if (cloudReadinessReportDownload.enabled) {
            Object.values(CloudReadinessReportType).forEach((type) => {
                options.push({
                    key: `reportTypeDropdown_${type}`,
                    id: `reportTypeDropdown_${type}`,
                    data: type,
                    text: type,
                    itemType: 0,
                });
            });
        }
        setReportTypeDropdownOptions(options);
    }, [userContext, cloudReadinessReportDownload.enabled]);

    useEffect(() => {
        // Every time screening download report modal opens, select the first option from dropdown
        if (props.isOpen && reportTypeDropdownOptions && reportTypeDropdownOptions.length > 0) {
            setSelectedReportType(reportTypeDropdownOptions[0]);
        }
    }, [props.isOpen, reportTypeDropdownOptions]);

    return (
        <Modal
            fixWidth={true}
            isOpen={props.isOpen}
            onCancel={clearAndClose}
            onDismiss={clearAndClose}
            onSubmit={onSubmit}
            title={`Download report`}
            showProgressIndicator={isDownloadingReport}
            isSubmitButtonDisabled={shouldDisableSubmit}
            errorMsg={errorMessage}
            onResetErrorMsg={(): void => setErrorMessage(undefined)}>
            {reportTypeDropdownOptions && (
                <Dropdown
                    label='Report type'
                    required
                    selectedKey={selectedReportType?.key || reportTypeDropdownOptions[0]?.key}
                    options={reportTypeDropdownOptions}
                    title={selectedReportType?.title}
                    onChange={(
                        event: FormEvent<HTMLDivElement>,
                        option?: IDropdownOption | undefined,
                    ): void => {
                        setSelectedReportType(option);
                    }}
                />
            )}
            {shouldDisplayDatePicker && (
                <>
                    <Label>Report date</Label>
                    <div className={styles.inlineContainer}>
                        <DatePicker
                            ariaLabel='Report Date'
                            key='reportForDate'
                            placeholder='Select a date...'
                            value={reportDate || undefined}
                            onSelectDate={(newDate?: Date | null | undefined): void =>
                                setReportDate(newDate)
                            }
                            highlightCurrentMonth={true}
                            highlightSelectedMonth={true}
                            minDate={new Date(1975, 3, 4)}
                            maxDate={new Date()}
                        />
                        <DefaultButton
                            autoFocus
                            onClick={(): void => setReportDate(getDate().Today())}>
                            Default
                        </DefaultButton>
                    </div>
                </>
            )}
            {cloudReadinessReportDownload.enabled &&
                selectedReportType?.data === CloudReadinessReportType.CloudReadiness && (
                    <CloudReadinessTypeChoiceGroup />
                )}
            {shouldShowScreeningAttentionMsg && (
                <>
                    <Spacer marginTop={10} />
                    <ScreeningAttentionMessage />
                </>
            )}
            <a ref={downloadLink} hidden={true} />
        </Modal>
    );
}

const styles = mergeStyleSets({
    inlineContainer: {
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gridGap: '1rem',
    },
});
