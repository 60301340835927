import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'contexts/auth-context';
import { useEmployeeInfo } from 'components/common/employee/internal-employee-utils';
import { scaHistoryBreadcrumbs } from 'components/sca/sca-breadcrumbs';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import ScaViewEmployeeHistory from 'components/sca/history/sca-view-employee-history';
import { HistoryPageTitle } from 'components/sca/sca-constants';

type MyUrlParamsType = {
    personnelId: string;
};

export default function ScaDisplayEmployeeHistory(): JSX.Element {
    const authContext = useContext(AuthContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const { personnelId } = useParams<MyUrlParamsType>();

    const employee = useEmployeeInfo({ authContext, id: personnelId });

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs(
            scaHistoryBreadcrumbs(HistoryPageTitle.EmployeeHistory, employee),
        );
    }, [employee]);

    return (
        <ScaViewEmployeeHistory
            employee={employee}
            hasTabs={{ employeeHistory: true }}
            shouldHideReviewComments={false}
            shouldHideEmployeeSearchBox={false}
        />
    );
}
