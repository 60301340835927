import React from 'react';
import { mergeStyleSets, PrimaryButton, FontWeights, FontSizes, getTheme } from '@fluentui/react';

import { SharedColors } from '@fluentui/theme';
import { VerticalNotches } from 'components/screening/us-gov/candidates/nomination/screening-status-vertical-notches';
import {
    getParentState,
    ScreeningParentStateType,
    ScreeningPaths,
} from 'components/screening/common/common-constants';
import { transformStepIndicatorDataTimes, StepIndicatorData } from 'types/screening-step';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';

export interface ScreeningOverviewModalProps {
    screening: ICommonScreening;
    screeningPaths: ScreeningPaths;
    onClose: () => void;
}

export default function ScreeningOverviewModal(props: ScreeningOverviewModalProps): JSX.Element {
    const notchesHeight = 700;
    const notchCircleRadius = 16;
    const notchColor = getTheme().palette.themePrimary;
    const isUSGov = props.screeningPaths === ScreeningPaths.UsGov;
    const screeningState =
        getParentState(props.screening?.stateName) ||
        ScreeningParentStateType.Preparation.toUpperCase();
    const screeningStepDataDisplay = transformStepIndicatorDataTimes(
        StepIndicatorData,
        screeningState,
    );
    const path = isUSGov ? 'us-gov' : 'public-trust';
    const url = `/screening/${path}/my-screenings`;
    const type = isUSGov ? 'clearance' : 'Public Trust';
    const submittedSpecialText = isUSGov ? 'polygraph' : 'training';
    const adjudicatedSpecialText = isUSGov
        ? 'an “Eyes Only Package”'
        : 'a package from the government';
    const indocSpecialText = isUSGov
        ? 'clearance level. NOTE: Most indoctrinations are done by the government customer at their site and there may be some delay time between a favorable adjudication and the indoctrination. For collateral clearance holders (e.g.: Confidential, Secret, and Top Secret) this step consists of a signature on the SF312 and initial training.'
        : 'position of Public Trust.';
    const completedSpecialText = isUSGov ? 'clearance data' : 'Public Trust data';
    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <span>Screening Overview</span>
            </div>

            <div className={styles.body}>
                <div className={styles.notchesContainer}>
                    <VerticalNotches
                        steps={screeningStepDataDisplay}
                        height={notchesHeight}
                        radius={notchCircleRadius}
                        color={notchColor}
                    />
                </div>

                <div className={styles.stepsContainer}>
                    <div className={styles.step}>
                        <div>
                            <h3 className={styles.stepTitle}>Nomination</h3>
                        </div>
                        <div>
                            A candidate is nominated for screening via the Nominate Candidate
                            function located in <a href={url}> Microsoft Personnel.</a> The
                            Microsoft Contract Owner will review and confirm the nomination, Nominee
                            will review and accept, or decline, being screened for {type}.
                        </div>
                    </div>

                    <div className={styles.step}>
                        <div>
                            <h3 className={styles.stepTitle}>Preparation</h3>
                        </div>
                        <div>
                            Nominee submits documents requested by the National Security Operations
                            Center (NSOC) for review and preparation. This can include many
                            iterations of review and revision of documents as well as, in some
                            cases, customer concurrence for the submission. At the end of this
                            phase, all documentation is submitted to the government customer.
                        </div>
                    </div>

                    <div className={styles.step}>
                        <div>
                            <h3 className={styles.stepTitle}>Submitted</h3>
                        </div>
                        <div>
                            Application has been submitted to the government customer. Completion of
                            investigation, any necessary {submittedSpecialText}, and adjudication
                            from the government customer.
                        </div>
                    </div>

                    <div className={styles.step}>
                        <div>
                            <h3 className={styles.stepTitle}>Adjudicated</h3>
                        </div>
                        <div>
                            Response is received from government customer and recorded in Personnel.
                            In negative cases this data may be referred to HR and/or the Nominee may
                            receive {adjudicatedSpecialText} that spell out the next steps available
                            to them.
                        </div>
                    </div>

                    <div className={styles.step}>
                        <div>
                            <h3 className={styles.stepTitle}>Indoc</h3>
                        </div>
                        <div>
                            Candidate is trained, briefed, and indoctrinated into their assigned{' '}
                            {indocSpecialText}
                        </div>
                    </div>

                    <div className={styles.step}>
                        <div>
                            <h3 className={styles.stepTitle}>Completed</h3>
                        </div>
                        <div>
                            The candidate has successfully completed the screening process and their{' '}
                            {completedSpecialText} displays correctly in the government system of
                            record (if applicable) and the Microsoft Personnel Portal.
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.footer}>
                <PrimaryButton
                    className={styles.closeBtn}
                    onClick={() => {
                        props.onClose();
                    }}
                    text='Close'
                    allowDisabledFocus
                />
            </div>
        </div>
    );
}

const styles = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 770,
    },
    header: {
        flex: '1 1 auto',
        borderTop: `4px solid ${SharedColors.cyanBlue10}`,
        display: 'flex',
        fontSize: FontSizes.xLargePlus,
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '25px 30px 10px 30px',
        boxSizing: 'border-box',
    },
    body: {
        padding: '0 30px 30px 30px',
        display: 'flex',
        flexDirection: 'row',
    },
    notchesContainer: {
        marginRight: '10px',
        marginTop: '5px',
    },
    stepsContainer: {
        flexGrow: '1',
    },
    step: {
        marginBottom: '20px',
        maxWidth: 600,
    },
    stepTitle: {
        marginTop: '5px',
        marginBottom: '5px',
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.large,
    },
    footer: {
        backgroundColor: 'rgb(253, 253, 253)',
        padding: '14px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    closeBtn: {
        marginRight: '10px',
    },
});
