import { GroupFilterType, IMyGroup } from 'clients/group-client';
import React, { ReactNode, SetStateAction, createContext, useState } from 'react';
import { SetStateFunc } from 'types/global-types';

type contextStateType = {
    myGroups: IMyGroup[];
    groupFilter: GroupFilterType;
    deleteRow: (groupId: string) => void;
    updateRow: (updateGroup: IMyGroup) => void;
    setMyGroups: SetStateFunc<IMyGroup[]>;
    setGroupFilter: SetStateFunc<GroupFilterType>;
};

export const MyGroupsContext = createContext<contextStateType>(null!);

export interface IMyGroupsProviderProps {
    children: ReactNode;
}

export default function MyGroupsProvider(props: IMyGroupsProviderProps): JSX.Element {
    const [myGroups, setMyGroups] = useState<IMyGroup[]>([]);
    const [groupFilter, setGroupFilter] = useState<GroupFilterType>(GroupFilterType.NO_FILTER);

    const contextValue = {
        myGroups,
        setMyGroups: (value: SetStateAction<IMyGroup[]>): void => {
            setMyGroups(value);
        },
        deleteRow: (groupId: string): void => {
            setMyGroups((currentValue) => currentValue.filter((group) => group.id !== groupId));
        },
        updateRow: (updatedGroup: IMyGroup): void => {
            setMyGroups((currentValue) => {
                const currentCopy = [...currentValue];
                const index = currentCopy.findIndex((g) => g.id === updatedGroup.id);
                currentCopy.splice(index, 1, updatedGroup);
                return currentCopy;
            });
        },
        groupFilter,
        // setGroupFilter doesn't need to check isMounted() because
        // it's not going to be called after any asynchronous operation.
        setGroupFilter,
    };

    return (
        <MyGroupsContext.Provider value={contextValue}>{props.children}</MyGroupsContext.Provider>
    );
}
