import { mergeStyles } from '@fluentui/react';
import { Dictionary } from 'assets/constants/global-constants';
import { GenericForm } from 'clients/forms-client';
import React from 'react';
import { ValueOf } from 'utils/object-utils';
import retailStoreMgr from 'assets/img/CLO22_Retail_004.jpg';
import hybridWorkplace from 'assets/img/HRC20_hybridWorkplace_091.jpg';
import mathuraIndia from 'assets/img/MSC19_indiaMathura_025.jpg';
import womanScanningBadge from 'assets/img/CLO22_TechOffice_015.jpg';
import womanScanningBadgeFurtherAway from 'assets/img/CLO22_TechOffice_018.jpg';
import hybridWheelchair from 'assets/img/HRC20_hybridWorkplace_077.jpg';
import blackWomenLaughing from 'assets/img/MSC21_Getty_home_707435373.jpg';
import walkingHanger from 'assets/img/CLO20_MobileMeeting_002.jpg';
import datacenterWalks from 'assets/img/MDC22_Generator_026.jpg';
import fatherDaughterBreakfast from 'assets/img/MSC21_Getty_remoteWorkplace_1037208650.jpg';
import wheelchairGuyFarAway from 'assets/img/HRC20_hybridWorkplace_040.jpg';
import motherBabyShopping from 'assets/img/MSC22_Getty_UK_Retail_1365935432.jpg';
import decisionMakersCollab from 'assets/img/CLO22_TechOffice_050.jpg';
import inclusiveWorkplace from 'assets/img/MSC21_Getty_inclusiveWorkplace_901228906.jpg';
import matureBusinessman from 'assets/img/MSC21_Getty_officePortrait_862596588.jpg';
import businesswomanWheelchair from 'assets/img/MSC21_Getty_officeMeeting_1210338080.jpg';
import twoColleaguesModern from 'assets/img/MSC21_Getty_officeMeeting_612387410.jpg';
import customerService from 'assets/img/CLO22_TechOffice_060.jpg';
import realPeopleProfessionals from 'assets/img/MSC16_slalom_028.jpg';
import realPeopleOutside from 'assets/img/CLO20b_informal_work_outside_office_001.jpg';
import manWomanCollab from 'assets/img/CLO22_Collaboration_013.jpg';
import businessTeamCollab from 'assets/img/Ofc17_Group_009.jpg';
import meeting from 'assets/img/MSC17_kendallClawson_006.jpg';
import enterpriseWorkers from 'assets/img/CLO22_TechOffice_025.jpg';
import guyAloneOnLaptop from 'assets/img/SEC20_Security_033.jpg';
import { toTitleCase } from 'utils/string-utils';

export const formImages = [
    { key: mathuraIndia, text: 'Report Foreign Travel' },
    { key: hybridWorkplace, text: 'Foreign Contact Report' },
    { key: retailStoreMgr, text: 'Badge requests' },
    { key: womanScanningBadge, text: 'CAC/SIPR' },
    { key: womanScanningBadgeFurtherAway, text: 'Smart Cards' },
    { key: hybridWheelchair, text: 'Citizenship Verification' },
    { key: blackWomenLaughing, text: 'Foreign Affections Report' },
    { key: walkingHanger, text: 'Foreign Travel Debrief' },
    { key: datacenterWalks, text: 'Visit Request' },
    { key: fatherDaughterBreakfast, text: 'Report Life Event' },

    { key: wheelchairGuyFarAway, text: 'man in wheelchair at work' },
    { key: motherBabyShopping, text: 'woman with baby at store' },
    { key: decisionMakersCollab, text: '3 people in hallway' },
    { key: inclusiveWorkplace, text: 'woman in wheelchair on phone' },
    { key: matureBusinessman, text: 'man smiling on tablet' },
    { key: businesswomanWheelchair, text: 'woman in wheelchair leading meeting' },
    { key: twoColleaguesModern, text: '2 people talking' },
    { key: customerService, text: 'customer service smiling' },
    { key: realPeopleProfessionals, text: '2 people pair coding' },
    { key: realPeopleOutside, text: 'people sitting on stairs' },
    { key: manWomanCollab, text: '2 people collaborating' },
    { key: businessTeamCollab, text: 'group working' },
    { key: meeting, text: '4 person meeting' },
    { key: enterpriseWorkers, text: '3 people working' },
    { key: guyAloneOnLaptop, text: 'man working on bench' },
].sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }));

export const baseElementStyles = mergeStyles({
    background: 'white',
    height: 'fit-content',
    padding: '0 25px 11px',
});

export const elementWrapperStyle = mergeStyles(baseElementStyles, {
    marginTop: '2rem',
});

export const formWrapperStyle = mergeStyles({
    height: '100%',
    minHeight: '82vh',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '2rem',
    paddingBottom: '3rem',
    width: '55%',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media(max-width: 1400px)': {
        width: '75%',
    },
});

export const descriptionStyles = mergeStyles({
    '& textarea': {
        resize: 'vertical',
    },
});

export const genericFormRecordFormStates = {
    draft: 'draft',
    submitted: 'submitted',
    underReview: 'under_review',
    accepted: 'accepted',
    completed: 'completed',
    rejected: 'rejected',
} as const;

// Note: These are user defined form names
// Not recommended to assume they always exist, but necessary for the occasional workaround
export const userDefinedGenericFormNames = {
    citizenshipVerification: 'citizenship_verification',
} as const;

export const formStateBadgeColors = {
    yellow: '#F5D779',
    blue: '#96BFF5',
    purple: '#B47DE8',
    mint: '#93DBB1',
    green: '#59B07E',
    red: '#F08181',
    darkGrey: '#C3C3C3',
    lightGrey: '#EBEBEB',
};

export const getStateColor = (state: string): string => {
    switch (state.toLowerCase()) {
        case 'accepted':
            return formStateBadgeColors.mint;
        case 'completed':
            return formStateBadgeColors.green;
        case 'rejected':
            return formStateBadgeColors.red;
        case 'underreview':
        case 'under_review':
            return formStateBadgeColors.purple;
        case 'submitted':
            return formStateBadgeColors.blue;
        case 'draft':
            return formStateBadgeColors.yellow;
        default:
            return formStateBadgeColors.darkGrey;
    }
};

const getFormStateText = (formState: string): string => {
    return formState.toLowerCase() === genericFormRecordFormStates.underReview
        ? 'Under Review'
        : toTitleCase(formState);
};

export type DateRangeData = {
    startDate: Date | undefined;
    endDate: Date | undefined;
    startTime: string;
    endTime: string;
};

export const getDateRangeValidatingSituation = (elem: FormElement): string => {
    const data = returnDateTimeForDateRange(elem);
    // check if empty only if it is required
    if (elem.required && (data.startDate === undefined || data.endDate === undefined)) {
        return `Date range must have start date and end date defined`;
    } else if (elem.hasTime && elem.required && (data.startTime === '' || data.endTime === '')) {
        return `Date range must have start time and end time defined`;
    } else if (
        data.startTime &&
        data.endTime &&
        data.startDate?.getTime() === data.endDate?.getTime() &&
        data.startTime > data.endTime
    ) {
        // same date but end time is before start time.
        return `End Time cannot be before the Start Time`;
    }
    return '';
};

export const getDateValidatingSituation = (elem: FormElement): string => {
    if (elem.type === 'date' && elem.required) {
        const dateStr = (elem.value as string) ?? '';
        const date = returnDatePart(dateStr);
        // check if empty only if it is required
        if (date === undefined) {
            return `Must have selected date`;
        } else if (elem.hasTime) {
            const time = returnTimePart(dateStr);
            if (time === '') {
                return 'Date must have a corresponding time';
            }
        }
    }
    return '';
};

export const returnDateTimeForDateRange = (element: FormElement): DateRangeData => {
    const date = (element.value as string[]) ?? [];
    const startValue = date[0];
    const endValue = date[1];
    const startDate = returnDatePart(startValue);
    const endDate = returnDatePart(endValue);
    const startTime = returnTimePart(startValue);
    const endTime = returnTimePart(endValue);
    return { startDate, endDate, startTime, endTime };
};

export const returnDatePart = (str: string | undefined): Date | undefined => {
    if (!str) {
        return undefined;
    }

    if (str.includes('T')) {
        if (str.split('T')[0] === '') {
            return undefined;
        }
        const dateWithTime = new Date(str);
        dateWithTime.setHours(0);
        dateWithTime.setMinutes(0);
        return dateWithTime;
    } else {
        return new Date(`${str}T00:00`);
    }
};

export const returnTimePart = (str: string | undefined): string => {
    if (!str) {
        return '';
    }

    const time = str.split('T')[1];
    return time ?? '';
};

const commonBadgeStyles = mergeStyles({
    color: '#201F1E',
    width: '6.7rem',
    textAlign: 'center',
    borderRadius: '4px',
    height: '1.69rem',
    lineHeight: '1.69rem',
    fontWeight: 600,
});

export const createBadge = (formState: string): JSX.Element => {
    const bgColor = mergeStyles({
        backgroundColor: getStateColor(formState),
    });
    return <div className={`${commonBadgeStyles} ${bgColor}`}>{getFormStateText(formState)}</div>;
};

export const next = 'next' as const;
export const endOfForm = 'endOfForm' as const;

export type ChoiceOptions = {
    key: string;
    text: string;
    branchTarget?: string;
};

export const TableOptionTypes = {
    textfield: 'textfield',
    dropdown: 'dropdown',
    date: 'date',
    country: 'country',
} as const;

export type TableOption = {
    key: string;
    text: string;
    description: string;
    type: keyof typeof TableOptionTypes;
    hasTime?: boolean;
    isFutureDatesOnly?: boolean;
    options?: ChoiceOptions[];
    required: boolean;
};

export const ValidatorKeys = {
    regex: 'regex',
    limit: 'limit',
} as const;

export const RegexValidatorValues = {
    phone: 'phone',
    email: 'email',
    zipcode: 'zipcode',
} as const;

export type ValidatorOptions = {
    type: keyof typeof ValidatorKeys;
    value: keyof typeof RegexValidatorValues | number;
};

export type UpdatableProperty = keyof Pick<
    FormElement,
    | 'label'
    | 'description'
    | 'options'
    | 'type'
    | 'required'
    | 'validatorOptions'
    | 'branches'
    | 'hasTime'
    | 'isFutureDatesOnly'
    | 'rangeLabels'
>;

export type ElementTypeProps = {
    element: FormElement;
    updateForm: (id: string, newValue: FormElementValues, operation: UpdatableProperty) => void;
};

export const ElementType = {
    textfield: 'textfield',
    radio: 'radio',
    checkbox: 'checkbox',
    dropdown: 'dropdown',
    signature: 'signature',
    table: 'table',
    info: 'info',
    date: 'date',
    daterange: 'daterange',
    country: 'country',
    people: 'people',
    person: 'person',
} as const;

export type ChoiceOptionValue = {
    key: string;
    text: string;
};

type DateRangeLabels = {
    start?: string;
    end?: string;
};
export type FormElement = {
    id: string;
    type: keyof typeof ElementType;
    label: string;
    description: string;
    required: boolean;
    validatorOptions?: ValidatorOptions;
    options?: ChoiceOptions[] | TableOption[];
    branches?: boolean;
    value?: string | string[] | StringDictionary[];
    hasTime?: boolean;
    isFutureDatesOnly?: boolean;
    rangeLabels?: DateRangeLabels;
    visible?: boolean;
};

export type DateElement = FormElement & {
    hasTime?: boolean;
    isFutureDatesOnly?: boolean;
    value: number;
};

export type DateRangeFormElement = FormElement & {
    rangeLabels?: DateRangeLabels;
    value?: string[];
};

export type StringDictionary = Dictionary<string>;

export type ChoiceGroupFormElement = FormElement & {
    options: ChoiceOptions[];
    value?: ChoiceOptionValue[];
};

export type TableFormElement = FormElement & {
    options: TableOption[];
};

export type ListNode = {
    next?: ListNode;
    value: number;
    prev?: ListNode;
};

export type FormValueTypes = string | string[] | StringDictionary[];

export const choiceTypes = [ElementType.checkbox, ElementType.radio, ElementType.dropdown] as const;

export type Section = {
    id: number;
    label: string;
    description: string;
    elements: FormElement[];
    branchTarget?: string;
    visible?: boolean;
};

export type Form = Pick<
    GenericForm,
    | 'id'
    | 'name'
    | 'title'
    | 'description'
    | 'image'
    | 'owners'
    | 'notificationList'
    | 'accessControlAttributes'
    | 'accessControlEligibilities'
    | 'accessControlEmployeeProperties'
    | 'isPublished'
    | 'isSoftDeleted'
    | 'subtitle'
    | 'isLocked'
> & { sections: Section[] };

export type FormElementValues = ValueOf<FormElement>;

export type FormResponse = Pick<Form, 'id' | 'name' | 'title' | 'description' | 'sections'> & {
    label?: string;
};
