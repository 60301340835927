import { TableCell } from 'components/common/table';
import React, { ReactNode } from 'react';
import { IColumn, CommandBar } from '@fluentui/react';
import { lastColumnStylesButton } from 'assets/styles/list-styles';
import { IEmployeeReview } from 'clients/sca-client';
import Badge from 'components/common/badge';
import {
    getEmployeeReviewStateBackground,
    getEmployeeReviewStatusText,
} from 'components/sca/sca-constants';
import { IconNames, Dictionary, maxWidthCoeff } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { IBasicEmployee } from 'clients/employee-client';
import { ScaViewAddEmployeeModalButton } from 'components/sca/manage/sca-view-add-employee-modal-button';
import { ScaViewDeleteEmployeeModalButton } from 'components/sca/manage/sca-view-delete-employee-modal-button';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { timeToString, TimeFormats } from 'utils/time-utils';
import ScaEmployeeHistoryDialogButton from 'components/sca/sca-common/sca-employee-history-dialog-button';
import { ISortableColumns } from 'utils/sort-utils';
import ScaReviewedByTableCell from 'components/sca/sca-common/sca-reviewed-by-table-cell';

interface IReviewsColumns extends ISortableColumns {
    imageUrls: Dictionary<string>;
    isCurrent: boolean;
    hasEditRoles: boolean;
    basicEmployeesInfo: Dictionary<IBasicEmployee>;
    reviewedByBasicEmployeesInfo: Dictionary<IBasicEmployee>;
    onDeleteEmployeeConcluded: (
        modalConclusion: ModalConclusion,
        employeeId: string,
    ) => Promise<void>;
    onAddEditEmployeeConcluded: (
        mode: 'add' | 'update',
        modalConclusion: ModalConclusion,
    ) => Promise<void>;
}

export function getScaReviewIdTableColumns(params: IReviewsColumns): IColumn[] {
    const actionsColumnWidth = 100;
    // The variable actionsButtonWidth below is added in an effort
    // to fix this problem: Browser Chrome may show overflow dots "..."
    ///
    const actionsButtonWidth = actionsColumnWidth + 20;
    const columnWidths = {
        employee: 140,
        status: 100,
        reviewedBy: 140,
        reviewedOn: 90,
        currentRate: 90,
        previousRate: 90,
        action: actionsColumnWidth,
    };

    const columns: IColumn[] = [
        {
            key: 'Employee',
            name: 'Employee',
            ariaLabel: 'Employee',
            minWidth: columnWidths.employee,
            maxWidth: columnWidths.employee * maxWidthCoeff,
            isSorted: params.sortColumn === 'Employee',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Employee');
            },
            onRender: (row: IEmployeeReview): JSX.Element => {
                if (params.basicEmployeesInfo[row.personnelId]) {
                    return (
                        // Strangely, the following key was instrumental in making
                        // sorting based on this column work.
                        // Problem without the key: Upon sorting, the employee cards
                        //     would correctly sort the employee info, but won't change
                        //     the picture - the picture that was on the first (or 2'nd, ...)
                        //     row before sorting would remain on the first (or 2'nd, ...)
                        //     row after sorting.
                        // With this key: Upon sorting, each employee card would show
                        //     the correct picture.
                        // It's funny how sorting without the key can create a face transplant.
                        <TableCell>
                            <EmployeeBasicHoverCard
                                key={row.personnelId}
                                showMiniCardAlias={false}
                                employeeBasicData={params.basicEmployeesInfo[row.personnelId]}
                            />
                        </TableCell>
                    );
                } else {
                    return (
                        <TableCell>
                            <EmployeeBasicHoverCard
                                key={row.personnelId}
                                personnelId={row.personnelId}
                                showMiniCardAlias={false}
                            />
                        </TableCell>
                    );
                }
            },
        },
        {
            key: 'Status',
            name: 'Status',
            ariaLabel: 'Status',
            minWidth: columnWidths.status,
            maxWidth: columnWidths.status * maxWidthCoeff,
            onRender: (row: IEmployeeReview): JSX.Element => {
                return (
                    <TableCell>
                        <Badge
                            text={getEmployeeReviewStatusText(row.reviewState)}
                            backgroundColor={getEmployeeReviewStateBackground(
                                row.reviewState ?? '',
                            )}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: 'Reviewed By',
            name: 'Reviewed By',
            ariaLabel: 'Reviewed By',
            minWidth: columnWidths.reviewedBy,
            maxWidth: columnWidths.reviewedBy * maxWidthCoeff,
            onRender: (row: IEmployeeReview): JSX.Element => {
                return <ScaReviewedByTableCell reviewedBy={row.reviewedBy} />;
            },
        },
        {
            key: 'Reviewed On',
            name: 'Reviewed On',
            ariaLabel: 'Reviewed On',
            minWidth: columnWidths.reviewedOn,
            maxWidth: columnWidths.reviewedOn * maxWidthCoeff,
            onRender: (row: IEmployeeReview): JSX.Element => {
                return (
                    <TableCell>
                        <span>
                            {timeToString(row.reviewedTimestampUTC * 1000, TimeFormats.MDYYYY)}
                        </span>
                    </TableCell>
                );
            },
        },
        {
            key: 'Current Rate',
            name: 'Current Rate',
            ariaLabel: 'Current Rate',
            minWidth: columnWidths.currentRate,
            maxWidth: columnWidths.currentRate * maxWidthCoeff,
            isSorted: params.sortColumn === 'Current Rate',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Current Rate');
            },
            onRender: (row: IEmployeeReview): JSX.Element => {
                return <TableCell>{row.rate.toFixed(2)}%</TableCell>;
            },
        },
        {
            key: 'Previous Rate',
            name: 'Previous Rate',
            ariaLabel: 'Previous Rate',
            minWidth: columnWidths.previousRate,
            maxWidth: undefined,
            isSorted: params.sortColumn === 'Previous Rate',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Previous Rate');
            },
            onRender: (row: IEmployeeReview): JSX.Element => {
                return <TableCell>{row.previousRate.toFixed(2)}%</TableCell>;
            },
        },
        {
            key: 'Action',
            name: 'Action',
            ariaLabel: 'Action',
            minWidth: columnWidths.action,
            maxWidth: columnWidths.action,
            ...lastColumnStylesButton,
            onRender: (row: IEmployeeReview): JSX.Element => {
                return <TableCell>{actionDropdown(params, row)}</TableCell>;
            },
        },
    ];

    const actionDropdown = (params: IReviewsColumns, row: IEmployeeReview): ReactNode => {
        const actions = [];
        if (params.hasEditRoles && params.isCurrent) {
            actions.push({
                key: 'Delete Employee',
                onRender: (): JSX.Element => (
                    <ScaViewDeleteEmployeeModalButton
                        employee={params.basicEmployeesInfo[row.personnelId]}
                        isCurrent={params.isCurrent}
                        reviewPeriod={row}
                        onModalConcluded={params.onDeleteEmployeeConcluded}
                    />
                ),
            });
            actions.push({
                key: 'Edit Employee',
                onRender: (): JSX.Element => (
                    <ScaViewAddEmployeeModalButton
                        mode='update'
                        review={row}
                        employee={params.basicEmployeesInfo[row.personnelId]}
                        onAddEditEmployeeConcluded={params.onAddEditEmployeeConcluded}
                    />
                ),
            });
        }
        actions.push({
            key: 'View History',
            onRender: (): JSX.Element => (
                <ScaEmployeeHistoryDialogButton
                    personnelId={row.personnelId}
                    buttonText='History'
                    noHoverCard={false}
                />
            ),
        });

        return (
            <CommandBar
                styles={{
                    root: {
                        paddingLeft: 0,
                        background: globalStyles.backgroundColor,
                        selectors: {
                            // Make the second icon that would be shown on the right
                            // side of the button disappear. We'll place our own icon
                            // at the left side.
                            ['i:nth-of-type(2)']: { display: 'none' },
                        },
                    },
                }}
                items={[
                    {
                        key: 'Actions',
                        text: 'Actions',
                        buttonStyles: {
                            root: {
                                background: `${globalStyles.backgroundColor}`,
                                width: actionsButtonWidth,
                                textAlign: 'left',
                            },
                        },
                        iconProps: { iconName: IconNames.ChevronDown },
                        subMenuProps: {
                            items: actions,
                        },
                    },
                ]}
                ariaLabel='Actions'
            />
        );
    };

    return columns;
}
