import { TableCell } from 'components/common/table';
import React from 'react';
import { IColumn } from '@fluentui/react';
import { lastColumnStylesButton } from 'assets/styles/list-styles';
import { IScaRecord, IReviewPeriod } from 'clients/sca-client';
import Badge from 'components/common/badge';
import {
    getEmployeeReviewStateBackground,
    getEmployeeReviewStatusText,
    IScaExecOrgData,
} from 'components/sca/sca-constants';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { largeMaxWidthCoeff } from 'assets/constants/global-constants';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import ScaEmployeeHistoryDialogButton from 'components/sca/sca-common/sca-employee-history-dialog-button';
import ScaReviewedByTableCell from 'components/sca/sca-common/sca-reviewed-by-table-cell';

interface IReviewsColumns {
    reviewPeriod: IReviewPeriod | undefined;
}

export function getScaMyOrgColumns(params: IReviewsColumns): IColumn[] {
    const columnWidths = {
        employee: 90,
        status: 60,
        reportsTo: 90,
        currentRate: 50,
        reviewedBy: 90,
        reviewedOn: 100,
        comment: 140,
        actions: 80,
    };

    const columns: IColumn[] = [
        {
            key: 'Employee',
            name: 'Employee',
            ariaLabel: 'Employee',
            minWidth: columnWidths.employee,
            maxWidth: columnWidths.employee * largeMaxWidthCoeff,
            onRender: (row: IScaRecord): JSX.Element => {
                return (
                    <TableCell>
                        {/* The following key is essential for EmployeeBasicHoverCard
                            to show the correct picture if sorting based on this column
                            was added in the future. */}
                        <EmployeeBasicHoverCard
                            key={row.personnelId}
                            personnelId={row.personnelId}
                            showMiniCardAlias={false}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: 'Status',
            name: 'Status',
            ariaLabel: 'Status',
            minWidth: columnWidths.status,
            maxWidth: columnWidths.status * largeMaxWidthCoeff,
            onRender: (row: IScaRecord): JSX.Element => {
                return (
                    <TableCell>
                        <Badge
                            text={getEmployeeReviewStatusText(row.reviewState)}
                            backgroundColor={getEmployeeReviewStateBackground(
                                row.reviewState ?? '',
                            )}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: 'Reports To',
            name: 'Reports To',
            ariaLabel: 'Reports To',
            minWidth: columnWidths.reportsTo,
            maxWidth: columnWidths.reportsTo * largeMaxWidthCoeff,
            onRender: (row: IScaExecOrgData): JSX.Element => {
                return (
                    <TableCell>
                        {/* The following key is important if sorting
                            by this column was added in the future.  */}
                        <EmployeeBasicHoverCard
                            key={row.reportsTo.id}
                            personnelId={row.reportsTo.id}
                            showMiniCardAlias={false}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: 'Rate',
            name: 'Rate',
            ariaLabel: 'Rate',
            minWidth: columnWidths.currentRate,
            maxWidth: columnWidths.currentRate * largeMaxWidthCoeff,
            onRender: (row: IScaRecord): JSX.Element => {
                return <TableCell>{row.rate.toFixed(2)}%</TableCell>;
            },
        },
        {
            key: 'Reviewed By',
            name: 'Reviewed By',
            ariaLabel: 'Reviewed By',
            minWidth: columnWidths.reviewedBy,
            maxWidth: columnWidths.reviewedBy * largeMaxWidthCoeff,
            onRender: (row: IScaRecord): JSX.Element => {
                return <ScaReviewedByTableCell reviewedBy={row.reviewedBy} />;
            },
        },
        {
            key: 'Reviewed On',
            name: 'Reviewed On',
            ariaLabel: 'Reviewed On',
            minWidth: columnWidths.reviewedOn,
            maxWidth: columnWidths.reviewedOn * largeMaxWidthCoeff,
            onRender: (row: IScaRecord): JSX.Element => {
                if (row.reviewedTimestampUTC) {
                    return (
                        <TableCell>
                            {new Date(row.reviewedTimestampUTC * 1000).toLocaleDateString()}
                        </TableCell>
                    );
                } else {
                    return <></>;
                }
            },
        },
        {
            key: 'Comment',
            name: 'Comment',
            ariaLabel: 'Comment',
            minWidth: columnWidths.comment,
            maxWidth: undefined,
            onRender: (row: IScaRecord): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisTextCss text={row.reviewComment} />
                    </TableCell>
                );
            },
        },
        {
            key: 'Actions',
            name: 'Actions',
            ariaLabel: 'Actions',
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions,
            ...lastColumnStylesButton,
            onRender: (row: IScaRecord): JSX.Element => (
                <TableCell>
                    <ScaEmployeeHistoryDialogButton personnelId={row.personnelId} />
                </TableCell>
            ),
        },
    ];
    return columns;
}
