import React, { useContext, useState } from 'react';
import { AuthContext } from 'contexts/auth-context';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import { useFetchSimple } from 'utils/misc-hooks';
import GroupClient, {
    IGroup,
    IGroupActivityFilters,
    GroupActivityTag,
    IGroupActivitiesResponse,
} from 'clients/group-client';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import { ActionButton, MessageBarType, Separator, Stack } from '@fluentui/react';
import { globalFilterSeparatorStyles, globalStyles } from 'assets/styles/global-styles';
import { IconNames } from 'assets/constants/global-constants';
import TimelineEventBins from 'components/groups/manage-group/timeline/timeline-event-bins';
import {
    GroupTimelineBin,
    binSortEvents,
} from 'components/groups/manage-group/timeline/timeline-utils';
import { doNothing } from 'utils/misc-utils';
import useMessageBar from 'components/common/use-message-bar';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import Spacer from 'components/common/spacer';
import { CheckScrollReachedBottom } from 'components/common/scroll-event-listener';
import DownloadCsvReportButton from 'components/common/buttons/download-csv-report-button';
import useChoiceGroup from 'components/common/use-input/use-choice-group';
import { useCoreEmployeePicker } from 'components/common/use-input/use-core-employee-picker';
import { useDatepicker } from 'components/common/use-input/use-date-picker';
import { useCheckboxArrayV1 } from 'components/common/use-input/use-checkbox-array-v1';
import { DAY_IN_MILLISECONDS } from 'utils/time-utils';

export interface ManageGroupTimelineProps {
    group: IGroup;
    groupId: string;
}

const EVENTS_PER_FETCH = 100;
const MINIMUM_EVENT_DISPLAY_COUNT = 100;

export default function ManageGroupTimeline(props: ManageGroupTimelineProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const [timelineEventBin, setTimelineEventBins] = useState<GroupTimelineBin[]>();
    const [activitiesContinuation, setActivitiesContinuation] = useState<string>();
    const [shouldFetchActivities, setShouldFetchActivities] = useState<boolean>(true);

    const [violationIdNameMap, setViolationIdNameMap] = useState<Map<string, string>>(new Map());
    const [violationsContinuation, setViolationsContinuation] = useState<string>();

    const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
    const [displayedEventsCount, setDisplayedEventsCount] = useState<number>(0);

    const [shouldFetchGroupRules, setShouldFetchGroupRules] = useState<boolean>(false);

    const {
        theMessage: errorFetchingActivities,
        theElement: errorFetchingActivitiesElement,
        setMessage: setErrorFetchingActivities,
    } = useMessageBar({
        type: MessageBarType.error,
    });

    const {
        theMessage: errorFetchingViolations,
        theElement: errorFetchingViolationsElement,
        setMessage: setErrorFetchingViolations,
    } = useMessageBar({
        type: MessageBarType.error,
    });

    const {
        theMessage: errorDownloadingReport,
        theElement: errorDownloadingReportElement,
        setMessage: setErrorDownloadingReport,
    } = useMessageBar({
        type: MessageBarType.error,
    });

    const {
        theMessage: errorFetchingGroupRules,
        theElement: errorFetchingGroupRulesElement,
        setMessage: setErrorFetchingGroupRules,
    } = useMessageBar({
        type: MessageBarType.error,
    });

    const {
        value: fromDate,
        initialize: initFromDate,
        theElement: fromDateElement,
    } = useDatepicker({
        allowTextInput: true,
        disabled: false,
        ariaLabel: 'From Date',
    });

    const { value: toDate, initialize: initToDate, theElement: toDateElement } = useDatepicker({
        allowTextInput: true,
        disabled: false,
        ariaLabel: 'To Date',
    });

    enum SearchFor {
        Employee = 'Employee ',
        Manager = 'Manager ',
    }

    const {
        value: searchForSelection,
        theElement: searchForElement,
        initialize: initSearchFor,
    } = useChoiceGroup({
        options: [
            { key: SearchFor.Employee, text: SearchFor.Employee },
            { key: SearchFor.Manager, text: SearchFor.Manager },
        ],
        horizontal: true,
    });

    const {
        value: principal,
        theElement: principalElement,
        initialize: initPrincipal,
    } = useCoreEmployeePicker({
        required: false,
        disabled: false,
    });

    const {
        value: categories,
        initialize: initCategories,
        theElement: categoriesElement,
    } = useCheckboxArrayV1({
        options: [
            { label: GroupActivityTag.MEMBER, isChecked: false },
            { label: GroupActivityTag.POLICY, isChecked: false },
            { label: GroupActivityTag.SETTING, isChecked: false },
            { label: GroupActivityTag.SYSTEM, isChecked: false },
        ],
    });

    const { isFetching: isFetchingViolations } = useFetchSimple<IGroupActivitiesResponse>({
        dependencies: [props.groupId, violationsContinuation],
        canPerformFetch:
            !!props.groupId &&
            (violationsContinuation === undefined || // This kicks in at the first fetch.
                !!violationsContinuation), // This kicks in at the end of every fetch
        fetchFunc: async () => {
            const filters = { categories: [GroupActivityTag.POLICY] };
            setErrorFetchingViolations('');
            return GroupClient.getActivities(
                authContext,
                props.groupId,
                filters,
                undefined, // pageSize
                violationsContinuation,
            );
        },
        onSuccess: (response) => {
            setViolationsContinuation(response.continuationToken);
            setViolationIdNameMap((currentValue) => {
                const newValue = new Map(currentValue);
                response.results.forEach((activity) => {
                    const violationId = activity.directObject.value;
                    const ruleName = activity.metadata.groupRuleName;
                    // Only add the name to the map if one does not already exist.
                    // Activites are added in reverse order. The first rule activity
                    // in the list will have the most recent name
                    if (!newValue.has(violationId)) {
                        newValue.set(violationId, ruleName);
                    }
                });
                return newValue;
            });

            if (!response.continuationToken) {
                // this will trigger a fetch for all current rules for group
                // activity service may not contain all group rule id to name mappings
                // by fetching all current rules, we can at least add missing mappings for all current rules
                setShouldFetchGroupRules(true);
            }
        },
        onError: () => {
            setErrorFetchingViolations('Error fetching Group activities on policy violation');
        },
        onFinally: doNothing,
    });

    useFetchSimple({
        dependencies: [shouldFetchGroupRules],
        canPerformFetch: !!props.groupId && shouldFetchGroupRules,
        fetchFunc: async () => {
            setShouldFetchGroupRules(false);
            return GroupClient.getGroupRules(authContext, props.groupId);
        },
        onSuccess: (results) => {
            setViolationIdNameMap((currentValue) => {
                const newValue = new Map(currentValue);
                results.forEach((groupRule) => {
                    const violationId = groupRule.id;
                    const ruleName = groupRule.name;

                    // only add the rule name to the map if group rule Id does not exist
                    if (!newValue.has(violationId)) {
                        newValue.set(violationId, ruleName);
                    }
                });
                return newValue;
            });
        },
        onError: () => {
            setErrorFetchingGroupRules(
                'Error fetching Group rules for translating from rule ids to names',
            );
        },
        onFinally: doNothing,
    });

    const { isFetching: isFetchingActivities } = useFetchSimple({
        dependencies: [
            props.groupId,
            shouldFetchActivities,
            activitiesContinuation,
            fromDate, // Used in requestFilters()
            toDate, // Used in requestFilters()
            categories, // Used in requestFilters()
            principal, // Used in requestFilters()
            searchForSelection, // Used in requestFilters()
            displayedEventsCount,
        ],
        canPerformFetch: !!props.groupId && shouldFetchActivities,
        fetchFunc: async () => {
            setShouldFetchActivities(false);
            setErrorFetchingActivities('');
            return await GroupClient.getActivities(
                authContext,
                props.groupId,
                requestFilters(),
                EVENTS_PER_FETCH, // pageSize
                activitiesContinuation, // continuation token
            );
        },
        onSuccess: (groupActivities) => {
            setTimelineEventBins((currentValue) => {
                return binSortEvents(groupActivities.results, currentValue);
            });
            setActivitiesContinuation(groupActivities.continuationToken);

            if (
                groupActivities.continuationToken &&
                displayedEventsCount + groupActivities.results.length < MINIMUM_EVENT_DISPLAY_COUNT
            ) {
                setShouldFetchActivities(true);
            }
        },
        onError: () => {
            setErrorFetchingActivities('Error fetching Group activities');
        },
        onFinally: doNothing,
    });

    const requestFilters = (): IGroupActivityFilters => {
        return {
            startDate: !!fromDate ? fromDate.getTime() : undefined,
            endDate: !!toDate ? toDate.getTime() + DAY_IN_MILLISECONDS : undefined,
            principalId: principal?.id,
            categories: categories
                ?.filter((category) => category.isChecked)
                .map((category) => category.label as GroupActivityTag),
            subjectsOnly: searchForSelection?.key === SearchFor.Manager,
        };
    };

    const isFetching = (): boolean => {
        return isFetchingActivities || isFetchingViolations;
    };

    const onApplyFilters = (): void => {
        setTimelineEventBins([]);
        setActivitiesContinuation('');
        setShouldFetchActivities(true);
    };

    const onClearFilters = (): void => {
        initFromDate();
        initToDate();
        initSearchFor();
        initPrincipal();
        initCategories();
        onApplyFilters();
    };

    function onScrollReachedBottom(): void {
        if (!!activitiesContinuation && !isFetchingActivities && !errorFetchingActivities) {
            setShouldFetchActivities(true);
        }
    }

    const onDownloadReport = async (): Promise<string> => {
        try {
            setIsDownloadingReport(true);
            const report = await GroupClient.getActivitiesReport(
                authContext,
                props.group.id,
                requestFilters(),
            );
            setIsDownloadingReport(false);
            return report;
        } catch (e) {
            setIsDownloadingReport(false);
            setErrorDownloadingReport('Error downloading report');
            return '';
        }
    };

    return (
        <SidebarAndContents>
            <SidebarPane>
                <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                    From Date
                </Separator>
                {fromDateElement()}
                <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                    To Date
                </Separator>
                {toDateElement()}
                <Spacer marginTop={5} />
                <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                    Role
                </Separator>
                {searchForElement()}
                <Spacer marginTop={5} />
                <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                    Employee
                </Separator>
                <Spacer marginTop={5} />
                {principalElement()}
                <Spacer marginTop={5} />
                <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                    Categories
                </Separator>
                {categoriesElement()}
                <Spacer marginTop={20} />
                <Stack horizontal horizontalAlign='space-between'>
                    <Stack.Item>
                        <ActionButton
                            disabled={isFetching()}
                            iconProps={{ iconName: IconNames.Refresh }}
                            onClick={onApplyFilters}>
                            <span>Apply Filters</span>
                        </ActionButton>
                    </Stack.Item>
                    <Stack.Item>
                        <ActionButton
                            disabled={isFetching()}
                            iconProps={{ iconName: IconNames.ClearFilter }}
                            onClick={onClearFilters}>
                            <span>Clear filters</span>
                        </ActionButton>
                    </Stack.Item>
                </Stack>
            </SidebarPane>
            <ContentPane>
                <HorizontalBar>
                    <Stack.Item className={horizontalBarTitleStyle} grow={100}>
                        <h1
                            className={`${globalStyles.boldFont} ${globalStyles.mediumLargeFont} ${globalStyles.removeTopBottomMargins}`}>
                            Timeline
                        </h1>
                    </Stack.Item>
                    <Stack.Item>
                        <DownloadCsvReportButton
                            getData={onDownloadReport}
                            fileNamePrefix={'group-timeline-' + props.group.id}
                            buttonText='Download report'
                            disabled={isDownloadingReport}
                        />
                    </Stack.Item>
                </HorizontalBar>
                {!!errorDownloadingReport && errorDownloadingReportElement()}
                {!!errorFetchingActivities && errorFetchingActivitiesElement()}
                {!!errorFetchingViolations && errorFetchingViolationsElement()}
                {!!errorFetchingGroupRules && errorFetchingGroupRulesElement()}
                <IsLoadingIndicator
                    isLoading={isDownloadingReport}
                    before={<Spacer marginTop={20} />}
                    msg='Downloading report...'
                />
                <Spacer marginTop={8} />
                <TimelineEventBins
                    group={props.group}
                    timelineEventBin={timelineEventBin}
                    violationIdNameMap={violationIdNameMap}
                    onDisplayedEventsCount={setDisplayedEventsCount}
                />

                <IsLoadingIndicator
                    isLoading={isFetchingActivities}
                    before={<Spacer marginTop={20} />}
                    msg='Loading activities...'
                />
                <IsLoadingIndicator
                    isLoading={isFetchingViolations}
                    before={<Spacer marginTop={20} />}
                    msg='Loading violations...'
                />
                <CheckScrollReachedBottom
                    shouldCheck={!!activitiesContinuation}
                    onScrollReachedBottom={onScrollReachedBottom}
                />
            </ContentPane>
        </SidebarAndContents>
    );
}
