import { Stack } from '@fluentui/react';
import ExpandColumnsActionButton from 'components/common/buttons/expand-columns-action-button';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import HorizontalBar from 'components/common/horizontal-bar';
import React from 'react';
import { TimeFormats } from 'utils/time-utils';
import { globalStyles } from 'assets/styles/global-styles';

interface IScreeningPageDataBarProps {
    pageName: string;
    showExpandColumnsButton: boolean;
    showDownloadReportButton: boolean;
    getReportData: () => Promise<string>;
    isColumnsExpanded: boolean;
    onExpandColumnsClick: () => void;
    shownRecordCount: number | undefined;
    totalRecordCount: number | undefined;
}

export default function ScreeningContentsToolbar(props: IScreeningPageDataBarProps): JSX.Element {
    return (
        <HorizontalBar horizontalAlign='space-between'>
            <Stack horizontal verticalAlign='center'>
                {props.shownRecordCount !== undefined && props.totalRecordCount !== undefined && (
                    <Stack.Item className={globalStyles.boldFont}>
                        {`${props.shownRecordCount} of ${props.totalRecordCount} Records`}
                    </Stack.Item>
                )}
            </Stack>
            <Stack horizontal>
                {props.showExpandColumnsButton && (
                    <ExpandColumnsActionButton
                        isColumnsExpanded={props.isColumnsExpanded}
                        onExpandColumnsClick={props.onExpandColumnsClick}
                    />
                )}
                {props.showDownloadReportButton && (
                    <ExportToExcelButton<string>
                        buttonTitle='Download report'
                        getData={async (): Promise<string> => props.getReportData()}
                        fileNamePrefix={`US_Gov_Screening_${props.pageName.replace(' ', '_')}`} // page name can come with spaces
                        fileNameTimeFormat={TimeFormats.MM_DD_YYYY_HHmm}
                        formatHeader={false}
                    />
                )}
            </Stack>
        </HorizontalBar>
    );
}
