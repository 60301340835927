import React from 'react';
import MyGroups from 'components/groups/my-groups/my-groups';
import MyGroupsProvider from 'components/groups/my-groups/my-groups-context';
import { Location } from 'history';

interface IMyGroupsWrapperProps {
    location: Location;
}

export default function MyGroupsWrapper(props: IMyGroupsWrapperProps): JSX.Element {
    return (
        <MyGroupsProvider>
            <MyGroups {...props} />
        </MyGroupsProvider>
    );
}
