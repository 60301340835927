import React from 'react';
import { TextField, DatePicker, mergeStyleSets } from '@fluentui/react';
import {
    AllEmployeeEditableFields,
    EmployeeEditableFieldsValidationFunctions,
    IEmployeeEditableInfo,
} from 'clients/employee-client';
import Spacer from 'components/common/spacer';

export interface CandidateNominationStepPersonalContactsProps {
    employee: IEmployeeEditableInfo;
    onEmployeeChange: (newEmployee: IEmployeeEditableInfo) => void;
}

const ERROR_MESSAGES = {
    email: 'A valid email address is required, for example: "address@domain.com"',
    cellPhoneNumber: 'A valid cell phone number between 10 and 18 digits long is required',
    phoneNumber: 'Please supply a valid 10 to 18 digit phone number, if applicable',
    SSN: 'A Social Security number that matches the pattern ###-##-#### is required',
    required: (fieldName: string): string => `${fieldName} is required`,
};

export default function CandidateNominationPersonalDetailsForm(
    props: CandidateNominationStepPersonalContactsProps,
): JSX.Element {
    const onChange = (
        newValue: string | Date | undefined,
        field: AllEmployeeEditableFields,
    ): void => {
        const newEmployee = { ...props.employee };

        if (typeof newValue === 'object') {
            switch (field) {
                case 'birthDate':
                    newEmployee.birthDate = newValue;
                    break;
                case 'passportIssueDate':
                    newEmployee.passportIssueDate = newValue;
                    break;
                case 'passportExpirationDate':
                    newEmployee.passportExpirationDate = newValue;
                    break;
            }
        } else if (typeof newValue === 'string') {
            switch (field) {
                case 'birthPlace':
                    newEmployee.birthPlace = newValue;
                    break;
                case 'employeeType':
                    newEmployee.employeeType = newValue;
                    break;
                case 'homeAddress':
                    newEmployee.homeAddress = newValue;
                    break;
                case 'homeCity':
                    newEmployee.homeCity = newValue;
                    break;
                case 'homeCountry':
                    newEmployee.homeCountry = newValue;
                    break;
                case 'homePhone':
                    newEmployee.homePhone = newValue;
                    break;
                case 'homeState':
                    newEmployee.homeState = newValue;
                    break;
                case 'homeZip':
                    newEmployee.homeZip = newValue;
                    break;
                case 'nationalIdNumber':
                    newEmployee.nationalIdNumber = newValue;
                    break;
                case 'passportId':
                    newEmployee.passportId = newValue;
                    break;
                case 'personalCellPhone':
                    newEmployee.personalCellPhone = newValue;
                    break;
                case 'personalEmail':
                    newEmployee.personalEmail = newValue;
                    break;
                case 'workEmail':
                    newEmployee.workEmail = newValue;
                    break;
                case 'workPhone':
                    newEmployee.workPhone = newValue;
                    break;
            }
        }

        props.onEmployeeChange(newEmployee);
    };

    return (
        <>
            <>
                {/* Empty block create a similar indentation compared with previous
                    version of the file, only to make the PR less crowded.
                */}
                <TextField
                    label='Social security number'
                    required
                    value={props.employee.nationalIdNumber || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.nationalIdNumber(
                            props.employee.nationalIdNumber,
                        )
                            ? ''
                            : ERROR_MESSAGES.SSN
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.nationalIdNumber)
                    }
                    validateOnLoad={false}
                    className={styles.firstInputElement}
                />
                <TextField
                    label='Birth place'
                    required
                    value={props.employee.birthPlace || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.birthPlace(
                            props.employee.birthPlace,
                        )
                            ? ''
                            : ERROR_MESSAGES.required('Birth Place')
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.birthPlace)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />
                <DatePicker
                    label='Birth date'
                    allowTextInput={true}
                    isRequired
                    value={
                        props.employee.birthDate ? new Date(props.employee.birthDate) : undefined
                    }
                    onSelectDate={(newValue): void =>
                        onChange(newValue || undefined, AllEmployeeEditableFields.birthDate)
                    }
                    className={styles.inputElement}
                />
                <TextField
                    label='Passport number'
                    value={props.employee.passportId || ''}
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.passportId)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />
                <DatePicker
                    label='Passport issue date'
                    allowTextInput={true}
                    value={
                        props.employee.passportIssueDate
                            ? new Date(props.employee.passportIssueDate)
                            : undefined
                    }
                    onSelectDate={(newValue): void =>
                        onChange(newValue || undefined, AllEmployeeEditableFields.passportIssueDate)
                    }
                    className={styles.inputElement}
                />
                <DatePicker
                    label='Passport expiration date'
                    allowTextInput={true}
                    value={
                        props.employee.passportExpirationDate
                            ? new Date(props.employee.passportExpirationDate)
                            : undefined
                    }
                    onSelectDate={(newValue): void =>
                        onChange(
                            newValue || undefined,
                            AllEmployeeEditableFields.passportExpirationDate,
                        )
                    }
                    className={styles.inputElement}
                />
                <TextField
                    label='Company'
                    required
                    value={props.employee.employeeType || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.employeeType(
                            props.employee.employeeType,
                        )
                            ? ''
                            : ERROR_MESSAGES.required('Company')
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.employeeType)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />
                <TextField
                    label='Work phone'
                    value={props.employee.workPhone || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.workPhone(
                            props.employee.workPhone,
                        ) || !props.employee.workPhone
                            ? ''
                            : ERROR_MESSAGES.phoneNumber
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.workPhone)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />
                <TextField
                    label='Work email'
                    required
                    value={props.employee.workEmail || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.workEmail(
                            props.employee.workEmail,
                        )
                            ? ''
                            : ERROR_MESSAGES.email
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.workEmail)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />
                <TextField
                    label='Home phone'
                    value={props.employee.homePhone || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.homePhone(
                            props.employee.homePhone,
                        ) || !props.employee.homePhone
                            ? ''
                            : ERROR_MESSAGES.phoneNumber
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.homePhone)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />
                <TextField
                    label='Personal mobile phone'
                    required
                    value={props.employee.personalCellPhone || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.personalCellPhone(
                            props.employee.personalCellPhone,
                        )
                            ? ''
                            : ERROR_MESSAGES.cellPhoneNumber
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.personalCellPhone)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />
                <TextField
                    label='Personal email'
                    required
                    value={props.employee.personalEmail || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.personalEmail(
                            props.employee.personalEmail,
                        )
                            ? ''
                            : ERROR_MESSAGES.email
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.personalEmail)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />
                <TextField
                    label='Home street address'
                    required
                    value={props.employee.homeAddress || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.homeAddress(
                            props.employee.homeAddress,
                        )
                            ? ''
                            : ERROR_MESSAGES.required('Home Address')
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.homeAddress)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />
                <TextField
                    label='Home city'
                    required
                    value={props.employee.homeCity || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.homeCity(props.employee.homeCity)
                            ? ''
                            : ERROR_MESSAGES.required('Home City')
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.homeCity)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />
                <TextField
                    label='Home state'
                    required
                    value={props.employee.homeState || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.homeState(
                            props.employee.homeState,
                        )
                            ? ''
                            : ERROR_MESSAGES.required('Home State')
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.homeState)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />
                <TextField
                    label='Home ZIP'
                    required
                    value={props.employee.homeZip || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.homeZip(props.employee.homeZip)
                            ? ''
                            : ERROR_MESSAGES.required('Home Zip')
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.homeZip)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />

                <TextField
                    label='Home country'
                    required
                    value={props.employee.homeCountry || ''}
                    errorMessage={
                        EmployeeEditableFieldsValidationFunctions.homeCountry(
                            props.employee.homeCountry,
                        )
                            ? ''
                            : ERROR_MESSAGES.required('Home Country')
                    }
                    onChange={(ev, newValue): void =>
                        onChange(newValue, AllEmployeeEditableFields.homeCountry)
                    }
                    validateOnLoad={false}
                    className={styles.inputElement}
                />

                <Spacer marginTop={15} />
            </>
        </>
    );
}

const styles = mergeStyleSets({
    firstInputElement: {
        marginTop: 10,
    },
    inputElement: {
        marginTop: 20,
    },
});
