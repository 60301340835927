import { DefaultButton, FontIcon, mergeStyles, ProgressIndicator } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import BoldFont from 'components/common/misc/bold-font';
import React from 'react';
import { Section } from 'components/forms/forms-common';

const stepperBoxStyles = mergeStyles(globalStyles.boxShadow, {
    gridArea: 'stepper',
    padding: '1rem  .5rem .5rem',
    height: 'fit-content',
    position: 'sticky',
    justifySelf: 'end',
    top: '10.5rem',
    width: '23.5rem',
    '@media(max-width: 900px)': {
        position: 'static',
        marginBottom: '1rem',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

const buttonStyles = mergeStyles(globalStyles.boxShadow, {
    marginBottom: '1rem',
    padding: '.5rem',
    border: 'none',
    lineHeight: 'inherit',
    width: '100%',
    height: 'inherit',
    textAlign: 'inherit',
    '& > span': {
        justifyContent: 'start',
    },
    boxShadow: 'none',
});

const marginRightOnly = mergeStyles({
    marginRight: '8px',
    fontWeight: 'bolder',
});

const regularStyle = mergeStyles(marginRightOnly, {
    color: 'black',
});

const validStyle = mergeStyles(marginRightOnly, {
    color: 'green',
});

const incompleteStyle = mergeStyles(marginRightOnly, {
    color: '#e50000',
});

const smallFont = mergeStyles({
    fontSize: '.6rem',
});

const smallRedFontStyle = mergeStyles(smallFont, {
    color: '#e50000',
});

const selectedStyles = mergeStyles(buttonStyles, {
    color: 'white',
    background: '#106ebe',
    '&:hover': {
        color: 'white',
        background: '#106ebe',
    },
});

type FormsStepperProps = {
    sections: Section[];
    currentSectionIndex: number;
    navigateToSection: (sectionIndex: number) => void;
    errors: Set<number>;
    isSubmission: boolean;
    validProgressCount?: number;
    totalProgressCount?: number;
};

export default function FormsStepper(props: FormsStepperProps): JSX.Element {
    const {
        sections,
        currentSectionIndex,
        navigateToSection,
        errors,
        isSubmission,
        validProgressCount,
        totalProgressCount,
    } = props;
    const reviewSectionId = sections.length - 1;
    const iconAndText = (sectionId: number, label: string): JSX.Element => {
        const hasError = errors.has(sectionId);
        const hasValue = sections[sectionId].elements.some((e) => e.value);
        const iconStyle =
            sectionId !== currentSectionIndex
                ? hasError
                    ? incompleteStyle
                    : hasValue
                    ? validStyle
                    : regularStyle
                : marginRightOnly;

        const icon = hasError
            ? IconNames.CircleRing
            : hasValue
            ? IconNames.CheckMark
            : IconNames.CircleRing;

        const incompleteStyleText =
            sectionId !== currentSectionIndex ? smallRedFontStyle : smallFont;
        const errorTextElement = hasError ? (
            <span className={incompleteStyleText}>&nbsp;(incomplete)</span>
        ) : undefined;
        return (
            <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                <FontIcon className={iconStyle} iconName={icon} />
                <BoldFont>{sectionId === 0 ? 'Start here' : label}</BoldFont>
                {errorTextElement}
            </div>
        );
    };

    return (
        <div className={stepperBoxStyles}>
            {sections
                .filter((section) => section.visible && section.id < sections.length - 1)
                .map((section) => (
                    <DefaultButton
                        disabled={isSubmission}
                        key={section.id}
                        className={
                            section.id === currentSectionIndex ? selectedStyles : buttonStyles
                        }
                        onClick={() => navigateToSection(section.id)}>
                        {iconAndText(section.id, section.label)}
                    </DefaultButton>
                ))}
            <DefaultButton
                disabled={isSubmission}
                className={
                    reviewSectionId === currentSectionIndex && !isSubmission
                        ? selectedStyles
                        : buttonStyles
                }
                onClick={() => navigateToSection(reviewSectionId)}>
                <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                    <FontIcon
                        style={{ marginRight: '8px' }}
                        iconName={isSubmission ? IconNames.CheckMark : IconNames.CircleRing}
                    />
                    <BoldFont>Review</BoldFont>
                </div>
            </DefaultButton>
            <DefaultButton
                disabled={!isSubmission}
                className={isSubmission ? selectedStyles : buttonStyles}>
                <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                    <FontIcon
                        style={{ marginRight: '8px' }}
                        iconName={isSubmission ? IconNames.CheckMark : IconNames.CircleRing}
                    />
                    <BoldFont>Submit</BoldFont>
                </div>
            </DefaultButton>

            {!isSubmission && totalProgressCount !== undefined && validProgressCount !== undefined && (
                <ProgressIndicator
                    label={`Progress ${validProgressCount}/${totalProgressCount}`}
                    styles={{
                        itemName: { fontFamily: 'Segoe UI' },
                        root: { padding: '0.5rem' },
                    }}
                    description=''
                    percentComplete={validProgressCount / totalProgressCount} // between 0 - 1
                    barHeight={4}
                />
            )}
        </div>
    );
}
