/*  TODO: Clean up at the end of groups effort.

    Chances are we'll never need BadgeColor.
    On the React app, BadgeColor shows "harder" colors than BadgeColorHex.
    On the Angular app, on the other hand, I think BadgeColor shows the same as BadgeColorHex.
    Comparing Angular and React, BadgeColorHex on React shows similar to BadgeColor in Angular.
    Therefore, we'll probably only use BadgeColorHex in React.
    Keep this here until end of groups effort, and remove if never used.

    export enum BadgeColor {
        YELLOW = 'YELLOW',
        BLUE = 'BLUE',
        PURPLE = 'PURPLE',
        GREEN = 'GREEN',
        RED = 'RED',
        GRAY = 'GRAY',
    }
*/

export enum BadgeColorHex {
    YELLOW = '#FFEE58',
    BLUE = '#81D4FA',
    PURPLE = '#B39DDB',
    GREEN = '#66BB6A',
    RED = '#EF9A9A',
    GRAY = '#c9c9c9',
}
export const lightGray = 'rgb(221, 221, 221)';
export const faintYellow = 'rgba(255, 200, 10, 0.2)';
export const inputFieldErrorColor = 'rgb(164, 38, 44)';
export const mediumGrey = 'rgb(180, 180, 180)';
export const faintBlue = 'rgb(0, 120, 212)'; // has enough contrast ratio on a #FFFFFF colored background (ie. content area) for accessibility
export const lightFaintBlue = 'rgb(0, 114, 201)'; // has enough contrast ratio on a #FAFAFA colored background (ie. footer) for accessibility
export const focusedLightFaintBlue = 'rgb(0, 23, 201)';
export const lightPink = 'rgba(245, 135, 145, 0.2)';
export const grey130 = 'rgba(96, 94, 92, 1)';
export const grey160 = 'rgba(50, 49, 48, 1)';
export const grey30 = 'rgba(237, 235, 233, 1)';
