import { IPersonaProps } from '@fluentui/react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import { CorePrincipalsClient } from 'clients/core/personnel-core-client-wrappers';
import { fetchOrUseCachePictures } from 'components/common/employee/employee-utils';
import { AuthContext } from 'contexts/auth-context';
import { CacheContext } from 'contexts/cache-context';
import { useContext } from 'react';
import { debounceAsync } from 'utils/misc-utils';
import { transformPrincipalRecordToPersona } from 'utils/persona-utils';
import { getAppInsights } from 'utils/telemetry-utils';

export type PersonaItemIdType = keyof IPrincipalRecord;

export const useDebounceGetPrincipalPersonas = (
    restrictionsQuery?: string,
    personaItemIdType?: PersonaItemIdType,
) => {
    const authContext = useContext(AuthContext);
    const cacheContext = useContext(CacheContext);
    const maxResults = 50;

    const debouncedGetTypeaheadPersonas = debounceAsync<IPersonaProps[]>(
        getTypeaheadPrincipalPersonas,
    );
    async function getTypeaheadPrincipalPersonas(input: string): Promise<IPersonaProps[]> {
        if (!input) {
            return [];
        }
        try {
            const principalClient = new CorePrincipalsClient(authContext);

            const pagedResults = await principalClient.search(
                input, // search
                restrictionsQuery ?? null, // assignmentQuery
                null, // oid
                maxResults, // limit
                null, // continuationToken
                false, // includeInactive
            );

            const results = pagedResults.results ?? [];

            await fetchOrUseCachePictures(authContext, results, cacheContext);

            const personas = results.map((x) =>
                transformPrincipalRecordToPersona(x, personaItemIdType, cacheContext),
            );

            return personas;
        } catch (e) {
            getAppInsights()?.trackException({
                exception: e,
                severityLevel: SeverityLevel.Error,
            });
            return [];
        }
    }
    return debouncedGetTypeaheadPersonas;
};
