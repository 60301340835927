import {
    ActionButton,
    DefaultButton,
    IPanelProps,
    mergeStyleSets,
    Panel,
    PanelType,
    PrimaryButton,
} from '@fluentui/react';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useState } from 'react';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import AcceptDeclineNomination from 'components/screening/common/employee/employee-accept-decline-nomination';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { doNothing, doNothingAsync } from 'utils/misc-utils';

import CoveredIndividual from 'components/screening/common/nominee-accept-decline/images/covered-individuals.png';
import ControlledSubstances from 'components/screening/common/nominee-accept-decline/images/controlled-substances.png';
import CannabisPurchasing from 'components/screening/common/nominee-accept-decline/images/cannabis-purchasing.png';
import Training from 'components/screening/common/nominee-accept-decline/images/training.png';
import ResponsibilityToReport from 'components/screening/common/nominee-accept-decline/images/responsibility-to-report.png';
import ClearanceAndSuitability from 'components/screening/common/nominee-accept-decline/images/clearance-and-suitability.png';
import ContractChange from 'components/screening/common/nominee-accept-decline/images/contract-change.png';
import ThreatOrSuspiciousActivity from 'components/screening/common/nominee-accept-decline/images/threat-or-suspicious-activity.png';
import Spacer from 'components/common/spacer';
import NominationSubHeader from 'components/screening/common/nomination/steps/common/nomination-sub-header';

const contentMaxHeight = { root: { maxHeight: 24 } };

interface NomineeAcceptProps {
    screening: ICommonScreening;
    updateScreeningInRawCandidates(screen: ICommonScreening): void;
    isInitialEmployeeBatchLoaded: boolean;
    screeningPath: ScreeningPaths;
}

interface IModalActionButtonSubset extends IPanelProps {
    headerText: string;
    enableNext: boolean;
    enableBack: boolean;
    showSubmit: boolean;
    enableSubmit: boolean;
    onBack: () => void;
    onNext: () => void;
    onSubmit: () => Promise<void>;
    onCancel: () => void;
    children: ReactNode;
}

enum DialogBoxSteps {
    YourResponsibilityAsACoveredIndividual,
    ControlledSubstances,
    CannabisPurchasing,
    RequiredAnnualTraining,
    ResponsibilityToReport,
    ClearanceAndSuitability,
    ContractChange,
    ThreatOrSuspiciousActivity,
    AcceptOrDecline,
}

export default function NomineeAccept(props: NomineeAcceptProps): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);

    /**
     * Order of items in the constant array "steps"
     * determines order of execution of the steps.
     * See onGoNext() and onGoBack().
     */
    const steps = useMemo(() => {
        switch (props.screeningPath) {
            case ScreeningPaths.UsGov:
                return [
                    DialogBoxSteps.YourResponsibilityAsACoveredIndividual,
                    DialogBoxSteps.ControlledSubstances,
                    DialogBoxSteps.CannabisPurchasing,
                    DialogBoxSteps.RequiredAnnualTraining,
                    DialogBoxSteps.ResponsibilityToReport,
                    DialogBoxSteps.ClearanceAndSuitability,
                    DialogBoxSteps.ContractChange,
                    DialogBoxSteps.ThreatOrSuspiciousActivity,
                    DialogBoxSteps.AcceptOrDecline,
                ];
            case ScreeningPaths.PublicTrust:
            default:
                return [
                    DialogBoxSteps.YourResponsibilityAsACoveredIndividual,
                    DialogBoxSteps.ControlledSubstances,
                    DialogBoxSteps.CannabisPurchasing,
                    DialogBoxSteps.ClearanceAndSuitability,
                    DialogBoxSteps.ContractChange,
                    DialogBoxSteps.ThreatOrSuspiciousActivity,
                    DialogBoxSteps.AcceptOrDecline,
                ];
        }
    }, [props.screeningPath]);

    const firstStage = steps[0];

    const [currentStage, setCurrentStage] = useState<DialogBoxSteps>(firstStage);

    const onGoNext = useCallback(async (): Promise<void> => {
        const currentIx = steps.findIndex((stage) => stage === currentStage);
        if (currentIx < steps.length - 1) {
            setCurrentStage(steps[currentIx + 1]);
        }
    }, [steps, currentStage]);

    const onGoBack = useCallback(async (): Promise<void> => {
        const currentIx = steps.findIndex((stage) => stage === currentStage);
        if (currentIx > 0) {
            setCurrentStage(steps[currentIx - 1]);
        }
    }, [steps, currentStage]);

    const onInit = useCallback(() => {
        setCurrentStage(firstStage);
    }, [firstStage]);

    const onOpen = useCallback(() => {
        onInit();
        setIsOpen(true);
    }, [onInit]);

    const onClose = useCallback(() => {
        onInit();
        setIsOpen(false);
    }, [onInit]);

    const PanelImage = useCallback((props: { src: string }) => {
        const styles = mergeStyleSets({
            panelImageContainer: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: 330,
            },
            panelImage: {
                display: 'block',
                maxWidth: '100%',
                maxHeight: '100%',
            },
        });
        return (
            <div>
                <div className={styles.panelImageContainer}>
                    <img className={styles.panelImage} src={props.src} alt='Logo' />
                </div>
            </div>
        );
    }, []);

    const Note = useCallback(
        (props: { text: string }) => (
            <div>
                <label>
                    <b>Note:</b>
                </label>
                <span> {props.text} </span>
            </div>
        ),
        [],
    );

    const LearnMore = useCallback(
        (props: { text: string; link: string; linkDisplay: string }) => (
            <div>
                <span>
                    <b>Learn more</b> {props.text}{' '}
                    <a href={props.link} target='_'>
                        {props.linkDisplay}
                    </a>
                </span>
            </div>
        ),
        [],
    );

    const LearnMoreAboutNationalSecurityPolicies = useCallback(
        () => (
            <LearnMore
                text='about our'
                link='https://microsoft.sharepoint.com/teams/CST_NationalSecurity/SitePages/Policies.aspx'
                linkDisplay='National Security policies'
            />
        ),
        [LearnMore],
    );

    const LearnMoreAboutResponsibilityToReport = useCallback(
        () => (
            <LearnMore
                text='about your'
                link='https://aka.ms/responsibilitytoreport'
                linkDisplay='responsibility to report'
            />
        ),
        [LearnMore],
    );

    const Questions = useCallback(
        () => (
            <div>
                <span>
                    <b>Questions?</b> Reach out to the National Security Operations Center (NSOC) at{' '}
                    <a href='mailto:MyClearance@microsoft.com'>MyClearance@microsoft.com</a>
                </span>
            </div>
        ),
        [],
    );

    const currentStageProps: Record<string, IModalActionButtonSubset> = {};

    currentStageProps[DialogBoxSteps.YourResponsibilityAsACoveredIndividual] = useMemo(
        () => ({
            headerText: 'Covered individuals',
            enableNext: true,
            enableBack: false,
            showSubmit: false,
            enableSubmit: false,
            onBack: doNothing,
            onNext: onGoNext,
            onSubmit: doNothingAsync,
            onCancel: onClose,
            children: (
                <>
                    <PanelImage src={CoveredIndividual} />
                    <NominationSubHeader>
                        Your responsibility as a covered individual
                    </NominationSubHeader>
                    <p>
                        As a covered individual, you will be performing work for or on behalf of the
                        United States government and will be granted access to classified/sensitive
                        information or hold a sensitive position.
                    </p>
                    <p>
                        Carefully read the following messages to understand your ongoing
                        responsibilities as a covered individual and learn more BEFORE you accept
                        this nomination.
                    </p>
                    <Spacer marginTop={10} />
                    <LearnMoreAboutNationalSecurityPolicies />
                    <Spacer marginTop={10} />
                    <Questions />
                </>
            ),
        }),
        [onClose, onGoNext, LearnMoreAboutNationalSecurityPolicies, Questions, PanelImage],
    );

    currentStageProps[DialogBoxSteps.ControlledSubstances] = useMemo(
        () => ({
            headerText: 'Controlled substances',
            enableNext: true,
            enableBack: true,
            showSubmit: false,
            enableSubmit: false,
            onBack: doNothing,
            onNext: onGoNext,
            onSubmit: doNothingAsync,
            onCancel: onClose,
            children: (
                <>
                    <PanelImage src={ControlledSubstances} />
                    <NominationSubHeader>
                        Clarifying controlled substances and marijuana use
                    </NominationSubHeader>
                    <p>
                        As a Microsoft covered individual you are not allowed to use Federally
                        illegal drugs or misuse controlled substances including but not limited to
                        steroids, marijuana, and cannabidiol (CBD) products as outlined by the
                        Office of the Director of National Intelligence (ODNI)
                    </p>
                    <Spacer marginTop={10} />
                    <LearnMoreAboutNationalSecurityPolicies />
                    <Spacer marginTop={10} />
                    <Questions />
                </>
            ),
        }),
        [onClose, onGoNext, LearnMoreAboutNationalSecurityPolicies, Questions, PanelImage],
    );

    currentStageProps[DialogBoxSteps.CannabisPurchasing] = useMemo(
        () => ({
            headerText: 'Cannabis purchasing',
            enableNext: true,
            enableBack: true,
            showSubmit: false,
            enableSubmit: false,
            onBack: doNothing,
            onNext: onGoNext,
            onSubmit: doNothingAsync,
            onCancel: onClose,
            children: (
                <>
                    <PanelImage src={CannabisPurchasing} />
                    <NominationSubHeader>Clarifying cannabis purchasing</NominationSubHeader>
                    <p>
                        As a Microsoft covered individual you cannot directly invest in stocks or
                        business ventures that specifically pertain to marijuana growers and
                        retailers and companies that produce CBD products; while the cultivation and
                        distribution of marijuana remains Federally illegal under the Controlled
                        Substances Act.
                    </p>
                    <Spacer marginTop={10} />
                    <LearnMoreAboutNationalSecurityPolicies />
                    <Spacer marginTop={10} />
                    <Questions />
                </>
            ),
        }),
        [onClose, onGoNext, LearnMoreAboutNationalSecurityPolicies, Questions, PanelImage],
    );

    currentStageProps[DialogBoxSteps.RequiredAnnualTraining] = useMemo(
        () => ({
            headerText: 'Training',
            enableNext: true,
            enableBack: true,
            showSubmit: false,
            enableSubmit: false,
            onBack: doNothing,
            onNext: onGoNext,
            onSubmit: doNothingAsync,
            onCancel: onClose,
            children: (
                <>
                    <PanelImage src={Training} />
                    <NominationSubHeader>
                        Required annual training through the Microsoft Security Education Training
                        Awareness (SETA) program
                    </NominationSubHeader>
                    <p>
                        Love learning something new? As a Microsoft covered individual you are
                        required to complete and attest to annual training through the Microsoft
                        Security Education Training Awareness (SETA) program – check your training
                        compliance and complete outstanding actions on
                        <a href='https://personnel.microsoft.com/groups' target='_'>
                            Microsoft Personnel &gt; Groups &gt; SETA group.
                        </a>
                    </p>
                    <Spacer marginTop={10} />
                    <LearnMoreAboutNationalSecurityPolicies />
                    <Spacer marginTop={10} />
                    <Questions />
                </>
            ),
        }),
        [onClose, onGoNext, LearnMoreAboutNationalSecurityPolicies, Questions, PanelImage],
    );

    currentStageProps[DialogBoxSteps.ResponsibilityToReport] = useMemo(
        () => ({
            headerText: 'Life events',
            enableNext: true,
            enableBack: true,
            showSubmit: false,
            enableSubmit: false,
            onBack: doNothing,
            onNext: onGoNext,
            onSubmit: doNothingAsync,
            onCancel: onClose,
            children: (
                <>
                    <PanelImage src={ResponsibilityToReport} />
                    <NominationSubHeader>Responsibility to report life events</NominationSubHeader>
                    <p>
                        As a Microsoft covered individual you are required to report on events in
                        your life including but not limited to: foreign travel outside of the United
                        States (pre and post travel), continuing contact with citizens of another
                        country or dual citizens, if your spouse, family member, or
                        romantic/intimate relationship partner is a citizen of another country or a
                        dual citizen, life events including changes in marital status, cohabitants,
                        name, or address, and a threat or suspicious activity.
                    </p>
                    <Note text='Reporting requirements are subject to change based on the customer you are supporting.' />
                    <Spacer marginTop={10} />
                    <LearnMoreAboutResponsibilityToReport />
                    <Spacer marginTop={10} />
                    <Questions />
                </>
            ),
        }),
        [onClose, onGoNext, LearnMoreAboutResponsibilityToReport, Questions, PanelImage, Note],
    );

    currentStageProps[DialogBoxSteps.ClearanceAndSuitability] = useMemo(
        () => ({
            headerText: 'Clearance and suitability',
            enableNext: true,
            enableBack: true,
            showSubmit: false,
            enableSubmit: false,
            onBack: doNothing,
            onNext: onGoNext,
            onSubmit: doNothingAsync,
            onCancel: onClose,
            children: (
                <>
                    <PanelImage src={ClearanceAndSuitability} />
                    <NominationSubHeader>
                        Responsibility to inform your FSO/CSSO if you no longer need a clearance
                        and/or suitability
                    </NominationSubHeader>
                    <p>
                        Still need a clearance and/or suitability? As a Microsoft covered individual
                        you are required to inform your Facility Security Officer (FSO) and/or
                        Contractor Special Security Officer (CSSO) if you no longer required a
                        clearance and/or suitability to complete your role.
                    </p>
                    <Spacer marginTop={10} />
                    <div>
                        <span>
                            <b>Next steps</b> Complete the{' '}
                            <a href='https://aka.ms/clearancedebrief' target='_'>
                                Clearance debrief
                            </a>{' '}
                            and/or{' '}
                            <a href='https://aka.ms/publictrustoffboarding' target='_'>
                                Public Trust offboarding
                            </a>{' '}
                            form on Microsoft Personnel.
                        </span>
                    </div>
                    <Spacer marginTop={10} />
                    <div>
                        <span>
                            <b>Questions?</b> Reach out to the National Security Team (NST) at{' '}
                            <a href={'mailto:NSTCompliance@microsoft.com'}>
                                NSTCompliance@microsoft.com
                            </a>
                        </span>
                    </div>
                </>
            ),
        }),
        [onClose, onGoNext, PanelImage],
    );

    currentStageProps[DialogBoxSteps.ContractChange] = useMemo(
        () => ({
            headerText: 'Contract change',
            enableNext: true,
            enableBack: true,
            showSubmit: false,
            enableSubmit: false,
            onBack: doNothing,
            onNext: onGoNext,
            onSubmit: doNothingAsync,
            onCancel: onClose,
            children: (
                <>
                    <PanelImage src={ContractChange} />
                    <NominationSubHeader>
                        Responsibility to inform employees of contract changes at anytime
                    </NominationSubHeader>
                    <p>
                        Notice a contract change? Don’t worry, the Microsoft National Security Team
                        (NST) partners across the company and the US Government to manage contracts
                        through their lifecycle; this may require moving employees from one contract
                        to another contract and typically doesn’t require engagement from the
                        cleared employee.
                    </p>
                    <Spacer marginTop={10} />
                    <LearnMoreAboutNationalSecurityPolicies />
                    <Spacer marginTop={10} />
                    <Questions />
                </>
            ),
        }),
        [onClose, onGoNext, LearnMoreAboutNationalSecurityPolicies, Questions, PanelImage],
    );

    currentStageProps[DialogBoxSteps.ThreatOrSuspiciousActivity] = useMemo(
        () => ({
            headerText: 'Threat or suspicious activity',
            enableNext: true,
            enableBack: true,
            showSubmit: false,
            enableSubmit: false,
            onBack: doNothing,
            onNext: onGoNext,
            onSubmit: doNothingAsync,
            onCancel: onClose,
            children: (
                <>
                    <PanelImage src={ThreatOrSuspiciousActivity} />
                    <NominationSubHeader>
                        Responsibility to report a threat or suspicious activity
                    </NominationSubHeader>
                    <p>
                        Something seem wrong? As a Microsoft covered individual you are required to
                        report a threat or suspicious activity – submit a{' '}
                        <a
                            href='https://personnel.microsoft.com/forms/respond/report_the_threat/'
                            target='_'>
                            Report the threat form
                        </a>{' '}
                        on Microsoft Personnel
                    </p>
                    <Spacer marginTop={10} />
                    {/* Learn more */}
                    <div>
                        <span>
                            <b>Learn more</b> about{' '}
                            <a href='https://aka.ms/MCIP' target='_'>
                                counterintelligence and insider threats
                            </a>
                        </span>
                    </div>
                    <Spacer marginTop={10} />
                    {/* Questions */}
                    <div>
                        <label>
                            <b>Questions?</b>
                        </label>
                        <span>
                            {' '}
                            Reach out to the Microsoft Counterintelligence Program (MCIP) at{' '}
                            <a href='mailto:NatSecRiskMgr@microsoft.com'>
                                NatSecRiskMgr@microsoft.com
                            </a>
                        </span>
                    </div>
                </>
            ),
        }),
        [onClose, onGoNext, PanelImage],
    );

    currentStageProps[DialogBoxSteps.AcceptOrDecline] = useMemo(
        () => ({
            headerText: 'Nomination for clearance screening',
            enableNext: false,
            enableBack: true,
            showSubmit: true,
            enableSubmit: false,
            onBack: onGoBack,
            onNext: doNothing,
            onSubmit: doNothingAsync,
            onCancel: onClose,
            children: (
                <AcceptDeclineNomination
                    isInitialEmployeeBatchLoaded={props.isInitialEmployeeBatchLoaded}
                    onBack={onGoBack}
                    onClose={(): void => setIsOpen(false)}
                    onSubmit={props.updateScreeningInRawCandidates}
                    screening={props.screening}
                    screeningPath={props.screeningPath}
                />
            ),
        }),
        [
            onClose,
            onGoBack,
            props.isInitialEmployeeBatchLoaded,
            props.screening,
            props.screeningPath,
            props.updateScreeningInRawCandidates,
        ],
    );

    const panelProps = currentStageProps[currentStage];

    return (
        <>
            <ActionButton
                styles={contentMaxHeight}
                iconProps={{ iconName: 'Inbox' }}
                allowDisabledFocus
                onClick={onOpen}>
                View Nomination
            </ActionButton>
            <Panel
                isOpen={isOpen}
                type={PanelType.custom}
                customWidth={PANEL_WIDTH}
                onDismiss={onClose}
                isLightDismiss
                closeButtonAriaLabel='Cancel'
                {...Object.assign(panelProps, {
                    isFooterAtBottom: true,
                    onRenderFooterContent: (): JSX.Element => {
                        return (
                            <div className={panelStyles.buttonContainer}>
                                <div>
                                    <PrimaryButton
                                        className={panelStyles.oneButton}
                                        onClick={onGoNext}
                                        disabled={!panelProps.enableNext}>
                                        Next
                                    </PrimaryButton>
                                    <DefaultButton
                                        className={panelStyles.oneButton}
                                        onClick={onGoBack}
                                        disabled={!panelProps.enableBack}>
                                        Back
                                    </DefaultButton>
                                </div>
                                <div>
                                    <DefaultButton onClick={onClose}>Cancel</DefaultButton>
                                </div>
                            </div>
                        );
                    },
                })}></Panel>
        </>
    );
}

export const PANEL_WIDTH = '500px';

export const panelStyles = mergeStyleSets({
    buttonContainer: { display: 'flex', justifyContent: 'space-between' },
    oneButton: {
        ':not(:first-child)': { marginLeft: 20 },
        display: 'inline-block',
    },
});
