import { Icon, mergeStyleSets, TextField } from '@fluentui/react';
import { BadgeColorHex } from 'assets/constants/global-colors';
import { IconNames } from 'assets/constants/global-constants';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import GroupClient, { GroupRole, ICreateGroupMembershipRequest } from 'clients/group-client';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { CoreSinglePrincipalRecordPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import { ModalSizeType } from 'components/common/modal';
import Spacer from 'components/common/spacer';
import { useTextField } from 'components/common/use-input/use-textfield';
import DisplayViolationCheck from 'components/groups/groups-common-components/display-violation-record';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useState } from 'react';

interface IMemberDetailsModalActionButtonProps {
    requireSponsor: boolean;
    requireJustification: boolean;
    onAddMemberToTable: () => void;
}

export type PolicyViolationType = {
    isChecking?: boolean;
    violations?: string[];
    errorMsg?: string;
};

function ManageGroupMembersAddNewMemberModalActionButton(
    props: IMemberDetailsModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [principal, setPrincipal] = useState<IPrincipalRecord | undefined>(undefined);
    const [sponsor, setSponsor] = useState<IPrincipalRecord | undefined>(undefined);
    const [justification, setJustification] = useState<string>('');
    const [violationCheck, setViolationCheck] = useState<PolicyViolationType>({});

    const isNotesEnabled =
        groupContext.isAdmin() ||
        groupContext.isManager() ||
        groupContext.isOwner() ||
        authContext.isInRole(Role.GroupAdmin);

    const { value: notes, initialize: initNotes, theElement: notesElement } = useTextField({
        label: 'Notes',
        disabled: !isNotesEnabled,
        placeholder: 'Optional notes not visible to members',
        ariaLabel: 'Optional notes not visible to members',
    });

    const onAddMemberConcluded = (conclusion: ModalConclusion): void => {
        if (conclusion === ModalConclusion.Done) {
            props.onAddMemberToTable();
        }
    };

    // reset state when modal is cancelled or submit is successful
    const resetModalState = (): void => {
        setPrincipal(undefined);
        setSponsor(undefined);
        setJustification('');
        setErrorMessage('');
        initNotes('');
        setViolationCheck({});
    };

    const onHandleSubmit = async (): Promise<void> => {
        const setSponsorId = sponsor !== undefined ? sponsor.id : '';
        if (principal && groupContext.group) {
            const request: ICreateGroupMembershipRequest = {
                personnelId: principal.id,
                groupId: groupContext.group?.id,
                role: GroupRole.MEMBER,
                sponsorId: setSponsorId,
                justification: justification,
                notes: notes,
            };
            try {
                await GroupClient.addGroupMember(authContext, request);
            } catch (error) {
                throw error;
            }
        }
    };

    const onHandleCheckPolicyViolation = async (
        employeeOrSponsor: IPrincipalRecord,
    ): Promise<void> => {
        setViolationCheck({});
        try {
            if (employeeOrSponsor && groupContext.group) {
                setViolationCheck({ isChecking: true });
                const response = await GroupClient.getPolicyViolation(
                    authContext,
                    groupContext.group?.id,
                    employeeOrSponsor.id,
                );
                setViolationCheck({});
                if (response.failed.length > 0) {
                    const foundViolations = response.failed.map(
                        (violation) => violation.errorMessage,
                    );
                    setViolationCheck({ violations: foundViolations });
                }
            }
        } catch (e) {
            setViolationCheck({ errorMsg: 'Error checking policy violations' });
            console.error('Error on policy violation', e);
        }
    };

    const onEmployeeSelect = (record?: IPrincipalRecord): void => {
        setPrincipal(record);
        if (record) {
            onHandleCheckPolicyViolation(record);
        }
    };

    const onchangeJustification = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setJustification(newValue || '');
        },
        [],
    );

    const enableSubmitButton = (): boolean => {
        if (principal === undefined) {
            return false;
        }
        if (props.requireJustification && !justification) {
            return false;
        }
        if (props.requireSponsor && !sponsor) {
            return false;
        }
        return true;
    };

    return (
        <ModalActionButton<void>
            text={'Add Member'}
            size={ModalSizeType.mediumLarge}
            iconName={IconNames.AddFriend}
            modalTitle={'Add Member'}
            enable={
                authContext.isInRole(Role.GroupAdmin) ||
                (!groupContext.isAuditor() && !groupContext.group?.enableDynamic)
            }
            enableSubmit={enableSubmitButton()}
            submitButtonText={'Add'}
            onSubmit={onHandleSubmit}
            errorMsg={errorMessage}
            onButtonClick={resetModalState}
            onModalConcluded={onAddMemberConcluded}>
            <CoreSinglePrincipalRecordPickerTypeaheadSearch
                placeHolder='Employee Name or Alias'
                onChange={onEmployeeSelect}
                label='Employee Name'
                required
                selectedItem={principal}
            />
            <Spacer marginTop={10} />
            {principal !== undefined && <DisplayViolationCheck violationCheck={violationCheck} />}
            <Spacer marginTop={5} />

            <CoreSinglePrincipalRecordPickerTypeaheadSearch
                placeHolder='Employee Name or Alias'
                onChange={setSponsor}
                label='Sponsor Name'
                required={props.requireSponsor}
                selectedItem={sponsor}
            />
            <Spacer marginTop={10} />
            {sponsor !== undefined && (
                <div className={styles.sponsorAliasCheck}>
                    <Icon iconName={IconNames.CheckMark} /> You have selected {sponsor.displayName}{' '}
                    as the sponsor.{' '}
                </div>
            )}

            <TextField
                label='Justification'
                multiline
                resizable={false}
                required={props.requireJustification}
                onChange={onchangeJustification}
            />
            <Spacer marginTop={10} />
            {isNotesEnabled && notesElement()}
            <Spacer marginTop={15} />
        </ModalActionButton>
    );
}

export default ManageGroupMembersAddNewMemberModalActionButton;

const styles = mergeStyleSets({
    sponsorAliasCheck: {
        color: BadgeColorHex.GREEN,
    },
});
