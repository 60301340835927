import React, { ReactNode } from 'react';
import { TableCell } from 'components/common/table';
import { IColumn, mergeStyleSets, Stack, TooltipHost, ActionButton } from '@fluentui/react';
import { lastColumnStylesButton, detailsListStyles } from 'assets/styles/list-styles';
import {
    Dictionary,
    xLargeMaxWidthCoeff,
    maxWidthCoeff,
    IconNames,
} from 'assets/constants/global-constants';
import { IAttribute, IEligibility } from 'clients/eligibility-client';
import { timeToString, TimeFormats } from 'utils/time-utils';
import { ModalMode } from 'components/eligibilities/eligibilities-constants';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import AddEligibilityModalButton from 'components/eligibilities/eligibilities/manage/add-eligibility-modal-button';
import DeleteEligibilityModalButton from 'components/eligibilities/eligibilities/manage/delete-eligibility-modal-button';
import {
    eligibilityRequestUrl,
    viewEligibilityUrl,
} from 'components/eligibilities/eligibilities-breadcrumbs';
import { NavLink } from 'react-router-dom';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { ISortableColumns } from 'utils/sort-utils';

interface IEligibilitysColumns extends ISortableColumns {
    attributes: IAttribute[];
    requestCounts: Dictionary<number>;
    hasEligibilityModify: boolean;
    hasEligibilityDelete: boolean;
    onAddEditEligibilityConcluded: (
        mode: ModalMode,
        modalConclusion: ModalConclusion,
        result?: IEligibility,
    ) => void;
    onDeletetEligibilityConcluded: (
        eligibilty: IEligibility,
        modalConclusion: ModalConclusion,
    ) => void;
}

export function getEligibilitiesTableColumns(params: IEligibilitysColumns): IColumn[] {
    let editButtonWidth = 0;
    let deleteButtonWidth = 0;
    const actionButtonFuncs: ((row: IAttribute) => ReactNode)[] = [];

    const actionsFunc = (row: IEligibility): JSX.Element[] => {
        const actions: JSX.Element[] = [];
        if (params.hasEligibilityModify) {
            editButtonWidth = 65;
            actions.push(
                <div className={styles.buttonContainer} key={`edit-${row.eligibilityCode}`}>
                    <AddEligibilityModalButton
                        mode={ModalMode.Update}
                        attributes={params.attributes}
                        eligibility={row}
                        onAddEditEligibilityConcluded={params.onAddEditEligibilityConcluded}
                    />
                </div>,
            );
        }
        if (params.hasEligibilityDelete) {
            deleteButtonWidth = 80;
            actions.push(
                <div className={styles.buttonContainer} key={`delete-${row.eligibilityCode}`}>
                    <DeleteEligibilityModalButton
                        eligibility={row}
                        onDeletetEligibilityConcluded={params.onDeletetEligibilityConcluded}
                    />
                </div>,
            );
        }
        return actions;
    };
    const actionsCount = actionsFunc({} as IEligibility).length;

    const actionsColumnWidth = editButtonWidth + deleteButtonWidth;

    const columnWidths = {
        code: 120,
        description: 100,
        requests: 50,
        lastModifiedAt: 110,
        lastModifiedBy: 120,
        id: 300,
        action: actionsColumnWidth,
    };

    const columns: IColumn[] = [
        {
            key: 'Code',
            name: 'Code',
            ariaLabel: 'Code',
            minWidth: columnWidths.code,
            maxWidth: columnWidths.code * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === 'Code',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Code');
            },
            onRender: (row: IEligibility): JSX.Element => (
                <TableCell>
                    <NavLink className={styles.navLink} to={viewEligibilityUrl(row)}>
                        <EllipsisTextCss text={row.eligibilityCode} />
                    </NavLink>
                </TableCell>
            ),
        },
        {
            key: 'Description',
            name: 'Description',
            ariaLabel: 'Description',
            minWidth: columnWidths.description,
            maxWidth: columnWidths.description * xLargeMaxWidthCoeff,
            onRender: (row: IEligibility): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisTextCss text={row.eligibilityName} />
                    </TableCell>
                );
            },
        },
        {
            key: 'Requests',
            name: 'Requests',
            ariaLabel: 'Requests',
            minWidth: columnWidths.requests,
            maxWidth: actionsCount > 0 ? columnWidths.requests * xLargeMaxWidthCoeff : undefined,
            onRender: (row: IEligibility): JSX.Element => {
                const count = params.requestCounts[row.id];
                let msg;
                switch (count) {
                    case undefined:
                        msg = '';
                        break;
                    case 0:
                        msg = 'No requests';
                        break;
                    default:
                        msg = `${count} requests`;
                        break;
                }
                return (
                    <TableCell>
                        <NavLink to={eligibilityRequestUrl(row.id)}>{msg}</NavLink>
                    </TableCell>
                );
            },
        },
        {
            key: 'Last Modified At',
            name: 'Last Modified At',
            ariaLabel: 'Last Modified At',
            minWidth: columnWidths.lastModifiedAt,
            maxWidth: columnWidths.lastModifiedAt * maxWidthCoeff,
            isSorted: params.sortColumn === 'Last Modified At',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Last Modified At');
            },
            onRender: (row: IAttribute): JSX.Element => {
                return (
                    <TableCell>
                        {timeToString(row.lastModifiedAt, TimeFormats.MMMDYYYY_hmmssA)}
                    </TableCell>
                );
            },
        },
        {
            key: 'Last Modified By',
            name: 'Last Modified By',
            ariaLabel: 'Last Modified By',
            minWidth: columnWidths.lastModifiedBy,
            maxWidth:
                actionButtonFuncs.length === 0
                    ? undefined
                    : columnWidths.lastModifiedBy * maxWidthCoeff,
            onRender: (row: IAttribute): JSX.Element => {
                return (
                    <TableCell>
                        <EmployeeBasicHoverCard
                            personnelId={row.lastModifiedBy}
                            showMiniCardAlias={false}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: 'Id',
            name: 'Id',
            ariaLabel: 'Id',
            minWidth: columnWidths.id,
            maxWidth: actionsCount > 0 ? undefined : columnWidths.id,
            onRender: (row: IAttribute): JSX.Element => {
                return (
                    <TableCell>
                        <Stack horizontal verticalAlign='center'>
                            <TooltipHost content='Copy to clipboard'>
                                <ActionButton
                                    className={detailsListStyles.icon}
                                    iconProps={{ iconName: IconNames.Copy }}
                                    onClick={(): void => {
                                        navigator.clipboard.writeText(row.id);
                                    }}
                                />
                            </TooltipHost>
                            <span>{row.id}</span>
                        </Stack>
                    </TableCell>
                );
            },
        },
    ];

    if (actionsCount > 0) {
        columns.push({
            key: 'Action',
            name: 'Action',
            ariaLabel: 'Action',
            minWidth: columnWidths.action,
            maxWidth: columnWidths.action,
            ...lastColumnStylesButton,
            onRender: (row: IEligibility): JSX.Element => {
                return <TableCell>{actionsFunc(row)}</TableCell>;
            },
        });
    }

    return columns;
}

const styles = mergeStyleSets({
    buttonContainer: {
        display: 'inline-block',
    },
    navLink: {
        textDecoration: 'none',
    },
});
