import {
    IFacilityRecord,
    IFacilityTimeslotItem,
    IFacilityTimeslots,
    ITimeslotSeatStatus,
} from 'clients/facilities-client';
import moment, { Moment } from 'moment';
import { minutesToHours, TimeFormats } from 'utils/time-utils';

export const DefaultTimeZone = 'UTC';

export function handleUTCToFacilityTimeZone(
    utcMilliseconds: number | undefined,
    facility: IFacilityRecord | undefined,
    format: string,
    undefinedString?: string,
): string {
    const timezone = facility?.timeZone ?? DefaultTimeZone;
    if (utcMilliseconds) {
        return roundMinutes(moment.utc(utcMilliseconds).tz(timezone)).format(format);
    }

    return undefinedString ?? 'Unknown';
}

export function convertToReadableTime(
    startUTCms: number,
    endUTCms: number,
    facility: IFacilityRecord | undefined,
): string {
    const text = `${handleUTCToFacilityTimeZone(
        startUTCms,
        facility,
        TimeFormats.H_mmA,
        '',
    )} - ${handleUTCToFacilityTimeZone(endUTCms, facility, TimeFormats.H_mmA, '')}`;
    return text;
}

const DefaultMinuteRound = 50;
export function roundMinutes(dateTime: Moment): Moment {
    if (dateTime?.get('minutes') > DefaultMinuteRound) {
        return dateTime.clone().add(1, 'hours').set('minutes', 0);
    }

    return dateTime;
}

// This function returns a selectedDate's UTC time used in filtering timeslots/timeslots+seatstatuses
function getDateBounds(
    selectedFacility: IFacilityRecord,
    selectedDate: Date,
): [Moment, Moment, boolean, string] {
    const timezone = selectedFacility?.timeZone ?? DefaultTimeZone;
    const offset = minutesToHours(moment(selectedDate).utc().tz(timezone).utcOffset());
    const currentTime = new Date();
    let isCurrentDate = false;

    if (selectedDate?.toDateString() === currentTime.toDateString()) {
        isCurrentDate = true;
        const startDateEpoch = moment.tz(currentTime, timezone);
        const endDateEpoch = moment.tz(currentTime, timezone).endOf('days');
        return [startDateEpoch, endDateEpoch, isCurrentDate, timezone];
    } else {
        // Calculate startDate and endDate with timezone offset-- otherwise it will lapse backwards into yesterday producing incorrect timeslots.
        const startDateEpoch = moment(selectedDate).startOf('days').utcOffset(offset, true);
        const endDateEpoch = moment(selectedDate).endOf('days').utcOffset(offset, true);
        return [startDateEpoch, endDateEpoch, isCurrentDate, timezone];
    }
}

export function isValidTimeslotSeatStatus(
    selectedFacility: IFacilityRecord,
    selectedDate: Date,
    slot: ITimeslotSeatStatus,
): boolean {
    const [startDateEpoch, endDateEpoch, isCurrentDate, timezone] = getDateBounds(
        selectedFacility,
        selectedDate,
    );

    const start = moment.tz(slot.timeslotItem.startDateTimeUTCMilliseconds, timezone);
    const end = moment.tz(slot.timeslotItem.endDateTimeUTCMilliseconds, timezone);
    return (
        start.isBetween(startDateEpoch, endDateEpoch, null, '[]') ||
        (isCurrentDate && start.isBefore(startDateEpoch) && end.isAfter(startDateEpoch))
    );
}

export function isValidTimeslots(
    selectedFacility: IFacilityRecord,
    selectedDate: Date,
    timeslots: IFacilityTimeslots[],
): IFacilityTimeslotItem[] {
    const [startDateEpoch, endDateEpoch, isCurrentDate, timezone] = getDateBounds(
        selectedFacility,
        selectedDate,
    );

    return timeslots
        .map((x) => x.timeslotItems)
        .flat()
        .filter((x) => {
            const start = moment.tz(x.startDateTimeUTCMilliseconds, timezone);
            const end = moment.tz(x.endDateTimeUTCMilliseconds, timezone);
            return (
                start.isBetween(startDateEpoch, endDateEpoch, null, '[]') ||
                (isCurrentDate && start.isBefore(startDateEpoch) && end.isAfter(startDateEpoch))
            );
        })
        .sort((a, b) => a.startDateTimeUTCMilliseconds - b.startDateTimeUTCMilliseconds);
}

export function convertToDateOnlyInTimezone(date: Date, timeZone: string): Moment {
    // Pass true to utcOffset to ensure we don't change the date as we shift associated timezone
    return moment(date)
        .startOf('days')
        .utcOffset(moment(date).utc().tz(timeZone).utcOffset(), true);
}
