import React from 'react';
import { TableCell } from 'components/common/table';
import { IColumn } from '@fluentui/react';
import { xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { TimeFormats, timeToString } from 'utils/time-utils';
import { IAttributeAssignmentDetail } from 'clients/eligibility-client';
import { IEmployee } from 'clients/employee-client';
import DeleteAttributeModalButton from 'components/user-assignments/manage-attributes/delete-attribute-modal-action-button';
import { IVisitorProfile } from 'clients/visitor-client';
import { ISortableColumns } from 'utils/sort-utils';

interface IAttributesTableColumnsParams extends ISortableColumns {
    visitorOrEmployee: IEmployee | IVisitorProfile;
    showDeleteAttributeButton: boolean;
    deleteAttributeFromTable: (attribute: IAttributeAssignmentDetail) => void;
}

export function tableColumnsAttributes(params: IAttributesTableColumnsParams): IColumn[] {
    const showActionColumn = params.showDeleteAttributeButton;
    const columnWidths = {
        code: 100,
        description: 100,
        starts: 100,
        expires: 100,
        grantedOn: 100,
        action: 80,
    };

    const columns: IColumn[] = [
        {
            key: 'Code',
            name: 'Code',
            ariaLabel: 'Code',
            isSorted: params.sortColumn === 'Code',
            isSortedDescending: !params.sortAscending,
            minWidth: columnWidths.code,
            maxWidth: columnWidths.code * xLargeMaxWidthCoeff,
            onColumnClick: (): void => {
                params.sortColumnHandler('Code');
            },
            onRender: (row: IAttributeAssignmentDetail): JSX.Element => (
                <TableCell>
                    <EllipsisTextCss text={row.attributeCode} />
                </TableCell>
            ),
        },
        {
            key: 'Description',
            name: 'Description',
            ariaLabel: 'Description',
            isSorted: params.sortColumn === 'Description',
            isSortedDescending: !params.sortAscending,
            minWidth: columnWidths.description,
            maxWidth: columnWidths.description * xLargeMaxWidthCoeff,
            onColumnClick: (): void => {
                params.sortColumnHandler('Description');
            },
            onRender: (row: IAttributeAssignmentDetail): JSX.Element => (
                <TableCell>
                    <EllipsisTextCss text={row.attributeDesc} />
                </TableCell>
            ),
        },
        {
            key: 'Starts',
            name: 'Starts',
            ariaLabel: 'Starts',
            isSorted: params.sortColumn === 'Starts',
            isSortedDescending: !params.sortAscending,
            minWidth: columnWidths.starts,
            maxWidth: columnWidths.starts * xLargeMaxWidthCoeff,
            onColumnClick: (): void => {
                params.sortColumnHandler('Starts');
            },
            onRender: (row: IAttributeAssignmentDetail): JSX.Element => (
                <TableCell>{timeToString(row.startDate, TimeFormats.MMMDDYYYY)}</TableCell>
            ),
        },
        {
            key: 'Expires',
            name: 'Expires',
            ariaLabel: 'Expires',
            isSorted: params.sortColumn === 'Expires',
            isSortedDescending: !params.sortAscending,
            minWidth: columnWidths.expires,
            maxWidth: showActionColumn ? columnWidths.expires * xLargeMaxWidthCoeff : undefined,
            onColumnClick: (): void => {
                params.sortColumnHandler('Expires');
            },
            onRender: (row: IAttributeAssignmentDetail): JSX.Element => (
                <TableCell>{timeToString(row.endDate, TimeFormats.MMMDDYYYY)}</TableCell>
            ),
        },
        {
            key: 'Granted on',
            name: 'Granted on',
            ariaLabel: 'Granted on',
            isSorted: params.sortColumn === 'Granted on',
            isSortedDescending: !params.sortAscending,
            minWidth: columnWidths.grantedOn,
            maxWidth: showActionColumn ? undefined : columnWidths.grantedOn,
            onColumnClick: (): void => {
                params.sortColumnHandler('Granted on');
            },
            onRender: (row: IAttributeAssignmentDetail): JSX.Element => (
                <TableCell>{timeToString(row.assignedAt, TimeFormats.MMMDDYYYY)}</TableCell>
            ),
        },
    ];

    if (showActionColumn) {
        columns.push({
            key: 'Action',
            name: 'Action',
            ariaLabel: 'Action',
            minWidth: columnWidths.action,
            maxWidth: columnWidths.action,
            onRender: (row: IAttributeAssignmentDetail): JSX.Element => (
                <TableCell>
                    {params.showDeleteAttributeButton && (
                        <DeleteAttributeModalButton
                            key={`delete-${row.attributeCode}`}
                            attribute={row}
                            deleteAttributeFromTable={params.deleteAttributeFromTable}
                            visitorOrEmployee={params.visitorOrEmployee}
                            personnelId={params.visitorOrEmployee?.id}
                        />
                    )}
                </TableCell>
            ),
        });
    }

    return columns;
}
