import config from 'environments/environment';
import { GetAadHttpOptions, GetDefaultHttpOptions } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import { getDate } from 'utils/time-utils';
import ClientUtils from 'clients/client-utils';
import { Dictionary } from 'assets/constants/global-constants';
import { IChoiceGroupOption } from '@fluentui/react';

const nominationServiceConfig = config.nominationServiceConfig;
const employeeServiceConfig = config.employeeServiceConfig;

export default class ReportsClient {
    static get screeningBaseUrl(): string {
        return `${nominationServiceConfig.baseUrl}${nominationServiceConfig.customerReportEndpoint}`;
    }

    static get employeeBaseUrl(): string {
        return `${employeeServiceConfig.baseUrl}${employeeServiceConfig.prehireReportEndpoint}`;
    }

    static get cloudReadinessBaseUrl(): string {
        return `${nominationServiceConfig.baseUrl}${nominationServiceConfig.cloudReadinessReportEndpoint}`;
    }

    static async getCustomerReport(
        authContext: IAuthContext,
        reportType: CustomerReportType = CustomerReportType.Aggregate,
        reportDate?: Date | null,
    ): Promise<ICustomerReport> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${ReportsClient.screeningBaseUrl}/${reportType}?reportDate=${
            reportDate ? reportDate.getTime() : getDate().Today().getTime()
        }`;
        return ClientUtils.doHttpRequest<ICustomerReport>(url, httpOptions);
    }

    // it will return a object with "personnelIds" as the keys and "IHierarchy" as the value.
    // which will contain the personnelId and alias of the bosses, and their bosses and so forth until CEO
    // in the Aggregate report list ie all the data in the downloads report info of the screening manage table
    static async getEmployeeHierarchyData(
        authContext: IAuthContext,
    ): Promise<Dictionary<IHierarchy>> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${ReportsClient.screeningBaseUrl}/hierarchyReport`;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else if (result.status === 404) {
            throw 'Could not get Hierarchy Report of Employees in Aggregate Report';
        } else {
            throw result;
        }
    }

    // this is passed a array of personnel Ids and similar to getEmployeeHierarchyData
    // it will return a object with "personnelIds" as the keys and "IHierarchy" as the value.
    // which will contain the personnelId and alias of the bosses, and their bosses and so forth until CEO
    static async getSelectHierarchy(
        authContext: IAuthContext,
        personnelIds: string[],
    ): Promise<Dictionary<IHierarchy>> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(personnelIds);
        const url = `${ReportsClient.screeningBaseUrl}/hierarchyReport`;

        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else if (result.status === 404) {
            throw 'Could not get Hierarchy Report of selected Employee Personnel ID';
        } else {
            throw result;
        }
    }
    // eslint-disable-next-line @typescript-eslint/ban-types
    static async getPrehireReport(authContext: IAuthContext): Promise<object[]> {
        const httpOptions = await GetAadHttpOptions(authContext, employeeServiceConfig.aadScopes);
        const url = `${ReportsClient.employeeBaseUrl}`;
        // eslint-disable-next-line @typescript-eslint/ban-types
        return ClientUtils.doHttpRequest<object[]>(url, httpOptions);
    }

    static async getCloudReadinessReport(
        authContext: IAuthContext,
        reportOption: IChoiceGroupOption,
    ): Promise<string | void> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${ReportsClient.cloudReadinessBaseUrl}/${reportOption.key}`;
        const response = await fetch(url, httpOptions);
        switch (response.status) {
            case 204:
                return;
            case 200:
                try {
                    return await response.text();
                } catch (e) {
                    console.error('Error getting report data:', e);
                    throw e;
                }
            default:
                throw response;
        }
    }
}

export enum CustomerReportType {
    Aggregate = 'Aggregate',
    Count = 'Count',
    Prehire = 'Prehire',
}

export enum CloudReadinessReportType {
    CloudReadiness = 'Cloud readiness',
}

// Values below match valid values of parameter "reportType" of endpoint "readinessReport/{reportType}" in screening controller.
// The corresponding enum in screening service is ReadinessReportType.
export type CloudReadinessReportKeyType =
    | 'MyCloudReadiness'
    | 'MyOrgCloudReadiness'
    | 'MyContractsCloudReadiness';

export const CloudReadinessReport: Record<
    CloudReadinessReportKeyType,
    { key: CloudReadinessReportKeyType; text: string; filenamePrefix: string }
> = {
    MyCloudReadiness: {
        key: 'MyCloudReadiness',
        text: 'My readiness',
        filenamePrefix: 'CloudReadiness-MyReadiness',
    },
    MyOrgCloudReadiness: {
        key: 'MyOrgCloudReadiness',
        text: 'My org',
        filenamePrefix: 'CloudReadiness-MyOrg',
    },
    MyContractsCloudReadiness: {
        key: 'MyContractsCloudReadiness',
        text: 'My contracts',
        filenamePrefix: 'CloudReadiness-MyContracts',
    },
};

export interface ICustomerReport {
    results: ICustomerReportRecord[];
    continuationToken: string;
}

export interface ICustomerReportRecord {
    createdTime: string;
    forDate: string;
    id: string;
    reportType: string;
    aggregateReportRecords: IAggregateReportRecord[];
}

export function getReportToFromHierarchy(employeeHierarchy: IHierarchy): string | undefined {
    return [
        employeeHierarchy.hierarchyLvl7Alias,
        employeeHierarchy.hierarchyLvl6Alias,
        employeeHierarchy.hierarchyLvl5Alias,
        employeeHierarchy.hierarchyLvl4Alias,
        employeeHierarchy.hierarchyLvl3Alias,
        employeeHierarchy.hierarchyLvl2Alias,
        employeeHierarchy.hierarchyLvl1Alias,
    ].find((item) => !!item);
}

export interface IHierarchy {
    hierarchyLvl1?: string;
    hierarchyLvl2?: string;
    hierarchyLvl3?: string;
    hierarchyLvl4?: string;
    hierarchyLvl5?: string;
    hierarchyLvl6?: string;
    hierarchyLvl7?: string;
    hierarchyLvl1Alias?: string;
    hierarchyLvl2Alias?: string;
    hierarchyLvl3Alias?: string;
    hierarchyLvl4Alias?: string;
    hierarchyLvl5Alias?: string;
    hierarchyLvl6Alias?: string;
    hierarchyLvl7Alias?: string;
    alias?: string;
}

export interface IAggregateReportRecord {
    personnelId: string;
    personnelAlias: string;
    personnelLastName: string;
    personnelFirstName: string;
    personnelMiddleName: string;
    reportsTo: string;
    clearanceLevel: string;
    requestType: string;
    contractId: string;
    projectName: string;
    contractCustomer: string;
    nominatedBy: string;
    requestedDcoumentsCount: number;
    uploadedDocumentsCount: number;
    updateRequestedDocumentsCount: number;
    isPendingDocumentRequest: boolean;
    isPendingDocumentUpdate: boolean;
    lockedDocumentsCount: number;
    areAllDocumentsLocked: boolean;
    screeningId: string;
    processOwnerAlias: string;
    currentMainStatus: string;
    currentSubStatus: string;
    adjudicationResult: string;
    nominationDate?: string;
    preparationDate?: string;
    paperworkRequestedDate?: string;
    paperworkCompleteDate?: string;
    submittedDate?: string;
    adjudicatedDate?: string;
    indocStatusDate?: string;
    completedDate?: string;
    withdrawnDate?: string;
    nominationDateUTCMilliseconds: number;
    preparationDateUTCMilliseconds: number;
    paperworkRequestedDateUTCMilliseconds: number;
    paperworkCompleteDateUTCMilliseconds: number;
    submittedDateUTCMilliseconds: number;
    adjudicatedDateUTCMilliseconds: number;
    indocStatusDateUTCMilliseconds: number;
    completedDateUTCMilliseconds: number;
    withdrawnDateUTCMilliseconds: number;
    nominationDaysCount: number;
    preparationDaysCount: number;
    submittedDaysCount: number;
    adjudicatedDaysCount: number;
    indocAndBriefingDaysCount: number;
    nomineeOfficeLocation: string;
    hierarchyLv1: string;
    hierarchyLv2: string;
    hierarchyLv3: string;
    hierarchyLv4: string;
    hierarchyLv5: string;
    hierarchyLv6: string;
    hierarchyLv7: string;
    url: string;
}

export enum hierarchyLvlsAlias {
    L1 = 'hierarchyLvl1Alias',
    L2 = 'hierarchyLvl2Alias',
    L3 = 'hierarchyLvl3Alias',
    L4 = 'hierarchyLvl4Alias',
    L5 = 'hierarchyLvl5Alias',
    L6 = 'hierarchyLvl6Alias',
    L7 = 'hierarchyLvl7Alias',
}
