import React from 'react';
import { globalStyles } from 'assets/styles/global-styles';
import { Stack, mergeStyles, mergeStyleSets, ActionButton, TooltipHost } from '@fluentui/react';
import { IAttribute, IEligibility } from 'clients/eligibility-client';
import { IconNames, Dictionary } from 'assets/constants/global-constants';
import { timeToString, TimeFormats } from 'utils/time-utils';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import ButtonBar from 'components/common/button-bar';
import AddEligibilityModalButton from 'components/eligibilities/eligibilities/manage/add-eligibility-modal-button';
import { ModalMode } from 'components/eligibilities/eligibilities-constants';
import CheckRole from 'components/common/check-role';
import { Role } from 'configs/roles';
import { Location } from 'history';
import { renderRequiredAttributes } from 'components/eligibilities/eligibilities-utils';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { eligAccessRequestAbsoluteURL } from 'components/eligibilities/eligibilities-breadcrumbs';
import { useFeatureFlag, FeatureFlagKeys } from 'utils/use-feature-flags';

interface IPeriodDetailsPros {
    location: Location;
    attributes: IAttribute[];
    eligibility: IEligibility | undefined;
    attributesDict: Dictionary<IAttribute>;
    onAddEditEligibilityConcluded: (
        mode: ModalMode,
        modalConclusion: ModalConclusion,
        result?: IEligibility,
    ) => void;
}

export default function DisplayEligibilityDetails(props: IPeriodDetailsPros): JSX.Element {
    const canAutoAssignNewEligibility = useFeatureFlag(FeatureFlagKeys.eligibilityAutoAssign)
        .enabled;
    const renderLocation = (): JSX.Element => {
        if (props?.eligibility?.id) {
            const requestLink = eligAccessRequestAbsoluteURL(props.eligibility.id);
            return (
                <Stack horizontal>
                    <Stack.Item>
                        <TooltipHost content='Copy link'>
                            <ActionButton
                                className={styles.linkCopyButton}
                                iconProps={{ iconName: IconNames.Copy }}
                                onClick={(): void => {
                                    navigator.clipboard.writeText(requestLink);
                                }}
                            />
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item>{requestLink}</Stack.Item>
                </Stack>
            );
        } else {
            return <span>Link Not Available</span>;
        }
    };

    const yesOrNo = (value?: boolean): string => {
        if (value === undefined) return '';
        else return value ? 'Yes' : 'No';
    };

    const renderEmployeeCard = (personnelId?: string): JSX.Element => {
        if (!personnelId) return <></>;
        return <EmployeeBasicHoverCard personnelId={personnelId} showMiniCardAlias={false} />;
    };

    const oneTableRow = (
        itemName: string | JSX.Element,
        item: JSX.Element | string | undefined,
    ): JSX.Element => {
        return (
            <Stack.Item>
                <Stack horizontal className={styles.tableStack} tokens={{ childrenGap: 10 }}>
                    <Stack.Item className={styles.itemNameColumn}>{itemName}</Stack.Item>
                    <Stack.Item className={styles.itemValueColumn}>{item}</Stack.Item>
                </Stack>
            </Stack.Item>
        );
    };

    return (
        <Stack>
            <CheckRole requiredRolesAny={[Role.EligibilitiesModify]}>
                <Stack.Item>
                    <ButtonBar>
                        <AddEligibilityModalButton
                            mode={ModalMode.Update}
                            buttonText='Edit Eligibility'
                            attributes={props.attributes}
                            eligibility={props.eligibility}
                            onAddEditEligibilityConcluded={props.onAddEditEligibilityConcluded}
                        />
                    </ButtonBar>
                </Stack.Item>
            </CheckRole>
            <Stack.Item>
                <Stack className={styles.reviewDetails}>
                    {oneTableRow(<strong>Property</strong>, <strong>Value</strong>)}
                    {oneTableRow('Code', props.eligibility?.eligibilityCode)}
                    {oneTableRow('Description', props.eligibility?.eligibilityName)}
                    {oneTableRow(
                        'Required Attributes',
                        renderRequiredAttributes(
                            props.eligibility?.requiredAttributes,
                            props.attributesDict,
                        ),
                    )}
                    {canAutoAssignNewEligibility &&
                        oneTableRow('Auto Assign', yesOrNo(props.eligibility?.autoAssign))}
                    {oneTableRow('User Can Request', yesOrNo(props.eligibility?.userCanRequest))}
                    {oneTableRow(
                        'Auto Provision Requests',
                        yesOrNo(props.eligibility?.autoProvisionRequest),
                    )}
                    {props.eligibility?.userCanRequest &&
                        oneTableRow('Link to Request this Eligibility', renderLocation())}
                    {oneTableRow(
                        'Created At',
                        timeToString(props.eligibility?.createdAt, TimeFormats.MMMDYYYY_hmmssA),
                    )}
                    {oneTableRow('Created By', renderEmployeeCard(props.eligibility?.createdBy))}
                    {oneTableRow(
                        'Last Modified At',
                        timeToString(
                            props.eligibility?.lastModifiedAt,
                            TimeFormats.MMMDYYYY_hmmssA,
                        ),
                    )}
                    {oneTableRow(
                        'Last Modified By',
                        renderEmployeeCard(props.eligibility?.lastModifiedBy),
                    )}
                </Stack>
            </Stack.Item>
        </Stack>
    );
}

const styles = mergeStyleSets({
    tableStack: {
        alignItems: 'center',
        lineHeight: 35,
    },
    linkCopyButton: {
        marginTop: -2,
        marginLeft: -7,
    },
    itemNameColumn: mergeStyles(
        {
            width: 250,
            flexShrink: 0,
            alignContent: 'flex-start',
        },
        globalStyles.boldFont,
    ),
    itemValueColumn: {
        minWidth: 400,
        alignContent: 'flex-start',
    },
    reviewDetails: {
        marginTop: 10,
    },
});
