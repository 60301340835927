import { IDocumentRecord, IDocumentToken } from 'clients/documents-client';
import React from 'react';
import { IEmployeeWithEditableData, IEmployeeEditableResponse } from 'clients/employee-client';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import { IColumn } from '@fluentui/react';
import { commonPersonnelPersonnelStyles } from 'components/personnel-profile/common-personnel-profile-styles';
import { Table, TableCell } from 'components/common/table';
import {
    firstColumnTableCellPadding,
    firstColumnTitlePadding,
} from 'components/personnel-profile/common/common-constants';
import { dateToLocalDate } from 'utils/time-utils';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { EmployeeNameResolverId } from 'components/common/employee/employee-name-resolver';
import DocumentProfileDeleteModal from 'components/personnel-profile/documents-profile/documents-profile-delete-modal';
import DocumentProfileAddModal from 'components/personnel-profile/documents-profile/documents-profile-add-modal';
import ViewUploadedDocumentModal from 'components/screening/common/documents/modals/view-document-modal';
import { AssociationType, AssociationValue } from 'clients/template-client';
import { convertIDocumentRecordToIUnifiedDocumentRecord } from 'components/screening/common/documents/document-common';
import { getDefaultColumnWidths } from 'utils/table-utils';

export interface PersonnelProfileUsGovDocumentsTableProps {
    employee: IEmployeeWithEditableData | undefined;
    editableEmployee?: IEmployeeEditableResponse;
    documents: IDocumentRecord[];
    isFetchingData: boolean;
    associatedUserToken: IDocumentToken;
    onChangeDeleteNote: React.Dispatch<React.SetStateAction<string>>;
    onChangeUploadNote: React.Dispatch<React.SetStateAction<string>>;
    setDocumentIdWorkaround: React.Dispatch<React.SetStateAction<string>>;
}

export default function PersonnelProfileUsGovDocumentTable(
    props: PersonnelProfileUsGovDocumentsTableProps,
): JSX.Element {
    const columnWidths = getDefaultColumnWidths(6, false);
    // TODO consider separting
    const columns: IColumn[] = [
        {
            key: 'type',
            name: 'Type',
            ariaLabel: 'Type',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            styles: { cellTitle: { paddingLeft: firstColumnTitlePadding } }, // DetailsList applies this to the header cell.
            onRender: (row: IDocumentRecord): JSX.Element => (
                <TableCell style={{ paddingLeft: firstColumnTableCellPadding }}>
                    {row.documentType}
                </TableCell>
            ),
        },
        {
            key: 'title',
            name: 'Title',
            ariaLabel: 'Title',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: IDocumentRecord): JSX.Element => (
                <TableCell>{row.documentTitle || row.fileName}</TableCell>
            ),
        },
        {
            key: 'uploaded_by',
            name: 'Uploaded by',
            ariaLabel: 'Uploaded By',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            data: 'string',
            onRender: (row: IDocumentRecord): JSX.Element => {
                return (
                    <>
                        {row.authorPersonnelId && (
                            <TableCell>
                                <EmployeeHoverCard
                                    key={`authorId-${row.authorPersonnelId}`}
                                    personnelId={row.authorPersonnelId}>
                                    <span>
                                        <EmployeeNameResolverId
                                            personnelId={row.authorPersonnelId}
                                        />
                                    </span>
                                </EmployeeHoverCard>
                            </TableCell>
                        )}
                    </>
                );
            },
        },
        {
            key: 'uploaded_on',
            name: 'Uploaded on',
            ariaLabel: 'Uploaded On',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: IDocumentRecord): JSX.Element => (
                <TableCell>{dateToLocalDate(row.createdDate * 1000)}</TableCell>
            ),
        },
        {
            key: 'documents_view',
            name: '',
            ariaLabel: 'view',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: IDocumentRecord): JSX.Element => (
                <TableCell key={row.id}>
                    <ViewUploadedDocumentModal
                        documentMetadata={row.metadata}
                        documentRecord={convertIDocumentRecordToIUnifiedDocumentRecord(row)}
                        isRequestDocument={false}
                        isUserCandidate
                    />
                </TableCell>
            ),
        },
        {
            key: 'documents_delete',
            name: '',
            ariaLabel: 'delete',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: IDocumentRecord): JSX.Element => (
                <TableCell key={row.id}>
                    <DocumentProfileDeleteModal
                        associatedUserToken={props.associatedUserToken}
                        documentFileName={row.fileName}
                        documentId={row.id}
                        editableEmployeeId={props.editableEmployee?.id}
                        employeeId={props.employee?.data?.id}
                        isScreeningDetails={false}
                        onChangeDeleteNote={props.onChangeDeleteNote}
                        setDocumentIdWorkaround={props.setDocumentIdWorkaround}
                    />
                </TableCell>
            ),
        },
    ];

    return (
        <>
            {props.employee && (
                <ContainerWithEtiquettes
                    leftEtiquetteLabel='Documents'
                    bottomInfo={
                        <>
                            {
                                <DocumentProfileAddModal
                                    employeeId={props.employee.data.id}
                                    editableEmployeeId={props.editableEmployee?.id}
                                    onChangeUploadNote={props.onChangeUploadNote}
                                    associationType={AssociationType.Person}
                                    associationValue={AssociationValue.USGovProfile}
                                    isAdhocScreeningDocument={false}
                                    setDocumentIdWorkaround={props.setDocumentIdWorkaround}
                                />
                            }
                        </>
                    }>
                    {!props.documents?.length && !props.isFetchingData ? (
                        <p className={commonPersonnelPersonnelStyles.noDataParagraphMorePadding}>
                            No documents to display
                        </p>
                    ) : (
                        <Table<IDocumentRecord>
                            rows={props.documents.filter((x) => x.status === 'ACTIVE')}
                            isFetchingData={props.isFetchingData}
                            shimmerLines={2}
                            tableColumns={columns}
                            tableName='Documents'
                        />
                    )}
                </ContainerWithEtiquettes>
            )}
        </>
    );
}
