import {
    AttributePredefinedValue,
    AttributePredefinedValueStatus,
    GetAttributeSetByIdResult,
    GetAttributesByAttributeSetResult,
    GetPrincipalAssignmentsResult,
} from 'personnel-core-clients';
import { MAX_DATE_IN_UNIX_SECONDS, utcSecondsToIsoDateString } from 'utils/time-utils';
import GraphClient, { IGraphPrincipal, isGraphServicePrincipal } from 'clients/graph-client';
import { IAuthContext } from 'contexts/auth-context';
import { SystemDisplayName, SystemGuid } from 'utils/cor-utils';

export type AttributeSetReportData = {
    id: string;
    name: string;
    description: string;
    ownerDisplayName: string;
    ownerUPN: string;
    managerDisplayName: string;
    managerUPN: string;
    readerDisplayName: string;
    readerUPN: string;
    lastModifiedDate: string;
    lastModifiedByDisplayName: string;
    lastModifiedByUPN: string;
};

export type AttributeReportData = {
    attributeSetId: string;
    id: string;
    name: string;
    description: string;
    dataType: string;
    visibilityLevel: string;
    predefinedValues: string;
    csaSyncStatus: string;
    autoAssignedDataSource: string;
    autoAssignedField: string;
    ownerDisplayName: string;
    ownerUPN: string;
    managerDisplayName: string;
    managerUPN: string;
    readerDisplayName: string;
    readerUPN: string;
    lastModifiedDate: string;
    lastModifiedByDisplayName: string;
    lastModifiedByUPN: string;
};

export type UserAssignmentsReportData = {
    attributeSetId: string;
    attributeId: string;
    attributeName: string;
    attributeDescription: string;
    attributeDataType: string;
    value: string;
    expirationDate: string;
    lastModifiedDate: string;
    lastModifiedByDisplayName: string;
    lastModifiedByUPN: string;
};

export async function getAttributeSetReportData(
    authContext: IAuthContext,
    attributeSets: GetAttributeSetByIdResult[],
): Promise<AttributeSetReportData[]> {
    const oids = [
        ...new Set(
            attributeSets.flatMap((attributeSet) => [
                ...attributeSet.owners,
                ...attributeSet.managers,
                ...attributeSet.readers,
                ...[attributeSet.lastModifiedBy],
            ]),
        ),
    ];

    const graphPrincipals = await GraphClient.getGraphPrincipalsByOids(authContext, oids);

    // Convert the array of principals to a map for easier lookup
    const principalMap = new Map<string, IGraphPrincipal>();
    graphPrincipals.forEach((principal) => {
        principalMap.set(principal.id, principal);
    });

    return attributeSets.map((attributeSet) => ({
        id: attributeSet.id,
        name: attributeSet.name,
        description: attributeSet.description,
        ownerDisplayName: getDisplayNameString(attributeSet.owners, principalMap),
        ownerUPN: getUPNString(attributeSet.owners, principalMap),
        managerDisplayName: getDisplayNameString(attributeSet.managers, principalMap),
        managerUPN: getUPNString(attributeSet.managers, principalMap),
        readerDisplayName: getDisplayNameString(attributeSet.readers, principalMap),
        readerUPN: getUPNString(attributeSet.readers, principalMap),
        lastModifiedDate: utcSecondsToIsoDateString(attributeSet.lastModifiedDate) || '',
        lastModifiedByDisplayName: getDisplayNameString(
            [attributeSet.lastModifiedBy],
            principalMap,
        ),
        lastModifiedByUPN: getUPNString([attributeSet.lastModifiedBy], principalMap),
    }));
}

export async function getAttributeReportData(
    authContext: IAuthContext,
    attributeSet: GetAttributeSetByIdResult,
    attributes: GetAttributesByAttributeSetResult[],
): Promise<AttributeReportData[]> {
    const oids = [
        ...new Set([
            ...attributes.flatMap((attribute) => [
                ...attribute.owners,
                ...attribute.managers,
                ...attribute.readers,
                ...[attribute.lastModifiedBy],
            ]),
            ...attributeSet.owners,
            ...attributeSet.managers,
            ...attributeSet.readers,
        ]),
    ];

    const graphPrincipals = await GraphClient.getGraphPrincipalsByOids(authContext, oids);

    // Convert the array of principals to a map for easier lookup
    const principalMap = new Map<string, IGraphPrincipal>();
    graphPrincipals.forEach((principal) => {
        principalMap.set(principal.id, principal);
    });

    return attributes.map((attribute) => {
        const owners = [...new Set([...attributeSet.owners, ...attribute.owners])];
        const managers = [...new Set([...attributeSet.managers, ...attribute.managers])];
        const readers = [...new Set([...attributeSet.readers, ...attribute.readers])];

        return {
            attributeSetId: attribute.attributeSetId,
            id: attribute.id,
            name: attribute.name,
            description: attribute.description,
            dataType: String(attribute.dataType),
            visibilityLevel: String(attribute.visibilityLevel),
            predefinedValues: getPredefinedValuesString(attribute.predefinedValues),
            csaSyncStatus: String(attribute.csaSyncStatus.status),
            autoAssignedDataSource: attribute.autoAssignedDataSource || '',
            autoAssignedField: attribute.autoAssignedField || '',
            ownerDisplayName: getDisplayNameString(owners, principalMap),
            ownerUPN: getUPNString(owners, principalMap),
            managerDisplayName: getDisplayNameString(managers, principalMap),
            managerUPN: getUPNString(managers, principalMap),
            readerDisplayName: getDisplayNameString(readers, principalMap),
            readerUPN: getUPNString(readers, principalMap),
            lastModifiedDate: utcSecondsToIsoDateString(attribute.lastModifiedDate) || '',
            lastModifiedByDisplayName: getDisplayNameString(
                [attribute.lastModifiedBy],
                principalMap,
            ),
            lastModifiedByUPN: getUPNString([attribute.lastModifiedBy], principalMap),
        };
    });
}

export async function getUserAssignmentsReportData(
    authContext: IAuthContext,
    userAssignments: GetPrincipalAssignmentsResult[],
): Promise<UserAssignmentsReportData[]> {
    const oids = [...new Set(userAssignments.map((assignment) => assignment.lastModifiedBy))];

    const graphPrincipals = await GraphClient.getGraphPrincipalsByOids(authContext, oids);

    // Convert the array of principals to a map for easier lookup
    const principalMap = new Map<string, IGraphPrincipal>();
    graphPrincipals.forEach((principal) => {
        principalMap.set(principal.id, principal);
    });

    return userAssignments.map((assignment) => ({
        attributeSetId: assignment.attributeSetId || '',
        attributeId: assignment.attributeId,
        attributeName: assignment.attributeName || '',
        attributeDescription: assignment.attributeDescription || '',
        attributeDataType: String(assignment.attributeDataType),
        value: Array.isArray(assignment.value)
            ? assignment.value.join(',')
            : String(assignment.value),
        expirationDate:
            assignment.expirationDate >= MAX_DATE_IN_UNIX_SECONDS
                ? ''
                : utcSecondsToIsoDateString(assignment.expirationDate) || '',
        lastModifiedDate: utcSecondsToIsoDateString(assignment.lastModifiedDate) || '',
        lastModifiedByDisplayName: getDisplayNameString([assignment.lastModifiedBy], principalMap),
        lastModifiedByUPN: getUPNString([assignment.lastModifiedBy], principalMap),
    }));
}

function getDisplayNameString(
    principalIds: string[],
    principalMap: Map<string, IGraphPrincipal>,
): string {
    return principalIds
        .map((id) => {
            if (id === SystemGuid) {
                return SystemDisplayName;
            }

            return principalMap.get(id)?.displayName || id;
        })
        .join(', ');
}

function getUPNString(principalIds: string[], principalMap: Map<string, IGraphPrincipal>): string {
    return principalIds
        .map((id) => {
            if (id === SystemGuid) {
                return SystemDisplayName;
            }

            const principal = principalMap.get(id);

            if (principal === undefined || isGraphServicePrincipal(principal)) {
                return id;
            }

            return principal.userPrincipalName;
        })
        .join(', ');
}

function getPredefinedValuesString(predefinedValues?: AttributePredefinedValue[]): string {
    if (predefinedValues === undefined) {
        return '';
    }

    return predefinedValues
        .filter((value) => value.status === AttributePredefinedValueStatus.Active)
        .map((value) => value.value)
        .join(', ');
}
