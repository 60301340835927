import React, { ReactNode } from 'react';

type FocusContainerProps = {
    onLoseFocus: () => void;
    onGainFocus?: () => void;
    children: JSX.Element | ReactNode | JSX.Element[] | ReactNode[];
};
export const FocusContainer = (props: FocusContainerProps) => {
    const { onLoseFocus, onGainFocus } = props;
    return (
        <div
            tabIndex={-1}
            onBlur={(e: any) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    onLoseFocus();
                }
            }}
            onFocus={(e: any) => {
                if (!e.currentTarget.contains(e.relatedTarget)) {
                    if (onGainFocus) {
                        onGainFocus();
                    }
                }
            }}>
            {props.children}
        </div>
    );
};
