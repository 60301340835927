import React, { useContext } from 'react';
import {
    useDetermineImage,
    fetchPictureBase,
    getDisplayNameOrDefault,
} from 'components/common/employee/employee-utils';
import { AuthContext } from 'contexts/auth-context';
import { CacheContext } from 'contexts/cache-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
// This common file has been updated to handle UserContext not always being present
// eslint-disable-next-line no-restricted-imports
import { IUserContext, UserContext } from 'contexts/user-context';
import {
    Callout,
    DirectionalHint,
    Persona,
    PersonaInitialsColor,
    PersonaSize,
    mergeStyleSets,
} from '@fluentui/react';
import ProfileCardContent from 'components/common/page-shell/feedback-notifications-profile/profile/profile-card-content';

type ProfileCalloutProps = {
    isProfileCalloutOpen: boolean;
    setIsProfileCalloutOpen: (isProfileCalloutOpen: boolean) => void;
    setIsFeedbackPanelOpen: (isFeedbackPanelOpen: boolean) => void;
};

export default function ProfileCallout({
    isProfileCalloutOpen,
    setIsProfileCalloutOpen,
    setIsFeedbackPanelOpen,
}: ProfileCalloutProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext) as IUserContext | null;
    const principalUserContext = useContext(PrincipalUserContext);
    const cacheContext = useContext(CacheContext);

    const profileCalloutIconId = 'profileCalloutIconId';
    const image = useDetermineImage({
        cacheContext: cacheContext,
        authContext: authContext,
        employee: principalUserContext?.principalRecord || userContext?.employeeRecord,
    });

    const onProfileIconClicked = (): void => {
        setIsProfileCalloutOpen(!isProfileCalloutOpen);
    };

    const profileName = getDisplayNameOrDefault(
        principalUserContext?.principalRecord || userContext?.employeeRecord,
        '',
    );

    return (
        <>
            {image && image !== fetchPictureBase ? (
                <Persona
                    hidePersonaDetails
                    id={profileCalloutIconId}
                    coinSize={32}
                    imageUrl={image}
                    className={styles.clickablePersonaIcon}
                    onClick={onProfileIconClicked}
                />
            ) : (
                <Persona
                    initialsColor={PersonaInitialsColor.coolGray}
                    hidePersonaDetails
                    id={profileCalloutIconId}
                    size={PersonaSize.size32}
                    className={styles.clickablePersonaIcon}
                    imageInitials={profileName.replace(/[^A-Z]+/g, '') ?? ''}
                    onClick={onProfileIconClicked}
                />
            )}
            {isProfileCalloutOpen && (
                <Callout
                    directionalHint={DirectionalHint.topLeftEdge}
                    target={`#${profileCalloutIconId}`}
                    isBeakVisible={false}
                    className={styles.profileIconCalloutStyle}>
                    <ProfileCardContent
                        image={image}
                        name={profileName}
                        email={
                            principalUserContext?.principalRecord?.upn ||
                            userContext?.employeeRecord?.email ||
                            ''
                        }
                        setIsProfileCalloutOpen={setIsProfileCalloutOpen}
                        setIsFeedbackPanelOpen={setIsFeedbackPanelOpen}
                    />
                </Callout>
            )}
        </>
    );
}

const styles = mergeStyleSets({
    profileIconCalloutStyle: {
        width: 300,
        right: '0px !important',
        top: '64px !important',
    },
    clickablePersonaIcon: {
        selectors: {
            ':hover': {
                cursor: 'pointer',
            },
        },
    },
});
