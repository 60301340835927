import React, { useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ElementCard } from 'components/forms/element-card';
import ChoiceGroupElement from 'components/forms/element-types/choicegroup-element';
import DateElement from 'components/forms/element-types/date-element';
import SignatureElement from 'components/forms/element-types/signature-element';
import TextFieldElement from 'components/forms/element-types/textfield-element';
import TableElement from 'components/forms/element-types/table-element';
import {
    FormElement,
    UpdatableProperty,
    ChoiceGroupFormElement,
    FormElementValues,
    choiceTypes,
    Section,
} from 'components/forms/forms-common';
import InfoElement from 'components/forms/element-types/info-element';
import CountryPicker from 'components/forms/element-types/country-picker';
import PeoplePicker from 'components/forms/element-types/people-picker';

type ElementListProps = {
    sectionId: number;
    sections: Section[];
    shiftElement: (sectionIndex: number, id: string, direction: 'up' | 'down') => void;
    deleteElement: (sectionIndex: number, id: string) => void;
    updateForm: (
        sectionIndex: number,
        id: string,
        newValue: FormElementValues,
        operation: UpdatableProperty,
    ) => void;
};

export default function ElementList(props: ElementListProps): JSX.Element {
    const { sectionId, sections, shiftElement, deleteElement, updateForm } = props;
    const elements = sections[sectionId].elements;

    const onUpdateForm = useCallback(
        (id: string, newValue: FormElementValues, operation: UpdatableProperty): void => {
            updateForm(sectionId, id, newValue, operation);
        },
        [sectionId],
    );

    const onShiftElement = useCallback(
        (id: string, direction: 'up' | 'down'): void => {
            shiftElement(sectionId, id, direction);
        },
        [sectionId],
    );

    const onDeleteElement = useCallback(
        (id: string): void => {
            deleteElement(sectionId, id);
        },
        [sectionId],
    );

    const CreateFormElement = useCallback(
        (
            element: FormElement,
            updateForm: (
                id: string,
                newValue: FormElementValues,
                operation: UpdatableProperty,
            ) => void,
        ): JSX.Element => {
            if (element.type === 'textfield') {
                return <TextFieldElement element={element} updateForm={updateForm} />;
            }
            if (element.type === 'signature') {
                return <SignatureElement element={element} updateForm={updateForm} />;
            }
            if (element.type === 'info') {
                return <InfoElement element={element} updateForm={updateForm} />;
            }
            if (element.type === 'date' || element.type === 'daterange') {
                return <DateElement element={element} updateForm={updateForm} />;
            }
            if (element.type === 'table') {
                return <TableElement element={element} updateForm={updateForm} />;
            }
            if (element.type === 'country') {
                return <CountryPicker element={element} updateForm={updateForm} />;
            }
            if (element.type === 'people' || element.type === 'person') {
                return <PeoplePicker element={element} updateForm={updateForm} />;
            }
            if (choiceTypes.some((choiceType) => choiceType === element.type)) {
                return (
                    <ChoiceGroupElement
                        futureSections={sections.slice(sectionId + 1)}
                        element={element as ChoiceGroupFormElement}
                        elements={elements}
                        updateForm={updateForm}
                    />
                );
            }
            return <></>;
        },
        [elements, sectionId, sections],
    );

    const Element = useCallback(
        (element: FormElement, index: number, displayIndex: number): JSX.Element => {
            return (
                <Draggable draggableId={element.id} index={index}>
                    {(provided: any) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                            <ElementCard
                                key={element.id}
                                displayIndex={displayIndex}
                                element={element}
                                provided={provided}
                                deleteElement={onDeleteElement}
                                shiftElement={onShiftElement}>
                                {CreateFormElement(element, onUpdateForm)}
                            </ElementCard>
                        </div>
                    )}
                </Draggable>
            );
        },
        [CreateFormElement, onDeleteElement, onShiftElement, onUpdateForm],
    );

    let displayIndex = 0;
    return (
        <>
            {elements.map((element: FormElement, index) => {
                if (element.type === 'info') {
                    return <div key={element.id}>{Element(element, index, -1)}</div>;
                }
                return <div key={element.id}>{Element(element, index, ++displayIndex)}</div>;
            })}
        </>
    );
}
