import React, { useCallback, useEffect, useState } from 'react';
import 'App.css';
import { initializeIcons } from '@fluentui/react';
import Environment, { overrideEnvironmentConfig } from 'environments/environment';
import { BrowserHistoryOptions, createBrowserHistory } from 'history';
import { getConfig, initializeConfig } from 'clients/config';
import AuthContextProvider from 'contexts/auth-context';
import ProtectedApp from 'components/common/protected-app';
import { initializeTelemetry } from 'utils/telemetry-utils';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Configuration, PublicClientApplication, LogLevel } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { logMessage } from 'utils/telemetry-utils';

// Adding based on documentation that history should take the public url into account
// https://skryvets.com/blog/2018/09/20/an-elegant-solution-of-deploying-react-app-into-a-subdirectory/
export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL,
} as BrowserHistoryOptions);

const loggerOptions = {
    loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        let severity = SeverityLevel.Information;
        switch (level) {
            case LogLevel.Error:
                console.error(message);
                severity = SeverityLevel.Error;
                break;
            case LogLevel.Info:
                console.info(message);
                severity = SeverityLevel.Information;
                break;
            case LogLevel.Verbose:
                console.trace(message);
                severity = SeverityLevel.Verbose;
                break;
            case LogLevel.Warning:
                console.warn(message);
                severity = SeverityLevel.Warning;
                break;
        }
        if (!containsPii) {
            logMessage({
                method: 'AuthContextProvider | msal-logger',
                message: message,
                severityLevel: severity,
            });
        }
    },
    logLevel: LogLevel.Info,
    piiLoggingEnabled: true,
};

export default function App(): JSX.Element {
    const [msalPublicClientApp, setMsalPublicClientApp] = useState<PublicClientApplication>();

    const initConfig = useCallback(async (): Promise<void> => {
        await initializeConfig();
        const conf = getConfig();
        overrideEnvironmentConfig(conf);
    }, []);

    const initTelemetry = useCallback(async (): Promise<void> => {
        if (Environment.applicationInsightsConnectionString) {
            initializeTelemetry(Environment.applicationInsightsConnectionString);
        }
    }, []);

    const initMsal = useCallback(async (): Promise<void> => {
        if (Environment.aadConfig) {
            const msalConfig: Configuration = {
                auth: {
                    clientId: Environment.aadConfig.clientID,
                    authority: Environment.aadConfig.authority,
                    redirectUri: Environment.aadConfig.redirectUri,
                    postLogoutRedirectUri: Environment.aadConfig.postLogoutRedirectUri,
                    navigateToLoginRequestUrl: false,
                    cloudDiscoveryMetadata:
                        Environment.aadConfig.cloudDiscoveryMetadata ?? undefined,
                },
                cache: {
                    cacheLocation: Environment.aadConfig.cacheLocation as any,
                },
                system: {
                    loggerOptions: loggerOptions,
                    loadFrameTimeout: 60000,
                },
            };
            const app = new PublicClientApplication(msalConfig);
            await app.initialize();
            setMsalPublicClientApp(app);
        } else {
            logMessage({
                method: 'initMsal',
                message: `Failed to get aad config for creating MSAL public client application.`,
                severityLevel: SeverityLevel.Error,
            });
        }
    }, []);

    useEffect(() => {
        (async (): Promise<void> => {
            await initConfig();
            await initTelemetry();
            // This is to make the @fluentui/react icons available globally.
            // Icons have been manually packaged into the app under the public directory.
            initializeIcons('/icons/');
            await initMsal();
        })();
    }, []);

    if (msalPublicClientApp) {
        return (
            <MsalProvider instance={msalPublicClientApp}>
                <AuthContextProvider>
                    <ProtectedApp />
                </AuthContextProvider>
            </MsalProvider>
        );
    } else {
        return <></>;
    }
}
