/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    MultiChoiceFilter,
    EmployeePickerFilter,
    ContractDropdownFilter,
    DateTimeStartEndPicker,
    OrgLeaderPicker,
} from 'components/common/filters';
import { Stack, Slider, Separator } from '@fluentui/react';
import { IMultiChoiceFilterItem } from 'types/multi-choice-filter-Item';
import { DAY_IN_MILLISECONDS, milisecondsPerDayMinus1 } from 'utils/time-utils';
import { filterContextKeys } from 'contexts/filters-context';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import { Dictionary } from 'assets/constants/global-constants';
import { hierarchyLvlsAlias, IHierarchy } from 'clients/reports-client';
import { isUserContractOwnerOrNST } from 'components/screening/common/filters/filter-help';
import { UserContext } from 'contexts/user-context';
import {
    IEmployeeNameAndType,
    PersonnelTypes,
} from 'components/common/employee/internal-employee-utils';
import { toTitleCase } from 'utils/string-utils';
import {
    filterMargin,
    filterMarginMarginTop10,
    separatorStyles,
    stackTokensChildSpace,
} from 'components/screening/common/filters/common-filter-styling';
import AgencyDropdownFilter from 'components/screening/common/filters/agency-dropdown';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import { ISuitabilityRecord } from 'clients/suitability-client';
import {
    FiltersSuitabilityRecords,
    filterSuitabilityRecordsKeys,
    DateTypes,
} from 'contexts/filters-suitability-records';
import {
    SuitabilityLevels,
    SuitabilityStatuses,
    SuitabilityTypes,
} from 'components/personnel-profile/suitability/profile-suitability-types';
import { ContinuousEvaluationFilterOptions } from 'components/personnel-profile/common/common-types';
import { SuitabilityColumnHeaders } from 'components/screening/us-gov/candidates-listing/tabpanel-contents/content-suitability-records-tab';

const status = 'status';
const suitabilityType = 'suitabilityType';
const suitabilityLevel = 'suitabilityLevel';
const employeeStatus = 'employeeStatus';
const continuousEvaluationContinuousVetting = 'continuousEvaluationContinuousVetting';

const suitabilityExpirationDateSliderValueToDays = {
    1: 30,
    2: 60,
    3: 90,
    4: 120,
    5: 180,
    6: 360,
};

export interface SuitabilityRecordsFilterProps {
    suitabilityContractMap: Map<string, string>;
    hierarchyRecords: Dictionary<IHierarchy> | undefined;
    rawSuitabilities: ISuitabilityRecord[];
    showCounts: boolean;
    employeeNames: Map<string, IEmployeeNameAndType>;
    employeeIdsWithFutureTerminationDates: string[];
}

export function SuitabilityRecordsFilter(props: SuitabilityRecordsFilterProps): JSX.Element {
    const filtersContext = useContext(FiltersSuitabilityRecords);
    const userContext = useContext(UserContext);

    const [grantStartDate, setGrantStartDate] = useState<Date | undefined>(undefined);
    const [grantEndDate, setGrantEndDate] = useState<Date | undefined>(undefined);
    const [expirationValue, setExpirationValue] = useState<number | undefined>(undefined);
    const [triggerFilterCleared, setTriggerFilterCleared] = useState<number>(0);

    const [briefedStartDate, setBriefedStartDate] = useState<Date | undefined>(undefined);
    const [briefedEndDate, setBriefedEndDate] = useState<Date | undefined>(undefined);
    const [investigationStartDate, setInvestigationStartDate] = useState<Date | undefined>(
        undefined,
    );
    const [investigationEndDate, setInvestigationEndDate] = useState<Date | undefined>(undefined);
    const isContractOwnerOrNst = useMemo(
        () => isUserContractOwnerOrNST(userContext, ScreeningPaths.UsGov),
        [],
    );

    useEffect(() => {
        if (filtersContext.filterCleared) {
            setExpirationValue(undefined);
            setTriggerFilterCleared(triggerFilterCleared + 1);
        }
    }, [filtersContext.filterCleared]);

    function updateFilterContext(
        value: IMultiChoiceFilterItem,
        adding: boolean,
        filterCtxKey: string,
        filterCtxSetter: (arg0: string) => void,
    ): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const currentStatus: string = (filtersContext as any)[filterCtxKey];
        const label: string = value.label;
        if (adding) {
            if (currentStatus) {
                const currentStatusToTable = currentStatus.split(',');
                currentStatusToTable.push(label);
                filterCtxSetter(currentStatusToTable.toString());
            } else {
                filterCtxSetter(label);
            }
        } else {
            const currentStatusToTable = currentStatus.split(',');
            if (currentStatusToTable.length > 1) {
                const newTable = currentStatusToTable.filter((val) => val !== label);
                filterCtxSetter(newTable.toString());
            } else filterCtxSetter('');
        }

        filtersContext.updateFilterFunctions(filterCtxKey, value.filterFunction, adding);
    }

    function IsCheckboxChecked(group: string, label: string): boolean {
        let toTable: string[] = [];
        if (group === status) toTable = filtersContext.status.split(',');
        else if (group === suitabilityLevel) toTable = filtersContext.suitabilityLevel.split(',');
        else if (group === suitabilityType) toTable = filtersContext.suitabilityType.split(',');
        else if (group === employeeStatus) toTable = filtersContext.employeeStatus.split(',');
        else if (group === SuitabilityColumnHeaders.DebriefedOn)
            toTable = filtersContext.debriefedDate.split(',');
        else if (group === continuousEvaluationContinuousVetting)
            toTable = filtersContext.continuousEvaluationContinuousVetting.split(',');
        return toTable.includes(label);
    }

    function getFilterfunction(isStart: boolean, date: Date | undefined, type: DateTypes) {
        if (type === DateTypes.grantDate) {
            if (isStart) {
                return (data: ISuitabilityRecord): boolean => {
                    if (data.grantDateUTCMilliseconds) {
                        if (date) {
                            if (grantEndDate) {
                                return (
                                    data.grantDateUTCMilliseconds >= date.getTime() &&
                                    data.grantDateUTCMilliseconds <=
                                        grantEndDate.getTime() + milisecondsPerDayMinus1
                                );
                            }
                            return data.grantDateUTCMilliseconds >= date.getTime();
                        }
                    } else if (date) {
                        // if the user sets a valid date but the table row doesn't have
                        // grantDateUTCMilliseconds then don't show it when we filter
                        return false;
                    }
                    return true;
                };
            } else {
                return (data: ISuitabilityRecord): boolean => {
                    if (data.grantDateUTCMilliseconds) {
                        if (date) {
                            if (grantStartDate) {
                                return (
                                    data.grantDateUTCMilliseconds <=
                                        date.getTime() + milisecondsPerDayMinus1 &&
                                    data.grantDateUTCMilliseconds >= grantStartDate.getTime()
                                );
                            }
                            return (
                                data.grantDateUTCMilliseconds <=
                                date.getTime() + milisecondsPerDayMinus1
                            );
                        }
                    } else if (date) {
                        // if the user sets a valid date but the table row doesn't have
                        // grantDateUTCMilliseconds then don't show it when we filter
                        return false;
                    }
                    return true;
                };
            }
        } else if (type === DateTypes.investigationDate) {
            if (isStart) {
                return (data: ISuitabilityRecord): boolean => {
                    if (data.investigationDateUTCMilliseconds) {
                        if (date) {
                            if (investigationEndDate) {
                                return (
                                    data.investigationDateUTCMilliseconds >= date.getTime() &&
                                    data.investigationDateUTCMilliseconds <=
                                        investigationEndDate.getTime() + milisecondsPerDayMinus1
                                );
                            }
                            return data.investigationDateUTCMilliseconds >= date.getTime();
                        }
                    } else if (date) {
                        // if the user sets a valid date but the table row doesn't have
                        // investigationDateUTCMilliseconds then don't show it when we filter
                        return false;
                    }
                    return true;
                };
            } else {
                return (data: ISuitabilityRecord): boolean => {
                    if (data.investigationDateUTCMilliseconds) {
                        if (date) {
                            if (investigationStartDate) {
                                return (
                                    data.investigationDateUTCMilliseconds <=
                                        date.getTime() + milisecondsPerDayMinus1 &&
                                    data.investigationDateUTCMilliseconds >=
                                        investigationStartDate.getTime()
                                );
                            }
                            return (
                                data.investigationDateUTCMilliseconds <=
                                date.getTime() + milisecondsPerDayMinus1
                            );
                        }
                    } else if (date) {
                        // if the user sets a valid date but the table row doesn't have
                        // investigationDateUTCMilliseconds then don't show it when we filter
                        return false;
                    }
                    return true;
                };
            }
        } else if (type === DateTypes.briefedDate) {
            if (isStart) {
                return (data: ISuitabilityRecord): boolean => {
                    if (data.briefDateUTCMilliseconds) {
                        if (date) {
                            if (briefedEndDate) {
                                return (
                                    data.briefDateUTCMilliseconds >= date.getTime() &&
                                    data.briefDateUTCMilliseconds <=
                                        briefedEndDate.getTime() + milisecondsPerDayMinus1
                                );
                            }
                            return data.briefDateUTCMilliseconds >= date.getTime();
                        }
                    } else if (date) {
                        // if the user sets a valid date but the table row doesn't have
                        // briefDateUTCMilliseconds then don't show it when we filter
                        return false;
                    }
                    return true;
                };
            } else {
                return (data: ISuitabilityRecord): boolean => {
                    if (data.briefDateUTCMilliseconds) {
                        if (date) {
                            if (briefedStartDate) {
                                return (
                                    data.briefDateUTCMilliseconds <=
                                        date.getTime() + milisecondsPerDayMinus1 &&
                                    data.briefDateUTCMilliseconds >= briefedStartDate.getTime()
                                );
                            }
                            return (
                                data.briefDateUTCMilliseconds <=
                                date.getTime() + milisecondsPerDayMinus1
                            );
                        }
                    } else if (!data.grantDateUTCMilliseconds && date) {
                        // if the user sets a valid date but the table row doesn't have
                        // briefDateUTCMilliseconds then don't show it when we filter
                        return false;
                    }
                    return true;
                };
            }
        }
        return () => true;
    }

    function changeDate(isStart: boolean, date: Date | null | undefined, type: DateTypes) {
        const finalDate = date || undefined;
        const filterFunction = getFilterfunction(isStart, finalDate, type);
        if (type === DateTypes.grantDate) {
            if (isStart) {
                setGrantStartDate(finalDate);
            } else {
                setGrantEndDate(finalDate);
            }
            filtersContext.setGrantDate(`${type}${Date.now()}`);
        } else if (type === DateTypes.investigationDate) {
            if (isStart) {
                setInvestigationStartDate(finalDate);
            } else {
                setInvestigationEndDate(finalDate);
            }
            filtersContext.setInvestigationDate(`${type}${Date.now()}`);
        } else if (type === DateTypes.briefedDate) {
            if (isStart) {
                setBriefedStartDate(finalDate);
            } else {
                setBriefedEndDate(finalDate);
            }
            filtersContext.setBriefedDate(`${type}${Date.now()}`);
        }
        filtersContext.updateFilterFunctions(type, filterFunction, true);
    }

    // goal is to accept the actual text of the filter like Interim TS (with the space instead of '_')
    // and accept the backend strings because of dirty data in Prod.
    const suitabilityLevelOptions: IMultiChoiceFilterItem[] = [
        {
            label: SuitabilityLevels.IT1,
            isChecked: IsCheckboxChecked(suitabilityLevel, SuitabilityLevels.IT1),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.suitabilityLevel) {
                    return (
                        SuitabilityLevels.IT1.replace(/\s/g, '') ===
                        data.suitabilityLevel.replace(/\s/g, '')
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityLevels.IT2,
            isChecked: IsCheckboxChecked(suitabilityLevel, SuitabilityLevels.IT2),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.suitabilityLevel) {
                    return (
                        SuitabilityLevels.IT2.replace(/\s/g, '') ===
                        data.suitabilityLevel.replace(/\s/g, '')
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityLevels.IT3,
            isChecked: IsCheckboxChecked(suitabilityLevel, SuitabilityLevels.IT3),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.suitabilityLevel) {
                    return (
                        SuitabilityLevels.IT3.replace(/\s/g, '') ===
                        data.suitabilityLevel.replace(/\s/g, '')
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityLevels.Tier1,
            isChecked: IsCheckboxChecked(suitabilityLevel, SuitabilityLevels.Tier1),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.suitabilityLevel) {
                    return (
                        SuitabilityLevels.Tier1.replace(/\s/g, '') ===
                        data.suitabilityLevel.replace(/\s/g, '')
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityLevels.Tier2,
            isChecked: IsCheckboxChecked(suitabilityLevel, SuitabilityLevels.Tier2),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.suitabilityLevel) {
                    return (
                        SuitabilityLevels.Tier2.replace(/\s/g, '') ===
                        data.suitabilityLevel.replace(/\s/g, '')
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityLevels.Tier3,
            isChecked: IsCheckboxChecked(suitabilityLevel, SuitabilityLevels.Tier3),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.suitabilityLevel) {
                    return (
                        SuitabilityLevels.Tier3.replace(/\s/g, '') ===
                        data.suitabilityLevel.replace(/\s/g, '')
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityLevels.Tier4,
            isChecked: IsCheckboxChecked(suitabilityLevel, SuitabilityLevels.Tier4),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.suitabilityLevel) {
                    return (
                        SuitabilityLevels.Tier4.replace(/\s/g, '') ===
                        data.suitabilityLevel.replace(/\s/g, '')
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityLevels.Tier5,
            isChecked: IsCheckboxChecked(suitabilityLevel, SuitabilityLevels.Tier5),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.suitabilityLevel) {
                    return (
                        SuitabilityLevels.Tier5.replace(/\s/g, '') ===
                        data.suitabilityLevel.replace(/\s/g, '')
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
    ];

    // goal is to accept the actual text of the filter like 'Pending CSR'  (with the space instead of '_')
    // and accept the backend strings because of dirty data in Prod.
    const statusOptions: IMultiChoiceFilterItem[] = [
        {
            label: SuitabilityStatuses.Active,
            isChecked: IsCheckboxChecked(status, SuitabilityStatuses.Active),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.status) {
                    return SuitabilityStatuses.Active.toLowerCase() === data.status.toLowerCase();
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityStatuses.Denied,
            isChecked: IsCheckboxChecked(status, SuitabilityStatuses.Denied),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.status) {
                    return SuitabilityStatuses.Denied.toLowerCase() === data.status.toLowerCase();
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityStatuses.Inactive,
            isChecked: IsCheckboxChecked(status, SuitabilityStatuses.Inactive),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.status) {
                    return SuitabilityStatuses.Inactive.toLowerCase() === data.status.toLowerCase();
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityStatuses.LossOfJurisdiction,
            isChecked: IsCheckboxChecked(status, SuitabilityStatuses.LossOfJurisdiction),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.status) {
                    return (
                        SuitabilityStatuses.LossOfJurisdiction.toLowerCase() ===
                        data.status.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityStatuses.Pending,
            isChecked: IsCheckboxChecked(status, SuitabilityStatuses.Pending),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.status) {
                    return SuitabilityStatuses.Pending.toLowerCase() === data.status.toLowerCase();
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityStatuses.PendingCSR,
            isChecked: IsCheckboxChecked(status, SuitabilityStatuses.PendingCSR),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.status) {
                    return (
                        SuitabilityStatuses.PendingCSR.toLowerCase() === data.status.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityStatuses.RemovedFromProcessing,
            isChecked: IsCheckboxChecked(status, SuitabilityStatuses.RemovedFromProcessing),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.status) {
                    return (
                        SuitabilityStatuses.RemovedFromProcessing.toLowerCase() ===
                        data.status.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
    ];

    const suitabilityTypeOptions: IMultiChoiceFilterItem[] = [
        {
            label: SuitabilityTypes.Final,
            isChecked: IsCheckboxChecked(suitabilityType, SuitabilityTypes.Final),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.suitabilityType) {
                    return (
                        SuitabilityTypes.Final.toLowerCase() === data.suitabilityType.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityTypes.Interim,
            isChecked: IsCheckboxChecked(suitabilityType, SuitabilityTypes.Interim),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.suitabilityType) {
                    return (
                        SuitabilityTypes.Interim.toLowerCase() ===
                        data.suitabilityType.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: SuitabilityTypes.None,
            isChecked: IsCheckboxChecked(suitabilityType, SuitabilityTypes.None),
            filterFunction(data: ISuitabilityRecord): boolean {
                if (data.suitabilityType) {
                    return (
                        SuitabilityTypes.None.toLowerCase() === data.suitabilityType.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
    ];

    const briefingOptions: IMultiChoiceFilterItem[] = [
        {
            label: SuitabilityColumnHeaders.DebriefedOn,
            isChecked: IsCheckboxChecked(
                SuitabilityColumnHeaders.DebriefedOn,
                SuitabilityColumnHeaders.DebriefedOn,
            ),
            filterFunction(data: ISuitabilityRecord): boolean {
                return (
                    data.debriefDateUTCMilliseconds !== undefined &&
                    data.debriefDateUTCMilliseconds > 0
                );
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
    ];

    const continuousEvaluationOptions: IMultiChoiceFilterItem[] = [
        {
            label: ContinuousEvaluationFilterOptions.ContinuousEvaluationType,
            isChecked: IsCheckboxChecked(
                continuousEvaluationContinuousVetting,
                ContinuousEvaluationFilterOptions.ContinuousEvaluationType,
            ),
            filterFunction(data: ISuitabilityRecord): boolean {
                return !!data.continuousEvaluationType;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: ContinuousEvaluationFilterOptions.ContinuousEvaluationEnrollmentDate,
            isChecked: IsCheckboxChecked(
                continuousEvaluationContinuousVetting,
                ContinuousEvaluationFilterOptions.ContinuousEvaluationEnrollmentDate,
            ),
            filterFunction(data: ISuitabilityRecord): boolean {
                return !!data.continuousEvaluationEnrollmentDateUTCMilliseconds;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: ContinuousEvaluationFilterOptions.ContinuousEvaluationUnenrollmentDate,
            isChecked: IsCheckboxChecked(
                continuousEvaluationContinuousVetting,
                ContinuousEvaluationFilterOptions.ContinuousEvaluationUnenrollmentDate,
            ),
            filterFunction(data: ISuitabilityRecord): boolean {
                return !!data.continuousEvaluationUnenrollmentDateUTCMilliseconds;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
    ];

    const employeeStatusOptions: IMultiChoiceFilterItem[] = [
        {
            label: toTitleCase(PersonnelTypes.PreHire),
            isChecked: IsCheckboxChecked(employeeStatus, toTitleCase(PersonnelTypes.PreHire)),
            filterFunction(data: ISuitabilityRecord): boolean {
                return props.employeeNames.get(data.personnelId)?.type === PersonnelTypes.PreHire;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: toTitleCase(PersonnelTypes.Active),
            isChecked: IsCheckboxChecked(employeeStatus, toTitleCase(PersonnelTypes.Active)),
            filterFunction(data: ISuitabilityRecord): boolean {
                return props.employeeNames.get(data.personnelId)?.type === PersonnelTypes.Active;
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: toTitleCase(PersonnelTypes.FutureTermination),
            isChecked: IsCheckboxChecked(
                employeeStatus,
                toTitleCase(PersonnelTypes.FutureTermination),
            ),
            filterFunction(data: ISuitabilityRecord): boolean {
                return (
                    props.employeeIdsWithFutureTerminationDates.findIndex(
                        (employeeId) => employeeId === data.personnelId,
                    ) !== -1
                );
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return !props.showCounts
                    ? this.label
                    : this.label +
                          ' (' +
                          dataArray.filter((screeningData) =>
                              props.employeeIdsWithFutureTerminationDates.find(
                                  (employeeId) => employeeId === screeningData.personnelId,
                              ),
                          ).length +
                          ')';
            },
            styles: { root: { paddingLeft: '20px' } },
        },
        {
            label: toTitleCase(PersonnelTypes.Terminated),
            isChecked: IsCheckboxChecked(employeeStatus, toTitleCase(PersonnelTypes.Terminated)),
            filterFunction(data: ISuitabilityRecord): boolean {
                return (
                    props.employeeNames.get(data.personnelId)?.type === PersonnelTypes.Terminated
                );
            },
            generateLabel(dataArray: ISuitabilityRecord[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
    ];

    return (
        <div id='filterDiv' style={{ margin: '0 0.25em' }}>
            <span className={separatorStyles.content}>
                Total Active Suitability Holders
                {props.showCounts && props.rawSuitabilities && props.rawSuitabilities.length > 0
                    ? ' (' + getActiveSuitabilityHolderCount(props.rawSuitabilities) + ')'
                    : ''}
            </span>
            <Separator styles={separatorStyles} alignContent='start'>
                Suitability Records
                {props.showCounts && props.rawSuitabilities && props.rawSuitabilities.length > 0
                    ? ' (' + props.rawSuitabilities.length + ')'
                    : ''}
            </Separator>
            <Stack tokens={stackTokensChildSpace} styles={filterMarginMarginTop10}>
                <ContractDropdownFilter
                    screeningPath={ScreeningPaths.PublicTrust}
                    rawCandidates={props.rawSuitabilities}
                    contractMap={props.suitabilityContractMap}
                    onFilterStateChange={(selectedContracts: string[], adding: boolean): void => {
                        filtersContext.updateFilterFunctions(
                            filterSuitabilityRecordsKeys.contractIdKey,
                            (data: ISuitabilityRecord): boolean => {
                                if (data?.contractId && selectedContracts.length > 0) {
                                    return selectedContracts.includes(data.contractId);
                                } else if (selectedContracts.length === 0) {
                                    // nothing to filter
                                    return true;
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                <AgencyDropdownFilter
                    rawCandidates={props.rawSuitabilities}
                    onFilterStateChange={(selectedAgencies: string[], adding: boolean): void => {
                        filtersContext.updateFilterFunctions(
                            filterSuitabilityRecordsKeys.requestingAgencyIdKey,
                            (data: ISuitabilityRecord): boolean => {
                                if (data?.requestingAgency && selectedAgencies.length > 0) {
                                    return selectedAgencies.includes(data.requestingAgency);
                                } else if (selectedAgencies.length === 0) {
                                    // nothing to filter
                                    return true;
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                <EmployeePickerFilter
                    isSuitabilityRecords
                    onFilterStateChange={(value: string, adding: boolean): void => {
                        filtersContext.updateFilterFunctions(
                            filterSuitabilityRecordsKeys.personnelIdKey,
                            (data: ISuitabilityRecord): boolean => {
                                if (data?.personnelId) {
                                    return value === data.personnelId;
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                {isContractOwnerOrNst && (
                    <>
                        <OrgLeaderPicker
                            hierarchyRecords={props.hierarchyRecords}
                            isSuitabilityRecords
                            onFilterStateChange={(
                                value: string,
                                adding: boolean,
                                level: hierarchyLvlsAlias,
                            ): void => {
                                filtersContext.updateFilterFunctions(
                                    filterContextKeys.orgLeaderAliasKey,
                                    (data: ISuitabilityRecord): boolean => {
                                        if (props.hierarchyRecords) {
                                            const hierarchyRecord =
                                                props.hierarchyRecords[data.personnelId];
                                            if (hierarchyRecord && hierarchyRecord[level]) {
                                                return value === hierarchyRecord[level];
                                            }
                                        }
                                        return false;
                                    },
                                    adding,
                                );
                            }}
                        />
                    </>
                )}
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Suitability Level
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Suitability Level Group'>
                <MultiChoiceFilter
                    unfilteredData={props.rawSuitabilities}
                    filterItems={suitabilityLevelOptions}
                    onChildStateChange={(value: IMultiChoiceFilterItem, adding: boolean): void => {
                        updateFilterContext(
                            value,
                            adding,
                            suitabilityLevel,
                            filtersContext.setSuitabilityLevel,
                        );
                    }}
                />
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Suitability Type
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Suitability Type Group'>
                <MultiChoiceFilter
                    unfilteredData={props.rawSuitabilities}
                    filterItems={suitabilityTypeOptions}
                    onChildStateChange={(value: IMultiChoiceFilterItem, adding: boolean): void => {
                        updateFilterContext(
                            value,
                            adding,
                            suitabilityType,
                            filtersContext.setSuitabilityType,
                        );
                    }}
                />
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Status
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Status Group'>
                <MultiChoiceFilter
                    unfilteredData={props.rawSuitabilities}
                    filterItems={statusOptions}
                    onChildStateChange={(value: IMultiChoiceFilterItem, adding: boolean): void => {
                        updateFilterContext(value, adding, status, filtersContext.setStatus);
                    }}
                />
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Briefing
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Briefing Status Group'>
                <MultiChoiceFilter
                    unfilteredData={props.rawSuitabilities}
                    filterItems={briefingOptions}
                    onChildStateChange={(value: IMultiChoiceFilterItem, adding: boolean): void => {
                        // we only care to update the filter function when adding == true or when checkbox is checked.
                        // if that's the case then apply filter defined in briefingOptions
                        // else remove the filter by applying a function that always return true for everything
                        if (adding) {
                            filtersContext.setDebriefedDate(value.label); // this to make sure we indicate the check box is checked
                            filtersContext.updateFilterFunctions(
                                DateTypes.debriefedDate,
                                value.filterFunction,
                                adding,
                            );
                        } else {
                            filtersContext.setDebriefedDate(''); // this to make sure we indicate the check box is not checked
                            filtersContext.updateFilterFunctions(
                                DateTypes.debriefedDate,
                                (data: ISuitabilityRecord): boolean => {
                                    return true;
                                },
                                adding,
                            );
                        }
                    }}
                />
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Continuous Evaluation/Continuous Vetting
            </Separator>
            <Stack
                styles={filterMargin}
                role='group'
                aria-label='Continuous Evaluation/Continuous Vetting'>
                <MultiChoiceFilter
                    unfilteredData={props.rawSuitabilities}
                    filterItems={continuousEvaluationOptions}
                    onChildStateChange={(value: IMultiChoiceFilterItem, adding: boolean): void => {
                        updateFilterContext(
                            value,
                            adding,
                            continuousEvaluationContinuousVetting,
                            filtersContext.setContinuousEvaluationContinuousVetting,
                        );
                    }}
                />
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Employee Status
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Employee Status Group'>
                <MultiChoiceFilter
                    unfilteredData={props.rawSuitabilities}
                    filterItems={employeeStatusOptions}
                    onChildStateChange={(value: IMultiChoiceFilterItem, adding: boolean): void => {
                        updateFilterContext(
                            value,
                            adding,
                            employeeStatus,
                            filtersContext.setEmployeeStatus,
                        );
                    }}
                />
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Expiration on
            </Separator>
            <Stack styles={filterMargin}>
                <Slider
                    min={1}
                    max={7}
                    step={1}
                    defaultValue={7}
                    showValue
                    snapToStep
                    value={expirationValue}
                    key={triggerFilterCleared}
                    ariaLabel='Expiration On'
                    valueFormat={(value: number) => {
                        // want to show 30, 60, 90, 120, 180, 360 days, all
                        // 1 => 30, 2 => 60, 3 => 90, 4 => 120, 5=> 180, 6 => 360, 7 =>all
                        if (value === 7) {
                            return 'All';
                        } else {
                            return suitabilityExpirationDateSliderValueToDays[
                                value as keyof typeof suitabilityExpirationDateSliderValueToDays
                            ].toString();
                        }
                    }}
                    onChange={(value: number) => {
                        setExpirationValue(value);
                        filtersContext.setExpiresOnDate(`${DateTypes.expiresOnDate}${Date.now()}`);
                        filtersContext.updateFilterFunctions(
                            DateTypes.expiresOnDate,
                            (data: ISuitabilityRecord): boolean => {
                                if (value === 7) {
                                    return true;
                                } else if (data.suitabilityExpirationDateTimeUTCMilliseconds) {
                                    const rightNow = new Date().valueOf();
                                    const timeRangeDays =
                                        suitabilityExpirationDateSliderValueToDays[
                                            value as keyof typeof suitabilityExpirationDateSliderValueToDays
                                        ] * DAY_IN_MILLISECONDS;
                                    const rangeEnd = rightNow.valueOf() + timeRangeDays;
                                    return (
                                        data.suitabilityExpirationDateTimeUTCMilliseconds >=
                                            rightNow &&
                                        data.suitabilityExpirationDateTimeUTCMilliseconds <=
                                            rangeEnd
                                    );
                                }
                                return false;
                            },
                            true,
                        );
                    }}
                />
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Granted on
            </Separator>
            <DateTimeStartEndPicker
                startDate={grantStartDate}
                endDate={grantEndDate}
                changeDate={(isStart: boolean, date: Date | null | undefined) =>
                    changeDate(isStart, date, DateTypes.grantDate)
                }
                filterCleared={filtersContext.filterCleared}
            />
            <Separator styles={separatorStyles} alignContent='start'>
                Investigated on
            </Separator>
            <DateTimeStartEndPicker
                startDate={investigationStartDate}
                endDate={investigationEndDate}
                changeDate={(isStart: boolean, date: Date | null | undefined) =>
                    changeDate(isStart, date, DateTypes.investigationDate)
                }
                filterCleared={filtersContext.filterCleared}
            />
            <Separator styles={separatorStyles} alignContent='start'>
                Briefed on
            </Separator>
            <DateTimeStartEndPicker
                startDate={briefedStartDate}
                endDate={briefedEndDate}
                changeDate={(isStart: boolean, date: Date | null | undefined) =>
                    changeDate(isStart, date, DateTypes.briefedDate)
                }
                filterCleared={filtersContext.filterCleared}
            />
            <Stack.Item styles={{ root: { display: 'flex', flexDirection: 'row-reverse' } }}>
                <ClearFiltersActionButton clearFunc={filtersContext.clearFilters} />
            </Stack.Item>
        </div>
    );
}

function labelFunction(
    label: string,
    dataArray: ISuitabilityRecord[],
    filterFunction: (data: ISuitabilityRecord) => boolean,
    showCount: boolean,
): string {
    let retLabel = label;
    if (showCount) {
        let count = 0;
        if (Array.isArray(dataArray)) {
            count = dataArray.filter((data) => {
                return filterFunction(data);
            }).length;
        }
        retLabel = retLabel + ' (' + count + ')';
    }
    return retLabel;
}

function getActiveSuitabilityHolderCount(suitabilities: ISuitabilityRecord[]): number {
    const personnelIdsWithActiveSuitabilities = new Set(
        suitabilities
            .filter((c) => c.status === SuitabilityStatuses.Active)
            .map((c) => c.personnelId),
    );

    return personnelIdsWithActiveSuitabilities.size;
}
