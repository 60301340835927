import React, { useContext, useEffect, useState } from 'react';
import {
    mergeStyles,
    Checkbox,
    Dropdown,
    IDropdownOption,
    IStackTokens,
    Stack,
    TextField,
    ICheckboxStyles,
    DatePicker,
    IPersonaProps,
    mergeStyleSets,
    Separator,
} from '@fluentui/react';
import {
    ScaReviewPeriodFilterContext,
    IEmployeeFilterSetting,
} from 'components/sca/contexts/sca-review-period-filter-context';
import {
    globalSeparatorStyles,
    globalStyles,
    globalCheckboxStyles,
    globalFilterSeparatorStyles,
} from 'assets/styles/global-styles';
import { fetchAllReviewPeriods, reviewStatusBadge } from 'components/sca/sca-utils';
import { IReviewPeriod, reviewStates } from 'clients/sca-client';
import { AuthContext } from 'contexts/auth-context';
import {
    translateReviewState,
    rateSelectionOptions,
    OtherRate,
    MinValidRate,
    MaxValidRate,
} from 'components/sca/sca-constants';
import Spacer from 'components/common/spacer';
import deepcopy from 'deepcopy';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { employeeCardPadding } from 'components/common/employee/employee-card';
import { Role } from 'configs/roles';
import { SidebarPane } from 'components/common/sidebar-and-contents';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';

const styles = mergeStyleSets({
    top: {
        marginBottom: '15px',
    },
});

const filterStackTokens: IStackTokens = {
    childrenGap: 5,
};

interface IScaManageViewIdFiltersProps {
    reviewId?: string; // Initialization value for review period Id
    showExec?: boolean;
    hideFilters?: boolean; // Default: false
    showRate?: boolean;
    rateFilterLabel?: string;
    showPreviousRate?: boolean;
    aliases: string[]; // Aliases or email addresses of employees
    reviewPeriod?: IReviewPeriod;
    showOrgleader?: boolean;
    showReportsTo?: boolean;
    showReviewedBy?: boolean;
    showReviewedOn?: boolean;
    showClearFilters?: boolean;
}

export default function ScaManageViewIdFilters(props: IScaManageViewIdFiltersProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const filterContext = useContext(ScaReviewPeriodFilterContext);

    const [reviewPeriods, setReviewPeriods] = useState<IReviewPeriod[] | undefined>();

    useEffect(() => {
        let isMounted = true;
        const go = async (): Promise<void> => {
            const reviewPeriodsVar = await fetchAllReviewPeriods(authContext);
            if (isMounted) {
                setReviewPeriods(reviewPeriodsVar);
                const defaultReviewPeriod = props.reviewId ?? reviewPeriodsVar[0]?.id;
                if (defaultReviewPeriod) {
                    filterContext.setFilter(
                        'period',
                        filterContext.periodFilterSelection(defaultReviewPeriod),
                    );
                } else {
                    filterContext.setFilter('period', undefined);
                }
            }
        };
        go();
        return (): void => {
            isMounted = false;
        };
    }, []);

    const selectPeriodHandler = (
        a?: React.FormEvent<HTMLElement>,
        selection?: IDropdownOption,
    ): void => {
        if (selection) {
            filterContext.setFilter('period', selection);
        }
    };

    const selectStatusHandler = (
        a?: React.FormEvent<HTMLElement>,
        checked?: boolean,
        statusName?: string,
    ): void => {
        if (statusName) {
            filterContext.setFilter('status', { [statusName]: checked });
        }
    };

    const onOrgLeadeerSelectedHandler = (info?: IPersonaProps): void => {
        const infoCopy = deepcopy(info) as IEmployeeFilterSetting;
        if (info) {
            infoCopy.itemPropObj = JSON.parse(info?.itemProp ?? '{}');
        }
        filterContext.setFilter('orgLeader', infoCopy);
    };

    const onCandidateSelectedHandler = (info?: IPersonaProps): void => {
        const infoCopy = deepcopy(info) as IEmployeeFilterSetting;
        if (info) {
            infoCopy.itemPropObj = JSON.parse(info?.itemProp ?? '{}');
        }
        filterContext.setFilter('employee', infoCopy);
    };

    const onReportsToSelectedHandler = (info?: IPersonaProps): void => {
        const infoCopy = deepcopy(info) as IEmployeeFilterSetting;
        if (info) {
            infoCopy.itemPropObj = JSON.parse(info?.itemProp ?? '{}');
        }
        filterContext.setFilter('reportsTo', infoCopy);
    };

    const onReviewerSelectedHandler = (info?: IPersonaProps): void => {
        const infoCopy = deepcopy(info) as IEmployeeFilterSetting;
        if (info) {
            infoCopy.itemPropObj = JSON.parse(info?.itemProp ?? '{}');
        }
        filterContext.setFilter('reviewer', infoCopy);
    };

    const reviewPeriodsOptions = reviewPeriods
        ? reviewPeriods.map((r) => ({ key: r.id ?? r.title, text: r.title }))
        : [];

    return (
        <div className={styles.top}>
            <Stack tokens={filterStackTokens}>
                {/**
                 * This component needs two sidebar panes. It is also supposed
                 * to be the first child of component <SidebarAndContents>. So, it
                 * instantiates the component <SidebarPane> to create the sidebars.
                 * Therefore, the parent component, <SidebarAndContents> will not
                 * need to, and should not, use <SidebarPane>. If it does, styling
                 * will be off, way off. It should instead only instantiate this
                 * component as its first child.
                 */}
                <SidebarPane>
                    <Separator styles={globalSeparatorStyles} alignContent='start'>
                        Period
                    </Separator>
                    <Dropdown
                        styles={{ dropdownItems: globalStyles.dropdownItems }}
                        selectedKey={filterContext.period?.key || ''}
                        onChange={selectPeriodHandler}
                        options={reviewPeriodsOptions}
                        ariaLabel='Period'
                    />
                    {props.reviewPeriod && (
                        <>
                            <Spacer marginTop={10} />
                            <div>
                                <span className={globalStyles.boldFont}>
                                    Review Period Status&nbsp;&nbsp;
                                </span>
                                {reviewStatusBadge(props.reviewPeriod)}
                            </div>
                        </>
                    )}
                    {/* SCA Auth */}
                    {props.showExec && authContext.isInRole(Role.SCAAdmin) && (
                        <div className={execBoxStyle}>
                            <EmployeeBasicHoverCard
                                key={filterContext.execId}
                                personnelId={filterContext.execId}
                                noHoverCard={true}
                                displayActions={false}
                                employeeCardStyle={{
                                    card: { minWidth: 0 },
                                    cardActions: { marginTop: employeeCardPadding },
                                }}
                            />
                        </div>
                    )}
                </SidebarPane>
                {!props.hideFilters && (
                    <SidebarPane>
                        <Separator styles={globalSeparatorStyles} alignContent='start'>
                            Status
                        </Separator>
                        <div role='group' aria-label='Status Group'>
                            {reviewStates().map(
                                (statusName): JSX.Element => (
                                    <Checkbox
                                        styles={checkboxStyles}
                                        label={translateReviewState(statusName)}
                                        key={statusName}
                                        onChange={(ev, checked): void => {
                                            selectStatusHandler(ev, checked, statusName);
                                        }}
                                        checked={
                                            filterContext.status
                                                ? filterContext.status[statusName] ?? false
                                                : false
                                        }
                                    />
                                ),
                            )}
                        </div>
                        {props.showOrgleader && (
                            <>
                                <Separator
                                    styles={globalFilterSeparatorStyles}
                                    alignContent='start'>
                                    Org Leader
                                </Separator>
                                <EmployeePickerTypeaheadSearch
                                    ariaLabel='Org Leader'
                                    placeHolder='Org Leader Name or Alias'
                                    selectedItems={
                                        filterContext.orgLeader ? [filterContext.orgLeader] : []
                                    }
                                    onCandidateSelected={onOrgLeadeerSelectedHandler}
                                />
                            </>
                        )}

                        <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                            Employee Search
                        </Separator>
                        <EmployeePickerTypeaheadSearch
                            ariaLabel='Employee Search'
                            placeHolder='Employee Name or Alias'
                            selectedItems={filterContext.employee ? [filterContext.employee] : []}
                            onCandidateSelected={onCandidateSelectedHandler}
                        />
                        {props.showReportsTo && (
                            <>
                                <Separator
                                    styles={globalFilterSeparatorStyles}
                                    alignContent='start'>
                                    <span>Reports To</span>
                                </Separator>
                                <EmployeePickerTypeaheadSearch
                                    ariaLabel='Reports To'
                                    placeHolder='Employee Name or Alias'
                                    selectedItems={
                                        filterContext.reportsTo ? [filterContext.reportsTo] : []
                                    }
                                    onCandidateSelected={onReportsToSelectedHandler}
                                />
                            </>
                        )}
                        {props.showReviewedBy && (
                            <>
                                <Separator
                                    styles={globalFilterSeparatorStyles}
                                    alignContent='start'>
                                    <span>Reviewed By</span>
                                </Separator>
                                <EmployeePickerTypeaheadSearch
                                    ariaLabel='Reviewed By'
                                    placeHolder='Employee Name or Alias'
                                    selectedItems={
                                        filterContext.reviewer ? [filterContext.reviewer] : []
                                    }
                                    onCandidateSelected={onReviewerSelectedHandler}
                                />
                            </>
                        )}
                        {props.showReviewedOn && (
                            <>
                                <Separator
                                    styles={globalFilterSeparatorStyles}
                                    alignContent='start'>
                                    <span>Reviewed On</span>
                                </Separator>
                                <DatePicker
                                    value={filterContext.reviewedOn}
                                    placeholder={'Select a date'}
                                    allowTextInput={true}
                                    onSelectDate={(date): void => {
                                        filterContext.setFilter('reviewedOn', date);
                                    }}
                                    ariaLabel='Reviewed On'
                                />
                            </>
                        )}
                        {props.showRate && (
                            <>
                                <Dropdown
                                    onRenderLabel={() => (
                                        <Separator
                                            styles={globalFilterSeparatorStyles}
                                            alignContent='start'>
                                            <span>{props.rateFilterLabel ?? 'Rate'}</span>
                                        </Separator>
                                    )}
                                    ariaLabel={props.rateFilterLabel ?? 'Rate'}
                                    placeholder='Select a rate'
                                    selectedKey={filterContext.selectedRate?.key || ''}
                                    onChange={(e, rate): void => {
                                        filterContext.setFilter('selectedRate', rate);
                                    }}
                                    options={rateSelectionOptions}
                                />
                                {filterContext.selectedRate?.key === OtherRate && (
                                    <TextField
                                        label={`Enter a rate between ${MinValidRate}% and ${MaxValidRate}%`}
                                        title={`Enter a rate between ${MinValidRate}% and ${MaxValidRate}%`}
                                        type='number'
                                        min={MinValidRate}
                                        max={MaxValidRate}
                                        value={`${filterContext.rate}`}
                                        onChange={(e, value): void => {
                                            filterContext.setFilter('rate', value);
                                        }}
                                    />
                                )}
                            </>
                        )}
                        {props.showPreviousRate && (
                            <>
                                <Dropdown
                                    label='Previous Rate'
                                    onRenderLabel={() => (
                                        <Separator
                                            styles={globalFilterSeparatorStyles}
                                            alignContent='start'>
                                            <span>{'Previous Rate'}</span>
                                        </Separator>
                                    )}
                                    ariaLabel='Previous Rate'
                                    placeholder='Select a rate'
                                    selectedKey={filterContext.selectedPreviousRate?.key || ''}
                                    onChange={(e, rate): void => {
                                        filterContext.setFilter('selectedPreviousRate', rate);
                                    }}
                                    options={rateSelectionOptions}
                                />
                                {filterContext.selectedPreviousRate?.key === OtherRate && (
                                    <TextField
                                        label={`Enter a rate between ${MinValidRate}% and ${MaxValidRate}%`}
                                        title={`Enter a rate between ${MinValidRate}% and ${MaxValidRate}%`}
                                        type='number'
                                        min={MinValidRate}
                                        max={MaxValidRate}
                                        value={`${filterContext.previousRate}`}
                                        onChange={(e, value): void => {
                                            filterContext.setFilter('previousRate', value);
                                        }}
                                    />
                                )}
                            </>
                        )}
                        {props.showClearFilters && (
                            <Stack>
                                <Stack.Item align='end'>
                                    <ClearFiltersActionButton clearFunc={filterContext.resetAll} />
                                </Stack.Item>
                            </Stack>
                        )}
                    </SidebarPane>
                )}
            </Stack>
        </div>
    );
}

const checkboxStyles: ICheckboxStyles = {
    root: globalCheckboxStyles.root,
};

const execBoxStyle = mergeStyles(globalStyles.boxShadow, {
    marginTop: 15,
});
