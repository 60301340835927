import { validateDateString } from 'utils/time-utils';

const MAX_RECORDS = 500;

type ValidColumnNamesType = string;

// Name of the column in the data returned from back end that contains the upload status.
export const UPLOAD_STATUS_COLUMN_NAME = 'Upload status';

// The following type can replace the types UploadPublicTrustRecordResult, UploadEquipmentRecordResult and UploadSuitabilityRecordResult.
export type BulkUploadResult = {
    // Upload result has many more fields. Different upload endpoints generate different fields.
    // The following field, however, is common for all bulk uploads and is used in the front end
    // to show a summary of how many records in the input file were successfully uploaded, how many
    // failed and how many were skipped.
    // If a type were required to represent more of the fields in upload result of a particular
    // upload endpoint, a new type must be created, and it should extend this type.
    [UPLOAD_STATUS_COLUMN_NAME]: string;
};

export type UploadColumnParams<T> = {
    name: string; // Name of the property in the request object of type T
    header: string; // The corresponding column header in the CSV file
    isColumnRequired: boolean; // Whether the column has to be present in the CSV file
    isValid: (row: T) => boolean; // Validates the value provided in the CSV file
    transform?: (value: string) => string; // Optional. Transforms the value provided in the CSV file.
};

export function checkUploadFileData<T>(
    data: string[][],
    uploadColumnParams: UploadColumnParams<T>[],
    csvFileColumnHeaders: string[],
    columnParams: UploadColumnParams<T>[],
): {
    isDataItemsValid: boolean;
    columnIndexesResult: Record<string, number>;
    errorMessage: string;
    badRowIx: number;
    badColumnHeader: string;
    requestData: any[];
} {
    const result = {
        isDataItemsValid: false,
        columnIndexesResult: {} as Record<string, number>,
        errorMessage: '',
        badRowIx: -1,
        badColumnHeader: '',
        requestData: [],
    };

    // Check there is at least some data
    if (data.length === 0) {
        result.errorMessage = 'No data found in file';
        return result;
    }
    // check there are at most MAX_RECORDS in the file
    if (data.length > MAX_RECORDS) {
        result.errorMessage = `Too many records in file. Maximum is ${MAX_RECORDS}`;
        return result;
    }

    // Check if the header row has provided all the expected column names.
    const theProvidedColumnNames: Set<string> = new Set<string>(csvFileColumnHeaders);

    // The following column headers are required to be present in the CSV file,
    const expectedColumnNames = columnParams
        .filter((column) => column.isColumnRequired)
        .map((column) => column.header);

    if (!expectedColumnNames.every((item) => theProvidedColumnNames.has(item))) {
        const themissingColumnNames = expectedColumnNames.filter(
            (columnName) => !theProvidedColumnNames.has(columnName),
        );
        result.errorMessage = `The following required columns are missing in the CSV file: ${Array.from(
            themissingColumnNames,
        )
            .map((name) => `"${name}"`)
            .join(', ')}.`;
        return result;
    }

    // Column headers are ok.
    const columnIndexesVar: Record<string, number> = {} as Record<ValidColumnNamesType, number>;
    csvFileColumnHeaders.forEach((columnHeader, index) => {
        columnIndexesVar[columnHeader] = index;
    });

    const requestData = prepareRequestData(data, columnParams, columnIndexesVar);

    // Where possible, check if the provided data is valid.
    let isDataItemsValid = true;
    let badRowIx = -1;
    let badColumnHeader = '';
    for (let rowIx = 0; isDataItemsValid && rowIx < requestData.length; rowIx++) {
        const row = requestData[rowIx];
        for (
            let columnParamIx = 0;
            isDataItemsValid && columnParamIx < uploadColumnParams.length;
            columnParamIx++
        ) {
            const columnParam = uploadColumnParams[columnParamIx];
            const isValid = columnParam.isValid(row);
            if (!isValid) {
                isDataItemsValid = false;
                [badRowIx, badColumnHeader] = [rowIx, columnParam.header];
            }
        }
    }
    return {
        isDataItemsValid,
        columnIndexesResult: columnIndexesVar,
        errorMessage: '',
        badRowIx,
        badColumnHeader,
        requestData,
    };
}

function prepareRequestData(
    data: string[][],
    columnParams: UploadColumnParams<any>[],
    columnIndexesParam: Record<string, number>, // column name
): any[] {
    const requestDataResult = data.map((row) => {
        const rowObject: any = {};
        columnParams.map((columnParam) => {
            const { name, header, transform } = columnParam;
            if (!!transform) {
                rowObject[name] = transform(row[columnIndexesParam[header]]);
            } else {
                rowObject[name] = row[columnIndexesParam[header]];
            }
        });
        return rowObject;
    });
    return requestDataResult;
}

// Date strings could come back from backend in a form similar to 2024-05-29T20:48:38.348Z.
// This function goes through the upload results on the columns that are specified by
// parameter dateColumns, and converts them to default loacale date string format.
export function convertDateStringsToLocaleDateString(
    uploadResult: any[],
    dateColumns: string[],
): void {
    uploadResult.forEach((row: any) => {
        dateColumns.forEach((dateColumn) => {
            const dateString = (row[dateColumn] as string).trim();
            if (!!dateString) {
                if (validateDateString(dateString, false)) {
                    row[dateColumn] = new Date(dateString).toLocaleDateString();
                }
            }
        });
    });
}
