export enum FacilitiesMenuNavigationText {
    Facilities = 'Facilities',
    Reservations = 'Reservations',
    NewReservation = 'New Reservation',
    MyReservations = 'My Reservations',
    Equipment = 'Equipment',
    Seats = 'Seats',
    Audit = 'Audit',
    Manage = 'Manage',
    LogBook = 'Log Book',
    BlockedUsers = 'Blocked Users',
    Metrics = 'Metrics',
}

export enum FacilityUserType {
    UserRole = 'Facilities-User',
    AdminService = 'Facilities-Service-Admin',
    ManagerRole = 'Facility-Manager',
    EquipmentOfficerRole = 'Facilities-Service-Equipment-Officer',
}
