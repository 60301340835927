import React from 'react';
import { IPersonaProps, mergeStyleSets, DatePicker, TextField, Stack } from '@fluentui/react';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { stackChildrenGap } from 'components/personnel-profile/common-personnel-profile-styles';
export interface PrehireCandidateNominationStepPositionDetailsProps {
    pcn?: string;
    title?: string;
    selectedDate?: Date | null;
    selectedManagerPersona?: IPersonaProps;
    onPcnChange: (newValue?: string) => void;
    onTitleChange: (newValue?: string) => void;
    onDateChange: (newDate?: Date | null) => void;
    onSelectedManagerChange: (selectedManagerPersona?: IPersonaProps) => void;
}

const styles = mergeStyleSets({
    marginTop15: {
        marginTop: 15,
    },
    marginBottom15: {
        marginBottom: 15,
    },
});

export default function PrehireCandidateNominationStepPositionDetails(
    props: PrehireCandidateNominationStepPositionDetailsProps,
): JSX.Element {
    return (
        <Stack tokens={stackChildrenGap}>
            <TextField
                label='Position control number (PCN)'
                value={props.pcn || ''}
                onChange={(ev, newValue): void => onFieldChange(ev, 'pcn', newValue)}
            />
            <EmployeePickerTypeaheadSearch
                onCandidateSelected={props.onSelectedManagerChange}
                selectedPersona={props.selectedManagerPersona}
                required={true}
                label='Hiring manager'
            />
            <TextField
                label='Prospective title'
                required
                value={props.title || ''}
                onChange={(ev, newValue): void => onFieldChange(ev, 'title', newValue)}
                className={styles.marginTop15}
            />
            <DatePicker
                ariaLabel='Select an expected start date'
                label='Expected start date'
                value={props.selectedDate!}
                onSelectDate={props.onDateChange}
                className={styles.marginTop15}
            />
        </Stack>
    );

    function onFieldChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: string,
        newValue?: string,
    ): void {
        if (key === 'pcn') props.onPcnChange(newValue);
        else if (key === 'title') props.onTitleChange(newValue);
    }
}
