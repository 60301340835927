import FacilitiesClient, {
    generateReservationId,
    IFacilityRecord,
    IFacilityTimeslotItem,
    ICalendarReservationRecord,
    ISeatStatus,
    ReservationState,
    ISeatRecord,
    SeatStatuses,
    getAvailableSeatStatus,
    IReservationSeatChangeRequest,
} from 'clients/facilities-client';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { Label, Stack } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useIsMounted } from 'utils/misc-hooks';
import CancelReservationModalActionButton from 'components/facilities/facilities-reservations/modals/facilities-reservation-cancel-modal-action-button';
import ChangeReservationModal from 'components/facilities/facilities-reservations/modals/facilities-reservation-change-modal';
import ReservationCalendarCell, {
    ICell,
    IReservationCalendarCell,
} from 'components/facilities/facilities-reservations/facilities-reservation-calendar/reservation-calendar-cell';

export interface ICalendarRow {
    seatId: string;
    seatStatus: ISeatStatus;
    timeslotItem: IFacilityTimeslotItem;
    isLoading: boolean;
}
export interface IReservationCalendarColumn {
    columnTitle: string;
    rows: ICalendarRow[];
}

export interface ICalendarReservationRecords {
    reservationRecords: ICalendarReservationRecord[];
}

export interface ISelectedClaimCode {
    claimCode: string;
}

export interface ICellStatus {
    claimCode?: string;
}

export interface ICalendarRecords {
    reservationRecords: ICalendarReservationRecord[];
}
export interface IReservationCalendarColumnProps {
    columnTitle: string;
    rows: ICalendarRow[];
    selectedFacility: IFacilityRecord;
    selectedDate: Date;
    reservationRecords?: ICalendarRecords;
    setReservationRecords: (r: ICalendarRecords) => void;
    setSuccessProp: (showSuccess: boolean) => void;
    setErrorProp: (showError: boolean) => void;
    isReserveClicked: boolean;
    facilitySeats: ISeatRecord[];
    setIcmDescription: (desc: string) => void;
    isUserBlockedFromReserving: boolean;
}

export default function ReservationCalendarColumn(
    props: IReservationCalendarColumnProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [columnReservationRecord, setColumnReservationRecord] = useState<
        ICalendarReservationRecord | undefined
    >();
    const [rowData, setRowData] = useState<ICalendarRow[]>(props.rows);
    const [preclaimedSeat, setPreclaimedSeat] = useState<ICell | undefined>();
    const [confirmedSeat, setConfirmedSeat] = useState<ICell | undefined>();
    const [changeSeatIcmDescription, setChangeSeatIcmDescription] = useState<string | undefined>();
    const [showChangeSeatIcmModal, setShowChangeSeatIcmModal] = useState<boolean>(false);
    const [currentCell, setCurrentCell] = useState<IReservationCalendarCell | undefined>();
    const [newSeat, setNewSeat] = useState<ISeatRecord | undefined>();
    const [oldSeat, setOldSeat] = useState<ISeatRecord | undefined>();
    const [isCancelReservationDialogOpen, setCancelReservationDialogOpen] = useState<boolean>(
        false,
    );
    const [cancelReservationCell, setCancelReservationCell] = useState<IReservationCalendarCell>();

    const isMounted = useIsMounted();

    useEffect(() => {
        if (rowData) {
            const foundPreclaimedSeat = rowData.find(
                (x) => x.seatStatus.reservationState === ReservationState.Preclaimed,
            );
            if (foundPreclaimedSeat) {
                setPreclaimedSeat({
                    seatStatus: foundPreclaimedSeat.seatStatus,
                    timeslotItem: foundPreclaimedSeat.timeslotItem,
                    isLoading: foundPreclaimedSeat.isLoading,
                });
            } else {
                setPreclaimedSeat(undefined);
            }

            const foundConfirmedSeat = rowData.find(
                (x) => x.seatStatus.reservationState === ReservationState.Confirmed,
            );
            if (foundConfirmedSeat) {
                setConfirmedSeat({
                    seatStatus: foundConfirmedSeat.seatStatus,
                    timeslotItem: foundConfirmedSeat.timeslotItem,
                    isLoading: foundConfirmedSeat.isLoading,
                });
            } else {
                setConfirmedSeat(undefined);
            }
        }
    }, [rowData]);

    useEffect(() => {
        if (columnReservationRecord?.state === ReservationState.Cancelled) {
            removeCancelledReservations();
        } else if (columnReservationRecord) {
            if (props.reservationRecords) {
                props.setReservationRecords({
                    reservationRecords: [
                        ...props.reservationRecords.reservationRecords,
                        columnReservationRecord,
                    ],
                });
            } else {
                props.setReservationRecords({
                    reservationRecords: [columnReservationRecord],
                });
            }
        }
    }, [columnReservationRecord]);

    useEffect(() => {
        if (props.rows) {
            setRowData(props.rows);
        }
    }, [props.rows]);

    function updateState(
        rows: ICalendarRow[],
        seatId: string,
        ss?: ISeatStatus,
        ld?: boolean,
    ): ICalendarRow[] {
        return rows.map((x) =>
            x.seatId === seatId
                ? {
                      ...x,
                      seatStatus: ss ?? x.seatStatus,
                      isLoading: ld ?? x.isLoading,
                  }
                : x,
        );
    }

    function updateMySeat(
        seatStatus: ISeatStatus,
        rsvpType: ReservationState,
        loading?: boolean,
    ): void {
        if (rowData && isMounted()) {
            const newSeatStatus = {
                seatId: seatStatus.seatId,
                status: SeatStatuses.MySeat.value,
                reservationState: rsvpType,
            };
            setRowData((state) => updateState(state, seatStatus.seatId, newSeatStatus, loading));
        }
    }

    function updateCancel(seatStatus: ISeatStatus, loading?: boolean): void {
        if (rowData && isMounted()) {
            const newSeatStatus = {
                seatId: seatStatus.seatId,
                status: originalStatusText(seatStatus.seatId),
                reservationState: undefined,
            };
            setRowData((state) => updateState(state, seatStatus.seatId, newSeatStatus, loading));
        }
    }

    function updateLoading(seatStatus: ISeatStatus, loading: boolean): void {
        if (rowData && isMounted()) {
            setRowData((state) => updateState(state, seatStatus.seatId, undefined, loading));
        }
    }

    const originalStatusText = (seatId: string): string => {
        return getAvailableSeatStatus(props.facilitySeats.find((x) => x.id === seatId));
    };

    async function preclaim(cell: IReservationCalendarCell): Promise<void> {
        try {
            updateMySeat(cell.seatStatus, ReservationState.Preclaimed, true);
            const preclaimRecord: ICalendarReservationRecord = await FacilitiesClient.preclaimSeatReservation(
                authContext,
                userContext,
                props.selectedFacility.id,
                cell.seatStatus.seatId,
                cell.timeslotItem.startDateTimeUTCMilliseconds,
            );

            if (preclaimRecord) {
                preclaimRecord.isIcm =
                    cell.seatStatus.status === SeatStatuses.AvailableForICM.value;

                if (isMounted()) {
                    setColumnReservationRecord(preclaimRecord);
                }
            }
        } catch (error) {
            console.log('An error occurred when preclaiming a timeslot:'); // TODO: Improve error messaging
            console.error(error);
            updateCancel(cell.seatStatus);
        } finally {
            updateLoading(cell.seatStatus, false);
        }
    }

    async function cancel(cell: IReservationCalendarCell): Promise<void> {
        try {
            updateCancel(cell.seatStatus, true);
            const reservationId = generateReservationId(
                props.selectedFacility.id,
                cell.seatStatus.seatId,
                cell.timeslotItem.startDateTimeUTCMilliseconds,
            );

            await FacilitiesClient.cancelSeatReservation(authContext, userContext, reservationId);

            const reservationRecord = props.reservationRecords?.reservationRecords.find(
                (record) => record?.id === reservationId,
            );
            if (reservationRecord) {
                const cancelledReservationRecord = {
                    ...reservationRecord,
                    state: ReservationState.Cancelled,
                };
                setColumnReservationRecord(cancelledReservationRecord);
            }
        } catch (error) {
            console.log('We encountered some difficulties cancelling your seat reservation.'); // TODO: Improve error messaging
            console.error(error);
            if (cell.seatStatus.reservationState === ReservationState.Preclaimed) {
                updateMySeat(cell.seatStatus, ReservationState.Preclaimed);
            } else if (cell.seatStatus.reservationState === ReservationState.Confirmed) {
                updateMySeat(cell.seatStatus, ReservationState.Confirmed);
            }
        } finally {
            updateLoading(cell.seatStatus, false);
        }
    }

    function canFetch(): boolean {
        return !rowData.some((x) => x.isLoading);
    }

    function clicked(clickedCell: IReservationCalendarCell): void {
        if (!canFetch()) {
            return;
        }

        if (
            clickedCell.seatStatus.reservationState === undefined ||
            clickedCell.seatStatus.reservationState === null
        ) {
            if (!(preclaimedSeat || confirmedSeat)) {
                preclaim(clickedCell);
            } else {
                move(clickedCell);
            }
        } else if (clickedCell.seatStatus.reservationState === ReservationState.Preclaimed) {
            cancel(clickedCell);
        } else if (clickedCell.seatStatus.reservationState === ReservationState.Confirmed) {
            setCancelReservationDialogOpen(true);
            setCancelReservationCell(clickedCell);
        }
    }

    function move(cell: IReservationCalendarCell): void {
        if (!cell) {
            return;
        }

        if (preclaimedSeat) {
            movePreclaimedSeats(preclaimedSeat, cell);
        } else if (confirmedSeat) {
            if (cell.seatStatus.status === SeatStatuses.AvailableForICM.value) {
                if (isMounted()) {
                    setCurrentCell(cell);
                    setNewSeat(props.facilitySeats.find((x) => x.id === cell.seatStatus.seatId));
                    setOldSeat(
                        props.facilitySeats.find((x) => x.id === confirmedSeat.seatStatus.seatId),
                    );
                    setShowChangeSeatIcmModal(true);
                }
            } else {
                moveConfirmedSeat(confirmedSeat, cell, false);
            }
        }
    }

    async function movePreclaimedSeats(
        previousSeat: ICell,
        nextSeat: IReservationCalendarCell,
    ): Promise<void | undefined> {
        try {
            updateMySeat(nextSeat.seatStatus, ReservationState.Preclaimed, true);
            updateCancel(previousSeat.seatStatus, true);

            const cancelRequestReservationId = generateReservationId(
                props.selectedFacility!.facilityId,
                previousSeat.seatStatus.seatId,
                previousSeat.timeslotItem.startDateTimeUTCMilliseconds,
            );

            await FacilitiesClient.cancelSeatReservation(
                authContext,
                userContext,
                cancelRequestReservationId,
            ).then(() => {
                if (columnReservationRecord) {
                    const canceledColumnReservationRecord = {
                        ...columnReservationRecord,
                        state: ReservationState.Cancelled,
                    };
                    setColumnReservationRecord(canceledColumnReservationRecord);
                }
            });

            await FacilitiesClient.preclaimSeatReservation(
                authContext,
                userContext,
                props.selectedFacility!.id,
                nextSeat.seatStatus.seatId,
                nextSeat.timeslotItem.startDateTimeUTCMilliseconds,
            ).then((res) => updateReservationRecordAfterPreclaimedMove(res, nextSeat));
        } catch (err) {
            console.log('Error moving timeslot:'); // TODO: Improve error messaging
            console.error(err);
            props.setErrorProp(true);
            updateMySeat(nextSeat.seatStatus, ReservationState.Preclaimed);
            updateCancel(nextSeat.seatStatus);
        } finally {
            updateLoading(previousSeat.seatStatus, false);
            updateLoading(nextSeat.seatStatus, false);
        }
    }

    function updateReservationRecordAfterPreclaimedMove(
        res: ICalendarReservationRecord,
        next: ICell,
    ): void {
        if (isMounted()) {
            if (res) {
                res.isIcm = next.seatStatus.status === SeatStatuses.AvailableForICM.value;
            }
            setColumnReservationRecord(res);
        }
    }

    async function moveConfirmedSeat(
        previousSeat: ICell,
        nextSeat: IReservationCalendarCell,
        needsIcmDescription: boolean,
    ): Promise<void> {
        try {
            updateMySeat(nextSeat.seatStatus, ReservationState.Confirmed, true);
            updateCancel(previousSeat.seatStatus, true);

            let request: IReservationSeatChangeRequest = {
                facilityId: props.selectedFacility.id,
                oldSeatId: previousSeat.seatStatus.seatId,
                newSeatId: nextSeat.seatStatus.seatId,
                timeslot: nextSeat.timeslotItem.startDateTimeUTCMilliseconds,
            };
            if (needsIcmDescription) {
                request = {
                    ...request,
                    icmDescription: changeSeatIcmDescription,
                };
            }
            await FacilitiesClient.changeSeatReservation(authContext, userContext, request);
        } catch (error) {
            console.log('Failed to move confirmed seat'); // TODO: Improve error messaging
            console.error(error);
            props.setErrorProp(true);
            updateMySeat(previousSeat.seatStatus, ReservationState.Confirmed);
        } finally {
            updateLoading(previousSeat.seatStatus, false);
            updateLoading(nextSeat.seatStatus, false);
        }
    }

    function removeCancelledReservations(): void {
        const activeReservations = props.reservationRecords?.reservationRecords.filter(
            (record) =>
                record.claimCode.code !== columnReservationRecord!.claimCode.code &&
                record.state !== ReservationState.Cancelled,
        );
        props.setReservationRecords({ reservationRecords: activeReservations! });
    }

    async function onConfirmedOrCancelledSeatChangeWithICM(
        conclusion: ModalConclusion,
    ): Promise<void> {
        if (isMounted()) {
            if (conclusion === ModalConclusion.Done && confirmedSeat && currentCell && newSeat) {
                updateMySeat(currentCell.seatStatus, ReservationState.Confirmed, false);
                updateCancel(confirmedSeat.seatStatus, false);
                setChangeSeatIcmDescription(undefined);
            }
            setOldSeat(undefined);
            setNewSeat(undefined);
            setShowChangeSeatIcmModal(false);
        }
    }

    function onCancelledReservation(conclusion: ModalConclusion): void {
        if (conclusion === ModalConclusion.Done && cancelReservationCell) {
            updateCancel(cancelReservationCell.seatStatus, false);

            const reservationId = generateReservationId(
                props.selectedFacility.id,
                cancelReservationCell.seatStatus.seatId,
                cancelReservationCell.timeslotItem.startDateTimeUTCMilliseconds,
            );
            const reservationRecord = props.reservationRecords?.reservationRecords.find(
                (x) => x.id === reservationId,
            );
            if (reservationRecord) {
                setColumnReservationRecord({
                    ...reservationRecord,
                    state: ReservationState.Cancelled,
                });
            }
        }

        // Reset regardless of whether reservation was cancelled or not
        setCancelReservationDialogOpen(false);
        setCancelReservationCell(undefined);
    }

    if (rowData) {
        return (
            <Stack style={{ paddingLeft: '16px' }}>
                <Stack.Item align='center'>
                    <Label>{props.columnTitle}</Label>
                </Stack.Item>
                {rowData.map((value, index) => (
                    <Stack.Item
                        key={`${index}-${value.seatStatus?.seatId}-${value.timeslotItem?.startDateTimeUTCMilliseconds}`}
                        style={{ paddingTop: '10px' }}>
                        {showChangeSeatIcmModal && newSeat && oldSeat && confirmedSeat && (
                            <ChangeReservationModal
                                iseatRecords={props.facilitySeats}
                                reservationInstructions={{
                                    facility: props.selectedFacility,
                                    reschedule: [
                                        {
                                            currentTimeSlot: {
                                                seatStatus: {
                                                    seatId: oldSeat.id,
                                                    status: 'Unknown',
                                                },
                                                timeslotItem: {
                                                    endDateTimeUTCMilliseconds:
                                                        confirmedSeat?.timeslotItem
                                                            .endDateTimeUTCMilliseconds ?? 0,
                                                    startDateTimeUTCMilliseconds:
                                                        confirmedSeat?.timeslotItem
                                                            .startDateTimeUTCMilliseconds ?? 0,
                                                },
                                            },
                                            changeToSeatId: newSeat.id,
                                            facility: props.selectedFacility,
                                        },
                                    ],
                                }}
                                onModalConcluded={
                                    onConfirmedOrCancelledSeatChangeWithICM
                                }></ChangeReservationModal>
                        )}
                        <ReservationCalendarCell
                            {...value}
                            facilitySeats={props.facilitySeats}
                            preclaimedSeat={preclaimedSeat}
                            confirmedSeat={confirmedSeat}
                            clickHandler={clicked}
                            isUserBlockedFromReserving={props.isUserBlockedFromReserving}
                        />
                    </Stack.Item>
                ))}
                {isCancelReservationDialogOpen && props.selectedFacility && cancelReservationCell && (
                    <CancelReservationModalActionButton
                        iseatRecords={props.facilitySeats}
                        reservationInstructions={{
                            facility: props.selectedFacility,
                            cancel: [
                                {
                                    facility: props.selectedFacility,
                                    timeslot: {
                                        seatStatus: cancelReservationCell.seatStatus,
                                        timeslotItem: cancelReservationCell.timeslotItem,
                                    },
                                },
                            ],
                        }}
                        onModalConcluded={onCancelledReservation}
                    />
                )}
            </Stack>
        );
    } else {
        return (
            <>
                <h1>No data</h1>
            </>
        );
    }
}
