import React, { useState, useEffect, useContext } from 'react';
import EmployeeMiniCard from 'components/common/employee/employee-mini-card';
import { PersonaSize } from '@fluentui/react';
import { AuthContext } from 'contexts/auth-context';
import EmployeeClient, { AllEmployeeEditableFields } from 'clients/employee-client';
import { getImageUrlFromAlias } from 'utils/photo-utils';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import config from 'environments/environment';
import { isGUID } from 'utils/string-utils';
import { IPreHire } from 'components/screening/us-gov/IScreening';

export interface CandidateColumnContentProps {
    personnelId?: string;
    personnelAlias?: string;
    preHire?: IPreHire;
    displayActions?: boolean;
    imageSize?: PersonaSize;
    showImage?: boolean;
    showAlias?: boolean;
    isInitialEmployeeBatchLoaded?: boolean;
}
export function CandidateColumnContent(props: CandidateColumnContentProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const [isInitialEmployeeBatchLoaded] = useState<boolean>(
        props.isInitialEmployeeBatchLoaded === undefined
            ? true
            : props.isInitialEmployeeBatchLoaded,
    );
    const [preHire] = useState(props.preHire);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [showAlias, setShowAlias] = useState(props.showAlias);
    const [alias, setAlias] = useState('');
    const [personnelId, setPersonnelId] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        let isMounted = true;

        async function populatePrehireData(): Promise<void> {
            setShowAlias(false);

            // TODO once prehire flag is enabled permanently, remove the if clause and the entire else case
            if (config.personnelProfile.prehireFlag && props.personnelId) {
                try {
                    const editableEmployeeResponse = await EmployeeClient.getEditableEmployeeDataByIdOrAliasOrGUID(
                        authContext,
                        props.personnelId,
                        Object.values(AllEmployeeEditableFields),
                    );
                    const localDisplayName = getDisplayNameOrDefault(
                        {
                            firstName: editableEmployeeResponse?.employeeEditableInfo.firstName,
                            lastName: editableEmployeeResponse?.employeeEditableInfo.lastName,
                        },
                        editableEmployeeResponse?.id,
                    );
                    const fullDisplayName = `${localDisplayName} (${
                        editableEmployeeResponse.employeeEditableInfo.positionNumber
                            ? editableEmployeeResponse.employeeEditableInfo.positionNumber
                            : 'No PCN'
                    })`;

                    if (isMounted) {
                        setDisplayName(fullDisplayName);
                        setPersonnelId(editableEmployeeResponse.id);
                    }
                } catch (e) {
                    console.error('GetEditableEmployeeDataByIdOrAliasOrGUID error: ', e);
                }
            } else {
                const localDisplayName = createPreHireDisplayName(
                    preHire?.firstName,
                    preHire?.middleName,
                    preHire?.lastName,
                );
                const fullDisplayName = `${localDisplayName} (${
                    preHire?.pcn ? preHire.pcn : 'No PCN'
                })`;

                if (isMounted) {
                    setDisplayName(fullDisplayName);
                }
            }
        }

        async function populateFteData(): Promise<void> {
            let employee;
            if (props.personnelId) {
                try {
                    const basicResult = await EmployeeClient.getBasicEmployeesById(authContext, [
                        props.personnelId,
                    ]);
                    employee = basicResult[0];
                } catch {
                    console.error('Error fetching employee data by personnel id');
                }
            } else if (props.personnelAlias) {
                try {
                    const basicResult = await EmployeeClient.getBasicEmployeesByAlias(authContext, [
                        props.personnelAlias,
                    ]);
                    employee = basicResult[0];
                } catch {
                    console.error('Error fetching employee data by alias');
                }
            }

            if (employee && isMounted) {
                setAlias(employee.onPremisesSamAccountName);
                setPersonnelId(employee.id);
                setDisplayName(getDisplayNameOrDefault(employee, employee.id));
                setImageUrl(getImageUrlFromAlias(employee.onPremisesSamAccountName));
            }
        }

        if (!props.personnelId || isGUID(props.personnelId)) {
            populatePrehireData();
        } else {
            if (isInitialEmployeeBatchLoaded) {
                populateFteData();
            }
        }
        return (): void => {
            isMounted = false;
        };
    }, [isInitialEmployeeBatchLoaded]);

    // TODO once prehire flag is enabled permanently, everyting can be consolidated as the if case, i.e. remove the conditional check and else case completely
    return !props.preHire || config.personnelProfile.prehireFlag ? (
        <EmployeeHoverCard
            personnelId={personnelId}
            isInitialEmployeeBatchLoaded={isInitialEmployeeBatchLoaded}>
            <EmployeeMiniCard
                imageSize={props.imageSize}
                showAlias={showAlias}
                showImage={props.showImage}
                employee={{
                    alias: alias,
                    displayName: displayName,
                }}
                imageUrl={imageUrl}
                key={`hover_card_${alias}`}
            />
        </EmployeeHoverCard>
    ) : (
        <EmployeeMiniCard
            imageSize={props.imageSize}
            showAlias={showAlias}
            showImage={props.showImage}
            employee={{
                alias: alias,
                displayName: displayName,
            }}
            imageUrl={imageUrl}
            key={`hover_card_${alias}`}
        />
    );
}

function createPreHireDisplayName(
    firstName?: string,
    middleName?: string,
    lastName?: string,
): string {
    let displayName = '';
    if (firstName) {
        displayName = firstName;
    }
    if (middleName) {
        displayName = displayName + ' ' + middleName;
    }
    if (lastName) {
        displayName = displayName + ' ' + lastName;
    }
    return displayName;
}
