import React, { useState } from 'react';
import { Location } from 'history';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import AllowListAdd from 'components/screening/cloud-screening/admin/allow-list-add';
import AllowListsTable from 'components/screening/cloud-screening/admin/allow-list-table';

export interface CloudScreeningAdminPageProps {
    pageName: string;
    location: Location;
}

export default function CloudScreeningAdminPage(props: CloudScreeningAdminPageProps): JSX.Element {
    const [shouldTriggerAllowListRefresh, setShouldTriggerAllowListRefresh] = useState<boolean>(
        false,
    );

    return (
        <SidebarAndContents>
            <SidebarPane>
                <AllowListAdd
                    onEmployeeSelected={(emp) =>
                        setShouldTriggerAllowListRefresh(!shouldTriggerAllowListRefresh)
                    }
                />
            </SidebarPane>

            <ContentPane>
                <AllowListsTable triggerAllowListRefresh={shouldTriggerAllowListRefresh} />
            </ContentPane>
        </SidebarAndContents>
    );
}
