import { IconNames } from 'assets/constants/global-constants';
import { ISeatRecord } from 'clients/facilities-client';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { handleUTCToFacilityTimeZone } from 'components/facilities/common/facility-time-utils';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import React, { useContext, useEffect, useState } from 'react';
import { TimeFormats, timeZoneAbbr } from 'utils/time-utils';
import {
    IReservationInstructions,
    IReservationPromiseInstruction,
    createCancelPromises,
    findSeatNames,
} from 'components/facilities/facilities-reservations/modals/facilities-reservation-instruction-utils';

export interface CancelReservationModalActionButtonProps<T> {
    reservationInstructions: IReservationInstructions;
    iseatRecords:
        | ISeatRecord[]
        | {
              id: string;
              seatName: string;
          }[];
    onModalConcluded: (
        modalConclusion: ModalConclusion,
        reservationResults: IReservationPromiseInstruction[],
    ) => void;
    renderWithoutButton?: boolean;
    buttonType?: 'primary' | 'default' | undefined;
    buttonText?: string;
    iconType?: string;
    buttonDisabled?: boolean;
}

export default function CancelReservationModalActionButton<T>(
    props: CancelReservationModalActionButtonProps<T>,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [reservationPromiseInstructions, setReservationPromiseInstructions] = useState<
        IReservationPromiseInstruction[]
    >([]);

    async function onSubmit(): Promise<void> {
        try {
            if (props.reservationInstructions) {
                const cancels = createCancelPromises(
                    authContext,
                    userContext,
                    props.reservationInstructions.cancel,
                );

                await Promise.all(cancels?.map((x) => x.promise));
                setReservationPromiseInstructions(cancels);
            }
        } catch (error) {
            throw 'We encountered some difficulties canceling your seat reservation.';
        }
    }

    function onCancel(): void {
        setReservationPromiseInstructions([]);
    }

    const [modalConclusion, setModalConclusion] = useState<ModalConclusion>();

    function onModalConcluded(modalConclusion: ModalConclusion): void {
        setModalConclusion(modalConclusion);
    }

    useEffect(() => {
        if (modalConclusion === ModalConclusion.Done && reservationPromiseInstructions.length > 0) {
            props.onModalConcluded(modalConclusion, reservationPromiseInstructions);
        } else if (modalConclusion === ModalConclusion.Cancel) {
            props.onModalConcluded(modalConclusion, []);
        }
    }, [modalConclusion, reservationPromiseInstructions]);

    function isReservationPassed(): boolean {
        const currentTimeEpoch = new Date().getTime();
        const endDateTimeUTCMilliseconds = Math.min(
            ...(props.reservationInstructions.cancel?.map(
                (x) => x.timeslot.timeslotItem.endDateTimeUTCMilliseconds,
            ) ?? []),
        );

        return currentTimeEpoch > endDateTimeUTCMilliseconds;
    }

    function getModalSubtext(): JSX.Element {
        if (props.reservationInstructions.cancel) {
            const facilityRecord = props.reservationInstructions.facility;

            const cancels = props.reservationInstructions.cancel ?? [];

            const startDateTimeUTCMilliseconds = Math.min(
                ...cancels.map((x) => x.timeslot.timeslotItem.startDateTimeUTCMilliseconds),
            );

            const endDateTimeUTCMilliseconds = Math.max(
                ...cancels.map((x) => x.timeslot.timeslotItem.endDateTimeUTCMilliseconds),
            );

            const seatNames =
                findSeatNames(
                    cancels?.map((x) => x.timeslot.seatStatus.seatId) ?? [],
                    props.iseatRecords,
                ).join(',') ?? '';
            return (
                <>
                    Are you sure you would like to cancel your reservation for the seat(s){' '}
                    {seatNames} at facility {facilityRecord.facilityName} on{' '}
                    {handleUTCToFacilityTimeZone(
                        startDateTimeUTCMilliseconds,
                        facilityRecord,
                        TimeFormats.ddd_DD_MMM_YYYY,
                        'Unknown',
                    )}{' '}
                    from{' '}
                    {handleUTCToFacilityTimeZone(
                        startDateTimeUTCMilliseconds,
                        facilityRecord,
                        TimeFormats.H_mmA,
                        'Unknown',
                    )}{' '}
                    to{' '}
                    {handleUTCToFacilityTimeZone(
                        endDateTimeUTCMilliseconds,
                        facilityRecord,
                        TimeFormats.H_mmA,
                        'Unknown',
                    )}{' '}
                    {timeZoneAbbr(facilityRecord?.timeZone)}?
                </>
            );
        } else {
            return <></>;
        }
    }

    return (
        <ModalActionButton
            text={props.buttonText ?? 'Cancel'}
            iconName={props.iconType ?? IconNames.Trash}
            buttonType={props.buttonType ?? undefined}
            submitButtonIcon={props.iconType ?? IconNames.Trash}
            tooltip={`Click here to cancel your reservation(s)`}
            modalTitle={'Confirming your cancelation'}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onModalConcluded={onModalConcluded}
            renderWithoutButton={props.renderWithoutButton}
            enableSubmit={!isReservationPassed()}
            enable={!props.buttonDisabled}>
            {getModalSubtext()}
        </ModalActionButton>
    );
}
