import { Dictionary } from 'assets/constants/global-constants';
import { IClearanceRecord } from 'clients/clearance-client';
import ReportsClient, { IHierarchy } from 'clients/reports-client';
import { IAuthContext } from 'contexts/auth-context';
import { DAY_IN_MILLISECONDS } from 'utils/time-utils';
import React, { SetStateAction } from 'react';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { IContract } from 'components/screening/us-gov/IContract';
import { ICacheContext } from 'contexts/cache-context';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import { IEmployeeIdentifier } from 'components/screening/common/common-constants';
import EmployeeClient, { IEmployee } from 'clients/employee-client';
import { BrowserCacheKeys } from 'utils/browser-cache-utils';
import { ISuitabilityRecord } from 'clients/suitability-client';
import {
    IEmployeeNameAndType,
    getEmployeeType,
} from 'components/common/employee/internal-employee-utils';

export type LatestHierarchyReport = {
    combinedHierarchyData: Dictionary<IHierarchy> | undefined;
    parsedHierarchyData: Dictionary<IHierarchy> | undefined;
};

/**
 * Create collection of processed hierarchy report data.
 * @param rawData Collection of Candidate or Clearance Record items that will be processed.
 * @param authContext Authorization context.
 * @param storedRecords Dictionary of IHierarchy items in JSON string format.
 * @param recordTimestamp Timestamp of hierarchy Reports.
 * @returns Object containing either combined hierarcy data or parsed hierarchy data.
 */
export async function generateLatestHierarchyReport(
    rawData: (IClearanceRecord | ICommonScreening | ISuitabilityRecord)[],
    authContext: IAuthContext,
    storedRecords: string | null,
    recordTimestamp: string | null,
): Promise<LatestHierarchyReport | undefined> {
    if (
        storedRecords &&
        recordTimestamp &&
        recordTimestamp > (Date.now() - DAY_IN_MILLISECONDS).toString()
    ) {
        const reports: LatestHierarchyReport = {
            combinedHierarchyData: undefined,
            parsedHierarchyData: undefined,
        };

        const currentPersonnelIDs = rawData.map((x) => x.personnelId);
        const parsedHierarchyData: Dictionary<IHierarchy> = JSON.parse(storedRecords);
        const storedIds = Object.keys(parsedHierarchyData);
        const remainingNoHierarchyData = currentPersonnelIDs.filter((x) => !storedIds.includes(x));
        // if there is some employees with missing hierarchy data compared to the cache
        // then lets grab those specific employee's hierarchy data and update the cache
        if (remainingNoHierarchyData.length > 0) {
            const newReportData = await ReportsClient.getSelectHierarchy(
                authContext,
                remainingNoHierarchyData,
            );
            const combinedHierarchyData: Dictionary<IHierarchy> = Object.assign(
                parsedHierarchyData,
                newReportData,
            );
            reports.combinedHierarchyData = combinedHierarchyData;
        } else {
            reports.parsedHierarchyData = parsedHierarchyData;
        }

        return reports;
    }

    return undefined;
}

/**
 * Update localstorage with Hierarchy report data.
 * @param hierarchyReport Colleciton of hierarchy data to store.
 * @param localStorage Local storage object.
 */
export function updateHierarchyCache(
    hierarchyReport: Dictionary<IHierarchy>,
    localStorage: Storage,
): void {
    localStorage.setItem(BrowserCacheKeys.hierarchyReportTimestampKey, Date.now().toString());
    localStorage.setItem(
        BrowserCacheKeys.hierarchyReportStorageAndCacheKey,
        JSON.stringify(hierarchyReport),
    );
}

export async function getLatestHierarchyReport(
    rawCandidates: ICommonScreening[],
    authContext: IAuthContext,
    setHierarchyRecords: React.Dispatch<SetStateAction<Dictionary<IHierarchy> | undefined>>,
    updateMessage: (error: boolean, message: string) => void,
): Promise<void> {
    const storedRecords = localStorage.getItem(BrowserCacheKeys.hierarchyReportStorageAndCacheKey);
    const recordTimestamp = localStorage.getItem(BrowserCacheKeys.hierarchyReportTimestampKey);

    try {
        const generatedReports = await generateLatestHierarchyReport(
            rawCandidates,
            authContext,
            storedRecords,
            recordTimestamp,
        );
        if (generatedReports) {
            if (
                generatedReports.combinedHierarchyData &&
                generatedReports.combinedHierarchyData.length > 0
            ) {
                updateHierarchyCache(generatedReports.combinedHierarchyData, localStorage);
                setHierarchyRecords(generatedReports.combinedHierarchyData);
            } else {
                setHierarchyRecords(generatedReports.parsedHierarchyData);
            }
        } else {
            // if there is no cached report or if the latest report is from more than 24hrs
            // then just get latest hierarchy report for employees in aggregate report
            await updateCacheWithLatestHierarchyData(authContext, setHierarchyRecords);
        }
    } catch (e) {
        // if it exists, use whatever stored data is available
        if (recordTimestamp && storedRecords) {
            const parsedHierarchyData: Dictionary<IHierarchy> = JSON.parse(storedRecords);
            setHierarchyRecords(parsedHierarchyData);
            // log the error if they still was able to parse some hierarchy data
            console.error('Could not retrieve or request any hierarchy reports', e);
        } else {
            updateMessage(true, 'Could not retrieve or request any hierarchy reports');
        }
    }
}

async function updateCacheWithLatestHierarchyData(
    authContext: IAuthContext,
    setHierarchyRecords: React.Dispatch<SetStateAction<Dictionary<IHierarchy> | undefined>>,
): Promise<void> {
    const reportData = await ReportsClient.getEmployeeHierarchyData(authContext);
    setHierarchyRecords(reportData);
    if (Object.keys(reportData).length > 0) {
        updateHierarchyCache(reportData, localStorage);
    }
}

// since in public trust we only get the contract id we need to pass it forward for our filters.
export async function getContracts(
    screeningArray: ICommonScreening[],
    cacheContext: ICacheContext,
    cacheKeyContract: string,
    authContext: IAuthContext,
): Promise<Dictionary<IContract>> {
    const contractIdSet: Dictionary<string> = {};
    screeningArray
        .filter((records): boolean => (records.contractId ? true : false))
        .forEach((x) => {
            contractIdSet[x.contractId] = x.contractId;
        });
    let uniqueContractIds: string[] = Object.values(contractIdSet);
    const contractDictionary = cacheContext.retrieve<Dictionary<IContract>>(cacheKeyContract) || {};
    uniqueContractIds = uniqueContractIds.filter((x) => !contractDictionary[x]);
    const newContracts =
        uniqueContractIds.length > 0
            ? await UsGovScreeningClient.getContractsByContractIds(
                  authContext,
                  uniqueContractIds,
                  true,
              )
            : [];
    newContracts.forEach((contract) => {
        contractDictionary[contract.id] = contract;
    });
    return contractDictionary;
}

function mapEmployeesWithHierarchy(
    employees: IEmployee[],
    newNameMap: Map<string, IEmployeeNameAndType>,
    hierarchyEmployeesAliasDict: Dictionary<string>,
): void {
    employees.forEach((employee) => {
        const l1 = hierarchyEmployeesAliasDict[employee.hierarchyLvl1] || '';
        const l2 = hierarchyEmployeesAliasDict[employee.hierarchyLvl2] || '';
        const l3 = hierarchyEmployeesAliasDict[employee.hierarchyLvl3] || '';
        const l4 = hierarchyEmployeesAliasDict[employee.hierarchyLvl4] || '';
        const l5 = hierarchyEmployeesAliasDict[employee.hierarchyLvl5] || '';
        const employeeData: IEmployeeNameAndType = {
            displayName: getDisplayNameOrDefault(employee, employee.onPremisesSamAccountName),
            alias: employee.alias,
            firstName: employee.firstName,
            middleName: employee.middleName,
            lastName: employee.lastName,
            type: getEmployeeType(employee),
            l1,
            l2,
            l3,
            l4,
            l5,
        };

        newNameMap.set(employee.id, employeeData);
    });
}

export async function getHierarchyEmployeeAliasDict(
    cacheContext: ICacheContext,
    authContext: IAuthContext,
    cacheKeyHierarchyAlias: string,
    cacheKeyExportFullEmployeeData: string,
    employeeIdToNameMap: Map<string, IEmployeeIdentifier>,
    setEmployeeNames: React.Dispatch<React.SetStateAction<Map<string, IEmployeeNameAndType>>>,
): Promise<Dictionary<string>> {
    const hierarchyEmployeesAliasDict =
        cacheContext.retrieve<Dictionary<string>>(cacheKeyHierarchyAlias) || {};
    const cachedFullEmployeeNames = cacheContext.retrieve<Map<string, IEmployeeNameAndType>>(
        cacheKeyExportFullEmployeeData,
    );
    // use cached employee data if available
    if (cachedFullEmployeeNames) {
        Array.from(cachedFullEmployeeNames.keys()).forEach((employeeId) => {
            const emp = cachedFullEmployeeNames.get(employeeId);
            const employeeData: IEmployeeIdentifier = {
                alias: emp?.alias,
                lastName: emp?.lastName,
                firstName: emp?.firstName,
                middleName: emp?.middleName,
                l1: emp?.l1,
                l2: emp?.l2,
                l3: emp?.l3,
                l4: emp?.l4,
                l5: emp?.l5,
            };
            employeeIdToNameMap.set(employeeId, employeeData);
        });
    } else {
        try {
            const employeeIds = Array.from(employeeIdToNameMap.keys());
            // if no cached data then make backend calls
            // do one api call for all employee data Non-Prehires
            const employees = await EmployeeClient.getEmployeesByIds(authContext, employeeIds);
            const hierarchyEmployeesPersonnelIdToFetch = new Set<string>();

            employees.forEach((employee) => {
                hierarchyEmployeesPersonnelIdToFetch.add(`${employee.hierarchyLvl1}`);
                hierarchyEmployeesPersonnelIdToFetch.add(`${employee.hierarchyLvl2}`);
                hierarchyEmployeesPersonnelIdToFetch.add(`${employee.hierarchyLvl3}`);
                hierarchyEmployeesPersonnelIdToFetch.add(`${employee.hierarchyLvl4}`);
                hierarchyEmployeesPersonnelIdToFetch.add(`${employee.hierarchyLvl5}`);
            });
            const hierarchyEmployees = await EmployeeClient.getEmployeesByIds(
                authContext,
                Array.from(hierarchyEmployeesPersonnelIdToFetch),
            );
            hierarchyEmployees.forEach((emp) => {
                hierarchyEmployeesAliasDict[emp.id] = emp.alias;
            });
            employees.forEach((employee) => {
                const l1 = hierarchyEmployeesAliasDict[employee.hierarchyLvl1] || '';
                const l2 = hierarchyEmployeesAliasDict[employee.hierarchyLvl2] || '';
                const l3 = hierarchyEmployeesAliasDict[employee.hierarchyLvl3] || '';
                const l4 = hierarchyEmployeesAliasDict[employee.hierarchyLvl4] || '';
                const l5 = hierarchyEmployeesAliasDict[employee.hierarchyLvl5] || '';
                employeeIdToNameMap.set(employee.id, {
                    alias: employee.alias,
                    lastName: employee.lastName,
                    firstName: employee.firstName,
                    middleName: employee.middleName,
                    l1,
                    l2,
                    l3,
                    l4,
                    l5,
                });
            });
            // update cache
            const newNameMap: Map<string, IEmployeeNameAndType> = new Map(cachedFullEmployeeNames);
            mapEmployeesWithHierarchy(employees, newNameMap, hierarchyEmployeesAliasDict);
            mapEmployeesWithHierarchy(hierarchyEmployees, newNameMap, hierarchyEmployeesAliasDict);
            setEmployeeNames(newNameMap);
            cacheContext.store<Map<string, IEmployeeNameAndType>>(
                cacheKeyExportFullEmployeeData,
                newNameMap,
            );
        } catch (e) {
            console.error('Failed to grab and build employee Information for Excel');
        }
    }
    return hierarchyEmployeesAliasDict;
}

export async function getL1ToL5Data(
    authContext: IAuthContext,
    hierarchyEmployeesAliasDict: Dictionary<string>,
    employeeDto: IEmployee,
): Promise<{
    l1: string;
    l2: string;
    l3: string;
    l4: string;
    l5: string;
}> {
    const hierarchyArr: string[] = [];

    let l1 = hierarchyEmployeesAliasDict[employeeDto.hierarchyLvl1];
    let l2 = hierarchyEmployeesAliasDict[employeeDto.hierarchyLvl2];
    let l3 = hierarchyEmployeesAliasDict[employeeDto.hierarchyLvl3];
    let l4 = hierarchyEmployeesAliasDict[employeeDto.hierarchyLvl4];
    let l5 = hierarchyEmployeesAliasDict[employeeDto.hierarchyLvl5];
    if (l1 === undefined) {
        if (employeeDto.hierarchyLvl1 === -1) {
            l1 = '';
        } else {
            hierarchyArr.push(employeeDto.hierarchyLvl1.toString());
        }
    }
    if (l2 === undefined) {
        if (employeeDto.hierarchyLvl2 === -1) {
            l2 = '';
        } else {
            hierarchyArr.push(employeeDto.hierarchyLvl2.toString());
        }
    }
    if (l3 === undefined) {
        if (employeeDto.hierarchyLvl3 === -1) {
            l3 = '';
        } else {
            hierarchyArr.push(employeeDto.hierarchyLvl3.toString());
        }
    }
    if (l4 === undefined) {
        if (employeeDto.hierarchyLvl4 === -1) {
            l4 = '';
        } else {
            hierarchyArr.push(employeeDto.hierarchyLvl4.toString());
        }
    }
    if (l5 === undefined) {
        if (employeeDto.hierarchyLvl5 === -1) {
            l5 = '';
        } else {
            hierarchyArr.push(employeeDto.hierarchyLvl5.toString());
        }
    }
    // one last shot to get the employee ids
    if (hierarchyArr.length > 0) {
        try {
            const hierarchyEmployees = await EmployeeClient.getEmployeesByIds(
                authContext,
                hierarchyArr,
            );
            hierarchyEmployees.forEach((emp) => {
                hierarchyEmployeesAliasDict[emp.id] = emp.alias;
            });
            if (l1 === undefined) {
                l1 = hierarchyEmployeesAliasDict[employeeDto.hierarchyLvl1] || '';
            }
            if (l2 === undefined) {
                l2 = hierarchyEmployeesAliasDict[employeeDto.hierarchyLvl2] || '';
            }
            if (l3 === undefined) {
                l3 = hierarchyEmployeesAliasDict[employeeDto.hierarchyLvl3] || '';
            }
            if (l4 === undefined) {
                l4 = hierarchyEmployeesAliasDict[employeeDto.hierarchyLvl4] || '';
            }
            if (l5 === undefined) {
                l5 = hierarchyEmployeesAliasDict[employeeDto.hierarchyLvl5] || '';
            }
        } catch (e) {
            console.error(`error grabbing hierarchy employee data ${hierarchyArr.join()}`, e);
        }
    }
    return {
        l1,
        l2,
        l3,
        l4,
        l5,
    };
}
