import React, { useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { IconNames } from 'assets/constants/global-constants';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { IBasicEmployee } from 'clients/employee-client';
import ScaClient, { IEmployeeReview } from 'clients/sca-client';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { mergeStyleSets } from '@fluentui/react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IScaDeleteEmployeeModalProps {
    employee: IBasicEmployee;
    isCurrent: boolean;
    reviewPeriod: IEmployeeReview;
    onModalConcluded: (modalConclusion: ModalConclusion, employeeId: string) => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ScaViewDeleteEmployeeModalButton(props: IScaDeleteEmployeeModalProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const disableSubmit = (): boolean => {
        return !props.isCurrent;
    };

    const onSubmit = async (): Promise<void> => {
        if (disableSubmit()) return;
        // Tech Debt
        // The following code relies on ModalActionButton to catch error.
        // A better way to handle the error is to get text of the error
        // and throw it. ModalActionButton will then catch and display it
        // on a banner on the modal.
        await ScaClient.deleteEmployeeReviewRecord(authContext, props.employee.id);
    };

    const onModalConcluded = (conclusion: ModalConclusion): void => {
        if (props.onModalConcluded) {
            props.onModalConcluded(conclusion, props.employee.id);
        }
    };

    return (
        <ModalActionButton
            text='Delete'
            iconName={IconNames.Delete}
            modalTitle={`Delete`}
            modalTitleIcon={IconNames.Delete}
            enableSubmit={!disableSubmit()}
            submitButtonIcon={IconNames.Delete}
            submitButtonText='Delete Employee'
            onSubmit={onSubmit}
            onModalConcluded={onModalConcluded}>
            <div className={styles.container}>
                <span className={styles.span}>Remove&nbsp;</span>
                <div>
                    <EmployeeBasicHoverCard
                        key={props.employee.id}
                        employeeBasicData={props.employee}
                    />
                </div>
                <span className={styles.span}>&nbsp;from {props.reviewPeriod.reviewPeriodId}?</span>
            </div>
        </ModalActionButton>
    );
}

const styles = mergeStyleSets({
    container: {
        display: 'flex',
        alignContent: 'center',
    },
    span: {
        paddingTop: 1,
    },
});
