import {
    ComboBox,
    IDropdownOption,
    IComboBoxStyles,
    TextField,
    IComboBox,
    IComboBoxOption,
} from '@fluentui/react';
import React, { memo, useMemo, useState } from 'react';
import { countryPhoneCodes } from 'components/forms/country-code';
import { ElementProps } from 'components/forms/element-viewer';

function TextFieldViewer(props: ElementProps): JSX.Element {
    const { element, updateFormResponse } = props;
    const [countryCode, setCountryCode] = useState<string>(
        element.validatorOptions?.value === 'phone' &&
            element.value &&
            (element.value as string).split('.').length === 2
            ? (element.value as string).split('.')[0]
            : '1', // default to US country code if not defined
    );

    const onChangeTextField = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void => {
        if (
            newValue &&
            element.validatorOptions?.type === 'limit' &&
            newValue.length > element.validatorOptions.value
        ) {
            return;
        }

        if (element.validatorOptions?.value === 'phone') {
            // don't allow the user to put a '.' it messes up the input completely
            if (newValue?.split('.').length !== 1) {
                return;
            }
            const countryCodeStr = countryCode !== '' ? `${countryCode}.` : '';
            updateFormResponse(element, `${countryCodeStr}${newValue}`);
        } else {
            updateFormResponse(element, newValue ?? '');
        }
    };

    const onChangeCountryCode = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
    ): void => {
        if (option === undefined || element.validatorOptions?.value !== 'phone') {
            return;
        }
        if (option !== undefined) {
            setCountryCode(`${option.key}`);
            let currentValue = element.value as string;
            if (currentValue) {
                const currentValSplit = currentValue.split('.');
                if (currentValSplit.length === 2) {
                    currentValue = currentValSplit[1];
                }
            }
            const countryCode = option.key !== '' ? `${option.key}.` : '';
            updateFormResponse(element, `${countryCode}${currentValue ? currentValue : ''}` ?? '');
        }
    };

    const showCharLimit = useMemo((): string | undefined => {
        return element.validatorOptions?.type === 'limit'
            ? `${element.value?.length ?? 0}/${element.validatorOptions?.value as string}`
            : undefined;
    }, [element.value?.length]);

    const countryDropdown: IDropdownOption[] = countryPhoneCodes.map((country) => {
        const key = country.code;
        const text = key && country.name ? `${country.name} (+${key})` : '';
        return {
            key: key,
            text: text,
        };
    });
    const dropdownStyles: Partial<IComboBoxStyles> = {
        root: { width: 100 },
        optionsContainer: { overflow: 'visible', whiteSpace: 'normal' },
    };
    if (element.validatorOptions?.value === 'phone') {
        const value = element.value as string;
        return (
            <div style={{ display: 'flex', gap: '.5rem' }}>
                <ComboBox
                    options={countryDropdown}
                    ariaLabel='Country Code'
                    allowFreeform
                    autoComplete='on'
                    styles={dropdownStyles}
                    onRenderLabel={() => <div>Country Code</div>}
                    text={countryCode ? `+${countryCode}` : ''}
                    onChange={onChangeCountryCode}
                />
                <TextField
                    style={{ maxWidth: '865px' }}
                    styles={{ root: { paddingTop: '22.4px' } }}
                    value={
                        (value && value.split('.').length === 2 ? value.split('.')[1] : value) ?? ''
                    }
                    placeholder='Enter your answer'
                    onChange={(ev, newValue) => onChangeTextField(ev, newValue)}
                    suffix={showCharLimit}
                />
            </div>
        );
    }

    return (
        <div style={{ maxWidth: '865px' }}>
            <TextField
                value={(element.value as string) ?? ''}
                placeholder='Enter your answer'
                onChange={(ev, newValue) => onChangeTextField(ev, newValue)}
                suffix={showCharLimit}
            />
        </div>
    );
}

export default memo(TextFieldViewer);
