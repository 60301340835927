import { ICustomBreadcrumbItem } from 'components/common/bread-crumb';

type ScreeningUrlsType = {
    managePageUrl: (tab?: string, filterSettings?: URLSearchParams) => string;
};

// eslint-disable-nextline @typescript-eslint/no-explicit-any
export const ScreeningURLs: ScreeningUrlsType = {
    managePageUrl: (tab?: string, filterSettings?: URLSearchParams): string => {
        const urlStr = filterSettings ? filterSettings.toString() : '';
        if (!tab) {
            return '/screening-v2';
        }
        if (!urlStr) {
            return `/screening-v2/${tab}`;
        }
        return `/screening-v2/${tab}?${urlStr}`;
    },
};

const managePageRootBreadcrumb = [
    {
        title: 'Screening V2',
        link: '',
    },
];

type ScreeningBreadcrumbKeys = 'managePageRoot' | 'managePageTab';

// eslint-disable-nextline @typescript-eslint/no-explicit-any
export const ScreeningBreadcrumbs: Record<
    ScreeningBreadcrumbKeys,
    (...rest: any[]) => ICustomBreadcrumbItem[]
> = {
    managePageRoot: () => [...managePageRootBreadcrumb],

    managePageTab: (tab: string) => [
        ...managePageRootBreadcrumb,
        {
            title: tab,
            link: '',
        },
    ],
};
