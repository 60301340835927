import React, { ReactNode, useState } from 'react';
import {
    ActionButton,
    PrimaryButton,
    ITooltipHostStyles,
    DefaultButton,
    TooltipHost,
} from '@fluentui/react';
import Modal, { ModalSizeType } from 'components/common/modal';
import { canReadErrorMessageBody, readErrorMessageBody } from 'utils/misc-utils';
import { useIsMounted } from 'utils/misc-hooks';
import { SetStateFunc } from 'types/global-types';

/**
 * This module provides an action button that, upon clicking, opens up a modal
 * using our very own common component <Modal>. Using this module helps the
 * developer avoid the extra code required to keep track of opening and
 * closing the modal, submitting the action, and processing error received
 * from performing the action and displaying error message on the modal,
 *
 * The common component <Modal> has more props that are not used in this
 * module. Feel free to add if necessary.
 */

/**
 *
 * @param modalConclusion This module calls prop.onModalConcluded and sets the parameter
 *                        "modalConclusion" to 'ModalConclusion.Done' if the user clicked the
 *                        "submit" button and the operation finished successfully. It sets
 *                        it to 'ModalConclusion.Cancel' if the user clicked the "cancel" button.
 *
 * @param result          Return result, if any.
 *
 */

export enum ModalConclusion {
    Done = 0,
    Cancel = 1,
}

export enum WhichSubmitButton {
    One = 0, // This is the default
    Two = 1,
}

export type onModalConcludeType<T> = (
    modalConclusion: ModalConclusion,
    result?: T | undefined,
    whichSubmitButton?: WhichSubmitButton,
) => void;

export interface IModalActionButton<T> {
    text: string; // What text to show on the action button, eg, "Add Item".
    size?: ModalSizeType;
    fixWidth?: boolean;
    enable?: boolean; // Enable the button to open the modal. Default: true.
    tooltip?: string; // If specified, a tool tip opens on the button when mouse hovers over it.
    iconName: string; // Icon to be used for the button.
    modalTitle: string | JSX.Element; // Will appear as title of the dialog box (modal).
    modalTitleIcon?: string; // If specified, will appear next to title of the dialog box (modal).
    modalSubtitle?: string; // If specified, will drive prop "subTitle" of <Modal>.
    errorMsg?: string; // If specified, will appear on the error message bar.
    buttonType?: 'primary' | 'default' | undefined; // defaults to ActionButton
    buttonStyle?: string; // Will be applied to prop "className" of instance of the button.
    enableNext?: boolean; // Enable the "Next" button, if one is displayed.
    enableSubmit: boolean;
    enableSubmit2?: boolean;
    showSubmit2?: boolean;
    shouldHideCancelButton?: boolean; // Can be used to hide Cancel button when submit button is "Close".
    enableCancel?: boolean; // Default true.
    containerStyle?: string; // Will be applied to prop "className" of the top <div> container of the module.
    tooltipStyles?: Partial<ITooltipHostStyles>; // If specified, will be applied to the tooltip host component.
    submitErrorMsg?: string; // If specified, shows this error message if submit action failed.
    submitButtonText?: string; // Will appear on the "submit" button of the dialog box (modal).
    submitButton2Text?: string; // Will appear on the 2'nd "submit" button of the dialog box (modal).
    cancelButtonText?: string; // Will appear on the "cancel" button of the dialog box (modal).
    submitButtonIcon?: string; // If specified, will appear on the "submit" button of the dialog box (modal).
    submitButton2Icon?: string; // If specified, will appear on the 2'nd "submit" button of the dialog box (modal).
    // After a successful submit, ie, no catch after submit, the modal closes.
    // If you want it to stay open, provide the following prop.
    keepOpenAfterSubmit?: boolean;
    // The following prop allows the coder to use all the good stuff this component has to offer
    // but make it appear open right off the bat, so that user doesn't have to click a button
    // to open it.
    renderWithoutButton?: boolean;

    // onMiscButton1 is useful when you want to show an auxiliary button
    // not covered by other buttons.
    // First usage: Button "Add" for adding "Optional email notification".
    onMiscButton1?: () => Promise<void>;
    miscButton1Text?: string;
    miscButton1Icon?: string;
    enableMisc1?: boolean;

    // onLeftMiscButton1 is useful when you want to show an auxiliary button next to the "Cancel" button
    // not covered by other buttons.
    // First usage: Button "Member Check" for going back to the Bulk Member Check Modal.
    onLeftMiscButton1?: () => Promise<void>;
    leftMiscButton1Text?: string;
    leftMiscButton1Icon?: string;
    enableLeftMisc1?: boolean;

    // Props "onNext" and "onBack" are useful when implementing a "wizard",
    // ie, a multi staged, multi page modal like the one used for
    // "SCA -> Manage -> Create Review".

    nextButtonText?: string;
    // If you want to show a "Next" button, specify a click handler for it here.
    // If the following is specified, <Modal> will show the "Next" button
    // instead of the "Submit" button.
    onNext?: () => Promise<void>;
    // If you want to show a "Back" button, specify a click handler for it here.
    // If the following is specified, <Modal> will show the "Back" button.
    onBack?: () => Promise<void>;
    onBackText?: string; // If specified, it will be the button text. Default text is "Back".
    // In most cases, parent wants to call a certain function
    // when user clicks the submit button when the modal is open.
    // Pass that function to onSubmit.
    onSubmit: (whichSubmitButton?: WhichSubmitButton) => Promise<T | void>;
    // If parent wants to call a function when user clicks the cancel button,
    // pass the function to onCancel.
    onCancel?: () => void;
    // If parent wants to call a function when user clicks the action button,
    // pass the function to onButtonClick.
    onButtonClick?: () => void;

    onResetErrorMsg?: () => void;

    // this will serve as a redirect button
    onRedirect?: () => Promise<void>;

    closeOnRedirect?: boolean; // If parent needs to close the modal on Redirect
    onRedirectText?: string; // If specified, it will be the button text. Default text is "Redirect".

    isBlocking?: boolean; // can the dialog be dismissed by clicking outside of it

    // See definition of onModalConcludeType above. Once the modal
    // concludes (eg, submit finished, or user clicked "Close")
    // this module will call this prop.
    onModalConcluded: onModalConcludeType<T>;

    // Displays these on the modal. Example: input boxes, check boxes,
    // radio buttons, pulldowns.
    // They should have their own event handlers in the parent component.
    children?: ReactNode;
}

export default function ModalActionButton<T>(props: IModalActionButton<T>): JSX.Element {
    const [errorMsg, setStateErrorMsg] = useState<string | undefined>();
    const [isVisible, setStateIsVisible] = useState<boolean>(props.renderWithoutButton ?? false);
    const [isForcedDisabled, setStateIsForcedDisabled] = useState<boolean>(false);
    const [isSubmitInProgress, setStateIsSubmitInProgress] = useState<boolean>(false);

    const isMounted = useIsMounted();

    function createSetStateFunction<T>(setState: SetStateFunc<T>): SetStateFunc<T> {
        return (value: React.SetStateAction<T>): void => {
            if (isMounted()) {
                setState(value);
            }
        };
    }

    const setErrorMsg = createSetStateFunction<string | undefined>(setStateErrorMsg);
    const setIsVisible = createSetStateFunction<boolean>(setStateIsVisible);
    const setIsForcedDisabled = createSetStateFunction<boolean>(setStateIsForcedDisabled);
    const setIsSubmitInProgress = createSetStateFunction<boolean>(setStateIsSubmitInProgress);

    const disableSubmit = (): boolean => !props.enableSubmit || isForcedDisabled;
    const disableSubmit2 = (): boolean => !props.enableSubmit2 || isForcedDisabled;

    const onCancel = (): void => {
        props.onCancel && props.onCancel();
        props.onModalConcluded && props.onModalConcluded(ModalConclusion.Cancel);
        resetErrorMsg();
        setIsVisible(false);
    };

    const onSubmit1 = (): Promise<void> => onSubmit(WhichSubmitButton.One);
    const onSubmit2 = props.showSubmit2
        ? (): Promise<void> => onSubmit(WhichSubmitButton.Two)
        : undefined;

    const onSubmit = async (whichSubmitButton: WhichSubmitButton): Promise<void> => {
        setIsForcedDisabled(true);
        setIsSubmitInProgress(true);
        resetErrorMsg();
        try {
            const results = await props.onSubmit(whichSubmitButton);
            setIsForcedDisabled(false);
            if (!props.keepOpenAfterSubmit) {
                setIsVisible(false);
            }
            if (props.onModalConcluded) {
                if (results) {
                    await props.onModalConcluded(ModalConclusion.Done, results, whichSubmitButton);
                } else {
                    await props.onModalConcluded(
                        ModalConclusion.Done,
                        undefined,
                        whichSubmitButton,
                    );
                }
            }
        } catch (submitErrorEvent) {
            if (props.submitErrorMsg) {
                setErrorMsg(props.submitErrorMsg);
            } else if (typeof submitErrorEvent === 'string') {
                // Note (+)
                // If processing errors is necessary for a particular end point,
                // the best practice is, the instantiator of this module should
                // capture text of the error event and throw it. This part of
                // the code will then display the error on the modal by using
                // component MessageBar.
                setErrorMsg(submitErrorEvent);
            } else {
                // Error messages received from different end points are vastly
                // different and therefore very difficult to process when you
                // don't know what they will look like.
                // The following, for sure, will not work for all sorts of errors.
                try {
                    if (submitErrorEvent.message && typeof submitErrorEvent.message === 'string') {
                        setErrorMsg(submitErrorEvent.message);
                    } else if (
                        submitErrorEvent.text &&
                        typeof submitErrorEvent.text === 'function'
                    ) {
                        const submitErrorEventText = await submitErrorEvent.text();
                        try {
                            const errMsgObj = JSON.parse(submitErrorEventText);
                            let errMsgText = JSON.stringify(errMsgObj?.errors);
                            errMsgText += errMsgObj?.title;
                            setErrorMsg(errMsgText);
                        } catch {
                            setErrorMsg(submitErrorEventText || 'Error occurred');
                        }
                    } else if (canReadErrorMessageBody(submitErrorEvent)) {
                        const submitErrorEventText = await readErrorMessageBody(submitErrorEvent);
                        setErrorMsg(submitErrorEventText);
                    } else {
                        setErrorMsg('Error occurred');
                    }
                } catch {
                    setErrorMsg('Error occurred');
                }
            }
            setIsForcedDisabled(false);
        } finally {
            setIsSubmitInProgress(false);
        }
    };

    const resetErrorMsg = (): void => {
        setErrorMsg('');
        props.onResetErrorMsg && props.onResetErrorMsg();
    };

    const onClick = (): void => {
        setIsVisible(true);
        if (props.onButtonClick) {
            props.onButtonClick();
        }
    };

    const onRedirect = (): void => {
        if (props.onRedirect) {
            props.onRedirect();
            if (props.closeOnRedirect) {
                setIsVisible(false);
            }
        }
    };

    function setButton() {
        switch (props.buttonType) {
            case 'primary':
                return PrimaryButton;
            case 'default':
                return DefaultButton;
            default:
                return ActionButton;
        }
    }

    const renderTheButton = (): JSX.Element => {
        if (props.renderWithoutButton) {
            return <></>;
        } else {
            const theButton = setButton();
            return React.createElement(theButton, {
                'aria-label': props.text,
                disabled: !(props.enable ?? true),
                className: props.buttonStyle,
                text: props.text,
                onClick: onClick,
                iconProps: { iconName: props.iconName },
            });
        }
    };

    return (
        <div className={props.containerStyle}>
            <TooltipHost content={props.tooltip} styles={props.tooltipStyles}>
                {renderTheButton()}
                <Modal
                    size={props.size}
                    fixWidth={props.fixWidth}
                    isOpen={isVisible}
                    onMiscButton1={props.onMiscButton1}
                    isMiscButton1Disabled={!(props.enableMisc1 ?? true)}
                    miscButton1Text={props.miscButton1Text}
                    miscButton1Icon={props.miscButton1Icon}
                    onLeftMiscButton1={props.onLeftMiscButton1}
                    isLeftMiscButton1Disabled={!(props.enableLeftMisc1 ?? true)}
                    leftMiscButton1Text={props.leftMiscButton1Text}
                    leftMiscButton1Icon={props.leftMiscButton1Icon}
                    nextButtonText={props.nextButtonText}
                    onNext={props.onNext}
                    onBack={props.onBack}
                    onBackText={props.onBackText}
                    onRedirect={props.onRedirect ? onRedirect : undefined}
                    onRedirectText={props.onRedirectText}
                    onCancel={onCancel}
                    onDismiss={onCancel}
                    onSubmit={onSubmit1}
                    onSubmit2={onSubmit2}
                    onResetErrorMsg={resetErrorMsg}
                    errorMsg={errorMsg || (props.errorMsg ?? '')}
                    submitButtonText={props.submitButtonText ?? 'Submit'}
                    submitButton2Text={props.submitButton2Text}
                    cancelButtonText={props.cancelButtonText}
                    submitButtonIcon={props.submitButtonIcon}
                    submitButton2Icon={props.submitButton2Icon}
                    showProgressIndicator={isSubmitInProgress}
                    shouldHideCancelButton={props.shouldHideCancelButton}
                    isNextButtonDisabled={!props.enableNext}
                    isSubmitButtonDisabled={disableSubmit()}
                    isSubmitButton2Disabled={disableSubmit2()}
                    isCancelButtonDisabled={!(props.enableCancel ?? true)}
                    title={props.modalTitle}
                    titleIcon={props.modalTitleIcon}
                    subTitle={props.modalSubtitle}
                    fluentModalProps={
                        !!props.isBlocking ? { isBlocking: props.isBlocking } : undefined
                    }>
                    <div>{props.children}</div>
                </Modal>
            </TooltipHost>
        </div>
    );
}
