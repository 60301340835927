import React from 'react';
import { TextField, Stack } from '@fluentui/react';
import { stackChildrenGap } from 'components/personnel-profile/common-personnel-profile-styles';
export interface CreateNewProfileStepperPageOneProps {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    suffix?: string;
    nationalIdNumber?: string;
    onFirstNameChange: (newValue?: string) => void;
    onMiddleNameChange: (newValue?: string) => void;
    onLastNameChange: (newValue?: string) => void;
    onSuffixChange: (newValue?: string) => void;
    onNationalIdNumberChange: (newValue?: string) => void;
}

export default function CreateNewProfileStepperPageOne(
    props: CreateNewProfileStepperPageOneProps,
): JSX.Element {
    return (
        <Stack tokens={stackChildrenGap}>
            <TextField
                label='First Name'
                required
                value={props.firstName || ''}
                onChange={(ev, newValue): void => props.onFirstNameChange(newValue)}
                placeholder='Enter First Name'
            />
            <TextField
                label='Middle Name'
                value={props.middleName || ''}
                onChange={(ev, newValue): void => props.onMiddleNameChange(newValue)}
                placeholder='Enter Middle Name'
            />
            <TextField
                label='Last Name'
                required
                value={props.lastName || ''}
                onChange={(ev, newValue): void => props.onLastNameChange(newValue)}
                placeholder='Enter Last Name'
            />
            <TextField
                label='Suffix'
                value={props.suffix || ''}
                onChange={(ev, newValue): void => props.onSuffixChange(newValue)}
                placeholder='Enter Suffix (e.g. Jr. Sr. III)'
            />
            <TextField
                label='Social Security Number'
                required
                value={props.nationalIdNumber || ''}
                onChange={(ev, newValue): void => props.onNationalIdNumberChange(newValue)}
                placeholder='Enter SSN, must be in ###-##-#### format; e.g. 123-45-6789'
            />
        </Stack>
    );
}
