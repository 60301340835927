import { CommandBar, ICommandBarItemProps, Stack, Toggle } from '@fluentui/react';
import React from 'react';
import { FormElement, FormElementValues, UpdatableProperty } from 'components/forms/forms-common';

type ElementFooterProps = {
    updateForm: (id: string, newValue: FormElementValues, operation: UpdatableProperty) => void;
    element: FormElement;
    hasRequiredToggle: boolean;
    CustomElement?: () => JSX.Element;
    overflowItems?: ICommandBarItemProps[] | undefined;
};

function ElementFooter(props: ElementFooterProps): JSX.Element {
    const { updateForm, element, hasRequiredToggle, CustomElement, overflowItems } = props;

    return (
        <Stack
            horizontal
            tokens={{ childrenGap: 15 }}
            style={{ height: '4.5rem', alignItems: 'center' }}>
            {hasRequiredToggle && (
                <Toggle
                    checked={element.required}
                    label={<div style={{ fontWeight: 400 }}>Required</div>}
                    onChange={(ev, checked) => {
                        if (checked !== undefined) {
                            updateForm(element.id, checked, 'required');
                        }
                    }}
                />
            )}
            {CustomElement && CustomElement()}
            {overflowItems && (
                <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
                    <CommandBar
                        items={[]}
                        overflowItems={overflowItems}
                        ariaLabel='Element actions'
                    />
                </div>
            )}
        </Stack>
    );
}

export default React.memo(ElementFooter);
