import {
    ActionButton,
    ColumnActionsMode,
    CommandBar,
    FluentTheme,
    IButtonStyles,
    ICommandBarItemProps,
    IContextualMenuProps,
    SelectionMode,
    Toggle,
    mergeStyleSets,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { IconNames, xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import { CoreAttributeSetsClient } from 'clients/core/personnel-core-client-wrappers';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import EllipsisText from 'components/common/ellipsis-text';
import { fetchPictures } from 'components/common/employee/employee-utils';
import { TableCell } from 'components/common/table';
import { ActionNotAllowedAttributeSetModal } from 'components/core/attribute-sets/action-not-allowed-attribute-set-modal';
import AddEditAttributeSetsPanel, {
    AttributeSetModalType,
} from 'components/core/attribute-sets/add-edit-attribute-sets-panel';
import AttributeSetFilterPanel from 'components/core/attribute-sets/attribute-set-filter-panel';
import { AttributeSetActionModal } from 'components/core/attribute-sets/attribute-set-action-modal';
import { RemoveAttributeSetModal } from 'components/core/attribute-sets/remove-attribute-set-modal';
import { CoreEmployeeHoverCardFromGraph } from 'components/core/common/employee-card/core-employee-hover-card';
import {
    FilterableTable,
    IFilter,
    IFilterableColumn,
} from 'components/core/common/filterableTable';
import { LastModifiedBy, TimeUnit } from 'components/core/common/last-modified-by';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { CacheContext } from 'contexts/cache-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import { AttributeStatus, GetAllAttributeSetsResult } from 'personnel-core-clients';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { excelFormatHeader } from 'utils/reporting-utils';
import { getAttributeSetReportData } from 'utils/cor-reports';

const DEFAULT_NO_DATA_TEXT = 'No data available';
const DESCRIPTION_VALUE_LIMIT = 70;
const viewAttributeSetIcon = IconNames.FabricFolder;

export function AttributeSetsList(): JSX.Element {
    const authContext = useContext(AuthContext);
    const principalContext = useContext(PrincipalUserContext);
    const cacheContext = useContext(CacheContext);
    const attributeSetsClient = new CoreAttributeSetsClient(authContext);
    const history = useHistory();
    const breadCrumbContext = useContext(BreadCrumbContext);

    const [attributeSets, setAttributeSets] = useState<GetAllAttributeSetsResult[]>([]);
    const [displayedAttributeSets, setDisplayedAttributeSets] = useState<
        GetAllAttributeSetsResult[]
    >([]);
    const [filteredAttributeSets, setFilteredAttributeSets] = useState<GetAllAttributeSetsResult[]>(
        [],
    );

    const [showDeactivatedSets, { toggle: toggleShowDeactivatedSets }] = useBoolean(false);
    const [showErrors, setShowErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedAttributeSet, setSelectedAttributeSet] = useState<
        GetAllAttributeSetsResult | undefined
    >(undefined);
    const [openModalType, setOpenModalType] = useState<AttributeSetModalType>();
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [filters, setFilters] = useState<Map<string, IFilter>>(new Map<string, IFilter>());
    const [shouldShowDelete, setShouldShowDelete] = useState<boolean>(false);
    const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState<boolean>(false);
    const [shouldShowDeactivate, setShouldShowDeactivate] = useState<boolean>(false);
    const [isReactivateModalOpen, setIsReactivateModalOpen] = useState(false);

    const isAdmin = useMemo(() => {
        // This is temporary until we get a role for Core Admin in the portal app or figure out another way to do this
        return (
            authContext.isInRole(Role.PortalAdmin) || authContext.isInRole(Role.CoreAttributeAdmin)
        );
    }, [authContext]);

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([
            { title: 'Home', link: '/home' },
            { title: 'Attributes', link: '' },
            { title: 'Attribute sets', link: '' },
        ]);

        setOpenModalType(undefined);
        fetchData();
    }, []);

    useEffect(() => {
        setDisplayedAttributeSets(
            attributeSets.filter((set) => (showDeactivatedSets ? !set.isActive : set.isActive)),
        );
    }, [attributeSets, showDeactivatedSets]);

    const fetchData = async (): Promise<void> => {
        setIsLoading(true);
        try {
            const response = await attributeSetsClient.getAll();
            setAttributeSets(response ?? []);
            await cacheUserPhotos(response ?? []);
        } catch (e) {
            console.log(e);
            setShowErrors(true);
            setErrorMessage('An error occurred retrieving attribute sets.');
        } finally {
            setIsLoading(false);
        }
    };

    const cacheUserPhotos = async (
        attributeSetResults: GetAllAttributeSetsResult[],
    ): Promise<void> => {
        // cache user photos (owners, managers, lastModifiedBy)
        const users = attributeSetResults
            .map((x) => x.owners.concat(x.managers).concat(x.lastModifiedBy))
            .flat();
        const usersSet = new Set(users);

        await fetchPictures(
            authContext,
            Array.from(usersSet).map((ownerOid) => ({
                id: ownerOid,
                oid: ownerOid,
            })),
            undefined,
            cacheContext,
        );
    };

    const editDisabled = useCallback((): boolean => {
        return (
            selectedAttributeSet === undefined ||
            (!selectedAttributeSet.owners.includes(principalContext.principalRecord.oid ?? '') &&
                !isAdmin)
        );
    }, [selectedAttributeSet, isAdmin]);

    const gotoAttributeSetDetails = useCallback(
        (item?: GetAllAttributeSetsResult): void => {
            if (item && item?.id) {
                history.push(`/profile/attributesets/${item?.id}/attributes`);
            }
        },
        [history],
    );

    const commandBarLeftItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        return [
            {
                key: 'add',
                text: 'Add Attribute set',
                onClick: () => setOpenModalType(AttributeSetModalType.Add),
                iconProps: { iconName: IconNames.Add },
                disabled: !isAdmin,
                leftCommandBarButtonStyle,
            },
            {
                key: 'edit',
                text: 'Edit Attribute set',
                onClick: () => setOpenModalType(AttributeSetModalType.Edit),
                iconProps: { iconName: IconNames.EditNoFill },
                disabled: editDisabled(),
            },
            {
                key: 'view',
                text: 'View Attribute set',
                iconProps: { iconName: viewAttributeSetIcon },
                onClick: () => gotoAttributeSetDetails(selectedAttributeSet),
                disabled: selectedAttributeSet === undefined,
            },
        ];
    }, [editDisabled, isAdmin, selectedAttributeSet, gotoAttributeSetDetails]);

    const commandBarRightItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        const commandBarRightItems: ICommandBarItemProps[] = [];

        if (
            isAdmin ||
            attributeSets.some(
                (item) =>
                    !item.isActive &&
                    item.owners.includes(principalContext.principalRecord.oid ?? ''),
            )
        ) {
            commandBarRightItems.push({
                key: 'deactivatedSets',
                commandBarButtonAs: () => (
                    <div style={{ marginTop: '6px' }}>
                        <Toggle
                            label='View deactivated sets'
                            inlineLabel
                            onText='Yes'
                            offText='No'
                            checked={showDeactivatedSets}
                            onChange={toggleShowDeactivatedSets}
                        />
                    </div>
                ),
            });
        }

        commandBarRightItems.push({
            key: 'filter',
            text: 'Filter',
            iconProps: { iconName: IconNames.Filter },
            onClick: () => setIsFilterOpen(true),
        });

        commandBarRightItems.push({
            key: 'export',
            commandBarButtonAs: () => (
                <div style={{ marginTop: '2px' }}>
                    <ExportToExcelButton
                        buttonTitle='Download report'
                        buttonType='action'
                        getData={async () => {
                            return await getAttributeSetReportData(
                                authContext,
                                filteredAttributeSets,
                            );
                        }}
                        fileNamePrefix='attribute-sets'
                        formatHeader={true}
                        formatType={excelFormatHeader.microsoftBrandCase}
                    />
                </div>
            ),
        });

        return commandBarRightItems;
    }, [attributeSets, filteredAttributeSets, isAdmin]);

    const columnsFilterable = useMemo(() => {
        return [
            {
                key: AttributeSetsListColumnKeys.AttributeSet,
                name: columnNames.AttributeSet,
                fieldName: columnFieldNames.AttributeSet,
                ariaLabel: columnNames.AttributeSet,
                minWidth: columnWidths.attributeSet,
                maxWidth: columnWidths.attributeSet * xLargeMaxWidthCoeff,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: GetAllAttributeSetsResult): JSX.Element => {
                    return (
                        <TableCell className={styles.detailsListField}>
                            <ActionButton
                                iconProps={{ iconName: viewAttributeSetIcon }}
                                style={{
                                    height: 'auto',
                                    padding: 0,
                                    verticalAlign: 'middle',
                                }}
                                onClick={(): void => gotoAttributeSetDetails(item)}>
                                {item.name}
                            </ActionButton>
                        </TableCell>
                    );
                },
                isFilterable: true,
                filterFunction(item: GetAllAttributeSetsResult, filter: IFilter): boolean {
                    if (filter.values.length > 0) {
                        return filter.values.some((f) =>
                            item.name.toLocaleLowerCase().includes(f.toLocaleLowerCase()),
                        );
                    }
                    return true;
                },
            },
            {
                key: AttributeSetsListColumnKeys.Description,
                name: columnNames.Description,
                fieldName: columnFieldNames.Description,
                ariaLabel: columnNames.Description,
                minWidth: columnWidths.description,
                maxWidth: columnWidths.description * xLargeMaxWidthCoeff,
                isRowHeader: false,
                isResizable: true,
                onRender: (item: GetAllAttributeSetsResult): JSX.Element => {
                    return (
                        <TableCell
                            className={styles.detailsListField}
                            toolTipText={item.description}>
                            <EllipsisText
                                text={item.description}
                                textLengthBeforeEllipsis={DESCRIPTION_VALUE_LIMIT}
                            />
                        </TableCell>
                    );
                },
                isFilterable: true,
                filterFunction(item: GetAllAttributeSetsResult, filter: IFilter): boolean {
                    if (filter.values.length > 0) {
                        return filter.values.some((f) =>
                            item.description.toLocaleLowerCase().includes(f.toLocaleLowerCase()),
                        );
                    }
                    return true;
                },
            },
            {
                key: AttributeSetsListColumnKeys.Owners,
                name: columnNames.Owners,
                fieldName: columnNames.Owners,
                ariaLabel: columnNames.Owners,
                minWidth: columnWidths.owners,
                maxWidth: columnWidths.owners * xLargeMaxWidthCoeff,
                isRowHeader: false,
                isResizable: true,
                columnActionsMode: ColumnActionsMode.disabled,
                onRender: (item: GetAllAttributeSetsResult): JSX.Element => {
                    return (
                        <TableCell className={styles.detailsListField}>
                            <div className={styles.coreEmployeeHoverCardDiv}>
                                {item.owners.map((o) => {
                                    return (
                                        <CoreEmployeeHoverCardFromGraph
                                            key={o}
                                            oid={o}
                                            showFullName={item.owners.length === 1}
                                            showMiniCardAlias={item.owners.length === 1}
                                        />
                                    );
                                })}
                            </div>
                        </TableCell>
                    );
                },
                isFilterable: true,
                filterFunction(item: GetAllAttributeSetsResult, filter: IFilter): boolean {
                    if (filter.values.length > 0) {
                        return filter.values.some((f) => item.owners.includes(f));
                    }
                    return true;
                },
            },
            {
                key: AttributeSetsListColumnKeys.Managers,
                name: columnNames.Managers,
                fieldName: columnNames.Managers,
                ariaLabel: columnNames.Managers,
                minWidth: columnWidths.managers,
                maxWidth: columnWidths.managers * xLargeMaxWidthCoeff,
                isRowHeader: false,
                isResizable: true,
                columnActionsMode: ColumnActionsMode.disabled,
                onRender: (item: GetAllAttributeSetsResult): JSX.Element => {
                    return (
                        <TableCell className={styles.detailsListField}>
                            <div className={styles.coreEmployeeHoverCardDiv}>
                                {item.managers.map((m) => {
                                    return (
                                        <CoreEmployeeHoverCardFromGraph
                                            key={m}
                                            oid={m}
                                            showMiniCardAlias={item.owners.length === 1}
                                            showFullName={item.managers.length === 1}
                                        />
                                    );
                                })}
                            </div>
                        </TableCell>
                    );
                },
                isFilterable: true,
                filterFunction(item: GetAllAttributeSetsResult, filter: IFilter): boolean {
                    if (filter.values.length > 0) {
                        return filter.values.some((f) => item.managers.includes(f));
                    }
                    return true;
                },
            },
            {
                key: AttributeSetsListColumnKeys.Readers,
                name: columnNames.Readers,
                fieldName: columnNames.Readers,
                ariaLabel: columnNames.Readers,
                minWidth: -1, // hidden column
                maxWidth: -1, // hidden column
                isRowHeader: false,
                isResizable: true,
                isFilterable: true,
                columnActionsMode: ColumnActionsMode.disabled,
                filterFunction(item: GetAllAttributeSetsResult, filter: IFilter): boolean {
                    if (filter.values.length > 0) {
                        return filter.values.some((f) => item.readers.includes(f));
                    }
                    return true;
                },
                isHidden: true,
            },
            {
                key: AttributeSetsListColumnKeys.LastModifiedBy,
                name: columnNames.LastModifiedBy,
                fieldName: columnFieldNames.LastModifiedBy,
                ariaLabel: columnNames.LastModifiedBy,
                minWidth: columnWidths.lastModifiedBy,
                maxWidth: columnWidths.lastModifiedBy * xLargeMaxWidthCoeff,
                isRowHeader: false,
                isResizable: true,
                onRender: (item: GetAllAttributeSetsResult): JSX.Element => {
                    return (
                        <TableCell className={styles.detailsListField}>
                            <LastModifiedBy lastModified={item} timeUnit={TimeUnit.Seconds} />
                        </TableCell>
                    );
                },
                isFilterable: true,
                filterFunction(item: GetAllAttributeSetsResult, filter: IFilter): boolean {
                    let startDate = 0;
                    let endDate = 0;
                    if (filter.values.length > 1) {
                        startDate = filter.values[0] !== '0' ? parseInt(filter.values[0]) : 0;
                        endDate =
                            filter.values[1] !== '0'
                                ? parseInt(filter.values[1])
                                : Number.MAX_SAFE_INTEGER;
                        return (
                            item.lastModifiedDate >= startDate && item.lastModifiedDate <= endDate
                        );
                    }
                    return true;
                },
            },
            {
                key: columnNames.Actions,
                name: columnNames.Actions,
                ariaLabel: columnNames.Actions,
                minWidth: columnWidths.actions,
                maxWidth: columnWidths.actions * xLargeMaxWidthCoeff,
                isResizable: true,
                columnActionsMode: ColumnActionsMode.disabled,
                onRender: (item: GetAllAttributeSetsResult): JSX.Element => {
                    const contextualMenuItems: IContextualMenuProps = {
                        items: [
                            {
                                key: `editAttributeSet_${item.id}`,
                                ariaLabel: 'Edit set',
                                text: 'Edit set',
                                iconProps: { iconName: IconNames.EditNoFill },
                                onClick: (): void => {
                                    setSelectedAttributeSet(item);
                                    setOpenModalType(AttributeSetModalType.Edit);
                                },
                                disabled: shouldDisableAction(
                                    isAdmin,
                                    item,
                                    principalContext.principalRecord.oid ?? '',
                                ),
                            },
                            ...(item.isActive
                                ? [
                                      {
                                          key: `deactivateAttributeSet_${item.id}`,
                                          ariaLabel: 'Deactivate set',
                                          text: 'Deactivate set',
                                          iconProps: { iconName: IconNames.FabricUnsyncFolder },
                                          onClick: async (): Promise<void> => {
                                              try {
                                                  const attributeSetChildrenCount = (
                                                      await attributeSetsClient.getAttributes(
                                                          item.id,
                                                      )
                                                  ).filter(
                                                      (x) => x.status === AttributeStatus.Active,
                                                  ).length;
                                                  setIsDeactivateDialogOpen(true);
                                                  setShouldShowDeactivate(
                                                      attributeSetChildrenCount === 0,
                                                  );
                                                  setShowErrors(false);
                                              } catch (e) {
                                                  setShowErrors(true);
                                                  setErrorMessage(
                                                      'An error occurred retrieving attributes.',
                                                  );
                                              }
                                          },
                                          disabled: shouldDisableAction(
                                              isAdmin,
                                              item,
                                              principalContext.principalRecord.oid ?? '',
                                          ),
                                      },
                                  ]
                                : [
                                      {
                                          key: `reactivateAttributeSet_${item.id}`,
                                          ariaLabel: 'Reactivate set',
                                          text: 'Reactivate set',
                                          iconProps: { iconName: IconNames.FabricSyncFolder },
                                          onClick: (): void => {
                                              setIsReactivateModalOpen(true);
                                          },
                                          disabled: shouldDisableAction(
                                              isAdmin,
                                              item,
                                              principalContext.principalRecord.oid ?? '',
                                          ),
                                      },
                                  ]),
                            {
                                key: `deleteAttributeSet_${item.id}`,
                                ariaLabel: 'Delete set',
                                text: 'Delete set',
                                iconProps: { iconName: IconNames.Trash },
                                onClick: async (): Promise<void> => {
                                    try {
                                        setShouldShowDelete(
                                            (
                                                await attributeSetsClient.getAttributes(item.id)
                                            ).filter(
                                                (x) =>
                                                    x.status === AttributeStatus.Active ||
                                                    x.status === AttributeStatus.Deprecated,
                                            ).length === 0,
                                        );
                                        setIsDeleteDialogOpen(true);
                                        setShowErrors(false);
                                    } catch (e) {
                                        setShowErrors(true);
                                        setErrorMessage('An error occurred retrieving attributes.');
                                    }
                                },
                                disabled:
                                    shouldDisableAction(
                                        isAdmin,
                                        item,
                                        principalContext.principalRecord.oid ?? '',
                                    ) || !item.isActive,
                            },
                        ],
                        shouldFocusOnMount: true,
                    };
                    return (
                        <TableCell className={styles.detailsListField}>
                            <ActionButton
                                iconProps={{ iconName: 'More' }}
                                ariaLabel={`More actions for ${item.id}`}
                                menuProps={contextualMenuItems}
                                styles={{
                                    menuIcon: {
                                        display: 'none', // Hide the menu icon
                                    },
                                }}
                            />
                        </TableCell>
                    );
                },
                isFilterable: false,
            },
        ];
    }, [isAdmin, principalContext, gotoAttributeSetDetails]);

    const reloadAttributeSets = (): void => {
        fetchData();
    };

    const createOrUpdateAttributeSets = (): void => {
        setAttributeSets([]);
        reloadAttributeSets();
    };

    return (
        <div>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <CommandBar
                styles={{
                    root: {
                        paddingTop: 25,
                        paddingLeft: 60,
                        background: globalStyles.backgroundColor,
                    },
                }}
                items={commandBarLeftItems}
                farItems={commandBarRightItems}
            />
            {showErrors && (
                <span style={{ color: FluentTheme.semanticColors.errorText, fontSize: 12 }}>
                    {errorMessage}
                </span>
            )}
            {isLoading === false && attributeSets && attributeSets.length === 0 ? (
                <div>
                    <span>{DEFAULT_NO_DATA_TEXT}</span>
                </div>
            ) : (
                <div style={{ marginLeft: '12px' }}>
                    <FilterableTable
                        filters={filters}
                        isLoading={isLoading}
                        columns={columnsFilterable}
                        items={displayedAttributeSets}
                        onSelectionChange={(selected): void =>
                            setSelectedAttributeSet(selected.length > 0 ? selected[0] : undefined)
                        }
                        onFilteredItemsChange={setFilteredAttributeSets}
                        selectionMode={SelectionMode.single}
                        selectionPreservedOnEmptyClick={true}
                        className={styles.detailsList}
                    />
                </div>
            )}
            {isDeleteDialogOpen &&
                (shouldShowDelete ? (
                    <RemoveAttributeSetModal
                        attributeSetId={selectedAttributeSet?.id ?? ''}
                        attributeSetName={selectedAttributeSet?.name ?? ''}
                        onDismissed={(): void => {
                            setIsDeleteDialogOpen(false);
                        }}
                        onSuccess={(): void => createOrUpdateAttributeSets()}
                    />
                ) : (
                    <ActionNotAllowedAttributeSetModal
                        title='Delete Attribute set'
                        message='This Attribute set contains attributes and cannot be deleted.'
                        onDismissed={(): void => {
                            setIsDeleteDialogOpen(false);
                        }}
                    />
                ))}
            {isReactivateModalOpen && selectedAttributeSet && (
                <AttributeSetActionModal
                    attributeSetId={selectedAttributeSet.id}
                    attributeSetName={selectedAttributeSet.name}
                    actionType='reactivate'
                    onDismissed={(): void => {
                        setIsReactivateModalOpen(false);
                        reloadAttributeSets();
                    }}
                    onSuccess={() => {
                        // No logic needed here, as it's handled in onDismissed
                    }}
                />
            )}

            {isDeactivateDialogOpen &&
                (shouldShowDeactivate ? (
                    <AttributeSetActionModal
                        attributeSetId={selectedAttributeSet?.id ?? ''}
                        attributeSetName={selectedAttributeSet?.name ?? ''}
                        actionType='deactivate'
                        onDismissed={(): void => {
                            setIsDeactivateDialogOpen(false);
                        }}
                        onSuccess={(): void => createOrUpdateAttributeSets()}
                    />
                ) : (
                    <ActionNotAllowedAttributeSetModal
                        title='Deactivate Attribute set'
                        message='This Attribute set contains attributes and cannot be deactivated.'
                        onDismissed={(): void => {
                            setIsDeactivateDialogOpen(false);
                        }}
                    />
                ))}

            <AddEditAttributeSetsPanel
                isOpen={openModalType !== undefined}
                modalType={openModalType ?? AttributeSetModalType.Add}
                onDismiss={(): void => setOpenModalType(undefined)}
                onCreateOrUpdate={createOrUpdateAttributeSets}
                initAttributeSet={selectedAttributeSet}
                initAttributeSets={attributeSets}
            />
            <AttributeSetFilterPanel
                isOpen={isFilterOpen}
                onDismiss={(): void => {
                    setIsFilterOpen(false);
                }}
                onSuccess={(filters): void => {
                    setFilters(filters);
                    setIsFilterOpen(false);
                }}
                onClearFilters={(): void => {
                    setFilters(new Map<string, IFilter>());
                    fetchData();
                }}
                onMinimizePanel={(): void => {
                    setIsFilterOpen(false);
                }}
            />
        </div>
    );
}

function shouldDisableAction(
    isAdmin: boolean,
    item: GetAllAttributeSetsResult,
    oid: string | undefined,
): boolean | undefined {
    return !isAdmin && !item.owners.includes(oid ?? ''); // user is not an admin or owner of the attribute set
}

const styles = mergeStyleSets({
    detailsList: {
        '& .ms-Check-checkHost': {
            height: '100%',
        },
        '& .ms-HoverCard-host': {
            ':focus-visible': {
                outline: '2px solid black',
            },
        },
    },
    detailsListField: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        maxWidth: '100%',
        lineHeight: '1.0',
    },
    coreEmployeeHoverCardDiv: {
        display: 'flex',
    },
});

const columnWidths = {
    attributeSet: 100,
    description: 200,
    owners: 75,
    managers: 75,
    lastModifiedBy: 225,
    actions: 75,
};

// filterableTable uses "key" to filter
export enum AttributeSetsListColumnKeys {
    AttributeSet = 'attributeset',
    Description = 'description',
    Owners = 'owners',
    Managers = 'managers',
    Readers = 'readers',
    LastModifiedBy = 'lastmodified',
}

// filterableTable uses "name" as the table header text
enum columnNames {
    AttributeSet = 'Attribute set',
    Description = 'Description',
    Owners = 'Owners',
    Managers = 'Managers',
    Readers = 'Readers',
    LastModifiedBy = 'Last modified by',
    Actions = 'Actions',
}

// filterableTable uses "fieldName" to sort
enum columnFieldNames {
    AttributeSet = 'name',
    Description = 'description',
    Owners = 'owners',
    Managers = 'managers',
    LastModifiedBy = 'lastModifiedDate', // use LastModifiedDate to sort the LastModifiedBy column
    Actions = 'actions',
}

const leftCommandBarButtonStyle: IButtonStyles = {
    root: { marginRight: '15px' },
};
