import React, { useEffect, useContext, ReactNode, useState } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import { Dictionary, IconNames } from 'assets/constants/global-constants';
import { detailsListStyles } from 'assets/styles/list-styles';
import { globalStyles } from 'assets/styles/global-styles';
import { mergeStyleSets, mergeStyles } from '@fluentui/react';
import ModalActionButton, {
    onModalConcludeType,
} from 'components/common/buttons/modal-action-button';
import { ModalSizeType } from 'components/common/modal';
import { useFetchSimple, useIsMounted } from 'utils/misc-hooks';
import GroupClient, {
    SecurityGroupRequestType,
    IGroupSecurityGroup,
    ISecurityGroup,
    IOwnedAdGroupResponse,
    IGroup,
} from 'clients/group-client';
import GraphClient, { IGraphGroup } from 'clients/graph-client';
import { useTextField } from 'components/common/use-input/use-textfield';
import Spacer from 'components/common/spacer';
import {
    transformADGroups,
    transformGraphGroups,
} from 'components/groups/manage-group/settings/link-security-group/link-to-security-group-utils';
import GroupSelectionTable, {
    FedNetGroupOptionKey,
} from 'components/groups/manage-group/settings/link-security-group/group-selection-table';
import GroupValidationTable from 'components/groups/manage-group/settings/link-security-group/group-validation-table';
import ConfirmSelection from 'components/groups/manage-group/settings/link-security-group/confirm-selection';
import { useCheckbox } from 'components/common/use-input/use-checkbox';
import LinkTheGroup from 'components/groups/manage-group/settings/link-security-group/link-the-group';
import { doNothing, doNothingAsync } from 'utils/misc-utils';
import { fetchLinkedSecurityGroups } from 'components/groups/manage-group/manage-group-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

enum DialogBoxSteps {
    FirstPage,
    SelectGroup,
    ValidateGroup,
    ConfirmSelection,
    LinkTheGroup,
}

/**
 * Order of items in the constant array "steps"
 * determines order of execution of the steps.
 * See onGoNext() and onGoBack().
 */
const steps = [
    DialogBoxSteps.FirstPage,
    DialogBoxSteps.SelectGroup,
    DialogBoxSteps.ValidateGroup,
    DialogBoxSteps.ConfirmSelection,
    DialogBoxSteps.LinkTheGroup,
];

const firstStage = DialogBoxSteps.FirstPage;

interface IModalActionButtonSubset {
    errorMsg?: string;
    enableSubmit: boolean;
    enableCancel?: boolean;
    modalSubtitle?: string;
    submitButtonText: string;
    submitButtonIcon?: string;
    keepOpenAfterSubmit: boolean;
    onModalConcluded: onModalConcludeType<void>;
    onBack?: () => Promise<void>;
    onSubmit: () => Promise<void>;
    children: ReactNode;
}
interface ILinkToSecurityGroupModalActionButtonProps {
    enable: boolean;
    group: IGroup | undefined;
    canLinkToFednetGroup: boolean;
    linkedSecurityGroups: IGroupSecurityGroup[];
}

export default function LinkToSecurityGroupModalActionButton(
    props: ILinkToSecurityGroupModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);

    const linkOnPremAD = useFeatureFlag(FeatureFlagKeys.groupsLinkOnPremAD);

    const [shouldFetch, setShouldFetch] = useState<boolean>(false);
    const [currentStage, setCurrentStage] = useState<DialogBoxSteps>(firstStage);
    const [securityGroups, setSecurityGroups] = useState<ISecurityGroup[]>([]);
    const [selectedGroupOptionKey, setSelectedGroupOptionKey] = useState<string>('');
    const [hasErrGettingGroups, setHasErrGettingGroups] = useState<boolean>(false);
    const [fednetGroupFromServer, setFednetGroupFromServer] = useState<IGroupSecurityGroup>();
    const [selectedSecurityGroup, setSelectedSecurityGroup] = useState<
        ISecurityGroup | undefined
    >();
    const [isOkToLink, setIsOkToLink] = useState<boolean>(false);
    const [isLinkingFinished, setIsLinkingFinished] = useState<boolean>(false);

    const isMounted = useIsMounted();

    useEffect(() => {
        const fednetGroupFromServerVar = props.linkedSecurityGroups.find(
            (group) => group.securityGroupType === SecurityGroupRequestType.MICROSOFTFEDERAL,
        );
        setFednetGroupFromServer(fednetGroupFromServerVar);
        if (!!fednetGroupFromServerVar) {
            initFednetGuidInput(fednetGroupFromServerVar.id);
        }
    }, [props.linkedSecurityGroups]);

    type GroupTypes = [IGraphGroup[], IOwnedAdGroupResponse[]];

    const { isFetching: isFetchingGroups } = useFetchSimple({
        dependencies: [shouldFetch],
        canPerformFetch: shouldFetch,
        fetchFunc: async (): Promise<GroupTypes> => {
            return Promise.all([
                GraphClient.getMyOwnerGroups(authContext),
                linkOnPremAD.enabled
                    ? GroupClient.getOwnedGroups(authContext, authContext.getUserProfile())
                    : Promise.resolve([]),
            ]);
        },
        onSuccess: (result: GroupTypes): void => {
            const [myOwnerGroupsVar, ownedGroupsVar] = result;
            const myOwnerGroupsXformed = transformGraphGroups(myOwnerGroupsVar);
            const ownedGroupsXformed = transformADGroups(ownedGroupsVar);
            setSecurityGroups(myOwnerGroupsXformed.concat(ownedGroupsXformed));
        },
        onError: () => {
            setHasErrGettingGroups(true);
        },
        onFinally: () => {
            setShouldFetch(false);
        },
    });

    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        value: fednetGroupGuidInputValue,
        initialize: initFednetGuidInput,
        theElement: fednetGuidInputElement,
    } = useTextField({
        placeholder: 'Enter FedNet Group GUID',
        disabled: !props.canLinkToFednetGroup || isFetchingGroups || !!fednetGroupFromServer,
    });

    const onButtonClick = (): void => {
        setCurrentStage(firstStage);
        initFednetGuidInput();
        setSecurityGroups([]);
        setSelectedGroupOptionKey('');
        setHasErrGettingGroups(false);
        setShouldFetch(true);
        setIsOkToLink(false);
    };

    const onGoNext = async (): Promise<void> => {
        const currentIx = steps.findIndex((stage) => stage === currentStage);
        if (currentIx < steps.length - 1) {
            setCurrentStage(steps[currentIx + 1]);
        }
    };

    const onGoBack = async (): Promise<void> => {
        const currentIx = steps.findIndex((stage) => stage === currentStage);
        if (currentIx > 0) {
            setCurrentStage(steps[currentIx - 1]);
        }
    };

    const currentStageProps: Dictionary<() => IModalActionButtonSubset> = {};

    /// First Page - Intros
    currentStageProps[DialogBoxSteps.FirstPage] = (): IModalActionButtonSubset => ({
        enableSubmit: true,
        submitButtonText: 'Next',
        onBack: undefined,
        onSubmit: onGoNext,
        keepOpenAfterSubmit: true,
        onModalConcluded: doNothing,
        children: (
            <div>
                <div className={globalStyles.largeFont}>Link to Security or Office 365 Group</div>
                <p>
                    Personnel can sync compliant members of this group to a designated AAD Security
                    Group or Office 365 Group. You&apos;ll be able to grant the target security
                    group access to other sites, documents, and applications.
                </p>
                <div className={globalStyles.largeFont}>Authoritative Sync</div>
                <p>
                    Once linked, Personnel will perform periodic authoritative sync to the Linked
                    Group. All other existing members, including users and groups, who do not meet
                    this group&apos;s policy requirements will be removed. As a result, the Linked
                    Group will always only contain users who are compliant members of this group.
                </p>
            </div>
        ),
    });

    /// Select Group
    const selectedGroupId = (): string | undefined => {
        switch (selectedGroupOptionKey) {
            case FedNetGroupOptionKey:
                return fednetGroupGuidInputValue;
            default:
                return selectedGroupOptionKey;
        }
    };

    currentStageProps[DialogBoxSteps.SelectGroup] = (): IModalActionButtonSubset => ({
        enableSubmit: !isFetchingGroups && !hasErrGettingGroups && !!selectedGroupId(),
        modalSubtitle: 'Select a security group',
        submitButtonText: 'Next',
        keepOpenAfterSubmit: true,
        onBack: onGoBack,
        onSubmit: onGoNext,
        onModalConcluded: doNothing,
        children: (
            <div>
                <Spacer marginTop={20} />
                <GroupSelectionTable
                    canLinkToFednetGroup={props.canLinkToFednetGroup}
                    fednetGroupGuidInputValue={fednetGroupGuidInputValue}
                    fednetGuidInputElement={fednetGuidInputElement}
                    securityGroups={securityGroups}
                    hasErrGettingGroups={hasErrGettingGroups}
                    isFetchingGroups={isFetchingGroups}
                    selectedGroupChoice={selectedGroupOptionKey}
                    setSelectedGroupOptionKey={setSelectedGroupOptionKey}
                />
            </div>
        ),
    });

    /// Group Validation
    currentStageProps[DialogBoxSteps.ValidateGroup] = (): IModalActionButtonSubset => ({
        enableSubmit: isOkToLink,
        modalSubtitle: 'Validating Selected Group',
        submitButtonText: 'Next',
        keepOpenAfterSubmit: true,
        onBack: onGoBack,
        onSubmit: onGoNext,
        onModalConcluded: doNothing,
        children: (
            <div>
                <Spacer marginTop={20} />
                <GroupValidationTable
                    selectedSecurityGroup={selectedSecurityGroup}
                    setSelectedSecurityGroup={setSelectedSecurityGroup}
                    selectedGroupOptionKey={selectedGroupOptionKey}
                    selectedGroupId={selectedGroupId()}
                    securityGroups={securityGroups}
                    setIsOkToLink={setIsOkToLink}
                />
            </div>
        ),
    });

    /// Confirm Selection
    const {
        value: hasAccepted,
        theElement: hasAcceptedCheckbox,
        initialize: initHasAccepted,
    } = useCheckbox({
        label: 'I understand',
    });

    currentStageProps[DialogBoxSteps.ConfirmSelection] = (): IModalActionButtonSubset => ({
        enableSubmit: !!hasAccepted,
        modalSubtitle: 'Confirm Selection',
        submitButtonText: 'Link The Group',
        keepOpenAfterSubmit: true,
        onBack: onGoBack,
        onSubmit: onGoNext,
        onModalConcluded: doNothing,
        children: (
            <ConfirmSelection
                selectedSecurityGroup={selectedSecurityGroup}
                hasAcceptedCheckbox={hasAcceptedCheckbox}
                initHasAccepted={initHasAccepted}
            />
        ),
    });

    /// Perform linking
    const onEndOfLinking = (): void => {
        if (isMounted()) {
            setIsLinkingFinished(true);
        }
    };

    const onModalClosing = (): void => {
        if (!props.group?.id) {
            return;
        }
        fetchLinkedSecurityGroups(authContext, groupContext, props.group.id);
    };

    currentStageProps[DialogBoxSteps.LinkTheGroup] = (): IModalActionButtonSubset => ({
        enableSubmit: isLinkingFinished,
        modalSubtitle: `Linking the group ${selectedSecurityGroup?.name}`,
        submitButtonText: 'Close',
        keepOpenAfterSubmit: false,
        onBack: undefined,
        onSubmit: doNothingAsync,
        onModalConcluded: onModalClosing,
        children: (
            <LinkTheGroup
                shouldPerformLinking={currentStage === DialogBoxSteps.LinkTheGroup}
                selectedSecurityGroup={selectedSecurityGroup}
                onEndOfLinking={onEndOfLinking}
            />
        ),
    });

    /// Render
    return (
        <ModalActionButton
            {...Object.assign(currentStageProps[currentStage](), {
                text: 'Link to Security Group',
                size: ModalSizeType.size700,
                fixWidth: true,
                buttonStyle: styles.button,
                enable: props.enable,
                iconName: IconNames.Link,
                modalTitle: 'Link to Security Group',
                modalTitleIcon: undefined,
                tooltipStyles: { root: { verticalAlign: 'sub' } },
            })}
            onButtonClick={onButtonClick}></ModalActionButton>
    );
}

const styles = mergeStyleSets({
    fednetGuidInput: {
        width: 300,
    },
    button: mergeStyles(detailsListStyles.icon, {
        height: 'auto',
    }),
});
