import React from 'react';
import { tableColumnsAttributes } from 'components/user-assignments/manage-attributes/table-columns-attributes';
import {
    IAttributeAssignment,
    IAttributeAssignmentDetail,
    PersonnelTypes,
} from 'clients/eligibility-client';
import { useSortColumnHandler, strCmp, numCmp } from 'utils/sort-utils';
import moment from 'moment';
import { Table } from 'components/common/table';
import { IEmployee } from 'clients/employee-client';
import HorizontalBar from 'components/common/horizontal-bar';
import AddAttributeModalActionButton from 'components/user-assignments/manage-attributes/add-attribute-modal-action-button';
import { mergeStyleSets, MessageBar } from '@fluentui/react';
import { IVisitorProfile } from 'clients/visitor-client';
import Spacer from 'components/common/spacer';
import { BlackListAttributeCode } from 'components/user-assignments/user-assignments-constants';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface IManageAttributesProps {
    isLoading: boolean;
    attrAssignment: IAttributeAssignment;
    visitorOrEmployee: IVisitorProfile | IEmployee;
    updateAddAttributeAssignment: () => void;
    deleteAttributeFromTable: (attribute: IAttributeAssignmentDetail) => void;
    personnelId: string;
    personnelType: PersonnelTypes;
    showAddAttributeButton: boolean;
    showDeleteAttributeButton: boolean;
}

export default function ManageAttributes(props: IManageAttributesProps): JSX.Element {
    const { visitorOrEmployee, deleteAttributeFromTable } = props;

    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler('Code', 1);

    const isUpdatedMessageBarOnProfileAttributesEnabled = useFeatureFlag(
        FeatureFlagKeys.profileAttributesUpdatedMessageBar,
    ).enabled;

    const sortAttributes = (
        attributes: IAttributeAssignmentDetail[],
    ): IAttributeAssignmentDetail[] => {
        type A = IAttributeAssignmentDetail;

        const chooseSortCmp = (
            sortColumn: string,
        ): ((r1: IAttributeAssignmentDetail, r2: IAttributeAssignmentDetail) => number) => {
            switch (sortColumn) {
                case 'Code':
                    return (r1: A, r2: A): number =>
                        sortAscending * strCmp(r1.attributeCode, r2.attributeCode);
                case 'Description':
                    return (r1: A, r2: A): number =>
                        sortAscending * strCmp(r1.attributeDesc, r2.attributeDesc);
                case 'Starts':
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(moment(r1.startDate).valueOf(), moment(r2.startDate).valueOf());
                case 'Expires':
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(moment(r1.endDate).valueOf(), moment(r2.endDate).valueOf());
                case 'Granted on':
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(moment(r1.assignedAt).valueOf(), moment(r2.assignedAt).valueOf());
                default:
                    // Sort column not recognized. No sorting performed.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp(sortColumn);
        return [...attributes].sort(sortCmp);
    };

    const tableColumns = tableColumnsAttributes({
        visitorOrEmployee,
        sortColumn,
        sortAscending: sortAscending === 1,
        showDeleteAttributeButton: props.showDeleteAttributeButton,
        sortColumnHandler,
        deleteAttributeFromTable,
    });

    return (
        <div>
            {props.showAddAttributeButton && (
                <HorizontalBar>
                    <AddAttributeModalActionButton {...props} />
                </HorizontalBar>
            )}
            {props.attrAssignment.attributes.length < 1 ? (
                <div className={styles.helpText}>
                    No personnel attributes have been added. Please reach out to{' '}
                    <a href='mailto:personnel_help@microsoft.com'>personnel_help@microsoft.com</a>{' '}
                    if you need attributes added.
                </div>
            ) : (
                <>
                    <Table
                        tableColumns={tableColumns}
                        rows={sortAttributes(props.attrAssignment?.attributes ?? [])}
                        isFetchingData={false}
                        tableName='Manage Attributes'
                    />
                    {props.personnelType === 'EMPLOYEE' && (
                        <>
                            <Spacer marginTop={20} />
                            <MessageBar styles={messageBarStyle}>
                                {isUpdatedMessageBarOnProfileAttributesEnabled && (
                                    <div style={{ marginBottom: '1rem' }}>
                                        <div className={styles.infoText}>
                                            <span className={styles.blacklistItem}>
                                                All clearance attributes
                                            </span>{' '}
                                            are automatically assigned based on National Security
                                            Team (NST) clearance records and can be manually
                                            assigned or removed by NST
                                        </div>
                                        <div className={styles.infoText}>
                                            <span className={styles.blacklistItem}>Questions?</span>{' '}
                                            Contact
                                            <a
                                                href='mailto:MyClearance@microsoft.com'
                                                className={styles.linkOnGreyBg}>
                                                NST
                                            </a>{' '}
                                            for support
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <div className={styles.infoText}>
                                        {BlackListAttributeCode.map((code, i, { length }) => (
                                            <span key={i} className={styles.blacklistItem}>
                                                {code}
                                                {i + 1 !== length ? ', ' : ' '}
                                            </span>
                                        ))}
                                        are automatically assigned based on HR data and cannot be
                                        manually assigned or removed
                                    </div>
                                    {isUpdatedMessageBarOnProfileAttributesEnabled && (
                                        <div className={styles.infoText}>
                                            <span className={styles.blacklistItem}>Questions?</span>{' '}
                                            Contact the
                                            <a
                                                href='mailto:personnel_pm@microsoft.com'
                                                className={styles.linkOnGreyBg}>
                                                Personnel PM team
                                            </a>{' '}
                                            for support
                                        </div>
                                    )}
                                </div>
                            </MessageBar>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

const messageBarStyle = mergeStyleSets({
    root: {
        background: 'rgba(0, 0, 0, 0.065)',
    },
});

const styles = mergeStyleSets({
    helpText: {
        paddingTop: 16,
        paddingLeft: 20,
        fontSize: 16,
    },
    blacklistItem: {
        fontWeight: 600,
        fontSize: 14,
    },
    infoText: {
        fontSize: 14,
    },
    linkOnGreyBg: {
        color: '#006cbe',
    },
});
