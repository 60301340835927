import { Dropdown, IDropdownOption, IDropdownProps } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';

export interface IMultiChoiceDropdownProps extends IDropdownProps {
    onSelectionsChanged?: (selectedKeys: (string | number)[]) => void;
}

export default function MultiChoiceDropdown(props: IMultiChoiceDropdownProps): JSX.Element {
    const [selectedKeys, setSelectedKeys] = useState<(string | number)[]>(
        props.defaultSelectedKeys as (string | number)[],
    );

    useEffect(() => {
        if (props.onSelectionsChanged) {
            props.onSelectionsChanged(selectedKeys);
        }
    }, [selectedKeys]);

    const onChange = useCallback(
        (event: React.FormEvent<HTMLDivElement>, opt?: IDropdownOption) => {
            if (opt) {
                setSelectedKeys((prev) =>
                    opt.selected
                        ? [...prev, opt.key as string | number]
                        : (prev as (string | number)[]).filter(
                              (key: string | number) => key !== opt.key,
                          ),
                );
            }
        },
        [],
    );

    return <Dropdown multiSelect onChange={onChange} {...props} />;
}
