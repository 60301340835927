import { ActionButton, mergeStyles, TextField } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import React, { useMemo, useState } from 'react';
import { UpdatableSettings } from 'components/forms/settings/forms-edit-settings';

const cancelSaveButtonStyles = mergeStyles({
    '& i': {
        fontSize: 'large',
    },
});

type SubtitleSettingsProps = {
    subtitle: string;
    updateAndSaveForm: (
        property: keyof UpdatableSettings,
        value: string | string[] | boolean | string[][],
    ) => void;
};

export function SubtitleSettings(props: SubtitleSettingsProps): JSX.Element {
    const { subtitle, updateAndSaveForm } = props;

    const [localSubtitle, setLocalSubtitle] = useState(subtitle ?? '');

    const isDirty = useMemo(() => localSubtitle !== (subtitle ?? ''), [localSubtitle, subtitle]);

    const getErrorMessage = (value: string): string => {
        return value.length <= 150 ? '' : 'Subtitle length must be less than 150 characters.';
    };

    return (
        <div
            style={{
                minHeight: '5rem',
            }}>
            <TextField
                placeholder='Enter a subtitle. This will appear on the "Available forms" tab.'
                value={localSubtitle}
                onChange={(ev, val) => setLocalSubtitle(val ?? '')}
                onGetErrorMessage={getErrorMessage}
            />
            {isDirty && (
                <div className={cancelSaveButtonStyles}>
                    <ActionButton
                        disabled={localSubtitle.length > 150}
                        style={{ display: 'inline-block', marginRight: '.5rem' }}
                        aria-label='Save subtitle'
                        iconProps={{ iconName: IconNames.CheckMark }}
                        onClick={() => updateAndSaveForm('subtitle', localSubtitle)}
                    />
                    <ActionButton
                        aria-label='Cancel'
                        iconProps={{ iconName: IconNames.Cancel }}
                        onClick={() => setLocalSubtitle(subtitle)}
                    />
                </div>
            )}
        </div>
    );
}
