import { Stack } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import GroupClient, {
    GroupMemberStatus,
    GroupRole,
    IGroup,
    IGroupMembership,
} from 'clients/group-client';
import { IPagedResults } from 'clients/http-options';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';
import Spacer from 'components/common/spacer';
import { Table } from 'components/common/table';
import { TabsContentsSpacer } from 'components/common/tabs';
import {
    JoinRequestsTableColumnNames,
    tableColumnsGroupJoinRequests,
} from 'components/groups/manage-group/actions/join-requests/table-columns-group-join-requests';
import UserAccessReviewsTable from 'components/groups/manage-group/actions/user-access-review/table-user-access-review';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import { displayWarningIfFewOwners } from 'components/groups/manage-group/manage-group-utils';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFetchSimple } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';
import { strCmp, useSortColumnHandler } from 'utils/sort-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface IGroupActionsTabProps {
    group: IGroup;
    onGroupMemberDeleted: (personnelId: string, ruleId?: string) => void;
}

export default function ManageGroupActions(props: IGroupActionsTabProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);
    const [shouldPerformFetch, setShouldPerformFetch] = useState<boolean>(false);
    const [continuationToken, setContinuationToken] = useState<string | undefined>('');
    const [fetchedJoinRequestMembers, setFetchedJoinRequestMembers] = useState<IGroupMembership[]>(
        [],
    );

    const [errorFetchingJoinRequestMembers, setErrorFetchingJoinRequestMembers] = useState<string>(
        '',
    );

    const isMin2FTEOwnersFeatureFlagEnabled = useFeatureFlag(FeatureFlagKeys.groupsMin2FteOwners)
        .enabled;

    const [
        { sortColumn: joinRequestSortColumn, sortAscending: joinRequestSortAscending },
        joinRequestSortColumnHandler,
    ] = useSortColumnHandler('');

    const sortJoinRequestMembers = (joinRequestMembers: IGroupMembership[]): IGroupMembership[] => {
        type A = IGroupMembership;

        const chooseSortCmp = (): ((r1: A, r2: A) => number) => {
            switch (joinRequestSortColumn) {
                case JoinRequestsTableColumnNames.name:
                    return (r1: A, r2: A): number =>
                        joinRequestSortAscending *
                        strCmp(r1.firstName + r1.lastName, r2.firstName + r2.lastName);
                case JoinRequestsTableColumnNames.dateAdded:
                    return (r1: A, r2: A): number =>
                        joinRequestSortAscending *
                        (+r1.requestedTimestampUTC - +r2.requestedTimestampUTC);
                default:
                    // Sort column not recognized. No sorting performed.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp();
        return [...joinRequestMembers].sort(sortCmp);
    };

    const sortedJoinRequestMembers = useMemo(() => {
        return sortJoinRequestMembers(fetchedJoinRequestMembers);
    }, [fetchedJoinRequestMembers, joinRequestSortAscending, joinRequestSortColumn]);

    useEffect(() => {
        if (!!props.group.id) {
            setShouldPerformFetch(true);
            setContinuationToken('');
        }
    }, [props.group.id]);

    const { isFetching: isFetchingJoinRequestMembers } = useFetchSimple<
        IPagedResults<IGroupMembership>
    >({
        dependencies: [props.group.id, continuationToken, shouldPerformFetch],
        canPerformFetch: shouldPerformFetch,
        fetchFunc: async () => {
            return GroupClient.getGroupMembers(authContext, props.group.id, continuationToken, [
                GroupMemberStatus.NOT_APPROVED,
            ]);
        },
        onSuccess: (result) => {
            setShouldPerformFetch(false);
            if (!!continuationToken) {
                setFetchedJoinRequestMembers((currentValue) =>
                    [...currentValue].concat(result.results),
                );
            } else {
                setFetchedJoinRequestMembers(result.results);
            }
            setContinuationToken(result.continuationToken);
        },
        onError: () => {
            setErrorFetchingJoinRequestMembers('Error loading join requests');
        },
        onFinally: () => doNothing(),
    });

    useEffect(() => {
        if (!!continuationToken && !isFetchingJoinRequestMembers) {
            setShouldPerformFetch(true);
        }
    }, [continuationToken, isFetchingJoinRequestMembers]);

    const onDeleteMemberFromJoinRequestTable = (groupMember: IGroupMembership) => {
        setFetchedJoinRequestMembers((currentValue) =>
            currentValue.filter((member) => member.personnelId !== groupMember.personnelId),
        );
    };

    const joinRequestsTableColumns = useMemo(() => {
        const cannotApproveOrDeny = [GroupRole.AUDITOR, GroupRole.MEMBER].find(
            (role) => role === groupContext.groupMembershipVar?.value?.role,
        );
        const canApproveOrDenyRequests =
            authContext.isInRole(Role.GroupAdmin) || !cannotApproveOrDeny;
        return tableColumnsGroupJoinRequests({
            group: props.group,
            sortColumn: joinRequestSortColumn,
            sortAscending: joinRequestSortAscending === 1,
            canApproveOrDenyRequests,
            sortColumnHandler: joinRequestSortColumnHandler,
            onDeleteMemberFromJoinRequestTable,
        });
    }, [joinRequestSortColumn, joinRequestSortAscending, groupContext.groupMembershipVar]);

    const displayJoinRequestsTable = (): JSX.Element => {
        return (
            <>
                {displayWarningIfFewOwners(
                    groupContext.groupOwners,
                    groupContext.ownerIsFTE,
                    groupContext.isGroupOwnersObtained,
                    isMin2FTEOwnersFeatureFlagEnabled,
                )}
                <HorizontalBar>
                    <Stack.Item className={horizontalBarTitleStyle}>
                        <h1
                            className={`${globalStyles.boldFont} ${globalStyles.mediumLargeFont} ${globalStyles.removeTopBottomMargins}`}>
                            Join Requests
                        </h1>
                    </Stack.Item>
                </HorizontalBar>
                <ProblemLoadingMsg problemLoadingMsg={errorFetchingJoinRequestMembers}>
                    <Table
                        rows={sortedJoinRequestMembers ?? []}
                        tableColumns={joinRequestsTableColumns}
                        shimmerLabel={isFetchingJoinRequestMembers ? 'Loading requests...' : ''}
                        isFetchingData={isFetchingJoinRequestMembers}
                        noDataText={
                            isFetchingJoinRequestMembers || fetchedJoinRequestMembers.length > 0
                                ? ''
                                : 'There are currently no requests pending.'
                        }
                    />
                </ProblemLoadingMsg>
            </>
        );
    };

    return (
        <div>
            <TabsContentsSpacer />
            {displayJoinRequestsTable()}
            <Spacer marginTop={30} />
            <UserAccessReviewsTable
                group={props.group}
                onGroupMemberDeleted={props.onGroupMemberDeleted}
            />
        </div>
    );
}
