import {
    DefaultButton,
    IconButton,
    Panel,
    PanelType,
    PrimaryButton,
    mergeStyleSets,
} from '@fluentui/react';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { PersonFeedbackFilled } from '@fluentui/react-icons';
import FeedbackContent from 'components/common/page-shell/feedback-notifications-profile/feedback/feedback-content';
import { getAppInsights } from 'utils/telemetry-utils';
import { CSATPropertiesType, KPIList } from 'components/common/kpi/kpi';
// eslint-disable-next-line no-restricted-imports
import { IUserContext, UserContext } from 'contexts/user-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import { FeedbackDataType } from 'components/common/page-shell/feedback-notifications-profile/feedback/feedback-common';

const iconStyle = {
    fontSize: '1.5rem',
};

const feedbackPanelStyle = {
    panel: {
        root: {
            marginTop: '65px',
        },
        content: {
            display: 'grid',
            paddingBottom: '26px',
        },
        header: {
            paddingTop: '6px',
        },
        overlay: {
            backgroundColor: 'rgb(255, 255, 255, 0)',
        },
        footer: {
            backgroundColor: 'white',
        },
    },
};

const feedbackIconStyles = {
    root: {
        color: 'white',
    },
    rootHovered: {
        color: 'white',
        backgroundColor: 'rgb(36, 36, 36)',
    },
    rootPressed: {
        color: 'white',
        backgroundColor: 'rgb(36, 36, 36)',
    },
};

type FeedbackIconPanelProps = {
    isFeedbackPanelOpen: boolean;
    setIsFeedbackPanelOpen: (isFeedbackPanelOpen: boolean) => void;
    isProfileCalloutOpen: boolean;
    setIsProfileCalloutOpen: (isProfileCalloutOpen: boolean) => void;
};

export default function FeedbackIconPanel({
    isFeedbackPanelOpen,
    setIsFeedbackPanelOpen,
    isProfileCalloutOpen,
    setIsProfileCalloutOpen,
}: FeedbackIconPanelProps): JSX.Element {
    const [showThankYou, setShowThankYou] = useState<boolean>(false);
    const [isCancelled, setIsCancelled] = useState<boolean>(false);
    const userContext = useContext(UserContext) as IUserContext | null;
    const principalUserContext = useContext(PrincipalUserContext);
    const defaultFeedback = useMemo(
        () => ({
            canContact: true,
            email:
                principalUserContext?.principalRecord?.upn ||
                userContext?.employeeRecord?.email ||
                '',
            rating: 0,
        }),
        [principalUserContext, userContext],
    );
    const [feedback, setFeedback] = useState<FeedbackDataType>(defaultFeedback);
    const isBeingDismissed = useRef(false);

    const panelFooter = useCallback((): JSX.Element => {
        const submitFeedback = (): void => {
            const CSATProperties: CSATPropertiesType = {
                EventName: KPIList.FeedbackSubmitted.EventName,
                EventId: KPIList.FeedbackSubmitted.EventId,
                Rating: feedback.rating,
                FeedbackType: feedback.type || '',
                Text: feedback.message || '',
                CanContact: feedback.canContact ?? false,
                User: feedback.email || '',
            };
            // In order to ingest KPIs via Logic App, EventName must be prefixed at the beginning of the message
            // with 'BE.' indicating this is a business event
            // The following text 'CSAT Feedback was submitted' is optional but included here for clarity
            const CSATMessage = `${KPIList.FeedbackSubmitted.EventName} CSAT Feedback was submitted`;
            const appInsightsTraceData = {
                message: CSATMessage,
                properties: CSATProperties,
            };

            getAppInsights()?.trackTrace(appInsightsTraceData);
            setShowThankYou(true);
        };

        const canSubmit = (): boolean => {
            return (
                feedback.rating !== 0 &&
                feedback.type !== undefined &&
                feedback.message !== undefined &&
                feedback.message !== ''
            );
        };

        return (
            <>
                <div className={styles.footerButtons}>
                    <div className={styles.leftButtonGroup}>
                        {showThankYou && (
                            <PrimaryButton
                                onClick={(): void => {
                                    setIsFeedbackPanelOpen(false);
                                }}>
                                Done
                            </PrimaryButton>
                        )}
                        {!showThankYou && (
                            <>
                                <PrimaryButton disabled={!canSubmit()} onClick={submitFeedback}>
                                    Submit
                                </PrimaryButton>
                                {!feedback.type && (
                                    <DefaultButton
                                        onClick={(): void => {
                                            setIsCancelled(true);
                                            setIsFeedbackPanelOpen(false);
                                        }}>
                                        Cancel
                                    </DefaultButton>
                                )}
                                {feedback.type && (
                                    <DefaultButton
                                        onClick={(): void => {
                                            setFeedback({
                                                ...feedback,
                                                message: undefined,
                                                type: undefined,
                                                canContact: true,
                                            });
                                        }}>
                                        Back
                                    </DefaultButton>
                                )}
                            </>
                        )}
                    </div>
                    {feedback.type && !showThankYou && (
                        <DefaultButton
                            onClick={(): void => {
                                setIsCancelled(true);
                                setIsFeedbackPanelOpen(false);
                            }}>
                            Cancel
                        </DefaultButton>
                    )}
                </div>
            </>
        );
    }, [feedback, setIsFeedbackPanelOpen, showThankYou]);

    return (
        <>
            <IconButton
                iconProps={{ iconName: 'Feedback' }}
                onRenderIcon={(): JSX.Element => <PersonFeedbackFilled style={iconStyle} />}
                styles={feedbackIconStyles}
                title='Submit feedback'
                ariaLabel='Submit feedback'
                onClick={(): void => {
                    if (!isBeingDismissed.current) {
                        if (!isFeedbackPanelOpen && isProfileCalloutOpen) {
                            setIsProfileCalloutOpen(false);
                        }
                        setIsFeedbackPanelOpen(!isFeedbackPanelOpen);
                    }
                }}>
                Click
            </IconButton>
            <Panel
                isOpen={isFeedbackPanelOpen}
                onDismiss={(): void => {
                    isBeingDismissed.current = true;
                    setIsFeedbackPanelOpen(false);
                }}
                onDismissed={(): void => {
                    isBeingDismissed.current = false;
                    if (showThankYou || isCancelled) {
                        setShowThankYou(false);
                        setIsCancelled(false);
                        setFeedback(defaultFeedback);
                    }
                }}
                type={PanelType.custom}
                customWidth={'405px'}
                headerText={!showThankYou ? 'Submit feedback' : 'Thank you'}
                closeButtonAriaLabel='Close'
                isFooterAtBottom={true}
                onRenderFooterContent={panelFooter}
                onLightDismissClick={(): void => {
                    if (!isBeingDismissed.current) {
                        setIsFeedbackPanelOpen(!isFeedbackPanelOpen);
                    }
                }}
                isLightDismiss={true}
                styles={feedbackPanelStyle.panel}>
                <FeedbackContent
                    showThankYou={showThankYou}
                    feedback={feedback}
                    setFeedback={setFeedback}
                />
            </Panel>
        </>
    );
}

const styles = mergeStyleSets({
    leftButtonGroup: {
        display: 'flex',
        gap: '10px',
    },
    footerButtons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});
