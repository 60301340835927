import React, { useContext, useState } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import { contentMaxHeight } from 'assets/styles/list-styles';
import DocumentsClient, {
    DocumentEvent,
    DocumentReferenceType,
    IDocumentNotificationRequest,
    IDocumentToken,
} from 'clients/documents-client';
import { AuthContext } from 'contexts/auth-context';
import {
    ActionButton,
    DefaultButton,
    Dialog,
    DialogType,
    FontSizes,
    FontWeights,
    IDialogContentProps,
    IModalProps,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    TextField,
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import config from 'environments/environment';
import { UserContext } from 'contexts/user-context';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import { DocumentSourceType } from 'components/screening/common/documents/document-common';
import ActivitiesClient, { IActivityValue, IActivityPost } from 'clients/activities-client';
import { ApplicationServices } from 'clients/screening/us-gov-screening-client';

export interface DocumentDeleteProps {
    children?: React.ReactNode;
    documentFileName: string;
    documentId: string;
    associatedUserToken: IDocumentToken;
    isScreeningDetails: boolean;
    onChangeDeleteNote?: React.Dispatch<React.SetStateAction<string>>;
    documentSourceType?: DocumentSourceType;
    onRequestDocumentDelete?: () => void;
    onUploadedDocumentDelete?: () => void;
    eventBitWillManuallyUpdate?: () => void; // forces an update to the activities timeline in Screening-content.tsx (copying logic from uploaded doc)
    screeningId?: string;
    employeeId?: string;
    editableEmployeeId?: string | undefined;
    getLatestAssociatedToken?: () => Promise<IDocumentToken>;
    setDocumentIdWorkaround?: React.Dispatch<React.SetStateAction<string>>;
}

export default function DocumentProfileDeleteModal(props: DocumentDeleteProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [businessJustification, setBusinessJustification] = useState('');

    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        title: 'Delete document',
        subText: 'Are you sure you want to delete document: ' + props.documentFileName + '?',
        styles: { title: styles.dialogTitle },
    };
    const maxHeightButtonStyles = {
        root: {
            maxHeight: props.isScreeningDetails ? 40 : contentMaxHeight,
        },
    };
    const defaultModalProps: IModalProps = {
        isOpen: isModalOpen,
    };

    const onChangeBusinessJustificationField = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setBusinessJustification(newValue || '');
        },
        [],
    );

    function onCloseClick(): void {
        setModalOpen(false);
        setResponseMessage('');
        setBusinessJustification('');
    }

    function writeDeleteDocumentActivityWithFeatureFlagEnabled(
        businessJustification: string,
    ): void {
        try {
            if (props.screeningId) {
                writeDeleteActivityToScreenings(props.screeningId, businessJustification);
            } else {
                writeDeleteActivityToProfile(businessJustification);
                if (props.setDocumentIdWorkaround) {
                    props.setDocumentIdWorkaround(props.documentId ?? '');
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    function writeDeleteActivityToProfile(businessJustification: string): void {
        const activityTag = 'converted';
        const notedEvent = 'noted';
        const tempDeletionnote = `Document ${props.documentFileName} was deleted by ${
            authContext.getUserProfile()?.name
        } with the following business justification: ${businessJustification}`;
        const subjectData: IActivityValue = {
            type: 'personnelId',
            value: userContext.employeeRecord.id,
        };

        const directObjectData: IActivityValue = {
            type: 'documentFileName',
            value: props.documentFileName ?? '',
        };
        const additionalObjectData: IActivityValue[] = [
            {
                type: 'businessJustification',
                value: businessJustification,
            },
            {
                type: 'documentId',
                value: props.documentId ?? '',
            },
        ];

        const activityPostData: IActivityPost = {
            event: notedEvent,
            eventTimestampUTC: new Date().getTime(),
            referenceId: props.employeeId ?? '',
            appName: 'profileApp',
            securityIds:
                props.editableEmployeeId &&
                props.employeeId &&
                props.employeeId !== props.editableEmployeeId
                    ? ['Admin', props.employeeId ?? '', props.editableEmployeeId ?? '']
                    : ['Admin', props.employeeId ?? ''],
            subject: subjectData,
            directObject: directObjectData,
            additionalObjects: additionalObjectData,
            message: tempDeletionnote,
            tags:
                props.editableEmployeeId &&
                props.employeeId &&
                props.employeeId !== props.editableEmployeeId
                    ? [activityTag, props.employeeId ?? '', props.editableEmployeeId ?? '']
                    : [props.employeeId ?? ''],
        };

        ActivitiesClient.getProfileActivityToken(authContext, props.employeeId ?? '', [
            'write',
        ]).then(async (token) => {
            await ActivitiesClient.postProfileActivity(
                authContext,
                props.employeeId ?? '',
                activityPostData,
                token,
            );
        });
    }

    function writeDeleteActivityToScreenings(
        screeningId: string,
        businessJustification: string,
    ): void {
        const event = 'uploaded-document-delete';
        const subjectData: IActivityValue = {
            type: 'personnelId',
            value: userContext.employeeRecord.id,
        };

        const directObjectData: IActivityValue = {
            type: 'documentFileName',
            value: props.documentFileName ?? '',
        };
        const additionalObjectData: IActivityValue[] = [
            {
                type: 'businessJustification',
                value: businessJustification ?? '',
            },
            {
                type: 'documentId',
                value: props.documentId ?? '',
            },
        ];

        const activityPostData: IActivityPost = {
            additionalObjects: additionalObjectData,
            event,
            eventTimestampUTC: new Date().getTime(),
            referenceId: screeningId,
            appName: 'screeningApp',
            securityIds: ['Admin', screeningId],
            subject: subjectData,
            directObject: directObjectData,
            message: businessJustification,
            tags: [],
        };

        ActivitiesClient.getScreeningActivityToken(authContext, screeningId, ['write']).then(
            async (token) => {
                const response = await ActivitiesClient.postScreeningActivity(
                    authContext,
                    screeningId || '',
                    activityPostData,
                    token,
                );

                if (
                    response !== undefined &&
                    response !== null &&
                    response.message !== undefined &&
                    response.message !== null &&
                    props.eventBitWillManuallyUpdate
                ) {
                    props.eventBitWillManuallyUpdate();
                }
            },
        );
    }

    async function onDeleteClick(): Promise<void> {
        setModalOpen(false);
        if (props.documentSourceType === DocumentSourceType.Requested) {
            if (props.onRequestDocumentDelete) {
                // Delete Document from Screening Details page.
                props.onRequestDocumentDelete();
            }
        } else {
            let currentAssociatedToken = props.associatedUserToken.token;
            if (props.getLatestAssociatedToken) {
                currentAssociatedToken = (await props.getLatestAssociatedToken()).token;
            }
            // delete from Profile page and Screening details Adhoc/User uploaded document
            const response = await DocumentsClient.updateTTLForAssociatedDocument(
                authContext,
                props.documentId ?? '',
                config.documentsServiceConfig.associatedUserDocumentTTL,
                businessJustification,
                currentAssociatedToken,
            );

            if (response.status !== 200) {
                setResponseMessage('Something went wrong');
            } else {
                // Send notification that document was successfully deleted.
                const request: IDocumentNotificationRequest = {
                    referenceId: props.employeeId ?? props.editableEmployeeId ?? '',
                    application: ApplicationServices.profile,
                    documentId: props.documentId,
                    event: DocumentEvent.Deleted,
                    metadata: {
                        documentServiceDocumentId: props.documentId,
                        documentReferenceType: `${DocumentReferenceType.PersonnelId}`,
                    },
                };
                await DocumentsClient.sendDocumentNotification(authContext, request);
                writeDeleteDocumentActivityWithFeatureFlagEnabled(businessJustification);

                if (props.onUploadedDocumentDelete) {
                    // delete a Screening Detail's row in the table on document delete
                    props.onUploadedDocumentDelete();
                }
            }
        }
    }
    return (
        <Stack>
            {userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST) && (
                <ActionButton
                    onClick={(): void => {
                        setModalOpen(true);
                    }}
                    iconProps={{ iconName: IconNames.Delete }}
                    text={'Delete'}
                    styles={maxHeightButtonStyles}
                />
            )}

            <Dialog
                hidden={!isModalOpen}
                modalProps={defaultModalProps}
                dialogContentProps={dialogContentProps}
                maxWidth={'md'}>
                <TextField
                    label='Business justification'
                    onChange={onChangeBusinessJustificationField}
                    value={businessJustification}
                    placeholder='Explain why you are deleting this document from Personnel.'
                    multiline
                    required
                />
                <div className={styles.dialogFooter}>
                    <DefaultButton
                        className={styles.dialogDefaultButton}
                        onClick={onCloseClick}
                        text='Cancel'
                    />
                    <span className={styles.separator}></span>
                    <PrimaryButton
                        type='submit'
                        disabled={businessJustification === ''}
                        className={styles.dialogPrimaryButton}
                        onClick={onDeleteClick}
                        text='Delete'
                    />
                </div>
                {responseMessage !== '' && (
                    <div className={styles.responseMessage}>
                        <MessageBar messageBarType={MessageBarType.error}>
                            {responseMessage}
                        </MessageBar>
                    </div>
                )}
            </Dialog>
        </Stack>
    );
}

const styles = mergeStyleSets({
    title: [
        FontSizes.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${SharedColors.cyanBlue10}`,
            display: 'flex',
            fontSize: FontSizes.xLargePlus,
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '30px 35px 0 35px',
        },
    ],
    subTitle: [
        FontSizes.small,
        {
            flex: '1 1 auto',
            display: 'flex',
            fontSize: FontSizes.medium,
            alignItems: 'center',
            padding: '0 35px',
        },
    ],
    body: {
        padding: '15px 35px 30px 35px',
        color: '#1b1b1b',
    },
    footer: {
        backgroundColor: '#FEFCFE',
        padding: '15px 15px 15px 15px',
        borderTop: '1px solid #EAE9EA',
        display: 'block',
    },
    modalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    separator: {
        flex: '1 0 0',
    },
    dialogTitle: {
        whiteSpace: 'nowrap',
        flex: '1 1 auto',
        borderTop: `4px solid ${SharedColors.cyanBlue10}`,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
    },
    dialogFooter: {
        backgroundColor: '#FEFCFE',
        borderTop: '1px solid #EAE9EA',
        display: 'flex',
        alignItems: 'center',
        padding: '20px 24px 15px 24px',
        margin: '0 -24px -24px -24px',
    },
    dialogTextAreaLabel: {
        fontSize: FontSizes.medium,
        fontWeight: FontWeights.semibold,
    },
    dialogTextArea: {
        minWidth: '100%',
        minHeight: '50px',
        paddingLeft: '10px',
        paddingTop: '10px',
    },
    dialogDefaultButton: {
        display: 'flex-start',
    },
    dialogPrimaryButton: {
        justifyContent: 'flex-end',
    },
    responseMessage: {
        marginTop: 20,
        fontSize: FontSizes.medium,
    },
});
