import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from 'contexts/auth-context';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import ScaClient, {
    IReviewPeriod,
    IScaCreateReviewRequestBody,
    IEscalation,
} from 'clients/sca-client';
import { IconNames } from 'assets/constants/global-constants';
import {
    ReviewStatusNames,
    ReviewPeriodStatusCodeType,
    reviewPeriodStatuses,
    isReviewPeriodStatusNameValid,
    getReviewPeriodStatusCode,
} from 'components/sca/sca-constants';
import { Dropdown, IDropdownOption, Checkbox } from '@fluentui/react';

interface IUpdateReviewPeriodStatusModalButtonProps {
    reviewPeriod: IReviewPeriod | undefined;
    onReviewPeriodUpdated: (result: IReviewPeriod) => void;
}

export default function UpdateReviewPeriodStatusModalButton(
    props: IUpdateReviewPeriodStatusModalButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const [selection, setSelection] = useState<IDropdownOption>();
    const [shouldClose, setShouldClose] = useState<boolean>(false);

    const disableSubmit = (): boolean => {
        return (
            props.reviewPeriod === undefined ||
            props.reviewPeriod?.id === undefined ||
            props.reviewPeriod?.title === undefined ||
            props.reviewPeriod?.startDateUTC === undefined ||
            props.reviewPeriod?.endDateUTC === undefined ||
            props.reviewPeriod?.approvalStartDateUTC === undefined ||
            props.reviewPeriod?.approvalEndDateUTC === undefined ||
            props.reviewPeriod?.escalations === undefined ||
            selection?.text === props.reviewPeriod.state ||
            (selection?.text === ReviewStatusNames.CLOSED && !shouldClose)
        );
    };

    const onSubmit = async (): Promise<IReviewPeriod> => {
        const requestBody: IScaCreateReviewRequestBody = {
            // the following typecasts are safe because function disableSubmit()
            // will prevent submit if either of the following is undefined.
            id: props.reviewPeriod?.id as string,
            title: props.reviewPeriod?.title as string,
            startDate: new Date((props.reviewPeriod?.startDateUTC as number) * 1000),
            endDate: new Date((props.reviewPeriod?.endDateUTC as number) * 1000),
            eligibilityDate: new Date((props.reviewPeriod?.eligibilityDateUTC as number) * 1000),
            approvalStartDate: new Date(
                (props.reviewPeriod?.approvalStartDateUTC as number) * 1000,
            ),
            approvalEndDate: new Date((props.reviewPeriod?.approvalEndDateUTC as number) * 1000),
            escalations: (props.reviewPeriod?.escalations as IEscalation[]).map((escalation) => ({
                name: escalation.name,
                hierarchy: escalation.hierarchy,
                date: new Date(escalation.date * 1000),
            })),
            // There is no check for validity of selection.key. Nevertheless,
            // the following typecast is safe because when generating the
            // selection keys, the code would only generate valid keys
            // by calling reviewPeriodStatuses().
            state: selection?.key as ReviewPeriodStatusCodeType,
        };
        // Tech Debt
        // The following code relies on ModalActionButton to catch error.
        // A better way to handle the error is to get text of the error
        // and throw it. ModalActionButton will then catch and display it
        // on a banner on the modal.
        // Leave the try/catch to ModalActionButton
        return await ScaClient.updateReviewPeriod(authContext, requestBody);
    };

    const onModalConcluded = (conclusion: ModalConclusion, result?: IReviewPeriod): void => {
        if (conclusion === ModalConclusion.Done && result !== undefined) {
            props.onReviewPeriodUpdated(result);
        }
        setShouldClose(false);
    };

    const onStatusSelection = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): void => {
        setShouldClose(false);
        if (option) {
            setSelection(option);
        }
    };

    const onClickShouldUpdate = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean,
    ) => {
        if (checked !== undefined) {
            setShouldClose(checked);
        } else {
            setShouldClose(false);
        }
    };

    useEffect(() => {
        const state = props.reviewPeriod?.state;
        if (isReviewPeriodStatusNameValid(state)) {
            // The following typecasts are safe because the above
            // function call has already validated the input.
            setSelection({
                key: getReviewPeriodStatusCode(state as string) as number,
                text: state as string,
            });
        } else {
            setSelection(undefined);
        }
    }, [props.reviewPeriod?.state]);

    const selectionOptions: IDropdownOption[] = reviewPeriodStatuses().map((status) => ({
        key: status.code,
        text: status.name,
    }));

    if (props.reviewPeriod?.state === ReviewStatusNames.CLOSED) {
        // Can't change status of closed review periods.
        return <></>;
    } else {
        return (
            <ModalActionButton<IReviewPeriod>
                text={'Review Period Status'}
                iconName={IconNames.Edit}
                errorMsg=''
                modalTitle={'Review Period Status'}
                modalTitleIcon={IconNames.Edit}
                enableSubmit={!disableSubmit()}
                submitButtonText={'Update'}
                submitButtonIcon={IconNames.Edit}
                onSubmit={onSubmit}
                onModalConcluded={onModalConcluded}>
                <Dropdown
                    options={selectionOptions}
                    onChange={onStatusSelection}
                    selectedKey={selection?.key}></Dropdown>
                {selection?.text === ReviewStatusNames.CLOSED && (
                    <>
                        <p>
                            If you close a review period, you won&apos;t be able to update it again.
                            If you&apos;re certain you want to close it, check the following box.
                        </p>
                        <Checkbox
                            label={"I'm sure I want to close this review period"}
                            checked={shouldClose}
                            onChange={onClickShouldUpdate}
                        />
                    </>
                )}
            </ModalActionButton>
        );
    }
}
