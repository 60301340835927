import React from 'react';
import { useCheckMountedState } from 'utils/misc-hooks';
import { Checkbox, ICheckboxProps, IRenderFunction } from '@fluentui/react';
import { IUseInputElement } from 'components/common/use-input/input-element-utils';
import Spacer from 'components/common/spacer';

interface ICheckboxParams {
    // Ideally, we should keep the following props 100% the same
    // as the underlying Fluent component so that they can directly
    // drive its props.
    // In this hook, name of prop isDisabled is different from
    // that of the underlying component to aviod having to disable
    // linting on all the code that uses it.
    label?: string;
    isDisabled?: boolean;
    onRenderLabel?: IRenderFunction<ICheckboxProps> | undefined;
    marginTop?: number;
}

export function useCheckbox(params: ICheckboxParams): IUseInputElement<boolean> {
    const [isChecked, setIsChecked] = useCheckMountedState<boolean | undefined>(undefined);

    const onValueChange = (a?: React.FormEvent<HTMLElement>, checked?: boolean): void => {
        if (a?.type === 'change') {
            setIsChecked(checked);
        }
    };

    const initialize = (initValue?: boolean): void => {
        setIsChecked(initValue);
    };

    return {
        value: isChecked,
        initialize,
        theElement: (): JSX.Element => (
            <>
                {!!params.marginTop && <Spacer marginTop={params.marginTop} />}
                <Checkbox
                    label={params.label}
                    checked={isChecked ?? false}
                    disabled={params.isDisabled}
                    onChange={onValueChange}
                    onRenderLabel={params.onRenderLabel}
                />
            </>
        ),
    };
}
