import { mergeStyleSets } from '@fluentui/react';
import React from 'react';

export interface IFacilitiesSvgMapToolbarProps {
    onZoomIn: () => void;
    onZoomOut: () => void;
}

const classNames = mergeStyleSets({
    toolbarStyle: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        zIndex: 100,
    },
    buttonStyle: {
        display: 'block',
        position: 'relative',
        width: '32px',
        height: '32px',
        color: 'grey',
        background: '#fff',
        boxShadow: 'rgba(0, 0, 0, .16) 0 0 4px',
        border: '0px',
        outline: '0px',
        cursor: 'pointer',
        fontSize: '20px',
        '&:focus-visible': {
            outline: 'black auto 1px',
        },
    },
});

export default function FacilitiesSvgMapToolbar(props: IFacilitiesSvgMapToolbarProps): JSX.Element {
    return (
        <div className={classNames.toolbarStyle}>
            <button onClick={props.onZoomIn} className={classNames.buttonStyle} title='Zoom In'>
                +
            </button>
            <button onClick={props.onZoomOut} className={classNames.buttonStyle} title='Zoom Out'>
                -
            </button>
        </div>
    );
}
