import useMessageBar from 'components/common/use-message-bar';
import { FiltersClearanceRecords } from 'contexts/filters-clearance-records';
import { filterContextKeys, FiltersContext, IFiltersContext } from 'contexts/filters-context';
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IDropdownOption,
    MessageBarType,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { dropdownComboBox } from 'components/screening/common/filters/common-filter-styling';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/suitability/profile-suitability-utils';
export interface RequestAgencyFilterProps {
    rawCandidates: ICommonScreening[];
    filterContext: IFiltersContext;
}

export function RequestAgencyFilter(props: RequestAgencyFilterProps): JSX.Element {
    const filtersClearanceRecordsContext = useContext(FiltersClearanceRecords);
    const filtersCandidateContext = useContext(FiltersContext);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const {
        theMessage: message,
        theElement: messageElement,
        setMessage: setMessage,
    } = useMessageBar({
        type: MessageBarType.error,
    });
    const agencyOptions = getRequestAgencyOptions(props.rawCandidates) || [];
    useEffect(() => {
        if (filtersCandidateContext?.filterCleared || filtersClearanceRecordsContext?.filterCleared)
            setSelectedKeys([]);
    }, [filtersCandidateContext?.filterCleared, filtersClearanceRecordsContext?.filterCleared]);
    return (
        <>
            <ComboBox
                ariaLabel='Agencies'
                multiSelect
                selectedKey={selectedKeys}
                placeholder={'Request Agency'}
                autoComplete='on'
                options={agencyOptions}
                onChange={onAgencySelect}
                useComboBoxAsMenuWidth
                allowFreeform
                styles={dropdownComboBox}
            />
            {!!message && messageElement()}
        </>
    );

    function getOptionsFromValidAgencies(validAgency: Set<string>): IDropdownOption[] {
        let options: IDropdownOption[] = [];
        validAgency.forEach((agency) => {
            if (agency) {
                const agencyFullText = getAgencyEnumValueFromKey(agency, true);
                options.push({ key: agency, text: agencyFullText });
            }
        });
        options = options.sort((a, b) => {
            const aUpper = a.text.toUpperCase();
            const bUpper = b.text.toUpperCase();
            if (aUpper < bUpper) return -1;
            if (aUpper > bUpper) return 1;
            return 0;
        });
        return options;
    }

    function getRequestAgencyOptions(rawCandidates: ICommonScreening[]): IDropdownOption[] {
        const validAgency = new Set(
            rawCandidates
                .filter((record): boolean => (record?.publicTrustAgency ? true : false))
                .map((x) => x.publicTrustAgency || ''),
        );
        return getOptionsFromValidAgencies(validAgency);
    }

    function onAgencySelect(event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void {
        if (option && option.key) {
            const agency = option.key;
            try {
                const isAdding = !!option.selected;
                let newlySelected: string[] = [];
                setSelectedKeys((prevSelectedKeys) => {
                    newlySelected = isAdding
                        ? [...prevSelectedKeys, agency as string]
                        : prevSelectedKeys.filter((k) => k !== agency);
                    return newlySelected;
                });
                const newFilterFunctions: {
                    func: (arg: ICommonScreening) => boolean;
                    undefined: undefined;
                }[] = [];
                if (newlySelected.length > 0) {
                    const func = (screening: ICommonScreening): boolean => {
                        if (screening.publicTrustAgency) {
                            return newlySelected.includes(screening.publicTrustAgency);
                        }
                        return false;
                    };
                    newFilterFunctions.push({ func, undefined });
                }
                props.filterContext.setFilterFunctions(
                    filterContextKeys.publicTrustAgency,
                    newFilterFunctions,
                );
            } catch (error) {
                setMessage(`An error occurred while loading the selected Agency information.`);
            }
        }
    }
}
export default RequestAgencyFilter;
