import {
    CreateAttributeResponse,
    EditAttributeResponse,
    GetAttributeResult,
    GetAttributeSetByIdResult,
    GetDataSourceFieldsResult,
} from 'personnel-core-clients';
import React, { useState } from 'react';
import { PredefinedValuePanel } from 'components/core/attributes/predefined-values/predefined-value-panel';
import AttributeMetadataPanel from 'components/core/attributes/attribute-metadata-panel';

export enum AttributeModalType {
    Add,
    Edit,
}

enum AttributeAddEditPanelPage {
    AttributeMetadata,
    PredefinedValues,
}

export interface IAddEditAttributePanelProps {
    isOpen?: boolean;
    modalType: AttributeModalType;
    attributeSet?: GetAttributeSetByIdResult | undefined;
    initAttribute?: GetAttributeResult | undefined;
    initAttributes?: GetAttributeResult[] | undefined;
    autoAssignedDataSourceFields?: GetDataSourceFieldsResult | undefined;
    isAdmin?: boolean;
    onFinish: () => void;
    onDismiss: () => void;
}
export default function AddEditAttributePanel(props: IAddEditAttributePanelProps): JSX.Element {
    const [currentPage, setCurrentPage] = useState<AttributeAddEditPanelPage>(
        AttributeAddEditPanelPage.AttributeMetadata,
    );

    const [createdOrUpdatedAttribute, setCreatedOrUpdatedAttribute] = useState<
        CreateAttributeResponse | EditAttributeResponse
    >();

    const onAttributeCreateOrUpdate = (
        attribute: CreateAttributeResponse | EditAttributeResponse,
    ): void => {
        if (attribute.usePredefinedValues) {
            setCreatedOrUpdatedAttribute(attribute);
            setCurrentPage(AttributeAddEditPanelPage.PredefinedValues);
        } else {
            onFinish();
        }
    };

    const reset = (): void => {
        setCurrentPage(AttributeAddEditPanelPage.AttributeMetadata);
        setCreatedOrUpdatedAttribute(undefined);
    };

    const onDismiss = (): void => {
        reset();
        props.onDismiss();
    };

    const onFinish = (): void => {
        reset();
        props.onFinish();
    };

    if (currentPage === AttributeAddEditPanelPage.AttributeMetadata) {
        return (
            <AttributeMetadataPanel
                isOpen={props.isOpen && currentPage === AttributeAddEditPanelPage.AttributeMetadata}
                modalType={props.modalType}
                attributeSet={props.attributeSet}
                initAttribute={props.initAttribute}
                initAttributes={props.initAttributes}
                autoAssignedDataSourceFields={props.autoAssignedDataSourceFields}
                isAdmin={props.isAdmin}
                onSuccess={onAttributeCreateOrUpdate}
                onDismiss={onDismiss}
            />
        );
    } else {
        return (
            <PredefinedValuePanel
                title={
                    props.modalType === AttributeModalType.Add ? 'Add attribute' : 'Edit attribute'
                }
                isOpen={props.isOpen && currentPage === AttributeAddEditPanelPage.PredefinedValues}
                onDismiss={onFinish}
                attributeId={createdOrUpdatedAttribute?.id}
            />
        );
    }
}
