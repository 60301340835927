import React, { useState, useContext, ReactNode } from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { AuthContext } from 'contexts/auth-context';
import { Dictionary } from 'assets/constants/global-constants';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import { isGUID } from 'utils/string-utils';
import ReportsClient, { IHierarchy } from 'clients/reports-client';
import { pageStyle } from 'components/screening/common/common-tab-styling';
import { updateHierarchyCache } from 'components/screening/common/content-tabs-help';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import CandidateNominationStepperCommon from 'components/screening/common/candidate-nomination-stepper-common';

export interface ContentCandidatesTabProps {
    isModalOpen: boolean;
    closeModal: () => void;
    pageName: string;
    pathName: string;
    onCandidateNominated: (nomination: ICommonScreening) => void;
    onNominationError: (errorMessage: string) => void;
}

export default function NominateCandidateModal(props: ContentCandidatesTabProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState<string>('');

    /* keep */ const [
        employeeIdsWithFutureTerminationDates,
        setEmployeeIdsWithFutureTerminationDates,
    ] = useState<string[]>([]);

    /* keep */ const [hierarchyRecords, setHierarchyRecords] = useState<Dictionary<IHierarchy>>();

    const NominationStepperContainer = (params: { children: ReactNode }): JSX.Element => {
        // Keeping this pass through component for the same reason as the component
        // with the same name in file content-public-trust-tab.tsx. See the comments there.
        return <>{params.children}</>;
    };

    return (
        <NominationStepperContainer>
            {!!errorMessage && (
                <MessageBar messageBarType={MessageBarType.error}>{errorMessage}/</MessageBar>
            )}
            <CandidateNominationStepperCommon
                isOpen={props.isModalOpen}
                containerClassName={pageStyle.modalContainer}
                onDismiss={props.closeModal}
                hideNominationProcess={async (nomination?: ICommonScreening): Promise<void> => {
                    if (nomination) {
                        if (!isGUID(nomination.personnelId)) {
                            try {
                                const nominatedHierarchyData = await ReportsClient.getSelectHierarchy(
                                    authContext,
                                    [nomination.personnelId],
                                );
                                const combinedHierarchyData: Dictionary<IHierarchy> = Object.assign(
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                    hierarchyRecords!,
                                    nominatedHierarchyData,
                                );
                                updateHierarchyCache(combinedHierarchyData, localStorage);
                                setHierarchyRecords(combinedHierarchyData);

                                if (
                                    !employeeIdsWithFutureTerminationDates.some(
                                        (x) => x === nomination.personnelId,
                                    )
                                ) {
                                    const results = await UsGovScreeningClient.getEmployeeIdsWithFutureTerminationDates(
                                        authContext,
                                        [nomination.personnelId],
                                    );
                                    setEmployeeIdsWithFutureTerminationDates([
                                        ...results,
                                        ...employeeIdsWithFutureTerminationDates,
                                    ]);
                                }
                            } catch (error) {
                                console.error(
                                    `failed to grab the hierarchy records for ${nomination.contact?.homeEmail}`,
                                );
                            }
                        }
                        props.onCandidateNominated(nomination);
                    }
                    props.closeModal();
                }}
                onNominationError={props.onNominationError}
                screeningPath={ScreeningPaths.UsGov}
            />
        </NominationStepperContainer>
    );
}
