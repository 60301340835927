import { useContext, useMemo } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import { Role } from 'configs/roles';

export enum AttributeRoleCheckType {
    Reader = 0,
    Manager = 1,
    Owner = 2,
}

export interface IRolesContainer {
    readers: string[];
    managers: string[];
    owners: string[];
}

export function useAttributeRoleCheck(
    role: AttributeRoleCheckType,
    attributeSet?: IRolesContainer,
    attribute?: IRolesContainer,
) {
    const authContext = useContext(AuthContext);
    const principalContext = useContext(PrincipalUserContext);

    const isAuthorized = useMemo(() => {
        const isAdmin =
            authContext.isInRole(Role.PortalAdmin) || authContext.isInRole(Role.CoreAttributeAdmin);

        if (isAdmin) {
            return true;
        }

        const principalOid = principalContext.principalRecord.oid;

        if (principalOid === undefined) {
            return false;
        }

        const authorizedOids = new Set<string>();
        if (role <= AttributeRoleCheckType.Reader) {
            attributeSet?.readers.forEach((oid) => authorizedOids.add(oid));
            attribute?.readers.forEach((oid) => authorizedOids.add(oid));
        }

        if (role <= AttributeRoleCheckType.Manager) {
            attributeSet?.managers.forEach((oid) => authorizedOids.add(oid));
            attribute?.managers.forEach((oid) => authorizedOids.add(oid));
        }

        if (role <= AttributeRoleCheckType.Owner) {
            attributeSet?.owners.forEach((oid) => authorizedOids.add(oid));
            attribute?.owners.forEach((oid) => authorizedOids.add(oid));
        }

        return authorizedOids.has(principalOid);
    }, [attribute, attributeSet, authContext, principalContext]);

    return isAuthorized;
}
