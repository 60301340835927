import React, { useEffect, useState } from 'react';
import {
    mergeStyleSets,
    FontWeights,
    TextField,
    Dropdown,
    IDropdownOption,
    DatePicker,
} from '@fluentui/react';
import ContainerWithEtiquettes, {
    IContainerWithEtiquettesProps,
} from 'components/common/container-with-etiquettes';
import { nameof, setNestedKeyValueOfCloneObject } from 'utils/object-utils';
import { SharedColors } from '@fluentui/theme';
import { IPerson } from 'clients/graph-client';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { dateToLocalLongDateFormat } from 'utils/time-utils';
import {
    specialAccessComparator,
    SpecialAccesses,
} from 'components/personnel-profile/clearance/profile-clearance-constants';
import {
    AdjudicatedSubStates,
    UsGovRequestTypes,
    US_GOV_REQUEST_TYPE_OPTIONS,
} from 'components/screening/us-gov/IScreening';
import {
    ClearanceLevelType,
    CURRENT_LEVEL_OPTIONS,
    getParentState,
    ScreeningParentStateType,
    ScreeningTargetLevelLabel,
    TARGET_LEVEL_OPTIONS,
} from 'components/screening/common/common-constants';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';

export const ADJUDICATED_OPTIONS: IDropdownOption[] = [
    { key: AdjudicatedSubStates.Final, text: AdjudicatedSubStates.Final, index: 0 },
    { key: AdjudicatedSubStates.Interim, text: AdjudicatedSubStates.Interim, index: 1 },
    { key: AdjudicatedSubStates.Denied, text: AdjudicatedSubStates.Denied, index: 2 },
];

const ACCESS_CODE_OPTIONS_SCI: IDropdownOption[] = [
    { key: SpecialAccesses.SI, text: SpecialAccesses.SI, index: 1 },
    { key: SpecialAccesses.TK, text: SpecialAccesses.TK, index: 2 },
    { key: SpecialAccesses.G, text: SpecialAccesses.G, index: 3 },
    { key: SpecialAccesses.HCS, text: SpecialAccesses.HCS, index: 4 },
    { key: SpecialAccesses.KLM, text: SpecialAccesses.KLM, index: 5 },
];

enum USGovEditableFields {
    requestType = 'requestType',
    clearance = 'clearance',
    isCustomerBadgeRequired = 'isCustomerBadgeRequired',
    adjudicatedResult = 'adjudicatedResult',
    adjudicatedUtcMillis = 'adjudicatedUtcMillis',
    indocBriefingUtcMillis = 'indocBriefingUtcMillis',
    indocTrainingUtcMillis = 'indocTrainingUtcMillis',
}

export interface ScreeningFieldSetProps {
    screening: ICommonScreening;
    processOwner?: IPerson;
    isDataEditable: boolean;
    onEditableFieldChange(screen: ICommonScreening): void;
}

function ScreeningFieldSet(props: ScreeningFieldSetProps): JSX.Element {
    const containerProps: IContainerWithEtiquettesProps = {
        leftEtiquetteLabel: 'Screening',
        rightEtiquette: {
            label: getParentState(props.screening.stateName).toUpperCase(),
            backgroundColor: SharedColors.cyanBlue10,
        },
    };

    const [screening, setScreening] = useState<ICommonScreening>(props.screening);
    const [specialAccesses, setSpecialAccesses] = useState<Set<string>>(new Set<string>());
    const [isSpecialAccessEditable, setIsSpecialAccessEditable] = useState<boolean>(false);

    const usGovRequestTypeOptions = US_GOV_REQUEST_TYPE_OPTIONS.map((option) => {
        return {
            ...option,
            disabled: option.key === nameof<typeof UsGovRequestTypes>('PeriodicReinvestigation'),
        };
    });

    useEffect(() => {
        if (props.isDataEditable) {
            setSpecialAccesses(new Set<string>(screening.access));
            if (screening.clearance === ClearanceLevelType.SCI) {
                setIsSpecialAccessEditable(true);
            }
        }
    }, [props.isDataEditable]);

    useEffect(() => {
        if (screening.clearance === ClearanceLevelType.SCI) {
            setIsSpecialAccessEditable(true);
        } else {
            setIsSpecialAccessEditable(false);
            // reset special access selections
            setSpecialAccesses(new Set<string>());
            onSpecialAccessesChange(new Set<string>());
        }
    }, [screening.clearance]);

    function onTextInputChange(
        e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void {
        const target: HTMLInputElement = e.target as HTMLInputElement;
        if (typeof newValue === 'string' && target.name) {
            const fieldName = target.name;
            const updatedScreening = setNestedKeyValueOfCloneObject<ICommonScreening>(
                screening,
                newValue,
                fieldName,
            );
            if (updatedScreening) {
                setScreening(updatedScreening);
                props.onEditableFieldChange(updatedScreening);
            }
        }
    }

    function onDateInputChange(fieldName: string, newDate?: Date | null): void {
        const updatedScreening = setNestedKeyValueOfCloneObject<ICommonScreening>(
            screening,
            newDate?.getTime(),
            fieldName,
        );
        if (updatedScreening) {
            setScreening(updatedScreening);
            props.onEditableFieldChange(updatedScreening);
        }
    }

    function onDropdownChange(
        fieldNames: string[],
        options: IDropdownOption[],
        index?: number,
    ): void {
        if (index !== undefined && index < options.length) {
            // if nested prop is isCustomerBadgeRequired then it needs to be converted to a boolean value
            let value =
                fieldNames[0] === USGovEditableFields.isCustomerBadgeRequired
                    ? options[index].text === ScreeningTargetLevelLabel.Required
                    : options[index].text;
            if (fieldNames[0] === USGovEditableFields.clearance) {
                screening.access = [];
                const currentSpecialAccesses = new Set<string>();
                setSpecialAccesses(currentSpecialAccesses);
                onSpecialAccessesChange(currentSpecialAccesses);
            }

            // for requestType we want to send the key value instead of the text value going forward for data consistency (12/1/2022)
            if (fieldNames[0] === USGovEditableFields.requestType) {
                value = options[index].key.toString();
            }

            const updatedScreening = setNestedKeyValueOfCloneObject<ICommonScreening>(
                screening,
                value,
                fieldNames[0],
            );
            if (updatedScreening) {
                setScreening(updatedScreening);
                props.onEditableFieldChange(updatedScreening);
            }
        }
    }

    function onSpecialAccessesChange(updatedSpecialAccess: Set<string>): void {
        const updatedScreening = setNestedKeyValueOfCloneObject<ICommonScreening>(
            screening,
            Array.from(updatedSpecialAccess),
            ...['access'],
        );

        if (updatedScreening) {
            setScreening(updatedScreening);
            props.onEditableFieldChange(updatedScreening);
        }
    }

    function getTextForIsCustomerBadgeRequired(): string {
        return screening.isCustomerBadgeRequired
            ? ScreeningTargetLevelLabel.Required
            : ScreeningTargetLevelLabel.NotRequired;
    }

    function isInEditableScreeningState(screening: ICommonScreening): boolean {
        return (
            getParentState(screening.stateName) === ScreeningParentStateType.Nomination ||
            getParentState(screening.stateName) === ScreeningParentStateType.Preparation
        );
    }

    return (
        <ContainerWithEtiquettes {...containerProps}>
            <div className={styles.container}>
                <div className={styles.colGroup}>
                    <div className={styles.row}>
                        <div className={styles.keyCell}>Request Type</div>
                        <div className={styles.valueCell}>
                            {props.isDataEditable && isInEditableScreeningState(screening) ? (
                                <Dropdown
                                    key={USGovEditableFields.requestType}
                                    options={usGovRequestTypeOptions}
                                    onChange={(
                                        event: React.FormEvent<HTMLDivElement>,
                                        option?: IDropdownOption,
                                        index?: number,
                                    ): void => {
                                        onDropdownChange(
                                            [USGovEditableFields.requestType],
                                            usGovRequestTypeOptions,
                                            index,
                                        );
                                    }}
                                    defaultSelectedKey={
                                        // due to the requestType field mixing the usage of key and text, we have
                                        // to check both fields to find the value index
                                        usGovRequestTypeOptions.find(
                                            (x) =>
                                                x.text === screening.requestType ||
                                                x.key === screening.requestType,
                                        )?.key
                                    }
                                />
                            ) : (
                                UsGovRequestTypes[
                                    screening.requestType as keyof typeof UsGovRequestTypes
                                ] ?? screening.requestType
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.keyCell}>Clearance</div>
                        <div className={styles.valueCell}>
                            {props.isDataEditable && isInEditableScreeningState(screening) ? (
                                <Dropdown
                                    key={USGovEditableFields.clearance}
                                    options={CURRENT_LEVEL_OPTIONS}
                                    onChange={(
                                        event: React.FormEvent<HTMLDivElement>,
                                        option?: IDropdownOption,
                                        index?: number,
                                    ): void => {
                                        onDropdownChange(
                                            [USGovEditableFields.clearance],
                                            CURRENT_LEVEL_OPTIONS,
                                            index,
                                        );
                                    }}
                                    defaultSelectedKey={
                                        CURRENT_LEVEL_OPTIONS.find(
                                            (x) => x.text === screening.clearance,
                                        )?.key
                                    }
                                />
                            ) : (
                                screening.clearance
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.keyCell}>Process Owner</div>
                        <div className={styles.keyCell}>
                            {props.processOwner && (
                                <EmployeeHoverCard
                                    personnelAlias={
                                        props.processOwner.userPrincipalName.split('@')[0]
                                    }>
                                    {props.processOwner.displayName}
                                </EmployeeHoverCard>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.colGroup}>
                    <div className={styles.row}>
                        <div className={styles.keyCell}>Access</div>
                        <div className={styles.valueCell}>
                            {props.isDataEditable &&
                            isInEditableScreeningState(screening) &&
                            isSpecialAccessEditable ? (
                                <Dropdown
                                    options={ACCESS_CODE_OPTIONS_SCI}
                                    multiSelect={true}
                                    onChange={(
                                        event: React.FormEvent<HTMLDivElement>,
                                        option?: IDropdownOption,
                                        index?: number,
                                    ): void => {
                                        const currentSpecialAccesses = new Set<string>(
                                            specialAccesses,
                                        );
                                        const specialAccess = option?.key as string;
                                        currentSpecialAccesses.has(specialAccess)
                                            ? currentSpecialAccesses.delete(specialAccess)
                                            : currentSpecialAccesses.add(specialAccess);
                                        setSpecialAccesses(currentSpecialAccesses);
                                        onSpecialAccessesChange(currentSpecialAccesses);
                                    }}
                                    defaultSelectedKeys={screening.access?.filter(
                                        (value, index, self) => self.indexOf(value) === index,
                                    )}
                                />
                            ) : (
                                <div className={styles.multiValueCell}>
                                    {[...(screening.access || [])]
                                        .filter(
                                            (value, index, self) => self.indexOf(value) === index,
                                        )
                                        .sort(specialAccessComparator)
                                        .map((x) => {
                                            return (
                                                <div key={x} className={styles.badge}>
                                                    {x}
                                                </div>
                                            );
                                        })}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.keyCell}>Customer Badge</div>
                        <div className={styles.valueCell}>
                            {props.isDataEditable && isInEditableScreeningState(screening) ? (
                                <Dropdown
                                    key={USGovEditableFields.isCustomerBadgeRequired}
                                    options={TARGET_LEVEL_OPTIONS}
                                    onChange={(
                                        event: React.FormEvent<HTMLDivElement>,
                                        option?: IDropdownOption,
                                        index?: number,
                                    ): void => {
                                        onDropdownChange(
                                            [USGovEditableFields.isCustomerBadgeRequired],
                                            TARGET_LEVEL_OPTIONS,
                                            index,
                                        );
                                    }}
                                    defaultSelectedKey={getTextForIsCustomerBadgeRequired()}
                                />
                            ) : (
                                getTextForIsCustomerBadgeRequired()
                            )}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.keyCell}>eQIP</div>
                        <div>
                            {props.isDataEditable ? (
                                <TextField
                                    name='eqipId'
                                    value={screening?.eqipId ?? ''}
                                    onChange={onTextInputChange}
                                />
                            ) : (
                                screening.eqipId
                            )}
                        </div>
                    </div>
                    {(getParentState(screening.stateName) === ScreeningParentStateType.Indoc ||
                        screening.adjudicatedResult) && (
                        <>
                            <div className={styles.row}>
                                <div className={styles.keyCell}>Adjudicated Decision</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <Dropdown
                                            key={USGovEditableFields.adjudicatedResult}
                                            options={ADJUDICATED_OPTIONS}
                                            onChange={(
                                                event: React.FormEvent<HTMLDivElement>,
                                                option?: IDropdownOption,
                                                index?: number,
                                            ): void => {
                                                onDropdownChange(
                                                    [USGovEditableFields.adjudicatedResult],
                                                    ADJUDICATED_OPTIONS,
                                                    index,
                                                );
                                            }}
                                            defaultSelectedKey={
                                                ADJUDICATED_OPTIONS.find(
                                                    (x) => x.text === screening.adjudicatedResult,
                                                )?.key
                                            }
                                        />
                                    ) : (
                                        screening.adjudicatedResult
                                    )}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.keyCell}>Adjudicated Date</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <DatePicker
                                            ariaLabel='Adjudicated Date'
                                            key={USGovEditableFields.adjudicatedUtcMillis}
                                            value={
                                                screening?.adjudicatedUtcMillis
                                                    ? new Date(screening.adjudicatedUtcMillis)
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void =>
                                                onDateInputChange(
                                                    USGovEditableFields.adjudicatedUtcMillis,
                                                    newDate,
                                                )
                                            }
                                        />
                                    ) : (
                                        dateToLocalLongDateFormat(screening.adjudicatedUtcMillis)
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {(getParentState(screening.stateName) === ScreeningParentStateType.Indoc ||
                        screening?.indocBriefingUtcMillis > 0 ||
                        screening?.indocTrainingUtcMillis > 0) && (
                        <>
                            <div className={styles.row}>
                                <div className={styles.keyCell}>Briefing Date</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <DatePicker
                                            ariaLabel='Indoc Briefing Date'
                                            key={USGovEditableFields.indocBriefingUtcMillis}
                                            value={
                                                screening?.indocBriefingUtcMillis
                                                    ? new Date(screening.indocBriefingUtcMillis)
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void =>
                                                onDateInputChange(
                                                    USGovEditableFields.indocBriefingUtcMillis,
                                                    newDate,
                                                )
                                            }
                                        />
                                    ) : (
                                        dateToLocalLongDateFormat(screening.indocBriefingUtcMillis)
                                    )}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.keyCell}>Training Date</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <DatePicker
                                            ariaLabel='Indoc Training Date'
                                            key={USGovEditableFields.indocTrainingUtcMillis}
                                            value={
                                                screening?.indocTrainingUtcMillis
                                                    ? new Date(screening.indocTrainingUtcMillis)
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void =>
                                                onDateInputChange(
                                                    USGovEditableFields.indocTrainingUtcMillis,
                                                    newDate,
                                                )
                                            }
                                        />
                                    ) : (
                                        dateToLocalLongDateFormat(screening.indocTrainingUtcMillis)
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </ContainerWithEtiquettes>
    );
}
const styles = mergeStyleSets({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    colGroup: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '48%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '3px',
        paddingBottom: '3px',
        alignItems: 'center',
    },
    keyCell: {
        flexBasis: '50%',
        minWidth: 130,
        fontWeight: FontWeights.semibold,
    },
    valueCell: {
        flexBasis: '50%',
        minWidth: 130,
    },
    multiValueCell: {
        flexBasis: '50%',
        display: 'flex',
        minWidth: 130,
        alignItems: 'center',
    },
    badge: {
        padding: '2px 7px',
        color: 'rgba(255, 255, 255, 0.867)',
        fontSize: 13,
        borderRadius: 1.5,
        backgroundColor: SharedColors.cyanBlue10,
        selectors: {
            '&:not(:last-child):not(:first-child)': {
                marginLeft: 5,
                marginRight: 5,
            },
        },
    },
});
export default ScreeningFieldSet;
