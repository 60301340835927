import { Stack, mergeStyles } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import { IGroupPolicyViolationRule } from 'clients/group-client';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { ModalSizeType } from 'components/common/modal';
import Spacer from 'components/common/spacer';
import { CoreEmployeeHoverCardFromGraph } from 'components/core/common/employee-card/core-employee-hover-card';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { doNothing, doNothingAsync } from 'utils/misc-utils';

interface IRemediationStepsModalActionButtonProps {
    policyViolation: IGroupPolicyViolationRule;
    groupName: string;
}

export default function RemediationStepsModalActionButton(
    props: IRemediationStepsModalActionButtonProps,
): JSX.Element {
    return (
        <ModalActionButton<boolean | undefined>
            text='Take action'
            buttonStyle={buttonStyle}
            enable={true}
            iconName={''}
            modalTitle={props.groupName}
            modalTitleIcon={''}
            shouldHideCancelButton={true}
            enableSubmit={true}
            submitButtonText={'Close'}
            size={ModalSizeType.medium}
            onSubmit={doNothingAsync}
            onModalConcluded={doNothing}>
            {/**
             * failedPolicy.remediationSteps is a string containing markdown.
             * ReactMarkdown converts it to HTML.
             * In the following:
             *    . rehypeRaw converts <a> tags to real links.
             *    . remarkGfm converts email addresses to real links.
             *    . removeNonMicrosoftLinks, as of this writing on 11/23/21,
             *      removes non-Microsoft href's. Please see description of
             *      the function for further details.
             */}
            {!!props.policyViolation.remediationSteps?.trim().length && (
                <Stack tokens={{ maxWidth: 600 }}>
                    <Spacer marginTop={10} />
                    <span>Remediation steps for {props.policyViolation.ruleName} violation:</span>
                    <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        remarkPlugins={[remarkGfm]}
                        components={{
                            em(props): JSX.Element {
                                const child = props.children[0];
                                return (
                                    <CoreEmployeeHoverCardFromGraph
                                        oid={child ? child?.toString() : ''}
                                    />
                                );
                            },
                        }}>
                        {props.policyViolation.remediationSteps}
                    </ReactMarkdown>
                </Stack>
            )}
        </ModalActionButton>
    );
}

const buttonStyle = mergeStyles(globalStyles.link, { height: 'auto' });
