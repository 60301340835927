import {
    ActionButton,
    Checkbox,
    DatePicker,
    IDetailsHeaderStyles,
    mergeStyleSets,
    MessageBarType,
    Separator,
    Stack,
} from '@fluentui/react';
import { BadgeColorHex, mediumGrey } from 'assets/constants/global-colors';
import { IconNames } from 'assets/constants/global-constants';
import {
    errorTypeColor,
    globalCheckboxStyles,
    globalFilterSeparatorStyles,
    globalStyles,
    successTypeColor,
    warningTypeColor,
} from 'assets/styles/global-styles';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import GroupClient, {
    GroupEvaluationScheduleStatus,
    GroupMemberViolationReport,
    IGroup,
    IGroupEvaluationSchedule,
    IGroupPolicyViolation,
    IGroupPolicyViolationResponse,
    IGroupPolicyViolationRule,
    IGroupRule,
} from 'clients/group-client';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { CoreSinglePrincipalIdPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { ProblemLoadingData } from 'components/common/problem-loading/problem-loading-data';
import { CheckScrollReachedBottom } from 'components/common/scroll-event-listener';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import Spacer from 'components/common/spacer';
import StatTile from 'components/common/stat-tiles/stat-tile';
import { Table } from 'components/common/table';
import useMessageBar from 'components/common/use-message-bar';
import { staticStatTileWrapperGroups } from 'components/groups/common/common-styles-groups';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import { displayWarningIfFewOwners } from 'components/groups/manage-group/manage-group-utils';
import CreateEditPolicyRuleModalActionButton from 'components/groups/manage-group/policy/buttons/create-policy-rule/create-edit-policy-rule-modal-action-button';
import PolicyRulesModalActionButton from 'components/groups/manage-group/policy/buttons/policy-rules-modal-action-button';
import managePoliciesTableColumns from 'components/groups/manage-group/policy/manage-policies-table-columns';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useFetchSimple, useTimeout, useToggle } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';
import { toTitleCase } from 'utils/string-utils';
import { TimeFormats, timeToString } from 'utils/time-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

export interface IManageGroupPolicyProps {
    group: IGroup;
    onGroupMemberDeleted: (principalId: string) => void;
}

export type FilteredListType = { [key: string]: number };

export default function ManageGroupPolicy(props: IManageGroupPolicyProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);
    const [isToggleFetch, toggleFetch] = useToggle(true);
    const [isToggleCheckStatus, toggleCheckStatus] = useToggle(true);
    const [isToggleFetchRules, toggleFetchRules] = useToggle(false);
    const [continuationToken, setContinuationToken] = useState<string>();
    const [errorFetchingPolicies, setErrorFetchingPolicies] = useState<string>('');
    const [fetchedPolicies, setFetchedPolicies] = useState<IGroupPolicyViolation[]>([]);
    const [filteredPolicies, setFilteredPolicies] = useState<IGroupPolicyViolation[]>([]);
    const [shouldShowStatus, setShouldShowStatus] = useState<boolean>(false);
    const [groupEvaluationSchedule, setGroupEvaluationSchedule] = useState<
        IGroupEvaluationSchedule
    >();
    const [isCheckingStatus, setIsCheckingStatus] = useState<boolean>(false);
    const [policyRules, setPolicyRules] = useState<IGroupRule[] | null>([]);
    const [basicGroupRules, setBasicGroupRules] = useState<IGroupRule[]>();
    const [filterList, setFilterList] = useState<string[]>([]);
    const [principals, setPrincipals] = useState<IPrincipalRecord[]>();
    const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
    const [toDate, setToDate] = useState<Date | undefined>(undefined);
    const [policyCheckMessageBarType, setPolicyCheckMessageBarType] = useState<number>(
        MessageBarType.info,
    );

    const isMin2FTEOwnersFeatureFlagEnabled = useFeatureFlag(FeatureFlagKeys.groupsMin2FteOwners)
        .enabled;

    const { isFetching: isFetchingRules } = useFetchSimple<IGroupRule[]>({
        dependencies: [isToggleFetchRules],
        canPerformFetch: true,
        fetchFunc: async () => GroupClient.getGroupRules(authContext, props.group.id),
        onSuccess: (result) => {
            setPolicyRules(result);
        },
        onError: () => {
            setPolicyRules(null);
        },
        onFinally: doNothing,
    });

    useFetchSimple<IGroupRule[]>({
        dependencies: [isToggleFetch],
        canPerformFetch: isToggleFetch,
        fetchFunc: async () => {
            toggleFetch(false);
            return GroupClient.getGroupRulesBasic(authContext);
        },
        onSuccess: (result) => {
            if (result) {
                setBasicGroupRules(result);
            }
        },
        onError: () => {
            throw 'Unable to retrieve group rule information.';
        },
        onFinally: doNothing,
    });

    const { isFetching: isFetchingPolicy } = useFetchSimple<IGroupPolicyViolationResponse>({
        dependencies: [isToggleFetch, continuationToken],
        canPerformFetch: isToggleFetch,
        fetchFunc: async () =>
            GroupClient.getPolicyViolations(authContext, props.group.id, continuationToken, true),
        onSuccess: (result) => {
            const failedPolicies = result.results.filter((r) => {
                return r.failed.length > 0;
            });

            if (!continuationToken) {
                setFetchedPolicies(failedPolicies);
            } else {
                setFetchedPolicies((currentValue) => [...currentValue].concat(failedPolicies));
            }
            setContinuationToken(result.continuationToken);
        },
        onError: () => {
            setErrorFetchingPolicies('Error loading policies');
        },
        onFinally: doNothing,
    });

    const { reset: startCheckScheduleTimer, clear: stopCheckScheduleTimer } = useTimeout(
        (): void => {
            toggleCheckStatus();
        },
        30000, // 30 seconds in between status checks
    );

    useEffect(() => {
        stopCheckScheduleTimer();
    }, []);

    useFetchSimple<IGroupEvaluationSchedule>({
        dependencies: [isToggleFetch, isToggleCheckStatus],
        canPerformFetch: isToggleFetch || isToggleCheckStatus,
        fetchFunc: async () => {
            setIsCheckingStatus(true);
            toggleCheckStatus(false);
            return GroupClient.getGroupEvaluationSchedule(authContext, props.group.id);
        },
        onSuccess: (result) => {
            setIsCheckingStatus(false);
            setGroupEvaluationSchedule(result);
            if (result.scheduleStatus === GroupEvaluationScheduleStatus.COMPLETED) {
                toggleFetch();
            } else {
                setShouldShowStatus(true);
                startCheckScheduleTimer();
            }
        },
        onError: () => {
            setIsCheckingStatus(false);
            setErrorFetchingPolicies('Error loading policies');
        },
        onFinally: doNothing,
    });

    const showPolicyCheckStatus = (): JSX.Element => {
        if (!groupEvaluationSchedule) {
            return <></>;
        }
        let backgroundColor;
        switch (groupEvaluationSchedule.scheduleStatus) {
            case GroupEvaluationScheduleStatus.SCHEDULED:
                backgroundColor = errorTypeColor;
                break;
            case GroupEvaluationScheduleStatus.RUNNING:
                backgroundColor = warningTypeColor;
                break;
            case GroupEvaluationScheduleStatus.COMPLETED:
                backgroundColor = successTypeColor;
                break;
        }
        return (
            <div className={styles.policyStatusContainer} style={{ background: backgroundColor }}>
                <div className={styles.policyCheckContent}></div>
                <div className={styles.policyCheckContent}>
                    {groupEvaluationSchedule.scheduleStatus}
                </div>
                <div className={styles.policyCheckContent}>
                    {isCheckingStatus && <span>Checking ...</span>}
                </div>
            </div>
        );
    };

    const onPrincipalSelect = (info?: IPrincipalRecord): void => {
        if (info) {
            setPrincipals([info]);
        } else {
            setPrincipals([]);
        }
    };

    const {
        theMessage: policyCheckMessage,
        theElement: policyCheckMessageBar,
        setMessage: setPolicyCheckMessage,
        clearMessage: clearPolicyCheckMessage,
    } = useMessageBar({ type: policyCheckMessageBarType });

    const onHandleSchedulePolicyCheck = async (): Promise<void> => {
        if (groupContext.group) {
            clearPolicyCheckMessage();
            try {
                const result = await GroupClient.scheduleGroupEvaluation(
                    authContext,
                    groupContext.group?.id,
                );
                setGroupEvaluationSchedule(result);
                setShouldShowStatus(true);
                startCheckScheduleTimer();
            } catch (e) {
                setPolicyCheckMessage(e as string);
                setPolicyCheckMessageBarType(MessageBarType.error);
            }
        }
    };

    useEffect(() => {
        if (groupEvaluationSchedule?.scheduleStatus === GroupEvaluationScheduleStatus.SCHEDULED) {
            setPolicyCheckMessage('Policy check scheduled.');
        } else {
            setPolicyCheckMessage('');
        }
    }, [groupEvaluationSchedule?.scheduleStatus]);

    function handleFailedFilterStatus({ id }: IGroupRule, e: any): void {
        if (e.target.checked) {
            setFilterList((currentValue) => [id, ...currentValue]);
        } else {
            const updatedList = filterList.filter((item) => item !== id);
            setFilterList(updatedList);
        }
    }

    function handleClearFilters(): void {
        setFilterList([]);
        setPrincipals([]);
        setFromDate(undefined);
        setToDate(undefined);
    }

    useEffect(() => {
        if (!!continuationToken && !isFetchingPolicy && !isFetchingRules) {
            toggleFetch();
        }
    }, [continuationToken, isFetchingPolicy, isFetchingRules]);

    useEffect(() => {
        let policies: IGroupPolicyViolation[] = fetchedPolicies;

        if (principals && principals[0]) {
            const emp = principals[0];
            if (emp.id) {
                policies = policies.filter((policy) => policy.personnelId === emp.id);
            }
        }

        if (fromDate) {
            const filteredTime = fromDate.getTime();
            policies = policies.filter((policy) => {
                return policy.nonComplianceTimestampUTC * 1000 > Number(filteredTime);
            });
        }

        if (toDate) {
            const filteredTime = toDate.getTime();
            policies = policies.filter((policy) => {
                return policy.nonComplianceTimestampUTC * 1000 < Number(filteredTime);
            });
        }

        if (filterList.length > 0) {
            policies = policies.filter((policy) => {
                return policy.failed.some((f) => filterList.includes(f.ruleId));
            });
        }

        setFilteredPolicies(policies);
    }, [filterList, principals, fromDate, toDate, fetchedPolicies]);

    const onGroupMemberDeleted = (principalId: string): void => {
        const updatedPolicies = fetchedPolicies.filter((p) => p.personnelId !== principalId);
        setFetchedPolicies(updatedPolicies);
        props.onGroupMemberDeleted(principalId);
    };

    const getReviewReport = async (): Promise<GroupMemberViolationReport[] | []> => {
        try {
            const report = await GroupClient.downloadGroupViolationsReport(
                authContext,
                props.group.id,
            );
            const csv = reportToCsv(report);
            return csv;
        } catch (e) {
            console.error('Error generating report ', e);
            return [];
        }
    };

    const reportToCsv = (rows: GroupMemberViolationReport[]): GroupMemberViolationReport[] => {
        const returnResult = rows.map((row) => ({
            personnelId: row.personnelId,
            alias: row.alias,
            firstName: row.firstName,
            middleName: row.middleName,
            lastName: row.lastName,
            fullName: row.fullName,
            ruleName: row.ruleName,
            ruleDescription: row.ruleDescription,
            evaluationState: row.evaluationState,
            errorMessage: row.errorMessage,
            creationTimestampPST: row.creationTimestampPST,
            nonComplianceTimestampPST: row.nonComplianceTimestampPST,
            daysNonCompliant: row.daysNonCompliant,
        }));
        return returnResult;
    };

    const tableColumns = useMemo(() => {
        return managePoliciesTableColumns({
            groupOwners: groupContext.groupOwners,
            enableOwnerDemote: groupContext.isEnableOwnerDemote,
            onGroupMemberDeleted,
            isDynamicGroup: groupContext.group?.enableDynamic ?? false,
        });
    }, [groupContext.groupOwners, fetchedPolicies, groupContext.isEnableOwnerDemote]);

    const updatePolicyRuleList = (list: IGroupRule[]): void => {
        setPolicyRules(list);
    };

    const handleUpdateAfterPolicyModalEdit = (updatedRule: IGroupRule): void => {
        setFetchedPolicies([]);
        if (policyRules !== null) {
            const policyRulesVar = [...policyRules];
            const index = policyRulesVar.findIndex((rule) => rule.id === updatedRule?.id);
            if (index > -1) {
                policyRulesVar.splice(index, 1, updatedRule as IGroupRule);
                setPolicyRules(policyRulesVar);
            }
            toggleFetch();
        }
    };

    const getNumberOfTotalFailedRules = (rule: IGroupRule): number => {
        const totalFoundPolicies: IGroupPolicyViolationRule[] = [];

        filteredPolicies.forEach((pol: IGroupPolicyViolation) => {
            pol.failed.forEach((element: IGroupPolicyViolationRule) => {
                if (element.ruleId === rule.id) {
                    totalFoundPolicies.push(element);
                }
            });
        });

        return totalFoundPolicies.length;
    };

    function onScrollReachedBottom(): void {
        if (!!continuationToken && !isFetchingPolicy && !isFetchingRules) {
            toggleFetch();
        }
    }

    return (
        <ProblemLoadingData isProblemLoadingData={!!errorFetchingPolicies}>
            <div className={staticStatTileWrapperGroups}>
                <StatTile
                    title={filteredPolicies.length.toString()}
                    text='Total'
                    background={BadgeColorHex.GRAY}
                    ariaLabel={`${props.group.metrics?.memberCount.toString()} Total Members`}
                />
            </div>
            <Stack horizontal>
                <Stack.Item>
                    <ActionButton
                        text={'Schedule Policy Check'}
                        onClick={onHandleSchedulePolicyCheck}
                        disabled={
                            groupContext.isAuditor() && !authContext.isInRole(Role.GroupAdmin)
                        }
                        iconProps={{ iconName: IconNames.AddToShoppingList }}></ActionButton>
                </Stack.Item>
                <Stack.Item>
                    {policyRules && (
                        <PolicyRulesModalActionButton
                            group={props.group}
                            policyRules={policyRules}
                            updateRulesList={(list: IGroupRule[]): void => {
                                updatePolicyRuleList(list);
                            }}
                            updateListAfterEdit={handleUpdateAfterPolicyModalEdit}
                            refetchRules={toggleFetchRules}
                            updatePolicyRulesTableAfterEdit={(): void => {
                                toggleFetchRules();
                                toggleFetch();
                            }}
                        />
                    )}
                </Stack.Item>
                <Stack.Item align='center'>
                    {!!policyCheckMessage && policyCheckMessageBar()}
                </Stack.Item>
            </Stack>
            <SidebarAndContents>
                <SidebarPane>
                    {groupEvaluationSchedule && (
                        <div className={styles.policyCheckcontainer}>
                            {timeToString(
                                groupEvaluationSchedule?.lastExecutedTimestampUTC * 1000,
                                TimeFormats.MMMDDYYYY_hmmA,
                            )}
                            <div className={styles.policyCheckcontainerLabel}>
                                LAST POLICY CHECK
                            </div>
                            {shouldShowStatus && showPolicyCheckStatus()}
                        </div>
                    )}
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        Employee
                    </Separator>
                    <CoreSinglePrincipalIdPickerTypeaheadSearch
                        ariaLabel='Employee'
                        placeHolder='Employee Name or Alias'
                        onChange={(item, itemAlt): void => {
                            onPrincipalSelect(itemAlt);
                        }}
                        selectedItem={principals ? principals[0]?.id : undefined}
                    />
                    <Spacer marginTop={6} />
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        From Date
                    </Separator>

                    <DatePicker
                        value={fromDate}
                        placeholder='Select a date'
                        onSelectDate={(date): void => {
                            if (date) setFromDate(date);
                        }}
                        ariaLabel='From Date'
                    />
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        To Date
                    </Separator>
                    <DatePicker
                        value={toDate}
                        placeholder='Select a date'
                        onSelectDate={(date): void => {
                            if (date) setToDate(date);
                        }}
                        ariaLabel='To Date'
                    />
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        Rules Failed
                    </Separator>
                    <div role='group' aria-label='Rules Failed Group'>
                        {policyRules &&
                            policyRules.map((rule, i) => (
                                <div key={`${rule.id}_${i}`}>
                                    {!rule.suspended && (
                                        <Stack horizontal>
                                            <Checkbox
                                                styles={globalCheckboxStyles}
                                                key={`${rule.name}_${i}`}
                                                label={toTitleCase(rule.name)}
                                                checked={filterList.includes(rule.id)}
                                                onChange={(e): void => {
                                                    handleFailedFilterStatus(rule, e);
                                                }}
                                            />
                                            <span className={styles.activeRuleCount}>
                                                ({getNumberOfTotalFailedRules(rule)})
                                            </span>
                                        </Stack>
                                    )}
                                </div>
                            ))}
                    </div>
                    <div className={styles.clearFilters}>
                        <ActionButton
                            iconProps={{ iconName: IconNames.ClearFilter }}
                            onClick={handleClearFilters}>
                            <span>Clear filters</span>
                        </ActionButton>
                    </div>
                </SidebarPane>
                <ContentPane>
                    {displayWarningIfFewOwners(
                        groupContext.groupOwners,
                        groupContext.ownerIsFTE,
                        groupContext.isGroupOwnersObtained,
                        isMin2FTEOwnersFeatureFlagEnabled,
                    )}
                    <HorizontalBar>
                        <Stack.Item className={horizontalBarTitleStyle} grow={100}>
                            <h1
                                className={`${globalStyles.boldFont} ${globalStyles.mediumLargeFont} ${globalStyles.removeTopBottomMargins}`}>
                                Policy
                            </h1>
                        </Stack.Item>
                        <Stack.Item>
                            {basicGroupRules && (
                                <CreateEditPolicyRuleModalActionButton
                                    isEditing={false}
                                    rules={basicGroupRules}
                                    updatePolicyRulesTableAfterEdit={toggleFetch}
                                />
                            )}
                        </Stack.Item>
                        <Stack.Item>
                            <ExportToExcelButton<GroupMemberViolationReport>
                                getData={getReviewReport}
                                fileNamePrefix={'group-violations-' + props.group.id}
                                buttonTitle='Download report'
                            />
                        </Stack.Item>
                    </HorizontalBar>
                    <IsLoadingIndicator
                        msg='Loading policy violations'
                        before={<Spacer marginTop={10} />}
                        after={<Spacer marginTop={10} />}
                        isLoading={isFetchingPolicy}
                    />
                    {!isFetchingPolicy && (
                        <Table
                            tableColumns={tableColumns}
                            rows={filteredPolicies ?? []}
                            isFetchingData={false}
                            detailsRowStyles={detailsRowStyles}
                            tableName='Policy'
                            noDataText={
                                isFetchingPolicy || fetchedPolicies.length > 0
                                    ? ''
                                    : 'All members are compliant or in grace period.'
                            }
                        />
                    )}
                    <IsLoadingIndicator
                        msg='Loading more records...'
                        before={<Spacer marginTop={10} />}
                        after={<Spacer marginTop={10} />}
                        isLoading={!!continuationToken && !!isFetchingPolicy}
                    />
                    <CheckScrollReachedBottom
                        shouldCheck={!!continuationToken}
                        onScrollReachedBottom={onScrollReachedBottom}
                    />
                </ContentPane>
            </SidebarAndContents>
        </ProblemLoadingData>
    );
}

const detailsRowStyles: Partial<IDetailsHeaderStyles> = {
    root: {
        selectors: {
            '.ms-DetailsRow-cell': {
                whiteSpace: 'normal',
                height: 'unset',
                lineHeight: 'normal',
            },
        },
    },
};

const styles = mergeStyleSets({
    policyCheckcontainer: {
        textAlign: 'center',
        paddingTop: '10px',
        marginBottom: '10px',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
    },
    policyCheckcontainerLabel: {
        backgroundColor: mediumGrey,
        width: '100%',
        marginTop: '4px',
        paddingTop: '4px',
        paddingBottom: '4px',
        fontSize: '10px',
    },
    policyStatusContainer: {
        display: 'flex',
        padding: 4,
        fontSize: 10,
        justifyContent: 'space-between',
    },
    policyCheckContent: {
        width: '33%',
    },
    clearFilters: {
        marginTop: '10px',
        textAlign: 'right',
    },
    activeRuleCount: {
        paddingLeft: 2,
    },
});
