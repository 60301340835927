import React, { ReactNode, createContext, useReducer } from 'react';
import { IPersonaProps, IDropdownOption } from '@fluentui/react';
import { Dictionary } from 'assets/constants/global-constants';
import { IEmployee } from 'clients/employee-client';

type FilterKeyType = keyof contextStateType | 'reset';

type FilterDataType =
    | IDropdownOption
    | Dictionary<boolean>
    | IPersonaProps
    | number
    | string
    | Date
    | null
    | undefined;

export interface IEmployeeFilterSetting extends IPersonaProps {
    itemPropObj: IEmployee;
}

type contextStateType = {
    execId: string | undefined;
    period: IDropdownOption | undefined;
    status: Dictionary<boolean> | undefined;
    employee: IEmployeeFilterSetting | undefined;
    reviewer: IEmployeeFilterSetting | undefined;
    orgLeader: IEmployeeFilterSetting | undefined;
    reportsTo: IEmployeeFilterSetting | undefined;
    reviewedOn: Date | undefined;
    selectedRate: IDropdownOption | undefined;
    rate: string | undefined;
    previousRate: string | undefined;
    selectedPreviousRate: IDropdownOption | undefined;
    resetAll: () => void;
    setFilter: (key: FilterKeyType, value: FilterDataType) => void;
    periodFilterSelection: (periodId: string) => IDropdownOption;
};

type actionType = {
    type: string;
    payload: FilterDataType;
};

export default function ScaReviewPeriodFiltersProvider(
    props: IStaffingServiceMappingFiltersProviderProps,
): JSX.Element {
    const reducer = (state: contextStateType, action: actionType): contextStateType => {
        let result;
        switch (action.type) {
            case 'execId':
            case 'period':
            case 'employee':
            case 'reviewer':
            case 'orgLeader':
            case 'reportsTo':
            case 'reviewedOn':
            case 'selectedRate':
            case 'rate':
            case 'previousRate':
            case 'selectedPreviousRate':
                result = {
                    ...state,
                    [action.type]: action.payload,
                };
                break;
            case 'status':
                result = {
                    ...state,
                    status: {
                        ...state.status,
                        ...(action.payload as Dictionary<boolean>),
                    },
                };
                break;
            case 'reset':
                result = {
                    ...filterInitValue(),
                    // No need to clear period or execId. It's better if
                    // they have a value all the time after initialization.
                    execId: state.execId,
                    period: state.period,
                };
                break;
            default:
                result = state;
                break;
        }
        return result;
    };

    const filterInitValue = (): contextStateType => {
        return {
            period: undefined,
            status: {},
            execId: undefined,
            employee: undefined,
            reviewer: undefined,
            orgLeader: undefined,
            reportsTo: undefined,
            reviewedOn: undefined,
            selectedRate: undefined,
            rate: undefined,
            previousRate: undefined,
            selectedPreviousRate: undefined,
            resetAll,
            setFilter,
            periodFilterSelection,
        };
    };

    const [contextValue, dispatch] = useReducer(reducer, filterInitValue());

    function setFilter(key: FilterKeyType, value: FilterDataType): void {
        dispatch({ type: key, payload: value });
    }

    function resetAll(): void {
        dispatch({ type: 'reset', payload: undefined });
    }

    function periodFilterSelection(periodId: string): IDropdownOption {
        return {
            key: periodId.toUpperCase(),
            text: periodId,
        };
    }

    return (
        <ScaReviewPeriodFilterContext.Provider value={contextValue}>
            {props.children}
        </ScaReviewPeriodFilterContext.Provider>
    );
}

export const ScaReviewPeriodFilterContext = createContext<contextStateType>(null!);

export interface IStaffingServiceMappingFiltersProviderProps {
    children: ReactNode | ReactNode[];
}
