import { IDropdownOption } from '@fluentui/react';
import {
    AdjudicatedDecision,
    DECLINED_SCREENING_STATES,
    ScreeningRequestTypes,
    StateName,
} from 'components/screening/common/common-constants';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { IBusinessData } from 'components/screening/us-gov/IScreening';
import { IContract } from 'components/screening/us-gov/IContract';
import {
    SuitabilityAgencies,
    SuitabilityLevels,
} from 'components/personnel-profile/suitability/profile-suitability-types';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/suitability/profile-suitability-utils';

export interface IPublicTrustResults {
    results: IPublicTrust[];
    continuationToken?: string;
}

export const PublicTrustAgenciesOptions: IDropdownOption[] = Object.values(SuitabilityAgencies).map(
    (agency, index) => {
        return {
            key: agency,
            index,
            text: getAgencyEnumValueFromKey(agency, true),
        };
    },
);
// the main public trust objectS
export interface IPublicTrust {
    adjudicatedDetails: {
        adjudicatedResult?: AdjudicatedDecision;
        adjudicatedUtcMillis: number;
        // isAdjudicatedGranted: boolean; // it is returned to us from the backend but we don't use it in the frontend
    };
    businessData: IBusinessData;
    businessJustification: string;
    contractId: string; // contract id column
    contract?: IContract; // we manually add this in the frontend
    indocDetails: {
        indocTrainingUtcMillis: number;
        indocBriefingUtcMillis: number;
    };
    nominated: {
        by: string; // personnel id of user
        atUtc: number;
    };
    previousPersonnelIds: string[];
    processOwnerId: string;
    requestType: ScreeningRequestTypes; // "Initial"
    isPIVRequired: boolean;
    publicTrustAgency: SuitabilityAgencies;
    suitabilityLevel: SuitabilityLevels;
    stateName: StateName;
    withdrawalDetails: {
        withdrawalReason: string;
        withdrawalNotes: string;
        withdrawalUtcMillis: number;
    };
    id: string;
    personnelId: string;
    dateCreated: {
        by: string;
        atUtc: number;
    };
    statusChanged: {
        by: string;
        atUtc: number;
    };
    lastModified: {
        by: string;
        atUtc: number;
    };
    // lastOperation: string; // it is returned to us from the backend but we don't use it in the frontend
    userType: string; // something we add in the frontend, not from the backend
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isIPublicTrust(object: any): object is IPublicTrust {
    if (!object) {
        return false;
    }
    return (object as IPublicTrust).contractId !== undefined;
}

export interface ICreatePublicTrustNominationRequest {
    businessJustification: string;
    contractId: string;
    personnelId: string;
    requestType: string;
    isPIVRequired: boolean;
    suitabilityLevel: string;
}

export enum UploadPublicTrustColumns {
    id = 'Record id',
    currentMicrosoftFTE = 'Current microsoft fte',
    personnelId = 'Personnel id',
    microsoftAlias = 'Microsoft alias',
    firstName = 'First name',
    lastName = 'Last name',
    governmentContractNumber = 'Government contract number',
    requestType = 'Request type',
    requestAgency = 'Request agency',
    pivRequired = 'PIV required',
    processOwner = 'Process owner',
    screeningStatus = 'Screening status',
    screeningSubstatus = 'Screening sub-status',
    suitabilityRecordStatus = 'Suitability record status',
    businessJustification = 'Business justification',
    suitabilityLevel = 'Suitability level',
    uploadStatus = 'Upload status',
}

// the old screening action
export enum AllowedUserActionOnScreening {
    Manage = 'Manage',
    ContractOwnerAccept = 'ContractOwnerAccept',
    ContractOwnerDeny = 'ContractOwnerDeny',
    NomineeApprove = 'NomineeApprove',
    NomineeReject = 'NomineeReject',
    ChangeState = 'ChangeState',
    AllowedToViewRecord = 'AllowedToViewRecord',
}

export enum PublicTrustRequestTypes {
    Initial = 'Initial',
    ContinuousEvaluationContinuousVetting = 'CE/CV',
    ContractChangeOrAdd = 'Contract change/add',
    PeriodicReinvestigation = 'Periodic Reinvestigation',
    Crossover = 'Crossover',
    Reinstatement = 'Reinstatement',
    RequestForAction = 'Request for Action (RFA)',
}

export const PUBLIC_TRUST_REQUEST_TYPE_OPTIONS: IDropdownOption[] = Object.entries(
    PublicTrustRequestTypes,
).map((entries, index) => {
    return {
        key: entries[0],
        text: entries[1],
        index,
    };
});

export function REQUEST_AGENCY_OPTIONS(): IDropdownOption[] {
    return Object.values(SuitabilityAgencies).map((value, index) => ({
        key: value,
        index,
        text: getAgencyEnumValueFromKey(value, true),
    }));
}

//check if i can combine with i screening
export function getEvaluatedCommonScreeningState(screening: ICommonScreening): string {
    if (!screening?.stateName) return '';

    if (screening.adjudicatedResult && screening.adjudicatedResult === AdjudicatedDecision.Denied) {
        return screening.adjudicatedResult;
    } else if (screening.stateName && DECLINED_SCREENING_STATES.includes(screening.stateName)) {
        return screening.stateName;
    }

    return '';
}
