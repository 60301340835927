import React from 'react';
import { IColumn } from '@fluentui/react';
import { Dictionary, maxWidthCoeff } from 'assets/constants/global-constants';
import { TableCell } from 'components/common/table';
import { IEmployeeReview, IReviewPeriod } from 'clients/sca-client';
import Badge from 'components/common/badge';
import {
    getEmployeeReviewStatusText,
    getEmployeeReviewStateBackground,
} from 'components/sca/sca-constants';
import { dateToLocalDate, getMonthShortName } from 'utils/time-utils';
import { ISortableColumns } from 'utils/sort-utils';
import ScaReviewedByTableCell from 'components/sca/sca-common/sca-reviewed-by-table-cell';

interface IHistory extends ISortableColumns {
    reviewPeriodsDict: Dictionary<IReviewPeriod>;
    hideReviewComments: boolean;
}

export function getScaEmployeeHistoryTableColumns(params: IHistory): IColumn[] {
    const columnWidths = {
        status: 90,
        period: 180,
        rate: 60,
        reviewedOn: 90,
        reviewedBy: 150,
        comment: 90,
    };

    const columns: IColumn[] = [
        {
            key: 'Period',
            name: 'Period',
            ariaLabel: 'Period',
            minWidth: columnWidths.period,
            maxWidth: columnWidths.period * maxWidthCoeff,
            isSorted: params.sortColumn === 'Period',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Period');
            },
            onRender: (row: IEmployeeReview): JSX.Element => {
                const startDateUTC = params.reviewPeriodsDict[row.reviewPeriodId]?.startDateUTC;
                const endDateUTC = params.reviewPeriodsDict[row.reviewPeriodId]?.endDateUTC;
                let dateRange: string;
                if (startDateUTC && endDateUTC) {
                    const startDate = startDateUTC ? new Date(startDateUTC * 1000) : undefined;
                    const endDate = endDateUTC ? new Date(endDateUTC * 1000) : undefined;
                    const startMonth = startDate
                        ? getMonthShortName(startDate.getMonth())
                        : undefined;
                    const startYear = startDate?.getFullYear();
                    const endMonth = endDate ? getMonthShortName(endDate.getMonth()) : undefined;
                    const endYear = endDate?.getFullYear();
                    dateRange = `(${startMonth} ${
                        startYear !== endYear ? startYear : ''
                    } - ${endMonth} ${endYear})`;
                } else {
                    dateRange = '';
                }
                return (
                    <TableCell>
                        {row.reviewPeriodId} {dateRange}
                    </TableCell>
                );
            },
        },
        {
            key: 'Status',
            name: 'Status',
            ariaLabel: 'Status',
            minWidth: columnWidths.status,
            maxWidth: columnWidths.status * maxWidthCoeff,
            isSorted: params.sortColumn === 'Status',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Status');
            },
            onRender: (row: IEmployeeReview): JSX.Element => {
                return (
                    <TableCell>
                        <Badge
                            text={getEmployeeReviewStatusText(row.reviewState)}
                            backgroundColor={getEmployeeReviewStateBackground(row.reviewState)}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: 'Rate',
            name: 'Rate',
            ariaLabel: 'Rate',
            minWidth: columnWidths.rate,
            maxWidth: columnWidths.rate * maxWidthCoeff,
            isSorted: params.sortColumn === 'Rate',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Rate');
            },
            onRender: (row: IEmployeeReview): JSX.Element => {
                return <TableCell>{Number(row.rate).toFixed(2)}%</TableCell>;
            },
        },
        {
            key: 'Reviewed By',
            name: 'Reviewed By',
            ariaLabel: 'Reviewed By',
            minWidth: columnWidths.reviewedBy,
            maxWidth: columnWidths.reviewedBy * maxWidthCoeff,
            isSorted: params.sortColumn === 'Reviewed By',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Reviewed By');
            },
            onRender: (row: IEmployeeReview): JSX.Element => {
                return <ScaReviewedByTableCell reviewedBy={row.reviewedBy} />;
            },
        },
        {
            key: 'Reviewed On',
            name: 'Reviewed On',
            ariaLabel: 'Reviewed On',
            minWidth: columnWidths.reviewedOn,
            maxWidth: columnWidths.reviewedOn * maxWidthCoeff,
            isSorted: params.sortColumn === 'Reviewed On',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Reviewed On');
            },
            onRender: (row: IEmployeeReview): JSX.Element => {
                return <TableCell>{dateToLocalDate(1000 * row.reviewedTimestampUTC)}</TableCell>;
            },
        },
    ];

    if (!params.hideReviewComments) {
        columns.push({
            key: 'Comment',
            name: 'Comment',
            ariaLabel: 'Comment',
            minWidth: columnWidths.comment,
            maxWidth: columnWidths.comment,
            onRender: (row: IEmployeeReview): JSX.Element => {
                return <TableCell>{row.reviewComment}</TableCell>;
            },
        });
    }
    return columns;
}
