import { DefaultButton, mergeStyles, PrimaryButton, Separator } from '@fluentui/react';
import React, {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    ChoiceOptions,
    FormElement,
    next,
    FormResponse,
    FormValueTypes,
    endOfForm,
    ListNode,
    getDateRangeValidatingSituation,
    getDateValidatingSituation,
} from 'components/forms/forms-common';
import { globalSeparatorStyles } from 'assets/styles/global-styles';
import ElementViewer from 'components/forms/element-viewer';
import { useHistory } from 'react-router-dom';
import { useDebounce, useUpOnePathLevel } from 'utils/misc-hooks';
import FormsClient, { FormState } from 'clients/forms-client';
import { AuthContext } from 'contexts/auth-context';
import { secondsToMilliseconds } from 'utils/time-utils';
import FormsStepper from 'components/forms/viewer/forms-stepper';
import HeaderViewer from 'components/forms/element-viewer/header-viewer';
import { FormsReviewer } from 'components/forms/forms-reviewer';
import LabelViewer from 'components/forms/element-viewer/label-viewer';
import { SaveButton } from 'components/common/buttons/save-button';
import FormsSubmission from 'components/forms/viewer/forms-submission';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

const pageStyles = mergeStyles({
    minHeight: '100vh',
    background: 'white',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr 2fr auto',
    gridTemplateRows: 'auto 1fr',
    gridColumnGap: '3.75rem',
    gridTemplateAreas: `
      'title title title title'
      '. stepper elements .'
      `,
    '@media(max-width: 900px)': {
        gridTemplateColumns: 'auto',
        gridTemplateRows: 'auto auto 1fr',
        gridTemplateAreas: `
            'title'
            'stepper'
            'elements'
            `,
    },
});

export const elementContainerStyles = mergeStyles({
    padding: '0 3rem 3rem 3rem',
    background: 'white',
    width: '75%',
    '@media(max-width: 1400px)': {
        width: '75%',
    },
    maxWidth: '75vw',
});

const elementContainerWithGridStyles = mergeStyles({
    padding: '0 3rem 3rem 3rem',
    background: 'white',
    gridArea: 'elements',
    overflow: 'hidden',
});

const backButtonStyles = mergeStyles({
    width: 'fit-content',
    margin: '2rem 0 0 2rem',
    position: 'fixed',
});

const titleStyles = mergeStyles({
    fontWeight: '400',
    marginTop: '0',
});

type FormsViewerProps = {
    formResponse: FormResponse;
    sectionList: ListNode[];
    currentSectionIndex: number;
    setCurrentSectionIndex: Dispatch<SetStateAction<number>>;
    setFormResponse: Dispatch<SetStateAction<FormResponse>>;
    setSectionList: Dispatch<SetStateAction<ListNode[]>>;
    setMessageBarText: Dispatch<SetStateAction<string>>;
    isPreviewPage: boolean;
    formState: string;
};

// https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
export const emailRegexPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const phoneRegexPattern = /^[\d\s\-\(\)]+$/;
export const zipcodeRegexPattern = /^[\d\s\-]+/;

export function FormsViewer(props: FormsViewerProps): JSX.Element {
    const {
        formResponse,
        currentSectionIndex,
        sectionList,
        isPreviewPage,
        setSectionList,
        setFormResponse,
        setCurrentSectionIndex,
        setMessageBarText,
        formState,
    } = props;

    const authContext = useContext(AuthContext);
    const upOnePathLevel = useUpOnePathLevel();
    const history = useHistory();
    const debounceTimeInMS = 1200;

    const userName = authContext.getUserProfile()?.name ?? '';
    const [errors, setErrors] = useState<Set<number>>(new Set());
    const currentSection = sectionList[currentSectionIndex];
    const [isSubmitting, setSubmitting] = useState(false);
    const [showSubmission, setShowSubmission] = useState(false);
    const [validProgressCount, setValidProgressCount] = useState<number | undefined>();
    const [totalProgressCount, setTotalProgressCount] = useState<number | undefined>();
    const [lastSavedText, setLastSavedText] = useState<string>('Last saved on ...');
    const sectionLabelRef = useRef<HTMLInputElement>(null);
    const autosaveFormRecord = async (): Promise<void> => {
        if (!isPreviewPage) {
            updateFormRecord(false);
        }
    };
    const hasEditFeatureFlagEnabled = useFeatureFlag(FeatureFlagKeys.formsEdit).enabled;

    useDebounce(autosaveFormRecord, debounceTimeInMS, [formResponse]);

    useEffect(() => {
        if (currentSectionIndex !== sectionList.length - 1) {
            const reachableSections = new Set<number>();
            let tempSection: ListNode | undefined = { ...currentSection };
            while (tempSection) {
                reachableSections.add(tempSection.value);
                tempSection = tempSection.prev;
            }
            tempSection = currentSection;
            while (tempSection) {
                reachableSections.add(tempSection.value);
                tempSection = tempSection.next;
            }

            setFormResponse((prev) => {
                const newResponse = { ...prev };
                newResponse.sections.forEach((section) => {
                    section.visible = reachableSections.has(section.id);
                });
                return newResponse;
            });
        }
    }, [sectionList]);

    const updateFormResponse = (element: FormElement, answer?: FormValueTypes): void => {
        const currElementIndex = formResponse.sections[currentSection.value].elements.findIndex(
            (el) => el.id === element.id,
        );
        if (currElementIndex < 0) {
            return;
        }

        setFormResponse((prev) => {
            const newResponse = { ...prev };
            const newElement: FormElement = {
                ...newResponse.sections[currentSection.value].elements[currElementIndex],
                value: answer,
            };
            newResponse.sections[currentSection.value].elements[currElementIndex] = newElement;
            return newResponse;
        });

        if (element.branches) {
            const options = element.options as ChoiceOptions[];
            const prevBranchTo = options.find((option) => option.text === element.value)
                ?.branchTarget;
            let branchTo = options.find((option) => option.text === answer)?.branchTarget ?? next;
            if (branchTo === prevBranchTo) {
                return;
            }
            const currElements = formResponse.sections[currentSection.value].elements;

            if (branchTo === endOfForm) {
                hideRemainingElementInSection(currElementIndex);
                goToEnd();
                return;
            }

            if (branchToIsInvalid(branchTo, currElements, currElementIndex, currentSectionIndex)) {
                branchTo = next;
            }

            if (branchTo === next) {
                if (currElementIndex + 1 !== currElements.length) {
                    // set branch to next element in section
                    branchTo = currElements[currElementIndex + 1].id;
                } else {
                    // No next element, reset current section to its default next.
                    resetCurrentSectionNextToDefault();
                    return;
                }
            }

            if (!isNaN(branchTo as any)) {
                // branch points to a section.
                setSectionList((prev) => {
                    const newSections = [...prev];
                    const currNode = newSections[currentSectionIndex];
                    const nextIndex = Number(branchTo);
                    const nextNode = newSections[nextIndex];
                    currNode.next = nextNode;
                    newSections[currentSectionIndex] = currNode;
                    nextNode.prev = currNode;
                    newSections[nextIndex] = nextNode;
                    return newSections;
                });
                hideRemainingElementInSection(currElementIndex);
            } else {
                // branch points to a question.

                resetCurrentSectionNextToDefault();
                // hide elements until branch target
                const newElements = [...currElements];
                let index = currElementIndex + 1;
                while (index < newElements.length) {
                    if (newElements[index].id === branchTo) {
                        newElements[index].visible = true;
                        break;
                    }
                    newElements[index].visible = false;
                    index += 1;
                }
                // show elements until next branch
                while (index < newElements.length) {
                    newElements[index].visible = true;
                    if (newElements[index].branches) {
                        index += 1;
                        break;
                    }
                    index += 1;
                }

                // Fix issue where selecting previous questions causes an invalid form to be valid
                if (newElements[index - 1].branches) {
                    newElements[index - 1].value = undefined;
                }

                // hide all elements after the next branch
                while (index < newElements.length) {
                    newElements[index].visible = false;
                    index += 1;
                }

                setFormResponse((prev) => {
                    const newResponse = { ...prev };
                    newResponse.sections[currentSection.value].elements = newElements;
                    return newResponse;
                });
            }
        }
    };

    const branchToIsInvalid = (
        branchTo: string,
        currElements: FormElement[],
        elementIndex: number,
        sectionIndex: number,
    ): boolean => {
        if (!isNaN(branchTo as any)) {
            return Number(branchTo) <= sectionIndex;
        }
        const validElement = currElements.find(
            (el, index) => el.id === branchTo && index > elementIndex,
        );
        return validElement === undefined;
    };

    const hideRemainingElementInSection = (startIndex: number): void => {
        // hide all elements in current section after the element at startIndex
        setFormResponse((prev) => {
            const updatedResponse = { ...prev };
            updatedResponse.sections[currentSection.value].elements.forEach((element, index) => {
                if (index > startIndex) {
                    element.visible = false;
                    element.value = undefined;
                }
            });
            return updatedResponse;
        });
    };

    const resetCurrentSectionNextToDefault = (): void => {
        let nextSection = formResponse.sections[currentSection.value].branchTarget ?? next;
        if (nextSection === endOfForm) {
            goToEnd();
            return;
        }
        if (nextSection === next) {
            if (currentSection.value === sectionList.length - 1) {
                goToEnd();
                return;
            }
            nextSection = (currentSection.value + 1).toString();
        }
        const nextSectionIndex = Number(nextSection);
        const newSections = [...sectionList];
        const currNode = newSections[currentSectionIndex];
        const nextNode = newSections[nextSectionIndex];
        currNode.next = nextNode;
        newSections[currentSectionIndex] = currNode;
        nextNode.prev = currNode;
        newSections[nextSectionIndex] = nextNode;
        setSectionList(newSections);
    };

    const goToEnd = (): void => {
        setSectionList((prev) => {
            const curr = [...prev];
            const currNode = curr[currentSectionIndex];
            currNode.next = undefined;
            curr[currentSectionIndex] = currNode;
            return curr;
        });
    };

    const navigateToSection = (sectionIndex: number): void => {
        if (
            sectionIndex < 0 ||
            sectionIndex >= sectionList.length ||
            sectionIndex === currentSectionIndex
        ) {
            return;
        }
        if (sectionIndex === sectionList.length - 1) {
            validateAllVisibleSections();
        } else {
            updateSectionErrors(currentSectionIndex);
        }
        const newSectionList = [...sectionList];
        const visibleSections = newSectionList.filter(
            (section) => formResponse.sections[section.value].visible,
        );

        visibleSections.forEach((section, index) => {
            section.next = visibleSections[index + 1];
            section.prev = visibleSections[index - 1];
        });
        setSectionList(newSectionList);
        setCurrentSectionIndex(sectionIndex);
    };

    const SectionDescription = useMemo(() => {
        const label =
            currentSectionIndex === 0
                ? 'Start here'
                : formResponse.sections[currentSection.value].label;
        return (
            <div style={{ scrollMarginTop: '15rem' }} ref={sectionLabelRef}>
                <h2 className={titleStyles}>{label}</h2>
                <p>{formResponse.sections[currentSection.value].description}</p>
            </div>
        );
    }, [currentSection, currentSectionIndex]);

    const updateFormRecord = async (isSubmit: boolean): Promise<void> => {
        if (isPreviewPage) {
            return;
        }
        if (isSubmit) {
            setSubmitting(true);
        }
        const visibleForm: FormResponse = JSON.parse(JSON.stringify(formResponse));
        // remove review section
        visibleForm.sections = visibleForm.sections.slice(0, -1);

        let totalProgressCount = 0;
        let validProgressCount = 0;
        visibleForm.sections.forEach((section, index) => {
            if (!section.visible) {
                section.elements.forEach((element) => {
                    element.value = undefined;
                });
            } else {
                section.elements.forEach((element) => {
                    if (!element.visible) {
                        element.value = undefined;
                    } else if (element.visible && element.type !== 'info') {
                        // Get Progress section.
                        totalProgressCount++;
                        // non-required checks
                        if (!element.required) {
                            if (!isFieldEmpty(element)) {
                                validProgressCount++;
                            } else if (index < currentSectionIndex) {
                                // if we are past that section then count non-required fields as pasted
                                validProgressCount++;
                            }
                        } else if (!validationConditions(element)) {
                            validProgressCount++;
                        }
                    }
                });
            }
        });
        setValidProgressCount(validProgressCount);
        setTotalProgressCount(totalProgressCount);
        const prevSaveText = lastSavedText;
        try {
            setLastSavedText('Saving...');

            const data = {
                id: formResponse.id,
                label: formResponse.label,
                schema: JSON.stringify(visibleForm.sections),
                formState: isSubmit
                    ? FormState.submitted
                    : hasEditFeatureFlagEnabled
                    ? formState
                    : FormState.draft,
            };

            const response = await FormsClient.UpdateGenericFormRecord(authContext, data);
            const lastModified = new Date(
                secondsToMilliseconds(response.lastModifiedAtUTC) ?? 0,
            ).toLocaleString();
            setLastSavedText(`Last saved on ${lastModified}`);
            if (isSubmit) {
                setShowSubmission(true);
            }
        } catch (error) {
            setMessageBarText('An error occurred saving the form');
            setLastSavedText(prevSaveText);
            console.error('error updating generic form record');
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    const validationConditions = (elem: FormElement): boolean => {
        return (
            elem.visible === true &&
            (isRequiredAndEmpty(elem) ||
                !matchesSpecifiedRegex(elem) ||
                !matchesSignature(elem) ||
                !isDateRangeValid(elem) ||
                !isDateValid(elem))
        );
    };

    const isRequiredAndEmpty = (elem: FormElement): boolean => {
        return elem.required && isFieldEmpty(elem);
    };

    const isFieldEmpty = (elem: FormElement): boolean => {
        if (elem.type === 'table') {
            return !elem.value?.length;
        }
        return elem.value === undefined || elem.value === '';
    };

    const isDateRangeValid = (elem: FormElement): boolean => {
        if (elem.type === 'daterange') {
            const situation = getDateRangeValidatingSituation(elem);
            if (situation !== '') {
                return false;
            }
        }
        return true;
    };

    const isDateValid = (elem: FormElement): boolean => {
        if (elem.type === 'date') {
            const situation = getDateValidatingSituation(elem);
            if (situation !== '') {
                return false;
            }
        }
        return true;
    };

    const matchesSpecifiedRegex = (elem: FormElement): boolean => {
        if (elem.validatorOptions?.type !== 'regex') {
            return true;
        }

        const valueString = elem.value as string;
        switch (elem.validatorOptions?.value) {
            case 'phone':
                // require a country code and a phone number we separate the country code via '.'
                // ie +1 2015197208 => 1.2015197208
                if (valueString === undefined || valueString === '') {
                    return false;
                }
                const phoneNumber = valueString.split('.');
                if (phoneNumber.length === 2) {
                    return phoneRegexPattern.test(phoneNumber[1]);
                }
                return false;
            case 'email':
                return emailRegexPattern.test(valueString);
            case 'zipcode':
                return zipcodeRegexPattern.test(valueString);
            default:
                return false;
        }
    };

    const matchesSignature = (elem: FormElement): boolean => {
        if (elem.type !== 'signature') {
            return true;
        }

        const valueString = elem.value as string;
        if (valueString !== userName) {
            return false;
        }
        return true;
    };

    const hasSectionErrors = (sectionNumber: number): boolean =>
        formResponse.sections[sectionNumber].elements.some(validationConditions);

    const updateSectionErrors = (sectionNumber: number): void => {
        const hasErrors = hasSectionErrors(sectionNumber);
        let newSet = new Set(errors);
        if (hasErrors) {
            newSet = new Set([...errors, sectionNumber]);
        } else {
            newSet.delete(sectionNumber);
        }
        setErrors(newSet);
    };

    const validateAllVisibleSections = (): boolean => {
        const visibleSections = formResponse.sections.filter((p) => p.visible).map((e) => e.id);
        const newSet: Set<number> = new Set();
        for (const section of visibleSections) {
            if (hasSectionErrors(section)) {
                newSet.add(section);
            }
        }
        setErrors(newSet);
        return newSet.size <= 0;
    };

    const submitClicked = (): void => {
        const isDisabled = errors.size > 0;
        if (!isDisabled) {
            updateFormRecord(true);
        } else {
            console.error('Validation error on submit clicked');
        }
    };

    const reviewClicked = (): void => {
        const nextIndex = formResponse.sections.length - 1;
        validateAllVisibleSections();
        setSectionList((prev) => {
            const newList = [...prev];
            const nextSectionNode = newList[nextIndex];
            nextSectionNode.prev = newList[currentSectionIndex];
            newList[nextIndex] = nextSectionNode;
            return newList;
        });
        setCurrentSectionIndex(nextIndex);
        sectionLabelRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const getNextButton = () => {
        const nextButtonStyle = {
            width: 'fit-content',
            margin: '1rem 2rem 2rem 0',
        };

        if (currentSection.next && currentSection.next.value !== formResponse.sections.length - 1) {
            return (
                <PrimaryButton
                    style={nextButtonStyle}
                    onClick={(): void => {
                        updateSectionErrors(currentSection.value);
                        const nextIndex = currentSection.next?.value ?? currentSection.value;
                        setSectionList((prev) => {
                            const newList = [...prev];
                            const nextSectionNode = newList[nextIndex];
                            nextSectionNode.prev = newList[currentSectionIndex];
                            newList[nextIndex] = nextSectionNode;
                            return newList;
                        });
                        setCurrentSectionIndex(nextIndex);
                        sectionLabelRef?.current?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                        });
                    }}>
                    Next
                </PrimaryButton>
            );
        }
        if (currentSectionIndex === formResponse.sections.length - 1) {
            const isDisabled = errors.size > 0;
            return (
                <SaveButton
                    style={nextButtonStyle}
                    onClick={submitClicked}
                    saveText={'Submitting'}
                    defaultText={'Submit'}
                    isSaving={isSubmitting}
                    isDisabled={isDisabled}
                />
            );
        }
        return (
            <PrimaryButton style={nextButtonStyle} onClick={reviewClicked} disabled={isPreviewPage}>
                Review
            </PrimaryButton>
        );
    };

    const getActionButtons = (): JSX.Element => {
        const backButton = (
            <DefaultButton
                style={{ width: 'fit-content', margin: '1rem 0 2rem' }}
                onClick={(): void => {
                    updateSectionErrors(currentSection.value);
                    const prevIndex = currentSection.prev?.value ?? currentSection.value;
                    setCurrentSectionIndex(prevIndex);
                    sectionLabelRef?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }}>
                Previous
            </DefaultButton>
        );
        const nextButton = getNextButton();
        return (
            <div style={{ marginTop: '1.5rem' }}>
                <Separator styles={globalSeparatorStyles} />
                {nextButton}
                {currentSection.prev && backButton}
            </div>
        );
    };

    const requiredInfo = useMemo(() => {
        if (formResponse.sections[currentSection.value].elements.some((e) => e.required)) {
            return (
                <div style={{ color: '#e50000', margin: '1.5rem 1.5rem 0px 0px' }}>
                    (required) Indicates this is a required field
                </div>
            );
        } else {
            return <></>;
        }
    }, [currentSection.value, formResponse.sections]);

    if (showSubmission) {
        return (
            <div className={pageStyles}>
                <HeaderViewer
                    title={formResponse.title}
                    description={formResponse.description}
                    saveText={lastSavedText}
                />
                <FormsStepper
                    sections={formResponse.sections}
                    navigateToSection={navigateToSection}
                    currentSectionIndex={currentSectionIndex}
                    errors={errors}
                    isSubmission={showSubmission}
                />
                <FormsSubmission formResponse={formResponse} />
            </div>
        );
    }
    let displayIndex = 0;
    return (
        <div className={pageStyles}>
            {isPreviewPage && (
                <DefaultButton
                    aria-label='Back to edit page'
                    className={backButtonStyles}
                    onClick={(): void => history.push(upOnePathLevel)}>
                    Back
                </DefaultButton>
            )}
            <HeaderViewer
                title={formResponse.title}
                description={formResponse.description}
                saveText={lastSavedText}
            />
            <FormsStepper
                sections={formResponse.sections}
                navigateToSection={navigateToSection}
                currentSectionIndex={currentSectionIndex}
                errors={errors}
                isSubmission={showSubmission}
                validProgressCount={validProgressCount}
                totalProgressCount={totalProgressCount}
            />
            <div className={elementContainerWithGridStyles}>
                {SectionDescription}
                {currentSectionIndex === 0 && (
                    <LabelViewer label={formResponse.label} setFormResponse={setFormResponse} />
                )}
                {requiredInfo}
                {currentSectionIndex === formResponse.sections.length - 1 ? (
                    <FormsReviewer formResponse={formResponse} isReviewPage={true} />
                ) : (
                    formResponse.sections[currentSection.value].elements
                        .filter((el) => el.visible)
                        .map((element) => (
                            <div key={element.id} style={{ margin: '1.5rem 0 0 0' }}>
                                <ElementViewer
                                    element={element}
                                    displayIndex={element.type === 'info' ? -1 : ++displayIndex}
                                    updateFormResponse={updateFormResponse}
                                    isSectionVisited={errors.has(currentSection.value)}
                                />
                            </div>
                        ))
                )}
                {getActionButtons()}
            </div>
        </div>
    );
}
