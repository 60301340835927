import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Location } from 'history';
import { CustomBreadcrumb, ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import {
    ActionButton,
    ChoiceGroup,
    getTheme,
    Icon,
    IPersonaProps,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Modal,
} from '@fluentui/react';
import {
    searchEmployeeStatusChoiceGroupOptions,
    IEmployee,
    SearchEmployeeStatus,
} from 'clients/employee-client';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { UserContext } from 'contexts/user-context';
import { AccessDeniedPage } from 'components/common/access-denied';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import CreateNewProfileStepper from 'components/personnel-profile/create-new-profile/create-new-profile-stepper';
import config from 'environments/environment';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import { useHistory } from 'react-router-dom';

export interface PersonnelProfileSearchPageProps {
    pageName: string;
    location: Location;
    userType?: string;
}

const defaultBreadCrumbItems: ICustomBreadcrumbItem[] = [
    {
        title: 'Profile',
        link: '',
    },
];

export default function PersonnelProfileSearchPage(
    props: PersonnelProfileSearchPageProps,
): JSX.Element {
    const userContext = useContext(UserContext);
    const breadCrumbContext = useContext(BreadCrumbContext);
    const [selectedPersona, setSelectedPersona] = useState<IEmployee | undefined>();
    const [isShowCreateNewProfileProcess, setShowCreateNewProfileProcess] = useState<boolean>(
        false,
    );
    const [hasErrorOccurred, setErrorOccurred] = useState<boolean>(false);
    const [errorMessage, setMessage] = useState<string>('');
    const [employeeSearchType, setEmployeeSearchType] = useState<SearchEmployeeStatus>(
        SearchEmployeeStatus.either,
    );
    const history = useHistory();

    const searchParameter = useCallback(
        (persona: IEmployee | undefined): string => {
            if (selectedPersona?.alias) {
                return `${persona?.alias}`;
            }
            return `${persona?.id}`;
        },
        [selectedPersona?.alias],
    );

    useEffect(() => {
        if (selectedPersona) {
            history.push(`/profile/us-gov/${searchParameter(selectedPersona)}`);
        }
    }, [history, searchParameter, selectedPersona]);

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([
            ...defaultBreadCrumbItems,
            {
                title: 'US Gov',
                link: props.location.pathname,
            },
        ]);
    }, []);

    function onSelectedCandidateChange(persona?: IPersonaProps): void {
        if (persona?.itemProp) {
            setSelectedPersona(JSON.parse(persona.itemProp) as IEmployee);
        } else {
            setSelectedPersona(undefined);
        }
    }

    function closeMessage(): void {
        setErrorOccurred(false);
        setMessage('');
    }

    function changeValue(value: string | number | undefined): void {
        if (value) {
            setEmployeeSearchType(SearchEmployeeStatus[value as keyof typeof SearchEmployeeStatus]);
        }
    }

    return (
        <PageLoadingSpinner
            isLoading={!userContext.isUsGovScreeningUserTypesLoaded}
            label='loading...'
            ariaLive='assertive'
            labelPosition='right'>
            {userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST) ? (
                <>
                    <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                    {config.personnelProfile.prehireFlag && (
                        <>
                            <div className={styles.nominationButton}>
                                <ActionButton
                                    onClick={(): void => setShowCreateNewProfileProcess(true)}
                                    iconProps={{ iconName: 'AddFriend' }}
                                    allowDisabledFocus>
                                    Create profile
                                </ActionButton>
                                <Modal
                                    isOpen={isShowCreateNewProfileProcess}
                                    onDismiss={(): void => setShowCreateNewProfileProcess(false)}
                                    isBlocking={true}
                                    isDarkOverlay={false}
                                    containerClassName={styles.modalContainer}>
                                    <CreateNewProfileStepper
                                        hideCreateNewProfileProcess={(): void => {
                                            setShowCreateNewProfileProcess(false);
                                        }}
                                        onCreateNewProfileError={(msg: string): void => {
                                            setErrorOccurred(true);
                                            setMessage(msg);
                                        }}
                                    />
                                </Modal>
                            </div>
                            <div>
                                {hasErrorOccurred && (
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={false}
                                        dismissButtonAriaLabel='Close'
                                        onDismiss={closeMessage}
                                        styles={{ root: { marginBottom: 15 } }}>
                                        {errorMessage}
                                    </MessageBar>
                                )}
                            </div>
                        </>
                    )}
                    <div className={styles.choiceAndSearchContainer}>
                        {config.personnelProfile.prehireFlag && (
                            <div className={styles.choiceContainer}>
                                <ChoiceGroup
                                    defaultSelectedKey={employeeSearchType}
                                    options={searchEmployeeStatusChoiceGroupOptions(
                                        employeeSearchType,
                                    )}
                                    styles={choiceGroupStyle}
                                    onChange={(ev, option): void => {
                                        changeValue(option?.key);
                                    }}
                                />
                            </div>
                        )}
                        <div className={styles.searchContainer}>
                            <span>Find users</span>
                            <EmployeePickerTypeaheadSearch
                                ariaLabel='Find users'
                                onCandidateSelected={onSelectedCandidateChange}
                                selectedPersona={selectedPersona}
                                placeHolder={
                                    employeeSearchType !== SearchEmployeeStatus.either
                                        ? 'by name, PCN, personal email, and SSN'
                                        : 'by name, alias, personnel ID, and SSN'
                                }
                                searchEmployeeStatus={employeeSearchType}
                                styles={employeePickerStyles}
                            />
                            <Icon iconName='Search' />
                        </div>
                    </div>
                </>
            ) : (
                AccessDeniedPage()
            )}
        </PageLoadingSpinner>
    );
}
const choiceGroupStyle = mergeStyleSets({
    flexContainer: {
        display: 'flex',
        gap: '4rem',
        padding: '1rem',
    },
});

const employeePickerStyles = {
    inputStyle: {
        border: '0',
    },
    textBoxStyle: {
        marginLeft: '10px',
        marginBottom: '2px',
        border: '0',
        '::after': {
            border: '0',
        },
    },
};

const styles = mergeStyleSets({
    choiceAndSearchContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 1fr',
        gridTemplateRows: '1fr 1fr',
    },
    choiceContainer: {
        gridColumnStart: '2',
    },
    searchContainer: {
        display: 'flex',
        gridColumnStart: '2',
        position: 'relative',
        boxShadow: getTheme().effects['elevation8'],
        transition: 'box-shadow 300ms ease 0s',
        borderRadius: '2px',
        backgroundColor: 'white',
        flexWrap: 'nowrap',
        alignItems: 'center',
        padding: '1rem',
    },
    nominationButton: {
        padding: `5px 0 5px 0`,
    },
    modalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
});
