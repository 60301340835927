import {
    ActionButton,
    Checkbox,
    Dropdown,
    IComboBoxOption,
    IDropdownOption,
    IDropdownStyleProps,
    IDropdownStyles,
    IStyleFunctionOrObject,
    Label,
    mergeStyles,
    mergeStyleSets,
    Separator,
    Stack,
    TextField,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import { globalSeparatorStyles, globalStyles } from 'assets/styles/global-styles';
import { AuthContext } from 'contexts/auth-context';
import FormsClient, { FormState, GenericFormRecord } from 'clients/forms-client';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import React, { useContext, useState } from 'react';
import { returnEmailUserName } from 'utils/string-utils';
import {
    getStateColor,
    genericFormRecordFormStates,
    descriptionStyles,
} from 'components/forms/forms-common';
import { FormReviewStatusIndicator } from 'components/forms/forms-review-status-indicator';
import { useHistory } from 'react-router-dom';
import { dateToLocalShortDateFormat, secondsToMilliseconds } from 'utils/time-utils';
import { SaveButton } from 'components/common/buttons/save-button';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

const timestampStyle = mergeStyles({
    flexGrow: 1,
    selectors: {
        ':before': {
            content: "'|'",
            marginRight: '1rem',
        },
    },
});

const halfWidthStyle = mergeStyles({
    width: '50%',
});

const flexStyle = mergeStyles({
    display: 'flex',
    gap: '3rem',
});

const infoContainerStyle = mergeStyles(halfWidthStyle, { paddingTop: '3rem' });
const commentsContainerStyle = mergeStyles(halfWidthStyle, { paddingBottom: '3rem' });
const hoverCardMinWidth = mergeStyles({
    minWidth: '14rem',
});
const statusDropdownStyle = mergeStyles(flexStyle, { marginTop: '2rem' });

const flexBasisStyle = mergeStyles({
    flexBasis: '25%',
    maxWidth: '18.7rem',
    minWidth: '6rem',
});

const checkboxPadding = mergeStyles({
    padding: '2rem 0 1rem 0.5rem',
});

const saveButtonPadding = mergeStyles({
    margin: '1rem 2rem 1rem 0',
});

const dropdownStyles: Partial<IDropdownStyles> = { root: { width: 210 } };

const headingStyle = mergeStyles(globalStyles.boldFont, globalStyles.mediumLargeFont);

const separatorStyles = mergeStyles(globalSeparatorStyles, {
    padding: '0',
    height: '1px',
});

type ReviewInfoProps = {
    currentFormRecord: GenericFormRecord;
    setCurrentFormRecord: (rec: GenericFormRecord) => void;
    isUserSubmitter: boolean;
    setMessageBarText: (s: string) => void;
};
const options: IComboBoxOption[] = [
    { key: genericFormRecordFormStates.underReview, text: 'Under Review' },
    { key: genericFormRecordFormStates.rejected, text: 'Rejected' },
    { key: genericFormRecordFormStates.accepted, text: 'Accepted' },
    { key: genericFormRecordFormStates.completed, text: 'Completed' },
];

export function FormsReviewInfoComments(props: ReviewInfoProps): JSX.Element {
    const { currentFormRecord, setCurrentFormRecord, isUserSubmitter, setMessageBarText } = props;
    const [comment, setComment] = useState<string>(currentFormRecord.comment ?? '');
    const [formRecordStatus, setFormRecordStatus] = useState<string | undefined>(
        currentFormRecord.formState === FormState.submitted
            ? undefined
            : currentFormRecord.formState.toLowerCase(),
    );
    const [isChecked, setIsChecked] = useState<boolean>(currentFormRecord.requestUpdate ?? false);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const hasEditFeatureFlagEnabled = useFeatureFlag(FeatureFlagKeys.formsEdit).enabled;

    const authContext = useContext(AuthContext);
    const history = useHistory();

    const changeDropdown = async (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): Promise<void> => {
        if (option?.key) {
            setFormRecordStatus(option.key as string);
        }
    };

    const getErrorMessage = (value: string): string => {
        return value.length <= 2000 ? '' : 'Comment length must be less than 2000 characters.';
    };

    const createDateTimeString = (date: number): string => {
        const dateStr = dateToLocalShortDateFormat(secondsToMilliseconds(date) ?? 0);
        const timeStr = new Date(secondsToMilliseconds(date) ?? 0).toLocaleTimeString();
        return `${dateStr} ${timeStr}`;
    };

    const updateGenericFormRecord = async (): Promise<void> => {
        try {
            const updatedRecord = await FormsClient.UpdateGenericFormRecord(authContext, {
                comment: comment,
                formState: formRecordStatus,
                id: currentFormRecord.id,
                requestUpdate: isChecked,
            });
            if (updatedRecord !== undefined) {
                setCurrentFormRecord(updatedRecord);
                setMessageBarText('Successfully updated form record');
            }
        } catch (e) {
            console.error(e);
            setMessageBarText(`An error occurred updating the form record`);
        } finally {
            setSubmitting(false);
        }
    };

    const submitReview = async (): Promise<void> => {
        setSubmitting(true);
        await updateGenericFormRecord();
    };

    const onCommentChange = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newText?: string | undefined,
    ): void => {
        if (newText !== undefined) {
            setComment(newText);
        }
    };

    const cloneForm = async (): Promise<void> => {
        try {
            const cloneId = await FormsClient.CloneGenericFormRecord(
                authContext,
                currentFormRecord.id,
            );
            if (cloneId !== '' || cloneId !== undefined) {
                history.push(`/forms/respond/${currentFormRecord.name}/${cloneId}`);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const createDropdownStyle = (): IStyleFunctionOrObject<
        IDropdownStyleProps,
        IDropdownStyles
    > => {
        if (!formRecordStatus) {
            return dropdownStyles;
        }

        const background = getStateColor(formRecordStatus);
        const foreground = '#000';
        const result = {
            title: {
                backgroundColor: background,
                color: foreground,
                fontWeight: 'bold',
                selectors: {
                    ':hover': {
                        color: foreground,
                    },
                },
            },
            caretDown: {
                color: foreground,
                fontWeight: 'bold',
                selectors: {
                    ':hover': {
                        color: foreground,
                    },
                },
            },
        };
        return mergeStyleSets(dropdownStyles, result);
    };

    const canCloneForm = (): boolean => {
        if (currentFormRecord === undefined || currentFormRecord.submittedAtUTC === undefined) {
            return false;
        }

        const submittedDate = new Date(
            secondsToMilliseconds(currentFormRecord.submittedAtUTC) as number,
        );
        const migrationDate = new Date(2023, 0, 28);
        return migrationDate < submittedDate;
    };

    return (
        <>
            <h2 className={headingStyle}>Form review</h2>
            <FormReviewStatusIndicator formRecord={currentFormRecord} />
            <div className={infoContainerStyle}>
                <div className={flexStyle}>
                    <Label className={flexBasisStyle}>Form type</Label>
                    <span>{currentFormRecord.title ?? ''}</span>
                </div>
                <div className={flexStyle}>
                    <Label className={flexBasisStyle}>Form name</Label>
                    <span>{currentFormRecord.label ?? ''}</span>
                </div>
                <div className={flexStyle}>
                    <Label className={flexBasisStyle}>Submitted by</Label>
                    {currentFormRecord.personnelId && (
                        <div className={hoverCardMinWidth}>
                            <EmployeeBasicHoverCard personnelId={currentFormRecord.personnelId} />
                        </div>
                    )}
                    {currentFormRecord.submittedAtUTC && (
                        <span className={timestampStyle}>
                            {createDateTimeString(currentFormRecord.submittedAtUTC)}
                        </span>
                    )}
                </div>
                <div className={flexStyle}>
                    <Label className={flexBasisStyle}>Reviewed by</Label>
                    {currentFormRecord.reviewStatusChangedBy && (
                        <div className={hoverCardMinWidth}>
                            <EmployeeBasicHoverCard
                                alias={returnEmailUserName(currentFormRecord.reviewStatusChangedBy)}
                            />
                        </div>
                    )}
                    {currentFormRecord.reviewStatusChangeAtUTC && (
                        <span className={timestampStyle}>
                            {createDateTimeString(currentFormRecord.reviewStatusChangeAtUTC)}
                        </span>
                    )}
                </div>
                {!isUserSubmitter && (
                    <div className={statusDropdownStyle}>
                        <Label className={flexBasisStyle}>Status</Label>
                        <Dropdown
                            styles={createDropdownStyle()}
                            ariaLabel='Status'
                            selectedKey={formRecordStatus}
                            onChange={(ev, op): void => {
                                changeDropdown(ev, op);
                            }}
                            options={options}
                            placeholder={'Select a review status..'}
                        />
                    </div>
                )}
                {!hasEditFeatureFlagEnabled && isUserSubmitter && (
                    <div className={statusDropdownStyle}>
                        <Label className={flexBasisStyle}>Action</Label>
                        <ActionButton
                            iconProps={{ iconName: IconNames.Copy }}
                            disabled={!canCloneForm()}
                            onClick={(): void => {
                                cloneForm();
                            }}>
                            Duplicate
                        </ActionButton>
                    </div>
                )}
                {hasEditFeatureFlagEnabled &&
                    isUserSubmitter &&
                    (currentFormRecord.formState === FormState.accepted ||
                        currentFormRecord.formState === FormState.completed) && (
                        <div className={statusDropdownStyle}>
                            <Label className={flexBasisStyle}>Action</Label>
                            <ActionButton
                                iconProps={{ iconName: IconNames.Copy }}
                                disabled={!canCloneForm()}
                                onClick={(): void => {
                                    cloneForm();
                                }}>
                                Duplicate
                            </ActionButton>
                        </div>
                    )}
            </div>
            <div className={commentsContainerStyle}>
                <Stack horizontal tokens={{ childrenGap: '12rem' }} verticalAlign='center'>
                    <Stack.Item>
                        <h3 className={headingStyle}>Comments</h3>
                    </Stack.Item>
                </Stack>
                {isUserSubmitter && <>{comment ?? ''}</>}
                {!isUserSubmitter && (
                    <div>
                        <TextField
                            value={comment}
                            multiline
                            className={descriptionStyles}
                            autoAdjustHeight
                            onChange={onCommentChange}
                            onGetErrorMessage={getErrorMessage}
                            placeholder={'Enter comment here'}
                        />
                        <Checkbox
                            label='Request user to make updates and resubmit'
                            checked={isChecked}
                            className={checkboxPadding}
                            onChange={(ev, checked): void =>
                                setIsChecked(checked !== undefined ? checked : false)
                            }
                        />
                        <SaveButton
                            className={saveButtonPadding}
                            onClick={(): Promise<void> => submitReview()}
                            saveText={'Submitting'}
                            defaultText={'Submit'}
                            isSaving={isSubmitting}
                            isDisabled={!formRecordStatus || comment.length >= 2000}
                        />
                    </div>
                )}
                <Separator className={separatorStyles} />
            </div>
        </>
    );
}
