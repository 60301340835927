import React, { ReactNode } from 'react';
import { IStaffingTeamResponse, IStaffingClearanceRecordResponse } from 'clients/staffing-client';
import {
    statusSortCmp,
    getStatusTitle,
    getStatusBackgroundColor,
    ClearanceStatusTextType,
    EmptyBoxBackground,
    orgHasNoTeamMsg,
} from 'components/staffing/staffing-constants';
import { mergeStyles, TooltipHost, TooltipDelay } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import EllipsisTextCss from 'components/common/ellipsis-text-css';

type StatusBox = {
    statusName: ClearanceStatusTextType | undefined;
    idOrPreHireName: string;
};

type showWhatType = 'status-table' | 'heat-map';

interface IOrgStatusTableProps {
    teams: IStaffingTeamResponse[];
    orgName: string;
    showWhat: showWhatType;
    maxStatusColumns: number;
    theseAllocations: IStaffingClearanceRecordResponse[];
}

export default function StaffingOrgStatusTable(props: IOrgStatusTableProps): JSX.Element {
    const { orgName, teams, theseAllocations } = props;

    const params = statusTableParams(props.showWhat);

    const tdStatusStyle = (statusName: ClearanceStatusTextType | undefined): string => {
        const backgroundColor = statusName
            ? getStatusBackgroundColor(statusName)
            : EmptyBoxBackground;
        return mergeStyles({
            border: defaultBorderStyle,
            width: params.tableSquareWidth,
            height: params.tableSquareWidth,
            textAlign: 'center',
            backgroundColor: backgroundColor,
        });
    };

    const tableAllocationStyle = mergeStyles({
        border: defaultBorderStyle,
        width: params.tableSquareWidth,
    });

    const tableOrgStyle = mergeStyles({
        border: defaultBorderStyle,
        width: 109,
        paddingLeft: 5,
        textAlign: 'left',
    });

    const tooltipContentStyle = mergeStyles({
        display: 'block',
        textAlign: 'center',
    });

    const emptyButGreedyStyle = mergeStyles({
        // This style ensures that the tooltip
        // appears on the status box.
        display: 'inline-block',
        width: params.tableSquareWidth,
    });

    const orgHasNoTeamMsgStyle = mergeStyles(
        {
            cursor: 'pointer',
        },
        globalStyles.largeFont,
    );

    const prepareStatusTds = (theseAllocations: IStaffingClearanceRecordResponse[]): ReactNode => {
        const theseStatuses = theseAllocations.map((allocation) => {
            const { cloudStatuses } = allocation;
            return Object.keys(
                cloudStatuses
                    .map((s) => s.status)
                    .reduce((a, c) => {
                        a[c] = true;
                        return a;
                    }, {} as { [key: string]: true }),
            ).map((statusName) => {
                return {
                    statusName,
                    allocation,
                    idOrPreHireName: allocation.preHireName
                        ? `Pre Hire Name: ${allocation.preHireName}`
                        : `Personnel ID: ${allocation.personnelId}`,
                } as StatusBox;
            });
        });
        const theseStatusesFlattened: StatusBox[] = [];
        theseStatuses.forEach((s) => {
            s.forEach((ss) => {
                if (theseStatusesFlattened.length < props.maxStatusColumns) {
                    theseStatusesFlattened.push(ss);
                }
            });
        });
        for (let ix = theseStatusesFlattened.length; ix < props.maxStatusColumns; ix++) {
            theseStatusesFlattened.push({ statusName: undefined, idOrPreHireName: '' });
        }
        return theseStatusesFlattened
            .sort((s1, s2) => statusSortCmp(s1.statusName, s2.statusName))
            .map((s, k) => (
                <td key={k} className={tdStatusStyle(s.statusName)}>
                    <TooltipHost
                        content={
                            s.statusName ? (
                                <span className={tooltipContentStyle}>
                                    <span>{getStatusTitle(s.statusName)}</span>
                                    <br />
                                    <span>{s.idOrPreHireName}</span>
                                </span>
                            ) : (
                                <span className={tooltipContentStyle}>No Allocation</span>
                            )
                        }
                        delay={TooltipDelay.medium}>
                        <span className={emptyButGreedyStyle}></span>
                    </TooltipHost>
                </td>
            ));
    };

    if ((teams ?? []).length <= 0) {
        if (props.showWhat === 'status-table')
            return <div className={orgHasNoTeamMsgStyle}> {orgHasNoTeamMsg(orgName)}</div>;
        else return <></>;
    } else {
        return (
            <table key={orgName} className={params.tableStyle}>
                <thead>
                    <tr>
                        <th className={tableOrgStyle}>
                            {orgName} ({theseAllocations.length})
                        </th>
                        <th className={params.tableTargetStyle}>{params.targetColumnHeader}</th>
                        <th className={params.tableTargetStyle}>
                            {params.allocationAssignedHeader}
                        </th>
                        {Array.from(Array(props.maxStatusColumns || 0).keys()).map((k) => (
                            <th key={k} className={tableAllocationStyle}>
                                {k + 1}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {teams
                        ?.sort((i1, i2) => i1.name.localeCompare(i2.name))
                        .map((team) => {
                            const thisTeamsAllocations = theseAllocations.filter(
                                (a) => a.team === team.name,
                            );
                            return (
                                <tr key={team.name}>
                                    <td className={tableOrgStyle}>
                                        <EllipsisTextCss text={team.name} />
                                    </td>
                                    <td className={params.tableTargetStyle}>{team.peopleTarget}</td>
                                    <td className={params.tableTargetStyle}>
                                        {thisTeamsAllocations.length}
                                    </td>
                                    {prepareStatusTds(thisTeamsAllocations)}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        );
    }
}

const defaultBorderStyle = '1px solid';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const statusTableParams = (forWhat: showWhatType): { [key: string]: any } => {
    switch (forWhat) {
        case 'status-table':
            return {
                tableSquareWidth: 19,
                targetColumnHeader: 'T',
                allocationAssignedHeader: '#',
                tableStyle: mergeStyles({
                    cursor: 'pointer',
                    borderCollapse: 'collapse',
                    selectors: {
                        ':hover': { boxShadow: '0 0 24px 0 rgba(0,0,0,.2)' },
                    },
                }),
                tableTargetStyle: mergeStyles({
                    width: 19,
                    border: defaultBorderStyle,
                    textAlign: 'center',
                }),
            };
        case 'heat-map':
            return {
                tableSquareWidth: 29,
                targetColumnHeader: 'Target #',
                allocationAssignedHeader: 'Assigned #',
                tableStyle: mergeStyles({
                    borderCollapse: 'collapse',
                    selectors: {
                        ':hover': { boxShadow: '0 0 24px 0 rgba(0,0,0,.2)' },
                    },
                }),
                tableTargetStyle: mergeStyles({
                    width: 80,
                    border: defaultBorderStyle,
                    textAlign: 'center',
                }),
            };
    }
};
