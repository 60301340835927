import React, { useContext } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import EligibilityClient, { IEligibility } from 'clients/eligibility-client';
import { readErrorMessageBody } from 'utils/misc-utils';

interface IDeleteEligibilityModalProps {
    eligibility: IEligibility;
    onDeletetEligibilityConcluded: (
        eligibility: IEligibility,
        modalConclusion: ModalConclusion,
    ) => void;
}

export default function DeleteEligibilityModalButton(
    props: IDeleteEligibilityModalProps,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const onDeleteEligibilitySubmit = async (): Promise<void> => {
        try {
            await EligibilityClient.deleteEligibility(authContext, props.eligibility.id);
        } catch (e) {
            const submitErrorEventText = await readErrorMessageBody(e);
            if (submitErrorEventText) {
                throw submitErrorEventText;
            } else {
                console.error('Error processing - DelEligibility');
                console.error(e);
                // I don't know what the error is.
                // Throw it and let the modal catch it.
                throw e;
            }
        }
    };

    const onDeleteEligibilityConcluded = (conclusion: ModalConclusion): void => {
        if (props.onDeletetEligibilityConcluded) {
            props.onDeletetEligibilityConcluded(props.eligibility, conclusion);
        }
    };

    return (
        <ModalActionButton<void>
            text={'Delete'}
            iconName={IconNames.Delete}
            modalTitle={'Delete Eligibility'}
            modalTitleIcon={IconNames.Delete}
            enableSubmit={true}
            submitButtonText={'Delete'}
            submitButtonIcon={IconNames.Delete}
            onSubmit={onDeleteEligibilitySubmit}
            onModalConcluded={onDeleteEligibilityConcluded}>
            <span>
                Are you sure you want to delete eligibility{' '}
                <strong>{`${props.eligibility.eligibilityCode} (${props.eligibility.eligibilityName})`}</strong>
                ?
            </span>
        </ModalActionButton>
    );
}
