import React, { useContext, useEffect, useState } from 'react';
import {
    IDropdownOption,
    ComboBox,
    IComboBoxOption,
    IComboBox,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import { noDataText } from 'assets/constants/global-constants';
import EmployeeClient, { IBasicEmployee } from 'clients/employee-client';
import { AuthContext, IAuthContext } from 'contexts/auth-context';
import { dropdownComboBox } from 'components/screening/common/filters/common-filter-styling';
import { FiltersContractsContext } from 'contexts/filters-contracts-context';
import { IContract } from 'components/screening/us-gov/IContract';
export interface ContractOwnerDropdownFilterProps {
    contracts: IContract[];
    onFilterStateChange: (ownerSelectedArr: string[], adding: boolean) => void;
}

export function ContractOwnerDropdownFilter(props: ContractOwnerDropdownFilterProps): JSX.Element {
    const [message, setMessage] = useState<string>('');
    const [hasMessage, setHasMessage] = useState(false);
    const [ownerOptions, setOwnerOptions] = useState<IDropdownOption[]>([]);
    const filtersContext = useContext(FiltersContractsContext);
    const authContext = useContext(AuthContext);
    const placeHolder = 'Owner';
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    useEffect(() => {
        if (filtersContext.filterCleared) setSelectedKeys([]);
    }, [filtersContext.filterCleared]);

    useEffect(() => {
        let isMounted = true;

        async function setupOwners(
            authContext: IAuthContext,
            contracts: IContract[],
        ): Promise<void> {
            // deduping owner (employee) IDs since we only want to look up the employee via ID once
            const uniqueOwnerIds = new Set<string>();
            contracts.forEach((contract) => {
                // may not have an owner assigned
                if (contract.owners && contract.owners.length > 0) {
                    contract.owners.forEach(uniqueOwnerIds.add, uniqueOwnerIds);
                }
            });

            const result = await EmployeeClient.getBasicEmployeesById(
                authContext,
                Array.from(uniqueOwnerIds),
            );
            // exclude owners that are not active from the list and sort it alphabetically
            const filteredResult = result.filter((x) => x.isActiveEmployee);
            filteredResult.sort(compareOwnerNames);

            const newOwnerOptions: IDropdownOption[] = [];
            filteredResult.forEach((po) => {
                newOwnerOptions.push({
                    key: po.id,
                    text: `${po.displayName} (${po.onPremisesSamAccountName})`,
                    data: po,
                });
            });
            newOwnerOptions.push({
                key: noDataText,
                text: noDataText,
                data: null,
            });

            if (isMounted) {
                setOwnerOptions(newOwnerOptions);
            }
        }

        if (props?.contracts?.length > 0) {
            setupOwners(authContext, props.contracts);
        }

        return (): void => {
            isMounted = false;
        };
    }, [props.contracts]);

    return (
        <>
            <ComboBox
                ariaLabel='Contract Owners'
                multiSelect
                selectedKey={selectedKeys}
                placeholder={placeHolder}
                multiSelectDelimiter='; '
                autoComplete='on'
                options={ownerOptions}
                onChange={onSelectedContractOwnerChange}
                useComboBoxAsMenuWidth
                allowFreeform
                styles={dropdownComboBox}
            />
            {hasMessage && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    dismissButtonAriaLabel='Close'
                    overflowButtonAriaLabel='See more'
                    onDismiss={onDismissMessage}>
                    {message}
                </MessageBar>
            )}
        </>
    );

    function compareOwnerNames(a: IBasicEmployee, b: IBasicEmployee): number {
        const aUpper = a.displayName.toUpperCase();
        const bUpper = b.displayName.toUpperCase();
        if (aUpper < bUpper) return -1;
        if (aUpper > bUpper) return 1;
        return 0;
    }

    function onDismissMessage() {
        setHasMessage(false);
        setMessage('');
    }

    function onSelectedContractOwnerChange(
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
    ): void {
        if (option) {
            try {
                const isAdding = !!option.selected;
                const newlySelected: string[] = isAdding
                    ? [...selectedKeys, option!.key as string]
                    : selectedKeys.filter((k) => k !== option!.key);
                props.onFilterStateChange([...newlySelected], !!option.selected);
                setSelectedKeys(newlySelected);
                const ownerID = option?.key === noDataText ? option.key : option.data.id;
                // we need to set this to something unique in the event that the
                // same owner is toggled on/off in succession
                filtersContext.setOwnerId(`${ownerID} ${Date.now()}`);
            } catch (error) {
                setMessage(
                    'An error occurred while loading the selected ' +
                        { placeHolder } +
                        ' information.',
                );
                setHasMessage(true);
            }
        } else {
            filtersContext.setOwnerId('');
            props.onFilterStateChange([], false);
        }
    }
}

export default ContractOwnerDropdownFilter;
