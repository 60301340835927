import { IPublicTrust } from 'components/screening/public-trust/public-trust-screening-result';
import { IContact, IPreHire, IScreening } from 'components/screening/us-gov/IScreening';
import { IContract } from 'components/screening/us-gov/IContract';
import {
    AdjudicatedDecision,
    getParentState,
    GetUSGovStateName,
    GetUSGovStatusString,
    ScreeningPaths,
    ScreeningRequestTypes,
    StateName,
} from 'components/screening/common/common-constants';
import {
    SuitabilityAgencies,
    SuitabilityLevels,
} from 'components/personnel-profile/suitability/profile-suitability-types';

// TODO: Convert ICommonScreening into a class.
export interface ICommonScreening {
    // adjudicated results
    adjudicatedResult?: AdjudicatedDecision;
    adjudicatedUtcMillis: number;
    // Business data
    billetNumber: string;
    mapping: string;
    milestone: string;
    location: string;

    businessJustification: string;
    contractId: string; // contract id column

    // indocDetails
    indocTrainingUtcMillis: number;
    indocBriefingUtcMillis: number;

    // nominated
    nominatedBy: string; // personnel id of user
    nominatedAtUtc: number;

    // not sure if we need this in the frontend
    previousPersonnelIds: string[];
    processOwnerId: string;
    requestType: ScreeningRequestTypes; // "Initial"

    // this needs to be explored and looked into due to us gov
    // doesn't have same stateNames as public trust
    stateName: StateName;

    // withdrawalDetails: {
    withdrawalReason: string;
    withdrawalNotes: string;
    withdrawalUtcMillis: number;

    id: string;
    personnelId: string;

    // statusChanged: {
    statusChangedBy?: string;
    statusChangedAtUtc?: number;

    userType: string;

    // lastOperation: string;

    // public trust specific
    publicTrustAgency?: SuitabilityAgencies;
    suitabilityLevel?: SuitabilityLevels;
    // isAdjudicatedGranted?: boolean; // it is returned to us from the backend but we don't use it in the frontend
    isPIVRequired?: boolean;
    // dateCreated: {
    dateCreatedBy?: string;
    dateCreatedAtUtc?: number;
    // lastModified: {
    lastModifiedBy?: string;
    lastModifiedAtUtc?: number;

    // US gov specific
    contract?: IContract; // we manually grab for public trust
    clearance?: string;
    access?: string[];
    isCustomerBadgeRequired?: boolean;
    eqipId?: string;
    contact?: IContact;
    // TODO: Refactor the backend to remove these fields from US Gov.
    screeningState?: string;
    screeningAction?: string;
    screeningView?: string;
    // prehire
    preHire?: IPreHire;

    ScreeningPath: ScreeningPaths;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isICommonScreening(object: any): object is ICommonScreening {
    if (!object) {
        return false;
    }
    return (object as ICommonScreening).ScreeningPath !== undefined;
}

export function convertCommonScreeningToIPublicTrust(
    commonScreening: ICommonScreening,
): IPublicTrust {
    return {
        adjudicatedDetails: {
            adjudicatedResult: commonScreening.adjudicatedResult,
            adjudicatedUtcMillis: commonScreening.adjudicatedUtcMillis,
        },
        businessData: {
            billetNumber: commonScreening.billetNumber,
            location: commonScreening.location,
            mapping: commonScreening.mapping,
            milestone: commonScreening.milestone,
        },
        businessJustification: commonScreening.businessJustification,
        contractId: commonScreening.contractId, // contract id column
        contract: commonScreening.contract, // we manually add this in the frontend
        indocDetails: {
            indocTrainingUtcMillis: commonScreening.indocTrainingUtcMillis,
            indocBriefingUtcMillis: commonScreening.indocBriefingUtcMillis,
        },
        nominated: {
            by: commonScreening.nominatedBy, // personnel id of user
            atUtc: commonScreening.nominatedAtUtc,
        },
        previousPersonnelIds: commonScreening.previousPersonnelIds,
        processOwnerId: commonScreening.processOwnerId,
        requestType: commonScreening.requestType, // "Initial"
        isPIVRequired: commonScreening.isPIVRequired || false,
        publicTrustAgency: commonScreening.publicTrustAgency || SuitabilityAgencies.DOD,
        suitabilityLevel: commonScreening.suitabilityLevel || SuitabilityLevels.IT1,
        stateName: commonScreening.stateName,
        withdrawalDetails: {
            withdrawalReason: commonScreening.withdrawalReason,
            withdrawalNotes: commonScreening.withdrawalNotes,
            withdrawalUtcMillis: commonScreening.withdrawalUtcMillis,
        },
        id: commonScreening.id,
        personnelId: commonScreening.personnelId,
        dateCreated: {
            by: commonScreening.dateCreatedBy || '',
            atUtc: commonScreening.dateCreatedAtUtc || 0,
        },
        statusChanged: {
            by: commonScreening.statusChangedBy || '',
            atUtc: commonScreening.statusChangedAtUtc || 0,
        },
        lastModified: {
            by: commonScreening.lastModifiedBy || '',
            atUtc: commonScreening.lastModifiedAtUtc || 0,
        },
        userType: commonScreening.userType,
    };
}

export function convertCommonScreeningToIScreening(commonScreening: ICommonScreening): IScreening {
    const contact: IContact = commonScreening.contact || {
        homePhone: '',
        homeEmail: '',
        homeAddress: '',
    };
    return {
        adjudicatedResult: commonScreening.adjudicatedResult?.toString() || '',
        adjudicatedUtcMillis: commonScreening.adjudicatedUtcMillis,
        businessData: {
            billetNumber: commonScreening.billetNumber,
            location: commonScreening.location,
            mapping: commonScreening.mapping,
            milestone: commonScreening.milestone,
        },
        businessJustification: commonScreening.businessJustification,
        governmentContract: commonScreening.contractId, // contract id column
        contact,
        indocTrainingUtcMillis: commonScreening.indocTrainingUtcMillis,
        indocBriefingUtcMillis: commonScreening.indocBriefingUtcMillis,
        nominated: {
            by: commonScreening.nominatedBy, // personnel id of user
            atUtc: commonScreening.nominatedAtUtc,
        },
        previousPersonnelIds: commonScreening.previousPersonnelIds,
        processOwnerId: commonScreening.processOwnerId,
        requestDetails: {
            requestType: commonScreening.requestType,
            clearance: commonScreening.clearance || '',
            access: commonScreening.access || [],
            isCustomerBadgeRequired: commonScreening.isCustomerBadgeRequired || false,
        },
        // isPIVRequired: commonScreening.isPIVRequired || false,
        // stateName: commonScreening.stateName,
        state: GetUSGovStatusString(commonScreening.stateName),
        parentState: getParentState(commonScreening.stateName),

        withdrawalReason: commonScreening.withdrawalReason,
        withdrawalNotes: commonScreening.withdrawalNotes,
        withdrawalUtcMillis: commonScreening.withdrawalUtcMillis,

        id: commonScreening.id,
        personnelId: commonScreening.personnelId,
        statusChanged: {
            by: commonScreening.statusChangedBy || '',
            atUtc: commonScreening.statusChangedAtUtc || 0,
        },
        userType: commonScreening.userType,
        eqipId: commonScreening.eqipId || '',
        preHire: commonScreening.preHire,
        // need to revisit this to make sure this doesn't affect the original
        screeningState: getParentState(commonScreening.stateName),
        screeningAction: commonScreening.screeningAction || '',
        screeningView: commonScreening.screeningView || '',
    };
}

export function convertIPublicTrustToCommonScreening(publicTrust: IPublicTrust): ICommonScreening {
    return {
        // adjudicated results
        adjudicatedResult: publicTrust.adjudicatedDetails.adjudicatedResult,
        adjudicatedUtcMillis: publicTrust.adjudicatedDetails.adjudicatedUtcMillis,
        // isAdjudicatedGranted: publicTrust.adjudicatedDetails.isAdjudicatedGranted, // it is returned to us from the backend but we don't use it in the frontend
        // Business data
        billetNumber: publicTrust.businessData.billetNumber,
        mapping: publicTrust.businessData.mapping,
        milestone: publicTrust.businessData.milestone,
        location: publicTrust.businessData.location,

        businessJustification: publicTrust.businessJustification,
        contractId: publicTrust.contractId,

        // indocDetails
        indocTrainingUtcMillis: publicTrust.indocDetails.indocTrainingUtcMillis,
        indocBriefingUtcMillis: publicTrust.indocDetails.indocBriefingUtcMillis,

        // nominated
        nominatedBy: publicTrust.nominated.by, // personnel id of user
        nominatedAtUtc: publicTrust.nominated.atUtc,

        // not sure if we need this in the frontend
        previousPersonnelIds: publicTrust.previousPersonnelIds,
        processOwnerId: publicTrust.processOwnerId,
        requestType: publicTrust.requestType, // "Initial"

        // this needs to be explored and looked into due to us gov
        // doesn't have same stateNames as public trust
        stateName: publicTrust.stateName,

        // withdrawalDetails: {
        withdrawalReason: publicTrust.withdrawalDetails.withdrawalReason,
        withdrawalNotes: publicTrust.withdrawalDetails.withdrawalNotes,
        withdrawalUtcMillis: publicTrust.withdrawalDetails.withdrawalUtcMillis,

        id: publicTrust.id,
        personnelId: publicTrust.personnelId,

        // statusChanged: {
        statusChangedBy: publicTrust.statusChanged.by,
        statusChangedAtUtc: publicTrust.statusChanged.atUtc,

        userType: publicTrust.userType,

        // public trust specific
        publicTrustAgency: publicTrust.publicTrustAgency,
        suitabilityLevel: publicTrust.suitabilityLevel,
        // lastOperation: publicTrust.lastOperation,
        isPIVRequired: publicTrust.isPIVRequired,
        // dateCreated: {
        dateCreatedBy: publicTrust.dateCreated.by,
        dateCreatedAtUtc: publicTrust.dateCreated.atUtc,
        // lastModified: {
        lastModifiedBy: publicTrust.lastModified.by,
        lastModifiedAtUtc: publicTrust.lastModified.atUtc,

        ScreeningPath: ScreeningPaths.PublicTrust,
    };
}

export function convertIScreeningToCommonScreening(screening: IScreening): ICommonScreening {
    const parentState = screening.parentState;
    const childState = screening.state;
    const convertedStateName = GetUSGovStateName(parentState, childState);
    return {
        // adjudicated results
        adjudicatedResult: screening.adjudicatedResult as AdjudicatedDecision,
        adjudicatedUtcMillis: screening.adjudicatedUtcMillis,
        // Business data
        billetNumber: screening.businessData.billetNumber,
        mapping: screening.businessData.mapping,
        milestone: screening.businessData.milestone,
        location: screening.businessData.location,

        businessJustification: screening.businessJustification,
        contractId: screening.governmentContract, // us gov called it govermentContract

        // indocDetails
        indocTrainingUtcMillis: screening.indocTrainingUtcMillis,
        indocBriefingUtcMillis: screening.indocBriefingUtcMillis,

        // nominated
        nominatedBy: screening.nominated.by, // personnel id of user
        nominatedAtUtc: screening.nominated.atUtc,

        // not sure if we need this in the frontend
        previousPersonnelIds: screening.previousPersonnelIds,
        processOwnerId: screening.processOwnerId,
        requestType: screening.requestDetails.requestType as ScreeningRequestTypes, // "Initial"
        // isPIVRequired: screening.isPIVRequired,

        // this needs to be explored and looked into due to us gov
        // doesn't have same stateNames as public trust
        stateName: convertedStateName,

        // withdrawalDetails: {
        withdrawalReason: screening.withdrawalReason,
        withdrawalNotes: screening.withdrawalNotes,
        withdrawalUtcMillis: screening.withdrawalUtcMillis,

        id: screening.id,
        personnelId: screening.personnelId,

        // statusChanged: {
        statusChangedBy: screening.statusChanged?.by,
        statusChangedAtUtc: screening.statusChanged?.atUtc,

        userType: screening.userType,
        // lastOperation: screening.lastOperation, // not sure if we need this
        // US gov specific
        clearance: screening.requestDetails.clearance,
        access: screening.requestDetails.access,
        isCustomerBadgeRequired: screening.requestDetails.isCustomerBadgeRequired,
        eqipId: screening.eqipId,
        contact: screening.contact,
        screeningState: screening.screeningState,
        screeningAction: screening.screeningAction,
        screeningView: screening.screeningView,
        // prehire
        preHire: screening.preHire,

        ScreeningPath: ScreeningPaths.UsGov,
    };
}
