import ManageGroup from 'components/groups/manage-group/manage-group';
import ManageGroupProvider from 'components/groups/manage-group/manage-group-context';
import ManageGroupsVariableProvider from 'components/groups/manage-groups/contexts/manage-groups-variable-context';
import React from 'react';

export default function ManageGroupWrapper(): JSX.Element {
    return (
        <ManageGroupsVariableProvider>
            <ManageGroupProvider>
                <ManageGroup />
            </ManageGroupProvider>
        </ManageGroupsVariableProvider>
    );
}
