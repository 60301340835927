import React from 'react';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { TableCell } from 'components/common/table';

interface IScaReviewedByTableCell {
    reviewedBy: string;
}

export default function ScaReviewedByTableCell(props: IScaReviewedByTableCell): JSX.Element {
    const reviewedBy = props.reviewedBy;
    const isAllDigits = /^\d+$/.test(reviewedBy);
    const alias = isAllDigits ? undefined : (reviewedBy ?? '').replace(/@.*/, '').toLowerCase();
    const personnelId = isAllDigits ? reviewedBy : undefined;
    return (
        // When first created, we used ID. At some point, we switched to alias. Now, we are switching back to ID because terminated employees are not pollable by alias.
        // https://dev.azure.com/msazure/Microsoft%20Personnel/_boards/board/t/Platform%20(Microsoft%20Personnel)/Stories/?workitem=13913370
        <TableCell>
            {alias && (
                <EmployeeBasicHoverCard key={reviewedBy} showMiniCardAlias={false} alias={alias} />
            )}
            {personnelId && (
                <EmployeeBasicHoverCard
                    key={reviewedBy}
                    showMiniCardAlias={false}
                    personnelId={personnelId}
                />
            )}
        </TableCell>
    );
}
