import {
    DefaultButton,
    Dialog,
    DialogFooter,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from '@fluentui/react';
import { DeleteByAttributeIdRequest } from 'personnel-core-clients';
import { CoreAssignmentsClient } from 'clients/core/personnel-core-client-wrappers';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useEffect, useState } from 'react';

export interface IRemoveAssignmentProps {
    attributeId: string;
    principalId: string;
    oid?: string;
    isDialogueOpen: boolean;
    onDismissed: () => void;
    onSuccess: () => void;
}

export function RemoveAssignmentModal(props: IRemoveAssignmentProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [messageType, setMessageType] = useState<MessageBarType>(MessageBarType.warning);
    const [message, setMessage] = useState<string>(
        'Caution, this user will not have the assignment once removed.',
    );
    const [shouldShowSubmit, setShouldShowSubmit] = useState<boolean>(true);

    useEffect(() => {
        if (props.isDialogueOpen) {
            setShouldShowSubmit(true);
            setMessage('Caution, this user will not have the assignment once removed.');
            setMessageType(MessageBarType.warning);
        }
    }, [props.isDialogueOpen]);

    const removeAssignment = async (): Promise<void> => {
        try {
            setShouldShowSubmit(false);
            const client = new CoreAssignmentsClient(authContext);
            const request = new DeleteByAttributeIdRequest();
            request.attributeId = props.attributeId;
            const response = await client.deleteByAttributeId(props.principalId, request);
            setMessageType(MessageBarType.success);
            setMessage('Attribute assignment has been successfully removed.');
            props.onSuccess();
        } catch (e) {
            let errorJson;
            let status;
            let detail;
            try {
                errorJson = JSON.parse(e.response);
                status = errorJson.status;
                detail = errorJson.detail;
            } catch (jsonError) {
                setMessageType(MessageBarType.error);
                setMessage('Failed to remove attribute assignment.');
                return;
            }

            if (
                status === 400 &&
                detail === 'Attribute is auto-assigned and its assignments cannot be deleted.'
            ) {
                setMessageType(MessageBarType.error);
                setMessage('Cannot manually remove assignments for auto-assigned attributes.');
            } else {
                setMessageType(MessageBarType.error);
                setMessage('Failed to remove attribute assignment.');
            }
        }
    };

    const onClose = async (): Promise<void> => {
        props.onDismissed();
    };

    return (
        <Dialog
            styles={{
                main: {
                    width: 'auto',
                },
            }}
            hidden={!props.isDialogueOpen}
            onDismiss={props.onDismissed}
            dialogContentProps={{
                onDismiss: onClose,
                title: 'Remove Assignment',
                showCloseButton: true,
            }}
            modalProps={{
                styles: {
                    main: {
                        borderRadius: '8px',
                        width: 'auto !important',
                        maxWidth: 'none !important',
                        minWidth: 'none !important',
                    },
                },
            }}>
            <MessageBar messageBarType={messageType}>{message}</MessageBar>
            <div
                style={{
                    paddingTop: 10,
                }}>
                {messageType === MessageBarType.warning &&
                    'Are you sure you want to remove the selected attribute assignment?'}
            </div>
            <DialogFooter>
                {shouldShowSubmit && <PrimaryButton onClick={removeAssignment} text='Remove' />}

                <DefaultButton onClick={onClose} text='Close' />
            </DialogFooter>
        </Dialog>
    );
}
