import { IAuthContext } from 'contexts/auth-context';
import config from 'environments/environment';
import ClientUtils from 'clients/client-utils';
import { IEmployee } from 'clients/employee-client';
import { GetDefaultHttpOptions, IPagedResults } from 'clients/http-options';
import { IFiltersClearanceRecords } from 'contexts/filters-clearance-records';

const { clearanceServiceConfig } = config;
export class ClearanceClient {
    static async getClearancesForEmployee(
        authContext: IAuthContext,
        employee: IEmployee,
        continuationToken?: string,
    ): Promise<IPagedResults<IClearanceRecord>> {
        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        const url =
            clearanceServiceConfig.baseUrl +
            clearanceServiceConfig.clearancesEndpoint.replace('{id}', employee.id);

        return ClientUtils.doHttpRequest<IPagedResults<IClearanceRecord>>(url, httpOptions);
    }

    static async getClearancesByIds(
        authContext: IAuthContext,
        clearanceIds: string[],
    ): Promise<IClearanceRecord[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url =
            clearanceServiceConfig.baseUrl + clearanceServiceConfig.clearancesSearchByIdsEndpoint;
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(clearanceIds);
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Clearances was not found`;
            default:
                throw result;
        }
    }

    static async upsertClearance(
        authContext: IAuthContext,
        clearance: IClearanceRecord,
    ): Promise<IClearanceRecord[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        // Validate=false flag added for card - https://msazure.visualstudio.com/Microsoft%20Personnel/_boards/board/t/Screening/Stories/?workitem=10073448
        const url =
            clearanceServiceConfig.baseUrl +
            clearanceServiceConfig.clearancesUpsertEndpoint +
            '?validate=false';

        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify([clearance]);
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return await response.json();
        } else {
            throw await response.text();
        }
    }

    static async uploadClearanceRecords(
        authContext: IAuthContext,
        request: IClearanceRecordUploadRequest[],
    ): Promise<string | undefined> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const urlParams = new URLSearchParams();
        urlParams.append('validate', `${false}`);
        const url = `${clearanceServiceConfig.baseUrl}${clearanceServiceConfig.clearanceUploadEndpoint}?${urlParams}`;

        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(request);

        const result = await fetch(url, httpOptions);

        switch (result.status) {
            case 200:
                return result.text();
            case 204:
                return;
            default:
                const text = await result.text();
                console.error(text);
                let json;
                try {
                    json = JSON.parse(text);
                } catch {}
                throw new Error(json?.message || result);
        }
    }

    static async deleteClearance(authContext: IAuthContext, id: string): Promise<Response> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';
        const url =
            clearanceServiceConfig.baseUrl +
            clearanceServiceConfig.clearancesDeleteEndpoint.replace('{id}', id);
        const res = await fetch(url, httpOptions);
        if (res.status === 200 || res.status === 500) {
            return res;
        }
        throw res;
    }

    static async getPolygraphsForEmployee(
        authContext: IAuthContext,
        employee: IEmployee,
        continuationToken?: string,
    ): Promise<IPagedResults<IPolygraphRecord>> {
        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        const url =
            clearanceServiceConfig.baseUrl +
            clearanceServiceConfig.polygraphEndpoint.replace('{id}', employee.id);
        return ClientUtils.doHttpRequest<IPagedResults<IPolygraphRecord>>(url, httpOptions);
    }

    static async upsertPolygraph(
        authContext: IAuthContext,
        polygraph: IPolygraphRecord,
    ): Promise<IPolygraphRecord[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = clearanceServiceConfig.baseUrl + clearanceServiceConfig.polygraphUpsertEndpoint;

        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify([polygraph]);
        return ClientUtils.doHttpRequest<IPolygraphRecord[]>(url, httpOptions);
    }

    static async deletePolygraph(authContext: IAuthContext, id: string): Promise<void> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';
        const url =
            clearanceServiceConfig.baseUrl +
            clearanceServiceConfig.polygraphDeleteEndpoint.replace('{id}', id);
        return ClientUtils.doHttpRequest(url, httpOptions);
    }

    static async getSpecialAccessesForEmployee(
        authContext: IAuthContext,
        employee: IEmployee,
        continuationToken?: string,
    ): Promise<IPagedResults<ISpecialAccessRecord>> {
        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        const url =
            clearanceServiceConfig.baseUrl +
            clearanceServiceConfig.specialAccessEndpoint.replace('{id}', employee.id);
        return ClientUtils.doHttpRequest<IPagedResults<ISpecialAccessRecord>>(url, httpOptions);
    }

    static async upsertSpecialAccesses(
        authContext: IAuthContext,
        specialAccesses: ISpecialAccessRecord[],
    ): Promise<ISpecialAccessRecord[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url =
            clearanceServiceConfig.baseUrl + clearanceServiceConfig.specialAccessUpsertEndpoint;

        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(specialAccesses);
        return ClientUtils.doHttpRequest<ISpecialAccessRecord[]>(url, httpOptions);
    }

    static async deleteSpecialAccesses(authContext: IAuthContext, ids: string[]): Promise<void> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(ids);
        const url =
            clearanceServiceConfig.baseUrl + clearanceServiceConfig.specialAccessesDeleteEndpoint;
        return ClientUtils.doHttpRequest(url, httpOptions);
    }

    static async getClearanceReportForEmployee(
        authContext: IAuthContext,
        employeeId: string,
        continuationToken?: string,
    ): Promise<IClearanceWithDetailRecord[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        const url =
            clearanceServiceConfig.baseUrl +
            clearanceServiceConfig.clearanceDownloadReportEndpoint.replace('{id}', employeeId);
        return ClientUtils.doHttpRequest<IClearanceWithDetailRecord[]>(url, httpOptions);
    }

    static async getClearanceRecordsByStatuses(
        authContext: IAuthContext,
        filter: IFiltersClearanceRecords,
        continuationToken?: string,
    ): Promise<IClearanceRecordResult> {
        const urlParams = new URLSearchParams();
        if (filter.status) {
            urlParams.append('userType', filter.status);
        }
        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        const url = `${clearanceServiceConfig.baseUrl}${
            clearanceServiceConfig.statusesEndpoint
        }?${urlParams.toString()}`;
        return ClientUtils.doHttpRequest<IClearanceRecordResult>(url, httpOptions);
    }

    static async getClearanceHolderExcelDataByIds(
        authContext: IAuthContext,
        personnelIds: string[],
        correlationId?: string,
    ): Promise<IClearanceHolderExcelResponse[]> {
        const urlParams = new URLSearchParams();
        if (!!correlationId) {
            urlParams.append('correlationId', correlationId);
        }
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(personnelIds);

        const url = `${clearanceServiceConfig.baseUrl}${
            clearanceServiceConfig.clearanceHolderExcelDataEndpoint
        }?${urlParams.toString()}`;

        return ClientUtils.doHttpRequest<IClearanceHolderExcelResponse[]>(url, httpOptions);
    }

    static async getClearanceRecordExcelDataByRequests(
        authContext: IAuthContext,
        clearanceRecordExcelRequests: IClearanceRecordExcelRequest[],
        correlationId?: string,
    ): Promise<IClearanceRecordExcelResponse[]> {
        const urlParams = new URLSearchParams();
        if (!!correlationId) {
            urlParams.append('correlationId', correlationId);
        }
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(clearanceRecordExcelRequests);

        const url = `${clearanceServiceConfig.baseUrl}${
            clearanceServiceConfig.clearanceRecordsExcelDataEndpoint
        }?${urlParams.toString()}`;

        return ClientUtils.doHttpRequest<IClearanceRecordExcelResponse[]>(url, httpOptions);
    }
}

export interface IClearanceRecord {
    id: string;
    personnelId: string;
    level: string;
    agency?: string;
    status: string;
    grantDateUTCMilliseconds?: number;
    briefDateUTCMilliseconds?: number;
    investigationDateUTCMilliseconds?: number;
    continuousEvaluationEnrollmentDateUTCMilliseconds?: number;
    continuousEvaluationUnenrollmentDateUTCMilliseconds?: number;
    continuousEvaluationType?: string;
    contractId?: string;
    creationDateTimeUTCMilliseconds?: number;
    clearanceBasis?: string;
    clearanceType?: string;
    lastModified?: {
        by: string;
        atUtc: number;
    };
    jpasUpdateIneligible?: boolean;
    nextBriefDateUTCMilliseconds?: number;
    sF312BriefDateUTCMilliseconds?: number;
    debriefDateUTCMilliseconds?: number;
    debriefJustification?: string;
    clearanceExpirationDateTimeUTCMilliseconds?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isIClearanceRecord(object: any): object is IClearanceRecord {
    if (!object) {
        return false;
    }
    return (object as IClearanceRecord).level !== undefined;
}

export interface IClearanceRecordResult {
    results: IClearanceRecord[];
    continuationToken?: string;
}

export interface IPolygraphRecord {
    id: string;
    personnelId: string;
    clearanceId?: string;
    polygraphType: string;
    testDateUTCMilliseconds?: number;
    completionDateUTCMilliseconds?: number;
    results?: string;
    creationDateTimeUTCMilliseconds?: number;
    clearance?: IClearanceRecord;
}

export interface ISpecialAccessRecord {
    id: string;
    personnelId: string;
    clearanceId?: string;
    specialAccess: string;
    status: string;
    submitDateUTCMilliseconds?: number;
    grantDateUTCMilliseconds?: number;
    denyDateUTCMilliseconds?: number;
    briefDateUTCMilliseconds?: number;
    debriefDateUTCMilliseconds?: number;
    rebriefDateUTCMilliseconds?: number;
    creationDateTimeUTCMilliseconds?: number;
    clearance?: IClearanceRecord;
    billetType?: string;
    fileNumber?: string;
    position?: string;
}

export interface IDeletedRecordInfo {
    id: string;
    type: string;
}

export enum ClearanceType {
    Clearance = 'Clearance',
    Polygraph = 'Polygraph',
    SpecialAccess = 'SpecialAccess',
}

export interface IClearanceWithDetailRecord extends IClearanceRecord {
    specialAccesses: ISpecialAccessRecord[];
    polygraphs: IPolygraphRecord[];
}

export interface IClearanceRecordExcelRequest {
    clearanceRecordId: string;
    contractId?: string;
    personnelId: string;
}

export interface IClearanceHolderExcelResponse {
    alias: string;
    firstName: string;
    email: string;
    lastName: string;
    middleName?: string;
    personnelId: string;
}

export interface IClearanceRecordExcelResponse {
    agency: string;
    alias: string;
    briefDateUTCMilliseconds: number;
    debriefDateUTCMilliseconds: number;
    clearanceExpirationDateTimeUTCMilliseconds: number;
    clearanceRecordId: string;
    clearanceType: string;
    continuousEvaluationType: string;
    continuousEvaluationEnrollmentDateUTCMilliseconds: number;
    continuousEvaluationUnenrollmentDateUTCMilliseconds: number;
    contractCustomer: string;
    contractId: string;
    contractProjectName: string;
    employeeStatus: string;
    employeeTitle: string;
    firstName: string;
    geographicLocation: string;
    grantDateUTCMilliseconds: number;
    investigationDateUTCMilliseconds: number;
    l1: string;
    l2: string;
    l3: string;
    l4: string;
    l5: string;
    l6: string;
    lastName: string;
    level: string;
    middleName?: string;
    officeLocation: string;
    organization: string;
    personnelId: string;
    personStatusCode: string;
    polygraphResult: string;
    polygraphType: string;
    recordId: string;
    reportsTo: string;
    status: string;
    terminationDateUTCMilliseconds: number;
}

export interface IClearanceRecordUploadRequest {
    microsoftEmployeeOrVendor: string; // Microsoft Employee Or Vendor
    id: string; // id
    personnelId: string; // Personnel id
    microsoftAlias: string; // Microsoft alias
    firstName: string; // First name
    lastName: string; // Last name
    clearanceLevel: string; // Clearance level
    agency: string; // Agency
    status: string; // Status
    clearanceType: string; // Clearance type
    clearanceBasis: string; // Clearance basis
    investigatedOn: string; // Investigated on
    grantedOn: string; // Granted on
    briefedOn: string; // Briefed on
    nextBriefedOn: string; // Next briefed on
    sF312BriefedOn: string; // SF312 briefed on
    debriefedOn: string; // Debriefed on
    debriefJustification: string; // Debrief justification
    contract: string; // Contract
    jPASUpdateIneligible: string; // JPAS update ineligible
    continuousEvaluationType: string; // Continuous evaluation type
    ceEnrollmentDate: string; // Ce enrollment date
    ceUnenrollmentDate: string; // Ce unenrollment date
    uploadStatus?: string; // Upload status - Will be available in upload result. Is ok to be available in request data.
    uploadComment?: string; // Upload comment - Will be available in upload result. Is ok to be available in request data.
}
