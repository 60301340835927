/*
    Note the casing on EventName, EventId, etc.
    is not eventName, eventId, etc..

    This is intentional (case sensitive) for a
    Logic App query to work with mostly backend services KPIs
    and those naming conventions (C#) since CSAT currently the only front-end driven "KPI"

    See Personnel Wiki for more EventId information:
    https://msazure.visualstudio.com/Microsoft%20Personnel/_wiki/wikis/Microsoft-Personnel.wiki/648543/Event-ID's-KPI's

    Logic app query to ingest KPIs into Azure Data Explorer Cluster from Log Analytics Workspace:

        let dt = now();
        let year = datetime_part('year', dt);
        let month = datetime_part('month', dt);
        let day = datetime_part('day', dt);
        let hour = datetime_part('hour', dt);
        let startTime = make_datetime(year, month, day, hour, 0) - 1h;
        let endTime = startTime + 2h - 1tick;
        AppTraces
        | where isnotnull(Properties.EventId)
        | where Message startswith  "BE."
        | where ingestion_time() between(startTime .. endTime)
        | project EventProcessedUtcTime = ingestion_time(),
            TimeGenerated,
            Message,
            EventId = Properties.EventId,
            EventName = Properties.EventName,
            User = Properties.User,
            Properties = replace_string(tostring(Properties), '"', '\\"'),
            AppRoleName

    Example of KPIData in Azure Data Explorer Cluster upon ingesting,
    This is for a CSAT Feedback Submitted item but an ExampleKPI with KPIPropertiesType
    is commented below for reference purposes:

        "EventProcessedUtcTime": 2024-03-05T18:27:02.0042984Z,
        "TimeGenerated": 2024-03-05T18:26:20.064Z,
        "Message": BE.CSAT.Personnel.FeedbackSubmitted CSAT Feedback was submitted,
        "EventId": 4000,
        "EventName": BE.CSAT.Personnel.FeedbackSubmitted,
        "User": danimartinez@microsoft.com,
        "Properties": {
            "EventName":"BE.CSAT.Personnel.FeedbackSubmitted",
            "EventId":"4000",
            "Rating":"5",
            "FeedbackType":"compliment",
            "Text":"DevTest1",
            "CanContact":"true",
            "User":"danimartinez@microsoft.com"
        },
        "AppRoleName": Microsoft-Personnel-App
*/

export const KPIList = {
    FeedbackSubmitted: { EventName: 'BE.CSAT.Personnel.FeedbackSubmitted', EventId: 4000 },
    // ExampleKPI: { EventName: 'BE.KPI.Personnel.ExampleKPI', EventId: 4001 },
} as const;

export type CSATPropertiesType = {
    EventName: string;
    EventId: number;
    Rating: number;
    FeedbackType: string;
    Text: string;
    CanContact: boolean;
    User: string;
};

// export type KPIPropertiesType = {
//     EventName: string;
//     EventId: number;
//     User: string;
// };
