import { AllocationFilter } from 'components/staffing/status-filters/allocation-filter-class';
import { IStaffingClearanceRecordResponse } from 'clients/staffing-client';

// Filter out allocations that belong to organizations 'O365' and 'CO+I'
export class O365AllocationFilter extends AllocationFilter {
    filter(allocations: IStaffingClearanceRecordResponse[]): IStaffingClearanceRecordResponse[] {
        const filteredStatuses = this.filterStatuses(allocations);
        return filteredStatuses.filter((value) => {
            return value.organization !== 'O365' && value.organization !== 'CO+I';
        });
    }
}
