import React, { useState, useContext } from 'react';
import EmployeeClient, {
    IBasicEmployee,
    IEmployee,
    IEmployeeWithEditableData,
    IConvertEditableRequest,
} from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import { IPersonaProps, MessageBarType, ProgressIndicator } from '@fluentui/react';
import Stepper, { StepperProps } from 'components/common/stepper';
import ModalCreationStep from 'components/common/modal-creation-step';
import { displayErrorsWithHTML } from 'utils/error-display-utils';
import { IStepValidationResult } from 'types/step-validation-result';
import { useHistory } from 'react-router-dom';
import ConvertProfileStepperPageSelection from 'components/personnel-profile/convert-profile/convert-profile-stepper-page-selection';
import ConvertProfileStepperPageConfirmation from 'components/personnel-profile/convert-profile/convert-profile-stepper-page-confirmation';
import ConvertProfileStepperPageReview from 'components/personnel-profile/convert-profile/convert-profile-stepper-page-review';
import { UserContext } from 'contexts/user-context';
import config from 'environments/environment';

export interface ConvertProfileProps {
    convertPerson: IEmployeeWithEditableData;
    convertManager: IBasicEmployee | undefined;
    hideCreateNewProfileProcess: () => void;
    onCreateNewProfileError: (msg: string) => void;
}

export default function ConvertProfileStepper(props: ConvertProfileProps) {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [employee, setEmployee] = useState<IEmployee>();
    const [employeeManager, setEmployeeManager] = useState<IBasicEmployee>();
    const [microsoftId, setMicrosoftId] = useState<string>();
    const [isConvertFTEConfirmed, setIsConvertFTEConfirmed] = useState<boolean>();
    const [convertToEmployeePersona, setConvertToEmployeePersona] = useState<
        IPersonaProps | undefined
    >();
    const [isValidating, setIsValidating] = useState<boolean>();
    const history = useHistory();
    const iconName = 'AddFriend';
    const modalTitle = 'Convert Profile';
    const waitForConvertToPropagateInMilliSecond = 30000;

    const createNewProfileSteps: JSX.Element[] = [
        <ModalCreationStep key='1' iconName={iconName} stepTitle={modalTitle} stepWidth={480}>
            <ConvertProfileStepperPageSelection
                microsoftId={microsoftId}
                selectedEmployeePersona={convertToEmployeePersona}
                onMicrosoftIdChange={(newValue) => setMicrosoftId(newValue)}
                onSelectedEmployeeChange={(employeePersona) => {
                    setConvertToEmployeePersona(employeePersona);
                }}
            />
            {isValidating && (
                <ProgressIndicator
                    label='Validating...'
                    description='Selected Employee Validation'
                />
            )}
        </ModalCreationStep>,
        <ModalCreationStep key='2' iconName={iconName} stepTitle={modalTitle} stepWidth={980}>
            <ConvertProfileStepperPageReview
                convertPerson={props.convertPerson}
                convertManager={props.convertManager}
                employee={employee}
                employeeManager={employeeManager}
            />
        </ModalCreationStep>,
        <ModalCreationStep key='3' iconName={iconName} stepTitle={modalTitle} stepWidth={691}>
            <ConvertProfileStepperPageConfirmation
                checkBoxStatus={isConvertFTEConfirmed}
                onFTEConvertConfirmation={(newValue) => setIsConvertFTEConfirmed(newValue)}
            />
        </ModalCreationStep>,
    ];

    const [isLastStep, setIsLastStep] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [hasMessage, setHasMessage] = useState(false);
    const [message, setMessage] = useState<any>('');
    const [messageType, setMessageType] = useState<MessageBarType>();

    const stepperProps: StepperProps = {
        isFirstStep: false, // this flag is only used to style the cancel, back, and primary button. we want the non first step behavior.
        isLastStep: isLastStep,
        disableFinishButton: !isConvertFTEConfirmed,
        finishText: modalTitle,
        activeStep: activeStep,
        hasMessage: hasMessage,
        messageType: messageType,
        messageText: message,
        finishIndicatorLabel: 'Processing Conversion...',
        handleResetMessage: () => setHasMessage(false),
        handleCancel: () => {
            props.hideCreateNewProfileProcess();
        },
        renderContent: () => {
            return createNewProfileSteps[activeStep];
        },
        handleBack: () => {
            const previousStepIndex = activeStep - 1;
            if (isLastStep) setIsLastStep(false);
            setActiveStep(previousStepIndex);
            if (hasMessage) {
                setHasMessage(false);
                setMessage('');
                setMessageType(undefined);
            }
        },
        handleNext: async () => {
            const validationResult = await currentStepDataIsValid();
            if (validationResult.valid) {
                setActiveStep(activeStep + 1);
                if (activeStep === createNewProfileSteps.length - 2) {
                    setIsLastStep(true);
                }
                if (hasMessage) {
                    setHasMessage(false);
                    setMessage('');
                    setMessageType(undefined);
                }
            } else {
                const message = displayErrorsWithHTML(validationResult.messages!);
                setMessage(message);
                setMessageType(MessageBarType.error);
                setHasMessage(true);
            }
        },
        handleFinish: async () => {
            const request: IConvertEditableRequest = {
                fromPersonnelId: props.convertPerson.data.id,
                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                toPersonnelId: employee?.id!,
                requestBy: userContext.employeeRecord.id,
            };

            try {
                const response = await EmployeeClient.convertEditableEmployeeData(
                    authContext,
                    request,
                );

                if (response) {
                    const waitTimeInMilliSecond = config.production
                        ? waitForConvertToPropagateInMilliSecond
                        : 10000;
                    await new Promise((resolve) => setTimeout(resolve, waitTimeInMilliSecond));
                    history.push(`/profile/us-gov/${response.employeeId}`);
                } else {
                    props.onCreateNewProfileError(
                        `Error: not able to convert prehire record to FTE`,
                    );
                }
            } catch (e) {
                console.log(e);
                if (e.status !== 201) {
                    props.onCreateNewProfileError(`Error: ${e.status} - ${e.statusText}`);
                }
            } finally {
                props.hideCreateNewProfileProcess();
            }
        },
    };

    async function currentStepDataIsValid(): Promise<IStepValidationResult> {
        if (activeStep === 0) return await isStep1Valid();
        return { valid: true };
    }

    async function isStep1Valid(): Promise<IStepValidationResult> {
        const msgs = [];
        if (microsoftId === undefined || microsoftId.trim() === '') {
            msgs.push('The Microsoft ID is required.');
        }
        if (convertToEmployeePersona === undefined) {
            msgs.push('An employee should be selected in the alias input box.');
        }

        if (convertToEmployeePersona && microsoftId) {
            const userInputtedMsftId = microsoftId.trim();
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            const selectedEmployee: IEmployee = JSON.parse(convertToEmployeePersona?.itemProp!);

            if (userInputtedMsftId !== selectedEmployee.id) {
                msgs.push('The Microsoft ID does not match the one in the employee selected');
            } else {
                setEmployee(selectedEmployee);

                setIsValidating(true);
                try {
                    const result = await EmployeeClient.getBasicEmployeesByAlias(authContext, [
                        selectedEmployee.reportsToEmailName,
                    ]);
                    if (result) {
                        setEmployeeManager(result[0]);
                    }
                } catch (e) {
                    if (e.status === 404) {
                        msgs.push(
                            `Selected employee ${selectedEmployee.id} does not have a valid manager`,
                        );
                    } else {
                        msgs.push('Error retrieving selected employee manager');
                    }
                } finally {
                    setIsValidating(false);
                }
            }
        }
        return { valid: msgs.length === 0, messages: msgs };
    }

    return <Stepper {...stepperProps} />;
}
