import React, { useState, useEffect, useContext } from 'react';
import { Table } from 'components/common/table';
import HorizontalBar from 'components/common/horizontal-bar';
import {
    ActionButton,
    Stack,
    TeachingBubble,
    DirectionalHint,
    DefaultButton,
    Toggle,
    TooltipHost,
    Icon,
    MessageBar,
    MessageBarType,
    IColumn,
} from '@fluentui/react';
import { ClearanceRecordsFilter } from 'components/screening/us-gov/candidates/listing/clearance-records-filter';
import { AuthContext } from 'contexts/auth-context';
import { IconNames } from 'assets/constants/global-constants';
import {
    IFiltersClearanceRecords,
    FiltersClearanceRecords,
} from 'contexts/filters-clearance-records';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import {
    ClearanceClient,
    IClearanceHolderExcelResponse,
    IClearanceRecordExcelRequest,
    IClearanceRecordExcelResponse,
    IClearanceRecordResult,
    IClearanceRecord,
} from 'clients/clearance-client';
import { dateToLocalDate, TimeFormats } from 'utils/time-utils';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import EmployeeClient, {
    IBasicEmployee,
    IEmployeeEditableResponse,
    transformPrehireToEmployeeObject,
} from 'clients/employee-client';
import { getClearanceRecordsTableColumns } from 'components/screening/us-gov/candidates/listing/get-clearance-records-table-columns';
import { CacheContext } from 'contexts/cache-context';
import { isGUID } from 'utils/string-utils';
import { Dictionary } from 'assets/constants/global-constants';
import { distinct } from 'utils/misc-utils';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import { useSortColumnHandler, strCmp, numCmp, SortDescending } from 'utils/sort-utils';
import {
    ClearanceLevelsShortHand,
    ClearanceStatuses,
    getAgencyEnumValueFromKey,
} from 'components/personnel-profile/clearance/profile-clearance-constants';
import ReportsClient, { IHierarchy } from 'clients/reports-client';
import { UserContext } from 'contexts/user-context';
import {
    generateLatestHierarchyReport,
    updateHierarchyCache,
} from 'components/screening/common/content-tabs-help';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import {
    getEmployeeType,
    IEmployeeNameAndType,
    PersonnelTypes,
} from 'components/common/employee/internal-employee-utils';
import { isUserContractOwnerOrNST } from 'components/screening/common/filters/filter-help';
import ClearanceRecordsChart from 'components/screening/us-gov/candidates-listing/metrics/clearance-records-chart';
import Spacer from 'components/common/spacer';
import CandidatesPageHelp from 'components/screening/common/components/candidate-page-help';
import { filterWidth, pageStyle } from 'components/screening/common/common-tab-styling';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import { partitionArray } from 'utils/array-utils';
import { determineContractType } from 'components/screening/screening-utils';
import { excelFormatHeader } from 'utils/reporting-utils';
import { columnHeaderStyles, expandedTableWidth } from 'assets/styles/list-styles';
import {
    BrowserCacheKeys,
    generateCacheKeyContract,
    generateCacheKeyEmployeeNames,
} from 'utils/browser-cache-utils';
import ClearanceBulkUploadModal from 'components/screening/us-gov/candidates-listing/tabpanel-contents/clearance-bulk-upload-modal';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface IContentClearanceRecordsTabProps {
    screeningPath: ScreeningPaths;
}

export default function ContentClearanceRecordsTab(
    props: IContentClearanceRecordsTabProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const cacheContext = useContext(CacheContext);
    const filtersContext = useContext(FiltersClearanceRecords);
    const userContext = useContext(UserContext);
    const cacheKey = `${filtersContext.location.pathname}${filtersContext.location.search}`;
    const cacheKeyContracts = generateCacheKeyContract(cacheKey);
    const cacheKeyEmployeeNames = generateCacheKeyEmployeeNames(filtersContext); // the URL of the page
    const MaxExcelDataBatch = 2000;

    const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false);
    const [errorMessage, setMessage] = useState<string>('');
    const [filteredClearances, setFilteredClearances] = useState<IClearanceRecord[]>([]);
    const [hasErrorOccurred, setErrorOccurred] = useState<boolean>(false);
    const [hierarchyRecords, setHierarchyRecords] = useState<Dictionary<IHierarchy>>();
    const [isDoneLoadingClearances, setDoneLoadingClearances] = useState<boolean>(false);
    const [isExpandButtonShown, setisExpandButtonShown] = useState<boolean>(true);
    const [isPageFetchingInitialNominationData, setIsPageFetchingInitialNominationData] = useState<
        boolean
    >(false);
    const [rawClearances, setRawClearances] = useState<IClearanceRecord[]>([]);
    const [tableColumns, setTableColumns] = useState([] as IColumn[]);
    const [contractMap, setContractMap] = useState(new Map<string, string>());
    const [isTeachingBubbleOpen, setTeachingBubbleOpen] = useState(false);
    const [excelExportType, setExcelExportType] = useState<ExportToExcelDataType | undefined>(
        ExportToExcelDataType.Clearances,
    );
    const [
        employeeIdsWithFutureTerminationDates,
        setEmployeeIdsWithFutureTerminationDates,
    ] = useState<string[]>([]);
    const [employeeNames, setEmployeeNames] = useState<Map<string, IEmployeeNameAndType>>(
        new Map<string, IEmployeeNameAndType>(),
    );
    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler(
        'Last Modified',
        SortDescending,
    );
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

    /**
     * Fetches hierarchy data by using personnel ids of clearance records.
     */
    async function updateCacheWithLatestHierarchyData(): Promise<void> {
        const personnelIds = rawClearances.map((record) => record.personnelId);

        if (!personnelIds || personnelIds.length <= 0) {
            return;
        }

        const reportData = await ReportsClient.getSelectHierarchy(authContext, personnelIds);
        setHierarchyRecords(reportData);
        if (Object.keys(reportData).length > 0) {
            updateHierarchyCache(reportData, localStorage);
        }
    }

    async function getLatestHierarchyReport(): Promise<void> {
        const storedRecords = localStorage.getItem(
            BrowserCacheKeys.hierarchyReportStorageAndCacheKey,
        );
        const recordTimestamp = localStorage.getItem(BrowserCacheKeys.hierarchyReportTimestampKey);

        try {
            const generatedReports = await generateLatestHierarchyReport(
                rawClearances,
                authContext,
                storedRecords,
                recordTimestamp,
            );
            if (generatedReports) {
                if (
                    generatedReports.combinedHierarchyData &&
                    Object.keys(generatedReports.combinedHierarchyData).length > 0
                ) {
                    updateHierarchyCache(generatedReports.combinedHierarchyData, localStorage);
                    setHierarchyRecords(generatedReports.combinedHierarchyData);
                } else {
                    setHierarchyRecords(generatedReports.parsedHierarchyData);
                }
            } else {
                // if there is no cached report or if the latest report is from more than 24hrs
                // then just get latest hierarchy report for employees in aggregate report
                await updateCacheWithLatestHierarchyData();
            }
        } catch (e) {
            // if it exists, use whatever stored data is available
            if (recordTimestamp && storedRecords) {
                const parsedHierarchyData: Dictionary<IHierarchy> = JSON.parse(storedRecords);
                setHierarchyRecords(parsedHierarchyData);

                // log the error if they still was able to parse some hierarchy data
                console.error('Could not retrieve or request any hierarchy reports', e);
            } else {
                setErrorOccurred(true);
                setMessage('Could not retrieve or request any hierarchy reports');
            }
        }
    }

    async function getEmployeesWithFutureTerminationDates(personnelIds: string[]): Promise<void> {
        const isContractOwnerOrNST = isUserContractOwnerOrNST(userContext, ScreeningPaths.UsGov);

        if (isContractOwnerOrNST) {
            const results = await UsGovScreeningClient.getEmployeeIdsWithFutureTerminationDates(
                authContext,
                personnelIds,
            );
            setEmployeeIdsWithFutureTerminationDates(results);
        }
    }

    async function getInitialCandidates(filterCtx: IFiltersClearanceRecords): Promise<void> {
        setIsPageFetchingInitialNominationData(true);

        try {
            const results: IClearanceRecordResult = await ClearanceClient.getClearanceRecordsByStatuses(
                authContext,
                filterCtx,
            );
            let clearanceArray: IClearanceRecord[] = results.results;
            if (results.continuationToken) {
                clearanceArray = await getMoreClearances(
                    clearanceArray,
                    filterCtx,
                    results.continuationToken,
                );
            }
            setFilteredClearances(clearanceArray);
            setRawClearances(clearanceArray);
            getEmployeesWithFutureTerminationDates(clearanceArray.map((x) => x.personnelId));
            await batchFetchCandidateEmployeeData(clearanceArray);
            setDoneLoadingClearances(true);
            cacheContext.store<IClearanceRecord[]>(cacheKey, clearanceArray);
        } catch (e) {
            setFilteredClearances([]);
            setErrorOccurred(true);
            setMessage('No Clearances to show');
        }
    }

    async function getContractList(): Promise<void> {
        setIsPageFetchingInitialNominationData(true);

        try {
            const contractsList = await UsGovScreeningClient.getContracts(authContext, [
                determineContractType(props.screeningPath),
            ]);
            const newContractsList = new Map<string, string>();
            // TODO: once there is a contracts api where you can give it a list of contractIds then use that instead
            // get all the contracts into a so we can show the project name for the contract number
            // User Story 12739430
            contractsList.results.forEach((contract) => {
                newContractsList.set(contract.id, contract.project);
            });
            setContractMap(newContractsList);
            cacheContext.store<Map<string, string>>(cacheKeyContracts, newContractsList);
        } catch (e) {
            setMessage('No Clearances to show');
        } finally {
            setIsPageFetchingInitialNominationData(false);
        }
    }

    async function getMoreClearances(
        clearanceArray: IClearanceRecord[],
        filterCtx: IFiltersClearanceRecords,
        continuationToken?: string,
    ): Promise<IClearanceRecord[]> {
        try {
            let results: IClearanceRecordResult;
            let useContinuationToken: string | undefined = continuationToken;
            do {
                results = await ClearanceClient.getClearanceRecordsByStatuses(
                    authContext,
                    filterCtx,
                    useContinuationToken,
                );
                clearanceArray = [...clearanceArray, ...results.results];
                populateEmployeeDataCache(results.results);
                useContinuationToken = results.continuationToken;
            } while (useContinuationToken);
        } catch (e) {
            setErrorOccurred(true);
            setMessage('Failed to get all Clearance records');
        } finally {
            return clearanceArray;
        }
    }

    useEffect(() => {
        const clearanceArray = cacheContext.retrieve<IClearanceRecord[]>(cacheKey);
        const contractMap = cacheContext.retrieve<Map<string, string>>(cacheKeyContracts);
        if (clearanceArray) {
            setFilteredClearances(clearanceArray);
            setRawClearances(clearanceArray);
            setIsPageFetchingInitialNominationData(false);
            getEmployeesWithFutureTerminationDates(clearanceArray.map((x) => x.personnelId));
            setDoneLoadingClearances(true);
        } else {
            getInitialCandidates(filtersContext);
        }
        if (contractMap) {
            setContractMap(contractMap);
        } else {
            getContractList();
        }
    }, []);

    useEffect(() => {
        function filterData(): void {
            let finalData = rawClearances;
            // eslint-disable-next-line @typescript-eslint/ban-types
            filtersContext.filterFunctionsMap.forEach((filterFunctions: Function[]) => {
                if (filterFunctions!.length > 0) {
                    finalData = finalData.filter((data) => {
                        for (const filterFunction of filterFunctions!) {
                            if (filterFunction(data)) {
                                return true;
                            }
                        }
                        return false;
                    });
                }
            });
            setFilteredClearances(finalData);
        }
        filterData();
    }, [filtersContext, rawClearances]);

    useEffect(() => {
        if (
            isDoneLoadingClearances &&
            userContext.isUsGovScreeningUserTypesLoaded &&
            isUserContractOwnerOrNST(userContext, ScreeningPaths.UsGov)
        ) {
            const storedRecords = localStorage.getItem(
                BrowserCacheKeys.hierarchyReportStorageAndCacheKey,
            );
            const hierarchyReportMap: Dictionary<IHierarchy> = storedRecords
                ? JSON.parse(storedRecords)
                : undefined;

            if (hierarchyReportMap) {
                setHierarchyRecords(hierarchyReportMap);
            }
            getLatestHierarchyReport();
        }
    }, [isDoneLoadingClearances, userContext.isUsGovScreeningUserTypesLoaded]);

    /**
     * This is a greedy method just to pre-cache employee data, because it might be shown on the UI
     *
     * Very close to batchFetchCandidateEmployeeData but different in that this will not
     * capture the manager data and the UI will not be held up in rendering.
     * Primary used for data that is not going to be rendering right away.
     */
    async function populateEmployeeDataCache(candidates: IClearanceRecord[]): Promise<void> {
        try {
            const initialPersonnelIdsToFetch = new Set<string>();
            // TODO: Remove after confirming no data issues in Clearance records
            const preHireCheckPersonnelIdsToFetch = new Set<string>();
            candidates.forEach((screen: IClearanceRecord) => {
                if (screen.personnelId) {
                    if (!isGUID(screen.personnelId)) {
                        initialPersonnelIdsToFetch.add(screen.personnelId);
                    } else {
                        // TODO: Remove after confirming no data issues in Clearance records
                        preHireCheckPersonnelIdsToFetch.add(screen.personnelId);
                    }
                } else {
                    // TODO: Remove after confirming no data issues in Clearance records
                    console.log('no personnel id screening', screen);
                }
            });
            if (initialPersonnelIdsToFetch.size > 0) {
                EmployeeClient.getBasicEmployeesById(
                    authContext,
                    Array.from(initialPersonnelIdsToFetch),
                );
            }
        } catch (e) {
            // don't show the error, just log it instead
            console.error(
                'Error resolving alias/ids from employee-services in populateEmployeeDataCache',
                e,
            );
        }
    }

    /**
     * This is a greedy method to handle the first Page of Clearance records that do end up on the
     *  UI therefor also want to capture there managers info and make sure nothing renders until
     * this function is done.
     *
     * Very close to populateEmployeeDataCache, but different in that this is pulling manager
     * and will hold the UI from rendering until complete.
     */
    async function batchFetchCandidateEmployeeData(candidates: IClearanceRecord[]): Promise<void> {
        try {
            const initialPersonnelIdsToFetch = new Set<string>();
            const managerAliasesToFetch = new Set<string>();
            const newNameMap = new Map(employeeNames);
            const initialEditableEmployeeIdsToFetch = new Set<string>();
            candidates.forEach((screen: IClearanceRecord) => {
                if (screen.personnelId) {
                    if (!isGUID(screen.personnelId)) {
                        initialPersonnelIdsToFetch.add(screen.personnelId);
                    } else {
                        initialEditableEmployeeIdsToFetch.add(screen.personnelId);
                    }
                } else {
                    console.log('no personnel id screening', screen);
                }
            });

            if (initialEditableEmployeeIdsToFetch.size > 0) {
                const initialEmployeeData: IEmployeeEditableResponse[] = await EmployeeClient.getMultipleEditableEmployeeDataByIdOrAliasOrGUID(
                    authContext,
                    Array.from(initialEditableEmployeeIdsToFetch),
                );

                initialEmployeeData.forEach((editableEmployee) => {
                    const employee = transformPrehireToEmployeeObject(editableEmployee);

                    if (!!editableEmployee.employeeEditableInfo.reportsToEmailName) {
                        managerAliasesToFetch.add(
                            editableEmployee.employeeEditableInfo.reportsToEmailName,
                        );
                    }

                    newNameMap.set(employee.id, {
                        displayName: getDisplayNameOrDefault(
                            employee,
                            employee.onPremisesSamAccountName,
                        ),
                        alias: employee.alias,
                        firstName: employee.firstName,
                        middleName: employee.middleName,
                        lastName: employee.lastName,
                        email: employee.email,
                        type: getEmployeeType(employee),
                    });

                    if (isGUID(editableEmployee?.employeeId || '')) {
                        newNameMap.set(editableEmployee.employeeId || '', {
                            displayName: getDisplayNameOrDefault(
                                employee,
                                employee.onPremisesSamAccountName,
                            ),
                            alias: employee.alias,
                            firstName: employee.firstName,
                            middleName: employee.middleName,
                            lastName: employee.lastName,
                            email: employee.email,
                            type: getEmployeeType(employee),
                        });
                    }
                });
            }

            if (initialPersonnelIdsToFetch.size > 0) {
                const initialEmployeeData: IBasicEmployee[] = await EmployeeClient.getBasicEmployeesById(
                    authContext,
                    Array.from(initialPersonnelIdsToFetch),
                );

                initialEmployeeData.forEach((emp: IBasicEmployee) => {
                    if (!!emp.reportsToEmailName) {
                        managerAliasesToFetch.add(emp.reportsToEmailName);
                    }
                });

                await EmployeeClient.getBasicEmployeesByAlias(
                    authContext,
                    Array.from(managerAliasesToFetch),
                );

                initialEmployeeData.forEach((basicEmployee) => {
                    newNameMap.set(basicEmployee.id, {
                        displayName: getDisplayNameOrDefault(
                            basicEmployee,
                            basicEmployee.onPremisesSamAccountName,
                        ),
                        alias: basicEmployee.onPremisesSamAccountName,
                        email: basicEmployee.mail,
                        firstName: basicEmployee.givenName,
                        middleName: basicEmployee.middleName,
                        lastName: basicEmployee.surname,
                        type: getEmployeeType(basicEmployee),
                    });
                });
            }

            setEmployeeNames(newNameMap);

            cacheContext.store<Map<string, IEmployeeNameAndType>>(
                cacheKeyEmployeeNames,
                newNameMap,
            );
        } catch (e) {
            // don't show the error instead log it
            console.error(
                'Error resolving alias/ids from employee-services in batchFetchCandidateEmployeeData',
                e,
            );
        }
        setIsPageFetchingInitialNominationData(false);
    }

    async function getClearanceExcelData(): Promise<ClearanceRecordExcelColumns[]> {
        const excelData: ClearanceRecordExcelColumns[] = [];

        try {
            const excelRequestItems = filteredClearances.map((clearanceRecord) => {
                const requestItem: IClearanceRecordExcelRequest = {
                    clearanceRecordId: clearanceRecord.id,
                    contractId: clearanceRecord.contractId,
                    personnelId: clearanceRecord.personnelId,
                };

                return requestItem;
            });

            const batchedRequestItems = partitionArray(excelRequestItems, MaxExcelDataBatch);

            for (let i = 0; i < batchedRequestItems.length; i++) {
                const results: IClearanceRecordExcelResponse[] = await ClearanceClient.getClearanceRecordExcelDataByRequests(
                    authContext,
                    batchedRequestItems[i],
                );

                results.forEach((x) => {
                    // Terminated employees will actually not be found above (clearanceHolder) so we will just leave it blank except for the id
                    const excelRow: ClearanceRecordExcelColumns = {
                        personnelId: x.personnelId,
                        alias: x.alias?.toLowerCase() || '',
                        lastName: x.lastName || '',
                        firstName: x.firstName || '',
                        middleName: x.middleName || '',
                        employeeStatus: x.employeeStatus || '',
                        employeeTitle: x.employeeTitle || '',
                        organization: x.organization || '',
                        geographicLocation: x.geographicLocation || '',
                        officeLocation: x.officeLocation || '',
                        level1: x.l1?.toUpperCase() || '',
                        level2: x.l2?.toUpperCase() || '',
                        level3: x.l3?.toUpperCase() || '',
                        level4: x.l4?.toUpperCase() || '',
                        level5: x.l5?.toUpperCase() || '',
                        level6: x.l6?.toUpperCase() || '',
                        reportsTo: x.reportsTo || '',
                        contractID: x.contractId || '',
                        projectName: x.contractProjectName,
                        agency: x.agency || '',
                        customer: x.contractCustomer,
                        clearanceLevel: x.level,
                        clearanceType: x.clearanceType || '',
                        clearanceStatus: x.status,
                        expiresOn:
                            dateToLocalDate(x.clearanceExpirationDateTimeUTCMilliseconds) || '',
                        grantedOn: dateToLocalDate(x.grantDateUTCMilliseconds) || '',
                        investigatedOn: dateToLocalDate(x.investigationDateUTCMilliseconds) || '',
                        briefedOn: dateToLocalDate(x.briefDateUTCMilliseconds) || '',
                        debriefedDate: dateToLocalDate(x.debriefDateUTCMilliseconds) || '',
                        terminationDate: dateToLocalDate(x.terminationDateUTCMilliseconds) || '',
                        personStatusCode: x.personStatusCode || '',
                        continuousEvaluationType: x.continuousEvaluationType || '',
                        CEEnrollmentDate:
                            dateToLocalDate(x.continuousEvaluationEnrollmentDateUTCMilliseconds) ||
                            '',
                        CEUnenrollmentDate:
                            dateToLocalDate(
                                x.continuousEvaluationUnenrollmentDateUTCMilliseconds,
                            ) || '',
                        polygraphType: x.polygraphType?.toUpperCase() || '',
                        polygraphResult: x.polygraphResult?.toUpperCase() || '',
                        recordId: x.recordId,
                    };

                    excelData.push(excelRow);
                });
            }
        } catch (e) {
            setErrorOccurred(true);
            setMessage('Unable to download clearance records excel data.');
        }

        return excelData;
    }

    async function getClearanceHolderExcelData(): Promise<ClearanceHolderExcelColumns[]> {
        const excelData: ClearanceHolderExcelColumns[] = [];

        try {
            const uniqueIds = distinct(filteredClearances.map((x) => x.personnelId));

            const sortedIds = uniqueIds
                .sort((t1, t2) => {
                    return strCmp(t1, t2);
                })
                .sort((t1, t2) => {
                    if (isGUID(t1) && !isGUID(t2)) {
                        return 1;
                    }

                    if (isGUID(t2) && !isGUID(t1)) {
                        return -1;
                    }

                    return 0;
                });

            const batchedItems = partitionArray(sortedIds, MaxExcelDataBatch);

            for (let i = 0; i < batchedItems.length; i++) {
                const results: IClearanceHolderExcelResponse[] = await ClearanceClient.getClearanceHolderExcelDataByIds(
                    authContext,
                    batchedItems[i],
                );

                results.forEach((holder) => {
                    excelData.push({
                        id: holder.personnelId,
                        alias: holder.alias?.toLowerCase() ?? '',
                        lastName: holder.lastName ?? '',
                        middleName: holder.middleName ?? '',
                        firstName: holder.firstName ?? '',
                        email: holder.email ?? '',
                    });
                });
            }
        } catch (e) {
            setErrorOccurred(true);
            setMessage('Unable to download clearance holder excel data.');
        }

        return excelData;
    }

    function getExportFileName(): string {
        switch (excelExportType) {
            default:
            case ExportToExcelDataType.Clearances:
                return 'US_Gov_Clearance_Records';
            case ExportToExcelDataType.ClearanceHolders:
                return 'US_Gov_Clearance_Holders';
        }
    }

    function sortTableRows(rows: IClearanceRecord[]): IClearanceRecord[] {
        type R = IClearanceRecord;
        const determineSortFunc = (): ((r1: R, r2: R) => number) => {
            switch (sortColumn) {
                case 'Name':
                    return (r1: R, r2: R) => {
                        const e1 = employeeNames.get(r1?.personnelId);
                        const e2 = employeeNames.get(r2?.personnelId);

                        if (
                            e1?.type === PersonnelTypes.Terminated &&
                            e2?.type === PersonnelTypes.Terminated
                        )
                            return 0;
                        if (e1?.type === PersonnelTypes.Terminated) return 1;
                        if (e2?.type === PersonnelTypes.Terminated) return -1;

                        return sortAscending * strCmp(e1?.displayName || '', e2?.displayName || '');
                    };
                case 'Level':
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        strCmp(
                            ClearanceLevelsShortHand[r1.level] || r1.level,
                            ClearanceLevelsShortHand[r2.level] || r2.level,
                        );
                case 'Type':
                    return (r1: R, r2: R): number =>
                        sortAscending * strCmp(r1?.clearanceType, r2?.clearanceType);
                case 'Status':
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        strCmp(
                            ClearanceStatuses[r1.status] || r1.status,
                            ClearanceStatuses[r2.status] || r2.status,
                        );
                case 'Project Name':
                    return (r1: R, r2: R): number => {
                        return (
                            sortAscending *
                            strCmp(
                                contractMap.get(r1.contractId || ''),
                                contractMap.get(r2.contractId || ''),
                            )
                        );
                    };
                case 'Contract ID':
                    return (r1: R, r2: R): number =>
                        sortAscending * strCmp(r1?.contractId, r2?.contractId);
                case 'Agency':
                    return (r1: R, r2: R): number => {
                        const s1 = r1.agency ? getAgencyEnumValueFromKey(r1.agency) : '';
                        const s2 = r2.agency ? getAgencyEnumValueFromKey(r2.agency) : '';

                        return sortAscending * strCmp(s1, s2);
                    };
                case 'Expires on':
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        numCmp(
                            r1?.clearanceExpirationDateTimeUTCMilliseconds,
                            r2?.clearanceExpirationDateTimeUTCMilliseconds,
                        );
                case 'Granted on':
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        numCmp(r1?.grantDateUTCMilliseconds, r2?.grantDateUTCMilliseconds);
                case 'Investigated on':
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        numCmp(
                            r1?.investigationDateUTCMilliseconds,
                            r2?.investigationDateUTCMilliseconds,
                        );
                case 'Briefed on':
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        numCmp(r1?.briefDateUTCMilliseconds, r2?.briefDateUTCMilliseconds);
                case 'Debriefed on':
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        numCmp(r1?.debriefDateUTCMilliseconds, r2?.debriefDateUTCMilliseconds);
                case 'Continuous evaluation type':
                    return (r1: R, r2: R): number => {
                        return (
                            sortAscending *
                            strCmp(r1?.continuousEvaluationType, r2?.continuousEvaluationType)
                        );
                    };
                case 'Last Modified':
                    return (r1: R, r2: R) =>
                        sortAscending * numCmp(r1?.lastModified?.atUtc, r2?.lastModified?.atUtc);
                default:
                    return (r1: R, r2: R): number => 1;
            }
        };

        return (rows || []).sort(determineSortFunc());
    }

    useEffect(() => {
        setTableColumns(
            getClearanceRecordsTableColumns({
                contractMap,
                isExpandButtonClicked: !isExpandButtonShown || isTableExpanded,
                sortColumn,
                sortAscending: sortAscending === 1,
                sortColumnHandler,
            }),
        );
    }, [rawClearances, isTableExpanded, isExpandButtonShown, sortColumn, sortAscending]);

    useEffect(() => {
        function handleResize(): void {
            if (window.innerWidth >= filterWidth + expandedTableWidth) {
                setisExpandButtonShown(false);
            } else {
                setisExpandButtonShown(true);
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize(); // check the size of the screen on page load

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={pageStyle.page}>
            {showUploadModal && (
                <ClearanceBulkUploadModal
                    hideUploadModal={(): void => {
                        setShowUploadModal(false);
                    }}
                />
            )}

            <div className={pageStyle.filterAndTable}>
                <div className={pageStyle.filterWrapper}>
                    <div className={pageStyle.filter}>
                        <ClearanceRecordsFilter
                            clearanceContractMap={contractMap}
                            hierarchyRecords={hierarchyRecords}
                            rawClearances={rawClearances}
                            showCounts={isDoneLoadingClearances}
                            employeeNames={employeeNames}
                            employeeIdsWithFutureTerminationDates={
                                employeeIdsWithFutureTerminationDates
                            }
                        />
                    </div>
                    <Stack styles={{ root: { marginTop: 15, paddingBottom: 15 } }}>
                        {isTeachingBubbleOpen && (
                            <TeachingBubble
                                calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                                isWide={true}
                                styles={{ body: { lineHeight: '1.3' } }}
                                hasCloseButton={true}
                                onDismiss={(): void => {
                                    setTeachingBubbleOpen(false);
                                }}
                                hasCondensedHeadline={true}
                                target={`#aboutRecordsTeachingBubble`}
                                headline='About Clearance Records'>
                                This is a complete list of all clearance records in the system.
                            </TeachingBubble>
                        )}
                        <div>
                            <DefaultButton
                                style={{ height: 'auto', minHeight: '35px' }}
                                id='aboutRecordsTeachingBubble'
                                text='About Clearance Records'
                                onClick={(): void => setTeachingBubbleOpen(!isTeachingBubbleOpen)}
                            />
                        </div>
                    </Stack>
                </div>
                <div className={pageStyle.clearanceTable}>
                    {hasErrorOccurred && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel='Close'
                            onDismiss={closeMessage}
                            styles={{ root: { marginBottom: 15 } }}>
                            {errorMessage}
                        </MessageBar>
                    )}
                    <Spacer marginTop={20} />
                    <CandidatesPageHelp screeningPath={ScreeningPaths.UsGov} />
                    {rawClearances.length > 0 && (
                        <>
                            <ClearanceRecordsChart records={filteredClearances} />
                        </>
                    )}
                    <Spacer marginTop={20} />
                    <HorizontalBar>
                        <Stack.Item grow={1}>
                            <span className={pageStyle.recordsCount}>
                                {`${filteredClearances.length} of ${rawClearances.length} Records`}
                            </span>
                        </Stack.Item>
                        <Stack.Item grow={1}>
                            <ClearFiltersActionButton clearFunc={filtersContext.clearFilters} />
                        </Stack.Item>
                        <Stack.Item grow={100}>
                            <ActionButton
                                onClick={(): void => setShowUploadModal(true)}
                                iconProps={{ iconName: IconNames.BulkUpload }}
                                allowDisabledFocus>
                                Upload
                            </ActionButton>
                        </Stack.Item>
                        <Stack.Item grow={0.001}>
                            <Toggle
                                label={
                                    <div>
                                        Export Dataset{' '}
                                        <TooltipHost
                                            content={
                                                excelExportType &&
                                                excelExportType ===
                                                    ExportToExcelDataType.ClearanceHolders
                                                    ? 'Export Unique Clearance Holders'
                                                    : 'Export Displayed Clearance Records'
                                            }>
                                            <Icon iconName='Info' aria-label='Info tooltip' />
                                        </TooltipHost>
                                    </div>
                                }
                                styles={{ root: pageStyle.exportToggle }}
                                inlineLabel
                                onText='Unique Clearance Holders'
                                offText='Clearance Records'
                                onChange={(
                                    event: React.MouseEvent<HTMLElement>,
                                    checked?: boolean,
                                ): void => {
                                    setExcelExportType(
                                        checked
                                            ? ExportToExcelDataType.ClearanceHolders
                                            : ExportToExcelDataType.Clearances,
                                    );
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item grow={0.001}>
                            {isExpandButtonShown && (
                                // show the expand button if there isn't enough space for the user to see all the columns
                                <ActionButton
                                    iconProps={
                                        isTableExpanded
                                            ? { iconName: IconNames.CalculatorSubtract }
                                            : { iconName: IconNames.Add }
                                    }
                                    onClick={(): void => setIsTableExpanded(!isTableExpanded)}>
                                    {`${isTableExpanded ? 'Collapse' : 'Expand'} Columns`}
                                </ActionButton>
                            )}
                            <ExportToExcelButton<
                                ClearanceRecordExcelColumns | ClearanceHolderExcelColumns
                            >
                                getData={async (): Promise<
                                    ClearanceRecordExcelColumns[] | ClearanceHolderExcelColumns[]
                                > => {
                                    switch (excelExportType) {
                                        default:
                                        case ExportToExcelDataType.Clearances:
                                            return getClearanceExcelData();
                                        case ExportToExcelDataType.ClearanceHolders:
                                            return getClearanceHolderExcelData();
                                    }
                                }}
                                fileNamePrefix={getExportFileName()}
                                fileNameTimeFormat={TimeFormats.MM_DD_YYYY_HHmm}
                                formatHeader={true}
                                formatType={excelFormatHeader.microsoftBrandCase}
                            />
                        </Stack.Item>
                    </HorizontalBar>
                    <Table<IClearanceRecord>
                        rows={sortTableRows(filteredClearances)}
                        isFetchingData={isPageFetchingInitialNominationData}
                        shimmerLabel='Loading initial nominations...'
                        tableColumns={tableColumns}
                        tableName='Clearance Records'
                        detailsHeaderStyles={columnHeaderStyles}
                    />
                </div>
            </div>
        </div>
    );

    function closeMessage(): void {
        setErrorOccurred(false);
        setMessage('');
    }
}

type ClearanceRecordExcelColumns = {
    personnelId: string;
    alias: string;
    lastName: string;
    middleName: string;
    firstName: string;
    employeeStatus: string;
    employeeTitle: string;
    organization: string;
    geographicLocation: string;
    officeLocation: string;
    level1: string;
    level2: string;
    level3: string;
    level4: string;
    level5: string;
    level6: string;
    reportsTo: string;
    contractID: string;
    clearanceLevel: string;
    customer: string;
    clearanceStatus: string;
    agency: string;
    grantedOn: string;
    investigatedOn: string;
    briefedOn: string;
    debriefedDate: string;
    continuousEvaluationType?: string;
    CEEnrollmentDate?: string;
    CEUnenrollmentDate?: string;
    expiresOn: string;
    clearanceType: string;
    personStatusCode: string;
    polygraphResult: string;
    polygraphType: string;
    projectName: string;
    terminationDate: string;
    recordId: string;
};

type ClearanceHolderExcelColumns = {
    id: string;
    alias: string;
    lastName: string;
    middleName: string;
    firstName: string;
    email: string;
};

enum ExportToExcelDataType {
    Clearances = 'Clearances',
    ClearanceHolders = 'ClearanceHolders',
}
