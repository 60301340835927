import React, { useEffect, useState, useContext } from 'react';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { manageTitle } from 'assets/constants/global-constants';
import { AuthContext } from 'contexts/auth-context';
import EligibilityClient, {
    IAttribute,
    IEligibility,
    IEligibilityRequest,
} from 'clients/eligibility-client';
import {
    Stack,
    TextField,
    ChoiceGroup,
    IChoiceGroupOption,
    Separator,
    mergeStyles,
    mergeStyleSets,
} from '@fluentui/react';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { renderRequiredAttributes } from 'components/eligibilities/eligibilities-utils';
import Badge from 'components/common/badge';
import { BadgeColorHex } from 'assets/constants/global-colors';
import { assignmentsAttributesUrl } from 'components/eligibilities/eligibilities-breadcrumbs';
import { IBasicEmployee } from 'clients/employee-client';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import { globalStyles, globalSeparatorStyles } from 'assets/styles/global-styles';
import { Dictionary, IconNames } from 'assets/constants/global-constants';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';

interface IManageRequestModalActionButtonProps {
    request?: IEligibilityRequest;
    eligibility?: IEligibility;
    attributesDict: Dictionary<IAttribute>;
    personnelId: string;
    onManageRequestConcluded: (conclusion: ModalConclusion, result?: IEligibilityRequest) => void;
}

const MAX_COMMENT_LEN = 250;

export default function ManageRequestModalActionButton(
    props: IManageRequestModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const [errorMsg, setErrorMsg] = useState<string>('');
    const [selectedKey, setSelectedKey] = useState<string | undefined>();
    const [comment, setComment] = useState<string | undefined>();
    const [redirectTo, setRedirectTo] = useState<string>('');
    const [employeeBasicData, setEmployeeBasicData] = useState<IBasicEmployee | undefined>();
    const [willMeetEligibilityRequirements, setWillMeetEligibilityRequirements] = useState<
        boolean | null
    >(null);
    const [isEligibilitiesLoaded, setIsEligibilitiesLoaded] = useState<boolean>(false);
    const [problemFetching, setProblemFetching] = useState<string>('');

    const initInputs = (): void => {
        if (props.eligibility) {
            fetchEligibilities();
        }
        clearInputs();
    };

    // We need to refetch eligibilities here in the case
    // where a user will will click "Manage Employee Attributes" button
    // and navigate away from the page. Since that page opens in a new tab
    // there is a chance the user will come back this page and open the modal again
    // and expect to see updated data
    const fetchEligibilities = async (): Promise<void> => {
        try {
            const eligibilities = await EligibilityClient.getPersonnelAvailableEligibilities(
                authContext,
                props.personnelId,
            );

            if (props.eligibility) {
                setWillMeetEligibilityRequirements(
                    meetsEligibilityRequirement(props.eligibility, eligibilities),
                );
            }
        } catch {
            setProblemFetching('Error loading eligibility');
        } finally {
            setIsEligibilitiesLoaded(true);
        }
    };

    const meetsEligibilityRequirement = (
        eligibility: IEligibility,
        eligibilities: IEligibility[],
    ): boolean => {
        const eligCodes: string[] = [];
        eligibilities.forEach((e) => eligCodes.push(e.eligibilityCode));

        return eligCodes.includes(eligibility.eligibilityCode);
    };

    const clearInputs = (): void => {
        setSelectedKey(undefined);
        setComment(undefined);
        setErrorMsg('');
    };

    const isCommentValidForEntry = (str: string): boolean => str.length < MAX_COMMENT_LEN;

    const isCommentValidForSubmit = (): boolean => !comment || comment.length <= MAX_COMMENT_LEN;

    const disableSubmit = (): boolean => {
        const isDisable1 = !isCommentValidForSubmit();
        const isDisable2 = !selectedKey || !/^(approve|deny)$/.test(selectedKey);
        const isDisable4 = !props.request?.id;
        const isDisable3 = !props.eligibility?.id;
        return isDisable1 || isDisable2 || isDisable3 || isDisable4;
    };

    const onManageRequestSubmit = async (): Promise<IEligibilityRequest> => {
        try {
            return await EligibilityClient.manageEligibilityRequest(
                authContext,
                {
                    // The following typecasts are safe because of the
                    // checks that the function disableSubmit performs.
                    requestBody: { comment },
                    eligibilityId: props.eligibility?.id as string,
                    eligibilityRequestId: props.request?.id as string,
                },
                selectedKey as 'approve' | 'deny',
            );
        } catch (e) {
            throw `Encountered error trying to manage the request`;
        }
    };

    const approvalOptions: IChoiceGroupOption[] = [
        { key: 'deny', text: 'Deny Request' },
        { key: 'approve', text: 'Approve Request', disabled: !willMeetEligibilityRequirements },
    ];

    const onApprovalClick = (
        ev?: React.SyntheticEvent<HTMLElement>,
        option?: IChoiceGroupOption,
    ): void => {
        if (ev?.type === 'change') {
            setSelectedKey(option?.key);
        }
    };

    const onCommentChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        str?: string,
    ): void => {
        if (isCommentValidForEntry(str ?? '')) {
            setComment(str);
        }
    };

    const yesNoBadge = (yesNo: boolean | null): JSX.Element => {
        return (
            <Badge
                text={yesNo ? 'YES' : 'NO'}
                backgroundColor={yesNo ? BadgeColorHex.GREEN : BadgeColorHex.RED}
            />
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const oneRow = (title: string, content: any): JSX.Element => {
        return (
            <Stack horizontal wrap>
                <Stack.Item className={styles.itemNameColumn}>
                    <strong>{title}</strong>
                </Stack.Item>
                <Stack.Item className={styles.itemValueColumn}>
                    <strong>{content}</strong>
                </Stack.Item>
            </Stack>
        );
    };

    const onEmployeeBasicData = (data: IBasicEmployee | undefined): void => {
        setEmployeeBasicData(data);
    };

    const manageEmployeeAttributes = async (): Promise<void> => {
        if (employeeBasicData) {
            setRedirectTo(assignmentsAttributesUrl(employeeBasicData.onPremisesSamAccountName));
        }
    };

    useEffect(() => {
        if (redirectTo) {
            setRedirectTo('');
            window.open(redirectTo, '_blank');
        }
    }, [redirectTo]);

    return (
        <ModalActionButton
            text={manageTitle}
            iconName={IconNames.TaskManager}
            errorMsg={errorMsg}
            modalTitle='Review Eligibility Request'
            modalTitleIcon=''
            enableSubmit={!disableSubmit()}
            submitButtonText='Save'
            submitButtonIcon=''
            onButtonClick={initInputs}
            onRedirect={manageEmployeeAttributes}
            onRedirectText={'Manage Employee Attributes'}
            closeOnRedirect={true}
            onSubmit={onManageRequestSubmit}
            onModalConcluded={props.onManageRequestConcluded}>
            <PageLoadingSpinner
                label='Loading requests...'
                ariaLive='assertive'
                isLoading={!isEligibilitiesLoaded && willMeetEligibilityRequirements !== null}
                labelPosition='left'>
                <ProblemLoadingMsg problemLoadingMsg={problemFetching}></ProblemLoadingMsg>
                <Stack className={styles.tableStack}>
                    {oneRow(
                        'Employee',
                        <EmployeeBasicHoverCard
                            personnelId={props.request?.personnelId}
                            onEmployeeBasicData={onEmployeeBasicData}
                        />,
                    )}
                    {oneRow('Eligibility', props.eligibility?.eligibilityCode)}
                    {oneRow(
                        'Required Attributes',
                        renderRequiredAttributes(
                            props.eligibility?.requiredAttributes,
                            props.attributesDict,
                        ),
                    )}
                    {oneRow('Meets Requirements', yesNoBadge(willMeetEligibilityRequirements))}
                    {oneRow('Business Justification', props.request?.justification)}
                    <Separator styles={separatorStyle} />
                    {oneRow(
                        'Approval',
                        <ChoiceGroup
                            className={styles.approvalChoice}
                            selectedKey={selectedKey}
                            options={approvalOptions}
                            onChange={onApprovalClick}
                        />,
                    )}
                    <br />
                    {oneRow(
                        'Moderator Comments',
                        <TextField
                            value={comment || ''}
                            multiline
                            resizable={false}
                            ariaLabel='Moderator Comments'
                            placeholder={`Enter moderator comment (${MAX_COMMENT_LEN} char max)`}
                            onChange={onCommentChange}
                        />,
                    )}
                </Stack>
            </PageLoadingSpinner>
        </ModalActionButton>
    );
}

const styles = mergeStyleSets({
    tableStack: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        lineHeight: 35,
    },
    itemNameColumn: mergeStyles(
        {
            width: 200,
            flexShrink: 0,
            alignContent: 'flex-start',
        },
        globalStyles.boldFont,
    ),
    itemValueColumn: {
        width: 500,
        alignContent: 'flex-start',
    },
    approvalChoice: {
        lineHeight: 'initial',
    },
});

const separatorStyle = mergeStyleSets({
    ...globalSeparatorStyles,
    root: {
        width: '100%',
    },
});
