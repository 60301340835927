import React, { ReactNode } from 'react';
import { ProblemLoading } from 'components/common/problem-loading/problem-loading';

interface IProblemLoadingPageProps {
    isProblemLoadingPage: boolean;
    children?: ReactNode;
}

export function ProblemLoadingPage(props: IProblemLoadingPageProps): JSX.Element {
    return (
        <ProblemLoading isProblemLoadingPage={props.isProblemLoadingPage}>
            {props.children}
        </ProblemLoading>
    );
}
