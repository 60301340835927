import React from 'react';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { globalStyles } from 'assets/styles/global-styles';
import { mergeStyleSets } from '@fluentui/react';
import { IGroupPolicyViolationRule } from 'clients/group-client';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { doNothing, doNothingAsync } from 'utils/misc-utils';
import { CoreEmployeeHoverCardFromGraph } from 'components/core/common/employee-card/core-employee-hover-card';

interface IMemberMoreInfoModalActionButtonProps {
    policyViolationRule: IGroupPolicyViolationRule | undefined;
    buttonStyle?: string;
}

function ManageGroupMemberMoreInfoModalActionButton(
    props: IMemberMoreInfoModalActionButtonProps,
): JSX.Element {
    return (
        <ModalActionButton<void>
            text={'learn more'}
            iconName={``}
            modalTitle={`${props.policyViolationRule?.ruleName}`}
            enableSubmit={true}
            shouldHideCancelButton={true}
            buttonStyle={props.buttonStyle}
            onButtonClick={doNothing}
            submitButtonText={'Close'}
            onModalConcluded={doNothing}
            onSubmit={doNothingAsync}>
            <div className={styles.container}>
                <h4 className={globalStyles.largeFont}>Remediation Steps</h4>
                <ul>
                    <li>{props.policyViolationRule?.ruleDescription}</li>
                    <li className={styles.nestedListItem}>
                        {props.policyViolationRule && (
                            <ReactMarkdown
                                rehypePlugins={[rehypeRaw]}
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    em(props): JSX.Element {
                                        return (
                                            <CoreEmployeeHoverCardFromGraph
                                                oid={props.children[0]?.toString()}
                                            />
                                        );
                                    },
                                }}>
                                {props.policyViolationRule.remediationSteps}
                            </ReactMarkdown>
                        )}
                    </li>
                </ul>
            </div>
        </ModalActionButton>
    );
}

export default ManageGroupMemberMoreInfoModalActionButton;

const styles = mergeStyleSets({
    container: {
        maxWidth: 800,
    },
    nestedListItem: {
        listStyle: 'none',
    },
});
