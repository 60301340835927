import React from 'react';
import { TableCell } from 'components/common/table';
import { IColumn } from '@fluentui/react';
import { lastColumnStylesButton } from 'assets/styles/list-styles';
import { maxWidthCoeff, Dictionary } from 'assets/constants/global-constants';
import { IEligibilityRequest, IEligibility, IAttribute } from 'clients/eligibility-client';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { timeToString, TimeFormats } from 'utils/time-utils';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import ManageRequestModalActionButton from 'components/eligibilities/eligibilities/view/manage-request-modal-action-button';
import { ISortableColumns } from 'utils/sort-utils';

interface IEligibilitysColumns extends ISortableColumns {
    eligibility: IEligibility | undefined;
    attributesDict: Dictionary<IAttribute>;
    onManageRequestConcluded: (
        modalConclusion: ModalConclusion,
        result?: IEligibilityRequest,
    ) => void;
    meetsEligibilityRequirement: (personnelId: string, eligibility?: IEligibility) => boolean;
}

const yesNoOrNothing = (value: boolean | undefined): string => {
    if (value === undefined) return '';
    else return value ? 'Yes' : 'No';
};

export function getEligibilityRequestsTableColumns(params: IEligibilitysColumns): IColumn[] {
    const columnWidths = {
        employee: 200,
        meetsRequirement: 100,
        dateRequested: 100,
        action: 85,
    };

    const columns: IColumn[] = [
        {
            key: 'Employee',
            name: 'Employee',
            ariaLabel: 'Employee',
            minWidth: columnWidths.employee,
            maxWidth: columnWidths.employee * maxWidthCoeff,
            onRender: (row: IEligibilityRequest): JSX.Element => (
                <TableCell key={`employee-${row.id}`}>
                    <EmployeeBasicHoverCard personnelId={row.personnelId} />
                </TableCell>
            ),
        },
        {
            key: 'Meets Requirements',
            name: 'Meets Requirements',
            ariaLabel: 'Meets Requirements',
            minWidth: columnWidths.meetsRequirement,
            maxWidth: columnWidths.meetsRequirement * maxWidthCoeff,
            onRender: (row: IEligibilityRequest): JSX.Element => {
                return (
                    <TableCell key={`meets-${row.id}`}>
                        {yesNoOrNothing(
                            params.meetsEligibilityRequirement(row.personnelId, params.eligibility),
                        )}
                    </TableCell>
                );
            },
        },
        {
            key: 'Date Requested',
            name: 'Date Requested',
            ariaLabel: 'Date Requested',
            minWidth: columnWidths.dateRequested,
            maxWidth: undefined,
            isSorted: params.sortColumn === 'Date Requested',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Date Requested');
            },
            onRender: (row: IEligibilityRequest): JSX.Element => {
                return (
                    <TableCell key={`date-${row.id}`}>
                        {timeToString(row.createdAt * 1000, TimeFormats.MMMDDYYYY_hmmA)}
                    </TableCell>
                );
            },
        },
        {
            key: 'Action',
            name: 'Action',
            ariaLabel: 'Action',
            minWidth: columnWidths.action,
            maxWidth: columnWidths.action,
            ...lastColumnStylesButton,
            onRender: (row: IEligibilityRequest): JSX.Element => {
                return (
                    <TableCell key={`manage-${row.id}`}>
                        <ManageRequestModalActionButton
                            eligibility={params.eligibility}
                            request={row}
                            attributesDict={params.attributesDict}
                            personnelId={row.personnelId}
                            onManageRequestConcluded={params.onManageRequestConcluded}
                        />
                    </TableCell>
                );
            },
        },
    ];
    return columns;
}
