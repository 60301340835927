import React from 'react';
import { IEscalation, IReviewPeriod } from 'clients/sca-client';
import { reviewStatusBadge } from 'components/sca/sca-utils';
import {
    dateToLocalShortDateFormat,
    dateToLocalShortDateWithDayOfWeekFormat,
    getDayShortName,
} from 'utils/time-utils';
import { globalStyles } from 'assets/styles/global-styles';
import { IColumn, Stack, mergeStyles, IStyle } from '@fluentui/react';
import { findManagerialLevel } from 'components/sca/sca-constants';
import { Table } from 'components/common/table';

interface IPeriodDetailsPros {
    reviewPeriod: IReviewPeriod | undefined;
}

export default function DisplayPeriodDetails(props: IPeriodDetailsPros): JSX.Element {
    const dateRangeString = (
        date1Param: number | undefined,
        date2Param: number | undefined,
    ): string => {
        const date1Str = dateToLocalShortDateWithDayOfWeekFormat(date1Param);
        const date2Str = dateToLocalShortDateWithDayOfWeekFormat(date2Param);
        return `${date1Str} to ${date2Str}`;
    };

    const updateTimeToJSX = (
        msg: string,
        time: number | undefined,
        by: string | undefined,
    ): JSX.Element => {
        if (!time) {
            return <></>;
        }
        let result: string = msg + ' ';
        const date = new Date(time);
        result += getDayShortName(date) + ' ' + dateToLocalShortDateFormat(date);
        if (by) {
            result += ` by ${by}`;
        }
        return <div>{result}</div>;
    };

    const lastModifiedOn = (reviewPeriod: IReviewPeriod): JSX.Element => {
        if (
            reviewPeriod.lastModifiedTimestampUTC &&
            reviewPeriod.lastModifiedTimestampUTC !== reviewPeriod.creationTimestampUTC
        ) {
            return (
                <div>
                    {updateTimeToJSX(
                        'Last updated on',
                        reviewPeriod.lastModifiedTimestampUTC * 1000,
                        reviewPeriod.lastModifiedBy,
                    )}
                </div>
            );
        } else {
            return <></>;
        }
    };

    const createdOn = (reviewPeriod: IReviewPeriod): JSX.Element => {
        if (reviewPeriod?.creationTimestampUTC === undefined) return <></>;
        return (
            <div>
                {updateTimeToJSX(
                    'Created on',
                    reviewPeriod?.creationTimestampUTC * 1000,
                    reviewPeriod.createdBy,
                )}
            </div>
        );
    };

    const renderPeriodUpdates = (reviewPeriod: IReviewPeriod | undefined): JSX.Element => {
        if (reviewPeriod === undefined) {
            return <></>;
        }
        return (
            <>
                {lastModifiedOn(reviewPeriod)}
                {createdOn(reviewPeriod)}
            </>
        );
    };

    const renderEmailNotifications = (
        escalations: IEscalation[] | undefined,
    ): JSX.Element | string => {
        if (escalations === undefined || (escalations?.length ?? 0) === 0) {
            return 'No email notification has been set';
        }
        const emailNotificationColumns: IColumn[] = [
            {
                key: 'Date',
                name: 'Date',
                ariaLabel: 'Date',
                minWidth: 100,
                maxWidth: 100,
                onRender: (row: IEscalation): JSX.Element => {
                    return <span>{dateToLocalShortDateFormat(row.date * 1000)}</span>;
                },
            },
            {
                key: 'Level',
                name: 'Level',
                ariaLabel: 'Level',
                minWidth: 200,
                maxWidth: 200,
                onRender: (row: IEscalation): JSX.Element => {
                    const managerialLevel = findManagerialLevel(row.hierarchy);
                    return <span>{managerialLevel?.text}</span>;
                },
            },
        ];
        return (
            <Table
                rows={escalations}
                rootStyle={emailNotificationsRootStyle}
                tableColumns={emailNotificationColumns}
                isFetchingData={false}
                tableName='Email Notifications'
            />
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const oneTableRow = (itemName: string, item: any, nameColumnStyle?: IStyle): JSX.Element => {
        return (
            <Stack.Item>
                <Stack horizontal>
                    <Stack.Item className={mergeStyles(itemNameColumnStyle, nameColumnStyle)}>
                        {itemName}
                    </Stack.Item>
                    <Stack.Item className={itemValueColumnStyle}>{item}</Stack.Item>
                </Stack>
            </Stack.Item>
        );
    };

    return (
        <Stack className={reviewDetailsStyle}>
            {oneTableRow('Review Id', props.reviewPeriod?.id)}
            {oneTableRow('Status', reviewStatusBadge(props.reviewPeriod))}
            {oneTableRow('Review Title', props.reviewPeriod?.title)}
            {oneTableRow(
                'Review Period',
                dateRangeString(props.reviewPeriod?.startDateUTC, props.reviewPeriod?.endDateUTC),
            )}
            {oneTableRow(
                'Eligibility Date',
                dateToLocalShortDateWithDayOfWeekFormat(props.reviewPeriod?.eligibilityDateUTC),
            )}
            {oneTableRow(
                'Approval Period',
                dateRangeString(
                    props.reviewPeriod?.approvalStartDateUTC,
                    props.reviewPeriod?.approvalEndDateUTC,
                ),
            )}
            {oneTableRow('Updates', renderPeriodUpdates(props.reviewPeriod))}
            {oneTableRow(
                'Emails',
                renderEmailNotifications(props.reviewPeriod?.escalations),
                props.reviewPeriod?.escalations?.length ? emailRowNameStyle : undefined,
            )}
        </Stack>
    );
}

const itemNameColumnStyle = mergeStyles(
    {
        width: 130,
        height: 30,
        alignContent: 'flex-start',
    },
    globalStyles.boldFont,
);
const itemValueColumnStyle = mergeStyles({
    minWidth: 400,
    minHeight: 30,
    alignContent: 'flex-start',
});

const reviewDetailsStyle = mergeStyles({
    marginTop: 10,
});

const emailNotificationsRootStyle = mergeStyles({ marginTop: -16, marginLeft: -12 });

const emailRowNameStyle = mergeStyles({
    marginTop: 10,
});
