import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    FontSizes,
    FontWeights,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Modal,
    // eslint-disable-next-line no-restricted-imports
    Link,
    PrimaryButton,
    mergeStyles,
    DefaultButton,
    Icon,
    Separator,
} from '@fluentui/react';
import MyScreeningsTable from 'components/screening/cloud-screening/my-screenings/my-screenings-table';
import CloudScreeningClient, {
    ICloudScreening,
    IScopeRecord,
} from 'clients/cloud-screening-client';
import { CloudScreeningUserType } from 'utils/cloud-screening-utils';
import { AuthContext } from 'contexts/auth-context';
import RequestCloudScreeningModal from 'components/screening/cloud-screening/my-screenings/request-cloud-screening-modal';
import EmployeeClient, { IBasicEmployee, IEmployee } from 'clients/employee-client';
import { IStep, ProcessOverview } from 'components/common/process-overview';
import { generalIsMountedCodes } from 'utils/misc-utils';
import { UserContext } from 'contexts/user-context';
import {
    getCloudScreeningAction,
    CloudScreeningAction,
    getCloudScreeningStatus,
    cloudScreeningStatuses,
    FIRST_ADVANTAGE_COUNTRY_CODES,
    checkInternationalException,
    createScopesElementFromRecords,
} from 'components/screening/cloud-screening/cloud-screening-utils';
import { Location } from 'history';
import CloudScreeningAdminImpersonation from 'components/screening/cloud-screening/admin/cloud-screening-admin-impersonation';
import { IconNames } from 'assets/constants/global-constants';

export interface MyCloudScreeningsPageProps {
    pageName: string;
    location: Location;
}

enum ProcessState {
    NotRequested,
    Requested,
    Complete,
}

function transformProcessStepData(data: IStep[], curState: ProcessState): void {
    const curIdx = curState as number;
    let i = 0;
    while (i < data.length && i < curIdx) {
        data[i++].time = 'past';
    }
    if (curIdx < data.length) {
        data[i++].time = 'current';
    }
    while (i < data.length) {
        data[i++].time = 'future';
    }
}

export default function MyCloudScreeningsPage(props: MyCloudScreeningsPageProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    const [viewedEmployee, setViewedEmployee] = useState<IEmployee>(userContext.employeeRecord);
    const [viewedEmployeeSecure, setViewedEmployeeSecure] = useState<IEmployee | undefined>(
        userContext.employeeRecord,
    );
    const [viewedEmployeeManager, setViewedEmployeeManager] = useState<IBasicEmployee>();
    const [myScreenings, setMyScreenings] = useState<ICloudScreening[]>();
    const [myExpiredScreenings, setMyExpiredScreenings] = useState<ICloudScreening[]>();
    const [showPastScreenings, setShowPastScreenings] = useState<boolean>(false);
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
    const [showRequestBtn, setShowRequestBtn] = useState<boolean>(false);
    const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
    const [isFirstAdvantage, setIsFirstAdvantage] = useState<boolean>(false);
    const [isInternationalException, setIsInternationalException] = useState<boolean>(false);
    const [vendorProcessStepData, setVendorProcessStepData] = useState<IStep[]>();
    const [fteProcessStepData, setFteProcessStepData] = useState<IStep[]>();
    const [myScopes, setMyScopes] = useState<IScopeRecord[]>([]);

    const VendorProcessStepData: IStep[] = [
        {
            label: 'Request a Screen from Vendor Company',
            description:
                'Vendor screens are not conducted by Microsoft. Request a screening from your vendor company manager or HR contact.',
            time: 'past',
        },
        {
            label: 'Fill out Attestation',
            description: (
                <p>
                    Once the screening is completed, have your vendor company fill out an
                    attestation form with&nbsp;
                    <a href='mailto:screensu@microsoft.com'>screensu@microsoft.com</a>.
                    Self-attestation are not permitted.
                </p>
            ),
            time: 'current',
        },
        {
            label: 'Screening Complete',
            description:
                'Once your screening is complete and attestation successfully processed, it will be valid for two years. Your screening will show throughout all systems within 72 hours.',
            time: 'future',
        },
    ];

    const FTEProcessStepData: IStep[] = [
        {
            label: 'Request',
            description: (
                <span>
                    Request a screen to initiate the process.&nbsp;
                    {showRequestBtn && !isInternationalException && (
                        <Link to='#' onClick={(): void => setShowRequestModal(true)}>
                            Click here to get started.
                        </Link>
                    )}
                </span>
            ),
            time: 'past',
        },
        {
            label: 'Fill Out Form + Wait',
            description:
                'The external third party provider (HireRight or First Advantage) will reach out to you in 2-5 days after the initial request with a short form to fill out. You MUST submit the form in less than 10 days, failure to do so will terminate your screen.',
            time: 'current',
        },
        {
            label: 'Screening Complete',
            description:
                'After submitting the form, it takes 14 days or less for the vendor team to complete the screening. Upon completion your new screening will populate this site and all dependent downstream systems.',
            time: 'future',
        },
    ];

    async function populateViewedEmployeeSecure(isMountedFunc: () => boolean): Promise<void> {
        let viewedEmployeeSecureVar: IEmployee | undefined;
        if (viewedEmployee.id === userContext.employeeRecord.id) {
            viewedEmployeeSecureVar = await EmployeeClient.getEmployeeByAliasOrId(
                authContext,
                viewedEmployee.id,
            );
        } else {
            // for admin view
            let viewedEmployeeManagerVar: IBasicEmployee | undefined = undefined;
            try {
                const managerResult = await EmployeeClient.getBasicEmployeesByAlias(authContext, [
                    viewedEmployee.reportsToEmailName,
                ]);
                viewedEmployeeManagerVar = managerResult[0];
            } catch {
                console.error("Error fetching employee's manager info");
            }
            setViewedEmployeeManager(viewedEmployeeManagerVar);
            if (viewedEmployeeManagerVar) {
                try {
                    const managerDirectReports = await CloudScreeningClient.getDirectReports(
                        authContext,
                        viewedEmployeeManagerVar.id,
                    );
                    viewedEmployeeSecureVar = managerDirectReports.find(
                        (report) => report.id === viewedEmployee.id,
                    );
                } catch {
                    console.error('Error fetching direct reports');
                }
            }
        }

        if (isMountedFunc() && viewedEmployeeSecureVar) {
            setViewedEmployeeSecure(viewedEmployeeSecureVar);
            setIsFirstAdvantage(
                FIRST_ADVANTAGE_COUNTRY_CODES.has(viewedEmployeeSecureVar.locationAreaCode),
            );
            setIsInternationalException(checkInternationalException(viewedEmployeeSecureVar));
        }
    }

    async function populateMyScreenings(isMountedFunc: () => boolean): Promise<void> {
        if (!viewedEmployeeSecure) return;

        setIsFetchingData(true);
        let allScreeningsForUser: ICloudScreening[] = [];
        if (viewedEmployeeSecure.id === userContext.employeeRecord.id) {
            allScreeningsForUser = await CloudScreeningClient.getAllMyScreenings(authContext);
        } else if (viewedEmployeeManager) {
            // for admin view
            const allScreeningsOfManager = await CloudScreeningClient.getAllScreeningsByManagerId(
                authContext,
                viewedEmployeeManager.id,
            );
            allScreeningsForUser = allScreeningsOfManager.filter(
                (screening) => screening.personnelId === viewedEmployeeSecure.id,
            );
        } else {
            console.error(
                `populateMyScreenings: Viewed manager not populated for admin view of my-screenings:${viewedEmployee.alias}`,
            );
        }
        let hasScreeningExpiringPast120Days = false;
        let hasIncompleteScreening = false;
        let curProcessSate = ProcessState.NotRequested;
        const expired: ICloudScreening[] = [];
        const notExpired: ICloudScreening[] = [];
        const todaysDate = new Date();
        todaysDate.setHours(0, 0, 0, 0);

        const status = getCloudScreeningStatus(viewedEmployeeSecure);
        if (status === cloudScreeningStatuses.screened) {
            curProcessSate = ProcessState.Complete;
            hasScreeningExpiringPast120Days = true;
        }

        const action = getCloudScreeningAction(viewedEmployeeSecure, allScreeningsForUser);
        if (action === CloudScreeningAction.AlreadyRequested) {
            hasIncompleteScreening = true;
            curProcessSate = ProcessState.Requested;
        }

        allScreeningsForUser.forEach((screen) => {
            if (screen.dateExpires && new Date(screen.dateExpires.atUtc) < todaysDate) {
                expired.push(screen);
            } else {
                notExpired.push(screen);
            }
        });

        if (isMountedFunc()) {
            transformProcessStepData(FTEProcessStepData, curProcessSate);
            setFteProcessStepData(FTEProcessStepData);
            transformProcessStepData(VendorProcessStepData, curProcessSate);
            setVendorProcessStepData(VendorProcessStepData);

            setShowRequestBtn(
                (notExpired.length === 0 || !hasScreeningExpiringPast120Days) &&
                    !hasIncompleteScreening,
            );
            setMyScreenings(notExpired);
            setMyExpiredScreenings(expired);
            setIsFetchingData(false);
        }
    }

    async function populateMyScopes(isMountedFunc: () => boolean): Promise<void> {
        if (!viewedEmployeeSecure) return;

        let continuationToken: string | undefined;
        const scopes: IScopeRecord[] = [];

        if (viewedEmployeeSecure.id === userContext.employeeRecord.id) {
            do {
                const results = await CloudScreeningClient.getMyScopes(
                    authContext,
                    userContext,
                    continuationToken,
                );
                continuationToken = results.continuationToken;
                if (results.results) {
                    results.results.forEach((x) => scopes.push(x));
                }
            } while (continuationToken && isMountedFunc());
        } else if (viewedEmployeeManager) {
            // for admin view
            do {
                const results = await CloudScreeningClient.getScopesForManager(
                    authContext,
                    viewedEmployeeManager.id,
                    [viewedEmployeeSecure.id],
                    continuationToken,
                );
                continuationToken = results.continuationToken;
                if (results.results) {
                    results.results.forEach((x) => scopes.push(x));
                }
            } while (continuationToken && isMountedFunc());
        } else {
            console.error(
                `populateMyScopes: Viewed manager not populated for admin view of my-screenings:${viewedEmployee.alias}`,
            );
        }

        if (isMountedFunc()) {
            setMyScopes(scopes);
        }
    }

    useEffect(() => {
        return generalIsMountedCodes([populateMyScreenings, populateMyScopes]);
    }, [viewedEmployeeSecure]);

    useEffect(() => {
        return generalIsMountedCodes([populateViewedEmployeeSecure]);
    }, [viewedEmployee]);

    function onCloseRequestModal(screen?: ICloudScreening): void {
        if (myScreenings && screen) {
            const newMyScreenings = [screen, ...myScreenings];
            setMyScreenings(newMyScreenings);
            setShowRequestBtn(false);
        }
        setShowRequestModal(false);
    }

    const isRequestScreeningDisplayed = useCallback(
        (employee?: IEmployee): boolean => {
            const allScreenings: ICloudScreening[] = [
                ...(myScreenings ?? []),
                ...(myExpiredScreenings ?? []),
            ];
            return (
                getCloudScreeningAction(employee, allScreenings) ===
                    CloudScreeningAction.RequestScreening && showRequestBtn
            );
        },
        [myScreenings, myExpiredScreenings, showRequestBtn],
    );

    // Permanent fix used in table columns to show request button, not just showRequestBtn.
    // https://dev.azure.com/msazure/Microsoft%20Personnel/_workitems/edit/26456164/
    const canCreateRequest = useMemo(() => {
        return isRequestScreeningDisplayed(viewedEmployeeSecure) && !isInternationalException;
    }, [viewedEmployeeSecure, isInternationalException, isRequestScreeningDisplayed]);

    function DaysLeftBeforeNextRequest(): JSX.Element {
        if (!myScreenings) {
            return <></>;
        }

        const sortedRequests = myScreenings
            .filter((x) => x.state === 'Requested')
            .sort((a, b) => a.dateCreated.atUtc - b.dateCreated.atUtc)
            .reverse();

        if (!sortedRequests.length) {
            return <></>;
        }

        const mostRecent = new Date(sortedRequests[0].dateCreated.atUtc);

        const mostRecentPlusTenDaysZeroedOutTime = new Date(
            mostRecent.getFullYear(),
            mostRecent.getMonth(),
            mostRecent.getDate(),
            0,
            0,
            0,
        );
        mostRecentPlusTenDaysZeroedOutTime.setDate(
            mostRecentPlusTenDaysZeroedOutTime.getDate() + 10,
        );

        const today = new Date();
        const msDifference = mostRecentPlusTenDaysZeroedOutTime.valueOf() - today.valueOf();
        const dayDifference = Math.ceil(msDifference / (1000 * 3600 * 24));
        if (dayDifference < 1 || dayDifference > 10) {
            return <></>;
        }

        return (
            <>
                Cloud Screen applications MUST be completed in 10 days! You can request a new
                application in <b>{dayDifference}</b> {dayDifference === 1 ? 'day' : 'days'} if this
                one expires.
            </>
        );
    }

    function SupportTextLinks(): JSX.Element {
        return (
            <>
                <h3 className={styles.requirementsHeader2}>Support Resources</h3>
                <div className={styles.resourcesContainer}>
                    <div
                        className={mergeStyles(
                            styles.resourceLabelWithLink,
                            styles.boldedResourceLabelWithLink,
                        )}>
                        <label id='faq' className={styles.resourceLabel}>
                            Frequently Asked Questions:
                        </label>
                        <Link
                            aria-labelledby='faq'
                            target='_blank'
                            href='https://aka.ms/screeningfaqs'>
                            aka.ms/screeningfaqs
                        </Link>
                    </div>
                    <div className={styles.resourceLabelWithLink}>
                        <label id='vendor' className={styles.resourceLabel}>
                            Vendor Cloud Screening Questions:
                        </label>
                        <Link aria-labelledby='vendor' href='mailto:screensu@microsoft.com'>
                            screensu@microsoft.com
                        </Link>
                    </div>
                    <div className={styles.resourceLabelWithLink}>
                        <label id='questions' className={styles.resourceLabel}>
                            FTE Cloud Screening Questions:
                        </label>
                        <Link aria-labelledby='questions' href='mailto:askhr@microsoft.com'>
                            askhr@microsoft.com
                        </Link>
                    </div>
                    <div className={styles.resourceLabelWithLink}>
                        <label id='bgscreen' className={styles.resourceLabel}>
                            Background Screening Program:
                        </label>
                        <Link aria-labelledby='bgscreen' href='mailto:bgcheck@microsoft.com'>
                            bgcheck@microsoft.com
                        </Link>
                    </div>
                    <div className={styles.resourceLabelWithLink}>
                        <label id='hireright' className={styles.resourceLabel}>
                            HireRight Customer Support:
                        </label>
                        <Link
                            aria-labelledby='hireright'
                            target='_blank'
                            href='https://aka.ms/hirerightsupport'>
                            aka.ms/hirerightsupport
                        </Link>
                    </div>
                    <div className={styles.resourceLabelWithLink}>
                        <label id='americasbgscreen' className={styles.resourceLabel}>
                            Americas Background Screening Program:
                        </label>
                        <Link
                            aria-labelledby='americasbgscreen'
                            href='mailto:bgcheck@microsoft.com'>
                            bgcheck@microsoft.com
                        </Link>
                    </div>
                    <div className={styles.resourceLabelWithLink}>
                        <label id='emeabgscreen' className={styles.resourceLabel}>
                            EMEA Background Screening Program:
                        </label>
                        <Link aria-labelledby='emeabgscreen' href='mailto:emeabsp@microsoft.com'>
                            emeabsp@microsoft.com
                        </Link>
                    </div>
                    <div className={styles.resourceLabelWithLink}>
                        <label id='asiabgscreen' className={styles.resourceLabel}>
                            Asia Background Screening Program:
                        </label>
                        <Link aria-labelledby='asiabgscreen' href='mailto:asiabsp@microsoft.com'>
                            asiabsp@microsoft.com
                        </Link>
                    </div>
                </div>
            </>
        );
    }

    function PastScreeningsTableHeading(): JSX.Element {
        if (!myExpiredScreenings || myExpiredScreenings.length === 0) return <></>;
        return (
            <>
                <DefaultButton
                    styles={pastButtonStyle}
                    onClick={() => setShowPastScreenings((prev) => !prev)}
                    aria-label={`Past Screenings button - Click to ${
                        showPastScreenings ? 'collapse' : 'expand'
                    } past screenings`}
                    aria-expanded={
                        showPastScreenings && myExpiredScreenings && myExpiredScreenings.length > 0
                    }>
                    <Icon
                        styles={{ root: { marginRight: '10px' } }}
                        iconName={
                            showPastScreenings ? IconNames.ChevronDown : IconNames.ChevronRight
                        }></Icon>
                    <b>View Past Screenings</b>&nbsp;&nbsp;&nbsp;Note: Past Screenings are only for
                    reference
                </DefaultButton>
                {showPastScreenings && myExpiredScreenings && myExpiredScreenings.length > 0 && (
                    <div className={styles.myScreeningsContainer}>
                        <h1 className={styles.header}>My Past Screenings</h1>
                        <MyScreeningsTable
                            screenings={myExpiredScreenings}
                            isFetchingData={isFetchingData}
                            showRequestButton={canCreateRequest}
                            setShowRequestModal={setShowRequestModal}
                            scopes={myScopes}
                        />
                    </div>
                )}
                <Separator />
            </>
        );
    }

    return (
        <>
            <div className={styles.internationalExceptionBanner}>
                {isInternationalException && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        Your country does not allow screening of employees per local privacy laws.
                        If you need access to a resource that requires Cloud Screening please fill
                        out this
                        <Link
                            href='https://aka.ms/screening/cloud/exception'
                            target='_blank'
                            rel='noopener noreferrer'
                            underline>
                            international exception form.
                        </Link>{' '}
                        An employee&apos;s manager must be the one to fill out this form. Send the
                        completed form to cloudsrt@microsoft.com.
                    </MessageBar>
                )}

                {userContext.hasCloudScreeningUserType(CloudScreeningUserType.Admin) && (
                    <div style={{ margin: '10px' }}>
                        <CloudScreeningAdminImpersonation
                            onEmployeeSelected={(employee: IEmployee | undefined): void => {
                                if (employee) {
                                    setViewedEmployee(employee);
                                } else {
                                    setViewedEmployee(userContext.employeeRecord);
                                }
                            }}
                        />
                        <div style={{ fontWeight: 'normal', fontSize: '1em', margin: '0px' }}>
                            Admin page view for employee: <b>{viewedEmployeeSecure?.alias ?? ''}</b>
                        </div>
                    </div>
                )}

                <div className={styles.myScreeningsContainer}>
                    <div className={styles.myScreeningsHeaderContainer}>
                        <h1 className={styles.header}>My Current Screening</h1>
                        {isRequestScreeningDisplayed(viewedEmployeeSecure) &&
                            (isInternationalException ? (
                                <div className={styles.requestBtnContainer}>
                                    <a href='https://aka.ms/InternationalExceptions'>
                                        <PrimaryButton
                                            text='Fill Out Exception Form'
                                            iconProps={{ iconName: 'PreviewLink' }}
                                        />
                                    </a>
                                </div>
                            ) : (
                                <div className={styles.requestBtnContainer}>
                                    <PrimaryButton
                                        text='Request New Screen'
                                        iconProps={{ iconName: 'PreviewLink' }}
                                        onClick={(): void => setShowRequestModal(true)}
                                    />
                                </div>
                            ))}
                    </div>

                    <div
                        style={{
                            marginLeft: '10px',
                            marginTop: '10px',
                            marginBottom: '0px',
                            fontWeight: 'normal',
                            fontSize: '1em',
                        }}>
                        You are required to be screened every 2 years to maintain access to:{' '}
                        <b>{createScopesElementFromRecords(myScopes)}</b>
                        <br />
                        <DaysLeftBeforeNextRequest />
                    </div>
                    {(myScreenings || isFetchingData) && (
                        <MyScreeningsTable
                            screenings={myScreenings ?? []}
                            isFetchingData={isFetchingData}
                            showRequestButton={canCreateRequest}
                            setShowRequestModal={setShowRequestModal}
                            scopes={myScopes}
                        />
                    )}
                </div>

                <PastScreeningsTableHeading />

                <div className={styles.lowerInfoContainer}>
                    {viewedEmployeeSecure?.isFTE && (
                        <div className={styles.requirementsContainer}>
                            <SupportTextLinks />
                            <h3 className={styles.requirementsHeader2}>Overview</h3>
                            <p className={styles.requirementsBody}>
                                The Microsoft &quot;Cloud Screen&quot; background check is a key
                                component of our enterprise promise to customers around safeguarding
                                their data. Employees and external suppliers are required to
                                complete their Cloud Screen background check every two years to
                                access production, customer resources, and other projects determined
                                to be especially sensitive.
                            </p>
                        </div>
                    )}

                    {!viewedEmployeeSecure?.isFTE && (
                        <div className={styles.requirementsContainer}>
                            <h2 className={styles.lowerInfoHeaderReq}>
                                C+AI and Partners screening requirements for External Suppliers
                            </h2>
                            <SupportTextLinks />
                            <h3 className={styles.requirementsHeader2}>Overview</h3>
                            <p className={styles.requirementsBody}>
                                An MS Cloud Screen is a simple background check conducted every 2
                                years. Microsoft conducts screening directly on FTEs but not on
                                External Suppliers. Therefore External Suppliers must work through
                                their own company to compete a background check with the designated
                                third party provider prior to accessing resources. The check is
                                considered &quot;complete&quot; for an External when the manager at
                                the External Supplier files an attestation stating that the
                                screening requirements have been met.
                            </p>
                        </div>
                    )}

                    {viewedEmployeeSecure?.isFTE && (
                        <div className={styles.processContainer}>
                            <h2 className={styles.lowerInfoHeaderProcess}>
                                FTE Cloud Screening Process
                            </h2>

                            {fteProcessStepData && <ProcessOverview steps={fteProcessStepData} />}
                        </div>
                    )}

                    {!viewedEmployeeSecure?.isFTE && (
                        <div className={styles.processContainer}>
                            <h2 className={styles.lowerInfoHeaderProcess}>
                                Vendor Screening Process
                            </h2>

                            {vendorProcessStepData && (
                                <ProcessOverview steps={vendorProcessStepData || []} />
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Modal
                isOpen={showRequestModal}
                // The following calls onCloseRequestModal on dimiss
                // because the component RequestCloudScreeningModal
                // calls its "onClose" when "Cancel" is clicked
                // and that is what drives onClose.
                onDismiss={(): void => {
                    onCloseRequestModal();
                }}
                isDarkOverlay={false}
                isBlocking={true}
                containerClassName={styles.modalContainer}>
                <RequestCloudScreeningModal
                    onClose={onCloseRequestModal}
                    isFirstAdvantage={isFirstAdvantage}
                    myScopes={myScopes}
                    viewedEmployeeSecure={viewedEmployeeSecure}
                />
            </Modal>
        </>
    );
}

const pastButtonStyle = {
    root: {
        border: 'none',
    },
    rootHovered: {
        background: 'white',
    },
    rootPressed: {
        background: 'white',
    },
};

const styles = mergeStyleSets({
    resourcesContainer: {
        width: '75%',
    },
    resourceLabelWithLink: {
        display: 'flex',
    },
    resourceLabel: {
        flexBasis: '270px',
    },
    boldedResourceLabelWithLink: {
        fontWeight: FontWeights.semibold,
        marginTop: '0px',
        marginBottom: '10px',
    },
    internationalExceptionBanner: {
        padding: '10px 0px 0px 0px',
    },
    header: {
        padding: '0px 0px 0px 10px',
        boxSizing: 'border-box',
        justifyContent: 'flex-start',
        flexGrow: '1',
        fontSize: FontSizes.xLargePlus,
        fontWeight: FontWeights.semibold,
        marginTop: '0px',
        marginBottom: '0px',
    },
    myScreeningsContainer: {
        margin: '10px 0px 40px 0px',
    },
    myScreeningsHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    requestBtnContainer: {
        justifyContent: 'flex-end',
    },
    modalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    lowerInfoContainer: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
    },
    requirementsContainer: {
        padding: '0px 15px 0px 0px',
    },
    processContainer: {
        padding: '0px 0px 0px 15px',
    },
    lowerInfoHeaderReq: {
        fontSize: FontSizes.large,
        fontWeight: FontWeights.semibold,
        marginTop: '0px',
        marginBottom: '0px',
    },
    lowerInfoHeaderProcess: {
        fontSize: FontSizes.large,
        fontWeight: FontWeights.semibold,
        marginBottom: '20px',
        marginTop: '0px',
    },
    requirementsHeader2: {
        fontSize: FontSizes.medium,
        fontWeight: FontWeights.semibold,
        borderBottom: '1px solid rgb(222, 222, 222)',
        padding: '24px 0px 24px 0px',
        marginBottom: '24px',
        marginTop: '0px',
    },
    requirementsBody: {
        fontSize: FontSizes.medium,
    },
});
