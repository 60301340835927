import React from 'react';
import { useCheckMountedState } from 'utils/misc-hooks';
import { Checkbox, Label, Separator, mergeStyleSets } from '@fluentui/react';
import { IUseInputElementArray } from 'components/common/use-input/input-element-utils';
import { globalCheckboxStyles } from 'assets/styles/global-styles';

const separatorStyles = mergeStyleSets({
    root: {
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                opacity: 0.9,
            },
        },
    },
    content: {
        paddingLeft: 0,
        fontWeight: '500',
    },
});

export interface ICheckboxArrayV1Props {
    label: string;
    isChecked?: boolean;
    isDisabled?: boolean;
}

interface ICheckboxParams {
    // Ideally, we should've kept the following props 100% the same
    // as the underlying Fluent component so that they can directly
    // drive its props, but since this hook delivers an array of
    // Fluent components, and not a Fluent component, I had to
    // deviate from the above rule.

    // This hook enforces the parameter options to be defined so as
    // to make it easier and more straightforward to implement it.
    options: ICheckboxArrayV1Props[];
    label?: string;
    isLabelSeparator?: boolean;
}

// The reason I have V1 suffix in the name is, I am not sure if this
// implementation is the best. It's the first, so I may be able to
// improve it in the future. The suffix V1 leaves the door open to
// create a new one out of this without breaking its current consumers.
// If it worked fine for the next few usages, we can remove the "V1".
export function useCheckboxArrayV1(
    params: ICheckboxParams,
): IUseInputElementArray<ICheckboxArrayV1Props> {
    const [selections, setSelections] = useCheckMountedState<ICheckboxArrayV1Props[]>(initialValue);

    function initialValue(): ICheckboxArrayV1Props[] {
        return params.options.map((option) => {
            return {
                label: option.label,
                isChecked: option.isChecked,
            };
        });
    }

    const onValueChange = (
        ix: number,
        e?: React.FormEvent<HTMLElement>,
        isChecked?: boolean,
    ): void => {
        if (e?.type === 'change') {
            setSelections((currentValue) => {
                const newValue = [...currentValue];
                newValue[ix].isChecked = isChecked;
                return newValue;
            });
        }
    };

    const initialize = (initValue?: ICheckboxArrayV1Props[]): void => {
        setSelections(initValue ?? initialValue());
    };

    return {
        value: selections,
        initialize,
        theElement: (): JSX.Element => (
            <div>
                {!!params.label && !params.isLabelSeparator && <Label>{params.label}</Label>}
                {!!params.label && params.isLabelSeparator && (
                    <Separator styles={separatorStyles} alignContent='start'>
                        <Label>{params.label}</Label>
                    </Separator>
                )}
                {selections.map((option, ix) => {
                    const { label, isChecked, isDisabled } = option;
                    return (
                        <Checkbox
                            styles={globalCheckboxStyles}
                            key={label}
                            label={label}
                            checked={isChecked ?? false}
                            disabled={isDisabled}
                            onChange={(
                                e?: React.FormEvent<HTMLElement>,
                                isChecked?: boolean,
                            ): void => {
                                onValueChange(ix, e, isChecked);
                            }}
                        />
                    );
                })}
            </div>
        ),
    };
}
