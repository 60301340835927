import { IPersonaProps, mergeStyleSets, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { useContext, useState } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import CloudScreeningClient from 'clients/cloud-screening-client';
import { IEmployee } from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import { getEmployeeFromPersona } from 'utils/internal-persona-utils';
import CommonSeparator from 'components/common/common-separator';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { RequiredForScopes } from 'components/screening/cloud-screening/cloud-screening-utils';

export interface AllowListAddProps {
    onEmployeeSelected: (emp: IEmployee | undefined) => void;
}

export default function AllowListAdd(props: AllowListAddProps): JSX.Element {
    const [selectedEmployee, setSelectedEmployee] = useState<IEmployee>();
    const [isAddDisabled, setIsAddDisabled] = useState<boolean>();
    const [scopeName, setScopeName] = useState<string | undefined>(RequiredForScopes.Contractual);
    const authContext = useContext(AuthContext);

    function onCandidateSelected(persona: IPersonaProps | undefined): void {
        const employeeResult = getEmployeeFromPersona(persona);
        setSelectedEmployee(employeeResult);
    }

    async function onClickAddAllowListButton() {
        if (selectedEmployee) {
            try {
                setIsAddDisabled(true);
                await CloudScreeningClient.addAllowList(
                    authContext,
                    selectedEmployee.id,
                    scopeName,
                );
                props.onEmployeeSelected(selectedEmployee);
            } catch (err) {
                console.error('Error adding allow list', err);
            } finally {
                setIsAddDisabled(false);
            }
        }
    }

    return (
        <Stack>
            <Stack.Item>
                <CommonSeparator text={'Add Allow List for Manager'} />
            </Stack.Item>
            <Stack.Item>
                <div className={styles.employeePickerContainer}>
                    <EmployeePickerTypeaheadSearch
                        ariaLabel='Manager Picker'
                        placeHolder={'Manager name or alias'}
                        onCandidateSelected={onCandidateSelected}
                    />
                </div>

                <div className={styles.employeePickerContainer}>
                    <TextField
                        placeholder={'Scope Name'}
                        value={scopeName ?? ''}
                        onChange={(ev, newVal) => setScopeName(newVal)}
                    />
                </div>

                <div className={styles.assignBtnContainer}>
                    <PrimaryButton
                        text={'Add Allow List'}
                        iconProps={{ iconName: IconNames.AddGroup }}
                        onClick={onClickAddAllowListButton}
                        disabled={isAddDisabled}
                    />
                </div>
            </Stack.Item>
        </Stack>
    );
}

const styles = mergeStyleSets({
    assignBtnContainer: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    employeePickerContainer: {
        marginBottom: '10px',
    },
});
