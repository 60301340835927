import React from 'react';
import { IColumn, DialogType } from '@fluentui/react';
import { EmailNotificationType } from 'components/sca/manage/create-review/create-review-modal';
import { IconNames } from 'assets/constants/global-constants';
import DialogActionButton from 'components/common/buttons/dialog-action-button';
import { dateToLocalShortDateFormat } from 'utils/time-utils';
import { findManagerialLevel } from 'components/sca/sca-constants';
import { ISortableColumns } from 'utils/sort-utils';

const columnWidths = {
    date: 60,
    level: 60,
    action: 30,
};

interface ITableParams extends ISortableColumns {
    showDeleteNotification: boolean;
    performDeleteNotification: (ix: number | undefined) => void;
}

export const getEmailNotificationColumns = (params: ITableParams): IColumn[] => [
    {
        key: 'Date',
        name: 'Date',
        ariaLabel: 'Date',
        minWidth: columnWidths.date,
        maxWidth: columnWidths.date,
        isSorted: true,
        isSortedDescending: !params.sortAscending,
        onColumnClick: (): void => {
            params.sortColumnHandler('Date');
        },
        onRender: (row: EmailNotificationType): JSX.Element => {
            return <span>{dateToLocalShortDateFormat(row.date)}</span>;
        },
    },
    {
        key: 'Level',
        name: 'Level',
        ariaLabel: 'Level',
        minWidth: columnWidths.level,
        maxWidth: undefined,
        onRender: (row: EmailNotificationType): JSX.Element => {
            const managerialLevel = findManagerialLevel(row.level);
            return <span>{managerialLevel?.text}</span>;
        },
    },
    {
        key: 'Action',
        name: '',
        ariaLabel: 'Action',
        minWidth: columnWidths.action,
        maxWidth: columnWidths.action,
        onRender: (row: EmailNotificationType, ix: number | undefined): JSX.Element => {
            if (params.showDeleteNotification) {
                return (
                    <DialogActionButton
                        text=''
                        iconName={IconNames.Delete}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: 'Delete email notification',
                            closeButtonAriaLabel: 'Close',
                            subText:
                                'Do you want to delete notification email that would be sent to' +
                                ` ${row.level} on ${dateToLocalShortDateFormat(row.date)}?`,
                        }}
                        performTaskText='Delete it'
                        onPerformTask={async (): Promise<void> => {
                            params.performDeleteNotification(ix);
                        }}
                        dontPerformTaskText='Keep it'>
                        {}
                    </DialogActionButton>
                );
            } else {
                return <></>;
            }
        },
    },
];
