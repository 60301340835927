import React, { useState } from 'react';
import { Dropdown, IDropdownOption, Checkbox, mergeStyleSets, mergeStyles } from '@fluentui/react';
import {
    UsGovRequestTypes,
    US_GOV_REQUEST_TYPE_OPTIONS,
} from 'components/screening/us-gov/IScreening';
import {
    ClearanceLevelType,
    CURRENT_LEVEL_OPTIONS,
} from 'components/screening/common/common-constants';
import { IClearanceLevel } from 'components/screening/common/candidate-nomination-stepper-common';
import { SpecialAccesses } from 'components/personnel-profile/clearance/profile-clearance-constants';
import { nameof } from 'utils/object-utils';

export interface CandidateNominationStepTargetLevelProps {
    onRequestTypeChange: (requestType?: UsGovRequestTypes) => void;
    onClearanceLevelChange: (clearanceLevel?: IClearanceLevel) => void;
    onCustomerBadgeRequiredChange: (badge?: string) => void;
    requestType?: UsGovRequestTypes;
    clearanceLevel?: IClearanceLevel;
    customerBadgeRequired?: string;
    employementType: string;
}

enum Labels {
    reqType = 'Request type',
    currLevel = 'Clearance level',
    targLevel = 'Customer badge required',
}
const TARGET_LEVEL_OPTIONS: IDropdownOption[] = [
    { key: 'YES', text: 'Yes' },
    { key: 'NO', text: 'No' },
];

function CandidateNominationStepTargetLevelUsGov(
    props: CandidateNominationStepTargetLevelProps,
): JSX.Element {
    const [isSciAdditionalOptionsVisible, SetSciAdditionalOptionsVisible] = useState<boolean>(
        undefined !== props.clearanceLevel?.additionalAttributes &&
            props.clearanceLevel?.key === ClearanceLevelType.SCI,
    );
    const [
        isTsAndSecretAdditionalOptionsVisible,
        SetTsAndSecretAdditionalOptionsVisible,
    ] = useState<boolean>(
        undefined !== props.clearanceLevel?.additionalAttributes &&
            props.clearanceLevel?.key !== ClearanceLevelType.SCI,
    );
    const [clearanceLevel, setClearanceLevel] = useState<IClearanceLevel | undefined>(
        props.clearanceLevel,
    );

    const usGovRequestTypeOptions = US_GOV_REQUEST_TYPE_OPTIONS.filter((options) => {
        return options.key !== nameof<typeof UsGovRequestTypes>('PeriodicReinvestigation');
    });

    return (
        <>
            <div>
                <Dropdown
                    label={Labels.reqType}
                    required
                    options={usGovRequestTypeOptions}
                    selectedKey={props.requestType}
                    title={props.requestType}
                    onChange={(ev, option) => {
                        if (option && option.key)
                            props.onRequestTypeChange(option.key as UsGovRequestTypes);
                    }}
                />
                <Dropdown
                    label={Labels.currLevel}
                    required
                    className={dropdownSpacer}
                    selectedKey={clearanceLevel?.key}
                    options={CURRENT_LEVEL_OPTIONS}
                    title={clearanceLevel?.name}
                    onChange={handleClearanceLevelChange}
                />
                <Dropdown
                    label={Labels.targLevel}
                    required
                    className={dropdownSpacer}
                    options={TARGET_LEVEL_OPTIONS}
                    selectedKey={props.customerBadgeRequired}
                    title={props.customerBadgeRequired}
                    onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        option?: IDropdownOption,
                    ): void => props.onCustomerBadgeRequiredChange(option?.key.toString())}
                />
            </div>
            {isSciAdditionalOptionsVisible && (
                <div className={styles.additionalOptionsRoot}>
                    <label>Access required</label>
                    <div>
                        <div className={styles.additionalOptionsRow}>
                            <Checkbox
                                onChange={(
                                    ev?: React.FormEvent<HTMLElement>,
                                    isChecked?: boolean,
                                ): void => onChange(SpecialAccesses.SI, ev, isChecked)}
                                styles={checkBoxStyles}
                                label={SpecialAccesses.SI}
                                checked={clearanceLevel?.additionalAttributes?.SI ?? false}
                            />
                            <Checkbox
                                label={SpecialAccesses.G}
                                checked={clearanceLevel?.additionalAttributes?.G ?? false}
                                onChange={(
                                    ev?: React.FormEvent<HTMLElement>,
                                    isChecked?: boolean,
                                ): void => onChange(SpecialAccesses.G, ev, isChecked)}
                            />
                        </div>
                        <div className={styles.additionalOptionsRow}>
                            <Checkbox
                                checked={clearanceLevel?.additionalAttributes?.TK ?? false}
                                styles={checkBoxStyles}
                                label={SpecialAccesses.TK}
                                onChange={(
                                    ev?: React.FormEvent<HTMLElement>,
                                    isChecked?: boolean,
                                ): void => onChange(SpecialAccesses.TK, ev, isChecked)}
                            />
                            <Checkbox
                                label={SpecialAccesses.HCS}
                                checked={clearanceLevel?.additionalAttributes?.HCS ?? false}
                                onChange={(
                                    ev?: React.FormEvent<HTMLElement>,
                                    isChecked?: boolean,
                                ): void => onChange(SpecialAccesses.HCS, ev, isChecked)}
                            />
                        </div>
                        <div className={styles.additionalOptionsRow}>
                            <Checkbox
                                label={SpecialAccesses.KLM}
                                checked={clearanceLevel?.additionalAttributes?.KLM ?? false}
                                onChange={(
                                    ev?: React.FormEvent<HTMLElement>,
                                    isChecked?: boolean,
                                ): void => onChange(SpecialAccesses.KLM, ev, isChecked)}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );

    function handleClearanceLevelChange(
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): void {
        if (option) {
            const newClearanceLevel: IClearanceLevel = {
                key: option.key.toString(),
                name: option.text,
            };
            if (option.key === ClearanceLevelType.SCI) {
                newClearanceLevel.additionalAttributes = {
                    G: clearanceLevel?.additionalAttributes?.G,
                    HCS: clearanceLevel?.additionalAttributes?.HCS,
                    SI: clearanceLevel?.additionalAttributes?.SI,
                    TK: clearanceLevel?.additionalAttributes?.TK,
                    KLM: clearanceLevel?.additionalAttributes?.KLM,
                };
                SetTsAndSecretAdditionalOptionsVisible(false);
                SetSciAdditionalOptionsVisible(true);
            } else {
                SetSciAdditionalOptionsVisible(false);
                SetTsAndSecretAdditionalOptionsVisible(false);
                newClearanceLevel.additionalAttributes = undefined;
            }
            setClearanceLevel(newClearanceLevel);
            props.onClearanceLevelChange(newClearanceLevel);
        }
    }

    function onChange(label: string, ev?: React.FormEvent<HTMLElement>, isChecked?: boolean): void {
        if (clearanceLevel !== undefined) {
            const newClearanceLevel: IClearanceLevel = {
                key: clearanceLevel.key,
                name: clearanceLevel.name,
                additionalAttributes: {
                    [label]: isChecked,
                },
            };
            newClearanceLevel.additionalAttributes = {
                ...clearanceLevel.additionalAttributes,
                ...newClearanceLevel.additionalAttributes,
            };
            setClearanceLevel(newClearanceLevel);
            props.onClearanceLevelChange(newClearanceLevel);
        }
    }
}

const checkBoxStyles = { root: { width: '50%' } };

const styles = mergeStyleSets({
    additionalOptionsRoot: {
        marginTop: 20,
    },
    additionalOptionsRow: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 10,
    },
});

export default CandidateNominationStepTargetLevelUsGov;

const dropdownSpacer = mergeStyles({ marginTop: 20 });
