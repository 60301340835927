import React, { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import { contentMaxHeight } from 'assets/styles/list-styles';
import { AuthContext } from 'contexts/auth-context';
import {
    ActionButton,
    ContextualMenu,
    DefaultButton,
    Dropdown,
    FontSizes,
    FontWeights,
    IDropdownOption,
    Label,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Modal,
    PrimaryButton,
    Stack,
    TextField,
    TextFieldBase,
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import {
    acceptedFileTypesForTemplate,
    acceptedFileTypesTextForTemplate,
    fileInputAcceptStringForTemplate,
    isValidFileType,
} from 'utils/file-utils';
import DocumentsClient, {
    IDocumentRecord,
    IDocumentReference,
    IDocumentTypeItem,
} from 'clients/documents-client';
import { UserContext } from 'contexts/user-context';
import {
    AssociationType,
    AssociationValue,
    IScreeningTemplateRequestRecord,
} from 'clients/template-client';
import ActivitiesClient, { IActivityValue, IActivityPost } from 'clients/activities-client';

export interface DocumentAddProps {
    children?: React.ReactNode;
    screeningId?: string;
    employeeId?: string;
    editableEmployeeId?: string;
    onChangeUploadNote?: React.Dispatch<React.SetStateAction<string>>;
    eventBitWillManuallyUpdate?: () => void;
    associationType?: AssociationType | undefined;
    associationValue?: AssociationValue | undefined;
    updateUploadedDocumentsPanel?: () => void;
    onUploadComplete?(request: IScreeningTemplateRequestRecord): Promise<void>;
}

export default function DocumentProfileAddModalFlag(props: DocumentAddProps): JSX.Element {
    const userContext = useContext(UserContext);
    const authContext = useContext(AuthContext);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const titleField = useRef<TextFieldBase>(null);
    const notesField = useRef<TextFieldBase>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileField = useRef<any>(null);
    const [fileName, setFileName] = useState<string>();
    const [isInvalidDocumentType, setIsInvalidDocumentType] = useState<boolean>();
    const [isInvalidFile, setIsInvalidFile] = useState<boolean>();
    const [isEmptyFile, setIsEmptyFile] = useState<boolean>(false);
    const [isInvalidTitle, setIsInvalidTitle] = useState<boolean>();
    const [uploadFailedError, setUploadFailedError] = useState<string | undefined>(undefined);
    const [isUploadError, setIsUploadError] = useState<boolean>(false);
    const [canAddDoc, setCanAddDoc] = useState<boolean>(false);
    const [uploadFile, setUploadFile] = useState<File | undefined>();
    const [documentTypeItemList, setDocumentTypeItemList] = useState<IDropdownOption[]>([]);
    const [selectedDocumentTypeItem, setSelectedDocumentTypeItem] = useState<IDropdownOption>();
    const activityEventName = 'created';
    const activityTag = 'converted';

    useEffect(() => {
        populateDocumentTypesList();
    }, []);

    useEffect(() => {
        setCanAddDoc(
            isInvalidDocumentType === undefined ||
                isInvalidDocumentType ||
                isInvalidTitle === undefined ||
                isInvalidTitle ||
                isInvalidFile === undefined ||
                isInvalidFile,
        );
    }, [isInvalidDocumentType, isInvalidTitle, isInvalidFile]);

    async function populateDocumentTypesList(): Promise<void> {
        const docTypeItemsList: IDocumentTypeItem[] = await DocumentsClient.getDocumentTypeItems(
            authContext,
            props.associationType?.toString(),
            props.associationValue?.toString(),
        );

        const options: IDropdownOption[] = docTypeItemsList.map((x) => {
            const option: IDropdownOption = {
                key: x.documentType,
                text: x.displayName,
                data: x.documentTypeName,
            };
            return option;
        });
        setDocumentTypeItemList([...options]);
    }

    async function onFileChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        if (event.target.files && event.target.files[0]) {
            const file: File = event.target.files[0];
            const isValid: boolean = await isValidFileType(file, acceptedFileTypesForTemplate);
            setUploadFile(file);
            setFileName(file.name);
            setIsEmptyFile(file.size === 0);
            setIsInvalidFile(!isValid);
        } else {
            setUploadFile(undefined);
            setFileName(undefined);
            setIsInvalidFile(false);
            setIsEmptyFile(false);
        }
    }

    function resetUploadFile(): void {
        setSelectedDocumentTypeItem(undefined);
        setIsInvalidDocumentType(undefined);
        setIsInvalidTitle(undefined);
        setUploadFile(undefined);
        setIsInvalidFile(true);
        setFileName(undefined);
        setUploadFailedError(undefined);
        setIsUploadError(false);
        if (props.onChangeUploadNote) {
            props.onChangeUploadNote(() => '');
        }
    }

    async function upload(): Promise<void> {
        if (uploadFile) {
            try {
                const uploadedFile: IDocumentRecord = await performUploadFile(uploadFile);

                if (uploadedFile) {
                    if (
                        props.eventBitWillManuallyUpdate &&
                        (props.associationValue === AssociationValue.PublicTrust ||
                            props.associationValue === AssociationValue.UsGov)
                    ) {
                        if (!props.screeningId) {
                            throw 'Cannot find screening Id.';
                        } else {
                            const request: IDocumentReference = {
                                referenceId: props.screeningId,
                                application: 'Screening_Service',
                                documentId: uploadedFile.id,
                                allowedPermissions: [
                                    'ViewMetadata',
                                    'Download',
                                    'Lock',
                                    'ManageLifecycle',
                                ],
                                metadata: {
                                    documentRequestId: '',
                                },
                            };
                            await DocumentsClient.shareDocument(authContext, request);
                        }
                        if (props.updateUploadedDocumentsPanel)
                            props.updateUploadedDocumentsPanel();

                        props.eventBitWillManuallyUpdate();
                    }
                    setIsUploadError(false);

                    writeDocumentActivity(uploadedFile);
                }
            } catch (error) {
                setIsUploadError(true);
                setUploadFailedError(error);
            } finally {
                resetUploadFile();
            }
        }
    }

    function writeDocumentActivity(uploadedFile: IDocumentRecord): void {
        const uploadNote = `${userContext.employeeRecord.fullName} uploaded the ${uploadedFile.documentType}`;

        const subjectData: IActivityValue = {
            type: 'personId',
            value: userContext.employeeRecord.id,
        };

        const directObjectData: IActivityValue = {
            type: 'templateId',
            value: uploadedFile.id,
        };

        // Make sure the activity event have the prehire record Id in the tags and securityIds field so if
        // an unconvert happens (splitting the converted prehire back to the original state prehire and FTE),
        // we could still find the notes using the prehire record Id and/or FTE
        const activityPostData: IActivityPost = {
            event: activityEventName,
            eventTimestampUTC: new Date().getTime(),
            referenceId: uploadedFile.id,
            securityIds:
                props.editableEmployeeId &&
                props.editableEmployeeId &&
                props.employeeId !== props.editableEmployeeId
                    ? ['Admin', props.employeeId || '', props.editableEmployeeId]
                    : ['Admin', props.employeeId || ''],
            subject: subjectData,
            directObject: directObjectData,
            message: uploadNote,
            tags:
                props.editableEmployeeId &&
                props.editableEmployeeId &&
                props.employeeId !== props.editableEmployeeId
                    ? [activityTag, props.employeeId || '', props.editableEmployeeId]
                    : [props.employeeId || ''],
        };

        ActivitiesClient.getProfileActivityToken(authContext, props.employeeId || '', [
            'write',
        ]).then(async (token) => {
            const response = await ActivitiesClient.postDocumentActivity(
                authContext,
                uploadedFile.id,
                activityPostData,
                token,
            );

            if (response && props.onChangeUploadNote) {
                props.onChangeUploadNote(() => uploadNote);
            }
        });
    }

    async function performUploadFile(uploadFile: File): Promise<IDocumentRecord> {
        try {
            return await DocumentsClient.uploadDocument(
                authContext,
                uploadFile,
                notesField.current?.value,
                titleField.current?.value,
                selectedDocumentTypeItem?.data,
            );
        } catch (error) {
            throw 'Error with uploading file.';
        }
    }

    function onDocumentTypeChange(
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption | undefined,
    ): void {
        if (!!option) {
            setIsInvalidDocumentType(false);
            setSelectedDocumentTypeItem(option);
        } else {
            setIsInvalidDocumentType(true);
            setSelectedDocumentTypeItem(undefined);
        }
    }

    function onTitleChange(
        event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void {
        const title: string | undefined = newValue?.trim();
        if (!title || title.length === 0) setIsInvalidTitle(true);
        else setIsInvalidTitle(false);
    }

    function onCloseClick(): void {
        setModalOpen(false);
        resetUploadFile();
    }

    async function onAddClick(): Promise<void> {
        upload();
        setModalOpen(false);
    }

    return (
        <Stack>
            <ActionButton
                onClick={(): void => {
                    setModalOpen(true);
                }}
                iconProps={{ iconName: IconNames.AddToShoppingList }}
                text={'Add'}
                styles={{ root: { maxHeight: contentMaxHeight } }}
            />
            <Modal
                isOpen={isModalOpen}
                onDismiss={(): void => setModalOpen(false)}
                isDarkOverlay={false}
                containerClassName={styles.modalContainer}
                dragOptions={{
                    moveMenuItemText: 'Move',
                    closeMenuItemText: 'Close',
                    menu: ContextualMenu,
                }}>
                <div className={styles.root}>
                    <div className={styles.body}>
                        <div className={styles.label}>Add Document</div>
                        <div className={styles.content}>
                            <div className={stylesForOtherDocuments.row}>
                                <div className={stylesForOtherDocuments.leftColumn}>
                                    <Label required={true}>Type</Label>
                                </div>
                                <div className={stylesForOtherDocuments.rightColumn}>
                                    <Dropdown
                                        options={documentTypeItemList}
                                        placeholder='Select document type'
                                        required
                                        onChange={onDocumentTypeChange}
                                    />
                                </div>
                            </div>
                            <div className={stylesForOtherDocuments.row}>
                                <div className={stylesForOtherDocuments.leftColumn}>
                                    <Label required={true}>Title</Label>
                                </div>
                                <div className={stylesForOtherDocuments.rightColumn}>
                                    <TextField
                                        className={isInvalidTitle ? styles.error : ''}
                                        componentRef={titleField}
                                        onChange={onTitleChange}
                                    />
                                </div>
                            </div>
                            <div className={stylesForOtherDocuments.row}>
                                <div className={stylesForOtherDocuments.leftColumn}>
                                    <Label>Notes</Label>
                                </div>
                                <div className={stylesForOtherDocuments.rightColumn}>
                                    <TextField
                                        multiline={true}
                                        resizable={false}
                                        componentRef={notesField}
                                    />
                                </div>
                            </div>
                            <div className={stylesForOtherDocuments.row}>
                                <div className={stylesForOtherDocuments.leftColumn}>
                                    <Label>Documents</Label>
                                </div>
                                <div className={stylesForOtherDocuments.rightColumn}>
                                    <div className={styles.uploadButton}>
                                        <DefaultButton
                                            iconProps={{ iconName: 'OpenFile' }}
                                            type='file'>
                                            Choose File
                                        </DefaultButton>
                                        <input
                                            className={styles.hiddenInput}
                                            type='file'
                                            id='fileInput'
                                            ref={fileField}
                                            onChange={onFileChange}
                                            accept={fileInputAcceptStringForTemplate}
                                        />
                                        <span className={styles.fileName}>
                                            {fileName || 'No file chosen'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isUploadError && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                File upload failed: {uploadFailedError}
                            </MessageBar>
                        )}
                        {isInvalidDocumentType && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                Invalid document type: Document type cannot be empty.
                            </MessageBar>
                        )}
                        {isInvalidTitle && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                Invalid title: Title cannot be empty.
                            </MessageBar>
                        )}
                        {uploadFile && isInvalidFile && !isEmptyFile && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                Invalid file type. {acceptedFileTypesTextForTemplate}
                            </MessageBar>
                        )}
                        {isEmptyFile && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                Invalid file: File is empty.
                            </MessageBar>
                        )}
                    </div>
                    <div className={styles.footer}>
                        <DefaultButton text='Back' onClick={onCloseClick} allowDisabledFocus />
                        <PrimaryButton
                            onClick={onAddClick}
                            text='Add doc'
                            disabled={canAddDoc}
                            allowDisabledFocus
                        />
                    </div>
                </div>
            </Modal>
        </Stack>
    );
}

const styles = mergeStyleSets({
    root: {
        minWidth: '670px',
        boxSizing: 'border-box',
        selectors: {
            '@media(max-width: 450px)': {
                minWidth: '100%',
                maxWidth: '100%',
            },
        },
    },
    body: {
        backgroundColor: '#FFFFFF',
    },
    label: [
        FontSizes.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${SharedColors.cyanBlue10}`,
            display: 'flex',
            fontSize: '24px',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    sublabel: [
        FontSizes.small,
        {
            flex: '1 1 auto',
            display: 'flex',
            fontSize: '14px',
            alignItems: 'center',
            padding: '0px 0px 0px 24px',
        },
    ],
    content: {
        padding: '30px 24px 24px 24px',
        color: '#1b1b1b',
    },
    footer: {
        backgroundColor: '#FEFCFE',
        padding: '14px',
        borderTop: '1px solid #EAE9EA',
        display: 'flex',
        justifyContent: 'flex-end',
        selectors: {
            '& :not(:last-child)': {
                marginRight: '10px',
            },
        },
    },
    modalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    error: {
        border: '1px solid red',
    },
    fileName: {
        marginLeft: '10px',
    },
    uploadButton: {
        position: 'relative',
        overflow: 'hidden',
        display: 'inline-block',
    },
    hiddenInput: {
        position: 'absolute',
        left: '0',
        top: '0',
        opacity: '0',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
    },
});

const stylesForOtherDocuments = mergeStyleSets({
    row: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5,
        marginBottom: 5,
        selectors: {
            '@media(max-width: 450px)': {
                display: 'block',
            },
        },
    },
    leftColumn: {
        float: 'left',
        width: '30%',
        fontWeight: FontWeights.semibold,
        selectors: {
            '@media(max-width: 450px)': {
                width: '100%',
                marginBottom: '5px',
            },
        },
    },
    rightColumn: {
        width: '70%',
        overflowX: 'hidden',
        selectors: {
            '@media(max-width: 450px)': {
                clear: 'left',
                width: '100%',
            },
        },
    },
});
