import { Rating, mergeStyleSets } from '@fluentui/react';
import FeedbackActions from 'components/common/page-shell/feedback-notifications-profile/feedback/feedback-actions';
import React from 'react';
import {
    FeedbackDataType,
    GenericFeedbackForm,
} from 'components/common/page-shell/feedback-notifications-profile/feedback/feedback-common';

type ContentPropsType = {
    showThankYou: boolean;
    feedback: FeedbackDataType;
    setFeedback: (feedback: FeedbackDataType) => void;
};

export default function FeedbackContent({
    showThankYou,
    feedback,
    setFeedback,
}: ContentPropsType): JSX.Element {
    const ThankYou = (): JSX.Element => (
        <div>
            Your feedback has been submitted. We appreciate your time and helping us to improve.
        </div>
    );

    return (
        <>
            <div className={styles.feedbackFormContents}>
                {showThankYou && <ThankYou />}
                {!showThankYou && (
                    <>
                        <div className={styles.feedbackBoldText}>
                            Rate your experience using Personnel{' '}
                            <span className={styles.feedbackTextRequired}>*</span>
                        </div>
                        <Rating
                            ariaLabel='Rate your experience using Personnel'
                            defaultRating={feedback.rating ?? 0}
                            allowZeroStars
                            max={5}
                            ariaLabelFormat={'{0} of {1}} stars'}
                            onChange={(ev, rating): void => {
                                setFeedback({ ...feedback, rating: rating ?? 5 });
                            }}
                        />
                        <div className={styles.classifyTextStyle}>
                            Classify your feedback{' '}
                            <span className={styles.feedbackTextRequired}>*</span>
                        </div>
                    </>
                )}
                {!showThankYou && feedback && feedback.type && (
                    <GenericFeedbackForm feedback={feedback} setFeedback={setFeedback} />
                )}
                {!showThankYou && !feedback?.type && (
                    <FeedbackActions feedback={feedback} setFeedback={setFeedback} />
                )}
            </div>
            {!showThankYou && (
                <div className={styles.privacyContainerStyle}>
                    <div>
                        By pressing Submit, your feedback will be used to improve Microsoft products
                        and services. Product admins will be able to view and manage your feedback
                        data.{' '}
                        <a
                            target='_blank'
                            className={styles.smallPrivacyLink}
                            href='https://go.microsoft.com/fwlink/?LinkId=521839'
                            rel='noreferrer'>
                            Privacy statement
                        </a>
                    </div>
                </div>
            )}
        </>
    );
}

const styles = mergeStyleSets({
    feedbackFormContents: {
        marginTop: '25px',
    },
    privacyContainerStyle: {
        fontSize: '10px',
        alignSelf: 'end',
        paddingTop: '1rem',
    },
    classifyTextStyle: {
        marginTop: '40px',
        marginBottom: '18px',
        fontWeight: '600',
    },
    feedbackBoldText: {
        fontWeight: '600',
    },
    feedbackTextRequired: {
        color: 'rgb(164, 38, 44)',
    },
    smallPrivacyLink: {
        color: 'rgb(0, 114, 201)',
        textDecoration: 'none',
    },
});
