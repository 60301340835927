import React, { ReactNode, useMemo } from 'react';
import { Stack, Alignment, mergeStyles, IStyle } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import { Dictionary } from 'assets/constants/global-constants';

interface IHorizontalBarProps {
    children: ReactNode;
    styles?: Dictionary<IStyle>;
    horizontalAlign?: Alignment;
    verticalAlign?: Alignment;
}

export default function HorizontalBar(props: IHorizontalBarProps): JSX.Element {
    const style = useMemo(() => mergeStyles(globalStyles.horizontalBar, props.styles), [
        globalStyles.horizontalBar,
        props?.styles,
    ]);

    return (
        <Stack
            horizontal
            horizontalAlign={props.horizontalAlign}
            verticalAlign={props.verticalAlign}
            className={style}>
            {props.children}
        </Stack>
    );
}

export const horizontalBarTitleStyle = mergeStyles(
    globalStyles.boldFont,
    globalStyles.mediumLargeFont,
    {
        // If the element is <h1>, <h2> or the like, it may have
        // its own top and bottom margins. The following styling
        // will prevent that from taking effect.
        marginTop: 0,
        marginBottom: 0,
    },
);
