import React, { FormEvent, useState, useContext, useMemo, useEffect } from 'react';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import {
    cosmosDbIdSpecialCharacters,
    hasCosmostDbIdSpecialCharacters,
    IconNames,
} from 'assets/constants/global-constants';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { IPersonaProps, MessageBar, MessageBarType, TextField } from '@fluentui/react';
import { AuthContext } from 'contexts/auth-context';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import { ContractsPageModalTypes, IContractRequest } from 'components/screening/us-gov/IContract';
import { globalStyles } from 'assets/styles/global-styles';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import Spacer from 'components/common/spacer';
import { ContractStatus, ContractType, IContract } from 'components/screening/us-gov/IContract';
import { useDropdown } from 'components/common/use-input/use-dropdown';
import { doNothing } from 'utils/misc-utils';
import { IBasicEmployee } from 'clients/employee-client';
import { ModalSizeType } from 'components/common/modal';
import { transformBasicEmployeeToPersona } from 'utils/internal-persona-utils';
import { IAgency } from 'components/screening/us-gov/IAgency';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface ManageContractModalActionButtonProps {
    modalType: ContractsPageModalTypes;
    contract?: IContract;
    isUserContractAdmin: boolean;
    basicEmployeesMap: Map<string, IBasicEmployee>;
    contractsCallback: (contract: IContract, modalType: ContractsPageModalTypes) => void;
    agencies: IAgency[];
}

export default function ManageContractModalActionButton(
    props: ManageContractModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [isCreatingContract, setIsCreatingContract] = useState<boolean>(false);
    const [contractId, setContractId] = useState<string>('');
    const [hasIdInvalidCharacters, setHasIdInvalidCharacters] = useState<boolean>();
    const [project, setProject] = useState<string>('');
    const [customer, setCustomer] = useState<string>('');
    const [owners, setOwners] = useState<Set<string>>(new Set<string>([]));
    const [selectedOwnerPersonas, setSelectedOwnerPersonas] = useState<IPersonaProps[]>([]);

    const canDeleteLastOwner = props.isUserContractAdmin;
    const agencies = props.agencies;

    const isAgencyDropdownEnabled = useFeatureFlag(FeatureFlagKeys.screeningAgencyDropdown).enabled;

    const { disableEdit, shouldDisableSubmit } = useMemo(() => {
        const shouldPreventOwner = // Prevents owner of a contract from editing some fields.
            !props.isUserContractAdmin || props.modalType === ContractsPageModalTypes.changeOwner;

        // Prevents a contract owner who's not an admin from changing status of a non-active contract.
        const shouldPreventStatusChange =
            props.modalType === ContractsPageModalTypes.changeOwner ||
            (!props.isUserContractAdmin &&
                props.contract?.contractStatus !== ContractStatus.Active);

        // Prevents a contract owner who's not an admin from changing owners of a non-active contract.
        const shouldPreventOwnerChange =
            !props.isUserContractAdmin && props.contract?.contractStatus !== ContractStatus.Active;

        const disableEdit = {
            id: props.modalType !== ContractsPageModalTypes.add,
            type: shouldPreventOwner,
            customer: shouldPreventOwner,
            projectName: shouldPreventOwner,
            status: shouldPreventStatusChange,
            agency: shouldPreventOwner,
            owner: shouldPreventOwnerChange,
        };

        const shouldDisableSubmit = Object.values(disableEdit).every((v) => v);

        return { disableEdit, shouldDisableSubmit };
    }, [props.isUserContractAdmin, props.modalType, props.contract?.contractStatus]);

    const determinePersonas = (personnelIds: string[]): IPersonaProps[] => {
        const personasVar: IPersonaProps[] = [];
        personnelIds.forEach((personnelId) => {
            const ownerBasicEmployee = props.basicEmployeesMap.get(personnelId);
            if (!!ownerBasicEmployee) {
                personasVar.push(transformBasicEmployeeToPersona(ownerBasicEmployee));
            }
        });
        return personasVar;
    };

    useEffect(() => {
        if (props.modalType !== ContractsPageModalTypes.add && !!props.contract?.owners) {
            setSelectedOwnerPersonas(determinePersonas(props.contract.owners));
        }
    }, [props.basicEmployeesMap, props.contract?.owners]);

    const contractTypeDropdownOptions = useMemo(
        () =>
            Object.entries(ContractType).map(([key, value]) => {
                return {
                    key: key,
                    id: value,
                    data: key,
                    text: value,
                    itemType: 0,
                };
            }),
        [],
    );

    const contractStatusDropdownOptions = useMemo(
        () =>
            Object.entries(ContractStatus).map(([key, value]) => {
                return {
                    key: key,
                    id: value,
                    data: key,
                    text: value,
                    itemType: 0,
                };
            }),
        [],
    );

    const agencyDropdownOptions = useMemo(
        () =>
            agencies.map((agency) => {
                return {
                    key: agency.name,
                    id: agency.name,
                    data: agency.name,
                    text: agency.name,
                    itemType: 0,
                };
            }),
        [agencies],
    );

    const {
        selectedOption: selectedContractType,
        theElement: contractTypeElement,
        initializeFromKey: initSelectedContractType,
    } = useDropdown({
        label: 'Type',
        options: contractTypeDropdownOptions,
        placeholder: 'Select Contract Type',
        required: true,
        styles: { dropdownItems: globalStyles.dropdownItems },
        disabled: disableEdit.type,
    });

    const {
        selectedOption: selectedContractStatus,
        theElement: contractStatusElement,
        initializeFromKey: initSelectedContractStatus,
    } = useDropdown({
        label: 'Status',
        options: contractStatusDropdownOptions,
        placeholder: 'Select Contract Status',
        required: true,
        styles: { dropdownItems: globalStyles.dropdownItems },
        disabled: disableEdit.status,
    });

    const {
        selectedOption: selectedAgency,
        theElement: agencyElement,
        initializeFromKey: initSelectedAgency,
    } = useDropdown({
        label: 'Agency',
        options: agencyDropdownOptions,
        placeholder: 'Select Agency',
        required: true,
        styles: { dropdownItems: globalStyles.dropdownItems },
        disabled: disableEdit.agency,
    });

    const resetStateValues = (): void => {
        if (props.modalType !== ContractsPageModalTypes.add && props.contract) {
            setContractId(props.contract.id);
            setHasIdInvalidCharacters(false);
            setProject(props.contract.project);
            setCustomer(props.contract.customer);
            setOwners(new Set<string>(props.contract.owners) || new Set<string>());
            setSelectedOwnerPersonas(determinePersonas(props.contract.owners ?? []));
            setIsCreatingContract(false);
            initSelectedContractType(
                props.contract.contractType ? props.contract.contractType : '',
            );
            initSelectedContractStatus(
                props.contract.contractStatus ? props.contract.contractStatus : '',
            );
            initSelectedAgency(props.contract.customer ? props.contract.customer : '');
        } else {
            setContractId('');
            setProject('');
            setCustomer('');
            setOwners(new Set<string>([]));
            setSelectedOwnerPersonas([]);
            setIsCreatingContract(false);
            initSelectedContractType('');
            initSelectedContractStatus('');
            initSelectedAgency('');
        }
    };

    const onIdChange = (
        event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue: string | undefined,
    ): void => {
        setContractId(newValue ?? '');
    };

    const validateContractId = (value: string): JSX.Element | undefined => {
        if (hasCosmostDbIdSpecialCharacters(value)) {
            setHasIdInvalidCharacters(true);
            return (
                <>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.error}>
                        <p>Correct the following error(s) and try again:</p>
                        <ul>
                            <li>
                                Check that the ID does not include special characters ({' '}
                                {cosmosDbIdSpecialCharacters.join(', ')} )
                            </li>
                        </ul>
                    </MessageBar>
                </>
            );
        }
        setHasIdInvalidCharacters(false);
        setContractId((currentValue) => currentValue.trim().replace(/\s+/g, ' '));
    };

    const onCustomerChange = (
        event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue: string | undefined,
    ): void => {
        setCustomer(newValue ?? '');
    };

    const onProjectChange = (
        event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue: string | undefined,
    ): void => {
        setProject(newValue ?? '');
    };

    const onSubmit = async (): Promise<void> => {
        try {
            // if agency drowndown feature flag is enabled, then use agency dropdown value as customer
            const customerValue = isAgencyDropdownEnabled
                ? selectedAgency
                    ? selectedAgency.key.toString()
                    : ''
                : customer;
            const request: IContractRequest = {
                id: contractId,
                project: project,
                customer: customerValue,
                owners: Array.from(owners.values()),
                contractType: selectedContractType
                    ? (selectedContractType.key as keyof typeof ContractType)
                    : 'USGovScreening',
                contractStatus: selectedContractStatus
                    ? (selectedContractStatus.key as string)
                    : 'Active',
            };
            setIsCreatingContract(true);

            let result;
            if (props.modalType === ContractsPageModalTypes.add) {
                result = await UsGovScreeningClient.createContract(authContext, request);
            } else {
                result = await UsGovScreeningClient.editContractById(authContext, request);
            }

            props.contractsCallback(result, props.modalType);
        } catch (e) {
            if (props.modalType === ContractsPageModalTypes.add) {
                console.error('Error creating contract with error: ', e);
            } else {
                console.error('Error updating contract with error: ', e);
            }
            throw e;
        } finally {
            setIsCreatingContract(false);
        }
    };

    const enableSubmit = (): boolean => {
        // Variable isEnabled1 and isEnabled2 are introduced
        // to simplify the logic equation. Since they cover
        // various conditions, it's hard to choose a symbolic
        // name for them.
        const canSubmitCustomerAgency = isAgencyDropdownEnabled
            ? !!selectedAgency
            : customer?.length > 0;
        const isEnabled1 =
            contractId?.length > 0 &&
            !hasIdInvalidCharacters &&
            !!selectedContractType &&
            canSubmitCustomerAgency &&
            project?.length > 0 &&
            !!selectedContractStatus;

        let isEnabled2: boolean;
        switch (props.modalType) {
            case ContractsPageModalTypes.add:
                // When creating a contract, enforce at least one owner.
                isEnabled2 = owners.size > 0;
                break;
            case ContractsPageModalTypes.edit:
            case ContractsPageModalTypes.changeOwner:
                // When updating a contract, only allow
                // contract admins to remove the last owner.
                isEnabled2 = canDeleteLastOwner || owners.size > 0;
        }
        return !shouldDisableSubmit && isEnabled1 && isEnabled2;
    };

    const showContent = (): JSX.Element => {
        return (
            <>
                <TextField
                    label='ID'
                    rows={1}
                    required={true}
                    value={contractId}
                    resizable={false}
                    onChange={onIdChange}
                    disabled={disableEdit.id}
                    validateOnFocusOut
                    onGetErrorMessage={validateContractId}
                />

                {contractTypeElement()}

                {isAgencyDropdownEnabled ? (
                    agencyElement()
                ) : (
                    <TextField
                        label='Customer'
                        rows={1}
                        required={true}
                        value={customer}
                        resizable={false}
                        onChange={onCustomerChange}
                        disabled={disableEdit.customer}
                    />
                )}

                <TextField
                    label='Project name'
                    rows={1}
                    required={true}
                    value={project}
                    resizable={false}
                    onChange={onProjectChange}
                    disabled={disableEdit.projectName}
                />

                {contractStatusElement()}

                <EmployeePickerTypeaheadSearch
                    label='Owners'
                    // If the modal is opened in add mode (as opposed to edit mode,)
                    // or if current user is not a contract admin, make having at least
                    // one owner mandatory.
                    // In other words, only allow a contract admin to remove the last owner.
                    required={
                        props.modalType === ContractsPageModalTypes.add || !canDeleteLastOwner
                    }
                    placeHolder='Search Employees'
                    itemLimit={-1}
                    selectedItems={selectedOwnerPersonas}
                    onMultipleCandidatesSelected={(personas?: IPersonaProps[]): void => {
                        const ownerIdSet: Set<string> = new Set<string>([]);
                        const personaMap: Map<string, IPersonaProps> = new Map<
                            string,
                            IPersonaProps
                        >([]);
                        personas?.forEach((persona) => {
                            if (persona.itemProp) {
                                try {
                                    const ownerId = JSON.parse(persona.itemProp).id;
                                    ownerIdSet.add(ownerId);
                                    personaMap.set(ownerId, persona);
                                } catch (e) {
                                    console.error(
                                        'Could not parse item. Could not add owner.',
                                        persona,
                                    );
                                    console.error(e);
                                }
                            } else {
                                console.error('Incomplete persona. Could not add owner.', persona);
                            }
                        });

                        setOwners(ownerIdSet);
                        setSelectedOwnerPersonas(personas ?? []);
                    }}
                    disabled={disableEdit.owner}
                />

                <IsLoadingIndicator
                    isLoading={isCreatingContract}
                    before={<Spacer marginTop={20} />}
                    msg={
                        props.modalType === ContractsPageModalTypes.add
                            ? 'Creating contract...'
                            : 'Updating contract...'
                    }
                />
            </>
        );
    };

    const modalTitle = useMemo(() => {
        switch (props.modalType) {
            case ContractsPageModalTypes.add:
                return 'Add contract';
            case ContractsPageModalTypes.edit:
                return 'Manage contract';
            case ContractsPageModalTypes.changeOwner:
                return 'Add/remove contract owner';
        }
    }, [props.modalType]);

    return (
        <ModalActionButton<boolean | undefined>
            text={
                props.modalType === ContractsPageModalTypes.changeOwner
                    ? 'Add/remove contract owner'
                    : props.modalType + ' contract'
            }
            enable={props.modalType !== ContractsPageModalTypes.add ? !!props.contract : true}
            iconName={IconNames.AddToShoppingList}
            modalTitle={modalTitle}
            enableSubmit={enableSubmit()}
            submitButtonText={props.modalType === ContractsPageModalTypes.add ? 'Create' : 'Update'}
            shouldHideCancelButton={false}
            keepOpenAfterSubmit={false}
            onButtonClick={resetStateValues}
            onSubmit={onSubmit}
            onModalConcluded={doNothing}
            size={ModalSizeType.size700}
            fixWidth={true}>
            {showContent()}
        </ModalActionButton>
    );
}
