import React, { useContext, useEffect, useState } from 'react';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { IPersonaProps } from '@fluentui/react';
import EmployeeClient, { IEmployee } from 'clients/employee-client';
import { transformEmployeeToPersona } from 'utils/internal-persona-utils';
import { AuthContext } from 'contexts/auth-context';
import { useCheckMountedState, useIsMounted } from 'utils/misc-hooks';
import { CacheContext } from 'contexts/cache-context';

/** ***************************************************
 * This hook is intended for single person select only.
 * If you need multi select, create a new hook for it.
 * ****************************************************
 */
interface IEmployeePickerParams {
    label?: string;
    disabled?: boolean;
    placeHolder?: string;
    selectedPersona?: IPersonaProps;
    required?: boolean;
    blockSelfSponsor?: boolean;
    validate?: () => boolean;
    onError?: (msg: string) => void;
}

export interface IInitEmployeePickerParams {
    personnelId?: string;
    employee?: IEmployee;
}

export interface IUseEmployeePicker {
    value: IEmployee | undefined;
    isValid: boolean | undefined;
    hasChanged: boolean | undefined;
    initialize: (initValue?: IInitEmployeePickerParams) => void;
    theElement: () => JSX.Element;
}

export function useEmployeePicker(params?: IEmployeePickerParams): IUseEmployeePicker {
    const authContext = useContext(AuthContext);
    const cacheContext = useContext(CacheContext);

    const [employee, setEmployee] = useState<IEmployee>();
    const [initialEmployee, setInitialEmployee] = useCheckMountedState<IEmployee | undefined>(
        undefined,
    );
    const [selectedPersona, setSelectedPersona] = useState<IPersonaProps | undefined>();
    const [isValid, setIsValid] = useCheckMountedState<boolean | undefined>(undefined);

    const isMounted = useIsMounted();

    const onEmployeeSelectHandler = (info?: IPersonaProps): void => {
        if (info?.itemProp) {
            const person = JSON.parse(info?.itemProp);
            const isSponsorSelf =
                authContext.getUserProfile()?.alias.toLowerCase() === person.alias.toLowerCase();

            if (params?.blockSelfSponsor && isSponsorSelf) {
                setEmployee(undefined);
                setSelectedPersona(undefined);
                if (!!params?.onError) {
                    params?.onError('Cannot set sponsor to self');
                } else {
                    console.error(`Cannot set sponsor to self`);
                }

                return;
            }
            if (person.id) {
                setEmployee(person);
                setSelectedPersona(transformEmployeeToPersona(person, undefined, cacheContext));
                return;
            }
        }
        setEmployee(undefined);
        setSelectedPersona(undefined);
    };

    const fetchEmployee = async (personnelId: string): Promise<void> => {
        try {
            const employees = await EmployeeClient.searchEmployees(personnelId, authContext);
            const employeeVar = employees.find((employee) => employee.id === personnelId);
            if (!!employeeVar) {
                if (isMounted()) {
                    setEmployee(employeeVar);
                    setInitialEmployee(employeeVar);
                    setSelectedPersona(
                        transformEmployeeToPersona(employeeVar, undefined, cacheContext),
                    );
                }
            } else {
                if (isMounted()) {
                    setEmployee(undefined);
                    setInitialEmployee(undefined);
                    setSelectedPersona(undefined);
                }
                reportErrorFetchingEmployee();
            }
        } catch {
            reportErrorFetchingEmployee();
        }

        function reportErrorFetchingEmployee(): void {
            if (!!params?.onError) {
                params?.onError('Error fetching employee information');
            } else {
                console.error(`Error fetching information for employee ${personnelId}`);
            }
        }
    };

    const initialize = (initValue?: IInitEmployeePickerParams): void => {
        if (!!initValue?.personnelId) {
            fetchEmployee(initValue.personnelId);
        } else if (!!initValue?.employee) {
            setEmployee(initValue.employee);
            setInitialEmployee(initValue.employee);
            setSelectedPersona(
                transformEmployeeToPersona(initValue.employee, undefined, cacheContext),
            );
        } else {
            setEmployee(undefined);
            setInitialEmployee(undefined);
            setSelectedPersona(undefined);
        }
    };

    useEffect(() => {
        setIsValid(!!params?.validate ? params.validate() : undefined);
    }, [employee, params?.validate]);

    return {
        value: employee,
        isValid,
        hasChanged: employee?.id !== initialEmployee?.id,
        initialize,
        theElement: () => (
            <EmployeePickerTypeaheadSearch
                label={params?.label ?? ''}
                ariaLabel={params?.label ?? ''}
                disabled={params?.disabled}
                required={params?.required}
                placeHolder={'Employee Name or Alias'}
                onCandidateSelected={onEmployeeSelectHandler}
                selectedItems={!!selectedPersona ? [selectedPersona] : []}
                // Do NOT use the following props in this hook
                // because they are related to multi select.
                // itemLimit?: number;
                // selectedItems?: IPersonaProps[];
            />
        ),
    };
}
