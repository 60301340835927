import React, { useContext, useEffect, useMemo } from 'react';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import { ISecurityGroup } from 'clients/group-client';
import { Stack, IColumn } from '@fluentui/react';
import { Table, TableCell } from 'components/common/table';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import Spacer from 'components/common/spacer';
import BoldFont from 'components/common/misc/bold-font';

type SelectedGroupInfoType = {
    name: JSX.Element | undefined;
    email: JSX.Element | undefined;
    type: string | undefined;
    options: string[] | undefined;
};

interface IConfirmSelectionProps {
    selectedSecurityGroup: ISecurityGroup | undefined;
    initHasAccepted: (hasAccepted?: boolean) => void;
    hasAcceptedCheckbox: () => JSX.Element;
}

export default function ConfirmSelection(props: IConfirmSelectionProps): JSX.Element {
    const groupContext = useContext(ManageGroupContext);

    useEffect(() => {
        props.initHasAccepted(false);
    }, []);

    const selectedGroupTableRow: SelectedGroupInfoType[] = useMemo(() => {
        return [
            {
                name: <BoldFont>{props.selectedSecurityGroup?.name}</BoldFont>,
                email: <Stack.Item>{props?.selectedSecurityGroup?.mail}</Stack.Item>,
                type: props.selectedSecurityGroup?.type,
                options: props.selectedSecurityGroup?.options,
            },
        ];
    }, [props.selectedSecurityGroup]);

    return (
        <Stack>
            <Table
                tableColumns={columns}
                isFetchingData={false}
                rows={selectedGroupTableRow}
                tableName='Selected Group'
            />
            <Spacer marginTop={20} />
            <Stack.Item>When you click next,</Stack.Item>
            <Stack.Item>
                <ul>
                    <li>
                        <BoldFont>{groupContext.group?.name}</BoldFont>
                        <span> will be associated with </span>
                        <BoldFont>{props.selectedSecurityGroup?.name}</BoldFont>.
                    </li>
                    <li>
                        <div>An authoritative sync will commence in the next sync cycle.</div>
                        <div>
                            <span>Compliant members of the Personnel group </span>
                            <BoldFont>{groupContext.group?.name}</BoldFont>
                            <span> will be added to the linked group.</span>
                        </div>
                        <div>
                            All other members in the linked group, including users and groups, will
                            be removed.
                        </div>
                    </li>
                </ul>
            </Stack.Item>
            <Stack.Item>{props.hasAcceptedCheckbox()}</Stack.Item>
        </Stack>
    );
}

const columns: IColumn[] = [
    {
        key: 'Name',
        name: 'Name',
        ariaLabel: 'Name',
        minWidth: 190,
        maxWidth: 190,
        onRender: (row: SelectedGroupInfoType): JSX.Element => {
            return <TableCell>{row.name}</TableCell>;
        },
    },
    {
        key: 'Email',
        name: 'Email',
        ariaLabel: 'Email',
        minWidth: 200,
        maxWidth: 200,
        onRender: (row: SelectedGroupInfoType): JSX.Element => {
            return <TableCell>{row.email}</TableCell>;
        },
    },
    {
        key: 'Type',
        name: 'Type',
        ariaLabel: 'Type',
        minWidth: 70,
        maxWidth: 70,
        onRender: (row: SelectedGroupInfoType): JSX.Element => {
            return <TableCell>{row.type}</TableCell>;
        },
    },
    {
        key: 'Options',
        name: 'Options',
        ariaLabel: 'Options',
        minWidth: 70,
        maxWidth: 70,
        onRender: (row: SelectedGroupInfoType): JSX.Element => {
            return (
                <TableCell>
                    <EllipsisTextCss text={row.options?.join(', ')} />
                </TableCell>
            );
        },
    },
];
