import React, { ReactNode, createContext, useReducer } from 'react';
import { Dictionary } from 'assets/constants/global-constants';

type FilterKeyType = keyof contextStateType | 'reset';

type FilterDataType = Dictionary<boolean> | undefined | Date;

type contextStateType = {
    fromDate: Date | undefined;
    toDate: Date | undefined;
    categories: Dictionary<boolean>;
    resetAll: () => void;
    setFilter: (key: FilterKeyType, value: FilterDataType) => void;
};

type actionType = {
    type: string;
    payload: FilterDataType;
};

interface IEligibilitiesFilterProviderProps {
    children: ReactNode;
}

export default function EligibilitiesFilterProvider(
    props: IEligibilitiesFilterProviderProps,
): JSX.Element {
    const reducer = (state: contextStateType, action: actionType): contextStateType => {
        let result;
        switch (action.type) {
            case 'fromDate':
            case 'toDate':
                result = {
                    ...state,
                    [action.type]: action.payload,
                };
                break;
            case 'categories':
                result = {
                    ...state,
                    categories: {
                        ...state.categories,
                        ...(action.payload as Dictionary<boolean>),
                    },
                };
                break;
            case 'reset':
                result = {
                    ...filterInitValue(),
                };
                break;
            default:
                result = state;
                break;
        }
        return result;
    };

    const filterInitValue = (): contextStateType => {
        return {
            fromDate: undefined,
            toDate: undefined,
            categories: {},
            resetAll,
            setFilter,
        };
    };

    const [contextValue, dispatch] = useReducer(reducer, filterInitValue());

    function setFilter(key: FilterKeyType, value: FilterDataType): void {
        dispatch({ type: key, payload: value });
    }

    function resetAll(): void {
        dispatch({ type: 'reset', payload: undefined });
    }

    return (
        <EligibilitiesFilterContext.Provider value={contextValue}>
            {props.children}
        </EligibilitiesFilterContext.Provider>
    );
}

export const EligibilitiesFilterContext = createContext<contextStateType>(null!);
