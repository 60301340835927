import FacilitiesClient, {
    IFacilityRecord,
    IReservationRecord,
    ISeatRecord,
    IProvisionInfo,
    IFacilityTimeslotItem,
    IReservationClaim,
    ReservationActivityName,
    ActivityObject,
    IFacilityReservationInfo,
} from 'clients/facilities-client';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import React, { useContext, useEffect, useState } from 'react';
import UserReservationTable from 'components/facilities/facilities-reservations/facilities-user-reservation-table-view/facilities-user-reservation-table';
import { useIsMounted } from 'utils/misc-hooks';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { IFacilityReservationTableColumn } from 'components/facilities/facilities-reservations/facilities-user-reservation-table-view/facilities-user-reservation-columns';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import ActivitiesClient, { IActivity, IActivityReadRequest } from 'clients/activities-client';
import { ITimestampChange } from 'clients/cloud-screening-client';
import useMessageBar from 'components/common/use-message-bar';
import { MessageBarType, Stack } from '@fluentui/react';
import { DefaultTimeZone } from 'components/facilities/common/facility-time-utils';
import { IFacilityPastReservationTableColumn } from 'components/facilities/facilities-reservations/facilities-user-reservation-table-view/facilities-user-past-reservation-columns';
import { Dictionary } from 'assets/constants/global-constants';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import FacilitiesUserReservationFilter from 'components/facilities/facilities-reservations/facilities-user-reservation-table-view/facilities-user-reservation-filter';

export interface FacilitiesUserReservationTableViewProps {
    facilities: IFacilityRecord[];
    reservations?: IReservationRecord[];
    onModalConcluded?: (modalConclusion: ModalConclusion) => void;
}

interface ISelectedFacility {
    facilityId: string;
    facilityName: string;
}
export interface IFilterCriteria {
    startDate?: Date;
    endDate?: Date;
    selectedFacility?: ISelectedFacility;
    statuses: string[];
}

export default function FacilitiesUserReservationTableView(
    props: FacilitiesUserReservationTableViewProps,
): JSX.Element {
    const isMounted = useIsMounted();
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [reservations, setReservations] = useState<IFacilityReservationTableColumn[]>();
    const [unfilteredReservations, setUnfilteredReservations] = useState<
        IFacilityReservationTableColumn[]
    >();
    const [displayedReservations, setDisplayedReservations] = useState<
        IFacilityReservationTableColumn[]
    >();
    const [pastReservations, setPastReservations] = useState<
        IFacilityPastReservationTableColumn[]
    >();
    const [filterCriteria, setFilterCriteria] = useState<IFilterCriteria>({ statuses: [] });

    const {
        theMessage: pastReservationErrorMsg,
        theElement: pastReservationErrMsgElement,
        setMessage: setPastReservationErrMsg,
    } = useMessageBar({
        type: MessageBarType.error,
    });

    async function fetchUserReservations(): Promise<void> {
        const currentTimeUtc = Date.now();

        if (props.facilities) {
            const userReservations =
                props.reservations ??
                (await FacilitiesClient.getAllMyReservations(authContext, userContext));

            const reservationsWithFacilityInfo = userReservations
                .filter((x) => x.reservationTimeSlot.endDateTimeUTCMilliseconds > currentTimeUtc)
                .map((r) => {
                    return {
                        reservationRecord: r,
                        facilityRecord: props.facilities.find((f) => f.id === r.facilityId),
                    };
                });

            const reservationsWithSeatInfo = await Promise.all(
                reservationsWithFacilityInfo.map(async (r) => {
                    try {
                        const seatRecord = await FacilitiesClient.getSeatById(
                            authContext,
                            userContext,
                            r.reservationRecord.seatId,
                        );

                        const reservationActivities = await getCurrentReservationCheckInCheckOutActivities(
                            r.reservationRecord,
                        );
                        const checkInActivity = reservationActivities?.find(
                            (a) => a.event === ReservationActivityName.ReservationCheckedIn,
                        );
                        const checkOutActivity = reservationActivities?.find(
                            (a) => a.event === ReservationActivityName.ReservationCheckedOut,
                        );

                        return {
                            ...r,
                            seatRecord: seatRecord,
                            checkInTime:
                                checkInActivity &&
                                parseInt(
                                    checkInActivity.metadata[
                                        ActivityObject.ReservationCheckedInTime
                                    ],
                                ),
                            checkOutTime:
                                checkOutActivity &&
                                parseInt(
                                    checkOutActivity.metadata[
                                        ActivityObject.ReservationCheckedOutTime
                                    ],
                                ),
                        };
                    } catch (seatNotFoundException) {
                        return {
                            ...r,
                            seatRecord: undefined,
                            checkInTime: undefined,
                            checkOutTime: undefined,
                        };
                    }
                }),
            );

            if (isMounted()) {
                setReservations(reservationsWithSeatInfo);
                setDisplayedReservations(reservationsWithSeatInfo);
            }
        }
    }

    async function getCurrentReservationCheckInCheckOutActivities(
        reservationRecord: IReservationRecord,
    ): Promise<IActivity[] | undefined> {
        const facilitiesToken = await FacilitiesClient.getFacilitiesToken(authContext);
        if (!facilitiesToken) return;

        try {
            const token = await FacilitiesClient.getFacilityActivityToken(
                authContext,
                facilitiesToken,
            );

            const activityRequest: IActivityReadRequest = {
                events: [
                    ReservationActivityName.ReservationCheckedOut,
                    ReservationActivityName.ReservationCheckedIn,
                ],
                tags: [reservationRecord.id],
            };

            const activities = await ActivitiesClient.getAllFacilityActivitiesForUserActivityEvents(
                authContext,
                token,
                facilitiesToken,
                activityRequest,
            );

            return activities;
        } catch (e) {
            console.error(e);
        }
    }

    async function getPastReservationActivities(): Promise<void> {
        const facilitiesToken = await FacilitiesClient.getFacilitiesToken(authContext);
        if (!facilitiesToken) return;

        try {
            const token = await FacilitiesClient.getFacilityActivityToken(
                authContext,
                facilitiesToken,
            );

            const activityRequest: IActivityReadRequest = {
                events: [
                    ReservationActivityName.ReservationCheckedOut,
                    ReservationActivityName.ReservationCheckedIn,
                    ReservationActivityName.ReservationConfirmed,
                    ReservationActivityName.ReservationSeatChanged,
                ],
                tags: [userContext.employeeRecord.id],
            };

            const activities = await ActivitiesClient.getAllFacilityActivitiesForUserActivityEvents(
                authContext,
                token,
                facilitiesToken,
                activityRequest,
            );

            if (isMounted()) {
                getReservationDataFromActivities(activities);
            }
        } catch (e) {
            console.error(e);
            setPastReservationErrMsg('An error occurred retrieving/parsing past reservations.');
        }
    }

    function getReservationDataFromActivities(activities: IActivity[]): void {
        const varPastReservations: IFacilityPastReservationTableColumn[] = [];

        if (activities) {
            const currentDate = new Date();
            const currentMilliseconds = currentDate.getTime();
            const activityMetadataEventByReservationId = activities
                .filter(
                    (act) =>
                        currentMilliseconds >
                        parseInt(act.metadata[ActivityObject.ReservationEndTime]),
                )
                .map((a) => ({
                    reservationId: a.additionalObjects.find(
                        (ao) => ao.type === ActivityObject.ReservationType,
                    )?.value,
                    facilityId: a.additionalObjects.find(
                        (ao) => ao.type === ActivityObject.FacilityType,
                    )?.value,
                    seatId: a.additionalObjects.find((ao) => ao.type === ActivityObject.SeatType)
                        ?.value,
                    metadata: a.metadata,
                    eventType: a.event,
                    additionalObjects: a.additionalObjects,
                    eventTimeStampUtc: a.eventTimestampUTC,
                }));

            const checkOuts = activityMetadataEventByReservationId.filter(
                (s) => s.eventType === ReservationActivityName.ReservationCheckedOut,
            );
            const checkIns = activityMetadataEventByReservationId.filter(
                (s) => s.eventType === ReservationActivityName.ReservationCheckedIn,
            );
            const confirmed = activityMetadataEventByReservationId.filter(
                (s) => s.eventType === ReservationActivityName.ReservationConfirmed,
            );

            const seatChangedIds = activityMetadataEventByReservationId.filter(
                (s) => s.eventType === ReservationActivityName.ReservationSeatChanged,
            );

            confirmed.forEach((c) => {
                const seatChangeActivity = seatChangedIds.find(
                    (sc) =>
                        sc.additionalObjects &&
                        sc.additionalObjects.find((ao) => ao.type === 'oldReservationId')?.value ===
                            c.reservationId &&
                        sc.additionalObjects.find((ao) => ao.type === 'oldSeatId')?.value ===
                            c.seatId,
                );

                if (!seatChangeActivity) {
                    const facilityId = c.facilityId ?? '';
                    const seatId = c.seatId ?? '';

                    const pastReservationFacilityColumn: IFacilityPastReservationTableColumn = getFacilityReservationTableColumn(
                        undefined,
                        undefined,
                        facilityId,
                        seatId,
                        c.metadata,
                        c.eventType,
                    );

                    varPastReservations.push(pastReservationFacilityColumn);
                }
            });

            checkIns.forEach((c) => {
                const reservationCheckOutTime = checkOuts.find(
                    (co) => co.reservationId === c.reservationId,
                )?.metadata[ActivityObject.ReservationCheckedOutTime];
                const facilityId = c.facilityId ?? '';
                const seatId = c.seatId ?? '';

                const pastReservationFacilityColumn: IFacilityPastReservationTableColumn = getFacilityReservationTableColumn(
                    c.metadata[ActivityObject.ReservationCheckedInTime],
                    reservationCheckOutTime,
                    facilityId,
                    seatId,
                    c.metadata,
                    reservationCheckOutTime
                        ? ReservationActivityName.ReservationCheckedOut
                        : ReservationActivityName.ReservationCheckedIn,
                );
                varPastReservations.push(pastReservationFacilityColumn);
            });

            const sortedPastReservations = varPastReservations.sort((a, b) =>
                b.reservationRecord.reservationTimeSlot.startDateTimeUTCMilliseconds >
                a.reservationRecord.reservationTimeSlot.startDateTimeUTCMilliseconds
                    ? 1
                    : -1,
            );
            setPastReservations(sortedPastReservations);
        }
    }

    function getFacilityReservationTableColumn(
        reservationCheckInTime: string | undefined,
        reservationCheckOutTime: string | undefined,
        facilityId: string,
        seatId: string,
        metaData: Dictionary<string>,
        eventType: string,
    ): IFacilityPastReservationTableColumn {
        const pastReservationFacilityTimeSlotItem: IFacilityTimeslotItem = {
            startDateTimeUTCMilliseconds: parseInt(metaData[ActivityObject.ReservationStartTime]),
            endDateTimeUTCMilliseconds: parseInt(metaData[ActivityObject.ReservationEndTime]),
        };

        const emptySeatProvisionInfo: IProvisionInfo = {
            provisionType: '',
        };

        const emptyTimeStampChange: ITimestampChange = {
            by: 'by',
            atUtc: 0,
        };

        const facilityTimeZone = props.facilities.find((fa) => fa.id === facilityId)?.timeZone;
        const pastReservationFacilityRecord: IFacilityRecord = {
            facilityName: metaData[facilityId],
            id: facilityId,
            facilityType: '',
            facilityId: facilityId,
            recordType: 'Facility',
            createdTimestamp: emptyTimeStampChange,
            lastModified: emptyTimeStampChange,
            lastOperation: '',
            facilityStatus: '',
            requiredEligibilities: [],
            cssoPointsOfContact: [],
            issoPointsOfContact: [],
            itPointsOfContact: [],
            facilityReservationInfo: {} as IFacilityReservationInfo,
            timeZone: facilityTimeZone !== undefined ? facilityTimeZone : DefaultTimeZone,
            isEquipmentEnabled: true,
            facilitySupportPointOfContact: '',
        };

        const pastReservationSeatRecord: ISeatRecord = {
            id: seatId,
            facilityId: facilityId,
            recordType: 'Seat',
            seatName: metaData[seatId],
            isOutOfOrder: false,
            provisionInfo: emptySeatProvisionInfo,
            createdTimestamp: emptyTimeStampChange,
            lastModified: emptyTimeStampChange,
            lastOperation: '',
            qualifiers: [],
        };

        const emptyClaimCode: IReservationClaim = {
            code: '',
            expiresDateTimeUTCMilliseconds: 0,
        };

        const pastReservationRecord: IReservationRecord = {
            id: '',
            facilityId: facilityId,
            seatId: seatId,
            personnelId: '',
            state: '',
            claimCode: emptyClaimCode,
            reservationTimeSlot: pastReservationFacilityTimeSlotItem,
            createdTimestamp: emptyTimeStampChange,
            lastModified: emptyTimeStampChange,
            lastOperation: '',
        };

        return {
            facilityRecord: pastReservationFacilityRecord,
            seatRecord: pastReservationSeatRecord,
            reservationRecord: pastReservationRecord,
            checkInTime: reservationCheckInTime ? parseInt(reservationCheckInTime) : undefined,
            checkOutTime: reservationCheckOutTime ? parseInt(reservationCheckOutTime) : undefined,
            columnReservationActivityState: eventType,
        };
    }

    // TODO: Determine if we can do an update without refetching in the future.
    async function onModalConcluded(modalConclusion: ModalConclusion): Promise<void> {
        await fetchUserReservations();

        if (props.onModalConcluded) {
            props.onModalConcluded(modalConclusion);
        }
    }

    async function fetchUserAndPastReservations(): Promise<void> {
        await fetchUserReservations();
        await getPastReservationActivities();
    }

    useEffect(() => {
        if (props.facilities && props.facilities.length > 0) {
            fetchUserAndPastReservations();
        }
    }, [props.facilities]);

    useEffect(() => {
        if (reservations && pastReservations) {
            const combined = [...reservations, ...pastReservations];
            setUnfilteredReservations(combined);
            setDisplayedReservations(combined);
        }
    }, [reservations, pastReservations]);

    const userReservationFilter = (): JSX.Element | undefined => {
        if (reservations && displayedReservations) {
            return (
                <FacilitiesUserReservationFilter
                    facilities={props.facilities}
                    unfilteredReservations={unfilteredReservations}
                    displayedReservations={displayedReservations}
                    setDisplayedReservations={setDisplayedReservations}
                    filterCriteria={filterCriteria}
                    setFilterCriteria={setFilterCriteria}
                />
            );
        }
    };

    return (
        <>
            <SidebarAndContents>
                <SidebarPane sidebarPaneOuterStyle={{ width: '240px' }}>
                    {userReservationFilter()}
                </SidebarPane>
                <ContentPane contentPaneOuterStyle={{ width: 'calc(100% - 260px)' }}>
                    {<>{pastReservationErrMsgElement()}</>}
                    <HorizontalBar>
                        <Stack.Item grow={100}>
                            <span className={horizontalBarTitleStyle}>My Reservations</span>
                        </Stack.Item>
                    </HorizontalBar>
                    {displayedReservations && (
                        <UserReservationTable
                            isFetchingData={displayedReservations === undefined}
                            reservations={displayedReservations}
                            setReservations={setDisplayedReservations}
                            onModalConcluded={onModalConcluded}
                            shimmerLabel='Searching for your previous reservations'
                            tableName='My Reservations'
                            isCollapsed={true}
                        />
                    )}
                </ContentPane>
            </SidebarAndContents>
        </>
    );
}
