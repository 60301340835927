import React, { useContext } from 'react';
import {
    IStackTokens,
    Stack,
    mergeStyleSets,
    Separator,
    ChoiceGroup,
    IChoiceGroupOption,
} from '@fluentui/react';
import { StaffingServiceMapFilterContext } from 'components/staffing/contexts/staffing-service-map-filter-context';
import { globalSeparatorStyles } from 'assets/styles/global-styles';

const styles = mergeStyleSets({
    top: {
        marginBottom: '15px',
    },
});

const filterStackTokens: IStackTokens = {
    childrenGap: 5,
};

export default function StaffingServiceMapFilters(): JSX.Element {
    const filterContext = useContext(StaffingServiceMapFilterContext);

    const selectMappingHandler = (
        a?: React.FormEvent<HTMLElement>,
        selection?: IChoiceGroupOption,
    ): void => {
        if (selection) {
            filterContext.setFilter('mapping', selection);
        }
    };

    const selectStatusHandler = (
        a?: React.FormEvent<HTMLElement>,
        selection?: IChoiceGroupOption,
    ): void => {
        if (selection) {
            filterContext.setFilter('status', selection);
        }
    };

    return (
        <div className={styles.top}>
            <Stack tokens={filterStackTokens}>
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Mapping
                </Separator>
                <ChoiceGroup
                    selectedKey={filterContext.mapping.key}
                    options={filterContext.mappingChoices}
                    onChange={selectMappingHandler}
                />
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Status
                </Separator>
                <ChoiceGroup
                    selectedKey={filterContext.status.key}
                    options={filterContext.statusChoices}
                    onChange={selectStatusHandler}
                />
            </Stack>
        </div>
    );
}
