import React from 'react';
import { IStep, StepTime } from 'types/screening-step';

export function HorizontalNotches(props: {
    steps: IStep[];
    width: number;
    radius: number;
    color: string;
}) {
    const { width, steps, radius, color } = props;
    const strokeWidth = 1;
    const cy = radius + strokeWidth;
    return (
        <svg width={width} height={radius * 2} xmlns='http://www.w3.org/2000/svg'>
            <g>
                <line
                    x1={radius + strokeWidth}
                    y1={radius + strokeWidth / 2}
                    x2={width - strokeWidth}
                    y2={radius + strokeWidth / 2}
                    strokeWidth={strokeWidth}
                    stroke={color}
                />

                {steps.map((step, index) => (
                    <Notch
                        key={index}
                        color={color}
                        cx={radius + getNotchLeftXCoordinate(width, radius, index, steps)}
                        cy={cy}
                        radius={radius}
                        step={step}
                        strokeWidth={strokeWidth}
                    />
                ))}
            </g>
        </svg>
    );
}

function Notch(props: {
    cx: number;
    cy: number;
    color: string;
    strokeWidth: number;
    radius: number;
    step: IStep;
}) {
    const { cx, cy, color, radius, step } = props;
    const strokeWidth = props.strokeWidth < 1 || props.strokeWidth > 5 ? 1 : props.strokeWidth;
    const common = { cx, cy };

    return (
        <g>
            <circle {...common} fill={color} r={radius - strokeWidth} />
            {[StepTime.CURRENT, StepTime.FUTURE].includes(step.time) && (
                <circle {...common} fill='#ffffff' r={radius - strokeWidth * 2} />
            )}
            {step.time === StepTime.CURRENT && (
                <circle {...common} fill={color} r={radius - strokeWidth * 3.5} />
            )}
        </g>
    );
}

export function getNotchLeftXCoordinate(
    width: number,
    radius: number,
    index: number,
    steps: any[],
) {
    return index * ((width - radius * 2) / (steps.length - 1));
}
