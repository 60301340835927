import { noDataText } from 'assets/constants/global-constants';
import { detailsListStyles } from 'assets/styles/list-styles';
import { ScreeningPageNames } from 'components/common/constants';
import EllipsisText from 'components/common/ellipsis-text';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { EmployeeNameResolverId } from 'components/common/employee/employee-name-resolver';
import { TableCell } from 'components/common/table';
import {
    getStatusText,
    ScreeningPaths,
    ScreeningRequestTypesLabels,
    StateName,
} from 'components/screening/common/common-constants';
import { tableColumnStyles } from 'components/screening/common/common-tab-styling';
import { getCommonScreeningStatusLabel } from 'components/screening/common/common-ui';
import ContractOwnerAccept from 'components/screening/common/contract-owner/contract-owner-accept';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import NomineeAccept from 'components/screening/common/nominee-accept';
import { Icon, Stack, IColumn } from '@fluentui/react';
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { generateRandomKey } from 'utils/misc-utils';
import { ISortableColumns } from 'utils/sort-utils';
import { dateToFormattedDateTimeString } from 'utils/time-utils';
import {
    SuitabilityAgencies,
    SuitabilityLevels,
} from 'components/personnel-profile/suitability/profile-suitability-types';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/suitability/profile-suitability-utils';

interface IPublicTrustCandidates extends ISortableColumns {
    updateScreeningInRawCandidates: (screen: ICommonScreening) => void;
    isInitialEmployeeBatchLoaded: boolean;
    openDetails: (screening: ICommonScreening) => void;
    pageName: string;
}

export enum publicTrustTableColumns {
    Requested = 'Requested',
    Candidate = 'Candidate',
    Type = 'Type',
    RequestAgency = 'Request Agency',
    RequestType = 'Request Type',
    ContractId = 'Contract ID',
    Status = 'Status',
    Substatus = 'Sub-Status',
    ProcessOwner = 'Process Owner',
    StatusChanged = 'Status Changed',
    Actions = 'Actions',
    Action = 'Action',
    SuitabilityLevel = 'Suitability Level',
}

export function getPublicTrustTableColumns(args: IPublicTrustCandidates): IColumn[] {
    const {
        sortColumn,
        sortAscending: isSortedAscending,
        sortColumnHandler,
        // TODO: for later when we implement action button
        pageName,
        updateScreeningInRawCandidates,
        isInitialEmployeeBatchLoaded,
    } = args;
    return [
        {
            key: 'candidate',
            name: publicTrustTableColumns.Candidate,
            ariaLabel: publicTrustTableColumns.Candidate,
            minWidth: 200,
            maxWidth: 300,
            isRowHeader: true,
            isMultiline: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                return (
                    <TableCell key={row?.personnelId}>
                        {/* || row?.preHire?.pcn */}
                        {/* {renderCorrectCandidateColumnOption(row)} */}
                        <EmployeeBasicHoverCard personnelId={row.personnelId} />
                    </TableCell>
                );
            },
            isSorted: sortColumn === publicTrustTableColumns.Candidate,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(publicTrustTableColumns.Candidate);
            },
        },
        {
            key: 'requestAgency',
            name: publicTrustTableColumns.RequestAgency,
            ariaLabel: publicTrustTableColumns.RequestAgency,
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            isCollapsible: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                const requestAgency = row.publicTrustAgency
                    ? getAgencyEnumValueFromKey(row.publicTrustAgency, true)
                    : '';
                return <TableCell>{requestAgency}</TableCell>;
            },
            isSorted: sortColumn === publicTrustTableColumns.RequestAgency,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(publicTrustTableColumns.RequestAgency);
            },
        },
        {
            key: 'requestType',
            name: publicTrustTableColumns.RequestType,
            ariaLabel: publicTrustTableColumns.RequestType,
            minWidth: 90,
            maxWidth: 90,
            isCollapsible: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisText
                            text={ScreeningRequestTypesLabels[row.requestType]}
                            textLengthBeforeEllipsis={13}
                        />
                    </TableCell>
                );
            },
            isSorted: sortColumn === publicTrustTableColumns.RequestType,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(publicTrustTableColumns.RequestType);
            },
        },
        {
            key: 'suitabilityLevel',
            name: publicTrustTableColumns.SuitabilityLevel,
            ariaLabel: publicTrustTableColumns.SuitabilityLevel,
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            isCollapsible: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                const publicTrustType = row.suitabilityLevel
                    ? SuitabilityLevels[row.suitabilityLevel as keyof typeof SuitabilityLevels]
                    : '';
                return <TableCell>{publicTrustType}</TableCell>;
            },
            isSorted: sortColumn === publicTrustTableColumns.SuitabilityLevel,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(publicTrustTableColumns.SuitabilityLevel);
            },
        },
        {
            key: 'contractId',
            name: publicTrustTableColumns.ContractId,
            ariaLabel: publicTrustTableColumns.ContractId,
            minWidth: 115,
            maxWidth: 115,
            isCollapsible: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                return <TableCell>{row.contractId}</TableCell>;
            },
            isSorted: sortColumn === publicTrustTableColumns.ContractId,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(publicTrustTableColumns.ContractId);
            },
        },
        {
            key: 'status',
            name: publicTrustTableColumns.Status,
            ariaLabel: publicTrustTableColumns.Status,
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
            onRender: (row: ICommonScreening): JSX.Element | string => {
                if (row.stateName) {
                    return (
                        <div className={detailsListStyles.dataCellContainer}>
                            {getCommonScreeningStatusLabel(row)}
                        </div>
                    );
                } else {
                    return '';
                }
            },
            isSorted: sortColumn === publicTrustTableColumns.Status,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(publicTrustTableColumns.Status);
            },
        },
        {
            key: 'subStatus',
            name: publicTrustTableColumns.Substatus,
            ariaLabel: publicTrustTableColumns.Substatus,
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onRender: (row: ICommonScreening): JSX.Element | string => {
                if (row.stateName) {
                    return (
                        <div className={detailsListStyles.dataCellContainer}>
                            {getStatusText(row, false)}
                        </div>
                    );
                } else {
                    return '';
                }
            },
            isSorted: sortColumn === publicTrustTableColumns.Substatus,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(publicTrustTableColumns.Substatus);
            },
        },
        {
            key: 'processOwner',
            name: publicTrustTableColumns.ProcessOwner,
            ariaLabel: publicTrustTableColumns.ProcessOwner,
            minWidth: 110,
            maxWidth: 150,
            isMultiline: true,
            onRender: (row: ICommonScreening): ReactNode => {
                return row.processOwnerId ? (
                    <EmployeeHoverCard personnelId={row.processOwnerId}>
                        <EmployeeNameResolverId personnelId={row.processOwnerId} />
                    </EmployeeHoverCard>
                ) : (
                    noDataText
                );
            },
            isSorted: sortColumn === publicTrustTableColumns.ProcessOwner,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(publicTrustTableColumns.ProcessOwner);
            },
        },
        {
            key: 'statusChanged',
            name: publicTrustTableColumns.StatusChanged,
            ariaLabel: publicTrustTableColumns.StatusChanged,
            minWidth: 130,
            maxWidth: 130,
            isMultiline: true,
            onRender: (row: ICommonScreening): ReactNode => {
                return row.statusChangedAtUtc ? (
                    <TableCell> {dateToFormattedDateTimeString(row.statusChangedAtUtc)} </TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === publicTrustTableColumns.StatusChanged,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(publicTrustTableColumns.StatusChanged);
            },
        },
        {
            key: 'actions',
            name: publicTrustTableColumns.Actions,
            ariaLabel: publicTrustTableColumns.Actions,
            minWidth: 250,
            maxWidth: 300,
            onRender: (row: ICommonScreening): JSX.Element => {
                return getAction(
                    pageName,
                    updateScreeningInRawCandidates,
                    isInitialEmployeeBatchLoaded,
                    args.openDetails,
                    row,
                );
            },
        },
    ] as IColumn[];
}

// TODO: Another task https://msazure.visualstudio.com/Microsoft%20Personnel/_workitems/edit/13886520/
export function getAction(
    pageName: string,
    updateScreeningInRawCandidates: (screen: ICommonScreening) => void,
    isInitialEmployeeBatchLoaded: boolean,
    openDetails: (screening: ICommonScreening) => void,
    row: ICommonScreening,
): JSX.Element {
    const showNomineeApprovalRequest =
        pageName === ScreeningPageNames.MyScreenings &&
        row.stateName === StateName.WaitingForNomineeApproval;
    const canSeeDetailsButton =
        pageName === ScreeningPageNames.Manage ||
        (pageName === ScreeningPageNames.MyScreenings &&
            row.stateName !== StateName.WaitingForNomineeApproval &&
            row.stateName !== StateName.WaitingForContractOwnerApproval);
    return (
        <Stack horizontal className={tableColumnStyles.actionRow}>
            {canSeeDetailsButton && (
                <div className={tableColumnStyles.navLink}>
                    <NavLink
                        key={`preview-link-${row.id ?? generateRandomKey()}`}
                        className={tableColumnStyles.viewScreeningLink}
                        to={`/screening/public-trust/${row.id}`}>
                        <Icon
                            iconName='PreviewLink'
                            className={tableColumnStyles.viewScreeningIcon}
                        />{' '}
                        Details
                    </NavLink>
                </div>
            )}
            {pageName === ScreeningPageNames.MyContracts &&
                row.stateName === StateName.WaitingForContractOwnerApproval && (
                    <ContractOwnerAccept
                        key={`contract-owner-accept-${row.id ?? generateRandomKey()}`}
                        screening={row as ICommonScreening}
                        screeningPath={ScreeningPaths.PublicTrust}
                        updateScreeningInRawCandidates={updateScreeningInRawCandidates}
                        isInitialEmployeeBatchLoaded={isInitialEmployeeBatchLoaded}
                    />
                )}
            {showNomineeApprovalRequest && (
                <NomineeAccept
                    key={`nominee-approve-${row.id ?? generateRandomKey()}`}
                    screening={row as ICommonScreening}
                    screeningPath={ScreeningPaths.PublicTrust}
                    updateScreeningInRawCandidates={updateScreeningInRawCandidates}
                    isInitialEmployeeBatchLoaded={isInitialEmployeeBatchLoaded}
                />
            )}
            {/* Metrics for v2 as long as the page isn't my-screening */}
            {/* {pageName !== ScreeningPageNames.MyScreenings && (
                <div className={tableColumnStyles.metrics}>
                <ActionButton
                    label={metricsTitle}
                    text={metricsTitle}
                    iconProps={{
                        iconName: IconNames.BarChartVerticalFill,
                    }}
                    onClick={(): void => openDetails(row)}
                />
                </div
            )} */}
        </Stack>
    );
}
