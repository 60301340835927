import React from 'react';
import { TextField, Stack } from '@fluentui/react';
import { stackChildrenGap } from 'components/personnel-profile/common-personnel-profile-styles';
export interface CreateNewProfileStepperPageThreeProps {
    phoneNumber?: string;
    emailAddress?: string;
    homeAddress?: string;
    homeCity?: string;
    homeState?: string;
    homeZip?: string;
    homeCountry?: string;
    onPhoneNumberChange: (newValue?: string) => void;
    onEmailAddressChange: (newValue?: string) => void;
    onHomeAddressChange: (newValue?: string) => void;
    onHomeCityChange: (newValue?: string) => void;
    onHomeStateChange: (newValue?: string) => void;
    onHomeZipChange: (newValue?: string) => void;
    onHomeCountryChange: (newValue?: string) => void;
}

export default function CreateNewProfileStepperPageThree(
    props: CreateNewProfileStepperPageThreeProps,
): JSX.Element {
    return (
        <Stack tokens={stackChildrenGap}>
            <TextField
                label='Personal Phone Number'
                value={props.phoneNumber || ''}
                onChange={(ev, newValue): void => props.onPhoneNumberChange(newValue)}
                placeholder='Enter Personal Phone Number'
            />
            <TextField
                label='Personal Email Address'
                required
                value={props.emailAddress || ''}
                onChange={(ev, newValue): void => props.onEmailAddressChange(newValue)}
                placeholder='Enter Personal Email Address'
            />
            <TextField
                label='Home Address'
                value={props.homeAddress || ''}
                onChange={(ev, newValue): void => props.onHomeAddressChange(newValue)}
                placeholder='Enter Home Address'
            />
            <TextField
                label='Home City'
                value={props.homeCity || ''}
                onChange={(ev, newValue): void => props.onHomeCityChange(newValue)}
                placeholder='Enter Home City'
            />
            <TextField
                label='Home State'
                value={props.homeState || ''}
                onChange={(ev, newValue): void => props.onHomeStateChange(newValue)}
                placeholder='Enter Home State'
            />
            <TextField
                label='Home Zip'
                value={props.homeZip || ''}
                onChange={(ev, newValue): void => props.onHomeZipChange(newValue)}
                placeholder='Enter Home Zip'
            />
            <TextField
                label='Home Country'
                value={props.homeCountry || ''}
                onChange={(ev, newValue): void => props.onHomeCountryChange(newValue)}
                placeholder='Enter Home Country'
            />
        </Stack>
    );
}
