import { ActionButton, IColumn, Stack, TooltipHost } from '@fluentui/react';
import { IconNames, maxWidthCoeff, xxxLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { detailsListStyles } from 'assets/styles/list-styles';
import { IApplicationGroupMembership, IGroup } from 'clients/group-client';
import { TableCell } from 'components/common/table';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import { ModalAddEditType } from 'components/groups/groups-utils';
import AddEditAppModalActionButton from 'components/groups/manage-group/roles/buttons/add-edit-app-modal-action-button';
import AppDetailsModalActionButton from 'components/groups/manage-group/roles/buttons/app-details-modal-action-button';
import DeleteAppModalActionButton from 'components/groups/manage-group/roles/buttons/delete-app-modal-action-button';
import React from 'react';
import { ISortableColumns } from 'utils/sort-utils';
import { toTitleCase } from 'utils/string-utils';
import { TimeFormats, timeToString } from 'utils/time-utils';

export enum ApplicationsTableColumns {
    name = 'Name',
    id = 'Id',
    role = 'Role',
    modifiedBy = 'Modified By',
    dateModified = 'Date Modified',
    actions = 'Actions',
}

interface ITableColumnsApplicationsParams extends ISortableColumns {
    group: IGroup;
    canTouchRoleOrApp: boolean;
    onAppDeleted: (app: IApplicationGroupMembership) => void;
    onAppAddOrUpdate: (role: IApplicationGroupMembership, mode: ModalAddEditType) => void;
}

export function tableColumnsApplications(params: ITableColumnsApplicationsParams): IColumn[] {
    const deleteButtonWidth = params.canTouchRoleOrApp ? 80 : 0;
    const columnWidths = {
        name: 80,
        id: 200,
        role: 40,
        modifiedBy: 100,
        dateModified: 130,
        actions: 150 + deleteButtonWidth,
    };

    return [
        {
            key: ApplicationsTableColumns.name,
            name: ApplicationsTableColumns.name,
            ariaLabel: ApplicationsTableColumns.name,
            minWidth: columnWidths.name,
            maxWidth: columnWidths.name * xxxLargeMaxWidthCoeff,
            isSorted: params.sortColumn === ApplicationsTableColumns.name,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(ApplicationsTableColumns.name);
            },
            onRender: (row: IApplicationGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${ApplicationsTableColumns.name}`}>
                    <span>{row.applicationName}</span>
                </TableCell>
            ),
        },
        {
            key: ApplicationsTableColumns.id,
            name: ApplicationsTableColumns.id,
            ariaLabel: ApplicationsTableColumns.id,
            minWidth: columnWidths.id,
            maxWidth: columnWidths.id * maxWidthCoeff,
            onRender: (row: IApplicationGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${ApplicationsTableColumns.id}`}>
                    <Stack horizontal verticalAlign='center'>
                        <TooltipHost content='Copy to clipboard'>
                            <ActionButton
                                className={detailsListStyles.icon}
                                iconProps={{ iconName: IconNames.Copy }}
                                onClick={(): void => {
                                    navigator.clipboard.writeText(row.applicationId);
                                }}
                                ariaLabel='Copy'
                            />
                        </TooltipHost>
                        <TooltipHost content={row.applicationId}>{row.applicationId}</TooltipHost>
                    </Stack>
                </TableCell>
            ),
        },
        {
            key: ApplicationsTableColumns.role,
            name: ApplicationsTableColumns.role,
            ariaLabel: ApplicationsTableColumns.role,
            minWidth: columnWidths.role,
            maxWidth: columnWidths.role * xxxLargeMaxWidthCoeff,
            isSorted: params.sortColumn === ApplicationsTableColumns.role,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(ApplicationsTableColumns.role);
            },
            onRender: (row: IApplicationGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${ApplicationsTableColumns.role}`}>
                    {toTitleCase(row.role)}
                </TableCell>
            ),
        },
        {
            key: ApplicationsTableColumns.modifiedBy,
            name: ApplicationsTableColumns.modifiedBy,
            ariaLabel: ApplicationsTableColumns.modifiedBy,
            minWidth: columnWidths.modifiedBy,
            maxWidth: columnWidths.modifiedBy * xxxLargeMaxWidthCoeff,
            onRender: (row: IApplicationGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${ApplicationsTableColumns.modifiedBy}`}>
                    <CoreEmployeeHoverCardFromPrincipalId principalId={row.modifiedBy} />
                </TableCell>
            ),
        },
        {
            key: ApplicationsTableColumns.dateModified,
            name: ApplicationsTableColumns.dateModified,
            ariaLabel: ApplicationsTableColumns.dateModified,
            minWidth: columnWidths.dateModified,
            maxWidth: undefined,
            isSorted: params.sortColumn === ApplicationsTableColumns.dateModified,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(ApplicationsTableColumns.dateModified);
            },
            onRender: (row: IApplicationGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${ApplicationsTableColumns.dateModified}`}>
                    {timeToString(row.modifiedTimestampUTC * 1000, TimeFormats.MMMDDYYYY_hmmA)}
                </TableCell>
            ),
        },
        {
            key: ApplicationsTableColumns.actions,
            name: ApplicationsTableColumns.actions,
            ariaLabel: ApplicationsTableColumns.actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions,
            onRender: (row: IApplicationGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${ApplicationsTableColumns.actions}`}>
                    <Stack horizontal>
                        <AppDetailsModalActionButton app={row} group={params.group} />
                        {params.canTouchRoleOrApp && (
                            <AddEditAppModalActionButton
                                mode={ModalAddEditType.update}
                                app={row}
                                group={params.group}
                                onAppAddOrUpdate={params.onAppAddOrUpdate}
                                canTouchRoleOrApp={params.canTouchRoleOrApp}
                            />
                        )}
                        {params.canTouchRoleOrApp && (
                            <DeleteAppModalActionButton
                                app={row}
                                group={params.group}
                                onAppDeleted={params.onAppDeleted}
                            />
                        )}
                    </Stack>
                </TableCell>
            ),
        },
    ];
}
