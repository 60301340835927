/**
 * This react hook maintains and manages filter settings,
 * and provides their values to its parent component.
 */

import { IDropdownOption, IPersonaProps } from '@fluentui/react';
import EmployeeClient, { IEmployee, PublicEmployeeEditableFields } from 'clients/employee-client';
import { IPreHire } from 'components/screening/us-gov/IScreening';
import { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import {
    FilterItemTypeEnum,
    IFilterItem,
} from 'components/common/search-filter/search-filter-library';
import { AuthContext } from 'contexts/auth-context';
import {
    transformEditableEmployeeInfoToPersona,
    transformEmployeeToPersona,
} from 'utils/internal-persona-utils';
import deepcopy from 'deepcopy';
import usePrevious from 'utils/misc-hooks';

type KeySelectedType = {
    key: string;
    selected: boolean;
};

type SetValueType =
    | string
    | number
    | boolean
    | Date
    | null
    | IDropdownOption
    | undefined
    | IPersonaProps[];

type GetValueType =
    | string
    | string[]
    | number
    | boolean
    | Date
    | IPersonaProps[]
    | IPreHire[]
    | undefined;

type URLParamValueType = string[];

enum UpdateSettingEnum {
    UpdateTextField = 'UpdateTextField',
    UpdateCheckbox = 'UpdateCheckbox',
    UpdateDropdown = 'UpdateDropdown',
    UpdateSliderBar = 'UpdateSliderBar',
    UpdateDatePicker = 'UpdateDatePicker',
    UpdateEmployeeSearch = 'UpdateEmployeeSearch',
    UpdatePrehireSearch = 'UpdatePrehireSearch',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DynamicFilterSettingsType = Record<string, any>;

export type FilterSettingsRecordType = {
    settings: DynamicFilterSettingsType;
    urlParams: URLSearchParams;
};

export const InitFilterSettingsRecord: FilterSettingsRecordType = {
    settings: {},
    urlParams: new URLSearchParams(),
};

export type FilterSettingsHookType = {
    // Is to be called when user clicks on "Clear" button.
    onClearAll: () => void;
    updateFilterSetting: (filterItem: IFilterItem, value: SetValueType) => void;
    getTextFieldSetting(name: string): string;
    getCheckboxSetting(name: string, checked: string | undefined): boolean;
    getDropdownSelectedKey: (name: string) => string | undefined;
    getDropdownSelectedKeys: (name: string) => string[] | undefined;
    getSliderBarValue: (name: string) => number | undefined;
    getDatePickerValue: (name: string) => Date | undefined;
    getPersonasSearchValue: (name: string) => IPersonaProps[] | undefined; // Can be used both for employees and prehires.
    getEmployeesSearchValue: (name: string) => IEmployee[] | undefined;
    getPrehireSearchValue: (name: string) => IPreHire[] | undefined;
};

interface IUseFilterSettings {
    cacheStorage?: Storage;
    cacheKey?: string;
    filterItems: IFilterItem[];
    isFilterDefinitionsReady: boolean;
    isFilterDefinitionsOk: boolean;
    flattenedFilterItems: IFilterItem[];
    propertyNameToFilterItemDict: Record<string, IFilterItem>;
    urlParamsKeyValuesPairs: Record<string, any[]>;
    onFilterSettingsChanged: (settingsRecord: FilterSettingsRecordType) => void;
    // onSettingsCleared will ONLY be called when filter settings are cleared
    // because user clicks on "Clear" button, not every time filter settings
    // are cleared.
    onSettingsCleared: () => void;
}

export default function useFilterSettings(params: IUseFilterSettings): FilterSettingsHookType {
    const authContext = useContext(AuthContext);
    const { cacheStorage, cacheKey } = params;

    // Variable "settings" holds the filter settings as set by user,
    // speicified by URL parameters or restrored from cache.
    const initSettings: DynamicFilterSettingsType = {};
    const [settings, updateSettings] = useReducer<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: DynamicFilterSettingsType, action: any) => DynamicFilterSettingsType,
        DynamicFilterSettingsType
    >(updateSettingsReducer, initSettings, () => initSettings);
    const [isFilterSettingsReady, setIsFilterSettingsReady] = useState<boolean>(false);
    const [isClearAllCalled, setIsClearAllCalled] = useState<boolean>(false);
    const isClearAllCalledPrev = (usePrevious(isClearAllCalled) as unknown) as boolean;

    useEffect(() => {
        if (isFilterSettingsReady) {
            params.onFilterSettingsChanged({
                urlParams: makeUrlParams(),
                settings: deepcopy(settings),
            });
        }
    }, [settings, isFilterSettingsReady]);

    function updateSettingsReducer(
        state: DynamicFilterSettingsType,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action: any,
    ): DynamicFilterSettingsType {
        if (!action?.type) {
            console.error(`Invalid action or action type "${action}"`);
            return state;
        }
        let result: DynamicFilterSettingsType;
        switch (action.type) {
            case UpdateSettingEnum.UpdateCheckbox:
                const checkboxSetting = state[action.name] ?? {};
                if (action.isChecked) {
                    checkboxSetting[action.checked] = true;
                } else {
                    delete checkboxSetting[action.checked];
                }
                result = {
                    ...state,
                    [action.name]: checkboxSetting,
                };
                if (Object.entries(checkboxSetting).length === 0) {
                    delete result[action.name];
                }
                break;
            case UpdateSettingEnum.UpdateTextField:
                result = {
                    ...state,
                };
                if (!!action.value) {
                    result[action.name] = action.value;
                } else {
                    delete result[action.name];
                }
                break;
            case UpdateSettingEnum.UpdateSliderBar:
            case UpdateSettingEnum.UpdateEmployeeSearch:
            case UpdateSettingEnum.UpdatePrehireSearch:
                result = {
                    ...state,
                    [action.name]: action.value,
                };
                break;
            case UpdateSettingEnum.UpdateDatePicker:
                if (!action.value) {
                    result = { ...state };
                    delete result[action.name];
                } else {
                    result = {
                        ...state,
                        [action.name]: action.value,
                    };
                }
                break;
            case UpdateSettingEnum.UpdateDropdown:
                if (action.multiSelect) {
                    const selections = state[action.name] ?? {};
                    if (action.option?.selected) {
                        selections[action.option?.key] = true;
                    } else if (action.option?.key !== undefined) {
                        delete selections[action.option?.key];
                    }
                    result = {
                        ...state,
                        [action.name]: selections,
                    };
                } else {
                    result = {
                        ...state,
                        [action.name]: action.option.key,
                    };
                }
                break;
            case clear.name:
                result = {};
                break;
            case restoreSettingsFromCache.name:
                result = action.value;
                break;
            default:
                console.error(`Invalid action type "${action.type}"`);
                result = state;
                break;
        }
        if (!!cacheKey && !!cacheStorage) {
            cacheStorage?.setItem(cacheKey, JSON.stringify(result));
        }
        return result;
    }

    // If URL parameters specify filter settings, set the settings accordingly.
    // Otherwise restore them from cache.
    useEffect(() => {
        if (
            !params.isFilterDefinitionsReady ||
            !params.isFilterDefinitionsOk ||
            !params.propertyNameToFilterItemDict ||
            Object.keys(params.propertyNameToFilterItemDict).length === 0
        ) {
            return;
        }
        if (Object.keys(params.urlParamsKeyValuesPairs ?? {}).length > 0) {
            // URL parameters are defining filter settings. Update settings accordingly.
            clear();
            Object.entries(params.urlParamsKeyValuesPairs).forEach(([key, value]) => {
                const filterItem = params.propertyNameToFilterItemDict[key];
                if (filterItem) {
                    updateFilterSettingFromUrl(filterItem, value);
                }
            });
        } else {
            restoreSettingsFromCache();
        }
        setIsFilterSettingsReady(true);
    }, [
        params.urlParamsKeyValuesPairs,
        params.isFilterDefinitionsReady,
        params.isFilterDefinitionsOk,
        params.propertyNameToFilterItemDict,
    ]);

    type SettingAccessType = {
        updateFunction: (filterItem: IFilterItem, value: SetValueType) => void;
        updateFromUrlFunction: (filterItem: IFilterItem, value: URLParamValueType) => void;
        getFunction: (filterItem: IFilterItem) => GetValueType;
        makeUrlParams: (filterItem: IFilterItem, value: any) => URLSearchParams;
    };

    type SettingsAccessDictionaryType = Record<keyof typeof FilterItemTypeEnum, SettingAccessType>;

    const settingsAccessDictionary = useMemo((): SettingsAccessDictionaryType => {
        const result = {} as SettingsAccessDictionaryType;
        result.Checkbox = {
            updateFunction: (filterItem: IFilterItem, value: SetValueType) =>
                updateSettings({
                    type: UpdateSettingEnum.UpdateCheckbox,
                    name: filterItem.name,
                    checked: filterItem.checkboxOptions!.checked,
                    isChecked: value,
                }),
            updateFromUrlFunction: (filterItem: IFilterItem, value: URLParamValueType) => {
                value.forEach((v) => {
                    if (filterItem.checkboxOptions!.validOptions![v]) {
                        updateSettings({
                            type: UpdateSettingEnum.UpdateCheckbox,
                            name: filterItem.name,
                            checked: v,
                            isChecked: true,
                        });
                    }
                });
            },
            getFunction: (filterItem: IFilterItem) =>
                getCheckboxSetting(filterItem.name, filterItem.checkboxOptions!.checked),
            makeUrlParams: (filterItem: IFilterItem, value: any): URLSearchParams => {
                const urlParams = new URLSearchParams();
                Object.keys(value).forEach((v) => {
                    urlParams.append(filterItem.name, v);
                });
                return urlParams;
            },
        };
        result.DatePicker = {
            updateFunction: (filterItem: IFilterItem, value: SetValueType) =>
                updateSettings({
                    type: UpdateSettingEnum.UpdateDatePicker,
                    name: filterItem.name,
                    value,
                }),
            updateFromUrlFunction: (filterItem: IFilterItem, value: URLParamValueType) => {
                const numberValue = Number.parseInt(value[0]);
                if (Number.isNaN(numberValue)) {
                    return;
                }
                updateSettings({
                    type: UpdateSettingEnum.UpdateDatePicker,
                    name: filterItem.name,
                    value: Number.parseInt(value[0]),
                });
            },
            getFunction: (filterItem: IFilterItem) => getDatePickerValue(filterItem.name),
            makeUrlParams: (filterItem: IFilterItem, value: any): URLSearchParams => {
                const urlParams = new URLSearchParams();
                urlParams.append(filterItem.name, new Date(value).getTime().toString());
                return urlParams;
            },
        };
        result.Dropdown = {
            updateFunction: (filterItem: IFilterItem, value: SetValueType) =>
                updateSettings({
                    type: UpdateSettingEnum.UpdateDropdown,
                    multiSelect: filterItem.dropdownOptions!.multiSelect,
                    name: filterItem.name,
                    option: value,
                }),
            updateFromUrlFunction: (filterItem: IFilterItem, value: URLParamValueType) => {
                // Each value is the key to an option. All such options should become selected.
                value.forEach((v) => {
                    if (filterItem.dropdownOptions!.validOptions![v]) {
                        updateSettings({
                            type: UpdateSettingEnum.UpdateDropdown,
                            multiSelect: filterItem.dropdownOptions!.multiSelect,
                            name: filterItem.name,
                            option: { key: v, selected: true } as KeySelectedType,
                        });
                    }
                });
            },
            getFunction: (filterItem: IFilterItem) => {
                if (filterItem.dropdownOptions!.multiSelect) {
                    return getDropdownSelectedKeys(filterItem.name);
                } else {
                    return getDropdownSelectedKey(filterItem.name);
                }
            },
            makeUrlParams: (filterItem: IFilterItem, value: any): URLSearchParams => {
                const urlParams = new URLSearchParams();
                if (filterItem.dropdownOptions!.multiSelect) {
                    Object.keys(value).forEach((v) => {
                        urlParams.append(filterItem.name, v);
                    });
                } else {
                    urlParams.append(filterItem.name, value);
                }
                return urlParams;
            },
        };
        result.EmployeeSearch = {
            updateFunction: (filterItem: IFilterItem, value: SetValueType) =>
                updateSettings({
                    type: UpdateSettingEnum.UpdateEmployeeSearch,
                    name: filterItem.name,
                    value,
                }),
            updateFromUrlFunction: (filterItem: IFilterItem, value: URLParamValueType) => {
                async function getEmployeesAndSetFilter(
                    filterItem: IFilterItem,
                    value: URLParamValueType,
                ): Promise<void> {
                    try {
                        const employees = await EmployeeClient.getBasicEmployeesById(
                            authContext,
                            value,
                        );
                        const personas: IPersonaProps[] = [];
                        employees.forEach((employee) => {
                            personas.push(
                                transformEmployeeToPersona((employee as unknown) as IEmployee),
                            );
                        });
                        updateFilterSetting(filterItem, personas);
                    } catch (e) {
                        console.error(
                            'Could not obtain employee(s) when converting URL parameters to filter settings',
                        );
                    }
                }
                getEmployeesAndSetFilter(filterItem, value);
            },
            getFunction: (filterItem: IFilterItem) => getEmployeesSearchValue(filterItem.name),
            makeUrlParams: (filterItem: IFilterItem, value: any): URLSearchParams => {
                const urlParams = new URLSearchParams();
                value.forEach((v: IPersonaProps) => {
                    try {
                        const itemProp = JSON.parse(v.itemProp as string);
                        urlParams.append(filterItem.name, itemProp.id);
                    } catch {}
                });
                return urlParams;
            },
        };
        result.PrehireSearch = {
            updateFunction: (filterItem: IFilterItem, value: SetValueType) =>
                updateSettings({
                    type: UpdateSettingEnum.UpdatePrehireSearch,
                    name: filterItem.name,
                    value,
                }),
            updateFromUrlFunction: (filterItem: IFilterItem, value: URLParamValueType): void => {
                async function getPrehireAndSetFilter(
                    filterItem: IFilterItem,
                    value: URLParamValueType,
                ): Promise<void> {
                    try {
                        const prehires = await EmployeeClient.getMultipleEditableEmployeeDataByIdOrAliasOrGUID(
                            authContext,
                            value,
                            Object.values(PublicEmployeeEditableFields),
                        );
                        const personas: IPersonaProps[] = [];
                        prehires.forEach((prehire) => {
                            personas.push(transformEditableEmployeeInfoToPersona(prehire));
                        });
                        updateFilterSetting(filterItem, personas);
                    } catch (e) {
                        console.error(
                            'Could not obtain prehire when converting URL parameters to filter settings',
                        );
                    }
                }
                getPrehireAndSetFilter(filterItem, value);
            },
            getFunction: (filterItem: IFilterItem) => getPrehireSearchValue(filterItem.name),
            makeUrlParams: (filterItem: IFilterItem, value: any): URLSearchParams => {
                const urlParams = new URLSearchParams();
                value.forEach((v: IPersonaProps) => {
                    try {
                        const itemProp = JSON.parse(v.itemProp as string);
                        urlParams.append(filterItem.name, itemProp.id);
                    } catch {}
                });
                return urlParams;
            },
        };
        result.SliderBar = {
            updateFunction: (filterItem: IFilterItem, value: SetValueType) =>
                updateSettings({
                    type: UpdateSettingEnum.UpdateSliderBar,
                    name: filterItem.name,
                    value,
                }),
            updateFromUrlFunction: (filterItem: IFilterItem, value: URLParamValueType) => {
                updateSettings({
                    type: UpdateSettingEnum.UpdateTextField,
                    name: filterItem.name,
                    value: Number.parseInt(value[0]),
                });
            },
            getFunction: (filterItem: IFilterItem) => getSliderBarValue(filterItem.name),
            makeUrlParams: (filterItem: IFilterItem, value: any): URLSearchParams => {
                const urlParams = new URLSearchParams();
                urlParams.append(filterItem.name, value);
                return urlParams;
            },
        };
        result.TextField = {
            updateFunction: (filterItem: IFilterItem, value: SetValueType) =>
                updateSettings({
                    type: UpdateSettingEnum.UpdateTextField,
                    name: filterItem.name,
                    value,
                }),
            updateFromUrlFunction: (filterItem: IFilterItem, value: URLParamValueType) =>
                updateSettings({
                    type: UpdateSettingEnum.UpdateTextField,
                    name: filterItem.name,
                    value: value[0],
                }),
            getFunction: (filterItem: IFilterItem) => getTextFieldSetting(filterItem.name),
            makeUrlParams: (filterItem: IFilterItem, value: any): URLSearchParams => {
                const urlParams = new URLSearchParams();
                urlParams.append(filterItem.name, value);
                return urlParams;
            },
        };
        return result;
    }, []);

    function updateFilterSetting(filterItem: IFilterItem, value: SetValueType): void {
        const accessFunctions = settingsAccessDictionary[filterItem.type];
        const { updateFunction } = accessFunctions;
        updateFunction(filterItem, value);
    }

    function updateFilterSettingFromUrl(filterItem: IFilterItem, value: URLParamValueType): void {
        const accessFunctions = settingsAccessDictionary[filterItem.type];
        const { updateFromUrlFunction } = accessFunctions;
        updateFromUrlFunction(filterItem, value);
    }

    function clear(): void {
        updateSettings({ type: clear.name });
    }

    function onClearAll(): void {
        clear();
        setIsClearAllCalled(true);
    }

    useEffect(() => {
        // This variable creates a "pulse" on isClearAllCalled. It will be used to
        // ensure filter settings are cleared before calling onSettingsCleared.
        if (isClearAllCalled) {
            setIsClearAllCalled(false);
        }
    }, [isClearAllCalled]);

    useEffect(() => {
        // The following prevents potential for race condition because it calls
        // onSettingsCleared only after the underlying object maintaining filter
        // settings has indeed been cleared, and one more variable update cycle
        // has passed (Note *). This way, if the parent component first registers
        // updated filter settings inside a variable, that variable will have the
        // latest value (which is the cleared filters) before it uses that value
        // to act on onSettingsCleared.
        //
        // This code is assuming that the parent component will need at most one
        // variable update cycle to register a change in filter settings. Here's
        // where the "one more variable update cycle" mentioned on (Note *) comes
        // into the picture.
        if (!isClearAllCalled && isClearAllCalledPrev) {
            params.onSettingsCleared();
        }
    }, [isClearAllCalled, isClearAllCalledPrev]);

    function restoreSettingsFromCache(): void {
        if (!!cacheKey && !!cacheStorage) {
            const cachedSettings = cacheStorage?.getItem(cacheKey);
            if (!!cachedSettings) {
                try {
                    const value = {
                        type: restoreSettingsFromCache.name,
                        value: JSON.parse(cachedSettings),
                    };
                    updateSettings(value);
                } catch {
                    console.error(
                        'Error parsing cached filter settings. Clearing filter settings.',
                    );
                    clear();
                }
            }
        }
    }

    // Create a URL based on current filter settings.
    // Used for deep linking using the same filter settings.
    function makeUrlParams(): URLSearchParams {
        const urlParams = new URLSearchParams();
        Object.entries(settings).forEach(([key, value]) => {
            const filterItem = params.propertyNameToFilterItemDict[key];
            if (!filterItem) {
                return;
            }
            const access = settingsAccessDictionary[filterItem.type];
            const theseUrlParams = access.makeUrlParams(filterItem, value);
            for (const [param, value] of theseUrlParams.entries()) {
                urlParams.append(param, value);
            }
        });
        return urlParams;
    }

    const getTextFieldSetting = (name: string): string => settings[name];

    const getCheckboxSetting = (name: string, checked: string | undefined): boolean => {
        if (!checked) return false;
        return (settings[name] ?? {})[checked] ?? false;
    };

    const getDropdownSelectedKey = (name: string): string => {
        return settings[name];
    };

    const getDropdownSelectedKeys = (name: string): string[] => {
        return Object.keys(settings[name] ?? {});
    };

    const getSliderBarValue = (name: string): number | undefined => {
        return settings[name];
    };

    const getDatePickerValue = (name: string): Date | undefined => {
        return settings[name];
    };

    const getPersonasSearchValue = (name: string): IPersonaProps[] | undefined => {
        return settings[name];
    };

    const getEmployeesSearchValue = (name: string): IEmployee[] | undefined => {
        const result: IEmployee[] = [];
        (settings[name] ?? []).forEach((setting: IPersonaProps) => {
            try {
                result.push(JSON.parse(setting?.itemProp as string));
            } catch {}
        });
        return result;
    };

    const getPrehireSearchValue = (name: string): IPreHire[] | undefined => {
        const result: IPreHire[] = [];
        (settings[name] ?? []).forEach((setting: IPersonaProps) => {
            try {
                result.push(JSON.parse(setting?.itemProp as string));
            } catch {}
        });
        return result;
    };

    return {
        onClearAll,
        updateFilterSetting,
        getTextFieldSetting,
        getCheckboxSetting,
        getDropdownSelectedKey,
        getDropdownSelectedKeys,
        getSliderBarValue,
        getDatePickerValue,
        getPersonasSearchValue,
        getEmployeesSearchValue,
        getPrehireSearchValue,
    };
}
