import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { AccountInfo } from '@azure/msal-common';

export default function useActiveAccount(): AccountInfo | undefined {
    const { instance } = useMsal();
    const [activeAccount, setActiveAccount] = useState(
        () => instance.getActiveAccount() ?? undefined,
    );

    useEffect(() => {
        // keep track of the account we just logged into
        instance.handleRedirectPromise().then((tokenResponse) => {
            if (tokenResponse) {
                instance.setActiveAccount(tokenResponse.account);
                setActiveAccount(tokenResponse.account);
            }
        });
    }, [instance]);

    return activeAccount;
}
