import React, { useRef, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

/**
 * This component makes its entire content clickable. Upon a click,
 * it uses NavLink to route to the URL specified on prop "to".
 */

interface INavlinkProps {
    to: string | undefined;
    children: ReactElement | ReactElement[];
}

export default function NavLinkContainer(props: INavlinkProps): JSX.Element {
    const navLinkRef = useRef<HTMLAnchorElement>(null);

    const isActive = (): boolean => {
        return props.to !== undefined && props.to !== '';
    };

    const onClick = (): void => {
        if (isActive() && navLinkRef != null && navLinkRef.current != null) {
            navLinkRef.current.click();
        }
    };

    return (
        <div onClick={onClick}>
            {isActive() && <NavLink hidden={true} ref={navLinkRef} to={props.to} />}
            {props.children}
        </div>
    );
}
