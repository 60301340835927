import React, { useState, useEffect } from 'react';
import { ActionButton } from '@fluentui/react';
import { Redirect } from 'react-router-dom';
import { generalIsMountedCode } from 'utils/misc-utils';
import { ScaProgramDetails } from 'components/sca/sca-breadcrumbs';

export default function ViewScaProgramDetailsActionButton(): JSX.Element {
    const [redirectTo, setRedirectTo] = useState<string>();

    const resetRedirect = async (isMountedFunc: () => boolean): Promise<void> => {
        if (isMountedFunc() && redirectTo) setRedirectTo('');
    };

    useEffect(() => {
        return generalIsMountedCode(resetRedirect);
    }, [redirectTo]);

    const onViewScaProgramDetails = (): void => {
        setRedirectTo(ScaProgramDetails());
    };

    if (redirectTo) {
        return <Redirect to={redirectTo} push={true} />;
    } else {
        return (
            <ActionButton
                iconProps={{ iconName: 'EntryView' }}
                text='View SCA Program Details'
                onClick={onViewScaProgramDetails}
            />
        );
    }
}
