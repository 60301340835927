import {
    ChoiceGroup,
    Dropdown,
    IChoiceGroupOption,
    IDropdownOption,
    MessageBar,
    PrimaryButton,
    TextField,
    mergeStyleSets,
} from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { globalStyles } from 'assets/styles/global-styles';
/* eslint-disable no-restricted-imports -- Referenced in policy creation/edit modal */
import EmployeeClient, {
    EmployeeMetadataType,
    IEmployeeMetadata,
    MAX_SEARCH_COUNT,
} from 'clients/employee-client';
/* eslint-enable no-restricted-imports -- Referenced in policy creation/edit modal */
import { IHRDataCheckField } from 'clients/group-client';
import Spacer from 'components/common/spacer';
import {
    costCenterOptions,
    costCenterOptionsKeys,
} from 'components/groups/manage-group/policy/buttons/create-policy-rule/create-edit-policy-rule-modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import React, { SetStateAction, useContext, useEffect, useState } from 'react';

interface ICountryOrCompanyCodeProps {
    setHRDataCheckCourses: React.Dispatch<SetStateAction<string[]>>;
    HRDataCheckCourses: string[];
    setCostCenterChoice: React.Dispatch<SetStateAction<string>>;
    costCenterChoice: string;
    setHRMetaDataType: React.Dispatch<SetStateAction<EmployeeMetadataType | undefined>>;
    HRMetaDataType: EmployeeMetadataType | undefined;
    isEditing: boolean;
}

const dataCheckRuleOptions: IDropdownOption[] = [
    { key: EmployeeMetadataType.CompanyCode, text: IHRDataCheckField.CompanyCode },
    { key: EmployeeMetadataType.CountryCode, text: IHRDataCheckField.CountryCode },
    { key: EmployeeMetadataType.CostCenterCode, text: IHRDataCheckField.CostCenterCode },
];

/** @deprecated this is not used in principal/groups rules */
export default function CountryOrCompanyCodePickerDisplay(
    props: ICountryOrCompanyCodeProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
    const [foundMetaData, setFoundMetaData] = useState<IEmployeeMetadata[] | undefined>(undefined);
    const [searchBarValue, setSearchBarValue] = useState<string>('');
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<boolean>(true);
    const [dataCheckRuleDropdownValue, setDataCheckRuleDropdownValue] = useState<
        IDropdownOption | undefined
    >();

    useEffect(() => {
        if (props.isEditing) {
            const dataCheckCode = dataCheckRuleOptions.filter((code) => {
                return code.key === props.HRMetaDataType;
            });
            setDataCheckRuleDropdownValue(dataCheckCode[0]);
        }
    }, [props.HRMetaDataType, props.isEditing]);

    const onHandleDismissItem = (item: string) => {
        const updatedItems = props.HRDataCheckCourses.filter((i) => i !== item);
        props.setHRDataCheckCourses(updatedItems);
        if (updatedItems.length === 0) {
            setShowSearchBar(true);
        }
    };

    const onDropDownChange = (item: IDropdownOption) => {
        setDataCheckRuleDropdownValue(item);
        const type: EmployeeMetadataType =
            item.text === IHRDataCheckField.CompanyCode
                ? EmployeeMetadataType.CompanyCode
                : item.text === IHRDataCheckField.CountryCode
                ? EmployeeMetadataType.CountryCode
                : EmployeeMetadataType.CostCenterCode;
        props.setHRMetaDataType(type);
        setShowSearchBar(true);
        props.setHRDataCheckCourses([]);
        setSearchBarValue('');
    };

    const onCostCenterChoiceChange = (
        ev?: React.SyntheticEvent<HTMLElement>,
        // leaving option in case its needed in future work
        option?: IChoiceGroupOption,
    ): void => {
        if (option) {
            props.setCostCenterChoice(option?.key);
            setFoundMetaData(undefined);
        }
    };

    const addCourse = (item?: string): void => {
        if (item) {
            props.setHRDataCheckCourses([...props.HRDataCheckCourses, item]);
            setFoundMetaData(undefined);
            setSearchBarValue('');
            setShowSearchBar(false);
        }
    };

    const chosenItemBar = props.HRDataCheckCourses.map((item: string, i) => (
        <div className={styles.messageBarContainer} key={i}>
            <MessageBar
                dismissButtonAriaLabel='Close'
                className={styles.messageBar}
                onDismiss={(): void => {
                    return onHandleDismissItem(item);
                }}>
                {item}
            </MessageBar>
            {props.HRDataCheckCourses.length > 1 &&
                props.HRDataCheckCourses[props.HRDataCheckCourses.length - 1] !== item && (
                    <div>Or</div>
                )}
        </div>
    ));

    const onHandleAddClass = (): void => {
        addCourse(searchBarValue);
        setFoundMetaData(undefined);
    };

    const onHandleSearch = async (str?: string): Promise<void> => {
        try {
            if (props.HRMetaDataType && str) {
                const courseDetails = await EmployeeClient.searchEmployeeMetadata(
                    props.HRMetaDataType,
                    authContext,
                    str,
                    MAX_SEARCH_COUNT,
                );
                if (courseDetails.length > 0) {
                    setFoundMetaData(courseDetails);
                } else {
                    setFoundMetaData([]);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onDescriptionChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setSearchBarValue(newValue || '');
            if (newValue && newValue !== '' && props.HRMetaDataType) {
                onHandleSearch(newValue);
            } else {
                setFoundMetaData(undefined);
            }
        },
        [props.HRMetaDataType],
    );

    return (
        <>
            <Spacer marginTop={20} />
            <Dropdown
                ariaLabel='Data check rule dropdown'
                placeholder='Please select a field type'
                options={dataCheckRuleOptions}
                selectedKey={
                    dataCheckRuleDropdownValue ? dataCheckRuleDropdownValue.key : undefined
                }
                onChange={(
                    event: React.FormEvent<HTMLDivElement>,
                    item?: IDropdownOption,
                ): void => {
                    if (item) {
                        onDropDownChange(item);
                    }
                }}
            />
            <Spacer marginTop={10} />
            {dataCheckRuleDropdownValue && (
                <>
                    {dataCheckRuleDropdownValue?.text === IHRDataCheckField.CostCenterCode ? (
                        <div>
                            Members must
                            <ChoiceGroup
                                defaultSelectedKey={costCenterOptionsKeys.True}
                                options={costCenterOptions}
                                onChange={onCostCenterChoiceChange}
                                required={true}
                                className={styles.choiceGroup}
                                aria-label='Cost center choice group'
                            />
                        </div>
                    ) : (
                        <div>
                            Member must be a compliant member in <b>any</b> of the following{' '}
                            {dataCheckRuleDropdownValue?.text}
                            {'s '}:
                        </div>
                    )}
                    <Spacer marginTop={10} />
                    {dataCheckRuleDropdownValue?.text === IHRDataCheckField.CostCenterCode && (
                        <div>
                            {props.costCenterChoice === costCenterOptionsKeys.True ? (
                                <span>
                                    Member must be in <b>any</b> of the following Cost Center Codes:
                                </span>
                            ) : (
                                <span>
                                    Member must <b>not</b> be in <b>any</b> of the following Cost
                                    Center Codes:
                                </span>
                            )}
                        </div>
                    )}
                    <Spacer marginTop={10} />
                    <div className={styles.messageBarSection}>{chosenItemBar}</div>

                    {showSearchBar && (
                        <>
                            <div className={styles.searchBarContainer}>
                                <div className='course-search-bar'>
                                    <TextField
                                        value={searchBarValue}
                                        onChange={onDescriptionChange}
                                        ariaLabel='Course search bar'
                                    />

                                    <PrimaryButton
                                        text='+ Add'
                                        disabled={isAddButtonDisabled}
                                        onClick={onHandleAddClass}
                                    />
                                </div>
                                {foundMetaData && foundMetaData.length > 0 && (
                                    <ul className='found-course-container'>
                                        {foundMetaData &&
                                            foundMetaData.map((data) => (
                                                <li
                                                    key={data.value}
                                                    onClick={(): void => {
                                                        setIsAddButtonDisabled(false);
                                                        setSearchBarValue(data.value);
                                                    }}>
                                                    {data.value}
                                                </li>
                                            ))}
                                    </ul>
                                )}
                                <Spacer marginTop={10} />
                            </div>
                            <a
                                className={globalStyles.link}
                                onClick={(): void => setShowSearchBar(false)}>
                                Cancel
                            </a>
                        </>
                    )}
                    {props.HRDataCheckCourses.length > 0 && !showSearchBar && (
                        <a
                            className={globalStyles.link}
                            onClick={(): void => setShowSearchBar(true)}>
                            Add Alternative...
                        </a>
                    )}
                </>
            )}
        </>
    );
}

const styles = mergeStyleSets({
    messageBarSection: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    searchBarContainer: {
        display: 'block',
        marginBottom: 30,
        alignItems: 'center',
        width: '100%',
        position: 'relative',
        selectors: {
            '.course-search-bar': {
                width: '35%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            '.found-course-container': {
                border: `1px solid ${NeutralColors.gray130}`,
                padding: 0,
                position: 'absolute',
                zIndex: 1,
                margin: 0,
                minWidth: 200,
                selectors: {
                    '& li': {
                        background: `${NeutralColors.white}`,
                        listStyleType: 'none',
                        lineHeight: '48px',
                        height: '48px',
                        padding: '0 16px',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        outline: 0,
                    },
                    '& li:hover': {
                        background: `${NeutralColors.gray30}`,
                    },
                },
            },
        },
    },
    choiceGroup: {
        selectors: {
            '.ms-ChoiceFieldGroup-flexContainer': {
                display: 'flex',
            },
            '.ms-ChoiceField': {
                marginRight: 15,
            },
        },
    },
    messageBarContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        selectors: {
            '& div:nth-child(1)': {
                width: 'auto',
            },
            '& div:nth-child(2)': {
                paddingLeft: 10,
                paddingRight: 10,
            },
        },
    },
    messageBar: {
        selectors: {
            '.ms-MessageBar-icon': {
                display: 'none',
            },
        },
    },
});
