import React, { useEffect, useState } from 'react';
import CommonSpinner from 'components/common/common-spinner';

export interface PageLoadingSpinnerProps {
    isLoading: boolean;
    children: React.ReactNode;
    label: string;
    ariaLive: 'assertive' | 'polite' | 'off' | undefined;
    labelPosition: 'top' | 'right' | 'bottom' | 'left' | undefined;
    // The following prop determines how long is too long
    // before reporting that the page is taking too long to
    // load. Specify 0 if you don't want the "too long" message.
    // Default: Constant DEFAULT_TIMEOUT defined further below.
    timeout?: number;
}

const DEFAULT_TIMEOUT = 5000;

export default function PageLoadingSpinner(props: PageLoadingSpinnerProps): JSX.Element {
    const { isLoading, children, label, ariaLive, labelPosition } = props;
    const [timeoutMessage, setTimeoutMessage] = useState<string | undefined>();

    useEffect(() => {
        let timer: NodeJS.Timeout;
        const timeoutAmount = props.timeout ?? DEFAULT_TIMEOUT;

        if (timeoutAmount) {
            timer = setTimeout(() => {
                setTimeoutMessage('Page is taking longer than expected to load...');
            }, timeoutAmount);
        }

        return (): void => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    });

    if (isLoading) {
        return (
            <CommonSpinner
                label={timeoutMessage ?? label}
                ariaLive={ariaLive}
                labelPosition={labelPosition}
            />
        );
    } else {
        return <>{children}</>;
    }
}
