import React, { memo } from 'react';
import { useSanitizeHtml } from 'utils/misc-hooks';
import { mergeStyles } from '@fluentui/react';

const infoBlockStyles = mergeStyles({
    whiteSpace: 'pre-line',
    padding: '0 2rem',
    flex: '1 1 20rem',
    maxWidth: '61rem',
});

export const infoFlexContainerStyles = mergeStyles({
    padding: '1rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    borderLeft: '2px solid rgba(0,0,0,.4)',
    background: 'rgba(0,0,0,.05)',
});

type InfoViewerProps = {
    description: string;
};

function InfoViewer(props: InfoViewerProps): JSX.Element {
    const { description } = props;
    const parsedDescription = useSanitizeHtml(description ?? '');
    const splitDescription = parsedDescription.split('&lt;/divider&gt;');

    return (
        <div className={infoFlexContainerStyles}>
            {splitDescription.map((desc, index) => (
                <div
                    key={index}
                    className={infoBlockStyles}
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    dangerouslySetInnerHTML={{ __html: desc }}
                />
            ))}
        </div>
    );
}

export default memo(InfoViewer);
