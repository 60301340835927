import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IDropdownOption,
    MessageBarType,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import { dropdownComboBox } from 'components/screening/common/filters/common-filter-styling';
import { IContract } from 'components/screening/us-gov/IContract';
import useMessageBar from 'components/common/use-message-bar';
import { FiltersContractsContext } from 'contexts/filters-contracts-context';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/clearance/profile-clearance-constants';

export interface ContractCustomerDropdownFilterProps {
    onFilterStateChange: (selectedAgencies: string[], adding: boolean) => void;
    contracts: IContract[];
    placeHolder?: string;
}

export function ContractCustomerDropdownFilter(
    props: ContractCustomerDropdownFilterProps,
): JSX.Element {
    const {
        theMessage: message,
        theElement: messageElement,
        setMessage: setMessage,
    } = useMessageBar({
        type: MessageBarType.error,
    });
    const filtersContext = useContext(FiltersContractsContext);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const agencyOptions = getCustomerOptionsFromContracts(props.contracts) ?? [];

    useEffect(() => {
        if (filtersContext?.filterCleared) setSelectedKeys([]);
    }, [filtersContext?.filterCleared]);

    return (
        <>
            <ComboBox
                ariaLabel='Customers'
                multiSelect
                selectedKey={selectedKeys}
                placeholder={props.placeHolder || 'Customer'}
                autoComplete='on'
                options={agencyOptions}
                onChange={onAgencySelect}
                useComboBoxAsMenuWidth
                allowFreeform
                styles={dropdownComboBox}
            />
            {!!message && messageElement()}
        </>
    );

    function onAgencySelect(event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void {
        if (option && option.key) {
            const agency = option.key;
            try {
                const isAdding = !!option.selected;
                let newlySelected: string[] = [];
                setSelectedKeys((prevSelectedKeys) => {
                    newlySelected = isAdding
                        ? [...prevSelectedKeys, option!.key as string]
                        : prevSelectedKeys.filter((k) => k !== option!.key);
                    return newlySelected;
                });
                // we need to set this to something unique in the event that the
                // same agency is toggled on/off in succession
                filtersContext.setCustomerId(`${agency} ${Date.now()}`);
                props.onFilterStateChange(newlySelected, isAdding);
            } catch (error) {
                setMessage(
                    `An error occurred while loading the selected ${
                        props.placeHolder || 'Customer'
                    } information.`,
                );
            }
        } else {
            filtersContext.setCustomerId('');
            props.onFilterStateChange([], false);
        }
    }

    function getOptionsFromValidAgencies(validCustomer: Set<string>): IDropdownOption[] {
        let options: IDropdownOption[] = [];
        validCustomer.forEach((customer) => {
            if (customer) {
                const agencyFullText = getAgencyEnumValueFromKey(customer);
                options.push({ key: customer, text: agencyFullText });
            }
        });
        options = options.sort((a, b) => {
            const aUpper = a.text.toUpperCase();
            const bUpper = b.text.toUpperCase();
            if (aUpper < bUpper) return -1;
            if (aUpper > bUpper) return 1;
            return 0;
        });
        return options;
    }

    function getCustomerOptionsFromContracts(contracts: IContract[]): IDropdownOption[] {
        const validCustomer = new Set(
            contracts.map((x) => {
                return x.customer || '';
            }),
        );

        return getOptionsFromValidAgencies(validCustomer);
    }
}
export default ContractCustomerDropdownFilter;
