import { DetailsList, FontSizes, IColumn, SelectionMode } from '@fluentui/react';
import { GetAttributeByIdResult, GetPrincipalAssignmentsResult } from 'personnel-core-clients';
import {
    CoreAttributesClient,
    CorePrincipalsClient,
} from 'clients/core/personnel-core-client-wrappers';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { Pagination } from 'components/common/pagination';
import { TableCell } from 'components/common/table';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import React, { useContext, useEffect, useState } from 'react';
import { unixTimeStampToLocalDate } from 'utils/time-utils';
import { CoreEmployeeHoverCardFromGraph } from 'components/core/common/employee-card/core-employee-hover-card';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import CoreEmployeeCardFromPrincipalRecord from 'components/core/common/employee-card/core-employee-card-from-principal-record';

const INITIAL_ITEMS_PER_PAGE = 10;

export function MyAssignmentsPage(): JSX.Element {
    const breadCrumbContext = useContext(BreadCrumbContext);
    const authContext = useContext(AuthContext);
    const principalUserContext = useContext(PrincipalUserContext);
    const [shouldFetchData, setShouldFetchData] = useState<boolean>(true);
    const [assignments, setAssignments] = useState<GetPrincipalAssignmentsResult[]>([]);
    const [attributes, setAttributes] = useState<{ [key: string]: GetAttributeByIdResult }>({});
    const [displayedAssignments, setDisplayedAssignments] = useState<
        GetPrincipalAssignmentsResult[]
    >([]);
    const [itemsPerPage, setItemsPerPage] = useState<number>(INITIAL_ITEMS_PER_PAGE);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    useEffect(() => {
        const startIdx = (currentPage - 1) * itemsPerPage;
        const endIdx = startIdx + itemsPerPage;
        const displayed = assignments.slice(startIdx, endIdx);
        setDisplayedAssignments(displayed);
        const totalPages = Math.ceil(assignments.length / itemsPerPage);
        setTotalPages(totalPages);
    }, [assignments, currentPage, itemsPerPage]);

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([
            { title: 'Home', link: '/home' },
            { title: 'Attributes', link: '' },
            { title: 'Me', link: '' },
            { title: 'Details', link: '' },
        ]);
    }, []);

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            setShouldFetchData(false);

            const principalClient = new CorePrincipalsClient(authContext);
            const attributesClient = new CoreAttributesClient(authContext);

            const assignmentsResponse = await principalClient.getAssignments(
                principalUserContext.principalRecord.id,
            );
            setAssignments(assignmentsResponse);

            const attributeIds = assignmentsResponse.map((assignment) => assignment.attributeId);

            // Create an array of promises for attribute retrieval
            const attributePromises = attributeIds.map((attributeId) =>
                attributesClient.getById(attributeId),
            );

            // Execute the promises in parallel
            const attributeResponses = (await Promise.allSettled(attributePromises)).map(
                (result) => {
                    if (result.status === 'fulfilled') {
                        return result.value;
                    } else {
                        return null;
                    }
                },
            );

            // Update the dictionary with the attribute responses
            const updatedAttributes: Record<string, GetAttributeByIdResult> = {};
            attributeResponses.forEach((response, index) => {
                const assignment = assignmentsResponse[index];
                if (response !== null) {
                    updatedAttributes[assignment.attributeId] = response;
                }
            });

            setAttributes((prevAttributes) => ({
                ...prevAttributes,
                ...updatedAttributes,
            }));
        };

        if (shouldFetchData) {
            fetchData();
        }
    }, [shouldFetchData]);

    const columns: IColumn[] = [
        {
            key: 'attribute',
            name: 'Attribute',
            ariaLabel: 'Attribute',
            minWidth: 150,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            onRender: (item: GetPrincipalAssignmentsResult, index?: number, column?: IColumn) => {
                if (attributes[item.attributeId]?.name != null) {
                    return (
                        <TableCell style={detailsListFieldStyle}>
                            <EllipsisTextCss
                                width={column?.currentWidth}
                                text={attributes[item.attributeId].name}
                            />
                        </TableCell>
                    );
                } else {
                    return <span>{item.attributeId}</span>;
                }
            },
        },
        {
            key: 'description',
            name: 'Description',
            ariaLabel: 'Description',
            minWidth: 200,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            onRender: (item: GetPrincipalAssignmentsResult, index?: number, column?: IColumn) => {
                if (attributes[item.attributeId]?.description != null) {
                    return (
                        <TableCell style={detailsListFieldStyle}>
                            <EllipsisTextCss
                                width={column?.currentWidth}
                                text={attributes[item.attributeId].description}
                            />
                        </TableCell>
                    );
                } else {
                    return <span></span>;
                }
            },
        },
        {
            key: 'value',
            name: 'Value',
            ariaLabel: 'Value',
            minWidth: 250,
            maxWidth: 300,
            isResizable: true,
            onRender: (item: GetPrincipalAssignmentsResult, index?: number, column?: IColumn) => {
                if (Array.isArray(item.value))
                    return (
                        <TableCell style={detailsListFieldStyle}>
                            <EllipsisTextCss
                                width={column?.currentWidth}
                                text={item.value.join(', ')}
                            />
                        </TableCell>
                    );
                else {
                    return (
                        <TableCell style={detailsListFieldStyle}>
                            <EllipsisTextCss width={column?.currentWidth} text={item.value} />
                        </TableCell>
                    );
                }
            },
        },
        {
            key: 'expire',
            name: 'Expires On',
            ariaLabel: 'Expires On',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            onRender: (item: GetPrincipalAssignmentsResult) => {
                if (item.expirationDate) {
                    return (
                        <div style={detailsListFieldStyle}>
                            <span>{unixTimeStampToLocalDate(item.expirationDate)}</span>
                        </div>
                    );
                } else {
                    return <div style={detailsListFieldStyle}>N/A</div>;
                }
            },
        },
        {
            key: 'lastModifiedBy',
            name: 'Last Modified By',
            ariaLabel: 'Last Modified By',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            onRender: (item: GetPrincipalAssignmentsResult) => {
                return (
                    <div style={detailsListFieldStyle}>
                        <CoreEmployeeHoverCardFromGraph
                            key={item.lastModifiedBy}
                            oid={item.lastModifiedBy}
                            showMiniCardAlias={false}
                        />
                    </div>
                );
            },
        },
        {
            key: 'lastModifiedOn',
            name: 'Last Modified On',
            ariaLabel: 'Last Modified On',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            onRender: (item: GetPrincipalAssignmentsResult) => {
                if (item.lastModifiedDate) {
                    return (
                        <div style={detailsListFieldStyle}>
                            <span>{unixTimeStampToLocalDate(item.lastModifiedDate)}</span>
                        </div>
                    );
                } else {
                    return <div style={detailsListFieldStyle}>N/A</div>;
                }
            },
        },
        {
            key: 'contact',
            name: 'Contact',
            ariaLabel: 'Contact',
            minWidth: 75,
            maxWidth: 75,
            isResizable: true,
            onRender: (item: GetPrincipalAssignmentsResult) => {
                if (attributes[item.attributeId]?.remediationSteps != null) {
                    return (
                        <div style={detailsListFieldStyle}>
                            <span>{attributes[item.attributeId].remediationSteps}</span>
                        </div>
                    );
                } else {
                    return <div style={detailsListFieldStyle}>N/A</div>;
                }
            },
        },
    ];

    return (
        <div>
            <div style={{ marginBottom: '43px' }}>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            </div>
            <div
                style={{
                    display: 'flex',
                    padding: '12px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px',
                    alignSelf: 'stretch',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    width: '396px',
                    height: 'fit-content',
                    borderRadius: '8px',
                }}>
                <CoreEmployeeCardFromPrincipalRecord
                    principalRecord={principalUserContext.principalRecord}
                    showActions={false}
                    showEmail={false}
                    showManager={false}
                    showMiniCard={true}
                    style={{
                        title: {
                            fontSize: FontSizes.small,
                            textTransform: 'capitalize',
                            marginBottom: 0,
                            marginTop: 0,
                        },
                        card: { width: 'fit-content', minHeight: 0 },
                        contact: { justifyContent: 'flex-start' },
                        details: { padding: 0 },
                    }}
                />
            </div>

            <DetailsList
                items={displayedAssignments}
                columns={columns}
                selectionMode={SelectionMode.none}
                styles={{
                    root: {
                        paddingTop: 35,
                    },
                }}
            />
            <Pagination
                onPageChange={(index): void => setCurrentPage(index)}
                pageCount={totalPages}
                selectedPageIndex={currentPage}
                totalItems={assignments.length}
                onItemsPerPageChange={(count): void => {
                    setItemsPerPage(count);
                    setCurrentPage(1);
                }}
            />
        </div>
    );
}

const detailsListFieldStyle = {
    height: 48,
    display: 'flex',
    alignItems: 'center',
};
