import React from 'react';
import { mergeStyleSets, mergeStyles } from '@fluentui/react';
import EmployeeCard from 'components/common/employee/employee-card';
import {
    DisplayBadges,
    IAccesses,
} from 'components/screening/us-gov/candidates/nomination/steps/common/display-badges';
import { UsGovRequestTypes } from 'components/screening/us-gov/IScreening';

export interface FteCandidateNominationStepReviewProps {
    employee: {
        alias: string;
        department: string;
        email: string;
        displayName: string;
        jobTitle: string;
        managerName: string;
        managerAlias: string;
        image?: string;
        oid?: string;
    };
    request: {
        type: string;
        clearanceLevel: string;
        accesses?: IAccesses;
    };
    contractID: string;
    businessJustification: string;
    badgeRequired: string;
}

export default function FteCandidateNominationStepReview(
    props: FteCandidateNominationStepReviewProps,
): JSX.Element {
    return (
        <div className={styles.root}>
            <div className={mergeStyles({ marginTop: UNIFORM_TOP_MARGIN })}>
                <div className={styles.cardWrapperPanel}>
                    <EmployeeCard
                        employee={{
                            alias: props.employee.alias,
                            displayName: props.employee.displayName,
                            email: props.employee.email,
                            department: props.employee.department,
                            jobTitle: props.employee.jobTitle,
                            oid: props.employee.oid,
                        }}
                        managerAlias={props.employee.managerAlias}
                        manager={props.employee.managerName}
                        displayActions={false}
                    />
                </div>
                <div className={styles.contactDetailsWrapperPanel}>
                    <b>Request details</b>
                    <div className={styles.row}>
                        <div className={styles.key}>Contract ID</div>
                        <div className={styles.value}>{props.contractID}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Request type</div>
                        <div className={styles.value}>
                            {UsGovRequestTypes[
                                props.request.type! as keyof typeof UsGovRequestTypes
                            ] ?? props.request.type}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Clearance level</div>
                        <div className={styles.value}>{props.request.clearanceLevel}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Badge required</div>
                        <div className={styles.value}>{props.badgeRequired}</div>
                    </div>
                    {props.request.accesses && (
                        <div className={styles.row}>
                            <div className={styles.key}>Access required</div>
                            <div className={styles.multiValue}>
                                {DisplayBadges(props.request.accesses)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.secondRow}>
                <div className={styles.businessJustificationWrapper}>
                    <b>Business justification</b>
                    <div className={styles.row}>{props.businessJustification}</div>
                </div>
            </div>
        </div>
    );
}

const UNIFORM_TOP_MARGIN = 20;

const UNIFORM_WIDTH = 380;

const UNIFORM_RIGHT_MARGIN = 40;

const UNIFORM_BOTTOM_MARGIN = 15;

const styles = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    firstRow: {
        display: 'flex',
    },
    secondRow: {
        display: 'flex',
    },
    cardWrapperPanel: {
        marginBottom: UNIFORM_BOTTOM_MARGIN,
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
    },
    cardWrapper: {
        marginRight: UNIFORM_RIGHT_MARGIN,
        marginBottom: UNIFORM_BOTTOM_MARGIN,
        width: UNIFORM_WIDTH,
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
    },
    contactDetailsWrapperPanel: {
        marginTop: 35,
        marginBottom: UNIFORM_BOTTOM_MARGIN,
    },
    contactDetailsWrapper: {
        width: UNIFORM_WIDTH,
        marginBottom: UNIFORM_BOTTOM_MARGIN,
    },
    requestDetailsWrapper: {
        width: UNIFORM_WIDTH,
    },
    businessJustificationWrapper: {
        marginTop: 5,
    },
    row: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '4px 0',
    },
    key: {
        width: 140,
        fontWeight: '500',
    },
    value: {
        width: 'calc(100% - 140px)',
    },
    multiValue: {
        display: 'flex',
        width: 'calc(100% - 140px)',
    },
});
