import React, { useContext, useMemo } from 'react';
import { Table, TableCell } from 'components/common/table';
import { IGroupSecurityGroup } from 'clients/group-client';
import { timeToString, TimeFormats } from 'utils/time-utils';
import { Stack } from '@fluentui/react';
import LinkToSecurityGroupButtonModalActionButton from 'components/groups/manage-group/settings/link-security-group/link-to-security-group-modal-action-button';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import UnlinkSecurityGroupModalActionButton from 'components/groups/manage-group/settings/unlink-security-group-modal-action-button';
import { lastColumnStylesButton } from 'assets/styles/list-styles';
import CopyToClipboardButton from 'components/common/buttons/copy-to-clipboard-button';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface ILinkedSecurityGroupsTableProps {
    canEditSettingsOrDeleteGroup: boolean;
    linkedSecurityGroups: IGroupSecurityGroup[];
}

enum ColumnNames {
    groupName = 'Linked Group',
    lastSuccessfulLink = 'Last Successful Link',
    contact = 'Contact',
    action = 'Action',
}

export default function LinkedSecurityGroupsTable(
    props: ILinkedSecurityGroupsTableProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);

    const linkOnPremAD = useFeatureFlag(FeatureFlagKeys.groupsLinkOnPremAD);

    const canLinkToFednetGroup = authContext.isInRole(Role.GroupAdmin) && linkOnPremAD.enabled;

    const columns = useMemo(() => {
        const columns = [
            {
                key: ColumnNames.groupName,
                name: ColumnNames.groupName,
                ariaLabel: ColumnNames.groupName,
                minWidth: 150,
                maxWidth: 150,
                onRender: (row: IGroupSecurityGroup): JSX.Element => (
                    <TableCell>{row.name}</TableCell>
                ),
            },
            {
                key: ColumnNames.lastSuccessfulLink,
                name: ColumnNames.lastSuccessfulLink,
                ariaLabel: ColumnNames.lastSuccessfulLink,
                minWidth: 150,
                maxWidth: 150,
                onRender: (row: IGroupSecurityGroup): JSX.Element => (
                    <TableCell>
                        {timeToString(
                            row.syncSuccessTimestampUTC * 1000,
                            TimeFormats.MMMDDYYYY_hmmA,
                        )}
                    </TableCell>
                ),
            },
            {
                key: ColumnNames.contact,
                name: ColumnNames.contact,
                ariaLabel: ColumnNames.contact,
                minWidth: 270,
                maxWidth: 270,
                onRender: (row: IGroupSecurityGroup): JSX.Element => (
                    <TableCell>
                        {!!row.mail ? (
                            <a href={`mailto:${row.mail}`}> {`${row.mail}`} </a>
                        ) : (
                            <Stack horizontal verticalAlign='center'>
                                <Stack.Item>
                                    <CopyToClipboardButton text={row.id} />
                                </Stack.Item>
                                <Stack.Item>
                                    <span>{row.id}</span>
                                </Stack.Item>
                            </Stack>
                        )}
                    </TableCell>
                ),
            },
        ];
        if (props.canEditSettingsOrDeleteGroup) {
            columns.push({
                key: ColumnNames.action,
                name: ColumnNames.action,
                ariaLabel: ColumnNames.action,
                minWidth: 80,
                maxWidth: 80,
                ...lastColumnStylesButton,
                onRender: (row: IGroupSecurityGroup): JSX.Element => (
                    <TableCell>
                        <UnlinkSecurityGroupModalActionButton securityGroup={row} />
                    </TableCell>
                ),
            });
        }
        return columns;
    }, []);

    return (
        <Stack horizontalAlign='start'>
            <Stack.Item>
                <Stack horizontal horizontalAlign='start'>
                    {props.linkedSecurityGroups.length === 0 && <span>Not linked -&nbsp;</span>}
                    <LinkToSecurityGroupButtonModalActionButton
                        enable={props.canEditSettingsOrDeleteGroup}
                        group={groupContext.group}
                        canLinkToFednetGroup={canLinkToFednetGroup}
                        linkedSecurityGroups={props.linkedSecurityGroups}
                    />
                </Stack>
            </Stack.Item>
            {props.linkedSecurityGroups.length > 0 && (
                <Stack.Item>
                    <Table
                        tableColumns={columns}
                        rows={props.linkedSecurityGroups}
                        isFetchingData={false}
                        tableName='Linked Security Groups'
                    />
                </Stack.Item>
            )}
        </Stack>
    );
}
