import { manageTitle } from 'assets/constants/global-constants';
import { IEmployee } from 'clients/employee-client';
import { ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import { HistoryPageTitle } from 'components/sca/sca-constants';

const scaBreadcrumbs: ICustomBreadcrumbItem[] = [
    {
        title: 'SCA',
        link: '/sca',
    },
];

export const scaManageBreadcrumbs = (isManageActive?: boolean): ICustomBreadcrumbItem[] => [
    ...scaBreadcrumbs,
    {
        title: manageTitle,
        link: isManageActive ? '/sca/manage' : '',
    },
];

export const scaManageViewBreadcrumbs = (id: string): ICustomBreadcrumbItem[] => [
    ...scaManageBreadcrumbs(true),
    {
        title: `${id}`,
        link: '',
    },
];

export const scaHistoryBreadcrumbs = (
    title: HistoryPageTitle,
    additionalInfo?: string | IEmployee | undefined,
): ICustomBreadcrumbItem[] => {
    switch (title) {
        case HistoryPageTitle.MyHistory:
            return [
                ...scaBreadcrumbs,
                {
                    title: title,
                    link: '',
                },
            ];
        case HistoryPageTitle.MyOrg:
            return [
                ...scaBreadcrumbs,
                {
                    title: title,
                    link: '',
                },
                {
                    // The following typecast is error prone.
                    // The calling routine must make sure to pass review period Id on additionalInfo.
                    title: additionalInfo as string,
                    link: '',
                },
            ];
        case HistoryPageTitle.MyTeam:
            if (additionalInfo) {
                return [
                    ...scaBreadcrumbs,
                    {
                        title: title,
                        link: '',
                    },
                    {
                        // The following typecast is error prone.
                        // The calling routine must make sure to pass review period Id on additionalInfo.
                        title: additionalInfo as string,
                        link: '',
                    },
                ];
            } else {
                return [
                    ...scaBreadcrumbs,
                    {
                        title: title,
                        link: '',
                    },
                ];
            }
        case HistoryPageTitle.EmployeeHistory:
            if ((additionalInfo as IEmployee)?.displayName) {
                return [
                    ...scaManageBreadcrumbs(true),
                    {
                        title: title,
                        link: '',
                    },
                    {
                        title: (additionalInfo as IEmployee).displayName,
                        link: '',
                    },
                ];
            } else {
                return [
                    ...scaBreadcrumbs,
                    {
                        title: title,
                        link: '',
                    },
                ];
            }
        default:
            // This case is unlikely to happen. Nevertheless ...
            return [
                ...scaBreadcrumbs,
                {
                    title: title,
                    link: '',
                },
            ];
    }
};

export const reviewIdUrlGeneric = (): string => `/sca/manage/:reviewId/employees`;
export const reviewIdOrgLeaderUrlGeneric = (): string => `/sca/manage/:reviewId/employees/:execId`;
export const employeeHistoryUrlGeneric = (): string => `/sca/employee-history/:personnelId`;
export const scaManageUrl = (): string => '/sca/manage';
export const myHistoryUrl = (): string => `/sca/my-history`;
export const ScaProgramDetails = (): string => '/sca/program-details';
export const scaMyHistoryUrl = (): string => '/sca/my-history';
export const scaMyOrgDefaultUrl = (): string => '/sca/my-org';
export const scaMyTeamUrl = (reviewId?: string): string =>
    reviewId ? `/sca/my-team/${reviewId}` : '/sca/my-team';

type ScaMyOrgUrlParamsType = {
    reviewId?: string;
    execId?: string;
};
export const ScaMyOrgUrl = (params: ScaMyOrgUrlParamsType) => {
    const urlParams = new URLSearchParams();
    params.reviewId && urlParams.append('reviewId', params.reviewId);
    params.execId && urlParams.append('execId', params.execId);
    const urlParamsStr = urlParams.toString();

    if (urlParamsStr) {
        return `/sca/my-org?${urlParamsStr}`;
    } else {
        return '/sca/my-org';
    }
};

export const ScaProgramDetailsBreadcrumbs = [
    ...scaBreadcrumbs,
    {
        title: 'Program Details',
        link: ScaProgramDetails(),
        active: false,
    },
];

export const reviewIdUrl = (reviewId: string, execId?: string): string => {
    if (execId)
        return reviewIdOrgLeaderUrlGeneric()
            .replace(/:reviewId/, reviewId.toLowerCase())
            .replace(/:execId/, execId);
    else return reviewIdUrlGeneric().replace(/:reviewId/, reviewId.toLowerCase());
};

export const employeeHistoryUrl = (personnelId: string): string =>
    employeeHistoryUrlGeneric().replace(/:personnelId/, personnelId);
