import React, { useState, useContext, useEffect } from 'react';
import { SharedColors } from '@fluentui/theme';
import {
    SelectionMode,
    DetailsListLayoutMode,
    IColumn,
    ShimmeredDetailsList,
    mergeStyleSets,
    Icon,
} from '@fluentui/react';
import DocumentsClient, { IDocumentRecord, IDocumentToken } from 'clients/documents-client';
import {
    IScreeningTemplateRequestRecord,
    IDocumentMetadata,
    isIScreeningTemplateRequestRecordRequestingUpdate,
    AssociationType,
    AssociationValue,
} from 'clients/template-client';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { EmployeeNameResolverId } from 'components/common/employee/employee-name-resolver';
import { ActivityEventBin } from 'components/common/event';
import { AuthContext } from 'contexts/auth-context';
import { unixTimeStampToFormattedDateString } from 'utils/time-utils';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import ViewUploadedDocumentModalFlag from 'components/screening/common/screening-document-feature-flag/modals/view-document-modal-flag';
import { IEmployeeEditableResponse, IEmployee } from 'clients/employee-client';
import { useFeatureFlag, FeatureFlagKeys } from 'utils/use-feature-flags';
import DocumentProfileAddModalFlag from 'components/screening/common/screening-document-feature-flag/documents-profile/documents-profile-add-modal-flag';

export interface UploadedDocumentsTableProps {
    screeningId: string;
    userTypes: string[];
    notifyUpdate: number;
    causeUpdate: () => void;
    isUserCandidate: boolean;
    requestedDocuments: IScreeningTemplateRequestRecord[] | undefined;
    documentMetadata: IDocumentMetadata[] | undefined;
    onUpdateRequest(request: IScreeningTemplateRequestRecord): void;
    screeningEventBins: ActivityEventBin[];
    screeningPath: ScreeningPaths;
    employee: IEmployee | undefined;
    editableEmployee?: IEmployeeEditableResponse;
    eventBitWillManuallyUpdate: () => void;
    associationValue?: AssociationValue | undefined;
    updateUploadedDocumentsPanel?: () => void;
}

function getTemplateRequestFromDocument(
    documentId: string,
    requestedDocuments: IScreeningTemplateRequestRecord[] | undefined,
): IScreeningTemplateRequestRecord | undefined {
    return requestedDocuments?.find((x) => x.documentServiceDocumentId === documentId);
}

function UploadedDocumentsTable(props: UploadedDocumentsTableProps): JSX.Element {
    const isDocumentStorageFlag = useFeatureFlag(FeatureFlagKeys.documentStorage).enabled;
    const [uploadedDocuments, setUploadedDocuments] = useState<IDocumentRecord[]>([]);
    const [errorMsg, setErrorMsg] = useState<string>();
    const authContext = useContext(AuthContext);
    const [isUpdatedRequested, setIsUpdatedRequested] = useState<boolean>(false);

    function doesDocumentRecordRequireEdits(documentRecord: IDocumentRecord): boolean {
        const templateRequest = getTemplateRequestFromDocument(
            documentRecord.id,
            props.requestedDocuments,
        );
        if (templateRequest) {
            return isIScreeningTemplateRequestRecordRequestingUpdate(templateRequest);
        }
        return false;
    }

    useEffect(() => {
        if (props.requestedDocuments) {
            if (
                props.requestedDocuments?.find((x) =>
                    isIScreeningTemplateRequestRecordRequestingUpdate(x),
                )
            ) {
                setIsUpdatedRequested(true);
            } else {
                setIsUpdatedRequested(false);
            }
        }
    }, [props.requestedDocuments, props.notifyUpdate]);

    useEffect(() => {
        let isMounted = true;
        async function getScreeningDocumentRecords(): Promise<void> {
            try {
                let documentToken: IDocumentToken | undefined = undefined;
                if (!props.isUserCandidate) {
                    documentToken = await DocumentsClient.getDocumentToken(
                        authContext,
                        props.screeningId,
                        props.screeningPath,
                    );

                    if (!documentToken || !documentToken.token) {
                        return;
                    }
                }
                let documents: IDocumentRecord[] = await DocumentsClient.getDocuments(
                    authContext,
                    props.screeningId,
                    documentToken?.token,
                );

                // Ignore 'DELETING' and 'DELETED' statuses
                documents = documents.filter((x) => x.status === 'ACTIVE');

                if (isMounted) {
                    setUploadedDocuments(() => {
                        return documents;
                    });
                }
            } catch (err) {
                if (err?.status >= 400 && err?.status < 500 && isMounted) {
                    setErrorMsg('Not allowed to view documents');
                } else {
                    // TODO: add use message bar and show error message
                    console.log(
                        'More then likely no documents with screeningId in document-service ',
                        err,
                    );
                }
            }
        }
        getScreeningDocumentRecords();

        return (): void => {
            isMounted = false;
        };
    }, [props.notifyUpdate]);

    const columns: IColumn[] = [
        {
            key: 'title',
            name: 'Title',
            minWidth: 100,
            isRowHeader: false,
            ariaLabel: 'Document title',
            data: 'string',
            onRender: (item: IDocumentRecord): JSX.Element => {
                return (
                    <div className={styles.columnPadding}>
                        <span className={styles.stringContent}>{item.fileName}</span>
                        {DocumentsClient.isDocumentLocked(item) && (
                            <>
                                {' '}
                                <Icon iconName='lock' />
                            </>
                        )}
                        {doesDocumentRecordRequireEdits(item) && (
                            <>
                                {' '}
                                <Icon iconName='Edit' />
                            </>
                        )}
                        {DocumentsClient.isDocumentDeleted(item) && (
                            <>
                                {' '}
                                <Icon iconName='PageRemove' title='Document has been removed' />
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            key: 'uploadedBy',
            name: 'Uploaded By',
            minWidth: 100,
            isRowHeader: false,
            ariaLabel: 'Document Uploaded By',
            data: 'string',
            onRender: (item: IDocumentRecord): JSX.Element => {
                return (
                    <div className={styles.columnPadding}>
                        <EmployeeHoverCard personnelId={item.authorPersonnelId}>
                            <span className={styles.stringContent}>
                                <EmployeeNameResolverId personnelId={item.authorPersonnelId} />
                            </span>
                        </EmployeeHoverCard>
                    </div>
                );
            },
        },
        {
            name: 'Uploaded On',
            minWidth: 140,
            key: 'lastAction',
            data: 'string',
            isRowHeader: false,
            ariaLabel: 'Uploaded On',
            onRender: (item: IDocumentRecord): JSX.Element => {
                return item.lastModifiedDate ? (
                    <div className={styles.columnPadding}>
                        <span className={styles.stringContent}>
                            {unixTimeStampToFormattedDateString(item.lastModifiedDate)}
                        </span>
                    </div>
                ) : (
                    <></>
                );
            },
        },
        {
            key: 'actions',
            name: 'Actions',
            minWidth: 130,
            maxWidth: 140,
            isRowHeader: false,
            isPadded: false,
            ariaLabel: 'Actions',
            onRender: (item: IDocumentRecord): JSX.Element => {
                return (
                    <ViewUploadedDocumentModalFlag
                        key={item.id}
                        documentMetadata={props.documentMetadata}
                        documentRecord={item}
                        isRequestDocument
                        isUserCandidate={props.isUserCandidate}
                        onFileUpdate={props.causeUpdate}
                        onUpdateRequest={props.onUpdateRequest}
                        screeningId={props.screeningId}
                        templateRequestRecords={props.requestedDocuments}
                        screeningEventBins={props.screeningEventBins}
                        screeningPath={props.screeningPath}
                        userTypes={props.userTypes}
                    />
                );
            },
        },
    ];
    // TODO : When removing the feature flag isDocumentStorageFlag, move this back to bottomInfo. User Story integration and cleanup work: https://dev.azure.com/msazure/Microsoft Personnel/_workitems/edit/15472048
    const getAddModal = (): JSX.Element | undefined => {
        if (isDocumentStorageFlag) {
            return (
                <DocumentProfileAddModalFlag
                    screeningId={props.screeningId}
                    employeeId={props.employee?.id}
                    editableEmployeeId={props.editableEmployee?.id}
                    associationType={AssociationType.ScreeningRecord}
                    eventBitWillManuallyUpdate={props.eventBitWillManuallyUpdate}
                    updateUploadedDocumentsPanel={props.updateUploadedDocumentsPanel}
                    associationValue={props.associationValue}
                />
            );
        }
        return undefined;
    };
    return (
        <ContainerWithEtiquettes
            leftEtiquetteLabel='Uploaded Documents'
            bottomInfo={getAddModal()}
            rightEtiquette={
                isUpdatedRequested
                    ? {
                          label: 'UPDATES REQUESTED',
                          backgroundColor: SharedColors.cyanBlue10,
                      }
                    : undefined
            }>
            {uploadedDocuments && (
                <ShimmeredDetailsList
                    columns={columns}
                    items={uploadedDocuments}
                    setKey='uploaded_documents'
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                />
            )}
            {errorMsg && <span>{errorMsg}</span>}
        </ContainerWithEtiquettes>
    );
}

const styles = mergeStyleSets({
    stringContent: {
        color: 'rgb(50, 49, 48)',
        fontSize: 14,
    },
    columnPadding: {
        paddingTop: '11px',
    },
});
export default UploadedDocumentsTable;
