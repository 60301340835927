import {
    DefaultButton,
    Dialog,
    DialogFooter,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from '@fluentui/react';
import { CoreAttributeSetsClient } from 'clients/core/personnel-core-client-wrappers';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useState } from 'react';

export interface IRemoveAttributeSetProps {
    attributeSetId: string;
    attributeSetName: string;
    onDismissed: () => void;
    onSuccess: () => void;
}

const DEFAULT_MESSAGE =
    'Caution, once deleted this attribute set cannot be recovered. Consider deactivating instead.';

export function RemoveAttributeSetModal(props: IRemoveAttributeSetProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState<boolean>(false);
    const [messageType, setMessageType] = useState<MessageBarType>(MessageBarType.warning);
    const [message, setMessage] = useState<string>(DEFAULT_MESSAGE);

    const onClose = async (): Promise<void> => {
        props.onDismissed();
        setMessageType(MessageBarType.warning);
        setMessage(DEFAULT_MESSAGE);
    };

    const deleteAttributeSet = async (): Promise<void> => {
        try {
            setIsDeleteConfirmed(true);
            setMessage('Deleting attribute set...');
            const attributeSetClient = new CoreAttributeSetsClient(authContext);
            await attributeSetClient.delete(props.attributeSetId);
            setMessageType(MessageBarType.success);
            setMessage('Attribute set has been successfully deleted.');
            props.onSuccess();
        } catch (e) {
            console.log(e);
            setMessageType(MessageBarType.error);
            setMessage('An error occurred deleting the attribute set.');
        }
    };

    return (
        <Dialog
            hidden={false}
            onDismiss={props.onDismissed}
            dialogContentProps={{
                onDismiss: onClose,
                title: 'Delete Attribute set',
                showCloseButton: true,
            }}
            modalProps={{
                styles: {
                    main: {
                        borderRadius: '8px',
                        width: '580px !important',
                        maxWidth: 'none !important',
                        minWidth: 'none !important',
                    },
                },
            }}>
            <MessageBar messageBarType={messageType}>{message}</MessageBar>
            <div
                style={{
                    paddingTop: 10,
                }}>
                {!isDeleteConfirmed && (
                    <div>
                        Are you sure you want to delete the attribute set:
                        <b> {props.attributeSetName}</b>?
                    </div>
                )}
            </div>
            <DialogFooter>
                {!isDeleteConfirmed && <PrimaryButton onClick={deleteAttributeSet} text='Delete' />}
                <DefaultButton onClick={onClose} text='Close' />
            </DialogFooter>
        </Dialog>
    );
}
