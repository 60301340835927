import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { IAuthProfile } from 'contexts/auth-context';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';

// null! needed for creating context without specifying default values required by typescript
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const PrincipalUserContext = createContext<IPrincipalUserContext>(null!);

export interface IUserProviderProps {
    msalUser: IAuthProfile;
    principalRecord: IPrincipalRecord;
    children: ReactNode;
}

export default function PrincipalUserContextProvider(props: IUserProviderProps): JSX.Element {
    const [msalUser, principalRecord] = [props.msalUser, props.principalRecord];

    const principalUserContext = useMemo(
        () => ({
            msalUser: msalUser,
            principalRecord: principalRecord,
        }),
        [msalUser, principalRecord],
    );

    return (
        <PrincipalUserContext.Provider value={principalUserContext}>
            {props.children}
        </PrincipalUserContext.Provider>
    );
}

export interface IPrincipalUserContext {
    msalUser: IAuthProfile;
    principalRecord: IPrincipalRecord;
}
