import React, { useContext, useState } from 'react';
import { MessageBar, MessageBarType, Separator, Stack } from '@fluentui/react';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import {
    filterMargin,
    filterMarginMarginTop10,
    separatorStyles,
    stackTokensChildSpace,
} from 'components/screening/common/filters/common-filter-styling';
import { ContractStatus, IContract } from 'components/screening/us-gov/IContract';
import { filterContractsKeys, FiltersContractsContext } from 'contexts/filters-contracts-context';
import ContractDropdown from 'components/screening/common/filters/contract-dropdown';
import ContractOwnerDropdownFilter from 'components/screening/common/filters/contract-owner-dropdown';
import { noDataText } from 'assets/constants/global-constants';
import ContractCustomerDropdownFilter from 'components/screening/common/filters/contract-customer-dropdown';
import ContractProjectDropdown from 'components/screening/common/filters/contract-project-dropdown';
import { MultiChoiceFilter } from 'components/common/filters';
import { IMultiChoiceFilterItem } from 'types/multi-choice-filter-Item';
import ContractTypeDropdownFilter from 'components/screening/common/filters/contract-type-dropdown';

interface ContractsFilterProps {
    contracts: IContract[] | undefined;
}

export function ContractsFilter(props: ContractsFilterProps): JSX.Element {
    const filtersContractsContext = useContext(FiltersContractsContext);
    const [contractPickerFilterMessage, setContractPickerFilterMessage] = useState<string>('');
    const [hasContractPickerFilterMessage, setHasContractPickerFilterMessage] = useState(false);

    function onSelectedContractChange(
        contractIdArr: string[],
        contractId: string,
        adding: boolean,
    ): void {
        if (contractIdArr) {
            try {
                // we need to set this to something unique in the event that the
                // same contract owner is toggled on/off in succession
                filtersContractsContext.setContractId(`${contractId} ${Date.now()}`);
                filtersContractsContext.updateFilterFunctions(
                    filterContractsKeys.contractIdKey,
                    (data: IContract): boolean => {
                        if (data?.id && contractIdArr.length > 0) {
                            return contractIdArr.includes(data.id);
                        } else if (contractIdArr.length === 0) {
                            // nothing to filter
                            return true;
                        }
                        return false;
                    },
                    adding,
                );
            } catch (error) {
                setContractPickerFilterMessage(
                    'An error occurred while loading the selected contract information.',
                );
                setHasContractPickerFilterMessage(true);
            }
        } else {
            setContractPickerFilterMessage('An error occurred while selecting a contract option.');
            setHasContractPickerFilterMessage(true);
        }
    }

    function onDismissMessage(): void {
        setHasContractPickerFilterMessage(false);
        setContractPickerFilterMessage('');
    }

    function onSelectedContractProjectChange(
        contractIdArr: string[],
        contractProject: string,
        adding: boolean,
    ): void {
        if (contractIdArr) {
            try {
                // we need to set this to something unique in the event that the
                // same contract owner is toggled on/off in succession
                filtersContractsContext.setProjectId(`${contractProject} ${Date.now()}`);
                filtersContractsContext.updateFilterFunctions(
                    filterContractsKeys.projectIdKey,
                    (data: IContract): boolean => {
                        if (data?.project && contractIdArr.length > 0) {
                            return contractIdArr.includes(data.project);
                        } else if (contractIdArr.length === 0) {
                            // nothing to filter
                            return true;
                        }
                        return false;
                    },
                    adding,
                );
            } catch (error) {
                console.error(
                    'An error occurred while loading the selected contract project information.',
                );
            }
        } else {
            console.error('An error occurred while selecting a contract project option.');
        }
    }

    function updateFilterContext(
        value: IMultiChoiceFilterItem,
        adding: boolean,
        filterCtxKey: string,
        filterCtxSetter: (arg0: string) => void,
    ): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const currentStatus: string = (filtersContractsContext as any)[filterCtxKey];
        const label: string = value.label;
        if (adding) {
            if (currentStatus) {
                const currentStatusToTable = currentStatus.split(',');
                currentStatusToTable.push(label);
                filterCtxSetter(currentStatusToTable.toString());
            } else {
                filterCtxSetter(label);
            }
        } else {
            const currentStatusToTable = currentStatus.split(',');
            if (currentStatusToTable.length > 1) {
                const newTable = currentStatusToTable.filter((val) => val !== label);
                filterCtxSetter(newTable.toString());
            } else filterCtxSetter('');
        }

        filtersContractsContext.updateFilterFunctions(filterCtxKey, value.filterFunction, adding);
    }

    const contractStatusOptions: IMultiChoiceFilterItem[] = [
        {
            label: ContractStatus.Active,
            isChecked: IsCheckboxChecked(filterContractsKeys.statusIdKey, ContractStatus.Active),
            filterFunction(data: IContract): boolean {
                if (data.contractStatus) {
                    return (
                        ContractStatus.Active.toLowerCase() === data.contractStatus.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: IContract[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction);
            },
        },
        {
            label: ContractStatus.Inactive,
            isChecked: IsCheckboxChecked(filterContractsKeys.statusIdKey, ContractStatus.Inactive),
            filterFunction(data: IContract): boolean {
                if (data.contractStatus) {
                    return (
                        ContractStatus.Inactive.toLowerCase() === data.contractStatus.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: IContract[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction);
            },
        },
        {
            label: ContractStatus.Disabled,
            isChecked: IsCheckboxChecked(filterContractsKeys.statusIdKey, ContractStatus.Disabled),
            filterFunction(data: IContract): boolean {
                if (data.contractStatus) {
                    return (
                        ContractStatus.Disabled.toLowerCase() === data.contractStatus.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: IContract[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction);
            },
        },
    ];

    function IsCheckboxChecked(group: string, label: string): boolean {
        let toTable: string[] = [];
        if (group === filterContractsKeys.statusIdKey)
            toTable = filtersContractsContext.statusId.split(',');
        return toTable.includes(label);
    }

    function labelFunction(
        label: string,
        dataArray: IContract[],
        filterFunction: (data: IContract) => boolean,
    ): string {
        let count = 0;
        if (Array.isArray(dataArray)) {
            count = dataArray.filter((data) => {
                return filterFunction(data);
            }).length;
        }
        return label + ' (' + count + ')';
    }

    return (
        <div id='filterDiv' style={{ margin: '0 0.25em' }}>
            <Separator styles={separatorStyles} alignContent='start'>
                Contracts
                {props.contracts && props.contracts.length > 0
                    ? ' (' + props.contracts.length + ')'
                    : ''}
            </Separator>
            <Stack tokens={stackTokensChildSpace} styles={filterMarginMarginTop10}>
                <>
                    <ContractDropdown
                        placeHolder='Contract ID'
                        contracts={props.contracts ?? []}
                        onContractSelected={onSelectedContractChange}
                        filterCleared={
                            props.contracts?.length !== 0
                                ? filtersContractsContext?.filterCleared
                                : Date.now().toString()
                        }
                    />
                    {hasContractPickerFilterMessage && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={true}
                            dismissButtonAriaLabel='Close'
                            overflowButtonAriaLabel='See more'
                            onDismiss={onDismissMessage}>
                            {contractPickerFilterMessage}
                        </MessageBar>
                    )}
                </>
                <ContractTypeDropdownFilter
                    placeHolder='Type'
                    contracts={props.contracts ?? []}
                    onFilterStateChange={(
                        selectedContractTypes: string[],
                        adding: boolean,
                    ): void => {
                        filtersContractsContext.updateFilterFunctions(
                            filterContractsKeys.typeIdKey,
                            (data: IContract): boolean => {
                                const contractType = data.contractType;
                                if (contractType && selectedContractTypes.length > 0) {
                                    return selectedContractTypes.includes(contractType);
                                } else if (selectedContractTypes.length === 0) {
                                    // nothing to filter
                                    return true;
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                <ContractCustomerDropdownFilter
                    placeHolder='Agency' // Agency is the new name for customer
                    contracts={props.contracts ?? []}
                    onFilterStateChange={(selectedAgencies: string[], adding: boolean): void => {
                        filtersContractsContext.updateFilterFunctions(
                            filterContractsKeys.customerIdKey,
                            (data: IContract): boolean => {
                                const customer = data.customer;
                                if (customer && selectedAgencies.length > 0) {
                                    return selectedAgencies.includes(customer);
                                } else if (selectedAgencies.length === 0) {
                                    // nothing to filter
                                    return true;
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                <ContractOwnerDropdownFilter
                    contracts={props.contracts ?? []}
                    onFilterStateChange={(
                        contractOwnerSelectedArr: string[],
                        adding: boolean,
                    ): void => {
                        filtersContractsContext.updateFilterFunctions(
                            filterContractsKeys.ownerIdKey,
                            (data: IContract): boolean => {
                                if (contractOwnerSelectedArr.includes(noDataText)) {
                                    if (data?.owners) {
                                        // if there is data for the contract owner then we need to check if the data is selected in the
                                        // contractOwnerSelectedArr
                                        return contractOwnerSelectedArr.every((x) =>
                                            data.owners?.includes(x),
                                        );
                                    } else {
                                        // if data.owners is null or undefined then just return it cause it will show as n/a
                                        return true;
                                    }
                                } else if (data?.owners && contractOwnerSelectedArr.length > 0) {
                                    return contractOwnerSelectedArr.every((x) =>
                                        data.owners?.includes(x),
                                    );
                                } else if (contractOwnerSelectedArr.length === 0) {
                                    return true;
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                <ContractProjectDropdown
                    placeHolder='Project name'
                    contracts={props.contracts ?? []}
                    onContractProjectSelected={onSelectedContractProjectChange}
                    filterCleared={
                        props.contracts?.length !== 0
                            ? filtersContractsContext?.filterCleared
                            : Date.now().toString()
                    }
                />
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Status
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Contract Status Group'>
                <MultiChoiceFilter
                    unfilteredData={props.contracts ?? []}
                    filterItems={contractStatusOptions}
                    onChildStateChange={(value: IMultiChoiceFilterItem, adding: boolean): void => {
                        updateFilterContext(
                            value,
                            adding,
                            filterContractsKeys.statusIdKey,
                            filtersContractsContext.setStatusId,
                        );
                    }}
                />
            </Stack>
            <Stack.Item styles={{ root: { display: 'flex', flexDirection: 'row-reverse' } }}>
                <ClearFiltersActionButton clearFunc={filtersContractsContext?.clearFilters} />
            </Stack.Item>
        </div>
    );
}
