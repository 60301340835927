import { mergeStyles, PrimaryButton, Spinner } from '@fluentui/react';
import React from 'react';

const spinnerStyle = mergeStyles({
    marginRight: '5px',
});

interface SaveButtonProps {
    isDisabled?: boolean;
    isSaving?: boolean;
    saveText?: string;
    defaultText?: string;
    className?: string;
    style?: React.CSSProperties;
    onClick(): void;
}

export const SaveButton = (props: SaveButtonProps) => {
    const { isDisabled, isSaving, onClick, className, style } = props;

    const saveText = props.saveText ?? 'Saving...';
    const defaultText = props.defaultText ?? 'Save';
    return (
        <PrimaryButton
            disabled={isDisabled || isSaving}
            style={style}
            className={className}
            onClick={onClick}>
            {isSaving ? (
                <>
                    <Spinner className={spinnerStyle} />
                    {saveText}
                </>
            ) : (
                <span style={{ fontWeight: 600 }}>{defaultText}</span>
            )}
        </PrimaryButton>
    );
};
