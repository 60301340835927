let config: IPortalConfiguration;

export const initializeConfig = async (): Promise<void> => {
    try {
        const configData = await fetch('/api/config');
        const serverConfig = await configData.json();
        config = serverConfig;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Configurations failed to initialize', error);
        throw error;
    }
};

export const getConfig = (): IPortalConfiguration => config;

export interface IPortalConfiguration {
    aadConfig: IAadConfiguration;
    apiConfig: IApiConfiguration;
    generalConfig: IGeneralConfiguration;
    featureFlag: IFeatureFlagConfiguration;
    emailConfig: IEmailConfiguration;
    attributeIdMappingConfig?: IAttributeIdMappingConfig;
}

export interface IAadConfiguration {
    clientId: string;
    redirectUri: string;
    logoutRedirectUri: string;
    baseUri: string;
    aadScope: string;
    authority: string;
    graphServiceBaseUrl: string;
    whitelistedTenants: string[];
    whitelistedDomains: string[];
    cloudDiscoveryMetadata: string | null;
}

export interface IEmailConfiguration {
    dev: string;
    fso: string;
    personnel: string;
    readiness: string;
    groupSync: string;
    fednetSync: string;
}

export interface IApiConfiguration {
    defaultApiAadScope: string;
    documentApiBaseUrl: string;
    documentApiAadScope: string;
    employeeApiBaseUrl: string;
    scaApiBaseUrl: string;
    formApiBaseUrl: string;
    groupApiBaseUrl: string;
    emailApiBaseUrl: string;
    eligibilityApiBaseUrl: string;
    nominationApiBaseUrl: string;
    activityServiceBaseUrl: string;
    activityServiceAadScope: string;
    staffingApiBaseUrl: string;
    screeningApiBaseUrl: string;
    screeningApiAadScope: string;
    clearanceApiBaseUrl: string;
    suitabilityApiBaseUrl: string;
    visitorApiBaseUrl: string;
    facilityApiBaseUrl: string;
    facilityApiAadScope: string;
    facilityApiMapsClientId: string;
    readinessApiBaseUrl: string;
    readinessApiAadScope: string;
    coreApiBaseUrl: string;
    coreApiAadScope: string;
    groupsServiceIdentityAppId: string;
}

export interface IGeneralConfiguration {
    preHireFlag: boolean;
    appInsightsConnectionString: string;
    processOwnerGroupId: string;
    privilegedGroupId: string;
    productionFlag: boolean;
    environmentName: string;
    renderModeDebug: string;
    isVerifiedUSCitizenFlag: boolean;
    policyCheckModalFlag: boolean;
    allowApproveUnsignedUAR: boolean;
    eligibilityApiProfileFlag: boolean;
    facilityApiExtraFlag: boolean;
    orgExplorerUrl: string;
    defaultDomain: string;
    supportEmail: string;
    supportPhone: string;
    privacyLinkText: string;
    privacyLinkUrl: string;
}

export interface IFeatureFlagConfiguration {
    documentStorage: boolean;
    coreEmailService: boolean;
    newEligibilityEnableAutoOptions: boolean;
    coreActivityService: boolean;
    enableProfileVisitor: boolean;
    enableProfileUSGov: boolean;
    enableProfileAttributesUpdatedMessageBar: boolean;
    coreGroupService: boolean;
    enableGroupMetaDataField: boolean;
    enableGroupLinkSecurityGroup: boolean;
    enableGroupPOC: boolean;
    coreScreeningService: boolean;
    enableScreeningContracts: boolean;
    enableScreeningContractOwners: boolean;
    enableScreeningSearchFilterContentRedesign: boolean;
    enableScreeningAgencyDropdown: boolean;
    coreFacilitiesService: boolean;
    coreFormsService: boolean;
    coreSCAService: boolean;
    coreStaffingService: boolean;
    coreReadinessService: boolean;
    supportLinkToAKA: boolean;
    emailLogSanitized: boolean;
    enableGroupMin2FteOwners: boolean;
    enableLinkOnPremAD: boolean;
    enableLinkWithServicePrincipal: boolean;
    enableHeaderBanner: boolean;
    enablePersonnelCoreAttributes: boolean;
    enableSuitabilityRecordsTable: boolean;
    enableTabToSuitabilityRecordsTable: boolean;
    enableFormsEditingFeature: boolean;
    enableCloudScreeningHireRightApi: boolean;
    enableDynamicGroup: boolean;
    enableEmployeeService: boolean;
    enableCloudReadinessReportDownload: boolean;
    enableFluentUITimePickerInForms: boolean;
    enableNonExternalComponents: boolean;
    enableAboutUser: boolean;
    enableCSATFeedback: boolean;
    enableFacilitiesSvgMap: boolean;
    enableEligibilities: boolean;
}

export interface IAttributeIdMappingConfig {
    fteId: string;
}
