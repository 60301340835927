import { useContext, useEffect, useState } from 'react';
import EmployeeClient, { IBasicEmployee } from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import { fetchPictures } from 'components/common/employee/employee-utils';
import { Dictionary } from 'assets/constants/global-constants';

type BasicEmployeeDataType = {
    basicEmployeesMap: Map<string, IBasicEmployee>;
    employeesPictureMap: Map<string, string>;
    errorGettingRecords: string | undefined;
    clearErrorMessage: () => void;
};

export default function useEmployeeBasicRecordsByAlias(aliases: string[]): BasicEmployeeDataType {
    const [aliasesToFetch, setAliasesToFetch] = useState<string[]>(aliases);
    const [basicEmployeesMap, setBasicEmployeesMap] = useState<Map<string, IBasicEmployee>>(
        new Map<string, IBasicEmployee>(),
    );
    const [employeesPictureMap, setEmployeesPictureMap] = useState<Map<string, string>>(
        new Map<string, string>(),
    );
    const [errorGettingRecords, setErrorGettingRecords] = useState<string | undefined>();

    const authContext = useContext(AuthContext);

    useEffect(() => {
        const uniqueAliases = Array.from(new Set(aliases));
        setAliasesToFetch(uniqueAliases);
    }, [aliases]);

    const clearErrorMessage = (): void => {
        setErrorGettingRecords(undefined);
    };

    useEffect(() => {
        async function getEmployees(): Promise<void> {
            try {
                const unfetchedEmployees = new Set<string>(
                    aliasesToFetch.filter((alias) => !basicEmployeesMap.has(alias)),
                );

                if (unfetchedEmployees.size === 0) {
                    return;
                }
                const employeesResult: IBasicEmployee[] = await EmployeeClient.getBasicEmployeesByAlias(
                    authContext,
                    Array.from(unfetchedEmployees),
                );
                const picturesDictionary = await fetchPictures(authContext, employeesResult);
                const aliasDictionary: Dictionary<string> = {};
                employeesResult.forEach((result) => {
                    aliasDictionary[result.onPremisesSamAccountName?.toLowerCase()] =
                        picturesDictionary[result.id];
                });
                setEmployeesPictureMap((currentValue) => {
                    const newValue = new Map<string, string>(currentValue);
                    Object.entries(aliasDictionary).forEach(([alias, picture]) => {
                        if (!!picture && picture !== 'data:image/jpeg;base64,') {
                            newValue.set(alias, picture);
                        }
                    });
                    return newValue;
                });

                setBasicEmployeesMap((currentValue) => {
                    const newValue = new Map<string, IBasicEmployee>(currentValue);
                    employeesResult.forEach((employee) => {
                        newValue.set(employee.onPremisesSamAccountName?.toLowerCase(), employee);
                    });
                    return newValue;
                });
            } catch (e) {
                console.error(e);
                console.error('Error getting employee by employee personnel Id');
                setErrorGettingRecords('Error getting employee data');
            }
        }

        getEmployees();
    }, [aliasesToFetch]);

    return {
        clearErrorMessage,
        basicEmployeesMap,
        employeesPictureMap,
        errorGettingRecords,
    };
}
