import { BadgeColorHex } from 'assets/constants/global-colors';

export function getIsMemberColor(isMember: boolean | undefined): BadgeColorHex {
    switch (isMember) {
        case true:
            return BadgeColorHex.GREEN;
        case false:
            return BadgeColorHex.RED;
        default:
            return BadgeColorHex.GRAY;
    }
}
