import React, { useRef, MutableRefObject } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import { ActionButton, DefaultButton } from '@fluentui/react';
import { excelFormatHeader, exportCsvString, exportJsonToCsvFile } from 'utils/reporting-utils';

interface IExportToExcelProps<T> {
    // getData must return an array of data.
    getData: () => Promise<T[] | string>;
    buttonType?: 'action' | 'default'; // default: 'action'
    fileNamePrefix: string;
    disabled?: boolean;
    fileNameTimeFormat?: string;
    formatHeader?: boolean;
    formatType?: excelFormatHeader;
    buttonTitle?: string;
    hidden?: boolean;
}

// This component is suitable for calling functions or service endpoints
// that return an array of JSON data. This component will call that
// function and passes the data thus obtained to a utility function that
// parses it with jsonToCsv to convert it to CSV format, and exports that
// into a CSV file. Flow of data is:
//      |-  This Button   -|       |- exportJsonToCsvFile  -|
//      Function or endpoint   ->     jsonToCsv -> CSV file
// If your function or endpoint returns a CSV string (not an array) and
// you want to export that to a CSV file, use DownloadCsvReportButton
// instead.
export default function ExportToExcelButton<T>(props: IExportToExcelProps<T>): JSX.Element {
    const downloadLink: MutableRefObject<HTMLAnchorElement | null> = useRef(null);

    const onClick = async (): Promise<void> => {
        if (!props.fileNamePrefix) {
            return;
        }

        const data = await props.getData();

        if (Array.isArray(data)) {
            exportArrayData(data);
        } else {
            exportStringData(data);
        }
    };

    const exportArrayData = async (data: T[]): Promise<void> => {
        if (data.length === 0) {
            return;
        }

        exportJsonToCsvFile(data, {
            fileNamePrefix: props.fileNamePrefix,
            ref: downloadLink?.current,
            options: {
                fileNameTimeFormat: props?.fileNameTimeFormat,
                formatHeader: props?.formatHeader,
                formatType: props?.formatType,
            },
        });
    };

    const exportStringData = async (data: string): Promise<void> => {
        if (!data) {
            return;
        }

        exportCsvString(data, {
            fileNamePrefix: props.fileNamePrefix,
            ref: downloadLink?.current,
            options: {
                fileNameTimeFormat: props?.fileNameTimeFormat,
                formatHeader: props?.formatHeader,
                formatType: props?.formatType,
            },
        });
    };

    return (
        <>
            <a ref={downloadLink} hidden={true} />
            {React.createElement(props.buttonType === 'default' ? DefaultButton : ActionButton, {
                text: props?.buttonTitle || 'Download report',
                iconProps: { iconName: IconNames.ExcelLogo },
                onClick,
                disabled: props.disabled,
                style: props.hidden ? { display: 'none' } : {},
            })}
        </>
    );
}
