import SearchFilterContainer, {
    IBaseSummaryDataResponse,
} from 'components/common/search-filter/search-filter-container';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import { AuthContext } from 'contexts/auth-context';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { BrowserCacheKeys } from 'utils/browser-cache-utils';
import { doNothing } from 'utils/misc-utils';
import { CheckScrollReachedBottom } from 'components/common/scroll-event-listener';
import {
    IDetailsHeaderProps,
    IRenderFunction,
    ShimmeredDetailsList,
    Sticky,
    StickyPositionType,
} from '@fluentui/react';
import EquipmentHeader from 'components/facilities/facilities-equipment/equipment-table-header';
import FacilitiesClient, {
    IEquipmentRecord,
    IFacilityRecord,
    emptyEquipmentRecord,
} from 'clients/facilities-client';
import { UserContext } from 'contexts/user-context';
import {
    FilterSettingsRecordType,
    InitFilterSettingsRecord,
} from 'components/common/search-filter/use-filter-settings';
import { tableColumns } from 'components/facilities/facilities-management/facilities-management-equipment-table-columns-v2';
import { DEFAULT_SHIMMER_LINE_COUNT } from 'components/common/table';
import useEmployeeBasicRecords from 'components/common/employee/use-employee-basic-records';
import { columnHeaderStyles } from 'assets/styles/list-styles';
import { useContracts } from 'components/screening/admin-contracts/contract-utils';
import { useClearances } from 'components/personnel-profile/clearance/clearance-utils';
import {
    RecordsPerFetch,
    RecordsPerFetchLoadAll,
} from 'components/common/search-filter/search-filter-constants';
import AddEditEquipmentModal from 'components/user-assignments/equipment/add-edit-equipment-modal';
import DeleteEquipmentModal from 'components/user-assignments/equipment/delete-equipment-modal';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import { ContractType } from 'components/screening/us-gov/IContract';
import EquipmentBulkUploadModal from 'components/facilities/facilities-management/facilities-management-bulk-upload-modal';

enum FilterItemNames {
    Facility = 'Facility',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFacilitiesFilterDefinitions extends IBaseSummaryDataResponse {
    facilities: IFacilityRecord[];
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFacilitiesManagementEquipmentPageProps {}

export default function FacilitiesManagementEquipmentPage(
    props: IFacilitiesManagementEquipmentPageProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    // TODO - Remove unused vars when the page is complete
    const [isInitialPageLoad, setIsInitialPageLoad] = useState<boolean>(true);
    const [filterSettingsRecord, setFilterSettingsRecord] = useState<FilterSettingsRecordType>(
        InitFilterSettingsRecord,
    );
    const [isLoadingRecords, setIsLoadingRecords] = useState<boolean>(false);
    const [errorGettingRecords, setErrorGettingRecords] = useState<string>('');
    const [shouldFetchEquipmentRecords, setShouldFetchEquipmentRecords] = useState<boolean>(false);
    const [shouldLoadAll, setShouldLoadAll] = useState<boolean>(false);
    const [shouldRefetchFilterDefinitions, setShouldRefetchFilterDefinitions] = useState<boolean>(
        false,
    );
    const [facilityOptions, setFacilityOptions] = useState<IFacilityRecord[]>([]);
    const [facilityMap, setFacilityMap] = useState<Map<string, IFacilityRecord>>(
        new Map<string, IFacilityRecord>(),
    );
    const [equipments, setEquipments] = useState<IEquipmentRecord[]>([]);
    const [continuationToken, setContinuationToken] = useState<string | undefined>();
    const [showAUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false);
    const [selectedRecord, setSelectedRecord] = useState<IEquipmentRecord>(emptyEquipmentRecord);
    const [itemToDelete, setItemToDelete] = useState<IEquipmentRecord | undefined>();
    const isNST = userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST);

    const {
        getBasicEmployeeRecords,
        basicEmployeesMap,
        employeesPictureMap,
    } = useEmployeeBasicRecords();

    const {
        getContracts,
        contractsMap,
        errorGettingRecords: errorGettingContractRecords, // TODO - Remove if never used
        clearErrorMessage: clearErrorGettingContractRecords, // TODO - Remove if never used
    } = useContracts();

    const {
        getClearances,
        clearanceMap,
        errorGettingRecords: errorGettingClearanceRecords, // TODO - Remove if never used
        clearErrorMessage: clearErrorGettingClearanceRecords, // TODO - Remove if never used
    } = useClearances();

    const theUrl = useMemo((): string => {
        const urlParams = filterSettingsRecord.urlParams.toString();
        let url = window.location.origin + window.location.pathname;
        if (urlParams) {
            url += `?${urlParams}`;
        }
        return url;
    }, [filterSettingsRecord]);

    useEffect(() => {
        history.replaceState({}, '', theUrl);
    }, [filterSettingsRecord]);

    const onMakeAndCopyUrlClick = () => {
        const url = theUrl;
        navigator.clipboard.writeText(url);
    };

    const onFilterSettingsChanged = useCallback(
        (settingsRecord: FilterSettingsRecordType) => {
            setFilterSettingsRecord(settingsRecord);
            if (isInitialPageLoad) {
                setShouldFetchEquipmentRecords(true);
                setIsInitialPageLoad(false);
            }
        },
        [isInitialPageLoad],
    );

    const onApplyFilters = () => {
        if (!isLoadingRecords) {
            setContinuationToken('');
            setShouldFetchEquipmentRecords(true);
        }
    };

    const onLoadAll = () => {
        if (!isLoadingRecords) {
            setShouldLoadAll(true);
            onApplyFilters();
        }
    };

    useEffect(() => {
        if (shouldRefetchFilterDefinitions) {
            setShouldRefetchFilterDefinitions(false);
        }
    }, [shouldRefetchFilterDefinitions]);

    const facilityIdsFilterSetting = useMemo(() => {
        return new Set(
            Object.entries(filterSettingsRecord.settings[FilterItemNames.Facility] ?? {})
                .filter(([key, value]) => {
                    return value;
                })
                .map(([key, value]) => key),
        );
    }, [filterSettingsRecord.settings]);

    async function getEquipmentRecords(): Promise<void> {
        try {
            setIsLoadingRecords(true);
            const result = await FacilitiesClient.searchEquipments(
                authContext,
                userContext,
                filterSettingsRecord.urlParams,
                shouldLoadAll ? RecordsPerFetchLoadAll : RecordsPerFetch, // resultCount
                continuationToken,
            );
            const {
                results: equipmentsVar,
                continuationToken: continuationTokenVar,
            } = result.searchResults;

            equipmentsVar.sort((a, b) => b.lastModified.atUtc - a.lastModified.atUtc);

            const personnelIdsVar = new Set<string>();
            equipmentsVar.forEach((e) => {
                [
                    e.ownerPersonnelId,
                    e.checkinCheckoutInfo?.lastCheckinCheckout?.by,
                    e.createdTimestamp?.by,
                    e.issued.by,
                    e.lastModified?.by,
                ].forEach((personnelId) => {
                    if (personnelId) {
                        personnelIdsVar.add(personnelId);
                    }
                });
            });
            getBasicEmployeeRecords(Array.from(personnelIdsVar));
            getContracts(
                new Set(equipmentsVar.map((e) => e.contractId ?? '')),
                ContractType.USGovScreening,
            );
            getClearances(new Set(equipmentsVar.map((e) => e.clearanceId ?? '')));

            setEquipments(equipmentsVar);
            setContinuationToken(continuationTokenVar);
            setIsLoadingRecords(false);
            if (shouldLoadAll && continuationTokenVar) {
                setShouldFetchEquipmentRecords(true);
            } else {
                setShouldLoadAll(false);
            }
        } catch (error) {
            console.error('Error getting equipment.');
            setIsLoadingRecords(false);
            setShouldLoadAll(false);
        }
    }

    const loadMoreEquipmentRecords = (): void => {
        if (isLoadingRecords || !!errorGettingRecords) {
            return;
        }
        setShouldFetchEquipmentRecords(true);
    };

    useEffect(() => {
        if (shouldFetchEquipmentRecords) {
            setShouldFetchEquipmentRecords(false);
            getEquipmentRecords();
        }
    }, [filterSettingsRecord, getEquipmentRecords, continuationToken, shouldFetchEquipmentRecords]);

    const onSummaryDataReady = useCallback((summaryData: IFacilitiesFilterDefinitions) => {
        setFacilityOptions(summaryData.facilities);
        const facilityMapVar = new Map<string, IFacilityRecord>();
        summaryData.facilities.forEach((f) => {
            facilityMapVar.set(f.facilityId, f);
        });
        setFacilityMap(facilityMapVar);
    }, []);

    const renderDetailsHeader = useCallback(
        (): IRenderFunction<IDetailsHeaderProps> => (headerProps, defaultRender) => {
            if (!headerProps) {
                return null;
            }

            return (
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                    {defaultRender!({
                        ...headerProps,
                        styles: columnHeaderStyles,
                    })}
                </Sticky>
            );
        },
        [],
    );

    return (
        <>
            <SidebarAndContents>
                <SidebarPane>
                    <SearchFilterContainer<IFacilitiesFilterDefinitions>
                        onFilterDefinitionsChecked={doNothing}
                        cacheStorage={localStorage}
                        cacheKey={BrowserCacheKeys.facilitiesManageEquipmentSearchFilterSettings}
                        filterDefinitionsFetchFunction={() =>
                            FacilitiesClient.getManageEquipmentFilterDefinitions(
                                authContext,
                                userContext,
                            )
                        }
                        onFilterSettingsChanged={onFilterSettingsChanged}
                        shouldRefetchFilterDefinitions={shouldRefetchFilterDefinitions}
                        onSummaryDataReady={onSummaryDataReady}
                        onApplyFiltersClick={onApplyFilters}
                        onLoadAllClick={onLoadAll}
                        onMakeAndCopyUrlClick={onMakeAndCopyUrlClick}
                        onSettingsCleared={onApplyFilters}
                    />
                </SidebarPane>
                <ContentPane>
                    <EquipmentHeader
                        facility={facilityOptions}
                        equipment={equipments}
                        filterSettingsRecord={filterSettingsRecord}
                        setShouldRefresh={setShouldFetchEquipmentRecords}
                        setSelectedRecord={setSelectedRecord}
                        setShowAddEditModal={setShowAddEditModal}
                        setShowUploadModal={setShowUploadModal}
                    />
                    <ShimmeredDetailsList
                        items={equipments}
                        columns={tableColumns({
                            employeesPictureMap,
                            basicEmployeesMap,
                            contractsMap,
                            clearanceMap,
                            facilityMap,
                            isNST,
                            setSelectedRecord,
                            setShowAddEditModal,
                            setItemToDelete,
                        })}
                        onRenderDetailsHeader={renderDetailsHeader()}
                        shimmerLines={DEFAULT_SHIMMER_LINE_COUNT}
                        enableShimmer={false}
                        ariaLabelForShimmer={'Loading equipment records'}
                        // TODO Remove selection related props if drag and drop was never implemented for this page.
                        // selectionMode={SelectionMode.single}
                        // selectionPreservedOnEmptyClick={true}
                        // selection={selection}
                    />
                </ContentPane>
            </SidebarAndContents>
            <CheckScrollReachedBottom
                shouldCheck={false} // Can be like: shouldCheck={!!continuationToken}
                onScrollReachedBottom={loadMoreEquipmentRecords}
            />
            {showAddEditModal && (
                <AddEditEquipmentModal
                    setShowAddEditModal={setShowAddEditModal}
                    facilities={facilityOptions ?? []}
                    setShouldRefresh={setShouldFetchEquipmentRecords}
                    existingEquipment={selectedRecord}
                />
            )}
            {itemToDelete && (
                <DeleteEquipmentModal
                    itemToDelete={itemToDelete}
                    setItemToDelete={setItemToDelete}
                    setShouldRefresh={setShouldFetchEquipmentRecords}
                />
            )}
            {showAUploadModal && (
                <EquipmentBulkUploadModal setShowUploadModal={setShowUploadModal} />
            )}
        </>
    );
}
