import React from 'react';
import { SharedColors } from '@fluentui/theme';
import { mergeStyleSets } from '@fluentui/react';
import {
    SpecialAccesses,
    specialAccessComparator,
} from 'components/personnel-profile/clearance/profile-clearance-constants';

export interface IAccesses {
    SI?: boolean;
    G?: boolean;
    TK?: boolean;
    HCS?: boolean;
    KLM?: boolean;
}

export function DisplayBadges(badges: IAccesses): JSX.Element {
    const badgesToDisplay: string[] = [];
    const pairs = [
        [badges.SI, SpecialAccesses.SI],
        [badges.TK, SpecialAccesses.TK],
        [badges.G, SpecialAccesses.G],
        [badges.HCS, SpecialAccesses.HCS],
        [badges.KLM, SpecialAccesses.KLM],
    ];

    pairs.forEach((pair) => {
        const [badge, specialAccess] = pair;
        if (badge) {
            badgesToDisplay.push(specialAccess as string);
        }
    });

    return (
        <>
            {badgesToDisplay.sort(specialAccessComparator).map((text) => {
                return (
                    <div key={text} className={styles.badgesStyles}>
                        {text}
                    </div>
                );
            })}
        </>
    );
}

const styles = mergeStyleSets({
    badgesStyles: {
        padding: '4px 10px',
        color: 'rgba(255, 255, 255, 0.867)',
        fontSize: 13,
        borderRadius: 1.5,
        backgroundColor: SharedColors.cyanBlue10,
        marginRight: 5,
    },
});
