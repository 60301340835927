import { ScreeningPageNames } from 'components/common/constants';
import { ICustomBreadcrumbItem } from 'components/common/bread-crumb';

const breadcrumbs = [
    {
        title: 'Screening',
        link: '',
    },
    {
        title: 'Cloud',
        link: '',
    },
];

export const screeningBreadcrumbs = (tab: string | undefined): ICustomBreadcrumbItem[] => {
    switch (tab) {
        case 'my-screenings':
            return [
                ...breadcrumbs,
                {
                    title: ScreeningPageNames.MyScreenings,
                    link: '',
                },
            ];
        case 'my-org':
            return [
                ...breadcrumbs,
                {
                    title: 'My Org',
                    link: '',
                },
            ];
        case 'admin':
            return [
                ...breadcrumbs,
                {
                    title: 'Admin',
                    link: '',
                },
            ];
        default:
            return [...breadcrumbs];
    }
};
