import { IconButton, mergeStyleSets } from '@fluentui/react';
import React, { ReactNode } from 'react';

export interface IFacilitiesKioskPanel {
    onDismiss: () => void;
    headerText: string;
    children?: ReactNode | undefined;
}

const classNames = mergeStyleSets({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, 0.4)',
        zIndex: 1000000,
    },
    panel: {
        position: 'fixed',
        top: 0,
        left: '176px',
        right: 0,
        bottom: 0,
        zIndex: 1000001,
        backgroundColor: 'white',
        boxShadow:
            'rgba(0, 0, 0, 0.22) 0px 25.6px 57.6px 0px, rgba(0, 0, 0, 0.18) 0px 4.8px 14.4px 0px',
    },
    panelHeaderText: {
        paddingLeft: '24px',
        paddingTop: '18px',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '27px',
    },
    closeButton: {
        position: 'absolute',
        top: '18px',
        right: '14px',
    },
    panelContents: {
        position: 'absolute',
        top: '50px',
        left: '24px',
        right: '24px',
        bottom: '24px',
    },
});

export default function FacilitiesKioskPanel(props: IFacilitiesKioskPanel): JSX.Element {
    return (
        <>
            <div className={classNames.overlay} onClick={props.onDismiss}></div>
            <div className={classNames.panel}>
                <div>
                    <div role='heading' aria-level={1} className={classNames.panelHeaderText}>
                        {props.headerText}
                    </div>
                    <IconButton
                        className={classNames.closeButton}
                        onClick={props.onDismiss}
                        ariaLabel={'Close'}
                        title={'Close'}
                        data-is-visible={true}
                        iconProps={{ iconName: 'Cancel' }}
                    />
                </div>
                <div className={classNames.panelContents}>{props.children}</div>
            </div>
        </>
    );
}
