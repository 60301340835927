import React, { useEffect, useState } from 'react';
import { IDropdownOption, ComboBox, IComboBoxOption, IComboBox } from '@fluentui/react';
import { dropdownComboBox } from 'components/screening/common/filters/common-filter-styling';
import { IContract } from 'components/screening/us-gov/IContract';
export interface ContractProjectDropdownProps {
    label?: string;
    placeHolder?: string;
    contracts: IContract[];
    onContractProjectSelected: (
        contractIdArr: string[],
        contractProject: string,
        adding: boolean,
    ) => void;
    filterCleared: string | undefined;
}

const transformContractsToProjectDropdownOptions = (contracts: IContract[]): IDropdownOption[] => {
    let validProject = new Set<string>();
    validProject = new Set(
        contracts.map((x) => {
            return x.project || '';
        }),
    );

    let options: IDropdownOption[] = [];
    validProject.forEach((project) => {
        if (project) {
            options.push({ key: project, text: project });
        }
    });
    options = options.sort((a, b) => {
        const aUpper = a.text.toUpperCase();
        const bUpper = b.text.toUpperCase();
        if (aUpper < bUpper) return -1;
        if (aUpper > bUpper) return 1;
        return 0;
    });

    return options;
};

function ContractProjectDropdown(props: ContractProjectDropdownProps): JSX.Element {
    const contractOptions = transformContractsToProjectDropdownOptions(props.contracts);
    const placeholder = props.placeHolder;
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    useEffect(() => {
        if (props.filterCleared) setSelectedKeys([]);
    }, [props.filterCleared]);

    return (
        <>
            <ComboBox
                ariaLabel='Contracts'
                label={props.label}
                multiSelect
                selectedKey={selectedKeys}
                placeholder={placeholder}
                autoComplete='on'
                options={contractOptions}
                onChange={onContractSelect}
                useComboBoxAsMenuWidth
                allowFreeform
                styles={dropdownComboBox}
            />
        </>
    );

    function onContractSelect(event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void {
        if (option) {
            const contractProject = option.key as string;
            const isAdding = !!option.selected;
            let newlySelected: string[] = [];
            setSelectedKeys((prevSelectedKeys) => {
                newlySelected = isAdding
                    ? [...prevSelectedKeys, option!.key as string]
                    : prevSelectedKeys.filter((k) => k !== option!.key);
                return newlySelected;
            });
            props.onContractProjectSelected(newlySelected, contractProject, isAdding);
        }
    }
}

export default ContractProjectDropdown;
