import { ActionButton, Checkbox, IPersonaProps, Separator, Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Dictionary, IconNames } from 'assets/constants/global-constants';
import { globalSeparatorStyles } from 'assets/styles/global-styles';
import { IScopeRecord } from 'clients/cloud-screening-client';
import { IEmployee } from 'clients/employee-client';
import { transformEmployeeToPersona } from 'utils/internal-persona-utils';
import { toTitleCase } from 'utils/string-utils';
import EmployeeListPicker from 'components/common/employee-list-picker';
import {
    cloudScreeningStatuses,
    getCloudScreeningStatus,
    RequiredForScopes,
} from 'components/screening/cloud-screening/cloud-screening-utils';

export interface CloudScreeningOrgSearchProps {
    reports: IEmployee[];
    setFilteredReports: React.Dispatch<React.SetStateAction<IEmployee[]>>;
    scopeRecordsDictionary: Dictionary<IScopeRecord[]>;
}

export const StatusFilterMapping: { [key: string]: string[] } = {
    [cloudScreeningStatuses.screened.longText]: [cloudScreeningStatuses.screened.longText],
    [cloudScreeningStatuses.expiringWithinOneHundredTwentyDays.longText]: [
        cloudScreeningStatuses.expiringWithinOneHundredTwentyDays.longText,
        cloudScreeningStatuses.expiringWithinNinetyDays.longText,
        cloudScreeningStatuses.expiringWithinSixtyDays.longText,
        cloudScreeningStatuses.expiringWithinThirtyDays.longText,
    ],
    [cloudScreeningStatuses.expiringWithinNinetyDays.longText]: [
        cloudScreeningStatuses.expiringWithinNinetyDays.longText,
        cloudScreeningStatuses.expiringWithinSixtyDays.longText,
        cloudScreeningStatuses.expiringWithinThirtyDays.longText,
    ],
    [cloudScreeningStatuses.expiringWithinSixtyDays.longText]: [
        cloudScreeningStatuses.expiringWithinSixtyDays.longText,
        cloudScreeningStatuses.expiringWithinThirtyDays.longText,
    ],
    [cloudScreeningStatuses.expiringWithinThirtyDays.longText]: [
        cloudScreeningStatuses.expiringWithinThirtyDays.longText,
    ],
    [cloudScreeningStatuses.expired.longText]: [cloudScreeningStatuses.expired.longText],
    [cloudScreeningStatuses.notScreened.longText]: [cloudScreeningStatuses.notScreened.longText],
};

const standardScopes = Object.values(RequiredForScopes);
const standardScopesSet = new Set<string>(standardScopes);
const statuses = Object.keys(StatusFilterMapping);

function getAllowedStatusSet(selectedStatusList: string[]): Set<string> {
    let allStatus: string[] = [];
    for (const status of selectedStatusList) {
        allStatus = allStatus.concat(StatusFilterMapping[status] ?? []);
    }
    return new Set<string>(allStatus);
}

function getNamedContractualScopes(scopesDict: Dictionary<IScopeRecord[]>): string[] {
    const namedScopes: Set<string> = new Set<string>();
    Object.keys(scopesDict).forEach((personnelId) => {
        scopesDict[personnelId].forEach((personnelScope) => {
            if (!standardScopesSet.has(personnelScope.scope)) {
                namedScopes.add(personnelScope.scope);
            }
        });
    });
    return Array.from(namedScopes);
}

export default function CloudScreeningOrgFilter(props: CloudScreeningOrgSearchProps): JSX.Element {
    const { reports, setFilteredReports } = props;
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
    const [selectedScopes, setSelectedScopes] = useState<string[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<IEmployee>();
    const [filterPersonas, setFilterPersonas] = useState<IPersonaProps[]>();
    const [triggerEmployeeSearchClear, setTriggerEmployeeSearchClear] = useState<number>(0);
    const [namedContractualScopes, setNamedContractualScopes] = useState<string[]>([]);

    function onStatusFilterCheckedChange(
        status: string,
        ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
        checked?: boolean | undefined,
    ): void {
        if (checked) {
            setSelectedStatuses([status, ...selectedStatuses]);
        } else {
            const newStatuses = selectedStatuses.filter(
                (selectedStatus) => selectedStatus !== status,
            );
            setSelectedStatuses(newStatuses);
        }
    }

    function onScopeFilterCheckedChange(
        scope: string,
        ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
        checked?: boolean | undefined,
    ): void {
        if (checked) {
            setSelectedScopes([scope, ...selectedScopes]);
        } else {
            const newScopes = selectedScopes.filter((selectedScope) => selectedScope !== scope);
            setSelectedScopes(newScopes);
        }
    }

    function onClickClearFilters(): void {
        if (selectedEmployee) {
            setSelectedEmployee(undefined);
        }
        if (selectedScopes.length) {
            setSelectedScopes([]);
        }
        if (selectedStatuses.length) {
            setSelectedStatuses([]);
        }
        setTriggerEmployeeSearchClear(triggerEmployeeSearchClear + 1);
    }

    useEffect((): void => {
        const result = getNamedContractualScopes(props.scopeRecordsDictionary);
        setNamedContractualScopes(result);
    }, [props.scopeRecordsDictionary]);

    useEffect((): void => {
        const personas = [...reports].map((report: IEmployee) =>
            transformEmployeeToPersona(report),
        );
        setFilterPersonas(personas);
    }, [reports]);

    useEffect((): void => {
        const filteredReports = reports.filter((report: IEmployee) => {
            if (selectedStatuses.length) {
                const allowedStatusSet = getAllowedStatusSet(selectedStatuses);
                const status = getCloudScreeningStatus(report);
                if (!allowedStatusSet.has(status.longText)) {
                    return false;
                }
            }

            if (selectedScopes.length) {
                const reportScopes = new Set(
                    props.scopeRecordsDictionary[report.id]?.map((x) => x.scope),
                );
                for (const scope of selectedScopes) {
                    if (scope === RequiredForScopes.NotRequired) {
                        if (reportScopes.size !== 0) {
                            return false;
                        }
                    } else if (scope === RequiredForScopes.Contractual) {
                        const intersect = namedContractualScopes.filter((namedScope) =>
                            reportScopes.has(namedScope),
                        );
                        if (!intersect.length && !reportScopes.has(scope)) {
                            return false;
                        }
                    } else if (!reportScopes.has(scope)) {
                        return false;
                    }
                }
            }

            if (selectedEmployee) {
                if (report.alias.toLowerCase() !== selectedEmployee.alias.toLowerCase()) {
                    return false;
                }
            }

            return true;
        });

        setFilteredReports(filteredReports);
    }, [selectedStatuses, selectedScopes, selectedEmployee, reports, props.scopeRecordsDictionary]);

    return (
        <Stack>
            <Stack.Item>
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Employee
                </Separator>
            </Stack.Item>
            <Stack.Item>
                <EmployeeListPicker
                    ariaLabel='Employee'
                    key={triggerEmployeeSearchClear}
                    placeHolder='Employee name or alias'
                    personaChoiceList={filterPersonas}
                    onCandidateSelected={(persona): void => {
                        if (persona?.itemProp) {
                            const employee = JSON.parse(persona.itemProp) as IEmployee;
                            setSelectedEmployee(employee);
                        } else {
                            setSelectedEmployee(undefined);
                        }
                    }}
                />
            </Stack.Item>
            <Stack.Item>
                <div style={{ marginTop: '10px' }}>
                    <Stack role='group' aria-label='Screening Status Group'>
                        <Stack.Item>
                            <Separator styles={globalSeparatorStyles} alignContent='start'>
                                Screening Status
                            </Separator>
                        </Stack.Item>
                        {statuses.map(
                            (status): JSX.Element => (
                                <Stack.Item key={`statusFilterBox_${status}`}>
                                    <div style={{ padding: '2px 0 2px 0' }}>
                                        <Checkbox
                                            label={toTitleCase(status)}
                                            key={toTitleCase(status)}
                                            onChange={(ev, checked): void =>
                                                onStatusFilterCheckedChange(status, ev, checked)
                                            }
                                            checked={selectedStatuses.includes(status)}
                                        />
                                    </div>
                                </Stack.Item>
                            ),
                        )}
                    </Stack>
                </div>
            </Stack.Item>
            <Stack.Item>
                <div style={{ marginTop: '10px' }}>
                    <Stack role='group' aria-label='Required For Group'>
                        <Stack.Item>
                            <Separator styles={globalSeparatorStyles} alignContent='start'>
                                Required For
                            </Separator>
                        </Stack.Item>
                        {standardScopes.map(
                            (scope): JSX.Element => (
                                <Stack.Item key={`scopesFilterBox_${scope}`}>
                                    <div style={{ padding: '2px 0 2px 0' }}>
                                        <Checkbox
                                            label={scope}
                                            key={scope}
                                            onChange={(ev, checked): void =>
                                                onScopeFilterCheckedChange(scope, ev, checked)
                                            }
                                            checked={selectedScopes.includes(scope)}
                                        />
                                    </div>
                                </Stack.Item>
                            ),
                        )}
                    </Stack>
                </div>
            </Stack.Item>

            <Stack.Item>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <ActionButton
                        onClick={onClickClearFilters}
                        iconProps={{ iconName: IconNames.ClearFilter }}
                        text='Clear filters'
                    />
                </div>
            </Stack.Item>
        </Stack>
    );
}
