import { manageTitle } from 'assets/constants/global-constants';
import { ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import { IEligibility } from 'clients/eligibility-client';

const eligEligibilitiesUrl = '/profile/manage/eligibilities';

export const viewEligibilityUrl = (eligibility?: IEligibility, tab?: string): string => {
    if (eligibility) {
        if (tab) return `${eligEligibilitiesUrl}/${eligibility.id}/${tab}`;
        else return `${eligEligibilitiesUrl}/${eligibility.id}`;
    } else {
        return `${eligEligibilitiesUrl}`;
    }
};

export const eligAccessRequestAbsoluteURL = (id: string): string =>
    `${window.location.origin}/access/eligibility/${id}/request`;

export const assignmentsAttributesUrl = (alias: string): string =>
    `/profile/user/${alias}/attributes`;
export const assignmentsPersonnelInfoUrl = (alias: string): string =>
    `/profile/user/${alias}/personnel-info`;
export const assignmentsEligibilitiesUrl = (alias: string): string =>
    `/profile/user/${alias}/eligibilities`;

export const eligibilityRequestUrl = (id: string) => `${eligEligibilitiesUrl}/${id}/requests`;

const eligRootBreadcrumbs: ICustomBreadcrumbItem[] = [
    {
        title: 'Profile',
        link: '/profile',
    },
];

export const manageAttrBreadcrumbs: ICustomBreadcrumbItem[] = [
    ...eligRootBreadcrumbs,
    {
        title: manageTitle,
        link: '',
    },
    {
        title: 'Attributes',
        link: '',
    },
];

export const manageEligBreadcrumbs: ICustomBreadcrumbItem[] = [
    ...eligRootBreadcrumbs,
    {
        title: manageTitle,
        link: '',
    },
    {
        title: 'Eligibilities',
        link: '',
    },
];

export const eligViewEligibilityBreadcrumbs = (
    eligibility?: IEligibility,
): ICustomBreadcrumbItem[] => [
    ...eligRootBreadcrumbs,
    {
        title: manageTitle,
        link: '',
    },
    {
        title: 'Eligibilities',
        link: '/profile/manage/eligibilities',
    },
    {
        title: eligibility?.eligibilityCode ?? '',
        link: '',
    },
];

//http://localhost:3000/profile/manage/eligibilities/8c6fa8df-07c1-486a-b207-4909a0f7c874/details
