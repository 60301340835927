import React, { useContext } from 'react';
import logo from 'assets/img/logo.svg';
import { NeutralColors } from '@fluentui/theme';
import { mergeStyleSets, FontWeights, FontSizes, ActionButton, Spinner } from '@fluentui/react';
import { AuthContext, RenderMode, isKioskAppPresent } from 'contexts/auth-context';
import { CacheContext } from 'contexts/cache-context';
import Clock from 'components/common/clock';
import EllipsisText from 'components/common/ellipsis-text';
import {
    useDetermineImage,
    fetchPictureBase,
    getDisplayNameOrDefault,
} from 'components/common/employee/employee-utils';
import { Link } from 'react-router-dom';
import { PersonnelNavigationMenu } from 'components/common/page-shell/personnel-navigation-menu';
// eslint-disable-next-line no-restricted-imports -- Not used in core.
import FacilitiesKioskRestartOptions from 'components/facilities/facilities-kiosk/facilities-kiosk-restart-options';
import { PrincipalUserContext } from 'contexts/principal-user-context';
// This common file has been updated to handle UserContext not always being present
// eslint-disable-next-line no-restricted-imports
import { IUserContext, UserContext } from 'contexts/user-context';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import FeedbackNotificationsProfileIcons from 'components/common/page-shell/feedback-notifications-profile/feedback-notifications-profile';

export const MIN_HEIGHT_HEADER_PX = 64;

const MAX_CHARACTERS_TO_DISPLAY = 40;
const styles = mergeStyleSets({
    header: {
        position: 'fixed',
        zIndex: 1000,
        width: '100%',
        backgroundColor: '#242424',
        color: NeutralColors.white,
        minHeight: MIN_HEIGHT_HEADER_PX,
        selectors: {
            '@media (forced-colors: active) and (prefers-color-scheme: light)': {
                backgroundColor: 'CanvasText',
            },
        },
    },
    headerWrapper: {
        margin: '0px 10px',
        minHeight: MIN_HEIGHT_HEADER_PX,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 20px 0px 20px',
        selectors: {
            '@media(max-width: 600px)': {
                justifyContent: 'center',
            },
        },
    },
    logoWrapper: {
        selectors: {
            '@media(max-width: 600px)': {
                textAlign: 'center',
                width: '100%',
            },
        },
    },
    hideOnResponsive: {
        selectors: {
            '@media(max-width: 600px)': {
                display: 'none',
            },
        },
    },
    nestedFlex: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: MIN_HEIGHT_HEADER_PX,
    },
    nestedFlexItem: {
        lineHeight: 16,
        margin: '0px 10px',
        selectors: {
            '@media(max-width: 600px)': {
                textAlign: 'center',
                margin: '0px',
            },
        },
    },
    link: {
        color: NeutralColors.white,
        textDecorationLine: 'none',
        fontWeight: FontWeights.regular,
        fontSize: FontSizes.mediumPlus,
        selectors: {
            ':hover': {
                textDecorationLine: 'underline',
                color: NeutralColors.gray50,
            },
        },
    },
    userDisplayNameNoLink: {
        color: NeutralColors.white,
        fontWeight: FontWeights.regular,
        fontSize: FontSizes.mediumPlus,
    },
    employeeEmail: {
        color: '#908e8c',
        fontSize: FontSizes.medium,
        fontWeight: FontWeights.regular,
    },
    clockWrapper: {
        fontSize: 16,
    },
});

interface HeaderProps {
    isCsatDefault: boolean;
    onResetCsatDefault: () => void;
    logout(): void;
}

export function Header(props: HeaderProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext) as IUserContext | null;
    const principalUserContext = useContext(PrincipalUserContext);
    const cacheContext = useContext(CacheContext);
    const isAboutUserEnabled = useFeatureFlag(FeatureFlagKeys.aboutUser).enabled;
    const isCSATFeedbackEnabled = useFeatureFlag(FeatureFlagKeys.csatFeedback).enabled;

    const image = useDetermineImage({
        cacheContext: cacheContext,
        authContext: authContext,
        employee: principalUserContext?.principalRecord || userContext?.employeeRecord,
    });

    const Logo = () => {
        const logoContent = (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={logo}
                    height='24'
                    alt='Microsoft Personnel logo'
                    aria-label='Microsoft Personnel logo'
                    style={{ paddingTop: '.15rem' }}
                />
                <span
                    style={{
                        margin: '0 .5rem .4rem',
                        fontWeight: '200',
                        fontSize: '32px',
                    }}>
                    {' '}
                    |{' '}
                </span>
                <span style={{ fontWeight: '600', fontSize: '20px' }}>Personnel</span>
            </div>
        );
        if (authContext.renderMode() === RenderMode.Web) {
            return (
                <Link to='/' style={{ all: 'unset', cursor: 'pointer' }}>
                    {logoContent}
                </Link>
            );
        }
        return logoContent;
    };

    const userDisplayNameTrimmed = (
        <EllipsisText
            text={getDisplayNameOrDefault(
                principalUserContext?.principalRecord || userContext?.employeeRecord,
                '',
            )}
            textLengthBeforeEllipsis={MAX_CHARACTERS_TO_DISPLAY}
        />
    );

    return (
        <div className={styles.header}>
            <div className={styles.headerWrapper}>
                <div className={`${styles.logoWrapper} ${styles.nestedFlex}`}>
                    <Logo />
                </div>
                {authContext.renderMode() === RenderMode.Web && <PersonnelNavigationMenu />}
                {authContext.renderMode() === RenderMode.Web && isCSATFeedbackEnabled && (
                    <FeedbackNotificationsProfileIcons
                        isCsatDefault={props.isCsatDefault}
                        onResetCsatDefault={props.onResetCsatDefault}
                    />
                )}
                {authContext.renderMode() === RenderMode.Web && !isCSATFeedbackEnabled && (
                    <div className={styles.nestedFlex}>
                        {principalUserContext?.principalRecord || userContext?.employeeRecord ? (
                            <>
                                <div className={styles.hideOnResponsive}>
                                    {image && image !== fetchPictureBase ? (
                                        <img
                                            alt='User profile'
                                            aria-label='User profile'
                                            width='32'
                                            height='32'
                                            style={{ borderRadius: '50%' }}
                                            src={image}
                                        />
                                    ) : (
                                        <img
                                            alt='User profile'
                                            aria-label='User profile'
                                            width='32'
                                            height='32'
                                            style={{ borderRadius: '50%' }}
                                            src='data:image/svg+xml, %3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2064%2064%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23fff%3B%7D%3C/style%3E%3C/defs%3E%3Ctitle%3EAsset%202%3C/title%3E%3Cg%20id%3D%22Layer_2%22%20data-name%3D%22Layer%202%22%3E%3Cg%20id%3D%22Elements%22%3E%3Cpath%20class%3D%22cls-1%22%20d%3D%22M42.26%2C42.29a30.12%2C30.12%2C0%2C0%2C1%2C8.63%2C4.62%2C30.53%2C30.53%2C0%2C0%2C1%2C6.54%2C6.87%2C31.35%2C31.35%2C0%2C0%2C1%2C4.13%2C8.54A32.4%2C32.4%2C0%2C0%2C1%2C63%2C72H58.5a27.57%2C27.57%2C0%2C0%2C0-2.06-10.71A25.88%2C25.88%2C0%2C0%2C0%2C42.21%2C47.06%2C27.57%2C27.57%2C0%2C0%2C0%2C31.5%2C45a27.34%2C27.34%2C0%2C0%2C0-13.73%2C3.64%2C26.37%2C26.37%2C0%2C0%2C0-9.63%2C9.63%2C27.13%2C27.13%2C0%2C0%2C0-2.69%2C6.49A27.4%2C27.4%2C0%2C0%2C0%2C4.5%2C72H0a31.64%2C31.64%2C0%2C0%2C1%2C1.48-9.69%2C31.32%2C31.32%2C0%2C0%2C1%2C4.18-8.49A30.89%2C30.89%2C0%2C0%2C1%2C12.22%2C47a31.91%2C31.91%2C0%2C0%2C1%2C8.6-4.68A22.23%2C22.23%2C0%2C0%2C1%2C12.18%2C34a23%2C23%2C0%2C0%2C1-2.36-5.52%2C22.62%2C22.62%2C0%2C0%2C1%2C.93-14.8%2C22.5%2C22.5%2C0%2C0%2C1%2C12-12%2C22.76%2C22.76%2C0%2C0%2C1%2C17.54%2C0%2C22.5%2C22.5%2C0%2C0%2C1%2C12%2C12%2C22.57%2C22.57%2C0%2C0%2C1%2C.93%2C14.78A22.8%2C22.8%2C0%2C0%2C1%2C50.82%2C34a23.28%2C23.28%2C0%2C0%2C1-3.69%2C4.69A21.77%2C21.77%2C0%2C0%2C1%2C42.26%2C42.29ZM13.5%2C22.5a17.33%2C17.33%2C0%2C0%2C0%2C1.42%2C7%2C18.23%2C18.23%2C0%2C0%2C0%2C9.58%2C9.58%2C17.9%2C17.9%2C0%2C0%2C0%2C14%2C0%2C18.23%2C18.23%2C0%2C0%2C0%2C9.58-9.58%2C17.33%2C17.33%2C0%2C0%2C0%2C1.42-7%2C17.33%2C17.33%2C0%2C0%2C0-1.42-7A18.23%2C18.23%2C0%2C0%2C0%2C38.5%2C5.92a17.9%2C17.9%2C0%2C0%2C0-14%2C0%2C18.23%2C18.23%2C0%2C0%2C0-9.58%2C9.58A17.33%2C17.33%2C0%2C0%2C0%2C13.5%2C22.5Z%22/%3E%3C/g%3E%3C/g%3E%3C/svg%3E'
                                        />
                                    )}
                                </div>
                                <div className={styles.hideOnResponsive}>
                                    {isAboutUserEnabled ? (
                                        <a href={'/about/user'} className={styles.link}>
                                            {userDisplayNameTrimmed}
                                        </a>
                                    ) : (
                                        <span className={styles.userDisplayNameNoLink}>
                                            {userDisplayNameTrimmed}
                                        </span>
                                    )}
                                    <br />
                                    <span className={styles.employeeEmail}>
                                        {principalUserContext?.principalRecord?.upn ||
                                            userContext?.employeeRecord?.email}
                                    </span>
                                </div>
                            </>
                        ) : (
                            <Spinner
                                styles={{ label: { color: NeutralColors.white } }}
                                label='Please wait while we load your information'
                                ariaLive='assertive'
                                labelPosition='right'
                            />
                        )}
                        <div className={styles.hideOnResponsive}>
                            <ActionButton
                                onClick={(): void => props.logout()}
                                className={styles.link}>
                                Logout
                            </ActionButton>
                        </div>
                    </div>
                )}
                {(authContext.isKioskLandingRenderMode() || authContext.isKioskRenderMode()) && (
                    <div className={styles.nestedFlex}>
                        {isKioskAppPresent && <FacilitiesKioskRestartOptions />}
                        <span className={styles.clockWrapper}>
                            <Clock />
                        </span>
                        {authContext.isKioskRenderMode() && (
                            <div className={styles.nestedFlexItem}>
                                <ActionButton
                                    onClick={(): void => props.logout()}
                                    className={styles.link}>
                                    Sign Out
                                </ActionButton>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
