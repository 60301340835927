import React, { useState, useEffect, useContext } from 'react';
import {
    Dropdown,
    IDropdownOption,
    mergeStyles,
    IDropdownStyles,
    Checkbox,
    Stack,
    IStackTokens,
    Spinner,
    SpinnerSize,
    DatePicker,
} from '@fluentui/react';
import { IAttribute, PersonnelTypes, IAttributeAssignment } from 'clients/eligibility-client';
import { UserAssignmentsContext } from 'components/user-assignments/user-assignments-context';
import { getDate, datePlusDays } from 'utils/time-utils';
import { BlackListAttributeCode } from 'components/user-assignments/user-assignments-constants';

interface IAssignAttributeProps {
    personnelType: PersonnelTypes;
    attributeOptions: IAttributeAssignment;
    handleSetSelectedItem: (item: IDropdownOption) => void;
    handleSetIsSubmitButtonEnabled: (value: boolean) => void;
    handleSetSelectedDate: (date: Date | null | undefined) => void;
}

export default function AssignAttribute(props: IAssignAttributeProps): JSX.Element {
    const [isChecked, setIsChecked] = React.useState(false);
    const [options, setOptions] = useState<IDropdownOption[]>();
    const context = useContext(UserAssignmentsContext);
    const sectionStackTokens: IStackTokens = { childrenGap: 15 };

    useEffect(() => {
        handleSetOptions(context.attributes);
    }, [props.attributeOptions]);

    const handleSetOptions = (allAttributes: IAttribute[]): void => {
        // Remove previously added attributes from master list of Attributes
        // "context.attributes = allAttributes" and convert to dropdown format
        let filteredAttributes = allAttributes.filter(
            (attr: IAttribute) =>
                props.attributeOptions.attributes.findIndex(
                    (personnelAttr) => personnelAttr.attributeCode === attr.attributeCode,
                ) === -1,
        );

        // For visitors, allow all attributes to show on the selection,
        // all the attributes that the visitor doesn't currently have.
        // For employees, filter out certain attributes because
        // they are supposed to be added automatically.
        if (props.personnelType === PersonnelTypes.EMPLOYEE) {
            filteredAttributes = filteredAttributes.filter(
                (attr: IAttribute) => !BlackListAttributeCode.includes(attr.attributeCode),
            );
        }

        const attrOptions: IDropdownOption[] = filteredAttributes.map((attr: IAttribute) => {
            return {
                key: attr.id,
                text: attr.attributeCode,
            };
        });

        setOptions(attrOptions);
    };

    const onDropdownChange = (
        event: React.FormEvent<HTMLDivElement>,
        item?: IDropdownOption,
    ): void => {
        if (item) props.handleSetSelectedItem(item);
    };

    const onCheckboxChange = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsChecked(!!checked);
            props.handleSetIsSubmitButtonEnabled(!checked);
        },
        [],
    );

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 350 },
    };

    return (
        <div>
            <h3 className={mergeStyles({ marginBottom: '.5rem' })}>Assign Personnel Attribute </h3>

            <Stack tokens={sectionStackTokens}>
                {!options ? (
                    <Spinner size={SpinnerSize.large} />
                ) : (
                    <>
                        <Dropdown
                            ariaLabel='Attribute'
                            onChange={onDropdownChange}
                            placeholder='Select an Attribute...'
                            options={options}
                            styles={dropdownStyles}
                        />
                        <Checkbox
                            ariaLabel='Attribute Has Expiry Date'
                            label='Has Expiry Date'
                            checked={isChecked}
                            onChange={onCheckboxChange}
                        />
                        <DatePicker
                            ariaLabel='Attribute Expiry Date'
                            onSelectDate={props.handleSetSelectedDate}
                            placeholder='Select a date...'
                            minDate={datePlusDays(getDate().Today(), 1)} // Accept from tomorrow onwards
                            disabled={!isChecked}
                        />
                    </>
                )}
            </Stack>
        </div>
    );
}
