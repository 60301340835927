import {
    ActionButton,
    ConstrainMode,
    IColumn,
    IDetailsHeaderStyles,
    mergeStyleSets,
    Separator,
    Stack,
    TextField,
    TextFieldBase,
} from '@fluentui/react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import EmployeeCard from 'components/common/employee/employee-card';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import { Table, TableCell } from 'components/common/table';
import Tabs, { TabbedContent } from 'components/common/tabs';
import config from 'environments/environment';
import FacilitiesClient, {
    IFacilitiesToken,
    IFacilitiesTokenResult,
} from 'clients/facilities-client';
import { isoDateStringToUtcMilliseconds } from 'utils/time-utils';
import { objectToTupleArray, tuple } from 'utils/object-utils';
import { useIsMounted } from 'utils/misc-hooks';
import { useFeatureFlag, FeatureFlagKeys } from 'utils/use-feature-flags';

const cloudScreeningConfig = config.cloudScreeningServiceConfig;
const employeeConfig = config.employeeServiceConfig;
const facilitiesConfig = config.facilitiesServiceConfig;
const documentConfig = config.documentsServiceConfig;

export default function User(): JSX.Element {
    const userContext = useContext(UserContext);
    const authContext = useContext(AuthContext);

    const facilitiesCore = useFeatureFlag(FeatureFlagKeys.facilitiesCore);

    const [msCloudScreeningToken, setMsCloudScreeningToken] = useState<string>();
    const msCloudScreeningTokenField = useRef<TextFieldBase>(null);

    const [usGovScreeningToken, setUsGovScreeningToken] = useState<string>();
    const usGovScreeningTokenField = useRef<TextFieldBase>(null);

    const [docServiceUserImpToken, setDocServiceUserImpToken] = useState<string>();
    const docServiceUserImpTokenField = useRef<TextFieldBase>(null);

    const [agsToken, setAgsToken] = useState<string>();
    const agsTokenField = useRef<TextFieldBase>(null);

    const [aadFacilitiesToken, setAadFacilitiesToken] = useState<string>();
    const aadFacilitiesTokenField = useRef<TextFieldBase>(null);

    const [facilitiesTokenResult, setFacilitiesTokenResult] = useState<IFacilitiesTokenResult>();
    const [facilitiesTokenDecoded, setFacilitiesTokenDecoded] = useState<
        IFacilitiesToken | undefined
    >();
    const facilitiesTokenField = useRef<TextFieldBase>(null);

    const getAgsToken = async (): Promise<void> => {
        const token = await authContext.getToken(employeeConfig.aadScopes);
        if (isMounted() && token) {
            setAgsToken(token);
        }
    };

    const getMsCloudScreeningToken = async (): Promise<void> => {
        const token = await authContext.getToken(cloudScreeningConfig.aadScopes);
        if (isMounted() && token) {
            setMsCloudScreeningToken(token);
        }
    };

    const getUsGovScreeningToken = async (): Promise<void> => {
        const token = await authContext.getToken();
        if (isMounted() && token) {
            setUsGovScreeningToken(token);
        }
    };

    const getDocServiceUserImpToken = async (): Promise<void> => {
        const token = await authContext.getToken(documentConfig.aadScopes);
        if (isMounted() && token) {
            setDocServiceUserImpToken(token);
        }
    };

    const getAadFacilitiesToken = async (): Promise<void> => {
        if (facilitiesCore.enabled) {
            const token = await authContext.getToken(facilitiesConfig.aadScopes);
            if (isMounted() && token) {
                setAadFacilitiesToken(token);
            }

            if (userContext.isFacilitiesTokenLoaded) {
                await refreshFacilitiesToken();
            }
        }
    };

    const refreshFacilitiesToken = async (): Promise<void> => {
        const token = await userContext.refreshFacilitiesToken();
        if (isMounted() && token) {
            setFacilitiesTokenResult(token);
            setFacilitiesTokenDecoded(FacilitiesClient.deserializeFacilitiesToken(token));
        }
    };

    const isMounted = useIsMounted();

    useEffect(() => {
        getMsCloudScreeningToken();
    }, [authContext]);

    useEffect(() => {
        getUsGovScreeningToken();
    }, [authContext]);

    useEffect(() => {
        getDocServiceUserImpToken();
    }, [authContext]);

    useEffect(() => {
        getAgsToken();
    }, [authContext]);

    useEffect(() => {
        getAadFacilitiesToken();
    }, [authContext, userContext.isFacilitiesTokenLoaded]);

    const employeeMap = useMemo(() => {
        if (userContext && userContext.employeeRecord) {
            return objectToTupleArray(userContext.employeeRecord);
        }
        return [];
    }, [userContext.employeeRecord]);

    const msalMap = useMemo(() => {
        if (userContext && userContext.employeeRecord) {
            return objectToTupleArray(userContext.msalUser);
        }
        return [];
    }, [userContext.msalUser]);

    const facilitiesTokenMap = useMemo(() => {
        return objectToTupleArray(facilitiesTokenDecoded);
    }, [facilitiesTokenDecoded]);

    const usGovScreeningUserTypes = useMemo(() => {
        const tmpScreeningUserTypes: string[] = [];
        if (userContext && userContext.usGovScreeningUserTypes) {
            userContext.usGovScreeningUserTypes.forEach((x) => tmpScreeningUserTypes.push(x));
        }
        return tmpScreeningUserTypes;
    }, [userContext.usGovScreeningUserTypes]);

    const publicTrustScreeningUserTypes = useMemo(() => {
        const tmpScreeningUserTypes: string[] = [];
        if (userContext && userContext.publicTrustScreeningUserTypes) {
            userContext.publicTrustScreeningUserTypes.forEach((x) => tmpScreeningUserTypes.push(x));
        }
        return tmpScreeningUserTypes;
    }, [userContext.publicTrustScreeningUserTypes]);

    const cloudScreeningUserTypes = useMemo(() => {
        const tmpScreeningUserTypes: string[] = [];
        if (userContext && userContext.cloudScreeningUserTypes) {
            userContext.cloudScreeningUserTypes.forEach((x) => tmpScreeningUserTypes.push(x));
        }
        return tmpScreeningUserTypes;
    }, [userContext.cloudScreeningUserTypes]);

    const copyAgsToken = useCallback(() => {
        copyText(agsTokenField?.current, 'Copied AGS Token');
    }, [agsTokenField]);

    const copyUsGovCloudScreeningToken = useCallback(() => {
        copyText(usGovScreeningTokenField?.current, 'Copied US-Gov Screening Token');
    }, [usGovScreeningTokenField]);

    const copyDocServiceUserImpToken = useCallback(() => {
        copyText(
            docServiceUserImpTokenField?.current,
            'Copied Document Service User Impersonation Token',
        );
    }, [docServiceUserImpTokenField]);

    const copyMsCloudScreeningToken = useCallback(() => {
        copyText(msCloudScreeningTokenField?.current, 'Copied MS-Cloud Screening Token');
    }, [msCloudScreeningTokenField]);

    const copyAadFacilitiesToken = useCallback(() => {
        copyText(aadFacilitiesTokenField?.current, 'Copied Aad Facilities Token');
    }, [aadFacilitiesTokenField]);

    const copyFacilitiesToken = useCallback(() => {
        copyText(facilitiesTokenField?.current, 'Copied Facilities Token');
    }, [facilitiesTokenField]);

    function copyText(textField: TextFieldBase | undefined | null, message: string): void {
        if (textField) {
            textField.select();
            document.execCommand('copy');
            alert(message);
        }
    }

    function facilitiesStackItem(): JSX.Element {
        return (
            <Stack.Item>
                <Stack>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item>
                                <TextField
                                    label={'Aad Facilities Service Token'}
                                    componentRef={aadFacilitiesTokenField}
                                    value={aadFacilitiesToken || ''}
                                    contentEditable={false}
                                    multiline={true}
                                    resizable={true}
                                    styles={{
                                        fieldGroup: styles.tokenField,
                                    }}
                                    readOnly
                                />
                            </Stack.Item>
                            <Stack.Item
                                styles={{
                                    root: styles.copyButton,
                                }}>
                                <ActionButton
                                    label='Copy Token'
                                    text='Copy Token'
                                    iconProps={{
                                        iconName: IconNames.Copy,
                                    }}
                                    onClick={copyAadFacilitiesToken}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Separator />
                        <Stack>
                            {facilitiesTokenResult && (
                                <>
                                    <Stack.Item>
                                        Facilities Token <br />
                                        Expires: {facilitiesTokenResult.expires} (
                                        {isoDateStringToUtcMilliseconds(
                                            facilitiesTokenResult.expires,
                                        )}
                                        ) <br />
                                        Can Access?{' '}
                                        {`${FacilitiesClient.canAccessFacilities(
                                            facilitiesTokenResult,
                                        )}`}
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Table
                                            isFetchingData={false}
                                            tableColumns={tableColumns}
                                            rows={facilitiesTokenMap}
                                            constrainMode={ConstrainMode.unconstrained}
                                            detailsRowStyles={detailsRowStyles}
                                            tableName='Token'
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack horizontal>
                                            <Stack.Item>
                                                <TextField
                                                    label={'Facilities Token'}
                                                    componentRef={facilitiesTokenField}
                                                    value={facilitiesTokenResult.token || ''}
                                                    contentEditable={false}
                                                    multiline={true}
                                                    resizable={true}
                                                    styles={{
                                                        fieldGroup: styles.tokenField,
                                                    }}
                                                    readOnly
                                                />
                                            </Stack.Item>
                                            <Stack.Item
                                                styles={{
                                                    root: styles.copyButton,
                                                }}>
                                                <ActionButton
                                                    label='Copy Token'
                                                    text='Copy Token'
                                                    iconProps={{
                                                        iconName: IconNames.Copy,
                                                    }}
                                                    onClick={copyFacilitiesToken}
                                                />
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <ActionButton
                                            label='Refresh Facilities-Token'
                                            text='Refresh Facilities Token'
                                            onClick={refreshFacilitiesToken}
                                            iconProps={{
                                                iconName: IconNames.Refresh,
                                            }}
                                        />
                                    </Stack.Item>
                                </>
                            )}
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        );
    }

    return (
        <SidebarAndContents>
            <SidebarPane sidebarPaneOuterStyle={{ minWidth: '400px' }}>
                <EmployeeCard
                    employee={userContext.employeeRecord}
                    manager={''}
                    managerAlias={''}
                />
            </SidebarPane>
            <ContentPane>
                <Stack>
                    <Stack.Item>
                        <Tabs>
                            <TabbedContent tabHeader='Auth Info'>
                                <Stack>
                                    <Stack.Item>
                                        <Table
                                            isFetchingData={false}
                                            tableColumns={tableColumns}
                                            rows={msalMap}
                                            constrainMode={ConstrainMode.unconstrained}
                                            detailsRowStyles={detailsRowStyles}
                                            tableName='Auth Info'
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Separator />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack.Item>
                                            <Stack.Item>
                                                {'US Gov Screening User Types:'}
                                                <ul>
                                                    {usGovScreeningUserTypes.map((x, index) => (
                                                        <ul key={index}>{x}</ul>
                                                    ))}
                                                </ul>
                                            </Stack.Item>
                                            <Stack.Item>
                                                {'Public Trust Screening User Types:'}
                                                <ul>
                                                    {publicTrustScreeningUserTypes.map(
                                                        (x, index) => (
                                                            <ul key={index}>{x}</ul>
                                                        ),
                                                    )}
                                                </ul>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Stack horizontal>
                                                    <Stack.Item>
                                                        <TextField
                                                            label={'US-Gov-Screen Token'}
                                                            componentRef={usGovScreeningTokenField}
                                                            value={usGovScreeningToken || ''}
                                                            contentEditable={false}
                                                            multiline={true}
                                                            resizable={true}
                                                            styles={{
                                                                fieldGroup: styles.tokenField,
                                                            }}
                                                            readOnly
                                                        />
                                                    </Stack.Item>
                                                    <Stack.Item
                                                        styles={{
                                                            root: styles.copyButton,
                                                        }}>
                                                        <ActionButton
                                                            label='Copy Token'
                                                            text='Copy Token'
                                                            iconProps={{ iconName: IconNames.Copy }}
                                                            onClick={copyUsGovCloudScreeningToken}
                                                        />
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Separator />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Stack horizontal>
                                                    <Stack.Item>
                                                        <TextField
                                                            label={
                                                                'Document Service User Impersonation Token'
                                                            }
                                                            componentRef={
                                                                docServiceUserImpTokenField
                                                            }
                                                            value={docServiceUserImpToken || ''}
                                                            contentEditable={false}
                                                            multiline={true}
                                                            resizable={true}
                                                            styles={{
                                                                fieldGroup: styles.tokenField,
                                                            }}
                                                            readOnly
                                                        />
                                                    </Stack.Item>
                                                    <Stack.Item
                                                        styles={{
                                                            root: styles.copyButton,
                                                        }}>
                                                        <ActionButton
                                                            label='Copy Token'
                                                            text='Copy Token'
                                                            iconProps={{ iconName: IconNames.Copy }}
                                                            onClick={copyDocServiceUserImpToken}
                                                        />
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Separator />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Stack>
                                                    <Stack.Item>
                                                        {'MS-Cloud Screening User Types:'}
                                                        <ul>
                                                            {cloudScreeningUserTypes.map(
                                                                (x, index) => (
                                                                    <ul key={index}>{x}</ul>
                                                                ),
                                                            )}
                                                        </ul>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <Stack horizontal>
                                                            <Stack.Item>
                                                                <TextField
                                                                    label={'MS-Cloud-Screen Token'}
                                                                    componentRef={
                                                                        msCloudScreeningTokenField
                                                                    }
                                                                    value={
                                                                        msCloudScreeningToken || ''
                                                                    }
                                                                    contentEditable={false}
                                                                    multiline={true}
                                                                    resizable={true}
                                                                    styles={{
                                                                        fieldGroup:
                                                                            styles.tokenField,
                                                                    }}
                                                                    readOnly
                                                                />
                                                            </Stack.Item>
                                                            <Stack.Item
                                                                styles={{
                                                                    root: styles.copyButton,
                                                                }}>
                                                                <ActionButton
                                                                    label='Copy Token'
                                                                    text='Copy Token'
                                                                    iconProps={{
                                                                        iconName: IconNames.Copy,
                                                                    }}
                                                                    onClick={
                                                                        copyMsCloudScreeningToken
                                                                    }
                                                                />
                                                            </Stack.Item>
                                                        </Stack>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <Separator />
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <Stack horizontal>
                                                            <Stack.Item>
                                                                <TextField
                                                                    label={
                                                                        'Azure-Global-Services Token'
                                                                    }
                                                                    componentRef={agsTokenField}
                                                                    value={agsToken || ''}
                                                                    contentEditable={false}
                                                                    multiline={true}
                                                                    resizable={true}
                                                                    styles={{
                                                                        fieldGroup:
                                                                            styles.tokenField,
                                                                    }}
                                                                    readOnly
                                                                />
                                                            </Stack.Item>
                                                            <Stack.Item
                                                                styles={{
                                                                    root: styles.copyButton,
                                                                }}>
                                                                <ActionButton
                                                                    label='Copy Token'
                                                                    text='Copy Token'
                                                                    iconProps={{
                                                                        iconName: IconNames.Copy,
                                                                    }}
                                                                    onClick={copyAgsToken}
                                                                />
                                                            </Stack.Item>
                                                        </Stack>
                                                    </Stack.Item>
                                                    {facilitiesCore.enabled &&
                                                        facilitiesStackItem()}
                                                </Stack>
                                            </Stack.Item>
                                        </Stack.Item>
                                    </Stack.Item>
                                </Stack>
                            </TabbedContent>
                            <TabbedContent tabHeader='User Info'>
                                <Table
                                    isFetchingData={false}
                                    tableColumns={tableColumns}
                                    rows={employeeMap}
                                    constrainMode={ConstrainMode.unconstrained}
                                    detailsRowStyles={detailsRowStyles}
                                    tableName='User Info'
                                />
                            </TabbedContent>
                        </Tabs>
                    </Stack.Item>
                </Stack>
            </ContentPane>
        </SidebarAndContents>
    );
}

const styles = mergeStyleSets({
    copyButton: {
        alignSelf: 'flex-end',
    },
    tokenField: {
        width: 400,
    },
});

const detailsRowStyles: Partial<IDetailsHeaderStyles> = {
    root: {
        selectors: {
            '.ms-DetailsRow-cell': {
                whiteSpace: 'normal',
                height: 'unset',
                lineHeight: 'normal',
            },
        },
    },
};

const tableColumns: IColumn[] = [
    {
        key: 'key',
        name: 'Key',
        minWidth: 150,
        maxWidth: 150,
        fieldName: 'key',
    },
    {
        key: 'value',
        name: 'Value',
        minWidth: 150,
        isMultiline: true,
        onRender: (row: tuple): JSX.Element => {
            return Array.isArray(row.value) ? (
                <ul>
                    {[...row.value].map((x, index) => (
                        <li key={index}>{`${x}`}</li>
                    ))}
                </ul>
            ) : (
                <TableCell>{`${row.value}`}</TableCell>
            );
        },
    },
];
