import { ComboBox, TextField, Label, IDropdownOption } from '@fluentui/react';
import React, { useCallback } from 'react';
import ElementFooter from 'components/forms/element-footer';
import { ElementTypeProps } from 'components/forms/forms-common';

export default function PeoplePicker(props: ElementTypeProps): JSX.Element {
    const { element, updateForm } = props;
    const customLabel = (label: string): JSX.Element => {
        return <div style={{ fontWeight: 400 }}>{label}</div>;
    };

    const employeeTypeOptions: IDropdownOption[] = [
        { key: 'person', text: 'Single' },
        { key: 'people', text: 'Multiple' },
    ];

    const onEmployeeTypeChange = (itemKey: string): void => {
        updateForm(element.id, itemKey, 'type');
    };

    const employeeElement = useCallback(
        () => (
            <div>
                <Label htmlFor='validationTypeId'>{customLabel('Employee Type')}</Label>
                <ComboBox
                    ariaLabel='Employee Types'
                    id='validationTypeId'
                    aria-label='EmployeeType'
                    options={employeeTypeOptions}
                    selectedKey={
                        employeeTypeOptions.find((x) => x.key === element.type)?.key || 'person'
                    }
                    onChange={(ev, option) => {
                        if (option) {
                            onEmployeeTypeChange(`${option.key}`);
                        }
                    }}
                />
            </div>
        ),
        [element.type],
    );

    return (
        <>
            <TextField
                value={element.label}
                onChange={(ev, newValue): void => updateForm(element.id, newValue, 'label')}
                placeholder='Enter a label for Employee Picker...'
                underlined
            />
            <TextField
                value={element.description}
                onChange={(ev, newValue): void => updateForm(element.id, newValue, 'description')}
                placeholder='Enter a description for Employee Picker...'
            />
            <ComboBox ariaLabel='Employees' options={[]} disabled />
            <ElementFooter
                element={element}
                updateForm={updateForm}
                hasRequiredToggle={true}
                CustomElement={employeeElement}
            />
        </>
    );
}
