import React, { useContext, useState } from 'react';
import { AuthContext } from 'contexts/auth-context';
import GraphClient, { IGraphGroup } from 'clients/graph-client';
import { GeneralFetchResultDataType, useFetchSimple } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';

interface DisplaySecurityGroupProps {
    groupId: string | undefined;
}

export default function DisplaySecurityGroup(props: DisplaySecurityGroupProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const [securityGroupVar, setSecurityGroupVar] = useState<
        GeneralFetchResultDataType<IGraphGroup>
    >();
    const securityGroup = securityGroupVar?.value;

    useFetchSimple<IGraphGroup | undefined>({
        dependencies: [props.groupId],
        canPerformFetch: !!props.groupId,
        fetchFunc: (): Promise<IGraphGroup | undefined> => {
            setSecurityGroupVar({ isFetching: true });
            return GraphClient.getGroup(
                authContext,
                // The following typecast is safe, because the condition of
                // "canPerformFetch" will not allow the following be called
                // if props.groupId is undefined.
                props.groupId as string,
                // Using fetch cache because in zero time, this component
                // may be instantiated many times, each time asking to
                // fetch the group from backend, and sometimes the same group
                // is listed in the timeline more than once.
                { useFetchCache: true },
            );
        },
        onSuccess: (group: IGraphGroup | undefined) => {
            setSecurityGroupVar({ value: group });
        },
        onError: (): void => {
            setSecurityGroupVar({ errMsg: 'Error fetching security group' });
        },
        onFinally: doNothing,
    });

    if (!props.groupId || !securityGroup) {
        return <></>;
    }
    return <span>{securityGroup.displayName}</span>;
}
