import React, { useEffect, useState } from 'react';
import { IDropdownOption, ComboBox, IComboBoxOption, IComboBox } from '@fluentui/react';
import { dropdownComboBox } from 'components/screening/common/filters/common-filter-styling';
import { IContract } from 'components/screening/us-gov/IContract';
import { strCmp } from 'utils/sort-utils';
export interface ContractDropdownProps {
    label?: string;
    placeHolder?: string;
    contracts: IContract[];
    onContractSelected: (contractIdArr: string[], contractId: string, adding: boolean) => void;
    filterCleared: string | undefined;
}

const transformContractsToDropdownOptions = (contracts: IContract[]): IDropdownOption[] =>
    contracts
        .filter((contract): boolean => (contract?.id ? true : false))
        .sort((c1, c2) => strCmp(c1.id, c2.id))
        .map(
            (contract): IDropdownOption => {
                return {
                    text: contract.id,
                    key: contract.id,
                    data: contract,
                };
            },
        );

function ContractDropdown(props: ContractDropdownProps): JSX.Element {
    const contractOptions = transformContractsToDropdownOptions(props.contracts);
    const placeholder = props.placeHolder;
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    useEffect(() => {
        if (props.filterCleared) setSelectedKeys([]);
    }, [props.filterCleared]);

    return (
        <>
            <ComboBox
                label={props.label}
                ariaLabel='Contracts'
                multiSelect
                selectedKey={selectedKeys}
                placeholder={placeholder}
                autoComplete='on'
                options={contractOptions}
                onChange={onContractSelect}
                useComboBoxAsMenuWidth
                allowFreeform
                styles={dropdownComboBox}
            />
        </>
    );

    function onContractSelect(event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void {
        if (option) {
            const contractId = option.data.id;
            const isAdding = !!option.selected;
            let newlySelected: string[] = [];
            setSelectedKeys((prevSelectedKeys) => {
                newlySelected = isAdding
                    ? [...prevSelectedKeys, option!.key as string]
                    : prevSelectedKeys.filter((k) => k !== option!.key);
                return newlySelected;
            });
            props.onContractSelected(newlySelected, contractId, isAdding);
        }
    }
}

export default ContractDropdown;
