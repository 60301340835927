import { IColumn, Stack } from '@fluentui/react';
import { xxLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { IGroup, IPointOfContact } from 'clients/group-client';
import { TableCell } from 'components/common/table';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import DeletePocModalActionButton from 'components/groups/manage-group/roles/buttons/delete-poc-modal-action-button';
import React from 'react';
import { TimeFormats, timeToString } from 'utils/time-utils';

enum PocsTableColumnNames {
    name = 'Name',
    createdBy = 'Created By',
    dateModified = 'Date Modified',
    actions = 'Actions',
}

interface ITableColumnsGroupOwnershipsParams {
    group: IGroup;
    canDeletePoc: boolean;
    showPoc: (poc: IPointOfContact) => JSX.Element;
    onPocDeleted: (updatedGroup: IGroup) => void;
}

export function tableColumnsPointsOfContact(params: ITableColumnsGroupOwnershipsParams): IColumn[] {
    const columnWidths = {
        name: 100,
        modifiedBy: 100,
        dateModified: 140,
        actions: 80,
    };

    const columns = [
        {
            key: PocsTableColumnNames.name,
            name: PocsTableColumnNames.name,
            ariaLabel: PocsTableColumnNames.name,
            minWidth: columnWidths.name,
            maxWidth: columnWidths.name * xxLargeMaxWidthCoeff,
            onRender: (row: IPointOfContact): JSX.Element => (
                <TableCell key={`${row.contactValue}-${PocsTableColumnNames.name}`}>
                    {params.showPoc(row)}
                </TableCell>
            ),
        },
        {
            key: PocsTableColumnNames.createdBy,
            name: PocsTableColumnNames.createdBy,
            ariaLabel: PocsTableColumnNames.createdBy,
            minWidth: columnWidths.modifiedBy,
            maxWidth: params.canDeletePoc
                ? columnWidths.modifiedBy * xxLargeMaxWidthCoeff
                : undefined,
            onRender: (row: IPointOfContact): JSX.Element => (
                <TableCell key={`${row.contactValue}-${PocsTableColumnNames.createdBy}`}>
                    <CoreEmployeeHoverCardFromPrincipalId principalId={row.createdBy} />
                </TableCell>
            ),
        },
        {
            key: PocsTableColumnNames.dateModified,
            name: PocsTableColumnNames.dateModified,
            ariaLabel: PocsTableColumnNames.dateModified,
            minWidth: columnWidths.dateModified,
            maxWidth: params.canDeletePoc ? undefined : columnWidths.dateModified,
            onRender: (row: IPointOfContact): JSX.Element => (
                <TableCell key={`${row.contactValue}-${PocsTableColumnNames.dateModified}`}>
                    {timeToString(row.createdTimestampUTC * 1000, TimeFormats.MMMDDYYYY_hmmA)}
                </TableCell>
            ),
        },
    ];

    if (params.canDeletePoc) {
        columns.push({
            key: PocsTableColumnNames.actions,
            name: PocsTableColumnNames.actions,
            ariaLabel: PocsTableColumnNames.actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions,
            onRender: (row: IPointOfContact): JSX.Element => (
                <TableCell key={`${row.contactValue}-${PocsTableColumnNames.actions}`}>
                    <Stack horizontal>
                        {params.canDeletePoc && (
                            <DeletePocModalActionButton
                                group={params.group}
                                pointOfContact={row}
                                showPoc={params.showPoc}
                                onPocDeleted={params.onPocDeleted}
                                canDeletePoc={params.canDeletePoc}
                            />
                        )}
                    </Stack>
                </TableCell>
            ),
        });
    }
    return columns;
}
