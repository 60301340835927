import { MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react';
import { isKioskAppPresent } from 'contexts/auth-context';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Spacer from 'components/common/spacer';

const fieldStyle: React.CSSProperties = {
    userSelect: 'all',
    wordWrap: 'break-word',
    fontFamily: 'Courier New',
};

export default function FacilitiesKioskProvisionPage(): JSX.Element {
    const [isActionInProgress, setIsActionInProgress] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const { deviceId } = useParams<{ deviceId: string }>();

    const urlParams = new URLSearchParams(window.location.search);
    const endorsementKey = urlParams.get('ek')?.replaceAll('-', '+')?.replaceAll('_', '/');

    async function restartUwp(): Promise<void> {
        const httpOptions = { method: 'POST' };
        setIsActionInProgress(true);
        const response = await fetch('https://127.0.0.1:7072/restart?isuwponly=true', httpOptions);
        setIsActionInProgress(false);

        if (response.ok) {
            return;
        }

        if (response.status === 400 || response.status === 500) {
            setErrorMessage(await response.text());
        }
    }

    return (
        <div>
            <h1>Device is ready for provisioning</h1>
            <div>Device ID:</div>
            <div style={fieldStyle}>{deviceId}</div>
            <br />
            <div>Endorsment Key:</div>
            <div style={fieldStyle}>{endorsementKey}</div>
            <br />
            <div>URL:</div>
            <div style={fieldStyle}>{location.href}</div>
            <br />
            {isKioskAppPresent && <div>Create QR to open this page off kiosk</div>}
            {isKioskAppPresent && (
                <>
                    <div>
                        <span>If something went wrong running the kiosk click here</span>{' '}
                        <PrimaryButton
                            ariaLabel='Restart App'
                            onClick={(): Promise<void> => restartUwp()}
                            disabled={isActionInProgress}>
                            Restart App
                        </PrimaryButton>{' '}
                        <span>to try again</span>
                    </div>
                    {errorMessage && (
                        <>
                            <Spacer marginTop={5} />
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                onDismiss={(): void => setErrorMessage(undefined)}>
                                {errorMessage}
                            </MessageBar>
                        </>
                    )}
                </>
            )}
        </div>
    );
}
