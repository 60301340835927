import { IPersonaProps } from '@fluentui/react';
import { IEmployee, SearchEmployeeStatus } from 'clients/employee-client';
import { IBaseEmployeePickerTypeaheadSearchPropsNoItems } from 'components/common/core-employee-picker-typeahead-search';
import EmployeeListPicker from 'components/common/employee-list-picker';
import { useDebounceGetEmployeePersonas } from 'components/common/employee/internal-employee-utils';
import React from 'react';

export interface IEmployeePickerTypeaheadSearchProps
    extends IBaseEmployeePickerTypeaheadSearchPropsNoItems {
    selectedItems?: IPersonaProps[];
    selectedPersona?: IPersonaProps;
    // itemLimit: Default: 1. Specify EmployeeListPickerUnlimited for unlimited.
    itemLimit?: number;
    // If itemLimit is 1 (the default), use the prop onCandidateSelected.
    onCandidateSelected?: (item?: IPersonaProps, itemAlt?: IPersonaProps) => void;
    // If itemLimit is unlimited, use the prop onMultipleCandidatesSelected.
    onMultipleCandidatesSelected?: (items?: IPersonaProps[], itemsAlt?: IPersonaProps) => void; // Use this when itemLimit is -1 or >1
    searchEmployeeStatus?: SearchEmployeeStatus;
    showFTEOnly?: boolean;
}

export default function EmployeePickerTypeaheadSearch(
    props: IEmployeePickerTypeaheadSearchProps,
): JSX.Element {
    const debouncedGetEdsPersonas = useDebounceGetEmployeePersonas(
        props.searchEmployeeStatus,
        undefined,
        undefined,
        undefined,
        props.showFTEOnly,
    );

    return (
        <EmployeeListPicker
            label={props.label}
            ariaLabel={props.ariaLabel}
            disabled={props.disabled}
            required={props.required}
            itemLimit={props.itemLimit}
            placeHolder={props.placeHolder}
            selectedItems={props?.selectedItems}
            selectedPersona={props.selectedPersona}
            onCandidateSelected={props.onCandidateSelected}
            onMultipleCandidatesSelected={props.onMultipleCandidatesSelected}
            loadCandidatesOnFilterChange={debouncedGetEdsPersonas}
            styles={{
                textBoxStyle: props.styles?.textBoxStyle,
                inputStyle: props.styles?.inputStyle,
            }}
        />
    );
}

export const onPersonSelectedHandler = (
    info: IPersonaProps | undefined,
    // setFunc is the set function of the state, variable
    // such as setEmployee in the following example:
    //      const [employee, setEmployee] = useState<IEmployee>();
    setFunc: React.Dispatch<React.SetStateAction<IEmployee | undefined>>,
): void => {
    if (info?.itemProp) {
        const person = JSON.parse(info?.itemProp);
        if (person.id) {
            setFunc(person);
        }
    } else {
        setFunc(undefined);
    }
};
