import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts/auth-context';
import EligibilityClient, { IEligibility } from 'clients/eligibility-client';
import { ProblemLoadingPage } from 'components/common/problem-loading/problem-loading-page';
import { useParams } from 'react-router';
import { useFetch } from 'utils/misc-hooks';
import {
    mergeStyleSets,
    FontSizes,
    FontWeights,
    ITextFieldStyles,
    PrimaryButton,
    TextField,
} from '@fluentui/react';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { UserContext } from 'contexts/user-context';
import config from 'environments/environment';

const ResponseMessages = {
    BasicError: 'Something went wrong',
    EligibilityNotFound: 'The eligibility was not found',
    EligibilityNotRequestable: 'You cannot request this eligibility',
    UserHasEligibility: 'You already have this eligibility',
    RequestUnderReview: `Your request has been received and is being reviewed. If you do not receive a response within 2 business days, please contact `,
};

export default function ElgibilityRequest(): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [requestedEligibility, setRequestedEligibility] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [canUserRequestEleigibility, setCanUserRequestEleigibility] = useState(false);
    const { eligiblityId } = useParams<{ eligiblityId?: string }>();

    const eligibilityReviewDetail = config.production
        ? {
              reviewEmail: 'egs_requests@microsoft.com',
              reviewDL: 'Eligibility_Requests',
          }
        : {
              reviewEmail: 'egs_requests_dev@microsoft.com',
              reviewDL: 'Eligibility_Requests_Dev',
          };

    const onChangeEligibilityRequestField = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setRequestedEligibility(newValue || '');
        },
        [],
    );

    const handleRequestEligibility = async () => {
        if (eligiblityId) {
            try {
                await EligibilityClient.createEligibilityRequest(
                    authContext,
                    eligiblityId,
                    requestedEligibility,
                );
                setResponseMessage(ResponseMessages.RequestUnderReview);
            } catch (error) {
                setResponseMessage(ResponseMessages.BasicError);
            }
        }
    };

    const { item: eligibilityFetched, problemFetching: problemLoadingPage } = useFetch<
        IEligibility
    >({
        fetchFunc: (): Promise<IEligibility> | undefined => {
            if (eligiblityId)
                return EligibilityClient.getEligibilityById(authContext, eligiblityId);
            else {
                setResponseMessage(ResponseMessages.EligibilityNotFound);
                return undefined;
            }
        },
    });

    const checkEligibilityIsRequestable = (eligibility: IEligibility): boolean => {
        const isRequestable = eligibility.userCanRequest;

        if (!isRequestable) {
            setResponseMessage(ResponseMessages.EligibilityNotRequestable);
        }

        return isRequestable;
    };

    const checkEligibilityIsAlreadyAssigned = async (
        personnelID: string,
        eligibilityCode: string,
    ): Promise<boolean> => {
        try {
            const result = await EligibilityClient.hasPersonnelEligibility(
                authContext,
                personnelID,
                eligibilityCode,
            );

            const isAssigned: boolean = result.eligibilities.length >= 1;

            if (isAssigned) {
                setResponseMessage(ResponseMessages.UserHasEligibility);
            }

            return isAssigned;
        } catch (e) {
            return false;
        }
    };

    const checkRequestIsInReview = async (eligibilityId: string): Promise<boolean> => {
        let isInReview: boolean;
        try {
            await EligibilityClient.getActiveEligibilityRequestForPersonnel(
                authContext,
                eligibilityId,
            );
            isInReview = true;
        } catch (e) {
            isInReview = false;
        }

        if (isInReview) {
            setResponseMessage(ResponseMessages.RequestUnderReview);
        }

        return isInReview;
    };

    const checkUserCanMakeRequest = async (): Promise<void> => {
        if (eligiblityId && eligibilityFetched && userContext) {
            const personnelID = userContext.employeeRecord.id;
            const isEligAssigned = await checkEligibilityIsAlreadyAssigned(
                personnelID,
                eligibilityFetched.eligibilityCode,
            );
            const isRequestInReview = await checkRequestIsInReview(eligiblityId);
            const canUserMakeRequest =
                checkEligibilityIsRequestable(eligibilityFetched) &&
                !isEligAssigned &&
                !isRequestInReview;

            if (canUserMakeRequest) {
                setCanUserRequestEleigibility(true);
            } else {
                // TODO:  This may require a more detailed message but I won't know until
                // I can test on a non local environment
                setResponseMessage(ResponseMessages.EligibilityNotRequestable);
            }
        }
    };

    useEffect(() => {
        if (eligibilityFetched && eligiblityId) {
            checkUserCanMakeRequest();
        }
    }, [eligibilityFetched, eligiblityId]);

    if (!eligibilityFetched) return <IsLoadingIndicator isLoading={true} />;

    return (
        <ProblemLoadingPage isProblemLoadingPage={!!problemLoadingPage}>
            <div>
                <h2 className={styles.header}>Request Eligibility</h2>
                <h4 className={styles.subHeader}>Request eligibility assignment</h4>
                {canUserRequestEleigibility && (
                    <>
                        <div>{eligibilityFetched.eligibilityCode}</div>
                        <div>{eligibilityFetched.eligibilityName}</div>
                        <div className={styles.requestBlock}>
                            <TextField
                                label='Business Justification'
                                onChange={onChangeEligibilityRequestField}
                                styles={textFieldStyles}
                                value={requestedEligibility || ''}
                            />

                            <PrimaryButton
                                disabled={requestedEligibility === ''}
                                onClick={handleRequestEligibility}>
                                Request Eligibility
                            </PrimaryButton>
                        </div>
                    </>
                )}

                <div className={styles.responseMessage}>
                    {responseMessage !== '' && <>{responseMessage}</>}
                    {responseMessage === ResponseMessages.RequestUnderReview && (
                        <a
                            href={`mailto:${eligibilityReviewDetail.reviewEmail}?subject=Eligibility Request Help`}>
                            {eligibilityReviewDetail.reviewDL}
                        </a>
                    )}
                </div>
            </div>
        </ProblemLoadingPage>
    );
}

const styles = mergeStyleSets({
    header: {
        fontSize: FontSizes.xLargePlus,
        fontWeight: FontWeights.semibold,
        marginBottom: 0,
        paddingBottom: 0,
    },
    subHeader: {
        fontSize: FontSizes.mediumPlus,
        fontWeight: FontWeights.semibold,
        paddingTop: 0,
        marginTop: 0,
    },
    requestBlock: {
        marginTop: 20,
        marginBottom: 20,
    },
    responseMessage: {
        marginTop: 20,
        fontSize: FontSizes.medium,
    },
});

const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 400, marginBottom: 10 } };
