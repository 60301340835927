import { mergeStyleSets } from '@fluentui/react';
import { FontWeights } from '@fluentui/theme';
import { CoreEmployeeHoverCardFromGraph } from 'components/core/common/employee-card/core-employee-hover-card';
import React from 'react';
import { SystemDisplayName, SystemGuid } from 'utils/cor-utils';
import { isGUID } from 'utils/string-utils';
import { TimeFormats, timeToString } from 'utils/time-utils';

export enum TimeUnit {
    Seconds,
    Milliseconds,
}

type ValidDates = string | number | undefined;

export interface LastModifiedByProps {
    lastModified?: {
        by?: string;
        on?: ValidDates;
        lastModifiedBy?: string;
        lastModifiedOn?: ValidDates;
        lastModifiedDate?: ValidDates;
    };
    timeUnit?: TimeUnit;
}

const classNames = mergeStyleSets({
    lastModifiedByRoot: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        height: '100%',
    },
    principal: {
        overflow: 'hidden',
    },
    time: {
        flex: '0 0 auto',
    },
});

export function LastModifiedBy(props: LastModifiedByProps): JSX.Element {
    const by = props.lastModified?.by ?? props.lastModified?.lastModifiedBy;

    // Seconds -> milliseconds
    let on =
        props.lastModified?.on ??
        props.lastModified?.lastModifiedOn ??
        props.lastModified?.lastModifiedDate;
    if (typeof on === 'number' && props.timeUnit === TimeUnit.Seconds) {
        on = on * 1000;
    }

    function produceLastModifiedBy(): JSX.Element {
        if (by) {
            if (by === SystemGuid) {
                return (
                    <span style={{ fontWeight: FontWeights.semibold }}>{SystemDisplayName}</span>
                );
            } else if (isGUID(by)) {
                return (
                    <>
                        <CoreEmployeeHoverCardFromGraph
                            key={by}
                            oid={by}
                            showMiniCardAlias={false}
                            className={classNames.principal}
                        />
                    </>
                );
            }
        }
        return <></>;
    }

    return (
        <div className={classNames.lastModifiedByRoot}>
            {produceLastModifiedBy()}
            <div className={classNames.time}>{timeToString(on, TimeFormats.MDYYYY)}</div>
        </div>
    );
}
