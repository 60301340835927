import React, { useContext, useMemo } from 'react';
import {
    Persona,
    PersonaPresence,
    PersonaInitialsColor,
    PersonaSize,
    mergeStyleSets,
    FontWeights,
    IStyle,
    Icon,
} from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { NavLink } from 'react-router-dom';
import {
    faintBlue,
    grey130,
    grey160,
    grey30,
    lightFaintBlue,
} from 'assets/constants/global-colors';
// eslint-disable-next-line no-restricted-imports -- not used unless UserContext is present
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import { getAlias, getInitials } from 'components/common/employee/employee-utils';
// eslint-disable-next-line no-restricted-imports -- This common file has been updated to handle UserContext not always being present
import { IUserContext, UserContext } from 'contexts/user-context';
import config from 'environments/environment';
import ICardPrincipal from 'components/core/common/employee-card/card-principal';

export interface ICoreEmployeeCardProps {
    cardPrincipal?: ICardPrincipal;
    detailHeight?: number;
    style?: ICoreEmployeeCardStyle; // Additional styling. See Note (±) below.
    // Show compressed version of the card for user-assignments page
    showEmail?: boolean; // Default: true
    showActions?: boolean; // Default: true
    showManager?: boolean; // Default: true
    showMiniCard?: boolean; // Default: false
}
// Note (±)
// The following is added to specify additional stylings for this
// component. If you need to add properties to it, if it makes sense
// and possible try to make it compatible with stylings defined by
// variable "defaultStyle" at Note (+) at the bottom of the file,
// because the function mergeStyleSets will merge the two.
// For instance, the properties "card" and "cardActions" below are
// already properties of object "defaultStyle". By specifying styling
// on them, they will automatically merge them with the already
// available styles by the same name, and you won't have to make any
// additional effort to make them take effect.
export interface ICoreEmployeeCardStyle {
    // Add more stylings here.
    card: IStyle; // Will merge with "style.card" defined at Note (+).
    cardActions?: IStyle; // Additional styles for the <Actions> instance.
    title?: IStyle;
    details?: IStyle;
    contact?: IStyle;
}
export default function CoreEmployeeCard(props: ICoreEmployeeCardProps): JSX.Element {
    const {
        showEmail = true,
        showActions = true,
        showManager = true,
        showMiniCard = false,
    } = props;

    const {
        oid,
        displayName,
        imageUrl,
        jobTitle,
        officeLocation,
        pcn,
        upn,
        email,
        organization,
        manager,
        managerUpn,
        personnelNumber,
    } = props.cardPrincipal ?? {};
    const imageAlt = `${displayName}'s photo`;
    const imageInitials = displayName ? getInitials(displayName) : undefined;

    const tertiaryText = showMiniCard ? organization : officeLocation;

    const alias = getAlias(upn)?.toLocaleLowerCase();
    const emailFormatted = email?.toLocaleLowerCase();
    const managerAliasOrUpn = (getAlias(managerUpn) ?? managerUpn)?.toLowerCase();

    const style = useMemo(() => mergeStyleSets(defaultStyle, props?.style), [
        defaultStyle,
        defaultStyle.card,
        props.style,
        props?.style?.card,
    ]);

    return (
        <div className={style.card}>
            <div
                className={style.details}
                style={{ height: props.detailHeight ? props.detailHeight : 'auto' }}>
                <div style={{ paddingRight: employeeCardPadding, height: 'fit-content' }}>
                    <Persona
                        size={PersonaSize.size56}
                        initialsColor={PersonaInitialsColor.purple}
                        presence={PersonaPresence.none}
                        hidePersonaDetails={true}
                        imageAlt={imageAlt}
                        imageInitials={imageInitials}
                        showInitialsUntilImageLoads={true}
                        imageUrl={imageUrl}
                    />
                </div>
                <div className={style.contact} tabIndex={0}>
                    <div>
                        <div>
                            <span className={style.name}>{displayName}</span>
                        </div>
                    </div>
                    <div>
                        {jobTitle && <div className={style.title}>{jobTitle}</div>}
                        {tertiaryText && <div className={style.genericText}>{tertiaryText}</div>}
                        {pcn && (
                            <div className={style.pcn}>
                                <span className={style.pcnLabel}>PCN:</span>
                                <span>{pcn}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {!showMiniCard && (
                <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                    <div className={style.content}>
                        {upn && (
                            <>
                                <span className={style.label}>{alias ? 'Alias:' : 'UPN:'}</span>
                                {alias ? (
                                    <span className={style.genericText} tabIndex={0}>
                                        {alias}
                                    </span>
                                ) : (
                                    <a
                                        style={{ textDecoration: 'none', color: lightFaintBlue }}
                                        href={'mailto:' + upn}>
                                        {upn}
                                    </a>
                                )}
                            </>
                        )}
                        {upn &&
                            emailFormatted &&
                            showEmail &&
                            (upn.toLocaleLowerCase() !== emailFormatted || alias) && (
                                <span
                                    className={style.genericText}
                                    style={{ paddingLeft: '15px', paddingRight: '15px' }}>
                                    {'|'}
                                </span>
                            )}
                        {emailFormatted &&
                            showEmail &&
                            ((upn?.toLocaleLowerCase() ?? '') !== emailFormatted || alias) && (
                                <span className={style.genericText}>
                                    <a
                                        style={{ textDecoration: 'none', color: lightFaintBlue }}
                                        href={'mailto:' + emailFormatted}>
                                        {emailFormatted}
                                    </a>
                                </span>
                            )}
                    </div>
                    {organization && (
                        <div className={style.content} tabIndex={0}>
                            <span className={style.label}>Organization:</span>
                            <span className={style.genericText}>{organization}</span>
                        </div>
                    )}
                    {manager && showManager && (
                        <div className={style.content} tabIndex={0}>
                            <span className={style.label}>Manager:</span>
                            <span className={style.genericText}>{manager}</span>
                            {managerAliasOrUpn && (
                                <span className={style.genericText}> ({managerAliasOrUpn})</span>
                            )}
                        </div>
                    )}
                </div>
            )}
            {showActions && (
                <Actions
                    oid={oid}
                    alias={alias}
                    upn={upn}
                    personnelNumber={personnelNumber}
                    email={emailFormatted}
                    style={props.style}
                />
            )}
        </div>
    );
}
interface ActionProps {
    alias: string | undefined;
    oid: string | undefined;
    personnelNumber: string | undefined;
    email: string | undefined;
    upn: string | undefined;
    style?: ICoreEmployeeCardStyle;
}
function Actions(props: ActionProps): JSX.Element {
    const userContext = useContext(UserContext) as IUserContext | null;
    const iconStyle = {
        root: {
            fontSize: 20,
            color: '#212121',
            lineHeight: 16,
            marginRight: 5,
        },
    };
    const style = useMemo(() => mergeStyleSets(defaultStyle, props?.style), [
        defaultStyle,
        props.style,
    ]);
    return (
        <div>
            <hr className={style.divider}></hr>
            <div className={style.cardActions}>
                {props.email && (
                    <div className={style.cardAction}>
                        <a
                            className={style.actionLabel}
                            href={'mailto:' + props.email}
                            aria-label='Email'>
                            <Icon iconName='Mail' styles={iconStyle} />
                        </a>
                    </div>
                )}
                {props.upn && (
                    <div className={style.cardAction}>
                        <a
                            className={style.actionLabel}
                            href={'msteams://teams.microsoft.com/l/chat/0/0?users=' + props.upn}
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='Chat'>
                            <Icon iconName='Chat' styles={iconStyle} />
                        </a>
                    </div>
                )}
                {config.orgExplorerUrl && (
                    <div className={style.cardAction}>
                        <a
                            className={style.actionLabel}
                            href={`${config.orgExplorerUrl.replace('{oid}', props.oid ?? '')}`}
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='Org Explorer'>
                            <Icon iconName='Org' styles={iconStyle} />
                        </a>
                    </div>
                )}
                {(props.alias || props.personnelNumber) &&
                    userContext?.hasUsGovScreeningUserType(UsGovScreeningUserType.NST) && (
                        <div className={style.cardAction}>
                            <NavLink
                                to={`/profile/us-gov/${
                                    props?.alias
                                        ? `${props.alias?.toLocaleUpperCase()}`
                                        : `${props.personnelNumber}`
                                }`}
                                className={style.actionLabel}
                                rel='noopener noreferrer'
                                aria-label='View Profile'>
                                <Icon iconName={'ContactInfo'} styles={iconStyle} />
                            </NavLink>
                        </div>
                    )}
            </div>
        </div>
    );
}
/**
 * @constant
 */
export const employeeCardPadding = 15;
const defaultStyle = mergeStyleSets({
    // Note (+)
    employee: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'fit-content',
        cursor: 'pointer',
    },
    genericText: {
        fontSize: '12px',
        color: grey130,
        fontWeight: 400,
        selectors: {
            ':focus': {
                outline: 'none',
            },
        },
    },
    name: {
        fontWeight: 400,
        fontSize: '14px',
        marginRight: '5px',
        borderRadius: 2,
        color: grey160,
    },
    title: {
        fontSize: '12px',
        textTransform: 'uppercase',
        marginBottom: 0,
        marginTop: 0,
        color: grey130,
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 350,
        minHeight: 0,
        backgroundColor: NeutralColors.white,
        boxSizing: 'border-box',
        borderRadius: 2,
        width: 'auto',
    },
    details: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        padding: employeeCardPadding,
    },
    cardActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        padding: '15px',
        cursor: 'pointer',
        backgroundColor: 'white', //requested by the design team
    },
    cardAction: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: '45px',
        paddingLeft: '5px',
        selectors: {
            '& a': {
                color: 'rgb(50, 49, 48)',
            },
            '& a:hover': {
                color: faintBlue,
            },
        },
    },
    actionLabel: {
        textDecoration: 'none',
        fontSize: 14,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
    },
    contact: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        lineHeight: '1.5',
        selectors: {
            ':focus': {
                outline: 'none',
            },
        },
    },
    email: {
        selectors: {
            '': {
                color: faintBlue,
                textDecoration: 'underline',
            },
        },
    },
    content: {
        marginTop: 5,
        color: grey160,
        fontSize: '12px',
        fontWeight: 600,
        selectors: {
            ':focus': {
                outline: 'none',
            },
        },
    },
    label: {
        marginRight: '5px',
        fontWeight: FontWeights.semibold,
    },
    pcn: {
        height: '25px',
        marginTop: 5,
    },
    pcnLabel: {
        marginRight: '5px',
        fontWeight: FontWeights.semibold,
    },
    divider: {
        color: grey30,
        width: 'auto',
        marginBottom: '0px',
        marginTop: '20px',
        marginRight: '15px',
        marginLeft: '15px',
    },
});
