import { TextField } from '@fluentui/react';
import React, { memo } from 'react';
import { ElementProps } from 'components/forms/element-viewer';

type SignatureViewerProps = ElementProps & {
    userName: string;
};
function SignatureViewer(props: SignatureViewerProps): JSX.Element {
    const { element, updateFormResponse, userName } = props;

    return (
        <div style={{ maxWidth: '865px' }}>
            <TextField
                placeholder='Sign your name'
                value={(element.value as string) ?? ''}
                onChange={(ev, newValue) => updateFormResponse(element, newValue ?? '')}
            />
            <span
                style={{
                    color: 'rgba(0,0,0,.6)',
                    whiteSpace: 'pre',
                }}>{`Enter your name, ${userName}, exactly as it appears.`}</span>
        </div>
    );
}

export default memo(SignatureViewer);
