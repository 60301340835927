import React, { useState, useEffect, useContext } from 'react';
import { ICollapseButton, Table } from 'components/common/table';
import {
    IColumn,
    Stack,
    IGroup,
    IGroupRenderProps,
    IGroupHeaderProps,
    Label,
    IDetailsHeaderStyles,
    MessageBar,
    MessageBarType,
    IStyle,
} from '@fluentui/react';
import { IAuthContext } from 'contexts/auth-context';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import { SharedColors } from '@fluentui/theme';
import PieChart, { PieChartItem } from 'components/common/charts/pie-chart';
import { faintBlue } from 'assets/constants/global-colors';
import { useIsMounted } from 'utils/misc-hooks';
import { Dictionary } from 'assets/constants/global-constants';
import { CacheContext } from 'contexts/cache-context';
import {
    customerMetricsStyles,
    expandButtonStyle,
    noExpandButtonStyle,
} from 'components/screening/common/common-tab-styling';
import {
    ICustomerMetricsBackend,
    substatesMetrics,
    metricSubStatesNameMapping,
} from 'components/screening/common/ICustomerMetrics';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
const contractOwner = 'Contract Owner';
const candidate = 'Candidate';
const usg = 'USG';

const processOwnerLabels = [UsGovScreeningUserType.NST, contractOwner, candidate, usg];
const pieChartColors = [
    SharedColors.cyanBlue10,
    SharedColors.blueMagenta20,
    SharedColors.magentaPink20,
    SharedColors.green10,
    SharedColors.orangeYellow20,
    SharedColors.orange20,
    SharedColors.red10,
];

/*
Substates and their corresponding processOwner Notes 3/18/2022 from NST
Nomination State:
    Nomination(substate) - Contract Owner
    Waiting for Contract Owner Approval - Contract Owner
    Waiting for Nominee Approval - Candidate
Preparation State:
    Preparation(substate) - NST
    With Nominee - Candidate
    With NST - NST
    Approval Requested from USG - USG
    Removed from Processing - N/A
Submitted State:
    Submitted(substate) - NST
    Submitted to USG - USG
    Received from USG - NST
    Awaiting Poly - Candidate
    Awaiting Medical - Candidate
    Awaiting Adjudication - Candidate
Adjudicated State:
    Adjudicated(substate) - NST
    Final - NST
    Interim - NST
Indoc State:
    Indoc(substate) - NST
    Awaiting USG - USG
    Awaiting Nominee - Candidate
    Awaiting NST - NST

    So Nomination substate count in IMetricsDaysCount would be the nominationNomineeDurationCountDays minus all other substates
    EX: nominationDurationCountDays - nominationContractOwnerDurationCountDays - nominationNomineeDurationCountDays
    Same goes for other (substate)
*/
interface IMetricsDaysCount {
    nominationDurationCountDays: number;
    nominationContractOwnerDurationCountDays: number;
    nominationNomineeDurationCountDays: number;
    preparationDurationCountDays: number;
    preparationWithNomineeDurationCountDays: number;
    preparationDocumentReceivedDurationCountDays: number; // with NST
    preparationSentToUSGDurationCountDays: number;
    submittedDurationCountDays: number;
    submittedSubmittedToUSGDurationCountDays: number;
    submittedReceivedFromUSGDurationCountDays: number;
    submittedAwaitingPolyResultDurationCountDays: number;
    submittedAwaitingMedicalDurationCountDays: number;
    submittedAwaitingAdjudicationDurationCountDays: number;
    adjudicatedDurationCountDays: number;
    adjudicatedInterimDurationCountDays: number;
    adjudicatedFinalDurationCountDays: number;
    indocDurationCountDays: number;
    indocAwaitingNomineeDurationCountDays: number;
    indocAwaitingUSGDurationCountDays: number;
    indocAwaitingNSTDurationCountDays: number;
}

export interface AllCustomerMetricTableProps {
    authContext: IAuthContext;
    showAdvancedMetrics: boolean;
    filteredCandidates: ICommonScreening[];
    rawCandidateCount: number;
    pageName: string;
    cacheKey: string;
}

export default function AllCustomerMetricTable(props: AllCustomerMetricTableProps): JSX.Element {
    const isMounted = useIsMounted();
    const cacheContext = useContext(CacheContext);
    const [isCollapsing, setCollapsing] = useState<boolean>(true);
    const [groups, setGroups] = useState<IGroup[] | undefined>(undefined);
    const [processOwnerGroups, setProcessOwnerGroups] = useState<IGroup[] | undefined>(undefined);
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [pieData, setPieData] = useState<PieChartItem[]>([]);
    const [processOwnerPieData, setProcessOwnerPieData] = useState<PieChartItem[]>([]);
    const [errorMessage, setMessage] = useState<string>('');
    const [hasErrorOccurred, setErrorOccurred] = useState<boolean>(false);

    const collapseButton: ICollapseButton = {
        isVisible: true,
        isCollapsing: isCollapsing,
        style: { color: faintBlue },
        onToggleCollapseAll: (isAllCollapsed: boolean) => {
            collapseManagerDirectReports(isAllCollapsed);
        },
    };
    function collapseManagerDirectReports(isAllCollapsed: boolean): void {
        const newGroups: IGroup[] = [];
        if (groups) {
            groups.forEach((x) => {
                const copiedGroup: IGroup = {
                    ...x,
                    isCollapsed: isAllCollapsed,
                };
                newGroups.push(copiedGroup);
            });
            setGroups(newGroups);
        }
        setCollapsing(!isCollapsing);
    }

    function buildProcessOwnerPieChartItems(totalCustomerMetric: IMetricsDaysCount): void {
        const nstCount =
            // preparation count without 'With Nominee' and 'Approval Requested from usg'
            +(
                totalCustomerMetric.preparationDurationCountDays -
                totalCustomerMetric.preparationSentToUSGDurationCountDays -
                totalCustomerMetric.preparationWithNomineeDurationCountDays +
                // submitted count without awaiting and 'submit to usg'
                totalCustomerMetric.submittedDurationCountDays -
                totalCustomerMetric.submittedReceivedFromUSGDurationCountDays -
                totalCustomerMetric.submittedAwaitingAdjudicationDurationCountDays -
                totalCustomerMetric.submittedAwaitingMedicalDurationCountDays -
                totalCustomerMetric.submittedAwaitingPolyResultDurationCountDays +
                // all of adjucdicated is nst
                totalCustomerMetric.adjudicatedDurationCountDays +
                // indoc without awaiting 'nominee' and 'usg'
                (totalCustomerMetric.indocDurationCountDays -
                    totalCustomerMetric.indocAwaitingNomineeDurationCountDays -
                    totalCustomerMetric.indocAwaitingUSGDurationCountDays)
            ).toFixed(1);
        // we only need nomination count without Nomination nominee to get total time contract owner owns it.
        const contractOwnerCount = +(
            totalCustomerMetric.nominationDurationCountDays -
            totalCustomerMetric.nominationNomineeDurationCountDays
        ).toFixed(1);

        const candidateCount = +(
            totalCustomerMetric.nominationNomineeDurationCountDays +
            totalCustomerMetric.preparationWithNomineeDurationCountDays +
            totalCustomerMetric.submittedAwaitingAdjudicationDurationCountDays +
            totalCustomerMetric.submittedAwaitingMedicalDurationCountDays +
            totalCustomerMetric.submittedAwaitingPolyResultDurationCountDays +
            totalCustomerMetric.indocAwaitingNomineeDurationCountDays
        ).toFixed(1);

        const usgCount = +(
            totalCustomerMetric.preparationSentToUSGDurationCountDays +
            totalCustomerMetric.submittedSubmittedToUSGDurationCountDays +
            totalCustomerMetric.indocAwaitingUSGDurationCountDays
        ).toFixed(1);
        setProcessOwnerGroups([
            {
                key: UsGovScreeningUserType.NST,
                name: UsGovScreeningUserType.NST,
                startIndex: 0,
                count: 0,
                level: 0,
                isCollapsed: true,
                children: [],
                data: `${nstCount}`,
            },
            {
                key: contractOwner,
                name: contractOwner,
                startIndex: 0,
                count: 0,
                level: 0,
                isCollapsed: true,
                children: [],
                data: `${contractOwnerCount}`,
            },
            {
                key: candidate,
                name: candidate,
                startIndex: 0,
                count: 0,
                level: 0,
                isCollapsed: true,
                children: [],
                data: `${candidateCount}`,
            },
            {
                key: usg,
                name: usg,
                startIndex: 0,
                count: 0,
                level: 0,
                isCollapsed: true,
                children: [],
                data: `${usgCount}`,
            },
        ]);
        setProcessOwnerPieData([
            {
                label: UsGovScreeningUserType.NST,
                percentage: nstCount,
                color: pieChartColors[0],
            },
            {
                label: contractOwner,
                percentage: contractOwnerCount,
                color: pieChartColors[1],
            },
            {
                label: candidate,
                percentage: candidateCount,
                color: pieChartColors[2],
            },
            {
                label: usg,
                percentage: usgCount,
                color: pieChartColors[3],
            },
        ]);
    }

    function buildGroups(customerMetrics: ICustomerMetricsBackend[]): void {
        const count = customerMetrics.length;
        let total = 0;
        const newGroups: IGroup[] = [];
        const totalCustomerMetric: IMetricsDaysCount = {
            nominationDurationCountDays: 0,
            nominationContractOwnerDurationCountDays: 0,
            nominationNomineeDurationCountDays: 0,
            preparationDurationCountDays: 0,
            preparationWithNomineeDurationCountDays: 0,
            preparationDocumentReceivedDurationCountDays: 0,
            preparationSentToUSGDurationCountDays: 0,
            submittedDurationCountDays: 0,
            submittedSubmittedToUSGDurationCountDays: 0,
            submittedReceivedFromUSGDurationCountDays: 0,
            submittedAwaitingPolyResultDurationCountDays: 0,
            submittedAwaitingMedicalDurationCountDays: 0,
            submittedAwaitingAdjudicationDurationCountDays: 0,
            adjudicatedDurationCountDays: 0,
            adjudicatedInterimDurationCountDays: 0,
            adjudicatedFinalDurationCountDays: 0,
            indocDurationCountDays: 0,
            indocAwaitingNomineeDurationCountDays: 0,
            indocAwaitingUSGDurationCountDays: 0,
            indocAwaitingNSTDurationCountDays: 0,
        };
        customerMetrics.forEach((customerMetric) => {
            for (const [, substates] of Object.entries(substatesMetrics)) {
                // main header group like 'Preparation'
                const firstKeyTyped = substates[0] as keyof typeof customerMetric;
                const data = parseInt(`${customerMetric[firstKeyTyped]}`) ?? 0;
                const firstKeyTypedTotalCustomerMetric = substates[0] as keyof typeof totalCustomerMetric;
                totalCustomerMetric[firstKeyTypedTotalCustomerMetric] += data;
                total = total + data;
                // substates groups like 'AwaitingPoly'
                for (let i = 1; i < substates.length; i++) {
                    const substateString = substates[i];
                    const keyTyped = substateString as keyof typeof customerMetric;
                    if (keyTyped) {
                        const value = parseInt(`${customerMetric[keyTyped]}`) ?? 0;
                        const subStateTypedTotalCustomerMetric = substateString as keyof typeof totalCustomerMetric;
                        totalCustomerMetric[subStateTypedTotalCustomerMetric] += value;
                    }
                }
            }
        });

        const pieChartData: PieChartItem[] = [];
        Object.keys(totalCustomerMetric).forEach((key) => {
            const keyTyped = key as keyof typeof totalCustomerMetric;
            totalCustomerMetric[keyTyped] = totalCustomerMetric[keyTyped] / count;
        });
        total = total / count;
        let index = 0;
        for (const [state, substates] of Object.entries(substatesMetrics)) {
            // main header group like 'Preparation'
            const firstKeyTyped = substates[0] as keyof typeof totalCustomerMetric;
            const data = +parseFloat(`${totalCustomerMetric[firstKeyTyped]}`).toFixed(1) ?? 0;
            const group: IGroup = {
                key: state,
                name: state,
                startIndex: 0,
                count: substates.length,
                level: 0,
                isCollapsed: true,
                children: [],
                data,
            };
            // build Status PieChartItems
            const newPieChartItem: PieChartItem = {
                label: state,
                percentage: data,
                color: pieChartColors[index],
            };
            pieChartData.push(newPieChartItem);
            index++;
            // substates groups like 'AwaitingPoly'
            for (let i = 1; i < substates.length; i++) {
                const substateString = substates[i];
                const keyTyped = substateString as keyof typeof totalCustomerMetric;
                const substateNameKey = substateString as keyof typeof metricSubStatesNameMapping;
                const subStateName = metricSubStatesNameMapping[substateNameKey];
                if (keyTyped) {
                    const value = +parseFloat(`${totalCustomerMetric[keyTyped]}`).toFixed(1) ?? 0;
                    group.children?.push({
                        key: keyTyped,
                        name: subStateName,
                        startIndex: 0,
                        count: 0,
                        level: 1,
                        isCollapsed: true,
                        data: value,
                    });
                }
            }
            newGroups.push(group);
        }

        setTotal(+total.toFixed(1));
        setPieData(pieChartData);
        setGroups(newGroups);
        buildProcessOwnerPieChartItems(totalCustomerMetric);
    }

    function getColumns(processOwner?: boolean): IColumn[] {
        const statusStr = 'Status';
        const dayStr = 'Average Days';
        const percentageOfTime = 'Percentage of Time';
        return [
            {
                key: statusStr,
                name: statusStr,
                ariaLabel: statusStr,
                minWidth: 125,
                maxWidth: 125,
                isRowHeader: true,
                isMultiline: true,
                fieldName: statusStr,
                styles: {
                    root: {
                        alignItems: 'flex-start',
                        minWidth: processOwner ? 153 : 283,
                        maxWidth: processOwner ? 153 : 283,
                        marginLeft: processOwner ? 0 : -20,
                    },
                },
            },
            {
                key: dayStr,
                name: dayStr,
                ariaLabel: dayStr,
                minWidth: 75,
                maxWidth: 75,
                isMultiline: true,
                isRowHeader: false,
                styles: {
                    root: { alignItems: 'flex-start', minWidth: 75, maxWidth: 75 },
                },
            },
            {
                key: percentageOfTime,
                name: percentageOfTime,
                ariaLabel: percentageOfTime,
                minWidth: 95,
                maxWidth: 95,
                isMultiline: true,
                isRowHeader: false,
                styles: {
                    root: { alignItems: 'flex-start', minWidth: 95, maxWidth: 95 },
                },
            },
        ];
    }
    async function buildCustomerMetrics(): Promise<void> {
        setErrorOccurred(false);
        setIsFetchingData(true);
        setProcessOwnerGroups(undefined);
        setGroups(undefined);
        let results: ICustomerMetricsBackend[] = [];
        if (props.filteredCandidates.length > 0) {
            const screeningIds: string[] = [];
            const customerMetricDictionary = cacheContext.retrieve<
                Dictionary<ICustomerMetricsBackend>
            >(props.cacheKey);
            props.filteredCandidates.forEach((screening) => {
                if (customerMetricDictionary && customerMetricDictionary[screening.id]) {
                    const customerMetricBackend = customerMetricDictionary[screening.id];
                    results.push(customerMetricBackend);
                } else {
                    screeningIds.push(screening.id);
                }
            });
            if (screeningIds.length > 0) {
                try {
                    const newCustomerMetrics = await UsGovScreeningClient.getSelectCustomerMetrics(
                        props.authContext,
                        screeningIds,
                    );
                    if (newCustomerMetrics.length > 0) {
                        results = results.concat(newCustomerMetrics);
                        const newCustomerMetricsDictionary: Dictionary<ICustomerMetricsBackend> =
                            customerMetricDictionary ?? {};
                        newCustomerMetrics.forEach((customerMetrics) => {
                            newCustomerMetricsDictionary[customerMetrics.id] = customerMetrics;
                        });
                        cacheContext.store<Dictionary<ICustomerMetricsBackend>>(
                            props.cacheKey,
                            newCustomerMetricsDictionary,
                        );
                    }
                    if (newCustomerMetrics.length < screeningIds.length) {
                        console.info(
                            `Grabbed ${newCustomerMetrics.length} Customer Metrics out of ${screeningIds.length}`,
                        );
                    }
                } catch (e) {
                    if (isMounted()) {
                        setErrorOccurred(true);
                        setMessage(`Failed To Grab ${screeningIds.length} Customer Metrics`);
                        console.error('Failed to grab Customer Metrics:', e);
                        console.error('Failed to Grab Customer metrics for:', screeningIds);
                    }
                }
            }
            // i always want this to run as long as we can load something
            // if there is one customer metric not showing due to it is
            // brand new and there is no customer metric for said screening record
            // then i will load the customer metrics for the rest of the data
            if (isMounted() && results.length > 0) {
                buildGroups(results);
            }
        }
        setIsFetchingData(false);
    }

    useEffect(() => {
        if (props.showAdvancedMetrics && props.filteredCandidates.length > 0) {
            buildCustomerMetrics();
        } else if (props.rawCandidateCount === 0) {
            setIsFetchingData(true);
            setProcessOwnerGroups(undefined);
            setGroups(undefined);
        } else if (props.rawCandidateCount > 0 && props.filteredCandidates.length === 0) {
            setErrorOccurred(true);
            setMessage('No Selected Customer Metrics');
            setIsFetchingData(false);
            setProcessOwnerGroups(undefined);
            setGroups(undefined);
        }
    }, [props.showAdvancedMetrics, props.filteredCandidates, props.rawCandidateCount]);

    const groupProps: IGroupRenderProps = {
        showEmptyGroups: true,
        onRenderHeader: renderHeaderRow,
    };

    function renderHeaderRow(
        prop?: IGroupHeaderProps,
        defaultRender?: (props?: IGroupHeaderProps) => JSX.Element | null,
    ): JSX.Element | null {
        {
            if (prop) {
                prop.styles = {
                    expand: isManager(prop) ? expandButtonStyle : noExpandButtonStyle,
                    expandIsCollapsed: isManager(prop) ? expandButtonStyle : noExpandButtonStyle,
                };
            }

            return (
                <>
                    {defaultRender!({
                        ...prop,
                        onRenderTitle: renderDirectReportsTitleRows,
                    })}
                </>
            );
        }
    }

    function isManager(localProps: IGroupHeaderProps): boolean {
        return localProps.group !== undefined && localProps.group.count > 0;
    }

    // adjust the name (header) width based on the space taken by nested grouping indentation
    function getHeaderWidth(level: number | undefined, initialWidth: number): number {
        const calLevel = level !== undefined ? level : 0;
        return calLevel > 0 ? initialWidth - calLevel * tableIndentWidth : initialWidth;
    }

    function renderDirectReportsTitleRows(prop?: IGroupHeaderProps): JSX.Element {
        if (prop?.group?.key) {
            const isProcessOwner = processOwnerLabels.includes(prop.group.key);
            const percentageOfTime = ((prop.group.data / total) * 100).toFixed(1);
            return (
                <Stack horizontal>
                    <Stack.Item
                        styles={{
                            root: {
                                alignSelf: 'center',
                                minWidth: getHeaderWidth(
                                    prop.groupLevel,
                                    isProcessOwner
                                        ? directReportColumnStyles.processOwnerStatus.width
                                        : directReportColumnStyles.status.width,
                                ),
                                maxWidth: getHeaderWidth(
                                    prop.groupLevel,
                                    isProcessOwner
                                        ? directReportColumnStyles.processOwnerStatus.width
                                        : directReportColumnStyles.status.width,
                                ),
                                fontWeight: 'normal',
                                marginLeft: isProcessOwner ? -20 : 0,
                            },
                        }}>
                        {prop.group.name}
                    </Stack.Item>

                    <Stack.Item styles={directReportColumnStyles.clearance.stackStyles}>
                        {prop.group.data}
                    </Stack.Item>
                    <Stack.Item styles={directReportColumnStyles.averagePercentage.stackStyles}>
                        {percentageOfTime}%
                    </Stack.Item>
                </Stack>
            );
        }
        return <></>;
    }

    function closeMessage(): void {
        setErrorOccurred(false);
        setMessage('');
    }

    return (
        <div>
            {hasErrorOccurred && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel='Close'
                    onDismiss={closeMessage}
                    styles={{ root: { marginBottom: 15 } }}>
                    {errorMessage}
                </MessageBar>
            )}
            {!isFetchingData && (
                <div className={customerMetricsStyles.row}>
                    <div className={customerMetricsStyles.innerRow1}>
                        <div>
                            <Label className={customerMetricsStyles.labelHeader}>
                                Screenings by Status
                            </Label>
                        </div>
                        <div className={customerMetricsStyles.averageDaysDiv}>
                            Average Days: {total.toFixed(1)} days
                        </div>
                        <PieChart displayLegend pieChartItems={pieData} legendPosition='right' />
                    </div>
                    <div className={customerMetricsStyles.innerRow2}>
                        <div>
                            <Label className={customerMetricsStyles.labelHeader}>
                                Screenings by Process Owner
                            </Label>
                        </div>
                        <div className={customerMetricsStyles.averageDaysDiv}>
                            Average Days: {total.toFixed(1)} days
                        </div>
                        <PieChart
                            displayLegend
                            pieChartItems={processOwnerPieData}
                            legendPosition='right'
                        />
                    </div>
                </div>
            )}
            <Stack horizontal styles={{ root: { overflowX: 'auto', maxWidth: 890 } }}>
                <Table
                    key={`customer-metrics-table`}
                    collapseButton={collapseButton}
                    rows={[]} // don't need rows when there are groups and groupProps
                    isFetchingData={isFetchingData}
                    tableColumns={getColumns(false)}
                    shimmerLabel='Loading Statuses Metrics...'
                    groups={groups}
                    groupProps={groupProps}
                    groupNestingDepth={2}
                    indentWidth={15}
                    rootStyle={tableRootStyle}
                    detailsHeaderStyles={columnHeaderStyles}
                    compact={true}
                />
                <Table
                    key={`customer-time-with-metrics-table`}
                    collapseButton={processOwnerCollapseButton}
                    rows={[]} // don't need rows when there are groups and groupProps
                    isFetchingData={isFetchingData}
                    tableColumns={getColumns(true)}
                    shimmerLabel='Loading Process Owner Metrics...'
                    groups={processOwnerGroups}
                    groupProps={groupProps}
                    detailsHeaderStyles={columnHeaderStyles}
                    rootStyle={tableRootStyleProcessOwner}
                    compact={true}
                />
            </Stack>
        </div>
    );
}

const tableIndentWidth = 15;
const directReportColumnStyles = {
    status: {
        width: 290,
        styles: {
            root: { alignItems: 'flex-start', minWidth: 290, maxWidth: 290 },
        },
    },
    processOwnerStatus: {
        width: 150,
        styles: {
            root: { alignItems: 'flex-start', minWidth: 150, maxWidth: 150 },
        },
    },
    clearance: {
        width: 75,
        styles: {
            root: { alignItems: 'flex-start', minWidth: 75, maxWidth: 75 },
        },
        stackStyles: {
            root: {
                minWidth: 75,
                maxWidth: 75,
                alignSelf: 'center',
                paddingLeft: 0,
            },
        },
    },
    averagePercentage: {
        width: 130,
        styles: {
            root: { alignItems: 'flex-start', minWidth: 130, maxWidth: 130 },
        },
        stackStyles: {
            root: {
                minWidth: 130,
                maxWidth: 130,
                alignSelf: 'center',
                paddingLeft: 0,
            },
        },
    },
};

const tableRootStyle: IStyle = {
    overflow: 'hidden',
    selectors: {
        '& [role=row]': {
            backgroundColor: 'transparent',
        },
        '& [role=gridcell], & [role=rowheader]': {
            minHeight: '49px',
            display: 'flex',
            alignItems: 'center',
            padding: '0px 0px 0px 0px',
            borderBottom: 'none',
        },
    },
    maxWidth: 480,
};

const tableRootStyleProcessOwner: IStyle = {
    ...tableRootStyle,
    marginLeft: 50,
    maxWidth: 350,
};

// used to make column titles multi-lined
const columnHeaderStyles: Partial<IDetailsHeaderStyles> = {
    root: {
        selectors: {
            '.ms-DetailsHeader-cell': {
                whiteSpace: 'normal',
                textOverflow: 'clip',
                lineHeight: 'normal',
            },
            '.ms-DetailsHeader-cellTitle': {
                height: '100%',
                alignItems: 'center',
            },
        },
    },
};

const processOwnerCollapseButton: ICollapseButton = {
    isVisible: false,
};
