import { DatePicker, DefaultButton, Stack } from '@fluentui/react';
import {
    returnDatePart,
    returnDateTimeForDateRange,
    returnTimePart,
} from 'components/forms/forms-common';
import React, { memo } from 'react';
import { DAY_IN_MILLISECONDS } from 'utils/time-utils';
import { ElementProps } from 'components/forms/element-viewer';
import { DateElementTimePicker } from 'components/forms/element-viewer/date-viewer/time-picker';

const start = 'start';
const end = 'end';

function DateViewer(props: ElementProps): JSX.Element {
    const { element, updateFormResponse } = props;

    const returnRangeLabelFromKey = (key: string): string | undefined =>
        !element.rangeLabels
            ? undefined
            : key === start
            ? element.rangeLabels.start ?? ''
            : element.rangeLabels.end ?? '';

    const onChangeDate = (value: Date | null | undefined): void => {
        const dateString = getUpdatedDateString(value, element.value as string);
        updateFormResponse(element, dateString);
    };

    const createDateString = (value: Date): string => {
        return `${value.getFullYear()}-${(value.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${value.getDate().toString().padStart(2, '0')}`;
    };

    const getUpdatedDateString = (value: Date | null | undefined, str: string): string => {
        if (value) {
            const dateOnly = createDateString(value);
            if (!str) {
                return dateOnly;
            }
            const timeOnly = str.split('T')[1];
            if (!timeOnly) {
                return dateOnly;
            }
            const result = `${dateOnly}T${timeOnly}`;
            return result;
        }
        return '';
    };

    const updateTime = (timeValue: string): void => {
        const dateOnly = element.value ? (element.value as string).split('T')[0] : '';
        const combined = `${dateOnly}T${timeValue}`;
        updateFormResponse(element, combined);
    };

    const updateTimeRange = (timeValue: string, num: number): void => {
        const newValue = [
            element.value ? (element.value[0] as string) : '',
            element.value ? (element.value[1] as string) : '',
        ];
        const dateOnly = newValue[num].split('T')[0];
        const timeString = timeValue !== '' ? `T${timeValue}` : '';
        const combined = `${dateOnly}${timeString}`;
        newValue.splice(num, 1, combined);
        updateFormResponse(element, newValue);
    };

    const onChangeDateRange = (value: Date | null | undefined, num: number): void => {
        let newValue: string[] = [];
        if (!element.value) {
            newValue = ['', ''];
        } else {
            newValue = [(element.value[0] as string) ?? '', (element.value[1] as string) ?? ''];
        }
        const dateString = getUpdatedDateString(value, newValue[num] as string);
        newValue.splice(num, 1, dateString);
        updateFormResponse(element, newValue);
    };

    const resetDates = (): void => {
        updateFormResponse(element, ['', '']);
    };

    let minStartDate = element.isFutureDatesOnly ? new Date() : undefined;
    if (element.type === 'date') {
        return (
            <Stack horizontal wrap tokens={{ childrenGap: '1rem' }}>
                <Stack.Item>
                    <DatePicker
                        ariaLabel='Date'
                        style={{ width: '200px' }}
                        value={returnDatePart(element.value as string)}
                        onSelectDate={(newValue): void => onChangeDate(newValue)}
                        placeholder={'Select a date'}
                        minDate={minStartDate}
                        allowTextInput
                    />
                </Stack.Item>
                {element.hasTime && (
                    <Stack.Item>
                        <DateElementTimePicker
                            onChange={updateTime}
                            value={returnTimePart(element.value as string)}
                        />
                    </Stack.Item>
                )}
            </Stack>
        );
    } else {
        // date range section
        const data = returnDateTimeForDateRange(element);

        const val =
            (element.validatorOptions?.value as string) !== undefined
                ? parseInt(element.validatorOptions?.value as string, 10)
                : undefined;
        const maxDateRange = typeof val === 'number' ? val : undefined;
        if (maxDateRange && data.endDate) {
            const newStartDate = new Date(
                data.endDate.getTime() - DAY_IN_MILLISECONDS * maxDateRange,
            );
            minStartDate =
                minStartDate !== undefined && minStartDate > newStartDate
                    ? minStartDate
                    : newStartDate;
        }
        // get limited date range for End date
        const maxEndDate =
            maxDateRange && data.startDate
                ? new Date(data.startDate.getTime() + DAY_IN_MILLISECONDS * maxDateRange)
                : undefined;
        const minEndDate = data.startDate ?? (element.isFutureDatesOnly ? new Date() : undefined);
        return (
            <>
                <Stack horizontal wrap tokens={{ childrenGap: '1rem' }}>
                    <Stack.Item>
                        <DatePicker
                            style={{ width: '200px' }}
                            value={data.startDate}
                            onSelectDate={(newValue): void => onChangeDateRange(newValue, 0)}
                            placeholder={'Please select a start date'}
                            label={returnRangeLabelFromKey(start)}
                            minDate={minStartDate}
                            maxDate={data.endDate}
                            // below fixes the bug where it will not show an error message when there is infact no error.
                            // if you set the start date then reset -> then set endDate to before the previously
                            // set start date then a error with no text will appear and cause it to turn error
                            textField={{ errorMessage: '' }}
                            allowTextInput
                        />
                    </Stack.Item>
                    {element.hasTime && (
                        <Stack.Item>
                            <DateElementTimePicker
                                onChange={(newValue): void => updateTimeRange(newValue, 0)}
                                value={data.startTime}
                            />
                        </Stack.Item>
                    )}
                    <Stack.Item>
                        <DatePicker
                            style={{ width: '200px' }}
                            value={data.endDate}
                            onSelectDate={(newValue): void => onChangeDateRange(newValue, 1)}
                            placeholder={'Please select an end date'}
                            label={returnRangeLabelFromKey(end)}
                            minDate={minEndDate}
                            maxDate={maxEndDate}
                            // same reason as start date
                            textField={{ errorMessage: '' }}
                            allowTextInput
                        />
                    </Stack.Item>
                    {element.hasTime && (
                        <Stack.Item>
                            <DateElementTimePicker
                                onChange={(newValue): void => updateTimeRange(newValue, 1)}
                                value={data.endTime}
                            />
                        </Stack.Item>
                    )}
                </Stack>
                {!!maxDateRange && (
                    <Stack.Item style={{ margin: '14px 0px' }}>
                        <DefaultButton
                            title='Reset Dates'
                            aria-label='Reset Dates'
                            onClick={(): void => resetDates()}>
                            Reset
                        </DefaultButton>
                    </Stack.Item>
                )}
            </>
        );
    }
}

export default memo(DateViewer);
