import React, { useState, useEffect, useContext } from 'react';
import { mergeStyleSets, MessageBar, MessageBarType } from '@fluentui/react';
import ActivitiesClient, { IActivity } from 'clients/activities-client';
import DocumentsClient, { IDocumentToken, IDocumentRecord } from 'clients/documents-client';
import EligibilityClient, {
    IPersonnelAttributesMap,
    PersonnelAttributeCodes,
} from 'clients/eligibility-client';
import EmployeeClient, {
    IEmployeeEditableResponse,
    IEmployee,
    IBasicEmployee,
    AllEmployeeEditableFields,
} from 'clients/employee-client';
import GraphClient, { IPerson } from 'clients/graph-client';
import PublicTrustDocumentClient from 'clients/screening/public-trust-document-client';
import PublicTrustScreeningClient from 'clients/screening/public-trust-screening-client';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import TemplateClient, {
    IScreeningTemplateRequestRecord,
    IDocumentMetadata,
    ScreeningTemplateRequestedState,
    AssociationValue,
} from 'clients/template-client';
import { AppConstants } from 'components/common/constants';
import AdditionalData from 'components/common/employee/employee-additional-data';
import EmployeeCard from 'components/common/employee/employee-card';
import HrData, { EmployeeHrDataProps } from 'components/common/employee/employee-hr-data';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import Event, { ActivityEventBin, binSortEvents } from 'components/common/event';
import { AuthContext } from 'contexts/auth-context';
import { CacheContext } from 'contexts/cache-context';
import { UserContext } from 'contexts/user-context';
import { useIsMounted } from 'utils/misc-hooks';
import { isGUID } from 'utils/string-utils';
import { dateToLocalLongDateFormat } from 'utils/time-utils';
import {
    ScreeningPaths,
    ScreeningParentStateType,
    ScreeningStateLabels,
    StateName,
    AdjudicatedDecision,
    getParentState,
    GetUSGovStatusString,
    ClearanceLevelType,
} from 'components/screening/common/common-constants';
import EmployeePublicTrustData from 'components/screening/common/employee/employee-public-trust-data';
import ScreeningFieldSet from 'components/screening/common/employee/employee-screening-data';
import { PrehireData } from 'components/screening/common/employee/prehire-data';
import {
    ICommonScreening,
    convertIScreeningToCommonScreening,
    convertIPublicTrustToCommonScreening,
    convertCommonScreeningToIScreening,
    convertCommonScreeningToIPublicTrust,
} from 'components/screening/common/ICommonScreening';
import RequestedDocumentTable from 'components/screening/common/screening-document-feature-flag/requested-documents-table';
import UploadedDocumentsTable from 'components/screening/common/screening-document-feature-flag/uploaded-documents-table';
import { ManageCandidatePageHeaderFlag } from 'components/screening/common/screening-document-feature-flag/manage-candidate-page-header-flag';
import config from 'environments/environment';

interface ScreeningContentProps {
    screeningId: string;
    cacheKey?: string;
    screeningPath: ScreeningPaths;
}

const ScreeningUpdateStatusMap: {
    [key: string]: {
        statusText: string;
        messageBarType: MessageBarType;
        message: string;
    };
} = {
    success: {
        statusText: 'success',
        messageBarType: MessageBarType.success,
        message: 'Successfully updated screening.',
    },
    pending: {
        statusText: 'pending',
        messageBarType: MessageBarType.warning,
        message: 'Screening update pending...',
    },
    error: {
        statusText: 'error',
        messageBarType: MessageBarType.error,
        message: 'Failed to update screening.',
    },
    processOwnerError: {
        statusText: 'processOwnerError',
        messageBarType: MessageBarType.error,
        message: 'Failed to update screening, a process owner must be assigned first.',
    },
    screeningRecordUpdateError: {
        statusText: 'screeningRecordUpdateError',
        messageBarType: MessageBarType.error,
        message: 'Error: Select the Access Required for SCI',
    },
};

export default function ScreeningContentFlag(props: ScreeningContentProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const cacheContext = useContext(CacheContext);
    const [isAuthorized, setIsAuthorized] = useState<boolean>(true);
    const [isValidScreening, setIsValidScreening] = useState<boolean>(true);
    const [genericErrorText, setGenericErrorText] = useState<string>('');
    const [isDataEditable, setIsDataEditable] = useState<boolean>(false);
    const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
    const [isDataSaving, setIsDataSaving] = useState<boolean>(false);
    const [screeningUpdateStatus, setScreeningUpdateStatus] = useState<string>('');

    const [editableEmployee, setEditableEmployee] = useState<
        IEmployeeEditableResponse | undefined
    >();
    const [employee, setEmployee] = useState<IEmployee | undefined>();
    const [manager, setManager] = useState<IBasicEmployee | undefined>();
    const [employeeHrData, setEmployeeHrData] = useState<EmployeeHrDataProps | undefined>();
    const [screening, setScreening] = useState<ICommonScreening | undefined>();
    const [nominator, setNominator] = useState<IBasicEmployee | undefined>();
    const [processOwner, setProcessOwner] = useState<IPerson | undefined>();
    const [screeningProcessOwner, setScreeningProcessOwner] = useState<
        IBasicEmployee | undefined
    >();
    const [processOwnerGroupMembersList, setProcessOwnerGroupMembersList] = useState<
        IPerson[] | undefined
    >();

    const [updateUploadedDocuments, setUpdateUploadedDocuments] = useState<number>(0);

    const [requestedDocuments, setRequestedDocuments] = useState<
        IScreeningTemplateRequestRecord[]
    >();
    const [filteredDocumentRequests, setFilteredDocumentRequests] = useState<
        IScreeningTemplateRequestRecord[]
    >();
    const [documentMetadata, setDocumentMetadata] = useState<IDocumentMetadata[]>();

    const [screeningEventBins, setScreeningEventBins] = useState<ActivityEventBin[]>();
    const [isRefreshActivitiesLocked, setRefreshActivitiesLocked] = useState<boolean>(false);
    const [refreshActivityCallDelayMs, setRefreshActivityCallDelayMs] = useState<number>(0);
    const [userTypes, setUserTypes] = useState<string[]>([]);
    const [showInvalidAgencyChangeMessageBar, setShowInvalidAgencyChangeMessageBar] = useState<
        boolean
    >(false);
    const isMounted = useIsMounted();
    const REFRESH_ACTIVITY_DELAY_TIME_AFTER_INIT_MS = 1000;

    useEffect(() => {
        async function loadPersonnelData(): Promise<void> {
            let results;
            if (screening && screening.personnelId) {
                try {
                    results = await EmployeeClient.getEmployeeByAliasOrId(
                        authContext,
                        screening.personnelId,
                    );
                    setEmployee(results);
                } catch (e) {
                    // TODO add error-message UseMessageBar post mvp
                    console.log('Error retrieving FTE employee or manager information', e);
                }
            }
        }
        loadPersonnelData();
    }, [screening]);

    function updateDocumentInfo(newRequests: IScreeningTemplateRequestRecord[]): void {
        if (requestedDocuments) {
            const requestedDocumentsCopy = requestedDocuments.slice(0);
            const updatedRequests = requestedDocumentsCopy.concat(newRequests);
            setRequestedDocuments(updatedRequests);
            updateFilteredDocumentRequests(updatedRequests);
            updateDocumentMetadata(updatedRequests);
        }
    }

    async function updateDocumentMetadata(
        requestedDocuments: IScreeningTemplateRequestRecord[],
    ): Promise<void> {
        try {
            const documentIds: Set<string> = new Set<string>();
            requestedDocuments.forEach((x) => {
                documentIds.add(x.documentId);
            });
            let metadataResults: IDocumentMetadata[] = [];
            if (props.screeningPath === ScreeningPaths.UsGov) {
                metadataResults = await TemplateClient.getDocumentMetadataFromDocumentIds(
                    authContext,
                    documentIds,
                );
            } else {
                metadataResults = await PublicTrustDocumentClient.getDocumentMetadataFromDocumentIds(
                    authContext,
                    documentIds,
                );
            }
            setDocumentMetadata(metadataResults);
        } catch (e) {
            setGenericErrorText(getGenericErrorText(e));
        }
    }

    async function updateFilteredDocumentRequests(
        requestedDocuments: IScreeningTemplateRequestRecord[],
    ): Promise<void> {
        const filteredDocumentRequests = requestedDocuments.filter(
            (x: IScreeningTemplateRequestRecord) =>
                x.requestedState === ScreeningTemplateRequestedState.RequestedState,
        );
        setFilteredDocumentRequests(filteredDocumentRequests);
    }

    function updateDocumentRequest(request: IScreeningTemplateRequestRecord): void {
        if (requestedDocuments && filteredDocumentRequests) {
            const requestedDocumentsCopy = requestedDocuments.slice(0);
            const requestToUpdateIdx = requestedDocumentsCopy.findIndex((x) => x.id === request.id);
            if (requestToUpdateIdx > -1) {
                requestedDocumentsCopy[requestToUpdateIdx] = request;
                setRequestedDocuments(requestedDocumentsCopy);
                updateFilteredDocumentRequests(requestedDocumentsCopy);
            }
        }
    }

    function removeDocumentRequest(request: IScreeningTemplateRequestRecord): void {
        if (requestedDocuments && filteredDocumentRequests) {
            const requests = requestedDocuments.filter((x) => x.id !== request.id);
            setRequestedDocuments(requests);
            updateFilteredDocumentRequests(requests);

            if (documentMetadata) {
                const metadata = documentMetadata.filter(
                    (x) => x.documentId !== request.documentId,
                );
                setDocumentMetadata(metadata);
            }
        }
    }

    useEffect(() => {
        async function initializeRequestedDocumentInfo(): Promise<void> {
            try {
                let requestedDocuments: IScreeningTemplateRequestRecord[] = [];
                if (props.screeningPath === ScreeningPaths.UsGov) {
                    requestedDocuments = await TemplateClient.getDocumentRequestedForScreeningId(
                        authContext,
                        props.screeningId,
                    );
                } else {
                    requestedDocuments = await PublicTrustDocumentClient.getDocumentRequestedForScreeningId(
                        authContext,
                        props.screeningId,
                    );
                }
                if (isMounted()) {
                    setRequestedDocuments(requestedDocuments);

                    updateFilteredDocumentRequests(requestedDocuments);

                    updateDocumentMetadata(requestedDocuments);
                }
            } catch (e) {
                if (isMounted()) {
                    setGenericErrorText(getGenericErrorText(e));
                }
            }
        }
        initializeRequestedDocumentInfo();
    }, [props.screeningId]);

    useEffect(() => {
        async function populateProcessOwnerPersonaList(): Promise<void> {
            if (config.processOwnerGroupId) {
                const res = await GraphClient.getGroupMembers(
                    authContext,
                    config.processOwnerGroupId,
                );
                const groupMembers: IPerson[] = res.value;
                if (isMounted()) {
                    setProcessOwnerGroupMembersList(groupMembers);
                }
            } else {
                // TODO add warning-message UseMessageBar
                console.warn(
                    'Process owner group id not set in config, skip populating process owner list.',
                );
            }
        }
        populateProcessOwnerPersonaList();
    }, []);

    useEffect(() => {
        async function populateProcessOwner(): Promise<void> {
            try {
                if (screening) {
                    if (
                        !screeningProcessOwner ||
                        screeningProcessOwner.id !== screening.processOwnerId
                    ) {
                        if (screening.processOwnerId) {
                            const employees: IBasicEmployee[] = await EmployeeClient.getBasicEmployeesById(
                                authContext,
                                [screening.processOwnerId],
                            );
                            if (employees.length > 0 && isMounted()) {
                                const employee = employees[0];
                                setScreeningProcessOwner(employee);
                                populateProcessOwnerIfExist(employee.onPremisesSamAccountName);
                            }
                        }
                    } else {
                        if (isMounted()) {
                            populateProcessOwnerIfExist(
                                screeningProcessOwner.onPremisesSamAccountName,
                            );
                        }
                    }
                }
            } catch (e) {
                if (isMounted()) {
                    setGenericErrorText(getGenericErrorText(e));
                }
            }
        }

        populateProcessOwner();
    }, [processOwnerGroupMembersList, screening]);

    async function getDocumentsIfTokenExists(
        documentToken: IDocumentToken,
    ): Promise<IDocumentRecord[]> {
        if (documentToken && documentToken.token) {
            return DocumentsClient.getDocuments(
                authContext,
                props.screeningId,
                documentToken.token,
            );
        }

        return Promise.resolve([]);
    }

    function eventBitWillManuallyUpdate(): void {
        if (!isRefreshActivitiesLocked) {
            setRefreshActivitiesLocked(true);
            setTimeout(() => {
                getEventData();
            }, refreshActivityCallDelayMs);
            setRefreshActivityCallDelayMs(REFRESH_ACTIVITY_DELAY_TIME_AFTER_INIT_MS);
        }
    }

    async function getEventData(): Promise<void> {
        const activitiesPromises: Promise<IActivity[]>[] = [];
        const activitiesScreening = ActivitiesClient.getScreeningActivityToken(
            authContext,
            props.screeningId,
        ).then((token) =>
            activitiesPromises.push(
                ActivitiesClient.getAllScreeningActivitiesForScreeningId(
                    authContext,
                    props.screeningId,
                    token,
                ),
            ),
        );
        try {
            try {
                const activitiesDocuments: Promise<number>[] = [];
                const documentRecords = await DocumentsClient.getDocumentToken(
                    authContext,
                    props.screeningId,
                    props.screeningPath,
                ).then((documentToken) => getDocumentsIfTokenExists(documentToken));
                documentRecords.forEach((docRecord) =>
                    activitiesDocuments.push(
                        ActivitiesClient.getDocumentActivityToken(
                            authContext,
                            docRecord.id,
                        ).then((docToken) =>
                            activitiesPromises.push(
                                ActivitiesClient.getAllDocumentActivitiesForDocumentId(
                                    authContext,
                                    docRecord.id,
                                    docToken,
                                ),
                            ),
                        ),
                    ),
                );

                await Promise.allSettled([...activitiesDocuments]);
            } catch (e) {
                // TODO add error-message UseMessageBar
                console.log('Error getting document records', e);
            }
            await Promise.allSettled([activitiesScreening]);
            await Promise.allSettled(activitiesPromises);

            const events: IActivity[] = [];
            for (let i = 0; i < activitiesPromises.length; i++) {
                try {
                    const res = await activitiesPromises[i];
                    res.forEach((x) => events.push(x));
                } catch (e) {
                    // TODO add error-message UseMessageBar
                    console.log('Error with getting activities from result', e);
                }
            }
            const sortedBins = binSortEvents(events);
            setScreeningEventBins(sortedBins);
        } catch (e) {
            setGenericErrorText(getGenericErrorText(e));
        }

        setRefreshActivitiesLocked(false);
    }

    useEffect(() => {
        eventBitWillManuallyUpdate();
    }, [
        filteredDocumentRequests,
        screening,
        processOwner,
        documentMetadata,
        screeningUpdateStatus,
    ]);

    // get current user that is viewing the web page's user types to determine what they can see
    useEffect(() => {
        const userTypes: string[] = [];
        if (props.screeningPath === ScreeningPaths.PublicTrust) {
            userContext.publicTrustScreeningUserTypes.forEach((x) => userTypes.push(x));
        } else if (props.screeningPath === ScreeningPaths.UsGov) {
            userContext.usGovScreeningUserTypes.forEach((x) => userTypes.push(x));
        }
        setUserTypes(userTypes);
    }, [userContext.usGovScreeningUserTypes, userContext.publicTrustScreeningUserTypes]);

    useEffect(() => {
        async function populateData(): Promise<void> {
            const screening = await getScreeningIfAuthorized();
            if (screening) {
                if (!screening) {
                    return;
                }
                if (isMounted()) {
                    setScreening(screening);
                }
                const nominatedBy = screening.nominatedBy;
                const basicResults = await EmployeeClient.getBasicEmployeesById(authContext, [
                    nominatedBy,
                ]);
                const nominator = basicResults[0];

                if (isMounted()) {
                    setNominator(nominator);
                }

                // TODO once prehire flag is enabled permanently, remove screening.preHire check
                if (
                    props.screeningPath === ScreeningPaths.PublicTrust ||
                    (props.screeningPath === ScreeningPaths.UsGov &&
                        (!screening.preHire ||
                            (config.personnelProfile.prehireFlag &&
                                screening.personnelId &&
                                !isGUID(screening.personnelId))) &&
                        isMounted())
                ) {
                    populateFteEmployeeData(screening.personnelId);
                    // TODO once prehire flag is enabled permanently, remove screening.preHire check
                } else if (
                    props.screeningPath === ScreeningPaths.UsGov &&
                    (screening.preHire ||
                        (config.personnelProfile.prehireFlag &&
                            screening.personnelId &&
                            isGUID(screening.personnelId))) &&
                    isMounted()
                ) {
                    try {
                        // TODO once prehire flag is enabled permanently, if clause and else case entirely
                        if (config.personnelProfile.prehireFlag && isGUID(screening.personnelId)) {
                            populatePrehireData(screening.personnelId);
                        } else {
                            if (screening.preHire) {
                                const basicResults = await EmployeeClient.getBasicEmployeesByAlias(
                                    authContext,
                                    [screening.preHire.manager],
                                );
                                const prehireManager = basicResults[0];
                                if (isMounted()) {
                                    setManager(prehireManager);
                                }
                            }
                        }
                    } catch (e) {
                        if (isMounted()) {
                            setGenericErrorText(getGenericErrorText(e));
                        }
                    }
                }
            }
        }

        async function getScreeningIfAuthorized(): Promise<ICommonScreening | undefined> {
            try {
                let screening;
                if (props.screeningPath === ScreeningPaths.UsGov) {
                    const usScreening = await UsGovScreeningClient.getScreeningById(
                        authContext,
                        props.screeningId,
                    );
                    screening = convertIScreeningToCommonScreening(usScreening);
                } else {
                    const tempScreening = await PublicTrustScreeningClient.getPublicTrustScreeningById(
                        authContext,
                        props.screeningId,
                    );
                    screening = convertIPublicTrustToCommonScreening(tempScreening);
                }
                return screening;
            } catch (e) {
                if (e.status === 403 && isMounted()) {
                    setIsAuthorized(false);
                } else if (e.status === 404 && isMounted()) {
                    setIsValidScreening(false);
                } else {
                    if (isMounted()) {
                        setGenericErrorText(getGenericErrorText(e));
                    }
                }
            }
        }

        async function populatePrehireData(personnelId: string): Promise<void> {
            let asyncEditableEmployee: IEmployeeEditableResponse | undefined;
            let asyncManager: IBasicEmployee | undefined;

            try {
                if (personnelId) {
                    asyncEditableEmployee = await EmployeeClient.getEditableEmployeeDataByIdOrAliasOrGUID(
                        authContext,
                        personnelId,
                        Object.values(AllEmployeeEditableFields),
                    );
                    if (isMounted()) {
                        setEditableEmployee(asyncEditableEmployee);
                    }
                }
            } catch (e) {
                if (isMounted()) {
                    setGenericErrorText('Error retrieving editable employee information');
                }
                return;
            }

            try {
                const basicResults = await EmployeeClient.getBasicEmployeesByAlias(authContext, [
                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                    asyncEditableEmployee?.employeeEditableInfo?.reportsToEmailName!,
                ]);
                asyncManager = basicResults[0];
                if (isMounted()) {
                    setManager(asyncManager);
                }
            } catch (e) {
                if (isMounted()) {
                    setGenericErrorText(
                        'Error retrieving manager information for editable employee.',
                    );
                }
            }
        }

        async function populateFteEmployeeData(personnelId: string): Promise<void> {
            let asyncEmployee: IEmployee;
            let asyncManager: IBasicEmployee | undefined;
            let personnelAttributes: IPersonnelAttributesMap | undefined;

            try {
                asyncEmployee = await EmployeeClient.getEmployeeByAliasOrId(
                    authContext,
                    personnelId,
                );
                if (isMounted()) {
                    setEmployee(asyncEmployee);
                }
            } catch (e) {
                if (isMounted()) {
                    setGenericErrorText('Error retrieving employee information');
                }
                return;
            }

            try {
                const basicResults = await EmployeeClient.getBasicEmployeesByAlias(authContext, [
                    asyncEmployee.reportsToEmailName,
                ]);
                asyncManager = basicResults[0];
                if (isMounted()) {
                    setManager(asyncManager);
                }
            } catch (e) {
                if (isMounted()) {
                    setGenericErrorText('Error retrieving manager information.');
                }
            }

            try {
                personnelAttributes = await EligibilityClient.getPersonnelAttributesMap(
                    authContext,
                    personnelId,
                );
            } catch (e) {
                if (isMounted()) {
                    setGenericErrorText('Error retrieving personnel Attribute information.');
                }
                // TODO add error-message UseMessageBar
                console.log(getGenericErrorText(e));
            }

            const employeeHrDataObject: EmployeeHrDataProps = {
                employee: asyncEmployee,
                manager: getDisplayNameOrDefault(asyncManager, ''),
                managerAlias: asyncManager?.onPremisesSamAccountName ?? '',
                usCitizenship: !!(
                    personnelAttributes &&
                    personnelAttributes[PersonnelAttributeCodes.USCitizenship]
                ),
                cloudScreen: !!(
                    personnelAttributes && personnelAttributes[PersonnelAttributeCodes.CloudScreen]
                ),
                cjis: !!(personnelAttributes && personnelAttributes[PersonnelAttributeCodes.CJIS]),
            };

            if (isMounted()) {
                setEmployeeHrData(employeeHrDataObject);
            }
        }

        if (
            userContext.isPublicTrustUserTypesLoaded &&
            userContext.isUsGovScreeningUserTypesLoaded
        ) {
            populateData();
        }
    }, [userContext.isPublicTrustUserTypesLoaded, userContext.isUsGovScreeningUserTypesLoaded]);

    function isDocumentRequestTitleUnique(requestTitle: string): boolean {
        if (documentMetadata) {
            return documentMetadata.filter((x) => x.title === requestTitle).length === 0;
        } else if (documentMetadata === undefined) {
            return true;
        }
        return false;
    }

    function onEditableFieldChange(screen: ICommonScreening): void {
        setIsDataChanged(true);
        setScreening(screen);
    }

    function onEditableEmployeeFieldChange(editableEmployee: IEmployeeEditableResponse): void {
        setIsDataChanged(true);
        setEditableEmployee(editableEmployee);
    }

    async function onIsDataEditableChange(): Promise<void> {
        if (isDataEditable && isDataChanged && screening) {
            setIsDataSaving(true);
            const hasUpdatedResult = await updateScreening(screening);
            setIsDataSaving(false);
            if (!hasUpdatedResult) {
                return;
            }
        }
        setIsDataChanged(false);
        setIsDataEditable(!isDataEditable);
    }

    async function onWithdrawCandidate(updatedScreen: ICommonScreening): Promise<void> {
        if (updatedScreen) {
            updatedScreen.withdrawalUtcMillis = updatedScreen.withdrawalReason ? Date.now() : 0;
            setScreening(updatedScreen);
            await updateScreening(updatedScreen);
        }
    }

    async function onScreeningStatusChange(
        parentState: string,
        childState?: string,
        adjudicatedDateMilliseconds?: number,
    ): Promise<void> {
        if (screening) {
            if (parentState === ScreeningParentStateType.Adjudicated) {
                switch (childState) {
                    case ScreeningStateLabels.Interim:
                        screening.stateName = StateName.AdjudicatedInterim;
                        screening.adjudicatedResult = AdjudicatedDecision.Interim;
                        break;
                    case ScreeningStateLabels.Denied:
                        screening.stateName = StateName.AdjudicatedDenied;
                        screening.adjudicatedResult = AdjudicatedDecision.Denied;
                        break;
                    case ScreeningStateLabels.Final:
                        screening.stateName = StateName.AdjudicatedFinal;
                        screening.adjudicatedResult = AdjudicatedDecision.Final;
                    default:
                        console.error(
                            `Error, no such child state ${childState} for adjudicated decision`,
                        );
                }
                if (adjudicatedDateMilliseconds !== undefined) {
                    screening.adjudicatedUtcMillis = adjudicatedDateMilliseconds;
                }
                await updateScreening(screening);
                onEditableFieldChange(screening);
            } else {
                screening.stateName = (childState ?? parentState) as StateName;
            }
            if (props.screeningPath === ScreeningPaths.UsGov) {
                await updateScreeningState(screening.id, screening.stateName);
            } else {
                await updatePublicTrustState(screening.id, screening.stateName);
            }
        }
    }

    async function onProcessOwnerChange(employee: IBasicEmployee | undefined): Promise<void> {
        if (screening) {
            const processOwnerId = employee ? employee.id : undefined;
            if (await updateProcessOwner(screening.id, processOwnerId)) {
                if (employee) {
                    screening.processOwnerId = employee.id;
                    populateProcessOwnerIfExist(employee.onPremisesSamAccountName);
                } else {
                    screening.processOwnerId = '';
                    populateProcessOwnerIfExist(undefined);
                }
                setScreening(screening);
                setScreeningProcessOwner(employee);
            } else {
                // on process owner change fail
                screening.processOwnerId = '';
                setGenericErrorText('Error changing process owner.');
            }
        }
    }

    function populateProcessOwnerIfExist(processOwnerAlias: string | undefined): void {
        if (screening) {
            if (processOwnerAlias && processOwnerGroupMembersList) {
                const processOwner: IPerson | undefined = findProcessOwnerPersonFromList(
                    processOwnerAlias,
                    processOwnerGroupMembersList,
                );
                setProcessOwner(processOwner);
            } else {
                setProcessOwner(undefined);
            }
        }
    }

    function findProcessOwnerPersonFromList(
        processOwnerAlias: string,
        list: IPerson[],
    ): IPerson | undefined {
        if (processOwnerAlias && list) {
            processOwnerAlias = processOwnerAlias.toLowerCase();
            return list.find(
                (person) =>
                    person.userPrincipalName?.split('@')[0]?.toLowerCase() === processOwnerAlias,
            );
        }
    }

    async function updateScreeningState(id: string, stateName: StateName): Promise<boolean> {
        try {
            setScreeningUpdateStatus(ScreeningUpdateStatusMap.pending.statusText);
            const convertedParentState = getParentState(stateName);
            const convertedSubState = GetUSGovStatusString(stateName);
            const updatedScreeningRecord = await UsGovScreeningClient.updateScreeningState(
                authContext,
                id,
                convertedParentState,
                convertedSubState,
            );
            setScreeningUpdateStatus(ScreeningUpdateStatusMap.success.statusText);
            updateCachedICommonScreening(
                convertIScreeningToCommonScreening(updatedScreeningRecord),
            );
            return true;
        } catch (e) {
            setScreeningUpdateStatus(ScreeningUpdateStatusMap.error.statusText);
        }
        return false;
    }

    function updateStatus(status: string): void {
        const statusText = ScreeningUpdateStatusMap[status].statusText ?? '';
        if (statusText) {
            setScreeningUpdateStatus(statusText);
        }
    }

    async function updatePublicTrustState(id: string, state: StateName): Promise<boolean> {
        try {
            setScreeningUpdateStatus(ScreeningUpdateStatusMap.pending.statusText);
            const updatedScreeningRecord = await PublicTrustScreeningClient.updateScreeningState(
                authContext,
                id,
                state,
            );
            setScreeningUpdateStatus(ScreeningUpdateStatusMap.success.statusText);
            const convertedCommonScreening = convertIPublicTrustToCommonScreening(
                updatedScreeningRecord,
            );
            updateCachedICommonScreening(convertedCommonScreening);
            return true;
        } catch (e) {
            setScreeningUpdateStatus(ScreeningUpdateStatusMap.error.statusText);
        }
        return false;
    }

    async function updateProcessOwner(
        id: string,
        processOwnerId: string | undefined,
    ): Promise<boolean> {
        try {
            setScreeningUpdateStatus(ScreeningUpdateStatusMap.pending.statusText);
            let convertedCommonScreening: ICommonScreening;
            if (props.screeningPath === ScreeningPaths.UsGov) {
                const updatedScreeningRecord = await UsGovScreeningClient.updateProcessOwner(
                    authContext,
                    id,
                    processOwnerId,
                );
                convertedCommonScreening = convertIScreeningToCommonScreening(
                    updatedScreeningRecord,
                );
            } else {
                const updatedPublicTrust = await PublicTrustScreeningClient.updateProcessOwner(
                    authContext,
                    id,
                    processOwnerId,
                );
                convertedCommonScreening = convertIPublicTrustToCommonScreening(updatedPublicTrust);
            }
            setScreeningUpdateStatus(ScreeningUpdateStatusMap.success.statusText);
            updateCachedICommonScreening(convertedCommonScreening);
            return true;
        } catch (e) {
            setScreeningUpdateStatus(ScreeningUpdateStatusMap.error.statusText);
        }
        return false;
    }

    async function updateScreening(screening: ICommonScreening): Promise<boolean> {
        if (
            props.screeningPath === ScreeningPaths.UsGov &&
            screening.clearance === ClearanceLevelType.SCI &&
            screening.access?.length === 0
        ) {
            setScreeningUpdateStatus(
                ScreeningUpdateStatusMap.screeningRecordUpdateError.statusText,
            );
            return false;
        }

        try {
            setScreeningUpdateStatus(ScreeningUpdateStatusMap.pending.statusText);
            let updatedScreeningRecord: ICommonScreening;
            if (props.screeningPath === ScreeningPaths.UsGov) {
                const updatedIScreeningRecord = await UsGovScreeningClient.updateScreening(
                    authContext,
                    convertCommonScreeningToIScreening(screening),
                );
                updatedScreeningRecord = convertIScreeningToCommonScreening(
                    updatedIScreeningRecord,
                );
            } else {
                const publicScreening = await PublicTrustScreeningClient.updateScreening(
                    authContext,
                    convertCommonScreeningToIPublicTrust(screening),
                );
                updatedScreeningRecord = convertIPublicTrustToCommonScreening(publicScreening);
            }
            setScreeningUpdateStatus(ScreeningUpdateStatusMap.success.statusText);
            updateCachedICommonScreening(updatedScreeningRecord);
            return true;
        } catch (e) {
            setScreeningUpdateStatus(ScreeningUpdateStatusMap.error.statusText);
        }
        return false;
    }

    function updateUploadedDocumentsPanel(): void {
        setUpdateUploadedDocuments(updateUploadedDocuments + 1);
    }

    // function updateCachedScreeningData(updatedScreeningRecord: IScreening): void {
    //     if (props.cacheKey && updatedScreeningRecord) {
    //         const cachedScreeningArray = cacheContext.retrieve<IScreening[]>(props.cacheKey);
    //         if (cachedScreeningArray) {
    //             const updatedCachedScreeningRecords = cachedScreeningArray.map((screeningRecord) =>
    //                 screeningRecord.id === updatedScreeningRecord.id
    //                     ? {
    //                           ...updatedScreeningRecord,
    //                           // when screening records are first pulled into the local cache these two fields are dynamically populated:
    //                           // screeningState is calculated to be used for filtering, this needs to be updated in the cache
    //                           // screeningView is added to link to the proper screening record on the table display, perserving this data
    //                           'screeningState': updatedScreeningRecord.parentState,
    //                           'screeningView': screeningRecord.screeningView,
    //                       }
    //                     : screeningRecord,
    //             );
    //             cacheContext.store<IScreening[]>(props.cacheKey, updatedCachedScreeningRecords);
    //         }
    //     }
    // }

    function updateCachedICommonScreening(updatedScreeningRecord: ICommonScreening): void {
        if (props.cacheKey && updatedScreeningRecord) {
            const cachedScreeningArray = cacheContext.retrieve<ICommonScreening[]>(props.cacheKey);
            if (cachedScreeningArray) {
                const updatedCachedScreeningRecords = cachedScreeningArray.map((screeningRecord) =>
                    screeningRecord.id === updatedScreeningRecord.id
                        ? updatedScreeningRecord
                        : screeningRecord,
                );
                cacheContext.store<ICommonScreening[]>(
                    props.cacheKey,
                    updatedCachedScreeningRecords,
                );
            }
        }
    }

    // TODO once prehire flag is enabled permanently, remove screening.preHire checks below
    // if (isAuthorized && screening) {
    if (screening) {
        return (
            <div className={styles.main}>
                <div className={styles.header}>
                    <div>
                        {props.screeningPath === ScreeningPaths.PublicTrust &&
                            showInvalidAgencyChangeMessageBar && (
                                <MessageBar messageBarType={MessageBarType.error}>
                                    The selected sub status does not apply to this Public Trust
                                    Agency - select a new sub status.
                                </MessageBar>
                            )}
                    </div>
                    <div>
                        {((props.screeningPath === ScreeningPaths.UsGov &&
                            screening.preHire &&
                            !screening.personnelId) ||
                            (config.personnelProfile.prehireFlag &&
                                screening.personnelId &&
                                isGUID(screening.personnelId))) && (
                            <>
                                <MessageBar messageBarType={MessageBarType.info}>
                                    External emails to pre-hire candidates are suppressed in
                                    Personnel and requires external communication.
                                </MessageBar>
                                <br />
                            </>
                        )}
                        {screeningUpdateStatus && (
                            <MessageBar
                                messageBarType={
                                    ScreeningUpdateStatusMap[screeningUpdateStatus].messageBarType
                                }
                                overflowButtonAriaLabel='See more'
                                onDismiss={(): void => setScreeningUpdateStatus('')}>
                                {ScreeningUpdateStatusMap[screeningUpdateStatus].message}
                            </MessageBar>
                        )}
                    </div>
                    <ManageCandidatePageHeaderFlag
                        screeningId={props.screeningId}
                        screening={screening}
                        screeningPath={props.screeningPath}
                        employee={employee}
                        isDataEditable={isDataEditable}
                        isDataSaving={isDataSaving}
                        onIsDataEditableChange={onIsDataEditableChange}
                        onScreeningStatusChange={onScreeningStatusChange}
                        onProcessOwnerChange={onProcessOwnerChange}
                        processOwner={processOwner}
                        processOwnerGroupMembers={processOwnerGroupMembersList}
                        setScreeningUpdateStatus={(status: string): void =>
                            setScreeningUpdateStatus(status)
                        }
                        isCurrentUser={userContext.employeeRecord.id === employee?.id}
                        userTypes={userTypes}
                        updateDocumentInfo={updateDocumentInfo}
                        isDocumentRequestTitleUnique={isDocumentRequestTitleUnique}
                        onWithdrawCandidate={onWithdrawCandidate}
                    />
                </div>
                <div className={styles.content}>
                    <div className={styles.leftContent}>
                        {(!screening.preHire ||
                            (config.personnelProfile.prehireFlag &&
                                screening.id &&
                                !isGUID(screening.personnelId))) &&
                            employee && (
                                <div>
                                    <div className={styles.card}>
                                        <EmployeeCard
                                            employee={employee}
                                            manager={getDisplayNameOrDefault(manager, '')}
                                            managerAlias={`${
                                                manager && manager.onPremisesSamAccountName
                                                    ? manager.onPremisesSamAccountName
                                                    : ''
                                            }`}
                                        />
                                    </div>
                                    <div className={styles.container}>
                                        {employeeHrData && <HrData {...employeeHrData} />}
                                    </div>
                                </div>
                            )}
                        {(!config.personnelProfile.prehireFlag || !screening.personnelId) &&
                            props.screeningPath === ScreeningPaths.UsGov &&
                            screening.preHire && (
                                <div>
                                    <div className={styles.card}>
                                        <EmployeeCard
                                            employee={{
                                                displayName: [
                                                    screening.preHire.firstName,
                                                    screening.preHire.middleName,
                                                    screening.preHire.lastName,
                                                ].join(' '),
                                                jobTitle: screening.preHire.jobTitle,
                                            }}
                                            key={`employee_card_${screening.preHire.firstName}_${screening.preHire.lastName}`}
                                            manager={screening.preHire.manager}
                                            managerAlias={''}
                                        />
                                    </div>
                                    <div className={styles.container}>
                                        <PrehireData
                                            screening={screening}
                                            managerName={manager?.displayName ?? ''}
                                            isDataEditable={isDataEditable}
                                            onEditableFieldChange={onEditableFieldChange}
                                            onEditableEmployeeFieldChange={
                                                onEditableEmployeeFieldChange
                                            }
                                            screeningPath={props.screeningPath}
                                        />
                                    </div>
                                </div>
                            )}
                        {config.personnelProfile.prehireFlag && isGUID(screening.personnelId) && (
                            <div>
                                <div className={styles.card}>
                                    <EmployeeCard
                                        employee={{
                                            displayName: getDisplayNameOrDefault(
                                                {
                                                    firstName:
                                                        editableEmployee?.employeeEditableInfo
                                                            ?.firstName,
                                                    lastName:
                                                        editableEmployee?.employeeEditableInfo
                                                            ?.lastName,
                                                },
                                                editableEmployee?.id,
                                            ),
                                            jobTitle:
                                                editableEmployee?.employeeEditableInfo
                                                    ?.standardTitle,
                                            id: editableEmployee?.id,
                                        }}
                                        pcn={
                                            editableEmployee?.employeeEditableInfo
                                                ?.positionNumber &&
                                            editableEmployee?.employeeEditableInfo?.positionNumber >
                                                0
                                                ? `${editableEmployee?.employeeEditableInfo.positionNumber}`
                                                : undefined
                                        }
                                        key={`employee_card_${editableEmployee?.employeeEditableInfo?.firstName}_${editableEmployee?.employeeEditableInfo?.lastName}`}
                                        manager={
                                            editableEmployee?.employeeEditableInfo
                                                ?.reportsToEmailName ?? ''
                                        }
                                        managerAlias={''}
                                    />
                                </div>
                                <div className={styles.container}>
                                    <PrehireData
                                        screening={screening}
                                        editableEmployee={editableEmployee}
                                        managerName={manager?.displayName ?? ''}
                                        isDataEditable={isDataEditable}
                                        onEditableFieldChange={onEditableFieldChange}
                                        onEditableEmployeeFieldChange={
                                            onEditableEmployeeFieldChange
                                        }
                                        screeningPath={props.screeningPath}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={styles.middleContent}>
                        {props.screeningPath === ScreeningPaths.UsGov && (
                            <div className={styles.container}>
                                {/* top block of editable fields in US Gov */}
                                <ScreeningFieldSet
                                    screening={screening}
                                    processOwner={processOwner}
                                    isDataEditable={isDataEditable}
                                    onEditableFieldChange={onEditableFieldChange}
                                />
                            </div>
                        )}
                        {props.screeningPath === ScreeningPaths.PublicTrust && (
                            <div className={styles.container}>
                                {/* top block of editable fields in US Gov */}
                                <EmployeePublicTrustData
                                    screening={screening}
                                    processOwner={processOwner}
                                    isDataEditable={isDataEditable}
                                    onEditableFieldChange={onEditableFieldChange}
                                    setShowInvalidAgencyChangeMessageBar={
                                        setShowInvalidAgencyChangeMessageBar
                                    }
                                />
                            </div>
                        )}

                        <div className={styles.container}>
                            <RequestedDocumentTable
                                screeningId={props.screeningId}
                                updateUploadedDocumentsPanel={updateUploadedDocumentsPanel}
                                requestedDocuments={filteredDocumentRequests}
                                documentMetadata={documentMetadata}
                                updateDocumentRequest={updateDocumentRequest}
                                removeDocumentRequest={removeDocumentRequest}
                                userTypes={userTypes}
                                nomineeId={screening.personnelId}
                                screeningPath={props.screeningPath}
                            />
                        </div>
                        <div className={styles.container}>
                            {employee && (
                                <UploadedDocumentsTable
                                    screeningPath={props.screeningPath}
                                    screeningId={props.screeningId}
                                    notifyUpdate={updateUploadedDocuments}
                                    causeUpdate={updateUploadedDocumentsPanel}
                                    userTypes={userTypes}
                                    isUserCandidate={employee.id === userContext.employeeRecord.id}
                                    requestedDocuments={requestedDocuments}
                                    documentMetadata={documentMetadata}
                                    onUpdateRequest={updateDocumentRequest}
                                    screeningEventBins={screeningEventBins ?? []}
                                    employee={employee}
                                    editableEmployee={editableEmployee}
                                    eventBitWillManuallyUpdate={eventBitWillManuallyUpdate}
                                    associationValue={
                                        props.screeningPath === ScreeningPaths.UsGov
                                            ? AssociationValue.UsGov
                                            : AssociationValue.PublicTrust
                                    }
                                    updateUploadedDocumentsPanel={updateUploadedDocumentsPanel}
                                />
                            )}
                        </div>
                        <div className={styles.container}>
                            <AdditionalData
                                screeningPath={props.screeningPath}
                                screening={screening}
                                employee={employee}
                                manager={manager}
                                nominator={nominator}
                                isDataEditable={isDataEditable}
                                updateStatus={updateStatus}
                                onEditableFieldChange={onEditableFieldChange}
                            />
                        </div>
                    </div>
                    <div className={styles.rightContent}>
                        {screeningEventBins &&
                            screeningEventBins.map(
                                (obj: ActivityEventBin): JSX.Element => {
                                    return (
                                        <div key={`activities_${obj.key}`}>
                                            <div
                                                key={`eventDay_${obj.key}`}
                                                className={styles.eventDateRow}>
                                                <div className={styles.eventLine}></div>
                                                <div className={styles.eventRowGap}></div>
                                                <div>{dateToLocalLongDateFormat(obj.key)}</div>
                                            </div>
                                            {obj.activities.map(
                                                (activity: IActivity): JSX.Element => (
                                                    <Event
                                                        screeningPath={props.screeningPath}
                                                        key={`event_${activity.id}`}
                                                        activity={activity}
                                                        provideDocumentsNameForDocumentAppActivities={
                                                            true
                                                        }
                                                        screeningId={props.screeningId}
                                                    />
                                                ),
                                            )}
                                        </div>
                                    );
                                },
                            )}
                    </div>
                </div>
            </div>
        );
    } else if (!isAuthorized) {
        return (
            <MessageBar messageBarType={MessageBarType.error} overflowButtonAriaLabel='See more'>
                You are not authorized to view this screening.
            </MessageBar>
        );
    } else if (!isValidScreening) {
        return (
            <MessageBar messageBarType={MessageBarType.error} overflowButtonAriaLabel='See more'>
                {`The screening with id ${props.screeningId} could not be found.`}
            </MessageBar>
        );
    } else if (genericErrorText) {
        return (
            <MessageBar messageBarType={MessageBarType.error} overflowButtonAriaLabel='See more'>
                {genericErrorText}
            </MessageBar>
        );
    } else {
        return <span className={styles.loading}>Loading...</span>;
    }
}

function getGenericErrorText(e: Response): string {
    return `Error: ${e.status} - ${e.statusText}`;
}

const styles = mergeStyleSets({
    main: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: AppConstants.padding,
        backgroundColor: 'transparent',
        boxSizing: 'border-box',
    },
    header: {
        marginBottom: AppConstants.margin,
    },
    content: {
        display: 'grid',
        gridTemplateColumns: 'minmax(350px, .75fr) minmax(350px, 2fr) minmax(350px, .75fr)',
        gridGap: AppConstants.margin,
    },
    card: {
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
        marginBottom: AppConstants.margin,
    },
    container: {
        marginBottom: AppConstants.margin,
    },
    loading: {
        margin: '50%',
        padding: '1rem',
        border: '1px solid #f3f3f3',
    },
    eventLine: {
        height: '1px',
        marginTop: '10px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        flexGrow: 1,
    },
    eventDateRow: {
        marginBottom: '10px',
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'row',
    },
    eventRowGap: {
        width: '10px',
    },
    rightContent: {},
    middleContent: {},
    leftContent: {},
    event: {
        marginBottom: '1rem',
    },
});
