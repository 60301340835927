import React, { useState, useContext, useEffect, FormEvent } from 'react';
import {
    mergeStyleSets,
    PrimaryButton,
    FontWeights,
    FontSizes,
    DefaultButton,
    Dropdown,
    IDropdownOption,
    Spinner,
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { IPerson } from 'clients/graph-client';
import { AuthContext } from 'contexts/auth-context';
import { transformPersonToDropdownOption } from 'utils/internal-persona-utils';
import EmployeeClient, { IBasicEmployee, IEmployee } from 'clients/employee-client';

export interface ChangeProcessOwnerModalProps {
    groupMembers?: IPerson[];
    processOwner?: IPerson;
    onClose: () => void;
    onSubmit: (processOwner: IBasicEmployee | undefined) => void;
}

export default function ChangeProcessOwnerModal(props: ChangeProcessOwnerModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [processOwnerList, setProcessOwnerList] = useState<IDropdownOption[]>([
        {
            key: 'unassigned_key',
            id: 'unassigned_id',
            data: undefined,
            text: 'Unassign Process Owner',
            itemType: 0,
        },
        {
            key: 'divide',
            id: 'divide_id',
            text: '----- CELA -----',
            itemType: 2,
        },
    ]);
    const [selectedProcessOwner, setSelectedProcessOwner] = useState<IDropdownOption>();
    const keyPrefix = 'change-process-owner_';

    useEffect(() => {
        populateProcessOwnerPersonaList();
    }, [props.groupMembers]);

    useEffect(() => {
        populatePreselectedProcessOwnerIfExist();
    }, [props.processOwner]);

    function populateProcessOwnerPersonaList(): void {
        if (props.groupMembers) {
            const processOwnerOptions: IDropdownOption[] = props.groupMembers
                .sort((a: IPerson, b: IPerson): number =>
                    (a.surname + a.givenName).localeCompare(b.surname + b.givenName),
                )
                .map(
                    (member: IPerson): IDropdownOption =>
                        transformPersonToDropdownOption(member, keyPrefix),
                );
            setProcessOwnerList([...processOwnerList, ...processOwnerOptions]);
        }
    }

    function populatePreselectedProcessOwnerIfExist(): void {
        if (props.processOwner) {
            const selectedProcessOwnerPerson = transformPersonToDropdownOption(
                props.processOwner,
                keyPrefix,
            );
            setSelectedProcessOwner(selectedProcessOwnerPerson);
        }
    }

    async function onSubmitProcessOwnerChange(): Promise<void> {
        if (selectedProcessOwner && props.processOwner !== selectedProcessOwner.data) {
            if (selectedProcessOwner.data) {
                const alias = selectedProcessOwner.data?.userPrincipalName.split('@')[0];
                if (alias) {
                    try {
                        const processOwners: IBasicEmployee[] = await EmployeeClient.getBasicEmployeesByAlias(
                            authContext,
                            [alias],
                        );
                        if (processOwners.length > 0) {
                            props.onSubmit(processOwners[0]);
                        }
                    } catch (ex) {
                        console.log('Error getting alias [', alias, '] ', ex);
                    }
                } else {
                    console.log('Could not find the alias for ', selectedProcessOwner.data);
                }
            } else {
                props.onSubmit(undefined);
            }
        } else {
            props.onClose();
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <span>Change Process Owner</span>
            </div>

            <div className={styles.body}>
                <div className={styles.mainPanel}>
                    {processOwnerList ? (
                        <Dropdown
                            label='Search NST'
                            required
                            selectedKey={selectedProcessOwner?.key}
                            options={processOwnerList}
                            title={selectedProcessOwner?.title}
                            onChange={(
                                event: FormEvent<HTMLDivElement>,
                                option?: IDropdownOption | undefined,
                            ): void => {
                                setSelectedProcessOwner(option);
                            }}
                        />
                    ) : (
                        <Spinner label='Loading...' />
                    )}
                </div>
            </div>

            <div className={styles.footer}>
                <DefaultButton
                    className={styles.footerBtn}
                    onClick={props.onClose}
                    text='Close'
                    allowDisabledFocus
                />
                <PrimaryButton
                    className={styles.footerBtn}
                    onClick={onSubmitProcessOwnerChange}
                    text='Submit'
                    allowDisabledFocus
                />
            </div>
        </div>
    );
}

const styles = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 400,
        maxWidth: 770,
    },
    header: {
        flex: '1 1 auto',
        borderTop: `4px solid ${SharedColors.cyanBlue10}`,
        display: 'flex',
        fontSize: FontSizes.xLargePlus,
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '25px 30px 10px 30px',
        boxSizing: 'border-box',
    },
    body: {
        minWidth: 300,
        padding: '0 30px 30px 30px',
        display: 'flex',
        flexDirection: 'row',
    },
    mainPanel: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: 'rgb(253, 253, 253)',
        padding: '14px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    footerBtn: {
        marginRight: '10px',
    },
});
