import React, { useState, useEffect, useContext } from 'react';
import CheckRole from 'components/common/check-role';
import { AccessDeniedURL } from 'assets/constants/global-constants';
import { IAttribute } from 'clients/eligibility-client';
import { AuthContext } from 'contexts/auth-context';
import { Role } from 'configs/roles';
import ButtonBar from 'components/common/button-bar';
import { getAttributesTableColumns } from 'components/eligibilities/attributes/table-columns-attributes';
import { useSortColumnHandler, strCmp, numCmp } from 'utils/sort-utils';
import { ProblemLoadingData } from 'components/common/problem-loading/problem-loading-data';
import { Table } from 'components/common/table';
import moment from 'moment';
import AddAttributeModalButton from 'components/eligibilities/attributes/add-attribute-modal-button';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { ModalMode } from 'components/eligibilities/eligibilities-constants';
import { useAttributes } from 'components/eligibilities/eligibilities-utils';
import Spacer from 'components/common/spacer';

export default function Attributes(): JSX.Element {
    const authContext = useContext(AuthContext);
    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler('Code', 1);
    const {
        attributes,
        hasProblemLoadingPage,
        isFetchingAttributes: isLoading,
        setAttributes,
    } = useAttributes(authContext);

    const [tableRows, setTableRows] = useState<IAttribute[]>([]);

    const onAddEditAttributeConcluded = (
        mode: ModalMode,
        modalConclusion: ModalConclusion,
        result?: IAttribute,
    ): void => {
        switch (modalConclusion) {
            case ModalConclusion.Done:
                let attributesVar: IAttribute[];
                switch (mode) {
                    case ModalMode.Add:
                        attributesVar = [...attributes];
                        attributesVar.push(result as IAttribute);
                        setAttributes(attributesVar);
                        break;
                    case ModalMode.Update:
                        attributesVar = [...attributes];
                        const index = attributesVar.findIndex(
                            (attribute) => attribute.id === result?.id,
                        );
                        if (index > -1) {
                            attributesVar.splice(index, 1, result as IAttribute);
                            setAttributes(attributesVar);
                        }
                        break;
                    default:
                        console.error(`Invalid mode "${mode}".`);
                        break;
                }
                break;
            case ModalConclusion.Cancel:
            default:
                break;
        }
    };

    const sortAttributes = (attributes: IAttribute[]): IAttribute[] => {
        type A = IAttribute;

        const chooseSortCmp = (
            sortColumn: string,
        ): ((r1: IAttribute, r2: IAttribute) => number) => {
            switch (sortColumn) {
                case 'Code':
                    return (r1: A, r2: A): number =>
                        sortAscending * strCmp(r1.attributeCode, r2.attributeCode);
                case 'Description':
                    return (r1: A, r2: A): number =>
                        sortAscending * strCmp(r1.attributeDesc, r2.attributeDesc);
                case 'Created At':
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(moment(r1.createdAt).valueOf(), moment(r2.createdAt).valueOf());
                case 'Last Modified At':
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(
                            moment(r1.lastModifiedAt).valueOf(),
                            moment(r2.lastModifiedAt).valueOf(),
                        );
                default:
                    // Sort column not recognized. No sorting performed.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp(sortColumn);
        return [...attributes].sort(sortCmp);
    };

    const onDeletetAttributeConcluded = (
        attribute: IAttribute,
        conclusion: ModalConclusion,
    ): void => {
        if (conclusion !== ModalConclusion.Done) {
            return;
        }
        const index = attributes.findIndex((a) => a.id === attribute.id);
        if (index !== -1) {
            const attributesVar = [...attributes];
            attributesVar.splice(index, 1);
            setAttributes(attributesVar);
        }
    };

    const tableColumns = getAttributesTableColumns({
        sortColumn,
        sortAscending: sortAscending === 1,
        hasAttributesModify: authContext.isInRole(Role.AttributesModify),
        hasAttributesDelete: authContext.isInRole(Role.AttributesDelete),
        sortColumnHandler,
        onAddEditAttributeConcluded,
        onDeletetAttributeConcluded,
    });

    useEffect(() => {
        const tableRowsVar = sortAttributes(attributes);
        setTableRows(tableRowsVar);
    }, [sortAscending, sortColumn, attributes]);

    return (
        <CheckRole requiredRolesAny={[Role.AttributesRead]} redirectNotInRole={AccessDeniedURL}>
            <ProblemLoadingData isProblemLoadingData={!!hasProblemLoadingPage}>
                <CheckRole requiredRolesAny={[Role.AttributesWrite]}>
                    <ButtonBar>
                        <AddAttributeModalButton
                            mode={ModalMode.Add}
                            onAddEditAttributeConcluded={onAddEditAttributeConcluded}
                        />
                    </ButtonBar>
                </CheckRole>
                <Table
                    rows={tableRows || []}
                    tableColumns={tableColumns}
                    isFetchingData={isLoading}
                    tableName='Attributes'
                />
                <Spacer marginTop={100} />
            </ProblemLoadingData>
        </CheckRole>
    );
}
