import React from 'react';
import { useCheckMountedState } from 'utils/misc-hooks';
import { Toggle } from '@fluentui/react';
import { IUseInputElement } from 'components/common/use-input/input-element-utils';

interface IFluentToggleParams {
    // Ideally, we should keep the following props 100% the same
    // as the underlying Fluent component so that they can directly
    // drive its props.
    // In this hook, name of prop isDisabled is different from
    // that of the underlying component to aviod having to disable
    // linting on all the code that uses it.
    isDisabled?: boolean;
    inlineLabel?: boolean;
    label?: string | JSX.Element;
    onText?: string;
    offText?: string;
}

export function useFluentToggle(params: IFluentToggleParams): IUseInputElement<boolean> {
    const [isChecked, setIsChecked] = useCheckMountedState<boolean | undefined>(undefined);

    const onValueChange = (a?: React.FormEvent<HTMLElement>, checked?: boolean): void => {
        if (a?.type === 'click') {
            setIsChecked(checked);
        }
    };

    const initialize = (initValue?: boolean): void => {
        setIsChecked(initValue);
    };

    return {
        value: isChecked,
        initialize,
        theElement: (): JSX.Element => (
            <Toggle
                checked={isChecked}
                onChange={onValueChange}
                label={params.label}
                disabled={params.isDisabled}
                onText={params.onText}
                offText={params.offText}
                inlineLabel={params.inlineLabel}
            />
        ),
    };
}
