import React, { createContext, useReducer, useEffect } from 'react';
import { IChoiceGroupOption, IDropdownOption } from '@fluentui/react';
import { cloudKeys, translateCloudName } from 'components/staffing/staffing-constants';

type FilterKeyType = 'cloud' | 'filter';

type FilterDataType = IChoiceGroupOption | IDropdownOption;

type contextStateType = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    cloud: FilterDataType | {};
    // eslint-disable-next-line @typescript-eslint/ban-types
    filter: FilterDataType | {};
    cloudChoices: IDropdownOption[];
    filterChoices: IChoiceGroupOption[];
    setFilter: (key: FilterKeyType, value: FilterDataType) => void;
};

export type StatusFiltersContextType = {
    cloud: FilterDataType;
    filter: FilterDataType;
    cloudChoices: IDropdownOption[];
    filterChoices: IChoiceGroupOption[];
    setFilter: (key: FilterKeyType, value: FilterDataType) => void;
};

type actionType = {
    type: string;
    payload: IChoiceGroupOption | IChoiceGroupOption[] | IDropdownOption | IDropdownOption[];
};

export enum FilterSettings {
    None = 'None',
    Azure = 'Azure',
    M4Burndown = 'M4 Burndown',
    TargetReady = 'Target Ready',
}

export default function StaffingStatusFiltersProvider(
    props: IStaffingStatusFilterContextProviderProps,
): JSX.Element {
    const reducer = (state: contextStateType, action: actionType) => {
        let result;
        switch (action.type) {
            case 'cloud':
            case 'filter':
            case 'cloudChoices':
            case 'filterChoices':
                result = {
                    ...state,
                    [action.type]: action.payload,
                };
                break;
            default:
                result = state;
                break;
        }
        return result;
    };

    const [contextValue, dispatch] = useReducer(reducer, {
        cloud: {},
        filter: {},
        cloudChoices: [],
        filterChoices: [],
        setFilter: setContextFilter,
    });

    const None: IChoiceGroupOption = {
        key: FilterSettings.None,
        text: FilterSettings.None,
    };

    const filterChoices: IChoiceGroupOption[] = [
        None,
        {
            key: FilterSettings.Azure,
            text: FilterSettings.Azure,
        },
        {
            key: FilterSettings.M4Burndown,
            text: FilterSettings.M4Burndown,
        },
        {
            key: FilterSettings.TargetReady,
            text: FilterSettings.TargetReady,
        },
    ];

    useEffect(() => {
        dispatch({
            type: 'cloudChoices',
            payload: cloudKeys().map((c) => ({ key: c, text: translateCloudName(c) })),
        });
        dispatch({ type: 'filterChoices', payload: filterChoices });
        dispatch({ type: 'filter', payload: None });
        dispatch({
            type: 'cloud',
            payload: { key: cloudKeys()[0], text: translateCloudName(cloudKeys()[0]) },
        });
    }, []);

    function setContextFilter(key: FilterKeyType, value: FilterDataType): void {
        dispatch({ type: key, payload: value });
    }

    return (
        <StaffingStatusFiltersContext.Provider value={contextValue as StatusFiltersContextType}>
            {props.children}
        </StaffingStatusFiltersContext.Provider>
    );
}

export const StaffingStatusFiltersContext = createContext<StatusFiltersContextType>(null!);

export interface IStaffingStatusFilterContextProviderProps {
    children: JSX.Element[] | JSX.Element;
}
