/**
 * This react hook checks correctness and integrity of filter definitions.
 */

import { useEffect, useMemo, useState } from 'react';

import {
    FilterItemType,
    FilterItemTypeEnum,
    flattenFilterItems,
    IFilterItem,
    makePropertyNameToFilterItemDict,
} from 'components/common/search-filter/search-filter-library';

type UseCheckFilterDefinitionsType = {
    isFilterDefinitionsChecked: boolean;
    isFilterDefinitionsOk: boolean;
    filterDefinitionError: string;
    flattenedFilterItems: IFilterItem[];
    propertyNameToFilterItemDict: Record<string, IFilterItem>;
};

export function useCheckFilterDefinitions(
    isFilterDefinitionsReady: boolean,
    filterItems: IFilterItem[],
): UseCheckFilterDefinitionsType {
    const [isFilterDefinitionsChecked, setIsFilterDefinitionsChecked] = useState<boolean>(false);
    const [isFilterDefinitionsOk, setIsFilterDefinitionsOk] = useState<boolean>(false); // True means filter data has been checked and ready to use.
    const [filterDefinitionError, setFilterDefinitionError] = useState<string>('');
    const [flattenedFilterItems, setFlattenedFilterItems] = useState<IFilterItem[]>([]);

    const clearErrorInFilterData = (): void => {
        setFilterDefinitionError('');
    };
    const setErrorInFilterData = (): void => {
        setFilterDefinitionError('Error in filter definitions');
    };

    // Check integrity of filter data.
    useEffect(() => {
        setIsFilterDefinitionsChecked(false);
        if (!isFilterDefinitionsReady) {
            return;
        }
        clearErrorInFilterData();
        // The following checks should be performed when filter definitions become available or when they change:
        // Check 1. Check if all filter items have defined the property "type". Flag error if there's any that doesn't.
        // Check 1.1 Check if all the specified types are valid types.
        // Check 2. Check if all filter items have defined the property "name". Flag error if there's any that doesn't.
        // Check 3. Check if they are unique. Flag error if there's any duplicates except only for checkboxes.
        //          Duplicates are accepted, and even expected, for checkboxes. The reason is that
        //          checkboxes are grouped together and the same "name" is used to identify the group.
        //          The "checked" property of each checkbox is used to identify the checkbox within the group.
        // Check 4. Check if all dropdown filter items have specified dropdown options on property "dropdownOptions.options".
        // Check 4.1 - Option "multiSelect", is only applicable to dropdowns. If specified, make sure the filter item is a dropdown.
        // Check 4.2 - Check if option keys are unique
        // Check 5. Check if all checkbox filter items have specified the corresponding option on property "checkboxOptions" and "checked".
        // Check 6. Check if property "checked" of checkbox with the same propery "name" is unique.
        //          Assume each checkbox with the same "name" property creates a name space. Value of property "checked"
        //          for each checkbox is expected to be unique within that name space.
        // Check 7. If sliderOptions is specified, check to make sure the filter item is a slider.
        // Check 7.1 - Check if property "min" is specified.
        // Check 8. Check if all date picker filter items have specified corresponding options on property "datePickerOptions".
        // Check 9. Check if all employee search items have specified corresponding options on property "employeeSearchOptions".

        const flattenedFilterItemsVar = flattenFilterItems(filterItems);
        // Key is "name", value is filter item type.
        const variableNames = new Map<string, FilterItemType>();
        // Key is "name", value is a set of values for property "checked".
        const checkboxNameSpaces = new Map<string, Set<string>>();
        let isOk = true;

        flattenedFilterItemsVar.forEach((filterItem) => {
            // Check 1
            if (!filterItem.type) {
                console.error(`Filter item hasn't defined a "type" property`, filterItem);
                setErrorInFilterData();
                isOk = false;
                return;
            }
            if (!Object.keys(FilterItemTypeEnum).includes(filterItem.type)) {
                console.error(`Filter item type is invalid`, filterItem);
                setErrorInFilterData();
                isOk = false;
                return;
            }

            // Check 2
            if (!filterItem.name) {
                console.error(`Filter item hasn't defined a "name" property`, filterItem);
                setErrorInFilterData();
                isOk = false;
                return;
            }

            // Check 3
            if (
                variableNames.has(filterItem.name) &&
                variableNames.get(filterItem.name) !== FilterItemTypeEnum.Checkbox // This is strongly typed.
            ) {
                console.error(
                    'Filter item has duplicate variable name',
                    filterItem,
                    'The duplicate variable name is',
                    variableNames.get(filterItem.name),
                );
                setErrorInFilterData();
                isOk = false;
                return;
            }
            variableNames.set(filterItem.name, filterItem.type);

            // Check 4
            if (filterItem.type === FilterItemTypeEnum.Dropdown) {
                if (!filterItem.dropdownOptions) {
                    console.error(
                        "Dropdown filter item hasn't specified dropdownOptions",
                        filterItem,
                    );
                    setErrorInFilterData();
                    isOk = false;
                    return;
                }
                // Check 4.1
                if (filterItem.dropdownOptions.multiSelect !== undefined) {
                    if (filterItem.type !== FilterItemTypeEnum.Dropdown) {
                        console.error(
                            'Property "multiSelect" is only applicable to dropdowns',
                            filterItem,
                        );
                        setErrorInFilterData();
                        isOk = false;
                        return;
                    }
                }
                if (!filterItem.dropdownOptions.options) {
                    console.error(
                        "Dropdown filter item hasn't specified dropdown options",
                        filterItem,
                    );
                    setErrorInFilterData();
                    isOk = false;
                    return;
                }

                /*
                // This block checks whether list of options is empty.
                // Assuming it to be ok for now but keeping it as a
                // comment for possible future consideration.
                if (
                    !Array.isArray(filterItem.dropdownOptions.options) ||
                    filterItem.dropdownOptions.options.length === 0
                ) {
                    console.warn('List of dropdown options is empty', filterItem);
                }
                */

                // filterItem.options is expected to be of type KeyTextType[].
                const optionKeys = new Set<string>();
                filterItem.dropdownOptions.options.forEach((option, ix) => {
                    if (!option.hasOwnProperty('key') || !option.hasOwnProperty('text')) {
                        console.error(
                            `Dropdown filter item's data element ${ix} is not object of (key, text) pairs`,
                            filterItem,
                        );
                        setErrorInFilterData();
                        isOk = false;
                        return;
                    }
                    // Check 4.2 - Check if option keys are unique
                    if (optionKeys.has(option.key)) {
                        console.error(
                            `Duplicate option key '${option.key}' is specified for dropdown filter item`,
                            filterItem,
                        );
                        setErrorInFilterData();
                        isOk = false;
                        return;
                    }
                    optionKeys.add(option.key);
                });
            }

            // Check 5
            if (filterItem.type !== FilterItemTypeEnum.Checkbox && filterItem.checkboxOptions) {
                console.error(
                    `Property checkboxOptions is only applicable to checkbox search items`,
                    filterItem,
                );
                setErrorInFilterData();
                isOk = false;
                return;
            }
            if (filterItem.type === FilterItemTypeEnum.Checkbox) {
                if (!filterItem.checkboxOptions) {
                    console.error(
                        `Checkbox filter item hasn't specified the property "checkboxOptions"`,
                        filterItem,
                    );
                    setErrorInFilterData();
                    isOk = false;
                    return;
                }
                if (!filterItem.checkboxOptions.checked) {
                    console.error(
                        `Checkbox filter item hasn't specified the property "checked"`,
                        filterItem,
                    );
                    setErrorInFilterData();
                    isOk = false;
                    return;
                }
                if (typeof filterItem.checkboxOptions.checked !== 'string') {
                    console.error(
                        'Value of "checked" property of Checkbox filter item is expected to be a string',
                        filterItem,
                    );
                    setErrorInFilterData();
                    isOk = false;
                    return;
                }

                // Check 6
                if (!checkboxNameSpaces.has(filterItem.name)) {
                    checkboxNameSpaces.set(filterItem.name, new Set<string>());
                }
                if (
                    checkboxNameSpaces
                        .get(filterItem.name)
                        ?.has(filterItem.checkboxOptions.checked) &&
                    !filterItem.checkboxOptions.isDuplicate
                ) {
                    console.error(
                        `Duplicate value '${filterItem.checkboxOptions.checked}' is specified for property "checked" of filter item for checkboxes with name '${filterItem.name}'.` +
                            ` If you need to show the same checkbox more than once, specify "isDuplicate".`,
                        filterItem,
                    );
                    setErrorInFilterData();
                    isOk = false;
                    return;
                }
                if (
                    !checkboxNameSpaces
                        .get(filterItem.name)
                        ?.has(filterItem.checkboxOptions.checked) &&
                    filterItem.checkboxOptions.isDuplicate
                ) {
                    console.error(
                        `isDuplicate is specified too early.` +
                            ` In order to specify isDuplicate, first declare a filter item with the same value for properties` +
                            ` "name" and "checked" without specifying isDuplicate.` +
                            ` Then declare another filter item with the same "name" and "checked" somewhere after the first one` +
                            ` and specify isDuplicate on that.`,
                        filterItem,
                    );
                    setErrorInFilterData();
                    isOk = false;
                    return;
                }
                // The following type cast is safe.
                (checkboxNameSpaces.get(filterItem.name) as Set<string>)!.add(
                    filterItem.checkboxOptions.checked,
                );
            }
            // Check 7.
            // sliderOptions is optional for sliders. No need to check if it is defined.
            if (filterItem.sliderOptions) {
                if (filterItem.type !== FilterItemTypeEnum.SliderBar) {
                    console.error(
                        `Property sliderOptions is only applicable to sliders`,
                        filterItem,
                    );
                    setErrorInFilterData();
                    isOk = false;
                    return;
                }
                if (filterItem.sliderOptions.min === undefined) {
                    console.error(`Property min of sliderOptions must be specified`, filterItem);
                    setErrorInFilterData();
                    isOk = false;
                    return;
                }
            }
            // Check 8.
            // datePickerOptions is optional for date pickers. No need to check if it is defined.
            if (filterItem.type !== FilterItemTypeEnum.DatePicker && filterItem.datePickerOptions) {
                console.error(
                    `Property datePickerOptions is only applicable to date pickers`,
                    filterItem,
                );
                setErrorInFilterData();
                isOk = false;
                return;
            }

            // Check 9.
            if (
                filterItem.type !== FilterItemTypeEnum.EmployeeSearch &&
                filterItem.employeeSearchOptions
            ) {
                console.error(
                    `Property employeeSearchOptions is only applicable to employee search items`,
                    filterItem,
                );
                setErrorInFilterData();
                isOk = false;
                return;
            }
            if (filterItem.type === FilterItemTypeEnum.EmployeeSearch) {
                if (!filterItem.employeeSearchOptions) {
                    console.error(
                        "Employee search filter item hasn't specified employeeSearchOptions",
                        filterItem,
                    );
                    setErrorInFilterData();
                    isOk = false;
                    return;
                }
                if (filterItem.employeeSearchOptions.itemLimit === undefined) {
                    console.error(
                        "Employee search options hasn't specified itemLimit in employeeSearchOptions",
                        filterItem,
                    );
                    setErrorInFilterData();
                    isOk = false;
                    return;
                }
            }
        });

        setIsFilterDefinitionsChecked(true);
        setIsFilterDefinitionsOk(isOk);
        if (isOk) {
            setFlattenedFilterItems(flattenedFilterItemsVar);
        } else {
            setFlattenedFilterItems([]);
        }
    }, [isFilterDefinitionsReady, filterItems]);

    const propertyNameToFilterItemDict = useMemo(
        () => makePropertyNameToFilterItemDict(flattenedFilterItems, isFilterDefinitionsOk),
        [flattenedFilterItems, isFilterDefinitionsOk],
    );

    return {
        isFilterDefinitionsChecked,
        isFilterDefinitionsOk,
        filterDefinitionError,
        flattenedFilterItems,
        propertyNameToFilterItemDict,
    };
}
