import { ActionButton, FontIcon, mergeStyles } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import React from 'react';
import { ElementType } from 'components/forms/forms-common';

const ElementToolbarContainerStyles = mergeStyles({
    marginTop: '1rem',
    display: 'flex',
    width: 'fit-content',
    background: 'white',
    clipPath: 'inset(0px 633px 0 0)',
    transition: 'clip-path .15s ease-out',
    transitionDelay: '.75s',
    overflow: 'hidden',
    '&:hover': {
        clipPath: 'inset(0px)',
        transition: 'clip-path .25s ease-out',
    },
});

const addIconContainerStyles = mergeStyles({
    display: 'flex',
    background: 'rgb(0, 120, 212)',
    color: 'white',
    paddingTop: '7px',
});

const addIconStyles = mergeStyles({
    paddingLeft: '7px',
    fontSize: 'larger',
    paddingRight: '10px',
});

type IElementToolbarProps = {
    addNewElement: (currentSection: number, type: keyof typeof ElementType) => void;
    currentSection: number;
};
const ElementToolbar = (props: IElementToolbarProps): JSX.Element => {
    const { addNewElement, currentSection } = props;

    const onAddNewElement = (type: keyof typeof ElementType): void => {
        addNewElement(currentSection, type);
    };

    return (
        <div className={ElementToolbarContainerStyles}>
            <div className={addIconContainerStyles}>
                <FontIcon className={addIconStyles} iconName={IconNames.Add} />
                <span style={{ paddingTop: '2px', paddingRight: '12px' }}>Add</span>
            </div>
            <ActionButton
                title='Add Choice element'
                aria-label='Add Choice element'
                iconProps={{ iconName: IconNames.CircleRing }}
                onClick={() => onAddNewElement('radio')}>
                Choice
            </ActionButton>
            <ActionButton
                title='Add Text element'
                aria-label='Add Text element'
                iconProps={{ iconName: IconNames.TextField }}
                onClick={() => onAddNewElement('textfield')}>
                Text
            </ActionButton>
            <ActionButton
                title='Add Date element'
                aria-label='Add Date element'
                iconProps={{ iconName: IconNames.Calendar }}
                onClick={() => onAddNewElement('date')}>
                Date
            </ActionButton>
            <ActionButton
                title='Add Signature element'
                aria-label='Add Signature element'
                iconProps={{ iconName: IconNames.PenWorkspace }}
                onClick={() => onAddNewElement('signature')}>
                Signature
            </ActionButton>
            <ActionButton
                title='Add Table element'
                aria-label='Add Table element'
                iconProps={{ iconName: IconNames.Table }}
                onClick={() => onAddNewElement('table')}>
                Table
            </ActionButton>
            <ActionButton
                title='Add Info element'
                aria-label='Add Info element'
                iconProps={{ iconName: IconNames.Info }}
                onClick={() => onAddNewElement('info')}>
                Info
            </ActionButton>
            <ActionButton
                title='Add Country Picker element'
                aria-label='Add Country Picker element'
                iconProps={{ iconName: IconNames.Flag }}
                onClick={() => onAddNewElement('country')}>
                Country
            </ActionButton>
            <ActionButton
                title='Employee Picker element'
                aria-label='Employee Picker element'
                iconProps={{ iconName: IconNames.PeopleAdd }}
                onClick={() => onAddNewElement('people')}>
                People
            </ActionButton>
        </div>
    );
};

export default React.memo(ElementToolbar);
