import { IChoiceGroupOption } from '@fluentui/react';
import { PersonnelTypes } from 'clients/eligibility-client';

const searchOptionVisitor = (enable: boolean): IChoiceGroupOption => {
    return {
        key: PersonnelTypes.VISITOR,
        text: 'Visitor',
        styles: { root: { width: '50%' } },
        disabled: !enable,
    };
};

const searchOptionEmployee = (enable: boolean) => {
    return {
        key: PersonnelTypes.EMPLOYEE,
        text: 'Employee',
        styles: { root: { width: '50%' } },
        disabled: !enable,
    };
};

type EnableSearchOptionType = {
    enableVisitor: boolean;
    enableEmployee: boolean;
};

export const defaultSearchOption = (
    enables: EnableSearchOptionType,
): IChoiceGroupOption | undefined => {
    if (enables.enableEmployee) return searchOptionEmployee(true);
    if (enables.enableVisitor) return searchOptionVisitor(true);
    return undefined;
};

export const searchOptions = (params: EnableSearchOptionType): IChoiceGroupOption[] => {
    return [searchOptionEmployee(params.enableEmployee), searchOptionVisitor(params.enableVisitor)];
};

export enum UserAssignmentsTabs {
    PersonnelInfo = 'info',
    ManageAttributes = 'attributes',
    ManageEligibilities = 'eligibilities',
    Equipment = 'equipment',
}

export const BlackListAttributeCode = [
    'CITIZENSHIP_US',
    'CLOUDSCREEN',
    'SCREENING_CJIS',
    'CLEARANCE_IT2',
    'SCREENING_FEDERAL_EOD',
];

export enum AssignmentsPageContentType {
    SearchBox = 'SearchBox ',
    MyInfo = 'MyInfo',
    EmployeeOrVisitor = 'SomeonesInfo',
}
