import { ActionButton, mergeStyles, mergeStyleSets, Stack } from '@fluentui/react';
import { BadgeColorHex } from 'assets/constants/global-colors';
import { IconNames, noDataText } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import GroupClient, {
    IGroup,
    IGroupJoinReviewRequest,
    IGroupMembership,
    IGroupPolicyViolationRule,
} from 'clients/group-client';
import ModalActionButton, {
    ModalConclusion,
    WhichSubmitButton,
} from 'components/common/buttons/modal-action-button';
import { ChartLegendDot } from 'components/common/charts/chart-legend';
import { ModalSizeType } from 'components/common/modal';
import Spacer from 'components/common/spacer';
import { useTextField } from 'components/common/use-input/use-textfield';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import ManageGroupMemberMoreInfoModalActionButton from 'components/groups/manage-group/members/buttons/manage-group-member-more-info-modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import React, { ReactNode, useContext, useState } from 'react';

enum ViolationDetailNames {
    Violations = 'Violations',
    GracePeriod = 'Grace Period',
    Compliant = 'Compliant',
}

export interface IReviewRequestModalActionButtonProps {
    group: IGroup;
    member: IGroupMembership;
    groupName?: string;
    canApproveOrDenyRequest: boolean;
    onDeleteMemberFromJoinRequestTable: (groupMember: IGroupMembership) => void;
}

export default function ReviewRequestModalActionButton(
    props: IReviewRequestModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [failedPolicyViolations, setFailedPolicyViolations] = useState<
        IGroupPolicyViolationRule[]
    >();
    const [inGracePeriodPolicyViolations, setInGracePeriodPolicyViolations] = useState<
        IGroupPolicyViolationRule[]
    >();
    const [passedPolicyViolations, setPassedPolicyViolations] = useState<
        IGroupPolicyViolationRule[]
    >();

    const maxModeratorCommentLength = 500;
    const {
        value: moderatorComment,
        theElement: moderatorCommentElement,
        initialize: initModeratorComment,
    } = useTextField({
        rows: 5,
        maxLength: maxModeratorCommentLength,
        multiline: true,
        resizable: false,
        autoAdjustHeight: true,
        placeholder: 'Enter optional comment for denial OR optional note for approval',
        ariaLabel: 'Notes',
    });

    const displayOneRow = (
        title: string,
        content: string | undefined | JSX.Element | ReactNode,
    ): JSX.Element => {
        return (
            <Stack horizontal verticalAlign='start' tokens={{ padding: 7 }}>
                <Stack.Item className={styles.left}>{title}</Stack.Item>
                <Stack.Item className={styles.right}>{content}</Stack.Item>
            </Stack>
        );
    };

    const displayOneStack = (
        badgeName: string,
        violationOrPassingRules: IGroupPolicyViolationRule[],
    ): JSX.Element => {
        return displayOneRow(
            badgeName,
            <ul className={styles.listWrapper}>
                {violationOrPassingRules.map((violation: IGroupPolicyViolationRule) => (
                    <li className={styles.listItem} key={violation.ruleId}>
                        <Stack horizontal wrap key={violation.ruleId}>
                            <ChartLegendDot
                                backgroundColor={
                                    badgeName === ViolationDetailNames.Violations
                                        ? BadgeColorHex.RED
                                        : badgeName === ViolationDetailNames.GracePeriod
                                        ? BadgeColorHex.YELLOW
                                        : BadgeColorHex.GREEN
                                }
                            />
                            {learnMore(badgeName, violation)}
                        </Stack>
                    </li>
                ))}
            </ul>,
        );
    };

    const learnMore = (
        violationType: string,
        content: IGroupPolicyViolationRule | undefined,
    ): JSX.Element => {
        return (
            <>
                <Stack horizontal verticalAlign='center'>
                    <Stack.Item className={styles.learnMoreTitle}>{content?.ruleName}</Stack.Item>
                    {content?.remediationSteps && violationType !== ViolationDetailNames.Compliant && (
                        <Stack.Item>
                            <ManageGroupMemberMoreInfoModalActionButton
                                policyViolationRule={content}
                                buttonStyle={styles.learnMoreButton}
                            />
                        </Stack.Item>
                    )}
                    {content?.remediationSteps &&
                        violationType === ViolationDetailNames.Compliant && (
                            <ActionButton
                                text='View'
                                className={styles.learnMoreButton}
                                href={content?.remediationSteps}
                                target='_blank'
                            />
                        )}
                </Stack>
            </>
        );
    };

    const showAllRulesPassed = (
        failedPolicyViolations: IGroupPolicyViolationRule[] | undefined,
        inGracePeriodPolicyViolations: IGroupPolicyViolationRule[] | undefined,
    ): boolean => {
        return (
            !!failedPolicyViolations &&
            failedPolicyViolations.length === 0 &&
            !!inGracePeriodPolicyViolations &&
            inGracePeriodPolicyViolations.length === 0
        );
    };

    const initLocalVariables = (): void => {
        setErrorMessage('');
        initModeratorComment(moderatorComment);
        fetchPolicyViolation();
    };

    const fetchPolicyViolation = async (): Promise<void> => {
        try {
            const policyViolation = await GroupClient.createPolicyViolation(
                authContext,
                props.member.groupId,
                props.member.personnelId,
            );
            setFailedPolicyViolations(policyViolation.failed);
            setInGracePeriodPolicyViolations(
                policyViolation.passed.filter((r) => r.isInGracePeriod),
            );
            setPassedPolicyViolations(policyViolation.passed.filter((r) => !r.isInGracePeriod));
        } catch (e) {
            setErrorMessage('Failed to load policy violations.');
        }
    };

    const onHandleJoinRequest = async (whichSubmitButton?: WhichSubmitButton): Promise<void> => {
        try {
            const isApproveButton = whichSubmitButton === WhichSubmitButton.One;
            // const isDenyButton = whichSubmitButton === WhichSubmitButton.Two; // Can toggle if necessary
            const groupJoinReviewRequest: IGroupJoinReviewRequest = {
                groupId: props.group.id,
                personnelId: props.member.personnelId,
                isApproved: isApproveButton,
                comment: moderatorComment,
            };

            await GroupClient.updateGroupRequest(authContext, groupJoinReviewRequest);
        } catch (error) {
            console.error(error);
            throw 'Error on approve/deny member from group.';
        }
    };

    const onApproveDenyMemberConcluded = (conclusion: ModalConclusion): void => {
        if (conclusion === ModalConclusion.Done) {
            props.onDeleteMemberFromJoinRequestTable(props.member);
        }
    };

    return (
        <ModalActionButton
            text='Review Request'
            size={ModalSizeType.mediumLarge}
            enable={!props.group.enableDynamic}
            iconName={IconNames.CheckList}
            modalTitle='Review Request'
            enableSubmit={props.canApproveOrDenyRequest && !errorMessage}
            onButtonClick={initLocalVariables}
            submitButtonText='Approve'
            shouldHideCancelButton={false}
            onSubmit={onHandleJoinRequest}
            showSubmit2={true}
            submitButton2Text='Deny'
            enableSubmit2={props.canApproveOrDenyRequest}
            errorMsg={errorMessage} //'Failed to load policy violations.'
            onModalConcluded={onApproveDenyMemberConcluded}>
            {displayOneRow('Group', props.group.name)}
            {displayOneRow(
                'Employee',
                <CoreEmployeeHoverCardFromPrincipalId principalId={props.member.personnelId} />,
            )}

            {!!failedPolicyViolations &&
                failedPolicyViolations.length > 0 &&
                displayOneStack(ViolationDetailNames.Violations, failedPolicyViolations)}

            {!!inGracePeriodPolicyViolations &&
                inGracePeriodPolicyViolations.length > 0 &&
                displayOneStack(ViolationDetailNames.GracePeriod, inGracePeriodPolicyViolations)}

            {!!passedPolicyViolations &&
                passedPolicyViolations.length > 0 &&
                displayOneStack(ViolationDetailNames.Compliant, passedPolicyViolations)}

            {showAllRulesPassed(failedPolicyViolations, inGracePeriodPolicyViolations) &&
                displayOneRow(
                    ViolationDetailNames.Compliant,
                    <ul className={styles.listWrapper}>
                        <li className={styles.listItem}>
                            <Stack horizontal wrap>
                                <ChartLegendDot backgroundColor={BadgeColorHex.GREEN} />
                                All rules passed
                            </Stack>
                        </li>
                    </ul>,
                )}

            {displayOneRow(
                'Sponsor',
                !!props.member.sponsorId || !!props.member.sponsorAlias ? (
                    <CoreEmployeeHoverCardFromPrincipalId principalId={props.member.sponsorId} />
                ) : (
                    noDataText
                ),
            )}

            {displayOneRow(
                'Justification',
                props.member.justification ? props.member.justification : noDataText,
            )}

            {displayOneRow(
                'Notes',
                <>
                    <Spacer marginTop={5} />
                    {moderatorCommentElement()}
                </>,
            )}
        </ModalActionButton>
    );
}

const styles = mergeStyleSets({
    left: mergeStyles(
        {
            width: '30%',
        },
        globalStyles.boldFont,
    ),
    right: {
        width: '70%',
    },
    tableStack: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        lineHeight: 35,
        marginBottom: 20,
    },
    itemNameColumn: {
        width: 200,
        flexShrink: 0,
        alignItems: 'flex-start',
    },
    listWrapper: {
        margin: 0,
        padding: 0,
    },
    listItem: {
        listStyle: 'none',
    },
    itemValueColumn: {
        width: 400,
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        marginBottom: 0,
    },
    learnMoreTitle: {
        paddingRight: 5,
    },
    learnMoreButton: {
        color: 'rgb(0, 120, 212)',
        '& :first-letter': {
            textTransform: 'capitalize',
        },
        '& :hover': {
            textDecoration: 'underline',
        },
    },
});
