import { HoverCard, HoverCardType, mergeStyleSets, Stack } from '@fluentui/react';
import { lightGray } from 'assets/constants/global-colors';
import { IBasicEmployee } from 'clients/employee-client';
import React from 'react';
import EmployeeBasicHoverCardSimple from 'components/common/employee/employee-basic-hover-card-simple';

interface IDisplayEmployeeMinicardsInHoverCardProps {
    personnelIds: string[];
    basicEmployeesMap: Map<string, IBasicEmployee>;
    showMiniCardAlias?: boolean; // Default true
    showFullName?: boolean; // Default true
    displayActions?: boolean; // Default true
    employeePicturesMap: Map<string, string>;
}

export default function DisplayFacepileInHoverCard(
    props: IDisplayEmployeeMinicardsInHoverCardProps,
): JSX.Element {
    const showMiniCardAlias = props.showMiniCardAlias ?? true;
    const showFullName = props.showFullName ?? true;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const displayActions = props.displayActions ?? true;

    function onRenderPlainCard(): JSX.Element {
        return (
            <Stack
                horizontal
                horizontalAlign='start'
                wrap
                tokens={{ childrenGap: 10 }}
                style={{ margin: 10, maxWidth: 160 }}>
                {props.personnelIds.map((personnelId) => {
                    const employee = props.basicEmployeesMap.get(personnelId);
                    const image = props.employeePicturesMap.get(personnelId);
                    if (!employee) {
                        return;
                    }

                    return (
                        <EmployeeBasicHoverCardSimple
                            key={`facepile-hover-${personnelId}`}
                            employeeBasicData={employee}
                            image={image ?? ''}
                            showFullName={showFullName}
                            showMiniCardAlias={showMiniCardAlias}
                            displayActions={displayActions}
                        />
                    );
                })}
            </Stack>
        );
    }

    const inlineStyle = mergeStyleSets({
        host: {
            display: 'inline-block',
        },
    });

    return (
        <HoverCard
            styles={inlineStyle}
            plainCardProps={{ onRenderPlainCard }}
            instantOpenOnClick={true}
            cardOpenDelay={300}
            expandedCardOpenDelay={3000}
            type={HoverCardType.plain}>
            <span className={styles.circle}>{`+${props.personnelIds.length}`}</span>
        </HoverCard>
    );
}

const styles = mergeStyleSets({
    circle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        fontSize: 10,
        background: lightGray,
        borderRadius: '50%',
    },
});
