/* eslint-disable no-restricted-imports -- Routes must reference internal only components */
import React, { useEffect } from 'react';
import { Route, Redirect, Switch, RouteComponentProps, useLocation } from 'react-router-dom';
import CandidatesPage from 'components/screening/common/candidates-page';
import StaffingLandingPage from 'components/staffing/staffing-landing-page';
import StaffingStatusPage from 'components/staffing/status/staffing-status-page';
import StaffingOrgMapPage from 'components/staffing/allocation/staffing-org-map-page';
import CloudScreeningPage from 'components/screening/cloud-screening/cloud-screening-page';
import PersonnelProfileSearchPage from 'components/personnel-profile/personnel-profile-search-page';
import { AccessDeniedPage } from 'components/common/access-denied';
import { PageNotFound } from 'components/common/page-not-found';
import { AccessDeniedURL } from 'assets/constants/global-constants';
import ScaManage from 'components/sca/manage/sca-manage';
import ScaManageViewIdFilterWrapper from 'components/sca/manage/sca-manage-view-id-filter-wrapper';
import UsGovLandingPage from 'components/screening/us-gov/us-gov-landing-page';
import ScaDisplayMyHistory from 'components/sca/history/sca-display-my-history';
import ScaDisplayEmployeeHistory from 'components/sca/history/sca-display-employee-history';
import ScaProgramDetails from 'components/sca/sca-program-details';
import { CloudScreeningRequestsTable } from 'components/screening/cloud-screening/cloud-screening-requests-table';
import { HistoryPageValidTabs } from 'components/sca/sca-constants';
import User from 'components/about/user';
import ViewEligibilityFilterWrapper from 'components/eligibilities/eligibilities/view/view-eligibility-filter-wrapper';
import UserAssignmentsEmployeeOrVisitor from 'components/user-assignments/user-assignments-employee-or-visitor';
import VisitorsWrapper from 'components/visitors/visitors-wrapper';
import { CloudScreeningMonthlyReport } from 'components/screening/cloud-screening/cloud-screening-monthly-report';
import ManageEligibilities from 'components/eligibilities/manage-eligibilities';
import FacilitiesPage from 'components/facilities/facilities-page';
import FacilitiesManagementPage from 'components/facilities/facilities-management/facilities-management-page';
import ElgibilityRequest from 'components/eligibilities/eligibility-request';

// Groups using Core
import ManageGroupsWrapper from 'components/groups/manage-groups/manage-groups-wrapper';
import ManageGroupWrapper from 'components/groups/manage-group/manage-group-wrapper';
import MyGroupsWrapper from 'components/groups/my-groups/my-groups-wrapper';
import RequestToJoinGroup from 'components/groups/request-to-join/request-to-join-group';
import GroupRuleAgreement from 'components/groups/agreement/group-rule-agreement';

import UserAssignmentsMe from 'components/user-assignments/user-assignments-me';
import UserAssignmentsSearch from 'components/user-assignments/user-assignments-search';
import { ScreeningPageNames } from 'components/common/constants';
import {
    ScreeningPaths,
    UsGovScreeningUserType,
} from 'components/screening/common/common-constants';

import EmailDashboard from 'components/email/email-dashboard';
import Home from 'components/home/home';

import ManageCandidatePageCommon from 'components/screening/common/manage-candidate/manage-candidate-page-common';
import { useFeatureFlag, FeatureFlagKeys } from 'utils/use-feature-flags';
import ContactUs from 'components/support/contact-us';
import FacilitiesReservationLandingPage from 'components/facilities/facilities-landing-page';
import FacilitiesManageLandingPage from 'components/facilities/facilities-manage-landing-page';
import PublicTrustLandingPage from 'components/screening/public-trust/public-trust-landing-page';
import ContractManagementTab from 'components/screening/admin-contracts/contracts-page';
import PersonnelProfilePage from 'components/personnel-profile/personnel-profile-page';
import FacilitiesKioskLandingPage from 'components/facilities/facilities-kiosk/facilities-kiosk-landing-page';
import { Forms } from 'components/forms/forms';
import { FormsEditDataLoader } from 'components/forms/forms-edit-data-loader';
import FiltersContractsProvider from 'contexts/filters-contracts-context';
import { FormsViewerDataLoader } from 'components/forms/forms-viewer-data-loader';
import { FormsReviewerDataLoader } from 'components/forms/forms-reviewer-data-loader';
import FacilitiesKioskProvisionPage from 'components/facilities/facilities-kiosk/facilities-kiosk-provision-page';
import ScreeningV2AccessWrapper from 'components/screening/screening-v2/Screening-v2-access-wrapper';
import { AttributesList } from 'components/core/attributes/attributes-list';
import FacilitiesKioskPage from 'components/facilities/facilities-kiosk/facilities-kiosk-page';
import { AttributeAssignmentsPage } from 'components/core/attributes/attribute-assignments-page';
import { UserAssignmentsPage } from 'components/core/users/user-assignments-page';
import { UserSearchPage } from 'components/core/users/user-search-page';
import { MyAssignmentsPage } from 'components/core/users/my-assignments-page';
import { AttributeSetsList } from 'components/core/attribute-sets/attribute-sets-list';
import SignedIn from 'components/auth/signed-in';
const pageNotFound = '/Page-Not-Found';
export const home = '/home';
export const signedin = '/signedin';
interface IRoutesProps {
    onSetCsaDefault: () => void;
}

export default function Routes(props: IRoutesProps): JSX.Element {
    const { onSetCsaDefault } = props;
    const emailsCore = useFeatureFlag(FeatureFlagKeys.emailsCore);
    const profileVisitor = useFeatureFlag(FeatureFlagKeys.profileVisitor);
    const profileUsGov = useFeatureFlag(FeatureFlagKeys.profileUsGov);
    const screeningCore = useFeatureFlag(FeatureFlagKeys.screeningCore);
    const scaCore = useFeatureFlag(FeatureFlagKeys.scaCore);
    const staffingCore = useFeatureFlag(FeatureFlagKeys.staffingCore);
    const facilitiesCore = useFeatureFlag(FeatureFlagKeys.facilitiesCore);
    const groupsCore = useFeatureFlag(FeatureFlagKeys.groupsCore);
    const screeningSearchFilterContentRedesign = useFeatureFlag(
        FeatureFlagKeys.screeningSearchFilterContentRedesign,
    );
    const aboutUser = useFeatureFlag(FeatureFlagKeys.aboutUser);
    const employeeCore = useFeatureFlag(FeatureFlagKeys.employeeCore);
    const eligibilities = useFeatureFlag(FeatureFlagKeys.eligibilities);

    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const feedback = queryParams.get('feedback');
        if (feedback !== null && feedback !== undefined) {
            onSetCsaDefault();
        }
    }, [location, onSetCsaDefault]);

    return (
        <Switch>
            <Route exact path={pageNotFound} component={PageNotFound} />
            <Route exact path={AccessDeniedURL} component={AccessDeniedPage} />
            <ProtectedRoute
                renderCondition={aboutUser.enabled}
                exact
                path='/about/user'
                component={User}
            />
            <ProtectedRoute
                renderCondition={screeningSearchFilterContentRedesign.enabled}
                exact
                path={['/screening-v2', '/screening-v2/:tab']}
                component={ScreeningV2AccessWrapper}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening'
                component={UsGovLandingPage}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/us-gov'
                component={UsGovLandingPage}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/public-trust'
                component={PublicTrustLandingPage}
            />
            <ProtectedRoute
                renderCondition={facilitiesCore.enabled}
                exact
                path={['/facilities', '/facilities/reservations']}
                component={FacilitiesReservationLandingPage}
            />
            <ProtectedRoute
                renderCondition={facilitiesCore.enabled}
                exact
                path={['/facilities/reservations/:category']}
                component={FacilitiesPage}
            />
            <ProtectedRoute
                renderCondition={facilitiesCore.enabled}
                exact
                path={'/facilities/manage'}
                component={FacilitiesManageLandingPage}
            />
            <ProtectedRoute
                renderCondition={facilitiesCore.enabled}
                exact
                path={'/facilities/manage/:category'}
                component={FacilitiesManagementPage}
            />
            <ProtectedRoute
                renderCondition={facilitiesCore.enabled}
                exact
                path={['/facilities-kiosk-landing/:facilityId']}
                component={FacilitiesKioskLandingPage}
            />
            <ProtectedRoute
                renderCondition={facilitiesCore.enabled}
                exact
                path={['/facilities-kiosk-provision/:deviceId']}
                component={FacilitiesKioskProvisionPage}
            />
            <ProtectedRoute
                renderCondition={facilitiesCore.enabled}
                exact
                path={['/facilities-kiosk/:facilityId', '/facilities-kiosk']}
                component={FacilitiesKioskPage}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path={['/screening/us-gov/manage/:tab', '/screening/us-gov/manage']}
                render={(routeProps): JSX.Element => (
                    <CandidatesPage
                        pageName={ScreeningPageNames.Manage}
                        screeningPath={ScreeningPaths.UsGov}
                        userType={''}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/us-gov/my-screenings'
                render={(routeProps): JSX.Element => (
                    <CandidatesPage
                        pageName={ScreeningPageNames.MyScreenings}
                        screeningPath={ScreeningPaths.UsGov}
                        userType={'Nominee'}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path={['/screening/us-gov/my-contracts/:tab', '/screening/us-gov/my-contracts']}
                render={(routeProps): JSX.Element => (
                    <CandidatesPage
                        pageName={ScreeningPageNames.MyContracts}
                        screeningPath={ScreeningPaths.UsGov}
                        userType={'ContractOwner'}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path={['/screening/us-gov/my-nominees/:tab', '/screening/us-gov/my-nominees']}
                render={(routeProps): JSX.Element => (
                    <CandidatesPage
                        pageName={ScreeningPageNames.MyNominees}
                        screeningPath={ScreeningPaths.UsGov}
                        userType={'Nominator'}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/us-gov/clearance-records'
                render={(routeProps): JSX.Element => (
                    <CandidatesPage
                        pageName={ScreeningPageNames.ClearanceRecords}
                        screeningPath={ScreeningPaths.UsGov}
                        userType={''}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path={['/screening/us-gov/my-org/:tab', '/screening/us-gov/my-org']}
                render={(routeProps): JSX.Element => (
                    <CandidatesPage
                        pageName={ScreeningPageNames.MyOrg}
                        screeningPath={ScreeningPaths.UsGov}
                        userType={'Manager'}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/us-gov/:id'
                render={(routeProps): JSX.Element => (
                    <ManageCandidatePageCommon
                        screeningPaths={ScreeningPaths.UsGov}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path={[
                    // '/screening/public-trust/manage/:tab', // TODO: Customer Metrics
                    '/screening/public-trust/manage',
                ]}
                render={(routeProps): JSX.Element => (
                    <CandidatesPage
                        pageName={ScreeningPageNames.Manage}
                        screeningPath={ScreeningPaths.PublicTrust}
                        userType={''}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/public-trust/my-screenings'
                render={(routeProps): JSX.Element => (
                    <CandidatesPage
                        pageName={ScreeningPageNames.MyScreenings}
                        screeningPath={ScreeningPaths.PublicTrust}
                        userType={'Nominee'}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path={[
                    // '/screening/public-trust/my-contracts/:tab', // TODO: Customer Metrics
                    '/screening/public-trust/my-contracts',
                ]}
                render={(routeProps): JSX.Element => (
                    <CandidatesPage
                        pageName={ScreeningPageNames.MyContracts}
                        screeningPath={ScreeningPaths.PublicTrust}
                        userType={'ContractOwner'}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path={[
                    // '/screening/public-trust/my-nominees/:tab', // TODO: Customer Metrics
                    '/screening/public-trust/my-nominees',
                ]}
                render={(routeProps): JSX.Element => (
                    <CandidatesPage
                        pageName={ScreeningPageNames.MyNominees}
                        screeningPath={ScreeningPaths.PublicTrust}
                        userType={'Nominator'}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path={[
                    // '/screening/public-trust/my-org/:tab', // TODO: Customer Metrics
                    '/screening/public-trust/my-org',
                ]}
                render={(routeProps): JSX.Element => (
                    <CandidatesPage
                        pageName={ScreeningPageNames.MyOrg}
                        screeningPath={ScreeningPaths.PublicTrust}
                        userType={'Manager'}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/public-trust/suitability-records'
                render={(routeProps): JSX.Element => (
                    <CandidatesPage
                        pageName={ScreeningPageNames.SuitabilityRecords}
                        screeningPath={ScreeningPaths.PublicTrust}
                        userType={''}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/public-trust/:id'
                render={(routeProps): JSX.Element => (
                    <ManageCandidatePageCommon
                        screeningPaths={ScreeningPaths.PublicTrust}
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/cloud/requests/:region'
                render={(routeProps): JSX.Element => (
                    <CloudScreeningRequestsTable
                        pageName='Cloud Screening Requests Table'
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/cloud/monthly-report'
                render={(routeProps): JSX.Element => (
                    <CloudScreeningMonthlyReport
                        pageName='Cloud Screening Monthly Report'
                        {...routeProps}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/cloud/:tabSelection?'
                render={(routeProps): JSX.Element => (
                    <CloudScreeningPage pageName='Cloud Screening' {...routeProps} />
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/contracts'
                render={(routeProps): JSX.Element => (
                    <FiltersContractsProvider>
                        <ContractManagementTab pageName='Contracts' {...routeProps} />
                    </FiltersContractsProvider>
                )}
            />
            <ProtectedRoute
                renderCondition={screeningCore.enabled}
                exact
                path='/screening/:id'
                component={UsGovLandingPage}
            />
            <ProtectedRoute
                renderCondition={staffingCore.enabled}
                exact
                path='/staffing'
                render={(routeProps): JSX.Element => (
                    <StaffingLandingPage pageName='Staffing' {...routeProps} />
                )}
            />
            <ProtectedRoute
                renderCondition={staffingCore.enabled}
                exact
                path='/staffing/status'
                render={(): JSX.Element => <StaffingStatusPage />}
            />
            <ProtectedRoute
                renderCondition={staffingCore.enabled}
                exact
                path='/staffing/org'
                render={(): JSX.Element => <Redirect to='/staffing' />}
            />
            <ProtectedRoute
                renderCondition={staffingCore.enabled}
                exact
                path='/staffing/org/:urlOrgName'
                render={(routeProps): JSX.Element => (
                    <StaffingOrgMapPage
                        pageName={'Organization Mapping'}
                        userType={UsGovScreeningUserType.None}
                        {...routeProps}
                    />
                )}
            />

            <ProtectedRoute
                renderCondition={profileUsGov.enabled}
                exact
                path='/profile/us-gov'
                render={(routeProps): JSX.Element => (
                    <PersonnelProfileSearchPage pageName='Search' {...routeProps} />
                )}
            />
            <ProtectedRoute
                renderCondition={profileUsGov.enabled}
                exact
                path='/profile/us-gov/:employeeAliasOrId'
                render={(routeProps): JSX.Element => <PersonnelProfilePage {...routeProps} />}
            />
            <ProtectedRoute
                renderCondition={scaCore.enabled}
                exact
                path='/sca'
                render={(): JSX.Element => <Redirect to='/sca/my-history' />}
            />
            <ProtectedRoute
                renderCondition={scaCore.enabled}
                exact
                path='/sca/my-history'
                render={(): JSX.Element => (
                    <ScaDisplayMyHistory defaultTab={HistoryPageValidTabs.MyHistory} />
                )}
            />
            <ProtectedRoute
                renderCondition={scaCore.enabled}
                exact
                path={['/sca/my-team', '/sca/my-team/:reviewId']}
                render={(): JSX.Element => (
                    <ScaDisplayMyHistory defaultTab={HistoryPageValidTabs.MyTeam} />
                )}
            />
            <ProtectedRoute
                renderCondition={scaCore.enabled}
                exact
                path='/sca/my-org'
                render={(): JSX.Element => (
                    <ScaDisplayMyHistory defaultTab={HistoryPageValidTabs.MyOrg} />
                )}
            />
            <ProtectedRoute
                renderCondition={scaCore.enabled}
                exact
                path='/sca/manage'
                render={(): JSX.Element => <ScaManage />}
            />
            <ProtectedRoute
                renderCondition={scaCore.enabled}
                exact
                path='/sca/manage/:reviewId/employees'
                render={(routeProps): JSX.Element => (
                    <ScaManageViewIdFilterWrapper {...routeProps} />
                )}
            />
            <ProtectedRoute
                renderCondition={scaCore.enabled}
                exact
                path='/sca/manage/:reviewId/employees/:execId'
                render={(routeProps): JSX.Element => (
                    <ScaManageViewIdFilterWrapper {...routeProps} />
                )}
            />
            <ProtectedRoute
                renderCondition={scaCore.enabled}
                exact
                path='/sca/employee-history/:personnelId'
                render={(): JSX.Element => <ScaDisplayEmployeeHistory />}
            />
            <ProtectedRoute
                renderCondition={scaCore.enabled}
                exact
                path='/sca/program-details'
                render={(): JSX.Element => <ScaProgramDetails />}
            />
            <ProtectedRoute
                renderCondition={employeeCore.enabled}
                exact
                path={['/profile', '/profile/user/me']}
                render={(): JSX.Element => <Redirect push={false} to={'/profile/user/me/info'} />}
            />
            <ProtectedRoute
                renderCondition={employeeCore.enabled}
                exact
                path={['/profile/user/me/eligibilities']}
                render={(routeProps): JSX.Element => {
                    return eligibilities.enabled ? (
                        <UserAssignmentsMe {...routeProps} />
                    ) : (
                        <Redirect to={'/groups'} />
                    );
                }}
            />
            <ProtectedRoute
                renderCondition={employeeCore.enabled}
                exact
                path={[
                    '/profile/user/me/info',
                    '/profile/user/me/attributes',
                    '/profile/user/me/equipment',
                ]}
                render={(routeProps): JSX.Element => <UserAssignmentsMe {...routeProps} />}
            />
            <ProtectedRoute
                renderCondition={employeeCore.enabled}
                exact
                path='/access/eligibility/:eligiblityId/request'
                render={(): JSX.Element => {
                    return eligibilities.enabled ? (
                        <ElgibilityRequest />
                    ) : (
                        <Redirect to={'/groups'} />
                    );
                }}
            />
            <ProtectedRoute
                renderCondition={employeeCore.enabled}
                exact
                path='/profile/manage/eligibilities'
                render={(): JSX.Element => {
                    return eligibilities.enabled ? (
                        <ManageEligibilities tab='eligibilities' />
                    ) : (
                        <Redirect to={'/groups'} />
                    );
                }}
            />
            <ProtectedRoute
                renderCondition={employeeCore.enabled}
                exact
                path='/profile/manage/attributes'
                render={(): JSX.Element => <ManageEligibilities tab='attributes' />}
            />
            <ProtectedRoute
                renderCondition={employeeCore.enabled}
                exact
                path='/profile/manage/eligibilities/:id'
                render={(routeProps): JSX.Element => {
                    return eligibilities.enabled ? (
                        <Redirect to={`${routeProps.location.pathname}/details`} />
                    ) : (
                        <Redirect to={'/groups'} />
                    );
                }}
            />
            <ProtectedRoute
                renderCondition={employeeCore.enabled}
                exact
                path='/profile/manage/eligibilities/:id/:tab'
                render={(routeProps): JSX.Element => {
                    return eligibilities.enabled ? (
                        <ViewEligibilityFilterWrapper {...routeProps} />
                    ) : (
                        <Redirect to={'/groups'} />
                    );
                }}
            />
            <ProtectedRoute
                renderCondition={employeeCore.enabled}
                exact
                path={['/profile/user/search']}
                render={(routeProps): JSX.Element => <UserAssignmentsSearch {...routeProps} />}
            />
            <ProtectedRoute
                renderCondition={profileVisitor.enabled}
                exact
                path={['/profile/user/:visitorIdOrAlias/eligibilities']}
                render={(routeProps): JSX.Element => {
                    return eligibilities.enabled ? (
                        <UserAssignmentsEmployeeOrVisitor {...routeProps} />
                    ) : (
                        <Redirect to={'/groups'} />
                    );
                }}
            />
            <ProtectedRoute
                renderCondition={profileVisitor.enabled}
                exact
                path={[
                    '/profile/user/:visitorIdOrAlias/info',
                    '/profile/user/:visitorIdOrAlias/attributes',
                    '/profile/user/:visitorIdOrAlias/equipment',
                ]}
                render={(routeProps): JSX.Element => (
                    <UserAssignmentsEmployeeOrVisitor {...routeProps} />
                )}
            />
            <ProtectedRoute
                renderCondition={profileVisitor.enabled}
                exact
                // This has to come after '/profile/user/search' otherwise
                // the follwing rule will think the string "search" is the
                // parameter :visitorIdOrAlias.
                path='/profile/user/:visitorIdOrAlias'
                // Send it to /profile/user/:visitorIdOrAlias/info
                render={(routeProps): JSX.Element => (
                    <Redirect
                        push={false}
                        to={routeProps.location.pathname.replace(/\/$/, '') + '/info'}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={profileVisitor.enabled}
                exact
                path='/profile/visitor'
                render={(): JSX.Element => <VisitorsWrapper />}
            />

            <ProtectedRoute
                renderCondition={groupsCore.enabled}
                exact
                path='/groups'
                render={(routeProps): JSX.Element => <MyGroupsWrapper {...routeProps} />}
            />

            <ProtectedRoute
                renderCondition={groupsCore.enabled}
                exact
                path='/groups/:groupId/request'
                render={(routeProps): JSX.Element => <RequestToJoinGroup {...routeProps} />}
            />
            <ProtectedRoute
                renderCondition={groupsCore.enabled}
                exact
                path='/groups/manage'
                render={(routeProps): JSX.Element => <ManageGroupsWrapper {...routeProps} />}
            />

            {/*
                        April 20, 2022
                        Begin forwarding of old Angular groups URLs to their new React counterparts.

                        TODO: Should these forwards be temporary or permanent?

                        Angular Path                                React Path
                        =================================           =================================
                        /groups                                     Goto React
                        /groups/active                              /groups
                        /groups/pending                             /groups
                        /groups/manage/{groupID}/violations         /groups/manage/{groupID}/policy
                        /groups/manage/{groupID}/rules              /groups/manage/{groupID}/policy
                        /groups/manage/{groupID}/details            /groups/manage/{groupID}/settings
                        /groups/{groupID}/policycheck               /groups
                        /groups/{groupID}/policycheck/active        /groups
                        */}

            <ProtectedRoute
                renderCondition={groupsCore.enabled}
                exact
                path={['/groups/manage/:groupId/violations', '/groups/manage/:groupId/rules']}
                render={(routeProps): JSX.Element => (
                    <Redirect
                        to={routeProps.location.pathname.replace(
                            /\/violations|\/rules$/,
                            '/policy',
                        )}
                    />
                )}
            />
            <ProtectedRoute
                renderCondition={groupsCore.enabled}
                exact
                path={['/groups/manage/:groupId/details']}
                render={(routeProps): JSX.Element => (
                    <Redirect to={routeProps.location.pathname.replace('/details', '/settings')} />
                )}
            />
            <ProtectedRoute
                renderCondition={groupsCore.enabled}
                exact
                path={[
                    '/groups/active',
                    '/groups/pending',
                    '/groups/:groupId/policycheck',
                    '/groups/:groupId/policycheck/active',
                ]}
                render={(): JSX.Element => <Redirect to='/groups' />}
            />

            {/*
                End forwarding of old Angular URLs to their React counterparts.
                */}

            <ProtectedRoute
                renderCondition={groupsCore.enabled}
                exact
                path={['/groups/manage/:groupId/:tab', '/groups/manage/:groupId']}
                render={(): JSX.Element => <ManageGroupWrapper />}
            />
            <ProtectedRoute
                renderCondition={groupsCore.enabled}
                exact
                path={'/groups/:groupId/agreements/:ruleId/:personnelId?'}
                render={(): JSX.Element => <GroupRuleAgreement />}
            />
            <ProtectedRoute
                renderCondition={emailsCore.enabled}
                exact
                path={['/email']}
                render={(): JSX.Element => <Redirect push={false} to={'/email/search'} />}
            />
            <ProtectedRoute
                renderCondition={emailsCore.enabled}
                exact
                path={['/email/:tab', '/email/:tab/:id']}
                render={(): JSX.Element => <EmailDashboard />}
            />
            <ProtectedRoute
                renderCondition={true}
                exact
                path={['/forms/respond/:name', '/forms/respond/:name/:id']}
                render={(): JSX.Element => <FormsViewerDataLoader />}
            />
            <ProtectedRoute
                renderCondition={true}
                exact
                path={['/forms/review/:name/:id']}
                render={(): JSX.Element => <FormsReviewerDataLoader />}
            />
            <ProtectedRoute
                renderCondition={true}
                exact
                path={['/forms/edit', '/forms/edit/:name', '/forms/edit/:name/preview']}
                render={(): JSX.Element => <FormsEditDataLoader />}
            />
            <ProtectedRoute
                renderCondition={true}
                exact
                path={['/forms/:tab']}
                render={(): JSX.Element => <Forms />}
            />
            <ProtectedRoute
                renderCondition={true}
                exact
                path={['/forms']}
                render={(): JSX.Element => <Redirect push={false} to={'/forms/available-forms'} />}
            />
            <Route
                exact
                path='/profile/attributesets'
                render={(): JSX.Element => <AttributeSetsList />}
            />
            <Route
                exact
                path='/profile/attributesets/:attributeSetId/attributes'
                render={(): JSX.Element => <AttributesList />}
            />
            <Route
                exact
                path='/profile/attributes/:attributeId'
                render={(): JSX.Element => <AttributeAssignmentsPage />}
            />
            <Route
                exact
                path='/profile/attributes/user/search'
                render={(): JSX.Element => <UserSearchPage />}
            />
            <Route
                exact
                path='/profile/attributes/user/me'
                render={(): JSX.Element => <MyAssignmentsPage />}
            />
            <Route
                exact
                path='/profile/attributes/user/:id'
                render={(): JSX.Element => <UserAssignmentsPage />}
            />
            <Route exact path={home} render={(): JSX.Element => <Home />} />
            <Route exact path={['/support']} render={(): JSX.Element => <ContactUs />} />
            <Route exact path={signedin} render={(): JSX.Element => <SignedIn />} />
            <Redirect push={false} to={home} />
        </Switch>
    );
}

interface ProtectedRouteProps {
    renderCondition: boolean;
    exact: boolean;
    path: string | string[];
    render?: (routeProps?: any) => JSX.Element;
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

function ProtectedRoute(props: ProtectedRouteProps): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { renderCondition, exact, path, render, component } = props;
    if (!renderCondition) {
        return <Redirect push={false} to={home} />;
    }
    return <Route exact={exact} path={path} render={render} component={component} />;
}
