/**
 * File Name: view-requested-document-modal
 * Team: Personnel - Screening Team
 * Summary: This button and modal is used when the User views a 'Request document' in the
 * Document table row of Screening Details page.
 * Use Cases:
 * 1) Allows NST to  Delete Document Request
 * 2) Allows Screening's User to view Request Document upload to the file
 * Last Updated Summary: 10/7/2022 by andreli
 **/
import React, { useContext, MutableRefObject, useRef, useState, useEffect } from 'react';
import {
    ActionButton,
    Label,
    DefaultButton,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import TemplateClient, { IDocumentMetadata } from 'clients/template-client';
import { IAuthContext, AuthContext } from 'contexts/auth-context';
import PublicTrustDocumentClient from 'clients/screening/public-trust-document-client';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import UploadDocumentModal, {
    UploadDocumentModalProps,
} from 'components/screening/common/documents/modals/upload-document-modal';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import { IDocumentRecord } from 'clients/documents-client';

interface ViewRequestedDocumentModalProps {
    documentMetadata?: IDocumentMetadata;
    documentRequestId?: string;
    documentId?: string;
    referenceId?: string;
    file?: File;
    userTypes?: string[];
    nomineeId: string;
    title: string;
    iconName: string;
    isUploadingTemplateRequest: boolean;
    screeningPath: ScreeningPaths;
    documentTypesMap?: Map<string, string>;
    onRequestRemove?: () => void;
    updateUploadedDocumentsPanel?: (doc: IDocumentRecord) => void;
}

export function ViewRequestedDocumentModal(props: ViewRequestedDocumentModalProps): JSX.Element {
    const isDocumentStorageFlag = useFeatureFlag(FeatureFlagKeys.documentStorage).enabled;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [showDownloadError, setShowDownloadError] = useState<boolean>(false);
    const [documentMetadata, setDocumentMetadata] = useState<IDocumentMetadata | undefined>(
        props.documentMetadata,
    );
    const [documentMetadataError, setDocumentMetadataError] = useState<string>();
    const authContext: IAuthContext = useContext(AuthContext);
    const downloadLink: MutableRefObject<null> = useRef(null);

    useEffect(() => {
        async function getDocumentMetadata(): Promise<void> {
            const documentIdSet = new Set<string>();
            const docId = props.documentId;
            if (docId) {
                documentIdSet.add(docId);
            }
            try {
                let res: IDocumentMetadata[] = [];
                if (props.screeningPath === ScreeningPaths.UsGov) {
                    res = await TemplateClient.getDocumentMetadataFromDocumentIds(
                        authContext,
                        documentIdSet,
                    );
                } else {
                    res = await PublicTrustDocumentClient.getDocumentMetadataFromDocumentIds(
                        authContext,
                        documentIdSet,
                    );
                }

                setDocumentMetadata(res.pop());
            } catch (err) {
                setDocumentMetadataError(
                    `Error getting document Metadata from  document id [${props.documentId}] -- error ---${err}`,
                );
            }
        }

        if (!documentMetadata && props.documentId) {
            getDocumentMetadata();
        }
    }, []);

    // this code is to make sure documentMetadata is set every time when there's props data
    // instead of setting documentMetadata once only during initial render
    useEffect(() => {
        setDocumentMetadata(props.documentMetadata);
    }, [props.documentMetadata]);

    // TODO: in the document refactor to combine both publictrust/ us gov
    // add a try catch block
    async function download(): Promise<void> {
        try {
            setIsDownloading(true);
            if (downloadLink?.current && (documentMetadata?.documentId || props.file)) {
                let blobData;
                if (!props.file && documentMetadata?.documentId) {
                    let res: Response;
                    if (props.screeningPath === ScreeningPaths.UsGov) {
                        res = await TemplateClient.getDocument(
                            authContext,
                            documentMetadata.documentId,
                        );
                    } else {
                        res = await PublicTrustDocumentClient.getDocument(
                            authContext,
                            documentMetadata.documentId,
                        );
                    }
                    blobData = await res.blob();
                } else if (props.file) {
                    blobData = new Blob([await props.file.arrayBuffer()], {
                        type: props.file.type,
                    });
                }
                if (blobData) {
                    initiateBrowserDownload(blobData);
                    setShowDownloadError(false);
                } else {
                    setShowDownloadError(true);
                }
            }
            setIsDownloading(false);
        } catch (error) {
            console.error(error, 'Fail to download');
        }
    }

    function initiateBrowserDownload(blobData: Blob): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const downloadLinkRef: HTMLAnchorElement = downloadLink.current as any;
        const url: string = window.webkitURL.createObjectURL(blobData);
        downloadLinkRef.href = url;
        downloadLinkRef.download = documentMetadata?.title || '';
        downloadLinkRef.click();
        downloadLinkRef.href = '';
        downloadLinkRef.download = '';
    }

    const modalProps: UploadDocumentModalProps = React.useMemo(
        () => ({
            isOpen: isOpen,
            isUploadingTemplateRequest: props.isUploadingTemplateRequest,
            onCancel: (): void => setIsOpen(false),
            onRemove: (): void => {
                setIsOpen(false);
                if (props.onRequestRemove) props.onRequestRemove();
            },
            // TODO: in the document refactor to combine both publictrust/ us gov
            // add try catch block
            onComplete: async (doc: IDocumentRecord): Promise<void> => {
                if (props.updateUploadedDocumentsPanel) props.updateUploadedDocumentsPanel(doc);
            },
            documentMetadata: documentMetadata,
            referenceId: props.referenceId,
            documentRequestId: props.documentRequestId,
            title: `Request for ${documentMetadata?.title}`,
            targetFileName: documentMetadata?.title,
            userTypes: props.userTypes,
            screeningPath: props.screeningPath,
            nomineeId: props.nomineeId,
        }),
        [isOpen, props.documentMetadata, props.referenceId, setIsOpen],
    );

    return documentMetadata ? (
        <>
            <ActionButton
                onClick={(): void => setIsOpen(true)}
                iconProps={{ iconName: `${props.iconName}` }}>
                {props.title}
            </ActionButton>
            <UploadDocumentModal {...modalProps}>
                <div className={styles.grid}>
                    {documentMetadataError && (
                        <MessageBar
                            className={styles.messageBar}
                            // TODO: in the document refactor to combine both publictrust/ us gov - spelling mistake below
                            messageBarType={MessageBarType.severeWarning}>
                            {documentMetadataError}
                        </MessageBar>
                    )}

                    {isDocumentStorageFlag && (
                        <>
                            <Label>Type</Label>
                            <span className={styles.value}>
                                {documentMetadata.documentType
                                    ? props.documentTypesMap?.get(documentMetadata.documentType)
                                    : documentMetadata?.documentType ?? ''}
                            </span>
                        </>
                    )}

                    <Label>Title</Label>
                    <span className={styles.value}>{documentMetadata?.title}</span>

                    <Label>Notes</Label>
                    <span className={styles.value}>
                        {documentMetadata?.notes ||
                            'Please download and fill out the provided template. Save and upload your completed form.'}
                    </span>

                    {showDownloadError && (
                        <div className={styles.messageBar}>
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                onDismiss={(): void => setShowDownloadError(false)}
                                dismissButtonAriaLabel='Close'>
                                Error getting file for download.
                            </MessageBar>
                        </div>
                    )}

                    <Label>Template</Label>
                    {documentMetadata.hasFile ? (
                        <>
                            <DefaultButton
                                className={styles.button}
                                iconProps={{ iconName: 'Download' }}
                                onClick={download}
                                disabled={isDownloading}>
                                {isDownloading ? 'Downloading...' : 'Download'}
                            </DefaultButton>
                            <a ref={downloadLink} hidden={true} />
                        </>
                    ) : (
                        <span className={styles.value}>No template available</span>
                    )}
                </div>
            </UploadDocumentModal>
        </>
    ) : (
        <></>
    );
}

const styles = mergeStyleSets({
    description: {
        marginTop: '0',
        marginBottom: '20px',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '120px auto',
        gridGap: '0.5em',
    },
    value: {
        paddingTop: '5px',
    },
    button: {
        width: '140px',
    },
    messageBar: {
        gridColumnStart: '2',
    },
});
