import { mergeStyleSets, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CloudScreeningClient, {
    CloudScreeningRegion,
    ICloudScreening,
} from 'clients/cloud-screening-client';
import { CloudScreeningUserType } from 'utils/cloud-screening-utils';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { generalIsMountedCode, useQuery } from 'utils/misc-utils';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import {
    EmployeeNameResolverId,
    getNameFromPersonnelId,
} from 'components/common/employee/employee-name-resolver';

type UrlParams = {
    region: string;
};

type ExcelRecordType = {
    employeeId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    emailAddress: string;
    alias: string;
    country: string;
    divisionLeader: string;
};

export interface CloudScreeningRequestsTableProps {
    pageName: string;
}

export function CloudScreeningRequestsTable(props: CloudScreeningRequestsTableProps): JSX.Element {
    const { region } = useParams() as UrlParams;
    const query = useQuery();

    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [regionParam, setRegionParam] = useState<keyof typeof CloudScreeningRegion>();
    const [dateParam, setDateParam] = useState<Date>();
    const [regionalScreenings, setRegionalScreenings] = useState<ICloudScreening[]>();
    const [errorMessage, setErrorMessage] = useState<string>();

    function validateDateURLParams(): Date | undefined {
        const monthParam = query.get('month'),
            dayParam = query.get('day'),
            yearParam = query.get('year');
        if (!monthParam || !dayParam || !yearParam) {
            setErrorMessage('Date parameters are missing from URL.');
            return;
        }

        const month = +monthParam,
            day = +dayParam,
            year = +yearParam;
        if (isNaN(month) || isNaN(day) || isNaN(year)) {
            setErrorMessage('Invalid date parameter present in URL.');
            return;
        }

        return new Date(year, month - 1, day, 0, 0, 0, 0);
    }

    function validateRegionURLParam(): string | undefined {
        if (!region) {
            setErrorMessage('Region parameter is missing from URL.');
            return;
        }
        const regionParamUpper = region.toUpperCase();
        const isRegionValid = Object.values(CloudScreeningRegion).some(
            (regionEnumValue) => regionEnumValue === regionParamUpper,
        );
        if (!isRegionValid) {
            setErrorMessage('Invalid region parameter present in URL.');
            return;
        }

        return regionParamUpper;
    }

    async function populateRegionalScreenings(isMountedFunc: () => boolean): Promise<void> {
        if (regionParam && dateParam) {
            let continuationToken: string | undefined;
            const screenings: ICloudScreening[] = [];
            do {
                const results = await CloudScreeningClient.getRequestedScreeningsByRegionAndDate(
                    authContext,
                    regionParam,
                    dateParam,
                    continuationToken,
                );
                continuationToken = results.continuationToken;
                // Filter out non-CN/HK/IN/TW records if 'filter' is set in URL, example:
                // /APAC?month=10&day=7&year=2023&filter
                // Applies only to APAC region
                if (results.results) {
                    const hasFilter = query.has('filter');
                    const isAPAC = regionParam === 'APAC';
                    const isFilteredOnAPAC = hasFilter && isAPAC;
                    results.results
                        .filter((x) =>
                            isFilteredOnAPAC ? ['CN', 'HK', 'IN', 'TW'].includes(x.country) : true,
                        )
                        .forEach((x) => screenings.push(x));
                }
            } while (continuationToken && isMountedFunc());

            if (isMountedFunc()) {
                setRegionalScreenings(screenings);
            }
        }
    }

    useEffect(() => {
        return generalIsMountedCode(populateRegionalScreenings);
    }, [regionParam, dateParam]);

    useEffect(() => {
        function setupParameters(): void {
            const region = validateRegionURLParam();
            if (!region) return;
            setRegionParam(region as keyof typeof CloudScreeningRegion);
            const date = validateDateURLParams();
            if (!date) return;
            setDateParam(date);
        }

        setupParameters();
    }, []);

    const asyncMapRecordsToExcel = async (): Promise<ExcelRecordType[]> => {
        return await Promise.all(
            (regionalScreenings ?? []).map(
                async (screen): Promise<ExcelRecordType> => {
                    const orgLeaderName = await getNameFromPersonnelId(
                        authContext,
                        screen.orgLeaderId,
                    );

                    return {
                        employeeId: screen.personnelId,
                        firstName: screen.firstName,
                        lastName: screen.lastName,
                        fullName: `${screen.firstName} ${screen.lastName}`,
                        emailAddress: `${screen.personnelAlias}@microsoft.com`,
                        alias: screen.personnelAlias,
                        country: screen.country,
                        divisionLeader: orgLeaderName,
                    };
                },
            ),
        );
    };

    return userContext.hasCloudScreeningUserType(CloudScreeningUserType.CloudSRT) ||
        userContext.hasCloudScreeningUserType(CloudScreeningUserType.Admin) ? (
        <>
            {errorMessage && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    overflowButtonAriaLabel='See more'>
                    {errorMessage}
                </MessageBar>
            )}
            {regionParam && dateParam && (
                <Stack className={styles.mainContainer}>
                    <Stack horizontal style={{ justifyContent: 'space-between' }}>
                        <Stack.Item>
                            <h2>Cloud Screening Requests</h2>
                        </Stack.Item>

                        <Stack.Item align={'center'}>
                            {regionalScreenings && (
                                <ExportToExcelButton<ExcelRecordType>
                                    getData={asyncMapRecordsToExcel}
                                    fileNamePrefix={'cloud-screening-requests'}
                                    formatHeader={true}
                                />
                            )}
                        </Stack.Item>
                    </Stack>

                    {regionalScreenings && (
                        <div>
                            <Stack.Item>
                                <div>
                                    <table className={styles.tableStyle}>
                                        <thead>
                                            <tr>
                                                <th>Employee Id</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Full Name</th>
                                                <th>Email Address</th>
                                                <th>Alias</th>
                                                <th>Country</th>
                                                <th>Division Leader</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {regionalScreenings.map(
                                                (screening, i): JSX.Element => {
                                                    return (
                                                        <tr key={`table_content_${i}`}>
                                                            <td>{screening.personnelId}</td>
                                                            <td>{screening.firstName}</td>
                                                            <td>{screening.lastName}</td>
                                                            <td>{`${screening.firstName} ${screening.lastName}`}</td>
                                                            <td>
                                                                <a
                                                                    href={`mailto:${screening.personnelAlias}@microsoft.com`}>
                                                                    {`${screening.personnelAlias}@microsoft.com`}
                                                                </a>
                                                            </td>
                                                            <td>{screening.personnelAlias}</td>
                                                            <td>{screening.country}</td>
                                                            <td>
                                                                <EmployeeNameResolverId
                                                                    personnelId={
                                                                        screening.orgLeaderId
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                },
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Stack.Item>
                        </div>
                    )}

                    <Stack.Item>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Region</td>
                                    <td>{regionParam}</td>
                                </tr>
                                <tr>
                                    <td>Date</td>
                                    <td>{dateParam?.toLocaleDateString() ?? ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Stack.Item>
                </Stack>
            )}
        </>
    ) : (
        <></>
    );
}

const styles = mergeStyleSets({
    tableStyle: {
        borderCollapse: 'collapse',
        border: '1px solid black',
        width: '100%',
        selectors: {
            'th,td': {
                border: '1px solid black',
                padding: '5px',
            },
            'th': {
                backgroundColor: '#000066',
                color: 'white',
            },
        },
    },
    mainContainer: {
        padding: '0 10px 0 10px',
    },
});
