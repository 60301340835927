import {
    Checkbox,
    Dropdown,
    IDropdownOption,
    IPersonaProps,
    mergeStyleSets,
    Separator,
    Stack,
    TextField,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import { globalFilterSeparatorStyles, globalStyles } from 'assets/styles/global-styles';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import {
    CorePrincipalsClient,
    obtainPrincipalRecords,
} from 'clients/core/personnel-core-client-wrappers';
import GroupClient, {
    DayOfWeek,
    Division,
    GroupType,
    IGroup,
    PrimaryCategorization,
    SensitivityLevel,
} from 'clients/group-client';
import ModalActionBotton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import {
    CoreMultiPrincipalPersonaPickerTypeaheadSearch,
    CoreSinglePrincipalIdPickerTypeaheadSearch,
} from 'components/common/core-employee-picker-typeahead-search';
import {
    distinctPersonaArray,
    EmployeeListPickerUnlimited,
} from 'components/common/employee-list-picker';
import { ModalSizeType } from 'components/common/modal';
import Spacer from 'components/common/spacer';
import LabelInfoIcon from 'components/common/use-input/info-icon-label';
import { useDropdown } from 'components/common/use-input/use-dropdown';
import { ManageGroupsVariableContext } from 'components/groups/manage-groups/contexts/manage-groups-variable-context';
import { AuthContext } from 'contexts/auth-context';
import config from 'environments/environment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SetStateFunc } from 'types/global-types';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

const DUPLICATE_NAME_MSG = 'A group by that name already exists';
const TRUE_AS_STRING = '1';
const MINIMUM_OWNERS = 2;

export default function CreateGroupModalActionButton(): JSX.Element {
    const variableContext = useContext(ManageGroupsVariableContext);
    const authContext = useContext(AuthContext);
    const fteAttributeId = config.groupServiceConfig.principalRecordAttributeIdMap.fteId;

    const emailsCore = useFeatureFlag(FeatureFlagKeys.emailsCore);
    const groupsMetadata = useFeatureFlag(FeatureFlagKeys.groupsMetaDataSection);
    const shouldRequire2Ftes = useFeatureFlag(FeatureFlagKeys.groupsMin2FteOwners).enabled;
    const isFeatureFlagDynamicGroupEnabled = useFeatureFlag(
        FeatureFlagKeys.groupsEnableDynamicGroup,
    ).enabled;

    const [name, setName] = useState<string>('');
    const [isDuplicateName, setIsDuplicateName] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');
    const [blockList, setBlockList] = useState<IPersonaProps[]>([]);
    const [ownerPersonas, setOwnerPersonas] = useState<IPersonaProps[]>([]);
    const [owners, setOwners] = useState<IPrincipalRecord[]>([]);
    const [fteOwners, setFteOwners] = useState<string[]>([]);

    const [isRequireSponsor, setRequireSponsor] = useState<boolean>(false);
    const [isRequireJustification, setRequireJustification] = useState<boolean>(false);
    const [isEnableMemberLeaveJustification, setEnableMemberLeaveJustification] = useState<boolean>(
        false,
    );
    const [isAllowJoin, setAllowJoin] = useState<boolean>(false);
    const [isEnableHidden, setEnableHidden] = useState<boolean>(false);
    const [isEnableDynamic, setEnableDynamic] = useState<boolean>(false);
    const [isAllowMemberNomination, setAllowMemberNomination] = useState<boolean>(false);
    const [isAutoApproveJoin, setAutoApproveJoin] = useState<boolean>(false);
    const [isEnableWelcomeMessage, setEnableWelcomeMessage] = useState<boolean>(false);
    const [isEnableOffboardMessage, setEnableOffboardMessage] = useState<boolean>(false);
    const [
        isEnableMemberNoncomplianceNotification,
        setEnableMemberNoncomplianceNotification,
    ] = useState<boolean>(false);
    const [isEnableMemberRoleNotification, setEnableMemberRoleNotification] = useState<boolean>(
        false,
    );

    const [agcApprovedByEmployee, setAGCApprovedByEmployee] = useState<IPrincipalRecord>();
    const [cvpApprovedByEmployee, setCVPApprovedByEmployee] = useState<IPrincipalRecord>();

    const isNonExternalEnabled = useFeatureFlag(FeatureFlagKeys.enableNonExternalComponents)
        .enabled;

    const checkboxList: CheckboxListType = [
        {
            text: 'Hide group from members',
            initValue: false,
            variable: isEnableHidden,
            setFunction: setEnableHidden,
        },
        {
            text: 'Set group as dynamic',
            initValue: false,
            variable: isEnableDynamic,
            setFunction: setEnableDynamic,
            featureFlag: [FeatureFlagKeys.groupsEnableDynamicGroup],
        },
        {
            text: 'Require sponsor for members',
            initValue: true,
            variable: isRequireSponsor,
            setFunction: setRequireSponsor,
            disabled: isEnableDynamic,
        },
        {
            text: 'Require business justification for members to join',
            initValue: true,
            variable: isRequireJustification,
            setFunction: setRequireJustification,
            disabled: isEnableDynamic,
        },
        {
            text: 'Require business justification for member removal',
            initValue: false,
            variable: isEnableMemberLeaveJustification,
            setFunction: setEnableMemberLeaveJustification,
            disabled: isEnableDynamic,
        },
        {
            text: 'Allow join requests',
            initValue: false,
            variable: isAllowJoin,
            setFunction: setAllowJoin,
            disabled: isEnableHidden || isEnableDynamic,
        },
        {
            text: 'Allow member nominations',
            initValue: false,
            variable: isAllowMemberNomination,
            setFunction: setAllowMemberNomination,
            disabled: isEnableHidden || isEnableDynamic,
        },
        {
            text: 'Auto-approve join requests if compliant',
            initValue: false,
            variable: isAutoApproveJoin,
            setFunction: setAutoApproveJoin,
            disabled: isEnableHidden || isEnableDynamic,
        },
        {
            text: 'Enable welcome messages',
            variable: isEnableWelcomeMessage,
            initValue: false,
            setFunction: setEnableWelcomeMessage,
            featureFlag: [FeatureFlagKeys.emailsCore, FeatureFlagKeys.enableNonExternalComponents],
            disabled: isEnableHidden,
        },
        {
            text: 'Enable offboard messages',
            variable: isEnableOffboardMessage,
            initValue: false,
            setFunction: setEnableOffboardMessage,
            featureFlag: [FeatureFlagKeys.emailsCore, FeatureFlagKeys.enableNonExternalComponents],
            disabled: isEnableHidden,
        },
        {
            text: 'Enable member role change notifications',
            variable: isEnableMemberRoleNotification,
            initValue: true,
            setFunction: setEnableMemberRoleNotification,
            featureFlag: [FeatureFlagKeys.enableNonExternalComponents],
        },
        {
            text: 'Enable member non-compliance notifications',
            variable: isEnableMemberNoncomplianceNotification,
            initValue: true,
            setFunction: setEnableMemberNoncomplianceNotification,
            featureFlag: [FeatureFlagKeys.emailsCore, FeatureFlagKeys.enableNonExternalComponents],
            disabled: isEnableHidden,
        },
    ];

    const filteredCheckboxList = useMemo(() => {
        return checkboxList.filter((item) => {
            const isAllFlagsEnabled =
                item.featureFlag?.every((flag) => {
                    switch (flag) {
                        case FeatureFlagKeys.groupsEnableDynamicGroup:
                            return isFeatureFlagDynamicGroupEnabled;
                        case FeatureFlagKeys.emailsCore:
                            return emailsCore.enabled;
                        case FeatureFlagKeys.enableNonExternalComponents:
                            return isNonExternalEnabled;
                        default:
                            return true;
                    }
                }) ?? true;

            return isAllFlagsEnabled;
        });
    }, [emailsCore.enabled, checkboxList]);

    const [notificationDays, setNotificationDays] = useState<Map<string, boolean>>(
        initNotificationDays,
    );

    const groupTypeOptions = (): IDropdownOption[] => {
        return Object.entries(GroupType)
            .filter(([key]) => key !== GroupType.Project.valueOf())
            .map(([key, val]) => {
                return { key: key, text: val };
            });
    };

    const {
        selectedOption: selectedGroupType,
        theElement: GroupTypeDropdownElement,
        initializeFromKey: initSelectedGroupType,
    } = useDropdown({
        onRenderLabel: (): JSX.Element => {
            return (
                <LabelInfoIcon
                    iconHoverContent={
                        <ul className={styles.hoverCardList}>
                            <li>Tent: to secure tented info</li>
                            <li>Confidential Project: to secure confidential info</li>
                            <li>Group: to give a specific person/team access to resources</li>
                        </ul>
                    }
                    iconName={IconNames.Info}>
                    Group Type
                </LabelInfoIcon>
            );
        },
        options: groupTypeOptions(),
        placeholder: 'Select group type',
        styles: { dropdownItems: globalStyles.dropdownItems },
        ariaLabel: 'Group Type',
    });

    const sensitivityLevelOptions = (): IDropdownOption[] => {
        return Object.entries(SensitivityLevel).map(([key, val]) => {
            return { key: key, text: val };
        });
    };

    const {
        selectedOption: selectedSensitityLevel,
        theElement: SensitivityLevelDropdownElement,
        initializeFromKey: initSelectedSensitivityLevel,
    } = useDropdown({
        onRenderLabel: (): JSX.Element => {
            return (
                <LabelInfoIcon
                    iconHoverContent={
                        <a
                            href='https://microsoft.sharepoint.com/sites/MSProtect/SitePages/Data-Classification-Labels.aspx?xsdata=MDV8MDF8fDhmMThlNDgzYjU5MjRkZjFiNzM5MDhkYTFkOWU4NzRlfDcyZjk4OGJmODZmMTQxYWY5MWFiMmQ3Y2QwMTFkYjQ3fDB8MHw2Mzc4NTQ4NjM5Mzg0Njc2NDF8R29vZHxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxNVGs2TkRGaU5HWTNNelV0TjJKalppMDBNamsyTFRnMk1qUXRZV1JtTUdGa1lUZzFaRFl5WDJKbE1UVXdabVZqTFRBeE1UWXROREJsWkMwNE1ERXlMV1l5TkdRNE9URTBaV1JtWmtCMWJuRXVaMkpzTG5Od1lXTmxjdz09fHw%3D&sdata=dU1RSVUyTFZIMnRKbWhUR3FxM09zRjRJM0NzSzh6YVoreDl2SGpESWVkMD0%3D&ovuser=72f988bf-86f1-41af-91ab-2d7cd011db47%2csinghda%40microsoft.com&OR=Teams-HL&CT=1649889759598&params=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjA0MDExMTQwMCJ9'
                            target='_blank'
                            rel='noreferrer'>
                            Data Classification Label
                        </a>
                    }
                    iconName={IconNames.Info}>
                    Sensitivity Level
                </LabelInfoIcon>
            );
        },
        options: sensitivityLevelOptions(),
        placeholder: 'Select sensitivity level',
        styles: { dropdownItems: globalStyles.dropdownItems },
        ariaLabel: 'Sensitivity Level',
    });

    const primaryCategorizationOptions = (): IDropdownOption[] => {
        return Object.entries(PrimaryCategorization).map(([key, val]) => {
            return { key: key, text: val };
        });
    };

    const {
        selectedOption: selectedPrimaryCategorization,
        theElement: primaryCategorizationlDropdownElement,
        initializeFromKey: initPrimaryCategorization,
    } = useDropdown({
        onRenderLabel: (): JSX.Element => {
            return (
                <LabelInfoIcon
                    required={[GroupType.Tent, GroupType.ConfidentialProject].includes(
                        selectedGroupType?.text as GroupType,
                    )}
                    iconHoverContent={
                        <ul className={styles.hoverCardList}>
                            <li>
                                <a
                                    href='https://microsoft.sharepoint.com/sites/mspolicy/SitePages/PolicyProcedure.aspx?policyprocedureid=MSProcedure-2052'
                                    target='_blank'
                                    rel='noreferrer'>
                                    US Government
                                </a>
                                : classified cloud, contracts with USG, etc
                            </li>
                            <li>
                                <a
                                    href='https://microsoft.sharepoint.com/sites/mspolicy/SitePages/PolicyProcedure.aspx?policyprocedureid=MSProcedure-2052'
                                    target='_blank'
                                    rel='noreferrer'>
                                    International Government
                                </a>
                                : non-US contractual relationships with governments
                            </li>
                            <li>
                                Hardware, Device, Gaming, or Confidential Intellectual Property:
                                Surface, Xbox, Quantum, etc
                            </li>
                            <li>Critical Infrastructure: power grids, 5G, etc</li>
                            <li>Other: none of the above</li>
                        </ul>
                    }
                    iconName={IconNames.Info}>
                    Primary Categorization
                </LabelInfoIcon>
            );
        },
        options: primaryCategorizationOptions(),
        placeholder: 'Select primary categorization',
        styles: { dropdownItems: globalStyles.dropdownItems },
        ariaLabel: 'Primary Categorization',
    });

    const divisionOptions = (): IDropdownOption[] => {
        return Object.entries(Division).map(([key, val]) => {
            return { key: key, text: val };
        });
    };

    const {
        selectedOption: selectedDivision,
        theElement: divisionDropdownElement,
        initializeFromKey: initDivision,
    } = useDropdown({
        label: 'Division',
        options: divisionOptions(),
        placeholder: 'Select a division',
        required: [GroupType.Tent, GroupType.ConfidentialProject].includes(
            selectedGroupType?.text as GroupType,
        ),
        styles: { dropdownItems: globalStyles.dropdownItems },
    });

    const onCVPApprovedBySelect = (info?: IPrincipalRecord): void => {
        setCVPApprovedByEmployee(info);
    };

    const onAGCApprovedBySelect = (info?: IPrincipalRecord): void => {
        setAGCApprovedByEmployee(info);
    };

    type CheckboxListType = {
        text: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        initValue: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        variable: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFunction: SetStateFunc<any>;
        featureFlag?: string[];
        disabled?: boolean;
    }[];

    const notificationOptions = useMemo(() => {
        return Object.values(DayOfWeek).map((dayOfWeek) => {
            return {
                key: dayOfWeek,
                text: dayOfWeek,
                isSelected: notificationDays.get(dayOfWeek),
            };
        });
    }, [notificationDays]);

    const selectedNotificationDays = (): string[] => {
        return Array.from(notificationDays)
            .filter(([key, value]) => value)
            .map(([key]) => key);
    };

    const checkboxChangeHandler = (checked: boolean, setFunction: SetStateFunc<any>): void => {
        setFunction(!!checked);
    };

    const onButtonClick = (): void => {
        setName('');
        setIsDuplicateName(false);
        setDescription('');
        setBlockList([]);
        filteredCheckboxList.forEach((checkbox) => {
            checkbox.setFunction(checkbox.initValue);
        });
        setNotificationDays(initNotificationDays);
        initSelectedGroupType(undefined);
        initSelectedSensitivityLevel(undefined);
        initPrimaryCategorization(undefined);
        initDivision(undefined);
        setAGCApprovedByEmployee(undefined);
        setCVPApprovedByEmployee(undefined);
    };

    const isPrimaryCategorizationAndDivisionValid = (): boolean => {
        // Primary categorization is required on if type is Tent or Confidential.
        // So is Division.
        return (
            ![GroupType.Tent, GroupType.ConfidentialProject].includes(
                selectedGroupType?.text as GroupType,
            ) ||
            (!!selectedPrimaryCategorization && !!selectedDivision)
        );
    };

    const isCvpAndAgcValid = (): boolean => {
        // CVP and AGC are required only if type is Tent.
        return (
            GroupType.Tent !== selectedGroupType?.text ||
            (!!cvpApprovedByEmployee && !!agcApprovedByEmployee)
        );
    };

    const trimGroupName = useCallback((str: string): string => {
        return str.trim().replace(/\s+/g, ' ');
    }, []);

    const validateGroupName = (): string => {
        const isDuplicateNameVar = !!variableContext.groups.find(
            (g) =>
                trimGroupName(g.name).toLocaleLowerCase() ===
                trimGroupName(name).toLocaleLowerCase(),
        );
        setIsDuplicateName(isDuplicateNameVar);
        return isDuplicateNameVar ? DUPLICATE_NAME_MSG : '';
    };

    const validateOwners = useCallback((): boolean => {
        // Valid when there is at least two owners.
        if (shouldRequire2Ftes) {
            return fteOwners.length >= MINIMUM_OWNERS;
        }
        return owners.length >= MINIMUM_OWNERS;
    }, [fteOwners, owners]);

    useEffect(() => {
        const fetchData = async () => {
            const principalClient = new CorePrincipalsClient(authContext);
            const principalIds = owners.map((owner) => owner.id);
            const attributeIds = [fteAttributeId];

            const assignmentResponses = await obtainPrincipalRecords({
                principalClient: principalClient,
                principalIds: principalIds,
                attributeIds: attributeIds,
            });

            setFteOwners(
                assignmentResponses
                    .filter((assignments) => assignments.assignments[0].value === TRUE_AS_STRING)
                    .map((assignments) => assignments.id),
            );
        };

        // Limiting calling this function unless there are MINIMUM_OWNERS+ owners to check
        if (shouldRequire2Ftes && owners.length >= MINIMUM_OWNERS) {
            fetchData();
        } else {
            setFteOwners([]);
        }
    }, [owners]);

    const isSubmitEnabled = (): boolean => {
        const isEnable1 = !!name && !isDuplicateName && !!description && validateOwners();
        const isEnable2 =
            !groupsMetadata.enabled ||
            (isPrimaryCategorizationAndDivisionValid() && isCvpAndAgcValid());

        return isEnable1 && isEnable2;
    };

    const onClickCreate = async (): Promise<IGroup> => {
        setName(trimGroupName(name));
        try {
            return await GroupClient.createGroup(authContext, {
                name: trimGroupName(name),
                description,
                groupType: selectedGroupType?.key as keyof typeof GroupType,
                requireJustification: isRequireJustification,
                requireSponsor: isRequireSponsor,
                allowJoin: isAllowJoin,
                allowMemberNomination: isAllowMemberNomination,
                autoApproveJoin: isAutoApproveJoin,
                enableMemberNoncomplianceNotification: isEnableMemberNoncomplianceNotification,
                enableMemberRoleNotification: isEnableMemberRoleNotification,
                enableWelcomeMessage: isEnableWelcomeMessage,
                rules: [],
                enableHidden: isEnableHidden,
                enableDynamic: isEnableDynamic,
                ownerList: ownerPersonas
                    .map((person) => {
                        const employee = JSON.parse(person.itemProp ?? '{ }');
                        return employee.id;
                    })
                    .filter((id) => !!id),
                blockList: blockList
                    .map((person) => {
                        const employee = JSON.parse(person.itemProp ?? '{ }');
                        return employee.id;
                    })
                    .filter((id) => !!id),
                enableMemberLeaveJustification: isEnableMemberLeaveJustification,
                enableOffboardMessage: isEnableOffboardMessage,
                memberNonComplianceNotificationDays: selectedNotificationDays(),
                sensitivityLevel: selectedSensitityLevel?.key as keyof typeof SensitivityLevel,
                primaryCategorization: selectedPrimaryCategorization
                    ? (selectedPrimaryCategorization.key as keyof typeof PrimaryCategorization)
                    : undefined,
                division: selectedDivision
                    ? (selectedDivision.key as keyof typeof Division)
                    : undefined,
                cvpApprovedBy: cvpApprovedByEmployee?.id ? cvpApprovedByEmployee.id : undefined,
                agcApprovedBy: agcApprovedByEmployee?.id ? agcApprovedByEmployee.id : undefined,
            });
        } catch (e) {
            console.error(e);
            throw 'Encountered error while trying to create the group';
        }
    };

    const onModalConcluded = (conclusion: ModalConclusion, result?: IGroup): void => {
        if (conclusion === ModalConclusion.Done) {
            // The following type cast is safe because the endpoint that
            // function createGroup calls returns an IGroup object in case of success.
            variableContext.setGroups((currentValue) => currentValue.concat(result as IGroup));
        }
    };

    useEffect(() => {
        if (isEnableHidden) {
            setEnableWelcomeMessage(false);
            setEnableOffboardMessage(false);
            setAllowJoin(false);
            setAllowMemberNomination(false);
            setAutoApproveJoin(false);
        }
        setEnableMemberNoncomplianceNotification(!isEnableHidden);
    }, [isEnableHidden]);

    useEffect(() => {
        if (isEnableDynamic) {
            setAllowJoin(false);
            setAllowMemberNomination(false);
            setRequireSponsor(false);
            setRequireJustification(false);
            setEnableMemberLeaveJustification(false);
            setAutoApproveJoin(false);
        }
    }, [isEnableDynamic]);

    return (
        <ModalActionBotton
            text='Create Group'
            size={ModalSizeType.mediumLarge}
            enable={true}
            iconName={IconNames.AddGroup}
            modalTitle='Create Group'
            enableSubmit={isSubmitEnabled()}
            submitButtonText={'Create'}
            onSubmit={onClickCreate}
            onButtonClick={onButtonClick}
            onModalConcluded={onModalConcluded}>
            <div style={{ maxWidth: 600 }}>
                <TextField
                    value={name}
                    label='Name'
                    required
                    maxLength={MaxLens.name}
                    onChange={(e: any, newValue?: string): void => {
                        setName(newValue ?? '');
                    }}
                    validateOnFocusOut={true}
                    onGetErrorMessage={validateGroupName}
                />
                <TextField
                    value={description}
                    label='Description'
                    required
                    rows={3}
                    multiline
                    resizable={false}
                    maxLength={MaxLens.description}
                    onChange={(e: any, newValue?: string): void => {
                        setDescription(newValue ?? '');
                    }}
                />
                {
                    <CoreMultiPrincipalPersonaPickerTypeaheadSearch
                        label='Owner List'
                        required={true}
                        itemLimit={EmployeeListPickerUnlimited}
                        selectedItems={ownerPersonas}
                        onChange={(personas?: IPersonaProps[]): void => {
                            const ownerPersonasVar = distinctPersonaArray(personas);
                            const ownerPersonasNew: IPersonaProps[] = [];
                            const ownersVar: IPrincipalRecord[] = [];
                            ownerPersonasVar.forEach((ownerPersona) => {
                                if (ownerPersona.itemProp) {
                                    ownerPersonasNew.push(ownerPersona);
                                    ownersVar.push(JSON.parse(ownerPersona.itemProp));
                                }
                            });
                            setOwnerPersonas(ownerPersonasNew);
                            setOwners(ownersVar);
                        }}
                    />
                }
                <CoreMultiPrincipalPersonaPickerTypeaheadSearch
                    label='Block List'
                    itemLimit={EmployeeListPickerUnlimited}
                    selectedItems={blockList}
                    onChange={(personas?: IPersonaProps[]): void => {
                        setBlockList(distinctPersonaArray(personas));
                    }}
                />
                {filteredCheckboxList.map((checkbox) => {
                    return (
                        <div key={checkbox.text}>
                            <Spacer marginTop={15} />
                            <Checkbox
                                label={checkbox.text}
                                checked={checkbox.variable}
                                onChange={(ev, isChecked): void => {
                                    checkboxChangeHandler(isChecked ?? false, checkbox.setFunction);
                                }}
                                disabled={checkbox.disabled}
                            />
                        </div>
                    );
                })}
                {isNonExternalEnabled &&
                    emailsCore.enabled &&
                    isEnableMemberNoncomplianceNotification && (
                        <>
                            <Spacer marginTop={15} />
                            <Dropdown
                                placeholder='Select days'
                                selectedKeys={selectedNotificationDays()}
                                multiSelect
                                onChange={(
                                    event: React.FormEvent<HTMLDivElement>,
                                    item?: IDropdownOption,
                                ): void => {
                                    if (!item) {
                                        return;
                                    }
                                    setNotificationDays(
                                        (currentValue): Map<string, boolean> => {
                                            const newValue = new Map(currentValue);
                                            newValue.set(item?.key as string, !!item?.selected);
                                            return newValue;
                                        },
                                    );
                                }}
                                options={notificationOptions}
                                ariaLabel='Days'
                            />
                        </>
                    )}
            </div>
            {groupsMetadata.enabled && (
                <>
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        Metadata
                    </Separator>
                    {GroupTypeDropdownElement()}
                    {SensitivityLevelDropdownElement()}
                    {primaryCategorizationlDropdownElement()}
                    {divisionDropdownElement()}
                    {GroupType.Tent === selectedGroupType?.text && (
                        <Stack>
                            <LabelInfoIcon
                                iconHoverContent={'Accountable Owner'}
                                iconName={IconNames.Info}
                                required // Only shown and required when group type is Tent
                            >
                                CVP Approved By
                            </LabelInfoIcon>
                            <CoreSinglePrincipalIdPickerTypeaheadSearch
                                ariaLabel='CVP Approved By'
                                placeHolder='Employee Name or Alias'
                                onChange={(item, itemAlt): void => {
                                    onCVPApprovedBySelect(itemAlt);
                                }}
                            />
                        </Stack>
                    )}
                    {GroupType.Tent === selectedGroupType?.text && (
                        <Stack>
                            <LabelInfoIcon
                                iconHoverContent={
                                    <a
                                        href='https://findcontact.microsoft.com/'
                                        target='_blank'
                                        rel='noreferrer'>
                                        Associate General Counsel
                                    </a>
                                }
                                iconName={IconNames.Info}
                                required // Only shown and required when type is Tent.
                            >
                                AGC Approved By
                            </LabelInfoIcon>
                            <CoreSinglePrincipalIdPickerTypeaheadSearch
                                ariaLabel='AGC Approved By'
                                placeHolder='Employee Name or Alias'
                                onChange={(item, itemAlt): void => {
                                    onAGCApprovedBySelect(itemAlt);
                                }}
                            />
                        </Stack>
                    )}
                </>
            )}
        </ModalActionBotton>
    );
}

const initNotificationDays: Map<string, boolean> = Object.values(DayOfWeek).reduce(
    (settings, dayOfWeek) => {
        settings.set(dayOfWeek, true);
        return settings;
    },
    new Map<string, boolean>(),
);

const MaxLens = {
    name: 50,
    description: 250,
};

const styles = mergeStyleSets({
    hoverCardList: {
        paddingRight: 20,
    },
});
