import { IconNames } from 'assets/constants/global-constants';
import { ISeatRecord, ProvisionType } from 'clients/facilities-client';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { Stack, TextField } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import {
    IReservationInstructions,
    IReservationPromiseInstruction,
    createCancelPromises,
    createMakePromises,
    createReschedulePromises,
    findSeatName,
} from 'components/facilities/facilities-reservations/modals/facilities-reservation-instruction-utils';

export interface ChangeReservationModalProps {
    reservationInstructions?: IReservationInstructions;
    iseatRecords: ISeatRecord[];
    onModalConcluded: (
        modalConclusion: ModalConclusion,
        reservationResults: IReservationPromiseInstruction[],
    ) => void;
}

export default function ChangeReservationModal(props: ChangeReservationModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [isIcmDescriptionRequired, setIcmDescriptionRequired] = useState<boolean>(false);
    const [icmDescription, setIcmDescription] = useState<string>();
    const [reservationPromiseInstructions, setReservationPromiseInstructions] = useState<
        IReservationPromiseInstruction[]
    >([]);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true);
    const [modalConclusion, setModalConclusion] = useState<ModalConclusion | undefined>(undefined);

    useEffect(() => {
        setIcmDescriptionRequired(
            determineIfIcmDescriptionIsRequired(props.reservationInstructions, props.iseatRecords),
        );
    }, []);

    useEffect(() => {
        let shouldSubmitButtonBeDisabled = false;
        if (
            isIcmDescriptionRequired &&
            (icmDescription === undefined || icmDescription?.trim().length === 0)
        ) {
            shouldSubmitButtonBeDisabled = true;
        }
        setIsSubmitButtonDisabled(shouldSubmitButtonBeDisabled);
    }, [icmDescription, isIcmDescriptionRequired]);

    async function onSubmit(): Promise<void> {
        try {
            if (props.reservationInstructions) {
                const make = props.reservationInstructions.make;
                const reschedules = props.reservationInstructions.reschedule?.filter(
                    (x) => x.changeToSeatId !== x.currentTimeSlot.seatStatus.seatId,
                );
                const cancels = props.reservationInstructions.cancel;

                const promises = [] as IReservationPromiseInstruction[];
                promises.push(
                    ...createReschedulePromises(
                        authContext,
                        userContext,
                        reschedules,
                        icmDescription,
                    ),
                );
                promises.push(
                    ...createMakePromises(authContext, userContext, make, icmDescription),
                );
                promises.push(...createCancelPromises(authContext, userContext, cancels));

                await Promise.all(promises.map((x) => x.promise));

                setReservationPromiseInstructions(promises);
            }
        } catch (error) {
            console.error(error);
            throw 'Failed to change seat for reservation';
        }
    }

    function getModalSubtitle(): JSX.Element {
        if (props.reservationInstructions) {
            const nameSet = new Set<string>();
            props.reservationInstructions.reschedule?.forEach((x) =>
                nameSet.add(findSeatName(x.currentTimeSlot.seatStatus.seatId, props.iseatRecords)),
            );
            const seatNames = [...nameSet].join(',') ?? '';

            const newSeatName = findSeatName(
                props.reservationInstructions.reschedule?.[0].changeToSeatId ?? '',
                props.iseatRecords,
            );
            return (
                <>
                    Please confirm you want to change your reservation from seat {seatNames} to seat{' '}
                    {newSeatName}.
                </>
            );
        }
        return <></>;
    }

    function determineIfIcmDescriptionIsRequired(
        reservationInstructions: IReservationInstructions | undefined,
        iseatRecords: ISeatRecord[] | undefined,
    ): boolean {
        for (const make of reservationInstructions?.make ?? []) {
            const seat = iseatRecords?.find((x) => x.id === make.seatId);
            if (seat?.provisionInfo.provisionType === ProvisionType.LiveSite) {
                return true;
            }
        }

        for (const reschedule of reservationInstructions?.reschedule ?? []) {
            const seat = iseatRecords?.find((x) => x.id === reschedule.changeToSeatId);
            if (seat?.provisionInfo.provisionType === ProvisionType.LiveSite) {
                return true;
            }
        }
        for (const reschedule of props.reservationInstructions?.reschedule ?? []) {
            const seat = props.iseatRecords.find((x) => x.id === reschedule.changeToSeatId);
            if (seat?.provisionInfo.provisionType === ProvisionType.LiveSite) {
                return true;
            }
        }
        return false;
    }

    function getIcmDescriptionTextBox(): JSX.Element {
        return (
            <Stack>
                <Stack.Item>{'Please provide an ICM # or description.'}</Stack.Item>
                <Stack.Item>
                    <TextField
                        required
                        label='ICM # / Description'
                        value={icmDescription || ''}
                        maxLength={255}
                        onChange={(e, value): void => {
                            setIcmDescription(value);
                        }}
                    />
                </Stack.Item>
            </Stack>
        );
    }

    function onModalConcluded(modalConclusion: ModalConclusion): void {
        setModalConclusion(modalConclusion);
    }

    useEffect(() => {
        if (modalConclusion === ModalConclusion.Done && reservationPromiseInstructions.length > 0) {
            props.onModalConcluded(modalConclusion, reservationPromiseInstructions);
        } else if (modalConclusion === ModalConclusion.Cancel) {
            props.onModalConcluded(modalConclusion, []);
        }
    }, [modalConclusion, reservationPromiseInstructions]);

    return (
        <ModalActionButton
            text={''}
            iconName={IconNames.Edit}
            submitButtonIcon={IconNames.Edit}
            modalTitle={'Changing your seat reservation'}
            onSubmit={onSubmit}
            onCancel={(): void => {
                setReservationPromiseInstructions([]);
            }}
            enableSubmit={!isSubmitButtonDisabled}
            renderWithoutButton={true}
            onModalConcluded={onModalConcluded}>
            {getModalSubtitle()}
            {isIcmDescriptionRequired && getIcmDescriptionTextBox()}
        </ModalActionButton>
    );
}
