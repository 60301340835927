import { IStackItemStyles } from '@fluentui/react';

// TODO 24543473 Refactor this file and delete
export enum ContinuousEvaluationTypes {
    Deferred = 'Deferred',
    Unenrolled = 'Unenrolled',
    Other = 'Other',
    Blank = '',
}

export enum ContinuousEvaluationFilterOptions {
    ContinuousEvaluationType = 'CE type',
    ContinuousEvaluationEnrollmentDate = 'CE enrollment date',
    ContinuousEvaluationUnenrollmentDate = 'CE unenrollment date',
}

export const labelStackItem: IStackItemStyles = {
    root: {
        padding: 5,
        width: '130px',
    },
};
