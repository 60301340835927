import React, { useContext } from 'react';
import { EligibilitiesFilterContext } from 'components/eligibilities/eligibilities/contexts/eligibilities-filter-context';
import {
    ActionButton,
    Stack,
    Separator,
    Checkbox,
    mergeStyleSets,
    DatePicker,
} from '@fluentui/react';
import { IconNames, Dictionary } from 'assets/constants/global-constants';
import { globalFilterSeparatorStyles, globalCheckboxStyles } from 'assets/styles/global-styles';
import Spacer from 'components/common/spacer';

export enum ValidAuditLogCategoriesEnum {
    Eligibility = 'Eligibility',
    EligibilityRequest = 'Eligibility Request',
}

const validAuditLogCategories = [
    ValidAuditLogCategoriesEnum.Eligibility,
    ValidAuditLogCategoriesEnum.EligibilityRequest,
];

interface IEligibilityTimelineFiltersProps {
    onClickApplyFilters: () => void;
}

export default function EligibilityTimelineFilters(
    props: IEligibilityTimelineFiltersProps,
): JSX.Element {
    const filterContext = useContext(EligibilitiesFilterContext);

    const onSetFromDate = (date: Date | null | undefined): void => {
        if (date) filterContext.setFilter('fromDate', date);
        else filterContext.setFilter('fromDate', undefined);
    };

    const onSetToDate = (date: Date | null | undefined): void => {
        if (date) filterContext.setFilter('toDate', date);
        else filterContext.setFilter('toDate', undefined);
    };

    const onSelectCategory = (checked?: boolean, category?: string): void => {
        if (category) {
            filterContext.setFilter('categories', { [category]: checked } as Dictionary<boolean>);
        }
    };

    return (
        <>
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>From Date</span>
            </Separator>
            <DatePicker
                ariaLabel='From Date'
                value={filterContext.fromDate}
                placeholder={'Select a date'}
                allowTextInput={true}
                onSelectDate={onSetFromDate}
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>To Date</span>
            </Separator>
            <DatePicker
                ariaLabel='To Date'
                value={filterContext.toDate}
                placeholder={'Select a date'}
                allowTextInput={true}
                onSelectDate={onSetToDate}
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Categories</span>
            </Separator>
            <div role='group' aria-label='Categories Group'>
                {validAuditLogCategories.map(
                    (category): JSX.Element => (
                        <Checkbox
                            styles={globalCheckboxStyles}
                            label={category}
                            key={category}
                            onChange={(ev, checked): void => {
                                onSelectCategory(checked, category);
                            }}
                            checked={
                                filterContext.categories
                                    ? filterContext.categories[category] ?? false
                                    : false
                            }
                        />
                    ),
                )}
            </div>
            <Spacer marginTop={10} />
            <Stack horizontal horizontalAlign='space-between'>
                <Stack.Item>
                    <ActionButton
                        iconProps={{ iconName: IconNames.Refresh }}
                        onClick={(): void => {
                            filterContext.resetAll();
                            props.onClickApplyFilters();
                        }}>
                        <span>Clear filters</span>
                    </ActionButton>
                </Stack.Item>
                <Stack.Item className={styles.clearFilter}>
                    <ActionButton
                        iconProps={{ iconName: IconNames.ClearFilter }}
                        onClick={(): void => {
                            props.onClickApplyFilters();
                        }}>
                        <span>Apply Filters</span>
                    </ActionButton>
                </Stack.Item>
            </Stack>
        </>
    );
}

const styles = mergeStyleSets({
    clearFilter: {
        textAlign: 'right',
    },
});
