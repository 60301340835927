import React, { useEffect, useContext } from 'react';
import { mergeStyleSets } from '@fluentui/react';
import { CloudScreeningUserType } from 'utils/cloud-screening-utils';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import Tabs, { TabbedContent } from 'components/common/tabs';
import MyCloudScreeningsPage from 'components/screening/cloud-screening/my-screenings/my-screenings-page';
import { Location } from 'history';
import { useParams, useHistory } from 'react-router-dom';
import CloudScreeningOrgPage from 'components/screening/cloud-screening/direct-reports/cloud-screening-org-page';
import { UserContext } from 'contexts/user-context';
import CommonSpinner from 'components/common/common-spinner';
import CloudScreeningAdminPage from 'components/screening/cloud-screening/admin/cloud-screening-admin-page';
import { screeningBreadcrumbs } from 'components/screening/cloud-screening/cloud-screening-breadcrumbs';
import { ScreeningPageNames } from 'components/common/constants';

type UrlParams = {
    tabSelection: string;
};

const TabSelectionKeyMap: {
    [tabSelection: string]: {
        key: string;
        uri: string;
        security: CloudScreeningUserType[];
    };
} = {
    'my-screenings': {
        key: 'my-screenings',
        uri: '/screening/cloud',
        security: [],
    },
    'my-org': {
        key: 'my-org',
        uri: '/screening/cloud/my-org',
        security: [
            CloudScreeningUserType.Manager,
            CloudScreeningUserType.ManagerDelegate,
            CloudScreeningUserType.Admin,
        ],
    },
    'admin': {
        key: 'admin',
        uri: '/screening/cloud/admin',
        security: [CloudScreeningUserType.Admin],
    },
};

export interface CloudScreeningPageProps {
    pageName: string;
    location: Location;
}

export default function CloudScreeningPage(props: CloudScreeningPageProps): JSX.Element {
    const defaultTabKey = 'my-screenings';
    const breadCrumbContext = useContext(BreadCrumbContext);
    const userContext = useContext(UserContext);
    const history = useHistory();
    let { tabSelection } = useParams() as UrlParams;
    tabSelection = tabSelection ?? defaultTabKey;

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([...screeningBreadcrumbs(tabSelection)]);
    }, [tabSelection]);

    function onTabChange(key?: string): void {
        if (key) {
            const selectedTab = Object.values(TabSelectionKeyMap).find((tab) => tab.key === key);
            if (
                selectedTab &&
                (selectedTab.security.length === 0 ||
                    selectedTab.security.some((x) => userContext.hasCloudScreeningUserType(x)))
            ) {
                history.push(`${selectedTab.uri}`);
            }
        }
    }

    function organizationTab(): JSX.Element | undefined {
        if (
            TabSelectionKeyMap['my-org'].security.some((x) =>
                userContext.hasCloudScreeningUserType(x),
            )
        ) {
            return (
                <TabbedContent
                    tabHeader='My Organization'
                    itemKey={TabSelectionKeyMap['my-org'].key}>
                    <CloudScreeningOrgPage {...props} />
                </TabbedContent>
            );
        }
    }

    function adminTab(): JSX.Element | undefined {
        if (
            TabSelectionKeyMap['admin'].security.some((x) =>
                userContext.hasCloudScreeningUserType(x),
            )
        ) {
            return (
                <TabbedContent tabHeader='Admin' itemKey={TabSelectionKeyMap['admin'].key}>
                    <CloudScreeningAdminPage {...props} />
                </TabbedContent>
            );
        }
    }

    return (
        <div>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            {userContext.isCloudScreeningUserTypesLoaded ? (
                <div className={styles.tabsContainer}>
                    <Tabs selectedKey={TabSelectionKeyMap[tabSelection].key} onChange={onTabChange}>
                        <TabbedContent
                            tabHeader={ScreeningPageNames.MyScreenings}
                            itemKey={TabSelectionKeyMap['my-screenings'].key}>
                            <MyCloudScreeningsPage {...props} />
                        </TabbedContent>
                        {organizationTab()}
                        {adminTab()}
                    </Tabs>
                </div>
            ) : (
                <CommonSpinner label={'Loading cloud screening interface...'} />
            )}
        </div>
    );
}

const styles = mergeStyleSets({
    tabsContainer: {
        margin: '5px 0 5px 0',
    },
});
