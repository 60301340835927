import { IPersonaProps, mergeStyleSets, PrimaryButton, Separator, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { globalSeparatorStyles } from 'assets/styles/global-styles';
import { IEmployee } from 'clients/employee-client';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';

export interface CloudScreeningAdminImpersonationProps {
    onEmployeeSelected: (emp: IEmployee | undefined) => void;
}

export default function CloudScreeningAdminImpersonation(
    props: CloudScreeningAdminImpersonationProps,
): JSX.Element {
    const [viewedEmployee, setViewedEmployee] = useState<IEmployee>();

    function onCandidateSelected(persona: IPersonaProps | undefined): void {
        if (persona?.itemProp) {
            const employee = JSON.parse(persona.itemProp) as IEmployee;
            setViewedEmployee(employee);
        } else {
            setViewedEmployee(undefined);
        }
    }

    return (
        <Stack>
            <Stack.Item>
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Admin
                </Separator>
            </Stack.Item>
            <Stack.Item>
                <div className={styles.employeePickerContainer}>
                    <EmployeePickerTypeaheadSearch
                        ariaLabel='Admin'
                        placeHolder={'Employee name or alias'}
                        onCandidateSelected={onCandidateSelected}
                    />
                </div>

                <div className={styles.assignBtnContainer}>
                    <PrimaryButton
                        text={'View Employee'}
                        iconProps={{ iconName: 'ReminderPerson' }}
                        onClick={async (): Promise<void> => {
                            props.onEmployeeSelected(viewedEmployee);
                        }}
                    />
                </div>
            </Stack.Item>
        </Stack>
    );
}

const styles = mergeStyleSets({
    assignBtnContainer: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    employeePickerContainer: {
        marginBottom: '10px',
    },
});
