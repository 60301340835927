import React, { useMemo } from 'react';
import {
    PrimaryButton,
    Modal as FluentModal,
    MessageBar,
    mergeStyles,
    MessageBarType,
    mergeStyleSets,
    DefaultButton,
    FontSizes,
    FontWeights,
    IModalProps,
    Icon,
    ProgressIndicator,
    FontIcon,
    FocusTrapZone,
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';

export enum ModalSizeType {
    medium = 'medium-size',
    size500 = 'size500',
    mediumLarge = 'medium-large-size',
    size700 = 'size700',
    large = 'large-size',
    xLarge = 'xtra-large-size',
    xxLarge = 'xtra-xtra-large-size',
    size1400 = 'size1400',
    standard = 'standard',
}

export interface ModalProps {
    isOpen: boolean;
    onMiscButton1?: () => Promise<void>;
    miscButton1Text?: string;
    miscButton1Icon?: string;
    onLeftMiscButton1?: () => Promise<void>; // If specified, the modal will show a button to the right of the "Cancel" button.
    leftMiscButton1Text?: string;
    leftMiscButton1Icon?: string;
    nextButtonText?: string;
    onNext?: () => void; // If specified, the modal will show a "Next" button instead of the "Submit" button.
    onBack?: () => void; // If specified, the modal will show a "Back" button.
    onBackText?: string; // if specified, it will be the button text. Default text is "Back".
    onRedirect?: () => void; // If specified, the modal will show a "Redirect" button.
    onRedirectText?: string; // if specified, it will be the button text. Default text is "Redirect".
    onCancel: () => void;
    onDismiss?: () => void;
    onSubmit2?: () => void;
    onSubmit: () => void;
    onResetErrorMsg?: () => void;
    onResetSuccessMsg?: () => void;
    cancelButtonText?: string;
    submitButton2Text?: string;
    submitButtonText?: string;
    submitButtonIcon?: string;
    submitButton2Icon?: string;
    children?: React.ReactNode;
    errorMsg?: string;
    successMsg?: string;
    footerIconName?: string;
    footerText?: string;
    footerNode?: React.ReactNode;
    shouldHideCancelButton?: boolean;
    isNextButtonDisabled?: boolean;
    isSubmitButton2Disabled?: boolean;
    isSubmitButtonDisabled?: boolean;
    isCancelButtonDisabled?: boolean;
    isMiscButton1Disabled?: boolean;
    isLeftMiscButton1Disabled?: boolean;
    fluentModalProps?: IModalProps;
    showProgressIndicator?: boolean;
    subTitle?: string;
    title?: string | JSX.Element;
    titleIcon?: string;
    size?: ModalSizeType;
    fixWidth?: boolean; // Default: false
    isBlocking?: boolean;
}

export function Modal(props: ModalProps): JSX.Element {
    const defaultModalProps: IModalProps = {
        isOpen: props.isOpen,
        isDarkOverlay: false,
        isBlocking: props.isBlocking ?? false,
        containerClassName: styles.modalContainer,
        onDismiss: props.onDismiss,
    };

    const {
        children,
        errorMsg = '',
        successMsg = '',
        footerIconName,
        footerText = '',
        footerNode = <></>,
        fluentModalProps = defaultModalProps,
        onMiscButton1,
        miscButton1Text,
        miscButton1Icon,
        onLeftMiscButton1,
        leftMiscButton1Text,
        leftMiscButton1Icon,
        nextButtonText,
        onNext,
        onBack,
        onBackText,
        onRedirect,
        onRedirectText,
        onCancel,
        onSubmit2,
        onSubmit,
        onResetErrorMsg,
        onResetSuccessMsg,
        shouldHideCancelButton,
        isNextButtonDisabled,
        isSubmitButton2Disabled,
        isSubmitButtonDisabled,
        isCancelButtonDisabled,
        isMiscButton1Disabled,
        isLeftMiscButton1Disabled,
        showProgressIndicator,
        subTitle,
        title,
        titleIcon,
        cancelButtonText = 'Cancel',
        submitButton2Text,
        submitButtonText = 'Submit',
    } = props;

    const modalProps: IModalProps = {
        ...defaultModalProps,
        ...fluentModalProps,
    };

    const resetErrorMsg = (): void => {
        if (onResetErrorMsg) {
            onResetErrorMsg();
        }
    };

    const resetSuccessMsg = (): void => {
        if (onResetSuccessMsg) {
            onResetSuccessMsg();
        }
    };

    const rootStyle = useMemo(() => {
        const sizeProp = {
            [ModalSizeType.medium]: {
                minWidth: '370px',
            },
            [ModalSizeType.size500]: {
                minWidth: '500px',
            },
            [ModalSizeType.mediumLarge]: {
                minWidth: '600px',
            },
            [ModalSizeType.size700]: {
                minWidth: '700px',
            },
            [ModalSizeType.large]: {
                minWidth: '850px',
            },
            [ModalSizeType.xLarge]: {
                minWidth: '1000px',
            },
            [ModalSizeType.xxLarge]: {
                minWidth: '1250px',
            },
            [ModalSizeType.size1400]: {
                minWidth: '1400px',
            },
            [ModalSizeType.standard]: {
                minWidth: '400px',
            },
        };
        const minWidth = sizeProp[props.size ?? ModalSizeType.standard].minWidth;

        if (props.fixWidth) {
            return mergeStyles(styles.root, { minWidth: minWidth, maxWidth: minWidth });
        } else {
            return mergeStyles(styles.root, { minWidth: minWidth });
        }
    }, [props.size, styles.root, props.fixWidth]);

    // titleId is necessary for accessibility to drive attribute titleAriaId.
    const titleId = (): string => {
        if (typeof title === 'string') {
            return `modal-title-${title.replace(/\s+/g, '-')}`;
        } else {
            return 'modal-title';
        }
    };

    return (
        <FluentModal {...modalProps} titleAriaId={titleId()} aria-label={title}>
            <FocusTrapZone>
                <div className={rootStyle}>
                    <header>
                        <div className={styles.title} id={titleId()}>
                            {titleIcon && (
                                <>
                                    <FontIcon className={styles.titleIcon} iconName={titleIcon} />{' '}
                                    &nbsp;
                                </>
                            )}
                            {title}
                        </div>
                        <div className={styles.subTitle}>{subTitle}</div>
                    </header>
                    <section className={styles.body}>{children}</section>
                    {errorMsg && (
                        <MessageBar
                            onDismiss={resetErrorMsg}
                            messageBarType={MessageBarType.error}
                            dismissButtonAriaLabel='Close'>
                            {errorMsg}
                        </MessageBar>
                    )}
                    {successMsg && (
                        <MessageBar
                            onDismiss={resetSuccessMsg}
                            messageBarType={MessageBarType.success}
                            dismissButtonAriaLabel='Close'>
                            {successMsg}
                        </MessageBar>
                    )}
                    {showProgressIndicator ? (
                        <ProgressIndicator styles={progressBarIndicatorStyles} barHeight={1} />
                    ) : (
                        // Prevent box size to change when progress indicator is active
                        <div style={{ height: '9px', width: '100%' }}></div>
                    )}
                    <footer className={styles.footer}>
                        {!shouldHideCancelButton && (
                            <DefaultButton
                                ariaLabel={cancelButtonText}
                                text={cancelButtonText}
                                onClick={onCancel}
                                disabled={isCancelButtonDisabled}
                                allowDisabledFocus
                            />
                        )}
                        {onLeftMiscButton1 && (
                            <DefaultButton
                                ariaLabel={leftMiscButton1Text}
                                text={leftMiscButton1Text}
                                disabled={isLeftMiscButton1Disabled}
                                onClick={onLeftMiscButton1}
                                allowDisabledFocus
                                iconProps={{ iconName: leftMiscButton1Icon }}
                            />
                        )}
                        <div className={styles.footerText}>
                            {footerIconName && (
                                <Icon
                                    id='footer-icon'
                                    className={styles.icon}
                                    iconName={footerIconName}
                                />
                            )}
                            {footerText}
                        </div>
                        <div className={styles.footerNode}>{footerNode}</div>
                        <div className={styles.actionButtons}>
                            {onBack && (
                                <div className={styles.actionButtons}>
                                    <DefaultButton
                                        ariaLabel={onBackText ?? 'Back'}
                                        text={onBackText ?? 'Back'}
                                        onClick={onBack}
                                        allowDisabledFocus
                                    />
                                </div>
                            )}
                            {onRedirect && (
                                <div className={styles.actionButtons}>
                                    <DefaultButton
                                        ariaLabel={onRedirectText ?? 'Redirect'}
                                        text={onRedirectText ?? 'Redirect'}
                                        onClick={onRedirect}
                                        allowDisabledFocus
                                    />
                                </div>
                            )}
                            {onMiscButton1 && (
                                <DefaultButton
                                    ariaLabel={miscButton1Text}
                                    text={miscButton1Text}
                                    disabled={isMiscButton1Disabled}
                                    onClick={onMiscButton1}
                                    allowDisabledFocus
                                    iconProps={{ iconName: miscButton1Icon }}
                                />
                            )}
                            {onSubmit2 && (
                                <div className={styles.noWarpText}>
                                    <PrimaryButton
                                        ariaLabel={submitButton2Text}
                                        text={submitButton2Text}
                                        disabled={isSubmitButton2Disabled}
                                        onClick={onSubmit2}
                                        allowDisabledFocus
                                        iconProps={{ iconName: props.submitButton2Icon ?? '' }}
                                    />
                                </div>
                            )}
                            {props.onNext && (
                                <PrimaryButton
                                    ariaLabel={nextButtonText ?? 'Next'}
                                    text={nextButtonText ?? 'Next'}
                                    disabled={isNextButtonDisabled}
                                    onClick={onNext}
                                    allowDisabledFocus
                                />
                            )}
                            {!props.onNext && (
                                <PrimaryButton
                                    ariaLabel={submitButtonText}
                                    text={submitButtonText}
                                    disabled={isSubmitButtonDisabled}
                                    onClick={onSubmit}
                                    allowDisabledFocus
                                    iconProps={{ iconName: props.submitButtonIcon ?? '' }}
                                />
                            )}
                        </div>
                    </footer>
                </div>
            </FocusTrapZone>
        </FluentModal>
    );
}

const progressBarIndicatorStyles = mergeStyleSets({
    itemProgress: {
        paddingBottom: '0',
    },
});

const styles = mergeStyleSets({
    root: {
        minHeight: '172px',
        boxSizing: 'border-box',
        selectors: {
            '@media(max-width: 450px)': {
                minWidth: '100%',
                maxWidth: '100%',
            },
        },
    },
    title: [
        FontSizes.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${SharedColors.cyanBlue10}`,
            display: 'flex',
            fontSize: '24px',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '30px 35px 0 35px',
        },
    ],
    titleIcon: {
        fontSize: '16px',
    },
    subTitle: [
        FontSizes.small,
        {
            flex: '1 1 auto',
            display: 'flex',
            fontSize: '14px',
            alignItems: 'center',
            padding: '0 35px',
        },
    ],
    body: {
        padding: '15px 35px 10px 35px',
        color: '#1b1b1b',
    },
    footer: {
        backgroundColor: '#FEFCFE',
        padding: 20,
        borderTop: '1px solid #EAE9EA',
        display: 'flex',
        selectors: {
            '& :not(:last-child)': {
                marginRight: '10px',
            },
        },
    },
    footerText: {
        fontSize: '13px',
        paddingTop: '4px',
        display: 'flex',
        flexGrow: '1',
    },
    footerNode: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    noWarpText: {
        whiteSpace: 'nowrap',
    },
    icon: {
        marginRight: '8px',
        paddingTop: '1px',
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    modalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    separator: {
        fontSize: FontSizes.smallPlus,
    },
});

export default Modal;
