import { mergeStyles, PrimaryButton } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { FormResponse } from 'components/forms/forms-common';
import { ReactComponent as SubmissionCheck } from 'components/forms/viewer/submission-check.svg';

const containerStyles = mergeStyles({
    gridArea: 'elements',
    marginLeft: 'auto',
    marginRight: 'auto',
});

const submissionBoxStyles = mergeStyles(globalStyles.boxShadow, {
    width: 'fit-content',
    padding: '3rem 3.5rem',
});

const buttonStyles = mergeStyles({
    width: 'fit-content',
    margin: 'auto',
    display: 'block',
    marginTop: '5rem',
});

const submissionTextStyles = mergeStyles({
    fontWeight: '600',
    textAlign: 'center',
});

const submissionSvgStyles = mergeStyles({
    display: 'block',
    margin: 'auto',
    padding: '2rem',
});

const linkStyles = mergeStyles(globalStyles.link, {
    textDecoration: 'none',
});

type FormsSubmissionProps = {
    formResponse: FormResponse;
};

export default function FormsSubmission(props: FormsSubmissionProps): JSX.Element {
    const { formResponse } = props;

    return (
        <div className={containerStyles}>
            <div className={submissionBoxStyles}>
                <h2 className={submissionTextStyles}>
                    The <em>{formResponse.title}</em> form is complete and has been submitted.
                </h2>
                <h3 className={submissionTextStyles}>
                    Review your form submission{' '}
                    <Link
                        to={`/forms/review/${formResponse.name}/${formResponse.id}`}
                        className={linkStyles}>
                        here
                    </Link>
                </h3>
                <div>
                    <SubmissionCheck className={submissionSvgStyles} />
                </div>
            </div>
            <Link to='/forms/submissions' style={{ all: 'unset' }}>
                <PrimaryButton className={buttonStyles}>Back to forms</PrimaryButton>
            </Link>
        </div>
    );
}
