import React from 'react';
import { TableCell } from 'components/common/table';
import { IColumn } from '@fluentui/react';
import { xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { IMyGroup } from 'clients/group-client';
import { ISortableColumns } from 'utils/sort-utils';

export function tableColumnsMyGroupsPending(params: ISortableColumns): IColumn[] {
    const columnWidths = {
        group: 300,
    };

    const columns: IColumn[] = [
        {
            key: 'Group',
            name: 'Group',
            ariaLabel: 'Group',
            minWidth: columnWidths.group,
            maxWidth: columnWidths.group * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === 'Group',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Group');
            },
            onRender: (row: IMyGroup): JSX.Element => (
                <TableCell key={`${row.id}-name`}>
                    <EllipsisTextCss text={row.name} />
                </TableCell>
            ),
        },
    ];

    return columns;
}
