import { mergeStyles, mergeStyleSets, IStyle, IDetailsHeaderStyles } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';

export const contentMaxHeight = '24px';

export const expandedTableWidth = 1620;

export const detailsListStyles = mergeStyleSets({
    theList: {
        backgroundColor: 'transparent',
        selectors: {
            '& [role=row]': {
                backgroundColor: 'transparent',
            },
            '& [role=gridcell], & [role=rowheader]': {
                minHeight: '49px', // This allows rows to grow based on their content if IColumn.isMultiline is set to true
                display: 'flex',
                alignItems: 'center',
                padding: '4px 8px 4px 12px', // 8 and 12 make the L/R padding the same as column header
                borderBottom: '1px solid rgba(0,0,0,0.1)',
            },
        },
    },
    wrapper: {
        height: 'auto',
        position: 'relative',
    },
    dataCellContainer: [
        {
            height: contentMaxHeight,
            display: 'table',
            maxWidth: '100%',
        },
        globalStyles.fontStyles,
        globalStyles.lineHeight,
    ],
    dataCell: {
        verticalAlign: 'middle',
        display: 'table-cell',
        maxWidth: '100%',
    },
    lastColumnClassName: {
        // See NOTE (*) below
        justifyContent: 'flex-end', // for data cells
    },
    icon: {
        // To align icons in the column properly.
        // Specially useful if the icon is the leftmost item on the column.
        marginTop: -2,
        marginLeft: -7,
    },
    expandedTable: {
        minWidth: expandedTableWidth,
    },
});

/**
 * NOTE (*)
 * Our common component <Table> uses Fluent-UI's DetailsList to implement
 * tables. The desired styling for the table asks for pushing last column
 * to right side of the screen. Since <Table>'s behavior when expanding
 * the screen follows behavior of DetailsList, when using <Table>, in
 * order to accomplish the above, do the following:
 *
 * 1) Use either of the styles (±) or (+) defined below for the last column.
 * 2) Use style (*) defined below for the next to last column.
 * 3) This applies to the last column:
 *    On definition of the columns of the table, set BOTH min and max
 *    width descriptions of last column to the same number to force
 *    DetailsList to keep its width fixed.
 * 4) This applies to the next to last column:
 *    Set min width of the next to last column to a suitable number, and
 *    set its max width to undefined.
 *
 * Items (3) and (4) above will cause DetailsList to expand width of the
 * next to last column when user expands the screen, and keeps width of
 * the last column fixed. This way, last column will be pushed to right
 * side of the screen, its contents remain left justified within the
 * column, and the next to last column will expand and shrink with changes
 * in screen size.
 *
 * Examples: src/components/staffing/get-allocation-table-columns.tsx
 *           src/components/staffing/service/get-service-map-table-columns.tsx
 */

// Use the following if the last column contains action button(s):
// Note (±)
export const lastColumnStylesButton = {
    // Use this if last column shows buttons
    styles: { cellTitle: {} as IStyle }, // DetailsList applies this to the header cell.
    className: mergeStyles({ marginLeft: -8 }), // DetailsList applies this to the data cells.
};

// Use the following if the last column contains text:
// Note (+)
export const lastColumnStylesText = {
    // Use this if last column shows text
    styles: { cellTitle: {} as IStyle }, // DetailsList applies this to the header cell.
    className: mergeStyles({ marginLeft: -5 }), // DetailsList applies this to the data cells.
};

// The following styling is for the case the last column could be switched on or off.
// Example: The following table may show either of two different sets of columns:
//    1) A, B, C, D // 4 columns. Column D is shown.
//    2) A, B, C    // 3 columns. Column D isn't shown. Column C is the last column.
// Use the following guideline to determine what styling to apply on column C:
//    a) If column D is shown, use the style notLastColumnStyles on Column C.
//    b) If column D isn't shown, column C will become the last column.
//       Use one of the styling mentioned in Notes (+) or (±) above for it.
//       But if neither is suitable, define a new suitable styling. Most
//       probably your new styling will look similar to either (+) or (±)
//       above.
// Examples: src/components/staffing/get-allocation-table-columns.tsx
//           src/components/staffing/service/get-service-map-table-columns.tsx
// Note (*)
export const notLastColumnStyles = {
    styles: { cellTitle: {} as IStyle }, // DetailsList applies this to the header cell.
    className: '', // DetailsList applies this to the data cells.
};

// used to make column titles multi-lined
export const columnHeaderStyles: Partial<IDetailsHeaderStyles> = {
    root: {
        selectors: {
            '.ms-DetailsHeader-cell': {
                whiteSpace: 'normal',
                textOverflow: 'clip',
                lineHeight: 'normal',
            },
            '.ms-DetailsHeader-cellTitle': {
                height: '100%',
                alignItems: 'center',
            },
        },
    },
};

export const noSpacerStyle: Partial<IDetailsHeaderStyles> = {
    root: {
        selectors: {
            '.ms-GroupSpacer': {
                display: 'none !important',
            },
        },
    },
};
