import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
    IEligibility,
    IEligibilityAuditLog,
    IEligibilityRequestAuditLog,
} from 'clients/eligibility-client';
import SidebarAndContents, {
    SidebarPane,
    ContentPane,
} from 'components/common/sidebar-and-contents';
import { Location } from 'history';
import { useIsMounted } from 'utils/misc-hooks';
import { numCmp } from 'utils/sort-utils';
import { Table } from 'components/common/table';
import { getEligibilityAuditLogColumns } from 'components/eligibilities/eligibilities/view/audit-log/table-columns-audit-log';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import { ProblemLoadingPage } from 'components/common/problem-loading/problem-loading-page';
import PageTitle from 'components/common/page-title';
import { EligibilitiesFilterContext } from 'components/eligibilities/eligibilities/contexts/eligibilities-filter-context';
import EligibilityTimelineFilters, {
    ValidAuditLogCategoriesEnum,
} from 'components/eligibilities/eligibilities/view/audit-log/audit-log-filters';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { CheckScrollReachedBottom } from 'components/common/scroll-event-listener';
import ClearSeparator from 'components/common/clear-separator';

interface IDisplayEligibilityTimelineProps {
    location: Location;
    eligibility: IEligibility | undefined;
    eventsLog: IEligibilityAuditLog[];
    requestsLog: IEligibilityRequestAuditLog[];
    isLoading: boolean;
    isInitialLoad: boolean;
    hasErrorLoading: boolean;
    isMoreLogRecords: boolean;
    onClickApplyFilters: () => void;
    scrollEventHandler: () => void;
}

export interface IEligibilityLogTableRow {
    date: number;
    eligibilityCode: string;
    eligibilityName: string;
    actionBy: string;
    action: string;
    justification?: string;
    reactionBy: string;
    reaction: string;
    comment: string;
}

type EligibilityMixedAuditLogType = IEligibilityAuditLog | IEligibilityRequestAuditLog;

export default function DisplayEligibilityTimeline(
    props: IDisplayEligibilityTimelineProps,
): JSX.Element {
    const filterContext = useContext(EligibilitiesFilterContext);

    const isMounted = useIsMounted();

    const [mixedLog, setMixedLog] = useState<EligibilityMixedAuditLogType[]>([]);
    const [tableRows, setTableRows] = useState<IEligibilityLogTableRow[]>([]);

    /** ************************************************************
     *  Mix event log and request log and sort it
     */
    useEffect(() => {
        const filterEvents = (): void => {
            type R = EligibilityMixedAuditLogType;

            let logVar: R[] = [];

            const showEventsLogFilter =
                filterContext.categories[ValidAuditLogCategoriesEnum.Eligibility];

            const showRequestLogFilter =
                filterContext.categories[ValidAuditLogCategoriesEnum.EligibilityRequest];

            const showEventsLog = showEventsLogFilter || !showRequestLogFilter;
            const showRequestLog = showRequestLogFilter || !showEventsLogFilter;

            if (showEventsLog && props.eventsLog && props.eventsLog?.length > 0)
                logVar = [...props.eventsLog];

            if (showRequestLog && props.requestsLog && props.requestsLog?.length > 0)
                logVar = logVar.concat([...props.requestsLog]);

            const sortFunc = (r1: R, r2: R): number => {
                return -1 * numCmp(r1.date, r2.date);
            };

            const mixedLogVar = logVar.sort(sortFunc);
            if (isMounted()) setMixedLog(mixedLogVar);
        };

        filterEvents();
    }, [props.eventsLog, props.requestsLog, filterContext]);

    /**
     * END Mix event log and request log and sort it
     ***********************************************************************************/

    /** ************************************************************************************
     *  Create table data for display
     */

    /**
     * TODO: Remove or clean up these comments when code is finalized.
     *
     * EligibilityCreated                   createdBy     created eligibility
       EligibilityModified                  modifiedBy    modified eligibility. Code: eligibilityCode, Description: eligibilityName
       EligibilityRequestSubmitted          requestedBy   submitted eligibility request&
       EligibilityRequestApproved           requestedBy   approved eligibility request from&  [personnelIdentifier]=$anyapprovedBy>
       EligibilityRequestApprovedAutomatic  Eligibility Request from requestedBy automatically approved
       EligibilityRequestDenied             requestedBy & denied eligibility request from&  [personnelIdentifier]=$anydeniedBy>

       Eligibility      Description         Action By       Action                  Justification   Reaction By     Reaction
       eligibilityCode  eligibilityName     createdBy       created eligibility
                                            modifiedBy      modified eligibility
                                            requestedBy     requested eligibility   justification
                                            requestedBy     requested eligibility                                   automatically approved
                                            requestedBy     requested                               deniedBy        denied
                                            requestedBy     requested                               approvedBy      approved
     */

    useEffect(() => {
        const oneTableRow = (mixedLogItem: EligibilityMixedAuditLogType) => {
            const tableRow: IEligibilityLogTableRow = {} as IEligibilityLogTableRow;

            tableRow.date = mixedLogItem.date;
            tableRow.eligibilityCode = mixedLogItem.eligibilityCode;
            tableRow.eligibilityName = mixedLogItem.eligibilityName;

            const eventType = mixedLogItem.eventType; // Auxiliary variable. See Note (A).

            switch (mixedLogItem.eventType) {
                case 'EligibilityCreated':
                    tableRow.action = 'created eligibility';
                    tableRow.actionBy = mixedLogItem.createdBy;
                    break;
                case 'EligibilityModified':
                    tableRow.action = 'modified eligibility';
                    tableRow.actionBy = mixedLogItem.modifiedBy;
                    break;
                case 'EligibilityRequestApproved':
                    tableRow.actionBy = mixedLogItem.requestedBy;
                    tableRow.reaction = 'approved request for eligibility';
                    tableRow.reactionBy = mixedLogItem.approvedBy as string; // typecast is safe. approvedBy will have a value.
                    tableRow.comment = mixedLogItem.comment;
                    break;
                case 'EligibilityRequestApprovedAutomatic':
                    tableRow.actionBy = mixedLogItem.requestedBy;
                    tableRow.reaction = 'automatically approved request for eligibility';
                    tableRow.comment = mixedLogItem.comment;
                    break;
                case 'EligibilityRequestDenied':
                    tableRow.actionBy = mixedLogItem.requestedBy;
                    tableRow.reaction = 'denied request for eligibility';
                    tableRow.reactionBy = mixedLogItem.deniedBy as string; // typecast is safe. deniedBy will have a value.
                    tableRow.comment = mixedLogItem.comment;
                    break;
                case 'EligibilityRequestSubmitted':
                    tableRow.actionBy = mixedLogItem.requestedBy;
                    tableRow.action = 'submitted request for eligibility';
                    tableRow.justification = mixedLogItem?.justification;
                    tableRow.comment = mixedLogItem.comment;
                    break;
                default:
                    // Note (A)
                    // If in the following, instead of the auxiliary variable "eventType"
                    // I had used "mixedLogItem.eventType", VSCode would've given me an
                    // error waveline, because it has figured out that if the code reaches
                    // here, "mixedLogItem.eventType" has none of its valid values.
                    // By declaring "eventType" earlier, I managed to clear the waveline.
                    console.error(`eventType "${eventType}" not recognized.`);
                    break;
            }
            return tableRow;
        };

        setTableRows(mixedLog.map((mixedLogItem) => oneTableRow(mixedLogItem)));
    }, [mixedLog]);

    /**
     * END Create table data for display
     ****************************************************************************************************/

    const tableColumns = useMemo(() => getEligibilityAuditLogColumns(), [tableRows]);

    return (
        <PageLoadingSpinner
            label='Loading timeline...'
            ariaLive='assertive'
            isLoading={props.isInitialLoad}
            labelPosition='left'>
            <ProblemLoadingPage isProblemLoadingPage={props.hasErrorLoading}>
                <SidebarAndContents>
                    <SidebarPane>
                        <EligibilityTimelineFilters
                            onClickApplyFilters={props.onClickApplyFilters}
                        />
                    </SidebarPane>
                    <ContentPane>
                        <PageTitle>Event Timeline</PageTitle>
                        <Table
                            rows={tableRows || []}
                            tableColumns={tableColumns}
                            isFetchingData={false}
                            tableName='Event Timeline'
                        />
                        <ClearSeparator />
                        <IsLoadingIndicator
                            isLoading={props.isLoading}
                            ifNotLoading={<ClearSeparator />}
                        />
                        <ClearSeparator />
                        <CheckScrollReachedBottom
                            shouldRemoveHandler={!props.isMoreLogRecords}
                            onScrollReachedBottom={props.scrollEventHandler}
                        />
                    </ContentPane>
                </SidebarAndContents>
            </ProblemLoadingPage>
        </PageLoadingSpinner>
    );
}
