import React, { useEffect, useState } from 'react';
import {
    Modal as FluentModal,
    mergeStyles,
    mergeStyleSets,
    FontSizes,
    FontWeights,
    IModalProps,
    DefaultButton,
    PrimaryButton,
    Stack,
    IStackItemStyles,
    DialogType,
    Dialog,
    DialogFooter,
    IDialogContentProps,
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
export type ButtonParams = { title: string; iconName: string };
export const EditParams: ButtonParams = { title: 'Edit', iconName: 'edit' };
export const UpdateParams: ButtonParams = { title: 'Update', iconName: 'refresh' };
export const SaveParams: ButtonParams = { title: 'Save', iconName: 'save' };

export interface BasicDetailsModalProps {
    isOpen: boolean;
    closeTitle: 'Close' | 'Cancel';
    editButton: ButtonParams;
    onCloseClick: () => void;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
    deleteDialogProps?: DeleteDialogProps;
    children?: React.ReactNode;
    fluentModalProps?: IModalProps;
    subTitle?: string;
    title?: string;
    size?: 'medium-size';
    actionButtonDisabled: boolean;
}

export interface DeleteDialogProps {
    title: string;
    subText: string;
    confirmButtonText: string;
    cancelButtonText: string;
    autoClosed: boolean;
}

export function BasicDetailsModal(props: BasicDetailsModalProps): JSX.Element {
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    const defaultModalProps: IModalProps = {
        isOpen: props.isOpen,
        onDismiss: (): void => {
            props.onCloseClick();
        },
        isDarkOverlay: true,
        isBlocking: false,
        containerClassName: styles.modalContainer,
        dragOptions: undefined,
    };

    const { children, fluentModalProps = defaultModalProps, subTitle, title } = props;

    const modalProps: IModalProps = {
        ...defaultModalProps,
        ...fluentModalProps,
    };

    const dialogContentProps: IDialogContentProps = {
        type: DialogType.normal,
        title: props.deleteDialogProps?.title,
        subText: props.deleteDialogProps?.subText,
        styles: { title: styles.dialogTitle },
    };

    const rootStyle =
        props.size === 'medium-size'
            ? mergeStyles(styles.root, styles.rootMediumSizeWidth)
            : mergeStyles(styles.root, styles.rootDefaultWidth);

    useEffect(() => {
        setDeleteDialogOpen(false);
    }, [props.deleteDialogProps?.autoClosed]);

    function toggleDeleteDialogOpen(): void {
        setDeleteDialogOpen(!isDeleteDialogOpen);
    }

    return (
        <FluentModal {...modalProps}>
            <div className={rootStyle}>
                <header>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.subTitle}>{subTitle}</div>
                </header>
                <section className={styles.body}>{children}</section>
                <div className={styles.footer}>
                    {(props.onDeleteClick || props.onEditClick) &&
                    props.editButton.title === 'Edit' ? (
                        <Stack horizontal horizontalAlign='space-between'>
                            <Stack.Item>
                                <Stack horizontal horizontalAlign='start' styles={stackItemStyle}>
                                    {props.onEditClick && (
                                        <Stack.Item styles={stackItemStyle} align='start'>
                                            <DefaultButton
                                                disabled={
                                                    props.actionButtonDisabled &&
                                                    !isDeleteDialogOpen
                                                }
                                                iconProps={{ iconName: props.editButton.iconName }}
                                                onClick={props.onEditClick}>
                                                {props.editButton.title}
                                            </DefaultButton>
                                        </Stack.Item>
                                    )}
                                    {props.onDeleteClick && (
                                        <Stack.Item styles={stackItemStyle}>
                                            <DefaultButton
                                                disabled={
                                                    props.actionButtonDisabled &&
                                                    !isDeleteDialogOpen
                                                }
                                                iconProps={{ iconName: 'delete' }}
                                                onClick={
                                                    props.deleteDialogProps
                                                        ? toggleDeleteDialogOpen
                                                        : props.onDeleteClick
                                                }>
                                                Delete
                                            </DefaultButton>
                                        </Stack.Item>
                                    )}
                                </Stack>
                            </Stack.Item>

                            <Stack.Item styles={stackItemStyle}>
                                <DefaultButton onClick={props.onCloseClick}>
                                    {props.closeTitle}
                                </DefaultButton>
                            </Stack.Item>
                        </Stack>
                    ) : (
                        <Stack horizontal horizontalAlign='end'>
                            <Stack.Item styles={stackItemStyle}>
                                <DefaultButton onClick={props.onCloseClick}>
                                    {props.closeTitle}
                                </DefaultButton>
                            </Stack.Item>
                            {props.onEditClick && (
                                <Stack.Item styles={stackItemStyle}>
                                    <PrimaryButton
                                        disabled={props.actionButtonDisabled && !isDeleteDialogOpen}
                                        iconProps={{
                                            iconName: props.editButton.iconName,
                                        }}
                                        onClick={props.onEditClick}>
                                        {props.editButton.title}
                                    </PrimaryButton>
                                </Stack.Item>
                            )}
                        </Stack>
                    )}
                </div>
            </div>
            {props.deleteDialogProps && (
                <Dialog
                    hidden={!isDeleteDialogOpen}
                    dialogContentProps={dialogContentProps}
                    modalProps={modalProps}>
                    <div className={styles.dialogFooter}>
                        <DialogFooter>
                            <DefaultButton
                                onClick={toggleDeleteDialogOpen}
                                text={props.deleteDialogProps?.cancelButtonText}
                            />
                            <PrimaryButton
                                disabled={props.actionButtonDisabled}
                                onClick={props.onDeleteClick}
                                text={props.deleteDialogProps?.confirmButtonText}
                            />
                        </DialogFooter>
                    </div>
                </Dialog>
            )}
        </FluentModal>
    );
}

const stackItemStyle: IStackItemStyles = {
    root: {
        paddingRight: 10,
    },
};

//Styles are taken from common/modal.tsx
const styles = mergeStyleSets({
    root: {
        minHeight: '172px',
        boxSizing: 'border-box',
        selectors: {
            '@media(max-width: 450px)': {
                minWidth: '100%',
                maxWidth: '100%',
            },
        },
    },
    rootDefaultWidth: {
        minWidth: '350px',
    },
    rootMediumSizeWidth: {
        minWidth: '370px',
    },
    title: [
        FontSizes.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${SharedColors.cyanBlue10}`,
            display: 'flex',
            fontSize: '24px',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '30px 35px 0 35px',
        },
    ],
    subTitle: [
        FontSizes.small,
        {
            flex: '1 1 auto',
            display: 'flex',
            fontSize: '14px',
            alignItems: 'center',
            padding: '0 35px',
        },
    ],
    body: {
        padding: '15px 35px 30px 35px',
        color: '#1b1b1b',
    },
    footer: {
        backgroundColor: '#FEFCFE',
        padding: '15px 15px 15px 15px',
        borderTop: '1px solid #EAE9EA',
        display: 'block',
    },
    modalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        overflowX: 'hidden',
    },
    separator: {
        fontSize: FontSizes.smallPlus,
    },
    dialogTitle: {
        whiteSpace: 'nowrap',
        flex: '1 1 auto',
        borderTop: `4px solid ${SharedColors.cyanBlue10}`,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
    },
    dialogFooter: {
        backgroundColor: '#FEFCFE',
        borderTop: '1px solid #EAE9EA',
        display: 'flex',
        padding: '0 24px 15px 0',
        margin: '0 -24px -24px -24px',
    },
});

export default BasicDetailsModal;
