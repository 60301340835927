import React from 'react';
import { screenReaderContentStyle } from 'assets/styles/global-styles';
import { IBreadCrumbContext } from 'contexts/breadcrumb-context';
import { Link } from 'react-router-dom';
import { faintBlue } from 'assets/constants/global-colors';
import { Breadcrumb, IBreadcrumbItem, mergeStyles, mergeStyleSets } from '@fluentui/react';

/**
 * @typedef ICustomBreadcrumbItem
 * @property {string} title
 * @property {string} link
 */
/**
 * The CustomBreadcrumb component
 * @param {object} props
 * @param {Array<ICustomBreadcrumbItem>} props.items
 */

interface CustomBreadcrumbProps {
    breadCrumbContext?: IBreadCrumbContext;
}

export interface ICustomBreadcrumbItem {
    title: string;
    link: string;
}

export function CustomBreadcrumb(props: CustomBreadcrumbProps): JSX.Element {
    const breadCrumbStyles = {
        root: [
            {
                paddingLeft: '3px',
            },
        ],
        item: {
            lineHeight: 'inherit',
        },
        itemLink: {
            lineHeight: 'inherit',
        },
        chevron: { fontSize: 12 },
    };

    function renderBreadcrumb(
        breadcrumbItem: IBreadcrumbItem | undefined | null,
    ): JSX.Element | null {
        if (breadcrumbItem) {
            if (!breadcrumbItem.href) {
                return (
                    <span
                        className={
                            breadcrumbItem.isCurrentItem
                                ? currentCrumbRootStyle
                                : defaultCrumbRootStyle
                        }>
                        {breadcrumbItem.text}
                    </span>
                );
            }
            return (
                <Link
                    to={breadcrumbItem.href}
                    className={
                        breadcrumbItem.isCurrentItem
                            ? currentCrumbRootStyle
                            : clickableCrumbRootStyle
                    }>
                    {breadcrumbItem.text}
                </Link>
            );
        }
        return null;
    }

    function currentCrumb(breadCrumbs: ICustomBreadcrumbItem[]): string {
        function last(breadCrumbs: ICustomBreadcrumbItem[]): ICustomBreadcrumbItem | undefined {
            return breadCrumbs[breadCrumbs.length - 1];
        }
        return last(breadCrumbs)?.title || 'Personnel';
    }

    return props.breadCrumbContext ? (
        <div className={styles.mainContainer}>
            <Breadcrumb
                styles={breadCrumbStyles}
                items={TransformContract(props.breadCrumbContext.breadCrumbs)}
                ariaLabel='Breadcrumb'
                overflowAriaLabel='More links'
                onRenderItem={(
                    breadCrumb: IBreadcrumbItem | undefined | null,
                ): JSX.Element | null => renderBreadcrumb(breadCrumb)}
            />
            <h1 className={screenReaderContentStyle}>
                {currentCrumb(props.breadCrumbContext.breadCrumbs)}
            </h1>
        </div>
    ) : (
        <></>
    );
}
/**
 * This function transform the original contract to make it compatible
 * with ui fabric breadcrumb.
 * @param {Array<BreadcrumbItem>} originalContract
 */
function TransformContract(originalContract: ICustomBreadcrumbItem[]): IBreadcrumbItem[] {
    return originalContract.map((item, ix) => {
        return {
            text: item.title,
            key: item.title?.toLowerCase(),
            href: item.link,
            isCurrentItem: ix === originalContract.length - 1,
        };
    });
}

const styles = mergeStyleSets({
    mainContainer: {
        width: '50%',
    },
});

/*
   Show both enabled and disabled crumbs with the same style
   to keep breadcrumb styling consistent.
   An enabled crumb will be identified when mouse hovers on it.
*/

const defaultCrumbRootStyle = mergeStyles({
    height: 'initial',
    fontFamily:
        '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
    color: '#605e5c', // ie, rgb(96, 94, 92)
    textDecoration: 'none',
    padding: '0px 4px',
});

const clickableCrumbRootStyle = mergeStyles(defaultCrumbRootStyle, {
    '&:hover': {
        cursor: 'pointer',
        color: faintBlue,
    },
});

const currentCrumbRootStyle = mergeStyles(defaultCrumbRootStyle, {
    color: '#323130', // ie, rgb(50, 49, 48)
    fontWeight: 600,
});
