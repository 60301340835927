import React, { useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import CheckRole from 'components/common/check-role';
import { Role } from 'configs/roles';
import { IconNames } from 'assets/constants/global-constants';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { TextField, IPersonaProps } from '@fluentui/react';
import { REGEX_EMAIL } from 'utils/misc-utils';
import { IEmployee } from 'clients/employee-client';
import { getEmployeeFromPersona } from 'utils/internal-persona-utils';
import { globalStyles } from 'assets/styles/global-styles';
import VisitorClient, { IVisitorProfile } from 'clients/visitor-client';
import { useEmployeeInfo } from 'components/common/employee/internal-employee-utils';

export enum VisitorProfileModeEnum {
    add = 'add',
    update = 'update',
}

interface IProps {
    mode: VisitorProfileModeEnum;
    visitor?: IVisitorProfile;
    overrideButtonText?: string;
    onVisitorAdded?: (visitor: IVisitorProfile) => void;
    onVisitorUpdated?: (visitor: IVisitorProfile) => void;
}

export default function VisitorProfileModalActionButton(props: IProps): JSX.Element {
    const addMode = () => props.mode === VisitorProfileModeEnum.add;

    const authContext = useContext(AuthContext);

    const [firstName, setFirstName] = useState<string>();
    const [middleName, setMiddleName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [company, setCompany] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [sponsor, setSponsor] = useState<IEmployee>();

    const [firstNameError, setFirstNameError] = useState<string>();
    const [lastNameError, setLastNameError] = useState<string>();
    const [emailError, setEmailError] = useState<string>();
    const [companyError, setCompanyError] = useState<string>();
    const [titleError, setTitleError] = useState<string>();
    const [sponsorError, setSponsorError] = useState<string>();

    const sponsorEmployeeRecord = useEmployeeInfo({ authContext, id: props?.visitor?.sponsor });

    const onInitialize = (): void => {
        setFirstName(props?.visitor?.firstName);
        setMiddleName(props?.visitor?.middleName || undefined); // Because middleName may be null
        setLastName(props?.visitor?.lastName);
        setEmail(props?.visitor?.email);
        setCompany(props?.visitor?.company || undefined); // Because company may be null
        setTitle(props?.visitor?.title || undefined); // Because title may be null
        setSponsor(sponsorEmployeeRecord);
        setFirstNameError(undefined);
        setLastNameError(undefined);
        setEmailError(undefined);
        setCompanyError(undefined);
        setTitleError(undefined);
        setSponsorError(undefined);
    };

    const isSubmitEnabled =
        !!firstName &&
        !firstNameError &&
        !!lastName &&
        !lastNameError &&
        !!email &&
        !emailError &&
        !!company &&
        !companyError &&
        !!title &&
        !titleError &&
        !!sponsor &&
        !sponsorError;

    const onEmployeeSelect = (info?: IPersonaProps): void => {
        const employeeVar = getEmployeeFromPersona(info);
        setSponsor(employeeVar);
        if (!!employeeVar) {
            setSponsorError(undefined);
        } else {
            setSponsorError('Sponsor must be a valid Microsoft employee');
        }
    };

    const onSubmitCreate = async (): Promise<IVisitorProfile | void> => {
        try {
            // The following type casts are safe because the variable
            // isSubmitEnabled will prevent the code from reaching here
            // if either of the following is undefined.
            return await VisitorClient.createVisitor(authContext, {
                firstName: firstName as string,
                middleName: middleName as string,
                lastName: lastName as string,
                email: email as string,
                company: company as string,
                title: title as string,
                sponsor: sponsor?.id as string,
            });
        } catch (e) {
            throw 'Error creating user profile';
        }
    };

    const onSubmitUpdate = async (): Promise<IVisitorProfile | void> => {
        try {
            // The following type casts are safe because the variable
            // isSubmitEnabled will prevent the code from reaching here
            // if either of the following is undefined.
            return await VisitorClient.updateVisitor(authContext, props.visitor?.id as string, {
                firstName: firstName as string,
                middleName: middleName as string,
                lastName: lastName as string,
                email: email as string,
                company: company as string,
                title: title as string,
                sponsor: sponsor?.id as string,
            });
        } catch (e) {
            throw 'Error updating user profile';
        }
    };

    const onModalConcluded = (conclusion: ModalConclusion, result?: IVisitorProfile): void => {
        if (conclusion === ModalConclusion.Done) {
            // The following type casts are safe,
            // otherwise modal conclusion wouldn't have been ModalConclusion.Done.
            if (addMode()) {
                if (props.onVisitorAdded) {
                    props.onVisitorAdded(result as IVisitorProfile);
                }
            } else {
                if (props.onVisitorUpdated) {
                    props.onVisitorUpdated(result as IVisitorProfile);
                }
            }
        }
    };

    return (
        <CheckRole requiredRolesAny={[Role.VisitorRecordWrite]}>
            <ModalActionButton
                text={
                    addMode()
                        ? 'Create Visitor Profile'
                        : props.overrideButtonText
                        ? props.overrideButtonText
                        : 'Edit'
                }
                iconName={addMode() ? IconNames.AddToShoppingList : IconNames.Edit}
                modalTitle={addMode() ? 'Create Visitor Profile' : 'Edit Visitor Profile'}
                enableSubmit={isSubmitEnabled}
                modalTitleIcon={IconNames.AddToShoppingList}
                submitButtonText={addMode() ? 'Create Profile' : 'Update Profile'}
                onSubmit={addMode() ? onSubmitCreate : onSubmitUpdate}
                onButtonClick={onInitialize}
                onModalConcluded={onModalConcluded}>
                <TextField
                    label='First Name'
                    value={firstName || ''}
                    maxLength={50}
                    required
                    onChange={(e, value): void => {
                        setFirstName(value);
                    }}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value) => {
                        const msg = !!value ? '' : 'First name is required';
                        setFirstNameError(msg);
                        return msg;
                    }}
                />
                <TextField
                    label='Middle Name'
                    value={middleName || ''}
                    maxLength={50}
                    onChange={(e, value): void => {
                        setMiddleName(value);
                    }}
                />
                <TextField
                    label='Last Name'
                    value={lastName || ''}
                    required
                    maxLength={50}
                    onChange={(e, value): void => {
                        setLastName(value);
                    }}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value) => {
                        const msg = !!value ? '' : 'Last name is required';
                        setLastNameError(msg);
                        return msg;
                    }}
                />
                <TextField
                    label='Email'
                    value={email || ''}
                    maxLength={255}
                    required
                    onChange={(e, value): void => {
                        setEmail(value);
                    }}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value) => {
                        const msg = !!REGEX_EMAIL.test(value ?? '')
                            ? ''
                            : 'A valid email address is required';
                        setEmailError(msg);
                        return msg;
                    }}
                />
                <TextField
                    label='Company'
                    value={company || ''}
                    required
                    maxLength={50}
                    onChange={(e, value): void => {
                        setCompany(value);
                    }}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value) => {
                        const msg = !!value ? '' : 'Company is required';
                        setCompanyError(msg);
                        return msg;
                    }}
                />
                <TextField
                    label='Title'
                    value={title || ''}
                    required
                    maxLength={50}
                    onChange={(e, value): void => {
                        setTitle(value);
                    }}
                    validateOnLoad={false}
                    validateOnFocusOut={true}
                    onGetErrorMessage={(value) => {
                        const msg = !!value ? '' : 'Title is required';
                        setTitleError(msg);
                        return msg;
                    }}
                />
                <EmployeePickerTypeaheadSearch
                    label='Sponsor'
                    required
                    placeHolder='Employee Name or Alias'
                    onCandidateSelected={onEmployeeSelect}
                />
                {!!sponsorError && (
                    <span className={globalStyles.inputFieldError}>{sponsorError}</span>
                )}
            </ModalActionButton>
        </CheckRole>
    );
}
