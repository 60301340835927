import {
    DetailsList,
    DetailsRow,
    IColumn,
    IDetailsRowProps,
    mergeStyles,
    mergeStyleSets,
    PrimaryButton,
    SearchBox,
    SelectionMode,
    Spinner,
} from '@fluentui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import FormsClient, { GenericForm } from 'clients/forms-client';
import { IconNames } from 'assets/constants/global-constants';
import formThumb from 'assets/img/form.png';
import { globalStyles } from 'assets/styles/global-styles';
import { AuthContext } from 'contexts/auth-context';

type FormsTemplatesProps = {
    forms: GenericForm[];
    setForms: React.Dispatch<React.SetStateAction<GenericForm[]>>;
};

export function FormsTemplates(props: FormsTemplatesProps): JSX.Element {
    const { forms, setForms } = props;
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const [filteredForms, setFilteredForms] = useState<GenericForm[]>(forms);

    const getAllForms = async (): Promise<void> => {
        const response = await FormsClient.GetAllGenericForms(authContext);
        const sorted = response.sort((a, b) => Number(a.isSoftDeleted) - Number(b.isSoftDeleted));
        setForms(sorted);
        setFilteredForms(sorted);
    };
    useEffect(() => {
        getAllForms();
    }, []);

    const searchForms = (value?: string) => {
        if (!value) {
            setFilteredForms(forms);
            return;
        }
        const matchingForms = forms.filter(
            (form) =>
                form.title.toLowerCase().includes(value.toLowerCase()) ||
                form.description.toLowerCase().includes(value.toLowerCase()) ||
                form.name.toLowerCase().includes(value.toLowerCase()),
        );
        setFilteredForms(matchingForms ?? []);
    };

    const customRenderRow = (props?: IDetailsRowProps): JSX.Element => {
        return props ? (
            <DetailsRow
                {...props}
                className={
                    (props?.item as GenericForm).isSoftDeleted
                        ? detailStyle.deletedfields
                        : detailStyle.activefields
                }
            />
        ) : (
            <></>
        );
    };

    const detailStyle = mergeStyleSets({
        activefields: {
            alignItems: 'center',
            color: '#323130',
            fontSize: '14px',
            fontWeight: 400,
            backgroundColor: 'white',
            '& .link-106': {
                color: '#0078d4',
            },
        },
        deletedfields: {
            alignItems: 'center',
            color: '#323130',
            fontSize: '14px',
            fontWeight: 400,
            backgroundColor: '#f8f7f7',
            '& .link-106': {
                color: '#004070',
            },
        },
        imageStyle: {
            width: '100%',
            aspectRatio: '3 / 2',
            objectFit: 'cover',
        },
    });

    const columns = useMemo(
        (): IColumn[] => [
            {
                key: 'status',
                name: '',
                iconName: IconNames.ImagePixel,
                minWidth: 60,
                maxWidth: 70,
                onRender: (item: GenericForm) => (
                    <img
                        className={detailStyle.imageStyle}
                        src={item.image ?? formThumb}
                        aria-label='Form Thumbnail'
                    />
                ),
            },
            {
                key: 'title',
                name: 'Form Type',
                fieldName: 'title',
                minWidth: 120,
                maxWidth: 300,
                onRender: (item: GenericForm): JSX.Element => {
                    return (
                        <Link
                            to={`/forms/edit/${item.name}`}
                            className={globalStyles.link}
                            style={{ textDecoration: 'none' }}>
                            {item.title}
                        </Link>
                    );
                },
            },
            {
                key: 'name',
                name: 'Form name',
                fieldName: 'name',
                minWidth: 120,
                maxWidth: 220,
            },
            {
                key: 'published',
                name: 'Published',
                fieldName: 'isPublished',
                minWidth: 120,
                maxWidth: 140,
            },
            {
                key: 'subtitle',
                name: 'Subtitle',
                fieldName: 'subtitle',
                minWidth: 120,
                maxWidth: 240,
            },
        ],
        [],
    );

    return (
        <>
            <PrimaryButton
                aria-label='Create Form'
                style={{ marginTop: '2rem' }}
                onClick={() => history.push('/forms/edit')}>
                Create Form
            </PrimaryButton>
            {forms.length === 0 ? (
                <Spinner style={{ margin: '10% 0 0 50%' }} />
            ) : (
                <>
                    <div style={{ margin: '1rem 2rem 1rem auto', width: '17rem' }}>
                        <SearchBox
                            placeholder='Find form by name or description...'
                            onChange={(ev, newValue) => searchForms(newValue)}
                            onClear={() => searchForms(undefined)}
                        />
                    </div>
                    <DetailsList
                        items={filteredForms}
                        selectionMode={SelectionMode.none}
                        columns={columns}
                        onRenderRow={customRenderRow}
                    />
                </>
            )}
        </>
    );
}
