import { HoverCard, HoverCardType, mergeStyleSets, PersonaSize } from '@fluentui/react';
import { IBasicEmployee } from 'clients/employee-client';
import React from 'react';
import EmployeeCard from 'components/common/employee/employee-card';
import EmployeeMiniCard from 'components/common/employee/employee-mini-card';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';

/**
 * To keep this component efficient, we won't add code to
 * it to fetch employee basic data or their image. For that
 * we already have the component EmployeeBasicHoverCard.
 * We'll keep this component simple, like its name suggests.
 *
 * For using this component, we'll fetch basic employee data
 * and their pictures and pass them to this one. We can use
 * the custom hook useEmployeeBasicRecords to easily fetch
 * employee records and images.
 */

interface IShowHoverCardProps {
    employeeBasicData: IBasicEmployee | undefined;
    showMiniCardAlias?: boolean; // Default true
    showFullName?: boolean; // Default true
    displayActions?: boolean; // Default true
    image: string | undefined;
}

export default function EmployeeBasicHoverCardSimple(props: IShowHoverCardProps): JSX.Element {
    const showMiniCardAlias = props.showMiniCardAlias ?? true;
    const showFullName = props.showFullName ?? true;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const displayActions = props.displayActions ?? true;

    function onRenderPlainCard(cardProps: {
        displayName: string;
        onPremisesSamAccountName: string;
        mail: string;
        department: string;
        managerName: string;
        reportsToEmailName: string;
        jobTitle: string;
        image: string;
        displayActions: boolean;
        oid: string;
    }): JSX.Element {
        return (
            <EmployeeCard
                employee={{
                    displayName: cardProps.displayName,
                    alias: cardProps.onPremisesSamAccountName,
                    email: cardProps.mail,
                    jobTitle: cardProps.jobTitle,
                    department: cardProps.department,
                }}
                manager={cardProps.managerName}
                managerAlias={cardProps.reportsToEmailName}
                displayActions={cardProps.displayActions}
                image={cardProps.image}
                oid={cardProps.oid}
            />
        );
    }

    const plainCardProps = {
        onRenderPlainCard: onRenderPlainCard,
        renderData: {
            ...props.employeeBasicData,
            image: props.image,
            displayActions: displayActions,
        },
    };

    const inlineStyle = mergeStyleSets({
        host: {
            display: 'inline-block',
        },
    });

    if (!props.employeeBasicData) return <></>;
    return (
        <HoverCard
            styles={inlineStyle}
            plainCardProps={plainCardProps}
            instantOpenOnClick={true}
            cardOpenDelay={300}
            expandedCardOpenDelay={3000}
            type={HoverCardType.plain}>
            <EmployeeMiniCard
                imageSize={PersonaSize.size24}
                showAlias={showMiniCardAlias}
                showFullName={showFullName}
                showImage={true}
                employee={{
                    alias: props.employeeBasicData.onPremisesSamAccountName,
                    displayName: getDisplayNameOrDefault(
                        props.employeeBasicData,
                        props.employeeBasicData.id,
                    ),
                    isActiveEmployee: props.employeeBasicData.isActiveEmployee,
                }}
                imageUrl={props.image}
                key={`employee_minicard_${props.employeeBasicData.id}`}
            />
        </HoverCard>
    );
}
