import {
    GroupRole,
    IGroup,
    CompliantStatus,
    GroupMemberStatus,
    IGroupMembership,
    IMyGroup,
} from 'clients/group-client';
import { BadgeColorHex } from 'assets/constants/global-colors';
import { Dictionary } from 'assets/constants/global-constants';

export const groupJoinRequestUrl = (group: IGroup): string => {
    // Example http://localhost:4200/groups/086d60e8-f8a9-4233-89aa-ceb0f5e342f2/request
    return `${window.location.origin}/groups/${group.id}/request`;
};

export const percentCompliant = (group: IGroup): number => {
    let percentCompliant = 0;
    if ((group.metrics?.memberCount ?? 0) > 0) {
        const compliant: number =
            (group.metrics?.memberCount ?? 0) - (group.metrics?.violationCount ?? 0);
        percentCompliant = (compliant / (group.metrics?.memberCount ?? 0)) * 100;
    }
    return percentCompliant;
};

export const getCompliantStatus = (
    isCompliant: boolean,
    isOnGracePeriod: boolean,
    isAllowListed: boolean,
): string => {
    return getCompliantStatusTitleAndColor(isCompliant, isOnGracePeriod, isAllowListed).title;
};

export const getCompliantColor = (
    isCompliant: boolean,
    isOnGracePeriod: boolean,
    isAllowListed: boolean,
): string => {
    return getCompliantStatusTitleAndColor(isCompliant, isOnGracePeriod, isAllowListed).color;
};

export const canManage = (groupRole: IMyGroup | IGroupMembership): boolean => {
    const { role } = groupRole;
    // manager/auditor cannot edit group settings, nor can they delete a group
    return role === GroupRole.MANAGER || role === GroupRole.OWNER || role === GroupRole.AUDITOR;
};

export const canCheckMember = (row: IMyGroup): boolean => {
    return row.compliant || canManage(row);
};

export const canTouchRoleOrApp = (groupRole: IGroupMembership | undefined): boolean => {
    if (!groupRole) {
        return false;
    }
    const { role } = groupRole;
    return role === GroupRole.OWNER || role === GroupRole.ADMIN;
};

export const canNominate = (groupRole: IMyGroup): boolean => {
    if (groupRole.compliant) {
        //Members can nominate only if they are compliant and group allows
        return (
            groupRole.role === GroupRole.OWNER ||
            groupRole.role === GroupRole.MANAGER ||
            groupRole.role === GroupRole.AUDITOR ||
            (groupRole.role === GroupRole.MEMBER && groupRole.allowMemberNomination)
        );
    } else {
        //Auditors, managers, and owners can nominate regardless of compliance or if group allows
        return (
            groupRole.role === GroupRole.OWNER ||
            groupRole.role === GroupRole.MANAGER ||
            groupRole.role === GroupRole.AUDITOR
        );
    }
};

type CompliantStatusType = {
    title: CompliantStatus;
    color: BadgeColorHex;
};

export const compliantStatuses: Dictionary<CompliantStatusType> = {
    onGracePeriod: {
        title: CompliantStatus.GRACE_PERIOD,
        color: BadgeColorHex.YELLOW,
    },
    allowList: {
        title: CompliantStatus.ALLOW_LIST,
        color: BadgeColorHex.GRAY,
    },
    compliant: {
        title: CompliantStatus.COMPLIANT,
        color: BadgeColorHex.GREEN,
    },
    notCompliant: {
        title: CompliantStatus.NOT_COMPLIANT,
        color: BadgeColorHex.RED,
    },
};

function getCompliantStatusTitleAndColor(
    isCompliant: boolean,
    isOnGracePeriod: boolean,
    isAllowListed: boolean,
): CompliantStatusType {
    if (isOnGracePeriod) {
        return compliantStatuses.onGracePeriod;
    }
    if (isAllowListed) {
        return compliantStatuses.allowList;
    }
    if (isCompliant === null || isCompliant) {
        return compliantStatuses.compliant;
    }
    return compliantStatuses.notCompliant;
}

export enum ModalAddEditType {
    add = 'add',
    update = 'update',
}

export const compliantGroupCount = (groupRoles: undefined | IMyGroup[]): number => {
    return !!groupRoles
        ? groupRoles.filter(
              (group) =>
                  group.status === GroupMemberStatus.APPROVED && !group.hasViolationOnGracePeriod,
          ).length
        : 0;
};

export const notCompliantGroupCount = (groups: undefined | IMyGroup[]): number => {
    return !!groups
        ? groups.filter((group) => group.status === GroupMemberStatus.QUARANTINED).length
        : 0;
};

export const onGracePeriodCount = (groups: undefined | IMyGroup[]): number => {
    return !!groups
        ? groups.filter(
              (group) =>
                  group.status === GroupMemberStatus.APPROVED && group.hasViolationOnGracePeriod,
          ).length
        : 0;
};
