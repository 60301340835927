import React, { useEffect, useMemo, useState } from 'react';
import { IRenderFunction } from '@fluentui/react';
import ICardPrincipal from 'components/core/common/employee-card/card-principal';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import CardPrincipalProviderFromGraph from 'components/core/common/employee-card/card-principal-provider-from-graph';

export interface ICardPrincipalProviderFromPrincipalRecordProps {
    principalRecord?: IPrincipalRecord;
    displayName?: string;
    cardPrincipal?: ICardPrincipal;
    onRender: IRenderFunction<ICardPrincipal>;
}

export default function CardPrincipalProviderFromPrincipalRecord(
    props: ICardPrincipalProviderFromPrincipalRecordProps,
): JSX.Element {
    const { principalRecord, cardPrincipal, onRender } = props;

    const combinedCardPrincipal = useMemo(
        () =>
            ({
                ...cardPrincipal,
                displayName:
                    principalRecord?.displayName ??
                    cardPrincipal?.displayName ??
                    props.displayName ??
                    '',
                upn: principalRecord?.upn ?? cardPrincipal?.upn,
                isActive: principalRecord?.isActive,
            } as ICardPrincipal),
        [
            cardPrincipal,
            principalRecord?.displayName,
            principalRecord?.isActive,
            principalRecord?.upn,
        ],
    );

    return (
        <CardPrincipalProviderFromGraph
            cardPrincipal={combinedCardPrincipal}
            oid={principalRecord?.oid}
            onRender={onRender}
        />
    );
}
