import {
    ActionButton,
    DatePicker,
    DefaultButton,
    FontIcon,
    IPersonaProps,
    Label,
    Panel,
    PanelType,
    PrimaryButton,
    TextField,
    mergeStyleSets,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import React, { useCallback, useMemo, useState } from 'react';
import { IFilter } from 'components/core/common/filterableTable';
import { CoreSinglePrincipalPersonaPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import { datePlusDays, isoDateStringToUtcMilliseconds } from 'utils/time-utils';
import { AttributeSetsListColumnKeys } from 'components/core/attribute-sets/attribute-sets-list';

export interface IAttributeSetFilterPanelProps {
    isOpen?: boolean;
    onDismiss: () => void;
    onSuccess: (filters: Map<string, IFilter>) => void;
    onClearFilters: () => void;
    onMinimizePanel: () => void;
}

export default function AttributeSetFilterPanel(props: IAttributeSetFilterPanelProps): JSX.Element {
    const [attributeSetName, setAttributeSetName] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [modifiedAfter, setModifiedAfter] = useState<Date | undefined>();
    const [modifiedBefore, setModifiedBefore] = useState<Date | undefined>();
    const [owner, setOwner] = useState<IPersonaProps | undefined>();
    const [manager, setManager] = useState<IPersonaProps | undefined>();
    const [reader, setReader] = useState<IPersonaProps | undefined>();
    const [previousAttributeSetName, setPreviousAttributeSetName] = useState<string>();
    const [previousDescription, setPreviousDescription] = useState<string>();
    const [previousOwner, setPreviousOwner] = useState<IPersonaProps | undefined>();
    const [previousManager, setPreviousManager] = useState<IPersonaProps | undefined>();
    const [previousReader, setPreviousReader] = useState<IPersonaProps | undefined>();
    const [previousModifiedBefore, setPreviousModifiedBefore] = useState<Date | undefined>();
    const [previousModifiedAfter, setPreviousModifiedAfter] = useState<Date | undefined>();

    const buttonStyles = useMemo(() => ({ root: { marginRight: 8 } }), []);

    const savePreviousFilters = useCallback(() => {
        setPreviousAttributeSetName(attributeSetName);
        setPreviousDescription(description);
        setPreviousOwner(owner);
        setPreviousManager(manager);
        setPreviousReader(reader);
        setPreviousModifiedBefore(modifiedBefore);
        setPreviousModifiedAfter(modifiedAfter);
    }, [attributeSetName, description, owner, manager, reader, modifiedBefore, modifiedAfter]);

    const saveFilters = useCallback((): Map<string, IFilter> => {
        const newFilter = new Map<string, IFilter>();
        newFilter.set(AttributeSetsListColumnKeys.AttributeSet, {
            values: [attributeSetName || ''],
        });
        newFilter.set(AttributeSetsListColumnKeys.Description, { values: [description || ''] });
        newFilter.set(AttributeSetsListColumnKeys.LastModifiedBy, {
            values: [
                (isoDateStringToUtcMilliseconds(modifiedAfter?.toString()) / 1000).toString() ??
                    '0',
                (isoDateStringToUtcMilliseconds(modifiedBefore?.toString()) / 1000).toString() ??
                    '0',
            ],
        });
        newFilter.set(AttributeSetsListColumnKeys.Owners, {
            values: owner ? [owner.itemID ?? ''] : [],
        });
        newFilter.set(AttributeSetsListColumnKeys.Managers, {
            values: manager ? [manager.itemID ?? ''] : [],
        });
        newFilter.set(AttributeSetsListColumnKeys.Readers, {
            values: reader ? [reader.itemID ?? ''] : [],
        });
        return newFilter;
    }, [attributeSetName, description, modifiedAfter, modifiedBefore, owner, manager, reader]);

    const onSubmitClick = useCallback((): void => {
        savePreviousFilters();
        const newFilter = saveFilters();
        props.onSuccess(newFilter);
    }, [props, savePreviousFilters, saveFilters]);

    const restorePreviousFilters = useCallback(() => {
        setAttributeSetName(previousAttributeSetName);
        setDescription(previousDescription);
        setOwner(previousOwner);
        setManager(previousManager);
        setReader(previousReader);
        setModifiedBefore(previousModifiedBefore);
        setModifiedAfter(previousModifiedAfter);
    }, [
        previousAttributeSetName,
        previousDescription,
        previousOwner,
        previousManager,
        previousReader,
        previousModifiedBefore,
        previousModifiedAfter,
    ]);

    const onCancel = useCallback((): void => {
        restorePreviousFilters();
        props.onDismiss();
    }, [props, restorePreviousFilters]);

    function clearFilters(): void {
        // Clear current filters
        setAttributeSetName('');
        setDescription('');
        setModifiedAfter(undefined);
        setModifiedBefore(undefined);
        setOwner(undefined);
        setReader(undefined);
        setManager(undefined);

        // Clear previous filters
        setPreviousAttributeSetName('');
        setPreviousDescription('');
        setPreviousOwner(undefined);
        setPreviousManager(undefined);
        setPreviousReader(undefined);
        setPreviousModifiedBefore(undefined);
        setPreviousModifiedAfter(undefined);
        props.onClearFilters();
    }

    const onRenderFooterContent = React.useCallback(
        () => (
            <div>
                <PrimaryButton onClick={onSubmitClick} styles={buttonStyles}>
                    {'Apply'}
                </PrimaryButton>
                <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
            </div>
        ),
        [buttonStyles, onCancel, onSubmitClick],
    );

    return (
        <Panel
            headerText={'Filter'}
            isOpen={props.isOpen}
            closeButtonAriaLabel='Close'
            onRenderFooterContent={onRenderFooterContent}
            onDismiss={(): void => onCancel()}
            isLightDismiss
            onLightDismissClick={(): void => onCancel()}
            isFooterAtBottom={true}
            type={PanelType.custom}
            customWidth={'360px'}
            styles={styles.panel}>
            <Label style={styles.label}>Attribute set</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <TextField
                    placeholder='Filter by attribute set'
                    value={attributeSetName}
                    maxLength={MaxLens.name}
                    onChange={(ev, newVal): void => {
                        setAttributeSetName(newVal);
                    }}
                    width={'450px'}
                    styles={styles.element}
                />
                <FontIcon
                    aria-label='Search'
                    iconName='Search'
                    className={mergedstyles.searchIcon}
                />
            </div>
            <Label style={styles.label}>Description</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <TextField
                    placeholder={'Filter by description'}
                    value={description}
                    maxLength={MaxLens.name}
                    styles={styles.element}
                    onChange={(ev, newVal): void => {
                        setDescription(newVal);
                    }}
                />
                <FontIcon
                    aria-label='Search'
                    iconName='Search'
                    className={mergedstyles.searchIcon}
                />
            </div>

            <Label style={styles.label}>Owner</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <CoreSinglePrincipalPersonaPickerTypeaheadSearch
                    ariaLabel='Owner'
                    placeHolder='Filter by owner name or alias'
                    required
                    onChange={(personna): void => {
                        setOwner(personna);
                    }}
                    selectedItem={owner}
                />
                <FontIcon
                    aria-label='Search'
                    iconName='Search'
                    className={mergedstyles.searchIcon}
                />
            </div>
            <Label style={styles.label}>Manager</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <CoreSinglePrincipalPersonaPickerTypeaheadSearch
                    ariaLabel='Manager'
                    placeHolder='Filter by manager name or alias'
                    required
                    onChange={(personna): void => {
                        setManager(personna);
                    }}
                    selectedItem={manager}
                />
                <FontIcon
                    aria-label='Search'
                    iconName='Search'
                    className={mergedstyles.searchIcon}
                />
            </div>
            <Label style={styles.label}>Reader</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <CoreSinglePrincipalPersonaPickerTypeaheadSearch
                    ariaLabel='Reader'
                    placeHolder='Filter by reader name or alias'
                    required
                    onChange={(personna): void => {
                        setReader(personna);
                    }}
                    selectedItem={reader}
                />
                <FontIcon
                    aria-label='Search'
                    iconName='Search'
                    className={mergedstyles.searchIcon}
                />
            </div>
            <Label style={styles.label}>Modified after</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <DatePicker
                    placeholder={'Select a date...'}
                    allowTextInput={true}
                    value={modifiedAfter}
                    maxDate={datePlusDays(new Date(), -1)}
                    onSelectDate={(date): void => {
                        setModifiedAfter(date ?? undefined);
                    }}
                />
            </div>
            <Label style={styles.label}>Modified before</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <DatePicker
                    placeholder={'Select a date...'}
                    allowTextInput={true}
                    value={modifiedBefore}
                    onSelectDate={(date): void => {
                        setModifiedBefore(date ?? undefined);
                    }}
                />
            </div>
            <div style={{ marginTop: '10px' }}>
                <ActionButton
                    iconProps={{ iconName: IconNames.ClearFilter }}
                    onClick={(): void => clearFilters()}>
                    Clear filters
                </ActionButton>
            </div>
        </Panel>
    );
}

const MaxLens = {
    employees: 1,
    name: 32,
    description: 128,
    support: 250,
};

const styles = {
    label: {
        marginTop: '25px',
        padding: 0,
        height: '20px',
    },
    button: {
        marginTop: '20px',
        marginBottom: '5px',
        padding: 0,
    },
    element: {
        root: {
            marginTop: '5px',
            width: '312px',
            borderRadius: '4px',
            background: '#FFF',
            borderColor: '#D1D1D1',
        },
    },
    panel: {
        root: {
            marginTop: '65px',
        },
    },
};

const mergedstyles = mergeStyleSets({
    element: { position: 'relative' },
    searchIcon: {
        position: 'absolute',
        right: '6px',
        top: '2.5px',
        fontSize: '20px',
        transform: 'scaleX(-1)',
        color: '#616161',
    },
});
