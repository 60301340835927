import React from 'react';
import {
    FontSizes,
    FontWeights,
    mergeStyleSets,
    Persona,
    PersonaInitialsColor,
    PersonaPresence,
    PersonaSize,
} from '@fluentui/react';
import { IEmployee, IEmployeeEditableInfo } from 'clients/employee-client';
import { getImageUrlFromAlias } from 'utils/photo-utils';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { globalStyles } from 'assets/styles/global-styles';
import { getInitials } from 'components/common/employee/employee-utils';

export interface CreateNewProfileStepperReviewProps {
    newProfile: IEmployeeEditableInfo;
    hiringManager: IEmployee;
}

export default function CreateNewProfileStepperReview(
    props: CreateNewProfileStepperReviewProps,
): JSX.Element {
    return (
        <div className={styles.root}>
            <div className={styles.firstColumn}>
                <div className={styles.cardWrapper}>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>First Name</div>
                        <div className={styles.value}>{props.newProfile.firstName}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Middle Name</div>
                        <div className={styles.value}>{props.newProfile.middleName}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Last Name</div>
                        <div className={styles.value}>{props.newProfile.lastName}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Suffix</div>
                        <div className={styles.value}>{props.newProfile.suffix}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>SSN</div>
                        <div className={styles.value}>{props.newProfile.nationalIdNumber}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>PCN</div>
                        <div className={styles.value}>
                            {props.newProfile.positionNumber ? props.newProfile.positionNumber : ''}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Start Date</div>
                        <div className={styles.value}>
                            {props.newProfile.startDate
                                ? new Date(props.newProfile.startDate).toDateString()
                                : ''}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Job Title</div>
                        <div className={styles.value}>{props.newProfile.standardTitle}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Company</div>
                        <div className={styles.value}>{props.newProfile.employeeType}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Hiring Manager</div>
                        <div className={styles.multiValue}>
                            {props.hiringManager ? (
                                <>
                                    <Persona
                                        className={styles.icon}
                                        size={PersonaSize.size24}
                                        initialsColor={PersonaInitialsColor.purple}
                                        presence={PersonaPresence.none}
                                        hidePersonaDetails={true}
                                        imageInitials={getInitials(props.hiringManager.displayName)}
                                        key={props.hiringManager.alias}
                                        showInitialsUntilImageLoads={true}
                                        imageUrl={getImageUrlFromAlias(props.hiringManager.alias)}
                                    />
                                    <EmployeeHoverCard personnelId={props.hiringManager.id}>
                                        <span className={styles.name}>
                                            {props.hiringManager.displayName}
                                        </span>
                                        {props.hiringManager.alias && (
                                            <span className={globalStyles.fontStylesAlias}>
                                                ({props.hiringManager.alias.toLowerCase()})
                                            </span>
                                        )}
                                    </EmployeeHoverCard>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.secondColumn}>
                <div className={styles.cardWrapper}>
                    <div className={styles.row}>
                        <div className={styles.secondColumnKey}>Personal Phone Number</div>
                        <div className={styles.value}>{props.newProfile.personalCellPhone}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.secondColumnKey}>Personal Email Address</div>
                        <div className={styles.value}>{props.newProfile.personalEmail}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.secondColumnKey}>Home Address</div>
                        <div className={styles.value}>{props.newProfile.homeAddress}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.secondColumnKey}>Home City</div>
                        <div className={styles.value}>{props.newProfile.homeCity}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.secondColumnKey}>Home State</div>
                        <div className={styles.value}>{props.newProfile.homeState}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.secondColumnKey}>Home Zip</div>
                        <div className={styles.value}>{props.newProfile.homeZip}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.secondColumnKey}>Home Country</div>
                        <div className={styles.value}>{props.newProfile.homeCountry}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const UNIFORM_WIDTH = 430;

const UNIFORM_RIGHT_MARGIN = 15;

const UNIFORM_BOTTOM_MARGIN = 15;

const styles = mergeStyleSets({
    root: {
        display: 'flex',
    },
    firstColumn: {
        display: 'flex',
        width: '445px',
    },
    secondColumn: {
        display: 'flex',
        width: '445px',
    },
    cardWrapper: {
        marginRight: UNIFORM_RIGHT_MARGIN,
        marginBottom: UNIFORM_BOTTOM_MARGIN,
        width: UNIFORM_WIDTH,
    },
    personalContactWrapper: {
        width: UNIFORM_WIDTH,
        marginRight: UNIFORM_RIGHT_MARGIN,
    },
    contactDetailsWrapper: {
        width: UNIFORM_WIDTH,
        marginBottom: UNIFORM_BOTTOM_MARGIN,
    },
    requestDetailsWrapper: {
        width: UNIFORM_WIDTH,
    },
    row: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '4px 0',
    },
    firstColumnKey: {
        width: 150,
        fontWeight: '500',
    },
    secondColumnKey: {
        width: 190,
        fontWeight: '500',
    },
    value: {
        width: 'calc(100% - 140px)',
    },
    multiValue: {
        display: 'flex',
        width: 'calc(100% - 140px)',
    },
    icon: {
        fontSize: '1rem',
        marginRight: '7px',
    },
    name: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.medium,
        marginRight: '5px',
        borderRadius: 2,
    },
});
