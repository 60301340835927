import React, { useMemo } from 'react';
import {
    CommandBar,
    ICommandBarStyleProps,
    ICommandBarStyles,
    ICommandBarItemProps,
    CommandBarButton,
    IButtonProps,
    mergeStyleSets,
    FontSizes,
    IStyleFunctionOrObject,
    IContextualMenuItem,
    ContextualMenuItem,
    IContextualMenuItemProps,
} from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import { Link } from 'react-router-dom';

/**
 * @constant - The styling applied to the component in general.
 */
const menuHeight = '41px';
const uniformBgColor = '#242424';
const activeMenu = 'hsla(0,0%,100%,.75)';

/**
 * @constant - Specific styling applied to each menu item of the first level.
 * The submenu items styling are inherited for now.
 * @todo - Update this, if a specific styling is added to the sub menus and further.
 */
const customCommandBarButtonStyles = {
    root: {
        height: '100%',
        backgroundColor: uniformBgColor,
        color: NeutralColors.white,
        padding: '0px 16px',
        border: '1px solid',
        borderColor: uniformBgColor,
    },
    rootHovered: {
        backgroundColor: activeMenu,
        color: NeutralColors.black,
    },
    rootFocused: {
        backgroundColor: activeMenu,
        color: NeutralColors.black,
        borderColor: uniformBgColor,
    },
    rootPressed: {
        backgroundColor: activeMenu,
        color: NeutralColors.black,
        borderColor: uniformBgColor,
    },
    rootExpanded: {
        backgroundColor: activeMenu,
        color: NeutralColors.black,
        borderColor: uniformBgColor,
    },
    rootExpandedHovered: {
        backgroundColor: activeMenu,
        color: NeutralColors.black,
    },
    menuIcon: {
        color: NeutralColors.white,
        fontSize: '8px',
        height: '16px',
        lineHeight: '18px',
    },
    menuIconHovered: {
        color: NeutralColors.black,
    },
    menuIconPressed: {
        color: NeutralColors.black,
    },
    menuIconExpanded: {
        backgroundColor: 'transparent',
        color: `${NeutralColors.black} !important`,
    },
    menuIconExpandedHovered: {
        backgroundColor: 'transparent',
        color: NeutralColors.black,
    },
    label: {
        fontSize: FontSizes.medium,
    },
};

export interface IMenuItem {
    title: string;
    link?: string;
    visible: boolean;
    icon?: string;
    subMenu?: IMenuItem[];
    onClick?: (
        ev?: React.MouseEvent<HTMLElement | HTMLButtonElement> | React.KeyboardEvent<HTMLElement>,
        item?: IContextualMenuItem,
    ) => boolean | void;
}

export interface MenuProps {
    menuItems: IMenuItem[];
    onMenuItemClicked?: () => void;
}

const isExcludedRoute = (href: string): boolean => {
    const excludedRoutes = ['/screening/public-trust', '/screening/us-gov'];
    return excludedRoutes.some((route) => href?.startsWith(route));
};

const isExternalUrl = (href: string | undefined): boolean =>
    !!href && (href.startsWith('https://') || href.startsWith('http://'));

export function Menu(props: MenuProps): JSX.Element {
    const generalStyles = mergeStyleSets({
        mainContainer: {
            maxHeight: menuHeight,
            backgroundColor: uniformBgColor,
            boxSizing: 'border-box',
            marginRight: 'auto',
        },
        barWrapper: {
            boxSizing: 'border-box',
            margin: '0px 10px',
        },
    });
    /**
     * @constant - Specific styling applied to the CommandBar/MenuBar
     */
    const commanBarStyles: IStyleFunctionOrObject<ICommandBarStyleProps, ICommandBarStyles> = {
        root: {
            backgroundColor: uniformBgColor,
            boxSizing: 'border-box',
            paddingLeft: '0px',
            height: menuHeight,
        },
    };

    const items = useMemo(
        () => TransformToUIFabricContract(props.menuItems, props.onMenuItemClicked),
        [props.menuItems, props.onMenuItemClicked],
    );

    return items && items.length > 0 ? (
        <div className={generalStyles.mainContainer}>
            <div className={generalStyles.barWrapper} role='group'>
                <CommandBar
                    styles={commanBarStyles}
                    buttonAs={CustomCommandBarButton}
                    items={items}
                    ariaLabel='Use left and right arrow keys to navigate between commands'
                    overflowButtonProps={{
                        ariaLabel: 'Menu',
                        styles: {
                            root: { backgroundColor: uniformBgColor },
                            rootHovered: { backgroundColor: uniformBgColor },
                            menuIcon: { color: '#fff' },
                            menuIconHovered: { color: '#fff' },
                        },
                    }}
                />
            </div>
        </div>
    ) : (
        <></>
    );
}

function CustomCommandBarButton(props: IButtonProps): JSX.Element {
    if (props.href) {
        const commandBarButton = (
            <CommandBarButton
                text={props.text}
                styles={{
                    ...customCommandBarButtonStyles,
                }}
            />
        );

        if (isExternalUrl(props.href)) {
            return (
                <a
                    href={props.href}
                    target={props.target}
                    style={{ all: 'unset' }}
                    rel={props.rel}
                    role='menuitem'>
                    {commandBarButton}
                </a>
            );
        }

        return (
            <Link
                to={props.href}
                target={props.target}
                style={{ all: 'unset' }}
                rel={props.rel}
                role='menuitem'>
                {commandBarButton}
            </Link>
        );
    }

    return (
        <CommandBarButton
            {...props}
            styles={{
                ...customCommandBarButtonStyles,
            }}
        />
    );
}

const subMenuStyles = {
    subComponentStyles: {
        menuItem: {
            icon: { color: 'rgb(102, 102, 102)' },
            label: {
                paddingLeft: '12px',
            },
        },
        callout: {
            calloutMain: {
                overflowY: 'hidden',
            },
        },
    },
};

function TransformToUIFabricContract(
    menuItems: IMenuItem[],
    onMenuItemClicked?: () => void,
): ICommandBarItemProps[] {
    const returnObject: ICommandBarItemProps[] = [];
    if (menuItems && menuItems.length > 0) {
        menuItems.forEach((menuItem): void => {
            const element = makeICommandBarItem(menuItem, onMenuItemClicked);
            if (element) {
                const subMenus = menuItem.subMenu;
                if (subMenus && subMenus.length > 0) {
                    const subElements = TransformToUIFabricContract(subMenus, onMenuItemClicked);
                    const subMenuItems = makeISubMenuItems(element, subElements);
                    element.href = undefined;
                    if (subElements) {
                        element.subMenuProps = {
                            styles: subMenuStyles,
                            items: subMenuItems,
                            contextualMenuItemAs: renderMenuItem,
                        };
                    }
                }
                returnObject.push(element);
            }
        });
    }
    return returnObject;
}

/**
 * Method to determine whether ContextualMenuItem should be wrapped in Link component.
 * For excluded routes, the command bar will not be wrapped in Link. This is necessary
 * for angular routes, and for routes that don't support SPA style navigation.
 *
 */
const renderMenuItem = (props: IContextualMenuItemProps) => {
    return <ContextualMenuItem {...props} />;
};

function makeISubMenuItems(
    parentElement: ICommandBarItemProps,
    subMenus: ICommandBarItemProps[],
): IContextualMenuItem[] {
    return subMenus.map(
        (x): IContextualMenuItem => {
            return {
                key: `${parentElement.key}_${x.text?.toLowerCase()}`,
                text: x.text,
                link: x.link,
                iconProps: x.iconProps,
                href: x.link,
                subMenuProps: x.subMenuProps,
                onClick: x.onClick,
            };
        },
    );
}

function makeICommandBarItem(
    element: IMenuItem,
    onMenuItemClicked?: () => void,
): ICommandBarItemProps | undefined {
    let retItem: ICommandBarItemProps | undefined;
    if (element.visible) {
        retItem = {
            text: element.title,
            key: element.title.toLowerCase(),
            href: element.link,
            link: element.link,
            onClick: () => {
                if (element.onClick) {
                    element.onClick();
                }
                if (onMenuItemClicked) {
                    onMenuItemClicked();
                }
            },
        };
        retItem.iconProps = element.icon
            ? {
                  iconName: element.icon,
              }
            : undefined;

        if (isExternalUrl(element.link)) {
            retItem.target = '_blank';
            retItem.rel = 'noreferrer';
        }
    }
    return retItem;
}
