/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React, { useState, useContext, useMemo, useCallback } from 'react';
import {
    ProgressIndicator,
    IPersonaProps,
    MessageBarType,
    DefaultButton,
    PrimaryButton,
    Panel,
    PanelType,
} from '@fluentui/react';
import ConfigurableButtonsStepper, {
    ConfigurableButtonsStepperProps,
    ConfigurableStepperDefaultButtonsProps,
} from 'components/common/configurable-buttons-stepper';
import CandidateNominationStepContractDetails from 'components/screening/common/nomination/steps/common/candidate-nomination-step-contract-details';
import CandidateNominationStepBusinessJustification from 'components/screening/common/nomination/steps/common/candidate-nomination-step-business-justification';
import CandidateNominationStepNominationRequirements from 'components/screening/common/nomination/steps/common/candidate-nomination-step-nomination-requirements';
import FteCandidateNominationStepChooseCandidate from 'components/screening/common/nomination/steps/fte/fte-candidate-nomination-step-choose-candidate';
import EmployeeClient, { IBasicEmployee, IEmployee } from 'clients/employee-client';
import {
    ClearanceLevelType,
    EmploymentType,
    ScreeningPaths,
} from 'components/screening/common/common-constants';
import { SpecialAccesses } from 'components/personnel-profile/clearance/profile-clearance-constants';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import CandidateNominationStep from 'components/screening/common/nomination/candidate-nomination-step';
import CandidateNominationStepEmploymentType from 'components/screening/common/nomination/steps/common/candidate-nomination-step-employment-type';
import CandidateNominationStepTargetLevelUsGov from 'components/screening/us-gov/candidates/nomination/steps/common/candidate-nomination-step-target-level';
import FteCandidateNominationStepReviewUsGov from 'components/screening/us-gov/candidates/nomination/steps/fte/fte-candidate-nomination-step-review';
import PrehireCandidateNominationStepEmployeeLegalInfo from 'components/screening/us-gov/candidates/nomination/steps/vendor/prehire-candidate-nomination-step-legal-information';
import PrehireCandidateNominationStepPositionDetails from 'components/screening/us-gov/candidates/nomination/steps/vendor/prehire-candidate-nomination-step-position-details';
import CandidateNominationStepPersonalContacts from 'components/screening/us-gov/candidates/nomination/steps/common/candidate-nomination-step-personal-contacts';
import { AuthContext, IAuthContext } from 'contexts/auth-context';
import {
    ICreateNominationRequest,
    UsGovRequestTypes,
} from 'components/screening/us-gov/IScreening';
import PrehireCandidateNominationStepReview from 'components/screening/us-gov/candidates/nomination/steps/vendor/prehire-candidate-nomination-step-review';
import { displayErrorsWithHTML } from 'utils/error-display-utils';
import { IStepValidationResult } from 'types/step-validation-result';
import { PHONE_NUMBER_MAX_LENGTH, REGEX_EMAIL, REGEX_PHONE } from 'utils/misc-utils';
import {
    ICreatePublicTrustNominationRequest,
    PublicTrustRequestTypes,
} from 'components/screening/public-trust/public-trust-screening-result';
import { Dictionary } from 'assets/constants/global-constants';
import PublicTrustScreeningClient from 'clients/screening/public-trust-screening-client';
import CandidateNominationStepTargetLevelPublicTrust from 'components/screening/public-trust/candidates/nomination/steps/common/candidate-nomination-step-target-level';
import FteCandidateNominationStepReviewPublicTrust from 'components/screening/public-trust/candidates/nomination/steps/fte/fte-candidate-nomination-step-review';
import {
    convertIPublicTrustToCommonScreening,
    convertIScreeningToCommonScreening,
    ICommonScreening,
} from 'components/screening/common/ICommonScreening';
import {
    removeAllWhitespaceAndSlashesFromString,
    removeWhiteSpaceFromString,
} from 'utils/string-utils';
import { ContractStatus, ContractType } from 'components/screening/us-gov/IContract';
import { SuitabilityAgencies } from 'components/personnel-profile/suitability/profile-suitability-types';
import { PANEL_WIDTH, panelStyles } from 'components/screening/common/nominee-accept';

export interface IClearanceLevel {
    key: string;
    name: string;
    additionalAttributes?: {
        SI?: boolean;
        G?: boolean;
        TK?: boolean;
        HCS?: boolean;
        KLM?: boolean;
    };
}

export interface IRequestType {
    name: string;
    key: string;
}

interface CandidateNominationStringProps {
    isUsGovOnly?: boolean;
    isPublicTrustOnly?: boolean;
    isFteOnly?: boolean;
    isPreHireOnly?: boolean;
    stepTitle: string;
    stepSubTitle?: string;
    stepWidth: number;
    children?: JSX.Element;
}

export interface CandidateNominationStepperProps {
    isOpen: boolean;
    containerClassName: string;
    onDismiss: () => void;
    hideNominationProcess: (nomination?: ICommonScreening) => void;
    onNominationError: (msg: string) => void;
    screeningPath: ScreeningPaths;
}

export default function CandidateNominationStepperCommon(
    props: CandidateNominationStepperProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [isValidating, setIsValidating] = useState<boolean>();
    //Pre-hire
    const [firstNamePrehire, setFirstNamePrehire] = useState<string>();
    const [middleNamePrehire, setMiddleNamePrehire] = useState<string>();
    const [lastNamePrehire, setLastNamePrehire] = useState<string>();
    const [suffixPrehire, setSuffixPrehire] = useState<string>();

    const [pcnPrehire, setPcnPrehire] = useState<string | undefined>();
    const [managerPrehire, setManagerPrehire] = useState<IEmployee | undefined>();
    const [managerPersonaPrehire, setManagerPersonaPrehire] = useState<IPersonaProps | undefined>();
    const [titlePrehire, setTitlePrehire] = useState<string>();
    const [expectedStartDatePrehire, setExpectedStartDatePrehire] = useState<
        Date | undefined | null
    >();
    const [personalPhoneNumberPrehire, setPersonalPhoneNumberPrehire] = useState<string>();
    const [emailAddressPrehire, setEmailAddressPrehire] = useState<string>();
    const [homeAddressPrehire, setHomeAddressPrehire] = useState<string>();

    const [requestTypePublicTrust, setRequestTypePublicTrust] = useState<
        PublicTrustRequestTypes | undefined
    >();
    const [requestAgency, setRequestAgency] = useState<SuitabilityAgencies | undefined>();
    const [suitabilityLevel, setSuitabilityLevel] = useState<string | undefined>();
    const [isPIVRequired, setIsPIVRequired] = useState<boolean>(false);

    const [govContract, setGovContract] = useState<string>('');

    const [businessJustification, setBusinessJustification] = useState<string>();
    const [isBusinessJustificationConfirmed, setBusinessJustificationConfirmed] = useState<boolean>(
        false,
    );

    const [employmentType, setEmploymentType] = useState<string>(EmploymentType.FteKey);
    const [selectedPersona, setSelectedPersona] = useState<IPersonaProps | undefined>();
    const [selectedEmployee, setSelectedEmployee] = useState<IEmployee | undefined>();
    const [selectedEmployeeManager, setSelectedEmployeeManager] = useState<
        IBasicEmployee | undefined
    >();

    const [requestTypeUsGov, setRequestTypeUsGov] = useState<UsGovRequestTypes | undefined>();
    const [clearanceLevel, setClearanceLevel] = useState<IClearanceLevel | undefined>();
    const [customerBadgeRequired, setCustomerBadgeRequired] = useState<string | undefined>();

    const [screeningPathDisplay, screeningPathContractType] = useMemo((): [
        string,
        keyof typeof ContractType,
    ] => {
        switch (props.screeningPath) {
            case ScreeningPaths.UsGov:
                return ['US Gov', 'USGovScreening'];
            case ScreeningPaths.PublicTrust:
                return ['Public Trust', 'PublicTrust'];
        }
    }, [props.screeningPath]);

    const handleCandidateChange = (
        persona: IPersonaProps | undefined,
        employee: IEmployee | undefined,
    ): void => {
        setSelectedPersona(persona);
        setSelectedEmployee(employee);
        populateSelectedEmployeeManager(employee);
    };

    async function populateSelectedEmployeeManager(employee: IEmployee | undefined): Promise<void> {
        if (!employee) {
            setSelectedEmployeeManager(undefined);
            return;
        }
        try {
            const basicResults = await EmployeeClient.getBasicEmployeesByAlias(authContext, [
                employee.reportsToEmailName,
            ]);
            const manager = basicResults[0];
            setSelectedEmployeeManager(manager);
        } catch (e) {
            setMessage('Failed to retrieve manager information.');
            setMessageType(MessageBarType.error);
            setHasMessage(true);
        }
    }

    //common
    async function getContractStatus(
        authContext: IAuthContext,
        contractId: string,
        contractType: keyof typeof ContractType,
    ): Promise<ContractStatus | undefined> {
        setIsValidating(true);
        try {
            const contractStatus = await UsGovScreeningClient.validateContract(
                authContext,
                contractId,
                contractType,
            );
            setIsValidating(false);
            return contractStatus;
        } catch (error) {
            setIsValidating(false);
            return undefined;
        }
    }

    function ConstructAccesses(): string[] {
        const access: string[] = [];

        const attributes =
            employmentType === EmploymentType.FteKey && clearanceLevel?.additionalAttributes
                ? clearanceLevel!.additionalAttributes
                : clearanceLevel?.additionalAttributes
                ? clearanceLevel!.additionalAttributes
                : {};

        for (const specialAccess in SpecialAccesses) {
            if (
                specialAccess in attributes &&
                attributes[specialAccess as keyof typeof attributes]
            ) {
                access.push(specialAccess);
            }
        }

        return access;
    }

    const nominationStepObject: Dictionary<CandidateNominationStringProps> = {
        nominationStep: {
            stepTitle: 'Nomination for screening',
            stepSubTitle:
                props.screeningPath === ScreeningPaths.UsGov
                    ? 'Use this workflow to nominate a candidate for clearance screening.'
                    : 'Use this workflow to nominate a candidate for a Public Trust screening.',
            stepWidth: 520,
            children: (
                <CandidateNominationStepNominationRequirements
                    screeningPath={props.screeningPath}
                />
            ),
        },
        preHireOrFteSelectionStep: {
            stepTitle: 'Identify candidate',
            stepSubTitle:
                props.screeningPath === ScreeningPaths.UsGov
                    ? 'Is this candidate a current Microsoft employee, or a pre-hire or otherwise external candidate?'
                    : 'This candidate is a current Microsoft employee:',
            stepWidth: 450,
            children: (
                <CandidateNominationStepEmploymentType
                    screeningPath={props.screeningPath}
                    onChange={setEmploymentType}
                    selectedKey={employmentType}
                />
            ),
        },
        preHireCandidateNameStep: {
            isPreHireOnly: true,
            stepTitle: 'Pre-hire candidate',
            stepSubTitle: "Enter candidate's legal name.",
            stepWidth: 450,
            children: (
                <PrehireCandidateNominationStepEmployeeLegalInfo
                    firstName={firstNamePrehire}
                    lastName={lastNamePrehire}
                    middleName={middleNamePrehire}
                    suffix={suffixPrehire}
                    onFirstNameChange={(newValue): void => setFirstNamePrehire(newValue)}
                    onLastNameChange={(newValue): void => setLastNamePrehire(newValue)}
                    onMiddleNameChange={(newValue): void => setMiddleNamePrehire(newValue)}
                    onSuffixChange={(newValue): void => setSuffixPrehire(newValue)}
                />
            ),
        },
        preHireCandidatePositionDetailsStep: {
            isPreHireOnly: true,
            stepTitle: 'Pre-hire candidate',
            stepSubTitle: "Enter candidate's position details.",
            stepWidth: 450,
            children: (
                <>
                    <PrehireCandidateNominationStepPositionDetails
                        selectedManagerPersona={managerPersonaPrehire}
                        pcn={pcnPrehire}
                        selectedDate={expectedStartDatePrehire}
                        title={titlePrehire}
                        onDateChange={(date): void => setExpectedStartDatePrehire(date)}
                        onSelectedManagerChange={(managerPersona): void => {
                            if (managerPersona && managerPersona.itemProp) {
                                const manager = JSON.parse(managerPersona?.itemProp!);
                                setManagerPrehire(manager);
                            } else {
                                setManagerPrehire(undefined);
                            }
                            setManagerPersonaPrehire(managerPersona);
                        }}
                        onPcnChange={(pcn): void => setPcnPrehire(pcn ? pcn.trim() : pcn)}
                        onTitleChange={(title): void => setTitlePrehire(title)}
                    />

                    {isValidating && (
                        <ProgressIndicator label='Validating...' description='PCN Validation' />
                    )}
                </>
            ),
        },
        personalContactsStep: {
            isPreHireOnly: true,
            stepTitle: 'Personal contacts',
            stepWidth: 450,
            children: (
                <CandidateNominationStepPersonalContacts
                    emailAddress={emailAddressPrehire}
                    homeAddress={homeAddressPrehire}
                    phoneNumber={personalPhoneNumberPrehire}
                    onEmailAddressChange={(email): void => setEmailAddressPrehire(email)}
                    onHomeAddressChange={(address): void => setHomeAddressPrehire(address)}
                    onPhoneNumberChange={(phone): void => setPersonalPhoneNumberPrehire(phone)}
                />
            ),
        },
        chooseFteOrVendorStep: {
            isFteOnly: true,
            stepTitle: 'Identify candidate',
            stepSubTitle: 'Select a Microsoft FTE or vendor:',
            stepWidth: 450,
            children: (
                <FteCandidateNominationStepChooseCandidate
                    handleCandidateChange={handleCandidateChange}
                    selectedPersona={selectedPersona}
                    selectedEmployee={selectedEmployee}
                    selectedEmployeeManager={selectedEmployeeManager}
                />
            ),
        },
        contractDetailsStep: {
            stepTitle: 'Contract details',
            stepWidth: 470,
            children: (
                <>
                    <CandidateNominationStepContractDetails
                        contractInput={govContract}
                        onContractInputChange={(contract: string): void => {
                            setGovContract(contract);
                        }}
                    />
                    {isValidating && (
                        <ProgressIndicator
                            label='Validating...'
                            description='Government Contract Validation'
                        />
                    )}
                </>
            ),
        },
        requestDetailsStepPublicTrust: {
            isPublicTrustOnly: true,
            stepTitle: 'Request details',
            stepWidth: 450,
            children: (
                <CandidateNominationStepTargetLevelPublicTrust
                    onRequestTypeChange={setRequestTypePublicTrust}
                    onSuitabilityLevelChange={setSuitabilityLevel}
                    onRequestAgencyChange={setRequestAgency}
                    onIsPIVRequiredChange={setIsPIVRequired}
                    requestType={requestTypePublicTrust}
                    requestAgency={requestAgency}
                    isPIVRequired={isPIVRequired}
                    suitabilityLevel={suitabilityLevel}
                />
            ),
        },
        requestDetailsStepUsGov: {
            isUsGovOnly: true,
            stepTitle: 'Request details',
            stepWidth: 450,
            children: (
                <CandidateNominationStepTargetLevelUsGov
                    onRequestTypeChange={setRequestTypeUsGov}
                    onClearanceLevelChange={setClearanceLevel}
                    onCustomerBadgeRequiredChange={setCustomerBadgeRequired}
                    employementType={employmentType!}
                    requestType={requestTypeUsGov}
                    clearanceLevel={clearanceLevel}
                    customerBadgeRequired={customerBadgeRequired}
                />
            ),
        },
        businssJustificationStep: {
            stepTitle: 'Request details',
            stepWidth: 450,
            children: (
                <CandidateNominationStepBusinessJustification
                    screeningPath={props.screeningPath}
                    employementType={employmentType!}
                    businessJustification={businessJustification}
                    onBusinessJustificationChange={setBusinessJustification}
                    onConfirmed={setBusinessJustificationConfirmed}
                />
            ),
        },
        reviewCandidateFteUsGovStep: {
            isFteOnly: true,
            isUsGovOnly: true,
            stepTitle: 'Review candidate',
            stepWidth: 850,
            children: (
                <FteCandidateNominationStepReviewUsGov
                    employee={{
                        alias: selectedEmployee?.onPremisesSamAccountName!,
                        department: selectedEmployee?.department!,
                        displayName: selectedEmployee?.displayName!,
                        email: selectedEmployee?.email!,
                        jobTitle: selectedEmployee?.jobTitle!,
                        managerAlias: selectedEmployee?.reportsToEmailName!,
                        managerName: selectedEmployeeManager?.displayName!,
                    }}
                    contractID={govContract}
                    businessJustification={businessJustification!}
                    badgeRequired={customerBadgeRequired!}
                    request={{
                        type: requestTypeUsGov!,
                        clearanceLevel: clearanceLevel?.name || '',
                        accesses: clearanceLevel?.additionalAttributes,
                    }}
                />
            ),
        },
        reviewCandidateFtePublicTrustStep: {
            isFteOnly: true,
            isPublicTrustOnly: true,
            stepTitle: 'Review candidate',
            stepWidth: 850,
            children: (
                <FteCandidateNominationStepReviewPublicTrust
                    employee={{
                        alias: selectedEmployee?.onPremisesSamAccountName!,
                        department: selectedEmployee?.department!,
                        displayName: selectedEmployee?.displayName!,
                        email: selectedEmployee?.email!,
                        jobTitle: selectedEmployee?.jobTitle!,
                        managerAlias: selectedEmployee?.reportsToEmailName!,
                        managerName: selectedEmployeeManager?.displayName!,
                    }}
                    contractID={govContract}
                    businessJustification={businessJustification!}
                    requestType={requestTypePublicTrust!}
                    requestAgency={requestAgency!}
                    isPIVRequired={isPIVRequired}
                    suitabilityLevel={suitabilityLevel!}
                />
            ),
        },
        reviewCandidatePreHireUsGovStep: {
            isUsGovOnly: true,
            isPreHireOnly: true,
            stepTitle: 'Review candidate',
            stepWidth: 850,
            children: (
                <PrehireCandidateNominationStepReview
                    employee={{
                        alias: '',
                        department: '',
                        displayName: `${firstNamePrehire} ${
                            middleNamePrehire ? middleNamePrehire : ''
                        } ${lastNamePrehire} ${suffixPrehire ? suffixPrehire : ''}`,
                        email: '',
                        personalEmail: emailAddressPrehire!,
                        homeAddress: homeAddressPrehire!,
                        jobTitle: titlePrehire!,
                        managerAlias: managerPrehire?.alias!,
                        managerName: managerPrehire?.displayName!,
                        phoneNumber: personalPhoneNumberPrehire!,
                    }}
                    contractID={govContract}
                    businessJustification={businessJustification!}
                    badgeRequired={customerBadgeRequired!}
                    request={{
                        type: requestTypeUsGov!,
                        clearanceLevel: clearanceLevel?.name || '',
                        accesses: clearanceLevel?.additionalAttributes,
                    }}
                />
            ),
        },
    };

    const steps: { stepName: string; element: JSX.Element }[] = Object.entries(nominationStepObject)
        .filter(
            ([, x]) =>
                (employmentType === EmploymentType.FteKey ? !x.isPreHireOnly : !x.isFteOnly) &&
                (props.screeningPath === ScreeningPaths.UsGov
                    ? !x.isPublicTrustOnly
                    : !x.isUsGovOnly),
        )
        .map(([k, x], index) => {
            return {
                stepName: k,
                element: (
                    <CandidateNominationStep
                        key={index + 1}
                        stepTitle={x.stepTitle}
                        stepSubTitle={x.stepSubTitle}
                        stepWidth={x.stepWidth}>
                        {x.children}
                    </CandidateNominationStep>
                ),
            };
        });

    const [isTheFirstStep, setIsTheFirstStep] = useState(true);
    const [isLastStep, setIsLastStep] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [hasMessage, setHasMessage] = useState(false);
    const [message, setMessage] = useState<any>('');
    const [messageType, setMessageType] = useState<MessageBarType>();
    const [shouldDisableFinishButton, setShouldDisableFinishButton] = useState(false);

    const stepperProps: ConfigurableButtonsStepperProps = {
        isFirstStep: isTheFirstStep,
        isLastStep: isLastStep,
        finishText: 'Nominate',
        activeStep: activeStep,
        hasMessage: hasMessage,
        messageType: messageType,
        messageText: message,
        finishIndicatorLabel: 'Saving nomination...',
        handleResetMessage: () => setHasMessage(false),
        handleCancel: () => {
            props.hideNominationProcess();
        },
        renderContent: () => {
            const newIndex = activeStep;
            return steps[newIndex].element;
        },
        handleBack: () => {
            const previousStepIndex = activeStep - 1;
            if (previousStepIndex === 0) setIsTheFirstStep(true);
            if (isLastStep) setIsLastStep(false);
            setActiveStep(previousStepIndex);
            if (hasMessage) {
                setHasMessage(false);
                setMessage('');
                setMessageType(undefined);
            }
            //Force the user to always re-check the confirmation checkbox
            if (isBusinessJustificationConfirmed) setBusinessJustificationConfirmed(false);
        },
        handleNext: async () => {
            const validationResult = await currentStepDataIsValid();
            if (validationResult.valid) {
                if (isTheFirstStep) setIsTheFirstStep(false);
                if (activeStep === steps.length - 2) setIsLastStep(true);
                setActiveStep(activeStep + 1);
                if (hasMessage) {
                    setHasMessage(false);
                    setMessage('');
                    setMessageType(undefined);
                }
            } else {
                const message = displayErrorsWithHTML(
                    validationResult.messages?.length ? validationResult.messages : [],
                );
                setMessage(message);
                setMessageType(MessageBarType.error);
                setHasMessage(true);
            }
        },
        handleFinish: async () => {
            setShouldDisableFinishButton(true);
            if (props.screeningPath === ScreeningPaths.UsGov) {
                if (employmentType === EmploymentType.FteKey) {
                    if (selectedEmployee && govContract) {
                        const request: ICreateNominationRequest = {
                            businessJustification: businessJustification!,
                            personnelId: selectedEmployee.id,
                            governmentContract: govContract,
                            contact: {
                                homePhone: 'N/A',
                                homeAddress: 'N/A',
                                homeEmail: 'N/A',
                            },
                            requestDetails: {
                                //review this. Maybe change the datatype of customerBadgeRequired to boolean
                                isCustomerBadgeRequired:
                                    customerBadgeRequired === 'YES' ? true : false,
                                clearance: clearanceLevel!.name,
                                requestType: requestTypeUsGov!,
                                access: ConstructAccesses(), //maybe handle this differently to avoid magic strings
                            },
                        };

                        try {
                            const response = await UsGovScreeningClient.createNomination(
                                authContext,
                                request,
                            );
                            const convertedResponse = convertIScreeningToCommonScreening(response);
                            props.hideNominationProcess(convertedResponse);
                        } catch (e) {
                            console.error(e);
                            props.onNominationError(e);
                            props.hideNominationProcess();
                        }
                    } else {
                        //Alert: must select an employee
                        alert(
                            'The selected employee an the government contract number are needed in order to create a nomination',
                        );
                    }
                } else {
                    //TODO: implement this
                    const request: ICreateNominationRequest = {
                        businessJustification: businessJustification!,
                        personnelId: '',
                        preHire: {
                            firstName: firstNamePrehire!,
                            middleName: middleNamePrehire!,
                            lastName: lastNamePrehire!,
                            suffix: suffixPrehire!,
                            startDate: expectedStartDatePrehire!,
                            pcn: pcnPrehire!,
                            jobTitle: titlePrehire!,
                            manager: managerPrehire!.id,
                        },
                        governmentContract: govContract,
                        contact: {
                            homeAddress: homeAddressPrehire!,
                            homeEmail: emailAddressPrehire!,
                            homePhone: personalPhoneNumberPrehire!,
                        },
                        requestDetails: {
                            //review this. Maybe change the datatype of customerBadgeRequired to boolean
                            isCustomerBadgeRequired: customerBadgeRequired === 'YES',
                            clearance: clearanceLevel!.name,
                            requestType: requestTypeUsGov!,
                            access: ConstructAccesses(), //maybe handle this differently to avoid magic strings
                        },
                    };
                    try {
                        const response = await UsGovScreeningClient.createNomination(
                            authContext,
                            request,
                        );
                        const convertedResponse = convertIScreeningToCommonScreening(response);
                        props.hideNominationProcess(convertedResponse);
                    } catch (e) {
                        console.error(e);
                        props.onNominationError(e);
                        props.hideNominationProcess();
                    }
                }
            } else if (props.screeningPath === ScreeningPaths.PublicTrust) {
                if (employmentType === EmploymentType.FteKey) {
                    if (selectedEmployee && govContract) {
                        const convertedRequestType = removeWhiteSpaceFromString(
                            requestTypePublicTrust!,
                        );
                        const request: ICreatePublicTrustNominationRequest = {
                            businessJustification: businessJustification!,
                            contractId: govContract,
                            isPIVRequired,
                            personnelId: selectedEmployee.id,
                            requestType: convertedRequestType,
                            suitabilityLevel: removeWhiteSpaceFromString(suitabilityLevel ?? ''),
                        };

                        try {
                            const response = await PublicTrustScreeningClient.createNomination(
                                authContext,
                                request,
                                removeAllWhitespaceAndSlashesFromString(requestAgency ?? ''),
                            );
                            const convertedResponse = convertIPublicTrustToCommonScreening(
                                response,
                            );
                            props.hideNominationProcess(convertedResponse);
                        } catch (e) {
                            console.error(e);
                            props.onNominationError(e);
                            props.hideNominationProcess();
                        }
                    } else {
                        //Alert: must select an employee
                        alert(
                            'The selected employee an the government contract number are needed in order to create a nomination',
                        );
                    }
                } else {
                    //TODO: implement this
                    // const request: ICreatePublicTrustNominationRequest = {
                    //     businessJustification: businessJustificationPrehire!,
                    //     personnelId: '',
                    //     preHire: {
                    //         firstName: firstNamePreHire!,
                    //         middleName: middleNamePreHire!,
                    //         lastName: lastNamePreHire!,
                    //         suffix: suffixPreHire!,
                    //         startDate: expectedStartDatePrehire!,
                    //         pcn: pcnPrehire!,
                    //         jobTitle: titlePrehire!,
                    //         manager: managerPrehire!.id,
                    //     },
                    //     governmentContract: govContractPrehire,
                    //     contact: {
                    //         homeAddress: homeAddressPrehire!,
                    //         homeEmail: emailAddressPrehire!,
                    //         homePhone: personalPhoneNumberPrehire!,
                    //     },
                    //     requestDetails: {
                    //         //review this. Maybe change the datatype of customerBadgeRequired to boolean
                    //         isPIVRequired: customerBadgeRequired === 'YES' ? true : false,
                    //         trustType: clearanceLevel!.name,
                    //         requestType: requestType!.name,
                    //         requestAgency: '',
                    //         access: ConstructAccesses(), //maybe handle this differently to avoid magic strings
                    //     },
                    // };
                    // try {
                    //     const response = await PublicTrustScreeningClient.createNomination(
                    //         authContext,
                    //         request,
                    //     );
                    //     props.hideNominationProcess(response);
                    // } catch (e) {
                    //     console.error(e);
                    //     props.onNominationError(e);
                    //     props.hideNominationProcess();
                    // }
                }
            }
            setShouldDisableFinishButton(false);
        },
    };

    async function currentStepDataIsValid(): Promise<IStepValidationResult> {
        if (activeStep === 0) return { valid: true };
        if (activeStep === 1) {
            if (
                employmentType === EmploymentType.FteKey ||
                employmentType === EmploymentType.PrehireKey
            )
                return { valid: true };
            else
                return {
                    valid: false,
                    messages: ['The employment type must be selected.'],
                };
        }
        if (activeStep === 2) return isStep3Valid();
        if (activeStep === 3) return await isStep4Valid();
        if (activeStep === 4 && props.screeningPath === ScreeningPaths.UsGov) {
            return await isStep5Valid();
        } else if (activeStep === 4 && props.screeningPath === ScreeningPaths.PublicTrust) {
            return await isStep5ValidPublicTrust();
        }
        if (activeStep === 5) return await isStep6Valid();

        if (employmentType === EmploymentType.PrehireKey) {
            //only for Prehire
            if (activeStep === 6) return isStep7Valid();
            if (activeStep === 7) return isStep8Valid();
        }

        return { valid: false };
    }
    function isStep3Valid(): IStepValidationResult {
        if (employmentType === EmploymentType.FteKey) {
            if (
                selectedPersona !== undefined &&
                selectedEmployee !== undefined &&
                selectedEmployeeManager !== undefined
            )
                return { valid: true };
            else
                return {
                    valid: false,
                    messages: [
                        "The candidate must be selected. Please type the candidate's alias or name, then chose the desired employee.",
                    ],
                };
        } else {
            const msgs = [];
            if (firstNamePrehire === undefined || firstNamePrehire.trim() === '') {
                msgs.push('The First Name is required.');
            }
            if (lastNamePrehire === undefined || lastNamePrehire.trim() === '') {
                msgs.push('The Last Name is required.');
            }
            if (msgs.length !== 0) {
                return { valid: false, messages: msgs };
            } else return { valid: true };
        }
    }

    const invalidContractMessage = useMemo(() => {
        return (
            `Check that <strong>${govContract}</strong> is a valid and active <strong>${screeningPathDisplay}</strong> contract. If you think you are using a valid contract ID, contact the<a href="mailto:myclearance@microsoft.com">National Security Team (NST)</a>` +
            ` with the statement of work (SOW) and DD254 to be reviewed.`
        );
    }, [govContract]);

    async function isStep4Valid() {
        const msgs = [];
        if (employmentType === EmploymentType.FteKey) {
            if (govContract) {
                if (
                    (await getContractStatus(
                        authContext,
                        govContract,
                        screeningPathContractType,
                    )) === ContractStatus.Active
                ) {
                    return { valid: true };
                } else {
                    return {
                        valid: false,
                        messages: [invalidContractMessage],
                    };
                }
            } else {
                return {
                    valid: false,
                    messages: [
                        'The government contract must be selected. Please type the contract number and choose the appropriate project.',
                    ],
                };
            }
        } else {
            if (pcnPrehire) {
                setIsValidating(true);
                try {
                    await EmployeeClient.getPositionById(authContext, pcnPrehire);
                } catch (e) {
                    if (e.status === 404) {
                        msgs.push(`${pcnPrehire} is not a valid PCN.`);
                    } else {
                        msgs.push('Error retrieving validating PCN information');
                    }
                }
                setIsValidating(false);
            }

            if (managerPersonaPrehire === undefined) {
                msgs.push('The hiring manager should be selected.');
            }

            if (titlePrehire === undefined || titlePrehire.trim() === '') {
                msgs.push('The prospective title is required.');
            }
        }

        if (msgs.length !== 0) {
            return { valid: false, messages: msgs };
        } else return { valid: true };
    }

    //Returns true if access is not selected for SCI
    function shouldSelectAccess(clearanceLevel: IClearanceLevel): boolean {
        const attributes = clearanceLevel.additionalAttributes;
        const isAccessSelected =
            attributes && Object.values(attributes).some((attribute) => attribute);
        return clearanceLevel.key === ClearanceLevelType.SCI && !isAccessSelected;
    }

    async function isStep5ValidPublicTrust() {
        const msgs = [];
        if (employmentType === EmploymentType.FteKey) {
            const msgs = [];
            if (requestTypePublicTrust === undefined)
                msgs.push('The request type must be selected.');
            if (requestAgency === undefined) msgs.push('Request agency must be selected');
            if (!suitabilityLevel) {
                msgs.push('Suitability level must be selected');
            }
            if (isPIVRequired === undefined) msgs.push('PIV requirement selection is required.');
            if (msgs.length !== 0) {
                return { valid: false, messages: msgs };
            } else return { valid: true };
        } else {
            //TODO: create a function to validate each common step
            // const msgs = [];
            // if (
            //     personalPhoneNumberPrehire === undefined ||
            //     personalPhoneNumberPrehire.trim() === ''
            // ) {
            //     msgs.push('The personal phone number is required.');
            // } else if (personalPhoneNumberPrehire.length > PHONE_NUMBER_MAX_LENGTH) {
            //     msgs.push(
            //         `The phone number should be less than ${PHONE_NUMBER_MAX_LENGTH} characters long.`,
            //     );
            // } else if (!REGEX_PHONE.test(personalPhoneNumberPrehire)) {
            //     msgs.push('Invalid phone number.');
            // }
            // if (emailAddressPrehire === undefined || emailAddressPrehire.trim() === '') {
            //     msgs.push('The personal email address is required.');
            // } else if (!REGEX_EMAIL.test(emailAddressPrehire)) {
            //     msgs.push('Invalid email address.');
            // }
            // if (homeAddressPrehire === undefined || homeAddressPrehire.trim() === '') {
            //     msgs.push('The home address is required.');
            // }
            // if (msgs.length !== 0) {
            //     return { valid: false, messages: msgs };
            // } else
            return { valid: true };
        }
    }

    async function isStep5Valid() {
        const msgs = [];

        if (employmentType === EmploymentType.FteKey) {
            if (requestTypeUsGov === undefined) msgs.push('The request type must be selected.');
            if (
                clearanceLevel === undefined ||
                clearanceLevel.key === '' ||
                clearanceLevel.name === ''
            )
                msgs.push('The clearance level must be selected.');
            else {
                if (shouldSelectAccess(clearanceLevel)) {
                    msgs.push('Please select at least one access.');
                }
            }
            if (
                customerBadgeRequired === undefined ||
                (customerBadgeRequired !== 'YES' && customerBadgeRequired !== 'NO')
            )
                msgs.push('Please let us know if the candidate needs a customer badge.');
            if (msgs.length !== 0) {
                return { valid: false, messages: msgs };
            } else return { valid: true };
        } else {
            //TODO: create a function to validate each common step
            if (
                personalPhoneNumberPrehire === undefined ||
                personalPhoneNumberPrehire.trim() === ''
            ) {
                msgs.push('The personal phone number is required.');
            } else if (personalPhoneNumberPrehire.length > PHONE_NUMBER_MAX_LENGTH) {
                msgs.push(
                    `The phone number should be less than ${PHONE_NUMBER_MAX_LENGTH} characters long.`,
                );
            } else if (!REGEX_PHONE.test(personalPhoneNumberPrehire)) {
                msgs.push('Invalid phone number.');
            }
            if (emailAddressPrehire === undefined || emailAddressPrehire.trim() === '') {
                msgs.push('The personal email address is required.');
            } else if (!REGEX_EMAIL.test(emailAddressPrehire)) {
                msgs.push('Invalid email address.');
            }
            if (homeAddressPrehire === undefined || homeAddressPrehire.trim() === '') {
                msgs.push('The home address is required.');
            }
            if (msgs.length !== 0) {
                return { valid: false, messages: msgs };
            } else return { valid: true };
        }
    }
    async function isStep6Valid() {
        const msgs = [];
        if (employmentType === EmploymentType.FteKey) {
            if (businessJustification === undefined || businessJustification.trim() === '') {
                msgs.push('The business justification is required.');
            }
            if (isBusinessJustificationConfirmed === false) {
                msgs.push(
                    'You must confirm that the identified candidate requires security clearance.',
                );
            }
            if (msgs.length !== 0) {
                return { valid: false, messages: msgs };
            } else return { valid: true };
        } else {
            if (govContract)
                if (
                    (await getContractStatus(
                        authContext,
                        govContract,
                        screeningPathContractType,
                    )) === ContractStatus.Active
                ) {
                    return { valid: true };
                } else {
                    return {
                        valid: false,
                        messages: [invalidContractMessage],
                    };
                }
            else
                return {
                    valid: false,
                    messages: [
                        'The government contract must be selected. Please type the contract number and chose the appropriate project.',
                    ],
                };
        }
    }

    function isStep7Valid() {
        const msgs = [];
        //this step is only for Pre-hire
        if (employmentType !== EmploymentType.FteKey) {
            if (requestTypeUsGov === undefined) msgs.push('The request type must be selected.');
            if (
                clearanceLevel === undefined ||
                clearanceLevel.key === '' ||
                clearanceLevel.name === ''
            )
                msgs.push('The clearance level must be selected.');
            else {
                if (shouldSelectAccess(clearanceLevel)) {
                    msgs.push('Please select at least one access.');
                }
            }
            if (
                customerBadgeRequired === undefined ||
                (customerBadgeRequired !== 'YES' && customerBadgeRequired !== 'NO')
            )
                msgs.push('Please let us know if the candidate needs a customer badge.');
            if (msgs.length !== 0) {
                return { valid: false, messages: msgs };
            } else return { valid: true };
        } else return { valid: true };
    }
    function isStep8Valid() {
        const msgs = [];
        //this step is only for Pre-hire
        if (employmentType !== EmploymentType.FteKey) {
            if (businessJustification === undefined || businessJustification === '') {
                msgs.push('The business justification is required.');
            }
            if (isBusinessJustificationConfirmed === false) {
                msgs.push(
                    'You must confirm that the identified candidate requires security clearance.',
                );
            }

            if (msgs.length !== 0) {
                return { valid: false, messages: msgs };
            } else return { valid: true };
        } else return { valid: true };
    }

    const NominationButtons = useCallback(
        (props: ConfigurableStepperDefaultButtonsProps): JSX.Element => {
            return (
                <div className={panelStyles.buttonContainer}>
                    <div>
                        <PrimaryButton
                            className={panelStyles.oneButton}
                            onClick={(ev) =>
                                props.isLastStep
                                    ? props.handleFinish
                                        ? props.handleFinish(ev)
                                        : console.warn('No final step function provided')
                                    : props.handleNext(ev)
                            }
                            text={
                                props.isLastStep
                                    ? props.finishText !== ''
                                        ? props.finishText
                                        : 'Finish'
                                    : 'Next'
                            }
                            allowDisabledFocus
                            disabled={
                                props.isLastStep && props.disableFinishButton
                                    ? props.disableFinishButton
                                    : props.isButtonDisabled
                            }
                        />
                        {props.activeStep > 0 && (
                            <DefaultButton
                                className={panelStyles.oneButton}
                                onClick={props.handleBack}
                                text='Back'
                                allowDisabledFocus
                                disabled={props.isButtonDisabled}
                            />
                        )}
                    </div>
                    <div>
                        <DefaultButton
                            text='Cancel'
                            onClick={props.handleCancel}
                            allowDisabledFocus
                            disabled={props.isButtonDisabled}
                        />
                    </div>
                </div>
            );
        },
        [],
    );

    return (
        <Panel
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            type={PanelType.custom}
            customWidth={PANEL_WIDTH}
            closeButtonAriaLabel='Cancel'
            isFooterAtBottom={true}
            headerText={nominationStepObject[steps[activeStep]?.stepName]?.stepTitle ?? ''}
            onRenderFooterContent={(): JSX.Element => {
                return (
                    <NominationButtons
                        {...stepperProps}
                        finalFooterStyles=''
                        isButtonDisabled={shouldDisableFinishButton}
                    />
                );
            }}>
            <ConfigurableButtonsStepper shouldDisplayDefaultButtons={false} {...stepperProps} />
        </Panel>
    );
}
