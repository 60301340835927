import React, { ReactNode } from 'react';
import { ProblemLoading } from 'components/common/problem-loading/problem-loading';

interface IProblemLoadingMsgProps {
    problemLoadingMsg: string;
    children?: ReactNode;
}

export function ProblemLoadingMsg(props: IProblemLoadingMsgProps): JSX.Element {
    return (
        <ProblemLoading problemLoadingMsg={props.problemLoadingMsg}>
            {props.children}
        </ProblemLoading>
    );
}
