import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { AccessDeniedURL } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import CheckRole from 'components/common/check-role';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { scaManageBreadcrumbs } from 'components/sca/sca-breadcrumbs';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import { Table } from 'components/common/table';
import { IReviewPeriod } from 'clients/sca-client';
import { getScaReviewPeriodsTableColumns } from 'components/sca/manage/get-sca-manage-reviews-columns';
import { useSortColumnHandler, strCmp, numCmp } from 'utils/sort-utils';
import PageTitle from 'components/common/page-title';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import { Role } from 'configs/roles';
import { useReviewPeriods } from 'components/sca/sca-utils';
import ScaManageActionBar from 'components/sca/manage/sca-manage-action-bar';
import { ReviewPeriodStatusDict } from 'components/sca/sca-constants';

export default function ScaManage(): JSX.Element {
    const authContext = useContext(AuthContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const {
        isLoading: isReviewPeriodsLoading,
        reviewPeriods,
        currentReviewPeriod,
        refetchReviewPeriods,
    } = useReviewPeriods(authContext);

    const [tableRows, setTableRows] = useState<IReviewPeriod[]>();
    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler('Status', 1);

    /**
     * Determine breadcrumbs
     */
    useEffect(() => {
        const breadcrumb = scaManageBreadcrumbs();
        breadCrumbContext.setBreadCrumbs([...breadcrumb]);
    }, []);

    useEffect(() => {
        setTableRows(sortReviews([...reviewPeriods] || []));
    }, [sortColumn, sortAscending, reviewPeriods]);

    const onNewReviewPeriod = (): void => {
        refetchReviewPeriods();
    };

    const sortReviews = (reviews: IReviewPeriod[]): IReviewPeriod[] => {
        type R = IReviewPeriod;
        const statusDict = ReviewPeriodStatusDict;

        const chooseSortCmp = (
            sortColumn: string,
        ): ((r1: IReviewPeriod, r2: IReviewPeriod) => number) => {
            switch (sortColumn) {
                case '':
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: R, r2: R): number => 0;
                case 'Status':
                    return (r1: R, r2: R): number =>
                        r1.state !== undefined && r2.state !== undefined
                            ? sortAscending *
                              (statusDict[r1.state].sortOrder - statusDict[r2.state].sortOrder)
                            : 0;
                case 'id':
                    return (r1: R, r2: R): number => sortAscending * strCmp(r1.id, r2.id);
                case 'title':
                    return (r1: R, r2: R): number => sortAscending * strCmp(r1.title, r2.title);
                case 'startDateUTC':
                    return (r1: R, r2: R): number =>
                        sortAscending * numCmp(r1.startDateUTC, r2.startDateUTC);
                case 'endDateUTC':
                    return (r1: R, r2: R): number =>
                        sortAscending * numCmp(r1.endDateUTC, r2.endDateUTC);
                case 'eligibilityDateUTC':
                    return (r1: R, r2: R): number =>
                        sortAscending * numCmp(r1.eligibilityDateUTC, r2.eligibilityDateUTC);
                case 'lastModifiedTimestampUTC':
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        numCmp(r1.lastModifiedTimestampUTC, r2.lastModifiedTimestampUTC);
                default:
                    console.error(`Invalid sort column '${sortColumn}'`);
                    return (r1: R, r2: R): number => sortAscending * strCmp(r1.id, r2.id);
            }
        };
        const sortCmp = chooseSortCmp(sortColumn);
        return reviews.sort(sortCmp);
    };

    const tableColumns = getScaReviewPeriodsTableColumns({
        sortColumn: sortColumn,
        hasEditRoles: true,
        sortAscending: sortAscending === 1,
        sortColumnHandler: sortColumnHandler,
    });

    return (
        <CheckRole requiredRolesAny={[Role.SCAAdmin]} redirectNotInRole={AccessDeniedURL}>
            <PageLoadingSpinner
                label='Loading reviews ...'
                ariaLive='assertive'
                isLoading={isReviewPeriodsLoading}
                labelPosition='left'>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                <div>
                    <ScaManageActionBar
                        onNewReviewPeriod={onNewReviewPeriod}
                        currentReviewPeriod={currentReviewPeriod}
                    />
                    <PageTitle>SCA Reviews</PageTitle>
                    <Table
                        isFetchingData={false}
                        rows={tableRows || []}
                        tableColumns={tableColumns}
                        tableName='Review Periods'
                    />
                </div>
            </PageLoadingSpinner>
        </CheckRole>
    );
}
