import React from 'react';
import { Icon, mergeStyles } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import { faintYellowBackground } from 'assets/styles/global-styles';

interface IMessageBarProps {
    iconName: string;
    message: string;
}

/**
 * @deprecated Use Fluent element MessageBar instead
 */
export default function MessageBar(props: IMessageBarProps): JSX.Element {
    return (
        <div className={containerStyle}>
            <Icon iconName={props.iconName} className={iconStyle} />
            <span className={textStyle}>{props.message}</span>
        </div>
    );
}

const containerStyle = mergeStyles(
    {
        padding: '8px 12px 8px 12px',
        lineHeight: 16,
        display: 'flex',
        flexDirection: 'row',
    },
    faintYellowBackground,
);

const iconStyle = mergeStyles({
    fontSize: 16,
    minHeight: 16,
    maxHeight: 16,
});

const textStyle = mergeStyles(
    {
        paddingLeft: 8,
    },
    globalStyles.mediumFont,
);
