import { DefaultButton, Panel, PanelType } from '@fluentui/react';
import {
    GetAttributeSetByIdResult,
    GetAttributesByAttributeSetResult,
} from 'personnel-core-clients';
import React, { ReactNode, useCallback, useState } from 'react';
import { CoreEmployeeHoverCardFromGraph } from 'components/core/common/employee-card/core-employee-hover-card';

export interface IViewAttributeRolesPanelProps {
    isOpen: boolean;
    initAttribute: GetAttributesByAttributeSetResult | undefined;
    initAttributeSet: GetAttributeSetByIdResult | undefined;
    onDismiss: () => void;
    userOid: string;
}

export function ViewAttributeRolesPanel(props: IViewAttributeRolesPanelProps): JSX.Element {
    const [attributeName, setAttributeName] = useState<string>();

    const onCancel = useCallback((): void => {
        props.onDismiss();
        resetFormFields();
    }, [props]);

    const resetFormFields = (): void => {
        setAttributeName('');
    };

    function getCombinedRoles(attributeRoles: string[], attributeSetRoles: string[]): string[] {
        const hashSet = new Set([...attributeRoles, ...attributeSetRoles]);
        return Array.from(hashSet);
    }

    const owners = getCombinedRoles(
        props.initAttribute?.owners ?? [],
        props.initAttributeSet?.owners ?? [],
    );
    const managers = getCombinedRoles(
        props.initAttribute?.managers ?? [],
        props.initAttributeSet?.managers ?? [],
    );
    const readers = getCombinedRoles(
        props.initAttribute?.readers ?? [],
        props.initAttributeSet?.readers ?? [],
    );

    function getHighestOwnershipLevel(
        userOID: string,
        owners: string[],
        managers: string[],
        readers: string[],
    ): string {
        if (owners.includes(userOID)) {
            return 'Owner';
        } else if (managers.includes(userOID)) {
            return 'Manager';
        } else if (readers.includes(userOID)) {
            return 'Reader';
        } else {
            return 'None';
        }
    }

    const onRenderFooterContent = React.useCallback(
        () => (
            <div>
                <DefaultButton onClick={onCancel}>Close</DefaultButton>
            </div>
        ),
        [onCancel],
    );

    return (
        <div>
            <Panel
                isOpen={props.isOpen}
                closeButtonAriaLabel='Close'
                onDismiss={(): void => onCancel()}
                isLightDismiss
                onLightDismissClick={(): void => onCancel()}
                onRenderFooterContent={onRenderFooterContent}
                isFooterAtBottom={true}
                type={PanelType.smallFixedFar}>
                <div style={titleStyle}>Roles</div>
                <div style={labelStyle}>Attribute</div>
                <div style={valueStyle}>{props.initAttribute?.name}</div>
                <hr style={hrStyle} />
                <div style={labelStyle}>My Role</div>
                <div style={valueStyle}>
                    {getHighestOwnershipLevel(props.userOid, owners, managers, readers)}
                </div>
                <hr style={hrStyle} />
                <div style={labelStyle}>Owner</div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {owners.map(
                        (owner): ReactNode => (
                            <div key={owner} style={{ padding: '2px' }}>
                                <CoreEmployeeHoverCardFromGraph
                                    key={owner}
                                    oid={owner}
                                    showFullName={owners.length === 1}
                                    showMiniCardAlias={owners.length === 1}
                                />
                            </div>
                        ),
                    )}
                </div>
                <hr style={hrStyle} />
                <div style={labelStyle}>Manager</div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {managers.map(
                        (manager): ReactNode => (
                            <div key={manager} style={{ padding: '2px' }}>
                                <CoreEmployeeHoverCardFromGraph
                                    key={manager}
                                    oid={manager}
                                    showFullName={managers.length === 1}
                                    showMiniCardAlias={managers.length === 1}
                                    employeeCardStyle={{
                                        card: { minWidth: 0, paddingRight: '20px' },
                                    }}
                                />
                            </div>
                        ),
                    )}
                </div>
                <hr style={hrStyle} />
                <div style={labelStyle}>Reader</div>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {readers.map(
                        (reader): ReactNode => (
                            <div key={reader} style={{ padding: '2px' }}>
                                <CoreEmployeeHoverCardFromGraph
                                    key={reader}
                                    oid={reader}
                                    showFullName={readers.length === 1}
                                    showMiniCardAlias={readers.length === 1}
                                />
                            </div>
                        ),
                    )}
                </div>
            </Panel>
        </div>
    );
}

const titleStyle = {
    fontWeight: 'bold',
    fontSize: '20px',
    paddingBottom: '20px',
};

const labelStyle = {
    fontWeight: '600',
    fontSize: '14px',
    paddingBottom: '5px',
};

const valueStyle = {
    fontWeight: '600',
    fontSize: '12px',
    color: '#605E5C',
    paddingBottom: '5px',
};

const hrStyle = {
    height: '1px',
    margin: '10px 0',
    border: 'none',
    borderBottom: '1px solid #ddd',
};
