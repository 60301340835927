import React, { ReactNode } from 'react';
import { IColumn } from '@fluentui/react';
import { dateToLocalDate, unixTimeStampToFormattedDateString } from 'utils/time-utils';
import { TableCell } from 'components/common/table';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import EllipsisText from 'components/common/ellipsis-text';
import { ISortableColumns } from 'utils/sort-utils';
import { getDefaultColumnWidths } from 'utils/table-utils';
import { ISuitabilityRecord } from 'clients/suitability-client';
import {
    SuitabilityLevels,
    SuitabilityStatuses,
} from 'components/personnel-profile/suitability/profile-suitability-types';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/suitability/profile-suitability-utils';
import { SuitabilityColumnHeaders } from 'components/screening/us-gov/candidates-listing/tabpanel-contents/content-suitability-records-tab';

interface ISuitabilities extends ISortableColumns {
    contractMap: Map<string, string>;
    isExpandButtonClicked: boolean;
}

export function getSuitabilityRecordsTableColumns(args: ISuitabilities): IColumn[] {
    const {
        contractMap,
        isExpandButtonClicked,
        sortColumn,
        sortAscending: isSortedAscending,
        sortColumnHandler,
    } = args;

    const collapsedTableColumnsAmount = 8;
    const expandedTableColumnsAmount = 13;
    const columnsWidth = 150;

    const columnWidths = getDefaultColumnWidths(
        isExpandButtonClicked ? expandedTableColumnsAmount : collapsedTableColumnsAmount,
        false,
        isExpandButtonClicked
            ? expandedTableColumnsAmount * columnsWidth
            : collapsedTableColumnsAmount * columnsWidth,
    );

    return [
        {
            key: 'personnelId',
            name: SuitabilityColumnHeaders.Name,
            ariaLabel: SuitabilityColumnHeaders.Name,
            minWidth: columnWidths.defaultColumnWidth * 2,
            maxWidth: columnWidths.defaultColumnWidth * 2,
            isRowHeader: true,
            onRender: (row: ISuitabilityRecord): JSX.Element => {
                return (
                    <TableCell key={row?.personnelId}>
                        {renderCorrectSuitabilityRecordsColumnOption(row)}
                    </TableCell>
                );
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.Name,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.Name);
            },
        },
        {
            key: 'suitabilityLevel',
            name: SuitabilityColumnHeaders.SuitabilityLevel,
            ariaLabel: SuitabilityColumnHeaders.SuitabilityLevel,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isCollapsible: !isExpandButtonClicked,
            onRender: (row: ISuitabilityRecord): JSX.Element => {
                const suitabilityText =
                    SuitabilityLevels[row.suitabilityLevel as keyof typeof SuitabilityLevels] ||
                    row.suitabilityLevel;
                return (
                    <TableCell>
                        <EllipsisText
                            text={suitabilityText}
                            textLengthBeforeEllipsis={isExpandButtonClicked ? 14 : 9}
                        />
                    </TableCell>
                );
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.SuitabilityLevel,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.SuitabilityLevel);
            },
        },
        {
            key: 'suitabilityType',
            name: SuitabilityColumnHeaders.Type,
            ariaLabel: SuitabilityColumnHeaders.Type,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            onRender: (row: ISuitabilityRecord): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisText
                            text={row.suitabilityType}
                            textLengthBeforeEllipsis={isExpandButtonClicked ? 17 : 9}
                        />
                    </TableCell>
                );
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.Type,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.Type);
            },
        },
        {
            key: 'status',
            name: SuitabilityColumnHeaders.Status,
            ariaLabel: SuitabilityColumnHeaders.Status,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            onRender: (row: ISuitabilityRecord): JSX.Element => {
                const status =
                    SuitabilityStatuses[row.status as keyof typeof SuitabilityStatuses] ||
                    row.status;
                return (
                    <TableCell>
                        <EllipsisText
                            text={status}
                            textLengthBeforeEllipsis={isExpandButtonClicked ? 23 : 10}
                        />
                    </TableCell>
                );
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.Status,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.Status);
            },
        },
        {
            key: 'requestingAgency',
            name: SuitabilityColumnHeaders.Agency,
            ariaLabel: SuitabilityColumnHeaders.Agency,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isCollapsible: !isExpandButtonClicked,
            onRender: (row: ISuitabilityRecord): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisText
                            text={
                                row.requestingAgency
                                    ? getAgencyEnumValueFromKey(row.requestingAgency, true)
                                    : ''
                            }
                            textLengthBeforeEllipsis={isExpandButtonClicked ? 9 : 6}
                        />
                    </TableCell>
                );
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.Agency,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.Agency);
            },
        },
        {
            key: 'contractId',
            name: SuitabilityColumnHeaders.ContractID,
            ariaLabel: SuitabilityColumnHeaders.ContractID,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isCollapsible: !isExpandButtonClicked,
            onRender: (row: ISuitabilityRecord): JSX.Element => {
                return <TableCell>{row.contractId}</TableCell>;
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.ContractID,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.ContractID);
            },
        },
        {
            key: 'projectName',
            name: SuitabilityColumnHeaders.ProjectName,
            ariaLabel: SuitabilityColumnHeaders.ProjectName,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            onRender: (row: ISuitabilityRecord): JSX.Element => {
                const projectName = contractMap.get(row.contractId || '') || '';
                return <TableCell>{projectName}</TableCell>;
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.ProjectName,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.ProjectName);
            },
        },
        {
            key: 'suitabilityExpirationDateTimeUTCMilliseconds',
            name: SuitabilityColumnHeaders.ExpiresOn,
            ariaLabel: SuitabilityColumnHeaders.ExpiresOn,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            onRender: (row: ISuitabilityRecord): ReactNode => {
                return row.suitabilityExpirationDateTimeUTCMilliseconds ? (
                    <TableCell>
                        {dateToLocalDate(row.suitabilityExpirationDateTimeUTCMilliseconds)}
                    </TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.ExpiresOn,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.ExpiresOn);
            },
        },
        {
            key: 'grantedDate',
            name: SuitabilityColumnHeaders.GrantedOn,
            ariaLabel: SuitabilityColumnHeaders.GrantedOn,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            onRender: (row: ISuitabilityRecord): ReactNode => {
                return row.grantDateUTCMilliseconds ? (
                    <TableCell>{dateToLocalDate(row.grantDateUTCMilliseconds)}</TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.GrantedOn,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.GrantedOn);
            },
        },
        {
            key: 'investigatedDate',
            name: SuitabilityColumnHeaders.InvestigatedOn,
            ariaLabel: SuitabilityColumnHeaders.InvestigatedOn,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isCollapsible: !isExpandButtonClicked,
            onRender: (row: ISuitabilityRecord): ReactNode => {
                return row.investigationDateUTCMilliseconds ? (
                    <TableCell>{dateToLocalDate(row.investigationDateUTCMilliseconds)}</TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.InvestigatedOn,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.InvestigatedOn);
            },
        },
        {
            key: 'briefedDate',
            name: SuitabilityColumnHeaders.BriefedOn,
            ariaLabel: SuitabilityColumnHeaders.BriefedOn,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isCollapsible: !isExpandButtonClicked,
            onRender: (row: ISuitabilityRecord): ReactNode => {
                return row.briefDateUTCMilliseconds ? (
                    <TableCell>{dateToLocalDate(row.briefDateUTCMilliseconds)}</TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.BriefedOn,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.BriefedOn);
            },
        },
        {
            key: 'continuousEvaluationType',
            name: SuitabilityColumnHeaders.ContinuousEvaluationType,
            ariaLabel: SuitabilityColumnHeaders.ContinuousEvaluationType,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            onRender: (row: ISuitabilityRecord): ReactNode => {
                return <TableCell>{row.continuousEvaluationType}</TableCell>;
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.ContinuousEvaluationType,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.ContinuousEvaluationType);
            },
        },
        {
            key: 'lastModified',
            name: SuitabilityColumnHeaders.LastModified,
            ariaLabel: SuitabilityColumnHeaders.LastModified,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            onRender: (row: ISuitabilityRecord): ReactNode => {
                return row.lastModified ? (
                    <TableCell>
                        {' '}
                        {unixTimeStampToFormattedDateString(row.lastModified?.atUtc, true)}{' '}
                    </TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === SuitabilityColumnHeaders.LastModified,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(SuitabilityColumnHeaders.LastModified);
            },
        },
    ] as IColumn[];
}

function renderCorrectSuitabilityRecordsColumnOption(row: ISuitabilityRecord): JSX.Element {
    return <EmployeeBasicHoverCard personnelId={row.personnelId} />;
}
