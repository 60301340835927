import { Persona, PersonaInitialsColor, PersonaSize, mergeStyleSets } from '@fluentui/react';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

type ProfileCardProps = {
    image: string;
    name: string;
    email: string;
    setIsProfileCalloutOpen: (isProfileCalloutOpen: boolean) => void;
    setIsFeedbackPanelOpen: (isFeedbackPanelOpen: boolean) => void;
};

export default function ProfileCardContent({
    image,
    name,
    email,
    setIsProfileCalloutOpen,
    setIsFeedbackPanelOpen,
}: ProfileCardProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const isAboutUserEnabled = useFeatureFlag(FeatureFlagKeys.aboutUser).enabled;

    const onFeedbackLinkClicked = (): void => {
        setIsProfileCalloutOpen(false);
        setIsFeedbackPanelOpen(true);
    };

    const onAboutLinkClicked = (): void => {
        setIsProfileCalloutOpen(false);
    };

    return (
        <div>
            <div className={classes.profileCardStyle}>
                <div className={classes.profileCardImage}>
                    {image ? (
                        <Persona size={PersonaSize.size48} imageUrl={image} />
                    ) : (
                        <Persona
                            size={PersonaSize.size48}
                            initialsColor={PersonaInitialsColor.coolGray}
                            imageInitials={name.replace(/[^A-Z]+/g, '') ?? ''}
                        />
                    )}
                </div>
                <div>
                    <div className={classes.profileNameBold}>{name}</div>
                    <div>{email}</div>
                    {isAboutUserEnabled && (
                        <Link
                            to={'/about/user'}
                            onClick={onAboutLinkClicked}
                            className={classes.profileCardAccountLink}>
                            View account
                        </Link>
                    )}
                </div>
            </div>
            <Link
                to='#'
                onClick={(): void => onFeedbackLinkClicked()}
                className={classes.profileCardMenuLink}>
                Feedback
            </Link>
            {/* <Link to='#' className={classes.menuLink}>
                Notifications
            </Link> */}
            <Link
                to='#'
                onClick={(): void => {
                    authContext.logout();
                }}
                className={classes.profileCardMenuLink}>
                Sign out
            </Link>
        </div>
    );
}

const classes = mergeStyleSets({
    profileCardMenuLink: {
        width: '100%',
        display: 'block',
        paddingLeft: '16px',
        paddingTop: '8px',
        paddingBottom: '8px',
        color: '#424242', // var(--colorNeutralForeground2)
        backgroundColor: '#fff', // var(--colorNeutralBackground1)
        textDecoration: 'none',
        borderTop: '1px solid #e0e0e0', // var(--colorNeutralStroke1)
        selectors: {
            ':hover': {
                color: '#242424', // var(--colorNeutralForeground2Hover)
                backgroundColor: '#f5f5f5', // var(----colorNeutralBackground1Hover)
            },
        },
    },
    profileCardAccountLink: {
        color: '#115ea3', // var(--colorBrandForegroundLink)
        textDecoration: 'none',
        selectors: {
            ':hover': {
                color: '#0f548c', // var(--colorBrandForegroundLinkHover)
            },
        },
    },
    profileCardImage: {
        alignSelf: 'center',
        margin: '10px',
    },
    profileCardStyle: {
        paddingTop: '20px',
        margin: '10px',
        display: 'flex',
    },
    profileNameBold: {
        fontWeight: '600',
    },
});
