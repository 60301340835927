import React from 'react';
import { PersonaSize } from '@fluentui/react';
import { getAlias } from 'components/common/employee/employee-utils';
import ICardPrincipal from 'components/core/common/employee-card/card-principal';
import EmployeeMiniCard from 'components/common/employee/employee-mini-card';

export interface ICoreEmployeeMiniCardProps {
    cardPrincipal?: ICardPrincipal;
    imageSize?: PersonaSize;
    showImage?: boolean;
    showAlias?: boolean;
    showFullName?: boolean; // Default: true
}

export default function CoreEmployeeMiniCard(props: ICoreEmployeeMiniCardProps): JSX.Element {
    const { displayName, imageUrl, upn, isActive } = props.cardPrincipal ?? {};

    const alias = getAlias(upn)?.toLowerCase() ?? upn;

    const employee = displayName
        ? {
              displayName: displayName,
              alias: alias,
              isActiveEmployee: isActive,
          }
        : undefined;

    return <EmployeeMiniCard {...props} employee={employee} imageUrl={imageUrl} />;
}
