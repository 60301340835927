import React, { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { BrowserCacheKeys } from 'utils/browser-cache-utils';
import { useHistory, useLocation } from 'react-router-dom';
import { home } from 'configs/routes';

// we handle navigation back to the original request uri after logging in manually here in our redirect uri
// as when MSAL handles it itself it causes a page reload as it needs to remove a hash code it puts in the url of the redirect uri
// Reference Link: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5694

const defaultPage = home;

export default function SignedIn(): JSX.Element {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        // MSAL's `navigateToLoginRequestUrl` would cause a full page reload when redirecting us to the requested page upon login.
        // To prevent this, we navigate internally within our SPA by having the React Router take care of the navigation instead
        const targetUri = sessionStorage.getItem(BrowserCacheKeys.loginOriginalRequestUriKey);
        if (targetUri) {
            const navigateTo = targetUri.replace(window.location.origin, '');
            sessionStorage.removeItem(BrowserCacheKeys.loginOriginalRequestUriKey);
            if (navigateTo === '/') {
                history.replace(defaultPage);
            } else {
                history.replace(navigateTo);
            }
        }
    }, [history]);

    if (!location.hash) {
        // this page is used only during the login process. If the hash is not there anymore, it means that MSAL has processed the state in the URL already and it's safe to redirect internally, which will happen in the next tick
        return <></>;
    }

    sessionStorage.removeItem(BrowserCacheKeys.loginOriginalRequestUriKey);
    // This is only a virtual route which is part of the login contract. There's nothing to show here, we're just redirecting to the default page after the login.
    // We have to keep the hash in the URL in place though, so that MSAL can pick it up during `` and complete the authentication if it hasn't already.
    // If a specific target URL was requested during login, MSAL will redirect us to that page (by doing another replace) once the authentication is completed
    history.replace({ pathname: defaultPage, hash: location.hash, search: location.search });

    return <></>;
}
