import { IAzureServiceKpi } from 'clients/staffing-client';
import { BadgeColorHex } from 'assets/constants/global-colors';

export enum MappingSettings {
    All = 'All',
    Mapped = 'Mapped',
    UnMapped = 'Un-Mapped',
}

export enum StatusSettings {
    All = 'All',
    OneDRI = '1 DRI Assigned',
    Two4DRI = '24/7 DRI Support',
}

export const translateKpiStatus = (service: IAzureServiceKpi): string => {
    switch (service.kpi) {
        case 'PERSONNEL_KPI_SINGLE_CLEARED_PERSON':
            return '1 DRI Assigned';
        case 'PERSONNEL_KPI_24_7_SUPPORT':
            return '24/7 DRI Support';
        default:
            return '';
    }
};

export const serviceMapStatusBackground = (service: IAzureServiceKpi): string | undefined => {
    switch (service.value) {
        case 'red':
            return BadgeColorHex.RED;
        case 'green':
            return BadgeColorHex.GREEN;
        default:
            return undefined;
    }
};
