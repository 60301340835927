import React, { useEffect, useContext } from 'react';
import StaffingStatusTables from 'components/staffing/status/staffing-status-tables';
import SidebarAndContent, {
    SidebarPane,
    ContentPane,
} from 'components/common/sidebar-and-contents';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import { staffingStatusBreadcrumb } from 'components/staffing/staffing-breadcrumbs';
import StaffingStatusFilters from 'components/staffing/status/staffing-status-filters';
import StaffingStatusFiltersProvider from 'components/staffing/contexts/staffing-status-filter-context';
import { StaffingAdminRoles } from 'configs/roles';
import CheckRole from 'components/common/check-role';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { AccessDeniedURL } from 'assets/constants/global-constants';

export default function StaffingStatusPage(): JSX.Element {
    const breadCrumbContext = useContext(BreadCrumbContext);
    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([...staffingStatusBreadcrumb]);
    }, []);
    return (
        <CheckRole requiredRolesAny={StaffingAdminRoles} redirectNotInRole={AccessDeniedURL}>
            <StaffingStatusFiltersProvider>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                <div>
                    <SidebarAndContent>
                        <SidebarPane>
                            <StaffingStatusFilters />
                        </SidebarPane>
                        <ContentPane contentPaneOuterStyle={{ overflowX: 'visible' }}>
                            <StaffingStatusTables />
                        </ContentPane>
                    </SidebarAndContent>
                </div>
            </StaffingStatusFiltersProvider>
        </CheckRole>
    );
}
