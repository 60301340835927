import React, { useState, useEffect } from 'react';
import BarChart, { ChartItem } from 'components/common/charts/bar-chart';
import { CommunicationColors, Stack } from '@fluentui/react';
import {
    barChartStack,
    overallGraphStack,
    textBoxInfoStack,
} from 'components/screening/common/common-manage-profile-page-styling';
import { IBarChartData } from 'components/screening/us-gov/IScreening';

interface CommonScreeningChartProps {
    barChartData: IBarChartData | undefined;
    isBarChartDataReady: boolean;
}

export default function CommonScreeningChart(props: CommonScreeningChartProps): JSX.Element {
    const [barChartData, setBarChartData] = useState<ChartItem[]>([]);
    const [textInfo, setTextInfo] = useState<string[]>([]);

    useEffect(() => {
        let updatedChartData: ChartItem[] = [];
        if (props.barChartData && props.barChartData.barData) {
            Object.entries(props.barChartData.barData).map(([label, count]) => {
                updatedChartData = updatedChartData.concat({
                    xAxis: label,
                    yAxis: count,
                    color: CommunicationColors.primary,
                });
            });

            setBarChartData(updatedChartData);
            setTextInfo(props.barChartData.textInfo);
        }
    }, [props.barChartData]);

    if (!props.isBarChartDataReady) {
        return <></>;
    }
    return (
        <Stack styles={overallGraphStack}>
            <Stack styles={barChartStack}>
                <BarChart barChartData={barChartData} displayDataLabels={true} hideLegend={true} />
            </Stack>
            <Stack styles={textBoxInfoStack}>
                <ul>
                    {textInfo.map((text, index) => (
                        <li key={index}>{text}</li>
                    ))}
                </ul>
            </Stack>
        </Stack>
    );
}
