import React, { createContext, useState } from 'react';
import { ICustomBreadcrumbItem } from 'components/common/bread-crumb';

export const BreadCrumbContext = createContext<IBreadCrumbContext>(null!);

export interface BreadCrumbContextProviderProps {
    children: JSX.Element;
}

function BreadCrumbContextProvider(props: BreadCrumbContextProviderProps): JSX.Element {
    const [breadCrumbs, setBreadCrumbs] = useState<ICustomBreadcrumbItem[]>([]);

    return (
        <BreadCrumbContext.Provider
            value={{
                setBreadCrumbs: setBreadCrumbs,
                breadCrumbs: breadCrumbs,
            }}>
            {props.children}
        </BreadCrumbContext.Provider>
    );
}

export default BreadCrumbContextProvider;

export interface IBreadCrumbContext {
    setBreadCrumbs: (breadCrumbs: ICustomBreadcrumbItem[]) => void;
    breadCrumbs: ICustomBreadcrumbItem[];
}

export class BreadCrumbHelper {
    static resetBreadCrumbs(localBreadCrumbContext: IBreadCrumbContext): void {
        localBreadCrumbContext.setBreadCrumbs([]);
    }

    static addBreadCrumbs(
        localBreadCrumbContext: IBreadCrumbContext,
        breadCrumbItems: ICustomBreadcrumbItem[],
    ): void {
        const localBreadCrumbs = localBreadCrumbContext.breadCrumbs;
        localBreadCrumbContext.setBreadCrumbs([...localBreadCrumbs, ...breadCrumbItems]);
    }

    static setBreadCrumbs(
        localBreadCrumbContext: IBreadCrumbContext,
        breadCrumbItems: ICustomBreadcrumbItem[],
    ): void {
        localBreadCrumbContext.setBreadCrumbs(breadCrumbItems);
    }

    static appendOrAddDefaults(
        localBreadCrumbContext: IBreadCrumbContext,
        breadCrumbItemToAdd: ICustomBreadcrumbItem,
        prefixBreadCrumbsIfNoneExist: ICustomBreadcrumbItem[] | undefined,
    ): void {
        const currentBreadCrumbs: ICustomBreadcrumbItem[] = [...localBreadCrumbContext.breadCrumbs];
        const hasNoBreadCrumbs: boolean = currentBreadCrumbs.length === 0;

        if (hasNoBreadCrumbs && prefixBreadCrumbsIfNoneExist) {
            currentBreadCrumbs.push(...prefixBreadCrumbsIfNoneExist);
        }

        const shouldAppendBreadcrumbText = !BreadCrumbHelper.breadCrumbTextExists(
            localBreadCrumbContext,
            breadCrumbItemToAdd,
        );

        if (shouldAppendBreadcrumbText) {
            currentBreadCrumbs.push(breadCrumbItemToAdd);
        }

        localBreadCrumbContext.setBreadCrumbs([...currentBreadCrumbs]);
    }

    static breadCrumbTextExists(
        localBreadCrumbContext: IBreadCrumbContext,
        breadCrumbToCheck: ICustomBreadcrumbItem,
    ): boolean {
        return (
            localBreadCrumbContext.breadCrumbs.findIndex(
                (localBreadCrumb) => localBreadCrumb.title === breadCrumbToCheck.title,
            ) >= 0
        );
    }

    static replaceLastBreadCrumbItem(
        localBreadCrumbContext: IBreadCrumbContext,
        breadCrumbItems: ICustomBreadcrumbItem,
    ): void {
        let localBreadCrumbs = localBreadCrumbContext.breadCrumbs.slice(
            0,
            localBreadCrumbContext.breadCrumbs.length - 1,
        );

        const shouldAppendBreadCrumbItems = !BreadCrumbHelper.breadCrumbTextExists(
            localBreadCrumbContext,
            breadCrumbItems,
        );

        if (shouldAppendBreadCrumbItems) {
            localBreadCrumbs = [...localBreadCrumbs, breadCrumbItems];
        }

        localBreadCrumbContext.setBreadCrumbs([...localBreadCrumbs]);
    }
}
