import {
    ActionButton,
    CheckboxVisibility,
    DetailsList,
    IObjectWithKey,
    MessageBar,
    MessageBarType,
    Selection,
    SelectionMode,
} from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import GroupClient, { GroupFilterType, GroupMemberStatus, IMyGroup } from 'clients/group-client';
import { IPagedResults } from 'clients/http-options';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import HorizontalBar from 'components/common/horizontal-bar';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';
import Spacer from 'components/common/spacer';
import StatTile from 'components/common/stat-tiles/stat-tile';
import { Table } from 'components/common/table';
import useMessageBar from 'components/common/use-message-bar';
import { staticStatTileWrapperGroups } from 'components/groups/common/common-styles-groups';
import {
    canCheckMember,
    canNominate,
    compliantGroupCount,
    getCompliantColor,
    getCompliantStatus,
    notCompliantGroupCount,
    onGracePeriodCount,
} from 'components/groups/groups-utils';
import MemberBulkModalActionButton, {
    Pages,
} from 'components/groups/my-groups/member-bulk-modal-action-button';
import { MyGroupsContext } from 'components/groups/my-groups/my-groups-context';
import { tableColumnsMyGroupsActive } from 'components/groups/my-groups/table-columns-active-groups';
import { tableColumnsMyGroupsPending } from 'components/groups/my-groups/table-columns-pending-groups';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { GroupsUserContext } from 'contexts/user-contexts/groups-user-context';
import { Location } from 'history';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFetchSimple } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';
import { strCmp, useSortColumnHandler } from 'utils/sort-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface IMyGroupsProps {
    location: Location;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function MyGroups(props: IMyGroupsProps): JSX.Element {
    const context = useContext(MyGroupsContext);
    const authContext = useContext(AuthContext);
    const groupsUserContext = useContext(GroupsUserContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const pageRef = useRef(null!);

    const [errorFetchingMyGroups, setErrorFetchingMyGroups] = useState<string>('');
    const [continuationToken, setContinuationToken] = useState<string>('');
    const [shouldFetch, setShouldFetch] = useState<boolean>(true);
    const [updatedGroupId, setUpdatedGroupId] = useState<string>('');
    const [refetchContinuationToken, setRefetchContinuationToken] = useState<string>('');
    const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);
    const [updatedGroups, setUpdateGroups] = useState<IMyGroup[]>([]);
    const [
        fetchWithEvaluateRulesContinuationToken,
        setFetchWithEvaluateRulesContinuationToken,
    ] = useState<string>('');
    const [shouldFetchWithEvaluateRules, setShouldFetchWithEvaluateRules] = useState<boolean>(
        false,
    );
    const [myGroupsUpdated, setMyGroupsUpdated] = useState<IMyGroup[]>([]);
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

    const selection = useMemo(
        () =>
            new Selection<IMyGroup>({
                getKey: (item) => item.id,
                onSelectionChanged: (): void => {
                    const selectedGroups = selection.getSelection();
                    const selectedGroupIds = selectedGroups.map(
                        (selectedGroup) => selectedGroup.id,
                    );
                    setSelectedGroups(selectedGroupIds);
                },
                canSelectItem(item): boolean {
                    return canCheckMember(item as IMyGroup) || canNominate(item as IMyGroup);
                },
                selectionMode: SelectionMode.multiple,
            }),
        [],
    );

    const isNonExternalEnabled = useFeatureFlag(FeatureFlagKeys.enableNonExternalComponents)
        .enabled;

    const { theElement: updateErrMsgElement, setMessage: setUpdateErrMsg } = useMessageBar({
        type: MessageBarType.error,
    });

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([
            { title: 'Groups', link: '' },
            { title: 'My Groups', link: '' },
        ]);
    }, []);

    const [
        { sortColumn: sortColumnActive, sortAscending: sortAscendingActive },
        sortColumnHandlerActive,
    ] = useSortColumnHandler('Group', 1);

    const [
        { sortColumn: sortColumnPending, sortAscending: sortAscendingPending },
        sortColumnHandlerPending,
    ] = useSortColumnHandler('Group', 1);

    const { isFetching: isFetchingMyGroups } = useFetchSimple<IPagedResults<IMyGroup>>({
        dependencies: [shouldFetch, continuationToken],
        canPerformFetch: shouldFetch,
        fetchFunc: async () => {
            setShouldFetch(false);
            return GroupClient.getMyGroups(authContext, continuationToken, false);
        },
        onSuccess: (result) => {
            if (!continuationToken) {
                context.setMyGroups(result.results);
            } else {
                context.setMyGroups((currentValue) => currentValue.concat(result.results));
            }

            setContinuationToken(result.continuationToken ?? '');

            if (!result.continuationToken) {
                setShouldFetchWithEvaluateRules(true);
            }
        },
        onError: () => {
            setErrorFetchingMyGroups('Error loading groups');
        },
        onFinally: doNothing,
    });

    useEffect(() => {
        if (!isFetchingMyGroups && !!continuationToken) {
            setShouldFetch(true);
        }
    }, [continuationToken, isFetchingMyGroups]);

    useFetchSimple<IPagedResults<IMyGroup>>({
        dependencies: [shouldRefetch, refetchContinuationToken],
        canPerformFetch: shouldRefetch,
        fetchFunc: async () => {
            setShouldRefetch(false);
            return GroupClient.getMyGroups(authContext, refetchContinuationToken);
        },
        onSuccess: (result) => {
            if (!refetchContinuationToken) {
                const groups = updatedGroups.length !== 0 ? updatedGroups : result.results;
                const updatedGroup = groups.find((g) => g.id === updatedGroupId);

                if (updatedGroup) {
                    context.updateRow(updatedGroup);
                }
            } else {
                setUpdateGroups((currentValue) => currentValue.concat(result.results));
            }
            setRefetchContinuationToken(result.continuationToken ?? '');
        },
        onError: () => {
            console.error('Error refetching groups');
        },
        onFinally: doNothing,
    });

    const { isFetching: isFetchingMyGroupsWithEvaluateRules } = useFetchSimple<
        IPagedResults<IMyGroup>
    >({
        dependencies: [shouldFetchWithEvaluateRules, fetchWithEvaluateRulesContinuationToken],
        canPerformFetch: shouldFetchWithEvaluateRules,
        fetchFunc: async () => {
            setShouldFetchWithEvaluateRules(false);
            return GroupClient.getMyGroups(
                authContext,
                fetchWithEvaluateRulesContinuationToken,
                true,
            );
        },
        onSuccess: (result) => {
            if (!fetchWithEvaluateRulesContinuationToken) {
                setMyGroupsUpdated(result.results);
            } else {
                if (result.continuationToken) {
                    setMyGroupsUpdated((currentValue) => currentValue.concat(result.results));
                }
            }

            setFetchWithEvaluateRulesContinuationToken(result.continuationToken ?? '');

            if (result.continuationToken) {
                setShouldFetchWithEvaluateRules(true);
            } else {
                context.setMyGroups(myGroupsUpdated.concat(result.results));
            }
        },
        onError: () => {
            setUpdateErrMsg('Error fetching groups while evaluating group rules.');
        },
        onFinally: doNothing,
    });

    const handleGroupsTableUpdate = async (groupId: string): Promise<void> => {
        setShouldRefetch(true);
        setUpdatedGroupId(groupId);
    };

    const sortActiveGroups = (groups: IMyGroup[]): IMyGroup[] => {
        type A = IMyGroup;

        const chooseSortCmp = (): ((r1: A, r2: A) => number) => {
            switch (sortColumnActive) {
                case 'Group':
                    return (r1: A, r2: A): number => sortAscendingActive * strCmp(r1.name, r2.name);
                case 'Membership':
                    return (r1: A, r2: A): number =>
                        sortAscendingActive *
                        strCmp(r1.role.toLocaleLowerCase(), r2.role.toLocaleLowerCase());
                case 'Compliancy':
                    return (r1: A, r2: A): number =>
                        sortAscendingActive *
                        strCmp(
                            getCompliantStatus(!!r1.compliant, false, false),
                            getCompliantStatus(!!r2.compliant, false, false),
                        );
                default:
                    // Sort column not recognized. No sorting performed.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp();
        return [...groups].sort(sortCmp);
    };

    const sortPendingGroups = (groups: IMyGroup[]): IMyGroup[] => {
        type A = IMyGroup;

        const chooseSortCmp = (): ((r1: A, r2: A) => number) => {
            switch (sortColumnPending) {
                case 'Group':
                    return (r1: A, r2: A): number =>
                        sortAscendingPending * strCmp(r1.name, r2.name);
                default:
                    // Sort column not recognized. No sorting performed.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp();
        return [...groups].sort(sortCmp);
    };

    const activeTableColumns = useMemo(() => {
        return tableColumnsMyGroupsActive({
            sortColumn: sortColumnActive,
            sortAscending: sortAscendingActive === 1,
            sortColumnHandler: sortColumnHandlerActive,
            handleGroupsTableUpdate,
            isLoadingWithEvaluateRules: isFetchingMyGroupsWithEvaluateRules,
        });
    }, [sortColumnActive, sortAscendingActive, isFetchingMyGroupsWithEvaluateRules]);

    const pendingTableColumns = useMemo(() => {
        return tableColumnsMyGroupsPending({
            sortColumn: sortColumnPending,
            sortAscending: sortAscendingPending === 1,
            sortColumnHandler: sortColumnHandlerPending,
        });
    }, [sortColumnPending, sortAscendingPending]);

    const activeGroups = useMemo(() => {
        return sortActiveGroups(
            context.myGroups.filter((group) => group.status !== GroupMemberStatus.NOT_APPROVED),
        );
    }, [context.myGroups, sortColumnActive, sortAscendingActive]);

    const pendingGroups = useMemo(() => {
        return sortPendingGroups(
            context.myGroups.filter((group) => group.status === GroupMemberStatus.NOT_APPROVED),
        );
    }, [context.myGroups, sortColumnPending, sortAscendingPending]);

    const compliantGroups = (): IMyGroup[] => {
        return activeGroups.filter(
            (group) =>
                group.status === GroupMemberStatus.APPROVED && !group.hasViolationOnGracePeriod,
        );
    };
    const notCompliantGroups = (): IMyGroup[] => {
        return activeGroups.filter((group) => group.status === GroupMemberStatus.QUARANTINED);
    };
    const gracePeriodGroups = (): IMyGroup[] => {
        return activeGroups.filter(
            (group) =>
                group.status === GroupMemberStatus.APPROVED && group.hasViolationOnGracePeriod,
        );
    };

    const { notCompliantGroupCountVar, onGracePeriodCountVar } = useMemo(() => {
        return {
            compliantGroupCountVar: compliantGroupCount(context.myGroups),
            notCompliantGroupCountVar: notCompliantGroupCount(context.myGroups),
            onGracePeriodCountVar: onGracePeriodCount(context.myGroups),
        };
    }, [context.myGroups]);

    const displayActiveGroupsInfoBanner = (): JSX.Element => {
        if (isFetchingMyGroups || !!errorFetchingMyGroups) {
            return <></>;
        } else if (notCompliantGroupCountVar > 0) {
            if (onGracePeriodCountVar > 0) {
                return (
                    <>
                        <Spacer marginTop={10} />
                        <MessageBar messageBarType={MessageBarType.warning}>
                            You have Action Items for Grace Period and Not Compliant Groups.
                            Navigate to Policy Check under Actions to Take action.
                        </MessageBar>
                    </>
                );
            } else {
                return (
                    <>
                        <Spacer marginTop={10} />
                        <MessageBar messageBarType={MessageBarType.warning}>
                            You have Action Items for Not Compliant Groups. Navigate to Policy Check
                            under Actions to Take action.
                        </MessageBar>
                    </>
                );
            }
        } else if (onGracePeriodCountVar > 0) {
            if (onGracePeriodCountVar > 0) {
                return (
                    <>
                        <Spacer marginTop={10} />
                        <MessageBar messageBarType={MessageBarType.warning}>
                            You have Action Items for Groups in the Grace Period. Navigate to Policy
                            Check under Actions to Take action.
                        </MessageBar>
                    </>
                );
            }
        }
        return <></>;
    };

    const filteredActiveGroups = useMemo(() => {
        switch (context.groupFilter) {
            case GroupFilterType.COMPLIANT:
                return compliantGroups();
            case GroupFilterType.NOT_COMPLIANT:
                return notCompliantGroups();
            case GroupFilterType.GRACE_PERIOD:
                return gracePeriodGroups();
            default:
                return activeGroups;
        }
    }, [activeGroups, context.groupFilter]);

    const setGroupFilter = (selection: GroupFilterType): void => {
        if (context.groupFilter === selection) {
            context.setGroupFilter(GroupFilterType.NO_FILTER);
        } else {
            context.setGroupFilter(selection);
        }
    };

    return (
        <>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <div className={staticStatTileWrapperGroups}>
                <StatTile
                    title={activeGroups.length.toString()}
                    text='Total'
                    background='lightGrey'
                    onClick={(): void => {
                        setGroupFilter(GroupFilterType.NO_FILTER);
                    }}
                    ariaLabel={`${activeGroups.length.toString()} Total Groups - Click to filter by total groups`}
                    role='button'
                    ariaPressed={context.groupFilter === GroupFilterType.NO_FILTER}
                />
                <StatTile
                    title={compliantGroups().length.toString()}
                    text='Compliant'
                    background={getCompliantColor(true, false, false)}
                    onClick={(): void => {
                        setGroupFilter(GroupFilterType.COMPLIANT);
                    }}
                    highlight={context.groupFilter === GroupFilterType.COMPLIANT}
                    ariaLabel={`${compliantGroups().length.toString()} Compliant Groups - Click to filter by compliant groups`}
                    role='button'
                    ariaPressed={context.groupFilter === GroupFilterType.COMPLIANT}
                />
                <StatTile
                    title={notCompliantGroups().length.toString()}
                    text='Not Compliant'
                    background={getCompliantColor(false, false, false)}
                    onClick={(): void => {
                        setGroupFilter(GroupFilterType.NOT_COMPLIANT);
                    }}
                    highlight={context.groupFilter === GroupFilterType.NOT_COMPLIANT}
                    ariaLabel={`${notCompliantGroups().length.toString()} Not Compliant Groups - Click to filter by not compliant groups`}
                    role='button'
                    ariaPressed={context.groupFilter === GroupFilterType.NOT_COMPLIANT}
                />
                <StatTile
                    title={gracePeriodGroups().length.toString()}
                    text='Grace Period'
                    background={getCompliantColor(true, true, false)}
                    onClick={(): void => {
                        setGroupFilter(GroupFilterType.GRACE_PERIOD);
                    }}
                    highlight={context.groupFilter === GroupFilterType.GRACE_PERIOD}
                    ariaLabel={`${gracePeriodGroups().length.toString()} Grace Period Groups - Click to filter by grace period groups`}
                    role='button'
                    ariaPressed={context.groupFilter === GroupFilterType.GRACE_PERIOD}
                />
            </div>
            <div className={globalStyles.myGroupsTable}>
                <PageLoadingSpinner
                    label='Loading my groups...'
                    ariaLive='assertive'
                    isLoading={isFetchingMyGroups}
                    labelPosition='left'>
                    <ProblemLoadingMsg problemLoadingMsg={errorFetchingMyGroups}>
                        <HorizontalBar styles={{ marginTop: '20px', height: '50px' }}>
                            <h1
                                className={`${globalStyles.boldFont} ${globalStyles.mediumLargeFont}`}>
                                Active
                            </h1>
                            <Spacer marginLeft={10} />
                            <div style={{ marginTop: '5px' }}>
                                <MemberBulkModalActionButton
                                    startPage={Pages.MemberCheckBulk}
                                    selectedGroups={context.myGroups.filter((myGroup) =>
                                        selectedGroups.includes(myGroup.id),
                                    )}
                                />
                            </div>
                            <div style={{ marginTop: '5px' }}>
                                <MemberBulkModalActionButton
                                    startPage={Pages.NominateMemberBulk}
                                    selectedGroups={context.myGroups.filter((myGroup) =>
                                        selectedGroups.includes(myGroup.id),
                                    )}
                                />
                            </div>
                            {isNonExternalEnabled && (
                                <div
                                    style={{
                                        all: 'unset',
                                        marginLeft: 'auto',
                                    }}>
                                    <Link
                                        to={{
                                            pathname:
                                                'https://personnel.microsoft.com/forms/respond/group_creation_request/',
                                        }}
                                        target='_blank'>
                                        <ActionButton
                                            text={'Group Creation Request'}
                                            iconProps={{ iconName: 'AddGroup' }}
                                        />
                                    </Link>
                                </div>
                            )}
                        </HorizontalBar>
                        {displayActiveGroupsInfoBanner()}
                        {updateErrMsgElement()}
                        {activeGroups.length === 0 ? (
                            <div>{'You have no active groups'}</div>
                        ) : (
                            <DetailsList
                                items={filteredActiveGroups}
                                columns={activeTableColumns}
                                selectionMode={SelectionMode.multiple}
                                selection={selection as Selection<IObjectWithKey>}
                                checkboxVisibility={CheckboxVisibility.always}
                                selectionPreservedOnEmptyClick={true}
                                compact={true}
                                ariaLabelForSelectAllCheckbox='Toggle selection for all items'
                                checkButtonAriaLabel='Toggle selection'
                            />
                        )}
                        <HorizontalBar styles={{ marginTop: '20px', height: '50px' }}>
                            <h1
                                className={`${globalStyles.boldFont} ${globalStyles.mediumLargeFont}`}>
                                Pending
                            </h1>
                        </HorizontalBar>
                        <Table
                            rows={pendingGroups}
                            tableColumns={pendingTableColumns}
                            isFetchingData={false}
                            noDataText='You have no pending groups'
                            tableName='Pending Groups'
                        />
                    </ProblemLoadingMsg>
                </PageLoadingSpinner>
            </div>
        </>
    );
}
