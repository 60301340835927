import { PrimaryButton, SharedColors, Spinner } from '@fluentui/react';
import Modal from 'components/common/modal';
import React from 'react';

type DeleteModalButtonProps = {
    name: string;
    isDeleting: boolean;
    isSoftDeleted: boolean;
    isDeleteModalOpen: boolean;
    isModalUnclosable: boolean;
    errorMessageBarMessage?: string;
    successMessageBarMessage?: string;
    closeDeleteModal: () => void;
    deleteForm: (shouldDelete: boolean) => Promise<void>;
    setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function DeleteModalButton(props: DeleteModalButtonProps): JSX.Element {
    const {
        name,
        isSoftDeleted,
        isDeleting,
        isDeleteModalOpen,
        setDeleteModalOpen,
        isModalUnclosable,
        closeDeleteModal,
        deleteForm,
        successMessageBarMessage,
        errorMessageBarMessage,
    } = props;

    if (isSoftDeleted) {
        return (
            <>
                <PrimaryButton
                    style={{ marginLeft: 'auto' }}
                    onClick={() => setDeleteModalOpen(true)}>
                    Restore Form
                </PrimaryButton>
                <Modal
                    isOpen={isDeleteModalOpen}
                    submitButtonText={'Restore'}
                    isSubmitButtonDisabled={isModalUnclosable}
                    isCancelButtonDisabled={isModalUnclosable}
                    isBlocking={isModalUnclosable}
                    onCancel={() => closeDeleteModal()}
                    onDismiss={() => closeDeleteModal()}
                    onSubmit={() => deleteForm(false)}
                    title={`Restore form ${name}`}
                    successMsg={successMessageBarMessage}
                    errorMsg={errorMessageBarMessage}>
                    <p>Are you sure you want to restore this form?</p>
                    {isDeleting && <Spinner />}
                </Modal>
            </>
        );
    }
    return (
        <>
            <PrimaryButton
                style={{
                    marginLeft: 'auto',
                    background: SharedColors.red10,
                    border: '1px solid',
                    borderColor: SharedColors.red20,
                }}
                onClick={() => setDeleteModalOpen(true)}>
                Delete Form
            </PrimaryButton>
            <Modal
                isOpen={isDeleteModalOpen}
                submitButtonText={'Delete'}
                isSubmitButtonDisabled={isModalUnclosable}
                isCancelButtonDisabled={isModalUnclosable}
                isBlocking={isModalUnclosable}
                onCancel={() => closeDeleteModal()}
                onDismiss={() => closeDeleteModal()}
                onSubmit={() => deleteForm(true)}
                title={`Delete form ${name}`}
                successMsg={successMessageBarMessage}
                errorMsg={errorMessageBarMessage}>
                <p>Are you sure you want to delete this form? This can be undone at any time.</p>
                <p>All existing records of this form will be unaffected.</p>
                {isDeleting && <Spinner />}
            </Modal>
        </>
    );
}
