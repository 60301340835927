import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link, mergeStyleSets, MessageBar, MessageBarType } from '@fluentui/react';
import BoldFont from 'components/common/misc/bold-font';

export default function ScreeningAttentionMessage(): JSX.Element {
    const scalesOfDisciplineLink = `https://aka.ms/personnel/nsocscalesofdiscipline`;
    const helpLink = `https://aka.ms/personnel/userguides/usgov`; // us gov link

    const styles = mergeStyleSets({
        linkContainer: {
            marginBottom: '20px',
        },
    });

    return (
        <MessageBar messageBarType={MessageBarType.warning} className={styles.linkContainer}>
            <BoldFont>PAUSE. THINK. ACT.</BoldFont> This is sensitive information that is limited to
            vetted personnel only. Misuse of this information may result in penalties outlined by
            the{' '}
            <Link href={scalesOfDisciplineLink} target='_blank' rel='noopener noreferrer' underline>
                scales of discipline
            </Link>{' '}
            in this protecting and managing information policy. By using this platform, you are
            acknowledging notice of, and agree to comply with, all NST and Microsoft policies
            concerning data security and personal data use. Questions?
            <Link href={helpLink} target='_blank' rel='noopener noreferrer' underline>
                Check out our FAQs and user guide
            </Link>
        </MessageBar>
    );
}
