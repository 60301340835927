import {
    DatePicker,
    Dropdown,
    IDropdownOption,
    IPersonaProps,
    Label,
    MessageBar,
    MessageBarType,
    Stack,
    TextField,
    mergeStyleSets,
    mergeStyles,
} from '@fluentui/react';
import FacilitiesClient, {
    IFacilityRecord,
    Status,
    EquipmentTypes,
    Regions,
    IEquipmentRecord,
} from 'clients/facilities-client';
import { FacilityUserType } from 'utils/facilities-utils';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import Modal, { ModalSizeType } from 'components/common/modal';
import ContractPicker from 'components/common/contract-picker';
import { ContractType, IContract } from 'components/screening/us-gov/IContract';
import { ClearanceClient, IClearanceRecord } from 'clients/clearance-client';
import EmployeeClient, { IEmployee } from 'clients/employee-client';
import {
    transformContractToPersona,
    transformEmployeeToPersona,
} from 'utils/internal-persona-utils';
import { globalStyles } from 'assets/styles/global-styles';
import { IPagedResults } from 'clients/http-options';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import { PartialEquipmentRecord } from 'components/user-assignments/equipment/equipment';
import { getClearanceText } from 'components/personnel-profile/clearance/profile-clearance-constants';
import { nameof } from 'utils/object-utils';
import { separateWordsByCapitalLetter, upperCaseFirstLetter } from 'utils/string-utils';

type EquipmentModalPropsType = {
    setShowAddEditModal: (showAddEditModal: boolean) => void;
    facilities: IFacilityRecord[];
    setShouldRefresh: (shouldRefresh: boolean) => void;
    existingEquipment: PartialEquipmentRecord;
};

const fieldLabelsForErrorMessages = {
    owner: 'Owner',
    region: 'Region',
    facility: 'Facility',
    equipmentType: 'Equipment type',
    make: 'Make',
    model: 'Model',
    assetNumber: 'Asset/card number',
    status: 'Status',
    returnedOn: 'Returned on',
    reportedOn: 'Reported on',
    issuedBy: 'Issued by',
    issuedOn: 'Issued on',
    clearanceRecord: 'Clearance record',
    contract: 'Contract',
    activeContract: 'Active contract',
} as const;

export default function AddEditEquipmentModal(props: EquipmentModalPropsType): JSX.Element {
    const { setShowAddEditModal, facilities, setShouldRefresh, existingEquipment } = props;
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [errors, setErrors] = useState<JSX.Element>();
    const [equipmentItem, setEquipmentItem] = useState<PartialEquipmentRecord>(existingEquipment);
    const [clearances, setClearances] = useState<IClearanceRecord[]>([]);
    const [contracts, setContracts] = useState<IContract[]>([]);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [owner, setOwner] = useState<IEmployee>();
    const [issuedBy, setIssuedBy] = useState<IEmployee>();

    const isEditMode = existingEquipment.id ? true : false;
    const isUserInNSTRole = userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST);
    const isUserInEquipOfficerRole = userContext.hasFacilitiesUserType(
        FacilityUserType.EquipmentOfficerRole,
    );

    useEffect(() => {
        const loadContracts = async (): Promise<void> => {
            const contractArray: IContract[] = [];
            try {
                let continuationToken: string | undefined = '';
                do {
                    const response: IPagedResults<IContract> = await UsGovScreeningClient.getContracts(
                        authContext,
                        Object.keys(ContractType),
                        continuationToken,
                    );
                    contractArray.push(...response.results);
                    continuationToken = response.continuationToken;
                } while (continuationToken);
                setContracts(contractArray);
            } catch (err) {
                console.error(err);
            }
        };
        if (isUserInNSTRole) {
            loadContracts();
        }
    }, []);

    useEffect(() => {
        const loadOwnerAsEmployee = async (ownerPersonnelId: string): Promise<void> => {
            const fetchedOwner = await EmployeeClient.getEmployeeByAliasOrId(
                authContext,
                ownerPersonnelId,
            );
            setOwner(fetchedOwner);
        };

        const getAndSetClearances = async (personnelId: string): Promise<void> => {
            try {
                const retrievedClearances: IClearanceRecord[] = [];
                const employeeData = await EmployeeClient.getEmployeeByAliasOrId(
                    authContext,
                    personnelId,
                );

                let continuationToken: string | undefined = '';
                do {
                    const response: IPagedResults<IClearanceRecord> = await ClearanceClient.getClearancesForEmployee(
                        authContext,
                        employeeData,
                        continuationToken,
                    );
                    retrievedClearances.push(...response.results);
                    continuationToken = response.continuationToken;
                } while (continuationToken);

                setClearances(retrievedClearances);
            } catch (err) {
                console.error(err);
            }
        };

        if (equipmentItem.ownerPersonnelId) {
            loadOwnerAsEmployee(equipmentItem.ownerPersonnelId);
            getAndSetClearances(equipmentItem.ownerPersonnelId);
        } else {
            setOwner(undefined);
            setClearances([]);
        }
    }, [equipmentItem.ownerPersonnelId]);

    useEffect(() => {
        const loadIssuedByAsEmployee = async (): Promise<void> => {
            try {
                if (equipmentItem.issued?.by) {
                    const fetchedIssuedBy = await EmployeeClient.getEmployeeByAliasOrId(
                        authContext,
                        equipmentItem.issued.by,
                    );
                    setIssuedBy(fetchedIssuedBy);
                } else {
                    console.error("No personnelId was provided when loading 'Issued by' field");
                }
            } catch (err) {
                console.error(err);
            }
        };

        if (equipmentItem.issued?.by) {
            loadIssuedByAsEmployee();
        } else {
            setIssuedBy(undefined);
        }
    }, [equipmentItem.issued?.by]);

    const validateFieldsAndReturnErrors = (): string[] => {
        const requiredFields: string[] = [];

        if (!equipmentItem.ownerPersonnelId) {
            requiredFields.push(fieldLabelsForErrorMessages.owner);
        }
        if (!equipmentItem.region) {
            requiredFields.push(fieldLabelsForErrorMessages.region);
        }
        if (!equipmentItem.facilityId) {
            requiredFields.push(fieldLabelsForErrorMessages.facility);
        }
        if (!equipmentItem.type) {
            requiredFields.push(fieldLabelsForErrorMessages.equipmentType);
        }
        if (!isEquipmentTypeSmartCard && !equipmentItem.make) {
            requiredFields.push(fieldLabelsForErrorMessages.make);
        }
        if (!isEquipmentTypeSmartCard && !equipmentItem.model) {
            requiredFields.push(fieldLabelsForErrorMessages.model);
        }
        if (!equipmentItem.assetNumber) {
            requiredFields.push(fieldLabelsForErrorMessages.assetNumber);
        }
        if (!isEquipmentTypeSmartCard && !equipmentItem.status) {
            requiredFields.push(fieldLabelsForErrorMessages.status);
        }
        if (isEquipmentStatusReturned && !equipmentItem.returnedOnUtcMilliseconds) {
            requiredFields.push(fieldLabelsForErrorMessages.returnedOn);
        }
        if (isEquipmentStatusStolen && !equipmentItem.reportedOnUtcMilliseconds) {
            requiredFields.push(fieldLabelsForErrorMessages.reportedOn);
        }
        if (!isEquipmentTypeSmartCard && !equipmentItem.issued?.atUtc) {
            requiredFields.push(fieldLabelsForErrorMessages.issuedOn);
        }
        if (isIssuedByPropertyRequired && !equipmentItem.issued?.by) {
            requiredFields.push(fieldLabelsForErrorMessages.issuedBy);
        }
        if (isEquipmentTypeGFE && !equipmentItem.clearanceId) {
            requiredFields.push(fieldLabelsForErrorMessages.clearanceRecord);
        }
        if (isEquipmentTypeGFE && !equipmentItem.contractId) {
            requiredFields.push(fieldLabelsForErrorMessages.contract);
        }
        if (
            isEquipmentTypeGFE &&
            equipmentItem.contractId &&
            contracts.find((x) => x.id === equipmentItem.contractId)?.contractStatus !== 'Active'
        ) {
            requiredFields.push(fieldLabelsForErrorMessages.activeContract);
        }
        return requiredFields;
    };

    const onSubmit = (): void => {
        const errors = validateFieldsAndReturnErrors();
        if (errors.length === 0) {
            setErrors(undefined);
            if (isEditMode) {
                editEquipmentItem();
            } else {
                createEquipmentItem();
            }
        } else {
            setErrors(errorsList(errors));
        }
    };

    const createEquipmentItem = async (): Promise<void> => {
        try {
            setIsBusy(true);
            await FacilitiesClient.createEquipmentRecords(authContext, userContext, [
                equipmentItem,
            ]);
        } catch (err) {
            console.error(err);
        } finally {
            setShowAddEditModal(false);
            setShouldRefresh(true);
        }
    };

    const editEquipmentItem = async (): Promise<void> => {
        try {
            setIsBusy(true);
            await FacilitiesClient.updateEquipmentRecords(authContext, userContext, [
                equipmentItem,
            ]);
        } catch (err) {
            console.error(err);
        } finally {
            setShowAddEditModal(false);
            setShouldRefresh(true);
        }
    };

    const onOwnerSelected = (persona?: IPersonaProps): void => {
        setEquipmentItem((prev: PartialEquipmentRecord) => ({
            ...prev,
            ownerPersonnelId: persona?.itemProp ? JSON.parse(persona?.itemProp).id : undefined,
        }));
    };

    const onIssuedBySelected = (persona?: IPersonaProps): void => {
        setEquipmentItem((prev: PartialEquipmentRecord) => {
            return {
                ...prev,
                issued: {
                    atUtc: prev.issued?.atUtc ?? 0,
                    by: persona?.itemProp ? JSON.parse(persona?.itemProp).id : undefined,
                },
            };
        });
    };

    const onContractSelected = (persona?: IPersonaProps): void => {
        setEquipmentItem((prev: PartialEquipmentRecord) => {
            return {
                ...prev,
                contractId: persona?.itemProp ? JSON.parse(persona?.itemProp).id : undefined,
            };
        });
    };

    const onTextFieldChange = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        property: keyof IEquipmentRecord,
        newValue?: string,
    ): void => {
        setEquipmentItem((prev: PartialEquipmentRecord) => {
            return {
                ...prev,
                [property]: newValue,
            };
        });
    };

    const onDropdownFieldChange = useCallback(
        (ev: React.FormEvent<HTMLDivElement>, property: string, opt?: IDropdownOption<unknown>) => {
            if (opt) {
                setEquipmentItem((prev: PartialEquipmentRecord) => {
                    return { ...prev, [property]: opt.key };
                });
            }
        },
        [],
    );

    const onDatePickerDateSelected = useCallback(
        (date: Date | null | undefined, property: keyof IEquipmentRecord) => {
            const toMS = date?.getTime() || 0;
            setEquipmentItem((prev: PartialEquipmentRecord) => {
                return { ...prev, [property]: toMS };
            });
        },
        [],
    );

    const onIssuedOnSelected = useCallback((date: Date | null | undefined) => {
        const toMS = date?.getTime() || 0;
        setEquipmentItem((prev: PartialEquipmentRecord) => ({
            ...prev,
            issued: { atUtc: toMS, by: prev.issued?.by ?? '' },
        }));
    }, []);

    const NSTInfo = (): JSX.Element => {
        return (
            <li>
                <strong>Note:</strong> Equipment officer must have screening officer user type to
                save GFE equipment. <strong>Questions?</strong> Contact the
                <a href='mailto:NSTcompliance@microsoft.com'>National Security Team</a> for support.
            </li>
        );
    };

    const errorListItemWithNSTInfo = (field: string, index: number): JSX.Element => {
        return (
            <li key={index}>
                {field} is required
                <ul>
                    <NSTInfo />
                </ul>
            </li>
        );
    };

    const errorListItem = (field: string, index: number): JSX.Element => {
        return <li key={index}>{field} is required</li>;
    };

    const errorsList = (errors: string[]): JSX.Element => {
        return (
            <>
                Correct the following error(s) and try again:
                <ul>
                    {errors.map((error, index) =>
                        error === 'Clearance record' || error === 'Contract'
                            ? errorListItemWithNSTInfo(error, index)
                            : errorListItem(error, index),
                    )}
                </ul>
            </>
        );
    };

    const statusOptions: IDropdownOption[] = useMemo(
        () =>
            Object.keys(Status)
                .filter((x) => {
                    if (x === nameof(Status.CheckedIn) || x === nameof(Status.CheckedOut)) {
                        return (
                            equipmentItem.type === nameof<typeof EquipmentTypes>('RSTARME') ||
                            equipmentItem.type === nameof<typeof EquipmentTypes>('ESTARME')
                        );
                    } else {
                        return true;
                    }
                })
                .map((key) => ({
                    key,
                    text: upperCaseFirstLetter(separateWordsByCapitalLetter(key)),
                })),
        [equipmentItem.type],
    );

    const equipmentTypeOptions: IDropdownOption[] = useMemo(() => {
        const dropdownOptions = Object.entries(EquipmentTypes).map(([key, value]) => ({
            key,
            text: value,
        }));
        return isUserInEquipOfficerRole
            ? dropdownOptions
            : dropdownOptions.filter(
                  // 24353143 feature - filter out GFE type equipments for no equipment officer role
                  (x) =>
                      x.text !== EquipmentTypes.CAC &&
                      x.text !== EquipmentTypes.ICBadge &&
                      x.text !== EquipmentTypes.RSA &&
                      x.text !== EquipmentTypes.SIPR &&
                      x.text !== EquipmentTypes.PIV &&
                      x.text !== EquipmentTypes.Laptop,
              );
    }, [isUserInEquipOfficerRole]);

    const regionOptions: IDropdownOption[] = useMemo(
        () => Object.entries(Regions).map(([key, value]) => ({ key, text: value })),
        [],
    );

    const facilityOptions: IDropdownOption[] = useMemo(() => {
        return facilities
            .filter((x) => x.facilityRegion === equipmentItem.region)
            .map((facility) => ({
                key: facility.facilityId,
                text: facility.facilityName,
            }));
    }, [facilities, equipmentItem.region]);

    const clearanceOptions: IDropdownOption[] = useMemo(
        () =>
            clearances.map((clearance) => ({
                key: clearance.id,
                text: getClearanceText(clearance),
            })),
        [clearances],
    );

    const returnIssuedDate: Date | undefined = equipmentItem.issued?.atUtc
        ? new Date(equipmentItem.issued.atUtc)
        : undefined;

    const returnExpiration: Date | undefined =
        equipmentItem &&
        equipmentItem.expirationOnUtcMilliseconds &&
        equipmentItem.expirationOnUtcMilliseconds > 0
            ? new Date(equipmentItem.expirationOnUtcMilliseconds)
            : undefined;

    const [isEquipmentTypeGFE, isEquipmentTypeSmartCard] = useMemo(() => {
        if (equipmentItem?.type === undefined) return [false, false];

        const isGFE =
            EquipmentTypes[equipmentItem.type] === EquipmentTypes.CAC ||
            EquipmentTypes[equipmentItem.type] === EquipmentTypes.ICBadge ||
            EquipmentTypes[equipmentItem.type] === EquipmentTypes.RSA ||
            EquipmentTypes[equipmentItem.type] === EquipmentTypes.SIPR ||
            EquipmentTypes[equipmentItem.type] === EquipmentTypes.PIV ||
            EquipmentTypes[equipmentItem.type] === EquipmentTypes.Laptop;

        const isSmartCard =
            EquipmentTypes[equipmentItem.type] === EquipmentTypes.RSTARME ||
            EquipmentTypes[equipmentItem.type] === EquipmentTypes.ESTARME;
        return [isGFE, isSmartCard];
    }, [equipmentItem.type]);

    const [isIssuedByPropertyRequired] = useMemo(() => {
        if (equipmentItem?.type === undefined) return [false];

        const isIssuedByRequired =
            EquipmentTypes[equipmentItem.type] === EquipmentTypes.FacilityBadge ||
            EquipmentTypes[equipmentItem.type] === EquipmentTypes.Yubikey ||
            EquipmentTypes[equipmentItem.type] === EquipmentTypes.AccessFob;
        return [isIssuedByRequired];
    }, [equipmentItem.type]);

    const [isEquipmentStatusReturned, isEquipmentStatusStolen] = useMemo(() => {
        if (equipmentItem?.status === undefined) return [false, false];

        const isReturned = Status[equipmentItem.status] === Status.Returned;
        const isStolen = Status[equipmentItem.status] === Status.Stolen;
        return [isReturned, isStolen];
    }, [equipmentItem.status]);

    const issuedDate = (): Date | undefined => {
        return equipmentItem.issued?.atUtc ? new Date(equipmentItem.issued?.atUtc) : undefined;
    };

    const findSmallerDate = (
        date1: number | undefined,
        date2: number | undefined,
    ): Date | undefined => {
        if (!date1 && !date2) {
            return undefined;
        }

        if (date1 && date2) {
            return date1 < date2 ? new Date(date1) : new Date(date2);
        }

        if (date1 && !date2) {
            return new Date(date1);
        }

        if (!date1 && date2) {
            return new Date(date2);
        }
    };

    const issuedOnMaxDate = (): Date | undefined => {
        if (!isEquipmentStatusReturned && !isEquipmentStatusStolen) {
            return equipmentItem.expirationOnUtcMilliseconds
                ? new Date(equipmentItem.expirationOnUtcMilliseconds)
                : undefined;
        }

        if (isEquipmentStatusReturned) {
            return findSmallerDate(
                equipmentItem.expirationOnUtcMilliseconds,
                equipmentItem.returnedOnUtcMilliseconds,
            );
        }

        if (isEquipmentStatusStolen) {
            return findSmallerDate(
                equipmentItem.expirationOnUtcMilliseconds,
                equipmentItem.reportedOnUtcMilliseconds,
            );
        }
    };

    const removeField = (fieldName: keyof IEquipmentRecord): void => {
        setEquipmentItem((prev: IEquipmentRecord | PartialEquipmentRecord) => {
            if (prev && prev[fieldName]) {
                delete prev[fieldName];
            }
            return { ...prev };
        });
    };

    const removeUnusedFieldValues = useCallback((): void => {
        if (!isEquipmentStatusStolen && equipmentItem.reportedOnUtcMilliseconds) {
            removeField('reportedOnUtcMilliseconds');
        }
        if (!isEquipmentStatusReturned && equipmentItem.returnedOnUtcMilliseconds) {
            removeField('returnedOnUtcMilliseconds');
        }
        if (!isEquipmentTypeGFE) {
            if (equipmentItem.contractId) {
                removeField('contractId');
            }
            if (equipmentItem.clearanceId) {
                removeField('clearanceId');
            }
        }
    }, [
        equipmentItem.clearanceId,
        equipmentItem.contractId,
        equipmentItem.reportedOnUtcMilliseconds,
        equipmentItem.returnedOnUtcMilliseconds,
        isEquipmentTypeGFE,
        isEquipmentStatusReturned,
        isEquipmentStatusStolen,
    ]);

    useEffect(() => {
        removeUnusedFieldValues();
    }, [removeUnusedFieldValues, equipmentItem.type, equipmentItem.status]);

    const makeHtmlId = useId('makeField');
    const modelHtmlId = useId('modelField');
    const assetHtmlId = useId('assetField');

    return (
        <Modal
            title={isEditMode ? 'Manage equipment' : 'Add equipment'}
            size={ModalSizeType.mediumLarge}
            onSubmit={onSubmit}
            isOpen={true}
            onCancel={(): void => setShowAddEditModal(false)}
            onDismiss={(): void => setShowAddEditModal(false)}
            showProgressIndicator={isBusy}
            isSubmitButtonDisabled={isBusy}>
            <Stack horizontal tokens={{ padding: 7 }}>
                <Stack.Item className={styles.left}>
                    <Label required>Owner</Label>
                </Stack.Item>
                <Stack.Item className={styles.right}>
                    <EmployeePickerTypeaheadSearch
                        ariaLabel='Owner'
                        disabled={!!existingEquipment.ownerPersonnelId}
                        required={true}
                        selectedItems={owner ? [transformEmployeeToPersona(owner)] : []}
                        onCandidateSelected={onOwnerSelected}
                    />
                </Stack.Item>
            </Stack>

            <Stack horizontal tokens={{ padding: 7 }}>
                <Stack.Item className={styles.left}>
                    <Label required>Region</Label>
                </Stack.Item>
                <Stack.Item className={styles.right}>
                    <Dropdown
                        disabled={isEditMode}
                        ariaLabel='Region'
                        onChange={(ev, o): void => onDropdownFieldChange(ev, 'region', o)}
                        options={regionOptions}
                        selectedKey={equipmentItem.region}
                    />
                </Stack.Item>
            </Stack>

            <Stack horizontal tokens={{ padding: 7 }}>
                <Stack.Item className={styles.left}>
                    <Label required>Facility</Label>
                </Stack.Item>
                <Stack.Item className={styles.right}>
                    <Dropdown
                        disabled={isEditMode}
                        ariaLabel='Facility'
                        onChange={(ev, o): void => onDropdownFieldChange(ev, 'facilityId', o)}
                        options={facilityOptions}
                        selectedKey={equipmentItem.facilityId}
                    />
                </Stack.Item>
            </Stack>

            <Stack horizontal tokens={{ padding: 7 }}>
                <Stack.Item className={styles.left}>
                    <Label required>Equipment type</Label>
                </Stack.Item>
                <Stack.Item className={styles.right}>
                    <Dropdown
                        disabled={isEditMode}
                        ariaLabel='Equipment type'
                        onChange={(ev, o): void => onDropdownFieldChange(ev, 'type', o)}
                        options={equipmentTypeOptions}
                        selectedKey={equipmentItem.type}
                    />
                </Stack.Item>
            </Stack>

            <Stack horizontal tokens={{ padding: 7 }}>
                <Stack.Item className={styles.left}>
                    <Label htmlFor={makeHtmlId} required={!isEquipmentTypeSmartCard}>
                        Make
                    </Label>
                </Stack.Item>
                <Stack.Item className={styles.right}>
                    <TextField
                        id={makeHtmlId}
                        value={equipmentItem.make}
                        onChange={(ev, nv): void => onTextFieldChange(ev, 'make', nv)}
                    />
                </Stack.Item>
            </Stack>

            <Stack horizontal tokens={{ padding: 7 }}>
                <Stack.Item className={styles.left}>
                    <Label htmlFor={modelHtmlId} required={!isEquipmentTypeSmartCard}>
                        Model
                    </Label>
                </Stack.Item>
                <Stack.Item className={styles.right}>
                    <TextField
                        id={modelHtmlId}
                        value={equipmentItem.model}
                        onChange={(ev, nv): void => onTextFieldChange(ev, 'model', nv)}
                    />
                </Stack.Item>
            </Stack>

            <Stack horizontal tokens={{ padding: 7 }}>
                <Stack.Item className={styles.left}>
                    <Label htmlFor={assetHtmlId} required>
                        Asset/card number
                    </Label>
                </Stack.Item>
                <Stack.Item className={styles.right}>
                    <TextField
                        id={assetHtmlId}
                        value={equipmentItem.assetNumber}
                        onChange={(ev, nv): void => onTextFieldChange(ev, 'assetNumber', nv)}
                    />
                </Stack.Item>
            </Stack>

            <Stack horizontal tokens={{ padding: 7 }}>
                <Stack.Item className={styles.left}>
                    <Label required={!isEquipmentTypeSmartCard}>Status</Label>
                </Stack.Item>
                <Stack.Item className={styles.right}>
                    <Dropdown
                        ariaLabel='Status'
                        onChange={(ev, o): void => onDropdownFieldChange(ev, 'status', o)}
                        options={statusOptions}
                        selectedKey={equipmentItem.status}
                    />
                </Stack.Item>
            </Stack>

            {isEquipmentStatusReturned && (
                <Stack horizontal tokens={{ padding: 7 }}>
                    <Stack.Item className={styles.left}>
                        <Label required>Returned on</Label>
                    </Stack.Item>
                    <Stack.Item className={styles.right}>
                        <DatePicker
                            ariaLabel='Returned on'
                            onSelectDate={(ev): void =>
                                onDatePickerDateSelected(ev, 'returnedOnUtcMilliseconds')
                            }
                            value={
                                equipmentItem.returnedOnUtcMilliseconds
                                    ? new Date(equipmentItem.returnedOnUtcMilliseconds)
                                    : undefined
                            }
                            minDate={issuedDate()}
                        />
                    </Stack.Item>
                </Stack>
            )}

            {isEquipmentStatusStolen && (
                <Stack horizontal tokens={{ padding: 7 }}>
                    <Stack.Item className={styles.left}>
                        <Label required>Reported on</Label>
                    </Stack.Item>
                    <Stack.Item className={styles.right}>
                        <DatePicker
                            ariaLabel='Reported on'
                            onSelectDate={(ev): void =>
                                onDatePickerDateSelected(ev, 'reportedOnUtcMilliseconds')
                            }
                            value={
                                equipmentItem.reportedOnUtcMilliseconds
                                    ? new Date(equipmentItem.reportedOnUtcMilliseconds)
                                    : undefined
                            }
                            minDate={issuedDate()}
                        />
                    </Stack.Item>
                </Stack>
            )}

            <Stack horizontal tokens={{ padding: 7 }}>
                <Stack.Item className={styles.left}>
                    <Label required={!isEquipmentTypeSmartCard}>Issued on</Label>
                </Stack.Item>
                <Stack.Item className={styles.right}>
                    <DatePicker
                        ariaLabel='Issued on'
                        onSelectDate={(d): void => onIssuedOnSelected(d)}
                        value={returnIssuedDate}
                        maxDate={issuedOnMaxDate()}
                    />
                </Stack.Item>
            </Stack>

            {!isEquipmentTypeGFE && (
                <Stack horizontal tokens={{ padding: 7 }}>
                    <Stack.Item className={styles.left}>
                        <Label required={isIssuedByPropertyRequired}>Issued by</Label>
                    </Stack.Item>
                    <Stack.Item className={styles.right}>
                        <EmployeePickerTypeaheadSearch
                            ariaLabel='Issued by'
                            selectedItems={issuedBy ? [transformEmployeeToPersona(issuedBy)] : []}
                            onCandidateSelected={onIssuedBySelected}
                        />
                    </Stack.Item>
                </Stack>
            )}

            <Stack horizontal tokens={{ padding: 7 }}>
                <Stack.Item className={styles.left}>
                    <Label>Expiration</Label>
                </Stack.Item>
                <Stack.Item className={styles.right}>
                    <DatePicker
                        ariaLabel='Expiration'
                        onSelectDate={(ev): void =>
                            onDatePickerDateSelected(ev, 'expirationOnUtcMilliseconds')
                        }
                        value={returnExpiration}
                        minDate={issuedDate()}
                    />
                </Stack.Item>
            </Stack>

            {isUserInNSTRole && isEquipmentTypeGFE && (
                <Stack horizontal tokens={{ padding: 7 }}>
                    <Stack.Item className={styles.left}>
                        <Label required>Clearance record</Label>
                    </Stack.Item>
                    <Stack.Item className={styles.right}>
                        <Dropdown
                            ariaLabel='Clearance record'
                            onChange={(ev, o): void => onDropdownFieldChange(ev, 'clearanceId', o)}
                            options={clearanceOptions}
                            selectedKey={equipmentItem.clearanceId}
                        />
                    </Stack.Item>
                </Stack>
            )}
            {isUserInNSTRole && isEquipmentTypeGFE && contracts.length === 0 && (
                <Stack horizontal tokens={{ padding: 7 }}>
                    <Stack.Item className={styles.left}>
                        <Label required>Contract</Label>
                    </Stack.Item>
                    <Stack.Item className={styles.right}>
                        <TextField disabled></TextField>
                    </Stack.Item>
                </Stack>
            )}
            {isUserInNSTRole && isEquipmentTypeGFE && contracts.length > 0 && (
                <Stack horizontal tokens={{ padding: 7 }}>
                    <Stack.Item className={styles.left}>
                        <Label required>Contract</Label>
                    </Stack.Item>
                    <Stack.Item className={styles.right}>
                        <ContractPicker
                            contracts={contracts}
                            onContractSelected={onContractSelected}
                            selectedPersona={transformContractToPersona(
                                contracts.find((x) => x.id === equipmentItem.contractId),
                            )}
                        />
                    </Stack.Item>
                </Stack>
            )}
            {errors && <MessageBar messageBarType={MessageBarType.error}>{errors}</MessageBar>}
        </Modal>
    );
}

const styles = mergeStyleSets({
    left: mergeStyles(
        {
            width: '30%',
        },
        globalStyles.boldFont,
    ),
    right: {
        width: '70%',
    },
});
