import React, { useState, useEffect, useContext } from 'react';
import CheckRole from 'components/common/check-role';
import { AccessDeniedURL, Dictionary } from 'assets/constants/global-constants';
import EligibilityClient, { IEligibility } from 'clients/eligibility-client';
import { generalIsMountedCode } from 'utils/misc-utils';
import { AuthContext } from 'contexts/auth-context';
import { Role } from 'configs/roles';
import ButtonBar from 'components/common/button-bar';
import { getEligibilitiesTableColumns } from 'components/eligibilities/eligibilities/manage/table-columns-eligibilities';
import { useSortColumnHandler, strCmp, numCmp } from 'utils/sort-utils';
import { Table } from 'components/common/table';
import moment from 'moment';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { ModalMode } from 'components/eligibilities/eligibilities-constants';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import AddEligibilityModalButton from 'components/eligibilities/eligibilities/manage/add-eligibility-modal-button';
import { useAttributes, useEligibilities } from 'components/eligibilities/eligibilities-utils';
import { ProblemLoadingData } from 'components/common/problem-loading/problem-loading-data';
import Spacer from 'components/common/spacer';

export default function Eligibilities(): JSX.Element {
    const authContext = useContext(AuthContext);
    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler('Code', 1);

    const [requestCounts, setRequestCounts] = useState<Dictionary<number>>({});
    const [requestCountsError, setRequestCountsError] = useState<string>();
    const [tableRows, setTableRows] = useState<IEligibility[]>([]);

    const { attributes } = useAttributes(authContext);

    const {
        eligibilities,
        isFetchingEligibilities: isLoading,
        hasProblemLoadingEligibilities: hasProblemLoadingPage,
        setEligibilities,
    } = useEligibilities(authContext);

    const fetchRequestCounts = async (isMountedFunc: () => boolean) => {
        try {
            const requestCountsVar = await EligibilityClient.getEligibilityRequestCounts(
                authContext,
                eligibilities.map((e) => e.id),
            );
            if (isMountedFunc()) {
                setRequestCounts(requestCountsVar);
            }
        } catch {
            if (isMountedFunc()) {
                setRequestCountsError('Could not fetch request counts');
            }
        }
    };

    useEffect(() => {
        return generalIsMountedCode(fetchRequestCounts);
    }, [eligibilities]);

    const onAddEditEligibilityConcluded = (
        mode: ModalMode,
        modalConclusion: ModalConclusion,
        result?: IEligibility,
    ): void => {
        switch (modalConclusion) {
            case ModalConclusion.Done:
                let eligibilitiesVar: IEligibility[];
                switch (mode) {
                    case ModalMode.Add:
                        eligibilitiesVar = [...eligibilities];
                        eligibilitiesVar.push(result as IEligibility);
                        setEligibilities(eligibilitiesVar);
                        break;
                    case ModalMode.Update:
                        eligibilitiesVar = [...eligibilities];
                        const index = eligibilitiesVar.findIndex(
                            (eligibility) => eligibility.id === result?.id,
                        );
                        if (index > -1) {
                            eligibilitiesVar.splice(index, 1, result as IEligibility);
                            setEligibilities(eligibilitiesVar);
                        }
                        break;
                    default:
                        break;
                }
                break;
            case ModalConclusion.Cancel:
            default:
                break;
        }
    };

    const sortEligibilities = (eligibilities: IEligibility[]): IEligibility[] => {
        type A = IEligibility;

        const chooseSortCmp = (
            sortColumn: string,
        ): ((r1: IEligibility, r2: IEligibility) => number) => {
            switch (sortColumn) {
                case 'Code':
                    return (r1: A, r2: A): number =>
                        sortAscending * strCmp(r1.eligibilityCode, r2.eligibilityCode);
                case 'Description':
                    return (r1: A, r2: A): number =>
                        sortAscending * strCmp(r1.eligibilityName, r2.eligibilityName);
                case 'Created At':
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(moment(r1.createdAt).valueOf(), moment(r2.createdAt).valueOf());
                case 'Last Modified At':
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(
                            moment(r1.lastModifiedAt).valueOf(),
                            moment(r2.lastModifiedAt).valueOf(),
                        );
                default:
                    // Sort column not recognized. No sorting performed.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp(sortColumn);
        return [...eligibilities].sort(sortCmp);
    };

    const onResetRequestCountsError = (): void => {
        setRequestCountsError('');
    };

    const onDeletetEligibilityConcluded = (
        eligibility: IEligibility,
        conclusion: ModalConclusion,
    ): void => {
        if (conclusion !== ModalConclusion.Done) {
            return;
        }
        const index = eligibilities.findIndex((a) => a.id === eligibility.id);
        if (index !== -1) {
            const eligibilitiesVar = [...eligibilities];
            eligibilitiesVar.splice(index, 1);
            setEligibilities(eligibilitiesVar);
        }
    };

    const tableColumns = getEligibilitiesTableColumns({
        sortColumn,
        attributes,
        sortAscending: sortAscending === 1,
        requestCounts,
        hasEligibilityModify: authContext.isInRole(Role.EligibilitiesModify),
        hasEligibilityDelete: authContext.isInRole(Role.EligibilitiesDelete),
        sortColumnHandler,
        onAddEditEligibilityConcluded,
        onDeletetEligibilityConcluded,
    });

    useEffect(() => {
        const tableRowsVar = sortEligibilities(eligibilities);
        setTableRows(tableRowsVar);
    }, [sortAscending, sortColumn, eligibilities]);

    return (
        <CheckRole requiredRolesAny={[Role.EligibilitiesRead]} redirectNotInRole={AccessDeniedURL}>
            <ProblemLoadingData isProblemLoadingData={!!hasProblemLoadingPage}>
                <CheckRole requiredRolesAny={[Role.EligibilitiesWrite]}>
                    <ButtonBar>
                        <AddEligibilityModalButton
                            mode={ModalMode.Add}
                            attributes={attributes}
                            onAddEditEligibilityConcluded={onAddEditEligibilityConcluded}
                        />
                    </ButtonBar>
                </CheckRole>
                {requestCountsError && (
                    <MessageBar
                        onDismiss={onResetRequestCountsError}
                        messageBarType={MessageBarType.error}
                        dismissButtonAriaLabel='Close'>
                        {requestCountsError}
                    </MessageBar>
                )}
                <Table
                    rows={tableRows || []}
                    tableColumns={tableColumns}
                    isFetchingData={isLoading}
                    tableName='Eligibilities'
                />
                <Spacer marginTop={100} />
            </ProblemLoadingData>
        </CheckRole>
    );
}
