import { IDocumentMetadata } from 'clients/template-client';
import {
    TextFieldBase,
    MessageBarType,
    DefaultButton,
    Label,
    MessageBar,
    PrimaryButton,
    TextField,
} from '@fluentui/react';
import React, { useRef, useState, ChangeEvent, FormEvent } from 'react';
import {
    isValidFileType,
    acceptedFileTypesForTemplate,
    fileInputAcceptStringForTemplate,
    acceptedFileTypesTextForTemplate,
} from 'utils/file-utils';
import {
    documentScreeningStyles,
    stylesForOtherDocuments,
} from 'components/screening/common/documents/document-styles';

interface OtherDocumentsRequesterProps {
    handleModalClose(documentMetadata?: IDocumentMetadata, uploadFile?: File): void;
    screeningId: string;
}

export default function OtherDocumentsRequesterFlag(
    props: OtherDocumentsRequesterProps,
): JSX.Element {
    const titleField = useRef<TextFieldBase>(null);
    const notesField = useRef<TextFieldBase>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileField = useRef<any>(null);
    const [fileName, setFileName] = useState<string>();
    const [isInvalidFile, setIsInvalidFile] = useState<boolean>(false);
    const [isEmptyFile, setIsEmptyFile] = useState<boolean>(false);
    const [isInvalidTitle, setIsInvalidTitle] = useState<boolean>(false);

    async function onFileChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        if (event.target.files && event.target.files[0]) {
            const file: File = event.target.files[0];
            const isValid: boolean = await isValidFileType(file, acceptedFileTypesForTemplate);
            setFileName(file.name);
            setIsEmptyFile(file.size === 0);
            setIsInvalidFile(!isValid);
        } else {
            setFileName(undefined);
            setIsInvalidFile(false);
            setIsEmptyFile(false);
        }
    }

    function onTitleChange(
        event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void {
        const title: string | undefined = newValue?.trim();
        if (!title || title.length === 0) setIsInvalidTitle(true);
        else setIsInvalidTitle(false);
    }

    return (
        <div className={documentScreeningStyles.root}>
            <div className={documentScreeningStyles.body}>
                <div className={documentScreeningStyles.label}>Add Other Document</div>
                <div className={documentScreeningStyles.content}>
                    <div className={stylesForOtherDocuments.row}>
                        <div className={stylesForOtherDocuments.leftColumn}>
                            <Label required={true}>Title</Label>
                        </div>
                        <div className={stylesForOtherDocuments.rightColumn}>
                            <TextField
                                className={isInvalidTitle ? documentScreeningStyles.error : ''}
                                componentRef={titleField}
                                onChange={onTitleChange}
                            />
                        </div>
                    </div>
                    <div className={stylesForOtherDocuments.row}>
                        <div className={stylesForOtherDocuments.leftColumn}>
                            <Label>Notes</Label>
                        </div>
                        <div className={stylesForOtherDocuments.rightColumn}>
                            <TextField
                                multiline={true}
                                resizable={false}
                                componentRef={notesField}
                            />
                        </div>
                    </div>
                    <div className={stylesForOtherDocuments.row}>
                        <div className={stylesForOtherDocuments.leftColumn}>
                            <Label>Documents</Label>
                        </div>
                        <div className={stylesForOtherDocuments.rightColumn}>
                            <div className={documentScreeningStyles.uploadButton}>
                                <DefaultButton iconProps={{ iconName: 'OpenFile' }} type='file'>
                                    Choose File
                                </DefaultButton>
                                <input
                                    className={documentScreeningStyles.hiddenInput}
                                    type='file'
                                    id='fileInput'
                                    ref={fileField}
                                    onChange={onFileChange}
                                    accept={fileInputAcceptStringForTemplate}
                                />
                                <span className={documentScreeningStyles.fileName}>
                                    {fileName || 'No file chosen'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {isInvalidTitle && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        Invalid title: Title cannot be empty.
                    </MessageBar>
                )}
                {isInvalidFile && !isEmptyFile && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        Invalid file type. {acceptedFileTypesTextForTemplate}
                    </MessageBar>
                )}
                {isEmptyFile && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        Invalid file: File is empty.
                    </MessageBar>
                )}
            </div>
            <div className={documentScreeningStyles.footer}>
                <DefaultButton
                    text='Back'
                    onClick={(): void => {
                        props.handleModalClose();
                    }}
                    allowDisabledFocus
                />
                <PrimaryButton
                    onClick={addSpecificRequestableDocument}
                    text='Add to Request'
                    disabled={isInvalidTitle || isInvalidFile}
                    allowDisabledFocus
                />
            </div>
        </div>
    );

    function addSpecificRequestableDocument(): void {
        if (!titleField?.current?.value || titleField?.current?.value?.trim() === '') {
            setIsInvalidTitle(true);
        } else if (fileField && titleField && notesField) {
            const documentMetaData: IDocumentMetadata = {
                title: titleField.current?.value ?? '',
                notes: notesField.current?.value ?? '',
                documentId: '',
                screeningId: props.screeningId,
            };
            setFileName(undefined);
            props.handleModalClose(documentMetaData, fileField.current?.files[0] ?? null);
        }
    }
}
