import { contentMaxHeight } from 'assets/styles/list-styles';
import { IButtonStyles, IStackTokens, mergeStyleSets } from '@fluentui/react';

export const emptyTableWidth = '771px';

export const commonPersonnelPersonnelStyles = mergeStyleSets({
    noDataParagraph: {
        width: emptyTableWidth,
        textAlign: 'center',
        padding: '20px 0px 10px 0px',
    },
    noDataParagraphMorePadding: {
        width: emptyTableWidth,
        textAlign: 'center',
        padding: '40px 0px 20px 0px',
    },
});

export const actionButtonStyles: IButtonStyles = { root: { maxHeight: contentMaxHeight } };
export const stackChildrenGap: IStackTokens = { childrenGap: 20 };
