import {
    ActionButton,
    MessageBarType,
    ProgressIndicator,
    Stack,
    mergeStyleSets,
} from '@fluentui/react';
import { BadgeColorHex, faintBlue } from 'assets/constants/global-colors';
import { IconNames, noDataText } from 'assets/constants/global-constants';
import GroupClient, {
    IGroupPolicyViolation,
    IMyGroupMembership,
    IMyGroupMembershipRequest,
} from 'clients/group-client';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { ChartLegendDot } from 'components/common/charts/chart-legend';
import ContentCancelSubmit from 'components/common/content-cancel-submit';
import LabelAndEditButton from 'components/common/label-and-edit-button';
import { ModalSizeType } from 'components/common/modal';
import Spacer from 'components/common/spacer';
import { useCorePrincipalIdPicker } from 'components/common/use-input/use-core-employee-picker';
import { useTextField } from 'components/common/use-input/use-textfield';
import useMessageBar from 'components/common/use-message-bar';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import { MyGroupsContext } from 'components/groups/my-groups/my-groups-context';
import RemediationStepsModalActionButton from 'components/groups/my-groups/remediation-steps-modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import config from 'environments/environment';
import React, { ReactNode, useContext, useState } from 'react';
import { SetStateFunc } from 'types/global-types';
import { doNothing, doNothingAsync } from 'utils/misc-utils';
import { TimeFormats, timeToString } from 'utils/time-utils';

const featureFlags = config.groupServiceConfig.featureFlags;

interface IPolicyCheckModalActionButtonProps {
    groupId: string;
    groupName: string;
    etag: string;
    handleGroupsTableUpdate: (groupId: string) => void;
}

export default function PolicyCheckModalActionButton(
    props: IPolicyCheckModalActionButtonProps,
): JSX.Element {
    const principalUserContext = useContext(PrincipalUserContext);
    const authContext = useContext(AuthContext);
    const groupContext = useContext(MyGroupsContext);

    const [isPolicyViolationLoaded, setIsPolicyViolationLoaded] = useState<boolean>();
    const [isMemberRecordLoaded, setIsMemberRecordLoaded] = useState<boolean>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [result, setResult] = useState<IGroupPolicyViolation>();
    const [myMembershipRecord, setMyMembershipRecord] = useState<IMyGroupMembership>();
    const [isEditingSponsor, setIsEditingSponsor] = useState<boolean>(false);
    const [isEditingJustification, setIsEditingJustification] = useState<boolean>(false);

    const initializeInputs = (): void => {
        setIsEditingJustification(false);
        setIsEditingSponsor(false);
        checkPolicy()
            .then(() => getMyMembershipRecord())
            .catch((error) => console.log(error.message));
        setIsPolicyViolationLoaded(false);
        setIsMemberRecordLoaded(false);
        setErrorMessage('');
        setResult(undefined);
    };

    // First load -- on modal opening
    const onPolicyCheckClick = (): void => {
        initializeInputs();
    };

    const {
        value: sponsor,
        isValid: isSponsorValid,
        hasChanged: hasSponsorChanged,
        initialize: initSponsorElement,
        theElement: pickSponsorElement,
    } = useCorePrincipalIdPicker({
        placeHolder: 'Employee name or alias',
        required: groupContext.myGroups.find((group) => group.id === props.groupId)?.requireSponsor,
        disabled: !featureFlags.policyCheckModal,
        blockSelfSponsor: true,
        validate: (): boolean => {
            return groupContext.myGroups.find((group) => group.id === props.groupId)?.requireSponsor
                ? !!sponsor
                : true;
        },
        onError: () => {
            setUpdateErrMsg('Cannot set self as Sponsor');
        },
    });

    const {
        value: justification,
        isValid: isJustificationValid,
        hasChanged: hasJustificationChanged,
        theElement: justificationElement,
        initialize: initJustificationElement,
    } = useTextField({
        multiline: true,
        disabled: !featureFlags.policyCheckModal,
        resizable: false,
        rows: 5,
        validate: () => {
            const group = groupContext.myGroups.find((group) => group.id === props.groupId);
            return group?.requireJustification ? !!justification : true;
        },
    });

    const {
        theElement: updateErrMsgElement,
        setMessage: setUpdateErrMsg,
        clearMessage: clearUpdateErrMsg,
    } = useMessageBar({ type: MessageBarType.error });

    function resetSponsor(): void {
        setIsEditingSponsor(false);
        initSponsorElement(myMembershipRecord?.sponsorId);
    }

    function resetJustification(): void {
        setIsEditingJustification(false);
        initJustificationElement(myMembershipRecord?.justification);
    }

    const getMyMembershipRecord = async (): Promise<void> => {
        clearUpdateErrMsg();
        try {
            const result = await GroupClient.getMyGroupMembership(authContext, props.groupId);
            setMyMembershipRecord(result);
        } catch {
            setErrorMessage('Error encountered when trying to get membership record');
        } finally {
            setIsMemberRecordLoaded(true);
            initJustificationElement(myMembershipRecord?.justification);
            initSponsorElement(myMembershipRecord?.sponsorId);
        }
    };

    const checkPolicy = async (): Promise<void> => {
        try {
            const result = await GroupClient.createPolicyViolation(
                authContext,
                props.groupId,
                principalUserContext.principalRecord.id,
            );
            setResult(result);
        } catch {
            setErrorMessage('Error encountered when trying to perform policy check');
        } finally {
            setIsPolicyViolationLoaded(true);
        }
    };

    const showIsLoadingContent = (): JSX.Element => {
        return (
            <>
                <span>Performing policy check ...</span>
                <ProgressIndicator barHeight={1} />
            </>
        );
    };

    const showErrorContent = (): JSX.Element => {
        return (
            <Stack horizontal wrap className={styles.tableStack}>
                <Stack.Item className={styles.itemNameColumn}>
                    Error policy checking for&nbsp;
                    <CoreEmployeeHoverCardFromPrincipalId
                        principalId={principalUserContext.principalRecord.id}
                    />
                </Stack.Item>
                <Stack.Item className={styles.itemValueColumn}>
                    &nbsp;for group&nbsp;
                    <span className={styles.groupName}>{props.groupName}</span>
                </Stack.Item>
            </Stack>
        );
    };

    const nullOrEmptyStringCheck = (value: string | null | undefined): string => {
        return value ? value : noDataText;
    };

    const displayOneRow = (
        left: string | JSX.Element,
        right: ReactNode,
        options?: {
            verticalAlign?: 'start';
        },
    ): JSX.Element => {
        return (
            <Stack
                tokens={{ padding: 7 }}
                horizontal
                wrap
                verticalAlign={options?.verticalAlign ?? 'center'}
                className={styles.tableStack}>
                <Stack.Item className={styles.itemNameColumn}>{left}</Stack.Item>
                <Stack.Item className={styles.itemValueColumn}>{right}</Stack.Item>
            </Stack>
        );
    };

    const showDateSponsorJustificationContent = (): JSX.Element => {
        return (
            <>
                {displayOneRow(
                    'Added By',
                    <CoreEmployeeHoverCardFromPrincipalId
                        principalId={myMembershipRecord?.addedBy}
                    />,
                )}
                {displayOneRow('Added Date', dateAdded())}
                {updateErrMsgElement()}
                {displayOneRow(
                    <LabelAndEditButton
                        label='Sponsor'
                        required={
                            groupContext.myGroups.find((group) => group.id === props.groupId)
                                ?.requireSponsor
                        }
                        canEdit={true}
                        hideEditButton={isEditingSponsor}
                        onClickEdit={(): void => setIsEditingSponsor(true)}
                    />,
                    isEditingSponsor ? (
                        <ContentCancelSubmit
                            onCancelClick={resetSponsor}
                            submitText='Update'
                            enableSubmit={!!isSponsorValid && !!hasSponsorChanged}
                            onSubmitClick={(): void => {
                                updateMyMembershipRecord(
                                    Object.assign({}, myMembershipRecord, {
                                        sponsorId: sponsor,
                                    }),
                                    'sponsor',
                                    setIsEditingSponsor,
                                );
                            }}>
                            <Spacer marginTop={15} />
                            {pickSponsorElement()}
                        </ContentCancelSubmit>
                    ) : !!myMembershipRecord?.sponsorId ? (
                        <CoreEmployeeHoverCardFromPrincipalId
                            principalId={myMembershipRecord?.sponsorId}
                            onCardPrincipalProvided={doNothing}
                        />
                    ) : (
                        <span>N/A</span>
                    ),
                    isEditingSponsor ? { verticalAlign: 'start' } : undefined,
                )}
                {displayOneRow(
                    <LabelAndEditButton
                        label='Justification'
                        required={
                            groupContext.myGroups.find((group) => group.id === props.groupId)
                                ?.requireJustification
                        }
                        canEdit={true}
                        hideEditButton={isEditingJustification}
                        onClickEdit={(): void => setIsEditingJustification(true)}
                    />,
                    isEditingJustification ? (
                        <ContentCancelSubmit
                            onCancelClick={resetJustification}
                            submitText='Update'
                            enableSubmit={!!isJustificationValid && !!hasJustificationChanged}
                            onSubmitClick={(): void => {
                                updateMyMembershipRecord(
                                    Object.assign({}, myMembershipRecord, {
                                        justification: justification,
                                    }),
                                    'justification',
                                    setIsEditingJustification,
                                );
                            }}>
                            <Spacer marginTop={15} />
                            {justificationElement()}
                        </ContentCancelSubmit>
                    ) : (
                        nullOrEmptyStringCheck(justification)
                    ),
                    isEditingJustification ? { verticalAlign: 'start' } : undefined,
                )}
            </>
        );
    };

    const showLoadedContent = (): JSX.Element => {
        const inGracePeriodPolicies = !!result?.passed?.length
            ? result.passed.filter((item) => item.isInGracePeriod)
            : [];
        const compliantPolicies = !!result?.passed?.length
            ? result.passed.filter((item) => !item.isInGracePeriod)
            : [];
        return (
            <Stack>
                {displayOneRow('Group', props.groupName)}
                {displayOneRow(
                    'Employee',
                    <CoreEmployeeHoverCardFromPrincipalId
                        principalId={principalUserContext.principalRecord.id}
                    />,
                )}
                {!!result?.failed.length &&
                    displayOneRow(
                        'Violations',
                        result.failed.map((failed) => (
                            <Stack horizontal key={failed.ruleId} className={styles.item}>
                                <ChartLegendDot backgroundColor={BadgeColorHex.RED} />
                                <Stack horizontal verticalAlign='center'>
                                    <Stack.Item>{failed.ruleName}</Stack.Item>
                                    <Stack.Item>
                                        <RemediationStepsModalActionButton
                                            policyViolation={failed}
                                            groupName={props.groupName}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                        )),
                    )}
                {!!inGracePeriodPolicies?.length &&
                    displayOneRow(
                        'Grace Period',
                        inGracePeriodPolicies.map((inGrace) => (
                            <Stack
                                horizontal
                                key={inGrace.ruleId}
                                className={styles.item}
                                verticalAlign='center'>
                                <ChartLegendDot backgroundColor={BadgeColorHex.YELLOW} />
                                <Stack.Item>{inGrace.ruleName}</Stack.Item>
                                <RemediationStepsModalActionButton
                                    policyViolation={inGrace}
                                    groupName={props.groupName}
                                />
                            </Stack>
                        )),
                    )}
                {!!compliantPolicies?.length &&
                    displayOneRow(
                        'Compliant',
                        compliantPolicies.map((compliant) => (
                            <Stack
                                horizontal
                                key={compliant.ruleId}
                                className={styles.item}
                                verticalAlign='center'>
                                <ChartLegendDot backgroundColor={BadgeColorHex.GREEN} />
                                <div>{compliant.ruleName}</div>
                                {compliant?.remediationSteps && (
                                    <ActionButton
                                        text='View'
                                        className={styles.ViewButton}
                                        href={compliant?.remediationSteps}
                                        target='_blank'
                                    />
                                )}
                            </Stack>
                        )),
                    )}
                {!!result?.failed &&
                    result.failed.length === 0 &&
                    !!result.passed &&
                    result.passed.length === 0 &&
                    displayOneRow(
                        'Compliant',
                        <Stack horizontal verticalAlign='center'>
                            <ChartLegendDot backgroundColor={BadgeColorHex.GREEN} />
                            <div>All rules passed</div>
                        </Stack>,
                    )}
                {featureFlags.policyCheckModal && showDateSponsorJustificationContent()}
            </Stack>
        );
    };

    const showModalContent = (): JSX.Element => {
        if (errorMessage) {
            return showErrorContent();
        } else if (isPolicyViolationLoaded && isMemberRecordLoaded) {
            return showLoadedContent();
        } else {
            return showIsLoadingContent();
        }
    };

    const dateAdded = (): string => {
        return timeToString(
            myMembershipRecord ? myMembershipRecord.addedTimestampUTC * 1000 : '',
            TimeFormats.MMMDYYYY_hmmssA,
        );
    };

    const updateMyMembershipRecord = async (
        myMembershipRecord: IMyGroupMembershipRequest,
        fieldName: string,
        setIsEditingVariable: SetStateFunc<boolean>,
    ): Promise<void> => {
        clearUpdateErrMsg();
        if (fieldName === 'justification') {
            initJustificationElement(myMembershipRecord?.justification);
        } else {
            initSponsorElement(myMembershipRecord?.sponsorId);
        }
        setIsEditingVariable(false);

        try {
            const result = await GroupClient.updateMyGroupMembership(
                authContext,
                myMembershipRecord,
            );

            setMyMembershipRecord(result);
        } catch (e) {
            resetJustification();
            resetSponsor();
            let errMsg = '';
            if (typeof e === 'string') {
                errMsg = e;
            } else {
                errMsg = `Error updating ${fieldName}`;
            }
            setUpdateErrMsg(errMsg);
        }
    };

    return (
        <ModalActionButton<boolean | undefined>
            text='Policy Check'
            enable={true}
            iconName={IconNames.BulletedList}
            modalTitle='Policy Check'
            enableSubmit={!!isPolicyViolationLoaded && !!isMemberRecordLoaded}
            shouldHideCancelButton={true}
            submitButtonText={'Close'}
            submitButtonIcon={''}
            size={ModalSizeType.mediumLarge}
            errorMsg={errorMessage}
            onButtonClick={onPolicyCheckClick}
            onSubmit={doNothingAsync}
            onModalConcluded={(): void => props.handleGroupsTableUpdate(props.groupId)}>
            {showModalContent()}
        </ModalActionButton>
    );
}

const styles = mergeStyleSets({
    tableStack: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        lineHeight: 35,
        marginBottom: 20,
    },
    itemNameColumn: {
        width: 200,
        flexShrink: 0,
        alignItems: 'flex-start',
    },
    itemValueColumn: {
        width: 400,
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        marginBottom: 0,
    },
    groupName: {
        fontWeight: 600,
    },
    left: {
        width: '30%',
        fontWeight: 600,
    },
    right: {
        width: '70%',
    },
    item: {
        marginBottom: 10,
    },
    ViewButton: {
        color: faintBlue,
        '& :first-letter': {
            textTransform: 'capitalize',
        },
        '& :hover': {
            textDecoration: 'underline',
        },
    },
});
