import { DefaultButton, Dialog, DialogFooter, MessageBar, MessageBarType } from '@fluentui/react';
import React from 'react';

export interface IRemoveAttributeProps {
    title: string;
    message: string;
    onDismissed: () => void;
}

export function ActionNotAllowedAttributeModal(props: IRemoveAttributeProps): JSX.Element {
    return (
        <Dialog
            hidden={false}
            onDismiss={props.onDismissed}
            dialogContentProps={{
                title: props.title,
                showCloseButton: true,
            }}
            modalProps={{
                styles: {
                    main: {
                        borderRadius: '8px',
                        width: '580px !important',
                        maxWidth: 'none !important',
                        minWidth: 'none !important',
                    },
                },
            }}>
            <MessageBar messageBarType={MessageBarType.warning}>{props.message}</MessageBar>
            <DialogFooter>
                <DefaultButton onClick={props.onDismissed} text='Close' />
            </DialogFooter>
        </Dialog>
    );
}
