import React from 'react';
import { mergeStyleSets, FontWeights, Icon, Separator } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import ContainerWithEtiquettes, {
    IContainerWithEtiquettesProps,
} from 'components/common/container-with-etiquettes';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import EllipsisText from 'components/common/ellipsis-text';
import { IEmployee } from 'clients/employee-client';
import { toTitleCase } from 'utils/string-utils';
import {
    getJobTitleOrDefault,
    getEmployeeTypeLabel,
} from 'components/common/employee/internal-employee-utils';

export interface EmployeeHrDataProps {
    employee: IEmployee;
    manager: string;
    managerAlias: string;
    usCitizenship: boolean;
    cloudScreen: boolean;
    cjis: boolean;
}

//TODO: Find a way to render this component without a fix width on the first column
export function HrData(props: EmployeeHrDataProps): JSX.Element {
    const containerProps: IContainerWithEtiquettesProps = {
        leftEtiquetteLabel: 'HR Data',
        rightEtiquette: getEmployeeTypeLabel(props.employee),
    };

    return (
        <ContainerWithEtiquettes {...containerProps}>
            {props.employee && (
                <div className={styles.hrData}>
                    <div className={styles.dataGroup}>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>First Name</div>
                            <div>{props.employee.firstName}</div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Middle Name</div>
                            <div>{props.employee.middleName ? props.employee.middleName : ''}</div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Last Name</div>
                            <div>{props.employee.lastName}</div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Manager</div>
                            <div>
                                <EmployeeHoverCard personnelAlias={props.managerAlias}>
                                    <span className={styles.manager}>{props.manager}</span>{' '}
                                    <span>({props.managerAlias?.toLowerCase() || ''})</span>
                                </EmployeeHoverCard>
                            </div>
                        </div>
                    </div>
                    <Separator styles={separatorStyles} />
                    <div className={styles.dataGroup}>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Microsoft Id</div>
                            <div>{props.employee.id}</div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Company Code</div>
                            <div>{props.employee.companyCode}</div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Cost Center</div>
                            <div>
                                <span className={styles.costCenter}>
                                    <EllipsisText
                                        text={`${props.employee.costCenterCode} - ${props.employee.department}`}
                                        textLengthBeforeEllipsis={25}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>PCN</div>
                            <div>{props.employee.positionNumber}</div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Start Date</div>
                            <div>{new Date(props.employee.startDate).toDateString()}</div>
                        </div>
                    </div>
                    <Separator styles={separatorStyles} />
                    <div className={styles.dataGroup}>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Profession</div>
                            <div>{props.employee.profession}</div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Discipline</div>
                            <div>{props.employee.discipline}</div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Title</div>
                            <div className={styles.title}>
                                {getJobTitleOrDefault(props.employee).toUpperCase()}
                            </div>
                        </div>
                    </div>
                    <Separator styles={separatorStyles} />
                    <div className={styles.dataGroup}>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>City</div>
                            <div>{toTitleCase(props.employee.locationAreaCity)}</div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>State</div>
                            <div>{props.employee.locationAreaDetail}</div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Country</div>
                            <div>{`${props.employee.country} (${props.employee.locationAreaCode})`}</div>
                        </div>
                    </div>
                    <Separator styles={separatorStyles} />
                    <div className={styles.dataGroup}>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>US Citizenship</div>
                            <div className={styles.multivalue}>
                                <div>{displayCheckBox(props.usCitizenship)}</div>
                                <div>Verified</div>
                            </div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Cloud Screen</div>
                            <div className={styles.multivalue}>
                                <div>{displayCheckBox(props.cloudScreen)}</div>
                                <div>Valid</div>
                            </div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>CJIS</div>
                            <div className={styles.multivalue}>
                                <div>{displayCheckBox(props.cjis)}</div>
                                <div>Available</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ContainerWithEtiquettes>
    );
}

/**
 * @param {boolean} checked
 */
function displayCheckBox(checked: boolean): JSX.Element {
    const checkboxStyles = {
        root: {
            fontSize: 18,
            color: checked === true ? SharedColors.cyanBlue10 : undefined,
        },
    };
    return checked === true ? (
        <Icon styles={checkboxStyles} iconName='CheckboxCompositeReversed' />
    ) : (
        <Icon styles={checkboxStyles} iconName='Checkbox' />
    );
}

const separatorStyles = {
    root: {
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                opacity: 0.7,
            },
        },
    },
};

const styles = mergeStyleSets({
    hrData: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 5,
        paddingTop: 5,
    },
    dataLine: {
        display: 'flex',
        alignItems: 'center',
        selectors: {
            '&:not(:last-child):not(:first-child)': {
                marginTop: 7,
                marginBottom: 7,
            },
        },
    },
    key: {
        width: '120px',
        fontWeight: FontWeights.semibold,
    },
    dataGroup: {
        paddingTop: 2,
        paddingBottom: 2,
    },
    multivalue: {
        display: 'flex',
        alignItems: 'center',
        selectors: {
            '& div:nth-child(2)': {
                marginLeft: 10,
            },
        },
    },
    manager: {
        fontWeight: FontWeights.semibold,
    },
    title: {
        width: '140px',
    },
    costCenter: {
        whiteSpace: 'pre',
    },
});
export default HrData;
