import GroupClient from 'clients/group-client';
import { AuthContext } from 'contexts/auth-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import environment from 'environments/environment';
import { useContext, useEffect, useState } from 'react';

type UseHasMyOrgType = {
    hasMyOrgAccess: boolean;
    hasMyOrgAccessIsReady: boolean;
};

export default function useHasMyOrgAccess(): UseHasMyOrgType {
    const authContext = useContext(AuthContext);
    const principalUserContext = useContext(PrincipalUserContext);

    const [hasMyOrgAccessIsReady, setHasMyOrgAccessIsReady] = useState(false);
    const [hasMyOrgAccess, setHasMyOrgAccess] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        async function validateUserGroupForMyOrgAccess(): Promise<void> {
            try {
                const hasNstOpSecGroup = await GroupClient.checkMembership(
                    authContext,
                    environment.privilegedGroupId,
                    principalUserContext.principalRecord.id,
                );
                setHasMyOrgAccess(hasNstOpSecGroup);
                setHasMyOrgAccessIsReady(true);
            } catch (e) {
                console.error('Error determining whether or not user can see My Org', e);
            }
        }

        validateUserGroupForMyOrgAccess();
    }, [authContext, principalUserContext.principalRecord]);

    return {
        hasMyOrgAccess,
        hasMyOrgAccessIsReady,
    };
}
