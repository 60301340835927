import React from 'react';
import { globalStyles } from 'assets/styles/global-styles';
import { mergeStyles } from '@fluentui/react';

export function AccessDeniedPage(): JSX.Element {
    return (
        <div>
            <div className={textStyle}>
                You don&apos;t have the required permissions to access this page
            </div>
        </div>
    );
}

const textStyle = mergeStyles(globalStyles.veryLargeFont, {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '100px',
    textAlign: 'center',
});
