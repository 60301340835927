import React, { useState, useContext, useEffect } from 'react';
import CheckRole from 'components/common/check-role';
import { Role } from 'configs/roles';
import { AccessDeniedURL, Dictionary } from 'assets/constants/global-constants';
import { useParams, Redirect } from 'react-router-dom';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import EligibilityClient, {
    IAttribute,
    IEligibility,
    IEligibilityAuditLog,
    IEligibilityRequestAuditLog,
} from 'clients/eligibility-client';
import { AuthContext } from 'contexts/auth-context';
import { ProblemLoadingPage } from 'components/common/problem-loading/problem-loading-page';
import {
    eligViewEligibilityBreadcrumbs,
    viewEligibilityUrl,
} from 'components/eligibilities/eligibilities-breadcrumbs';
import { useFetch, useIsMounted } from 'utils/misc-hooks';
import {
    ViewEligibilityValidTabs,
    ModalMode,
} from 'components/eligibilities/eligibilities-constants';
import Tabs, { TabbedContent } from 'components/common/tabs';
import DisplayEligibilityDetails from 'components/eligibilities/eligibilities/view/display-eligibility-details';
import DisplayEligibilityRequests from 'components/eligibilities/eligibilities/view/display-eligibility-requests';
import DisplayEligibilityTimeline from 'components/eligibilities/eligibilities/view/audit-log/display-eligibility-audit-log';
import { Location } from 'history';
import {
    useAttributes,
    genAttributesDictionary,
} from 'components/eligibilities/eligibilities-utils';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import PageTitle from 'components/common/page-title';
import { mergeStyleSets } from '@fluentui/react';
import { IPagedItems } from 'clients/http-options';
import { EligibilitiesFilterContext } from 'components/eligibilities/eligibilities/contexts/eligibilities-filter-context';
import { DAY_IN_MILLISECONDS } from 'utils/time-utils';

interface IViewEligibilityProps {
    location: Location;
}

const emptyPagedItems = () => ({
    items: [],
    continuationToken: undefined,
});

export default function ViewEligibility(props: IViewEligibilityProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const filterContext = useContext(EligibilitiesFilterContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const isMounted = useIsMounted();

    const { attributes } = useAttributes(authContext);

    const [redirectTo, setRedirectTo] = useState<string>();
    const [eligibility, setEligibility] = useState<IEligibility>();
    const [attributesDict, setAttributesDict] = useState<Dictionary<IAttribute>>({});

    // Variables related to Audit Log
    const [eventsLog, setEventsLog] = useState<IPagedItems<IEligibilityAuditLog>>(
        emptyPagedItems(),
    );
    const [requestsLog, setRequestsLog] = useState<IPagedItems<IEligibilityRequestAuditLog>>(
        emptyPagedItems(),
    );
    const [shouldLoad, setShouldLoad] = useState<boolean>(true);
    const [isMoreLogRecords, setIsMoreLogRecords] = useState<boolean>(true);
    const [isLoadingAuditLog, setIsLoadingAuditLog] = useState<boolean>(false);
    const [hasErrorLoadingLog, setHasErrorLoadingLog] = useState<boolean>(false);
    // END Variables related to Audit Log

    const { id, tab } = useParams<{ id?: string; tab?: string }>();

    const {
        item: eligibilityInit,
        problemFetching: problemLoadingPage,
        isFetching: isInitialLoad,
    } = useFetch<IEligibility>({
        fetchFunc: (): Promise<IEligibility> | undefined => {
            if (id) return EligibilityClient.getEligibilityById(authContext, id);
            else return undefined;
        },
    });

    useEffect(() => {
        if (eligibilityInit) setEligibility(eligibilityInit);
    }, [eligibilityInit]);

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs(eligViewEligibilityBreadcrumbs(eligibility));
    }, [eligibility, tab]);

    useEffect(() => {
        if (attributes.length > 0) setAttributesDict(genAttributesDictionary(attributes));
    }, [attributes]);

    const onTabChange = (itemKey?: string): void => {
        if (!eligibility?.id) return;
        switch (itemKey) {
            case ViewEligibilityValidTabs.Details:
            case ViewEligibilityValidTabs.Requests:
            case ViewEligibilityValidTabs.Timeline:
                setRedirectTo(viewEligibilityUrl(eligibility, itemKey));
                break;
            default:
                setRedirectTo(viewEligibilityUrl(eligibility, ViewEligibilityValidTabs.Details));
                break;
        }
    };

    const onAddEditEligibilityConcluded = (
        mode: ModalMode,
        conclusion: ModalConclusion,
        result?: IEligibility,
    ): void => {
        if (mode === ModalMode.Update && conclusion === ModalConclusion.Done && result)
            setEligibility(result);
    };

    /** **********************************************************************
     *  Fetch "eligibility event" log and "eligibility request" log
     */

    // Related Card: https://msazure.visualstudio.com/Microsoft%20Personnel/_boards/board/t/Personnel/Stories/?workitem=10723238
    // Continuation token may show non-empty but the list of items received
    // from backend may be empty. That doesn't indicate end of data. Rather,
    // it only indicates that while serving the last fetch, the backend filtered
    // out the data, and as a result sent an empty list of items. Only if
    // the backend sends an empty string or null we will know there's no more
    // data left.
    const isThereMore = (continuationToken: string | undefined): boolean =>
        continuationToken === undefined ||
        (continuationToken !== '' && continuationToken !== 'null');

    useEffect(() => {
        const fetchLogs = async (): Promise<void> => {
            if (!eligibility?.id || !isMoreLogRecords || !shouldLoad) return;

            try {
                setIsLoadingAuditLog(true);
                const startDate = filterContext.fromDate?.getTime()
                    ? filterContext.fromDate?.getTime() / 1000
                    : undefined;
                const endDate = filterContext.toDate?.getTime()
                    ? (filterContext.toDate?.getTime() + DAY_IN_MILLISECONDS) / 1000
                    : undefined;

                const [eventsLogVar, requestLogsVar] = await Promise.all([
                    EligibilityClient.getEligibilityAuditLog(
                        authContext,
                        { eligibilityId: eligibility.id, startDate, endDate },
                        eventsLog.continuationToken,
                    ),
                    EligibilityClient.getEligibilityRequestAuditLog(
                        authContext,
                        { eligibilityId: eligibility.id, startDate, endDate },
                        requestsLog.continuationToken,
                    ),
                ]);
                if (isMounted()) {
                    setEventsLog((eventsLog) => {
                        return {
                            items: eventsLog.items.concat(eventsLogVar.items),
                            continuationToken: eventsLogVar.continuationToken,
                        };
                    });
                    setRequestsLog((requestsLog) => {
                        return {
                            items: requestsLog.items.concat(requestLogsVar.items),
                            continuationToken: requestLogsVar.continuationToken,
                        };
                    });
                    setIsMoreLogRecords(
                        isThereMore(eventsLogVar.continuationToken) ||
                            isThereMore(requestLogsVar.continuationToken),
                    );
                }
            } catch (e) {
                console.error('Unable to fetch eligibility audit log or request log');
                if (isMounted()) {
                    setHasErrorLoadingLog(true);
                }
            } finally {
                if (isMounted()) {
                    setShouldLoad(false);
                    setIsLoadingAuditLog(false);
                }
            }
        };

        fetchLogs();
    }, [eligibility, isMoreLogRecords, shouldLoad, filterContext]);
    /*
     * END Fetch
     **************************************************************/

    const onClickApplyFilters = (): void => {
        setShouldLoad(true);
        setIsMoreLogRecords(true);
        setEventsLog(emptyPagedItems());
        setRequestsLog(emptyPagedItems());
    };

    const scrollEventHandler = (): void => {
        if (shouldLoad || isLoadingAuditLog || !isMoreLogRecords) {
            return;
        }
        setShouldLoad(true);
    };

    useEffect(() => {
        if (redirectTo) {
            setRedirectTo('');
        }
    }, [redirectTo]);

    if (redirectTo) {
        return <Redirect push={true} to={redirectTo} />;
    } else {
        return (
            <CheckRole
                requiredRolesAny={[Role.EligibilitiesRead]}
                redirectNotInRole={AccessDeniedURL}>
                <PageLoadingSpinner
                    label='Loading eligibily...'
                    ariaLive='assertive'
                    isLoading={isInitialLoad}
                    labelPosition='left'>
                    <ProblemLoadingPage isProblemLoadingPage={!!problemLoadingPage}>
                        <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                        <div className={styles.mainContainer}>
                            <div className={styles.pageTitle}>
                                <PageTitle>
                                    {eligibility?.eligibilityCode}&nbsp;(
                                    {eligibility?.eligibilityName})
                                </PageTitle>
                            </div>
                            <Tabs selectedKey={tab} onChange={onTabChange}>
                                <TabbedContent
                                    key='Details'
                                    tabHeader='Details'
                                    itemKey={ViewEligibilityValidTabs.Details}>
                                    <DisplayEligibilityDetails
                                        location={props.location}
                                        attributes={attributes}
                                        eligibility={eligibility}
                                        attributesDict={attributesDict}
                                        onAddEditEligibilityConcluded={
                                            onAddEditEligibilityConcluded
                                        }
                                    />
                                </TabbedContent>
                                <TabbedContent
                                    key='Requests'
                                    tabHeader='Requests'
                                    itemKey={ViewEligibilityValidTabs.Requests}>
                                    <DisplayEligibilityRequests
                                        location={props.location}
                                        eligibility={eligibility}
                                        attributesDict={attributesDict}
                                    />
                                </TabbedContent>
                                <TabbedContent
                                    key='Timeline'
                                    tabHeader='Timeline'
                                    itemKey={ViewEligibilityValidTabs.Timeline}>
                                    <DisplayEligibilityTimeline
                                        location={props.location}
                                        eligibility={eligibility}
                                        eventsLog={eventsLog.items}
                                        requestsLog={requestsLog.items}
                                        isLoading={isLoadingAuditLog}
                                        isInitialLoad={isInitialLoad}
                                        hasErrorLoading={hasErrorLoadingLog}
                                        isMoreLogRecords={isMoreLogRecords}
                                        onClickApplyFilters={onClickApplyFilters}
                                        scrollEventHandler={scrollEventHandler}
                                    />
                                </TabbedContent>
                            </Tabs>
                        </div>
                    </ProblemLoadingPage>
                </PageLoadingSpinner>
            </CheckRole>
        );
    }
}

const styles = mergeStyleSets({
    pageTitle: {
        paddingLeft: 8,
        margingTop: 10,
    },
    mainContainer: [{ marginBottom: 100 }],
});
