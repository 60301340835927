import React from 'react';
import { TableCell } from 'components/common/table';
import { FontIcon, IColumn, mergeStyleSets, PrimaryButton, Stack } from '@fluentui/react';
import { IconNames, noDataText, xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { TimeFormats } from 'utils/time-utils';
import { ISeatsTableRow } from 'components/facilities/facilities-kiosk/facilities-kiosk-page';
import { handleUTCToFacilityTimeZone } from 'components/facilities/common/facility-time-utils';
import {
    IFacilityRecord,
    ISeatAvailabilityCalendarResponse,
    ReservationState,
} from 'clients/facilities-client';
import Spacer from 'components/common/spacer';
import CancelReservationModalActionButton from 'components/facilities/facilities-reservations/modals/facilities-reservation-cancel-modal-action-button';
import { ITimeAvailability } from 'components/facilities/facilities-page';
import { IReservationPromiseInstruction } from 'components/facilities/facilities-reservations/modals/facilities-reservation-instruction-utils';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';

export enum FacilitiesKioskPageTableColumnKeys {
    TimeSlot = 'Time Slot',
    State = 'State',
    SeatName = 'Seat Name',
    Actions = 'Actions',
}

interface IManageGroupTableColumns {
    facility?: IFacilityRecord;
    isArriving?: boolean;
    isSubmitted: boolean;
    iSeatAvailabilityCalendarResponses: ISeatAvailabilityCalendarResponse[];
    sortColumnHandler: (newColumnName: string) => void;
    sortColumn: string;
    goToMap: (iTimeAvailability: ITimeAvailability, isViewOnly: boolean) => void;
    onMapFacilitySeatStatus: (updates: IReservationPromiseInstruction[]) => void;
}

function facilitiesKioskPageTableColumns(props: IManageGroupTableColumns): IColumn[] {
    const columnWidths = {
        timeSlot: 200,
        seatName: 110,
        state: 110,
        actions: 220,
    };
    const styles = mergeStyleSets({
        timeSlotWrapper: {
            position: 'relative',
        },
        checkmarkWrapper: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '72px',
        },
        checkmark: {
            color: 'green',
            fontSize: 22,
            paddingRight: '5px',
        },
    });

    const columns: IColumn[] = [
        {
            key: FacilitiesKioskPageTableColumnKeys.TimeSlot,
            name: FacilitiesKioskPageTableColumnKeys.TimeSlot,
            ariaLabel: FacilitiesKioskPageTableColumnKeys.TimeSlot,
            minWidth: columnWidths.timeSlot,
            maxWidth: columnWidths.timeSlot * xLargeMaxWidthCoeff,

            onRender: (row: ISeatsTableRow): JSX.Element => (
                <TableCell>
                    <div className={styles.timeSlotWrapper}>
                        {`${handleUTCToFacilityTimeZone(
                            row.timeslotItem.startDateTimeUTCMilliseconds,
                            props.facility,
                            TimeFormats.H_mmA,
                            noDataText,
                        )} - ${handleUTCToFacilityTimeZone(
                            row.timeslotItem.endDateTimeUTCMilliseconds,
                            props.facility,
                            TimeFormats.H_mmA,
                            noDataText,
                        )}`}
                    </div>
                </TableCell>
            ),
        },
        {
            key: FacilitiesKioskPageTableColumnKeys.SeatName,
            name: FacilitiesKioskPageTableColumnKeys.SeatName,
            ariaLabel: FacilitiesKioskPageTableColumnKeys.SeatName,
            minWidth: columnWidths.seatName,
            maxWidth: columnWidths.seatName * xLargeMaxWidthCoeff,

            onRender: (row: ISeatsTableRow): JSX.Element => (
                <TableCell>{row.seat?.seatName && <>Seat {row.seat.seatName}</>}</TableCell>
            ),
        },
        {
            key: FacilitiesKioskPageTableColumnKeys.State,
            name: FacilitiesKioskPageTableColumnKeys.State,
            ariaLabel: FacilitiesKioskPageTableColumnKeys.State,
            minWidth: columnWidths.state,
            maxWidth: columnWidths.state * xLargeMaxWidthCoeff,

            onRender: (row: ISeatsTableRow): JSX.Element => (
                <TableCell>
                    {row.reservation?.state === ReservationState.Confirmed && (
                        <div className={styles.checkmarkWrapper}>
                            <FontIcon
                                className={styles.checkmark}
                                iconName={IconNames.SkypeCheck}
                            />
                            <span>Confirmed</span>
                        </div>
                    )}
                    {row.reservation?.state === ReservationState.CheckedIn && (
                        <div className={styles.checkmarkWrapper}>
                            <FontIcon
                                className={styles.checkmark}
                                iconName={IconNames.SkypeCircleCheck}
                            />
                            <span>Checked In</span>
                        </div>
                    )}
                </TableCell>
            ),
        },
        {
            key: FacilitiesKioskPageTableColumnKeys.Actions,
            name: FacilitiesKioskPageTableColumnKeys.Actions,
            ariaLabel: FacilitiesKioskPageTableColumnKeys.Actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions * xLargeMaxWidthCoeff,

            onRender: (row: ISeatsTableRow): JSX.Element => (
                <TableCell>
                    <Stack horizontal>
                        {row.reservation && row.seat ? (
                            <>
                                <PrimaryButton
                                    text={
                                        row.reservation.state === ReservationState.CheckedIn
                                            ? 'View'
                                            : 'Change'
                                    } // you cannot change a checked in reservation
                                    onClick={(): void =>
                                        props.goToMap(
                                            {
                                                startTime:
                                                    row.timeslotItem.startDateTimeUTCMilliseconds,
                                                endTime:
                                                    row.timeslotItem.endDateTimeUTCMilliseconds,

                                                facilityId: props.facility?.id ?? '',
                                                calendarResponses: new Promise((resolve) => {
                                                    resolve(
                                                        props.iSeatAvailabilityCalendarResponses,
                                                    );
                                                }),
                                            },
                                            row.reservation?.state === ReservationState.CheckedIn,
                                        )
                                    }
                                    disabled={props.isSubmitted}
                                />
                                <Spacer marginLeft={10} />
                                {row.reservation.state !== ReservationState.CheckedIn && ( // you cannot cancel a checked in reservation
                                    <CancelReservationModalActionButton
                                        reservationInstructions={{
                                            facility: props.facility!,
                                            cancel: [
                                                {
                                                    facility: props.facility!,
                                                    timeslot: {
                                                        seatStatus: {
                                                            seatId: row.reservation.seatId,
                                                        },
                                                        timeslotItem: {
                                                            startDateTimeUTCMilliseconds:
                                                                row.reservation.reservationTimeSlot
                                                                    .startDateTimeUTCMilliseconds,
                                                            endDateTimeUTCMilliseconds:
                                                                row.reservation.reservationTimeSlot
                                                                    .endDateTimeUTCMilliseconds,
                                                        },
                                                    },
                                                },
                                            ],
                                        }}
                                        onModalConcluded={(modalConclusion, updates): void => {
                                            if (modalConclusion === ModalConclusion.Done) {
                                                props.onMapFacilitySeatStatus(updates);
                                            }
                                        }}
                                        buttonType='default'
                                        buttonText='Release'
                                        iconType={''}
                                        buttonDisabled={props.isSubmitted}
                                        iseatRecords={[
                                            {
                                                id: row.seat.id,
                                                seatName: row.seat.seatName,
                                            },
                                        ]}
                                    />
                                )}
                            </>
                        ) : (
                            <PrimaryButton
                                text='Reserve'
                                disabled={props.isSubmitted}
                                onClick={(): void =>
                                    props.goToMap(
                                        {
                                            startTime:
                                                row.timeslotItem.startDateTimeUTCMilliseconds,
                                            endTime: row.timeslotItem.endDateTimeUTCMilliseconds,
                                            facilityId: props.facility?.id ?? '',
                                            calendarResponses: new Promise((resolve) => {
                                                resolve(props.iSeatAvailabilityCalendarResponses);
                                            }),
                                        },
                                        row.reservation?.state === ReservationState.CheckedIn,
                                    )
                                }
                            />
                        )}
                    </Stack>
                </TableCell>
            ),
        },
    ];
    return columns;
}
export default facilitiesKioskPageTableColumns;
