import { IColumn, Stack } from '@fluentui/react';
import { xxxLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import {
    CompliantStatus,
    GroupRole,
    IGroup,
    IGroupMembership,
    IUarEvaluation,
    UserAccessReviewApproverType,
} from 'clients/group-client';
import Badge from 'components/common/badge';
import { TableCell } from 'components/common/table';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import ManageGroupMemberDeleteModalActionButton from 'components/groups/common/manage-group-member-delete-modal-action-button';
import ManageGroupMemberDetailsModalActionButton from 'components/groups/common/manage-group-member-details-modal-action-button';
import {
    compliantStatuses,
    getCompliantColor,
    getCompliantStatus,
} from 'components/groups/groups-utils';
import ApproveGroupMemberModalActionButton from 'components/groups/manage-group/actions/buttons/approve-group-member-modal-action-button';
import config from 'environments/environment';
import React from 'react';
import { getDaysSinceDate } from 'utils/time-utils';

const featureFlags = config.groupServiceConfig.featureFlags;

interface IUserAccessTableColumns {
    groupOwners: IGroupMembership[];
    enableOwnerDemote: boolean;
    onGroupMemberDeleted: (personnelId: string, ruleId?: string) => void;
    onDeleteMemberFromUARTable: (personnelId: string, ruleId?: string) => void;
    userAccessReviews: IGroupMembership[];
    canDeleteOrApprove: boolean;
    group: IGroup;
}

enum TableColumnNamesUar {
    name = 'Name',
    role = 'Role',
    status = 'Status',
    approverType = 'Approver',
    overdueDays = 'Overdue (days)',
    actions = 'Actions',
}

export function tableColumnsUserAccessReview(props: IUserAccessTableColumns): IColumn[] {
    const columnWidths = {
        name: 90,
        role: 50,
        status: 80,
        approverType: 50,
        overdueDays: 50,
        actions: props.canDeleteOrApprove ? 250 : 80,
    };

    const columnMaxWidths = {
        name: columnWidths.name * xxxLargeMaxWidthCoeff,
        role: 65,
        status: columnWidths.status * xxxLargeMaxWidthCoeff,
        approverType: 65,
        overdueDays: undefined,
        actions: columnWidths.actions,
    };

    const getMember = (row: IUarEvaluation): IGroupMembership | undefined => {
        return props.userAccessReviews.find((x) => x.personnelId === row.personnelId);
    };

    const getApproverType = (row: IUarEvaluation): UserAccessReviewApproverType | undefined => {
        const enumVals = Object.values(UserAccessReviewApproverType);
        return typeof row.metadata?.approverType === 'number'
            ? enumVals[row.metadata.approverType]
            : row.metadata?.approverType;
    };

    const getPolicyDaysOver = (row: IUarEvaluation): number | undefined => {
        if (row.metadata?.daysOver > 0) {
            return row.metadata.daysOver;
        } else {
            return getDaysSinceDate(row.firstFailTimeStampUTC);
        }
    };

    const isOwner = (personnelId: string): boolean =>
        !!props.groupOwners.find(
            (o) => o.personnelId === personnelId && o.role === GroupRole.OWNER,
        );

    return [
        {
            key: TableColumnNamesUar.name,
            name: TableColumnNamesUar.name,
            ariaLabel: TableColumnNamesUar.name,
            minWidth: columnWidths.name,
            maxWidth: columnMaxWidths.name,

            onRender: (row: IUarEvaluation): JSX.Element => (
                <TableCell key={`${row.personnelId}-${row.ruleId}-${TableColumnNamesUar.name}`}>
                    <CoreEmployeeHoverCardFromPrincipalId principalId={row.personnelId} />
                </TableCell>
            ),
        },
        {
            key: TableColumnNamesUar.role,
            name: TableColumnNamesUar.role,
            ariaLabel: TableColumnNamesUar.role,
            minWidth: columnWidths.role,
            maxWidth: columnMaxWidths.role,

            onRender: (row: IUarEvaluation): JSX.Element => (
                <TableCell key={`${row.personnelId}-${row.ruleId}-${TableColumnNamesUar.status}`}>
                    <Stack>{getMember(row)?.role}</Stack>
                </TableCell>
            ),
        },
        {
            key: TableColumnNamesUar.status,
            name: TableColumnNamesUar.status,
            ariaLabel: TableColumnNamesUar.status,
            minWidth: columnWidths.status,
            maxWidth: columnMaxWidths.status,

            onRender: (row: IUarEvaluation): JSX.Element => {
                const isCompliant = getMember(row)?.compliant;
                const status = getCompliantStatus(!!isCompliant, false, false);
                const isInGracePeriod = row.isInGracePeriod;

                return (
                    <TableCell
                        key={`${row.personnelId}-${row.ruleId}-${TableColumnNamesUar.status}`}>
                        <Stack horizontal>
                            <Badge
                                text={status}
                                backgroundColor={getCompliantColor(!!isCompliant, false, false)}
                                marginRight='10px'
                            />
                            {featureFlags.allowApproveUnsigned && isInGracePeriod && (
                                <Badge
                                    text={CompliantStatus.GRACE_PERIOD}
                                    marginRight='10px'
                                    backgroundColor={compliantStatuses.onGracePeriod.color}
                                />
                            )}
                        </Stack>
                    </TableCell>
                );
            },
        },
        {
            key: TableColumnNamesUar.approverType,
            name: TableColumnNamesUar.approverType,
            ariaLabel: TableColumnNamesUar.approverType,
            minWidth: columnWidths.role,
            maxWidth: columnMaxWidths.role,

            onRender: (row: IUarEvaluation): JSX.Element => (
                <TableCell key={`${row.personnelId}-${row.ruleId}-${TableColumnNamesUar.status}`}>
                    <Stack>{getApproverType(row)}</Stack>
                </TableCell>
            ),
        },
        {
            // TODO: sort overdue days
            key: TableColumnNamesUar.overdueDays,
            name: TableColumnNamesUar.overdueDays,
            ariaLabel: TableColumnNamesUar.overdueDays,
            minWidth: columnWidths.overdueDays,
            maxWidth: columnMaxWidths.overdueDays,
            onRender: (row: IUarEvaluation): JSX.Element => {
                return <TableCell>{<Stack>{getPolicyDaysOver(row)}</Stack>}</TableCell>;
            },
        },
        {
            key: TableColumnNamesUar.actions,
            name: TableColumnNamesUar.actions,
            ariaLabel: TableColumnNamesUar.actions,
            minWidth: columnWidths.actions,
            maxWidth: columnMaxWidths.actions,

            onRender: (row: IUarEvaluation): JSX.Element => {
                const member = getMember(row);
                return (
                    <TableCell
                        key={`${row.personnelId}-${row.ruleId}-${TableColumnNamesUar.actions}`}>
                        <Stack horizontal>
                            {member && (
                                <>
                                    <ManageGroupMemberDetailsModalActionButton
                                        memberDetails={member}
                                    />
                                    {props.canDeleteOrApprove && (
                                        <ApproveGroupMemberModalActionButton
                                            memberDetails={member}
                                            principalId={row.personnelId}
                                            policies={row}
                                            groupId={props.group.id}
                                            onDeleteMemberFromTable={
                                                props.onDeleteMemberFromUARTable
                                            }
                                        />
                                    )}
                                    {props.canDeleteOrApprove && (
                                        <ManageGroupMemberDeleteModalActionButton
                                            enable={
                                                !isOwner(row.personnelId) || props.enableOwnerDemote
                                            }
                                            memberDetails={member}
                                            personnelId={row.personnelId}
                                            onGroupMemberDeleted={(
                                                personnelId: string,
                                                ruleId?: string,
                                            ): void => {
                                                props.onGroupMemberDeleted(personnelId, ruleId);
                                                props.onDeleteMemberFromUARTable(
                                                    personnelId,
                                                    ruleId,
                                                );
                                            }}
                                        />
                                    )}
                                </>
                            )}
                        </Stack>
                    </TableCell>
                );
            },
        },
    ];
}
export default tableColumnsUserAccessReview;
