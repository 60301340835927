import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    IColumn,
    IDetailsListProps,
    mergeStyles,
    Selection,
    SelectionMode,
    ShimmeredDetailsList,
} from '@fluentui/react';

const DEFAULT_NO_DATA_TEXT = 'No data available';

export interface IFilter {
    values: string[];
}

export interface IFilterableColumn extends IColumn {
    isFilterable?: boolean;
    filterFunction?: (item: any, filter: IFilter) => boolean;
    isHidden?: boolean;
}

export interface IFilterableTableProps extends IDetailsListProps {
    isLoading?: boolean;
    noDataText?: string;
    columns: IFilterableColumn[];
    filters: Map<string, IFilter>;
    onFilteredItemsChange?: (filteredItems: any[]) => void;
    onSelectionChange?: (selectedItems: any[]) => void;
    onColumnHeaderClick?: (
        ev?: React.MouseEvent<HTMLElement, MouseEvent>,
        column?: IFilterableColumn,
    ) => void;
    selectionMode?: SelectionMode;
}

export function FilterableTable(props: IFilterableTableProps): JSX.Element {
    const {
        isLoading,
        columns,
        items,
        filters,
        onFilteredItemsChange,
        onSelectionChange,
        ...otherProps
    } = props;

    const [cols, setCols] = useState<IFilterableColumn[]>(columns);

    // Including the selectionMode check for cases where the table rows are not selectable
    useEffect(() => {
        if (columns && props.selectionMode === SelectionMode.none) {
            setCols(columns);
        }
    }, [columns, props.selectionMode]);

    const onColumnHeaderClick = useCallback(
        (ev?: React.MouseEvent<HTMLElement, MouseEvent>, column?: IFilterableColumn) => {
            if (!column) {
                return;
            }
            const columnCopy: IFilterableColumn[] = columns.slice();
            const clickedColumn: IFilterableColumn = columnCopy.filter(
                (currCol) => column.key === currCol.key,
            )[0];

            const isAllSame = items.every(
                (item) => item[clickedColumn.fieldName!] === items[0][clickedColumn.fieldName!],
            );
            if (!isAllSame) {
                // if all values of the column are not the same, sort the column
                columnCopy.forEach((col: IFilterableColumn) => {
                    if (col === clickedColumn) {
                        clickedColumn.isSortedDescending = !clickedColumn.isSortedDescending;
                        clickedColumn.isSorted = true;
                    } else {
                        col.isSortedDescending = true;
                        col.isSorted = false;
                    }
                });
            }

            setCols(columnCopy);
        },
        [columns],
    );

    const filterableColumns = useMemo(() => cols.filter((col) => col.isFilterable), [cols]);
    const selection: Selection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                onSelectionChange && onSelectionChange(selection.getSelection());
            },
        });
    }, []);

    // Do sorting
    const unfilteredItems = useMemo(() => {
        const sortColumn = cols.find((x) => x.isSorted);
        if (sortColumn) {
            return copyAndSort(items, sortColumn.fieldName!, sortColumn.isSortedDescending);
        }
        return items;
    }, [items, cols]);

    // Do filtering
    const filteredItems: any[] = useMemo(() => {
        const itemFilter = (item: any): boolean => {
            let shouldInclude = true;
            filters.forEach((filter: IFilter, columnName: string) => {
                if (!filter) {
                    return;
                }
                const column = filterableColumns.find((x) => x.key === columnName);
                if (column === undefined) {
                    // The column is not marked as filterable
                    return;
                }

                if (column.filterFunction && shouldInclude) {
                    shouldInclude = column.filterFunction(item, filter);
                }
            });

            return shouldInclude;
        };

        const filteredItems = unfilteredItems && unfilteredItems.filter(itemFilter);

        if (onFilteredItemsChange) {
            onFilteredItemsChange(filteredItems);
        }

        return filteredItems;
    }, [unfilteredItems, filters, filterableColumns]);

    return isLoading === false && items && items.length === 0 ? (
        <div className={emptyTableStyles}>
            <span>{props.noDataText ?? DEFAULT_NO_DATA_TEXT}</span>
        </div>
    ) : (
        <ShimmeredDetailsList
            enableShimmer={isLoading}
            columns={cols.filter((c) => !c.isHidden)}
            items={filteredItems}
            onColumnHeaderClick={props.onColumnHeaderClick ?? onColumnHeaderClick}
            selection={selection}
            {...otherProps}
        />
    );
}

function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;

    return items.slice(0).sort((a: T, b: T) => {
        const val1 = a[key] ?? undefined;
        const val2 = b[key] ?? undefined;

        if (val1 === val2) {
            return 0;
        }

        return (isSortedDescending ? val1! < val2! : val1! > val2!) ? 1 : -1;
    });
}

const emptyTableStyles = mergeStyles({
    paddingTop: 16,
    paddingLeft: 20,
    fontSize: 16,
});
