import React from 'react';
import { dateToLocalLongDateFormat, timeToString, TimeFormats } from 'utils/time-utils';
import { mergeStyleSets, mergeStyles, Stack } from '@fluentui/react';
import { AppConstants } from 'components/common/constants';
import {
    IGroupActivity,
    IGroupActivityObject,
    GroupActivityObjectType,
} from 'clients/group-client';
import { ManageGroupsVariableContextType } from 'components/groups/manage-groups/contexts/manage-groups-variable-context';
import Indent from 'components/common/misc/indent';
import DisplayActivitySubjectOrObject from 'components/groups/manage-group/timeline/display-activity-subject-or-object';
import { Dictionary } from 'assets/constants/global-constants';

export const True = 'True';
export const False = 'False';

export type GroupTimelineBin = { dateStamp: string; activities: IGroupActivity[] };

export function binSortEvents(
    // The newly arrived events.
    // Will be sorted into bins and concatenated with dateActivityBins.
    events: IGroupActivity[],
    // The events that have already been sorted into bins, each bin
    // containing events of one day.
    dateActivityBins: GroupTimelineBin[] = [],
): GroupTimelineBin[] {
    if (events.length === 0) {
        return dateActivityBins;
    }

    const bins: GroupTimelineBin[] = dateActivityBins;
    const newBins: GroupTimelineBin[] = [];

    let currentDateStamp;
    let currentBin: GroupTimelineBin;

    if (bins.length > 0) {
        // Remove the last entry in the bins so that if the first event
        // of the newly arrived events, ie, events[0] has the same date
        // as the last bin, the newly arrived event is added to that one.
        // Without this pop(), the last bin will be duplicated and
        // therefore repeated when the table is rendered.
        currentBin = bins.pop() as GroupTimelineBin;
        currentDateStamp = currentBin.dateStamp;
    } else {
        currentDateStamp = dateToLocalLongDateFormat(events[0].eventTimestampUTC);
        currentBin = {
            dateStamp: currentDateStamp,
            activities: [], // This is a new calendar day.
        };
    }

    for (let i = 0; i < events.length; i++) {
        const activity = events[i];
        const activityDateStamp = dateToLocalLongDateFormat(activity.eventTimestampUTC);
        if (activityDateStamp === currentDateStamp) {
            // Event belongs to the same calendar day as the previous event.
            currentBin.activities.push(activity);
        } else {
            // Event belongs to a different calendar day.
            // Push the current bin and create a new bin.
            newBins.push(currentBin);
            currentDateStamp = activityDateStamp;
            currentBin = {
                dateStamp: currentDateStamp,
                activities: [activity],
            };
        }
    }

    if (currentBin.activities.length > 0) {
        newBins.push(currentBin);
    }

    return bins.concat(newBins);
}

export const groupRuleViolations = (activity: IGroupActivity): IGroupActivityObject[] =>
    activity.additionalObjects?.filter(
        (object) => object.type === GroupActivityObjectType.GroupRuleViolationId,
    ) ?? [];

export const hasGroupRuleViolations = (activity: IGroupActivity): boolean =>
    !!activity.additionalObjects?.find(
        (object) => object.type === GroupActivityObjectType.GroupRuleViolationId,
    );

export const getBusinessJustification = (
    activity: IGroupActivity,
): IGroupActivityObject | undefined =>
    activity.additionalObjects?.find(
        (object) => object.type === GroupActivityObjectType.BusinessJustification,
    );

export const getDisplayStringForGroupRuleActivity = (activity: IGroupActivity): string => {
    if (activity.metadata?.groupRuleName) {
        return activity.metadata?.groupRuleName;
    } else {
        return activity.directObject.value;
    }
};

type IParams = {
    activity?: IGroupActivity;
    additionalObject?: IGroupActivityObject | undefined;
    groupId?: string;
    groupsContext?: ManageGroupsVariableContextType;
    key?: string;
};

// const { activity, additionalObject, groupId, groupsContext, key } = params;

const UnknownTypeRenderFunc = 'UnknownTypeRenderFunc ';

export const additionalObjectRenderFunc: Dictionary<(params: IParams) => JSX.Element> = {
    [GroupActivityObjectType.AddedDependentGroupId]: (params: IParams): JSX.Element => {
        const { additionalObject, groupsContext, key } = params;
        const addedGroupId = additionalObject?.value;
        if (!!addedGroupId && !!groupsContext) {
            return (
                <Indent key={key}>
                    Added dependency on &nbsp;
                    {strong(groupsContext.groupsDict[addedGroupId]?.name ?? addedGroupId)}
                </Indent>
            );
        }
        return <></>;
    },
    [GroupActivityObjectType.RemovedDependentGroupId]: (params: IParams): JSX.Element => {
        const { additionalObject, groupsContext, key } = params;
        const removedGroupId = additionalObject?.value;
        if (!!removedGroupId && !!groupsContext) {
            return (
                <Indent key={key}>
                    Removed dependency on &nbsp;
                    {strong(groupsContext.groupsDict[removedGroupId]?.name ?? removedGroupId)}
                </Indent>
            );
        }
        return <></>;
    },
    [GroupActivityObjectType.AddedAllowListPersonnelId]: (params: IParams): JSX.Element => {
        const { activity, additionalObject, groupId, key } = params;
        const principalId = additionalObject?.value;
        if (!!principalId && !!activity && !!groupId) {
            return (
                <Indent key={key}>
                    <Stack horizontal verticalAlign='center'>
                        Added&nbsp;
                        <DisplayActivitySubjectOrObject
                            id={activity.id}
                            type={GroupActivityObjectType.PersonnelId}
                            value={principalId}
                            groupId={groupId}
                        />
                        &nbsp;to policy&apos;s Allow List
                    </Stack>
                </Indent>
            );
        }
        return <></>;
    },
    [GroupActivityObjectType.RemovedAllowListPersonnelId]: (params: IParams): JSX.Element => {
        const { activity, additionalObject, groupId, key } = params;
        const principalId = additionalObject?.value;
        if (!!principalId && !!activity && !!groupId) {
            return (
                <Indent key={key}>
                    <Stack horizontal verticalAlign='center'>
                        Removed&nbsp;
                        <DisplayActivitySubjectOrObject
                            id={activity.id}
                            type={GroupActivityObjectType.PersonnelId}
                            value={principalId}
                            groupId={groupId}
                        />
                        &nbsp;from policy&apos;s Allow List
                    </Stack>
                </Indent>
            );
        }
        return <></>;
    },
    [GroupActivityObjectType.GracePeriod]: (params: IParams): JSX.Element => {
        const { activity, additionalObject, groupId, key } = params;
        const gracePeriod = additionalObject?.value;
        if (!!gracePeriod && !!activity && !!groupId) {
            return (
                <Indent key={key}>
                    Updated the &nbsp; {strong('Grace Period')} &nbsp; to &nbsp; {gracePeriod}{' '}
                    &nbsp; days
                </Indent>
            );
        }
        return <></>;
    },
    [GroupActivityObjectType.EffectiveStartTimestampUTC]: (params: IParams): JSX.Element => {
        const { additionalObject, key } = params;
        if (!!additionalObject) {
            const { value } = additionalObject;
            return (
                <Indent key={key}>
                    Updated the&nbsp;{strong('Effective Start Time')}&nbsp;to&nbsp;
                    {timeToString(parseInt(value) * 1000, TimeFormats.ddddDDMMMMYYYY_hmmA)}
                </Indent>
            );
        }
        return <></>;
    },
    [GroupActivityObjectType.EffectiveEndTimestampUTC]: (params: IParams): JSX.Element => {
        const { additionalObject, key } = params;
        if (!!additionalObject) {
            const { value } = additionalObject;
            return (
                <Indent key={key}>
                    Updated the&nbsp;{strong('Effective End Time')}&nbsp;to&nbsp;
                    {timeToString(parseInt(value) * 1000, TimeFormats.ddddDDMMMMYYYY_hmmA)}
                </Indent>
            );
        }
        return <></>;
    },
    [GroupActivityObjectType.GroupRuleName]: (params: IParams): JSX.Element => {
        const { additionalObject, key } = params;
        if (!!additionalObject) {
            const { value } = additionalObject;
            return (
                <Indent key={key}>
                    Updated the {strong('Rule Name')}&nbsp;to&nbsp;{strong(value)}
                </Indent>
            );
        }
        return <></>;
    },
    [GroupActivityObjectType.GroupRuleDescription]: (params: IParams): JSX.Element => {
        const { additionalObject, key } = params;
        if (!!additionalObject) {
            const { value } = additionalObject;
            return (
                <Indent key={key}>
                    <span>Updated the {strong('Rule Description')} to</span>
                    <Indent> {value} </Indent>
                </Indent>
            );
        }
        return <></>;
    },
    [GroupActivityObjectType.SuspendGroupRuleEnforcement]: (params: IParams): JSX.Element => {
        const { additionalObject, key } = params;
        if (!!additionalObject) {
            const { value } = additionalObject;
            return (
                <Indent key={key}>
                    <span>
                        {value === True ? 'Suspended' : 'Reinstated'} {strong('Rule Enforcement')}
                    </span>
                </Indent>
            );
        }
        return <></>;
    },
    [GroupActivityObjectType.SuspendGroupRuleNotifications]: (params: IParams): JSX.Element => {
        const { additionalObject, key } = params;
        if (!!additionalObject) {
            const { value } = additionalObject;
            return (
                <Indent key={key}>
                    <span>
                        {value === True ? 'Suspended' : 'Reinstated'}{' '}
                        {strong('Rule Non-Compliance Notifications')}
                    </span>
                </Indent>
            );
        }
        return <></>;
    },
    [GroupActivityObjectType.AddedBlockListPersonnelId]: (params: IParams): JSX.Element => {
        const { additionalObject, activity, groupId, key } = params;
        if (!!additionalObject && !!activity && !!groupId) {
            const { value } = additionalObject;
            return (
                <Indent key={key}>
                    <Stack horizontal verticalAlign='center'>
                        Added&nbsp;
                        <DisplayActivitySubjectOrObject
                            id={activity.id}
                            type={GroupActivityObjectType.PersonnelId}
                            value={value}
                            groupId={groupId}
                        />
                        &nbsp;to block list
                    </Stack>
                </Indent>
            );
        }
        return <></>;
    },
    [GroupActivityObjectType.RemovedBlockListPersonnelId]: (params: IParams): JSX.Element => {
        const { additionalObject, key, activity, groupId } = params;
        if (!!additionalObject && !!activity && !!groupId) {
            const { value } = additionalObject;
            return (
                <Indent key={key}>
                    <Stack horizontal verticalAlign='center'>
                        Removed&nbsp;
                        <DisplayActivitySubjectOrObject
                            id={activity.id}
                            type={GroupActivityObjectType.PersonnelId}
                            value={value}
                            groupId={groupId}
                        />
                        &nbsp;from block list
                    </Stack>
                </Indent>
            );
        }
        return <></>;
    },
    [UnknownTypeRenderFunc]: (params: IParams): JSX.Element => {
        const { additionalObject, key } = params;
        if (!!additionalObject) {
            const { type, value } = additionalObject;
            return (
                <Indent key={key}>
                    {strong(type)}&nbsp;{strong(value)}
                </Indent>
            );
        }
        return <></>;
    },
};

export const getDesiredAdditionalObjects = (
    activity: IGroupActivity,
    desiredTypes: GroupActivityObjectType[],
): IGroupActivityObject[] => {
    // (1) Create a dictionary of additionalObjects array,
    // indexed on value of their "type" property.
    type T = Dictionary<IGroupActivityObject>;
    const additionalObjectsDict: T =
        activity.additionalObjects?.reduce((prev: T, cur: IGroupActivityObject) => {
            prev[cur.type] = cur;
            return prev;
        }, {}) ?? {};

    // (2) Create an array of desired objects, with the same
    // order that desiredTypes has specified.
    const desiredObjects = desiredTypes
        .map((thisType) => {
            return additionalObjectsDict[thisType];
        })
        .filter((item) => !!item); // Filter out undefined values
    return desiredObjects;
};

export const renderAdditionalContent = (params: {
    activity: IGroupActivity;
    desiredTypes: GroupActivityObjectType[];
    groupId: string;
    groupsContext: ManageGroupsVariableContextType;
}): JSX.Element => {
    const { activity, desiredTypes, groupId, groupsContext } = params;

    const desiredObjects = getDesiredAdditionalObjects(activity, desiredTypes);
    return (
        <>
            {desiredObjects.map((additionalObject) => {
                const { type, value } = additionalObject;
                const renderFunc =
                    additionalObjectRenderFunc[type] ??
                    additionalObjectRenderFunc[UnknownTypeRenderFunc];
                return renderFunc({
                    activity,
                    additionalObject,
                    groupId,
                    groupsContext,
                    key: `additionalObject-${type}-${value}`,
                });
            })}
        </>
    );
};

export const addedDependencyContent = (
    groupsContext: ManageGroupsVariableContextType,
    activity: IGroupActivity,
): JSX.Element => {
    const addedDependentGroupObj = activity.additionalObjects?.find(
        (additionalObject) =>
            additionalObject.type === GroupActivityObjectType.AddedDependentGroupId,
    );
    return additionalObjectRenderFunc[GroupActivityObjectType.AddedDependentGroupId]({
        additionalObject: addedDependentGroupObj,
        groupsContext,
    });
};

export const removedDependencyContent = (
    groupsContext: ManageGroupsVariableContextType,
    activity: IGroupActivity,
): JSX.Element => {
    const removedDependentGroupObj = activity.additionalObjects?.find(
        (additionalObject) =>
            additionalObject.type === GroupActivityObjectType.RemovedDependentGroupId,
    );
    return additionalObjectRenderFunc[GroupActivityObjectType.RemovedDependentGroupId]({
        additionalObject: removedDependentGroupObj,
        groupsContext,
    });
};

// TODO - Not yet used. Will it ever be?
export const addedAllowListPersonnelIdContent = (
    groupId: string,
    activity: IGroupActivity,
): JSX.Element => {
    const principalIdObj = activity.additionalObjects?.find(
        (additionalObject) =>
            additionalObject.type === GroupActivityObjectType.AddedAllowListPersonnelId,
    );
    return additionalObjectRenderFunc[GroupActivityObjectType.AddedAllowListPersonnelId]({
        additionalObject: principalIdObj,
        groupId,
        activity,
    });
};

// TODO - Not yet used. Will it ever be?
export const removedAllowListPersonnelIdContent = (
    groupId: string,
    activity: IGroupActivity,
): JSX.Element => {
    const principalIdObj = activity.additionalObjects?.find(
        (additionalObject) =>
            additionalObject.type === GroupActivityObjectType.RemovedAllowListPersonnelId,
    );
    return additionalObjectRenderFunc[GroupActivityObjectType.RemovedAllowListPersonnelId]({
        activity,
        additionalObject: principalIdObj,
        groupId,
    });
};

// TODO - Not yet used. Will it ever be?
export const updatedGracePeriodContent = (activity: IGroupActivity): JSX.Element => {
    const gracePeriodObj = activity.additionalObjects?.find(
        (additionalObject) => additionalObject.type === GroupActivityObjectType.GracePeriod,
    );
    return additionalObjectRenderFunc[GroupActivityObjectType.GracePeriod]({
        activity,
        additionalObject: gracePeriodObj,
    });
};

// TODO - Not yet used. Will it ever be?
const getDisplayString = (
    groupsContext: ManageGroupsVariableContextType,
    additionalObject: IGroupActivityObject,
): string => {
    const { type, value } = additionalObject;
    switch (type) {
        case GroupActivityObjectType.GroupId:
            return groupsContext.groupsDict[additionalObject.value]?.name ?? value;
        default:
            return value;
    }
};

export const strong = (text: string, key?: string): JSX.Element => (
    <span key={key} className={styles.strong}>
        {text}
    </span>
);

export const strongLeftRight = (text: string, key?: string): JSX.Element => (
    <span key={key} className={`${styles.strong} ${styles.padLeftAndRight}`}>
        {text}
    </span>
);

const paddingLeft = '.2em';

export const styles = mergeStyleSets({
    icon: {
        fontSize: '1rem',
        marginRight: AppConstants.margin,
    },
    padLeftAndRight: {
        paddingLeft,
        paddingRight: '.2em',
    },
    padLeftOnly: {
        paddingLeft,
    },
    padRightOnly: {
        paddingRight: '.2em',
    },
    padAfterBy: {
        paddingRight: '.1em',
    },
    strong: {
        fontWeight: '500',
    },
    time: {
        color: 'rgba(0, 0, 0, 0.467)',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: '2px',
    },
    groupRuleViolationsBlock: mergeStyles({
        marginLeft: 32,
        paddingLeft,
    }),
    groupRuleViolationsList: mergeStyles({
        marginLeft: 54,
        paddingLeft,
    }),
});
