import React, { createContext, ReactNode, useState } from 'react';
import { SetStateFunc } from 'types/global-types';

type FilterContextStateType = {
    name: string | undefined;
    dateCreated: Date | undefined;
    lastModifiedDate: Date | undefined;
    upperPercentageCompliant: number | undefined;
    lowerPercentageCompliant: number | undefined;
    upperMemberCount: number | undefined;
    lowerMemberCount: number | undefined;
    setName: SetStateFunc<string | undefined>;
    setDateCreated: SetStateFunc<Date | undefined>;
    setLastModifiedDate: SetStateFunc<Date | undefined>;
    setLowerPercentageCompliant: (value: number | undefined) => void;
    setUpperPercentageCompliant: (value: number | undefined) => void;
    setLowerMemberCount: (value: number | undefined) => void;
    setUpperMemberCount: (value: number | undefined) => void;
    clearFilters: () => void;
};

export const ManageGroupsFilterContext = createContext<FilterContextStateType>(null!);

export interface IManageGroupsFilterProviderProps {
    children: ReactNode;
}

export default function ManageGroupsFilterProvider(
    props: IManageGroupsFilterProviderProps,
): JSX.Element {
    const [name, setName] = useState<string | undefined>(undefined);
    const [dateCreated, setDateCreated] = useState<Date | undefined>(undefined);
    const [lastModifiedDate, setLastModifiedDate] = useState<Date | undefined>(undefined);
    const [lowerPercentageCompliant, setLowerPercentageCompliant] = useState<number | undefined>(
        undefined,
    );
    const [upperPercentageCompliant, setUpperPercentageCompliant] = useState<number | undefined>(
        undefined,
    );
    const [lowerMemberCount, setLowerMemberCount] = useState<number | undefined>(undefined);
    const [upperMemberCount, setUpperMemberCount] = useState<number | undefined>(undefined);

    const clearFilters = (): void => {
        setName(undefined);
        setDateCreated(undefined);
        setLastModifiedDate(undefined);
        setLowerPercentageCompliant(undefined);
        setUpperPercentageCompliant(undefined);
        setLowerMemberCount(undefined);
        setUpperMemberCount(undefined);
    };

    const contextValue = {
        name,
        dateCreated,
        lastModifiedDate,
        lowerPercentageCompliant,
        upperPercentageCompliant,
        lowerMemberCount,
        upperMemberCount,
        setName,
        setDateCreated,
        setLastModifiedDate,
        setLowerPercentageCompliant,
        setUpperPercentageCompliant,
        setLowerMemberCount,
        setUpperMemberCount,
        clearFilters,
    };

    return (
        <ManageGroupsFilterContext.Provider value={contextValue}>
            {props.children}
        </ManageGroupsFilterContext.Provider>
    );
}
