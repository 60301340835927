import { IPersonaProps } from '@fluentui/react';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import {
    IGraphPrincipal,
    IGraphServicePrincipal,
    IGraphUser,
    isGraphServicePrincipal,
} from 'clients/graph-client';
import { getCachedPicture, getInitials } from 'components/common/employee/employee-utils';
import { PersonaItemIdType } from 'components/common/principal/principal-utils';
import { ICacheContext } from 'contexts/cache-context';

export function transformDisplayNameToPersona(displayName: string): IPersonaProps {
    const names = displayName.split(' ');
    const firstInitial = names !== undefined ? names[0].charAt(0).toUpperCase() : '';
    const secondInitial =
        names !== undefined && names.length >= 2 ? names[1].charAt(0).toUpperCase() : '';
    return {
        imageInitials: `${firstInitial}${secondInitial}`,
        showInitialsUntilImageLoads: true,
        text: displayName,
        secondaryText: displayName,
        tertiaryText: displayName,
    };
}

export function transformPrincipalRecordToPersona(
    principalRecord: IPrincipalRecord,
    personaItemIdType: PersonaItemIdType = 'oid', // Default to using oid as the identifier
    cacheContext?: ICacheContext,
): IPersonaProps {
    const primaryText = principalRecord.displayName ?? principalRecord.upn ?? principalRecord.id;
    const secondaryText = principalRecord.upn ?? undefined;
    const imageUrl = cacheContext ? getCachedPicture(principalRecord, cacheContext) : undefined;

    return {
        imageInitials: getInitials(primaryText).toUpperCase(),
        imageUrl: imageUrl,
        imageShouldFadeIn: !imageUrl,
        imageShouldStartVisible: !!imageUrl,
        text: primaryText,
        secondaryText: secondaryText,
        tertiaryText: undefined,
        showInitialsUntilImageLoads: true,
        itemID: principalRecord[personaItemIdType],
        itemProp: JSON.stringify(principalRecord),
    } as IPersonaProps;
}

// Can be used together with onCandidateSelected of CoreEmployeePickerTypeaheadSearch.
export const getPrincipalRecordFromPersona = (
    persona?: IPersonaProps,
): IPrincipalRecord | undefined => {
    if (persona?.itemProp === undefined) {
        return undefined;
    }

    const principalRecord = JSON.parse(persona.itemProp) as IPrincipalRecord;

    if (principalRecord.id === undefined || principalRecord.isActive === undefined) {
        throw 'Persona did not contain a principal record';
    }

    return principalRecord;
};

export const getPrincipalRecordsFromPersonas = (personas?: IPersonaProps[]): IPrincipalRecord[] => {
    if (personas === undefined) {
        return [];
    }

    const principalRecords: IPrincipalRecord[] = [];

    for (const persona of personas) {
        const principalRecord = getPrincipalRecordFromPersona(persona);
        if (principalRecord !== undefined) {
            principalRecords.push(principalRecord);
        }
    }

    return principalRecords;
};

export const getPersonasFromPrincipalRecords = (
    principalRecords?: IPrincipalRecord[],
    personaItemIdType?: PersonaItemIdType,
    cacheContext?: ICacheContext,
): IPersonaProps[] => {
    if (principalRecords === undefined) {
        return [];
    }

    const personas: IPersonaProps[] = [];

    for (const principalRecord of principalRecords) {
        personas.push(
            transformPrincipalRecordToPersona(principalRecord, personaItemIdType, cacheContext),
        );
    }

    return personas;
};

export function createUnknownPersona(id: string): IPersonaProps {
    return {
        imageInitials: '?',
        text: id,
        itemID: id,
    };
}

export function transformGraphPrincipalToPersona(
    graphPrincipal: IGraphPrincipal,
    cacheContext?: ICacheContext,
): IPersonaProps {
    if (isGraphServicePrincipal(graphPrincipal)) {
        return transformGraphServicePrincipalToPersona(graphPrincipal, cacheContext);
    } else {
        return transformGraphUserToPersona(graphPrincipal, cacheContext);
    }
}

export function transformGraphUserToPersona(
    graphUser: IGraphUser,
    cacheContext?: ICacheContext,
): IPersonaProps {
    const primaryText = graphUser.displayName ?? graphUser.userPrincipalName ?? graphUser.id;
    const secondaryText = graphUser.userPrincipalName ?? undefined;
    const imageUrl = cacheContext ? getCachedPicture(graphUser, cacheContext) : undefined;

    return {
        imageInitials: getInitials(primaryText).toUpperCase(),
        imageUrl: imageUrl,
        imageShouldFadeIn: !imageUrl,
        imageShouldStartVisible: !!imageUrl,
        text: primaryText,
        secondaryText: secondaryText,
        tertiaryText: undefined,
        showInitialsUntilImageLoads: true,
        itemID: graphUser.id,
        itemProp: JSON.stringify(graphUser),
    } as IPersonaProps;
}

export function transformGraphServicePrincipalToPersona(
    graphServicePrincipal: IGraphServicePrincipal,
    cacheContext?: ICacheContext,
): IPersonaProps {
    const primaryText = graphServicePrincipal.displayName ?? graphServicePrincipal.id;
    const secondaryText = graphServicePrincipal.servicePrincipalType ?? undefined;
    const imageUrl = cacheContext
        ? getCachedPicture(graphServicePrincipal, cacheContext)
        : undefined;

    return {
        imageInitials: getInitials(primaryText).toUpperCase(),
        imageUrl: imageUrl,
        imageShouldFadeIn: !imageUrl,
        imageShouldStartVisible: !!imageUrl,
        text: primaryText,
        secondaryText: secondaryText,
        tertiaryText: undefined,
        showInitialsUntilImageLoads: true,
        itemID: graphServicePrincipal.id,
        itemProp: JSON.stringify(graphServicePrincipal),
    } as IPersonaProps;
}

export function getGraphPrincipalFromPersona(persona?: IPersonaProps): IGraphPrincipal | undefined {
    if (persona?.itemProp === undefined) {
        return undefined;
    }

    const graphPrincipal = JSON.parse(persona.itemProp) as IGraphPrincipal;

    if (graphPrincipal.id === undefined) {
        throw 'Persona did not contain a graph principal';
    }

    return graphPrincipal;
}
