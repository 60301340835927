/* eslint-disable @typescript-eslint/naming-convention */
import { IDropdownOption } from '@fluentui/react';
import React from 'react';
import { BadgeColorHex } from 'assets/constants/global-colors';
import EllipsisText from 'components/common/ellipsis-text';
import { ISuitabilityRecord } from 'clients/suitability-client';
import { charPerPixelConversion } from 'components/personnel-profile/common/common-constants';
import {
    SuitabilityAgencies,
    SuitabilityLevels,
    SuitabilityStatuses,
    SuitabilityTypes,
    suitabilityStatusesConsideredActive,
} from 'components/personnel-profile/suitability/profile-suitability-types';
import { ContinuousEvaluationTypes } from 'components/personnel-profile/common/common-types';
import { dateToFormattedDateTimeStringFromMillis } from 'utils/time-utils';

export function getModalPixelWidthValueBasedOnTextLength(textLength: number): string {
    return textLength * charPerPixelConversion + 'px';
}

export function isSuitabilityActive(suitability: ISuitabilityRecord | undefined): boolean {
    if (suitability && suitability.status) {
        return suitabilityStatusesConsideredActive.some(
            (status) => status.toLocaleLowerCase() === suitability.status.toLocaleLowerCase(),
        );
    }
    return false;
}

export function getAgencyEnumValueFromKey(
    agency: string,
    format = false,
): SuitabilityAgencies | string {
    const keyWithoutUnderlines = agency.replace(/_/g, '');
    const result = SuitabilityAgencies[keyWithoutUnderlines as keyof typeof SuitabilityAgencies];
    return format ? result.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/_/g, ' ') : result;
}

export function suitabilityStatusSpan(
    suitability: ISuitabilityRecord | undefined,
    textEllipsisLength: number | undefined = 8,
): JSX.Element {
    if (suitability && suitability.status) {
        let backgroundColor = BadgeColorHex.RED;
        if (isSuitabilityActive(suitability)) {
            backgroundColor = BadgeColorHex.GREEN;
        }
        const text = SuitabilityStatuses[suitability.status as keyof typeof SuitabilityStatuses];
        return (
            <span
                style={{
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    backgroundColor: backgroundColor,
                    padding: '2px 7px',
                }}>
                {<EllipsisText text={text} textLengthBeforeEllipsis={textEllipsisLength} />}
            </span>
        );
    }
    return <></>;
}

export function suitabilityStatusDropdownOptions(
    selectedStatus: string | undefined,
): IDropdownOption[] {
    return Object.keys(SuitabilityStatuses).map((status) => {
        return {
            key: status,
            text: SuitabilityStatuses[status as keyof typeof SuitabilityStatuses],
            selected: (selectedStatus && selectedStatus === status) as boolean,
        };
    });
}

export function suitabilityDropdownOptions(
    selectedSuitability: string | undefined,
): IDropdownOption[] {
    return Object.keys(SuitabilityLevels).map((level) => {
        return {
            key: level,
            text: SuitabilityLevels[level as keyof typeof SuitabilityLevels],
            selected: selectedSuitability && selectedSuitability === level,
        } as IDropdownOption;
    });
}

export function suitabilityTypeDropdownOptions(
    selectedType: string | undefined,
): IDropdownOption[] {
    return Object.keys(SuitabilityTypes).map((type) => {
        return {
            key: type,
            text: SuitabilityTypes[type as keyof typeof SuitabilityTypes],
            selected: selectedType && selectedType === type,
        } as IDropdownOption;
    });
}

export function agencyDropdownOptions(selectedAgency: string | undefined): IDropdownOption[] {
    return Object.keys(SuitabilityAgencies).map((agency) => {
        return {
            key: agency,
            text: getAgencyEnumValueFromKey(agency, true),
            selected: (selectedAgency &&
                selectedAgency.replace(/_/g, '') === agency.replace(/_/g, '')) as boolean,
        };
    });
}

export function continuousEvaluationTypeDropdownOptions(
    selectedType: string | undefined,
): IDropdownOption[] {
    return Object.keys(ContinuousEvaluationTypes).map((type) => {
        return {
            key: type,
            text: ContinuousEvaluationTypes[type as keyof typeof ContinuousEvaluationTypes],
            selected: (selectedType && selectedType === type) as boolean,
        };
    });
}
