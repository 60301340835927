import { Dictionary } from 'assets/constants/global-constants';
import { GroupRole, IGroup, IGroupMembership, IGroupSecurityGroup } from 'clients/group-client';
import { hasEnoughFTEOwners } from 'components/groups/manage-group/manage-group-utils';
import React, { createContext, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { SetStateFunc } from 'types/global-types';
import { GeneralFetchResultDataType } from 'utils/misc-hooks';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

export interface IManageGroupContext {
    group: IGroup | undefined;
    groups: IGroup[];
    groupOwners: IGroupMembership[];
    shouldFetchOwners: boolean;
    errorFetchingOwners: string;
    isGroupOwnersObtained: boolean;
    linkedSecurityGroups: IGroupSecurityGroup[];
    hasErrorGettingLinkedSecurityGroups: boolean;
    isEnableOwnerDemote: boolean;
    ownerIsFTE: Dictionary<boolean>; // key: personnelId.
    isMember: () => boolean;
    isManager: () => boolean;
    isOwner: () => boolean;
    isAdmin: () => boolean;
    isAuditor: () => boolean;
    groupMembershipVar: GeneralFetchResultDataType<IGroupMembership>;
    setGroup: SetStateFunc<IGroup | undefined>;
    setGroups: SetStateFunc<IGroup[]>;
    setGroupOwners: SetStateFunc<IGroupMembership[]>;
    setShouldFetchOwners: SetStateFunc<boolean>;
    setErrorFetchingOwners: SetStateFunc<string | undefined>;
    setIsGroupOwnersObtained: SetStateFunc<boolean>;
    setLinkedSecurityGroups: SetStateFunc<IGroupSecurityGroup[]>;
    setErrorGettingLinkedSecurityGroups: SetStateFunc<boolean>;
    setGroupMembershipVar: SetStateFunc<GeneralFetchResultDataType<IGroupMembership>>;
    setOwnerIsFTE: SetStateFunc<Dictionary<boolean>>;
}

export const ManageGroupContext = createContext<IManageGroupContext>(null!);

export interface IManageGroupRolesProviderProps {
    children: ReactNode;
}

export default function ManageGroupProvider(props: IManageGroupRolesProviderProps): JSX.Element {
    const [group, setGroup] = useState<IGroup | undefined>();
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [groupOwners, setGroupOwners] = useState<IGroupMembership[]>([]);
    const [shouldFetchOwners, setShouldFetchOwners] = useState<boolean>(true);
    const [errorFetchingOwners, setErrorFetchingOwners] = useState<string>();
    const [isGroupOwnersObtained, setIsGroupOwnersObtained] = useState<boolean>(false);
    const [linkedSecurityGroups, setLinkedSecurityGroups] = useState<IGroupSecurityGroup[]>([]);
    const [hasErrorGettingLinkedSecurityGroups, setHasErrorGettingLinkedSecurityGroups] = useState<
        boolean
    >(false);
    const [groupMembershipVar, setGroupMembershipVar] = useState<
        GeneralFetchResultDataType<IGroupMembership>
    >({});
    const [ownerIsFTE, setOwnerIsFTE] = useState<Dictionary<boolean>>({});

    const hasFeatureFlagGroupsMin2FTEs = useFeatureFlag(FeatureFlagKeys.groupsMin2FteOwners)
        .enabled;

    function createUpdateFunction<T>(setStateFunc: SetStateFunc<T>): SetStateFunc<T> {
        return (value: SetStateAction<T>): void => {
            setStateFunc(value);
        };
    }

    const isMember = (): boolean => {
        return groupMembershipVar?.value?.role === GroupRole.MEMBER;
    };
    const isManager = (): boolean => {
        return groupMembershipVar?.value?.role === GroupRole.MANAGER;
    };
    const isOwner = (): boolean => {
        return groupMembershipVar?.value?.role === GroupRole.OWNER;
    };
    const isAdmin = (): boolean => {
        return groupMembershipVar?.value?.role === GroupRole.ADMIN;
    };
    const isAuditor = (): boolean => {
        return groupMembershipVar?.value?.role === GroupRole.AUDITOR;
    };

    const isEnableOwnerDemote = useMemo(
        (): boolean =>
            hasFeatureFlagGroupsMin2FTEs ? hasEnoughFTEOwners(groupOwners, ownerIsFTE) : true,
        [groupOwners, hasFeatureFlagGroupsMin2FTEs, ownerIsFTE],
    );

    const contextValue = {
        group,
        groups,
        // The following nullish coalescings are there to set coder's mind
        // free from whether or not groupOwners is undefined.
        groupOwners: groupOwners ?? [],
        shouldFetchOwners,
        errorFetchingOwners: errorFetchingOwners ?? '',
        isGroupOwnersObtained,
        linkedSecurityGroups,
        hasErrorGettingLinkedSecurityGroups: hasErrorGettingLinkedSecurityGroups,
        isEnableOwnerDemote,
        ownerIsFTE,
        isMember,
        isManager,
        isOwner,
        isAdmin,
        isAuditor,
        groupMembershipVar,
        setGroup: createUpdateFunction<IGroup | undefined>(setGroup),
        setGroups: createUpdateFunction<IGroup[]>(setGroups),
        setGroupOwners: createUpdateFunction<IGroupMembership[]>(setGroupOwners),
        setShouldFetchOwners: createUpdateFunction<boolean>(setShouldFetchOwners),
        setErrorFetchingOwners: createUpdateFunction<string | undefined>(setErrorFetchingOwners),
        setIsGroupOwnersObtained: createUpdateFunction<boolean>(setIsGroupOwnersObtained),
        setLinkedSecurityGroups: createUpdateFunction<IGroupSecurityGroup[]>(
            setLinkedSecurityGroups,
        ),
        setErrorGettingLinkedSecurityGroups: createUpdateFunction<boolean>(
            setHasErrorGettingLinkedSecurityGroups,
        ),
        setGroupMembershipVar: createUpdateFunction<GeneralFetchResultDataType<IGroupMembership>>(
            setGroupMembershipVar,
        ),
        setOwnerIsFTE: createUpdateFunction<Dictionary<boolean>>(setOwnerIsFTE),
    };

    return (
        <ManageGroupContext.Provider value={contextValue}>
            {props.children}
        </ManageGroupContext.Provider>
    );
}
