/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import DOMPurify from 'dompurify';
import {
    ReactSVGPanZoom,
    Value,
    MODE_IDLE,
    zoomOnViewerCenter,
    ViewerMouseEvent,
    ViewerTouchEvent,
    pan,
} from 'react-svg-pan-zoom';
import { SeatStatuses } from 'clients/facilities-client';
import { ISeatSlot } from 'components/facilities/common/facilities-timeslot-utils';
import FacilitiesSvgMapSizeProviderDiv from 'components/facilities/facilities-svg-map/facilities-svg-map-size-provider-div';
import FacilitiesSvgMapToolbar from 'components/facilities/facilities-svg-map/facilities-svg-map-toolbar';
import FacilitiesLegend from 'components/facilities/facilities-legend';

export interface IFacilitiesSvgMapProps {
    svg: string;
    seatSlots: ISeatSlot[];
    onSeatSelected: (seat: ISeatSlot) => void;
}

const classNames = mergeStyleSets({
    svgContainer: {
        position: 'relative',
        height: '100%',
        border: '1px solid #EDEBE9',
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        '&:focus-visible': {
            outline: 'black auto 1px',
        },
    },
});

export default function FacilitiesSvgMap(props: IFacilitiesSvgMapProps): JSX.Element {
    const { svg, seatSlots, onSeatSelected } = props;
    const [panZoomValue, setPanZoomValue] = useState<Value>({
        version: 2,
        mode: MODE_IDLE,
        focus: false,
        a: 1,
        b: 0,
        c: 0,
        d: 1,
        e: 0,
        f: 0,
        viewerWidth: 0,
        viewerHeight: 0,
        SVGWidth: 0,
        SVGHeight: 0,
        miniatureOpen: true,
    });

    const [containerSize, setContainerSize] = useState<{ width: number; height: number }>({
        width: 0,
        height: 0,
    });

    const seatColorCss = useMemo(() => {
        let css = 'text { pointer-events: none; } ';
        for (const seatSlot of seatSlots) {
            const status = seatSlot.status as keyof typeof SeatStatuses;
            const color = SeatStatuses[status].color;
            css += `polygon.seat[data-unitid='${seatSlot.unitId}'] { cursor: pointer; stroke: ${color}; fill: ${color}; fill-opacity: 0.1; } `;
        }
        return css;
    }, [seatSlots]);

    const zoomIn = useCallback(
        () => setPanZoomValue((currentValue: Value) => zoomOnViewerCenter(currentValue, 1.2)),
        [],
    );
    const zoomOut = useCallback(
        () => setPanZoomValue((currentValue: Value) => zoomOnViewerCenter(currentValue, 0.8)),
        [],
    );

    const handleKeyDown = useCallback(
        (ev: React.KeyboardEvent<HTMLDivElement>) => {
            const panAmount = 20;
            const panLimit = 20;

            switch (ev.key) {
                case 'ArrowUp':
                case 'ArrowDown':
                case 'ArrowLeft':
                case 'ArrowRight':
                    setPanZoomValue(
                        (currentValue: Value): Value => {
                            switch (ev.key) {
                                case 'ArrowDown':
                                    return pan(currentValue, 0, -panAmount, panLimit);
                                case 'ArrowUp':
                                    return pan(currentValue, 0, panAmount, panLimit);
                                case 'ArrowRight':
                                    return pan(currentValue, -panAmount, 0, panLimit);
                                case 'ArrowLeft':
                                    return pan(currentValue, panAmount, 0, panLimit);
                                default:
                                    return currentValue;
                            }
                        },
                    );
                    break;
                case '=':
                    zoomIn();
                    break;
                case '-':
                    zoomOut();
                    break;
                default:
                    break;
            }

            ev.preventDefault();
        },
        [zoomIn, zoomOut],
    );

    const debounceSeatSelectedTimerIdRef = useRef<NodeJS.Timeout | undefined>(undefined);

    const debouncedOnSeatSelected = useCallback(
        (seatSlot: ISeatSlot) => {
            if (debounceSeatSelectedTimerIdRef.current !== undefined) {
                clearTimeout(debounceSeatSelectedTimerIdRef.current);
            }
            debounceSeatSelectedTimerIdRef.current = setTimeout(onSeatSelected, 300, seatSlot);
        },
        [onSeatSelected],
    );

    // Cancel debounced onSeatSelected event if unmounted
    useEffect(() => {
        return () => {
            if (debounceSeatSelectedTimerIdRef.current !== undefined) {
                clearTimeout(debounceSeatSelectedTimerIdRef.current);
            }
            debounceSeatSelectedTimerIdRef.current = undefined;
        };
    }, []);

    const handleInteraction = useCallback(
        (ev: ViewerMouseEvent<any> | ViewerTouchEvent<any>): void => {
            const eventPanZoomValue = ((ev as unknown) as { value: Value }).value;

            // If the touch/mouse release was more than movementToConsiderPanVsClick away from the press, consider this a pan and do not raise a click event
            const movementToConsiderPanVsClick = 10;
            if (
                eventPanZoomValue.startX !== null &&
                eventPanZoomValue.startY !== null &&
                eventPanZoomValue.endX !== null &&
                eventPanZoomValue.endY !== null &&
                eventPanZoomValue.startX !== undefined &&
                eventPanZoomValue.startY !== undefined &&
                eventPanZoomValue.endX !== undefined &&
                eventPanZoomValue.endY !== undefined &&
                (Math.abs(eventPanZoomValue.endX - eventPanZoomValue.startX) >
                    movementToConsiderPanVsClick ||
                    Math.abs(eventPanZoomValue.endY - eventPanZoomValue.startY) >
                        movementToConsiderPanVsClick)
            ) {
                return;
            }

            const target = ev.originalEvent.target as HTMLElement;
            const dataUnitId = target?.getAttribute('data-unitid');
            if (!dataUnitId) return;
            const seatSlot = seatSlots.find((x) => x.unitId === dataUnitId);
            if (seatSlot) {
                debouncedOnSeatSelected(seatSlot);
            }
        },
        [seatSlots, debouncedOnSeatSelected],
    );

    // HACK: Workaround to get around out of date TypeScript definition included with ReactSVGPanZoom that doesn't include onTouchStart
    const additionalReactSvgPanZoomProps = useMemo(
        () => ({
            onTouchEnd: handleInteraction,
        }),
        [handleInteraction],
    );

    return (
        <FacilitiesSvgMapSizeProviderDiv
            tabIndex={0}
            onKeyDown={handleKeyDown}
            className={classNames.svgContainer}
            onSizeChange={setContainerSize}>
            {containerSize.width > 0 && containerSize.height > 0 && (
                <ReactSVGPanZoom
                    width={containerSize.width}
                    height={containerSize.height}
                    value={panZoomValue}
                    onChangeValue={setPanZoomValue}
                    onClick={handleInteraction}
                    scaleFactorMax={5}
                    scaleFactorMin={0.5}
                    scaleFactor={1.5}
                    scaleFactorOnWheel={1.1}
                    background='#F5F5F5'
                    tool='auto'
                    onChangeTool={() => null}
                    miniatureProps={{
                        position: 'none',
                        background: 'transparent',
                        width: 0,
                        height: 0,
                    }}
                    customToolbar={() => (
                        <FacilitiesSvgMapToolbar onZoomIn={zoomIn} onZoomOut={zoomOut} />
                    )}
                    {...additionalReactSvgPanZoomProps}>
                    <svg xmlns='http://www.w3.org/2000/svg'>
                        <style type='text/css'>{seatColorCss}</style>
                        <g
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(svg),
                            }}></g>
                    </svg>
                </ReactSVGPanZoom>
            )}
            <FacilitiesLegend />
        </FacilitiesSvgMapSizeProviderDiv>
    );
}
