import React, { createContext, useState } from 'react';
import { LocalStorageCache } from 'utils/local-storage-cache-utils';

export const CacheContext = createContext<ICacheContext>(null!);

export interface CacheContextProviderProps {
    cache: Map<string, unknown>;
    children: JSX.Element;
}

export function CacheContextProvider(props: CacheContextProviderProps): JSX.Element {
    const [cache] = useState<Map<string, unknown | undefined>>(props.cache);
    const DEFAULT_CACHE_NAME = 'default-cache';

    function store<T>(
        key: string,
        value: T,
        useLocalStorage = false,
        cacheName = DEFAULT_CACHE_NAME,
    ): void {
        if (useLocalStorage) {
            LocalStorageCache.store(key, value, cacheName);
        } else {
            cache.set(key, value);
        }
    }

    function retrieve<T>(
        key: string,
        useLocalStorage = false,
        cacheName = DEFAULT_CACHE_NAME,
    ): T | undefined {
        if (useLocalStorage) {
            return LocalStorageCache.retrieve(key, cacheName) as T | undefined;
        } else {
            return cache.get(key) as T | undefined;
        }
    }

    function remove(key: string, useLocalStorage = false, cacheName = DEFAULT_CACHE_NAME): void {
        if (useLocalStorage) {
            LocalStorageCache.remove(key, cacheName);
        } else {
            cache.delete(key);
        }
    }

    return (
        <CacheContext.Provider
            value={{
                store: store,
                retrieve: retrieve,
                remove: remove,
            }}>
            {props.children}
        </CacheContext.Provider>
    );
}

export interface ICacheContext {
    store: <T>(key: string, value: T, useLocalStorage?: boolean, cacheName?: string) => void;
    retrieve: <T>(key: string, useLocalStorage?: boolean, cacheName?: string) => T | undefined;
    remove: <T>(key: string, useLocalStorage?: boolean, cacheName?: string) => void;
}
