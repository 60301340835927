import React from 'react';
import { globalStyles } from 'assets/styles/global-styles';
import { mergeStyles } from '@fluentui/react';

export function PageNotFound(): JSX.Element {
    return (
        <div>
            <div className={textStyle}>Page Not Found</div>
        </div>
    );
}

const textStyle = mergeStyles(globalStyles.veryLargeFont, {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '100px',
    textAlign: 'center',
    fontWeight: 'bold',
});
