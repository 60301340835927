import { ComboBox, TextField } from '@fluentui/react';
import React from 'react';
import ElementFooter from 'components/forms/element-footer';
import { ElementTypeProps } from 'components/forms/forms-common';

export default function CountryPicker(props: ElementTypeProps): JSX.Element {
    const { element, updateForm } = props;

    return (
        <>
            <TextField
                value={element.label}
                onChange={(ev, newValue): void => updateForm(element.id, newValue, 'label')}
                placeholder='Enter a label...'
                underlined
            />
            <TextField
                value={element.description}
                onChange={(ev, newValue): void => updateForm(element.id, newValue, 'description')}
                placeholder='Enter a description...'
            />
            <ComboBox ariaLabel='Countries' options={[]} disabled />
            <ElementFooter element={element} updateForm={updateForm} hasRequiredToggle={true} />
        </>
    );
}
