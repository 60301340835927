import React, { useState, useReducer, useEffect, useContext } from 'react';
import {
    IStaffingTeamResponse,
    ITeamAzureService,
    IAzureServiceKpi,
    IAzureService,
} from 'clients/staffing-client';
import { Table } from 'components/common/table';
import {
    IServiceTableRow,
    getServiceMapTableColumns,
} from 'components/staffing/service/get-service-map-table-columns';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import { StaffingServiceMapFilterContext } from 'components/staffing/contexts/staffing-service-map-filter-context';
import { Projects, orgHasNoTeamMsg } from 'components/staffing/staffing-constants';
import { globalStyles } from 'assets/styles/global-styles';
import { Stack, IStackStyles, IColumn } from '@fluentui/react';
import {
    MappingSettings,
    StatusSettings,
    translateKpiStatus,
} from 'components/staffing/service/service-mapping-constants';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';

interface IStaffingServiceMap {
    teams: IStaffingTeamResponse[];
    orgName: string;
    isLoading: boolean;
    allServices: IAzureService[];
    orgServices: ITeamAzureService[];
    orgServiceKpis: IAzureServiceKpi[];
    hasOrgEditPermission: boolean;

    fetchServices: () => void;
    getAllServices: () => void;
}

interface ITableParams {
    rows: IServiceTableRow[];
    columns: IColumn[];
    title: string;
    noTeamsMsg: string;
}

export default function StaffingServiceMap(props: IStaffingServiceMap): JSX.Element {
    const filters = useContext(StaffingServiceMapFilterContext);
    const authContext = useContext(AuthContext);
    const [teamsWithoutServices, setTeamsWithoutServices] = useState<string[]>([]);
    const toggleSortAscendingReducer = (sortAscending: number): number => {
        return -1 * sortAscending;
    };
    const [sortAscending, dispatchSortAscending] = useReducer(toggleSortAscendingReducer, 1);

    const projectName = Projects[0].name;

    useEffect(() => {
        if (props.allServices.length === 0) {
            props.getAllServices();
        }
    }, []);

    useEffect(() => {
        if (props.orgServices.length === 0 || props.orgServiceKpis.length === 0) {
            props.fetchServices();
        }
    }, []);

    useEffect(() => {
        const teamsWithoutServices: string[] = [];
        const withServices = props.orgServices.reduce((sSet, s) => {
            sSet.add(s.teamName);
            return sSet;
        }, new Set());
        props.teams.forEach((t) => {
            if (!withServices.has(t.name)) {
                teamsWithoutServices.push(t.name);
            }
        });
        setTeamsWithoutServices(teamsWithoutServices);
    }, [props.teams, props.orgServices]);

    const sortColumnHandler = (): void => {
        dispatchSortAscending();
    };

    const deleteServiceConcluded = (modalConclusion: ModalConclusion): void => {
        if (modalConclusion === ModalConclusion.Done) {
            props.fetchServices();
        }
    };

    const addServiceCallback = (modalConclusion: ModalConclusion): void => {
        if (modalConclusion === ModalConclusion.Done) {
            props.fetchServices();
        }
    };

    const columns = (): IColumn[] =>
        getServiceMapTableColumns({
            hasEditRoles:
                authContext.isInRole(Role.StaffingAdminEdit) || props.hasOrgEditPermission,
            sortAscending: sortAscending === 1,
            sortColumnHandler,
            deleteServiceConcluded,
            addServiceModalParams: {
                teams: props.teams,
                orgName: props.orgName,
                allServices: props.allServices,
                addServiceCallback: addServiceCallback,
            },
        });

    const tableRows = (
        whichMapping: MappingSettings,
        whichStatus: StatusSettings,
    ): IServiceTableRow[] => {
        const sortCmp = (s1: IServiceTableRow, s2: IServiceTableRow): number => {
            const t1 = s1.team + s1.service;
            const t2 = s2.team + s2.service;
            return sortAscending * t1.toLocaleLowerCase().localeCompare(t2.toLocaleLowerCase());
        };

        let rowsMapFiltered: IServiceTableRow[];
        switch (whichMapping) {
            case MappingSettings.All:
                rowsMapFiltered = tableRowsWithService().concat(tableRowsWithoutService());
                break;
            case MappingSettings.Mapped:
                rowsMapFiltered = tableRowsWithService();
                break;
            case MappingSettings.UnMapped:
            default:
                rowsMapFiltered = tableRowsWithoutService();
                break;
        }

        let rowsStatusFiltered;
        switch (whichStatus) {
            case StatusSettings.All:
                rowsStatusFiltered = rowsMapFiltered;
                break;
            case StatusSettings.OneDRI:
                rowsStatusFiltered = rowsMapFiltered.filter((row) =>
                    row.status.find(
                        (status) => translateKpiStatus(status) === StatusSettings.OneDRI,
                    ),
                );
                break;
            case StatusSettings.Two4DRI:
            default:
                rowsStatusFiltered = rowsMapFiltered;
                rowsStatusFiltered = rowsMapFiltered.filter((row) =>
                    row.status.find(
                        (status) => translateKpiStatus(status) === StatusSettings.OneDRI,
                    ),
                );
                break;
        }

        return rowsStatusFiltered.sort(sortCmp);
    };

    const tableRowsWithService = (): IServiceTableRow[] => {
        return props.orgServices.map((s) => {
            return {
                team: s.teamName,
                service: s,
                project: projectName,
                mapping: 'Mapped',
                status: props.orgServiceKpis.filter((k) => k.teamName === s.teamName),
                actions: {
                    addService: true,
                    deleteService: true,
                },
            };
        });
    };

    const tableRowsWithoutService = (): IServiceTableRow[] => {
        return teamsWithoutServices.map((t) => {
            return {
                team: t,
                service: (undefined as unknown) as ITeamAzureService,
                project: projectName,
                mapping: 'Unmapped',
                status: props.orgServiceKpis.filter((k) => k.teamName === t),
                actions: {
                    addService: true,
                    deleteService: false,
                },
            };
        });
    };

    const determineTableParams = (): ITableParams => {
        const rows = tableRows(
            filters.mapping.text as MappingSettings,
            filters.status.text as StatusSettings,
        );
        return {
            rows: rows,
            columns: columns(),
            title: 'Teams',
            noTeamsMsg: orgHasNoTeamMsg(props.orgName),
        };
    };

    const tableParams = determineTableParams();

    const contentsStyles: IStackStyles = {
        root: {
            marginBottom: '15px',
        },
    };

    return (
        <PageLoadingSpinner
            isLoading={props.isLoading}
            label={'Loading Services...'}
            ariaLive={'assertive'}
            labelPosition={'left'}>
            <Stack styles={contentsStyles}>
                <Stack.Item>
                    <div className={globalStyles.boldFont}>{tableParams.title}</div>
                    {props.teams.length !== 0 ? (
                        <Table<IServiceTableRow>
                            rows={tableParams.rows}
                            tableColumns={tableParams.columns}
                            isFetchingData={false}
                            tableName='Staffing Services'
                        />
                    ) : (
                        <div className={globalStyles.largeFont}>{tableParams.noTeamsMsg}</div>
                    )}
                </Stack.Item>
            </Stack>
        </PageLoadingSpinner>
    );
}
