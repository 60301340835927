import { IStackStyles, Stack, Toggle } from '@fluentui/react';
import React from 'react';

export interface FacilitiesReservationViewToggleProps {
    toggled?: boolean;
    onChange?: (event: React.MouseEvent<HTMLElement>, checked?: boolean) => void;
    styles?: IStackStyles;
    label: string;
    onOffText: string;
}

export default function FacilitiesReservationViewToggle(
    props: FacilitiesReservationViewToggleProps,
): JSX.Element {
    return (
        <Stack styles={props.styles}>
            <Stack.Item>
                <Toggle
                    inlineLabel
                    label={props.label}
                    onText={props.onOffText}
                    offText={props.onOffText}
                    onChange={props.onChange}
                    defaultChecked={props.toggled}
                    styles={{
                        label: { fontWeight: 'normal' },
                        container: { minWidth: '96px', marginRight: '-9px' },
                        pill: { marginRight: '8px' },
                    }}
                />
            </Stack.Item>
        </Stack>
    );
}
