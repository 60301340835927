import {
    DefaultButton,
    mergeStyles,
    PrimaryButton,
    Spinner,
    Stack,
    TextField,
} from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import FormsClient from 'clients/forms-client';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { elementWrapperStyle, formWrapperStyle, Form } from 'components/forms/forms-common';

const infoStyles = mergeStyles({
    whiteSpace: 'break-spaces',
    paddingTop: '1rem',
    fontWeight: '600',
});

const containerStyles = mergeStyles(elementWrapperStyle, globalStyles.boxShadow, {
    background: '#FAFAFA',
});

export function FormsCreate(): JSX.Element {
    const authContext = useContext(AuthContext);

    const history = useHistory();
    const [name, setName] = useState<string>('');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const getFormByName = async () => {
        if (name.length < 3 || name.length > 30) {
            setErrorMessage('Name length must be between 3 and 30 characters long.');
            return;
        }
        const alphaNumericDashUnderscoresRegex = new RegExp(/^[a-z0-9-_]+$/);
        if (!alphaNumericDashUnderscoresRegex.test(name.toLocaleLowerCase())) {
            setErrorMessage(
                'Name must only consist of alphanumeric characters, dashes, or underscores.',
            );
            return;
        }
        try {
            setLoading(true);
            await FormsClient.GetGenericForm(authContext, name.trim());
            setErrorMessage('Form name already exists. Please pick a unique name.');
        } catch (e) {
            if (e.status === 404) {
                await createForm(name);
            }
        } finally {
            setLoading(false);
        }
    };
    const createForm = async (formName: string) => {
        const newForm: Form = {
            id: '',
            name: formName,
            title: 'Form Title',
            subtitle: '',
            description: '',
            image: '',
            sections: [
                {
                    id: 0,
                    label: '',
                    description: '',
                    elements: [],
                },
            ],
            owners: [],
            notificationList: [],
            accessControlAttributes: [],
            accessControlEligibilities: [],
            accessControlEmployeeProperties: [],
            isPublished: false,
            isSoftDeleted: false,
            isLocked: false,
        };

        try {
            const result = await FormsClient.SaveGenericForm(authContext, newForm);
            history.push(`/forms/edit/${result.name}`);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className={formWrapperStyle}>
            <div className={containerStyles}>
                <h3 className={infoStyles}>
                    {'To get started, enter the name of your form below.'}
                </h3>
                <p style={{ marginBottom: '3rem' }}>
                    Form names must be globally unique and contain only alphanumeric characters,
                    dashes, or underscores
                </p>
                <TextField
                    styles={{ root: { width: '20rem' } }}
                    value={name}
                    onChange={(ev, newValue): void => {
                        setErrorMessage('');
                        setName(newValue ?? '');
                    }}
                    placeholder='Enter a name for your form...'
                    errorMessage={errorMessage}
                />
                <Stack
                    horizontal
                    tokens={{ childrenGap: 10 }}
                    style={{ margin: '2.5rem 0 2rem 1rem' }}>
                    {isLoading ? (
                        <Spinner style={{ marginLeft: '1rem', marginRight: '3.5rem' }} />
                    ) : (
                        <PrimaryButton style={{ marginRight: '.5rem' }} onClick={getFormByName}>
                            Create
                        </PrimaryButton>
                    )}
                    <DefaultButton onClick={() => history.push('/forms/templates')}>
                        Cancel
                    </DefaultButton>
                </Stack>
            </div>
        </div>
    );
}
