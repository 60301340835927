import { MessageBar, MessageBarType } from '@fluentui/react';
import { Dictionary } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import GroupClient, { IGroupMembership } from 'clients/group-client';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import CheckRole from 'components/common/check-role';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';
import Spacer from 'components/common/spacer';
import Tabs, { TabbedContent } from 'components/common/tabs';
import ManageGroupActions from 'components/groups/manage-group/actions/manage-group-actions';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import {
    fetchLinkedSecurityGroups,
    manageGroupBreadcrumbs,
} from 'components/groups/manage-group/manage-group-utils';
import ManageGroupMembers from 'components/groups/manage-group/members/manage-group-members';
import ManageGroupPolicy from 'components/groups/manage-group/policy/manage-group-policy';
import ManageGroupRoles from 'components/groups/manage-group/roles/manage-group-roles';
import ManageGroupSettings from 'components/groups/manage-group/settings/manage-group-settings';
import ManageGroupTimeline from 'components/groups/manage-group/timeline/manage-group-timeline';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useFetchSimple } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

function ManageGroup(): JSX.Element {
    const authContext = useContext(AuthContext);
    const principalUserContext = useContext(PrincipalUserContext);
    const groupContext = useContext(ManageGroupContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const isCoreActivityServiceEnabled = useFeatureFlag(FeatureFlagKeys.activityCore).enabled;

    const { groupId, tab } = useParams<{ groupId: string; tab?: string }>();

    const [errorFetchingGroup, setErrorFetchingGroup] = useState<string>();
    const [redirectTo, setRedirectTo] = useState<string>();
    const [hasErrorsOnPage, setHasErrorsOnPage] = useState<boolean>(false);

    const tabs = ['members', 'actions', 'roles', 'policy', 'settings', 'timeline'];

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs(manageGroupBreadcrumbs(groupContext.group?.name, tab));
    }, [groupContext.group, tab]);

    const onTabChange = (itemKey?: string): void => {
        if (itemKey && tabs.includes(itemKey)) {
            setRedirectTo(`/groups/manage/${groupId}/${itemKey}`);
        }
    };

    useFetchSimple<IGroupMembership>({
        dependencies: [groupId],
        canPerformFetch: !!groupId,
        fetchFunc: async (): Promise<IGroupMembership> => {
            groupContext.setGroupMembershipVar({ isFetching: true });
            return GroupClient.searchGroupMembers(
                authContext,
                groupId,
                principalUserContext.principalRecord.id,
            );
        },
        onSuccess: (result: IGroupMembership): void => {
            groupContext.setGroupMembershipVar({ value: result });
        },
        onError: (e): void => {
            if (e.status === 404) {
                // This is not an error.
                // Current user is just not a member of this group.
                groupContext.setGroupMembershipVar({});
            } else {
                groupContext.setGroupMembershipVar({ errMsg: 'Error fetching group membership' });
                setHasErrorsOnPage(true);
            }
        },
        onFinally: doNothing,
    });

    useFetchSimple({
        dependencies: [groupId, groupContext.shouldFetchOwners],
        canPerformFetch: !!groupId && groupContext.shouldFetchOwners,
        fetchFunc: async () => {
            groupContext.setShouldFetchOwners(false);
            return await GroupClient.getSpecialRoleMembers(authContext, groupId);
        },
        onSuccess: (owners: IGroupMembership[]) => {
            groupContext.setGroupOwners(owners);
            groupContext.setIsGroupOwnersObtained(true);
        },
        onError: () => {
            groupContext.setErrorFetchingOwners('Error getting group owners');
            setHasErrorsOnPage(true);
        },
        onFinally: doNothing,
    });

    useFetchSimple({
        dependencies: [groupContext.groupOwners],
        canPerformFetch: !!groupId && groupContext.groupOwners?.length > 0,
        fetchFunc: async () => {
            const personnelIds = groupContext.groupOwners
                .map((o) => o.personnelId)
                .filter((p) => !groupContext.ownerIsFTE[p]);
            return await GroupClient.isFTE(authContext, personnelIds);
        },
        onSuccess: (ownerIsFTE: Dictionary<boolean>) => {
            groupContext.setOwnerIsFTE((currentValue) => {
                const newValue = { ...currentValue };
                Object.entries(ownerIsFTE).forEach((entry) => {
                    const [key, isTrue] = entry;
                    newValue[key] = isTrue;
                });
                return newValue;
            });
        },
        onError: () => {
            groupContext.setErrorFetchingOwners('Error getting group owners');
            setHasErrorsOnPage(true);
        },
        onFinally: doNothing,
    });

    const fetchGroup = async (): Promise<void> => {
        try {
            const groupResult = await GroupClient.getGroup(authContext, groupId);
            groupContext.setGroup(groupResult);
        } catch (e) {
            if (typeof e === 'string') {
                setErrorFetchingGroup(e);
            } else {
                setErrorFetchingGroup('Error fetching group');
                setHasErrorsOnPage(true);
            }
        }
    };

    useEffect(() => {
        if (redirectTo) {
            setRedirectTo('');
        }
    }, [redirectTo]);

    useEffect(() => {
        if (tab === undefined) {
            setRedirectTo(`/groups/manage/${groupId}/members`);
        } else {
            fetchGroup();
            fetchLinkedSecurityGroups(authContext, groupContext, groupId);
        }
    }, [tab]);

    const onGroupMembersDeleted = (personnelIds: (string | undefined)[]): void => {
        personnelIds.forEach((personnelId) => {
            if (!personnelId) {
                return;
            }
            onGroupMemberDeleted(personnelId);
        });
    };

    const onGroupMemberDeleted = (personnelId: string): void => {
        onDeleteOwner(personnelId);
    };

    const onDeleteOwner = (personnelId: string): void => {
        groupContext.setGroupOwners((currentValue) => {
            const membersMinusDeletedMember = currentValue.filter(
                (member) => member.personnelId !== personnelId,
            );
            return membersMinusDeletedMember;
        });
    };

    if (redirectTo) return <Redirect push={true} to={redirectTo} />;

    return (
        <ProblemLoadingMsg
            problemLoadingMsg={(errorFetchingGroup || groupContext.errorFetchingOwners) ?? ''}>
            <CheckRole
                requiredRolesAny={[Role.GroupAdmin]}
                hasRequiredRolesAny={[
                    groupContext.isAdmin(),
                    groupContext.isAuditor(),
                    groupContext.isOwner(),
                    groupContext.isManager(),
                ]}
                redirectNotInRole={'/groups'} // "/groups" is the page "My Groups"
                arePermissionsChecked={!!groupContext?.groupMembershipVar?.value}>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                <div className={globalStyles.statTilesWrapperFix}>
                    {hasErrorsOnPage && (
                        <>
                            <Spacer marginTop={10} />
                            <MessageBar messageBarType={MessageBarType.error}>
                                Error obtaining some information. Some data could be inaccurate.
                                Refreshing the page may fix that.
                            </MessageBar>
                        </>
                    )}
                    <Tabs selectedKey={tab} onChange={onTabChange}>
                        <TabbedContent key='Members' tabHeader='Members' itemKey={'members'}>
                            {groupId && groupContext.group && (
                                <ManageGroupMembers
                                    groupId={groupId}
                                    group={groupContext.group}
                                    requireSponsor={groupContext.group.requireSponsor}
                                    onGroupMemberDeleted={onGroupMemberDeleted}
                                />
                            )}
                        </TabbedContent>
                        <TabbedContent key='Actions' tabHeader='Actions' itemKey={'actions'}>
                            {groupId && groupContext.group && (
                                <ManageGroupActions
                                    group={groupContext.group}
                                    onGroupMemberDeleted={onGroupMemberDeleted}
                                />
                            )}
                        </TabbedContent>
                        <TabbedContent key='Roles' tabHeader='Roles' itemKey={'roles'}>
                            {groupId && groupContext.group && (
                                <ManageGroupRoles
                                    group={groupContext.group}
                                    onRoleDemoted={onDeleteOwner}
                                />
                            )}
                        </TabbedContent>
                        <TabbedContent key='Policy' tabHeader='Policy' itemKey={'policy'}>
                            {groupId && groupContext.group && (
                                <ManageGroupPolicy
                                    group={groupContext.group}
                                    onGroupMemberDeleted={onGroupMemberDeleted}
                                />
                            )}
                        </TabbedContent>
                        <TabbedContent key='Settings' tabHeader='Settings' itemKey={'settings'}>
                            {groupId && groupContext.group && (
                                <ManageGroupSettings
                                    fetchGroup={fetchGroup}
                                    onGroupMembersDeleted={onGroupMembersDeleted}
                                />
                            )}
                        </TabbedContent>
                        {isCoreActivityServiceEnabled && (
                            <TabbedContent key='Timeline' tabHeader='Timeline' itemKey={'timeline'}>
                                {groupId && groupContext.group && (
                                    <ManageGroupTimeline
                                        groupId={groupId}
                                        group={groupContext.group}
                                    />
                                )}
                            </TabbedContent>
                        )}
                    </Tabs>
                </div>
            </CheckRole>
        </ProblemLoadingMsg>
    );
}

export default ManageGroup;
