import React, { ReactElement, ReactNode } from 'react';
import { IPivotStyles, Pivot, PivotItem } from '@fluentui/react';
import Spacer from 'components/common/spacer';

/**
 * This is a general purpose component to implement "tabs" using Fluent UI <Pivot>
 * You can use it in either of the following ways:
 *
 * Mehod 1) Pass content of each tab as a child to component <Tabs>.
 *
 *      If you pass your own component as children, make sure they each have
 *      a prop named "tabHeader".
 *
 *      <Tabs>
 *          <ChildComponent1 tabHeader='Tab 1 Header'>
 *              Contents of first tab
 *          </ChildComponent1>
 *          <ChildComponent2 tabHeader='Tab 2 Header'>
 *              Contents of second tab
 *          </ChildComponent2>
 *          <ChildComponent3 tabHeader='Tab 3 Header'>
 *              Contents of third tab
 *          </ChildComponent3>
 *      </Tabs>
 *
 *      Or you may use the component <TabbedContent> if you wish. It already
 *      has a mandatory prop named "tabHeader".
 *
 *          <Tabs>
 *              <TabbedContent tabHeader='Tab 1 Header'>
 *                  <ChildComponent1>
 *                      Contents of first tab
 *                  </ChildComponent1>
 *              </TabbedContent>
 *              <TabbedContent tabHeader='Tab 2 Header'>
 *                  <ChildComponent2>
 *                      Contents of second tab
 *                  </ChildComponent2>
 *              </TabbedContent>
 *              <TabbedContent tabHeader='Tab 3 Header'>
 *                  <ChildComponent3>
 *                      Contents of third tab
 *                  </ChildComponent3>
 *              </TabbedContent>
 *          <Tabs>
 *
 * Mehod 2) Pass content of tabs through prop "contents".
 *
 *      <Tabs contents={[
 *              {
 *                  tabHeader: 'Tab 1 Header',
 *                  content: <ChildComponent1> Contents of the first tab </ChildComponent1>,
 *              },
 *              {
 *                  tabHeader: 'Tab 2 Header',
 *                  content: <ChildComponent2> Contents of the second tab </ChildComponent2>,
 *              },
 *              {
 *                  tabHeader: 'Tab 3 Header',
 *                  content: <ChildComponent3> Contents of the third tab </ChildComponent3>,
 *              },
 *           ]}
 *      />
 */

interface ITabsPropsWithChildren {
    children: ReactElement | ReactElement[];
    tabHeader: string;
    contents: never;
}

interface ITabsPropsWithContents {
    children: never;
    contents: {
        tabHeader: string;
        content: ReactNode;
    }[];
}

interface ITabsProps {
    children?: ReactNode | ReactNode[];
    contents?: {
        tabHeader: string;
        content: ReactNode | ReactNode[];
    }[];
    defaultSelectedKey?: string;
    selectedKey?: string;
    pivotStyle?: Partial<IPivotStyles>;
    onChange?: (selectedKey?: string) => void;
}

export default function Tabs(props: ITabsProps): JSX.Element {
    return (
        <Pivot
            defaultSelectedKey={props.defaultSelectedKey ?? '0'}
            selectedKey={props.selectedKey}
            styles={props.pivotStyle}
            onLinkClick={
                props.onChange
                    ? (item): void => {
                          if (item && item.props && props.onChange)
                              props.onChange(item.props.itemKey);
                      }
                    : undefined
            }>
            {props.children !== undefined
                ? renderChildTabs(props as ITabsPropsWithChildren)
                : renderPropTabs(props as ITabsPropsWithContents)}
        </Pivot>
    );
}

const renderChildTabs = (props: ITabsPropsWithChildren): ReactNode | ReactNode[] => {
    const pivotItem = (child: ReactElement, i: number): JSX.Element => (
        <PivotItem
            key={i}
            itemKey={child?.props?.itemKey ?? i.toString()}
            headerText={child?.props?.tabHeader}
            headerButtonProps={i === 0 ? { 'data-order': 1 } : {}}>
            {child}
        </PivotItem>
    );
    if (Array.isArray(props.children)) {
        return props.children.filter((x) => x).map((child, i) => pivotItem(child, i));
    } else {
        return pivotItem(props.children, 0);
    }
};

const renderPropTabs = (props: ITabsPropsWithContents): JSX.Element[] => {
    return props.contents.map((content, i) => {
        return (
            <PivotItem
                key={i}
                itemKey={i.toString()}
                headerText={content.tabHeader}
                headerButtonProps={i === 0 ? { 'data-order': 1 } : {}}>
                {content.content}
            </PivotItem>
        );
    });
};

/**
 * TabbedContent provides a component with a prop named "tabHeader"
 * so that the coder can insert a <div> or any react element in it without worrying
 * that typescript will complain that that component doesn't have a prop named
 * "tabHeader".
 *
 * If the prop "itemKey" is specified, it will drive the prop itemKey of the
 * corresponding PivotItem instance.
 *
 * The additional prop "[key:string] any" is added below just in case a coder needs
 * to pass a prop that this code cannot think of and predict at this moment. In
 * other words, if for any reason the coder needs to pass any other prop to the
 * instance of this component, they can.
 */
interface ITabbedContent {
    tabHeader: string;
    itemKey?: string;
    children: ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export function TabbedContent(props: ITabbedContent): JSX.Element {
    return <>{props.children}</>;
}

export function TabsContentsSpacer(): JSX.Element {
    return <Spacer marginTop={9} />;
}
