import config from 'environments/environment';
import { GetAadHttpOptions, IPagedResults, JSON_CONTENT_TYPE } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import ClientUtils from 'clients/client-utils';
import { IEmployee } from 'clients/employee-client';
import { IUserContext } from 'contexts/user-context';

const cloudScreeningConfig = config.cloudScreeningServiceConfig;

let cachedUserTypesPromise: Promise<string[]>;
class CloudScreeningClient {
    static maxScopePersonnelIdLength = 200;

    static get cloudScreeningBaseUrl(): string {
        return `${cloudScreeningConfig.baseUrl}`;
    }

    static async getAllMyScreenings(authContext: IAuthContext): Promise<ICloudScreening[]> {
        let ret: ICloudScreening[] = [];
        let continuationToken = '';

        do {
            const pagedResult = await this.getMyScreenings(authContext, continuationToken);
            ret = ret.concat(pagedResult.results);
            continuationToken = pagedResult.continuationToken ?? '';
        } while (continuationToken);

        return ret;
    }

    static async getMyScreenings(
        authContext: IAuthContext,
        continuationToken?: string,
    ): Promise<IPagedResults<ICloudScreening>> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            cloudScreeningConfig.aadScopes,
            JSON_CONTENT_TYPE,
            continuationToken,
        );
        const url = `${this.cloudScreeningBaseUrl}${cloudScreeningConfig.myScreeningsEndpoint}`;
        return ClientUtils.doHttpRequest<IPagedResults<ICloudScreening>>(url, httpOptions);
    }

    static async getUserTypes(authContext: IAuthContext): Promise<string[]> {
        if (cachedUserTypesPromise) {
            return cachedUserTypesPromise;
        }

        cachedUserTypesPromise = (async (): Promise<string[]> => {
            const httpOptions = await GetAadHttpOptions(
                authContext,
                cloudScreeningConfig.aadScopes,
            );
            const url = `${this.cloudScreeningBaseUrl}${cloudScreeningConfig.userTypesEndpoint}`;
            httpOptions.method = 'GET';
            const result = await fetch(url, httpOptions);
            switch (result.status) {
                case 200:
                    return result.json();
                case 404:
                    throw `Failed to get Cloud Screening User Type`;
                default:
                    throw result;
            }
        })();
        return cachedUserTypesPromise;
    }

    static async createBackgroundCheckRequest(
        authContext: IAuthContext,
        personnelId: string,
        featureEnabled: boolean,
    ): Promise<void> {
        if (!featureEnabled) {
            return;
        }
        const httpOptions = await GetAadHttpOptions(authContext, cloudScreeningConfig.aadScopes);
        const url = `${this.cloudScreeningBaseUrl}${cloudScreeningConfig.backgroundCheckEndpoint}`;
        httpOptions.method = 'POST';
        const request = {
            personnelId: personnelId,
        };
        httpOptions.body = JSON.stringify(request);
        const response = await fetch(url, httpOptions);
        switch (response.status) {
            // Background check request was successfully created.
            case 201:
            // Country not supported by HireRight API. Background check request was NOT created.
            // Don't flag as an error. This is normal condition.
            case 204:
            // A request is already underway for the specified employee.
            // Background check request was NOT created.
            case 409:
                return;
            default:
                throw response;
        }
    }

    static async createScreening(
        authContext: IAuthContext,
        personnelId: string,
        scopes: string[],
    ): Promise<ICloudScreening> {
        const httpOptions = await GetAadHttpOptions(authContext, cloudScreeningConfig.aadScopes);
        const url = `${this.cloudScreeningBaseUrl}${cloudScreeningConfig.screeningEndpoint}`;

        httpOptions.method = 'POST';
        const request: ICreateCloudScreeningRequest = {
            personnelId: personnelId,
            scopes: scopes,
        };
        httpOptions.body = JSON.stringify(request);
        return ClientUtils.doHttpRequest<ICloudScreening>(url, httpOptions);
    }

    static async createDelegateAssignment(
        authContext: IAuthContext,
        delegateId: string,
        managerId: string,
    ): Promise<IDelegateAssignment> {
        const httpOptions = await GetAadHttpOptions(authContext, cloudScreeningConfig.aadScopes);
        const url = `${this.cloudScreeningBaseUrl}${cloudScreeningConfig.addDelegateAssignment}`
            .replace('{delegateId}', delegateId)
            .replace('{managerId}', managerId);
        httpOptions.method = 'POST';
        return ClientUtils.doHttpRequest<IDelegateAssignment>(url, httpOptions);
    }
    static async deleteDelegateAssignment(
        authContext: IAuthContext,
        delegateId: string,
        managerId: string,
    ): Promise<void> {
        const httpOptions = await GetAadHttpOptions(authContext, cloudScreeningConfig.aadScopes);
        const url = `${this.cloudScreeningBaseUrl}${cloudScreeningConfig.deleteDelegateAssignment}`
            .replace('{delegateId}', delegateId)
            .replace('{managerId}', managerId);
        httpOptions.method = 'DELETE';
        return ClientUtils.doHttpRequest<void>(url, httpOptions);
    }

    static async getDelegateAssignmentsByManagerId(
        authContext: IAuthContext,
        managerId: string,
    ): Promise<IDelegateAssignment[]> {
        let ret: IDelegateAssignment[] = [];
        let continuationToken = '';
        const url = `${this.cloudScreeningBaseUrl}${cloudScreeningConfig.assignmentsByManagerId}`.replace(
            '{personnelId}',
            managerId,
        );

        do {
            const httpOptions = await GetAadHttpOptions(
                authContext,
                cloudScreeningConfig.aadScopes,
                JSON_CONTENT_TYPE,
                continuationToken,
            );
            const pagedResult = await ClientUtils.doHttpRequest<IPagedResults<IDelegateAssignment>>(
                url,
                httpOptions,
            );
            ret = ret.concat(pagedResult.results);
            continuationToken = pagedResult.continuationToken ?? '';
        } while (continuationToken);

        return ret;
    }

    static async getDelegateAssignmentsByDelegateId(
        authContext: IAuthContext,
        delegateId: string,
    ): Promise<IDelegateAssignment[]> {
        let ret: IDelegateAssignment[] = [];
        let continuationToken = '';
        const url = `${this.cloudScreeningBaseUrl}${cloudScreeningConfig.assignmentsByDelegateId}`.replace(
            '{personnelId}',
            delegateId,
        );

        do {
            const httpOptions = await GetAadHttpOptions(
                authContext,
                cloudScreeningConfig.aadScopes,
                JSON_CONTENT_TYPE,
                continuationToken,
            );
            const pagedResult = await ClientUtils.doHttpRequest<IPagedResults<IDelegateAssignment>>(
                url,
                httpOptions,
            );
            ret = ret.concat(pagedResult.results);
            continuationToken = pagedResult.continuationToken ?? '';
        } while (continuationToken);

        return ret;
    }

    static async getDirectReports(authContext: IAuthContext, id: string): Promise<IEmployee[]> {
        const httpOptions = await GetAadHttpOptions(authContext, cloudScreeningConfig.aadScopes);
        const url =
            this.cloudScreeningBaseUrl + cloudScreeningConfig.directReports.replace('{id}', id);
        return ClientUtils.doHttpRequest(url, httpOptions);
    }

    static async getAllReports(authContext: IAuthContext, id: string): Promise<IEmployee[]> {
        let ret: IEmployee[] = [];
        let continuationToken = '';

        do {
            const pagedResult = await this._getAllReportsHelper(authContext, id, continuationToken);
            ret = ret.concat(pagedResult.results);
            continuationToken = pagedResult.continuationToken ?? '';
        } while (continuationToken);

        return ret;
    }

    private static async _getAllReportsHelper(
        authContext: IAuthContext,
        id: string,
        continuationToken?: string,
    ): Promise<IPagedResults<IEmployee>> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            cloudScreeningConfig.aadScopes,
            JSON_CONTENT_TYPE,
            continuationToken,
        );

        const url =
            this.cloudScreeningBaseUrl + cloudScreeningConfig.allReports.replace('{id}', id);
        return ClientUtils.doHttpRequest(url, httpOptions);
    }

    static async getAllScreeningsByManagerId(
        authContext: IAuthContext,
        managerId: string,
    ): Promise<ICloudScreening[]> {
        let ret: ICloudScreening[] = [];
        let continuationToken = '';

        do {
            const pagedResult = await this.getScreeningsByManagerId(
                authContext,
                managerId,
                continuationToken,
            );
            ret = ret.concat(pagedResult.results);
            continuationToken = pagedResult.continuationToken ?? '';
        } while (continuationToken);

        return ret;
    }

    static async getScreeningsByManagerId(
        authContext: IAuthContext,
        managerId: string,
        continuationToken?: string,
    ): Promise<IPagedResults<ICloudScreening>> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            cloudScreeningConfig.aadScopes,
            JSON_CONTENT_TYPE,
            continuationToken,
        );

        const url = `${this.cloudScreeningBaseUrl}${cloudScreeningConfig.reportScreenings}`.replace(
            '{managerId}',
            managerId,
        );
        return ClientUtils.doHttpRequest(url, httpOptions);
    }

    static async postManagerReminder(
        authContext: IAuthContext,
        alias: string,
    ): Promise<ICloudScreening> {
        const httpOptions = await GetAadHttpOptions(authContext, cloudScreeningConfig.aadScopes);
        httpOptions.method = 'POST';
        const url = `${this.cloudScreeningBaseUrl}${cloudScreeningConfig.managerReminder}`.replace(
            '{alias}',
            alias,
        );
        return ClientUtils.doHttpRequest(url, httpOptions);
    }

    static async getRequestedScreeningsByRegionAndDate(
        authContext: IAuthContext,
        region: keyof typeof CloudScreeningRegion,
        forDate: Date,
        continuationToken?: string,
    ): Promise<IPagedResults<ICloudScreening>> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            cloudScreeningConfig.aadScopes,
            JSON_CONTENT_TYPE,
            continuationToken,
        );

        const url = new URL(
            `${this.cloudScreeningBaseUrl}${cloudScreeningConfig.requestedRegionalScreeningsEndpoint}`.replace(
                '{region}',
                region,
            ),
        );
        url.searchParams.set('month', (forDate.getMonth() + 1).toString());
        url.searchParams.set('day', forDate.getDate().toString());
        url.searchParams.set('year', forDate.getFullYear().toString());

        return ClientUtils.doHttpRequest(url.toString(), httpOptions);
    }

    static async getMonthlyReport(
        authContext: IAuthContext,
        forDate: Date,
    ): Promise<ICloudScreeningMonthlyReport> {
        const httpOptions = await GetAadHttpOptions(authContext, cloudScreeningConfig.aadScopes);
        const url = new URL(this.cloudScreeningBaseUrl + cloudScreeningConfig.monthlyReport);
        url.searchParams.set('month', (forDate.getMonth() + 1).toString());
        url.searchParams.set('day', forDate.getDate().toString());
        url.searchParams.set('year', forDate.getFullYear().toString());

        return ClientUtils.doHttpRequest(url.toString(), httpOptions);
    }

    static async getScopesForManager(
        authContext: IAuthContext,
        managerId: string,
        personnelIds: string[],
        continuationToken?: string,
    ): Promise<IPagedResults<IScopeRecord>> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            cloudScreeningConfig.aadScopes,
            JSON_CONTENT_TYPE,
            continuationToken,
        );
        httpOptions.method = 'POST';
        const url =
            this.cloudScreeningBaseUrl +
            cloudScreeningConfig.scopesForManager.replace('{managerId}', managerId);
        const toNumbers = personnelIds.map((x) => parseInt(x));
        httpOptions.body = JSON.stringify(toNumbers);
        return ClientUtils.doHttpRequest<IPagedResults<IScopeRecord>>(url, httpOptions);
    }

    static async getMyScopes(
        authContext: IAuthContext,
        userContext: IUserContext,
        continuationToken?: string,
    ): Promise<IPagedResults<IScopeRecord>> {
        return this.getScopesForManager(
            authContext,
            userContext.employeeRecord.id,
            [userContext.employeeRecord.id],
            continuationToken,
        );
    }

    static async getAllAllowLists(authContext: IAuthContext): Promise<ICloudScreeningAllowList[]> {
        const httpOptions = await GetAadHttpOptions(authContext, cloudScreeningConfig.aadScopes);
        const url = this.cloudScreeningBaseUrl + cloudScreeningConfig.allowLists;
        return ClientUtils.doHttpRequest(url, httpOptions);
    }

    static async deleteAllowList(authContext: IAuthContext, managerId: string): Promise<void> {
        const httpOptions = await GetAadHttpOptions(authContext, cloudScreeningConfig.aadScopes);
        const url = `${this.cloudScreeningBaseUrl}${cloudScreeningConfig.deleteAllowList}`.replace(
            '{managerId}',
            managerId,
        );
        httpOptions.method = 'DELETE';
        return ClientUtils.doHttpRequest<void>(url, httpOptions);
    }

    static async addAllowList(authContext: IAuthContext, managerId: string, scopeName?: string) {
        const httpOptions = await GetAadHttpOptions(authContext, cloudScreeningConfig.aadScopes);
        httpOptions.method = 'POST';
        const url = this.cloudScreeningBaseUrl + cloudScreeningConfig.allowLists;
        const request: ICloudScreeningAllowListRequest = {
            managerId,
            scopeName,
        };
        httpOptions.body = JSON.stringify([request]);
        return ClientUtils.doHttpRequest<void>(url, httpOptions);
    }
}

export default CloudScreeningClient;

export interface ICloudScreeningMonthlyReportItem {
    region: string;
    count: number;
}

export interface ICloudScreeningMonthlyReport {
    items: ICloudScreeningMonthlyReportItem[];
}

interface ICloudScreeningAllowListRequest {
    managerId: string;
    scopeName?: string;
}

export interface ICloudScreeningAllowList {
    id: string;
    managerId: string;
    scopeName: string;
    lastModified: ITimestampChange;
    lastOperation: string;
}

export interface IDelegateAssignment {
    id: string;
    delegatePersonnelId: string;
    managerPersonnelId: string;
}

export interface IScreeningBase {
    id: string;
    personnelId: string;
    state: string;
    screeningType: string;
    dateCreated: ITimestampChange;
    statusChanged: ITimestampChange;
}

export interface ICloudScreening extends IScreeningBase {
    dateCompleted: ITimestampChange | null;
    dateExpires: ITimestampChange | null;
    dateManagerReminderSent: ITimestampChange | null;
    scopes: string[];
    firstName: string;
    lastName: string;
    personnelEmail: string;
    personnelAlias: string;
    orgLeaderId: number;
    country: string;
    region: string;
}

export interface ICloudScreeningPagedResult {
    results: ICloudScreening[];
    continuationToken: string;
}

export interface ITimestampChange {
    by: string;
    atUtc: number;
}

export enum CloudScreeningRegion {
    US,
    AMERICAS,
    EMEA,
    APAC,
}

export interface IScopeRecord {
    id: string;
    personnelId: string;
    scope: string;
}

export interface ICreateCloudScreeningRequest {
    personnelId: string;
    scopes: string[];
}
