import React, { ReactNode } from 'react';
import { IColumn } from '@fluentui/react';
import { IClearanceRecord } from 'clients/clearance-client';
import { dateToLocalDate, unixTimeStampToFormattedDateString } from 'utils/time-utils';
import { TableCell } from 'components/common/table';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import {
    ClearanceStatuses,
    ClearanceLevelsShortHand,
} from 'components/personnel-profile/clearance/profile-clearance-constants';
import EllipsisText from 'components/common/ellipsis-text';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/clearance/profile-clearance-constants';
import { ISortableColumns } from 'utils/sort-utils';

interface IClearances extends ISortableColumns {
    contractMap: Map<string, string>;
    isExpandButtonClicked: boolean;
}

export function getClearanceRecordsTableColumns(args: IClearances): IColumn[] {
    const {
        contractMap,
        isExpandButtonClicked,
        sortColumn,
        sortAscending: isSortedAscending,
        sortColumnHandler,
    } = args;

    return [
        {
            key: 'personnelId',
            name: 'Name',
            ariaLabel: 'Name',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            onRender: (row: IClearanceRecord): JSX.Element => {
                return (
                    <TableCell key={row?.personnelId}>
                        {renderCorrectClearanceRecordsColumnOption(row)}
                    </TableCell>
                );
            },
            isSorted: sortColumn === 'Name',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Name');
            },
        },
        {
            // if we decide to use the same ui as the profile/us-gov/{id}:
            // clearanceStringStatusSpan components\personnel-profile\clearance\profile-clearance-constants.tsx
            key: 'level',
            name: 'Level',
            ariaLabel: 'Clearance Level',
            minWidth: isExpandButtonClicked ? 50 : 65,
            maxWidth: isExpandButtonClicked ? 85 : 65,
            onRender: (row: IClearanceRecord): JSX.Element => {
                const clearanceShortHand = ClearanceLevelsShortHand[row.level] || row.level;
                return (
                    <TableCell>
                        <EllipsisText
                            text={clearanceShortHand}
                            textLengthBeforeEllipsis={isExpandButtonClicked ? 14 : 9}
                        />
                    </TableCell>
                );
            },
            isSorted: sortColumn === 'Level',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Level');
            },
        },
        {
            key: 'clearanceType',
            name: 'Type',
            ariaLabel: 'Clearance Type',
            minWidth: isExpandButtonClicked ? 50 : 65,
            maxWidth: isExpandButtonClicked ? 95 : 65,
            onRender: (row: IClearanceRecord): JSX.Element => {
                // const clearanceShortHand = ClearanceLevelsShortHand[row.level] || row.level;
                return (
                    <TableCell>
                        <EllipsisText
                            text={row.clearanceType}
                            textLengthBeforeEllipsis={isExpandButtonClicked ? 17 : 9}
                        />
                    </TableCell>
                );
            },
            isSorted: sortColumn === 'Type',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Type');
            },
        },
        {
            key: 'status',
            name: 'Status',
            ariaLabel: 'Status',
            minWidth: isExpandButtonClicked ? 75 : 80,
            maxWidth: isExpandButtonClicked ? 155 : 80,
            onRender: (row: IClearanceRecord): JSX.Element => {
                const status = ClearanceStatuses[row.status] || row.status;
                return (
                    <TableCell>
                        <EllipsisText
                            text={status}
                            textLengthBeforeEllipsis={isExpandButtonClicked ? 23 : 10}
                        />
                    </TableCell>
                );
            },
            isSorted: sortColumn === 'Status',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Status');
            },
        },
        {
            key: 'contractId',
            name: 'Contract ID',
            ariaLabel: 'Contract ID',
            minWidth: 115,
            maxWidth: 120,
            isCollapsible: !isExpandButtonClicked,
            onRender: (row: IClearanceRecord): JSX.Element => {
                return <TableCell>{row.contractId}</TableCell>;
            },
            isSorted: sortColumn === 'Contract ID',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Contract ID');
            },
        },
        {
            key: 'projectName',
            name: 'Project Name',
            ariaLabel: 'Project Name',
            minWidth: 90,
            maxWidth: 120,
            onRender: (row: IClearanceRecord): JSX.Element => {
                const projectName = contractMap.get(row.contractId || '') || '';
                return <TableCell>{projectName}</TableCell>;
            },
            isSorted: sortColumn === 'Project Name',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Project Name');
            },
        },
        {
            key: 'agency',
            name: 'Agency',
            ariaLabel: 'Agency',
            minWidth: 50,
            maxWidth: isExpandButtonClicked ? 75 : 50,
            isCollapsible: !isExpandButtonClicked,
            onRender: (row: IClearanceRecord): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisText
                            text={row.agency ? getAgencyEnumValueFromKey(row.agency) : ''}
                            textLengthBeforeEllipsis={isExpandButtonClicked ? 9 : 6}
                        />
                    </TableCell>
                );
            },
            isSorted: sortColumn === 'Agency',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Agency');
            },
        },
        {
            key: 'clearanceExpirationDateTimeUTCMilliseconds',
            name: 'Expires on',
            ariaLabel: 'Clearance Expires on',
            minWidth: 105,
            maxWidth: 120,
            onRender: (row: IClearanceRecord): ReactNode => {
                return row.clearanceExpirationDateTimeUTCMilliseconds ? (
                    <TableCell>
                        {dateToLocalDate(row.clearanceExpirationDateTimeUTCMilliseconds)}
                    </TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === 'Expires on',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Expires on');
            },
        },
        {
            key: 'grantedDate',
            name: 'Granted on',
            ariaLabel: 'Granted on',
            minWidth: 105,
            maxWidth: 120,
            onRender: (row: IClearanceRecord): ReactNode => {
                return row.grantDateUTCMilliseconds ? (
                    <TableCell>{dateToLocalDate(row.grantDateUTCMilliseconds)}</TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === 'Granted on',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Granted on');
            },
        },

        {
            key: 'investigatedDate',
            name: 'Investigated on',
            ariaLabel: 'Investigated on',
            minWidth: 132,
            maxWidth: 132,
            isCollapsible: !isExpandButtonClicked,
            onRender: (row: IClearanceRecord): ReactNode => {
                return row.investigationDateUTCMilliseconds ? (
                    <TableCell>{dateToLocalDate(row.investigationDateUTCMilliseconds)}</TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === 'Investigated on',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Investigated on');
            },
        },
        {
            key: 'briefedDate',
            name: 'Briefed on',
            ariaLabel: 'Briefed on',
            minWidth: 80,
            maxWidth: 80,
            isCollapsible: !isExpandButtonClicked,
            onRender: (row: IClearanceRecord): ReactNode => {
                return row.briefDateUTCMilliseconds ? (
                    <TableCell>{dateToLocalDate(row.briefDateUTCMilliseconds)}</TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === 'Briefed on',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Briefed on');
            },
        },
        {
            key: 'debriefedDate',
            name: 'Debriefed on',
            ariaLabel: 'Debriefed on',
            minWidth: 110,
            maxWidth: 110,
            isCollapsible: !isExpandButtonClicked,
            onRender: (row: IClearanceRecord): ReactNode => {
                return row.debriefDateUTCMilliseconds ? (
                    <TableCell>{dateToLocalDate(row.debriefDateUTCMilliseconds)}</TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === 'Debriefed on',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Debriefed on');
            },
        },
        {
            key: 'Continuous evaluation type',
            name: 'Continuous evaluation type',
            ariaLabel: 'Continuous evaluation type',
            minWidth: 115,
            maxWidth: 115,
            onRender: (row: IClearanceRecord): ReactNode => {
                return <TableCell>{row.continuousEvaluationType}</TableCell>;
            },
            isSorted: sortColumn === 'Continuous evaluation type',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Continuous evaluation type');
            },
        },
        {
            key: 'LastModified',
            name: 'Last Modified',
            ariaLabel: 'Last Modified',
            minWidth: 130,
            maxWidth: 130,
            onRender: (row: IClearanceRecord): ReactNode => {
                return row.lastModified ? (
                    <TableCell>
                        {' '}
                        {unixTimeStampToFormattedDateString(row.lastModified.atUtc)}{' '}
                    </TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === 'Last Modified',
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler('Last Modified');
            },
        },
    ] as IColumn[];
}

function renderCorrectClearanceRecordsColumnOption(row: IClearanceRecord): JSX.Element {
    return <EmployeeBasicHoverCard personnelId={row.personnelId} />;
}
