import React, { useContext, useEffect, useState } from 'react';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { IPersonaProps } from '@fluentui/react';
import EmployeeClient from 'clients/employee-client';
import { transformBasicEmployeeToPersona } from 'utils/internal-persona-utils';
import { AuthContext } from 'contexts/auth-context';
import { useCheckMountedState, useIsMounted } from 'utils/misc-hooks';

interface IEmployeePickerParams {
    label?: string;
    disabled?: boolean;
    placeHolder?: string;
    selectedPersona?: IPersonaProps;
    required?: boolean;
    validate?: () => boolean;
    onError?: (msg: string) => void;
}

export interface IInitEmployeePickerParams {
    personnelId?: string[];
}

export interface IUseEmployeePicker {
    values: IPersonaProps[] | undefined;
    isValid: boolean | undefined;
    initialize: (initValue?: IInitEmployeePickerParams) => void;
    theElement: () => JSX.Element;
}

export function useEmployeePickerMultiSelect(params?: IEmployeePickerParams): IUseEmployeePicker {
    const authContext = useContext(AuthContext);
    const [selectedPersonas, setSelectedPersonas] = useState<IPersonaProps[] | undefined>();
    const [isValid, setIsValid] = useCheckMountedState<boolean | undefined>(undefined);

    const isMounted = useIsMounted();

    const onTester = (info?: IPersonaProps[]): void => {
        setSelectedPersonas(info);
    };

    const fetchEmployees = async (personnelId: string[]): Promise<void> => {
        let listOfInitPersonas: IPersonaProps[] = [];
        try {
            const listOfInitEmployees = await EmployeeClient.getBasicEmployeesById(
                authContext,
                personnelId,
            );
            if (!!listOfInitEmployees) {
                if (isMounted()) {
                    listOfInitPersonas = listOfInitEmployees.map(function (e) {
                        return transformBasicEmployeeToPersona(e);
                    });
                }
            } else {
                if (isMounted()) {
                    setSelectedPersonas(undefined);
                }
                reportErrorFetchingEmployee();
            }
        } catch {
            reportErrorFetchingEmployee();
        }
        setSelectedPersonas(listOfInitPersonas);
        function reportErrorFetchingEmployee(): void {
            if (!!params?.onError) {
                params?.onError('Error fetching employee information');
            } else {
                console.error(`Error fetching information for employee ${personnelId}`);
            }
        }
    };

    const initialize = (initValue?: IInitEmployeePickerParams): void => {
        if (!!initValue?.personnelId) {
            fetchEmployees(initValue.personnelId);
        } else {
            setSelectedPersonas(undefined);
        }
    };

    useEffect(() => {
        setIsValid(!!params?.validate ? params.validate() : undefined);
    }, [selectedPersonas, params?.validate]);
    return {
        values: selectedPersonas,
        isValid,
        initialize,
        theElement: () => (
            <EmployeePickerTypeaheadSearch
                label={params?.label ?? ''}
                ariaLabel={params?.label ?? ''}
                disabled={params?.disabled}
                required={params?.required}
                placeHolder='Employee Name or Alias'
                onMultipleCandidatesSelected={onTester}
                selectedItems={selectedPersonas}
                itemLimit={-1}
            />
        ),
    };
}
