import React, { useState, useContext } from 'react';
import Modal from 'components/common/modal';
import StaffingClient from 'clients/staffing-client';
import { AuthContext } from 'contexts/auth-context';
import { TextField } from '@fluentui/react';

interface AddTeamModalProps {
    orgName: string;
    isVisible: boolean;
    addTeamCallback(somethingModified: boolean): void;
}

export default function AddTeamModal(props: AddTeamModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const { orgName, isVisible, addTeamCallback } = props;
    const [teamName, setTeamName] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isAddForcedDisabled, setAddForcedDisabled] = useState(false);
    const [isSubmitInProgress, setIsSubmitInProgress] = useState<boolean>(false);

    const addTeam = async (): Promise<void> => {
        setAddForcedDisabled(true);
        setIsSubmitInProgress(true);
        try {
            await StaffingClient.createTeam(authContext, orgName, {
                name: teamName,
                peopleTarget: 0,
            });
            setTeamName('');
            resetErrorMsg();
            await addTeamCallback(true);
        } catch (e) {
            setErrorMsg('Error occurred. Could not add team.');
        } finally {
            setAddForcedDisabled(false);
            setIsSubmitInProgress(false);
        }
    };

    const cancel = (): void => {
        setTeamName('');
        resetErrorMsg();
        addTeamCallback(false);
    };

    const onTeamNameInput = (a?: React.FormEvent<HTMLElement>, str?: string): void => {
        if (a?.type === 'input') {
            setTeamName(str || '');
        }
    };

    const resetErrorMsg = () => {
        setErrorMsg('');
    };

    const isAddDisabled = () => isAddForcedDisabled || !teamName || !orgName;

    return (
        <Modal
            isOpen={isVisible}
            onCancel={cancel}
            onDismiss={cancel}
            onSubmit={addTeam}
            onResetErrorMsg={resetErrorMsg}
            errorMsg={errorMsg}
            submitButtonText='Add'
            isSubmitButtonDisabled={isAddDisabled()}
            showProgressIndicator={isSubmitInProgress}
            title={'Add Team'}>
            <div>
                <TextField label='Team Name' value={teamName} onChange={onTeamNameInput} />
            </div>
        </Modal>
    );
}
