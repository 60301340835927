import config from 'environments/environment';
import {
    GetDefaultHttpOptions,
    GetMultiPartHttpOptions,
    GetBlobResponseType,
} from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';

const screeningServiceDocumentConfig = config.nominationServiceConfig.document;

class TemplateClient {
    static async getAllRequestableDocuments(
        authContext: IAuthContext,
        screeningId: string,
    ): Promise<IDocumentMetadata[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url =
            screeningServiceDocumentConfig.baseUrl +
            screeningServiceDocumentConfig.requestableDocumentsToRequest +
            screeningId;
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            return res.json();
        } else {
            throw res;
        }
    }

    static async saveRequestableDocument(
        authContext: IAuthContext,
        screeningId: string,
        putDocumentData: IDocumentMetadata,
        file?: File,
    ): Promise<IDocumentMetadata> {
        const formData = new FormData();
        formData.append('metadata', new Blob([JSON.stringify(putDocumentData)]));
        if (putDocumentData.documentType) {
            formData.append('documentType', putDocumentData.documentType);
        }
        if (file) {
            formData.append('file', file);
        }
        const httpOptions = {
            ...(await GetMultiPartHttpOptions(authContext)),
            method: 'POST',
            body: formData,
        };
        const url =
            screeningServiceDocumentConfig.baseUrl +
            screeningServiceDocumentConfig.createRequestableDocument +
            screeningId;
        return await fetch(url, httpOptions)
            .then((response) => response.json())
            .catch((error) => console.error(error));
    }

    static async updateDocument(
        authContext: IAuthContext,
        screeningDocumentRecord: IScreeningTemplateRequestRecord,
    ): Promise<IScreeningTemplateRequestRecord> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url =
            screeningServiceDocumentConfig.baseUrl +
            screeningServiceDocumentConfig.screeningDocumentRequestRecordChange;
        httpOptions.method = 'POST';
        const requests: IScreeningTemplateRequestRecord[] = [screeningDocumentRecord];
        httpOptions.body = JSON.stringify(requests);

        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            return new Promise<IScreeningTemplateRequestRecord>(async (resolve, reject) => {
                const records = (await res.json()) as IScreeningTemplateRequestRecord[];
                if (!!records && records.length === 1) {
                    const record = records[0];
                    resolve(record);
                } else {
                    reject(undefined);
                }
            });
        } else {
            throw res;
        }
    }

    static async updateMultipleDocuments(
        authContext: IAuthContext,
        screeningDocumentRecords: IScreeningTemplateRequestRecord[],
    ): Promise<IScreeningTemplateRequestRecord[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url =
            screeningServiceDocumentConfig.baseUrl +
            screeningServiceDocumentConfig.screeningDocumentRequestRecordChange;
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(screeningDocumentRecords);
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            return res.json();
        } else {
            throw res;
        }
    }

    static async getDocumentRequestedForScreeningId(
        authContext: IAuthContext,
        screeningId: string,
    ): Promise<IScreeningTemplateRequestRecord[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url =
            screeningServiceDocumentConfig.baseUrl +
            screeningServiceDocumentConfig.documentRequestedForScreeningId +
            screeningId;
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            return res.json();
        } else {
            throw res;
        }
    }

    static async deleteDocumentRequest(
        authContext: IAuthContext,
        documentRequestId: string,
    ): Promise<void> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';
        const url =
            screeningServiceDocumentConfig.baseUrl +
            screeningServiceDocumentConfig.documentRequestedForScreeningId +
            documentRequestId +
            '/delete';
        const res = await fetch(url, httpOptions);
        if (res.status !== 200) {
            throw res;
        }
    }

    static async getDocumentMetadataFromDocumentIds(
        authContext: IAuthContext,
        documentIds: Set<string>,
    ): Promise<IDocumentMetadata[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url =
            screeningServiceDocumentConfig.baseUrl +
            screeningServiceDocumentConfig.documentMetadataFromDocumentIds;
        const documentIdsArray: string[] = [];
        documentIds.forEach((x) => documentIdsArray.push(x));
        httpOptions.body = JSON.stringify(documentIdsArray);
        httpOptions.method = 'POST';
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            return res.json();
        } else {
            throw res;
        }
    }

    static async getDocument(authContext: IAuthContext, documentId: string): Promise<Response> {
        const httpOptions = await GetBlobResponseType(authContext);
        const url =
            screeningServiceDocumentConfig.baseUrl +
            screeningServiceDocumentConfig.requestDocument.replace('{id}', documentId);
        const res = await fetch(url, httpOptions);
        return res;
    }
}

export default TemplateClient;

export interface IDocumentMetadata {
    documentType?: string;
    id?: string;
    title: string;
    documentId: string;
    screeningId: string;
    contentType?: string;
    hasFile?: boolean;
    notes?: string;
    authorPersonnelId?: string;
}
// for request document table
export interface IScreeningTemplateRequestRecord {
    id?: string;
    createdDateTime?: string;
    documentId: string;
    documentName?: string;
    documentServiceDocumentId?: string;
    documentType?: string;
    documentTypeName?: string;
    message: string;
    personnelId?: string;
    requesterPersonnelId?: string;
    requestedState: string; // states should map to ScreeningTemplateRequestedState
    screeningId: string;
    title?: string;
    updatedBy?: string;
    updatedDateTime?: string;
}

export function isIScreeningTemplateRequestRecordRequestingUpdate(
    screeningTemplateRequestRecord: IScreeningTemplateRequestRecord,
): boolean {
    return (
        screeningTemplateRequestRecord.requestedState ===
        ScreeningTemplateRequestedState.ReRequestedState
    );
}

export function isIScreeningTemplateRequestRecordOutstanding(
    screeningTemplateRequestRecord: IScreeningTemplateRequestRecord,
): boolean {
    return (
        screeningTemplateRequestRecord.requestedState ===
            ScreeningTemplateRequestedState.RequestedState ||
        screeningTemplateRequestRecord.requestedState ===
            ScreeningTemplateRequestedState.ReRequestedState
    );
}

export enum ScreeningTemplateRequestedState {
    AdHoc = 'ADHOC',
    RequestedState = 'REQUESTED',
    UploadedState = 'UPLOADED',
    ReRequestedState = 'RE_REQUESTED',
    ReRequestedCancel = 'RE_REQUESTED_CANCEL',
    Fulfilled = 'Fulfilled',
}

/**
 * Screening Record -> USGov, PublicTrust
 * Person -> US Gov Profile
 */
export enum AssociationType {
    Person = 'Person',
    ScreeningRecord = 'ScreeningRecord',
}

export enum AssociationValue {
    UsGov = 'USGov',
    PublicTrust = 'PublicTrust',
    USGovProfile = 'USGovProfile',
}
