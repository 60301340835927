import { IDropdownOption, IGroup } from '@fluentui/react';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';

export interface IScreening {
    id: string;
    personnelId: string;
    previousPersonnelIds: string[];
    preHire?: IPreHire;
    nominated: {
        by: string;
        atUtc: number;
    };
    statusChanged?: {
        by: string;
        atUtc: number;
    };
    contact: IContact;
    requestDetails: IRequestDetails;
    governmentContract: string;
    businessJustification: string;
    state: string;
    parentState: string;
    screeningState: string;
    screeningAction: string;
    screeningView: string;
    businessData: IBusinessData;
    processOwnerId: string;
    eqipId: string;
    withdrawalReason: string;
    withdrawalNotes: string;
    withdrawalUtcMillis: number;
    indocTrainingUtcMillis: number;
    indocBriefingUtcMillis: number;
    adjudicatedResult: string;
    adjudicatedUtcMillis: number;
    userType: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isIScreening(object: any): object is IScreening {
    if (!object) {
        return false;
    }
    return (object as IScreening).governmentContract !== undefined;
}

export interface IScreeningResult {
    results: IScreening[];
    continuationToken?: string;
}

export interface IScreeningSearchResult {
    searchResults: {
        results: IScreening[];
        continuationToken?: string;
    };
    totalFilteredRecordsCount: number;
}

export interface IBarChartData {
    barData: Map<string, number>;
    textInfo: string[];
}

export interface IPieChartData {
    label: string;
    percentage: number;
    colorIndex: number;
}

export interface ICustomerMetricsSummaryData {
    total: number;
    groups: IGroup[];
    pieChartData: IPieChartData[];
    processOwnerGroups: IGroup[];
    processOwnerPieChartData: IPieChartData[];
}

export interface IContractOfficer {
    name: string;
    phone: string;
}

export interface IBusinessData {
    billetNumber: string;
    mapping: string;
    milestone: string;
    location: string;
}

export interface IPreHire {
    firstName: string;
    middleName: string;
    lastName: string;
    suffix: string;
    pcn: string;
    manager: string;
    jobTitle: string;
    startDate: Date;
}

export interface IContact {
    homePhone: string;
    homeEmail: string;
    homeAddress: string;
    workEmail?: string;
    workAddress?: string;
}

export interface IRequestDetails {
    requestType: string;
    clearance: string;
    access: string[];
    isCustomerBadgeRequired: boolean;
}

export interface ICreateNominationRequest {
    personnelId: string;
    preHire?: IPreHire;
    contact: IContact;
    requestDetails: IRequestDetails;
    governmentContract: string;
    businessJustification: string;
}

export enum UsGovRequestTypes {
    Initial = 'Initial',
    ContinuousEvaluationContinuousVetting = 'CE/CV',
    ContractChangeOrAdd = 'Contract change/add',
    Crossover = 'Crossover',
    PeriodicReinvestigation = 'Periodic Reinvestigation',
    Reinstatement = 'Reinstatement',
    RequestForAction = 'Request for Action (RFA)',
}

export const US_GOV_REQUEST_TYPE_OPTIONS: IDropdownOption[] = Object.entries(UsGovRequestTypes).map(
    (entries, index) => {
        return {
            key: entries[0],
            text: entries[1],
            index,
        };
    },
);

export enum AdjudicatedSubStates {
    Final = 'Final',
    Interim = 'Interim',
    Denied = 'Denied',
}

export enum AcceptanceOptions {
    Accept = 'Accept',
    Decline = 'Decline',
}

export enum ApprovalOptions {
    Approve = 'Approve',
    Deny = 'Deny',
}

export function isNSTUser(userType: string | undefined): boolean {
    return userType === UsGovScreeningUserType.NST;
}

export function containsNSTUser(userTypes: string[] | undefined): boolean {
    return userTypes?.includes(UsGovScreeningUserType.NST) ?? false;
}

export function isNomineeUser(userType: string): boolean {
    return userType === UsGovScreeningUserType.Nominee;
}

export function containsNomineeUser(userTypes: string[]): boolean {
    return userTypes?.includes(UsGovScreeningUserType.Nominee) ?? false;
}
