import {
    DefaultButton,
    Dialog,
    DialogFooter,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from '@fluentui/react';
import { GetAttributeResult } from 'personnel-core-clients';
import { CoreAttributesClient } from 'clients/core/personnel-core-client-wrappers';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useState } from 'react';

export interface IRemoveAttributeProps {
    attribute: GetAttributeResult | undefined;
    onDismissed: () => void;
    onSuccess: () => void;
}

const DEFAULT_MESSAGE =
    'Caution, once deleted this attribute cannot be recovered. Consider deactivating instead.';

export function RemoveAttributeModal(props: IRemoveAttributeProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState<boolean>(false);
    const [messageType, setMessageType] = useState<MessageBarType>(MessageBarType.warning);
    const [message, setMessage] = useState<string>(DEFAULT_MESSAGE);

    const onClose = async (): Promise<void> => {
        props.onDismissed();
        setMessageType(MessageBarType.warning);
        setMessage(DEFAULT_MESSAGE);
    };

    const deleteAttribute = async (): Promise<void> => {
        try {
            if (props.attribute === undefined) {
                return;
            }

            setIsDeleteConfirmed(true);
            setMessage('Deleting attribute...');
            const attributeClient = new CoreAttributesClient(authContext);
            await attributeClient.delete(props.attribute.id);
            setMessageType(MessageBarType.success);
            setMessage('Attribute has been successfully deleted.');
            props.onSuccess();
        } catch (e) {
            console.log(e);
            setMessageType(MessageBarType.error);
            setMessage('An error occurred deleting the attribute.');
        }
    };

    return (
        <Dialog
            hidden={false}
            onDismiss={props.onDismissed}
            dialogContentProps={{
                onDismiss: onClose,
                title: 'Delete Attribute',
                showCloseButton: true,
            }}
            modalProps={{
                styles: {
                    main: {
                        borderRadius: '8px',
                        width: '580px !important',
                        maxWidth: 'none !important',
                        minWidth: 'none !important',
                    },
                },
            }}>
            <MessageBar messageBarType={messageType}>{message}</MessageBar>
            <div
                style={{
                    paddingTop: 10,
                }}>
                {!isDeleteConfirmed && props.attribute && (
                    <div>
                        Are you sure you want to delete the attribute:
                        <b> {props.attribute.name}</b>?
                    </div>
                )}
            </div>
            <DialogFooter>
                {!isDeleteConfirmed && <PrimaryButton onClick={deleteAttribute} text='Delete' />}
                <DefaultButton onClick={onClose} text='Close' />
            </DialogFooter>
        </Dialog>
    );
}
