import React, { useState, useEffect, useContext } from 'react';
import Modal from 'components/common/modal';
import { IAllocationItem } from 'components/staffing/staffing-page-types';
import { AuthContext } from 'contexts/auth-context';
import StaffingClient, {
    IStaffingTeamResponse,
    IStaffingCloudResponse,
    IStaffingProjectResponse,
    IStaffingCloudStatusResponse,
    IStaffingOrganizationResponse,
} from 'clients/staffing-client';
import {
    Dropdown,
    IDropdownOption,
    IPersonaProps,
    ChoiceGroup,
    IChoiceGroupOption,
    TextField,
    MaskedTextField,
    mergeStyleSets,
} from '@fluentui/react';
import { translateCloudName, ClearanceStatus } from 'components/staffing/staffing-constants';
import deepcopy from 'deepcopy';
import isEqual from 'react-fast-compare';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { Dictionary } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';

interface EditAllocationModalProps {
    title: string;
    action: 'edit' | 'add';
    allocation?: IAllocationItem;
    isVisible: boolean;
    teams: Dictionary<IStaffingTeamResponse[]>;
    clouds: IStaffingCloudResponse[];
    orgId: string;
    orgName: string;
    projects: IStaffingProjectResponse[];
    organizations: IStaffingOrganizationResponse[];
    editAllocationCallback: (somethingModified: boolean) => void;
}

interface IEditedAllocationItem extends IAllocationItem {
    FuturePCN?: string;
}

enum allocationConversionKeyType {
    Prehire = 'prehire',
    ConvertEmployee = 'convertemployee',
}

export default function EditAddAllocationModal(props: EditAllocationModalProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const { isVisible, editAllocationCallback, teams, title, allocation, clouds, action } = props;
    const [editedAllocation, setEditedAllocation] = useState({} as IEditedAllocationItem);
    const [isSubmitForceDisabled, setForceSubmitDisabled] = useState(false);
    const [orgsOptions, setOrgsOptions] = useState<IDropdownOption[]>([]);
    const [cloudsOptions, setCloudsOptions] = useState<IChoiceGroupOption[]>([]);
    const [cloudStatus, setCloudStatus] = useState((undefined as unknown) as ClearanceStatus);
    const [selectedOrg, setSelectedOrg] = useState<IDropdownOption>();
    const [selectedTeam, setSelectedTeam] = useState<IDropdownOption>();
    const [selectedCloud, setSelectedCloud] = useState<IChoiceGroupOption>();
    const [allocationType, setAllocationType] = useState<allocationKeyType>('existing');
    // eslint-disable-next-line
    const [allocationConversionType, setAllocationConversionType] = useState<
        allocationConversionKeyType
    >(allocationConversionKeyType.Prehire);
    const [isSubmitInProgress, setIsSubmitInProgress] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState('');

    const both = { key: 'both', text: 'Both' };

    type allocationKeyType = 'existing' | 'future';
    const allocationTypeOptions = [
        { key: 'existing' as allocationKeyType, text: 'Existing Employee' },
        { key: 'future' as allocationKeyType, text: 'Future Position' },
    ];

    const allocationConversionTypeOptions = [
        {
            key: allocationConversionKeyType.Prehire as allocationConversionKeyType,
            text: 'Prehire',
        },
        {
            key: allocationConversionKeyType.ConvertEmployee as allocationConversionKeyType,
            text: 'Convert to Employee',
        },
    ];

    const addAction = (): boolean => action === 'add';
    const editAction = (): boolean => action === 'edit';
    const addFuturePosition = (): boolean => allocationType === 'future';
    const addExistingEmployee = (): boolean => allocationType === 'existing';
    const convertToEmployee = (): boolean =>
        allocationConversionType === allocationConversionKeyType.ConvertEmployee;
    const convertToPrehire = (): boolean =>
        allocationConversionType === allocationConversionKeyType.Prehire;

    useEffect(() => {
        const edited = deepcopy(allocation) as IAllocationItem;
        setCloudStatus((allocation?.clouds || [])[0]?.status);
        if (isVisible) {
            setEditedAllocation({
                ...edited,
                organizationName: props.orgName,
            });
            setSelectedOrg({ key: props.orgId, text: props.orgName });
            if (allocation?.personnelId) {
                setAllocationConversionType(allocationConversionKeyType.ConvertEmployee);
            } else {
                setAllocationConversionType(allocationConversionKeyType.Prehire);
            }
        } else {
            setSelectedOrg(undefined);
            setSelectedTeam(undefined);
            setSelectedCloud(undefined);
            setAllocationType('existing');
            setEditedAllocation({} as IAllocationItem);
            // Make sure upon becoming invisible, this component
            // enables submit button so that it's ready for the
            // next time it becomes visible.
            setForceSubmitDisabled(false);
        }
    }, [isVisible, allocation]);

    const teamOptions = (orgName: string): IDropdownOption[] => {
        const organization = props.organizations.find((o) => o.name === orgName);
        return (teams[organization?.id ?? ''] || []).map((team: IStaffingTeamResponse) => ({
            key: team.name,
            text: team.name,
        }));
    };

    useEffect(() => {
        setOrgsOptions(
            props.organizations.map((organization: IStaffingOrganizationResponse) => ({
                key: organization.id,
                text: organization.name,
            })),
        );
    }, [props.organizations]);

    useEffect(() => {
        const bothOptions = [both] as IChoiceGroupOption[];
        const cloudsOptions = clouds.map((cloud) => ({
            key: cloud.name,
            text: translateCloudName(cloud.name),
        })) as IChoiceGroupOption[];
        setCloudsOptions(bothOptions.concat(cloudsOptions));
    }, [clouds, allocation]);

    useEffect(() => {
        if (editAction()) {
            const organization = props.organizations.find(
                (organization) =>
                    organization.id === allocation?.organizationName?.toLocaleLowerCase(),
            );
            if (organization) {
                setSelectedOrg({ key: organization.id, text: organization.name });
            }
            const team = (teams[organization?.id ?? ''] ?? []).find(
                (team) => team.name === allocation?.team,
            );
            if (team) {
                setSelectedTeam({ key: team.name, text: team.name });
            }
        }
    }, [isVisible, allocation, teams]);

    useEffect(() => {
        if (editAction()) {
            switch (allocation?.clouds?.length) {
                case 0:
                    setSelectedCloud(undefined);
                    break;
                case 1:
                    const name = allocation?.clouds[0]?.name;
                    setSelectedCloud({ key: name, text: name });
                    break;
                case 2:
                    setSelectedCloud(both);
                    break;
                default:
                    break;
            }
        }
    }, [isVisible, allocation, clouds, action]);

    const cancel = (): void => {
        resetErrorMsg();
        editAllocationCallback(false);
    };

    const selectOrganization = (
        a: React.FormEvent<HTMLDivElement>,
        orgName?: IDropdownOption,
    ): void => {
        if (a.type === 'click') {
            setSelectedOrg(orgName);
            setSelectedTeam(undefined);
            setEditedAllocation({
                ...editedAllocation,
                organizationName: orgName?.text || '',
                team: '',
            });
        }
    };

    const selectTeam = (a: React.FormEvent<HTMLDivElement>, team?: IDropdownOption): void => {
        if (a.type === 'click') {
            setSelectedTeam(team);
            setEditedAllocation({
                ...editedAllocation,
                team: team?.text || '',
            });
        }
    };

    const selectCloudHandler = (
        a?: React.FormEvent<HTMLElement>,
        cloud?: IChoiceGroupOption,
    ): void => {
        selectCloud(cloud);
    };

    const selectCloud = (cloud?: IChoiceGroupOption): void => {
        setSelectedCloud(cloud);
        let updatedClouds;
        if (cloud) {
            switch (cloud.key) {
                case 'both':
                    updatedClouds = clouds as IStaffingCloudStatusResponse[];
                    updatedClouds.forEach((thisOne) => {
                        thisOne.status = cloudStatus;
                    });
                    break;
                default:
                    const c = clouds.find((availClouds) => availClouds.name === cloud.key);
                    updatedClouds = c?.name ? [{ status: cloudStatus, name: c?.name }] : [];
                    break;
            }
            setEditedAllocation({
                ...editedAllocation,
                clouds: updatedClouds,
            });
        }
    };

    const selectAllocationType = (
        a?: React.FormEvent<HTMLElement>,
        type?: IChoiceGroupOption,
    ): void => {
        if (a?.type === 'change') {
            switch (type?.key) {
                case 'existing':
                case 'future':
                    setAllocationType(type.key);
                    break;
                default:
                    break;
            }
        }
    };

    const selectAllocationConversionType = (
        a?: React.FormEvent<HTMLElement>,
        type?: IChoiceGroupOption,
    ): void => {
        if (a?.type === 'change') {
            switch (type?.key) {
                case allocationConversionKeyType.Prehire:
                    setEditedAllocation({
                        ...editedAllocation,
                        personnelId: '',
                    });
                    setAllocationConversionType(type.key);
                    break;
                case allocationConversionKeyType.ConvertEmployee:
                    setEditedAllocation({
                        ...editedAllocation,
                        preHireName: '',
                    });
                    setAllocationConversionType(type.key);
                    break;
                default:
                    console.log(
                        'Reached default case for Allocation Conversion type change. Value: ' +
                            type?.key,
                    );
                    break;
            }
        }
    };

    const onPcnInput = (a?: React.FormEvent<HTMLElement>, str?: string): void => {
        if (str && a?.type === 'input') {
            setEditedAllocation({
                ...editedAllocation,
                FuturePCN: str,
            });
        }
    };

    const resetErrorMsg = (): void => {
        setErrorMsg('');
    };

    const onPreHireNameInput = (a?: React.FormEvent<HTMLElement>, str?: string): void => {
        if (a?.type === 'input') {
            setEditedAllocation({
                ...editedAllocation,
                preHireName: str || '',
                personnelId: '',
            });
        }
    };

    const allocationAction = async (): Promise<void> => {
        try {
            setForceSubmitDisabled(true);
            setIsSubmitInProgress(true);
            if (editAction()) {
                await StaffingClient.editAllocation(authContext, {
                    clouds: Object.values(editedAllocation.clouds).map((c) => c.name),
                    projects: Object.values(props.projects).map((p) => p.name),
                    teamName: editedAllocation.team,
                    personnelId: editedAllocation.personnelId,
                    preHireName: editedAllocation.preHireName,
                    organizationName: editedAllocation.organizationName,
                    positionControlNumber: editedAllocation.PCN,
                });
            } else {
                await StaffingClient.createAllocation(authContext, {
                    clouds: Object.values(editedAllocation.clouds).map((c) => c.name),
                    projects: Object.values(props.projects).map((p) => p.name),
                    teamName: editedAllocation.team,
                    personnelId: addExistingEmployee() ? editedAllocation.personnelId : '',
                    preHireName: addExistingEmployee() ? '' : editedAllocation.preHireName,
                    organizationName: editedAllocation.organizationName,
                    positionControlNumber:
                        (addExistingEmployee()
                            ? editedAllocation.PCN
                            : editedAllocation.FuturePCN) || '',
                });
            }
            resetErrorMsg();
            await editAllocationCallback(true);
        } catch (e) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setErrorMsg(await (e as any).text());
            } catch (f) {
                setErrorMsg('Error occurred. Could not modify allocation');
                console.error(e);
            }
        } finally {
            setForceSubmitDisabled(false);
            setIsSubmitInProgress(false);
        }
    };

    const isSubmitDisabled = (): boolean => {
        const isDisabled1 =
            isSubmitForceDisabled ||
            selectedOrg?.key === undefined ||
            selectedTeam?.key === undefined ||
            selectedCloud?.key === undefined;

        let isDisabled2 = false;

        if (editAction()) {
            isDisabled2 = isEqual(allocation, editedAllocation);
        } else if (addAction()) {
            isDisabled2 =
                (addExistingEmployee() && editedAllocation?.personnelId === undefined) ||
                (addFuturePosition() &&
                    (!/^\d{8}$/.test(editedAllocation.FuturePCN || '') ||
                        /^\s\s*$/.test(editedAllocation.preHireName || '')));
        }
        return isDisabled1 || isDisabled2;
    };

    const modalTitledisplayName = allocation?.employee?.displayName || allocation?.preHireName;
    const alias = allocation?.employee?.onPremisesSamAccountName?.toLowerCase();
    const modalTitleAlias = alias ? `(${alias})` : '';

    return (
        <Modal
            isOpen={isVisible}
            onCancel={cancel}
            onDismiss={cancel}
            onSubmit={allocationAction}
            onResetErrorMsg={resetErrorMsg}
            errorMsg={errorMsg}
            isSubmitButtonDisabled={isSubmitDisabled()}
            submitButtonText={editAction() ? 'Save' : 'Add'}
            showProgressIndicator={isSubmitInProgress}
            title={title}>
            <div>
                {editAction() && (
                    <>
                        Edit allocation for&nbsp;
                        <span style={{ fontWeight: 'bold' }}>{modalTitledisplayName}</span>
                        <span>&nbsp;{modalTitleAlias}</span>
                        <br />
                    </>
                )}
                {addAction() && (
                    <>
                        <br />
                        <ChoiceGroup
                            className={styles.choiceGroup}
                            selectedKey={allocationType}
                            options={allocationTypeOptions}
                            onChange={selectAllocationType}
                            label='Allocation Type'
                        />
                    </>
                )}
                {addAction() && addExistingEmployee() && (
                    <>
                        <br />
                        <EmployeePickerTypeaheadSearch
                            label='Employee'
                            placeHolder='Employee Name or Alias'
                            onCandidateSelected={(info?: IPersonaProps): void => {
                                const itemProp = JSON.parse(info?.itemProp || '{}');
                                setEditedAllocation({
                                    ...editedAllocation,
                                    PCN: itemProp.positionNumber?.toString(),
                                    personnelId: itemProp.id,
                                });
                            }}
                        />
                    </>
                )}
                {addAction() && addFuturePosition() && (
                    <>
                        <br />
                        <MaskedTextField
                            label='PCN'
                            value={editedAllocation.FuturePCN || ''}
                            mask='99999999'
                            onChange={onPcnInput}
                        />

                        <br />
                        <TextField
                            label='Pre-Hire Name'
                            value={editedAllocation.preHireName}
                            onChange={onPreHireNameInput}
                        />
                    </>
                )}
                <br />
                <Dropdown
                    placeholder='Select an organization'
                    label='Select Organization'
                    styles={{ dropdownItems: globalStyles.dropdownItems }}
                    // without the term (|| '') below, the dropdown appeared as if the
                    // first item in the list of options is selected, whereas for the
                    // case <Modal> was instantiated for "Add Allocation", it wasn't
                    // selected.
                    selectedKey={selectedOrg?.key || ''}
                    onChange={selectOrganization}
                    options={orgsOptions}
                />
                <br />
                <Dropdown
                    placeholder={
                        selectedOrg?.key ? 'Select a team' : 'First select an organization'
                    }
                    label='Select Team'
                    styles={{ dropdownItems: globalStyles.dropdownItems }}
                    // without the term (|| '') below, the dropdown appeared as if the
                    // first item in the list of options is selected, whereas for the
                    // case <Modal> was instantiated for "Add Allocation", it wasn't
                    // selected.
                    selectedKey={selectedTeam?.key || ''}
                    onChange={selectTeam}
                    // The following typecast is safe because only
                    // string values are assigned to selectedOrg.key.
                    options={teamOptions(selectedOrg?.text ?? '') || ''}
                />
                <br />
                <ChoiceGroup
                    className={styles.choiceGroup}
                    selectedKey={selectedCloud?.key}
                    options={cloudsOptions}
                    onChange={selectCloudHandler}
                    label='Select Cloud'
                />
                {editAction() && !editedAllocation?.personnelId && (
                    // Only show allocation conversion is the person is not an employee.
                    // TODO - It's worth converting this block to a function component inside this file
                    //        but leave that for another PR so we can clearly see what changed here in this PR.
                    <>
                        <br />
                        <ChoiceGroup
                            className={styles.choiceGroup}
                            selectedKey={allocationConversionType}
                            options={allocationConversionTypeOptions}
                            onChange={selectAllocationConversionType}
                            label='Allocation Conversion Type'
                        />
                        {convertToPrehire() && (
                            <>
                                <br />
                                <TextField
                                    label='Pre-Hire Name'
                                    value={editedAllocation.preHireName}
                                    onChange={onPreHireNameInput}
                                />
                            </>
                        )}
                        {convertToEmployee() && (
                            <>
                                <br />
                                <EmployeePickerTypeaheadSearch
                                    label='Employee'
                                    placeHolder='Employee Name or Alias'
                                    onCandidateSelected={(info?: IPersonaProps): void => {
                                        const itemProp = JSON.parse(info?.itemProp || '{}');
                                        setEditedAllocation({
                                            ...editedAllocation,
                                            PCN: itemProp.positionNumber?.toString(),
                                            personnelId: itemProp.id,
                                        });
                                    }}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
}

const styles = mergeStyleSets({
    choiceGroup: {
        selectors: {
            '.ms-ChoiceField': {
                display: 'inline-block',
                marginRight: 10,
            },
        },
    },
});
