import { ActionButton, IColumn, MessageBar, MessageBarType, Stack, Toggle } from '@fluentui/react';
import { Dictionary, IconNames } from 'assets/constants/global-constants';
import { columnHeaderStyles, expandedTableWidth } from 'assets/styles/list-styles';
import EmployeeClient, {
    IBasicEmployee,
    IEmployeeEditableResponse,
    transformPrehireToEmployeeObject,
} from 'clients/employee-client';
import ReportsClient, { IHierarchy } from 'clients/reports-client';
import PublicTrustScreeningClient, {
    IScreeningRecordPublicTrustExcelRequest,
    IScreeningRecordPublicTrustExcelResponse,
} from 'clients/screening/public-trust-screening-client';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { ScreeningPageNames } from 'components/common/constants';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import {
    getEmployeeType,
    IEmployeeNameAndType,
    PersonnelTypes,
} from 'components/common/employee/internal-employee-utils';
import HorizontalBar from 'components/common/horizontal-bar';
import Spacer from 'components/common/spacer';
import { Table } from 'components/common/table';
import { SuitabilityLevels } from 'components/personnel-profile/suitability/profile-suitability-types';
import CandidateNominationStepperCommon from 'components/screening/common/candidate-nomination-stepper-common';
import {
    defaultNoReportToPersonnelId,
    getPublicTrustStatusDisplay,
    getStatusText,
    getStatusTextByStateName,
    metricsTitle,
    ScreeningPaths,
    ScreeningRequestTypesLabels,
    StateName,
} from 'components/screening/common/common-constants';
import { filterWidth, pageStyle } from 'components/screening/common/common-tab-styling';
import CandidatesPageHelp from 'components/screening/common/components/candidate-page-help';
import MyOrgBlockedMessage from 'components/screening/common/components/my-org-severe-warning-message';
import ScreeningAttentionMessage from 'components/screening/common/components/screening-attention-message';
import {
    getContracts,
    getLatestHierarchyReport,
    updateHierarchyCache,
} from 'components/screening/common/content-tabs-help';
import { isUserContractOwnerOrNST } from 'components/screening/common/filters/filter-help';
import {
    convertIPublicTrustToCommonScreening,
    ICommonScreening,
} from 'components/screening/common/ICommonScreening';
import useHasMyOrgAccess from 'components/screening/common/use-has-my-org-access';
import PublicTrustScreeningChart from 'components/screening/public-trust/candidates-listing/metrics/public-trust-screening-chart';
import PublicTrustBulkUploadModal from 'components/screening/public-trust/candidates-listing/tabpanel-contents/public-trust-bulk-upload-modal';
import {
    getPublicTrustTableColumns,
    publicTrustTableColumns,
} from 'components/screening/public-trust/candidates/listing/get-public-trust-table-columns';
import { PublicTrustFilter } from 'components/screening/public-trust/candidates/listing/public-trust-filter';
import { IPublicTrustResults } from 'components/screening/public-trust/public-trust-screening-result';
import { useCanShowContractAdminPage } from 'components/screening/screening-utils';
import { IContract } from 'components/screening/us-gov/IContract';
import { AuthContext } from 'contexts/auth-context';
import { CacheContext } from 'contexts/cache-context';
import { FiltersContext, IFiltersContext } from 'contexts/filters-context';
import { UserContext } from 'contexts/user-context';
import { default as config } from 'environments/environment';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { partitionArray } from 'utils/array-utils';
import { BrowserCacheKeys } from 'utils/browser-cache-utils';
import { excelFormatHeader } from 'utils/reporting-utils';
import { numCmp, SortDescending, strCmp, useSortColumnHandler } from 'utils/sort-utils';
import { isGUID } from 'utils/string-utils';
import { dateToFormattedDateTimeStringFromMillis, TimeFormats } from 'utils/time-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface ContentPublicTrustTabProps {
    pageName: string;
    pathName: string;
}

export default function ContentPublicTrustTab(props: ContentPublicTrustTabProps): JSX.Element {
    const isMyOrg = props.pageName === ScreeningPageNames.MyOrg;
    const authContext = useContext(AuthContext);
    const cacheContext = useContext(CacheContext);
    const filtersContext = useContext(FiltersContext);
    const userContext = useContext(UserContext);

    const canShowContractAdminPage = useCanShowContractAdminPage(authContext);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const suitabilityFeatureOn = useFeatureFlag(FeatureFlagKeys.suitabilityRecordsTab);

    const cacheKey = `${filtersContext.location.pathname}${filtersContext.location.search}`; // the URL of the page
    const cacheKeyEmployeeNames = `${cacheKey}EmployeeNames`; // the URL of the page
    const cacheKeyContract = `${cacheKey}ContractsSetKey`;
    const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false);
    const [errorMessage, setMessage] = useState<string>('');
    const [filteredCandidates, setFilteredCandidates] = useState<ICommonScreening[]>([]);
    const [hasErrorOccurred, setErrorOccurred] = useState<boolean>(false);
    const [isDoneLoadingScreenings, setDoneLoadingScreenings] = useState<boolean>(false);
    const [isExpandButtonShown, setisExpandButtonShown] = useState<boolean>(true);
    const [showDownloadExcelButton, setShowDownloadExcelButton] = useState<boolean>(false);
    const [showUploadModalButton, setShowUploadModalButton] = useState<boolean>(false);
    const [shouldScreeningChartBeShown, setShouldScreeningChartBeShown] = useState<boolean>(false);
    const [isInitialEmployeeBatchLoaded, setIsInitialEmployeeBatchLoaded] = useState<boolean>(
        false,
    );
    const [isPageFetchingInitialNominationData, setIsPageFetchingInitialNominationData] = useState<
        boolean
    >(false);
    const [isShowNominationProcess, setShowNominationProcess] = useState<boolean>(false);
    const { tab } = useParams<{ tab: 'string' }>();
    const [showAdvancedMetrics, setShowAdvancedMetrics] = useState<boolean>(false);
    const [rawCandidates, setRawCandidates] = useState<ICommonScreening[]>([]);
    const [contracts, setContracts] = useState<Dictionary<IContract>>({});
    const [
        employeeIdsWithFutureTerminationDates,
        setEmployeeIdsWithFutureTerminationDates,
    ] = useState<string[]>([]);
    const [employeeNames, setEmployeeNames] = useState<Map<string, IEmployeeNameAndType>>(
        new Map<string, IEmployeeNameAndType>(),
    );
    const [hierarchyRecords, setHierarchyRecords] = useState<Dictionary<IHierarchy>>();
    const [tableColumns, setTableColumns] = useState([] as IColumn[]);
    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler(
        'Status Changed On',
        SortDescending,
    );
    const [isDirectReportSelected, setDirectReportSelected] = useState<boolean>(isMyOrg);
    const [reportsToOrgDict, setReportsToOrgDict] = useState<Dictionary<number>>();
    const [highestManager, setHighestManager] = useState<number>(defaultNoReportToPersonnelId);
    const [isDetailWindowOpen, setIsDetailWindowOpen] = useState<boolean>(false);
    const [currentDetailWindowScreening, setCurrentDetailWindowScreening] = useState<
        ICommonScreening | undefined
    >(undefined);
    const { hasMyOrgAccess } = useHasMyOrgAccess();
    const [isContractOwnerOrNst, setIsContractOwnerOrNST] = useState<boolean>(false);
    const MaxExcelDataBatch = 2000;

    function findHighestManager(reportToDict: Dictionary<number>): number {
        if (reportToDict) {
            let currentPersonnelId = Object.values(reportToDict)[0];
            // get first id in reportToDict and loop up
            while (reportToDict[currentPersonnelId]) {
                const nextHighestManager = reportToDict[currentPersonnelId];
                if (!nextHighestManager) {
                    break;
                }
                currentPersonnelId = reportToDict[currentPersonnelId];
            }
            return currentPersonnelId;
        }
        return defaultNoReportToPersonnelId;
    }

    async function getEmployeeIdsWithFutureTerminationDates(personnelIds: string[]): Promise<void> {
        const isContractOwnerOrNST = isUserContractOwnerOrNST(userContext, ScreeningPaths.UsGov);

        if (
            personnelIds.length > 0 &&
            isContractOwnerOrNST &&
            (props.pageName === ScreeningPageNames.MyContracts ||
                props.pageName === ScreeningPageNames.Manage)
        ) {
            const results = await UsGovScreeningClient.getEmployeeIdsWithFutureTerminationDates(
                authContext,
                personnelIds,
            );
            setEmployeeIdsWithFutureTerminationDates(results);
        }
    }

    async function getInitialCandidates(filterCtx: IFiltersContext): Promise<void> {
        if (
            isMyOrg &&
            !config.personnelProfile.isUsCitizenTestingFlag &&
            !userContext.employeeRecord.isUSCitizen
        ) {
            return;
        }
        setIsPageFetchingInitialNominationData(true);
        try {
            const results: IPublicTrustResults = await PublicTrustScreeningClient.getPagedScreenings(
                authContext,
                filterCtx,
                '',
            );
            const screeningArray = results.results.map((publicTrust) =>
                convertIPublicTrustToCommonScreening(publicTrust),
            );
            let filteredCandidates = screeningArray;
            if (results.continuationToken) {
                getMoreScreenings(screeningArray, filterCtx, results.continuationToken);
            } else {
                setDoneLoadingScreenings(true);
                cacheContext.store<ICommonScreening[]>(cacheKey, screeningArray);
            }
            if (isMyOrg) {
                const reportToDict = await UsGovScreeningClient.getReportToScreening(authContext);
                const newhighestManager = findHighestManager(reportToDict);
                setReportsToOrgDict(reportToDict);
                setHighestManager(newhighestManager);
                if (isDirectReportSelected) {
                    filteredCandidates = screeningArray.filter(
                        (screening) => reportToDict[screening.personnelId] === highestManager,
                    );
                }
            }
            const contractDictionary = await getContracts(
                screeningArray,
                cacheContext,
                cacheKeyContract,
                authContext,
            );
            setRawCandidates(screeningArray);
            setFilteredCandidates(filteredCandidates);
            batchFetchCandidateEmployeeData(screeningArray);
            setContracts(contractDictionary);
            cacheContext.store<Dictionary<IContract>>(cacheKeyContract, contractDictionary);
            getEmployeeIdsWithFutureTerminationDates(screeningArray.map((x) => x.personnelId));
        } catch (e) {
            setFilteredCandidates([]);
            if (props.pageName === ScreeningPageNames.MyNominees) {
                updateMessage(true, 'No Nominations to show');
            }
        }
    }

    async function getMoreScreenings(
        screeningArray: ICommonScreening[],
        filterCtx: IFiltersContext,
        continuationToken?: string,
    ): Promise<void> {
        try {
            let results: IPublicTrustResults;
            let useContinuationToken: string | undefined = continuationToken;
            do {
                results = await PublicTrustScreeningClient.getPagedScreenings(
                    authContext,
                    filterCtx,
                    useContinuationToken,
                );
                const moreScreenings = results.results.map((publicTrust) =>
                    convertIPublicTrustToCommonScreening(publicTrust),
                );
                screeningArray = [...screeningArray, ...moreScreenings];
                populateEmployeeDataCache(moreScreenings);
                useContinuationToken = results.continuationToken;
            } while (useContinuationToken);
        } catch (e) {
            updateMessage(true, 'Failed to get all records');
        } finally {
            setRawCandidates(screeningArray);
            let filteredCandidates = screeningArray;
            if (isMyOrg && isDirectReportSelected && reportsToOrgDict) {
                filteredCandidates = screeningArray.filter(
                    (screening) => reportsToOrgDict[screening.personnelId] === highestManager,
                );
            }
            setFilteredCandidates(filteredCandidates);
            setDoneLoadingScreenings(true);
            cacheContext.store<ICommonScreening[]>(cacheKey, screeningArray);
        }
    }

    function checkIfMetricsTabFromURL(): void {
        if (
            props.pageName !== ScreeningPageNames.MyScreenings &&
            tab &&
            tab.toLocaleLowerCase().localeCompare(metricsTitle.toLocaleLowerCase()) === 0
        ) {
            setShowAdvancedMetrics(true);
        }
    }

    useEffect(() => {
        async function updateContracts(screeningArray: ICommonScreening[]): Promise<void> {
            if (screeningArray.length > 0) {
                const newContracts = await getContracts(
                    screeningArray,
                    cacheContext,
                    cacheKeyContract,
                    authContext,
                );
                setContracts(newContracts);
                cacheContext.store<Dictionary<IContract>>(cacheKeyContract, newContracts);
            }
        }

        checkIfMetricsTabFromURL();
        const screeningArray = cacheContext.retrieve<ICommonScreening[]>(cacheKey);
        if (screeningArray) {
            setRawCandidates(screeningArray);
            let filteredCandidates = screeningArray;
            if (isMyOrg && isDirectReportSelected && reportsToOrgDict) {
                filteredCandidates = screeningArray.filter(
                    (screening) => reportsToOrgDict[screening.personnelId] === highestManager,
                );
            }
            setFilteredCandidates(filteredCandidates);
            setIsPageFetchingInitialNominationData(false);
            setIsInitialEmployeeBatchLoaded(true);
            getEmployeeIdsWithFutureTerminationDates(screeningArray.map((x) => x.personnelId));
            setDoneLoadingScreenings(true);
            updateContracts(screeningArray);
        } else {
            getInitialCandidates(filtersContext);
        }
    }, []);

    useEffect(() => {
        if (userContext.isUsGovScreeningUserTypesLoaded) {
            const isContractOwnerOrNST = isUserContractOwnerOrNST(
                userContext,
                ScreeningPaths.PublicTrust,
            );
            if (isContractOwnerOrNST) {
                const storedRecords = localStorage.getItem(
                    BrowserCacheKeys.hierarchyReportStorageAndCacheKey,
                );
                const hierarchyReportMap: Dictionary<IHierarchy> = storedRecords
                    ? JSON.parse(storedRecords)
                    : undefined;

                if (hierarchyReportMap) {
                    setHierarchyRecords(hierarchyReportMap);
                }
                getLatestHierarchyReport(
                    rawCandidates,
                    authContext,
                    setHierarchyRecords,
                    updateMessage,
                );
            }
            setIsContractOwnerOrNST(isContractOwnerOrNST);
        }
    }, [userContext.isUsGovScreeningUserTypesLoaded]);

    useEffect(() => {
        // don't show for My Screening page only
        setShowDownloadExcelButton(!(props.pageName === ScreeningPageNames.MyScreenings));
        // only show upload modal on Manage page only
        setShowUploadModalButton(props.pageName === ScreeningPageNames.Manage);
    }, [props.pageName]);

    useEffect(() => {
        setShouldScreeningChartBeShown(
            rawCandidates.length > 0 && props.pageName !== ScreeningPageNames.MyScreenings,
        );
    }, [rawCandidates, props.pageName]);

    useEffect(() => {
        function filterData(): void {
            let finalData = rawCandidates;
            filtersContext.filterFunctionsMap.forEach((filterFunctions: any[]) => {
                if (filterFunctions!.length > 0) {
                    finalData = finalData.filter((data) => {
                        for (const filterFunction of filterFunctions!) {
                            if (filterFunction.func(data)) {
                                return true;
                            }
                        }
                        return false;
                    });
                }
            });
            if (isMyOrg && isDirectReportSelected && reportsToOrgDict) {
                finalData = finalData.filter(
                    (screening) => reportsToOrgDict[screening.personnelId] === highestManager,
                );
            }
            setFilteredCandidates(finalData);
        }
        filterData();
    }, [filtersContext, rawCandidates, isDirectReportSelected, highestManager, reportsToOrgDict]);

    useEffect(() => {
        {
            (props.pageName !== ScreeningPageNames.MyOrg ||
                (hasMyOrgAccess && props.pageName === ScreeningPageNames.MyOrg)) &&
                setTableColumns(
                    getPublicTrustTableColumns({
                        updateScreeningInRawCandidates,
                        isInitialEmployeeBatchLoaded,
                        sortColumn,
                        sortAscending: sortAscending === 1,
                        sortColumnHandler,
                        openDetails: openIndividualDetailsWindow,
                        pageName: props.pageName,
                    }),
                );
        }
    }, [
        props.pageName,
        rawCandidates, // because it affects updateScreeningInRawCandidates
        isInitialEmployeeBatchLoaded,
        sortColumn,
        sortAscending,
        hasMyOrgAccess,
    ]);

    useEffect(() => {
        function handleResize(): void {
            setisExpandButtonShown(window.innerWidth < filterWidth + expandedTableWidth);
        }
        window.addEventListener('resize', handleResize);
        handleResize(); // check the size of the screen on page load

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function updateScreeningInRawCandidates(updatedScreening: ICommonScreening): void {
        const updatedRawCandidates = rawCandidates.slice(0);
        const screenIdx = updatedRawCandidates.findIndex((x) => x.id === updatedScreening.id);
        updatedRawCandidates[screenIdx] = updatedScreening;
        setRawCandidates(updatedRawCandidates);
    }

    /**
     * This is a greedy method just to pre-cache employee data, because it might be shown on the UI
     *
     * Very close to batchFetchCandidateEmployeeData but different in that this will not
     * capture the manager data and the UI will not be held up in rendering.
     * Primary used for data that is not going to be rendering right away.
     */
    async function populateEmployeeDataCache(candidates: ICommonScreening[]): Promise<void> {
        try {
            const initialPersonnelIdsToFetch = new Set<string>();
            candidates.forEach((screen: ICommonScreening) => {
                if (screen.personnelId) {
                    initialPersonnelIdsToFetch.add(screen.personnelId);
                }
                if (screen.nominatedBy) {
                    initialPersonnelIdsToFetch.add(screen.nominatedBy);
                }
                if (screen.processOwnerId) {
                    initialPersonnelIdsToFetch.add(screen.processOwnerId);
                }
            });

            if (initialPersonnelIdsToFetch.size > 0) {
                EmployeeClient.getBasicEmployeesById(
                    authContext,
                    Array.from(initialPersonnelIdsToFetch),
                );
            }
        } catch (e) {
            updateMessage(
                true,
                'Error resolving alias/ids in populateEmployeeDataCache calling employee-services',
            );
        }
    }

    /**
     * This is a greedy method to handle the first Page of Screening records that do end up on the
     *  UI therefor also want to capture there managers info and make sure nothing renders until
     * this function is done.
     *
     * Very close to populateEmployeeDataCache, but different in that this is pulling manager
     * and will hold the UI from rendering until complete.
     */
    async function batchFetchCandidateEmployeeData(candidates: ICommonScreening[]): Promise<void> {
        try {
            const initialPersonnelIdsToFetch = new Set<string>();
            const initialEditableEmployeeIdsToFetch = new Set<string>();
            const managerAliasesToFetch = new Set<string>();
            const newNameMap = new Map(employeeNames);
            candidates.forEach((screen: ICommonScreening) => {
                if (screen.personnelId) {
                    if (isGUID(screen.personnelId)) {
                        initialEditableEmployeeIdsToFetch.add(screen.personnelId);
                    } else {
                        initialPersonnelIdsToFetch.add(screen.personnelId);
                    }
                }
                if (screen.nominatedBy) {
                    initialPersonnelIdsToFetch.add(screen.nominatedBy);
                }
                if (screen.processOwnerId) {
                    initialPersonnelIdsToFetch.add(screen.processOwnerId);
                }
            });

            if (initialEditableEmployeeIdsToFetch.size > 0) {
                const initialEmployeeData: IEmployeeEditableResponse[] = await EmployeeClient.getMultipleEditableEmployeeDataByIdOrAliasOrGUID(
                    authContext,
                    Array.from(initialEditableEmployeeIdsToFetch),
                );

                initialEmployeeData.forEach((editableEmployee) => {
                    const employee = transformPrehireToEmployeeObject(editableEmployee);

                    if (!!editableEmployee.employeeEditableInfo.reportsToEmailName) {
                        managerAliasesToFetch.add(
                            editableEmployee.employeeEditableInfo.reportsToEmailName,
                        );
                    }

                    const employeeData: IEmployeeNameAndType = {
                        displayName: getDisplayNameOrDefault(
                            employee,
                            employee.onPremisesSamAccountName,
                        ),
                        alias: employee.alias,
                        firstName: employee.firstName,
                        middleName: employee.middleName,
                        lastName: employee.lastName,
                        type: getEmployeeType(employee),
                    };

                    newNameMap.set(employee.id, employeeData);

                    if (isGUID(editableEmployee?.employeeId || '')) {
                        newNameMap.set(editableEmployee.employeeId || '', employeeData);
                    }
                });
                setEmployeeNames(newNameMap);
            }

            if (initialPersonnelIdsToFetch.size > 0) {
                const initialEmployeeData: IBasicEmployee[] = await EmployeeClient.getBasicEmployeesById(
                    authContext,
                    Array.from(initialPersonnelIdsToFetch),
                );

                initialEmployeeData.forEach((basicEmployee) => {
                    if (!!basicEmployee.reportsToEmailName) {
                        managerAliasesToFetch.add(basicEmployee.reportsToEmailName);
                    }

                    newNameMap.set(basicEmployee.id, {
                        displayName: getDisplayNameOrDefault(
                            basicEmployee,
                            basicEmployee.onPremisesSamAccountName,
                        ),
                        alias: basicEmployee.onPremisesSamAccountName,
                        firstName: basicEmployee.givenName,
                        middleName: basicEmployee.middleName,
                        lastName: basicEmployee.surname,
                        type: getEmployeeType(basicEmployee),
                    });
                });

                await EmployeeClient.getBasicEmployeesByAlias(
                    authContext,
                    Array.from(managerAliasesToFetch),
                );

                setEmployeeNames(newNameMap);
            }
            cacheContext.store<Map<string, IEmployeeNameAndType>>(
                cacheKeyEmployeeNames,
                newNameMap,
            );
        } catch (e) {
            updateMessage(
                true,
                'Error resolving alias/ids in batchFetchCandidateEmployeeData calling employee-services',
            );
        }
        setIsInitialEmployeeBatchLoaded(true);
        setIsPageFetchingInitialNominationData(false);
    }

    const openIndividualDetailsWindow = (screening: ICommonScreening): void => {
        setIsDetailWindowOpen(true);
        setCurrentDetailWindowScreening(screening);
    };

    useEffect(() => {
        // for cases where employee names are cleared out after drilling into
        // an individual screening and then using breadcrumb to navigate back
        // then use what's stored in the employeeName cache
        if (employeeNames.size === 0) {
            const cachedEmployeeNames = cacheContext.retrieve<Map<string, IEmployeeNameAndType>>(
                cacheKeyEmployeeNames,
            );
            if (cachedEmployeeNames) {
                setEmployeeNames(cachedEmployeeNames);
            }
        }
    }, [employeeNames, cacheContext, cacheKeyEmployeeNames]);

    const sortTableRows = (rows: ICommonScreening[]): ICommonScreening[] => {
        if (rows.length === 0) return [];

        type R = ICommonScreening;
        const determineSortFunc = (): ((r1: R, r2: R) => number) => {
            switch (sortColumn) {
                case publicTrustTableColumns.Candidate:
                    return (r1: R, r2: R): number => {
                        const e1 = employeeNames.get(r1?.personnelId);
                        const e2 = employeeNames.get(r2?.personnelId);

                        if (
                            e1?.type === PersonnelTypes.Terminated &&
                            e2?.type === PersonnelTypes.Terminated
                        )
                            return 0;
                        if (e1?.type === PersonnelTypes.Terminated) return 1;
                        if (e2?.type === PersonnelTypes.Terminated) return -1;

                        return sortAscending * strCmp(e1?.displayName || '', e2?.displayName || '');
                    };
                case publicTrustTableColumns.RequestAgency:
                    return (r1: R, r2: R): number =>
                        sortAscending * strCmp(r1?.publicTrustAgency, r2?.publicTrustAgency);
                case publicTrustTableColumns.RequestType:
                    return (r1: R, r2: R): number =>
                        sortAscending * strCmp(r1?.requestType, r2?.requestType);
                case publicTrustTableColumns.SuitabilityLevel:
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        strCmp(r1?.suitabilityLevel || '', r2?.suitabilityLevel || '');
                case publicTrustTableColumns.ContractId:
                    return (r1: R, r2: R): number =>
                        sortAscending * strCmp(r1?.contractId, r2?.contractId);
                case publicTrustTableColumns.Status:
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        strCmp(getPublicTrustStatusDisplay(r1), getPublicTrustStatusDisplay(r2));
                case publicTrustTableColumns.Substatus:
                    return (r1: R, r2: R): number =>
                        sortAscending * strCmp(getStatusText(r1, false), getStatusText(r2, false));
                case publicTrustTableColumns.ProcessOwner:
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        strCmp(
                            employeeNames.get(r1?.processOwnerId)?.displayName || '',
                            employeeNames.get(r2?.processOwnerId)?.displayName || '',
                        );
                case publicTrustTableColumns.StatusChanged:
                    return (r1: R, r2: R): number =>
                        sortAscending * numCmp(r1?.statusChangedAtUtc, r2?.statusChangedAtUtc);
                default:
                    return (r1: R, r2: R): number => 1;
            }
        };

        return (rows || []).sort(determineSortFunc());
    };

    function clearFiltersButton(): JSX.Element {
        return (
            <Stack.Item grow={100}>
                <ClearFiltersActionButton clearFunc={filtersContext.clearFilters} />
            </Stack.Item>
        );
    }

    function expandColumnButton(): JSX.Element {
        if (showAdvancedMetrics) {
            return <></>;
        }
        return (
            <>
                <ActionButton
                    iconProps={
                        isTableExpanded
                            ? { iconName: IconNames.CalculatorSubtract }
                            : { iconName: IconNames.Add }
                    }
                    onClick={(): void => setIsTableExpanded(!isTableExpanded)}>
                    {`${isTableExpanded ? 'Collapse' : 'Expand'} Columns`}
                </ActionButton>
            </>
        );
    }

    function getTable(): JSX.Element {
        // if (!showAdvancedMetrics) {
        return (
            <Table<ICommonScreening>
                rows={sortTableRows(filteredCandidates)}
                isFetchingData={isPageFetchingInitialNominationData}
                shimmerLabel='Loading initial nominations...'
                tableColumns={tableColumns}
                tableName='Nominations'
                detailsHeaderStyles={columnHeaderStyles}
            />
        );
    }

    function updateMessage(error: boolean, message: string): void {
        setErrorOccurred(error);
        setMessage(message);
    }

    function showBlocked(): boolean {
        return props.pageName === ScreeningPageNames.MyOrg && !hasMyOrgAccess;
    }

    function showAttention(): boolean {
        return props.pageName === ScreeningPageNames.MyOrg && hasMyOrgAccess;
    }

    /**
     * In order to export the current Candidates in Public Trust, we need
     * to format the date for export so the excel looks like the table
     * @param candidates list of candidates we pass into the table
     * @returns converted list of data for excel
     */
    async function convertCandidatesToExcel(
        candidates: ICommonScreening[],
    ): Promise<IExportPublicTrustToExcel[]> {
        const excelData: IExportPublicTrustToExcel[] = [];

        try {
            const excelRequestItems = candidates.map((candidate) => {
                const requestItem: IScreeningRecordPublicTrustExcelRequest = {
                    screeningRecordId: candidate.id,
                    nominatedById: candidate.nominatedBy,
                    personnelId: candidate.personnelId,
                    ProcessOwnerId: candidate.processOwnerId,
                };

                return requestItem;
            });

            const batchedRequestItems = partitionArray(excelRequestItems, MaxExcelDataBatch);

            for (let i = 0; i < batchedRequestItems.length; i++) {
                const results: IScreeningRecordPublicTrustExcelResponse[] = await PublicTrustScreeningClient.getScreeningRecordExcelDataByRequests(
                    authContext,
                    batchedRequestItems[i],
                    filtersContext.userType,
                    props.pageName === ScreeningPageNames.MyOrg,
                );

                results.forEach(async (row) => {
                    const statusChangeOn = row.statusChangedOn
                        ? dateToFormattedDateTimeStringFromMillis(row.statusChangedOn)
                        : '';

                    const terminationDateUTCMilliseconds = row.terminationDateUTCMilliseconds
                        ? dateToFormattedDateTimeStringFromMillis(
                              row.terminationDateUTCMilliseconds,
                          )
                        : '';

                    const statusLabel = getStatusTextByStateName(row.status, true);
                    const subStatusLabel = getStatusTextByStateName(row.status, false);

                    // only add the action url when the View screening button is active
                    // don't add the action url when the table shows blank or view nomination
                    let actionUrl = '';
                    const canSeeDetailsButton =
                        props.pageName === ScreeningPageNames.Manage ||
                        (props.pageName === ScreeningPageNames.MyScreenings &&
                            row.status !== StateName.WaitingForNomineeApproval &&
                            row.status !== StateName.WaitingForContractOwnerApproval);
                    if (canSeeDetailsButton) {
                        // Note: this is meant for ppe and production, local development enviroment the url will look off
                        // ex: https://personnel-ppe.microsoft.com/screening/public-trust/${row.id}
                        actionUrl = `${config.aadConfig.redirectUri}/public-trust/${row.screeningRecordId}`;
                    }

                    const requestType = ScreeningRequestTypesLabels[row.requestType];
                    const requestAgency = row.requestAgency
                        ? row.requestAgency || row.requestAgency
                        : '';
                    const suitabilityLevel = row.suitabilityLevel
                        ? SuitabilityLevels[
                              row.suitabilityLevel as keyof typeof SuitabilityLevels
                          ] || row.suitabilityLevel
                        : '';

                    // Need the space in the key for the excel columns
                    // instead of candidates column like the table display
                    // we will show alias, lastname, first name and middle name as
                    // it makes the excel file easier to search up a specific person
                    // Any Alias should be uppercase in the excel.
                    const excelRow: IExportPublicTrustToExcel = {
                        personnelId: row.personnelId,
                        alias: row.personnelAlias?.toUpperCase() || '',
                        lastName: row.personnelLastName || '',
                        firstName: row.personnelFirstName || '',
                        middleName: row.personnelMiddleName || '',
                        employeeStatus: row.employeeStatus || '',
                        employeeTitle: row.employeeTitle || '',
                        organization: row.organization || '',
                        geographicLocation: row.geographicLocation || '',
                        officeLocation: row.officeLocation || '',
                        level1: row.l1?.toUpperCase() || '',
                        level2: row.l2?.toUpperCase() || '',
                        level3: row.l3?.toUpperCase() || '',
                        level4: row.l4?.toUpperCase() || '',
                        level5: row.l5?.toUpperCase() || '',
                        level6: row.l6?.toUpperCase() || '',
                        reportsTo: row.reportsTo || '',
                        requestAgency: requestAgency
                            .replace(/([a-z])([A-Z])/g, '$1 $2')
                            .replace(/_/g, ' '),
                        requestType,
                        suitabilityLevel,
                        contractID: row.contractId || '',
                        nominatedBy: row.nominatedBy?.toUpperCase() || '',
                        nominatedOn: dateToFormattedDateTimeStringFromMillis(row.nominatedOn),
                        status: statusLabel,
                        subStatus: subStatusLabel,
                        processOwner: row.processOwner?.toUpperCase() || '',
                        statusChangedOn: statusChangeOn,
                        terminationDate: terminationDateUTCMilliseconds,
                        personStatusCode: row.personStatusCode || '',
                        action: actionUrl, // url of the action button
                    };

                    // Remove certain properties from excelRow based upon page to remove associated columns from Excel document output.
                    if (
                        props.pageName === ScreeningPageNames.MyOrg ||
                        props.pageName === ScreeningPageNames.MyNominees
                    ) {
                        delete excelRow['terminationDate'];
                        delete excelRow['personStatusCode'];
                    }

                    excelData.push(excelRow);
                });
            }
        } catch (e) {
            setErrorOccurred(true);
            setMessage('Unable to download screening excel data.');
        }
        return excelData;
    }

    function downloadExcelButton(showAdvancedMetrics: boolean): JSX.Element {
        if (showAdvancedMetrics) {
            return <></>;
        }
        return (
            <Stack.Item grow={0.001}>
                <ExportToExcelButton<IExportPublicTrustToExcel>
                    getData={async (): Promise<IExportPublicTrustToExcel[]> =>
                        convertCandidatesToExcel(filteredCandidates)
                    }
                    fileNamePrefix={`PUBLIC_TRUST_SCREENING_${props.pageName.replace(' ', '_')}`} // page name can come with spaces
                    fileNameTimeFormat={TimeFormats.MM_DD_YYYY_HHmm}
                    formatHeader={true}
                    formatType={excelFormatHeader.microsoftBrandCase}
                />
            </Stack.Item>
        );
    }

    const NominationStepperContainer = (props: { children: ReactNode }): JSX.Element => {
        // Strange, but this good for nothing component is preventing a bug! I couldn't
        // figure out why. If I remove it, the nomination panels will not forget their
        // previous contents when user opens it again to nominate a new user. So, while
        // it appears redundant, it's apparently not so.
        return <>{props.children}</>;
    };

    return (
        <div className={pageStyle.page}>
            <NominationStepperContainer>
                <CandidateNominationStepperCommon
                    isOpen={isShowNominationProcess}
                    containerClassName={pageStyle.modalContainer}
                    onDismiss={(): void => setShowNominationProcess(false)}
                    hideNominationProcess={async (nomination?: ICommonScreening): Promise<void> => {
                        if (nomination) {
                            if (!isGUID(nomination.personnelId)) {
                                try {
                                    const nominatedHierarchyData = await ReportsClient.getSelectHierarchy(
                                        authContext,
                                        [nomination.personnelId],
                                    );
                                    const combinedHierarchyData: Dictionary<IHierarchy> = Object.assign(
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        hierarchyRecords!,
                                        nominatedHierarchyData,
                                    );
                                    updateHierarchyCache(combinedHierarchyData, localStorage);
                                    setHierarchyRecords(combinedHierarchyData);

                                    if (
                                        !employeeIdsWithFutureTerminationDates.some(
                                            (x) => x === nomination.personnelId,
                                        )
                                    ) {
                                        const results = await UsGovScreeningClient.getEmployeeIdsWithFutureTerminationDates(
                                            authContext,
                                            [nomination.personnelId],
                                        );
                                        setEmployeeIdsWithFutureTerminationDates([
                                            ...results,
                                            ...employeeIdsWithFutureTerminationDates,
                                        ]);
                                    }
                                } catch (error) {
                                    console.error(
                                        `failed to grab the hierarchy records for ${nomination.personnelId}`,
                                    );
                                }
                            }
                            setRawCandidates([nomination, ...rawCandidates]);
                        }
                        setShowNominationProcess(false);
                    }}
                    onNominationError={(msg: string): void => updateMessage(true, msg)}
                    screeningPath={ScreeningPaths.PublicTrust}
                />
            </NominationStepperContainer>

            <div className={pageStyle.filterAndTable}>
                <div className={pageStyle.filterWrapper}>
                    <div className={pageStyle.filter}>
                        <PublicTrustFilter
                            rawCandidates={rawCandidates}
                            hierarchyRecords={hierarchyRecords}
                            showCounts={isDoneLoadingScreenings}
                            employeeNames={employeeNames}
                            contracts={contracts}
                            employeeIdsWithFutureTerminationDates={
                                employeeIdsWithFutureTerminationDates
                            }
                            pageName={props.pageName}
                        />
                    </div>
                </div>
                <div
                    className={`${pageStyle.candidatesTable} ${
                        isTableExpanded ? pageStyle.expandedCandidatesTable : ''
                    }`}>
                    {hasErrorOccurred && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel='Close'
                            onDismiss={(): void => updateMessage(false, '')}
                            styles={{ root: { marginBottom: 15 } }}>
                            {errorMessage}
                        </MessageBar>
                    )}
                    <CandidatesPageHelp screeningPath={ScreeningPaths.PublicTrust} />
                    {showBlocked() && <MyOrgBlockedMessage />}
                    {showAttention() && <ScreeningAttentionMessage />}
                    <HorizontalBar styles={{ maxHeight: '50px' }}>
                        <Stack.Item grow={1}>
                            <ActionButton
                                onClick={(): void => setShowNominationProcess(true)}
                                iconProps={{ iconName: IconNames.AddFriend }}
                                allowDisabledFocus>
                                Nominate candidate
                            </ActionButton>
                            {canShowContractAdminPage &&
                                props.pageName === ScreeningPageNames.MyContracts && (
                                    <Link
                                        style={{ marginRight: '5rem' }}
                                        to={'/screening/contracts'}>
                                        <ActionButton
                                            title='Manage my contracts'
                                            aria-label='Manage my contracts'
                                            iconProps={{ iconName: IconNames.EntitlementPolicy }}>
                                            Manage my contracts
                                        </ActionButton>
                                    </Link>
                                )}
                            {suitabilityFeatureOn && showUploadModalButton && (
                                <ActionButton
                                    onClick={(): void => setShowUploadModal(true)}
                                    iconProps={{ iconName: IconNames.BulkUpload }}
                                    allowDisabledFocus>
                                    Upload
                                </ActionButton>
                            )}
                        </Stack.Item>
                        {showUploadModal && (
                            <PublicTrustBulkUploadModal setShowUploadModal={setShowUploadModal} />
                        )}
                        {/* {props.pageName !== ScreeningPageNames.MyScreenings && (
                            <Stack.Item grow={1} styles={{ root: { textAlign: 'end' } }}>
                                <Pivot
                                    selectedKey={showAdvancedMetrics ? '1' : '0'}
                                    onLinkClick={(item): void => {
                                        BreadCrumbHelper.resetBreadCrumbs(breadCrumbContext);
                                        const key = props.pageName as keyof typeof pageNameMap;
                                        const path = pageNameMap[key] ?? pageNameMap.Manage;
                                        // need to make a copy otherwise it will keep on adding to the same array
                                        const newBreadCrumbs = [
                                            ...defaultBreadCrumbs,
                                            {
                                                title: props.pageName
                                                    ? props.pageName
                                                    : 'Screenings',
                                                link: `/screening/us-gov/${path}`,
                                            },
                                        ];
                                        if (
                                            item?.props.headerText?.toUpperCase() ===
                                                metricsTitle.toUpperCase() &&
                                            showAdvancedMetrics !== true
                                        ) {
                                            const link = `/screening/us-gov/${path}/metrics`;
                                            history.replace(link);
                                            setShowAdvancedMetrics(true);
                                            newBreadCrumbs.push({
                                                title: metricsTitle,
                                                link,
                                            });
                                            BreadCrumbHelper.setBreadCrumbs(
                                                breadCrumbContext,
                                                newBreadCrumbs,
                                            );
                                        } else if (showAdvancedMetrics !== false) {
                                            const link = `/screening/us-gov/${path}/records`;
                                            history.replace(link);
                                            newBreadCrumbs.push({
                                                title: recordsTitle,
                                                link,
                                            });
                                            BreadCrumbHelper.setBreadCrumbs(
                                                breadCrumbContext,
                                                newBreadCrumbs,
                                            );
                                            setShowAdvancedMetrics(false);
                                        }
                                    }}>
                                    <PivotItem headerText={recordsTitle} />
                                    <PivotItem headerText={metricsTitle} />
                                </Pivot>
                            </Stack.Item>
                        )} */}
                    </HorizontalBar>
                    {shouldScreeningChartBeShown && (
                        <>
                            <Spacer marginTop={10} />
                            <PublicTrustScreeningChart
                                screenings={filteredCandidates}
                                totalRecords={rawCandidates.length}
                            />
                        </>
                    )}
                    <Spacer marginTop={20} />
                    <HorizontalBar>
                        <Stack.Item grow={1}>
                            <span className={pageStyle.recordsCount}>
                                {`${filteredCandidates.length} of ${rawCandidates.length} Records`}
                            </span>
                        </Stack.Item>
                        {isMyOrg && (
                            <Stack.Item grow={1} className={pageStyle.marginTop10}>
                                <Toggle
                                    checked={isDirectReportSelected}
                                    inlineLabel
                                    onText='Direct Reports'
                                    offText='Entire Org'
                                    onChange={(
                                        event: React.MouseEvent<HTMLElement>,
                                        checked?: boolean,
                                    ): void => {
                                        setDirectReportSelected(!!checked);
                                    }}
                                />
                            </Stack.Item>
                        )}
                        {clearFiltersButton()}
                        {isExpandButtonShown && expandColumnButton()}
                        {showDownloadExcelButton && downloadExcelButton(false)}
                    </HorizontalBar>
                    {isMyOrg &&
                    !config.personnelProfile.isUsCitizenTestingFlag &&
                    !userContext.employeeRecord.isUSCitizen ? (
                        <></>
                    ) : (
                        getTable()
                    )}
                </div>
            </div>
        </div>
    );
}

interface IExportPublicTrustToExcel {
    personnelId: string;
    alias: string;
    lastName: string;
    firstName: string;
    middleName: string;
    employeeStatus: string;
    employeeTitle: string;
    organization: string;
    geographicLocation: string;
    officeLocation: string;
    level1: string;
    level2: string;
    level3: string;
    level4: string;
    level5: string;
    level6: string;
    reportsTo: string;
    requestAgency: string;
    requestType: string;
    suitabilityLevel: string;
    contractID: string;
    nominatedBy: string;
    nominatedOn: string;
    status: string;
    subStatus: string;
    processOwner: string;
    statusChangedOn: string;
    terminationDate?: string;
    personStatusCode?: string;
    action: string; // this is the hyper link for "View Screening"
}
