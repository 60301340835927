const TTL = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds
const DEFAULT_CACHE_NAME = 'default-cache';

export const LocalStorageCache = {
    store<T>(key: string, value: T, cacheName = DEFAULT_CACHE_NAME): void {
        if (!key || !value) {
            return;
        }
        const cacheEntry = {
            value: value,
            timestamp: Date.now(),
        };
        const storedCache = localStorage.getItem(cacheName);
        const parsedCache = storedCache
            ? new Map<string, { value: unknown; timestamp: number }>(JSON.parse(storedCache))
            : new Map<string, { value: unknown; timestamp: number }>();
        parsedCache.set(key, cacheEntry);
        localStorage.setItem(cacheName, JSON.stringify(Array.from(parsedCache.entries())));
    },
    retrieve<T>(key: string, cacheName = DEFAULT_CACHE_NAME): T | undefined {
        if (!key) {
            return;
        }
        const storedCache = localStorage.getItem(cacheName);
        if (storedCache) {
            const parsedCache = new Map<string, { value: unknown; timestamp: number }>(
                JSON.parse(storedCache),
            );
            const cacheEntry = parsedCache.get(key);
            if (cacheEntry) {
                if (Date.now() - cacheEntry.timestamp < TTL) {
                    return cacheEntry.value as T;
                } else {
                    parsedCache.delete(key);
                    localStorage.setItem(
                        cacheName,
                        JSON.stringify(Array.from(parsedCache.entries())),
                    );
                    return undefined;
                }
            }
        }
        return undefined;
    },
    remove(key: string, cacheName = DEFAULT_CACHE_NAME): void {
        if (!key) {
            return;
        }
        const storedCache = localStorage.getItem(cacheName);
        if (storedCache) {
            const parsedCache = new Map<string, unknown>(JSON.parse(storedCache));
            parsedCache.delete(key);
            localStorage.setItem(cacheName, JSON.stringify(Array.from(parsedCache.entries())));
        }
    },
};
