import { useContext, useEffect, useState } from 'react';
import EmployeeClient, { IBasicEmployee } from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import { fetchPictures } from 'components/common/employee/employee-utils';

type IUseEmployeesBasicDataType = {
    getBasicEmployeeRecords: (personnelIds: string[]) => void;
    basicEmployeesMap: Map<string, IBasicEmployee>;
    employeesPictureMap: Map<string, string>;
    errorGettingRecords: string | undefined;
    clearErrorMessage: () => void;
};

export default function useEmployeeBasicRecords(): IUseEmployeesBasicDataType {
    const [personnelIds, setPersonnlIds] = useState<string[]>([]);
    const [basicEmployeesMap, setBasicEmployeesMap] = useState<Map<string, IBasicEmployee>>(
        new Map<string, IBasicEmployee>(),
    );
    const [employeesPictureMap, setEmployeesPictureMap] = useState<Map<string, string>>(
        new Map<string, string>(),
    );
    const [errorGettingRecords, setErrorGettingRecords] = useState<string | undefined>();

    const authContext = useContext(AuthContext);

    const clearErrorMessage = (): void => {
        setErrorGettingRecords(undefined);
    };

    const getBasicEmployeeRecords = (personnelIdsParam: string[]): void => {
        if (!personnelIds || personnelIdsParam.length === 0) {
            return;
        }
        setPersonnlIds(personnelIdsParam);
    };

    useEffect(() => {
        async function getEmployees(): Promise<void> {
            try {
                const getTheseEmployees = new Set<string>();
                personnelIds.forEach((id) => {
                    if (!basicEmployeesMap.has(id)) {
                        /**
                         * Check to make sure the id is a valid personnelId. During testing,
                         * some turned out to be a person's name. It was result of a temporary
                         * bug that existed during development. I placed this safeguard against
                         * it nontheless because it was causing infinite rerenders.
                         */
                        if (/^\d+$/.test(id)) {
                            getTheseEmployees.add(id);
                        }
                    }
                });
                if (getTheseEmployees.size === 0) {
                    return;
                }
                const getTheseEmployeesArray = Array.from(getTheseEmployees);
                const employeesResult: IBasicEmployee[] = await EmployeeClient.getBasicEmployeesById(
                    authContext,
                    getTheseEmployeesArray,
                );
                const picturesDictionary = await fetchPictures(authContext, employeesResult);

                setEmployeesPictureMap((currentValue) => {
                    const newValue = new Map<string, string>(currentValue);
                    Object.entries(picturesDictionary).forEach(([id, picture]) => {
                        if (!!picture && picture !== 'data:image/jpeg;base64,') {
                            newValue.set(id, picture);
                        }
                    });
                    return newValue;
                });

                setBasicEmployeesMap((currentValue) => {
                    const newValue = new Map<string, IBasicEmployee>(currentValue);
                    employeesResult.forEach((employee) => {
                        newValue.set(employee.id, employee);
                    });
                    return newValue;
                });
            } catch (e) {
                console.error(e);
                console.error('Error getting employee by employee personnel Id');
                setErrorGettingRecords('Error getting employee data');
            }
        }

        getEmployees();
    }, [personnelIds, basicEmployeesMap]);

    return {
        getBasicEmployeeRecords,
        clearErrorMessage,
        basicEmployeesMap,
        employeesPictureMap,
        errorGettingRecords,
    };
}
