import { ComboBox, IComboBoxOption, IComboBoxStyles } from '@fluentui/react';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { ElementProps } from 'components/forms/element-viewer';
import { countryList } from 'components/forms/element-viewer/country-list';

function CountryPickerViewer(props: ElementProps): JSX.Element {
    const { element, updateFormResponse } = props;
    const [selectedCountry, setSelectedCountry] = useState<string>(
        (element.value as string) ?? 'United States',
    );
    const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 350 } };

    const countryDropdownOptions: IComboBoxOption[] = useMemo(() => {
        return countryList.map((country) => {
            return { key: country, text: country };
        });
    }, []);

    useEffect(() => {
        updateFormResponse(element, selectedCountry);
    }, [selectedCountry]);

    return (
        <ComboBox
            ariaLabel='Countries'
            selectedKey={selectedCountry}
            options={countryDropdownOptions}
            allowFreeform
            autoComplete='on'
            styles={comboBoxStyles}
            onChange={(ev, option) => {
                if (option) {
                    setSelectedCountry(option.text);
                }
            }}
        />
    );
}

export default memo(CountryPickerViewer);
