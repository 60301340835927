import React, { useEffect, useState, useContext } from 'react';
import {
    ModalConclusion,
    onModalConcludeType,
} from 'components/common/buttons/modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import ScaClient, { IReviewPeriod, IScaRecord, ReviewState } from 'clients/sca-client';
import {
    Dropdown,
    IDropdownOption,
    Dialog,
    DialogFooter,
    MessageBar,
    MessageBarType,
    DefaultButton,
    Stack,
} from '@fluentui/react';
import {
    translateReviewState,
    ReviewStatusNames,
    getEmployeeReviewStateBackground,
    getEmployeeReviewStatusText,
    encodeEmployeeReviewStateDisplayText,
    employeeReviewStateOptions,
    employeeEligibleOption,
    getEmployeeReviewStatusRecordValue,
} from 'components/sca/sca-constants';
import ScaEmployeeEligibilityDialog, {
    useScaEmployeeEligibilityDialogUtils,
} from 'components/sca/my-team/sca-employee-eligibility-dialog';
import deepEqual from 'deep-equal';
import Badge from 'components/common/badge';
import CommonSpinner from 'components/common/common-spinner';
import { generalIsMountedCode } from 'utils/misc-utils';

interface IScaEligibilityDropdownProps {
    reviewPeriod: IReviewPeriod | undefined;
    employeeReview: IScaRecord;
    onUpdateEligibilityConcluded: onModalConcludeType<IScaRecord>;
}

export default function ScaEligibilityDropdown(props: IScaEligibilityDropdownProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const [comment, setComment] = useState<string>();
    const [submitError, setSubmitError] = useState<string>();
    const [shouldUpdate, setShouldUpdate] = useState<boolean>();
    const [selectedStatus, setSelectedStatus] = useState<IDropdownOption>();
    const [isSubmitInProgress, setIsSubmitInProgress] = useState<boolean>();
    const {
        isEmpEligNoticeDialogOpen,
        hideEligibilityNoticeDialog,
        showEligibilityNoticeDialog,
    } = useScaEmployeeEligibilityDialogUtils();

    const initInputs = (): void => {
        if (props.reviewPeriod && props.employeeReview) {
            const selectedStatusVar = employeeReviewStateOptions.find(
                (thisSelection) =>
                    translateReviewState(props?.employeeReview?.reviewState) === thisSelection.text,
            );
            setSelectedStatus(selectedStatusVar);
            setComment(props.employeeReview.reviewComment);
        }
    };

    useEffect(() => {
        initInputs();
    }, [props.employeeReview, props.reviewPeriod]);

    const resetSubmitError = (): void => {
        setSubmitError('');
    };

    const updateEmployeeRecord = async (isMountedFunc: () => boolean): Promise<void> => {
        setIsSubmitInProgress(true);
        try {
            const returnResult = await ScaClient.addOrEditSCAEmployee(
                authContext,
                props.employeeReview.personnelId,
                props.employeeReview.rate,
                comment?.trim(),
                encodeEmployeeReviewStateDisplayText(selectedStatus?.text as string),
                'update',
            );
            props.onUpdateEligibilityConcluded(ModalConclusion.Done, returnResult);
        } catch (e) {
            if (isMountedFunc()) {
                initInputs();
                setSubmitError('Error updating employee status');
            }
        } finally {
            if (isMountedFunc()) {
                setShouldUpdate(false);
                setIsSubmitInProgress(false);
            }
        }
    };

    useEffect(() => {
        if (shouldUpdate) {
            return generalIsMountedCode(updateEmployeeRecord);
        }
    }, [shouldUpdate]);

    const onStatusSelect = (
        event: React.FormEvent<HTMLElement> | undefined,
        option?: IDropdownOption,
    ): void => {
        if (/^click|focus|keydown$/.test(event?.type ?? '')) {
            if (
                option?.text === translateReviewState(ReviewState.Eligible) &&
                !deepEqual(selectedStatus, employeeEligibleOption)
            ) {
                showEligibilityNoticeDialog();
            } else {
                setSelectedStatus(option);
                if (option?.text !== translateReviewState(props.employeeReview.reviewState)) {
                    setShouldUpdate(true);
                }
            }
        }
    };

    const onAcceptEligibilityDialog = (): void => {
        hideEligibilityNoticeDialog();
        setSelectedStatus(employeeEligibleOption);
        if (
            employeeEligibleOption.text !== translateReviewState(props.employeeReview.reviewState)
        ) {
            setShouldUpdate(true);
        }
    };

    const onRenderOption = (option: IDropdownOption | undefined): JSX.Element => {
        return (
            <Stack horizontal>
                <div
                    style={{
                        background: getEmployeeReviewStateBackground(
                            getEmployeeReviewStatusRecordValue(option?.text),
                        ),
                        width: 20,
                        height: 20,
                        marginRight: 5,
                    }}></div>
                <div>{option?.text}</div>
            </Stack>
        );
    };

    return (
        <div>
            {props.reviewPeriod?.state === ReviewStatusNames.APPROVAL ? (
                <Dropdown
                    styles={dropdownStyles}
                    options={employeeReviewStateOptions}
                    placeholder='Select a Status'
                    selectedKey={selectedStatus?.key}
                    dropdownWidth={130}
                    onChange={onStatusSelect}
                    onRenderOption={onRenderOption}
                />
            ) : (
                <Badge
                    text={getEmployeeReviewStatusText(props.employeeReview?.reviewState)}
                    backgroundColor={getEmployeeReviewStateBackground(
                        props.employeeReview?.reviewState ?? '',
                    )}
                />
            )}
            <ScaEmployeeEligibilityDialog
                employeeName={props.employeeReview.name}
                isEmpEligNoticeDialogOpen={isEmpEligNoticeDialogOpen}
                onAcceptEligibilityDialog={onAcceptEligibilityDialog}
                hideEligibilityNoticeDialog={hideEligibilityNoticeDialog}
            />
            <Dialog
                hidden={!(isSubmitInProgress || !!submitError)}
                modalProps={{
                    isBlocking: true,
                }}
                dialogContentProps={{
                    title: 'Updating employee record',
                }}>
                {isSubmitInProgress && (
                    <CommonSpinner label='Updating ...' ariaLive='assertive' labelPosition='left' />
                )}
                {submitError && (
                    <>
                        <MessageBar
                            onDismiss={resetSubmitError}
                            messageBarType={MessageBarType.error}
                            dismissButtonAriaLabel='Close'>
                            {submitError}
                        </MessageBar>
                        <DialogFooter>
                            <DefaultButton text='Dismiss' onClick={resetSubmitError} />
                        </DialogFooter>
                    </>
                )}
            </Dialog>
        </div>
    );
}

const dropdownStyles = {
    dropdown: {
        width: 150,
    },
    callout: {
        minWidth: 150,
    },
};
