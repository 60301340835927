import { IAuthContext } from 'contexts/auth-context';
import { IUserContext } from 'contexts/user-context';
import FacilitiesClient, {
    IFacilityRecord,
    IFacilityTimeslotItem,
    IReservationRecord,
    IReservationSeatChangeRequest,
    IReservationSeatChangeResponse,
    ISeatAvailabilityCalendarResponse,
    ISeatRecord,
    ITimeslotSeatStatus,
    ReservationState,
    SeatStatuses,
    generateReservationId,
} from 'clients/facilities-client';
import ClientUtils from 'clients/client-utils';

export interface IMakeReservationInfo {
    timeslot: IFacilityTimeslotItem;
    seatId: string;
    facility: IFacilityRecord;
}

export interface ICancelationInfo {
    timeslot:
        | ITimeslotSeatStatus
        | { timeslotItem: IFacilityTimeslotItem; seatStatus: { seatId: string } };
    facility: IFacilityRecord;
}

export interface IRescheduleInfo {
    currentTimeSlot: ITimeslotSeatStatus;
    changeToSeatId: string;
    facility: IFacilityRecord;
}

export interface IReservationInstructions {
    facility: IFacilityRecord;
    make?: IMakeReservationInfo[];
    reschedule?: IRescheduleInfo[];
    cancel?: ICancelationInfo[];
}

export interface IReservationPromiseInstruction {
    type: 'make' | 'reschedule' | 'cancel';
    instruction: IMakeReservationInfo | IRescheduleInfo | ICancelationInfo;
    promise: Promise<IReservationRecord | IReservationSeatChangeResponse | void>;
}

export function findSeatName(
    seatId: string,
    seats: ISeatRecord[] | { id: string; seatName: string }[],
): string {
    const seat = seats.find((x) => x.id === seatId);
    return seat?.seatName ?? '';
}

export function findSeatNames(
    seatIds: string[],
    seats: ISeatRecord[] | { id: string; seatName: string }[],
): string[] {
    const seatNames = new Set<string>();
    for (const seatId of seatIds) {
        const seatName = findSeatName(seatId, seats);
        seatNames.add(seatName);
    }
    return [...seatNames];
}

export function createReschedulePromises(
    authContext: IAuthContext,
    userContext: IUserContext,
    reschedules?: IRescheduleInfo[],
    icmDescription?: string,
): IReservationPromiseInstruction[] {
    const promises = [] as IReservationPromiseInstruction[];
    for (const reschedule of reschedules ?? []) {
        const request: IReservationSeatChangeRequest = {
            facilityId: reschedule.facility.id,
            oldSeatId: reschedule.currentTimeSlot.seatStatus.seatId,
            newSeatId: reschedule.changeToSeatId,
            timeslot: reschedule.currentTimeSlot.timeslotItem.startDateTimeUTCMilliseconds,
            icmDescription: icmDescription,
        };

        promises.push({
            type: 'reschedule',
            instruction: reschedule,
            promise: ClientUtils.withRetry(
                async () =>
                    await FacilitiesClient.changeSeatReservation(authContext, userContext, request),
            ),
        });
    }
    return promises;
}

//Utilizes makes provided to create preclaims
export function createPreclaimPromises(
    authContext: IAuthContext,
    userContext: IUserContext,
    make?: IMakeReservationInfo[],
): IReservationPromiseInstruction[] {
    const promises = [] as IReservationPromiseInstruction[];
    for (const createTimeSlot of make ?? []) {
        promises.push({
            type: 'make',
            instruction: createTimeSlot,
            promise: ClientUtils.withRetry(
                async () =>
                    await FacilitiesClient.preclaimSeatReservation(
                        authContext,
                        userContext,
                        createTimeSlot.facility.id,
                        createTimeSlot.seatId,
                        createTimeSlot.timeslot.startDateTimeUTCMilliseconds,
                    ),
            ),
        });
    }
    return promises;
}

// Creates a preclaim and then claim the result together in one step
export function createMakePromises(
    authContext: IAuthContext,
    userContext: IUserContext,
    make?: IMakeReservationInfo[],
    icmDescription?: string,
): IReservationPromiseInstruction[] {
    const promises = [] as IReservationPromiseInstruction[];
    for (const createTimeSlot of make ?? []) {
        promises.push({
            type: 'make',
            instruction: createTimeSlot,
            promise: ClientUtils.withRetry(
                async () =>
                    await FacilitiesClient.preclaimSeatReservation(
                        authContext,
                        userContext,
                        createTimeSlot.facility.id,
                        createTimeSlot.seatId,
                        createTimeSlot.timeslot.startDateTimeUTCMilliseconds,
                    ),
            ).then((y) => {
                return ClientUtils.withRetry(
                    async () =>
                        await FacilitiesClient.confirmSeatReservation(
                            authContext,
                            userContext,
                            y.facilityId,
                            y.claimCode.code,
                            icmDescription ?? '',
                        ),
                );
            }),
        });
    }
    return promises;
}

export function createCancelPromises(
    authContext: IAuthContext,
    userContext: IUserContext,
    cancels?: ICancelationInfo[],
): IReservationPromiseInstruction[] {
    const promises = [] as IReservationPromiseInstruction[];
    for (const cancel of cancels ?? []) {
        promises.push({
            type: 'cancel',
            instruction: cancel,
            promise: ClientUtils.withRetry(
                async () =>
                    await FacilitiesClient.cancelSeatReservation(
                        authContext,
                        userContext,
                        generateReservationId(
                            cancel.facility.id,
                            cancel.timeslot.seatStatus.seatId,
                            cancel.timeslot.timeslotItem.startDateTimeUTCMilliseconds,
                        ),
                    ),
            ),
        });
    }
    return promises;
}

// Instruction provided is to cancel reservation, therefor the timeslot in question should be marked
// as Available
export function applyCancelInstruction(
    instruction: IReservationPromiseInstruction,
    iSeatAvailabilityCalendarResponse: ISeatAvailabilityCalendarResponse,
): void {
    const instruct = instruction.instruction as ICancelationInfo;
    const timeslot = getTimeSlotSeatStatus(
        instruct.timeslot.seatStatus.seatId,
        instruct.timeslot.timeslotItem.startDateTimeUTCMilliseconds,
        iSeatAvailabilityCalendarResponse,
    );
    if (timeslot) {
        timeslot.seatStatus.status = SeatStatuses.Available.value;
    }
}

// Instruction provided is to 'make' reservation, therefor the timeslot in question should be marked
// as MySeat and Reserved.
export function applyMakeInstruction(
    instruction: IReservationPromiseInstruction,
    iSeatAvailabilityCalendarResponse: ISeatAvailabilityCalendarResponse,
): void {
    const instruct = instruction.instruction as IMakeReservationInfo;
    const timeslot = getTimeSlotSeatStatus(
        instruct.seatId,
        instruct.timeslot.startDateTimeUTCMilliseconds,
        iSeatAvailabilityCalendarResponse,
    );
    if (timeslot) {
        timeslot.seatStatus.status = SeatStatuses.MySeat.value;
        timeslot.seatStatus.reservationState = ReservationState.Confirmed;
    }
}

// Instruction provided is to 'make' reservation, therefor the timeslot in question should be marked
// as MySeat and Reserved.
export function applyRescheduleInstruction(
    instruction: IReservationPromiseInstruction,
    iSeatAvailabilityCalendarResponse: ISeatAvailabilityCalendarResponse,
): void {
    const instruct = instruction.instruction as IRescheduleInfo;
    // make current Timeslot avaliable
    const startDateTimeUTCMilliseconds =
        instruct.currentTimeSlot.timeslotItem.startDateTimeUTCMilliseconds;
    const previousTimeSlot = getTimeSlotSeatStatus(
        instruct.currentTimeSlot.seatStatus.seatId,
        startDateTimeUTCMilliseconds,
        iSeatAvailabilityCalendarResponse,
    );
    if (previousTimeSlot) {
        previousTimeSlot.seatStatus.status = SeatStatuses.Available.value;
    }

    const newTimeslot = getTimeSlotSeatStatus(
        instruct.changeToSeatId,
        startDateTimeUTCMilliseconds,
        iSeatAvailabilityCalendarResponse,
    );
    if (newTimeslot) {
        newTimeslot.seatStatus.status = SeatStatuses.MySeat.value;
        newTimeslot.seatStatus.reservationState = ReservationState.Confirmed;
    }
}

// Finds the ITimeslotSeatStatus from the ISeatAvailabilityCalendarResponse provided.
// There should only be 1 ITimeslotSeatStatus for the seatId and startDateTimeUTCMilliseconds
// across the ISeatAvailabilityCalendarResponse provided.
function getTimeSlotSeatStatus(
    seatId: string,
    startDateTimeUTCMilliseconds: number,
    iSeatAvailabilityCalendarResponse: ISeatAvailabilityCalendarResponse,
): ITimeslotSeatStatus | undefined {
    for (const seatStatus of iSeatAvailabilityCalendarResponse.seatsTimeslotStatusesForDates) {
        const seatTimeSlots = seatStatus.seatTimeslotsStatusesForDateDict[seatId];
        if (seatTimeSlots) {
            const timeslot = seatTimeSlots.find(
                (x) => x.timeslotItem.startDateTimeUTCMilliseconds === startDateTimeUTCMilliseconds,
            );
            if (timeslot) {
                return timeslot;
            }
        }
    }
}
