import { globalStyles } from 'assets/styles/global-styles';
import { Modal, ModalSizeType } from 'components/common/modal';
import React from 'react';

type FormsUnavailableModalProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};
export function FormsUnavailableModal(props: FormsUnavailableModalProps): JSX.Element {
    const { isOpen, setIsOpen } = props;
    return (
        <Modal
            isOpen={isOpen}
            shouldHideCancelButton={true}
            onCancel={(): void => setIsOpen(false)}
            onSubmit={(): void => setIsOpen(false)}
            submitButtonText='Cancel'
            size={ModalSizeType.standard}
            title='Citizenship Verification Unavailable'>
            <p>
                External staff can NOT have their citizenship verified internally at Microsoft.
                Email the HR screen sub team,{' '}
                <a href='mailto:screensu@microsoft.com' className={globalStyles.link}>
                    screensu@microsoft.com
                </a>
                , for the external staff citizenship attestation and instructions.
            </p>
        </Modal>
    );
}
