import { IconNames, noDataText } from 'assets/constants/global-constants';
import EmployeeClient, { IEmployee } from 'clients/employee-client';
import FacilitiesClient, { IFacilityRecord, ILogBookRecord } from 'clients/facilities-client';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { handleUTCToFacilityTimeZone } from 'components/facilities/common/facility-time-utils';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { Label } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useIsMounted } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';
import { TimeFormats } from 'utils/time-utils';

export interface DeleteLogBookEntryModalProps {
    facility?: IFacilityRecord;
    logBookRecord: ILogBookRecord;
    updateLogBookOnDelete: (logBookRecord: ILogBookRecord) => void;
}

export default function DeleteLogBookEntryModal(props: DeleteLogBookEntryModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [employee, setEmployee] = useState<IEmployee>();

    const isMounted = useIsMounted();

    const deleteLogBookEntry = async (): Promise<void> => {
        try {
            await FacilitiesClient.deleteLogBookRecord(
                authContext,
                userContext,
                props.logBookRecord.facilityId,
                props.logBookRecord.personnelId,
            );
            props.updateLogBookOnDelete(props.logBookRecord);
        } catch (e) {
            throw 'An error occurred deleting the log book entry.';
        }
    };

    const getEmployeeInfo = async (): Promise<void> => {
        if (!props.logBookRecord.personnelId) {
            return;
        }

        try {
            const employeeVar = await EmployeeClient.getEmployeeByAliasOrId(
                authContext,
                props.logBookRecord.personnelId,
            );
            if (isMounted()) {
                setEmployee(employeeVar);
            }
        } catch (e) {
            throw 'An error occurred getting log book entry employee.';
        }
    };

    useEffect(() => {
        getEmployeeInfo();
    }, [props.logBookRecord.personnelId]);

    return (
        <ModalActionButton<void>
            text={'Delete'}
            iconName={IconNames.Delete}
            modalTitle={'Delete Log Book Entry?'}
            enableSubmit={true}
            onSubmit={deleteLogBookEntry}
            onModalConcluded={doNothing}
            submitButtonText={'Delete'}>
            <div>
                <Label>Employee</Label>
                {employee ? (
                    <EmployeeBasicHoverCard personnelId={employee.id} key={employee.id} />
                ) : (
                    noDataText
                )}
            </div>
            <div>
                <Label>Signed In At</Label>
                {handleUTCToFacilityTimeZone(
                    props.logBookRecord.createdTimestamp.atUtc,
                    props.facility,
                    TimeFormats.MMMDDYYYY_hmmA,
                    '',
                )}
            </div>
            <div>
                <Label>Signed In By</Label>
                <EmployeeBasicHoverCard
                    personnelId={props.logBookRecord.createdTimestamp.by}
                    key={props.logBookRecord.createdTimestamp.by}
                />
            </div>
        </ModalActionButton>
    );
}
