import { DatePicker, Label, mergeStyles } from '@fluentui/react';
import React, { useState, useMemo, useEffect } from 'react';
import { TableOption } from 'components/forms/forms-common';
import { DateElementTimePicker } from 'components/forms/element-viewer/date-viewer/time-picker';
import { TableRow } from 'components/forms/element-viewer/table-viewer';

type TableDateViewerProps = {
    value: string;
    tableOption: TableOption;
    onRenderLabel: (tableOption: TableOption) => JSX.Element;
    setEditingRow: (value: React.SetStateAction<TableRow | undefined>) => void;
};

const tableTimePickerContainer = mergeStyles({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '1rem',
});

export default function TableDateViewer(props: TableDateViewerProps) {
    const { value, tableOption, onRenderLabel, setEditingRow } = props;
    const [time, setTime] = useState(value.split('T')[1]);

    useEffect(() => setTime(value.split('T')[1]), [value]);

    const datePart = useMemo(() => {
        if (!value) {
            return undefined;
        }

        if (value.includes('T')) {
            const dateWithTime = new Date(value);
            dateWithTime.setHours(0);
            dateWithTime.setMinutes(0);
            return dateWithTime;
        }
        return new Date(`${value}T00:00`);
    }, [value]);

    const updateTime = (timeValue: string): void => {
        setTime(timeValue);
        if (value) {
            const dateOnly = value.split('T')[0];
            onValueChange(dateOnly, timeValue);
        }
    };

    const onValueChange = (date: string, time: string) => {
        if (date) {
            setEditingRow((prev) => {
                if (prev) {
                    const datePart = date.split('T')[0];
                    const dateTime = time ? `${datePart}T${time}` : datePart;
                    const newItem = { ...prev };
                    newItem.item[tableOption.key] = dateTime;
                    return newItem;
                }
            });
        }
    };

    const createDateString = (value: Date): string => {
        return `${value.getFullYear()}-${(value.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${value.getDate().toString().padStart(2, '0')}`;
    };

    return (
        <>
            <Label>{onRenderLabel(tableOption)}</Label>
            <div className={tableTimePickerContainer}>
                <DatePicker
                    styles={{ root: { flexGrow: '1' } }}
                    value={datePart}
                    placeholder='Select a date...'
                    aria-labelledby={tableOption.key}
                    onSelectDate={(newValue): void =>
                        onValueChange(newValue ? createDateString(newValue) : '', time)
                    }
                    allowTextInput
                />
                {tableOption.hasTime && (
                    <DateElementTimePicker
                        key={time}
                        onChange={(newValue): void => updateTime(newValue)}
                        value={time}
                    />
                )}
            </div>
        </>
    );
}
