import {
    CheckboxVisibility,
    DetailsHeader,
    DetailsList,
    IColumn,
    IDetailsHeaderProps,
    IObjectWithKey,
    IRenderFunction,
    IStackTokens,
    Label,
    MessageBar,
    MessageBarType,
    ProgressIndicator,
    Selection,
    SelectionMode,
    Stack,
    TextField,
    mergeStyleSets,
    mergeStyles,
} from '@fluentui/react';
import { IconNames, xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { detailsListStyles } from 'assets/styles/list-styles';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import GroupClient, { IMyGroup } from 'clients/group-client';
import Badge from 'components/common/badge';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { CoreSinglePrincipalRecordPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { ModalSizeType } from 'components/common/modal';
import Spacer from 'components/common/spacer';
import { TableCell } from 'components/common/table';
import LabelInfoIcon from 'components/common/use-input/info-icon-label';
import useMessageBar from 'components/common/use-message-bar';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import { canNominate } from 'components/groups/groups-utils';
import { getIsMemberColor } from 'components/groups/my-groups/my-groups-utils';
import { AuthContext } from 'contexts/auth-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import React, { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import { readErrorMessageBody } from 'utils/misc-utils';
import { strCmp } from 'utils/sort-utils';

export const NominateJustificationMaxLen = 2000;

export const enum Pages {
    MemberCheckBulk,
    NominateMemberBulk,
}

interface MemberBulkModalActionButtonProps {
    startPage: Pages;
    selectedGroups: IMyGroup[];
}

export default function MemberBulkModalActionButton(
    props: MemberBulkModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const principalUserContext = useContext(PrincipalUserContext);

    const [items, setItems] = useState<IMyGroup[]>(props.selectedGroups);
    const [groupIdsToNominate, setGroupIdsToNominate] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState<Pages>();
    const [nominee, setNominee] = useState<IPrincipalRecord>();

    const [selection, setSelection] = useState<Selection<IObjectWithKey>>();
    const [columns, setColumns] = useState<IColumn[]>();

    const [sponsor, setSponsor] = useState<IPrincipalRecord>();
    const [justification, setJustification] = useState<string>();

    const [isAnyGroupRequireSponsor, setIsAnyGroupRequireSponsor] = useState<boolean>(false);
    const [isAnyGroupRequireJustification, setIsAnyGroupRequireJustification] = useState<boolean>(
        false,
    );

    const [groupIdToGroupNameMap, setGroupIdToGroupNameMap] = useState<Map<string, string>>(
        new Map<string, string>(),
    );

    const [nominationErrorMsgs, setNominationErrorMsg] = useState<string[]>();
    const [isDoNominateLoading, setIsDoNominateLoading] = useState<boolean>(false);

    useEffect(() => {
        updateSelection();
    }, [items]);

    useEffect(() => {
        if (nominee) {
            const fetchAndUpdateTable = async (): Promise<void> => {
                //fetch and update employee member status and manager in employee card.
                try {
                    await fetchAndSetEmployeeMemberStatus();
                } catch (e) {
                    setNominationWarningMsg('Failed to retrieve employee member status.');
                }
            };

            fetchAndUpdateTable();
        } else {
            const myGroupsWithoutEmployeeMemberStatus = [...items];
            myGroupsWithoutEmployeeMemberStatus.forEach((myGroup) => {
                myGroup.isMember = undefined;
            });
            setItems(myGroupsWithoutEmployeeMemberStatus);
        }
    }, [nominee]);

    const fetchAndSetEmployeeMemberStatus = async (): Promise<void> => {
        if (nominee?.id) {
            const data = await GroupClient.checkMembershipBulk(
                authContext,
                items.map((x) => x.id),
                nominee?.id ?? '',
            );

            const groupIdToIsMemberMap = new Map<string, boolean>(Object.entries(data));
            const myGroupsWithEmployeeMemberStatus = [...items];

            myGroupsWithEmployeeMemberStatus.forEach((myGroup) => {
                myGroup.isMember = groupIdToIsMemberMap.get(myGroup.id) || false;
            });

            setItems(myGroupsWithEmployeeMemberStatus);
        }
    };

    const {
        theElement: nominationSuccessMsgBar,
        setMessage: setNominationSuccessMsg,
        clearMessage: clearNominationSuccessMsg,
    } = useMessageBar({ type: MessageBarType.success });

    const {
        theElement: nominationWarningMsgBar,
        setMessage: setNominationWarningMsg,
        clearMessage: clearNominationWarningMsg,
    } = useMessageBar({ type: MessageBarType.severeWarning });

    const clearMessages = (): void => {
        clearNominationSuccessMsg();
        setNominationErrorMsg(undefined);
        clearNominationWarningMsg();
    };

    const UseNominationErrorMsgBar = (): JSX.Element => {
        return (
            <>
                <MessageBar
                    onDismiss={(): void => {
                        setNominationErrorMsg(undefined);
                    }}
                    messageBarType={MessageBarType.error}
                    dismissButtonAriaLabel='Close'>
                    <>
                        {nominationErrorMsgs?.map((msg, i) => {
                            return (
                                <>
                                    {i === 0 ? msg : <>&nbsp;&nbsp;{msg}</>}
                                    <br />
                                </>
                            );
                        })}
                    </>
                </MessageBar>
            </>
        );
    };

    const updateSelection = (): void => {
        //init selection
        const newSelection = new Selection<IMyGroup>({
            items: items,
            getKey: (item) => item.id,
            onSelectionChanged: (): void => {
                const selectedGroups = newSelection.getSelection();
                const selctedGroupIds = selectedGroups.map((x) => x.id);
                setGroupIdsToNominate(selctedGroupIds);

                setIsAnyGroupRequireJustification(
                    selectedGroups.some((selectedGroup) => selectedGroup.requireJustification),
                );
                setIsAnyGroupRequireSponsor(
                    selectedGroups.some((selectedGroup) => selectedGroup.requireSponsor),
                );
            },
            canSelectItem(item): boolean {
                return !item.isMember && canNominate(item) && !item.enableDynamic;
            },
        });

        newSelection?.setAllSelected(true);
        setSelection(newSelection as Selection<IObjectWithKey>);

        //set group Ids selected
        setGroupIdsToNominate(newSelection.getSelection().map((x) => x.id));
    };

    const doNominate = async (): Promise<boolean> => {
        if (principalUserContext.principalRecord.id === nominee?.id) {
            setNominationErrorMsg(['You cannot nominate yourself']);
            return false;
        }

        try {
            setIsDoNominateLoading(true);

            const request = {
                groupIds: groupIdsToNominate,
                personnel: nominee?.id ?? '',
                sponsorId: sponsor?.id ?? '',
                justification: justification ?? '',
            };
            const response = await GroupClient.createBulkNominateRequest(authContext, request);

            const successGroupIds = response
                .filter((item) => item.isSuccess)
                .map((success) => success.groupId);

            const failedGroups = response.filter((item) => !item.isSuccess);

            if (successGroupIds.length > 0) {
                const successGroupNames = items
                    .filter((myGroup) => successGroupIds.includes(myGroup.id))
                    .map((successGroup) => successGroup.name);
                setNominationSuccessMsg(
                    `You have nominated ${nominee?.displayName} (${
                        nominee?.upn
                    }) for: ${successGroupNames.join(', ')}`,
                );
            }

            if (failedGroups.length > 0) {
                const groupedErrors = groupBy(failedGroups, (item) => item.message);
                const errorMessages = Object.entries(groupedErrors).map((error) => {
                    const groupResponses = error[1];
                    const groupNames = groupResponses.map((groupResponse) =>
                        groupIdToGroupNameMap.get(groupResponse.groupId),
                    );

                    return `${error[0]} (${groupNames.join(', ')})`;
                });

                errorMessages.unshift(`Failed to create nomination for ${nominee?.displayName} (${nominee?.upn}) to the
                    following groups:`);

                setNominationErrorMsg(errorMessages);
            }

            return true;
        } catch (e) {
            const error = e as Response;
            switch (error.status) {
                case 400:
                    const errorMessage = await readErrorMessageBody(e);
                    if (!!errorMessage) {
                        throw errorMessage;
                    } else {
                        throw 'Error encountered when trying to nominate employee';
                    }
                case 404:
                    throw 'Unable to locate employee record';
                default:
                    throw 'Error encountered when trying to nominate employee';
            }
        } finally {
            setIsDoNominateLoading(false);
        }
    };

    const onNomineeSelectedHandler = (info?: IPrincipalRecord): void => {
        clearMessages();
        setNominee(info);
    };

    const onSponsorSelectedHandler = (info?: IPrincipalRecord): void => {
        clearMessages();

        if (info) {
            setSponsor(info);
        } else {
            setSponsor(undefined);
        }
    };

    const onButtonClick = (): void => {
        setItems(sortGroupsByName([...props.selectedGroups]));
        setGroupIdToGroupNameMap(new Map(props.selectedGroups.map((x) => [x.id, x.name])));
        setColumns(tableColumns);
        setCurrentPage(props.startPage);
        setNominee(undefined);
        setSponsor(undefined);
        setJustification('');
        clearMessages();
    };

    const onJustificationChange = (
        event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue: string | undefined,
    ): void => {
        if ((newValue?.length ?? 0) <= NominateJustificationMaxLen) {
            setJustification(newValue);
        }
    };

    const onNominateMemberSubmit = async (): Promise<boolean> => {
        return await doNominate();
    };

    const goToMemberCheck = async (): Promise<void> => {
        clearMessages();
        setCurrentPage(Pages.MemberCheckBulk);
        setColumns(tableColumns);
    };

    const onModalConcluded = (
        modalConclusion: ModalConclusion,
        result: boolean | undefined,
    ): void => {
        if (modalConclusion === ModalConclusion.Cancel) {
            setNominee(undefined);
            setSponsor(undefined);
        } else if (modalConclusion === ModalConclusion.Done) {
            if (result) {
                setNominee(undefined);
                setSponsor(undefined);
                setJustification('');
                selection?.setAllSelected(true);
            }
        }
    };

    const {
        modalTitle,
        isSubmitEnabled,
        submitButtonText,
        onLeftMiscButton1Click: onLeftMiscButton1Click,
        isShowSponsorJustification,
        shouldKeepOpenAfterSubmit,
        onSubmit,
    } = useMemo(() => {
        if (currentPage === Pages.MemberCheckBulk) {
            return {
                modalTitle: 'Member Check',
                isSubmitEnabled: nominee !== undefined && groupIdsToNominate.length > 0,
                submitButtonText: 'Nomination Review',
                onLeftMiscButton1Click: undefined,
                isShowSponsorJustification: false,
                shouldKeepOpenAfterSubmit: true,
                onSubmit: async (): Promise<void> => {
                    setCurrentPage(Pages.NominateMemberBulk);
                },
            };
        } else {
            return {
                modalTitle: 'Nominate Member',
                isSubmitEnabled:
                    !!nominee &&
                    (isAnyGroupRequireSponsor ? !!sponsor : true) &&
                    (isAnyGroupRequireJustification
                        ? !!justification && justification.trim().length > 0
                        : true) &&
                    groupIdsToNominate.length > 0,
                submitButtonText: 'Nominate',
                onLeftMiscButton1Click: goToMemberCheck,
                isShowSponsorJustification: true,
                shouldKeepOpenAfterSubmit: true,
                onSubmit: onNominateMemberSubmit,
            };
        }
    }, [
        currentPage,
        nominee,
        groupIdsToNominate,
        sponsor,
        justification,
        isAnyGroupRequireSponsor,
        isAnyGroupRequireJustification,
    ]);

    const sortGroupsByName = (groups: IMyGroup[]): IMyGroup[] => {
        return [...groups].sort((a, b) => {
            return strCmp(a.name.toLocaleLowerCase(), b.name.toLocaleLowerCase());
        });
    };

    const stackTokens: IStackTokens = {
        childrenGap: 50,
    };

    const renderDetailsHeader = (): IRenderFunction<IDetailsHeaderProps> => (headerProps) => {
        if (!headerProps) {
            return null;
        }

        return <DetailsHeader styles={{ root: { padding: '0px 0px 0px 0px' } }} {...headerProps} />;
    };

    const customLabel = (label: string, required: boolean): JSX.Element => {
        return (
            <Label className={styles.labelStyle}>
                {label}
                {required ? <span className={styles.requiredStyle}> *</span> : ''}
            </Label>
        );
    };

    const showIsLoadingContent = (): JSX.Element => {
        return (
            <>
                <ProgressIndicator barHeight={2} />
            </>
        );
    };

    const memberCheckNominateBulkContent = (): JSX.Element => {
        return (
            <Stack>
                <Stack horizontal tokens={stackTokens}>
                    <Stack.Item>
                        <div style={{ width: '425px' }}>
                            <CoreSinglePrincipalRecordPickerTypeaheadSearch
                                label={
                                    currentPage === Pages.MemberCheckBulk
                                        ? 'Enter the employee you would like to perform member check for:'
                                        : 'Enter another employee or nominate the current employee:'
                                }
                                placeHolder='Employee Name or Alias'
                                onChange={onNomineeSelectedHandler}
                                selectedItem={nominee}
                            />
                        </div>
                        {nominee && currentPage === Pages.MemberCheckBulk && (
                            <Stack horizontal style={{ marginTop: '5px' }}>
                                <CoreEmployeeHoverCardFromPrincipalId
                                    principalId={nominee.id}
                                    key={`employee_minicard_${nominee.id}`}
                                />
                            </Stack>
                        )}
                        {isShowSponsorJustification && (
                            <>
                                <div style={{ marginTop: '15px' }}>
                                    <CoreSinglePrincipalRecordPickerTypeaheadSearch
                                        label='Sponsor:'
                                        required={isAnyGroupRequireSponsor}
                                        placeHolder='Sponsor Name or Alias'
                                        onChange={onSponsorSelectedHandler}
                                        selectedItem={sponsor}
                                    />
                                </div>
                                <div>
                                    <TextField
                                        styles={{ root: { marginTop: '15px' } }}
                                        rows={5}
                                        multiline
                                        value={justification}
                                        resizable={false}
                                        description={`${
                                            justification?.length ?? 0
                                        } / ${NominateJustificationMaxLen}`}
                                        onChange={onJustificationChange}
                                        onRenderLabel={(): JSX.Element =>
                                            customLabel(
                                                'Business Justification:',
                                                isAnyGroupRequireJustification,
                                            )
                                        }
                                    />
                                </div>
                            </>
                        )}
                    </Stack.Item>
                    <Stack.Item>
                        <div
                            style={{
                                maxHeight: '500px',
                                overflowY: 'auto',
                                overflowX: 'auto',
                            }}>
                            <DetailsList
                                items={items}
                                setKey='groupId'
                                columns={columns}
                                selection={selection}
                                selectionMode={SelectionMode.multiple}
                                checkboxVisibility={CheckboxVisibility.always}
                                selectionPreservedOnEmptyClick={true}
                                isPlaceholderData={true}
                                compact={true}
                                onRenderDetailsHeader={renderDetailsHeader()}
                            />
                        </div>
                    </Stack.Item>
                </Stack>
                <Stack>
                    <Spacer marginTop={20} />
                    {nominationWarningMsgBar()}
                    {nominationSuccessMsgBar()}
                    {nominationErrorMsgs && UseNominationErrorMsgBar()}
                    {isDoNominateLoading && showIsLoadingContent()}
                </Stack>
            </Stack>
        );
    };

    /// Render
    return (
        <ModalActionButton
            enable={props.selectedGroups.length > 0}
            size={ModalSizeType.xLarge}
            fixWidth={true}
            buttonStyle={styles.button}
            modalTitleIcon={undefined}
            tooltipStyles={{ root: { verticalAlign: 'sub' } }}
            submitButtonText={submitButtonText}
            enableSubmit={isSubmitEnabled}
            onButtonClick={onButtonClick}
            onModalConcluded={onModalConcluded}
            text={props.startPage === Pages.MemberCheckBulk ? 'Member Check' : 'Nominate'}
            iconName={props.startPage === Pages.MemberCheckBulk ? IconNames.Search : IconNames.Add}
            modalTitle={modalTitle}
            onSubmit={onSubmit}
            keepOpenAfterSubmit={shouldKeepOpenAfterSubmit}
            leftMiscButton1Text='Member Check'
            onLeftMiscButton1={onLeftMiscButton1Click}>
            {memberCheckNominateBulkContent()}
        </ModalActionButton>
    );

    function tableColumns(): IColumn[] {
        const columnWidths = {
            group: 250,
            status: 125,
        };

        const columns: IColumn[] = [
            {
                key: 'Group',
                name: 'Group',
                ariaLabel: 'Group',
                minWidth: columnWidths.group,
                maxWidth: columnWidths.group * xLargeMaxWidthCoeff,
                onRender: (row: IMyGroup): JSX.Element => {
                    const isShowInfoBubble = row.isMember || !canNominate(row) || row.enableDynamic;

                    return (
                        <TableCell>
                            {isShowInfoBubble ? (
                                <LabelInfoIcon
                                    iconHoverContent={
                                        <ul className={styles.hoverCardList}>
                                            {row.isMember && (
                                                <li key={`${row.id}-info`}>
                                                    The selected employee is already a member of the
                                                    group.
                                                </li>
                                            )}
                                            {!canNominate(row) && (
                                                <li>
                                                    This group does not allow members to nominate.
                                                </li>
                                            )}
                                            {row.enableDynamic && (
                                                <li>
                                                    This group is not selectable because it&apos;s
                                                    set as dynamic.
                                                </li>
                                            )}
                                        </ul>
                                    }
                                    iconName={IconNames.Info}>
                                    {'  '}
                                    <div style={{ fontWeight: 400, marginTop: '-3px' }}>
                                        <EllipsisTextCss text={row.name} />
                                    </div>
                                </LabelInfoIcon>
                            ) : (
                                <EllipsisTextCss text={row.name} />
                            )}
                        </TableCell>
                    );
                },
            },
            {
                key: 'Status',
                name: 'Status',
                ariaLabel: 'Status',
                minWidth: columnWidths.status,
                maxWidth: columnWidths.status * xLargeMaxWidthCoeff,
                onRender: (row: IMyGroup): JSX.Element => {
                    return (
                        <TableCell>
                            <Badge
                                fontWeight={'600'}
                                text={
                                    row.isMember === undefined
                                        ? 'Unknown'
                                        : row.isMember
                                        ? 'Member'
                                        : 'Not a member'
                                }
                                backgroundColor={getIsMemberColor(row.isMember)}
                            />
                        </TableCell>
                    );
                },
            },
        ];

        return columns;
    }
}

const styles = mergeStyleSets({
    nominateLabel: {
        fontWeight: 600,
        marginBottom: 5,
        display: 'inline-block',
    },
    button: mergeStyles(detailsListStyles.icon, {
        height: 'auto',
    }),
    hoverCardList: {
        paddingRight: 20,
    },
    icon: {
        fontSize: '1rem',
        marginRight: '7px',
    },
    labelStyle: {
        fontWeight: 600,
        marginBottom: 5,
        display: 'inline-block',
    },
    requiredStyle: {
        color: 'rgb(164, 38, 44)',
        paddingRight: '12px',
    },
});

const groupBy = <IGroupNominationResponse, K extends keyof any>(
    list: IGroupNominationResponse[],
    getKey: (item: IGroupNominationResponse) => K,
) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, IGroupNominationResponse[]>);
