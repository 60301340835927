import React from 'react';
import { TextField, IPersonaProps, mergeStyleSets, DatePicker } from '@fluentui/react';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
export interface CreateNewProfileStepperPageTwoProps {
    positionControlNumber?: string;
    jobTitle?: string;
    company?: string;
    selectedDate?: Date | null;
    selectedManagerPersona?: IPersonaProps;
    onPositionControlNumberChange: (newValue?: string) => void;
    onJobTitleChange: (newValue?: string) => void;
    onCompanyChange: (newValue?: string) => void;
    onDateChange: (newDate?: Date | null) => void;
    onSelectedManagerChange: (selectedManagerPersona?: IPersonaProps) => void;
}

const styles = mergeStyleSets({
    marginTop15: {
        marginTop: 15,
    },
    marginBottom15: {
        marginBottom: 15,
    },
});

export default function CreateNewProfileStepperPageTwo(
    props: CreateNewProfileStepperPageTwoProps,
): JSX.Element {
    return (
        <>
            <TextField
                label='Position Control Number'
                value={props.positionControlNumber || ''}
                onChange={(ev, newValue): void => props.onPositionControlNumberChange(newValue)}
                className={styles.marginBottom15}
                placeholder='Enter existing PCN, must be 8-digit numeric characters only; e.g. 12848290'
            />
            <EmployeePickerTypeaheadSearch
                onCandidateSelected={props.onSelectedManagerChange}
                selectedPersona={props.selectedManagerPersona}
                label='Hiring Manager'
                placeHolder="Enter Hiring Manager's Name or Alias"
            />
            <DatePicker
                ariaLabel='Select an Expected Start Date'
                label='Start Date'
                value={props.selectedDate!}
                onSelectDate={props.onDateChange}
                className={styles.marginTop15}
                placeholder='Enter Start Date'
            />
            <TextField
                label='Job Title'
                value={props.jobTitle || ''}
                onChange={(ev, newValue): void => props.onJobTitleChange(newValue)}
                className={styles.marginTop15}
                placeholder='Enter Job Title'
            />
            <TextField
                label='Company'
                value={props.company || ''}
                onChange={(ev, newValue): void => props.onCompanyChange(newValue)}
                className={styles.marginTop15}
                placeholder='Enter Company'
            />
        </>
    );
}
