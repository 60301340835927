import {
    FontWeights,
    mergeStyles,
    MessageBarType,
    Panel,
    PanelType,
    Separator,
} from '@fluentui/react';
import EmailClient, { IEmailResult } from 'clients/email-client';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import BoldFont from 'components/common/misc/bold-font';
import Spacer from 'components/common/spacer';
import useMessageBar from 'components/common/use-message-bar';
import { AuthContext } from 'contexts/auth-context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSanitizeHtml } from 'utils/misc-hooks';
import { useUpOnePathLevel } from 'utils/misc-hooks';
import { dateToFormattedDateTimeStringFromSeconds } from 'utils/time-utils';
import { getResultCodeBadge } from 'components/email/email-utils';

const gridStyling = mergeStyles({
    display: 'grid',
    maxWidth: '1500px',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridAutoRows: 'auto',
    gridRowGap: '2.2rem',
    gridColumnGap: '0.1rem',
    marginTop: '2rem',
    marginLeft: '15%',
    marginRight: 'auto',
    marginBottom: '4rem',
    gridTemplateAreas: `
      'r1c1 r1c2 r1c3'
      'r2c1 r2c2 r2c3'
      'r3c1 r3c2 r3c3'
      'r4c1 r4c2 r4c3'
      `,
});

const headerStyling = mergeStyles({
    fontWeight: FontWeights.semilight,
    marginTop: '2rem',
});

const panelHeaderStyle = mergeStyles(headerStyling, {
    fontSize: 24,
    marginBottom: '2rem',
});

type IEmailDetailsProps = {
    emailId: string;
};

export default function EmailDetails(props: IEmailDetailsProps): JSX.Element {
    const { emailId } = props;
    const authContext = useContext(AuthContext);
    const [emailTemplate, setEmailTemplate] = useState<IEmailResult>();
    const parsedHTML = useSanitizeHtml(emailTemplate?.payload?.message?.body?.content ?? '');
    const upOnePathLevel = useUpOnePathLevel();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [isError, setIsError] = useState<boolean>(false);
    const {
        theElement: updateErrMsgElement,
        setMessage: setUpdateErrMsg,
        clearMessage: clearUpdateErrMsg,
    } = useMessageBar({ type: MessageBarType.error });

    useEffect(() => {
        const fetchEmail = async (): Promise<void> => {
            try {
                setIsLoading(true);
                clearUpdateErrMsg();
                setIsError(false);
                const email = await EmailClient.getEmailById(authContext, emailId);
                setEmailTemplate(email);
            } catch (e) {
                console.error(e);
                setUpdateErrMsg(
                    `Error fetching email with ID: ${emailId}. Please refresh to try again.`,
                );
                setIsError(true);
            } finally {
                setIsLoading(false);
            }
        };
        fetchEmail();
    }, [authContext, emailId]);

    useEffect(() => {
        setIsOpen(!!emailId);
    }, [emailId]);

    const onClosePanel = () => {
        setIsOpen(false);
        history.push(upOnePathLevel);
    };

    const CreateField = useCallback((label: string, value: string | string[], gridArea: string) => {
        const element = !Array.isArray(value) ? value : value.map((el) => <div key={el}>{el}</div>);

        return (
            <div style={{ gridArea: gridArea }}>
                <BoldFont>{label}</BoldFont>
                <div>{element}</div>
            </div>
        );
    }, []);

    const Header = useCallback((): JSX.Element => {
        return (
            <>
                {updateErrMsgElement()}
                <h1 className={headerStyling}>{`Email Details: ${emailId}`}</h1>
                <Separator />
            </>
        );
    }, [emailId, updateErrMsgElement]);

    const Content = useCallback((): JSX.Element => {
        if (isError) {
            return <Header />;
        }
        if (emailTemplate === undefined || isLoading) {
            return (
                <>
                    <Header />
                    <IsLoadingIndicator
                        isLoading={true}
                        before={<Spacer marginTop={60} />}
                        msg='Loading Emails...'
                    />
                </>
            );
        }
        return (
            <>
                <Header />
                <div className={gridStyling}>
                    {CreateField('Email ID', emailTemplate.record.id, 'r1c1')}
                    {CreateField(
                        'Application ID',
                        emailTemplate.record.senderApplicationId,
                        'r1c2',
                    )}
                    {CreateField('Subject', emailTemplate.payload.message.subject, 'r1c3')}
                    {CreateField('Sent As', emailTemplate.record.sendAsEmail, 'r2c1')}
                    {CreateField('To Recipients', emailTemplate.record.toRecipients, 'r2c2')}
                    {CreateField('CC Recipients', emailTemplate.record.ccRecipients, 'r2c3')}
                    {CreateField('Reply To', emailTemplate.record.replyTo, 'r3c1')}
                    {CreateField(
                        'Created At',
                        dateToFormattedDateTimeStringFromSeconds(
                            emailTemplate.record.createdTimeStamp,
                        ),
                        'r3c2',
                    )}
                    {CreateField(
                        'Sent At',
                        dateToFormattedDateTimeStringFromSeconds(
                            emailTemplate.record.sentTimeStamp,
                        ),
                        'r3c3',
                    )}
                    {CreateField(
                        'Is Html?',
                        emailTemplate.payload.message.body.isBodyHtml.toString(),
                        'r4c1',
                    )}
                    {CreateField('Sent?', emailTemplate.record.isSent.toString(), 'r4c2')}
                    <div style={{ gridArea: 'r4c3' }}>
                        <BoldFont>{'Send Result Code'}</BoldFont>
                        <div>
                            {getResultCodeBadge(
                                emailTemplate.record.sendResultCode,
                                emailTemplate.record.isSent,
                            )}
                        </div>
                    </div>
                </div>
                <h1 className={headerStyling}>{'Email View'}</h1>
                <Separator />
                <div
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    dangerouslySetInnerHTML={{ __html: parsedHTML }}
                />
            </>
        );
    }, [CreateField, Header, emailTemplate, isError, isLoading, parsedHTML]);

    return (
        <Panel
            headerClassName={panelHeaderStyle}
            isOpen={isOpen}
            onDismiss={(): void => onClosePanel()}
            isLightDismiss
            onLightDismissClick={(): void => onClosePanel()}
            type={PanelType.large}
            closeButtonAriaLabel='Close'>
            <Content />
        </Panel>
    );
}
