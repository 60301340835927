import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { AuthContext, RenderMode } from 'contexts/auth-context';
import GroupClient from 'clients/group-client';
import { getAppInsights, logMessage } from 'utils/telemetry-utils';
import { SeverityLevel } from '@microsoft/applicationinsights-common';

export const GroupsUserContext = createContext<IGroupsUserContext | undefined>(undefined);

export interface IGroupsUserProviderProps {
    children: ReactNode;
}

export default function GroupsUserContextProvider(props: IGroupsUserProviderProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const [isUserInAnyGroup, setUserGroupStatus] = useState<boolean>(false);
    const [isUserInAnyGroupReady, setUserGroupStatusReady] = useState<boolean>(false);

    useEffect(() => {
        (async (): Promise<void> => {
            const oid = authContext?.getUserProfile()?.oid;

            if (oid) {
                logMessage({
                    method: 'Groups-User-Context',
                    message: `Getting user types and status for ${oid}. `,
                    severityLevel: SeverityLevel.Information,
                });

                // Facilities kiosk doesn't care about groups
                if (authContext.renderMode() === RenderMode.Web) {
                    try {
                        const isUserInAnyGroup: boolean = await GroupClient.isUserInAnyGroup(
                            authContext,
                        );

                        setUserGroupStatus(isUserInAnyGroup);
                        logMessage({
                            method: 'Groups-User-Context',
                            message: `Got user permissions for user ${oid}`,
                            severityLevel: SeverityLevel.Information,
                        });
                    } catch (e) {
                        getAppInsights()?.trackException({
                            exception: e,
                            severityLevel: SeverityLevel.Error,
                        });
                        console.error('Error with setting up permissions in userContext', e);
                    } finally {
                        setUserGroupStatusReady(true);
                    }
                }
            }
        })();
    }, [authContext]);

    return (
        <GroupsUserContext.Provider
            value={{
                isUserInAnyGroup,
                isUserInAnyGroupReady,
            }}>
            {props.children}
        </GroupsUserContext.Provider>
    );
}

export interface IGroupsUserContext {
    isUserInAnyGroup: boolean;
    isUserInAnyGroupReady: boolean;
}
