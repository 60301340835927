import React, { useEffect, useState } from 'react';
import { descriptionStyles, ElementTypeProps } from 'components/forms/forms-common';
import 'draft-js/dist/Draft.css';
import { RichTextEditor } from 'components/forms/rich-text-editor/rich-text-editor';
import {
    CompositeDecorator,
    ContentBlock,
    ContentState,
    convertFromHTML,
    EditorState,
    RawDraftEntity,
} from 'draft-js';
import { convertToHTML, IConvertToHTMLConfig, Tag } from 'draft-convert';
import { globalStyles } from 'assets/styles/global-styles';

export default function InfoElement(props: ElementTypeProps): JSX.Element {
    const { element, updateForm } = props;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const LinkEntity = (props: any): JSX.Element => {
        const { url } = props.contentState.getEntity(props.entityKey).getData();
        return <a href={url}>{props.children}</a>;
    };

    const findLinkEntities = (
        contentBlock: ContentBlock,
        callback: (start: number, end: number) => void,
        contentState: ContentState,
    ): void => {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
        }, callback);
    };

    const decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: LinkEntity,
        },
    ]);

    const getInitialEditorState = (): EditorState => {
        const fromHTML = convertFromHTML(element.description);
        const editorContentState = ContentState.createFromBlockArray(
            fromHTML.contentBlocks,
            fromHTML.entityMap,
        );
        return EditorState.createWithContent(editorContentState, decorator);
    };
    const [editorState, setEditorState] = useState(getInitialEditorState());

    const draftConvertOptions: IConvertToHTMLConfig = {
        entityToHTML: (entity: RawDraftEntity, originalText: string): Tag => {
            if (entity.type === 'LINK') {
                return (
                    <a
                        href={entity.data.url}
                        target='_blank'
                        rel='noreferrer'
                        className={globalStyles.link}>
                        {originalText}
                    </a>
                );
            }
            return originalText;
        },
    };

    useEffect(() => {
        const html = convertToHTML(draftConvertOptions)(editorState.getCurrentContent());
        updateForm(element.id, html, 'description');
    }, [editorState]);

    return (
        <div className={descriptionStyles}>
            <RichTextEditor editorState={editorState} setEditorState={setEditorState} />
        </div>
    );
}
