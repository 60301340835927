import { mergeStyleSets } from '@fluentui/react';
import React, { ReactNode } from 'react';
import { AppConstants } from 'components/common/constants';
import { detailsListStyles } from 'assets/styles/list-styles';

interface IExpandableColumnsContainerProps {
    isColumnsExpanded: boolean;
    children: ReactNode;
}

export default function ExpandableColumnsContainer(
    props: IExpandableColumnsContainerProps,
): JSX.Element {
    return (
        <div
            className={`${styles.theContainer} ${
                props.isColumnsExpanded ? detailsListStyles.expandedTable : ''
            }`}>
            {props.children}
        </div>
    );
}

const styles = mergeStyleSets({
    theContainer: {
        paddingLeft: AppConstants.padding,
    },
});
