import React, { LegacyRef } from 'react';
import { useContext, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { CheckAccessType, UserContext } from 'contexts/user-context';

export interface ICheckAccess {
    /**
     * User meets access requirements:
     *     It shows props.children.
     *
     * User does not meet access requirements.
     *     arePermissionsChecked = false
     *         It waits, doesn't deny access, nor does it show props.children.
     *     arePermissionsChecked = true
     *         Ok, it can deny access now. It either redirects
     *         to props.accessDeniedRedirect or shows props.accessDeniedContent.
     *         accessDeniedRedirect takes precedence over accessDeniedContent.
     */
    arePermissionsChecked?: boolean; // Default true. Read below.
    /**
     * URL to which CheckAccess should redirect if user does not have access.
     *
     * CAUTION
     *
     * If you want to use redirect, be sure to do the following:
     *   . If you don't need to call an API endpoint to determine permissions,
     *     in other words, if permissions are at your disposal when rendering
     *     your component, leave the prop arePermissionsChecked unconnected.
     *     Otherwise, there will be a delay from the time this module is
     *     first rendered till the time permissions are known. So, be sure
     *     to do the following.
     *   . Create a variable to drive the prop arePermissionsChecked (above).
     *   . Initialize it with false.
     *   . ONLY AFTER you check the permissions and determine whether the
     *     user has or doesn't have access, set the variable to true.
     *   . Drive the prop arePermissionsChecked with that variable.
     *
     * This all is to prevent redirection until permissions are checked
     * because if the module redirects, and later it's determined the
     * user did, indeed, have access, this module has already redirected
     * to the access denied page and doesn't go back, and we've blocked
     * a legitimate user from accessing a page.
     *
     * Even if you don't want to use redirect, it's better to drive this
     * prop with a variable as described above. That would prevent temporarily
     * showing "access denied" if user is permitted to see the contents.
     */
    accessDeniedRedirect?: string;

    /**
     * Content to display if it is determined that a user does not meet the proper access requirements.
     */
    accessDeniedContent?: JSX.Element | ReactNode | JSX.Element[] | ReactNode[];

    /**
     * Content to display if the user has met the proper access requirements.
     */
    children?: JSX.Element | ReactNode | JSX.Element[] | ReactNode[];
}

export interface ICheckAccessProps extends ICheckAccess {
    /**
     * Collection of Roles and User Types that are allowed access.
     */
    requiredAccessTypeAny?: CheckAccessType[];

    /**
     * Allow access when any of the items in this array are true.
     */
    hasRequiredPermissionsAny?: boolean[];
}

/**
 * Permit or Deny access based upon provided roles, user types, and permissions.
 */
const CheckAccess = React.forwardRef(
    (
        props: ICheckAccessProps,
        // "ref" will become a ref to the top-most <div> of this component.
        ref: LegacyRef<HTMLDivElement> | undefined,
    ): JSX.Element => {
        const userContext = useContext(UserContext);

        const hasAccess = (): boolean => {
            // Check arbitrary conditions to determine if permission should be granted.
            if (
                props.hasRequiredPermissionsAny &&
                props.hasRequiredPermissionsAny?.some((condition) => condition)
            ) {
                return true;
            }

            // Check Access Types.
            if (
                props.requiredAccessTypeAny &&
                userContext.hasAccessAny(props.requiredAccessTypeAny)
            ) {
                return true;
            }

            return false;
        };

        const determineContent = (): JSX.Element => {
            if (hasAccess()) {
                return <>{props.children}</>;
            } else if (props.arePermissionsChecked ?? true) {
                if (props.accessDeniedRedirect) return <Redirect to={props.accessDeniedRedirect} />;
                else if (props.accessDeniedContent) return <>{props.accessDeniedContent}</>;
                // Show nothing. This is suitable for hiding parts of the page.
                // Example: Hiding action buttons for users who don't have
                // permission to edit.
                else return <></>;
            } else {
                // Stay silent and just show nothing until permissions are checked
                return <></>;
            }
        };

        return <div ref={ref}>{determineContent()}</div>;
    },
);

export default CheckAccess;
export interface IAccessDeniedContentProps {
    msg: string;
}
