export type ClientOptionsType = {
    useFetchCache?: boolean;
    invalidateCache?: boolean; // Tech Debt- Support for this option is not developed yet.
};

export const DefaultMaxRetryCount = 2;

export default class ClientUtils {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static async withRetry<T>(
        httpRequest: () => Promise<T>,
        maxRetryCount: number = DefaultMaxRetryCount,
    ): Promise<T> {
        const count = maxRetryCount ?? DefaultMaxRetryCount;
        let i = 0;
        do {
            try {
                return await httpRequest();
            } catch (e) {
                console.error(e);
                if (i >= count) {
                    if (count > 0) {
                        console.error('Max retry count reached for HTTP request');
                    }
                    throw e;
                }
            }
            i++;
        } while (i <= count);
        return {} as T;
    }

    // Pass 0 for maxRetryCount to skip retry logic
    static async doHttpRequest<T>(
        url: string,
        httpOptions: RequestInit,
        maxRetryCount = 0,
    ): Promise<T> {
        return this.withRetry(() => this._doHttpInner(url, httpOptions), maxRetryCount);
    }

    private static async _doHttpInner<T>(url: string, httpOptions: RequestInit): Promise<T> {
        try {
            const res = await fetch(url, httpOptions);

            if (res.status === 204) {
                return {} as T;
            } else if (res.status >= 200 && res.status < 300) {
                return await res.json();
            }
            throw res;
        } catch (e) {
            console.error(e);
            return {} as T;
        }
    }

    // Pass 0 for maxRetryCount to skip retry logic
    // this is if i want the response back
    static async doHttpRequestResponse(
        url: string,
        httpOptions: RequestInit,
        maxRetryCount = 0,
    ): Promise<Response> {
        return this.withRetry(() => fetch(url, httpOptions), maxRetryCount);
    }

    static constructURL(baseUrl: string, urlParamsStr?: string): URL {
        try {
            if (urlParamsStr) {
                const urlObj = new URL('?' + urlParamsStr.replace(/^\?/, ''), baseUrl);
                return urlObj;
            }
            return new URL(baseUrl);
        } catch (e) {
            console.error(
                'Error in url string: baseUrl:' + baseUrl,
                'params string:' + urlParamsStr,
                e,
            );
            throw 'Error in url search string';
        }
    }
}
