import { Label } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import MultiTextFieldItem from 'components/core/common/textfield/multi-text-field-item';

export interface IMultiTextFieldProps {
    label: string;
    type?: string;
    selectedValue?: string[];
    onValuesChanged?: (values: string[]) => void;
    isRequired?: boolean;
}

export default function MultiTextField(props: IMultiTextFieldProps): JSX.Element {
    const [values, setValues] = useState<string[]>(props.selectedValue ?? ['']);

    useEffect(() => {
        if (props.selectedValue) {
            setValues(props.selectedValue);
        }
    }, [props.selectedValue]);

    useEffect(() => {
        if (props.onValuesChanged) {
            const nonEmptyValues = values.filter((x) => {
                if (x !== undefined && x !== '') {
                    return x;
                }
            });
            props.onValuesChanged(nonEmptyValues);
        }
    }, [values]);

    const onAddClicked = (index: number): void => {
        setValues((prev) => {
            const curr = [...prev];
            curr.splice(index + 1, 0, '');
            return curr;
        });
    };

    const onDeleteClicked = (index: number): void => {
        setValues((prev) => {
            const curr = [...prev];

            if (prev.length === 1) {
                curr[0] = '';
            } else {
                curr.splice(index, 1);
            }

            return curr;
        });
    };

    const onChange = (index: number, val?: string): void => {
        setValues((prev) => {
            const curr = [...prev];
            curr[index] = val ?? '';
            return curr;
        });
    };

    const renderItems = (): JSX.Element => {
        // Disabling adding new items if there are any empty values
        const isAddDisabled = values.some((val) => val === '');

        return <>{values.map((val, idx) => renderItem(val, idx, isAddDisabled))}</>;
    };

    const renderItem = (val: string, index: number, addDisabled = false): JSX.Element => {
        return (
            <>
                <MultiTextFieldItem
                    key={index}
                    value={val}
                    type={props.type}
                    onChange={(val) => onChange(index, val)}
                    addDisabled={addDisabled}
                    onAddClicked={() => onAddClicked(index)}
                    onDeleteClicked={() => onDeleteClicked(index)}
                    isRequired={props.isRequired}
                />
            </>
        );
    };

    return (
        <>
            <Label>{props.label}</Label>
            {renderItems()}
        </>
    );
}
