import React from 'react';
import { IColumn } from '@fluentui/react';
import { lastColumnStylesButton } from 'assets/styles/list-styles';
import { maxWidthCoeff, noDataText } from 'assets/constants/global-constants';
import { TableCell } from 'components/common/table';
import { ISortableColumns } from 'utils/sort-utils';
import {
    IFacilityRecord,
    IReservationRecord,
    ISeatRecord,
    ReservationState,
} from 'clients/facilities-client';
import CancelReservationModalActionButton from 'components/facilities/facilities-reservations/modals/facilities-reservation-cancel-modal-action-button';
import FacilitiesReservationModal from 'components/facilities/facilities-reservations/modals/facilities-reservation-modal';
import { TimeFormats, timeZoneAbbr } from 'utils/time-utils';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { separateWordsByCapitalLetter } from 'utils/string-utils';
import {
    DefaultTimeZone,
    handleUTCToFacilityTimeZone,
} from 'components/facilities/common/facility-time-utils';

interface FacilityReservationTableColumnsProps extends ISortableColumns {
    reservations: IFacilityReservationTableColumn[];
    onModalConcluded: (modalConclusion: ModalConclusion) => void;
}

export interface IFacilityReservationTableColumn {
    facilityRecord: IFacilityRecord | undefined;
    reservationRecord: IReservationRecord;
    seatRecord: ISeatRecord | undefined;
    checkInTime?: number | undefined;
    checkOutTime?: number | undefined;
}

export enum ReservationColumnNames {
    FACILITYNAME = 'Facility',
    SEATNAME = 'Seat',
    STATUS = 'Status',
    DATE = 'Date',
    TIMESLOT = 'Time Slot',
    CHECKINTIME = 'Check-In Time',
    CHECKOUTIME = 'Check-Out Time',
    ACTIONS = 'Actions',
}

export function FacilityReservationTableColumns(
    props: FacilityReservationTableColumnsProps,
): IColumn[] {
    const columnWidths = {
        facilityName: 80,
        seatName: 60,
        reservationStatus: 60,
        date: 120,
        timeslot: 160,
        checkin: 100,
        checkout: 110,
        actions: 100,
    };

    const isReservationInPast = (reservation: IReservationRecord): boolean => {
        const now = new Date().getTime();
        const reservationTime = reservation.reservationTimeSlot.startDateTimeUTCMilliseconds;
        return reservationTime < now;
    };

    const columns: IColumn[] = [
        {
            key: ReservationColumnNames.FACILITYNAME,
            name: ReservationColumnNames.FACILITYNAME,
            ariaLabel: ReservationColumnNames.FACILITYNAME,
            minWidth: columnWidths.facilityName,
            maxWidth: columnWidths.facilityName * maxWidthCoeff,
            isSorted: props.sortColumn === ReservationColumnNames.FACILITYNAME,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(ReservationColumnNames.FACILITYNAME);
            },
            onRender: (row: IFacilityReservationTableColumn): JSX.Element => {
                return <TableCell>{row.facilityRecord?.facilityName ?? noDataText}</TableCell>;
            },
        },
        {
            key: ReservationColumnNames.SEATNAME,
            name: ReservationColumnNames.SEATNAME,
            ariaLabel: ReservationColumnNames.SEATNAME,
            minWidth: columnWidths.seatName,
            maxWidth: columnWidths.seatName * maxWidthCoeff,
            isSorted: props.sortColumn === ReservationColumnNames.SEATNAME,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(ReservationColumnNames.SEATNAME);
            },
            onRender: (row: IFacilityReservationTableColumn): JSX.Element => {
                return <TableCell>{row.seatRecord?.seatName ?? noDataText}</TableCell>;
            },
        },
        {
            key: ReservationColumnNames.DATE,
            name: ReservationColumnNames.DATE,
            ariaLabel: ReservationColumnNames.DATE,
            minWidth: columnWidths.date,
            maxWidth: columnWidths.date * maxWidthCoeff,
            isSorted: props.sortColumn === ReservationColumnNames.DATE,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(ReservationColumnNames.DATE);
            },
            onRender: (row: IFacilityReservationTableColumn): JSX.Element => {
                return (
                    <TableCell>
                        {handleUTCToFacilityTimeZone(
                            row.reservationRecord.reservationTimeSlot.startDateTimeUTCMilliseconds,
                            row.facilityRecord,
                            TimeFormats.ddd_DD_MMM_YYYY,
                            noDataText,
                        )}
                    </TableCell>
                );
            },
        },
        {
            key: ReservationColumnNames.TIMESLOT,
            name: ReservationColumnNames.TIMESLOT,
            ariaLabel: ReservationColumnNames.TIMESLOT,
            minWidth: columnWidths.timeslot,
            maxWidth: columnWidths.timeslot * maxWidthCoeff,
            isSorted: props.sortColumn === ReservationColumnNames.TIMESLOT,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(ReservationColumnNames.TIMESLOT);
            },
            onRender: (row: IFacilityReservationTableColumn): JSX.Element => {
                return (
                    <TableCell>
                        {`${handleUTCToFacilityTimeZone(
                            row.reservationRecord.reservationTimeSlot.startDateTimeUTCMilliseconds,
                            row.facilityRecord,
                            TimeFormats.H_mmA,
                            noDataText,
                        )} - ${handleUTCToFacilityTimeZone(
                            row.reservationRecord.reservationTimeSlot.endDateTimeUTCMilliseconds,
                            row.facilityRecord,
                            TimeFormats.H_mmA,
                            noDataText,
                        )} ${timeZoneAbbr(row.facilityRecord?.timeZone ?? DefaultTimeZone)}`}
                    </TableCell>
                );
            },
        },
        {
            key: ReservationColumnNames.CHECKINTIME,
            name: ReservationColumnNames.CHECKINTIME,
            ariaLabel: ReservationColumnNames.CHECKINTIME,
            minWidth: columnWidths.checkin,
            maxWidth: columnWidths.checkin * maxWidthCoeff,
            isSorted: props.sortColumn === ReservationColumnNames.CHECKINTIME,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(ReservationColumnNames.CHECKINTIME);
            },
            onRender: (row: IFacilityReservationTableColumn): JSX.Element => {
                return (
                    <>
                        {row.checkInTime && (
                            <TableCell>
                                {`${handleUTCToFacilityTimeZone(
                                    row.checkInTime,
                                    row.facilityRecord,
                                    TimeFormats.H_mmA,
                                    noDataText,
                                )} ${timeZoneAbbr(
                                    row.facilityRecord?.timeZone ?? DefaultTimeZone,
                                )}`}
                            </TableCell>
                        )}
                    </>
                );
            },
        },
        {
            key: ReservationColumnNames.CHECKOUTIME,
            name: ReservationColumnNames.CHECKOUTIME,
            ariaLabel: ReservationColumnNames.CHECKOUTIME,
            minWidth: columnWidths.checkout,
            maxWidth: columnWidths.checkout * maxWidthCoeff,
            isSorted: props.sortColumn === ReservationColumnNames.CHECKOUTIME,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(ReservationColumnNames.CHECKOUTIME);
            },
            onRender: (row: IFacilityReservationTableColumn): JSX.Element => {
                return (
                    <>
                        {row.checkOutTime && (
                            <TableCell>
                                {`${handleUTCToFacilityTimeZone(
                                    row.checkOutTime,
                                    row.facilityRecord,
                                    TimeFormats.H_mmA,
                                    noDataText,
                                )} ${timeZoneAbbr(
                                    row.facilityRecord?.timeZone ?? DefaultTimeZone,
                                )}`}
                            </TableCell>
                        )}
                    </>
                );
            },
        },
        {
            key: ReservationColumnNames.STATUS,
            name: ReservationColumnNames.STATUS,
            ariaLabel: ReservationColumnNames.STATUS,
            minWidth: columnWidths.reservationStatus,
            maxWidth: columnWidths.reservationStatus * maxWidthCoeff,
            isSorted: props.sortColumn === ReservationColumnNames.STATUS,
            isSortedDescending: !props.sortAscending,
            onColumnClick: (): void => {
                props.sortColumnHandler(ReservationColumnNames.STATUS);
            },
            onRender: (row: IFacilityReservationTableColumn): JSX.Element => {
                return (
                    <TableCell>
                        {separateWordsByCapitalLetter(row.reservationRecord.state)}
                    </TableCell>
                );
            },
        },
        {
            key: ReservationColumnNames.ACTIONS,
            name: ReservationColumnNames.ACTIONS,
            ariaLabel: ReservationColumnNames.ACTIONS,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions * maxWidthCoeff,
            ...lastColumnStylesButton,
            onRender: (row: IFacilityReservationTableColumn): JSX.Element => {
                return (
                    <>
                        <TableCell>
                            {row.reservationRecord.state !== ReservationState.CheckedIn && (
                                <CancelReservationModalActionButton
                                    reservationInstructions={{
                                        facility: row.facilityRecord!,
                                        cancel: [
                                            {
                                                facility: row.facilityRecord!,
                                                timeslot: {
                                                    seatStatus: {
                                                        seatId: row.seatRecord?.id ?? '',
                                                    },
                                                    timeslotItem:
                                                        row.reservationRecord.reservationTimeSlot,
                                                },
                                            },
                                        ],
                                    }}
                                    iseatRecords={[]}
                                    onModalConcluded={props.onModalConcluded}
                                    buttonDisabled={isReservationInPast(row.reservationRecord)}
                                />
                            )}
                        </TableCell>
                        <TableCell>
                            {row.reservationRecord.state === ReservationState.Preclaimed &&
                                row.facilityRecord !== undefined &&
                                row.seatRecord !== undefined && (
                                    <FacilitiesReservationModal
                                        facilityRecord={row.facilityRecord}
                                        timeslotItem={row.reservationRecord.reservationTimeSlot}
                                        seatRecord={row.seatRecord}
                                        onModalConcluded={props.onModalConcluded}
                                        text={'Confirm'}
                                        preclaimedReservation={row.reservationRecord}
                                    />
                                )}
                        </TableCell>
                    </>
                );
            },
        },
    ];

    return columns;
}
