import React, { useState, useEffect, useContext } from 'react';
import EmployeeCard from 'components/common/employee/employee-card';
import { HoverCard, HoverCardType, mergeStyleSets } from '@fluentui/react';
import { AuthContext } from 'contexts/auth-context';
import EmployeeClient, {
    IEmployeeEditableResponse,
    PublicEmployeeEditableFields,
} from 'clients/employee-client';
import { isGUID } from 'utils/string-utils';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';

export interface EmployeeHoverCardProps {
    children: React.ReactNode;
    personnelId?: string | null; // null can happen when reportsToEmailName is null. That, in turn, happens for CEO.
    personnelAlias?: string;
    displayActions?: boolean;
    isInitialEmployeeBatchLoaded?: boolean;
}

export function EmployeeHoverCard(props: EmployeeHoverCardProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const [isInitialEmployeeBatchLoaded] = useState<boolean>(
        props.isInitialEmployeeBatchLoaded === undefined
            ? true
            : props.isInitialEmployeeBatchLoaded,
    );
    const [editableEmployeeData, setEditableEmployeeData] = useState<IEmployeeEditableResponse>();
    const [alias, setAlias] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [manager, setManager] = useState('');
    const [managerAlias, setManagerAlias] = useState('');
    const [department, setDepartment] = useState('');
    const [oid, setOid] = useState('');

    useEffect(() => {
        let isMounted = true;

        async function populateFteData(): Promise<void> {
            let employee;

            if (props.personnelId) {
                try {
                    const basicResult = await EmployeeClient.getBasicEmployeesById(authContext, [
                        props.personnelId,
                    ]);
                    employee = basicResult[0];
                } catch {
                    console.error('Error fetching employee by employee personnel Id');
                }
            }

            if (!props.personnelId && props.personnelAlias) {
                try {
                    const basicResult = await EmployeeClient.getBasicEmployeesByAlias(authContext, [
                        props.personnelAlias,
                    ]);
                    employee = basicResult[0];
                } catch {
                    console.error('Error fetching employee by employee alias');
                }
            }

            if (employee && isMounted) {
                setAlias(employee.onPremisesSamAccountName);
                setDisplayName(employee.displayName);
                setEmail(employee.mail);
                setJobTitle(employee.jobTitle ? employee.jobTitle : '');
                setDepartment(employee.department);
                setOid(employee.oid);

                const basicResult = await EmployeeClient.getBasicEmployeesByAlias(authContext, [
                    employee.reportsToEmailName,
                ]);
                const basicManager = basicResult[0];
                if (basicManager && isMounted) {
                    setManagerAlias(basicManager.onPremisesSamAccountName);
                    setManager(basicManager.displayName);
                }
            }
        }

        async function populateNonFteData(): Promise<void> {
            if (props.personnelId) {
                try {
                    const editableEmployeeResponse = await EmployeeClient.getEditableEmployeeDataByIdOrAliasOrGUID(
                        authContext,
                        props.personnelId!,
                        Object.values(PublicEmployeeEditableFields),
                    );

                    if (editableEmployeeResponse && isMounted) {
                        setEditableEmployeeData(editableEmployeeResponse);
                        const basicResult = await EmployeeClient.getBasicEmployeesByAlias(
                            authContext,
                            [editableEmployeeResponse.employeeEditableInfo.reportsToEmailName!],
                        );
                        const basicManager = basicResult[0];
                        if (basicManager && isMounted) {
                            setManagerAlias(basicManager.onPremisesSamAccountName);
                            setManager(basicManager.displayName);
                        }
                    }
                } catch (e) {
                    console.error('GetEditableEmployeeDataByIdOrAliasOrGUID error: ', e);
                }
            }
        }

        if (isInitialEmployeeBatchLoaded && props.personnelId && isGUID(props.personnelId)) {
            populateNonFteData();
        } else if (isInitialEmployeeBatchLoaded && (props.personnelId || props.personnelAlias)) {
            populateFteData();
        }
        return (): void => {
            isMounted = false;
        };
    }, [props.personnelAlias, props.personnelId, isInitialEmployeeBatchLoaded, authContext]);

    function onRenderPlainCard(): JSX.Element {
        return props.personnelId && !isGUID(props.personnelId) ? (
            <EmployeeCard
                employee={{
                    alias: alias,
                    displayName: displayName,
                    email: email,
                    jobTitle: jobTitle,
                    department: department,
                    oid: oid,
                }}
                manager={manager}
                managerAlias={managerAlias}
                displayActions={props.displayActions}
            />
        ) : (
            <EmployeeCard
                employee={{
                    id: editableEmployeeData?.id,
                    alias: editableEmployeeData?.alias,
                    displayName: getDisplayNameOrDefault(
                        {
                            firstName: editableEmployeeData?.employeeEditableInfo.firstName,
                            lastName: editableEmployeeData?.employeeEditableInfo.lastName,
                        },
                        editableEmployeeData?.id,
                    ),
                    oid: oid,
                }}
                manager={manager}
                managerAlias={managerAlias}
                displayActions={props.displayActions}
                pcn={
                    editableEmployeeData?.employeeEditableInfo.positionNumber &&
                    editableEmployeeData?.employeeEditableInfo.positionNumber > 0
                        ? `${editableEmployeeData?.employeeEditableInfo.positionNumber}`
                        : ''
                }
            />
        );
    }

    const plainCardProps = {
        onRenderPlainCard: onRenderPlainCard,
        renderData: props,
    };

    return (
        <HoverCard
            styles={inlineStyle}
            plainCardProps={plainCardProps}
            instantOpenOnClick={true}
            cardOpenDelay={300}
            expandedCardOpenDelay={3000}
            type={HoverCardType.plain}>
            {props.children}
        </HoverCard>
    );
}

const inlineStyle = mergeStyleSets({
    host: {
        display: 'inline-block',
    },
});
