import React, { useEffect, useState, useContext } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import EligibilityClient, { IAttribute } from 'clients/eligibility-client';
import { TextField } from '@fluentui/react';
import { ModalMode } from 'components/eligibilities/eligibilities-constants';
import { readErrorMessageBody } from 'utils/misc-utils';

interface IAddAttributeModalProps {
    mode: ModalMode;
    attribute?: IAttribute;
    buttonText?: string;
    onAddEditAttributeConcluded: (
        mode: ModalMode,
        modalConclusion: ModalConclusion,
        result?: IAttribute,
    ) => void;
}

export default function AddAttributeModalButton(props: IAddAttributeModalProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const [attributeCode, setAttributeCode] = useState<string | undefined>('');
    const [attributeDesc, setAttributeDesc] = useState<string | undefined>('');
    const [errorMsg, setErrorMsg] = useState<string>('');

    const addMode = (): boolean => props.mode === ModalMode.Add;
    const updateMode = (): boolean => props.mode === ModalMode.Update;

    useEffect(() => {
        if (updateMode() && !props.attribute) {
            setErrorMsg('Attribute not provided');
        }
    }, []);

    const initInputs = (): void => {
        if (addMode()) {
            clearInputs();
        } else {
            setAttributeCode(props?.attribute?.attributeCode);
            setAttributeDesc(props?.attribute?.attributeDesc);
        }
    };

    const isAttrValidNonBlank = (str: string | undefined): boolean => {
        // True if:
        // It doesn't start with underscore "_".
        // It only contains letters and digits including underscore "_".
        return /^[0-9a-z]+([_0-9a-z]+)*$/i.test(str ?? '');
    };

    const isAttrValidForEntry = (str: string | undefined): boolean => {
        // True if:
        // It doesn't start or end with underscore "_".
        // It only contains letters and digits including underscore "_".
        // Or if it's empty.
        return /^$/.test(str ?? '') || isAttrValidNonBlank(str);
    };

    const isAttrValidForSubmit = (str: string | undefined): boolean => {
        // True if:
        // It doesn't start or end with underscore "_".
        // It only contains letters and digits including underscore "_".
        return /^[0-9a-z]+(_[0-9a-z]+)*$/i.test(str ?? '');
    };

    const isDescriptionValidNonBlank = (str: string | undefined): boolean => {
        return /[a-z]/i.test(str ?? '');
    };

    const isDescriptionValidForEntry = (str: string | undefined): boolean => {
        return /^$/.test(str ?? '') || isDescriptionValidNonBlank(str);
    };

    const isDescriptionValidForSubmit = (str: string | undefined): boolean => {
        return isDescriptionValidNonBlank(str);
    };

    const clearInputs = (): void => {
        setAttributeCode('');
        setAttributeDesc('');
    };

    const disableSubmit = (): boolean => {
        const isDisable1 = !isAttrValidForSubmit(attributeCode);
        const isDisable2 = !isDescriptionValidForSubmit(attributeDesc);
        return isDisable1 || isDisable2;
    };

    const onAddAttributeSubmit = async (): Promise<IAttribute> => {
        try {
            // The following type casts are safe because checking of disableSubmit()
            // ensures that attributeCode and attributeDesc are not undefined.
            if (addMode()) {
                return await EligibilityClient.createAttribute(authContext, {
                    attributeCode: attributeCode as string,
                    attributeDesc: attributeDesc as string,
                });
            } else {
                return await EligibilityClient.updateAttribute(authContext, {
                    id: props.attribute?.id as string,
                    attributeCode: attributeCode as string,
                    attributeDesc: attributeDesc as string,
                });
            }
        } catch (e) {
            const submitErrorEventText = await readErrorMessageBody(e);
            if (submitErrorEventText) {
                // Throw the error message text. The modal will catch and display it.
                throw submitErrorEventText;
            } else {
                console.error('Error processing - AddAttribute');
                console.error(e);
                // I don't know what the error is.
                // Throw it and let the modal catch it.
                throw e;
            }
        }
    };

    const onAddEditAttributeConcluded = (
        conclusion: ModalConclusion,
        result?: IAttribute,
    ): void => {
        if (props.onAddEditAttributeConcluded) {
            props.onAddEditAttributeConcluded(props.mode, conclusion, result);
        }
    };

    const onAttributeChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        str?: string,
    ): void => {
        if (isAttrValidForEntry(str ?? '')) {
            setAttributeCode(str?.toUpperCase());
        }
    };

    const onDescriptionChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        str?: string,
    ): void => {
        if (isDescriptionValidForEntry(str ?? '')) {
            setAttributeDesc(str);
        }
    };

    const onModalOpen = (): void => {
        initInputs();
    };

    const iconName = addMode() ? IconNames.Add : IconNames.Edit;
    const buttonText = props.buttonText ?? (addMode() ? 'Add New Attribute' : 'Edit Attribute');
    const submitButtonText = addMode() ? 'Add' : 'Save';

    return (
        <ModalActionButton<IAttribute>
            text={buttonText}
            iconName={iconName}
            errorMsg={errorMsg}
            modalTitle={buttonText}
            modalTitleIcon={iconName}
            enableSubmit={!disableSubmit()}
            submitButtonText={submitButtonText}
            submitButtonIcon={iconName}
            onSubmit={onAddAttributeSubmit}
            onButtonClick={onModalOpen}
            onModalConcluded={onAddEditAttributeConcluded}>
            <TextField
                label='Attribute Code'
                value={attributeCode || ''}
                ariaLabel='Attribute Code'
                placeholder='Enter a code'
                onChange={onAttributeChange}
            />
            <TextField
                label='Attribute Description'
                value={attributeDesc || ''}
                ariaLabel='Attribute Description'
                placeholder='Enter a description'
                onChange={onDescriptionChange}
            />
        </ModalActionButton>
    );
}
