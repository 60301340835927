import { mergeStyles } from '@fluentui/react';
import React, { useCallback, useContext, useState } from 'react';
import {
    choiceTypes,
    DateRangeFormElement,
    FormElement,
    TableFormElement,
    FormValueTypes,
    getDateValidatingSituation,
    getDateRangeValidatingSituation,
} from 'components/forms/forms-common';
import { AuthContext } from 'contexts/auth-context';
import TableViewer from 'components/forms/element-viewer/table-viewer';
import ChoiceViewer from 'components/forms/element-viewer/choice-viewer';
import DateViewer from 'components/forms/element-viewer/date-viewer/date-viewer';
import {
    emailRegexPattern,
    phoneRegexPattern,
    zipcodeRegexPattern,
} from 'components/forms/forms-viewer';
import CountryPickerViewer from 'components/forms/element-viewer/country-picker-viewer';
import InfoViewer from 'components/forms/element-viewer/info-viewer';
import TextFieldViewer from 'components/forms/element-viewer/text-field-viewer';
import SignatureViewer from 'components/forms/element-viewer/signature-viewer';
import { FocusContainer } from 'components/common/misc/focus-container';
import PeoplePickerViewer from 'components/forms/element-viewer/people-picker-viewer';

// takes the same css as h3 element tag outside of font size and weight
const questionLabelStyles = mergeStyles({
    fontSize: '1rem',
    fontWeight: '400',
    display: 'block',
    margin: '1em 0 1em 0',
});

export type ElementProps = {
    element: FormElement;
    updateFormResponse: (element: FormElement, answer: FormValueTypes) => void;
};

type ElementViewerProps = {
    element: FormElement;
    displayIndex: number;
    updateFormResponse: (element: FormElement, answer: FormValueTypes) => void;
    isSectionVisited: boolean;
};

export default function ElementViewer(props: ElementViewerProps): JSX.Element {
    const { element, displayIndex, updateFormResponse, isSectionVisited } = props;
    const [isVisited, setIsVisited] = useState<boolean>(isSectionVisited);
    const authContext = useContext(AuthContext);
    const userName = authContext.getUserProfile()?.name ?? '';

    const showRequiredIndicator = (): JSX.Element => {
        return <div style={{ color: '#e50000' }}>(required)</div>;
    };

    const errorElement = (errorMessage: string): JSX.Element => {
        return <span style={{ color: '#e50000', position: 'absolute' }}>{errorMessage}</span>;
    };

    const createValidationMessage = (): JSX.Element => {
        if (element.required && (!element.value || element.value.length === 0)) {
            return errorElement('Required field');
        } else if (element.validatorOptions?.type === 'regex') {
            const valueString = element.value as string;
            if (element.validatorOptions?.value === 'email') {
                return emailRegexPattern.test(valueString) ? (
                    <></>
                ) : (
                    errorElement('Invalid email format')
                );
            } else if (element.validatorOptions?.value === 'phone') {
                if (valueString) {
                    const phoneNumber = valueString.split('.');
                    if (phoneNumber.length === 2) {
                        // only country code
                        if (phoneNumber[1].length === 0 && element.required) {
                            return errorElement('Phone number required');
                        } else if (
                            phoneNumber[1].length > 0 &&
                            !phoneRegexPattern.test(phoneNumber[1])
                        ) {
                            // phone number is invalid format
                            return errorElement('Invalid phone format');
                        } else if (phoneNumber[0] === '') {
                            // somehow there is a country code but blank.
                            return errorElement('Country Code required');
                        }
                    } else if (phoneNumber.length === 1 && element.required) {
                        return errorElement('Country Code required ');
                    } else {
                        return errorElement('Invalid phone format');
                    }
                }
            } else if (element.validatorOptions?.value === 'zipcode') {
                return zipcodeRegexPattern.test(valueString) ? (
                    <></>
                ) : (
                    errorElement('Invalid zipcode format')
                );
            }
        } else if (
            element.type !== 'daterange' &&
            element.validatorOptions?.type === 'limit' &&
            element.value &&
            element.value.length > element.validatorOptions?.value
        ) {
            return errorElement('Invalid value length');
        } else if (element.type === 'daterange') {
            const situation = getDateRangeValidatingSituation(element);
            if (situation !== '') {
                return errorElement(situation);
            }
        } else if (element.type === 'date') {
            const situation = getDateValidatingSituation(element);
            if (situation !== '') {
                return errorElement(situation);
            }
        } else if (element.type === 'signature' && element.value !== userName) {
            return errorElement('Invalid signature');
        }
        return <></>;
    };

    const CreateFormElement = useCallback(
        (element: FormElement): JSX.Element => {
            if (element.type === 'textfield') {
                return (
                    <TextFieldViewer element={element} updateFormResponse={updateFormResponse} />
                );
            }
            if (element.type === 'signature') {
                return (
                    <SignatureViewer
                        element={element}
                        updateFormResponse={updateFormResponse}
                        userName={userName}
                    />
                );
            }
            if (element.type === 'table') {
                return (
                    <TableViewer
                        element={element as TableFormElement}
                        updateFormResponse={updateFormResponse}
                    />
                );
            }
            if (element.type === 'country') {
                return (
                    <CountryPickerViewer
                        element={element as TableFormElement}
                        updateFormResponse={updateFormResponse}
                    />
                );
            }
            if (element.type === 'people' || element.type === 'person') {
                return (
                    <PeoplePickerViewer element={element} updateFormResponse={updateFormResponse} />
                );
            }
            if (element.type === 'date') {
                return (
                    <DateViewer
                        element={element as FormElement}
                        updateFormResponse={updateFormResponse}
                    />
                );
            }
            if (element.type === 'daterange') {
                return (
                    <DateViewer
                        element={element as DateRangeFormElement}
                        updateFormResponse={updateFormResponse}
                    />
                );
            }
            if (choiceTypes.some((choiceType) => choiceType === element.type)) {
                return <ChoiceViewer element={element} updateFormResponse={updateFormResponse} />;
            }
            return <></>;
        },
        [element.value],
    );
    if (element.type === 'info') {
        return (
            <div key={element.id}>
                <InfoViewer description={element.description} />
            </div>
        );
    }

    return (
        <div key={element.id}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '.3rem' }}>
                <label className={questionLabelStyles}>{displayIndex + '. ' + element.label}</label>
                {element.required && showRequiredIndicator()}
            </div>
            <span>{element.description}</span>
            <FocusContainer
                onLoseFocus={() => {
                    setIsVisited(true);
                }}>
                {CreateFormElement(element)}
            </FocusContainer>
            {isVisited && createValidationMessage()}
        </div>
    );
}
