import { FontIcon, mergeStyles, TextField } from '@fluentui/react';
import { faintBlue } from 'assets/constants/global-colors';
import { FocusContainer } from 'components/common/misc/focus-container';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormResponse } from 'components/forms/forms-common';

const iconClass = mergeStyles({
    color: faintBlue,
});

const questionLabelStyles = mergeStyles({
    fontSize: '1rem',
    fontWeight: '400',
});

type LabelViewerProps = {
    label?: string;
    setFormResponse: Dispatch<SetStateAction<FormResponse>>;
};
const LabelViewer = (props: LabelViewerProps) => {
    const { label, setFormResponse } = props;
    const [hasFocus, setFocus] = useState(false);
    const onLabelChange = (newLabel?: string) => {
        setFormResponse((prev) => {
            const newResponse = { ...prev };
            newResponse.label = newLabel ?? '';
            return newResponse;
        });
    };

    const customLabel = (labelValue: string): JSX.Element => {
        return <h3 className={questionLabelStyles}>{labelValue}</h3>;
    };

    return (
        <FocusContainer onLoseFocus={() => setFocus(false)} onGainFocus={() => setFocus(true)}>
            {hasFocus || !label ? (
                <div style={{ maxWidth: '865px' }}>
                    <TextField
                        onRenderLabel={() => customLabel('Create a unique name for this form')}
                        value={label ?? ''}
                        placeholder='Enter a description to identify this form, like “My trip to Mexico”'
                        onChange={(ev, newValue) => onLabelChange(newValue)}
                    />
                </div>
            ) : (
                <div style={{ display: 'flex', gap: '.5rem' }}>
                    <h3 style={{ marginTop: 0 }}>{label}</h3>
                    <FontIcon
                        aria-label='edit'
                        iconName='edit'
                        style={{ paddingBottom: '.8rem' }}
                        className={iconClass}
                    />
                </div>
            )}
        </FocusContainer>
    );
};

export default React.memo(LabelViewer);
