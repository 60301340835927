import React from 'react';
import { mergeStyleSets, DefaultButton, Stack, PrimaryButton } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';

export interface IActionAndCancelButtonsProps {
    buttonText: string;
    disabled: boolean;
    isVisible: boolean;
    onClickAction: () => void;
    onClickCancel: () => void;
}

export default function ActionAndCancelButtons(props: IActionAndCancelButtonsProps): JSX.Element {
    if (!props.isVisible) {
        return <></>;
    }
    return (
        <Stack horizontal horizontalAlign='center'>
            <Stack.Item>
                {/* Without iconName for the cancel button, its vertical alignment goes haywire. */}
                <DefaultButton iconProps={{ iconName: '' }} onClick={props.onClickCancel}>
                    Cancel
                </DefaultButton>
                <div className={styles.buttonsGap}></div>
                {/* Tops of DefaultButton and PrimaryButton
                    are not aligned, hence the following styling.  */}
                <PrimaryButton
                    styles={{ root: { top: 2 } }}
                    iconProps={{ iconName: IconNames.Edit }}
                    disabled={props.disabled}
                    onClick={props.onClickAction}>
                    {props.buttonText}
                </PrimaryButton>
            </Stack.Item>
        </Stack>
    );
}

const styles = mergeStyleSets({
    buttonsGap: {
        width: 50,
        display: 'inline-block',
    },
});
