import React, { useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { ModalSizeType } from 'components/common/modal';
import EmployeeHistoryTable from 'components/sca/history/employee-history-table';
import { useEmployeeBasicInfo } from 'components/common/employee/internal-employee-utils';
import { IconNames } from 'assets/constants/global-constants';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { Redirect } from 'react-router-dom';
import { Stack } from '@fluentui/react';

interface IScaMyTeamViewDetailsProps {
    personnelId: string;
    buttonText?: string; // Default: "History"
    // noHoverCard Default is false so that by default, it shows the hoverable mini card.
    // Set it to true so that instead of showing the mini card, it shows the whole card.
    noHoverCard?: boolean;
}

export default function ScaEmployeeHistoryDialogButton(
    props: IScaMyTeamViewDetailsProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [redirectTo, setRedirecTo] = useState<string>();

    const buttonText = props.buttonText ?? 'History';

    const employee = useEmployeeBasicInfo({ authContext, id: props.personnelId });

    const onSubmit = async (): Promise<void> => {
        if (employee?.id) {
            setRedirecTo(`/sca/employee-history/${employee.id}`);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const nop = (): void => {};

    if (redirectTo) {
        return <Redirect to={redirectTo} push={true} />;
    } else {
        return (
            <ModalActionButton
                text={buttonText}
                size={ModalSizeType.large}
                iconName={IconNames.History}
                onSubmit={onSubmit}
                enableSubmit={true}
                submitButtonText={'View Complete History'}
                modalTitle={
                    <Stack horizontal>
                        <EmployeeBasicHoverCard
                            key={employee?.id}
                            noHoverCard={props.noHoverCard ?? false}
                            displayActions={false}
                            showMiniCardAlias={false}
                            employeeBasicData={employee}
                        />
                        <span>&nbsp;Review History</span>
                    </Stack>
                }
                modalTitleIcon={IconNames.QuickNote}
                cancelButtonText='Close'
                onModalConcluded={nop}>
                <EmployeeHistoryTable
                    employee={employee}
                    displayThisMany={4}
                    shouldFetchAllHistory={false}
                    shouldHideReviewComments={true}
                />
            </ModalActionButton>
        );
    }
}
