import { CloudStatus, ClearanceStatus } from 'components/staffing/staffing-constants';
import { AllocationFilter } from 'components/staffing/status-filters/allocation-filter-class';
import { getAllocationKey } from 'components/staffing/status-filters/filter-utility';
import { IStaffingClearanceRecordResponse } from 'clients/staffing-client';

// Filter out allocations that belong to teams with > MIN_READY_COUNT allocations with 'Ready' status
const MIN_READY_COUNT = 6;

export class RXM4BurndownAllocationFilter extends AllocationFilter {
    filter(allocations: IStaffingClearanceRecordResponse[]): IStaffingClearanceRecordResponse[] {
        // Group allocations by org-team name and track 'Ready' status
        const map: { [key: string]: number } = {};

        const filteredStatuses = this.filterStatuses(allocations);
        filteredStatuses.forEach((allocation) => {
            const orgTeam: string = getAllocationKey(allocation);

            map[orgTeam] = map[orgTeam] || 0;

            const foundCloud = allocation.cloudStatuses.find((x) => x.name === CloudStatus.RX);
            if (foundCloud && foundCloud.status === ClearanceStatus.Ready) {
                map[orgTeam]++;
            }
        });

        // Filter all allocations belonging to those teams.
        return filteredStatuses.filter((value) => {
            const orgTeam: string = getAllocationKey(value);
            return map[orgTeam] < MIN_READY_COUNT;
        });
    }
}
