import { ICustomBreadcrumbItem } from 'components/common/bread-crumb';

const requestToJoinBreadcrumbRoot: ICustomBreadcrumbItem[] = [
    {
        title: 'Request to Join',
        link: '',
    },
];

export function requestToJoinBreadcrumbs(groupName: string | undefined): ICustomBreadcrumbItem[] {
    if (!groupName) {
        return requestToJoinBreadcrumbRoot;
    }

    return [
        ...requestToJoinBreadcrumbRoot,
        {
            title: groupName,
            link: '',
        },
    ];
}
