import React, { useCallback, useContext, useEffect } from 'react';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { FontIcon, mergeStyleSets } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { CoreSinglePrincipalRecordPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import { UserAssignmentsList } from 'components/core/users/user-assignments-list';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';

export function UserSearchPage(): JSX.Element {
    const breadCrumbContext = useContext(BreadCrumbContext);
    const routerHistory = useHistory();

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([
            { title: 'Home', link: '/home' },
            { title: 'Attributes', link: '' },
            { title: 'User Assignments', link: '' },
        ]);
    }, []);

    const onPrincipalSelected = useCallback(
        (item?: IPrincipalRecord): void => {
            if (item) {
                routerHistory.push(`/profile/attributes/user/${item.id}`);
            }
        },
        [routerHistory],
    );

    return (
        <>
            <div className={styles.breadcrumb}>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            </div>
            <div className={styles.searchBoxWrapper}>
                <div className={styles.searchBox}>
                    <CoreSinglePrincipalRecordPickerTypeaheadSearch
                        ariaLabel='User search'
                        placeHolder='Find users'
                        onChange={onPrincipalSelected}
                        styles={{
                            textBoxStyle: {
                                borderRadius: '4px',
                                background: '#FFF',
                                boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.12)',
                                borderColor: '#D1D1D1',
                                height: '55px',
                            },
                            inputStyle: {
                                alignContent: 'center',
                                fontSize: '16px',
                                alignSelf: 'auto',
                                color: '#616161',
                                paddingLeft: '30px',
                            },
                        }}
                    />
                    <FontIcon aria-label='Search' iconName='Search' className={styles.searchIcon} />
                </div>
            </div>
            <div>
                <UserAssignmentsList />
            </div>
        </>
    );
}

const styles = mergeStyleSets({
    breadcrumb: {
        marginBottom: '43px',
    },
    searchBoxWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    searchBox: {
        position: 'relative',
        display: 'flex',
        width: '661px',
    },
    searchIcon: {
        position: 'absolute',
        right: '25px',
        top: '12px',
        fontSize: '20px',
        transform: 'scaleX(-1)',
        color: '#616161',
    },
});
