import React, { useRef, useState } from 'react';
import { mergeStyleSets } from '@fluentui/merge-styles';
import { DefaultButton } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import { IFileInfo } from 'components/common/buttons/upload-csv-button';
import * as PapaParse from 'papaparse';

interface IAddFileProps {
    acceptedFileTypes: string[];
    buttonText?: string;
    disabled?: boolean;
    onFileLoaded: (data: any[], fileInfo: IFileInfo) => any;
    onError: (error: Error) => void;
    onClick?: () => void; // Informing the parent that user clicked on the button.
}

export function ChooseFileButton(props: IAddFileProps) {
    const buttonText = props.buttonText || 'Choose File';
    const fileField = useRef<any>(null);

    const parserOptions = {} as PapaParse.ParseConfig;
    const fileEncoding = 'UTF-8';

    const [fileName, setFileName] = useState<string | undefined>(undefined);

    const onClick = (): void => {
        // Jun,9,2023
        // If onClick changed to an asynchronous function, disable the button first,
        // then call the function, and enable the button only when it returns.
        if (props.onClick) {
            props.onClick();
        }
    };

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const reader: FileReader = new FileReader();
        const files: FileList = e.target.files!;
        if (!files.length) {
            return;
        }
        const fileInfo: IFileInfo = {
            name: files[0].name,
            type: files[0].type,
        };

        setFileName(fileInfo.name);

        if (props.acceptedFileTypes.indexOf(fileInfo.type) < 0) {
            props.onError(
                new Error(
                    `File type, '${fileInfo.type}', is not in accepted filetypes '${props.acceptedFileTypes}'`,
                ),
            );
            return;
        }

        reader.onload = (): void => {
            const csvData = PapaParse.parse(
                reader.result as string,
                Object.assign(parserOptions, {
                    error: props.onError,
                    encoding: fileEncoding,
                }),
            );
            props.onFileLoaded(csvData?.data ?? [], fileInfo);
        };

        reader.readAsText(files[0], fileEncoding);
    };

    return (
        <div className={styles.uploadButton}>
            <DefaultButton
                disabled={props.disabled}
                iconProps={{ iconName: IconNames.OpenFile }}
                type='file'
                onClick={onClick}>
                {buttonText}
            </DefaultButton>
            <input
                className={styles.hiddenInput}
                title={buttonText}
                type='file'
                id='fileInput'
                ref={fileField}
                onChange={onFileChange}
                accept={props.acceptedFileTypes.join(',')}
            />
            <span className={styles.fileName}>{fileName || 'No file chosen'}</span>
        </div>
    );
}

const styles = mergeStyleSets({
    fileName: {
        marginLeft: '10px',
    },
    uploadButton: {
        position: 'relative',
        overflow: 'hidden',
        display: 'inline-block',
    },
    hiddenInput: {
        position: 'absolute',
        left: '0',
        top: '0',
        opacity: '0',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
    },
});
