import React, { ReactNode, useContext, useEffect, useState, FormEvent } from 'react';
import { AuthContext } from 'contexts/auth-context';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { IconNames, Dictionary } from 'assets/constants/global-constants';
import {
    Stack,
    TextField,
    Separator,
    Dropdown,
    IDropdownOption,
    DatePicker,
} from '@fluentui/react';
import Spacer from 'components/common/spacer';
import { globalSeparatorStyles, globalStyles } from 'assets/styles/global-styles';
import { Table } from 'components/common/table';
import { useSortColumnHandler } from 'utils/sort-utils';
import { getEmailNotificationColumns } from 'components/sca/manage/create-review/get-review-notification-emails-columns';
import deepcopy from 'deepcopy';
import { dateToLocalShortDateFormat, datePlusDays } from 'utils/time-utils';
import ScaClient, { IReviewPeriod, IScaCreateReviewRequestBody } from 'clients/sca-client';
import { managerialLevels, findManagerialLevel } from 'components/sca/sca-constants';

export type EmailNotificationType = {
    date: Date;
    level: number;
};

interface IModalActionButtonSubset {
    errorMsg?: string;
    enableNext?: boolean;
    enableSubmit: boolean;
    enableCancel?: boolean; // ModalActionButton implements a default of "true" for this prop.
    modalSubtitle?: string;
    miscButton1Text?: string;
    miscButton1Icon?: string;
    submitButtonText?: string;
    submitButtonIcon?: string;
    onNext?: () => Promise<void>;
    onBack?: () => Promise<void>;
    onCancel: () => void;
    onMiscButton1?: () => Promise<void>;
    children: ReactNode;
}

interface ICreateReviewModalProps {
    mode: 'add' | 'edit';
    onNewReviewPeriod: () => void;
    currentReviewPeriod: IReviewPeriod | undefined;
}

export default function CreateReviewModal(props: ICreateReviewModalProps): JSX.Element {
    const authContext = useContext(AuthContext);

    enum DialogBoxSteps {
        EnterReviewId,
        EnterReviewTitle,
        EnterPeriodDates,
        EnterApprovalDates,
        AddEmailNotification,
        ReviewAndSubmit,
    }

    const addMode = (): boolean => props.mode === 'add' && !props.currentReviewPeriod;
    const editMode = (): boolean => props.mode === 'edit' && !!props.currentReviewPeriod;

    /**
     * Order of items in the constant array "steps"
     * determines order of execution of the steps.
     * See onGoNext() and onGoBack().
     */
    const steps = [
        DialogBoxSteps.EnterReviewId,
        DialogBoxSteps.EnterReviewTitle,
        DialogBoxSteps.EnterPeriodDates,
        DialogBoxSteps.EnterApprovalDates,
        DialogBoxSteps.AddEmailNotification,
        DialogBoxSteps.ReviewAndSubmit,
    ];

    const firstStage = DialogBoxSteps.EnterReviewId;

    enum SCAReviewEventDateType {
        periodStart,
        periodEnd,
        periodEligibility,
        approvalStart,
        approvalEnd,
    }

    const [currentStage, setCurrentStage] = useState<DialogBoxSteps>(firstStage);
    const [reviewId, setReviewId] = useState<string>();
    const [reviewTitle, setReviewTitle] = useState<string>();
    const [dates, setDates] = useState<Dictionary<Date>>({});

    // The following are related to email notifications
    const [isShowEmailDatePicker, setIsShowEmailDatePicker] = useState<boolean>(false);
    const [emailNotifs, setEmailNotifs] = useState<EmailNotificationType[]>([]);
    const [newEmailDate, setNewEmailDate] = useState<Date>();
    const [level, setLevel] = useState<number>();
    const [{ sortAscending }, sortColumnHandler] = useSortColumnHandler('Date', 1);
    // The above are related to email notifications

    const earliestPeriodEndDate = (): Date | undefined => datePlusDays(dates[SCAReviewEventDateType.periodStart], 28); // prettier-ignore
    const earliestApprovalStartDate = (): Date | undefined => dates[SCAReviewEventDateType.periodStart]; // prettier-ignore
    const earliestApprovalEndDate = (): Date | undefined => datePlusDays(dates[SCAReviewEventDateType.approvalStart], 13); // prettier-ignore
    const earliestEligibilityDate = (): Date | undefined => dates[SCAReviewEventDateType.periodStart]; // prettier-ignore
    const latestApprovalEndDate = (): Date | undefined => dates[SCAReviewEventDateType.periodEnd]; // prettier-ignore
    const latestEligibilityDate = (): Date | undefined => dates[SCAReviewEventDateType.periodEnd]; // prettier-ignore
    const earliestEmailDate = (): Date | undefined => dates[SCAReviewEventDateType.approvalStart]; // prettier-ignore
    const latestEmailDate = (): Date | undefined => datePlusDays(dates[SCAReviewEventDateType.approvalStart], 99); // prettier-ignore

    const initValues = (): void => {
        let reviewIdVar = '';
        let reviewTitleVar = '';
        const datesVar: Dictionary<Date> = {};
        let emailNotifsVar: EmailNotificationType[] = [];

        if (editMode()) {
            reviewIdVar = props.currentReviewPeriod?.id as string; // This typecast is safe
            reviewTitleVar = props.currentReviewPeriod?.title as string; // This typecast is safe

            if (props.currentReviewPeriod?.approvalEndDateUTC)
                datesVar[SCAReviewEventDateType.approvalEnd] = new Date(
                    props.currentReviewPeriod?.approvalEndDateUTC * 1000,
                );

            if (props.currentReviewPeriod?.approvalStartDateUTC)
                datesVar[SCAReviewEventDateType.approvalStart] = new Date(
                    props.currentReviewPeriod?.approvalStartDateUTC * 1000,
                );

            if (props.currentReviewPeriod?.endDateUTC)
                datesVar[SCAReviewEventDateType.periodEnd] = new Date(
                    props.currentReviewPeriod?.endDateUTC * 1000,
                );

            if (props.currentReviewPeriod?.eligibilityDateUTC)
                datesVar[SCAReviewEventDateType.periodEligibility] = new Date(
                    props.currentReviewPeriod?.eligibilityDateUTC * 1000,
                );

            if (props.currentReviewPeriod?.startDateUTC)
                datesVar[SCAReviewEventDateType.periodStart] = new Date(
                    props.currentReviewPeriod?.startDateUTC * 1000,
                );

            if (props.currentReviewPeriod?.escalations)
                emailNotifsVar = props.currentReviewPeriod.escalations.map((escalation) => ({
                    date: new Date(escalation.date * 1000),
                    level: escalation.hierarchy,
                }));
        }

        setCurrentStage(firstStage);
        setReviewId(reviewIdVar);
        setReviewTitle(reviewTitleVar);
        setDates(datesVar);
        setEmailNotifs(emailNotifsVar);
        setNewEmailDate(undefined);
        setLevel(undefined);
        setIsShowEmailDatePicker(false);
    };

    useEffect(() => {
        initValues();
    }, []);

    const onCancel = (): void => {
        initValues();
    };

    const onSetDate = (type: SCAReviewEventDateType, date: Date | null | undefined): void => {
        setDates({
            ...dates,
            [type]: date,
        });
    };

    const onGoNext = async (): Promise<void> => {
        const currentIx = steps.findIndex((stage) => stage === currentStage);
        if (currentIx < steps.length - 1) {
            setCurrentStage(steps[currentIx + 1]);
        }
    };

    const onGoBack = async (): Promise<void> => {
        const currentIx = steps.findIndex((stage) => stage === currentStage);
        if (currentIx > 0) {
            setCurrentStage(steps[currentIx - 1]);
        }
    };

    const currentStageProps: Dictionary<() => IModalActionButtonSubset> = {};

    /*************************************************************************************
     * Is data Valid? Can user submit?
     */

    const isReviewIdInvalid = (): boolean => !reviewId;
    const isReviewTitleInvalid = (): boolean => !reviewTitle;

    const isPeriodDateRangeInvalid = (): boolean =>
        !dates[SCAReviewEventDateType.periodStart] ||
        !dates[SCAReviewEventDateType.periodEnd] ||
        !dates[SCAReviewEventDateType.periodEligibility] ||
        !earliestPeriodEndDate() ||
        dates[SCAReviewEventDateType.periodStart] >= dates[SCAReviewEventDateType.periodEnd] ||
        dates[SCAReviewEventDateType.periodEnd] < (earliestPeriodEndDate() as Date) || // This typecast is safe
        dates[SCAReviewEventDateType.periodEligibility] < (earliestEligibilityDate() as Date) ||
        dates[SCAReviewEventDateType.periodEligibility] > (latestEligibilityDate() as Date);

    const arePeriodDatesInvalid = (): boolean =>
        !dates[SCAReviewEventDateType.periodStart] ||
        !dates[SCAReviewEventDateType.periodEnd] ||
        isPeriodDateRangeInvalid();

    const isApprovalDateRangeInvalid = (): boolean =>
        !dates[SCAReviewEventDateType.approvalStart] ||
        !dates[SCAReviewEventDateType.approvalEnd] ||
        !earliestApprovalStartDate() ||
        !earliestApprovalEndDate() ||
        !latestApprovalEndDate() ||
        dates[SCAReviewEventDateType.approvalStart] >= dates[SCAReviewEventDateType.approvalEnd] ||
        dates[SCAReviewEventDateType.approvalStart] < (earliestApprovalStartDate() as Date) || // This typecast is safe
        dates[SCAReviewEventDateType.approvalEnd] < (earliestApprovalEndDate() as Date) || // This typecast is safe
        dates[SCAReviewEventDateType.approvalEnd] > (latestApprovalEndDate() as Date); // This typecast is safe

    const areApprovalDatesInvalid = (): boolean =>
        !dates[SCAReviewEventDateType.approvalStart] ||
        !dates[SCAReviewEventDateType.approvalEnd] ||
        isApprovalDateRangeInvalid();

    const areEmailDatesInvalid = (): boolean =>
        !earliestEmailDate() ||
        !latestEmailDate() ||
        emailNotifs.some(
            (notification) =>
                notification.date < (earliestEmailDate() as Date) || // This typecast is safe
                notification.date > (latestEmailDate() as Date), // This typecast is safe
        );

    const isSubmitDisabled = (): boolean => {
        const isDisabled =
            isReviewIdInvalid() ||
            isReviewTitleInvalid() ||
            arePeriodDatesInvalid() ||
            areApprovalDatesInvalid() ||
            areEmailDatesInvalid();

        return isDisabled;
    };

    /*************************************************************************************
     * DialogBoxSteps.EnterReviewId - First stage - Enter review period Id
     */
    const onReviewIdInput = (a?: React.FormEvent<HTMLElement>, str?: string): void => {
        if (a?.type === 'input') {
            setReviewId(str || '');
        }
    };

    currentStageProps[DialogBoxSteps.EnterReviewId] = (): IModalActionButtonSubset => ({
        enableNext: !isReviewIdInvalid(),
        enableSubmit: false,
        onBack: undefined,
        onNext: onGoNext,
        onCancel: onCancel,
        children: (
            <div>
                <TextField
                    label='Review Id'
                    placeholder='Review Id'
                    value={reviewId || ''}
                    onChange={onReviewIdInput}
                />
            </div>
        ),
    });

    /*************************************************************************************
     * DialogBoxSteps.EnterReviewTitle - Enter review title
     */

    const onReviewTitleInput = (a?: React.FormEvent<HTMLElement>, str?: string): void => {
        if (a?.type === 'input') {
            setReviewTitle(str || '');
        }
    };

    currentStageProps[DialogBoxSteps.EnterReviewTitle] = (): IModalActionButtonSubset => ({
        enableNext: !isReviewTitleInvalid(),
        enableSubmit: false,
        onBack: onGoBack,
        onNext: onGoNext,
        onCancel: onCancel,
        children: (
            <div>
                <TextField
                    label='Review Title'
                    placeholder='Review Title'
                    value={reviewTitle || ''}
                    onChange={onReviewTitleInput}
                />
            </div>
        ),
    });

    /*************************************************************************************
     * DialogBoxSteps.EnterPeriodDates - Get dates of review period
     */

    currentStageProps[DialogBoxSteps.EnterPeriodDates] = (): IModalActionButtonSubset => {
        const shouldShowErrorMsg =
            !!dates[SCAReviewEventDateType.periodStart] &&
            !!dates[SCAReviewEventDateType.periodEnd] &&
            !!dates[SCAReviewEventDateType.periodEligibility];
        return {
            errorMsg:
                shouldShowErrorMsg && isPeriodDateRangeInvalid()
                    ? 'The selected date range is invalid'
                    : '',
            enableNext: !arePeriodDatesInvalid(),
            enableSubmit: false,
            modalSubtitle: editMode() ? 'Start and end dates cannot be changed' : '',
            onBack: onGoBack,
            onNext: onGoNext,
            onCancel: onCancel,
            children: (
                <div>
                    <DatePicker
                        label='Period Start Date'
                        value={dates[SCAReviewEventDateType.periodStart]}
                        disabled={editMode()}
                        placeholder={'Select a date'}
                        allowTextInput={true}
                        onSelectDate={(date): void => {
                            onSetDate(SCAReviewEventDateType.periodStart, date);
                        }}
                    />
                    <Spacer marginTop={10} />
                    <DatePicker
                        label='Period End Date'
                        value={dates[SCAReviewEventDateType.periodEnd]}
                        disabled={editMode()}
                        placeholder={'Select a date'}
                        allowTextInput={true}
                        minDate={earliestPeriodEndDate()}
                        onSelectDate={(date): void => {
                            onSetDate(SCAReviewEventDateType.periodEnd, date);
                        }}
                    />
                    <Spacer marginTop={10} />
                    <DatePicker
                        label='Period Eligibility Date'
                        value={dates[SCAReviewEventDateType.periodEligibility]}
                        disabled={editMode()}
                        placeholder={'Select a date'}
                        allowTextInput={true}
                        minDate={earliestEligibilityDate()}
                        onSelectDate={(date): void => {
                            onSetDate(SCAReviewEventDateType.periodEligibility, date);
                        }}
                    />
                </div>
            ),
        };
    };

    /*************************************************************************************
     * DialogBoxSteps.EnterApprovalDates - Get dates of review period
     */

    currentStageProps[DialogBoxSteps.EnterApprovalDates] = (): IModalActionButtonSubset => {
        const shouldShowErrorMsg =
            !!dates[SCAReviewEventDateType.approvalStart] &&
            !!dates[SCAReviewEventDateType.approvalEnd];
        return {
            errorMsg:
                shouldShowErrorMsg && isApprovalDateRangeInvalid()
                    ? 'The selected date range is invalid'
                    : '',
            enableNext: !areApprovalDatesInvalid(),
            enableSubmit: false,
            onBack: onGoBack,
            onNext: onGoNext,
            onCancel: onCancel,
            children: (
                <div>
                    <DatePicker
                        label='Approval Start Date'
                        value={dates[SCAReviewEventDateType.approvalStart]}
                        minDate={earliestApprovalStartDate()}
                        placeholder={'Select a date'}
                        allowTextInput={true}
                        onSelectDate={(date): void => {
                            onSetDate(SCAReviewEventDateType.approvalStart, date);
                        }}
                    />
                    <Spacer marginTop={10} />
                    <DatePicker
                        label='Approval End Date'
                        minDate={earliestApprovalEndDate()}
                        maxDate={latestApprovalEndDate()}
                        value={dates[SCAReviewEventDateType.approvalEnd]}
                        placeholder={'Select a date'}
                        allowTextInput={true}
                        onSelectDate={(date): void => {
                            onSetDate(SCAReviewEventDateType.approvalEnd, date);
                        }}
                    />
                </div>
            ),
        };
    };

    /*************************************************************************************
     * DialogBoxSteps.AddEmailNotification - Add email notifications
     */

    const showAddEmailDatePicker = async (): Promise<void> => {
        setIsShowEmailDatePicker(true);
    };

    const hideAddEmailDatePicker = async (): Promise<void> => {
        setIsShowEmailDatePicker(false);
    };

    const addEmailNotification = async (): Promise<void> => {
        if (level !== undefined && newEmailDate !== undefined) {
            await Promise.resolve(
                setEmailNotifs(emailNotifs.concat({ level: level, date: newEmailDate })),
            );
            setLevel(undefined);
            setNewEmailDate(undefined);
            hideAddEmailDatePicker();
        }
    };

    const performDeleteNotification = (ix: number | undefined): void => {
        if (ix !== undefined) {
            const emailNotifsVar = deepcopy(emailNotifs);
            emailNotifsVar.splice(ix, 1);
            setEmailNotifs(emailNotifsVar);
        }
    };

    const sortEmailNotifications = (): EmailNotificationType[] => {
        const sortCmp = (r1: EmailNotificationType, r2: EmailNotificationType): number =>
            sortAscending * (r1.date > r2.date ? 1 : -1);
        return emailNotifs.sort(sortCmp);
    };

    // The following shows a table, listing the email notifications
    // that have already been set up by this module.
    const renderEmailNotifications = (showDeleteNotification: boolean): JSX.Element => {
        const columns = getEmailNotificationColumns({
            sortAscending: sortAscending === 1,
            showDeleteNotification,
            sortColumnHandler: sortColumnHandler,
            performDeleteNotification: performDeleteNotification,
        });
        return (
            <div>
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Optional email notifications
                </Separator>
                {emailNotifs.length === 0 && (
                    <>
                        <Spacer marginTop={10} />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            No email notification has been set
                        </div>
                    </>
                )}
                {emailNotifs.length !== 0 && (
                    <Table
                        rows={sortEmailNotifications()}
                        tableColumns={columns}
                        isFetchingData={false}
                        tableName='Email Notifications'
                    />
                )}
            </div>
        );
    };

    const levelSelectionOptions = managerialLevels.map((level) => ({
        key: level.level,
        text: level.text,
    }));

    const onLevelSelectHandler = (
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): void => {
        if (option?.key !== undefined && typeof option.key === 'number') {
            setLevel(option.key);
        }
    };

    const onSetNewEmailDate = (date: Date | null | undefined): void => {
        if (date) {
            setNewEmailDate(date);
        }
    };

    const addEmailNotificationProps = (): IModalActionButtonSubset => ({
        errorMsg: '',
        enableNext: true,
        enableSubmit: false,
        enableCancel: false,
        onMiscButton1: addEmailNotification,
        miscButton1Text: 'Add',
        miscButton1Icon: IconNames.MailReminder,
        onBack: hideAddEmailDatePicker,
        onCancel: onCancel,
        children: (
            <div>
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Optional email notifications
                </Separator>
                <DatePicker
                    minDate={earliestEmailDate()}
                    maxDate={latestEmailDate()}
                    label='Email Notification Date'
                    value={newEmailDate}
                    placeholder={'Select a date'}
                    allowTextInput={true}
                    onSelectDate={onSetNewEmailDate}
                />
                <Dropdown
                    label={'Level'}
                    styles={{ dropdownItems: globalStyles.dropdownItems }}
                    placeholder='Select a managerial level'
                    options={levelSelectionOptions}
                    onChange={onLevelSelectHandler}
                    selectedKey={level}
                />
            </div>
        ),
    });

    const showEmailNotificationsProps = (): IModalActionButtonSubset => ({
        errorMsg: areEmailDatesInvalid() ? 'Email notification date(s) invalid' : '',
        enableNext: true,
        enableSubmit: false,
        onMiscButton1: showAddEmailDatePicker,
        miscButton1Text: 'Add',
        miscButton1Icon: IconNames.MailReminder,
        onBack: onGoBack,
        onNext: onGoNext,
        onCancel: onCancel,
        children: renderEmailNotifications(true),
    });

    currentStageProps[DialogBoxSteps.AddEmailNotification] = (): IModalActionButtonSubset => {
        if (isShowEmailDatePicker) {
            return addEmailNotificationProps();
        } else {
            return showEmailNotificationsProps();
        }
    };

    /*************************************************************************************
     * DialogBoxSteps.ReviewAndSubmit - Review settings and perform submit
     */

    const onSubmit = async (): Promise<IReviewPeriod | undefined> => {
        if (
            isReviewIdInvalid() ||
            isReviewTitleInvalid() ||
            arePeriodDatesInvalid() ||
            areApprovalDatesInvalid()
        ) {
            return;
        }
        const escalations = emailNotifs.map((emailNotif) => {
            const level = findManagerialLevel(emailNotif.level);
            return {
                // It's very unlikely for variable "level" to be undefined
                // because the values it's searching for are hardcoded.
                // So I added the following typecasts.
                name: level?.text as string,
                hierarchy: level?.level as number,
                date: emailNotif.date,
            };
        });
        const requestBody: IScaCreateReviewRequestBody = {
            id: reviewId as string, // typecast is safe. If reviewId is undefined, the code will not reach here.
            title: reviewTitle as string, // typecast is safe. Similar reason.
            startDate: dates[SCAReviewEventDateType.periodStart],
            endDate: dates[SCAReviewEventDateType.periodEnd],
            eligibilityDate: dates[SCAReviewEventDateType.periodEligibility],
            approvalStartDate: dates[SCAReviewEventDateType.approvalStart],
            approvalEndDate: dates[SCAReviewEventDateType.approvalEnd],
            escalations: escalations,
        };
        // Tech Debt
        // The following code relies on ModalActionButton to catch error.
        // A better way to handle the error is to get text of the error
        // and throw it. ModalActionButton will then catch and display it
        // on a banner on the modal.
        // Leave the try/catch to ModalActionButton.
        if (addMode()) {
            await ScaClient.createReviewPeriod(authContext, requestBody);
        } else if (editMode()) {
            await ScaClient.updateReviewPeriod(authContext, requestBody);
        }
        initValues();
    };

    const onModalConcluded = (modalConclusion: ModalConclusion): void => {
        if (modalConclusion === ModalConclusion.Done) {
            props.onNewReviewPeriod();
        }
        initValues();
    };

    currentStageProps[DialogBoxSteps.ReviewAndSubmit] = (): IModalActionButtonSubset => ({
        enableSubmit: !isSubmitDisabled(),
        submitButtonText: addMode() ? 'Create Review' : 'Update Review',
        submitButtonIcon: addMode() ? IconNames.Add : IconNames.Edit,
        onBack: onGoBack,
        onCancel: initValues,
        children: (
            <div>
                <Stack horizontal style={{ width: 730, columnGap: 30 }}>
                    <Stack.Item styles={{ root: { width: '100%' } }}>
                        <Separator styles={globalSeparatorStyles} alignContent='start'>
                            <span>Review Id</span>
                        </Separator>
                        <div>{reviewId}</div>
                        <Spacer marginTop={5} />
                        <Separator styles={globalSeparatorStyles} alignContent='start'>
                            <span>Review Title</span>
                        </Separator>
                        <div>{reviewTitle}</div>
                        <Spacer marginTop={5} />
                        <Separator styles={globalSeparatorStyles} alignContent='start'>
                            <span>Preiod Dates</span>
                        </Separator>
                        <div>
                            {dateToLocalShortDateFormat(dates[SCAReviewEventDateType.periodStart])}
                            <span> to </span>
                            {dateToLocalShortDateFormat(dates[SCAReviewEventDateType.periodEnd])}
                        </div>
                        <Spacer marginTop={5} />
                        <Separator styles={globalSeparatorStyles} alignContent='start'>
                            <span>Approval Dates</span>
                        </Separator>
                        <div>
                            {dateToLocalShortDateFormat(
                                dates[SCAReviewEventDateType.approvalStart],
                            )}
                            <span> to </span>
                            {dateToLocalShortDateFormat(dates[SCAReviewEventDateType.approvalEnd])}
                        </div>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { width: '100%' } }}>
                        {renderEmailNotifications(false)}
                    </Stack.Item>
                </Stack>
            </div>
        ),
    });

    /*************************************************************************************
     *
     */

    if (addMode()) {
        return (
            <ModalActionButton
                {...Object.assign(currentStageProps[currentStage](), {
                    text: 'Create Review',
                    enable: !props.currentReviewPeriod,
                    tooltip: !!props.currentReviewPeriod
                        ? 'Cannot create a review period because a current review period is still open'
                        : '',
                    iconName: IconNames.Add,
                    modalTitle: 'Create Review',
                    modalTitleIcon: IconNames.Add,
                    // eslint-disable-next-line @typescript-eslint/prefer-as-const
                    buttonType: 'primary' as 'primary',
                    onSubmit: onSubmit,
                    onModalConcluded,
                })}
            />
        );
    } else if (editMode()) {
        return (
            <ModalActionButton
                {...Object.assign(currentStageProps[currentStage](), {
                    text: 'Edit Review',
                    enable: !!props.currentReviewPeriod,
                    iconName: IconNames.Edit,
                    modalTitle: 'Edit Review',
                    modalTitleIcon: IconNames.Edit,
                    onSubmit: onSubmit,
                    onModalConcluded,
                })}
            />
        );
    } else {
        return <></>;
    }
}
