import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

export interface PieChartProps {
    pieChartItems: PieChartItem[];
    displayLegend?: boolean;
    legendPosition?: string;
    boxSize?: string;
}

export type PieChartItem = {
    label: string;
    percentage: number;
    color: string;
};

export default function PieChart(props: PieChartProps): JSX.Element {
    const { pieChartItems } = props;
    const [labels, setLabels] = useState<string[]>([]);
    const [chartData, setChartData] = useState<number[]>([]);
    const [chartColors, setChartColors] = useState<string[]>([]);

    useEffect(() => {
        setLabels(pieChartItems.map((x) => x.label));
        setChartData(pieChartItems.map((x) => x.percentage));
        setChartColors(pieChartItems.map((x) => x.color));
    }, [pieChartItems]);

    return (
        <div style={{ display: 'flex', flex: `0 1 ${props.boxSize ?? '200px'}` }}>
            <Doughnut
                data={{
                    labels,
                    datasets: [
                        {
                            data: chartData,
                            backgroundColor: chartColors,
                            borderWidth: 0,
                        },
                    ],
                }}
                options={{
                    legend: {
                        display: !!props.displayLegend,
                        position: props.legendPosition ? props.legendPosition : '',
                    },
                    animation: false,
                }}
            />
        </div>
    );
}
