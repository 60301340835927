import React from 'react';
import { TextField, Stack, IPersonaProps } from '@fluentui/react';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
export interface ConvertProfileStepperPageSelectionProps {
    microsoftId?: string;
    selectedEmployeePersona?: IPersonaProps;
    onMicrosoftIdChange: (newValue?: string) => void;
    onSelectedEmployeeChange: (selectedEmployeePersona?: IPersonaProps) => void;
}

export default function ConvertProfileStepperPageSelection(
    props: ConvertProfileStepperPageSelectionProps,
): JSX.Element {
    return (
        <Stack tokens={{ childrenGap: 15 }}>
            <TextField
                label='Microsoft ID'
                required
                value={props.microsoftId || ''}
                onChange={(ev, newValue): void => props.onMicrosoftIdChange(newValue)}
                placeholder='Enter Microsoft ID'
            />
            <EmployeePickerTypeaheadSearch
                onCandidateSelected={props.onSelectedEmployeeChange}
                selectedPersona={props.selectedEmployeePersona}
                label='Alias'
                required={true}
                placeHolder='Enter Alias'
            />
        </Stack>
    );
}
