import React, { useRef, MutableRefObject } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import { ActionButton } from '@fluentui/react';
import { exportCsvString } from 'utils/reporting-utils';

interface IDownloadCsvReportButtonProps {
    // getData must return a CSV string.
    getData: () => Promise<string>;
    fileNamePrefix: string;
    disabled?: boolean;
    buttonText?: string;
}

// This component is suitable for calling functions or service endpoints
// that return a CSV data string (not an array). This component will call
// the function and export the CSV string thus obtained into a CSV file.
// Please note that the string must contain all rows of the desired file.
// If instead of a string your function or endpoint returns an array of
// data and you want to export that to a CSV file, use component
// ExportToExcelButton instead.
export default function DownloadCsvReportButton(props: IDownloadCsvReportButtonProps): JSX.Element {
    const downloadLink: MutableRefObject<HTMLAnchorElement | null> = useRef(null);

    const onClick = async (): Promise<void> => {
        if (!props.fileNamePrefix) {
            return;
        }

        // getData must return a csv data string.
        const data = await props.getData();

        if (!data) {
            return;
        }

        exportCsvString(data, {
            fileNamePrefix: props.fileNamePrefix,
            ref: downloadLink?.current,
        });
    };

    return (
        <>
            <a ref={downloadLink} hidden={true} />
            <ActionButton
                text={props.buttonText ?? 'Download report'}
                iconProps={{ iconName: IconNames.ExcelLogo }}
                onClick={onClick}
                disabled={props.disabled}
            />
        </>
    );
}
