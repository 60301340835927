import React, { useEffect, useState } from 'react';
import FacilitiesClient, {
    IReservationRecord,
    ISeatRecord,
    ProvisionType,
} from 'clients/facilities-client';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { useContext } from 'react';
import { TimeFormats, timeZoneAbbr } from 'utils/time-utils';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { Stack, TextField } from '@fluentui/react';
import { handleUTCToFacilityTimeZone } from 'components/facilities/common/facility-time-utils';
import { IconNames } from 'assets/constants/global-constants';
import Modal from 'components/common/modal';
import { generateRandomKey } from 'utils/misc-utils';
import {
    IReservationInstructions,
    IReservationPromiseInstruction,
    createPreclaimPromises,
    findSeatNames,
} from 'components/facilities/facilities-reservations/modals/facilities-reservation-instruction-utils';
import ClientUtils from 'clients/client-utils';

// Modal attempts the preclaim if all preclaims succed provide the user with a confirmation modal to confirm the reservation(s)
// cancel reservations if any are present
export interface FacilitiesReservationModalV2Props {
    onModalConcluded: (
        modalConclusion: ModalConclusion,
        reservationResults: IReservationPromiseInstruction[],
    ) => void;

    reservationInstructions: IReservationInstructions;
    iseatRecords: ISeatRecord[];
    show: boolean;
}

export default function FacilitiesReservationModalV2(
    props: FacilitiesReservationModalV2Props,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [isIcmDescriptionRequired, setIcmDescriptionRequired] = useState<boolean>(false);
    const [icmDescription, setIcmDescription] = useState<string>('');
    const [errorMsg, setErrorMsg] = useState<string>();

    const [isInProgress, setIsInProgress] = useState<boolean>(false);
    const [preclaims, setPreclaims] = useState<IReservationRecord[]>([]);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true);

    useEffect(() => {
        async function preclaimReservationForCurrentTimeslot(
            reservationInstructions: IReservationInstructions,
        ): Promise<void> {
            try {
                const preclaimpromises = createPreclaimPromises(
                    authContext,
                    userContext,
                    reservationInstructions.make,
                );
                const resolvedPreclaims = (await Promise.all(
                    preclaimpromises.map((x) => x.promise),
                )) as IReservationRecord[];
                setPreclaims(resolvedPreclaims);
            } catch (ex) {
                console.error(ex);
                let tmp = '';
                if (ex instanceof Promise) {
                    tmp = await ex;
                }
                setErrorMsg(
                    `Cannot obtain preclaim(s) for timespan, close dialog and try again. ${tmp}`,
                );
            } finally {
                setIsInProgress(false);
            }
        }

        if (props.show && props.reservationInstructions) {
            setIsInProgress(true);
            preclaimReservationForCurrentTimeslot(props.reservationInstructions);
        }
    }, [authContext, props.reservationInstructions, props.show, userContext]);

    useEffect(() => {
        const preclaimsLength = preclaims?.length ?? 0;
        let isSubmitButtonDisabled = false;
        if (
            isInProgress ||
            preclaimsLength === 0 ||
            (isIcmDescriptionRequired && icmDescription.trim().length === 0)
        ) {
            isSubmitButtonDisabled = true;
        }

        setIsSubmitButtonDisabled(isSubmitButtonDisabled);
    }, [preclaims, isIcmDescriptionRequired, icmDescription, isInProgress]);

    useEffect(() => {
        function determineIfIcmDescriptionIsRequired(
            reservationInstructions: IReservationInstructions | undefined,
            iseatRecords: ISeatRecord[] | undefined,
        ): boolean {
            for (const make of reservationInstructions?.make ?? []) {
                const seat = iseatRecords?.find((x) => x.id === make.seatId);
                if (seat?.provisionInfo.provisionType === ProvisionType.LiveSite) {
                    return true;
                }
            }

            for (const reschedule of reservationInstructions?.reschedule ?? []) {
                const seat = iseatRecords?.find((x) => x.id === reschedule.changeToSeatId);
                if (seat?.provisionInfo.provisionType === ProvisionType.LiveSite) {
                    return true;
                }
            }
            return false;
        }

        setIcmDescriptionRequired(
            determineIfIcmDescriptionIsRequired(props.reservationInstructions, props.iseatRecords),
        );
    }, [props.iseatRecords, props.reservationInstructions]);

    async function onSubmit(): Promise<IReservationPromiseInstruction[]> {
        const confirmationsPromises =
            preclaims?.map((x) => {
                return {
                    type: 'make',
                    instruction: props.reservationInstructions.make?.find(
                        (y) =>
                            y.timeslot.startDateTimeUTCMilliseconds ===
                            x.reservationTimeSlot.startDateTimeUTCMilliseconds,
                    ),
                    promise: ClientUtils.withRetry(
                        async () =>
                            await FacilitiesClient.confirmSeatReservation(
                                authContext,
                                userContext,
                                x.facilityId,
                                x.claimCode.code,
                                icmDescription,
                            ),
                    ),
                } as IReservationPromiseInstruction;
            }) ?? [];

        for (const promise of confirmationsPromises?.map((x) => x.promise) ?? []) {
            await promise;
        }

        return confirmationsPromises;
    }

    async function onCancel(): Promise<void> {
        const preClaimCancels = preclaims?.map((x) =>
            ClientUtils.withRetry(
                async () =>
                    await FacilitiesClient.cancelSeatReservation(authContext, userContext, x.id),
            ),
        );

        try {
            await Promise.all(preClaimCancels ?? []);
        } catch (ex) {
            console.log(ex);
            throw 'Something went wrong, please try again.';
        }
    }

    function getModalSubtitle(): JSX.Element {
        let returnJsxElement: JSX.Element = <></>;
        if (props.reservationInstructions.make) {
            const facility = props.reservationInstructions.facility;
            const make = props.reservationInstructions.make;

            const seatName = findSeatNames(
                make.map((x) => x.seatId),
                props.iseatRecords,
            ).join(',');
            try {
                returnJsxElement = (
                    <>
                        Would you like to confirm your reservation for the seat {seatName} facility{' '}
                        {facility.facilityName} on{' '}
                        {handleUTCToFacilityTimeZone(
                            Math.min(...make.map((x) => x.timeslot.startDateTimeUTCMilliseconds)),
                            facility,
                            TimeFormats.ddd_DD_MMM_YYYY,
                            'Unknown',
                        )}{' '}
                        from{' '}
                        {handleUTCToFacilityTimeZone(
                            Math.min(...make.map((x) => x.timeslot.startDateTimeUTCMilliseconds)),
                            facility,
                            TimeFormats.H_mmA,
                            'Unknown',
                        )}{' '}
                        to{' '}
                        {handleUTCToFacilityTimeZone(
                            Math.max(...make.map((x) => x.timeslot.endDateTimeUTCMilliseconds)),
                            facility,
                            TimeFormats.H_mmA,
                            'Unknown',
                        )}{' '}
                        {timeZoneAbbr(
                            facility.timeZone,
                            new Date(
                                Math.min(...make.map((x) => x.timeslot.endDateTimeUTCMilliseconds)),
                            ),
                        )}
                        ?
                        {props.reservationInstructions.cancel ? (
                            <>
                                <br />
                                This will cancel previous reservations overlapping in this timespan.
                                <br />
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                );
            } catch (ex) {
                console.log(ex);
                returnJsxElement = <>Error with values provided cancel and try again.</>;
            }
        }
        return returnJsxElement;
    }

    function getIcmDescriptionTextBox(): JSX.Element {
        return (
            <Stack>
                <Stack.Item>Please provide an ICM # or description.</Stack.Item>
                <Stack.Item>
                    <TextField
                        required
                        label='ICM # / Description'
                        value={icmDescription}
                        maxLength={255}
                        onChange={(e, value): void => {
                            setIcmDescription(value ?? '');
                        }}
                    />
                </Stack.Item>
            </Stack>
        );
    }

    return (
        <Modal
            key={`${generateRandomKey()}`}
            isOpen={true}
            submitButtonIcon={IconNames.AirTickets}
            title={'Confirming your reservation'}
            errorMsg={errorMsg}
            onResetErrorMsg={(): void => setErrorMsg(undefined)}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            isCancelButtonDisabled={false}
            onSubmit={(): void => {
                setIsInProgress(true);
                onSubmit()
                    .then((x) => {
                        props.onModalConcluded(ModalConclusion.Done, x);
                    })
                    .catch((ex) => {
                        setErrorMsg(ex);
                    })
                    .finally(() => {
                        setIsInProgress(false);
                    });
            }}
            onCancel={(): void => {
                setIsInProgress(true);
                onCancel()
                    .then(() => {
                        props.onModalConcluded(ModalConclusion.Cancel, []);
                    })
                    .catch((ex) => {
                        setErrorMsg(ex);
                    })
                    .finally(() => {
                        setIsInProgress(false);
                    });
            }}
            fluentModalProps={{ isBlocking: true }}>
            <Stack>
                <Stack.Item>{isIcmDescriptionRequired}</Stack.Item>
                <Stack.Item>{getModalSubtitle()}</Stack.Item>
                {isIcmDescriptionRequired && <Stack.Item>{getIcmDescriptionTextBox()}</Stack.Item>}
            </Stack>
        </Modal>
    );
}
