import React, { useState, useEffect, useContext } from 'react';
import {
    Dropdown,
    IDropdownOption,
    mergeStyles,
    IDropdownStyles,
    Stack,
    IStackTokens,
    Spinner,
    SpinnerSize,
} from '@fluentui/react';
import EligibilityClient, {
    IEligibility,
    IEligibilityAssignment,
} from 'clients/eligibility-client';

import { AuthContext } from 'contexts/auth-context';

interface IAssignEligibilityProps {
    eligibilityOptions: IEligibilityAssignment;
    personnelId: string;
    handleSetSelectedItem: (item: IDropdownOption) => void;
}

export default function AssignEligibility(props: IAssignEligibilityProps): JSX.Element {
    const [options, setOptions] = useState<IDropdownOption[]>();
    const authContext = useContext(AuthContext);
    const sectionStackTokens: IStackTokens = { childrenGap: 15 };

    useEffect(() => {
        fetchOptions();
    }, [props.eligibilityOptions]);

    const fetchOptions = () => {
        EligibilityClient.getAvailableEligibilitiesByPersonnelId(authContext, props.personnelId)
            .then((res: IEligibility[]) => {
                const options = res.map((eligibility: IEligibility) => {
                    return {
                        key: eligibility.id,
                        text: eligibility.eligibilityCode,
                    };
                });
                setOptions(options);
            })
            .catch((err) => console.error(err));
    };

    const onDropdownChange = (
        event: React.FormEvent<HTMLDivElement>,
        item?: IDropdownOption,
    ): void => {
        if (item) props.handleSetSelectedItem(item);
    };

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 350 },
    };

    if (options === undefined) return <Spinner size={SpinnerSize.large} />;

    return (
        <div>
            <h3 className={mergeStyles({ marginBottom: '.5rem' })}>
                Assign Personnel Eligibility{' '}
            </h3>

            <Stack tokens={sectionStackTokens}>
                <Dropdown
                    onChange={onDropdownChange}
                    placeholder='Select an Eligibility...'
                    options={options}
                    styles={dropdownStyles}
                />
            </Stack>
        </div>
    );
}
