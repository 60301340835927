import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IDropdownOption,
    MessageBarType,
} from '@fluentui/react';
import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import { dropdownComboBox } from 'components/screening/common/filters/common-filter-styling';
import { getContractTypeEnumValueFromKey, IContract } from 'components/screening/us-gov/IContract';
import useMessageBar from 'components/common/use-message-bar';
import { FiltersContractsContext } from 'contexts/filters-contracts-context';

export interface ContractTypeDropdownFilterProps {
    onFilterStateChange: (selectedContractTypes: string[], adding: boolean) => void;
    contracts: IContract[];
    placeHolder?: string;
}

export function ContractTypeDropdownFilter(props: ContractTypeDropdownFilterProps): JSX.Element {
    const {
        theMessage: message,
        theElement: messageElement,
        setMessage: setMessage,
    } = useMessageBar({
        type: MessageBarType.error,
    });
    const filtersContext = useContext(FiltersContractsContext);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const contractTypeOptions = getContractTypeOptionsFromContracts(props.contracts) ?? [];

    useEffect(() => {
        if (filtersContext?.filterCleared) setSelectedKeys([]);
    }, [filtersContext?.filterCleared]);

    return (
        <>
            <ComboBox
                ariaLabel='Contract Types'
                multiSelect
                selectedKey={selectedKeys}
                placeholder={props.placeHolder || 'Customer'}
                autoComplete='on'
                options={contractTypeOptions}
                onChange={onContractTypeSelect}
                useComboBoxAsMenuWidth
                allowFreeform
                styles={dropdownComboBox}
            />
            {!!message && messageElement()}
        </>
    );

    function onContractTypeSelect(
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
    ): void {
        if (option && option.key) {
            const contractType = option.key;
            try {
                const isAdding = !!option.selected;
                let newlySelected: string[] = [];
                setSelectedKeys((prevSelectedKeys) => {
                    newlySelected = isAdding
                        ? [...prevSelectedKeys, option!.key as string]
                        : prevSelectedKeys.filter((k) => k !== option!.key);
                    return newlySelected;
                });
                // we need to set this to something unique in the event that the
                // same agency is toggled on/off in succession
                filtersContext.setTypeId(`${contractType} ${Date.now()}`);
                props.onFilterStateChange(newlySelected, isAdding);
            } catch (error) {
                setMessage(
                    `An error occurred while loading the selected ${
                        props.placeHolder || 'Type'
                    } information.`,
                );
            }
        } else {
            filtersContext.setTypeId('');
            props.onFilterStateChange([], false);
        }
    }

    function getOptionsFromValidContractTypes(validContractType: Set<string>): IDropdownOption[] {
        let options: IDropdownOption[] = [];
        validContractType.forEach((contractType) => {
            if (contractType) {
                const contractTypeFullText = getContractTypeEnumValueFromKey(contractType);
                options.push({ key: contractType, text: contractTypeFullText });
            }
        });
        options = options.sort((a, b) => {
            const aUpper = a.text.toUpperCase();
            const bUpper = b.text.toUpperCase();
            if (aUpper < bUpper) return -1;
            if (aUpper > bUpper) return 1;
            return 0;
        });
        return options;
    }

    function getContractTypeOptionsFromContracts(contracts: IContract[]): IDropdownOption[] {
        const validContractType = new Set(
            contracts.map((x) => {
                return x.contractType || '';
            }),
        );

        return getOptionsFromValidContractTypes(validContractType);
    }
}
export default ContractTypeDropdownFilter;
