import React, { useState, useContext, useEffect, useReducer } from 'react';
import { IPersonaProps, IColumn, Stack, IStackStyles, mergeStyles } from '@fluentui/react';
import {
    StaffingFiltersContext,
    extractDefinedFilters,
    IStaffingFiltersContext,
} from 'components/staffing/contexts/staffing-filters-context';
import {
    IAllocationItem,
    DefinedFilterType,
    AllocationMapProps,
    FilterCategorySettingType,
    StaffingEmployeesPicturesType,
} from 'components/staffing/staffing-page-types';
import { getStatusTitle } from 'components/staffing/staffing-constants';
import { Table } from 'components/common/table';
import { getAllocationTableColumns } from 'components/staffing/allocation/get-allocation-table-columns';
import { IBasicEmployee } from 'clients/employee-client';
import {
    IStaffingTeamResponse,
    IStaffingCloudStatusResponse,
    IStaffingAllocationResponse,
} from 'clients/staffing-client';
import BarChart, { ChartItem } from 'components/common/charts/bar-chart';
import { generateStatusBarChartProps } from 'components/staffing/staffing-utils';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import StaffingOrgStatusTable from 'components/staffing/status/staffing-org-status-table';
import { Role } from 'configs/roles';
import { cloudFilterSubkey } from 'components/staffing/allocation/staffing-allocation-filters';
import { AuthContext } from 'contexts/auth-context';

export default function StaffingAllocationMap(props: AllocationMapProps): JSX.Element {
    const {
        orgName,
        showWhat,
        allocations,
        employeesPictures,
        basicEmployeesInfo,
        hasOrgEditPermission,
        editAllocationActionButton,
        deleteAllocationActionButton,
    } = props;

    const [tableColumns, setTableColumns] = useState<IColumn[]>([]);
    const [sortedItems, setSortedItems] = useState<IAllocationItem[]>([]);
    const [statusBarChartProps, setStatusBarChartProps] = useState<ChartItem[]>([]);
    const [filteredTeams, setFilteredTeams] = useState<IStaffingTeamResponse[]>([]);

    const [sortColumn, setSortColumn] = useState<string>('name');
    const toggleSortAscendingReducer = (sortAscending: number): number => {
        // Returns either 1 or -1.
        // Chose these values to make sorting easier.
        return -1 * sortAscending;
    };
    // Feb,4,2021 Apparently DetailsList handles column clicks in
    // a way that "useState" couldn't change value of the variable.
    // "useReducer" on the other hand, did the job.
    const [sortAscending, dispatchSortAscending] = useReducer(toggleSortAscendingReducer, 1);

    const authContext = useContext(AuthContext);
    const staffingFilters = useContext(StaffingFiltersContext);

    useEffect(() => {
        setTableColumns(
            getAllocationTableColumns({
                sortColumn: sortColumn,
                hasEditRoles: authContext.isInRole(Role.StaffingAdminEdit) || hasOrgEditPermission,
                sortAscending: sortAscending === 1,
                sortColumnHandler: sortColumnHandler,
                editAllocationActionButton,
                deleteAllocationActionButton,
            }),
        );
    }, [sortColumn, sortAscending, staffingFilters?.filters?.cloud[cloudFilterSubkey]]);

    const sortColumnHandler = (newSortColumn: string): void => {
        if (newSortColumn !== sortColumn) {
            setSortColumn(newSortColumn);
        } else {
            dispatchSortAscending();
        }
    };

    useEffect(() => {
        determineTableData(allocations, basicEmployeesInfo, employeesPictures, staffingFilters);
    }, [
        sortColumn,
        allocations,
        sortAscending,
        staffingFilters,
        employeesPictures,
        basicEmployeesInfo,
    ]);

    useEffect(() => {
        const teamFilterSetting = staffingFilters?.filters?.team || {};
        const teamFilterCount = Object.values(teamFilterSetting).reduce(
            (count, filter) => (filter ? count + 1 : count),
            0,
        );
        if (teamFilterCount >= 1) {
            setFilteredTeams((props.teams || []).filter((team) => teamFilterSetting[team.name]));
        } else {
            setFilteredTeams(props.teams);
        }
    }, [staffingFilters, props.teams]);

    const determineTableData = (
        allocations: IStaffingAllocationResponse[],
        basicEmployeesInfo: IPersonaProps[],
        employeesPictures: StaffingEmployeesPicturesType,
        staffingFilters: IStaffingFiltersContext,
    ): void => {
        const { filters } = staffingFilters;
        const filtered = filterAllocations(allocations, filters);
        const items = [] as IAllocationItem[];
        filtered.forEach((filteredItem: IStaffingAllocationResponse) => {
            const item = {} as IAllocationItem;
            item.employee = (basicEmployeesInfo.find((e) => e.id === filteredItem.personnelId) ||
                {}) as IBasicEmployee;

            item.PCN = filteredItem.positionControlNumber;
            item.personnelId = filteredItem.personnelId;
            item.team = filteredItem.team.name;
            item.organizationName = filteredItem.organization.name;
            item.clouds = filteredItem.cloudStatuses;
            item.projects = filteredItem.projects;
            item.preHireName = filteredItem.preHireName;
            item.picture = employeesPictures[item.employee.id];
            items.push(item);
        });

        type R = IAllocationItem;
        type sortFuncType = (i1: R, i2: R) => number;
        const sortFunc = (): sortFuncType => {
            switch (sortColumn) {
                case 'name':
                    return (i1: R, i2: R): number => {
                        const j1 = i1.employee.displayName ?? i1.preHireName;
                        const j2 = i2.employee.displayName ?? i2.preHireName;
                        return sortAscending * j1?.localeCompare(j2);
                    };
                case 'pcn':
                    return (i1: R, i2: R): number => sortAscending * i1.PCN?.localeCompare(i2.PCN);
                case 'team':
                    return (i1: R, i2: R): number => sortAscending * i1.team.localeCompare(i2.team);
                case 'cloud':
                    return (i1: R, i2: R): number => {
                        if (!i1.clouds || !i2.clouds) {
                            return 0;
                        }
                        return (
                            sortAscending *
                            i1.clouds[0]?.status?.localeCompare(i2.clouds[0]?.status)
                        );
                    };
                default:
                    return () => 0;
            }
        };

        const sortedItems = items.sort(sortFunc());
        setSortedItems(sortedItems);
        setStatusBarChartProps(
            generateStatusBarChartProps(
                filtered.map((a) => a.cloudStatuses),
                'forAllocationMap',
            ),
        );
    };

    const filterAllocations = (
        allocations: IStaffingAllocationResponse[],
        filters: FilterCategorySettingType,
    ): IStaffingAllocationResponse[] => {
        const hasNoTeamFilter = !Object.values(filters?.team || {}).find(
            (teamFilter) => teamFilter,
        );
        const hasNoStatusFilter = !Object.values(filters?.status || {}).find(
            (statusFilter) => statusFilter,
        );
        const definedFilters = extractDefinedFilters(filters);
        const filteredAllocations = allocations
            .map((allocation) => {
                return {
                    ...allocation,
                    // First, on this one allocation record, only let status of
                    // the one cloud that's selected by the filter to remain
                    // in this record.
                    cloudStatuses: allocation.cloudStatuses.filter(
                        (cloudStatus) => cloudStatus.name === filters?.cloud[cloudFilterSubkey],
                    ),
                };
            })
            .filter((allocation) => {
                // Now determine which allocation records can go through.
                const showIt =
                    definedFilters.length === 0 ||
                    (definedFilters.every(([key, subKey, value]: DefinedFilterType) => {
                        // AND on "employee" and "cloud" filter settings.
                        switch (key) {
                            case 'employee':
                                if (
                                    allocation.personnelId ===
                                    JSON.parse((value as IPersonaProps)?.itemProp || '{}').id
                                ) {
                                    return true;
                                }
                                return false;
                            case 'cloud':
                                if (
                                    value &&
                                    allocation.cloudStatuses.find(
                                        (status: IStaffingCloudStatusResponse) =>
                                            // status.name is from server.
                                            // Possible values: "EX", "RX".
                                            status.name === value,
                                    )
                                ) {
                                    return true;
                                }
                                return false;
                            default:
                                return true;
                        }
                    }) &&
                        (hasNoTeamFilter ||
                            definedFilters.some(([key, subKey, value]: DefinedFilterType) => {
                                switch (key) {
                                    // OR on team filter setting.
                                    case 'team':
                                        return value && allocation.team.name === subKey;
                                    default:
                                        return false;
                                }
                            })) &&
                        (hasNoStatusFilter ||
                            definedFilters.some(([key, subKey]: DefinedFilterType) => {
                                switch (key) {
                                    // OR on cloud status filter setting.
                                    case 'status':
                                        return !!allocation.cloudStatuses.find(
                                            (status: IStaffingCloudStatusResponse) =>
                                                getStatusTitle(status.status) === subKey &&
                                                status.name === filters?.cloud[cloudFilterSubkey],
                                        );
                                    default:
                                        return false;
                                }
                            })));
                return showIt;
            });
        return filteredAllocations;
    };

    const containerStyles: IStackStyles = {
        root: {
            marginBottom: '15px',
        },
    };

    return (
        <PageLoadingSpinner
            label={'Loading allocations...'}
            ariaLive='assertive'
            isLoading={props.loading}
            labelPosition='left'>
            <Stack styles={containerStyles}>
                <Stack.Item>
                    <BarChart
                        barChartData={statusBarChartProps}
                        yAxisProps={{ intelligentMax: true }}
                    />
                </Stack.Item>
                <Stack.Item>
                    {props.teams?.length > 0 && showWhat === 'allocation-map' && (
                        <Table<IAllocationItem>
                            rows={sortedItems}
                            isFetchingData={false}
                            tableColumns={tableColumns}
                            shimmerLabel='Loading allocations...'
                            tableName='Staffing Allocations'
                        />
                    )}
                    {props.teams?.length > 0 && showWhat === 'heat-map' && (
                        <div className={heatMapStyles}>
                            <StaffingOrgStatusTable
                                key={orgName}
                                teams={filteredTeams}
                                orgName={orgName}
                                showWhat='heat-map'
                                maxStatusColumns={Math.max(
                                    ...(props.teams || [{ peopleTarget: 0 }]).map(
                                        (team) => team.peopleTarget,
                                    ),
                                )}
                                theseAllocations={sortedItems
                                    .filter(
                                        (allocation) =>
                                            allocation.organizationName.toLocaleLowerCase() ===
                                            orgName.toLocaleLowerCase(),
                                    )
                                    .map((a) => ({
                                        pcn: a.PCN,
                                        team: a.team,
                                        personnelId: a.personnelId,
                                        preHireName: a.preHireName,
                                        organization: a.organizationName,
                                        cloudStatuses: a.clouds,
                                    }))}
                            />
                        </div>
                    )}
                </Stack.Item>
            </Stack>
        </PageLoadingSpinner>
    );
}

const heatMapStyles = mergeStyles({
    // The following two padding values are from what I observed on DetailsList.
    // Applied here so that whether the module is showing a heat map or allocation
    // map, the paddings, with respect to the bar chart, appear the same.
    paddingTop: '16px',
    paddingLeft: '12px',
});
