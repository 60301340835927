import React, { useContext } from 'react';
import {
    IStackTokens,
    Stack,
    mergeStyleSets,
    Separator,
    ChoiceGroup,
    IChoiceGroupOption,
    Dropdown,
    IDropdownOption,
} from '@fluentui/react';
import { globalStyles, globalSeparatorStyles } from 'assets/styles/global-styles';
import { StaffingStatusFiltersContext } from 'components/staffing/contexts/staffing-status-filter-context';
import { CloudStatusMap } from 'components/staffing/staffing-constants';

const styles = mergeStyleSets({
    top: {
        marginBottom: '15px',
    },
});

const filterStackTokens: IStackTokens = {
    childrenGap: 5,
};

export default function StaffingStatusFilters(): JSX.Element {
    const filterContext = useContext(StaffingStatusFiltersContext);

    const selectFilterHandler = (
        a?: React.FormEvent<HTMLElement>,
        selection?: IChoiceGroupOption,
    ): void => {
        if (selection) {
            filterContext.setFilter('filter', selection);
        }
    };

    const onCloudSelected = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): void => {
        if (option?.key !== undefined) {
            filterContext.setFilter('cloud', option);
        }
    };
    const cloudSelectionOptions: IDropdownOption[] = Object.entries(
        CloudStatusMap,
    ).map(([cloudKey, cloudName]) => ({ key: cloudKey, text: cloudName }));

    return (
        <div className={styles.top}>
            <Stack tokens={filterStackTokens}>
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Cloud
                </Separator>
                <Dropdown
                    styles={{ dropdownItems: globalStyles.dropdownItems }}
                    options={cloudSelectionOptions}
                    onChange={onCloudSelected}
                    selectedKey={filterContext.cloud.key as string}
                />
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Filter
                </Separator>
                <ChoiceGroup
                    selectedKey={filterContext.filter.key}
                    options={filterContext.filterChoices}
                    onChange={selectFilterHandler}
                />
            </Stack>
        </div>
    );
}
