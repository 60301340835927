import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import FacilitiesClient, {
    IFacilityRecord,
    IReservationsConfirmRequest,
    ISeatRecord,
    ISeatsTimeslotStatusesForDate,
    ITimeslotSeatStatus,
    updateSeatsTimeslotStatusesForDates,
} from 'clients/facilities-client';
import { useIsMounted } from 'utils/misc-hooks';
import { Label, mergeStyleSets, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import moment from 'moment';
import ReservationCalendarColumn, {
    ICalendarReservationRecords,
    IReservationCalendarColumn,
} from 'components/facilities/facilities-reservations/facilities-reservation-calendar/reservation-calendar-column';
import {
    convertToReadableTime,
    isValidTimeslotSeatStatus,
} from 'components/facilities/common/facility-time-utils';
import FacilitiesReservationCalendarModal from 'components/facilities/facilities-reservations/modals/facilities-reservation-calendar-modal';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';

interface IFacilitiesReservationCalendarProps {
    selectedDate: Date;
    selectedFacility?: IFacilityRecord;
    facilitySeats: ISeatRecord[];
    setIsReserveClicked: (isClicked: boolean) => void;
    isReserveClicked: boolean;
    setIsReserveButtonEnabled: (enabled: boolean) => void;
    isUserBlockedFromReserving: boolean;
}

export default function FacilitiesReservationCalendar(
    props: IFacilitiesReservationCalendarProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [calendarData, setCalendarData] = useState<IReservationCalendarColumn[]>();
    const [reservationRecords, setReservationRecords] = useState<ICalendarReservationRecords>({
        reservationRecords: [],
    });
    const [showSuccessMessageBar, setSuccessMessageBar] = useState<boolean>(false);
    const [showErrorMessageBar, setErrorMessageBar] = useState<boolean>(false);
    const [showIcmModal, setShowIcmModal] = useState<boolean>(false);
    const [icmDescription, setIcmDescription] = useState<string | undefined>();

    const isMounted = useIsMounted();

    useEffect(() => {
        if (reservationRecords.reservationRecords.length > 0 && !props.isUserBlockedFromReserving) {
            props.setIsReserveButtonEnabled(true);
        } else {
            props.setIsReserveButtonEnabled(false);
        }
    }, [reservationRecords]);

    useEffect(() => {
        if (props.selectedFacility && props.selectedDate) {
            hideMessageBars();
            getAvailability();
        }
    }, [props.selectedDate, props.selectedFacility]);

    useEffect(() => {
        if (props.isReserveClicked) {
            if (hasIcMRecords()) {
                setShowIcmModal(true);
            } else {
                confirmPreclaimedWithoutIcMModal();
            }
            props.setIsReserveClicked(false);
        }
    }, [props.isReserveClicked]);

    function hideMessageBars(): void {
        setSuccessMessageBar(false);
        setErrorMessageBar(false);
    }

    const getAvailability = async (): Promise<void> => {
        try {
            const newAvail: ISeatsTimeslotStatusesForDate[] = [];
            const startDate = moment
                .utc(props.selectedDate)
                .subtract(2, 'days')
                .startOf('days')
                .toDate();
            const endDate = moment.utc(props.selectedDate).add(2, 'days').endOf('days').toDate();

            let continuationToken = '';
            do {
                const localResults = await FacilitiesClient.getSeatAvailabilityForCalendar(
                    authContext,
                    userContext,
                    props.selectedFacility!.facilityId,
                    startDate,
                    endDate,
                    continuationToken,
                );

                updateSeatsTimeslotStatusesForDates(
                    newAvail,
                    localResults.seatsTimeslotStatusesForDates,
                );

                continuationToken = localResults.continuationToken ?? '';
            } while (continuationToken);

            if (isMounted()) {
                convertAndUpdateCalendarData(newAvail);
            }
        } catch (error) {
            console.log('An error occurred getting availability:');
            console.log(error);
        }
    };

    function filterTimeslots(slot: ITimeslotSeatStatus): boolean {
        return isValidTimeslotSeatStatus(props.selectedFacility!, props.selectedDate, slot);
    }

    const convertAndUpdateCalendarData = (data: ISeatsTimeslotStatusesForDate[]): void => {
        const timeslots = data
            .map((x) => Object.entries(x.seatTimeslotsStatusesForDateDict))
            .flat()
            .map((y) => y[1])
            .flat()
            .filter(filterTimeslots)
            .map((z) => ({ ...z, isLoading: false }));

        const timeslotMap = new Map();
        for (const index in timeslots) {
            const item = timeslots[index];
            const startTime = item.timeslotItem.startDateTimeUTCMilliseconds;
            if (timeslotMap.has(startTime)) {
                const existing = timeslotMap.get(startTime);
                const newItem = { seatId: item.seatStatus.seatId, ...item };
                timeslotMap.set(startTime, [...existing, newItem]);
            } else {
                const newItem = { seatId: item.seatStatus.seatId, ...item };
                timeslotMap.set(startTime, [newItem]);
            }
        }

        const result = Array.from(timeslotMap.entries()).map((x) => {
            const title = convertToReadableTime(
                x[1][0].timeslotItem.startDateTimeUTCMilliseconds,
                x[1][0].timeslotItem.endDateTimeUTCMilliseconds,
                props.selectedFacility,
            );
            return { columnTitle: title, rows: x[1] };
        });

        if (isMounted()) {
            setCalendarData(result);
        }
    };

    const confirmPreclaimedReservations = async (): Promise<boolean> => {
        const request: IReservationsConfirmRequest[] = reservationRecords.reservationRecords.map(
            (x) => {
                return { claimCode: x.claimCode.code, icmDescription: icmDescription };
            },
        );
        try {
            await FacilitiesClient.confirmSeatReservations(
                authContext,
                userContext,
                props.selectedFacility!.id,
                request,
            );

            if (isMounted()) {
                setReservationRecords({
                    reservationRecords: [],
                });
                setSuccessMessageBar(true);
            }
            return true;
        } catch (error) {
            console.log('An error occurred making seat reservations:');
            console.log(error);
            if (isMounted()) {
                setErrorMessageBar(true);
            }
            return false;
        }
    };

    function hasIcMRecords(): boolean {
        return reservationRecords.reservationRecords.some((x) => x.isIcm);
    }

    async function confirmPreclaimedWithoutIcMModal(): Promise<void> {
        await confirmPreclaimedReservations().then(async (resultSuccess) => {
            if (resultSuccess) {
                await getAvailability();
            }
        });
    }

    async function onConfirmedOrCancelledReservation(conclusion: ModalConclusion): Promise<void> {
        if (conclusion === ModalConclusion.Done) {
            await confirmPreclaimedReservations().then(async (resultSuccess) => {
                if (resultSuccess) {
                    await getAvailability();
                }
            });
            if (isMounted()) {
                setIcmDescription(undefined);
                setSuccessMessageBar(true);
            }
        }
        if (isMounted()) {
            setShowIcmModal(false);
        }
    }

    if (props.facilitySeats && props.selectedFacility && calendarData) {
        return (
            <>
                {!props.isUserBlockedFromReserving && (
                    <MessageBar className={styles.linkContainer}>
                        Please select the seat timeslots you want to make reservations for then
                        click the reserve button
                    </MessageBar>
                )}
                {showSuccessMessageBar && (
                    <MessageBar
                        messageBarType={MessageBarType.success}
                        onDismiss={(): void => setSuccessMessageBar(false)}>
                        Reservation successful!
                    </MessageBar>
                )}
                {showErrorMessageBar && (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        onDismiss={(): void => setErrorMessageBar(false)}>
                        An error occurred during reservation
                    </MessageBar>
                )}
                <Stack horizontal>
                    {showIcmModal && (
                        <FacilitiesReservationCalendarModal
                            renderWithoutButton={true}
                            selectedFacility={props.selectedFacility}
                            onModalConcluded={onConfirmedOrCancelledReservation}
                            icmDescription={icmDescription}
                            setIcmDescription={setIcmDescription}
                        />
                    )}
                    <Stack.Item>
                        <Stack>
                            <Stack.Item>
                                <Label>Seat / Timeslot </Label>
                            </Stack.Item>
                            {props.facilitySeats.map((value) => (
                                <Stack.Item
                                    key={`${value.facilityId}-${value.id}`}
                                    style={{ paddingTop: '10px', height: '32px' }}>
                                    <Label>{value.seatName}</Label>
                                </Stack.Item>
                            ))}
                        </Stack>
                    </Stack.Item>
                    {calendarData.map((value: IReservationCalendarColumn, index: React.Key) => (
                        <Stack.Item
                            key={`${props.selectedFacility?.facilityId}-${value.rows[0].timeslotItem.startDateTimeUTCMilliseconds}-${index}`}>
                            <ReservationCalendarColumn
                                {...value}
                                selectedFacility={props.selectedFacility!}
                                selectedDate={props.selectedDate}
                                reservationRecords={reservationRecords}
                                setReservationRecords={setReservationRecords}
                                setSuccessProp={setSuccessMessageBar}
                                setErrorProp={setErrorMessageBar}
                                isReserveClicked={props.isReserveClicked}
                                facilitySeats={props.facilitySeats}
                                setIcmDescription={setIcmDescription}
                                isUserBlockedFromReserving={props.isUserBlockedFromReserving}
                            />
                        </Stack.Item>
                    ))}
                </Stack>
            </>
        );
    } else {
        return (
            <>
                <h4>No timeslots found for this date or facility</h4>
            </>
        );
    }
}

const styles = mergeStyleSets({
    linkContainer: {
        marginBottom: '1em',
    },
});
