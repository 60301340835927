import React, { useContext, useMemo } from 'react';
import {
    Persona,
    PersonaPresence,
    PersonaInitialsColor,
    PersonaSize,
    mergeStyleSets,
    FontWeights,
    FontSizes,
    IStyle,
    Icon,
} from '@fluentui/react';
import { NeutralColors, SharedColors } from '@fluentui/theme';
import { globalStyles } from 'assets/styles/global-styles';
import { getDisplayNameOrDefault, getInitials } from 'components/common/employee/employee-utils';
import { NavLink } from 'react-router-dom';
// This common file has been updated to handle UserContext not always being present
// eslint-disable-next-line no-restricted-imports
import { IUserContext, UserContext } from 'contexts/user-context';
import config from 'environments/environment';
import { IEmployee } from 'clients/employee-client';
import { generateRandomKey } from 'utils/misc-utils';
import { isGUID } from 'utils/string-utils';
import { faintBlue } from 'assets/constants/global-colors';
import { AuthContext, RenderMode } from 'contexts/auth-context';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import { getJobTitleOrDefault } from 'components/common/employee/internal-employee-utils';
export interface EmployeeCardProps {
    employee:
        | IEmployee
        | {
              id?: string;
              displayName?: string;
              preferredFirstName?: string;
              preferredLastName?: string;
              firstName?: string;
              lastName?: string;
              alias?: string;
              email?: string;
              department?: string;
              jobTitle?: string;
              standardJobTitle?: string;
              oid?: string;
          }
        | undefined;
    manager: string | null; // null can happen for CEO.
    managerAlias: string | null; // null can happen for CEO.
    pcn?: string;
    displayActions?: boolean;
    image?: string;
    detailHeight?: number;
    style?: IEmployeeCardStyle; // Additional styling. See Note (±) below.
    oid?: string;
    displayEmail?: boolean;
    displayManager?: boolean;
}
// Note (±)
// The following is added to specify additional stylings for this
// component. If you need to add properties to it, if it makes sense
// and possible try to make it compatible with stylings defined by
// variable "defaultStyle" at Note (+) at the bottom of the file,
// because the function mergeStyleSets will merge the two.
// For instance, the properties "card" and "cardActions" below are
// already properties of object "defaultStyle". By specifying styling
// on them, they will automatically merge them with the already
// available styles by the same name, and you won't have to make any
// additional effort to make them take effect.
export interface IEmployeeCardStyle {
    // Add more stylings here.
    card: IStyle; // Will merge with "style.card" defined at Note (+).
    cardActions?: IStyle; // Additional styles for the <Actions> instance.
    title?: IStyle;
    department?: IStyle;
    details?: IStyle;
    contact?: IStyle;
}
export default function EmployeeCard(props: EmployeeCardProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const displayName = getDisplayNameOrDefault(props.employee);
    const imageAlt = `${displayName}'s photo`;
    const imageInitials = getInitials(displayName);
    const imageUrl = props.image;
    const style = useMemo(() => mergeStyleSets(defaultStyle, props?.style), [
        defaultStyle,
        defaultStyle.card,
        props.style,
        props?.style?.card,
    ]);

    function getEmailOrDefault(defaultValue: string): string {
        return props?.employee?.email ? props?.employee?.email.toLocaleLowerCase() : defaultValue;
    }
    return (
        <div className={style.card}>
            <div
                className={style.details}
                style={{ height: props.detailHeight ? props.detailHeight : 'auto' }}>
                <div style={{ paddingRight: employeeCardPadding }}>
                    <Persona
                        size={PersonaSize.size56}
                        initialsColor={PersonaInitialsColor.purple}
                        presence={PersonaPresence.none}
                        hidePersonaDetails={true}
                        imageAlt={imageAlt}
                        imageInitials={imageInitials}
                        key={
                            props?.employee?.alias
                                ? `employee_card_${props?.employee?.alias}`
                                : generateRandomKey('CardPersona')
                        }
                        showInitialsUntilImageLoads={true}
                        imageUrl={imageUrl}
                    />
                </div>
                <div className={style.contact}>
                    <div>
                        <div>
                            <span className={style.name}>
                                {getDisplayNameOrDefault(props.employee, '')}
                            </span>
                            {props?.employee?.alias && (
                                <span className={globalStyles.fontStylesAlias}>
                                    ({props?.employee?.alias.toLowerCase()})
                                </span>
                            )}
                        </div>
                        {(props.displayEmail === undefined || props.displayEmail === true) && (
                            <div className={style.email}>
                                {authContext?.renderMode() === RenderMode.Web && (
                                    <a href={'mailto:' + getEmailOrDefault('')}>
                                        {getEmailOrDefault('')}
                                    </a>
                                )}
                                {authContext?.renderMode() !== RenderMode.Web && (
                                    <>{getEmailOrDefault('')}</>
                                )}
                            </div>
                        )}
                    </div>
                    <div>
                        {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                            (!isGUID(props.employee?.id!) || props.oid !== undefined) && (
                                <>
                                    <div className={style.title}>
                                        {getJobTitleOrDefault(props.employee, '')}
                                    </div>
                                    <div className={style.department}>
                                        {props?.employee?.department}
                                    </div>
                                </>
                            )
                        }
                        {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                            isGUID(props.employee?.id!) && props.pcn && (
                                <div className={style.pcn}>
                                    <span className={style.pcnLabel}>PCN:</span>
                                    <span>{props.pcn}</span>
                                </div>
                            )
                        }
                    </div>
                    {(props.displayManager === undefined || props.displayManager === true) && (
                        <div className={style.manager}>
                            <span className={style.managerLabel}>Manager:</span>
                            <span>{props.manager + ' '}</span>
                            {props.managerAlias && (
                                <span className={globalStyles.fontStylesAlias}>
                                    ({props.managerAlias.toLowerCase()})
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {(props.displayActions === undefined || props.displayActions === true) && (
                <Actions
                    alias={props?.employee?.alias}
                    id={props?.employee?.id}
                    oid={props?.oid ?? props?.employee?.oid}
                    email={props?.employee?.email}
                    style={props.style}
                />
            )}
        </div>
    );
}
interface ActionProps {
    alias: string | undefined;
    id: string | undefined;
    oid: string | undefined;
    email: string | undefined;
    style?: IEmployeeCardStyle;
}
function Actions(props: ActionProps): JSX.Element {
    const userContext = useContext(UserContext) as IUserContext | null;
    const iconStyle = {
        root: {
            fontSize: 16,
            color: SharedColors.cyanBlue10,
            lineHeight: 16,
            marginRight: 5,
        },
    };
    const style = useMemo(() => mergeStyleSets(defaultStyle, props?.style), [
        defaultStyle,
        props.style,
    ]);
    return (
        <div className={style.cardActions}>
            {!isGUID(props.id!) && (
                <>
                    <div className={style.cardAction}>
                        <a className={style.actionLabel} href={'mailto:' + props.email}>
                            <Icon iconName='Mail' styles={iconStyle} />
                            <span>Email</span>
                        </a>
                    </div>
                    <div className={style.cardAction}>
                        <a
                            className={style.actionLabel}
                            href={'msteams://teams.microsoft.com/l/chat/0/0?users=' + props.email}
                            target='_blank'
                            rel='noopener noreferrer'>
                            <Icon iconName='OfficeChat' styles={iconStyle} />
                            <span>Chat</span>
                        </a>
                    </div>
                    {config.orgExplorerUrl && props.oid && (
                        <div className={style.cardAction}>
                            <a
                                className={style.actionLabel}
                                href={`${config.orgExplorerUrl.replace('{oid}', props.oid ?? '')}`}
                                target='_blank'
                                rel='noopener noreferrer'>
                                <Icon iconName='Org' styles={iconStyle} />
                                <span>Org</span>
                            </a>
                        </div>
                    )}
                </>
            )}
            {userContext?.hasUsGovScreeningUserType(UsGovScreeningUserType.NST) && (
                <div className={style.cardAction}>
                    <NavLink
                        to={`/profile/us-gov/${
                            props?.alias ? `${props.alias?.toLocaleUpperCase()}` : `${props.id}`
                        }`}
                        className={style.actionLabel}
                        rel='noopener noreferrer'>
                        <Icon iconName='Contact' styles={iconStyle} />
                        <span>Profile</span>
                    </NavLink>
                </div>
            )}
        </div>
    );
}
/**
 * @constant
 */
export const employeeCardPadding = 15;
const defaultStyle = mergeStyleSets({
    // Note (+)
    employee: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'fit-content',
        cursor: 'pointer',
    },
    name: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.medium,
        marginRight: '5px',
        borderRadius: 2,
    },
    title: {
        fontSize: FontSizes.medium,
        textTransform: 'uppercase',
        marginBottom: 5,
        marginTop: 5,
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 350,
        minHeight: 150,
        backgroundColor: NeutralColors.white,
        boxSizing: 'border-box',
        borderRadius: 2,
    },
    details: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        padding: employeeCardPadding,
    },
    cardActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '15px',
        cursor: 'pointer',
        borderTop: '1px solid rgba(0, 0, 0, 0.07)', //requested by the design team
        backgroundColor: 'rgba(0, 0, 0, 0.07)', //requested by the design team
    },
    cardAction: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        selectors: {
            '& a': {
                color: 'rgb(50, 49, 48)',
            },
            '& a:hover': {
                color: faintBlue,
            },
        },
    },
    actionLabel: {
        textDecoration: 'none',
        fontSize: 14,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
    },
    contact: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    email: {
        selectors: {
            '': {
                color: faintBlue,
                textDecoration: 'underline',
            },
        },
    },
    manager: {
        marginTop: 5,
    },
    managerLabel: {
        marginRight: '5px',
        fontWeight: FontWeights.semibold,
    },
    pcn: {
        height: '25px',
        marginTop: 5,
    },
    pcnLabel: {
        marginRight: '5px',
        fontWeight: FontWeights.semibold,
    },
});
