import {
    ActionButton,
    Dropdown,
    DropdownMenuItemType,
    FontIcon,
    ICommandBarItemProps,
    IDropdownOption,
    mergeStyles,
    TextField,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import React, { useCallback } from 'react';
import ElementFooter from 'components/forms/element-footer';
import {
    ElementTypeProps,
    ElementType,
    FormElement,
    ChoiceOptions,
    Section,
    next,
    endOfForm,
} from 'components/forms/forms-common';

const labelTypeStyles = mergeStyles({
    display: 'flex',
    marginBottom: '1.5rem',
    gap: '5rem',
    marginRight: '1rem',
});

type ChoiceGroupElementProps = ElementTypeProps & {
    elements: FormElement[];
    futureSections: Section[];
};
export default function ChoiceGroupElement(props: ChoiceGroupElementProps): JSX.Element {
    const { element, elements, futureSections, updateForm } = props;
    const options = (element.options ?? [
        { key: crypto.randomUUID(), text: 'Option 1', branchTarget: next },
    ]) as ChoiceOptions[];

    const BranchOptions = useCallback(() => {
        const currIndex = elements.findIndex((el) => el.id === element.id);
        const futureQuestions = elements.slice(currIndex + 1);
        const numPrevInfoElements = elements.filter(
            (el, index) => el.type === 'info' && index < currIndex,
        ).length;
        const offset = elements.length - futureQuestions.length - numPrevInfoElements;

        const nextQuestion = [
            { key: next, text: 'Next' },
            { key: endOfForm, text: 'End of form' },
            { key: 'divider_0', text: '-', itemType: DropdownMenuItemType.Divider },
        ];
        const questionHeader = {
            key: 'Questions',
            text: 'Questions',
            itemType: DropdownMenuItemType.Header,
        };
        let calculatedIndex = 0;
        const questionItems = futureQuestions.map((element) => {
            if (element.type !== 'info') {
                calculatedIndex += 1;
            }
            return {
                key: element.id,
                text: getQuestionDropdownText(element, calculatedIndex + offset),
            };
        });

        const sectionItems =
            futureSections.length === 0
                ? []
                : [
                      { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
                      {
                          key: 'Sections',
                          text: 'Sections',
                          itemType: DropdownMenuItemType.Header,
                      },
                      ...futureSections.map((section) => ({
                          key: section.id.toString(),
                          text: section.id + 1 + '. ' + section.label,
                      })),
                  ];
        return [...nextQuestion, questionHeader, ...questionItems, ...sectionItems];
    }, [element.id, elements, futureSections]);

    const getQuestionDropdownText = (element: FormElement, index: number) => {
        if (element.type === 'info') {
            return 'Info Element';
        }
        return index + '. ' + element.label;
    };

    const choiceOverflowItems: ICommandBarItemProps[] =
        element.type !== 'checkbox'
            ? [
                  {
                      key: 'delete',
                      text: 'Enable based on selection',
                      onClick: () => {
                          if (!element.branches) {
                              updateForm(element.id, true, 'branches');
                          } else {
                              updateForm(element.id, false, 'branches');
                          }
                      },
                      iconProps: { iconName: element.branches ? IconNames.CheckMark : '' },
                  },
              ]
            : [];

    const choiceTypes: IDropdownOption[] = [
        { key: ElementType.checkbox, text: 'Checkbox', data: { icon: IconNames.Checkbox } },
        { key: ElementType.radio, text: 'Radio', data: { icon: IconNames.CircleRing } },
        { key: ElementType.dropdown, text: 'Dropdown', data: { icon: IconNames.CaretDownSolid8 } },
    ];
    const iconName =
        element.type === 'checkbox'
            ? IconNames.Checkbox
            : element.type === 'radio'
            ? IconNames.CircleRing
            : IconNames.CaretDownSolid8;

    const onRenderOption = (option?: IDropdownOption): JSX.Element => {
        if (!option) {
            return <></>;
        }
        return (
            <div>
                <FontIcon
                    style={{ marginRight: '8px' }}
                    iconName={option.data.icon}
                    aria-hidden='true'
                    title={option.text}
                />
                <span>{option.text}</span>
            </div>
        );
    };
    return (
        <>
            <div className={labelTypeStyles}>
                <div style={{ flexGrow: 2 }}>
                    <TextField
                        value={element.label}
                        onChange={(ev, newValue): void => updateForm(element.id, newValue, 'label')}
                        placeholder='Enter a label...'
                        underlined
                    />
                </div>
                <Dropdown
                    selectedKey={element.type}
                    placeholder='Select an option'
                    ariaLabel='Select an option'
                    options={choiceTypes}
                    styles={{ dropdown: { width: 200 } }}
                    onChange={(ev, newValue) => {
                        updateForm(element.id, (newValue?.key as string) ?? element.type, 'type');
                        if (newValue?.key === ElementType.checkbox) {
                            updateForm(element.id, false, 'branches');
                        }
                    }}
                    onRenderOption={onRenderOption}
                />
            </div>
            {options.map((option, index) => (
                <div
                    key={option.key}
                    style={{ display: 'flex', alignItems: 'center', paddingBottom: '1rem' }}>
                    <FontIcon style={{ padding: '4px 5px 0 0' }} iconName={iconName} />
                    <TextField
                        style={{ width: '275px' }}
                        value={option.text}
                        onChange={(ev, newValue) => {
                            const newOption = { ...option };
                            newOption.text = newValue ?? '';
                            options[index] = newOption;
                            updateForm(element.id, options, 'options');
                        }}
                        underlined
                        placeholder='Enter a label...'
                    />
                    <ActionButton
                        title='Delete element'
                        aria-label='Delete'
                        iconProps={{ iconName: IconNames.Trash }}
                        style={{ padding: '4px 5px 0 0' }}
                        onClick={() => {
                            const newOptions = options.filter((el) => el.key !== option.key);
                            updateForm(element.id, newOptions, 'options');
                        }}
                    />
                    {element.branches && (
                        <div style={{ display: 'flex', alignItems: 'end' }}>
                            <span>If selected, go to &nbsp;</span>
                            <Dropdown
                                placeholder='Select options'
                                selectedKey={option.branchTarget ?? ''}
                                onChange={(event, selectedOption) => {
                                    const newOption = { ...option };
                                    newOption.branchTarget =
                                        (selectedOption?.key as string) ?? next;
                                    options[index] = newOption;
                                    updateForm(element.id, options, 'options');
                                }}
                                options={BranchOptions()}
                                styles={{ dropdown: { width: 200 } }}
                            />
                        </div>
                    )}
                </div>
            ))}
            <ActionButton
                title='Add Option'
                aria-label='Add Option'
                iconProps={{ iconName: IconNames.Add }}
                style={{ marginTop: '1rem' }}
                onClick={() => {
                    const newOption: ChoiceOptions = {
                        key: crypto.randomUUID(),
                        text: `Option ${options.length + 1}`,
                        branchTarget: next,
                    };
                    const newOptions = options.concat(newOption);
                    updateForm(element.id, newOptions, 'options');
                }}>
                Add Option
            </ActionButton>
            <ElementFooter
                element={element}
                updateForm={updateForm}
                hasRequiredToggle={true}
                overflowItems={choiceOverflowItems}
            />
        </>
    );
}
