import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from 'contexts/auth-context';
import SidebarAndContents, { ContentPane } from 'components/common/sidebar-and-contents';
import { UserContext } from 'contexts/user-context';
import { ScaReviewPeriodFilterContext } from 'components/sca/contexts/sca-review-period-filter-context';
import CheckRole from 'components/common/check-role';
import ScaManageViewIdFilters from 'components/sca/manage/sca-manage-view-id-filters';
import ScaClient, { IReviewPeriod, IScaRecord, IScaExecOrgResponse } from 'clients/sca-client';
import { Dictionary } from 'assets/constants/global-constants';
import { largeAtTheCenter } from 'assets/styles/global-styles';
import { generalIsMountedCode, useUrlSearchParams } from 'utils/misc-utils';
import { getScaMyOrgColumns } from 'components/sca/my-org/get-sca-my-org-columns';
import { Table } from 'components/common/table';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';
import {
    OtherRate,
    MyOrgUrlParams,
    IScaExecOrgData,
    HistoryPageTitle,
} from 'components/sca/sca-constants';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { Stack } from '@fluentui/react';
import { ScaMyOrgUrl, scaHistoryBreadcrumbs } from 'components/sca/sca-breadcrumbs';
import { Redirect } from 'react-router-dom';
import ScaReviewsStatBarChart from 'components/sca/sca-common/sca-review-stat-bar-chart';
import { Role } from 'configs/roles';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import HorizontalBar from 'components/common/horizontal-bar';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { fetchAllReviewPeriods } from 'components/sca/sca-utils';

export default function ScaViewMyOrg(): JSX.Element {
    const breadCrumbContext = useContext(BreadCrumbContext);

    const [reviewId, setReviewId] = useState<string>();
    const [execId, setExecId] = useState<string>();

    const filters = useContext(ScaReviewPeriodFilterContext);
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [allReviewsDict, setAllReviewsDict] = useState<
        Dictionary<Dictionary<IScaExecOrgResponse>>
    >({});
    const [reviewPeriodsDict, setReviewPeriodsDict] = useState<Dictionary<IReviewPeriod>>({});
    const [selectedPeriod, setSelectedPeriod] = useState<IScaExecOrgResponse>();
    const [loadingErrorMsg, setLoadingErrorMsg] = useState<string>('');

    const [selectedRecords, setSelectedRecords] = useState<IScaExecOrgData[]>([]);
    const [tableRows, setTableRows] = useState<IScaExecOrgData[]>([]);
    const [tablerowAliases, setTablerowAliases] = useState<string[]>([]);
    const [redirectTo, setRedirectTo] = useState<string>();

    const {
        [MyOrgUrlParams.ReviewId]: reviewIdFromUrl,
        [MyOrgUrlParams.ExecId]: execIdFromUrl,
    } = useUrlSearchParams([MyOrgUrlParams.ReviewId, MyOrgUrlParams.ExecId]);

    useEffect(() => {
        // SCA Auth
        if (execIdFromUrl[0]) {
            setExecId(execIdFromUrl[0]);
        } else {
            setExecId(userContext.employeeRecord.id);
        }
    }, [execIdFromUrl, userContext.employeeRecord]);

    useEffect(() => {
        if (filters.execId && filters.execId !== execId) setExecId(filters.execId);
    }, [filters.execId]);

    useEffect(() => {
        if (
            reviewIdFromUrl[0] &&
            reviewIdFromUrl[0]?.toLocaleLowerCase() !== reviewId?.toLocaleLowerCase()
        ) {
            setReviewId(reviewIdFromUrl[0]);
            filters.setFilter('period', filters.periodFilterSelection(reviewIdFromUrl[0]));
        }
    }, [reviewIdFromUrl, reviewId]);

    useEffect(() => {
        if (
            filters.period?.key &&
            (filters.period.key as string).toLocaleLowerCase() !== reviewId?.toLocaleLowerCase()
        ) {
            // The following typecast is safe. It prevents filters.period.key
            // to be considered as a "number".
            setReviewId(filters.period.key as string);
        }
    }, [reviewId, filters.period]);

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs(scaHistoryBreadcrumbs(HistoryPageTitle.MyOrg, reviewId));
    }, [reviewId]);

    useEffect(() => {
        setRedirectTo(ScaMyOrgUrl({ reviewId, execId }));
    }, [reviewId, execId]);

    useEffect(() => {
        filters.setFilter('execId', execId);
    }, [execId]);

    // Clear "redirectTo" otherwise you'll never see the end of it.
    useEffect(() => {
        setRedirectTo('');
    }, [redirectTo]);

    const addReviews = (reviewId: string, reviews: IScaExecOrgResponse): void => {
        const currentExecReviews = allReviewsDict[execId as string];

        setAllReviewsDict({
            ...allReviewsDict,
            [execId as string]: {
                ...currentExecReviews,
                [reviewId]: reviews,
            },
        });
    };

    const fetchReviewPeriods = async (isMountedFunc: () => boolean): Promise<void> => {
        const reviewPeriodsVar = await fetchAllReviewPeriods(authContext);
        if (isMountedFunc()) {
            const reviewPeriodsDictVar: Dictionary<IReviewPeriod> = {};
            reviewPeriodsVar.forEach((reviewPeriod) => {
                reviewPeriodsDictVar[reviewPeriod.id] = reviewPeriod;
            });
            setReviewPeriodsDict(reviewPeriodsDictVar);
        }
    };

    useEffect(() => {
        return generalIsMountedCode(fetchReviewPeriods);
    }, []);

    const fetchExecOrgPromise = async (
        execId: string,
        reviewId: string,
    ): Promise<IScaExecOrgResponse | undefined> => {
        // Only fetch the selected reviews if not already fetched
        if ((allReviewsDict[execId] ?? {})[reviewId] !== undefined) {
            return allReviewsDict[execId][reviewId];
        }
        try {
            return await ScaClient.getScaEmployeeRecordsForExec(authContext, execId, reviewId);
        } catch (e) {
            if (e.status === 404) {
                setLoadingErrorMsg(
                    `There is no review record for the specified review period or exec`,
                );
            } else {
                throw e;
            }
        }
    };

    const fetchManagerOrg = async (isMountedFunc: () => boolean): Promise<void> => {
        if (reviewId === undefined || execId === undefined) {
            return;
        }
        try {
            setIsLoading(true);
            setLoadingErrorMsg('');
            const reviewsVar = await fetchExecOrgPromise(execId, reviewId);
            if (isMountedFunc() && reviewsVar) {
                addReviews(reviewId, reviewsVar);
            }
        } catch (e) {
            console.error(e);
            setLoadingErrorMsg(`Error loading org data for the specified exec and review period`);
        } finally {
            if (isMountedFunc()) {
                setIsLoading(false);
                setIsInitialLoad(false);
            }
        }
    };

    useEffect(() => {
        return generalIsMountedCode(fetchManagerOrg);
    }, [authContext, execId, reviewId]);

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (
            execId &&
            reviewId &&
            allReviewsDict[execId] &&
            allReviewsDict[execId as string][reviewId]
        ) {
            setSelectedPeriod(allReviewsDict[execId as string][reviewId]);
        }
    }, [isLoading, reviewId, allReviewsDict]);

    function mergeReviewRecords(): IScaExecOrgData[] {
        const result: IScaExecOrgData[] = [];
        selectedPeriod?.managerGroups?.forEach(({ directReports, managerHierarchy }) => {
            const manager = managerHierarchy[managerHierarchy.length - 1];
            directReports.forEach((directReport) => {
                result.push({
                    ...directReport,
                    reportsTo: {
                        id: manager.managerId,
                        name: manager.managerName,
                        alias: manager.managerAlias,
                    },
                });
            });
        });
        return result;
    }

    const filterFunc = (r: IScaExecOrgData): boolean => {
        const checkStatus = (): boolean => {
            const hasSomeReviewStatusFilter = Object.values(filters.status ?? {}).some((v) => v);
            if (hasSomeReviewStatusFilter) {
                // User has set some status filter.
                if ((filters?.status ?? {})[r.reviewState]) {
                    return true; // This review record has a status that user has set.
                } else {
                    return false;
                }
            } else {
                return true; // User has set no status filter.
            }
        };
        if (!checkStatus()) {
            return false;
        }
        if (filters.employee) {
            if (filters.employee?.itemPropObj?.id !== r.personnelId) {
                return false;
            }
        }
        if (filters.reportsTo) {
            if (filters.reportsTo?.itemPropObj?.id !== r.reportsTo.id) {
                return false;
            }
        }
        if (filters.reviewer) {
            if (
                filters.reviewer?.itemPropObj?.alias.toLocaleLowerCase() !==
                r.reviewedBy?.replace(/@.*/, '').toLocaleLowerCase()
            ) {
                return false;
            }
        }
        if (filters.reviewedOn) {
            const reviewDate = new Date(r.reviewedTimestampUTC * 1000).toDateString();
            if (reviewDate !== filters.reviewedOn.toDateString()) {
                return false;
            }
        }
        if (filters.selectedRate) {
            if (filters.selectedRate.key === OtherRate) {
                if (r.rate !== parseFloat((filters.rate ?? '').toString())) {
                    return false;
                }
            } else if (parseFloat(filters.selectedRate.key.toString()) !== r.rate) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        const selectedRecordsVar = mergeReviewRecords();
        const tableRowsVar = reviewId ? selectedRecordsVar ?? [] : [];
        setTableRows(tableRowsVar.filter(filterFunc));
        setSelectedRecords(selectedRecordsVar);
        setTablerowAliases(
            // Collect alias of employees, "reviewed by", and "reports to".
            selectedRecordsVar
                .map((curRow) => curRow.alias)
                .concat(selectedRecordsVar.map((curRow) => curRow.reportsTo.alias))
                .concat(selectedRecordsVar.map((curRow) => curRow.reviewCommentBy)),
        );
    }, [selectedPeriod, filters, reviewId]);

    type ExcelRecordType = {
        employee: string;
        status: string;
        reportsTo: string;
        reviewedBy: string;
        previousRate: string;
        currentRate: string;
        reviewedOn: string;
        comment: string;
    };

    const mapRecordsToExcel = async (records: IScaExecOrgData[]): Promise<ExcelRecordType[]> => {
        return records.map((record) => {
            const reviewedOn = record.reviewedTimestampUTC
                ? new Date(record.reviewedTimestampUTC * 1000).toLocaleDateString()
                : '';
            return {
                employee: record.name,
                status: record.reviewState,
                reportsTo: `${record.reportsTo.name} (${record.reportsTo.alias})`,
                reviewedBy: record.reviewedBy,
                previousRate: record.previousRate.toString(),
                currentRate: record.rate.toString(),
                reviewedOn: reviewedOn,
                comment: record.reviewComment,
            };
        });
    };

    const tableColumns = getScaMyOrgColumns({
        reviewPeriod: reviewId ? reviewPeriodsDict[reviewId] : undefined,
    });

    if (redirectTo) {
        return <Redirect push={true} to={redirectTo} />;
    } else {
        return (
            <CheckRole
                // SCA Auth
                requiredRolesAny={[Role.SCAExec]}>
                <SidebarAndContents>
                    {/**
                     * Here the code is not using <SidebarPane> because
                     * <ScaManageViewIdFilters> needs two panes and it
                     * will, instead, use two instances of <SidebarPane>
                     * to create them.
                     */}
                    <ScaManageViewIdFilters
                        showRate={true}
                        reviewId={reviewIdFromUrl[0]}
                        aliases={tablerowAliases}
                        reviewPeriod={reviewId ? reviewPeriodsDict[reviewId] : undefined}
                        showReportsTo={true}
                        showReviewedBy={true}
                        showReviewedOn={true}
                        showClearFilters={true}
                    />
                    <ContentPane>
                        <ProblemLoadingMsg problemLoadingMsg={loadingErrorMsg}>
                            {isInitialLoad ||
                            isLoading ||
                            tableRows.length > 0 ||
                            selectedRecords.length > 0 ||
                            !reviewId ? (
                                <>
                                    <ScaReviewsStatBarChart tableRows={tableRows} />
                                    <HorizontalBar>
                                        {/* Large grow factor to occupy "almost" the entire line and
                                            this way push the next item to the right side of the line */}
                                        <Stack.Item grow={100}>
                                            <ClearFiltersActionButton
                                                clearFunc={filters.resetAll}
                                            />
                                        </Stack.Item>
                                        {/* Small grow factor to occupy a small portion of the line
                                            so it goes flush at the right side of the line */}
                                        <Stack.Item grow={0.001}>
                                            <ExportToExcelButton<ExcelRecordType>
                                                getData={() => mapRecordsToExcel(tableRows)}
                                                fileNamePrefix={reviewId ?? ''}
                                                formatHeader={true}
                                            />
                                        </Stack.Item>
                                    </HorizontalBar>

                                    <Table<IScaRecord>
                                        tableColumns={tableColumns}
                                        isFetchingData={isLoading}
                                        shimmerLabel='Loading review data'
                                        rows={tableRows}
                                        tableName='Employee Reviews'
                                    />
                                </>
                            ) : (
                                <div className={largeAtTheCenter}>No review record was found</div>
                            )}
                        </ProblemLoadingMsg>
                    </ContentPane>
                </SidebarAndContents>
            </CheckRole>
        );
    }
}
