import React, { useEffect, useContext } from 'react';
import { CustomBreadcrumb, ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import { BreadCrumbContext, BreadCrumbHelper } from 'contexts/breadcrumb-context';
import { ScreeningPaths, ScreeningPathsTitles } from 'components/screening/common/common-constants';
import ScreeningContentFlag from 'components/screening/common/screening-document-feature-flag/screening-content-flag';
import { useFeatureFlag, FeatureFlagKeys } from 'utils/use-feature-flags';
import ScreeningDetails from 'components/screening/common/manage-candidate/screening-details';

export interface ManageCandidatePageCommonProps {
    match: {
        params: {
            id: string;
        };
    };
    screeningPaths: ScreeningPaths;
    breadCrumbs?: ICustomBreadcrumbItem[];
}

export default function ManageCandidatePageCommon(
    props: ManageCandidatePageCommonProps,
): JSX.Element {
    const isDocumentStorageFlag = useFeatureFlag(FeatureFlagKeys.documentStorage).enabled;
    const defaultBreadCrumbItems: ICustomBreadcrumbItem[] = [
        {
            title: 'Screening',
            link: '',
        },
        {
            title:
                props.screeningPaths === ScreeningPaths.PublicTrust
                    ? ScreeningPathsTitles.PublicTrust
                    : ScreeningPathsTitles.UsGov,
            link: '',
        },
        {
            title: 'View Screening',
            link: '',
        },
    ];
    const path = props.screeningPaths === ScreeningPaths.PublicTrust ? 'public-trust' : 'us-gov';
    const breadCrumbContext = useContext(BreadCrumbContext);
    // if we're going to the manage candidate page from a page redirect e.g. "manage", "my-screening", etc.  then we use
    // the previously page title's breadcrumb as the cachekey to update the local cached copies of the screening records
    // if the candidate's screening record had been updated on the manage candidate page
    const cacheKey =
        breadCrumbContext.breadCrumbs.length >= 2
            ? breadCrumbContext.breadCrumbs[breadCrumbContext.breadCrumbs.length - 2].link
            : '';
    useEffect(() => {
        const id = props.match.params.id;
        BreadCrumbHelper.appendOrAddDefaults(
            breadCrumbContext,
            {
                title: id,
                link: `/screening/${path}/${id}`,
            },
            defaultBreadCrumbItems,
        );
    }, [props.match.params.id]);

    return (
        <>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            {isDocumentStorageFlag ? (
                <ScreeningDetails
                    screeningId={props.match.params.id}
                    cacheKey={cacheKey}
                    screeningPath={props.screeningPaths}
                />
            ) : (
                <ScreeningContentFlag
                    screeningId={props.match.params.id}
                    cacheKey={cacheKey}
                    screeningPath={props.screeningPaths}
                />
            )}
        </>
    );
}
