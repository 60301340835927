import React, { useContext } from 'react';
import { AccessDeniedURL } from 'assets/constants/global-constants';
import CheckRole from 'components/common/check-role';
import ScaReviewPeriodFiltersProvider from 'components/sca/contexts/sca-review-period-filter-context';
import ScaViewMyTeam from 'components/sca/my-team/sca-view-my-team';
import { UserContext } from 'contexts/user-context';

export default function ScaViewMyTeamFilterWrapper(): JSX.Element {
    const userContext = useContext(UserContext);
    return (
        // SCA Auth
        <CheckRole
            requiredRolesAny={[]}
            redirectNotInRole={AccessDeniedURL}
            hasRequiredRolesAny={[userContext.isScaManager]}
            arePermissionsChecked={userContext.isScaStatusChecked}>
            <ScaReviewPeriodFiltersProvider>
                <ScaViewMyTeam />
            </ScaReviewPeriodFiltersProvider>
        </CheckRole>
    );
}
