import React, { useState, useContext, useMemo, useEffect, useCallback } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { FedNetGroupOptionKey } from 'components/groups/manage-group/settings/link-security-group/group-selection-table';
import {
    mergeStyles,
    Spinner,
    Icon,
    IColumn,
    MessageBar,
    MessageBarType,
    Stack,
    PrimaryButton,
    mergeStyleSets,
} from '@fluentui/react';
import { Table, TableCell } from 'components/common/table';
import GroupClient, {
    SecurityGroupTableType,
    ISecurityGroup,
    SecurityGroupDomain,
    IGroupSecurityGroup,
    IGroup,
} from 'clients/group-client';
import GraphClient, { IGraphServicePrincipal } from 'clients/graph-client';
import { IconNames } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import environment from 'environments/environment';
import { BadgeColorHex } from 'assets/constants/global-colors';
import { useIsMounted } from 'utils/misc-hooks';
import { SetStateFunc } from 'types/global-types';
import Spacer from 'components/common/spacer';
import { convertSecurityGroupTableType } from 'components/groups/manage-group/settings/link-security-group/link-to-security-group-utils';
import { getAppInsights } from 'utils/telemetry-utils';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

enum ValidationStatus {
    needed,
    checking,
    passed,
    failed,
}

type ValidationStatusDataType = {
    validationStatus: ValidationStatus;
    errMsg?: string | undefined;
};

function validationInitStatus(): ValidationStatusDataType {
    return {
        validationStatus: ValidationStatus.needed,
        errMsg: '',
    };
}

interface IGroupValidationTableProps {
    selectedGroupOptionKey: string;
    selectedGroupId: string | undefined;
    securityGroups: ISecurityGroup[];
    selectedSecurityGroup: ISecurityGroup | undefined;
    setSelectedSecurityGroup: SetStateFunc<ISecurityGroup | undefined>;
    setIsOkToLink: SetStateFunc<boolean>;
}

export default function GroupValidationTable(props: IGroupValidationTableProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);

    const isMounted = useIsMounted();

    const isServicePrincpalLinkingEnabled = useFeatureFlag(
        FeatureFlagKeys.groupsLinkWithServicePrincipal,
    ).enabled;

    const [existingGroupValidationData, setExistingGroupValidationData] = useState<ValidationStatusDataType>(validationInitStatus()); // prettier-ignore
    const [ownerCheckValidationData, setOwnerCheckValidationData] = useState<ValidationStatusDataType>(validationInitStatus()); // prettier-ignore

    const [groupsServiceServicePrincipal, setGroupsServiceServicePrincipal] = useState<
        IGraphServicePrincipal
    >();

    const {
        selectedGroupOptionKey,
        selectedGroupId,
        securityGroups,
        selectedSecurityGroup,
        setSelectedSecurityGroup,
        setIsOkToLink,
    } = props;

    const shouldCheckExisting = !!selectedGroupOptionKey;
    const shouldCheckOwner =
        !!selectedGroupOptionKey && selectedGroupOptionKey !== FedNetGroupOptionKey;

    useEffect(() => {
        (async (): Promise<void> => {
            if (isServicePrincpalLinkingEnabled) {
                try {
                    const servicePrincipal = await GraphClient.getServicePrincipal(
                        authContext,
                        environment.groupServiceConfig.serviceIdentityAppId,
                    );
                    setGroupsServiceServicePrincipal(servicePrincipal);
                } catch (e) {
                    getAppInsights()?.trackException({
                        exception: e,
                        severityLevel: SeverityLevel.Error,
                    });
                }
            }
        })();
    }, [authContext, isServicePrincpalLinkingEnabled]);

    const clearValidationStatus = useCallback((): void => {
        setExistingGroupValidationData(validationInitStatus());
        setOwnerCheckValidationData(validationInitStatus());
    }, []);

    useEffect(() => {
        clearValidationStatus();
        setSelectedSecurityGroup(
            selectedGroupOptionKey === FedNetGroupOptionKey
                ? {
                      id: selectedGroupId as string,
                      name: 'FedNet Group',
                      type: SecurityGroupTableType.MICROSOFTFEDERAL,
                      mail: '',
                      options: [],
                      domain: SecurityGroupDomain.UNDEFINED,
                  }
                : securityGroups.find((group) => group.id === selectedGroupId),
        );
    }, [
        selectedGroupOptionKey,
        securityGroups,
        selectedGroupId,
        clearValidationStatus,
        setSelectedSecurityGroup,
    ]);

    const existingGroupValidation = useCallback(async (): Promise<void> => {
        if (
            !selectedSecurityGroup ||
            !selectedGroupId ||
            !shouldCheckExisting ||
            existingGroupValidationData.validationStatus !== ValidationStatus.needed
        ) {
            return;
        }

        // Don't rely on groupContext for value of linkedSecurityGroups.
        // Rather, refetch them to make sure it's getting the latest,
        // current values from the backend. This behavior works well with
        // the meaning of the button "Check Again".
        let refetchedLinkedSecurityGroups: IGroupSecurityGroup[] = [];
        try {
            refetchedLinkedSecurityGroups = await GroupClient.getLinkedSecurityGroups(
                authContext,
                // the following typecast is safe because groupContext.group
                // is checked before calling this function.
                (groupContext.group as IGroup).id,
            );
        } catch (error) {
            if (isMounted()) {
                setExistingGroupValidationData({
                    validationStatus: ValidationStatus.failed,
                    errMsg: `Error refetching list of linked security groups`,
                });
                return;
            }
        }

        const groupType = convertSecurityGroupTableType(selectedSecurityGroup.type)?.toUpperCase();

        if (
            refetchedLinkedSecurityGroups?.some(
                (group) => group?.securityGroupType.toUpperCase() === groupType,
            )
        ) {
            setExistingGroupValidationData({
                validationStatus: ValidationStatus.failed,
                errMsg: `Security group type is already linked to this Personnel Group.`,
            });
        } else {
            setExistingGroupValidationData((currentValue) =>
                Object.assign(currentValue, { validationStatus: ValidationStatus.checking }),
            );
            try {
                const hasPassed = await GroupClient.checkSecurityGroupLink(
                    authContext,
                    selectedGroupId,
                );
                if (!isMounted()) {
                    return;
                }
                if (hasPassed === false) {
                    setExistingGroupValidationData({
                        validationStatus: ValidationStatus.passed,
                    });
                } else {
                    setExistingGroupValidationData({
                        validationStatus: ValidationStatus.failed,
                        errMsg: 'Security group is already linked to another Personnel Group.',
                    });
                }
            } catch {
                if (isMounted()) {
                    setExistingGroupValidationData({
                        validationStatus: ValidationStatus.failed,
                        errMsg: 'There was an issue checking security group link. Please try again later.' // prettier-ignore
                    });
                } else {
                    console.error(
                        'There was an issue checking security group link. Please try again later.',
                    );
                }
            }
        }
    }, [
        selectedSecurityGroup,
        selectedGroupId,
        shouldCheckExisting,
        existingGroupValidationData.validationStatus,
        authContext,
        groupContext.group,
        isMounted,
    ]);

    useEffect(() => {
        existingGroupValidation();
    }, [selectedSecurityGroup, selectedGroupId, existingGroupValidation]);

    const ownerCheckValidationForCoreIdentity = useCallback(async (): Promise<void> => {
        if (!selectedSecurityGroup) {
            return;
        }
        if (ownerCheckValidationData.validationStatus !== ValidationStatus.needed) {
            return;
        }
        try {
            setOwnerCheckValidationData((currentValue) =>
                Object.assign(currentValue, { validationStatus: ValidationStatus.checking }),
            );
            const hasPassed = await GroupClient.verifyGroupIsOwnedByManagementApp(
                authContext,
                selectedSecurityGroup.domain,
                selectedSecurityGroup.name,
            );
            if (!isMounted()) {
                return;
            }
            if (hasPassed) {
                setOwnerCheckValidationData({
                    validationStatus: ValidationStatus.passed,
                });
            } else {
                setOwnerCheckValidationData({
                    validationStatus: ValidationStatus.failed,
                    errMsg: `Please add this application as an owner manually through the CoreIdentity Portal.`,
                });
            }
        } catch {
            if (isMounted()) {
                setOwnerCheckValidationData({
                    validationStatus: ValidationStatus.failed,
                    errMsg: 'Error validating Security Group',
                });
            } else {
                console.error('Error validating Security Group');
            }
        }
    }, [authContext, isMounted, selectedSecurityGroup]);

    const ownerCheckValidationForGraph = useCallback(async (): Promise<void> => {
        if (!selectedSecurityGroup) {
            return;
        }
        if (ownerCheckValidationData.validationStatus !== ValidationStatus.needed) {
            return;
        }
        try {
            if (isServicePrincpalLinkingEnabled && groupsServiceServicePrincipal === undefined) {
                if (isMounted()) {
                    setOwnerCheckValidationData({
                        validationStatus: ValidationStatus.needed,
                        errMsg: 'Retrieving owning service principal required for linking.',
                    });
                }

                return;
            }

            setOwnerCheckValidationData((currentValue) =>
                Object.assign(currentValue, { validationStatus: ValidationStatus.checking }),
            );

            const hasPassed = await GraphClient.checkOwnerExists(
                authContext,
                selectedSecurityGroup.id,
                isServicePrincpalLinkingEnabled ? groupsServiceServicePrincipal?.id : undefined,
            );
            if (!isMounted()) {
                return;
            }
            setOwnerCheckValidationData({
                validationStatus: hasPassed ? ValidationStatus.passed : ValidationStatus.failed,
            });
        } catch (error) {
            let errMsgVar: string;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((error as any).status === 404) {
                errMsgVar = `Please add this user as an owner manually through Azure Portal.`;
            } else {
                errMsgVar = 'Error validating Security Group';
            }
            if (isMounted()) {
                setOwnerCheckValidationData({
                    validationStatus: ValidationStatus.failed,
                    errMsg: errMsgVar,
                });
            } else {
                console.error(errMsgVar);
            }
        }
    }, [
        authContext,
        groupsServiceServicePrincipal,
        isMounted,
        isServicePrincpalLinkingEnabled,
        selectedSecurityGroup,
    ]);

    const ownerCheckValidation = useCallback(async (): Promise<void> => {
        if (!selectedSecurityGroup || !selectedGroupId || !shouldCheckOwner) {
            return;
        }

        if (selectedSecurityGroup.type === SecurityGroupTableType.CoreIdentity) {
            ownerCheckValidationForCoreIdentity();
        } else {
            ownerCheckValidationForGraph();
        }
    }, [
        shouldCheckOwner,
        ownerCheckValidationForCoreIdentity,
        ownerCheckValidationForGraph,
        selectedGroupId,
        selectedSecurityGroup,
    ]);

    useEffect(() => {
        ownerCheckValidation();
    }, [
        selectedSecurityGroup,
        selectedGroupId,
        groupsServiceServicePrincipal,
        ownerCheckValidation,
    ]);

    const checkAgain = useCallback((): void => {
        clearValidationStatus();
        ownerCheckValidation();
        existingGroupValidation();
    }, [clearValidationStatus, existingGroupValidation, ownerCheckValidation]);

    const isCheckExistingOkToLink =
        !shouldCheckExisting ||
        existingGroupValidationData.validationStatus === ValidationStatus.passed;
    const isCheckOwnerOkToLink =
        !shouldCheckOwner || ownerCheckValidationData.validationStatus === ValidationStatus.passed;

    const isOkToLink = isCheckExistingOkToLink && isCheckOwnerOkToLink;

    useEffect(() => {
        setIsOkToLink(isOkToLink);
    }, [isOkToLink, setIsOkToLink]);

    const statusCheckTableRows = useMemo(() => {
        const tableRowsVar: JSX.Element[] = [];

        if (shouldCheckExisting) {
            tableRowsVar.push(
                displayRow({
                    key: `existing-${selectedGroupId}`,
                    groupInfoLeft:
                        selectedGroupOptionKey === FedNetGroupOptionKey
                            ? 'The FedNet Group'
                            : selectedSecurityGroup?.name,
                    groupInfoMiddle: ' is available for syncing with ',
                    groupInfoRight: groupContext.group?.name,
                    validationData: existingGroupValidationData,
                }),
            );
        }
        if (shouldCheckOwner) {
            tableRowsVar.push(
                displayRow({
                    key: `owner-${selectedGroupId}`,
                    groupInfoLeft:
                        selectedSecurityGroup?.type === SecurityGroupTableType.CoreIdentity
                            ? environment.groupServiceConfig.coreIdentityManagementAppId
                            : isServicePrincpalLinkingEnabled
                            ? groupsServiceServicePrincipal?.displayName
                            : environment.emailAddress.groupSync,
                    groupInfoMiddle: ' is a co-owner of ',
                    groupInfoRight: selectedSecurityGroup?.name,
                    validationData: ownerCheckValidationData,
                }),
            );
        }
        return tableRowsVar;
    }, [
        shouldCheckExisting,
        shouldCheckOwner,
        selectedGroupId,
        selectedGroupOptionKey,
        selectedSecurityGroup?.name,
        selectedSecurityGroup?.type,
        groupContext.group?.name,
        existingGroupValidationData,
        isServicePrincpalLinkingEnabled,
        groupsServiceServicePrincipal?.displayName,
        ownerCheckValidationData,
    ]);

    const columns: IColumn[] = useMemo(() => {
        return [
            {
                key: 'rule check',
                name: 'rule check',
                ariaLabel: 'rule check',
                minWidth: 400,
                maxWidth: 400,
                onRender: (row: JSX.Element): JSX.Element => {
                    return <TableCell>{row}</TableCell>;
                },
            },
        ];
    }, []);

    return (
        <Stack>
            <Stack.Item>
                <Table
                    tableColumns={columns}
                    isFetchingData={false}
                    isHeaderVisible={false}
                    rows={statusCheckTableRows ?? []}
                    tableName='Validation Results'
                />
            </Stack.Item>
            {(existingGroupValidationData.validationStatus === ValidationStatus.failed ||
                ownerCheckValidationData.validationStatus === ValidationStatus.failed) && (
                <Stack.Item className={styles.checkAgainButton}>
                    <Spacer marginTop={20} />
                    <PrimaryButton onClick={checkAgain}>Check Again</PrimaryButton>
                </Stack.Item>
            )}
        </Stack>
    );
}

/// Decoupling the following functions to reduce the clutter in the component.

interface IDisplayRowParams {
    key: string;
    groupInfoLeft: string | undefined;
    groupInfoMiddle: string | undefined;
    groupInfoRight: string | undefined;
    validationData: ValidationStatusDataType;
}

function displayRow(params: IDisplayRowParams): JSX.Element {
    const leftTextStyle = mergeStyles(globalStyles.boldFont, globalStyles.TabHeaderPadding);
    return (
        <Stack key={params.key}>
            <Stack.Item>
                <Stack horizontal style={{ width: '200px' }} horizontalAlign='space-between'>
                    <Stack.Item>{renderStatus(params.validationData.validationStatus)}</Stack.Item>
                    <Stack.Item>
                        <span className={leftTextStyle}>{params.groupInfoLeft}</span>
                        <span>{params.groupInfoMiddle}</span>
                        <span className={globalStyles.boldFont}>{params.groupInfoRight}</span>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                {params.validationData.validationStatus === ValidationStatus.failed && (
                    <Stack.Item>
                        <MessageBar messageBarType={MessageBarType.error}>
                            {params.validationData.errMsg}
                        </MessageBar>
                    </Stack.Item>
                )}
            </Stack.Item>
        </Stack>
    );
}

function renderStatus(status: ValidationStatus): JSX.Element {
    const iconStyle = (color?: BadgeColorHex): string => {
        return mergeStyles(globalStyles.largeFont, !!color ? { 'color': color } : {});
    };
    switch (status) {
        case ValidationStatus.passed:
            return (
                <div>
                    <Icon
                        className={iconStyle(BadgeColorHex.GREEN)}
                        iconName={IconNames.CheckMark}
                    />
                </div>
            );
        case ValidationStatus.failed:
            return (
                <div>
                    <Icon className={iconStyle(BadgeColorHex.RED)} iconName={IconNames.Error} />
                </div>
            );
        case ValidationStatus.needed:
        case ValidationStatus.checking:
            return (
                <div className={iconStyle()}>
                    <Spinner />
                </div>
            );
        default:
            return <></>;
    }
}

const styles = mergeStyleSets({
    checkAgainButton: {
        alignSelf: 'flex-end',
        marginRight: 20,
    },
});
