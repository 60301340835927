/* eslint-disable @typescript-eslint/naming-convention */
import { IDropdownOption } from '@fluentui/react';
import React from 'react';
import { BadgeColorHex } from 'assets/constants/global-colors';
import { Dictionary } from 'assets/constants/global-constants';
import { IClearanceRecord, IPolygraphRecord, ISpecialAccessRecord } from 'clients/clearance-client';
import EllipsisText from 'components/common/ellipsis-text';
import { nameof } from 'utils/object-utils';
import { charPerPixelConversion } from 'components/personnel-profile/common/common-constants';
import { removeAllParenthesisFromString } from 'utils/string-utils';

export type clearanceLevel = { record: string; shortHand: string; longHand: string; order: number };
export type polygraphType = { record: string; shortHand: string; longHand: string; order: number };

interface IClearanceLevels {
    // adding this so that in type script the user can use like ClearanceStatuses[row.level]
    [x: string]: string;
    TS: string;
    INTERIM_TS: string;
    S: string;
    INTERIM_S: string;
    CONFIDENTIAL: string;
    PUBLIC_TRUST: string;
    EOD: string;
    Q: string;
    L: string;
    SAP: string;
}

export const ClearanceLevelsShortHand: IClearanceLevels = {
    TS: 'TS',
    INTERIM_TS: 'INTERIM TS',
    S: 'S',
    INTERIM_S: 'INTERIM S',
    CONFIDENTIAL: 'C',
    PUBLIC_TRUST: 'PUBLIC TRUST',
    EOD: 'EOD',
    Q: 'Q',
    L: 'L',
    SAP: 'SAP',
};

export enum ClearanceLevelBackendStrings {
    TS = 'TS',
    INTERIM_TS = 'INTERIM_TS',
    S = 'S',
    INTERIM_S = 'INTERIM_S',
    CONFIDENTIAL = 'CONFIDENTIAL',
    PUBLIC_TRUST = 'PUBLIC_TRUST',
    EOD = 'EOD',
    Q = 'Q',
    L = 'L',
    SAP = 'SAP',
}

export const AcceptedSecurityFilters: string[] = [
    ClearanceLevelBackendStrings.TS,
    ClearanceLevelBackendStrings.INTERIM_TS,
    ClearanceLevelBackendStrings.S,
    ClearanceLevelBackendStrings.INTERIM_S,
    ClearanceLevelBackendStrings.CONFIDENTIAL,
    ClearanceLevelBackendStrings.PUBLIC_TRUST,
    ClearanceLevelBackendStrings.EOD,
    ClearanceLevelBackendStrings.Q,
    ClearanceLevelBackendStrings.L,
    ClearanceLevelBackendStrings.SAP,
    ClearanceLevelsShortHand.INTERIM_TS,
    ClearanceLevelsShortHand.INTERIM_S,
    ClearanceLevelsShortHand.PUBLIC_TRUST,
].map((item) => item.toLowerCase());

export const ClearanceLevelsLongHand: IClearanceLevels = {
    TS: 'TOP SECRET',
    INTERIM_TS: 'INTERIM TOP SECRET',
    S: 'SECRET',
    INTERIM_S: 'INTERIM SECRET',
    CONFIDENTIAL: 'CONFIDENTIAL',
    PUBLIC_TRUST: 'PUBLIC TRUST',
    EOD: 'ENTER ON DUTY',
    Q: 'Q',
    L: 'L',
    SAP: 'SAP',
};

/**
 * record what is saved at the record level, all caps no-spaces
 * shorthand should be all
 * longhand should be the spelled out name of the clearance level
 **/
const clearanceTable: clearanceLevel[] = [
    {
        record: 'TS',
        shortHand: ClearanceLevelsShortHand.TS,
        longHand: ClearanceLevelsLongHand.TS,
        order: 5,
    },
    {
        record: 'INTERIM_TS',
        shortHand: ClearanceLevelsShortHand.INTERIM_TS,
        longHand: ClearanceLevelsLongHand.INTERIM_TS,
        order: 6,
    },
    {
        record: 'S',
        shortHand: ClearanceLevelsShortHand.S,
        longHand: ClearanceLevelsLongHand.S,
        order: 7,
    },
    {
        record: 'INTERIM_S',
        shortHand: ClearanceLevelsShortHand.INTERIM_S,
        longHand: ClearanceLevelsLongHand.INTERIM_S,
        order: 8,
    },
    {
        record: 'CONFIDENTIAL',
        shortHand: ClearanceLevelsShortHand.CONFIDENTIAL,
        longHand: ClearanceLevelsLongHand.CONFIDENTIAL,
        order: 9,
    },
    {
        record: 'PUBLIC_TRUST',
        shortHand: ClearanceLevelsShortHand.PUBLIC_TRUST,
        longHand: ClearanceLevelsLongHand.PUBLIC_TRUST,
        order: 10,
    },
    {
        record: 'EOD',
        shortHand: ClearanceLevelsShortHand.EOD,
        longHand: ClearanceLevelsLongHand.EOD,
        order: 10,
    },
    {
        record: 'Q',
        shortHand: ClearanceLevelsShortHand.Q,
        longHand: ClearanceLevelsLongHand.Q,
        order: 9,
    },
    {
        record: 'L',
        shortHand: ClearanceLevelsShortHand.L,
        longHand: ClearanceLevelsLongHand.L,
        order: 10,
    },
    {
        record: 'SAP',
        shortHand: ClearanceLevelsShortHand.SAP,
        longHand: ClearanceLevelsLongHand.SAP,
        order: 11,
    },
];

export enum ClearanceTypes {
    Final = 'Final',
    Interim = 'Interim',
    ISA = 'ISA',
    ISSA = 'ISSA',
    SCI = 'SCI',
    JPAS = 'Servicing in JPAS',
    Unclassified = 'Unclassified',
    XOVER = 'XOVER',
    YankeeWhite = 'Yankee White',
}

export enum ContinuousEvaluationTypes {
    Deferred = 'Deferred',
    Unenrolled = 'Unenrolled',
    Other = 'Other',
    Blank = '',
}

export enum ContinuousEvaluationFilterOptions {
    ContinuousEvaluationType = 'CE type',
    ContinuousEvaluationEnrollmentDate = 'CE enrollment date',
    ContinuousEvaluationUnenrollmentDate = 'CE unenrollment date',
}

export function getClearanceTypeFromKey(key: string | undefined): string {
    if (key) {
        const value = ClearanceTypes[key as keyof typeof ClearanceTypes];

        if (value) {
            return value;
        }
        return key;
    }
    return '';
}

export function getContinuousEvaluationTypeFromKey(key: string | undefined): string {
    if (key) {
        const value = ContinuousEvaluationTypes[key as keyof typeof ContinuousEvaluationTypes];

        if (value) {
            return value;
        }
        return key;
    }
    return '';
}

export const AcceptedClearanceTypes: string[] = Object.values(ClearanceTypes).map((x) =>
    x.toLowerCase(),
);

const polygraphTable: polygraphType[] = [
    {
        record: 'FSP',
        shortHand: 'FSP',
        longHand: 'Full-Scope',
        order: 10,
    },
    {
        record: 'CI',
        shortHand: 'CI',
        longHand: 'Counter-Intelligence',
        order: 7,
    },
    {
        record: 'ESS',
        shortHand: 'ESS',
        longHand: 'Expanded Scope Screening',
        order: 10,
    },
];

interface IClearanceStatus {
    // adding this so that in type script the user can use like ClearanceStatuses[row.status]
    [x: string]: string;
    Active: string;
    Inactive: string;
    ApprovedNotBriefed: string;
    Denied: string;
    DowngradedToISA: string;
    LossOfJurisdiction: string;
    Pending: string;
    PendingCSR: string;
    PendingPrInProgress: string;
    RemovedFromProcessing: string;
    UpgradedToISSA: string;
}

export const ClearanceStatuses: IClearanceStatus = {
    Active: 'Active',
    Inactive: 'Inactive',
    ApprovedNotBriefed: 'Approved Not Briefed',
    Denied: 'Denied',
    DowngradedToISA: 'Downgraded to ISA',
    LossOfJurisdiction: 'Loss of Jurisdiction',
    Pending: 'Pending',
    PendingCSR: 'Pending CSR',
    PendingPrInProgress: 'Pending-PR in Progress',
    RemovedFromProcessing: 'Removed from Processing',
    UpgradedToISSA: 'Upgraded to ISSA',
};

export const ClearanceStatusesBackendStrings: IClearanceStatus = {
    Active: 'Active',
    Inactive: 'Inactive',
    ApprovedNotBriefed: 'ApprovedNotBriefed',
    Denied: 'Denied',
    DowngradedToISA: 'DowngradedToISA',
    LossOfJurisdiction: 'LossOfJurisdiction',
    Pending: 'Pending',
    PendingCSR: 'PendingCSR',
    PendingPrInProgress: 'PendingPrInProgress',
    RemovedFromProcessing: 'RemovedFromProcessing',
    UpgradedToISSA: 'UpgradedToISSA',
};

export const ClearanceDebriefString = 'Debriefed on';

export const AcceptedClearanceStatus: string[] = [
    ClearanceStatusesBackendStrings.Active,
    ClearanceStatusesBackendStrings.DowngradedToISA,
    ClearanceStatusesBackendStrings.UpgradedToISSA,
    ClearanceStatusesBackendStrings.Pending,
    ClearanceStatusesBackendStrings.PendingCSR,
    ClearanceStatusesBackendStrings.PendingPrInProgress,
    ClearanceStatusesBackendStrings.ApprovedNotBriefed,
    ClearanceStatusesBackendStrings.Inactive,
    ClearanceStatusesBackendStrings.Denied,
    ClearanceStatusesBackendStrings.LossOfJurisdiction,
    ClearanceStatusesBackendStrings.RemovedFromProcessing,
    ClearanceStatuses.ApprovedNotBriefed,
    ClearanceStatuses.DowngradedToISA,
    ClearanceStatuses.PendingCSR,
    ClearanceStatuses.PendingPrInProgress,
    ClearanceStatuses.RemovedFromProcessing,
    ClearanceStatuses.UpgradedToISSA,
].map((item) => item.toLowerCase());

const clearanceStatusesConsideredActive: string[] = [ClearanceStatuses.Active];

const clearanceTypes: string[] = [
    'Final',
    'Interim',
    'ISA',
    'ISSA',
    'SCI',
    'Servicing in JPAS',
    'Unclassified',
    'XOVER',
    'Yankee White',
];

export const polygraphPassResult = 'pass';
export const polygraphFailResult = 'fail';
const polygraphResults: string[] = [polygraphFailResult, polygraphPassResult];

//Order of this array will determine the sort order in specialAccessComparator
const specialAccessesList: string[] = [
    'SI',
    'TK',
    'G',
    'HCS',
    'KLM',
    'NATO Restricted',
    'NATO Confidential',
    'NATO Secret',
    'NATO Confidential ATOMAL',
    'NATO Secret ATOMAL',
    'ATOMAL C',
    'ATOMAL S',
    'COSMIC Top Secret',
    'Crypto',
    'COMSEC',
    'RD',
    'FRD',
    'CNWDI',
    'ISA PEND SI/TK/G/HCS',
    'ISSA PEND SI/TK/G/HCS',
    'Yankee White',
    'Generic SCI',
];

export const groupedSpecialAccesses: string[] = ['SI', 'TK', 'G', 'HCS', 'KLM'];

const specialAccessBilletTypes: string[] = ['ISA', 'ISSA', 'None'];

// Sorting by order of the specialAccesses array, if a value is unknown it should go
// to the end of the list
export function specialAccessComparator(r1: string | undefined, r2: string | undefined): number {
    if (r1 && r2) {
        let r1Indx = specialAccessesList.indexOf(r1);
        let r2Indx = specialAccessesList.indexOf(r2);
        if (r2Indx < 0) {
            r2Indx = specialAccessesList.length;
        }
        if (r1Indx < 0) {
            r1Indx = specialAccessesList.length;
        }
        return r1Indx - r2Indx;
    }
    if (r1 && !r2) {
        return -1;
    }
    return 1;
}

const specialAccessStatuses: string[] = [
    'Submitted',
    'Granted',
    'Denied',
    'Briefed',
    'Debriefed',
    'Rebriefed',
    'Approved but not Briefed',
    'Revoked',
];
const specialAccessStatusesConsideredActive: string[] = ['Briefed', 'Rebriefed'];

export enum SpecialAccesses {
    SI = 'SI',
    TK = 'TK',
    G = 'G',
    HCS = 'HCS',
    KLM = 'KLM',
    NatoRestricted = 'NATO Restricted',
    NatoConfidential = 'NATO Confidential',
    NatoSecret = 'NATO Secret',
    NatoConfidentialAtomal = 'NATO Confidential ATOMAL',
    NatoSecretAtomal = 'NATO Secret ATOMAL',
    AtomalC = 'ATOMAL C',
    AtomalS = 'ATOMAL S',
    CosmicTopSecret = 'COSMIC Top Secret',
    Crypto = 'Crypto',
    Comsec = 'COMSEC',
    RD = 'RD',
    FRD = 'FRD',
    CNWDI = 'CNWDI',
    IsaPend = 'ISA PEND SI/TK/G/HCS',
    IssaPend = 'ISSA PEND SI/TK/G/HCS',
    YankeeWhite = 'Yankee White',
    GenericSci = 'Generic SCI',
}

export enum agencies {
    AF = 'AF',
    AFCAF = 'AFCAF',
    AFECMO = 'AFECMO',
    AFISRA = 'AFISRA',
    AFNET = 'AFNET',
    AFNIC = 'AFNIC',
    AFOSI = 'AFOSI',
    AFRICOM = 'AFRICOM',
    AirForce = 'AIR FORCE',
    AirForceResearchLabsAFRL = 'AIR FORCE RESEARCH LABS (AFRL)',
    Arizona = 'ARIZONA',
    Arkansas = 'ARKANSAS',
    Army = 'ARMY',
    ArmyCyberCommandARCYBER = 'ARMY CYBER COMMAND (ARCYBER)',
    ArmyCCF = 'ARMYCCF',
    ATF = 'ATF',
    AVC = 'AVC',
    AVCISA = 'AVC - ISA',
    AVCISSA = 'AVC - ISSA',
    California = 'CALIFORNIA',
    CBP = 'CBP',
    CENTCOM = 'CENTCOM',
    CIA = 'CIA',
    CIFA = 'CIFA',
    CIS = 'CIS',
    Customer9 = 'CUSTOMER 9',
    DEA = 'DEA',
    DHS = 'DHS',
    DIA = 'DIA',
    DISA = 'DISA',
    DNI = 'DNI',
    DNIBAEDTE = 'DNI BAE DTE',
    DODCRP = 'DOD CRP',
    DODCAF = 'DODCAF',
    DOE = 'DOE',
    DOJ = 'DOJ',
    DONCAF = 'DONCAF',
    DOS = 'DOS',
    DTRA = 'DTRA',
    EuropeanCommandEUCOM = 'EUROPEAN COMMAND (EUCOM)',
    FBI = 'FBI',
    FEMA = 'FEMA',
    GSA = 'GSA',
    ICE = 'ICE',
    Illinois = 'ILLINOIS',
    INSCOM = 'INSCOM',
    IRS = 'IRS',
    JIATFS = 'JIATFS',
    JIDO = 'JIDO',
    JIEDDO = 'JIEDDO',
    LockheedMartinValleyForge = 'LOCKHEED MARTIN VALLEY FORGE',
    MARCENT = 'MARCENT',
    Marines = 'MARINES',
    MCIA = 'MCIA',
    MPO = 'MPO',
    NASIC = 'NASIC',
    Navy = 'NAVY',
    NCIS = 'NCIS',
    NETCOM = 'NETCOM',
    NewYork = 'NEW YORK',
    NGA = 'NGA',
    NGABAEDTE = 'NGA BAE DTE',
    NIOC = 'NIOC',
    NJVCNGA = 'NJVC (NGA)',
    NRO = 'NRO',
    NROLockheedMartin = 'NRO-LOCKHEED MARTIN',
    NSA = 'NSA',
    NVC = 'NVC',
    ONI = 'ONI',
    Other = 'OTHER',
    Pennsylvania = 'PENNSYLVANIA',
    RCCEurope = 'RCC EUROPE',
    SOCOM = 'SOCOM',
    SSA = 'SSA',
    STRATCOM = 'STRATCOM',
    Texas = 'TEXAS',
    TSA = 'TSA',
    USMarshals = 'US MARSHALS',
    USAF53rdWing = 'USAF-53RD WING',
    USAREUR = 'USAREUR',
    USASOC = 'USASOC',
    USASPO = 'USASPO',
    USCG = 'USCG',
    USDA = 'USDA',
    USMC = 'USMC',
    USSS = 'USSS',
    VA = 'VA',
    VAA = 'VAA',
    VEXCEL = 'VEXCEL',
    VisitRequest = 'VISIT REQUEST',
    WHCA = 'WHCA',
    WHS = 'WHS',
}

export function getModalPixelWidthValueBasedOnTextLength(textLength: number): string {
    return textLength * charPerPixelConversion + 'px';
}

export function findPolygraph(identifier: string | undefined): polygraphType | undefined {
    return polygraphTable.find(
        (x) =>
            (x.record.toLocaleLowerCase() === identifier?.toLocaleLowerCase() ||
                x.shortHand.toLocaleLowerCase() === identifier?.toLocaleLowerCase() ||
                x.longHand.toLocaleLowerCase() === identifier?.toLocaleLowerCase()) === true,
    );
}

export function findClearanceLevel(identifier: string | undefined): clearanceLevel | undefined {
    return clearanceTable.find(
        (x) =>
            (x.record.toLocaleLowerCase() === identifier?.toLocaleLowerCase() ||
                x.shortHand.toLocaleLowerCase() === identifier?.toLocaleLowerCase() ||
                x.longHand.toLocaleLowerCase() === identifier?.toLocaleLowerCase()) === true,
    );
}

export function clearanceDropdownOptions(
    selectedClearance: clearanceLevel | undefined,
): IDropdownOption[] {
    return clearanceTable.map((x) => {
        return {
            key: x.record,
            text: x.longHand,
            selected: selectedClearance && (selectedClearance.record === x.record) === true,
        } as IDropdownOption;
    });
}

export function createClearanceDropdownOptions(
    clearances: IClearanceRecord[] | undefined,
    selectedClearanceId?: string | undefined,
): IDropdownOption[] {
    if (clearances) {
        return clearances.map((x) => {
            return {
                key: x.id,
                text: getClearanceText(x),
                selected: x.id === selectedClearanceId,
            } as IDropdownOption;
        });
    }
    return [];
}

export function clearanceStatusSpan(
    clearance: IClearanceRecord | undefined,
    textEllipsisLength: number | undefined = 8,
): JSX.Element {
    if (clearance && clearance.status) {
        let backgroundColor = BadgeColorHex.RED;
        if (isClearanceActive(clearance)) {
            backgroundColor = BadgeColorHex.GREEN;
        }
        const text = getClearanceStatusesEnumValueFromKey(clearance.status);
        return (
            <span
                style={{
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    backgroundColor: backgroundColor,
                    padding: '2px 7px',
                }}>
                {<EllipsisText text={text} textLengthBeforeEllipsis={textEllipsisLength} />}
            </span>
        );
    }
    return <></>;
}

export function clearanceStringStatusSpan(clearance: IClearanceRecord | undefined): JSX.Element {
    if (clearance) {
        const backgroundColor = isClearanceActive(clearance)
            ? BadgeColorHex.GREEN
            : BadgeColorHex.RED;
        const text = `${getClearanceLevelAndAgencyTitle(
            clearance,
        )} ${getClearanceStatusesEnumValueFromKey(clearance.status)}`;
        return (
            <span
                style={{
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    backgroundColor: backgroundColor,
                    padding: '2px 7px',
                }}>
                {<EllipsisText text={text} textLengthBeforeEllipsis={15} />}
            </span>
        );
    }
    return <></>;
}

export function specialAccessStatusSpan(
    specialAccess: ISpecialAccessRecord | undefined,
): JSX.Element {
    if (specialAccess && specialAccess.status) {
        const backgroundColor = isSpecialAccessActive(specialAccess)
            ? BadgeColorHex.GREEN
            : BadgeColorHex.RED;
        return (
            <span
                style={{
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    backgroundColor: backgroundColor,
                    padding: '2px 7px',
                }}>
                {
                    <EllipsisText
                        text={specialAccess.status.toLocaleUpperCase()}
                        textLengthBeforeEllipsis={9}
                    />
                }
            </span>
        );
    }
    return <></>;
}

export function polygraphResultSpan(polygraph: IPolygraphRecord | undefined): JSX.Element {
    if (polygraph && polygraph.results) {
        let text = polygraphFailResult;
        let backgroundColor = BadgeColorHex.RED;
        if (polygraph.results.toLocaleLowerCase() === polygraphPassResult) {
            text = polygraphPassResult;
            backgroundColor = BadgeColorHex.GREEN;
        }
        return (
            <span
                style={{
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    backgroundColor: backgroundColor,
                    padding: '2px 7px',
                }}>
                {text.toLocaleUpperCase()}
            </span>
        );
    }
    return <></>;
}

export function isClearanceActive(clearance: IClearanceRecord | undefined): boolean {
    if (clearance && clearance.status) {
        return (
            clearanceStatusesConsideredActive.findIndex(
                (x) => x.toLocaleLowerCase() === clearance.status.toLocaleLowerCase(),
            ) > -1
        );
    }
    return false;
}

export function isSpecialAccessActive(specialAccess: ISpecialAccessRecord | undefined): boolean {
    if (specialAccess && specialAccess.status) {
        return (
            specialAccessStatusesConsideredActive.findIndex(
                (x) => x.toLocaleLowerCase() === specialAccess.status.toLocaleLowerCase(),
            ) > -1
        );
    }
    return false;
}

export function polygraphTypeDropdownOptions(
    selectedPolygraphType: polygraphType | undefined,
): IDropdownOption[] {
    return polygraphTable.map((x) => {
        return {
            key: x.record,
            text: x.longHand,
            selected: (x.record === selectedPolygraphType?.record) === true,
        } as IDropdownOption;
    });
}

export function clearanceStatusDropdownOptions(
    selectedStatus: string | undefined,
): IDropdownOption[] {
    return Object.keys(ClearanceStatuses).map((status) => {
        return {
            key: status,
            text: getClearanceStatusesEnumValueFromKey(status),
            selected:
                (selectedStatus &&
                    selectedStatus.toLocaleLowerCase() === status.toLocaleLowerCase()) === true,
        };
    });
}

export function clearanceTypeDropdownOptions(selectedType: string | undefined): IDropdownOption[] {
    return clearanceTypes.map((type) => {
        return {
            key: type,
            text: type,
            selected:
                (selectedType && selectedType.toLocaleLowerCase() === type.toLocaleLowerCase()) ===
                true,
        };
    });
}

export function continuousEvaluationTypeDropdownOptions(
    selectedType: string | undefined,
): IDropdownOption[] {
    return Object.keys(ContinuousEvaluationTypes).map((type) => {
        return {
            key:
                type === nameof<typeof ContinuousEvaluationTypes>('Blank')
                    ? ContinuousEvaluationTypes.Blank
                    : type,
            text:
                type === nameof<typeof ContinuousEvaluationTypes>('Blank')
                    ? ContinuousEvaluationTypes.Blank
                    : type,
            selected:
                (selectedType && selectedType.toLocaleLowerCase() === type.toLocaleLowerCase()) ===
                true,
        };
    });
}

export function specialAccessStatusDropdownOptions(
    selectedStatus: string | undefined,
): IDropdownOption[] {
    return specialAccessStatuses.map((status) => {
        return {
            key: status,
            text: status,
            selected:
                (selectedStatus &&
                    selectedStatus.toLocaleLowerCase() === status.toLocaleLowerCase()) === true,
        };
    });
}

export function specialAccessBilletTypeDropdownOptions(
    selectedBilletType: string | undefined,
): IDropdownOption[] {
    return specialAccessBilletTypes.map((billetType) => {
        return {
            key: billetType,
            text: billetType,
            selected:
                (selectedBilletType &&
                    selectedBilletType.toLocaleLowerCase() === billetType.toLocaleLowerCase()) ===
                true,
        };
    });
}

export function specialAccessDropdownOptions(
    selectedSpecialAccess: string | undefined,
): IDropdownOption[] {
    return specialAccessesList.map((specialAccess) => {
        return {
            key: specialAccess,
            text: specialAccess,
            selected:
                (selectedSpecialAccess &&
                    selectedSpecialAccess.toLocaleLowerCase() ===
                        specialAccess.toLocaleLowerCase()) === true,
        };
    });
}

export function resultDropdownOptions(selectResult: string | undefined): IDropdownOption[] {
    return polygraphResults.map((result) => {
        return {
            key: result,
            text: result.toLocaleUpperCase(),
            selected:
                (selectResult &&
                    selectResult.toLocaleLowerCase() === result.toLocaleLowerCase()) === true,
        };
    });
}

export function agencyDropdownOptions(selectedAgency: string | undefined): IDropdownOption[] {
    return Object.keys(agencies).map((agency) => {
        return {
            key: agency,
            text: getAgencyEnumValueFromKey(agency),
            selected:
                (selectedAgency &&
                    selectedAgency.toLocaleLowerCase() === agency.toLocaleLowerCase()) === true,
        };
    });
}

export function getAgencyEnumValueFromKey(key: string | undefined): string {
    if (key) {
        const value = agencies[key as keyof typeof agencies];

        if (value) {
            return value;
        }
        return key;
    }
    return '';
}

export function getClearanceStatusesEnumValueFromKey(key: string | undefined): string {
    if (key) {
        const value = ClearanceStatuses[key as keyof typeof ClearanceStatuses];

        if (value) {
            return value;
        }
        return key;
    }
    return '';
}

/**
 * Format clearance string for report download.
 *
 * @param input The string to format that's in an expected clearance string format.
 *              e.g. "_ClearanceLevel(TS)_ClearanceAgency(Test)_ClearanceContractId(1232)_"
 * @returns {string} trimmed string.
 */
export function replaceDownloadReportTemplatedClearanceText(text: string): string {
    const regEx = /\(.*?\)/g;
    const clearanceData = text.match(regEx);
    const clearanceLevel = clearanceData
        ? removeAllParenthesisFromString(clearanceData[0])
        : undefined;
    const clearanceAgency = clearanceData
        ? removeAllParenthesisFromString(clearanceData[1])
        : undefined;
    const clearanceContractId = clearanceData
        ? removeAllParenthesisFromString(clearanceData[2])
        : undefined;

    return getClearanceText({
        level: clearanceLevel,
        agency: clearanceAgency,
        contractId: clearanceContractId,
    } as IClearanceRecord);
}

/**
 * Provides shorthand clearance + agency
 * i.e. 'TS (agency1)'
 */
export function getClearanceLevelAndAgencyTitle(clearance: IClearanceRecord | undefined): string {
    if (!clearance || !clearance.level || !clearance.agency) {
        return '';
    }

    return `${
        findClearanceLevel(clearance.level)?.shortHand ?? clearance.level
    } (${getAgencyEnumValueFromKey(clearance.agency)})`;
}

export function getClearanceText(clearance: IClearanceRecord | undefined): string {
    if (!clearance || !clearance.level || !clearance.agency) {
        return '';
    }

    let text = getClearanceLevelAndAgencyTitle(clearance);

    if (clearance.contractId) {
        text += `/${clearance?.contractId}`;
    }

    return text;
}
export function getGroupedSpecialAccessesTitle(
    varSpecialAccesses: ISpecialAccessRecord[] | undefined,
): string {
    if (!varSpecialAccesses || varSpecialAccesses.length === 0) {
        return '';
    }

    const isGroupedSpecialAccessFound: boolean[] = [];

    groupedSpecialAccesses.forEach(() => {
        isGroupedSpecialAccessFound.push(false);
    });

    varSpecialAccesses.forEach((x) => {
        const index = groupedSpecialAccesses.findIndex((y) => y === x.specialAccess);
        if (index > -1) {
            isGroupedSpecialAccessFound[index] = true;
        }
    });

    let title = '';
    let groupedSpecialAccessCount = 0;

    for (let i = 0; i < isGroupedSpecialAccessFound.length; i++) {
        if (isGroupedSpecialAccessFound[i]) {
            groupedSpecialAccessCount++;
            if (groupedSpecialAccessCount > 1) {
                title += '/';
            }
            title += groupedSpecialAccesses[i];
        }
    }

    return title;
}

export function getGroupedSpecialAcccesesSubTitle(
    varSecialAccesses: ISpecialAccessRecord[] | undefined,
): string {
    if (!varSecialAccesses || varSecialAccesses.length === 0) {
        return '';
    }

    return `Clearance ${getClearanceLevelAndAgencyTitle(varSecialAccesses[0].clearance)}${
        varSecialAccesses[0].clearance?.contractId
            ? ' and Contract ' + varSecialAccesses[0].clearance?.contractId
            : ''
    }`;
}

export function getSpecialAccessesGroupName(varSpecialAccesses: ISpecialAccessRecord[]): string {
    return `${getGroupedSpecialAccessesTitle(
        varSpecialAccesses,
    )} - ${getClearanceLevelAndAgencyTitle(varSpecialAccesses[0].clearance)}${
        varSpecialAccesses[0].clearance?.contractId
            ? ' - ' + varSpecialAccesses[0].clearance?.contractId
            : ''
    }${' - (' + varSpecialAccesses.length + ')'}`;
}

export function getActiveAccessesTitle(
    varSpecialAccesses: ISpecialAccessRecord[] | undefined,
): string {
    if (!varSpecialAccesses || varSpecialAccesses.length === 0) {
        return '';
    }

    let accessCount = 0;
    const agencyActiveSpecialAccesses: Dictionary<ISpecialAccessRecord[]> = {};
    const activeSpecialAccesses = new Set<string>();

    varSpecialAccesses.forEach((x) => {
        if (isSpecialAccessActive(x) && isClearanceActive(x.clearance)) {
            if (x.clearance && x.clearance.agency) {
                if (agencyActiveSpecialAccesses[x.clearance.agency]) {
                    agencyActiveSpecialAccesses[x.clearance.agency].push(x);
                } else {
                    agencyActiveSpecialAccesses[x.clearance.agency] = [x];
                }
            } else {
                activeSpecialAccesses.add(x.specialAccess);
                accessCount++;
            }
        }
    });

    Object.values(agencyActiveSpecialAccesses).forEach((x) => {
        const uniqueActiveSpecialAccesses = Array.from(
            new Map(x.map((y) => [y.specialAccess, y])).values(),
        );

        activeSpecialAccesses.add(
            `${specialAccessShortHand(uniqueActiveSpecialAccesses)}(${getAgencyEnumValueFromKey(
                x[0]?.clearance?.agency,
            )})`,
        );

        accessCount += uniqueActiveSpecialAccesses.length;
    });

    return activeSpecialAccesses.size > 0
        ? `${accessCount > 1 ? 'ACTIVE ACCESSES' : 'ACTIVE ACCESS'}: ${Array.from(
              activeSpecialAccesses,
          ).join(' & ')}`
        : '';
}

const strComp = (r1: string | undefined, r2: string | undefined): number =>
    r1?.toLocaleLowerCase()?.localeCompare(r2?.toLocaleLowerCase() ?? '') ?? 1;

export function specialAccessShortHand(
    specialAccessRecords: ISpecialAccessRecord[] | { specialAccess: string }[] | undefined,
    dataPrefix = '',
    noDataValue = '',
): string {
    if (specialAccessRecords && specialAccessRecords.length > 0) {
        return `${dataPrefix}${[...specialAccessRecords]
            .sort((a, b) => {
                return specialAccessComparator(a.specialAccess, b.specialAccess);
            })
            .map((x) => x.specialAccess)
            .join('/')}`;
    }
    return noDataValue;
}

export function polygraphShortHand(
    polygraphRecords: IPolygraphRecord[] | undefined,
    dataPrefix = '',
    noDataValue = '',
): string {
    if (polygraphRecords && polygraphRecords.length > 0) {
        return `${dataPrefix}${[...polygraphRecords]
            .sort((a, b) => {
                return strComp(a.polygraphType, b.polygraphType);
            })
            .map((x) => x.polygraphType)
            .join(', ')}`;
    }
    return noDataValue;
}
