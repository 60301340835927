import config from 'environments/environment';
import {
    GetDefaultHttpOptions,
    AUTH_PREFIX,
    JSON_CONTENT_TYPE,
    GetAadHttpOptions,
} from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import FacilitiesClient, { IFacilitiesTokenResult } from 'clients/facilities-client';
import { Dictionary } from 'assets/constants/global-constants';

const activityConfig = config.activityServiceConfig;
const tokenExpirationValue = 500;
const readStr = 'read';
const writeStr = 'write';
class ActivitiesClient {
    static async getScreeningActivityToken(
        authContext: IAuthContext,
        screeningId: string,
        tokenPermissions: string[] = [readStr],
    ): Promise<IActivityToken> {
        const url =
            activityConfig.screeningActivityToken.baseUrl +
            activityConfig.screeningActivityToken.tokenEndpoint.replace(
                '{screeningId}',
                screeningId,
            );
        const urlParams = new URLSearchParams();
        tokenPermissions.forEach((permission) => {
            urlParams.append('permission', `${permission}`);
        });
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const res = await fetch(`${url}?${urlParams}`, httpOptions);
        if (res.status === 200) {
            return await res.json();
        } else {
            throw res;
        }
    }

    // tokenPermissions takes in string[] e.g. ['read', 'write'], default to read
    static async getProfileActivityToken(
        authContext: IAuthContext,
        profileId: string,
        tokenPermissions: string[] = [readStr],
    ): Promise<IActivityToken> {
        const url =
            activityConfig.profileActivityToken.baseUrl +
            activityConfig.profileActivityToken.tokenEndpoint.replace('{profileId}', profileId);
        const urlParams = new URLSearchParams();
        tokenPermissions.forEach((permission) => {
            urlParams.append('permission', `${permission}`);
        });
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const res = await fetch(`${url}?${urlParams}`, httpOptions);
        if (res.status === 200) {
            return await res.json();
        } else {
            throw res;
        }
    }

    static async getDocumentActivityToken(
        authContext: IAuthContext,
        documentId: string,
    ): Promise<IActivityToken> {
        const url =
            activityConfig.documentActivityToken.baseUrl +
            activityConfig.documentActivityToken.tokenEndpoint.replace('{documentId}', documentId);
        const httpOptions = await GetAadHttpOptions(
            authContext,
            config.documentsServiceConfig.aadScopes,
        );
        const res: Response = await fetch(url, httpOptions);
        if (res.status === 200) {
            return await res.json();
        } else {
            throw res;
        }
    }

    static async getAllDocumentActivitiesForDocumentId(
        authContext: IAuthContext,
        documentId: string,
        activityToken: IActivityToken,
    ): Promise<IActivity[]> {
        let usingToken = activityToken;
        const tokenTime = new Date(activityToken.expires);
        const currentTime = Date.now() - tokenExpirationValue;
        if (tokenTime.getTime() < currentTime) {
            usingToken = await ActivitiesClient.getDocumentActivityToken(authContext, documentId);
        }

        return await ActivitiesClient._getAllActivitiesForId(authContext, documentId, usingToken);
    }

    static async getAllScreeningActivitiesForScreeningId(
        authContext: IAuthContext,
        screeningId: string,
        activityToken: IActivityToken,
    ): Promise<IActivity[]> {
        let usingToken = activityToken;
        const tokenTime = new Date(activityToken.expires);
        const currentTime = Date.now() - tokenExpirationValue;
        if (tokenTime.getTime() < currentTime) {
            usingToken = await ActivitiesClient.getScreeningActivityToken(authContext, screeningId);
        }

        return await ActivitiesClient._getAllActivitiesForId(authContext, screeningId, usingToken);
    }

    static async getFacilityActivitiesForUserActivityEvents(
        authContext: IAuthContext,
        activityToken: IActivityToken,
        facilitiesToken: IFacilitiesTokenResult,
        request: IActivityReadRequest,
        pageSize?: number,
        continuationToken?: string,
    ): Promise<IActivitiesResponse> {
        let usingToken = activityToken;
        const tokenTime = new Date(activityToken.expires);
        const currentTime = Date.now() - tokenExpirationValue;

        if (tokenTime.getTime() < currentTime) {
            usingToken = await FacilitiesClient.getFacilityActivityToken(
                authContext,
                facilitiesToken,
            );
        }

        const urlParams = ActivitiesClient._getURLParamsFromRequest(request);
        if (pageSize) {
            urlParams.set('pageSize', pageSize.toString());
        }

        const url = activityConfig.baseUrl + activityConfig.activityEndpoint + `?${urlParams}`;
        return ActivitiesClient._getActivitiesHelper(
            authContext,
            url,
            usingToken,
            continuationToken,
        );
    }

    static async getAllFacilityActivitiesForUserActivityEvents(
        authContext: IAuthContext,
        activityToken: IActivityToken,
        facilitiesToken: IFacilitiesTokenResult,
        request: IActivityReadRequest,
    ): Promise<IActivity[]> {
        let usingToken = activityToken;
        const tokenTime = new Date(activityToken.expires);
        const currentTime = Date.now() - tokenExpirationValue;

        if (tokenTime.getTime() < currentTime) {
            usingToken = await FacilitiesClient.getFacilityActivityToken(
                authContext,
                facilitiesToken,
            );
        }

        const urlParams = ActivitiesClient._getURLParamsFromRequest(request);

        const url = activityConfig.baseUrl + activityConfig.activityEndpoint + `?${urlParams}`;
        return ActivitiesClient._getAllActivitiesHelper(authContext, url, usingToken);
    }

    static async getReservationMetricsCount(
        authContext: IAuthContext,
        activityToken: IActivityToken,
        facilitiesToken: IFacilitiesTokenResult,
        request: IActivityReadRequest,
    ): Promise<number> {
        let usingToken = activityToken;
        const tokenTime = new Date(activityToken.expires);
        const currentTime = Date.now() - tokenExpirationValue;
        if (tokenTime.getTime() < currentTime) {
            usingToken = await FacilitiesClient.getFacilityActivityToken(
                authContext,
                facilitiesToken,
            );
        }
        const urlParams = ActivitiesClient._getURLParamsFromRequest(request);
        const url =
            activityConfig.baseUrl +
            activityConfig.activityEndpoint +
            activityConfig.countEndpoint +
            `?${urlParams}`;
        return ActivitiesClient._getMetricsHelper(authContext, url, usingToken);
    }

    private static _getURLParamsFromRequest(request: IActivityReadRequest): URLSearchParams {
        const paramsDict: Dictionary<string> = {};

        if (request.events && request.events.length > 0) {
            paramsDict['events'] = request.events.join(',');
        }

        if (request.ids && request.ids.length > 0) {
            paramsDict['ids'] = request.ids.join(',');
        }

        if (request.referenceIds && request.referenceIds.length > 0) {
            paramsDict['referenceIds'] = request.referenceIds.join(',');
        }

        if (request.tags && request.tags.length > 0) {
            paramsDict['tags'] = request.tags.join(',');
        }

        if (request.subjectTypes && request.subjectTypes.length > 0) {
            paramsDict['subjectTypes'] = request.subjectTypes.join(',');
        }

        if (request.subjectValues && request.subjectValues.length > 0) {
            paramsDict['subjectValues'] = request.subjectValues.join(',');
        }

        if (request.directObjectTypes && request.directObjectTypes.length > 0) {
            paramsDict['directObjectTypes'] = request.directObjectTypes.join(',');
        }

        if (request.directObjectValues && request.directObjectValues.length > 0) {
            paramsDict['directObjectValues'] = request.directObjectValues.join(',');
        }

        if (request.startTimeUtc) {
            paramsDict['startTimeUtc'] = request.startTimeUtc.toString();
        }

        if (request.endTimeUtc) {
            paramsDict['endTimeUtc'] = request.endTimeUtc.toString();
        }

        let urlParams: URLSearchParams;

        const entries = Object.entries(paramsDict);
        if (entries.length > 0) {
            const paramData = entries.map(
                ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
            );
            urlParams = new URLSearchParams(paramData.join('&'));
        } else {
            urlParams = new URLSearchParams();
        }

        return urlParams;
    }

    static async getAllProfileActivitiesForProfileId(
        authContext: IAuthContext,
        profileId: string,
        activityToken: IActivityToken,
    ): Promise<IActivity[]> {
        let usingToken = activityToken;
        const tokenTime = new Date(activityToken.expires);
        const currentTime = Date.now() - tokenExpirationValue;
        if (tokenTime.getTime() < currentTime) {
            usingToken = await ActivitiesClient.getProfileActivityToken(authContext, profileId);
        }

        return await ActivitiesClient._getAllActivitiesForId(authContext, profileId, usingToken);
    }

    static async getAllProfileActivitiesBasedOnEventsAndTags(
        authContext: IAuthContext,
        profileId: string,
        events: string[],
        tags: string[],
        activityToken: IActivityToken,
    ): Promise<IActivity[]> {
        let usingToken = activityToken;
        const tokenTime = new Date(usingToken.expires);
        const currentTime = Date.now() - tokenExpirationValue;
        if (tokenTime.getTime() < currentTime) {
            usingToken = await ActivitiesClient.getProfileActivityToken(authContext, profileId);
        }

        const eventsURLParams = new URLSearchParams();
        events.forEach((event) => {
            eventsURLParams.append('events', `${event}`);
        });

        const tagsURLParams = new URLSearchParams();
        tags.forEach((tag) => {
            tagsURLParams.append('tags', `${tag}`);
        });

        const url =
            activityConfig.baseUrl +
            activityConfig.activityEndpoint +
            `?${eventsURLParams}&${tagsURLParams}`;

        return ActivitiesClient._getAllActivitiesHelper(authContext, url, activityToken);
    }

    static async postProfileActivity(
        authContext: IAuthContext,
        profileId: string,
        payload: IActivityPost,
        activityToken: IActivityToken,
    ): Promise<IActivity> {
        let usingToken = activityToken;
        const tokenTime = new Date(activityToken.expires);
        const currentTime = Date.now() - tokenExpirationValue;
        if (tokenTime.getTime() < currentTime) {
            usingToken = await ActivitiesClient.getProfileActivityToken(authContext, profileId, [
                writeStr,
            ]);
        }

        const url = activityConfig.baseUrl + activityConfig.activityEndpoint;

        const httpOptions = {
            ...(await ActivitiesClient._postActivityHttpOptions(authContext, usingToken)),
            method: 'POST',
            body: JSON.stringify(payload),
        };

        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const ret = await res.json();
            return ret;
        } else {
            throw res;
        }
    }

    static async postDocumentActivity(
        authContext: IAuthContext,
        documentId: string,
        payload: IActivityPost,
        activityToken: IActivityToken,
    ): Promise<IActivity> {
        let usingToken = activityToken;
        const tokenTime = new Date(activityToken.expires);
        const currentTime = Date.now() - tokenExpirationValue;
        if (tokenTime.getTime() < currentTime) {
            usingToken = await ActivitiesClient.getDocumentActivityToken(authContext, documentId);
        }

        const url = activityConfig.baseUrl + activityConfig.activityEndpoint;

        const httpOptions = {
            ...(await ActivitiesClient._postActivityHttpOptions(authContext, usingToken)),
            method: 'POST',
            body: JSON.stringify(payload),
        };

        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const ret = await res.json();
            return ret;
        } else {
            throw res;
        }
    }

    static async postScreeningActivity(
        authContext: IAuthContext,
        screeningId: string,
        payload: IActivityPost,
        activityToken: IActivityToken,
    ): Promise<IActivity> {
        let usingToken = activityToken;
        const tokenTime = new Date(activityToken.expires);
        const currentTime = Date.now() - tokenExpirationValue;
        if (tokenTime.getTime() < currentTime) {
            usingToken = await ActivitiesClient.getScreeningActivityToken(
                authContext,
                screeningId,
                [writeStr],
            );
        }

        const url = activityConfig.baseUrl + activityConfig.activityEndpoint;

        const httpOptions = {
            ...(await ActivitiesClient._postActivityHttpOptions(authContext, usingToken)),
            method: 'POST',
            body: JSON.stringify(payload),
        };

        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const ret = await res.json();
            return ret;
        } else {
            throw res;
        }
    }

    private static async _getAllActivitiesForId(
        authContext: IAuthContext,
        id: string,
        activityToken: IActivityToken,
    ): Promise<IActivity[]> {
        const url =
            activityConfig.baseUrl + activityConfig.activityEndpoint + `?referenceIds=${id}`;

        return ActivitiesClient._getAllActivitiesHelper(authContext, url, activityToken);
    }

    private static async _getActivitiesHelper(
        authContext: IAuthContext,
        url: string,
        activityToken: IActivityToken,
        continuatonToken?: string,
    ): Promise<IActivitiesResponse> {
        const httpOptions = await ActivitiesClient._getActivityHttpOptions(
            authContext,
            activityToken,
            continuatonToken,
        );

        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            return await res.json();
        } else {
            throw res;
        }
    }

    private static async _getMetricsHelper(
        authContext: IAuthContext,
        url: string,
        activityToken: IActivityToken,
        continuatonToken?: string,
    ): Promise<number> {
        const httpOptions = await ActivitiesClient._getActivityHttpOptions(
            authContext,
            activityToken,
            continuatonToken,
        );

        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            return await res.json();
        } else {
            throw res;
        }
    }

    private static async _getAllActivitiesHelper(
        authContext: IAuthContext,
        url: string,
        activityToken: IActivityToken,
    ): Promise<IActivity[]> {
        let continuationToken = '';
        let returnActivities: IActivity[] = [];
        do {
            const httpOptions = await ActivitiesClient._getActivityHttpOptions(
                authContext,
                activityToken,
                continuationToken,
            );

            const res = await fetch(url, httpOptions);
            if (res.status === 200) {
                const jsonResponse = (await res.json()) as IActivitiesResponse;
                continuationToken = jsonResponse.continuationToken;
                returnActivities = returnActivities.concat(jsonResponse.result);
            } else {
                throw res;
            }
        } while (continuationToken !== '');
        return returnActivities;
    }

    private static async _getActivityHttpOptions(
        context: IAuthContext,
        activityToken: IActivityToken,
        continuationToken?: string,
    ): Promise<RequestInit> {
        const authToken = await context.getToken(activityConfig.aadScopes);
        const actToken = activityToken.token;
        return {
            headers: {
                Authorization: AUTH_PREFIX + authToken,
                'Content-Type': JSON_CONTENT_TYPE,
                'activity-token': actToken,
                'x-continuation-token': continuationToken ? continuationToken : '',
            },
        };
    }

    private static async _postActivityHttpOptions(
        context: IAuthContext,
        activityToken: IActivityToken,
    ): Promise<RequestInit> {
        const authToken = await context.getToken(activityConfig.aadScopes);
        const actToken = activityToken.token;
        return {
            headers: {
                Authorization: AUTH_PREFIX + authToken,
                'Content-Type': JSON_CONTENT_TYPE,
                'activity-token': actToken,
            },
        };
    }
}

export default ActivitiesClient;

export interface IActivityToken {
    token: string;
    expires: string;
}

export interface IActivitiesResponse {
    continuationToken: string;
    result: IActivity[];
}

export interface IActivity {
    type: string;
    id: string;
    receivedTimestampUTC: number;
    event: string;
    eventTimestampUTC: number;
    subject: IActivityValue;
    directObject: IActivityValue;
    additionalObjects: IActivityValue[];
    message: string;
    metadata: { [key: string]: string };
    referenceId: string;
    tags: string[];
    appName: string;
}

export interface IActivityValue {
    type: string;
    value: string;
}

export interface IActivityPost {
    additionalObjects?: IActivityValue[];
    appName?: string;
    event: string;
    eventTimestampUTC: number;
    referenceId: string;
    securityIds: string[];
    subject: IActivityValue;
    directObject: IActivityValue;
    message: string;
    tags?: string[];
}

export interface IActivityReadRequest {
    events?: string[];
    ids?: string[];
    referenceIds?: string[];
    tags?: string[];
    subjectTypes?: string[];
    subjectValues?: string[];
    directObjectTypes?: string[];
    directObjectValues?: string[];
    startTimeUtc?: number;
    endTimeUtc?: number;
}
