type mimeTypeDetails = { mimeType: string; hexRegex: RegExp; extensions: string[] };

//Hex signatures mostly come from https://en.wikipedia.org/wiki/List_of_file_signatures
export const hexSignatureMap: mimeTypeDetails[] = [
    {
        mimeType: 'image/jpeg',
        hexRegex: /^FFD8/,
        extensions: ['.jpg'],
    },
    {
        mimeType: 'image/png',
        hexRegex: /^89504E47/,
        extensions: ['.png'],
    },
    {
        mimeType: 'application/pdf',
        hexRegex: /^25504446/,
        extensions: ['.pdf'],
    },
    {
        mimeType: 'application/msword',
        hexRegex: /^ECA5C100/,
        extensions: ['.doc'],
    },
    {
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        hexRegex: /^(504B3414060|504B030414000600|D0CF11E0A1B11AE1)/,
        extensions: ['.docx'],
    },
    {
        mimeType: 'application/zip',
        hexRegex: /^(504B0304|504B0506|504B0708|5353564B44415441)/,
        extensions: ['.zip'],
    },
    {
        mimeType: 'application/x-zip-compressed',
        hexRegex: /^(504B3414000)/,
        extensions: ['.zip'],
    },
];

// zipped files are accepted only during document request (when uploading a template)
export const acceptedFileTypesTextForTemplate = 'PDF, DOC, DOCX, PNG, JPG, and ZIP files accepted.';
export const acceptedFileTypesTextForUpload = 'PDF, DOC, DOCX, PNG, and JPG files are accepted.';
export const acceptedMaxUploadPerFileText = 'max 1 file.';
export const acceptedMaxFileSize = 'max file size 50MB.';

export const fileInputAcceptStringForTemplate = hexSignatureMap
    .map((x) => x.extensions)
    .flat()
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort()
    .join(',');

export const acceptedFileTypesForTemplate = hexSignatureMap.map((x) => x.mimeType);

const hexSignatureMapWithOutZipFormat = hexSignatureMap.filter(
    (x) => !x.extensions.includes('.zip'),
);
export const fileInputAcceptStringForUpload = hexSignatureMapWithOutZipFormat
    .map((x) => x.extensions)
    .flat()
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort()
    .join(',');

export const acceptedFileTypesForUpload = hexSignatureMapWithOutZipFormat.map((x) => x.mimeType);

export const getMimeTypeFromHex = (hexSignature: string): string => {
    const foundHexType = hexSignatureMap.find((x) => x.hexRegex.test(hexSignature));
    return foundHexType ? foundHexType.mimeType : 'unknown';
};

export async function getFileType(file: File): Promise<string> {
    const uint: Uint8Array = new Uint8Array(await file?.slice(0, 8).arrayBuffer());
    const hex: string = uint
        .reduce((acc, currentValue) => {
            return acc + currentValue.toString(16);
        }, '')
        .toUpperCase();
    const type: string = getMimeTypeFromHex(hex);
    return type;
}

export async function isValidFileType(
    file: File,
    varAcceptedFileTypes: string[] | undefined,
): Promise<boolean> {
    if (!varAcceptedFileTypes) return true;
    const fileType: string = await getFileType(file);
    return varAcceptedFileTypes.includes(file.type) && varAcceptedFileTypes.includes(fileType);
}

export function checkIfWordDocument(fileType: string): boolean {
    return (
        fileType === 'application/msword' ||
        fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
}

export function changeFileName(file: File, targetName: string): File {
    let fileExt = '';
    if (file.name.includes('.')) {
        fileExt = file.name.split('.').slice(-1)[0];
    }
    targetName = fileExt ? `${targetName}.${fileExt}` : targetName;
    return new File([file], targetName, { type: file.type });
}

export function initiateBrowserDownload(
    blobData: Blob,
    fileName: string,
    fileExt: string,
    downloadLink: HTMLAnchorElement,
    windowObj: Window & typeof globalThis = window,
): void {
    downloadLink.href = windowObj.webkitURL.createObjectURL(blobData);
    downloadLink.download = `${fileName}.${fileExt}`;
    downloadLink.click();
}

export async function readFile(response: Response): Promise<Blob | undefined> {
    if (!response.body) return;
    const reader: ReadableStreamDefaultReader<Uint8Array> = response.body.getReader();
    const stream: ReadableStream<Uint8Array> = await new ReadableStream({
        start(controller: ReadableStreamDefaultController<Uint8Array>): PromiseLike<void> {
            return pump();
            async function pump(): Promise<void> {
                const result: ReadableStreamReadResult<Uint8Array> = await reader.read();
                if (result.done) {
                    controller.close();
                    return;
                }
                controller.enqueue(result.value);
                return pump();
            }
        },
    });
    const res: Response = new Response(stream);
    const blob: Blob = await res.blob();
    return blob;
}
