import React, { FormEvent } from 'react';

import { Dropdown, IDropdownOption, Separator, Stack } from '@fluentui/react';
import { globalSeparatorStyles } from 'assets/styles/global-styles';

export interface SecureWorkAreaDropdownSelectorProps {
    onChange: (
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
        index?: number,
    ) => Promise<void>;
    placeholder: string;
    selectedKey?: string;
    options?: { key: string; text: string }[];
    separatorStyles?: any;
}

export default function SecureWorkAreaDropdownSelector(
    props: SecureWorkAreaDropdownSelectorProps,
): JSX.Element {
    return (
        <Stack>
            <Stack.Item>
                <Separator
                    styles={props.separatorStyles ?? globalSeparatorStyles}
                    alignContent='start'>
                    Secure Work Area
                </Separator>
                <Dropdown
                    onChange={props.onChange}
                    placeholder={props.placeholder}
                    selectedKey={props.selectedKey}
                    options={props.options ?? []}
                    ariaLabel='Secure Work Area'
                />
            </Stack.Item>
        </Stack>
    );
}
