import { IClearanceRecord } from 'clients/clearance-client';
import React, { createContext, useState } from 'react';
import { Location } from 'history';

export enum DateTypes {
    grantDate = 'grantDate',
    investigationDate = 'investigationDate',
    briefedDate = 'briefedDate',
    expiresOnDate = 'expiresOnDate',
    debriefedDate = 'debriefedDate',
}
export enum filterClearanceRecordsKeys {
    contractIdKey = 'contractId',
    agencyIdKey = 'agencyId',
    orgLeaderAliasKey = 'orgLeaderAlias',
    personnelIdKey = 'personnelId',
}

interface FiltersClearanceRecordsProviderProps {
    agency?: string;
    briefDateUTCMilliseconds?: string;
    children: React.ReactNode;
    contractId?: string;
    creationDateTimeUTCMilliseconds?: string;
    debriefDateUTCMilliseconds?: string;
    grantDateUTCMilliseconds?: string;
    investigationDateUTCMilliseconds?: string;
    level?: string;
    type?: string;
    location: Location;
    employeeStatus?: string;
    orgLeaderAlias?: string;
    personnelId?: string;
    status?: string;
    userType: string;
}

//review this filter for possible improvement
function FiltersClearanceRecordsProvider(props: FiltersClearanceRecordsProviderProps): JSX.Element {
    const [location] = useState<Location>(props.location);
    const [personnelId, setPersonnelId] = useState<string>(props.personnelId || '');
    const [contractId, setContractId] = useState<string>(props.contractId || '');
    const [status, setStatus] = useState<string>(props.status || '');
    const [level, setLevel] = useState<string>(props.level || '');
    const [type, setType] = useState<string>(props.type || '');
    const [filterFunctionsMap] = useState<Map<string, any[]>>(new Map<string, any[]>());
    const [agency, setAgency] = useState<string>(props.agency || '');
    const [grantDate, setGrantDate] = useState<string>(
        props.grantDateUTCMilliseconds?.toString() || '',
    );
    const [investigationDate, setInvestigationDate] = useState<string>(
        props.investigationDateUTCMilliseconds || '',
    );
    const [briefedDate, setBriefedDate] = useState<string>(props.briefDateUTCMilliseconds || '');
    const [expiresOnDate, setExpiresOnDate] = useState<string>(
        props.creationDateTimeUTCMilliseconds || '',
    );
    const [filterCleared, setFilterCleared] = useState<string>('');
    const [employeeStatus, setEmployeeStatus] = useState<string>('');
    const [orgLeaderAlias, setOrgLeaderAlias] = useState<string>(props.orgLeaderAlias || '');
    const [debriefedDate, setDebriefedDate] = useState<string>(
        props.debriefDateUTCMilliseconds || '',
    );
    const [
        continuousEvaluationContinuousVetting,
        setContinuousEvaluationContinuousVetting,
    ] = useState<string>('');

    function clearFilters(): void {
        // all these variables above ^^ need to get cleared
        filterFunctionsMap.clear();
        [
            setPersonnelId,
            setContractId,
            setStatus,
            setLevel,
            setType,
            setAgency,
            setGrantDate,
            setInvestigationDate,
            setBriefedDate,
            setDebriefedDate,
            setContinuousEvaluationContinuousVetting,
            setExpiresOnDate,
            setOrgLeaderAlias,
            setEmployeeStatus,
        ].forEach((set) => set(''));
        setFilterCleared(Date.now().toString());
    }

    function updateFilter(
        filterFunctionsMap: Map<string, any[]>,
        key: string,
        func: (arg: IClearanceRecord) => boolean,
        adding: boolean,
    ): void {
        if (
            key.includes(DateTypes.grantDate) ||
            key.includes(DateTypes.investigationDate) ||
            key.includes(DateTypes.briefedDate) ||
            key.includes(DateTypes.expiresOnDate) ||
            key.includes(DateTypes.debriefedDate) ||
            [
                filterClearanceRecordsKeys.contractIdKey.valueOf(),
                filterClearanceRecordsKeys.agencyIdKey.valueOf(),
            ].includes(key)
        ) {
            filterFunctionsMap.set(key, [func]);
        } else if (filterFunctionsMap && adding) {
            let filters = filterFunctionsMap.get(key);
            if (!filters) {
                filters = [];
            }
            filters.push(func);
            filterFunctionsMap.set(key, filters);
        } else if (filterFunctionsMap && !adding) {
            if (
                [
                    filterClearanceRecordsKeys.personnelIdKey.valueOf(),
                    filterClearanceRecordsKeys.orgLeaderAliasKey.valueOf(),
                ].includes(key)
            ) {
                filterFunctionsMap.set(key, []);
            } else {
                let filters = filterFunctionsMap.get(key);
                if (!filters) {
                    filters = [];
                }
                filters = filters.filter((x) => {
                    //Turning the function into strings and comparing them as such
                    //to figure out if it should be removed or not.
                    return '' + func !== '' + x;
                });
                filterFunctionsMap.set(key, filters);
            }
        }
    }

    return (
        <FiltersClearanceRecords.Provider
            value={{
                personnelId,
                setEmployeeId: (value: string): void => setPersonnelId(value),
                level,
                setLevel,
                type,
                setType,
                agency,
                setAgency,
                status,
                setStatus,
                contractId,
                setContractId,
                grantDate,
                setGrantDate,
                investigationDate,
                setInvestigationDate,
                briefedDate,
                setBriefedDate,
                expiresOnDate,
                setExpiresOnDate,
                debriefedDate,
                setDebriefedDate,
                continuousEvaluationContinuousVetting,
                setContinuousEvaluationContinuousVetting,
                employeeStatus,
                setEmployeeStatus,
                orgLeaderAlias,
                setOrgLeaderAlias,
                filterFunctionsMap,
                filterCleared,
                clearFilters,
                updateFilterFunctions: (
                    key: string,
                    func: (data: IClearanceRecord) => boolean,
                    adding: boolean,
                ): void => updateFilter(filterFunctionsMap, key, func, adding),
                location,
            }}>
            {props.children}
        </FiltersClearanceRecords.Provider>
    );
}
export default FiltersClearanceRecordsProvider;

export const FiltersClearanceRecords = createContext<IFiltersClearanceRecords>(null!);

export interface IFiltersClearanceRecords {
    // 1st column - the employee
    personnelId: string;
    setEmployeeId: (value: string) => void;
    // 2nd column - clearance level
    level: string;
    setLevel: (value: string) => void;
    // 3rd column - clearance type
    type: string;
    setType: (value: string) => void;
    // 4th column - status
    status: string;
    setStatus: (value: string) => void;
    // 5th column - contractId, project name is 5th column which is found via the contract id
    contractId: string;
    setContractId: (value: string) => void;
    // 6th column - agency
    agency: string;
    setAgency: (value: string) => void;
    // 7th column - granted date - grantDateUTCMilliseconds
    grantDate?: string;
    setGrantDate: (value: string) => void;
    // 8th column - investigated date
    investigationDate?: string;
    setInvestigationDate: (value: string) => void;
    // 9th column  - briefedDate - originalData?.Briefed
    briefedDate?: string;
    setBriefedDate: (value: string) => void;
    // 10th column  - expiresOnDate - this requires some math
    expiresOnDate?: string;
    setExpiresOnDate: (value: string) => void;
    debriefedDate: string;
    setDebriefedDate: (value: string) => void;
    continuousEvaluationContinuousVetting: string;
    setContinuousEvaluationContinuousVetting: (value: string) => void;
    employeeStatus: string;
    setEmployeeStatus: (value: string) => void;
    orgLeaderAlias: string;
    setOrgLeaderAlias: (value: string) => void;
    filterFunctionsMap: Map<string, any[]>;
    filterCleared: string;
    clearFilters: () => void;
    updateFilterFunctions: (
        key: string,
        func: (data: IClearanceRecord) => boolean,
        adding: boolean,
        name?: string,
    ) => void;
    location: Location;
}
