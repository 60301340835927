import React from 'react';
import { TableCell } from 'components/common/table';
import {
    IColumn,
    IDetailsColumnProps,
    Spinner,
    Stack,
    ActionButton,
    mergeStyleSets,
} from '@fluentui/react';
import { xLargeMaxWidthCoeff, IconNames } from 'assets/constants/global-constants';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { IMyGroup } from 'clients/group-client';
import Badge from 'components/common/badge';
import {
    canManage,
    canNominate,
    getCompliantStatus,
    getCompliantColor,
    canCheckMember,
} from 'components/groups/groups-utils';
import NavLinkContainer from 'components/common/navlink-container';
import { lastColumnStylesButton } from 'assets/styles/list-styles';
import LeaveGroupModalActionButton from 'components/groups/my-groups/leave-group-modal-action-button';
import PolicyCheckModalActionButton from 'components/groups/my-groups/policy-check-modal-action-button';
import { ISortableColumns } from 'utils/sort-utils';
import { manageTitle } from 'assets/constants/global-constants';
import { enumNameToDisplayString } from 'utils/enum-utils';
import LabelInfoIcon from 'components/common/use-input/info-icon-label';

interface ITableColumnsMyGroupsActiveParams extends ISortableColumns {
    handleGroupsTableUpdate: (groupId: string) => void;
    isLoadingWithEvaluateRules: boolean;
}

export function tableColumnsMyGroupsActive(params: ITableColumnsMyGroupsActiveParams): IColumn[] {
    const columnWidths = {
        group: 180,
        membership: 150,
        compliancy: 220,
        action: 250,
    };

    const columns: IColumn[] = [
        {
            key: 'Group',
            name: 'Group',
            ariaLabel: 'Group',
            minWidth: columnWidths.group,
            maxWidth: columnWidths.group * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === 'Group',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Group');
            },
            onRender: (row: IMyGroup): JSX.Element => {
                const canCheckOrNominate = canCheckMember(row) || canNominate(row);

                return (
                    <TableCell key={`${row.id}-name`}>
                        {!canCheckOrNominate ? (
                            <>
                                <div style={{ marginTop: '-4px' }}>
                                    <LabelInfoIcon
                                        iconHoverContent={
                                            <ul className={styles.hoverCardList}>
                                                <li key={`${row.id}-info`}>
                                                    Only compliant members are able to perform group
                                                    actions. Select the &apos;Policy Check&apos;
                                                    button to view remediation steps.
                                                </li>
                                            </ul>
                                        }
                                        iconName={IconNames.Info}>
                                        {'  '}
                                        <div style={{ fontWeight: 400, marginTop: '-2px' }}>
                                            <EllipsisTextCss text={row.name} />
                                        </div>
                                    </LabelInfoIcon>
                                </div>
                            </>
                        ) : (
                            <div style={{ marginTop: '-4px' }}>
                                <EllipsisTextCss text={row.name} />
                            </div>
                        )}
                    </TableCell>
                );
            },
        },
        {
            key: 'Membership',
            name: 'Membership',
            ariaLabel: 'Membership',
            minWidth: columnWidths.membership,
            maxWidth: columnWidths.membership * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === 'Membership',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('Membership');
            },
            onRender: (row: IMyGroup): JSX.Element => (
                <TableCell key={`${row.id}-role`} style={{ marginTop: '-2px' }}>
                    <span>{enumNameToDisplayString(row.role)}</span>
                </TableCell>
            ),
        },
        {
            key: 'Compliancy',
            name: 'Compliancy',
            ariaLabel: 'Compliancy',
            minWidth: columnWidths.compliancy,
            maxWidth: columnWidths.compliancy * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === 'Compliancy',
            isSortedDescending: !params.sortAscending,
            onRenderHeader: (p: IDetailsColumnProps | undefined): JSX.Element => {
                return (
                    <Stack horizontal>
                        {p?.column.name}&nbsp;
                        {params.isLoadingWithEvaluateRules && <Spinner />}
                    </Stack>
                );
            },
            onColumnClick: (): void => {
                params.sortColumnHandler('Compliancy');
            },
            onRender: (row: IMyGroup): JSX.Element => (
                <TableCell key={`${row.id}-compliancy`} style={{ marginTop: '-1px' }}>
                    <span>{row.compliant}</span>
                    <Badge
                        text={getCompliantStatus(!!row.compliant, false, false)}
                        backgroundColor={getCompliantColor(!!row.compliant, false, false)}
                        marginRight='10px'
                    />
                    {row.compliant && row.hasViolationOnGracePeriod && (
                        <Badge
                            text={getCompliantStatus(!!row.compliant, true, false)}
                            backgroundColor={getCompliantColor(!!row.compliant, true, false)}
                        />
                    )}
                </TableCell>
            ),
        },
        {
            key: 'Actions',
            name: 'Actions',
            ariaLabel: 'Actions',
            minWidth: columnWidths.action,
            maxWidth: columnWidths.action * xLargeMaxWidthCoeff,
            ...lastColumnStylesButton,
            onRender: (row: IMyGroup): JSX.Element => {
                return (
                    <TableCell key={`${row.id}-actions`}>
                        <div style={{ marginTop: '-10px' }}>
                            <Stack horizontal>
                                {params.handleGroupsTableUpdate && (
                                    <PolicyCheckModalActionButton
                                        handleGroupsTableUpdate={params.handleGroupsTableUpdate}
                                        groupId={row.id}
                                        groupName={row.name}
                                        etag={row._etag}
                                    />
                                )}
                                {canManage(row) ? (
                                    <NavLinkContainer to={`/groups/manage/${row.id}`}>
                                        <ActionButton
                                            text={manageTitle}
                                            iconProps={{ iconName: IconNames.Settings }}
                                        />
                                    </NavLinkContainer>
                                ) : (
                                    <LeaveGroupModalActionButton
                                        groupId={row.id}
                                        isLeaveEnabled={!row.enableDynamic}
                                    />
                                )}
                            </Stack>
                        </div>
                    </TableCell>
                );
            },
        },
    ];

    return columns;
}

const styles = mergeStyleSets({
    hoverCardList: {
        paddingRight: 20,
    },
});
