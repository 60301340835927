import React, { ReactNode } from 'react';
import { FontIcon, Icon, mergeStyles } from '@fluentui/react';
import InfoViewer, { infoFlexContainerStyles } from 'components/forms/element-viewer/info-viewer';

const titleStyles = mergeStyles({
    fontWeight: '400',
});

const titleContainerStyles = mergeStyles({
    marginBottom: '3rem',
    gridArea: 'title',
    height: 'fit-content',
    background: 'white',
});

const saveTextContainerStyles = mergeStyles(infoFlexContainerStyles, {
    padding: '0 0 1rem',
});
const saveTextContentStyles = mergeStyles({
    width: 'max-content',
    marginLeft: 'auto',
    paddingRight: '2rem',
});
const infoIconStyles = mergeStyles({
    verticalAlign: 'bottom',
    marginRight: '0.2rem',
});

type HeaderViewerProps = {
    title: string;
    description: string;
    saveText: string;
    children?: ReactNode;
    isLocked?: boolean;
};

function HeaderViewer(props: HeaderViewerProps): JSX.Element {
    const { title, description, saveText, children, isLocked } = props;
    return (
        <div className={titleContainerStyles}>
            <h1 className={titleStyles}>
                {title} {isLocked && <Icon iconName='lock' aria-label='locked'></Icon>}
            </h1>
            <InfoViewer description={description} />
            <div className={saveTextContainerStyles}>
                <div className={saveTextContentStyles}>
                    <FontIcon className={infoIconStyles} iconName='info' />
                    {saveText}
                </div>
            </div>
            {children !== undefined && children}
        </div>
    );
}

export default React.memo(HeaderViewer);
