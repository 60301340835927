import React, { useContext } from 'react';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { ITeamAzureService } from 'clients/staffing-client';
import { globalStyles } from 'assets/styles/global-styles';
import StaffingClient from 'clients/staffing-client';
import { AuthContext } from 'contexts/auth-context';

interface IDeleteServiceMapModal {
    service: ITeamAzureService;
    deleteServiceConcluded: (modalConclusion: ModalConclusion) => void;
}

export default function DeleteServiceMapModal(props: IDeleteServiceMapModal): JSX.Element {
    const authContext = useContext(AuthContext);

    const performDeleteService = async (service: ITeamAzureService) => {
        // Tech Debt
        // The following code relies on ModalActionButton to catch error.
        // A better way to handle the error is to get text of the error
        // and throw it. ModalActionButton will then catch and display it
        // on a banner on the modal.
        await StaffingClient.deleteAzureService(authContext, service);
    };

    return (
        <ModalActionButton<void>
            text={'Delete'}
            iconName={'Delete'}
            onSubmit={async () => performDeleteService(props.service)}
            modalTitle={'Delete Service'}
            enableSubmit={true}
            submitErrorMsg={'Some error occurred. Could not delete the service.'}
            onModalConcluded={props.deleteServiceConcluded}
            submitButtonText={'Delete'}>
            <div>
                <span>Are you sure you want to delete service </span>
                <span className={globalStyles.boldFont}>{props.service.serviceName}</span>
                <span> from team </span>
                <span className={globalStyles.boldFont}>{props.service.teamName}</span>
                <span>?</span>
            </div>
        </ModalActionButton>
    );
}
