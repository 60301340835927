import React from 'react';
import { Stack, mergeStyleSets, mergeStyles } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';

interface ISeparatorFuncProps {
    text: string;
    id?: string;
}

export default function CommonSeparator(props: ISeparatorFuncProps): JSX.Element {
    /**
     * This component was first implemented using the Fluent component Separator.
     * The reason for changing it was to have a clear background color on the text
     * of the separator in order to show the background color of the parent element.
     */
    return (
        <Stack id={props.id} horizontal verticalAlign='center'>
            <h2 className={styles.separator}>{props.text}</h2>
            <div>&nbsp;&nbsp;</div>
            <div className={styles.separatorLine}></div>
        </Stack>
    );
}

const styles = mergeStyleSets({
    separator: mergeStyles(globalStyles.boldFont, {
        flexGrow: 1,
        marginTop: 20,
        marginBottom: 20,
        fontSize: '1em',
    }),
    separatorLine: {
        flexGrow: 100,
        height: 1,
        marginTop: 2,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
});
