import {
    ApplicationInsights,
    DistributedTracingModes,
    ITelemetryItem,
    SeverityLevel,
} from '@microsoft/applicationinsights-web';

interface IKioskWindowExtensions {
    facilitiesKioskDeviceName: string | undefined;
    facilitiesKioskUwpVersion: string | undefined;
    facilitiesKioskSessionId: string | undefined;
}

let appInsights: ApplicationInsights | null = null;

export function initializeTelemetry(connectionString: string): ApplicationInsights | null {
    if (connectionString) {
        appInsights = new ApplicationInsights({
            config: {
                connectionString: connectionString,
                distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
                disableFetchTracking: false,
                enableAutoRouteTracking: true,
                enableCorsCorrelation: true,
                enableUnhandledPromiseRejectionTracking: true,
            },
        });
        appInsights.loadAppInsights();
        appInsights.addTelemetryInitializer((telemetry: ITelemetryItem) => {
            telemetry.tags?.push({ 'ai.cloud.role': 'Microsoft-Personnel-App' });

            const kioskWindow = (window as unknown) as IKioskWindowExtensions;

            if (
                kioskWindow.facilitiesKioskDeviceName !== undefined &&
                kioskWindow.facilitiesKioskUwpVersion !== undefined &&
                kioskWindow.facilitiesKioskSessionId !== undefined
            ) {
                telemetry.tags?.push({
                    'ai.cloud.roleInstance': kioskWindow.facilitiesKioskDeviceName,
                    'ai.session.id': kioskWindow.facilitiesKioskSessionId,
                });

                telemetry.data = telemetry.data ?? {};
                telemetry.data['kioskUwpVersion'] = kioskWindow.facilitiesKioskUwpVersion;
            }
        });
    }
    return appInsights;
}

export function getAppInsights(): ApplicationInsights | null {
    return appInsights;
}

export function logMessage({
    method,
    message,
    severityLevel,
}: {
    method: string;
    message: string;
    severityLevel: SeverityLevel;
}): void {
    getAppInsights()?.trackTrace({
        message: `${method} | ${message}`,
        severityLevel: severityLevel,
    });
}
