import React from 'react';
import {
    FontWeights,
    mergeStyleSets,
    mergeStyles,
    Persona,
    PersonaPresence,
    PersonaInitialsColor,
    PersonaSize,
} from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import { generateRandomKey } from 'utils/misc-utils';
import { getDisplayNameOrDefault, getInitials } from 'components/common/employee/employee-utils';

export interface EmployeeMiniCardProps {
    employee:
        | {
              displayName: string;
              alias?: string;
              isActiveEmployee?: boolean; // Default: True. Specifically pass false if employee is terminated.
              preferredFirstName?: string;
              preferredLastName?: string;
              firstName?: string;
              lastName?: string;
              fullName?: string;
          }
        | undefined;
    imageUrl?: string;
    imageSize?: PersonaSize;
    showImage?: boolean;
    showAlias?: boolean;
    showFullName?: boolean; // Default: true
}

const columnStyles = mergeStyleSets({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0px 5px',
        alignItems: 'flex-start',
        maxWidth: '100%',
    },
    details: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        maxWidth: '100%',
    },
    alias: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0px 5px',
        alignItems: 'center',
    },
    fullName: {
        fontWeight: FontWeights.semibold,
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden',
    },
});

export default function EmployeeMiniCard(props: EmployeeMiniCardProps): JSX.Element {
    const isActiveEmployee = props.employee?.isActiveEmployee ?? true; // to be backward compatible
    const displayName = getDisplayNameOrDefault(props.employee);
    const imageAlt = `${displayName}'s photo`;

    const showFullName = props.showFullName ?? true;
    const isNameDisplayed = showFullName && displayName;
    const isAliasDisplayed = !!props.showAlias && props?.employee?.alias;

    const imageInitials = getInitials(displayName);

    return (
        <div className={columnStyles.root}>
            <div className={columnStyles.details}>
                {!!props.showImage && (
                    <Persona
                        initialsColor={PersonaInitialsColor.purple}
                        imageAlt={imageAlt}
                        imageInitials={imageInitials}
                        key={props?.employee?.alias ?? generateRandomKey('MiniCardPersona')}
                        showInitialsUntilImageLoads={true}
                        imageUrl={props.imageUrl}
                        size={props.imageSize ? props.imageSize : PersonaSize.size24}
                        hidePersonaDetails={true}
                        presence={PersonaPresence.none}
                    />
                )}
                {isNameDisplayed && <span className={columnStyles.fullName}>{displayName}</span>}
            </div>
            <div className={columnStyles.alias}>
                {isAliasDisplayed && (
                    <span className={globalStyles.fontStylesAlias}>
                        ({props.employee?.alias?.toLowerCase()})
                    </span>
                )}
                {(isNameDisplayed || isAliasDisplayed) && !isActiveEmployee && (
                    <span
                        className={mergeStyles(globalStyles.fontStylesAlias, {
                            fontStyle: 'italic',
                        })}>
                        (terminated)
                    </span>
                )}
            </div>
        </div>
    );
}
