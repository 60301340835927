import { lightPink } from 'assets/constants/global-colors';
import { Checkbox } from '@fluentui/react';
import React from 'react';
import { globalCheckboxStyles } from 'assets/styles/global-styles';

export interface ConvertProfileStepperPageConfirmationProps {
    checkBoxStatus: boolean | undefined;
    onFTEConvertConfirmation: (newValue?: boolean) => void;
}

const checkboxStyles = {
    root: globalCheckboxStyles.root,
};

export default function ConvertProfileStepperPageConfirmation(
    props: ConvertProfileStepperPageConfirmationProps,
): JSX.Element {
    return (
        <>
            <blockquote
                style={{
                    background: lightPink,
                    margin: '0px 0px',
                    padding: '12px 15px',
                }}>
                Read the following items carefully prior to converting to FTE.
            </blockquote>
            <p>
                During the conversion of a prehire to FTE, the following items will be updated
                automatically:
            </p>
            <ul style={{ paddingInlineStart: 20 }}>
                <li>
                    The employee&apos;s name and employment information will be overwritten by HR
                    Data and will no longer be editable.
                </li>
                <li>
                    If the employee has a Prehire US Gov screening record in Personnel, their
                    screening record will also be converted to a FTE screening record.
                </li>
                <li>
                    If the FTE and Prehire profiles have conflicting Employee Data, then the FTE
                    Employee Data will take precedence.
                </li>
                <li style={{ marginBottom: '10px' }}>
                    After the conversion, the prehire&apos;s profile and (if applicable)
                    prehire&apos;s screening record will no longer be accessible. The data will
                    instead be associated with the converted FTE.
                </li>
            </ul>
            <Checkbox
                styles={checkboxStyles}
                checked={props.checkBoxStatus ?? false}
                label={
                    'I have read and understand the items. I  would like to proceed to convert this record.'
                }
                onChange={(ev, isChecked): void => props.onFTEConvertConfirmation(isChecked)}
            />
        </>
    );
}
