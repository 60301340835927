import { ActionButton, Pivot, PivotItem, Stack } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import HorizontalBar from 'components/common/horizontal-bar';
import React from 'react';

interface IScreeningPageToolbarProps {
    showNominateButton: boolean;
    tabTitles: string[];
    selectedTab: string | undefined;
    onTabSelected: (tabHeader: string | undefined) => void;
    onClickNominateCandidate: () => void;
}

export default function ScreeningPageToolbar(props: IScreeningPageToolbarProps): JSX.Element {
    return (
        <HorizontalBar horizontalAlign='space-between'>
            <Stack horizontal>
                <ActionButton
                    onClick={props.onClickNominateCandidate}
                    iconProps={{ iconName: IconNames.AddFriend }}
                    allowDisabledFocus>
                    Nominate candidate
                </ActionButton>
            </Stack>
            <Stack horizontal>
                {props.tabTitles.length > 0 && (
                    <Pivot
                        selectedKey={
                            props.tabTitles.findIndex((t) => t === props.selectedTab).toString() ??
                            '0'
                        }
                        onLinkClick={(tabHeader: PivotItem | undefined) => {
                            props.onTabSelected(tabHeader?.props.headerText);
                        }}>
                        {props.tabTitles.map((tabTitle, index) => (
                            <PivotItem key={`tab-${tabTitle}-${index}`} headerText={tabTitle} />
                        ))}
                    </Pivot>
                )}
            </Stack>
        </HorizontalBar>
    );
}
