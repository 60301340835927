import { mergeStyles } from '@fluentui/react';

export const staticStatTileWrapperGroups = mergeStyles({
    display: 'flex',
    position: 'absolute',
    top: '228px',
    right: '40px',
    selectors: {
        '@media (max-width: 940px)': {
            marginTop: '200px',
            position: 'absolute',
            top: '65px',
            left: '15px',
            flexWrap: 'wrap',
        },
    },
});
