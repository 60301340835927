import { useContext, useState } from 'react';
import { ContractType, IContract } from 'components/screening/us-gov/IContract';
import { AuthContext } from 'contexts/auth-context';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';

type IUseContractsType = {
    getContracts: (contractIdSet: Set<string>, contractType: ContractType) => Promise<void>;
    contractsMap: Map<string, IContract>;
    errorGettingRecords: string | undefined;
    clearErrorMessage: () => void;
};

export function useContracts(): IUseContractsType {
    const [contractsMap, setContractsMap] = useState<Map<string, IContract>>(
        new Map<string, IContract>(),
    );
    const [errorGettingRecords, setErrorGettingRecords] = useState<string | undefined>();

    const authContext = useContext(AuthContext);

    const clearErrorMessage = (): void => {
        setErrorGettingRecords(undefined);
    };

    const getContracts = async (
        contractIdsSet: Set<string>,
        contractType: ContractType,
    ): Promise<void> => {
        if (!contractIdsSet || contractIdsSet.size === 0) {
            return;
        }

        try {
            const getTheseContracts = new Set<string>();
            contractIdsSet.forEach((id) => {
                if (!contractsMap.has(id)) {
                    getTheseContracts.add(id);
                }
            });
            if (getTheseContracts.size === 0) {
                return;
            }
            const contractsResult: IContract[] = await UsGovScreeningClient.getContractsByContractIds(
                authContext,
                Array.from(getTheseContracts),
                contractType === ContractType.PublicTrust,
            );

            if (contractsResult.length > 0) {
                setContractsMap((currentValue) => {
                    const newValue = new Map<string, IContract>(currentValue);
                    contractsResult.forEach((contract) => {
                        newValue.set(contract.id, contract);
                    });
                    return newValue;
                });
            }
        } catch (e) {
            console.error('Error getting contracts by contract Ids', e);
            setErrorGettingRecords('Error getting contracts');
        }
    };

    return {
        getContracts,
        clearErrorMessage,
        contractsMap,
        errorGettingRecords,
    };
}
