import React, { useState, useEffect, useContext } from 'react';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import { Location } from 'history';
import PageSelections from 'components/common/page-selections';
import PageSelectionOption, {
    PageSelectionOptionProps,
} from 'components/common/page-selection-option';
import { AuthContext } from 'contexts/auth-context';
import StaffingClient, {
    IStaffingProjectResponse,
    IStaffingOrganizationResponse,
} from 'clients/staffing-client';
import { staffingPageBreadcrumbs } from 'components/staffing/staffing-breadcrumbs';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { StaffingAdminRoles, StaffingRoles } from 'configs/roles';
import CheckRole from 'components/common/check-role';
import { AccessDeniedURL } from 'assets/constants/global-constants';
import { OrgPermissionType } from 'components/staffing/staffing-page-types';
import { Dictionary } from 'assets/constants/global-constants';
import { determineOrgsInfo } from 'components/staffing/staffing-utils';

export interface StaffingPageProps {
    pageName: string;
    location: Location;
    userType?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function StaffingLandingPage(props: StaffingPageProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const [projects, setProjects] = useState<IStaffingProjectResponse[]>([]);
    const [organizations, setOrganizations] = useState<IStaffingOrganizationResponse[]>([]);
    const [orgPermissions, setOrgPermissions] = useState<Dictionary<OrgPermissionType>>({});

    useEffect(() => {
        StaffingClient.getProjects(authContext).then((projects) => {
            setProjects(projects);
        });
        breadCrumbContext.setBreadCrumbs([...staffingPageBreadcrumbs]);
    }, []);

    useEffect(() => {
        const go = async (): Promise<void> => {
            try {
                const {
                    accessibleOrgNames,
                    orgPermissions: orgPermissionsVar,
                    accessibleOrgs,
                } = await determineOrgsInfo(authContext);
                setOrganizations(accessibleOrgs);
                setOrgPermissions(orgPermissionsVar);
            } catch (e) {
                console.error(e);
                setOrganizations([]);
                setOrgPermissions({});
            }
        };
        go();
    }, [projects]);

    const organizationTablesProps: PageSelectionOptionProps = {
        title: 'View Allocation Status',
        description: 'View personnel allocation of all organizations',
        selections: [
            {
                linkDisplayText: 'Go',
                link: '/staffing/status',
            },
        ],
    };

    const organizationAllocationProps = (): PageSelectionOptionProps => {
        return {
            title: 'Edit Allocations',
            description: 'Edit personnel allocations',
            dropdownPlaceholder: 'Select An Organization',
            selections:
                /**
                 * Example:
                 *   [
                 *       {
                 *           link: '/staffing/org/global',
                 *           linkDisplayText: 'Global',
                 *       },
                 *       {
                 *           link: '/staffing/org/next organization',
                 *           linkDisplayText: 'Next Organization',
                 *       },
                 *   ]
                 */
                organizations
                    .filter((organization) =>
                        Object.values(orgPermissions[organization.id] || {}).some(
                            (permission) => permission,
                        ),
                    )
                    .sort((i1, i2) => i1.id?.localeCompare(i2.id))
                    .map((organization: IStaffingOrganizationResponse) => {
                        const link = `/staffing/org/${StaffingClient.encodeOrgNameToUrlAcceptableFormat(
                            organization.name,
                        )}`;
                        return {
                            link,
                            linkDisplayText: organization.name,
                        };
                    }),
        };
    };

    return (
        <CheckRole requiredRolesAny={StaffingRoles} redirectNotInRole={AccessDeniedURL}>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <PageSelections>
                <CheckRole requiredRolesAny={StaffingAdminRoles}>
                    <PageSelectionOption {...organizationTablesProps} />
                </CheckRole>
                <PageSelectionOption {...organizationAllocationProps()}></PageSelectionOption>
            </PageSelections>
        </CheckRole>
    );
}
