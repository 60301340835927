import {
    CommandBar,
    ICommandBarItemProps,
    MessageBar,
    MessageBarType,
    Spinner,
    VerticalDivider,
} from '@fluentui/react';
import { Dictionary, IconNames } from 'assets/constants/global-constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AttributeRuleExpression from 'components/groups/manage-group/policy/builder/attribute-rule-expression';
import { IGetVisibleAttributeResult } from 'personnel-core-clients';
import { IPrincipalAttributeExpression, LogicalOperatorType } from 'clients/group-client';
import AttributeRuleExpressionGroup from 'components/groups/manage-group/policy/builder/attribute-rule-expression-group';
import { CoreAttributesClient } from 'clients/core/personnel-core-client-wrappers';
import { useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import HiddenCondition, {
    showValidCondition,
} from 'components/groups/manage-group/policy/builder/hidden-condition';
import { GroupRole } from 'clients/group-client';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';

const MAX_EXPRESSIONS = 25;
const MAX_EXPRESSION_DEPTH = 4;

export enum GlobalCollapseState {
    Collapsed,
    Expanded,
}

export interface AttributePolicyBuilderProps {
    expressions: IPrincipalAttributeExpression[];
    onExpressionsChanged: (expressions: IPrincipalAttributeExpression[]) => void;
}

export default function AttributePolicyBuilder(props: AttributePolicyBuilderProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();

    const [visibleAttributes, setVisibleAttributes] = useState<IGetVisibleAttributeResult[]>([]);
    const [selectedExpressions, setSelectedExpressions] = useState<string[]>([]);
    const [globalCollapseState, setGlobalCollapseState] = useState<GlobalCollapseState | null>(
        null,
    );

    useEffect(() => {
        const fetchVisibleAttributes = async (): Promise<void> => {
            const attributesClient = new CoreAttributesClient(authContext);

            try {
                const visibleAttributes = await attributesClient.getVisible();
                setVisibleAttributes(visibleAttributes);
            } catch (e) {
                setError('Error occurred loading policy');
            } finally {
                setIsLoading(false);
            }
        };

        fetchVisibleAttributes();
    }, [authContext]);

    const expressionMetadata: ExpressionMetadataDictionary = useMemo(() => {
        return new ExpressionMetadataDictionary(props.expressions);
    }, [props.expressions]);

    const onExpressionChanged = useCallback(
        (ex: IPrincipalAttributeExpression) => {
            const newExpressions = modifyExpression([...props.expressions], ex);

            props.onExpressionsChanged(newExpressions);
        },
        [props.onExpressionsChanged, props.expressions],
    );

    const onSelectionChanged = useCallback((id: string, checked: boolean) => {
        if (checked) {
            setSelectedExpressions((prev) => [...new Set([...prev, id])]);
        } else {
            setSelectedExpressions((prev) => prev.filter((x) => x !== id));
        }
    }, []);

    const onAddClicked = (): void => {
        const selectedId = selectedExpressions.length === 1 ? selectedExpressions[0] : undefined;
        const modifiedExpressions = addNewExpressionAfterSelected(
            [...props.expressions],
            selectedId,
        );

        props.onExpressionsChanged(normalizeExpressions(modifiedExpressions));
    };

    const onDeleteClicked = (): void => {
        const modifiedExpressions = removeExpressions([...props.expressions], selectedExpressions);

        props.onExpressionsChanged(normalizeExpressions(modifiedExpressions));

        setSelectedExpressions([]);
    };

    const onGroupClicked = (): void => {
        if (!expressionMetadata.areGroupable(selectedExpressions)) {
            return;
        }

        const modifiedExpressions = groupExpressions([...props.expressions], selectedExpressions);

        props.onExpressionsChanged(normalizeExpressions(modifiedExpressions));

        setSelectedExpressions([]);
    };

    const onUngroupClicked = (): void => {
        if (selectedExpressions.length !== 1) {
            return;
        }

        const modifiedExpressions = ungroupExpression(
            [...props.expressions],
            selectedExpressions[0],
        );

        props.onExpressionsChanged(normalizeExpressions(modifiedExpressions));

        setSelectedExpressions([]);
    };

    const onMoveUpClicked = (): void => {
        if (
            selectedExpressions.length !== 1 ||
            expressionMetadata.getPosition(selectedExpressions[0]) <= 0
        ) {
            return;
        }

        const modifiedExpressions = moveUpExpression(
            [...props.expressions],
            selectedExpressions[0],
        );

        props.onExpressionsChanged(normalizeExpressions(modifiedExpressions));

        setSelectedExpressions([...selectedExpressions]);
    };

    const onMoveDownClicked = (): void => {
        if (
            selectedExpressions.length !== 1 ||
            expressionMetadata.getPosition(selectedExpressions[0]) ===
                expressionMetadata.getCurrentLevelExpressions(selectedExpressions[0]) - 1
        ) {
            return;
        }

        const modifiedExpressions = moveDownExpression(
            [...props.expressions],
            selectedExpressions[0],
        );

        props.onExpressionsChanged(normalizeExpressions(modifiedExpressions));

        setSelectedExpressions([...selectedExpressions]);
    };

    const commandBarLeftItems = (): ICommandBarItemProps[] => {
        return [
            {
                key: 'add',
                text: 'Add Rule',
                iconProps: { iconName: IconNames.Add },
                disabled:
                    selectedExpressions.length > 1 ||
                    expressionMetadata.getCount() >= MAX_EXPRESSIONS,
                onClick: onAddClicked,
            },
            {
                key: 'delete',
                text: 'Delete',
                iconProps: { iconName: IconNames.Trash },
                disabled: selectedExpressions.length !== 1,
                onClick: onDeleteClicked,
            },
            {
                key: 'divider1',
                onRender: () => (
                    <VerticalDivider
                        styles={{
                            wrapper: { margin: '14px 24px 0px 24px', height: '16px' },
                            divider: { color: '#E0E0E0' },
                        }}
                    />
                ),
            },
            {
                key: 'group',
                text: 'Group',
                iconProps: { iconName: IconNames.PageList },
                disabled:
                    selectedExpressions.length <= 1 ||
                    !expressionMetadata.areGroupable(selectedExpressions),
                onClick: onGroupClicked,
            },
            {
                key: 'ungroup',
                text: 'Ungroup',
                iconProps: { iconName: IconNames.BulletedList2 },
                disabled:
                    selectedExpressions.length !== 1 ||
                    !expressionMetadata.isGroup(selectedExpressions[0]),
                onClick: onUngroupClicked,
            },
            {
                key: 'divider2',
                onRender: () => (
                    <VerticalDivider
                        styles={{
                            wrapper: { margin: '14px 24px 0px 24px', height: '16px' },
                            divider: { color: '#E0E0E0' },
                        }}
                    />
                ),
            },
            {
                key: 'moveup',
                text: 'Move up',
                iconProps: { iconName: IconNames.SortUp },
                disabled:
                    selectedExpressions.length !== 1 ||
                    expressionMetadata.getPosition(selectedExpressions[0]) <= 0,
                onClick: onMoveUpClicked,
            },
            {
                key: 'movedown',
                text: 'Move down',
                iconProps: { iconName: IconNames.SortDown },
                disabled:
                    selectedExpressions.length !== 1 ||
                    expressionMetadata.getPosition(selectedExpressions[0]) ===
                        expressionMetadata.getCurrentLevelExpressions(selectedExpressions[0]) - 1,
                onClick: onMoveDownClicked,
            },
            {
                key: 'divider3',
                onRender: () => (
                    <VerticalDivider
                        styles={{
                            wrapper: { margin: '14px 24px 0px 24px', height: '16px' },
                            divider: { color: '#E0E0E0' },
                        }}
                    />
                ),
            },
            {
                key: 'closeall',
                text: 'Close all',
                iconProps: { iconName: IconNames.ChevronRight },
                onClick: () => setGlobalCollapseState(GlobalCollapseState.Collapsed),
            },
            {
                key: 'openall',
                text: 'Open all',
                iconProps: { iconName: IconNames.ChevronDown },
                onClick: () => setGlobalCollapseState(GlobalCollapseState.Expanded),
            },
        ];
    };

    return (
        <div>
            <CommandBar
                style={{ margin: 'auto', marginBottom: '5px', borderBottom: '1px solid #E0E0E0' }}
                items={commandBarLeftItems()}
            />
            {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
            {isLoading ? (
                <div>
                    <Spinner label='Loading...' ariaLive='assertive' labelPosition='right' />
                </div>
            ) : (
                <div>
                    {props.expressions.map((ex) => {
                        if (ex.children) {
                            return (
                                <AttributeRuleExpressionGroup
                                    key={ex.id}
                                    attributes={visibleAttributes}
                                    expression={ex}
                                    globalCollapseState={globalCollapseState}
                                    expressionMetadata={expressionMetadata}
                                    onCheckboxChange={onSelectionChanged}
                                    onExpressionChange={(newExpression): void =>
                                        onExpressionChanged(newExpression)
                                    }
                                    onCollapseToggle={(): void => setGlobalCollapseState(null)}
                                />
                            );
                        } else {
                            if (
                                groupContext.groupMembershipVar?.value?.role === GroupRole.ADMIN ||
                                showValidCondition(ex)
                            ) {
                                return (
                                    <AttributeRuleExpression
                                        key={ex.id}
                                        ruleName={String(
                                            expressionMetadata.getExpressionRuleNumber(ex.id),
                                        )}
                                        expression={ex}
                                        attributes={visibleAttributes}
                                        globalCollapseState={globalCollapseState}
                                        onCheckboxChange={(checked): void =>
                                            onSelectionChanged(ex.id, checked)
                                        }
                                        onExpressionChange={(newExpression): void =>
                                            onExpressionChanged(newExpression)
                                        }
                                        onCollapseToggle={(): void => setGlobalCollapseState(null)}
                                    />
                                );
                            } else {
                                return (
                                    <HiddenCondition
                                        ruleNumber={String(
                                            expressionMetadata.getExpressionRuleNumber(ex.id),
                                        )}
                                        key={
                                            'hidden_' +
                                            String(
                                                expressionMetadata.getExpressionRuleNumber(ex.id),
                                            )
                                        }
                                    />
                                );
                            }
                        }
                    })}
                </div>
            )}
        </div>
    );
}

function modifyExpression(
    expressions: IPrincipalAttributeExpression[],
    expressionToModify: IPrincipalAttributeExpression,
): IPrincipalAttributeExpression[] {
    for (let i = 0; i < expressions.length; i++) {
        const expression = expressions[i];

        if (expression.id === expressionToModify.id) {
            expressions[i] = expressionToModify;
        }

        if (expression.children) {
            expression.children = modifyExpression(expression.children, expressionToModify);
        }
    }

    return expressions;
}

function normalizeExpressions(
    expressions: IPrincipalAttributeExpression[],
): IPrincipalAttributeExpression[] {
    for (let i = 0; i < expressions.length; i++) {
        const expression = expressions[i];

        if (i === 0) {
            expression.op = undefined;
        } else if (!expression.op) {
            expression.op = LogicalOperatorType.And;
        }

        if (expression.children) {
            expression.children = normalizeExpressions(expression.children);
        }
    }

    return expressions;
}

function addNewExpressionAfterSelected(
    expressions: IPrincipalAttributeExpression[],
    selectedId?: string,
): IPrincipalAttributeExpression[] {
    if (!selectedId) {
        return [...expressions, { id: crypto.randomUUID(), op: LogicalOperatorType.And }];
    }

    for (let i = 0; i < expressions.length; i++) {
        if (expressions[i].id === selectedId) {
            expressions.splice(i + 1, 0, {
                id: crypto.randomUUID(),
                op: LogicalOperatorType.And,
            });
        } else if (expressions[i].children) {
            expressions[i].children = addNewExpressionAfterSelected(
                expressions[i].children!,
                selectedId,
            );
        }
    }
    return expressions; // return unmodified if not found
}

/**
 * Moves up selected expression.
 * If selected expression is not the first expression in group then it will move up.
 *
 * @param expressions - expressions
 * @param idToMoveUp - selected expressions id to move up
 * @returns modified expressions and boolean indicating if expression was moved up
 */
function moveUpExpression(
    expressions: IPrincipalAttributeExpression[],
    idToMoveUp: string,
): IPrincipalAttributeExpression[] {
    for (let i = 0; i < expressions.length; i++) {
        const expression = expressions[i];

        if (expression.id === idToMoveUp) {
            if (i === 0) {
                // if first expression in expressions, do nothing.
                return expressions;
            } else {
                [expressions[i - 1], expressions[i]] = [expressions[i], expressions[i - 1]];
                return expressions;
            }
        } else if ((expressions[i].children?.length ?? 0) > 1) {
            const childrenExpressions = moveUpExpression(
                expressions[i].children as IPrincipalAttributeExpression[],
                idToMoveUp,
            );
            expressions[i].children = childrenExpressions;
        }
    }

    return expressions;
}

/**
 * Moves down selected expression.
 * If selected expression is not the last expression in group then it will move down.
 *
 * @param expressions - expressions
 * @param idToMoveDown - selected expressions id to move down
 * @returns modified expressions and boolean indicating if expression was moved down
 */
function moveDownExpression(
    expressions: IPrincipalAttributeExpression[],
    idToMoveDown: string,
): IPrincipalAttributeExpression[] {
    for (let i = 0; i < expressions.length; i++) {
        const expression = expressions[i];

        if (expression.id === idToMoveDown) {
            if (i === expressions.length - 1) {
                return expressions;
            } else {
                [expressions[i], expressions[i + 1]] = [expressions[i + 1], expressions[i]];
                return expressions;
            }
        } else if ((expressions[i].children?.length ?? 0) > 1) {
            const childrenExpressions = moveDownExpression(
                expressions[i].children as IPrincipalAttributeExpression[],
                idToMoveDown,
            );

            expressions[i].children = childrenExpressions;
        }
    }

    return expressions;
}

function removeExpressions(
    expressions: IPrincipalAttributeExpression[],
    idsToRemove: string[],
): IPrincipalAttributeExpression[] {
    for (let i = 0; i < expressions.length; i++) {
        if (idsToRemove.includes(expressions[i].id)) {
            expressions.splice(i, 1);
        } else if (expressions[i].children) {
            expressions[i].children = removeExpressions(expressions[i].children!, idsToRemove);

            // If children is empty then remove this expression
            if (expressions[i].children?.length === 0) {
                expressions.splice(i, 1);
            }
        }
    }
    return expressions;
}

function groupExpressions(
    expressions: IPrincipalAttributeExpression[],
    idsToGroup: string[],
): IPrincipalAttributeExpression[] {
    const groupedExpressions: IPrincipalAttributeExpression[] = [];
    const remainingExpressions: IPrincipalAttributeExpression[] = [];
    let indexToAddGroup = -1;

    for (let i = 0; i < expressions.length; i++) {
        const expression = expressions[i];

        if (idsToGroup.includes(expression.id)) {
            groupedExpressions.push(expression);
            if (indexToAddGroup === -1) {
                indexToAddGroup = i;
            }
        } else {
            if (expression.children) {
                expression.children = groupExpressions(expression.children, idsToGroup);
            }
            remainingExpressions.push(expression);
        }
    }

    if (groupedExpressions.length > 0) {
        const groupedExpression: IPrincipalAttributeExpression = {
            id: crypto.randomUUID(),
            children: groupedExpressions,
        };

        remainingExpressions.splice(indexToAddGroup, 0, groupedExpression);
    }

    return remainingExpressions;
}

function ungroupExpression(
    expressions: IPrincipalAttributeExpression[],
    idToUngroup: string,
): IPrincipalAttributeExpression[] {
    for (let i = 0; i < expressions.length; i++) {
        const expression = expressions[i];

        if (expression.id === idToUngroup && expression.children) {
            expressions.splice(i, 1, ...expression.children);
        } else if (expression.children) {
            expressions[i].children = ungroupExpression(expression.children, idToUngroup);
        }
    }
    return expressions;
}

export type ExpressionMetadata = {
    expression: IPrincipalAttributeExpression;
    isGroup: boolean;
    level: number;
    position: number;
    numExpressionsOnLevel: number;
};

export class ExpressionMetadataDictionary {
    private _metadataArr: ExpressionMetadata[] = [];
    private _metadataDict: Dictionary<ExpressionMetadata> = {};
    private _count: number;

    constructor(expressions: IPrincipalAttributeExpression[]) {
        this._metadataArr = this._buildMetadata(expressions);

        for (const metadata of this._metadataArr) {
            this._metadataDict[metadata.expression.id] = metadata;
        }

        this._count = Object.keys(this._metadataDict).length;
    }

    get(id: string): ExpressionMetadata | undefined {
        return this._metadataDict[id];
    }

    isGroup(id: string): boolean {
        return this.get(id)?.isGroup ?? false;
    }

    getLevel(id: string): number {
        return this.get(id)?.level ?? -1;
    }

    areGroupable(ids: string[]): boolean {
        if (ids.length <= 1) {
            return false;
        }

        const isAnyGroupedExpressions = ids.some((x) => this.isGroup(x));

        if (isAnyGroupedExpressions) {
            return false;
        }

        const firstExpressionLevel = this.getLevel(ids[0]);
        const isAllOnSameLevel = ids.every((x) => this.getLevel(x) === firstExpressionLevel);

        return isAllOnSameLevel && firstExpressionLevel < MAX_EXPRESSION_DEPTH;
    }

    getPosition(id: string): number {
        return this.get(id)?.position ?? -1;
    }

    getCurrentLevelExpressions(id: string): number {
        return this.get(id)?.numExpressionsOnLevel ?? -1;
    }

    getCount(): number {
        return this._count;
    }

    getExpressionRuleNumber(id: string): number {
        return (
            this._metadataArr.filter((x) => !x.isGroup).findIndex((x) => x.expression.id === id) + 1
        );
    }

    private _buildMetadata(
        expressions: IPrincipalAttributeExpression[],
        level = 0,
    ): ExpressionMetadata[] {
        const results: ExpressionMetadata[] = [];
        let position = 0;

        for (const expression of expressions) {
            if (!expression.children) {
                results.push({
                    expression: expression,
                    isGroup: false,
                    level: level,
                    position: position++,
                    numExpressionsOnLevel: expressions.length,
                });
            } else {
                results.push({
                    expression: expression,
                    isGroup: true,
                    level: level,
                    position: position++,
                    numExpressionsOnLevel: expressions.length,
                });
                results.push(...this._buildMetadata(expression.children, level + 1));
            }
        }

        return results;
    }
}
