import { AttributeCsaSyncStatus, AttributeDataType } from 'personnel-core-clients';

export function convertValues(
    values: any,
    type: AttributeDataType | undefined,
    isArray: boolean,
): any {
    if (isArray) {
        const convertedVals = values.map((x: string) =>
            convertValue(x, type ?? AttributeDataType.String),
        );
        return convertedVals;
    } else {
        return convertValue(values.toString(), type!);
    }
}

export function convertValue(val: string, type: AttributeDataType): any {
    switch (type) {
        case AttributeDataType.Boolean:
            return val.toLowerCase() === 'true';
        case AttributeDataType.Integer:
            return parseInt(val);
        default:
            return val;
    }
}

export function isCsaSyncEnabled(status: AttributeCsaSyncStatus): boolean {
    return !(status === AttributeCsaSyncStatus.NONE || status === AttributeCsaSyncStatus.DISABLED);
}
