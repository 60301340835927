import React, { useCallback, useContext, useState } from 'react';
import Modal, { ModalSizeType } from 'components/common/modal';
import { IFileInfo } from 'components/common/buttons/upload-csv-button';
import { MessageBar, MessageBarType } from '@fluentui/react';
import Spacer from 'components/common/spacer';
import pluralize from 'pluralize';
import { AuthContext } from 'contexts/auth-context';
import { convertCSVToArray } from 'utils/string-utils';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { replaceTemplatedTimeStringInDownloadReport } from 'utils/reporting-utils';
import { ChooseFileButton } from 'components/common/buttons/choose-file-button';
import {
    AdjudicatedDecision,
    ClearanceLevelType,
    ScreeningParentStateType,
    ScreeningRequestTypes,
    ScreeningStateType,
} from 'components/screening/common/common-constants';
import UsGovScreeningClient, {
    IScreeningRecordUploadRequest,
} from 'clients/screening/us-gov-screening-client';
import {
    UPLOAD_STATUS_COLUMN_NAME,
    UploadColumnParams,
    BulkUploadResult,
    checkUploadFileData,
    convertDateStringsToLocaleDateString,
} from 'utils/upload-utils';
import { dateStringToIsoString, validateDateString } from 'utils/time-utils';

type UsGovBulkUploadModalPropsType = {
    hideUploadModal: () => void;
};

type MessagesType = {
    infoMessage?: string;
    errorMessage?: string;
    successMessage?: string;
};

enum DateHeaders {
    adjudicatedDate = 'Adjudicated date',
    preHireStartDate = 'Pre hire start date',
}

const UsGovUploadColumnParams: UploadColumnParams<IScreeningRecordUploadRequest>[] = [
    {
        name: 'microsoftEmployeeOrVendor',
        header: 'Microsoft employee or vendor',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            ['true', 'false', 'yes', 'no'].includes(row.microsoftEmployeeOrVendor.toLowerCase()),
    },
    { name: 'id', header: 'Id', isColumnRequired: false, isValid: () => true },
    {
        name: 'personnelId',
        header: 'Personnel id',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest): boolean => {
            return !!row.personnelId;
        },
    },
    {
        name: 'microsoftAlias',
        header: 'Microsoft alias',
        isColumnRequired: true,
        isValid: () => true,
    },
    { name: 'firstName', header: 'First name', isColumnRequired: true, isValid: () => true },
    { name: 'lastName', header: 'Last name', isColumnRequired: true, isValid: () => true },
    {
        name: 'governmentContractNumber',
        header: 'Government contract number',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) => !!row.governmentContractNumber,
    },
    {
        name: 'requestType',
        header: 'Request type',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            (Object.values(ScreeningRequestTypes) as string[]).includes(row.requestType as string),
    },
    {
        name: 'clearanceLevel',
        header: 'Clearance level',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            (Object.values(ClearanceLevelType) as string[]).includes(row.clearanceLevel as string),
    },
    {
        name: 'specialAccess',
        header: 'Special access',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest): boolean => {
            const accessTypeRegex = new RegExp('^(SI|TK|KLM|G|HCS)(,(SI|TK|KLM|G|HCS))*$');
            return row.clearanceLevel === ClearanceLevelType.SCI
                ? accessTypeRegex.test(row.specialAccess as string)
                : !row.specialAccess;
        },
    },
    {
        name: 'customerBadgeRequired',
        header: 'Customer badge required',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest): boolean => {
            return ['true', 'false', 'yes', 'no'].includes(row.customerBadgeRequired.toLowerCase());
        },
    },
    {
        name: 'processOwner',
        header: 'Process owner',
        isColumnRequired: true,
        isValid: () => true,
    },
    {
        name: 'screeningStatus',
        header: 'Screening status',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            (Object.values(ScreeningParentStateType) as string[]).includes(
                row.screeningStatus as string,
            ),
    },
    {
        name: 'screeningSubStatus',
        header: 'Screening sub-status',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            (Object.values(ScreeningStateType) as string[]).includes(
                row.screeningSubStatus as string,
            ),
    },
    {
        name: 'adjudicatedDecision',
        header: 'Adjudicated decision',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            row.screeningStatus === ScreeningParentStateType.Adjudicated
                ? (Object.values(AdjudicatedDecision) as string[]).includes(row.adjudicatedDecision)
                : !row.adjudicatedDecision,
    },
    {
        name: 'adjudicatedDate',
        header: DateHeaders.adjudicatedDate,
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            validateDateString(row.adjudicatedDate, true),
        transform: (value: string): string => dateStringToIsoString(value),
    },
    {
        name: 'businessJustification',
        header: 'Business justification',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) => !!row.businessJustification,
    },
    { name: 'eQIP', header: 'EQIP', isColumnRequired: true, isValid: () => true },
    {
        name: 'preHireFirstName',
        header: 'Pre hire first name',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            // If the candidate is a Microsoft employee or vendor, pre-hire first name should not be specified.
            ['false', 'no'].includes(row.microsoftEmployeeOrVendor.toLowerCase()) ||
            !row.preHireFirstName.trim(),
    },
    {
        name: 'preHireMiddleName',
        header: 'Pre hire middle name',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            // If the candidate is a Microsoft employee or vendor, pre-hire middle name should not be specified.
            ['false', 'no'].includes(row.microsoftEmployeeOrVendor.toLowerCase()) ||
            !row.preHireMiddleName.trim(),
    },
    {
        name: 'preHireLastName',
        header: 'Pre hire last name',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            // If the candidate is a Microsoft employee or vendor, pre-hire last name should not be specified.
            ['false', 'no'].includes(row.microsoftEmployeeOrVendor.toLowerCase()) ||
            !row.preHireLastName.trim(),
    },
    {
        name: 'preHireSuffix',
        header: 'Pre hire suffix',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            // If the candidate is a Microsoft employee or vendor, pre-hire suffix should not be specified.
            ['false', 'no'].includes(row.microsoftEmployeeOrVendor.toLowerCase()) ||
            !row.preHireSuffix.trim(),
    },
    {
        name: 'preHirePcn',
        header: 'Pre hire pcn',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            // If the candidate is a Microsoft employee or vendor, pre-hire PCN should not be specified.
            ['false', 'no'].includes(row.microsoftEmployeeOrVendor.toLowerCase()) ||
            !row.preHirePcn.trim(),
    },
    {
        name: 'preHireManager',
        header: 'Pre hire manager',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            // If the candidate is a Microsoft employee or vendor, pre-hire manager should not be specified.
            ['false', 'no'].includes(row.microsoftEmployeeOrVendor.toLowerCase()) ||
            !row.preHireManager.trim(),
    },
    {
        name: 'preHireJobTitle',
        header: 'Pre hire job title',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            // If the candidate is a Microsoft employee or vendor, pre-hire job title should not be specified.
            ['false', 'no'].includes(row.microsoftEmployeeOrVendor.toLowerCase()) ||
            !row.preHireJobTitle.trim(),
    },
    {
        name: 'preHireStartDate',
        header: DateHeaders.preHireStartDate,
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            // If the candidate is a Microsoft employee or vendor, pre-hire start date should not be specified.
            ['true', 'yes'].includes(row.microsoftEmployeeOrVendor.toLowerCase())
                ? !row.preHireStartDate.trim()
                : validateDateString(row.preHireStartDate, true),
        transform: (value: string): string => dateStringToIsoString(value),
    },
    {
        name: 'preHirePersonalPhoneNumber',
        header: 'Pre hire personal phone number',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            // If the candidate is a Microsoft employee or vendor, pre-hire personnal phone number should not be specified.
            ['false', 'no'].includes(row.microsoftEmployeeOrVendor.toLowerCase()) ||
            !row.preHirePersonalPhoneNumber.trim(),
    },
    {
        name: 'preHirePersonalEmailAddress',
        header: 'Pre hire personal email address',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            // If the candidate is a Microsoft employee or vendor, pre-hire personnal email address should not be specified.
            ['false', 'no'].includes(row.microsoftEmployeeOrVendor.toLowerCase()) ||
            !row.preHirePersonalEmailAddress.trim(),
    },
    {
        name: 'preHireHomeAddress',
        header: 'Pre hire home address',
        isColumnRequired: true,
        isValid: (row: IScreeningRecordUploadRequest) =>
            // If the candidate is a Microsoft employee or vendor, pre-hire home address should not be specified.
            ['false', 'no'].includes(row.microsoftEmployeeOrVendor.toLowerCase()) ||
            !row.preHireHomeAddress.trim(),
    },
    {
        name: 'uploadStatus',
        header: 'Upload status',
        isColumnRequired: false,
        isValid: (row: IScreeningRecordUploadRequest) =>
            // Should not use !row.uploadStatus because this column a string and could be empty.
            // And the API endpoint cannot convert an empty string to the corresponding enum,
            // returning a 400 error.
            // The following checks for null or undefined, thus allowing a user to NOT specify the column in the
            // CSV file. But if the column is specified, the value must be one of "Success", "Failed", or "Skipped".
            row.uploadStatus === undefined ||
            row.uploadStatus === null ||
            ['success', 'failed', 'skipped'].includes(row.uploadStatus?.toLowerCase() ?? ''),
    },
    {
        name: 'uploadComment',
        header: 'Upload comment',
        isColumnRequired: false,
        isValid: () => true,
    },
];

export default function UsGovBulkUploadModal(props: UsGovBulkUploadModalPropsType): JSX.Element {
    const authContext = useContext(AuthContext);

    const [messages, setMessages] = useState<MessagesType>({});
    const [requestData, setRequestData] = useState<IScreeningRecordUploadRequest[]>([]);
    const [isReadyToSubmit, setIsReadyToSubmit] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [showDownloadButton, setShowDownloadButton] = useState<boolean>(false);
    const [uploadResult, setUploadResult] = useState<BulkUploadResult[]>([]);

    const initialize = (): void => {
        setMessages({});
        setIsReadyToSubmit(false);
    };

    const onFileLoadClick = (): void => {
        initialize();
    };

    function onFileLoaded(fileContents: any[], fileInfo: IFileInfo): void {
        const [row1, ...otherRows] = fileContents;
        const csvFileColumnHeaders = row1 as string[];
        const data = otherRows as string[][];
        data.pop(); // remove the last row because it's empty.

        const {
            isDataItemsValid,
            columnIndexesResult: columnIndexes,
            errorMessage,
            badRowIx,
            badColumnHeader,
            requestData: requestDataVar,
        } = checkUploadFileData(
            data,
            UsGovUploadColumnParams,
            csvFileColumnHeaders,
            UsGovUploadColumnParams,
        );

        if (!isDataItemsValid) {
            if (badRowIx === -1) {
                setMessages({ errorMessage });
            } else {
                // badRowIx + 2 because rowIx is zero-based and we need to account for the header row.
                setMessages({
                    errorMessage: `Invalid data item in row ${
                        badRowIx + 2
                    } column "${badColumnHeader}".`,
                });
            }
            return;
        }

        const len = requestDataVar.length;
        setMessages({
            infoMessage: `Read ${len} ${pluralize('row', len)} of data, ready to upload.`,
        });
        setRequestData(requestDataVar);
        setIsReadyToSubmit(true);
    }

    const onSubmit = async (): Promise<void> => {
        if (hasSubmitted) {
            props.hideUploadModal();
            return;
        }
        setMessages({});
        try {
            setIsSubmitting(true);
            const submitResult = await UsGovScreeningClient.uploadScreeningRecords(
                authContext,
                requestData,
            );
            const csvReplaced = replaceTemplatedTimeStringInDownloadReport(submitResult ?? '');
            const result = convertCSVToArray<BulkUploadResult>(csvReplaced);
            convertDateStringsToLocaleDateString(result, Object.values(DateHeaders));
            setUploadResult(result);
            // Determine number of successful, skipped, and failed records.
            if (result.length === 0) {
                setMessages({
                    successMessage:
                        'All records had already been uploaded in a previous upload attempt.',
                });
            } else if (
                result.every((r) => {
                    return r[UPLOAD_STATUS_COLUMN_NAME] === 'Success';
                })
            ) {
                setMessages({
                    successMessage: `${result.length} ${pluralize(
                        'record',
                        result.length,
                    )} uploaded successfully`,
                });
            } else {
                const successCount = result.filter(
                    (r) => r[UPLOAD_STATUS_COLUMN_NAME] === 'Success',
                ).length;
                const skipCount = result.filter((r) => r[UPLOAD_STATUS_COLUMN_NAME] === 'Skipped')
                    .length;
                const failureCount = result.filter((r) => r[UPLOAD_STATUS_COLUMN_NAME] === 'Failed')
                    .length;
                setMessages(() => {
                    const messages = {
                        successMessage: '',
                        errorMessage: '',
                    };
                    if (successCount > 0) {
                        messages.successMessage = `${successCount} ${pluralize(
                            'record',
                            successCount,
                        )} uploaded successfully. `;
                    }
                    if (skipCount > 0) {
                        messages.successMessage += `${skipCount} ${pluralize(
                            'record',
                            skipCount,
                        )} skipped because they were marked "Success" or "Skipped" in the upload file.`;
                    }
                    if (failureCount > 0) {
                        messages.errorMessage = `${failureCount} ${pluralize(
                            'record',
                            failureCount,
                        )} failed to upload.`;
                    }
                    return messages;
                });
            }
            setShowDownloadButton(true);
        } catch (error) {
            setMessages({ errorMessage: `Error submitting bulk upload: ${error}` });
        } finally {
            setIsSubmitting(false);
            setHasSubmitted(true);
        }
    };

    const getUploadResult = useCallback(async (): Promise<BulkUploadResult[]> => {
        return uploadResult;
    }, [uploadResult]);

    function onFileOpenError(error: Error): void {
        setMessages({ errorMessage: error.message });
    }

    return (
        <Modal
            isOpen={true}
            size={ModalSizeType.size500}
            fixWidth={true}
            title='Upload Screening'
            onCancel={(): void => props.hideUploadModal()}
            isSubmitButtonDisabled={!isReadyToSubmit || isSubmitting}
            showProgressIndicator={isSubmitting}
            submitButtonText={hasSubmitted ? 'Close' : 'Upload'}
            shouldHideCancelButton={hasSubmitted}
            onSubmit={onSubmit}>
            <ChooseFileButton
                disabled={isSubmitting || hasSubmitted}
                acceptedFileTypes={['text/csv']}
                onFileLoaded={onFileLoaded}
                onError={onFileOpenError}
                onClick={onFileLoadClick}
            />
            <div>Upload CSV, max 1 file with 500 rows</div>
            {!!messages.infoMessage && (
                <div>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.info}>
                        <div>{messages.infoMessage}</div>
                    </MessageBar>
                </div>
            )}
            {!!messages.successMessage && (
                <div>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.success}>
                        <div>{messages.successMessage}</div>
                    </MessageBar>
                </div>
            )}
            {!!messages.errorMessage && (
                <div>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.error}>
                        <div>{messages.errorMessage}</div>
                    </MessageBar>
                </div>
            )}
            {showDownloadButton && uploadResult.length > 0 && (
                <>
                    <Spacer marginTop={10} />
                    <ExportToExcelButton<BulkUploadResult>
                        buttonTitle='Download report'
                        buttonType='default'
                        getData={getUploadResult}
                        fileNamePrefix='screening-upload'
                        formatHeader={false}
                    />
                </>
            )}
        </Modal>
    );
}
