import React, { ChangeEvent, FormEvent, useContext, useEffect, useRef, useState } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import { contentMaxHeight } from 'assets/styles/list-styles';
import { AuthContext } from 'contexts/auth-context';
import ActivitiesClient, { IActivityPost, IActivityValue } from 'clients/activities-client';
import {
    ActionButton,
    ContextualMenu,
    DefaultButton,
    Dropdown,
    FontSizes,
    FontWeights,
    IDropdownOption,
    Label,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Modal,
    PrimaryButton,
    Stack,
    TextField,
    TextFieldBase,
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import {
    acceptedFileTypesForTemplate,
    acceptedFileTypesTextForTemplate,
    acceptedFileTypesTextForUpload,
    acceptedMaxFileSize,
    acceptedMaxUploadPerFileText,
    changeFileName,
    fileInputAcceptStringForTemplate,
    isValidFileType,
} from 'utils/file-utils';
import DocumentsClient, {
    DocumentReferenceType,
    IDocumentRecord,
    IDocumentReference,
    IDocumentTypeItem,
} from 'clients/documents-client';
import { UserContext } from 'contexts/user-context';
import PublicTrustDocumentClient from 'clients/screening/public-trust-document-client';
import TemplateClient, {
    AssociationType,
    AssociationValue,
    IScreeningTemplateRequestRecord,
    ScreeningTemplateRequestedState,
} from 'clients/template-client';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import { ApplicationServices } from 'clients/screening/us-gov-screening-client';
export interface DocumentAddProps {
    children?: React.ReactNode;
    screeningId?: string;
    employeeId?: string;
    editableEmployeeId?: string;
    onChangeUploadNote?: React.Dispatch<React.SetStateAction<string>>;
    eventBitWillManuallyUpdate?: () => void;
    associationType?: AssociationType | undefined;
    associationValue?: AssociationValue | undefined;
    updateUploadedDocumentsPanel?: (doc: IDocumentRecord) => void;
    onUploadComplete?(request: IScreeningTemplateRequestRecord): Promise<void>;
    isAdhocScreeningDocument: boolean;
    setDocumentIdWorkaround?: React.Dispatch<React.SetStateAction<string>>;
}

export default function DocumentProfileAddModal(props: DocumentAddProps): JSX.Element {
    const userContext = useContext(UserContext);
    const authContext = useContext(AuthContext);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const titleField = useRef<TextFieldBase>(null);
    const notesField = useRef<TextFieldBase>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileField = useRef<any>(null);
    const [fileName, setFileName] = useState<string>();
    const [isInvalidDocumentType, setIsInvalidDocumentType] = useState<boolean>();
    const [isInvalidFile, setIsInvalidFile] = useState<boolean>();
    const [isEmptyFile, setIsEmptyFile] = useState<boolean>(false);
    const [isInvalidTitle, setIsInvalidTitle] = useState<boolean>();
    const [uploadFailedError, setUploadFailedError] = useState<string | undefined>(undefined);
    const [isUploadError, setIsUploadError] = useState<boolean>(false);
    const [canAddDoc, setCanAddDoc] = useState<boolean>(false);
    const [uploadFile, setUploadFile] = useState<File | undefined>();
    const [documentTypeItemList, setDocumentTypeItemList] = useState<IDropdownOption[]>([]);
    const [selectedDocumentTypeItem, setSelectedDocumentTypeItem] = useState<IDropdownOption>();
    const activityEventName = 'created';
    const activityTag = 'converted';

    useEffect(() => {
        populateDocumentTypesList();
    }, []);

    useEffect(() => {
        setCanAddDoc(
            isInvalidDocumentType === undefined ||
                isInvalidDocumentType ||
                isInvalidTitle === undefined ||
                isInvalidTitle ||
                isInvalidFile === undefined ||
                isInvalidFile,
        );
    }, [isInvalidDocumentType, isInvalidTitle, isInvalidFile]);

    async function populateDocumentTypesList(): Promise<void> {
        const docTypeItemsList: IDocumentTypeItem[] = await DocumentsClient.getDocumentTypeItems(
            authContext,
            props.associationType?.toString(),
            props.associationValue?.toString(),
        );
        const options: IDropdownOption[] = docTypeItemsList.map((x) => {
            const option: IDropdownOption = {
                key: x.documentType,
                text: x.displayName,
                data: x.documentTypeName,
            };
            return option;
        });
        setDocumentTypeItemList([...options]);
    }

    async function onFileChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        if (event.target.files && event.target.files[0]) {
            const file: File = event.target.files[0];
            const isValid: boolean = await isValidFileType(file, acceptedFileTypesForTemplate);
            setUploadFile(file);
            setFileName(file.name);
            setIsEmptyFile(file.size === 0);
            setIsInvalidFile(!isValid);
        } else {
            setUploadFile(undefined);
            setFileName(undefined);
            setIsInvalidFile(false);
            setIsEmptyFile(false);
        }
    }

    function resetUploadFile(): void {
        setSelectedDocumentTypeItem(undefined);
        setIsInvalidDocumentType(undefined);
        setIsInvalidTitle(undefined);
        setUploadFile(undefined);
        setIsInvalidFile(true);
        setFileName(undefined);
        setUploadFailedError(undefined);
        setIsUploadError(false);
        if (props.onChangeUploadNote) {
            props.onChangeUploadNote(() => '');
        }
    }

    async function upload(): Promise<void> {
        if (uploadFile) {
            try {
                const documentNotes = notesField.current?.value;
                const documentTitle = titleField.current?.value;

                const screeningDocumentRequestRecord: IScreeningTemplateRequestRecord = await createScreeningDocumentRecord(
                    documentTitle,
                );

                const uploadedFile: IDocumentRecord = await performUploadFile(
                    uploadFile,
                    documentNotes,
                    documentTitle,
                );

                if (uploadedFile) {
                    if (
                        props.eventBitWillManuallyUpdate &&
                        props.screeningId &&
                        (props.associationValue === AssociationValue.PublicTrust ||
                            props.associationValue === AssociationValue.UsGov)
                    ) {
                        const documentReferenceType =
                            props.associationValue === AssociationValue.UsGov
                                ? DocumentReferenceType.ScreeningIdUSGov
                                : DocumentReferenceType.ScreeningIdPublicTrust;

                        const request: IDocumentReference = {
                            referenceId: props.screeningId,
                            application: ApplicationServices.screening,
                            documentId: uploadedFile.id,
                            allowedPermissions: [
                                'ViewMetadata',
                                'Download',
                                'Lock',
                                'ManageLifecycle',
                            ],
                            metadata: {
                                documentRequestId: screeningDocumentRequestRecord?.id,
                                documentReferenceType: `${DocumentReferenceType[documentReferenceType]}`,
                                documentServiceAuthorPersonnelId: uploadedFile.authorPersonnelId,
                            },
                        };
                        await DocumentsClient.shareDocument(authContext, request);

                        if (props.updateUploadedDocumentsPanel)
                            props.updateUploadedDocumentsPanel(uploadedFile);

                        props.eventBitWillManuallyUpdate();
                    } else {
                        const request: IDocumentReference = {
                            referenceId: props.employeeId ?? props.editableEmployeeId ?? '',
                            application: ApplicationServices.profile,
                            documentId: uploadedFile.id,
                            allowedPermissions: [
                                'ViewMetadata',
                                'Download',
                                'Lock',
                                'ManageLifecycle',
                            ],
                            metadata: {
                                documentRequestId: screeningDocumentRequestRecord?.id,
                                documentReferenceType: `${
                                    DocumentReferenceType[DocumentReferenceType.ScreeningIdUSGov]
                                }`,
                                documentServiceAuthorPersonnelId: uploadedFile.authorPersonnelId,
                            },
                        };
                        await DocumentsClient.shareDocument(authContext, request);
                    }
                    setIsUploadError(false);
                    if (uploadedFile) {
                        writeUploadDocumentActivityWithFeatureFlagEnabled(uploadedFile);
                    }
                }
            } catch (error) {
                setIsUploadError(true);
                setUploadFailedError(error);
            } finally {
                resetUploadFile();
            }
        }
    }

    function writeUploadDocumentActivityWithFeatureFlagEnabled(
        uploadedFile: IDocumentRecord,
    ): void {
        try {
            if (props.screeningId) {
                writeUploadDocumentToScreenings(uploadedFile, props.screeningId);
            } else {
                writeUploadDocumentActivityToProfile(uploadedFile);
            }
        } catch (error) {
            console.error(error);
        }
    }

    function writeUploadDocumentActivityToProfile(uploadedFile: IDocumentRecord): void {
        const activityTag = 'converted';
        const uploadMessage = `${userContext.employeeRecord.fullName} uploaded ${uploadedFile.documentType}`;
        const subjectData: IActivityValue = {
            type: 'personId',
            value: userContext.employeeRecord.id,
        };

        const directObjectData: IActivityValue = {
            type: 'personId',
            value: props.employeeId || '',
        };

        const activityPostData: IActivityPost = {
            event: 'noted',
            eventTimestampUTC: new Date().getTime(),
            referenceId: props.employeeId || '',
            securityIds:
                props.editableEmployeeId && props.employeeId !== props.editableEmployeeId
                    ? ['Admin', props.employeeId || '', props.editableEmployeeId || '']
                    : ['Admin', props.employeeId || ''],
            subject: subjectData,
            directObject: directObjectData,
            additionalObjects: uploadedFile.id
                ? [{ type: 'documentId', value: uploadedFile.id }]
                : undefined,
            message: uploadMessage,
            tags:
                props.editableEmployeeId && props.employeeId !== props.editableEmployeeId
                    ? [activityTag, props.employeeId || '', props.editableEmployeeId || '']
                    : [props.employeeId || ''],
        };

        if (props.employeeId) {
            ActivitiesClient.getProfileActivityToken(authContext, props.employeeId, ['write']).then(
                async (token) => {
                    const response = await ActivitiesClient.postProfileActivity(
                        authContext,
                        props.employeeId ?? '',
                        activityPostData,
                        token,
                    );

                    if (
                        response !== undefined &&
                        response !== null &&
                        response.message !== undefined &&
                        response.message !== null &&
                        props.setDocumentIdWorkaround
                    ) {
                        props.setDocumentIdWorkaround(uploadedFile.id);
                    }
                },
            );
        }
    }

    function writeUploadDocumentToScreenings(
        uploadedFile: IDocumentRecord,
        screeningId: string,
    ): void {
        const uploadMessage = `${userContext.employeeRecord.fullName} uploaded ${uploadedFile.documentType}`;
        const event = 'adhoc-document-uploaded';
        const subjectData: IActivityValue = {
            type: 'personnelId',
            value: userContext.employeeRecord.id,
        };

        const directObjectData: IActivityValue = {
            type: 'documentFileName',
            value: uploadedFile.fileName ?? '',
        };

        const activityPostData: IActivityPost = {
            event,
            eventTimestampUTC: new Date().getTime(),
            referenceId: screeningId,
            appName: 'screeningApp',
            securityIds: ['Admin', screeningId],
            subject: subjectData,
            directObject: directObjectData,
            additionalObjects: uploadedFile.id
                ? [{ type: 'documentId', value: uploadedFile.id }]
                : undefined,
            message: uploadMessage,
            tags: [],
        };

        ActivitiesClient.getScreeningActivityToken(authContext, screeningId, ['write']).then(
            async (token) => {
                const response = await ActivitiesClient.postScreeningActivity(
                    authContext,
                    screeningId,
                    activityPostData,
                    token,
                );

                if (
                    response !== undefined &&
                    response !== null &&
                    response.message !== undefined &&
                    response.message !== null &&
                    props.eventBitWillManuallyUpdate
                ) {
                    props.eventBitWillManuallyUpdate();
                }
            },
        );
    }

    async function createScreeningDocumentRecord(
        documentTitle: string | undefined,
    ): Promise<IScreeningTemplateRequestRecord> {
        try {
            const screeningDocumentRequestRecord: IScreeningTemplateRequestRecord = {
                id: '',
                documentId: '',
                documentType: `${selectedDocumentTypeItem?.key}`,
                documentName: `${selectedDocumentTypeItem?.text}`,
                message: '',
                personnelId: props.employeeId ?? props.editableEmployeeId,
                requestedState: ScreeningTemplateRequestedState.AdHoc,
                screeningId: props.screeningId ?? '',
                title: documentTitle ?? '',
            };

            if (props.associationValue === AssociationValue.PublicTrust) {
                return await PublicTrustDocumentClient.updateDocument(
                    authContext,
                    screeningDocumentRequestRecord,
                );
            } else {
                return await TemplateClient.updateDocument(
                    authContext,
                    screeningDocumentRequestRecord,
                );
            }
        } catch (error) {
            throw 'Error with creating Screening Document Request Records.';
        }
    }

    async function performUploadFile(
        uploadFile: File,
        documentNotes: string | undefined,
        documentTitle: string | undefined,
    ): Promise<IDocumentRecord> {
        try {
            const fileWithChangedName = changeFileName(uploadFile, documentTitle ?? upload.name);

            return await DocumentsClient.uploadDocument(
                authContext,
                fileWithChangedName,
                documentNotes,
                documentTitle,
                `${selectedDocumentTypeItem?.key}`,
                props.screeningId
                    ? props.screeningId
                    : props.employeeId
                    ? props.employeeId
                    : props.editableEmployeeId,
            );
        } catch (error) {
            throw 'Error with uploading file.';
        }
    }

    function onDocumentTypeChange(
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption | undefined,
    ): void {
        if (!!option) {
            setIsInvalidDocumentType(false);
            setSelectedDocumentTypeItem(option);
        } else {
            setIsInvalidDocumentType(true);
            setSelectedDocumentTypeItem(undefined);
        }
    }

    function onTitleChange(
        event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void {
        const title: string | undefined = newValue?.trim();
        if (!title || title.length === 0) setIsInvalidTitle(true);
        else setIsInvalidTitle(false);
    }

    function onCloseClick(): void {
        setModalOpen(false);
        resetUploadFile();
    }

    async function onAddClick(): Promise<void> {
        upload();
        setModalOpen(false);
    }

    function isAddBtnDisabled(): boolean {
        return (userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST) ||
            userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.ContractAdmin)) &&
            userContext.employeeRecord.id !== props.employeeId
            ? false
            : true;
    }

    return (
        <Stack>
            {!isAddBtnDisabled() && (
                <ActionButton
                    onClick={(): void => {
                        setModalOpen(true);
                    }}
                    iconProps={{ iconName: IconNames.AddToShoppingList }}
                    text={'Add'}
                    styles={{ root: { maxHeight: contentMaxHeight } }}
                />
            )}
            <Modal
                isOpen={isModalOpen}
                onDismiss={(): void => setModalOpen(false)}
                isDarkOverlay={false}
                containerClassName={styles.modalContainer}
                dragOptions={{
                    moveMenuItemText: 'Move',
                    closeMenuItemText: 'Close',
                    menu: ContextualMenu,
                }}>
                <div className={styles.root}>
                    <div className={styles.body}>
                        <div className={styles.label}>Add document</div>
                        <div className={styles.content}>
                            <div className={stylesForOtherDocuments.row}>
                                <div className={stylesForOtherDocuments.leftColumn}>
                                    <Label required={true}>Type</Label>
                                </div>
                                <div className={stylesForOtherDocuments.rightColumn}>
                                    <Dropdown
                                        options={documentTypeItemList}
                                        placeholder='Select document type'
                                        required
                                        onChange={onDocumentTypeChange}
                                    />
                                </div>
                            </div>
                            <div className={stylesForOtherDocuments.row}>
                                <div className={stylesForOtherDocuments.leftColumn}>
                                    <Label required={true}>Title</Label>
                                </div>
                                <div className={stylesForOtherDocuments.rightColumn}>
                                    <TextField
                                        className={isInvalidTitle ? styles.error : ''}
                                        componentRef={titleField}
                                        onChange={onTitleChange}
                                    />
                                </div>
                            </div>
                            <div className={stylesForOtherDocuments.row}>
                                <div className={stylesForOtherDocuments.leftColumn}>
                                    <Label>Notes</Label>
                                </div>
                                <div className={stylesForOtherDocuments.rightColumn}>
                                    <TextField
                                        multiline={true}
                                        resizable={false}
                                        componentRef={notesField}
                                    />
                                </div>
                            </div>
                            <div className={stylesForOtherDocuments.row}>
                                <div className={stylesForOtherDocuments.leftColumn}>
                                    <Label>Documents</Label>
                                </div>
                                <div className={stylesForOtherDocuments.rightColumn}>
                                    <div className={styles.uploadButton}>
                                        <DefaultButton
                                            iconProps={{ iconName: 'OpenFile' }}
                                            type='file'>
                                            Choose File
                                        </DefaultButton>
                                        <input
                                            className={styles.hiddenInput}
                                            type='file'
                                            id='fileInput'
                                            ref={fileField}
                                            onChange={onFileChange}
                                            accept={fileInputAcceptStringForTemplate}
                                        />
                                        <span className={styles.fileName}>
                                            {fileName || 'No file selected'}
                                            <br></br>
                                            {acceptedFileTypesTextForUpload}
                                            <br></br>
                                            {acceptedMaxUploadPerFileText}
                                            <br></br>
                                            {acceptedMaxFileSize}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isUploadError && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                File upload failed: {uploadFailedError}
                            </MessageBar>
                        )}
                        {isInvalidDocumentType && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                Invalid document type: Document type cannot be empty.
                            </MessageBar>
                        )}
                        {isInvalidTitle && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                Invalid title: Title cannot be empty.
                            </MessageBar>
                        )}
                        {uploadFile && isInvalidFile && !isEmptyFile && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                Invalid file type. {acceptedFileTypesTextForTemplate}
                            </MessageBar>
                        )}
                        {isEmptyFile && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                Invalid file: File is empty.
                            </MessageBar>
                        )}
                    </div>
                    <div className={styles.footer}>
                        <DefaultButton text='Back' onClick={onCloseClick} allowDisabledFocus />
                        <PrimaryButton
                            onClick={onAddClick}
                            text='Add doc'
                            disabled={canAddDoc}
                            allowDisabledFocus
                        />
                    </div>
                </div>
            </Modal>
        </Stack>
    );
}

const styles = mergeStyleSets({
    root: {
        minWidth: '526px',
        boxSizing: 'border-box',
        selectors: {
            '@media(max-width: 450px)': {
                minWidth: '100%',
                maxWidth: '100%',
            },
        },
    },
    body: {
        backgroundColor: '#FFFFFF',
    },
    label: [
        FontSizes.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${SharedColors.cyanBlue10}`,
            display: 'flex',
            fontSize: '24px',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    sublabel: [
        FontSizes.small,
        {
            flex: '1 1 auto',
            display: 'flex',
            fontSize: '14px',
            alignItems: 'center',
            padding: '0px 0px 0px 24px',
        },
    ],
    content: {
        padding: '30px 24px 24px 24px',
        color: '#1b1b1b',
    },
    footer: {
        backgroundColor: '#FEFCFE',
        padding: '14px',
        borderTop: '1px solid #EAE9EA',
        display: 'flex',
        justifyContent: 'flex-end',
        selectors: {
            '& :not(:last-child)': {
                marginRight: '10px',
            },
        },
    },
    modalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    error: {
        border: '1px solid red',
    },
    fileName: {
        marginLeft: '10px',
    },
    uploadButton: {
        position: 'relative',
        overflow: 'hidden',
        display: 'inline-block',
    },
    hiddenInput: {
        position: 'absolute',
        left: '0',
        top: '0',
        opacity: '0',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
    },
});

const stylesForOtherDocuments = mergeStyleSets({
    row: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5,
        marginBottom: 5,
        selectors: {
            '@media(max-width: 450px)': {
                display: 'block',
            },
        },
    },
    leftColumn: {
        float: 'left',
        width: '30%',
        fontWeight: FontWeights.semibold,
        selectors: {
            '@media(max-width: 450px)': {
                width: '100%',
                marginBottom: '5px',
            },
        },
    },
    rightColumn: {
        width: '70%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        selectors: {
            '@media(max-width: 450px)': {
                clear: 'left',
                width: '100%',
            },
        },
    },
});
