import React from 'react';
import Badge from 'components/common/badge';
import { IColumn, mergeStyles, TooltipHost } from '@fluentui/react';
import { TableCell } from 'components/common/table';
import {
    lastColumnStylesButton,
    lastColumnStylesText,
    notLastColumnStyles,
} from 'assets/styles/list-styles';
import { ITeamAzureService, IAzureServiceKpi } from 'clients/staffing-client';
import {
    translateKpiStatus,
    serviceMapStatusBackground,
} from 'components/staffing/service/service-mapping-constants';
import DeleteServiceMapModal from 'components/staffing/service/delete-service-map-modal';
import AddServiceModalButton from 'components/staffing/service/add-service-modal-button';
import { IAddServiceModal } from 'components/staffing/staffing-page-types';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { xLargeMaxWidthCoeff } from 'assets/constants/global-constants';

interface IServiceMapTableColumns {
    hasEditRoles: boolean;
    sortAscending: boolean;
    sortColumnHandler: () => void;
    addServiceModalParams: IAddServiceModal;
    deleteServiceConcluded: (modalConclusion: ModalConclusion) => void;
}

export interface IServiceTableRow {
    team: string;
    service: ITeamAzureService;
    mapping: string;
    status: IAzureServiceKpi[];
    project: string;
    actions: {
        addService: boolean;
        deleteService: boolean;
    };
}

export interface IServiceUnmappedRow {
    team: string;
    actions: {
        addService: boolean;
        deleteService: boolean;
    };
}

export function getServiceMapTableColumns(params: IServiceMapTableColumns): IColumn[] {
    const hasActionButton = params.hasEditRoles;
    const columnWidths = {
        team: 90,
        service: 120,
        mapping: 65,
        status: {
            min: 210,
            max: hasActionButton ? 210 : undefined, // Undefined means let it expand if screen widens.
        },
        project: {
            min: 54,
            max: hasActionButton ? undefined : 54, // Undefined means let it expand if screen widens.
        },
        actions: {
            min: 200,
            max: 200,
        },
    };

    const columns: IColumn[] = [
        {
            key: 'Team',
            name: 'Team',
            ariaLabel: 'Team',
            minWidth: columnWidths.team,
            maxWidth: columnWidths.team * xLargeMaxWidthCoeff,
            isSorted: true,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler();
            },
            onRender: (row: IServiceTableRow): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisTextCss text={row.team} />
                    </TableCell>
                );
            },
        },
        {
            key: 'Service',
            name: 'Service',
            ariaLabel: 'Service',
            minWidth: columnWidths.service,
            maxWidth: columnWidths.service * xLargeMaxWidthCoeff,
            onRender: (row: IServiceTableRow): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisTextCss text={row.service?.serviceName} />
                    </TableCell>
                );
            },
        },
        {
            key: 'Mapping',
            name: 'Mapping',
            ariaLabel: 'Mapping',
            minWidth: columnWidths.mapping,
            maxWidth: columnWidths.mapping,
            onRender: (row: IServiceTableRow): JSX.Element => {
                return <TableCell>{row.mapping}</TableCell>;
            },
        },
        {
            key: 'Status',
            name: 'Status',
            ariaLabel: 'Status',
            minWidth: columnWidths.status.min,
            maxWidth: columnWidths.status.max,
            onRender: (row: IServiceTableRow): JSX.Element => {
                const text = row.status
                    .map((s: IAzureServiceKpi): string => translateKpiStatus(s))
                    .join(', ');
                return (
                    <TableCell>
                        <TooltipHost content={text}>
                            {row.status.map(
                                (s: IAzureServiceKpi, ix: number): JSX.Element => statusCard(s, ix),
                            )}
                        </TooltipHost>
                    </TableCell>
                );
            },
        },
        {
            key: 'Project',
            name: 'Project',
            ariaLabel: 'Project',
            minWidth: columnWidths.project.min,
            maxWidth: columnWidths.project.max,
            ...(hasActionButton ? notLastColumnStyles : lastColumnStylesText),
            onRender: (row: IServiceTableRow): JSX.Element => {
                return <TableCell>{row.project}</TableCell>;
            },
        },
    ];

    if (hasActionButton) {
        columns.push({
            key: 'Actions',
            name: 'Actions',
            ariaLabel: 'Actions',
            minWidth: columnWidths.actions.min,
            maxWidth: columnWidths.actions.max,
            ...lastColumnStylesButton,
            onRender: (row: IServiceTableRow): JSX.Element => {
                return (
                    <TableCell>
                        {row.actions.addService && (
                            <div className={mergeStyles({ display: 'inline-block' })}>
                                <AddServiceModalButton
                                    teams={params.addServiceModalParams.teams}
                                    orgName={params.addServiceModalParams.orgName}
                                    teamName={row.team}
                                    allServices={params.addServiceModalParams.allServices}
                                    addServiceCallback={
                                        params.addServiceModalParams.addServiceCallback
                                    }
                                />
                            </div>
                        )}
                        {row.actions.deleteService && (
                            <div className={mergeStyles({ display: 'inline-block' })}>
                                <DeleteServiceMapModal
                                    service={row.service}
                                    deleteServiceConcluded={params.deleteServiceConcluded}
                                />
                            </div>
                        )}
                    </TableCell>
                );
            },
        });
    }
    return columns;
}

function statusCard(status: IAzureServiceKpi, ix: number): JSX.Element {
    /**
     * Example for value of parameter status:
     *  {
     *      kpi: "PERSONNEL_KPI_24_7_SUPPORT"
     *      organizationName: "global"
     *      serviceName: "AuthN SDK - ADAL Objective-C"
     *      serviceOid: "170d170a-a3e3-41ef-8abc-6c86ce1978c1"
     *      teamName: "a"
     *      value: "red"
     *  },
     */
    return (
        <div
            key={ix}
            className={mergeStyles({
                display: 'inline-block',
                whiteSpace: 'no-wrap',
                selectors: {
                    ':first-child': {
                        marginRight: '5px',
                        marginBottom: '2px',
                    },
                },
            })}>
            <Badge
                text={translateKpiStatus(status)}
                backgroundColor={serviceMapStatusBackground(status)}
            />
        </div>
    );
}
