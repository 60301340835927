import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CustomBreadcrumb, ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import ContentCandidatesTab from 'components/screening/us-gov/candidates-listing/tabpanel-contents/content-candidates-tab';
import ContentClearanceRecordsTab from 'components/screening/us-gov/candidates-listing/tabpanel-contents/content-clearance-records-tab';
import FiltersContextProvider from 'contexts/filters-context';
import FiltersClearanceRecordsProvider from 'contexts/filters-clearance-records';
import queryString from 'query-string';
import { Location } from 'history';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { CheckAccessType, ServiceProviderType, UserContext } from 'contexts/user-context';
import { AccessDeniedURL } from 'assets/constants/global-constants';
import { useParams } from 'react-router-dom';
import {
    metricsTitle,
    recordsTitle,
    ScreeningPaths,
    ScreeningPathsTitles,
    UsGovScreeningUserType,
} from 'components/screening/common/common-constants';
import { ScreeningPageNames } from 'components/common/constants';
import ContentPublicTrustTab from 'components/screening/public-trust/candidates-listing/tabpanel-contents/content-public-trust-tab';
import ScreeningCheckAccess from 'components/screening/common/screening-check-access';
import ContentSuitabilityRecordsTab from 'components/screening/us-gov/candidates-listing/tabpanel-contents/content-suitability-records-tab';
import FiltersSuitabilityeRecordsProvider from 'contexts/filters-suitability-records';

export interface CandidatesPageProps {
    pageName: string;
    location: Location;
    userType: string;
    screeningPath: ScreeningPaths;
    isClearanceRecords?: boolean;
}

export default function CandidatesPage(props: CandidatesPageProps): JSX.Element {
    const defaultBreadCrumbs: ICustomBreadcrumbItem[] = useMemo(
        () => [
            {
                title: 'Screening',
                link: '',
            },
            {
                title:
                    props.screeningPath === ScreeningPaths.UsGov
                        ? ScreeningPathsTitles.UsGov
                        : ScreeningPathsTitles.PublicTrust,
                link: '',
            },
        ],
        [props.screeningPath],
    );
    const userContext = useContext(UserContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const [checkAccessTypes, setCheckAccessTypes] = useState<CheckAccessType[]>([]);
    const [hasPermissions, setHasPermissions] = useState<boolean[]>([]);
    const [hasAsyncPermissions, setAsyncHasPermissions] = useState<Promise<boolean>[]>([]);
    const [isInit, setIsInit] = useState<boolean>(false);
    const { tab } = useParams<{ tab: 'string' }>();
    const filters = queryString.parse(props.location.search);

    useEffect(() => {
        const beginningBreadCrumbs = [...defaultBreadCrumbs];
        beginningBreadCrumbs.push({
            title: props.pageName ? props.pageName : 'Screenings',
            link: props.location.pathname + props.location.search,
        });
        if (tab && tab.toLocaleLowerCase().localeCompare(metricsTitle.toLocaleLowerCase()) === 0) {
            beginningBreadCrumbs.push({
                title: metricsTitle,
                link: props.location.pathname,
            });
        } else if (
            tab &&
            tab.toLocaleLowerCase().localeCompare(recordsTitle.toLocaleLowerCase()) === 0
        ) {
            beginningBreadCrumbs.push({
                title: recordsTitle,
                link: props.location.pathname,
            });
        }
        breadCrumbContext.setBreadCrumbs([...beginningBreadCrumbs]);
    }, [props.pageName, props.location]);

    useEffect(() => {
        function buildCheckAccessData(): void {
            const serviceProvider =
                props.screeningPath === ScreeningPaths.UsGov
                    ? ServiceProviderType.UsGovScreening
                    : ServiceProviderType.PublicTrust;
            switch (props.userType) {
                case 'ContractOwner':
                    setCheckAccessTypes([
                        {
                            serviceProvider,
                            value: UsGovScreeningUserType.ContractOwner,
                        },
                    ]);
                    break;
                case 'Manager': // my org
                case 'Nominee':
                    setHasPermissions([true]);
                    break;
                case 'Nominator':
                    setCheckAccessTypes([
                        {
                            serviceProvider,
                            value: UsGovScreeningUserType.Nominator,
                        },
                    ]);
                    break;
                case '': // Manage page
                    if (props.pageName === ScreeningPageNames.ClearanceRecords) {
                        setCheckAccessTypes([
                            {
                                serviceProvider,
                                value: UsGovScreeningUserType.NST,
                            },
                            {
                                serviceProvider,
                                value: UsGovScreeningUserType.ContractOwner,
                            },
                        ]);
                    } else {
                        setCheckAccessTypes([
                            {
                                serviceProvider,
                                value: UsGovScreeningUserType.NST,
                            },
                        ]);
                    }
                    break;
                default:
                    setCheckAccessTypes([]);
                    setAsyncHasPermissions([]);
                    setHasPermissions([false]);
                    break;
            }
            setIsInit(true); // we are done initilizing
        }
        // build it only once
        buildCheckAccessData();
    }, [props.pageName, props.screeningPath, props.userType, userContext]);

    function getTab(): JSX.Element {
        if (props.pageName === ScreeningPageNames.ClearanceRecords) {
            return (
                <FiltersClearanceRecordsProvider
                    userType={props.userType}
                    location={props.location}
                    {...filters}>
                    <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                    <ContentClearanceRecordsTab screeningPath={props.screeningPath} />
                </FiltersClearanceRecordsProvider>
            );
        }

        if (props.pageName === ScreeningPageNames.SuitabilityRecords) {
            return (
                <FiltersSuitabilityeRecordsProvider
                    userType={props.userType}
                    location={props.location}
                    {...filters}>
                    <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                    <ContentSuitabilityRecordsTab screeningPath={props.screeningPath} />
                </FiltersSuitabilityeRecordsProvider>
            );
        }

        return (
            <FiltersContextProvider
                userType={props.userType}
                location={props.location}
                {...filters}>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                {props.screeningPath === ScreeningPaths.UsGov && (
                    <ContentCandidatesTab
                        key={props.pageName}
                        pageName={props.pageName}
                        pathName={props.location.pathname}
                    />
                )}

                {props.screeningPath === ScreeningPaths.PublicTrust && (
                    <ContentPublicTrustTab
                        key={props.pageName}
                        pageName={props.pageName}
                        pathName={props.location.pathname}
                    />
                )}
            </FiltersContextProvider>
        );
    }

    return (
        <ScreeningCheckAccess
            requiredAccessTypeAny={checkAccessTypes}
            hasRequiredPermissionsAny={hasPermissions}
            hasRequiredAsyncPermissions={hasAsyncPermissions}
            arePermissionsChecked={isInit}
            accessDeniedRedirect={AccessDeniedURL}>
            {getTab()}
        </ScreeningCheckAccess>
    );
}
