import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from 'contexts/user-context';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';

export interface UsGovLandingPageProps {
    match: {
        params: {
            id: string;
        };
    };
}

export default function UsGovLandingPage(props: UsGovLandingPageProps): JSX.Element {
    const userContext = useContext(UserContext);
    const [redirectUrl, setRedirectUrl] = useState<string | undefined>();

    useEffect(() => {
        if (userContext.isUsGovScreeningUserTypesLoaded) {
            if (props.match?.params?.id && props.match?.params?.id !== 'us-gov') {
                setRedirectUrl(`/screening/us-gov/${props.match.params.id}`);
            } else {
                if (userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST)) {
                    setRedirectUrl('/screening/us-gov/manage');
                } else if (userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.Manager)) {
                    setRedirectUrl('/screening/us-gov/my-org');
                } else if (
                    userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.ContractOwner)
                ) {
                    setRedirectUrl('/screening/us-gov/my-contracts');
                } else if (
                    userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.Nominator)
                ) {
                    setRedirectUrl('/screening/us-gov/my-nominees');
                } else {
                    setRedirectUrl('/screening/us-gov/my-screenings');
                }
            }
        }
    }, [userContext.isUsGovScreeningUserTypesLoaded]);

    return <>{redirectUrl && <Redirect to={redirectUrl} />}</>;
}
