import React, { useContext } from 'react';
import {
    mergeStyleSets,
    Separator,
    IStackProps,
    IStackTokens,
    Stack,
    IPersonaProps,
    Checkbox,
    Dropdown,
    IDropdownOption,
} from '@fluentui/react';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { StaffingFiltersContext } from 'components/staffing/contexts/staffing-filters-context';
import { StaffingFiltersProps } from 'components/staffing/staffing-page-types';
import { globalSeparatorStyles, globalStyles } from 'assets/styles/global-styles';
import { CloudStatusMap } from 'components/staffing/staffing-constants';

const styles = mergeStyleSets({
    top: {
        marginBottom: '15px',
    },
});

const filtersStackProps: IStackProps = {};
const filterStackTokens: IStackTokens = {
    childrenGap: 5,
};

export const cloudFilterSubkey = 'cloudFilterSubkey';

export default function StaffingAllocationFilters(props: StaffingFiltersProps): JSX.Element {
    const { teamNames, statusNames } = props;
    const staffingContext = useContext(StaffingFiltersContext);
    const { filters, setFilter } = staffingContext;

    const onCloudSelected = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): void => {
        if (option?.key !== undefined) {
            setFilter('cloud', cloudFilterSubkey, option.key as string);
        }
    };

    const cloudSelectionOptions: IDropdownOption[] = Object.entries(
        CloudStatusMap,
    ).map(([cloudKey, cloudName]) => ({ key: cloudKey, text: cloudName }));

    return (
        <div className={styles.top}>
            <Stack {...filtersStackProps} tokens={filterStackTokens}>
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Search
                </Separator>
                <EmployeePickerTypeaheadSearch
                    ariaLabel='Search'
                    placeHolder='Employee Name or Alias'
                    onCandidateSelected={(info?: IPersonaProps): void => {
                        setFilter('employee', 'info', info);
                    }}
                />
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Cloud
                </Separator>
                <Dropdown
                    ariaLabel='Cloud'
                    styles={{ dropdownItems: globalStyles.dropdownItems }}
                    options={cloudSelectionOptions}
                    onChange={onCloudSelected}
                    selectedKey={
                        (!!cloudFilterSubkey ? filters?.cloud[cloudFilterSubkey] : '') as string
                    }
                />
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Status
                </Separator>
                {statusNames?.map(
                    (statusName): JSX.Element => (
                        <Checkbox
                            label={statusName}
                            key={statusName}
                            onChange={(): void => {
                                setFilter('status', statusName, !filters.status[statusName]);
                            }}
                            checked={
                                filters?.status ? (filters.status[statusName] as boolean) : false
                            }
                        />
                    ),
                )}
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Teams
                </Separator>
                {teamNames
                    ?.sort((i1, i2) => i1.localeCompare(i2))
                    .map(
                        (teamName): JSX.Element => (
                            <Checkbox
                                label={teamName}
                                key={teamName}
                                onChange={(): void => {
                                    setFilter('team', teamName, !filters.team[teamName]);
                                }}
                                checked={
                                    filters?.team ? (filters.team[teamName] as boolean) : false
                                }
                            />
                        ),
                    )}
            </Stack>
        </div>
    );
}
