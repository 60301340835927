import { IPersonaProps } from '@fluentui/react';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import GraphClient, { isGraphServicePrincipal } from 'clients/graph-client';
import { fetchOrUseCachePictures } from 'components/common/employee/employee-utils';
import { AuthContext, IAuthContext } from 'contexts/auth-context';
import { CacheContext } from 'contexts/cache-context';
import { useContext } from 'react';
import { debounceAsync } from 'utils/misc-utils';
import {
    transformGraphServicePrincipalToPersona,
    transformGraphUserToPersona,
} from 'utils/persona-utils';
import { getAppInsights } from 'utils/telemetry-utils';

export const useDebounceGetGraphPersonas = () => {
    const authContext = useContext(AuthContext);
    const cacheContext = useContext(CacheContext);
    const maxResults = 5;

    const debouncedGetTypeaheadPersonas = debounceAsync<IPersonaProps[]>(getTypeaheadGraphPersonas);
    async function getTypeaheadGraphPersonas(input: string): Promise<IPersonaProps[]> {
        if (!input) {
            return [];
        }

        try {
            const userFilterQuery = `startswith(userPrincipalName, '${input}') or startswith(displayName, '${input}')`;
            const exactMatches = await GraphClient.listUsers(
                authContext,
                userFilterQuery,
                maxResults,
            );

            const fuzzyMatches = await getFuzzyMatches(
                authContext,
                input,
                maxResults - exactMatches.length,
            );

            // Deduplicate any fuzzy matches that are already in exact matches
            const dedupedFuzzyMatches = fuzzyMatches.filter(
                (x) => !exactMatches.some((y) => x.id === y.id),
            );

            const results = [...exactMatches, ...dedupedFuzzyMatches];

            await fetchOrUseCachePictures(authContext, results, cacheContext);

            const personas = results.map((x) =>
                isGraphServicePrincipal(x)
                    ? transformGraphServicePrincipalToPersona(x, cacheContext)
                    : transformGraphUserToPersona(x, cacheContext),
            );

            return personas;
        } catch (e) {
            getAppInsights()?.trackException({
                exception: e,
                severityLevel: SeverityLevel.Error,
            });
            return [];
        }
    }
    return debouncedGetTypeaheadPersonas;
};

const getFuzzyMatches = async (
    authContext: IAuthContext,
    searchTerm: string,
    maxResults: number,
) => {
    if (maxResults <= 0) {
        return [];
    }

    const userSearchQuery = `"displayName:${searchTerm}" OR "userPrincipalName:${searchTerm}"`;
    const servicePrincipalSearchQuery = `"displayName:${searchTerm}" OR "description:${searchTerm}"`;

    const searchUsersPromise = GraphClient.searchUsers(authContext, userSearchQuery, maxResults);
    const searchAppsPromise = GraphClient.searchServicePrincipals(
        authContext,
        servicePrincipalSearchQuery,
        maxResults,
    );

    const results = [...((await searchUsersPromise) ?? []), ...((await searchAppsPromise) ?? [])];

    return results;
};
