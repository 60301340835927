import { ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import { IEmployee } from 'clients/employee-client';
import { IVisitorProfile } from 'clients/visitor-client';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import { UserAssignmentsTabs } from 'components/user-assignments/user-assignments-constants';
import { AssignmentsPageContentType } from 'components/user-assignments/user-assignments-constants';

export const userAssignmentUrl = (
    contentType: AssignmentsPageContentType,
    aliasOrId?: string,
    tab?: string,
) => {
    switch (contentType) {
        case AssignmentsPageContentType.MyInfo:
            return `/profile/user/me/${tab ?? UserAssignmentsTabs.PersonnelInfo}`;
        case AssignmentsPageContentType.EmployeeOrVisitor:
            return !!aliasOrId
                ? `/profile/user/${aliasOrId}/${tab ?? UserAssignmentsTabs.PersonnelInfo}`
                : '';
        default:
            console.error(`The contentType value "${contentType}" is not supported`);
            return '';
    }
};

export const userAssignmentsBreadcrumbs: ICustomBreadcrumbItem[] = [
    {
        title: 'Profile',
        link: '/profile',
    },
    {
        title: 'User',
        link: '',
    },
];

export const userSearchBreadcrumbs = (): ICustomBreadcrumbItem[] => [
    ...userAssignmentsBreadcrumbs,
    {
        title: 'Search',
        link: '',
    },
];

export const userInfoBreadcrumbs = (
    person?: IEmployee | IVisitorProfile,
): ICustomBreadcrumbItem[] => {
    return [
        ...userAssignmentsBreadcrumbs,
        {
            title: getDisplayNameOrDefault(person),
            link: '',
        },
        {
            title: 'Info',
            link: '',
        },
    ];
};

export const userManageAttributesBreadcrumbs = (
    person?: IEmployee | IVisitorProfile,
): ICustomBreadcrumbItem[] => {
    return [
        ...userAssignmentsBreadcrumbs,
        {
            title: getDisplayNameOrDefault(person),
            link: '',
        },
        {
            title: 'Attributes',
            link: '',
        },
    ];
};

export const userManageEligibilitiesBreadcrumbs = (
    person?: IEmployee | IVisitorProfile,
): ICustomBreadcrumbItem[] => [
    ...userAssignmentsBreadcrumbs,
    {
        title: getDisplayNameOrDefault(person),
        link: '',
    },
    {
        title: 'Eligibilities',
        link: '',
    },
];
