import { Stack } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import ChartLegend, { ChartLegendItem } from 'components/common/charts/chart-legend';
import PieChart, { PieChartItem } from 'components/common/charts/pie-chart';
import {
    ICloudScreenChartSideInfo,
    PieChartColorMap,
    PieChartStatus,
} from 'components/screening/cloud-screening/cloud-screening-utils';

export interface CloudScreeningPieChartProps {
    pieChartData: PieChartItem[];
    pieChartSideInfo: ICloudScreenChartSideInfo | undefined;
}

export default function CloudScreeningPieChart(props: CloudScreeningPieChartProps): JSX.Element {
    const [pieChartData, setPieChartData] = useState<PieChartItem[]>([]);
    const [pieChartSideInfo, setPieChartSideInfo] = useState<ICloudScreenChartSideInfo>();

    useEffect(() => {
        setPieChartData(props.pieChartData);
        setPieChartSideInfo(props.pieChartSideInfo);
    }, [props]);

    return (
        <Stack horizontal styles={chartContainerStyle}>
            <Stack.Item>
                <Stack horizontal styles={chartContainerInnerStyle} tokens={{ childrenGap: 15 }}>
                    <PieChart pieChartItems={pieChartData}></PieChart>

                    {pieChartSideInfo &&
                        !isNaN(pieChartSideInfo.screened / pieChartSideInfo.totalRequired) && (
                            <Stack
                                styles={{ root: { justifyContent: 'center', minWidth: '200px' } }}>
                                <Stack.Item>
                                    <h2 style={{ fontSize: '32px', margin: '0' }}>
                                        {(
                                            (pieChartSideInfo.screened /
                                                pieChartSideInfo.totalRequired) *
                                            100
                                        ).toFixed(0)}
                                        %
                                        <span style={{ fontSize: '18px' }}>
                                            &nbsp;({pieChartSideInfo.screened} of{' '}
                                            {pieChartSideInfo.totalRequired} employees screened)
                                        </span>
                                    </h2>
                                </Stack.Item>
                                <Stack.Item>
                                    {pieChartSideInfo.notRequired} not required employees
                                </Stack.Item>
                                <Stack.Item>{pieChartSideInfo.total} employees total</Stack.Item>
                            </Stack>
                        )}
                </Stack>
            </Stack.Item>

            <Stack.Item>
                <ChartLegend
                    legendItems={Object.values(PieChartStatus).map(
                        (status): ChartLegendItem => {
                            return {
                                label: status,
                                color: PieChartColorMap[status],
                            };
                        },
                    )}
                    maxHeight={'150px'}
                    minWidth={'150px'}
                />
            </Stack.Item>
        </Stack>
    );
}

const chartContainerStyle = {
    root: {
        justifyContent: 'space-between',
        marginBottom: 20,
        maxWidth: '75%',
    },
};

const chartContainerInnerStyle = {
    root: {
        maxHeight: '150px',
    },
};
