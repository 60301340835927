import { IPivotStyles, Stack } from '@fluentui/react';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import CloudScreeningClient, {
    ICloudScreening,
    IScopeRecord,
} from 'clients/cloud-screening-client';
import { CloudScreeningUserType } from 'utils/cloud-screening-utils';
import { IEmployee } from 'clients/employee-client';
import { AuthContext, IAuthContext } from 'contexts/auth-context';
import Tabs from 'components/common/tabs';
import { UserContext } from 'contexts/user-context';
import SidebarAndContents, {
    SidebarPane,
    ContentPane,
} from 'components/common/sidebar-and-contents';
import CloudScreeningOrgFilter from 'components/screening/cloud-screening/direct-reports/cloud-screening-org-filter';
import CloudScreeningReportsTable from 'components/screening/cloud-screening/direct-reports/cloud-screening-reports-table';
import ManagerDelegateAddRemove from 'components/screening/cloud-screening/direct-reports/manager-delegate-add-remove';
import ManagerDelegateToggle from 'components/screening/cloud-screening/direct-reports/manager-delegate-toggle';
import { Dictionary } from 'assets/constants/global-constants';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { getNameFromPersonnelId } from 'components/common/employee/employee-name-resolver';
import { getCloudScreeningStatus } from 'components/screening/cloud-screening/cloud-screening-utils';
import { distinct } from 'utils/misc-utils';
import { Location } from 'history';
import CloudScreeningAdminImpersonation from 'components/screening/cloud-screening/admin/cloud-screening-admin-impersonation';

// eslint-disable-next-line  @typescript-eslint/no-empty-interface
export interface CloudScreeningOrgPageProps {
    pageName: string;
    location: Location;
}

type tabOption = { key: string; name: string };

const tabOptions: tabOption[] = [
    {
        key: '0',
        name: 'Direct Reports',
    },
    {
        key: '1',
        name: 'Entire Org',
    },
];

type ExcelRecordType = {
    userAlias: string;
    personnelId: string;
    cloudScreenStartDate: string;
    cloudScreenEndDate: string;
    country: string;
    requestStatus: string;
    divisionLeader: string;
    isRequiredFor: string;
    requiredFor: string;
    isFTE: string;
};

export default function CloudScreeningOrgPage(props: CloudScreeningOrgPageProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [triggerClear, setTriggerClear] = useState<number>(0);
    const [isRetrievingReports, setRetrievingReports] = useState<boolean>(false);
    const [isRetrievingScopes, setIsRetrievingScopes] = useState<boolean>(false);
    const [reports, setReports] = useState<IEmployee[]>([]);
    const [filteredReports, setFilteredReports] = useState<IEmployee[]>([]);
    const [reportScreeningsDict, setReportScreeningsDict] = useState<Dictionary<ICloudScreening[]>>(
        {},
    );
    const [scopeRecordsDict, setScopeRecordsDict] = useState<Dictionary<IScopeRecord[]>>({});
    const [viewedEmployeeId, setViewedEmployeeId] = useState<string>(userContext.employeeRecord.id);
    const [selectedTabOption, setSelectedTabOption] = useState<'Direct Reports' | 'Entire Org'>(
        'Direct Reports',
    );

    const rectangleStyle: CSSProperties = {
        display: 'flex',
        backgroundColor: 'rgba(0, 0, 0, 0.035)',
        margin: '0px 0 15px',
        padding: '5px 10px',
        borderRadius: '1.5px',
    };

    const hoverStyle = {
        selectors: {
            ':hover': { backgroundColor: 'white' },
        },
    };

    const pivotStyle: Partial<IPivotStyles> = {
        link: hoverStyle,
        linkIsSelected: hoverStyle,
    };

    const contents = tabOptions.map((x) => {
        return {
            tabHeader: x.name,
            content: <></>,
        };
    });

    const asyncMapRecordsToExcel = async (): Promise<ExcelRecordType[]> => {
        return Promise.all(
            reports.map(
                async (report): Promise<ExcelRecordType> => {
                    const orgLeaderName = await getNameFromPersonnelId(
                        authContext,
                        report.hierarchyLvl2,
                    );

                    const screeningEmployee = report;
                    const employeeScopeRecords = scopeRecordsDict[report.id];
                    const employeeScopes =
                        employeeScopeRecords && employeeScopeRecords.length > 0
                            ? distinct(
                                  employeeScopeRecords.map((x) => `${x.scope}`.toLocaleUpperCase()),
                              )
                            : [];
                    const scopeStr = employeeScopes.join();

                    return {
                        userAlias: screeningEmployee.alias,
                        personnelId: screeningEmployee.id,
                        cloudScreenStartDate: screeningEmployee.cloudScreenCompletionDate ?? '',
                        cloudScreenEndDate: screeningEmployee.cloudScreenExpireDate ?? '',
                        country: screeningEmployee.locationAreaCode,
                        requestStatus: getCloudScreeningStatus(report).longText,
                        divisionLeader: orgLeaderName,
                        isRequiredFor: `${employeeScopes.length > 0}`,
                        requiredFor: scopeStr,
                        isFTE: `${screeningEmployee.isFTE}`,
                    };
                },
            ),
        );
    };

    useEffect(() => {
        let isMounted = true;

        async function getReports(id: string): Promise<void> {
            setRetrievingReports(true);
            try {
                const allReports = await CloudScreeningClient.getAllReports(authContext, id);
                if (isMounted) {
                    setReports(allReports);
                    setFilteredReports(allReports);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setRetrievingReports(false);
            }
        }

        if (viewedEmployeeId) {
            setRetrievingReports(true);
            setIsRetrievingScopes(true);
            if (isViewingManager()) {
                getReports(viewedEmployeeId);
            } else {
                setReports([]);
                setFilteredReports([]);
                setRetrievingReports(false);
                setIsRetrievingScopes(false);
            }
        }

        return (): void => {
            isMounted = false;
        };
    }, [viewedEmployeeId, authContext]);

    useEffect(() => {
        let isMounted = true;

        async function getScreeningsForReports(id: string): Promise<void> {
            try {
                const newReportScreeningsDict: Dictionary<ICloudScreening[]> = {};
                let continuationToken = '';

                do {
                    const pagedResults = await CloudScreeningClient.getScreeningsByManagerId(
                        authContext,
                        id,
                        continuationToken,
                    );
                    pagedResults.results.forEach((x) => {
                        if (!newReportScreeningsDict[x.personnelId]) {
                            newReportScreeningsDict[x.personnelId] = [];
                        }
                        newReportScreeningsDict[x.personnelId].push(x);
                    });
                    continuationToken = pagedResults.continuationToken ?? '';
                } while (continuationToken && isMounted);

                if (isMounted) {
                    setReportScreeningsDict(newReportScreeningsDict);
                }
            } catch (e) {
                console.error(e);
            }
        }

        if (viewedEmployeeId) {
            if (isViewingManager()) {
                getScreeningsForReports(viewedEmployeeId);
            } else {
                setReportScreeningsDict({});
            }
        }

        return (): void => {
            isMounted = false;
        };
    }, [viewedEmployeeId, authContext]);

    useEffect(() => {
        let isMounted = true;

        if (viewedEmployeeId && reports) {
            if (isViewingManager()) {
                getScopes(authContext, viewedEmployeeId, reports, (): boolean => {
                    return isMounted;
                });
            } else {
                setScopeRecordsDict({});
                setIsRetrievingScopes(false);
            }
        }
        return (): void => {
            isMounted = false;
        };
    }, [reports]);

    function isViewingManager(): boolean {
        if (viewedEmployeeId !== userContext.employeeRecord.id) {
            return true;
        }
        // The current user doesn't have to be a manager
        return userContext.hasCloudScreeningUserType(CloudScreeningUserType.Manager);
    }

    async function getScopes(
        varAuthContext: IAuthContext,
        varManagerId: string,
        varDirectReports: IEmployee[],
        isMountedFunction: () => boolean,
    ): Promise<void> {
        const newScopeRecordsDict: Dictionary<IScopeRecord[]> = {};
        try {
            setIsRetrievingScopes(true);
            let continuationToken = '';
            const personnelIds = varDirectReports.map((x) => x.id);
            if (personnelIds.length > 0) {
                while (personnelIds.length) {
                    const chunk = personnelIds.splice(
                        0,
                        CloudScreeningClient.maxScopePersonnelIdLength,
                    );
                    do {
                        const pagedResult = await CloudScreeningClient.getScopesForManager(
                            varAuthContext,
                            varManagerId,
                            chunk,
                            continuationToken,
                        );
                        pagedResult.results.forEach((scopeRecord) => {
                            if (newScopeRecordsDict[scopeRecord.personnelId] === undefined) {
                                newScopeRecordsDict[scopeRecord.personnelId] = [];
                            }
                            newScopeRecordsDict[scopeRecord.personnelId].push(scopeRecord);
                        });

                        continuationToken = pagedResult.continuationToken ?? '';
                    } while (continuationToken && isMountedFunction());
                }
            }
        } catch (error) {
            console.warn('error getting scopes', error);
        } finally {
            if (isMountedFunction()) {
                setScopeRecordsDict(newScopeRecordsDict);
                setIsRetrievingScopes(false);
            }
        }
    }

    function optionChange(key?: string): void {
        if (!key) {
            return;
        }

        const option = tabOptions.find((x) => x.key === key);
        if (option) {
            setSelectedTabOption(
                option.name === 'Direct Reports' ? 'Direct Reports' : 'Entire Org',
            );
        }
    }

    function upsertScreening(screening: ICloudScreening): void {
        const newReportScreeningsDict = { ...reportScreeningsDict };
        let newReportScreenings: ICloudScreening[] = [];
        if (newReportScreeningsDict[screening.personnelId]) {
            newReportScreenings = newReportScreeningsDict[screening.personnelId].filter(
                (x) => x.id !== screening.id,
            );
        }
        newReportScreenings.push(screening);
        newReportScreeningsDict[screening.personnelId] = newReportScreenings;
        setReportScreeningsDict(newReportScreeningsDict);
    }

    return (
        <SidebarAndContents>
            <>
                {userContext.hasCloudScreeningUserType(CloudScreeningUserType.Admin) && (
                    <SidebarPane>
                        <CloudScreeningAdminImpersonation
                            onEmployeeSelected={(employee: IEmployee | undefined): void => {
                                setTriggerClear(triggerClear + 1);
                                if (employee) {
                                    setViewedEmployeeId(employee.id);
                                } else {
                                    setViewedEmployeeId(userContext.employeeRecord.id);
                                }
                            }}
                        />
                    </SidebarPane>
                )}

                <SidebarPane>
                    <CloudScreeningOrgFilter
                        reports={reports}
                        setFilteredReports={setFilteredReports}
                        scopeRecordsDictionary={scopeRecordsDict}
                    />
                </SidebarPane>

                {userContext.hasCloudScreeningUserType(CloudScreeningUserType.ManagerDelegate) ? (
                    <SidebarPane>
                        <ManagerDelegateToggle
                            managerId={viewedEmployeeId}
                            setManagerId={setViewedEmployeeId}
                            triggerClear={triggerClear}
                        />
                    </SidebarPane>
                ) : (
                    <></>
                )}

                <SidebarPane>
                    <ManagerDelegateAddRemove managerId={viewedEmployeeId} />
                </SidebarPane>
            </>

            <ContentPane>
                <Stack>
                    <Stack.Item>
                        <Stack horizontal style={rectangleStyle} horizontalAlign='space-between'>
                            <Stack.Item>
                                <Tabs
                                    contents={contents}
                                    pivotStyle={pivotStyle}
                                    onChange={optionChange}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <ExportToExcelButton<ExcelRecordType>
                                    fileNamePrefix={`cloud-screening-reports-${
                                        viewedEmployeeId ?? ''
                                    }`}
                                    getData={asyncMapRecordsToExcel}
                                    disabled={isRetrievingReports || isRetrievingScopes}
                                    formatHeader={true}
                                />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <CloudScreeningReportsTable
                            isRetrievingReports={isRetrievingReports}
                            isRetrievingScopes={isRetrievingScopes}
                            employees={filteredReports}
                            reportScreeningsDict={reportScreeningsDict}
                            scopeRecordsDict={scopeRecordsDict}
                            option={selectedTabOption}
                            upsertScreening={upsertScreening}
                        />
                    </Stack.Item>
                </Stack>
            </ContentPane>
        </SidebarAndContents>
    );
}
