import { IColumn, Stack } from '@fluentui/react';
import { xxxLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { IGroup, IGroupMembership } from 'clients/group-client';
import { TableCell } from 'components/common/table';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import ReviewRequestModalActionButton from 'components/groups/manage-group/actions/join-requests/review-request-modal-action-button';
import React from 'react';
import { ISortableColumns } from 'utils/sort-utils';
import { toTitleCase } from 'utils/string-utils';
import { TimeFormats, timeToString } from 'utils/time-utils';

export enum JoinRequestsTableColumnNames {
    name = 'Name',
    requestType = 'Request Type',
    dateAdded = 'Date Added',
    actions = 'Actions',
}

interface ITableColumnsJoinRequestsParams extends ISortableColumns {
    group: IGroup;
    canApproveOrDenyRequests: boolean;
    onDeleteMemberFromJoinRequestTable: (groupMember: IGroupMembership) => void;
}

export function tableColumnsGroupJoinRequests(params: ITableColumnsJoinRequestsParams): IColumn[] {
    const columnWidths = {
        name: 120,
        requestType: 200,
        dateAdded: 150,
        actions: 120,
    };

    return [
        {
            key: JoinRequestsTableColumnNames.name,
            name: JoinRequestsTableColumnNames.name,
            ariaLabel: JoinRequestsTableColumnNames.name,
            minWidth: columnWidths.name,
            maxWidth: columnWidths.name * xxxLargeMaxWidthCoeff,
            isSorted: params.sortColumn === JoinRequestsTableColumnNames.name,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(JoinRequestsTableColumnNames.name);
            },
            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${JoinRequestsTableColumnNames.name}`}>
                    <CoreEmployeeHoverCardFromPrincipalId principalId={row.personnelId} />
                </TableCell>
            ),
        },
        {
            key: JoinRequestsTableColumnNames.requestType,
            name: JoinRequestsTableColumnNames.requestType,
            ariaLabel: JoinRequestsTableColumnNames.requestType,
            minWidth: columnWidths.requestType,
            maxWidth: columnWidths.requestType * xxxLargeMaxWidthCoeff,
            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${JoinRequestsTableColumnNames.requestType}`}>
                    <Stack horizontal>
                        {row.requestedType ? toTitleCase(row.requestedType) : ''}
                        <span>&nbsp; by &nbsp;</span>
                        <CoreEmployeeHoverCardFromPrincipalId principalId={row.requestedBy} />
                    </Stack>
                </TableCell>
            ),
        },
        {
            key: JoinRequestsTableColumnNames.dateAdded,
            name: JoinRequestsTableColumnNames.dateAdded,
            ariaLabel: JoinRequestsTableColumnNames.dateAdded,
            minWidth: columnWidths.dateAdded,
            maxWidth: undefined,
            isSorted: params.sortColumn === JoinRequestsTableColumnNames.dateAdded,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(JoinRequestsTableColumnNames.dateAdded);
            },
            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${JoinRequestsTableColumnNames.dateAdded}`}>
                    <span>
                        {timeToString(row.requestedTimestampUTC * 1000, TimeFormats.MMMDDYYYY_hmmA)}
                    </span>
                </TableCell>
            ),
        },
        {
            key: JoinRequestsTableColumnNames.actions,
            name: JoinRequestsTableColumnNames.actions,
            ariaLabel: JoinRequestsTableColumnNames.actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions,
            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell key={`${row.id}-${JoinRequestsTableColumnNames.actions}`}>
                    <Stack horizontal>
                        <ReviewRequestModalActionButton
                            member={row}
                            group={params.group}
                            canApproveOrDenyRequest={params.canApproveOrDenyRequests}
                            onDeleteMemberFromJoinRequestTable={
                                params.onDeleteMemberFromJoinRequestTable
                            }
                        />
                    </Stack>
                </TableCell>
            ),
        },
    ];
}
