import React, { ReactNode } from 'react';
import { globalStyles } from 'assets/styles/global-styles';
import { mergeStyles } from '@fluentui/react';

interface BoldFontProps {
    children?: ReactNode;
    className?: string;
}

export default function BoldFont(props: BoldFontProps): JSX.Element {
    const styles = mergeStyles(globalStyles.boldFont, props.className);

    return <b className={styles}>{props.children}</b>;
}
