import React, { useState, useEffect, useContext, ReactNode, useMemo, useCallback } from 'react';
import {
    mergeStyleSets,
    FontSizes,
    FontWeights,
    SharedColors,
    Separator,
    ChoiceGroup,
    IChoiceGroupOption,
    PrimaryButton,
    DefaultButton,
    ActionButton,
    MessageBarType,
    ProgressIndicator,
    MessageBar,
    Panel,
    PanelType,
} from '@fluentui/react';
import EmployeeCard from 'components/common/employee/employee-card';
import { CandidateColumnContent } from 'components/screening/common/candidate-column-content';
import { IAuthContext, AuthContext } from 'contexts/auth-context';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import EmployeeClient, { IBasicEmployee } from 'clients/employee-client';
import { dateToFormattedDateTimeString, unixTimeStampToLocalDate } from 'utils/time-utils';
import { specialAccessComparator } from 'components/personnel-profile/clearance/profile-clearance-constants';
import {
    AcceptanceChoice,
    ScreeningPaths,
    ScreeningRequestTypesLabels,
} from 'components/screening/common/common-constants';
import PublicTrustScreeningClient from 'clients/screening/public-trust-screening-client';
import { useIsMounted } from 'utils/misc-hooks';
import { IScreening, ApprovalOptions } from 'components/screening/us-gov/IScreening';
import { IContract } from 'components/screening/us-gov/IContract';
import { separateWordsByCapitalLetter } from 'utils/string-utils';
import {
    convertIPublicTrustToCommonScreening,
    convertIScreeningToCommonScreening,
    ICommonScreening,
} from 'components/screening/common/ICommonScreening';
import { IPublicTrust } from 'components/screening/public-trust/public-trust-screening-result';
import { determineContractType } from 'components/screening/screening-utils';
import { SuitabilityLevels } from 'components/personnel-profile/suitability/profile-suitability-types';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/suitability/profile-suitability-utils';
import { PANEL_WIDTH, panelStyles } from 'components/screening/common/nominee-accept';

const APPROVE_LABEL = 'Approve nomination';
const DENY_LABEL = 'Deny nomination';
const APPROVE_KEY = 'APPROVE';
const DENY_KEY = 'DENY';

export interface ContractOwnerAcceptProps {
    screening: ICommonScreening;
    updateScreeningInRawCandidates(screen: ICommonScreening): void;
    isInitialEmployeeBatchLoaded: boolean;
    screeningPath: ScreeningPaths;
}

const contentMaxHeight = '24px';

const CHOICES: IChoiceGroupOption[] = [
    {
        key: APPROVE_KEY,
        text: APPROVE_LABEL,
        value: APPROVE_KEY,
    },
    {
        key: DENY_KEY,
        text: DENY_LABEL,
        value: DENY_KEY,
    },
];

export default function ContractOwnerAccept(props: ContractOwnerAcceptProps): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);
    const authContext = useContext(AuthContext);

    const isMounted = useIsMounted();
    const [acceptanceChoice, setAcceptanceChoice] = useState<AcceptanceChoice>(
        AcceptanceChoice.UNSET,
    );
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [employee, setEmployee] = useState<IBasicEmployee | null>(null);
    const [manager, setManager] = useState<IBasicEmployee | null>(null);
    const [contract, setContract] = useState<IContract>();
    const [errorMessage, setErrorMessage] = useState<string | ReactNode>();

    useEffect(() => {
        if (!isOpen) setAcceptanceChoice(AcceptanceChoice.UNSET);
    }, [isOpen]);

    useEffect(() => {
        async function loadPersonnelData(): Promise<void> {
            let basicResult;
            if (props.screening.personnelId) {
                try {
                    basicResult = await EmployeeClient.getBasicEmployeesById(authContext, [
                        props.screening.personnelId,
                    ]);
                    const emp = basicResult[0];
                    setEmployee(emp);

                    if (!emp) {
                        console.error(`Could not find employee ${props.screening.personnelId}.`);
                    } else if (!!emp?.reportsToEmailName) {
                        const managerResult = await EmployeeClient.getBasicEmployeesByAlias(
                            authContext,
                            [emp.reportsToEmailName],
                        );
                        const manager = managerResult[0];
                        setManager(manager);
                        if (!manager) {
                            console.error(`Could not find manager ${emp.reportsToEmailName}.`);
                        }
                    } else {
                        console.log(
                            `No manager has been assigned for employee ${props.screening.personnelId}.`,
                        );
                    }
                } catch (e) {
                    const errorMessage = 'Error retrieving FTE employee or manager information';
                    console.error(errorMessage, e);
                    setErrorMessage(errorMessage);
                }
            } else if (props.screeningPath === ScreeningPaths.UsGov && props.screening.preHire) {
                try {
                    basicResult = await EmployeeClient.getBasicEmployeesByAlias(authContext, [
                        props.screening.preHire.manager,
                    ]);
                    const manager = basicResult[0];
                    setManager(manager);
                } catch (e) {
                    const errorMessage = "Error retrieving Pre-hire employee's manager information";
                    console.error(errorMessage, e);
                    setErrorMessage(errorMessage);
                }
            }
        }

        async function loadContracts(): Promise<void> {
            try {
                const contracts = await UsGovScreeningClient.getContracts(authContext, [
                    determineContractType(props.screeningPath),
                ]);
                const contractId = props.screening.contractId;
                setContract(contracts.results.filter((contract) => contract.id === contractId)[0]);
            } catch (e) {
                const errorMessage = 'Error retrieving Contracts Information information';
                console.error(errorMessage, e);
                setErrorMessage(errorMessage);
            }
        }

        loadContracts();
        loadPersonnelData();
    }, []);

    const getRequestDetails = (): JSX.Element => {
        if (props.screeningPath === ScreeningPaths.UsGov) {
            return (
                <>
                    <div className={styles.row}>
                        <div className={styles.key}>Request type</div>
                        <div className={styles.value}>
                            {ScreeningRequestTypesLabels[
                                props.screening
                                    .requestType as keyof typeof ScreeningRequestTypesLabels
                            ] ?? separateWordsByCapitalLetter(props.screening.requestType)}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Clearance level</div>
                        <div className={styles.value}>{props.screening.clearance}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Badge required</div>
                        <div className={styles.value}>
                            {props.screening.isCustomerBadgeRequired ? 'YES' : 'NO'}
                        </div>
                    </div>
                    {props.screening.access && props.screening.access.length > 0 && (
                        <div className={styles.row}>
                            <div className={styles.key}>Access required</div>
                            <div className={styles.multiValue}>
                                {DisplayBadges(props.screening.access)}
                            </div>
                        </div>
                    )}
                </>
            );
        } else if (props.screeningPath === ScreeningPaths.PublicTrust) {
            return (
                <>
                    <div className={styles.row}>
                        <div className={styles.key}>Request type</div>
                        <div className={styles.value}>
                            {ScreeningRequestTypesLabels[
                                props.screening
                                    .requestType as keyof typeof ScreeningRequestTypesLabels
                            ] ?? separateWordsByCapitalLetter(props.screening.requestType)}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Request agency</div>
                        <div className={styles.value}>
                            {props.screening?.publicTrustAgency
                                ? getAgencyEnumValueFromKey(
                                      props.screening?.publicTrustAgency,
                                      true,
                                  )
                                : ''}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Suitability level</div>
                        <div className={styles.value}>
                            {props.screening.suitabilityLevel
                                ? SuitabilityLevels[
                                      props.screening
                                          .suitabilityLevel as keyof typeof SuitabilityLevels
                                  ]
                                : ''}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>PIV required</div>
                        <div className={styles.value}>
                            {props.screening.isPIVRequired ? 'YES' : 'NO'}
                        </div>
                    </div>
                </>
            );
        } else {
            return <>Path {props.screeningPath} is not supported.</>;
        }
    };

    const invalidContractMessage = useCallback(
        (contract: string): ReactNode => {
            const contractType =
                props.screeningPath === ScreeningPaths.UsGov ? 'US Gov' : 'Public Trust';
            return (
                <>
                    <div>Correct the following error(s) and try again:</div>
                    <ul>
                        <li>
                            <strong>{contract}</strong> is not a valid and active{' '}
                            <strong>{contractType}</strong> contract. Contract owners cannot approve
                            or deny nominations against non-active contracts. Contact the
                            <a href='mailto:myclearance@microsoft.com'>
                                National Security Team (NST)
                            </a>{' '}
                            with the contract ID, project name, and customer to determine nomination
                            next steps.
                        </li>
                    </ul>
                </>
            );
        },
        [props.screeningPath],
    );

    const onSubmit = useCallback(
        async (authContext: IAuthContext, id: string): Promise<void> => {
            if (props.screeningPath === ScreeningPaths.UsGov) {
                if (acceptanceChoice !== AcceptanceChoice.UNSET) {
                    setIsSubmitting(true);
                    try {
                        const updatedScreening:
                            | IScreening
                            | undefined = await UsGovScreeningClient.approveDeny(
                            authContext,
                            id,
                            acceptanceChoice === AcceptanceChoice.ACCEPT
                                ? ApprovalOptions.Approve
                                : ApprovalOptions.Deny,
                        );
                        if (updatedScreening) {
                            props.updateScreeningInRawCandidates(
                                convertIScreeningToCommonScreening(updatedScreening),
                            );
                        }
                    } catch (error) {
                        if (isMounted()) {
                            if (typeof error === 'string') {
                                if (error.includes('contract is not active')) {
                                    const contractErrorMessage = invalidContractMessage(
                                        props.screening.contractId ?? 'unknown',
                                    );
                                    setErrorMessage(contractErrorMessage);
                                } else {
                                    setErrorMessage(error);
                                }
                            } else {
                                setErrorMessage(
                                    'An error occurred. Please contact the administrator.',
                                );
                            }
                        }
                    }
                    if (isMounted()) {
                        setIsSubmitting(false);
                    }
                } else {
                    if (isMounted()) {
                        setErrorMessage('Please approve or deny the nomination.');
                    }
                }
            } else {
                if (acceptanceChoice !== AcceptanceChoice.UNSET) {
                    setIsSubmitting(true);
                    try {
                        const updatedScreening:
                            | IPublicTrust
                            | undefined = await PublicTrustScreeningClient.contractOwnerApproveRejectNomination(
                            authContext,
                            id,
                            acceptanceChoice === AcceptanceChoice.ACCEPT,
                        );
                        const convertedScreening = convertIPublicTrustToCommonScreening(
                            updatedScreening,
                        );
                        if (updatedScreening) {
                            props.updateScreeningInRawCandidates(convertedScreening);
                        }
                    } catch (error) {
                        if (isMounted()) {
                            if (typeof error === 'string') {
                                if (error.includes('contract is not active')) {
                                    const contractErrorMessage = invalidContractMessage(
                                        props.screening.contractId ?? 'unknown',
                                    );
                                    setErrorMessage(contractErrorMessage);
                                } else {
                                    setErrorMessage(error);
                                }
                            }
                        }
                    }
                    setIsSubmitting(false);
                } else {
                    if (isMounted()) {
                        setErrorMessage('Please approve or deny the nomination.');
                    }
                }
            }
        },
        [acceptanceChoice, invalidContractMessage, isMounted, props],
    );

    function onChange(
        event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ): void {
        setAcceptanceChoice(
            option?.value === APPROVE_KEY ? AcceptanceChoice.ACCEPT : AcceptanceChoice.DECLINE,
        );
    }

    const isUsGovScreeningPath = props.screeningPath === ScreeningPaths.UsGov;

    const OwnerMessageBar = (): JSX.Element => {
        if (!errorMessage) return <></>;

        return <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>;
    };

    const panelProps = useMemo(
        () => ({
            isOpen: isOpen,
            type: PanelType.custom,
            customWidth: PANEL_WIDTH,
            onDismiss: (): void => setIsOpen(false),
            isLightDismiss: true,
            closeButtonAriaLabel: 'Cancel',
            isFooterAtBottom: true,
            isBlocking: true,
            headerText: 'Review nomination',
            styles: mergeStyleSets({ footer: { borderTop: '0px' } }),
            onRenderFooterContent: (): JSX.Element => (
                <div className={panelStyles.buttonContainer}>
                    <PrimaryButton
                        className={panelStyles.oneButton}
                        disabled={acceptanceChoice === AcceptanceChoice.UNSET || isSubmitting}
                        onClick={(): Promise<void> => onSubmit(authContext, props.screening.id)}
                        text='Submit'
                        allowDisabledFocus
                    />
                    <DefaultButton
                        className={panelStyles.oneButton}
                        disabled={isSubmitting}
                        style={{ marginRight: '10px' }}
                        text='Cancel'
                        onClick={(): void => setIsOpen(false)}
                        allowDisabledFocus
                    />
                </div>
            ),
        }),
        [isOpen, props.screening.id, acceptanceChoice, isSubmitting, authContext, onSubmit],
    );

    const containerComponentProps = useMemo(() => panelProps, [panelProps]);

    return (
        <>
            <ActionButton
                styles={{ root: { maxHeight: contentMaxHeight } }}
                iconProps={{ iconName: 'Inbox' }}
                allowDisabledFocus
                onClick={(): void => setIsOpen(true)}>
                View Nomination
            </ActionButton>
            {React.createElement(
                Panel,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                containerComponentProps as any,
                props.screening.id ? (
                    <div className={styles.rootPanel}>
                        <div>
                            <div className={styles.fullColumn}>
                                <div className={styles.cardWrapperPanel}>
                                    {employee && (
                                        <EmployeeCard
                                            employee={{
                                                alias: employee.onPremisesSamAccountName,
                                                department: employee.department,
                                                email: employee.mail,
                                                displayName: employee.displayName,
                                                jobTitle: employee.jobTitle,
                                                oid: employee.oid,
                                            }}
                                            managerAlias={manager?.onPremisesSamAccountName ?? ''}
                                            manager={manager?.displayName ?? ''}
                                            displayActions={false}
                                        />
                                    )}
                                    {!employee && isUsGovScreeningPath && props.screening.preHire && (
                                        <EmployeeCard
                                            employee={{
                                                alias: '',
                                                department: '',
                                                email: props.screening.contact?.homeEmail,
                                                displayName: `${props.screening.preHire.firstName} ${props.screening.preHire.lastName}`,
                                                jobTitle: props.screening.preHire.jobTitle,
                                            }}
                                            managerAlias={manager?.onPremisesSamAccountName ?? ''}
                                            manager={manager?.displayName ?? ''}
                                            displayActions={false}
                                        />
                                    )}
                                </div>
                                <div className={styles.contactDetailsWrapper}>
                                    <Separator
                                        alignContent='start'
                                        styles={separatorStylesPanel}
                                        key={'Contract_Details_Separator'}>
                                        Contract details
                                    </Separator>
                                    <div className={styles.row}>
                                        <div className={styles.key}>Contract ID</div>
                                        <div className={styles.value}>{contract?.id}</div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.key}>Project name</div>
                                        <div className={styles.value}>{contract?.project}</div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.key}>Customer</div>
                                        <div className={styles.value}>{contract?.customer}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.requestDetailsWrapper}>
                                <Separator
                                    className={separatorStyles.root}
                                    alignContent='start'
                                    styles={separatorStylesPanel}
                                    key={'Request_Details_Separator'}>
                                    Request details
                                </Separator>
                                {getRequestDetails()}
                            </div>
                            <div className={styles.fullRow}>
                                <div className={styles.personalContactWrapper}>
                                    <Separator
                                        alignContent='start'
                                        styles={separatorStylesPanel}
                                        key={'Nomination_Separator'}>
                                        Nomination
                                    </Separator>
                                    <div className={styles.flex}>
                                        <ActionButton
                                            // The purpose of this button is to catch the initial propagated click event
                                            // that caused to modal to open. Without this, the first interactive item's
                                            // onHover event will trigger, which is, in this case, the employee hover card,
                                            // making it assume the mouse is hovering on it and causing it to open.
                                            aria-hidden={true}
                                            style={{ maxHeight: 0, maxWidth: 0 }}
                                            hidden={true}
                                            onClick={(event): void => {
                                                event.stopPropagation();
                                            }}
                                        />
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.key}>Nominated by</div>
                                        <div className={styles.value}>
                                            <CandidateColumnContent
                                                personnelId={props.screening.nominatedBy}
                                                isInitialEmployeeBatchLoaded={
                                                    props.isInitialEmployeeBatchLoaded
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.key}>Nominated on</div>
                                        <div className={styles.value}>
                                            {isUsGovScreeningPath
                                                ? unixTimeStampToLocalDate(
                                                      props.screening.nominatedAtUtc,
                                                  )
                                                : dateToFormattedDateTimeString(
                                                      props.screening.nominatedAtUtc,
                                                  )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.fullRow100}>
                                <Separator
                                    alignContent='start'
                                    styles={separatorStylesPanel}
                                    key={'Business_Justification_Separator'}>
                                    Business justification
                                </Separator>
                                <div className={styles.businessJustification}>
                                    {props.screening.businessJustification}
                                </div>
                            </div>
                            <div className={styles.fullRow100}>
                                <Separator
                                    alignContent='start'
                                    styles={separatorStylesPanel}
                                    key={'Contract_Approval_Separator'}>
                                    Contract approval
                                </Separator>
                                <div>
                                    <ChoiceGroup
                                        options={CHOICES}
                                        onChange={onChange}
                                        required={true}
                                        selectedKey={
                                            acceptanceChoice === AcceptanceChoice.ACCEPT
                                                ? APPROVE_KEY
                                                : acceptanceChoice === AcceptanceChoice.DECLINE
                                                ? DENY_KEY
                                                : undefined
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <OwnerMessageBar />
                        {isSubmitting && (
                            <ProgressIndicator
                                styles={{ itemProgress: { padding: 0 } }}
                                ariaValueText='Submitting...'
                            />
                        )}
                    </div>
                ) : (
                    <></>
                ),
            )}
        </>
    );
}

function DisplayBadges(badges: string[]): JSX.Element {
    return (
        <>
            {badges
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort(specialAccessComparator)
                .map((x) => {
                    return RenderBadge(x);
                })}
        </>
    );
}
function RenderBadge(text: string): JSX.Element {
    return (
        <div key={text} className={styles.badgesStyles}>
            {text}
        </div>
    );
}

const UNIFORM_TOP_MARGIN = 20;
const UNIFORM_WIDTH = 400;
const UNIFORM_RIGHT_MARGIN = 50;
const UNIFORM_BOTTOM_MARGIN = 12;
const styles = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 950,
    },
    rootPanel: {
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        flex: '1 1 auto',
        borderTop: `4px solid ${SharedColors.cyanBlue10}`,
        display: 'flex',
        fontSize: FontSizes.xLargePlus,
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '25px 30px 10px 30px',
        boxSizing: 'border-box',
    },
    body: {
        padding: '0 30px 30px 30px',
    },
    flex: {
        display: 'flex',
    },
    row: {
        display: 'flex',
        padding: '5px 0',
    },
    cardWrapper: {
        marginRight: UNIFORM_RIGHT_MARGIN,
        marginBottom: UNIFORM_BOTTOM_MARGIN,
        width: UNIFORM_WIDTH,
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
    },
    cardWrapperPanel: {
        marginTop: UNIFORM_TOP_MARGIN,
        marginRight: UNIFORM_RIGHT_MARGIN,
        marginBottom: UNIFORM_BOTTOM_MARGIN,
        width: '100%',
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
    },
    personalContactWrapper: {
        width: UNIFORM_WIDTH,
        marginRight: UNIFORM_RIGHT_MARGIN,
        marginTop: 10,
    },
    contactDetailsWrapper: {
        width: UNIFORM_WIDTH,
        marginBottom: UNIFORM_BOTTOM_MARGIN,
        marginTop: 16,
    },
    requestDetailsWrapper: {
        width: UNIFORM_WIDTH,
    },
    fullRow: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '5px 0',
    },
    fullColumn: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '5px 0',
        flexDirection: 'column',
    },
    fullRow100: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        padding: '5px 0',
    },
    key: {
        width: 140,
        fontWeight: '600',
    },
    value: {
        width: 'calc(100% - 140px)',
    },
    multiValue: {
        display: 'flex',
        width: 'calc(100% - 140px)',
    },
    businessJustification: {
        maxWidth: 820,
        marginBottom: 10,
    },
    badgesStyles: {
        padding: '4px 10px',
        color: 'rgba(255, 255, 255, 1)',
        fontSize: 13,
        borderRadius: 1.5,
        backgroundColor: SharedColors.cyanBlue10,
        marginRight: 5,
    },
    footer: {
        backgroundColor: 'rgb(253, 253, 253)',
        padding: '14px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'flex-end',
    },
});
const separatorStyles = mergeStyleSets({
    root: {
        width: '100%',
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                opacity: 0.9,
            },
        },
    },
    content: {
        paddingLeft: 0,
        fontWeight: '600',
        marginTop: 5,
    },
});
const separatorStylesPanel = mergeStyleSets({
    root: {
        width: '100%',
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0)',
            },
        },
    },
    content: {
        paddingLeft: 0,
        fontWeight: '700',
        marginTop: 5,
    },
});
