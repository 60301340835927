import {
    ActionButton,
    DatePicker,
    DefaultButton,
    Dropdown,
    FontIcon,
    IPersonaProps,
    Label,
    Panel,
    PanelType,
    PrimaryButton,
    TextField,
    mergeStyleSets,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import React, { useCallback, useMemo, useState } from 'react';
import { IFilter } from 'components/core/common/filterableTable';
import { CoreSinglePrincipalPersonaPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import { datePlusDays, isoDateStringToUtcMilliseconds } from 'utils/time-utils';
import { AttributeDataType } from 'personnel-core-clients';
import { AttributesListColumnKeys } from 'components/core/attributes/attributes-list';

export interface IAttributeFilterPanelProps {
    isOpen?: boolean;
    onDismiss: () => void;
    onSuccess: (filters: Map<string, IFilter>) => void;
    onClearFilters: () => void;
    onMinimizePanel: () => void;
}

export default function AttributeFilterPanel(props: IAttributeFilterPanelProps): JSX.Element {
    const [attributeName, setAttributeName] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [modifiedAfter, setModifiedAfter] = useState<Date | undefined>();
    const [modifiedBefore, setModifiedBefore] = useState<Date | undefined>();
    const [owner, setOwner] = useState<IPersonaProps | undefined>();
    const [manager, setManager] = useState<IPersonaProps | undefined>();
    const [reader, setReader] = useState<IPersonaProps | undefined>();
    const [dataType, setDataType] = useState<string | undefined>();

    const [previousAttributeName, setPreviousAttributeName] = useState<string>();
    const [previousDescription, setPreviousDescription] = useState<string>();
    const [previousOwner, setPreviousOwner] = useState<IPersonaProps | undefined>();
    const [previousManager, setPreviousManager] = useState<IPersonaProps | undefined>();
    const [previousReader, setPreviousReader] = useState<IPersonaProps | undefined>();
    const [previousDataType, setPreviousDataType] = useState<string | undefined>();
    const [previousModifiedAfter, setPreviousModifiedAfter] = useState<Date | undefined>();
    const [previousModifiedBefore, setPreviousModifiedBefore] = useState<Date | undefined>();

    const buttonStyles = useMemo(() => ({ root: { marginRight: 8 } }), []);

    const savePreviousFilters = useCallback(() => {
        setPreviousAttributeName(attributeName);
        setPreviousDescription(description);
        setPreviousOwner(owner);
        setPreviousManager(manager);
        setPreviousReader(reader);
        setPreviousDataType(dataType);
        setPreviousModifiedAfter(modifiedAfter);
        setPreviousModifiedBefore(modifiedBefore);
    }, [
        attributeName,
        description,
        owner,
        manager,
        reader,
        dataType,
        modifiedAfter,
        modifiedBefore,
    ]);

    const saveFilters = useCallback((): Map<string, IFilter> => {
        const newFilter = new Map<string, IFilter>();
        newFilter.set(AttributesListColumnKeys.Attribute, { values: [attributeName || ''] });
        newFilter.set(AttributesListColumnKeys.Description, { values: [description || ''] });
        newFilter.set(AttributesListColumnKeys.Owners, {
            values: owner ? [owner.itemID ?? ''] : [],
        });
        newFilter.set(AttributesListColumnKeys.Managers, {
            values: manager ? [manager.itemID ?? ''] : [],
        });
        newFilter.set(AttributesListColumnKeys.Readers, {
            values: reader ? [reader.itemID ?? ''] : [],
        });
        newFilter.set(AttributesListColumnKeys.DataType, { values: [dataType || ''] });
        newFilter.set(AttributesListColumnKeys.LastModifiedBy, {
            values: [
                (isoDateStringToUtcMilliseconds(modifiedAfter?.toString()) / 1000).toString() ??
                    '0',
                (isoDateStringToUtcMilliseconds(modifiedBefore?.toString()) / 1000).toString() ??
                    '0',
            ],
        });

        return newFilter;
    }, [
        attributeName,
        description,
        owner,
        manager,
        reader,
        dataType,
        modifiedAfter,
        modifiedBefore,
    ]);

    const onSubmitClick = useCallback((): void => {
        savePreviousFilters();
        const newFilter = saveFilters();
        props.onSuccess(newFilter);
    }, [props, savePreviousFilters, saveFilters]);

    const restorePreviousFilters = useCallback(() => {
        setAttributeName(previousAttributeName);
        setDescription(previousDescription);
        setOwner(previousOwner);
        setManager(previousManager);
        setReader(previousReader);
        setDataType(previousDataType);
        setModifiedAfter(previousModifiedAfter);
        setModifiedBefore(previousModifiedBefore);
    }, [
        previousAttributeName,
        previousDescription,
        previousOwner,
        previousManager,
        previousReader,
        previousDataType,
        previousModifiedAfter,
        previousModifiedBefore,
    ]);

    const onCancel = useCallback((): void => {
        restorePreviousFilters();
        props.onDismiss();
    }, [props, restorePreviousFilters]);

    function clearFilters(): void {
        // Clear current filters
        setAttributeName('');
        setDescription('');
        setOwner(undefined);
        setReader(undefined);
        setManager(undefined);
        setDataType(undefined);
        setModifiedAfter(undefined);
        setModifiedBefore(undefined);

        // Clear previous filters
        setPreviousAttributeName('');
        setPreviousDescription('');
        setPreviousOwner(undefined);
        setPreviousManager(undefined);
        setPreviousReader(undefined);
        setPreviousDataType(undefined);
        setPreviousModifiedAfter(undefined);
        setPreviousModifiedBefore(undefined);

        props.onClearFilters();
    }

    const onRenderFooterContent = React.useCallback(
        () => (
            <div>
                <PrimaryButton onClick={onSubmitClick} styles={buttonStyles}>
                    {'Apply'}
                </PrimaryButton>
                <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
            </div>
        ),
        [buttonStyles, onCancel, onSubmitClick],
    );

    const DataTypeOptions = (Object.keys(AttributeDataType) as Array<AttributeDataType>).map(
        (v) => {
            return { key: v, text: v.toString() };
        },
    );

    return (
        <Panel
            headerText={'Filter'}
            isOpen={props.isOpen}
            closeButtonAriaLabel='Close'
            onRenderFooterContent={onRenderFooterContent}
            onDismiss={(): void => onCancel()}
            isLightDismiss
            onLightDismissClick={onCancel}
            isFooterAtBottom={true}
            type={PanelType.custom}
            customWidth={'360px'}
            styles={styles.panel}>
            <Label style={styles.label}>Attribute</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <TextField
                    placeholder='Filter by attribute'
                    value={attributeName}
                    maxLength={MaxLens.name}
                    onChange={(ev, newVal): void => {
                        setAttributeName(newVal);
                    }}
                    width={'450px'}
                    styles={styles.element}
                />
                <FontIcon
                    aria-label='Search'
                    iconName='Search'
                    className={mergedstyles.searchIcon}
                />
            </div>
            <Label style={styles.label}>Description</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <TextField
                    placeholder={'Filter by description'}
                    value={description}
                    maxLength={MaxLens.name}
                    styles={styles.element}
                    onChange={(ev, newVal): void => {
                        setDescription(newVal);
                    }}
                />
                <FontIcon
                    aria-label='Search'
                    iconName='Search'
                    className={mergedstyles.searchIcon}
                />
            </div>

            <Label style={styles.label}>Owner</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <CoreSinglePrincipalPersonaPickerTypeaheadSearch
                    ariaLabel='Owner'
                    placeHolder='Filter by owner'
                    required
                    onChange={(personna): void => {
                        setOwner(personna);
                    }}
                    selectedItem={owner}
                />
                <FontIcon
                    aria-label='Search'
                    iconName='Search'
                    className={mergedstyles.searchIcon}
                />
            </div>
            <Label style={styles.label}>Manager</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <CoreSinglePrincipalPersonaPickerTypeaheadSearch
                    ariaLabel='Manager'
                    placeHolder='Filter by manager'
                    required
                    onChange={(personna): void => {
                        setManager(personna);
                    }}
                    selectedItem={manager}
                />
                <FontIcon
                    aria-label='Search'
                    iconName='Search'
                    className={mergedstyles.searchIcon}
                />
            </div>
            <Label style={styles.label}>Reader</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <CoreSinglePrincipalPersonaPickerTypeaheadSearch
                    ariaLabel='Reader'
                    placeHolder='Filter by reader'
                    required
                    onChange={(personna): void => {
                        setReader(personna);
                    }}
                    selectedItem={reader}
                />
                <FontIcon
                    aria-label='Search'
                    iconName='Search'
                    className={mergedstyles.searchIcon}
                />
            </div>
            <Label style={styles.label}>Data type</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <Dropdown
                    placeholder='Filter by data type'
                    options={DataTypeOptions}
                    onChange={(ev, newVal): void => {
                        setDataType(newVal?.key.toString());
                    }}
                    selectedKey={dataType ?? ''}
                />
            </div>
            <Label style={styles.label}>Modified after</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <DatePicker
                    placeholder={'Select a date...'}
                    allowTextInput={true}
                    value={modifiedAfter}
                    maxDate={datePlusDays(new Date(), -1)}
                    onSelectDate={(date): void => {
                        setModifiedAfter(date ?? undefined);
                    }}
                />
            </div>
            <Label style={styles.label}>Modified before</Label>
            <div style={styles.element.root} className={mergedstyles.element}>
                <DatePicker
                    placeholder={'Select a date...'}
                    allowTextInput={true}
                    value={modifiedBefore}
                    onSelectDate={(date): void => {
                        setModifiedBefore(date ?? undefined);
                    }}
                />
            </div>
            <div style={{ marginTop: '10px' }}>
                <ActionButton
                    iconProps={{ iconName: IconNames.ClearFilter }}
                    onClick={(): void => clearFilters()}>
                    Clear filters
                </ActionButton>
            </div>
        </Panel>
    );
}

const MaxLens = {
    name: 32,
    description: 128,
    support: 250,
};

const styles = {
    label: {
        marginTop: '25px',
        padding: 0,
        height: '20px',
    },
    button: {
        marginTop: '20px',
        marginBottom: '5px',
        padding: 0,
    },
    element: {
        root: {
            marginTop: '5px',
            width: '312px',
            borderRadius: '4px',
            background: '#FFF',
            borderColor: '#D1D1D1',
        },
    },
    panel: {
        root: {
            marginTop: '65px',
        },
    },
};

const mergedstyles = mergeStyleSets({
    element: { position: 'relative' },
    searchIcon: {
        position: 'absolute',
        right: '6px',
        top: '2.5px',
        fontSize: '20px',
        transform: 'scaleX(-1)',
        color: '#616161',
    },
});
