import React, { ReactNode } from 'react';
import { SharedColors } from '@fluentui/theme';
import { mergeStyleSets, FontSizes, FontWeights } from '@fluentui/react';

interface CandidateNominationStepProps {
    stepTitle: string;
    stepSubTitle?: string;
    stepWidth?: number;
    children?: ReactNode;
}

const CandidateNominationStep: React.FunctionComponent<CandidateNominationStepProps> = ({
    stepSubTitle,
    children,
}) => {
    const bodyStyle = undefined;
    return (
        <div className={bodyStyle}>
            {stepSubTitle && stepSubTitle.trim() !== '' && <div>{stepSubTitle}</div>}
            <div className={stepStyles.stepPanel}>{children}</div>
        </div>
    );
};
const stepStyles = mergeStyleSets({
    header: {
        fontSize: FontSizes.xLargePlus,
        fontWeight: FontWeights.semibold,
        marginTop: '0px',
        marginBottom: '0px',
    },
    label: {
        flex: '1 1 auto',
        borderTop: `4px solid ${SharedColors.cyanBlue10}`,
        display: 'flex',
        fontSize: FontSizes.xLargePlus,
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '30px 35px 10px 35px',
        boxSizing: 'border-box',
    },
    labelPanel: {
        marginTop: 15,
    },
    labelStyles: {
        fontSize: FontSizes.medium,
        padding: '0 35px',
        wordBreak: 'keep-all', //choose between this and overflowBreak
    },
    step: {
        padding: '10px 35px 20px 35px',
    },
    stepPanel: {
        marginTop: 15,
    },
});
export default CandidateNominationStep;
