import React, { useCallback } from 'react';
import CoreEmployeeCard, {
    ICoreEmployeeCardProps,
} from 'components/core/common/employee-card/core-employee-card';
import ICardPrincipal from 'components/core/common/employee-card/card-principal';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import CardPrincipalProviderFromPrincipalRecord from 'components/core/common/employee-card/card-principal-provider-from-principal-record';

export interface ICoreEmployeeCardFromPrincipalRecordProps extends ICoreEmployeeCardProps {
    principalRecord?: IPrincipalRecord;
    onCardPrincipalProvided?: (data: ICardPrincipal | undefined) => void;
}

export default function CoreEmployeeCardFromPrincipalRecord(
    props: ICoreEmployeeCardFromPrincipalRecordProps,
): JSX.Element {
    const onRender = useCallback(
        (cardPrincipal?: ICardPrincipal): JSX.Element => {
            return <CoreEmployeeCard {...props} cardPrincipal={cardPrincipal} />;
        },
        [props],
    );

    return <CardPrincipalProviderFromPrincipalRecord {...props} onRender={onRender} />;
}
