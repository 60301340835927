import { Icon, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import { IPrincipalAttributeExpression } from 'clients/group-client';
import React from 'react';

export interface IHiddenConditionProps {
    ruleNumber: string;
}
export default function HiddenCondition(props: IHiddenConditionProps): JSX.Element {
    return (
        <div style={{ marginLeft: '37px' }}>
            <Stack style={{ marginTop: '5px' }}>
                <Stack.Item style={{ display: 'flex' }}>
                    <Icon
                        aria-label={'Hidden condition'}
                        iconName={IconNames.ChevronDown}
                        style={{
                            marginTop: '8px',
                            marginBottom: '8px',
                            marginRight: '9px',
                            height: '16px',
                            color: '#242424',
                        }}
                    />
                    <span
                        style={{
                            marginTop: '8px',
                            marginBottom: '8px',
                        }}>
                        Rule {props.ruleNumber}
                    </span>
                </Stack.Item>
            </Stack>

            <div style={{ margin: '8px', width: '21em' }}>
                <MessageBar messageBarType={MessageBarType.warning}>
                    You do not have permission to view this rule.
                </MessageBar>
            </div>
        </div>
    );
}

export const showValidCondition = (expression: IPrincipalAttributeExpression): boolean => {
    return expression.attributeId !== 'REDACTED';
};
