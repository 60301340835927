import React, { useCallback, useContext, useEffect, useState } from 'react';
import { GroupTimelineBin } from 'components/groups/manage-group/timeline/timeline-utils';
import { mergeStyleSets } from '@fluentui/react';
import { IGroup, IGroupActivity } from 'clients/group-client';
import { timelineEvent } from 'components/groups/manage-group/timeline/timeline-event';
import { ManageGroupsVariableContext } from 'components/groups/manage-groups/contexts/manage-groups-variable-context';
import { useFeatureFlag, FeatureFlagKeys } from 'utils/use-feature-flags';

interface TimelineEventBinsProps {
    group: IGroup;
    timelineEventBin: GroupTimelineBin[] | undefined;
    violationIdNameMap: Map<string, string>;
    // When number of displayed events is known, it calls the following function.
    onDisplayedEventsCount: (n: number) => void;
}

export default function TimelineEventBins(props: TimelineEventBinsProps): JSX.Element {
    const groupsContext = useContext(ManageGroupsVariableContext);

    const isFeatureFlagGroupsPOCEnabled = useFeatureFlag(FeatureFlagKeys.groupsPOC).enabled;
    const isFeatureFlagGroupsEnableDynamic = useFeatureFlag(
        FeatureFlagKeys.groupsEnableDynamicGroup,
    ).enabled;

    const [eventsArray, setEventsArray] = useState<JSX.Element[]>();

    useEffect(() => {
        timelineEventBins();
    }, [props.group, props.timelineEventBin, props.violationIdNameMap]);

    const timelineEventBins = useCallback((): void => {
        const eventBins: JSX.Element[] = [];
        let totalEventsVar = 0;
        props.timelineEventBin?.forEach((oneBin: GroupTimelineBin): void => {
            const binEvents = determineBinEvents(oneBin);
            if (binEvents.length > 0) {
                totalEventsVar += binEvents.length;
                eventBins.push(
                    <div key={`activities_${oneBin.dateStamp}`}>
                        <div className={styles.eventDateRow}>
                            <div className={styles.eventLine}></div>
                            <div className={styles.eventRowGap}></div>
                            <div>{oneBin.dateStamp}</div>
                        </div>
                        {binEvents}
                    </div>,
                );
            }
        });
        props.onDisplayedEventsCount(totalEventsVar);
        setEventsArray(eventBins);
    }, [props.timelineEventBin, props.violationIdNameMap, props.group]);

    const determineBinEvents = (bin: GroupTimelineBin): JSX.Element[] => {
        const binEventsVar: JSX.Element[] = [];
        bin.activities.forEach((activity: IGroupActivity): void => {
            const oneEvent = timelineEvent({
                key: `event_${activity.id}`,
                group: props.group,
                activity: activity,
                violationIdNameMap: props.violationIdNameMap,
                groupsContext,
                isFeatureFlagGroupsPOCEnabled,
                isFeatureFlagGroupsEnableDynamic,
            });
            if (!!oneEvent) {
                binEventsVar.push(oneEvent);
            }
        });
        return binEventsVar;
    };

    return <>{eventsArray}</>;
}

const styles = mergeStyleSets({
    eventLine: {
        height: '1px',
        marginTop: '10px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        flexGrow: 1,
    },
    eventDateRow: {
        marginBottom: '4px',
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'row',
    },
    eventRowGap: {
        width: '10px',
    },
});
