import React from 'react';
import { IStyle, mergeStyles } from '@fluentui/react';

interface IBadgeProps {
    text: string;
    width?: number;
    color?: string;
    height?: number;
    textAlign?: string;
    fontWeight?: string;
    borderColor?: string;
    backgroundColor?: string;
    marginRight?: string;
}

export default function Badge(props: IBadgeProps): JSX.Element {
    const {
        text,
        width,
        color,
        height,
        textAlign,
        fontWeight,
        borderColor,
        backgroundColor,
        marginRight,
    } = props;
    const styles: IStyle[] = [defaultstyles];
    if (color) {
        styles.push({ color });
    }
    if (width) {
        styles.push({ width });
    }
    if (height) {
        styles.push({ height });
    }
    if (textAlign) {
        styles.push({ textAlign });
    }
    if (fontWeight) {
        styles.push({ fontWeight });
    }
    if (borderColor) {
        styles.push({ border: `1px solid ${borderColor}` });
    }
    if (backgroundColor) {
        styles.push({ backgroundColor });
    }
    if (marginRight) {
        styles.push({ marginRight });
    }
    return <span className={mergeStyles(styles)}>{text}</span>;
}

const defaultstyles: IStyle = {
    color: 'rgba(0, 0, 0, .87)',
    display: 'inline-block',
    padding: '2px 7px',
    fontSize: '13px',
    borderRadius: '1.5px',
    backgroundColor: '#ddd',
    marginRight: '0',
};
