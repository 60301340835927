import { ICustomBreadcrumbItem } from 'components/common/bread-crumb';

// In the following, id can apply to a visitor Id or an employee Id
export const visitorProfileUrl = (id: string) => `/profile/user/${id}`;

export const visitorsProfileBreadcrumbs = (): ICustomBreadcrumbItem[] => [
    {
        title: 'Profile',
        link: '/profile',
    },
    {
        title: 'Visitor',
        link: '',
    },
];
