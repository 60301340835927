import {
    ISecurityGroup,
    SecurityGroupTableType,
    SecurityGroupOption,
    SecurityGroupDomain,
    IOwnedAdGroupResponse,
    SecurityGroupRequestType,
} from 'clients/group-client';
import { IGraphGroup } from 'clients/graph-client';

function getSecurityGroupOption(value: string | undefined): SecurityGroupOption | undefined {
    const valueVar = value && value.length > 0 ? value.trim() : null;
    switch (valueVar) {
        case SecurityGroupOption.CONFIDENTIAL:
        case SecurityGroupOption.GENERAL:
        case SecurityGroupOption.HIGHLY_CONFIDENTIAL:
        case SecurityGroupOption.PRIVATE:
        case SecurityGroupOption.PUBLIC:
            return valueVar;
        // See comment on declaration of SecurityGroupOption
        // regarding the following value.
        case 'HiddenMembership':
            return SecurityGroupOption.PRIVATE;
        default:
            console.error(`Unrecognized group option "${value}"`);
            return undefined;
    }
}

export function transformGraphGroups(graphGroups: IGraphGroup[]): ISecurityGroup[] {
    const groups: ISecurityGroup[] = [];
    if (graphGroups) {
        graphGroups.forEach((item) => {
            const type: SecurityGroupTableType =
                item.groupTypes && item.groupTypes.length === 0
                    ? SecurityGroupTableType.SECURITY
                    : SecurityGroupTableType.OFFICE;
            const options: SecurityGroupOption[] = [];
            if (!!getSecurityGroupOption(item.visibility)) {
                options.push(getSecurityGroupOption(item.visibility) as SecurityGroupOption);
            }
            if (item.securityEnabled === true) {
                options.push(SecurityGroupOption.SECURITY_ENABLED);
            }
            if (!!getSecurityGroupOption(item.classification)) {
                options.push(getSecurityGroupOption(item.classification) as SecurityGroupOption);
            }
            const group: ISecurityGroup = {
                id: item.id,
                name: item.displayName,
                mail: item.mail,
                type: type,
                options: options,
                domain: SecurityGroupDomain.UNDEFINED,
            };
            groups.push(group);
        });
    }
    return groups;
}

export function transformADGroups(adGroups: IOwnedAdGroupResponse[]): ISecurityGroup[] {
    if (adGroups) {
        const groups = adGroups
            .filter((group) => {
                if (
                    !SecurityGroupDomain[
                        group.domain.toUpperCase() as keyof typeof SecurityGroupDomain
                    ]
                ) {
                    console.error(
                        `Group domain "${group.domain}" of group "${group.name}" with guid "${group.guid}" not recognized.`,
                    );
                    return false;
                }
                return true;
            })
            .map((group) => {
                const securityGroup: ISecurityGroup = {
                    id: group.guid,
                    name: group.name,
                    mail: '',
                    type: SecurityGroupTableType.CoreIdentity,
                    options: [],
                    // The following type cast is safe because the above filter
                    // has already filtered out group domains that are not covered
                    // by the enum SecurityGroupDomain.
                    domain:
                        SecurityGroupDomain[
                            group.domain.toUpperCase() as keyof typeof SecurityGroupDomain
                        ],
                };
                return securityGroup;
            });
        return groups;
    } else {
        return [];
    }
}

export function convertSecurityGroupTableType(
    tableType: SecurityGroupTableType,
): SecurityGroupRequestType {
    switch (tableType) {
        case SecurityGroupTableType.SECURITY:
            return SecurityGroupRequestType.AZUREACTIVEDIRECTORY;
        case SecurityGroupTableType.OFFICE:
            return SecurityGroupRequestType.OFFICE;
        case SecurityGroupTableType.CoreIdentity:
            return SecurityGroupRequestType.ACTIVEDIRECTORY;
        case SecurityGroupTableType.MICROSOFTFEDERAL:
            return SecurityGroupRequestType.MICROSOFTFEDERAL;
        default:
            return SecurityGroupRequestType.UNDEFINED;
    }
}
