import config from 'environments/environment';
import {
    GetBlobResponseType,
    GetDefaultHttpOptions,
    GetMultiPartHttpOptions,
} from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import { IDocumentMetadata, IScreeningTemplateRequestRecord } from 'clients/template-client';

const nominationConfig = config.nominationServiceConfig;
/**
 * section for Public Trust Document services:
 * publictrustscreening/DocumentRequest/....
 */
class PublicTrustDocumentClient {
    static get publicTrustDocumentBaseUrl(): string {
        return `${nominationConfig.baseUrl}${nominationConfig.document.publicTrustRequestDocumentEndPoint}`;
    }

    static async getAllRequestableDocuments(
        authContext: IAuthContext,
        screeningId: string,
    ): Promise<IDocumentMetadata[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publicTrustDocumentBaseUrl}/requestables/for/${screeningId}`;
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Failed to get Documents for ${screeningId}`;
            default:
                throw result;
        }
    }

    static async saveRequestableDocument(
        authContext: IAuthContext,
        screeningId: string,
        putDocumentData: IDocumentMetadata,
        file?: File,
    ): Promise<IDocumentMetadata> {
        const formData = new FormData();
        formData.append('metadata', new Blob([JSON.stringify(putDocumentData)]));
        if (file) {
            formData.append('file', file);
        }
        if (putDocumentData.documentType) {
            formData.append('documentType', putDocumentData.documentType);
        }
        const httpOptions = {
            ...(await GetMultiPartHttpOptions(authContext)),
            method: 'POST',
            body: formData,
        };
        const url = `${this.publicTrustDocumentBaseUrl}/requestable/${screeningId}`;
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Failed to get Documents for ${screeningId}`;
            default:
                throw result;
        }
    }

    static async getDocument(authContext: IAuthContext, documentId: string): Promise<Response> {
        const httpOptions = await GetBlobResponseType(authContext);
        const url = `${this.publicTrustDocumentBaseUrl}/requestable/document/${documentId}`;
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result;
            case 404:
                throw `Failed to get Documents for ${documentId}`;
            default:
                throw result;
        }
    }

    static async updateDocument(
        authContext: IAuthContext,
        screeningDocumentRecord: IScreeningTemplateRequestRecord,
    ): Promise<IScreeningTemplateRequestRecord> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publicTrustDocumentBaseUrl}/request/records/changes`;
        httpOptions.method = 'POST';
        const requests: IScreeningTemplateRequestRecord[] = [screeningDocumentRecord];
        httpOptions.body = JSON.stringify(requests);

        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return new Promise<IScreeningTemplateRequestRecord>(async (resolve, reject) => {
                    const records = (await result.json()) as IScreeningTemplateRequestRecord[];
                    if (!!records && records.length === 1) {
                        const record = records[0];
                        resolve(record);
                    } else {
                        reject(undefined);
                    }
                });
            case 404:
                throw `Failed to handle document request`;
            default:
                throw result;
        }
    }

    static async updateMultipleDocuments(
        authContext: IAuthContext,
        screeningDocumentRecords: IScreeningTemplateRequestRecord[],
    ): Promise<IScreeningTemplateRequestRecord[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publicTrustDocumentBaseUrl}/request/records/changes`;
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(screeningDocumentRecords);
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Failed to update documents`;
            default:
                throw result;
        }
    }

    static async deleteDocumentRequest(
        authContext: IAuthContext,
        documentRequestId: string,
    ): Promise<void> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publicTrustDocumentBaseUrl}/request/records/${documentRequestId}/delete`;
        httpOptions.method = 'POST';
        const result = await fetch(url, httpOptions);
        if (result.status !== 200) {
            console.error(`Failed to delete document with Id ${documentRequestId}`);
            throw result;
        }
    }

    static async getDocumentRequestedForScreeningId(
        authContext: IAuthContext,
        screeningId: string,
    ): Promise<IScreeningTemplateRequestRecord[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publicTrustDocumentBaseUrl}/request/records/${screeningId}`;
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Failed to get Documents for ${screeningId}`;
            default:
                throw result;
        }
    }

    static async getDocumentMetadataFromDocumentIds(
        authContext: IAuthContext,
        documentIds: Set<string>,
    ): Promise<IDocumentMetadata[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publicTrustDocumentBaseUrl}/metadata/documentIds`;
        const documentIdsArray: string[] = [];
        documentIds.forEach((x) => documentIdsArray.push(x));
        httpOptions.body = JSON.stringify(documentIdsArray);
        httpOptions.method = 'POST';
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Failed to get Documents Meta Data`;
            default:
                throw result;
        }
    }
}

export default PublicTrustDocumentClient;
