import React from 'react';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { MessageBar, MessageBarType } from '@fluentui/react';
import pluralize from 'pluralize';
import { PolicyViolationType } from 'components/groups/manage-group/members/buttons/manage-group-members-add-new-member-modal-action-button';

interface IDisplayViolationCheck {
    violationCheck: PolicyViolationType;
}

export default function DisplayViolationCheck(props: IDisplayViolationCheck): JSX.Element {
    if (props.violationCheck.isChecking) {
        return <IsLoadingIndicator isLoading={true} msg='Checking policy violations...' />;
    }
    return (
        <>
            {!!props.violationCheck.violations && (
                <>
                    {props.violationCheck.violations.length > 0 && (
                        <MessageBar messageBarType={MessageBarType.error}>
                            <div>
                                Adding this member will create the following policy&nbsp;
                                {pluralize('violation', props.violationCheck.violations.length)}:
                            </div>
                            <ul>
                                {props.violationCheck.violations.map((violation) => (
                                    <li key={violation}>{violation}</li>
                                ))}
                            </ul>
                        </MessageBar>
                    )}
                    {props.violationCheck.violations.length === 0 && (
                        <div style={{ maxWidth: 'inherit' }}>
                            <MessageBar messageBarType={MessageBarType.success}>
                                <div>Adding this member will cause no policy violation.</div>
                            </MessageBar>
                        </div>
                    )}
                </>
            )}
            {!!props.violationCheck.errorMsg && (
                <div style={{ maxWidth: 'inherit' }}>
                    <MessageBar messageBarType={MessageBarType.error}>
                        <div>{props.violationCheck.errorMsg}</div>
                    </MessageBar>
                </div>
            )}
        </>
    );
}
