import React, { useMemo } from 'react';
import BarChart, { ChartItem } from 'components/common/charts/bar-chart';
import { reviewStates, reviewStatusDisplayText } from 'clients/sca-client';
import { getEmployeeReviewStateBackground } from 'components/sca/sca-constants';
import { Dictionary } from 'assets/constants/global-constants';
import { IScaExecOrgData } from 'components/sca/sca-constants';
import { IScaRecord } from 'clients/sca-client';

interface IMyOrgStatBarChartProps {
    tableRows: IScaExecOrgData[] | IScaRecord[];
}

export default function ScaReviewsStatBarChart(props: IMyOrgStatBarChartProps): JSX.Element {
    const tableRowStats = useMemo<Dictionary<number>>(() => {
        const tableRowStats: Dictionary<number> = {};
        props.tableRows.forEach((row) => {
            tableRowStats[row.reviewState] = (tableRowStats[row.reviewState] ?? 0) + 1;
        });
        return tableRowStats;
    }, [props.tableRows]);

    const barChartData = useMemo<ChartItem[]>(() => {
        const barChartData: ChartItem[] = [];
        let totalCount = 0;
        // Sorting legends based on length of the legend title made them look
        // better. Functionally it makes no different though to sort or not to.
        reviewStates()
            .sort((s1, s2) => s1.length - s2.length)
            .forEach((reviewState) => {
                const count = tableRowStats[reviewState] ?? 0;
                barChartData.push({
                    xAxis: reviewStatusDisplayText(reviewState) as string,
                    yAxis: count,
                    color: getEmployeeReviewStateBackground(reviewState),
                    // If necessary, borderColor will come here.
                    // borderColor?: string; // Value for CSS style "border-color"
                });
                totalCount += count;
            });
        barChartData.unshift({
            xAxis: 'Total',
            yAxis: totalCount,
            // In the following, "Total" is not a valid state.
            // It will therefore return a default color for the
            // "unfound" state "Total".
            color: getEmployeeReviewStateBackground('Total'),
        });
        return barChartData;
    }, [tableRowStats]);

    return <BarChart barChartData={barChartData} yAxisProps={{ intelligentMax: true }} />;
}
