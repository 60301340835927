import React, { useState } from 'react';
import { mergeStyleSets, Dropdown, IDropdownOption } from '@fluentui/react';
import PrimaryButtonNav from 'components/common/buttons/primary-button-nav';
import { globalStyles } from 'assets/styles/global-styles';

export interface PageSelectionOptionProps {
    title: string;
    description: string;
    dropdownPlaceholder?: string;
    selections: {
        link: string;
        linkDisplayText: string;
    }[];
}

export default function PageSelectionOption(props: PageSelectionOptionProps): JSX.Element {
    const { title, description, selections, dropdownPlaceholder } = props;
    const [selectedItem, setSelectedItem] = useState<IDropdownOption>();

    const onChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setSelectedItem(item);
    };

    const selection = (): JSX.Element => {
        if (selections.length === 1) {
            const { link, linkDisplayText } = selections[0];
            return (
                <div className={styles.selectionBox}>
                    <div className={styles.selectionText}>
                        <PrimaryButtonNav to={link} title={linkDisplayText}>
                            {linkDisplayText}
                        </PrimaryButtonNav>
                    </div>
                </div>
            );
        } else {
            const options = selections?.map((selection) => {
                const { link, linkDisplayText } = selection;
                return {
                    key: link,
                    text: linkDisplayText,
                };
            });

            return (
                <div className={styles.selectionBox}>
                    <div className={styles.dropdown}>
                        <Dropdown
                            styles={{ dropdownItems: globalStyles.dropdownItems }}
                            selectedKey={selectedItem ? selectedItem.key : undefined}
                            onChange={onChange}
                            placeholder={dropdownPlaceholder}
                            options={options}
                        />
                    </div>
                    <div className={styles.button}>
                        <PrimaryButtonNav
                            to={selectedItem?.key.toString() || ''}
                            disabled={!selectedItem?.key.toString()}
                            title='Go'>
                            Go
                        </PrimaryButtonNav>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            {selection()}
        </div>
    );
}

const styles = mergeStyleSets({
    container: {},
    imgContainer: {
        width: '320px',
        height: '180px',
    },
    title: {
        fontSize: '20px',
        lineHeight: '24px',
        opacity: '0.95',
        paddingTop: '1px',
        paddingBottom: '2px',
        marginTop: '37px',
    },
    description: {},
    selectionBox: {
        marginTop: '24px',
        minWidth: '300px',
    },
    selectionText: {
        lineHeight: '18.5px',
    },
    selectionLink: {},
    dropdown: {
        display: 'inline-block',
        width: '70%',
        verticalAlign: 'top',
        float: 'left',
    },
    button: {
        display: 'inline-block',
        width: '29%',
        float: 'right',
    },
});
