import React from 'react';
import { AccessDeniedURL } from 'assets/constants/global-constants';
import CheckRole from 'components/common/check-role';
import { Role } from 'configs/roles';
import ScaReviewPeriodFiltersProvider from 'components/sca/contexts/sca-review-period-filter-context';
import ScaViewMyOrg from 'components/sca/my-org/sca-view-my-org';

export default function ScaViewMyOrgFilterWrapper(): JSX.Element {
    return (
        // SCA Auth
        <CheckRole requiredRolesAny={[Role.SCAExec]} redirectNotInRole={AccessDeniedURL}>
            <ScaReviewPeriodFiltersProvider>
                <ScaViewMyOrg />
            </ScaReviewPeriodFiltersProvider>
        </CheckRole>
    );
}
