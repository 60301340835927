import React from 'react';
import { IconNames } from 'assets/constants/global-constants';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { doNothing } from 'utils/misc-utils';
import BoldFont from 'components/common/misc/bold-font';

interface IDeleteModelKeyModalProps {
    modelKey: string;
    handleDeleteModelKey: (field: string) => Promise<void>;
}

export default function DeleteModelKeyModalButton(props: IDeleteModelKeyModalProps): JSX.Element {
    return (
        <ModalActionButton
            text={''}
            iconName={IconNames.Cancel}
            modalTitle={'Delete Model Key'}
            modalTitleIcon={IconNames.Delete}
            submitButtonText={'Delete'}
            onSubmit={(): Promise<void> => props.handleDeleteModelKey(props.modelKey)}
            enableSubmit={true}
            onButtonClick={doNothing}
            onModalConcluded={doNothing}>
            Are you sure you want to delete the Model Key: <BoldFont>{props.modelKey}?</BoldFont>
        </ModalActionButton>
    );
}
