import { IDropdownOption, mergeStyles, Stack } from '@fluentui/react';
import { inputFieldErrorColor } from 'assets/constants/global-colors';
import { IconNames } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import GroupClient, { GroupRole, IApplicationGroupMembership, IGroup } from 'clients/group-client';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { ModalSizeType } from 'components/common/modal';
import Spacer from 'components/common/spacer';
import { useDropdown } from 'components/common/use-input/use-dropdown';
import { useTextField } from 'components/common/use-input/use-textfield';
import { ModalAddEditType } from 'components/groups/groups-utils';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useMemo } from 'react';
import { toTitleCase } from 'utils/string-utils';

export interface IAddEditAppModalActionButtonProps {
    mode: ModalAddEditType;
    app?: IApplicationGroupMembership;
    group: IGroup;
    canTouchRoleOrApp: boolean;
    onAppAddOrUpdate: (role: IApplicationGroupMembership, mode: ModalAddEditType) => void;
}

const dropdownOption = (role: GroupRole): IDropdownOption => {
    return {
        key: role,
        text: toTitleCase(role),
    };
};

const dropdownOptions = [GroupRole.MEMBER, GroupRole.AUDITOR].map((role) => {
    return dropdownOption(role);
});

const justificationMaxLen = 500;

export default function AddEditAppModalActionButton(
    props: IAddEditAppModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const {
        value: appName,
        theElement: appNameInputElement,
        initialize: initAppName,
    } = useTextField({
        label: 'App Name',
        required: true,
        maxLength: 50,
    });

    const { value: appId, theElement: appIdInputElement, initialize: initAppId } = useTextField({
        label: 'App Id',
        required: true,
    });

    const {
        selectedOption: selectedRole,
        theElement: roleDropdownElement,
        initializeFromKey: initSelectedRole,
    } = useDropdown({
        label: 'Role',
        options: dropdownOptions,
        placeholder: 'Select role',
        required: true,
        styles: { dropdownItems: globalStyles.dropdownItems },
    });

    const {
        value: justification,
        theElement: justificationInputElement,
        initialize: initJustification,
    } = useTextField({
        onRenderLabel: (): JSX.Element => {
            return (
                <Stack horizontal horizontalAlign='space-between'>
                    <span>
                        <span>Provide a business justification for this group role addition</span>
                        <span>&nbsp;</span>
                        {props.group.requireJustification && (
                            <span className={mergeStyles({ color: inputFieldErrorColor })}>
                                *&nbsp;
                            </span>
                        )}
                    </span>
                    <span className={justificationLengthStyle}>
                        {justification?.length ?? 0}/{justificationMaxLen}
                    </span>
                </Stack>
            );
        },
        rows: 5,
        maxLength: justificationMaxLen,
        multiline: true,
        resizable: false,
        ariaLabel: 'Business Justification',
    });

    const isAddMode = props.mode === ModalAddEditType.add;

    const isSubmitEnabled = useMemo(() => {
        const isJustificationOk = props.group.requireJustification ? !!justification : true;
        const isOtherValuesOk = !!appName && !!appId && !!selectedRole?.key;
        const isValuesOk = isJustificationOk && isOtherValuesOk;

        if (isAddMode) {
            return isValuesOk;
        } else {
            const isAppNameModified = appName !== props.app?.applicationName;
            const isAppRoleModified = selectedRole?.key !== props.app?.role;
            const isJustificationModified = justification !== props.app?.justification;
            return (
                isValuesOk && (isAppNameModified || isAppRoleModified || isJustificationModified)
            );
        }
    }, [
        appName,
        appId,
        selectedRole,
        props.app,
        props.group,
        justification,
        isAddMode,
        dropdownOptions,
    ]);

    const onButtonClick = (): void => {
        initAppId(props.app?.applicationId);
        initAppName(props.app?.applicationName);
        initJustification(props.app?.justification);
        initSelectedRole(props.app?.role);
    };

    const onSubmit = async (): Promise<IApplicationGroupMembership> => {
        if (!selectedRole?.key) {
            // modal action button will catch the error and display it.
            throw 'Role is not selected';
        }
        if (!appId) {
            throw 'App ID is not specified';
        }
        if (props.group.requireJustification && !justification) {
            throw 'Justification must be entered for this group';
        }
        if (!appName) {
            throw 'Application name is not specified';
        }
        if (isAddMode) {
            return GroupClient.addApplicationGroupMembership(authContext, {
                groupId: props.group.id,
                applicationId: appId,
                applicationName: appName,
                // The following type cast is safe because the dropdown
                // options are all GroupRole.
                role: selectedRole.key as GroupRole,
                justification: justification ?? '',
                sponsorId: '',
            });
        } else {
            return GroupClient.updateApplicationGroupMembership(authContext, {
                groupId: props.group.id,
                applicationId: appId,
                applicationName: appName,
                // The following type cast is safe because the dropdown
                // options are all GroupRole.
                role: selectedRole.key as GroupRole,
                justification: justification ?? '',
                // The following type cast is safe because if props.app was
                // undefined, the code would never reach here.
                // Besides, on update mode it's the parent that determines
                // value of this _etag, not the user.
                // eslint-disable-next-line @typescript-eslint/naming-convention
                _etag: (props.app as IApplicationGroupMembership)._etag,
            });
        }
    };

    const onModalConcluded = async (
        conclusion: ModalConclusion,
        result?: IApplicationGroupMembership,
    ): Promise<void> => {
        if (conclusion === ModalConclusion.Done && !!result) {
            props.onAppAddOrUpdate(result, props.mode);
        }
    };

    return (
        <ModalActionButton<IApplicationGroupMembership>
            text={isAddMode ? 'Add App' : 'Edit'}
            size={ModalSizeType.mediumLarge}
            fixWidth={true}
            enable={!!props.group.id && !!props.group.name && props.canTouchRoleOrApp}
            iconName={isAddMode ? IconNames.AppIconDefaultAdd : IconNames.Edit}
            modalTitle={isAddMode ? 'Add App' : 'Edit App'}
            modalSubtitle={props.group.name}
            enableSubmit={isSubmitEnabled}
            submitButtonText={isAddMode ? 'Add' : 'Update'}
            onSubmit={onSubmit}
            onButtonClick={onButtonClick}
            onModalConcluded={onModalConcluded}>
            {appNameInputElement()}
            {isAddMode && appIdInputElement()}
            {roleDropdownElement()}
            <Spacer marginTop={10} />
            {justificationInputElement()}
        </ModalActionButton>
    );
}

const justificationLengthStyle = mergeStyles({
    width: '55px',
    textAlign: 'right',
    marginRight: 1,
});
