import {
    ActionButton,
    IColumn,
    MessageBar,
    MessageBarType,
    Pivot,
    PivotItem,
    Stack,
    Toggle,
} from '@fluentui/react';
import { Dictionary, IconNames } from 'assets/constants/global-constants';
import { columnHeaderStyles, expandedTableWidth } from 'assets/styles/list-styles';
import EmployeeClient, {
    IBasicEmployee,
    IEmployeeEditableResponse,
    transformPrehireToEmployeeObject,
} from 'clients/employee-client';
import ReportsClient, { IHierarchy } from 'clients/reports-client';
import UsGovScreeningClient, {
    IScreeningRecordUSGovExcelRequest,
    IScreeningRecordUSGovExcelResponse,
} from 'clients/screening/us-gov-screening-client';
import { ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { ScreeningPageNames } from 'components/common/constants';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import {
    getEmployeeType,
    IEmployeeNameAndType,
    PersonnelTypes,
} from 'components/common/employee/internal-employee-utils';
import HorizontalBar from 'components/common/horizontal-bar';
import Spacer from 'components/common/spacer';
import { Table } from 'components/common/table';
import CandidateNominationStepperCommon from 'components/screening/common/candidate-nomination-stepper-common';
import {
    defaultNoReportToPersonnelId,
    getPublicTrustStatusDisplay,
    getStatusDisplayByStateName,
    getStatusText,
    getStatusTextByStateName,
    metricsTitle,
    pageNameMap,
    recordsTitle,
    ScreeningPaths,
    ScreeningRequestTypesLabels,
} from 'components/screening/common/common-constants';
import { filterWidth, pageStyle } from 'components/screening/common/common-tab-styling';
import CandidatesPageHelp from 'components/screening/common/components/candidate-page-help';
import MyOrgSevereWarningMessage from 'components/screening/common/components/my-org-severe-warning-message';
import ScreeningAttentionMessage from 'components/screening/common/components/screening-attention-message';
import {
    getContracts,
    getLatestHierarchyReport,
    updateHierarchyCache,
} from 'components/screening/common/content-tabs-help';
import { isUserContractOwnerOrNST } from 'components/screening/common/filters/filter-help';
import {
    convertIScreeningToCommonScreening,
    ICommonScreening,
} from 'components/screening/common/ICommonScreening';
import useHasMyOrgAccess from 'components/screening/common/use-has-my-org-access';
import PublicTrustScreeningChart from 'components/screening/public-trust/candidates-listing/metrics/public-trust-screening-chart';
import { useCanShowContractAdminPage } from 'components/screening/screening-utils';
import AllCustomerMetricTable from 'components/screening/us-gov/candidates-listing/tabpanel-contents/all-customer-metric-table';
import DetailCustomerMetricModal from 'components/screening/us-gov/candidates-listing/tabpanel-contents/detail-customer-metric-modal';
import UsGovBulkUploadModal from 'components/screening/us-gov/candidates-listing/tabpanel-contents/us-gov-bulk-upload-modal';
import { CandidateFilter } from 'components/screening/us-gov/candidates/listing/candidate-filter';
import { getCandidatesTableColumns } from 'components/screening/us-gov/candidates/listing/get-candidates-table-columns';
import { IContract } from 'components/screening/us-gov/IContract';
import { IScreeningResult } from 'components/screening/us-gov/IScreening';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext, BreadCrumbHelper } from 'contexts/breadcrumb-context';
import { CacheContext } from 'contexts/cache-context';
import { FiltersContext, IFiltersContext } from 'contexts/filters-context';
import { UserContext } from 'contexts/user-context';
import { default as config } from 'environments/environment';
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { partitionArray } from 'utils/array-utils';
import { BrowserCacheKeys } from 'utils/browser-cache-utils';
import { excelFormatHeader } from 'utils/reporting-utils';
import { numCmp, SortDescending, strCmp, useSortColumnHandler } from 'utils/sort-utils';
import { isGUID } from 'utils/string-utils';
import { dateToFormattedDateTimeStringFromSeconds, TimeFormats } from 'utils/time-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

export interface ContentCandidatesTabProps {
    pageName: string;
    pathName: string;
}

const defaultBreadCrumbs: ICustomBreadcrumbItem[] = [
    {
        title: 'Screening',
        link: '',
    },
    {
        title: 'US Gov',
        link: '',
    },
];

export default function ContentCandidatesTab(props: ContentCandidatesTabProps): JSX.Element {
    const {
        isMyOrgPage,
        isManagePage,
        isMyNomineesPage,
        isMyContractsPage,
        isMyScreeningsPage,
    } = useMemo(() => {
        return {
            isMyOrgPage: props.pageName === ScreeningPageNames.MyOrg,
            isManagePage: props.pageName === ScreeningPageNames.Manage,
            isMyNomineesPage: props.pageName === ScreeningPageNames.MyNominees,
            isMyContractsPage: props.pageName === ScreeningPageNames.MyContracts,
            isMyScreeningsPage: props.pageName === ScreeningPageNames.MyScreenings,
        };
    }, [props.pageName]);

    const breadCrumbContext = useContext(BreadCrumbContext);
    const authContext = useContext(AuthContext);
    const cacheContext = useContext(CacheContext);
    const filtersContext = useContext(FiltersContext);
    const userContext = useContext(UserContext);

    const canShowContractAdminPage = useCanShowContractAdminPage(authContext);

    const cacheKey = `${filtersContext.location.pathname}${filtersContext.location.search}`; // the URL of the page
    const cacheKeyEmployeeNames = `${filtersContext.location.pathname}${filtersContext.location.search}EmployeeNames`; // the URL of the page
    const cacheKeyCustomerMetrics = `${filtersContext.location.pathname}${filtersContext.location.search}CustomerMetrics`; // the URL of the page + CustomerMetrics
    const cacheKeyContract = `${cacheKey}ContractsSetKey`;
    const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false);
    const [errorMessage, setMessage] = useState<string>('');
    const [filteredCandidates, setFilteredCandidates] = useState<ICommonScreening[]>([]);
    const [hasErrorOccurred, setErrorOccurred] = useState<boolean>(false);
    const [isDoneLoadingScreenings, setDoneLoadingScreenings] = useState<boolean>(false);
    const [isExpandButtonShown, setisExpandButtonShown] = useState<boolean>(true);
    const [showDownloadExcelButton, setShowDownloadExcelButton] = useState<boolean>(false);
    const [shouldScreeningChartBeShown, setShouldScreeningChartBeShown] = useState<boolean>(false);
    const [isInitialEmployeeBatchLoaded, setIsInitialEmployeeBatchLoaded] = useState<boolean>(
        false,
    );
    const [isPageFetchingInitialNominationData, setIsPageFetchingInitialNominationData] = useState<
        boolean
    >(false);
    const [isShowNominationProcess, setShowNominationProcess] = useState<boolean>(false);
    const { tab } = useParams<{ tab: 'string' }>();
    const [showAdvancedMetrics, setShowAdvancedMetrics] = useState<boolean>(false);
    const [rawCandidates, setRawCandidates] = useState<ICommonScreening[]>([]);
    const [
        employeeIdsWithFutureTerminationDates,
        setEmployeeIdsWithFutureTerminationDates,
    ] = useState<string[]>([]);
    const [employeeNames, setEmployeeNames] = useState<Map<string, IEmployeeNameAndType>>(
        new Map<string, IEmployeeNameAndType>(),
    );
    const [contracts, setContracts] = useState<Dictionary<IContract>>({});
    const [hierarchyRecords, setHierarchyRecords] = useState<Dictionary<IHierarchy>>();
    const [tableColumns, setTableColumns] = useState([] as IColumn[]);
    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler(
        'Status Changed On',
        SortDescending,
    );
    const [isDirectReportSelected, setDirectReportSelected] = useState<boolean>(isMyOrgPage);
    const [reportsToOrgDict, setReportsToOrgDict] = useState<Dictionary<number>>();
    const [highestManager, setHighestManager] = useState<number>(defaultNoReportToPersonnelId);
    const [isDetailWindowOpen, setIsDetailWindowOpen] = useState<boolean>(false);
    const [currentDetailWindowScreening, setCurrentDetailWindowScreening] = useState<
        ICommonScreening | undefined
    >(undefined);
    const [isContractOwnerOrNst, setIsContractOwnerOrNST] = useState<boolean>(false);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

    const history = useHistory();
    const MaxExcelDataBatch = 2000;

    const { hasMyOrgAccess } = useHasMyOrgAccess();

    function findHighestManager(reportToDict: Dictionary<number>): number {
        if (reportToDict) {
            let currentPersonnelId = Object.values(reportToDict)[0];
            // get first id in reportToDict and loop up
            while (reportToDict[currentPersonnelId]) {
                const nextHighestManager = reportToDict[currentPersonnelId];
                if (!nextHighestManager) {
                    break;
                }
                currentPersonnelId = reportToDict[currentPersonnelId];
            }
            return currentPersonnelId;
        }
        return defaultNoReportToPersonnelId;
    }

    async function getInitialCandidates(filterCtx: IFiltersContext): Promise<void> {
        if (
            isMyOrgPage &&
            !config.personnelProfile.isUsCitizenTestingFlag &&
            !userContext.employeeRecord.isUSCitizen
        ) {
            return;
        }
        setIsPageFetchingInitialNominationData(true);
        try {
            const results: IScreeningResult = await UsGovScreeningClient.getPagedScreenings(
                authContext,
                filterCtx,
                isMyOrgPage,
            );
            let screeningArray = results.results.map((screening) =>
                convertIScreeningToCommonScreening(screening),
            );
            let filteredCandidates = screeningArray;
            if (results.continuationToken) {
                screeningArray = await getMoreScreenings(
                    screeningArray,
                    filterCtx,
                    results.continuationToken,
                );
            }

            if (isMyOrgPage) {
                const reportToDict = await UsGovScreeningClient.getReportToScreening(authContext);
                setReportsToOrgDict(reportToDict);
                const newhighestManager = findHighestManager(reportToDict);
                setHighestManager(newhighestManager);
                if (isDirectReportSelected) {
                    filteredCandidates = screeningArray.filter(
                        (screening) => reportToDict[screening.personnelId] === highestManager,
                    );
                }
            }
            const contractDictionary = await getContracts(
                screeningArray,
                cacheContext,
                cacheKeyContract,
                authContext,
            );
            setRawCandidates(screeningArray);
            setFilteredCandidates(filteredCandidates);
            setContracts(contractDictionary);
            batchFetchCandidateEmployeeData(screeningArray);
            cacheContext.store<ICommonScreening[]>(cacheKey, screeningArray);
            getEmployeeIdsWithFutureTerminationDates(screeningArray.map((x) => x.personnelId));
            setDoneLoadingScreenings(true);
        } catch (e) {
            setFilteredCandidates([]);
            if (isMyNomineesPage) {
                updateMessage(true, 'No Nominations to show');
            }
        }
    }

    async function getMoreScreenings(
        screeningArray: ICommonScreening[],
        filterCtx: IFiltersContext,
        continuationToken?: string,
    ): Promise<ICommonScreening[]> {
        try {
            let results: IScreeningResult;
            let useContinuationToken: string | undefined = continuationToken;
            do {
                results = await UsGovScreeningClient.getPagedScreenings(
                    authContext,
                    filterCtx,
                    isMyOrgPage,
                    useContinuationToken,
                );
                const moreScreening = results.results.map((screening) =>
                    convertIScreeningToCommonScreening(screening),
                );
                screeningArray = [...screeningArray, ...moreScreening];
                populateEmployeeDataCache(moreScreening);
                useContinuationToken = results.continuationToken;
            } while (useContinuationToken);
        } catch (e) {
            updateMessage(true, 'Failed to get all records');
        } finally {
            return screeningArray;
        }
    }

    function checkIfMetricsTabFromURL(): void {
        if (
            !isMyScreeningsPage &&
            tab &&
            tab.toLocaleLowerCase().localeCompare(metricsTitle.toLocaleLowerCase()) === 0
        ) {
            setShowAdvancedMetrics(true);
        }
    }

    async function getEmployeeIdsWithFutureTerminationDates(personnelIds: string[]): Promise<void> {
        const isContractOwnerOrNST = isUserContractOwnerOrNST(userContext, ScreeningPaths.UsGov);

        if (
            personnelIds.length > 0 &&
            isContractOwnerOrNST &&
            (isMyContractsPage || isManagePage)
        ) {
            const results = await UsGovScreeningClient.getEmployeeIdsWithFutureTerminationDates(
                authContext,
                personnelIds,
            );
            setEmployeeIdsWithFutureTerminationDates(results);
        }
    }

    useEffect(() => {
        async function updateContracts(screeningArray: ICommonScreening[]): Promise<void> {
            if (screeningArray.length > 0) {
                const newContracts = await getContracts(
                    screeningArray,
                    cacheContext,
                    cacheKeyContract,
                    authContext,
                );
                setContracts(newContracts);
                cacheContext.store<Dictionary<IContract>>(cacheKeyContract, newContracts);
            }
        }

        checkIfMetricsTabFromURL();
        const screeningArray = cacheContext.retrieve<ICommonScreening[]>(cacheKey);
        if (screeningArray) {
            setRawCandidates(screeningArray);
            let filteredCandidates = screeningArray;
            if (isMyOrgPage && isDirectReportSelected && reportsToOrgDict) {
                filteredCandidates = screeningArray.filter(
                    (screening) => reportsToOrgDict[screening.personnelId] === highestManager,
                );
            }
            setFilteredCandidates(filteredCandidates);
            setIsPageFetchingInitialNominationData(false);
            setIsInitialEmployeeBatchLoaded(true);
            updateContracts(screeningArray);
            getEmployeeIdsWithFutureTerminationDates(screeningArray.map((x) => x.personnelId));
            setDoneLoadingScreenings(true);
        } else {
            getInitialCandidates(filtersContext);
        }
    }, []);

    useEffect(() => {
        if (userContext.isUsGovScreeningUserTypesLoaded) {
            const isContractOwnerOrNST = isUserContractOwnerOrNST(
                userContext,
                ScreeningPaths.UsGov,
            );
            if (isContractOwnerOrNST) {
                const storedRecords = localStorage.getItem(
                    BrowserCacheKeys.hierarchyReportStorageAndCacheKey,
                );
                const hierarchyReportMap: Dictionary<IHierarchy> = storedRecords
                    ? JSON.parse(storedRecords)
                    : undefined;

                if (hierarchyReportMap) {
                    setHierarchyRecords(hierarchyReportMap);
                }
                getLatestHierarchyReport(
                    rawCandidates,
                    authContext,
                    setHierarchyRecords,
                    updateMessage,
                );
            }
            setIsContractOwnerOrNST(isContractOwnerOrNST);
        }
    }, [userContext.isUsGovScreeningUserTypesLoaded]);

    useEffect(() => {
        // don't show for My Screening page only
        setShowDownloadExcelButton(!isMyScreeningsPage);
    }, [props.pageName]);

    useEffect(() => {
        setShouldScreeningChartBeShown(rawCandidates.length > 0 && !isMyScreeningsPage);
    }, [rawCandidates, props.pageName]);

    useEffect(() => {
        function filterData(): void {
            let finalData = rawCandidates;
            filtersContext.filterFunctionsMap.forEach((filterFunctions: any[]) => {
                if (filterFunctions!.length > 0) {
                    finalData = finalData.filter((data) => {
                        for (const filterFunction of filterFunctions!) {
                            if (filterFunction.func(data)) {
                                return true;
                            }
                        }
                        return false;
                    });
                }
            });
            if (isMyOrgPage && isDirectReportSelected && reportsToOrgDict) {
                finalData = finalData.filter(
                    (screening) => reportsToOrgDict[screening.personnelId] === highestManager,
                );
            }
            setFilteredCandidates(finalData);
        }
        filterData();
    }, [filtersContext, rawCandidates, isDirectReportSelected]);

    useEffect(() => {
        {
            (props.pageName !== ScreeningPageNames.MyOrg ||
                (hasMyOrgAccess && props.pageName === ScreeningPageNames.MyOrg)) &&
                setTableColumns(
                    getCandidatesTableColumns({
                        updateScreeningInRawCandidates,
                        isInitialEmployeeBatchLoaded,
                        sortColumn,
                        sortAscending: sortAscending === 1,
                        sortColumnHandler,
                        openDetails: openIndividualDetailsWindow,
                        pageName: props.pageName,
                        contractsDict: contracts,
                    }),
                );
        }
    }, [
        rawCandidates, // because it affects updateScreeningInRawCandidates
        isInitialEmployeeBatchLoaded,
        sortColumn,
        sortAscending,
        hasMyOrgAccess,
    ]);

    useEffect(() => {
        function handleResize(): void {
            setisExpandButtonShown(window.innerWidth < filterWidth + expandedTableWidth);
        }
        window.addEventListener('resize', handleResize);
        handleResize(); // check the size of the screen on page load

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function updateScreeningInRawCandidates(updatedScreening: ICommonScreening): void {
        const updatedRawCandidates = rawCandidates.slice(0);
        const screenIdx = updatedRawCandidates.findIndex((x) => x.id === updatedScreening.id);
        updatedRawCandidates[screenIdx] = updatedScreening;
        setRawCandidates(updatedRawCandidates);
    }

    /**
     * This is a greedy method just to pre-cache employee data, because it might be shown on the UI
     *
     * Very close to batchFetchCandidateEmployeeData but different in that this will not
     * capture the manager data and the UI will not be held up in rendering.
     * Primary used for data that is not going to be rendering right away.
     */
    async function populateEmployeeDataCache(candidates: ICommonScreening[]): Promise<void> {
        try {
            const initialPersonnelIdsToFetch = new Set<string>();
            candidates.forEach((screen: ICommonScreening) => {
                if (screen.personnelId) {
                    initialPersonnelIdsToFetch.add(screen.personnelId);
                }
                if (screen.nominatedBy) {
                    initialPersonnelIdsToFetch.add(screen.nominatedBy);
                }
                if (screen.processOwnerId) {
                    initialPersonnelIdsToFetch.add(screen.processOwnerId);
                }
            });

            if (initialPersonnelIdsToFetch.size > 0) {
                EmployeeClient.getBasicEmployeesById(
                    authContext,
                    Array.from(initialPersonnelIdsToFetch),
                );
            }
        } catch (e) {
            updateMessage(
                true,
                'Error resolving alias/ids in populateEmployeeDataCache calling employee-services',
            );
        }
    }

    /**
     * This is a greedy method to handle the first Page of Screening records that do end up on the
     *  UI therefor also want to capture there managers info and make sure nothing renders until
     * this function is done.
     *
     * Very close to populateEmployeeDataCache, but different in that this is pulling manager
     * and will hold the UI from rendering until complete.
     */
    async function batchFetchCandidateEmployeeData(candidates: ICommonScreening[]): Promise<void> {
        try {
            const initialPersonnelIdsToFetch = new Set<string>();
            const initialEditableEmployeeIdsToFetch = new Set<string>();
            const managerAliasesToFetch = new Set<string>();
            const newNameMap = new Map(employeeNames);
            candidates.forEach((screen: ICommonScreening) => {
                if (screen.personnelId) {
                    if (isGUID(screen.personnelId)) {
                        initialEditableEmployeeIdsToFetch.add(screen.personnelId);
                    } else {
                        initialPersonnelIdsToFetch.add(screen.personnelId);
                    }
                }
                if (screen.nominatedBy) {
                    initialPersonnelIdsToFetch.add(screen.nominatedBy);
                }
                if (screen.processOwnerId) {
                    initialPersonnelIdsToFetch.add(screen.processOwnerId);
                }
            });

            if (initialEditableEmployeeIdsToFetch.size > 0) {
                const initialEmployeeData: IEmployeeEditableResponse[] = await EmployeeClient.getMultipleEditableEmployeeDataByIdOrAliasOrGUID(
                    authContext,
                    Array.from(initialEditableEmployeeIdsToFetch),
                );

                initialEmployeeData.forEach((editableEmployee) => {
                    const employee = transformPrehireToEmployeeObject(editableEmployee);

                    if (!!editableEmployee.employeeEditableInfo.reportsToEmailName) {
                        managerAliasesToFetch.add(
                            editableEmployee.employeeEditableInfo.reportsToEmailName,
                        );
                    }

                    const employeeData: IEmployeeNameAndType = {
                        displayName: getDisplayNameOrDefault(
                            employee,
                            employee.onPremisesSamAccountName,
                        ),
                        alias: employee.alias,
                        firstName: employee.firstName,
                        middleName: employee.middleName,
                        lastName: employee.lastName,
                        type: getEmployeeType(employee),
                    };

                    newNameMap.set(employee.id, employeeData);

                    if (isGUID(editableEmployee?.employeeId || '')) {
                        newNameMap.set(editableEmployee.employeeId || '', employeeData);
                    }
                });
                setEmployeeNames(newNameMap);
            }

            if (initialPersonnelIdsToFetch.size > 0) {
                const initialEmployeeData: IBasicEmployee[] = await EmployeeClient.getBasicEmployeesById(
                    authContext,
                    Array.from(initialPersonnelIdsToFetch),
                );

                initialEmployeeData.forEach((basicEmployee) => {
                    if (!!basicEmployee.reportsToEmailName) {
                        managerAliasesToFetch.add(basicEmployee.reportsToEmailName);
                    }

                    newNameMap.set(basicEmployee.id, {
                        displayName: getDisplayNameOrDefault(
                            basicEmployee,
                            basicEmployee.onPremisesSamAccountName,
                        ),
                        alias: basicEmployee.onPremisesSamAccountName,
                        firstName: basicEmployee.givenName,
                        middleName: basicEmployee.middleName,
                        lastName: basicEmployee.surname,
                        type: getEmployeeType(basicEmployee),
                    });
                });

                await EmployeeClient.getBasicEmployeesByAlias(
                    authContext,
                    Array.from(managerAliasesToFetch),
                );

                setEmployeeNames(newNameMap);
            }
            cacheContext.store<Map<string, IEmployeeNameAndType>>(
                cacheKeyEmployeeNames,
                newNameMap,
            );
        } catch (e) {
            updateMessage(
                true,
                'Error resolving alias/ids in batchFetchCandidateEmployeeData calling employee-services',
            );
        }
        setIsInitialEmployeeBatchLoaded(true);
        setIsPageFetchingInitialNominationData(false);
    }

    const openIndividualDetailsWindow = (screening: ICommonScreening): void => {
        setIsDetailWindowOpen(true);
        setCurrentDetailWindowScreening(screening);
    };

    useEffect(() => {
        // for cases where employee names are cleared out after drilling into
        // an individual screening and then using breadcrumb to navigate back
        // then use what's stored in the employeeName cache
        if (employeeNames.size === 0) {
            const cachedEmployeeNames = cacheContext.retrieve<Map<string, IEmployeeNameAndType>>(
                cacheKeyEmployeeNames,
            );
            if (cachedEmployeeNames) {
                setEmployeeNames(cachedEmployeeNames);
            }
        }
    }, [cacheContext, employeeNames, cacheKeyEmployeeNames]);

    const sortedTableRows = useMemo(() => {
        const sortTableRows = (rows: ICommonScreening[]): ICommonScreening[] => {
            if (rows.length === 0) return [];

            type R = ICommonScreening;
            const determineSortFunc = (): ((r1: R, r2: R) => number) => {
                switch (sortColumn) {
                    case 'Candidate':
                        return (r1: R, r2: R) => {
                            const e1 = employeeNames.get(r1?.personnelId);
                            const e2 = employeeNames.get(r2?.personnelId);

                            if (
                                e1?.type === PersonnelTypes.Terminated &&
                                e2?.type === PersonnelTypes.Terminated
                            )
                                return 0;
                            if (e1?.type === PersonnelTypes.Terminated) return 1;
                            if (e2?.type === PersonnelTypes.Terminated) return -1;

                            return (
                                sortAscending * strCmp(e1?.displayName || '', e2?.displayName || '')
                            );
                        };
                    case 'Clearance':
                        return (r1: R, r2: R) =>
                            sortAscending * strCmp(r1?.clearance, r2?.clearance);
                    case 'Request Type':
                        return (r1: R, r2: R) =>
                            sortAscending * strCmp(r1?.requestType, r2?.requestType);
                    case 'Contract ID':
                        return (r1: R, r2: R) =>
                            sortAscending * strCmp(r1?.contractId, r2?.contractId);
                    case 'Project Name':
                        return (r1: R, r2: R) =>
                            sortAscending * strCmp(r1?.contract?.project, r2?.contract?.project);
                    case 'Customer':
                        return (r1: R, r2: R) =>
                            sortAscending * strCmp(r1?.contract?.customer, r2?.contract?.customer);
                    case 'Nominated By':
                        return (r1: R, r2: R) =>
                            sortAscending *
                            strCmp(
                                employeeNames.get(r1?.nominatedBy)?.displayName || '',
                                employeeNames.get(r2?.nominatedBy)?.displayName || '',
                            );
                    case 'Nominated On':
                        return (r1: R, r2: R) =>
                            sortAscending * numCmp(r1?.nominatedAtUtc, r2?.nominatedAtUtc);
                    case 'Status':
                        return (r1: R, r2: R) =>
                            sortAscending *
                            strCmp(
                                getPublicTrustStatusDisplay(r1),
                                getPublicTrustStatusDisplay(r2),
                            );
                    case 'Sub-Status':
                        return (r1: R, r2: R) =>
                            sortAscending *
                            strCmp(getStatusText(r1, false), getStatusText(r2, false));
                    case 'Process Owner':
                        return (r1: R, r2: R) =>
                            sortAscending *
                            strCmp(
                                employeeNames.get(r1?.processOwnerId)?.displayName || '',
                                employeeNames.get(r2?.processOwnerId)?.displayName || '',
                            );
                    case 'Status Changed On':
                        return (r1: R, r2: R) =>
                            sortAscending * numCmp(r1?.statusChangedAtUtc, r2?.statusChangedAtUtc);
                    default:
                        return (r1: R, r2: R) => 1;
                }
            };

            return (rows || []).sort(determineSortFunc());
        };

        return sortTableRows(filteredCandidates);
    }, [filteredCandidates, employeeNames, sortColumn, sortAscending]);

    /**
     * in order to export the current Candidates in the us gov candidate
     * we need to format the date for export so the excel looks like the table
     * @param candidates list of candidates we pass into the table
     * @returns converted list of data for excel
     */
    async function convertCandidatesToExcel(
        candidates: ICommonScreening[],
    ): Promise<IExportUsGovToExcel[]> {
        const excelData: IExportUsGovToExcel[] = [];

        try {
            const excelRequestItems = candidates.map((candidate) => {
                const requestItem: IScreeningRecordUSGovExcelRequest = {
                    ScreeningRecordId: candidate.id,
                    contractId: candidate.contractId,
                    nominatedById: candidate.nominatedBy,
                    personnelId: candidate.personnelId,
                    ProcessOwnerId: candidate.processOwnerId,
                };

                return requestItem;
            });

            const batchedRequestItems = partitionArray(excelRequestItems, MaxExcelDataBatch);

            for (let i = 0; i < batchedRequestItems.length; i++) {
                const results: IScreeningRecordUSGovExcelResponse[] = await UsGovScreeningClient.getScreeningRecordExcelDataByRequests(
                    authContext,
                    batchedRequestItems[i],
                    filtersContext.userType,
                    props.pageName === ScreeningPageNames.MyOrg,
                );

                results.forEach(async (row) => {
                    const statusChangeOn = row.statusChangedOn
                        ? dateToFormattedDateTimeStringFromSeconds(row.statusChangedOn)
                        : '';

                    const terminationDateUTCSeconds = row.terminationDateUTCSeconds
                        ? dateToFormattedDateTimeStringFromSeconds(row.terminationDateUTCSeconds)
                        : '';

                    const statusLabel = getStatusDisplayByStateName(row.status);
                    const subStatusLabel = getStatusTextByStateName(row.status, false);

                    // only add the action url when the View screening button is active
                    // don't add the action url when the table shows blank or view nomination
                    let actionUrl = '';
                    if (row.screeningView === 'ViewScreening') {
                        // Note: this is meant for ppe and production, local development enviroment the url will look off
                        // ex: https://personnel-ppe.microsoft.com/screening/us-gov/${row.id}
                        actionUrl = `${config.aadConfig.redirectUri}/us-gov/${row.screeningRecordId}`;
                    }

                    // need the space in the key for the excel columns
                    // instead of candidates column like the table display
                    // we will show alias, lastname, first name and middle name as
                    // it makes the excel file easier to search up a specific person
                    const excelRow: IExportUsGovToExcel = {
                        personnelId: row.personnelId?.toUpperCase() || '',
                        alias: row.personnelAlias?.toUpperCase() || '',
                        lastName: row.personnelLastName || '',
                        firstName: row.personnelFirstName || '',
                        middleName: row.personnelMiddleName || '',
                        employeeStatus: row.employeeStatus || '',
                        employeeTitle: row.employeeTitle || '',
                        organization: row.organization || '',
                        geographicLocation: row.geographicLocation || '',
                        officeLocation: row.officeLocation || '',
                        level1: row.l1?.toUpperCase() || '',
                        level2: row.l2?.toUpperCase() || '',
                        level3: row.l3?.toUpperCase() || '',
                        level4: row.l4?.toUpperCase() || '',
                        level5: row.l5?.toUpperCase() || '',
                        level6: row.l6?.toUpperCase() || '',
                        reportsTo: row.reportsTo || '',
                        contractID: row.contractId || '',
                        projectName: row.contractProjectName,
                        customer: row.contractCustomer,
                        requestType:
                            ScreeningRequestTypesLabels[
                                row.requestType as keyof typeof ScreeningRequestTypesLabels
                            ] ?? row.requestType,
                        clearanceLevel: row.clearance || '',
                        customerBadgeRequired: row.customerBadgeRequired
                            ? 'Required'
                            : 'Not Required',
                        nominatedBy: row.nominatedBy?.toUpperCase() || '',
                        nominatedOn: dateToFormattedDateTimeStringFromSeconds(row.nominatedOn),
                        status: statusLabel,
                        subStatus: subStatusLabel,
                        processOwner: row.processOwner,
                        statusChangedOn: statusChangeOn,
                        terminationDate: terminationDateUTCSeconds,
                        personStatusCode: row.personStatusCode || '',
                        action: actionUrl,
                    };

                    // Remove certain properties from excelRow based upon page to remove associated columns from Excel document output.
                    if (
                        props.pageName === ScreeningPageNames.MyOrg ||
                        props.pageName === ScreeningPageNames.MyNominees
                    ) {
                        delete excelRow['terminationDate'];
                        delete excelRow['personStatusCode'];
                    }

                    excelData.push(excelRow);
                });
            }
        } catch (e) {
            setErrorOccurred(true);
            setMessage('Unable to download screening excel data.');
        }
        return excelData;
    }

    function expandColumnButton(): JSX.Element {
        if (showAdvancedMetrics) {
            return <></>;
        }
        return (
            <>
                <ActionButton
                    iconProps={
                        isTableExpanded
                            ? { iconName: IconNames.CalculatorSubtract }
                            : { iconName: IconNames.Add }
                    }
                    onClick={(): void => setIsTableExpanded(!isTableExpanded)}>
                    {`${isTableExpanded ? 'Collapse' : 'Expand'} Columns`}
                </ActionButton>
            </>
        );
    }

    function downloadExcelButton(): JSX.Element {
        if (showAdvancedMetrics) {
            return <></>;
        }
        return (
            <Stack.Item grow={0.001}>
                <ExportToExcelButton<IExportUsGovToExcel>
                    getData={async (): Promise<IExportUsGovToExcel[]> =>
                        convertCandidatesToExcel(filteredCandidates)
                    }
                    fileNamePrefix={`US_Gov_Screening_${props.pageName.replace(' ', '_')}`} // page name can come with spaces
                    fileNameTimeFormat={TimeFormats.MM_DD_YYYY_HHmm}
                    formatHeader={true}
                    formatType={excelFormatHeader.microsoftBrandCase}
                />
            </Stack.Item>
        );
    }

    function clearFiltersButton(): JSX.Element {
        return (
            <Stack.Item grow={100}>
                <ClearFiltersActionButton clearFunc={filtersContext.clearFilters} />
            </Stack.Item>
        );
    }

    function getTable(): JSX.Element {
        if (!showAdvancedMetrics) {
            return (
                <Table<ICommonScreening>
                    rows={sortedTableRows}
                    isFetchingData={isPageFetchingInitialNominationData}
                    shimmerLabel='Loading initial nominations...'
                    tableColumns={tableColumns}
                    tableName='Nominations'
                    detailsHeaderStyles={columnHeaderStyles}
                />
            );
        }
        return (
            <AllCustomerMetricTable
                showAdvancedMetrics={showAdvancedMetrics}
                authContext={authContext}
                filteredCandidates={filteredCandidates}
                rawCandidateCount={rawCandidates.length}
                pageName={props.pageName}
                cacheKey={cacheKeyCustomerMetrics}
            />
        );
    }

    function updateMessage(error: boolean, message: string): void {
        setErrorOccurred(error);
        setMessage(message);
    }

    function showSevereWarning(): boolean {
        return props.pageName === ScreeningPageNames.MyOrg && !hasMyOrgAccess;
    }

    function showAttention(): boolean {
        return props.pageName === ScreeningPageNames.MyOrg && hasMyOrgAccess;
    }

    const NominationStepperContainer = (props: { children: ReactNode }): JSX.Element => {
        // Removing this pass through component created the similar problem that's
        // indicated in file content-public-trust-tab.tsx on a component by the same
        // name. I'm keeping the component.
        return <>{props.children}</>;
    };

    return (
        <div className={pageStyle.page}>
            <DetailCustomerMetricModal
                isDetailWindowOpen={isDetailWindowOpen}
                closeModal={(): void => setIsDetailWindowOpen(false)}
                authContext={authContext}
                screening={currentDetailWindowScreening}
                pageName={props.pageName}
            />
            <NominationStepperContainer>
                <CandidateNominationStepperCommon
                    isOpen={isShowNominationProcess}
                    containerClassName={pageStyle.modalContainer}
                    onDismiss={(): void => setShowNominationProcess(false)}
                    hideNominationProcess={async (nomination?: ICommonScreening): Promise<void> => {
                        if (nomination) {
                            if (!isGUID(nomination.personnelId)) {
                                try {
                                    const nominatedHierarchyData = await ReportsClient.getSelectHierarchy(
                                        authContext,
                                        [nomination.personnelId],
                                    );
                                    const combinedHierarchyData: Dictionary<IHierarchy> = Object.assign(
                                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                        hierarchyRecords!,
                                        nominatedHierarchyData,
                                    );
                                    updateHierarchyCache(combinedHierarchyData, localStorage);
                                    setHierarchyRecords(combinedHierarchyData);

                                    if (
                                        !employeeIdsWithFutureTerminationDates.some(
                                            (x) => x === nomination.personnelId,
                                        )
                                    ) {
                                        const results = await UsGovScreeningClient.getEmployeeIdsWithFutureTerminationDates(
                                            authContext,
                                            [nomination.personnelId],
                                        );
                                        setEmployeeIdsWithFutureTerminationDates([
                                            ...results,
                                            ...employeeIdsWithFutureTerminationDates,
                                        ]);
                                    }
                                } catch (error) {
                                    console.error(
                                        `failed to grab the hierarchy records for ${nomination.contact?.homeEmail}`,
                                    );
                                }
                            }
                            setRawCandidates([nomination, ...rawCandidates]);
                        }
                        setShowNominationProcess(false);
                    }}
                    onNominationError={(msg: string): void => updateMessage(true, msg)}
                    screeningPath={ScreeningPaths.UsGov}
                />
            </NominationStepperContainer>

            {showUploadModal && (
                <UsGovBulkUploadModal
                    hideUploadModal={(): void => {
                        setShowUploadModal(false);
                    }}
                />
            )}

            <div className={pageStyle.filterAndTable}>
                <div className={pageStyle.filterWrapper}>
                    <div className={pageStyle.filter}>
                        <CandidateFilter
                            rawCandidates={rawCandidates}
                            hierarchyRecords={hierarchyRecords}
                            showCounts={isDoneLoadingScreenings}
                            employeeNames={employeeNames}
                            contracts={contracts}
                            employeeIdsWithFutureTerminationDates={
                                employeeIdsWithFutureTerminationDates
                            }
                            pageName={props.pageName}
                        />
                    </div>
                </div>
                <div
                    className={`${pageStyle.candidatesTable} ${
                        isTableExpanded ? pageStyle.expandedCandidatesTable : ''
                    }`}>
                    {hasErrorOccurred && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel='Close'
                            onDismiss={(): void => updateMessage(false, '')}
                            styles={{ root: { marginBottom: 15 } }}>
                            {errorMessage}
                        </MessageBar>
                    )}
                    <CandidatesPageHelp screeningPath={ScreeningPaths.UsGov} />
                    {showSevereWarning() && <MyOrgSevereWarningMessage />}
                    {showAttention() && <ScreeningAttentionMessage />}
                    <HorizontalBar styles={{ maxHeight: '50px' }}>
                        <Stack.Item grow={1}>
                            <ActionButton
                                onClick={(): void => setShowNominationProcess(true)}
                                iconProps={{ iconName: IconNames.AddFriend }}
                                allowDisabledFocus>
                                Nominate candidate
                            </ActionButton>
                            {isManagePage && (
                                <ActionButton
                                    onClick={(): void => setShowUploadModal(true)}
                                    iconProps={{ iconName: IconNames.BulkUpload }}
                                    allowDisabledFocus>
                                    Upload
                                </ActionButton>
                            )}
                            {canShowContractAdminPage && isMyContractsPage && (
                                <Link style={{ marginRight: '5rem' }} to={'/screening/contracts'}>
                                    <ActionButton
                                        title='Manage my contracts'
                                        aria-label='Manage my contracts'
                                        iconProps={{ iconName: IconNames.EntitlementPolicy }}>
                                        Manage my contracts
                                    </ActionButton>
                                </Link>
                            )}
                        </Stack.Item>
                        {!isMyScreeningsPage && (
                            <Stack.Item grow={1} styles={{ root: { textAlign: 'end' } }}>
                                <Pivot
                                    selectedKey={showAdvancedMetrics ? '1' : '0'}
                                    onLinkClick={(item): void => {
                                        BreadCrumbHelper.resetBreadCrumbs(breadCrumbContext);
                                        const key = props.pageName as keyof typeof pageNameMap;
                                        const path = pageNameMap[key] ?? pageNameMap.Manage;
                                        // need to make a copy otherwise it will keep on adding to the same array
                                        const newBreadCrumbs = [
                                            ...defaultBreadCrumbs,
                                            {
                                                title: props.pageName
                                                    ? props.pageName
                                                    : 'Screenings',
                                                link: `/screening/us-gov/${path}`,
                                            },
                                        ];
                                        if (
                                            item?.props.headerText?.toUpperCase() ===
                                                metricsTitle.toUpperCase() &&
                                            showAdvancedMetrics !== true
                                        ) {
                                            const link = `/screening/us-gov/${path}/metrics`;
                                            history.replace(link);
                                            setShowAdvancedMetrics(true);
                                            newBreadCrumbs.push({
                                                title: metricsTitle,
                                                link,
                                            });
                                            BreadCrumbHelper.setBreadCrumbs(
                                                breadCrumbContext,
                                                newBreadCrumbs,
                                            );
                                        } else if (showAdvancedMetrics !== false) {
                                            const link = `/screening/us-gov/${path}/records`;
                                            history.replace(link);
                                            newBreadCrumbs.push({
                                                title: recordsTitle,
                                                link,
                                            });
                                            BreadCrumbHelper.setBreadCrumbs(
                                                breadCrumbContext,
                                                newBreadCrumbs,
                                            );
                                            setShowAdvancedMetrics(false);
                                        }
                                    }}>
                                    <PivotItem headerText={recordsTitle} />
                                    <PivotItem headerText={metricsTitle} />
                                </Pivot>
                            </Stack.Item>
                        )}
                    </HorizontalBar>
                    {shouldScreeningChartBeShown && (
                        <>
                            <Spacer marginTop={10} />
                            <PublicTrustScreeningChart
                                screenings={filteredCandidates}
                                totalRecords={rawCandidates.length}
                            />
                        </>
                    )}
                    <Spacer marginTop={20} />
                    <HorizontalBar>
                        <Stack.Item grow={1}>
                            <span className={pageStyle.recordsCount}>
                                {`${filteredCandidates.length} of ${rawCandidates.length} Records`}
                            </span>
                        </Stack.Item>
                        {/* TODO: Remove flag once good for production. */}
                        {isMyOrgPage && (
                            <Stack.Item grow={1} className={pageStyle.marginTop10}>
                                <Toggle
                                    checked={isDirectReportSelected}
                                    inlineLabel
                                    onText='Direct Reports'
                                    offText='Entire Org'
                                    onChange={(
                                        event: React.MouseEvent<HTMLElement>,
                                        checked?: boolean,
                                    ): void => {
                                        setDirectReportSelected(!!checked);
                                    }}
                                />
                            </Stack.Item>
                        )}
                        {clearFiltersButton()}

                        {isExpandButtonShown && expandColumnButton()}
                        {showDownloadExcelButton && downloadExcelButton()}
                    </HorizontalBar>
                    {isMyOrgPage &&
                    !config.personnelProfile.isUsCitizenTestingFlag &&
                    !userContext.employeeRecord.isUSCitizen ? (
                        <></>
                    ) : (
                        getTable()
                    )}
                </div>
            </div>
        </div>
    );
}

interface IExportUsGovToExcel {
    personnelId: string;
    alias: string;
    lastName: string;
    firstName: string;
    middleName: string;
    employeeStatus: string;
    employeeTitle: string;
    organization: string;
    geographicLocation: string;
    officeLocation: string;
    level1: string;
    level2: string;
    level3: string;
    level4: string;
    level5: string;
    level6: string;
    reportsTo: string;
    requestType: string;
    contractID: string;
    projectName: string;
    nominatedBy: string;
    nominatedOn: string;
    clearanceLevel: string;
    customer: string;
    customerBadgeRequired: string;
    status: string;
    subStatus: string;
    processOwner: string;
    statusChangedOn: string;
    terminationDate?: string;
    personStatusCode?: string;
    action: string; // this is the hyper link for "View Screening"
}
