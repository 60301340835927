import { dateToFormattedDateTimeStringFromMillis } from 'utils/time-utils';

/**
 * Converts a string to title case
 *
 * @example
 * toTitleCase("title case") => "Title Case"
 *
 * @param {string} str
 * @returns {string} a copy of the input string, converted to title case
 */
export function toTitleCase(str: string): string {
    if (str) {
        return str.replace(/\w\S*/g, (txt: string) => {
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
        });
    }
    return str;
}

/**
 * Converts a string to upperCase FirstLetter
 *
 * @example
 * upperCaseFirstLetter("my form") => "My form"
 *
 * @param {string} str
 * @returns {string} a copy of the input string, converted to title case
 */
export function upperCaseFirstLetter(str: string): string {
    if (str) {
        return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
    }
    return str;
}

/**
 * Converts a string to upperCase FirstLetter and removes the "-"
 *
 * @example
 * makeBreadcrumb("my-form") => "My form"
 *
 * @param {string} str
 * @returns {string} a copy of the input string, converted to title case
 */
export function makeBreadcrumb(str: string): string {
    if (str) {
        str = str.replace('-', ' ');
        return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
    }
    return str;
}

/**
 * Removes underscores from a given string
 *
 * @example
 * removeUnderscoresFromString("ATTRIBUTE_CHECK_RULE") => "ATTRIBUTE CHECK RULE"
 *
 * @param {string} str
 * @returns {string} a copy of the input string replaced with string value passed in or simply removed
 */
export function removeUnderscoresFromString(
    str: string,
    stringToReplaceUnderscore?: string,
): string {
    if (str) {
        return str.replace(/_/g, stringToReplaceUnderscore ? stringToReplaceUnderscore : '');
    }
    return str;
}

/**
 * Combines local string methods to return readable format
 * Works well with enum keys that are strings
 *
 * @example
 * removeUnderscoreAndCapitalize("ATTRIBUTE_CHECK_RULE") => "Attribute Check Rule"
 *
 * @param {string} str
 * @returns {string} a copy of the input string replaced with string value passed in or simply removed
 */
export function removeUnderscoreAndCapitalize(str: string): string {
    if (str) {
        return toTitleCase(removeUnderscoresFromString(str, ' '));
    }
    return str;
}

/**
 * Converts a camel cased string to title case
 *
 * @example
 * camelCaseToTitleCase("titleCase") => "Title Case"
 *
 * @param {string} str
 * @returns {string} a copy of the input string, converted to title case
 */
export function camelCaseToTitleCase(str: string): string {
    if (str) {
        let addSpacesToStr = str.slice(0, 1).toLocaleUpperCase();
        let index = 1;
        while (index < str.length) {
            addSpacesToStr += isCapitalLetter(str.charCodeAt(index))
                ? ` ${str[index]}`
                : str[index];
            index++;
        }

        return toTitleCase(addSpacesToStr);
    }
    return str;
}

/**
 * Converts a title case to camel cased string
 *
 * @example
 * titleCaseToCamelCase("Title Case") => "titleCase"
 *
 * @param {string} str
 * @returns {string} a copy of the input string, converted to camel cased
 */
export function titleCaseToCamelCase(str: string): string {
    if (!str) {
        return '';
    }

    const strArray = str.split(' ');

    const firstLetterLowerCased = strArray[0].charAt(0).toLowerCase();
    strArray[0] =
        strArray[0].length > 1
            ? firstLetterLowerCased + strArray[0].slice(1, strArray[0].length)
            : firstLetterLowerCased;

    return strArray.join('');
}

/**
 * Separate words in a string that have capital first letters
 *
 * @example
 * separateWordsByCapitalLetter("CheckedOut") => "Checked Out"
 *
 * @param {string} str
 * @returns {string} a copy of the input string with spaces between words which are indicated by beginning with a capital letter
 */
export function separateWordsByCapitalLetter(str: string): string {
    if (!str) {
        return '';
    }

    let capitalLetterCount = 0;
    const result: string[] = [];

    for (let i = 0; i < str.length; i++) {
        if (isCapitalLetter(str.charCodeAt(i))) {
            if (capitalLetterCount > 0) {
                result.push(' ');
            }
            capitalLetterCount++;
        }
        result.push(str.charAt(i));
    }

    return result.join('');
}

/**
 * Converts a csv header from camel case to header case (title case with spaces)
 *
 * @example
 * transformHeader("headerOne,headerTwo,headerThree,headerFour\n1,2,3,4") => "Header One,Header Two,Header Three,Header Four\n1,2,3,4"
 *
 * @param {string} csv
 * @returns {string} a copy of the input csv string, converted to header case
 */
export function transformHeaderToHeaderCase(csv: string): string {
    let output = csv.slice(0, 1).toLocaleUpperCase();
    let i = 1;
    while (csv[i] !== '\n') {
        if (csv[i] === ',') {
            output += `,${csv[i + 1].toLocaleUpperCase()}`;
            i += 2;
        } else if (isCapitalLetter(csv.charCodeAt(i))) {
            if (
                isCapitalLetter(csv.charCodeAt(i - 1)) &&
                (isCSVNewLineOrDelimiter(csv[i + 1]) || isCapitalLetter(csv.charCodeAt(i + 1)))
            ) {
                output += csv[i];
            } else {
                output += ` ${csv[i]}`;
            }

            i++;
        } else if (isNumberChar(csv.charCodeAt(i))) {
            output += ` ${csv[i]}`;
            i++;

            // skip chars until a non-number is found
            while (isNumberChar(csv.charCodeAt(i))) {
                i++;
            }
        } else {
            output += csv[i];
            i++;
        }
    }
    return output + csv.slice(i);
}

/**
 * Converts a csv header from camel case to Microsoft brand sentent casing ("geographicLocation" =>  "Geographic location")
 *
 * @example
 * transformHeader("headerOne,headerTwo,headerThree,headerFour\n1,2,3,4") => "Header one,Header two,Header three,Header four\n1,2,3,4"
 *
 * @param {string} csv
 * @returns {string} a copy of the input csv string, converted to header case
 */
export function transformHeaderToMicrosoftBrandCasing(csv: string): string {
    let output = csv.slice(0, 1).toLocaleUpperCase();
    let i = 1;
    while (csv[i] !== '\n') {
        if (csv[i] === ',') {
            output += `,${csv[i + 1].toLocaleUpperCase()}`;
            i += 2;
        } else if (isCapitalLetter(csv.charCodeAt(i))) {
            if (
                isCapitalLetter(csv.charCodeAt(i - 1)) &&
                (isCSVNewLineOrDelimiter(csv[i + 1]) || isCapitalLetter(csv.charCodeAt(i + 1)))
            ) {
                output += csv[i].toLocaleLowerCase();
            } else {
                output += ` ${csv[i].toLocaleLowerCase()}`;
            }

            i++;
        } else if (isNumberChar(csv.charCodeAt(i))) {
            output += ` ${csv[i].toLocaleLowerCase()}`;
            i++;

            // skip chars until a non-number is found
            while (isNumberChar(csv.charCodeAt(i))) {
                i++;
            }
        } else {
            output += csv[i].toLocaleLowerCase();
            i++;
        }
    }
    return output + csv.slice(i);
}

export function convertCSVToArray<T>(csv: string): T[] {
    let csvArray: string[] = [];
    const result: T[] = [];
    if (/\r\n/.test(csv)) {
        csvArray = csv.split('\r\n');
    } else if (/\n/.test(csv)) {
        csvArray = csv.split('\n');
    }
    if (csvArray.length === 0) {
        return result;
    }
    const header = csvArray[0].split(',');
    for (let ix = 1; ix < csvArray.length; ix++) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const resultRow: any = {};

        const rowArray = splitCsvRow(csvArray[ix]);
        for (let jx = 0; jx < header.length; jx++) {
            resultRow[header[jx]] = jx < rowArray.length ? rowArray[jx] : '';
        }
        result.push(resultRow as T);
    }
    return result;
}

export function splitCsvRow(row: string): string[] {
    const lookingForStart = 'lookingForStart';
    const lookingForEnd = 'lookingForEnd';
    const DOUBLE_QUOTE = '"';
    const COMMA = ',';

    const rowArray = [];
    let colIx = 0;

    let token = '';
    let delimiter = COMMA; // Default delimiter
    let state = lookingForStart;

    for (let charIx = 0; charIx < row.length; charIx++) {
        const char = row[charIx];
        let isTokenComplete = false;
        switch (state) {
            case lookingForStart:
                if (char === DOUBLE_QUOTE) {
                    delimiter = DOUBLE_QUOTE;
                    state = lookingForEnd;
                } else if (char !== delimiter) {
                    token += char;
                } else {
                    isTokenComplete = true;
                }

                break;
            case lookingForEnd:
                if (char === delimiter) {
                    if (delimiter === DOUBLE_QUOTE) {
                        charIx++; // Skip the next char
                    }
                    delimiter = COMMA;
                    state = lookingForStart;
                    isTokenComplete = true;
                } else {
                    token += char;
                }
                break;
        }
        if (
            isTokenComplete ||
            charIx >= row.length - 1 ||
            char === '\n' ||
            char === '\r' ||
            char === null ||
            char === undefined
        ) {
            rowArray[colIx] = token;
            token = '';
            colIx++;
        }
    }
    return rowArray;
}

function isCSVNewLineOrDelimiter(s: string, delimiter = ','): boolean {
    return s === delimiter || s === '\r' || s === '\n';
}

function isCapitalLetter(charCode: number): boolean {
    return charCode >= 65 && charCode <= 90;
}

function isNumberChar(charCode: number): boolean {
    return charCode >= 48 && charCode <= 57;
}

/**
 *
 * @param str A 6 digit hex color string, eg, "#1A2B3C".
 * Returns the equivalent rgb color value.
 * Returns the input string unchanged if input is not as above.
 */
export function hexColor2Rgb(str: string): string {
    if (/^\s*rgba?\s*\(/.test(str)) {
        return str; // Already looks like an rgb color string. Return it as it is.
    } else if (!/^#[0-9a-fA-F]{6}$/.test(str)) {
        return str; // Doesn't look like a hex color string. Return it as it is.
    }
    const matches = str.replace(/^#/, '').matchAll(/[0-9A-Fa-f]{2}/g);
    const rgbs: string[] = [];
    let match = matches.next();
    while (!match.done) {
        rgbs.push(parseInt(match.value[0], 16).toString());
        match = matches.next();
    }
    return `rgb(${rgbs.join(', ')})`;
}

/**
 *
 * @param rgbValue An rgb or rgba string, with or without an opacity value.
 * @param opacity  The desired opacity on the output string.
 *
 * Replaces the current opacity, if any, with the specified one.
 * If the string doesn't resemble an rgb or rgba value, it returns
 * the string unchanged.
 */
export function replaceRgbOpacity(rgbValue: string, opacity: string | number): string {
    const trimmed = rgbValue.replace(/\s+/g, '');
    const m3 = trimmed.match(/^rgba?\((\d{1,3}),(\d{1,3}),(\d{1,3})\)/);
    const m4 = trimmed.match(/^rgba?\((\d{1,3}),(\d{1,3}),(\d{1,3}),(\d{1,3})\)/);
    if (m3) {
        return `rgb(${m3[1]}, ${m3[2]}, ${m3[3]}, ${opacity})`;
    } else if (m4) {
        return `rgb(${m4[1]}, ${m4[2]}, ${m4[3]}, ${opacity})`;
    } else {
        return rgbValue;
    }
}

/**
 * Check if the entire string contain only digit chars.
 *
 * @param input The string to check.
 * @returns {boolean} true or false.
 */
export function containsOnlyDigits(input: string): boolean {
    return /^\d+$/.test(input);
}

/**
 * Check if the entire string is a GUID or not.
 *
 * @param input The string to check.
 * @returns {boolean} true or false.
 */
export function isGUID(input: string): boolean {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
        input,
    );
}

/**
 * Check if the string is a valid number.
 *
 * @param input The string to check.
 * @returns {boolean} true or false.
 */
export function isNumeric(input: string): boolean {
    return !isNaN(Number(input));
}

/**
 * Check if the string is a valid email address.
 *
 * @param input The string to check.
 * @returns {boolean} true or false.
 */
export function isValidEmail(input: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
}

/**
 * Return the username if a given email address.
 *
 * @param input The string to check.
 * @returns username string.
 */

export function returnEmailUserName(input: string) {
    return input.split('@')[0];
}

/**
 * @param s String potentially containing HTML tags with "href" attributes.
 * @returns Removes all href's that:
 *            . are not "microsoft.com", or
 *            . are not valid URL strings.
 * As of this writing on 11/23/21, it only recognizes and removes href's
 * that are specified by either of the following expressions:
 *      . href="non-blank-string"
 *      . href='non-blank-string'
 */
export function removeNonMicrosoftLinks(s: string): string {
    let result = s;
    const matches = s.matchAll(/href=['"](\S+)['"]/g);
    Array.from(matches).forEach((match) => {
        const theCompleteHref = match[0]; // Example: href="https://microsoft.com" - includes the quotes
        const theLink = match[1]; // Example: https://microsoft.com - there's no quote
        try {
            const url = new URL(theLink);
            if (!url.host || !url.hostname) {
                // It's not a valid URL. Remove it.
                throw 'Invalid URL string';
            } else if (!/\.?\bmicrosoft\.com/.test(theLink)) {
                // The URL is not linking to "microsoft.com". Remove it.
                throw 'Non Microsoft link';
            } else {
                // href is good
            }
        } catch (e) {
            // This was not a valid url string.
            // Remove the href string from the result.
            console.warn(e, '- Removing invalid href from string', theCompleteHref);
            result = result.replace(theCompleteHref, '');
        }
    });
    return result;
}

/**
 * Remove all whitespace from string.
 *
 * @param input The string to remove all whitespace from.
 * @returns {string} trimmed string.
 */
export function removeWhiteSpaceFromString(s: string): string {
    return s.replace(/\s/g, '');
}

/**
 * Remove all whitespace and slashes from string.
 *
 * @param input The string to remove all whitespace and slashes from.
 * @returns {string} trimmed string.
 */
export function removeAllWhitespaceAndSlashesFromString(s: string): string {
    return s.replace(/\s+|[\/]/g, '');
}

/**
 * Remove all parenthesis from string.
 *
 * @param input The string to remove all parenthesis from.
 * @returns {string} trimmed string.
 */
export function removeAllParenthesisFromString(s: string): string {
    return s.replace(/[()]/g, '');
}

/**
 * Format time string for report download.
 *
 * @param input The string to format that's in an expected time string format.
 *              e.g. "_DateTimeInUTCMilliseconds(111111111111)"
 * @returns {string} trimmed string.
 */
export function replaceDownloadReportTemplatedTimeText(text: string): string {
    const regEx = /\d+/g;
    const numberStr = text.match(regEx);
    const number = numberStr ? parseInt(numberStr[0]) : undefined;

    const date = dateToFormattedDateTimeStringFromMillis(number);
    return date;
}

/**
 * Convert some string values ("yes", "true", "1", etc.) to boolean representation.
 *
 * @param input The string to convert into boolean e.g. "yes", "NO", "True", "FALSE", "0", "1"
 * @returns {boolean} true/false.
 */
export function toBoolean(input: string): boolean {
    input = input.trim().toLocaleLowerCase();
    switch (input) {
        case 'true':
        case '1':
        case 'yes':
            return true;
        default:
            return false;
    }
}
