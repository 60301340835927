import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IDropdownOption,
    mergeStyleSets,
    MessageBar,
} from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import { ManageGroupsVariableContext } from 'components/groups/manage-groups/contexts/manage-groups-variable-context';

interface IGroupMemebershipRulePickerProps {
    setGroupMembershipCheckIDS: React.Dispatch<SetStateAction<string[]>>;
    groupMembershipCheckIDS: string[];
    isEditing: boolean;
}

export default function GroupMembershipRulePicker(
    props: IGroupMemebershipRulePickerProps,
): JSX.Element {
    const variableContext = useContext(ManageGroupsVariableContext);
    const [showDropDown, setShowDropDown] = useState<boolean>(true);
    const [chosenItems, setChosenItems] = useState<IDropdownOption[]>([]);
    const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[]>([]);
    const [localOptions, setLocalOptions] = useState<IDropdownOption[]>();

    useEffect(() => {
        const options = variableContext.groups
            .filter((item) => item.enableHidden !== true)
            .map((option) => {
                return { key: option.id, text: option.name };
            });
        setDropdownOptions(options);
    }, [variableContext.groups]);

    useEffect(() => {
        //TODO: Fix boolean comparator. dropdownOptions !== [] always returns true
        if (props.isEditing && true) {
            setChosenItems([]);
            const items: IDropdownOption[] = [];
            props.groupMembershipCheckIDS.forEach((id) => {
                const foundId = dropdownOptions.filter((option: IDropdownOption) => {
                    return option.key === id;
                });
                if (foundId.length > 0) {
                    items.push(foundId[0]);
                }
            });

            setChosenItems(items);
        }
    }, [props.groupMembershipCheckIDS, dropdownOptions, props.isEditing]);

    const onHandleDismissItem = (item: IDropdownOption): void => {
        const updatedItems = chosenItems.filter((i) => i.text !== item.text);
        const updatedItemIDs = props.groupMembershipCheckIDS.filter((i) => i !== item.key);
        props.setGroupMembershipCheckIDS(updatedItemIDs);
        setChosenItems(updatedItems);
        if (updatedItems.length === 0) {
            setShowDropDown(true);
        }
        if (localOptions) {
            setLocalOptions([...localOptions, item]);
        }
    };

    const onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption): void => {
        if (option) {
            props.setGroupMembershipCheckIDS([
                ...props.groupMembershipCheckIDS,
                option.key.toString(),
            ]);
            setChosenItems([...chosenItems, option]);
            setShowDropDown(false);

            const updatedOptions = localOptions?.filter((i) => i !== option);
            setLocalOptions(updatedOptions);
        }
    };

    const chosenItemBar = chosenItems.map((item: IDropdownOption) => (
        <div className={styles.messageBarContainer} key={item.key}>
            <MessageBar
                dismissButtonAriaLabel='Close'
                className={styles.messageBar}
                onDismiss={(e): void => {
                    if (e) {
                        onHandleDismissItem(item);
                    }
                }}>
                {item.text}
            </MessageBar>
            {chosenItems.length > 1 && chosenItems[chosenItems.length - 1] !== item && (
                <div>Or</div>
            )}
        </div>
    ));

    return (
        <>
            <p>
                Member must be a compliant member in <b>any</b> of the following groups:{' '}
            </p>
            <div className={styles.messageBarSection}>{chosenItemBar}</div>

            {showDropDown && dropdownOptions && (
                <div className={styles.dropdownContainer}>
                    <ComboBox
                        ariaLabel='Groups for Membership Checks'
                        className={styles.dropDown}
                        options={dropdownOptions}
                        onChange={onChange}
                        allowFreeform={false}
                    />
                </div>
            )}

            {chosenItems.length > 0 && !showDropDown && (
                <a className={globalStyles.link} onClick={(): void => setShowDropDown(true)}>
                    Add Alternative...
                </a>
            )}
        </>
    );
}

const styles = mergeStyleSets({
    dropDown: {
        width: '100%',
        maxWidth: 320,
        marginRight: 10,
    },
    messageBarSection: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    dropdownContainer: {
        display: 'flex',
        marginBottom: 10,
        alignItems: 'center',
    },
    messageBarContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        selectors: {
            '& div:nth-child(1)': {
                width: 'auto',
            },
            '& div:nth-child(2)': {
                paddingLeft: 10,
                paddingRight: 10,
            },
        },
    },
    messageBar: {
        selectors: {
            '.ms-MessageBar-icon': {
                display: 'none',
            },
        },
    },
});
