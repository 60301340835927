import React, { ReactNode } from 'react';
import { mergeStyles } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';

export interface PageHeaderProps {
    children: ReactNode;
}

export default function PageTitle(props: PageHeaderProps): JSX.Element {
    return <h1 className={titleStyles}>{props.children}</h1>;
}

const titleStyles = mergeStyles(globalStyles.boldFont, globalStyles.veryLargeFont);
