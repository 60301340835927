import React, { LegacyRef } from 'react';
import { useToggle, useDebounce, useScrollEventListener } from 'utils/misc-hooks';

interface ICheckScrollReachedBottomProps {
    shouldCheck?: boolean;
    shouldRemoveHandler?: boolean;
    onScrollReachedBottom: () => void;
}

export function CheckScrollReachedBottom(props: ICheckScrollReachedBottomProps): JSX.Element {
    const shouldCheck = props.shouldCheck ?? true;

    useScrollEventListener(scrollEventHandler, { shouldRemoveHandler: props.shouldRemoveHandler });

    const [hasReachedBottomToggle, toggleHasReachedBottom] = useToggle(false);

    useDebounce(props.onScrollReachedBottom, 100, [hasReachedBottomToggle]);

    function scrollEventHandler(): void {
        if (!shouldCheck) return;
        const bodyElement = document.getElementsByTagName('body')[0];
        const bodyBoundingRect = bodyElement?.getBoundingClientRect();

        const effectiveScroll = (window.scrollY + window.innerHeight) * window.devicePixelRatio;
        const effectiveHeight = bodyBoundingRect?.height * window.devicePixelRatio;

        // Trigger when scroll down reaches 90% of the body height.
        // I noticed if zoom factor is very large (200 and above), comparing heights
        // as they are will not trigger a reload. Turned out effectiveHeight grew slightly
        // faster than effectiveScroll. Giving scroll a 10% margin gives us a saftety margin
        // to ensure the following is triggered.
        if (effectiveScroll >= 0.9 * effectiveHeight) {
            toggleHasReachedBottom();
        }
    }

    return <></>;
}

/**
 * Checks if bottom of the element that contains a list (the parameter
 * "ref" below) has reached bottom of the window. Primarily added to be
 * used alongside the component CheckScrollReachedBottom (above). In
 * times a list is so short that it doesn't reach the bottom of the
 * window. Therefore the page doesn't show vertical scrollbars. On the
 * other hand, the continuation token is a valid, non-blank string,
 * implying there's more data to be fetched. But since there's no scroll
 * mark, user was unable to trigger a "load more", because there was
 * no scrollbar to scroll to the bottom and trigger a load more. One
 * thing user  could do was to make the window's height short to make
 * them appear, scroll to the bottom of the page, and the above mentioned
 * module would've triggered a "load more".
 *
 * The following function can be called to detect if the bottom of the
 * element that contains the list (the parameter "ref" below) has reached
 * the bottom of the page. If so, it's very likely that the vertical
 * scrollbar is visible on the page. But if not, the calling component
 * can decide - if the continuation token indicates more data, it can
 * just trigger a "load more". It can keep doing that until either the
 * continuation token is blank, or the bottom of the "ref" element
 * reaches the bottom of the window, in which case the scollbar will
 * appear. The calling component can then stop doing "load more" and
 * let user's scrolling trigger the "load more".
 *
 * @param ref Ref to the component that contains the list.
 * @returns true, if bottom of the "ref" element reaches bottom of screen.
 */
export function doesElementBottomReachScreenBottom(
    ref: LegacyRef<HTMLDivElement> | undefined,
): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!ref || !((ref as unknown) as any)?.current) {
        return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const refBoundingRect = ((ref as unknown) as any)?.current?.getBoundingClientRect();
    const displayHeight = refBoundingRect.height * window.devicePixelRatio;

    // Be pesimistic. Since on the original usage of this function, a "false"
    // value should trigger a "load more", make the following comparison more
    // likely to return false to make "load more" more likely. This specially
    // helps because of errors in floating point calculations, or an extreme
    // display zoom factor, which affects the above calculation.
    return displayHeight > 1.1 * window.screen.height;
}
