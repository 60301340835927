import React, { useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import DisplaySecurityGroup from 'components/groups/manage-group/timeline/display-security-group';
import GroupClient, { GroupActivityObjectType } from 'clients/group-client';
import { useFetchSimple, useIsMounted } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';
import BoldFont from 'components/common/misc/bold-font';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { ManageGroupsVariableContext } from 'components/groups/manage-groups/contexts/manage-groups-variable-context';
import { THE_SYSTEM } from 'components/groups/manage-group/members/manage-groups-table-columns';

interface DisplayActivitySubjectOrObjectProps {
    id: string;
    groupId: string;
    type: GroupActivityObjectType;
    value: string;
}

export default function DisplayActivitySubjectOrObject(
    props: DisplayActivitySubjectOrObjectProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupsContext = useContext(ManageGroupsVariableContext);

    const key = `${props.id}-${props.type}-${props.value}`;

    const [applicationName, setApplicationName] = useState<string>();

    const isMounted = useIsMounted();

    useFetchSimple({
        dependencies: [props.value, props.groupId, props.type],
        canPerformFetch:
            !!props.value &&
            !!props.groupId &&
            !!props.type &&
            (props.type === GroupActivityObjectType.ApplicationId ||
                props.type === GroupActivityObjectType.Application),
        fetchFunc: (): Promise<string> => {
            return GroupClient.getApplicationName(
                authContext,
                // The following typecast is safe because the condition of
                // "canPerformFetch" will not allow the following to be called
                // if props.groupId is undefined.
                props.groupId as string,
                props.value,
            );
        },
        onSuccess: (appName: string | undefined) => {
            if (isMounted()) {
                setApplicationName(appName);
            }
        },
        onError: (): void => {
            // props.value is application id
            // Could not fetch the application name. Show its id.
            setApplicationName(props.value);
        },
        onFinally: doNothing,
    });

    switch (props.type) {
        case GroupActivityObjectType.User:
        case GroupActivityObjectType.PersonnelId:
            return <CoreEmployeeHoverCardFromPrincipalId key={key} principalId={props.value} />;
        case GroupActivityObjectType.DistributionList: // For the case POC type is PocTypeEnum.DistributionList
            return (
                <span>
                    Distribution list <BoldFont>{props.value}</BoldFont>
                </span>
            );
        case GroupActivityObjectType.Application:
        case GroupActivityObjectType.ApplicationId:
            return (
                <span key={key}>
                    Application&nbsp;
                    <BoldFont>
                        <EllipsisTextCss text={applicationName} tipText={`App id: ${props.id}`} />
                    </BoldFont>
                </span>
            );
        case GroupActivityObjectType.SecurityGroupId:
            return (
                <BoldFont>
                    <DisplaySecurityGroup groupId={props.value} />
                </BoldFont>
            );
        case GroupActivityObjectType.GroupId:
            const group = groupsContext.groupsDict[props.groupId];
            if (!!group?.name) {
                return (
                    <BoldFont>
                        <span>{group.name}</span>
                    </BoldFont>
                );
            } else {
                return (
                    <span>
                        Group <BoldFont>{props.groupId}</BoldFont>
                    </span>
                );
            }
        case GroupActivityObjectType.System:
            return <CoreEmployeeHoverCardFromPrincipalId displayName={THE_SYSTEM} />;

        default:
            // This is a missing mapping for subject or object type.
            // Code must be added to support it.
            return (
                <span>
                    Unknown type <BoldFont>{props.type}</BoldFont>
                </span>
            );
    }
}
