import React, { useState } from 'react';
import {
    mergeStyleSets,
    DefaultButton,
    PrimaryButton,
    FontWeights,
    FontSizes,
    Separator,
} from '@fluentui/react';
import { ScreeningParentStateType } from 'components/screening/common/common-constants';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { IEmployee } from 'clients/employee-client';
import { dateToFormattedDateTimeString } from 'utils/time-utils';

const separatorStyles = mergeStyleSets({
    root: {
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                opacity: 0.9,
            },
        },
    },
    content: {
        paddingLeft: 0,
        fontWeight: '500',
    },
});

export interface WithdrawApproveProps {
    screening: ICommonScreening;
    employee?: IEmployee;
    onClose: () => void;
    onScreeningStatusChange(
        parentState: string,
        childState?: string,
        adjudicatedDate?: number,
    ): Promise<void>;
    onWithdrawCandidate(screening: ICommonScreening): Promise<void>;
    getEmployeeName(): string;
}

const APPROVE_WITHDRAWAL = 'APPROVE';
const DENY_WITHDRAWAL = 'DENY';

export default function WithdrawApprove(props: WithdrawApproveProps): JSX.Element {
    const [approveOrDeny, setApproveOrDeny] = useState<string>('');

    async function onSubmitWithdrawApproveModal(): Promise<void> {
        if (approveOrDeny) {
            if (approveOrDeny === APPROVE_WITHDRAWAL) {
                await props.onScreeningStatusChange(ScreeningParentStateType.Withdrawn);
            } else if (approveOrDeny === DENY_WITHDRAWAL) {
                const updatedScreening: ICommonScreening = {
                    ...props.screening,
                    withdrawalReason: '',
                    withdrawalNotes: '',
                    withdrawalUtcMillis: new Date().getTime(),
                };

                await props.onWithdrawCandidate(updatedScreening);
            }
            props.onClose();
        }
    }

    function onRadioButtonClick(e: React.ChangeEvent<HTMLInputElement>): void {
        setApproveOrDeny(e.currentTarget.value);
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <span>Request to Withdraw</span>
            </div>
            <div className={styles.body}>
                <div className={styles.topSection}>
                    <div className={styles.dataLine}>
                        <div className={styles.label}>Requested By</div>
                        <div>
                            {props.getEmployeeName()}
                            <span className={styles.aliasText}>
                                {props.employee ? ` (${props.employee.alias.toLowerCase()})` : ''}
                            </span>
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.label}>Requested Date</div>
                        <div>
                            {dateToFormattedDateTimeString(props.screening.withdrawalUtcMillis)}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.label}>Reason</div>
                        <div>{props.screening.withdrawalReason}</div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.label}>Notes</div>
                        <div>{props.screening.withdrawalNotes}</div>
                    </div>
                </div>

                <Separator styles={separatorStyles} alignContent='start'>
                    Actions
                </Separator>

                <div className={styles.bottomSection}>
                    <div>
                        <div>
                            <input
                                type='radio'
                                aria-label={'Approve Withdrawal Request'}
                                value={APPROVE_WITHDRAWAL}
                                checked={approveOrDeny === APPROVE_WITHDRAWAL}
                                onChange={onRadioButtonClick}
                            />
                            &nbsp;
                            <span>Approve withdrawal and terminate screening</span>
                        </div>
                    </div>

                    <div>
                        <div>
                            <input
                                type='radio'
                                aria-label={'Dismiss Withdrawal Request'}
                                value={DENY_WITHDRAWAL}
                                checked={approveOrDeny === DENY_WITHDRAWAL}
                                onChange={onRadioButtonClick}
                            />
                            &nbsp;
                            <span>Dismiss withdrawal request</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <span className={styles.footerBtn}>
                    <DefaultButton onClick={props.onClose}>Close</DefaultButton>
                </span>
                <span className={styles.footerBtn}>
                    <PrimaryButton onClick={onSubmitWithdrawApproveModal}>Save</PrimaryButton>
                </span>
            </div>
        </div>
    );
}

const styles = mergeStyleSets({
    root: {
        minWidth: 400,
        maxWidth: 600,
    },
    label: {
        fontWeight: FontWeights.semibold,
    },
    dataLine: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        paddingTop: '4px',
        paddingBottom: '4px',
    },
    header: {
        borderTop: `4px solid`,
        fontSize: FontSizes.xLargePlus,
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '25px 30px 10px 30px',
        boxSizing: 'border-box',
    },
    body: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, 1fr)',
        minWidth: 300,
        padding: '0 30px 30px 30px',
    },
    mainPanel: {},
    footer: {
        backgroundColor: 'rgb(253, 253, 253)',
        padding: '14px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    footerBtn: {
        marginRight: '10px',
    },
    withdrawalInputs: {
        marginTop: '10px',
    },
    aliasText: {
        color: 'rgba(0,0,0,.5)',
    },
    topSection: {
        marginBottom: '30px',
    },
    bottomSection: {
        marginTop: '10px',
    },
});
