import React, { useState } from 'react';
import { IconButton, Label, MessageBar, MessageBarType, PrimaryButton } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import Modal from 'components/common/modal';
import { doNothing, generateRandomKey } from 'utils/misc-utils';
import Spacer from 'components/common/spacer';

export default function FacilitiesKioskRestartOptions(): JSX.Element {
    const [isActionInProgress, setIsActionInProgress] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const navigationToolbarColor = '#242424';
    const iconStyles = {
        icon: { color: 'white', fontSize: 28 },
        root: {
            width: 45,
            height: 45,
            backgroundColor: navigationToolbarColor,
        },
        rootHovered: { backgroundColor: navigationToolbarColor },
        rootPressed: { backgroundColor: navigationToolbarColor },
    };
    const buttonStyles = {
        root: {
            width: '214px',
            height: '48px',
        },
    };

    const restartUwp = (ignoreErrorMessage?: boolean): Promise<void> =>
        restart(true, ignoreErrorMessage);
    const restartMachine = (): Promise<void> => restart(false);

    async function restart(isUwpOnly: boolean, ignoreErrorMessage?: boolean): Promise<void> {
        const httpOptions = { method: 'POST' };
        setIsActionInProgress(true);
        const response = await fetch(
            `https://127.0.0.1:7072/restart?isuwponly=${isUwpOnly}`,
            httpOptions,
        );
        setIsActionInProgress(false);

        if (response.ok || ignoreErrorMessage) {
            return;
        }

        if (response.status === 400 || response.status === 500) {
            setErrorMessage(await response.text());
        }
    }

    return (
        <>
            <div style={{ marginRight: '7px' }}>
                <IconButton
                    styles={iconStyles}
                    iconProps={{ iconName: IconNames.Refresh }}
                    ariaLabel='Restart App'
                    onClick={(): Promise<void> => restartUwp(true)}
                    disabled={isActionInProgress}
                />
                <IconButton
                    styles={iconStyles}
                    iconProps={{ iconName: IconNames.Settings }}
                    ariaLabel='Kiosk Specs & Options Dialog Button'
                    onClick={(): void => setIsDialogOpen(true)}
                    disabled={isActionInProgress}
                />
            </div>
            <Modal
                key={`${generateRandomKey()}`}
                title='Kiosk Specs & Options'
                isOpen={isDialogOpen}
                submitButtonText={'Close'}
                onSubmit={(): void => {
                    setIsDialogOpen(false);
                    setErrorMessage(undefined);
                }} // have the submit button act as the close button
                isSubmitButtonDisabled={isActionInProgress}
                isBlocking={isActionInProgress}
                onDismiss={(): void => {
                    setIsDialogOpen(false);
                    setErrorMessage(undefined);
                }}
                shouldHideCancelButton={true}
                onCancel={(): void => doNothing()}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                    }}>
                    {((window as any).facilitiesKioskUwpVersion as string) && (
                        <>
                            <Label>Version</Label>
                            <div>{(window as any).facilitiesKioskUwpVersion as string}</div>
                        </>
                    )}
                    <Spacer marginTop={6} />
                    <PrimaryButton
                        styles={buttonStyles}
                        ariaLabel='Restart App'
                        onClick={(): Promise<void> => restartUwp(false)}
                        disabled={isActionInProgress}>
                        Restart App
                    </PrimaryButton>
                    <Spacer marginTop={10} />
                    <PrimaryButton
                        styles={buttonStyles}
                        ariaLabel='Restart Machine'
                        onClick={restartMachine}
                        disabled={isActionInProgress}>
                        Restart Machine
                    </PrimaryButton>
                    {errorMessage && (
                        <>
                            <Spacer marginTop={10} />
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                onDismiss={(): void => setErrorMessage(undefined)}>
                                {errorMessage}
                            </MessageBar>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
}
