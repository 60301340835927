import React, { useContext, useEffect, useState } from 'react';
import {
    ActionButton,
    Checkbox,
    Icon,
    Label,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Stack,
    TextField,
    DatePicker,
} from '@fluentui/react';
import BasicDetailsModal, {
    UpdateParams,
} from 'components/personnel-profile/common/basic-details-modal';
import EmployeeClient, {
    IEmployeeEditableInfo,
    IEmployeeEditableRequest,
    IEmployeeWithEditableData,
    SearchEmployeeStatus,
    AllEmployeeEditableFields,
} from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import { dateToDateStringFormat } from 'utils/time-utils';
import EllipsisText from 'components/common/ellipsis-text';
import { SharedColors } from '@fluentui/theme';
import { UserContext } from 'contexts/user-context';

export type UpdateKeyVauleType = <K extends keyof IEmployeeEditableInfo>(
    key: K,
    value: IEmployeeEditableInfo[K],
) => void;

export interface EditableEmployeeDataProps {
    employee: IEmployeeWithEditableData;
    editableData: IEmployeeEditableInfo | undefined;
    secureField: AllEmployeeEditableFields;
    placeholder?: string;
    regex?: string | RegExp;
    errorMessage?: string;
    dialogTitle: string;
    dialogLabel: string;
    type: 'Text' | 'Date' | 'Checkbox';
    updateKeyValue: UpdateKeyVauleType;
}

export default function EditableEmployeeData(props: EditableEmployeeDataProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [value, setValue] = useState<string | undefined>(getPropsEditableData());
    const [errorMsg, setErrorMsg] = useState<JSX.Element>();
    const [isEditDialogOpen, setEditDialogOpen] = useState<boolean>(false);

    function toggleEditDialogOpen(): void {
        setValue(value);
        setErrorMsg(undefined);
        setEditDialogOpen(!isEditDialogOpen);
    }

    useEffect(() => {
        if (props.editableData && props.secureField) {
            setValue(getPropsEditableData());
        }
    }, [props.editableData, props.secureField, isEditDialogOpen]);

    async function updateKeyValue(): Promise<void> {
        if (!props.regex || !value || value?.match(props.regex)) {
            try {
                if (
                    !value &&
                    (props.secureField === AllEmployeeEditableFields.firstName ||
                        props.secureField === AllEmployeeEditableFields.lastName ||
                        props.secureField === AllEmployeeEditableFields.nationalIdNumber ||
                        props.secureField === AllEmployeeEditableFields.personalEmail)
                ) {
                    setErrorMsg(<span>You must have a value for {props.dialogLabel} </span>);
                } else {
                    let results = [];

                    if (
                        props.secureField === AllEmployeeEditableFields.nationalIdNumber ||
                        props.secureField === AllEmployeeEditableFields.personalEmail
                    ) {
                        results = await EmployeeClient.searchPrehires(value!, authContext);
                    }

                    if (results.length > 0) {
                        setErrorMsg(
                            <span>
                                Duplicate entry. An employee profile with that {props.dialogLabel}{' '}
                                already exists
                            </span>,
                        );
                    } else {
                        const employeeEditableRequest: IEmployeeEditableRequest = {
                            id: props.employee.data?.id,
                            alias: props.employee.data.alias,
                            requestBy: userContext.employeeRecord.id,
                            employeeStatus: props.employee.employeeStatus
                                ? props.employee.employeeStatus
                                : SearchEmployeeStatus.active,
                            employeeEditableInfo: { [props.secureField]: value },
                        };

                        const editableFieldsFromUpsert = await EmployeeClient.updateEditableEmployeeData(
                            authContext,
                            employeeEditableRequest,
                        );

                        if (editableFieldsFromUpsert) {
                            props.updateKeyValue(props.secureField, value);
                        }
                        toggleEditDialogOpen();
                    }
                }
            } catch (e) {
                setErrorMsg(<span>Error trying to update {props.dialogLabel}</span>);
            }
        } else {
            setErrorMsg(<span>{props.errorMessage}</span>);
        }
    }

    function getPropsEditableData(): string | undefined {
        if (props.editableData) {
            return props.editableData[props.secureField]
                ? `${props.editableData[props.secureField]}`
                : '';
        }
        return undefined;
    }

    function displayEditInput(): JSX.Element {
        switch (props.type) {
            case 'Text':
                return (
                    <TextField
                        ariaLabel={props.dialogLabel}
                        defaultValue={value}
                        placeholder={props.placeholder}
                        onChange={(event, newValue): void => setValue(newValue)}
                    />
                );
            case 'Date':
                return (
                    <DatePicker
                        ariaLabel={props.dialogLabel}
                        value={value ? new Date(value) : undefined}
                        placeholder={props.placeholder}
                        allowTextInput={true}
                        onSelectDate={(date): void => {
                            setValue(dateToDateStringFormat(date));
                        }}
                    />
                );
            case 'Checkbox':
                return (
                    <Checkbox
                        ariaLabel={props.dialogLabel}
                        onChange={(ev, checked: boolean | undefined): void =>
                            setValue(`${checked}`)
                        }
                        checked={isTrue()}
                    />
                );
        }
    }

    function displayEditDialog(): JSX.Element {
        return (
            <>
                <ActionButton
                    onClick={toggleEditDialogOpen}
                    iconProps={{ iconName: 'Edit' }}
                    ariaLabel='Edit'
                />
                {isEditDialogOpen && (
                    <BasicDetailsModal
                        title={props.dialogTitle}
                        editButton={UpdateParams}
                        closeTitle={'Cancel'}
                        isOpen={isEditDialogOpen}
                        onEditClick={updateKeyValue}
                        onCloseClick={toggleEditDialogOpen}
                        actionButtonDisabled={false}>
                        <Stack>
                            <ActionButton
                                // The purpose of this button is to catch the initial propagated onClick event
                                // when the 'edit' button is clicked to open up this modal.
                                // Otherwise the first stack item's onChange event will trigger e.g. in this case
                                // the dropdown will select and display the top item from the dropdown selection
                                style={{ maxHeight: 0, maxWidth: 0 }}
                                hidden={true}
                                onClick={(event): void => {
                                    event.stopPropagation();
                                }}
                            />

                            <Stack.Item>
                                <Label required={true}>{props.dialogLabel}</Label>
                            </Stack.Item>
                            <Stack.Item>{displayEditInput()}</Stack.Item>
                        </Stack>

                        {errorMsg && (
                            <Stack.Item>
                                <div style={{ marginTop: '5px' }}></div>
                                <MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={false}
                                    dismissButtonAriaLabel='Close'>
                                    {errorMsg}
                                </MessageBar>
                            </Stack.Item>
                        )}
                    </BasicDetailsModal>
                )}
            </>
        );
    }

    function displayValueAsText(): JSX.Element {
        return (
            <>
                <EllipsisText
                    text={
                        props.type === 'Text'
                            ? getPropsEditableData()
                            : getPropsEditableData()
                            ? new Date(
                                  getPropsEditableData() ? (getPropsEditableData() as string) : '',
                              ).toDateString()
                            : ''
                    }
                    textLengthBeforeEllipsis={16}
                />
                {displayEditDialog()}
            </>
        );
    }

    function displayValueAsCheckBox(): JSX.Element {
        return (
            <>
                <div className={styles.multivalue}>
                    <div>{renderCheckBox()}</div>
                    <div>
                        <span style={{ opacity: isTrue() ? undefined : 0.5 }}>
                            {props.dialogLabel}
                        </span>
                    </div>
                    {displayEditDialog()}
                </div>
            </>
        );
    }

    const styles = mergeStyleSets({
        multivalue: {
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '10px',
            selectors: {
                '& div:nth-child(2)': {
                    marginLeft: 10,
                },
            },
        },
    });

    function isTrue(): boolean {
        return /true/i.test(`${value}`);
    }

    function renderCheckBox(): JSX.Element {
        const checkboxStyles = {
            root: {
                fontSize: 18,
                color: isTrue() ? SharedColors.cyanBlue10 : undefined,
                opacity: isTrue() ? undefined : 0.5,
            },
        };
        return isTrue() ? (
            <Icon styles={checkboxStyles} iconName='CheckboxCompositeReversed' />
        ) : (
            <Icon styles={checkboxStyles} iconName='Checkbox' />
        );
    }

    switch (props.type) {
        case 'Checkbox':
            return displayValueAsCheckBox();
        case 'Text':
        case 'Date':
        default:
            return displayValueAsText();
    }
}
