import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import PageTitle from 'components/common/page-title';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { IPersonaProps, Stack } from '@fluentui/react';
import { employeeHistoryUrl } from 'components/sca/sca-breadcrumbs';
import Spacer from 'components/common/spacer';
import CreateReviewModal from 'components/sca/manage/create-review/create-review-modal';
import { IReviewPeriod } from 'clients/sca-client';

interface IScaManageActionBarProps {
    onNewReviewPeriod: () => void;
    currentReviewPeriod: IReviewPeriod | undefined;
}

export default function ScaManageActionBar(props: IScaManageActionBarProps): JSX.Element {
    const [redirectTo, setRedirectTo] = useState<string>();

    const onCandidateSelectedHandler = (info?: IPersonaProps): void => {
        const employee = JSON.parse(info?.itemProp || '{}');
        setRedirectTo(employeeHistoryUrl(employee.id));
    };

    if (redirectTo) {
        return <Redirect push to={redirectTo} />;
    }
    return (
        <div>
            <PageTitle>Employee Search</PageTitle>
            <Spacer marginTop={16} />
            <Stack horizontal style={{ justifyContent: 'space-between' }}>
                <Stack.Item>
                    <div style={{ width: 300 }}>
                        <EmployeePickerTypeaheadSearch
                            ariaLabel='Employee Search'
                            placeHolder='Employee Name or Alias'
                            onCandidateSelected={onCandidateSelectedHandler}
                        />
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <CreateReviewModal
                        mode='add'
                        onNewReviewPeriod={props.onNewReviewPeriod}
                        currentReviewPeriod={props.currentReviewPeriod}
                    />
                </Stack.Item>
            </Stack>
            <Spacer marginTop={16} />
        </div>
    );
}
