import {
    ChoiceGroup,
    DefaultButton,
    IChoiceGroupOption,
    Label,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Separator,
    Stack,
} from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { globalSeparatorStyles } from 'assets/styles/global-styles';
import CloudScreeningClient from 'clients/cloud-screening-client';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { EmployeeNameResolverId } from 'components/common/employee/employee-name-resolver';

export interface ManagerDelegateToggleProps {
    managerId: string;
    setManagerId: React.Dispatch<React.SetStateAction<string>>;
    triggerClear: number;
}

export default function ManagerDelegateToggle(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    props: ManagerDelegateToggleProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [delegateManagerIds, setDelegateManagerIds] = useState<string[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [toggleOptions, setToggleOptions] = useState<IChoiceGroupOption[]>();
    const [selectedOptionKey, setSelectedOptionKey] = useState<string>();

    function onChange(
        event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ): void {
        if (option) {
            props.setManagerId(option.key);
            setSelectedOptionKey(option.key);
        }
    }

    useEffect(() => {
        setSelectedOptionKey(undefined);
    }, [props.triggerClear]);

    useEffect(() => {
        if (delegateManagerIds?.length) {
            const options: IChoiceGroupOption[] = delegateManagerIds.map((managerId, i) => {
                return {
                    key: managerId.toString(),
                    onRenderField: (prop, render): JSX.Element => {
                        return (
                            <div
                                className={styles.radioButtonRow}
                                key={`manager_toggle_row_${managerId}`}>
                                {render!(prop)}
                                <div className={styles.radioButtonContent}>
                                    <EmployeeBasicHoverCard
                                        key={`manager_toggle_row_hovercard_${managerId}`}
                                        personnelId={managerId}
                                    />
                                </div>
                            </div>
                        );
                    },
                } as IChoiceGroupOption;
            });

            setToggleOptions(options);
        }
    }, [delegateManagerIds]);

    useEffect(() => {
        let isMounted = true;

        async function populateDelegateManagerIds(): Promise<void> {
            try {
                const assignments = await CloudScreeningClient.getDelegateAssignmentsByDelegateId(
                    authContext,
                    userContext.employeeRecord.id,
                );

                const managerIds = assignments.map((assignment) => {
                    return assignment.managerPersonnelId;
                });

                if (isMounted) {
                    setDelegateManagerIds(managerIds);
                }
            } catch (err) {
                setErrorMessage('Error retrieving delegates.');
            }
        }

        populateDelegateManagerIds();

        return (): void => {
            isMounted = false;
        };
    }, [userContext.employeeRecord.id, authContext]);

    return (
        <Stack>
            {errorMessage && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    dismissButtonAriaLabel='Close'
                    overflowButtonAriaLabel='See more'
                    onDismiss={(): void => setErrorMessage('')}>
                    {errorMessage}
                </MessageBar>
            )}
            <Stack.Item>
                <Label id='delegateForLabel'>
                    <Separator styles={globalSeparatorStyles} alignContent='start'>
                        Delegate For
                    </Separator>
                </Label>
                <div style={{ fontStyle: 'italic' }}>
                    As a delegate, view and request a screen for all employees in your
                    manager&apos;s organization.
                </div>
                <div>
                    <ChoiceGroup
                        defaultSelectedKey={'0'}
                        selectedKey={selectedOptionKey}
                        options={toggleOptions}
                        onChange={onChange}
                        ariaLabelledBy='delegateForLabel'
                    />
                </div>
            </Stack.Item>

            <Stack.Item>
                <div style={{ marginTop: '10px' }}>
                    Viewing as&nbsp;
                    <b>
                        <EmployeeNameResolverId personnelId={props.managerId} />
                    </b>
                </div>
            </Stack.Item>

            <Stack.Item>
                <div className={styles.lowerRightContent}>
                    <DefaultButton
                        text={'Clear'}
                        onClick={(): void => {
                            setSelectedOptionKey(undefined);
                            props.setManagerId(userContext.employeeRecord.id);
                        }}
                        allowDisabledFocus
                    />
                </div>
            </Stack.Item>
        </Stack>
    );
}

const styles = mergeStyleSets({
    radioButtonRow: {
        display: 'flex',
        alignItems: 'center',
    },
    radioButtonContent: {
        marginLeft: '10px',
    },
    lowerRightContent: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
});
