import React from 'react';
import { Separator, mergeStyleSets } from '@fluentui/react';

export default function ClearSeparator(): JSX.Element {
    return <Separator styles={separatorStyle} />;
}

const separatorStyle = mergeStyleSets({
    root: {
        selectors: {
            '&::before': {
                backgroundColor: 'inherit',
            },
        },
    },
});
