import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import ModalActionButton, {
    ModalConclusion,
    onModalConcludeType,
} from 'components/common/buttons/modal-action-button';
import { ModalSizeType } from 'components/common/modal';
import { IconNames } from 'assets/constants/global-constants';
import GroupClient, {
    GroupRole,
    IGroup,
    IGroupCustomOffboardMessageRequest,
    IGroupOffboardMessageTemplate,
} from 'clients/group-client';
import { useIsMounted, useFetchSimple, GeneralFetchResultDataType } from 'utils/misc-hooks';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { Separator, Stack } from '@fluentui/react';
import { globalSeparatorStyles } from 'assets/styles/global-styles';
import { useTextField } from 'components/common/use-input/use-textfield';
import { useFluentToggle } from 'components/common/use-input/use-fluent-toggle';
import BoldFont from 'components/common/misc/bold-font';
import Indent from 'components/common/misc/indent';
import Spacer from 'components/common/spacer';
import { Role } from 'configs/roles';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { doNothing, doNothingAsync } from 'utils/misc-utils';
import { modalContentsStyles } from 'components/groups/manage-group/settings/edit-welcome-message-modal-action-button';

interface EditOffboardMessageModalActionButtonProps {
    group: IGroup;
    enable: boolean;
    fetchGroup: () => Promise<void>;
}

export default function EditOffboardMessageModalActionButton(
    props: EditOffboardMessageModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);

    const [offboardMessageTemplateVar, setOffboardMessageTemplateVar] = useState<
        GeneralFetchResultDataType<IGroupOffboardMessageTemplate>
    >();
    const offboardMessageTemplate = offboardMessageTemplateVar?.value;

    const canEditOffboardMsg =
        authContext.isInRole(Role.GroupAdmin) ||
        (!!groupContext.groupMembershipVar?.value?.role &&
            groupContext.groupMembershipVar?.value?.role !== GroupRole.AUDITOR);

    const hasErrorFetching = [
        offboardMessageTemplateVar?.errMsg,
        groupContext.groupMembershipVar?.errMsg,
    ].some((value) => !!value);

    const isMounted = useIsMounted();

    const onButtonClick = (): void => {
        setOffboardMessageTemplateVar({ shouldFetch: true });
        initOptionalMessage('');
        initCustomSignature('');
        initShouldUseCustomSignature(false);
        props.fetchGroup();
    };

    useEffect(() => {
        initOptionalMessage(props.group?.customOffboardMessage);
        initCustomSignature(props.group?.customOffboardMessageSignature);
        initShouldUseCustomSignature(!!props.group?.customOffboardMessageSignature);
    }, [props.group]);

    const { isFetching: isFetchingMessageTemplate } = useFetchSimple<IGroupOffboardMessageTemplate>(
        {
            dependencies: [offboardMessageTemplateVar?.shouldFetch],
            canPerformFetch: !!offboardMessageTemplateVar?.shouldFetch,
            fetchFunc: async (): Promise<IGroupOffboardMessageTemplate> => {
                return GroupClient.getGroupOffboardMessageTemplate(authContext, props.group.id);
            },
            onSuccess: (result: IGroupOffboardMessageTemplate): void => {
                if (isMounted()) {
                    setOffboardMessageTemplateVar({ value: result });
                }
            },
            onError: (): void => {
                if (isMounted()) {
                    setOffboardMessageTemplateVar({
                        errMsg: 'Error fetching offboard message template',
                    });
                }
            },
            onFinally: doNothing,
        },
    );

    const maxOptionalMessageLength = 20000;
    const maxCustomSignatureLength = 20000;

    const {
        value: optionalMessage,
        theElement: optionalMessageElement,
        initialize: initOptionalMessage,
    } = useTextField({
        rows: 10,
        maxLength: maxOptionalMessageLength,
        multiline: true,
        resizable: false,
        autoAdjustHeight: true,
    });

    const {
        value: customSignature,
        theElement: customSignatureElement,
        initialize: initCustomSignature,
    } = useTextField({
        rows: 4,
        maxLength: maxCustomSignatureLength,
        multiline: true,
        resizable: false,
        autoAdjustHeight: true,
    });

    const {
        value: shouldUseCustomSignature,
        theElement: shouldUseCustomSignatureElement,
        initialize: initShouldUseCustomSignature,
    } = useFluentToggle({
        inlineLabel: true,
        label: <ShouldUseCustomSignatureLabel />,
    });

    function ShouldUseCustomSignatureLabel(): JSX.Element {
        return <div>{shouldUseCustomSignature ? 'Custom Signature' : 'Default Signature'}</div>;
    }

    const updateOffboardMessage = async (): Promise<void> => {
        try {
            const request: IGroupCustomOffboardMessageRequest = {
                customOffboardMessage: optionalMessage as string,
                customOffboardMessageSignature: shouldUseCustomSignature ? customSignature : '',
                // eslint-disable-next-line @typescript-eslint/naming-convention
                _etag: props.group._etag,
            };

            await GroupClient.setGroupCustomOffboardMessage(authContext, props.group.id, request);
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((e as any)?.status === 409) {
                throw 'The record has been modified since you selected it. Please close and re-open the dialog and apply your changes again.';
            } else {
                throw 'Error updating offboard message';
            }
        }
    };

    function displayEditPane(): JSX.Element {
        return (
            <div>
                <MySeparator>Default Message</MySeparator>
                <Indent>
                    <Markdown>{offboardMessageTemplate?.templateHeader ?? ''}</Markdown>
                </Indent>

                <MySeparator>Optional Message</MySeparator>
                <Stack horizontal verticalAlign='center' horizontalAlign='end'>
                    <Stack.Item>
                        <a
                            href='https://www.markdownguide.org/basic-syntax'
                            // See https://mathiasbynens.github.io/rel-noopener/ for the reason behind rel=noopener
                            rel='noopener noreferrer'
                            target='_blank'>
                            Markdown Guide
                        </a>
                    </Stack.Item>
                </Stack>
                <Indent>
                    {optionalMessageElement()}
                    <span>
                        {optionalMessage?.length ?? 0} / {maxOptionalMessageLength}
                    </span>
                </Indent>

                <Spacer marginTop={10} />
                {shouldUseCustomSignatureElement()}

                {shouldUseCustomSignature && (
                    <>
                        <MySeparator>
                            <BoldFont>Sign Off</BoldFont>
                        </MySeparator>
                        <Indent>
                            <>
                                <br />
                                {customSignatureElement()}
                                <span>
                                    {customSignature?.length ?? 0} / {maxCustomSignatureLength}
                                </span>
                            </>
                        </Indent>
                    </>
                )}

                {!shouldUseCustomSignature && (
                    <>
                        <MySeparator>
                            <BoldFont>Sign Off</BoldFont>
                        </MySeparator>
                        <Indent>
                            <>
                                <Markdown>
                                    {offboardMessageTemplate?.templateDefaultSignature ?? ''}
                                </Markdown>
                            </>
                        </Indent>
                    </>
                )}

                <Markdown>{offboardMessageTemplate?.templateClosing ?? ''}</Markdown>
            </div>
        );
    }

    function displayPreviewPane(): JSX.Element {
        if (!offboardMessageTemplate) {
            return <></>;
        }

        let text = offboardMessageTemplate?.templateHeader + '\n\n';

        if (!!optionalMessage) {
            text += optionalMessage + '\n\n';
        }

        if (shouldUseCustomSignature) {
            if (!!customSignature) {
                text += customSignature + '\n\n';
            }
        } else {
            text += offboardMessageTemplate.templateDefaultSignature + '\n\n';
        }

        text += offboardMessageTemplate.templateClosing + '\n\n';

        return (
            <div>
                <MySeparator>Message Preview</MySeparator>
                <Markdown>{text ?? ''}</Markdown>
            </div>
        );

        // Original Angular code had the following function call.
        // The following comment appears on the function:
        //     Single asterisks don't work for Formatting Links in Markdown library so replace them with underscores
        // return this.convertSingleAsterickToUnderscore(text);
        // Keeping the comment here for possible future reference.
    }

    const onEditConcluded = (conclusion: ModalConclusion): void => {
        if (conclusion === ModalConclusion.Done) {
            props.fetchGroup();
        }
    };

    const determineProps = (): ModalPropsType => {
        if (canEditOffboardMsg) {
            return {
                shouldHideCancelButton: false,
                text: 'Edit Offboard Message',
                title: 'Edit Offboard Message',
                icon: IconNames.Edit,
                submitIcon: undefined,
                titleIcon: undefined,
                submitButtonText: 'Update',
                enableSubmit: true,
                size: ModalSizeType.xLarge,
                onSubmit: updateOffboardMessage,
                onModalConcluded: onEditConcluded,
            };
        } else {
            return {
                shouldHideCancelButton: true,
                text: 'View Offboard Message',
                title: 'Offboard Message',
                icon: IconNames.View,
                submitIcon: undefined,
                titleIcon: undefined,
                submitButtonText: 'Close',
                enableSubmit: true,
                size: ModalSizeType.large,
                onSubmit: doNothingAsync,
                onModalConcluded: doNothing,
            };
        }
    };
    const modalProps = determineProps();

    const displayModalContents = (): JSX.Element => {
        if (groupContext.groupMembershipVar.isFetching || isFetchingMessageTemplate) {
            return <IsLoadingIndicator isLoading={true} />;
        }

        if (canEditOffboardMsg) {
            return (
                <Stack
                    horizontal
                    verticalAlign='start'
                    horizontalAlign='center'
                    className={modalContentsStyles.container}>
                    <Stack.Item className={modalContentsStyles.modalPane}>
                        {displayEditPane()}
                    </Stack.Item>
                    <Stack.Item className={modalContentsStyles.modalPane}>
                        {displayPreviewPane()}
                    </Stack.Item>
                </Stack>
            );
        }

        return displayPreviewPane();
    };

    return (
        <ModalActionButton
            errorMsg={hasErrorFetching ? 'Error fetching data' : undefined}
            shouldHideCancelButton={modalProps?.shouldHideCancelButton}
            text={modalProps.text}
            enable={props.enable}
            iconName={modalProps.icon}
            modalTitle={modalProps.title}
            submitButtonText={modalProps.submitButtonText}
            enableSubmit={modalProps.enableSubmit}
            onSubmit={modalProps.onSubmit}
            size={modalProps.size}
            onModalConcluded={modalProps.onModalConcluded}
            fixWidth={true}
            onButtonClick={onButtonClick}>
            {displayModalContents()}
        </ModalActionButton>
    );
}

function Markdown(props: { children: string }): JSX.Element {
    return (
        <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
            {props.children}
        </ReactMarkdown>
    );
}

function MySeparator(props: { children: string | JSX.Element }): JSX.Element {
    return (
        <Separator styles={globalSeparatorStyles} alignContent='start'>
            {props.children}
        </Separator>
    );
}

type ModalPropsType = {
    shouldHideCancelButton: boolean;
    text: string;
    title: string;
    icon: string;
    submitIcon: string | undefined;
    titleIcon: string | undefined;
    submitButtonText: string;
    enableSubmit: boolean;
    size: ModalSizeType;
    onSubmit: () => Promise<void>;
    onModalConcluded: onModalConcludeType<void>;
};
