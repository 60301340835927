import FeedbackIconPanel from 'components/common/page-shell/feedback-notifications-profile/feedback/feedback-icon-panel';
// import NotifcationsIconCallout from 'components/common/page-shell/feedback-notifications-profile/notifications';
import ProfileCallout from 'components/common/page-shell/feedback-notifications-profile/profile/profile-icon-callout';
import React, { useEffect, useState } from 'react';

type FeedbackNotificationsProfileIconsProps = {
    isCsatDefault: boolean;
    onResetCsatDefault: () => void;
};

export default function FeedbackNotificationsProfileIcons(
    props: FeedbackNotificationsProfileIconsProps,
): JSX.Element {
    const { isCsatDefault: isCsatDefault, onResetCsatDefault: onResetCsatDefault } = props;
    const [isFeedbackPanelOpen, setIsFeedbackPanelOpen] = useState<boolean>(false);
    const [isProfileCalloutOpen, setIsProfileCalloutOpen] = useState<boolean>(false);

    useEffect(() => {
        if (isCsatDefault) {
            onResetCsatDefault();
            setIsFeedbackPanelOpen(true);
        }
    }, [isCsatDefault, onResetCsatDefault]);

    return (
        <div style={{ display: 'flex' }}>
            <FeedbackIconPanel
                isFeedbackPanelOpen={isFeedbackPanelOpen}
                setIsFeedbackPanelOpen={setIsFeedbackPanelOpen}
                isProfileCalloutOpen={isProfileCalloutOpen}
                setIsProfileCalloutOpen={setIsProfileCalloutOpen}
            />
            {/* <NotifcationsIconCallout /> */}
            <div style={{ marginLeft: '30px' }}>
                <ProfileCallout
                    isProfileCalloutOpen={isProfileCalloutOpen}
                    setIsProfileCalloutOpen={setIsProfileCalloutOpen}
                    setIsFeedbackPanelOpen={setIsFeedbackPanelOpen}
                />
            </div>
        </div>
    );
}
