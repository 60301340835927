import { Dropdown, IDropdownOption, TextField, Toggle } from '@fluentui/react';
import React, { useCallback } from 'react';
import ElementFooter from 'components/forms/element-footer';
import { ElementTypeProps, ValidatorOptions } from 'components/forms/forms-common';
import { isNumeric } from 'utils/string-utils';

const validatorTypeOptions: IDropdownOption[] = [
    { key: 'limit', text: 'Character Limit' },
    { key: 'regex', text: 'Regex' },
];

const regexOptions: IDropdownOption[] = [
    { key: 'phone', text: 'Phone number' },
    { key: 'email', text: 'Email address' },
    { key: 'zipcode', text: 'Zipcode' },
];

const defaultTextValidation = {
    type: 'limit',
    value: 100,
} as ValidatorOptions;

const defaultRegexValidation = {
    type: 'regex',
    value: 'phone',
} as ValidatorOptions;

export default function TextFieldElement(props: ElementTypeProps): JSX.Element {
    const { element, updateForm } = props;

    const validationToggled = (checked: boolean): void => {
        const newValidation = checked ? defaultTextValidation : undefined;
        updateForm(element.id, newValidation, 'validatorOptions');
    };

    const onValidationTypeChange = (
        event: React.FormEvent<HTMLDivElement>,
        item?: IDropdownOption,
    ): void => {
        const newValidation =
            item?.key === 'regex' ? defaultRegexValidation : defaultTextValidation;

        updateForm(element.id, newValidation, 'validatorOptions');
    };

    const onValidationValueChange = (newValue?: string | number): void => {
        if (newValue === undefined) {
            return;
        }
        const newValidation = { ...element.validatorOptions, value: newValue } as ValidatorOptions;
        updateForm(element.id, newValidation, 'validatorOptions');
    };

    const onCharLimitChange = (newValue: string): void => {
        if (!newValue || !isNumeric(newValue) || !isNumeric(newValue[0])) {
            onValidationValueChange(0);
            return;
        }
        onValidationValueChange(Number(newValue));
    };

    const customLabel = (label: string): JSX.Element => {
        return <div>{label}</div>;
    };

    const ValidationElement = useCallback(
        () => (
            <>
                <Toggle
                    checked={element.validatorOptions !== undefined}
                    label={<div style={{ fontWeight: 400 }}>Validation</div>}
                    onChange={(ev, checked) => {
                        if (checked !== undefined) {
                            validationToggled(checked);
                        }
                    }}
                />
                {Object.keys(element.validatorOptions ?? {}).length > 0 && (
                    <div style={{ padding: '0 .7rem', marginTop: '.1rem', width: '10rem' }}>
                        <Dropdown
                            ariaLabel='Validation Type'
                            options={validatorTypeOptions}
                            selectedKey={element.validatorOptions?.type ?? undefined}
                            onChange={onValidationTypeChange}
                            onRenderLabel={() => customLabel('Validation Type')}
                        />
                    </div>
                )}
                {element.validatorOptions?.type === 'regex' && (
                    <div style={{ padding: '.7rem', width: '10rem' }}>
                        <Dropdown
                            ariaLabel='Regex Type'
                            options={regexOptions}
                            selectedKey={element.validatorOptions?.value as string}
                            onChange={(ev, item) => onValidationValueChange(item?.key)}
                            onRenderLabel={() => customLabel('Regex Type')}
                        />
                    </div>
                )}
                {element.validatorOptions?.type === 'limit' && (
                    <div style={{ padding: '.7rem', width: '10rem' }}>
                        <TextField
                            ariaLabel='Character limit'
                            maxLength={8}
                            value={(element.validatorOptions?.value as string) || ''}
                            onChange={(ev, newValue): void => onCharLimitChange(newValue ?? '')}
                            onRenderLabel={(): JSX.Element => customLabel('Character limit')}
                        />
                    </div>
                )}
            </>
        ),
        [element.validatorOptions],
    );
    return (
        <>
            <TextField
                value={element.label}
                onChange={(ev, newValue): void => updateForm(element.id, newValue, 'label')}
                placeholder='Enter a label...'
                underlined
            />
            <TextField
                value={element.description}
                onChange={(ev, newValue): void => updateForm(element.id, newValue, 'description')}
                placeholder='Enter a description...'
            />
            <div style={{ marginTop: '1rem' }}>
                <TextField disabled placeholder='Enter your answer' />
            </div>
            <ElementFooter
                element={element}
                updateForm={updateForm}
                hasRequiredToggle={true}
                CustomElement={ValidationElement}
            />
        </>
    );
}
