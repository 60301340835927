import React from 'react';
import { AccessDeniedURL } from 'assets/constants/global-constants';
import CheckRole from 'components/common/check-role';
import { Role } from 'configs/roles';
import ScaReviewPeriodFiltersProvider from 'components/sca/contexts/sca-review-period-filter-context';
import ScaManageViewId from 'components/sca/manage/sca-manage-view-id';
import { Location } from 'history';

interface IProps {
    location: Location;
}

export default function ScaManageViewIdFilterWrapper(props: IProps): JSX.Element {
    return (
        // SCA Auth
        <CheckRole requiredRolesAny={[Role.SCAAdmin]} redirectNotInRole={AccessDeniedURL}>
            <ScaReviewPeriodFiltersProvider>
                <ScaManageViewId location={props.location} />
            </ScaReviewPeriodFiltersProvider>
        </CheckRole>
    );
}
