import React, { ComponentProps, useCallback, useEffect, useRef } from 'react';

export interface IFacilitiesSvgMapSizeProvidingDivProps extends ComponentProps<'div'> {
    onSizeChange: (size: { width: number; height: number }) => void;
}

export default function FacilitiesSvgMapSizeProviderDiv(
    props: IFacilitiesSvgMapSizeProvidingDivProps,
): JSX.Element {
    const containerRef = useRef<HTMLDivElement | null>(null);

    const { onSizeChange } = props;

    const handleResize = useCallback(() => {
        if (containerRef.current && onSizeChange) {
            const { width, height } = containerRef.current.getBoundingClientRect();
            onSizeChange({ width, height });
        }
    }, [containerRef, onSizeChange]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    return (
        <div ref={containerRef} {...props}>
            {props.children}
        </div>
    );
}
