import React, { useContext, useEffect, useState } from 'react';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import GroupClient, {
    ISecurityGroup,
    IGroupSecurityGroupRequest,
    SecurityGroupDomain,
} from 'clients/group-client';
import { Stack } from '@fluentui/react';
import BoldFont from 'components/common/misc/bold-font';
import { AuthContext } from 'contexts/auth-context';
import { useIsMounted } from 'utils/misc-hooks';
import { convertSecurityGroupTableType } from 'components/groups/manage-group/settings/link-security-group/link-to-security-group-utils';
import IsLoadingIndicator from 'components/common/is-loading-indicator';

enum LinkingStage {
    NotLinking,
    Linking,
    ErrorLinking,
    Linked,
}

interface IConfirmSelectionProps {
    shouldPerformLinking: boolean;
    selectedSecurityGroup: ISecurityGroup | undefined;
    onEndOfLinking: () => void;
}

export default function LinkTheGroup(props: IConfirmSelectionProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);

    const [linkingStage, setLinkingStage] = useState<LinkingStage>(LinkingStage.NotLinking);
    const [linkingErr, setLinkingErr] = useState<string>();

    const isMounted = useIsMounted();

    useEffect(() => {
        async function linkTheGroup(): Promise<void> {
            if (
                !props.shouldPerformLinking ||
                !groupContext.group ||
                !props.selectedSecurityGroup
            ) {
                if (isMounted()) {
                    setLinkingStage(LinkingStage.NotLinking);
                }
                return;
            }
            switch (linkingStage) {
                case LinkingStage.NotLinking:
                    setLinkingErr('');
                    setLinkingStage(LinkingStage.Linking);
                    break;
                case LinkingStage.Linking:
                    const request: IGroupSecurityGroupRequest = {
                        groupId: groupContext.group.id,
                        securityGroupId: props.selectedSecurityGroup.id,
                        securityGroupAlias: props.selectedSecurityGroup.name,
                        securityGroupType: convertSecurityGroupTableType(
                            props.selectedSecurityGroup.type,
                        ),
                        domainName:
                            SecurityGroupDomain[
                                props.selectedSecurityGroup.domain?.toUpperCase() as keyof typeof SecurityGroupDomain
                            ],
                    };
                    try {
                        await GroupClient.linkSecurityGroup(authContext, request);
                        if (isMounted()) {
                            setLinkingStage(LinkingStage.Linked);
                        }
                    } catch (e) {
                        if (isMounted()) {
                            if (typeof e === 'string') {
                                setLinkingErr(e);
                            } else {
                                setLinkingErr('Error linking the group');
                            }
                            setLinkingStage(LinkingStage.ErrorLinking);
                        }
                    } finally {
                        props.onEndOfLinking();
                    }
                    break;
                case LinkingStage.ErrorLinking:
                case LinkingStage.Linked:
                default:
                    break;
            }
        }

        linkTheGroup();
    }, [props.shouldPerformLinking, linkingStage, groupContext.group, props.selectedSecurityGroup]);

    return (
        <Stack>
            {linkingStage === LinkingStage.Linking && (
                <IsLoadingIndicator isLoading={true} msg='Linking...' />
            )}
            {linkingStage === LinkingStage.ErrorLinking && (
                <div>
                    <p>
                        <span>Error linking group </span>
                        <BoldFont>{props.selectedSecurityGroup?.name}</BoldFont>
                        <span> to Personnel group </span>
                        <BoldFont>{groupContext.group?.name}</BoldFont>
                    </p>
                    <p>{linkingErr}</p>
                </div>
            )}
            {linkingStage === LinkingStage.Linked && (
                <div>
                    <p>
                        <span>Group </span>
                        <BoldFont>{props.selectedSecurityGroup?.name}</BoldFont>
                        <span> successfully linked to Personnel group </span>
                        <BoldFont>{groupContext.group?.name}</BoldFont>
                        <span>.</span>
                    </p>
                    <p>
                        <span>Compliant members of </span>
                        <BoldFont>{groupContext.group?.name}</BoldFont>
                        <span> will be synced to </span>
                        <BoldFont>{props.selectedSecurityGroup?.name}</BoldFont>
                        <span>.</span>
                    </p>
                </div>
            )}
        </Stack>
    );
}
