import React, { useState, useContext } from 'react';
import { ProgressIndicator, IPersonaProps, MessageBarType } from '@fluentui/react';
import EmployeeClient, {
    IEmployee,
    IEmployeeEditableInfo,
    IEmployeeEditableRequest,
    SearchEmployeeStatus,
} from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import Stepper, { StepperProps } from 'components/common/stepper';
import ModalCreationStep from 'components/common/modal-creation-step';
import CreateNewProfileStepperPageOne from 'components/personnel-profile/create-new-profile/create-new-profile-stepper-page-one';
import CreateNewProfileStepperPageTwo from 'components/personnel-profile/create-new-profile/create-new-profile-stepper-page-two';
import CreateNewProfileStepperPageThree from 'components/personnel-profile/create-new-profile/create-new-profile-stepper-page-three';
import CreateNewProfileStepperReview from 'components/personnel-profile/create-new-profile/create-new-profile-stepper-page-review';
import { displayErrorsWithHTML } from 'utils/error-display-utils';
import { IStepValidationResult } from 'types/step-validation-result';
import { PHONE_NUMBER_MAX_LENGTH, REGEX_EMAIL, REGEX_PHONE, REGEX_SSN } from 'utils/misc-utils';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'contexts/user-context';

export interface CreateNewProfileStepperProps {
    hideCreateNewProfileProcess: () => void;
    onCreateNewProfileError: (msg: string) => void;
}

export default function CreateNewProfileStepper(props: CreateNewProfileStepperProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [isValidating, setIsValidating] = useState<boolean>();
    const [prehireEditableData, setPrehireEditableData] = useState<IEmployeeEditableInfo>();
    // step 1
    const [firstName, setFirstName] = useState<string>();
    const [middleName, setMiddleName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [suffix, setSuffix] = useState<string>();
    const [nationalIdNumber, setNationalIdNumber] = useState<string>();
    // step 2
    const [positionControlNumber, setPositionControlNumber] = useState<string>();
    const [hiringManager, setHiringManager] = useState<IEmployee | undefined>();
    const [hiringManagerPersona, setHiringManagerPersona] = useState<IPersonaProps | undefined>();
    const [startDate, setStartDate] = useState<Date | undefined | null>();
    const [jobTitle, setJobTitle] = useState<string>();
    const [company, setCompany] = useState<string>();
    // step 3
    const [personalPhoneNumber, setPersonalPhoneNumber] = useState<string>();
    const [personalEmailAddress, setPersonalEmailAddress] = useState<string>();
    const [homeAddress, setHomeAddress] = useState<string>();
    const [homeCity, setHomeCity] = useState<string>();
    const [homeState, setHomeState] = useState<string>();
    const [homeZip, setHomeZip] = useState<string>();
    const [homeCountry, setHomeCountry] = useState<string>();
    const history = useHistory();
    const iconName = 'AddFriend';
    const stepWindowSize = 640;
    const modalTitle = 'Create Personnel Profile';

    const createNewProfileSteps: JSX.Element[] = [
        <ModalCreationStep
            key='1'
            iconName={iconName}
            stepTitle={modalTitle}
            stepWidth={stepWindowSize}>
            <CreateNewProfileStepperPageOne
                firstName={firstName}
                lastName={lastName}
                middleName={middleName}
                suffix={suffix}
                nationalIdNumber={nationalIdNumber}
                onFirstNameChange={(newValue): void => setFirstName(newValue)}
                onLastNameChange={(newValue): void => setLastName(newValue)}
                onMiddleNameChange={(newValue): void => setMiddleName(newValue)}
                onSuffixChange={(newValue): void => setSuffix(newValue)}
                onNationalIdNumberChange={(newValue): void => setNationalIdNumber(newValue)}
            />
        </ModalCreationStep>,
        <ModalCreationStep
            key='2'
            iconName={iconName}
            stepTitle={modalTitle}
            stepWidth={stepWindowSize}>
            <CreateNewProfileStepperPageTwo
                selectedManagerPersona={hiringManagerPersona}
                positionControlNumber={positionControlNumber}
                selectedDate={startDate}
                jobTitle={jobTitle}
                company={company}
                onDateChange={(date): void => setStartDate(date)}
                onSelectedManagerChange={(managerPersona): void => {
                    if (managerPersona && managerPersona.itemProp) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        const manager = JSON.parse(managerPersona?.itemProp!);
                        setHiringManager(manager);
                    } else {
                        setHiringManager(undefined);
                    }
                    setHiringManagerPersona(managerPersona);
                }}
                onPositionControlNumberChange={(pcn): void =>
                    setPositionControlNumber(pcn ? pcn.trim() : '')
                }
                onJobTitleChange={(jobTitle): void => setJobTitle(jobTitle)}
                onCompanyChange={(company): void => setCompany(company)}
            />
            {isValidating && (
                <ProgressIndicator
                    label='Validating...'
                    description='Position Control Number Validation'
                />
            )}
        </ModalCreationStep>,
        <ModalCreationStep
            key='3'
            iconName={iconName}
            stepTitle={modalTitle}
            stepWidth={stepWindowSize}>
            <CreateNewProfileStepperPageThree
                emailAddress={personalEmailAddress}
                homeAddress={homeAddress}
                homeCity={homeCity}
                homeState={homeState}
                homeZip={homeZip}
                homeCountry={homeCountry}
                phoneNumber={personalPhoneNumber}
                onEmailAddressChange={(email): void => setPersonalEmailAddress(email)}
                onHomeAddressChange={(address): void => setHomeAddress(address)}
                onHomeCityChange={(city): void => setHomeCity(city)}
                onHomeStateChange={(state): void => setHomeState(state)}
                onHomeZipChange={(zip): void => setHomeZip(zip)}
                onHomeCountryChange={(country): void => setHomeCountry(country)}
                onPhoneNumberChange={(phone): void => setPersonalPhoneNumber(phone)}
            />
        </ModalCreationStep>,
        <ModalCreationStep key='4' iconName={iconName} stepTitle={modalTitle} stepWidth={960}>
            <CreateNewProfileStepperReview
                newProfile={prehireEditableData ?? {}}
                hiringManager={hiringManager!}
            />
        </ModalCreationStep>,
    ];

    const [isLastStep, setIsLastStep] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [hasMessage, setHasMessage] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [message, setMessage] = useState<any>('');
    const [messageType, setMessageType] = useState<MessageBarType>();

    const stepperProps: StepperProps = {
        isFirstStep: false, // this flag is only used to style the cancel, back, and primary button. we want the non first step behavior.
        isLastStep: isLastStep,
        finishText: 'Create New Profile',
        activeStep: activeStep,
        hasMessage: hasMessage,
        messageType: messageType,
        messageText: message,
        handleResetMessage: () => setHasMessage(false),
        handleCancel: () => {
            props.hideCreateNewProfileProcess();
        },
        renderContent: () => {
            return createNewProfileSteps[activeStep];
        },
        handleBack: () => {
            const previousStepIndex = activeStep - 1;
            if (isLastStep) setIsLastStep(false);
            setActiveStep(previousStepIndex);
            if (hasMessage) {
                setHasMessage(false);
                setMessage('');
                setMessageType(undefined);
            }
        },
        handleNext: async () => {
            const validationResult = await currentStepDataIsValid();
            if (validationResult.valid) {
                setActiveStep(activeStep + 1);
                if (activeStep === createNewProfileSteps.length - 2) {
                    const newProfileData: IEmployeeEditableInfo = {
                        firstName: firstName,
                        middleName: middleName,
                        lastName: lastName,
                        suffix: suffix,
                        nationalIdNumber: nationalIdNumber,
                        positionNumber: positionControlNumber
                            ? parseInt(positionControlNumber!)
                            : undefined,
                        startDate: startDate ? startDate.toDateString() : '',
                        standardTitle: jobTitle,
                        employeeType: company,
                        personalCellPhone: personalPhoneNumber,
                        personalEmail: personalEmailAddress,
                        homeAddress: homeAddress,
                        homeCity: homeCity,
                        homeState: homeState,
                        homeZip: homeZip,
                        homeCountry: homeCountry,
                        reportsToEmailName: hiringManager?.alias
                            ? hiringManager?.alias
                            : hiringManager?.onPremisesSamAccountName,
                    };
                    setPrehireEditableData(newProfileData);
                    setIsLastStep(true);
                }
                if (hasMessage) {
                    setHasMessage(false);
                    setMessage('');
                    setMessageType(undefined);
                }
            } else {
                const message = displayErrorsWithHTML(validationResult.messages!);
                setMessage(message);
                setMessageType(MessageBarType.error);
                setHasMessage(true);
            }
        },
        handleFinish: async () => {
            const request: IEmployeeEditableRequest = {
                employeeStatus: SearchEmployeeStatus.prehire,
                employeeEditableInfo: prehireEditableData!,
                requestBy: userContext.employeeRecord.id,
            };

            try {
                const response = await EmployeeClient.createEditableEmployeeData(
                    authContext,
                    request,
                );

                if (response) {
                    history.push(`/profile/us-gov/${response.id}`);
                } else {
                    props.onCreateNewProfileError(
                        `Error: not able to create prehire record in the database`,
                    );
                }
            } catch (e) {
                console.log(e);
                if (e.status !== 201) {
                    props.onCreateNewProfileError(`Error: ${e.status} - ${e.statusText}`);
                }
            } finally {
                props.hideCreateNewProfileProcess();
            }
        },
    };

    async function currentStepDataIsValid(): Promise<IStepValidationResult> {
        if (activeStep === 0) return await isStep1Valid();
        if (activeStep === 1) return await isStep2Valid();
        if (activeStep === 2) return await isStep3Valid();
        return { valid: false };
    }

    async function isStep1Valid(): Promise<IStepValidationResult> {
        const msgs = [];
        if (firstName === undefined || firstName.trim() === '') {
            msgs.push('The first name is required.');
        }
        if (lastName === undefined || lastName.trim() === '') {
            msgs.push('The last name is required.');
        }
        if (nationalIdNumber === undefined || nationalIdNumber.trim() === '') {
            msgs.push('The SSN is required.');
        }
        if (nationalIdNumber) {
            const ssnInput = nationalIdNumber.trim();
            if (!REGEX_SSN.test(ssnInput)) {
                msgs.push('SSN must be a nine digit number in the format ###-##-####');
            } else {
                setIsValidating(true);
                try {
                    const isSSNAvailable = await EmployeeClient.isSSNAvailable(
                        ssnInput,
                        authContext,
                    );
                    if (!isSSNAvailable) {
                        msgs.push(
                            `Duplicate entry. An employee profile with that SSN already exists.`,
                        );
                    }
                } catch (e) {
                    if (e.status === 404) {
                        msgs.push(`${ssnInput} is not a valid SSN.`);
                    } else {
                        msgs.push('Error validating SSN');
                    }
                } finally {
                    setIsValidating(false);
                }
            }
        }
        return { valid: msgs.length === 0, messages: msgs };
    }

    async function isStep2Valid(): Promise<IStepValidationResult> {
        const msgs = [];

        if (positionControlNumber) {
            setIsValidating(true);
            try {
                await EmployeeClient.getPositionById(authContext, positionControlNumber);
            } catch (e) {
                if (e.status === 404) {
                    msgs.push(`${positionControlNumber} is not a valid PCN.`);
                } else {
                    msgs.push('Error validating PCN');
                }
            } finally {
                setIsValidating(false);
            }
        }
        return { valid: msgs.length === 0, messages: msgs };
    }

    async function isStep3Valid(): Promise<IStepValidationResult> {
        const msgs = [];
        if (personalPhoneNumber) {
            if (personalPhoneNumber.length > PHONE_NUMBER_MAX_LENGTH) {
                msgs.push(
                    `The phone number should be less than ${PHONE_NUMBER_MAX_LENGTH} characters long.`,
                );
            } else if (!REGEX_PHONE.test(personalPhoneNumber)) {
                msgs.push('Invalid phone number.');
            }
        }

        if (personalEmailAddress === undefined || personalEmailAddress.trim() === '') {
            msgs.push('The personal email address is required.');
        }

        if (personalEmailAddress) {
            const emailInput = personalEmailAddress.trim();
            if (!REGEX_EMAIL.test(emailInput)) {
                msgs.push('Invalid email address.');
            } else {
                setIsValidating(true);
                try {
                    const results = await EmployeeClient.searchPrehires(emailInput, authContext);
                    if (results.length > 0) {
                        msgs.push(
                            `Duplicate entry. An employee profile with that personal email address already exists.`,
                        );
                    }
                } catch (e) {
                    if (e.status === 404) {
                        msgs.push(`${emailInput} is not a valid email.`);
                    } else {
                        msgs.push('Error validating email');
                    }
                } finally {
                    setIsValidating(false);
                }
            }
        }

        return { valid: msgs.length === 0, messages: msgs };
    }

    return <Stepper {...stepperProps} />;
}
