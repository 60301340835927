import { IUsGovScreeningToken } from 'clients/screening/us-gov-screening-client';
import { ScreeningRole } from 'configs/roles';
import { IAuthContext } from 'contexts/auth-context';
import { IUserContext, UserContext } from 'contexts/user-context';
import jwtDecode from 'jwt-decode';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import {
    ScreeningPaths,
    UsGovScreeningUserType,
} from 'components/screening/common/common-constants';
import { ContractType } from 'components/screening/us-gov/IContract';

type UseIsUserContractAdminType = {
    isUserContractAdmin: boolean;
    isUserContractAdminDetermined: boolean;
    hasContractAdminRole: boolean;
};

export function useIsUserContractAdmin(authContext: IAuthContext): UseIsUserContractAdminType {
    const [isUserContractAdminDetermined, setIsUserContractAdminDetermined] = useState<boolean>(
        false,
    );
    const [isUserContractAdmin, setIsUserContractAdmin] = useState<boolean>(false);

    useEffect(() => {
        const checkIfContractAdmin = async (): Promise<void> => {
            try {
                const token = await authContext.getToken();
                if (!!token) {
                    const decodedToken: IUsGovScreeningToken | null = jwtDecode(token);
                    setIsUserContractAdmin(
                        !!decodedToken?.roles?.find((role) => role === ScreeningRole.ContractAdmin),
                    );
                }
            } catch (e) {
                console.log('Could not determine contract admin role');
                console.log(e);
            }
            setIsUserContractAdminDetermined(true);
        };
        checkIfContractAdmin();
    }, [authContext]);

    return {
        isUserContractAdmin,
        isUserContractAdminDetermined,
        hasContractAdminRole: isUserContractAdminDetermined && isUserContractAdmin,
    };
}

export const determineContractType = (screeningPath: ScreeningPaths): keyof typeof ContractType => {
    switch (screeningPath) {
        case ScreeningPaths.UsGov:
            return 'USGovScreening';
        case ScreeningPaths.PublicTrust:
            return 'PublicTrust';
        // No need for a default clause because if in the future any
        // type was added to ScreeningPaths and this switch statement
        // isn't updated, prettier will catch it at compile time.
    }
};

export const useCanShowContractAdminPage = (authContext: IAuthContext): boolean => {
    const userContext = useContext(UserContext) as IUserContext | null;

    const screeningContracts = useFeatureFlag(FeatureFlagKeys.screeningContracts);
    const screeningContractOwners = useFeatureFlag(FeatureFlagKeys.screeningContractOwners);

    const { hasContractAdminRole } = useIsUserContractAdmin(authContext);

    const canShow = useMemo(() => {
        return (
            screeningContracts.enabled &&
            // Visible only to contract admins and contract owners
            (hasContractAdminRole ||
                (screeningContractOwners.enabled &&
                    !!userContext?.hasUsGovScreeningUserType(UsGovScreeningUserType.ContractOwner)))
        );
    }, [screeningContracts, screeningContractOwners, hasContractAdminRole, userContext]);

    return canShow;
};
