import React from 'react';
import { useCheckMountedState } from 'utils/misc-hooks';
import {
    Dropdown,
    IDropdownOption,
    IStyleFunctionOrObject,
    IDropdownStyleProps,
    IDropdownStyles,
} from '@fluentui/react';

/**
 * On IDropdownOption, key can be string or number. I tried to
 * support them both but that created a type mismatch and type
 * casting nightmare. I therefore decided to limit type of key
 * to string.
 */
export interface IDropdownOptionStringKey extends IDropdownOption {
    key: string;
}

interface IMultiselectDropdownParams {
    // Ideally, we should keep the following props 100% the same
    // as the underlying Fluent component so that they can directly
    // drive its props. But it became difficult for this hook. So
    // I deviated a little.
    // Example: Type of "options" is IDropdownOptionStringKey[] not IDropdownOption[].
    disabled?: boolean;
    label?: string;
    options: IDropdownOptionStringKey[];
    placeholder?: string;
    required?: boolean;
    styles?: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
}

/**
 *  The function dropdownSetOptions receives a list of keys, a list of options and
 *  looks for each key in the list. It's added so that this hook doesn't blindly accept
 *  a key given to it. Rather, it will look for it in the list of options. If found,
 *  it selects the corresponding option. Otherwise, it ignores the key.
 */

const dropdownSetOptions = (
    selectedKeys: string[] | undefined,
    options: IDropdownOptionStringKey[] | undefined,
): Set<string> => {
    const setOptions = new Set<string>();
    if (!selectedKeys || !options) {
        return setOptions;
    }
    const optionsSet = new Set(options.map((option) => option.key));
    selectedKeys.forEach((key) => {
        if (optionsSet.has(key)) {
            setOptions.add(key);
        }
    });
    return setOptions;
};

interface IMultiselectDropdown {
    selectedKeys: string[] | undefined;
    // Function to initialize the value of the input field.
    // Parent element can call it if needs be.
    initializeFromKeys: (key?: string[]) => void;
    // The following is the input element proper. Use it in render.
    theElement: () => JSX.Element;
}

export function useMultiselectDropDown(params: IMultiselectDropdownParams): IMultiselectDropdown {
    const [selectionSet, setSelectionSet] = useCheckMountedState<Set<string>>(new Set());

    const onSelect = (a: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
        if (!option) {
            return;
        }
        if (a.type === 'change') {
            setSelectionSet(
                (currentValue): Set<string> => {
                    const newValue = new Set(currentValue);
                    // This hook limits type of key to string,
                    // hence the following type casts.
                    if (option.selected) {
                        newValue.add(option?.key as string);
                    } else {
                        newValue.delete(option?.key as string);
                    }
                    return newValue;
                },
            );
        }
    };

    const initializeFromKeys = (selectedKeys: string[] | undefined): void => {
        setSelectionSet(dropdownSetOptions(selectedKeys, params.options));
    };

    const selectedKeys = (): string[] => {
        return Array.from(selectionSet);
    };

    return {
        selectedKeys: selectedKeys(),
        initializeFromKeys,
        theElement: (): JSX.Element => (
            <Dropdown
                required={params.required}
                multiSelect
                label={params.label}
                disabled={params.disabled}
                placeholder={params.placeholder}
                styles={params.styles}
                selectedKeys={selectedKeys()}
                onChange={onSelect}
                options={params.options}
            />
        ),
    };
}
