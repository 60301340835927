import { IconNames } from 'assets/constants/global-constants';
import GroupClient, { GroupRole, IGroupMembership } from 'clients/group-client';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import BoldFont from 'components/common/misc/bold-font';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext } from 'react';
import { doNothing } from 'utils/misc-utils';

interface IDemoteRoleModalActionButtonProps {
    enable: boolean;
    memberDetails: IGroupMembership;
    onRoleDemoted: (personnelId: string) => void;
}

export default function DemoteRoleModalActionButton(props: IDemoteRoleModalActionButtonProps) {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);

    const onHandleDemote = async (): Promise<void> => {
        try {
            if (!!groupContext.group) {
                await GroupClient.updateGroupMember(authContext, {
                    groupId: groupContext.group.id,
                    role: GroupRole.MEMBER,
                    justification: 'Owner/Manager is being moved to Member.',
                    personnelId: props.memberDetails.personnelId,
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    _etag: props.memberDetails._etag,
                });
            }
        } catch (e) {
            console.error('Error moving the individual to a Member role', e);
            throw e;
        } finally {
        }
    };

    const onDemoteMemberConcluded = (conclusion: ModalConclusion): void => {
        if (conclusion === ModalConclusion.Done) {
            props.onRoleDemoted(props.memberDetails.personnelId);
        }
    };

    return (
        <ModalActionButton<void>
            text={'Recategorize'}
            iconName={IconNames.Switch}
            modalTitle={'Recategorize'}
            enable={props.enable}
            enableSubmit={props.enable}
            submitButtonText={'Submit'}
            onSubmit={onHandleDemote}
            onButtonClick={doNothing}
            onModalConcluded={onDemoteMemberConcluded}>
            Are you sure you want to move{' '}
            <BoldFont>
                {props.memberDetails.firstName} {props.memberDetails.lastName}
            </BoldFont>{' '}
            to a member role?
        </ModalActionButton>
    );
}
