import React from 'react';
import { TextField, Stack, Checkbox, mergeStyles } from '@fluentui/react';
import { ScreeningPaths } from 'components/screening/common/common-constants';

export interface CandidateNominationStepBusinessJustificationProps {
    businessJustification?: string;
    employementType: string;
    screeningPath: ScreeningPaths;
    onBusinessJustificationChange: (newValue?: string) => void;
    onConfirmed: (value: boolean) => void;
}

export default function CandidateNominationStepBusinessJustification(
    props: CandidateNominationStepBusinessJustificationProps,
): JSX.Element {
    let confirmationText = '';
    if (props.screeningPath === ScreeningPaths.PublicTrust)
        confirmationText =
            'I confirm that the identified candidate requires this Public Trust adjudication to perform their work duties.';
    if (props.screeningPath === ScreeningPaths.UsGov)
        confirmationText =
            'I confirm that the identified candidate requires a security clearance to perform their work duties.';

    return (
        <Stack tokens={{ childrenGap: 15 }}>
            <TextField
                onChange={onFieldChange}
                label='Business justification'
                placeholder='In 3 sentences explain how exactly this individual will be supporting the customer and why this screening is critical to ensure Microsoft runs on trust - unclear business justifications will be denied.'
                multiline
                required
                rows={4}
                value={props.businessJustification || ''}
            />
            <div className={checkboxSpacer} />
            <Checkbox onChange={onCheckboxChange} label={confirmationText} />
        </Stack>
    );

    function onFieldChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void {
        props.onBusinessJustificationChange(newValue);
    }

    function onCheckboxChange(
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean,
    ): void {
        props.onConfirmed(!!checked);
    }
}

const checkboxSpacer = mergeStyles({ marginTop: 10 });
