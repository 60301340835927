import React, { useContext } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import EligibilityClient, { IAttribute } from 'clients/eligibility-client';
import { readErrorMessageBody } from 'utils/misc-utils';

interface IDeleteAttributeModalProps {
    attribute: IAttribute;
    onDeletetAttributeConcluded: (attribute: IAttribute, modalConclusion: ModalConclusion) => void;
}

export default function DeleteAttributeModalButton(props: IDeleteAttributeModalProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const onDeleteAttributeSubmit = async (): Promise<void> => {
        try {
            await EligibilityClient.deleteAttribute(authContext, props.attribute.id);
        } catch (e) {
            const submitErrorEventText = await readErrorMessageBody(e);
            if (submitErrorEventText) {
                throw submitErrorEventText;
            } else {
                console.error('Error processing - DelAttribute');
                console.error(e);
                // I don't know what the error is.
                // Throw it and let the modal catch it.
                throw e;
            }
        }
    };

    const onDeleteAttributeConcluded = (conclusion: ModalConclusion): void => {
        if (props.onDeletetAttributeConcluded) {
            props.onDeletetAttributeConcluded(props.attribute, conclusion);
        }
    };

    return (
        <ModalActionButton<void>
            text={'Delete'}
            iconName={IconNames.Delete}
            modalTitle={'Delete Attribute'}
            modalTitleIcon={IconNames.Delete}
            enableSubmit={true}
            submitButtonText={'Delete'}
            submitButtonIcon={IconNames.Delete}
            onSubmit={onDeleteAttributeSubmit}
            onModalConcluded={onDeleteAttributeConcluded}>
            <span>
                Are you sure you want to delete attribute{' '}
                <strong>{props.attribute.attributeCode}</strong>?
            </span>
        </ModalActionButton>
    );
}
