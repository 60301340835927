/**
 * File Name: document-common
 * Team: Personnel - Screening Team
 * Summary: common functions,enums,conversion methods for screening documents.
 * Last Updated Summary: 10/7/2022 by andreli
 **/
import DocumentsClient, {
    emptyNoContentIDocumentToken,
    IDocumentRecord,
    IDocumentToken,
    ILock,
} from 'clients/documents-client';
import PublicTrustDocumentClient from 'clients/screening/public-trust-document-client';
import UsGovScreeningClient, {
    ApplicationServices,
} from 'clients/screening/us-gov-screening-client';
import TemplateClient, {
    IDocumentMetadata,
    IScreeningTemplateRequestRecord,
    ScreeningTemplateRequestedState,
} from 'clients/template-client';
import { IAuthContext } from 'contexts/auth-context';
import { ScreeningPaths } from 'components/screening/common/common-constants';

// source of the document: Requested, Uploaded by user, Adhoc
// this is ideally for how we get the data for each 'document' source. There are 3 separate calls so
// this helps manage each source due to each source has different end points and tokens to use
export enum DocumentSourceType {
    Requested,
    UploadedByUser,
    Adhoc, // Added by someone else other than user usually NST Team
}
export enum DocumentTableStatuses {
    Completed = 'Completed',
    WithNominee = 'With Nominee',
    WithNST = 'With NST',
    Uploaded = 'UPLOADED',
}

export function getDocumentMetadataByDocId(
    documentId: string,
    documentMetadata: IDocumentMetadata[] | undefined,
): IDocumentMetadata | undefined {
    return documentMetadata
        ? documentMetadata.filter((x) => x.documentId === documentId).pop()
        : undefined;
}

export interface IUnifiedDocumentRecord {
    documentSourceType: DocumentSourceType;
    id: string;
    documentRequestId?: string; // this is the request Document Id that we use to delete it later
    authorPersonnelId: string;
    createdDate: number;
    lastModifiedDate: number;
    fileName: string;
    documentNotes: string;
    documentTitle: string;
    documentType: string;
    documentTypeName?: string;
    fileMimeType: string;
    lock?: ILock;
    metadata?: any[];
    status?: string;
    expiryTime?: number;
    associatedUserId?: string;
}

export function convertIDocumentRecordToIUnifiedDocumentRecord(
    document: IDocumentRecord,
    requestRecord?: IScreeningTemplateRequestRecord | undefined,
    forcedStatus?: string,
): IUnifiedDocumentRecord {
    let status = '';
    let documentSourceType = DocumentSourceType.UploadedByUser;
    if (forcedStatus) {
        if (forcedStatus === DocumentTableStatuses.Uploaded) {
            documentSourceType = DocumentSourceType.Adhoc;
        }
        status = forcedStatus;
    } else if (document.lock?.isLocked) {
        status = DocumentTableStatuses.Completed;
    } else if (requestRecord && requestRecord.requestedState) {
        switch (requestRecord.requestedState) {
            case ScreeningTemplateRequestedState.RequestedState:
            case ScreeningTemplateRequestedState.ReRequestedState:
                status = DocumentTableStatuses.WithNominee;
                break;
            default:
                status = DocumentTableStatuses.WithNST;
                break;
        }
    } else {
        status = DocumentTableStatuses.WithNST;
    }
    const newUnifiedDocument: IUnifiedDocumentRecord = {
        documentSourceType,
        id: document.id,
        authorPersonnelId: requestRecord?.updatedBy
            ? requestRecord.updatedBy
            : document.authorPersonnelId,
        associatedUserId: document.associatedUserId,
        documentRequestId: requestRecord?.id,
        createdDate: document.createdDate,
        lastModifiedDate: document.lastModifiedDate,
        fileName: document.fileName,
        documentNotes: document.documentNotes,
        documentTitle: document.documentTitle,
        documentType: requestRecord?.documentType ?? document.documentType,
        documentTypeName: requestRecord?.documentTypeName ?? document.documentTypeName,
        fileMimeType: document.fileMimeType,
        expiryTime: document.expiryTime,
        lock: document.lock,
        metadata: document.metadata,
        status: status,
    };
    return newUnifiedDocument;
}

export function convertIScreeningTemplateRequestRecordToIUnifiedDocumentRecord(
    requestDoc: IScreeningTemplateRequestRecord,
    documentMetadata: IDocumentMetadata[] | undefined,
): IUnifiedDocumentRecord | undefined {
    const document = getDocumentMetadataByDocId(requestDoc.documentId, documentMetadata);
    if (document !== undefined) {
        const documentTitle = document?.title ?? '';
        const documentType = document?.documentType ?? requestDoc.documentType ?? '';
        const createdDateTime = Date.parse(requestDoc.createdDateTime ?? '') / 1000;
        const lastModifiedDate = Date.parse(requestDoc.updatedDateTime ?? '') / 1000;
        const newUnifiedDocument: IUnifiedDocumentRecord = {
            documentSourceType: DocumentSourceType.Requested,
            id: requestDoc.documentId || '',
            documentRequestId: requestDoc.id,
            authorPersonnelId: requestDoc.requesterPersonnelId ?? '',
            createdDate: createdDateTime,
            lastModifiedDate: lastModifiedDate,
            fileName: documentTitle,
            documentNotes: requestDoc.message,
            documentTitle: documentTitle,
            documentType: documentType,
            fileMimeType: '',
            status: DocumentTableStatuses.WithNominee,
        };
        return newUnifiedDocument;
    }
    return;
}

export const getAdhocDocumentToken = async (
    authContext: IAuthContext,
    screeningId: string,
): Promise<IDocumentToken> => {
    let associatedUserToken: IDocumentToken;
    try {
        associatedUserToken = await UsGovScreeningClient.getAssociatedDocumentUserToken(
            authContext,
            screeningId ?? '',
            ApplicationServices.screening,
        );
        return associatedUserToken;
    } catch (err) {
        console.error('No Token');
        return emptyNoContentIDocumentToken;
    }
};

export const getAdhocDocument = async (
    authContext: IAuthContext,
    screeningId: string,
    associatedUserTokenStr: string,
): Promise<IDocumentRecord[]> => {
    try {
        let documents: IDocumentRecord[] = await DocumentsClient.getDocumentsByAssociatedUserId(
            authContext,
            screeningId,
            associatedUserTokenStr,
        );
        if (documents !== undefined && documents.filter) {
            documents = documents?.filter((x) => x.status === 'ACTIVE');
        }
        return documents;
    } catch (err) {
        console.error('Failed to get documents', err);
        return [];
    }
};

export const getScreeningDocument = async (
    authContext: IAuthContext,
    screeningId: string,
    screeningPath: ScreeningPaths,
): Promise<IScreeningTemplateRequestRecord[]> => {
    let requestedDocuments: IScreeningTemplateRequestRecord[] = [];
    if (screeningPath === ScreeningPaths.UsGov) {
        requestedDocuments = await TemplateClient.getDocumentRequestedForScreeningId(
            authContext,
            screeningId,
        );
    } else {
        requestedDocuments = await PublicTrustDocumentClient.getDocumentRequestedForScreeningId(
            authContext,
            screeningId,
        );
    }
    return requestedDocuments;
};
