import React, { useState, useEffect, useContext, useMemo } from 'react';

import { AuthContext } from 'contexts/auth-context';
import {
    ActionButton,
    Checkbox,
    Dropdown,
    IDropdownOption,
    Label,
    Separator,
} from '@fluentui/react';
import FacilitiesClient, {
    IFacilityRecord,
    ISeatRecord,
    ProvisionType,
} from 'clients/facilities-client';
import SecureWorkAreaDropdownSelector from 'components/facilities/common/secure-work-area-dropdown-selector';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import { UserContext } from 'contexts/user-context';
import { useIsMounted } from 'utils/misc-hooks';
import SeatsTableHeader from 'components/facilities/facilities-seats/seats-table-header';
import SeatsTable from 'components/facilities/facilities-seats/seats-table';
import { ISeatsColumns } from 'components/facilities/facilities-seats/seats-table-columns';
import { useEmployeePicker } from 'components/common/use-input/use-employee-picker';
import { IconNames } from 'assets/constants/global-constants';
import { globalCheckboxStyles, globalSeparatorStyles } from 'assets/styles/global-styles';

export interface FacilitiesManagementSeatsPageProps {
    facilities: IFacilityRecord[] | undefined;
}

interface IFilterCriteria {
    seatStatus?: IDropdownOption;
    provisionType?: IDropdownOption;
    vacant?: boolean;
}

export default function FacilitiesManagementSeatsPage(
    props: FacilitiesManagementSeatsPageProps,
): JSX.Element {
    const isMounted = useIsMounted();
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [selectedFacilityId, setSelectedFacilityId] = useState<string>();
    const [seatsColumn, setSeatsColumn] = useState<ISeatsColumns[]>();
    const [unfilteredSeats, setUnfilteredSeats] = useState<ISeatsColumns[]>();
    const [filterCriteria, setFilterCriteria] = useState<IFilterCriteria>({});

    const facilities = useMemo(() => props.facilities?.filter((facility) => !!facility?.svgMap), [
        props.facilities,
    ]);

    async function facilitySelectionChange(
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): Promise<void> {
        if (option && option.key) {
            try {
                setSelectedFacilityId(option.key.toString());

                const seatsVar = await getSeatsColumns(option.key.toString());

                if (isMounted()) {
                    setSeatsColumn(seatsVar);
                    setUnfilteredSeats(seatsVar);
                }
            } catch (error) {
                console.log('Failed to retrieve seats for facility');
            }
        }
    }

    async function checkLoadedFacilities(): Promise<void> {
        if (facilities) {
            try {
                const [firstFacilityInList] = facilities;
                setSelectedFacilityId(firstFacilityInList.id);

                const seatsVar = await getSeatsColumns(firstFacilityInList.id);

                if (isMounted()) {
                    setSeatsColumn(seatsVar);
                    setUnfilteredSeats(seatsVar);
                }
            } catch (error) {
                console.log('Failed to retrieve seats for facility');
            }
        }
    }

    function updateSeatOnDelete(seatDeleted: ISeatRecord): void {
        setSeatsColumn((prevSeats) => {
            const newSeat = prevSeats?.filter((x) => x.seatRecord.id !== seatDeleted.id);
            return newSeat;
        });
    }

    async function getSeatsColumns(facilityId: string): Promise<ISeatsColumns[]> {
        const newSelectedFacilitySeats: ISeatsColumns[] = [];

        let continuationToken = '';
        do {
            const seatsPagedResults = await FacilitiesClient.getSeatsByFacilityId(
                authContext,
                userContext,
                facilityId,
                continuationToken,
            );

            seatsPagedResults.results.forEach((x) => {
                newSelectedFacilitySeats.push({
                    seatRecord: x,
                });
            });
            continuationToken = seatsPagedResults.continuationToken ?? '';
        } while (continuationToken);

        return newSelectedFacilitySeats;
    }

    useEffect(() => {
        checkLoadedFacilities();
    }, [facilities]);

    function updateSeat(seatsVar: ISeatRecord[]): void {
        if (!seatsVar || seatsVar.length === 0) {
            return;
        }

        setSeatsColumn((prevSeats) => {
            const seatIds = new Set(seatsVar.map((x) => x.id));
            const newSeats = prevSeats?.filter((x) => !seatIds.has(x.seatRecord?.id));
            seatsVar.forEach((x) =>
                newSeats?.push({
                    seatRecord: x,
                }),
            );
            newSeats?.sort(
                (a, b) => b?.seatRecord.lastModified.atUtc - a.seatRecord.lastModified.atUtc,
            );
            return newSeats;
        });
    }

    function applyFilterCriteria(criteria: IFilterCriteria): void {
        let filtered = unfilteredSeats;

        if (criteria.vacant) {
            filtered = filtered?.filter((x) => !x.seatRecord.occupiedBy?.by);
        }

        if (criteria.provisionType && criteria.provisionType.key !== '') {
            filtered = filtered?.filter(
                (x) => x.seatRecord.provisionInfo.provisionType === criteria.provisionType?.key,
            );
        }

        if (criteria.seatStatus && criteria.seatStatus.key !== '') {
            const isOutOfOrder = criteria.seatStatus?.text === 'Yes' ? true : false;
            filtered = filtered?.filter((x) => x.seatRecord.isOutOfOrder === isOutOfOrder);
        }

        if (employee?.id) {
            filtered = filtered?.filter((x) => x.seatRecord.occupiedBy?.by === employee.id);
        }

        if (isMounted()) {
            setSeatsColumn(filtered);
        }
    }

    const provisionTypes: IDropdownOption[] = [
        { key: ProvisionType.General, text: 'General' },
        { key: ProvisionType.Admin, text: 'Admin' },
        { key: ProvisionType.LiveSite, text: 'Live Site' },
        { key: ProvisionType.Hierarchy, text: 'Hierarchy' },
    ];

    const isOutOfOrderStatuses: IDropdownOption[] = [
        { key: 'false', text: 'No' },
        { key: 'true', text: 'Yes' },
    ];

    const {
        value: employee,
        theElement: employeeElement,
        initialize: initEmployee,
    } = useEmployeePicker({
        required: false,
        disabled: false,
        label: 'Occupied By',
    });

    function seatStatusChange(
        event: React.FormEvent<HTMLDivElement>,
        selected?: IDropdownOption,
    ): void {
        setFilterCriteria((prev) => {
            return { ...prev, seatStatus: selected };
        });
    }

    function provisionTypeChange(
        event: React.FormEvent<HTMLDivElement>,
        selected?: IDropdownOption,
    ): void {
        setFilterCriteria((prev) => {
            return { ...prev, provisionType: selected };
        });
    }

    async function vacantCheckBoxChange(
        event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
        checked?: boolean | undefined,
    ): Promise<void> {
        if (checked) {
            employee === undefined;
        }
        setFilterCriteria((prev) => {
            return { ...prev, vacant: checked };
        });
    }

    useEffect(() => {
        applyFilterCriteria(filterCriteria);
    }, [employee, filterCriteria]);

    function clearFilters(): void {
        if (isMounted()) {
            setFilterCriteria({});
            initEmployee();
            setSeatsColumn(unfilteredSeats);
        }
    }

    return (
        <SidebarAndContents>
            <SidebarPane>
                <SecureWorkAreaDropdownSelector
                    onChange={facilitySelectionChange}
                    placeholder='Select a facility'
                    selectedKey={selectedFacilityId}
                    options={facilities?.map((f) => {
                        return {
                            key: f.id,
                            text: f.facilityName,
                        };
                    })}
                />
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    <Label>Seat Status</Label>
                </Separator>
                <Dropdown
                    options={isOutOfOrderStatuses}
                    selectedKey={filterCriteria.seatStatus?.key ?? ''}
                    onChange={seatStatusChange}
                    ariaLabel='Seat Status'
                    placeholder='Is Out of Order'></Dropdown>
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    <Label>Provision Type</Label>
                </Separator>
                <Dropdown
                    options={provisionTypes}
                    selectedKey={filterCriteria.provisionType?.key ?? ''}
                    onChange={provisionTypeChange}
                    ariaLabel='Provision Type'
                    placeholder=''></Dropdown>
                {employeeElement()}
                <Checkbox
                    label='VACANT?'
                    styles={globalCheckboxStyles}
                    onChange={vacantCheckBoxChange}
                    checked={filterCriteria.vacant ?? false}
                    ariaLabel='Select to search for Vacant seats'></Checkbox>
                <ActionButton
                    iconProps={{ iconName: IconNames.ClearFilter }}
                    onClick={clearFilters}
                    ariaLabel='Clear filters'>
                    <span>Clear filters</span>
                </ActionButton>
            </SidebarPane>
            <ContentPane>
                <SeatsTableHeader
                    facility={facilities?.find((x) => x.facilityId === selectedFacilityId)}
                    seatsColumns={seatsColumn}
                    updateSeat={updateSeat}
                />
                <SeatsTable
                    seats={seatsColumn}
                    facility={facilities?.find((x) => x.facilityId === selectedFacilityId)}
                    updateSeat={updateSeatOnDelete}
                    addEditSeat={updateSeat}
                    setSeatsColumns={setSeatsColumn}
                />
            </ContentPane>
        </SidebarAndContents>
    );
}
