/**
 * This component renders filter items and manages filter settings.
 * It uses useCheckFilterDefinitions hook to check integrity of filter definitions,
 * elements of search-filter-library.tsx to render filter items, the hook
 * useGetUrlParams to get URL parameters and set filter settings accordingly,
 * and the hook useFilterSettings to maintain and manage filter settings.
 * It receives definition of filter items on its props.
 * To use it, instantiate it inside your component to display the filter items.
 */

import { mergeStyles, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import {
    filterItemLibraryComponentsDict,
    FilterItemTypeEnum,
    IFilterItem,
} from 'components/common/search-filter/search-filter-library';
import { useCheckFilterDefinitions } from 'components/common/search-filter/use-check-filter-definitions';
import useFilterSettings, {
    FilterSettingsHookType,
    FilterSettingsRecordType,
} from 'components/common/search-filter/use-filter-settings';
import Spacer from 'components/common/spacer';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import { useLocation } from 'react-router-dom';
import { useGetUrlParams } from 'components/common/search-filter/use-get-url-params';
import MakeAndCopyUrlButton from 'components/common/search-filter/make-and-copy-url-button';
import ApplyFiltersActionButton from 'components/common/buttons/apply-filters-action-button';
import LoadAllActionButton from 'components/common/buttons/load-all-action-button';

export interface IBaseSummaryDataResponse {
    filterDefinitions: IFilterItem[];
}

interface ISearchFilterContainerProps<T extends IBaseSummaryDataResponse> {
    cacheStorage?: Storage;
    cacheKey?: string;
    filterDefinitionsFetchFunction: () => Promise<T>;
    onFilterDefinitionsChecked: (isOk: boolean) => void;
    onFilterSettingsChanged: (settingsRecord: FilterSettingsRecordType) => void;
    onApplyFiltersClick: () => void;
    onLoadAllClick: () => void;
    onMakeAndCopyUrlClick: () => void;
    onSummaryDataReady: (summaryData: T) => void;
    // The function props.onClearAll Will be called if user clicks on the "Clear" button,
    // and after the filters have been cleared.
    onSettingsCleared: () => void;
    shouldRefetchFilterDefinitions: boolean;
}

export default function SearchFilterContainer<T extends IBaseSummaryDataResponse>(
    props: ISearchFilterContainerProps<T>,
): JSX.Element {
    const [filterDefinitions, setFilterDefinitions] = useState<IFilterItem[]>([]);
    const [isFilterDefinitionsReady, setIsFilterDefinitionsReady] = useState<boolean>(false);
    const [errorGettingFilterDefinitions, setErrorGettingFilterDefinitions] = useState<string>('');
    // TODO - Remove if never used
    const [isFetchingFilterDefinitions, setIsFetchingFilterDefinitions] = useState<boolean>(false);

    const fetchFilterDefinitions = useCallback(async (): Promise<void> => {
        try {
            setIsFetchingFilterDefinitions(true);
            const summaryData = await props.filterDefinitionsFetchFunction();
            props.onSummaryDataReady({
                // Parent doesn't need filter definitions, but backend may have sent other information for it.
                // Strip off the filter definitions and send the rest.
                // Example: Screening service sends back total record count in the summary data.
                //          See the type IScreeningSummaryDataResponse.
                ...summaryData,
                filterDefinitions: undefined,
            });
            setFilterDefinitions(summaryData.filterDefinitions);
            setIsFilterDefinitionsReady(true);
        } catch (e) {
            console.error('Error getting filter definitions', e);
            setErrorGettingFilterDefinitions('Error getting filter definitions.');
        } finally {
            setIsFetchingFilterDefinitions(false);
        }
    }, []);

    useEffect(() => {
        fetchFilterDefinitions();
    }, []);

    useEffect(() => {
        if (props.shouldRefetchFilterDefinitions) {
            fetchFilterDefinitions();
        }
    }, [props.shouldRefetchFilterDefinitions]);

    // Check integrity of filter data and inform the parent component
    const {
        isFilterDefinitionsChecked,
        isFilterDefinitionsOk,
        filterDefinitionError,
        flattenedFilterItems,
        propertyNameToFilterItemDict,
    } = useCheckFilterDefinitions(isFilterDefinitionsReady, filterDefinitions);

    useEffect(() => {
        props.onFilterDefinitionsChecked(isFilterDefinitionsOk);
    }, [props, isFilterDefinitionsOk]);

    const location = useLocation();

    const { urlParamsKeyValuesPairs } = useGetUrlParams({
        locationPath: location,
        flattenedFilterItems,
        isFilterDefinitionsOk,
        propertyNameToFilterItemDict,
    });

    const filterSettings = useFilterSettings({
        cacheStorage: props.cacheStorage,
        cacheKey: props.cacheKey,
        filterItems: filterDefinitions,
        isFilterDefinitionsReady: isFilterDefinitionsReady,
        isFilterDefinitionsOk,
        flattenedFilterItems,
        propertyNameToFilterItemDict,
        urlParamsKeyValuesPairs: urlParamsKeyValuesPairs,
        onFilterSettingsChanged: props.onFilterSettingsChanged,
        onSettingsCleared: props.onSettingsCleared,
    });

    if (!isFilterDefinitionsReady) {
        return <></>;
    }

    if (!!errorGettingFilterDefinitions) {
        <MessageBar messageBarType={MessageBarType.error}>
            Error getting filter definitions
        </MessageBar>;
    }

    if (!isFilterDefinitionsChecked) {
        return <></>;
    }

    if (!!filterDefinitionError) {
        // Tsk tsk tsk
        return <>{filterDefinitionError}</>;
    }
    const renderTheButtons = (): JSX.Element => {
        return (
            <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
                <ClearFiltersActionButton clearFunc={filterSettings.onClearAll} text='Clear' />
                <MakeAndCopyUrlButton onClick={props.onMakeAndCopyUrlClick} text='Copy' />
                <ApplyFiltersActionButton onClick={props.onApplyFiltersClick} text='Apply' />
                <LoadAllActionButton onClick={props.onLoadAllClick} text='Load' />
            </Stack>
        );
    };
    return (
        <>
            {renderTheButtons()}
            {renderFilterItems(filterDefinitions, filterSettings)}
            {renderTheButtons()}
        </>
    );
}

function renderFilterItems(
    filterItems: IFilterItem[],
    filterSettings: FilterSettingsHookType,
    indentLevel = 0,
): JSX.Element {
    return (
        <div
            key={`filter-container-${indentLevel}`}
            className={mergeStyles({ marginLeft: indentLevel })}>
            <div>
                {filterItems.map((filterItem) => {
                    const {
                        libraryComponent,
                        marginTop,
                        marginBottom,
                        propsFunc,
                        keyFunc,
                    } = filterItemLibraryComponentsDict[filterItem.type];

                    return (
                        <div key={`container-${indentLevel}-${keyFunc(filterItem)}`}>
                            <Spacer marginTop={marginTop ?? 0} />
                            {React.createElement(libraryComponent, {
                                ...propsFunc(filterItem, filterSettings),
                                key: keyFunc(filterItem),
                            })}
                            <Spacer marginTop={marginBottom ?? 0} />
                            {
                                // Subfilter items are currently only supported for checkboxes
                                filterItem.type === FilterItemTypeEnum.Checkbox &&
                                    filterItem.subFilterItems &&
                                    filterSettings.getCheckboxSetting(
                                        filterItem.name,
                                        filterItem.checkboxOptions?.checked,
                                    ) &&
                                    renderFilterItems(
                                        // Currently only checkboxes are supported as subfilter items
                                        filterItem.subFilterItems.filter(
                                            (subfilterItem) =>
                                                subfilterItem.type === FilterItemTypeEnum.Checkbox,
                                        ),
                                        filterSettings,
                                        indentLevel + 29,
                                    )
                            }
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
