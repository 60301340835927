import React, { useRef, useState } from 'react';
import { getTheme, mergeStyles } from '@fluentui/react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { IStep, StepTime } from 'types/screening-step';
import {
    HorizontalNotches,
    getNotchLeftXCoordinate,
} from 'components/screening/us-gov/candidates/nomination/screening-status-horizontal-notches';

export interface StepIndicatorProps {
    steps: IStep[];
    onClick: () => void;
    width?: number;
    circleRadius?: number;
    circleColor?: string;
}

export function StepIndicator(props: StepIndicatorProps) {
    const timeoutRef = useRef<any>(null);
    const { steps } = props;
    const defaultTooltip = steps.find((s) => s.time === StepTime.CURRENT)!.label;
    const [visibleTooltip, setVisibleTooltip] = useState<string>(defaultTooltip);
    const notchesWidth = props.width || 380;
    const notchCircleRadius = props.circleRadius || 10;
    const notchColor = props.circleColor || getTheme().palette.themePrimary;

    return (
        <div
            onMouseEnter={() => clearTimeout(timeoutRef.current)}
            onMouseLeave={() =>
                (timeoutRef.current = setTimeout(() => setVisibleTooltip(defaultTooltip), 800))
            }
            onClick={props.onClick}
            style={{ marginTop: 10 }}>
            <div style={{ position: 'relative' }}>
                {steps.map((step, index) => {
                    const finalStyle = mergeStyles(transparentDivStyle, {
                        height: notchCircleRadius * 2,
                        width: notchCircleRadius * 2,
                        left: getNotchLeftXCoordinate(
                            notchesWidth,
                            notchCircleRadius,
                            index,
                            steps,
                        ),
                    });

                    return (
                        <Tippy
                            key={index}
                            content={step.label}
                            delay={[0, 800]}
                            visible={visibleTooltip === step.label}>
                            <div
                                onMouseEnter={() => setVisibleTooltip(step.label)}
                                className={finalStyle}
                            />
                        </Tippy>
                    );
                })}
            </div>
            <HorizontalNotches
                steps={steps}
                width={notchesWidth}
                radius={notchCircleRadius}
                color={notchColor}
            />
        </div>
    );
}

const transparentDivStyle = mergeStyles({
    background: 'transparent',
    position: 'absolute',
});
