import React, { useContext, useState } from 'react';
import config from 'environments/environment';
import HorizontalBar from 'components/common/horizontal-bar';
import { ActionButton, MessageBar, MessageBarType, Stack, TooltipHost } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { TimeFormats } from 'utils/time-utils';
import FacilitiesClient, {
    IEquipmentRecord,
    IFacilityRecord,
    emptyEquipmentRecord,
} from 'clients/facilities-client';
import { AuthContext } from 'contexts/auth-context';
import { FilterSettingsRecordType } from 'components/common/search-filter/use-filter-settings';
import { UserContext } from 'contexts/user-context';
import { replaceTemplatedTimeStringInDownloadReport } from 'utils/reporting-utils';
import { replaceDownloadReportTemplatedClearanceText } from 'components/personnel-profile/clearance/profile-clearance-constants';

export interface EquipmentTableHeaderProps {
    facility?: IFacilityRecord[];
    equipment?: IEquipmentRecord[];
    filterSettingsRecord?: FilterSettingsRecordType;
    updateEquipment?: (equipment: IEquipmentRecord[]) => void;
    setShouldRefresh: (shouldRefresh: boolean) => void;
    setSelectedRecord: (equipmentRecord: IEquipmentRecord) => void;
    setShowAddEditModal: (showAddEditModal: boolean) => void;
    setShowUploadModal: (showUploadModal: boolean) => void;
}

export default function EquipmentTableHeader(props: EquipmentTableHeaderProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState<string>();

    async function downloadEquipmentReport(): Promise<string> {
        let data = '';
        try {
            let urlParams = new URLSearchParams();

            if (props.filterSettingsRecord) {
                urlParams = props.filterSettingsRecord.urlParams;
            }

            const downloadEquipmentReport = await FacilitiesClient.downloadEquipmentReport(
                authContext,
                userContext,
                urlParams,
            );

            data = replaceTemplatedTimeStringInDownloadReport(downloadEquipmentReport);

            // If logic continues to be add to the report using this approach may need to migrate to report utils.
            const clearanceRegEx = /_ClearanceLevel\(.*?\)_ClearanceAgency\(.*?\)_ClearanceContractId\(.*?\)_/g;
            data = data.replaceAll(clearanceRegEx, replaceDownloadReportTemplatedClearanceText);
        } catch (e) {
            console.error('Failed to generate and return US Gov Screenings Report:', e);
            setErrorMessage('Unable to download US Gov Screenings Report.');
        }
        return data;
    }

    function resetErrorMessage(): void {
        setErrorMessage(undefined);
    }

    return (
        <>
            <HorizontalBar>
                <Stack.Item grow={100}>
                    <Stack horizontal verticalAlign='center'>
                        <TooltipHost content={'Add equipment to a user'}>
                            <ActionButton
                                iconProps={{ iconName: IconNames.Add }}
                                onClick={(): void => {
                                    props.setSelectedRecord(emptyEquipmentRecord);
                                    props.setShowAddEditModal(true);
                                }}>
                                Add
                            </ActionButton>
                        </TooltipHost>
                        <span style={{ paddingRight: '10px' }} />
                        <TooltipHost content={'Upload equipment CSV, max 1 file with 500 rows'}>
                            <ActionButton
                                iconProps={{ iconName: IconNames.BulkUpload }}
                                onClick={(): void => {
                                    props.setShowUploadModal(true);
                                }}>
                                Upload
                            </ActionButton>
                        </TooltipHost>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal horizontalAlign='center' verticalAlign='center'>
                        {config.facilitiesServiceConfig.management.extras.enabled && (
                            <Stack.Item>
                                <TooltipHost
                                    content={
                                        'Download equipment CSV, with search and filter settings'
                                    }>
                                    <ExportToExcelButton<string>
                                        getData={async (): Promise<string> =>
                                            downloadEquipmentReport()
                                        }
                                        buttonTitle='Download report'
                                        fileNamePrefix={`facility-equipment`}
                                        fileNameTimeFormat={TimeFormats.MM_DD_YYYY_HHmm}
                                        formatHeader={false}
                                        disabled={!props.facility || !props.equipment}
                                    />
                                </TooltipHost>{' '}
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
            </HorizontalBar>
            {errorMessage && (
                <MessageBar
                    styles={{
                        root: { marginTop: '5px' },
                    }}
                    onDismiss={resetErrorMessage}
                    messageBarType={MessageBarType.error}
                    dismissButtonAriaLabel='Close'>
                    {errorMessage}
                </MessageBar>
            )}
        </>
    );
}
