import React, { useRef, useState, useContext, useEffect } from 'react';
import { AccessDeniedURL, Dictionary, IconNames } from 'assets/constants/global-constants';
import SidebarAndContents, {
    SidebarPane,
    ContentPane,
} from 'components/common/sidebar-and-contents';
import CheckRole from 'components/common/check-role';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { Role } from 'configs/roles';
import { visitorsProfileBreadcrumbs, visitorProfileUrl } from 'components/visitors/breadcrumbs';
import Spacer from 'components/common/spacer';
import { IVisitorProfile } from 'clients/visitor-client';
import { useIsMounted } from 'utils/misc-hooks';
import {
    TableColumnsVisitors,
    VisitorsColumnNames,
} from 'components/visitors/table-columns-visitors';
import { useSortColumnHandler, strCmp } from 'utils/sort-utils';
import { Table } from 'components/common/table';
import {
    CheckScrollReachedBottom,
    doesElementBottomReachScreenBottom,
} from 'components/common/scroll-event-listener';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import {
    DefaultButton,
    Dialog,
    DialogType,
    DialogFooter,
    Stack,
    ActionButton,
    PrimaryButton,
} from '@fluentui/react';
import VisitorsFilters from 'components/visitors/visitors-filters';
import HorizontalBar from 'components/common/horizontal-bar';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import { VisitorsFilterContext } from 'components/visitors/visitors-filter-context';
import {
    VisitorsDataContext,
    VisitorLoadWarningMsgBar,
    VisitorLoadErrorMsgBar,
} from 'components/visitors/visitors-data-context';
import ButtonBar from 'components/common/button-bar';
import VisitorProfileModalActionButton, {
    VisitorProfileModeEnum,
} from 'components/visitors/visitor-profile-modal-action-button';
import { dialogStyles } from 'assets/styles/global-styles';
import { Redirect } from 'react-router';

export default function Visitors(): JSX.Element {
    const filterContext = useContext(VisitorsFilterContext);
    const dataContext = useContext(VisitorsDataContext);
    const authContext = useContext(AuthContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const pageRef = useRef(null!);

    const [theAddedVisitor, setTheAddedVisitor] = useState<IVisitorProfile>();
    const [shouldShowDialog, setShouldShowDialog] = useState<boolean>();

    const [redirecTo, setRedirectTo] = useState<string>();

    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler(
        VisitorsColumnNames.firstName,
        1,
    );

    const isLoadingMore = (): boolean => !!dataContext.isLoading && !!dataContext.continuationToken;

    const isMounted = useIsMounted();

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([...visitorsProfileBreadcrumbs()]);
    }, []);

    useEffect(() => {
        dataContext.fetchVisitors(isMounted);
    }, []);

    const sortVisitors = (visitors: IVisitorProfile[]): IVisitorProfile[] => {
        type A = IVisitorProfile;

        const chooseSortCmp = (sortColumn: string): ((r1: A, r2: A) => number) => {
            const sortColumnToField: Dictionary<keyof IVisitorProfile> = {
                [VisitorsColumnNames.visitorId]: 'id',
                [VisitorsColumnNames.firstName]: 'firstName',
                [VisitorsColumnNames.middleName]: 'middleName',
                [VisitorsColumnNames.lastName]: 'lastName',
                [VisitorsColumnNames.email]: 'email',
                [VisitorsColumnNames.company]: 'company',
                [VisitorsColumnNames.title]: 'title',
                [VisitorsColumnNames.sponsor]: 'sponsor',
            };

            const fieldName = sortColumnToField[sortColumn];

            if (fieldName) {
                return (r1: A, r2: A): number => {
                    // The following type casts are safe because all the fields currently used in
                    // sort are strings. On IVisitorProfile, the field lastModifiedAtUTC is a
                    // number. If that field was added to the sort fields, the following code
                    // needs to update accordingly.
                    return (
                        sortAscending *
                        strCmp(
                            ((r1[fieldName] as string) ?? '').toLowerCase(),
                            ((r2[fieldName] as string) ?? '').toLowerCase(),
                        )
                    );
                };
            }
            // Sort column not recognized. No sorting performed.
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            return (r1: A, r2: A): number => 0;
        };

        const sortCmp = chooseSortCmp(sortColumn);
        return [...visitors].sort(sortCmp);
    };

    function onScrollReachedBottom(): void {
        if (dataContext.continuationToken && !isLoadingMore()) {
            dataContext.fetchVisitors(isMounted);
        }
    }

    useEffect(() => {
        const { continuationToken, isLoading } = dataContext;
        if (!!continuationToken && !isLoading && !doesElementBottomReachScreenBottom(pageRef)) {
            dataContext.fetchVisitors(isMounted);
        }
    }, [dataContext.continuationToken, dataContext.isLoading]);

    const onSearchClick = (): void => {
        dataContext.fetchVisitors(isMounted, { replaceTable: true });
    };

    const onResetTableClick = (): void => {
        filterContext.resetFilter();
        dataContext.fetchVisitors(isMounted, { replaceTable: true, withClearFilters: true });
    };

    const onVisitorAdded = (visitor: IVisitorProfile): void => {
        if (isMounted()) {
            setTheAddedVisitor(visitor);
            dataContext.setTheAddedVisitorsSet((current) => current.add(visitor.id));
            dataContext.setVisitors((currentValue) => [...currentValue].concat([visitor]));
            setShouldShowDialog(true);
        }
    };

    const onUpdateVisitorRecord = (updatedVisitor: IVisitorProfile): void => {
        const tableIx = dataContext.visitors.findIndex((row) => row.id === updatedVisitor.id);
        if (tableIx === -1) {
            return;
        }
        if (isMounted()) {
            dataContext.setVisitors((currentValue) => {
                const newArray = [...currentValue];
                newArray.splice(tableIx, 1, updatedVisitor);
                return newArray;
            });
        }
    };

    const tableColumns = TableColumnsVisitors({
        sortColumn,
        sortAscending: sortAscending === 1,
        canModifyVisitorProfile: authContext.isInRole(Role.VisitorRecordModify),
        onUpdateVisitorRecord,
        sortColumnHandler,
    });

    if (redirecTo) {
        return <Redirect push to={redirecTo} />;
    } else {
        return (
            <CheckRole
                ref={pageRef}
                requiredRolesAny={[Role.VisitorRecordRead]}
                redirectNotInRole={AccessDeniedURL}>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                <div>
                    <CheckRole requiredRolesAny={[Role.VisitorRecordWrite]}>
                        <ButtonBar>
                            <VisitorProfileModalActionButton
                                mode={VisitorProfileModeEnum.add}
                                onVisitorAdded={onVisitorAdded}
                            />
                        </ButtonBar>
                    </CheckRole>
                    <SidebarAndContents>
                        <SidebarPane>
                            <VisitorsFilters
                                onSearchClick={onSearchClick}
                                onResetTableClick={onResetTableClick}
                            />
                        </SidebarPane>
                        <ContentPane>
                            <VisitorLoadWarningMsgBar />
                            <VisitorLoadErrorMsgBar />
                            <HorizontalBar>
                                <ClearFiltersActionButton
                                    clearFunc={onResetTableClick}
                                    text='Reset Table'
                                />
                            </HorizontalBar>
                            <Table
                                rows={sortVisitors(dataContext.visitors)}
                                tableColumns={tableColumns}
                                isFetchingData={
                                    dataContext.visitors.length === 0 && !!dataContext.isLoading
                                }
                                tableName='Visitors'
                            />
                            <IsLoadingIndicator
                                msg='Loading more records...'
                                before={<Spacer marginTop={10} />}
                                after={<Spacer marginTop={10} />}
                                isLoading={!!dataContext.continuationToken && !!isLoadingMore()}
                            />
                            <CheckScrollReachedBottom
                                shouldCheck={true}
                                onScrollReachedBottom={onScrollReachedBottom}
                            />
                        </ContentPane>
                    </SidebarAndContents>
                </div>
                <Dialog
                    hidden={!shouldShowDialog}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        title: (
                            <div className={dialogStyles.title}>
                                <span>Visitor successfully created</span>
                            </div>
                        ),
                        closeButtonAriaLabel: 'Close',
                        styles: { inner: dialogStyles.contentInner },
                    }}>
                    <div>You may copy the visitor Id:</div>
                    <Stack horizontal horizontalAlign='center' verticalAlign='start'>
                        <Stack.Item>
                            <strong>{theAddedVisitor?.id}</strong>
                            <ActionButton
                                iconProps={{ iconName: IconNames.Link }}
                                onClick={(): void => {
                                    navigator.clipboard.writeText(`${theAddedVisitor?.id}`);
                                }}
                            />
                        </Stack.Item>
                    </Stack>
                    <DialogFooter>
                        <DefaultButton
                            // The follwoing type cast is safe otherwise this dialog box would not appear
                            onClick={() =>
                                setRedirectTo(visitorProfileUrl(theAddedVisitor?.id as string))
                            }
                            text={'Go to profile'}
                        />
                        <PrimaryButton onClick={() => setShouldShowDialog(false)} text='Close' />
                    </DialogFooter>
                </Dialog>
            </CheckRole>
        );
    }
}
