import { MultiChoiceFilter } from 'components/common/filters';
import { IFiltersContext } from 'contexts/filters-context';
import { Separator, Stack } from '@fluentui/react';
import React from 'react';
import { IMultiChoiceFilterItem } from 'types/multi-choice-filter-Item';
import {
    requestTypeStr,
    ScreeningPaths,
    ScreeningRequestTypes,
    ScreeningRequestTypesLabels,
} from 'components/screening/common/common-constants';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import {
    separatorStyles,
    filterMargin,
} from 'components/screening/common/filters/common-filter-styling';
import {
    IsCheckboxChecked,
    labelFunction,
    updateFilterContext,
} from 'components/screening/common/filters/filter-help';
import { removeWhiteSpaceFromString } from 'utils/string-utils';

export interface RequestTypeFilterProps {
    rawCandidates: ICommonScreening[];
    filterContext: IFiltersContext;
    showCounts: boolean;
    screeningPath: ScreeningPaths;
}

export function RequestTypeFilter(props: RequestTypeFilterProps): JSX.Element {
    const requestTypeOptions: IMultiChoiceFilterItem[] = [
        {
            label: ScreeningRequestTypesLabels.Initial,
            isChecked: IsCheckboxChecked(
                props.filterContext,
                requestTypeStr,
                ScreeningRequestTypesLabels.Initial,
            ),
            filterFunction(data: ICommonScreening): boolean {
                if (data.requestType) {
                    return (
                        ScreeningRequestTypesLabels.Initial.toLowerCase() ===
                        data.requestType.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: ScreeningRequestTypesLabels.Crossover,
            isChecked: IsCheckboxChecked(
                props.filterContext,
                requestTypeStr,
                ScreeningRequestTypesLabels.Crossover,
            ),
            filterFunction(data: ICommonScreening): boolean {
                if (data.requestType) {
                    return (
                        ScreeningRequestTypesLabels.Crossover.toLowerCase() ===
                        data.requestType.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: ScreeningRequestTypesLabels.PeriodicReinvestigation,
            isChecked: IsCheckboxChecked(
                props.filterContext,
                requestTypeStr,
                ScreeningRequestTypesLabels.PeriodicReinvestigation,
            ),
            filterFunction(data: ICommonScreening): boolean {
                if (data.requestType) {
                    // TODO: Remove Periodic Reinvestigation variant check once Request Type data has been standardized.
                    const trimmedPRstring = removeWhiteSpaceFromString(
                        ScreeningRequestTypesLabels.PeriodicReinvestigation.toLowerCase(),
                    );

                    return (
                        ScreeningRequestTypesLabels.PeriodicReinvestigation.toLowerCase() ===
                            data.requestType.toLowerCase() ||
                        trimmedPRstring === data.requestType.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
            // isHidden: No need to hide Periodic Reinvestigation from filters.
            // This is in order to be able to filter based on this request type because some older record may have this
            // request type.
        },
        {
            label: ScreeningRequestTypesLabels.ContinuousEvaluationContinuousVetting,
            isChecked: IsCheckboxChecked(
                props.filterContext,
                requestTypeStr,
                ScreeningRequestTypesLabels.ContinuousEvaluationContinuousVetting,
            ),
            filterFunction(data: ICommonScreening): boolean {
                if (data.requestType) {
                    return (
                        ScreeningRequestTypes.ContinuousEvaluationContinuousVetting.toLowerCase() ===
                        data.requestType.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: ScreeningRequestTypesLabels.Reinstatement,
            isChecked: IsCheckboxChecked(
                props.filterContext,
                requestTypeStr,
                ScreeningRequestTypesLabels.Reinstatement,
            ),
            filterFunction(data: ICommonScreening): boolean {
                if (data.requestType) {
                    return (
                        ScreeningRequestTypesLabels.Reinstatement.toLowerCase() ===
                        data.requestType.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: ScreeningRequestTypesLabels.ContractChangeOrAdd,
            isChecked: IsCheckboxChecked(
                props.filterContext,
                requestTypeStr,
                ScreeningRequestTypesLabels.ContractChangeOrAdd,
            ),
            filterFunction(data: ICommonScreening): boolean {
                if (data.requestType) {
                    return (
                        ScreeningRequestTypes.ContractChangeOrAdd.toLowerCase() ===
                        data.requestType.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
        {
            label: ScreeningRequestTypesLabels.RequestForAction,
            isChecked: IsCheckboxChecked(
                props.filterContext,
                requestTypeStr,
                ScreeningRequestTypesLabels.RequestForAction,
            ),
            filterFunction(data: ICommonScreening): boolean {
                if (data.requestType) {
                    return (
                        ScreeningRequestTypes.RequestForAction.toLowerCase() ===
                        data.requestType.toLowerCase()
                    );
                }
                return false;
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        },
    ];

    return (
        <>
            <Separator styles={separatorStyles} alignContent='start'>
                Request Type
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Request Type Group'>
                <MultiChoiceFilter
                    unfilteredData={props.rawCandidates}
                    filterItems={requestTypeOptions}
                    onChildStateChange={(value: IMultiChoiceFilterItem, adding: boolean): void => {
                        updateFilterContext(
                            value,
                            adding,
                            requestTypeStr,
                            props.filterContext.setRequestType,
                            props.filterContext,
                            props.screeningPath,
                        );
                    }}
                />
            </Stack>
        </>
    );
}
export default RequestTypeFilter;
