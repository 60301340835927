/**
 * This react hook translates URL parameters to a dictionary
 * of key-values.
 */

import { useMemo } from 'react';
import { IFilterItem } from 'components/common/search-filter/search-filter-library';

type HookInputType = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    locationPath: any; // Use output of useLocation() of react-router-dom to this parameter.
    flattenedFilterItems: IFilterItem[];
    isFilterDefinitionsOk: boolean;
    propertyNameToFilterItemDict: Record<string, IFilterItem>;
};

type HookOutputType = {
    urlParamsKeyValuesPairs: Record<string, string[]>;
};

export function useGetUrlParams(params: HookInputType): HookOutputType {
    const urlParamsKeyValuesPairs = useMemo(() => {
        if (!params.isFilterDefinitionsOk) {
            return {};
        }
        // key is parameter name, value is the value provided in the URL
        // The following will be a dictionary of parameters and the values specified on the URL,
        // whether or not they are valid filter settings as defined in filter definitions.
        const urlParamsList = urlParamsListFunc(params.locationPath);

        // The following will be only those that are valid filter settings.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const urlParamsKeyValuesPairs: Record<string, any[]> = {};
        Object.entries(urlParamsList).forEach(([key, value]) => {
            value.forEach((v) => {
                const filterItemType = params.propertyNameToFilterItemDict[key];
                if (filterItemType) {
                    // Note: The following doesn't check validity of the setting
                    // specified in the URL parameter. For instance, it doesn't check
                    // if a selection specified for a checkbox or dropdown is valid for
                    // that checkbox or dropdown. However, such a check will be performed
                    // when value of the corresponding variables are to be set.
                    const paramValues = urlParamsKeyValuesPairs[key] ?? [];
                    paramValues.push(v);
                    urlParamsKeyValuesPairs[key] = paramValues;
                }
            });
        });
        return urlParamsKeyValuesPairs;
    }, [params.locationPath, params.flattenedFilterItems, params.isFilterDefinitionsOk]);
    return { urlParamsKeyValuesPairs };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function urlParamsListFunc(locationPath: any): Record<string, string[]> {
    const params = new URLSearchParams(locationPath.search);
    const keys = Array.from(params.keys());
    const uniqueKeys: Record<string, boolean> = {};
    keys.forEach((key) => {
        uniqueKeys[key] = true;
    });
    const result: Record<string, string[]> = {};
    Object.keys(uniqueKeys).forEach((key) => {
        result[key] = params.getAll(key);
    });
    return result;
}
