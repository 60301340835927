export type CountryCode = {
    name: string;
    abbrevation: string;
    code: string;
};

// Source https://countrycode.org/
// i combined couple countries together cause they have the same code
export const countryPhoneCodes: CountryCode[] = [
    // Putting US #1 then Canada and Mexico for ease for most US Microsoft employees
    { name: 'United States', code: '1', abbrevation: 'US' },
    // { name: 'Canada', code: '1', abbrevation: 'CA' }, // canada is same as US so just have US.
    { name: 'Mexico', code: '52', abbrevation: 'MX' },
    //
    { name: 'Afghanistan', code: '93', abbrevation: 'AF' },
    { name: 'Albania', code: '355', abbrevation: 'AL' },
    { name: 'Algeria', code: '213', abbrevation: 'DZ' },
    { name: 'American Samoa', code: '1-684', abbrevation: 'AS' },
    { name: 'Andorra', code: '376', abbrevation: 'AD' },
    { name: 'Angola', code: '244', abbrevation: 'AO' },
    { name: 'Anguilla', code: '1-264', abbrevation: 'AI' },
    { name: 'Antarctica', code: '672', abbrevation: 'AQ' },
    { name: 'Antigua and Barbuda', code: '1-268', abbrevation: 'AG' },
    { name: 'Argentina', code: '54', abbrevation: 'AR' },
    { name: 'Armenia', code: '374', abbrevation: 'AM' },
    { name: 'Aruba', code: '297', abbrevation: 'AW' },
    { name: 'Australia', code: '61', abbrevation: 'AU' },
    // { name: 'Christmas Island', code: '61', abbrevation: 'CX' },
    // { name: 'Cocos Islands', code: '61', abbrevation: 'CC' },
    { name: 'Austria', code: '43', abbrevation: 'AT' },
    { name: 'Azerbaijan', code: '994', abbrevation: 'AZ' },
    // END OF A
    { name: 'Bahamas', code: '1-242', abbrevation: 'BS' },
    { name: 'Bahrain', code: '973', abbrevation: 'BH' },
    { name: 'Bangladesh', code: '880', abbrevation: 'BD' },
    { name: 'Barbados', code: '1-246', abbrevation: 'BB' },
    { name: 'Belarus', code: '375', abbrevation: 'BY' },
    { name: 'Belgium', code: '32', abbrevation: 'BE' },
    { name: 'Belize', code: '501', abbrevation: 'BZ' },
    { name: 'Benin', code: '229', abbrevation: 'BJ' },
    { name: 'Bermuda', code: '1-441', abbrevation: 'BM' },
    { name: 'Bhutan', code: '975', abbrevation: 'BT' },
    { name: 'Bolivia', code: '591', abbrevation: 'BO' },
    { name: 'Bosnia and Herzegovina', code: '387', abbrevation: 'BA' },
    { name: 'Botswana', code: '267', abbrevation: 'BW' },
    { name: 'Brazil', code: '55', abbrevation: 'BR' },
    { name: 'British Indian Ocean Territory', code: '246', abbrevation: 'IO' },
    { name: 'British Virgin Islands', code: '1-284', abbrevation: 'VG' },
    { name: 'Brunei', code: '673', abbrevation: 'BN' },
    { name: 'Bulgaria', code: '359', abbrevation: 'BG' },
    { name: 'Burkina Faso', code: '226', abbrevation: 'BF' },
    { name: 'Myanmar', code: '95', abbrevation: 'MM' }, // used to be named Burma so it is here
    { name: 'Burundi', code: '257', abbrevation: 'BI' },
    // END OF B
    { name: 'Cambodia', code: '855', abbrevation: 'KH' },
    { name: 'Cameroon', code: '237', abbrevation: 'CM' },
    // { name: 'Canada', code: '1', abbrevation: 'CA' }, // up top for ease
    { name: 'Cape Verde', code: '238', abbrevation: 'CV' },
    { name: 'Cayman Islands', code: '1-345', abbrevation: 'KY' },
    { name: 'Central African Republic', code: '236', abbrevation: 'CF' },
    { name: 'Chad', code: '235', abbrevation: 'TD' },
    { name: 'Chile', code: '56', abbrevation: 'CL' },
    { name: 'China', code: '86', abbrevation: 'CN' },
    { name: 'Colombia', code: '57', abbrevation: 'CO' },
    { name: 'Comoros', code: '269', abbrevation: 'KM' },
    { name: 'Republic of the Congo', code: '242', abbrevation: 'CG' },
    { name: 'Democratic Republic of the Congo', code: '243', abbrevation: 'CD' },
    { name: 'Cook Islands', code: '682', abbrevation: 'CK' },
    { name: 'Costa Rica', code: '506', abbrevation: 'CR' },
    { name: 'Croatia', code: '385', abbrevation: 'HR' },
    { name: 'Cuba', code: '53', abbrevation: 'CU' },
    { name: 'Curacao/Netherlands Antilles', code: '599', abbrevation: 'CW' },
    // { name: 'Netherlands Antilles', code: '599', abbrevation: 'AN' },
    { name: 'Cyprus', code: '357', abbrevation: 'CY' },
    { name: 'Czech Republic', code: '420', abbrevation: 'CZ' },
    // END OF C
    { name: 'Denmark', code: '45', abbrevation: 'DK' },
    { name: 'Djibouti', code: '253', abbrevation: 'DJ' },
    { name: 'Dominica', code: '1-767', abbrevation: 'DM' },
    { name: 'Dominican Republic', code: '1-809', abbrevation: 'DO' },
    { name: 'Dominican Republic', code: '1-829', abbrevation: 'DO' },
    { name: 'Dominican Republic', code: '1-849', abbrevation: 'DO' },
    // END OF D
    { name: 'East Timor', code: '670', abbrevation: 'TL' },
    { name: 'Ecuador', code: '593', abbrevation: 'EC' },
    { name: 'Egypt', code: '20', abbrevation: 'EG' },
    { name: 'El Salvador', code: '503', abbrevation: 'SV' },
    { name: 'Equatorial Guinea', code: '240', abbrevation: 'GQ' },
    { name: 'Eritrea', code: '291', abbrevation: 'ER' },
    { name: 'Estonia', code: '372', abbrevation: 'EE' },
    { name: 'Ethiopia', code: '251', abbrevation: 'ET' },
    // END OF E
    { name: 'Falkland Islands', code: '500', abbrevation: 'FK' },
    { name: 'Faroe Islands', code: '298', abbrevation: 'FO' },
    { name: 'Fiji', code: '679', abbrevation: 'FJ' },
    { name: 'Finland', code: '358', abbrevation: 'FI' },
    { name: 'France', code: '33', abbrevation: 'FR' },
    { name: 'French Polynesia', code: '689', abbrevation: 'PF' },
    // END OF F
    { name: 'Gabon', code: '241', abbrevation: 'GA' },
    { name: 'Gambia', code: '220', abbrevation: 'GM' },
    { name: 'Georgia', code: '995', abbrevation: 'GE' },
    { name: 'Germany', code: '49', abbrevation: 'DE' },
    { name: 'Ghana', code: '233', abbrevation: 'GH' },
    { name: 'Gibraltar', code: '350', abbrevation: 'GI' },
    { name: 'Greece', code: '30', abbrevation: 'GR' },
    { name: 'Greenland', code: '299', abbrevation: 'GL' },
    { name: 'Grenada', code: '1-473', abbrevation: 'GD' },
    { name: 'Guam', code: '1-671', abbrevation: 'GU' },
    { name: 'Guatemala', code: '502', abbrevation: 'GT' },
    { name: 'Guernsey', code: '44-1481', abbrevation: 'GG' },
    { name: 'Guinea', code: '224', abbrevation: 'GN' },
    { name: 'Guinea-Bissau', code: '245', abbrevation: 'GW' },
    { name: 'Guyana', code: '592', abbrevation: 'GY' },
    // END OF G
    { name: 'Haiti', code: '509', abbrevation: 'HT' },
    { name: 'Honduras', code: '504', abbrevation: 'HN' },
    { name: 'Hong Kong', code: '852', abbrevation: 'HK' },
    { name: 'Hungary', code: '36', abbrevation: 'HU' },
    // END OF H
    { name: 'Iceland', code: '354', abbrevation: 'IS' },
    { name: 'India', code: '91', abbrevation: 'IN' },
    { name: 'Indonesia', code: '62', abbrevation: 'ID' },
    { name: 'Iran', code: '98', abbrevation: 'IR' },
    { name: 'Iraq', code: '964', abbrevation: 'IQ' },
    { name: 'Ireland', code: '353', abbrevation: 'IE' },
    { name: 'Isle of Man', code: '44-1624', abbrevation: 'IM' },
    { name: 'Israel', code: '972', abbrevation: 'IL' },
    { name: 'Italy', code: '39', abbrevation: 'IT' },
    { name: 'Ivory Coast', code: '225', abbrevation: 'CI' },
    // END OF I
    { name: 'Jamaica', code: '1-876', abbrevation: 'JM' },
    { name: 'Japan', code: '81', abbrevation: 'JP' },
    { name: 'Jersey', code: '44-1534', abbrevation: 'JE' },
    { name: 'Jordan', code: '962', abbrevation: 'JO' },
    // END OF J
    { name: 'Kenya', code: '254', abbrevation: 'KE' },
    { name: 'Kiribati', code: '686', abbrevation: 'KI' },
    { name: 'Kosovo', code: '383', abbrevation: 'XK' },
    { name: 'Kuwait', code: '965', abbrevation: 'KW' },
    { name: 'Kyrgyzstan', code: '996', abbrevation: 'KG' },
    // END OF K
    { name: 'Laos', code: '856', abbrevation: 'LA' },
    { name: 'Latvia', code: '371', abbrevation: 'LV' },
    { name: 'Lebanon', code: '961', abbrevation: 'LB' },
    { name: 'Lesotho', code: '266', abbrevation: 'LS' },
    { name: 'Liberia', code: '231', abbrevation: 'LR' },
    { name: 'Libya', code: '218', abbrevation: 'LY' },
    { name: 'Liechtenstein', code: '423', abbrevation: 'LI' },
    { name: 'Lithuania', code: '370', abbrevation: 'LT' },
    { name: 'Luxembourg', code: '352', abbrevation: 'LU' },
    // END OF L
    { name: 'Macau', code: '853', abbrevation: 'MO' },
    { name: 'Macedonia', code: '389', abbrevation: 'MK' },
    { name: 'Madagascar', code: '261', abbrevation: 'MG' },
    { name: 'Malawi', code: '265', abbrevation: 'MW' },
    { name: 'Malaysia', code: '60', abbrevation: 'MY' },
    { name: 'Maldives', code: '960', abbrevation: 'MV' },
    { name: 'Mali', code: '223', abbrevation: 'ML' },
    { name: 'Malta', code: '356', abbrevation: 'MT' },
    { name: 'Marshall Islands', code: '692', abbrevation: 'MH' },
    { name: 'Mauritania', code: '222', abbrevation: 'MR' },
    { name: 'Mauritius', code: '230', abbrevation: 'MU' },
    { name: 'Mayotte/Reunion', code: '262', abbrevation: 'YT' },
    // { name: 'Reunion', code: '262', abbrevation: 'RE' },
    { name: 'Micronesia', code: '691', abbrevation: 'FM' },
    { name: 'Moldova', code: '373', abbrevation: 'MD' },
    { name: 'Monaco', code: '377', abbrevation: 'MC' },
    { name: 'Mongolia', code: '976', abbrevation: 'MN' },
    { name: 'Montenegro', code: '382', abbrevation: 'ME' },
    { name: 'Montserrat', code: '1-664', abbrevation: 'MS' },
    { name: 'Morocco', code: '212', abbrevation: 'MA' },
    // { name: 'Western Sahara', code: '212', abbrevation: 'EH' },
    { name: 'Mozambique', code: '258', abbrevation: 'MZ' },
    // END OF M
    { name: 'Namibia', code: '264', abbrevation: 'NA' },
    { name: 'Nauru', code: '674', abbrevation: 'NR' },
    { name: 'Nepal', code: '977', abbrevation: 'NP' },
    { name: 'Netherlands', code: '31', abbrevation: 'NL' },
    { name: 'New Caledonia', code: '687', abbrevation: 'NC' },
    { name: 'New Zealand', code: '64', abbrevation: 'NZ' },
    // { name: 'Pitcairn', code: '64', abbrevation: 'PN' },
    { name: 'Nicaragua', code: '505', abbrevation: 'NI' },
    { name: 'Niger', code: '227', abbrevation: 'NE' },
    { name: 'Nigeria', code: '234', abbrevation: 'NG' },
    { name: 'Niue', code: '683', abbrevation: 'NU' },
    { name: 'Northern Mariana Islands', code: '1-670', abbrevation: 'MP' },
    { name: 'North Korea', code: '850', abbrevation: 'KP' },
    { name: 'Norway', code: '47', abbrevation: 'NO' },
    // { name: 'Svalbard and Jan Mayen', code: '47', abbrevation: 'SJ' },
    // END OF N
    { name: 'Oman', code: '968', abbrevation: 'OM' },
    // END OF O
    { name: 'Pakistan', code: '92', abbrevation: 'PK' },
    { name: 'Palau', code: '680', abbrevation: 'PW' },
    { name: 'Palestine', code: '970', abbrevation: 'PS' },
    { name: 'Panama', code: '507', abbrevation: 'PA' },
    { name: 'Papua New Guinea', code: '675', abbrevation: 'PG' },
    { name: 'Paraguay', code: '595', abbrevation: 'PY' },
    { name: 'Peru', code: '51', abbrevation: 'PE' },
    { name: 'Philippines', code: '63', abbrevation: 'PH' },
    { name: 'Poland', code: '48', abbrevation: 'PL' },
    { name: 'Portugal', code: '351', abbrevation: 'PT' },
    { name: 'Puerto Rico', code: '1-787', abbrevation: 'PR' },
    { name: 'Puerto Rico', code: '1-939', abbrevation: 'PR' },
    // END OF P
    { name: 'Qatar', code: '974', abbrevation: 'QA' },
    // END OF Q
    { name: 'Romania', code: '40', abbrevation: 'RO' },
    { name: 'Russia/Kazakhstan', code: '7', abbrevation: 'RU' },
    // { name: 'Kazakhstan', code: '7', abbrevation: 'KZ' },
    { name: 'Rwanda', code: '250', abbrevation: 'RW' },
    // END OF R
    { name: 'Saint Barthelemy/Saint Martin', code: '590', abbrevation: 'BL' },
    // { name: 'Saint Martin', code: '590', abbrevation: 'MF' },
    { name: 'Samoa', code: '685', abbrevation: 'WS' },
    { name: 'San Marino', code: '378', abbrevation: 'SM' },
    { name: 'Sao Tome and Principe', code: '239', abbrevation: 'ST' },
    { name: 'Saudi Arabia', code: '966', abbrevation: 'SA' },
    { name: 'Senegal', code: '221', abbrevation: 'SN' },
    { name: 'Serbia', code: '381', abbrevation: 'RS' },
    { name: 'Seychelles', code: '248', abbrevation: 'SC' },
    { name: 'Sierra Leone', code: '232', abbrevation: 'SL' },
    { name: 'Singapore', code: '65', abbrevation: 'SG' },
    { name: 'Sint Maarten', code: '1-721', abbrevation: 'SX' },
    { name: 'Slovakia', code: '421', abbrevation: 'SK' },
    { name: 'Slovenia', code: '386', abbrevation: 'SI' },
    { name: 'Solomon Islands', code: '677', abbrevation: 'SB' },
    { name: 'Somalia', code: '252', abbrevation: 'SO' },
    { name: 'South Africa', code: '27', abbrevation: 'ZA' },
    { name: 'South Korea', code: '82', abbrevation: 'KR' },
    { name: 'South Sudan', code: '211', abbrevation: 'SS' },
    { name: 'Spain', code: '34', abbrevation: 'ES' },
    { name: 'Sri Lanka', code: '94', abbrevation: 'LK' },
    { name: 'Saint Helena', code: '290', abbrevation: 'SH' },
    { name: 'Saint Kitts and Nevis', code: '1-869', abbrevation: 'KN' },
    { name: 'Saint Lucia', code: '1-758', abbrevation: 'LC' },
    { name: 'Saint Pierre and Miquelon', code: '508', abbrevation: 'PM' },
    { name: 'Saint Vincent and the Grenadines', code: '1-784', abbrevation: 'VC' },
    { name: 'Sudan', code: '249', abbrevation: 'SD' },
    { name: 'Suriname', code: '597', abbrevation: 'SR' },
    { name: 'Swaziland', code: '268', abbrevation: 'SZ' },
    { name: 'Sweden', code: '46', abbrevation: 'SE' },
    { name: 'Switzerland', code: '41', abbrevation: 'CH' },
    { name: 'Syria', code: '963', abbrevation: 'SY' },
    // END OF S
    { name: 'Taiwan', code: '886', abbrevation: 'TW' },
    { name: 'Tajikistan', code: '992', abbrevation: 'TJ' },
    { name: 'Tanzania', code: '255', abbrevation: 'TZ' },
    { name: 'Thailand', code: '66', abbrevation: 'TH' },
    { name: 'Togo', code: '228', abbrevation: 'TG' },
    { name: 'Tokelau', code: '690', abbrevation: 'TK' },
    { name: 'Tonga', code: '676', abbrevation: 'TO' },
    { name: 'Trinidad and Tobago', code: '1-868', abbrevation: 'TT' },
    { name: 'Tunisia', code: '216', abbrevation: 'TN' },
    { name: 'Turkey', code: '90', abbrevation: 'TR' },
    { name: 'Turkmenistan', code: '993', abbrevation: 'TM' },
    { name: 'Turks and Caicos Islands', code: '1-649', abbrevation: 'TC' },
    { name: 'Tuvalu', code: '688', abbrevation: 'TV' },
    // END OF T
    { name: 'United Arab Emirates', code: '971', abbrevation: 'AE' },
    { name: 'Uganda', code: '256', abbrevation: 'UG' },
    { name: 'United Kingdom', code: '44', abbrevation: 'GB' },
    { name: 'Ukraine', code: '380', abbrevation: 'UA' },
    { name: 'Uruguay', code: '598', abbrevation: 'UY' },
    { name: 'Uzbekistan', code: '998', abbrevation: 'UZ' },
    // END OF U
    { name: 'Vanuatu', code: '678', abbrevation: 'VU' },
    { name: 'Vatican', code: '379', abbrevation: 'VA' },
    { name: 'Venezuela', code: '58', abbrevation: 'VE' },
    { name: 'Vietnam', code: '84', abbrevation: 'VN' },
    { name: 'U.S. Virgin Islands', code: '1-340', abbrevation: 'VI' },
    // END OF V
    { name: 'Wallis and Futuna', code: '681', abbrevation: 'WF' },
    // END OF W
    { name: 'Yemen', code: '967', abbrevation: 'YE' },
    // END OF Y
    { name: 'Zambia', code: '260', abbrevation: 'ZM' },
    { name: 'Zimbabwe', code: '263', abbrevation: 'ZW' },
];
