import React, { ReactNode } from 'react';
import { ScreeningPageNames } from 'components/common/constants';
import { detailsListStyles } from 'assets/styles/list-styles';
import { IColumn, Stack, Icon, ActionButton } from '@fluentui/react';
import { unixTimeStampToLocalDate, unixTimeStampToFormattedDateString } from 'utils/time-utils';
import NomineeAccept from 'components/screening/common/nominee-accept';
import { TableCell } from 'components/common/table';
import EllipsisText from 'components/common/ellipsis-text';
import { generateRandomKey } from 'utils/misc-utils';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import EmployeeMiniCard from 'components/common/employee/employee-mini-card';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { EmployeeNameResolverId } from 'components/common/employee/employee-name-resolver';
import config from 'environments/environment';
import { NavLink } from 'react-router-dom';
import { Dictionary, IconNames, noDataText } from 'assets/constants/global-constants';
import { ISortableColumns } from 'utils/sort-utils';
import ContractOwnerAccept from 'components/screening/common/contract-owner/contract-owner-accept';
import { tableColumnStyles } from 'components/screening/common/common-tab-styling';
import {
    getStatusText,
    metricsTitle,
    ScreeningPaths,
    ScreeningRequestTypesLabels,
    StateName,
} from 'components/screening/common/common-constants';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { getCommonScreeningStatusLabel } from 'components/screening/common/common-ui';
import { IContract } from 'components/screening/us-gov/IContract';

interface ICandidates extends ISortableColumns {
    updateScreeningInRawCandidates: (screen: ICommonScreening) => void;
    isInitialEmployeeBatchLoaded: boolean;
    openDetails: (screening: ICommonScreening) => void;
    pageName: string;
    contractsDict: Dictionary<IContract>;
}

export enum usGovTableColumns {
    Requested = 'Requested',
    Candidate = 'Candidate',
    Clearance = 'Clearance',
    Level = 'Level',
    Type = 'Type',
    RequestType = 'Request Type',
    ProjectName = 'Project Name',
    ContractId = 'Contract ID',
    Customer = 'Customer',
    NominatedBy = 'Nominated By',
    NominatedOn = 'Nominated On',
    Status = 'Status',
    Substatus = 'Sub-Status',
    ProcessOwner = 'Process Owner',
    StatusChangedOn = 'Status Changed On',
    Actions = 'Actions',
    Action = 'Action',
}

export function getCandidatesTableColumns(args: ICandidates): IColumn[] {
    const {
        updateScreeningInRawCandidates,
        isInitialEmployeeBatchLoaded,
        sortColumn,
        sortAscending: isSortedAscending,
        sortColumnHandler,
        openDetails,
        pageName,
        contractsDict,
    } = args;

    return [
        {
            key: 'candidate',
            name: usGovTableColumns.Candidate,
            ariaLabel: usGovTableColumns.Candidate,
            minWidth: 200,
            maxWidth: 300,
            isRowHeader: true,
            isMultiline: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                return (
                    <TableCell key={row?.personnelId || row?.preHire?.pcn}>
                        {renderCorrectCandidateColumnOption(row)}
                    </TableCell>
                );
            },
            isSorted: sortColumn === usGovTableColumns.Candidate,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(usGovTableColumns.Candidate);
            },
        },
        {
            key: 'clearance',
            name: usGovTableColumns.Clearance,
            ariaLabel: usGovTableColumns.Clearance,
            minWidth: 85,
            maxWidth: 85,
            isMultiline: true,
            isCollapsible: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                return <TableCell>{row.clearance}</TableCell>;
            },
            isSorted: sortColumn === usGovTableColumns.Clearance,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(usGovTableColumns.Clearance);
            },
        },
        {
            key: 'requestType',
            name: usGovTableColumns.RequestType,
            ariaLabel: usGovTableColumns.RequestType,
            minWidth: 90,
            maxWidth: 90,
            isCollapsible: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                if (row.requestType) {
                    const requestTypeStr = row.requestType.replace(/ /g, ''); // remove spaces fast
                    return (
                        <TableCell>
                            <EllipsisText
                                text={
                                    ScreeningRequestTypesLabels[
                                        requestTypeStr as keyof typeof ScreeningRequestTypesLabels
                                    ]
                                }
                                textLengthBeforeEllipsis={13}
                            />
                        </TableCell>
                    );
                }
                return <></>;
            },
            isSorted: sortColumn === usGovTableColumns.RequestType,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(usGovTableColumns.RequestType);
            },
        },
        {
            key: 'contract',
            name: usGovTableColumns.ContractId,
            ariaLabel: usGovTableColumns.ContractId,
            minWidth: 115,
            maxWidth: 115,
            isCollapsible: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisText text={row.contractId} textLengthBeforeEllipsis={14} />
                    </TableCell>
                );
            },
            isSorted: sortColumn === usGovTableColumns.ContractId,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(usGovTableColumns.ContractId);
            },
        },
        {
            key: 'project',
            name: usGovTableColumns.ProjectName,
            ariaLabel: usGovTableColumns.ProjectName,
            minWidth: 100,
            maxWidth: 100,
            isCollapsible: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                return <TableCell>{contractsDict[row.contractId]?.project || ''}</TableCell>;
            },
            isSorted: sortColumn === usGovTableColumns.ProjectName,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(usGovTableColumns.ProjectName);
            },
        },
        {
            key: 'customer',
            name: usGovTableColumns.Customer,
            ariaLabel: usGovTableColumns.Customer,
            minWidth: 75,
            maxWidth: 75,
            isCollapsible: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                return <TableCell>{contractsDict[row.contractId]?.customer || ''}</TableCell>;
            },
            isSorted: sortColumn === usGovTableColumns.Customer,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(usGovTableColumns.Customer);
            },
        },
        {
            key: 'nominatedBy',
            name: usGovTableColumns.NominatedBy,
            ariaLabel: usGovTableColumns.NominatedBy,
            minWidth: 110,
            maxWidth: 150,
            isCollapsible: true,
            isMultiline: true,
            onRender: (row: ICommonScreening): ReactNode => {
                return row.nominatedBy ? (
                    <EmployeeHoverCard personnelId={row.nominatedBy}>
                        <EmployeeNameResolverId personnelId={row.nominatedBy} />
                    </EmployeeHoverCard>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === usGovTableColumns.NominatedBy,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(usGovTableColumns.NominatedBy);
            },
        },
        {
            key: 'nominatedOn',
            name: usGovTableColumns.NominatedOn,
            ariaLabel: usGovTableColumns.NominatedOn,
            minWidth: 100,
            maxWidth: 100,
            isCollapsible: true,
            isMultiline: true,
            onRender: (row: ICommonScreening): ReactNode => {
                return row.nominatedAtUtc ? (
                    <TableCell> {unixTimeStampToLocalDate(row.nominatedAtUtc)} </TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === usGovTableColumns.NominatedOn,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(usGovTableColumns.NominatedOn);
            },
        },
        {
            key: 'status',
            name: usGovTableColumns.Status,
            ariaLabel: usGovTableColumns.Status,
            minWidth: 115,
            maxWidth: 130,
            isMultiline: true,
            onRender: (row: ICommonScreening): JSX.Element | string => {
                if (row.stateName) {
                    return (
                        <div className={detailsListStyles.dataCellContainer}>
                            {getCommonScreeningStatusLabel(row)}
                        </div>
                    );
                } else {
                    return '';
                }
            },
            isSorted: sortColumn === usGovTableColumns.Status,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(usGovTableColumns.Status);
            },
        },
        {
            key: 'subStatus',
            name: usGovTableColumns.Substatus,
            ariaLabel: usGovTableColumns.Substatus,
            minWidth: 115,
            maxWidth: 130,
            isMultiline: true,
            onRender: (row: ICommonScreening): JSX.Element | string => {
                if (row.stateName) {
                    return (
                        <div className={detailsListStyles.dataCellContainer}>
                            {getStatusText(row, false)}
                        </div>
                    );
                } else {
                    return '';
                }
            },
            isSorted: sortColumn === usGovTableColumns.Substatus,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(usGovTableColumns.Substatus);
            },
        },
        {
            key: 'processOwner',
            name: usGovTableColumns.ProcessOwner,
            ariaLabel: usGovTableColumns.ProcessOwner,
            minWidth: 110,
            maxWidth: 150,
            isMultiline: true,
            onRender: (row: ICommonScreening): ReactNode => {
                return row.processOwnerId ? (
                    <EmployeeHoverCard personnelId={row.processOwnerId}>
                        <EmployeeNameResolverId personnelId={row.processOwnerId} />
                    </EmployeeHoverCard>
                ) : (
                    noDataText
                );
            },
            isSorted: sortColumn === usGovTableColumns.ProcessOwner,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(usGovTableColumns.ProcessOwner);
            },
        },
        {
            key: 'statusChangedOn',
            name: usGovTableColumns.StatusChangedOn,
            ariaLabel: usGovTableColumns.StatusChangedOn,
            minWidth: 130,
            maxWidth: 130,
            isMultiline: true,
            onRender: (row: ICommonScreening): ReactNode => {
                return row.statusChangedAtUtc ? (
                    <TableCell>
                        {' '}
                        {unixTimeStampToFormattedDateString(row.statusChangedAtUtc)}{' '}
                    </TableCell>
                ) : (
                    ''
                );
            },
            isSorted: sortColumn === usGovTableColumns.StatusChangedOn,
            isSortedDescending: !isSortedAscending,
            onColumnClick: (): void => {
                sortColumnHandler(usGovTableColumns.StatusChangedOn);
            },
        },
        {
            key: 'actions',
            name: usGovTableColumns.Actions,
            ariaLabel: usGovTableColumns.Actions,
            minWidth: 250,
            maxWidth: 300,
            onRender: (row: ICommonScreening): JSX.Element => {
                return getAction(
                    pageName,
                    row,
                    updateScreeningInRawCandidates,
                    isInitialEmployeeBatchLoaded,
                    openDetails,
                );
            },
        },
    ] as IColumn[];
}

function getAction(
    pageName: string,
    row: ICommonScreening,
    updateScreeningInRawCandidates: (screen: ICommonScreening) => void,
    isInitialEmployeeBatchLoaded: boolean,
    openDetails: (screening: ICommonScreening) => void,
): JSX.Element {
    const showNomineeApprovalRequest =
        pageName === ScreeningPageNames.MyScreenings &&
        row.stateName === StateName.WaitingForNomineeApproval;
    const canSeeDetailsButton =
        pageName === ScreeningPageNames.Manage ||
        (pageName === ScreeningPageNames.MyScreenings &&
            row.stateName !== StateName.WaitingForNomineeApproval &&
            row.stateName !== StateName.WaitingForContractOwnerApproval);
    return (
        <Stack horizontal className={tableColumnStyles.actionRow}>
            {canSeeDetailsButton && (
                <div className={tableColumnStyles.navLink}>
                    <NavLink
                        key={`preview-link-${row.id ?? generateRandomKey()}`}
                        className={tableColumnStyles.viewScreeningLink}
                        to={`/screening/us-gov/${row.id}`}>
                        <Icon
                            iconName='PreviewLink'
                            className={tableColumnStyles.viewScreeningIcon}
                        />{' '}
                        Details
                    </NavLink>
                </div>
            )}
            {pageName === ScreeningPageNames.MyContracts &&
                row.stateName === StateName.WaitingForContractOwnerApproval && (
                    <div className={tableColumnStyles.viewNomination}>
                        <ContractOwnerAccept
                            key={`contract-owner-accept-${row.id ?? generateRandomKey()}`}
                            screening={row}
                            screeningPath={ScreeningPaths.UsGov}
                            updateScreeningInRawCandidates={updateScreeningInRawCandidates}
                            isInitialEmployeeBatchLoaded={isInitialEmployeeBatchLoaded}
                        />
                    </div>
                )}
            {showNomineeApprovalRequest && (
                <NomineeAccept
                    key={`nominee-approve-${row.id ?? generateRandomKey()}`}
                    screening={row}
                    screeningPath={ScreeningPaths.UsGov}
                    updateScreeningInRawCandidates={updateScreeningInRawCandidates}
                    isInitialEmployeeBatchLoaded={isInitialEmployeeBatchLoaded}
                />
            )}
            {pageName !== ScreeningPageNames.MyScreenings && (
                <div className={tableColumnStyles.metrics}>
                    <ActionButton
                        label={metricsTitle}
                        text={metricsTitle}
                        iconProps={{
                            iconName: IconNames.BarChartVerticalFill,
                        }}
                        onClick={(): void => openDetails(row)}
                    />
                </div>
            )}
        </Stack>
    );
}

export function renderCorrectCandidateColumnOption(row: ICommonScreening | undefined): JSX.Element {
    if (!row) {
        return <></>;
    }
    // TODO once prehire flag is enabled permanently, everyting can be consolidated as EmployeeBasicHoverCard, i.e. remove the conditional check and if case completely
    return row.preHire && (!row.personnelId || !config.personnelProfile.prehireFlag) ? (
        <EmployeeMiniCard
            employee={{
                displayName: getDisplayNameOrDefault(row.preHire),
                alias: row.preHire.pcn,
            }}
            showImage={true}
            showAlias={true}
        />
    ) : (
        <EmployeeBasicHoverCard personnelId={row.personnelId} />
    );
}
