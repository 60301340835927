/*
obj: the object to be cloned (shallow copy)
value: the value to set and update on the cloned object
nestedKeyProps: the list of nested properties that form the key

supports up to 3 levels of nesting for now
*/
export function setNestedKeyValueOfCloneObject<T>(
    obj: T,
    value: any,
    ...nestedKeyProps: string[]
): T | undefined {
    if (nestedKeyProps.length === 1) {
        return setKeyValueOfCloneObject(obj, value, nestedKeyProps[0]);
    } else if (nestedKeyProps.length === 2) {
        return {
            ...obj,
            [nestedKeyProps[0]]: {
                ...(obj as any)[nestedKeyProps[0]],
                [nestedKeyProps[1]]: value,
            },
        } as T;
    } else if (nestedKeyProps.length > 2) {
        return {
            ...obj,
            [nestedKeyProps[0]]: {
                ...(obj as any)[nestedKeyProps[0]],
                [nestedKeyProps[1]]: {
                    ...(obj as any)[nestedKeyProps[0]][nestedKeyProps[1]],
                    [nestedKeyProps[2]]: value,
                },
            },
        } as T;
    }
}

/*
obj: the object to be cloned (shallow copy)
value: the value to set and update on the cloned object
keyProp: the key property to set the value on

no nesting support
*/
export function setKeyValueOfCloneObject<T>(obj: T, value: any, keyProp: string): T {
    return {
        ...obj,
        [keyProp]: value,
    } as T;
}

/*
obj: the object to filter the null properties from

no nesting support
*/
export function filterOutNullProperties<T>(obj: { [x: string]: any }): T {
    Object.keys(obj)
        .filter((k) => obj[k] === null)
        .forEach((k) => delete obj[k]);
    return obj as T;
}

export type tuple = {
    key: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function objectToTupleArray(obj: object | undefined): tuple[] {
    if (obj) {
        const tmpMap: tuple[] = [];
        Object.keys(obj).forEach((key: string) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            tmpMap.push({ key: `${key}`, value: `${(obj as any)[key]}` });
        });
        return tmpMap;
    }
    return [];
}

/**
 * Acts similar to C# `nameof` expression.
 * Pass in an object's property to receive the
 * name of that property back as a string value.
 * Used to enforce strong typing. Will result in a compile time error if the
 * property value changes without updating the call to nameof.
 * @example
 *
 * const myObj = { myProperty: 'someValue'};
 *
 * const myName = nameof<typeof myObj>('myProperty'); //myName === 'myProperty'
 *
 */
export const nameof = <T extends Record<string, unknown>>(name: Extract<keyof T, string>): string =>
    name;

/**
* Get all optional keys from a type and return a new type
*/
// eslint-disable-next-line prettier/prettier
export type GetOptionalKeys<T> = {[K in keyof T as (undefined extends T[K] ? K : never)]: T[K]}

/**
* Returns a type that corresponds to all of the values of the passed in type
*  @example
* type MyType = { prop1: string; prop2: boolean };
* type MyTypeValues = ValueOf<MyType>; // MyTypeValues = string | boolean
*
*/
export type ValueOf<T> = T[keyof T];
