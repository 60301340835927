import React from 'react';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { IColumn, TooltipHost, ITooltipHostStyles, mergeStyles, Stack } from '@fluentui/react';
import { TableCell, TableEditActionButton, TableDeleteActionButton } from 'components/common/table';
import {
    translateCloudName,
    getStatusBackgroundColor,
    getStatusForegroundColor,
    getStatusTitle,
} from 'components/staffing/staffing-constants';
import { IStaffingCloudStatusResponse } from 'clients/staffing-client';
import { IAllocationItem } from 'components/staffing/staffing-page-types';
import Badge from 'components/common/badge';
import { lastColumnStylesButton } from 'assets/styles/list-styles';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { ISortableColumns } from 'utils/sort-utils';

export interface IGetAllocationTableColumns extends ISortableColumns {
    hasEditRoles: boolean;
    editAllocationActionButton: (emp: IAllocationItem) => void;
    deleteAllocationActionButton: (emp: IAllocationItem) => void;
}

export function getAllocationTableColumns(params: IGetAllocationTableColumns): IColumn[] {
    const hasActionButtons = params.hasEditRoles;
    const columnWidths = {
        team: 50,
    };
    const columns: IColumn[] = [
        {
            key: 'Name',
            name: 'Name',
            ariaLabel: 'Name',
            minWidth: 100,
            maxWidth: 340,
            isSorted: params.sortColumn === 'name',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('name');
            },
            onRender: (row: IAllocationItem): JSX.Element => {
                const { employee, picture, preHireName } = row;
                return (
                    <TableCell>
                        <EmployeeBasicHoverCard
                            // Specifying key is essential for displaying the
                            // correct picture when sorting based on this column.
                            key={employee?.id ?? row.PCN}
                            employeeBasicData={employee}
                            base64ImageString={picture}
                            preHireInfo={{ preHireName }}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: 'Cloud',
            name: 'Cloud',
            ariaLabel: 'Cloud',
            minWidth: 100,
            maxWidth: 100,
            isSorted: params.sortColumn === 'cloud',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('cloud');
            },
            onRender: (row: IAllocationItem): JSX.Element => {
                return (
                    <TableCell>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item>{statusCard(row.clouds)}</Stack.Item>
                        </Stack>
                    </TableCell>
                );
            },
        },
        {
            key: 'PCN',
            name: 'PCN #',
            ariaLabel: 'PCN #',
            minWidth: 90,
            maxWidth: 120,
            isSorted: params.sortColumn === 'pcn',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('pcn');
            },
            onRender: (row: IAllocationItem): JSX.Element => {
                return <TableCell>{row.PCN}</TableCell>;
            },
        },
        {
            key: 'Team',
            name: 'Team',
            ariaLabel: 'Team',
            minWidth: columnWidths.team,
            // undefined in the following parameter allows the width to
            // expand without limit when user makes the window wider.
            maxWidth: undefined,
            isSorted: params.sortColumn === 'team',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('team');
            },
            onRender: (row: IAllocationItem): JSX.Element => {
                return (
                    <TableCell>
                        <div
                            className={mergeStyles({
                                minWidth: columnWidths.team,
                            })}>
                            <EllipsisTextCss text={row.team} />
                        </div>
                    </TableCell>
                );
            },
        },
    ];

    if (hasActionButtons) {
        columns.push({
            key: 'Actions',
            name: 'Actions',
            ariaLabel: 'Actions',
            minWidth: 150,
            maxWidth: 150,
            ...lastColumnStylesButton,
            onRender: (row: IAllocationItem): JSX.Element => {
                return (
                    <TableCell>
                        <TableEditActionButton
                            onClick={(): void => {
                                params.editAllocationActionButton(row);
                            }}
                        />
                        <TableDeleteActionButton
                            onClick={(): void => {
                                params.deleteAllocationActionButton(row);
                            }}
                        />
                    </TableCell>
                );
            },
        });
    }

    return columns;
}

function statusCard(cloudStatuses: IStaffingCloudStatusResponse[]): JSX.Element {
    /**
     * Example for value of parameter cloudStatuses:
     *     [{status: "InProcess", name: "EX"},]
     */
    // Prior to calling this function, only cloud status
    // of the cloud that's selcted by the filters is passed
    // to this function. As a result, cloudStatuses is
    // expected to have one element. I don't know if it can
    // have zero elements, but the code is able to handle it.
    let text, color, backgroundColor;
    const cloudStatus = cloudStatuses[0];
    if (cloudStatus) {
        text = getStatusTitle(cloudStatus.status);
        color = getStatusForegroundColor(cloudStatus.status);
        backgroundColor = getStatusBackgroundColor(cloudStatus.status);
    } else {
        backgroundColor = 'inherit';
    }
    return (
        <TooltipHost
            content={translateCloudName(cloudStatus?.name)}
            styles={
                {
                    root: {
                        selectors: {
                            ':not(:first-child)': {
                                marginLeft: '10px',
                            },
                        },
                    },
                } as Partial<ITooltipHostStyles>
            }
            delay={0}>
            <Badge
                width={80}
                text={text ?? ''}
                textAlign='center'
                color={color}
                backgroundColor={backgroundColor}
            />
        </TooltipHost>
    );
}
