import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import StaffingFiltersContextProvider, {
    initializeFilter,
} from 'components/staffing/contexts/staffing-filters-context';
import { Location } from 'history';
import { mergeStyles } from '@fluentui/react';
import StaffingAllocationFilters, {
    cloudFilterSubkey,
} from 'components/staffing/allocation/staffing-allocation-filters';
import StaffingServiceMapFilters from 'components/staffing/allocation/staffing-service-map-filters';
import SidebarAndContents, {
    SidebarPane,
    ContentPane,
} from 'components/common/sidebar-and-contents';
import StaffingAllocationMap from 'components/staffing/allocation/staffing-allocation-map';
import StaffingClient, {
    IStaffingCloudResponse,
    IStaffingProjectResponse,
    IStaffingAllocationResponse,
    IStaffingOrganizationResponse,
    IStaffingTeamResponse,
    ITeamAzureService,
    IAzureServiceKpi,
    IAzureService,
} from 'clients/staffing-client';
import { AuthContext } from 'contexts/auth-context';
import { ClearanceStatusTitle, cloudKeys } from 'components/staffing/staffing-constants';
import {
    StaffingFiltersProps,
    showAllocationInfoType,
    FilterCategorySettingType,
    StaffingEmployeesPicturesType,
    OrgPermissionType,
    IAllocationItem,
} from 'components/staffing/staffing-page-types';
import EmployeeClient, { IBasicEmployee } from 'clients/employee-client';
import AddTeamModal from 'components/staffing/allocation/add-team-modal';
import DeleteAllocationModal from 'components/staffing/allocation/delete-allocation-modal';
import EditAddAllocationModal from 'components/staffing/allocation/edit-allocation-modal';
import StaffingButtonBar from 'components/staffing/allocation/staffing-button-bar';
import { staffingOrgBreadcrumb } from 'components/staffing/staffing-breadcrumbs';
import { StaffingAdminRoles, Role } from 'configs/roles';
import CheckRole from 'components/common/check-role';
import Tabs, { TabbedContent } from 'components/common/tabs';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import StaffingServiceMappingFiltersProvider from 'components/staffing/contexts/staffing-service-map-filter-context';
import StaffingServiceMap from 'components/staffing/service/staffing-service-map';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { AccessDeniedURL, Dictionary } from 'assets/constants/global-constants';
import deepEqual from 'deep-equal';
import { determineOrgsInfo } from 'components/staffing/staffing-utils';
import { CacheContext } from 'contexts/cache-context';
import { strCmp } from 'utils/sort-utils';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import { useReducerVar } from 'utils/misc-hooks';
import { fetchPictures } from 'components/common/employee/employee-utils';

export interface StaffingOrgMapPageProps {
    pageName: string;
    location: Location;
    userType: string;
}

type Params = {
    urlOrgName: string;
};

const margin = mergeStyles({
    marginLeft: '30px',
    marginRight: '30px',
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function StaffingOrgMapPage(props: StaffingOrgMapPageProps): JSX.Element {
    const { urlOrgName } = useParams<Params>();
    const authContext = useContext(AuthContext);
    const cacheContext = useContext(CacheContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);
    const [clouds, setClouds] = useState<IStaffingCloudResponse[]>([]);
    const [editableOrgs, setEditableOrgs] = useState<IStaffingOrganizationResponse[]>([]);
    const [teams, setTeams] = useState<Dictionary<IStaffingTeamResponse[]>>({});
    const [allocations, setAllocations] = useState<IStaffingAllocationResponse[]>([]);
    const [basicEmployeesInfo, setBasicEmployeesInfo] = useState<IBasicEmployee[]>([]);
    const [employeesPictures, setEmployeesPictures] = useState<StaffingEmployeesPicturesType>({});
    const [initFilters, setInitFilters] = useState<FilterCategorySettingType>({});
    const [showEditAllocation, setShowEditAllocation] = useState(false);
    const [showAddTeam, setShowAddTeam] = useState(false);
    const [showAddAllocation, setShowAddAllocation] = useState(false);
    const [showDeleteAllocation, setShowDeleteAllocation] = useState(false);
    const [allocationToModify, setAllocationToModify] = useState({} as IAllocationItem);
    const [isAllServicesLoading, setIsAllServicesLoading] = useState<boolean>(true);
    const [isServicesFetching, setIsServicesFetching] = useState<boolean>(true);
    const [allServices, setAllServices] = useState<IAzureService[]>([]);
    const [orgServices, setOrgServices] = useState<ITeamAzureService[]>([]);
    const [orgServiceKpis, setOrgServiceKpis] = useState<IAzureServiceKpi[]>([]);
    const [isOrgPermissionsChecked, setIsOrgPermissionsChecked] = useReducerVar<boolean>(false);
    const [orgPermissions, setOrgPermissions] = useState<Dictionary<OrgPermissionType>>({});
    const [projects, setProjects] = useState<IStaffingProjectResponse[]>([]);
    const [organization, setOrganization] = useState<IStaffingOrganizationResponse | undefined>();

    const organizationId = (): string => organization?.id ?? '';
    const organizationName = (): string => organization?.name ?? '';

    useEffect(() => {
        const go = async (): Promise<void> => {
            setProjects(await StaffingClient.getProjects(authContext));
        };
        go();
    }, []);

    /**
     * Determine breadcrumbs
     */
    useEffect(() => {
        if (organizationName()) {
            // This if statement causes the breadcrumbs to appear
            // all at once, rather than one crumb at a time.
            const breadcrumb = staffingOrgBreadcrumb(urlOrgName);
            const lenm1 = breadcrumb.length - 1;
            breadcrumb[lenm1].link = breadcrumb[lenm1].link.replace(':urlOrgName', urlOrgName);
            breadcrumb[lenm1].title = organizationName();

            breadCrumbContext.setBreadCrumbs([...breadcrumb]);
        }
    }, [organization, urlOrgName]);

    useEffect(() => {
        let isMounted = true;
        const go = async (): Promise<void> => {
            try {
                const [
                    organizationVar,
                    { accessibleOrgs: accessibleOrgsVar, orgPermissions: orgPermissionsVar },
                ] = await Promise.all([
                    StaffingClient.getOrganization(authContext, urlOrgName),
                    determineOrgsInfo(authContext),
                ]);
                const editableOrgsVar = accessibleOrgsVar.filter(
                    (accessibleOrg) => orgPermissionsVar[accessibleOrg.id][Role.StaffingOrgEdit],
                );
                const teamsVar = await fetchTeams(accessibleOrgsVar);
                if (isMounted) {
                    updateTeams(teamsVar);
                    setOrganization(organizationVar);
                    setEditableOrgs(editableOrgsVar);
                    setOrgPermissions(orgPermissionsVar);
                }
            } catch (e) {
                console.error(e);
                if (isMounted) {
                    updateTeams({});
                    setOrganization(undefined);
                    setEditableOrgs([]);
                    setOrgPermissions({});
                }
            } finally {
                if (isMounted) {
                    setIsOrgPermissionsChecked(true);
                }
            }
        };
        go();
        return (): void => {
            isMounted = false;
        };
    }, [urlOrgName]);

    const hasEditPermission = (): boolean => {
        // Stragely, creating a variable instead of this function and setting its value
        // together with setting orgPermissions didn't work. The problem was, on the tab
        // "Allocation Map", at first the table would not show the action buttons even
        // for a user that has edit role on this org. The strange thing was, value
        // of the variable had been calculated and was true, but somehow the code didn't
        // show reaction to the variable being set to true. The table would only show
        // the action buttons after user changed tabs, say to "Heat Map" or "Serivce Map"
        // and then back to "Allocation Tab". Changing the variable to this function
        // solved the problem.
        return (orgPermissions[organizationId()] ?? {})[Role.StaffingOrgEdit];
    };

    const fetchAndUpdateClouds = (): void => {
        StaffingClient.getClouds(authContext)
            .then((clouds) => {
                setClouds(clouds);
            })
            .catch((e) => {
                console.error(e);
                setClouds([]);
            });
    };

    const fetchAndUpdateAllocations = async (): Promise<void> => {
        try {
            const allocationsVar = await StaffingClient.getAllocationsByOrganization(
                authContext,
                cacheContext,
                urlOrgName,
            );
            const ids = allocationsVar.map((a) => a.personnelId);

            const basicEmployeesInfo = await EmployeeClient.getBasicEmployeesById(authContext, ids);

            const pictures = await fetchPictures(authContext, basicEmployeesInfo);

            // eslint-disable-next-line @typescript-eslint/ban-types
            const teamsByName = {} as { [key: string]: {} };
            allocationsVar.forEach((allocation) => {
                const { team } = allocation;
                teamsByName[team.name] = team;
            });
            setAllocations(allocationsVar);
            setBasicEmployeesInfo(basicEmployeesInfo);
            setEmployeesPictures(pictures);
            setIsInitialLoading(false);
        } catch (e) {
            console.error(e);
            setAllocations([]);
            setBasicEmployeesInfo([]);
            setEmployeesPictures({});
            setIsInitialLoading(false);
        }
    };

    const fetchTeams = async (
        organizations: IStaffingOrganizationResponse[],
    ): Promise<Dictionary<IStaffingTeamResponse[]>> => {
        let newTeams: Dictionary<IStaffingTeamResponse[]>;

        try {
            const apiTeams = await Promise.all(
                organizations.map(
                    async (
                        organization: IStaffingOrganizationResponse,
                    ): Promise<Dictionary<IStaffingTeamResponse[]>> => {
                        let teamsVar: IStaffingTeamResponse[] = [];
                        try {
                            teamsVar = await StaffingClient.getTeamsFromOrganization(
                                authContext,
                                organization.name,
                            );
                        } catch (e) {
                            console.error(`Error fetching teams of org ${organization.name}`);
                            console.error(e);
                        } finally {
                            return {
                                // organization.id is a better choice here than organization.name
                                // because it's all small case.
                                [organization.id]: teamsVar.sort((t1, t2) =>
                                    strCmp(t1.name, t2.name),
                                ),
                            };
                        }
                    },
                ),
            );
            newTeams = apiTeams.reduce((allTeams, thisTeam) => ({ ...allTeams, ...thisTeam }), {});
        } catch (e) {
            console.error(e);
            newTeams = {};
        }
        return newTeams;
    };

    const updateTeams = (newTeams: Dictionary<IStaffingTeamResponse[]>): void => {
        if (!deepEqual(newTeams, teams, { strict: true })) {
            setTeams(newTeams);
        }
    };

    useEffect(() => {
        fetchAndUpdateClouds();
        fetchAndUpdateAllocations();
    }, []);

    /**
     * Initialize filters
     */
    useEffect(() => {
        const initFiltersVar = {};
        initializeFilter(initFiltersVar, 'employee', 'info', '');
        initializeFilter(initFiltersVar, 'cloud', cloudFilterSubkey, cloudKeys()[0]);
        Object.entries(ClearanceStatusTitle).forEach((status): void => {
            initializeFilter(initFiltersVar, 'status', status[1], false);
        });
        if (organization?.id) {
            teams[organization?.id].forEach((team): void => {
                initializeFilter(initFiltersVar, 'team', team.name, false);
            });
        }
        setInitFilters(initFiltersVar);
    }, [clouds, teams, organization]);

    const staffingFiltersProps: StaffingFiltersProps = {
        teamNames: organization?.id ? teams[organization?.id]?.map((team) => team.name) ?? [] : [],
        cloudNames: clouds.map((cloud) => cloud.name),
        statusNames: Object.values(ClearanceStatusTitle),
    };

    const getAllServices = async (): Promise<void> => {
        setIsAllServicesLoading(true);
        try {
            const services = await StaffingClient.getAzureServices(authContext);
            const servicesUnique = [] as IAzureService[];
            services
                .reduce((map, current) => {
                    map.set(current.oid, current);
                    return map;
                }, new Map<string, IAzureService>())
                .forEach((s) => servicesUnique.push(s));
            setAllServices(servicesUnique);
            setIsAllServicesLoading(false);
        } catch (e) {
            console.error(e);
            setAllServices([]);
            setIsAllServicesLoading(false);
        }
    };

    const fetchServices = async (): Promise<void> => {
        setIsServicesFetching(true);
        try {
            const [orgServices, orgServiceKpis] = await Promise.all([
                StaffingClient.getTeamAzureServices(authContext, urlOrgName),
                StaffingClient.getKpisByOrganization(authContext, urlOrgName),
            ]);
            setOrgServices(orgServices);
            setOrgServiceKpis(orgServiceKpis);
            setIsServicesFetching(false);
        } catch (e) {
            console.error(e);
            setOrgServices([]);
            setOrgServiceKpis([]);
            setIsServicesFetching(false);
        }
    };

    const addServiceCallback = (modalConclusion: ModalConclusion): void => {
        if (modalConclusion === ModalConclusion.Done) {
            StaffingClient.invalidateCache(cacheContext);
            fetchServices();
        }
    };

    const editAllocationActionButton = (allocation: IAllocationItem): void => {
        setAllocationToModify(allocation);
        setShowEditAllocation(true);
    };

    const deleteAllocationActionButton = (allocation: IAllocationItem): void => {
        setAllocationToModify(allocation);
        setShowDeleteAllocation(true);
    };

    const addTeamCallback = async (somethingModified: boolean): Promise<void> => {
        if (somethingModified) {
            StaffingClient.invalidateCache(cacheContext);
            const newTeams = await fetchTeams(editableOrgs);
            updateTeams(newTeams);
        }
        setShowAddTeam(false);
    };

    const editAllocationCallback = async (somethingModified: boolean): Promise<void> => {
        if (somethingModified) {
            StaffingClient.invalidateCache(cacheContext);
            await fetchAndUpdateAllocations();
        }
        setShowEditAllocation(false);
    };

    const deleteAllocationCallback = async (somethingModified: boolean): Promise<void> => {
        if (somethingModified) {
            StaffingClient.invalidateCache(cacheContext);
            await fetchAndUpdateAllocations();
        }
        setShowDeleteAllocation(false);
    };

    const addTeam = (): void => {
        setShowAddTeam(true);
    };

    const addAllocation = (): void => {
        setShowAddAllocation(true);
    };

    const addAllocationCallback = async (allocationAdded: boolean): Promise<void> => {
        if (allocationAdded) {
            StaffingClient.invalidateCache(cacheContext);
            await fetchAndUpdateAllocations();
        }
        setShowAddAllocation(false);
    };

    const editTeamConclusion = async (modalConclusion: ModalConclusion): Promise<void> => {
        if (modalConclusion === ModalConclusion.Done) {
            StaffingClient.invalidateCache(cacheContext);
            updateTeams(await fetchTeams(editableOrgs));
        }
    };

    const renderAllocation = (showWhat: showAllocationInfoType): JSX.Element => {
        return (
            <>
                <StaffingButtonBar
                    teams={teams[organizationId()]}
                    orgName={organizationName()}
                    addTeam={addTeam}
                    addAllocation={addAllocation}
                    editTeamConclusion={editTeamConclusion}
                    hasOrgEditPermission={hasEditPermission()}
                />
                <SidebarAndContents>
                    <SidebarPane>
                        <StaffingAllocationFilters {...staffingFiltersProps} />
                    </SidebarPane>
                    <ContentPane>
                        <StaffingAllocationMap
                            teams={teams[organizationId()]}
                            orgName={organizationName()}
                            loading={isInitialLoading}
                            showWhat={showWhat}
                            allocations={allocations}
                            employeesPictures={employeesPictures}
                            basicEmployeesInfo={basicEmployeesInfo}
                            hasOrgEditPermission={hasEditPermission()}
                            editAllocationActionButton={editAllocationActionButton}
                            deleteAllocationActionButton={deleteAllocationActionButton}
                        />
                    </ContentPane>
                </SidebarAndContents>
            </>
        );
    };

    const renderServiceMap = (): JSX.Element => {
        return (
            <StaffingServiceMappingFiltersProvider>
                <StaffingButtonBar
                    teams={teams[organizationId()]}
                    orgName={organizationName()}
                    addTeam={addTeam}
                    addService={true}
                    editTeamConclusion={editTeamConclusion}
                    addServiceModalParams={{
                        teams: teams[organizationId()],
                        orgName: organizationName(),
                        teamName: '',
                        allServices: allServices,
                        addServiceCallback: addServiceCallback,
                    }}
                    hasOrgEditPermission={hasEditPermission()}
                />
                <SidebarAndContents>
                    <SidebarPane>
                        <StaffingServiceMapFilters />
                    </SidebarPane>
                    <ContentPane>
                        <StaffingServiceMap
                            teams={teams[organizationId()] || []}
                            orgName={organizationName()}
                            isLoading={
                                isInitialLoading || isAllServicesLoading || isServicesFetching
                            }
                            allServices={allServices}
                            orgServices={orgServices}
                            orgServiceKpis={orgServiceKpis}
                            hasOrgEditPermission={hasEditPermission()}
                            fetchServices={fetchServices}
                            getAllServices={getAllServices}
                        />
                    </ContentPane>
                </SidebarAndContents>
            </StaffingServiceMappingFiltersProvider>
        );
    };

    return (
        <CheckRole
            requiredRolesAny={StaffingAdminRoles}
            hasRequiredRolesAny={[
                (orgPermissions[organizationId()] ?? {})[Role.StaffingOrgRead],
                (orgPermissions[organizationId()] ?? {})[Role.StaffingOrgEdit],
            ]}
            // Force a recalculation of prop arePermissionsChecked at every
            // render to reduce chances of race conditions. This is most
            // useful for the time the page loads. It prevents redirection
            // to 'access denied' page at page load time.
            arePermissionsChecked={((): boolean => isOrgPermissionsChecked)()}
            redirectNotInRole={AccessDeniedURL}>
            <StaffingFiltersContextProvider initFilterValues={initFilters}>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                <div className={margin}>
                    <PageLoadingSpinner
                        label={'Loading allocations...'}
                        ariaLive='assertive'
                        isLoading={isInitialLoading}
                        labelPosition='left'>
                        <Tabs>
                            <TabbedContent tabHeader='Allocation Map'>
                                {renderAllocation('allocation-map')}
                            </TabbedContent>
                            <TabbedContent tabHeader='Heat Map'>
                                {renderAllocation('heat-map')}
                            </TabbedContent>
                            <TabbedContent tabHeader='Service Map'>
                                {renderServiceMap()}
                            </TabbedContent>
                        </Tabs>
                        <CheckRole
                            requiredRolesAny={[Role.StaffingAdminEdit]}
                            hasRequiredRolesAny={[hasEditPermission()]}>
                            <EditAddAllocationModal
                                isVisible={showEditAllocation}
                                editAllocationCallback={editAllocationCallback}
                                allocation={allocationToModify}
                                teams={teams}
                                clouds={clouds}
                                title={'Edit Allocation'}
                                action='edit'
                                orgId={organizationId()}
                                orgName={organizationName()}
                                projects={projects}
                                organizations={editableOrgs}
                            />
                            <AddTeamModal
                                orgName={organizationName()}
                                isVisible={showAddTeam}
                                addTeamCallback={addTeamCallback}
                            />
                            <EditAddAllocationModal
                                isVisible={showAddAllocation}
                                editAllocationCallback={addAllocationCallback}
                                teams={teams}
                                clouds={clouds}
                                title={'Add Allocation'}
                                action='add'
                                orgId={organizationId()}
                                orgName={organizationName()}
                                projects={projects}
                                organizations={editableOrgs}
                            />
                            <DeleteAllocationModal
                                isVisible={showDeleteAllocation}
                                deleteAllocationCallback={deleteAllocationCallback}
                                allocation={allocationToModify}
                            />
                        </CheckRole>
                    </PageLoadingSpinner>
                </div>
            </StaffingFiltersContextProvider>
        </CheckRole>
    );
}
