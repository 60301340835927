import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

export interface FacilitiesManageLandingPageProps {
    match: {
        params: {
            category?: string;
        };
    };
}

export default function FacilitiesManageLandingPage(
    props: FacilitiesManageLandingPageProps,
): JSX.Element {
    const [redirectUrl, setRedirectUrl] = useState<string>();

    useEffect(() => {
        if (props.match.params.category) {
            setRedirectUrl(`/facilities/manage/${props.match.params.category}`);
        } else {
            setRedirectUrl('/facilities/manage/equipment');
        }
    });
    return <>{redirectUrl && <Redirect to={redirectUrl} />}</>;
}
