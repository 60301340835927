import {
    ScreeningStateLabels,
    ScreeningStateType,
} from 'components/screening/common/common-constants';

export interface ICustomerMetricsBackend {
    id: string;
    stateTimeLine: IStateTimeLineObj[];
    documentTimeLines: IDocumentTimeLineObj[];
    // start of Metrics day counts
    nominationDurationCountDays: number;
    nominationContractOwnerDurationCountDays: number;
    nominationNomineeDurationCountDays: number;
    preparationDurationCountDays: number;
    preparationWithNomineeDurationCountDays: number;
    preparationDocumentReceivedDurationCountDays: number;
    preparationSentToUSGDurationCountDays: number;
    submittedDurationCountDays: number;
    submittedSubmittedToUSGDurationCountDays: number;
    submittedReceivedFromUSGDurationCountDays: number;
    submittedAwaitingPolyResultDurationCountDays: number;
    submittedAwaitingMedicalDurationCountDays: number;
    submittedAwaitingAdjudicationDurationCountDays: number;
    adjudicatedDurationCountDays: number;
    adjudicatedInterimDurationCountDays: number;
    adjudicatedFinalDurationCountDays: number;
    indocDurationCountDays: number;
    indocAwaitingNomineeDurationCountDays: number;
    indocAwaitingUSGDurationCountDays: number;
    indocAwaitingNSTDurationCountDays: number;
    // end of Metrics day counts
    completedDateUTCMilliseconds: number;
    withdrawnDateUTCMilliseconds: number;
    nominationNomineeNotAcceptUTCMilliseconds: number;
    nominationContractOwnerDeniedUTCMilliseconds: number;
    preparationRemovedFromProcessingUTCMilliseconds: number;
    adjudicatedDeniedUTCMilliseconds: number;
    isInTerminalState: boolean;
    isAdjudicatedGranted: boolean;
    lastCalculatedState: {
        stateType: CustomerMetricStateType;
        stateValue: string; //Adjudicated;
        timeStampUTCMilliseconds: number;
    };
    lastModified: {
        by: string;
        atUtc: number;
    };
    nominated: {
        by: string;
        atUtc: number;
    };
}

enum CustomerMetricStateType {
    stateType = 'stateType',
    subStateType = 'subStateType',
}
interface IStateTimeLineObj {
    stateType: CustomerMetricStateType;
    stateValue: string;
    timeStampUTCMilliseconds: number;
}

interface IDocumentStateTimeLineObj {
    documentEvent: string;
    timeStampUTCMilliseconds: number;
}

interface IDocumentTimeLineObj {
    fileName: string;
    stateTimeLine: IDocumentStateTimeLineObj[];
    isLocked: boolean;
    isDeleted: boolean;
    lastCalculatedDocumentState: {
        documentEvent: string;
        timeStampUTCMilliseconds: number;
    };
    documentRequestDurationCountDays: number;
    documentRequestUpdateDurationCountDays: number;
    documentRequestDeleteDurationCountDays: number;
    documentRequestDeleteUTCMilliseconds: number;
    documentCreatedDurationCountDays: number;
    documentUpdatedDurationCountDays: number;
    documentLockedDurationCountDays: number;
    documentUnlockedDurationCountDays: number;
    documentLockedUTCMilliseconds: number;
}

// Customer Metrics
export enum ScreeningStateTypeMetrics {
    nomination = 'nominationDurationCountDays',
    nominationContractOwner = 'nominationContractOwnerDurationCountDays',
    nominationNominee = 'nominationNomineeDurationCountDays',
    preparation = 'preparationDurationCountDays',
    preparationWithNominee = 'preparationWithNomineeDurationCountDays',
    preparationDocumentReceived = 'preparationDocumentReceivedDurationCountDays',
    preparationSentToUSG = 'preparationSentToUSGDurationCountDays',
    submitted = 'submittedDurationCountDays',
    submittedSubmittedToUSG = 'submittedSubmittedToUSGDurationCountDays',
    submittedReceivedFromUSG = 'submittedReceivedFromUSGDurationCountDays',
    submittedAwaitingPolyResult = 'submittedAwaitingPolyResultDurationCountDays',
    submittedAwaitingMedical = 'submittedAwaitingMedicalDurationCountDays',
    submittedAwaitingAdjudication = 'submittedAwaitingAdjudicationDurationCountDays',
    adjudicated = 'adjudicatedDurationCountDays',
    adjudicatedInterim = 'adjudicatedInterimDurationCountDays',
    adjudicatedFinal = 'adjudicatedFinalDurationCountDays',
    indoc = 'indocDurationCountDays',
    indocAwaitingNominee = 'indocAwaitingNomineeDurationCountDays',
    indocAwaitingUSG = 'indocAwaitingUSGDurationCountDays',
    indocAwaitingNST = 'indocAwaitingNSTDurationCountDays',
}
// Fields from CustomerMetrics api for each "state"
// the first index of each array is the total number of days the screening was in EX: 'Nomination' State and rest are of
// the number of days in the substate ex: Preparation Sent To USG
const nominationCustomerMetricSubstates: string[] = [
    ScreeningStateTypeMetrics.nomination,
    ScreeningStateTypeMetrics.nominationContractOwner,
    ScreeningStateTypeMetrics.nominationNominee,
];
const preparationCustomerMetricSubstates: string[] = [
    ScreeningStateTypeMetrics.preparation,
    ScreeningStateTypeMetrics.preparationWithNominee,
    ScreeningStateTypeMetrics.preparationDocumentReceived,
    ScreeningStateTypeMetrics.preparationSentToUSG,
];
const submittedCustomerMetricSubstates: string[] = [
    ScreeningStateTypeMetrics.submitted,
    ScreeningStateTypeMetrics.submittedSubmittedToUSG,
    ScreeningStateTypeMetrics.submittedReceivedFromUSG,
    ScreeningStateTypeMetrics.submittedAwaitingPolyResult,
    ScreeningStateTypeMetrics.submittedAwaitingMedical,
    ScreeningStateTypeMetrics.submittedAwaitingAdjudication,
];
const adjudicatedCustomerMetricSubstates: string[] = [
    ScreeningStateTypeMetrics.adjudicated,
    ScreeningStateTypeMetrics.adjudicatedInterim,
    ScreeningStateTypeMetrics.adjudicatedFinal,
];
const indocCustomerMetricSubstates: string[] = [
    ScreeningStateTypeMetrics.indoc,
    ScreeningStateTypeMetrics.indocAwaitingNominee,
    ScreeningStateTypeMetrics.indocAwaitingUSG,
    ScreeningStateTypeMetrics.indocAwaitingNST,
];

export const substatesMetrics = {
    Nomination: nominationCustomerMetricSubstates,
    Preparation: preparationCustomerMetricSubstates,
    Submitted: submittedCustomerMetricSubstates,
    Indoc: indocCustomerMetricSubstates,
    Adjudicated: adjudicatedCustomerMetricSubstates,
};

// maps key to Substate Label
export const metricSubStatesNameMapping = {
    nominationDurationCountDays: ScreeningStateLabels.WaitingForContractOwnerApproval, //ScreeningStateLabels[ScreeningStateType.Nomination],
    nominationContractOwnerDurationCountDays: ScreeningStateLabels.WaitingForContractOwnerApproval,
    nominationNomineeDurationCountDays:
        ScreeningStateLabels[ScreeningStateType.ContractOwnerApproved],
    preparationDurationCountDays: ScreeningStateLabels[ScreeningStateType.Preparation],
    preparationWithNomineeDurationCountDays: ScreeningStateLabels[ScreeningStateType.WithNominee],
    preparationDocumentReceivedDurationCountDays: ScreeningStateLabels[ScreeningStateType.WithNST],
    preparationSentToUSGDurationCountDays:
        ScreeningStateLabels[ScreeningStateType.ApprovalRequestedFromUSG],
    submittedDurationCountDays: ScreeningStateLabels[ScreeningStateType.Submitted],
    submittedSubmittedToUSGDurationCountDays:
        ScreeningStateLabels[ScreeningStateType.SubmittedToUSG],
    submittedReceivedFromUSGDurationCountDays:
        ScreeningStateLabels[ScreeningStateType.ReceivedFromUSG],
    submittedAwaitingPolyResultDurationCountDays:
        ScreeningStateLabels[ScreeningStateType.AwaitingPoly],
    submittedAwaitingMedicalDurationCountDays:
        ScreeningStateLabels[ScreeningStateType.AwaitingMedical],
    submittedAwaitingAdjudicationDurationCountDays:
        ScreeningStateLabels[ScreeningStateType.AwaitingAdjudication],
    adjudicatedDurationCountDays: ScreeningStateLabels[ScreeningStateType.Adjudicated],
    adjudicatedInterimDurationCountDays: ScreeningStateLabels[ScreeningStateType.Interim],
    adjudicatedFinalDurationCountDays: ScreeningStateLabels[ScreeningStateType.Final],
    indocDurationCountDays: ScreeningStateLabels[ScreeningStateType.Indoc],
    indocAwaitingNomineeDurationCountDays:
        ScreeningStateLabels[ScreeningStateType.IndocAwaitingNominee],
    indocAwaitingUSGDurationCountDays: ScreeningStateLabels[ScreeningStateType.IndocAwaitingUSG],
    indocAwaitingNSTDurationCountDays: ScreeningStateLabels[ScreeningStateType.IndocAwaitingNST],
};
