import { Label, mergeStyles, Separator } from '@fluentui/react';
import React, { useMemo } from 'react';
import {
    FormResponse,
    FormElement,
    StringDictionary,
    TableOption,
    ChoiceOptions,
} from 'components/forms/forms-common';
import { globalSeparatorStyles, globalStyles } from 'assets/styles/global-styles';
import { isGUID } from 'utils/string-utils';
import { countryPhoneCodes } from 'components/forms/country-code';

const labelValueBoxStyles = mergeStyles({
    ':hover': {
        background: 'rgba(0,0,0,.05)',
    },
});

const labelValueStyles = mergeStyles({
    display: 'flex',
    gap: '3rem',
    alignItems: 'center',
    padding: '.5rem 0 .5rem .75rem',
});

const separatorStyles = mergeStyles(globalSeparatorStyles, {
    padding: '0',
    height: '1px',
});

const tableStyles = mergeStyles({
    padding: '1rem 0 1rem 2rem',
});

const h3Style = mergeStyles(globalStyles.boldFont, globalStyles.mediumLargeFont);

type FormsReviewerProps = {
    formResponse: FormResponse;
    isReviewPage?: boolean;
};

export function FormsReviewer(props: FormsReviewerProps): JSX.Element {
    const { formResponse, isReviewPage } = props;

    const labelValuePair = (label: string, value: string, country?: string): JSX.Element => {
        const id = label + value;
        return (
            <div className={labelValueBoxStyles}>
                <div className={labelValueStyles}>
                    <Label style={{ flexBasis: '25%', maxWidth: '18.7rem' }} htmlFor={id}>
                        {label}
                    </Label>
                    {!!country && <span id={id + country}>{country}</span>}
                    <span id={id}>{value}</span>
                </div>
                <Separator className={separatorStyles} />
            </div>
        );
    };

    const createTableReviewEntry = (element: FormElement): JSX.Element => {
        const values = (Array.isArray(element.value) ? element.value : []) as StringDictionary[];
        if (!values.length || !element.options?.length) {
            return <>{labelValuePair(element.label, '')}</>;
        }
        return (
            <div>
                <Label style={{ fontWeight: 700 }}>{element.label}</Label>
                {values.map((value, index) => {
                    return (
                        <div key={index} className={tableStyles}>
                            <h3>No. {index + 1}</h3>
                            {(element.options as TableOption[]).map((option) => {
                                const optionValue = value[option.key] ?? '';
                                return <>{labelValuePair(option.text, optionValue)}</>;
                            })}
                        </div>
                    );
                })}
            </div>
        );
    };
    const createDateRangeReviewEntry = (element: FormElement): JSX.Element => {
        const value = (Array.isArray(element.value) ? element.value : []) as string[];

        return (
            <>
                {labelValuePair(element.rangeLabels?.start ?? element.label, value[0] ?? '')}
                {labelValuePair(element.rangeLabels?.end ?? element.label, value[1] ?? '')}
            </>
        );
    };
    const createReviewEntry = (element: FormElement): JSX.Element => {
        if (!element.visible || element.type === 'info') {
            return <></>;
        }
        if (element.type === 'table') {
            return createTableReviewEntry(element);
        }
        if (element.type === 'daterange') {
            return <>{createDateRangeReviewEntry(element)}</>;
        }
        let value = (element.value as string) ?? '';
        if (element.type === 'checkbox') {
            value = (Array.isArray(element.value) ? element.value : []).join(', ');
        }
        if (element.type === 'radio' && isGUID(element.value as string)) {
            const x = element.options as ChoiceOptions[];
            if (x !== undefined) {
                value = x.find((option: ChoiceOptions) => option.key === value)?.text ?? value;
            }
        }
        if (element.type === 'textfield' && element.validatorOptions?.value === 'phone') {
            const val = element.value as string;
            if (val) {
                const split = val.split('.');
                if (split.length === 2) {
                    const country = countryPhoneCodes.find((x) => x.code === split[0])?.name ?? '';
                    value = `+${split[0]} ${split[1]}`;
                    return labelValuePair(element.label, value, country);
                }
            }
        }
        if (element.type === 'people' || element.type === 'person') {
            const values = Array.isArray(element.value) ? (element.value as string[]) : [];
            const texts = values.map((str) => {
                const split = str ? str.split('-') : [];
                if (split.length === 4) {
                    return `${split[0]} (${split[2]})`;
                }
                return str;
            });
            value = texts.join(', ');
        }
        return <>{labelValuePair(element.label, value)}</>;
    };

    const sections = useMemo(() => {
        return formResponse.sections.filter(
            (section) => section.id < formResponse.sections.length && section.visible,
        );
    }, [formResponse]);

    return (
        <>
            {isReviewPage && (
                <>
                    <strong>Status: </strong> Draft (not submitted)
                </>
            )}
            {sections.map((section) => (
                <div key={section.id} style={{ margin: '1.5rem 0' }}>
                    <h3 className={h3Style}>
                        {section.id === 0 ? 'Start Here' : section.label ?? section.id}
                    </h3>
                    {section.elements.map((element, index) => {
                        return (
                            <React.Fragment key={element.id + index}>
                                {createReviewEntry(element)}
                            </React.Fragment>
                        );
                    })}
                </div>
            ))}
        </>
    );
}
