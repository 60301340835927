import {
    IButtonStyles,
    IIconProps,
    IconButton,
    Modal,
    mergeStyleSets,
    getTheme,
    FontWeights,
    PrimaryButton,
    DefaultButton,
} from '@fluentui/react';
import { GenericForm, GenericFormRecord, formRespondBaseUrl } from 'clients/forms-client';
import { genericFormRecordFormStates } from 'components/forms/forms-common';
import React, { useCallback, useId } from 'react';
import { useHistory } from 'react-router-dom';

type FormsDecisionModalProps = {
    selectedForm: GenericForm;
    records?: GenericFormRecord[];
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

export function FormsDecisionModal(props: FormsDecisionModalProps): JSX.Element {
    const { selectedForm, records, isOpen, setIsOpen } = props;
    const history = useHistory();

    const navigateToDraft = useCallback((): void => {
        const draft = records?.find(
            (x) =>
                x.name === selectedForm.name &&
                x.formState.toLowerCase() === genericFormRecordFormStates.draft,
        );
        if (draft) {
            history.push(`${formRespondBaseUrl}/${draft?.name}/${draft?.id}`);
        }
    }, [history, records, selectedForm.name]);

    const navigateToNew = useCallback((): void => {
        history.push(`${formRespondBaseUrl}/${selectedForm.name}`);
    }, [history, selectedForm.name]);

    const titleId = useId();
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    return (
        <Modal isOpen={isOpen} isBlocking={false} onDismiss={(): void => setIsOpen(false)}>
            <div className={modalStyles.header}>
                <h2 className={modalStyles.heading} id={titleId}>
                    Form Decision
                </h2>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel='Close popup modal'
                    onClick={(): void => setIsOpen(false)}
                />
            </div>
            <div className={modalStyles.body}>
                <p>
                    You have an existing draft for {selectedForm.title}.<br />
                    Would you like to continue with your draft or create a new form?
                </p>
                <p className={styles.createNewFormWarning}>
                    Note: Creating a new form will delete the existing draft for this form.
                </p>
            </div>
            <div className={modalStyles.footer}>
                <PrimaryButton onClick={navigateToDraft}>Continue with draft</PrimaryButton>
                <DefaultButton onClick={navigateToNew}>Create new form</DefaultButton>
            </div>
        </Modal>
    );
}

const theme = getTheme();
const modalStyles = mergeStyleSets({
    header: [
        {
            fontSize: '20px',
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '16px 24px 20px 24px',
        },
    ],
    heading: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        fontSize: 'inherit',
        margin: '0',
    },
    body: {
        margin: ' 24px 100px 24px 24px',
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '24px',
        gap: '8px',
    },
});

const styles = mergeStyleSets({
    createNewFormWarning: {
        fontWeight: 'bold',
    },
});

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
