import React, { ReactNode } from 'react';
import {
    IGroup,
    GroupRole,
    IGroupActivity,
    GroupActivityEvent,
    GroupActivityObjectType,
    GroupActivityEventUpdateGroupSettings,
    GroupType,
    SensitivityLevel,
    PrimaryCategorization,
    Division,
} from 'clients/group-client';
import { dateToFormattedTimeString } from 'utils/time-utils';
import { Icon, Stack } from '@fluentui/react';
import { ManageGroupsVariableContextType } from 'components/groups/manage-groups/contexts/manage-groups-variable-context';
import { Dictionary, IconNames } from 'assets/constants/global-constants';
import DisplayActivitySubjectOrObject from 'components/groups/manage-group/timeline/display-activity-subject-or-object';
import BoldFont from 'components/common/misc/bold-font';
import {
    False,
    True,
    addedDependencyContent,
    getBusinessJustification,
    getDisplayStringForGroupRuleActivity,
    groupRuleViolations,
    hasGroupRuleViolations,
    removedDependencyContent,
    renderAdditionalContent,
    strongLeftRight,
    strong,
    styles,
} from 'components/groups/manage-group/timeline/timeline-utils';
import Indent from 'components/common/misc/indent';
import { camelCaseToTitleCase, toTitleCase } from 'utils/string-utils';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';

interface EventProps {
    key: string;
    group: IGroup;
    activity: IGroupActivity;
    violationIdNameMap: Map<string, string>;
    groupsContext: ManageGroupsVariableContextType;
    isFeatureFlagGroupsPOCEnabled: boolean;
    isFeatureFlagGroupsEnableDynamic: boolean;
}

export function timelineEvent(props: EventProps): JSX.Element | void {
    function groupSettingStr(strongText: string, currentValue: string): JSX.Element {
        return textSubject(
            <>
                <span className={styles.strong}>{strongText} </span>setting was{' '}
                {currentValue === False ? 'disabled' : 'enabled'} by
                <span className={styles.padAfterBy}></span>
            </>,
        );
    }

    const getEnumVal = (enumKey: string, previousVal: string, currentVal: string): string[] => {
        let oldVal;
        let newVal;
        switch (enumKey) {
            case GroupActivityEventUpdateGroupSettings.groupType:
                oldVal = GroupType[previousVal as keyof typeof GroupType];
                newVal = GroupType[currentVal as keyof typeof GroupType];
                break;
            case GroupActivityEventUpdateGroupSettings.sensitivityLevel:
                oldVal = SensitivityLevel[previousVal as keyof typeof SensitivityLevel];
                newVal = SensitivityLevel[currentVal as keyof typeof SensitivityLevel];
                break;
            case GroupActivityEventUpdateGroupSettings.primaryCategorization:
                oldVal = PrimaryCategorization[previousVal as keyof typeof PrimaryCategorization];
                newVal = PrimaryCategorization[currentVal as keyof typeof PrimaryCategorization];
                break;
            case GroupActivityEventUpdateGroupSettings.division:
                oldVal = Division[previousVal as keyof typeof Division];
                newVal = Division[currentVal as keyof typeof Division];
                break;
            default:
                oldVal = '';
                newVal = '';
                break;
        }
        return [oldVal, newVal];
    };

    const memberAddJustification = (
        justification: string,
    ): { memberAddType: string; isApproval: boolean } => {
        let memberAddType;
        let isApproval = false;
        switch (justification) {
            case 'manually approved':
                memberAddType = 'was approved by';
                isApproval = true;
                break;
            case 'auto-approved':
                memberAddType = 'was auto-approved by';
                isApproval = true;
                break;
            default:
                memberAddType = 'was added by';
                break;
        }
        return { memberAddType, isApproval };
    };

    function displayGroupUpdate(): JSX.Element {
        let mainContent: JSX.Element;
        let additionalContent: JSX.Element = <></>;
        let icon: string;

        switch (props.activity.metadata.groupSettingName) {
            case GroupActivityEventUpdateGroupSettings.requireSponsor as string: // type cast is safe
                mainContent = groupSettingStr(
                    'Require Sponsor',
                    props.activity.metadata.groupSettingCurrentValue,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.requireJustification as string: // type cast is safe
                mainContent = groupSettingStr(
                    'Require Business Justification',
                    props.activity.metadata.groupSettingCurrentValue,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.requireMemberRemovalJustification as string: // type cast is safe
                mainContent = groupSettingStr(
                    'Require Business Justification For Member Removal',
                    props.activity.metadata.groupSettingCurrentValue,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.allowJoin as string: // type cast is safe
                mainContent = groupSettingStr(
                    'Allow Join Requests',
                    props.activity.metadata.groupSettingCurrentValue,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.enableHidden as string: //type cast is safe
                mainContent = groupSettingStr(
                    'Hide group from members',
                    props.activity.metadata.groupSettingCurrentValue,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.enableDynamic as string: //type cast is safe
                if (!props.isFeatureFlagGroupsEnableDynamic) {
                    return <></>;
                }

                mainContent = groupSettingStr(
                    'Set Group as Dynamic',
                    props.activity.metadata.groupSettingCurrentValue,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.allowMemberNomination as string: // type cast is safe
                mainContent = groupSettingStr(
                    'Allow Member Nominations',
                    props.activity.metadata.groupSettingCurrentValue,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.autoApproveJoin as string: // type cast is safe
                mainContent = groupSettingStr(
                    'Auto-Approve Join Requests',
                    props.activity.metadata.groupSettingCurrentValue,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.enableMemberNoncomplianceNotification as string: // type cast is safe
                mainContent = groupSettingStr(
                    'Member Non-Compliance Notifications',
                    props.activity.metadata.groupSettingCurrentValue,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.enableMemberRoleNotification as string: // type cast is safe
                mainContent = groupSettingStr(
                    'Member Role Change Notifications',
                    props.activity.metadata.groupSettingCurrentValue,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.memberNonComplianceNotificationDays as string: // type cast is safe
                mainContent = textSubject(
                    <>
                        {strong('Member Non-Compliance Notification Recurrence')}
                        &nbsp;setting was modified from (
                        {props.activity.metadata.groupSettingPreviousValue}) to (
                        {props.activity.metadata.groupSettingCurrentValue})
                    </>,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.enableWelcomeMessage as string: // type cast is safe
                mainContent = groupSettingStr(
                    'Enable Welcome Messages',
                    props.activity.metadata.groupSettingCurrentValue,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.enableOffboardMessage as string: // type cast is safe
                mainContent = groupSettingStr(
                    'Enable Offboard Messages',
                    props.activity.metadata.groupSettingCurrentValue,
                );
                break;
            case GroupActivityEventUpdateGroupSettings.groupName as string: // type cast is safe
                mainContent = (
                    <span className={styles.padLeftAndRight}>
                        Group Name was changed from{' '}
                        <span className={styles.strong}>
                            {props.activity.metadata.groupSettingPreviousValue}
                        </span>{' '}
                        to{' '}
                        <span className={styles.strong}>
                            {props.activity.metadata.groupSettingCurrentValue}
                        </span>
                    </span>
                );
                break;
            case GroupActivityEventUpdateGroupSettings.groupDescription as string: // type cast is safe
                mainContent = (
                    <span className={styles.padLeftAndRight}>Group Description was edited</span>
                );
                break;
            case GroupActivityEventUpdateGroupSettings.groupType as string:
            case GroupActivityEventUpdateGroupSettings.sensitivityLevel as string:
            case GroupActivityEventUpdateGroupSettings.primaryCategorization as string:
            case GroupActivityEventUpdateGroupSettings.division as string:
                const [oldVal, newVal] = getEnumVal(
                    props.activity.metadata.groupSettingName,
                    props.activity.metadata.groupSettingPreviousValue,
                    props.activity.metadata.groupSettingCurrentValue,
                );
                mainContent = (
                    <>
                        Group Metadata{' '}
                        {camelCaseToTitleCase(props.activity.metadata.groupSettingName)} was changed
                        from&nbsp;<span className={styles.strong}>{oldVal}</span>&nbsp;to&nbsp;
                        <span className={styles.strong}>{newVal}</span>&nbsp;by&nbsp;
                        <CoreEmployeeHoverCardFromPrincipalId
                            principalId={props.activity.subject.value}
                        />
                    </>
                );
                break;
            case GroupActivityEventUpdateGroupSettings.cvpApprovedBy as string:
                mainContent = (
                    <>
                        CVP Approved By was changed from&nbsp;
                        <CoreEmployeeHoverCardFromPrincipalId
                            principalId={props.activity.metadata.groupSettingPreviousValue}
                        />
                        &nbsp;to&nbsp;
                        <CoreEmployeeHoverCardFromPrincipalId
                            principalId={props.activity.metadata.groupSettingCurrentValue}
                        />
                        &nbsp;by&nbsp;
                        <DisplayActivitySubjectOrObject
                            id={props.activity.id}
                            type={props.activity.subject.type}
                            value={props.activity.subject.value}
                            groupId={props.group.id}
                        />
                    </>
                );
                break;
            case GroupActivityEventUpdateGroupSettings.agcApprovedBy as string:
                mainContent = (
                    <>
                        AGC Approved By was changed from&nbsp;
                        <CoreEmployeeHoverCardFromPrincipalId
                            principalId={props.activity.metadata.groupSettingPreviousValue}
                        />
                        &nbsp;to&nbsp;
                        <CoreEmployeeHoverCardFromPrincipalId
                            principalId={props.activity.metadata.groupSettingCurrentValue}
                        />
                        &nbsp;by&nbsp;
                        <DisplayActivitySubjectOrObject
                            id={props.activity.id}
                            type={props.activity.subject.type}
                            value={props.activity.subject.value}
                            groupId={props.group.id}
                        />
                    </>
                );
                break;
            default:
                mainContent = (
                    <>
                        Group setting&nbsp;
                        {props.activity.metadata.groupSettingName}
                        &nbsp;updated by&nbsp;
                        <DisplayActivitySubjectOrObject
                            id={props.activity.id}
                            type={props.activity.subject.type}
                            value={props.activity.subject.value}
                            groupId={props.group.id}
                        />
                    </>
                );
        }

        additionalContent = renderAdditionalContent({
            activity: props.activity,
            desiredTypes: [
                GroupActivityObjectType.AddedBlockListPersonnelId,
                GroupActivityObjectType.RemovedBlockListPersonnelId,
            ],
            groupId: props.group.id,
            groupsContext: props.groupsContext,
        });

        switch (props.activity?.metadata?.groupSettingCurrentValue as string) {
            case True:
                icon = IconNames.Add;
                break;
            case False:
                icon = IconNames.Remove;
                break;
            default:
                icon = IconNames.Switch as string;
        }

        return displayLine({
            icon,
            mainContent,
            additionalContent,
            showTime: true,
        });
    }

    // {text} {subject}
    function textSubject(text: ReactNode): JSX.Element {
        const { subject } = props.activity;
        return (
            <>
                <span className={styles.padRightOnly}>{text}</span>
                <DisplayActivitySubjectOrObject
                    id={props.activity.id}
                    type={subject.type}
                    value={subject.value}
                    groupId={props.group.id}
                />
            </>
        );
    }

    // {directObject} {text}
    function directObjectText(text: ReactNode): JSX.Element {
        const { directObject } = props.activity;
        return (
            <>
                <DisplayActivitySubjectOrObject
                    id={props.activity.id}
                    type={directObject.type}
                    value={directObject.value}
                    groupId={props.group.id}
                />
                <span className={styles.padLeftOnly}>{text}</span>
            </>
        );
    }

    // {content1} {directObject} {content2} {subject}
    function textDirectObjectTextSubject(content1: ReactNode, content2?: ReactNode): JSX.Element {
        const { subject, directObject } = props.activity;
        return (
            <>
                <span className={styles.padLeftAndRight}>{content1}</span>
                <DisplayActivitySubjectOrObject
                    id={props.activity.id}
                    type={directObject.type}
                    value={directObject.value}
                    groupId={props.group.id}
                />
                {!!content2 && <span className={styles.padLeftAndRight}>{content2}</span>}
                <DisplayActivitySubjectOrObject
                    id={props.activity.id}
                    type={subject.type}
                    value={subject.value}
                    groupId={props.group.id}
                />
            </>
        );
    }

    // {directObject} {content1} {subject} {content2}
    function directObjTextSubjText(content1: ReactNode, content2?: ReactNode): JSX.Element {
        const { subject, directObject } = props.activity;
        return (
            <>
                <DisplayActivitySubjectOrObject
                    id={props.activity.id}
                    type={directObject.type}
                    value={directObject.value}
                    groupId={props.group.id}
                />
                <span className={styles.padLeftAndRight}>{content1}</span>
                <DisplayActivitySubjectOrObject
                    id={props.activity.id}
                    type={subject.type}
                    value={subject.value}
                    groupId={props.group.id}
                />
                {!!content2 && <span className={styles.padLeftOnly}>{content2}</span>}
            </>
        );
    }

    // {text} was downloaded by {subject}
    function downloadReport(text: string): JSX.Element {
        return displayLine({
            icon: IconNames.Download,
            mainContent: textSubject(
                <>
                    <span className={styles.strong}>{text}</span>
                    &nbsp;was downloaded by&nbsp;
                </>,
            ),
            showTime: true,
        });
    }

    function updateGroupMember(): JSX.Element | undefined {
        const {
            groupMemberRoleBefore,
            groupMemberRole,
            justification,
            justificationBefore,
            sponsor,
            sponsorBefore,
            notes,
            notesBefore,
            groupMemberStatusBefore,
            groupMemberStatus,
        } = props.activity.metadata;

        const elements: JSX.Element[] = [];
        const hasFailedGroupRuleViolations = hasGroupRuleViolations(props.activity);

        groupMemberRole !== groupMemberRoleBefore &&
            elements.push(
                <div key={'role'}>
                    {displayUpdateGroupMemberMetaData({
                        fieldName: 'Role',
                        type: MetadataValueType.Text,
                        value: toTitleCase(groupMemberRole),
                        valueBefore: toTitleCase(groupMemberRoleBefore),
                    })}
                </div>,
            );

        justification !== justificationBefore &&
            elements.push(
                <div key={'justification'}>
                    {displayUpdateGroupMemberMetaData({
                        fieldName: 'Justification',
                        type: MetadataValueType.Text,
                        value: justification,
                        valueBefore: justificationBefore,
                    })}
                </div>,
            );

        sponsor !== sponsorBefore &&
            elements.push(
                <div key={'sponsor'}>
                    {displayUpdateGroupMemberMetaData({
                        fieldName: 'Sponsor',
                        type: MetadataValueType.Person,
                        value: sponsor,
                        valueBefore: sponsorBefore,
                    })}
                </div>,
            );

        notes !== notesBefore &&
            elements.push(
                <div key={'notes'}>
                    {displayUpdateGroupMemberMetaData({
                        fieldName: 'Notes',
                        type: MetadataValueType.Text,
                        value: notes,
                        valueBefore: notesBefore,
                    })}
                </div>,
            );

        // Show non-complaint activity if member has failed violations AND one of the following conditions:
        // 1. groupMemberStatus and groupMemberStatusBefore do not exist
        // 2. groupMemberStatus and groupMemberStatusBefore exist, and previous role is different
        if (
            hasFailedGroupRuleViolations &&
            ((groupMemberStatus === undefined && groupMemberStatusBefore === undefined) ||
                groupMemberStatus !== groupMemberStatusBefore)
        ) {
            elements.push(
                <div key='violations'>
                    {displayLine({
                        icon: IconNames.UserWarning,
                        mainContent: directObjectText(
                            <>&nbsp;became not compliant with the following rule(s)</>,
                        ),
                        additionalContent: displayGroupRuleViolations(''),
                        showTime: true,
                    })}
                </div>,
            );
        }

        // Show complaint activity if member has no failed violations AND one of the following conditions:
        // 1. groupMemberStatus and groupMemberStatusBefore do not exist, and no previous role
        // 2. groupMemberStatus and groupMemberStatusBefore exist, and previous role is different
        if (
            !hasFailedGroupRuleViolations &&
            ((!groupMemberRoleBefore &&
                groupMemberStatus === undefined &&
                groupMemberStatusBefore === undefined) ||
                groupMemberStatus !== groupMemberStatusBefore)
        ) {
            elements.push(
                <div key={'compliance'}>
                    {displayLine({
                        icon: IconNames.UserFollowed,
                        mainContent: directObjectText(' is now compliant'),
                        showTime: true,
                    })}
                </div>,
            );
        }

        if (elements.length > 0) {
            return <div key={`updates-${props.activity.id}`}>{elements}</div>;
        }
    }

    enum MetadataValueType {
        Text,
        Person,
    }

    interface IMetadataParams {
        fieldName: string;
        type: MetadataValueType;
        value: string;
        valueBefore: string;
    }

    function displayUpdateGroupMemberMetaData(params: IMetadataParams): JSX.Element {
        function showValue(type: MetadataValueType, value: string): JSX.Element {
            switch (type) {
                case MetadataValueType.Person:
                    return <CoreEmployeeHoverCardFromPrincipalId principalId={value} />;
                case MetadataValueType.Text:
                default:
                    return strongLeftRight(value);
            }
        }

        if (!!params.value && !params.valueBefore) {
            // Value of field set. Had no value before
            return displayLine({
                icon: IconNames.AddToShoppingList,
                mainContent: textDirectObjectTextSubject(
                    <>{strongLeftRight(params.fieldName)} for</>,
                    <>
                        <Stack horizontal verticalAlign='center'>
                            &nbsp;was set to&nbsp;{showValue(params.type, params.value)}
                            &nbsp;by&nbsp;
                        </Stack>
                    </>,
                ),
                showTime: true,
            });
        } else if (!params.value && !!params.valueBefore) {
            // Value of field cleared. Had some value before
            return displayLine({
                icon: IconNames.RemoveFromShoppingList,
                mainContent: textDirectObjectTextSubject(
                    <>{strongLeftRight(params.fieldName)} for</>,
                    <>&nbsp;was cleared by&nbsp;</>,
                ),
                showTime: true,
            });
        } else if (!!params.value && !!params.valueBefore && params.value !== params.valueBefore) {
            // Value of field changed.
            return displayLine({
                icon: IconNames.ChangeEntitlements,
                mainContent: textDirectObjectTextSubject(
                    <>{strongLeftRight(params.fieldName)} for</>,
                    <Stack horizontal verticalAlign='center'>
                        &nbsp;was changed from&nbsp;
                        {showValue(params.type, params.valueBefore)}
                        &nbsp;to&nbsp;
                        {showValue(params.type, params.value)}
                        &nbsp;by&nbsp;
                    </Stack>,
                ),
                showTime: true,
            });
        } else {
            return <></>;
        }
    }

    function createGroupRule(icon: string, text: string): JSX.Element {
        return displayLine({
            icon,
            mainContent: textSubject(
                <>
                    Policy for
                    {strongLeftRight(getDisplayStringForGroupRuleActivity(props.activity))}
                    {text}
                </>,
            ),
            additionalContent: <>{addedDependencyContent(props.groupsContext, props.activity)}</>,
            showTime: true,
        });
    }

    function updateGroupRule(icon: string, text: string): JSX.Element {
        return displayLine({
            icon,
            // The following works for CREATE_GROUP_RULE
            mainContent: textSubject(
                <>
                    Policy for
                    {strongLeftRight(getDisplayStringForGroupRuleActivity(props.activity))}
                    {text}
                </>,
            ),
            additionalContent: renderAdditionalContent({
                activity: props.activity,
                desiredTypes: [
                    GroupActivityObjectType.AddedDependentGroupId,
                    GroupActivityObjectType.RemovedDependentGroupId,
                    GroupActivityObjectType.AddedAllowListPersonnelId,
                    GroupActivityObjectType.RemovedAllowListPersonnelId,
                    GroupActivityObjectType.GracePeriod,
                    GroupActivityObjectType.EffectiveStartTimestampUTC,
                    GroupActivityObjectType.EffectiveEndTimestampUTC,
                    GroupActivityObjectType.GroupRuleName,
                    GroupActivityObjectType.GroupRuleDescription,
                    GroupActivityObjectType.SuspendGroupRuleEnforcement,
                    GroupActivityObjectType.SuspendGroupRuleNotifications,
                ],
                groupId: props.group.id,
                groupsContext: props.groupsContext,
            }),
            showTime: true,
        });
    }

    function deleteGroupRule(icon: string, text: string): JSX.Element {
        return displayLine({
            icon,
            mainContent: textSubject(
                <>
                    Policy for
                    {strongLeftRight(getDisplayStringForGroupRuleActivity(props.activity))}
                    {text}
                </>,
            ),
            additionalContent: removedDependencyContent(props.groupsContext, props.activity),
            showTime: true,
        });
    }

    function noncompliantNoticeGroupRule(icon: string): JSX.Element {
        return displayLine({
            icon,
            mainContent: textSubject(
                <>Policy notifications for not compliant and grace period members sent by</>,
            ),
            additionalContent: <></>,
            showTime: true,
        });
    }

    function Separator(): JSX.Element {
        return <>;&nbsp;</>;
    }

    function removeGroupMember(): JSX.Element {
        const { justification } = props.activity.metadata;
        const businessJustification = getBusinessJustification(props.activity);
        if (
            props.activity.directObject.type === props.activity.subject.type &&
            props.activity.directObject.value === props.activity.subject.value
        ) {
            return displayLine({
                icon: IconNames.UserRemove,
                mainContent: directObjectText('left the group'),
                showTime: true,
            });
        } else {
            return displayLine({
                icon: IconNames.UserRemove,
                mainContent: directObjTextSubjText('was removed by'),
                additionalContent: (
                    <Indent>
                        <Stack horizontal verticalAlign='center' horizontalAlign='start' wrap>
                            {!!businessJustification && (
                                <>Business Justification:&nbsp;{businessJustification.value}</>
                            )}
                            {!!businessJustification && !!justification && <Separator />}
                            {justification && (
                                <>&nbsp;Justification:&nbsp;{strong(justification)}</>
                            )}
                        </Stack>
                    </Indent>
                ),
                showTime: true,
            });
        }
    }

    function addGroupMember(): JSX.Element {
        let compliantMsg = '';
        let typeOfMembershipMsg = '';
        const { groupMemberRole, justification } = props.activity.metadata;
        const { memberAddType, isApproval } = memberAddJustification(justification);

        switch (groupMemberRole) {
            case GroupRole.OWNER as string: // type cast is safe
                typeOfMembershipMsg = ' as an owner of the group';
                break;
            case GroupRole.MANAGER as string: // type cast is safe
                typeOfMembershipMsg = ' as a manager of the group';
                break;
            default:
                break;
        }

        const hasViolations = hasGroupRuleViolations(props.activity);

        if (!hasViolations) {
            compliantMsg = 'and is compliant';
        }

        return displayLine({
            icon: IconNames.AddFriend,
            mainContent: directObjTextSubjText(
                memberAddType,
                <>
                    {typeOfMembershipMsg}&nbsp;{compliantMsg}
                </>,
            ),
            additionalContent: displaySponsorJustificationNotesViolations(isApproval),
            showTime: true,
        });
    }

    function displayGroupAdded(): JSX.Element {
        return displayLine({
            icon: IconNames.PeopleAdd,
            mainContent: textSubject('Group was created by'),
            showTime: true,
        });
    }

    function addOrRemovePointOfContact(addedOrRemoved: 'added' | 'removed'): JSX.Element {
        if (!props.isFeatureFlagGroupsPOCEnabled) {
            return <div key={props.activity.id}></div>;
        }

        const justification = props.activity?.metadata?.justification;

        const justificationContent = justification ? (
            <div>
                <BoldFont>Justification</BoldFont>:<span>&nbsp;</span>
                {justification}
            </div>
        ) : (
            <></>
        );

        return displayLine({
            icon: addedOrRemoved === 'added' ? IconNames.AddFriend : IconNames.UserRemove,
            mainContent: directObjTextSubjText(
                `was ${addedOrRemoved} as a point of contact of the group by`,
            ),
            showTime: true,
            additionalContent: justificationContent,
        });
    }

    type EventStringToElementType = Dictionary<() => JSX.Element | undefined>;

    const eventFragments: EventStringToElementType = {
        [GroupActivityEvent.ADD_GROUP_MEMBER]: () => addGroupMember(),
        ['Personnel.Groups.MemberAdded']: () => addGroupMember(),
        [GroupActivityEvent.UPDATE_GROUP_MEMBER]: () => updateGroupMember(),
        ['Personnel.Groups.MemberUpdated']: () => updateGroupMember(),
        [GroupActivityEvent.REMOVE_GROUP_MEMBER]: () => removeGroupMember(),
        ['Personnel.Groups.MemberRemoved']: () => removeGroupMember(),
        [GroupActivityEvent.ADD_POINT_OF_CONTACT]: () => addOrRemovePointOfContact('added'),
        [GroupActivityEvent.REMOVE_POINT_OF_CONTACT]: () => addOrRemovePointOfContact('removed'),
        [GroupActivityEvent.CREATE_GROUP_RULE]: () =>
            createGroupRule(IconNames.World, 'was created by'),
        ['Personnel.Groups.GroupRuleAdded']: () =>
            createGroupRule(IconNames.World, 'was created by'),
        [GroupActivityEvent.UPDATE_GROUP_RULE]: () =>
            updateGroupRule(IconNames.Switch, 'was modified by'),
        ['Personnel.Groups.GroupRuleUpdated']: () =>
            updateGroupRule(IconNames.Switch, 'was modified by'),
        [GroupActivityEvent.DELETE_GROUP_RULE]: () =>
            deleteGroupRule(IconNames.Delete, 'was deleted by'),
        ['Personnel.Groups.GroupRuleDeleted']: () =>
            deleteGroupRule(IconNames.Delete, 'was deleted by'),
        [GroupActivityEvent.NONCOMPLIANT_NOTICE_GROUP_RULE]: () =>
            noncompliantNoticeGroupRule(IconNames.Mail),
        [GroupActivityEvent.REQUEST_JOIN_GROUP_MEMBER]: () => {
            const hasViolations = hasGroupRuleViolations(props.activity);
            const isCompliantMsg = hasViolations ? '' : ' and is compliant';
            return displayLine({
                icon: IconNames.Contact,
                mainContent: directObjectText(`has requested to join the group${isCompliantMsg}`),
                additionalContent: hasViolations
                    ? displayGroupRuleViolations('and is not compliant with the following rule(s)')
                    : undefined,
                showTime: true,
            });
        },
        [GroupActivityEvent.NOMINATE_GROUP_MEMBER]: () => {
            const hasViolations = hasGroupRuleViolations(props.activity);
            const isCompliantMsg = hasViolations ? '' : ' and is compliant';
            return displayLine({
                icon: IconNames.Contact,
                mainContent: directObjTextSubjText(
                    'was nominated by',
                    `to join the group${isCompliantMsg}`,
                ),
                additionalContent: hasViolations
                    ? displayGroupRuleViolations('and is not compliant with the following rule(s)')
                    : undefined,
                showTime: true,
            });
        },
        [GroupActivityEvent.APPROVE_GROUP_MEMBER]: () => {
            const hasViolations = hasGroupRuleViolations(props.activity);
            const isCompliantMsg = hasViolations ? '' : ' and is compliant';
            return displayLine({
                icon: IconNames.AddFriend,
                mainContent: directObjTextSubjText(
                    'was approved by',
                    `to join the group${isCompliantMsg}`,
                ),
                additionalContent: displaySponsorJustificationNotesViolations(true),
                showTime: true,
            });
        },
        [GroupActivityEvent.AUTO_APPROVE_GROUP_MEMBER]: () =>
            displayLine({
                icon: IconNames.AddFriend,
                mainContent: directObjectText('was auto-approved to join the group'),
                showTime: true,
            }),
        [GroupActivityEvent.DENY_GROUP_MEMBER]: () => {
            const businessJustification = getBusinessJustification(props.activity);
            return displayLine({
                icon: IconNames.Cancel,
                mainContent: directObjTextSubjText('was denied by', 'to join the group'),
                additionalContent: !!businessJustification ? (
                    <Indent>
                        {strong('Reviewer comment')}: {businessJustification.value}
                    </Indent>
                ) : undefined,
                showTime: true,
            });
        },
        [GroupActivityEvent.CREATE_GROUP]: () => displayGroupAdded(),
        ['Personnel.Groups.GroupAdded']: () => displayGroupAdded(),
        [GroupActivityEvent.UPDATE_GROUP]: () => displayGroupUpdate(),
        ['Personnel.Groups.GroupUpdated']: () => displayGroupUpdate(),
        [GroupActivityEvent.SCHEDULE_POLICY_VIOLATION]: () =>
            props.activity.subject.type === GroupActivityObjectType.System
                ? undefined
                : displayLine({
                      icon: IconNames.SyncOccurence,
                      mainContent: textSubject('A policy check was initiated by'),
                      showTime: true,
                  }),
        [GroupActivityEvent.DOWNLOAD_GROUP_MEMBERS]: () => downloadReport('Group Members Report'),
        [GroupActivityEvent.DOWNLOAD_GROUP_POLICY_VIOLATIONS]: () =>
            downloadReport('Group Policy Enforcement Report'),
        [GroupActivityEvent.LINK_SECURITY_GROUP]: () =>
            displayLine({
                icon: IconNames.Link,
                mainContent: directObjTextSubjText('security group was added by'),
                showTime: true,
            }),
        [GroupActivityEvent.UNLINK_SECURITY_GROUP]: () =>
            displayLine({
                icon: IconNames.RemoveLinkChain,
                mainContent: directObjTextSubjText('security group was removed by'),
                showTime: true,
            }),
        [GroupActivityEvent.UPDATE_CUSTOM_WELCOME_MESSAGE]: () =>
            displayLine({
                icon: IconNames.Switch,
                mainContent: textSubject(
                    <span>
                        {' '}
                        <BoldFont>Welcome Message</BoldFont> &nbsp;was edited by&nbsp;{' '}
                    </span>,
                ),
                showTime: true,
            }),
        [GroupActivityEvent.UPDATE_CUSTOM_OFFBOARD_MESSAGE]: () =>
            displayLine({
                icon: IconNames.Switch,
                mainContent: textSubject(
                    <span>
                        {' '}
                        <BoldFont>Offboard Message</BoldFont> &nbsp;was edited by&nbsp;{' '}
                    </span>,
                ),
                showTime: true,
            }),
        [GroupActivityEvent.EMAIL_NOTIFICATION]: () => {
            const emailNotificationType = camelCaseToTitleCase(
                props.activity.metadata.emailNotificationType,
            );
            return displayLine({
                icon: IconNames.MailAlert,
                mainContent: textSubject(
                    <>
                        <span className={styles.strong}>{emailNotificationType}</span>
                        &nbsp;email has been sent to group owners and managers by&nbsp;
                    </>,
                ),
                showTime: true,
            });
        },
        // No need to support GroupActivityEvent.DELETE_GROUP.
        // Once a group is deleted, there's no way to access its timeline.
        // Don't believe me? Try!
    };

    function displayGroupRuleViolations(violationMsg: string | undefined): JSX.Element {
        return (
            <Indent>
                <Stack horizontalAlign='start'>
                    {!!violationMsg && <Stack.Item>{violationMsg}</Stack.Item>}
                    <Stack.Item>
                        <Indent>
                            {groupRuleViolations(props.activity)
                                .map(
                                    (violation) =>
                                        props.violationIdNameMap.get(violation.value) ??
                                        violation.value,
                                )
                                .join(', ')}
                        </Indent>
                    </Stack.Item>
                </Stack>
            </Indent>
        );
    }

    function displaySponsorJustificationNotesViolations(isApproval: boolean): JSX.Element {
        const { justification, sponsor, notes } = props.activity.metadata || {};

        const hasViolations = hasGroupRuleViolations(props.activity);

        // eslint-disable-next-line @typescript-eslint/naming-convention
        let wasNonBlank = false;

        return (
            <Stack>
                <Stack.Item>
                    <Indent>
                        <Stack horizontal verticalAlign='center' horizontalAlign='start' wrap>
                            {sponsor && (
                                <>
                                    {(wasNonBlank = true)}
                                    Sponsor:&nbsp;
                                    <CoreEmployeeHoverCardFromPrincipalId principalId={sponsor} />
                                </>
                            )}
                            {wasNonBlank && !!justification && <Separator />}
                            {!isApproval && justification && (
                                <>
                                    {(wasNonBlank = true)}
                                    &nbsp;Justification:&nbsp;{strong(justification)}
                                </>
                            )}
                            {wasNonBlank && !!notes && <Separator />}
                            {notes && (
                                <>
                                    {(wasNonBlank = true)}
                                    &nbsp;Notes:&nbsp;{strong(notes)}
                                </>
                            )}
                        </Stack>
                    </Indent>
                </Stack.Item>
                <Stack.Item>
                    {hasViolations &&
                        displayGroupRuleViolations(
                            'and is not compliant with the following rule(s):',
                        )}
                </Stack.Item>
            </Stack>
        );
    }

    function displayLine(options: {
        icon: string | undefined;
        mainContent: ReactNode;
        additionalContent?: ReactNode;
        showTime: boolean;
    }): JSX.Element {
        const { icon, mainContent, showTime, additionalContent } = options;
        return (
            <Stack key={props.activity.id} horizontal verticalAlign='start' tokens={{ padding: 4 }}>
                <Stack.Item>
                    {!!icon && <Icon iconName={icon} className={styles.icon} />}
                </Stack.Item>
                <Stack.Item grow={100}>
                    <Stack horizontalAlign='start' tokens={{ childrenGap: 8 }}>
                        <Stack.Item>
                            <Stack horizontal verticalAlign='center'>
                                {mainContent}
                            </Stack>
                        </Stack.Item>
                        {!!additionalContent && <Stack.Item>{additionalContent}</Stack.Item>}
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    {showTime && (
                        <span className={styles.time}>
                            {dateToFormattedTimeString(props.activity.eventTimestampUTC)}
                        </span>
                    )}
                </Stack.Item>
            </Stack>
        );
    }

    function displayEvent(): JSX.Element | void {
        const { event } = props.activity;

        if (!event) {
            console.error(`Event unknown for timeline activity with id "${props?.activity?.id}"`);
            return;
        }

        const fragment = eventFragments[event];

        if (!!fragment) {
            const renderObj = fragment();
            if (!!renderObj) {
                return renderObj;
            } else {
                return;
            }
        }
        /*
        else {
            // Mapping for event is not developed.
            // Code must be added to eventFragments to support it.
        }
        */
    }

    return displayEvent();
}
