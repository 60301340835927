import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import EmailClient, { IEmailTemplate } from 'clients/email-client';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import { AuthContext } from 'contexts/auth-context';
import { useFetchSimple, useIsMounted } from 'utils/misc-hooks';
import { Table } from 'components/common/table';
import tableColumnsEmailTemplate from 'components/email/email-template-table-columns';
import ButtonBar from 'components/common/button-bar';
import { ActionButton, MessageBar, MessageBarType } from '@fluentui/react';
import { Dictionary, IconNames } from 'assets/constants/global-constants';
import Spacer from 'components/common/spacer';
import { returnEmailUserName } from 'utils/string-utils';
import EmployeeClient, { IBasicEmployee } from 'clients/employee-client';
import { fetchPictures } from 'components/common/employee/employee-utils';
import useMessageBar from 'components/common/use-message-bar';
import { useSortColumnHandler } from 'utils/sort-utils';

type ImageString = {
    base64ImageString: string;
};

type IEmailTemplatesProps = {
    templates: IEmailTemplate[];
    setTemplates: React.Dispatch<React.SetStateAction<IEmailTemplate[]>>;
    shouldFetchTemplates: boolean;
    setShouldFetchTemplates: React.Dispatch<React.SetStateAction<boolean>>;
    aliasInfoMap: AliasBasicInfoMap;
    setAliasInfoMap: React.Dispatch<React.SetStateAction<AliasBasicInfoMap>>;
};

export type AliasBasicInfoMap = {
    [alias: string]: IBasicEmployee & ImageString;
};

function EmailTemplates(props: IEmailTemplatesProps): JSX.Element {
    const {
        templates,
        setTemplates,
        aliasInfoMap,
        setAliasInfoMap,
        shouldFetchTemplates,
        setShouldFetchTemplates,
    } = props;
    const authContext = useContext(AuthContext);
    const [errorLoadingTemplatesMessage, setErrorLoadingTemplatesMessage] = useState<string>('');
    const isMounted = useIsMounted();

    const [
        { sortColumn: emailSortColumn, sortAscending: emailSortAscending },
        emailSortColumnHandler,
    ] = useSortColumnHandler('lastModifiedTimeStamp', -1);

    const {
        theElement: updateErrMsgElement,
        setMessage: setUpdateErrMsg,
        clearMessage: clearUpdateErrMsg,
    } = useMessageBar({ type: MessageBarType.error });

    const getImages = useCallback(async (basicResults: IBasicEmployee[]) => {
        const images: Dictionary<string> = {};
        for (const employeeResult of basicResults) {
            try {
                if (employeeResult.oid) {
                    const img = await fetchPictures(authContext, [employeeResult]);
                    images[employeeResult.id] = img[employeeResult.id];
                }
            } catch (e) {
                console.error('Error fetching employee image');
                console.error(e);
                setUpdateErrMsg(
                    'Unable to display employee card. There was an error fetching data.',
                );
            }
        }
        return images;
    }, []);

    const fetchNewEmployeeData = useCallback(async () => {
        clearUpdateErrMsg();
        const uniqueAliases = new Set(
            templates
                .map((template) => returnEmailUserName(template.lastModifiedBy))
                .filter((alias) => !(alias in aliasInfoMap)),
        );
        if (uniqueAliases.size === 0) {
            return;
        }
        let basicResults: IBasicEmployee[] = [];
        let images: Dictionary<string> = {};
        try {
            basicResults = await EmployeeClient.getBasicEmployeesByAlias(
                authContext,
                Array.from(uniqueAliases),
            );
            images = await getImages(basicResults);
        } catch (e) {
            console.error('Error fetching employee basic data');
            console.error(e);
            setUpdateErrMsg('Unable to display employee card. There was an error fetching data.');
        }
        if (isMounted() && basicResults.length > 0) {
            setAliasInfoMap((prevMap) => {
                const newMap = { ...prevMap };
                for (const employeeResult of basicResults) {
                    const empImage = employeeResult.id in images ? images[employeeResult.id] : ' ';

                    newMap[employeeResult.onPremisesSamAccountName] = {
                        ...employeeResult,
                        base64ImageString: empImage,
                    };
                }
                return newMap;
            });
        }
    }, [authContext, templates, getImages]);

    useEffect(() => {
        fetchNewEmployeeData();
    }, [fetchNewEmployeeData]);

    const { isFetching: isFetchingTemplates } = useFetchSimple<IEmailTemplate[]>({
        dependencies: [],
        canPerformFetch: shouldFetchTemplates,
        fetchFunc: async (): Promise<IEmailTemplate[]> => {
            return EmailClient.getEmailTemplates(authContext);
        },
        onSuccess: (result: IEmailTemplate[]): void => {
            setTemplates(result);
        },
        onError: (e): void => {
            console.error(e);
            setErrorLoadingTemplatesMessage(e);
        },
        onFinally: () => setShouldFetchTemplates(false),
    });

    const handleDeleteTemplate = useCallback(
        (id: string): void => {
            const updatedTemplates = templates.filter((t) => t.id !== id);
            setTemplates(updatedTemplates);
        },
        [setTemplates, templates],
    );

    const sortedTemplates = useMemo(() => {
        const sorted = copyAndSort(templates, emailSortColumn, emailSortAscending !== 1);
        return sorted;
    }, [emailSortAscending, emailSortColumn, templates]);

    function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items
            .slice(0)
            .sort((a: T, b: T) =>
                (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1,
            );
    }

    const emailTemplateTableColumns = useMemo(() => {
        return tableColumnsEmailTemplate({
            handleDeleteTemplate,
            aliasInfoMap,
            sortColumn: emailSortColumn,
            sortAscending: emailSortAscending === 1,
            sortColumnHandler: emailSortColumnHandler,
        });
    }, [
        handleDeleteTemplate,
        aliasInfoMap,
        emailSortColumn,
        emailSortAscending,
        emailSortColumnHandler,
    ]);

    if (errorLoadingTemplatesMessage !== '')
        return (
            <>
                <Spacer marginTop={20} />
                <MessageBar messageBarType={MessageBarType.error}>
                    {errorLoadingTemplatesMessage}
                </MessageBar>
            </>
        );

    return (
        <div>
            <PageLoadingSpinner
                label='Loading email templates...'
                ariaLive='assertive'
                isLoading={isFetchingTemplates}
                labelPosition='left'>
                <Spacer marginTop={10} />
                <ButtonBar>
                    <Link to={(location: any) => `${location.pathname}/create`}>
                        <ActionButton iconProps={{ iconName: IconNames.Add }}>
                            New Template
                        </ActionButton>
                    </Link>
                </ButtonBar>
                {updateErrMsgElement()}
                <Table
                    rows={sortedTemplates}
                    tableColumns={emailTemplateTableColumns}
                    shimmerLabel={isFetchingTemplates ? 'Loading templates...' : ''}
                    isFetchingData={isFetchingTemplates}
                />
            </PageLoadingSpinner>
        </div>
    );
}

export default React.memo(EmailTemplates);
