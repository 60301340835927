import {
    ScreeningPaths,
    ScreeningRequestTypesLabels,
} from 'components/screening/common/common-constants';
import { tableColumnStyles } from 'components/screening/common/common-tab-styling';
import { getCommonScreeningStatusLabel } from 'components/screening/common/common-ui';
import {
    convertIPublicTrustToCommonScreening,
    convertIScreeningToCommonScreening,
    ICommonScreening,
} from 'components/screening/common/ICommonScreening';
import { publicTrustTableColumns } from 'components/screening/public-trust/candidates/listing/get-public-trust-table-columns';
import { IPublicTrust } from 'components/screening/public-trust/public-trust-screening-result';
import { IScreening, isIScreening } from 'components/screening/us-gov/IScreening';
import { IButtonStyles, IColumn, Icon } from '@fluentui/react';
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { generateRandomKey } from 'utils/misc-utils';
import { contentMaxHeight, detailsListStyles } from 'assets/styles/list-styles';
import EmployeeClient, {
    IBasicEmployee,
    IEmployee,
    IEmployeeWithEditableData,
} from 'clients/employee-client';
import { AuthContext, IAuthContext } from 'contexts/auth-context';
import { dateToLocalDate, unixTimeStampToLocalDate } from 'utils/time-utils';
import ActionButtonNav from 'components/common/buttons/action-button-nav';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import EllipsisText from 'components/common/ellipsis-text';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import { Table, TableCell } from 'components/common/table';
import { usGovTableColumns } from 'components/screening/us-gov/candidates/listing/get-candidates-table-columns';
import {
    firstColumnTableCellPadding,
    firstColumnTitlePadding,
} from 'components/personnel-profile/common/common-constants';
import { commonPersonnelPersonnelStyles } from 'components/personnel-profile/common-personnel-profile-styles';
import { getDefaultColumnWidths } from 'utils/table-utils';
import { SuitabilityLevels } from 'components/personnel-profile/suitability/profile-suitability-types';

const actionButtonStylesMarginRight4: IButtonStyles = {
    root: { maxHeight: contentMaxHeight, marginRight: '4px' },
};
const actionButtonStylesMarginLeftMinus13: IButtonStyles = {
    root: { maxHeight: contentMaxHeight, marginRight: '-13px' },
};

export interface PersonnelProfileScreeningTableProps {
    employee: IEmployeeWithEditableData | undefined;
    resultSize: number;
    // eslint-disable-next-line @typescript-eslint/ban-types
    queryMethod: Function;
    screeningPath: ScreeningPaths;
    strings: Map<string, string>;
}

export default function PersonnelProfileScreeningTable(
    props: PersonnelProfileScreeningTableProps,
): JSX.Element {
    const queryMethod = useMemo(() => props.queryMethod, [props.queryMethod]);
    const authContext = useContext(AuthContext);
    const [screenings, setScreenings] = useState<ICommonScreening[]>([]);
    const [processOwnerMap, setProcessOwnerMap] = useState<Map<string, IBasicEmployee>>(
        new Map<string, IBasicEmployee>(),
    );
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
    const [isFetchingEmployees, setIsFetchingEmployees] = useState<boolean>(false);

    const columnWidths = getDefaultColumnWidths(7);

    const columnsUsGov = [
        {
            key: 'requested',
            name: usGovTableColumns.Requested,
            ariaLabel: usGovTableColumns.Requested,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            styles: { cellTitle: { paddingLeft: firstColumnTitlePadding } }, // DetailsList applies this to the header cell.
            onRender: (row: ICommonScreening): JSX.Element => (
                <TableCell style={{ paddingLeft: firstColumnTableCellPadding }}>
                    {unixTimeStampToLocalDate(row.nominatedAtUtc)}
                </TableCell>
            ),
        },
        {
            key: 'level',
            name: usGovTableColumns.Level,
            ariaLabel: usGovTableColumns.Level,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ICommonScreening): JSX.Element => (
                <TableCell>
                    <EllipsisText text={row.clearance} textLengthBeforeEllipsis={5} />
                </TableCell>
            ),
        },
        {
            key: 'type',
            name: usGovTableColumns.Type,
            ariaLabel: usGovTableColumns.Type,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ICommonScreening): JSX.Element => (
                <TableCell>
                    <EllipsisText
                        text={
                            ScreeningRequestTypesLabels[
                                row.requestType as keyof typeof ScreeningRequestTypesLabels
                            ] ?? row.requestType
                        }
                        textLengthBeforeEllipsis={8}
                    />
                </TableCell>
            ),
        },
        {
            key: 'suitabilityLevel',
            name: publicTrustTableColumns.SuitabilityLevel,
            ariaLabel: publicTrustTableColumns.SuitabilityLevel,
            minWidth: 75,
            maxWidth: 75,
            isRowHeader: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                return (
                    <TableCell>
                        {!!row.suitabilityLevel &&
                            SuitabilityLevels[
                                row.suitabilityLevel as keyof typeof SuitabilityLevels
                            ]}
                    </TableCell>
                );
            },
        },
        {
            key: 'status',
            name: usGovTableColumns.Status,
            ariaLabel: usGovTableColumns.Status,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ICommonScreening): JSX.Element => (
                <TableCell>{getCommonScreeningStatusLabel(row)}</TableCell>
            ),
        },
        {
            key: 'contractId',
            name: usGovTableColumns.ContractId,
            ariaLabel: usGovTableColumns.ContractId,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ICommonScreening): JSX.Element => (
                <TableCell>
                    <EllipsisText text={row.contractId} textLengthBeforeEllipsis={10} />
                </TableCell>
            ),
        },
        {
            key: 'processOwner',
            name: usGovTableColumns.ProcessOwner,
            ariaLabel: usGovTableColumns.ProcessOwner,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ICommonScreening): JSX.Element => (
                <TableCell>
                    {!processOwnerMap.has(row.processOwnerId) && <></>}
                    {processOwnerMap.has(row.processOwnerId) && (
                        <EmployeeHoverCard
                            key={`clearanceScreening-processOwner-${row.id}-${row.processOwnerId}`}
                            personnelId={row.processOwnerId}>
                            {getDisplayNameOrDefault(
                                processOwnerMap.get(row.processOwnerId),
                                row.processOwnerId,
                            )}
                        </EmployeeHoverCard>
                    )}
                </TableCell>
            ),
        },
        {
            key: 'action',
            name: usGovTableColumns.Action,
            ariaLabel: usGovTableColumns.Action,
            minWidth: columnWidths.actionColumnWidth,
            maxWidth: columnWidths.actionColumnWidth,
            isRowHeader: true,
            onRender: (row: ICommonScreening): JSX.Element => (
                <TableCell>
                    <ActionButtonNav
                        iconName='EntryView'
                        styles={actionButtonStylesMarginLeftMinus13}
                        to={`/screening/us-gov/${row.id}`}
                        title='View Screening'>
                        View
                    </ActionButtonNav>
                </TableCell>
            ),
        },
    ];

    const columnsPublicTrust = [
        {
            key: 'requested',
            name: publicTrustTableColumns.Requested,
            ariaLabel: publicTrustTableColumns.Requested,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            styles: { cellTitle: { paddingLeft: firstColumnTitlePadding } }, // DetailsList applies this to the header cell.
            onRender: (row: ICommonScreening): JSX.Element => (
                <TableCell style={{ paddingLeft: firstColumnTableCellPadding }}>
                    {dateToLocalDate(row.nominatedAtUtc)}
                </TableCell>
            ),
        },
        {
            key: 'requestAgency',
            name: publicTrustTableColumns.RequestAgency,
            ariaLabel: publicTrustTableColumns.RequestAgency,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                if (row.publicTrustAgency) {
                    return <TableCell>{row.publicTrustAgency}</TableCell>;
                }
                return <TableCell>{''}</TableCell>;
            },
        },
        {
            key: 'type',
            name: publicTrustTableColumns.Type,
            ariaLabel: publicTrustTableColumns.Type,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ICommonScreening): JSX.Element => (
                <TableCell>
                    <EllipsisText
                        text={
                            ScreeningRequestTypesLabels[
                                row.requestType as keyof typeof ScreeningRequestTypesLabels
                            ] ?? row.requestType
                        }
                        textLengthBeforeEllipsis={8}
                    />
                </TableCell>
            ),
        },
        {
            key: 'status',
            name: publicTrustTableColumns.Status,
            ariaLabel: publicTrustTableColumns.Status,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ICommonScreening): JSX.Element | string => {
                if (row.stateName) {
                    return (
                        <div className={detailsListStyles.dataCellContainer}>
                            {getCommonScreeningStatusLabel(row)}
                        </div>
                    );
                } else {
                    return '';
                }
            },
        },
        {
            key: 'contractId',
            name: publicTrustTableColumns.ContractId,
            ariaLabel: publicTrustTableColumns.ContractId,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ICommonScreening): JSX.Element => (
                <TableCell>
                    <EllipsisText text={row.contractId} textLengthBeforeEllipsis={10} />
                </TableCell>
            ),
        },
        {
            key: 'processOwner',
            name: publicTrustTableColumns.ProcessOwner,
            ariaLabel: publicTrustTableColumns.ProcessOwner,
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ICommonScreening): ReactNode => {
                return (
                    <TableCell>
                        {!processOwnerMap.has(row.processOwnerId) && <></>}
                        {processOwnerMap.has(row.processOwnerId) && (
                            <EmployeeHoverCard personnelId={row.processOwnerId}>
                                {getDisplayNameOrDefault(
                                    processOwnerMap.get(row.processOwnerId),
                                    row.processOwnerId,
                                )}
                            </EmployeeHoverCard>
                        )}
                    </TableCell>
                );
            },
        },
        {
            key: 'actions',
            name: publicTrustTableColumns.Action,
            ariaLabel: publicTrustTableColumns.Action,
            minWidth: columnWidths.actionColumnWidth,
            maxWidth: columnWidths.actionColumnWidth,
            isRowHeader: true,
            onRender: (row: ICommonScreening): JSX.Element => {
                return (
                    <TableCell>
                        <NavLink
                            key={`preview-link-${row.id ?? generateRandomKey()}`}
                            className={tableColumnStyles.viewScreeningLink}
                            to={`/screening/public-trust/${row.id}`}>
                            <Icon
                                iconName='PreviewLink'
                                className={tableColumnStyles.viewScreeningIcon}
                            />{' '}
                            View
                        </NavLink>
                    </TableCell>
                );
            },
        },
    ];

    const columns: IColumn[] =
        props.screeningPath === ScreeningPaths.UsGov ? columnsUsGov : columnsPublicTrust;

    useEffect(() => {
        async function setupScreenings(
            authContext: IAuthContext,
            employee: IEmployeeWithEditableData,
            resultSize: number,
        ): Promise<void> {
            setIsFetchingData(true);

            try {
                let screeningResult = await queryMethod(
                    authContext,
                    employee.data as IEmployee,
                    resultSize,
                );

                if (screeningResult.results) {
                    let localScreenings: ICommonScreening[] = screeningResult.results.map(
                        (x: IScreening | IPublicTrust) => {
                            if (isIScreening(x)) {
                                return convertIScreeningToCommonScreening(x);
                            } else {
                                return convertIPublicTrustToCommonScreening(x);
                            }
                        },
                    );
                    setScreenings(localScreenings);
                    if (localScreenings.length < resultSize && screeningResult.continuationToken) {
                        do {
                            screeningResult = await queryMethod(
                                authContext,
                                employee.data as IEmployee,
                                resultSize,
                                screeningResult.continuationToken,
                            );
                            const moreLocalScreening: ICommonScreening[] = screeningResult.results.map(
                                (x: IScreening | IPublicTrust) => {
                                    if (isIScreening(x)) {
                                        return convertIScreeningToCommonScreening(x);
                                    } else {
                                        return convertIPublicTrustToCommonScreening(x);
                                    }
                                },
                            );
                            if (screeningResult.results) {
                                localScreenings = localScreenings.concat(moreLocalScreening);
                            }
                        } while (
                            screeningResult.continuationToken &&
                            localScreenings.length < resultSize
                        );
                        const finalList = localScreenings.slice(0, resultSize);
                        setScreenings(finalList);
                    }
                }
            } catch (e) {
                console.error('error with getting screenings for employee ', employee, ' --- ', e);
            } finally {
                setIsFetchingData(false);
            }
        }

        if (props.employee && authContext) {
            setupScreenings(authContext, props.employee, props.resultSize);
        }
    }, [props.employee, props.resultSize, authContext, queryMethod]);

    useEffect(() => {
        async function setupProcessOwners(
            authContext: IAuthContext,
            screenings: ICommonScreening[],
        ): Promise<void> {
            // Several screenings could have the same process owner
            const uniqueProcessOwnerIds = new Set<string>();
            screenings.forEach((x) => {
                // A screening may not have a process owner assigned
                if (x.processOwnerId) {
                    uniqueProcessOwnerIds.add(x.processOwnerId);
                }
            });

            setIsFetchingEmployees(true);

            try {
                const result = await EmployeeClient.getBasicEmployeesById(
                    authContext,
                    Array.from(uniqueProcessOwnerIds),
                );
                const newProcessOwnerMap = new Map<string, IBasicEmployee>();
                result.forEach((x) => {
                    newProcessOwnerMap.set(x.id, x);
                });
                setProcessOwnerMap(newProcessOwnerMap);
            } catch (e) {
                console.error(
                    'Error with getting basic employees information of process owner(s)',
                    e,
                );
            } finally {
                setIsFetchingEmployees(false);
            }
        }

        if (screenings && screenings.length > 0) {
            setupProcessOwners(authContext, screenings);
        }
    }, [screenings, authContext]);

    return (
        <>
            {props.employee && !isFetchingEmployees && (
                <>
                    <ContainerWithEtiquettes
                        leftEtiquetteLabel={props.strings.get('tableLabel')!}
                        bottomInfo={
                            screenings.length > 0 && (
                                <ActionButtonNav
                                    iconName='PreviewLink'
                                    styles={actionButtonStylesMarginRight4}
                                    to={`/screening/${props.strings.get(
                                        'path',
                                    )}/manage?personnelId=${props.employee.data?.id}`}>
                                    {props.strings.get('viewAllRecordsMessage')}
                                </ActionButtonNav>
                            )
                        }>
                        {screenings.length === 0 && !isFetchingData ? (
                            <p
                                className={
                                    commonPersonnelPersonnelStyles.noDataParagraphMorePadding
                                }>
                                {props.strings.get('noRecordsToShowMessage')}
                            </p>
                        ) : (
                            <Table<ICommonScreening>
                                rows={screenings}
                                isFetchingData={isFetchingData}
                                shimmerLines={2}
                                tableColumns={columns}
                                tableName={props.strings.get('tableName')}
                            />
                        )}
                    </ContainerWithEtiquettes>
                </>
            )}
        </>
    );
}
