import { CloudStatus, ClearanceStatus } from 'components/staffing/staffing-constants';
import { getAllocationKey } from 'components/staffing/status-filters/filter-utility';
import { AllocationFilter } from 'components/staffing/status-filters/allocation-filter-class';
import { OrgTeamsObjectType } from 'components/staffing/staffing-page-types';
import { IStaffingClearanceRecordResponse } from 'clients/staffing-client';

// Filter out allocations that belong to teams with >= Team.PeopleTarget allocations with 'Ready' status
export class EXTargetReadyAllocationFilter extends AllocationFilter {
    filter(
        allocations: IStaffingClearanceRecordResponse[],
        orgTeamData: OrgTeamsObjectType,
    ): IStaffingClearanceRecordResponse[] {
        // Group allocations by org-team name and track 'Ready' status
        const map: { [key: string]: number } = {};

        const filteredStatuses = this.filterStatuses(allocations);
        filteredStatuses.forEach((allocation) => {
            const orgTeam: string = getAllocationKey(allocation);

            map[orgTeam] = map[orgTeam] || 0;

            const foundCloud = allocation.cloudStatuses.find((x) => x.name === CloudStatus.EX);
            if (foundCloud && foundCloud.status === ClearanceStatus.Ready) {
                map[orgTeam]++;
            }
        });

        // Filter all allocations belonging to those teams.
        return filteredStatuses.filter((value) => {
            const orgTeam: string = getAllocationKey(value);
            const teams = orgTeamData[value.organization];
            const team = teams.find((t) => t.name === value.team);
            /**
             * If the above statement did not find the team, it indicates there is
             * an inconsistency in database(s) because team names in allocation data
             * and team names obtained from server are not in sync. In such a case
             * it's best to somehow raise an error condition.
             */
            // TODO: Add error handling
            /**
             * if (!team) {
             *     raiseHell("Yo! Dude!")
             * }
             */
            return team && map[orgTeam] < team.peopleTarget;
        });
    }
}
