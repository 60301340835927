import DOMPurify from 'dompurify';
import React, { useReducer, useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { generalIsMountedCode } from 'utils/misc-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

/**
 * T is type of the variable you'd want to create with the reducer.
 */

type UseReducerVarStateType<T> = [T, (newValue: T) => void];

type ActionType<T> = {
    type: string;
    payload: T;
};

/**
 * Implements one variable with the help of useReducer.
 * It creates a wrapper around React hook useReducer such that user
 * doesn't have to deal with the nitty gritty details of using that
 * hook. The wrapper, called useReducerVar, does all the dirty work.
 * Technically, this wrapper is a custom React hook.
 *
 * First used for changing sort order when user clicks on the column
 * header of a table implemented by <DetailsList> (or <Table> which
 * uses <DetailsList>). Implementation of DetailsList is such that
 * as of this moment, Feb 26, 2021, useState doesn't work when user
 * clicks on a column header to change the sort order. I used this
 * custom React hook to remember the sort order and toggle it when
 * user clicks on a column header.
 *
 * Usage:
 *     const [variable, updateVariable] = useReducerVar<VarType>(initialValue);
 *     updateVariable(newValue);
 * export function useReducerVar<T>(init: T): UseReducerVarStateType<T> {
 */
export function useReducerVar<T>(init: T): UseReducerVarStateType<T> {
    const reducer = (currentState: T, action: ActionType<T>): T => {
        return action.payload;
    };
    const [variable, dispatch] = useReducer(reducer, init);
    function updateVariable(newValue: T): void {
        dispatch({ type: '', payload: newValue });
    }
    return [variable, updateVariable];
}

/**
 * useToggle returns a toggle-able boolean
 * and a function that toggles it on each call.
 *
 * @param initialValue : boolean
 * @returns ToggleHookType
 */

type ToggleHookType = [boolean, (value?: boolean) => void];

export function useToggle(initialValue: boolean): ToggleHookType {
    const [isTrue, setVariable] = useState<boolean>(initialValue);

    const toggleFunc = (value?: boolean): void => {
        if (value !== undefined) {
            setVariable(value);
        } else {
            setVariable(!isTrue);
        }
    };

    return [isTrue, toggleFunc];
}

/**
 * General purpose hook to fetch an item and maintain it as a state variable.
 *
 * Given a function (params.fetchFunc()), it calls it to perform fetch,
 * tells you if it's still fetching and tells you if there was a problem
 * fetching. It also gives you a function to update value of the item.
 *
 * If the fetch ends in error, it resets the item to undefined.
 *
 * If you want to process the fetch errors, develop a function to do that
 * and pass it to the hook (params.onError(e)).
 */
interface IUseFetchParams<T> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dependencies?: any[];
    fetchFunc: () => Promise<T> | undefined;
    // If the function canPerformFetch is provided, it will call it
    // to decide whether it should perform the fetch operation.
    // It can be used to prevent a redundant, useless fetch or to
    // prevent it if parameters are invalid.
    canPerformFetch?: () => boolean;
    // onError and problemFetching are mutually exclusive.
    // If the former is specified, the code won't set the latter
    // otherwise it will.
    onError?: (e: unknown) => void;
    onSuccess?: (item: T | undefined) => void;
    onFinally?: () => void;
}

interface IUseFetchResult<T> {
    item: T | undefined;
    isFetching: boolean;
    // onError and problemFetching are mutually exclusive.
    // If the former is specified, the latter won't be used.
    problemFetching: string;
}

export function useFetch<T>(params: IUseFetchParams<T>): IUseFetchResult<T> {
    const [item, setItem] = useState<T>();
    const [problemFetching, setProblemFetching] = useState<string>('');
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const fetchItem = async (isMountedFunc: () => boolean): Promise<void> => {
        if (params?.canPerformFetch && !params.canPerformFetch()) {
            return;
        }
        try {
            setIsFetching(true);
            const itemVar = await params.fetchFunc();
            if (isMountedFunc()) {
                setProblemFetching('');
                setItem(itemVar);
                if (params.onSuccess) {
                    params.onSuccess(itemVar);
                }
            }
        } catch (e) {
            if (isMountedFunc()) {
                setItem(undefined);
                if (params.onError) {
                    params.onError(e);
                } else {
                    setProblemFetching('Error loading the item');
                }
            }
        } finally {
            if (isMountedFunc()) {
                if (params.onFinally) {
                    params.onFinally();
                }
                setIsFetching(false);
            }
        }
    };
    useEffect(() => {
        return generalIsMountedCode(fetchItem);
    }, params?.dependencies ?? []);

    return { item, isFetching, problemFetching };
}
/**
 * End useFetch
 */

/**
 * useFetchSimple is very similar to useFetch, except that it's much simpler.
 * It's intentially made less versatile so that it has less overhead than useFetch.
 * All the functions passed to its input parameters are mandatory.As a result,
 * it doesn't have to check if they are defined before calling each and every one
 * of them. Rather, it forces the calling component to specify them all, and it
 * just calls the right one at the right time.
 *
 * In addition, it does not save the values it's fetched in a state variable so
 * that the calling component manage that any which way it wants.
 *
 * The only state variable it has is "isFetching", so that the calling component
 * can just check it - No point having the caller manage that.
 */

interface IUseFetchSimpleParams<T> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dependencies: any[];
    canPerformFetch: boolean;

    fetchFunc: () => Promise<T>;
    onSuccess: (item: T) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => void;
    onFinally: () => void;
}

interface IUseFetchSimpleResult {
    isFetching: boolean;
}

export function useFetchSimple<T>(params: IUseFetchSimpleParams<T>): IUseFetchSimpleResult {
    const [isFetching, setIsFetching] = useState<boolean>(false);

    const isMountedFunc = useIsMounted();

    const fetchItem = async (isMountedFunc: () => boolean): Promise<void> => {
        if (!params.canPerformFetch || !isMountedFunc()) {
            return;
        }
        try {
            setIsFetching(true);
            const itemVar = await params.fetchFunc();
            if (isMountedFunc()) {
                params.onSuccess(itemVar);
            }
        } catch (e) {
            if (isMountedFunc()) {
                params.onError(e);
            }
        } finally {
            if (isMountedFunc()) {
                params.onFinally();
                setIsFetching(false);
            }
        }
    };

    useEffect(() => {
        fetchItem(isMountedFunc);
    }, params?.dependencies);

    return { isFetching };
}

/*
    The following is a convenient type to use with useFetchSimple, or any other fetch.
    Example:
        To trigger a fetch:
            setVar({shouldFetch: true}); // Other properties will clear.
        To register fetch result:
            setVar({value: fetchResult}); // Other properties will clear.
        To register an error message:
            setVar({errmsg: "error message"}); // Other properties will clear.

        If a consumer of this type wishes, it may set the property isFetching to
        register that a fetch is in progress:
            setVar({isFetching: true});
*/
export type GeneralFetchResultDataType<T> = {
    value?: T;
    errMsg?: string;
    shouldFetch?: boolean;
    // The property isFetching of this type is different from the value "isFetching"
    // that the custom hook useFetchSimple returns. The former is an optional
    // value that a consumer of this type may wish to set. The latter is a value
    // that the hook will always return.
    isFetching?: boolean;
};

/**
 * @deprecated This function is deprecated after the react 18 migration.
 *  Please do not introduce any new calls to this hook and remove all existing call to useIsMounted.
 */
export function useIsMounted(): () => boolean {
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return (): void => {
            mounted.current = false;
        };
    }, []);

    const isMounted = (): boolean => mounted.current;

    return isMounted;
}
interface IUseTimeout {
    reset: () => void;
    clear: () => void;
}

// Reference: https://www.youtube.com/watch?v=0c6znExIqRw
// https://github.com/WebDevSimplified/useful-custom-react-hooks
// eslint-disable-next-line @typescript-eslint/ban-types
export function useTimeout(func: Function, delay: number): IUseTimeout {
    const callbackRef = useRef(func);
    const timeoutRef = useRef((undefined as unknown) as NodeJS.Timeout);

    useEffect(() => {
        callbackRef.current = func;
    }, [func]);

    const set = useCallback(() => {
        clear();
        timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
    }, [delay]);

    const clear = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = (undefined as unknown) as NodeJS.Timeout;
        }
    }, []);

    useEffect(() => {
        set();
        return clear;
    }, [delay, set, clear]);

    return { reset: set, clear };
}

// Reference: https://www.youtube.com/watch?v=0c6znExIqRw
// https://github.com/WebDevSimplified/useful-custom-react-hooks
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
export function useDebounce(func: Function, delay: number, dependencies: any[]): void {
    const { reset, clear } = useTimeout(func, delay);
    useEffect(reset, [...dependencies, reset]);
    useEffect(clear, []);
}

interface IScrollEventListenerOptions {
    shouldRemoveHandler?: boolean;
}

export function useScrollEventListener(
    scrollEventHandler: () => void,
    options: IScrollEventListenerOptions,
): void {
    const handlerRef = useRef<EventListener>(null!);

    useEffect(() => {
        removeHandler();
        if (typeof scrollEventHandler === 'function') {
            handlerRef.current = scrollEventHandler;
            document.addEventListener('scroll', handlerRef.current);
        }
        return removeHandler;
    }, [scrollEventHandler]);

    const removeHandler = (): void => {
        if (!!handlerRef.current) {
            document.removeEventListener('scroll', handlerRef.current);
            handlerRef.current = (undefined as unknown) as () => void;
        }
    };

    useEffect(() => {
        if (options.shouldRemoveHandler) removeHandler();
    }, [options.shouldRemoveHandler]);
}

export function useCheckMountedState<T>(
    initValue: T | (() => T),
): [T, (value: React.SetStateAction<T>) => void] {
    const [varValue, setVarValue] = useState<T>(initValue);

    const isMounted = useIsMounted();

    const updateVar = (value: React.SetStateAction<T>): void => {
        if (isMounted()) {
            setVarValue(value);
        }
    };
    return [varValue, updateVar];
}

/** Track value for a variable between renders of React functional component.
 * This is useful for things like checking whether a prop has changed and you need to re-render.
 * @param value this can be anything you want to compare across renders
 */
export default function usePrevious(value: any): undefined {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export function useSanitizeHtml(html: string): string {
    const shouldLogRemoved = useFeatureFlag(FeatureFlagKeys.emailsLogSanitized).enabled;
    const [cleanedHtmlBody, setCleanedHtmlBody] = useState<string>('');
    useEffect(() => {
        DOMPurify.addHook('afterSanitizeAttributes', function (node) {
            // set all elements with target to target=_blank, rel='noopener noreferrer'
            if ('target' in node) {
                node.setAttribute('target', '_blank');
                node.setAttribute('rel', 'noopener noreferrer');
            }
        });
        const clean = DOMPurify.sanitize(html, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            USE_PROFILES: { html: true },
        });
        if (shouldLogRemoved) {
            const removed = DOMPurify.removed;
        }
        setCleanedHtmlBody(clean);
    }, [html, shouldLogRemoved]);

    return cleanedHtmlBody;
}

/** Determines if the passed in HTML string contains unsanitary elements or attributes.
 * @returns true if HTML contains zero unsanitary elements.
 */
export function useIsHtmlSanitized(html: string): boolean {
    const shouldLogRemoved = useFeatureFlag(FeatureFlagKeys.emailsLogSanitized).enabled;
    const [dirtyHtml, setDirtyHtml] = useState<any[]>([]);
    useEffect(() => {
        DOMPurify.sanitize(html, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            USE_PROFILES: { html: true },
        });
        const removed = DOMPurify.removed;
        setDirtyHtml(removed);
    }, [html, shouldLogRemoved]);

    return dirtyHtml.length === 0;
}

/** Return the URL one level above the current level.
 * For example, if the current path is /email/search/1234
 * this hook will return /email/search.
 * Useful for path agnostic navigation.
 */
export const useUpOnePathLevel = (): string => {
    const location = useLocation();
    let currentPath = location.pathname;
    // strip off trailing slash if present
    if (currentPath.endsWith('/')) {
        currentPath = currentPath.slice(0, -1);
    }
    const upOnePathLevel = currentPath.substring(0, currentPath.lastIndexOf('/'));
    return upOnePathLevel;
};

/** Return an array of the current path parts.
 * For example, if the current path is /email/search/1234/
 * this hook will return ['email', 'search', '1234']
 */
export const usePathParts = (): string[] => {
    const location = useLocation();
    const currentPath = location.pathname;

    return currentPath.split('/').filter(Boolean);
};
