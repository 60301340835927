import { IStyle, mergeStyles, Stack } from '@fluentui/react';
import React from 'react';
import { generateRandomKey } from 'utils/misc-utils';
import { toTitleCase } from 'utils/string-utils';

export interface ChartLegendProps {
    legendItems: ChartLegendItem[];
    maxHeight?: string;
    minWidth?: string;
}

export type ChartLegendItem = {
    label: string;
    color: string;
};

export default function ChartLegend(props: ChartLegendProps): JSX.Element {
    return (
        <div
            style={{
                display: 'flex',
                flex: 'auto',
                flexDirection: 'column',
                justifyContent: 'center',
                maxHeight: props.maxHeight,
                minWidth: props.minWidth,
            }}>
            {props.legendItems.map(
                (item): JSX.Element => {
                    return (
                        <Stack horizontal key={generateRandomKey()}>
                            <ChartLegendDot backgroundColor={item.color} />
                            <div>{toTitleCase(item.label)}</div>
                        </Stack>
                    );
                },
            )}
        </div>
    );
}

interface IChartLegendDotProps {
    backgroundColor?: string;
}

export function ChartLegendDot(props: IChartLegendDotProps): JSX.Element {
    const legendDotBaseStyle: IStyle = {
        display: 'flex',
        marginRight: '8px',
        selectors: {
            '&::before': {
                content: '""',
                alignSelf: 'center',
                justifySelf: 'center',
                borderRadius: '50%',
                width: '8px',
                height: '8px',
                background: props.backgroundColor ?? '#0078d4',
            },
        },
    };

    return <div className={mergeStyles(legendDotBaseStyle)}></div>;
}
