import {
    DefaultButton,
    Dropdown,
    FontWeights,
    IButtonStyles,
    IIconProps,
    IconButton,
    Modal,
    PrimaryButton,
    getTheme,
    mergeStyleSets,
    mergeStyles,
} from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import FormsClient, { GenericForm, GenericFormRecord } from 'clients/forms-client';
import { genericFormRecordFormStates } from 'components/forms/forms-common';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useId, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getAppInsights } from 'utils/telemetry-utils';
import { secondsToMilliseconds } from 'utils/time-utils';

type FormsSelectDuplicateModalProps = {
    selectedForm: GenericForm;
    records?: GenericFormRecord[];
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
};

export function FormsSelectDuplicateModal(props: FormsSelectDuplicateModalProps): JSX.Element {
    const { records, selectedForm, isOpen, setIsOpen } = props;
    const authContext = useContext(AuthContext);
    const history = useHistory();

    const options = useMemo(() => {
        if (!records) {
            return [];
        }

        return records
            .filter(
                (x) =>
                    x.name === selectedForm.name &&
                    (x.formState.toLowerCase() === genericFormRecordFormStates.completed ||
                        x.formState.toLowerCase() === genericFormRecordFormStates.accepted),
            )
            .sort((x) => x.lastModifiedAtUTC)
            .reverse()
            .map((x) => {
                return {
                    key: x.id,
                    text: `${x.title} > ${!!x.label ? x.label : '(No label)'} - ${
                        x.submittedAtUTC
                            ? new Date(
                                  secondsToMilliseconds(x.submittedAtUTC) ?? 0,
                              ).toLocaleString()
                            : ''
                    }`,
                };
            });
    }, [records]);

    const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

    const duplicateForm = async (): Promise<void> => {
        if (selectedId) {
            getAppInsights()?.trackEvent(
                {
                    name: 'DuplicateFormLandingPage',
                },
                {
                    formName: selectedForm.name,
                },
            );
            cloneForm(selectedId);
        }
    };

    const cloneForm = async (recordId: string): Promise<void> => {
        try {
            const cloneId = await FormsClient.CloneGenericFormRecord(authContext, recordId);
            if (cloneId !== '' || cloneId !== undefined) {
                history.push(`/forms/respond/${selectedForm.name}/${cloneId}`);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const titleId = useId();
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={(): void => setIsOpen(false)}
            containerClassName={modalContainerStyle}>
            <div>
                <div className={modalStyles.header}>
                    <h2 className={modalStyles.heading} id={titleId}>
                        Duplicate Form
                    </h2>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel='Close popup modal'
                        onClick={(): void => setIsOpen(false)}
                    />
                </div>
                <div className={modalStyles.body}>
                    <p>
                        Duplicate and reuse a form from a previous submission. Reusing a form with
                        similar information can save time. Once completed, this will become a new
                        form submission.
                    </p>
                    <Dropdown
                        placeholder='Select a form to duplicate'
                        onChange={(ev, opt): void => setSelectedId(opt?.key.toString())}
                        options={options}
                        label='Form name'
                    />
                    <p>
                        Form not listed? Go to{' '}
                        <Link
                            to={'/forms/my-forms'}
                            className={linkStyles}
                            onClick={(): void => setIsOpen(false)}>
                            My Forms
                        </Link>{' '}
                        for a complete list.
                    </p>
                </div>
                <div className={modalStyles.footer}>
                    <PrimaryButton onClick={duplicateForm}>Duplicate</PrimaryButton>
                    <DefaultButton onClick={(): void => setIsOpen(false)}>Cancel</DefaultButton>
                </div>
            </div>
        </Modal>
    );
}

const linkStyles = mergeStyles(globalStyles.link, {
    textDecoration: 'none',
});

const theme = getTheme();

const modalContainerStyle = mergeStyles({
    maxWidth: '40%',
});

const modalStyles = mergeStyleSets({
    header: [
        {
            fontSize: '20px',
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '16px 24px 20px 24px',
        },
    ],
    heading: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        fontSize: 'inherit',
        margin: '0',
    },
    body: {
        padding: '0px 24px',
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '24px',
        gap: '8px',
    },
});

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
