import {
    MessageBar,
    MessageBarType,
    Stack,
    TextField,
    PrimaryButton,
    DefaultButton,
    ChoiceGroup,
    mergeStyleSets,
} from '@fluentui/react';
import {
    AttributeDataType,
    AttributePredefinedValue,
    AttributePredefinedValueStatus,
    CreatePredefinedValueRequest,
    EditPredefinedValueRequest,
} from 'personnel-core-clients';
import { CoreAttributesClient } from 'clients/core/personnel-core-client-wrappers';
import { AuthContext } from 'contexts/auth-context';
import React from 'react';
import { useContext, useState, useEffect, useMemo, useCallback } from 'react';

export interface IValueEditorProps {
    attributeId: string;
    attributeDataType?: AttributeDataType;
    // assume if undefined we're adding a new value, if set we're editing
    predefinedValue?: AttributePredefinedValue;
    onFinish: () => void;
    onCancel: () => void;
}

export function ValueEditor(props: IValueEditorProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false);
    const [hasValidationError, setHasValidationError] = useState<boolean>(false);
    const [errorResponse, setErrorResponse] = useState<string>();

    const [value, setValue] = useState<string | undefined>(props.predefinedValue?.value);
    const [status, setStatus] = useState<AttributePredefinedValueStatus>(
        props.predefinedValue?.status ?? AttributePredefinedValueStatus.Active,
    );

    useEffect(() => {
        setValue(props.predefinedValue?.value);
        setStatus(props.predefinedValue?.status ?? AttributePredefinedValueStatus.Active);
    }, [props.predefinedValue]);

    const isAdding: boolean = useMemo(() => {
        return props.predefinedValue === undefined;
    }, [props.predefinedValue]);

    const isValueValid = useCallback(() => (value ?? '').toString().trim().length > 0, [value]);

    const onAddClicked = useCallback(async () => {
        setHasValidationError(false);

        if (!isValueValid()) {
            setHasValidationError(true);
            return;
        }

        const attributeClient = new CoreAttributesClient(authContext);
        const request = new CreatePredefinedValueRequest({
            value: value!, // value is validated and is not undefined
        });

        try {
            setIsSendingRequest(true);
            await attributeClient.createPredefinedValue(props.attributeId, request);
            props.onFinish();
        } catch (e) {
            setErrorResponse('Unexpected error occurred when trying to add value');
        } finally {
            setIsSendingRequest(false);
        }
    }, [authContext, value, props.attributeId]);

    const onEditClicked = useCallback(async () => {
        setHasValidationError(false);

        if (!isValueValid()) {
            setHasValidationError(true);
            return;
        }

        const attributeClient = new CoreAttributesClient(authContext);
        const request = new EditPredefinedValueRequest({
            predefinedValueId: value!, // value is validated and is not undefined
            predefinedValueStatus: status,
        });

        try {
            setIsSendingRequest(true);
            await attributeClient.editPredefinedValue(props.attributeId, request);
            props.onFinish();
        } catch (e) {
            setErrorResponse('Unexpected error occurred when trying to edit value');
        } finally {
            setIsSendingRequest(false);
        }
    }, [authContext, value, status, props.attributeId]);

    return (
        <div className={styles.valueEditorContainer}>
            {errorResponse && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    dismissButtonAriaLabel='Close'
                    onDismiss={() => setErrorResponse(undefined)}>
                    {errorResponse}
                </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 40 }}>
                <div className={styles.valueEditorTextAndButtonsContainer}>
                    <TextField
                        className={styles.valueEditorTextField}
                        label='Value'
                        placeholder='Enter value'
                        value={value}
                        onChange={(ev, newVal) => setValue(newVal)}
                        disabled={!isAdding}
                        errorMessage={
                            hasValidationError && !isValueValid() ? 'Value must not be empty' : ''
                        }
                        type={
                            props.attributeDataType === AttributeDataType.Integer
                                ? 'number'
                                : undefined
                        }
                    />
                    <Stack horizontal tokens={{ childrenGap: 15 }}>
                        <PrimaryButton
                            text={isAdding ? 'Add value' : 'Update value'}
                            onClick={() => (isAdding ? onAddClicked() : onEditClicked())}
                            disabled={isSendingRequest}
                        />
                        <DefaultButton text='Cancel' onClick={props.onCancel} />
                    </Stack>
                </div>
                <div>
                    <ChoiceGroup
                        label='Status'
                        selectedKey={
                            status === AttributePredefinedValueStatus.Active ? 'active' : 'inactive'
                        }
                        options={[
                            {
                                key: 'active',
                                text: 'Active',
                            },
                            {
                                key: 'inactive',
                                text: 'Not active',
                            },
                        ]}
                        onChange={(ev, opt) =>
                            setStatus(
                                opt?.key === 'active'
                                    ? AttributePredefinedValueStatus.Active
                                    : AttributePredefinedValueStatus.Inactive,
                            )
                        }
                        disabled={isAdding}
                    />
                </div>
            </Stack>
        </div>
    );
}

const styles = mergeStyleSets({
    valueEditorContainer: { marginLeft: '55px' },
    valueEditorTextAndButtonsContainer: { width: '230px' },
    valueEditorTextField: { marginBottom: '20px' },
});
