import React, { useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import EligibilityClient, { IAttributeAssignmentDetail } from 'clients/eligibility-client';
import { IEmployee } from 'clients/employee-client';
import { IconNames } from 'assets/constants/global-constants';
import { readErrorMessageBody } from 'utils/misc-utils';
import { UserAssignmentsContext } from 'components/user-assignments/user-assignments-context';
import { IVisitorProfile } from 'clients/visitor-client';

interface IDeleteAttributeModalButtonProps {
    visitorOrEmployee: IEmployee | IVisitorProfile;
    personnelId: string;
    attribute: IAttributeAssignmentDetail;
    deleteAttributeFromTable: (attribute: IAttributeAssignmentDetail) => void;
}

export default function DeleteAttributeModalButton(
    props: IDeleteAttributeModalButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContextAssignment = useContext(UserAssignmentsContext);

    const onSubmit = async (): Promise<void> => {
        try {
            const attributeId = userContextAssignment.attributes.find(
                (a) => a.attributeCode === props.attribute.attributeCode,
            )?.id;

            if (attributeId) {
                await EligibilityClient.deletePersonnelAttribute(
                    authContext,
                    // The following type cast is safe:
                    // button is disabled if personnelId is undefined
                    props.personnelId as string,
                    attributeId,
                );
            }
        } catch (e) {
            const submitErrorEventText = await readErrorMessageBody(e);
            if (submitErrorEventText) {
                throw submitErrorEventText;
            } else {
                console.error('Error processing - delete assignment attribute');
                // I don't know what the error is.
                // Throw it and let the modal catch it.
                throw e;
            }
        }
    };

    const onDeleteAttributeConcluded = (conclusion: ModalConclusion): void => {
        if (conclusion === ModalConclusion.Done) {
            props.deleteAttributeFromTable(props.attribute);
        }
    };

    return (
        <ModalActionButton<void>
            text={'Delete'}
            enable={props.personnelId !== undefined}
            iconName={IconNames.Delete}
            modalTitle={'Delete Attribute'}
            modalTitleIcon={IconNames.Delete}
            enableSubmit={true}
            submitButtonText={'Delete'}
            submitButtonIcon={IconNames.Delete}
            onSubmit={onSubmit}
            onModalConcluded={onDeleteAttributeConcluded}>
            <span>
                Are you sure you want to delete attribute{' '}
                <strong>{props.attribute.attributeCode}</strong>?
            </span>
        </ModalActionButton>
    );
}
