import React, { useContext, useState } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { AuthContext } from 'contexts/auth-context';
import ScaClient, { IReviewPeriod } from 'clients/sca-client';
import { readErrorMessageBody } from 'utils/misc-utils';
import { ReviewStatusNames } from 'components/sca/sca-constants';
import { Dialog, DialogFooter, PrimaryButton, mergeStyles } from '@fluentui/react';
import { globalDialogStyles } from 'assets/styles/global-styles';

interface IScaDeleteReviewModalButtonProps {
    reviewPeriod: IReviewPeriod | undefined;
    onReviewPeriodDeleted: (reviewPeriod?: IReviewPeriod) => void;
}

export default function ScaDeleteReviewModalButton(
    props: IScaDeleteReviewModalButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const [isReviewDeleted, setIsReviewDeleted] = useState<boolean>();

    const isEnableSubmit =
        !!props.reviewPeriod?.id && props.reviewPeriod?.state === ReviewStatusNames.PREPARATION;

    const onDeleteReviewSubmit = async (): Promise<void> => {
        try {
            // The following typecast is safe because thanks to variable isEnableSubmit,
            // if props.reviewPeriod?.id is undefined, the submit button will be disabled.
            await ScaClient.deleteReviewPeriodById(authContext, props.reviewPeriod?.id as string);
        } catch (e) {
            const submitErrorEventText = await readErrorMessageBody(e);
            if (submitErrorEventText) {
                throw submitErrorEventText;
            } else {
                console.error('Error processing - DelAttribute');
                console.error(e);
                // TODO: Find the the error/exception format and handle it correctly.
                // For now throw it and let the modal catch it.
                throw e;
            }
        }
    };

    const onDeleteReviewConcluded = (conclusion: ModalConclusion): void => {
        if (conclusion === ModalConclusion.Done && !!props.reviewPeriod?.id) {
            setIsReviewDeleted(true);
        }
    };

    const onCloseNotification = (): void => {
        props.onReviewPeriodDeleted(props?.reviewPeriod);
    };

    return (
        <>
            <ModalActionButton<void>
                text={'Delete Review'}
                iconName={IconNames.Delete}
                modalTitle={'Delete Review Period'}
                modalTitleIcon={IconNames.Delete}
                enable={isEnableSubmit}
                enableSubmit={isEnableSubmit}
                submitButtonText={'Delete'}
                submitButtonIcon={IconNames.Delete}
                onSubmit={onDeleteReviewSubmit}
                onModalConcluded={onDeleteReviewConcluded}>
                <span>
                    Are you sure you want to delete review period{' '}
                    <strong>{props.reviewPeriod?.id}</strong>?
                </span>
            </ModalActionButton>
            <Dialog
                hidden={!isReviewDeleted}
                modalProps={{ containerClassName: dialogContainerClassName }}>
                <span>
                    Review period <strong>{props.reviewPeriod?.id}</strong> successfully deleted.
                </span>
                <DialogFooter>
                    <PrimaryButton onClick={onCloseNotification} text='OK' />
                </DialogFooter>
            </Dialog>
        </>
    );
}

const dialogContainerClassName = mergeStyles({
    ...globalDialogStyles.dialogBlueRibbonTop,
    minHeight: 'initial',
});
