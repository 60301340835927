import React from 'react';
import { TextField, Stack } from '@fluentui/react';
import { stackChildrenGap } from 'components/personnel-profile/common-personnel-profile-styles';
export interface PrehireCandidateNominationStepEmployeeLegalInfoProps {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    suffix?: string;
    onFirstNameChange: (newValue?: string) => void;
    onMiddleNameChange: (newValue?: string) => void;
    onLastNameChange: (newValue?: string) => void;
    onSuffixChange: (newValue?: string) => void;
}

export default function PrehireCandidateNominationStepEmployeeLegalInfo(
    props: PrehireCandidateNominationStepEmployeeLegalInfoProps,
): JSX.Element {
    return (
        <Stack tokens={stackChildrenGap}>
            <TextField
                label='First name'
                required
                value={props.firstName || ''}
                onChange={(ev, newValue): void => onFieldChange(ev, 'firstName', newValue)}
            />
            <TextField
                label='Middle name'
                value={props.middleName || ''}
                onChange={(ev, newValue): void => onFieldChange(ev, 'middleName', newValue)}
            />
            <TextField
                label='Last name'
                required
                value={props.lastName || ''}
                onChange={(ev, newValue): void => onFieldChange(ev, 'lastName', newValue)}
            />
            <TextField
                label='Suffix'
                value={props.suffix || ''}
                onChange={(ev, newValue): void => onFieldChange(ev, 'suffix', newValue)}
            />
        </Stack>
    );

    function onFieldChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: string,
        newValue?: string,
    ): void {
        if (key === 'firstName') props.onFirstNameChange(newValue);
        else if (key === 'middleName') props.onMiddleNameChange(newValue);
        else if (key === 'lastName') props.onLastNameChange(newValue);
        else if (key === 'suffix') props.onSuffixChange(newValue);
    }
}
