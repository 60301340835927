import { TableCell } from 'components/common/table';
import React from 'react';
import { IColumn, mergeStyles, Stack } from '@fluentui/react';
import { lastColumnStylesButton } from 'assets/styles/list-styles';
import { IScaRecord, IReviewPeriod } from 'clients/sca-client';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { maxWidthCoeff, largeMaxWidthCoeff } from 'assets/constants/global-constants';
import { ReviewStatusNames, getEmployeeReviewStateBackground } from 'components/sca/sca-constants';
import {
    ModalConclusion,
    onModalConcludeType,
} from 'components/common/buttons/modal-action-button';
import ScaEmployeeHistoryDialogButton from 'components/sca/sca-common/sca-employee-history-dialog-button';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import ScaEligibilityDropdown from 'components/sca/my-team/sca-eligibility-dropdown';
import { ScaViewAddEmployeeModalButton } from 'components/sca/manage/sca-view-add-employee-modal-button';
import { ISortableColumns } from 'utils/sort-utils';
import ScaReviewedByTableCell from 'components/sca/sca-common/sca-reviewed-by-table-cell';

interface IReviewsColumns extends ISortableColumns {
    reviewPeriod: IReviewPeriod | undefined;
    onUpdateEligibilityConcluded: onModalConcludeType<IScaRecord>;
}

export function getScaMyTeamReviewColumns(params: IReviewsColumns): IColumn[] {
    const showCommentButton =
        params.reviewPeriod && params.reviewPeriod.state === ReviewStatusNames.APPROVAL;

    const columnWidths = {
        employee: 90,
        status: 160,
        rate: 60,
        reviewedBy: 90,
        reviewedOn: 60,
        comment: 140,
        action: showCommentButton ? 215 : 80,
    };

    const addCommentConcludedHelperFunction = (
        mode: 'add' | 'update',
        modalConclusion: ModalConclusion,
        result: IScaRecord | undefined,
    ): void => {
        params?.onUpdateEligibilityConcluded(modalConclusion, result);
    };

    const columns: IColumn[] = [
        {
            key: 'Employee',
            name: 'Employee',
            ariaLabel: 'Employee',
            isSorted: params.sortColumn === 'Employee',
            isSortedDescending: !params.sortAscending,
            minWidth: columnWidths.employee,
            maxWidth: columnWidths.employee * largeMaxWidthCoeff,
            onColumnClick: (): void => {
                params.sortColumnHandler('Employee');
            },
            onRender: (row: IScaRecord): JSX.Element => {
                return (
                    <TableCell>
                        {/* The following key is important when user sorts by this column. */}
                        <EmployeeBasicHoverCard
                            key={row.personnelId}
                            personnelId={row.personnelId}
                            showMiniCardAlias={false}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: 'Status',
            name: 'Status',
            ariaLabel: 'Status',
            minWidth: columnWidths.status,
            maxWidth: columnWidths.status * maxWidthCoeff,
            onRender: (row: IScaRecord): JSX.Element => {
                return (
                    <TableCell>
                        <Stack horizontal>
                            {params.reviewPeriod?.state === ReviewStatusNames.APPROVAL && (
                                <div
                                    style={{
                                        background: getEmployeeReviewStateBackground(
                                            row.reviewState,
                                        ),
                                        width: 30,
                                        height: 30,
                                        marginTop: 1,
                                        marginRight: 5,
                                    }}></div>
                            )}
                            <ScaEligibilityDropdown
                                reviewPeriod={params.reviewPeriod}
                                employeeReview={row}
                                onUpdateEligibilityConcluded={params.onUpdateEligibilityConcluded}
                            />
                        </Stack>
                    </TableCell>
                );
            },
        },
        {
            key: 'Rate',
            name: 'Rate',
            ariaLabel: 'Rate',
            minWidth: columnWidths.rate,
            maxWidth: columnWidths.rate * maxWidthCoeff,
            onRender: (row: IScaRecord): JSX.Element => {
                return <TableCell>{row.rate.toFixed(2)}%</TableCell>;
            },
        },
        {
            key: 'Reviewed By',
            name: 'Reviewed By',
            ariaLabel: 'Reviewed By',
            minWidth: columnWidths.reviewedBy,
            maxWidth: columnWidths.reviewedBy * largeMaxWidthCoeff,
            onRender: (row: IScaRecord): JSX.Element => {
                return <ScaReviewedByTableCell reviewedBy={row.reviewedBy} />;
            },
        },
        {
            key: 'Reviewed On',
            name: 'Reviewed On',
            ariaLabel: 'Reviewed On',
            minWidth: columnWidths.reviewedOn,
            maxWidth: columnWidths.reviewedOn * maxWidthCoeff,
            onRender: (row: IScaRecord): JSX.Element => {
                if (row.reviewedTimestampUTC) {
                    return (
                        <TableCell>
                            {new Date(row.reviewedTimestampUTC * 1000).toLocaleDateString()}
                        </TableCell>
                    );
                } else {
                    return <></>;
                }
            },
        },
        {
            key: 'Comment',
            name: 'Comment',
            ariaLabel: 'Comment',
            minWidth: columnWidths.comment,
            maxWidth: undefined,
            onRender: (row: IScaRecord): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisTextCss text={row.reviewComment} />
                    </TableCell>
                );
            },
        },
        {
            key: 'Actions',
            name: 'Actions',
            ariaLabel: 'Actions',
            minWidth: columnWidths.action,
            maxWidth: columnWidths.action,
            ...lastColumnStylesButton,
            onRender: (row: IScaRecord): JSX.Element => {
                return (
                    <TableCell>
                        <Stack horizontal>
                            {showCommentButton && (
                                <div className={buttonContainerStyle}>
                                    <ScaViewAddEmployeeModalButton
                                        mode='update'
                                        review={row}
                                        personnelId={row.personnelId}
                                        showRateUpdate={false}
                                        showStatusUpdate={false}
                                        buttonText={'Add Comment'}
                                        modalTitle={
                                            <Stack horizontal>
                                                <span>Add comment for&nbsp;</span>
                                                <EmployeeBasicHoverCard
                                                    noHoverCard={false}
                                                    personnelId={row.personnelId}
                                                    displayActions={false}
                                                    showMiniCardAlias={false}
                                                />
                                            </Stack>
                                        }
                                        showEmployeeCard={false}
                                        noHoverCard={false}
                                        submitButtonText={'Add'}
                                        onAddEditEmployeeConcluded={
                                            addCommentConcludedHelperFunction
                                        }
                                    />
                                </div>
                            )}
                            <div className={buttonContainerStyle}>
                                <ScaEmployeeHistoryDialogButton personnelId={row.personnelId} />
                            </div>
                        </Stack>
                    </TableCell>
                );
            },
        },
    ];
    return columns;
}

const buttonContainerStyle = mergeStyles({
    display: 'inline-block',
});
