/* eslint-disable @typescript-eslint/naming-convention */
import moment from 'moment-timezone';

export const TimeFormats = {
    // For use by package "moment".
    // Example "2021-05-26 11:09:14-07:00" will be formatted to 2021_05_26_11_09
    // eslint-disable-next-line @typescript-eslint/naming-convention
    filenameTimestamp: 'YYYY_MM_DD_HH_mm',
    // ISO 8601. Default serialization of C# DateTime type
    // Example "2021-06-23T14:11:26" will be formatted to "2021-06-23T17:11:26Z"
    isoDateString: 'YYYY-MM-DDTHH:mm:ss[Z]',
    // Example "2021-06-23T14:11:26" will be formatted to "6/23/2021"
    MDYYYY: 'M/D/YYYY',
    // Example "2021-06-23T14:11:26" will be formatted to "6/23/2021 14:11"
    MDYYYY_24hour: 'M/D/YYYY HH:mm',
    // Example output: "Jul 09, 2021"

    MMMDDYYYY: 'MMM DD, yyyy',
    // Example output: "Jul 9, 2021, 10:43:04 AM"

    MMMDYYYY_hmmssA: 'MMM D, yyyy, h:mm:ss A',
    // Example output: "Jul 09, 2021 10:43 AM"

    MMMDDYYYY_hmmA: 'MMM DD, yyyy h:mm A',
    // Example output: "Wednesday 19 June 2019 8:33 PM"

    ddddDDMMMMYYYY_hmmA: 'dddd DD MMMM yyyy h:mm A',
    // Example output: "07_28_2021_0931"

    MM_DD_YYYY_HHmm: 'MM_DD_YYYY_HHmm',
    // Example output: "2022-03-02"

    YYYYMMDD: 'yyyy-MM-DD',

    // Example output for Oct 30 2024: '20241030'
    yyyyMMDD: 'yyyyMMDD',

    // Example output: "12:00 (03/18/2022)"

    H_mm_YYYYMMDD: 'H:mm (M/DD/yyyy)',
    // Example output: "12:00:00"

    H_mm_ss: 'H:mm:ss',
    // Example output: "12:00"

    H_mm: 'H:mm',
    // Example output: "02:10 PM"

    H_mmA: 'h:mm A',
    // Example output: 'Thu, 02 Jun, 2022'

    ddd_DD_MMM_YYYY: 'ddd, DD MMM, yyyy',
};

export const timeToString = (
    // A "number" must be in "milliseconds since the epoch".
    date: Date | number | string | undefined,
    momentFormatString: string,
    defaultValue?: string,
): string => {
    if (!!date) {
        // Exclude 0, '', null and undefined
        return moment(date).format(momentFormatString);
    }
    return defaultValue ?? '';
};

export const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24; // 86400000
export const milisecondsPerDayMinus1 = DAY_IN_MILLISECONDS - 1;
export const MAX_DATE_IN_UNIX_SECONDS = 253402300799; //.Net DateTimeOffset.MaxValue.ToUnixTimeSeconds()

/**
 * Convert minutes to seconds
 *
 * @example
 * minutesToSeconds(4) => 240
 *
 * @param {number} minutes
 * @returns {number} the input (minutes), converted to seconds
 */
export const minutesToSeconds = (minutes: number): number => minutes * 60;

/**
 * Convert minutes to hours
 *
 * @example
 * minutesToHours(120) => 2
 *
 * @param {number} minutes
 * @returns {number} the input (minutes), converted to hours
 */
export const minutesToHours = (minutes: number): number => minutes / 60;

/**
 * Convert minutes to milliseconds
 *
 * @example
 * minutesToMilliseconds(4) => 240000
 *
 * @param {number} minutes
 * @returns {number} the input (minutes), converted to milliseconds
 */
export const minutesToMilliseconds = (minutes: number): number => minutes * 60000;

/**
 * Convert hours to seconds
 *
 * @example
 * hoursToSeconds(4) => 14400
 *
 * @param {number} hours
 * @returns {number} the input (hours), converted to seconds
 */
export const hoursToSeconds = (hours: number): number => hours * 3600;

/**
 * Convert seconds to milliseconds
 * if seconds is undefined|null undefined will be returned
 *
 * @example
 * secondsToMilliseconds(4) => 1000
 *
 * @param {number | undefined | null} seconds
 * @returns {number | undefined} the input (seconds), converted to milliseconds
 */
export const secondsToMilliseconds = (seconds: number | undefined | null): number | undefined =>
    seconds ? seconds * 1000 : undefined;

/**
 * Convert milliseconds to hours
 *
 * @example
 * millisecondsToHours(1) => 0.277777778 nanoseconds
 *
 * @param {number} milliseconds
 * @returns {number} the input (milliseconds), converted to hours
 */
export const millisecondsToHours = (milliseconds: number): number => milliseconds / 3600000;

/**
 * Convert hours to milliseconds
 *
 * @example
 * hoursToMilliseconds(1) => 3600000 milliseconds
 *
 * @param {number} hours
 * @returns {number} the input (hours), converted to milliseconds
 */
export const hoursToMilliseconds = (hours: number): number => hours * 3600000;

/**
 * @deprecated use dateToLocalDate.
 *
 * Provides a short date format like '9/1/2020'
 * empty '' if utcSeconds is < 0 or undefined
 */
export function unixTimeStampToLocalDate(utcSeconds: number | undefined | null): string {
    return dateToLocalDate(secondsToMilliseconds(utcSeconds));
}

/**
 * @deprecated use dateToLocalLongDateFormat.
 *
 * Provides a long date format like 'September 1, 2020'
 * empty '' if utcSeconds is < 0 or undefined
 */
export function unixTimeStampToLocalLongDateFormat(utcSeconds: number | undefined | null): string {
    return dateToLocalLongDateFormat(secondsToMilliseconds(utcSeconds));
}

/**
 * @deprecated use dateToFormattedDateTimeString.
 *
 * provides unix Timestamp format like '09/24/2020 12:00 AM'
 * empty '' if utcSeconds is < 0 or undefined
 */
export function unixTimeStampToFormattedDateString(
    utcValue: number | undefined | null,
    isMilliseconds = false,
): string {
    return dateToFormattedDateTimeString(
        isMilliseconds ? utcValue : secondsToMilliseconds(utcValue),
    );
}

/**
 * @deprecated use dateToFormattedTimeString
 *
 * provides unix Timestamp format like '12:00 AM'
 * empty '' if utcSeconds is < 0 or undefined
 */
export function unixTimeStampToFormattedTimeString(utcSeconds: number | undefined | null): string {
    return dateToFormattedTimeString(secondsToMilliseconds(utcSeconds));
}

export function daysBetweenDates(date1: Date, date2: Date): number {
    const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return Math.floor((utc2 - utc1) / DAY_IN_MILLISECONDS);
}

/**
 * Provides a short date format like '9/1/2020'
 * empty '' if utcSeconds is < 0 or undefined
 * utcTime is either a Date or the number should be in milliseconds.
 */
export function dateToLocalDate(utcTime: Date | number | undefined | null): string {
    const utcMilliseconds = toUtcMilliseconds(utcTime);
    if (!utcMilliseconds) {
        return '';
    }
    const utcDate = new Date(utcMilliseconds);
    return utcDate.toLocaleDateString();
}

/**
 *  Provides a long date format like 'September 1, 2020'
 * empty '' if utcSeconds is < 0 or undefined
 * utcTime is either a Date or the number should be in milliseconds.
 * timeZone is the time zone you want the date displayed in.
 */
export function dateToLocalLongDateFormat(
    utcTime: Date | number | undefined | null,
    timeZone?: string,
): string {
    const utcMilliseconds = toUtcMilliseconds(utcTime);
    if (!utcMilliseconds) {
        return '';
    }
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: timeZone,
    }).format(utcMilliseconds);
}

/**
 *  Provides a short date format like 'Sep 1, 2020'
 * empty '' if utcSeconds is < 0 or undefined
 * utcTime is either a Date or the number should be in milliseconds.
 * timeZone is the time zone you want the date displayed in.
 */
export function dateToLocalShortDateFormat(
    utcTime: Date | number | undefined | null,
    timeZone?: string,
): string {
    const utcMilliseconds = toUtcMilliseconds(utcTime);
    if (!utcMilliseconds) {
        return '';
    }
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: timeZone,
    }).format(utcMilliseconds);
}

/**
 *  Provides a short date format with the day of the week. e.g. 'Tue, Aug 17, 2021'
 *  empty '' if utcSeconds is < 0 or undefined
 *  utcTime is either a Date or the number should be in milliseconds.
 */
export function dateToLocalShortDateWithDayOfWeekFormat(
    utcTime: Date | number | undefined | null,
): string {
    const utcMilliseconds = toUtcMilliseconds(utcTime);
    if (!utcMilliseconds) {
        return '';
    }

    const date = new Date((utcTime as number) * 1000);
    return getDayShortName(date) + ', ' + dateToLocalShortDateFormat(date);
}

/**
 * provides unix Timestamp format like '09/24/2020 12:00 AM'
 * empty '' if utcSeconds is < 0 or undefined
 * utcTime is either a Date or the number should be in milliseconds.
 */
export function dateToFormattedDateTimeString(utcTime: Date | number | undefined | null): string {
    const utcMilliseconds = toUtcMilliseconds(utcTime);
    return dateToFormattedDateTimeStringFromMillis(utcMilliseconds);
}

/**
 * provides unix Timestamp format like '09/24/2020 12:00 AM'
 * empty '' if utcSeconds is < 0 or undefined
 */
export function dateToFormattedDateTimeStringFromSeconds(utcSeconds: number | undefined): string {
    if (!utcSeconds) {
        return '';
    }
    return dateToFormattedDateTimeStringFromMillis(utcSeconds * 1000);
}

/**
 * provides unix Timestamp format like '09/24/2020 12:00 AM'
 * empty '' if utcSeconds is < 0 or undefined
 */
export function dateToFormattedDateTimeStringFromMillis(
    utcMilliseconds: number | undefined,
): string {
    if (!utcMilliseconds) {
        return '';
    }
    return new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        hour12: true,
        minute: '2-digit',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    })
        .format(utcMilliseconds)
        .replace(',', '');
}

/**
 * provides unix Timestamp format like '12:00 AM'
 * empty '' if utcSeconds is < 0 or undefined
 * utcTime is either a Date or the number should be in milliseconds.
 * timeZone is the time zone you want the date displayed in.
 */
export function dateToFormattedTimeString(
    utcTime: Date | number | undefined | null,
    timeZone?: string,
): string {
    const utcMilliseconds = toUtcMilliseconds(utcTime);
    if (!utcMilliseconds) {
        return '';
    }
    return new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        hour12: true,
        minute: '2-digit',
        timeZone: timeZone,
    }).format(utcMilliseconds);
}

/**
 * Unified Type safe way to convert date|number| undefined | null into a number | undefined value for use in follow on development
 */
function toUtcMilliseconds(input: Date | number | undefined | null): number | undefined {
    if (!input || input < 0) {
        return undefined;
    }
    if (input instanceof Date) {
        return (input as Date).getTime();
    }
    return input;
}

/**
 * Creates a date-time string intended to be used as part of a file name.
 * If an optional date param is passed in then it'll use that instead of today's date.
 *
 * @example
 * // Called at 13:30 on 04 November 2020
 *
 * @returns {string} a formatted date-time string
 */
export function getDateTimeForFilename(reportDate?: Date | null, timeFormat?: string): string {
    const date = reportDate ?? new Date();
    return timeToString(date, timeFormat ?? TimeFormats.MM_DD_YYYY_HHmm);
}

/**
 * Creates a date string in the format 'MM/dd/yyyy' from a given Date object
 */
export function dateToDateStringFormat(date: Date | undefined | null): string {
    if (!date) {
        return '';
    }

    let month = (date.getMonth() + 1).toString();
    month = month.length === 2 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length === 2 ? day : '0' + day;

    const year = date.getFullYear();

    return month + '/' + day + '/' + year;
}

export interface IDateObject {
    Today: () => Date;
    EndOfToday: () => Date;
    Now: () => Date;
}

/**
 * Creates a minimal C#-style date object so we can either get the date to the second, as of midnight, or one second before the day ends.
 *
 * @example
 * getDate().Today() => // today's date/time as of midnight
 * getDate().EndOfToday() => // today's date/time as of one second before tomorrow
 * getDate().Now() => // the date/time right now
 *
 * @returns {IDateObject} a new date object
 */
export function getDate(): IDateObject {
    return {
        Today: (): Date => {
            const date = new Date();
            date.setHours(0, 0, 0, 0);
            return date;
        },
        EndOfToday: (): Date => {
            const date = new Date();
            date.setHours(23, 59, 59, 999);
            return date;
        },
        Now: (): Date => new Date(),
    };
}

export function getMonthShortName(m: number): string | undefined {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][m];
}

export function getDayShortName(m: Date | number): string | undefined {
    if (m instanceof Date) return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][m.getDay()];
    else return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][m];
}

export function datePlusDays(date: Date | undefined, days: number): Date | undefined {
    if (date) {
        const thatDayMilliSeconds = date.getTime() + days * 24 * 3600 * 1000;
        return new Date(thatDayMilliSeconds);
    }
    return undefined;
}

export function getDaysSinceDate(timeStampSeconds: number): number | undefined {
    if (!timeStampSeconds) {
        return undefined;
    }

    const currentDate = new Date();
    const currentDateNoTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
    );

    const startDate = new Date(timeStampSeconds * 1000);
    const startDateNoTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate(),
    );

    return Math.floor(
        (currentDateNoTime.getTime() - startDateNoTime.getTime()) / DAY_IN_MILLISECONDS,
    );
}

/**
 * @date An ISO format date string.
 *
 * Example 1:
 * Input: "2021-01-01T00:00:00"
 * Output: 1609459200000
 * Example 2:
 * Input: "2022-12-31T00:00:00"
 * Output: 1672444800000
 *
 * If date undefined then return 0;
 *
 * The difference from Date.parse method is that it parses input as local timezone,
 * while this method parses as UTC timezone.
 * See time-utils.test.ts for more examples
 *
 * @return UTC (Unix Time) millisecond representation of date string, 0 if undefined
 */
export function isoDateStringToUtcMilliseconds(date: string | undefined): number {
    if (date) {
        return moment.utc(date).valueOf();
    }
    return 0;
}

/**
 * @date UTC (Unix Time) millisecond representation of date string
 *
 * Example 1:
 * Input: 1609459200000
 * Output: "2021-01-01T00:00:00"
 * Example 2:
 * Input: 1672444800000
 * Output: "2022-12-31T00:00:00"
 *
 * @return An ISO format date string, undefined if seconds is undefined.
 */
export function utcSecondsToIsoDateString(seconds: number | undefined): string | undefined {
    if (!seconds) {
        return undefined;
    }

    return moment.unix(seconds).utc().format(TimeFormats.isoDateString);
}

// Example return value: "America/Los_Angeles"
export function getLocalTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Return true if the clientTimeZone matches the timezone provided.
 *
 * expected timezone values see
 * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 */
export function isLocalTimeZone(timezone?: string | undefined): boolean {
    return getLocalTimeZone() === timezone;
}

export function localDateToUTCDate(localDate: Date): Date {
    const utcDate = new Date(localDate);
    utcDate.setMinutes(utcDate.getMinutes() + localDate.getTimezoneOffset());
    return utcDate;
}

// Example return value: "PDT"
export function timeZoneAbbr(timezone?: string | undefined, localDate?: Date): string {
    if (timezone) {
        return moment(localDate).set('hours', 3).tz(timezone).zoneAbbr();
    }
    return '';
}

export function getOffsetInHoursFromTimeZoneToTimeZoneString(
    fromTimeZone: string,
    toTimeZone: string,
): string {
    const value = getOffsetInHoursFromTimeZoneToTimeZone(fromTimeZone, toTimeZone);
    const symbol = value > 0 ? '+' : '';
    return `${symbol}${value}`;
}

export function getOffsetInHoursFromTimeZoneToTimeZone(
    fromTimeZone: string,
    toTimeZone: string,
): number {
    const fromUtcOffsetInHours = minutesToHours(moment.utc().tz(fromTimeZone).utcOffset());
    const toUtcOffsetInHours = minutesToHours(moment.utc().tz(toTimeZone).utcOffset());
    const deltaHours = toUtcOffsetInHours - fromUtcOffsetInHours;
    return deltaHours;
}

export const createDateTimeString = (date: number): string => {
    const dateStr = dateToLocalShortDateFormat(date ?? 0); // "Jan 18, 2023"
    const timeStr = new Date(date ?? 0).toLocaleTimeString(); // "2:42:04 PM"
    return `${dateStr} ${timeStr}`;
};

// Example:  Mon, January 29, 2024 10:28:05 PST
export const createShortDateTimeWithZone = (date: number): string => {
    const newDate = new Date(date);
    const dateString = newDate.toLocaleDateString(undefined, {
        day: 'numeric',
        year: 'numeric',
        weekday: 'short',
        month: 'long',
    });
    const timeString = newDate.toLocaleTimeString(undefined, {
        hour12: false,
        timeZoneName: 'short',
    });
    return `${dateString} ${timeString}`;
};

export const validateDateString = (dateString: string, isEmptyOk: boolean): boolean => {
    if (!dateString) return isEmptyOk;
    return new Date(dateString).toString() !== 'Invalid Date';
};

export const dateStringToIsoString = (dateString: string): string => {
    if (!dateString.trim()) return '';
    return new Date(dateString).toISOString();
};
