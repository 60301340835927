import React, { useEffect, useState } from 'react';
import { Label, TextField } from '@fluentui/react';

export interface CandidateNominationStepContractDetailsProps {
    onContractInputChange: (contract: string) => void;
    contractInput?: string;
}

function CandidateNominationStepContractDetails(
    props: CandidateNominationStepContractDetailsProps,
): JSX.Element {
    const [contractInput, setContractInput] = useState<string>('');

    function onContractInputChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void {
        if (typeof newValue === 'string') {
            setContractInput(newValue);
            props.onContractInputChange(newValue);
        }
    }

    useEffect(() => {
        if (props.contractInput) {
            setContractInput(props.contractInput);
        }
    }, [props.contractInput]);

    return (
        <>
            <Label required>Government contract ID</Label>
            <TextField
                ariaLabel='Government contract ID'
                value={contractInput}
                onChange={onContractInputChange}
            />
        </>
    );
}

export default CandidateNominationStepContractDetails;
