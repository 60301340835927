import React from 'react';
import { TableCell } from 'components/common/table';
import { IColumn } from '@fluentui/react';
import { lastColumnStylesText } from 'assets/styles/list-styles';
import { xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { timeToString, TimeFormats } from 'utils/time-utils';
import { IEligibilityLogTableRow } from 'components/eligibilities/eligibilities/view/audit-log/display-eligibility-audit-log';
import EllipsisTextCss from 'components/common/ellipsis-text-css';

export function getEligibilityAuditLogColumns(): IColumn[] {
    type RowType = IEligibilityLogTableRow;

    // Return the alias if the string is email address, otherwise return undefined.
    const getAlias = (str: string): string | undefined =>
        /@/.test(str) ? str.replace(/@.*/, '') : undefined;

    // Return the string verbatim if the string is not an email address, otherwise return undefined.
    const getPersonnelId = (str: string): string | undefined => (/@/.test(str) ? undefined : str);

    const columnWidths = {
        actionBy: 70,
        action: 100,
        justification: 50,
        reactionBy: 70,
        reaction: 100,
        comment: 100,
        date: 140,
    };

    const columns = [
        {
            key: 'Action By',
            name: 'Action By',
            ariaLabel: 'Action performed by',
            minWidth: columnWidths.actionBy,
            maxWidth: columnWidths.actionBy * xLargeMaxWidthCoeff,
            onRender: (row: RowType): JSX.Element => (
                <TableCell key='actionBy'>
                    <EmployeeBasicHoverCard
                        alias={getAlias(row.actionBy)}
                        personnelId={getPersonnelId(row.actionBy)}
                        showMiniCardAlias={false}
                    />
                </TableCell>
            ),
        },
        {
            key: 'Action',
            name: 'Action',
            ariaLabel: 'Action',
            minWidth: columnWidths.action,
            maxWidth: columnWidths.action * xLargeMaxWidthCoeff,
            onRender: (row: IEligibilityLogTableRow): JSX.Element => (
                <TableCell key='action'>
                    <EllipsisTextCss text={row.action} />
                </TableCell>
            ),
        },
        {
            key: 'Justification',
            name: 'Justification',
            ariaLabel: 'Justification for Action',
            minWidth: columnWidths.justification,
            maxWidth: columnWidths.justification * xLargeMaxWidthCoeff,
            onRender: (row: IEligibilityLogTableRow): JSX.Element => (
                <TableCell key='justification'>
                    <EllipsisTextCss text={row.justification} />
                </TableCell>
            ),
        },
        {
            key: 'Reaction By',
            name: 'Reaction By',
            ariaLabel: 'Reaction performed by',
            minWidth: columnWidths.reactionBy,
            maxWidth: columnWidths.reactionBy * xLargeMaxWidthCoeff,
            onRender: (row: RowType): JSX.Element => (
                <TableCell key='reactionBy'>
                    <EmployeeBasicHoverCard
                        alias={getAlias(row.reactionBy)}
                        personnelId={getPersonnelId(row.reactionBy)}
                        showMiniCardAlias={false}
                    />
                </TableCell>
            ),
        },
        {
            key: 'Reaction',
            name: 'Reaction',
            ariaLabel: 'Reaction',
            minWidth: columnWidths.reaction,
            maxWidth: columnWidths.reaction * xLargeMaxWidthCoeff,
            onRender: (row: RowType): JSX.Element => (
                <TableCell key='reaction'>
                    <EllipsisTextCss text={row.reaction} />
                </TableCell>
            ),
        },
        {
            key: 'Comment',
            name: 'Comment',
            ariaLabel: 'Comment',
            minWidth: columnWidths.comment,
            maxWidth: undefined,
            onRender: (row: RowType): JSX.Element => (
                <TableCell key='comment'>
                    <EllipsisTextCss text={row.comment} />
                </TableCell>
            ),
        },
        {
            key: 'Date',
            name: 'Date',
            ariaLabel: 'Date',
            minWidth: columnWidths.date,
            maxWidth: columnWidths.date,
            ...lastColumnStylesText,
            onRender: (row: RowType): JSX.Element => (
                <TableCell key='date'>
                    {timeToString(row.date * 1000, TimeFormats.MMMDDYYYY_hmmA)}
                </TableCell>
            ),
        },
    ];
    return columns;
}
