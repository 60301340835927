import { IChoiceGroupOption } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import GroupClient, {
    IGroup,
    IPointOfContact,
    PocTypeEnum,
    POC_JUSTIFICATION_MAX_LENGTH,
} from 'clients/group-client';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { ModalSizeType } from 'components/common/modal';
import Spacer from 'components/common/spacer';
import useChoiceGroup from 'components/common/use-input/use-choice-group';
import { useCoreEmployeePicker } from 'components/common/use-input/use-core-employee-picker';
import { useTextField } from 'components/common/use-input/use-textfield';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useMemo } from 'react';

export interface IAddPocModalActionButtonProps {
    group: IGroup;
    canAddPoc: boolean;
    onPocAdded: (poc: IPointOfContact) => void;
}

interface IAddPocProps {
    canAddPoc: boolean;
    group: IGroup;
    onPocAdded: (poc: IGroup) => void;
}

const PocTypeEmployee = { key: PocTypeEnum.Employee, text: 'Employee' };
const PocTypeDistributionList = { key: PocTypeEnum.DistributionList, text: 'Distribution List' };
const pocTypeOptions = [PocTypeEmployee, PocTypeDistributionList];

export default function AddPocModalActionButton(props: IAddPocProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const { value: pocType, initialize: initPocType, theElement: pocTypeElement } = useChoiceGroup({
        label: 'Type of POC',
        options: pocTypeOptions,
        horizontal: true,
    });

    const {
        value: employee,
        initialize: initEmployee,
        theElement: employeeElement,
    } = useCoreEmployeePicker({
        label: 'Employee',
        disabled: pocType?.key !== PocTypeEnum.Employee,
        required: pocType?.key === PocTypeEnum.Employee,
    });

    const validateEmailAddress = (): boolean => {
        return /^[\w-.\d]+\@microsoft\.com$/.test(emailAddress ?? '');
    };

    const {
        value: emailAddress,
        isValid: isEmailAddressValid,
        initialize: initEmailAddress,
        theElement: emailAddressElement,
    } = useTextField({
        label: 'Distribution List',
        disabled: pocType?.key !== PocTypeEnum.DistributionList,
        multiline: false,
        placeholder: 'A Microsoft email address',
        required: pocType?.key === PocTypeEnum.DistributionList,
        resizable: false,
        ariaLabel: 'Distribution List',
        validate: validateEmailAddress,
    });

    const validateJustification = (): boolean => {
        return !props.group.requireJustification || !!justification;
    };

    const {
        value: justification,
        isValid: isJustificationValid,
        initialize: initJustification,
        theElement: justificationElement,
    } = useTextField({
        autoAdjustHeight: false,
        label: 'Justification',
        disabled: false,
        maxLength: POC_JUSTIFICATION_MAX_LENGTH,
        multiline: true,
        placeholder: 'Justification',
        required: props.group.requireJustification,
        resizable: false,
        rows: 5,
        ariaLabel: 'Justification',
        validate: validateJustification,
    });

    const onButtonClick = (): void => {
        initPocType(PocTypeEmployee);
        initEmployee(undefined);
        initEmailAddress(undefined);
        initJustification(undefined);
    };

    const isSubmitEnabled = useMemo((): boolean => {
        const isEn1 = props.canAddPoc;
        const isEn2 = pocType === PocTypeEmployee ? !!employee?.id : true;
        const isEn3 = pocType === PocTypeDistributionList ? !!isEmailAddressValid : true;
        const isEn4 = !!isJustificationValid;
        return isEn1 && isEn2 && isEn3 && isEn4;
    }, [props.canAddPoc, pocType, employee, isJustificationValid, isEmailAddressValid]);

    const onSubmit = async (): Promise<IGroup> => {
        try {
            // The following typecasts are safe because parameter validation
            // won't let the code reach here if any parameter is invalid.
            const result = await GroupClient.addPoc(authContext, props.group.id, {
                contactValueType: (pocType as IChoiceGroupOption).key as PocTypeEnum,
                contactValue:
                    (pocType as IChoiceGroupOption).key === PocTypeEnum.Employee
                        ? (employee as IPrincipalRecord).id
                        : (emailAddress as string),
                justification: justification,
            });
            return result;
        } catch (e) {
            throw e;
        }
    };

    const onModalConcluded = async (
        conclusion: ModalConclusion,
        result?: IGroup,
    ): Promise<void> => {
        if (conclusion === ModalConclusion.Done && !!result) {
            props.onPocAdded(result);
        }
    };

    return (
        <ModalActionButton<IGroup>
            text={'Add Point of Contact'}
            size={ModalSizeType.mediumLarge}
            fixWidth={true}
            enable={props.canAddPoc}
            iconName={IconNames.AddFriend}
            modalTitle={'Add Point of Contact'}
            enableSubmit={isSubmitEnabled}
            submitButtonText={'Add'}
            onButtonClick={onButtonClick}
            onSubmit={onSubmit}
            onModalConcluded={onModalConcluded}>
            {props.group.name}
            <Spacer marginTop={10} />
            {pocTypeElement()}
            <Spacer marginTop={10} />
            {(pocType?.key === undefined || pocType?.key === PocTypeEnum.Employee) &&
                employeeElement()}
            <Spacer marginTop={10} />
            {pocType?.key === PocTypeEnum.DistributionList && emailAddressElement()}
            <Spacer marginTop={10} />
            {justificationElement()}
        </ModalActionButton>
    );
}
