import {
    GroupRole,
    IPrincipalAttributeExpression,
    LogicalOperatorType,
} from 'clients/group-client';
import React, { useContext } from 'react';
import AttributeRuleExpression from 'components/groups/manage-group/policy/builder/attribute-rule-expression';
import { Checkbox, mergeStyleSets } from '@fluentui/react';
import AndOrToggle from 'components/groups/manage-group/policy/builder/and-or-toggle';
import {
    ExpressionMetadataDictionary,
    GlobalCollapseState,
} from 'components/groups/manage-group/policy/builder/attribute-policy-builder';
import { IGetVisibleAttributeResult } from 'personnel-core-clients';
import HiddenCondition, {
    showValidCondition,
} from 'components/groups/manage-group/policy/builder/hidden-condition';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';

export interface IAttributeRuleExpressionGroupProps {
    attributes: IGetVisibleAttributeResult[];
    expression: IPrincipalAttributeExpression;
    globalCollapseState: GlobalCollapseState | null;
    expressionMetadata: ExpressionMetadataDictionary;

    onCheckboxChange: (id: string, checked: boolean) => void;
    onExpressionChange: (ex: IPrincipalAttributeExpression) => void;
    onCollapseToggle: () => void;
}

export default function AttributeRuleExpressionGroup(
    props: IAttributeRuleExpressionGroupProps,
): JSX.Element {
    const groupContext = useContext(ManageGroupContext);
    const children = props.expression.children ?? [];

    return (
        <div>
            <div className={styles.andOrToggleContainer}>
                {props.expression?.op && (
                    <AndOrToggle
                        selected={props.expression.op}
                        onChange={(newOp: LogicalOperatorType) =>
                            props.onExpressionChange({ ...props.expression, op: newOp })
                        }
                    />
                )}
            </div>
            <div style={{ display: 'flex' }}>
                <div className={styles.groupConnector}>
                    <Checkbox
                        ariaLabel='Group Connector'
                        onChange={(ev, checked) =>
                            props.onCheckboxChange(props.expression.id, !!checked)
                        }
                    />
                </div>
                <div className={styles.groupContainer}>
                    {children.map((ex, index) => {
                        if (ex.children) {
                            return (
                                <AttributeRuleExpressionGroup
                                    key={ex.id}
                                    attributes={props.attributes}
                                    expression={ex}
                                    globalCollapseState={props.globalCollapseState}
                                    expressionMetadata={props.expressionMetadata}
                                    onCheckboxChange={props.onCheckboxChange}
                                    onExpressionChange={props.onExpressionChange}
                                    onCollapseToggle={props.onCollapseToggle}
                                />
                            );
                        } else {
                            if (
                                groupContext.groupMembershipVar?.value?.role === GroupRole.ADMIN ||
                                showValidCondition(ex)
                            ) {
                                return (
                                    <AttributeRuleExpression
                                        key={ex.id}
                                        ruleName={String(
                                            props.expressionMetadata.getExpressionRuleNumber(ex.id),
                                        )}
                                        expression={ex}
                                        attributes={props.attributes}
                                        globalCollapseState={props.globalCollapseState}
                                        onCheckboxChange={(checked) =>
                                            props.onCheckboxChange(ex.id, checked)
                                        }
                                        onExpressionChange={props.onExpressionChange}
                                        onCollapseToggle={props.onCollapseToggle}
                                    />
                                );
                            } else {
                                return (
                                    <HiddenCondition
                                        ruleNumber={String(
                                            props.expressionMetadata.getExpressionRuleNumber(ex.id),
                                        )}
                                        key={
                                            'hidden_' +
                                            String(
                                                props.expressionMetadata.getExpressionRuleNumber(
                                                    ex.id,
                                                ),
                                            )
                                        }
                                    />
                                );
                            }
                        }
                    })}
                </div>
            </div>
        </div>
    );
}

const styles = mergeStyleSets({
    groupConnector: {
        borderTop: '1px solid #B4D6FA',
        borderLeft: '1px solid #B4D6FA',
        borderBottom: '1px solid #B4D6FA',
        marginTop: '3px',
        padding: '8px',
        width: '33px',
    },
    groupContainer: {
        borderTop: '7px solid #B4D6FA',
        borderBottom: '7px solid #B4D6FA',
        paddingTop: '25px',
        paddingBottom: '25px',
        width: '100%',
    },
    andOrToggleContainer: {
        width: 80,
        marginTop: 20,
        marginBottom: 30,
    },
});
