import {
    DefaultButton,
    FontIcon,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
} from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import GroupClient, {
    GroupRuleType,
    ICANDAMetadata,
    UserAccessReviewApproverType,
} from 'clients/group-client';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import LargeFont from 'components/common/misc/large-font';
import Spacer from 'components/common/spacer';
import { useCheckbox } from 'components/common/use-input/use-checkbox';
import useMessageBar from 'components/common/use-message-bar';
import {
    CoreEmployeeHoverCardFromGraph,
    CoreEmployeeHoverCardFromPrincipalId,
} from 'components/core/common/employee-card/core-employee-hover-card';
import { groupAgreementsBreadcrumbs } from 'components/groups/manage-group/manage-group-utils';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Redirect, useParams } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import { RedirectType } from 'types/global-types';
import { useFetchSimple } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';
import { TimeFormats, timeToString } from 'utils/time-utils';

/**
 * Enables the user to sign an agreement, or show them
 * the agreement that they have already signed.
 */
export default function GroupRuleAgreementV2(): JSX.Element {
    const authContext = useContext(AuthContext);
    const principalUserContext = useContext(PrincipalUserContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const [agreementMetadata, setAgreementMetadata] = useState<ICANDAMetadata>();
    const [hasJustBeenSigned, setHasJustBeenSigned] = useState<boolean>();
    const [isInProgress, setIsInProgress] = useState<boolean>();
    const [redirectTo, setRedirectTo] = useState<RedirectType>({ to: '', push: false });
    const [pocEmails, setPocEmails] = useState<string[]>([]);

    const { groupId, ruleId, personnelId: personnelIdUrl } = useParams<{
        groupId: string;
        ruleId: string;
        personnelId: string;
    }>();

    const principalId = useMemo((): string | undefined => {
        return personnelIdUrl ?? principalUserContext.principalRecord.id;
    }, [personnelIdUrl, principalUserContext.principalRecord]);

    const isAllowedToSign = useMemo((): boolean => {
        return !!agreementMetadata?.allowedSignerOids.includes(
            principalUserContext.principalRecord.oid ?? '',
        );
    }, [agreementMetadata]);

    const isSelfSign = useMemo((): boolean => {
        return isAllowedToSign && principalUserContext.principalRecord.id === principalId;
    }, [isAllowedToSign]);

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs(groupAgreementsBreadcrumbs(agreementMetadata?.groupName));
    }, [agreementMetadata]);

    const { value: didAgreeCheckbox, theElement: agreeCheckboxElement } = useCheckbox({
        label: 'I have read and agree to the terms',
    });

    const {
        theMessage: loadErrMsg,
        theElement: loadErrMsgElement,
        setMessage: setLoadErrMsg,
    } = useMessageBar({
        type: MessageBarType.error,
    });

    const {
        theMessage: signErrMsg,
        theElement: signErrMsgElement,
        setMessage: setSignErrMsg,
    } = useMessageBar({
        type: MessageBarType.error,
    });

    const { isFetching: isFetchingAgreement } = useFetchSimple({
        dependencies: [groupId, ruleId, principalId],
        canPerformFetch: !!groupId && !!ruleId && !!principalId,
        fetchFunc: async () => {
            setAgreementMetadata(undefined);
            return GroupClient.getAgreement(authContext, groupId, ruleId, principalId as string);
        },
        onSuccess: (item) => {
            setAgreementMetadata(item);
        },
        onError: () => {
            setLoadErrMsg('Error loading agreement');
        },
        onFinally: doNothing,
    });

    useFetchSimple({
        dependencies: [agreementMetadata],
        canPerformFetch:
            !!agreementMetadata &&
            !agreementMetadata?.isSigned &&
            !isSelfSign &&
            agreementMetadata.allowedSignerOids.length === 0,
        fetchFunc: async () => {
            return GroupClient.getPocEmails(authContext, groupId);
        },
        onSuccess: (item) => {
            setPocEmails(item);
        },
        onError: () => {
            setLoadErrMsg('Error getting group POC emails.');
        },
        onFinally: doNothing,
    });

    const signAgreement = async (): Promise<void> => {
        try {
            setIsInProgress(true);
            await GroupClient.signAgreement(authContext, groupId, ruleId, principalId);
            setHasJustBeenSigned(true);
            setIsInProgress(false);
        } catch {
            setSignErrMsg('Error occurred while trying to sign the agreement');
        }
    };

    const showAllowedSigners = (): JSX.Element => {
        if (!!agreementMetadata && !agreementMetadata?.isSigned && !isSelfSign) {
            if (agreementMetadata.allowedSignerOids.length === 0) {
                if (
                    agreementMetadata.ruleType === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE &&
                    agreementMetadata.approverType === UserAccessReviewApproverType.Sponsor
                ) {
                    return (
                        <span>
                            Member does not have a sponsor, please contact the group POCs to add a
                            sponsor: {pocEmails.join(', ')}
                        </span>
                    );
                } else {
                    return (
                        <span>
                            Please contact the group POCs for assistance: {pocEmails.join(', ')}
                        </span>
                    );
                }
            } else {
                return (
                    <div>
                        <span className={globalStyles.mediumLargeFont}>Allowed signers:</span>
                        <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: '5px' }}>
                            {agreementMetadata.allowedSignerOids.slice(0, 2).map(
                                (oid): ReactNode => (
                                    <div key={oid} style={{ paddingRight: '5px' }}>
                                        <CoreEmployeeHoverCardFromGraph key={oid} oid={oid} />
                                    </div>
                                ),
                            )}
                        </div>
                    </div>
                );
            }
        } else {
            return <></>;
        }
    };

    const showAlreadySigned = (): JSX.Element => {
        if (agreementMetadata?.isSigned) {
            return (
                <div style={{ display: 'flex', gap: '5px' }}>
                    <FontIcon iconName='Completed' />
                    <span>Agreement signed by </span>
                    {agreementMetadata?.signerOid && (
                        <CoreEmployeeHoverCardFromGraph
                            key={agreementMetadata.signerOid}
                            oid={agreementMetadata.signerOid}
                        />
                    )}
                    {agreementMetadata?.signedTimestampUTC && (
                        <span>
                            {' '}
                            on{' '}
                            {timeToString(
                                agreementMetadata.signedTimestampUTC * 1000,
                                TimeFormats.MMMDDYYYY_hmmA,
                            )}
                        </span>
                    )}
                    <Spacer marginTop={10} />
                </div>
            );
        } else if (!agreementMetadata?.isSigned && isAllowedToSign) {
            // In this case, the sign agreement button will show.
            // There's therefore no need to show anything here.
            return <></>;
        } else {
            return (
                <>
                    <MessageBar messageBarType={MessageBarType.error}>
                        The agreement has not yet been signed.
                    </MessageBar>
                    <Spacer marginTop={10} />
                </>
            );
        }
    };

    const showJustSigned = (): JSX.Element => {
        if (hasJustBeenSigned) {
            return (
                <>
                    <MessageBar messageBarType={MessageBarType.success}>
                        You successfully signed the Agreement and Attestation.
                    </MessageBar>
                    <Spacer marginTop={10} />
                </>
            );
        } else {
            return <></>;
        }
    };

    const showSignErrMsg = (): JSX.Element => {
        if (!!signErrMsg) {
            return (
                <>
                    {signErrMsgElement()}
                    <Spacer marginTop={10} />
                </>
            );
        } else {
            return <></>;
        }
    };

    const isLoading = isFetchingAgreement;

    if (!!redirectTo.to) {
        return <Redirect to={redirectTo.to} push={redirectTo.push} />;
    }

    return (
        <div>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            {isLoading && <IsLoadingIndicator isLoading={isLoading} msg='Loading information...' />}
            {!!loadErrMsg && (
                <>
                    <Spacer marginTop={10} />
                    {loadErrMsgElement()}
                </>
            )}
            {!isLoading && !!agreementMetadata && (
                <div className={styles.mainContent}>
                    <Spacer marginTop={20} />
                    <CoreEmployeeHoverCardFromPrincipalId
                        principalId={principalId}
                        showMiniCardAlias={true}
                    />
                    <Spacer marginTop={10} />
                    <LargeFont>{agreementMetadata.ruleName}</LargeFont>
                    <Spacer marginTop={5} />
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                        {agreementMetadata.CANDAText.text}
                    </ReactMarkdown>
                    <Spacer marginTop={10} />
                    <Spacer marginTop={10} />
                    {showJustSigned()}
                    {showAlreadySigned()}
                    {showAllowedSigners()}
                    {isAllowedToSign &&
                        !agreementMetadata.isSigned &&
                        !hasJustBeenSigned &&
                        agreeCheckboxElement()}
                    {showSignErrMsg()}
                    <Stack horizontal horizontalAlign='end' gap={20}>
                        <DefaultButton onClick={() => setRedirectTo({ to: '/groups', push: true })}>
                            Go to My Groups
                        </DefaultButton>
                        {isAllowedToSign && !agreementMetadata.isSigned && !hasJustBeenSigned && (
                            <PrimaryButton
                                disabled={!didAgreeCheckbox && !isInProgress}
                                onClick={signAgreement}>
                                Submit
                            </PrimaryButton>
                        )}
                    </Stack>
                </div>
            )}
        </div>
    );
}

const styles = mergeStyleSets({
    mainContent: {
        marginLeft: 8,
    },
});
