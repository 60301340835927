import React, { ReactNode } from 'react';
import { ProblemLoading } from 'components/common/problem-loading/problem-loading';

interface IProblemLoadingDataProps {
    isProblemLoadingData: boolean;
    children?: ReactNode;
}

export function ProblemLoadingData(props: IProblemLoadingDataProps): JSX.Element {
    return (
        <ProblemLoading isProblemLoadingData={props.isProblemLoadingData}>
            {props.children}
        </ProblemLoading>
    );
}
