import { Dictionary } from 'assets/constants/global-constants';

/**
 * The motivation behind this caching utility is to avoid fetching an item
 * while another fetch is in progress for it. This will specially happen if
 * in "zero" time two different callers try to launch a fetch for the same
 * item. Since both fetches are launched at the same time, the second one
 * may not see that the first one has already been launched, thus sending
 * two requests for the very same item.
 *
 * This utility will keep track of what has and what hasn't yet been requested.
 * If not requested, it will launch a fetch request and will return the
 * corresponding "Promise". If already requested, it will return to the caller
 * the "Promise" that represents the first fetch. So, the second caller will
 * just wait for the same promise to resolve or reject. A third requester of
 * the same item will also receive the very same Promise.
 *
 * If the fetch successfully finishes, subsequent requests for that item will
 * receive the item and no fetch will be launched for it.
 */

/**
 * import { ClientOptionsType } from 'clients/client-utils';
 * Tech Debt: Needs a mechanism to invalidate the cache.
 *            Implement support for the option ClientOptionsType.invalidateCache.
 */

const cache: Dictionary<Dictionary<DataItemType>> = {};
let isInitialized = false;

export default class FetchCache {
    static init(): void {
        if (isInitialized) {
            return;
        }
        Object.keys(FetchDataTypes).forEach((dataType) => {
            cache[dataType] = {};
        });
        isInitialized = true;
    }

    static async fetchItem<T>(
        itemType: FetchDataTypes,
        id: string,
        fetchFunction: () => Promise<T>,
    ): Promise<T | undefined> {
        this.init();
        const cachedItemsDictionary = cache[itemType];
        const cachedItem = cachedItemsDictionary[id] ?? initDataItemType();
        // Write back the above value so as to initialize
        // cachedItem in case this is the first call for this id,
        cache[itemType][id] = cachedItem;

        cachedItem.requestCount++; // Statistics

        if (cachedItem.isValueAvailable) {
            // Value is already fetched and is cached.
            cachedItem.requestAfterFetchCount++;
            return cachedItem.value;
        }

        if (!!cachedItem.theFetchingPromise) {
            // Item is being fetched and a promise is available for it.
            cachedItem.requestWhileFetchCount++;
        } else {
            // Let's fetch it.
            cachedItem.fetchCount++;
            cachedItem.id = id;
            if (cachedItem.errorCount >= MAX_ERR_FETCH_COUNT) {
                throw (
                    `Reached maximum fetch attempt count ${MAX_ERR_FETCH_COUNT}` +
                    ` for item type ${itemType} id ${id}. All ended in error.` +
                    ` No more fetch will be attempted for this item.`
                );
            }
            cachedItem.theFetchingPromise = new Promise(async (resolve, reject) => {
                try {
                    const value = await fetchFunction();
                    resolve(value);
                } catch (e) {
                    reject(e);
                }
            });
        }

        try {
            cachedItem.requestsInProgressCount++;
            cachedItem.value = await cachedItem.theFetchingPromise;
            cachedItem.isValueAvailable = true;
            cachedItem.errorCount = 0;
            return cachedItem.value;
        } catch (e) {
            cachedItem.errorCount++;
            throw e;
        } finally {
            cachedItem.requestsInProgressCount--;
            if (cachedItem.requestsInProgressCount <= 0) {
                cachedItem.requestsInProgressCount = 0;
                cachedItem.theFetchingPromise = undefined;
            }
        }
    }

    static logStatistics(): void {
        Object.entries(cache).forEach((cacheEntry) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [itemType, cachedItems] = cacheEntry;
            Object.entries(cachedItems).forEach((cacheItem) => {
                const [itemId, itemCacheValue] = cacheItem;
                const logValues = {
                    id: itemId,
                    requestCount: itemCacheValue.requestCount,
                    fetchCount: itemCacheValue.fetchCount,
                    requestWhileFetchCount: itemCacheValue.requestWhileFetchCount,
                    requestAfterFetchCount: itemCacheValue.requestAfterFetchCount,
                };
                console.log(logValues);
            });
        });
    }
}

// Don't make any more attempt trying to fetch
// the same item if you tried to fetch it this
// many times and it all ended in error.
const MAX_ERR_FETCH_COUNT = 10;

type DataItemType = {
    id: string | undefined;
    // value can be any item in the app that has a unique ID.
    // eslint-dsiable-next-line @typescript-eslint/no-explicit-any
    value: any;
    isValueAvailable: boolean;
    errorCount: number;
    // eslint-dsiable-next-line @typescript-eslint/no-explicit-any
    theFetchingPromise: Promise<any> | undefined;
    requestsInProgressCount: number;

    // Statistics
    requestCount: number;
    fetchCount: number;
    requestWhileFetchCount: number;
    requestAfterFetchCount: number;
};

const initDataItemType = (): DataItemType => ({
    id: undefined,
    value: undefined,
    isValueAvailable: false,
    errorCount: 0,
    theFetchingPromise: undefined,
    requestsInProgressCount: 0,
    // Statistics
    requestCount: 0,
    fetchCount: 0,
    requestWhileFetchCount: 0,
    requestAfterFetchCount: 0,
});

export enum FetchDataTypes {
    SecurityGroup = 'SecurityGroup',
    EmployeeRecord = 'EmployeeRecord',
    GraphUserExtended = 'GraphUserExtended',
}

/**
 * The following can be used for debug.
 * Uncomment the line if required.
 */
// setTimeout(() => { FetchCache.logStatistics() }, 10000)
