import { BadgeColorHex } from 'assets/constants/global-colors';
import {
    ReviewState,
    EmployeeReviewStatuses,
    reviewStatusDisplayText,
    EmployeeReviewStatusCodeType,
    EmployeeReviewStatusDisplayTexts,
} from 'clients/sca-client';
import { IDropdownOption } from '@fluentui/react';
import { Dictionary } from 'assets/constants/global-constants';
import { makeDictFromList } from 'utils/misc-utils';
import { IScaRecord } from 'clients/sca-client';

export enum ReviewStatusNames {
    CLOSED = 'CLOSED',
    REVIEW = 'REVIEW',
    APPROVAL = 'APPROVAL',
    PREPARATION = 'PREPARATION',
}

export type ReviewPeriodStatusCodeType = 0 | 1 | 2 | 3;

type ReviewPeriodStatusType = {
    code: ReviewPeriodStatusCodeType;
    name: keyof typeof ReviewStatusNames;
    sortOrder: ReviewPeriodStatusCodeType;
    backgroundColor: BadgeColorHex;
};

export const reviewPeriodStatuses: () => ReviewPeriodStatusType[] = () => [
    {
        code: 3,
        name: ReviewStatusNames.CLOSED,
        sortOrder: 3,
        backgroundColor: BadgeColorHex.RED,
    },
    {
        code: 2,
        name: ReviewStatusNames.REVIEW,
        sortOrder: 2,
        backgroundColor: BadgeColorHex.BLUE,
    },
    {
        code: 1,
        name: ReviewStatusNames.APPROVAL,
        sortOrder: 0,
        backgroundColor: BadgeColorHex.GREEN,
    },
    {
        code: 0,
        name: ReviewStatusNames.PREPARATION,
        sortOrder: 1,
        backgroundColor: BadgeColorHex.YELLOW,
    },
];

const findReviewPeriodStatus = (status: string): ReviewPeriodStatusType | undefined => {
    const reviewStatus = reviewPeriodStatuses().find((thisStatus) => thisStatus.name === status);
    return reviewStatus;
};

// Go through reviewPeriodStatuses() once, and create a
// dictionary that returns status info based on status name.
// Return result will be similar to:
//    {
//      APPROVAL:    { name: "APPROVAL", code: 1, sortOrder: 0, backgroundColor: ... },
//      PREPARATION: { name: "PREPARATION", code: 0, sortOrder: 1, backgroundColor: ... },
//      etc
//    }
export const ReviewPeriodStatusDict: Dictionary<ReviewPeriodStatusType> = makeDictFromList(
    reviewPeriodStatuses(),
    'name',
);

export const getReviewStatusBackground = (status: string): string => {
    const reviewStatus = findReviewPeriodStatus(status);
    return reviewStatus?.backgroundColor ?? BadgeColorHex.YELLOW;
};

export const getReviewPeriodStatusCode = (
    statusName: string,
): ReviewPeriodStatusCodeType | undefined => {
    const reviewPeriodStatus = findReviewPeriodStatus(statusName);
    return reviewPeriodStatus?.code;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isReviewPeriodStatusNameValid = (statusName: any) => {
    return !!reviewPeriodStatuses().find((status) => status.name === statusName);
};

// Translate review status string that's received
// from backend into its equivalent display test.
export const translateReviewState = (reviewState: string | undefined): string | undefined => {
    const theStatus = EmployeeReviewStatuses.find((status) => status.recordValue === reviewState);
    return (theStatus || {}).displayText;
};

// Translates display text of an employee's review status
// to the equivalent recordValue, ie, the data that comes from backend.
export const getEmployeeReviewStatusRecordValue = (
    reviewState: string | undefined,
): string | undefined => {
    const theStatus = EmployeeReviewStatuses.find((status) => status.displayText === reviewState);
    return (theStatus || {}).recordValue;
};

export const encodeEmployeeReviewStateDisplayText = (
    employeeReviewState: string | undefined,
): EmployeeReviewStatusCodeType | undefined => {
    const theState = EmployeeReviewStatuses.find(
        (state) => state.displayText === employeeReviewState,
    );
    return (theState || {}).code;
};

export const isSelectedStatusValid = (selectedKey: string): boolean => {
    return !!EmployeeReviewStatuses.find((state) => state.displayText === selectedKey);
};

export const getEmployeeReviewStateBackground = (reviewState: string | undefined): string => {
    switch (reviewState) {
        case ReviewState.NotDetermined:
            return BadgeColorHex.YELLOW;
        case ReviewState.Eligible:
            return BadgeColorHex.GREEN;
        case ReviewState.Ineligible:
            return BadgeColorHex.RED;
        case ReviewState.All:
            return BadgeColorHex.GRAY;
        default:
            return BadgeColorHex.GRAY;
    }
};

// The following can potentially be replaced by reviewStatusDisplayText(),
// considering the latter doesn't return the options "All" and "Unknown".
export const getEmployeeReviewStatusText = (reviewState: string): string => {
    switch (reviewState) {
        case ReviewState.NotDetermined:
            return 'Not Determined';
        case ReviewState.Eligible:
            return 'Eligible';
        case ReviewState.Ineligible:
            return 'Ineligible';
        case ReviewState.All:
            return 'All';
        default:
            return 'Unknown';
    }
};

export const employeeReviewStateOptions: IDropdownOption[] = EmployeeReviewStatusDisplayTexts().map(
    (status) => ({
        key: status,
        text: status,
    }),
);

export const employeeEligibleOption = (function (): IDropdownOption {
    const eligibleText = reviewStatusDisplayText(ReviewState.Eligible);
    // The following typecasts are safe because the above cannot be undefined.
    return { key: eligibleText as string, text: eligibleText as string };
})();

export interface IManagerialLevel {
    level: number;
    text: string;
}

export const managerialLevels: IManagerialLevel[] = [
    { level: 1, text: 'L-1 (Manager)' },
    { level: 2, text: 'L-2 (Manager + Escalation)' },
    { level: 3, text: 'L-3 (Manager + Escalation)' },
    { level: 4, text: 'L-4 (Manager + Escalation)' },
];

export const findManagerialLevel = (level: number): IManagerialLevel | undefined => {
    const foundLevel = managerialLevels.find((managerialLevel) => managerialLevel.level === level);
    return foundLevel;
};

export const MinValidRate = 0.01;
export const MaxValidRate = 100;
export const OtherRate = 'Other';

export const rateSelectionOptions: IDropdownOption[] = [
    { key: '3.75', text: '3.75%' },
    { key: '5', text: '5.00%' },
    { key: '6.25', text: '6.25%' },
    { key: OtherRate, text: OtherRate },
];

export enum HistoryPageValidTabs {
    EmployeeHistory = 'employee-history',
    MyHistory = 'my-history',
    MyTeam = 'my-team',
    MyOrg = 'my-org',
}

export enum MyOrgUrlParams {
    ReviewId = 'reviewId',
    ExecId = 'execId',
}

export enum HistoryPageTitle {
    MyOrg = 'My Org',
    MyTeam = 'My Team',
    MyHistory = 'My History',
    EmployeeHistory = 'Employee History',
}

export interface IScaExecOrgData extends IScaRecord {
    reportsTo: {
        id: string; // Personnel ID of manager of a reviewee.
        alias: string;
        name: string;
    };
}
