import { Panel, PanelType, PrimaryButton } from '@fluentui/react';
import React from 'react';
import { PredefinedValueSelector } from 'components/core/attributes/predefined-values/predefined-value-selector';

export interface IPredefinedValuePanelProps {
    isOpen?: boolean;
    title?: string;
    attributeId?: string;
    onDismiss: () => void;
}

export function PredefinedValuePanel(props: IPredefinedValuePanelProps): JSX.Element {
    return (
        <Panel
            isOpen={props.isOpen}
            headerText={props.title}
            closeButtonAriaLabel='Close'
            onDismiss={props.onDismiss}
            isLightDismiss
            onLightDismissClick={props.onDismiss}
            onRenderFooterContent={() => (
                <PrimaryButton onClick={props.onDismiss}>Finish</PrimaryButton>
            )}
            isFooterAtBottom={true}
            type={PanelType.custom}
            customWidth={'501px'}>
            {props.attributeId && <PredefinedValueSelector attributeId={props.attributeId} />}
        </Panel>
    );
}
