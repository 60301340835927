import { IColumn, PrimaryButton } from '@fluentui/react';
import React from 'react';
import { ICloudScreening, IScopeRecord } from 'clients/cloud-screening-client';
import { dateToLocalDate } from 'utils/time-utils';
import { TableCell } from 'components/common/table';
import {
    createScopesElementFromRecords,
    getCloudScreeningStatusLabelScreening,
} from 'components/screening/cloud-screening/cloud-screening-utils';

export function getMyScreeningsTableColumns(
    showRequestButton: boolean,
    setShowRequestModal: (show: boolean) => void,
    scopes: IScopeRecord[],
): IColumn[] {
    return [
        {
            key: 'type',
            name: 'Type',
            ariaLabel: 'Type',
            minWidth: 250,
            maxWidth: 450,
            onRender: (row: ICloudScreening): JSX.Element => {
                return (
                    <TableCell>
                        {row.screeningType === 'CloudScreening'
                            ? 'Cloud Screening'
                            : row.screeningType}
                    </TableCell>
                );
            },
        },
        {
            key: 'request',
            name: 'Request',
            ariaLabel: 'Request',
            minWidth: 250,
            maxWidth: 450,
            onRender: (row: ICloudScreening): JSX.Element => {
                if (showRequestButton) {
                    return (
                        <TableCell>
                            <PrimaryButton
                                text='Request New Screen'
                                iconProps={{ iconName: 'PreviewLink' }}
                                onClick={(): void => setShowRequestModal(true)}
                            />
                        </TableCell>
                    );
                } else {
                    return <></>;
                }
            },
        },
        {
            key: 'status',
            name: 'Status',
            ariaLabel: 'Status',
            minWidth: 150,
            maxWidth: 250,
            isCollapsible: false,
            onRender: (row: ICloudScreening): JSX.Element => {
                return <TableCell>{getCloudScreeningStatusLabelScreening(row)}</TableCell>;
            },
        },
        {
            key: 'lastUpdatedOn',
            name: 'Last Updated On',
            ariaLabel: 'Last Updated On',
            minWidth: 150,
            maxWidth: 250,
            isCollapsible: false,
            onRender: (row: ICloudScreening): JSX.Element => {
                return (
                    <>
                        <TableCell>{dateToLocalDate(row.statusChanged.atUtc)}</TableCell>
                    </>
                );
            },
        },
        {
            key: 'completedOn',
            name: 'Completed On',
            ariaLabel: 'Completed On',
            minWidth: 150,
            maxWidth: 250,
            isCollapsible: false,
            onRender: (row: ICloudScreening): JSX.Element => {
                return (
                    <TableCell>
                        {row.dateCompleted?.atUtc
                            ? dateToLocalDate(row.dateCompleted?.atUtc)
                            : 'Incomplete'}
                    </TableCell>
                );
            },
        },
        {
            key: 'expiresOn',
            name: 'Expires On',
            ariaLabel: 'Expires On',
            minWidth: 150,
            maxWidth: 250,
            isCollapsible: false,
            onRender: (row: ICloudScreening): JSX.Element => {
                return (
                    <TableCell>
                        {row.dateExpires?.atUtc
                            ? dateToLocalDate(row.dateExpires?.atUtc)
                            : 'Not set'}
                    </TableCell>
                );
            },
        },
        {
            key: 'requiredFor',
            name: 'Required For',
            ariaLabel: 'Required For',
            minWidth: 150,
            maxWidth: 250,
            isMultiline: true,
            isCollapsible: false,
            onRender: (): JSX.Element => {
                return createScopesElementFromRecords(scopes);
            },
        },
    ];
}
