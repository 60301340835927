import { jsonToCSV } from 'react-papaparse';
import { initiateBrowserDownload } from 'utils/file-utils';
import {
    replaceDownloadReportTemplatedTimeText,
    transformHeaderToHeaderCase,
    transformHeaderToMicrosoftBrandCasing,
} from 'utils/string-utils';
import { TimeFormats } from 'utils/time-utils';
import moment from 'moment';

export function replaceTemplatedTimeStringInDownloadReport(data: string): string {
    const regEx = /_DateTimeInUTCMilliseconds\(\d+\)_/g;
    return data.replaceAll(regEx, replaceDownloadReportTemplatedTimeText);
}

// as we add more additional options into this exportToExcel we can just add it to this interface
interface IExportOptionalParams {
    formatHeader?: boolean; // Whether to apply formating to CSV column headers or not. Defaults to transforming from camelCase to Title Case.
    formatType?: excelFormatHeader; // Format used for CSV column headers. Defaults to transforming from camelCase to Title Case.
    fileNameTimeFormat?: string; // TimeFormats from time-utils.ts is a good reference for formats
}
interface IExportToExcelParams {
    fileNamePrefix: string;
    ref: HTMLAnchorElement | null;
    options?: IExportOptionalParams;
}

export enum excelFormatHeader {
    headerCase = 'Header Case',
    microsoftBrandCase = 'Microsoft Brand Case',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function exportJsonToCsvFile(jsonArray: any[], params: IExportToExcelParams): void {
    if (params.ref) {
        let csv: string = jsonToCSV(jsonArray);

        if (params.options?.formatHeader) {
            switch (params.options?.formatType) {
                case excelFormatHeader.headerCase:
                    csv = transformHeaderToHeaderCase(csv);
                    break;
                case excelFormatHeader.microsoftBrandCase:
                    csv = transformHeaderToMicrosoftBrandCasing(csv);
                    break;
                default:
                    csv = transformHeaderToHeaderCase(csv);
            }

            csv = transformHeaderToHeaderCase(csv);
        }
        exportCsvString(csv, params);
    }
}

export function exportCsvString(csv: string, params: IExportToExcelParams): void {
    if (params.ref) {
        const report = new Blob([csv], { type: 'text/csv' });
        const filenameTimestamp = moment(new Date()).format(
            params.options?.fileNameTimeFormat
                ? params.options?.fileNameTimeFormat
                : TimeFormats.filenameTimestamp,
        );
        const fileName = `${params.fileNamePrefix}_${filenameTimestamp}`;
        initiateBrowserDownload(report, fileName, 'csv', params.ref);
    }
}
