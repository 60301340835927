import {
    ActionButton,
    DefaultButton,
    DetailsList,
    DetailsListLayoutMode,
    DirectionalHint,
    IColumn,
    IDetailsHeaderProps,
    IRenderFunction,
    PrimaryButton,
    Selection,
    SelectionMode,
    Sticky,
    StickyPositionType,
    TextField,
    TooltipHost,
    Label,
    ComboBox,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import { columnHeaderStyles } from 'assets/styles/list-styles';
import { TableCell } from 'components/common/table';
import LabelInfoIcon from 'components/common/use-input/info-icon-label';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { ElementProps } from 'components/forms/element-viewer';
import { StringDictionary, TableOption } from 'components/forms/forms-common';
import BoldFont from 'components/common/misc/bold-font';
import TableDateViewer from 'components/forms/element-viewer/table-date-viewer';
import TableCountryViewer from 'components/forms/element-viewer/table-country-viewer';

export type TableRow = {
    item: StringDictionary;
    index: number;
};

function TableViewer(props: ElementProps): JSX.Element {
    const { element, updateFormResponse } = props;
    const tableOptions = element.options as TableOption[];
    const [items, setItems] = useState<unknown[]>([]);
    const [editingRow, setEditingRow] = useState<TableRow>();
    const [selectedItem, setSelectedItem] = useState<Selection>();
    const blankItem: StringDictionary = {};

    useEffect(() => {
        tableOptions.forEach((tableOption) => {
            blankItem[tableOption.key] = '';
        });

        if (element.value) {
            const existingItems = element.value;
            setItems(existingItems as unknown[]);
        }
    }, []);

    useEffect(() => {
        updateFormResponse(element, items as StringDictionary[]);
    }, [items]);

    const getDateDisplayValue = (date: string) => {
        if (!date) {
            return '';
        }
        if (date.includes('T')) {
            const dateWithTime = new Date(date).toLocaleString();
            return dateWithTime;
        }
        const dateOnly = new Date(`${date}T00:00`);
        return dateOnly.toLocaleDateString();
    };

    const columns: IColumn[] = useMemo(
        () =>
            (element.options ?? []).map((option, index) => ({
                key: option.key,
                name: option.text,
                fieldName: option.key,
                minWidth: Math.max(option.text.length * 7, 115),
                isResizable: true,
                isMultiline: true,
                onRender: (item: StringDictionary): JSX.Element => {
                    let cellValue = item[option.key] ?? '';
                    if ((option as TableOption).type === 'date') {
                        cellValue = getDateDisplayValue(cellValue);
                    }
                    return (
                        <TableCell>
                            <TooltipHost
                                content={cellValue}
                                directionalHint={DirectionalHint.topLeftEdge}>
                                {cellValue}
                            </TooltipHost>
                        </TableCell>
                    );
                },
            })),
        [element.options],
    );
    const renderLabelWithDescription = (tableOption: TableOption): JSX.Element => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { description, key, text, required } = tableOption;
        if (!description) {
            return (
                <div id={key}>
                    <BoldFont>{text}</BoldFont>
                    {required && <span style={{ color: '#e50000' }}> *</span>}
                </div>
            );
        }
        return (
            <div id={key} style={{ display: 'flex', alignItems: 'baseline' }}>
                <LabelInfoIcon iconHoverContent={description} iconName={IconNames.Info}>
                    {text}
                </LabelInfoIcon>
                {required && <span style={{ color: '#e50000' }}> *</span>}
            </div>
        );
    };

    const CreateInnerElement = (tableOption: TableOption): JSX.Element => {
        const optionValue = editingRow?.item ? editingRow.item[tableOption.key] : '';
        if (tableOption.type === 'textfield') {
            return (
                <>
                    <TextField
                        value={optionValue ?? ''}
                        label={tableOption.text}
                        placeholder='Enter your answer'
                        onRenderLabel={(): JSX.Element => renderLabelWithDescription(tableOption)}
                        aria-labelledby={tableOption.key}
                        onChange={(ev, newValue): void => {
                            setEditingRow((prev) => {
                                if (!prev) {
                                    return;
                                }
                                const newItem = { ...prev };
                                newItem.item[tableOption.key] = newValue ?? '';
                                return newItem;
                            });
                        }}
                    />
                    {!optionValue && tableOption.required && (
                        <div style={{ color: '#e50000' }}>Required field</div>
                    )}
                </>
            );
        } else if (tableOption.type === 'date') {
            return (
                <>
                    <TableDateViewer
                        value={optionValue ?? ''}
                        tableOption={tableOption}
                        setEditingRow={setEditingRow}
                        onRenderLabel={renderLabelWithDescription}
                    />
                    {!optionValue && tableOption.required && (
                        <div style={{ color: '#e50000' }}>Required field</div>
                    )}
                </>
            );
        } else if (tableOption.type === 'dropdown') {
            const dropdownKey = tableOption.options?.find((el) => el.text === optionValue)?.key;
            return (
                <>
                    <ComboBox
                        styles={{
                            container: { width: 300 },
                        }}
                        comboBoxOptionStyles={{
                            optionTextWrapper: { overflow: 'visible', whiteSpace: 'normal' },
                            optionText: { height: 'auto' },
                        }}
                        selectedKey={dropdownKey}
                        label={tableOption.text}
                        placeholder='Select an option'
                        ariaLabel='Select an option'
                        options={tableOption.options ?? []}
                        onRenderLabel={(): JSX.Element => renderLabelWithDescription(tableOption)}
                        aria-labelledby={tableOption.key}
                        onChange={(ev, newValue): void =>
                            setEditingRow((prev) => {
                                if (!prev) {
                                    return;
                                }
                                const newItem = { ...prev };
                                newItem.item[tableOption.key] = newValue?.text ?? '';
                                return newItem;
                            })
                        }
                    />
                    {!dropdownKey && tableOption.required && (
                        <div style={{ color: '#e50000' }}>Required field</div>
                    )}
                </>
            );
        } else if (tableOption.type === 'country') {
            return (
                <>
                    <Label>{renderLabelWithDescription(tableOption)}</Label>
                    <TableCountryViewer
                        value={optionValue}
                        tableOption={tableOption}
                        setEditingRow={setEditingRow}
                    />
                </>
            );
        }
        return <></>;
    };

    const renderDetailsHeader = (): IRenderFunction<IDetailsHeaderProps> => (
        headerProps,
        defaultRender,
    ) => {
        if (!headerProps) {
            return null;
        }

        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...headerProps,
                    styles: columnHeaderStyles,
                })}
            </Sticky>
        );
    };

    return (
        <>
            {!!items.length && (
                <DetailsList
                    items={items}
                    columns={columns}
                    selection={selectedItem}
                    enableUpdateAnimations={true}
                    selectionMode={SelectionMode.single}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    onActiveItemChanged={(item, index): void => {
                        const clonedItem = structuredClone(item);
                        setEditingRow({ item: clonedItem, index: index ?? 0 });
                        setSelectedItem(item);
                    }}
                    onRenderDetailsHeader={renderDetailsHeader()}
                    onRenderCheckbox={(): JSX.Element => (
                        <ActionButton
                            ariaLabel='Edit item'
                            iconProps={{ iconName: IconNames.Edit }}
                        />
                    )}
                />
            )}
            {editingRow?.item === undefined && (
                <PrimaryButton
                    style={{ marginTop: '2rem' }}
                    iconProps={{ iconName: 'Add' }}
                    onClick={(): void => {
                        setEditingRow({ item: blankItem, index: -1 });
                    }}>
                    Add item
                </PrimaryButton>
            )}
            {editingRow?.item !== undefined && (
                <div style={{ marginTop: '1rem' }}>
                    {editingRow.index >= 0 && (
                        <div style={{ marginLeft: '90%', marginBottom: '-.75rem' }}>
                            <ActionButton
                                ariaLabel='Delete item'
                                iconProps={{ iconName: IconNames.Delete }}
                                onClick={(): void => {
                                    setItems((prev) => {
                                        const newItems = [...prev];
                                        newItems.splice(editingRow.index, 1);
                                        return newItems;
                                    });
                                    setEditingRow(undefined);
                                    setSelectedItem(undefined);
                                }}
                            />
                        </div>
                    )}
                    {tableOptions.map((tableOption) => (
                        <div
                            key={tableOption.key}
                            style={{
                                marginBottom: '1.5rem',
                                maxWidth: '865px',
                            }}>
                            {CreateInnerElement(tableOption)}
                        </div>
                    ))}
                    <PrimaryButton
                        style={{ marginRight: '1rem' }}
                        onClick={(): void => {
                            const requiredFieldErrors = tableOptions.filter(
                                (x) =>
                                    x.required &&
                                    (editingRow.item[x.key] === undefined ||
                                        editingRow.item[x.key].length === 0),
                            );

                            if (requiredFieldErrors.length < 1) {
                                setItems((prev) => {
                                    const newItems = [...prev];
                                    if (editingRow.index >= 0) {
                                        newItems[editingRow.index] = editingRow.item;
                                    } else {
                                        editingRow.index = newItems.length;
                                        newItems.push(editingRow.item);
                                    }
                                    return newItems;
                                });
                                setEditingRow(undefined);
                            }
                        }}>
                        Save item
                    </PrimaryButton>
                    <DefaultButton
                        onClick={(): void => {
                            setEditingRow(undefined);
                            setSelectedItem(undefined);
                        }}>
                        Cancel
                    </DefaultButton>
                </div>
            )}
        </>
    );
}

export default memo(TableViewer);
