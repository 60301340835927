import { mergeStyleSets, mergeStyles } from '@fluentui/react';
import { FontWeights, FontSizes, getTheme } from '@fluentui/react';
import { faintBlue, faintYellow, inputFieldErrorColor } from 'assets/constants/global-colors';
import { SharedColors } from '@fluentui/react';

export const lightTextColor = '#ffffffdd';
export const darkTextColor = '#000000dd';
export const checkboxBlue = 'rgb(0, 120, 212)';
export const disabledButtonColor = '#f3f2f1';
export const disabledButtonTextColor = '#d2d7de';
export const errorTypeColor = 'rgb(253, 231, 233)'; // Background color of MessageBarType.error
export const successTypeColor = 'rgb(223, 246, 221)'; // Background color of MessageBarType.success
export const warningTypeColor = 'rgb(255, 244, 206)'; // Background color of MessageBarType.warning
export const etiquetteTextColor = 'rgba(255, 255, 255, 1)'; // Text color of right-hand etiquette text (white)

export const globalStyles = mergeStyleSets({
    backgroundColor: 'linear-gradient(transparent 150px, rgb(234, 234, 234) 100vh)',
    footerColor: '#f2f2f2',
    fontStyles: {
        color: '#323130',
        fontSize: '14px',
        fontWeight: 400,
    },
    // How about using FontWeights instead of hardcoded numbers?
    // See https://developer.microsoft.com/en-us/fluentui#/styles/web/typography
    boldFont: {
        fontWeight: 500,
    },
    smallMediumFont: {
        fontSize: '10px',
    },
    mediumFont: {
        fontSize: '12px',
    },
    fontStylesAlias: {
        color: 'rgba(0,0,0,.536)',
    },
    mediumLargeFont: {
        fontSize: '18px',
    },
    largeFont: {
        fontSize: '20px',
    },
    veryLargeFont: {
        fontSize: '24px',
    },
    h1Font: {
        fontSize: '32px',
    },
    lineHeight: {
        lineHeight: '1.6',
    },
    gutter: {
        marginLeft: '30px',
        marginRight: '30px',
    },
    antiGutter: {
        marginLeft: '-30px',
        marginRight: '-30px',
    },
    TabHeaderPadding: {
        // Can come in handy when you use components <Tabs> and <TabbedContent>.
        // You may need to use it for "contents" of the tabbed panel, if you see
        // the left margin is not enough for it. The value is almost the amount
        // of padding Fluent UI applies to the text of the left-most "tab title".
        marginLeft: '10px',
    },
    // Style dropdownItems is useful for Fluent component Dropdown.
    // It restricts height of the dropdown list of selections to prevent
    // it from being rendered at one side of the dropdown box.
    // Do not use the following on "callout" styling item of Dropdown
    // because it will cause a double scrollbar to appear if list of
    // selections is very long.
    dropdownItems: {
        maxHeight: '300px',
        overflowY: 'scroll',
    },
    boxShadow: {
        // Used for showing employee card.
        // Styling is from the preview site.
        position: 'relative',
        boxShadow: getTheme().effects['elevation8'],
        transition: 'box-shadow 300ms ease 0s',
        borderRadius: '2px',
        backgroundColor: 'white',
    },
    horizontalBar: {
        // Gives a background slightly darker than body background.
        backgroundColor: 'rgba(0, 0, 0, 0.035)',
        padding: '5px 10px',
        width: '100%',
        alignItems: 'center',
    },
    inputFieldError: {
        // The following is similar to styling of validation
        // error message of Fluent component TextField.
        color: inputFieldErrorColor,
        fontSize: '12px',
        fontWeight: 400,
    },
    link: {
        color: faintBlue,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
    },
    // The "label" style is meant to mimic styling of labels
    // on Fluent components. Feel free to add properties to
    // it compatible with them if you feel something is missing.
    label: {
        paddingTop: '5px',
        paddingBottom: '5px',
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.medium,
    },
    separatorText: {
        // Use this with <Text> if you want a separator without a line.
        // Use <Separator styles={globalSeparatorStyles} /> instead if you want a separator with a line.
        fontSize: '14px',
        fontWeight: 500,
        '&::before': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            opacity: 0.9,
        },
    },
    removeTopBottomMargins: {
        marginTop: 0,
        marginBottom: 0,
    },
    statTilesWrapperFix: {
        selectors: {
            '@media (max-width: 940px)': {
                marginTop: '70px',
            },
            '@media (max-width: 508px)': {
                marginTop: '124px',
            },
            '@media (max-width: 312px)': {
                marginTop: '184px',
            },
            '@media (max-width: 250px)': {
                marginTop: '244px',
            },
        },
    },
    myGroupsTable: {
        marginTop: '50px',
        selectors: {
            '@media (max-width: 942px)': {
                marginTop: '80px',
            },
            '@media (max-width: 498px)': {
                marginTop: '144px',
            },
            '@media (max-width: 308px)': {
                marginTop: '194px',
            },
            '@media (max-width: 246px)': {
                marginTop: '248px',
            },
        },
    },
});

export const globalCheckboxStyles = {
    root: {
        paddingBottom: '2px',
        paddingTop: '2px',
    },
};

// Use the follwoing with <Separator styles={globalSeparatorStyles} /> if you want a separator with a line.
// Use <Text className={globalStyles.separatorText}>you text</Text> if you want a separator without a line.
export const globalSeparatorStyles = mergeStyleSets({
    root: {
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                opacity: 0.9,
            },
        },
    },
    content: {
        padding: '0 12px 0 0',
        fontWeight: '500',
    },
});

// Example usage: components/sca/manage/sca-manage-view-id-filters.tsx
export const globalFilterSeparatorStyles = mergeStyleSets(globalSeparatorStyles, {
    content: {
        marginTop: 5,
        marginBottom: 5,
    },
});

export const faintYellowBackground = {
    backgroundColor: faintYellow,
};

export const largeAtTheCenter = mergeStyles(globalStyles.largeFont, {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '100px',
});

export const dialogStyles = mergeStyleSets({
    footer: {
        margin: 0,
    },
    titleIcon: {
        fontSize: 16,
        marginRight: 8,
    },
    title: mergeStyles(
        globalStyles.fontStyles,
        globalStyles.largeFont,
        globalStyles.boldFont,
        globalStyles.veryLargeFont,
        {
            marginTop: 10,
        },
    ),
    contentInner: {
        paddingBottom: 14,
    },
});

export const globalDialogStyles = {
    // Apply the following to "modalProps.containerClassName":
    dialogBlueRibbonTop: { borderTop: `4px solid ${SharedColors.cyanBlue10}` },
};

// Visually hides content such as headings while still allowing them to be accessible via screen reader
// https://webaim.org/techniques/css/invisiblecontent/
export const screenReaderContentStyle = mergeStyles({
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden',
});
