import React from 'react';
import { ActionButton, IColumn } from '@fluentui/react';
import { TableCell } from 'components/common/table';
import { IEquipmentRecord, IFacilityRecord } from 'clients/facilities-client';
import { IBasicEmployee } from 'clients/employee-client';
import EmployeeBasicHoverCardSimple from 'components/common/employee/employee-basic-hover-card-simple';
import { TimeFormats } from 'utils/time-utils';
import {
    getEquipmentRegionDisplayText,
    getEquipmentTypeDisplayText,
    reviewEquipmentStatusBadge,
} from 'components/facilities/facilities-equipment/util/facilities-equipment-utils';
import { handleUTCToFacilityTimeZone } from 'components/facilities/common/facility-time-utils';
import { IContract } from 'components/screening/us-gov/IContract';
import { IconNames } from 'assets/constants/global-constants';
import { IClearanceRecord } from 'clients/clearance-client';
import { getClearanceText } from 'components/personnel-profile/clearance/profile-clearance-constants';

enum ColumnNames {
    region = 'Region',
    facility = 'Facility',
    equipmentOwner = 'Equipment owner',
    equipmentType = 'Equipment type',
    equipmentStatus = 'Equipment status',
    lastCheckInCheckout = 'Last check in / check out',
    customer = 'Customer',
    issuedOn = 'Issued on',
    issuedBy = 'Issued by',
    expiration = 'Expiration',
    clearance = 'Clearance',
    contract = 'Contract',
    actions = 'Actions',
}

const columnWidths = {
    region: 100,
    facility: 100,
    equipmentOwner: 100,
    equipmentType: 100,
    equipmentStatus: 100,
    lastCheckedIn: 100,
    customer: 100,
    issuedOn: 100,
    issuedBy: 100,
    expiration: 100,
    clearance: 100,
    contract: 100,
    actions: 100,
};

export const tableColumns = (params: {
    basicEmployeesMap: Map<string, IBasicEmployee>;
    employeesPictureMap: Map<string, string>;
    contractsMap: Map<string, IContract>;
    clearanceMap: Map<string, IClearanceRecord>;
    facilityMap: Map<string, IFacilityRecord>;
    isNST: boolean;
    setSelectedRecord: (equipmentRecord: IEquipmentRecord) => void;
    setShowAddEditModal: (showAddEditModal: boolean) => void;
    setItemToDelete: (itemToDelete: IEquipmentRecord | undefined) => void;
}): IColumn[] => {
    return [
        {
            key: ColumnNames.region,
            name: ColumnNames.region,
            ariaLabel: ColumnNames.region,
            isResizable: true,
            isMultiline: true,
            minWidth: columnWidths.region,
            maxWidth: columnWidths.region,
            onRender: (row: IEquipmentRecord): JSX.Element => {
                // TODO - Needs region mapping
                return (
                    <TableCell>
                        <>{getEquipmentRegionDisplayText(row.region)}</>
                    </TableCell>
                );
            },
        },
        {
            key: ColumnNames.facility,
            name: ColumnNames.facility,
            ariaLabel: ColumnNames.facility,
            isResizable: true,
            isMultiline: true,
            minWidth: columnWidths.facility,
            maxWidth: columnWidths.facility,
            onRender: (row: IEquipmentRecord): JSX.Element => {
                return (
                    <TableCell>
                        <span>{params.facilityMap.get(row.facilityId)?.facilityName}</span>
                    </TableCell>
                );
            },
        },
        {
            key: ColumnNames.equipmentOwner,
            name: ColumnNames.equipmentOwner,
            ariaLabel: ColumnNames.equipmentOwner,
            isResizable: true,
            isMultiline: true,
            minWidth: columnWidths.equipmentOwner,
            maxWidth: columnWidths.equipmentOwner,
            onRender: (row: IEquipmentRecord): JSX.Element => {
                return (
                    <TableCell>
                        <EmployeeBasicHoverCardSimple
                            employeeBasicData={params.basicEmployeesMap.get(
                                row.ownerPersonnelId ?? '',
                            )}
                            image={params.employeesPictureMap.get(row.ownerPersonnelId ?? '')}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: ColumnNames.equipmentType,
            name: ColumnNames.equipmentType,
            ariaLabel: ColumnNames.equipmentType,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            minWidth: columnWidths.equipmentType,
            maxWidth: columnWidths.equipmentType,
            onRender: (row: IEquipmentRecord): JSX.Element => {
                return (
                    <TableCell>
                        <span>{getEquipmentTypeDisplayText(row.type)}</span>
                    </TableCell>
                );
            },
        },
        {
            key: ColumnNames.equipmentStatus,
            name: ColumnNames.equipmentStatus,
            ariaLabel: ColumnNames.equipmentStatus,
            isResizable: true,
            isMultiline: true,
            minWidth: columnWidths.equipmentStatus,
            maxWidth: columnWidths.equipmentStatus,
            onRender: (row: IEquipmentRecord): JSX.Element => {
                return (
                    <TableCell>
                        <>{reviewEquipmentStatusBadge(row)}</>
                    </TableCell>
                );
            },
        },
        {
            key: ColumnNames.lastCheckInCheckout,
            name: ColumnNames.lastCheckInCheckout,
            ariaLabel: ColumnNames.lastCheckInCheckout,
            isResizable: true,
            isMultiline: true,
            minWidth: columnWidths.lastCheckedIn,
            maxWidth: columnWidths.lastCheckedIn,
            onRender: (row: IEquipmentRecord): JSX.Element => {
                return (
                    <TableCell>
                        {row.checkinCheckoutInfo?.lastCheckinCheckout?.atUtc
                            ? handleUTCToFacilityTimeZone(
                                  row.checkinCheckoutInfo?.lastCheckinCheckout?.atUtc,
                                  params.facilityMap.get(row.facilityId),
                                  TimeFormats.MMMDYYYY_hmmssA,
                              )
                            : ''}
                    </TableCell>
                );
            },
        },
        {
            key: ColumnNames.customer,
            name: ColumnNames.customer,
            ariaLabel: ColumnNames.customer,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            minWidth: columnWidths.customer,
            maxWidth: columnWidths.customer,
            onRender: (row: IEquipmentRecord): JSX.Element => {
                return (
                    <TableCell>
                        <>{params.contractsMap.get(row.contractId ?? '')?.customer}</>
                    </TableCell>
                );
            },
        },
        {
            key: ColumnNames.issuedOn,
            name: ColumnNames.issuedOn,
            ariaLabel: ColumnNames.issuedOn,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            minWidth: columnWidths.issuedOn,
            maxWidth: columnWidths.issuedOn,
            onRender: (row: IEquipmentRecord): JSX.Element => {
                return (
                    <TableCell>
                        {row.issued.atUtc
                            ? handleUTCToFacilityTimeZone(
                                  row.issued.atUtc,
                                  params.facilityMap.get(row.facilityId),
                                  TimeFormats.MMMDDYYYY,
                              )
                            : ''}
                    </TableCell>
                );
            },
        },
        {
            key: ColumnNames.issuedBy,
            name: ColumnNames.issuedBy,
            ariaLabel: ColumnNames.issuedBy,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            minWidth: columnWidths.issuedBy,
            maxWidth: columnWidths.issuedBy,
            onRender: (row: IEquipmentRecord): JSX.Element => {
                return (
                    <TableCell>
                        <EmployeeBasicHoverCardSimple
                            employeeBasicData={params.basicEmployeesMap.get(row.issued.by)}
                            image={params.employeesPictureMap.get(row.issued.by)}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: ColumnNames.expiration,
            name: ColumnNames.expiration,
            ariaLabel: ColumnNames.expiration,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            minWidth: columnWidths.expiration,
            maxWidth: columnWidths.expiration,
            onRender: (row: IEquipmentRecord): JSX.Element => {
                return (
                    <TableCell>
                        <></>
                        {row.expirationOnUtcMilliseconds
                            ? handleUTCToFacilityTimeZone(
                                  row.expirationOnUtcMilliseconds,
                                  params.facilityMap.get(row.facilityId),
                                  TimeFormats.MMMDDYYYY,
                              )
                            : ''}
                    </TableCell>
                );
            },
        },
        {
            key: ColumnNames.clearance,
            name: ColumnNames.clearance,
            ariaLabel: ColumnNames.clearance,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            minWidth: columnWidths.clearance,
            maxWidth: columnWidths.clearance,
            onRender: (row: IEquipmentRecord): JSX.Element => {
                if (params.isNST) {
                    return (
                        <TableCell>
                            <>{getClearanceText(params.clearanceMap.get(row.clearanceId ?? ''))}</>
                        </TableCell>
                    );
                } else {
                    return <></>;
                }
            },
        },
        {
            key: ColumnNames.contract,
            name: ColumnNames.contract,
            ariaLabel: ColumnNames.contract,
            isResizable: true,
            isMultiline: true,
            isCollapsible: true,
            minWidth: columnWidths.contract,
            maxWidth: columnWidths.contract,
            onRender: (row: IEquipmentRecord): JSX.Element => {
                if (params.isNST) {
                    return (
                        <TableCell>
                            <>{row.contractId}</>
                        </TableCell>
                    );
                } else {
                    return <></>;
                }
            },
        },
        {
            key: ColumnNames.actions,
            name: ColumnNames.actions,
            ariaLabel: ColumnNames.actions,
            isResizable: true,
            isMultiline: true,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions,
            onRender: (item: IEquipmentRecord): JSX.Element => {
                return (
                    <span>
                        <ActionButton
                            iconProps={{ iconName: IconNames.IDBadge }}
                            onClick={(): void => {
                                params.setSelectedRecord(item);
                                params.setShowAddEditModal(true);
                            }}>
                            Manage
                        </ActionButton>
                        <ActionButton
                            iconProps={{ iconName: IconNames.Delete }}
                            onClick={(): void => {
                                params.setItemToDelete(item);
                            }}>
                            Delete
                        </ActionButton>
                    </span>
                );
            },
        },
    ];
};
