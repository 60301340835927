import { MessageBarType } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import GroupClient, { IGroupBasic } from 'clients/group-client';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import BoldFont from 'components/common/misc/bold-font';
import Spacer from 'components/common/spacer';
import { useTextField } from 'components/common/use-input/use-textfield';
import useMessageBar from 'components/common/use-message-bar';
import { MyGroupsContext } from 'components/groups/my-groups/my-groups-context';
import { AuthContext } from 'contexts/auth-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import React, { useContext, useState } from 'react';
import { useFetchSimple } from 'utils/misc-hooks';

interface ILeaveGroupModalActionButtonProps {
    groupId: string;
    isLeaveEnabled: boolean;
}

export default function LeaveGroupModalActionButton(
    props: ILeaveGroupModalActionButtonProps,
): JSX.Element {
    const context = useContext(MyGroupsContext);
    const principalUserContext = useContext(PrincipalUserContext);
    const authContext = useContext(AuthContext);

    const [group, setGroup] = useState<IGroupBasic>();
    const [shouldCheckToFetchGroup, setShouldCheckToFetchGroup] = useState<boolean>(false);

    const { isFetching } = useFetchSimple({
        // All the following checks is to fetch the group information
        // only when user clicks the "Leave" button to open the modal,
        // and to fetch it only once during the lifetime of the parent
        // component.
        canPerformFetch: shouldCheckToFetchGroup && !!props.groupId && group?.id !== props.groupId,
        dependencies: [props.groupId, shouldCheckToFetchGroup, group],
        fetchFunc: (): Promise<IGroupBasic> => {
            return GroupClient.getGroupBasic(authContext, props.groupId);
        },
        onSuccess: (groupVar: IGroupBasic) => {
            setGroup(groupVar);
        },
        onError: (): void => {
            setErrorMessage('Error fetching group info');
        },
        onFinally: (): void => {
            setShouldCheckToFetchGroup(false);
        },
    });

    const disableSubmit = (): boolean =>
        isFetching ||
        !!errorMessage ||
        !group ||
        group.id !== props.groupId ||
        (group.enableMemberLeaveJustification && !leaveJustification);

    const {
        value: leaveJustification,
        theElement: businessJustificationElement,
        initialize: setBusinessJustification,
    } = useTextField({
        label: 'Justification',
        multiline: true,
        required: group?.enableMemberLeaveJustification,
        resizable: false,
        rows: 5,
    });

    const {
        theMessage: errorMessage,
        theElement: errorMessageBarElement,
        setMessage: setErrorMessage,
        clearMessage: clearErrorMessage,
    } = useMessageBar({
        type: MessageBarType.error,
    });

    const onButtonClick = (): void => {
        clearErrorMessage();
        setBusinessJustification('');
        setShouldCheckToFetchGroup(true);
    };

    const onClickYes = async (): Promise<void> => {
        try {
            return await GroupClient.deleteGroupMember(
                authContext,
                props.groupId,
                principalUserContext.principalRecord.id,
                leaveJustification,
            );
        } catch (e) {
            switch ((e as Response).status) {
                case 403:
                    throw 'You do not have the necessary authorization to remove a user from a group';
                case 404:
                    throw 'Group membership was not found';
                default:
                    throw 'Error encountered while trying to leave the group';
            }
        }
    };

    const onModalConcluded = (modalConclusion: ModalConclusion): void => {
        if (modalConclusion === ModalConclusion.Done) {
            context.deleteRow(props.groupId);
        }
    };

    return (
        <ModalActionButton<boolean>
            text='Leave'
            enable={props.isLeaveEnabled}
            iconName={IconNames.Leave}
            modalTitle='Leave Group'
            cancelButtonText={'No'}
            enableSubmit={!disableSubmit()}
            submitButtonText={'Yes'}
            onSubmit={onClickYes}
            onButtonClick={onButtonClick}
            onModalConcluded={onModalConcluded}>
            Are you sure you want to leave the group <BoldFont>{group?.name}</BoldFont>?
            <Spacer marginTop={10} />
            {businessJustificationElement()}
            {!!errorMessage && (
                <>
                    <Spacer marginTop={10} />
                    {errorMessageBarElement()}
                </>
            )}
        </ModalActionButton>
    );
}
