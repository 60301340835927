import {
    Stack,
    StackItem,
    Separator,
    IPersonaProps,
    DatePicker,
    ActionButton,
    Checkbox,
    Label,
    mergeStyles,
    ComboBox,
    IComboBoxOption,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import React, { useContext, useEffect, useState } from 'react';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { IFormsFilterCriteria } from 'components/forms/manage/forms-manage-search';
import { useDebounce } from 'utils/misc-hooks';
import { globalStyles } from 'assets/styles/global-styles';
import FormsClient from 'clients/forms-client';
import { AuthContext } from 'contexts/auth-context';

interface IFormsFilterProps {
    filterCriteria?: IFormsFilterCriteria;
    setFilterCriteria: (fc: IFormsFilterCriteria) => void;
    availableReviewStatuses?: string[];
    onClearFilters: () => void;
}

const filterHeaderStyles = mergeStyles(globalStyles.boldFont, globalStyles.mediumLargeFont, {
    marginTop: '0',
});

export function FormsFilter(props: IFormsFilterProps): JSX.Element {
    const [titleValue, setTitleValue] = useState<string>();
    const [createdBy, setCreatedBy] = useState<IPersonaProps>();
    const [lastModified, setLastModifiedBy] = useState<IPersonaProps>();
    const [availableFormTypes, setAvailableFormTypes] = useState<IComboBoxOption[]>([]);
    const authContext = useContext(AuthContext);
    const debounceTimeInMS = 1200;

    const getAllAvailableFormTypes = async (): Promise<void> => {
        const response = await FormsClient.GetQueryableGenericFormRecordTypes(authContext);
        const mapped = response.map((x: string) => {
            return { key: x, text: x };
        });
        setAvailableFormTypes(mapped);
    };

    useEffect(() => {
        getAllAvailableFormTypes();
    }, []);

    const { filterCriteria, setFilterCriteria, availableReviewStatuses, onClearFilters } = props;

    const updateDateCriteria = (newValue: Date | null | undefined, fieldName: string): void => {
        if (newValue) {
            setFilterCriteria({ ...filterCriteria, [fieldName]: newValue });
        }
    };

    const updateCriteriaTitle = (): void => {
        setFilterCriteria({ ...filterCriteria, 'title': titleValue });
    };

    useDebounce(updateCriteriaTitle, debounceTimeInMS, [titleValue]);

    const changedTitle = (v: string): void => {
        setTitleValue(v);
    };

    const selectEmployee = (filterName: string, selected?: IPersonaProps): void => {
        if (selected?.tertiaryText) {
            setFilterCriteria({
                ...filterCriteria,
                // bug 25350335 optionalText contains the user's principal name, which can be differ than their email (such as having ...@ntdev.microsoft.com)
                // this is to match forms backend logic of using the user's principal name as first priority and if doesn't exist then use user's email
                [filterName]:
                    selected.optionalText ?? `${selected.tertiaryText.toLowerCase()}@microsoft.com`,
            });
        } else {
            setFilterCriteria({ ...filterCriteria, [filterName]: undefined });
        }
    };

    const filterCheckboxChanged = (
        item: string,
        filterName: 'reviewStatus',
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean,
    ): void => {
        if (filterCriteria && filterCriteria[filterName]?.has(item) && !checked) {
            const existingSet = filterCriteria[filterName];
            if (existingSet) {
                existingSet.delete(item);
                if (existingSet.size) {
                    setFilterCriteria({ ...filterCriteria, [filterName]: existingSet });
                } else {
                    setFilterCriteria({ ...filterCriteria, [filterName]: undefined });
                }
            }
        } else if (checked) {
            if (filterCriteria && filterCriteria[filterName]) {
                const existingSet = filterCriteria[filterName];
                if (existingSet) {
                    existingSet.add(item);
                    setFilterCriteria({ ...filterCriteria, [filterName]: existingSet });
                }
            } else {
                const newSet = new Set<string>();
                newSet.add(item);
                setFilterCriteria({ ...filterCriteria, [filterName]: newSet });
            }
        }
    };

    return (
        <Stack>
            <StackItem>
                <h3 className={filterHeaderStyles}>Filter</h3>
            </StackItem>
            <StackItem>
                <ComboBox
                    placeholder='Form type'
                    onChange={(e, v) => {
                        if (v?.text) {
                            changedTitle(v?.text);
                        }
                    }}
                    label='Form type'
                    options={availableFormTypes}
                />
            </StackItem>
            <StackItem>
                <DatePicker
                    label='Submitted from'
                    onSelectDate={(newValue): void => updateDateCriteria(newValue, 'submittedFrom')}
                    value={filterCriteria?.submittedFrom}
                    placeholder='Select a date...'
                />
            </StackItem>
            <StackItem>
                <DatePicker
                    label='Submitted to'
                    onSelectDate={(newValue): void => updateDateCriteria(newValue, 'submittedTo')}
                    value={filterCriteria?.submittedTo}
                    placeholder='Select a date...'
                />
            </StackItem>
            <StackItem>
                <EmployeePickerTypeaheadSearch
                    label='Submitted by'
                    selectedItems={createdBy ? [createdBy] : []}
                    onCandidateSelected={(e): void => {
                        setCreatedBy(e);
                        selectEmployee('createdBy', e);
                    }}
                    placeHolder={'Employee name or alias'}
                />
            </StackItem>
            <StackItem>
                <EmployeePickerTypeaheadSearch
                    label='Status changed by'
                    selectedItems={lastModified ? [lastModified] : []}
                    onCandidateSelected={(e): void => {
                        setLastModifiedBy(e);
                        selectEmployee('lastModifiedBy', e);
                    }}
                    placeHolder={'Employee name or alias'}
                />
            </StackItem>
            {availableReviewStatuses && (
                <StackItem>
                    <Label>Review status</Label>
                    {availableReviewStatuses.map((item, num) => (
                        <Checkbox
                            key={num}
                            label={item}
                            name={item}
                            onChange={(ev, checked): void =>
                                filterCheckboxChanged(item, 'reviewStatus', ev, checked)
                            }
                            checked={filterCriteria?.reviewStatus?.has(item) || false}
                            styles={{ root: { padding: '.1rem' } }}
                        />
                    ))}
                </StackItem>
            )}
            <Separator />
            <StackItem>
                <Stack horizontal wrap>
                    <StackItem style={{ 'padding': '5px' }}>
                        <ActionButton
                            iconProps={{ iconName: IconNames.ClearFilter }}
                            onClick={() => {
                                setTitleValue(undefined);
                                setCreatedBy(undefined);
                                setLastModifiedBy(undefined);
                                onClearFilters();
                            }}>
                            <span>Clear filters</span>
                        </ActionButton>
                    </StackItem>
                </Stack>
            </StackItem>
        </Stack>
    );
}
