/* eslint-disable @typescript-eslint/naming-convention */
import { ClientOptionsType } from 'clients/client-utils';
import FetchCache, { FetchDataTypes } from 'clients/fetch-cache';
import { GetGraphHttpOptions } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import config from 'environments/environment';
import { chunk } from 'utils/misc-utils';
import { convertPhotoToBase64 } from 'utils/photo-utils';

const graphConfig = config.graphServiceConfig;

export enum PhotoSize {
    '48x48' = '48x48',
    '64x64' = '64x64',
    '96x96' = '96x96',
    '120x120' = '120x120',
    '240x240' = '240x240',
    '360x360' = '360x360',
    '432x432' = '432x432',
    '504x504' = '504x504',
    '648x648' = '648x648',
}

export interface IImageRequestType {
    id: string;
    oid?: string;
    userPrincipalName?: string;
    upn?: string;
}

class GraphClient {
    static ownerOid: string | undefined;

    static async getGroupLinkUserOid(authContext: IAuthContext): Promise<string | undefined> {
        if (!!this.ownerOid) {
            return this.ownerOid;
        }
        const syncEmail = config.emailAddress.groupSync;
        try {
            const result = await this.getUser(authContext, syncEmail);
            this.ownerOid = result?.id;
            return result?.id;
        } catch {
            console.error('Unable to get owner Oid');
        }
    }

    static async getGroupMembers(
        authContext: IAuthContext,
        groupOid: string,
    ): Promise<IGroupListMembersResponse> {
        const httpOptions = await GetGraphHttpOptions(authContext);
        const url = `${graphConfig.baseUrl}${graphConfig.groupMembersEndpoint}`.replace(
            '{id}',
            groupOid,
        );
        const response = await fetch(url, httpOptions);
        const data: IGroupListMembersResponse = await response.json();
        return data;
    }

    static async getPhoto(authContext: IAuthContext, oid: string): Promise<IPhotoResponse> {
        const httpOptions = await GetGraphHttpOptions(authContext);
        const url =
            graphConfig.baseUrl + graphConfig.usersEndpoint + oid + graphConfig.photoEndpoint;
        const result = await fetch(url, httpOptions);
        const blobString = await convertPhotoToBase64(await result.blob());

        return {
            id: oid,
            headers: result.headers,
            status: result.status,
            body: blobString,
        };
        return {
            id: oid,
            headers: null,
            status: 0,
            body: 'null',
        };
    }

    static async getBatchPhoto(
        authContext: IAuthContext,
        employeeList: IImageRequestType[],
        dimensions?: PhotoSize,
    ): Promise<IBatchPhotoResponse> {
        const emptyResponse = { responses: [] };
        try {
            if (employeeList && employeeList.length > 0) {
                const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
                const url = graphConfig.baseUrl + graphConfig.batchEndpoint;
                const requests = this._createBatchImageRequest(employeeList, dimensions);
                httpOptions.method = 'POST';
                httpOptions.body = JSON.stringify({ requests });

                const result = await (await fetch(url, httpOptions)).json();
                if (result.responses) {
                    return result;
                } else {
                    return emptyResponse;
                }
            }
        } catch (e) {
            console.error(e);
            return emptyResponse;
        }
        return emptyResponse;
    }

    static async getUser(
        authContext: IAuthContext,
        email: string,
    ): Promise<IGraphUser | undefined> {
        const { baseUrl, usersEndpoint } = graphConfig;
        const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
        const url = baseUrl + usersEndpoint + email;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async getUserByOid(
        authContext: IAuthContext,
        oid: string,
    ): Promise<IGraphUser | undefined> {
        const { baseUrl, usersEndpoint } = graphConfig;
        const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
        const url = baseUrl + usersEndpoint + oid;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static MAX_REQUESTS_PER_BATCH = 20;
    static MAX_ITEMS_PER_QUERY = 15;
    static MAX_CONCURRENT_QUERIES = 5;
    static MAX_DIRECTORYOBJECT_QUERY_IDS = 1000;

    static async getUsersByOids(authContext: IAuthContext, oids: string[]): Promise<IGraphUser[]> {
        const oidsByRequest = chunk(oids, GraphClient.MAX_ITEMS_PER_QUERY);

        const requests = oidsByRequest.map(
            (queryOids: string[], queryIndex: number) =>
                ({
                    method: 'GET',
                    // eslint-disable-next-line prettier/prettier
                    url: `/users?$filter=id in (${queryOids.map(oid => `'${oid}'`).join(',')})&$orderbydisplayName&$select=id,userPrincipalName,mail,displayName,givenName`,
                    id: String(queryIndex),
                } as IGraphBatchRequest),
        );

        const responses = await GraphClient._executeBatchRequests<IGraphResponse<IGraphUser>>(
            authContext,
            requests,
        );

        return responses.flatMap((response) => {
            if (response.status === 200) {
                return response.body.value;
            } else {
                throw response;
            }
        });
    }

    static async getGraphPrincipalsByOids(
        authContext: IAuthContext,
        oids: string[],
    ): Promise<IGraphPrincipal[]> {
        if (oids.length <= 0) {
            return [];
        }

        if (oids.length > GraphClient.MAX_DIRECTORYOBJECT_QUERY_IDS) {
            throw `Directory object id length cannot exceed ${GraphClient.MAX_DIRECTORYOBJECT_QUERY_IDS}`;
        }

        const { baseUrl } = graphConfig;
        const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
        const url = baseUrl + '/directoryObjects/getByIds';
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify({ ids: oids, types: ['user', 'servicePrincipal'] });

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            const result = await response.json();
            return result.value;
        } else {
            throw response;
        }
    }

    static async _executeBatchRequests<T>(
        authContext: IAuthContext,
        requests: IGraphBatchRequest[],
    ): Promise<IBatchResponse<T>[]> {
        const batches = chunk(requests, GraphClient.MAX_REQUESTS_PER_BATCH);

        if (batches.length > GraphClient.MAX_CONCURRENT_QUERIES) {
            throw `Simultaneous requests cannot exceed ${GraphClient.MAX_CONCURRENT_QUERIES}`;
        }

        const batchPromises = batches.map((batchRequests) =>
            GraphClient._executeSingleBatchRequests<T>(authContext, batchRequests),
        );

        const result: IBatchResponse<T>[][] = [];

        for (let i = 0; i < batchPromises.length; i++) {
            result.push(await batchPromises[i]);
        }

        return result.flat();
    }

    static async _executeSingleBatchRequests<T>(
        authContext: IAuthContext,
        requests: IGraphBatchRequest[],
    ): Promise<IBatchResponse<T>[]> {
        if (requests.length > GraphClient.MAX_REQUESTS_PER_BATCH) {
            throw `Requests per batch cannot exceed ${GraphClient.MAX_REQUESTS_PER_BATCH}`;
        }

        if (requests.length === 0) {
            return [];
        }

        const { baseUrl, batchEndpoint } = graphConfig;

        try {
            const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
            const url = baseUrl + batchEndpoint;
            httpOptions.method = 'POST';
            httpOptions.body = JSON.stringify({ requests });

            const response = await fetch(url, httpOptions);
            if (response.status === 200) {
                const result = (await response.json()) as IBatchResponses<T>;
                return result.responses;
            } else {
                throw response;
            }
        } catch (e) {
            // TODO: LOG
            console.error(e);
        }
        return [];
    }

    // This function allows you to query graph to get user fields that aren't returned by default.
    // Update the IGraphUserExtended if the fields you want to query for aren't already included.
    static async getUserByOidQuery(
        authContext: IAuthContext,
        oid: string,
        queryParams: string,
    ): Promise<IGraphUserExtended | undefined> {
        const { baseUrl, usersEndpoint } = graphConfig;
        const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
        const url = baseUrl + usersEndpoint + oid + `?${queryParams}`;

        const result = await FetchCache.fetchItem<IGraphUserExtended>(
            FetchDataTypes.GraphUserExtended,
            oid + '_' + queryParams,
            async (): Promise<IGraphUserExtended> => {
                const response = await fetch(url, httpOptions);
                if (response.status === 200) {
                    return await response.json();
                } else {
                    throw response;
                }
            },
        );

        return result;
    }

    static async listUsers(
        authContext: IAuthContext,
        filterQuery: string,
        maxResults: number,
    ): Promise<IGraphUser[]> {
        const { baseUrl } = graphConfig;
        const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
        httpOptions.headers = httpOptions.headers ?? {};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (httpOptions.headers as any)['ConsistencyLevel'] = 'eventual';
        const url = `${baseUrl}/users?$filter=${filterQuery}&$orderbydisplayName&$top=${maxResults}&$select=id,userPrincipalName,mail,displayName,givenName`;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            const result = await response.json();
            return result?.value ?? [];
        } else {
            throw response;
        }
    }

    static async searchUsers(
        authContext: IAuthContext,
        searchQuery: string,
        maxResults: number,
    ): Promise<IGraphUser[] | undefined> {
        const { baseUrl } = graphConfig;
        const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
        httpOptions.headers = httpOptions.headers ?? {};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (httpOptions.headers as any)['ConsistencyLevel'] = 'eventual';
        const url = `${baseUrl}/users?$search=${searchQuery}&$orderbydisplayName&$top=${maxResults}&$select=id,userPrincipalName,mail,displayName,givenName`;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            const result = await response.json();
            return result.value;
        } else {
            throw response;
        }
    }

    static async searchServicePrincipals(
        authContext: IAuthContext,
        searchQuery: string,
        maxResults: number,
    ): Promise<IGraphServicePrincipal[] | undefined> {
        const { baseUrl } = graphConfig;
        const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
        httpOptions.headers = httpOptions.headers ?? {};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (httpOptions.headers as any)['ConsistencyLevel'] = 'eventual';
        const url = `${baseUrl}/servicePrincipals?$search=${searchQuery}&$orderbydisplayName&$top=${maxResults}&$select=id,appDisplayName,appId,displayName,servicePrincipalType`;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            const result: IGraphResponse<IGraphServicePrincipal> = await response.json();
            result.value.forEach((servicePrincipal) => {
                servicePrincipal.oid = servicePrincipal.id;
            });
            return result.value;
        } else {
            throw response;
        }
    }

    static async getServicePrincipal(
        authContext: IAuthContext,
        appId: string,
    ): Promise<IGraphServicePrincipal | undefined> {
        const { baseUrl } = graphConfig;
        const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
        const url = `${baseUrl}/servicePrincipals(appId='${appId}')?$select=id,appDisplayName,appId,displayName,servicePrincipalType`;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            const servicePrincipal: IGraphServicePrincipal = await response.json();
            servicePrincipal.oid = servicePrincipal.id;
            return servicePrincipal;
        } else {
            throw response;
        }
    }

    // This function is used to obtain a larger image size and for now is only needed
    // for the kiosk. If a larger size is needed for another part of the application
    // you can refactor to accept alternative sizes.
    static async getUserImage(authContext: IAuthContext): Promise<string | undefined> {
        const { baseUrl } = graphConfig;
        const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
        const url = baseUrl + `/me/photos/${PhotoSize['240x240']}/$value`;
        const response = await fetch(url, httpOptions);
        if (response.status === 200 && response.body) {
            const reader = response.body.getReader();
            const stream = new ReadableStream({
                start(controller) {
                    return pump();
                    async function pump(): Promise<void> {
                        return reader.read().then(({ done, value }) => {
                            // When no more data needs to be consumed, close the stream
                            if (done) {
                                controller.close();
                                return;
                            }
                            // Enqueue the next data chunk into our target stream
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                },
            });
            const res: Response = new Response(stream);
            const blob = await res.blob();
            const urlObject = URL.createObjectURL(blob);
            return urlObject;
        } else {
            throw response;
        }
    }

    static async getGroup(
        authContext: IAuthContext,
        groupId: string,
        options?: ClientOptionsType,
    ): Promise<IGraphGroup | undefined> {
        const { baseUrl, groupsEndpoint } = graphConfig;
        const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
        const url = baseUrl + groupsEndpoint + groupId;

        if (options?.useFetchCache) {
            const result = await FetchCache.fetchItem<IGraphGroup>(
                FetchDataTypes.SecurityGroup,
                groupId,
                async (): Promise<IGraphGroup> => {
                    const response = await fetch(url, httpOptions);
                    if (response.status === 200) {
                        return await response.json();
                    } else {
                        throw response;
                    }
                },
            );
            return result;
        }

        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return await response.json();
        } else {
            throw response;
        }
    }

    static async getMyOwnerGroups(authContext: IAuthContext): Promise<IGraphGroup[]> {
        const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
        let url =
            graphConfig.baseUrl +
            graphConfig.meEndpoint +
            'ownedObjects?$select=id,displayName,classification,groupTypes,mail,visibility,securityEnabled';
        let result: IGraphResponse<IGraphGroup>;
        let returnValue: IGraphGroup[] = [];
        do {
            const response = await fetch(url, httpOptions);
            if (response.status === 200) {
                result = await response.json();
                /* Example value of result
                    {
                        "@odata.context": "https://graph.microsoft.com/v1.0/$metadata#directoryObjects(id,displayName,classification,groupTypes,mail,visibility,securityEnabled)",
                        "value": [
                            {
                                "@odata.type": "#microsoft.graph.group",
                                "id": "dcd343e7-741b-46c2-b47d-964efe4ad9eb",
                                "displayName": "AliTest",
                                "classification": null,
                                "groupTypes": [
                                    "Unified"
                                ],
                                "mail": "alitest@service.microsoft.com",
                                "visibility": "Private",
                                "securityEnabled": false
                            },
                            {
                                "@odata.type": "#microsoft.graph.group",
                                "id": "5cd61023-c9a1-4289-9821-f021ded16e56",
                                "displayName": "AliTest2",
                                "classification": null,
                                "groupTypes": [
                                    "Unified"
                                ],
                                "mail": "alitest2@service.microsoft.com",
                                "visibility": "Private",
                                "securityEnabled": false
                            }
                        ]
                    }
                    */
                returnValue = returnValue.concat(
                    result.value.filter(
                        (y: IGraphGroup) => y['@odata.type'] === '#microsoft.graph.group',
                    ),
                );
                /*
                 * If any "ownedDataObject"s remain to be fetched, graph.microsoft.com will
                 * send the URL to "next page" on a property called "@odata.nextLink".
                 * If a call returns such value, it should be used to fetch the next page.
                 * It works somewhat like continuationToken.
                 * See https://docs.microsoft.com/en-us/graph/paging for more info.
                 */

                // The following typecast is safe. If value is undefined, the loop ends.
                url = result['@odata.nextLink'] as string;
            } else {
                throw response;
            }
        } while (!!result['@odata.nextLink']);
        return returnValue;
    }

    static async checkOwnerExists(
        authContext: IAuthContext,
        groupId: string,
        ownerOid?: string,
    ): Promise<boolean> {
        if (ownerOid === undefined) {
            ownerOid = await this.getGroupLinkUserOid(authContext);
        }

        const { baseUrl, groupsEndpoint } = config.graphServiceConfig;
        const httpOptions: RequestInit = await GetGraphHttpOptions(authContext);
        const url = baseUrl + groupsEndpoint + groupId + `/owners?$filter=id eq '${ownerOid}'`;
        try {
            // "response" in the following will have the type IGroupOwnerResponse:
            const response = await fetch(url, httpOptions);
            if (response.status === 200) {
                const result = await response.json();
                return result.value.length > 0;
            } else {
                throw response;
            }
        } catch (error) {
            throw error;
        }
    }

    private static _createBatchImageRequest(
        employeeList: IImageRequestType[],
        dimensions: PhotoSize = PhotoSize['48x48'],
    ): IGraphBatchRequest[] {
        const requests = [];
        let key = null;
        for (const item of employeeList) {
            key = item.oid || item.upn || item.userPrincipalName;
            if (key) {
                requests.push({
                    method: 'GET',
                    url: `/users/${key}/photos/${dimensions}/$value`,
                    id: item.id,
                });
            }
        }
        return requests;
    }

    static async removeOwner(
        authContext: IAuthContext,
        groupId: string,
        ownerOid?: string,
    ): Promise<void> {
        if (ownerOid === undefined) {
            ownerOid = await this.getGroupLinkUserOid(authContext);
        }
        const { baseUrl, groupsEndpoint } = config.graphServiceConfig;
        const httpOptions = {
            ...(await GetGraphHttpOptions(authContext)),
            method: 'DELETE',
        };
        const url = baseUrl + groupsEndpoint + groupId + '/owners/' + ownerOid + '/$ref';
        await fetch(url, httpOptions);
    }
}

export default GraphClient;

export function isGraphServicePrincipal(
    graphServicePrincipal: IGraphPrincipal,
): graphServicePrincipal is IGraphServicePrincipal {
    return (<IGraphServicePrincipal>graphServicePrincipal).servicePrincipalType !== undefined;
}

interface IGraphResponse<T> {
    '@odata.context': string;
    '@odata.nextLink'?: string;
    value: T[];
}

export interface IGraphGroup {
    '@odata.type': string;
    id: string;
    displayName: string;
    classification: string;
    groupTypes: string[];
    mail: string;
    visibility: string;
    securityEnabled: boolean;
}

export interface IGroupListMembersResponse {
    value: IPerson[];
}

export interface IPerson {
    displayName: string;
    givenName: string;
    id: string;
    jobTitle: string;
    mail: string;
    mobilePhone: string;
    officeLocation: string;
    preferredLanguage: string;
    surname: string;
    userPrincipalName: string;
}

interface IGroupOwner {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '@odata.type': string; // Example value:"#microsoft.graph.user"
    id: string;
    businessPhones: string[];
    displayName: string;
    givenName: string;
    jobTitle: string | null;
    mail: string; // Example value: "pmgtdev@microsoft.com"
    mobilePhone: string | null;
    officeLocation: string | null;
    preferredLanguage: string | null;
    surname: string | null;
    userPrincipalName: string; // Example value: "pmgtdev@microsoft.com"
}

export interface IGraphBatchRequest {
    method: string;
    url: string;
    id: string;
}

export type IBatchPhotoResponse = IBatchResponses<string>;
export type IPhotoResponse = IBatchResponse<string>;

export interface IGraphUser {
    id: string;
    userPrincipalName: string;
    mail: string;
    displayName: string;
    givenName: string;
}

export interface IBatchResponse<T> {
    id: string;
    status: number;
    headers: Headers | null;
    body: T;
}

export interface IBatchResponses<T> {
    responses: IBatchResponse<T>[];
}

export interface IBatchGraphUserListResponse {
    responses: IGraphUser[];
}

export interface IGraphUserExtended {
    id?: string;
    userPrincipalName?: string;
    mail?: string;
    mailNickname?: string;
    displayName?: string;
    givenName?: string;
    preferredFirstName?: string;
    preferredLastName?: string;
    firstName?: string;
    lastName?: string;
    department?: string;
    jobTitle?: string;
    standardJobTitle?: string;
    onPremisesSamAccountName?: string;
    onPremisesDomainName?: string;
    officeLocation?: string;
    surname?: string;
    manager?: IGraphUserExtended;
}

export interface IGraphServicePrincipal {
    id: string;
    oid?: string;
    appDisplayName?: string;
    appId?: string;
    displayName?: string;
    servicePrincipalType: string;
}

export type IGraphPrincipal = IGraphServicePrincipal | IGraphUser;
