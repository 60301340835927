import { TableCell } from 'components/common/table';
import React from 'react';
import { IColumn, mergeStyles, ActionButton } from '@fluentui/react';
import { lastColumnStylesButton } from 'assets/styles/list-styles';
import { IReviewPeriod } from 'clients/sca-client';
import { dateToFormattedTimeString, dateToLocalShortDateFormat } from 'utils/time-utils';
import NavLinkContainer from 'components/common/navlink-container';
import { maxWidthCoeff } from 'assets/constants/global-constants';
import { reviewStatusBadge } from 'components/sca/sca-utils';
import { ISortableColumns } from 'utils/sort-utils';
import { manageTitle, IconNames } from 'assets/constants/global-constants';

interface IReviewsColumns extends ISortableColumns {
    hasEditRoles: boolean;
}

export function getScaReviewPeriodsTableColumns(params: IReviewsColumns): IColumn[] {
    const columnWidths = {
        reviewId: 100,
        name: 150,
        startDate: 100,
        endDate: 100,
        eligibilityDate: 100,
        status: 100,
        lastUpdated: 100,
        action: 100,
    };

    const columns: IColumn[] = [
        {
            key: 'id',
            name: 'Review ID',
            ariaLabel: 'Review ID',
            minWidth: columnWidths.reviewId,
            maxWidth: columnWidths.reviewId * maxWidthCoeff,
            isSorted: params.sortColumn === 'id',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('id');
            },
            onRender: (row: IReviewPeriod): JSX.Element => {
                return <TableCell>{row.id}</TableCell>;
            },
        },
        {
            key: 'title',
            name: 'Name',
            ariaLabel: 'Name',
            minWidth: columnWidths.name,
            maxWidth: columnWidths.name * maxWidthCoeff,
            isSorted: params.sortColumn === 'title',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('title');
            },
            onRender: (row: IReviewPeriod): JSX.Element => {
                return <TableCell>{row.title}</TableCell>;
            },
        },
        {
            key: 'startDateUTC',
            name: 'Start Date',
            ariaLabel: 'Start Date',
            minWidth: columnWidths.startDate,
            maxWidth: columnWidths.startDate * maxWidthCoeff,
            isSorted: params.sortColumn === 'startDateUTC',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('startDateUTC');
            },
            onRender: (row: IReviewPeriod): JSX.Element => {
                return <TableCell>{dateToLocalShortDateFormat(row.startDateUTC * 1000)}</TableCell>;
            },
        },
        {
            key: 'endDateUTC',
            name: 'End Date',
            ariaLabel: 'End Date',
            minWidth: columnWidths.endDate,
            maxWidth: columnWidths.endDate * maxWidthCoeff,
            isSorted: params.sortColumn === 'endDateUTC',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('endDateUTC');
            },
            onRender: (row: IReviewPeriod): JSX.Element => {
                return <TableCell>{dateToLocalShortDateFormat(row.endDateUTC * 1000)}</TableCell>;
            },
        },
        {
            key: 'eligibilityDateUTC',
            name: 'Eligibility Date',
            ariaLabel: 'Eligibility Date',
            minWidth: columnWidths.eligibilityDate,
            maxWidth: columnWidths.eligibilityDate * maxWidthCoeff,
            isSorted: params.sortColumn === 'eligibilityDateUTC',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('eligibilityDateUTC');
            },
            onRender: (row: IReviewPeriod): JSX.Element => {
                return (
                    <TableCell>
                        {dateToLocalShortDateFormat(row.eligibilityDateUTC * 1000)}
                    </TableCell>
                );
            },
        },
        {
            key: 'state',
            name: 'Status',
            ariaLabel: 'Status',
            minWidth: columnWidths.status,
            maxWidth: columnWidths.status * maxWidthCoeff,
            isSorted: params.sortColumn === 'Status',
            onColumnClick: (): void => {
                params.sortColumnHandler('Status');
            },
            isSortedDescending: !params.sortAscending,
            onRender: (row: IReviewPeriod): JSX.Element => {
                return <TableCell>{reviewStatusBadge(row)}</TableCell>;
            },
        },
        {
            key: 'lastModifiedTimestampUTC',
            name: 'Last Updated',
            ariaLabel: 'Last Updated',
            minWidth: columnWidths.lastUpdated,
            maxWidth: params.hasEditRoles ? undefined : columnWidths.lastUpdated * maxWidthCoeff,
            isSorted: params.sortColumn === 'lastModifiedTimestampUTC',
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler('lastModifiedTimestampUTC');
            },
            onRender: (row: IReviewPeriod): JSX.Element => {
                return (
                    <TableCell>
                        {row.lastModifiedTimestampUTC &&
                            dateToLocalShortDateFormat(row.lastModifiedTimestampUTC * 1000) +
                                ' ' +
                                dateToFormattedTimeString(row.lastModifiedTimestampUTC * 1000)}
                    </TableCell>
                );
            },
        },
    ];

    if (params.hasEditRoles) {
        columns.push({
            key: 'Actions',
            name: 'Actions',
            ariaLabel: 'Actions',
            minWidth: columnWidths.action,
            maxWidth: columnWidths.action * maxWidthCoeff,
            ...lastColumnStylesButton,
            onRender: (row: IReviewPeriod): JSX.Element => {
                const idLower = row.id?.toLocaleLowerCase();
                const iconName = idLower && (row.isCurrent ? IconNames.Settings : IconNames.View);
                const buttonText = idLower && (row.isCurrent ? manageTitle : 'View');
                const redirectUrl = idLower && `/sca/manage/${idLower}/employees`;
                return (
                    <TableCell>
                        <div className={mergeStyles({ display: 'inline-block' })}>
                            <NavLinkContainer to={redirectUrl}>
                                <ActionButton
                                    text={buttonText}
                                    iconProps={{ iconName: iconName }}
                                />
                            </NavLinkContainer>
                        </div>
                    </TableCell>
                );
            },
        });
    }
    return columns;
}
