import { IconNames } from 'assets/constants/global-constants';
import FacilitiesClient, {
    IFacilityRecord,
    IBlockedFacilityUserRecord,
} from 'clients/facilities-client';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { useEmployeePicker } from 'components/common/use-input/use-employee-picker';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import React, { useContext } from 'react';
import { doNothing } from 'utils/misc-utils';

export interface AddBlockedUserProps {
    facilityId: string | undefined;
    updateBlockedUsersOnAdd: (blockedUser: IBlockedFacilityUserRecord) => void;
}
export default function AddBlockedUser(props: AddBlockedUserProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const {
        value: selectedEmployee,
        theElement: employeeElement,
        initialize: initializeEmployee,
    } = useEmployeePicker({
        label: 'Employee',
        selectedPersona: undefined,
    });

    const addBlockedUser = async (): Promise<void> => {
        try {
            if (props.facilityId) {
                const newBlockedFacilityUserRecord = await FacilitiesClient.createBlockedFacilityUserRecord(
                    authContext,
                    userContext,
                    props.facilityId,
                    selectedEmployee?.id ?? '',
                );

                props.updateBlockedUsersOnAdd(newBlockedFacilityUserRecord);

                initializeEmployee(undefined);
            }
        } catch (e) {
            throw 'An error occurred blocking employee. Employee may already be blocked.';
        }
    };

    return (
        <>
            <ModalActionButton<IFacilityRecord>
                text={'Block Employee'}
                iconName={IconNames.Add}
                modalTitle={'Block New Employee'}
                enableSubmit={selectedEmployee !== undefined}
                onSubmit={addBlockedUser}
                submitButtonText={'Block'}
                onModalConcluded={doNothing}>
                {employeeElement()}
            </ModalActionButton>
        </>
    );
}
