import React, { ReactNode } from 'react';
import { Stack, Spinner } from '@fluentui/react';
import Spacer from 'components/common/spacer';

interface IisLoadingIndicatorProps {
    isLoading: boolean;
    ifNotLoading?: ReactNode;
    before?: ReactNode;
    after?: ReactNode;
    msg?: string;
    marginTop?: number;
}

export default function IsLoadingIndicator(props: IisLoadingIndicatorProps): JSX.Element {
    return props.isLoading ? (
        <Stack horizontalAlign='center'>
            {!!props.marginTop && <Spacer marginTop={props.marginTop} />}
            {props.before}
            <Stack horizontal>
                <span>{`${props.msg ?? 'Loading...'}`}&nbsp;</span>
                <Spinner />
            </Stack>
            {props.after}
        </Stack>
    ) : (
        <>{props.ifNotLoading}</>
    );
}
