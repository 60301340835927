import React from 'react';
import { useCheckMountedState } from 'utils/misc-hooks';
import {
    Dropdown,
    IDropdownOption,
    IStyleFunctionOrObject,
    IDropdownStyleProps,
    IDropdownStyles,
    IRenderFunction,
    IDropdownProps,
} from '@fluentui/react';

interface IDropdownParams {
    // Ideally, we should keep the following props 100% the same
    // as the underlying Fluent component so that they can directly
    // drive its props.
    label?: string;
    options: IDropdownOption[];
    placeholder?: string;
    required?: boolean;
    styles?: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles>;
    ariaLabel?: string;
    disabled?: boolean;
    onRenderLabel?: IRenderFunction<IDropdownProps> | undefined;
}

/**
 *  The function findDropdownOption receives a key and list of options and looks
 *  for the key in the list. It's added so that this hook doesn't blindly accept
 *  an option or key given to it. Rather, it will look for it. If found, it returns
 *  the corresponding option. Otherwise, it returns undefined.
 */
const findDropdownOption = (
    key: string | number | undefined,
    options: IDropdownOption[],
): IDropdownOption | undefined => {
    return options.find((option) => option.key === key);
};

interface IUseDropdown {
    // Current value of the input field
    selectedKey: string | number | undefined;
    selectedOption: IDropdownOption | undefined;
    // Function to initialize the value of the input field.
    // Parent element can call it if needs be.
    initializeFromKey: (selectedKey?: string | number) => void;
    // The following is the input element proper. Use it in render.
    theElement: () => JSX.Element;
}

export function useDropdown(params: IDropdownParams): IUseDropdown {
    const [selectedOption, setSelectedOption] = useCheckMountedState<IDropdownOption | undefined>(
        undefined,
    );

    const onSelect = (a: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
        if (['click', 'keydown'].find((e) => e === a.type)) {
            setSelectedOption(option);
        }
    };

    const initializeFromKey = (key: string | number | undefined): void => {
        setSelectedOption(findDropdownOption(key, params.options));
    };

    return {
        selectedKey: selectedOption?.key,
        selectedOption,
        initializeFromKey,
        theElement: (): JSX.Element => (
            <Dropdown
                required={params.required}
                label={params.label}
                placeholder={params.placeholder}
                styles={params.styles}
                selectedKey={selectedOption?.key}
                onChange={onSelect}
                onRenderLabel={params.onRenderLabel}
                options={params.options}
                ariaLabel={params.ariaLabel}
                disabled={params.disabled}
            />
        ),
    };
}
