import React, { useContext, useMemo, useState } from 'react';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { IPersonaProps, mergeStyleSets, MessageBar, MessageBarType } from '@fluentui/react';
import EmployeeCard from 'components/common/employee/employee-card';
import { IBasicEmployee, IEmployee } from 'clients/employee-client';
import Environment from 'environments/environment';
import { AuthContext } from 'contexts/auth-context';

interface FteCandidateNominationStepChooseCandidateProps {
    selectedPersona?: IPersonaProps;
    selectedEmployee?: IEmployee;
    selectedEmployeeManager?: IBasicEmployee;
    handleCandidateChange: (
        persona: IPersonaProps | undefined,
        employee: IEmployee | undefined,
    ) => void;
}

export default function FteCandidateNominationStepChooseCandidate(
    props: FteCandidateNominationStepChooseCandidateProps,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const msalUser = useMemo(() => {
        return authContext.getUserProfile();
    }, [authContext]);

    const [message, setMessage] = useState<string>('');
    const [hasMessage, setHasMessage] = useState(false);

    return (
        <>
            <EmployeePickerTypeaheadSearch
                label='Candidate'
                onCandidateSelected={onSelectedCandidateChange}
                selectedPersona={props.selectedPersona}
            />
            {hasMessage && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={true}
                    dismissButtonAriaLabel='Close'
                    overflowButtonAriaLabel='See more'
                    onDismiss={onDismissMessage}>
                    {message}
                </MessageBar>
            )}
            {props.selectedPersona &&
                props.selectedEmployee &&
                props.selectedEmployeeManager &&
                DisplayEmployeeCard(props.selectedEmployee, props.selectedEmployeeManager)}
        </>
    );

    function onDismissMessage(): void {
        setHasMessage(false);
        setMessage('');
    }

    function onSelectedCandidateChange(persona?: IPersonaProps): void {
        onDismissMessage();
        if (msalUser?.alias && persona && persona.itemProp) {
            try {
                const emp: IEmployee = JSON.parse(persona.itemProp);
                if (
                    Environment.production &&
                    emp.alias.toLocaleLowerCase() === msalUser.alias.toLocaleLowerCase()
                ) {
                    setMessage(
                        'Select another employee - you cannot nominate yourself. Candidates should be nominated by a manager, contract owner, or organizational screening program manager.',
                    );
                    setHasMessage(true);
                    props.handleCandidateChange(undefined, undefined);
                } else {
                    props.handleCandidateChange(persona, emp);
                }
            } catch (error) {
                setMessage("An error occured while loading the selected employee's information.");
                setHasMessage(true);
            }
        }
    }
}

function DisplayEmployeeCard(employee: IEmployee, manager: IBasicEmployee): JSX.Element {
    return (
        <div className={styles.card}>
            <EmployeeCard
                displayActions={false}
                employee={employee}
                managerAlias={employee.reportsToEmailName}
                manager={manager.displayName}
                key={`chosen_candidate`}
            />
        </div>
    );
}

const styles = mergeStyleSets({
    card: {
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
    },
});
