import config from 'environments/environment';
import { GetAadHttpOptions, JSON_CONTENT_TYPE } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import ClientUtils from 'clients/client-utils';
import { IPagedResults } from 'clients/http-options';
import { ReviewPeriodStatusCodeType } from 'components/sca/sca-constants';

const { scaServiceConfig } = config;

export default class ScaClient {
    static async isScaManager(authContext: IAuthContext, personnelId: string): Promise<boolean> {
        const httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
        const url =
            scaServiceConfig.baseUrl +
            scaServiceConfig.scaManagerDirectsEndpoint.replace('{id}', personnelId);
        httpOptions.method = 'GET';
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const ret = await res.json();
            return ret.directsInReview;
        } else {
            throw res;
        }
    }

    static async getManagerReviews(
        authContext: IAuthContext,
        managerId: string,
        reviewPeriodId: string,
    ): Promise<IManagerReviewsType> {
        const httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
        const url =
            scaServiceConfig.baseUrl +
            scaServiceConfig.scaManagerReviewsEndpoint
                .replace('{id}', managerId)
                .replace('{reviewId}', reviewPeriodId.toLocaleUpperCase());
        httpOptions.method = 'GET';
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const ret = await res.json();
            return {
                hasDirectsInReview: true,
                results: ret,
            };
        } else {
            throw res;
        }
    }

    static async getScaReviewsForPersonnel(
        authContext: IAuthContext,
        personnelId: string,
        continuationToken?: string,
        // Default: true, so as to force the caller to specify
        // false if it doesn't want the comments removed.
        removeComments?: boolean,
    ): Promise<IScaRecordResult> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            scaServiceConfig.aadScopes,
            JSON_CONTENT_TYPE,
            continuationToken,
        );
        let url =
            scaServiceConfig.baseUrl +
            scaServiceConfig.scaEmployeeReviewEndpoint.replace('{id}', personnelId);

        if (removeComments || removeComments === undefined) {
            const urlParams = new URLSearchParams();
            urlParams.append('removeComments', 'true');
            url += '?' + urlParams.toString();
        }
        httpOptions.method = 'GET';
        return ClientUtils.doHttpRequest<IScaRecordResult>(url, httpOptions);
    }

    static async isScaMember(authContext: IAuthContext, personnelId: string): Promise<boolean> {
        const ret = await this.getScaReviewsForPersonnel(authContext, personnelId);
        return ret.results && ret.results.length > 0;
    }

    static async getAllScaReviewsForPersonnel(
        authContext: IAuthContext,
        personnelId: string,
        removeComments?: boolean,
    ): Promise<IScaRecord[]> {
        let ret: IScaRecord[] = [];
        let continuationToken = '';

        do {
            const pagedResult = await this.getScaReviewsForPersonnel(
                authContext,
                personnelId,
                continuationToken,
                removeComments,
            );
            ret = ret.concat(pagedResult.results);
            continuationToken = pagedResult.continuationToken;
        } while (continuationToken);

        return ret;
    }

    static async getTopScaReviewsForPersonnelWithSortingCriteria(
        authContext: IAuthContext,
        personnelId: string,
        topCount: number,
        sortBy: SCAEmployeeRecordSortBy = SCAEmployeeRecordSortBy.LastModifiedTimestampUTC,
        ordering: SCAEmployeeRecordOrder = SCAEmployeeRecordOrder.Desc,
    ): Promise<IScaRecord[]> {
        const httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);

        const urlParams = new URLSearchParams();
        urlParams.append('topCount', topCount.toString());
        urlParams.append('sortBy', sortBy);
        urlParams.append('ordering', ordering);

        const url =
            scaServiceConfig.baseUrl +
            scaServiceConfig.scaEmployeeTopSortedReview.replace('{id}', personnelId) +
            '?' +
            urlParams.toString();
        httpOptions.method = 'GET';
        return ClientUtils.doHttpRequest(url, httpOptions);
    }

    static async updateReviewPeriod(
        authContext: IAuthContext,
        reviewPeriodRequestBody: IScaCreateReviewRequestBody,
    ): Promise<IReviewPeriod> {
        return this._upsertReviewPeriod(authContext, reviewPeriodRequestBody, 'PUT');
    }

    static async createReviewPeriod(
        authContext: IAuthContext,
        reviewPeriodRequestBody: IScaCreateReviewRequestBody,
    ): Promise<IReviewPeriod> {
        return this._upsertReviewPeriod(authContext, reviewPeriodRequestBody, 'POST');
    }

    private static async _upsertReviewPeriod(
        authContext: IAuthContext,
        reviewPeriodRequestBody: IScaCreateReviewRequestBody,
        method: 'POST' | 'PUT',
    ): Promise<IReviewPeriod> {
        const httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
        httpOptions.method = method;
        httpOptions.body = JSON.stringify(reviewPeriodRequestBody);
        return ClientUtils.doHttpRequest<IReviewPeriod>(
            scaServiceConfig.baseUrl + scaServiceConfig.reviewPeriodEndpoint,
            httpOptions,
        );
    }

    // Delete: Review Period that is in PREPARATION state
    static async deleteReviewPeriodById(
        authContext: IAuthContext,
        reviewId: string,
    ): Promise<void> {
        const httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
        httpOptions.method = 'DELETE';
        const result = await fetch(
            scaServiceConfig.baseUrl +
                scaServiceConfig.reviewPeriodEndpoint +
                reviewId.toUpperCase(),
            httpOptions,
        );
        if (result.status === 200) {
            return;
        } else {
            throw result;
        }
    }

    static async getReviewPeriods(
        authContext: IAuthContext,
        continuationToken = '',
    ): Promise<IPagedResults<IReviewPeriod> | undefined> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            scaServiceConfig.aadScopes,
            JSON_CONTENT_TYPE,
            continuationToken,
        );
        return ClientUtils.doHttpRequest<IPagedResults<IReviewPeriod>>(
            scaServiceConfig.baseUrl + scaServiceConfig.reviewPeriodEndpoint,
            httpOptions,
            3,
        );
    }

    // GET review period by id
    static async getReviewPeriodById(
        authContext: IAuthContext,
        reviewId: string,
    ): Promise<IReviewPeriod> {
        const httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
        const res = await fetch(
            scaServiceConfig.baseUrl +
                scaServiceConfig.reviewPeriodEndpoint +
                reviewId.toUpperCase(),
            httpOptions,
        );

        if (res.status === 200) {
            const ret = await res.json();
            return ret;
        } else {
            throw res;
        }
    }

    static async getCurrentReviewPeriod(authContext: IAuthContext): Promise<IReviewPeriod> {
        const httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
        return ClientUtils.doHttpRequest<Promise<IReviewPeriod>>(
            scaServiceConfig.baseUrl + scaServiceConfig.reviewPeriodEndpoint + 'current',
            httpOptions,
        );
    }

    // Deletes existing SCA Employee Record part of the Current Review Period.
    static async deleteEmployeeReviewRecord(
        authContext: IAuthContext,
        personnelId: string,
    ): Promise<void> {
        const httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
        httpOptions.method = 'DELETE';
        const url =
            scaServiceConfig.baseUrl +
            scaServiceConfig.scaEmployeeCurrentReviewEndpoint.replace('{id}', personnelId);
        return ClientUtils.doHttpRequest<void>(url, httpOptions);
    }

    // Gets SCA Employee Records for a given Review Period
    static async getSCAEmployeesByReviewId(
        authContext: IAuthContext,
        reviewId: string,
        continuationToken?: string,
    ): Promise<IReviewIdResult> {
        let httpOptions: RequestInit = {} as RequestInit;
        try {
            httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
            if (continuationToken) {
                httpOptions.headers = {
                    ...httpOptions.headers,
                    'x-scope': 'ags',
                    'x-continuation-token': continuationToken,
                };
            }
            const url =
                scaServiceConfig.baseUrl +
                scaServiceConfig.scaEmployeeEndpoint +
                'review/' +
                reviewId;
            const result = await ClientUtils.doHttpRequest<IReviewIdResult>(url, httpOptions);
            return result;
        } catch (e) {
            console.error(e);
            return {
                results: [],
                continuationToken: '',
            };
        }
    }

    // Gets SCA Employee Records for a given Review Period
    static async getSCAEmployeeReviewInPeriod(
        authContext: IAuthContext,
        personnelId: string,
        reviewId: string,
    ): Promise<IScaRecord> {
        let httpOptions: RequestInit = {} as RequestInit;
        httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
        const url =
            scaServiceConfig.baseUrl +
            scaServiceConfig.scaEmployeeReviewInPeriod
                .replace(/{id}/, personnelId)
                .replace(/{reviewPeriodId}/, reviewId);

        const result = await fetch(url, httpOptions);

        if (result.status === 200) {
            return result.json();
        } else if (result.status === 404) {
            throw 'Not Found';
        } else {
            throw result;
        }
    }

    // History
    static async getSCAEmployeesReviews(
        authContext: IAuthContext,
        personnelId: string,
    ): Promise<IReviewIdResult> {
        /* TODO:
           The Angular code has the following if statement. Keep This
           commented code for now. I may have to uncomment it eventually.

           if (!personnelId) {
               const user = this.userService.getUser();
               personnelId = user.id;
           }
        */
        const httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
        const url =
            scaServiceConfig.baseUrl +
            scaServiceConfig.scaEmployeeEndpoint +
            `${personnelId}/reviews`;
        return ClientUtils.doHttpRequest<IReviewIdResult>(url, httpOptions);
    }

    static async addOrEditSCAEmployee(
        authContext: IAuthContext,
        personnelId: string,
        rate: number,
        reviewComment: string | undefined,
        reviewState: EmployeeReviewStatusCodeType | undefined,
        mode: 'update' | 'add',
    ): Promise<IScaRecord> {
        const httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
        switch (mode) {
            case 'update':
                httpOptions.method = 'PUT';
                httpOptions.body = `{ rate: ${rate}, reviewComment: "${
                    reviewComment ?? ''
                }", reviewState: ${reviewState} }`;
                break;
            case 'add':
                httpOptions.method = 'POST';
                httpOptions.body = `{ rate: ${rate}, }`;
                break;
            default:
                throw 'DUDE!';
        }
        const url =
            scaServiceConfig.baseUrl +
            scaServiceConfig.scaEmployeeEndpoint +
            `${personnelId}/current/review`;
        return ClientUtils.doHttpRequest<IScaRecord>(url, httpOptions);
    }

    static async getReviewReport(
        authContext: IAuthContext,
        reviewId: string,
    ): Promise<IScaReportRecord[]> {
        const httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
        return ClientUtils.doHttpRequest<Promise<IScaReportRecord[]>>(
            scaServiceConfig.baseUrl + scaServiceConfig.reportReviewEndpoint + reviewId,
            httpOptions,
        );
    }

    static async getScaEmployeeRecordsForExec(
        authContext: IAuthContext,
        personnelId: string,
        reviewPeriodId: string,
    ): Promise<IScaExecOrgResponse> {
        const httpOptions = await GetAadHttpOptions(authContext, scaServiceConfig.aadScopes);
        return ClientUtils.doHttpRequest<Promise<IScaExecOrgResponse>>(
            scaServiceConfig.baseUrl +
                scaServiceConfig.scaEmployeeRecordsForExec
                    .replace(/{personnelId}/, personnelId)
                    .replace(/{reviewPeriodId}/, reviewPeriodId.toUpperCase()),
            httpOptions,
        );
    }
}

export interface IScaRecordResult {
    results: IScaRecord[];
    continuationToken: string;
}

export interface IScaRecord {
    alias: string;
    createdBy: string;
    creationTimestampUTC: number;
    id: string;
    lastModifiedBy: string;
    lastModifiedTimestampUTC: number;
    name: string;
    personnelId: string;
    previousRate: number;
    rate: number;
    rateChangedBy: string;
    rateChangedTimestampUTC: number;
    reviewComment: string;
    reviewCommentBy: string;
    reviewCommentTimestampUTC: number;
    reviewPeriodId: string;
    reviewState: string;
    reviewedBy: string;
    reviewedTimestampUTC: number;
}

export interface IScaReportRecord {
    personnelId: string;
    alias: string;
    lastName: string;
    firstName: string;
    middleName: string;
    title: string;
    organization: string;
    geographicLocation: string;
    officeLocation: string;
    l1: number;
    l2: number;
    l3: number;
    l4: number;
    l5: number;
    l6: number;
    reportsTo: string;
    reviewPeriodId: string;
    rate: number;
    previousRate: number;
    createdBy: string;
    createdTimestampUTC: string; // eg "2021-05-19T20:16:24"
    rateChangedBy: string;
    rateChangedTimestampUTC: string;
    reviewState: string;
    reviewedBy: string;
    reviewedTimestampUTC: string;
    reviewComment: string;
    reviewCommentBy: string;
    reviewCommentTimestampUTC: string;
}

export type IEmployeeReview = IScaRecord;

// The following values are the possible values
// that the server will send for each employee's
// review record on property IScaRecord.reviewState.
// Except for "ALL". It appears to be for filtering.
// It's not a valid employee elibility status.
export enum ReviewState {
    NotDetermined = 'NOT_DETERMINED',
    Eligible = 'ELIGIBLE',
    Ineligible = 'INELIGIBLE',
    All = 'ALL',
}

export type EmployeeReviewStatusCodeType = 0 | 1 | 2;

type EmployeeReviewStatusType = {
    code: EmployeeReviewStatusCodeType;
    recordValue: string; // The value received from backend
    displayText: string; // The text to display on screen
};

export const EmployeeReviewStatuses: EmployeeReviewStatusType[] = [
    {
        code: 1,
        displayText: 'Eligible',
        recordValue: ReviewState.Eligible,
    },
    {
        code: 2,
        displayText: 'Ineligible',
        recordValue: ReviewState.Ineligible,
    },
    {
        code: 0,
        displayText: 'Not Determined',
        recordValue: ReviewState.NotDetermined,
    },
];

export const reviewStates = (): ReviewState[] =>
    Object.values(ReviewState).filter((state) => state !== ReviewState.All) as ReviewState[];

export const reviewStatusDisplayText = (state: ReviewState): string | undefined => {
    return EmployeeReviewStatuses.find((s) => s.recordValue === state)?.displayText;
};

export const EmployeeReviewStatusDisplayTexts = (): string[] =>
    EmployeeReviewStatuses.map((state) => state.displayText);

export enum SCAEmployeeRecordSortBy {
    LastModifiedTimestampUTC = 'LastModifiedTimestampUTC',
    CreationTimestampUTC = 'CreationTimestampUTC',
}

export enum SCAEmployeeRecordOrder {
    Asc = 'ASC',
    Desc = 'DESC',
}

export interface IReviewPeriod {
    id: string;
    title: string;
    isCurrent?: boolean;
    state?: string;
    startDateUTC: number;
    endDateUTC: number;
    eligibilityDateUTC: number;
    approvalStartDateUTC: number;
    approvalEndDateUTC: number;
    escalations: IEscalation[];
    createdBy?: string;
    creationTimestampUTC?: number;
    lastModifiedBy?: string;
    lastModifiedTimestampUTC?: number;
}

export interface IReviewIdResult {
    results: IScaRecord[];
    continuationToken: string;
}

export interface IEscalation {
    id: string;
    name: string;
    hierarchy: number;
    date: number;
}

export interface IScaCreateReviewRequestBody {
    state?: ReviewPeriodStatusCodeType;
    id: string;
    title: string;
    startDate: Date;
    endDate: Date;
    eligibilityDate: Date;
    approvalStartDate: Date;
    approvalEndDate: Date;
    escalations: {
        name: string;
        hierarchy: number;
        date: Date;
    }[];
}

interface IManagerReviewsType {
    results: IScaRecord[];
    hasDirectsInReview: boolean;
}

export interface IScaExecOrgResponse {
    stats: IScaEmployeeReviewCount[];
    managerGroups: IManagerGroupResponse[];
}

export interface IScaEmployeeReviewCount {
    reviewState: ReviewState;
    count: number;
}

export interface IManagerGroupResponse {
    managerHierarchy: IManagerGroupItem[];
    directReports: IScaRecord[];
}

export interface IManagerGroupItem {
    managerId: string;
    managerName: string;
    managerAlias: string;
    managerLevel: number;
}
