/**
 * File Name: document-requester
 * Team: Personnel - Screening Team
 * Summary: Requests Document(s) from Screening's User (can only be done by NST)
 * Last Updated Summary: 10/7/2022 by andreli
 **/
import React, { useState, useEffect, useContext } from 'react';
import { SharedColors } from '@fluentui/theme';
import {
    mergeStyleSets,
    FontSizes,
    Separator,
    DetailsList,
    DetailsListLayoutMode,
    SelectionMode,
    IColumn,
    ActionButton,
    PrimaryButton,
    DefaultButton,
    Checkbox,
    MessageBar,
    MessageBarType,
    Modal,
    ContextualMenu,
    SpinnerSize,
    Spinner,
} from '@fluentui/react';
import TemplateClient, {
    IDocumentMetadata,
    IScreeningTemplateRequestRecord,
    ScreeningTemplateRequestedState,
} from 'clients/template-client';
import { AuthContext } from 'contexts/auth-context';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import PublicTrustDocumentClient from 'clients/screening/public-trust-document-client';
import { ViewRequestedDocumentModal } from 'components/screening/common/documents/modals/view-requested-document-modal';
import { documentScreeningStyles } from 'components/screening/common/documents/document-styles';
import OtherDocumentsRequester from 'components/screening/common/documents/other-document-requester';

interface IOtherDocumentStruct {
    documentMetadata: IDocumentMetadata;
    uploadFile?: File;
}

interface DocumentRequesterProps {
    handleModalClose(): void;
    screeningId: string;
    nomineeId: string;
    updateDocumentInfo(requests: IScreeningTemplateRequestRecord[]): void;
    isDocumentRequestTitleUnique(title: string): boolean;
    screeningPaths: ScreeningPaths;
    documentTypesMap?: Map<string, string>;
}

function DocumentRequester(props: DocumentRequesterProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [shouldShowOtherDocumentsRequester, setShowOtherDocumentsRequester] = useState(false);
    const [isDuplicateTitle, setIsDuplicateTitle] = useState(false);
    const [items, setItems] = useState<IDocumentMetadata[]>();
    const [selectedItems, setSelectedItems] = useState<IDocumentMetadata[]>([]);

    const [isSendRequestDisabled, setSendRequestDisabled] = useState(false);
    const [updateItems, setUpdateItems] = useState(0);

    const [userSpecificRequests, setUserSpecificRequests] = useState<IOtherDocumentStruct[]>([]);

    function addOtherDocumentStruct(documentMetadata: IDocumentMetadata, uploadFile?: File): void {
        if (documentMetadata) {
            if (props.isDocumentRequestTitleUnique(documentMetadata.title)) {
                const otherFiles: IOtherDocumentStruct[] = userSpecificRequests.slice(0);
                const newFile: IOtherDocumentStruct = { documentMetadata: documentMetadata };
                if (uploadFile) {
                    newFile.uploadFile = uploadFile;
                }
                otherFiles.push(newFile);
                setUserSpecificRequests(otherFiles);
                setUpdateItems(updateItems + 1);
            } else {
                setIsDuplicateTitle(true);
            }
        }
    }

    useEffect(() => {
        async function getRequestableDocuments(): Promise<void> {
            try {
                let requestableDocuments: IDocumentMetadata[] = [];
                if (props.screeningPaths === ScreeningPaths.UsGov) {
                    requestableDocuments = await TemplateClient.getAllRequestableDocuments(
                        authContext,
                        props.screeningId,
                    );
                } else {
                    requestableDocuments = await PublicTrustDocumentClient.getAllRequestableDocuments(
                        authContext,
                        props.screeningId,
                    );
                }

                const metadataDocuments = requestableDocuments.filter(
                    (x: IDocumentMetadata) => x.screeningId !== props.screeningId,
                );
                userSpecificRequests.forEach((x) => metadataDocuments.push(x.documentMetadata));
                setItems(metadataDocuments);
            } catch (err) {
                // use message bar error message
                console.error('Error ', err);
            }
        }
        getRequestableDocuments();
    }, [updateItems]);

    useEffect(() => {
        if (selectedItems && userSpecificRequests) {
            setSendRequestDisabled(selectedItems.length === 0 && userSpecificRequests.length === 0);
        }
    }, [selectedItems, userSpecificRequests]);

    function addToSelected(checked?: boolean, markedItem?: IDocumentMetadata): void {
        if (checked && markedItem) {
            const arrayOfSelectedItems = selectedItems.slice(0);
            arrayOfSelectedItems.push(markedItem);
            setSelectedItems(arrayOfSelectedItems);
        } else if (markedItem) {
            const arrayOfSelectedItems: IDocumentMetadata[] = selectedItems.filter(
                (item) => item.documentId !== markedItem.documentId,
            );
            setSelectedItems(arrayOfSelectedItems);
        }
    }

    function itemChecked(documentMetaData: IDocumentMetadata): boolean | undefined {
        const isItemUserSpecific =
            userSpecificRequests.filter(
                (x) => x.documentMetadata.screeningId === documentMetaData.screeningId,
            ).length > 0;
        // returned undefined here so checkbox can be controlled by user.
        return isItemUserSpecific ? true : undefined;
    }

    function getBlobData(documentMetaData: IDocumentMetadata): File | undefined {
        const foundItem = userSpecificRequests.find(
            (x) => x.documentMetadata.title === documentMetaData.title,
        );

        return foundItem?.uploadFile;
    }

    function removeDocumentRequest(documentMetaData: IDocumentMetadata): void {
        if (items) {
            const newItems = items.filter((x) => x.title !== documentMetaData.title);
            const newUserSpecificRequests = userSpecificRequests.filter(
                (x) => x.documentMetadata.title !== documentMetaData.title,
            );
            addToSelected(false, documentMetaData);
            setItems(newItems);
            setUserSpecificRequests(newUserSpecificRequests);
        }
    }

    const columnsForTemplates: IColumn[] = [
        {
            key: 'checkbox',
            name: 'checkbox',
            ariaLabel: 'Column for the checkbox',
            fieldName: 'checkbox',
            minWidth: 20,
            maxWidth: 20,
            onRender: (item: IDocumentMetadata): JSX.Element => {
                return (
                    <Checkbox
                        checked={itemChecked(item) ?? false}
                        onChange={(
                            ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                            checked?: boolean,
                        ): void => {
                            addToSelected(checked, item);
                        }}
                        key={item.title}
                    />
                );
            },
        },
        {
            key: 'title',
            name: 'Title',
            ariaLabel: 'Column for the document title',
            fieldName: 'title',
            minWidth: 100,
            onRender: (item: IDocumentMetadata): string => {
                return item.title;
            },
        },
        {
            key: 'actions',
            name: 'Actions',
            ariaLabel: 'Column for the actions on the document',
            fieldName: 'action',
            minWidth: 300,
            onRender: (item: IDocumentMetadata): JSX.Element => {
                return (
                    <>
                        {item && props.screeningId === item?.screeningId && (
                            <ActionButton
                                onClick={(): void => removeDocumentRequest(item)}
                                iconProps={{ iconName: 'Delete' }}>
                                Remove
                            </ActionButton>
                        )}
                        <ViewRequestedDocumentModal
                            title='View'
                            iconName='TextDocument'
                            documentMetadata={item}
                            file={getBlobData(item)}
                            isUploadingTemplateRequest={true}
                            referenceId={props.screeningId}
                            screeningPath={props.screeningPaths}
                            nomineeId={props.nomineeId}
                            documentTypesMap={props.documentTypesMap}
                        />
                    </>
                );
            },
        },
    ];

    async function sendRequest(): Promise<void> {
        try {
            setSendRequestDisabled(true);
            const itemsToAdd: IDocumentMetadata[] = selectedItems.slice(0);
            if (userSpecificRequests.length > 0) {
                //Uploading additional files for the screeningId
                const promiseArray: Promise<IDocumentMetadata>[] = userSpecificRequests.map(
                    (otherStruct): Promise<IDocumentMetadata> => {
                        if (props.screeningPaths === ScreeningPaths.UsGov) {
                            return TemplateClient.saveRequestableDocument(
                                authContext,
                                props.screeningId,
                                otherStruct.documentMetadata,
                                otherStruct.uploadFile,
                            );
                        } else {
                            return PublicTrustDocumentClient.saveRequestableDocument(
                                authContext,
                                props.screeningId,
                                otherStruct.documentMetadata,
                                otherStruct.uploadFile,
                            );
                        }
                    },
                );
                await Promise.all(promiseArray).then((metadataResults) => {
                    metadataResults.forEach((metadata: IDocumentMetadata | undefined) => {
                        if (metadata) {
                            // skip adding failed request items
                            itemsToAdd.push(metadata);
                        }
                    });
                });
            }

            const requestArray: IScreeningTemplateRequestRecord[] = itemsToAdd.map((item) => {
                return {
                    id: '',
                    documentId: item.documentId,
                    documentType: item.documentType,
                    message: '',
                    personnelId: props.nomineeId,
                    requestedState: ScreeningTemplateRequestedState.RequestedState,
                    screeningId: props.screeningId,
                    title: item.title,
                };
            });

            let newRequests: IScreeningTemplateRequestRecord[] = [];
            if (props.screeningPaths === ScreeningPaths.UsGov) {
                newRequests = await TemplateClient.updateMultipleDocuments(
                    authContext,
                    requestArray,
                );
            } else {
                newRequests = await PublicTrustDocumentClient.updateMultipleDocuments(
                    authContext,
                    requestArray,
                );
            }
            props.updateDocumentInfo(newRequests);
            props.handleModalClose();
        } catch (e) {
            console.error(e, 'document-requester update to send Request');
        }
    }

    return (
        <div className={documentScreeningStyles.root}>
            <div className={documentScreeningStyles.body}>
                <div className={documentScreeningStyles.label}>Request documents</div>
                <div className={documentScreeningStyles.sublabel}>
                    Choose from existing templates, or select Other documents for adding one-off
                    requests
                </div>
                <div className={documentScreeningStyles.content}>
                    <Separator styles={separatorStyles} alignContent='start'>
                        Templates
                    </Separator>
                    {items && (
                        <DetailsList
                            items={items}
                            columns={columnsForTemplates}
                            selectionMode={SelectionMode.none}
                            setKey='templates_table'
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={false}
                        />
                    )}
                    {!items && (
                        <>
                            <Spinner size={SpinnerSize.xSmall} label='Loading...' />
                            <br />
                        </>
                    )}
                    {isDuplicateTitle && (
                        <div>
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                onDismiss={(): void => setIsDuplicateTitle(false)}>
                                Duplicate title names are not allowed.
                            </MessageBar>
                        </div>
                    )}

                    <DefaultButton
                        text='Other documents'
                        iconProps={{ iconName: 'CircleAddition' }}
                        allowDisabledFocus
                        styles={otherDocsBtnStyles}
                        onClick={(): void => setShowOtherDocumentsRequester(true)}
                    />
                    <Modal
                        isOpen={shouldShowOtherDocumentsRequester}
                        onDismiss={(): void => setShowOtherDocumentsRequester(false)}
                        isBlocking={true}
                        isDarkOverlay={false}
                        containerClassName={documentScreeningStyles.modalContainer}
                        dragOptions={{
                            moveMenuItemText: 'Move',
                            closeMenuItemText: 'Close',
                            menu: ContextualMenu,
                        }}>
                        <OtherDocumentsRequester
                            screeningId={props.screeningId}
                            screeningPath={props.screeningPaths}
                            handleModalClose={(
                                documentMetaData: IDocumentMetadata,
                                uploadFile?: File,
                            ): void => {
                                addOtherDocumentStruct(documentMetaData, uploadFile);
                                setShowOtherDocumentsRequester(false);
                            }}
                        />
                    </Modal>
                </div>
            </div>
            <div className={documentScreeningStyles.footer}>
                <DefaultButton text='Cancel' onClick={props.handleModalClose} allowDisabledFocus />
                <PrimaryButton
                    onClick={sendRequest}
                    text='Send request'
                    allowDisabledFocus
                    disabled={isSendRequestDisabled}
                />
            </div>
        </div>
    );
}

const otherDocsBtnStyles = mergeStyleSets({
    root: { fontSize: FontSizes.smallPlus, marginTop: 10 },
    icon: { color: SharedColors.cyanBlue20 },
});

const separatorStyles = mergeStyleSets({
    root: {
        fontSize: FontSizes.smallPlus,
    },
});

export default DocumentRequester;
