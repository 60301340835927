export enum Role {
    PortalAdmin = 'Portal_Admin',
    AttributesRead = 'Attributes_Read',
    AttributesWrite = 'Attributes_Write',
    AttributesModify = 'Attributes_Modify',
    AttributesDelete = 'Attributes_Delete',
    EligibilitiesRead = 'Eligibilities_Read',
    EligibilitiesWrite = 'Eligibilities_Write',
    EligibilitiesModify = 'Eligibilities_Modify',
    EligibilitiesDelete = 'Eligibilities_Delete',
    PersonnelAttributesRead = 'PersonnelAttributes_Read',
    PersonnelEligibilitiesRead = 'PersonnelEligibilities_Read',
    PersonnelAttributesAdd = 'PersonnelAttributes_Add',
    PersonnelEligibilitiesAdd = 'PersonnelEligibilities_Add',
    PersonnelAttributesDelete = 'PersonnelAttributes_Delete',
    PersonnelEligibilitiesDelete = 'PersonnelEligibilities_Delete',
    PersonnelAssignmentRead = 'PersonnelAssignment_Read',
    PersonnelAssignmentWrite = 'PersonnelAssignment_Write',
    VisitorRecordRead = 'VisitorRecord_Read',
    VisitorRecordWrite = 'VisitorRecord_Write',
    VisitorRecordModify = 'VisitorRecord_Modify',
    VisitorRecordDelete = 'VisitorRecord_Delete',
    FormRecordRead = 'FormRecord_Read',
    FormRecordWrite = 'FormRecord_Write',
    FormRecordModify = 'FormRecord_Modify',
    FormRecordDelete = 'FormRecord_Delete',
    EmailRead = 'Email_Read',
    EmailSend = 'Email_Send',
    EmailCoordinator = 'Email_Coordinator',
    EmailTemplateRead = 'EmailTemplate_Read',
    EmailTemplateCreate = 'EmailTemplate_Create',
    EmailTemplateModify = 'EmailTemplate_Modify',
    EmailTemplateDelete = 'EmailTemplate_Delete',
    SCAAdmin = 'SCA_Admin',
    SCAExec = 'SCA_Exec',
    DocAdmin = 'Doc_Admin',
    DocCreate = 'Doc_Create',
    GroupAdmin = 'Group_Admin',
    StaffingAdminRead = 'Staffing_Admin_Read',
    StaffingAdminEdit = 'Staffing_Admin_Edit',
    StaffingOrgEdit = 'Staffing_Org_Edit',
    StaffingOrgRead = 'Staffing_Org_Read',
    ReportReadinessRead = 'Report_Readiness_Read',
    CoreAttributeAdmin = 'Core_Attribute_Admin',
}

export const StaffingRoles = [
    Role.StaffingAdminEdit,
    Role.StaffingAdminRead,
    Role.StaffingOrgEdit,
    Role.StaffingOrgRead,
];

export type StaffingOrgRolesType = Role.StaffingOrgRead | Role.StaffingOrgEdit;

export const StaffingAdminRoles = [Role.StaffingAdminEdit, Role.StaffingAdminRead];

export enum ScreeningRole {
    ContractAdmin = 'ContractAdmin',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Screening_Officer = 'Screening_Officer',
}
