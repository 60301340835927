import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import SidebarAndContents, { SidebarPane } from 'components/common/sidebar-and-contents';
import EmailClient, { IEmailRecord, SendResultCode } from 'clients/email-client';
import {
    ActionButton,
    Separator,
    DatePicker,
    TextField,
    Dropdown,
    IDropdownOption,
    mergeStyles,
    MessageBarType,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import Spacer from 'components/common/spacer';
import { Table } from 'components/common/table';
import tableColumnsEmailSearch from 'components/email/email-search-table-columns';
import { globalFilterSeparatorStyles } from 'assets/styles/global-styles';
import BoldFont from 'components/common/misc/bold-font';
import { ANY, EmailFilters } from 'components/email/email-dashboard';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { enumNameToDisplayString } from 'utils/enum-utils';
import { useParams } from 'react-router-dom';
import EmailDetails from 'components/email/email-details';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import useMessageBar, { IUseMessageBar } from 'components/common/use-message-bar';

const pausedTableStyle = mergeStyles({
    backgroundColor: 'rgba(0, 0, 0, 0.065)',
});

const messageBarStyles = mergeStyles({
    width: '75%',
    margin: '0 2rem',
});

const tableStyles = mergeStyles({
    width: '100%',
    '@media(max-width: 1380px)': {
        width: '80%',
    },
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
});

type IEmailSearchProps = {
    isFetchingData: boolean;
    templates: IEmailRecord[];
    continuationToken: string;
    emailFilters: EmailFilters;
    errorMessageBar: IUseMessageBar;

    onClearFilters: () => void;
    setShouldFetchEmailRecords: React.Dispatch<React.SetStateAction<boolean>>;
    updateFilters: (property: keyof EmailFilters, value: string | Date | null) => void;
};

export default function EmailSearch(props: IEmailSearchProps): JSX.Element {
    const {
        templates,
        emailFilters,
        isFetchingData,
        errorMessageBar,
        continuationToken,
        updateFilters,
        onClearFilters,
        setShouldFetchEmailRecords,
    } = props;
    const authContext = useContext(AuthContext);
    const isEmailCoordinator = useMemo(() => authContext.isInRole(Role.EmailCoordinator), [
        authContext,
    ]);
    const canResendEmails = useMemo(
        () => authContext.isInRole(Role.EmailCoordinator) || authContext.isInRole(Role.EmailSend),
        [authContext],
    );
    const [emailResending, setEmailResending] = useState<string>('');

    const [isSendingOn, setIsSendingOn] = useState<boolean>();

    const { id: emailId } = useParams<{ id: string }>();

    const setPauseStatus = useCallback(async () => {
        setIsSendingOn((await EmailClient.getEmailPauseStatus(authContext)).emailsEnabled);
    }, [authContext]);

    useEffect(() => {
        if (isEmailCoordinator) {
            setPauseStatus();
        }
    }, [setPauseStatus, isEmailCoordinator]);

    const sendResultCodeOptions = useMemo((): IDropdownOption[] => {
        const resultCodeOptions = [
            { key: ANY, text: 'Any' },
            ...Object.entries(SendResultCode).map(([key, val]) => {
                return { key: key, text: enumNameToDisplayString(val) };
            }),
        ];

        return resultCodeOptions;
    }, []);

    const successMessageBar = useMessageBar({ type: MessageBarType.success });

    const clearMessageBar = useCallback(() => {
        successMessageBar.clearMessage();
        errorMessageBar.clearMessage();
    }, [errorMessageBar, successMessageBar]);

    const onScrollReachedBottom = useCallback(() => {
        setShouldFetchEmailRecords(true);
    }, [setShouldFetchEmailRecords]);

    useEffect(() => {
        if (continuationToken && templates.length < 40) {
            onScrollReachedBottom();
        }
    }, [continuationToken, onScrollReachedBottom, templates.length]);

    const renderLabel = useCallback((label: string) => {
        return (
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <BoldFont>{label}</BoldFont>
            </Separator>
        );
    }, []);

    const onScroll = (event: React.UIEvent<HTMLElement>): void => {
        const target = event.target as HTMLInputElement;
        if (target.scrollHeight - target.scrollTop <= 1.05 * target.clientHeight) {
            onScrollReachedBottom();
        }
    };

    const resendEmail = useCallback(
        async (id: string) => {
            try {
                clearMessageBar();
                setEmailResending(id);
                await EmailClient.resendEmail(authContext, id);
                successMessageBar.setMessage(`Successfully resent email ${id}.`);
            } catch (e) {
                console.error(e);
                errorMessageBar.setMessage(`There was an error resending email ${id}.`);
            } finally {
                setEmailResending('');
            }
        },
        [authContext, clearMessageBar, errorMessageBar, successMessageBar],
    );

    const emailTemplateTableColumns = useMemo(() => {
        return tableColumnsEmailSearch({ resendEmail, canResendEmails, emailResending });
    }, [canResendEmails, emailResending, resendEmail]);

    return (
        <>
            <SidebarAndContents>
                <SidebarPane>
                    <TextField
                        label='Email ID'
                        onRenderLabel={(): JSX.Element => renderLabel('Email ID')}
                        value={emailFilters.id}
                        onChange={(event, value): void => updateFilters('id', value ?? '')}
                        resizable={false}
                        ariaLabel='Email ID'
                        autoComplete='off'
                    />
                    <Spacer marginTop={10} />
                    <TextField
                        label='Application ID'
                        onRenderLabel={(): JSX.Element => renderLabel('Application ID')}
                        value={emailFilters.senderApplicationId}
                        onChange={(event, value): void =>
                            updateFilters('senderApplicationId', value ?? '')
                        }
                        resizable={false}
                        ariaLabel='Application ID'
                        autoComplete='off'
                    />
                    <Spacer marginTop={10} />
                    <TextField
                        label='Subject'
                        onRenderLabel={(): JSX.Element => renderLabel('Subject')}
                        value={emailFilters.subject}
                        onChange={(event, value): void => updateFilters('subject', value ?? '')}
                        resizable={false}
                        ariaLabel='Subject'
                        autoComplete='off'
                    />
                    <Spacer marginTop={10} />
                    <TextField
                        label='To/Cc/Bcc'
                        onRenderLabel={(): JSX.Element => renderLabel('To/Cc/Bcc')}
                        value={emailFilters.to}
                        onChange={(event, value): void => updateFilters('to', value ?? '')}
                        resizable={false}
                        ariaLabel='To/Cc/Bcc'
                        autoComplete='email'
                    />
                    <Spacer marginTop={10} />
                    <Dropdown
                        label={'Send Result Code'}
                        onRenderLabel={(): JSX.Element => renderLabel('Send Result Code')}
                        selectedKey={emailFilters.sendResultCode}
                        onChange={(event, option): void => {
                            if (option) {
                                updateFilters('sendResultCode', option.key as string);
                            }
                        }}
                        options={sendResultCodeOptions}
                        ariaLabel={'Send Result Code'}
                    />
                    <Spacer marginTop={5} />
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        <BoldFont>Created After</BoldFont>
                    </Separator>
                    <DatePicker
                        value={emailFilters.createdAfter ?? undefined}
                        onSelectDate={(value): void => {
                            if (value) {
                                updateFilters('createdAfter', value);
                            }
                        }}
                        ariaLabel='Date Added'
                    />
                    <Spacer marginTop={5} />
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        Sent After
                    </Separator>
                    <DatePicker
                        value={emailFilters.sentAfter ?? undefined}
                        onSelectDate={(value): void => {
                            if (value) {
                                updateFilters('sentAfter', value);
                            }
                        }}
                        ariaLabel='Date Added'
                    />
                    <Spacer marginTop={20} />
                    <ActionButton
                        iconProps={{ iconName: IconNames.ClearFilter }}
                        onClick={onClearFilters}>
                        <span>Clear filters</span>
                    </ActionButton>
                </SidebarPane>
                <div className={tableStyles}>
                    <HorizontalBar>
                        <div style={{ flexGrow: 1 }} className={horizontalBarTitleStyle}>
                            Emails
                        </div>
                        <div className={messageBarStyles}>
                            {successMessageBar.theElement()}
                            {errorMessageBar.theElement()}
                        </div>
                    </HorizontalBar>
                    <div
                        onScroll={onScroll}
                        style={{ overflow: 'auto', width: '100%', height: '78vh' }}
                        className={isSendingOn === false ? pausedTableStyle : ''}>
                        <Table
                            rows={templates}
                            tableColumns={emailTemplateTableColumns}
                            shimmerLabel={isFetchingData ? 'Loading emails...' : ''}
                            isFetchingData={isFetchingData && templates.length === 0}
                        />
                        <IsLoadingIndicator
                            isLoading={isFetchingData && templates.length !== 0}
                            before={<Spacer marginTop={20} />}
                            msg='Loading Emails...'
                        />
                    </div>
                </div>
            </SidebarAndContents>
            {emailId && <EmailDetails emailId={emailId} />}
        </>
    );
}
