import React, { useState, useContext } from 'react';
import AssignAttribute from 'components/user-assignments/manage-attributes/assign-attribute';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import EligibilityClient, {
    IAttributeAssignment,
    PersonnelTypes,
} from 'clients/eligibility-client';
import { AuthContext } from 'contexts/auth-context';
import { IDropdownOption } from '@fluentui/react';
import { canReadErrorMessageBody, readErrorMessageBody } from 'utils/misc-utils';

interface IAddAttributeModalActionButtonProps {
    personnelId: string;
    personnelType: PersonnelTypes;
    attrAssignment: IAttributeAssignment;
    updateAddAttributeAssignment: () => void;
}

function AddAttributeModalActionButton(props: IAddAttributeModalActionButtonProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
    const [selectedItem, setSelectedItem] = useState<IDropdownOption>();
    const [dateValue, setDateValue] = React.useState<Date | undefined | null>(undefined);

    const handleSubmit = async (): Promise<void> => {
        if (selectedItem) {
            try {
                await EligibilityClient.addPersonnelAttribute(
                    authContext,
                    props.personnelId,
                    `${selectedItem.key}`, // convert to string
                    dateValue,
                );
                setSelectedItem(undefined);
                setDateValue(undefined);
            } catch (error) {
                if (canReadErrorMessageBody(error)) {
                    const submitErrorEventText = await readErrorMessageBody(error);
                    throw submitErrorEventText;
                } else {
                    throw error;
                }
            }
        }
    };

    return (
        <ModalActionButton<void>
            text={'Add attribute'}
            modalTitle={'Add Attribute'}
            enableSubmit={isSubmitButtonEnabled}
            iconName={'Add'}
            submitButtonText={'Assign Attribute'}
            onSubmit={handleSubmit}
            onModalConcluded={(conclusion: ModalConclusion): void => {
                if (conclusion === ModalConclusion.Done) {
                    props.updateAddAttributeAssignment();
                } else {
                    setIsSubmitButtonEnabled(false);
                }
            }}>
            {props.attrAssignment && (
                <AssignAttribute
                    personnelType={props.personnelType}
                    handleSetSelectedItem={(item: IDropdownOption): void => {
                        setSelectedItem(item);
                        setIsSubmitButtonEnabled(true);
                    }}
                    handleSetSelectedDate={(date): void => {
                        setDateValue(date);
                        if (selectedItem !== undefined) setIsSubmitButtonEnabled(true);
                    }}
                    handleSetIsSubmitButtonEnabled={(value): void =>
                        setIsSubmitButtonEnabled(value)
                    }
                    attributeOptions={props.attrAssignment}
                />
            )}
        </ModalActionButton>
    );
}

export default AddAttributeModalActionButton;
