import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { useFetchSimple, useIsHtmlSanitized, useIsMounted } from 'utils/misc-hooks';
import EmailClient, { IEmailTemplate, ITemplateResult } from 'clients/email-client';
import { doNothing } from 'utils/misc-utils';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import Spacer from 'components/common/spacer';
import { Link, useParams } from 'react-router-dom';
import { useTextField } from 'components/common/use-input/use-textfield';
import {
    DefaultButton,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from '@fluentui/react';
import ButtonBar from 'components/common/button-bar';
import BoldFont from 'components/common/misc/bold-font';
import ModelKeysComponent from 'components/email/common/model-keys-component';
import TemplateHTMLComponent from 'components/email/common/template-html-component';
import { setFormData } from 'components/email/email-utils';
import { CREATE } from 'components/email/email-dashboard';

enum UpdateTemplateMessages {
    SuccessMessage = 'Template has been successfully saved.',
    ErrorMessage = 'Please add Template label and HTML to email template.',
    FailedSubmissionError = 'There was an error saving your template, please try again.',
    ValidationError = 'Template has invalid characters, or unmatched {{ }}, please edit and save again.',
    SanitizeError = 'Template has elements with invalid or unsafe HTML. Please remove and try again.',
}

type IEditEmailTemplateProps = {
    onModifyTemplate: (modifiedTemplate: IEmailTemplate, isNewTemplate?: boolean) => void;
};

export default function EditEmailTemplate(props: IEditEmailTemplateProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const breadCrumbContext = useContext(BreadCrumbContext);
    const isMounted = useIsMounted();
    const { id: templateId } = useParams<{ id: string }>();
    const [template, setTemplate] = useState<ITemplateResult>();
    const [updatedTemplate, setUpdatedTemplate] = useState<ITemplateResult>();
    const [errorLoadingTemplateMessage, setErrorLoadingTemplateMessage] = useState<string>('');
    const [onUpdateTemplateMessage, setOnUpdateTemplateMessage] = useState<string>('');
    const [modelFields, setModelFields] = useState<string[]>([]);
    const [htmlBody, setHtmlBody] = useState<string>('');
    const isHtmlSanitary = useIsHtmlSanitized(htmlBody);

    const [isSavingTemplate, setIsSavingTemplate] = useState<boolean>();

    const { isFetching: isFetchingTemplate } = useFetchSimple<ITemplateResult>({
        dependencies: [],
        canPerformFetch: templateId !== CREATE,
        fetchFunc: async (): Promise<ITemplateResult> => {
            return EmailClient.getTemplateById(authContext, templateId);
        },
        onSuccess: (result: ITemplateResult): void => {
            setTemplate(result);
            setModelFields(result.record.modelFields);
        },
        onError: (e): void => {
            console.error(e);
            setErrorLoadingTemplateMessage(
                `There was an error loading email template with ID: ${templateId}`,
            );
        },
        onFinally: doNothing,
    });

    const onHandleSaveTemplate = (): void => {
        setIsSavingTemplate(true);
        if (!isValidTemplate()) {
            setOnUpdateTemplateMessage(UpdateTemplateMessages.ValidationError);
            setIsSavingTemplate(false);
            return;
        }
        if (!isHtmlSanitary) {
            setOnUpdateTemplateMessage(UpdateTemplateMessages.SanitizeError);
            setIsSavingTemplate(false);
            return;
        }
        if (templateId === CREATE) {
            onHandleCreateTemplate();
            return;
        }
        onHandleUpdateTemplate();
    };

    const isValidTemplate = (): boolean => {
        if (htmlBody) {
            // searching for {{ word }}, {{word}}, {{ #if word }}, {{ #each word }} {{ /word }} matches, and removing
            const replaceValues = htmlBody.replace(
                /(\{{2}\s?(#(if|each)\s)?(\/)?\w*\s?\}\})/g,
                function (match) {
                    // regex has issue and will find these but these are also bad
                    if (match === '{{ }}') {
                        return match;
                    }
                    return '';
                },
            );
            // if any {{ or }} remain then it is invalid.
            if (replaceValues.match(/{{|}}/g)) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    };

    const onHandleUpdateTemplate = async (): Promise<void> => {
        try {
            if (updatedTemplate && isMounted()) {
                const {
                    content,
                    record: { modelFields, templateLabel },
                } = updatedTemplate;

                const result = await EmailClient.updateTemplate(
                    authContext,
                    templateId,
                    setFormData(content, templateLabel, modelFields),
                );
                setOnUpdateTemplateMessage(UpdateTemplateMessages.SuccessMessage);
                props.onModifyTemplate(result);
            }
        } catch (e) {
            setOnUpdateTemplateMessage(UpdateTemplateMessages.FailedSubmissionError);
        } finally {
            setIsSavingTemplate(false);
        }
    };

    const onHandleCreateTemplate = async (): Promise<void> => {
        setIsSavingTemplate(true);
        try {
            if (isHtmlSanitary && templateLabelValue && isMounted()) {
                const createdTemplate = await EmailClient.createTemplate(
                    authContext,
                    setFormData(htmlBody, templateLabelValue, modelFields),
                );
                if (isMounted()) {
                    setOnUpdateTemplateMessage(UpdateTemplateMessages.SuccessMessage);
                    props.onModifyTemplate(createdTemplate, true);
                }
            } else {
                if (isMounted()) {
                    setOnUpdateTemplateMessage(UpdateTemplateMessages.ErrorMessage);
                }
            }
        } catch (e) {
            if (isMounted()) {
                setOnUpdateTemplateMessage(UpdateTemplateMessages.FailedSubmissionError);
            }
        } finally {
            if (isMounted()) {
                setIsSavingTemplate(false);
            }
        }
    };

    const {
        value: templateLabelValue,
        initialize: initTemplateLabel,
        theElement: theTemplateLabelElement,
    } = useTextField({
        label: 'Template Label',
        resizable: false,
        placeholder: 'Label is required',
    });

    const onHandleAddModelKey = (key: string): void => {
        setModelFields((currentValue) => [...currentValue, key]);
    };

    const onHandleDeleteModelKey = async (field: string): Promise<void> => {
        const updatedModelKeys = modelFields.filter((k) => k !== field);
        setModelFields(updatedModelKeys);
    };

    useEffect(() => {
        initTemplateLabel(template?.record.templateLabel);
        setHtmlBody(template?.content ?? '');
    }, [template]);

    useEffect(() => {
        if (isHtmlSanitary && templateLabelValue && template) {
            const { record } = template;

            const updatedTemplateObj = {
                content: htmlBody,
                record: { ...record, templateLabel: templateLabelValue, modelFields: modelFields },
            };

            setUpdatedTemplate(updatedTemplateObj);
        }
    }, [templateLabelValue, modelFields, isHtmlSanitary, htmlBody]);

    if (errorLoadingTemplateMessage) {
        return (
            <>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                <MessageBar
                    messageBarType={MessageBarType.error}
                    onDismiss={doNothing}
                    isMultiline={false}
                    dismissButtonAriaLabel='Close'>
                    {errorLoadingTemplateMessage}
                </MessageBar>
            </>
        );
    }
    return (
        <PageLoadingSpinner
            label='Loading email templates...'
            ariaLive='assertive'
            isLoading={isFetchingTemplate}
            labelPosition='left'>
            <Spacer marginTop={10} />
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            {onUpdateTemplateMessage && (
                <MessageBar
                    messageBarType={
                        onUpdateTemplateMessage === UpdateTemplateMessages.SuccessMessage
                            ? MessageBarType.success
                            : MessageBarType.error
                    }
                    onDismiss={(): void => setOnUpdateTemplateMessage('')}
                    isMultiline={false}
                    dismissButtonAriaLabel='Close'>
                    {onUpdateTemplateMessage}
                </MessageBar>
            )}
            <div className={styles.content}>
                <Spacer marginTop={20} />
                <ButtonBar>
                    <Link
                        to={(location: { pathname: string }) =>
                            `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}`
                        }>
                        <DefaultButton text='Back' className={styles.backButton} />
                    </Link>
                    <PrimaryButton
                        disabled={!htmlBody || !templateLabelValue || isSavingTemplate}
                        text='Save'
                        onClick={onHandleSaveTemplate}
                    />
                </ButtonBar>
                <Spacer marginTop={40} />
                {template?.record.id && (
                    <>
                        <BoldFont>Id: </BoldFont>
                        <span>{template?.record.id}</span>
                    </>
                )}
                <ModelKeysComponent
                    modelFields={modelFields}
                    handleAddModelKey={onHandleAddModelKey}
                    handleDeleteModelKey={onHandleDeleteModelKey}
                />

                <TemplateHTMLComponent
                    theTemplateLabelElement={theTemplateLabelElement}
                    setHtmlBody={setHtmlBody}
                    htmlBody={htmlBody}
                    cleanedHtmlBody={htmlBody}
                />
            </div>
        </PageLoadingSpinner>
    );
}

const styles = mergeStyleSets({
    content: {
        padding: 9,
    },
    addModel: {
        alignItems: 'flex-end',
    },
    modelKeyListWrapper: {
        paddingLeft: 0,
    },
    modelKeyListItem: {
        listStyleType: 'none',
        display: 'flex',
        alignItems: 'center',
    },
    cancelAddModelKeyButton: {
        position: 'relative',
        top: 5,
    },
    separatorStyle: {
        width: '50%',
    },
    backButton: {
        marginRight: 10,
    },
});
