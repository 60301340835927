import React, { useCallback, useRef, useState } from 'react';
import {
    Editor,
    EditorState,
    RichUtils,
    Modifier,
    DraftHandleValue,
    getDefaultKeyBinding,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import 'components/forms/rich-text-editor/rich-text-editor.css';
import { DefaultButton, Icon, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { ValueOf } from 'utils/object-utils';

export const dividerTag = '</divider>';
const inlineStyleActions = {
    link: 'link',
    column: 'DoubleColumn',
} as const;

interface RichTextEditorProps {
    editorState: EditorState;
    setEditorState: (es: EditorState) => void;
}

export function RichTextEditor(props: RichTextEditorProps): JSX.Element {
    const editor = useRef(null);
    const { editorState, setEditorState } = props;
    const [customUrl, setCustomUrl] = useState<string>('');
    const [isEditingUrl, setIsEditingUrl] = useState<boolean>(false);

    const BLOCK_TYPES = [
        { label: 'H2', style: 'header-two', icon: 'header1' },
        { label: 'H3', style: 'header-three', icon: 'header2' },
        { label: 'UL', style: 'unordered-list-item', icon: 'bulletedList' },
        { label: 'OL', style: 'ordered-list-item', icon: 'numberedList' },
    ];

    const INLINE_STYLES = [
        { label: 'Bold', style: 'BOLD', icon: 'bold' },
        { label: 'Italic', style: 'ITALIC', icon: 'italic' },
        { label: 'Underline', style: 'UNDERLINE', icon: 'underline' },
    ];

    const INLINE_ACTIONS = [
        { label: 'Hyperlink', icon: inlineStyleActions.link },
        { label: 'Column', icon: inlineStyleActions.column },
    ];

    const handleKeyCommand = (command: string, editorState: EditorState): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    interface IStyleParams {
        onToggle: any;
        active: any;
        label?: any;
        icon: any;
        style: any;
    }

    function StyleButton({ onToggle, active, icon, style }: IStyleParams): JSX.Element {
        let className = 'RichEditor-styleButton';
        if (active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span
                className={className}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onToggle(style);
                }}>
                <Icon iconName={icon} />
            </span>
        );
    }

    function ActionButton({ onToggle, icon }: any): JSX.Element {
        return (
            <span
                className={'RichEditor-styleButton'}
                style={{ cursor: 'pointer', marginRight: '.5rem' }}
                onMouseDown={(e): void => {
                    e.preventDefault();
                    onToggle(icon);
                }}>
                <Icon iconName={icon} />
            </span>
        );
    }

    interface IBlockParams {
        editorState: any;
        onToggle: any;
    }

    function BlockStyleControls({ editorState, onToggle }: IBlockParams): JSX.Element {
        const selection = editorState.getSelection();
        const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();

        return (
            <div className='RichEditor-controls'>
                {BLOCK_TYPES.map((type) => (
                    <StyleButton
                        key={type.label}
                        active={type.style === blockType}
                        icon={type.icon}
                        onToggle={onToggle}
                        style={type.style}
                    />
                ))}
            </div>
        );
    }
    const styleMap = {
        CODE: {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
            fontSize: 16,
            padding: 2,
        },
    };

    function getBlockStyle(block: { getType: () => any }): string {
        switch (block.getType()) {
            case 'blockquote':
                return 'RichEditor-blockquote';
            default:
                return '';
        }
    }

    function InlineControls({ editorState, onToggle }: IBlockParams): JSX.Element {
        const currentStyle = editorState.getCurrentInlineStyle();
        return (
            <div className='RichEditor-controls'>
                {INLINE_STYLES.map((type) => (
                    <StyleButton
                        key={type.label}
                        active={currentStyle.has(type.style)}
                        icon={type.icon}
                        onToggle={onToggle}
                        style={type.style}
                    />
                ))}
                {INLINE_ACTIONS.map((type) => (
                    <ActionButton key={type.label} icon={type.icon} onToggle={onToggle} />
                ))}
            </div>
        );
    }

    const mapKeyToEditorCommand = useCallback(
        (e: any) => {
            switch (e.keyCode) {
                case 9: // TAB
                    const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */);
                    if (newEditorState !== editorState) {
                        setEditorState(newEditorState);
                    }
                    return null;
            }
            return getDefaultKeyBinding(e);
        },
        [editorState, setEditorState],
    );

    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    const contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
        }
    }

    const createLink = (): void => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
            url: customUrl,
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
        });
        const newState = RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey,
        );
        setEditorState(newState);
        setCustomUrl('');
        setIsEditingUrl(false);
    };

    const createColumnDivider = (): void => {
        const stateWithNewLine = RichUtils.insertSoftNewline(editorState);
        const currentContent = stateWithNewLine.getCurrentContent();

        const newState = Modifier.insertText(
            currentContent,
            stateWithNewLine.getSelection(),
            dividerTag,
        );
        const newEditorState = EditorState.set(editorState, {
            currentContent: newState,
        });
        setEditorState(newEditorState);
    };

    return (
        <>
            <div className='RichEditor-root'>
                <Stack horizontal>
                    <BlockStyleControls
                        editorState={editorState}
                        onToggle={(blockType: string): void => {
                            const newState = RichUtils.toggleBlockType(editorState, blockType);
                            setEditorState(newState);
                        }}
                    />
                    <InlineControls
                        editorState={editorState}
                        onToggle={(inlineCmd: ValueOf<typeof inlineStyleActions>): void => {
                            if (inlineCmd === 'link') {
                                setIsEditingUrl(true);
                            } else if (inlineCmd === 'DoubleColumn') {
                                createColumnDivider();
                            } else {
                                const newState = RichUtils.toggleInlineStyle(
                                    editorState,
                                    inlineCmd,
                                );
                                setEditorState(newState);
                            }
                        }}
                    />
                </Stack>
                <div className={className} onClick={focus}>
                    <Editor
                        ariaLabel='Description'
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={handleKeyCommand}
                        keyBindingFn={mapKeyToEditorCommand}
                        onChange={setEditorState}
                        placeholder='Add description...'
                        ref={editor}
                        spellCheck={true}
                    />
                </div>
            </div>
            {isEditingUrl && (
                <Stack horizontal verticalAlign='end' tokens={{ childrenGap: '1rem' }}>
                    <Stack.Item>
                        <TextField
                            style={{ width: '400px' }}
                            label='URL'
                            value={customUrl}
                            onChange={(event, newValue): void => setCustomUrl(newValue ?? '')}
                            onKeyPress={(ev): void => {
                                if (ev.key === 'Enter') {
                                    createLink();
                                }
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <PrimaryButton onClick={createLink}>Create hyperlink</PrimaryButton>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton
                            onClick={(): void => {
                                setCustomUrl('');
                                setIsEditingUrl(false);
                            }}>
                            Cancel
                        </DefaultButton>
                    </Stack.Item>
                </Stack>
            )}
        </>
    );
}
