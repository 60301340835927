import {
    ActionButton,
    ColumnActionsMode,
    CommandBar,
    FluentTheme,
    ICommandBarItemProps,
    SelectionMode,
} from '@fluentui/react';
import { IconNames, xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import {
    CoreAttributeSetsClient,
    CoreAttributesClient,
    CorePrincipalsClient,
} from 'clients/core/personnel-core-client-wrappers';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { CheckScrollReachedBottom } from 'components/common/scroll-event-listener';
import Spacer from 'components/common/spacer';
import { TableCell } from 'components/common/table';
import AddEditCoreAssignmentPanelActionButton, {
    AssignmentModalState,
    AssignmentModalType,
} from 'components/core/common/add-edit-assignment';
import { CoreEmployeeHoverCardFromGraph } from 'components/core/common/employee-card/core-employee-hover-card';
import {
    FilterableTable,
    IFilter,
    IFilterableColumn,
} from 'components/core/common/filterableTable';
import { LastModifiedBy, TimeUnit } from 'components/core/common/last-modified-by';
import UserAssignmentsListFilterPanel from 'components/core/users/user-assignments-list-filter-panel';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import {
    OrderByType,
    PagedResultOfSearchByAssignmentResult,
    PrincipalSearchByAssignmentRequest,
    SearchByAssignmentResult,
    SortByType,
} from 'personnel-core-clients';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFetchSimple } from 'utils/misc-hooks';

export function UserAssignmentsList(): JSX.Element {
    const authContext = useContext(AuthContext);
    const principalUserContext = useContext(PrincipalUserContext);
    const principalsClient = new CorePrincipalsClient(authContext);

    const [assignmentRecords, setAssignmentRecords] = useState<SearchByAssignmentResult[]>([]);
    const [columns, setCols] = useState<IFilterableColumn[]>([]);
    const [shouldFetchAssignmentRecords, setShouldFetchAssignmentRecords] = useState<boolean>(true);
    const [isInitFetchAssignmentRecords, setIsInitFetchAssignmentRecords] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [continuationToken, setContinuationToken] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [openModalState, setOpenModalState] = useState<AssignmentModalState | undefined>();
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [filters, setFilters] = useState<Map<string, IFilter>>(new Map<string, IFilter>());
    const [isDisableAddAssignment, setIsDisableAddAssignment] = useState<boolean>(true);
    const [previousRequest, setPreviousRequest] = useState<PrincipalSearchByAssignmentRequest>();

    const columnsFilterable: IFilterableColumn[] = [
        {
            key: columnNames.Name,
            name: columnNames.Name,
            fieldName: SortByType.DisplayName, // used for sorting
            ariaLabel: columnNames.Name,
            minWidth: columnWidths.name,
            maxWidth: columnWidths.name * xLargeMaxWidthCoeff,
            isRowHeader: true,
            isResizable: true,
            isSorted: true,
            onRender: (item: SearchByAssignmentResult): JSX.Element => {
                return (
                    <TableCell key={`${item.principalId}-name`} style={detailsListFieldStyle}>
                        <CoreEmployeeHoverCardFromGraph
                            key={item.oid}
                            oid={item.oid}
                            displayName={item.displayName}
                            upn={item.upn}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: columnNames.AssignmentsCount,
            name: columnNames.AssignmentsCount,
            ariaLabel: columnNames.AssignmentsCount,
            minWidth: columnWidths.assignmentsCount,
            maxWidth: columnWidths.assignmentsCount * xLargeMaxWidthCoeff,
            isResizable: true,
            columnActionsMode: ColumnActionsMode.disabled,
            onRender: (item: SearchByAssignmentResult): JSX.Element => {
                return (
                    <TableCell
                        key={`${item.principalId}-assignments`}
                        style={detailsListFieldStyle}>
                        {item.assignmentCount}
                    </TableCell>
                );
            },
        },
        {
            key: columnNames.LastModifiedBy,
            name: columnNames.LastModifiedBy,
            fieldName: SortByType.RecordTimestamp, // used for sorting
            ariaLabel: columnNames.LastModifiedBy,
            minWidth: columnWidths.lastModifiedBy,
            maxWidth: columnWidths.lastModifiedBy * xLargeMaxWidthCoeff,
            isResizable: true,
            onRender: (item: SearchByAssignmentResult): JSX.Element => {
                return (
                    <TableCell
                        key={`${item.principalId}-lastModifiedBy`}
                        style={detailsListFieldStyle}>
                        <LastModifiedBy lastModified={item} timeUnit={TimeUnit.Seconds} />
                    </TableCell>
                );
            },
        },
        {
            key: columnNames.Action,
            name: columnNames.Action,
            ariaLabel: columnNames.Action,
            minWidth: columnWidths.action,
            maxWidth: columnWidths.action * xLargeMaxWidthCoeff,
            isResizable: true,
            columnActionsMode: ColumnActionsMode.disabled,
            onRender: (item: SearchByAssignmentResult): JSX.Element => {
                return (
                    <TableCell
                        key={`${item.principalId}-actions`}
                        style={{ display: 'flex', alignItems: 'center' }}>
                        <Link to={`/profile/attributes/user/${item.principalId}`}>
                            <ActionButton text={'View'} iconProps={{ iconName: IconNames.View }} />
                        </Link>
                    </TableCell>
                );
            },
        },
    ];

    const isAdmin = useMemo(() => {
        // This is temporary until we get a role for Core Admin in the portal app or figure out another way to do this
        return (
            principalUserContext.msalUser.roles.includes(Role.PortalAdmin) ||
            principalUserContext.msalUser.roles.includes(Role.CoreAttributeAdmin)
        );
    }, [principalUserContext.msalUser.roles]);

    useEffect(() => {
        const getIsDisableAddAssignment = async (): Promise<void> => {
            if (isAdmin) {
                setIsDisableAddAssignment(false);
                return;
            }

            const attributeSetsClient = new CoreAttributeSetsClient(authContext);
            const attributeSets = (await attributeSetsClient.getAll()).filter(
                (a) => a.isActive === true,
            );

            // check if user is an owner or manager for any attribute set
            const hasOwnerManagerAttributeSetIds = attributeSets.some(
                (a) =>
                    a.owners.includes(principalUserContext.principalRecord.oid ?? '') ||
                    a.managers.includes(principalUserContext.principalRecord.oid ?? ''),
            );

            if (hasOwnerManagerAttributeSetIds) {
                setIsDisableAddAssignment(false);
                return;
            }

            const attributesClient = new CoreAttributesClient(authContext);
            let attributesContinuationToken = '';
            do {
                const attributesResponse = await attributesClient.getAll(
                    100,
                    attributesContinuationToken,
                );
                if (attributesResponse.results) {
                    // check if user is an owner or manager for any attribute
                    const hasOwnerManagerAttributeIds = attributesResponse.results.some(
                        (a) =>
                            a.owners.includes(principalUserContext.principalRecord.oid ?? '') ||
                            a.managers.includes(principalUserContext.principalRecord.oid ?? ''),
                    );
                    if (hasOwnerManagerAttributeIds) {
                        setIsDisableAddAssignment(false);
                        return;
                    }
                }
                attributesContinuationToken = attributesResponse.continuationToken ?? '';
            } while (attributesContinuationToken);
        };

        getIsDisableAddAssignment();
    }, [authContext, isAdmin, principalUserContext.principalRecord.oid]);

    useFetchSimple<PagedResultOfSearchByAssignmentResult>({
        dependencies: [
            shouldFetchAssignmentRecords,
            isInitFetchAssignmentRecords,
            filters,
            columnsFilterable,
        ],
        canPerformFetch: shouldFetchAssignmentRecords,
        fetchFunc: async () => {
            setIsLoading(true);
            setShouldFetchAssignmentRecords(false);
            setErrorMessage(undefined);

            let request = new PrincipalSearchByAssignmentRequest();
            if (isInitFetchAssignmentRecords) {
                // set filter values to request
                request.attributeSetId = filters.get('attributeset')?.values[0];
                request.attributeId = filters.get('attribute')?.values[0];
                request.lastModifiedBy = filters.get('modifiedby')?.values[0];
                if (filters.has('lastmodified')) {
                    const filterLastModifiedAfter = filters.get('lastmodified')?.values[0];
                    const filterLastModifiedBefore = filters.get('lastmodified')?.values[1];

                    if (filterLastModifiedAfter) {
                        request.lastModifiedAfter = new Date(filterLastModifiedAfter);
                    }
                    if (filterLastModifiedBefore) {
                        request.lastModifiedBefore = new Date(filterLastModifiedBefore);
                    }
                }

                // set init sort to request
                request.sortBy = SortByType.DisplayName;
                request.sortDirection = OrderByType.Asc;
            } else {
                request = previousRequest ?? new PrincipalSearchByAssignmentRequest();
                request.continuationToken = continuationToken;
            }
            setPreviousRequest(request);
            return await principalsClient.searchByAssignment(request);
        },
        onSuccess: (response) => {
            if (isInitFetchAssignmentRecords) {
                setIsInitFetchAssignmentRecords(false);
                setCols(columnsFilterable);
                setAssignmentRecords(response.results ?? []);
            } else {
                setAssignmentRecords((currentAssignmentRecords) =>
                    currentAssignmentRecords.concat(response.results ?? []),
                );
            }
            setContinuationToken(response.continuationToken);
        },
        onError: () => {
            setErrorMessage('An error occurred retrieving user assignment records.');
        },
        onFinally: () => {
            setIsLoading(false);
        },
    });

    const onColumnHeaderClick = useCallback(
        async (ev?: React.MouseEvent<HTMLElement, MouseEvent>, column?: IFilterableColumn) => {
            if (!column) {
                return;
            }

            const columnCopy: IFilterableColumn[] = columns.slice();
            const clickedColumn: IFilterableColumn = columnCopy.filter(
                (currCol) => column.key === currCol.key,
            )[0];

            try {
                setAssignmentRecords([]);
                setIsLoading(true);

                //build request
                const request = new PrincipalSearchByAssignmentRequest();

                // set filter values to request
                request.attributeId = filters.get('attribute')?.values[0];
                request.lastModifiedBy = filters.get('modifiedby')?.values[0];
                if (filters.has('lastmodified')) {
                    const filterLastModifiedAfter = filters.get('lastmodified')?.values[0];
                    const filterLastModifiedBefore = filters.get('lastmodified')?.values[1];

                    if (filterLastModifiedAfter) {
                        request.lastModifiedAfter = new Date(filterLastModifiedAfter);
                    }
                    if (filterLastModifiedBefore) {
                        request.lastModifiedBefore = new Date(filterLastModifiedBefore);
                    }
                }
                request.sortBy = SortByType[clickedColumn.fieldName as keyof typeof SortByType];
                request.sortDirection = !clickedColumn.isSortedDescending
                    ? OrderByType.Desc
                    : OrderByType.Asc;

                setPreviousRequest(request);

                // get response
                const response = await principalsClient.searchByAssignment(request);

                setAssignmentRecords(response.results ?? []);
                setContinuationToken(response.continuationToken);
                setErrorMessage(undefined);
            } catch (error) {
                // if error, set error message and do not update table/columns
                setErrorMessage('An error occurred retrieving user assignment records.');
                return;
            } finally {
                setIsLoading(false);
            }

            columnCopy.forEach((col: IFilterableColumn) => {
                if (col === clickedColumn) {
                    clickedColumn.isSortedDescending = !clickedColumn.isSortedDescending;
                    clickedColumn.isSorted = true;
                } else {
                    col.isSortedDescending = true;
                    col.isSorted = false;
                }
            });

            setCols(columnCopy);
        },
        [columns],
    );

    const commandBarLeftItems: ICommandBarItemProps[] = useMemo(
        (): ICommandBarItemProps[] => [
            {
                key: 'add',
                text: 'Add Assignment',
                onClick: (): void => {
                    setOpenModalState(AssignmentModalState.Add);
                },
                iconProps: { iconName: IconNames.Add },
                disabled: isDisableAddAssignment,
            },
        ],
        [isDisableAddAssignment],
    );

    const commandBarRightItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        return [
            {
                key: 'filter',
                text: 'Filter',
                onClick: () => setIsFilterOpen(true),
                iconProps: { iconName: IconNames.Filter },
            },
        ];
    }, []);

    const createOrUpdateAssignments = (): void => {
        setShouldFetchAssignmentRecords(true);
        setIsInitFetchAssignmentRecords(true);
    };

    function onScrollReachedBottom(): void {
        if (!!continuationToken && !isLoading && !errorMessage) {
            setShouldFetchAssignmentRecords(true);
        }
    }

    return (
        <>
            {errorMessage && (
                <span style={{ color: FluentTheme.semanticColors.errorText, fontSize: 12 }}>
                    {errorMessage}
                </span>
            )}
            <AddEditCoreAssignmentPanelActionButton
                isOpen={openModalState !== undefined}
                modalState={openModalState ?? AssignmentModalState.Add}
                modalType={AssignmentModalType.Attribute}
                onDismiss={(): void => {
                    setOpenModalState(undefined);
                }}
                onCreateOrUpdate={createOrUpdateAssignments}
            />
            <div>
                <CommandBar
                    styles={{
                        root: {
                            paddingTop: 35,
                            paddingLeft: 0,
                            background: globalStyles.backgroundColor,
                        },
                    }}
                    items={commandBarLeftItems}
                    farItems={commandBarRightItems}
                />
                <FilterableTable
                    columns={columns}
                    filters={filters}
                    items={assignmentRecords}
                    selectionMode={SelectionMode.none}
                    onColumnHeaderClick={onColumnHeaderClick}
                />
                <IsLoadingIndicator
                    isLoading={isLoading}
                    before={<Spacer marginTop={20} />}
                    msg='Loading user assignments...'
                />
            </div>
            <CheckScrollReachedBottom
                shouldCheck={!!continuationToken} // only check if there is a continuation token
                onScrollReachedBottom={onScrollReachedBottom}
            />
            <UserAssignmentsListFilterPanel
                isOpen={isFilterOpen}
                onDismiss={(): void => {
                    setIsFilterOpen(false);
                }}
                onSuccess={(selectedfilters): void => {
                    setFilters(selectedfilters);
                    setIsInitFetchAssignmentRecords(true);
                    setShouldFetchAssignmentRecords(true);
                    setAssignmentRecords([]);
                    setIsFilterOpen(false);
                }}
                onClearFilters={(): void => {
                    setFilters(new Map<string, IFilter>());
                }}
                onMinimizePanel={(): void => {
                    setIsFilterOpen(false);
                }}
            />
        </>
    );
}

const detailsListFieldStyle = {
    display: 'flex',
    alignItems: 'left',
};

const columnWidths = {
    name: 325,
    assignmentsCount: 150,
    lastModifiedBy: 325,
    action: 75,
};

enum columnNames {
    Name = 'Name',
    AssignmentsCount = 'Assignments count',
    LastModifiedBy = 'Last modified by',
    Action = 'Action',
}
