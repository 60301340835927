import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link, mergeStyleSets, MessageBar, MessageBarType } from '@fluentui/react';
import BoldFont from 'components/common/misc/bold-font';

export default function MyOrgSevereWarningMessage(): JSX.Element {
    const nstLink = `mailto:myclearance@microsoft.com`;
    const citizenVerificationLink = `https://personnel.microsoft.com/forms/citizenship-verification/`;
    const approvedDataConsumersLink = `https://aka.ms/personnel/NSTapproveddataconsumers`;
    const myGroupsLink = `https://aka.ms/personnel/groups`;

    const styles = mergeStyleSets({
        linkContainer: {
            marginBottom: '20px',
        },
    });

    return (
        <MessageBar messageBarType={MessageBarType.severeWarning} className={styles.linkContainer}>
            <BoldFont>PERMISSIONS NEEDED!</BoldFont> You are attempting to access a page which you
            have not been vetted for. To be considered for access complete the following:
            <ul>
                <li>Ensure you have a defined business justification to access this data</li>
                <li>
                    Verify you are a
                    <Link
                        href={citizenVerificationLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        underline>
                        US citizen in Microsoft Personnel
                    </Link>
                </li>
                <li>
                    Request access to the
                    <Link
                        href={approvedDataConsumersLink}
                        target='_blank'
                        rel='noopener noreferrer'
                        underline>
                        NST Approved Data Consumers for Microsoft Personnel
                    </Link>{' '}
                    group
                </li>
                <li>
                    If approved, confirm compliance to the NST Approved Data Consumers for Microsoft
                    Personnel group in
                    <Link href={myGroupsLink} target='_blank' rel='noopener noreferrer' underline>
                        My groups
                    </Link>{' '}
                </li>
            </ul>
            <BoldFont>Questions?</BoldFont> Contact the
            <Link href={nstLink} target='_blank' rel='noopener noreferrer' underline>
                National Security team (NST)
            </Link>{' '}
            for support
        </MessageBar>
    );
}
