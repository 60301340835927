import { Checkbox, ChoiceGroup, ComboBox } from '@fluentui/react';
import React, { memo } from 'react';
import { ElementProps } from 'components/forms/element-viewer';
import { ChoiceOptions, FormElement, FormValueTypes } from 'components/forms/forms-common';

type ChoiceViewerProps = ElementProps & {
    updateFormResponse: (element: FormElement, answer: string | string[]) => void;
};
function ChoiceViewer(props: ChoiceViewerProps): JSX.Element {
    const { element, updateFormResponse } = props;
    const choiceOptions = (element.options ?? []) as ChoiceOptions[];

    const returnNonCheckboxSelection = (): string | null => {
        const selectedKey =
            choiceOptions.find((option) => option.text === element.value)?.key ?? null;
        return selectedKey;
    };

    const isStringArray = (val: FormValueTypes | undefined): val is string[] => {
        if (!Array.isArray(val) || val.hasOwnProperty('text')) {
            return false;
        }
        return true;
    };

    const isCheckedOption = (val: string): boolean => {
        if (isStringArray(element.value)) {
            return element.value.some((e) => e === val);
        }
        return false;
    };

    const changeCheckboxValues = (option: ChoiceOptions, checked?: boolean): void => {
        const existingValues = element.value ? (element.value as string[]) : [];
        if (!checked) {
            updateFormResponse(
                element,
                existingValues.filter((e) => e !== option.text),
            );
        } else {
            updateFormResponse(element, [...existingValues, option.text]);
        }
    };

    let choiceElement = <></>;
    if (element.type === 'radio') {
        choiceElement = (
            <ChoiceGroup
                selectedKey={returnNonCheckboxSelection()}
                options={element.options}
                onChange={(ev, selectedOption): void => {
                    if (!selectedOption) {
                        return;
                    }
                    updateFormResponse(element, selectedOption.text);
                }}
            />
        );
    } else if (element.type === 'dropdown') {
        choiceElement = (
            <ComboBox
                ariaLabel={element.label ?? 'ComboBox'}
                styles={{
                    container: { width: 300 },
                }}
                comboBoxOptionStyles={{
                    optionTextWrapper: { overflow: 'visible', whiteSpace: 'normal' },
                    optionText: { height: 'auto' },
                }}
                placeholder='Select an option'
                options={element.options ?? []}
                onChange={(ev, selectedOption): void => {
                    if (!selectedOption) {
                        return;
                    }
                    updateFormResponse(element, selectedOption.text);
                }}
            />
        );
    } else {
        choiceElement = (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {element.options?.map((option) => (
                    <Checkbox
                        checked={isCheckedOption(option.text)}
                        key={option.key}
                        label={option.text}
                        onChange={(ev, checked): void => {
                            changeCheckboxValues(option, checked);
                        }}
                    />
                ))}
            </div>
        );
    }
    return <div style={{ marginTop: '-1rem' }}>{choiceElement}</div>;
}

export default memo(ChoiceViewer);
