import React, { useCallback, useMemo } from 'react';
import CoreEmployeeCard, {
    ICoreEmployeeCardProps,
    ICoreEmployeeCardStyle,
} from 'components/core/common/employee-card/core-employee-card';
import { HoverCard, HoverCardType, KeyCodes, mergeStyleSets, PersonaSize } from '@fluentui/react';
import ICardPrincipal from 'components/core/common/employee-card/card-principal';
import CoreEmployeeMiniCard from 'components/core/common/employee-card/core-employee-mini-card';
import CardPrincipalProviderFromGraph from 'components/core/common/employee-card/card-principal-provider-from-graph';
import CardPrincipalProviderFromPrincipalId from 'components/core/common/employee-card/card-principal-provider-from-principal-id';

export interface ICoreEmployeeHoverCardProps {
    cardPrincipal?: ICardPrincipal;
    // To prevent showing actions on the the employee card,
    // pass a false to this prop.
    showActions?: boolean; // Default: true
    // If you need to pass additional styles to component
    // EmployeeCard, you can use the following.
    // As of this writing (June, 16, 21), it's only used when
    // noHoverCard is also true. In other words, at this time it
    // only affects instance of EmployeeCard when showing the
    // card permanently, not only during a mouse hover.
    employeeCardStyle?: ICoreEmployeeCardStyle;
    employeeCardProps?: ICoreEmployeeCardProps;
    // If you want the card NOT to show employee alias on the mini
    // card, pass a boolean "false" to the following prop.
    showMiniCardAlias?: boolean; // Default: true
    // If you want to display a default mini card when no employee data is available.
    // Useful for employees that no longer exist in employee database
    showDefaultMiniCard?: boolean; // Default: false
    // If you want to display a default display name.
    // Useful placeholder when data is loading or no employee record exists but you still have a valid
    // placeholder for the display name. Used in conjunction with showDefaultMiniCard
    defaultDisplayName?: string;
    defaultUpn?: string;
    showFullName?: boolean; // Default: true
    className?: string;
}

export default function CoreEmployeeHoverCard(props: ICoreEmployeeHoverCardProps): JSX.Element {
    const {
        showActions = true,
        showMiniCardAlias = true,
        defaultDisplayName = props.defaultDisplayName || '',
        showDefaultMiniCard = defaultDisplayName !== '',
        defaultUpn = props.defaultUpn || '',
        showFullName = true,
    } = props;

    const defaultCardPrincipal = useMemo(
        () =>
            showDefaultMiniCard
                ? {
                      displayName: defaultDisplayName,
                      upn: defaultUpn,
                  }
                : undefined,
        [defaultDisplayName, showDefaultMiniCard],
    );

    const canCardPrincipalShowMiniCard = !!props.cardPrincipal?.displayName;

    const cardPrincipal = canCardPrincipalShowMiniCard ? props.cardPrincipal : defaultCardPrincipal;
    const isHoverEnabled = props.cardPrincipal !== undefined;

    const onRenderPlainCard = useCallback((cardProps?: ICoreEmployeeCardProps): JSX.Element => {
        return <CoreEmployeeCard {...cardProps} />;
    }, []);

    const plainCardProps = useMemo(
        () => ({
            onRenderPlainCard: onRenderPlainCard,
            renderData: {
                cardPrincipal: cardPrincipal,
                showActions: showActions,
            } as ICoreEmployeeCardProps,
        }),
        [onRenderPlainCard, props.cardPrincipal, showActions],
    );

    const inlineStyle = mergeStyleSets({
        host: {
            display: 'inline-block',
            whiteSpace: 'normal',
            maxWidth: '100%',
        },
    });

    if (cardPrincipal === undefined) {
        return <></>;
    }

    const miniCard = (
        <CoreEmployeeMiniCard
            imageSize={PersonaSize.size24}
            showAlias={showMiniCardAlias}
            showFullName={showFullName}
            showImage={true}
            cardPrincipal={cardPrincipal}
        />
    );

    if (!isHoverEnabled) {
        return miniCard;
    } else {
        return (
            <HoverCard
                styles={inlineStyle}
                plainCardProps={plainCardProps}
                instantOpenOnClick={true}
                cardOpenDelay={300}
                expandedCardOpenDelay={3000}
                type={HoverCardType.plain}
                trapFocus
                setInitialFocus
                openHotKey={KeyCodes.enter}
                className={props.className}>
                {miniCard}
            </HoverCard>
        );
    }
}

export interface ICoreEmployeeHoverCardFromGraphProps extends ICoreEmployeeHoverCardProps {
    oid?: string;
    displayName?: string;
    upn?: string;
    onCardPrincipalProvided?: (data: ICardPrincipal | undefined) => void;
}

export function CoreEmployeeHoverCardFromGraph(
    props: ICoreEmployeeHoverCardFromGraphProps,
): JSX.Element {
    const onRender = useCallback(
        (cardPrincipal?: ICardPrincipal): JSX.Element => {
            return (
                <CoreEmployeeHoverCard
                    {...props}
                    cardPrincipal={cardPrincipal}
                    defaultDisplayName={props.displayName}
                    defaultUpn={props.upn}
                />
            );
        },
        [props],
    );

    return <CardPrincipalProviderFromGraph {...props} onRender={onRender} />;
}

export interface ICoreEmployeeHoverCardFromPrincipalIdProps extends ICoreEmployeeHoverCardProps {
    principalId?: string;
    displayName?: string;
    onCardPrincipalProvided?: (data: ICardPrincipal | undefined) => void;
}

export function CoreEmployeeHoverCardFromPrincipalId(
    props: ICoreEmployeeHoverCardFromPrincipalIdProps,
): JSX.Element {
    const onRender = useCallback(
        (cardPrincipal?: ICardPrincipal): JSX.Element => {
            return (
                <CoreEmployeeHoverCard
                    {...props}
                    cardPrincipal={cardPrincipal}
                    defaultDisplayName={props.displayName}
                />
            );
        },
        [props],
    );

    return <CardPrincipalProviderFromPrincipalId {...props} onRender={onRender} />;
}
