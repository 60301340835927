import {
    IDropdownOption,
    mergeStyleSets,
    Separator,
    Stack,
    DatePicker,
    PrimaryButton,
    Dropdown,
} from '@fluentui/react';
import React, { FormEvent, useContext, useEffect, useMemo, useState } from 'react';
import {
    TimeFormats,
    getLocalTimeZone,
    getOffsetInHoursFromTimeZoneToTimeZoneString,
    isLocalTimeZone,
    timeZoneAbbr,
} from 'utils/time-utils';
import FacilitiesClient, { IFacilityRecord, ReservationView } from 'clients/facilities-client';
import SecureWorkAreaDropdownSelector from 'components/facilities/common/secure-work-area-dropdown-selector';
import { DefaultTimeZone } from 'components/facilities/common/facility-time-utils';
import BoldFont from 'components/common/misc/bold-font';
import moment, { Moment } from 'moment';
import { IconNames } from 'assets/constants/global-constants';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { generateRandomKey } from 'utils/misc-utils';
import { globalSeparatorStyles } from 'assets/styles/global-styles';

export interface ITimeRange {
    startTime: Moment;
    endTime: Moment;
}

export interface FacilitiesSearchProps {
    facilities: IFacilityRecord[];
    selectedFacility?: IFacilityRecord;
    selectedDate: Date | undefined; // In facility timezone
    selectedTimeRange: ITimeRange | undefined; // In facility timezone
    updateSelectedFacility: (facility: IFacilityRecord) => void;
    updateSelectedDate: (date: Date) => void;
    updateSelectedTimeRange: (timeRange: ITimeRange | undefined) => void;
    reservationView: ReservationView;
    setIsReserveButtonClickedProp?: (isClicked: boolean) => void;
    isReserveButtonEnabled?: boolean;
}

function createDropdownOptionKeyForTimeRange(
    timeRange: ITimeRange | undefined,
): string | undefined {
    if (timeRange === undefined) {
        return undefined;
    }

    // eslint-disable-next-line prettier/prettier
    return `${timeRange.startTime.format('HH:mm')}.${timeRange.endTime.format('HH:mm')}`;
}

function createDropdownOptionForTimeRange(timeRange: ITimeRange): IDropdownOption<ITimeRange> {
    return {
        key: createDropdownOptionKeyForTimeRange(timeRange),
        // eslint-disable-next-line prettier/prettier
        text: `${timeRange.startTime.format(TimeFormats.H_mmA)} - ${timeRange.endTime.format(TimeFormats.H_mmA)}`,
        data: timeRange,
    } as IDropdownOption;
}

export default function FacilitiesSearch(props: FacilitiesSearchProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [timeSlotOptions, setTimeSlotOptions] = useState<IDropdownOption<ITimeRange>[]>([]);

    async function facilityChange(
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption | undefined,
        index?: number | undefined,
    ): Promise<void> {
        if (!props.facilities) {
            return;
        }

        if (option) {
            const newSelectedFacility = props.facilities.find((x) => x.id === option.key);
            if (newSelectedFacility) {
                props.updateSelectedFacility(newSelectedFacility);
            }
        } else if (index && index > -1 && index < props.facilities.length) {
            const newSelectedFacility = props.facilities[index];
            props.updateSelectedFacility(newSelectedFacility);
        }
    }

    const { selectedFacility, selectedTimeRange, updateSelectedTimeRange } = props;

    const minDate = useMemo(() => {
        const facilityCloseTime = selectedFacility?.facilityReservationInfo.timeSlotEndHours ?? 24;
        const timezone = selectedFacility?.timeZone ?? getLocalTimeZone();
        const currentTimeInFacilityTime = moment.tz(timezone);

        if (
            currentTimeInFacilityTime.date() !== new Date().getDate() ||
            currentTimeInFacilityTime.hour() >= facilityCloseTime
        ) {
            return new Date(
                currentTimeInFacilityTime.year(),
                currentTimeInFacilityTime.month(),
                currentTimeInFacilityTime.date(),
            );
        }
        return new Date();
    }, [selectedFacility]);

    const onSelectDate = React.useCallback(
        // focus on selectingCorrect Date
        (selectedDate: Date | null | undefined): void => {
            if (selectedDate) {
                props.updateSelectedDate(selectedDate);
            }
        },
        [props],
    );

    useEffect(() => {
        if (props.selectedDate === undefined || minDate > props.selectedDate) {
            props.updateSelectedDate(minDate);
        }
    }, [props.selectedDate, minDate, props]);

    useEffect(() => {
        if (props.selectedDate && selectedFacility && authContext && userContext) {
            setIsTimeSlotDisabled(true);
            const startTime = moment(props.selectedDate)
                .tz(selectedFacility.timeZone)
                .startOf('days');
            const endTime = moment(props.selectedDate)
                .tz(selectedFacility.timeZone)
                .set('hours', 23)
                .set('minutes', 59);
            FacilitiesClient.getFacilityTimeslots(
                authContext,
                userContext,
                selectedFacility.id,
                startTime,
                endTime,
            )
                .then((response) => {
                    const newTimeSlotOptions: IDropdownOption<ITimeRange>[] = [];
                    response.timeslots.map((x) => {
                        x.timeslotItems.map((y) => {
                            if (y.endDateTimeUTCMilliseconds > new Date().getTime()) {
                                newTimeSlotOptions.push(
                                    createDropdownOptionForTimeRange({
                                        startTime: moment.tz(
                                            y.startDateTimeUTCMilliseconds,
                                            selectedFacility.timeZone,
                                        ),
                                        endTime: moment.tz(
                                            y.endDateTimeUTCMilliseconds,
                                            selectedFacility.timeZone,
                                        ),
                                    }),
                                );
                            }
                        });
                    });
                    newTimeSlotOptions[0].selected = true;
                    setSelectedTimeSlotChange(newTimeSlotOptions[0].data);
                    setTimeSlotOptions(newTimeSlotOptions);
                })
                .finally(() => {
                    setIsTimeSlotDisabled(false);
                });
        }
    }, [authContext, props.selectedDate, selectedFacility, userContext]);

    useEffect(() => {
        const selectedKey = createDropdownOptionKeyForTimeRange(selectedTimeRange);

        const sameTimeslot = timeSlotOptions.find((option) => option.key === selectedKey);

        updateSelectedTimeRange(
            sameTimeslot?.data ??
                (timeSlotOptions.length >= 1 ? timeSlotOptions[0].data : undefined),
        );
    }, [selectedTimeRange, timeSlotOptions, updateSelectedTimeRange]);

    function localTimeZone(): string {
        const timeZone = getLocalTimeZone();
        return timeZoneAbbr(timeZone, selectedTimeRange?.startTime?.toDate());
    }

    const [selectedTimeSlotChange, setSelectedTimeSlotChange] = useState<ITimeRange | undefined>();
    const [isTimeSlotDisabled, setIsTimeSlotDisabled] = useState<boolean>(true);

    useEffect(() => {
        props.updateSelectedTimeRange(selectedTimeSlotChange);
    }, [props, selectedTimeSlotChange]);

    const timeSlots = useMemo((): JSX.Element => {
        function timeSlotChange(
            event: React.FormEvent<HTMLDivElement>,
            option?: IDropdownOption<ITimeRange>,
        ): void {
            setSelectedTimeSlotChange(option?.data);
        }

        return (
            <>
                <Dropdown
                    key={`${generateRandomKey()}`}
                    onChange={timeSlotChange}
                    placeholder={'Select time slot'}
                    options={timeSlotOptions}
                    ariaLabel='Timeslot'
                    disabled={isTimeSlotDisabled}
                />
            </>
        );
    }, [timeSlotOptions, isTimeSlotDisabled]);

    return (
        <Stack>
            <Stack.Item>
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Time Zone
                </Separator>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal horizontalAlign={'space-evenly'}>
                    <Stack.Item>
                        <BoldFont>Local: </BoldFont>
                        {localTimeZone()}
                    </Stack.Item>
                    <Stack.Item>
                        <BoldFont>SWA: </BoldFont>
                        {timeZoneAbbr(
                            selectedFacility?.timeZone ?? DefaultTimeZone,
                            props.selectedDate,
                        )}
                    </Stack.Item>
                    {!isLocalTimeZone(selectedFacility?.timeZone ?? DefaultTimeZone) && (
                        <Stack.Item>
                            <BoldFont>Offset: </BoldFont>
                            {`${getOffsetInHoursFromTimeZoneToTimeZoneString(
                                getLocalTimeZone(),
                                selectedFacility?.timeZone ?? DefaultTimeZone,
                            )}h`}
                        </Stack.Item>
                    )}
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <SecureWorkAreaDropdownSelector
                    onChange={facilityChange}
                    placeholder='Select a facility'
                    selectedKey={selectedFacility?.id ?? ''}
                    options={props.facilities?.map((x) => {
                        return {
                            key: x.id,
                            text: x.facilityName,
                        };
                    })}
                />
            </Stack.Item>
            <Stack.Item>
                <Separator styles={globalSeparatorStyles} alignContent='start'>
                    Date
                </Separator>
            </Stack.Item>
            <Stack.Item>
                <DatePicker
                    key={`${selectedFacility?.id}`}
                    placeholder='Select Date'
                    value={props.selectedDate}
                    onSelectDate={onSelectDate}
                    minDate={minDate}
                    disabled={!selectedFacility}
                    ariaLabel='Select Date'
                />
            </Stack.Item>
            {props.reservationView !== ReservationView.Calendar && (
                <Stack.Item>
                    <Separator styles={separatorStyles} alignContent='start'>
                        Time Slot{' '}
                        {props.selectedFacility?.timeZone && (
                            <>
                                {' '}
                                {'('}
                                {timeZoneAbbr(
                                    selectedFacility?.timeZone ?? DefaultTimeZone,
                                    props.selectedDate,
                                )}
                                {')'}
                            </>
                        )}
                    </Separator>
                    <Stack.Item>{timeSlots}</Stack.Item>
                </Stack.Item>
            )}

            {props.reservationView === ReservationView.Calendar && (
                <Stack.Item>
                    <Separator alignContent='start'></Separator>
                    <Stack>
                        <Stack.Item style={{ padding: 5 }}>
                            <PrimaryButton
                                text='Reserve'
                                iconProps={{ iconName: IconNames.AirTickets }}
                                onClick={(): void => {
                                    if (props.setIsReserveButtonClickedProp) {
                                        return props.setIsReserveButtonClickedProp(true);
                                    }
                                }}
                                disabled={!props.isReserveButtonEnabled}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
}

const separatorStyles = mergeStyleSets({
    root: {
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                opacity: 0.9,
            },
        },
    },
    content: {
        paddingLeft: 0,
        fontWeight: '500',
    },
});
