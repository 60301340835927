import {
    IPersonaProps,
    Label,
    ListPeoplePicker,
    mergeStyles,
    MessageBarType,
    PrimaryButton,
    Spinner,
    Toggle,
    TextField,
    ActionButton,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import EmployeeClient from 'clients/employee-client';
import FormsClient from 'clients/forms-client';
import { employeePickerSuggestionProps } from 'components/common/employee-list-picker';
import { useDebounceGetEmployeePersonas } from 'components/common/employee/internal-employee-utils';
import LabelInfoIcon from 'components/common/use-input/info-icon-label';
import useMessageBar from 'components/common/use-message-bar';
import { AuthContext } from 'contexts/auth-context';
import { useHistory } from 'react-router-dom';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
    transformBasicEmployeeToPersona,
    transformEmailToPersona,
} from 'utils/internal-persona-utils';
import { Form } from 'components/forms/forms-common';
import { elementContainerStyles } from 'components/forms/forms-viewer';
import { globalStyles } from 'assets/styles/global-styles';
import { AccessControlSettings } from 'components/forms/settings/access-control-settings';
import { ImageSettings } from 'components/forms/settings/image-settings';
import { DeleteModalButton } from 'components/forms/settings/delete-modal-button';
import { SubtitleSettings } from 'components/forms/settings/subtitle-settings';

const gridStyling = mergeStyles(elementContainerStyles, globalStyles.boxShadow, {
    top: '2rem',
    display: 'grid',
    marginLeft: 'auto',
    marginRight: 'auto',
    gridTemplateColumns: '1fr 2fr',
    gridAutoRows: 'auto',
    gridRowGap: '4rem',
    gridColumnGap: '4rem',
    gridTemplateAreas: `
      'subtitleLabel subtitleValue'
      'ownersLabel ownersValue'
      'labelNotification AddByEmploy'
      'accessLabel accessValue'
      'imageLabel imageVal'
      'publishLabel value3'
      'lockLabel value4'
      `,
});

const disabledGridStyling = mergeStyles(gridStyling, {
    pointerEvents: 'none',
    opacity: 0.6,
});

export type UpdatableSettings = Pick<
    Form,
    | 'owners'
    | 'image'
    | 'accessControlAttributes'
    | 'accessControlEligibilities'
    | 'accessControlEmployeeProperties'
    | 'notificationList'
    | 'isPublished'
    | 'subtitle'
    | 'isLocked'
>;

const disallowedCharacters = ['!', '%', '#', '$', '&', '~'];
type FormsEditSettingsProps = {
    form: Form;
    setForm: React.Dispatch<React.SetStateAction<Form>>;
};

export function FormsEditSettings(props: FormsEditSettingsProps): JSX.Element {
    const { form, setForm } = props;

    const authContext = useContext(AuthContext);
    const history = useHistory();

    const [owners, setOwners] = useState<IPersonaProps[]>([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [notificationList, setNotificationList] = useState<IPersonaProps[]>([]);
    const [isOwnersLoading, setOwnersLoading] = useState<boolean>(true);
    const [notificationEmail, setNotificationEmail] = useState<string>('');
    const [hasAccessControl, setHasAccessControl] = useState<boolean>(
        form.accessControlAttributes?.length !== 0 ||
            form.accessControlEligibilities?.length !== 0 ||
            form.accessControlEmployeeProperties?.length !== 0,
    );

    const [emailErrorText, setEmailErrorText] = useState<string>('');

    const { theElement: errorElement, setMessage: setErrorMessage } = useMessageBar({
        type: MessageBarType.error,
    });

    const deleteErrorMessageBar = useMessageBar({
        type: MessageBarType.error,
    });
    const deleteSuccessMessageBar = useMessageBar({
        type: MessageBarType.success,
    });

    const isModalUnclosable = useMemo(
        () => isDeleting || deleteSuccessMessageBar.theMessage !== undefined,
        [deleteSuccessMessageBar.theMessage, isDeleting],
    );

    const notificationContainerStyles = mergeStyles({
        display: 'flex',
        gap: '1rem',
        '@media(max-width: 50rem)': {
            flexWrap: 'wrap',
        },
    });

    useEffect(() => {
        const fetchOwners = async (): Promise<void> => {
            // emails will be the userPrincipalName of the basic Employee
            // EX:"mail": "Jonathan.Lavering@microsoft.com",
            //    "userPrincipalName": "jonlave@microsoft.com", <=== we use this one
            const ownerAliases = form.owners?.map((email) => email.split('@')[0]) ?? [];
            try {
                const employees = await EmployeeClient.getBasicEmployeesByAlias(
                    authContext,
                    ownerAliases,
                );

                const ownersAsPersonas = employees.map((e) => {
                    return transformBasicEmployeeToPersona(e, e.userPrincipalName);
                });
                setOwners(ownersAsPersonas);
                setOwnersLoading(false);
            } catch (e) {
                setErrorMessage('Error loading owners. Please refresh to try again.');
                console.error('Error fetching owners: ', e);
            }
        };
        fetchOwners();

        const convertedNotificationList =
            form.notificationList?.map((email) => transformEmailToPersona(email)) ?? [];
        setNotificationList(convertedNotificationList);
    }, []);

    const saveForm = async (updatedForm: Form): Promise<void> => {
        try {
            await FormsClient.UpdateGenericForm(authContext, updatedForm);
        } catch (e) {
            console.error('error in saveForm:', e);
            setErrorMessage('Error saving form. Please refresh to try again.');
            throw e;
        }
    };

    const updateAndSaveForm = async (
        property: keyof UpdatableSettings,
        value: string | string[] | boolean | string[][],
    ): Promise<void> => {
        try {
            const newForm = { ...form, [property]: value };
            await saveForm(newForm);
            setForm(newForm);
        } catch (e) {
            console.error('error: ', e);
            throw e;
        }
    };

    const clearAccessControl = (): void => {
        setForm((prev) => {
            const newForm: Form = {
                ...prev,
                accessControlAttributes: [],
                accessControlEligibilities: [],
                accessControlEmployeeProperties: [],
            };
            saveForm(newForm);
            return newForm;
        });
    };

    const deleteForm = async (shouldDelete: boolean): Promise<void> => {
        const verb = shouldDelete ? 'delete' : 'restore';
        try {
            setIsDeleting(true);
            deleteErrorMessageBar.clearMessage();
            await FormsClient.ToggleSoftDeleteGenericForm(authContext, form.name, shouldDelete);
            deleteSuccessMessageBar.setMessage(`Successfully ${verb}d form ${form.name}.`);
            setTimeout(() => {
                //redirect after short delay
                history.push('/forms/templates');
            }, 2000);
        } catch (e) {
            console.error(e);
            deleteErrorMessageBar.setMessage(
                `Failed to ${verb} form ${form.name}. Please try again`,
            );
        } finally {
            setIsDeleting(false);
        }
    };

    const debouncedGetTypeaheadPersonas = useDebounceGetEmployeePersonas(undefined, true, true);
    const debouncedGetTypeaheadEmailOnlyPersonas = useDebounceGetEmployeePersonas(
        undefined,
        true,
        true,
        true,
    );

    const onOwnersChange = async (items?: IPersonaProps[] | undefined): Promise<void> => {
        if (!items?.length) {
            return;
        }
        const uniquePersonas = new Map<string, IPersonaProps>();
        for (const item of items) {
            uniquePersonas.set(item.secondaryText ?? '', item);
        }
        const newOwners = Array.from(uniquePersonas.values());
        const newOwnerIds = Array.from(uniquePersonas.keys());
        await updateAndSaveForm('owners', newOwnerIds);
        setOwners(newOwners);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        deleteErrorMessageBar.clearMessage();
    };

    const onNotificationListChange = async (items?: IPersonaProps[] | undefined): Promise<void> => {
        if (!items?.length) {
            // no more people in the notification list.
            await updateAndSaveForm('notificationList', []);
            setNotificationList([]);
            return;
        }
        const uniquePersonas = new Map<string, IPersonaProps>();
        for (const item of items) {
            if (item.secondaryText !== undefined && item.secondaryText !== '') {
                uniquePersonas.set(item.secondaryText, item);
            }
        }
        // submit the whole email
        const newNotificationList = Array.from(uniquePersonas.values());
        const newNotificationListIds = Array.from(uniquePersonas.keys());
        await updateAndSaveForm('notificationList', newNotificationListIds);
        setNotificationList(newNotificationList);
    };

    const onEmailSubmit = async (): Promise<void> => {
        // validation
        const x = notificationEmail.split('@') ?? [];
        let email = notificationEmail;
        // If the user just starts copy-pasting full microsoft emails then I will check quickly if it is a microsoft email then add it
        if (x.length === 2) {
            if (x[1] !== 'microsoft.com') {
                setEmailErrorText('not a valid @microsoft.com Email');
                return;
            }
        } else if (x.length > 2) {
            setEmailErrorText('not a valid Email');
            return;
        } else {
            if (disallowedCharacters.some((char) => email.includes(char))) {
                setEmailErrorText('Emails cannot contain !, %, #, $, &, ~');
                return;
            }
            email = email + '@microsoft.com';
        }
        const updatedNotificationsList = [...notificationList];
        const updatedNotificationsListIds = notificationList.map((x) => x.secondaryText ?? '');
        // submit the whole email so just check if there is a duplicate first then submit
        if (!updatedNotificationsListIds.includes(email)) {
            updatedNotificationsList.push(transformEmailToPersona(email));
            updatedNotificationsListIds.push(email);
        } else {
            setEmailErrorText('duplicate Email added');
        }
        await updateAndSaveForm('notificationList', updatedNotificationsListIds);
        setNotificationList(updatedNotificationsList);
        // clear text field after each submit
        setNotificationEmail('');
        setEmailErrorText('');
    };

    const onAddOwnerSubmit = async (): Promise<void> => {
        const owners = form.owners;
        const updatedNotificationsList = [...notificationList];
        const updatedNotificationsListIds = notificationList.map((x) => x.secondaryText ?? '');
        owners.forEach((email) => {
            // submit the whole email so just check if there is a duplicate first then submit
            if (!updatedNotificationsListIds.includes(email)) {
                updatedNotificationsList.push(transformEmailToPersona(email));
                updatedNotificationsListIds.push(email);
            } else {
                return;
            }
        });

        await updateAndSaveForm('notificationList', updatedNotificationsListIds);
        setNotificationList(updatedNotificationsList);
    };

    return (
        <div
            style={{
                minHeight: '100vh',
                background: '#FAFAFA',
                paddingTop: '1rem',
                paddingBottom: '5rem',
            }}>
            {errorElement()}
            <div style={{ margin: '0 7.5rem 0 auto', width: 'fit-content' }}>
                <DeleteModalButton
                    name={form.name}
                    isSoftDeleted={form.isSoftDeleted}
                    setDeleteModalOpen={setDeleteModalOpen}
                    isDeleting={isDeleting}
                    closeDeleteModal={closeDeleteModal}
                    deleteForm={deleteForm}
                    isDeleteModalOpen={isDeleteModalOpen}
                    isModalUnclosable={isModalUnclosable}
                    errorMessageBarMessage={deleteErrorMessageBar.theMessage}
                    successMessageBarMessage={deleteSuccessMessageBar.theMessage}
                />
            </div>
            <div className={form.isSoftDeleted ? disabledGridStyling : gridStyling}>
                <Label id={'subtitleLabel'} style={{ gridArea: 'subtitleLabel' }}>
                    Form subtitle
                </Label>
                <div style={{ gridArea: 'subtitleValue' }}>
                    <SubtitleSettings
                        subtitle={form.subtitle}
                        updateAndSaveForm={updateAndSaveForm}
                    />
                </div>
                <div style={{ gridArea: 'ownersLabel' }}>
                    <LabelInfoIcon
                        iconHoverContent={'Owners can view and manage form submissions'}
                        iconName={IconNames.Info}>
                        Owners
                    </LabelInfoIcon>
                </div>
                <div style={{ gridArea: 'ownersValue' }}>
                    {isOwnersLoading ? (
                        <Spinner />
                    ) : (
                        <ListPeoplePicker
                            onResolveSuggestions={debouncedGetTypeaheadPersonas}
                            selectedItems={owners}
                            onChange={onOwnersChange}
                            searchingText='Searching Employees'
                            pickerSuggestionsProps={employeePickerSuggestionProps}
                            selectionAriaLabel={'Selected owners'}
                            removeButtonAriaLabel={'Remove'}
                            disabled={isOwnersLoading}
                            resolveDelay={300}
                        />
                    )}
                </div>
                <div style={{ gridArea: 'labelNotification' }}>
                    <LabelInfoIcon
                        iconHoverContent={
                            'Notifications of form submissions will be sent to the specified employees/distribution lists'
                        }
                        iconName={IconNames.Info}>
                        Notification
                    </LabelInfoIcon>
                    <ActionButton
                        iconProps={{ iconName: IconNames.AddGroup }}
                        style={{ marginTop: '0.5em', float: 'left' }}
                        primary
                        onClick={onAddOwnerSubmit}
                        text='Add All Owners'
                    />
                </div>
                <div style={{ gridArea: 'AddByEmploy' }}>
                    <div className={notificationContainerStyles}>
                        <div style={{ width: '48%' }}>
                            Add by employee
                            <ListPeoplePicker
                                onResolveSuggestions={debouncedGetTypeaheadEmailOnlyPersonas}
                                selectedItems={notificationList}
                                onChange={onNotificationListChange}
                                searchingText='Searching Employees'
                                pickerSuggestionsProps={employeePickerSuggestionProps}
                                selectionAriaLabel={'Selected Notifications'}
                                removeButtonAriaLabel={'Remove'}
                                resolveDelay={300}
                            />
                        </div>
                        <div>
                            Add by email
                            <TextField
                                onChange={(event, value): void => setNotificationEmail(value ?? '')}
                                suffix='@microsoft.com'
                                errorMessage={emailErrorText}
                                value={notificationEmail}
                            />
                            <PrimaryButton
                                style={{ marginTop: '0.5em', float: 'right' }}
                                primary
                                onClick={onEmailSubmit}
                                text='Add'
                            />
                        </div>
                    </div>
                </div>
                <Label id={'accessLabel'} style={{ gridArea: 'accessLabel' }}>
                    Who can fill out this form
                </Label>
                <div style={{ gridArea: 'accessValue' }}>
                    <AccessControlSettings
                        form={form}
                        updateAndSaveForm={updateAndSaveForm}
                        clearAccessControl={clearAccessControl}
                        hasAccessControl={hasAccessControl}
                        setHasAccessControl={setHasAccessControl}
                        setErrorMessage={setErrorMessage}
                    />
                </div>
                <Label id={'imageLabel'} style={{ gridArea: 'imageLabel', margin: 'auto 0' }}>
                    Cover image
                </Label>
                <ImageSettings form={form} updateAndSaveForm={updateAndSaveForm} />
                <Label id={'publishLabel'} style={{ gridArea: 'publishLabel' }}>
                    Publish form
                </Label>
                <Toggle
                    styles={{ root: { gridArea: 'value3' } }}
                    checked={form.isPublished}
                    onText='Published'
                    offText='Hidden'
                    inlineLabel
                    aria-labelledby={'publishLabel'}
                    ariaLabel={'Publish form'}
                    onChange={(ev, checked): void => {
                        if (checked !== undefined) {
                            updateAndSaveForm('isPublished', checked);
                        }
                    }}
                />
                <Label id={'lockLabel'} style={{ gridArea: 'lockLabel' }}>
                    Lock form
                </Label>
                <Toggle
                    styles={{ root: { gridArea: 'value4' } }}
                    checked={form.isLocked}
                    onText='Locked'
                    offText='Unlocked'
                    inlineLabel
                    aria-labelledby={'lockLabel'}
                    ariaLabel={'Lock form'}
                    onChange={(ev, checked): void => {
                        if (checked !== undefined) {
                            updateAndSaveForm('isLocked', checked);
                        }
                    }}
                />
            </div>
        </div>
    );
}
