import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import {
    CoreMultiPrincipalIdPickerTypeaheadSearch,
    CoreSinglePrincipalIdPickerTypeaheadSearch,
    CoreSinglePrincipalRecordPickerTypeaheadSearch,
} from 'components/common/core-employee-picker-typeahead-search';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useCheckMountedState } from 'utils/misc-hooks';

/** ***************************************************
 * This hook is intended for single person select only.
 * If you need multi select, create a new hook for it.
 * ****************************************************
 */
interface ICoreEmployeePickerParams {
    label?: string;
    disabled?: boolean;
    required?: boolean;
    blockSelfSponsor?: boolean;
    validate?: () => boolean;
    placeHolder?: string;
    onError?: (msg: string) => void;
}

export interface IUseCoreEmployeePicker {
    value: IPrincipalRecord | undefined;
    isValid: boolean | undefined;
    hasChanged: boolean | undefined;
    initialize: (initValue?: IPrincipalRecord) => void;
    theElement: () => JSX.Element;
}

export function useCoreEmployeePicker(params?: ICoreEmployeePickerParams): IUseCoreEmployeePicker {
    const authContext = useContext(AuthContext);

    const [employee, setEmployee] = useState<IPrincipalRecord | undefined>();
    const [initialEmployee, setInitialEmployee] = useState<IPrincipalRecord | undefined>();

    const [isValid, setIsValid] = useCheckMountedState<boolean | undefined>(undefined);

    const onEmployeeSelectHandler = (person?: IPrincipalRecord): void => {
        if (person === undefined) {
            setEmployee(undefined);
            return;
        }

        const isSponsorSelf = authContext.getUserProfile()?.oid === person.oid;

        if (params?.blockSelfSponsor && isSponsorSelf) {
            setEmployee(undefined);
            if (!!params?.onError) {
                params?.onError('Cannot set sponsor to self');
            } else {
                console.error(`Cannot set sponsor to self`);
            }

            return;
        }

        setEmployee(person);
    };

    const initialize = (initValue?: IPrincipalRecord): void => {
        setEmployee(initValue);
        setInitialEmployee(initValue);
    };

    useEffect(() => {
        setIsValid(!!params?.validate ? params.validate() : undefined);
    }, [employee, params?.validate]);

    return {
        value: employee,
        isValid,
        hasChanged: employee?.id !== initialEmployee?.id,
        initialize,
        theElement: () => (
            <CoreSinglePrincipalRecordPickerTypeaheadSearch
                label={params?.label ?? ''}
                ariaLabel={params?.label ?? ''}
                disabled={params?.disabled}
                required={params?.required}
                placeHolder={params?.placeHolder ?? 'Employee Name or Alias'}
                onChange={onEmployeeSelectHandler}
                selectedItem={employee}
            />
        ),
    };
}

export interface IUseCorePrincipalIdPicker {
    value: string | undefined;
    valueRecord: IPrincipalRecord | undefined;
    isValid: boolean | undefined;
    hasChanged: boolean | undefined;
    initialize: (initValue?: string) => void;
    placeHolder?: string;
    theElement: () => JSX.Element;
}

export function useCorePrincipalIdPicker(
    params?: ICoreEmployeePickerParams,
): IUseCorePrincipalIdPicker {
    const authContext = useContext(AuthContext);

    const [principalId, setPrincipalId] = useState<string | undefined>();
    const [principalRecord, setPrincipalRecord] = useState<IPrincipalRecord | undefined>();
    const [initialPrincipalId, setInitialPrincipalId] = useState<string | undefined>();

    const [isValid, setIsValid] = useCheckMountedState<boolean | undefined>(undefined);

    const onEmployeeSelectHandler = (
        principalId?: string,
        principalRecord?: IPrincipalRecord,
    ): void => {
        if (principalId === undefined) {
            setPrincipalId(undefined);
            setPrincipalRecord(undefined);
            return;
        }

        if (params?.blockSelfSponsor) {
            if (principalRecord === undefined) {
                setPrincipalId(undefined);
                setPrincipalRecord(undefined);
                return;
            }

            const isSponsorSelf = authContext.getUserProfile()?.oid === principalRecord.oid;

            if (isSponsorSelf) {
                setPrincipalId(undefined);
                setPrincipalRecord(undefined);
                if (!!params?.onError) {
                    params?.onError('Cannot set sponsor to self');
                } else {
                    console.error(`Cannot set sponsor to self`);
                }

                return;
            }
        }

        setPrincipalId(principalId);
        setPrincipalRecord(principalRecord);
    };

    const initialize = (initValue?: string): void => {
        setPrincipalId(initValue);
        setInitialPrincipalId(initValue);
    };

    useEffect(() => {
        setIsValid(!!params?.validate ? params.validate() : undefined);
    }, [principalId, params?.validate]);

    return {
        value: principalId,
        valueRecord: principalRecord,
        isValid,
        hasChanged: principalId !== initialPrincipalId,
        initialize,
        theElement: () => (
            <CoreSinglePrincipalIdPickerTypeaheadSearch
                label={params?.label ?? ''}
                ariaLabel={params?.label ?? ''}
                disabled={params?.disabled}
                required={params?.required}
                placeHolder={params?.placeHolder ?? 'Employee Name or Alias'}
                onChange={onEmployeeSelectHandler}
                selectedItem={principalId}
            />
        ),
    };
}

export interface ICoreMultiEmployeePickerParams extends ICoreEmployeePickerParams {
    allowDuplicates?: boolean;
}

export interface IUseCoreMultiPrincipalIdPicker {
    value: string[] | undefined;
    valueRecord: IPrincipalRecord[] | undefined;
    isValid: boolean | undefined;
    hasChanged: boolean | undefined;
    initialize: (initValue?: string[]) => void;
    theElement: () => JSX.Element;
}

export function useCoreMultiPrincipalIdPicker(
    params?: ICoreMultiEmployeePickerParams,
): IUseCoreMultiPrincipalIdPicker {
    const authContext = useContext(AuthContext);

    const [principalIds, setPrincipalIds] = useState<string[] | undefined>();
    const [principalRecords, setPrincipalRecords] = useState<IPrincipalRecord[] | undefined>();
    const [initialPrincipalIds, setInitialPrincipalIds] = useState<string[] | undefined>();

    const [isValid, setIsValid] = useCheckMountedState<boolean | undefined>(undefined);

    const onPrincipalsSelectHandler = (
        principalIds?: string[],
        principalRecords?: IPrincipalRecord[],
    ): void => {
        if (principalIds === undefined || principalRecords === undefined) {
            setPrincipalIds(undefined);
            setPrincipalRecords(undefined);
            return;
        }

        if (!params?.allowDuplicates) {
            const uniqueIds = new Set();
            principalRecords = principalRecords?.filter((record) => {
                if (!uniqueIds.has(record.id)) {
                    uniqueIds.add(record.id);
                    return true;
                }
                return false;
            });

            principalIds = principalRecords.map((record) => record.id);
        }

        if (params?.blockSelfSponsor) {
            const selfOid = authContext.getUserProfile()?.oid;
            const selfSponsorRecord = principalRecords.find((x) => x.oid === selfOid);
            if (selfSponsorRecord) {
                setPrincipalIds(principalIds.filter((x) => x !== selfSponsorRecord.id));
                setPrincipalRecords(principalRecords.filter((x) => x.id !== selfSponsorRecord.id));
                if (!!params?.onError) {
                    params?.onError(`Cannot set sponsor to self: ${selfSponsorRecord.id} `);
                } else {
                    console.error(`Cannot set sponsor to self: ${selfSponsorRecord.id} `);
                }

                return;
            }
        }

        setPrincipalIds(principalIds);
        setPrincipalRecords(principalRecords);
    };

    const initialize = (initValues?: string[]): void => {
        setPrincipalIds(initValues);
        setInitialPrincipalIds(initValues);
    };

    useEffect(() => {
        setIsValid(!!params?.validate ? params.validate() : undefined);
    }, [principalIds, params?.validate]);

    const hasChanged = useMemo(() => {
        if (principalIds === undefined && initialPrincipalIds === undefined) {
            return false;
        } else if (principalIds === undefined || initialPrincipalIds === undefined) {
            return true;
        } else {
            return (
                principalIds.length !== initialPrincipalIds.length ||
                !principalIds.every((id) => initialPrincipalIds.includes(id))
            );
        }
    }, [principalIds, initialPrincipalIds]);

    return {
        value: principalIds,
        valueRecord: principalRecords,
        isValid,
        hasChanged: hasChanged,
        initialize,
        theElement: () => (
            <CoreMultiPrincipalIdPickerTypeaheadSearch
                label={params?.label ?? ''}
                ariaLabel={params?.label ?? ''}
                disabled={params?.disabled}
                required={params?.required}
                placeHolder={'Employee Name or Alias'}
                onChange={onPrincipalsSelectHandler}
                selectedItems={principalIds}
            />
        ),
    };
}
