import React, { useState } from 'react';
import {
    DefaultButton,
    PrimaryButton,
    ProgressIndicator,
    MessageBar,
    MessageBarType,
    mergeStyles,
} from '@fluentui/react';

const footerStyles = mergeStyles({
    backgroundColor: 'rgb(253, 253, 253)',
    padding: '14px',
    marginTop: 10,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
});

const finalIndicatorStyles = mergeStyles({
    padding: '5px 15px 5px 15px',
});

export interface StepperProps {
    renderContent: () => JSX.Element;
    isFirstStep: boolean;
    isLastStep: boolean;
    activeStep: number;
    finishText?: string;
    handleBack: (ev: React.MouseEvent<any>) => any;
    handleNext: (ev: React.MouseEvent<any>) => any;
    handleFinish?: (ev: React.MouseEvent<any>) => any;
    handleCancel?: (ev: React.MouseEvent<any>) => any;
    hasMessage: boolean;
    disableFinishButton?: boolean;
    finishIndicatorLabel?: string;
    messageText?: string;
    messageType?: MessageBarType;
    handleResetMessage: () => void;
}
function Stepper(props: StepperProps) {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const finishIndicatorLabel = props.finishIndicatorLabel
        ? props.finishIndicatorLabel
        : 'Working...';
    const finalFooterStyles = mergeStyles(footerStyles, {
        justifyContent: props.isFirstStep ? 'flex-end' : 'space-between',
    });

    return (
        <div>
            {props.renderContent()}
            {props.hasMessage && (
                <MessageBar
                    styles={{
                        root: { padding: '0 25px', boxSizing: 'border-box' },
                    }}
                    messageBarType={props.messageType}
                    isMultiline={true}
                    dismissButtonAriaLabel='Close'
                    overflowButtonAriaLabel='See more'
                    onDismiss={onDismissMessage}>
                    <div style={{ maxWidth: 275 }}>{props.messageText}</div>
                </MessageBar>
            )}
            <div className={finalIndicatorStyles}>
                {isButtonDisabled && <ProgressIndicator label={finishIndicatorLabel} />}
            </div>
            <div className={finalFooterStyles}>
                <DefaultButton
                    style={{ marginRight: '10px' }}
                    text='Cancel'
                    onClick={props.handleCancel}
                    allowDisabledFocus
                    disabled={isButtonDisabled}
                />
                <div>
                    {props.activeStep > 0 && (
                        <DefaultButton
                            style={{ marginRight: '10px' }}
                            onClick={props.handleBack}
                            text='Back'
                            allowDisabledFocus
                            disabled={isButtonDisabled}
                        />
                    )}
                    <PrimaryButton
                        onClick={(ev) =>
                            props.isLastStep
                                ? props.handleFinish
                                    ? handleFinish(ev)
                                    : console.warn('No final step function provided')
                                : props.handleNext(ev)
                        }
                        text={
                            props.isLastStep
                                ? props.finishText !== ''
                                    ? props.finishText
                                    : 'Finish'
                                : 'Next'
                        }
                        allowDisabledFocus
                        disabled={
                            props.isLastStep && props.disableFinishButton
                                ? props.disableFinishButton
                                : isButtonDisabled
                        }
                    />
                </div>
            </div>
        </div>
    );
    function onDismissMessage() {
        props.handleResetMessage();
    }
    function handleFinish(ev: React.MouseEvent<any>): void {
        if (props.handleFinish) {
            setButtonDisabled(true);
            props.handleFinish(ev);
        }
    }
}
export default Stepper;
