import React, { useState, useEffect } from 'react';
import EligibilityClient, { IAttribute, IEligibility } from 'clients/eligibility-client';
import { IAuthContext } from 'contexts/auth-context';
import { generalIsMountedCode } from 'utils/misc-utils';
import { Stack, mergeStyleSets } from '@fluentui/react';
import { Dictionary } from 'assets/constants/global-constants';

interface IUseAttributes {
    attributes: IAttribute[];
    isFetchingAttributes: boolean;
    hasProblemLoadingPage: string;
    setAttributes: (attributes: IAttribute[]) => void;
}

export function useAttributes(authContext: IAuthContext): IUseAttributes {
    const [attributes, setAttributes] = useState<IAttribute[]>([]);
    const [hasProblemLoadingPage, setHasProblemLoadingPage] = useState<string>('');
    const [isFetchingAttributes, setIsFetchingAttributes] = useState<boolean>(true);

    const fetchAttributes = async (isMountedFunc: () => boolean): Promise<void> => {
        try {
            const attributesVar = await EligibilityClient.getAttributes(authContext);
            if (isMountedFunc()) {
                setHasProblemLoadingPage('');
                setAttributes(attributesVar);
            }
        } catch {
            if (isMountedFunc()) {
                setHasProblemLoadingPage('Error loading attributes');
            }
        } finally {
            if (isMountedFunc()) {
                setIsFetchingAttributes(false);
            }
        }
    };
    useEffect(() => {
        return generalIsMountedCode(fetchAttributes);
    }, []);

    return { attributes, isFetchingAttributes, hasProblemLoadingPage, setAttributes };
}

interface IUseEligibilities {
    eligibilities: IEligibility[];
    isFetchingEligibilities: boolean;
    hasProblemLoadingEligibilities: string;
    setEligibilities: (eligibilities: IEligibility[]) => void;
}

export function useEligibilities(authContext: IAuthContext): IUseEligibilities {
    const [eligibilities, setEligibilities] = useState<IEligibility[]>([]);
    const [hasProblemLoadingEligibilities, setHasProblemLoadingEligibilities] = useState<string>(
        '',
    );
    const [isFetchingEligibilities, setIsFetchingEligibilities] = useState<boolean>(true);

    const fetchEligibilities = async (isMountedFunc: () => boolean): Promise<void> => {
        try {
            const eligibilitiesVar = await EligibilityClient.getEligibilities(authContext);
            if (isMountedFunc()) {
                setHasProblemLoadingEligibilities('');
                setEligibilities(eligibilitiesVar);
            }
        } catch {
            if (isMountedFunc()) {
                setHasProblemLoadingEligibilities('Error loading eligibilities');
            }
        } finally {
            if (isMountedFunc()) {
                setIsFetchingEligibilities(false);
            }
        }
    };
    useEffect(() => {
        return generalIsMountedCode(fetchEligibilities);
    }, []);

    return {
        eligibilities,
        isFetchingEligibilities,
        hasProblemLoadingEligibilities,
        setEligibilities,
    };
}

export const renderRequiredAttributes = (
    requiredAttributes?: string[][],
    attributesDict: Dictionary<IAttribute> = {},
): JSX.Element => {
    const styles = mergeStyleSets({
        andBox: {
            width: 45,
        },
        orBox: {
            width: 25,
        },
    });

    return (
        <Stack>
            {requiredAttributes?.map((attributeGroup, ix) => (
                <Stack.Item key={ix}>
                    <Stack horizontal>
                        <Stack.Item grow={0}>
                            {requiredAttributes.length > 1 && (
                                <div className={styles.andBox}>
                                    {ix > 0 && <strong>AND&nbsp;</strong>}
                                </div>
                            )}
                        </Stack.Item>
                        <Stack wrap horizontal>
                            {attributeGroup.map((attributeId, jx) => (
                                <Stack.Item key={jx}>
                                    {jx > 0 && (
                                        <span className={styles.orBox}>
                                            <strong>&nbsp;or&nbsp;</strong>
                                        </span>
                                    )}
                                    <span>
                                        {attributesDict[attributeId]?.attributeCode ?? attributeId}
                                    </span>
                                </Stack.Item>
                            ))}
                        </Stack>
                    </Stack>
                </Stack.Item>
            ))}
        </Stack>
    );
};

/**
 *
 * @param attributes Array of attributes
 * @returns Dictionary<IAttribute> Key is attribute ID
 */
export const genAttributesDictionary = (attributes: IAttribute[]): Dictionary<IAttribute> => {
    const attrDict: Dictionary<IAttribute> = {};
    attributes.forEach((attribute) => {
        attrDict[attribute.id] = attribute;
    });
    return attrDict;
};
