import React, { useEffect } from 'react';
import { useCheckMountedState } from 'utils/misc-hooks';
import { TextField, ITextFieldProps, IRenderFunction } from '@fluentui/react';
import { IUseInputElement } from 'components/common/use-input/input-element-utils';

export interface ITextFieldParams {
    // Ideally, we should keep the following props 100% the same
    // as the underlying Fluent component so that they can directly
    // drive its props.
    autoAdjustHeight?: boolean;
    label?: string;
    disabled?: boolean;
    maxLength?: number;
    multiline?: boolean;
    placeholder?: string;
    readOnly?: boolean;
    required?: boolean;
    resizable?: boolean;
    rows?: number;
    onRenderLabel?: IRenderFunction<ITextFieldProps>;
    type?: string;
    ariaLabel?: string;
    // Non-standard input params
    // By non-standard I mean the prop is not on the underlying
    // Fluent component, or has a different definition.
    validate?: () => boolean;
}

export function useTextField(params: ITextFieldParams): IUseInputElement<string> {
    const [value, setValue] = useCheckMountedState<string | undefined>(undefined);
    const [initialValue, setInitialValue] = useCheckMountedState<string | undefined>(undefined);
    const [isValid, setIsValid] = useCheckMountedState<boolean | undefined>(undefined);

    const onValueChange = (a?: React.FormEvent<HTMLElement>, str?: string): void => {
        if (a?.type === 'input') {
            setValue(str);
        }
    };

    const initialize = (initValue?: string): void => {
        setValue(initValue ?? '');
        setInitialValue(initValue ?? '');
    };

    useEffect(() => {
        setIsValid(!!params.validate ? params.validate() : undefined);
    }, [value, params.validate]);

    return {
        value,
        isValid,
        hasChanged: value !== initialValue,
        initialize,
        theElement: (): JSX.Element => (
            <TextField
                autoAdjustHeight={params.autoAdjustHeight}
                label={params.label}
                required={params.required}
                value={value || ''}
                disabled={params.disabled}
                maxLength={params.maxLength}
                multiline={params.multiline}
                onChange={onValueChange}
                onRenderLabel={params.onRenderLabel}
                readOnly={params.readOnly}
                resizable={params.resizable}
                placeholder={params.placeholder}
                rows={params.rows}
                type={params.type}
                ariaLabel={params.ariaLabel}
            />
        ),
    };
}
