import { Dictionary } from 'assets/constants/global-constants';
import { ICacheContext } from 'contexts/cache-context';
import { OrgPermissionType } from 'components/staffing/staffing-page-types';
import { StaffingOrgRolesType } from 'configs/roles';
import {
    IStaffingClearanceRecordResponse,
    IStaffingAllocationResponse,
} from 'clients/staffing-client';

/**
 * This file masquerades as a cache and presents a few utility functions
 * that use CacheContext to store and retrieve variables related to Staffing.
 *
 * It creates an object for staffing variables inside CacheContext and
 * puts all staffing related variables under that. It declares the constant
 * identifier StaffingPrefix as the Map key of CacheContext.
 */

export class StaffingCache {
    /*************************************************************************************
     * Internal functions
     * Only the following functions directly interact with cacheContext
     */
    private static _initCache(cacheContext: ICacheContext): void {
        if (cacheContext.retrieve(StaffingPrefix)) {
            return;
        }
        this._clearCache(cacheContext);
    }

    private static _clearCache(cacheContext: ICacheContext): void {
        cacheContext.store(StaffingPrefix, cacheInitValue());
    }

    private static _retrieveCache(cacheContext: ICacheContext): StaffingCacheDataType {
        this._initCache(cacheContext);
        // The following type cast is safe because the above
        // statement ensures the following data is not undefined.
        return cacheContext.retrieve(StaffingPrefix) as StaffingCacheDataType;
    }

    private static _retrieveVariable<T>(
        cacheContext: ICacheContext,
        varName: StaffingCacheVarNameType,
    ): T | undefined {
        this._initCache(cacheContext);
        const cached = this._retrieveCache(cacheContext);
        return cached ? ((cached[varName] as unknown) as T | undefined) : undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static _storeVariable(
        cacheContext: ICacheContext,
        varName: StaffingCacheVarNameType,
        value: any,
    ): void {
        this._initCache(cacheContext);
        const cache = this._retrieveCache(cacheContext);
        cache[varName] = value;
        cacheContext.store<StaffingCacheDataType>(StaffingPrefix, cache);
    }

    private static _storeDictionaryVariable<T>(
        // Sets a variable whose value is a dictionary.
        // Don't use this method for updating variables
        // that are dictionary of dictionaries, eg orgPermission.
        cacheContext: ICacheContext,
        varName: StaffingCacheVarNameType,
        keyName: string,
        value: T,
    ): void {
        const dictionary = (this._retrieveVariable(cacheContext, varName) as unknown) as Dictionary<
            T
        >;
        dictionary[keyName] = value;
        this._storeVariable(cacheContext, varName, dictionary);
    }
    /**
     * END Internal functions
     * END Only the following functions directly interact
     * with cacheContext
     *****************************************************************/

    /*************************************************************************************
     * Interface to the outside world
     */
    static invalidateCache(cacheContext: ICacheContext): void {
        this._clearCache(cacheContext);
    }

    static getOrgPermission(
        cacheContext: ICacheContext,
        orgName: string,
        role: StaffingOrgRolesType,
    ): boolean | undefined {
        const permissions = this._retrieveVariable(cacheContext, OrgPermissionsVar) as Dictionary<
            OrgPermissionType
        >;
        return (permissions[orgName] || {})[role];
    }

    static setOrgPermission(
        cacheContext: ICacheContext,
        orgName: string,
        role: StaffingOrgRolesType,
        value: boolean,
    ): void {
        // Don't use _storeDictionaryVariable for this, because
        // type of this variable is dictionary of dictionaries.
        // At this moment I don't have a method to update such
        // a variable.
        const permissions = this._retrieveVariable(cacheContext, OrgPermissionsVar) as Dictionary<
            OrgPermissionType
        >;
        const orgPermissions = permissions[orgName] || {};
        orgPermissions[role] = value;
        permissions[orgName] = orgPermissions;
        this._storeVariable(cacheContext, OrgPermissionsVar, permissions);
    }

    static getAllocationsByOrg(
        cacheContext: ICacheContext,
        orgName: string,
    ): IStaffingAllocationResponse[] {
        const dictionary = this._retrieveVariable(cacheContext, AllocationsByOrgVar) as Dictionary<
            IStaffingAllocationResponse[]
        >;
        return dictionary[orgName];
    }

    static setAllocationsByOrg(
        cacheContext: ICacheContext,
        orgName: string,
        allocationsByOrg: IStaffingAllocationResponse[],
    ): void {
        this._storeDictionaryVariable(cacheContext, AllocationsByOrgVar, orgName, allocationsByOrg);
    }

    static getAllocationsWithClearance(
        cacheContext: ICacheContext,
    ): IStaffingClearanceRecordResponse[] {
        return this._retrieveVariable(
            cacheContext,
            AllocationsWithClearanceVar,
        ) as IStaffingClearanceRecordResponse[];
    }

    static setAllocationsWithClearance(
        cacheContext: ICacheContext,
        allocationsWithClearance: IStaffingClearanceRecordResponse[],
    ): void {
        this._storeVariable(cacheContext, AllocationsWithClearanceVar, allocationsWithClearance);
    }

    /**
     * END Interface to the outside world
     *****************************************************************/
}

const StaffingPrefix = 'Staffing';

type StaffingCacheDataType = {
    /**
     * Permissions of current user to access an org
     * Example orgPermissions: {
     *      ASME: {
     *          [Role.StaffingOrgRead]: false,
     *          [Role.StaffingOrgEdit]: true,
     *      },
     *      global: {
     *          [Role.StaffingOrgRead]: true,
     *          [Role.StaffingOrgEdit]: true,
     *      },
     * }
     */
    orgPermissions: Dictionary<OrgPermissionType>; // Key: orgName, Value: OrgPermissions
    allocationsByOrg: Dictionary<IStaffingAllocationResponse[]>;
    allocationsWithClearance: IStaffingClearanceRecordResponse[] | undefined;
};

const OrgPermissionsVar = 'orgPermissions';
const AllocationsByOrgVar = 'allocationsByOrg';
const AllocationsWithClearanceVar = 'allocationsWithClearance';

type StaffingCacheVarNameType = 'orgPermissions' | 'allocationsByOrg' | 'allocationsWithClearance';

const cacheInitValue = (): StaffingCacheDataType => ({
    orgPermissions: {},
    allocationsByOrg: {},
    allocationsWithClearance: undefined,
});
