import React from 'react';
import { ActionButton } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';

export default function MailtoScaSecurityOfficerActionButton(): JSX.Element {
    return (
        <ActionButton
            iconProps={{ iconName: IconNames.Mail }}
            href='mailto:SCASecurityOfficers@microsoft.com'
            text='SCASecurityOfficers@microsoft.com'
        />
    );
}
