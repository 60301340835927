import {
    CommandBar,
    IColumn,
    ICommandBarItemProps,
    Icon,
    Label,
    MessageBar,
    MessageBarType,
    SelectionMode,
    ShimmeredDetailsList,
    mergeStyleSets,
    mergeStyles,
    Selection,
    registerIcons,
    Stack,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import {
    AttributeDataType,
    AttributePredefinedValue,
    AttributePredefinedValueStatus,
} from 'personnel-core-clients';
import { CoreAttributesClient } from 'clients/core/personnel-core-client-wrappers';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DeletePredefinedValueDialog } from 'components/core/attributes/predefined-values/delete-predefined-value-dialog';
import { ValueEditor } from 'components/core/attributes/predefined-values/predefined-value-add-edit';

export interface IPredefinedValueSelectorProps {
    attributeId: string;
}

export function PredefinedValueSelector(props: IPredefinedValueSelectorProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [shouldFetchData, setShouldFetchData] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const [mode, setMode] = useState<SelectorMode>(); // TODO make this an enum probably
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

    const [predefinedValues, setPredefinedValues] = useState<AttributePredefinedValue[]>([]);
    const [selectedPredefinedValue, setSelectedPredefinedValue] = useState<
        AttributePredefinedValue
    >();
    const [attributeDataType, setAttributeDataType] = useState<AttributeDataType>();

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            setShouldFetchData(false);
            setIsLoading(true);
            const attributeSetClient = new CoreAttributesClient(authContext);

            try {
                const attribute = await attributeSetClient.getById(props.attributeId);
                const values = attribute.predefinedValues ?? [];
                const filteredValues = values.filter(
                    (x) => x.status !== AttributePredefinedValueStatus.Deleting,
                );
                setPredefinedValues(filteredValues);
                setAttributeDataType(attribute.dataType);
            } catch (e) {
                setError('Error occurred loading predefined values.');
            } finally {
                setIsLoading(false);
            }
        };
        if (shouldFetchData) {
            fetchData();
        }
    }, [props.attributeId, authContext, shouldFetchData]);

    const commandBarItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        return [
            {
                key: 'add',
                text: 'Add value',
                iconProps: { iconName: IconNames.Add },
                onClick: () => {
                    setSelectedPredefinedValue(undefined);
                    setMode(SelectorMode.Add);
                },
            },
            {
                key: 'edit',
                text: 'Edit value',
                iconProps: { iconName: IconNames.EditNoFill },
                onClick: () => setMode(SelectorMode.Edit),
                disabled: selectedPredefinedValue === undefined,
            },
            {
                key: 'delete',
                text: 'Delete value',
                iconProps: { iconName: IconNames.Delete },
                onClick: () => setShowDeleteConfirmation(true),
                disabled: selectedPredefinedValue === undefined,
            },
        ];
    }, [selectedPredefinedValue]);

    const selection: Selection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                setSelectedPredefinedValue(
                    (selection.getSelection()[0] as AttributePredefinedValue) ?? undefined,
                );
            },
        });
    }, []);

    if (error !== undefined) {
        return <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>;
    } else {
        return (
            <div className={styles.mainContainer}>
                <Label>Predefined values</Label>
                <CommandBar
                    styles={{
                        root: {
                            paddingLeft: 0,
                        },
                    }}
                    items={commandBarItems}
                />
                {(mode === SelectorMode.Add || mode === SelectorMode.Edit) && (
                    <ValueEditor
                        attributeId={props.attributeId}
                        attributeDataType={attributeDataType}
                        predefinedValue={selectedPredefinedValue}
                        onFinish={() => {
                            setMode(undefined);
                            setSelectedPredefinedValue(undefined);
                            setShouldFetchData(true);
                        }}
                        onCancel={() => {
                            setMode(undefined);
                        }}
                    />
                )}
                {(isLoading || predefinedValues.length > 0) && (
                    <ShimmeredDetailsList
                        enableShimmer={isLoading}
                        columns={columns}
                        items={predefinedValues}
                        selectionMode={SelectionMode.single}
                        selection={selection}
                    />
                )}
                {selectedPredefinedValue && (
                    <DeletePredefinedValueDialog
                        hidden={!showDeleteConfirmation}
                        attributeId={props.attributeId}
                        predefinedValue={selectedPredefinedValue}
                        onDismissed={() => setShowDeleteConfirmation(false)}
                        onSuccess={() => {
                            setMode(undefined);
                            setShowDeleteConfirmation(false);
                            setSelectedPredefinedValue(undefined);
                            setShouldFetchData(true);
                        }}
                    />
                )}
            </div>
        );
    }
}

enum SelectorMode {
    Add,
    Edit,
}

const columns: IColumn[] = [
    {
        key: 'value',
        name: 'Value',
        ariaLabel: 'Predefined value',
        fieldName: 'value',
        minWidth: 135,
        isResizable: true,
        onRender: (item: AttributePredefinedValue): JSX.Element => {
            return <span className={styles.valueText}>{item.value}</span>;
        },
    },
    {
        key: 'status',
        name: 'Status',
        ariaLabel: 'Predefined value status',
        fieldName: 'status',
        minWidth: 135,
        isResizable: true,
        onRender: (item: AttributePredefinedValue): JSX.Element => {
            return (
                <Stack horizontal>
                    <Icon
                        iconName={
                            item.status === AttributePredefinedValueStatus.Active
                                ? 'status-active-svg'
                                : 'status-inactive-svg'
                        }
                        className={
                            item.status === AttributePredefinedValueStatus.Active
                                ? styles.predefinedEnabled
                                : styles.predefinedDisabled
                        }
                    />
                    <span className={styles.statusText}>
                        {item.status === AttributePredefinedValueStatus.Active
                            ? 'Active'
                            : 'Inactive'}
                    </span>
                </Stack>
            );
        },
    },
];

const iconClass = mergeStyles({
    margin: '0 12px 0 0',
    height: '22px',
});

const styles = mergeStyleSets({
    predefinedEnabled: [{ color: 'green' }, iconClass],
    predefinedDisabled: [{ fill: 'red' }, iconClass],
    mainContainer: {
        marginTop: '25px',
    },
    valueText: {
        lineHeight: '22px',
    },
    statusText: {
        color: '#605E5C',
        lineHeight: '22px',
    },
});

registerIcons({
    icons: {
        'status-active-svg': (
            <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM10.75 13.4393L15.2197 8.96967C15.5126 8.67678 15.9874 8.67678 16.2803 8.96967C16.5466 9.23594 16.5708 9.6526 16.3529 9.94621L16.2803 10.0303L11.2803 15.0303C11.0141 15.2966 10.5974 15.3208 10.3038 15.1029L10.2197 15.0303L7.71967 12.5303C7.42678 12.2374 7.42678 11.7626 7.71967 11.4697C7.98594 11.2034 8.4026 11.1792 8.69621 11.3971L8.78033 11.4697L10.75 13.4393L15.2197 8.96967L10.75 13.4393Z'
                    fill='#13A10E'
                />
            </svg>
        ),
        'status-inactive-svg': (
            <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM16.25 11.25C16.6642 11.25 17 11.5858 17 12C17 12.4142 16.6642 12.75 16.25 12.75C11.2529 12.75 13.211 12.75 7.75 12.75C7.33579 12.75 7 12.4142 7 12C7 11.5858 7.33579 11.25 7.75 11.25C13.211 11.25 11.2529 11.25 16.25 11.25Z'
                    fill='#EAA300'
                />
            </svg>
        ),
    },
});
