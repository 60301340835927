import React, { ReactElement } from 'react';
import { mergeStyleSets } from '@fluentui/react';

type PageSelectionsProps = {
    children: JSX.Element | JSX.Element[];
};

export default function PageSelections({ children }: PageSelectionsProps): JSX.Element {
    const childrenVar: JSX.Element[] = Array.isArray(children) ? children : [children];
    return (
        <div className={styles.container}>
            {childrenVar.map((child: ReactElement, ix: number) => (
                <div key={ix} className={styles.child}>
                    {child}
                </div>
            ))}
        </div>
    );
}

const styles = mergeStyleSets({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        alignContent: 'flex-start',
    },
    child: {
        flexShrink: '1',
        alignSelf: 'stretch',
        marginLeft: '6%',
        marginBottom: '24px',
    },
});
