import React, { useState, useContext, FormEvent } from 'react';
import StaffingClient, { IAzureService } from 'clients/staffing-client';
import { Dropdown, IDropdownOption, ITag } from '@fluentui/react';
import { AuthContext } from 'contexts/auth-context';
import Picker from 'components/common/picker';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { globalStyles } from 'assets/styles/global-styles';

import { IAddServiceModal } from 'components/staffing/staffing-page-types';

export default function AddServiceModalButton(props: IAddServiceModal): JSX.Element {
    const authContext = useContext(AuthContext);
    const [teamName, setTeamName] = useState<string | undefined>();
    const [selectedServices, setSelectedServices] = useState<ITag[]>([]);

    const determineTeamName = (): string | undefined => props.teamName || teamName;

    const isAddDisabled = (): boolean =>
        (!teamName && !props.teamName) || !props.orgName || selectedServices.length === 0;

    const addService = async (): Promise<void> => {
        if (isAddDisabled()) {
            return;
        }
        const services = selectedServices.map((selectedService) =>
            props.allServices.find((service) => service.oid === selectedService.key),
        );
        const teamName = determineTeamName();
        if (services.length === 0 || !teamName) {
            return;
        }
        try {
            await StaffingClient.createTeamAzureServices(
                authContext,
                props.orgName,
                teamName,
                services as IAzureService[], // to prevent VSCode warning wave line.
            );
        } catch (e) {
            throw 'Some error occurred. Could not add the service.';
        }
    };

    const onTeamSelectHandler = (
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): void => {
        setTeamName(option?.text ?? undefined);
    };

    const onServiceSelectionChange = (selectedServices?: ITag[]): void => {
        setSelectedServices(selectedServices ?? []);
    };

    const onButtonClick = () => {
        setTeamName('');
        setSelectedServices([]);
    };

    const teamSelectOptions = props.teams
        .sort((i, j) => i.name.toLocaleLowerCase().localeCompare(j.name.toLocaleLowerCase()))
        .map(
            (t): IDropdownOption => ({
                key: t.name,
                text: t.name,
            }),
        );

    const serviceOrServices = selectedServices.length > 1 ? 'Services' : 'Service';
    const modalTitle = props.teamName
        ? `Assign Azure Service(s) to Team${props.teamName ? ` "${props.teamName}"` : ''}`
        : 'Assign Azure Service(s) to a Team';

    return (
        <ModalActionButton<void>
            text={'Add Service'}
            iconName={'CloudAdd'}
            onSubmit={addService}
            modalTitle={modalTitle}
            enableSubmit={!isAddDisabled()}
            onButtonClick={onButtonClick}
            submitErrorMsg={`Some error occurred. Could not add the ${serviceOrServices}.`}
            onModalConcluded={props.addServiceCallback}
            submitButtonText={`Assign ${serviceOrServices}`}>
            <div>
                <Dropdown
                    label={props.teamName ? 'Team' : 'Select team'}
                    styles={{ dropdownItems: globalStyles.dropdownItems }}
                    placeholder='Select a team'
                    options={teamSelectOptions}
                    onChange={onTeamSelectHandler}
                    disabled={!!props.teamName}
                    selectedKey={determineTeamName()}
                />
                <br />
                <Picker
                    tags={props.allServices.map((s) => ({ name: s.name, key: s.oid }))}
                    onSelectionChange={onServiceSelectionChange}
                />
            </div>
        </ModalActionButton>
    );
}
