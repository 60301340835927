import React, { ReactNode, useState, useEffect, useContext } from 'react';
import StaffingClient, {
    IStaffingClearanceRecordResponse,
    IStaffingTeamResponse,
} from 'clients/staffing-client';
import { AuthContext } from 'contexts/auth-context';
import { CacheContext } from 'contexts/cache-context';
import { StaffingStatusFiltersContext } from 'components/staffing/contexts/staffing-status-filter-context';
import { Projects, StatusTableMaxColumns } from 'components/staffing/staffing-constants';
import { Stack, IStackStyles, IStackTokens } from '@fluentui/react';
import { OrgTeamsObjectType } from 'components/staffing/staffing-page-types';
import {
    filterAllocations,
    emptyFilteredAllocations,
} from 'components/staffing/status-filters/filter-utility';
import StaffingOrgStatusTable from 'components/staffing/status/staffing-org-status-table';
import BarChart from 'components/common/charts/bar-chart';
import { generateStatusBarChartProps, determineOrgsInfo } from 'components/staffing/staffing-utils';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import NavLinkContainer from 'components/common/navlink-container';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';
import { Dictionary } from 'assets/constants/global-constants';

export default function StaffingStatusTables(): JSX.Element {
    const authContext = useContext(AuthContext);
    const cacheContext = useContext(CacheContext);
    const filters = useContext(StaffingStatusFiltersContext);

    const [orgNames, setOrgNames] = useState<string[]>([]);
    const [orgTeams, setOrgTeams] = useState<OrgTeamsObjectType>({});
    const [isOrgTeamsProblem, setIsOrgTeamsProblem] = useState<Dictionary<boolean>>({});
    const [allocations, setAllocations] = useState<IStaffingClearanceRecordResponse[]>([]);
    const [isFetchingData, setIsFetchingData] = useState<boolean>(true);
    const [filteredAllocations, setFilteredAllocations] = useState(emptyFilteredAllocations);

    const statusTableContainerStyle: IStackStyles = {
        root: {
            height: '100vh',
        },
        inner: {
            width: '',
            flexGrow: 0,
            justifyContent: 'flex-start',
        },
    };

    const pageStackTokens: IStackTokens = { childrenGap: '20 20' };
    const statusTableStackTokens: IStackTokens = { childrenGap: '20 20' };

    useEffect(() => {
        Projects.forEach(async (project) => {
            try {
                const { accessibleOrgNames: orgNamesVar } = await determineOrgsInfo(authContext);
                const allocationsVar = await StaffingClient.getAllocationsWithClearance(
                    authContext,
                    cacheContext,
                    project.name,
                );
                const orgTeamsVar = {} as OrgTeamsObjectType;
                const teams = await Promise.all(
                    orgNamesVar.map(async (orgName) => {
                        let teams: IStaffingTeamResponse[];
                        try {
                            teams = await StaffingClient.getTeamsFromOrganization(
                                authContext,
                                orgName,
                            );
                        } catch (e) {
                            console.error(e);
                            setIsOrgTeamsProblem({
                                ...isOrgTeamsProblem,
                                [orgName]: true,
                            });
                            teams = [];
                        }
                        return {
                            orgName,
                            teams,
                        };
                    }),
                );
                teams.forEach((team) => {
                    orgTeamsVar[team.orgName] = team.teams;
                });
                setOrgTeams(orgTeamsVar);
                setOrgNames(orgNamesVar);
                setAllocations(allocationsVar);
                setIsFetchingData(false);
            } catch (e) {
                console.error(e);
                setOrgTeams({});
                setOrgNames([]);
                setAllocations([]);
                setIsFetchingData(false);
            }
        });
    }, []);

    useEffect(() => {
        // First use the filter factor to filter through allocations
        const filteredAllocations = filterAllocations({ filters, orgNames, orgTeams, allocations });
        // Then filter based on the selected cloud.
        filteredAllocations.allocations = filteredAllocations.allocations.filter((allocation) => {
            const result = allocation.cloudStatuses.find(
                (cloudStatus) => cloudStatus.name === filters?.cloud?.key,
            );

            return result;
        });
        setFilteredAllocations(filteredAllocations);
    }, [filters, orgNames, orgTeams, allocations]);

    const statusTables = (): ReactNode[] => {
        return orgNames
            .sort((i1, i2) => i1.localeCompare(i2))
            .map((orgName) => (
                <NavLinkContainer
                    key={orgName}
                    to={`/staffing/org/${StaffingClient.encodeOrgNameToUrlAcceptableFormat(
                        orgName,
                    )}`}>
                    <ProblemLoadingMsg
                        problemLoadingMsg={
                            isOrgTeamsProblem[orgName]
                                ? `There was a problem loading teams of ${orgName}`
                                : ''
                        }>
                        <StaffingOrgStatusTable
                            teams={orgTeams[orgName]}
                            orgName={orgName}
                            showWhat='status-table'
                            theseAllocations={filteredAllocations.allocations.filter(
                                (allocation) => allocation.organization === orgName,
                            )}
                            maxStatusColumns={StatusTableMaxColumns}
                        />
                    </ProblemLoadingMsg>
                </NavLinkContainer>
            ));
    };

    return (
        <PageLoadingSpinner
            isLoading={isFetchingData}
            label={'Loading allocations...'}
            ariaLive='assertive'
            labelPosition='left'>
            <Stack tokens={pageStackTokens}>
                <Stack.Item>
                    <BarChart
                        barChartData={generateStatusBarChartProps(
                            filteredAllocations.allocations
                                .map((a) => a.cloudStatuses)
                                .map((a) => a.filter((b) => b.name === filters.cloud.key)),
                            'forStatusTables',
                        )}
                        yAxisProps={{ intelligentMax: true }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <Stack wrap styles={statusTableContainerStyle} tokens={statusTableStackTokens}>
                        {statusTables().map((statusTable, k) => (
                            <Stack.Item key={k}>{statusTable}</Stack.Item>
                        ))}
                    </Stack>
                </Stack.Item>
            </Stack>
        </PageLoadingSpinner>
    );
}
