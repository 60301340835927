import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

export interface FacilitiesReservationLandingPageProps {
    match: {
        params: {
            category?: string;
        };
    };
}

export default function FacilitiesReservationLandingPage(
    props: FacilitiesReservationLandingPageProps,
): JSX.Element {
    const [redirectUrl, setRedirectUrl] = useState<string>();

    useEffect(() => {
        if (props.match.params.category) {
            setRedirectUrl(`/facilities/reservations/${props.match.params.category}`);
        } else {
            setRedirectUrl('/facilities/reservations/new');
        }
    });
    return <>{redirectUrl && <Redirect to={redirectUrl} />}</>;
}
