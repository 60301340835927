import { MessageBar, MessageBarType, Spinner, SpinnerSize, ITextField } from '@fluentui/react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import EmployeeClient, { IEmployee } from 'clients/employee-client';
import FacilitiesClient, { IFacilitiesTokenResult } from 'clients/facilities-client';
import { AuthContext } from 'contexts/auth-context';
import React, { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { createTimeoutAbortSignal } from 'utils/abort-utils';
import { getAppInsights } from 'utils/telemetry-utils';
import employeeCard from 'assets/img/employeeCard.png';

const ShowErrorMessageForMilliseconds = 5000;
const BadgeLength = 7;
const BadgeSerialError =
    'Badge serial was not recognized. Please verify that the correct badge was tapped.';
const GenericBadgeError =
    'An unknown error occurred while processing your badge. Please verify that the correct badge was tapped.';
const KioskCommunicationError =
    'The kiosk may be experiencing an issue communicating with facility-service.';
const KioskNoInternetError = 'The kiosk has no network connection. Try again later.';
const InvalidTokenReceived = 'The kiosk unfortunately received an invalid authentication token.';
const TokenHasNoAccessToFacility = 'User does not have permission to access facility.';
const EmployeeIdNotRecognized =
    'Employee ID/Badge/Alias was not recognized or employee info could not be retrieved.';

const numberKeyCodeExpression = /^(Numpad|Digit)([0-9])$/;

export default function FacilitiesKioskLandingPage(): JSX.Element {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const { facilityId } = useParams<{ facilityId: string }>();
    const [keyedBadgeNumber, setKeyedBadgeNumber] = useState<string>('');
    const [isBadgeSerialLookupStarted, setIsBadgeSerialLookupStarted] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const textFieldRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const setAndLogErrorMessage = useCallback(
        (message: string) => {
            getAppInsights()?.trackTrace({
                message: `Kiosk user error message ${message}`,
                severityLevel: SeverityLevel.Error,
            });
            setErrorMessage(message);
        },
        [setErrorMessage],
    );

    const isBadgeSerialAvailable = keyedBadgeNumber.endsWith('\n');

    if (isBadgeSerialAvailable && !isBadgeSerialLookupStarted) {
        const badgeSerial = keyedBadgeNumber.trim();

        if (badgeSerial.length !== BadgeLength) {
            setAndLogErrorMessage(BadgeSerialError);
            setKeyedBadgeNumber('');
        } else {
            (async (): Promise<void> => {
                setIsBadgeSerialLookupStarted(true);
                setKeyedBadgeNumber('');
                await (async (): Promise<void> => {
                    let employee: IEmployee | undefined = undefined;
                    try {
                        employee = await EmployeeClient.getEmployeeRecordByBadgeSerialNumber(
                            authContext,
                            badgeSerial,
                            createTimeoutAbortSignal(10000),
                        );
                    } catch (e) {
                        if (e.status === 404) {
                            setAndLogErrorMessage(EmployeeIdNotRecognized);
                        } else if (e.message === 'The user aborted a request.') {
                            // Request timed out
                            setAndLogErrorMessage(KioskCommunicationError);
                        } else if (e.message === 'Failed to fetch') {
                            setAndLogErrorMessage(KioskNoInternetError);
                        } else {
                            setAndLogErrorMessage(GenericBadgeError);
                        }

                        getAppInsights()?.trackException({
                            exception: e,
                            severityLevel: SeverityLevel.Error,
                        });
                        return;
                    }

                    let facilitiesToken: IFacilitiesTokenResult | undefined = undefined;
                    try {
                        facilitiesToken = await FacilitiesClient.getFacilitiesToken(
                            authContext,
                            employee.id,
                            createTimeoutAbortSignal(10000),
                        );
                    } catch (e) {
                        getAppInsights()?.trackException({
                            exception: e,
                            severityLevel: SeverityLevel.Error,
                        });
                        return;
                    }

                    if (facilitiesToken === undefined || facilitiesToken.token === undefined) {
                        setAndLogErrorMessage(InvalidTokenReceived);
                        return;
                    }

                    if (!FacilitiesClient.canAccessFacility(facilitiesToken, facilityId)) {
                        setAndLogErrorMessage(TokenHasNoAccessToFacility);
                        return;
                    }

                    getAppInsights()?.trackTrace({
                        message: 'Navigating to user page.',
                        severityLevel: SeverityLevel.Information,
                    });
                    history.push(
                        `/facilities-kiosk/${facilityId}#facilities-token=${facilitiesToken?.token}`,
                    );
                })();

                setIsBadgeSerialLookupStarted(false);
            })();
        }
    }

    const keyPressHandler = useCallback((ev: KeyboardEvent) => {
        const numberMatch = ev.code.match(numberKeyCodeExpression);
        if (numberMatch !== null) {
            setKeyedBadgeNumber((oldKeyedBadgeNumber) => oldKeyedBadgeNumber + numberMatch[2]);
        } else if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            setKeyedBadgeNumber((oldKeyedBadgeNumber) => oldKeyedBadgeNumber + '\n');
        } else {
            setKeyedBadgeNumber('');
        }
    }, []);

    const focusHandler = useCallback(() => {
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, []);

    const clickHandler = useCallback(() => {
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, []);

    // There have been numerous bugs where focus doesn't automatically come to the application on first launch, so show a message asking the user to touch the screen first.
    const focusRecieved = useCallback(() => {
        setIsFocused(true);
    }, []);

    useEffect(() => {
        document.addEventListener('keypress', keyPressHandler);
        document.addEventListener('focus', focusHandler);
        document.addEventListener('click', clickHandler);

        return () => {
            document.removeEventListener('keypress', keyPressHandler);
            document.removeEventListener('focus', focusHandler);
            document.removeEventListener('click', clickHandler);
        };
    }, [focusHandler, keyPressHandler, clickHandler]);

    // Clear error message after ShowErrorMessageForMilliseconds
    useEffect(() => {
        if (errorMessage !== undefined) {
            const timeoutId = setTimeout(() => {
                setErrorMessage(undefined);
            }, ShowErrorMessageForMilliseconds);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [errorMessage]);

    useEffect(() => {
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, [textFieldRef, errorMessage]);

    // Don't allow the textbox to build up a record of badge serial numbers.
    const preventValueChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        ev.target.value = '';
    }, []);

    return (
        <div
            style={{
                height: 'calc(100vh - 197px)',
                display: 'grid',
                gridTemplateColumns: '1fr auto 1fr',
                gridTemplateRows: '1fr auto 40px 60px 1fr',
            }}>
            {errorMessage && (
                <div style={{ gridColumn: '1 / 4' }}>
                    <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>
                </div>
            )}
            {isFocused && (
                <img
                    title='employeeCard'
                    style={{ boxShadow: '0 3px 10px rgb(0 0 0 / 0.3)', gridColumn: 2, gridRow: 2 }}
                    src={employeeCard}
                />
            )}
            <span
                style={{
                    gridColumn: 2,
                    gridRow: 4,
                    textAlign: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                }}>
                {isBadgeSerialLookupStarted ? (
                    <Spinner size={SpinnerSize.large} label={'Searching for employee record...'} />
                ) : isFocused ? (
                    'Tap badge to begin.'
                ) : (
                    'Please touch screen to begin.'
                )}
            </span>
            <input
                type='text'
                ref={textFieldRef}
                onChange={preventValueChange}
                onFocus={focusRecieved}
                style={{ opacity: 0, position: 'absolute' }}
            />
        </div>
    );
}
