import React from 'react';
import {
    DECLINED_SCREENING_STATES,
    GetUSGovStateName,
    getPublicTrustStatusDisplay,
    getStatusDisplayByStateName,
} from 'components/screening/common/common-constants';
import {
    statusSpanStyleDeclined,
    statusSpanStyleNormal,
} from 'components/screening/common/common-tab-styling';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { IScreening } from 'components/screening/us-gov/IScreening';

// Parent Status Display Column
export function getCommonScreeningStatusLabel(publicTrust: ICommonScreening): JSX.Element {
    return (
        <span
            style={
                DECLINED_SCREENING_STATES.includes(publicTrust.stateName)
                    ? statusSpanStyleDeclined
                    : statusSpanStyleNormal
            }>
            <span>{getPublicTrustStatusDisplay(publicTrust)}</span>
        </span>
    );
}

export function getScreeningStatusLabel(screeningRecord: IScreening): JSX.Element {
    const stateName = GetUSGovStateName(screeningRecord.parentState, screeningRecord.state);
    return (
        <span
            style={
                DECLINED_SCREENING_STATES.includes(stateName)
                    ? statusSpanStyleDeclined
                    : statusSpanStyleNormal
            }>
            <span>{getStatusDisplayByStateName(stateName)}</span>
        </span>
    );
}
