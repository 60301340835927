import React from 'react';
import { FontIcon, HoverCard, HoverCardType, mergeStyleSets, Stack } from '@fluentui/react';
import { timeToString, TimeFormats } from 'utils/time-utils';
import { IEmployee } from 'clients/employee-client';
import { Table } from 'components/common/table';
import { employeeHierarchyTableColumns } from 'components/user-assignments/display-person-info/table-columns-employee-hierarchy';
import { getCloudScreeningStatus } from 'components/screening/cloud-screening/cloud-screening-utils';
import { Link } from 'react-router-dom';
import { globalStyles } from 'assets/styles/global-styles';

interface IDisplayEmployeeInfoProps {
    employee: IEmployee | undefined;
    employeeHierarchy: IEmployee[] | undefined;
    isFetchingEmployeeHierarchy: boolean;
    problemFetchingEmployeeHierarchy: string;
    showViewOthersProfileButton: boolean;
}

export default function EmployeeInfoDisplay(props: IDisplayEmployeeInfoProps): JSX.Element {
    const displayItem = (label: string, value: string | number | undefined | null): JSX.Element => {
        return (
            <div className={styles.infoItem}>
                <div>
                    <strong>{label}</strong>
                </div>
                <div>{value}</div>
            </div>
        );
    };

    const displayItemWithInfoBubbleForTerminationDate = (
        label: string,
        value: string | number | undefined | null,
    ): JSX.Element => {
        return (
            <div className={styles.infoItem}>
                <div style={{ display: 'flex' }}>
                    <div>
                        <strong>{label}</strong>
                    </div>
                    <div>
                        <HoverCard
                            style={{ maxWidth: '400px' }}
                            type={HoverCardType.plain}
                            plainCardProps={{
                                onRenderPlainCard: (): JSX.Element => {
                                    return (
                                        <p style={{ margin: '10px' }}>
                                            Your most recent termination date data in{' '}
                                            <Link
                                                className={globalStyles.link}
                                                to={{ pathname: 'https://aka.ms/ec' }}
                                                target='_blank'>
                                                Employee Central
                                            </Link>
                                            . Questions? Contact{' '}
                                            <Link
                                                className={globalStyles.link}
                                                to={{ pathname: 'https://aka.ms/askhrvaec' }}
                                                target='_blank'
                                                rel='noreferrer'>
                                                AskHR
                                            </Link>
                                        </p>
                                    );
                                },
                            }}>
                            <FontIcon iconName='Info' style={{ marginLeft: '0.25rem' }} />
                        </HoverCard>
                    </div>
                </div>
                <div>{value}</div>
            </div>
        );
    };

    const employee = props.employee;

    if (!employee) return <></>;
    else
        return (
            <Stack>
                <Stack.Item>
                    <Stack horizontal wrap>
                        <Stack.Item className={styles.itemGroup}>
                            {displayItem('Personnel Id', employee.id)}
                            {displayItem('Alias', employee.alias)}
                            {displayItem('Reports to Alias', employee.reportsToEmailName)}
                            {displayItem('Is Full-Time', employee.isFTE ? 'Yes' : 'No')}
                        </Stack.Item>
                        <Stack.Item className={styles.itemGroup}>
                            {displayItem('Full Name', employee.fullName)}
                            {displayItem('First Name', employee.firstName)}
                            {displayItem('Middle Name', employee.middleName)}
                            {displayItem('Last Name', employee.lastName)}
                        </Stack.Item>
                        <Stack.Item className={styles.itemGroup}>
                            {displayItem('Address Book Title', employee.addressBookTitleDesc)}
                            {displayItem('Standard Title', employee.standardTitle)}
                            {displayItem('Profession', employee.profession)}
                            {displayItem('Discipline', employee.discipline)}
                        </Stack.Item>
                        <Stack.Item className={styles.itemGroup}>
                            {displayItem('Location Area City', employee.locationAreaCity)}
                            {displayItem('Location Area State', employee.locationAreaDetail)}
                            {displayItem('Location Area Country', employee.country)}
                            {displayItem('Location Area Code', employee.locationAreaCode)}
                        </Stack.Item>
                        <Stack.Item className={styles.itemGroup}>
                            {displayItem('Company Code', employee.companyCode)}
                            {displayItem('Cost Center Code', employee.costCenterCode)}
                            {displayItem('Position Number', employee.positionNumber)}
                            {employee?.terminationDate &&
                                displayItemWithInfoBubbleForTerminationDate(
                                    'Termination Date',
                                    timeToString(employee?.terminationDate, TimeFormats.MMMDDYYYY),
                                )}
                        </Stack.Item>
                        {(employee?.csPersonnelNumber > -1 ||
                            !!employee?.csCompany ||
                            !!employee?.isUSCitizen ||
                            !!employee?.cloudScreenCompletionDate ||
                            !!employee?.cloudScreenExpireDate) && (
                            <Stack.Item className={styles.itemGroup}>
                                {!!employee?.csCompany &&
                                    displayItem('Contingent Staff Country', employee.csCompany)}
                                {employee?.csPersonnelNumber > -1 &&
                                    displayItem(
                                        'Contingent Staff Personnel Number',
                                        employee.csPersonnelNumber,
                                    )}
                                {employee?.isUSCitizen !== undefined &&
                                    displayItem(
                                        'US Citizenship - Verified',
                                        employee.isUSCitizen ? 'Yes' : 'No',
                                    )}
                                {!!employee?.cloudScreenCompletionDate &&
                                    displayItem(
                                        'Cloud Screen Completed Date',
                                        timeToString(
                                            employee?.cloudScreenCompletionDate,
                                            TimeFormats.MMMDDYYYY,
                                        ),
                                    )}
                                {!!employee?.cloudScreenExpireDate &&
                                    displayItem(
                                        'Cloud Screen Valid Until',
                                        timeToString(
                                            employee?.cloudScreenExpireDate,
                                            TimeFormats.MMMDDYYYY,
                                        ),
                                    )}
                                {!!employee?.cloudScreenCompletionDate &&
                                    !!employee?.cloudScreenExpireDate &&
                                    displayItem(
                                        'Cloud Screen Status',
                                        getCloudScreeningStatus(employee)?.shortText,
                                    )}
                            </Stack.Item>
                        )}
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Table
                        rows={props.employeeHierarchy ?? []}
                        tableColumns={employeeHierarchyTableColumns({
                            showViewOthersProfileButton: props.showViewOthersProfileButton,
                        })}
                        shimmerLabel={'Loading hierarchy...'}
                        isFetchingData={props.isFetchingEmployeeHierarchy}
                        tableName='Employee Hierarchy'
                    />
                </Stack.Item>
            </Stack>
        );
}

const styles = mergeStyleSets({
    infoItem: {
        width: 180,
        padding: 5,
    },
    itemGroup: {
        marginBottom: 20,
    },
});
