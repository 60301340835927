import React from 'react';
import { Stack, IStackStyles, IStackItemStyles } from '@fluentui/react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { Options } from 'chartjs-plugin-datalabels/types/options';

/**
 * Example:
 *   barChartData: [
 *      {
 *        xAxis:  'NO STATUS',
 *        yAxis: 0,
 *        color: 'grey',
 *      },
 *      {
 *        xAxis:  'PRE HIRE',
 *        yAxis: 2,
 *        color: 'blue',
 *      },
 *   ],
 */

export interface BarChartProps {
    barChartData: ChartItem[];
    additionalLegend?: Legend[];
    /**
     * Enables or disables the display of a label above each bar
     * in the chart indicating the value of the bar.
     */
    displayDataLabels?: boolean;
    /**
     * Placeholder for possible future customization parameters
     *     styling: {
     *         height: string,
     *     }
     */
    yAxisProps?: {
        // If you want the max value on y axis to the the same as
        // the maximum value in data, set the following to "false"
        // or leave it unconnected.
        // If, however, you want to this module to "intelligently"
        // determine some max value for it, set this to true.
        // Here "intelligently" is limited to my intelligence,
        // whatever is left of it.
        // This property will be ignored if maximum value in data
        // is 0 or negative.
        intelligentMax?: boolean; // Default: false
        height?: number;
    };
    hideLegend?: boolean;
}

export type ChartItem = {
    xAxis: string;
    yAxis: number;
    color: string;
    borderColor?: string; // Value for CSS style "border-color"
};

type ChartLayoutPaddingOption = {
    top?: number | undefined;
    right?: number | undefined;
    bottom?: number | undefined;
    left?: number | undefined;
};

type Legend = {
    xAxis: string;
    color: string | undefined;
};

export default function BarChart(props: BarChartProps): JSX.Element {
    const { barChartData } = props;

    const chartWidth = props.hideLegend ? '100%' : '70%';

    const barChartStyles: IStackItemStyles = {
        root: {
            // Using flexBasis caused the bar chart to expand when window
            // expanded, but then also caused it NOT to shrink if the
            // window shrinked back. Therefore, changed it to "width : 70%".
            width: chartWidth,
        },
    };

    const dataLabelsOptions = (data: number[]): { datalabels?: Options } => {
        const total = data.reduce((a, b) => a + b);
        if (props.displayDataLabels) {
            return {
                datalabels: {
                    align: 'end',
                    anchor: 'end',
                    clamp: true,
                    color: 'black',
                    display: function (context: Context) {
                        return total > 0;
                    },
                },
            };
        } else {
            return {
                datalabels: {
                    display: false,
                },
            };
        }
    };

    const layoutPaddingOption = (): ChartLayoutPaddingOption => {
        const bottom = 0;
        const left = 0;
        const right = 0;
        let top = 0;

        top = top + (props.displayDataLabels ? 25 : 0);

        return {
            bottom: bottom,
            left: left,
            right: right,
            top: top,
        };
    };

    const legendStyles: IStackItemStyles = {
        root: {
            flexBasis: '30%',
            maxWidth: '210px',
            display: 'flex',
            height: 50,
            justifyContent: 'flex-start',
        },
    };

    const legendItemStyles: IStackStyles = {
        root: {
            flexBasis: '100px',
            marginLeft: 5,
        },
    };

    const legendColorBoxStyles = (color: string, borderColor?: string): IStackItemStyles => {
        type rootStyleType = { [styleName: string]: string | number };
        const borderStyle: rootStyleType = borderColor
            ? {
                  borderColor: borderColor,
                  borderWidth: 1,
                  borderStyle: 'solid',
              }
            : {};
        const rootStyle: rootStyleType = {
            flexBasis: '10px',
            height: '10px',
            marginRight: '5px',
            backgroundColor: color,
            ...borderStyle,
        };
        return {
            root: rootStyle,
        };
    };

    const maxYaxis = (data: number[]): number => {
        const maxData = Math.max(...data);

        if (props.yAxisProps?.height) {
            return props.yAxisProps.height;
        } else if (!props.yAxisProps?.intelligentMax || maxData <= 0) {
            return maxData;
        }

        /**
         * If the max value in the data is greater than 0, it automatically
         * determines one of the following yMax for the chart: 10, 25, 50,
         * 75, 100, 250, 500, 750, 1000, 2500, and so on.
         *
         * So, if the max data is anywhere between 1 to 10, yMax will be 10.
         * If it's anywhere between 11 and 25, it'll be 25.
         * 26 to 50 -> 50
         * 51 to 75 -> 75
         * 76 to 100 -> 100
         * 101 to 250 -> 250
         * etc.
         */
        const numDigits = Math.floor(Math.log10(maxData)) + 1;
        const closest10 = Math.max(Math.pow(10, numDigits - 1), 10);

        const by1 = closest10;
        const by20 = closest10 * 2;
        const by25 = closest10 * 2.5;
        const by50 = closest10 * 5;
        const by75 = closest10 * 7.5;
        const by100 = closest10 * 10;

        let result;
        if (maxData <= by1) result = by1;
        else if (maxData > by1 && maxData <= by20) result = by20;
        else if (maxData > by20 && maxData <= by25) result = by25;
        else if (maxData > by25 && maxData <= by50) result = by50;
        else if (maxData > by50 && maxData <= by75) result = by75;
        else result = by100;

        return result;
    };

    const legendTextStyles: IStackItemStyles = {
        root: {},
    };

    const legend = (): JSX.Element => {
        const legend = barChartData.concat((props.additionalLegend as ChartItem[]) ?? []);
        return (
            <Stack horizontal wrap>
                {legend.map(({ xAxis, color, borderColor }, ix) => {
                    return (
                        <Stack
                            horizontal
                            wrap={false}
                            key={xAxis}
                            styles={legendItemStyles}
                            disableShrink={true}
                            horizontalAlign={'start'}
                            verticalAlign={'center'}>
                            <Stack.Item styles={legendColorBoxStyles(color, borderColor)}>
                                &nbsp;
                            </Stack.Item>
                            <Stack.Item styles={legendTextStyles}>{xAxis}</Stack.Item>
                        </Stack>
                    );
                })}
            </Stack>
        );
    };

    const theChart = (): JSX.Element => {
        const data: number[] = [];
        const labels: string[] = [];
        const colors: string[] = [];
        const borderColor: (string | undefined)[] = [];
        const borderWidth: (number | undefined)[] = [];
        barChartData.forEach((dataItem, ix) => {
            labels.push(dataItem.xAxis);
            data.push(dataItem.yAxis);
            colors.push(dataItem.color);
            borderColor.push(dataItem.borderColor);
            borderWidth.push(dataItem.borderColor ? 1 : 0);
        });
        if (barChartData.length) {
            return (
                // Jan, 8, 2021
                // For an explanation of why I am using 'relative', see below:
                //   https://github.com/reactchartjs/react-chartjs-2/issues/334
                <div style={{ position: 'relative' }}>
                    <Bar
                        data={{
                            labels,
                            data,
                            datasets: [
                                {
                                    label: '',
                                    data: data,
                                    backgroundColor: colors,
                                    borderColor,
                                    borderWidth,
                                },
                            ],
                        }}
                        plugins={[ChartDataLabels]}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            layout: {
                                padding: layoutPaddingOption(),
                            },
                            legend: {
                                display: false,
                            },
                            tooltips: {
                                mode: 'index',
                                axis: 'y',
                                bodyAlign: 'center',
                            },
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            min: 0,
                                            max: maxYaxis(data),
                                            suggestedMin: 0,
                                            suggestedMax: 10,
                                            maxTicksLimit: 4,
                                        },
                                    },
                                ],
                                xAxes: [
                                    {
                                        gridLines: {
                                            display: false,
                                        },
                                        ticks: {
                                            // padding: 5, // distace between label and the horizontal axis above them
                                        },
                                    },
                                ],
                            },
                            plugins: dataLabelsOptions(data),
                        }}></Bar>
                </div>
            );
        } else return <></>;
    };

    const barChartContainerStyles: IStackStyles = {
        root: {
            height: 150, // This can potentially be a prop
        },
    };

    return (
        <Stack horizontal styles={barChartContainerStyles}>
            <Stack.Item styles={barChartStyles}>{theChart()}</Stack.Item>
            {!props.hideLegend && <Stack.Item styles={legendStyles}>{legend()}</Stack.Item>}
        </Stack>
    );
}
