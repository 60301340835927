import React from 'react';
import {
    NormalPeoplePicker,
    IBasePickerSuggestionsProps,
    Label,
    IPersonaProps,
    mergeStyles,
} from '@fluentui/react';
import { transformContractsToPersonas } from 'utils/internal-persona-utils';
import { IContract } from 'components/screening/us-gov/IContract';

const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested Contracts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading contracts...',
    showRemoveButtons: false,
    suggestionsAvailableAlertText: 'Contract Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested Contracts',
};

export interface ContractPickerProps {
    label?: string;
    placeHolder?: string;
    selectedPersona?: IPersonaProps;
    contracts: IContract[];
    onContractSelected: (persona?: IPersonaProps) => void;
}

const labelStyle = {
    fontWeight: 600,
    marginBottom: 5,
    display: 'inline-block',
};

const pickerStyle = mergeStyles({
    selectors: {
        '.is-selected .ms-Persona-primaryText': {
            color: 'white',
        },
    },
});

function ContractPicker(props: ContractPickerProps) {
    const persona = props.selectedPersona;
    const contractPersonas = transformContractsToPersonas(props.contracts);
    const placeholder =
        props.placeHolder && props.selectedPersona === undefined ? props.placeHolder : '';

    return (
        <>
            {props.label && props.label.trim() !== '' && (
                <Label required style={labelStyle}>
                    {props.label}
                </Label>
            )}
            <NormalPeoplePicker
                className={pickerStyle}
                aria-label={'Contract Picker'}
                searchingText='Searching Contracts'
                pickerSuggestionsProps={suggestionProps}
                defaultSelectedItems={persona ? [persona] : undefined}
                itemLimit={1}
                onResolveSuggestions={(filterText: string, selectedItems?: IPersonaProps[]) =>
                    onFilterChanged(contractPersonas, filterText, selectedItems)
                }
                onItemSelected={onItemSelected}
                onChange={onItemsChange}
                inputProps={{ placeholder }}
            />
        </>
    );

    function onItemsChange(items?: IPersonaProps[]): void {
        if (undefined === items || items.length === 0) {
            props.onContractSelected(undefined);
        }
    }

    function onItemSelected(selectedItem?: IPersonaProps): IPersonaProps | null {
        if (selectedItem) {
            props.onContractSelected(selectedItem);
            return selectedItem;
        } else {
            return null;
        }
    }

    function onFilterChanged(
        personas: IPersonaProps[],
        filterText: string,
        selectedItems?: IPersonaProps[],
    ): IPersonaProps[] {
        if (selectedItems && selectedItems.length === 1) {
            return [];
        } else {
            if (filterText) {
                return personas.filter((x) =>
                    x.text?.toLowerCase().includes(filterText.toLowerCase()),
                );
            } else {
                return [];
            }
        }
    }
}

export default ContractPicker;
