import { mergeStyles, MessageBar, MessageBarType, Spinner } from '@fluentui/react';
import FormsClient, { FormState, GenericFormRecord } from 'clients/forms-client';
import { AuthContext } from 'contexts/auth-context';
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { usePathParts } from 'utils/misc-hooks';
import { endOfForm, Form, FormResponse, ListNode, next } from 'components/forms/forms-common';
import { FormsViewer } from 'components/forms/forms-viewer';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import { UserContext } from 'contexts/user-context';

const messageBarContainerStyles = mergeStyles({
    width: '97%',
    position: 'fixed',
    right: '1rem',
    zIndex: 999,
});
type FormsViewerDataLoaderProps = {
    form?: FormResponse;
};
const preview = 'preview';

export function FormsViewerDataLoader(props: FormsViewerDataLoaderProps): JSX.Element {
    const { form } = props;
    const [formResponse, setFormResponse] = useState<FormResponse>();
    const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);
    const [formState, setFormState] = useState<string>(FormState.draft);

    const [sectionList, setSectionList] = useState<ListNode[]>([]);
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    const history = useHistory();
    const pathParts = usePathParts();
    const isPreviewPage = pathParts.at(-1) === preview;

    const { name } = useParams<{ name?: string }>();
    const { id } = useParams<{ id: string }>();

    const [messageBarText, setMessageBarText] = useState<string>('');
    const hasEditFeatureFlagEnabled = useFeatureFlag(FeatureFlagKeys.formsEdit).enabled;

    useEffect(() => {
        const setUpForm = async (): Promise<void> => {
            let formForViewer = await getFormForViewer();
            if (!formForViewer) {
                history.push('/forms');
                return;
            }
            formForViewer = addReviewSection(formForViewer);
            const listNodes = getSectionListNodes(formForViewer);
            if (formForViewer.sections[0].visible === undefined) {
                // Either preview or first time filling out form. Set up branching
                formForViewer = setUpBranching(formForViewer);
                buildLinkedList(formForViewer, listNodes);
            } else {
                rebuildLinkedList(formForViewer, listNodes);
            }
            setSectionList(listNodes);
            setFormResponse(formForViewer);
            if (!id && !isPreviewPage) {
                history.replace(`/forms/respond/${formForViewer.name}/${formForViewer.id}`);
            }
        };
        setUpForm();
    }, []);

    const addReviewSection = (formForViewer: FormResponse): FormResponse => {
        const newFormForViewer = { ...formForViewer };
        newFormForViewer.sections = [
            ...formForViewer.sections,
            {
                id: formForViewer.sections.length,
                label: 'Review',
                description: '',
                elements: [],
            },
        ];
        return newFormForViewer;
    };
    const setUpBranching = (formResponse: FormResponse): FormResponse => {
        const formWithBranches = { ...formResponse };
        formResponse.sections.forEach((section) => {
            let isVisible = true;
            section.elements.forEach((element) => {
                element.visible = isVisible;
                if (element.branches && isVisible) {
                    isVisible = false;
                }
            });
        });
        return formWithBranches;
    };
    const buildLinkedList = (formResponse: FormResponse, sections: ListNode[]): void => {
        if (!sections.length) {
            return;
        }
        buildDefaultBranching(formResponse, sections);
    };

    const rebuildLinkedList = (formResponse: FormResponse, sections: ListNode[]): void => {
        if (!sections.length) {
            return;
        }
        const hiddenSections = sections.filter(
            (section) => formResponse.sections[section.value].visible === false,
        );
        buildDefaultBranching(formResponse, hiddenSections);

        const visibleSections = sections.filter(
            (section) => formResponse.sections[section.value].visible,
        );

        visibleSections.forEach((section, index) => {
            if (index < visibleSections.length - 1) {
                section.next = visibleSections[index + 1];
            }
        });
    };

    const buildDefaultBranching = (formResponse: FormResponse, sections: ListNode[]): void => {
        sections.forEach((section) => {
            let branchTarget = formResponse.sections[section.value].branchTarget ?? next;
            if (branchTarget === endOfForm) {
                return;
            }
            if (branchTarget === next) {
                branchTarget = (section.value + 1).toString();
            }
            const sectionIndex = Number(branchTarget);
            if (sectionIndex >= sections.length) {
                return;
            }
            section.next = sections[sectionIndex];
        });
    };

    const getSectionListNodes = (formResponse: FormResponse): ListNode[] => {
        return formResponse.sections.map((section) => {
            return {
                next: undefined,
                value: section.id,
                prev: undefined,
            };
        });
    };

    const getFormForViewer = async (): Promise<FormResponse | undefined> => {
        if (isPreviewPage && form) {
            // isPreviewPage = true, make sure we set up form as a deep copy so no values are written back to the form
            const deepCopiedForm: Form = JSON.parse(JSON.stringify(form));
            return deepCopiedForm;
        }
        const formRecord = id ? await getExistingFormRecord(id) : await createNewRecord();
        if (!formRecord) {
            return;
        }
        return convertFormRecordToForm(formRecord);
    };

    const getExistingFormRecord = async (formRecordId: string): Promise<GenericFormRecord> => {
        const existingFormRecord = await FormsClient.GetGenericFormRecordById(
            authContext,
            formRecordId,
        );

        if (hasEditFeatureFlagEnabled) {
            setFormState(existingFormRecord.formState);
        }
        return existingFormRecord;
    };

    const convertFormRecordToForm = (formRecord: GenericFormRecord): FormResponse => {
        const convertedForm: FormResponse = {
            id: formRecord.id,
            name: name ?? '',
            title: formRecord.title,
            description: formRecord.description,
            label: formRecord.label,
            sections: JSON.parse(formRecord.schema),
        };
        return convertedForm;
    };

    const createNewRecord = async (): Promise<GenericFormRecord | undefined> => {
        try {
            const requestBody = {
                genericFormName: name,
            };
            // TODO: Migrate to long term solution documented here:
            // Forms: Settings / Block v-/b-/t-/a- users from submitting form
            // https://msazure.visualstudio.com/Microsoft%20Personnel/_workitems/edit/25160328
            const alias = userContext.employeeRecord.alias;
            if (
                name?.toLowerCase() === 'citizenship_verification' &&
                alias.toLowerCase().includes('v-')
            ) {
                console.error('Citizenship Verification form is not available for vendors');
                return;
            }
            return await FormsClient.CreateGenericFormRecord(authContext, requestBody);
        } catch (error) {
            console.error('Error setting up form without id during CreateGenericFormRecord:');
            console.error(error);
        }
    };

    const FormViewerElement = useMemo(() => {
        return (
            <FormsViewer
                formResponse={formResponse as FormResponse}
                sectionList={sectionList}
                setSectionList={setSectionList}
                currentSectionIndex={currentSectionIndex}
                setCurrentSectionIndex={setCurrentSectionIndex}
                setFormResponse={setFormResponse as Dispatch<SetStateAction<FormResponse>>}
                setMessageBarText={setMessageBarText}
                isPreviewPage={isPreviewPage}
                formState={formState}
            />
        );
    }, [currentSectionIndex, formResponse, isPreviewPage, sectionList]);

    if (!formResponse || !sectionList.length) {
        return <Spinner />;
    }

    return (
        <>
            {messageBarText && (
                <div className={messageBarContainerStyles}>
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        onDismiss={() => setMessageBarText('')}
                        dismissButtonAriaLabel='Close'>
                        {messageBarText}
                    </MessageBar>
                </div>
            )}
            {FormViewerElement}
        </>
    );
}
