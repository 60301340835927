import { SeatStatusArray } from 'clients/facilities-client';
import { mergeStyleSets } from '@fluentui/react';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FacilitiesLegendProps {}

export const azureMapLegendClass = 'legendContainer';

export default function FacilitiesLegend(props: FacilitiesLegendProps): JSX.Element {
    return (
        <div className={styles.legendContainer}>
            <div className={styles.legendTitle}>Legend</div>
            {SeatStatusArray.map((x, index) => {
                return (
                    <div key={index} className={styles.legendItem}>
                        <div
                            style={{
                                backgroundColor: x.color,
                                border: '1px solid black',
                                width: '25px',
                                height: '25px',
                                marginRight: '20px',
                            }}></div>
                        <span>{x.text}</span>
                    </div>
                );
            })}
        </div>
    );
}

// Follows sidebarPaneOuterStyle & sidebarPaneInnerStyle from SidebarAndContents
const styles = mergeStyleSets({
    legendContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        position: 'absolute',
        zIndex: '1',
        padding: '9px',
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
        borderRadius: '2px',
        margin: '5px 0 0 5px',
        top: '0',
    },
    legendTitle: {
        display: 'flex',
        flexDirection: 'row',
        padding: '5px',
        alignItems: 'center',
        fontSize: '22px',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    legendItem: {
        display: 'flex',
        flexDirection: 'row',
        padding: '5px',
        alignItems: 'center',
        fontSize: '16px',
    },
});
