import React, { useEffect } from 'react';
import {
    ReservationColumnNames,
    FacilityReservationTableColumns,
    IFacilityReservationTableColumn,
} from 'components/facilities/facilities-reservations/facilities-user-reservation-table-view/facilities-user-reservation-columns';
import { Table, ICollapseButton } from 'components/common/table';
import { numCmp, strCmp, useSortColumnHandler } from 'utils/sort-utils';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { mediumGrey } from 'assets/constants/global-colors';
import { noSpacerStyle } from 'assets/styles/list-styles';

export interface UserReservationTableProps {
    reservations?: IFacilityReservationTableColumn[];
    setReservations: (rsvp?: IFacilityReservationTableColumn[]) => void;
    isFetchingData: boolean;
    onModalConcluded: (modalConclusion: ModalConclusion) => void;
    shimmerLabel?: string;
    tableName?: string;
    isCollapsed?: boolean;
}

export default function UserReservationTable(props: UserReservationTableProps): JSX.Element {
    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler(
        ReservationColumnNames.FACILITYNAME,
        1,
    );

    useEffect(() => {
        if (props.reservations) {
            const rsvps = sortReservations(props.reservations);
            props.setReservations(rsvps);
        }
    }, [sortColumn, sortAscending]);

    const sortReservations = (
        reservations: IFacilityReservationTableColumn[],
    ): IFacilityReservationTableColumn[] => {
        type A = IFacilityReservationTableColumn;

        const chooseSortCmp = (
            sortColumn: string,
        ): ((
            r1: IFacilityReservationTableColumn,
            r2: IFacilityReservationTableColumn,
        ) => number) => {
            switch (sortColumn) {
                case ReservationColumnNames.FACILITYNAME:
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        strCmp(r1.facilityRecord?.facilityName, r2.facilityRecord?.facilityName);
                case ReservationColumnNames.SEATNAME:
                    return (r1: A, r2: A): number =>
                        sortAscending * strCmp(r1.seatRecord?.seatName, r2.seatRecord?.seatName);
                case ReservationColumnNames.DATE:
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(
                            r1.reservationRecord.reservationTimeSlot.startDateTimeUTCMilliseconds,
                            r2.reservationRecord.reservationTimeSlot.startDateTimeUTCMilliseconds,
                        );
                case ReservationColumnNames.TIMESLOT:
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        numCmp(
                            r1.reservationRecord.reservationTimeSlot.startDateTimeUTCMilliseconds,
                            r2.reservationRecord.reservationTimeSlot.startDateTimeUTCMilliseconds,
                        );
                case ReservationColumnNames.CHECKINTIME:
                    return (r1: A, r2: A): number =>
                        sortAscending * numCmp(r1.checkInTime, r2.checkInTime);
                case ReservationColumnNames.CHECKOUTIME:
                    return (r1: A, r2: A): number =>
                        sortAscending * numCmp(r1.checkOutTime, r2.checkOutTime);
                case ReservationColumnNames.STATUS:
                    return (r1: A, r2: A): number =>
                        sortAscending *
                        strCmp(r1.reservationRecord.state, r2.reservationRecord.state);
                default:
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp(sortColumn);
        return [...reservations].sort(sortCmp);
    };

    function renderTableResults(): JSX.Element {
        if ((props.reservations && props.reservations.length > 0) || props.isFetchingData) {
            const tableColumns = FacilityReservationTableColumns({
                sortColumn,
                sortAscending: sortAscending === 1,
                sortColumnHandler,
                reservations: props.reservations ?? [],
                onModalConcluded: props.onModalConcluded,
            });

            const collapseButton: ICollapseButton = {
                isVisible: true,
                isCollapsing: true,
                style: { mediumGrey },
            };

            return (
                <Table
                    shimmerLabel={props.shimmerLabel ?? 'Searching for your existing reservations'}
                    isFetchingData={props.isFetchingData}
                    rows={props.reservations ?? []}
                    tableColumns={tableColumns}
                    tableName={props.tableName ?? 'My Seat Reservations'}
                    collapseButton={collapseButton}
                    detailsRowStyles={noSpacerStyle}
                />
            );
        } else {
            return <p style={{ marginLeft: '10px' }}>No reservations were found</p>;
        }
    }

    return (
        // TODO: Add sorting on column headers
        <>{renderTableResults()}</>
    );
}
