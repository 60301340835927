import { MessageBar, PrimaryButton, TextField, mergeStyleSets } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import GroupClient, { ICourseDetail } from 'clients/group-client';
import { AuthContext } from 'contexts/auth-context';
import React, {
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

interface IOptionSelectDisplayProps {
    setTrainingCourses: React.Dispatch<SetStateAction<ICourseDetail[]>>;
    trainingCourses: ICourseDetail[];
    trainingCoursesFromEdit: ICourseDetail[];
    isEditing: boolean;
}

export default function CoursePickerDisplay(props: IOptionSelectDisplayProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [chosenCourses, setChosenCourses] = useState<string[]>([]);
    const [foundCourse, setFoundCourse] = useState<ICourseDetail | undefined>(undefined);
    const [searchBarValue, setSearchBarValue] = useState<string | undefined>(undefined);
    const [searchBarMessage, setSearchBarMessage] = useState<string | undefined>('');

    const onHandleDismissItem = (item: string): void => {
        const updatedItems = chosenCourses.filter((i) => i !== item);
        setChosenCourses(updatedItems);
        const updatedCourses = props.trainingCourses.filter((i) => i.name !== item);
        props.setTrainingCourses(updatedCourses);
    };

    const addCourse = (item?: string): void => {
        if (item && !chosenCourses.includes(item) && foundCourse) {
            setChosenCourses([...chosenCourses, item]);
            props.setTrainingCourses([...props.trainingCourses, foundCourse]);
            setFoundCourse(undefined);
            setSearchBarValue('');
            setSearchBarMessage('');
        }
    };

    const chosenItemBar = useMemo(() => {
        return chosenCourses.map((item: string, i) => (
            <div className={styles.messageBarContainer} key={i}>
                <MessageBar
                    dismissButtonAriaLabel='Close'
                    className={styles.messageBar}
                    onDismiss={(): void => {
                        return onHandleDismissItem(item);
                    }}>
                    {item}
                </MessageBar>
                {chosenCourses.length > 1 && chosenCourses[chosenCourses.length - 1] !== item && (
                    <div>Or</div>
                )}
            </div>
        ));
    }, [chosenCourses]);

    const onHandleAddClass = (): void => {
        if (foundCourse) {
            addCourse(foundCourse?.name);
        } else {
            setFoundCourse(undefined);
        }
    };

    const onHandleSearch = async (str?: string): Promise<void> => {
        try {
            if (str) {
                const courseDetails = await GroupClient.getCourseDetails(authContext, [str]);
                // backend literally returns 'string'
                const badStringText = 'string';
                const foundCourse = courseDetails[0];
                if (foundCourse && foundCourse.name !== badStringText) {
                    setFoundCourse(foundCourse);
                    setSearchBarMessage(foundCourse.name);
                } else {
                    setFoundCourse(undefined);
                    setSearchBarMessage('No Course Found');
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onDescriptionChange = useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setSearchBarValue(newValue || '');
            if (newValue && newValue !== '') {
                onHandleSearch(newValue);
            } else {
                setFoundCourse(undefined);
            }
            if (newValue === '') {
                setSearchBarMessage('');
            }
        },
        [foundCourse],
    );

    useEffect(() => {
        if (props.isEditing && props.trainingCoursesFromEdit.length > 0) {
            const names = props.trainingCoursesFromEdit.map((course) => course.name);
            setChosenCourses(names);
        }
    }, [props.trainingCoursesFromEdit]);

    return (
        <>
            <div className={styles.messageBarSection}>{chosenItemBar}</div>

            <div className={styles.searchBarContainer}>
                <div className='course-search-bar'>
                    <TextField
                        value={searchBarValue || ''}
                        onChange={onDescriptionChange}
                        ariaLabel='Course search bar'
                    />

                    <PrimaryButton
                        text='Add Course'
                        disabled={!foundCourse}
                        onClick={onHandleAddClass}
                    />
                </div>

                {searchBarMessage !== '' && (
                    <div
                        className='found-course-container'
                        onClick={(): void => {
                            if (foundCourse) {
                                setSearchBarValue(foundCourse.name);
                            }
                        }}>
                        {searchBarMessage}
                    </div>
                )}
            </div>
        </>
    );
}

const styles = mergeStyleSets({
    messageBarSection: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    searchBarContainer: {
        display: 'block',
        marginBottom: 30,
        alignItems: 'center',
        width: '100%',
        selectors: {
            '.course-search-bar': {
                width: '38%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'relative',
            },
            '.found-course-container': {
                position: 'absolute',
                border: `1px solid ${NeutralColors.gray130}`,
                padding: '4px 12px',
            },
        },
    },
    messageBarContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        selectors: {
            '& div:nth-child(1)': {
                width: 'auto',
            },
            '& div:nth-child(2)': {
                paddingLeft: 10,
                paddingRight: 10,
            },
        },
    },
    messageBar: {
        selectors: {
            '.ms-MessageBar-icon': {
                display: 'none',
            },
        },
    },
});
