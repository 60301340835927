import EmailClient, {
    IEmailRecord,
    IEmailSearchRequest,
    IEmailTemplate,
    ISearchEmailResponse,
    SendResultCode,
} from 'clients/email-client';
import { CustomBreadcrumb, ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import Tabs, { TabbedContent } from 'components/common/tabs';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import React, { useCallback, useContext, useEffect, useRef, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import usePrevious, { useFetchSimple } from 'utils/misc-hooks';
import EmailSearch from 'components/email/email-search';
import EmailTemplates, { AliasBasicInfoMap } from 'components/email/email-templates';
import deepEqual from 'deep-equal';
import useMessageBar from 'components/common/use-message-bar';
import { MessageBarType } from '@fluentui/react';
import { AbortError } from 'components/common/constants';
import { toTitleCase } from 'utils/string-utils';
import EditEmailTemplate from 'components/email/edit-email-template';
import EmailManage from 'components/email/email-manage';

export const ANY = 'ANY';

export const CREATE = 'create';

const tabKeys = {
    search: 'search',
    template: 'template',
    manage: 'manage',
} as const;

export type EmailFilters = {
    id: string;
    to: string;
    subject: string;
    sentAfter: Date | null;
    createdAfter: Date | null;
    senderApplicationId: string;
    sendResultCode: keyof typeof SendResultCode | typeof ANY;
};

export default function EmailDashboard(): JSX.Element {
    const authContext = useContext(AuthContext);
    const breadCrumbContext = useContext(BreadCrumbContext);
    const history = useHistory();
    const { tab } = useParams<{ tab: string }>();
    const { id } = useParams<{ id?: string }>();

    const [emailRecords, setEmailRecords] = useState<IEmailRecord[]>([]);
    const [shouldFetchEmailRecords, setShouldFetchEmailRecords] = useState<boolean>(true);
    const [continuationToken, setContinuationToken] = useState<string>('');
    const [templates, setTemplates] = useState<IEmailTemplate[]>([]);
    const [shouldFetchTemplates, setShouldFetchTemplates] = useState<boolean>(true);
    const [aliasInfoMap, setAliasInfoMap] = useState<AliasBasicInfoMap>({});

    const fetchCount = useRef(0);

    const defaultEmailFilters: EmailFilters = useMemo(
        () => ({
            id: '',
            to: '',
            subject: '',
            sentAfter: null,
            createdAfter: null,
            sendResultCode: 'ANY',
            senderApplicationId: '',
        }),
        [],
    );
    const [emailFilters, setEmailFilters] = useState<EmailFilters>({ ...defaultEmailFilters });
    const prevFilters = usePrevious(emailFilters);
    const abortController = useRef<undefined | AbortController>();

    const onTabChange = useCallback(
        (itemKey?: string): void => {
            if (itemKey) {
                history.push(itemKey);
            }
        },
        [history],
    );

    useEffect(() => {
        const tabCrumb = tab ? { title: toTitleCase(tab), link: `/email/${tab}` } : {};
        const idCrumb = id ? { title: id, link: '' } : {};

        const crumbs = [
            { title: 'Home', link: '/' },
            { title: 'Email', link: '/email/search' },
            tabCrumb,
            idCrumb,
        ].filter((value) => Object.keys(value).length !== 0) as ICustomBreadcrumbItem[];

        breadCrumbContext.setBreadCrumbs(crumbs);
    }, [tab, id]);

    const updateFilters = (property: keyof EmailFilters, value: string | Date | null): void => {
        setEmailFilters((prevFilters) => {
            return { ...prevFilters, [property]: value };
        });
        tableReset();
    };

    const onClearFilters = (): void => {
        if (!deepEqual(emailFilters, defaultEmailFilters)) {
            setEmailFilters(defaultEmailFilters);
            tableReset();
        }
    };

    const tableReset = useCallback(() => {
        setContinuationToken('');
        setEmailRecords([]);
        setShouldFetchEmailRecords(true);
    }, []);

    const onUpdateTemplate = (modifiedTemplate: IEmailTemplate, isNewTemplate?: boolean): void => {
        if (templates.length === 0) {
            setShouldFetchTemplates(true);
        } else {
            setTemplates((prevTemplates) => {
                const existingId = prevTemplates.findIndex(
                    (template) => template.id === modifiedTemplate.id,
                );
                if (existingId === -1) {
                    return [modifiedTemplate, ...prevTemplates];
                }
                prevTemplates[existingId] = modifiedTemplate;
                return prevTemplates;
            });
        }
        if (isNewTemplate) {
            history.push(`/email/${tabKeys.template}`);
        }
    };

    useEffect(() => {
        // when filters change, cancel any in-flight requests
        if (!deepEqual(emailFilters, prevFilters)) {
            abortController.current?.abort();
            abortController.current = new AbortController();
        }
    }, [emailFilters]);

    useEffect(() => {
        setShouldFetchEmailRecords(true);
    }, []);

    const errorMessageBar = useMessageBar({ type: MessageBarType.error });

    useFetchSimple<ISearchEmailResponse>({
        dependencies: [shouldFetchEmailRecords, emailFilters],
        canPerformFetch: shouldFetchEmailRecords,
        fetchFunc: async (): Promise<ISearchEmailResponse> => {
            errorMessageBar.clearMessage();
            fetchCount.current += 1;
            const sendResultCode =
                emailFilters.sendResultCode === 'ANY' ? '' : emailFilters.sendResultCode;
            const searchRequest: IEmailSearchRequest = { ...emailFilters, sendResultCode };

            return EmailClient.getEmailBySearch(
                authContext,
                searchRequest,
                continuationToken,
                abortController.current?.signal,
            );
        },
        onSuccess: (result: ISearchEmailResponse): void => {
            setShouldFetchEmailRecords(false);
            setEmailRecords((currentValue) => [...currentValue, ...result.results]);
            setContinuationToken(result.continuationToken);
        },
        onError: (e): void => {
            if (e.name !== AbortError) {
                console.error(e);
                errorMessageBar.setMessage('An error occurred while loading emails.');
            }
        },
        onFinally: () => (fetchCount.current -= 1),
    });

    if (id && tab !== tabKeys.search) {
        return <EditEmailTemplate onModifyTemplate={onUpdateTemplate} />;
    }

    return (
        <>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <Tabs key={tab} defaultSelectedKey={tab} onChange={onTabChange}>
                <TabbedContent key={tabKeys.search} itemKey={tabKeys.search} tabHeader={'Search'}>
                    <EmailSearch
                        templates={emailRecords}
                        emailFilters={emailFilters}
                        updateFilters={updateFilters}
                        onClearFilters={onClearFilters}
                        continuationToken={continuationToken}
                        isFetchingData={fetchCount.current > 0}
                        errorMessageBar={errorMessageBar}
                        setShouldFetchEmailRecords={setShouldFetchEmailRecords}
                    />
                </TabbedContent>
                <TabbedContent
                    key={tabKeys.template}
                    itemKey={tabKeys.template}
                    tabHeader='Templates'>
                    <EmailTemplates
                        templates={templates}
                        setTemplates={setTemplates}
                        aliasInfoMap={aliasInfoMap}
                        setAliasInfoMap={setAliasInfoMap}
                        shouldFetchTemplates={shouldFetchTemplates}
                        setShouldFetchTemplates={setShouldFetchTemplates}
                    />
                </TabbedContent>
                <TabbedContent key={tabKeys.manage} itemKey={tabKeys.manage} tabHeader='Manage'>
                    <EmailManage />
                </TabbedContent>
            </Tabs>
        </>
    );
}
