import React, { createContext, useReducer, useEffect } from 'react';
import { IChoiceGroupOption } from '@fluentui/react';
import {
    StatusSettings,
    MappingSettings,
} from 'components/staffing/service/service-mapping-constants';

type FilterKeyType = 'mapping' | 'status';

type FilterDataType = IChoiceGroupOption;

type contextStateType = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    mapping: FilterDataType | {};
    // eslint-disable-next-line @typescript-eslint/ban-types
    status: FilterDataType | {};
    mappingChoices: IChoiceGroupOption[];
    statusChoices: IChoiceGroupOption[];
    setFilter: (key: FilterKeyType, value: FilterDataType) => void;
};

export type ServiceMapFilterContextType = {
    mapping: FilterDataType;
    status: FilterDataType;
    mappingChoices: IChoiceGroupOption[];
    statusChoices: IChoiceGroupOption[];
    setFilter: (key: FilterKeyType, value: FilterDataType) => void;
};

type actionType = {
    type: string;
    payload: IChoiceGroupOption | IChoiceGroupOption[];
};

export default function StaffingServiceMappingFiltersProvider(
    props: IStaffingServiceMappingFiltersProviderProps,
): JSX.Element {
    const reducer = (state: contextStateType, action: actionType): contextStateType => {
        let result;
        switch (action.type) {
            case 'status':
            case 'mapping':
            case 'statusChoices':
            case 'mappingChoices':
                result = {
                    ...state,
                    [action.type]: action.payload,
                };
                break;
            default:
                result = state;
                break;
        }
        return result;
    };

    const [contextValue, dispatch] = useReducer(reducer, {
        status: {},
        mapping: {},
        statusChoices: [],
        mappingChoices: [],
        setFilter: setContextFilter,
    });

    const AllMappings: IChoiceGroupOption = {
        key: 'all',
        text: MappingSettings.All,
    };

    const mappingChoices: IChoiceGroupOption[] = [
        AllMappings,
        {
            key: 'mapped',
            text: MappingSettings.Mapped,
        },
        {
            key: 'unmapped',
            text: MappingSettings.UnMapped,
        },
    ];

    const AllStatuses: IChoiceGroupOption = {
        key: 'all',
        text: StatusSettings.All,
    };

    const statusChoices: IChoiceGroupOption[] = [
        AllStatuses,
        {
            key: '1dri',
            text: StatusSettings.OneDRI,
        },
        {
            key: '24dri',
            text: StatusSettings.Two4DRI,
        },
    ];

    useEffect(() => {
        dispatch({ type: 'status', payload: AllStatuses });
        dispatch({ type: 'mapping', payload: AllMappings });
        dispatch({ type: 'statusChoices', payload: statusChoices });
        dispatch({ type: 'mappingChoices', payload: mappingChoices });
    }, []);

    function setContextFilter(key: FilterKeyType, value: FilterDataType): void {
        dispatch({ type: key, payload: value });
    }

    return (
        <StaffingServiceMapFilterContext.Provider
            value={contextValue as ServiceMapFilterContextType}>
            {props.children}
        </StaffingServiceMapFilterContext.Provider>
    );
}

export const StaffingServiceMapFilterContext = createContext<ServiceMapFilterContextType>(null!);

export interface IStaffingServiceMappingFiltersProviderProps {
    children: JSX.Element[] | JSX.Element;
}
