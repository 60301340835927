import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from 'contexts/user-context';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';

export interface PublicTrustLandingPageProps {
    match: {
        params: {
            id: string;
        };
    };
}

export default function PublicTrustLandingPage(props: PublicTrustLandingPageProps): JSX.Element {
    const userContext = useContext(UserContext);
    const [redirectUrl, setRedirectUrl] = useState<string | undefined>();

    useEffect(() => {
        if (userContext.isPublicTrustUserTypesLoaded) {
            if (props.match?.params?.id && props.match?.params?.id !== 'public-trust') {
                setRedirectUrl(`/screening/public-trust/${props.match.params.id}`);
            } else {
                if (userContext.hasPublicTrustUserType(UsGovScreeningUserType.NST)) {
                    setRedirectUrl('/screening/public-trust/manage');
                } else if (userContext.hasPublicTrustUserType(UsGovScreeningUserType.Manager)) {
                    setRedirectUrl('/screening/public-trust/my-org');
                } else if (
                    userContext.hasPublicTrustUserType(UsGovScreeningUserType.ContractOwner)
                ) {
                    setRedirectUrl('/screening/public-trust/my-contracts');
                } else if (userContext.hasPublicTrustUserType(UsGovScreeningUserType.Nominator)) {
                    setRedirectUrl('/screening/public-trust/my-nominees');
                } else {
                    setRedirectUrl('/screening/public-trust/my-screenings');
                }
            }
        }
    }, [userContext.isPublicTrustUserTypesLoaded, props.match?.params?.id]);

    return <>{redirectUrl && <Redirect to={redirectUrl} />}</>;
}
