import React, { ReactNode, useMemo } from 'react';
import { IStackProps, Stack, mergeStyles } from '@fluentui/react';
import { Dictionary } from 'assets/constants/global-constants';

/**
 * To create a single pane sidebar and a single pane content,
 * use the components of this file in the following manner:
 *
 *      <SidebarAndContents>
 *          <SidebarPane>
 *              ... Sidebar components go here.
 *          </SidebarPane>
 *          <ContentPane>
 *              .... Content components go here.
 *          </ContentPane>
 *      </SidebarAndContents>
 *
 * To create a multi pane sidebar and a multi pane content,
 * use the components of this file in the following manner:
 *
 *      <SidebarAndContents>
 *          <>
 *              <SidebarPane>
 *                  ... Components of the first sidebar pane go here.
 *              </SidebarPane>
 *              <SidebarPane>
 *                  ... Components of the second sidebar pane go here.
 *              </SidebarPane>
 *          </>
 *          <>
 *              <ContentPane>
 *                  ... Components of the first content pane go here.
 *              </ContentPane>
 *              <ContentPane>
 *                  ... Components of the second content pane go here.
 *              </ContentPane>
 *          </>
 *      </SidebarAndContents>
 */

type StyleDictionary = Dictionary<string | undefined>;

interface SidebarAndContentsProps {
    children: Array<JSX.Element>;
}

interface ISidebarPaneProps {
    children: ReactNode;
    sidebarPaneOuterStyle?: StyleDictionary;
    sidebarPaneInnerStyles?: StyleDictionary;
}

interface IContentsPaneProps {
    children: ReactNode;
    contentPaneOuterStyle?: StyleDictionary;
}

const stackProps: IStackProps = {
    horizontalAlign: 'start',
};

const sidebarPaneOuterStyle = (
    moreStyles: Dictionary<string | number | undefined> | undefined = {},
): string =>
    mergeStyles({
        width: '320px',
        flexGrow: '0',
        flexShrink: '0',
        padding: '9px',
        ...moreStyles,
        selectors: {
            '@media(max-width: 420px)': {
                width: '100%',
                padding: '0',
            },
        },
    });

const sidebarsOuterStyle = mergeStyles({
    marginBottom: '20px',
});

const contentPaneOuterStyle = (
    moreStyles: Dictionary<string | number | undefined> | undefined = {},
): string =>
    mergeStyles({
        padding: '9px',
        width: 'calc(100% - 330px)',
        ...moreStyles,
        selectors: {
            '@media(max-width: 420px)': {
                width: '100%',
            },
        },
    });

const sidebarPaneInnerStyle = (moreStyles: StyleDictionary | undefined) =>
    mergeStyles({
        backgroundColor: 'white',
        padding: '15px',
        position: 'relative',
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
        borderRadius: '2px',
        ...moreStyles,
    });

const reflowAtHighZoom = () =>
    mergeStyles({
        selectors: {
            '@media(max-width: 420px)': {
                display: 'block',
                width: '100%',
            },
        },
    });

export default function SidebarAndContents(props: SidebarAndContentsProps): JSX.Element {
    return (
        <Stack horizontal {...stackProps} className={reflowAtHighZoom()}>
            <div className={sidebarsOuterStyle}>
                {props.children[0]} {/* This is the sidebar */}
            </div>
            {props.children[1]} {/* This is the content */}
            {/**
             * It has been brought to our attention that at this point,
             * the component <SidebarAndContents> has been neutered and
             * as a result cannot have any more children. Therefore, if
             * you wish to have multi-pane sidebar (or multi-pane content),
             * please use one instance of component <SidebarPane>
             * (<ContentPane>) for each desired sidebar pane (content pane)
             * and place them as children of props.children[0] (props.children[1]).
             */}
        </Stack>
    );
}

export function SidebarPane(props: ISidebarPaneProps) {
    const outerStyle = useMemo(() => sidebarPaneOuterStyle(props.sidebarPaneOuterStyle), [
        props.sidebarPaneOuterStyle,
    ]);
    const innerStyle = useMemo(() => sidebarPaneInnerStyle(props.sidebarPaneInnerStyles), [
        props.sidebarPaneInnerStyles,
    ]);
    return (
        <div className={outerStyle}>
            <div className={innerStyle}>{props.children}</div>
        </div>
    );
}

export function ContentPane(props: IContentsPaneProps) {
    const contentPaneStyle = useMemo(() => contentPaneOuterStyle(props.contentPaneOuterStyle), [
        props.contentPaneOuterStyle,
    ]);
    return <div className={contentPaneStyle}>{props.children}</div>;
}

/** *****************************************
 *  You may use <SidebarContainer> as parent
 *  component for instances of <SidebarPane>.
 *  It provides default styling.
 */
interface ISidebarContainerProps {
    children?: ReactNode; // Preferably only use <SidebarPane>
}

export function SidebarContainer(props: ISidebarContainerProps): JSX.Element {
    return <div className={sidebarContainerStyles}>{props.children}</div>;
}

const sidebarContainerStyles = mergeStyles({
    selectors: {
        '> *': {
            marginTop: '5px',
        },
        '> *:first-child': {
            marginTop: '5px',
        },
    },
});
