import config from 'environments/environment';
import { GetAadHttpOptions, addContinuationToken } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import ClientUtils from 'clients/client-utils';
import { Dictionary } from 'assets/constants/global-constants';
import { IPagedResults, IPagedItems } from 'clients/http-options';

const { eligibilityServiceConfig } = config;

/*
TODO: Do we need to add this to httpOptions?
const xScopeHeader: HeadersInit = {
    'x-scope': 'ags',
}
*/

export default class EligibilityClient {
    static async getPersonnelAssignment(
        authContext: IAuthContext,
        id: string,
    ): Promise<IPersonnelAssignment> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            eligibilityServiceConfig.aadScopes,
        );
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelAssignmentEndpoint +
            id;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else if (result.status === 404) {
            throw 'Personnel with the specified ID was not found';
        } else {
            throw result;
        }
    }

    static async createPersonnelAssignment(
        authContext: IAuthContext,
        request: IPersonnelAssignmentRequest,
    ): Promise<IPersonnelAssignment> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            eligibilityServiceConfig.aadScopes,
        );
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(request);
        const url =
            eligibilityServiceConfig.baseUrl + eligibilityServiceConfig.personnelAssignmentEndpoint;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async deletePersonnelAssignment(
        authContext: IAuthContext,
        id: string,
    ): Promise<IAttributeAssignment> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            eligibilityServiceConfig.aadScopes,
        );
        httpOptions.method = 'DELETE';
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelAssignmentEndpoint +
            id;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async getAssignmentAttributes(
        authContext: IAuthContext,
        id: string,
    ): Promise<IAttributeAssignment> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            eligibilityServiceConfig.aadScopes,
        );
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelAssignmentEndpoint +
            id +
            '/attributes';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else if (result.status === 404) {
            throw 'No assignment attributes found for the specified id';
        } else {
            throw result;
        }
    }

    static async getAssignmentEligibilities(
        authContext: IAuthContext,
        id: string,
    ): Promise<IEligibilityAssignment> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            eligibilityServiceConfig.aadScopes,
        );
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelAssignmentEndpoint +
            id +
            '/eligibilities';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else if (result.status === 404) {
            throw 'No assignment eligibilities found for the specified id';
        } else {
            throw result;
        }
    }

    static async hasPersonnelEligibility(
        authContext: IAuthContext,
        personnelId: string,
        eligibilityCode: string,
    ): Promise<IEligibilityAssignment> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            eligibilityServiceConfig.aadScopes,
        );
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelAssignmentEndpoint +
            personnelId +
            '/eligibilities/' +
            eligibilityCode;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async createEligibilityRequest(
        authContext: IAuthContext,
        eligibilityId: string,
        justification: string,
    ): Promise<IEligibilityRequest> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify({ justification });

        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.eligibilitiesEndpoint +
            eligibilityId +
            '/requests';
        const result = await fetch(url, httpOptions);
        if (result.status === 200 || result.status === 201) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async getAvailableEligibilitiesByPersonnelId(
        authContext: IAuthContext,
        id: string,
    ): Promise<IEligibility[]> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            eligibilityServiceConfig.aadScopes,
        );
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelAssignmentEndpoint +
            id +
            '/available/eligibilities/';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async addPersonnelAttribute(
        authContext: IAuthContext,
        id: string,
        attributeId: string,
        endDate?: Date | null,
    ): Promise<IPersonnelAssignment> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            eligibilityServiceConfig.aadScopes,
        );
        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify({
            attributeId: attributeId,
            endDate: endDate,
        });
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelAssignmentEndpoint +
            id +
            '/attribute';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async addPersonnelEligibility(
        authContext: IAuthContext,
        id: string,
        eligibilityId: string,
    ): Promise<IPersonnelAssignment> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            eligibilityServiceConfig.aadScopes,
        );
        httpOptions.method = 'PUT';
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelAssignmentEndpoint +
            id +
            '/eligibility';
        httpOptions.body = JSON.stringify({
            eligibilityId: eligibilityId,
        });
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async deletePersonnelAttribute(
        authContext: IAuthContext,
        personnelId: string,
        attributeId: string,
    ): Promise<IPersonnelAssignment> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            eligibilityServiceConfig.aadScopes,
        );

        httpOptions.method = 'DELETE';
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelAssignmentEndpoint +
            personnelId +
            '/attributes/' +
            attributeId;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async deletePersonnelEligibility(
        authContext: IAuthContext,
        id: string,
        eligibilityId: string,
    ): Promise<IPersonnelAttributes> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            eligibilityServiceConfig.aadScopes,
        );
        httpOptions.method = 'DELETE';
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelAssignmentEndpoint +
            id +
            '/eligibilities/' +
            eligibilityId;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    // Eligibility Specific requests
    static async getAadHttpOptions(authContext: IAuthContext): Promise<RequestInit> {
        return GetAadHttpOptions(authContext, eligibilityServiceConfig.aadScopes);
    }

    static async getPersonnelAttributes(
        authContext: IAuthContext,
        personnelId: string,
    ): Promise<IPersonnelAttributes> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelAttributesEndpoint.replace('{id}', personnelId);
        httpOptions.method = 'GET';
        return ClientUtils.doHttpRequest<IPersonnelAttributes>(url, httpOptions);
    }

    static async getActiveEligibilityRequestForPersonnel(
        authContext: IAuthContext,
        eligibilityId: string,
    ): Promise<IPersonnelAttributes> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.eligibilitiesEndpoint +
            eligibilityId +
            '/requests/active';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async getPersonnelAttributesMap(
        authContext: IAuthContext,
        personnelId: string,
    ): Promise<IPersonnelAttributesMap> {
        const ret = await this.getPersonnelAttributes(authContext, personnelId);
        return personnelAttributeListToMap(ret);
    }

    static async getPersonnelEligibilities(
        authContext: IAuthContext,
        personnelId: string,
    ): Promise<IPersonnelEligibilities> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelEligibilitiesEndpoint.replace('{id}', personnelId);
        httpOptions.method = 'GET';
        return ClientUtils.doHttpRequest<IPersonnelEligibilities>(url, httpOptions);
    }

    static async getPersonnelAvailableEligibilities(
        authContext: IAuthContext,
        personnelId: string,
    ): Promise<IEligibility[]> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.personnelAvailableEligibilitiesEndpoint.replace(
                '{id}',
                personnelId,
            );
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async getEligibilities(authContext: IAuthContext): Promise<IEligibility[]> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        const url =
            eligibilityServiceConfig.baseUrl + eligibilityServiceConfig.eligibilitiesEndpoint;
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async getEligibilityById(authContext: IAuthContext, id: string): Promise<IEligibility> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        const url =
            eligibilityServiceConfig.baseUrl + eligibilityServiceConfig.eligibilitiesEndpoint + id;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async createEligibility(
        authContext: IAuthContext,
        elg: IEligibilityHttpRequest,
    ): Promise<IEligibility> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = this._createEligibilityJsonRequest(elg);
        const url =
            eligibilityServiceConfig.baseUrl + eligibilityServiceConfig.eligibilitiesEndpoint;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async updateEligibility(
        authContext: IAuthContext,
        elg: IEligibilityHttpRequest,
    ): Promise<IEligibility> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        httpOptions.method = 'PUT';
        httpOptions.body = this._createEligibilityJsonRequest(elg);
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.eligibilitiesEndpoint +
            elg.id;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async deleteEligibility(authContext: IAuthContext, id: string): Promise<boolean> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        httpOptions.method = 'DELETE';
        const url =
            eligibilityServiceConfig.baseUrl + eligibilityServiceConfig.eligibilitiesEndpoint + id;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return true;
        } else {
            throw result;
        }
    }

    static async getEligibilityRequests(
        authContext: IAuthContext,
        eligibilityId?: string,
        continuationToken?: string,
    ): Promise<IPagedResults<IEligibilityRequest>> {
        let httpOptions = await this.getAadHttpOptions(authContext);
        httpOptions = addContinuationToken(httpOptions, continuationToken);
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.eligibilitiesEndpoint +
            eligibilityId +
            '/requests';
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw 'The specified eligibility request was not found';
            default:
                throw result;
        }
    }

    static async getEligibilityRequestCounts(
        authContext: IAuthContext,
        ids: string[],
    ): Promise<Dictionary<number>> {
        if (ids?.length === 0) return {};
        const httpOptions = await this.getAadHttpOptions(authContext);
        httpOptions.body = JSON.stringify({ eligibilityIds: ids });
        httpOptions.method = 'POST';
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.eligibilitiesEndpoint +
            'requests/count';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async manageEligibilityRequest(
        authContext: IAuthContext,
        params: IManageEligibilityParams,
        action: 'approve' | 'deny',
    ): Promise<IEligibilityRequest> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        httpOptions.body = JSON.stringify(params.requestBody);
        httpOptions.method = 'PUT';
        let url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.eligibilityRequestEndpoint
                .replace('{id}', params.eligibilityId)
                .replace('{requestId}', params.eligibilityRequestId);
        switch (action) {
            case 'approve':
            case 'deny':
                url += action;
                break;
            default:
                console.error(`Invalid value "${action}" provided for parameter 'action'.`);
                throw 'Error encountered';
        }
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async getAttributes(authContext: IAuthContext): Promise<IAttribute[]> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        const url = eligibilityServiceConfig.baseUrl + eligibilityServiceConfig.attributesEndpoint;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async getAttributeById(authContext: IAuthContext, id: string): Promise<IAttribute> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        const url =
            eligibilityServiceConfig.baseUrl + eligibilityServiceConfig.attributesEndpoint + id;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async createAttribute(
        authContext: IAuthContext,
        attr: IAttributeHttpRequest,
    ): Promise<IAttribute> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = this._createAttributeJsonRequest(attr);
        const url = eligibilityServiceConfig.baseUrl + eligibilityServiceConfig.attributesEndpoint;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async updateAttribute(
        authContext: IAuthContext,
        attr: IAttributeHttpRequest,
    ): Promise<IAttribute> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        httpOptions.body = this._createAttributeJsonRequest(attr);
        httpOptions.method = 'PUT';
        const url =
            eligibilityServiceConfig.baseUrl +
            eligibilityServiceConfig.attributesEndpoint +
            attr.id;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async deleteAttribute(authContext: IAuthContext, id: string): Promise<boolean> {
        const httpOptions = await this.getAadHttpOptions(authContext);
        httpOptions.method = 'DELETE';
        const url =
            eligibilityServiceConfig.baseUrl + eligibilityServiceConfig.attributesEndpoint + id;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return true;
        } else {
            throw result;
        }
    }

    // Mandatory properties for CREATE/UPDATE request are:
    //     eligibilityCode, eligibilityName, requiredAttributes and autoProvisionRequest.
    private static _createEligibilityJsonRequest(elg: IEligibilityHttpRequest): string {
        return JSON.stringify({
            eligibilityCode: elg.eligibilityCode,
            eligibilityName: elg.eligibilityName,
            requiredAttributes: elg.requiredAttributes,
            autoAssign: elg.autoAssign,
            userCanRequest: elg.userCanRequest,
            autoProvisionRequest: elg.autoProvisionRequest,
        });
    }

    // We only need attributeCode and attributeDesc when sending CREATE request.
    // "id" becomes necessary when updating.
    private static _createAttributeJsonRequest(attr: IAttributeHttpRequest): string {
        return JSON.stringify({
            id: attr.id,
            attributeCode: attr.attributeCode,
            attributeDesc: attr.attributeDesc,
        });
    }

    static async getEligibilityAuditLog(
        authContext: IAuthContext,
        params: EligibilityAuditLogRequestParamType,
        continuationToken?: string,
    ): Promise<IPagedItems<IEligibilityAuditLog>> {
        return this._getEligibilityLog(
            authContext,
            params,
            eligibilityServiceConfig.eligibilityAutditLogEndpoint,
            continuationToken,
        );
    }

    static async getAllEligibilityAuditLog(
        authContext: IAuthContext,
        params: EligibilityAuditLogRequestParamType,
    ): Promise<IEligibilityAuditLog[]> {
        let ret: IEligibilityAuditLog[] = [];
        let continuationToken: string | undefined = '';

        let pagedItems: IPagedItems<IEligibilityAuditLog>;
        do {
            pagedItems = await this.getEligibilityAuditLog(authContext, params, continuationToken);
            ret = ret.concat(pagedItems.items);
            continuationToken = pagedItems.continuationToken;
        } while (continuationToken && continuationToken !== 'null' && pagedItems.items?.length);

        return ret;
    }

    static async getEligibilityRequestAuditLog(
        authContext: IAuthContext,
        params: EligibilityAuditLogRequestParamType,
        continuationToken?: string,
    ): Promise<IPagedItems<IEligibilityRequestAuditLog>> {
        return this._getEligibilityLog(
            authContext,
            params,
            eligibilityServiceConfig.eligibilityRequestAutditLogEndpoint,
            continuationToken,
        );
    }

    static async getAllEligibilityRequestAuditLog(
        authContext: IAuthContext,
        params: EligibilityAuditLogRequestParamType,
    ): Promise<IEligibilityRequestAuditLog[]> {
        let ret: IEligibilityRequestAuditLog[] = [];
        let continuationToken: string | undefined = '';

        let pagedItems: IPagedItems<IEligibilityRequestAuditLog>;
        do {
            pagedItems = await this.getEligibilityRequestAuditLog(
                authContext,
                params,
                continuationToken,
            );
            ret = ret.concat(pagedItems.items);
            continuationToken = pagedItems.continuationToken;
        } while (continuationToken && continuationToken !== 'null' && pagedItems.items?.length);

        return ret;
    }

    private static async _getEligibilityLog(
        authContext: IAuthContext,
        params: EligibilityAuditLogRequestParamType,
        genericUrl: string,
        continuationToken?: string,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any> {
        let httpOptions = await this.getAadHttpOptions(authContext);
        httpOptions = addContinuationToken(httpOptions, continuationToken);

        const url =
            eligibilityServiceConfig.baseUrl + genericUrl.replace(/{id}/, params.eligibilityId);

        const urlParams = new URLSearchParams();
        params.startDate && urlParams.append('startDate', params.startDate.toString());
        params.endDate && urlParams.append('endDate', params.endDate.toString());

        const result = await fetch(`${url}?${urlParams}`, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }
}

function personnelAttributeListToMap(attrList: IPersonnelAttributes): IPersonnelAttributesMap {
    const ret: IPersonnelAttributesMap = {};
    attrList.attributes.forEach((attribute: IPersonnelAttribute) => {
        ret[attribute.attributeCode] = attribute;
    });
    return ret;
}

export const PersonnelAttributeCodes = {
    USCitizenship: 'CITIZENSHIP_US',
    CloudScreen: 'CLOUDSCREEN',
    CJIS: 'SCREENING_CJIS',
};

export type IPersonnelAttributesMap = { [key: string]: IPersonnelAttribute };

export interface IPersonnelAttributes {
    id: string;
    attributes: IPersonnelAttribute[];
}

export interface IPersonnelAttribute {
    attributeCode: string;
    attributeDesc: string;
    startDate: string;
    endDate: string;
    assignedAt: string;
}

export interface IPersonnelEligibilities {
    id: string;
    eligibilities: IPersonnelEligibility[];
}

export interface IPersonnelEligibility {
    eligibilityCode: string;
    eligibilityName: string;
    startDate: string;
    endDate: string;
    assignedAt: string;
    assignedBy: string;
}

export interface IEligibility {
    id: string;
    eligibilityCode: string;
    eligibilityName: string;
    requiredAttributes: string[][];
    autoAssign: boolean;
    userCanRequest: boolean;
    autoProvisionRequest: boolean;
    createdAt: string;
    createdBy: string;
    lastModifiedAt: string;
    lastModifiedBy: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _etag?: string;
}

export interface IEligibilityHttpRequest {
    id?: string;
    eligibilityCode: string;
    eligibilityName: string;
    requiredAttributes: string[][];
    autoAssign?: boolean;
    userCanRequest?: boolean;
    autoProvisionRequest: boolean;
}

export interface IAttribute {
    id: string;
    attributeCode: string;
    attributeDesc: string;
    createdAt: string;
    createdBy: string;
    lastModifiedAt: string;
    lastModifiedBy: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _etag?: string;
}

interface IAttributeHttpRequest {
    id?: string;
    attributeCode: string;
    attributeDesc: string;
}

export interface IEligibilityRequest {
    id: string;
    eligibilityId: string;
    personnelId: string;
    justification: string;
    status: string;
    createdAt: number;
}

interface IManageEligibilityParams {
    eligibilityId: string;
    eligibilityRequestId: string;
    requestBody: {
        comment: string | undefined;
    };
}

export type IEligibilityAuditLog = IEligibilityCreateEventLog | IEligibilityModifyEventLog;

interface IEligibilityCreateEventLog {
    date: number;
    createdBy: string;
    eligibilityName: string;
    eligibilityCode: string;
    eventType: 'EligibilityCreated';
}

interface IEligibilityModifyEventLog {
    date: number;
    modifiedBy: string;
    eligibilityName: string;
    eligibilityCode: string;
    eventType: 'EligibilityModified';
}

export interface IEligibilityRequestAuditLog {
    date: number;
    comment: string;
    eventType: EligibilityRequestEventType;
    requestedBy: string;
    eligibilityCode: string;
    eligibilityName: string;

    deniedBy?: string;
    approvedBy?: string;
    justification?: string;
}

type EligibilityRequestEventType =
    | 'EligibilityRequestApproved'
    | 'EligibilityRequestApprovedAutomatic'
    | 'EligibilityRequestDenied'
    | 'EligibilityRequestSubmitted';

type EligibilityAuditLogRequestParamType = {
    eligibilityId: string;
    startDate?: number;
    endDate?: number;
};

export interface IAttributeAssignment {
    id: string;
    // Keep this comment to maintain a reference to the Angular app - Was IAttributeDetail[];
    attributes: IAttributeAssignmentDetail[];
}
export interface IAttributeAssignmentDetail {
    // Keep this comment to maintain a reference to the Angular app - Was: IAttributeDetail
    attributeCode: string;
    attributeDesc: string;
    startDate: string;
    endDate: string;
    assignedAt: string;
}

export interface IEligibilityAssignment {
    id: string;
    // Keep this comment to maintain a reference to the Angular app - Was: IEligibilityDetail[];
    eligibilities: IEligibilityAssignmentDetail[];
}

export interface IEligibilityAssignmentDetail {
    // Keep this comment to maintain a reference to the Angular app - Was: IEligibilityDetail
    eligibilityCode: string;
    eligibilityName: string;
    startDate: string;
    endDate: string;
    assignedAt: string;
}

export interface IPersonnelAssignment {
    id: string;
    personnelType: string;
    attributes: IPersonnelAttribute[];
    eligibilities: IPersonnelEligibility[];
    createdAt: string;
}

export interface IPersonnelAttribute {
    attributeId: string;
    startDate: string;
    endDate: string;
    assignedBy: string;
    assignedOn: string;
}

export interface IPersonnelEligibility {
    eligibilityId: string;
    startDate: string;
    assignedBy: string;
    assignedOn: string;
}

export interface IPersonnelAssignmentRequest {
    personnelId: string;
    personnelType: PersonnelTypes;
}

export enum PersonnelTypes {
    EMPLOYEE = 'EMPLOYEE',
    VISITOR = 'VISITOR',
}
