import React from 'react';
import { IconNames, xxxLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { IEmailTemplate } from 'clients/email-client';
import { TableCell } from 'components/common/table';
import { ActionButton, Stack, IColumn } from '@fluentui/react';
import { dateToFormattedDateTimeStringFromSeconds } from 'utils/time-utils';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { returnEmailUserName } from 'utils/string-utils';
import DeleteTemplateModalButton from 'components/email/buttons/delete-template-modal-button';
import { AliasBasicInfoMap } from 'components/email/email-templates';
import { Link } from 'react-router-dom';
import { globalStyles } from 'assets/styles/global-styles';
import { ISortableColumns } from 'utils/sort-utils';
import CopyToClipboardButton from 'components/common/buttons/copy-to-clipboard-button';
import Spacer from 'components/common/spacer';
import { nameof } from 'utils/object-utils';

interface ITableColumnsEmailTemplate extends ISortableColumns {
    handleDeleteTemplate: (id: string) => void;
    aliasInfoMap: AliasBasicInfoMap;
}

export default function tableColumnsEmailTemplate(params: ITableColumnsEmailTemplate): IColumn[] {
    const EmailTemplateNames = {
        name: 'Template ID',
        templateLabel: 'Template Label',
        lastModifiedBy: 'Last Modified By',
        lastModifiedTimeStamp: 'Last Modified (Local)',
        actions: 'Actions',
    } as const;

    const columnWidths = {
        name: 100,
        label: 100,
        lastModifiedBy: 60,
        lastModifiedTimeStamp: 60,
        actions: 100,
    };

    return [
        {
            key: EmailTemplateNames.name,
            name: EmailTemplateNames.name,
            ariaLabel: EmailTemplateNames.name,
            minWidth: columnWidths.name,
            maxWidth: columnWidths.name * xxxLargeMaxWidthCoeff,

            onRender: (row: IEmailTemplate): JSX.Element => (
                <TableCell key={`${row.id}-${EmailTemplateNames.name}`}>
                    <Link
                        to={(location: any) => `${location.pathname}/${row.id}`}
                        className={globalStyles.link}>
                        {row.id}
                    </Link>
                    <Spacer marginLeft={5} display={'inline'} />
                    <CopyToClipboardButton text={row.id} />
                </TableCell>
            ),
        },
        {
            key: EmailTemplateNames.templateLabel,
            name: EmailTemplateNames.templateLabel,
            ariaLabel: EmailTemplateNames.templateLabel,
            minWidth: columnWidths.label,
            maxWidth: columnWidths.label * xxxLargeMaxWidthCoeff,
            isSorted: params.sortColumn === nameof<typeof EmailTemplateNames>('templateLabel'),
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(nameof<typeof EmailTemplateNames>('templateLabel'));
            },
            onRender: (row: IEmailTemplate): JSX.Element => (
                <TableCell key={`${row.id}-${EmailTemplateNames.templateLabel}`}>
                    {row.templateLabel}
                </TableCell>
            ),
        },
        {
            key: EmailTemplateNames.lastModifiedBy,
            name: EmailTemplateNames.lastModifiedBy,
            ariaLabel: EmailTemplateNames.lastModifiedBy,
            minWidth: columnWidths.lastModifiedBy,
            maxWidth: columnWidths.lastModifiedBy * xxxLargeMaxWidthCoeff,
            isSorted: params.sortColumn === nameof<typeof EmailTemplateNames>('lastModifiedBy'),
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(nameof<typeof EmailTemplateNames>('lastModifiedBy'));
            },
            onRender: (row: IEmailTemplate): JSX.Element => {
                const alias = returnEmailUserName(row.lastModifiedBy);
                return (
                    <TableCell key={`${row.id}-${EmailTemplateNames.lastModifiedBy}`}>
                        <EmployeeBasicHoverCard
                            defaultDisplayName={alias}
                            employeeBasicData={params.aliasInfoMap[alias]}
                            showDefaultMiniCard={true}
                            base64ImageString={params.aliasInfoMap[alias]?.base64ImageString ?? ' '}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: EmailTemplateNames.lastModifiedTimeStamp,
            name: EmailTemplateNames.lastModifiedTimeStamp,
            ariaLabel: EmailTemplateNames.lastModifiedTimeStamp,
            minWidth: columnWidths.lastModifiedTimeStamp,
            maxWidth: columnWidths.lastModifiedTimeStamp * xxxLargeMaxWidthCoeff,
            isSorted:
                params.sortColumn === nameof<typeof EmailTemplateNames>('lastModifiedTimeStamp'),
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(
                    nameof<typeof EmailTemplateNames>('lastModifiedTimeStamp'),
                );
            },
            onRender: (row: IEmailTemplate): JSX.Element => (
                <TableCell key={`${row.id}-${EmailTemplateNames.lastModifiedTimeStamp}`}>
                    {dateToFormattedDateTimeStringFromSeconds(row.lastModifiedTimeStamp)}
                </TableCell>
            ),
        },
        {
            key: EmailTemplateNames.actions,
            name: EmailTemplateNames.actions,
            ariaLabel: EmailTemplateNames.actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions * xxxLargeMaxWidthCoeff,
            onRender: (row: IEmailTemplate): JSX.Element => (
                <TableCell key={`${row.id}-${EmailTemplateNames.actions}`}>
                    <Stack horizontal>
                        <Link to={(location: any) => `${location.pathname}/${row.id}`}>
                            <ActionButton iconProps={{ iconName: IconNames.Edit }}>
                                Edit
                            </ActionButton>
                        </Link>
                        <DeleteTemplateModalButton
                            templateId={row.id}
                            templateName={row.templateLabel}
                            handleDeleteTemplate={params.handleDeleteTemplate}
                        />
                    </Stack>
                </TableCell>
            ),
        },
    ];
}
