import React from 'react';
import { mergeStyles, mergeStyleSets } from '@fluentui/react';
import EmployeeCard from 'components/common/employee/employee-card';
import { PublicTrustRequestTypes } from 'components/screening/public-trust/public-trust-screening-result';
import { separateWordsByCapitalLetter } from 'utils/string-utils';
import { ScreeningRequestTypesLabels } from 'components/screening/common/common-constants';
import {
    SuitabilityAgencies,
    SuitabilityLevels,
} from 'components/personnel-profile/suitability/profile-suitability-types';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/suitability/profile-suitability-utils';

export interface FteCandidateNominationStepReviewProps {
    employee: {
        alias: string;
        department: string;
        email: string;
        displayName: string;
        jobTitle: string;
        managerName: string;
        managerAlias: string;
        image?: string;
        oid?: string;
    };
    contractID: string;
    businessJustification: string;
    requestType: PublicTrustRequestTypes;
    requestAgency: SuitabilityAgencies;
    suitabilityLevel: string;
    isPIVRequired: boolean;
}

export default function FteCandidateNominationStepReviewPublicTrust(
    props: FteCandidateNominationStepReviewProps,
): JSX.Element {
    return (
        <div className={styles.root}>
            <div className={mergeStyles({ marginTop: UNIFORM_BOTTOM_MARGIN })}>
                <div className={styles.cardWrapperPanel}>
                    <EmployeeCard
                        employee={{
                            alias: props.employee.alias,
                            displayName: props.employee.displayName,
                            email: props.employee.email,
                            department: props.employee.department,
                            jobTitle: props.employee.jobTitle,
                            oid: props.employee.oid,
                        }}
                        managerAlias={props.employee.managerAlias}
                        manager={props.employee.managerName}
                        displayActions={false}
                    />
                </div>
                <div className={styles.contactDetailsWrapper}>
                    <b>Request details</b>
                    <div className={styles.row}>
                        <div className={styles.key}>Contract ID</div>
                        <div className={styles.value}>{props.contractID}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Request type</div>
                        <div className={styles.value}>
                            {ScreeningRequestTypesLabels[
                                props.requestType as keyof typeof ScreeningRequestTypesLabels
                            ] ?? separateWordsByCapitalLetter(props.requestType)}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Request agency</div>
                        <div className={styles.value}>
                            {getAgencyEnumValueFromKey(props.requestAgency, true)}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Suitability level</div>
                        <div className={styles.value}>
                            {SuitabilityLevels[
                                props.suitabilityLevel as keyof typeof SuitabilityLevels
                            ] || props.suitabilityLevel}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>PIV required</div>
                        <div className={styles.value}>{props.isPIVRequired ? 'Yes' : 'No'}</div>
                    </div>
                </div>
            </div>
            <div className={styles.secondRow}>
                <div className={styles.requestDetailsWrapper}>
                    <b>Business justification</b>
                    <div className={styles.row}>{props.businessJustification}</div>
                </div>
            </div>
        </div>
    );
}

const UNIFORM_WIDTH = 380;

const UNIFORM_RIGHT_MARGIN = 40;

const UNIFORM_BOTTOM_MARGIN = 15;

const styles = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    firstRow: {
        display: 'flex',
    },
    secondRow: {
        display: 'flex',
    },
    cardWrapperPanel: {
        marginBottom: UNIFORM_BOTTOM_MARGIN,
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
    },
    cardWrapper: {
        marginRight: UNIFORM_RIGHT_MARGIN,
        marginBottom: UNIFORM_BOTTOM_MARGIN,
        width: UNIFORM_WIDTH,
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
    },
    contactDetailsWrapper: {
        width: UNIFORM_WIDTH,
        marginBottom: UNIFORM_BOTTOM_MARGIN,
        marginTop: 35,
    },
    requestDetailsWrapper: {
        width: UNIFORM_WIDTH,
        marginTop: 10,
    },
    row: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '4px 0',
    },
    key: {
        width: 140,
        fontWeight: '500',
    },
    value: {
        width: 'calc(100% - 140px)',
    },
});
