import config from 'environments/environment';
import { IAuthContext } from 'contexts/auth-context';

export const AUTH_PREFIX = 'Bearer ';
export const JSON_CONTENT_TYPE = 'application/json';
export const TEXT_PLAIN_TYPE = 'text/plain';
export const BLOB_CONTENT_TYPE = 'blob';

export enum HttpContentTypeEnum {
    ApplicationJson = 'application/json',
    TextPlain = 'text/plain',
    Blob = 'blob',
}

export async function GetDefaultHttpOptions(
    context: IAuthContext,
    continuationToken?: string,
    contextType?: HttpContentTypeEnum,
): Promise<RequestInit> {
    const token = await context.getToken();
    return {
        headers: {
            Authorization: AUTH_PREFIX + token,
            'Content-Type': contextType?.toString() ?? JSON_CONTENT_TYPE,
            'x-continuation-token': continuationToken ? continuationToken : '',
        },
    };
}

export async function GetAadHttpOptions(
    context: IAuthContext,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    aadScopes: any,
    contentType?: string,
    continuationToken?: string,
): Promise<RequestInit> {
    const token = await context.getToken(aadScopes);
    return {
        headers: {
            Authorization: AUTH_PREFIX + token,
            'Content-Type': contentType ? contentType : JSON_CONTENT_TYPE,
            'x-continuation-token': continuationToken ? continuationToken : '',
        },
    };
}

export async function GetMultiPartHttpOptions(context: IAuthContext): Promise<RequestInit> {
    const token = await context.getToken();
    return {
        headers: {
            Authorization: AUTH_PREFIX + token,
        },
    };
}

export async function PutAadHttpBlobOptions(
    context: IAuthContext,
    aadScopes: string[],
): Promise<RequestInit> {
    const token = await context.getToken(aadScopes);
    return {
        headers: {
            Authorization: AUTH_PREFIX + token,
        },
    };
}

export async function GetBlobResponseType(context: IAuthContext): Promise<RequestInit> {
    const token = await context.getToken();
    return {
        headers: {
            Authorization: AUTH_PREFIX + token,
            responseType: 'blob',
        },
    };
}

export async function GetGraphHttpOptions(context: IAuthContext): Promise<RequestInit> {
    if (!config.aadConfig.scopes.graph) {
        throw new Error('Unable to load environment configuration for AAD Graph');
    }

    const token = await context.getToken(config.aadConfig.scopes.graph);
    return {
        headers: {
            Authorization: AUTH_PREFIX + token,
            'Content-Type': JSON_CONTENT_TYPE,
        },
    };
}

export async function GetDocumentByDocumentTokenHttpOptions(
    context: IAuthContext,
    documentToken: string,
    contentType: string,
): Promise<RequestInit> {
    const authToken = await context.getToken(config.documentsServiceConfig.aadScopes);
    return {
        headers: {
            Authorization: AUTH_PREFIX + authToken,
            'document-token': documentToken,
            'Content-Type': contentType,
        },
    };
}

export function addHttpHeaders(httpOptions: RequestInit, headers: HeadersInit): RequestInit {
    return {
        ...httpOptions,
        headers: {
            ...httpOptions?.headers,
            ...headers,
        },
    };
}

export function addContinuationToken(
    httpOptions: RequestInit,
    continuationToken?: string,
): RequestInit {
    if (!continuationToken) return httpOptions;
    return {
        ...httpOptions,
        headers: {
            ...httpOptions?.headers,
            'x-continuation-token': continuationToken ?? '',
        },
    };
}

export interface IPagedResults<T> {
    results: T[];
    continuationToken: string | undefined;
}

export interface IPagedItems<T> {
    items: T[];
    continuationToken: string | undefined;
}
