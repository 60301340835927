import { ScreeningPageNames } from 'components/common/constants';
import { IColumn } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { ICloudScreening, IScopeRecord } from 'clients/cloud-screening-client';
import { Table } from 'components/common/table';
import { getMyScreeningsTableColumns } from 'components/screening/cloud-screening/my-screenings/my-screenings-table-columns';

export interface MyScreeningsTableProps {
    screenings: ICloudScreening[];
    isFetchingData: boolean;
    showRequestButton: boolean;
    setShowRequestModal: (show: boolean) => void;
    scopes: IScopeRecord[];
}

export default function MyScreeningsTable(props: MyScreeningsTableProps): JSX.Element {
    const [tableColumns, setTableColumns] = useState<IColumn[]>(
        getMyScreeningsTableColumns(
            props.showRequestButton,
            props.setShowRequestModal,
            props.scopes,
        ),
    );
    const [screenings, setScreenings] = useState<ICloudScreening[]>([]);

    useEffect(() => {
        setScreenings(props.screenings);
    }, [props.screenings]);

    useEffect(() => {
        // Update the columns when showRequestButton changes.
        const newColumns = getMyScreeningsTableColumns(
            props.showRequestButton,
            props.setShowRequestModal,
            props.scopes,
        );
        setTableColumns(newColumns);
    }, [props.showRequestButton, props.scopes]);

    return (
        <Table
            rows={screenings}
            isFetchingData={props.isFetchingData}
            tableColumns={tableColumns}
            shimmerLabel='Loading screenings...'
            tableName={ScreeningPageNames.MyScreenings}
        />
    );
}
