import React from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { useCheckMountedState } from 'utils/misc-hooks';
import { useIsMounted } from 'utils/misc-hooks';
import { SetStateFunc } from 'types/global-types';
import Spacer from 'components/common/spacer';

interface IParams {
    type: MessageBarType;
    disableClear?: boolean;
    marginTop?: number;
    alwaysShow?: boolean;
    url?: string;
    linkText?: string;
}

export interface IUseMessageBar {
    theMessage: string | undefined;
    theElement: () => JSX.Element;
    setMessage: SetStateFunc<string | undefined>;
    clearMessage: () => void;
}

export default function useMessageBar(params: IParams): IUseMessageBar {
    const [theMessage, setTheMessage] = useCheckMountedState<string | undefined>(undefined);

    const isMounted = useIsMounted();

    const clearMessage = (): void => {
        if (!params.disableClear && isMounted()) {
            setTheMessage(undefined);
        }
    };

    return {
        theMessage,
        theElement: (): JSX.Element => {
            if (!!theMessage || params.alwaysShow) {
                return (
                    <>
                        {!!params.marginTop && <Spacer marginTop={params.marginTop} />}
                        <MessageBar
                            onDismiss={params.disableClear ? undefined : clearMessage}
                            messageBarType={params.type}
                            dismissButtonAriaLabel='Close'>
                            {theMessage}
                            {params.url && (
                                <Link to={{ pathname: params.url }} target='_blank'>
                                    {params.linkText}
                                </Link>
                            )}
                        </MessageBar>
                    </>
                );
            } else {
                return <></>;
            }
        },
        setMessage: (value: React.SetStateAction<string | undefined>) => {
            if (isMounted()) {
                setTheMessage(value);
            }
        },
        clearMessage,
    };
}
