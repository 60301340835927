import { useContext, useState } from 'react';
import { ClearanceClient, IClearanceRecord } from 'clients/clearance-client';
import { AuthContext } from 'contexts/auth-context';

type IUseClearanceType = {
    getClearances: (clearanceIdSet: Set<string>) => Promise<void>;
    clearanceMap: Map<string, IClearanceRecord>;
    errorGettingRecords: string | undefined;
    clearErrorMessage: () => void;
};

export function useClearances(): IUseClearanceType {
    const [clearanceMap, setClearanceMap] = useState<Map<string, IClearanceRecord>>(
        new Map<string, IClearanceRecord>(),
    );
    const [errorGettingRecords, setErrorGettingRecords] = useState<string | undefined>();

    const authContext = useContext(AuthContext);

    const clearErrorMessage = (): void => {
        setErrorGettingRecords(undefined);
    };

    const getClearances = async (clearanceIdsSet: Set<string>): Promise<void> => {
        if (!clearanceIdsSet || clearanceIdsSet.size === 0) {
            return;
        }

        try {
            const getTheseClearances = new Set<string>();
            clearanceIdsSet.forEach((id) => {
                if (!clearanceMap.has(id)) {
                    getTheseClearances.add(id);
                }
            });
            if (getTheseClearances.size === 0) {
                return;
            }
            const clearancesResult: IClearanceRecord[] = await ClearanceClient.getClearancesByIds(
                authContext,
                Array.from(getTheseClearances),
            );

            if (clearancesResult.length > 0) {
                setClearanceMap((currentValue) => {
                    const newValue = new Map<string, IClearanceRecord>(currentValue);
                    clearancesResult.forEach((clearance) => {
                        newValue.set(clearance.id, clearance);
                    });
                    return newValue;
                });
            }
        } catch (e) {
            console.error('Error getting clearances by clearance Ids', e);
            setErrorGettingRecords('Error getting clearances');
        }
    };

    return {
        getClearances,
        clearErrorMessage,
        clearanceMap,
        errorGettingRecords,
    };
}
