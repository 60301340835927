import CheckAccess from 'components/common/check-access';
import React, { useContext } from 'react';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import ScreeningV2 from 'components/screening/screening-v2/screening-v2';
import { UserContext } from 'contexts/user-context';

export default function ScreeningV2AccessWrapper(): JSX.Element {
    const userContext = useContext(UserContext);
    return (
        <CheckAccess
            arePermissionsChecked={userContext.isUsGovScreeningUserTypesLoaded}
            hasRequiredPermissionsAny={[
                userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST),
            ]}
            accessDeniedRedirect='/home'>
            <ScreeningV2 />
        </CheckAccess>
    );
}
