import { NeutralColors, SharedColors } from '@fluentui/theme';
import { TooltipHost } from '@fluentui/react';
import React from 'react';
import { Dictionary } from 'assets/constants/global-constants';
import { ICloudScreening, IScopeRecord } from 'clients/cloud-screening-client';
import { IEmployee } from 'clients/employee-client';
import { distinct, generateRandomKey } from 'utils/misc-utils';
import { daysBetweenDates } from 'utils/time-utils';
import { PieChartItem } from 'components/common/charts/pie-chart';

const DAYS_TILL_REQUESTED_SCREENING_TERMINATED = 10;

export enum RequiredForScopes {
    AME = 'AME',
    SAW = 'SAW',
    JIT = 'JIT',
    ASC = 'ASC',
    Groups = 'Groups',
    Contractual = 'Contractual',
    NotRequired = 'Not Required',
}

export enum CloudScreeningState {
    Requested = 'Requested',
    Completed = 'Completed',
    Expired = 'Expired',
}

export enum UKVerificationLocations {
    EnglandAndWales = 'England and Wales',
    Scotland = 'Scotland',
    OutsideUK = 'Outside UK',
    NorthernIreland = 'Northern Ireland',
}

export type cloudScreeningStatus = {
    shortText: string;
    longText: string;
    description: string;
    color: string;
    backgroundColor: string;
    order: number;
};

export const cloudScreeningStatuses = {
    screened: {
        shortText: 'SCREENED',
        longText: 'SCREENED',
        description: 'Cloud screening on file',
        color: NeutralColors.white,
        backgroundColor: SharedColors.cyanBlue10,
        order: 0,
    },
    expiringWithinOneHundredTwentyDays: {
        shortText: 'EXPIRING',
        longText: 'EXPIRING <= 120 DAYS',
        description: 'Cloud screening expires within 120 days',
        color: NeutralColors.black,
        backgroundColor: SharedColors.yellow10,
        order: 1,
    },
    expiringWithinNinetyDays: {
        shortText: 'EXPIRING',
        longText: 'EXPIRING <= 90 DAYS',
        description: 'Cloud screening expires within 90 days',
        color: NeutralColors.black,
        backgroundColor: SharedColors.orange10,
        order: 2,
    },
    expiringWithinSixtyDays: {
        shortText: 'EXPIRING',
        longText: 'EXPIRING <= 60 DAYS',
        description: 'Cloud screening expires within 60 days',
        color: NeutralColors.white,
        backgroundColor: SharedColors.redOrange10,
        order: 3,
    },
    expiringWithinThirtyDays: {
        shortText: 'EXPIRING SOON',
        longText: 'EXPIRING <= 30 DAYS',
        description: 'Cloud screening expires within 30 days',
        color: NeutralColors.white,
        backgroundColor: SharedColors.magenta20,
        order: 4,
    },
    expired: {
        shortText: 'EXPIRED',
        longText: 'EXPIRED',
        description: 'Cloud screening is expired',
        color: NeutralColors.white,
        backgroundColor: NeutralColors.black,
        order: 5,
    },
    // used by Cloud Screening Service data
    requested: {
        shortText: 'REQUESTED',
        longText: 'REQUESTED',
        description: 'Cloud screening was requested',
        color: NeutralColors.black,
        backgroundColor: NeutralColors.gray50,
        order: 6,
    },
    notScreened: {
        shortText: 'NOT SCREENED',
        longText: 'NOT SCREENED',
        description: 'No cloud screening on file',
        color: NeutralColors.white,
        backgroundColor: NeutralColors.black,
        order: 7,
    },
};

export function getCloudScreeningStatusLabelScreening(screening: ICloudScreening): JSX.Element {
    let status: cloudScreeningStatus | undefined = undefined;

    switch (screening.state) {
        case CloudScreeningState.Expired:
            status = cloudScreeningStatuses.expired;
            break;
        case CloudScreeningState.Completed: // could be screened or expiring
            const cloudScreenExpireDate = new Date(screening.dateExpires?.atUtc ?? 0);
            status = getCloudScreeningActiveStatus(cloudScreenExpireDate);
            break;
        case CloudScreeningState.Requested:
            status = cloudScreeningStatuses.requested;
            break;
    }

    return getCloudScreeningStatusBadge(status);
}

export function getCloudScreeningStatus(employee: IEmployee): cloudScreeningStatus {
    if (!employee.cloudScreenExpireDate || !employee.cloudScreenCompletionDate) {
        return cloudScreeningStatuses.notScreened;
    }

    const cloudScreenExpireDate = new Date(employee.cloudScreenExpireDate);
    const cloudScreenCompletionDate = new Date(employee.cloudScreenCompletionDate);

    // if completion date is greater than expire date then the data is invalid
    if (cloudScreenCompletionDate > cloudScreenExpireDate) {
        console.log('Cloud Screen completion date cannot be greater than expire date.');
        return cloudScreeningStatuses.notScreened;
    }

    const currentDate = new Date();

    if (currentDate > cloudScreenExpireDate) {
        return cloudScreeningStatuses.expired;
    }

    return getCloudScreeningActiveStatus(cloudScreenExpireDate, currentDate);
}

export function getCloudScreeningActiveStatus(
    cloudScreenExpireDate: Date,
    currentDate?: Date,
): cloudScreeningStatus {
    currentDate = currentDate ?? new Date();
    const daysUntilExpired = daysBetweenDates(currentDate, cloudScreenExpireDate);

    if (daysUntilExpired <= 0) {
        return cloudScreeningStatuses.expired;
    } else if (daysUntilExpired <= 30) {
        return cloudScreeningStatuses.expiringWithinThirtyDays;
    } else if (daysUntilExpired <= 60) {
        return cloudScreeningStatuses.expiringWithinSixtyDays;
    } else if (daysUntilExpired <= 90) {
        return cloudScreeningStatuses.expiringWithinNinetyDays;
    } else if (daysUntilExpired <= 120) {
        return cloudScreeningStatuses.expiringWithinOneHundredTwentyDays;
    }

    return cloudScreeningStatuses.screened;
}

export function isCloudScreeningStatusExpiring(status: cloudScreeningStatus): boolean {
    return (
        status === cloudScreeningStatuses.expiringWithinThirtyDays ||
        status === cloudScreeningStatuses.expiringWithinSixtyDays ||
        status === cloudScreeningStatuses.expiringWithinNinetyDays ||
        status === cloudScreeningStatuses.expiringWithinOneHundredTwentyDays
    );
}

export function isCloudScreeningStatusActive(status: cloudScreeningStatus): boolean {
    return status === cloudScreeningStatuses.screened || isCloudScreeningStatusExpiring(status);
}

export enum CloudScreeningAction {
    NotRequired = 'Not Required',
    AlreadyRequested = 'Already Requested',
    RequestScreening = 'Request Screening',
    InternationalCannotRequest = 'International - Cannot Request',
    VendorCannotRequest = 'Vendor - Cannot Request',
}

export const FIRST_ADVANTAGE_COUNTRY_CODES = new Set(['IN', 'CN', 'HK', 'TW']);
export const INTERNATIONAL_EXCEPTIONS: Dictionary<{ fte: boolean; vendor: boolean }> = {
    FR: {
        fte: true,
        vendor: true,
    },
    PS: {
        fte: true,
        vendor: true,
    },
    ES: {
        fte: false,
        vendor: true,
    },
};

export function checkInternationalException(emp: IEmployee): boolean {
    const exceptionConfigObj = INTERNATIONAL_EXCEPTIONS[emp.locationAreaCode];
    if (exceptionConfigObj !== undefined) {
        if (emp.isFTE) {
            return exceptionConfigObj.fte;
        } else {
            return exceptionConfigObj.vendor;
        }
    }
    return false;
}

export function getCloudScreeningAction(
    employee?: IEmployee,
    screenings?: ICloudScreening[],
): CloudScreeningAction {
    if (!employee || getCloudScreeningStatus(employee) === cloudScreeningStatuses.screened) {
        return CloudScreeningAction.NotRequired;
    }
    if (checkInternationalException(employee)) {
        return CloudScreeningAction.InternationalCannotRequest;
    }

    if (!employee.isFTE) {
        return CloudScreeningAction.VendorCannotRequest;
    }

    if (screenings) {
        const currentDate = new Date();
        for (let i = 0; i < screenings.length; i++) {
            const screening = screenings[i];
            if (screening.state === CloudScreeningState.Requested) {
                const screeningStatusChangedDate = new Date(screening.statusChanged.atUtc);
                const daysAsRequested =
                    daysBetweenDates(screeningStatusChangedDate, currentDate) + 1;
                if (daysAsRequested < DAYS_TILL_REQUESTED_SCREENING_TERMINATED) {
                    return CloudScreeningAction.AlreadyRequested;
                }
            }
        }
    }

    // Show request screening button if not screened, expired, or expiring, &
    // all requested screenings that exists have been in that state for >= 10 days,
    // & no pending UK verification screening exists
    return CloudScreeningAction.RequestScreening;
}

export function hasCloudScreeningState(
    state: CloudScreeningState,
    screenings?: ICloudScreening[],
): boolean {
    if (!screenings) {
        return false;
    }

    return screenings.some((x) => x.state === state);
}

export function getCloudScreeningStatusLabelEmployee(employee: IEmployee): JSX.Element {
    const status = getCloudScreeningStatus(employee);
    return getCloudScreeningStatusBadge(status);
}

function getCloudScreeningStatusBadge(status?: cloudScreeningStatus): JSX.Element {
    if (!status) {
        return <></>;
    }

    return (
        <TooltipHost content={status.description} key={generateRandomKey()}>
            <span
                style={{
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    textAlign: 'center',
                    backgroundColor: status.backgroundColor,
                    color: status.color,
                    padding: '2px 7px',
                    minWidth: 98,
                }}>
                {status.shortText}
            </span>
        </TooltipHost>
    );
}

export enum PieChartStatus {
    Screened = 'SCREENED',
    Expiring120 = 'EXPIRING <= 120 DAYS',
    Expiring90 = 'EXPIRING <= 90 DAYS',
    Expiring60 = 'EXPIRING <= 60 DAYS',
    Expiring30 = 'EXPIRING <= 30 DAYS',
    Expired = 'EXPIRED',
    NotScreened = 'NOT SCREENED',
}

export const PieChartColorMap: { [status: string]: string } = {
    [PieChartStatus.Screened]: cloudScreeningStatuses.screened.backgroundColor,
    [PieChartStatus.NotScreened]: cloudScreeningStatuses.notScreened.backgroundColor,
    [PieChartStatus.Expiring120]:
        cloudScreeningStatuses.expiringWithinOneHundredTwentyDays.backgroundColor,
    [PieChartStatus.Expiring90]: cloudScreeningStatuses.expiringWithinNinetyDays.backgroundColor,
    [PieChartStatus.Expiring60]: cloudScreeningStatuses.expiringWithinSixtyDays.backgroundColor,
    [PieChartStatus.Expiring30]: cloudScreeningStatuses.expiringWithinThirtyDays.backgroundColor,
    [PieChartStatus.Expired]: cloudScreeningStatuses.expired.backgroundColor,
};

export function getPieChartData(reports: IEmployee[]): PieChartItem[] {
    if (reports.length === 0) return [];

    const statusDict: { [status: string]: number } = {};

    Object.values(PieChartStatus).forEach((chartStatus) => (statusDict[chartStatus] = 0));
    reports.forEach((employee) => {
        const status = getCloudScreeningStatus(employee);
        if (statusDict[status.longText] === undefined) {
            statusDict[PieChartStatus.NotScreened]++;
        } else {
            statusDict[status.longText]++;
        }
    });

    return Object.values(PieChartStatus).map(
        (chartStatus): PieChartItem => {
            return {
                label: chartStatus,
                color: PieChartColorMap[chartStatus],
                percentage: Math.round((statusDict[chartStatus] / reports.length) * 100),
            };
        },
    );
}

export function createScopesElementFromRecords(
    scopeRecords: IScopeRecord[] | undefined,
): JSX.Element {
    if (scopeRecords && scopeRecords.length > 0) {
        const distinctScopes = distinct(scopeRecords.map((x) => `${x.scope}`));
        return <>{distinctScopes.join(', ')}</>;
    }
    return <>{RequiredForScopes.NotRequired}</>;
}

export function createScopesElementFromStrings(scopes: string[] | undefined): JSX.Element {
    if (scopes && scopes.length > 0) {
        const distinctScopes = distinct(scopes.map((x) => `${x}`));
        return <>{distinctScopes.join(', ')}</>;
    }
    return <>{RequiredForScopes.NotRequired}</>;
}

export interface ICloudScreenChartSideInfo {
    total: number;
    totalRequired: number;
    notRequired: number;
    screened: number;
}

export function getPieChartSideInfo(
    reports: IEmployee[],
    scopeRecordsDict: Dictionary<IScopeRecord[]>,
): ICloudScreenChartSideInfo {
    const total = reports.length;
    let notRequired = 0;
    let screened = 0;
    let totalRequired = 0;

    reports.forEach((employee) => {
        if (!scopeRecordsDict[employee.id] || !scopeRecordsDict[employee.id].length) {
            notRequired++;
            return;
        }
        totalRequired++;
        const status = getCloudScreeningStatus(employee);
        if (isCloudScreeningStatusActive(status)) {
            screened++;
        }
    });

    return {
        total,
        totalRequired,
        notRequired,
        screened,
    };
}

export function getLatestCloudScreening(arr?: ICloudScreening[]): ICloudScreening | undefined {
    if (!arr) {
        return;
    }

    return arr.reduce((res, val) => (res.dateCreated.atUtc < val.dateCreated.atUtc ? val : res));
}
