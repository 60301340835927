import React, { useEffect, useState } from 'react';
import ScaClient, { IReviewPeriod } from 'clients/sca-client';
import { IPagedResults } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import { generalIsMountedCode } from 'utils/misc-utils';
import { useToggle } from 'utils/misc-hooks';
import Badge from 'components/common/badge';
import { getReviewStatusBackground } from 'components/sca/sca-constants';

export const fetchReviewPeriods = async (
    authContext: IAuthContext,
    continuationToken = '',
): Promise<IPagedResults<IReviewPeriod>> => {
    let result;
    try {
        result = await ScaClient.getReviewPeriods(authContext, continuationToken);
    } catch (e) {
        console.error(e);
    }
    const reviewPeriods = result ?? { results: [], continuationToken: '' };
    return reviewPeriods;
};

export const fetchAllReviewPeriods = async (
    authContext: IAuthContext,
): Promise<IReviewPeriod[]> => {
    let result;
    let hasNoError = true;
    let reviewPeriods: IReviewPeriod[] = [];
    let continuationToken = '';

    do {
        try {
            result = await ScaClient.getReviewPeriods(authContext, continuationToken);
        } catch (e) {
            console.error(e);
            hasNoError = false;
        }
        reviewPeriods = reviewPeriods.concat(result?.results ?? []);
        continuationToken = result?.continuationToken ?? '';
    } while (hasNoError && continuationToken !== '');

    // Sort decending based on start date.
    return reviewPeriods.sort((p1, p2) => p2.startDateUTC - p1.startDateUTC);
};

type UseAllReviewPeriodsType = {
    periods: IReviewPeriod[];
    isLoading: boolean;
};

export function useAllReviewPeriods(authContext: IAuthContext): UseAllReviewPeriodsType {
    const [periods, setPeriods] = useState<IReviewPeriod[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        return generalIsMountedCode(fetchThem);
    }, []);

    const fetchThem = async (isMoundtedFunc: () => boolean): Promise<void> => {
        try {
            const periodsVar = await fetchAllReviewPeriods(authContext);
            if (isMoundtedFunc()) {
                setPeriods(periodsVar);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        periods: periods,
        isLoading: isLoading,
    };
}

type UseReviewPeriodsType = {
    isLoading: boolean;
    reviewPeriods: IReviewPeriod[];
    continuationToken: string;
    currentReviewPeriod: IReviewPeriod | undefined;
    refetchReviewPeriods: () => void;
    continueFetchReviewPeriods: () => void;
};

export function useReviewPeriods(authContext: IAuthContext): UseReviewPeriodsType {
    const [shouldRefetch, setShouldRefetch] = useState<boolean>(true);
    const [shouldContinueFetch, toggleContinueFetch] = useToggle(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentReviewPeriod, setCurrentReviewPeriod] = useState<IReviewPeriod>();
    const [reviewPeriodsResults, setReviewPeriodsResults] = useState<IPagedResults<IReviewPeriod>>({
        results: [],
        continuationToken: '',
    });

    useEffect(() => {
        return generalIsMountedCode(callFetchReviewPeriods);
    }, [shouldRefetch]);

    useEffect(() => {
        return generalIsMountedCode(callFetchReviewPeriods);
    }, [shouldContinueFetch]);

    const toggleRefetch = (): void => {
        setReviewPeriodsResults((currentValue) => ({
            ...currentValue,
            continuationToken: '',
        }));
        setShouldRefetch(!shouldRefetch);
    };

    const callFetchReviewPeriods = async (isMountedFunc: () => boolean): Promise<void> => {
        const reviewPeriodsResultsVar = await fetchReviewPeriods(
            authContext,
            reviewPeriodsResults.continuationToken,
        );
        const currentReviewPeriodVar = reviewPeriodsResultsVar.results.find(
            (reviewPeriod) => reviewPeriod.isCurrent,
        );
        if (isMountedFunc()) {
            setCurrentReviewPeriod(currentReviewPeriodVar);
            setReviewPeriodsResults(reviewPeriodsResultsVar);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        reviewPeriods: reviewPeriodsResults.results,
        continuationToken: reviewPeriodsResults.continuationToken ?? '',
        currentReviewPeriod: currentReviewPeriod,
        // Call the following to clear continuationToken and fetch
        // periods. In other words, to re-fetch periods.
        refetchReviewPeriods: toggleRefetch,
        // Call the following to fetch based on current value of
        // continuationToken. In other words, to continue fetching.
        continueFetchReviewPeriods: toggleContinueFetch,
    };
}

export const reviewStatusBadge = (reviewPeriod: IReviewPeriod | undefined): JSX.Element => (
    <Badge
        text={reviewPeriod?.state || ''}
        backgroundColor={getReviewStatusBackground(reviewPeriod?.state ?? '')}
    />
);
