import { FontWeights, getTheme, mergeStyles, mergeStyleSets } from '@fluentui/react';
import { NeutralColors, SharedColors } from '@fluentui/theme';
import { AppConstants } from 'components/common/constants';
import { globalStyles } from 'assets/styles/global-styles';
import { contentMaxHeight, expandedTableWidth } from 'assets/styles/list-styles';
import { faintBlue, lightGray, lightPink } from 'assets/constants/global-colors';

export const filterWidth = 320;

export const normalNoBold = { fontWeight: 400 };

export const pageStyle = mergeStyleSets({
    page: {
        display: 'flex',
        flexDirection: 'column',
    },
    filterAndTable: {
        display: 'flex',
        flexDirection: 'row',
    },
    filterWrapper: {
        paddingRight: AppConstants.padding,
        boxSizing: 'border-box',
        width: `${filterWidth}px`,
    },
    filter: {
        width: '100%',
        marginTop: AppConstants.margin,
        backgroundColor: NeutralColors.white,
        padding: 10,
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
        transition: 'box-shadow 300ms ease 0s',
        borderRadius: 2,
    },
    candidatesTable: {
        width: `calc(100% - ${filterWidth}px)`,
        paddingLeft: AppConstants.padding,
        boxSizing: 'border-box',
        marginTop: '20px',
    },
    clearanceTable: {
        width: `calc(100% - ${filterWidth}px)`,
        paddingLeft: AppConstants.padding,
        boxSizing: 'border-box',
    },
    contractTable: {
        width: `calc(100% - ${filterWidth}px)`,
        paddingLeft: AppConstants.padding,
        boxSizing: 'border-box',
    },
    expandedCandidatesTable: {
        minWidth: `${expandedTableWidth}px`,
    },
    modalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    recordsCount: {
        verticalAlign: 'text-bottom',
        fontWeight: 600,
    },
    infoButton: { height: 'auto', minHeight: '35px' },
    marginTop10: { marginTop: 10 },
    exportToggle: {
        margin: '0px',
        width: '350px',
    },
});

const theme = getTheme();
export const customerMetricsStyles = mergeStyleSets({
    labelHeader: mergeStyles(globalStyles.boldFont, globalStyles.mediumLargeFont),
    averageDaysDiv: { marginBottom: '1em' },
    errorMessageBarDiv: { width: '80%', margin: 'auto' },
    viewScreeningLink: {
        maxHeight: contentMaxHeight,
        color: 'black',
        fontSize: '14px',
        selectors: {
            ':link': { textDecoration: 'none' },
            ':visited': { textDecoration: 'none' },
            ':hover': { textDecoration: 'none' },
            ':active': { textDecoration: 'none' },
        },
    },
    viewScreeningIcon: {
        fontSize: '16px',
        height: '16px',
        color: 'rgb(0, 120, 212)',
        textAlign: 'center',
        position: 'relative',
        top: '3px',
    },
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        borderTop: `4px solid ${SharedColors.cyanBlue10}`,
    },
    row: {
        display: 'inline',
        whiteSpace: 'nowrap',
    },
    innerRow1: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
    },
    innerRow2: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        marginLeft: '235px',
    },
    innerRowModal: {
        display: 'inline-block',
        paddingLeft: '24px',
        width: '300px',
        whiteSpace: 'normal',
    },
    innerRow2Modal: {
        display: 'inline-block',
        paddingLeft: '115px',
        width: '65px',
        whiteSpace: 'nowrap',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 18px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
        maxWidth: '560px',
    },
});

export const noExpandButtonStyle = {
    height: 0,
    color: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    opacity: 0.4,
    selectors: {
        ':hover': {
            color: 'rgb(243, 242, 241)',
            backgroundColor: 'rgb(243, 242, 241)',
        },
    },
    borderBottom: '0px',
};

export const expandButtonStyle = {
    color: 'rgb(0, 120, 212)',
    borderBottom: '0px',
};

export const tableColumnStyles = mergeStyleSets({
    actionRow: { height: '100%', maxHeight: '32px' },
    navLink: { display: 'inline' },
    metrics: { display: 'inline', marginTop: '-5px' },
    viewNomination: { display: 'inline', marginTop: '3px' },
    viewScreeningLink: {
        maxHeight: contentMaxHeight,
        color: 'black',
        fontSize: '14px',
        selectors: {
            ':link': { textDecoration: 'none' },
            ':visited': { textDecoration: 'none' },
            ':hover': { textDecoration: 'none' },
            ':active': { textDecoration: 'none' },
        },
    },
    viewScreeningIcon: {
        fontSize: '16px',
        height: '16px',
        color: faintBlue,
        textAlign: 'center',
        position: 'relative',
        top: '3px',
    },
});

export const statusSpanStyleDeclined = {
    verticalAlign: 'middle',
    display: 'table-cell',
    backgroundColor: lightPink,
    padding: '2px 7px',
};

export const statusSpanStyleNormal = {
    verticalAlign: 'middle',
    display: 'table-cell',
    backgroundColor: lightGray,
    padding: '2px 7px',
};
