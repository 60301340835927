import { ActionButton, IColumn } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import CloudScreeningClient, { ICloudScreeningAllowList } from 'clients/cloud-screening-client';
import { AuthContext } from 'contexts/auth-context';
import { generalIsMountedCode } from 'utils/misc-utils';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { Table, TableCell } from 'components/common/table';

interface AllowListsTableProps {
    triggerAllowListRefresh: boolean;
}

export default function AllowListsTable(props: AllowListsTableProps): JSX.Element {
    const [allowLists, setAllowLists] = useState<ICloudScreeningAllowList[]>([]);
    const [isRemoveDisabled, setIsRemoveDisabled] = useState<boolean>(false);
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
    const authContext = useContext(AuthContext);

    useEffect(() => {
        generalIsMountedCode(populateAllowLists);
    }, [props.triggerAllowListRefresh]);

    async function populateAllowLists(isMountedFunc: () => boolean): Promise<void> {
        try {
            setIsFetchingData(true);
            const allowListsResult = await CloudScreeningClient.getAllAllowLists(authContext);
            if (isMountedFunc()) {
                setAllowLists(allowListsResult);
            }
        } catch (err) {
            console.error('Error retrieving allow lists.', err);
        } finally {
            setIsFetchingData(false);
        }
    }

    function removeAllowListFromState(allowListManagerId: string) {
        const allowListIdx = allowLists.findIndex(
            (allowList) => allowList.managerId === allowListManagerId,
        );
        if (allowListIdx !== -1) {
            const allowListsCopy = [...allowLists];
            allowListsCopy.splice(allowListIdx, 1);
            setAllowLists(allowListsCopy);
        }
    }

    async function onRemoveButtonClick(row: ICloudScreeningAllowList): Promise<void> {
        try {
            setIsRemoveDisabled(true);
            await CloudScreeningClient.deleteAllowList(authContext, row.managerId);
            removeAllowListFromState(row.managerId);
        } catch (err) {
            console.error('Error deleting allow list.', err);
        } finally {
            setIsRemoveDisabled(false);
        }
    }

    function getAllowListTableColumns(): IColumn[] {
        return [
            {
                key: 'managerName',
                name: 'Manager Name',
                ariaLabel: 'Manager Name',
                minWidth: 250,
                onRender: (row: ICloudScreeningAllowList): JSX.Element => {
                    return (
                        <TableCell>
                            <EmployeeBasicHoverCard personnelId={row.managerId} />
                        </TableCell>
                    );
                },
            },
            {
                key: 'scopeName',
                name: 'Scope Name',
                ariaLabel: 'Scope Name',
                minWidth: 250,
                onRender: (row: ICloudScreeningAllowList): JSX.Element => {
                    return <TableCell>{row.scopeName}</TableCell>;
                },
            },
            {
                key: 'action',
                name: 'Action',
                ariaLabel: 'Action',
                minWidth: 250,
                onRender: (row: ICloudScreeningAllowList): JSX.Element => {
                    return (
                        <ActionButton
                            disabled={isRemoveDisabled}
                            iconProps={{ iconName: IconNames.Trash }}
                            text='Remove from Org Required'
                            onClick={onRemoveButtonClick.bind(null, row)}
                            key={`action-btn-${row.id}`}
                        />
                    );
                },
            },
        ];
    }

    return (
        <Table
            rows={allowLists}
            isFetchingData={isFetchingData}
            tableColumns={getAllowListTableColumns()}
            shimmerLabel='Loading allow lists...'
            tableName='Allow Lists'
        />
    );
}
