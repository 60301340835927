import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from 'contexts/auth-context';
import SidebarAndContents, { ContentPane } from 'components/common/sidebar-and-contents';
import { UserContext } from 'contexts/user-context';
import { ScaReviewPeriodFilterContext } from 'components/sca/contexts/sca-review-period-filter-context';
import CheckRole from 'components/common/check-role';
import ScaManageViewIdFilters from 'components/sca/manage/sca-manage-view-id-filters';
import ScaClient, { IReviewPeriod, IScaRecord } from 'clients/sca-client';
import { Dictionary, IconNames } from 'assets/constants/global-constants';
import { dialogStyles, largeAtTheCenter } from 'assets/styles/global-styles';
import { generalIsMountedCode } from 'utils/misc-utils';
import { useSortColumnHandler, strCmp } from 'utils/sort-utils';
import { getScaMyTeamReviewColumns } from 'components/sca/my-team/get-sca-my-team-reviews-columns';
import { Table } from 'components/common/table';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';
import { OtherRate, ReviewStatusNames, HistoryPageTitle } from 'components/sca/sca-constants';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import {
    Stack,
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    Separator,
    Icon,
} from '@fluentui/react';
import {
    ModalConclusion,
    onModalConcludeType,
} from 'components/common/buttons/modal-action-button';
import deepcopy from 'deepcopy';
import { Redirect, useParams } from 'react-router-dom';
import { scaMyTeamUrl, scaHistoryBreadcrumbs } from 'components/sca/sca-breadcrumbs';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import HorizontalBar from 'components/common/horizontal-bar';
import ViewScaProgramDetailsActionButton from 'components/sca/sca-common/sca-view-sca-program-details-action-button';
import { fetchAllReviewPeriods } from 'components/sca/sca-utils';

export default function ScaViewMyTeam(): JSX.Element {
    const filters = useContext(ScaReviewPeriodFilterContext);
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const filterContext = useContext(ScaReviewPeriodFilterContext);
    const breadCrumbContext = useContext(BreadCrumbContext);
    const { reviewId: reviewIdFromUrl } = useParams<Dictionary<string>>();

    const [reviewId, setReviewId] = useState<string>();
    const [tableRows, setTableRows] = useState<IScaRecord[]>([]);
    const [tableRowAliases, setTableRowAliases] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [allReviews, setAllReviews] = useState<Dictionary<IScaRecord[]>>({});
    const [redirectTo, setRedirectTo] = useState<string>();
    const [reviewPeriods, setReviewPeriods] = useState<Dictionary<IReviewPeriod>>({});
    const [selectedReviews, setSelectedReviews] = useState<IScaRecord[]>([]);
    const [loadingErrorMsg, setLoadingErrorMsg] = useState<string>('');
    const [showTeamEligibilityNoticeDialog, setShowTeamEligibilityNoticeDialog] = useState<
        boolean
    >();

    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler('');

    const fetchReviewPeriods = async (isMountedFunc: () => boolean): Promise<void> => {
        const reviewPeriodsVar = await fetchAllReviewPeriods(authContext);
        if (isMountedFunc()) {
            const reviewPeriodsDictVar: Dictionary<IReviewPeriod> = {};
            reviewPeriodsVar.forEach((reviewPeriod) => {
                reviewPeriodsDictVar[reviewPeriod.id] = reviewPeriod;
            });
            setReviewPeriods(reviewPeriodsDictVar);
        }
    };

    useEffect(() => {
        return generalIsMountedCode(fetchReviewPeriods);
    }, []);

    useEffect(() => {
        if (
            reviewIdFromUrl &&
            reviewIdFromUrl?.toLocaleLowerCase() !== reviewId?.toLocaleLowerCase()
        ) {
            setReviewId(reviewIdFromUrl);
            filters.setFilter('period', filters.periodFilterSelection(reviewIdFromUrl));
        }
    }, [reviewIdFromUrl, reviewId]);

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs(scaHistoryBreadcrumbs(HistoryPageTitle.MyTeam, reviewId));
    }, [reviewId]);

    useEffect(() => {
        if (filters?.period?.key && filters?.period?.key !== reviewId) {
            // The following typecast prevents filters.period.key to be
            // considered as a number.
            setRedirectTo(scaMyTeamUrl(filters.period.key as string));
        }
    }, [filters?.period?.key]);

    // Clear "redirectTo" otherwise you'll never see the end of it.
    useEffect(() => {
        setRedirectTo('');
    }, [redirectTo]);

    const selectedReviewPeriod = reviewId ? reviewPeriods[reviewId] : undefined;

    const addReviews = (reviewId: string, reviews: IScaRecord[]): void => {
        setAllReviews({ ...allReviews, [reviewId]: reviews });
    };

    const fetchManagerReviewsPromise = async (
        reviewId: string,
    ): Promise<IScaRecord[] | undefined> => {
        // Only fetch the selected reviews if not already fetched
        if (allReviews[reviewId] !== undefined) {
            return allReviews[reviewId];
        }
        try {
            // SCA Auth
            const result = await ScaClient.getManagerReviews(
                authContext,
                userContext.employeeRecord.id,
                reviewId,
            );
            if (result.hasDirectsInReview) {
                return result.results;
            } else {
                return [];
            }
        } catch (e) {
            if (e.status === 404) {
                setLoadingErrorMsg(`The period "${reviewId}" or the specified manager not found`);
            } else {
                throw e;
            }
        }
    };

    const fetchManagerReviews = async (isMountedFunc: () => boolean): Promise<void> => {
        if (reviewId === undefined) {
            return;
        }
        if (!userContext.employeeRecord.id) {
            setIsLoading(false);
            return;
        }
        try {
            setIsLoading(true);
            setLoadingErrorMsg('');
            const reviewsVar = await fetchManagerReviewsPromise(reviewId);
            if (isMountedFunc() && reviewsVar) {
                addReviews(reviewId, reviewsVar);
            }
        } catch (e) {
            setLoadingErrorMsg(`Error loading reviews for the specified period`);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        return generalIsMountedCode(fetchManagerReviews);
    }, [authContext, userContext.employeeRecord, reviewId]);

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (reviewId) {
            setSelectedReviews(allReviews[reviewId] ?? []);
        } else {
            setSelectedReviews([]);
        }
    }, [isLoading, reviewId]);

    type SortFuncType = (r1: IScaRecord, r2: IScaRecord) => number;

    const chooseSortCmp = (): SortFuncType => {
        switch (sortColumn) {
            case 'Employee':
                return (r1: IScaRecord, r2: IScaRecord): number =>
                    sortAscending *
                    strCmp(r1.name.toLocaleLowerCase(), r2.name.toLocaleLowerCase());
            default:
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                return (r1: IScaRecord, r2: IScaRecord): number => 1;
        }
    };

    const filterFunc = (r: IScaRecord): boolean => {
        const checkStatus = (): boolean => {
            const hasSomeReviewStatusFilter = Object.values(filters.status ?? {}).some((v) => v);
            if (hasSomeReviewStatusFilter) {
                // User has set some status filter.
                if ((filters?.status ?? {})[r.reviewState]) {
                    return true; // This review record has a status that user has set.
                } else {
                    return false;
                }
            } else {
                return true; // User has set no status filter.
            }
        };
        if (!checkStatus()) {
            return false;
        }
        if (filters.employee) {
            if (filters.employee?.itemPropObj?.id !== r.personnelId) {
                return false;
            }
        }
        if (filters.reviewer) {
            if (
                filters.reviewer?.itemPropObj?.alias.toLocaleLowerCase() !==
                r.reviewedBy?.replace(/@.*/, '').toLocaleLowerCase()
            ) {
                return false;
            }
        }
        if (filters.reviewedOn) {
            const reviewDate = new Date(r.reviewedTimestampUTC * 1000).toDateString();
            if (reviewDate !== filters.reviewedOn.toDateString()) {
                return false;
            }
        }
        if (filters.selectedRate) {
            if (filters.selectedRate.key === OtherRate) {
                if (r.rate !== parseFloat((filters.rate ?? '').toString())) {
                    return false;
                }
            } else if (parseFloat(filters.selectedRate.key.toString()) !== r.rate) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        const tableRowsVar = reviewId ? selectedReviews : [];
        const sortCmpFunc = chooseSortCmp();
        setTableRows(tableRowsVar.filter(filterFunc).sort(sortCmpFunc));
        setTableRowAliases(
            selectedReviews
                .map((curRow) => curRow.alias)
                .concat(selectedReviews.map((curRow) => curRow.reviewCommentBy)),
        );
    }, [selectedReviews, filters, sortAscending, sortColumn]);

    useEffect(() => {
        if (selectedReviewPeriod?.state === ReviewStatusNames.APPROVAL) {
            setShowTeamEligibilityNoticeDialog(true);
        }
    }, [selectedReviewPeriod]);

    const hideTeamEligibilityNoticeDialog = (): void => {
        setShowTeamEligibilityNoticeDialog(false);
    };

    type ExcelRecordType = {
        employee: string;
        status: string;
        reviewedBy: string;
        previousRate: string;
        currentRate: string;
        reviewedOn: string;
        comment: string;
    };

    const mapRecordsToExcel = async (records: IScaRecord[]): Promise<ExcelRecordType[]> => {
        return records.map((record) => {
            const reviewedOn = record.reviewedTimestampUTC
                ? new Date(record.reviewedTimestampUTC * 1000).toLocaleDateString()
                : '';
            return {
                employee: record.name,
                status: record.reviewState,
                reviewedBy: record.reviewedBy,
                previousRate: record.previousRate.toString(),
                currentRate: record.rate.toString(),
                reviewedOn: reviewedOn,
                comment: record.reviewComment,
            };
        });
    };

    const onUpdateEligibilityConcluded: onModalConcludeType<IScaRecord> = (
        modalConclusion: ModalConclusion,
        result?: IScaRecord,
    ) => {
        if (result === undefined || modalConclusion !== ModalConclusion.Done) {
            return;
        }
        const index = selectedReviews.findIndex(
            (review) => review.personnelId === result.personnelId,
        );
        if (index === -1) {
            // This condition is very unlikely to happen, but what the hell.
            return;
        }
        // deepcopy to trigger a dependency change on the
        // useEffect block that updates variable tableRows.
        const selectedReviewsVar = deepcopy(selectedReviews);
        selectedReviewsVar.splice(index, 1, result);
        setSelectedReviews(selectedReviewsVar);
    };

    const disableDismiss = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    ): void => {
        event?.stopPropagation();
    };

    const tableColumns = getScaMyTeamReviewColumns({
        sortColumn,
        reviewPeriod: selectedReviewPeriod,
        sortAscending: sortAscending === 1,
        sortColumnHandler,
        onUpdateEligibilityConcluded,
    });

    if (redirectTo) {
        return <Redirect push={true} to={redirectTo} />;
    } else {
        return (
            <CheckRole
                // SCA Auth
                requiredRolesAny={[]}
                hasRequiredRolesAny={[userContext.isScaManager]}
                arePermissionsChecked={userContext.isScaStatusChecked}>
                <SidebarAndContents>
                    {/**
                     * Here the code is not using <SidebarPane> because
                     * <ScaManageViewIdFilters> needs two panes and it
                     * will, instead, use two instances of <SidebarPane>
                     * to create them.
                     */}
                    <ScaManageViewIdFilters
                        showRate={true}
                        reviewId={reviewIdFromUrl}
                        aliases={tableRowAliases}
                        reviewPeriod={selectedReviewPeriod}
                        showReviewedBy={true}
                        showReviewedOn={true}
                        showClearFilters={true}
                    />
                    <ContentPane>
                        <ProblemLoadingMsg problemLoadingMsg={loadingErrorMsg}>
                            {isLoading ||
                            tableRows.length > 0 ||
                            selectedReviews.length > 0 ||
                            !reviewId ? (
                                <>
                                    <HorizontalBar>
                                        {/* Large grow factor to occupy "almost" the entire line and
                                            this way push the next item to the right side of the line */}
                                        <Stack.Item grow={100}>
                                            <ClearFiltersActionButton
                                                clearFunc={filterContext.resetAll}
                                            />
                                        </Stack.Item>
                                        {/* Small grow factor to occupy a small portion of the line
                                            so it goes flush at the right side of the line */}
                                        <Stack.Item grow={0.001}>
                                            <ExportToExcelButton<ExcelRecordType>
                                                getData={() => mapRecordsToExcel(tableRows)}
                                                fileNamePrefix={reviewId ?? ''}
                                                formatHeader={true}
                                            />
                                        </Stack.Item>
                                    </HorizontalBar>
                                    <Table<IScaRecord>
                                        tableColumns={tableColumns}
                                        isFetchingData={isLoading}
                                        shimmerLabel='Loading review data'
                                        rows={tableRows}
                                        tableName='My Team Reviews'
                                    />
                                </>
                            ) : (
                                <div className={largeAtTheCenter}>
                                    <span>
                                        {' '}
                                        You have no direct reports in the selected review period{' '}
                                    </span>
                                </div>
                            )}
                        </ProblemLoadingMsg>
                    </ContentPane>
                </SidebarAndContents>
                <Dialog
                    maxWidth={640}
                    minWidth={640}
                    hidden={!showTeamEligibilityNoticeDialog}
                    modalProps={{
                        isBlocking: true,
                    }}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        title: (
                            <div className={dialogStyles.title}>
                                <Icon
                                    iconName={IconNames.QuickNote}
                                    className={dialogStyles.titleIcon}
                                />
                                <span>SCA Eligibility Notice</span>
                            </div>
                        ),
                        closeButtonAriaLabel: 'Close',
                        styles: { inner: dialogStyles.contentInner },
                    }}
                    onDismiss={disableDismiss}>
                    <span>
                        As an approver of SCA Eligibility, you are responsible for verifying that
                        the individual has met the eligibility criteria before approving payment. If
                        the individual has not met the eligibility criteria of the program, please
                        deny their eligibility. Failure to perform your due diligence may result in
                        disciplinary action, up to and including termination.
                    </span>
                    <Separator />
                    <DialogFooter className={dialogStyles.footer}>
                        <ViewScaProgramDetailsActionButton />
                        <PrimaryButton
                            iconProps={{ iconName: IconNames.CheckMark }}
                            onClick={hideTeamEligibilityNoticeDialog}>
                            I Accept
                        </PrimaryButton>
                    </DialogFooter>
                </Dialog>
            </CheckRole>
        );
    }
}
