import React, { useMemo } from 'react';
import { IUseInputElement } from 'components/common/use-input/input-element-utils';
import {
    IStyleFunctionOrObject,
    IChoiceGroupStyleProps,
    IChoiceGroupStyles,
    IChoiceGroupOption,
    ChoiceGroup,
} from '@fluentui/react';
import { useCheckMountedState } from 'utils/misc-hooks';

interface IChoiceGroupParams {
    // Ideally, we should keep the following props 100% the same
    // as the underlying Fluent component so that they can directly
    // drive its props.
    label?: string;
    options: IChoiceGroupOption[];
    placeholder?: string;
    required?: boolean;
    styles?: IStyleFunctionOrObject<IChoiceGroupStyleProps, IChoiceGroupStyles>;
    shouldRender?: boolean;

    // The underlying Fluent component doesn't have the following props.
    horizontal?: boolean;
}

export default function useChoiceGroup(
    params: IChoiceGroupParams,
): IUseInputElement<IChoiceGroupOption> {
    const [value, setValue] = useCheckMountedState<IChoiceGroupOption | undefined>(undefined);

    const { shouldRender = true } = params;

    const styles = useMemo(() => {
        let result = {};
        if (params.horizontal) {
            result = Object.assign({}, choiceGroupHorizontalStyles);
        }
        if (!!params.styles) {
            result = Object.assign(result, params.styles);
        }
        return result;
    }, [params.styles, params.horizontal]);

    const initialize = (initValue?: IChoiceGroupOption): void => {
        // Only accept an initValue if its "key" and "text" properties
        // are defined, and they indicate one of the available
        // choices in the provided list of options.
        if (
            !!initValue?.key &&
            !!initValue?.text &&
            params.options.find((o) => o.key === initValue.key && o.text === initValue.text)
        ) {
            setValue(initValue);
        } else {
            setValue(undefined);
        }
    };

    const selectFilterHandler = (
        a?: React.FormEvent<HTMLElement>,
        selection?: IChoiceGroupOption,
    ): void => {
        if (selection) {
            setValue(selection);
        }
    };

    return {
        value,
        initialize,
        theElement: (): JSX.Element =>
            shouldRender ? (
                <ChoiceGroup
                    label={params.label}
                    styles={styles}
                    selectedKey={value?.key}
                    options={params.options}
                    onChange={selectFilterHandler}
                    placeholder={params.placeholder}
                    required={params.required}
                />
            ) : (
                <></>
            ),
    };
}

const choiceGroupHorizontalStyles = {
    flexContainer: {
        columnGap: '2em',
        display: 'inline-flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
};
