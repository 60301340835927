import React, { useContext, useState } from 'react';
import { UserContext } from 'contexts/user-context';
import {
    Dialog,
    DialogFooter,
    DialogType,
    ActionButton,
    PrimaryButton,
    Icon,
    Separator,
} from '@fluentui/react';
import { dialogStyles } from 'assets/styles/global-styles';
import { IconNames } from 'assets/constants/global-constants';

interface IScaEmployeeEligibilityDialogProps {
    employeeName: string;
    isEmpEligNoticeDialogOpen: boolean | undefined;
    onAcceptEligibilityDialog: () => void;
    hideEligibilityNoticeDialog: () => void;
}

export default function ScaEmployeeEligibilityDialog(
    props: IScaEmployeeEligibilityDialogProps,
): JSX.Element {
    const userContext = useContext(UserContext);

    return (
        <Dialog
            maxWidth={640}
            minWidth={640}
            hidden={!props.isEmpEligNoticeDialogOpen}
            modalProps={{
                isBlocking: true,
            }}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: (
                    <div className={dialogStyles.title}>
                        <Icon iconName={IconNames.QuickNote} className={dialogStyles.titleIcon} />
                        <span>SCA Eligibility Notice</span>
                    </div>
                ),
                closeButtonAriaLabel: 'Close',
                styles: { inner: dialogStyles.contentInner },
            }}
            onDismiss={props.hideEligibilityNoticeDialog}>
            <span>
                In accordance with section 2-200 of the National Industrial Security Program
                Operating Manual (NISPOM), I,&nbsp;
                <strong>{userContext.employeeRecord.displayName}</strong>,&nbsp; attest that my
                employee,&nbsp;
                <strong>{props.employeeName}</strong>,&nbsp; has a requirement to possess a
                personnel clearance level in the performance of tasks or services related to the
                fulfillment of a classified contract.
            </span>
            <Separator />
            <DialogFooter className={dialogStyles.footer}>
                <ActionButton onClick={props.hideEligibilityNoticeDialog}>
                    <span>Cancel</span>
                </ActionButton>
                <PrimaryButton
                    iconProps={{ iconName: IconNames.CheckMark }}
                    onClick={props.onAcceptEligibilityDialog}>
                    <span>I Accept</span>
                </PrimaryButton>
            </DialogFooter>
        </Dialog>
    );
}

// Export the functions and variable that open and close the dialog
// box so that parent element doesn't have to implement them.
export function useScaEmployeeEligibilityDialogUtils() {
    const [isEmpEligNoticeDialogOpen, setIsEmpEligNoticeDialogOpen] = useState<boolean>();

    const hideEligibilityNoticeDialog = (): void => {
        setIsEmpEligNoticeDialogOpen(false);
    };

    const showEligibilityNoticeDialog = (): void => {
        setIsEmpEligNoticeDialogOpen(true);
    };

    return {
        isEmpEligNoticeDialogOpen,
        hideEligibilityNoticeDialog,
        showEligibilityNoticeDialog,
    };
}
