import {
    ActionButton,
    CommandBar,
    DetailsList,
    IColumn,
    ICommandBarItemProps,
    IContextualMenuProps,
    MessageBar,
    MessageBarType,
    SelectionMode,
} from '@fluentui/react';
import config from 'environments/environment';
import { IconNames } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import {
    CoreAssignmentsClient,
    CoreAttributesClient,
    CorePrincipalsClient,
    CoreAttributeSetsClient,
} from 'clients/core/personnel-core-client-wrappers';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { CheckScrollReachedBottom } from 'components/common/scroll-event-listener';
import Spacer from 'components/common/spacer';
import { AssignmentValueTableCell } from 'components/core/attributes/assignment-value-table-cell';
import AddEditCoreAssignmentPanelActionButton, {
    AssignmentModalState,
    AssignmentModalType,
} from 'components/core/common/add-edit-assignment';
import { CoreEmployeeHoverCardFromGraph } from 'components/core/common/employee-card/core-employee-hover-card';
import { RemoveAssignmentModal } from 'components/core/users/remove-assignment-modal';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import {
    AttributeStatus,
    GetAllAssignmentsRequest,
    GetAllByAttributeIdResult,
    GetAttributeByIdResult,
    GetAttributeSetByIdResult,
    GetPrincipalAssignmentsResult,
    GetPrincipalByFieldResult,
    GetPrincipalByIdResult,
} from 'personnel-core-clients';
import React, { CSSProperties, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getLocalTimeZone, timeZoneAbbr } from 'utils/time-utils';
import { LastModifiedBy, TimeUnit } from 'components/core/common/last-modified-by';
import { expiresOnTableCell } from 'components/core/users/user-assignments-page';
import { AttributeRoleCheckType, useAttributeRoleCheck } from 'utils/cor-hooks';

const PAGE_REQUEST_SIZE = 100;

export function AttributeAssignmentsPage(): JSX.Element {
    const breadCrumbContext = useContext(BreadCrumbContext);
    const authContext = useContext(AuthContext);
    const principalsClient = new CorePrincipalsClient(authContext);
    const assignmentsClient = new CoreAssignmentsClient(authContext);
    const attributesClient = new CoreAttributesClient(authContext);
    const attributeSetsClient = new CoreAttributeSetsClient(authContext);

    const { attributeId } = useParams<{ attributeId: string }>();
    const [shouldFetchData, setShouldFetchData] = useState<boolean>(true);
    const [continuationToken, setContinuationToken] = useState<string | undefined>();
    const [attributeSet, setAttributeSet] = useState<GetAttributeSetByIdResult>();
    const [attribute, setAttribute] = useState<GetAttributeByIdResult>();
    const [assignments, setAssignments] = useState<GetAllByAttributeIdResult[]>([]);
    const [selectedItem, setSelectedItem] = useState<GetAllByAttributeIdResult>();
    const [openModalState, setOpenModalState] = useState<AssignmentModalState | undefined>();
    const [editableAssignment, setEditableAssignment] = useState<
        GetPrincipalAssignmentsResult | undefined
    >();
    const [principal, setPrincipal] = useState<GetPrincipalByFieldResult | undefined>();
    const [isRemoveAssignmentDialogueOpen, setIsRemoveAssignmentDialogueOpen] = useState<boolean>(
        false,
    );
    const [message, setMessage] = useState<string>();
    const [messageType, setMessageType] = useState<MessageBarType>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            const attr = await attributesClient.getById(attributeId);
            const attrSet = await attributeSetsClient.getById(attr.attributeSetId);

            setAttribute(attr);
            setAttributeSet(attrSet);
            breadCrumbContext.setBreadCrumbs([
                { title: 'Home', link: '/home' },
                { title: 'Attributes', link: '' },
                { title: 'Attribute Sets', link: '' },
                {
                    title: 'Attributes',
                    link: `/profile/attributesets/${attr.attributeSetId}/attributes`,
                },
                { title: `${attr.name}`, link: '' },
            ]);
        };
        fetchData();
    }, [attributeId]);

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            setIsLoading(true);
            setShouldFetchData(false);
            try {
                const request = new GetAllAssignmentsRequest({
                    limit: PAGE_REQUEST_SIZE,
                    continuationToken: continuationToken,
                });
                const response = await assignmentsClient.getAllByAttributeId(attributeId, request);
                setAssignments([...assignments, ...(response.results ?? [])]);
                setContinuationToken(response.continuationToken);
            } catch (e) {
                setMessage('Error occurred loading attribute assignments.');
                setMessageType(MessageBarType.error);
            } finally {
                setIsLoading(false);
            }
        };
        if (shouldFetchData) {
            fetchData();
        }
    }, [shouldFetchData]);

    const editAssignment = async (item: GetAllByAttributeIdResult): Promise<void> => {
        const principal = await principalsClient.getByField(null, item.oid, null);
        setPrincipal(principal);
        setEditableAssignment(
            new GetPrincipalAssignmentsResult({
                attributeId: attributeId,
                expirationDate: item.assignment.exp,
                value: item.assignment.val,
                lastModifiedBy: item.assignment.by,
                lastModifiedDate: item.assignment.on,
            }),
        );
    };

    const isAttributeManager = useAttributeRoleCheck(
        AttributeRoleCheckType.Manager,
        attributeSet,
        attribute,
    );

    const columns: IColumn[] = useMemo(
        () => [
            {
                key: 'name',
                name: 'Name',
                ariaLabel: 'Name',
                minWidth: 150,
                maxWidth: 200,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: GetAllByAttributeIdResult): JSX.Element => {
                    if (item) {
                        return (
                            <div style={detailsListFieldStyle}>
                                <CoreEmployeeHoverCardFromGraph
                                    key={item.oid}
                                    oid={item.oid}
                                    displayName={item.displayName}
                                    upn={item.upn}
                                    showMiniCardAlias={false}
                                />
                            </div>
                        );
                    } else {
                        return <></>;
                    }
                },
            },
            {
                key: 'value',
                name: 'Value',
                ariaLabel: 'Value',
                minWidth: 500,
                maxWidth: 600,
                isResizable: true,
                onRender: (
                    item: GetAllByAttributeIdResult,
                    index?: number,
                    column?: IColumn,
                ): JSX.Element => {
                    return (
                        <div style={detailsListFieldStyle}>
                            <AssignmentValueTableCell
                                value={item.assignment.val?.toString()}
                                attributeType={attribute?.dataType}
                                column={column}
                            />
                        </div>
                    );
                },
            },
            {
                key: 'expire',
                name: `Expires On (${timeZoneAbbr(getLocalTimeZone())})`,
                ariaLabel: 'Expires On',
                minWidth: 150,
                maxWidth: 200,
                isResizable: true,
                onRender: (item: GetAllByAttributeIdResult): JSX.Element => {
                    return expiresOnTableCell(item.assignment.exp, detailsListFieldStyle);
                },
            },
            {
                key: 'lastModifiedOn',
                name: 'Last Modified On',
                ariaLabel: 'Last Modified On',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                onRender: (item: GetAllByAttributeIdResult): JSX.Element => {
                    if (item.assignment.on) {
                        return (
                            <LastModifiedBy
                                lastModified={item.assignment}
                                timeUnit={TimeUnit.Seconds}
                            />
                        );
                    } else {
                        return <div style={detailsListFieldStyle}>N/A</div>;
                    }
                },
            },
            {
                key: 'actions',
                name: 'Actions',
                ariaLabel: 'Actions',
                minWidth: 75,
                maxWidth: 75,
                isResizable: true,
                onRender: (item: GetAllByAttributeIdResult): JSX.Element => {
                    const contextualMenuItems: IContextualMenuProps = {
                        items: [
                            {
                                key: 'edit',
                                ariaLabel: 'Edit',
                                text: 'Edit',
                                iconProps: { iconName: IconNames.Edit },
                                disabled: attribute?.isAutoAssigned || !isAttributeManager,
                                onClick: (): void => {
                                    setOpenModalState(AssignmentModalState.Edit);
                                    editAssignment(item);
                                },
                            },
                            {
                                key: 'remove',
                                ariaLabel: 'Remove',
                                text: 'Remove',
                                iconProps: { iconName: IconNames.Remove },
                                disabled: attribute?.isAutoAssigned || !isAttributeManager,
                                onClick: (): void => {
                                    setSelectedItem(item);
                                    setIsRemoveAssignmentDialogueOpen(true);
                                },
                            },
                        ],
                        shouldFocusOnMount: true,
                    };
                    return (
                        <div style={{ ...detailsListFieldStyle }}>
                            <ActionButton
                                iconProps={{ iconName: 'More' }}
                                menuProps={contextualMenuItems}
                                styles={{
                                    menuIcon: {
                                        display: 'none', // Hide the menu icon
                                    },
                                }}
                            />
                        </div>
                    );
                },
            },
        ],
        [attribute],
    );

    const createOrUpdateAssignments = (): void => {
        setAssignments([]);
        setShouldFetchData(true);
    };

    const commandBarLeftItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        return [
            {
                key: 'add',
                text: 'Add Assignment',
                iconProps: { iconName: IconNames.Add },
                onClick: () => setOpenModalState(AssignmentModalState.Add),
                disabled:
                    attribute?.isAutoAssigned ||
                    !isAttributeManager ||
                    attribute?.status === AttributeStatus.Deprecated,
            },
        ];
    }, [attribute?.isAutoAssigned]);

    const commandBarRightItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        return [
            {
                key: 'export',
                text: 'Download report',
                iconProps: { iconName: IconNames.ExcelLogo },
                onClick: () => {
                    const download = async (): Promise<void> => {
                        const token = await authContext.getToken(config.coreApiConfig.aadScopes);
                        const form = document.createElement('form');
                        form.method = 'post';
                        form.action =
                            config.coreApiConfig.baseUrl + 'v1/cor/reports/attributeassignments';
                        form.innerHTML =
                            '<input type="hidden" name="authorization" value="' +
                            token +
                            '">' +
                            '<input type="hidden" name="attributeId" value="' +
                            attributeId +
                            '">';

                        document.body.appendChild(form);
                        form.submit();
                        document.body.removeChild(form);
                    };

                    download();
                },
            },
        ];
    }, []);

    return (
        <div>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <CommandBar
                styles={{
                    root: {
                        paddingTop: 25,
                        paddingLeft: 0,
                        background: globalStyles.backgroundColor,
                    },
                }}
                items={commandBarLeftItems}
                farItems={commandBarRightItems}
            />
            {message !== undefined && messageType !== undefined && (
                <MessageBar messageBarType={messageType}>{message}</MessageBar>
            )}
            <DetailsList items={assignments} columns={columns} selectionMode={SelectionMode.none} />
            <IsLoadingIndicator
                isLoading={isLoading}
                before={<Spacer marginTop={20} />}
                msg='Loading attribute assignments...'
            />
            <CheckScrollReachedBottom
                shouldCheck={!!continuationToken} // only check if there is a continuation token
                onScrollReachedBottom={(): void => setShouldFetchData(true)}
            />
            <RemoveAssignmentModal
                key={selectedItem?.id}
                principalId={selectedItem?.id ?? ''}
                onDismissed={(): void => {
                    setSelectedItem(undefined);
                    setIsRemoveAssignmentDialogueOpen(false);
                }}
                onSuccess={(): void => {
                    setAssignments(assignments.filter((a) => a.id !== selectedItem?.id));
                    setSelectedItem(undefined);
                }}
                isDialogueOpen={isRemoveAssignmentDialogueOpen}
                attributeId={attribute?.id ?? ''}
            />
            <AddEditCoreAssignmentPanelActionButton
                isOpen={openModalState !== undefined}
                modalState={openModalState ?? AssignmentModalState.Add}
                modalType={AssignmentModalType.Attribute}
                onDismiss={(): void => {
                    setOpenModalState(undefined);
                    setEditableAssignment(undefined);
                    setPrincipal(undefined);
                }}
                onCreateOrUpdate={createOrUpdateAssignments}
                initAttribute={openModalState === AssignmentModalState.Add ? attribute : undefined}
                initAssignment={editableAssignment}
                initPrincipal={principal as GetPrincipalByIdResult}
            />
        </div>
    );
}

const detailsListFieldStyle: CSSProperties = {
    height: 48,
    display: 'flex',
    alignItems: 'center',
};
