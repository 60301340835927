import { Dropdown, IDropdownOption, TextField } from '@fluentui/react';
import { AttributeDataType } from 'personnel-core-clients';
import React from 'react';
import MultiChoiceDropdown from 'components/core/common/multi-choice-dropdown';
import MultiTextField from 'components/core/common/textfield/multi-text-field';
import {
    CoreMultiPrincipalIdPickerTypeaheadSearch,
    CoreSinglePrincipalIdPickerTypeaheadSearch,
} from 'components/common/core-employee-picker-typeahead-search';

export interface IAttributeValuePickerProps {
    label: string;
    disabled?: boolean;
    assignmentValue?: any;
    type?: AttributeDataType;
    isAnyValueAllowed?: boolean;
    allowedValues?: any[];
    allowMultiValueSelection?: boolean;
    dropDownOptions?: IDropdownOption[];
    onSelectValue?: (val: (string | number)[]) => void;
    isRequired?: boolean;
}

export default function AttributeValuePicker(props: IAttributeValuePickerProps): JSX.Element {
    const onValueChange = (val: (string | number)[]): void => {
        if (props.onSelectValue) {
            props.onSelectValue(val);
        }
    };

    const renderValuePicker = (): JSX.Element => {
        if (props.dropDownOptions && props.dropDownOptions.length > 0) {
            if (props.allowMultiValueSelection) {
                return (
                    <MultiChoiceDropdown
                        label={props.label}
                        defaultSelectedKeys={props.assignmentValue ?? []}
                        placeholder='Select one or more values'
                        options={props.dropDownOptions}
                        onSelectionsChanged={(vals) => onValueChange(vals)}
                    />
                );
            }
            return (
                <Dropdown
                    label={props.label}
                    placeholder='Select a value'
                    selectedKey={props.assignmentValue}
                    options={props.dropDownOptions}
                    onChange={(ev, opt) => onValueChange(opt === undefined ? [] : [opt.text])}
                />
            );
        }

        if (props.type === AttributeDataType.Boolean) {
            const selectedKey =
                typeof props.assignmentValue === 'boolean'
                    ? (props.assignmentValue as boolean)
                        ? 'True'
                        : 'False'
                    : props.assignmentValue;

            return (
                <Dropdown
                    label={props.label}
                    placeholder='Select a value'
                    selectedKey={selectedKey}
                    options={[
                        {
                            key: 'True',
                            text: 'True',
                        },
                        {
                            key: 'False',
                            text: 'False',
                        },
                    ]}
                    onChange={(ev, opt) => onValueChange(opt === undefined ? [] : [opt.text])}
                    disabled={props.disabled}
                />
            );
        }

        if (props.type === AttributeDataType.Person) {
            if (props.allowMultiValueSelection) {
                return (
                    <CoreMultiPrincipalIdPickerTypeaheadSearch
                        ariaLabel={props.label}
                        selectedItems={props.assignmentValue}
                        onChange={(principalIds) =>
                            onValueChange(principalIds === undefined ? [] : principalIds)
                        }
                    />
                );
            } else {
                return (
                    <CoreSinglePrincipalIdPickerTypeaheadSearch
                        ariaLabel={props.label}
                        selectedItem={props.assignmentValue[0]}
                        onChange={(principalId) =>
                            onValueChange(principalId === undefined ? [] : [principalId])
                        }
                    />
                );
            }
        }

        const inputType = props.type === AttributeDataType.Integer ? 'number' : 'text';

        if (props.allowMultiValueSelection && !props.disabled) {
            return (
                <MultiTextField
                    type={inputType}
                    label={props.label}
                    onValuesChanged={(vals) => onValueChange(vals)}
                    selectedValue={props.assignmentValue}
                    isRequired={props.isRequired}
                />
            );
        }

        return (
            <TextField
                type={inputType}
                value={props.assignmentValue}
                label={props.label}
                onChange={(ev, val) => onValueChange(val === undefined ? [] : [val])}
                required={props.isRequired}
                disabled={props.disabled}
            />
        );
    };

    return renderValuePicker();
}
