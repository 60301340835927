import React, { useMemo } from 'react';
import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import {
    PublicTrustRequestTypes,
    PUBLIC_TRUST_REQUEST_TYPE_OPTIONS,
    REQUEST_AGENCY_OPTIONS,
} from 'components/screening/public-trust/public-trust-screening-result';
import { nameof } from 'utils/object-utils';
import {
    SuitabilityAgencies,
    SuitabilityLevels,
} from 'components/personnel-profile/suitability/profile-suitability-types';

export interface CandidateNominationStepTargetLevelProps {
    onRequestTypeChange: (requestType: PublicTrustRequestTypes) => void;
    onRequestAgencyChange: (requestAgency: SuitabilityAgencies) => void;
    onIsPIVRequiredChange: (required: boolean) => void;
    onSuitabilityLevelChange: (suitabilityLevel: string) => void;
    requestType?: PublicTrustRequestTypes;
    requestAgency?: SuitabilityAgencies;
    isPIVRequired?: boolean;
    suitabilityLevel?: string;
}

enum Labels {
    reqType = 'Request type',
    agency = 'Request agency',
    suitabilityLevel = 'Suitability level',
    PIVReq = 'PIV required',
}

const PIV_OPTIONS: IDropdownOption[] = [
    { key: 'true', text: 'Yes' },
    { key: 'false', text: 'No' },
];

function CandidateNominationStepTargetLevelPublicTrust(
    props: CandidateNominationStepTargetLevelProps,
): JSX.Element {
    const suitabilityLevelTypeOptions = useMemo(() => {
        return Object.keys(SuitabilityLevels).map((level) => ({
            key: level,
            text: SuitabilityLevels[level as keyof typeof SuitabilityLevels],
        }));
    }, [props.suitabilityLevel]);
    const publicTrustRequestTypeOptions = PUBLIC_TRUST_REQUEST_TYPE_OPTIONS.filter((options) => {
        return options.key !== nameof<typeof PublicTrustRequestTypes>('PeriodicReinvestigation');
    });

    return (
        <>
            <Stack tokens={{ childrenGap: 20 }}>
                <Dropdown
                    label={Labels.reqType}
                    required
                    options={publicTrustRequestTypeOptions}
                    selectedKey={props.requestType}
                    title={props.requestType}
                    onChange={(ev, option): void => {
                        if (option && option.key)
                            props.onRequestTypeChange(option.key as PublicTrustRequestTypes);
                    }}
                />
                <Dropdown
                    label={Labels.agency}
                    required
                    options={REQUEST_AGENCY_OPTIONS()}
                    selectedKey={props.requestAgency}
                    title={props.requestAgency}
                    onChange={(ev, option): void => {
                        if (option && option.key) {
                            props.onRequestAgencyChange(option.key as SuitabilityAgencies);
                        }
                    }}
                />
                <Dropdown
                    label={Labels.suitabilityLevel}
                    required={suitabilityLevelTypeOptions.length !== 0}
                    defaultSelectedKey={
                        suitabilityLevelTypeOptions.length !== 1
                            ? null
                            : suitabilityLevelTypeOptions[0].key.toString()
                    }
                    options={suitabilityLevelTypeOptions}
                    selectedKey={props.suitabilityLevel}
                    title={props.suitabilityLevel}
                    onChange={(ev, option): void => {
                        if (option && option.key)
                            props.onSuitabilityLevelChange(option.key as string);
                    }}
                />
                <Dropdown
                    label={Labels.PIVReq}
                    required
                    options={PIV_OPTIONS}
                    selectedKey={props.isPIVRequired?.toString()}
                    title={props.isPIVRequired?.toString()}
                    onChange={(ev, option): void => {
                        if (option && option.key)
                            props.onIsPIVRequiredChange(option.key === 'true');
                    }}
                />
            </Stack>
        </>
    );
}

export default CandidateNominationStepTargetLevelPublicTrust;
