import React, { useState, useEffect } from 'react';
import { ICollapseButton, Table } from 'components/common/table';
import {
    IColumn,
    Stack,
    IGroup,
    IGroupRenderProps,
    IGroupHeaderProps,
    Label,
    IDetailsHeaderStyles,
    MessageBar,
    MessageBarType,
    IStyle,
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import PieChart, { PieChartItem } from 'components/common/charts/pie-chart';
import { faintBlue } from 'assets/constants/global-colors';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import {
    customerMetricsStyles,
    expandButtonStyle,
    noExpandButtonStyle,
} from 'components/screening/common/common-tab-styling';
import { ICustomerMetricsSummaryData } from 'components/screening/us-gov/IScreening';
const contractOwner = 'Contract Owner';
const candidate = 'Candidate';
const usg = 'USG';

const processOwnerLabels = [UsGovScreeningUserType.NST, contractOwner, candidate, usg];
const pieChartColors = [
    SharedColors.cyanBlue10,
    SharedColors.blueMagenta20,
    SharedColors.magentaPink20,
    SharedColors.green10,
    SharedColors.orangeYellow20,
    SharedColors.orange20,
    SharedColors.red10,
];

export interface ScreeningMetricsProps {
    customerMetricsSummaryData: ICustomerMetricsSummaryData | undefined;
}

export default function Metrics(props: ScreeningMetricsProps): JSX.Element {
    const [isCollapsing, setCollapsing] = useState<boolean>(true);
    const [groups, setGroups] = useState<IGroup[] | undefined>(undefined);
    const [processOwnerGroups, setProcessOwnerGroups] = useState<IGroup[] | undefined>(undefined);
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
    const [total, setTotal] = useState<number>(0);
    const [pieData, setPieData] = useState<PieChartItem[]>([]);
    const [processOwnerPieData, setProcessOwnerPieData] = useState<PieChartItem[]>([]);
    const [errorMessage, setMessage] = useState<string>('');
    const [hasErrorOccurred, setErrorOccurred] = useState<boolean>(false);

    const collapseButton: ICollapseButton = {
        isVisible: true,
        isCollapsing: isCollapsing,
        style: { color: faintBlue },
        onToggleCollapseAll: (isAllCollapsed: boolean) => {
            collapseManagerDirectReports(isAllCollapsed);
        },
    };

    function collapseManagerDirectReports(isAllCollapsed: boolean): void {
        const newGroups: IGroup[] = [];
        if (groups) {
            groups.forEach((x) => {
                const copiedGroup: IGroup = {
                    ...x,
                    isCollapsed: isAllCollapsed,
                };
                newGroups.push(copiedGroup);
            });
            setGroups(newGroups);
        }
        setCollapsing(!isCollapsing);
    }

    function getColumns(processOwner?: boolean): IColumn[] {
        const statusStr = 'Status';
        const dayStr = 'Average Days';
        const percentageOfTime = 'Percentage of Time';
        return [
            {
                key: statusStr,
                name: statusStr,
                ariaLabel: statusStr,
                minWidth: 125,
                maxWidth: 125,
                isRowHeader: true,
                isMultiline: true,
                fieldName: statusStr,
                styles: {
                    root: {
                        alignItems: 'flex-start',
                        minWidth: processOwner ? 153 : 283,
                        maxWidth: processOwner ? 153 : 283,
                        marginLeft: processOwner ? 0 : -20,
                    },
                },
            },
            {
                key: dayStr,
                name: dayStr,
                ariaLabel: dayStr,
                minWidth: 75,
                maxWidth: 75,
                isMultiline: true,
                isRowHeader: false,
                styles: {
                    root: { alignItems: 'flex-start', minWidth: 75, maxWidth: 75 },
                },
            },
            {
                key: percentageOfTime,
                name: percentageOfTime,
                ariaLabel: percentageOfTime,
                minWidth: 95,
                maxWidth: 95,
                isMultiline: true,
                isRowHeader: false,
                styles: {
                    root: { alignItems: 'flex-start', minWidth: 95, maxWidth: 95 },
                },
            },
        ];
    }

    useEffect(() => {
        setErrorOccurred(false);
        setIsFetchingData(true);
        setProcessOwnerGroups(undefined);
        setGroups(undefined);

        if (props.customerMetricsSummaryData) {
            // map color indexes to actually color codes
            let pieChartData: PieChartItem[] = [];
            props.customerMetricsSummaryData.pieChartData.forEach((pieChartEntry) => {
                pieChartData = pieChartData.concat({
                    label: pieChartEntry.label,
                    percentage: pieChartEntry.percentage,
                    color: pieChartColors[pieChartEntry.colorIndex],
                } as PieChartItem);
            });

            // map color indexes to actually color codes
            let processOwnerPieChartData: PieChartItem[] = [];
            props.customerMetricsSummaryData.processOwnerPieChartData.forEach((pieChartEntry) => {
                processOwnerPieChartData = processOwnerPieChartData.concat({
                    label: pieChartEntry.label,
                    percentage: pieChartEntry.percentage,
                    color: pieChartColors[pieChartEntry.colorIndex],
                } as PieChartItem);
            });

            setTotal(props.customerMetricsSummaryData.total);
            setGroups(props.customerMetricsSummaryData.groups);
            setProcessOwnerGroups(props.customerMetricsSummaryData.processOwnerGroups);
            setPieData(pieChartData);
            setProcessOwnerPieData(processOwnerPieChartData);
        }

        setIsFetchingData(false);
    }, [props.customerMetricsSummaryData]);

    const groupProps: IGroupRenderProps = {
        showEmptyGroups: true,
        onRenderHeader: renderHeaderRow,
    };

    function renderHeaderRow(
        prop?: IGroupHeaderProps,
        defaultRender?: (props?: IGroupHeaderProps) => JSX.Element | null,
    ): JSX.Element | null {
        {
            if (prop) {
                prop.styles = {
                    expand: isManager(prop) ? expandButtonStyle : noExpandButtonStyle,
                    expandIsCollapsed: isManager(prop) ? expandButtonStyle : noExpandButtonStyle,
                };
            }

            return (
                <>
                    {defaultRender!({
                        ...prop,
                        onRenderTitle: renderDirectReportsTitleRows,
                    })}
                </>
            );
        }
    }

    function isManager(localProps: IGroupHeaderProps): boolean {
        return localProps.group !== undefined && localProps.group.count > 0;
    }

    // adjust the name (header) width based on the space taken by nested grouping indentation
    function getHeaderWidth(level: number | undefined, initialWidth: number): number {
        const calLevel = level !== undefined ? level : 0;
        return calLevel > 0 ? initialWidth - calLevel * tableIndentWidth : initialWidth;
    }

    function renderDirectReportsTitleRows(prop?: IGroupHeaderProps): JSX.Element {
        if (prop?.group?.key) {
            const isProcessOwner = processOwnerLabels.includes(prop.group.key);
            const percentageOfTime = ((prop.group.data / total) * 100).toFixed(1);
            return (
                <Stack horizontal>
                    <Stack.Item
                        styles={{
                            root: {
                                alignSelf: 'center',
                                minWidth: getHeaderWidth(
                                    prop.groupLevel,
                                    isProcessOwner
                                        ? directReportColumnStyles.processOwnerStatus.width
                                        : directReportColumnStyles.status.width,
                                ),
                                maxWidth: getHeaderWidth(
                                    prop.groupLevel,
                                    isProcessOwner
                                        ? directReportColumnStyles.processOwnerStatus.width
                                        : directReportColumnStyles.status.width,
                                ),
                                fontWeight: 'normal',
                                marginLeft: isProcessOwner ? -20 : 0,
                            },
                        }}>
                        {prop.group.name}
                    </Stack.Item>

                    <Stack.Item styles={directReportColumnStyles.clearance.stackStyles}>
                        {prop.group.data}
                    </Stack.Item>
                    <Stack.Item styles={directReportColumnStyles.averagePercentage.stackStyles}>
                        {percentageOfTime}%
                    </Stack.Item>
                </Stack>
            );
        }
        return <></>;
    }

    function closeMessage(): void {
        setErrorOccurred(false);
        setMessage('');
    }

    return (
        <div>
            {hasErrorOccurred && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel='Close'
                    onDismiss={closeMessage}
                    styles={{ root: { marginBottom: 15 } }}>
                    {errorMessage}
                </MessageBar>
            )}
            {!isFetchingData && (
                <div className={customerMetricsStyles.row}>
                    <div className={customerMetricsStyles.innerRow1}>
                        <div>
                            <Label className={customerMetricsStyles.labelHeader}>
                                Screenings by Status
                            </Label>
                        </div>
                        <div className={customerMetricsStyles.averageDaysDiv}>
                            Average Days: {total.toFixed(1)} days
                        </div>
                        <PieChart displayLegend pieChartItems={pieData} legendPosition='right' />
                    </div>
                    <div className={customerMetricsStyles.innerRow2}>
                        <div>
                            <Label className={customerMetricsStyles.labelHeader}>
                                Screenings by Process Owner
                            </Label>
                        </div>
                        <div className={customerMetricsStyles.averageDaysDiv}>
                            Average Days: {total.toFixed(1)} days
                        </div>
                        <PieChart
                            displayLegend
                            pieChartItems={processOwnerPieData}
                            legendPosition='right'
                        />
                    </div>
                </div>
            )}
            <Stack horizontal styles={{ root: { overflowX: 'auto', maxWidth: 890 } }}>
                <Table
                    key={`customer-metrics-table`}
                    collapseButton={collapseButton}
                    rows={[]} // don't need rows when there are groups and groupProps
                    isFetchingData={isFetchingData}
                    tableColumns={getColumns(false)}
                    shimmerLabel='Loading Statuses Metrics...'
                    groups={groups}
                    groupProps={groupProps}
                    groupNestingDepth={2}
                    indentWidth={15}
                    rootStyle={tableRootStyle}
                    detailsHeaderStyles={columnHeaderStyles}
                    compact={true}
                />
                <Table
                    key={`customer-time-with-metrics-table`}
                    collapseButton={processOwnerCollapseButton}
                    rows={[]} // don't need rows when there are groups and groupProps
                    isFetchingData={isFetchingData}
                    tableColumns={getColumns(true)}
                    shimmerLabel='Loading Process Owner Metrics...'
                    groups={processOwnerGroups}
                    groupProps={groupProps}
                    detailsHeaderStyles={columnHeaderStyles}
                    rootStyle={tableRootStyleProcessOwner}
                    compact={true}
                />
            </Stack>
        </div>
    );
}

const tableIndentWidth = 15;
const directReportColumnStyles = {
    status: {
        width: 290,
        styles: {
            root: { alignItems: 'flex-start', minWidth: 290, maxWidth: 290 },
        },
    },
    processOwnerStatus: {
        width: 150,
        styles: {
            root: { alignItems: 'flex-start', minWidth: 150, maxWidth: 150 },
        },
    },
    clearance: {
        width: 75,
        styles: {
            root: { alignItems: 'flex-start', minWidth: 75, maxWidth: 75 },
        },
        stackStyles: {
            root: {
                minWidth: 75,
                maxWidth: 75,
                alignSelf: 'center',
                paddingLeft: 0,
            },
        },
    },
    averagePercentage: {
        width: 130,
        styles: {
            root: { alignItems: 'flex-start', minWidth: 130, maxWidth: 130 },
        },
        stackStyles: {
            root: {
                minWidth: 130,
                maxWidth: 130,
                alignSelf: 'center',
                paddingLeft: 0,
            },
        },
    },
};

const tableRootStyle: IStyle = {
    overflow: 'hidden',
    selectors: {
        '& [role=row]': {
            backgroundColor: 'transparent',
        },
        '& [role=gridcell], & [role=rowheader]': {
            minHeight: '49px',
            display: 'flex',
            alignItems: 'center',
            padding: '0px 0px 0px 0px',
            borderBottom: 'none',
        },
    },
    maxWidth: 480,
};

const tableRootStyleProcessOwner: IStyle = {
    ...tableRootStyle,
    marginLeft: 50,
    maxWidth: 350,
};

// used to make column titles multi-lined
const columnHeaderStyles: Partial<IDetailsHeaderStyles> = {
    root: {
        selectors: {
            '.ms-DetailsHeader-cell': {
                whiteSpace: 'normal',
                textOverflow: 'clip',
                lineHeight: 'normal',
            },
            '.ms-DetailsHeader-cellTitle': {
                height: '100%',
                alignItems: 'center',
            },
        },
    },
};

const processOwnerCollapseButton: ICollapseButton = {
    isVisible: false,
};
