import {
    Dropdown,
    IDropdownOption,
    Label,
    mergeStyles,
    MessageBar,
    PrimaryButton,
    Separator,
    Spinner,
} from '@fluentui/react';
import { globalSeparatorStyles, globalStyles } from 'assets/styles/global-styles';
import FormsClient, { GenericFormRecord, GenericFormRecordHistoryItem } from 'clients/forms-client';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import { AuthContext } from 'contexts/auth-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { UserContext } from 'contexts/user-context';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormResponse } from 'components/forms/forms-common';
import { FormsReviewer } from 'components/forms/forms-reviewer';
import { FormsReviewInfoComments } from 'components/forms/forms-review-info-comments';
import 'components/forms/FormsReviewerDataLoader.css';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

type FormsReviewerDataLoaderProps = { formRecord?: GenericFormRecord };

const pageStyles = mergeStyles(globalStyles.antiGutter, {
    background: 'white',
    padding: '0 5rem',
});

const headingStyle = mergeStyles(globalStyles.boldFont, globalStyles.mediumLargeFont);

const separatorStyles = mergeStyles(globalSeparatorStyles, {
    padding: '0',
    height: '1px',
});

export function FormsReviewerDataLoader(props: FormsReviewerDataLoaderProps): JSX.Element {
    const { formRecord } = props;
    const [formResponse, setFormResponse] = useState<FormResponse>();
    const [currFormRecord, setCurrFormRecord] = useState<GenericFormRecord>();
    const [messageBarText, setMessageBarText] = useState<string>();
    const [recordHistory, setRecordHistory] = useState<GenericFormRecordHistoryItem[]>([]);
    const [currentHistoryComment, setCurrentHistoryComment] = useState<string>();
    const [historyOptions, setHistoryOptions] = useState<IDropdownOption[]>([]);
    const [selectedVersion, setSelectedVersion] = useState<string>();

    const authContext = useContext(AuthContext);
    const breadCrumbContext = useContext(BreadCrumbContext);
    const userContext = useContext(UserContext);

    const isUserSubmitter = userContext.employeeRecord.id === currFormRecord?.personnelId ?? true;
    const history = useHistory();
    const { name } = useParams<{ name?: string }>();
    const { id } = useParams<{ id: string }>();
    const hasEditFeatureFlagEnabled = useFeatureFlag(FeatureFlagKeys.formsEdit).enabled;

    useEffect(() => {
        const backCrumb = isUserSubmitter
            ? { title: 'My Submissions', link: `/forms/my submissions` }
            : { title: 'Manage', link: `/forms/manage` };
        const crumbs = [{ title: 'Forms', link: '' }, backCrumb, { title: `${id}`, link: '' }];
        breadCrumbContext.setBreadCrumbs(crumbs);
    }, [name, id, isUserSubmitter]);

    useEffect(() => {
        const setUpForm = async (): Promise<void> => {
            const formForViewer = await getFormForViewer();
            if (!formForViewer) {
                history.push('/forms');
                return;
            }
            setFormResponse(formForViewer);
        };
        setUpForm();

        if (hasEditFeatureFlagEnabled) {
            const getHistory = async (): Promise<void> => {
                const allNonDraftHistoryItems = await FormsClient.GetMyGenericFormRecordHistory(
                    authContext,
                    id,
                );

                setRecordHistory(allNonDraftHistoryItems);
                const historyOptions = allNonDraftHistoryItems.map(
                    (formRecordHistoryItem: GenericFormRecordHistoryItem) => {
                        return {
                            key: formRecordHistoryItem.id,
                            text: `${formRecordHistoryItem.genericFormRecord.formState.replace(
                                '_',
                                ' ',
                            )} ${new Date(formRecordHistoryItem.eventTimeStampUtc * 1000)}`,
                        };
                    },
                );
                setSelectedVersion(historyOptions[historyOptions.length - 1].key.toString());
                setHistoryOptions(historyOptions);
            };
            getHistory();
        }
        setUpForm();
    }, []);

    useEffect(() => {
        if (selectedVersion && recordHistory.length > 0) {
            const locatedRecord = recordHistory.find((r) => {
                return r.id === selectedVersion;
            });
            if (locatedRecord) {
                const convertedFormResponse = convertFormRecordToForm(
                    locatedRecord.genericFormRecord,
                );
                setCurrentHistoryComment(locatedRecord.genericFormRecord.comment);
                setFormResponse(convertedFormResponse);
            }
        }
    }, [selectedVersion]);

    const getFormForViewer = async (): Promise<FormResponse | undefined> => {
        if (formRecord) {
            setCurrFormRecord(formRecord);
            if (hasEditFeatureFlagEnabled) {
                setCurrentHistoryComment(formRecord.comment);
            }
            return convertFormRecordToForm(formRecord);
        } else if (id) {
            const newFormRecord = await getExistingFormRecord(id);
            if (!newFormRecord) {
                return;
            }
            return convertFormRecordToForm(newFormRecord);
        }
    };

    const getExistingFormRecord = async (
        formRecordId: string,
    ): Promise<GenericFormRecord | undefined> => {
        try {
            const existingFormRecord = await FormsClient.GetGenericFormRecordById(
                authContext,
                formRecordId,
            );
            setCurrFormRecord(existingFormRecord);
            return existingFormRecord;
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    const convertFormRecordToForm = (formRecord: GenericFormRecord): FormResponse => {
        const convertedForm: FormResponse = {
            id: formRecord.id,
            name: name ?? '',
            title: formRecord.title,
            description: formRecord.description,
            sections: JSON.parse(formRecord.schema),
        };
        return convertedForm;
    };

    const FormTitle = useMemo(() => {
        return <h2 className={headingStyle}>Form information</h2>;
    }, []);

    if (!formResponse) {
        return <Spinner />;
    }
    return (
        <>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            {messageBarText && <MessageBar>{messageBarText}</MessageBar>}
            <div className={pageStyles}>
                {currFormRecord && (
                    <FormsReviewInfoComments
                        isUserSubmitter={isUserSubmitter}
                        currentFormRecord={currFormRecord}
                        setCurrentFormRecord={setCurrFormRecord}
                        setMessageBarText={setMessageBarText}
                    />
                )}
                <Separator className={separatorStyles} />
                {hasEditFeatureFlagEnabled && (
                    <div>
                        <Dropdown
                            label='Previous Versions'
                            options={historyOptions}
                            onChange={(ev, opt): void => {
                                if (opt) {
                                    setSelectedVersion(opt.key.toString());
                                }
                            }}
                            defaultSelectedKey={selectedVersion}
                        />
                    </div>
                )}
                {FormTitle}
                <Separator className={separatorStyles} />
                {hasEditFeatureFlagEnabled && (
                    <div>
                        <div style={{ fontSize: '18px', fontWeight: '500' }}>Version Comments</div>
                        <div>{currentHistoryComment}</div>
                    </div>
                )}
                <FormsReviewer formResponse={formResponse} />
            </div>
        </>
    );
}
