import { FontWeights, mergeStyles } from '@fluentui/react';
import React, { ReactNode } from 'react';

export default function NominationSubHeader(props: { children: ReactNode }): JSX.Element {
    return <h2 className={styles}>{props.children}</h2>;
}

const styles = mergeStyles({
    fontFamily: 'Segoe UI',
    fontWeight: FontWeights.semibold,
    fontSize: 20,
    lineHeight: '28px',
    marginTop: 0,
    marginBottom: 0,
});
