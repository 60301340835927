import FacilitiesClient, {
    EquipmentActivityName,
    FacilityActivityName,
    FacilityBlockedUserActivityName,
    LogBookActivityName,
    ReservationActivityName,
    SeatActivityName,
    userFeedbackSubmittedActivityName,
} from 'clients/facilities-client';
import { FacilityUserType } from 'utils/facilities-utils';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import React, { useContext, useEffect, useState, FormEvent, useMemo, useCallback } from 'react';
import { useFetchSimple, useIsMounted } from 'utils/misc-hooks';
import {
    ActionButton,
    MessageBarType,
    Stack,
    Separator,
    Dropdown,
    IDropdownOption,
    mergeStyleSets,
    DatePicker,
} from '@fluentui/react';
import useMessageBar from 'components/common/use-message-bar';
import { removeUnderscoreAndCapitalize } from 'utils/string-utils';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import { globalFilterSeparatorStyles } from 'assets/styles/global-styles';
import Spacer from 'components/common/spacer';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import { getDate, TimeFormats } from 'utils/time-utils';
import SecureWorkAreaDropdownSelector from 'components/facilities/common/secure-work-area-dropdown-selector';
import { IconNames } from 'assets/constants/global-constants';
import ActivitiesClient, { IActivityReadRequest, IActivity, IActivitiesResponse } from 'clients/activities-client'; // prettier-ignore
import { chunk, doNothing } from 'utils/misc-utils';
import Event, { ActivityEventBin, binSortEvents } from 'components/common/event';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import EmployeeClient from 'clients/employee-client';
import { CacheContext } from 'contexts/cache-context';
import { fetchPictures } from 'components/common/employee/employee-utils';
import { CheckScrollReachedBottom } from 'components/common/scroll-event-listener';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import moment from 'moment';
import { CorePrincipalsClient } from 'clients/core/personnel-core-client-wrappers';
import { IPrincipalGetByIdsResult, PrincipalGetByIdsRequest } from 'personnel-core-clients';
import { resultDropdownOptions } from 'components/personnel-profile/clearance/profile-clearance-constants';
import { CoreSingleExternalIdPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';

const activityPageSize = 100;
const PrincipalGetByIdsRequestMaxRequestedIds = 500;

type ExcelRecordType = {
    facilityName: string;
    facilityId: string;
    upn: string;
    firstName: string;
    lastName: string;
    displayName: string;
    personnelId: string;
    eventId: string;
    eventType: string;
    eventTimestamp: string;
    eventDate: string;
    eventTime: string;
    eventTimezone: string;
};

const AdditionalAttributesInExcelReport: { [key: string]: string } = {
    hierarchyLevel2PersonnelNumber: 'HRProfile_HierarchyL2PersonnelNumber',
    hierarchyLevel2Upn: '',
    hierarchyLevel3PersonnelNumber: 'HRProfile_HierarchyL3PersonnelNumber',
    hierarchyLevel3Upn: '',
    hierarchyLevel4PersonnelNumber: 'HRProfile_HierarchyL4PersonnelNumber',
    hierarchyLevel4Upn: '',
    hierarchyLevel5PersonnelNumber: 'HRProfile_HierarchyL5PersonnelNumber',
    hierarchyLevel5Upn: '',
    hierarchyLevel6PersonnelNumber: 'HRProfile_HierarchyL6PersonnelNumber',
    hierarchyLevel6Upn: '',
    hierarchyLevel7PersonnelNumber: 'HRProfile_HierarchyL7PersonnelNumber',
    hierarchyLevel7Upn: '',
    hierarchyLevel8PersonnelNumber: 'HRProfile_HierarchyL8PersonnelNumber',
    hierarchyLevel8Upn: '',
    hierarchyLevel9PersonnelNumber: 'HRProfile_HierarchyL9PersonnelNumber',
    hierarchyLevel9Upn: '',
    hierarchyLevel10PersonnelNumber: 'HRProfile_HierarchyL10PersonnelNumber',
    hierarchyLevel10Upn: '',
    hierarchyLevel11PersonnelNumber: 'HRProfile_HierarchyL11PersonnelNumber',
    hierarchyLevel11Upn: '',
    hierarchyLevel12PersonnelNumber: 'HRProfile_HierarchyL12PersonnelNumber',
    hierarchyLevel12Upn: '',
    hierarchyLevel13PersonnelNumber: 'HRProfile_HierarchyL13PersonnelNumber',
    hierarchyLevel13Upn: '',
    hierarchyLevel14PersonnelNumber: 'HRProfile_HierarchyL14PersonnelNumber',
    hierarchyLevel14Upn: '',
    hierarchyLevel15PersonnelNumber: 'HRProfile_HierarchyL15PersonnelNumber',
    hierarchyLevel15Upn: '',
    hierarchyLevel16PersonnelNumber: 'HRProfile_HierarchyL16PersonnelNumber',
    hierarchyLevel16Upn: '',
    hierarchyLevel17PersonnelNumber: 'HRProfile_HierarchyL17PersonnelNumber',
    hierarchyLevel17Upn: '',
};

const AdditionalAttributesInExcelReportDefaults = { ...AdditionalAttributesInExcelReport };
for (const additionalAttribute in AdditionalAttributesInExcelReportDefaults) {
    AdditionalAttributesInExcelReportDefaults[additionalAttribute] = '';
}

interface IFacilityRecordPartial {
    id: string;
    facilityName: string;
    timeZone: string;
}

type FacilitiesById = {
    [key: string]: IFacilityRecordPartial;
};

function yyyymmddToLocalLongDateFormat(yyyymmdd: string): string {
    const formattedWithDashes =
        yyyymmdd.substring(0, 4) + '-' + yyyymmdd.substring(4, 6) + '-' + yyyymmdd.substring(6);

    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
    }).format(new Date(formattedWithDashes));
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FacilitiesAuditPageProps {}

export default function FacilitiesAuditPage(props: FacilitiesAuditPageProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const cacheContext = useContext(CacheContext);
    const [facilities, setFacilities] = useState<IFacilityRecordPartial[]>();
    const [selectedFacility, setSelectedFacility] = useState<IFacilityRecordPartial>();
    const [subject, setSubject] = useState<string | undefined>();
    const [eventTypes, setEventTypes] = useState<string[]>([]);
    const [fromDate, setFromDate] = useState<Date>(getDate().Today);
    const [toDate, setToDate] = useState<Date>(getDate().EndOfToday);
    const [isFetchingFacilities, setFetchingFacilities] = useState<boolean>(false);
    const [shouldFetchActivities, setShouldFetchActivities] = useState<boolean>(false);
    const [facilitiesEventBins, setFacilitiesEventBins] = useState<ActivityEventBin[]>();
    const [isFetchingAdditionalData, setFetchingAdditionalData] = useState<boolean>(false);
    const [activitiesContinuationToken, setActivitiesContinuationToken] = useState<string>();

    const isMounted = useIsMounted();

    useEffect(() => {
        if (userContext.isFacilitiesTokenLoaded) {
            const hasAccess = isFacilityManager || isFacilityAdmin || isEquipmentOfficer;
            if (hasAccess) getFacilities();
        }
    }, [userContext.isFacilitiesTokenLoaded]);

    useEffect(() => {
        // Get activities for default filters
        if (facilities) onApplyFilters();
    }, [facilities]);

    const {
        theMessage: errorFetchingData,
        theElement: errorFetchingDataElement,
        setMessage: setErrorFetchingData,
    } = useMessageBar({
        type: MessageBarType.error,
    });

    const facilityDropdownOptions = Object.values(FacilityActivityName).map((x) => {
        return { key: x.toString(), text: removeUnderscoreAndCapitalize(x.toString()) };
    });

    const seatDropdownOptions = Object.values(SeatActivityName).map((x) => {
        return { key: x.toString(), text: removeUnderscoreAndCapitalize(x.toString()) };
    });

    const reservationDropdownOptions = Object.values(ReservationActivityName).map((x) => {
        return { key: x.toString(), text: removeUnderscoreAndCapitalize(x.toString()) };
    });

    const equipmentDropdownOptions = Object.values(EquipmentActivityName).map((x) => {
        return { key: x.toString(), text: removeUnderscoreAndCapitalize(x.toString()) };
    });

    const logBookDropdownOptions = Object.values(LogBookActivityName).map((x) => {
        return { key: x.toString(), text: removeUnderscoreAndCapitalize(x.toString()) };
    });

    const blockedFacilityUserDropdownOptions = Object.values(FacilityBlockedUserActivityName).map(
        (x) => {
            return { key: x.toString(), text: removeUnderscoreAndCapitalize(x.toString()) };
        },
    );

    const userFeedbackDropdownOptions = {
        key: userFeedbackSubmittedActivityName.toString(),
        text: removeUnderscoreAndCapitalize(userFeedbackSubmittedActivityName.toString()),
    };

    const dropdownOptions = facilityDropdownOptions.concat(
        seatDropdownOptions,
        reservationDropdownOptions,
        equipmentDropdownOptions,
        logBookDropdownOptions,
        blockedFacilityUserDropdownOptions,
        userFeedbackDropdownOptions,
    );

    const isFacilityManager = userContext.hasFacilitiesUserType(FacilityUserType.ManagerRole);
    const isFacilityAdmin = userContext.hasFacilitiesUserType(FacilityUserType.AdminService);
    const isEquipmentOfficer = userContext.hasFacilitiesUserType(
        FacilityUserType.EquipmentOfficerRole,
    );

    const facilitiesById = useMemo(
        (): FacilitiesById =>
            (facilities ?? []).reduce(
                (a: FacilitiesById, facility: IFacilityRecordPartial) => ({
                    ...a,
                    [facility.id]: facility,
                }),
                {} as FacilitiesById,
            ),
        [facilities],
    );

    function getTimezoneForActivity(activity: IActivity): string | undefined {
        const facilityId = activity.additionalObjects.find((x) => x.type === 'facilityId')?.value;

        if (facilityId === undefined) {
            return undefined;
        }

        return facilitiesById[facilityId]?.timeZone;
    }

    function activityBinToDate(activity: IActivity): number {
        return Number(
            moment
                .tz(activity.eventTimestampUTC, getTimezoneForActivity(activity) ?? 'UTC')
                .format('YYYYMMDD'),
        );
    }

    function activityBinToTime(activity: IActivity): number {
        return Number(
            moment
                .tz(activity.eventTimestampUTC, getTimezoneForActivity(activity) ?? 'UTC')
                .format('HHmmss'),
        );
    }

    const { isFetching: isFetchingActivities } = useFetchSimple<IActivitiesResponse>({
        dependencies: [
            selectedFacility,
            shouldFetchActivities,
            activitiesContinuationToken,
            eventTypes,
            fromDate,
            toDate,
        ],
        canPerformFetch: !!selectedFacility && shouldFetchActivities,
        fetchFunc: async () => {
            setShouldFetchActivities(false);
            setErrorFetchingData(undefined);

            const facilitiesToken = await FacilitiesClient.getFacilitiesToken(authContext);
            if (facilitiesToken) {
                const activitiesToken = await FacilitiesClient.getFacilityActivityToken(
                    authContext,
                    facilitiesToken,
                );

                let referenceIds: string[] | undefined = undefined;

                if (selectedFacility) {
                    referenceIds =
                        selectedFacility.id === 'all_facilities'
                            ? Object.keys(facilitiesById)
                            : [selectedFacility.id];
                }

                const activityRequest: IActivityReadRequest = {
                    events: eventTypes.length > 0 ? eventTypes : undefined,
                    referenceIds: referenceIds,
                    startTimeUtc: fromDate.getTime(),
                    endTimeUtc: toDate.getTime(),
                };

                if (subject) {
                    activityRequest.subjectTypes = ['personnelId'];
                    activityRequest.subjectValues = [subject];
                }

                const activitiesResponse = await ActivitiesClient.getFacilityActivitiesForUserActivityEvents(
                    authContext,
                    activitiesToken,
                    facilitiesToken,
                    activityRequest,
                    activityPageSize,
                    activitiesContinuationToken,
                );

                if (isMounted()) {
                    setActivitiesContinuationToken(activitiesResponse.continuationToken);
                    await cacheAdditionalDataAndUpdateEventBins(activitiesResponse.result);
                }

                return activitiesResponse;
            }
            throw 'Failed to retrieve facilities token';
        },
        onSuccess: doNothing,
        onError: () => {
            if (isMounted()) {
                setErrorFetchingData(
                    'Failed to retrieve facilities token, activity token, or activities',
                );
            }
        },
        onFinally: doNothing,
    });

    const onApplyFilters = (): void => {
        setFacilitiesEventBins(undefined);
        setActivitiesContinuationToken(undefined);
        setShouldFetchActivities(true);
    };

    const onClearFilters = (): void => {
        setEventTypes([]);
        setFromDate(getDate().Today);
        setToDate(getDate().EndOfToday);
        setSubject(undefined);
        onApplyFilters();
    };

    const getFacilities = async (): Promise<void> => {
        try {
            setFetchingFacilities(true);

            let facilitiesVar: IFacilityRecordPartial[] = [];
            if (isEquipmentOfficer) {
                facilitiesVar = await FacilitiesClient.getFacilityRecordsEquipmentOfficer(
                    authContext,
                    userContext,
                );
            } else if (isFacilityAdmin) {
                facilitiesVar = await FacilitiesClient.getFacilityRecords(authContext, userContext);
                facilitiesVar.push({
                    id: 'all_facilities',
                    facilityName: 'All',
                    timeZone: '',
                });
            } else if (isFacilityManager) {
                facilitiesVar = await FacilitiesClient.getFacilityRecordsManager(
                    authContext,
                    userContext,
                );
            } else {
                setErrorFetchingData("User doesn't have permission to retrieve facilities");
                return;
            }

            if (isMounted()) {
                setFacilities(facilitiesVar);
                setSelectedFacility(facilitiesVar.length > 0 ? facilitiesVar[0] : undefined);
            }
        } catch (error) {
            setErrorFetchingData('Failed to retrieve facilities');
        } finally {
            setFetchingFacilities(false);
        }
    };

    function onScrollReachedBottom(): void {
        if (!!activitiesContinuationToken && !isFetchingActivities) {
            setShouldFetchActivities(true);
        }
    }

    async function cacheAdditionalDataAndUpdateEventBins(activities: IActivity[]): Promise<void> {
        if (!activities || activities.length === 0) {
            return;
        }

        try {
            setFetchingAdditionalData(true);
            const personnelIds = new Set(activities.map((x) => x.subject.value));
            // employee records now cached
            const basicEmployees = await EmployeeClient.getBasicEmployeesById(
                authContext,
                Array.from(personnelIds),
            );
            // photos now cached
            await fetchPictures(authContext, basicEmployees, undefined, cacheContext);

            if (isMounted()) {
                setFacilitiesEventBins((prevEventBins) =>
                    binSortEvents(
                        activities,
                        prevEventBins ?? [],
                        activityBinToDate,
                        activityBinToTime,
                    ),
                );
            }
        } catch (error) {
            if (isMounted()) {
                setErrorFetchingData(
                    'Failed to retrieve additional data associated with the events',
                );
            }
        } finally {
            if (isMounted()) {
                setFetchingAdditionalData(false);
            }
        }
    }

    function isFetching(): boolean {
        return isFetchingFacilities || isFetchingActivities;
    }

    const onEventTypeChange = useCallback(
        (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
            if (option && option.key) {
                const eventType = eventTypes.find((x) => x === option.key);
                if (eventType) {
                    setEventTypes((prevEventTypes) =>
                        prevEventTypes.filter((x) => x !== option.key),
                    );
                } else {
                    setEventTypes((prevEventTypes) => {
                        const newEventTypes = [...prevEventTypes];
                        newEventTypes.push(option.key.toString());
                        return newEventTypes;
                    });
                }
            }
        },
        [eventTypes],
    );

    async function onFacilityChange(
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): Promise<void> {
        if (option && option.key) {
            setSelectedFacility(facilities?.find((x) => x.id === option.key));
            onApplyFilters();
        }
    }

    async function getExcelRecords(): Promise<ExcelRecordType[]> {
        const facilitiesToken = await FacilitiesClient.getFacilitiesToken(authContext);
        if (!facilitiesToken) {
            return [];
        }

        const activitiesToken = await FacilitiesClient.getFacilityActivityToken(
            authContext,
            facilitiesToken,
        );

        let referenceIds: string[] | undefined = undefined;

        if (selectedFacility) {
            referenceIds =
                selectedFacility.id === 'all_facilities'
                    ? Object.keys(facilitiesById)
                    : [selectedFacility.id];
        }

        const activityRequest: IActivityReadRequest = {
            events: eventTypes.length > 0 ? eventTypes : undefined,
            referenceIds: referenceIds,
            startTimeUtc: fromDate.getTime(),
            endTimeUtc: toDate.getTime(),
        };

        if (subject) {
            activityRequest.subjectTypes = ['personnelId'];
            activityRequest.subjectValues = [subject];
        }

        const records: ExcelRecordType[] = [];
        let activitiesResponse: IActivitiesResponse | undefined = undefined;

        let continuationToken: string | undefined = undefined;

        do {
            activitiesResponse = await ActivitiesClient.getFacilityActivitiesForUserActivityEvents(
                authContext,
                activitiesToken,
                facilitiesToken,
                activityRequest,
                5000,
                continuationToken,
            );
            continuationToken = activitiesResponse.continuationToken;

            for (const activity of activitiesResponse.result) {
                if (
                    activity.event === LogBookActivityName.BookLogCreated ||
                    activity.event === LogBookActivityName.BookLogDeleted
                ) {
                    const facilityId =
                        activity.additionalObjects.find((x) => x.type === 'facilityId')?.value ??
                        '';

                    const timeZone = getTimezoneForActivity(activity) ?? 'UTC';

                    const date = moment
                        .tz(activity.eventTimestampUTC, timeZone)
                        .format('YYYY-MM-DD');

                    const timestamp = moment
                        .tz(activity.eventTimestampUTC, timeZone)
                        .format('YYYY-MM-DDTHH:mm:ssZ');

                    const time = moment.tz(activity.eventTimestampUTC, timeZone).format('HH:mm:ss');

                    records.push({
                        facilityName: activity.metadata[facilityId] ?? '',
                        facilityId: facilityId,
                        upn: '',
                        firstName: '',
                        lastName: '',
                        displayName: '',
                        personnelId: activity.subject?.value,
                        eventId: activity.id,
                        eventType: removeUnderscoreAndCapitalize(activity.event),
                        eventTimestamp: timestamp,
                        eventDate: date,
                        eventTime: time,
                        eventTimezone: timeZone,
                        ...AdditionalAttributesInExcelReportDefaults,
                    });
                }
            }
        } while (continuationToken);

        // Create map of principal info filled with the Personnel IDs we are exporting
        const principalInfo: { [key: string]: IPrincipalGetByIdsResult | null } = {};
        records.forEach((record) => (principalInfo[record.personnelId] = null));

        const personnelIdBatches = chunk(
            Object.keys(principalInfo),
            PrincipalGetByIdsRequestMaxRequestedIds,
        );

        const principalClient = new CorePrincipalsClient(authContext);

        const managerPrincipalInfo: { [key: string]: IPrincipalGetByIdsResult | null } = {};

        for (const personnelIdsInBatch of personnelIdBatches) {
            const result = await principalClient.getByIds({
                externalIds: personnelIdsInBatch,
                attributeIds: Object.values(AdditionalAttributesInExcelReport).filter(
                    (x) => x !== '',
                ),
            } as PrincipalGetByIdsRequest);

            result.forEach((principalResult) => {
                principalInfo[principalResult.externalId] = principalResult;

                principalResult.assignments.forEach((assignment) => {
                    if (
                        assignment.attributeId.endsWith('PersonnelNumber') &&
                        assignment.value &&
                        Number.parseInt(assignment.value) !== Number.NaN
                    ) {
                        managerPrincipalInfo[assignment.value] = null;
                    }
                });
            });
        }

        const managerPersonnelIdBatches = chunk(
            Object.keys(managerPrincipalInfo),
            PrincipalGetByIdsRequestMaxRequestedIds,
        );

        for (const personnelIdsInBatch of managerPersonnelIdBatches) {
            const result = await principalClient.getByIds({
                externalIds: personnelIdsInBatch,
            } as PrincipalGetByIdsRequest);

            result.forEach(
                (principalResult) =>
                    (managerPrincipalInfo[principalResult.externalId] = principalResult),
            );
        }

        records.forEach((record) => {
            const principalInfoForRecord = principalInfo[record.personnelId];
            if (principalInfoForRecord) {
                record.upn = principalInfoForRecord.upn ?? '';
                record.firstName = principalInfoForRecord.firstName ?? '';
                record.lastName = principalInfoForRecord.lastName ?? '';
                record.displayName = principalInfoForRecord.displayName ?? '';
                for (const additionalAttributeHeader in AdditionalAttributesInExcelReport) {
                    const additionalAttributeId =
                        AdditionalAttributesInExcelReport[additionalAttributeHeader];

                    if (additionalAttributeId === '' && additionalAttributeHeader.endsWith('Upn')) {
                        const personnelNumberHeader =
                            additionalAttributeHeader.substring(
                                0,
                                additionalAttributeHeader.lastIndexOf('Upn'),
                            ) + 'PersonnelNumber';

                        const personnelNumberAttributeId =
                            AdditionalAttributesInExcelReport[personnelNumberHeader];

                        const personnelNumber = principalInfoForRecord.assignments?.find(
                            (assignment) => assignment.attributeId === personnelNumberAttributeId,
                        )?.value;

                        const managerInfo = managerPrincipalInfo[personnelNumber];

                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (record as any)[additionalAttributeHeader] = managerInfo?.upn ?? '';
                    } else {
                        const attributeAssignment = principalInfoForRecord.assignments?.find(
                            (assignment) => assignment.attributeId === additionalAttributeId,
                        );

                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (record as any)[additionalAttributeHeader] =
                            attributeAssignment?.value ?? '';
                    }
                }
            }
        });

        // If no records are returned the CSV generation fails, return an fully empty record instead.
        if (records.length === 0) {
            records.push({
                facilityName: '',
                facilityId: '',
                upn: '',
                firstName: '',
                lastName: '',
                displayName: '',
                personnelId: '',
                eventId: '',
                eventType: '',
                eventTimestamp: '',
                eventDate: '',
                eventTime: '',
                eventTimezone: '',
                ...AdditionalAttributesInExcelReportDefaults,
            });
        }

        return records;
    }

    return (
        <>
            {!!errorFetchingData && errorFetchingDataElement()}
            <SidebarAndContents>
                <SidebarPane>
                    <SecureWorkAreaDropdownSelector
                        onChange={onFacilityChange}
                        placeholder='Select a facility'
                        selectedKey={selectedFacility?.id}
                        options={facilities?.map((x) => {
                            return {
                                key: x.id,
                                text: x.facilityName,
                            };
                        })}
                        separatorStyles={globalFilterSeparatorStyles}
                    />
                    <Spacer marginTop={5} />
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        From Date
                    </Separator>
                    <DatePicker
                        placeholder='Select from date'
                        allowTextInput={true}
                        value={fromDate}
                        onSelectDate={(newDate?: Date | null): void => {
                            if (newDate && newDate.getTime() <= toDate.getTime()) {
                                newDate.setHours(0, 0, 0, 0);
                                setFromDate(newDate);
                            }
                        }}
                        ariaLabel='From Date'
                    />
                    <Spacer marginTop={5} />
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        To Date
                    </Separator>
                    <DatePicker
                        placeholder='Select to date'
                        allowTextInput={true}
                        value={toDate}
                        onSelectDate={(newDate?: Date | null): void => {
                            if (newDate && newDate.getTime() >= fromDate.getTime()) {
                                newDate.setHours(23, 59, 59, 999);
                                setToDate(newDate);
                            }
                        }}
                        ariaLabel='To Date'
                    />
                    <Spacer marginTop={5} />
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        Employee
                    </Separator>
                    <CoreSingleExternalIdPickerTypeaheadSearch
                        selectedItem={subject}
                        onChange={setSubject}
                    />
                    <Spacer marginTop={5} />
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        Event Type
                    </Separator>
                    <Dropdown
                        onChange={onEventTypeChange}
                        options={dropdownOptions}
                        placeholder={'Select event types'}
                        multiSelect={true}
                        selectedKeys={eventTypes}
                        ariaLabel={'Event Type Dropdown'}
                    />
                    <Spacer marginTop={20} />
                    <Stack horizontal horizontalAlign='space-between'>
                        <Stack.Item>
                            <ActionButton
                                disabled={isFetching()}
                                iconProps={{ iconName: IconNames.Refresh }}
                                onClick={onApplyFilters}>
                                Apply Filters
                            </ActionButton>
                        </Stack.Item>
                        <Stack.Item>
                            <ActionButton
                                disabled={isFetching()}
                                iconProps={{ iconName: IconNames.ClearFilter }}
                                onClick={onClearFilters}>
                                Clear filters
                            </ActionButton>
                        </Stack.Item>
                    </Stack>
                </SidebarPane>
                <ContentPane>
                    <HorizontalBar>
                        <Stack.Item className={horizontalBarTitleStyle} grow={100}>
                            Audit
                        </Stack.Item>
                        <Stack.Item>
                            <ExportToExcelButton<ExcelRecordType>
                                getData={getExcelRecords}
                                buttonTitle='Download report'
                                fileNamePrefix={'facilities-audit'}
                                fileNameTimeFormat={TimeFormats.MM_DD_YYYY_HHmm}
                                formatHeader={true}
                                disabled={
                                    isFetchingFacilities ||
                                    isFetchingActivities ||
                                    isFetchingAdditionalData ||
                                    !facilitiesEventBins
                                }
                            />
                        </Stack.Item>
                    </HorizontalBar>
                    {facilitiesEventBins &&
                        facilitiesEventBins
                            .map(
                                (obj: ActivityEventBin): JSX.Element => {
                                    return (
                                        <div key={`activities_${obj.key}`}>
                                            <div
                                                key={`eventDay_${obj.key}`}
                                                className={styles.eventDateRow}>
                                                <div className={styles.eventLine}></div>
                                                <div className={styles.eventRowGap}></div>
                                                <div>
                                                    {yyyymmddToLocalLongDateFormat(String(obj.key))}
                                                </div>
                                            </div>
                                            {obj.activities
                                                .map(
                                                    (activity: IActivity): JSX.Element => (
                                                        <Event
                                                            key={`event_${activity.id}`}
                                                            activity={activity}
                                                            screeningId={''}
                                                            timeZone={getTimezoneForActivity(
                                                                activity,
                                                            )}
                                                            displayTimeZone
                                                        />
                                                    ),
                                                )
                                                .reverse()}
                                        </div>
                                    );
                                },
                            )
                            .reverse()}
                    <IsLoadingIndicator
                        isLoading={isFetchingFacilities}
                        before={<Spacer marginTop={20} />}
                        msg='Loading facilities...'
                    />
                    <IsLoadingIndicator
                        isLoading={isFetchingActivities && !isFetchingAdditionalData}
                        before={<Spacer marginTop={20} />}
                        msg='Loading events...'
                    />
                    <IsLoadingIndicator
                        isLoading={isFetchingAdditionalData}
                        before={<Spacer marginTop={20} />}
                        msg='Loading additional data...'
                    />
                    <CheckScrollReachedBottom
                        shouldCheck={!!activitiesContinuationToken}
                        onScrollReachedBottom={onScrollReachedBottom}
                    />
                </ContentPane>
            </SidebarAndContents>
        </>
    );
}

const styles = mergeStyleSets({
    eventLine: {
        height: '1px',
        marginTop: '10px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        flexGrow: 1,
    },
    eventDateRow: {
        marginBottom: '10px',
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'row',
    },
    eventRowGap: {
        width: '10px',
    },
});
