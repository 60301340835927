import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
    headerBannerData,
    HeaderSize,
    HeaderItem,
} from 'components/common/page-shell/header-banner-data';
import headerImage from 'assets/img/header-background.png';
import { mergeStyles } from '@fluentui/react';
import { MIN_HEIGHT_HEADER_PX } from 'components/common/page-shell/header';
import { AuthContext } from 'contexts/auth-context';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

const commonContainerStyles = mergeStyles({
    backgroundImage: `linear-gradient(to right, transparent 1070px, black 1720px), url(${headerImage})`,
    backgroundSize: '1720px',
    backgroundPositionY: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'black',
    color: 'white',
    lineHeight: 'initial',
    marginBottom: -MIN_HEIGHT_HEADER_PX + 16, //TODO: When all pages have a banner, we can adjust gutter content paddingTop instead of adding negative margin
});
const largeContainerStyles = mergeStyles(commonContainerStyles, {
    height: '295px',
    padding: '0 5rem 0 6rem',
    paddingTop: MIN_HEIGHT_HEADER_PX,
});

const smallContainerStyles = mergeStyles(commonContainerStyles, {
    height: '142px',
    paddingLeft: '6rem',
    paddingTop: MIN_HEIGHT_HEADER_PX,
});

const commonTitleStyles = mergeStyles({
    marginTop: '0',
    marginBottom: '0',
    fontWeight: '400',
});
const largeTitleStyles = mergeStyles(commonTitleStyles, {
    fontSize: '42px',
    paddingTop: '3rem',
    width: '41rem',
});
const smallTitleStyles = mergeStyles(commonTitleStyles, {
    fontSize: '36px',
    paddingTop: '.75rem',
});

const descriptionStyles = mergeStyles({
    fontSize: '24px',
    fontWeight: '400',
});

/**
 * Determine which path matches the passed in path most closely.
 * For example, if we have paths for '/profile/me' and '/profile/me/info', and the passed in path is
 * 'profile/me/info/attributes', the closest matching path is 'profile/me/info' since it's a prefix of the path
 *  and has a deeper path nesting (the number of / in the route)
 * @param path The current path to check
 * @param items The list of header items
 * @returns The closest path match, defined by the deepest path that starts with the passed in path.
 */
const getClosestPathMatch = (path: string, items: HeaderItem[]): HeaderItem | undefined => {
    if (path === '/') {
        return items.find((item) => item.path === path);
    }

    const normalizedPath = path.endsWith('/') ? path.slice(0, -1) : path;
    const exactMatch = items.find((item) => item.path === normalizedPath);
    if (exactMatch) {
        return exactMatch;
    }

    const matchingPaths = items.filter((item) => item.path !== '/' && path.startsWith(item.path));
    if (matchingPaths.length === 0) {
        return undefined;
    }
    matchingPaths.sort((a, b) => {
        return (b.path.match(/\//g) || []).length - (a.path.match(/\//g) || []).length;
    });
    return matchingPaths[0];
};

export function HeaderBanner(): JSX.Element {
    const location = useLocation();
    const authContext = useContext(AuthContext);
    const isBannerEnabled = useFeatureFlag(FeatureFlagKeys.headerBanner).enabled;
    const isKioskMode = authContext.isKioskLandingRenderMode() || authContext.isKioskRenderMode();

    const LargeBanner = (item: HeaderItem) => {
        return (
            <div className={largeContainerStyles}>
                <h1 className={largeTitleStyles}>{item.title}</h1>
                <h2 className={descriptionStyles}>{item.description}</h2>
                <ul>
                    {item.additionalText?.map((x) => (
                        <li key={x}>{x}</li>
                    ))}
                </ul>
            </div>
        );
    };
    const SmallBanner = (item: HeaderItem) => {
        return (
            <div className={smallContainerStyles}>
                <h1 className={smallTitleStyles}>{item.title}</h1>
                <h2 className={descriptionStyles}>{item.description}</h2>
                <ul>
                    {item.additionalText?.map((x) => (
                        <li key={x}>{x}</li>
                    ))}
                </ul>
            </div>
        );
    };

    const currentHeaderData = getClosestPathMatch(location.pathname, headerBannerData);
    if (isKioskMode || !isBannerEnabled || !currentHeaderData) {
        return <></>;
    } else {
        const isLarge = currentHeaderData.size === HeaderSize.large;
        if (isLarge) {
            return LargeBanner(currentHeaderData);
        }
        return SmallBanner(currentHeaderData);
    }
}
