import React, { createContext, useState } from 'react';
import { IContract } from 'components/screening/us-gov/IContract';

export enum filterContractsKeys {
    contractIdKey = 'contractId',
    typeIdKey = 'typeId',
    customerIdKey = 'customerId',
    projectIdKey = 'projectId',
    ownerIdKey = 'ownerId',
    statusIdKey = 'statusId',
}

interface FiltersContractsProviderProps {
    children: React.ReactNode;
    contractId?: string;
    typeId?: string;
    customerId?: string;
    projectId?: string;
    ownerId?: string;
    statusId?: string;
}

//review this filter for possible improvement
function FiltersContractsProvider(props: FiltersContractsProviderProps): JSX.Element {
    const [contractId, setContractId] = useState<string>(props.contractId || '');
    const [typeId, setTypeId] = useState<string>(props.typeId || '');
    const [customerId, setCustomerId] = useState<string>(props.customerId || '');
    const [projectId, setProjectId] = useState<string>(props.projectId || '');
    const [ownerId, setOwnerId] = useState<string>(props.ownerId || '');
    const [statusId, setStatusId] = useState<string>(props.statusId || '');
    const [filterFunctionsMap] = useState<Map<string, any[]>>(new Map<string, any[]>());
    const [filterCleared, setFilterCleared] = useState<string>('');

    function clearFilters(): void {
        // all these variables above ^^ need to get cleared
        filterFunctionsMap.clear();
        [
            setContractId,
            setOwnerId,
            setCustomerId,
            setProjectId,
            setStatusId,
            setTypeId,
        ].forEach((set) => set(''));
        setFilterCleared(Date.now().toString());
    }

    function updateFilter(
        filterFunctionsMap: Map<string, any[]>,
        key: string,
        func: (arg: IContract) => boolean,
        adding: boolean,
    ): void {
        if (
            [
                filterContractsKeys.contractIdKey.valueOf(),
                filterContractsKeys.typeIdKey.valueOf(),
                filterContractsKeys.ownerIdKey.valueOf(),
                filterContractsKeys.customerIdKey.valueOf(),
                filterContractsKeys.projectIdKey.valueOf(),
            ].includes(key)
        ) {
            filterFunctionsMap.set(key, [func]);
        } else if (filterFunctionsMap && adding) {
            let filters = filterFunctionsMap.get(key);
            if (!filters) {
                filters = [];
            }
            filters.push(func);
            filterFunctionsMap.set(key, filters);
        } else if (filterFunctionsMap && !adding) {
            let filters = filterFunctionsMap.get(key);
            if (!filters) {
                filters = [];
            }
            filters = filters.filter((x) => {
                //Turning the function into strings and comparing them as such
                //to figure out if it should be removed or not.
                return '' + func !== '' + x;
            });
            filterFunctionsMap.set(key, filters);
        }
    }

    return (
        <FiltersContractsContext.Provider
            value={{
                contractId,
                setContractId,
                typeId,
                setTypeId,
                customerId,
                setCustomerId,
                projectId,
                setProjectId,
                ownerId,
                setOwnerId,
                statusId,
                setStatusId,
                filterFunctionsMap,
                filterCleared,
                clearFilters,
                updateFilterFunctions: (
                    key: string,
                    func: (data: IContract) => boolean,
                    adding: boolean,
                ): void => updateFilter(filterFunctionsMap, key, func, adding),
            }}>
            {props.children}
        </FiltersContractsContext.Provider>
    );
}
export default FiltersContractsProvider;

export const FiltersContractsContext = createContext<IFiltersContracts>(null!);

export interface IFiltersContracts {
    contractId: string;
    typeId: string;
    customerId: string;
    projectId: string;
    ownerId: string;
    statusId: string;
    filterFunctionsMap: Map<string, any[]>;
    filterCleared: string;
    setContractId: (value: string) => void;
    setTypeId: (value: string) => void;
    setCustomerId: (value: string) => void;
    setProjectId: (value: string) => void;
    setOwnerId: (value: string) => void;
    setStatusId: (value: string) => void;
    clearFilters: () => void;
    updateFilterFunctions: (
        key: string,
        func: (data: IContract) => boolean,
        adding: boolean,
        name?: string,
    ) => void;
}
