import {
    ActionButton,
    IDropdownOption,
    Label,
    // eslint-disable-next-line no-restricted-imports
    Link,
    MessageBar,
    MessageBarType,
    Stack,
    TooltipHost,
    mergeStyleSets,
    mergeStyles,
} from '@fluentui/react';
import { Dictionary, IconNames } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import GroupClient, {
    DayOfWeek,
    Division,
    GroupRole,
    GroupType,
    IGroup,
    IGroupRequest,
    PrimaryCategorization,
    SensitivityLevel,
} from 'clients/group-client';
import CopyToClipboardButton from 'components/common/buttons/copy-to-clipboard-button';
import CommonSeparator from 'components/common/common-separator';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import Modal, { ModalSizeType } from 'components/common/modal';
import Spacer from 'components/common/spacer';
import { TabsContentsSpacer } from 'components/common/tabs';
import LabelInfoIcon from 'components/common/use-input/info-icon-label';
import { useCheckbox } from 'components/common/use-input/use-checkbox';
import {
    useCoreMultiPrincipalIdPicker,
    useCorePrincipalIdPicker,
} from 'components/common/use-input/use-core-employee-picker';
import { useDropdown } from 'components/common/use-input/use-dropdown';
import { useMultiselectDropDown } from 'components/common/use-input/use-multiselect-dropdown';
import { useTextField } from 'components/common/use-input/use-textfield';
import useMessageBar from 'components/common/use-message-bar';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import ActionAndCancelButtons from 'components/groups/common/action-and-cancel-buttons';
import HorizontalBarCenterContents from 'components/groups/common/horizontal-bar-center-contents';
import { groupJoinRequestUrl } from 'components/groups/groups-utils';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import {
    MinimumRequiredOwners,
    displayWarningIfFewOwners,
    fteOwnerCount,
} from 'components/groups/manage-group/manage-group-utils';
import DeleteGroupModalActionButton from 'components/groups/manage-group/settings/delete-group-modal-action-button';
import EditOffboardMessageModalActionButton from 'components/groups/manage-group/settings/edit-offboarding-message-modal-action-button';
import EditWelcomeMessageModalActionButton from 'components/groups/manage-group/settings/edit-welcome-message-modal-action-button';
import LinkedSecurityGroupsTable from 'components/groups/manage-group/settings/linked-security-groups-table';
import { ManageGroupsVariableContext } from 'components/groups/manage-groups/contexts/manage-groups-variable-context';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import deepEqual from 'deep-equal';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { TimeFormats, timeToString } from 'utils/time-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface ManageGroupSettingsProps {
    fetchGroup: () => Promise<void>;
    onGroupMembersDeleted: (ids: (string | undefined)[]) => void;
}

export default function ManageGroupSettings(props: ManageGroupSettingsProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);
    const allGroupsContext = useContext(ManageGroupsVariableContext);

    const isCoreEmailServiceEnabled = useFeatureFlag(FeatureFlagKeys.emailsCore).enabled;
    const isGroupsMetaDataSectionEnabled = useFeatureFlag(FeatureFlagKeys.groupsMetaDataSection)
        .enabled;
    const isGroupsLinkSecurityGroupEnabled = useFeatureFlag(
        FeatureFlagKeys.groupsLinkToSecurityGroup,
    ).enabled;
    const isMin2FTEOwnersFeatureFlagEnabled = useFeatureFlag(FeatureFlagKeys.groupsMin2FteOwners)
        .enabled;
    const isEnableDynamicGroupFeatureFlagEnabled = useFeatureFlag(
        FeatureFlagKeys.groupsEnableDynamicGroup,
    ).enabled;

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [showConfirmUpdate, setShowConfirmUpdate] = useState<boolean>(false);

    // manager/auditor cannot edit group settings, nor can they delete a group
    const canEditSettingsOrDeleteGroup =
        groupContext.groupMembershipVar?.value?.role === GroupRole.ADMIN ||
        groupContext.groupMembershipVar?.value?.role === GroupRole.OWNER ||
        authContext.isInRole(Role.GroupAdmin);

    const {
        theMessage: errorMessage,
        theElement: errorMessageBar,
        setMessage: setErrorMessage,
        clearMessage: clearErrorMessage,
    } = useMessageBar({ type: MessageBarType.error });

    const {
        value: groupName,
        theElement: groupNameInputElement,
        initialize: initGroupName,
    } = useTextField({ ariaLabel: 'Name' });

    const {
        value: groupDescription,
        theElement: groupDescriptionInputElement,
        initialize: initGroupDescription,
    } = useTextField({ ariaLabel: 'Description' });

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value: enableDynamic,
        theElement: enableDynamicCheckbox,
        initialize: initEnableDynamic,
    } = useCheckbox({
        label: 'Set Group as Dynamic',
        isDisabled: !isEditMode,
    });

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value: requireSponsor,
        theElement: requiresSponsorCheckbox,
        initialize: initRequiresSponsor,
    } = useCheckbox({
        label: 'Require sponsor for members',
        isDisabled: !isEditMode || enableDynamic,
    });

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value: enableHidden,
        theElement: enableHiddenCheckbox,
        initialize: initEnableHidden,
    } = useCheckbox({
        label: 'Hide Group from Members',
        isDisabled: !isEditMode,
    });

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value: requireJustification,
        theElement: requiresJoinJustificationCheckbox,
        initialize: initRequiresJoinJustification,
    } = useCheckbox({
        label: 'Require business justification for members to join',
        isDisabled: !isEditMode || enableDynamic,
    });

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value: enableMemberLeaveJustification,
        theElement: requiresLeaveJustificationCheckbox,
        initialize: initRequiresLeaveJustification,
    } = useCheckbox({
        label: 'Require business justification for members to leave',
        isDisabled: !isEditMode || enableDynamic,
    });

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value: allowJoin,
        theElement: allowJoinRequestsCheckbox,
        initialize: initAllowJoinRequests,
    } = useCheckbox({
        label: 'Allow join requests',
        isDisabled: !isEditMode || enableHidden || enableDynamic,
    });

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value: allowMemberNomination,
        theElement: allowMemberNominationsCheckbox,
        initialize: initAllowMemberNominations,
    } = useCheckbox({
        label: 'Allow member nominations',
        isDisabled: !isEditMode || enableHidden || enableDynamic,
    });

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value: autoApproveJoin,
        theElement: autoApproveJoinRequestsIfCompliantCheckbox,
        initialize: initAutoApproveJoinRequestsIfCompliant,
    } = useCheckbox({
        label: 'Auto-approve join requests if compliant',
        isDisabled: !isEditMode || enableHidden || enableDynamic,
    });

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value: enableMemberNoncomplianceNotification,
        theElement: enableMemberNoncomplianceNotificationCheckbox,
        initialize: initEnableMemberNoncomplianceNotification,
    } = useCheckbox({
        label: 'Enable member non-compliance notifications',
        isDisabled: !isEditMode || enableHidden,
    });

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value: enableMemberRoleNotification,
        theElement: enableMemberRoleNotificationCheckbox,
        initialize: initEnableMemberRoleNotification,
    } = useCheckbox({
        label: 'Enable member role change notifications',
        isDisabled: !isEditMode,
    });

    const notificationOptions = () => {
        return Object.values(DayOfWeek).map((dayOfWeek) => {
            return {
                key: dayOfWeek,
                text: dayOfWeek,
            };
        });
    };

    const {
        selectedKeys: noncomplianceNotificationDays,
        initializeFromKeys: initNoncomplianceNotificationDays,
        theElement: noncomplianceNotificationDaysDropdown,
    } = useMultiselectDropDown({
        disabled: !isEditMode,
        label: isEditMode ? 'Specify days of notification' : 'Days of notification',
        options: notificationOptions(),
        placeholder: 'Select days of week',
        required: isEditMode ? enableMemberNoncomplianceNotification : false,
    });

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value: enableWelcomeMessage,
        theElement: enableWelcomeMessageCheckbox,
        initialize: initEnableWelcomeMessage,
    } = useCheckbox({
        label: 'Enable welcome messages',
        isDisabled: !isEditMode || enableHidden,
    });

    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        value: enableOffboardMessage,
        theElement: enableOffboardMessageCheckbox,
        initialize: initEnableOffboardMessage,
    } = useCheckbox({
        label: 'Enable offboard messages',
        isDisabled: !isEditMode || enableHidden,
    });

    const groupTypeOptions = (): IDropdownOption[] => {
        return Object.entries(GroupType)
            .filter(([key]) => key !== GroupType.Project.valueOf())
            .map(([key, val]) => {
                return { key: key, text: val };
            });
    };

    const {
        selectedOption: selectedGroupType,
        theElement: groupTypeDropdownElement,
        initializeFromKey: initSelectedGroupType,
    } = useDropdown({
        options: groupTypeOptions(),
        placeholder:
            groupContext.group?.groupType === GroupType.Project
                ? ''
                : groupContext.group?.groupType,
        styles: { dropdownItems: globalStyles.dropdownItems },
        ariaLabel: 'Group Type',
    });

    const sensitivityLevelOptions = (): IDropdownOption[] => {
        return Object.entries(SensitivityLevel).map(([key, val]) => {
            return { key: key, text: val };
        });
    };

    const {
        selectedOption: selectedSensitityLevel,
        theElement: sensitivityLevelDropdownElement,
        initializeFromKey: initSelectedSensitivityLevel,
    } = useDropdown({
        options: sensitivityLevelOptions(),
        placeholder:
            groupContext.group?.groupType === GroupType.Project
                ? ''
                : groupContext.group?.sensitivityLevel,
        styles: { dropdownItems: globalStyles.dropdownItems },
        ariaLabel: 'Sensitivity Level',
    });

    const primaryCategorizationOptions = (): IDropdownOption[] => {
        return Object.entries(PrimaryCategorization).map(([key, val]) => {
            return { key: key, text: val };
        });
    };

    const {
        selectedOption: selectedPrimaryCategorization,
        theElement: primaryCategorizationlDropdownElement,
        initializeFromKey: initPrimaryCategorization,
    } = useDropdown({
        options: primaryCategorizationOptions(),
        placeholder: groupContext.group?.primaryCategorization ?? 'Select a primary categorization',
        styles: { dropdownItems: globalStyles.dropdownItems },
        ariaLabel: 'Primary Categorization',
    });

    const divisionOptions = (): IDropdownOption[] => {
        return Object.entries(Division).map(([key, val]) => {
            return { key: key, text: val };
        });
    };

    const {
        selectedOption: selectedDivision,
        theElement: divisionDropdownElement,
        initializeFromKey: initDivision,
    } = useDropdown({
        options: divisionOptions(),
        placeholder: groupContext.group?.division ?? 'Select a division',
        styles: { dropdownItems: globalStyles.dropdownItems },
        ariaLabel: 'Division',
    });

    const {
        theMessage: settingsUpdatedMessage,
        theElement: settingsUpdatedMessageBar,
        setMessage: setSettingsUpdatedMessage,
        clearMessage: clearSettingsUpdatedMessage,
    } = useMessageBar({ type: MessageBarType.success });

    const {
        value: blockListIds,
        valueRecord: blockListPrincipals,
        initialize: initBlockList,
        theElement: blockListPicker,
        hasChanged: hasBlocklistChanged,
    } = useCoreMultiPrincipalIdPicker();

    const {
        value: agcApprovedByPrincipalId,
        initialize: initAgcApprovedBy,
        theElement: agcApprovedByPicker,
        hasChanged: hasAgcApprovedByChanged,
    } = useCorePrincipalIdPicker();

    const {
        value: cvpApprovedByPrincipalId,
        initialize: initCvpApprovedBy,
        theElement: cvpApprovedByPicker,
        hasChanged: hasCvpApprovedByChanged,
    } = useCorePrincipalIdPicker();

    const initializeInputs = async (doFetchGroup: boolean): Promise<void> => {
        clearErrorMessage();
        if (doFetchGroup) {
            await props.fetchGroup();
        }
        initGroupName(groupContext.group?.name);
        initGroupDescription(groupContext.group?.description);
        initRequiresSponsor(groupContext.group?.requireSponsor);
        initEnableHidden(groupContext.group?.enableHidden);
        initEnableDynamic(groupContext.group?.enableDynamic);
        initRequiresJoinJustification(groupContext.group?.requireJustification);
        initRequiresLeaveJustification(groupContext.group?.enableMemberLeaveJustification);
        initAllowJoinRequests(groupContext.group?.allowJoin);
        initAllowMemberNominations(groupContext.group?.allowMemberNomination);
        initAutoApproveJoinRequestsIfCompliant(groupContext.group?.autoApproveJoin);
        initBlockList(groupContext.group?.blockList);
        initEnableMemberNoncomplianceNotification(
            groupContext.group?.enableMemberNoncomplianceNotification,
        );
        initEnableMemberRoleNotification(groupContext.group?.enableMemberRoleNotification);
        initNoncomplianceNotificationDays(groupContext.group?.memberNonComplianceNotificationDays);
        initEnableWelcomeMessage(groupContext.group?.enableWelcomeMessage);
        initEnableOffboardMessage(groupContext.group?.enableOffboardMessage);
        initSelectedGroupType(
            groupContext.group?.groupType === GroupType.Project
                ? ''
                : groupContext.group?.groupType,
        );
        initSelectedSensitivityLevel(
            groupContext.group?.groupType === GroupType.Project
                ? ''
                : groupContext.group?.sensitivityLevel,
        );
        initPrimaryCategorization(groupContext.group?.primaryCategorization);
        initDivision(groupContext.group?.division);
        initAgcApprovedBy(groupContext.group?.agcApprovedBy);
        initCvpApprovedBy(groupContext.group?.cvpApprovedBy);
    };

    useEffect(() => {
        initializeInputs(false);
    }, []);

    useEffect(() => {
        if (enableHidden) {
            initEnableWelcomeMessage(false);
            initEnableOffboardMessage(false);
            initEnableMemberNoncomplianceNotification(false);
            initAllowJoinRequests(false);
            initAllowMemberNominations(false);
            initAutoApproveJoinRequestsIfCompliant(false);
        }
    }, [enableHidden]);

    useEffect(() => {
        if (enableDynamic) {
            initRequiresSponsor(false);
            initRequiresJoinJustification(false);
            initRequiresLeaveJustification(false);
            initAllowJoinRequests(false);
            initAllowMemberNominations(false);
            initAutoApproveJoinRequestsIfCompliant(false);
        }
    }, [enableDynamic]);

    const requiredOwnersInBlockList: IPrincipalRecord[] | undefined = useMemo(() => {
        if (isMin2FTEOwnersFeatureFlagEnabled) {
            return blockListPrincipals?.filter((record) => groupContext.ownerIsFTE[record.id]);
        } else {
            return blockListPrincipals?.filter((record) =>
                groupContext.groupOwners.some((x) => x.personnelId === record.id),
            );
        }
    }, [
        isMin2FTEOwnersFeatureFlagEnabled,
        blockListPrincipals,
        groupContext.ownerIsFTE,
        groupContext.groupOwners,
    ]);

    const shouldPreventOwnerInBlockList = useMemo(() => {
        if (blockListIds === undefined) {
            return false;
        }

        const requiredOwnersCount = isMin2FTEOwnersFeatureFlagEnabled
            ? fteOwnerCount(groupContext.groupOwners, groupContext.ownerIsFTE)
            : groupContext.groupOwners.length;
        const requiredOwnersInBlockListCount = requiredOwnersInBlockList?.length ?? 0;

        return requiredOwnersCount - requiredOwnersInBlockListCount < MinimumRequiredOwners;
    }, [
        requiredOwnersInBlockList,
        groupContext.ownerIsFTE,
        groupContext.groupOwners,
        isMin2FTEOwnersFeatureFlagEnabled,
    ]);

    const isSomeInputChanged = (): boolean => {
        if (!groupContext.group) {
            return false;
        }
        return [
            groupContext.group.name !== groupName,
            groupContext.group.description !== groupDescription,
            groupContext.group.requireSponsor !== requireSponsor,
            groupContext.group.enableHidden !== enableHidden,
            groupContext.group.enableDynamic !== enableDynamic,
            groupContext.group.requireJustification !== requireJustification,
            groupContext.group.enableMemberLeaveJustification !== enableMemberLeaveJustification,
            groupContext.group.allowJoin !== allowJoin,
            groupContext.group.allowMemberNomination !== allowMemberNomination,
            groupContext.group.autoApproveJoin !== autoApproveJoin,
            hasBlocklistChanged,
            groupContext.group.enableMemberNoncomplianceNotification !==
                enableMemberNoncomplianceNotification,
            groupContext.group.enableMemberRoleNotification !== enableMemberRoleNotification,
            !deepEqual(
                groupContext.group?.memberNonComplianceNotificationDays,
                noncomplianceNotificationDays,
            ),
            groupContext.group.enableWelcomeMessage !== enableWelcomeMessage,
            groupContext.group.enableOffboardMessage !== enableOffboardMessage,
            groupContext.group.groupType !== selectedGroupType?.key,
            groupContext.group.sensitivityLevel !== selectedSensitityLevel?.key,
            groupContext.group.primaryCategorization !== selectedPrimaryCategorization?.key,
            groupContext.group.division !== selectedDivision?.key,
            hasAgcApprovedByChanged,
            hasCvpApprovedByChanged,
        ].some((element) => element);
    };

    const isSubmitDisabled = (): boolean => {
        const isElevatedSensitivity = [
            SensitivityLevel.Confidential,
            SensitivityLevel.HighlyConfidential,
        ].includes(selectedSensitityLevel?.text as SensitivityLevel);

        const isElevatedGroupType = [GroupType.Tent, GroupType.ConfidentialProject].includes(
            selectedGroupType?.text as GroupType,
        );

        const isTentedGroup = GroupType.Tent === selectedGroupType?.text;

        const isBasicInfoIncomplete = !groupContext.group || !groupName || !groupDescription;

        const isCategorizationIncomplete =
            isGroupsMetaDataSectionEnabled &&
            (isElevatedSensitivity || isElevatedGroupType) &&
            (!selectedPrimaryCategorization || !selectedDivision);

        const isNotApproved =
            isGroupsMetaDataSectionEnabled &&
            isTentedGroup &&
            (!agcApprovedByPrincipalId || !cvpApprovedByPrincipalId);

        return (
            isBasicInfoIncomplete ||
            isCategorizationIncomplete ||
            isNotApproved ||
            !isSomeInputChanged() ||
            isUpdating ||
            shouldPreventOwnerInBlockList
        );
    };

    const onClickEditSettings = async (): Promise<void> => {
        await initializeInputs(true);
        setIsEditMode(true);
        setIsUpdating(false);
        setShowConfirmUpdate(false);
        clearSettingsUpdatedMessage();
    };

    const onClickCancelEdit = (): void => {
        clearErrorMessage();
        initializeInputs(false);
        setIsEditMode(false);
    };

    const onClickSaveSettings = async (): Promise<void> => {
        if (hasBlocklistChanged) {
            setShowConfirmUpdate(true);
        } else {
            performUpdateSettings();
        }
    };

    const performUpdateSettings = async (): Promise<void> => {
        setShowConfirmUpdate(false);
        if (!groupContext.group) {
            return;
        }
        try {
            clearErrorMessage();
            setIsUpdating(true);
            const groupUpdateRequest: IGroupRequest = {
                ...groupContext.group,
                rules: [],
                ownerList: [],
            };
            const result = await GroupClient.editGroup(
                authContext,
                groupContext.group.id,
                Object.assign(groupUpdateRequest, {
                    name: groupName,
                    description: groupDescription,
                    enableHidden,
                    enableDynamic,
                    requireSponsor,
                    requireJustification,
                    enableMemberLeaveJustification,
                    allowJoin,
                    allowMemberNomination,
                    autoApproveJoin,
                    blockList: blockListIds,
                    enableMemberNoncomplianceNotification,
                    enableMemberRoleNotification,
                    memberNonComplianceNotificationDays: noncomplianceNotificationDays,
                    enableWelcomeMessage,
                    enableOffboardMessage,
                    groupType: selectedGroupType?.key,
                    sensitivityLevel: selectedSensitityLevel?.key,
                    primaryCategorization: selectedPrimaryCategorization?.key,
                    division: selectedDivision?.key,
                    cvpApprovedBy:
                        selectedGroupType?.key === GroupType.Tent
                            ? cvpApprovedByPrincipalId
                            : undefined,
                    agcApprovedBy:
                        selectedGroupType?.key === GroupType.Tent
                            ? agcApprovedByPrincipalId
                            : undefined,
                }),
            );
            groupContext.setGroup(result);
            setIsEditMode(false);
            setIsUpdating(false);
            setSettingsUpdatedMessage('Group settings successfully updated.');

            if (blockListIds !== undefined && blockListIds.length > 0) {
                props.onGroupMembersDeleted(blockListIds);
            }
        } catch (e) {
            setErrorMessage(e as string);
            setIsUpdating(false);
            console.error(e);
        }
    };

    interface IDisplayOneRowOptions {
        verticalAlign?: 'center' | 'start';
        horizontalAlign?: number;
    }

    const displayOneRow = (
        title: string | JSX.Element,
        content: string | undefined | JSX.Element,
        options?: IDisplayOneRowOptions,
    ): JSX.Element => {
        return (
            <Stack
                horizontal
                verticalAlign={options?.verticalAlign ?? 'start'}
                tokens={{ padding: options?.horizontalAlign ?? 7 }}>
                <Stack.Item className={styles.left}>{title}</Stack.Item>
                <Stack.Item className={styles.right}>{content}</Stack.Item>
            </Stack>
        );
    };

    const showTextOrInputElement = (
        isEditModeParam: boolean,
        text: string,
        inputElement: JSX.Element,
    ): string | JSX.Element => {
        if (isEditModeParam) {
            return inputElement;
        } else {
            return text ?? '';
        }
    };

    interface ISaveAndCancelButtonsParams {
        isVisible: boolean;
    }

    const SaveAndCancelButtons = (params: ISaveAndCancelButtonsParams): JSX.Element => {
        return (
            <ActionAndCancelButtons
                buttonText='Update'
                disabled={isSubmitDisabled()}
                isVisible={params.isVisible}
                onClickAction={onClickSaveSettings}
                onClickCancel={onClickCancelEdit}
            />
        );
    };

    const SaveAndCancelButtonsWrapper = (params: ISaveAndCancelButtonsParams): JSX.Element => {
        // This wrapper is added here to create the exact same alignment
        // that the Cancel and Update buttons have on top of the screen.
        return (
            <HorizontalBarCenterContents isVisible={params.isVisible}>
                <SaveAndCancelButtons isVisible={params.isVisible} />
            </HorizontalBarCenterContents>
        );
    };

    interface IGroupDependencyInfo {
        id: string;
        name: string;
    }

    const distinctDependentGroups = (groups: IGroupDependencyInfo[]): IGroupDependencyInfo[] => {
        const groupDictionary: Dictionary<IGroupDependencyInfo> = {};
        groups.forEach((groupInfo) => {
            groupDictionary[groupInfo.id] = groupInfo;
        });
        return Object.values(groupDictionary);
    };

    const dependentGroups = useMemo((): IGroupDependencyInfo[] => {
        if (!groupContext.group) {
            return [];
        }
        const groups = groupContext.group.dependentGroups.map((dependentGroupId) => {
            const dependentGroup = allGroupsContext.groups.find(
                (groupVar) => groupVar.id === dependentGroupId,
            );
            return {
                id: dependentGroupId,
                name: dependentGroup?.name ?? '',
            };
        });
        return distinctDependentGroups(groups);
    }, [groupContext.group, allGroupsContext.groups]);

    const dependsOnGroups = useMemo((): IGroupDependencyInfo[] => {
        if (!groupContext?.group?.id) {
            // Note (+)
            return [];
        }
        const groups: IGroupDependencyInfo[] = [];
        allGroupsContext.groups.forEach((group) => {
            group.dependentGroups.forEach((dependentGroupId) => {
                // The following type cast is safe because the check at Note (+)
                // above has made sure groupContext.group.id is a defined value.
                if (dependentGroupId === (groupContext.group as IGroup).id) {
                    groups.push({
                        id: group.id,
                        name: group.name,
                    });
                }
            });
        });
        return distinctDependentGroups(groups);
    }, [groupContext.group, allGroupsContext.groups]);

    const displayGroupDependencies = (groupDependencies: IGroupDependencyInfo[]): JSX.Element => {
        return (
            <>
                {groupDependencies.map((group) => {
                    return (
                        <Stack key={group.id} horizontal verticalAlign='center'>
                            <Stack.Item className={styles.dependencyGroupId}>
                                <CopyToClipboardButton
                                    text={group.id}
                                    iconStyles={{ alignSelf: 'center' }}
                                />
                                <EllipsisTextCss text={group.name} />
                            </Stack.Item>
                        </Stack>
                    );
                })}
            </>
        );
    };

    const displayBlockListOwnerWarning = (): JSX.Element => {
        if (shouldPreventOwnerInBlockList && requiredOwnersInBlockList) {
            const requiredOwnersCount = isMin2FTEOwnersFeatureFlagEnabled
                ? fteOwnerCount(groupContext.groupOwners, groupContext.ownerIsFTE)
                : groupContext.groupOwners.length;

            return (
                <>
                    <MessageBar messageBarType={MessageBarType.error}>
                        The following person(s) in block list are also owners of the group but the
                        group only has {requiredOwnersCount} FTE owners. Please first add more FTE
                        owners or remove them from the block list:&nbsp;
                        {requiredOwnersInBlockList.map((x) => x.displayName).join(', ')}.
                    </MessageBar>
                    <Spacer marginTop={10} />
                </>
            );
        }
        return <></>;
    };

    if (!groupContext.group) {
        // Don't render anything until group context has read the group information.
        return <></>;
    } else {
        return (
            <>
                <TabsContentsSpacer />
                {displayWarningIfFewOwners(
                    groupContext.groupOwners,
                    groupContext.ownerIsFTE,
                    groupContext.isGroupOwnersObtained,
                    isMin2FTEOwnersFeatureFlagEnabled,
                )}
                <HorizontalBar>
                    <Stack.Item className={mergeStyles(horizontalBarTitleStyle, styles.oneThird)}>
                        <h1
                            className={`${globalStyles.boldFont} ${globalStyles.mediumLargeFont} ${globalStyles.removeTopBottomMargins}`}>
                            Settings
                        </h1>
                    </Stack.Item>
                    <Stack.Item className={styles.oneThird}>
                        <SaveAndCancelButtons isVisible={isEditMode} />
                    </Stack.Item>
                    <Stack.Item className={styles.oneThird}>
                        <Stack horizontal horizontalAlign='end'>
                            {canEditSettingsOrDeleteGroup && !isEditMode && (
                                <>
                                    <ActionButton
                                        iconProps={{ iconName: IconNames.Edit }}
                                        onClick={onClickEditSettings}>
                                        Edit Settings
                                    </ActionButton>
                                    <DeleteGroupModalActionButton group={groupContext.group} />
                                </>
                            )}
                        </Stack>
                    </Stack.Item>
                </HorizontalBar>
                {!!errorMessage && errorMessageBar()}
                {!!settingsUpdatedMessage && settingsUpdatedMessageBar()}
                <CommonSeparator text='Group Settings' />
                <div>
                    {displayOneRow(
                        <Label required={isEditMode}>Name</Label>,
                        showTextOrInputElement(
                            isEditMode,
                            groupContext.group.name,
                            groupNameInputElement(),
                        ),
                        { verticalAlign: 'center' },
                    )}
                    {displayOneRow(
                        <Label required={isEditMode}>Description</Label>,
                        showTextOrInputElement(
                            isEditMode,
                            groupContext.group.description,
                            groupDescriptionInputElement(),
                        ),
                        { verticalAlign: 'center' },
                    )}
                    {groupContext.group?.allowJoin &&
                        displayOneRow(
                            'Join Request URL',
                            <Stack horizontal verticalAlign='center'>
                                <CopyToClipboardButton
                                    text={groupJoinRequestUrl(groupContext.group)}
                                />
                                <TooltipHost content={groupJoinRequestUrl(groupContext.group)}>
                                    {groupJoinRequestUrl(groupContext.group)}
                                </TooltipHost>
                            </Stack>,
                            { verticalAlign: 'center' },
                        )}
                    {isGroupsLinkSecurityGroupEnabled && (
                        <>
                            {groupContext.hasErrorGettingLinkedSecurityGroups
                                ? displayOneRow(
                                      'Linked Security Groups',
                                      'Error getting the groups',
                                  )
                                : displayOneRow(
                                      'Linked Security Groups',
                                      <LinkedSecurityGroupsTable
                                          canEditSettingsOrDeleteGroup={
                                              canEditSettingsOrDeleteGroup
                                          }
                                          linkedSecurityGroups={groupContext.linkedSecurityGroups}
                                      />,
                                  )}
                        </>
                    )}
                    <>
                        <Spacer marginTop={8} />
                        {displayOneRow('Visibility', enableHiddenCheckbox(), {
                            horizontalAlign: 6,
                        })}

                        {isEnableDynamicGroupFeatureFlagEnabled && (
                            <>
                                <Spacer marginTop={8} />
                                {displayOneRow(
                                    <LabelInfoIcon
                                        iconHoverContent={
                                            'Making this group dynamic will sync in all personnel who are compliant with group policy rules. ' +
                                            'Additionally, it will disable the ability to manually add, remove, request, and nominate members.'
                                        }
                                        iconName={IconNames.Info}>
                                        Dynamic Group
                                    </LabelInfoIcon>,
                                    enableDynamicCheckbox(),
                                    {
                                        horizontalAlign: 6,
                                    },
                                )}
                                {!groupContext.group.enableDynamic && enableDynamic && (
                                    <MessageBar messageBarType={MessageBarType.warning}>
                                        Enabling the dynamic group setting will disable the
                                        following settings:
                                        <ul>
                                            <li>Require sponsor for members</li>
                                            <li>
                                                Require business justification for members to join
                                            </li>
                                            <li>
                                                Require business justification for members to leave
                                            </li>
                                            <li>Allow join requests</li>
                                            <li>Allow member nominations</li>
                                            <li>Auto-approve join requests if compliant</li>
                                        </ul>
                                        Additionally, all pending requests/nominations will be
                                        removed.
                                    </MessageBar>
                                )}
                            </>
                        )}
                    </>
                </div>
                <CommonSeparator text='Dependencies' />
                {displayOneRow(
                    'Dependent Groups',
                    !!dependentGroups.length
                        ? displayGroupDependencies(dependentGroups)
                        : 'No dependent groups',
                    { verticalAlign: 'center' },
                )}
                {displayOneRow(
                    'Depends on Groups',
                    !!dependsOnGroups.length
                        ? displayGroupDependencies(dependsOnGroups)
                        : 'No group dependency',
                    { verticalAlign: 'center' },
                )}
                <CommonSeparator text='Memberships' />
                <div>
                    {displayOneRow(
                        'Options',
                        <Stack>
                            {requiresSponsorCheckbox()}
                            <Spacer marginTop={10} />
                            {requiresJoinJustificationCheckbox()}
                            <Spacer marginTop={10} />
                            {requiresLeaveJustificationCheckbox()}
                            <Spacer marginTop={10} />
                            {allowJoinRequestsCheckbox()}
                            <Spacer marginTop={10} />
                            {allowMemberNominationsCheckbox()}
                            <Spacer marginTop={10} />
                            {autoApproveJoinRequestsIfCompliantCheckbox()}
                        </Stack>,
                    )}
                    {displayOneRow(
                        'Block List',
                        isEditMode ? (
                            <>
                                {displayBlockListOwnerWarning()}
                                {blockListPicker()}
                            </>
                        ) : groupContext.group?.blockList?.length === 0 ? (
                            'No person is blocked'
                        ) : (
                            <Stack horizontal wrap>
                                {groupContext.group?.blockList.map((principalId) => {
                                    return (
                                        <Stack.Item key={principalId}>
                                            <span>&nbsp;</span>
                                            <CoreEmployeeHoverCardFromPrincipalId
                                                principalId={principalId}
                                            />
                                        </Stack.Item>
                                    );
                                })}
                            </Stack>
                        ),
                    )}
                </div>
                {isCoreEmailServiceEnabled && (
                    <>
                        <CommonSeparator text='Notifications' />
                        <div>
                            {displayOneRow(
                                '',
                                <Stack>
                                    {enableMemberRoleNotificationCheckbox()}
                                    <Spacer marginTop={15} />
                                    {enableMemberNoncomplianceNotificationCheckbox()}
                                    {enableMemberNoncomplianceNotification &&
                                        noncomplianceNotificationDaysDropdown()}
                                    <Spacer marginTop={10} />
                                    <Stack horizontal verticalAlign='center'>
                                        <Stack.Item>{enableWelcomeMessageCheckbox()}</Stack.Item>
                                        <Stack.Item>
                                            <EditWelcomeMessageModalActionButton
                                                group={groupContext.group}
                                                enable={
                                                    !isEditMode &&
                                                    !!enableWelcomeMessage &&
                                                    !enableHidden
                                                }
                                                fetchGroup={props.fetchGroup}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                    <Spacer marginTop={10} />
                                    <Stack horizontal verticalAlign='center'>
                                        <Stack.Item>{enableOffboardMessageCheckbox()}</Stack.Item>
                                        <Stack.Item>
                                            <EditOffboardMessageModalActionButton
                                                group={groupContext.group}
                                                enable={
                                                    !isEditMode &&
                                                    !!enableOffboardMessage &&
                                                    !enableHidden
                                                }
                                                fetchGroup={props.fetchGroup}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                    <Spacer marginTop={10} />
                                </Stack>,
                            )}
                        </div>
                        {displayOneRow(
                            'Created',
                            <Stack horizontal verticalAlign='center'>
                                <CoreEmployeeHoverCardFromPrincipalId
                                    principalId={groupContext.group.createdBy}
                                />
                                <span>&nbsp;&nbsp;</span>
                                {timeToString(
                                    groupContext.group.creationTimestampUTC * 1000,
                                    TimeFormats.MMMDDYYYY_hmmA,
                                )}
                            </Stack>,
                        )}
                        {displayOneRow(
                            'Last Modified',
                            <Stack horizontal verticalAlign='center'>
                                <CoreEmployeeHoverCardFromPrincipalId
                                    principalId={groupContext.group.lastModifiedBy}
                                />
                                <span>&nbsp;&nbsp;</span>
                                {timeToString(
                                    groupContext.group.lastModifiedTimestampUTC * 1000,
                                    TimeFormats.MMMDDYYYY_hmmA,
                                )}
                            </Stack>,
                        )}
                    </>
                )}
                {isGroupsMetaDataSectionEnabled && (
                    <>
                        <CommonSeparator text='Metadata' />
                        {displayOneRow(
                            <LabelInfoIcon
                                iconHoverContent={
                                    <ul className={styles.hoverCardList}>
                                        <li>
                                            <Link href='https://aka.ms/tentpolicy' target='_blank'>
                                                Tent
                                            </Link>
                                            : to secure tented info
                                        </li>
                                        <li>Confidential Project: to secure confidential info</li>
                                        <li>
                                            Group: to give a specific person/team access to
                                            resources
                                        </li>
                                    </ul>
                                }
                                iconName={IconNames.Info}>
                                Group Type
                            </LabelInfoIcon>,
                            showTextOrInputElement(
                                isEditMode,
                                groupContext.group.groupType === GroupType.Project
                                    ? ''
                                    : GroupType[groupContext.group.groupType],
                                groupTypeDropdownElement(),
                            ),
                        )}
                        {displayOneRow(
                            <LabelInfoIcon
                                iconHoverContent={
                                    <Link
                                        href='https://microsoft.sharepoint.com/sites/MSProtect/SitePages/Data-Classification-Labels.aspx?xsdata=MDV8MDF8fDhmMThlNDgzYjU5MjRkZjFiNzM5MDhkYTFkOWU4NzRlfDcyZjk4OGJmODZmMTQxYWY5MWFiMmQ3Y2QwMTFkYjQ3fDB8MHw2Mzc4NTQ4NjM5Mzg0Njc2NDF8R29vZHxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxNVGs2TkRGaU5HWTNNelV0TjJKalppMDBNamsyTFRnMk1qUXRZV1JtTUdGa1lUZzFaRFl5WDJKbE1UVXdabVZqTFRBeE1UWXROREJsWkMwNE1ERXlMV1l5TkdRNE9URTBaV1JtWmtCMWJuRXVaMkpzTG5Od1lXTmxjdz09fHw%3D&sdata=dU1RSVUyTFZIMnRKbWhUR3FxM09zRjRJM0NzSzh6YVoreDl2SGpESWVkMD0%3D&ovuser=72f988bf-86f1-41af-91ab-2d7cd011db47%2csinghda%40microsoft.com&OR=Teams-HL&CT=1649889759598&params=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjA0MDExMTQwMCJ9'
                                        target='_blank'>
                                        Data Classification Label
                                    </Link>
                                }
                                iconName={IconNames.Info}>
                                Sensitivity Level
                            </LabelInfoIcon>,
                            showTextOrInputElement(
                                isEditMode,
                                groupContext.group?.groupType === GroupType.Project
                                    ? ''
                                    : SensitivityLevel[groupContext.group.sensitivityLevel],
                                sensitivityLevelDropdownElement(),
                            ),
                            { verticalAlign: 'center' },
                        )}
                        {displayOneRow(
                            <LabelInfoIcon
                                iconHoverContent={
                                    <ul className={styles.hoverCardList}>
                                        <li>
                                            <Link href='https://aka.ms/tentpolicy' target='_blank'>
                                                US Government
                                            </Link>
                                            : classified cloud, contracts with USG, etc
                                        </li>
                                        <li>
                                            <Link href='https://aka.ms/tentpolicy' target='_blank'>
                                                International Government
                                            </Link>
                                            : non-US contractual relationships with governments
                                        </li>
                                        <li>
                                            Hardware, Device, Gaming, or Confidential Intellectual
                                            Property: Surface, Xbox, Quantum, etc
                                        </li>
                                        <li>Critical Infrastructure: power grids, 5G, etc</li>
                                        <li>Other: none of the above</li>
                                    </ul>
                                }
                                iconName={IconNames.Info}
                                required={
                                    isEditMode &&
                                    [GroupType.Tent, GroupType.ConfidentialProject].includes(
                                        selectedGroupType?.text as GroupType,
                                    )
                                }>
                                Primary Categorization
                            </LabelInfoIcon>,
                            showTextOrInputElement(
                                isEditMode,
                                !!groupContext.group.primaryCategorization
                                    ? PrimaryCategorization[
                                          groupContext.group.primaryCategorization
                                      ]
                                    : '',
                                primaryCategorizationlDropdownElement(),
                            ),
                            { verticalAlign: 'center' },
                        )}
                        {displayOneRow(
                            <Label
                                required={
                                    isEditMode &&
                                    [GroupType.Tent, GroupType.ConfidentialProject].includes(
                                        selectedGroupType?.text as GroupType,
                                    )
                                }>
                                Division
                            </Label>,
                            showTextOrInputElement(
                                isEditMode,
                                !!groupContext.group.division
                                    ? Division[groupContext.group.division]
                                    : '',
                                divisionDropdownElement(),
                            ),
                            { verticalAlign: 'center' },
                        )}
                        {!!groupContext.group.groupType &&
                            GroupType.Tent === selectedGroupType?.text &&
                            displayOneRow(
                                <LabelInfoIcon
                                    iconHoverContent={'Accountable Owner'}
                                    iconName={IconNames.Info}
                                    required={isEditMode} // Only shown and required if type is Tent.
                                >
                                    CVP Approved By
                                </LabelInfoIcon>,
                                <Stack>
                                    {isEditMode ? (
                                        cvpApprovedByPicker()
                                    ) : (
                                        <CoreEmployeeHoverCardFromPrincipalId
                                            principalId={groupContext.group.cvpApprovedBy}
                                        />
                                    )}
                                </Stack>,
                                { verticalAlign: 'center' },
                            )}
                        {!!groupContext.group.groupType &&
                            GroupType.Tent === selectedGroupType?.text &&
                            displayOneRow(
                                <LabelInfoIcon
                                    iconHoverContent={
                                        <Link
                                            href='https://findcontact.microsoft.com/'
                                            target='_blank'>
                                            Associate General Counsel
                                        </Link>
                                    }
                                    iconName={IconNames.Info}
                                    required={isEditMode} // Only shown and required when type is Tent.
                                >
                                    AGC Approved By
                                </LabelInfoIcon>,
                                <Stack>
                                    {isEditMode ? (
                                        agcApprovedByPicker()
                                    ) : (
                                        <CoreEmployeeHoverCardFromPrincipalId
                                            principalId={groupContext.group.agcApprovedBy}
                                        />
                                    )}
                                </Stack>,
                                { verticalAlign: 'center' },
                            )}
                    </>
                )}
                <Spacer marginTop={20} />
                <SaveAndCancelButtonsWrapper isVisible={isEditMode} />
                <Spacer marginTop={20} />
                {showConfirmUpdate && (
                    <Modal
                        fixWidth={true}
                        isOpen={true}
                        size={ModalSizeType.medium}
                        submitButtonText='Confirm'
                        title='Block List Confirmation'
                        onCancel={(): void => {
                            setShowConfirmUpdate(false);
                        }}
                        onSubmit={performUpdateSettings}>
                        All members on the block list will automatically be removed from this group.
                    </Modal>
                )}
            </>
        );
    }
}

const styles = mergeStyleSets({
    left: mergeStyles(
        {
            width: '250px',
            marginLeft: 30,
        },
        globalStyles.boldFont,
    ),
    right: {
        width: '100%',
    },
    oneThird: {
        width: '33%',
    },
    dependencyGroupName: {
        width: '20%',
    },
    dependencyGroupId: {
        width: '80%',
        display: 'flex',
        alignItems: 'center',
    },
    hoverCardList: {
        paddingRight: 20,
    },
});
