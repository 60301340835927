import React from 'react';
import { ActionButton, Stack, TextField } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';

export interface IMultiTextFieldItemProps {
    value: string;
    type?: string;
    onChange?: (newValue?: string) => void;
    addDisabled?: boolean;
    onAddClicked?: () => void;
    onDeleteClicked?: () => void;
    isRequired?: boolean;
}

export default function MultiTextFieldItem(props: IMultiTextFieldItemProps): JSX.Element {
    return (
        <Stack horizontal>
            <Stack.Item grow={2}>
                <TextField
                    value={props.value}
                    type={props.type}
                    onChange={(ev, newVal) => props.onChange && props.onChange(newVal)}
                    required={props.isRequired}
                />
            </Stack.Item>
            <ActionButton
                disabled={props.addDisabled}
                iconProps={{ iconName: IconNames.Add }}
                onClick={props.onAddClicked}
            />
            <ActionButton
                iconProps={{ iconName: IconNames.Delete }}
                onClick={props.onDeleteClicked}
            />
        </Stack>
    );
}
