import { IComboBoxOption, ComboBox } from '@fluentui/react';
import React, { useMemo } from 'react';
import { TableOption } from 'components/forms/forms-common';
import { countryList } from 'components/forms/element-viewer/country-list';
import { TableRow } from 'components/forms/element-viewer/table-viewer';

type TableCountryViewerProps = {
    value: string;
    tableOption: TableOption;
    setEditingRow: (value: React.SetStateAction<TableRow | undefined>) => void;
};

export default function TableCountryViewer(props: TableCountryViewerProps): JSX.Element {
    const { value, tableOption, setEditingRow } = props;

    const onValueChange = (country: string): void => {
        setEditingRow((prev) => {
            if (!prev || !country) {
                return;
            }
            const newItem = { ...prev };
            newItem.item[tableOption.key] = country;
            return newItem;
        });
    };

    const countryDropdownOptions: IComboBoxOption[] = useMemo(() => {
        return countryList.map((country) => {
            return { key: country, text: country };
        });
    }, []);

    return (
        <ComboBox
            ariaLabel='Countries'
            selectedKey={value}
            options={countryDropdownOptions}
            allowFreeform
            autoComplete='on'
            onChange={(ev, option): void => {
                if (option) {
                    onValueChange(option.text);
                }
            }}
        />
    );
}
