/**
 * This file provides a set of components and data types required for rendering filter items.
 */

import React, { FormEvent } from 'react';
import {
    Checkbox,
    Dropdown,
    TextField,
    DatePicker,
    ICheckboxProps,
    IDatePickerProps,
    IDropdownProps,
    ITextFieldProps,
    IDropdownOption,
    IPersonaProps,
    Slider,
    ISliderProps,
    Text,
    ITextProps,
} from '@fluentui/react';
import EmployeePickerTypeaheadSearch, {
    IEmployeePickerTypeaheadSearchProps,
} from 'components/common/employee-picker-typeahead-search';
import { globalCheckboxStyles, globalStyles } from 'assets/styles/global-styles';
import { FilterSettingsHookType } from 'components/common/search-filter/use-filter-settings';
import { distinctPersonaArray } from 'components/common/employee-list-picker';
import { PrehireFilterListPickerProps } from 'components/common/prehire-filter-list-picker';
import { Dictionary } from 'assets/constants/global-constants';
import { SearchEmployeeStatus } from 'clients/employee-client';
import deepcopy from 'deepcopy';

export interface IFilterItem {
    // When performing a search, backend will check value of this variable against the data
    // that user has provided. In other words, "name"s can become URL query parameters.
    // Example values: "contractId", "employeeId", "requestType", "stateName", "screeningState".
    name: string;
    // label is only used for text display on UI.
    // Example value: "Status (2)".
    label?: string; // It's required for items of type 'Separator'.
    placeholder?: string;
    type: FilterItemType;
    // As of now, subFilterItems applies to checkboxes only.
    // Has potential for being applied to other filter items.
    // If specified, the front end should show the items in the list if this filter item
    // is checked.
    subFilterItems?: IFilterItem[];
    checkboxOptions?: {
        // For Checkbox
        //     Value of the property is string. Think of it as name of the variable
        //     that stores the checked state of the checkbox.
        //     Backend will search for that string and will return the matches.
        checked: string;
        // validOptions will be populated by UI code and will be placed in the
        // dictionary of filter items. It will not be provided by backend.
        validOptions?: Record<string, boolean>;
        // The following options determine what to do with the subfilter
        // items when the parent checkbox is checked or unchecked.
        checkSubFilterItemsOnCheck?: boolean; // Default: false. By default, don't check subfilter items when parent is checked.
        uncheckSubFilterItemsOnUncheck?: boolean; // Default: true. By default, uncheck subfilter items when parent is unchecked.
        // Display the item with a left margin.
        showWithIndent?: boolean;
        // If you want a checkbox to appear more than once, specify isDuplicate
        // on second and subsequent instances.
        isDuplicate?: boolean;
    };
    dropdownOptions?: {
        // options is only used for dropdown filter items.
        // It shows the dropdown selections.
        //     The options are of type KeyTextType[], containing list of (key, text) options.
        //     Key is the selection and text is the display text. Backend will search for key
        //     and will return the matched strings.
        options: KeyTextType[];
        // validOptions will be populated by UI code and will be placed in the
        // dictionary of filter items. It will not be provided by backend.
        validOptions?: Record<string, boolean>;
        multiSelect?: boolean;
    };
    sliderOptions?: {
        min: number; // It's required because without it, the displayed value of slider will not clear with clearAll.
        max?: number;
        step?: number;
        ranged?: boolean;
        showValue?: boolean;
        snapToStep?: boolean;
        forMin?: string; // If value euqls min, show this string instead. Example: "None".
        forMax?: string; // If value euqls max, show this string instead. Example: "All".
        prefix?: string; // Prefix to show before the value. Example: "$".
        suffix?: string; // Suffix to show after the value. Example: "%".
    };
    datePickerOptions?: {
        allowTextInput?: boolean;
        firstDayOfWeek?: number;
        showCloseButton?: boolean;
        highlightCurrentMonth?: boolean;
        highlightSelectedMonth?: boolean;
    };
    employeeSearchOptions?: {
        // itemLimit is required option for employee search items.
        // It shows number of employees that can be selected.
        // Specify -1 for unlimited.
        itemLimit?: number;
    };
}

type KeyTextType = {
    key: string;
    text: string;
};

export type FilterItemType = keyof typeof FilterItemTypeEnum;

export enum FilterItemTypeEnum {
    // Date range is considered for Fluent DatePicker of Fluent v9.
    // See https://github.com/microsoft/fluentui/issues/21183.
    // ChoiceGroup = 'ChoiceGroup', // Placeholder for possible future implementation.
    Checkbox = 'Checkbox',
    DatePicker = 'DatePicker',
    Dropdown = 'Dropdown',
    EmployeeSearch = 'EmployeeSearch',
    PrehireSearch = 'PrehireSearch',
    SliderBar = 'SliderBar',
    TextField = 'TextField',
    Separator = 'Separator',
}

const filterItemLibraryComponents: FilterItemLibraryComponentType[] = [
    {
        filterItemType: FilterItemTypeEnum.Checkbox,
        libraryComponent: Checkbox,
        propsFunc: (filterItem: IFilterItem, filterSettings: FilterSettingsHookType) =>
            checkboxProps(filterItem, filterSettings),
        keyFunc: (filterItem: IFilterItem) =>
            'checkbox-' + filterItem.name + '-' + filterItem.checkboxOptions!.checked,
    },
    // {
    //     // Placeholder for possible future implementation.
    //     filterItemType: FilterItemTypeEnum.ChoiceGroup,
    //     libraryComponent: ChoiceGroup,
    // },
    {
        filterItemType: FilterItemTypeEnum.DatePicker,
        libraryComponent: DatePicker,
        marginTop: 10,
        propsFunc: (filterItem: IFilterItem, filterSettings: FilterSettingsHookType) =>
            datePickerProps(filterItem, filterSettings),
        keyFunc: (filterItem: IFilterItem) => 'datepicker-' + filterItem.name,
    },
    {
        filterItemType: FilterItemTypeEnum.Dropdown,
        libraryComponent: Dropdown as React.FunctionComponent,
        marginTop: 10,
        propsFunc: (filterItem: IFilterItem, filterSettings: FilterSettingsHookType) =>
            dropdownProps(filterItem, filterSettings),
        keyFunc: (filterItem: IFilterItem) => 'dropdown-' + filterItem.name,
    },
    {
        filterItemType: FilterItemTypeEnum.EmployeeSearch,
        libraryComponent: EmployeeSearchComponent,
        marginTop: 10,
        propsFunc: (filterItem: IFilterItem, filterSettings: FilterSettingsHookType) =>
            employeePickerTypeaheadSearchProps(filterItem, filterSettings),
        keyFunc: (filterItem: IFilterItem) => 'employee-search-' + filterItem.name,
    },
    {
        filterItemType: FilterItemTypeEnum.TextField,
        libraryComponent: TextField,
        marginTop: 10,
        propsFunc: (filterItem: IFilterItem, filterSettings: FilterSettingsHookType) =>
            textFieldProps(filterItem, filterSettings),
        keyFunc: (filterItem: IFilterItem) => 'textfield-' + filterItem.name,
    },
    {
        filterItemType: FilterItemTypeEnum.SliderBar,
        libraryComponent: Slider,
        marginTop: 10,
        propsFunc: (filterItem: IFilterItem, filterSettings: FilterSettingsHookType) =>
            sliderBarProps(filterItem, filterSettings),
        keyFunc: (filterItem: IFilterItem) => 'sliderbar-' + filterItem.name,
    },
    {
        filterItemType: FilterItemTypeEnum.PrehireSearch,
        libraryComponent: PrehireSearchComponent,
        marginTop: 10,
        propsFunc: (filterItem: IFilterItem, filterSettings: FilterSettingsHookType) =>
            preHireFilterListPickerProps(filterItem, filterSettings),
        keyFunc: (filterItem: IFilterItem) => 'prehire-search-' + filterItem.name,
    },
    {
        filterItemType: FilterItemTypeEnum.Separator,
        libraryComponent: Text,
        marginTop: 10,
        marginBottom: 5,
        propsFunc: (filterItem: IFilterItem, filterSettings: FilterSettingsHookType) =>
            separatorProps(filterItem),
        keyFunc: (filterItem: IFilterItem) => 'separator-' + filterItem.name,
    },
];

function EmployeeSearchComponent(props: IEmployeePickerTypeaheadSearchProps): JSX.Element {
    return React.createElement(EmployeePickerTypeaheadSearch, {
        ...props,
        searchEmployeeStatus: SearchEmployeeStatus.either,
    });
}

function PrehireSearchComponent(props: IEmployeePickerTypeaheadSearchProps): JSX.Element {
    return React.createElement(EmployeePickerTypeaheadSearch, {
        ...props,
        searchEmployeeStatus: SearchEmployeeStatus.prehire,
    });
}

function checkboxProps(
    filterItem: IFilterItem,
    filterSettings: FilterSettingsHookType,
): ICheckboxProps {
    return {
        label: filterItem.label,
        styles: {
            ...globalCheckboxStyles,
            root: {
                ...globalCheckboxStyles.root,
                // non-null assertion is safe because of the checks performed by useCheckFilterData.
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                marginLeft: filterItem.checkboxOptions!.showWithIndent ? 29 : 0,
            },
        },
        checked: filterSettings.getCheckboxSetting(
            filterItem.name,
            filterItem.checkboxOptions?.checked,
        ),
        disabled: false,
        onChange: (e, newValue): void => {
            filterSettings.updateFilterSetting(filterItem, newValue ?? false);
            // Check if all subfilter items should be checked when the parent filter item is checked
            if (
                filterItem.type === FilterItemTypeEnum.Checkbox &&
                // non-null assertion is safe because of the checks performed by useCheckFilterData.
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                !!filterItem.checkboxOptions!.checkSubFilterItemsOnCheck && // Defaulting the checking the sub-checkboxes to false.
                !!newValue
            ) {
                filterItem.subFilterItems?.forEach((subFilterItem) => {
                    if (subFilterItem.type === FilterItemTypeEnum.Checkbox) {
                        filterSettings.updateFilterSetting(subFilterItem, true);
                    }
                });
            }
            // Check if all subfilter items should be unchecked when the parent filter item is unchecked
            if (
                filterItem.type === FilterItemTypeEnum.Checkbox &&
                // non-null assertion is safe because of the checks performed by useCheckFilterData.
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                (filterItem.checkboxOptions!.uncheckSubFilterItemsOnUncheck ?? true) && // Defaulting unchecking the sub-checkboxes to true.
                !newValue
            ) {
                filterItem.subFilterItems?.forEach((subFilterItem) => {
                    if (subFilterItem.type === FilterItemTypeEnum.Checkbox) {
                        filterSettings.updateFilterSetting(subFilterItem, false);
                    }
                });
            }
        },
    };
}

function datePickerProps(
    filterItem: IFilterItem,
    filterSettings: FilterSettingsHookType,
): IDatePickerProps {
    const dateSetting = filterSettings.getDatePickerValue(filterItem.name);
    const dateValue = !!dateSetting ? new Date(dateSetting) : undefined;
    return {
        label: filterItem.label,
        value: dateValue,
        allowTextInput: filterItem.datePickerOptions?.allowTextInput ?? true,
        firstDayOfWeek: filterItem.datePickerOptions?.firstDayOfWeek,
        placeholder: filterItem.placeholder,
        ariaLabel: filterItem.name,
        showCloseButton: filterItem.datePickerOptions?.showCloseButton,
        highlightCurrentMonth: filterItem.datePickerOptions?.highlightCurrentMonth,
        highlightSelectedMonth: filterItem.datePickerOptions?.highlightSelectedMonth,
        onSelectDate: (newDate?: Date | null | undefined): void => {
            filterSettings.updateFilterSetting(filterItem, newDate);
        },
    };
}

function employeePickerTypeaheadSearchProps(
    filterItem: IFilterItem,
    filterSettings: FilterSettingsHookType,
): IEmployeePickerTypeaheadSearchProps {
    return {
        label: filterItem.label,
        placeHolder: filterItem.placeholder,
        itemLimit: filterItem.employeeSearchOptions?.itemLimit,
        onCandidateSelected: (persona?: IPersonaProps): void => {
            filterSettings.updateFilterSetting(filterItem, persona ? [persona] : []);
        },
        onMultipleCandidatesSelected: (personas?: IPersonaProps[]): void => {
            filterSettings.updateFilterSetting(filterItem, distinctPersonaArray(personas));
        },
        selectedItems: filterSettings.getPersonasSearchValue(filterItem.name) ?? [],
    };
}

function dropdownProps(
    filterItem: IFilterItem,
    filterSettings: FilterSettingsHookType,
): IDropdownProps {
    if (filterItem.dropdownOptions?.multiSelect) {
        return {
            ariaLabel: filterItem.label || filterItem.placeholder,
            label: filterItem.label,
            placeholder: filterItem.placeholder,
            multiSelect: true,
            selectedKeys: filterSettings.getDropdownSelectedKeys(filterItem.name),
            // Type cast is safe because integrity of filter definitions is checked by useCheckFilterData.
            options: filterItem.dropdownOptions?.options as IDropdownOption[],
            onChange: (
                event: FormEvent<HTMLDivElement>,
                option?: IDropdownOption | undefined,
            ): void => {
                filterSettings.updateFilterSetting(filterItem, option);
            },
        };
    } else {
        return {
            ariaLabel: filterItem.label || filterItem.placeholder,
            label: filterItem.label,
            placeholder: filterItem.placeholder,
            // the term "?? ''" is necesary because without it clicking "Clear filters" doesn't clear it.
            selectedKey: filterSettings.getDropdownSelectedKey(filterItem.name) ?? '',
            // Type cast is safe because integrity of filter definitions is checked by useCheckFilterData.
            options: filterItem.dropdownOptions?.options as IDropdownOption[],
            onChange: (
                event: FormEvent<HTMLDivElement>,
                option?: IDropdownOption | undefined,
            ): void => {
                filterSettings.updateFilterSetting(filterItem, option);
            },
        };
    }
}

function textFieldProps(
    filterItem: IFilterItem,
    filterSettings: FilterSettingsHookType,
): ITextFieldProps {
    return {
        placeholder: filterItem.placeholder,
        label: filterItem.label,
        value: filterSettings.getTextFieldSetting(filterItem.name) ?? '',
        onChange: (e, newValue): void => {
            filterSettings.updateFilterSetting(filterItem, newValue);
        },
    };
}

function sliderBarProps(
    filterItem: IFilterItem,
    filterSettings: FilterSettingsHookType,
): ISliderProps {
    return {
        label: filterItem.label,
        min: filterItem.sliderOptions?.min,
        max: filterItem.sliderOptions?.max,
        step: filterItem.sliderOptions?.step,
        showValue: filterItem.sliderOptions?.showValue,
        snapToStep: filterItem.sliderOptions?.snapToStep,
        // non-null assertion is safe because of the checks performed by useCheckFilterData.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        value: filterSettings.getSliderBarValue(filterItem.name) ?? filterItem.sliderOptions!.min,
        ariaLabel: filterItem.name,
        valueFormat: (value: number): string => {
            let result = value.toString();
            if (value === filterItem.sliderOptions?.min && filterItem.sliderOptions?.forMin) {
                return filterItem.sliderOptions.forMin;
            } else if (
                value === filterItem.sliderOptions?.max &&
                filterItem.sliderOptions?.forMax
            ) {
                return filterItem.sliderOptions.forMax;
            }
            if (!!result) {
                if (filterItem.sliderOptions?.prefix) {
                    result = filterItem.sliderOptions.prefix + result;
                }
                if (filterItem.sliderOptions?.suffix) {
                    result += filterItem.sliderOptions.suffix;
                }
            }
            return result;
        },
        onChange: (value: number): void => {
            filterSettings.updateFilterSetting(filterItem, value);
        },
    };
}

function preHireFilterListPickerProps(
    filterItem: IFilterItem,
    filterSettings: FilterSettingsHookType,
): PrehireFilterListPickerProps {
    return {
        onCandidateSelected: (persona?: IPersonaProps): void => {
            filterSettings.updateFilterSetting(filterItem, persona ? [persona] : []);
        },
        selectedItems: filterSettings.getPersonasSearchValue(filterItem.name) ?? [],
        placeHolder: 'PCN/Prehire',
    };
}

function separatorProps(filterItem: IFilterItem): ITextProps {
    return {
        className: globalStyles.separatorText,
        children: filterItem.label,
    };
}

// Key is filterItem.type, value is of type FilterItemLibraryComponentType, specifying what to render.
export const filterItemLibraryComponentsDict = makeDictFromFilterItems(filterItemLibraryComponents);

type FilterItemLibraryComponentType = {
    marginTop?: number;
    marginBottom?: number;
    filterItemType: FilterItemType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    libraryComponent: React.FunctionComponent | ((props: any) => JSX.Element);
    keyFunc: (filterItem: IFilterItem) => string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    propsFunc: (filterItem: IFilterItem, filterSettings: FilterSettingsHookType) => any;
};

function makeDictFromFilterItems(
    filterItems: FilterItemLibraryComponentType[],
): Dictionary<FilterItemLibraryComponentType> {
    const dictionary = filterItems.reduce((curDictionary, filterItem) => {
        return {
            ...curDictionary,
            [filterItem.filterItemType]: filterItem,
        };
    }, {} as Dictionary<FilterItemLibraryComponentType>);
    return dictionary;
}

// Create a dictionary of filter items.
//      Key: filterItem.name
//      Value: filterItem
// Special case for checkboxes:
//      For checkboxes the dictionary will collect value of all "checked"
//      properties related to the checkboxes of the same namespace and
//      puts them in a property called validOptions. The dictionary will
//      not contain value of property "checked".
// Special case for dropdowns:
//      Somewhat similar to checkboxes, the dictionary will maintain option
//      keys in a property called validOptions.
// The first use for validOptions is the following: When translating URL
// parameters into filter settings, the code checks validOptions to make sure
// values provided on the URL are valid selections for checkboxes and dropdowns.
export function makePropertyNameToFilterItemDict(
    flattenedFilterItems: IFilterItem[],
    isFilterDefinitionsOk: boolean,
): Record<string, IFilterItem> {
    const result: Record<string, IFilterItem> = {};
    if (!isFilterDefinitionsOk) {
        return result;
    }
    flattenedFilterItems.forEach((filterItem) => {
        let filterItemVar: IFilterItem;
        switch (filterItem.type) {
            case FilterItemTypeEnum.Checkbox:
                // Populate the property validOptions in the dictionary.
                filterItemVar = result[filterItem.name];
                if (!filterItemVar) {
                    filterItemVar = deepcopy(filterItem);
                    // In the dictionary, Clear value of property "checked". Don't clear
                    // it in the original list of filter items nor in the flattened list.
                    // Populate Property "validOptions" in the dictionary. It will contain
                    // all values of the "checked" properties.
                    filterItemVar.checkboxOptions!.checked = '';
                    filterItemVar.checkboxOptions!.validOptions = {};
                }
                filterItemVar.checkboxOptions!.validOptions![
                    filterItem.checkboxOptions!.checked
                ] = true;
                break;
            case FilterItemTypeEnum.Dropdown:
                // Populate the property "validOptions" in the dictionary.
                filterItemVar = deepcopy(filterItem);
                filterItemVar.dropdownOptions!.validOptions = {};
                filterItem.dropdownOptions!.options.forEach((o) => {
                    filterItemVar.dropdownOptions!.validOptions![o.key] = true;
                });
                break;
            default:
                filterItemVar = deepcopy(filterItem);
                break;
        }
        result[filterItem.name] = filterItemVar;
    });
    return result;
}

export function flattenFilterItems(filterItems: IFilterItem[]): IFilterItem[] {
    const result: IFilterItem[] = [];
    filterItems.forEach((filterItem) => {
        result.push(filterItem);
        if (filterItem.subFilterItems) {
            result.push(...flattenFilterItems(filterItem.subFilterItems));
        }
    });
    return result;
}
