import React from 'react';
import { SetStateFunc } from 'types/global-types';
import {
    IRenderFunction,
    Stack,
    IChoiceGroupOption,
    ChoiceGroup,
    IChoiceGroupOptionProps,
} from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import { ISecurityGroup } from 'clients/group-client';
import IsLoadingIndicator from 'components/common/is-loading-indicator';

interface IProps {
    securityGroups: ISecurityGroup[];
    fednetGroupGuidInputValue: string | undefined;
    fednetGuidInputElement: () => JSX.Element;
    hasErrGettingGroups: boolean;
    isFetchingGroups: boolean;
    selectedGroupChoice: string;
    canLinkToFednetGroup: boolean;
    setSelectedGroupOptionKey: SetStateFunc<string>;
}

export const FedNetGroupOptionKey = 'fedNetGroup';

export default function GroupSelectionTable(props: IProps): JSX.Element {
    const onChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ): void => {
        if (!!option) {
            props.setSelectedGroupOptionKey(option.key);
        }
    };

    const optionsHeader = (): JSX.Element => {
        return (
            <div>
                <Stack horizontal>
                    <Stack.Item style={{ width: '190px' }} className={globalStyles.boldFont}>
                        Name
                    </Stack.Item>
                    <Stack.Item style={{ width: '250px' }} className={globalStyles.boldFont}>
                        Email
                    </Stack.Item>
                    <Stack.Item style={{ width: '100px' }} className={globalStyles.boldFont}>
                        Type
                    </Stack.Item>
                    <Stack.Item style={{ width: '100px' }} className={globalStyles.boldFont}>
                        Options
                    </Stack.Item>
                </Stack>
            </div>
        );
    };

    const fedNetGroupChoiceOption = props.canLinkToFednetGroup
        ? ([
              {
                  key: FedNetGroupOptionKey,
                  text: 'Link To A FedNet Group',
                  ariaLabel: 'FedNet Group',
                  onRenderField: ((renderProps, render) => {
                      return (
                          <Stack horizontal style={{ marginBottom: 5 }} verticalAlign='center'>
                              <Stack.Item style={{ width: '190px' }}>
                                  {!!render && render(renderProps)}
                              </Stack.Item>
                              <Stack.Item style={{ width: '300px' }}>
                                  {props.fednetGuidInputElement()}
                              </Stack.Item>
                          </Stack>
                      );
                  }) as IRenderFunction<IChoiceGroupOptionProps>,
              },
          ] as IChoiceGroupOption[])
        : [];

    const otherGroupsChoiceOptions = props.securityGroups.map(
        (group): IChoiceGroupOption => {
            return {
                key: group.id,
                text: group.name,
                ariaLabel: group.name,
                onRenderField: (renderProps, render) => {
                    return (
                        <Stack horizontal style={{ marginBottom: 5 }}>
                            <Stack.Item style={{ width: '190px' }}>
                                <Stack.Item>{!!render && render(renderProps)} </Stack.Item>
                            </Stack.Item>
                            <Stack.Item
                                style={{
                                    width: '240px',
                                    paddingRight: 10,
                                    overflowWrap: 'anywhere',
                                }}>
                                <Stack.Item>{group.mail} </Stack.Item>
                            </Stack.Item>
                            <Stack.Item style={{ width: '100px' }}>{group?.type}</Stack.Item>
                            <Stack.Item style={{ width: '100px' }}>
                                {group?.options.join(',')}
                            </Stack.Item>
                        </Stack>
                    );
                },
            };
        },
    );

    const groupSelectionOptions = otherGroupsChoiceOptions.concat(fedNetGroupChoiceOption);

    if (props.isFetchingGroups) {
        return <IsLoadingIndicator isLoading={props.isFetchingGroups} msg='Loading groups...' />;
    } else if (props.hasErrGettingGroups) {
        return <div className={globalStyles.boldFont}>Error loading groups</div>;
    } else if (props.securityGroups.length === 0) {
        return (
            <div className={globalStyles.boldFont}>You are not an owner of any Security Group</div>
        );
    } else {
        return (
            <div>
                <div className={globalStyles.label}>Your Groups</div>
                {optionsHeader()}
                <ChoiceGroup
                    selectedKey={props.selectedGroupChoice}
                    onChange={onChange}
                    options={groupSelectionOptions}
                />
            </div>
        );
    }
}
