import { Dropdown, IDropdownOption, MessageBar, mergeStyleSets } from '@fluentui/react';
import { globalStyles } from 'assets/styles/global-styles';
import { IGroupRule } from 'clients/group-client';
import { AttributeOrEligibility } from 'components/groups/manage-group/policy/buttons/create-policy-rule/create-edit-policy-rule-modal-action-button';
import React, { SetStateAction, useEffect, useState } from 'react';

interface IOptionSelectDisplayProps {
    dropDownOptions: IDropdownOption[];
    type: string;
    setChosenIDs: React.Dispatch<SetStateAction<string[]>>;
    chosenValue: IGroupRule;
    attributeOrEligibilityIDs: string[];
    isEditing: boolean;
    ariaLabel?: string;
}

const eligibilityNotFound = 'Eligibility not found';

export default function OptionSelectDisplay(props: IOptionSelectDisplayProps): JSX.Element {
    const [showDropDown, setShowDropDown] = useState<boolean>(true);
    const [chosenItems, setChosenItems] = useState<IDropdownOption[]>([]);
    const [localOptions, setLocalOptions] = useState<IDropdownOption[]>();

    useEffect(() => {
        setLocalOptions(props?.dropDownOptions);
        if (props.isEditing) {
            setChosenItems([]);
            const items: IDropdownOption[] = [];
            props.attributeOrEligibilityIDs.forEach((id) => {
                const foundId = props.dropDownOptions.filter((option) => {
                    return option.key === id;
                });
                if (foundId.length > 0) {
                    items.push(foundId[0]);
                }
            });
            if (items.length) {
                setChosenItems(items);
            } else {
                setChosenItems([{ key: 0, text: eligibilityNotFound }]);
            }
        }
    }, [props.dropDownOptions, props.attributeOrEligibilityIDs]);

    const onHandleDismissItem = (item: IDropdownOption): void => {
        if (item.text !== eligibilityNotFound) {
            const updatedItems = chosenItems.filter((i) => i.text !== item.text);
            const updatedItemIDs = props.attributeOrEligibilityIDs.filter((i) => i !== item.key);

            props.setChosenIDs(updatedItemIDs);
            setChosenItems(updatedItems);
            if (localOptions) {
                setLocalOptions([...localOptions, item]);
            }
            if (updatedItems.length === 0) {
                setShowDropDown(true);
            }
        } else {
            setChosenItems([]);
            setLocalOptions(props.dropDownOptions);
        }
    };

    const onChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            props.setChosenIDs([...props.attributeOrEligibilityIDs, item?.key.toString()]);
            setChosenItems([...chosenItems, item]);
            setShowDropDown(false);

            const updatedOptions = localOptions?.filter((i) => i !== item);
            setLocalOptions(updatedOptions);
        }
    };

    const chosenItemBar = chosenItems.map((item: IDropdownOption) => (
        <div className={styles.messageBarContainer} key={item.key}>
            <MessageBar
                dismissButtonAriaLabel='Close'
                className={styles.messageBar}
                onDismiss={(e): void => {
                    if (e) {
                        onHandleDismissItem(item);
                    }
                }}>
                {item.text}
            </MessageBar>
            {chosenItems.length > 1 && chosenItems[chosenItems.length - 1] !== item && (
                <div>Or</div>
            )}
        </div>
    ));

    return (
        <>
            <p>
                Member must have <b>any</b> of the following{' '}
                {props.type === AttributeOrEligibility.attribute
                    ? `${AttributeOrEligibility.attribute.toLowerCase()}s`
                    : `${AttributeOrEligibility.eligibility.toLowerCase().slice(0, -1)}ies`}
            </p>
            <div className={styles.messageBarSection}>{chosenItemBar}</div>

            {chosenItems.length === 0 && !showDropDown && (
                <a className={globalStyles.link} onClick={(): void => setShowDropDown(true)}>
                    Choose {props.type}...
                </a>
            )}

            {showDropDown && localOptions && (
                <div className={styles.dropdownContainer}>
                    <Dropdown
                        className={styles.dropDown}
                        placeholder={`Select ${props.type}`}
                        onChange={onChange}
                        options={localOptions}
                        ariaLabel={props.ariaLabel}
                    />
                </div>
            )}

            {chosenItems.length > 0 && !showDropDown && (
                <a className={globalStyles.link} onClick={(): void => setShowDropDown(true)}>
                    Add Alternative...
                </a>
            )}
        </>
    );
}

const styles = mergeStyleSets({
    dropDown: {
        width: '100%',
        maxWidth: 320,
        marginRight: 10,
    },
    messageBarSection: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    dropdownContainer: {
        display: 'flex',
        marginBottom: 10,
        alignItems: 'center',
    },
    messageBarContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        selectors: {
            '& div:nth-child(1)': {
                width: 'auto',
            },
            '& div:nth-child(2)': {
                paddingLeft: 10,
                paddingRight: 10,
            },
        },
    },
    messageBar: {
        selectors: {
            '.ms-MessageBar-icon': {
                display: 'none',
            },
        },
    },
});
