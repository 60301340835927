import { ActionButton, IColumn } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { contentMaxHeight } from 'assets/styles/list-styles';
import { IEmployeeWithEditableData } from 'clients/employee-client';
import FormsClient, {
    convertFormRecordToForm,
    GenericFormRecord,
    GenericFormRecordQueryRequest,
    GenericFormRecordQueryResults,
} from 'clients/forms-client';
import { AuthContext } from 'contexts/auth-context';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import { Table, TableCell } from 'components/common/table';
import { SharedColors } from '@fluentui/theme';
import {
    firstColumnTableCellPadding,
    firstColumnTitlePadding,
} from 'components/personnel-profile/common/common-constants';
import {
    actionButtonStyles,
    commonPersonnelPersonnelStyles,
} from 'components/personnel-profile/common-personnel-profile-styles';
import { FormResponse, StringDictionary } from 'components/forms/forms-common';
import { dateToLocalDate } from 'utils/time-utils';
import EllipsisText from 'components/common/ellipsis-text';
import { getDefaultColumnWidths } from 'utils/table-utils';

interface IForeignTravelDisplayData {
    id: string;
    name: string;
    duration: string;
    destination: string;
    purpose: string;
    reported: string;
}

interface IParsedForeignTravelData {
    travelLegs: ITravelLeg[];
    natureOfTravel: string;
}

interface ITravelLeg {
    departureDate: string;
    departureLocation: string;
    arrivalDate: string;
    arrivalLocation: string;
}

export interface PersonnelProfileForeignTravelTableProps {
    employee: IEmployeeWithEditableData | undefined;
    resultSize: number;
}

function FormatNatureOfTravelHelper(str: string): string {
    return str
        .toLowerCase()
        .split('_')
        .map(function (word) {
            return word.charAt(0).toUpperCase() + word.substr(1);
        })
        .join(' ')
        .replace(new RegExp('\\bOr\\b', 'g'), '/');
}

function DetermineMostRecentUTCDate(firstDate: Date | number, secondDate: Date | number): number {
    let res = 0;
    if (secondDate > firstDate) res = 1;
    if (secondDate < firstDate) res = -1;
    return res;
}

function GetTravelDataFromFormRecord(formRecord: FormResponse): IParsedForeignTravelData {
    const result: IParsedForeignTravelData = { travelLegs: [], natureOfTravel: '' };
    const departureTimeGUID = 'b2380227-a561-47f4-8854-dafa344a574f';
    const departureCityGUID = '3632adff-3d26-4e50-9e05-8b5fdd598f3f';
    const arrivalTimeGUID = '5a228a74-225c-4723-a52a-9bb2e4a46687';
    const arrivalCityGUID = 'c49b4124-9d42-4c07-a585-580679cdb2a7';
    const natureOfTravelGUID = 'b78a089d-32c0-4ff9-b649-1d975ebdda8f';
    const travelLegsLabel = 'Your Travel Legs';
    const natureOfTravelLabel = 'Administrative Details';

    // get the travel leg data from generic form record
    const travelLegsData = formRecord.sections.find((x) => x.label === travelLegsLabel);

    if (travelLegsData && travelLegsData.elements[0].value) {
        const travelLegs = travelLegsData.elements[0].value as StringDictionary[];
        travelLegs.forEach((travelLeg) => {
            const res: ITravelLeg = {
                departureDate: travelLeg[departureTimeGUID] ? travelLeg[departureTimeGUID] : '',
                departureLocation: travelLeg[departureCityGUID] ? travelLeg[departureCityGUID] : '',
                arrivalDate: travelLeg[arrivalTimeGUID] ? travelLeg[arrivalTimeGUID] : '',
                arrivalLocation: travelLeg[arrivalCityGUID] ? travelLeg[arrivalCityGUID] : '',
            };
            result.travelLegs = result.travelLegs.concat(res);
        });
    }

    // get the nature of travel information from generic form record
    const natureOfTravelData = formRecord.sections.find((x) => x.label === natureOfTravelLabel);

    if (natureOfTravelData && natureOfTravelData.elements.length > 0) {
        const natureOfTravelText = natureOfTravelData.elements.find(
            (element) => element.id === natureOfTravelGUID,
        );
        if (natureOfTravelText && natureOfTravelText.value) {
            result.natureOfTravel = natureOfTravelText.value as string;
        }
    }

    return result;
}

export default function PersonnelProfileForeignTravelTable(
    props: PersonnelProfileForeignTravelTableProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [travelFormRecords, setTravelFormRecords] = useState<GenericFormRecord[] | undefined>([]);
    const [foreignTravelData, setForeignTravelData] = useState<IForeignTravelDisplayData[]>([]);
    const [currentDestination, setCurrentDestination] = useState<string | undefined>('');
    const foreignReportName = 'report_foreign_travel';

    useEffect(() => {
        let isMounted = true;

        async function getTravelFormRecord(alias: string | undefined | null): Promise<void> {
            let genericFormRecordQueryResults:
                | GenericFormRecordQueryResults
                | undefined = undefined;
            let allTravelFormData: GenericFormRecord[] = [];
            if (alias) {
                const searchRequest: GenericFormRecordQueryRequest = {
                    genericForms: [
                        {
                            name: foreignReportName,
                            // forms will never be in any other version except 1.0.0 (unless a huge architecture change in how forms are handled)
                            version: '1.0.0',
                        },
                    ],
                    // createdBy for a user is using a case-sensitive alias with '@microsoft.com' appended to it
                    createdBy: alias.toLocaleLowerCase() + '@microsoft.com',
                    // requirement is to only show foreign travels up to 2 years ago
                    submittedFrom: new Date(
                        new Date().setFullYear(new Date().getFullYear() - 2),
                    ).toISOString(),
                };

                let continuationToken = '';
                do {
                    genericFormRecordQueryResults = await FormsClient.QueryGenericFormRecords(
                        authContext,
                        searchRequest,
                    );
                    allTravelFormData = allTravelFormData.concat(
                        genericFormRecordQueryResults.results,
                    );
                    continuationToken = genericFormRecordQueryResults.continuationToken;
                } while (continuationToken);
            }

            if (allTravelFormData.length > 0 && isMounted) {
                // only take the top resultSize of travel form data to display
                // sorted by most recent submitted dates
                allTravelFormData.sort((a, b) => {
                    return DetermineMostRecentUTCDate(a.submittedAtUTC, b.submittedAtUTC);
                });
                setTravelFormRecords(allTravelFormData.slice(0, props.resultSize));
            }
        }
        getTravelFormRecord(props.employee?.data?.alias);

        return (): void => {
            isMounted = false;
        };
    }, [props.employee, props.resultSize]);

    useEffect(() => {
        let isMounted = true;

        function ParseTravelFormDataForDisplay(travelFormData: GenericFormRecord[]): void {
            const result: IForeignTravelDisplayData[] = [];
            const today = new Date().getTime();
            let hasSetCurrentDestination = false;

            travelFormData.forEach((data) => {
                const formRecord = convertFormRecordToForm(data, foreignReportName);
                const parsedForeignTravelData = GetTravelDataFromFormRecord(formRecord);

                let destination: string;
                let startDate: number;
                let endDate: number;
                // if there's only one travel leg
                const travelLegs = parsedForeignTravelData.travelLegs;
                if (travelLegs.length > 0) {
                    if (travelLegs.length === 1) {
                        startDate = Date.parse(travelLegs[0].departureDate);
                        endDate = Date.parse(travelLegs[0].arrivalDate);
                        destination = travelLegs[0].arrivalLocation;
                    } else {
                        startDate = Date.parse(travelLegs[0].departureDate);
                        endDate = Date.parse(travelLegs[travelLegs.length - 1].arrivalDate);
                        // check for roundtrip
                        if (
                            travelLegs[0].departureLocation ===
                            travelLegs[travelLegs.length - 1].arrivalLocation
                        ) {
                            destination = travelLegs[travelLegs.length - 1].departureLocation;
                        }
                        // otherwise it's multi-leg one way/multi-leg roundtrip
                        else {
                            destination = travelLegs[travelLegs.length - 1].arrivalLocation;
                        }
                    }

                    // set current destination only once based on most recent travel date range
                    if (today >= startDate && today <= endDate && !hasSetCurrentDestination) {
                        hasSetCurrentDestination = true;
                        setCurrentDestination(destination.toUpperCase());
                    }

                    const foreignTravelInfo: IForeignTravelDisplayData = {
                        id: data.id,
                        name: data.name,
                        duration: `${dateToLocalDate(startDate)} - ${dateToLocalDate(endDate)}`,
                        destination: destination,
                        purpose: parsedForeignTravelData.natureOfTravel,
                        reported: dateToLocalDate(data.submittedAtUTC * 1000),
                    };

                    result.push(foreignTravelInfo);
                }
            });
            setForeignTravelData(result);
        }

        if (isMounted && travelFormRecords) {
            ParseTravelFormDataForDisplay(travelFormRecords);
        }

        return (): void => {
            isMounted = false;
        };
    }, [travelFormRecords]);

    return (
        <>
            {props.employee && (
                <ContainerWithEtiquettes
                    leftEtiquetteLabel='Foreign travel history'
                    rightEtiquette={
                        currentDestination
                            ? {
                                  label: `CURRENTLY IN ${currentDestination}`,
                                  backgroundColor: SharedColors.cyanBlue10,
                              }
                            : undefined
                    }
                    bottomInfo={
                        foreignTravelData.length > 0 && (
                            <ActionButton
                                styles={actionButtonStyles}
                                title='View All Travel Submissions'
                                iconProps={{ iconName: 'Airplane' }}
                                // the current forms UI don't support deeplinking so redirecting to the manage page for user to use filtering themselves
                                href={`/forms/manage`}>
                                View all
                            </ActionButton>
                        )
                    }>
                    {foreignTravelData.length === 0 ? (
                        <p className={commonPersonnelPersonnelStyles.noDataParagraph}>
                            No Foreign travel forms submitted
                        </p>
                    ) : (
                        <Table<IForeignTravelDisplayData>
                            rows={foreignTravelData}
                            isFetchingData={false}
                            tableColumns={columns}
                            tableName='Foreign Travel Records'
                        />
                    )}
                </ContainerWithEtiquettes>
            )}
        </>
    );
}

const columnWidths = getDefaultColumnWidths(5);

const columns: IColumn[] = [
    {
        key: 'Duration',
        name: 'Duration',
        ariaLabel: 'Duration',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        styles: { cellTitle: { paddingLeft: firstColumnTitlePadding } }, // DetailsList applies this to the header cell.
        onRender: (row: IForeignTravelDisplayData): JSX.Element => (
            <TableCell style={{ paddingLeft: firstColumnTableCellPadding }}>
                {row.duration}
            </TableCell>
        ),
    },
    {
        key: 'Destination',
        name: 'Destination',
        ariaLabel: 'Destination',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        onRender: (row: IForeignTravelDisplayData): JSX.Element => (
            <TableCell>{row.destination}</TableCell>
        ),
    },
    {
        key: 'Purpose',
        name: 'Purpose',
        ariaLabel: 'Purpose',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        onRender: (row: IForeignTravelDisplayData): JSX.Element => (
            <TableCell>
                <EllipsisText
                    text={FormatNatureOfTravelHelper(row.purpose)}
                    textLengthBeforeEllipsis={16}
                />
            </TableCell>
        ),
    },
    {
        key: 'Reported',
        name: 'Reported',
        ariaLabel: 'Reported',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        onRender: (row: IForeignTravelDisplayData): JSX.Element => (
            <TableCell>{row.reported}</TableCell>
        ),
    },
    {
        key: 'action',
        name: 'Action',
        ariaLabel: 'Action',
        minWidth: columnWidths.actionColumnWidth,
        maxWidth: columnWidths.actionColumnWidth,
        isRowHeader: true,
        onRender: (row: IForeignTravelDisplayData): JSX.Element => (
            <span style={{ paddingLeft: 18 }}>
                <TableCell>
                    <ActionButton
                        styles={{ root: { maxHeight: contentMaxHeight } }}
                        title='View Submission'
                        iconProps={{ iconName: 'EntryView' }}
                        href={`/forms/review/${row.name}/${row.id}`}>
                        View
                    </ActionButton>
                </TableCell>
            </span>
        ),
    },
];
