import { lightTextColor, darkTextColor } from 'assets/styles/global-styles';

export enum ClearanceStatus {
    Ready = 'Ready',
    InProcess = 'InProcess',
    PreHire = 'PreHire',
    OpenPcn = 'OpenPcn',
    NotReady = 'NotReady',
    VacatePcn = 'VacatePcn',
    NoStatus = 'NoStatus',
}

enum ClearanceStatusOrder {
    Ready = 0,
    InProcess = 1,
    PreHire = 2,
    OpenPcn = 3,
    NotReady = 4,
    VacatePcn = 5,
    NoStatus = 6,
    'undefined' = 7, // Useful for sorting
}

export enum ClearanceStatusTitle {
    Ready = 'Ready',
    InProcess = 'In Process',
    PreHire = 'Pre-Hire',
    OpenPcn = 'Open PCN',
    NotReady = 'Not Ready',
    VacatePcn = 'Vacate PCN',
    NoStatus = 'No Status',
}

enum ClearanceStatusBackground {
    Ready = '#40C540',
    InProcess = '#FFE603',
    PreHire = '#F4B202',
    OpenPcn = '#E97E00',
    NotReady = '#E81223',
    VacatePcn = '#800080',
    NoStatus = '#FFFFFF',
}

export const EmployeeBoxBackground = 'rgb(201, 201, 201)';

export const EmptyBoxBackground = 'rgb(161, 161, 161)';

const ClearanceStatusForeground = {
    Ready: lightTextColor,
    InProcess: darkTextColor,
    PreHire: darkTextColor,
    OpenPcn: darkTextColor,
    NotReady: darkTextColor,
    VacatePcn: lightTextColor,
    NoStatus: darkTextColor,
};

enum ClearanceStatusLegendBorderColor {
    NoStatus = 'grey',
}

export const getStatusBackgroundColor = (statusName: string): string => {
    return (
        (ClearanceStatusBackground as { [k: string]: string })[statusName] ||
        ClearanceStatusBackground.NoStatus
    );
};

export const getStatusForegroundColor = (statusName: string): string => {
    return (
        (ClearanceStatusForeground as { [k: string]: string })[statusName] ||
        ClearanceStatusForeground.NoStatus
    );
};

export const getStatusBarChartLegendBorderColor = (statusName: string): string | undefined => {
    const result =
        (ClearanceStatusLegendBorderColor as { [k: string]: string })[statusName] || undefined;
    return result;
};

export const getStatusNames = (): string[] => Object.keys(ClearanceStatusTitle);

export const getStatusTitle = (statusName: string): string => {
    return (
        (ClearanceStatusTitle as { [k: string]: string })[statusName] ||
        ClearanceStatusTitle.NoStatus
    );
};

export const statusSortCmp = (
    statusName1: ClearanceStatusTextType | undefined,
    statusName2: ClearanceStatusTextType | undefined,
): number => {
    const s1 = statusName1 || 'undefined';
    const s2 = statusName2 || 'undefined';
    return ClearanceStatusOrder[s1] - ClearanceStatusOrder[s2];
};

export enum CloudStatus {
    EX = 'EX',
    RX = 'RX',
}

export enum CloudStatusMap {
    EX = 'USNat',
    RX = 'USSec',
}

export type CloudMapStrings = keyof typeof CloudStatusMap;

export const cloudKeys = (): CloudMapStrings[] => Object.keys(CloudStatusMap) as CloudMapStrings[];

export const cloudNames = (): string[] => Object.values(CloudStatusMap);

export type ClearanceStatusTextType = keyof typeof ClearanceStatusTitle;

export const translateCloudName = (cloudName: CloudMapStrings | undefined): string => {
    if (cloudName) {
        return CloudStatusMap[cloudName];
    }
    return ClearanceStatusTitle.NoStatus;
};

export const Projects = [
    {
        name: 'JEDI',
    },
];

export const StatusTableMaxColumns = 8;

export const orgHasNoTeamMsg = (orgName: string): string => `${orgName} has no team yet`;
