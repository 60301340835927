import React, { ReactNode, createContext, useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { Dictionary } from 'assets/constants/global-constants';
import { IEmployee } from 'clients/employee-client';
import { useAttributes, useEligibilities } from 'components/eligibilities/eligibilities-utils';
import { IAttribute, IEligibility } from 'clients/eligibility-client';

type contextStateType = {
    attributes: IAttribute[];
    eligibilities: IEligibility[];
    employeeDict: Dictionary<IEmployee>;
    addEmployee: (idOrAlias: string | undefined, employee: IEmployee | undefined) => void;
};

export const UserAssignmentsContext = createContext<contextStateType>(null!);

export interface IUserAssignmentsProviderProps {
    children: ReactNode;
}

export default function UserAssignmentsProvider(props: IUserAssignmentsProviderProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const [employeeDict, setEmployeeDict] = useState<Dictionary<IEmployee>>({});

    const addEmployee = (idOrAlias: string | undefined, employee: IEmployee | undefined): void => {
        if (!idOrAlias || !employee) return;
        if (!employeeDict[idOrAlias]) {
            setEmployeeDict({
                ...employeeDict,
                [idOrAlias]: employee,
            });
        }
    };

    const { attributes } = useAttributes(authContext);

    const { eligibilities } = useEligibilities(authContext);

    const contextValue = {
        attributes,
        eligibilities,
        employeeDict,
        addEmployee,
    };

    return (
        <UserAssignmentsContext.Provider value={contextValue}>
            {props.children}
        </UserAssignmentsContext.Provider>
    );
}
