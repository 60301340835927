import React, { useContext, useState, useEffect } from 'react';
import EligibilityClient, {
    IAttribute,
    IEligibility,
    IEligibilityRequest,
} from 'clients/eligibility-client';
import { Dictionary } from 'assets/constants/global-constants';
import { Location } from 'history';
import { Table } from 'components/common/table';
import { AuthContext } from 'contexts/auth-context';
import { IPagedResults } from 'clients/http-options';
import { generalIsMountedCode, distinct } from 'utils/misc-utils';
import { getEligibilityRequestsTableColumns } from 'components/eligibilities/eligibilities/view/table-columns-eligibility-requests';
import { useSortColumnHandler, numCmp } from 'utils/sort-utils';
import { ProblemLoadingMsg } from 'components/common/problem-loading/problem-loading-msg';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import { Stack } from '@fluentui/react';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { CheckScrollReachedBottom } from 'components/common/scroll-event-listener';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import ClearSeparator from 'components/common/clear-separator';

interface IPeriodDetailsPros {
    location: Location;
    eligibility: IEligibility | undefined;
    attributesDict: Dictionary<IAttribute>;
}

export default function DisplayEligibilityRequests(props: IPeriodDetailsPros): JSX.Element {
    const authContext = useContext(AuthContext);

    const [requests, setRequests] = useState<IEligibilityRequest[]>([]);
    const [continuationToken, setContinuationToken] = useState<string>();
    const [isAllRequestsLoaded, setIsAllRequestsLoaded] = useState<boolean>(false);
    const [problemFetching, setProblemFetching] = useState<string>('');
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [shouldLoad, setShouldLoad] = useState<boolean>(true);
    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler(
        'Date Requested',
        -1,
    );
    const [tableRows, setTableRows] = useState<IEligibilityRequest[]>([]);
    // Unique Ids of personnel shown on this component
    // so far, for as long as it has been mounted.
    const [personnelIds, setPersonnelIds] = useState<string[]>([]);
    // Eligibilities of the personnel listed by the above variable.
    const [personnelEligibilitiesDict, setPersonnelEligibilitiesDict] = useState<
        Dictionary<IEligibility[]>
    >({});

    const isLoadingMore = (): boolean => isLoading && !isInitialLoad;

    /** ********************************************
     * - Fetch requests of the given eligibility
     * - Maintain a unique list of personnel Ids
     *   that are specified on each set of requests.
     * - Manage "Load More" button.
     */
    async function performFetchRequests(): Promise<IPagedResults<IEligibilityRequest>> {
        if (props?.eligibility?.id) {
            return EligibilityClient.getEligibilityRequests(
                authContext,
                props?.eligibility.id,
                continuationToken ?? '',
            );
        } else {
            return {
                results: [],
                continuationToken: '',
            };
        }
    }

    const fetchRequests = async (isMountedFunc: () => boolean): Promise<void> => {
        if (!shouldLoad) return;
        try {
            setIsLoading(true);
            const fetchResult = await performFetchRequests();
            if (isMountedFunc()) {
                setProblemFetching('');
                const requestsVar = [...requests, ...fetchResult.results];
                setRequests(requestsVar);
                setPersonnelIds(
                    distinct(
                        [...personnelIds].concat(
                            fetchResult.results.map((item) => item.personnelId),
                        ),
                    ),
                );
                setContinuationToken(fetchResult.continuationToken);
                if (!fetchResult.continuationToken) setIsAllRequestsLoaded(true);
            }
        } catch (e) {
            if (isMountedFunc()) {
                if (typeof e === 'string') setProblemFetching(e);
                else setProblemFetching('Error loading requests');
            }
        } finally {
            if (isMountedFunc()) {
                setIsLoading(false);
                setShouldLoad(false);
                setIsInitialLoad(false);
            }
        }
    };

    useEffect(() => {
        return generalIsMountedCode(fetchRequests);
    }, [props.eligibility?.id, shouldLoad]);

    /**
     * END Fetch requests of the given eligibility
     * ********************************************/

    /* *********************************************************************
     * Fetch available eligibilities of the personnel that are specified by
     * the requests. Remember which personnel have been fetched so that each
     * one is fetched only once.
     */
    const fetchPersonnelEligibilities = async (isMountedFunc: () => boolean): Promise<void> => {
        const fetchTheseIds: string[] = [];
        personnelIds.forEach((personnelId) => {
            if (!personnelEligibilitiesDict[personnelId]) {
                fetchTheseIds.push(personnelId);
            }
        });

        if (fetchTheseIds.length === 0) {
            return;
        }

        try {
            const eligibilitiesVar = await Promise.all(
                distinct(fetchTheseIds).map((personnelId) =>
                    EligibilityClient.getPersonnelAvailableEligibilities(authContext, personnelId),
                ),
            );
            const personnelEligibilitiesDictVar = { ...personnelEligibilitiesDict };
            fetchTheseIds.forEach((personnelId, ix) => {
                personnelEligibilitiesDictVar[personnelId] = eligibilitiesVar[ix];
            });
            if (isMountedFunc()) {
                setPersonnelEligibilitiesDict(personnelEligibilitiesDictVar);
            }
        } catch (e) {
            console.error('Error fetching personnel eligibities');
        }
    };

    useEffect(() => {
        return generalIsMountedCode(fetchPersonnelEligibilities);
    }, [personnelIds]);

    /**
     * END Fetch available eligibilities of personnel specified by the requests.
     * *************************************************************************/

    const sortTable = (requests: IEligibilityRequest[]): IEligibilityRequest[] => {
        type A = IEligibilityRequest;

        const chooseSortCmp = (
            sortColumn: string,
        ): ((r1: IEligibilityRequest, r2: IEligibilityRequest) => number) => {
            switch (sortColumn) {
                case 'Date Requested':
                    return (r1: A, r2: A): number =>
                        sortAscending * numCmp(r1.createdAt, r2.createdAt);
                default:
                    // Sort column not recognized. No sorting performed.
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    return (r1: A, r2: A): number => 0;
            }
        };
        const sortCmp = chooseSortCmp(sortColumn);
        return [...requests].sort(sortCmp);
    };

    useEffect(() => {
        setTableRows(sortTable(requests));
    }, [sortColumn, sortAscending, requests, props.eligibility]);

    const meetsEligibilityRequirement = (
        personnelId: string,
        eligibility?: IEligibility,
    ): boolean => {
        return personnelEligibilitiesDict[personnelId]?.some(
            (thisEligibility) => thisEligibility.eligibilityCode === eligibility?.eligibilityCode,
        );
    };

    const onManageRequestConcluded = (
        modalConclusion: ModalConclusion,
        result?: IEligibilityRequest,
    ): void => {
        if (modalConclusion === ModalConclusion.Done && result) {
            const ix = requests.findIndex((request) => request.id === result.id);
            if (ix > -1) {
                const requestsVar = [...requests];
                requestsVar.splice(ix, 1);
                setRequests(requestsVar);
            }
        }
    };

    const tableColumns = getEligibilityRequestsTableColumns({
        sortColumn,
        eligibility: props.eligibility,
        sortAscending: sortAscending === 1,
        attributesDict: props.attributesDict,
        sortColumnHandler,
        onManageRequestConcluded,
        meetsEligibilityRequirement,
    });

    const scrollEventHandler = (): void => {
        if (shouldLoad || isLoading || !continuationToken) {
            return;
        }
        setShouldLoad(true);
    };

    return (
        <>
            <PageLoadingSpinner
                label='Loading requests...'
                ariaLive='assertive'
                isLoading={isInitialLoad}
                labelPosition='left'>
                <ProblemLoadingMsg problemLoadingMsg={problemFetching}>
                    <Stack>
                        <Stack.Item>
                            <Table
                                rows={tableRows || []}
                                tableColumns={tableColumns}
                                isFetchingData={false}
                                tableName='Eligibility Requests'
                            />
                        </Stack.Item>
                        <ClearSeparator />
                        <IsLoadingIndicator
                            isLoading={
                                !isAllRequestsLoaded && !!continuationToken && isLoadingMore()
                            }
                            msg='Loading more'
                            ifNotLoading={<ClearSeparator />}
                        />
                        <ClearSeparator />
                    </Stack>
                </ProblemLoadingMsg>
            </PageLoadingSpinner>
            <CheckScrollReachedBottom
                shouldRemoveHandler={isAllRequestsLoaded}
                onScrollReachedBottom={scrollEventHandler}
            />
        </>
    );
}
