import React, { useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'contexts/auth-context';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import { globalStyles, globalSeparatorStyles } from 'assets/styles/global-styles';
import SidebarAndContents, {
    SidebarPane,
    ContentPane,
} from 'components/common/sidebar-and-contents';
import { useDetermineImage } from 'components/common/employee/employee-utils';
import Spacer from 'components/common/spacer';
import CheckRole from 'components/common/check-role';
import { Role } from 'configs/roles';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { Separator, IPersonaProps } from '@fluentui/react';
import { employeeHistoryUrl } from 'components/sca/sca-breadcrumbs';
import { AccessDeniedURL } from 'assets/constants/global-constants';
import { IEmployee } from 'clients/employee-client';
import Tabs, { TabbedContent } from 'components/common/tabs';
import EmployeeHistoryTable from 'components/sca/history/employee-history-table';
import { UserContext } from 'contexts/user-context';
import ScaViewMyTeamFilterWrapper from 'components/sca/my-team/sca-view-my-team-filter-wrapper';
import ScaViewMyOrgFilterWrapper from 'components/sca/my-org/sca-view-my-org-filter-wrapper';
import { HistoryPageValidTabs } from 'components/sca/sca-constants';
import { scaMyHistoryUrl, scaMyTeamUrl, scaMyOrgDefaultUrl } from 'components/sca/sca-breadcrumbs';
import { CacheContext } from 'contexts/cache-context';
import { HistoryPageTitle } from 'components/sca/sca-constants';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import ViewScaProgramDetailsActionButton from 'components/sca/sca-common/sca-view-sca-program-details-action-button';
import MailtoScaSecurityOfficerActionButton from 'components/sca/sca-common/mailto-sca-security-officer-action-button';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';

export type ScaHistoryValidTabsType = {
    myOrg?: boolean;
    myTeam?: boolean;
    myHistory?: boolean;
    employeeHistory?: boolean;
};

type IScaViewEmployeeHistoryProps = {
    employee: IEmployee | undefined;
    hasTabs?: ScaHistoryValidTabsType;
    defaultTab?: HistoryPageValidTabs;
    shouldHideReviewComments: boolean;
    shouldHideEmployeeSearchBox: boolean;
};

export default function ScaViewEmployeeHistory(props: IScaViewEmployeeHistoryProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const cacheContext = useContext(CacheContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const employeeImage = useDetermineImage({
        authContext: authContext,
        employee: props.employee,
        cacheContext: cacheContext,
    });

    const [redirectTo, setRedirectTo] = useState<string>();

    useEffect(() => {
        if (redirectTo) {
            setRedirectTo('');
        }
    }, [redirectTo]);

    const onCandidateSelectedHandler = (info?: IPersonaProps): void => {
        const employee = JSON.parse(info?.itemProp || '{}');
        setRedirectTo(employeeHistoryUrl(employee.id));
    };

    const onTabChange = (itemKey?: string): void => {
        switch (itemKey) {
            case HistoryPageValidTabs.EmployeeHistory:
                props.employee?.id && setRedirectTo(employeeHistoryUrl(props.employee.id));
                break;
            case HistoryPageValidTabs.MyHistory:
                setRedirectTo(scaMyHistoryUrl());
                break;
            case HistoryPageValidTabs.MyTeam:
                setRedirectTo(scaMyTeamUrl());
                break;
            case HistoryPageValidTabs.MyOrg:
                setRedirectTo(scaMyOrgDefaultUrl());
                break;
            default:
                break;
        }
    };

    if (redirectTo) {
        return <Redirect push={true} to={redirectTo} />;
    } else {
        return (
            // SCA Auth
            <CheckRole
                requiredRolesAny={[Role.SCAAdmin, Role.SCAExec]}
                hasRequiredRolesAny={[userContext.isScaManager, userContext.isScaMember]}
                arePermissionsChecked={userContext.isScaStatusChecked}
                redirectNotInRole={AccessDeniedURL}>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                <div>
                    <Tabs selectedKey={props.defaultTab} onChange={onTabChange}>
                        {(props.hasTabs?.myHistory || props.hasTabs?.employeeHistory) && (
                            <TabbedContent
                                itemKey={
                                    props.hasTabs?.myHistory
                                        ? HistoryPageValidTabs.MyHistory
                                        : HistoryPageValidTabs.EmployeeHistory
                                }
                                tabHeader={
                                    props.hasTabs?.myHistory
                                        ? HistoryPageTitle.MyHistory
                                        : HistoryPageTitle.EmployeeHistory
                                }>
                                <Spacer marginTop={10} />
                                <SidebarAndContents>
                                    <SidebarPane sidebarPaneOuterStyle={{ width: '380px' }}>
                                        {!props.shouldHideEmployeeSearchBox && (
                                            <>
                                                <CheckRole
                                                    requiredRolesAny={[Role.SCAAdmin, Role.SCAExec]}
                                                    hasRequiredRolesAny={[userContext.isScaManager]}
                                                    arePermissionsChecked={
                                                        userContext.isScaStatusChecked
                                                    }>
                                                    <Separator
                                                        styles={globalSeparatorStyles}
                                                        alignContent='start'>
                                                        {'Search'}
                                                    </Separator>
                                                    <EmployeePickerTypeaheadSearch
                                                        ariaLabel='Search'
                                                        placeHolder='Employee Name or Alias'
                                                        onCandidateSelected={
                                                            onCandidateSelectedHandler
                                                        }
                                                    />
                                                    <br />
                                                </CheckRole>
                                            </>
                                        )}
                                        {props.hasTabs?.employeeHistory && (
                                            <div className={globalStyles.boxShadow}>
                                                <EmployeeBasicHoverCard
                                                    key={props.employee?.id ?? 'employee-card'}
                                                    personnelId={props.employee?.id}
                                                    noHoverCard={true}
                                                    employeeCardStyle={{
                                                        card: { minWidth: 0 },
                                                        cardActions: { marginTop: 15 },
                                                    }}
                                                />
                                            </div>
                                        )}
                                        <Spacer marginTop={10} />
                                        <Separator
                                            styles={globalSeparatorStyles}
                                            alignContent='start'>
                                            {'Information'}
                                        </Separator>
                                        <ViewScaProgramDetailsActionButton />
                                        <Spacer marginTop={10} />
                                        <MailtoScaSecurityOfficerActionButton />
                                    </SidebarPane>
                                    <ContentPane
                                        contentPaneOuterStyle={{ width: 'calc(100% - 390px)' }}>
                                        <EmployeeHistoryTable
                                            employee={props.employee}
                                            shouldFetchAllHistory={true}
                                            shouldHideReviewComments={
                                                props.shouldHideReviewComments
                                            }
                                        />
                                    </ContentPane>
                                </SidebarAndContents>
                            </TabbedContent>
                        )}
                        {props.hasTabs?.myTeam &&
                            // SCA Auth
                            userContext.isScaManager && (
                                <TabbedContent tabHeader='My Team' itemKey='my-team'>
                                    <ScaViewMyTeamFilterWrapper />
                                </TabbedContent>
                            )}
                        {props.hasTabs?.myOrg &&
                            // SCA Auth
                            authContext.isInRole(Role.SCAExec) && (
                                <TabbedContent tabHeader='My Org' itemKey='my-org'>
                                    <ScaViewMyOrgFilterWrapper />
                                </TabbedContent>
                            )}
                    </Tabs>
                </div>
            </CheckRole>
        );
    }
}
