import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import SecureWorkAreaDropdownSelector from 'components/facilities/common/secure-work-area-dropdown-selector';
import {
    DatePicker,
    TimePicker,
    ITimeRange,
    List,
    ActionButton,
    MessageBarType,
    Stack,
    Separator,
    Dropdown,
    IDropdownOption,
    mergeStyleSets,
    getFocusStyle,
    getTheme,
    ITheme,
    IComboBox,
    FocusZone,
    FocusZoneDirection,
} from '@fluentui/react';
import ActivitiesClient, { IActivityReadRequest } from 'clients/activities-client';
import useMessageBar from 'components/common/use-message-bar';
import FacilitiesClient, {
    FacilityActivityName,
    IFacilityRecord,
    IFacilityUserFeedbackStats,
    ReservationActivityName,
} from 'clients/facilities-client';
import { FacilityUserType } from 'utils/facilities-utils';
import { removeUnderscoreAndCapitalize } from 'utils/string-utils';
import { useFetchSimple, useIsMounted } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';
import { getDate } from 'utils/time-utils';
import { globalFilterSeparatorStyles } from 'assets/styles/global-styles';
import Spacer from 'components/common/spacer';
import HorizontalBar, { horizontalBarTitleStyle } from 'components/common/horizontal-bar';
import { IconNames } from 'assets/constants/global-constants';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import { CheckScrollReachedBottom } from 'components/common/scroll-event-listener';
import BarChart, { ChartItem } from 'components/common/charts/bar-chart';

const eventMapping = new Map<string, string>([
    ['facility_created', 'Facilities Created'],
    ['facility_updated', 'Facilities Updated'],
    ['facility_deleted', 'Facilities Deleted'],
    ['reservation_preclaimed', 'Reservations Preclaimed'],
    ['reservation_confirmed', 'Reservations Confirmed'],
    ['reservation_checked_in', 'Reservations Checked In'],
    ['reservation_checked_out', 'Reservations Checked Out'],
    ['reservation_missed', 'Reservations Missed'],
    ['reservation_cancelled', 'Reservations Cancelled'],
    ['reservation_seat_changed', 'Reservations with Seat Changes'],
]);

type MetricsResponse = {
    event: string;
    count: number;
};

export interface FacilitiesManagementMetricsProps {
    facilities: IFacilityRecord[] | undefined;
}

export default function FacilitiesManagementMetricsPage(
    props: FacilitiesManagementMetricsProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [facilities, setFacilities] = useState<IFacilityRecord[]>();
    const [selectedFacility, setSelectedFacility] = useState<IFacilityRecord>();
    const [eventTypes, setEventTypes] = useState<string[]>([]);
    const [fromDate, setFromDate] = useState<Date>(getDate().Today);
    const [toDate, setToDate] = useState<Date>(getDate().EndOfToday);
    const [isFetchingFacilities, setFetchingFacilities] = useState<boolean>(false);
    const [shouldFetchActivities, setShouldFetchActivities] = useState<boolean>(false);
    const [activitiesContinuationToken, setActivitiesContinuationToken] = useState<string>();
    const [metricsMap, setMetricsMap] = useState<MetricsResponse[]>([]);
    const [queriedUserFeedbackInfo, setQueriedUserFeedbackInfo] = useState<
        IFacilityUserFeedbackStats[]
    >([]);
    const [fromTime, setFromTime] = useState<Date>();
    const [toTime, setToTime] = useState<Date>();
    const [userFeedbackBarChartData, setuserFeedbackBarChartData] = useState<ChartItem[]>([]);

    const runningAverageDays = [7, 14, 30, 60, 90, 120, 180];

    const isMounted = useIsMounted();

    useEffect(() => {
        if (userContext.isFacilitiesTokenLoaded) {
            const hasAccess = isFacilityManager || isFacilityAdmin;
            if (hasAccess) getFacilities();
        }
    }, [userContext.isFacilitiesTokenLoaded]);

    useEffect(() => {
        if (facilities) onApplyFilters();
    }, [facilities]);

    useEffect(() => {
        setQueriedUserFeedbackInfo([]);
        setuserFeedbackBarChartData([]);
        getTrailingAverages(selectedFacility?.facilityId ?? '');
    }, [selectedFacility]);

    const {
        theMessage: errorFetchingData,
        theElement: errorFetchingDataElement,
        setMessage: setErrorFetchingData,
    } = useMessageBar({
        type: MessageBarType.error,
    });

    async function getTrailingAverages(facilityId: string): Promise<void> {
        if (facilityId !== '') {
            runningAverageDays.forEach(async function (day) {
                const endTime = new Date().getTime();
                const startTime = endTime - 86400000 * day;
                const resp = await FacilitiesClient.getFacilityUserFeedbackStats(
                    authContext,
                    userContext,
                    facilityId,
                    startTime,
                    endTime,
                );
                if (resp != null) {
                    let color = 'red';
                    if (resp.average >= 3.5) {
                        color = 'green';
                    } else if (resp.average >= 2.5) {
                        color = 'orange';
                    }
                    setuserFeedbackBarChartData((prev) => {
                        return prev.concat({
                            xAxis: `${day} Day Running Avg (n=${resp.total})`,
                            yAxis: Number(resp.average.toFixed(2)),
                            color: color,
                        });
                    });
                }
            });
        }
    }

    const facilityDropdownOptions = Object.values(FacilityActivityName).map((x) => {
        return { key: x.toString(), text: removeUnderscoreAndCapitalize(x.toString()) };
    });

    const reservationDropdownOptions = Object.values(ReservationActivityName).map((x) => {
        return { key: x.toString(), text: removeUnderscoreAndCapitalize(x.toString()) };
    });

    const dropdownOptions = facilityDropdownOptions.concat(reservationDropdownOptions);

    const isFacilityManager = userContext.hasFacilitiesUserType(FacilityUserType.ManagerRole);
    const isFacilityAdmin = userContext.hasFacilitiesUserType(FacilityUserType.AdminService);
    const { isFetching: isFetchingActivities } = useFetchSimple<MetricsResponse[]>({
        dependencies: [
            selectedFacility,
            shouldFetchActivities,
            activitiesContinuationToken,
            eventTypes,
            fromDate,
            toDate,
        ],
        canPerformFetch: !!selectedFacility && shouldFetchActivities,
        fetchFunc: async () => {
            setShouldFetchActivities(false);
            setErrorFetchingData(undefined);

            const facilitiesToken = await FacilitiesClient.getFacilitiesToken(authContext);
            if (facilitiesToken) {
                const activitiesToken = await FacilitiesClient.getFacilityActivityToken(
                    authContext,
                    facilitiesToken,
                );
                const userFeedback = await FacilitiesClient.getFacilityUserFeedbackStats(
                    authContext,
                    userContext,
                    selectedFacility?.facilityId ?? '',
                    fromDate?.getTime() ?? 0,
                    toDate?.getTime() ?? 0,
                );
                setQueriedUserFeedbackInfo([userFeedback]);

                eventTypes.forEach(async function (event) {
                    // get activities for selected facility
                    const activityRequest: IActivityReadRequest = {
                        events: [event],
                        referenceIds: selectedFacility ? [selectedFacility.id] : undefined,
                        startTimeUtc: fromDate.getTime(),
                        endTimeUtc: toDate.getTime(),
                    };

                    const activitiesResponse = await ActivitiesClient.getReservationMetricsCount(
                        authContext,
                        activitiesToken,
                        facilitiesToken,
                        activityRequest,
                    );

                    const metricsResponse: MetricsResponse = {
                        event,
                        count: activitiesResponse,
                    };

                    if (metricsResponse != null) {
                        setMetricsMap((prev) => {
                            return prev.concat(metricsResponse);
                        });
                    }
                });
                return metricsMap;
            }
            throw 'Failed to retrieve facilities token';
        },
        onSuccess: doNothing,
        onError: () => {
            setErrorFetchingData(
                'Failed to retrieve facilities token, activity token, or activities',
            );
        },
        onFinally: doNothing,
    });

    const onApplyFilters = (): void => {
        setActivitiesContinuationToken(undefined);
        setShouldFetchActivities(true);
        setMetricsMap([]);
    };

    const onClearFilters = (): void => {
        setEventTypes([]);
        setFromDate(getDate().Today);
        setToDate(getDate().EndOfToday);
        onApplyFilters();
    };

    const getFacilities = async (): Promise<void> => {
        try {
            setFetchingFacilities(true);

            let facilitiesVar = [];
            if (isFacilityAdmin) {
                facilitiesVar = await FacilitiesClient.getFacilityRecords(authContext, userContext);
            } else if (isFacilityManager) {
                facilitiesVar = await FacilitiesClient.getFacilityRecordsManager(
                    authContext,
                    userContext,
                );
            } else {
                setErrorFetchingData("User doesn't have permission to retrieve facilities");
                return;
            }

            if (isMounted()) {
                setFacilities(facilitiesVar);
                setSelectedFacility(facilitiesVar.length > 0 ? facilitiesVar[0] : undefined);
            }
        } catch (error) {
            setErrorFetchingData('Failed to retrieve facilities');
        } finally {
            setFetchingFacilities(false);
        }
    };

    function onScrollReachedBottom(): void {
        if (!!activitiesContinuationToken && !isFetchingActivities) {
            setShouldFetchActivities(true);
        }
    }

    function isFetching(): boolean {
        return isFetchingFacilities || isFetchingActivities;
    }

    function onEventTypeChange(
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): void {
        if (option && option.key) {
            const eventType = eventTypes.find((x) => x === option.key);
            if (eventType) {
                setEventTypes((prevEventTypes) => prevEventTypes.filter((x) => x !== option.key));
            } else {
                setEventTypes((prevEventTypes) => {
                    const newEventTypes = [...prevEventTypes];
                    newEventTypes.push(option.key.toString());
                    return newEventTypes;
                });
            }
        }
    }

    async function onFacilityChange(
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): Promise<void> {
        if (option && option.key) {
            setSelectedFacility(facilities?.find((x) => x.id === option.key));
            onApplyFilters();
        }
    }

    return (
        <>
            {!!errorFetchingData && errorFetchingDataElement()}
            <SidebarAndContents>
                <SidebarPane>
                    <SecureWorkAreaDropdownSelector
                        onChange={onFacilityChange}
                        placeholder='Select a facility'
                        selectedKey={selectedFacility?.id}
                        options={facilities?.map((x) => {
                            return {
                                key: x.id,
                                text: x.facilityName,
                            };
                        })}
                        separatorStyles={globalFilterSeparatorStyles}
                    />
                    <Spacer marginTop={5} />
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        Start Date
                    </Separator>
                    <DatePicker
                        placeholder='Select from date'
                        allowTextInput={true}
                        value={fromDate}
                        onSelectDate={(newDate?: Date | null): void => {
                            if (newDate && newDate.getTime() <= toDate.getTime()) {
                                if (fromTime) {
                                    newDate.setHours(
                                        fromTime.getHours(),
                                        fromTime.getMinutes(),
                                        fromTime.getSeconds(),
                                    );
                                } else {
                                    newDate.setHours(0, 0, 0, 0);
                                }
                                setFromDate(newDate);
                            }
                        }}
                        ariaLabel='Start Date'
                    />
                    <Spacer marginTop={5} />
                    <TimePicker
                        label={'Start Time'}
                        showSeconds={false}
                        useHour12={true}
                        allowFreeform={true}
                        autoComplete='on'
                        useComboBoxAsMenuWidth
                        timeRange={timeRange}
                        defaultValue={new Date(0, 0, 0, 0)}
                        onChange={(e: React.FormEvent<IComboBox>, newTime?: Date | null): void => {
                            if (newTime) {
                                newTime.setHours(
                                    newTime.getHours(),
                                    newTime.getMinutes(),
                                    newTime.getSeconds(),
                                );
                                newTime.setDate(fromDate.getDate());
                                newTime.setMonth(fromDate.getMonth());
                                newTime.setFullYear(fromDate.getFullYear());
                                setFromDate(newTime);
                                setFromTime(newTime);
                            }
                        }}
                    />
                    <Spacer marginTop={5} />
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        End Date
                    </Separator>
                    <DatePicker
                        placeholder='Select to date'
                        allowTextInput={true}
                        value={toDate}
                        onSelectDate={(newDate?: Date | null): void => {
                            if (newDate && newDate.getTime() >= fromDate.getTime()) {
                                if (toTime) {
                                    newDate.setHours(
                                        toTime.getHours(),
                                        toTime.getMinutes(),
                                        toTime.getSeconds(),
                                    );
                                } else {
                                    newDate.setHours(23, 59, 59, 999);
                                }
                                setToDate(newDate);
                            }
                        }}
                        ariaLabel='End Date'
                    />
                    <Spacer marginTop={5} />
                    <TimePicker
                        label={'End Time'}
                        showSeconds={false}
                        useHour12={true}
                        allowFreeform={true}
                        autoComplete='on'
                        useComboBoxAsMenuWidth
                        timeRange={timeRange}
                        defaultValue={new Date(23, 59, 59, 999)}
                        onChange={(e: React.FormEvent<IComboBox>, newTime?: Date | null): void => {
                            if (newTime) {
                                newTime.setHours(
                                    newTime.getHours(),
                                    newTime.getMinutes(),
                                    newTime.getSeconds(),
                                );
                                newTime.setDate(toDate.getDate());
                                newTime.setMonth(toDate.getMonth());
                                newTime.setFullYear(toDate.getFullYear());
                                setToDate(newTime);
                                setToTime(newTime);
                            }
                        }}
                    />
                    <Spacer marginTop={5} />
                    <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                        Event Type
                    </Separator>
                    <Dropdown
                        onChange={onEventTypeChange}
                        options={dropdownOptions}
                        placeholder={'Select event types'}
                        multiSelect={true}
                        selectedKeys={eventTypes}
                        ariaLabel={'Event Type Dropdown'}
                    />
                    <Spacer marginTop={20} />
                    <Stack horizontal horizontalAlign='space-between'>
                        <Stack.Item>
                            <ActionButton
                                disabled={isFetching()}
                                iconProps={{ iconName: IconNames.Refresh }}
                                onClick={onApplyFilters}>
                                Apply Filters
                            </ActionButton>
                        </Stack.Item>
                        <Stack.Item>
                            <ActionButton
                                disabled={isFetching()}
                                iconProps={{ iconName: IconNames.ClearFilter }}
                                onClick={onClearFilters}>
                                Clear filters
                            </ActionButton>
                        </Stack.Item>
                    </Stack>
                </SidebarPane>
                <ContentPane>
                    <HorizontalBar>
                        <Stack.Item className={horizontalBarTitleStyle} grow={100}>
                            User Satisfaction Rating
                        </Stack.Item>
                    </HorizontalBar>
                    <BarChart
                        hideLegend
                        barChartData={userFeedbackBarChartData.sort((a, b) =>
                            a.xAxis.localeCompare(b.xAxis, 'en', { numeric: true }),
                        )}
                        displayDataLabels
                        yAxisProps={{ height: 5 }}
                    />
                    <List
                        items={queriedUserFeedbackInfo}
                        onRenderCell={onRenderQueriedUserFeedbackInfo}
                    />
                    <HorizontalBar>
                        <Stack.Item className={horizontalBarTitleStyle} grow={100}>
                            Facility Metrics
                        </Stack.Item>
                    </HorizontalBar>
                    {metricsMap && (
                        <FocusZone direction={FocusZoneDirection.vertical}>
                            <div className={styles.container} data-is-scrollable>
                                <List
                                    items={metricsMap.sort((a, b) =>
                                        a.event > b.event ? 1 : a.event < b.event ? -1 : 0,
                                    )}
                                    onRenderCell={onRenderCell}
                                />
                            </div>
                        </FocusZone>
                    )}
                    <IsLoadingIndicator
                        isLoading={isFetchingActivities}
                        before={<Spacer marginTop={20} />}
                        msg='Loading metrics...'
                    />
                    <CheckScrollReachedBottom
                        shouldCheck={!!activitiesContinuationToken}
                        onScrollReachedBottom={onScrollReachedBottom}
                    />
                </ContentPane>
            </SidebarAndContents>
        </>
    );
}

const onRenderCell = (response: MetricsResponse | undefined): React.ReactElement => {
    if (response != null) {
        const formattedEvent = eventMapping.get(response.event);
        return (
            <div className={styles.itemCell} data-is-focusable={true}>
                <div className={styles.itemContent}>
                    <div className={styles.itemName}>{`${formattedEvent}: ${response.count}`}</div>
                </div>
            </div>
        );
    }
    return <div></div>;
};

const onRenderQueriedUserFeedbackInfo = (
    response: IFacilityUserFeedbackStats | undefined,
): React.ReactElement => {
    if (response !== null) {
        return (
            <div className={styles.itemCell} data-is-focusable={true}>
                <div className={styles.itemContent}>
                    <div
                        className={
                            styles.itemName
                        }>{`Queried Running Avg: ${response?.average.toFixed(2)} (n=${
                        response?.total
                    })`}</div>
                </div>
            </div>
        );
    }
    return <div></div>;
};

const timeRange: ITimeRange = {
    start: 0,
    end: 23,
};

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const styles = mergeStyleSets({
    eventLine: {
        height: '1px',
        marginTop: '10px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        flexGrow: 1,
    },
    eventDateRow: {
        marginBottom: '10px',
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'row',
    },
    eventRowGap: {
        width: '10px',
    },
    itemContent: {
        marginLeft: 10,
        overflow: 'hidden',
        flexGrow: 1,
    },
    container: {
        overflow: 'auto',
        minHeight: 400,
        maxHeight: 500,
    },
    itemCell: [
        getFocusStyle(theme, { inset: -1 }),
        {
            minHeight: 54,
            padding: 10,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
                '&:hover': { background: palette.neutralLight },
            },
        },
    ],
    itemName: [
        fonts.xLarge,
        {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    ],
    itemIndex: {
        fontSize: fonts.small.fontSize,
        color: palette.neutralTertiary,
        marginBottom: 10,
    },
});
