import React from 'react';
import { Spinner, mergeStyleSets } from '@fluentui/react';

interface ICommonSpinnerProps {
    label?: string;
    ariaLive?: 'assertive' | 'polite' | 'off' | undefined;
    labelPosition?: 'top' | 'right' | 'bottom' | 'left' | undefined;
}

export default function CommonSpinner(props: ICommonSpinnerProps): JSX.Element {
    return (
        <Spinner
            label={props.label}
            styles={styles}
            ariaLive={props.ariaLive}
            labelPosition={props.labelPosition}
        />
    );
}

const styles = mergeStyleSets({
    root: {
        height: 100,
    },
});
