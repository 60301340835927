import React, { useContext, useEffect, useState } from 'react';
import { ActionButton, mergeStyleSets, TextField } from '@fluentui/react';
import { contentMaxHeight } from 'assets/styles/list-styles';
import { IEmployeeEditableResponse, IEmployeeWithEditableData } from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import ActivitiesClient, {
    IActivity,
    IActivityPost,
    IActivityValue,
} from 'clients/activities-client';
import Event, { ActivityEventBin, binSortEvents } from 'components/common/event';
import { dateToLocalLongDateFormat } from 'utils/time-utils';
import { UserContext } from 'contexts/user-context';
import { isGUID } from 'utils/string-utils';
import { ScreeningPaths } from 'components/screening/common/common-constants';

export interface PersonnelProfileNotesProps {
    employee: IEmployeeWithEditableData | undefined;
    editableEmployee?: IEmployeeEditableResponse;
    deletionNote: string;
    uploadNote: string;
    documentIdWorkaround: string;
}

export default function PersonnelProfileNotes(props: PersonnelProfileNotesProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [profileNotes, setProfileNotes] = useState<IActivity[]>();
    const [notesInput, setNotesInput] = useState<string>('');
    const [profileEventBins, setProfileEventBins] = useState<ActivityEventBin[]>();
    const [isRefreshActivitiesLocked, setRefreshActivitiesLocked] = useState<boolean>(false);
    const [refreshActivityCallDelayMs, setRefreshActivityCallDelayMs] = useState<number>(0);
    const REFRESH_ACTIVITY_DELAY_TIME_AFTER_INIT_MS = 1000;
    const activityEventName = 'noted';
    const activityTag = 'converted';

    useEffect(() => {
        async function getProfileEventData(): Promise<void> {
            if (props.employee?.data?.id) {
                const activitiesPromises: Promise<IActivity[]>[] = [];
                let preHireActivitiesProfile: Promise<number> = Promise.resolve(0);
                let preHireConvertedActivitiesProfile: Promise<number> = Promise.resolve(0);

                // if employee is prehire we don't want the notes to appear multiple times via getAllProfileActivitiesForProfileId being called twice
                if (
                    props.editableEmployee &&
                    props.editableEmployee.id &&
                    props.editableEmployee.id !== props.employee.data?.id
                ) {
                    preHireActivitiesProfile = ActivitiesClient.getProfileActivityToken(
                        authContext,
                        props.editableEmployee.id,
                    ).then((token) =>
                        activitiesPromises.push(
                            ActivitiesClient.getAllProfileActivitiesForProfileId(
                                authContext,
                                props.editableEmployee!.id,
                                token,
                            ),
                        ),
                    );
                }

                // this case for edge scenario when a converted FTE record is unconverted.  This will get all
                // activities notes with tags 'converted' and its editable employee id, so we know that
                // these notes are written after a prehire record was converted
                if (isGUID(props.employee.data?.id)) {
                    preHireConvertedActivitiesProfile = ActivitiesClient.getProfileActivityToken(
                        authContext,
                        props.employee.data?.id,
                    ).then((token) =>
                        activitiesPromises.push(
                            ActivitiesClient.getAllProfileActivitiesBasedOnEventsAndTags(
                                authContext,
                                props.employee!.data?.id,
                                [activityEventName],
                                [activityTag, props.employee!.data?.id],
                                token,
                            ),
                        ),
                    );
                }

                const activitiesProfile = ActivitiesClient.getProfileActivityToken(
                    authContext,
                    props.employee.data?.id,
                ).then((token) =>
                    activitiesPromises.push(
                        ActivitiesClient.getAllProfileActivitiesForProfileId(
                            authContext,
                            props.employee!.data?.id,
                            token,
                        ),
                    ),
                );

                await Promise.allSettled([
                    preHireActivitiesProfile,
                    preHireConvertedActivitiesProfile,
                    activitiesProfile,
                ]);
                await Promise.allSettled(activitiesPromises);

                const events: IActivity[] = [];
                for (let i = 0; i < activitiesPromises.length; i++) {
                    try {
                        const res = await activitiesPromises[i];
                        res.forEach((x) => events.push(x));
                    } catch (e) {
                        console.error('Error with getting profile activities from result', e);
                    }
                }
                setProfileNotes(events);
                const sortedBins = binSortEvents(events);
                setProfileEventBins(sortedBins);

                setRefreshActivitiesLocked(false);
            }
        }

        if (!isRefreshActivitiesLocked && props.employee && props.documentIdWorkaround === '') {
            setRefreshActivitiesLocked(true);
            setTimeout(() => {
                getProfileEventData();
            }, refreshActivityCallDelayMs);
            setRefreshActivityCallDelayMs(REFRESH_ACTIVITY_DELAY_TIME_AFTER_INIT_MS);
        }
    }, [props.employee, props.documentIdWorkaround]);

    useEffect(() => {
        function updateNotes(): void {
            if (props.deletionNote !== '') {
                submitNotes(props.deletionNote);
            }
        }
        updateNotes();
    }, [props.deletionNote]);

    useEffect(() => {
        function updateNotes(): void {
            if (props.uploadNote !== '') {
                submitNotes(props.uploadNote);
            }
        }
        updateNotes();
    }, [props.uploadNote]);

    function submitNotes(note: string): void {
        if (note) {
            const subjectData: IActivityValue = {
                type: 'personId',
                value: userContext.employeeRecord.id,
            };

            const directObjectData: IActivityValue = {
                type: 'personId',
                value: props.employee!.data?.id,
            };

            // make sure the activity event have the prehire record Id in the tags and securityIds field so if
            // an unconvert happens (splitting the converted prehire back to the original state prehire and FTE),
            // we could still find the notes using the prehire record Id and/or FTE
            const activityPostData: IActivityPost = {
                event: activityEventName,
                eventTimestampUTC: new Date().getTime(),
                referenceId: props.employee!.data?.id,
                securityIds:
                    props.editableEmployee &&
                    props.editableEmployee.id &&
                    props.employee!.data?.id !== props.editableEmployee.id
                        ? ['Admin', props.employee!.data?.id, props.editableEmployee.id]
                        : ['Admin', props.employee!.data?.id],
                subject: subjectData,
                directObject: directObjectData,
                message: note,
                tags:
                    props.editableEmployee &&
                    props.editableEmployee.id &&
                    props.employee!.data?.id !== props.editableEmployee.id
                        ? [activityTag, props.employee!.data?.id, props.editableEmployee.id]
                        : [props.employee!.data?.id],
            };

            if (props.employee?.data?.id) {
                ActivitiesClient.getProfileActivityToken(authContext, props.employee.data?.id, [
                    'write',
                ]).then(async (token) => {
                    const res = await ActivitiesClient.postProfileActivity(
                        authContext,
                        props.employee!.data?.id,
                        activityPostData,
                        token,
                    );

                    if (res) {
                        // clear textarea input after activity got posted
                        setNotesInput('');

                        // append to existing personnel profile notes and re-render with new notes
                        const newState = [...(profileNotes ?? []), res];
                        setProfileNotes(newState);
                        const sortedBins = binSortEvents(newState);
                        setProfileEventBins(sortedBins);
                    }
                });
            }
        }
    }

    function onNotesInputChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void {
        event.preventDefault();
        setNotesInput(newValue!);
    }

    return (
        <>
            {props.employee && (
                <div>
                    <span
                        className={styles.noteInputBox}
                        style={
                            profileNotes === undefined || profileNotes.length === 0
                                ? { padding: '0px' }
                                : {}
                        }>
                        <TextField
                            className={styles.startAtSecondColumn}
                            value={notesInput}
                            placeholder='Add a note'
                            onChange={onNotesInputChange}
                            multiline={true}
                            resizable={false}
                        />
                    </span>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ActionButton
                            styles={{ root: { maxHeight: contentMaxHeight } }}
                            title='Submit Notes'
                            iconProps={{ iconName: 'Send' }}
                            onClick={() => submitNotes(notesInput)}
                        />
                    </div>
                </div>
            )}
            {profileEventBins &&
                profileEventBins
                    .map(
                        (obj: ActivityEventBin): JSX.Element => {
                            return (
                                <div key={`activities_${obj.key}`}>
                                    <div
                                        key={`eventDay_${obj.key}`}
                                        className={styles.eventDateRow}>
                                        <div className={styles.eventLine}></div>
                                        <div className={styles.eventRowGap}></div>
                                        <div>{dateToLocalLongDateFormat(obj.key)}</div>
                                    </div>
                                    {obj.activities
                                        .map(
                                            (activity: IActivity): JSX.Element => (
                                                <Event
                                                    screeningPath={ScreeningPaths.UsGov}
                                                    key={`event_${activity.id}`}
                                                    activity={activity}
                                                    provideDocumentsNameForDocumentAppActivities={
                                                        false
                                                    }
                                                    screeningId={props.employee?.data?.id || ''}
                                                />
                                            ),
                                        )
                                        .reverse()}
                                </div>
                            );
                        },
                    )
                    .reverse()}
        </>
    );
}

const styles = mergeStyleSets({
    eventLine: {
        height: '1px',
        marginTop: '10px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        flexGrow: 1,
    },
    eventDateRow: {
        marginTop: '10px',
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'row',
    },
    eventRowGap: {
        width: '10px',
    },
    event: {
        marginTop: '1rem',
    },
    noteInputBox: {
        paddingTop: '10px',
        display: 'grid',
        gridTemplateColumns: '2rem auto',
    },
    startAtSecondColumn: {
        gridColumnStart: '2',
    },
});
