import React, { useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { IconNames } from 'assets/constants/global-constants';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import GroupClient from 'clients/group-client';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';

interface IDeletePolicyRuleModalButtonProps {
    ruleId: string;
    ruleName: string;
    deleteRuleFromList: (ruleId: string) => void;
    updatePolicyRulesTableAfterEdit?: () => void;
}

export default function DeletePolicyRuleModalButton(
    props: IDeletePolicyRuleModalButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);
    const onSubmit = async (): Promise<void> => {
        if (groupContext.group) {
            try {
                await GroupClient.removeGroupRule(authContext, groupContext.group.id, props.ruleId);
                if (props.updatePolicyRulesTableAfterEdit) {
                    props.updatePolicyRulesTableAfterEdit();
                }
            } catch (e) {
                throw e;
            }
        }
    };

    const onDeletePolicyRuleConcluded = (conclusion: ModalConclusion): void => {
        if (conclusion === ModalConclusion.Done) {
            props.deleteRuleFromList(props.ruleId);
        }
    };

    return (
        <ModalActionButton<void>
            text={'Delete'}
            enable={true}
            iconName={IconNames.Delete}
            modalTitle={'Delete Policy Rule'}
            enableSubmit={true}
            submitButtonText={'Delete'}
            onSubmit={onSubmit}
            onModalConcluded={onDeletePolicyRuleConcluded}>
            <span>
                Are you sure you want to delete the <b>{props.ruleName}</b> policy rule from the
                group?
            </span>
        </ModalActionButton>
    );
}
