import { jsonToCSV } from 'react-papaparse';
import { IClearanceWithDetailRecord } from 'clients/clearance-client';
import ReportsClient, {
    CloudReadinessReport,
    CloudReadinessReportKeyType,
    CustomerReportType,
    IAggregateReportRecord,
} from 'clients/reports-client';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/clearance/profile-clearance-constants';
import { IAuthContext } from 'contexts/auth-context';
import { initiateBrowserDownload } from 'utils/file-utils';
import { toTitleCase, transformHeaderToHeaderCase } from 'utils/string-utils';
import { TimeFormats, dateToLocalDate, getDateTimeForFilename } from 'utils/time-utils';
import { IChoiceGroupOption } from '@fluentui/react';

//Flatten the object provided.
// i.e. { x : 1, y { q:2, z:3 } } becomes { x: 1, 'y q': 2 , 'y z': 3 }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function flattenObject(obj: any): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toReturn: any = {};

    for (const i in obj) {
        if (!obj.hasOwnProperty(i)) continue;

        if (typeof obj[i] === 'object' && obj[i] !== null) {
            const flatObject = flattenObject(obj[i]);
            for (const x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[i + ' ' + x] = flatObject[x];
            }
        } else {
            toReturn[i] = obj[i];
        }
    }
    return toReturn;
}

export async function downloadReport(
    authContext: IAuthContext,
    reportType: CustomerReportType,
    downloadLink: HTMLAnchorElement | null,
    reportDate?: Date | null,
): Promise<void> {
    if (downloadLink) {
        let data;
        if (reportType === CustomerReportType.Prehire) {
            data = await ReportsClient.getPrehireReport(authContext);
        } else {
            const reportData = await ReportsClient.getCustomerReport(
                authContext,
                reportType,
                reportDate,
            );
            if (reportData?.results[0]) {
                switch (reportType) {
                    case CustomerReportType.Aggregate:
                        // This is done because the user should get dates in local time
                        // for the excel spreadsheets even though the reports store them as UTC Milliseconds.
                        data = convertStateDateUTCMillisecondsToStateDate(
                            reportData.results[0].aggregateReportRecords,
                        );
                        break;
                    case CustomerReportType.Count:
                        data = [flattenObject(reportData.results[0])];
                        break;
                }
            }
        }

        if (data && data.length > 0) {
            const csv: string = transformHeaderToHeaderCase(jsonToCSV(data));
            const report = new Blob([csv], { type: 'text/csv' });
            const fileName = `${reportType}_${getDateTimeForFilename(reportDate)}`;
            initiateBrowserDownload(report, fileName, 'csv', downloadLink);
        }
    }
}

export async function downloadCloudReadinessReport(
    authContext: IAuthContext,
    selectedReportOption: IChoiceGroupOption,
    downloadLink: HTMLAnchorElement | null,
): Promise<void> {
    if (downloadLink) {
        const csvData = await ReportsClient.getCloudReadinessReport(
            authContext,
            selectedReportOption,
        );

        if (csvData) {
            const filenamePrefix =
                CloudReadinessReport[selectedReportOption.key as CloudReadinessReportKeyType]
                    ?.filenamePrefix ?? selectedReportOption.key;
            const report = new Blob([csvData], { type: 'text/csv' });
            const fileName = `${filenamePrefix}_${getDateTimeForFilename(
                new Date(),
                TimeFormats.yyyyMMDD,
            )}`;
            initiateBrowserDownload(report, fileName, 'csv', downloadLink);
        }
    }
}

function convertStateDateUTCMillisecondsToStateDate(
    aggregateReportRecords: IAggregateReportRecord[],
): any[] {
    if (!aggregateReportRecords || aggregateReportRecords.length === 0) {
        return [];
    }

    const stateDateUTCMillisecondsKeys = Object.keys(aggregateReportRecords[0]).filter((x) =>
        x.includes('DateUTCMilliseconds'),
    );
    const recordModal = getAggregateReportRecordModal();

    const results: any[] = [];

    for (let i = 0; i < aggregateReportRecords.length; i++) {
        const record = copyAggregateReportRecordWithPopulatedNullableFields(
            aggregateReportRecords[i],
        );
        for (let j = 0; j < stateDateUTCMillisecondsKeys.length; j++) {
            const key = stateDateUTCMillisecondsKeys[j];
            // Older reports that don't have DateUTCMilliseconds fields will have values of zero
            if (record[`${key}`]) {
                const utcMilliseconds = record[`${key}`] as number;
                record[`${key.replace('UTCMilliseconds', '')}`] = dateToLocalDate(utcMilliseconds);
            }
            // remove this property regardless of whether its value is populated or not
            delete record[`${key}`];
        }
        // state dates are nullable fields on the back-end as they're no longer populated there for new reports so enforce the property ordering here
        results.push(JSON.parse(JSON.stringify(record, Object.keys(recordModal))));
    }

    return results;
}

export function formatClearanceReport(
    data: IClearanceWithDetailRecord[],
): { [key: string]: string }[] {
    const formattedData: { [key: string]: string }[] = [];

    // a clearance record could have N number of polygrahs so
    // first we sort the clearance data by the most polygraphs,
    // so during the CSV blob creation the clearance record with the most
    // data is used to create the CSV header info
    const sortedData = [...data].sort(function (a, b) {
        return b.polygraphs.length - a.polygraphs.length;
    });
    const maxPolygraphCount = sortedData[0].polygraphs.length;

    sortedData.forEach((clearance) => {
        // create a base lineItem that has a standard set of properties for a clearance report.
        // The empty special access and polygraph properties might get filled later based on
        // how many of each types are associated with the clearance record.
        const baseLineItem: { [key: string]: string } = {
            'clearanceLevel': clearance.level,
            'clearanceAgency': getAgencyEnumValueFromKey(clearance.agency),
            'clearanceStatus': clearance.status,
            'clearanceInvestigatedOn': dateToLocalDate(clearance.investigationDateUTCMilliseconds),
            'clearanceGrantedOn': dateToLocalDate(clearance.grantDateUTCMilliseconds),
            'clearanceBriefedOn': dateToLocalDate(clearance.briefDateUTCMilliseconds),
            'clearanceContract': clearance.contractId ?? '',
            'clearanceType': clearance.clearanceType ?? '',
            'clearanceBasis': clearance.clearanceBasis ?? '',
            'clearanceJpasUpdateIneligible': clearance?.jpasUpdateIneligible
                ? `${clearance.jpasUpdateIneligible}`
                : '',
            'clearanceNextDateBriefed': dateToLocalDate(clearance.nextBriefDateUTCMilliseconds),
            'clearanceSf312DateBriefed': dateToLocalDate(clearance.sF312BriefDateUTCMilliseconds),
            'clearanceDebriefDate': dateToLocalDate(clearance.debriefDateUTCMilliseconds),
            'clearanceDebriefJustification': clearance.debriefJustification ?? '',
            'continuousEvaluationType': clearance.continuousEvaluationType || '',
            'CEEnrollmentDate':
                dateToLocalDate(clearance.continuousEvaluationEnrollmentDateUTCMilliseconds) || '',
            'CEUnenrollmentDate':
                dateToLocalDate(clearance.continuousEvaluationUnenrollmentDateUTCMilliseconds) ||
                '',
            'specialAccess': '',
            'specialAccessDateBriefed': '',
            'specialAccessStatus': '',
            'specialAccessBilletType': '',
            'specialAccessFileNumber': '',
            'specialAccessPosition': '',
            'polygraphType': '',
            'polygraphTest': '',
            'polygraphCompletion': '',
            'polygraphResult': '',
        };

        // this is done to dynamically generate the polygraph CSV headers based on the max number of polygraph
        // in the user clearance records.
        if (clearance.polygraphs.length > 0) {
            if (maxPolygraphCount === 1) {
                baseLineItem['polygraphType'] = clearance.polygraphs[0].polygraphType;
                baseLineItem['polygraphTest'] = dateToLocalDate(
                    clearance.polygraphs[0].testDateUTCMilliseconds,
                );
                baseLineItem['polygraphCompletion'] = dateToLocalDate(
                    clearance.polygraphs[0].completionDateUTCMilliseconds,
                );
                baseLineItem['polygraphResult'] = clearance.polygraphs[0].results ?? '';
            } else if (maxPolygraphCount > 1) {
                delete baseLineItem['polygraphType'];
                delete baseLineItem['polygraphTest'];
                delete baseLineItem['polygraphCompletion'];
                delete baseLineItem['polygraphResult'];
                clearance.polygraphs.forEach((polygraph, index) => {
                    baseLineItem['polygraph' + (index + 1) + 'Type'] = polygraph.polygraphType;
                    baseLineItem['polygraph' + (index + 1) + 'Test'] = dateToLocalDate(
                        polygraph.testDateUTCMilliseconds,
                    );
                    baseLineItem['polygraph' + (index + 1) + 'Completion'] = dateToLocalDate(
                        polygraph.completionDateUTCMilliseconds,
                    );
                    baseLineItem['polygraph' + (index + 1) + 'Result'] = toTitleCase(
                        polygraph.results ?? '',
                    );
                });
            }
        }

        // the requirement is to have each special access as its own line item
        clearance.specialAccesses.forEach((specialAccess) => {
            const clonedBaseLineItem: { [key: string]: string } = {
                ...baseLineItem,
                'specialAccess': specialAccess.specialAccess,
                'specialAccessDateBriefed': dateToLocalDate(specialAccess.briefDateUTCMilliseconds),
                'specialAccessStatus': specialAccess.status,
                'specialAccessBilletType': specialAccess.billetType ?? '',
                'specialAccessFileNumber': specialAccess.fileNumber ?? '',
                'specialAccessPosition': specialAccess.position ?? '',
            };
            formattedData.push(clonedBaseLineItem);
        });

        if (clearance.specialAccesses.length === 0) {
            formattedData.push(baseLineItem);
        }
    });

    return formattedData;
}

function copyAggregateReportRecordWithPopulatedNullableFields(
    aggregateReportRecord: IAggregateReportRecord,
): any {
    const result = { ...aggregateReportRecord };

    result.nominationDate = result.nominationDate ? result.nominationDate : '';
    result.preparationDate = result.preparationDate ? result.preparationDate : '';
    result.paperworkRequestedDate = result.paperworkRequestedDate
        ? result.paperworkRequestedDate
        : '';
    result.paperworkCompleteDate = result.paperworkCompleteDate ? result.paperworkCompleteDate : '';
    result.submittedDate = result.submittedDate ? result.submittedDate : '';
    result.adjudicatedDate = result.adjudicatedDate ? result.adjudicatedDate : '';
    result.indocStatusDate = result.indocStatusDate ? result.indocStatusDate : '';
    result.completedDate = result.completedDate ? result.completedDate : '';
    result.withdrawnDate = result.withdrawnDate ? result.withdrawnDate : '';

    return result;
}

function getAggregateReportRecordModal(): IAggregateReportRecord {
    return {
        personnelId: '',
        personnelAlias: '',
        personnelLastName: '',
        personnelFirstName: '',
        personnelMiddleName: '',
        reportsTo: '',
        clearanceLevel: '',
        requestType: '',
        contractId: '',
        projectName: '',
        contractCustomer: '',
        nominatedBy: '',
        requestedDcoumentsCount: 0,
        uploadedDocumentsCount: 0,
        updateRequestedDocumentsCount: 0,
        isPendingDocumentRequest: false,
        isPendingDocumentUpdate: false,
        lockedDocumentsCount: 0,
        areAllDocumentsLocked: false,
        screeningId: '',
        processOwnerAlias: '',
        currentMainStatus: '',
        currentSubStatus: '',
        adjudicationResult: '',
        nominationDate: '',
        preparationDate: '',
        paperworkRequestedDate: '',
        paperworkCompleteDate: '',
        submittedDate: '',
        adjudicatedDate: '',
        indocStatusDate: '',
        completedDate: '',
        withdrawnDate: '',
        nominationDateUTCMilliseconds: 0,
        preparationDateUTCMilliseconds: 0,
        paperworkRequestedDateUTCMilliseconds: 0,
        paperworkCompleteDateUTCMilliseconds: 0,
        submittedDateUTCMilliseconds: 0,
        adjudicatedDateUTCMilliseconds: 0,
        indocStatusDateUTCMilliseconds: 0,
        completedDateUTCMilliseconds: 0,
        withdrawnDateUTCMilliseconds: 0,
        nominationDaysCount: 0,
        preparationDaysCount: 0,
        submittedDaysCount: 0,
        adjudicatedDaysCount: 0,
        indocAndBriefingDaysCount: 0,
        nomineeOfficeLocation: '',
        hierarchyLv1: '',
        hierarchyLv2: '',
        hierarchyLv3: '',
        hierarchyLv4: '',
        hierarchyLv5: '',
        hierarchyLv6: '',
        hierarchyLv7: '',
        url: '',
    };
}
