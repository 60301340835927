import React, { LegacyRef } from 'react';
import { Role } from 'configs/roles';
import { useContext, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'contexts/auth-context';

interface ICheckRole {
    /**
     * User is In Role:
     *     It shows props.children.
     *
     * User is not In Role
     *     arePermissionsChecked = false
     *         It waits, doesn't deny access, nor does it show props.children.
     *     arePermissionsChecked = true
     *         Ok, it can deny access now. It either redirects
     *         to props.redirectNotInRole or shows props.notInRoleContent.
     *         redirectNotInRole takes precedence over notInRoleContent.
     */
    arePermissionsChecked?: boolean; // Default true. Read below.
    /**
     * URL to which CheckRole should redirect if user is not in role.
     *
     * CAUTION
     *
     * If you want to use redirect, be sure to do the following:
     *   . If you don't need to call an API endpoint to determine permissions,
     *     in other words, if permissions are at your disposal when rendering
     *     your component, leave the prop arePermissionsChecked unconnected.
     *     Otherwise, there will be a delay from the time this module is
     *     first rendered till the time permissions are known. So, be sure
     *     to do the following.
     *   . Create a variable to drive the prop arePermissionsChecked (above).
     *   . Initialize it with false.
     *   . ONLY AFTER you check the permissions and determine whether the
     *     user has or doesn't have access, set the variable to true.
     *   . Drive the prop arePermissionsChecked with that variable.
     *
     * This all is to prevent redirection until permissions are checked
     * because if the module redirects, and later it's determined the
     * user did, indeed, have access, this module has already redirected
     * to the access denied page and doesn't go back, and we've blocked
     * a legitimate user from accessing a page.
     *
     * Even if you don't want to use redirect, it's better to drive this
     * prop with a variable as described above. That would prevent temporarily
     * showing "access denied" if user is permitted to see the contents.
     */
    redirectNotInRole?: string;
    notInRoleContent?: JSX.Element | ReactNode | JSX.Element[] | ReactNode[];
    children?: JSX.Element | ReactNode | JSX.Element[] | ReactNode[];
}

interface IAnyOfTheseRoles extends ICheckRole {
    requiredRolesAny: Role[]; // In-Role only if user is in any of these roles
    hasRequiredRolesAny?: boolean[]; // OR any of the items in this array is true
}
/**
 * @deprecated This component will be replaced by the CheckAccess component.
 * TODO: Replace CheckRole with CheckAccess.
 * https://msazure.visualstudio.com/DefaultCollection/Microsoft%20Personnel/_workitems/edit/13253518
 */
const CheckRole = React.forwardRef(
    (
        props: IAnyOfTheseRoles,
        // "ref" will become a ref to the top-most <div> of this component.
        ref: LegacyRef<HTMLDivElement> | undefined,
    ): JSX.Element => {
        const authContext = useContext(AuthContext);

        const isInRole =
            props.requiredRolesAny?.some((role) => authContext.isInRole(role)) ||
            props.hasRequiredRolesAny?.some((role) => role);

        const determineContent = (): JSX.Element => {
            if (isInRole) {
                return <>{props.children}</>;
            } else if (props.arePermissionsChecked ?? true) {
                if (props.redirectNotInRole) return <Redirect to={props.redirectNotInRole} />;
                else if (props.notInRoleContent) return <>{props.notInRoleContent}</>;
                // Show nothing. This is suitable for hiding parts of the page.
                // Example: Hiding action buttons for users who don't have
                // permission to edit.
                else return <></>;
            } else {
                // Stay silent and just show nothing until permissions are checked
                return <></>;
            }
        };

        return <div ref={ref}>{determineContent()}</div>;
    },
);

export default CheckRole;
