import React, { useContext, useEffect, ReactNode, useState, useRef } from 'react';
import { CustomBreadcrumb } from 'components/common/bread-crumb';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { ScaProgramDetailsBreadcrumbs } from 'components/sca/sca-breadcrumbs';
import SidebarAndContents, {
    SidebarPane,
    ContentPane,
} from 'components/common/sidebar-and-contents';
import { globalStyles } from 'assets/styles/global-styles';
import { mergeStyles, INavLinkGroup, Nav, mergeStyleSets } from '@fluentui/react';
import CommonSeparator from 'components/common/common-separator';

export default function ScaProgramDetails(): JSX.Element {
    const navTabRef = useRef(null);

    const breadCrumbContext = useContext(BreadCrumbContext);
    const [selectedKey, setSelectedKey] = useState<string>('key1');

    useEffect(() => {
        const locationHash = window.location.hash;
        switch (locationHash) {
            case '#overview':
            case '#manager-responsibility':
            case '#payout-n-eligibility':
            case '#tiers-n-rates':
                setSelectedKey(locationHash);
                break;
            default:
                setSelectedKey('#overview');
                break;
        }
    }, [window.location.hash]);

    useEffect(() => {
        const navTab = (navTabRef.current as unknown) as HTMLElement;
        const boundingRect = navTab.getBoundingClientRect();
        const navTabInitY = boundingRect.y - navTab.offsetTop - 140;
        const onScroll = (): void => {
            const newY = Math.max(0, -navTabInitY + Math.max(0, window.scrollY - 140));
            navTab.style.top = newY.toString() + 'px';
        };
        window.addEventListener('scroll', onScroll);
        return (): void => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    const navLinkGroups: INavLinkGroup[] = [
        {
            links: [
                {
                    name: 'Program overview',
                    url: '/sca/program-details#overview',
                    key: '#overview',
                    selectedKey: selectedKey,
                },
                {
                    name: "Manager's responsibility",
                    url: '/sca/program-details#manager-responsibility',
                    key: '#manager-responsibility',
                    selectedKey: selectedKey,
                },
                {
                    name: 'Payout and eligibility',
                    url: '/sca/program-details#payout-n-eligibility',
                    key: '#payout-n-eligibility',
                    selectedKey: selectedKey,
                },
                {
                    name: 'Tiers and rates',
                    url: '/sca/program-details#tiers-n-rates',
                    key: '#tiers-n-rates',
                    selectedKey: selectedKey,
                },
            ],
        },
    ];

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs(ScaProgramDetailsBreadcrumbs);
    }, [breadCrumbContext]);

    const strong = (text: string): JSX.Element => (
        <strong className={globalStyles.boldFont}>{text}</strong>
    );

    return (
        <div id='top'>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <div>
                <SidebarAndContents>
                    <SidebarPane
                        sidebarPaneOuterStyle={{ flex: '0 1', width: '70vw' }}
                        sidebarPaneInnerStyles={{ padding: '10px 40px 40px 32px' }}>
                        <h1
                            className={mergeStyles(globalStyles.h1Font, globalStyles.boldFont, {
                                marginBottom: 10,
                            })}>
                            Security clearance allowance (SCA) program
                        </h1>
                        <CommonSeparator id='overview' text='Program overview' />
                        <p>
                            Microsoft has contracts with the United States Government that require
                            our employees to have appropriate clearances to access classified
                            information. There are three clearance levels that are required at
                            Microsoft: Top Secret, Secret, and Confidential. Along with possessing a
                            clearance, there are Sensitive Compartmented Information (SCI) programs
                            which require access briefings as well as Counterintelligence or
                            Fullscope Polygraphs.
                        </p>
                        <p>
                            The Security Clearance Allowance (SCA) Program participation is limited
                            to US employees with clearances that require a Single Scope Background
                            Check (T5), TS//SCI or TS//SCI with Polygraph (full scope or
                            counterintelligence). Participation will not be granted until all
                            clearance requirements and documentation have been satisfied within the
                            program timeline.
                        </p>
                        <p>
                            Learn more about the SCA program on&nbsp;
                            <a href='https://microsoft.sharepoint.com/sites/hrw/Pages/SecurityClearanceAllowanceHome.aspx'>
                                HRweb
                            </a>
                            :
                        </p>
                        <Ul>
                            <li className={styles.liIndent}>
                                <a href='https://msit.microsoftstream.com/video/83e90840-98dc-b561-7e1a-f1ebe1a61738'>
                                    Education session video
                                </a>
                                &nbsp;- Review the SCA program overview education session video
                            </li>
                            <li className={styles.liIndent}>
                                <a href='https://microsoft.sharepoint.com/sites/hrw/DocLibrary/HRweb/SecurityClearanceAllowance/SCAEducationSession.pptx'>
                                    Education session deck
                                </a>
                                &nbsp;- Review the SCA program overview education presentation
                            </li>
                            <li className={styles.liIndent}>
                                <a href='https://microsoft.sharepoint.com/sites/hrw/DocLibrary/HRweb/SecurityClearanceAllowance/SCAOverviewandFAQs.pdf'>
                                    Program overview and FAQs
                                </a>
                                &nbsp;- Learn more about the SCA program and find answers to
                                frequently asked questions
                            </li>
                        </Ul>
                        {backToTop()}
                        <CommonSeparator
                            id='manager-responsibility'
                            text="Manager's responsibility"
                        />
                        <Ul>
                            <li className={styles.liIndent}>
                                The manager of each employee verifies an employee&apos;s eligibility
                                based on their clearance level (TS or higher). If an employee has no
                                intention to come back to a TS or higher engagement, it is the
                                responsibility of the manager to inform NST to debrief or lower the
                                access to the appropriate level.
                            </li>
                            <li className={styles.liIndent}>
                                The employee must have a completed, adjudicated, approved, active
                                clearance (regardless of the type or level) that is held by
                                Microsoft and validated by a security officer for each eligible
                                quarter.
                                <Ul>
                                    <li className={styles.liIndent}>
                                        All security clearances must be final, not interim, and held
                                        by Microsoft prior to the eligibility cutoff date to be
                                        eligible for that quarter&apos;s compensation.
                                    </li>
                                </Ul>
                            </li>
                            <li className={styles.liIndent}>
                                Managers are required to validate that an employee is utilizing
                                their clearance in support of a cleared effort or contact.
                                <Ul>
                                    <li className={styles.liIndent}>
                                        Each manager is required to enter the following missing
                                        items in the statement below:
                                        <br />
                                        “In accordance with section 2-200 of the National Industrial
                                        Security Program Operating Manual (NISPOM), I, _____, attest
                                        that my employee, _____, has a requirement to possess for a
                                        personnel clearance level in the performance of tasks or
                                        services related to the fulfillment of a classified
                                        contract.”
                                    </li>
                                    <li className={styles.liIndent}>
                                        Once managers have validated the usage of their
                                        employees&apos; clearance, they will acknowledge the SCA
                                        policies and validate the information they entered is
                                        correct.
                                    </li>
                                </Ul>
                            </li>
                        </Ul>
                        {backToTop()}
                        <CommonSeparator id='payout-n-eligibility' text='Payout and eligibility' />
                        <Ul>
                            <li className={styles.liIndent}>
                                Payout amounts are calculated based upon the clearance level
                                confirmed by the Microsoft National Security team based on their
                                database&nbsp;
                                {strong('as of the end of the second month of the quarter')}.
                            </li>
                            <br />
                            <li className={styles.liIndent}>
                                Employees {strong('without manager attestation')} completed by the
                                quarterly deadline will {strong('not')} be considered for the
                                quarterly payout.
                            </li>
                            <br />
                            <li className={styles.liIndent}>
                                Payouts are quarterly and use the&nbsp;
                                {strong(
                                    'quarterly rate multiplied by the annual salary at the time of the quarterly calculation',
                                )}
                                &nbsp;as posted in the HR systems.
                            </li>
                        </Ul>
                        <Table>
                            <thead>
                                <tr>
                                    <Th> Fiscal Quarters </Th>
                                    <Th> Period </Th>
                                    <Th> Eligibility Cut-Off </Th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <Td> Q1 </Td>
                                    <Td> Jul 1 - Sep 30 </Td>
                                    <Td> Aug 31 </Td>
                                </tr>
                                <tr>
                                    <Td> Q2 </Td>
                                    <Td> Oct 1 – Dec 31 </Td>
                                    <Td> Nov 30 </Td>
                                </tr>
                                <tr>
                                    <Td> Q3 </Td>
                                    <Td> Jan 1 – Mar 31 </Td>
                                    <Td> Feb 28/29 </Td>
                                </tr>
                                <tr>
                                    <Td> Q4 </Td>
                                    <Td> Apr 1 – Jun 30 </Td>
                                    <Td> May 31 </Td>
                                </tr>
                            </tbody>
                        </Table>
                        {backToTop()}
                        <CommonSeparator id='tiers-n-rates' text='Tiers and rates' />
                        <Table>
                            <thead>
                                <tr>
                                    <Th> SCA Level </Th>
                                    <Th> Type of Clearance </Th>
                                    <Th> Annual Rate (% of Annual Salary) </Th>
                                    <Th> Quarterly Rate (% of Annual Salary) </Th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <Td> Tier 1 </Td>
                                    <Td> Top Secret </Td>
                                    <Td> 15% </Td>
                                    <Td> 3.75% </Td>
                                </tr>
                                <tr>
                                    <Td> Tier 2 </Td>
                                    <Td> Top Secret w/ SCI </Td>
                                    <Td> 20% </Td>
                                    <Td> 5.00% </Td>
                                </tr>
                                <tr>
                                    <Td> Tier 3 </Td>
                                    <Td> Top Secret w/ SCI (CI/FSP) </Td>
                                    <Td> 25% </Td>
                                    <Td> 6.25% </Td>
                                </tr>
                            </tbody>
                        </Table>
                        {backToTop()}
                    </SidebarPane>
                    <ContentPane>
                        <div ref={navTabRef} className={styles.navTab}>
                            <CommonSeparator text='Jump to'></CommonSeparator>
                            <Nav
                                selectedKey={selectedKey}
                                ariaLabel='Jump To'
                                groups={navLinkGroups}
                            />
                        </div>
                    </ContentPane>
                </SidebarAndContents>
            </div>
        </div>
    );
}

const tableBorderStyle = '1px solid grey';

const styles = mergeStyleSets({
    navTab: {
        position: 'absolute',
    },
    liIndent: {
        marginLeft: 20,
    },
    table: {
        width: '80%',
        border: tableBorderStyle,
        margin: 'auto',
        marginTop: 14,
        textAlign: 'center',
        borderCollapse: 'collapse',
    },
    td: {
        border: tableBorderStyle,
    },
    th: {
        border: tableBorderStyle,
    },
    backToTop: {
        display: 'block',
        marginBottom: 10,
    },
});

interface IUlProps {
    children: ReactNode | ReactNode[];
}

const Ul = (props: IUlProps): JSX.Element => {
    return <ul style={{ paddingLeft: 20 }}>{props.children}</ul>;
};

const Table = (props: IUlProps): JSX.Element => {
    return <table className={styles.table}>{props.children}</table>;
};

const Td = (props: IUlProps): JSX.Element => {
    return <td className={styles.td}>{props.children}</td>;
};

const Th = (props: IUlProps): JSX.Element => {
    return <th className={styles.th}>{props.children}</th>;
};

const backToTop = (): JSX.Element => (
    <a className={styles.backToTop} href='/sca/program-details#top'>
        Back to top
    </a>
);
