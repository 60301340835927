import React, { useEffect, useContext } from 'react';
import { Location } from 'history';
import UserAssignmentsWrapper from 'components/user-assignments/user-assignments-wrapper';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { userSearchBreadcrumbs } from 'components/user-assignments/breadcrumbs';
import { AssignmentsPageContentType } from 'components/user-assignments/user-assignments-constants';
import { Role } from 'configs/roles';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'contexts/auth-context';

interface IUserAssignmentsSearchProps {
    location: Location;
}

export default function UserAssignmentsSearch(props: IUserAssignmentsSearchProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([...userSearchBreadcrumbs()]);
    }, []);

    if (
        !authContext.isInRole(Role.PersonnelAssignmentRead) &&
        !authContext.isInRole(Role.VisitorRecordRead)
    ) {
        return <Redirect to='/profile/user/me' push={false} />;
    }

    // TODO: Consider replacing the props show* with a context
    return (
        <UserAssignmentsWrapper
            location={props.location}
            contentType={AssignmentsPageContentType.SearchBox}
            visitorIdOrAlias={undefined}
            //
            showSearchBox={
                authContext.isInRole(Role.PersonnelAssignmentRead) ||
                authContext.isInRole(Role.VisitorRecordRead)
            }
            //
            showEmployeeInfo={authContext.isInRole(Role.PersonnelAssignmentRead)}
            showViewOthersProfileButton={false}
            //
            showVisitorInfo={authContext.isInRole(Role.VisitorRecordRead)}
            showEditVisitorButton={false}
            //
            showAttributes={false}
            showAddAttributeButton={false}
            showDeleteAttributeButton={false}
            //
            showEligibilities={false}
            showAddEligibilityButton={false}
            showDeleteEligibilityButton={false}
            //
            showEquipment={false}
        />
    );
}
