import React from 'react';
import { IBasicEmployee, IEmployee, IEmployeeWithEditableData } from 'clients/employee-client';
import {
    FontSizes,
    FontWeights,
    mergeStyleSets,
    FontIcon,
    Persona,
    PersonaInitialsColor,
    PersonaPresence,
    PersonaSize,
    Separator,
} from '@fluentui/react';
import { getInitials } from 'components/common/employee/employee-utils';
import EmployeeCard from 'components/common/employee/employee-card';
import { AppConstants } from 'components/common/constants';
import { getImageUrlFromAlias } from 'utils/photo-utils';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { globalStyles } from 'assets/styles/global-styles';

export interface ConvertProfileStepperPageReviewProps {
    convertPerson: IEmployeeWithEditableData | undefined;
    convertManager: IBasicEmployee | undefined;
    employee: IEmployee | undefined;
    employeeManager: IBasicEmployee | undefined;
}

export default function ConvertProfileStepperPageReview(
    props: ConvertProfileStepperPageReviewProps,
): JSX.Element {
    return (
        <div className={styles.root}>
            <div className={styles.firstColumn}>
                <div className={styles.cardWrapper}>
                    <Separator alignContent='start' styles={separatorStyles}>
                        Pre-hire
                    </Separator>
                    <div className={styles.employeeCard}>
                        <EmployeeCard
                            employee={props.convertPerson?.data}
                            detailHeight={128} // closest height match for a prehire employee card to a FTE employee card
                            manager={`${
                                props.convertManager && props.convertManager.displayName
                                    ? props.convertManager.displayName
                                    : ''
                            }`}
                            managerAlias={`${
                                props.convertManager &&
                                props.convertManager.onPremisesSamAccountName
                                    ? props.convertManager.onPremisesSamAccountName
                                    : ''
                            }`}
                            pcn={
                                props.convertPerson?.data &&
                                props.convertPerson?.data.positionNumber > 0
                                    ? `${props.convertPerson?.data?.positionNumber}`
                                    : ''
                            }
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>PCN</div>
                        <div className={styles.value}>
                            {props.convertPerson?.data?.positionNumber
                                ? props.convertPerson?.data?.positionNumber
                                : ''}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Start Date</div>
                        <div className={styles.value}>
                            {props.convertPerson?.data?.startDate
                                ? new Date(props.convertPerson?.data?.startDate).toDateString()
                                : ''}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Job Title</div>
                        <div className={styles.value}>
                            {props.convertPerson?.data?.standardTitle}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Microsoft ID</div>
                        <div className={styles.value}>{props.convertPerson?.data?.microsoftId}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Hiring Manager</div>
                        <div className={styles.multiValue}>
                            {props.convertManager ? (
                                <>
                                    <Persona
                                        className={styles.icon}
                                        size={PersonaSize.size24}
                                        initialsColor={PersonaInitialsColor.purple}
                                        presence={PersonaPresence.none}
                                        hidePersonaDetails={true}
                                        imageInitials={getInitials(
                                            props.convertManager.displayName,
                                        )}
                                        key={props.convertManager.onPremisesSamAccountName}
                                        showInitialsUntilImageLoads={true}
                                        imageUrl={getImageUrlFromAlias(
                                            props.convertManager.onPremisesSamAccountName,
                                        )}
                                    />
                                    <EmployeeHoverCard personnelId={props.convertManager.id}>
                                        <span className={styles.name}>
                                            {props.convertManager.displayName}
                                        </span>
                                        {props.convertManager.onPremisesSamAccountName && (
                                            <span className={globalStyles.fontStylesAlias}>
                                                (
                                                {props.convertManager.onPremisesSamAccountName.toLowerCase()}
                                                )
                                            </span>
                                        )}
                                    </EmployeeHoverCard>
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.secondColumn}>
                <FontIcon iconName='DoubleDownArrow' className={styles.transitionIcon} />
            </div>
            <div className={styles.thirdColumn}>
                <div className={styles.cardWrapper}>
                    <Separator alignContent='start' styles={separatorStyles}>
                        Full time
                    </Separator>
                    <div className={styles.employeeCard}>
                        <EmployeeCard
                            employee={props.employee}
                            manager={`${
                                props.employeeManager && props.employeeManager.displayName
                                    ? props.employeeManager.displayName
                                    : ''
                            }`}
                            managerAlias={`${
                                props.employeeManager &&
                                props.employeeManager.onPremisesSamAccountName
                                    ? props.employeeManager.onPremisesSamAccountName
                                    : ''
                            }`}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>PCN</div>
                        <div className={styles.value}>{props.employee?.positionNumber}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Start Date</div>
                        <div className={styles.value}>
                            {props.employee?.startDate
                                ? new Date(props.employee.startDate).toDateString()
                                : ''}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Job Title</div>
                        <div className={styles.value}>{props.employee?.standardTitle}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Microsoft ID</div>
                        <div className={styles.value}>{props.employee?.id}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.firstColumnKey}>Hiring Manager</div>
                        {props.employeeManager ? (
                            <>
                                <Persona
                                    className={styles.icon}
                                    size={PersonaSize.size24}
                                    initialsColor={PersonaInitialsColor.purple}
                                    presence={PersonaPresence.none}
                                    hidePersonaDetails={true}
                                    imageInitials={getInitials(props.employeeManager.displayName)}
                                    key={props.employeeManager.onPremisesSamAccountName}
                                    showInitialsUntilImageLoads={true}
                                    imageUrl={getImageUrlFromAlias(
                                        props.employeeManager.onPremisesSamAccountName,
                                    )}
                                />
                                <EmployeeHoverCard personnelId={props.employeeManager.id}>
                                    <span className={styles.name}>
                                        {props.employeeManager.displayName}
                                    </span>
                                    {props.employeeManager.onPremisesSamAccountName && (
                                        <span className={globalStyles.fontStylesAlias}>
                                            (
                                            {props.employeeManager.onPremisesSamAccountName.toLowerCase()}
                                            )
                                        </span>
                                    )}
                                </EmployeeHoverCard>
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const UNIFORM_WIDTH = 430;

const UNIFORM_RIGHT_MARGIN = 15;

const UNIFORM_BOTTOM_MARGIN = 15;

const styles = mergeStyleSets({
    root: {
        display: 'flex',
    },
    firstColumn: {
        display: 'flex',
        width: '445px',
    },
    secondColumn: {
        margin: '25px 10px 0px -5px',
    },
    thirdColumn: {
        display: 'flex',
        width: '445px',
    },
    cardWrapper: {
        marginRight: UNIFORM_RIGHT_MARGIN,
        marginBottom: UNIFORM_BOTTOM_MARGIN,
        width: UNIFORM_WIDTH,
    },
    row: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '4px 0',
    },
    firstColumnKey: {
        width: 150,
        fontWeight: '500',
    },
    secondColumnKey: {
        width: 190,
        fontWeight: '500',
    },
    value: {
        width: 'calc(100% - 140px)',
    },
    multiValue: {
        display: 'flex',
        width: 'calc(100% - 140px)',
    },
    icon: {
        fontSize: '1rem',
        marginRight: '7px',
    },
    name: {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.medium,
        marginRight: '5px',
        borderRadius: 2,
    },
    employeeCard: {
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
        marginBottom: AppConstants.margin,
    },
    transitionIcon: {
        margin: '0px 7px 0px 7px',
        transform: 'rotate(0.75turn)',
    },
});

const separatorStyles = mergeStyleSets({
    root: {
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                opacity: 0.9,
            },
        },
    },
    content: {
        paddingLeft: 0,
        fontWeight: '500',
    },
});
