import React from 'react';
import { LogicalOperatorType } from 'clients/group-client';
import { DefaultButton, IButtonStyles } from '@fluentui/react';

export interface IAndOrToggleProps {
    selected: LogicalOperatorType;

    onChange: (val: LogicalOperatorType) => void;
}

export default function AndOrToggle(props: IAndOrToggleProps): JSX.Element {
    return (
        <div style={containerStyles}>
            <DefaultButton
                text='And'
                onClick={(): void => props.onChange(LogicalOperatorType.And)}
                styles={getButtonStyles(props.selected === LogicalOperatorType.And)}
            />
            <DefaultButton
                text='Or'
                onClick={(): void => props.onChange(LogicalOperatorType.Or)}
                styles={getButtonStyles(props.selected === LogicalOperatorType.Or)}
            />
        </div>
    );
}

const getButtonStyles = (isSelected: boolean): IButtonStyles => {
    return {
        root: {
            width: 35,
            minWidth: 35,
            height: 25,
            minHeight: 25,
            padding: '3px 8px',
            borderRadius: 4,
            border: '1px solid var(--light-stroke-stroke-1, #D1D1D1)',
            marginRight: 5,
            marginBottom: 7,
            background: isSelected
                ? 'var(--light-background-background-1-selected, #EBEBEB)'
                : 'var(--light-background-background-1, #FFF);',
        },
        rootHovered: {
            backgroundColor: 'EBEBEB',
        },
        label: {
            color: 'var(--light-foreground-foreground-1-selected, #242424)',
            fontFamily: 'Segoe UI',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '16px',
        },
    };
};

const containerStyles = {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100%',
    marginLeft: 20,
};
