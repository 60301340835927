import { FontSizes, FontWeights, IStyleSet, Icon, mergeStyleSets } from '@fluentui/react';
import React, { useContext, useMemo } from 'react';
import casualOffice from 'assets/img/CLO17_casualOffice_002.jpg';
import theGherkin from 'assets/img/CLO18_theGherkin_001.jpg';
import manLookingAtPhone from 'assets/img/M365CO19_ENT_Dell3390_014.jpg';
import manDrawingArchitecture from 'assets/img/MSC21_Getty_SMB_1210923941.jpg';
import productsAzure2 from 'assets/img/asset-collections-products-azure2.jpg';
import whiteboard from 'assets/img/WCO18_whiteboard_0021.jpg';
import msftCampus1 from 'assets/img/asset-collections-popular-msftcampus1.jpg';
import msftCampus2 from 'assets/img/msftcampus2.jpg';
import countryCanada1 from 'assets/img/asset-collections-country-canada1.jpg';

import { Link } from 'react-router-dom';
import { useFeatureFlag, FeatureFlagKeys } from 'utils/use-feature-flags';
import { PrincipalUserContext } from 'contexts/principal-user-context';

interface IHomePageCard {
    imgSrc: string;
    link: string;
    title: string;
    subtitle: string;
}

function Home(): JSX.Element {
    const flags = {
        formsCore: useFeatureFlag(FeatureFlagKeys.formsCore).enabled,
        screeningCore: useFeatureFlag(FeatureFlagKeys.screeningCore).enabled,
        groupsCore: useFeatureFlag(FeatureFlagKeys.groupsCore).enabled,
        employeeCore: useFeatureFlag(FeatureFlagKeys.employeeCore).enabled,
        personnelCoreAttributes: useFeatureFlag(FeatureFlagKeys.personnelCoreAttributes).enabled,
    };

    const principalUserContext = useContext(PrincipalUserContext);

    const homePageCards = useMemo((): IHomePageCard[] => {
        const cards: (false | IHomePageCard)[] = [
            flags.employeeCore && {
                imgSrc: casualOffice,
                link: '/profile/user/me/info',
                title: 'Profile',
                subtitle: 'Understand your attributes and eligibilities',
            },
            flags.personnelCoreAttributes && {
                imgSrc: productsAzure2,
                link: `/profile/attributes/user/${principalUserContext.principalRecord.id}`,
                title: 'My attributes',
                subtitle: 'View your assigned attributes',
            },
            flags.personnelCoreAttributes && {
                imgSrc: whiteboard,
                link: '/profile/attributes/user/search',
                title: 'User assignments',
                subtitle: 'Search users for attribute assignments',
            },
            flags.personnelCoreAttributes && {
                imgSrc: msftCampus1,
                link: '/profile/attributesets',
                title: 'Attributes',
                subtitle: 'Create & modify attribute sets & attributes',
            },
            flags.screeningCore && {
                imgSrc: theGherkin,
                link: '/screening/cloud/my-screenings',
                title: 'Cloud',
                subtitle: 'Review screening status',
            },
            flags.screeningCore && {
                imgSrc: manLookingAtPhone,
                link: '/screening/us-gov/my-screenings',
                title: 'US Gov',
                subtitle: 'Review screening status',
            },
            flags.formsCore && {
                imgSrc: manDrawingArchitecture,
                link: '/forms',
                title: 'Forms',
                subtitle: 'Ensure compliance with policies',
            },
            flags.groupsCore && {
                imgSrc: msftCampus2,
                link: '/groups',
                title: 'Groups',
                subtitle: 'View & manage group memberships & policies',
            },
            {
                imgSrc: countryCanada1,
                link: '/support',
                title: 'Support',
                subtitle: 'Find the right team or resources for help',
            },
        ];
        return cards.filter(Boolean) as IHomePageCard[];
    }, [
        flags.employeeCore,
        flags.formsCore,
        flags.groupsCore,
        flags.personnelCoreAttributes,
        flags.screeningCore,
        principalUserContext.principalRecord.id,
    ]);

    return (
        <div className={styles.cards}>
            {homePageCards.map((card) => (
                <Link className={styles.card} key={card.title} to={{ pathname: card.link }}>
                    <img className={styles.cardImage} src={card.imgSrc} aria-hidden='true' />
                    <div className={styles.cardTitle}>{card.title}</div>
                    <div>{card.subtitle}</div>
                </Link>
            ))}
        </div>
    );
}

export default Home;

const styles = mergeStyleSets({
    cards: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '42px',
        flexWrap: 'wrap',
        margin: '73px 66px',
    },
    card: {
        boxSizing: 'border-box',
        background: '#FFF',
        borderRadius: '4px',
        boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.15), 0px 3px 8px 0px rgba(0, 0, 0, 0.14)',
        color: '#323130',
        textDecoration: 'none',
        selectors: {
            '&:hover': {
                background: '#f5f5f5',
                boxShadow: '0 4px 8px #00000024, 0 0 2px #0000001f',
            },
        },
        width: '322px',
        height: '270px',
        alignItems: 'center',
        padding: '11px',
    },
    cardImage: {
        width: '300px',
        height: '152px',
    },
    cardTitle: {
        fontWeight: '700',
        fontSize: '16px',
        lineHeight: '28px',
        margin: '11px 0',
    },
});
