import { CommandBar, ICommandBarItemProps, TextField } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import React from 'react';
import { baseElementStyles, descriptionStyles, Section } from 'components/forms/forms-common';

type SectionElementProps = {
    section: Section;
    updateSection: (
        id: number,
        newValue: string | undefined,
        operation: 'label' | 'description',
    ) => void;
    deleteSection: (sectionId: number) => void;
};
function SectionElement(props: SectionElementProps): JSX.Element {
    const { section, updateSection, deleteSection } = props;

    const overflowItems: ICommandBarItemProps[] = [
        {
            key: 'delete',
            text: 'Delete section',
            onClick: () => deleteSection(section.id),
            iconProps: { iconName: IconNames.Delete },
        },
    ];

    if (section.id === 0) {
        return <></>;
    }
    return (
        <div className={baseElementStyles}>
            <div style={{ width: 'fit-content', marginLeft: 'auto' }}>
                <CommandBar items={[]} overflowItems={overflowItems} ariaLabel='Section actions' />
            </div>
            <TextField
                value={section.label}
                onChange={(ev, newValue): void => updateSection(section.id, newValue, 'label')}
                placeholder={`Section ${section.id + 1} title...`}
                underlined
            />
            <div className={descriptionStyles}>
                <TextField
                    value={section.description}
                    onChange={(ev, newValue): void =>
                        updateSection(section.id, newValue, 'description')
                    }
                    placeholder={`Section ${section.id + 1} description...`}
                    underlined
                    rows={1}
                    multiline
                    autoAdjustHeight
                />
            </div>
        </div>
    );
}

export default React.memo(SectionElement);
