import { IPersonaProps } from '@fluentui/react';
import React, { memo, useEffect, useState } from 'react';
import { ElementProps } from 'components/forms/element-viewer';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { Dictionary } from 'assets/constants/global-constants';

function PeoplePickerViewer(props: ElementProps): JSX.Element {
    const { element, updateFormResponse } = props;

    const [selectedItems, setSelectedItems] = useState<IPersonaProps[] | undefined>([]);

    useEffect(() => {
        const val = element.value as string[];
        const selectedValue: IPersonaProps[] | undefined =
            val !== undefined && val.length > 0
                ? val.map((str) => {
                      const split = str ? str.split('-') : [];
                      if (split.length === 4) {
                          const person: IPersonaProps = {
                              imageInitials: split[3],
                              text: split[0],
                              secondaryText: split[1],
                              tertiaryText: split[2],
                              showInitialsUntilImageLoads: true,
                          };
                          return person;
                      }
                      // support old logic where they stored the email instead
                      const person: IPersonaProps = {
                          imageInitials: str.slice(0, 2).toUpperCase(),
                          text: str,
                          secondaryText: str,
                          tertiaryText: str,
                          showInitialsUntilImageLoads: true,
                      };
                      return person;
                  })
                : undefined;
        setSelectedItems(selectedValue);
    }, []);

    const onCandidateSelected = (persona: IPersonaProps | undefined): void => {
        if (element.type === 'people') {
            return;
        }
        setSelectedItems(persona ? [persona] : []);
        if (persona === undefined) {
            updateFormResponse(element, []);
        } else {
            updateFormResponse(element, [
                `${persona.text}-${persona.secondaryText}-${persona.tertiaryText}-${persona.imageInitials}` ??
                    '',
            ]);
        }
    };

    const onMultipleCandidatesSelected = (personas: IPersonaProps[] | undefined): void => {
        // find unique options
        const result: Dictionary<IPersonaProps> = {};
        personas?.forEach((persona) => {
            const employee = persona.secondaryText;
            if (employee) {
                result[employee] = persona;
            }
        });
        const items = Object.values(result);
        setSelectedItems(items);
        if (items === undefined) {
            updateFormResponse(element, []);
        } else {
            updateFormResponse(
                element,
                items.map(
                    (item) =>
                        `${item.text}-${item.secondaryText}-${item.tertiaryText}-${item.imageInitials}` ??
                        '',
                ),
            );
        }
    };

    return (
        <EmployeePickerTypeaheadSearch
            ariaLabel='People Picker'
            required={element.required}
            placeHolder='Search Employees'
            itemLimit={element.type === 'person' ? 1 : -1}
            selectedItems={selectedItems}
            onCandidateSelected={onCandidateSelected}
            onMultipleCandidatesSelected={onMultipleCandidatesSelected}
        />
    );
}

export default memo(PeoplePickerViewer);
