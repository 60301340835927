import React, { useState } from 'react';
import {
    mergeStyleSets,
    Modal,
    ContextualMenu,
    CommandBarButton,
    Stack,
    // eslint-disable-next-line no-restricted-imports
    Link,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import { IScreeningTemplateRequestRecord } from 'clients/template-client';
import { dateToLocalDate } from 'utils/time-utils';
import { IBasicEmployee, IEmployee } from 'clients/employee-client';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import { StepIndicator } from 'components/screening/us-gov/candidates/nomination/screening-status-indicator';
import ScreeningOverviewModal from 'components/screening/us-gov/candidates/nomination/screening-overview';
import ScreeningChangeStatusModal from 'components/screening/us-gov/candidates/nomination/screening-change-status';
import ChangeProcessOwnerModal from 'components/screening/us-gov/candidates/nomination/change-process-owner';
import WithdrawCandidate from 'components/screening/us-gov/candidates/nomination/screening-withdraw-candidate';
import WithdrawApprove from 'components/screening/us-gov/candidates/nomination/screening-withdraw-approve';
import {
    getParentState,
    ScreeningPaths,
    StateName,
} from 'components/screening/common/common-constants';
import { containsNSTUser } from 'components/screening/us-gov/IScreening';
import { IPerson } from 'clients/graph-client';
import {
    ScreeningStatusLabels,
    IStep,
    transformStepIndicatorDataTimes,
    StepIndicatorData,
} from 'types/screening-step';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import DocumentRequesterFlag from 'components/screening/common/screening-document-feature-flag/document-requester-flag';

interface ManageCandidatePageHeaderProps {
    screeningId: string;
    screening: ICommonScreening;
    screeningPath: ScreeningPaths;
    employee?: IEmployee;
    isDataEditable: boolean;
    isDataSaving: boolean;
    onIsDataEditableChange(): Promise<void>;
    onScreeningStatusChange(
        parentState: string,
        childState?: string,
        adjudicatedDate?: number,
    ): Promise<void>;
    onProcessOwnerChange(processOwner: IBasicEmployee | undefined): Promise<void>;
    processOwner?: IPerson;
    processOwnerGroupMembers?: IPerson[];
    setScreeningUpdateStatus(status: string): void;
    updateDocumentInfo(requests: IScreeningTemplateRequestRecord[]): void;
    isDocumentRequestTitleUnique(title: string): boolean;
    onWithdrawCandidate(screening: ICommonScreening): Promise<void>;
    userTypes: string[];
    isCurrentUser: boolean;
}

export function ManageCandidatePageHeaderFlag(props: ManageCandidatePageHeaderProps): JSX.Element {
    const initialScreeningState = getParentState(props.screening.stateName).toUpperCase();
    const [showChangeProcessOwner, setShowChangeProcessOwner] = useState<boolean>(false);
    const [showDocumentsRequester, setShowDocumentsRequester] = useState<boolean>(false);
    const [showScreeningOverview, setShowScreeningOverview] = useState<boolean>(false);
    const [showScreeningChangeState, setShowScreeningChangeState] = useState<boolean>(false);
    const [screeningStepDataDisplay, setScreeningStepDataDisplay] = useState<IStep[]>(
        transformStepIndicatorDataTimes(StepIndicatorData, initialScreeningState),
    );
    //Will be used once withdrawn is added back
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isWithdrawCandidateModalOpen, setIsWithdrawCandidateModalOpen] = useState<boolean>(
        false,
    );
    //Will be used once withdrawn is added back
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [isWithdrawCandidateApproveModalOpen, setIsWithdrawCandidateApproveModalOpen] = useState<
        boolean
    >(false);

    const isWithdrawnRequestActive = false;

    function getEmployeeName(): string {
        if (props.employee) {
            return getDisplayNameOrDefault(props.employee, props.employee?.id);
        } else if (props.screeningPath === ScreeningPaths.UsGov && props.screening.preHire) {
            return `${props.screening.preHire.firstName} ${props.screening.preHire.lastName}`;
        } else {
            return 'unnamed';
        }
    }

    function isScreeningWithdrawnCandidate(): boolean {
        if (props.screeningPath === ScreeningPaths.UsGov) {
            return (
                props.screening.stateName !== StateName.Withdrawn &&
                !!props.screening.withdrawalReason
            );
        } else {
            return props.screening.stateName !== StateName.Withdrawn;
        }
    }

    function onSubmitScreeningChangeStatus(
        updatedStatus: string,
        childState?: string,
        adjudicatedDate?: number,
    ): void {
        setScreeningStepDataDisplay(
            transformStepIndicatorDataTimes(StepIndicatorData, updatedStatus),
        );
        props.onScreeningStatusChange(updatedStatus, childState, adjudicatedDate);

        setShowScreeningChangeState(false);
    }

    function onSubmitProcessOwnerChange(processOwner: IBasicEmployee | undefined): void {
        setShowChangeProcessOwner(false);
        props.onProcessOwnerChange(processOwner);
    }

    return (
        <>
            <div className={style.header}>
                <Stack horizontal className={style.stack}>
                    {!props.isCurrentUser && containsNSTUser(props.userTypes) && (
                        <>
                            <CommandBarButton
                                iconProps={{ iconName: 'Switch' }}
                                onClick={(): void => setShowScreeningChangeState(true)}
                                allowDisabledFocus>
                                Change Status
                            </CommandBarButton>
                            <CommandBarButton
                                iconProps={{ iconName: 'EditContact' }}
                                onClick={props.onIsDataEditableChange}
                                checked={props.isDataEditable}
                                disabled={props.isDataSaving}
                                allowDisabledFocus>
                                {props.isDataEditable
                                    ? props.isDataSaving
                                        ? 'Saving...'
                                        : 'Save'
                                    : 'Edit Data'}
                            </CommandBarButton>
                            <CommandBarButton
                                onClick={(): void => setShowDocumentsRequester(true)}
                                iconProps={{ iconName: 'FileRequest' }}
                                allowDisabledFocus>
                                Request Document
                            </CommandBarButton>
                            <CommandBarButton
                                onClick={(): void => setShowChangeProcessOwner(true)}
                                iconProps={{ iconName: 'FabricUserFolder' }}
                                allowDisabledFocus>
                                Change Process Owner
                            </CommandBarButton>
                        </>
                    )}
                    {!containsNSTUser(props.userTypes) && isWithdrawnRequestActive && (
                        <CommandBarButton
                            iconProps={{ iconName: 'Leave' }}
                            onClick={(): void => setIsWithdrawCandidateModalOpen(true)}
                            allowDisabledFocus>
                            Withdraw from Screening
                        </CommandBarButton>
                    )}
                </Stack>
                {initialScreeningState !== ScreeningStatusLabels.Withdrawn.label && (
                    <div>
                        <StepIndicator
                            steps={screeningStepDataDisplay}
                            onClick={(): void => setShowScreeningOverview(!showScreeningOverview)}
                        />
                        <div>
                            Process Owner:{' '}
                            {props.processOwner
                                ? `${props.processOwner.displayName} (${
                                      props.processOwner.userPrincipalName.split('@')[0]
                                  })`
                                : 'Unassigned'}
                        </div>
                    </div>
                )}
            </div>
            {props.screeningPath === ScreeningPaths.UsGov && (
                <div>
                    {!containsNSTUser(props.userTypes) &&
                        isWithdrawnRequestActive &&
                        isScreeningWithdrawnCandidate() && (
                            <MessageBar messageBarType={MessageBarType.warning}>
                                You requested to withdraw from screening on{' '}
                                {dateToLocalDate(props.screening.withdrawalUtcMillis)}. If you do
                                not wish to withdraw from screening, contact the National Security
                                Team.
                            </MessageBar>
                        )}
                    {containsNSTUser(props.userTypes) &&
                        isWithdrawnRequestActive &&
                        isScreeningWithdrawnCandidate() && (
                            <MessageBar messageBarType={MessageBarType.warning}>
                                {getEmployeeName()} requested to withdraw from screening on{' '}
                                {dateToLocalDate(props.screening.withdrawalUtcMillis)}.
                                <Link
                                    href='#'
                                    onClick={(): void =>
                                        setIsWithdrawCandidateApproveModalOpen(true)
                                    }>
                                    View Request Details
                                </Link>
                            </MessageBar>
                        )}
                </div>
            )}

            <Modal
                isOpen={showDocumentsRequester}
                onDismiss={(): void => setShowDocumentsRequester(false)}
                isDarkOverlay={false}
                isBlocking={true}
                containerClassName={style.modalContainer}
                dragOptions={{
                    moveMenuItemText: 'Move',
                    closeMenuItemText: 'Close',
                    menu: ContextualMenu,
                }}>
                <DocumentRequesterFlag
                    screeningId={props.screeningId}
                    nomineeId={props.screening.personnelId}
                    handleModalClose={(): void => setShowDocumentsRequester(false)}
                    updateDocumentInfo={props.updateDocumentInfo}
                    isDocumentRequestTitleUnique={props.isDocumentRequestTitleUnique}
                    screeningPaths={props.screeningPath}
                />
            </Modal>
            <Modal
                isOpen={showScreeningOverview}
                onDismiss={(): void => setShowScreeningOverview(false)}
                isDarkOverlay={false}
                isBlocking={true}
                containerClassName={style.modalContainer}>
                <ScreeningOverviewModal
                    screening={props.screening}
                    onClose={(): void => setShowScreeningOverview(false)}
                    screeningPaths={props.screeningPath}
                />
            </Modal>
            <Modal
                isOpen={showScreeningChangeState}
                onDismiss={(): void => setShowScreeningChangeState(false)}
                isDarkOverlay={false}
                isBlocking={true}
                containerClassName={style.modalContainer}>
                <ScreeningChangeStatusModal
                    screening={props.screening}
                    onClose={(): void => setShowScreeningChangeState(false)}
                    onSubmit={onSubmitScreeningChangeStatus}
                    screeningPaths={props.screeningPath}
                />
            </Modal>
            <Modal
                isOpen={showChangeProcessOwner}
                onDismiss={(): void => setShowChangeProcessOwner(false)}
                isDarkOverlay={false}
                isBlocking={true}
                containerClassName={style.modalContainer}>
                <ChangeProcessOwnerModal
                    groupMembers={props.processOwnerGroupMembers}
                    processOwner={props.processOwner}
                    onClose={(): void => setShowChangeProcessOwner(false)}
                    onSubmit={onSubmitProcessOwnerChange}
                />
            </Modal>
            <Modal
                // TODO: If this modal ever changed to be visible, it will need
                // its prop "onDismiss" to be driven for accessibility sake.
                isOpen={false}>
                <WithdrawCandidate
                    screening={props.screening}
                    onWithdrawCandidate={props.onWithdrawCandidate}
                    onClose={(): void => setIsWithdrawCandidateModalOpen(false)}
                />
            </Modal>
            <Modal
                // TODO: If this modal ever changed to be visible, it will need
                // its prop "onDismiss" to be driven for accessibility sake.
                isOpen={false}>
                <WithdrawApprove
                    screening={props.screening}
                    employee={props.employee}
                    onScreeningStatusChange={props.onScreeningStatusChange}
                    onWithdrawCandidate={props.onWithdrawCandidate}
                    onClose={(): void => setIsWithdrawCandidateApproveModalOpen(false)}
                    getEmployeeName={getEmployeeName}
                />
            </Modal>
        </>
    );
}

const style = mergeStyleSets({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    stack: {
        height: '2rem',
    },
    modalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
});
