import React, { useContext, useEffect, useState } from 'react';
import {
    mergeStyleSets,
    FontWeights,
    FontSizes,
    TextField,
    MaskedTextField,
    PersonaSize,
    Persona,
    PersonaPresence,
} from '@fluentui/react';
import ContainerWithEtiquettes, {
    IContainerWithEtiquettesProps,
} from 'components/common/container-with-etiquettes';
import { SharedColors } from '@fluentui/theme';
import { AppConstants } from 'components/common/constants';
import EmployeeClient, {
    IEmployee,
    IBasicEmployee,
    IEmployeeEditableResponse,
    defaultIEmployeeEditableResponse,
    AllEmployeeEditableFields,
    IEmployeeEditableRequest,
    SearchEmployeeStatus,
} from 'clients/employee-client';
import {
    dateToFormattedDateTimeString,
    unixTimeStampToFormattedDateString,
} from 'utils/time-utils';
import { setNestedKeyValueOfCloneObject } from 'utils/object-utils';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { getImageUrlFromAlias } from 'utils/photo-utils';
import { getEmployeeInitials } from 'components/common/employee/employee-utils';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { useIsMounted } from 'utils/misc-hooks';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';

interface AdditionalDataProps {
    screening: ICommonScreening;
    employee: IEmployee | undefined;
    manager: IBasicEmployee | undefined;
    nominator: IBasicEmployee | undefined;
    isDataEditable: boolean;
    onEditableFieldChange(screen: ICommonScreening): void;
    updateStatus?(status: string): void;
    screeningPath: ScreeningPaths;
}

const containerProps: IContainerWithEtiquettesProps = {
    leftEtiquetteLabel: 'Additional Data',
};

const homePhoneKey = 'contact.homePhone';
const homeEmailKey = 'contact.homeEmail';
const homeAddressKey = 'contact.homeAddress';
const contactKey = 'contact';
const errorStatus = 'error';
const billetName = 'billetNumber';
const mappingName = 'mapping';
const milestoneName = 'milestone';
const locationName = 'location';

function AdditionalData(props: AdditionalDataProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [screening, setScreening] = useState<ICommonScreening>(props.screening);
    const [editableEmployee, setEditableEmployee] = useState<IEmployeeEditableResponse | undefined>(
        undefined,
    );
    const [contractCustomer, setContractCustomer] = useState<string>();
    const [employeeHomePhone, setEmployeeHomePhone] = useState<string>('');
    const [employeeHomeEmail, setEmployeeHomeEmail] = useState<string>('');
    const [employeeHomeAddress, setEmployeeHomeAddress] = useState<string>('');
    const isMounted = useIsMounted();

    const [isEmployeeDataEdited, setIsEmployeeDataEdited] = useState<boolean>(false);

    useEffect(() => {
        async function loadPersonnelData(): Promise<void> {
            if (screening && screening.personnelId) {
                try {
                    const editableEmployeeResponse: IEmployeeEditableResponse = await EmployeeClient.getEditableEmployeeDataByIdOrAliasOrGUID(
                        authContext,
                        screening.personnelId,
                        Object.values(AllEmployeeEditableFields),
                    );
                    if (isMounted()) {
                        setEditableEmployee(editableEmployeeResponse);
                        setEmployeeHomePhone(
                            editableEmployeeResponse.employeeEditableInfo.homePhone ?? 'N/A',
                        );
                        setEmployeeHomeEmail(
                            editableEmployeeResponse.employeeEditableInfo.personalEmail ?? 'N/A',
                        );
                        setEmployeeHomeAddress(
                            editableEmployeeResponse.employeeEditableInfo.homeAddress ?? 'N/A',
                        );
                    }
                } catch (e) {
                    // TODO add error-message UseMessageBar post mvp
                    console.error('Error retrieving FTE employee or manager information', e);
                }
            }
            if (screening && screening.contractId) {
                try {
                    const contract = await UsGovScreeningClient.getContractsByContractIds(
                        authContext,
                        [screening.contractId],
                        true,
                    );
                    if (isMounted() && contract[0]) {
                        setContractCustomer(contract[0].customer);
                    }
                } catch (e) {
                    // TODO add error-message UseMessageBar post mvp
                    console.error('Error retrieving contract name', e);
                }
            }
        }
        loadPersonnelData();
    }, [screening]);

    function onTextInputChange(
        e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void {
        const target: HTMLInputElement = e.target as HTMLInputElement;
        if (typeof newValue === 'string' && target.name) {
            const nestedFields: string[] = target.name.split('.');

            let updatedScreening;
            if (target.name.includes(contactKey)) {
                const newEditableEmployee = editableEmployee || defaultIEmployeeEditableResponse;
                switch (target.name) {
                    case homePhoneKey:
                        setIsEmployeeDataEdited(true);
                        setEmployeeHomePhone(newValue);
                        break;
                    case homeEmailKey:
                        setIsEmployeeDataEdited(true);
                        setEmployeeHomeEmail(newValue);
                        break;
                    case homeAddressKey:
                        setIsEmployeeDataEdited(true);
                        setEmployeeHomeAddress(newValue);
                        break;
                    default:
                        break;
                }
                setEditableEmployee(newEditableEmployee);
            } else {
                updatedScreening = setNestedKeyValueOfCloneObject<ICommonScreening>(
                    screening,
                    newValue,
                    ...nestedFields,
                );
            }
            if (updatedScreening) {
                setScreening(updatedScreening);
                props.onEditableFieldChange(updatedScreening);
            }
        }
    }

    useEffect(() => {
        if (props.screening !== screening) {
            setScreening(props.screening);
        }
    }, [props.screening]);

    useEffect(() => {
        async function uploadEditableEmployeeData(): Promise<void> {
            try {
                if (!editableEmployee) {
                    return;
                }

                editableEmployee.employeeEditableInfo.homeAddress = employeeHomeAddress;
                editableEmployee.employeeEditableInfo.personalEmail = employeeHomeEmail;
                editableEmployee.employeeEditableInfo.homePhone = employeeHomePhone;
                const editableRequest: IEmployeeEditableRequest = {
                    id: editableEmployee.id ?? props.employee?.id ?? screening.personnelId,
                    alias: editableEmployee.alias ?? props.employee?.alias,
                    requestBy: userContext.employeeRecord.id,
                    employeeStatus: editableEmployee.employeeStatus ?? SearchEmployeeStatus.active,
                    employeeEditableInfo: editableEmployee.employeeEditableInfo,
                };
                const isDataUploaded = await EmployeeClient.updateEditableEmployeeData(
                    authContext,
                    editableRequest,
                );

                if (props.updateStatus) {
                    if (isDataUploaded) {
                        props.updateStatus('success');
                    } else {
                        props.updateStatus(errorStatus);
                    }
                }
            } catch (e) {
                if (props.updateStatus) {
                    props.updateStatus(errorStatus);
                }
                console.error('failed to get editable employee in additional data', e);
            }
        }

        if (!props.isDataEditable && isEmployeeDataEdited) {
            if (editableEmployee) {
                if (props.updateStatus) {
                    props.updateStatus('pending');
                }
                uploadEditableEmployeeData();
            }
        }
    }, [props.isDataEditable]);

    return (
        <ContainerWithEtiquettes {...containerProps}>
            <div className={styles.container}>
                <div className={styles.dlSection}>
                    <div className={styles.heading}>Contacts</div>
                    {props.employee && (
                        <>
                            <div className={styles.dataLine}>
                                <div className={styles.label}>Work Phone</div>
                                <div>
                                    {props.employee.businessPhones?.length
                                        ? props.employee.businessPhones[0]
                                        : 'None'}
                                </div>
                            </div>

                            <div className={styles.dataLine}>
                                <div className={styles.label}>Work Email</div>
                                <div>{props.employee.email}</div>
                            </div>
                        </>
                    )}

                    <div className={styles.dataLine}>
                        <div className={styles.label}>Home Phone</div>
                        <div>
                            {props.isDataEditable ? (
                                <MaskedTextField
                                    name={homePhoneKey}
                                    value={employeeHomePhone}
                                    mask='(999) 999 - 9999'
                                    onChange={onTextInputChange}
                                />
                            ) : (
                                employeeHomePhone
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.label}>Home Email</div>
                        <div>
                            {props.isDataEditable ? (
                                <TextField
                                    name={homeEmailKey}
                                    value={employeeHomeEmail}
                                    onChange={onTextInputChange}
                                />
                            ) : (
                                employeeHomeEmail
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.label}>Home Address</div>
                        <div>
                            {props.isDataEditable ? (
                                <TextField
                                    name={homeAddressKey}
                                    value={employeeHomeAddress}
                                    onChange={onTextInputChange}
                                />
                            ) : (
                                employeeHomeAddress
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.dlSection}>
                    <div className={styles.heading}>Business Data</div>

                    <div className={styles.dataLine}>
                        <div className={styles.label}>Billet Number</div>
                        <div>
                            {props.isDataEditable ? (
                                <TextField
                                    name={billetName}
                                    value={screening.billetNumber}
                                    onChange={onTextInputChange}
                                />
                            ) : (
                                screening.billetNumber
                            )}
                        </div>
                    </div>

                    <div className={styles.dataLine}>
                        <div className={styles.label}>Mapping</div>
                        <div>
                            {props.isDataEditable ? (
                                <TextField
                                    name={mappingName}
                                    value={screening.mapping}
                                    onChange={onTextInputChange}
                                />
                            ) : (
                                screening.mapping
                            )}
                        </div>
                    </div>

                    <div className={styles.dataLine}>
                        <div className={styles.label}>Milestone</div>
                        <div>
                            {props.isDataEditable ? (
                                <TextField
                                    name={milestoneName}
                                    value={screening.milestone}
                                    onChange={onTextInputChange}
                                />
                            ) : (
                                screening.milestone
                            )}
                        </div>
                    </div>

                    <div className={styles.dataLine}>
                        <div className={styles.label}>Location</div>
                        <div>
                            {props.isDataEditable ? (
                                <TextField
                                    name={locationName}
                                    value={screening.location}
                                    onChange={onTextInputChange}
                                />
                            ) : (
                                screening.location
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.dlSection}>
                    <div className={styles.heading}>Contract</div>
                    <div className={styles.dataLine}>
                        <div className={styles.label}>COTR Name</div>
                        <div>{contractCustomer}</div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={`${styles.label} ${styles.govContract}`}>
                            Government Contract
                        </div>
                        <div>{props.screening.contractId}</div>
                    </div>
                </div>
                <div className={styles.pSection}>
                    <div className={styles.heading}>Business Justification</div>
                    <div>
                        <div className={styles.justification}>
                            {props.nominator && (
                                <EmployeeHoverCard personnelId={props.nominator.id}>
                                    <div className={styles.justificationPersonaHeading}>
                                        <Persona
                                            imageInitials={getEmployeeInitials(props.nominator)}
                                            key={props.nominator.onPremisesSamAccountName}
                                            showInitialsUntilImageLoads={true}
                                            imageUrl={getImageUrlFromAlias(
                                                props.nominator.onPremisesSamAccountName,
                                            )}
                                            size={PersonaSize.size24}
                                            hidePersonaDetails={true}
                                            presence={PersonaPresence.none}
                                        />
                                        <div className={styles.topPadding}>
                                            <span className={styles.label}>
                                                &nbsp;{props.nominator.displayName}&nbsp;
                                            </span>
                                            <span className={styles.textMuted}>
                                                {`(${
                                                    props.nominator?.onPremisesSamAccountName
                                                        ? props.nominator.onPremisesSamAccountName.toLowerCase()
                                                        : ''
                                                })`}
                                                &nbsp;
                                                {props.screeningPath === ScreeningPaths.UsGov
                                                    ? unixTimeStampToFormattedDateString(
                                                          props.screening.nominatedAtUtc,
                                                      )
                                                    : dateToFormattedDateTimeString(
                                                          props.screening.nominatedAtUtc,
                                                      )}
                                            </span>
                                        </div>
                                    </div>
                                </EmployeeHoverCard>
                            )}

                            <div>{props.screening.businessJustification}</div>
                        </div>
                    </div>
                </div>
            </div>
        </ContainerWithEtiquettes>
    );
}
export default AdditionalData;

const checkboxStyles = {
    root: {
        fontSize: '1rem',
        color: SharedColors.cyanBlue10,
    },
};

const styles = mergeStyleSets({
    container: {
        fontSize: FontSizes.medium,
        padding: '10px 12px 5px 12px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(375px, 1fr))',
        gridGap: AppConstants.margin,
    },
    label: {
        fontWeight: FontWeights.semibold,
    },
    topPadding: {
        paddingTop: '4px',
    },
    dataLine: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        selectors: {
            '&:not(:last-child):not(:first-child)': {
                marginTop: 7,
                marginBottom: 7,
            },
        },
    },
    dlSection: {},
    pSection: {},
    heading: {
        gridColumnStart: 'span 2',
        borderBottom: '1px solid #f4f4f4',
    },
    justification: {
        marginTop: '1rem',
        padding: '7px 9px',
        boxShadow: 'rgba(0, 0, 0, 0.41) 0px 0px 3px -1px',
        borderRadius: '1.5px',
    },
    justificationPersonaHeading: {
        display: 'flex',
    },
    textMuted: {
        color: 'rgba(0, 0, 0, 0.536)',
    },
    govContract: {
        lineHeight: '1rem',
    },
});
