import React, { ReactNode } from 'react';
import { SharedColors, NeutralColors } from '@fluentui/theme';
import { mergeStyleSets, FontSizes, FontWeights, mergeStyles, FontIcon } from '@fluentui/react';

interface ModalCreationStepProps {
    stepTitle: string;
    iconName?: string;
    stepSubTitle?: string;
    stepWidth?: number;
    children?: ReactNode;
}
const MIN_WIDTH_STEP = 350;

const ModalCreationStep: React.FunctionComponent<ModalCreationStepProps> = ({
    stepTitle,
    iconName,
    stepSubTitle,
    stepWidth,
    children,
}) => {
    const bodyStyle = mergeStyles({
        backgroundColor: NeutralColors.white,
        width: stepWidth && stepWidth > MIN_WIDTH_STEP ? stepWidth : MIN_WIDTH_STEP,
    });

    return (
        <div className={bodyStyle}>
            <div className={stepStyles.label}>
                {iconName && <FontIcon iconName={iconName} className={stepStyles.icon} />}
                <span>{stepTitle}</span>
            </div>
            {stepSubTitle && stepSubTitle.trim() !== '' && (
                <div className={stepStyles.subTitleLabel}>{stepSubTitle}</div>
            )}
            <div className={stepStyles.step}>{children}</div>
        </div>
    );
};
const stepStyles = mergeStyleSets({
    label: {
        flex: '1 1 auto',
        borderTop: `4px solid ${SharedColors.cyanBlue10}`,
        display: 'flex',
        fontSize: FontSizes.xLargePlus,
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '30px 35px 10px 35px',
        boxSizing: 'border-box',
    },
    subTitleLabel: {
        fontSize: FontSizes.medium,
        padding: '0 35px',
        wordBreak: 'keep-all', //choose between this and overflowBreak
    },
    step: {
        padding: '10px 35px 20px 35px',
    },
    icon: {
        fontSize: '16px',
        marginRight: '8px',
    },
});
export default ModalCreationStep;
