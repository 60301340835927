import React, { useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link, mergeStyleSets, MessageBar } from '@fluentui/react';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import { BrowserCacheKeys } from 'utils/browser-cache-utils';

interface CandidatesPageHelpProps {
    screeningPath: ScreeningPaths;
}

export default function CandidatesPageHelp(props: CandidatesPageHelpProps): JSX.Element {
    const usGovUserGuideLink = `https://aka.ms/personnel/userguides/usgov`;
    const publicTrustUserGuideLink = `https://aka.ms/personnel/userguides/publictrust`;
    const helpLink =
        props.screeningPath === ScreeningPaths.UsGov
            ? usGovUserGuideLink
            : publicTrustUserGuideLink;

    const [shouldRender, setShouldRender] = useState<boolean>(
        localStorage.getItem(BrowserCacheKeys.usgovShouldShowClearanceAndScreeningHelpLink) !== '0',
    );

    const styles = mergeStyleSets({
        linkContainer: {
            marginBottom: '1em',
        },
    });

    const buttonText = 'Hide Help Link';

    const dismissHelpLink = () => {
        setShouldRender(false);
        localStorage.setItem(BrowserCacheKeys.usgovShouldShowClearanceAndScreeningHelpLink, '0');
    };

    return shouldRender ? (
        <MessageBar
            onDismiss={dismissHelpLink}
            dismissButtonAriaLabel={buttonText}
            className={styles.linkContainer}>
            <b>First time here?</b>
            <Link href={helpLink} target='_blank' rel='noopener noreferrer' underline>
                Check out our FAQs and user guide
            </Link>
        </MessageBar>
    ) : (
        <></>
    );
}
