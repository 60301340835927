import React from 'react';
import { useCheckMountedState } from 'utils/misc-hooks';
import { DatePicker } from '@fluentui/react';
import { IUseInputElement } from 'components/common/use-input/input-element-utils';

interface IDatepickerParams {
    // Ideally, we should keep the following props 100% the same
    // as the underlying Fluent component so that they can directly
    // drive its props.
    allowTextInput?: boolean;
    disabled?: boolean;
    label?: string;
    ariaLabel?: string;
}

export function useDatepicker(params: IDatepickerParams): IUseInputElement<Date> {
    const [date, setDate] = useCheckMountedState<Date | undefined>(undefined);

    const initialize = (initValue?: Date | undefined): void => {
        setDate(initValue);
    };

    return {
        value: date,
        initialize,
        theElement: (): JSX.Element => (
            <DatePicker
                onSelectDate={(newDate?: Date | null | undefined): void => {
                    if (newDate) {
                        setDate(newDate);
                    }
                }}
                label={params.label}
                value={date}
                allowTextInput={params.allowTextInput ?? true}
                disabled={params.disabled}
                ariaLabel={params.ariaLabel}
            />
        ),
    };
}
