import { useState } from 'react';
import { useReducerVar } from 'utils/misc-hooks';

/**
 * The React custom hook useSortColumnHandler implements the functionality
 * required to remember name of sort column and sort direction.
 * Suitable for sorting tables that are implemented using our
 * common component Table or Fluent UI component DetailsList.
 * Example usage:
 *     const [{ sortColumnName, sortScending }, sortColumnHandler] = useSortColumnHandler('employeeName', 1)
 * In the above example:
 *     - The initial sort column name is 'employeeName'
 *     - The initial sort direction is 1 (ascending), assuming the sort
 *       algorithm uses 1 for asending and -1 for decending.
 *     - sortColumnHandler is the handler for click event when user
 *       clicks on column header. It toggles sort direction or changes
 *       sort column name.
 */

const DefaultSortAscending = 1;
export const SortDescending = -1;

type SortSettingsType = {
    sortColumn: string;
    sortAscending: number; // Tech Debt: Bad name! Better be called sortDirection.
    clearSortColumn: () => void;
};

type SortColumnHandlerType = (newColumnName: string) => void;

type UseColumnHandlerType = [SortSettingsType, SortColumnHandlerType];

export function useSortColumnHandler(columnName: string, ascending?: number): UseColumnHandlerType {
    const initialAscending = ascending ?? DefaultSortAscending;
    const [sortColumn, setSortColumn] = useState(columnName);
    const [sortAscending, updateSortAscending] = useReducerVar(initialAscending);

    function sortColumnHandler(newColumnName: string): void {
        // Toggle between ascending and descending
        if (newColumnName !== sortColumn) {
            setSortColumn(newColumnName);
        } else {
            updateSortAscending(-1 * sortAscending);
        }
    }

    function clearSortColumn(): void {
        setSortColumn('');
    }

    return [{ sortColumn, sortAscending, clearSortColumn }, sortColumnHandler];
}

export const strCmp = (r1: string | undefined, r2: string | undefined): number =>
    r1?.toLocaleLowerCase()?.localeCompare(r2?.toLocaleLowerCase() ?? '') ?? 1;

export const numCmp = (r1: number | undefined, r2: number | undefined): number =>
    (r1 ?? 0) - (r2 ?? 0);

/**
 * Extendable interface which can be used to create sortable IColumn[].
 */
export interface ISortableColumns {
    sortColumn?: string;
    sortAscending: boolean;
    sortColumnHandler: (columnName: string) => void;
}
