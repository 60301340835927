import React from 'react';

// Possible custom css display types.
// Add more types as needed
const displayTypes = {
    inline: 'inline',
} as const;

interface ISpacerProps {
    // Note to developers:
    // Feel free to add other margin or padding props
    // if this module can be useful for your code.
    marginTop?: number;
    marginLeft?: number;
    display?: keyof typeof displayTypes;
}

export default function Spacer(props: ISpacerProps): JSX.Element {
    const style: ISpacerProps = {};
    if (props.marginTop) {
        style.marginTop = props.marginTop;
    }
    if (props.marginLeft) {
        style.marginLeft = props.marginLeft;
    }
    if (props.display) {
        style.display = props.display;
    }
    return <div style={style}></div>;
}
