/**
 * File Name: request-document-modal
 * Team: Personnel - Screening Team
 * Summary: This is the 'Request Document' button and modal in Screening Details page. Used in document-table.tsx.
 * The modal will open up Document Requester which will actually have the logic for requesting a document.
 * Use Case:
 * 1) allows NST team to request a Document from Screening's User
 * Last Updated Summary: 10/7/2022 by andreli
 **/
import React, { useState } from 'react';
import { ActionButton, Modal, ContextualMenu } from '@fluentui/react';
import { IScreeningTemplateRequestRecord } from 'clients/template-client';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import DocumentRequester from 'components/screening/common/documents/document-requester';
import { documentScreeningStyles } from 'components/screening/common/documents/document-styles';

interface RequestDocumentModalProps {
    screeningId: string;
    nomineeId: string;
    screeningPath: ScreeningPaths;
    updateDocumentInfo(requests: IScreeningTemplateRequestRecord[]): void;
    isDocumentRequestTitleUnique(title: string): boolean;
    documentTypesMap?: Map<string, string>;
}

export function RequestDocumentModal(props: RequestDocumentModalProps): JSX.Element {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            <ActionButton
                onClick={(): void => setIsOpen(true)}
                iconProps={{ iconName: 'FileRequest' }}>
                Request
            </ActionButton>
            <Modal
                isOpen={isOpen}
                onDismiss={(): void => setIsOpen(false)}
                isDarkOverlay={false}
                isBlocking={true}
                containerClassName={documentScreeningStyles.modalContainer}
                dragOptions={{
                    moveMenuItemText: 'Move',
                    closeMenuItemText: 'Close',
                    menu: ContextualMenu,
                }}>
                <DocumentRequester
                    screeningId={props.screeningId}
                    nomineeId={props.nomineeId}
                    handleModalClose={(): void => setIsOpen(false)}
                    updateDocumentInfo={props.updateDocumentInfo}
                    isDocumentRequestTitleUnique={props.isDocumentRequestTitleUnique}
                    screeningPaths={props.screeningPath}
                    documentTypesMap={props.documentTypesMap}
                />
            </Modal>
        </>
    );
}
