import React from 'react';

export function displayRequiredFieldsErrors(fields: string[]): JSX.Element {
    return (
        <>
            <div>Please correct the following error(s) and try again: </div>
            <ul>
                {fields.map((field, index) => {
                    return (
                        <li key={index}>
                            <span>{field} is required.</span>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}

export function displayErrorsWithHTML(messages: string[]): JSX.Element {
    return (
        <>
            <div>Correct the following error(s) and try again: </div>
            <ul>
                {messages.map((msg, index) => {
                    const dangerouslySetInnerHTML = {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: msg,
                    };

                    return (
                        <li key={index}>
                            <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
                        </li>
                    );
                })}
            </ul>
        </>
    );
}
