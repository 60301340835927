/**
 * File Name: document-styles
 * Team: Personnel - Screening Team
 * Summary: common styles. Note: future work will be needed to see what we need and what can be unified.
 * Last Updated Summary: 10/7/2022 by andreli
 **/
import { SharedColors } from '@fluentui/theme';
import { mergeStyleSets, FontSizes, FontWeights } from '@fluentui/react';

export const documentScreeningStyles = mergeStyleSets({
    root: {
        minWidth: '670px',
        boxSizing: 'border-box',
        selectors: {
            '@media(max-width: 450px)': {
                minWidth: '100%',
                maxWidth: '100%',
            },
        },
    },
    body: {
        backgroundColor: '#FFFFFF',
    },
    label: [
        FontSizes.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${SharedColors.cyanBlue10}`,
            display: 'flex',
            fontSize: '24px',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    sublabel: [
        FontSizes.small,
        {
            flex: '1 1 auto',
            display: 'flex',
            fontSize: '14px',
            alignItems: 'center',
            padding: '0px 0px 0px 24px',
        },
    ],
    content: {
        padding: '30px 24px 24px 24px',
        color: '#1b1b1b',
    },
    footer: {
        backgroundColor: '#FEFCFE',
        padding: '14px',
        borderTop: '1px solid #EAE9EA',
        display: 'flex',
        justifyContent: 'flex-end',
        selectors: {
            '& :not(:last-child)': {
                marginRight: '10px',
            },
        },
    },
    modalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    // single use manage content header
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    stack: {
        height: '2rem',
    },
    // end of single use manage content header
    // single use other document request
    error: {
        border: '1px solid red',
    },
    fileName: {
        marginLeft: '10px',
    },
    uploadButton: {
        position: 'relative',
        overflow: 'hidden',
        display: 'inline-block',
    },
    hiddenInput: {
        position: 'absolute',
        left: '0',
        top: '0',
        opacity: '0',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
    },
    // end of single use other document request

    stringContent: {
        color: 'rgb(50, 49, 48)',
        fontSize: 14,
    },
    columnPadding: {
        paddingTop: '11px',
    },
});

export const stylesForOtherDocuments = mergeStyleSets({
    row: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5,
        marginBottom: 5,
        selectors: {
            '@media(max-width: 450px)': {
                display: 'block',
            },
        },
    },
    leftColumn: {
        float: 'left',
        width: '30%',
        fontWeight: FontWeights.semibold,
        selectors: {
            '@media(max-width: 450px)': {
                width: '100%',
                marginBottom: '5px',
            },
        },
    },
    rightColumn: {
        width: '70%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        selectors: {
            '@media(max-width: 450px)': {
                clear: 'left',
                width: '100%',
            },
        },
    },
});
