import React from 'react';
import { ActionButton, TooltipHost } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';

interface IClearFiltersActionButtonProps {
    text?: string; // Default: "Clear filters"
    clearFunc: () => void;
}

export default function ClearFiltersActionButton(
    props: IClearFiltersActionButtonProps,
): JSX.Element {
    return (
        <TooltipHost content='Clear all current search and filter settings'>
            <ActionButton iconProps={{ iconName: IconNames.ClearFilter }} onClick={props.clearFunc}>
                <span>{props.text ?? 'Clear filters'}</span>
            </ActionButton>
        </TooltipHost>
    );
}
