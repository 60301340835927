export enum ModalMode {
    Add = 'add',
    Update = 'update',
}

export enum ViewEligibilityValidTabs {
    Details = 'details',
    Requests = 'requests',
    Timeline = 'timeline',
}
