import React, { useContext, useEffect, useState } from 'react';
import { getReportToFromHierarchy, hierarchyLvlsAlias, IHierarchy } from 'clients/reports-client';
import { Dictionary, noDataText } from 'assets/constants/global-constants';
import {
    IEmployeeNameAndType,
    PersonnelTypes,
} from 'components/common/employee/internal-employee-utils';
import { Separator, Stack } from '@fluentui/react';
import {
    filterMargin,
    filterMarginMarginTop10,
    separatorStyles,
    stackTokensChildSpace,
} from 'components/screening/common/filters/common-filter-styling';
import {
    ContractDropdownFilter,
    DateTimeStartEndPicker,
    EmployeePickerFilter,
    MultiChoiceFilter,
    OrgLeaderPicker,
    ReportsToPicker,
} from 'components/common/filters';
import ProcessOwnerDropdownFilter from 'components/screening/common/filters/processOwner-dropdown';
import AgencyDropdownFilter from 'components/screening/common/filters/agency-dropdown';
import {
    filterOnParentState,
    getFilterfunction,
    IsCheckboxChecked,
    isUserContractOwnerOrNST,
    labelFunction,
    updateFilterContext,
} from 'components/screening/common/filters/filter-help';
import { UserContext } from 'contexts/user-context';
import { filterContextKeys, FiltersContext } from 'contexts/filters-context';
import {
    employeeStatusStr,
    PublicTrustParentStateToChildrenMap,
    PublicTrustScreeningStateLabels,
    ScreeningParentStateType,
    ScreeningPaths,
    StateName,
} from 'components/screening/common/common-constants';
import { IMultiChoiceFilterItem } from 'types/multi-choice-filter-Item';
import { getEnumFromString } from 'utils/enum-utils';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import RequestAgencyFilter from 'components/screening/common/filters/request-agency-filter';
import RequestTypeFilter from 'components/screening/common/filters/request-type-filter';
import DeclinedStatusFilter from 'components/screening/common/filters/declined-status-filter';
import { IContract } from 'components/screening/us-gov/IContract';
import { toTitleCase } from 'utils/string-utils';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { ScreeningPageNames } from 'components/common/constants';
import SuitabilityLevelFilter from 'components/screening/common/filters/suitability-level-filter';

interface PublicTrustFilterProps {
    rawCandidates: ICommonScreening[];
    hierarchyRecords: Dictionary<IHierarchy> | undefined;
    showCounts: boolean;
    employeeNames: Map<string, IEmployeeNameAndType>;
    contracts: Dictionary<IContract>;
    employeeIdsWithFutureTerminationDates: string[];
    pageName: string;
}

export function PublicTrustFilter(props: PublicTrustFilterProps): JSX.Element {
    const filtersContext = useContext(FiltersContext);
    const userContext = useContext(UserContext);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [selectedParentStates, setSelectedParentStates] = useState<ScreeningParentStateType[]>(
        [],
    );
    const [selectedChildStates, setSelectedChildStates] = useState<StateName[]>([]);

    function changeDate(isStart: boolean, date: Date | null | undefined): void {
        const finalDate = date || undefined;
        const filterFunction = getFilterfunction(isStart, finalDate, startDate, endDate, true);
        if (isStart) {
            setStartDate(finalDate);
        } else {
            setEndDate(finalDate);
        }
        filtersContext.setStatusChanged(`${filterContextKeys.statusChangedKey}${Date.now()}`);
        filtersContext.updateFilterFunctionsPublicTrust(
            filterContextKeys.statusChangedKey,
            filterFunction,
            true,
        );
    }

    useEffect(() => {
        if (filtersContext.filterCleared) {
            setSelectedChildStates([]);
            setSelectedParentStates([]);
        }
    }, [filtersContext.filterCleared]);

    function filterOnChildState(screening: ICommonScreening, state: StateName): boolean {
        return screening.stateName === state;
    }

    useEffect(() => {
        const newFilterFunctions: {
            func: (arg: ICommonScreening) => boolean;
            undefined: undefined;
        }[] = [];
        for (const parentState of selectedParentStates) {
            if (parentState === ScreeningParentStateType.Completed) {
                const func = (screening: ICommonScreening): boolean =>
                    screening.stateName === StateName.Completed;

                newFilterFunctions.push({ func, undefined });
            } else if (parentState === ScreeningParentStateType.Withdrawn) {
                const func = (screening: ICommonScreening): boolean =>
                    screening.stateName === StateName.Withdrawn;

                newFilterFunctions.push({ func, undefined });
            } else {
                const childStates = selectedChildStates.filter((x) =>
                    PublicTrustParentStateToChildrenMap[parentState]?.includes(x),
                );
                if (childStates.length === 0) {
                    const func = (screening: ICommonScreening): boolean =>
                        filterOnParentState(screening, childStates);

                    newFilterFunctions.push({ func, undefined });
                } else {
                    for (const childState of childStates) {
                        const func = (screening: ICommonScreening): boolean =>
                            filterOnChildState(screening, childState);

                        newFilterFunctions.push({ func, undefined });
                    }
                }
            }
        }

        filtersContext.setFilterFunctions(filterContextKeys.status, newFilterFunctions);
    }, [selectedParentStates, selectedChildStates]);

    function getStatusFilterOptions(): IMultiChoiceFilterItem[] {
        const filterItems: IMultiChoiceFilterItem[] = [];

        for (const parentStateString in ScreeningParentStateType) {
            const parentState = getEnumFromString(ScreeningParentStateType, parentStateString);
            if (!parentState) {
                continue;
            }
            const childStates: StateName[] = PublicTrustParentStateToChildrenMap[parentState];

            filterItems.push({
                key: parentState,
                label: parentStateString,
                isChecked: selectedParentStates.includes(parentState),
                filterFunction(data: ICommonScreening): boolean {
                    if (parentStateString === ScreeningParentStateType.Completed) {
                        return data.stateName === StateName.Completed;
                    } else if (parentStateString === ScreeningParentStateType.Withdrawn) {
                        return data.stateName === StateName.Withdrawn;
                    }
                    return filterOnParentState(data, childStates);
                },
                generateLabel(dataArray: ICommonScreening[]): string {
                    return labelFunction(
                        this.label,
                        dataArray,
                        this.filterFunction,
                        props.showCounts,
                    );
                },
                onChange(
                    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                    checked?: boolean,
                ): void {
                    const isCurrentlySelected: boolean = selectedParentStates.includes(parentState);

                    if (checked && !isCurrentlySelected) {
                        setSelectedParentStates((arr) => [...arr, parentState]);
                        setSelectedChildStates((arr) => [...arr, ...childStates]);
                    } else if (!checked && isCurrentlySelected) {
                        setSelectedParentStates((arr) => arr.filter((x) => x !== parentState));
                        setSelectedChildStates((arr) =>
                            arr.filter((x) => !childStates.includes(x)),
                        );
                    }
                },
            });

            if (selectedParentStates.includes(parentState)) {
                for (const childState of childStates) {
                    filterItems.push({
                        key: parentState + '.' + childState,
                        label: PublicTrustScreeningStateLabels[childState],
                        isChecked: selectedChildStates.includes(childState),
                        filterFunction(data: ICommonScreening): boolean {
                            return filterOnChildState(data, childState);
                        },
                        generateLabel(dataArray: ICommonScreening[]): string {
                            return labelFunction(
                                this.label,
                                dataArray,
                                this.filterFunction,
                                props.showCounts,
                            );
                        },
                        styles: { root: { paddingLeft: '20px' } },
                        onChange(
                            ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                            checked?: boolean,
                        ): void {
                            const isCurrentlySelected: boolean = selectedChildStates.includes(
                                childState,
                            );

                            if (checked && !isCurrentlySelected) {
                                setSelectedChildStates((arr) => [...arr, childState]);
                            } else if (!checked && isCurrentlySelected) {
                                setSelectedChildStates((arr) =>
                                    arr.filter((x) => x !== childState),
                                );
                            }
                        },
                    });
                }
            }
        }

        return filterItems;
    }

    function getEmployeeStatusOptions(): IMultiChoiceFilterItem[] {
        const filterItems: IMultiChoiceFilterItem[] = [];

        filterItems.push(
            // TODO: V2 Add PreHire
            // {
            //     label: toTitleCase(PersonnelTypes.PreHire),
            //     isChecked: IsCheckboxChecked(
            //         filtersContext,
            //         employeeStatusStr,
            //         toTitleCase(PersonnelTypes.PreHire),
            //     ),
            //     filterFunction(data: ICommonScreening): boolean {
            //         return props.employeeNames.get(data.personnelId)?.type === PersonnelTypes.PreHire;
            //     },
            //     generateLabel(dataArray: ICommonScreening[]): string {
            //         return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            //     },
            // },
            {
                label: toTitleCase(PersonnelTypes.Active),
                isChecked: IsCheckboxChecked(
                    filtersContext,
                    employeeStatusStr,
                    toTitleCase(PersonnelTypes.Active),
                ),
                filterFunction(data: ICommonScreening): boolean {
                    return (
                        props.employeeNames.get(data.personnelId)?.type === PersonnelTypes.Active
                    );
                },
                generateLabel(dataArray: ICommonScreening[]): string {
                    return labelFunction(
                        this.label,
                        dataArray,
                        this.filterFunction,
                        props.showCounts,
                    );
                },
            },
        );

        if (
            props.pageName === ScreeningPageNames.MyContracts ||
            props.pageName === ScreeningPageNames.Manage
        ) {
            filterItems.push({
                label: toTitleCase(PersonnelTypes.FutureTermination),
                isChecked: IsCheckboxChecked(
                    filtersContext,
                    employeeStatusStr,
                    toTitleCase(PersonnelTypes.FutureTermination),
                ),
                filterFunction(data: ICommonScreening): boolean {
                    return (
                        props.employeeIdsWithFutureTerminationDates.findIndex(
                            (employeeId) => employeeId === data.personnelId,
                        ) !== -1
                    );
                },
                generateLabel(dataArray: ICommonScreening[]): string {
                    return !props.showCounts
                        ? this.label
                        : this.label +
                              ' (' +
                              dataArray.filter((screeningData) =>
                                  props.employeeIdsWithFutureTerminationDates.find(
                                      (employeeId) => employeeId === screeningData.personnelId,
                                  ),
                              ).length +
                              ')';
                },
                styles: { root: { paddingLeft: '20px' } },
            });
        }

        filterItems.push({
            label: toTitleCase(PersonnelTypes.Terminated),
            isChecked: IsCheckboxChecked(
                filtersContext,
                employeeStatusStr,
                toTitleCase(PersonnelTypes.Terminated),
            ),
            filterFunction(data: ICommonScreening): boolean {
                return (
                    props.employeeNames.get(data.personnelId)?.type === PersonnelTypes.Terminated
                );
            },
            generateLabel(dataArray: ICommonScreening[]): string {
                return labelFunction(this.label, dataArray, this.filterFunction, props.showCounts);
            },
        });

        return filterItems;
    }

    return (
        <div>
            <Separator styles={separatorStyles} alignContent='start'>
                Public Trust Screenings
                {props.showCounts && props.rawCandidates && props.rawCandidates.length > 0
                    ? ' (' + props.rawCandidates.length + ')'
                    : ''}
            </Separator>
            <Stack tokens={stackTokensChildSpace} styles={filterMarginMarginTop10}>
                <ContractDropdownFilter
                    screeningPath={ScreeningPaths.PublicTrust}
                    rawCandidates={props.rawCandidates}
                    contracts={props.contracts}
                    onFilterStateChange={(selectedContracts: string[], adding: boolean): void => {
                        filtersContext.updateFilterFunctionsPublicTrust(
                            filterContextKeys.contractIdKey,
                            (data: ICommonScreening): boolean => {
                                if (data.contractId && selectedContracts.length > 0) {
                                    return selectedContracts.includes(data.contractId);
                                } else if (selectedContracts.length === 0) {
                                    // nothing to filter
                                    return true;
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                {isUserContractOwnerOrNST(userContext, ScreeningPaths.PublicTrust) && (
                    <AgencyDropdownFilter
                        placeHolder='Customer'
                        rawCandidates={props.rawCandidates}
                        contracts={props.contracts}
                        onFilterStateChange={(
                            selectedAgencies: string[],
                            adding: boolean,
                        ): void => {
                            filtersContext.updateFilterFunctionsPublicTrust(
                                filterContextKeys.agencyIdKey,
                                (data: ICommonScreening): boolean => {
                                    const customer = props.contracts[data.contractId]?.customer;
                                    if (customer && selectedAgencies.length > 0) {
                                        return selectedAgencies.includes(customer);
                                    } else if (selectedAgencies.length === 0) {
                                        // nothing to filter
                                        return true;
                                    }
                                    return false;
                                },
                                adding,
                            );
                        }}
                    />
                )}
                <EmployeePickerFilter
                    onFilterStateChange={(value: string, adding: boolean): void => {
                        filtersContext.updateFilterFunctionsPublicTrust(
                            filterContextKeys.personnelIdKey,
                            (data: ICommonScreening): boolean => {
                                if (data?.personnelId) {
                                    return value === data.personnelId;
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                <ProcessOwnerDropdownFilter
                    rawCandidates={props.rawCandidates}
                    onFilterStateChange={(
                        processOwnerSelectedArr: string[],
                        adding: boolean,
                    ): void => {
                        filtersContext.updateFilterFunctionsPublicTrust(
                            filterContextKeys.processOwnerIdKey,
                            (data: ICommonScreening): boolean => {
                                if (processOwnerSelectedArr.includes(noDataText)) {
                                    if (data?.processOwnerId) {
                                        // if there is data for the process owner then we need to check if the data is selected in the
                                        // processOwnerSelectedArr
                                        return processOwnerSelectedArr.includes(
                                            data.processOwnerId,
                                        );
                                    } else {
                                        // if data.processOwner is null or undefined then just return it cause it will show as n/a
                                        return true;
                                    }
                                } else if (
                                    data?.processOwnerId &&
                                    processOwnerSelectedArr.length > 0
                                ) {
                                    return processOwnerSelectedArr.includes(data.processOwnerId);
                                } else if (processOwnerSelectedArr.length === 0) {
                                    return true;
                                }
                                return false;
                            },
                            adding,
                        );
                    }}
                />
                {isUserContractOwnerOrNST(userContext, ScreeningPaths.PublicTrust) && (
                    <>
                        <OrgLeaderPicker
                            hierarchyRecords={props.hierarchyRecords}
                            onFilterStateChange={(
                                value: string,
                                adding: boolean,
                                level: hierarchyLvlsAlias,
                            ): void => {
                                filtersContext.updateFilterFunctionsPublicTrust(
                                    filterContextKeys.orgLeaderAliasKey,
                                    (data: ICommonScreening): boolean => {
                                        if (props.hierarchyRecords) {
                                            const hierarchyRecord =
                                                props.hierarchyRecords[data.personnelId];
                                            if (hierarchyRecord && hierarchyRecord[level]) {
                                                return value === hierarchyRecord[level];
                                            }
                                        }
                                        return false;
                                    },
                                    adding,
                                );
                            }}
                        />
                        <ReportsToPicker
                            onFilterStateChange={(value: string, adding: boolean): void => {
                                filtersContext.updateFilterFunctionsPublicTrust(
                                    filterContextKeys.reportsToKey,
                                    (data: ICommonScreening): boolean => {
                                        if (data?.personnelId && props.hierarchyRecords) {
                                            const reportTo =
                                                props.hierarchyRecords[data.personnelId];
                                            return reportTo
                                                ? value === getReportToFromHierarchy(reportTo)
                                                : false;
                                        }
                                        return false;
                                    },
                                    adding,
                                );
                            }}
                        />
                    </>
                )}
                <RequestAgencyFilter
                    rawCandidates={props.rawCandidates}
                    filterContext={filtersContext}
                />
                <SuitabilityLevelFilter
                    rawCandidates={props.rawCandidates}
                    filterContext={filtersContext}
                />
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Status
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Status Group'>
                <MultiChoiceFilter
                    unfilteredData={props.rawCandidates}
                    filterItems={getStatusFilterOptions()}
                />
            </Stack>
            <DeclinedStatusFilter
                filterContext={filtersContext}
                rawCandidates={props.rawCandidates}
                showCounts
            />
            <RequestTypeFilter
                rawCandidates={props.rawCandidates}
                filterContext={filtersContext}
                showCounts
                screeningPath={ScreeningPaths.PublicTrust}
            />
            <Separator styles={separatorStyles} alignContent='start'>
                Employee Status
            </Separator>
            <Stack styles={filterMargin} role='group' aria-label='Employee Type Group'>
                <MultiChoiceFilter
                    unfilteredData={props.rawCandidates}
                    filterItems={getEmployeeStatusOptions()}
                    onChildStateChange={(value: IMultiChoiceFilterItem, adding: boolean): void => {
                        updateFilterContext(
                            value,
                            adding,
                            employeeStatusStr,
                            filtersContext.setEmployeeStatus,
                            filtersContext,
                            ScreeningPaths.PublicTrust,
                        );
                    }}
                />
            </Stack>
            <Separator styles={separatorStyles} alignContent='start'>
                Last Status Change
            </Separator>
            <DateTimeStartEndPicker
                startDate={startDate}
                endDate={endDate}
                changeDate={(isStart: boolean, date: Date | null | undefined): void =>
                    changeDate(isStart, date)
                }
                filterCleared={filtersContext.filterCleared}
            />
            <Stack.Item styles={{ root: { display: 'flex', flexDirection: 'row-reverse' } }}>
                <ClearFiltersActionButton clearFunc={filtersContext.clearFilters} />
            </Stack.Item>
        </div>
    );
}
