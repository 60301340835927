import React, { useState, useContext, useEffect, useMemo } from 'react';
import { DEFAULT_SHIMMER_LINE_COUNT, TableCell } from 'components/common/table';
import HorizontalBar from 'components/common/horizontal-bar';
import {
    SelectionMode,
    Stack,
    IColumn,
    Sticky,
    IRenderFunction,
    IDetailsHeaderProps,
    StickyPositionType,
    MessageBarType,
    ShimmeredDetailsList,
    Selection,
    MessageBar,
} from '@fluentui/react';
import { AuthContext } from 'contexts/auth-context';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import { useFetchSimple, useIsMounted } from 'utils/misc-hooks';
import { pageStyle } from 'components/screening/common/common-tab-styling';
import { columnHeaderStyles } from 'assets/styles/list-styles';
import { ContractsPageModalTypes, IContract } from 'components/screening/us-gov/IContract';
import { IPagedResults } from 'clients/http-options';
import useMessageBar from 'components/common/use-message-bar';
import { dateToFormattedDateTimeStringFromSeconds } from 'utils/time-utils';
import ManageContractModalActionButton from 'components/screening/admin-contracts/manage-contract-modal-action-button';
import { ContractType } from 'components/screening/us-gov/IContract';
import { ContractsFilter } from 'components/screening/admin-contracts/contracts-filter';
import { FiltersContractsContext } from 'contexts/filters-contracts-context';
import useEmployeeBasicRecords from 'components/common/employee/use-employee-basic-records';
import Spacer from 'components/common/spacer';
import DisplayFacepile from 'components/common/employee/display-facepile';
import SidebarAndContents, {
    ContentPane,
    SidebarPane,
} from 'components/common/sidebar-and-contents';
import { UserContext } from 'contexts/user-context';
import { useIsUserContractAdmin } from 'components/screening/screening-utils';
import EmployeeBasicHoverCardSimple from 'components/common/employee/employee-basic-hover-card-simple';
import { IBasicEmployee } from 'clients/employee-client';
import { xxLargeMaxWidthCoeff, xxxxLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { numCmp, strCmp, useSortColumnHandler, SortDescending } from 'utils/sort-utils';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import { IAgency } from 'components/screening/us-gov/IAgency';

enum ContractsPageColumnNames {
    id = 'ID',
    type = 'Type',
    agency = 'Agency', // Changed from "customer" to "agency"
    projectName = 'Project name',
    status = 'Status',
    owners = 'Owners',
    lastModifiedBy = 'Last modified by',
    lastModified = 'Last modified',
}

export default function ContractManagementTab(): JSX.Element {
    const isMounted = useIsMounted();
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    const filtersContractsContext = useContext(FiltersContractsContext);
    const [selectedContract, setSelectedContract] = useState<IContract>();
    const [filteredContracts, setFilteredContracts] = useState<IContract[]>([]);
    const [shouldFetchContracts, setShouldFetchContracts] = useState<boolean>(true);
    const [isInitialContractLoad, setIsInitialContractLoad] = useState<boolean>(true);
    const [continuationToken, setContinuationToken] = useState<string | undefined>('');
    const [contracts, setContracts] = useState<IContract[]>([]);
    const [agencies, setAgencies] = useState<IAgency[]>([]);
    const [agenciesContinuationToken, setAgenciesContinuationToken] = useState<string | undefined>(
        '',
    );
    const [sortedContracts, setSortedContracts] = useState<IContract[]>([]);
    const [allOwnerIds, setAllOwnerIds] = useState<Set<string>>(new Set<string>());

    const { isUserContractAdmin, isUserContractAdminDetermined } = useIsUserContractAdmin(
        authContext,
    );

    const [{ sortColumn, sortAscending: sortDirection }, sortColumnHandler] = useSortColumnHandler(
        '',
    ); // Default: no sorting

    const selection = useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    setSelectedContract(selection.getSelection()[0] as IContract);
                },
            }),
        [],
    );

    const {
        getBasicEmployeeRecords,
        basicEmployeesMap,
        errorGettingRecords: errorGettingEmployeeRecords,
        clearErrorMessage: clearErrorGettingEmployeeRecords,
        employeesPictureMap: employeePicturesMap,
    } = useEmployeeBasicRecords();

    // Update list of owner Ids any time list of contracts change
    // because an employee may no longer be owner of any contract.
    // At the same time, fetch basic employee records of contract
    // owners and "last modified by" personnel Ids so that their
    // data is available to be shown on employee basic hover card.
    useEffect(() => {
        const allOwnerIdsVar = new Set<string>();
        const allModifiedByIdsVar = new Set<string>();
        contracts.forEach((contract) => {
            contract.owners?.forEach((ownerId) => {
                allOwnerIdsVar.add(ownerId);
            });
            const by = contract.lastModified?.by;
            if (!!by) {
                allModifiedByIdsVar.add(by);
            }
        });
        setAllOwnerIds(allOwnerIdsVar);
        getBasicEmployeeRecords(Array.from(allOwnerIdsVar).concat(Array.from(allModifiedByIdsVar)));
    }, [contracts]);

    const { canAddContract, canEditcontract } = useMemo(() => {
        const isCurrentUserOwner = !!Array.from(allOwnerIds).find(
            (ownerId) => ownerId === userContext.employeeRecord?.id,
        );

        return {
            canAddContract: isUserContractAdmin,
            canEditcontract: isUserContractAdmin || isCurrentUserOwner,
        };
    }, [isUserContractAdmin, allOwnerIds]);

    const renderDetailsHeader = (): IRenderFunction<IDetailsHeaderProps> => (
        headerProps,
        defaultRender,
    ) => {
        if (!headerProps) {
            return null;
        }

        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...headerProps,
                    styles: columnHeaderStyles,
                })}
            </Sticky>
        );
    };

    const {
        theElement: updateErrMsgElement,
        setMessage: setUpdateErrMsg,
        clearMessage: clearUpdateErrMsg,
    } = useMessageBar({ type: MessageBarType.error });

    // Fetch contracts
    useFetchSimple<IPagedResults<IContract>>({
        dependencies: [
            shouldFetchContracts,
            continuationToken,
            isUserContractAdmin,
            isUserContractAdminDetermined,
        ],
        canPerformFetch: isUserContractAdminDetermined && shouldFetchContracts,
        fetchFunc: async (): Promise<IPagedResults<IContract>> => {
            clearUpdateErrMsg();
            setShouldFetchContracts(false);
            if (isUserContractAdmin) {
                // Current user is authorized to see all contracts.
                return await UsGovScreeningClient.getContracts(
                    authContext,
                    Object.keys(ContractType), // Get all contract types.
                    continuationToken,
                );
            } else {
                // Current user is only authorized to see their own contracts.
                return await UsGovScreeningClient.getContractsByOwnerId(
                    authContext,
                    userContext.employeeRecord.id,
                    continuationToken,
                );
            }
        },
        onSuccess: (result: IPagedResults<IContract>): void => {
            if (isMounted()) {
                const { continuationToken: continuationTokenVar, results: contractsVar } = result;
                setContinuationToken(continuationTokenVar);
                setContracts((currentValue) => [...currentValue].concat(contractsVar));
                if (!!continuationTokenVar) {
                    setShouldFetchContracts(true);
                }
            }
        },
        onError: (e): void => {
            console.error(e);
            if (typeof e === 'string') {
                setUpdateErrMsg(e);
            } else {
                setUpdateErrMsg('Error getting contracts');
            }
        },
        onFinally: () => {
            setIsInitialContractLoad(false);
        },
    });

    // Fetch agencies
    useFetchSimple<IPagedResults<IAgency>>({
        dependencies: [agenciesContinuationToken],
        canPerformFetch: true,
        fetchFunc: async (): Promise<IPagedResults<IAgency>> => {
            return await UsGovScreeningClient.getAgencies(authContext, agenciesContinuationToken);
        },
        onSuccess: (result: IPagedResults<IAgency>): void => {
            if (isMounted()) {
                const { continuationToken: continuationTokenVar, results: agenciesVar } = result;
                setAgenciesContinuationToken(continuationTokenVar);
                setAgencies((currentValue) => [...currentValue].concat(agenciesVar));
            }
        },
        onError: (e): void => {
            console.error(e);
            if (typeof e === 'string') {
                setUpdateErrMsg(e);
            } else {
                setUpdateErrMsg('Error getting agencies');
            }
        },
        onFinally: () => {
            setIsInitialContractLoad(false);
        },
    });

    useEffect(() => {
        function filterData(): void {
            if (contracts) {
                let finalData = [...contracts];
                filtersContractsContext.filterFunctionsMap.forEach((filterFunctions: any[]) => {
                    if (filterFunctions!.length > 0) {
                        finalData = finalData.filter((data) => {
                            for (const filterFunction of filterFunctions) {
                                if (filterFunction(data)) {
                                    return true;
                                }
                            }
                            return false;
                        });
                    }
                });
                setFilteredContracts(finalData);
            }
        }
        filterData();
    }, [filtersContractsContext, contracts]);

    // TODO: When new contract is added or existing contract is edited, need to update filteredContracts also
    // - Editing a contract means it had to be visible already... already conforms to filter checkbox selections
    // - Adding a contract means it might not conform to filter checkbox selections
    // TODO - Should list of owner Ids update as well?
    function updateContractsTable(contract: IContract, modalType: ContractsPageModalTypes): void {
        setContracts((currentValue) => {
            const newValue = [...currentValue];
            switch (modalType) {
                case ContractsPageModalTypes.add:
                    newValue.unshift(contract);
                    break;
                case ContractsPageModalTypes.edit:
                case ContractsPageModalTypes.changeOwner:
                    const index = newValue.findIndex((c) => c.id === contract.id);
                    newValue.splice(index, 1, contract);
                    break;
            }
            return newValue;
        });
    }

    useEffect(() => {
        const sortContracts = (contractsParam: IContract[]): IContract[] => {
            type A = IContract;

            const chooseSortCmp = (
                sortColumnParam: string,
                sortDirectionParam: number,
            ): ((r1: IContract, r2: IContract) => number) => {
                switch (sortColumnParam) {
                    case ContractsPageColumnNames.id:
                        return (r1: A, r2: A): number => sortDirectionParam * strCmp(r1.id, r2.id);
                    case ContractsPageColumnNames.agency:
                        return (r1: A, r2: A): number =>
                            sortDirectionParam * strCmp(r1.customer, r2.customer);
                    case ContractsPageColumnNames.lastModified:
                        return (r1: A, r2: A): number =>
                            sortDirectionParam *
                            numCmp(r1.lastModified?.atUtc, r2.lastModified?.atUtc);
                    case ContractsPageColumnNames.lastModifiedBy:
                        return (r1: A, r2: A): number =>
                            sortDirectionParam *
                            strCmp(
                                getDisplayNameOrDefault(
                                    basicEmployeesMap.get(r1.lastModified?.by ?? ''),
                                ),
                                getDisplayNameOrDefault(
                                    basicEmployeesMap.get(r2.lastModified?.by ?? ''),
                                ),
                            );
                    case ContractsPageColumnNames.projectName:
                        return (r1: A, r2: A): number =>
                            sortDirectionParam * strCmp(r1.project, r2.project);
                    case ContractsPageColumnNames.type:
                        return (r1: A, r2: A): number =>
                            sortDirectionParam * strCmp(r1.contractType, r2.contractType);
                    case ContractsPageColumnNames.status:
                        return (r1: A, r2: A): number =>
                            sortDirectionParam * strCmp(r1.contractStatus, r2.contractStatus);
                    default:
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        return (r1: A, r2: A): number => 0;
                }
            };
            const sortCmp = chooseSortCmp(sortColumn, sortDirection);
            return [...contractsParam].sort(sortCmp);
        };
        setSortedContracts(sortContracts(filteredContracts));
    }, [filteredContracts, sortColumn, sortDirection]);

    /*
    Keeping this comment because of Note (1)

    const overflowButtonProps = {
        ariaLabel: 'More owners',
        onClick: doNothing,
    };

    function getOwnerPersonas(ownerIds: string[]): IFacepilePersona[] {
        const newFacepilePersonas: IFacepilePersona[] = [];
        ownerIds.forEach((ownerId) => {
            const currOwnerBasic: IBasicEmployee | undefined = basicEmployeesMap.get(ownerId);
            if (currOwnerBasic) {
                // DisplayHoverCard needs employee basic employee.
                // Add it to the persona object.
                newFacepilePersonas.push(currOwnerBasic);
                // newFacepilePersonas.push(
                // Object.assign({ personaName: currOwnerBasic.displayName }, currOwnerBasic),
                // );
            }
        });

        return newFacepilePersonas;
    }

    const onRenderPersona = (p: IFacepilePersona | undefined): JSX.Element => {
        if (!p) {
            return <></>;
        }

        let image = '';
        if (p.id) {
            image = employeesPictureMap.get(p.id) ?? '';
        }
        return (
            <DisplayHoverCard
                // The following typecast is safe because Value "p" has been
                // calculated by getOwnerPersonas which, in turn, has placed
                // the data of type IBasicEmployee into its return value.
                employeeBasicData={p as IBasicEmployee}
                showFullName={false}
                showMiniCardAlias={false}
                image={image}
            />
        );
    };
    */

    const columnWidths = {
        id: 70,
        type: 80,
        customer: 35,
        projectName: 60,
        state: 50,
        owners: {
            min: 140,
            max: 250,
        },
        lastModifiedBy: {
            min: 140,
            max: 250,
        },
        lastModified: 140,
    };

    const tableColumns: IColumn[] = [
        {
            key: ContractsPageColumnNames.id,
            name: ContractsPageColumnNames.id,
            ariaLabel: ContractsPageColumnNames.id,
            isResizable: true,
            isMultiline: true,
            minWidth: columnWidths.id,
            maxWidth: columnWidths.id * xxLargeMaxWidthCoeff,
            isSorted: sortColumn === ContractsPageColumnNames.id,
            isSortedDescending: sortDirection === SortDescending,
            onColumnClick: (): void => {
                sortColumnHandler(ContractsPageColumnNames.id);
            },
            onRender: (row: IContract): JSX.Element => {
                return <TableCell>{row.id}</TableCell>;
            },
        },
        {
            key: ContractsPageColumnNames.status,
            name: ContractsPageColumnNames.status,
            ariaLabel: ContractsPageColumnNames.status,
            minWidth: columnWidths.state,
            maxWidth: columnWidths.state,
            isSorted: sortColumn === ContractsPageColumnNames.status,
            isSortedDescending: sortDirection === SortDescending,
            onColumnClick: (): void => {
                sortColumnHandler(ContractsPageColumnNames.status);
            },
            onRender: (row: IContract): JSX.Element => {
                return <TableCell>{row.contractStatus ? row.contractStatus : 'Active'}</TableCell>;
            },
        },
        {
            key: ContractsPageColumnNames.type,
            name: ContractsPageColumnNames.type,
            ariaLabel: ContractsPageColumnNames.type,
            minWidth: columnWidths.type,
            maxWidth: columnWidths.type,
            isSorted: sortColumn === ContractsPageColumnNames.type,
            isSortedDescending: sortDirection === SortDescending,
            onColumnClick: (): void => {
                sortColumnHandler(ContractsPageColumnNames.type);
            },
            onRender: (row: IContract): JSX.Element => {
                const contractType = row.contractType
                    ? `${ContractType[row.contractType]}`
                    : `${ContractType.USGovScreening}`;
                return <TableCell>{contractType}</TableCell>;
            },
        },
        {
            key: ContractsPageColumnNames.agency,
            name: ContractsPageColumnNames.agency,
            ariaLabel: ContractsPageColumnNames.agency,
            isResizable: true,
            isMultiline: true,
            minWidth: columnWidths.customer,
            maxWidth: columnWidths.customer * xxLargeMaxWidthCoeff,
            isSorted: sortColumn === ContractsPageColumnNames.agency,
            isSortedDescending: sortDirection === SortDescending,
            onColumnClick: (): void => {
                sortColumnHandler(ContractsPageColumnNames.agency);
            },
            onRender: (row: IContract): JSX.Element => {
                return <TableCell>{row.customer}</TableCell>;
            },
        },
        {
            key: ContractsPageColumnNames.projectName,
            name: ContractsPageColumnNames.projectName,
            ariaLabel: ContractsPageColumnNames.projectName,
            isResizable: true,
            isMultiline: true,
            minWidth: columnWidths.projectName,
            maxWidth: columnWidths.projectName * xxxxLargeMaxWidthCoeff,
            isSorted: sortColumn === ContractsPageColumnNames.projectName,
            isSortedDescending: sortDirection === SortDescending,
            onColumnClick: (): void => {
                sortColumnHandler(ContractsPageColumnNames.projectName);
            },
            onRender: (row: IContract): JSX.Element => {
                return <TableCell>{row.project}</TableCell>;
            },
        },
        {
            key: ContractsPageColumnNames.owners,
            name: ContractsPageColumnNames.owners,
            ariaLabel: ContractsPageColumnNames.owners,
            minWidth: columnWidths.owners.min,
            maxWidth: columnWidths.owners.max,
            onRender: (row: IContract): JSX.Element => {
                return (
                    <DisplayFacepile
                        maxCount={3}
                        personnelIds={row.owners ?? []}
                        basicEmployeesMap={basicEmployeesMap}
                        employeePicturesMap={employeePicturesMap}
                    />
                );
            },
        },
        {
            key: ContractsPageColumnNames.lastModifiedBy,
            name: ContractsPageColumnNames.lastModifiedBy,
            ariaLabel: ContractsPageColumnNames.lastModifiedBy,
            isResizable: true,
            minWidth: columnWidths.lastModifiedBy.min,
            maxWidth: columnWidths.lastModifiedBy.max,
            isSorted: sortColumn === ContractsPageColumnNames.lastModifiedBy,
            isSortedDescending: sortDirection === SortDescending,
            onColumnClick: (): void => {
                sortColumnHandler(ContractsPageColumnNames.lastModifiedBy);
            },
            onRender: (row: IContract): JSX.Element => {
                const modifiedBy = row.lastModified?.by;
                let basicEmployeeRecord: IBasicEmployee | undefined;
                let employeeImage: string | undefined;
                if (modifiedBy) {
                    basicEmployeeRecord = basicEmployeesMap.get(modifiedBy);
                    employeeImage = employeePicturesMap.get(modifiedBy);
                }
                if (basicEmployeeRecord) {
                    return (
                        <EmployeeBasicHoverCardSimple
                            employeeBasicData={basicEmployeeRecord}
                            showMiniCardAlias={true}
                            showFullName={true}
                            displayActions={true}
                            image={employeeImage ?? ''}
                        />
                    );
                } else {
                    return <></>;
                }
            },
        },
        {
            key: ContractsPageColumnNames.lastModified,
            name: ContractsPageColumnNames.lastModified,
            ariaLabel: ContractsPageColumnNames.lastModified,
            minWidth: columnWidths.lastModified,
            maxWidth: columnWidths.lastModified,
            isSorted: sortColumn === ContractsPageColumnNames.lastModified,
            isSortedDescending: sortDirection === SortDescending,
            onColumnClick: (): void => {
                sortColumnHandler(ContractsPageColumnNames.lastModified);
            },
            onRender: (row: IContract): JSX.Element => {
                return (
                    <TableCell>
                        {row.lastModified
                            ? dateToFormattedDateTimeStringFromSeconds(row.lastModified.atUtc)
                            : ''}
                    </TableCell>
                );
            },
        },
    ];

    return (
        <div className={pageStyle.page}>
            {/* TODO: Add breadcrumbs in future PR*/}
            <p> Screening {'>'} Contracts</p>
            <SidebarAndContents>
                <SidebarPane>
                    <ContractsFilter contracts={contracts} />
                </SidebarPane>
                <ContentPane>
                    {errorGettingEmployeeRecords && (
                        <>
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                onDismiss={clearErrorGettingEmployeeRecords}>
                                Error getting employee data. Some employee information is
                                incomplete.
                            </MessageBar>
                            <Spacer marginTop={10} />
                        </>
                    )}
                    <HorizontalBar styles={{ maxHeight: '50px' }}>
                        {canAddContract && (
                            <Stack.Item>
                                {/* TODO: Contract owners cannot see "Add Contract" button. Can see everything else. Add once backend is updated */}
                                {/* userContext.hasPublicTrustUserType() currently does not work.. will implement in future PR */}
                                {/* {(userContext.hasPublicTrustUserType(UsGovScreeningUserType.ContractAdmin) ||
                            userContext.hasUsGovScreeningUserType(
                                UsGovScreeningUserType.ContractAdmin,
                            )) && ( */}
                                <ManageContractModalActionButton
                                    modalType={ContractsPageModalTypes.add}
                                    isUserContractAdmin={isUserContractAdmin}
                                    basicEmployeesMap={basicEmployeesMap}
                                    contractsCallback={updateContractsTable}
                                    agencies={agencies}
                                />
                            </Stack.Item>
                        )}

                        {canEditcontract && (
                            <Stack.Item>
                                <ManageContractModalActionButton
                                    modalType={ContractsPageModalTypes.edit}
                                    contract={selectedContract}
                                    isUserContractAdmin={isUserContractAdmin}
                                    basicEmployeesMap={basicEmployeesMap}
                                    contractsCallback={updateContractsTable}
                                    agencies={agencies}
                                />
                            </Stack.Item>
                        )}

                        {canEditcontract && (
                            <Stack.Item>
                                <ManageContractModalActionButton
                                    modalType={ContractsPageModalTypes.changeOwner}
                                    contract={selectedContract}
                                    isUserContractAdmin={isUserContractAdmin}
                                    basicEmployeesMap={basicEmployeesMap}
                                    contractsCallback={updateContractsTable}
                                    agencies={agencies}
                                />
                            </Stack.Item>
                        )}
                    </HorizontalBar>
                    {updateErrMsgElement()}
                    <Spacer marginTop={10} />
                    <ShimmeredDetailsList
                        items={sortedContracts}
                        columns={tableColumns}
                        onRenderDetailsHeader={renderDetailsHeader()}
                        selectionMode={SelectionMode.single}
                        selectionPreservedOnEmptyClick={true}
                        shimmerLines={DEFAULT_SHIMMER_LINE_COUNT}
                        enableShimmer={isInitialContractLoad}
                        ariaLabelForShimmer={'Loading contracts'}
                        selection={selection}
                    />
                </ContentPane>
            </SidebarAndContents>
        </div>
    );
}
