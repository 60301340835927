import React from 'react';
import { TableCell } from 'components/common/table';
// eslint-disable-next-line no-restricted-imports -- for @fluentui/react/Link import.
import { ActionButton, IColumn, Link } from '@fluentui/react';
import { xLargeMaxWidthCoeff, IconNames } from 'assets/constants/global-constants';
import { IEmployee } from 'clients/employee-client';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import EllipsisTextCss from 'components/common/ellipsis-text-css';

interface IParams {
    showViewOthersProfileButton?: boolean;
}

export function employeeHierarchyTableColumns(params: IParams): IColumn[] {
    const columnWidths = {
        level: 20,
        employee: 100,
        addressBookTitle: 100,
        city: 100,
        country: 100,
        fte: 50,
        action: 80,
    };

    const columns: IColumn[] = [
        {
            key: 'Level',
            name: 'Level',
            ariaLabel: 'Level',
            minWidth: columnWidths.level,
            maxWidth: columnWidths.level * xLargeMaxWidthCoeff,
            onRender: (row: IEmployee, ix: number | undefined): JSX.Element => (
                <TableCell>
                    <span>{ix !== undefined ? ix + 1 : undefined}</span>
                </TableCell>
            ),
        },
        {
            key: 'Employee',
            name: 'Employee',
            ariaLabel: 'Employee',
            minWidth: columnWidths.employee,
            maxWidth: columnWidths.employee * xLargeMaxWidthCoeff,
            onRender: (row: IEmployee): JSX.Element => (
                <TableCell>
                    <EmployeeBasicHoverCard personnelId={row.id} />
                </TableCell>
            ),
        },
        {
            key: 'Address Book Title',
            name: 'Address Book Title',
            ariaLabel: 'Address Book Title',
            minWidth: columnWidths.addressBookTitle,
            maxWidth: columnWidths.addressBookTitle * xLargeMaxWidthCoeff,
            onRender: (row: IEmployee): JSX.Element => (
                <TableCell>
                    <EllipsisTextCss text={row.addressBookTitleDesc} />
                </TableCell>
            ),
        },
        {
            key: 'City',
            name: 'City',
            ariaLabel: 'City',
            minWidth: columnWidths.city,
            maxWidth: columnWidths.city * xLargeMaxWidthCoeff,
            onRender: (row: IEmployee): JSX.Element => (
                <TableCell>
                    <EllipsisTextCss text={row.locationAreaCity} />
                </TableCell>
            ),
        },
        {
            key: 'Country',
            name: 'Country',
            ariaLabel: 'Country',
            minWidth: columnWidths.country,
            maxWidth: undefined,
            onRender: (row: IEmployee): JSX.Element => (
                <TableCell>
                    <EllipsisTextCss text={row.country} />
                </TableCell>
            ),
        },
        {
            key: 'FTE',
            name: 'FTE',
            ariaLabel: 'FTE',
            minWidth: columnWidths.fte,
            maxWidth: columnWidths.fte,
            onRender: (row: IEmployee): JSX.Element => (
                <TableCell>
                    <span>{row.isFTE ? 'Yes' : 'No'}</span>
                </TableCell>
            ),
        },
    ];

    if (params.showViewOthersProfileButton) {
        columns.push({
            key: 'Action',
            name: 'Action',
            ariaLabel: 'Action',
            minWidth: columnWidths.action,
            maxWidth: columnWidths.action,
            onRender: (row: IEmployee): JSX.Element => (
                <TableCell>
                    <Link href={`/profile/user/${row.alias}/info`}>
                        <ActionButton iconProps={{ iconName: IconNames.OpenInNewTab }}>
                            View
                        </ActionButton>
                    </Link>
                </TableCell>
            ),
        });
    }

    return columns;
}
