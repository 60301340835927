import React, { useContext, useEffect, useState } from 'react';
import { Location } from 'history';
import { CustomBreadcrumb, ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import EmployeeClient, {
    IBasicEmployee,
    IEmployee,
    IEmployeeEditableInfo,
    IEmployeeEditableResponse,
    IEmployeeWithEditableData,
    AllEmployeeEditableFields,
    transformPrehireToEmployeeObject,
    SearchEmployeeStatus,
} from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import { AppConstants } from 'components/common/constants';
import EmployeeCard from 'components/common/employee/employee-card';
import EmployeeData from 'components/common/employee/employee-data';
import EligibilityClient, {
    IPersonnelAttributesMap,
    PersonnelAttributeCodes,
} from 'clients/eligibility-client';
import PersonnelProfileForeignTravelTable from 'components/personnel-profile/personnel-profile-foreign-travel-table';
import { BreadCrumbContext, BreadCrumbHelper } from 'contexts/breadcrumb-context';
import PersonnelProfileClearanceTable from 'components/personnel-profile/clearance/personnel-profile-clearance-table';
import PersonnelProfileNotes from 'components/personnel-profile/personnel-profile-notes';
import PersonnelProfilePolygraphTable from 'components/personnel-profile/clearance/personnel-profile-polygraph-table';
import PersonnelProfileSpecialAccessTable from 'components/personnel-profile/clearance/personnel-profile-special-access-table';
import {
    ClearanceClient,
    ClearanceType,
    IClearanceRecord,
    IDeletedRecordInfo,
    IPolygraphRecord,
    ISpecialAccessRecord,
} from 'clients/clearance-client';
import { IPagedResults } from 'clients/http-options';
import PersonnelProfileScaHistoryTable from 'components/personnel-profile/personnel-profile-security-compensation-allowance-history-table';
import { useParams } from 'react-router-dom';
import { isGUID } from 'utils/string-utils';
import { UserContext } from 'contexts/user-context';
import { AccessDeniedPage } from 'components/common/access-denied';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import PersonnelProfileEligibilityTable from 'components/personnel-profile/personnel-profile-eligibilities';
import config from 'environments/environment';
import { ActionButton, MessageBar, MessageBarType, Modal, mergeStyleSets } from '@fluentui/react';
import ConvertProfileStepper from 'components/personnel-profile/convert-profile/convert-profile-stepper';
import { filterOutNullProperties } from 'utils/object-utils';
import {
    ScreeningPaths,
    UsGovScreeningUserType,
} from 'components/screening/common/common-constants';
import DocumentsClient, {
    emptyNoContentIDocumentToken,
    IDocumentRecord,
    IDocumentToken,
} from 'clients/documents-client';
import UsGovScreeningClient, {
    ApplicationServices,
} from 'clients/screening/us-gov-screening-client';
import PublicTrustScreeningClient from 'clients/screening/public-trust-screening-client';
import PersonnelProfileScreeningTable from 'components/personnel-profile/personnel-profile-screening-table';
import PersonnelProfileUsGovDocumentTable from 'components/personnel-profile/documents-profile/personnel-profile-us-gov-document-table';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import { ISuitabilityRecord, SuitabilityClient } from 'clients/suitability-client';
import PersonnelProfileSuitabilityTable from 'components/personnel-profile/suitability/personnel-profile-suitability-table';

export interface PersonnelProfilePageProps {
    location: Location;
    userType?: string;
}

type Params = {
    employeeAliasOrId: string;
};

const defaultBreadCrumbItems: ICustomBreadcrumbItem[] = [
    {
        title: 'Profile',
        link: '',
    },
    {
        title: 'US Gov',
        link: '/profile/us-gov',
    },
];

export default function PersonnelProfilePage(props: PersonnelProfilePageProps): JSX.Element {
    const isDocumentStorageFlag = useFeatureFlag(FeatureFlagKeys.documentStorage).enabled;
    const isSuitabilityRecordsFlag = useFeatureFlag(FeatureFlagKeys.suitabilityRecords).enabled;
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const breadCrumbContext = useContext(BreadCrumbContext);
    const [isBreadCrumbAdded, setAddedBreadCrumb] = useState<boolean>(false);
    // if we're going to the manage candidate page from a page redirect e.g. "manage", "my-screening", etc.  then we use
    // the previously page title's breadcrumb as the cachekey to update the local cached copies of the screening records
    // if the candidate's screening record had been updated on the manage candidate page
    const cacheKey =
        breadCrumbContext.breadCrumbs.length >= 2
            ? breadCrumbContext.breadCrumbs[breadCrumbContext.breadCrumbs.length - 2].link
            : '';
    const [employee, setEmployee] = useState<IEmployeeWithEditableData | undefined>();
    const [editableEmployee, setEditableEmployee] = useState<
        IEmployeeEditableResponse | undefined
    >();
    const [manager, setManager] = useState<IBasicEmployee | undefined>();

    const [employeeAttributes, setEmployeeAttributes] = useState<
        IPersonnelAttributesMap | undefined
    >();
    const [clearances, setClearances] = useState<IClearanceRecord[]>([]);
    const [suitabilities, setSuitabilities] = useState<ISuitabilityRecord[]>([]);
    const [polygraphs, setPolygraphs] = useState<IPolygraphRecord[]>([]);
    const [specialAccesses, setSpecialAccesses] = useState<ISpecialAccessRecord[]>([]);
    const [updatedClearance, setUpdatedClearance] = useState<IClearanceRecord | undefined>();
    const [updatedSuitability, setUpdatedSuitability] = useState<ISuitabilityRecord | undefined>();
    const [updatedPolygraph, setUpdatedPolygraph] = useState<IPolygraphRecord | undefined>();
    const [isShowCreateNewProfileProcess, setShowCreateNewProfileProcess] = useState<boolean>(
        false,
    );
    const [hasErrorOccurred, setErrorOccurred] = useState<boolean>(false);
    const [errorMessage, setMessage] = useState<string>('');
    const [isFetchingPolygraphData, setIsFetchingPolygraphData] = useState<boolean>(false);
    const [documents, setDocuments] = useState<IDocumentRecord[]>([]);
    const [associatedUserToken, setAssociatedUserToken] = useState<IDocumentToken>(
        emptyNoContentIDocumentToken,
    );
    const [isFetchingDocumenthData, setIsFetchingDocumentData] = useState<boolean>(false);
    // Used to drive update logic on profile page during upload/delete document events
    // not used in screenings.
    // https://dev.azure.com/msazure/Microsoft%20Personnel/_workitems/edit/26317833/
    const [documentIdWorkaround, setDocumentIdWorkaround] = useState<string>('');

    const usGovStringsMap: Map<string, string> = new Map([
        ['tableLabel', 'Clearance screenings'],
        ['path', 'us-gov'],
        ['viewAllRecordsMessage', 'View all'],
        ['noRecordsToShowMessage', 'No clearance screening records to display'],
        ['tableName', 'U S Government Screening Records'],
    ]);

    const publicTrustStringsMap: Map<string, string> = new Map([
        ['tableLabel', 'Public Trust screenings'],
        ['path', 'public-trust'],
        ['viewAllRecordsMessage', 'View all'],
        ['noRecordsToShowMessage', 'No suitability screening records to display'],
        ['tableName', 'Public Trust Screening Records'],
    ]);

    const numberOfTableRowsToDisplay = 5;
    const { employeeAliasOrId } = useParams<Params>();
    const [deletionNote, setDeletionNote] = useState('');
    const [uploadNote, setUploadNote] = useState('');

    useEffect(() => {
        setUploadNote(uploadNote);
    }, [uploadNote]);

    useEffect(() => {
        setDeletionNote(deletionNote);
    }, [deletionNote]);

    useEffect(() => {
        if (documentIdWorkaround !== '') {
            setDocumentIdWorkaround('');
        }
    }, [documentIdWorkaround]);

    useEffect(() => {
        async function setupEmployee(providedEmployeeAliasOrId: string): Promise<void> {
            let employeeResult: IEmployeeWithEditableData | undefined = undefined;

            let editableEmployeeResult = await EmployeeClient.getEditableEmployeeDataByIdOrAliasOrGUID(
                authContext,
                providedEmployeeAliasOrId,
                Object.values(AllEmployeeEditableFields),
            );

            // edge case -- redirect user to the FTE record if they tried to visit an already converted prehire record via the URL
            // if editiable record and not pre hire with a valid employeeId (should not happen)
            if (
                editableEmployeeResult.employeeStatus &&
                editableEmployeeResult.employeeId &&
                editableEmployeeResult.employeeStatus.localeCompare(
                    SearchEmployeeStatus.prehire,
                    'en',
                    { sensitivity: 'base' },
                ) !== 0 &&
                editableEmployeeResult.employeeStatus.localeCompare(
                    SearchEmployeeStatus.active,
                    'en',
                    { sensitivity: 'base' },
                ) !== 0
            ) {
                try {
                    editableEmployeeResult = await EmployeeClient.getEditableEmployeeDataByIdOrAliasOrGUID(
                        authContext,
                        editableEmployeeResult.employeeId,
                        Object.values(AllEmployeeEditableFields),
                    );

                    providedEmployeeAliasOrId = editableEmployeeResult.employeeId!;
                } catch {
                    console.error('Error fetching editable employee record');
                }
            }

            if (isGUID(providedEmployeeAliasOrId)) {
                employeeResult = {
                    data: {
                        ...transformPrehireToEmployeeObject(editableEmployeeResult),
                        ...editableEmployeeResult.employeeEditableInfo,
                    },
                    employeeStatus: editableEmployeeResult.employeeStatus,
                    editablePropertiesProvidedByHR:
                        editableEmployeeResult.editablePropertiesProvidedByHR,
                };
            } else {
                const employeeData = await EmployeeClient.getEmployeeByAliasOrId(
                    authContext,
                    providedEmployeeAliasOrId,
                );
                employeeResult = {
                    data: {
                        ...editableEmployeeResult.employeeEditableInfo,
                        ...filterOutNullProperties(employeeData),
                    },
                    employeeStatus: editableEmployeeResult.employeeStatus,
                    editablePropertiesProvidedByHR:
                        editableEmployeeResult.editablePropertiesProvidedByHR,
                };
            }

            if (employeeResult) {
                setEmployee(employeeResult);
            }

            if (editableEmployeeResult) {
                setEditableEmployee(editableEmployeeResult);
            }
        }

        if (employeeAliasOrId) {
            setupEmployee(employeeAliasOrId);
        }
    }, [employeeAliasOrId, authContext]);

    useEffect(() => {
        async function setupManager(reportsToEmailName: string | undefined | null): Promise<void> {
            let managerResult: IBasicEmployee | undefined = undefined;
            if (reportsToEmailName) {
                try {
                    const basicResults = await EmployeeClient.getBasicEmployeesByAlias(
                        authContext,
                        [reportsToEmailName],
                    );
                    managerResult = basicResults[0];
                } catch {
                    console.error("Error fetching employee's manager record");
                }
            }
            if (managerResult) {
                setManager(managerResult);
            }
        }

        setupManager(employee?.data.reportsToEmailName);
    }, [employee, authContext]);

    useEffect(() => {
        async function getUserAttributes(personnelId: string | undefined | null): Promise<void> {
            let personnelAttributes: IPersonnelAttributesMap | undefined;
            try {
                if (personnelId) {
                    personnelAttributes = await EligibilityClient.getPersonnelAttributesMap(
                        authContext,
                        personnelId,
                    );
                }
                if (personnelAttributes) {
                    setEmployeeAttributes(personnelAttributes);
                }
            } catch (e) {
                console.warn(
                    `User ${personnelId} does not have any attributes, maybe because they are terminated.`,
                );
            }
        }
        getUserAttributes(employee?.data?.id);
    }, [employee, authContext]);

    useEffect(() => {
        const queryValue = employeeAliasOrId;

        const breadCrumbTitle = getDisplayNameOrDefault(employee?.data, queryValue);

        if (!isBreadCrumbAdded) {
            BreadCrumbHelper.appendOrAddDefaults(
                breadCrumbContext,
                {
                    title: breadCrumbTitle,
                    link: `${props.location.pathname}/${queryValue}`,
                },
                defaultBreadCrumbItems,
            );
            setAddedBreadCrumb(true);
        } else {
            if (
                breadCrumbTitle !==
                breadCrumbContext.breadCrumbs[breadCrumbContext.breadCrumbs.length - 1].title
            ) {
                BreadCrumbHelper.replaceLastBreadCrumbItem(breadCrumbContext, {
                    title: breadCrumbTitle,
                    link: `${props.location.pathname}`,
                });
            }
        }
    }, [props.location, employeeAliasOrId, employee, breadCrumbContext, isBreadCrumbAdded]);

    useEffect(() => {
        async function setupClearances(
            selectedEmployee: IEmployee & IEmployeeEditableInfo,
        ): Promise<void> {
            const retrievedClearances: IClearanceRecord[] = [];
            let continuationToken: string | undefined = '';
            try {
                do {
                    const results: IPagedResults<IClearanceRecord> = await ClearanceClient.getClearancesForEmployee(
                        authContext,
                        selectedEmployee,
                        continuationToken,
                    );
                    retrievedClearances.push(...results.results);
                    continuationToken = results.continuationToken;
                } while (continuationToken);

                setClearances(retrievedClearances);
            } catch (e) {
                console.error(
                    'error with getting clearances from employee ',
                    selectedEmployee,
                    ' --- ',
                    e,
                );
            }
        }
        if (employee) {
            setupClearances(employee.data);
        }
    }, [employee, authContext]);

    useEffect(() => {
        async function setupSuitabilities(
            selectedEmployee: IEmployee & IEmployeeEditableInfo,
        ): Promise<void> {
            const retrievedSuitabilities: ISuitabilityRecord[] = [];
            let continuationToken: string | undefined = '';
            try {
                do {
                    const results: IPagedResults<ISuitabilityRecord> = await SuitabilityClient.getSuitabilitiesForEmployee(
                        authContext,
                        selectedEmployee,
                        continuationToken,
                    );
                    retrievedSuitabilities.push(...results.results);
                    continuationToken = results.continuationToken;
                } while (continuationToken);

                retrievedSuitabilities.sort((a, b) => {
                    const bDateTime = b.creationDateTimeUTCMilliseconds ?? 0;
                    const aDateTime = a.creationDateTimeUTCMilliseconds ?? 0;
                    return bDateTime - aDateTime;
                });

                setSuitabilities(retrievedSuitabilities);
            } catch (e) {
                console.error(
                    'error with getting suitabilities from employee ',
                    selectedEmployee,
                    ' --- ',
                    e,
                );
            }
        }
        if (employee) {
            setupSuitabilities(employee.data);
        }
    }, [employee, authContext]);

    useEffect(() => {
        async function setupSpecialAccesses(
            selectedEmployee: IEmployee & IEmployeeEditableInfo,
        ): Promise<void> {
            const retrievedSpecialAccesses: ISpecialAccessRecord[] = [];
            let continuationToken: string | undefined = '';
            try {
                do {
                    const results: IPagedResults<ISpecialAccessRecord> = await ClearanceClient.getSpecialAccessesForEmployee(
                        authContext,
                        selectedEmployee,
                        continuationToken,
                    );
                    retrievedSpecialAccesses.push(...results.results);
                    continuationToken = results.continuationToken;
                } while (continuationToken);

                setSpecialAccesses(retrievedSpecialAccesses);
            } catch (e) {
                console.error(
                    'error with getting special accesses from employee ',
                    selectedEmployee,
                    ' --- ',
                    e,
                );
            }
        }
        if (employee) {
            setupSpecialAccesses(employee.data);
        }
    }, [employee, authContext]);

    useEffect(() => {
        async function setupPolygraphs(
            selectedEmployee: IEmployee & IEmployeeEditableInfo,
        ): Promise<void> {
            setIsFetchingPolygraphData(true);
            const retrievedPolygraphs: IPolygraphRecord[] = [];
            let continuationToken: string | undefined = '';
            try {
                do {
                    const results: IPagedResults<IPolygraphRecord> = await ClearanceClient.getPolygraphsForEmployee(
                        authContext,
                        selectedEmployee,
                        continuationToken,
                    );
                    retrievedPolygraphs.push(...results.results);
                    continuationToken = results.continuationToken;
                } while (continuationToken);

                setPolygraphs(retrievedPolygraphs);
            } catch (e) {
                console.error(
                    'error with getting polygraphs from employee ',
                    selectedEmployee,
                    ' --- ',
                    e,
                );
            } finally {
                setIsFetchingPolygraphData(false);
            }
        }

        if (employee) {
            setupPolygraphs(employee.data);
        }
    }, [employee, authContext]);

    useEffect(() => {
        async function setupDocuments(): Promise<void> {
            try {
                const associatedUserToken: IDocumentToken = await UsGovScreeningClient.getAssociatedDocumentUserToken(
                    authContext,
                    employee?.data.id ?? '',
                    ApplicationServices.profile,
                );
                const results: IDocumentRecord[] = await DocumentsClient.getDocumentsByAssociatedUserId(
                    authContext,
                    employee?.data.id ?? '',
                    associatedUserToken.token,
                );

                if (results) {
                    setDocuments(results);
                    setAssociatedUserToken(associatedUserToken);
                }
            } catch {
                // TODO: Consider MessageBar
                console.error('Error fetching documents by associatedUserId');
            } finally {
                setIsFetchingDocumentData(false);
            }
        }
        if (isDocumentStorageFlag && employee?.data.id && documentIdWorkaround === '') {
            setupDocuments();
        }
    }, [
        employee?.data.id,
        authContext,
        deletionNote,
        uploadNote,
        documentIdWorkaround,
        isDocumentStorageFlag,
    ]);

    useEffect(() => {
        if (updatedClearance) {
            setSpecialAccesses((currentValue) => {
                const newSpecialAccesses = currentValue.map((x) => {
                    if (x.clearanceId === updatedClearance.id) {
                        x.clearance = updatedClearance;
                    }
                    return x;
                });
                return newSpecialAccesses;
            });

            setPolygraphs((currentValue) => {
                const newPolygraphs = currentValue.map((x) => {
                    if (x.clearanceId === updatedClearance.id) {
                        x.clearance = updatedClearance;
                    }
                    return x;
                });
                return newPolygraphs;
            });
        }

        setClearances((currentValue) => {
            if (currentValue) {
                const newClearances = currentValue.slice(0);
                newClearances.sort((a, b) => {
                    const bDateTime = b.creationDateTimeUTCMilliseconds ?? 0;
                    const aDateTime = a.creationDateTimeUTCMilliseconds ?? 0;
                    return bDateTime - aDateTime;
                });

                return newClearances;
            }
            return currentValue;
        });
    }, [updatedClearance]);

    useEffect(() => {
        setSuitabilities((currentValue) => {
            if (currentValue) {
                const newSuitabilities = currentValue.slice(0);

                newSuitabilities.sort((a, b) => {
                    const bDateTime = b.creationDateTimeUTCMilliseconds ?? 0;
                    const aDateTime = a.creationDateTimeUTCMilliseconds ?? 0;
                    return bDateTime - aDateTime;
                });
                return newSuitabilities;
            }
            return currentValue;
        });
    }, [updatedSuitability]);

    useEffect(() => {
        setPolygraphs((currentValue) => {
            if (currentValue) {
                const newPolygraphs = currentValue.slice(0);
                newPolygraphs.sort((a, b) => {
                    const bDateTime = b.creationDateTimeUTCMilliseconds ?? 0;
                    const aDateTime = a.creationDateTimeUTCMilliseconds ?? 0;
                    return bDateTime - aDateTime;
                });
                return newPolygraphs;
            }
            return currentValue;
        });
    }, [updatedPolygraph]);

    async function deleteClearanceRecord(id: string): Promise<boolean> {
        try {
            const res = await ClearanceClient.deleteClearance(authContext, id);

            const data = (await res.json()) as IDeletedRecordInfo[];
            let deletedClearanceId = '';
            const deletedSpecialAccessIds = new Set<string>();
            const deletedPolygraphIds = new Set<string>();
            data.forEach((x) => {
                switch (x.type) {
                    case ClearanceType.Clearance:
                        deletedClearanceId = x.id;
                        break;
                    case ClearanceType.SpecialAccess:
                        deletedSpecialAccessIds.add(x.id);
                        break;
                    case ClearanceType.Polygraph:
                        deletedPolygraphIds.add(x.id);
                        break;
                }
            });

            const newClearances = clearances.filter((x) => x.id !== deletedClearanceId);
            const newSpecialAccesses = specialAccesses.filter(
                (x) => !deletedSpecialAccessIds.has(x.id),
            );
            const newPolygraphs = polygraphs.filter((x) => !deletedPolygraphIds.has(x.id));
            setClearances(newClearances);
            setSpecialAccesses(newSpecialAccesses);
            setPolygraphs(newPolygraphs);

            if (deletedClearanceId) {
                setUpdatedClearance(undefined);
            }

            if (deletedPolygraphIds.size > 0) {
                setUpdatedPolygraph(undefined);
            }

            return res.status === 200;
        } catch (e) {
            console.error('error on delete', e);
            return false;
        }
    }

    async function deleteSuitabilityRecord(id: string): Promise<boolean> {
        try {
            const res = await SuitabilityClient.deleteSuitability(authContext, id);

            const data = (await res.json()) as IDeletedRecordInfo[];
            let deletedSuitabilityId = '';
            data.forEach((x) => {
                deletedSuitabilityId = x.id;
            });

            const newSuitabilities = suitabilities.filter((x) => x.id !== deletedSuitabilityId);

            setSuitabilities(newSuitabilities);

            return res.status === 200;
        } catch (e) {
            console.error('error on delete', e);
            return false;
        }
    }

    function getDisplayNameOrDefault(
        providedEmployee: (IEmployee & IEmployeeEditableInfo) | undefined,
        defaultValue: string,
    ): string {
        if (providedEmployee) {
            if (providedEmployee.displayName) {
                return providedEmployee.displayName;
            } else if (providedEmployee.preferredFirstName && providedEmployee.preferredLastName) {
                return `${providedEmployee?.preferredFirstName} ${providedEmployee?.preferredLastName}`;
            } else if (providedEmployee.firstName && providedEmployee.lastName) {
                return `${providedEmployee?.firstName} ${providedEmployee?.lastName}`;
            }
        }
        return defaultValue;
    }

    function hasClearance(): boolean {
        return clearances && clearances.length > 0;
    }

    function closeMessage(): void {
        setErrorOccurred(false);
        setMessage('');
    }

    return (
        <div>
            <PageLoadingSpinner
                isLoading={!userContext.isUsGovScreeningUserTypesLoaded}
                label='loading...'
                ariaLive='assertive'
                labelPosition='right'>
                {userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST) ? (
                    <>
                        <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                        <div className={styles.main}>
                            {config.personnelProfile.prehireFlag &&
                                employee &&
                                isGUID(employee.data?.id) && (
                                    <>
                                        <div className={styles.convertProfileButton}>
                                            <ActionButton
                                                onClick={(): void =>
                                                    setShowCreateNewProfileProcess(true)
                                                }
                                                iconProps={{ iconName: 'AddFriend' }}
                                                allowDisabledFocus>
                                                Convert Profile
                                            </ActionButton>
                                            <Modal
                                                isOpen={isShowCreateNewProfileProcess}
                                                onDismiss={(): void => {
                                                    setShowCreateNewProfileProcess(false);
                                                }}
                                                isBlocking={true}
                                                isDarkOverlay={false}
                                                containerClassName={styles.modalContainer}>
                                                <ConvertProfileStepper
                                                    hideCreateNewProfileProcess={(): void => {
                                                        setShowCreateNewProfileProcess(false);
                                                    }}
                                                    onCreateNewProfileError={(
                                                        msg: string,
                                                    ): void => {
                                                        setErrorOccurred(true);
                                                        setMessage(msg);
                                                    }}
                                                    convertPerson={employee}
                                                    convertManager={manager}
                                                />
                                            </Modal>
                                        </div>
                                        <div>
                                            {hasErrorOccurred && (
                                                <MessageBar
                                                    messageBarType={MessageBarType.error}
                                                    isMultiline={false}
                                                    dismissButtonAriaLabel='Close'
                                                    onDismiss={closeMessage}
                                                    styles={{ root: { marginBottom: 15 } }}>
                                                    {errorMessage}
                                                </MessageBar>
                                            )}
                                        </div>
                                    </>
                                )}
                            <div className={styles.content}>
                                <div className={styles.leftContent}>
                                    {employee && (
                                        <div>
                                            <div className={styles.card}>
                                                <EmployeeCard
                                                    employee={employee.data}
                                                    manager={`${
                                                        manager && manager.displayName
                                                            ? manager.displayName
                                                            : ''
                                                    }`}
                                                    managerAlias={`${
                                                        manager && manager.onPremisesSamAccountName
                                                            ? manager.onPremisesSamAccountName
                                                            : ''
                                                    }`}
                                                    pcn={
                                                        employee.data.positionNumber > 0
                                                            ? `${employee.data.positionNumber}`
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <div className={styles.container}>
                                                <EmployeeData
                                                    employee={{
                                                        data: {
                                                            ...employee.data,
                                                            isUSCitizen: !!(
                                                                employeeAttributes &&
                                                                employeeAttributes[
                                                                    PersonnelAttributeCodes
                                                                        .USCitizenship
                                                                ]
                                                            ),
                                                        },
                                                        employeeStatus: employee.employeeStatus,
                                                        editablePropertiesProvidedByHR:
                                                            employee.editablePropertiesProvidedByHR,
                                                    }}
                                                    allowEditableEmployeeData={true}
                                                    updateEmployeeData={(newEmployeeData): void =>
                                                        setEmployee(newEmployeeData)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className={styles.middleContent}>
                                    <div className={styles.container}>
                                        <PersonnelProfileScreeningTable
                                            employee={employee}
                                            resultSize={numberOfTableRowsToDisplay}
                                            queryMethod={
                                                PublicTrustScreeningClient.getPagedScreeningsByEmployee
                                            }
                                            screeningPath={ScreeningPaths.PublicTrust}
                                            strings={publicTrustStringsMap}
                                        />
                                    </div>

                                    {isSuitabilityRecordsFlag && (
                                        <div className={styles.container}>
                                            <PersonnelProfileSuitabilityTable
                                                employee={employee}
                                                suitabilities={suitabilities}
                                                setSuitabilities={setSuitabilities}
                                                setUpdatedSuitability={setUpdatedSuitability}
                                                deleteRecordFunction={deleteSuitabilityRecord}
                                                cacheKey={cacheKey}
                                            />
                                        </div>
                                    )}

                                    <div className={styles.container}>
                                        <PersonnelProfileScreeningTable
                                            employee={employee}
                                            resultSize={numberOfTableRowsToDisplay}
                                            queryMethod={
                                                UsGovScreeningClient.getPagedScreeningsByEmployee
                                            }
                                            screeningPath={ScreeningPaths.UsGov}
                                            strings={usGovStringsMap}
                                        />
                                    </div>
                                    <div className={styles.container}>
                                        <PersonnelProfileClearanceTable
                                            employee={employee}
                                            clearances={clearances}
                                            setClearances={setClearances}
                                            setUpdatedClearance={setUpdatedClearance}
                                            deleteRecordFunction={deleteClearanceRecord}
                                            specialAccesses={specialAccesses}
                                            polygraphs={polygraphs}
                                            cacheKey={cacheKey}
                                        />
                                    </div>

                                    {hasClearance() && (
                                        <div className={styles.container}>
                                            <PersonnelProfileSpecialAccessTable
                                                employee={employee}
                                                clearances={clearances}
                                                specialAccesses={specialAccesses}
                                                setSpecialAccesses={setSpecialAccesses}
                                            />
                                        </div>
                                    )}

                                    {hasClearance() && (
                                        <div className={styles.container}>
                                            <PersonnelProfilePolygraphTable
                                                employee={employee}
                                                clearances={clearances}
                                                isFetchingData={isFetchingPolygraphData}
                                                polygraphs={polygraphs}
                                                setPolygraphs={setPolygraphs}
                                                setUpdatedPolygraph={setUpdatedPolygraph}
                                            />
                                        </div>
                                    )}

                                    <div className={styles.container}>
                                        <PersonnelProfileForeignTravelTable
                                            employee={employee}
                                            resultSize={numberOfTableRowsToDisplay}
                                        />
                                    </div>

                                    <div className={styles.container}>
                                        <PersonnelProfileScaHistoryTable
                                            employee={employee}
                                            resultSize={numberOfTableRowsToDisplay}
                                        />
                                    </div>

                                    {isDocumentStorageFlag && (
                                        <div className={styles.container}>
                                            <PersonnelProfileUsGovDocumentTable
                                                employee={employee}
                                                editableEmployee={editableEmployee}
                                                documents={documents}
                                                isFetchingData={isFetchingDocumenthData}
                                                associatedUserToken={associatedUserToken}
                                                onChangeDeleteNote={setDeletionNote}
                                                onChangeUploadNote={setUploadNote}
                                                setDocumentIdWorkaround={setDocumentIdWorkaround}
                                            />
                                        </div>
                                    )}

                                    {config.personnelProfile.eligibilityFlag && (
                                        <div className={styles.container}>
                                            <PersonnelProfileEligibilityTable
                                                employee={employee}
                                                resultSize={numberOfTableRowsToDisplay}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className={styles.rightContent}>
                                    <div className={styles.container}>
                                        <PersonnelProfileNotes
                                            employee={employee}
                                            editableEmployee={editableEmployee}
                                            deletionNote={deletionNote}
                                            uploadNote={uploadNote}
                                            documentIdWorkaround={documentIdWorkaround}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    AccessDeniedPage()
                )}
            </PageLoadingSpinner>
        </div>
    );
}

const styles = mergeStyleSets({
    main: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: AppConstants.padding,
        backgroundColor: 'transparent',
        boxSizing: 'border-box',
    },
    header: {
        marginBottom: AppConstants.margin,
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        gridTemplateColumns: 'minmax(350px, .75fr) minmax(350px, 2fr) minmax(350px, .75fr)',
        gridGap: AppConstants.margin,
        width: 'fit-content',
    },
    card: {
        transition: 'box-shadow 300ms ease 0s',
        boxShadow:
            'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
        marginBottom: AppConstants.margin,
    },
    container: {
        marginBottom: AppConstants.margin,
    },
    loading: {
        margin: '50%',
        padding: '1rem',
        border: '1px solid #f3f3f3',
    },
    rightContent: {
        width: 'fit-content',
    },
    middleContent: {
        width: 'fit-content',
    },
    leftContent: {
        width: 'fit-content',
    },
    convertProfileButton: {
        marginTop: '-20px',
        marginLeft: '7px',
    },
    modalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
});
