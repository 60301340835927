import React from 'react';
import UserAssignmentsProvider from 'components/user-assignments/user-assignments-context';
import UserAssignments from 'components/user-assignments/user-assignments';
import VisitorsFilterProvider from 'components/visitors/visitors-filter-context';
import VisitorsDataProvider from 'components/visitors/visitors-data-context';
import { IUserAssignmentsComponentProps } from 'components/user-assignments/user-assignments';

export default function UserAssignmentsWrapper(props: IUserAssignmentsComponentProps): JSX.Element {
    return (
        <UserAssignmentsProvider>
            <VisitorsFilterProvider>
                <VisitorsDataProvider>
                    <UserAssignments {...props} />
                </VisitorsDataProvider>
            </VisitorsFilterProvider>
        </UserAssignmentsProvider>
    );
}
