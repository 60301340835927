import { IconNames } from 'assets/constants/global-constants';
import FacilitiesClient, {
    IFacilityPreClaimException,
    IFacilityRecord,
    IFacilityTimeslotItem,
    IReservationRecord,
    ISeatRecord,
    ProvisionType,
    ReservationState,
} from 'clients/facilities-client';
import ModalActionButton, {
    ModalConclusion,
    onModalConcludeType,
} from 'components/common/buttons/modal-action-button';
import { handleUTCToFacilityTimeZone } from 'components/facilities/common/facility-time-utils';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { TextField } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useIsMounted } from 'utils/misc-hooks';
import { TimeFormats, timeZoneAbbr } from 'utils/time-utils';
import ClientUtils from 'clients/client-utils';

export interface FacilitiesReservationModalProps<T> {
    facilityRecord: IFacilityRecord;
    timeslotItem: IFacilityTimeslotItem;
    seatRecord: ISeatRecord;
    onModalConcluded: onModalConcludeType<T>;
    renderWithoutButton?: boolean;
    preclaimedReservation?: IReservationRecord;
    text?: string;
    onReservation?: (reservation: IReservationRecord) => void;
}

export default function FacilitiesReservationModal<T>(
    props: FacilitiesReservationModalProps<T>,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [preclaimedReservation, setPreclaimedReservation] = useState<IReservationRecord>();
    const [icmDescription, setIcmDescription] = useState<string>();
    const [errorMsg, setErrorMsg] = useState<string>();

    const isMounted = useIsMounted();

    useEffect(() => {
        if (props.renderWithoutButton) {
            preclaimSeat();
        }
    }, []);

    async function preclaimSeat(): Promise<void> {
        try {
            const preclaimedReservationVar = props.preclaimedReservation
                ? props.preclaimedReservation
                : await ClientUtils.withRetry(
                      async () =>
                          await FacilitiesClient.preclaimSeatReservation(
                              authContext,
                              userContext,
                              props.facilityRecord.id,
                              props.seatRecord.id,
                              props.timeslotItem.startDateTimeUTCMilliseconds,
                          ),
                  );

            if (props.preclaimedReservation === undefined && props.onReservation)
                props.onReservation(preclaimedReservationVar);

            if (isMounted()) {
                setPreclaimedReservation(preclaimedReservationVar);
            }
        } catch (ex) {
            const exceptionPromise = (await ex) as string;
            const preclaimException = JSON.parse(exceptionPromise) as IFacilityPreClaimException;

            if (isMounted()) {
                setErrorMsg(preclaimException.message);
            }
        }
    }

    function getModalSubtitle(): string {
        return `Would you like to confirm your reservation for the seat ${props.seatRecord.seatName}
            at facility ${props.facilityRecord.facilityName}
            on ${handleUTCToFacilityTimeZone(
                props.timeslotItem.startDateTimeUTCMilliseconds,
                props.facilityRecord,
                TimeFormats.ddd_DD_MMM_YYYY,
                'Unknown',
            )}
             from
             ${handleUTCToFacilityTimeZone(
                 props.timeslotItem.startDateTimeUTCMilliseconds,
                 props.facilityRecord,
                 TimeFormats.H_mmA,
                 'Unknown',
             )}
              to ${handleUTCToFacilityTimeZone(
                  props.timeslotItem.endDateTimeUTCMilliseconds,
                  props.facilityRecord,
                  TimeFormats.H_mmA,
                  'Unknown',
              )} ${timeZoneAbbr(props.facilityRecord?.timeZone)}?
        ${isIcmDescriptionRequired() ? 'If so please provide an ICM # or description.' : ''}`;
    }

    async function confirmReservation(): Promise<void> {
        try {
            if (preclaimedReservation) {
                const confirmedReservation = await ClientUtils.withRetry(
                    async () =>
                        await FacilitiesClient.confirmSeatReservation(
                            authContext,
                            userContext,
                            props.facilityRecord.id,
                            preclaimedReservation.claimCode.code,
                            icmDescription,
                        ),
                );

                if (props.onReservation) {
                    props.onReservation(confirmedReservation);
                }
            }
        } catch (error) {
            throw 'We encountered some difficulties confirming your seat reservation.';
        }
    }

    async function onModalConcluded(modalConclusion: ModalConclusion): Promise<void> {
        if (
            modalConclusion === ModalConclusion.Cancel &&
            preclaimedReservation &&
            !props.preclaimedReservation
        ) {
            try {
                await ClientUtils.withRetry(
                    async () =>
                        await FacilitiesClient.cancelSeatReservation(
                            authContext,
                            userContext,
                            preclaimedReservation.id,
                        ),
                );

                if (props.onReservation) {
                    props.onReservation({
                        ...preclaimedReservation,
                        state: ReservationState.Cancelled,
                    });
                }
            } catch (error) {
                console.log('Failed to cancel the preclaimed reservation');
            }
        }
        props.onModalConcluded(modalConclusion);
    }

    function isIcmDescriptionRequired(): boolean {
        return props.seatRecord.provisionInfo.provisionType === ProvisionType.LiveSite;
    }

    function getIcmDescriptionTextBox(): JSX.Element {
        return (
            <TextField
                required
                label='ICM # / Description'
                value={icmDescription || ''}
                maxLength={255}
                onChange={(e, value): void => {
                    setIcmDescription(value);
                }}
            />
        );
    }

    function isSubmitButtonEnabled(): boolean {
        return isIcmDescriptionRequired()
            ? icmDescription !== undefined &&
                  icmDescription.trim() !== '' &&
                  !!preclaimedReservation
            : !!preclaimedReservation;
    }

    return (
        <ModalActionButton
            text={props.text ? props.text : 'Reserve Seat'}
            iconName={IconNames.AirTickets}
            submitButtonIcon={IconNames.AirTickets}
            tooltip={`Click here to reserve seat (${props.seatRecord.seatName})`}
            modalTitle={'Confirming your reservation'}
            errorMsg={errorMsg}
            modalSubtitle={getModalSubtitle()}
            enableSubmit={isSubmitButtonEnabled()}
            onButtonClick={preclaimSeat}
            renderWithoutButton={props.renderWithoutButton}
            onSubmit={confirmReservation}
            onModalConcluded={onModalConcluded}
            isBlocking={true}>
            {isIcmDescriptionRequired() && getIcmDescriptionTextBox()}
        </ModalActionButton>
    );
}
