import { TextField } from '@fluentui/react';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext } from 'react';
import ElementFooter from 'components/forms/element-footer';
import { ElementTypeProps } from 'components/forms/forms-common';

export default function SignatureElement(props: ElementTypeProps): JSX.Element {
    const { element, updateForm } = props;
    const authContext = useContext(AuthContext);
    const userName = authContext.getUserProfile()?.name ?? '';
    return (
        <div style={{ marginBottom: '1.5rem' }}>
            <TextField
                value={element.label}
                onChange={(ev, newValue): void => updateForm(element.id, newValue, 'label')}
                placeholder='Enter a label...'
                underlined
            />
            <div style={{ marginTop: '1rem' }}>
                <TextField
                    disabled
                    placeholder={`Enter your name, ${userName}, exactly as it appears`}
                />
            </div>
            <ElementFooter element={element} updateForm={updateForm} hasRequiredToggle={false} />
        </div>
    );
}
