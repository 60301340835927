import React, { useContext, useEffect, useMemo, useState } from 'react';
import Routes, { signedin } from 'configs/routes';
import { mergeStyleSets } from '@fluentui/react';
import { AuthContext, isKioskAppPresent } from 'contexts/auth-context';
// eslint-disable-next-line no-restricted-imports -- Not used in core.
import EmployeeClient, { createEmptyEmployeeRecord, IEmployee } from 'clients/employee-client';
// eslint-disable-next-line no-restricted-imports -- UserContextProvider not used in core.
import UserContextProvider from 'contexts/user-context';
import Environment from 'environments/environment';
import { BrowserHistoryOptions, createBrowserHistory } from 'history';
import BreadCrumbContextProvider from 'contexts/breadcrumb-context';
import { CacheContextProvider } from 'contexts/cache-context';
import { globalStyles } from 'assets/styles/global-styles';
import { BrowserRouter } from 'react-router-dom';
import { Footer } from 'components/common/page-shell/footer';
import { Header, MIN_HEIGHT_HEADER_PX } from 'components/common/page-shell/header';
import { getAppInsights } from 'utils/telemetry-utils';
import { HeaderBanner } from 'components/common/page-shell/header-banner';
import { CorePrincipalsClient } from 'clients/core/personnel-core-client-wrappers';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import PrincipalUserContextProvider from 'contexts/principal-user-context';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import GroupsUserContextProvider from 'contexts/user-contexts/groups-user-context';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { useMsal } from '@azure/msal-react';
import { useQuery } from 'utils/misc-utils';

const clarityEnvironmentIds = {
    'Local': 'ds956aho7u',
    'Test': 'ds8olfix1l',
    'PPE': 'ds89gmd93n',
    // NO PROD!
};

function loadAndStartClarityScript(projectId: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = `(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "${projectId}");`;
    document.head.appendChild(script);
}

// Adding based on documentation that history should take the public url into account
// https://skryvets.com/blog/2018/09/20/an-elegant-solution-of-deploying-react-app-into-a-subdirectory/
export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL,
} as BrowserHistoryOptions);

export default function ProtectedApp(): JSX.Element {
    const authContext = useContext(AuthContext);
    const employeeCore = useFeatureFlag(FeatureFlagKeys.employeeCore);
    const { inProgress } = useMsal();

    const [isCsatDefault, setIsCsatDefault] = useState<boolean>(false);

    const onSetCsatDefault = (): void => {
        setIsCsatDefault(true);
    };

    const onResetCsatDefault = (): void => {
        setIsCsatDefault(false);
    };

    if (!isSignedInPage()) {
        authContext.login();
    }

    const msalUser = useMemo(() => {
        return authContext.getUserProfile();
    }, [authContext]);

    const [employeeRecord, setEmployeeRecord] = useState<IEmployee>();

    // Temporarily allow the principal record to be null in the case of failure. Once Principal service is launched in prod, this should always be provided
    const [principalRecord, setPrincipalRecord] = useState<IPrincipalRecord | null>();

    useEffect(() => {
        if (!Environment.production && Environment.environmentName in clarityEnvironmentIds) {
            loadAndStartClarityScript(
                clarityEnvironmentIds[
                    Environment.environmentName as keyof typeof clarityEnvironmentIds
                ],
            );
        }
    }, []);

    const validDocumentTitlePrefixes = ['PPE', 'Test', 'Dev', 'Local'];
    useEffect(() => {
        if (
            !Environment.production &&
            validDocumentTitlePrefixes.includes(Environment.environmentName)
        ) {
            document.title = Environment.environmentName + ' - ' + document.title;
            const link = document.querySelector("link[rel~='icon']") as any;
            if (link) {
                link.href = `/screening/favicon_${Environment.environmentName}.png`;
            }
        }
    }, []);

    useEffect(() => {
        (async (): Promise<void> => {
            if (
                employeeCore.enabled &&
                msalUser &&
                msalUser.alias &&
                !isMsalInteractionInProgress()
            ) {
                const employee = await EmployeeClient.getEmployeeByAliasOrId(
                    authContext,
                    msalUser.alias,
                );
                setEmployeeRecord(employee);
            } else if (isKioskAppPresent) {
                // The kiosk identity isn't an employee. Create an empty employee record to represent it.
                setEmployeeRecord(createEmptyEmployeeRecord());
            }
        })();
    }, [authContext, employeeCore, msalUser]);

    useEffect(() => {
        (async (): Promise<void> => {
            if (msalUser && msalUser.oid && !isMsalInteractionInProgress()) {
                // Include authenticated user's OID on all further logging
                getAppInsights()?.setAuthenticatedUserContext(msalUser.oid);

                try {
                    const client = new CorePrincipalsClient(authContext);

                    const principalRecordResult = await client.getByField(null, msalUser.oid, null);
                    setPrincipalRecord(principalRecordResult);
                } catch (e) {
                    // Temporarily catch and set principal record to null so the page can render. Once Principal service is launched in prod, this should be uncaught and fatal.
                    getAppInsights()?.trackException({
                        exception: e,
                        severityLevel: SeverityLevel.Error,
                    });
                    setPrincipalRecord(null);
                }
            } else if (isKioskAppPresent) {
                // The kiosk identity isn't an employee. Bypass providing a PrincipalUserContext
                setPrincipalRecord(null);
            }
        })();
    }, [authContext, msalUser]);

    function isSignedInPage(): boolean {
        return window.location.href.includes(signedin);
    }

    // NOTE: ensure we're not trying to retrieve employee or principal record before MSAL is ready to get us a token
    function isMsalInteractionInProgress(): boolean {
        return inProgress !== 'none';
    }

    if (msalUser && (!employeeCore.enabled || employeeRecord) && principalRecord !== undefined) {
        const userContextChildren = (
            <GroupsUserContextProvider>
                <BreadCrumbContextProvider>
                    <CacheContextProvider cache={new Map<string, unknown>()}>
                        <BrowserRouter>
                            <div className={styles.app}>
                                <header>
                                    <div className={styles.header}>
                                        <Header
                                            logout={authContext.logout}
                                            isCsatDefault={isCsatDefault}
                                            onResetCsatDefault={onResetCsatDefault}
                                        />
                                        <HeaderBanner />
                                    </div>
                                </header>
                                <div className={styles.content}>
                                    <main>
                                        <Routes onSetCsaDefault={onSetCsatDefault} />
                                    </main>
                                </div>
                                <footer className={styles.footer}>
                                    <Footer />
                                </footer>
                            </div>
                        </BrowserRouter>
                    </CacheContextProvider>
                </BreadCrumbContextProvider>
            </GroupsUserContextProvider>
        );

        const principalUserContextChildren = employeeCore.enabled ? (
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            <UserContextProvider msalUser={msalUser} employeeRecord={employeeRecord!}>
                {userContextChildren}
            </UserContextProvider>
        ) : (
            userContextChildren
        );

        // Temporarily skip providing the context. Once Principal service is launched in prod, this should always be provided
        return principalRecord !== null ? (
            <PrincipalUserContextProvider msalUser={msalUser} principalRecord={principalRecord}>
                {principalUserContextChildren}
            </PrincipalUserContextProvider>
        ) : (
            principalUserContextChildren
        );
    } else {
        return <></>;
    }
}

const styles = mergeStyleSets({
    header: {
        width: '100%',
    },
    content: [
        {
            paddingTop: MIN_HEIGHT_HEADER_PX,
            flexGrow: 1,
            flexShrink: 1,
        },
        globalStyles.gutter,
    ],
    app: [
        {
            minWidth: '600px',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'Segoe UI,SegoeUI,"Helvetica Neue",Helvetica,Arial,sans-serif',
            backgroundColor: 'white',
        },
        globalStyles.fontStyles,
    ],
    footer: {
        width: '100%',
        bottom: 0,
        marginTop: '4.5rem',
    },
});
