import { Pivot, PivotItem, Spinner } from '@fluentui/react';
import FormsClient from 'clients/forms-client';
import { AuthContext } from 'contexts/auth-context';
import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { usePathParts } from 'utils/misc-hooks';
import { FormsCreate } from 'components/forms/forms-create';
import { Form } from 'components/forms/forms-common';
import { FormsEdit } from 'components/forms/forms-edit';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { CustomBreadcrumb, ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import { FormsEditSettings } from 'components/forms/settings/forms-edit-settings';
import { FormsViewerDataLoader } from 'components/forms/forms-viewer-data-loader';

const preview = 'preview';

export function FormsEditDataLoader(): JSX.Element {
    const authContext = useContext(AuthContext);
    const breadCrumbContext = useContext(BreadCrumbContext);

    const history = useHistory();
    const pathParts = usePathParts();

    const { name } = useParams<{ name?: string }>();

    const [form, setForm] = useState<Form>();

    const isCreatePage = !name;
    const isPreviewPage = pathParts.at(-1) === preview;

    const fetchFormByName = useCallback(async () => {
        try {
            const result = await FormsClient.GetGenericForm(authContext, name ?? '');
            setForm(result);
            if (!name) {
                history.replace(`${result.name}`);
            }
        } catch (e) {
            console.error(e);
        }
    }, [name]);

    useEffect(() => {
        if (!isCreatePage && !form) {
            fetchFormByName();
        }
    }, [fetchFormByName, form, isCreatePage]);

    useEffect(() => {
        const nameCrumb = name ? { title: name, link: `/forms/edit/${name}` } : {};
        const previewCrumb = isPreviewPage ? { title: preview, link: '' } : {};

        const crumbs = [
            { title: 'Forms', link: '' },
            { title: 'Templates', link: '/forms/templates' },
            { title: 'Edit', link: '' },
            nameCrumb,
            previewCrumb,
        ].filter((value) => Object.keys(value).length !== 0) as ICustomBreadcrumbItem[];

        breadCrumbContext.setBreadCrumbs(crumbs);
    }, [isPreviewPage, name]);

    let currentPage = <></>;

    if (isCreatePage) {
        currentPage = <FormsCreate />;
    } else if (!form) {
        currentPage = <Spinner />;
    } else if (isPreviewPage) {
        currentPage = <FormsViewerDataLoader form={form} />;
    } else {
        currentPage = (
            <Pivot aria-label='Questions and settings pivot'>
                <PivotItem headerText='Questions'>
                    <FormsEdit form={form} setForm={setForm as Dispatch<SetStateAction<Form>>} />
                </PivotItem>
                <PivotItem headerText='Settings'>
                    <FormsEditSettings
                        form={form}
                        setForm={setForm as Dispatch<SetStateAction<Form>>}
                    />
                </PivotItem>
            </Pivot>
        );
    }
    return (
        <>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            {currentPage}
        </>
    );
}
