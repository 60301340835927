import { globalStyles } from 'assets/styles/global-styles';
import { IStyleSet, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import { Link } from 'react-router-dom';
import EmailSvg from 'components/support/icons/email.svg';
import PhoneSvg from 'components/support/icons/phone.svg';
import UserGuideSvg from 'components/support/icons/user-guide.svg';
import TrainingGuideSvg from 'components/support/icons/training-guide.svg';
import SvgSvg from 'components/support/icons/svg.svg';
import Environment from 'environments/environment';

const userGuides = [
    'Attribute sets',
    'User details',
    'Attributes',
    'Group members',
    'User search',
    'Group owners',
    'Admin',
];

function ContactUs(): JSX.Element {
    const supportLinkToAka = useFeatureFlag(FeatureFlagKeys.supportLinkToAka);

    return (
        <>
            <div className={styles.supportContent}>
                <div>
                    <h4 className={styles.supportIntro}>Contact us and other resources</h4>
                </div>
                {supportLinkToAka.enabled ? (
                    <Link
                        className={styles.mediumCard}
                        to={{ pathname: 'https://aka.ms/personnel/support' }}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <div className={styles.mediumCardIconHolder}>
                            <img
                                className={styles.mediumCardIcon}
                                src={UserGuideSvg}
                                aria-hidden='true'
                            />
                        </div>
                        <div>
                            <div className={styles.mediumCardTitle}>Support Page</div>
                            <div>aka.ms/personnel/support</div>
                        </div>
                    </Link>
                ) : (
                    <div className={styles.cards}>
                        <div>
                            <div className={styles.headerCard}>
                                <img
                                    className={styles.headerCardIcon}
                                    src={UserGuideSvg}
                                    aria-hidden='true'
                                />
                                <div className={styles.mediumCardTitle}>User Guides</div>
                            </div>
                            <div className={styles.subCardContainer}>
                                {userGuides.map((userGuide) => (
                                    <Link
                                        className={styles.subCard}
                                        key={userGuide}
                                        to={{
                                            pathname: `/pdf/User_Guide_${userGuide.replaceAll(
                                                ' ',
                                                '_',
                                            )}.pdf`,
                                        }}
                                        target='_blank'
                                        rel='noopener noreferrer'>
                                        <img
                                            className={styles.subCardIcon}
                                            src={SvgSvg}
                                            aria-hidden='true'
                                        />
                                        <span>{userGuide}</span>
                                    </Link>
                                ))}
                            </div>
                        </div>
                        <div>
                            {Environment.supportEmail !== '' && (
                                <Link
                                    className={styles.mediumCard}
                                    to={{ pathname: `mailto:${Environment.supportEmail}` }}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    <div className={styles.mediumCardIconHolder}>
                                        <img
                                            className={styles.mediumCardIcon}
                                            src={EmailSvg}
                                            aria-hidden='true'
                                        />
                                    </div>
                                    <div>
                                        <div className={styles.mediumCardTitle}>Email</div>
                                        <div>{Environment.supportEmail}</div>
                                    </div>
                                </Link>
                            )}
                            {Environment.supportPhone !== '' && (
                                <Link
                                    className={styles.mediumCard}
                                    to={{ pathname: `tel:${Environment.supportPhone}` }}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    <div className={styles.mediumCardIconHolder}>
                                        <img
                                            className={styles.mediumCardIcon}
                                            src={PhoneSvg}
                                            aria-hidden='true'
                                        />
                                    </div>
                                    <div>
                                        <div className={styles.mediumCardTitle}>Phone</div>
                                        <div>{Environment.supportPhone}</div>
                                    </div>
                                </Link>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default ContactUs;

const cardStyle = {
    boxSizing: 'border-box',
    display: 'inline-block',
    background: '#FFF',
    borderRadius: '4px',
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.15), 0px 3px 8px 0px rgba(0, 0, 0, 0.14)',
    color: '#323130',
    textDecoration: 'none',
} as IStyleSet;

const selectableCard = {
    selectors: {
        '&:hover': {
            background: '#f5f5f5',
            boxShadow: '0 4px 8px #00000024, 0 0 2px #0000001f',
        },
    },
} as IStyleSet;

const styles = mergeStyleSets({
    cards: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: '42px',
        flexWrap: 'wrap',
    },
    headerCard: [
        cardStyle,
        {
            width: '666px',
            height: '214px',
            marginBottom: '42px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
    ],
    headerCardIcon: {
        width: '75px',
        height: '75px',
    },
    subCardContainer: {
        display: 'flex',
        gap: '20px',
        width: '666px',
        flexWrap: 'wrap',
    },
    subCard: [
        cardStyle,
        selectableCard,
        {
            width: '323px',
            height: '72px',
            display: 'flex',
            alignItems: 'center',
            padding: '20px 22px',
        },
    ],
    subCardIcon: {
        width: '32px',
        height: '32px',
        marginRight: '16px',
    },
    mediumCard: [
        cardStyle,
        selectableCard,
        {
            width: '323px',
            height: '86px',
            display: 'flex',
            alignItems: 'center',
            padding: '3px',
            marginBottom: '42px',
        },
    ],
    mediumCardIconHolder: {
        boxSizing: 'border-box',
        width: '78px',
        height: '54px',
        textAlign: 'center',
        padding: '5px',
        marginRight: '9px',
    },
    mediumCardIcon: {
        height: '44px',
    },
    mediumCardTitle: {
        fontWeight: '700',
        fontSize: '16px',
    },
    supportContent: {
        margin: '73px 66px',
    },
    supportIntro: {
        fontSize: '24px',
        fontWeight: '600',
        lineHeight: '32px',
        marginBottom: '94px',
        h4: { marginBottom: '0px' },
    },
    fullCard: {
        padding: '36px 11px',
    },
    cardIcon: {
        width: '60px',
        height: '60px',
        padding: '7px 14px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    cardTitle: {
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '28px',
        letterSpacing: '0.16px',
        marginTop: '4px',
        marginBottom: '10px',
    },
    cardSubtitle: {
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.14px',
    },
});
