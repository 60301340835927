import React, { useContext } from 'react';
import { UserContext } from 'contexts/user-context';
import UserAssignmentsWrapper from 'components/user-assignments/user-assignments-wrapper';
import { AssignmentsPageContentType } from 'components/user-assignments/user-assignments-constants';
import { Location } from 'history';

interface IUserAssignmentsMeProps {
    location: Location;
}

export default function UserAssignmentsMe(props: IUserAssignmentsMeProps): JSX.Element {
    const userContext = useContext(UserContext);

    // TODO: Consider replacing the props show* with a context
    return (
        <UserAssignmentsWrapper
            location={props.location}
            contentType={AssignmentsPageContentType.MyInfo}
            visitorIdOrAlias={userContext.employeeRecord.alias}
            //
            showSearchBox={false}
            //
            showEmployeeInfo={true}
            showViewOthersProfileButton={false}
            //
            showVisitorInfo={false}
            showEditVisitorButton={false}
            //
            showAttributes={true}
            showAddAttributeButton={false}
            showDeleteAttributeButton={false}
            //
            showEligibilities={true}
            showAddEligibilityButton={false}
            showDeleteEligibilityButton={false}
            //
            showEquipment={true}
        />
    );
}
