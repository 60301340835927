import React from 'react';
import { ActionButton } from '@fluentui/react';
import ButtonBar from 'components/common/button-bar';
import AddServiceModalButton from 'components/staffing/service/add-service-modal-button';
import { IAddServiceModal } from 'components/staffing/staffing-page-types';
import { IStaffingTeamResponse } from 'clients/staffing-client';
import { Role } from 'configs/roles';
import CheckRole from 'components/common/check-role';
import EditTeamModalButton from 'components/staffing/allocation/edit-team-modal-button';
import { ModalConclusion } from 'components/common/buttons/modal-action-button';

interface StaffingButtonBarProps {
    teams: IStaffingTeamResponse[];
    orgName: string;
    addTeam: () => void;
    addService?: boolean;
    addAllocation?: () => void;
    editTeamConclusion: (modalConclusion: ModalConclusion) => void;
    hasOrgEditPermission: boolean;
    addServiceModalParams?: IAddServiceModal;
}

export default function StaffingButtonBar(props: StaffingButtonBarProps): JSX.Element {
    return (
        <CheckRole
            requiredRolesAny={[Role.StaffingAdminEdit]}
            hasRequiredRolesAny={[props.hasOrgEditPermission]}>
            <ButtonBar>
                <EditTeamModalButton
                    teams={props.teams || []}
                    orgName={props.orgName}
                    editTeamConclusion={props.editTeamConclusion}
                />
                <ActionButton
                    iconProps={{ iconName: 'PeopleAdd' }}
                    text='Add Team'
                    onClick={props.addTeam}
                />
                {props.addService && (
                    <AddServiceModalButton
                        teams={props.addServiceModalParams?.teams ?? []}
                        orgName={props.addServiceModalParams?.orgName ?? ''}
                        allServices={props.addServiceModalParams?.allServices ?? []}
                        addServiceCallback={
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            props.addServiceModalParams?.addServiceCallback ?? ((): void => {})
                        }
                    />
                )}
                {props.addAllocation && (
                    <ActionButton
                        iconProps={{ iconName: 'AddFriend' }}
                        text='Add Allocation'
                        onClick={props.addAllocation}
                    />
                )}
            </ButtonBar>
        </CheckRole>
    );
}
