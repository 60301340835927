import {
    DefaultButton,
    IButtonStyles,
    mergeStyleSets,
    Spinner,
    SpinnerSize,
} from '@fluentui/react';
import {
    IFacilityTimeslotItem,
    ISeatStatus,
    ReservationState,
    SeatStatusArray,
    SeatStatuses,
    ISeatRecord,
    getAvailableSeatStatus,
} from 'clients/facilities-client';
import React from 'react';

export interface IReservationCalendarCell {
    timeslotItem: IFacilityTimeslotItem;
    seatStatus: ISeatStatus;
    facilitySeats: ISeatRecord[];
    preclaimedSeat?: ICell;
    confirmedSeat?: ICell;
    isLoading: boolean;
    clickHandler: (cell: IReservationCalendarCell) => void;
    isUserBlockedFromReserving: boolean;
}

export interface ICell {
    timeslotItem: IFacilityTimeslotItem;
    seatStatus: ISeatStatus;
    isLoading: boolean;
}

export default function ReservationCalendarCell(props: IReservationCalendarCell): JSX.Element {
    const getSeatRecord: ISeatRecord | undefined = props.facilitySeats.find(
        (x) => x.id === props.seatStatus.seatId,
    );

    function isCurrentSeatPreclaimed(): boolean {
        return props.seatStatus.reservationState === ReservationState.Preclaimed;
    }

    function isCurrentSeatCheckedIn(): boolean {
        return props.seatStatus.reservationState === ReservationState.CheckedIn;
    }

    function isDisabledTimeslot(): boolean {
        return [SeatStatuses.Unavailable.text, SeatStatuses.OutOfOrder.text].includes(
            textFromSeatStatusArray(),
        );
    }

    function textFromInputValue(inputValue: string): string {
        return SeatStatusArray.find((x) => x.value === inputValue)?.text ?? 'No Status';
    }

    const textFromSeatStatusArray = (): string => {
        return (
            SeatStatusArray.find((x) => x.value === props.seatStatus.status)?.text ?? 'No Status'
        );
    };

    const timeslotStyle = (): IButtonStyles => {
        if (isCurrentSeatCheckedIn()) {
            return checkedInStyle;
        } else if (isCurrentSeatPreclaimed()) {
            return mergeStyleSets(cellStyle(originalStatusText()), selectedStyle);
        }
        return mergeStyleSets(cellStyle(props.seatStatus.status));
    };

    const timeslotText = (): string => {
        if (isCurrentSeatPreclaimed()) {
            return textFromInputValue(originalStatusText());
        }
        return textFromSeatStatusArray();
    };

    const originalStatusText = (): string => {
        return getAvailableSeatStatus(getSeatRecord);
    };

    return (
        <>
            <DefaultButton
                disabled={
                    isDisabledTimeslot() || props.isLoading || props.isUserBlockedFromReserving
                }
                styles={timeslotStyle()}
                onClick={(): void => props.clickHandler(props)}>
                {props.isLoading ? <Spinner size={SpinnerSize.medium} /> : timeslotText()}
            </DefaultButton>
        </>
    );
}

const cellStyle = (valueOrText: string): IButtonStyles => {
    switch (valueOrText) {
        case SeatStatuses.Available.value || SeatStatuses.Available.text:
            return availableStyle;
        case SeatStatuses.AvailableForICM.value || SeatStatuses.AvailableForICM.text:
            return availableForICMStyle;
        case SeatStatuses.Unavailable.value || SeatStatuses.Unavailable.text:
            return unavailableStyle;
        case SeatStatuses.MySeat.value || SeatStatuses.MySeat.text:
            return mySeatStyle;
        case SeatStatuses.OutOfOrder.value || SeatStatuses.OutOfOrder.text:
            return outOfOrderStyle;
        default:
            return defaultCellStyle;
    }
};

const defaultCellStyle: IButtonStyles = {
    root: {
        width: '100%',
        fontWeight: 600,
    },
    rootHovered: {
        backgroundColor: '#fff',
    },
};

const availableStyle: IButtonStyles = mergeStyleSets(
    {
        root: {
            color: '#008440',
        },
        rootHovered: {
            color: '#008440',
        },
    },
    defaultCellStyle,
);

const availableForICMStyle: IButtonStyles = mergeStyleSets(
    {
        root: {
            color: SeatStatuses.AvailableForICM.color,
        },
        rootHovered: {
            color: SeatStatuses.AvailableForICM.color,
            backgroundColor: '#fff',
        },
    },
    defaultCellStyle,
);

const unavailableStyle: IButtonStyles = mergeStyleSets(
    {
        root: {
            color: SeatStatuses.Unavailable.color,
        },
    },
    defaultCellStyle,
);

const selectedStyle: IButtonStyles = mergeStyleSets(
    {
        root: {
            border: '3px solid rgb(16, 110, 190)',
        },
        rootHovered: {
            backgroundColor: '#fff',
        },
    },
    defaultCellStyle,
);

const checkedInStyle: IButtonStyles = mergeStyleSets({
    root: {
        width: '100%',
        fontWeight: 600,
        color: '#911418',
        backgroundColor: '#ddd',
    },
    rootHovered: {
        color: '#911418',
        backgroundColor: '#ddd',
    },
});

const mySeatStyle: IButtonStyles = mergeStyleSets(
    {
        root: {
            color: SeatStatuses.MySeat.color,
        },
        rootHovered: {
            color: SeatStatuses.MySeat.color,
            backgroundColor: '#fff',
        },
    },
    defaultCellStyle,
);

const outOfOrderStyle: IButtonStyles = mergeStyleSets(
    {
        root: {
            color: SeatStatuses.OutOfOrder.color,
        },
    },
    defaultCellStyle,
);
