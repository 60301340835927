import React, { useContext } from 'react';
import { ClearanceClient, IClearanceRecord, IPolygraphRecord } from 'clients/clearance-client';
import { IEmployeeWithEditableData } from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import { Table, TableCell } from 'components/common/table';
import { dateToLocalDate } from 'utils/time-utils';
import {
    getAgencyEnumValueFromKey,
    getClearanceLevelAndAgencyTitle,
    isClearanceActive,
    polygraphResultSpan,
} from 'components/personnel-profile/clearance/profile-clearance-constants';
import {
    firstColumnTableCellPadding,
    firstColumnTitlePadding,
} from 'components/personnel-profile/common/common-constants';
import { SharedColors } from '@fluentui/theme';
import { IColumn } from '@fluentui/react';
import PolygraphDetails from 'components/personnel-profile/clearance/polygraph-details';
import EllipsisText from 'components/common/ellipsis-text';
import { commonPersonnelPersonnelStyles } from 'components/personnel-profile/common-personnel-profile-styles';
import { getDefaultColumnWidths } from 'utils/table-utils';

export interface PersonnelProfilePolygraphTableProps {
    employee: IEmployeeWithEditableData | undefined;
    clearances: IClearanceRecord[];
    isFetchingData: boolean;
    polygraphs: IPolygraphRecord[];
    setPolygraphs: (records: IPolygraphRecord[]) => void;
    setUpdatedPolygraph: (record?: IPolygraphRecord) => void;
}

export default function PersonnelProfilePolygraphTable(
    props: PersonnelProfilePolygraphTableProps,
): JSX.Element {
    const authContext = useContext(AuthContext);

    function createRightEtiquette(
        etiquettePolygraphs: IPolygraphRecord[],
    ): { label: string; backgroundColor: string; maxLength: number } | undefined {
        if (etiquettePolygraphs) {
            const activePolygraphs = etiquettePolygraphs
                .filter(
                    (x) =>
                        x.results?.toLocaleLowerCase() === 'pass' && isClearanceActive(x.clearance),
                )
                .map((x) => {
                    return `${x.polygraphType.toUpperCase()}(${getAgencyEnumValueFromKey(
                        x?.clearance?.agency,
                    )})`;
                });
            if (activePolygraphs.length > 0) {
                const header = `ACTIVE POLYGRAPH${activePolygraphs.length > 1 ? 'S' : ''}:`;
                return {
                    label: `${header} ${activePolygraphs.join(' & ')}`,
                    backgroundColor: SharedColors.cyanBlue10,
                    maxLength: 105,
                };
            }
        }
        return undefined;
    }

    async function upsertPolygraphRecord(record: IPolygraphRecord): Promise<boolean> {
        try {
            const result = await ClearanceClient.upsertPolygraph(authContext, record);
            const newPolygraphs = props.polygraphs.filter(
                (x) => result.findIndex((y) => x.id !== y.id) > -1,
            );
            props.setPolygraphs([...result, ...newPolygraphs]);
            if (result.length > 0) {
                props.setUpdatedPolygraph(result[0]);
            }
            return true;
        } catch (e) {
            console.error('error on upsert', e);
            return false;
        }
    }

    async function deletePolygraphRecord(id: string): Promise<boolean> {
        try {
            await ClearanceClient.deletePolygraph(authContext, id);
            const newPolygraphs = props.polygraphs.filter((x) => x.id !== id);
            props.setPolygraphs(newPolygraphs);
            props.setUpdatedPolygraph(undefined);
            return true;
        } catch (e) {
            console.error('error on delete', e);
            return false;
        }
    }

    return (
        <>
            {props.employee && (
                <ContainerWithEtiquettes
                    leftEtiquetteLabel='Polygraph'
                    rightEtiquette={createRightEtiquette(props.polygraphs)}
                    bottomInfo={
                        <PolygraphDetails
                            buttonIcon='AddToShoppingList'
                            buttonText='Add'
                            modalTitle={`Add Polygraph`}
                            employee={props.employee}
                            upsertRecordFunction={upsertPolygraphRecord}
                            clearances={props.clearances}
                        />
                    }>
                    {!props.isFetchingData && props.polygraphs.length === 0 ? (
                        <p className={commonPersonnelPersonnelStyles.noDataParagraphMorePadding}>
                            No polygraph information to display
                        </p>
                    ) : (
                        <Table<IPolygraphRecord>
                            rows={props.polygraphs}
                            isFetchingData={props.isFetchingData}
                            shimmerLines={2}
                            tableColumns={generateColumns(
                                props.employee,
                                upsertPolygraphRecord,
                                deletePolygraphRecord,
                                props.clearances,
                            )}
                            tableName='Polygraph Records'
                        />
                    )}
                </ContainerWithEtiquettes>
            )}
        </>
    );
}

function generateColumns(
    employee: IEmployeeWithEditableData,
    upsertRecord: { (record: IPolygraphRecord): Promise<boolean> },
    deleteRecord: { (id: string): Promise<boolean> },
    clearances: IClearanceRecord[],
): IColumn[] {
    const columnWidths = getDefaultColumnWidths(7);
    return [
        {
            key: 'polygraph_type',
            name: 'Type',
            ariaLabel: 'Type',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            styles: { cellTitle: { paddingLeft: firstColumnTitlePadding } }, // DetailsList applies this to the header cell.
            onRender: (row: IPolygraphRecord): JSX.Element => (
                <TableCell style={{ paddingLeft: firstColumnTableCellPadding }}>
                    <EllipsisText text={row.polygraphType} textLengthBeforeEllipsis={9} />
                </TableCell>
            ),
        },
        {
            key: 'polygraph_test',
            name: 'Test',
            ariaLabel: 'Test',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true, // Style is taken from get-candidates-table-columns.tsx#getStatusLabel()
            onRender: (row: IPolygraphRecord): JSX.Element => (
                <TableCell>{dateToLocalDate(row.testDateUTCMilliseconds)}</TableCell>
            ),
        },
        {
            key: 'polygraph_clearance',
            name: 'Clearance',
            ariaLabel: 'Clearance',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: IPolygraphRecord): JSX.Element => (
                <TableCell>
                    <EllipsisText
                        text={getClearanceLevelAndAgencyTitle(row.clearance)}
                        textLengthBeforeEllipsis={11}
                    />
                </TableCell>
            ),
        },
        {
            key: 'polygraph_contract',
            name: 'Contract',
            ariaLabel: 'Contract',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: IPolygraphRecord): JSX.Element => (
                <TableCell>
                    <EllipsisText text={row?.clearance?.contractId} textLengthBeforeEllipsis={10} />
                </TableCell>
            ),
        },
        {
            key: 'polygraph_completion',
            name: 'Completion',
            ariaLabel: 'Completion',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: IPolygraphRecord): JSX.Element => (
                <TableCell>{dateToLocalDate(row.completionDateUTCMilliseconds)}</TableCell>
            ),
        },
        {
            key: 'polygraph_result',
            name: 'Result',
            ariaLabel: 'Result',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true, // Style is taken from get-candidates-table-columns.tsx#getStatusLabel()
            onRender: (row: IPolygraphRecord): JSX.Element => (
                <TableCell>{polygraphResultSpan(row)}</TableCell>
            ),
        },
        {
            key: 'polygraph_action',
            name: 'Action',
            ariaLabel: 'Action',
            minWidth: columnWidths.actionColumnWidth,
            maxWidth: columnWidths.actionColumnWidth,
            isRowHeader: true,
            onRender: (row: IPolygraphRecord): JSX.Element => (
                <TableCell>
                    <PolygraphDetails
                        buttonIcon='EntryView'
                        polygraph={row}
                        buttonText='View'
                        modalTitle={`Polygraph: ${row.polygraphType?.toLocaleUpperCase()}`}
                        employee={employee}
                        upsertRecordFunction={upsertRecord}
                        deleteRecordFunction={deleteRecord}
                        clearances={clearances}
                    />
                </TableCell>
            ),
        },
    ];
}
