import { Dictionary } from 'assets/constants/global-constants';
import GroupClient, { IGroup } from 'clients/group-client';
import { IPagedResults } from 'clients/http-options';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import React, { createContext, ReactNode, SetStateAction, useContext, useState } from 'react';
import { SetStateFunc } from 'types/global-types';
import { useFetchSimple, useToggle } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';

export type ManageGroupsVariableContextType = {
    groups: IGroup[];
    groupsDict: Dictionary<IGroup>; // Key: groupId
    isInitialGroupsLoad: boolean;
    errorFetchingGroups: string;
    maxMemberCount: number | undefined;
    setGroups: SetStateFunc<IGroup[]>;
    deleteRow: (groupId: string) => void;
    setMaxMemberCount: (value: number | undefined) => void;
};

export const ManageGroupsVariableContext = createContext<ManageGroupsVariableContextType>(null!);

export interface IManageGroupsProviderProps {
    children: ReactNode;
}

export default function ManageGroupsVariableProvider(
    props: IManageGroupsProviderProps,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const [groups, setGroups] = useState<IGroup[]>([]);
    const [groupsDict, setGroupsDict] = useState<Dictionary<IGroup>>({});
    const [maxMemberCount, setMaxMemberCount] = useState<number | undefined>(undefined);

    const [isTriggerFetch, triggerFetch] = useToggle(false);
    const [isInitialGroupsLoad, setIsInitialGroupsLoad] = useState<boolean>(true);
    const [errorFetchingGroups, setErrorFetchingGroups] = useState<string>('');
    const [continuationToken, setContinuationToken] = useState<string | undefined>();

    const contextValue = {
        groups,
        groupsDict,
        isInitialGroupsLoad,
        errorFetchingGroups,
        maxMemberCount,
        setGroups: (value: SetStateAction<IGroup[]>): void => {
            setGroups(value);
        },
        deleteRow: (groupId: string): void => {
            setGroups((currentValue) => currentValue.filter((group) => group.id !== groupId));
        },
        setMaxMemberCount: (value: number | undefined): void => {
            setMaxMemberCount(value);
        },
    };

    /**
     * Both "Manage Groups" and "Manage Group" need this context because
     * they both need to get list of all groups, which is variable "groups".
     * In order to refactor fetching them, I fetch them here. As for other
     * variables, I left it to the consuming module to fetch them.
     */

    useFetchSimple<IPagedResults<IGroup>>({
        dependencies: [isTriggerFetch],
        canPerformFetch: authContext.isInRole(Role.GroupAdmin),
        fetchFunc: async () => GroupClient.getGroups(authContext, continuationToken),
        onSuccess: (result) => {
            setGroups((currentValue) => currentValue.concat(result.results));
            setGroupsDict((currentValue) => {
                result.results.forEach((group) => {
                    currentValue[group.id] = group;
                });
                return currentValue;
            });
            setMaxMemberCount(
                Math.max(
                    maxMemberCount ?? 0,
                    ...result.results.map((group) => group.metrics?.memberCount ?? 0),
                ),
            );
            setContinuationToken(result.continuationToken);
            setIsInitialGroupsLoad(false);
            // Continuously load until all records are fetched.
            if (result.continuationToken) {
                triggerFetch();
            }
        },
        onError: () => {
            setErrorFetchingGroups('Error loading groups');
        },
        onFinally: doNothing,
    });

    return (
        <ManageGroupsVariableContext.Provider value={contextValue}>
            {props.children}
        </ManageGroupsVariableContext.Provider>
    );
}
