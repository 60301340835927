import React, { useContext, useState } from 'react';
import { AuthContext } from 'contexts/auth-context';
import GroupClient from 'clients/group-client';
import { useFetchSimple, useIsMounted } from 'utils/misc-hooks';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { doNothing } from 'utils/misc-utils';

interface IApplicationGroupDisplayProps {
    groupId: string;
    groupName: string;
    applicationId: string;
}

export default function ApplicationGroupDisplay(props: IApplicationGroupDisplayProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [applicationName, setApplicationName] = useState<string>('');

    const isMounted = useIsMounted();

    useFetchSimple<string>({
        dependencies: [props.groupId, props.applicationId],
        canPerformFetch: !!props.groupId && !!props.applicationId,
        fetchFunc: async () => {
            return GroupClient.getApplicationName(authContext, props.groupId, props.applicationId);
        },
        onSuccess: (result) => {
            if (isMounted()) {
                setApplicationName(result);
            }
        },
        onError: (e) => {
            console.error(
                `Could not obtain name of application "${props.applicationId}" on group "${props.groupName}":`,
                e,
            );
        },
        onFinally: doNothing,
    });

    if (!!applicationName) {
        return (
            <>
                <span>{applicationName}</span>
                <span>&nbsp;</span>
                <EllipsisTextCss text={props.applicationId} />
            </>
        );
    } else {
        return <EllipsisTextCss text={props.applicationId} />;
    }
}
