import React from 'react';
import { FontSizes, FontWeights, mergeStyles } from '@fluentui/react';
import { ScreeningPaths } from 'components/screening/common/common-constants';

const titleStyle = mergeStyles({
    fontSize: FontSizes.large,
    fontWeight: FontWeights.semibold,
    h4: { marginBottom: '0px' },
});

export interface CandidateNominationStepNominationRequirementsProps {
    screeningPath: ScreeningPaths;
}

export default function CandidateNominationStepNominationRequirements(
    props: CandidateNominationStepNominationRequirementsProps,
): JSX.Element {
    let strings = [''];

    switch (props.screeningPath) {
        case ScreeningPaths.UsGov:
            strings = [
                'Government contract ID',
                "Candidate's personal phone, email, and home address",
                'Clearance details including level and badge requirements',
            ];
            break;
        case ScreeningPaths.PublicTrust:
            strings = [
                'Government contract ID',
                'Public Trust details including type and badge requirements',
            ];
    }

    return (
        <>
            <div>
                <h2 className={titleStyle}>What you will need</h2>
            </div>
            <p>Make sure you have the following information available:</p>
            <ul style={{ paddingInlineStart: 15 }}>
                {strings.map((string, index) => {
                    return <li key={index}>{string}</li>;
                })}
            </ul>
        </>
    );
}
