import { ICheckAccess, ICheckAccessProps } from 'components/common/check-access';
import { CheckAccessType, UserContext } from 'contexts/user-context';
import React, { LegacyRef, useEffect, useState } from 'react';
import { useContext } from 'react';
import { Redirect } from 'react-router-dom';

export interface IScreeningCheckAccessProps extends ICheckAccess {
    /**
     * Collection of Roles and User Types that are allowed access.
     */
    requiredAccessTypeAny?: CheckAccessType[];

    /**
     * Allow access when any of the items in this array are true.
     */
    hasRequiredPermissionsAny?: boolean[];

    hasRequiredAsyncPermissions?: Promise<boolean>[];
}

/**
 * Permit or Deny access based upon provided roles, user types, and permissions.
 */
const ScreeningCheckAccess = React.forwardRef(
    (
        props: IScreeningCheckAccessProps,
        // "ref" will become a ref to the top-most <div> of this component.
        ref: LegacyRef<HTMLDivElement> | undefined,
    ): JSX.Element => {
        const userContext = useContext(UserContext);
        const [isAccessGranted, setAccessGranted] = useState<boolean>(false);
        const [isRedirect, setRedirect] = useState<boolean>(false);

        useEffect(() => {
            const hasAccess = async (): Promise<boolean> => {
                // Check arbitrary conditions to determine if permission should be granted.
                if (
                    props.hasRequiredPermissionsAny &&
                    props.hasRequiredPermissionsAny?.some((condition) => condition)
                ) {
                    return true;
                }

                if (
                    props.hasRequiredAsyncPermissions &&
                    props.hasRequiredAsyncPermissions.length > 0
                ) {
                    const isPermissionGranted = await Promise.all(
                        props.hasRequiredAsyncPermissions,
                    ).then((results) => {
                        if (results) {
                            return results.some((condition) => condition);
                        }
                        return false;
                    });

                    return isPermissionGranted;
                }

                // Check Access Types.
                if (props.requiredAccessTypeAny) {
                    const hasAccess = await userContext.screeningHasAccessAny(
                        props.requiredAccessTypeAny,
                    );
                    if (hasAccess) {
                        return true;
                    }
                }

                return false;
            };
            async function checkAccess(): Promise<void> {
                const hasAccessBeenGranted = await hasAccess();
                setAccessGranted(hasAccessBeenGranted);
                if (!hasAccessBeenGranted) {
                    setRedirect(true);
                }
            }
            if (props.arePermissionsChecked && !isAccessGranted) {
                checkAccess();
            }
        }, [
            props.arePermissionsChecked,
            props.hasRequiredAsyncPermissions,
            props.hasRequiredPermissionsAny,
            props.requiredAccessTypeAny,
        ]);

        const determineContent = (): JSX.Element => {
            if (isAccessGranted) {
                return <>{props.children}</>;
            } else if (props.arePermissionsChecked) {
                if (props.accessDeniedContent) return <>{props.accessDeniedContent}</>;
                // Show nothing. This is suitable for hiding parts of the page.
                // Example: Hiding action buttons for users who don't have
                // permission to edit.
                else return <></>;
            } else {
                // Stay silent and just show nothing until permissions are checked
                return <></>;
            }
        };

        if (isRedirect) {
            return (
                <div>
                    <Redirect to={props.accessDeniedRedirect} />
                </div>
            );
        }

        return <div ref={ref}>{determineContent()}</div>;
    },
);

export default ScreeningCheckAccess;
export interface IAccessDeniedContentProps {
    msg: string;
}
