/**
 * File Name: view-document-modal
 * Team: Personnel - Screening Team
 * Summary: This button and modal is used when the User views a 'Uploaded document by Screening Id's User' in the
 * Document table row of Screening Details page.
 * Use Cases:
 * 1) Allows NST to 'Request Update' to file uploaded by user via 'UPDATES REQUESTED'
 * 2) Allows NST to 'Lock' the file uploaded by user via 'Lock'
 * 3) Allows Screening's User to view Request Update message and upload to the file
 * 4) Allows NST and Screening's User to download the currently uploaded file.
 * Last Updated Summary: 10/7/2022 by andreli
 **/
import React, {
    useState,
    useContext,
    useEffect,
    MutableRefObject,
    useRef,
    ChangeEvent,
    useMemo,
} from 'react';
import {
    mergeStyleSets,
    Modal,
    IModalProps,
    DefaultButton,
    Icon,
    FontWeights,
    FontSizes,
    ActionButton,
    ProgressIndicator,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { containsNSTUser, containsNomineeUser } from 'components/screening/us-gov/IScreening';
import ActivitiesClient, { IActivity, IActivityToken } from 'clients/activities-client';
import DocumentsClient, { IDocumentRecord, IDocumentToken } from 'clients/documents-client';
import TemplateClient, {
    IDocumentMetadata,
    IScreeningTemplateRequestRecord,
    ScreeningTemplateRequestedState,
    isIScreeningTemplateRequestRecordRequestingUpdate,
} from 'clients/template-client';
import Event, {
    ActivityEventBin,
    binSortEvents,
    findDocumentViewerEvents,
} from 'components/common/event';
import {
    checkIfWordDocument,
    changeFileName,
    isValidFileType,
    acceptedFileTypesForTemplate,
    acceptedFileTypesTextForTemplate,
    getFileType,
    fileInputAcceptStringForTemplate,
} from 'utils/file-utils';
import { dateToLocalLongDateFormat } from 'utils/time-utils';
import { AuthContext } from 'contexts/auth-context';
import PublicTrustDocumentClient from 'clients/screening/public-trust-document-client';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import UpdateRequestModal from 'components/screening/common/documents/modals/update-request-modal';
import UsGovScreeningClient, {
    ApplicationServices,
} from 'clients/screening/us-gov-screening-client';
import {
    DocumentSourceType,
    DocumentTableStatuses,
    IUnifiedDocumentRecord,
} from 'components/screening/common/documents/document-common';

export interface ViewUploadedDocumentModalProps {
    onFileUpdate?: () => void;
    onUpdateRequest?(
        request: IScreeningTemplateRequestRecord,
        newDocumentTableStatus?: DocumentTableStatuses,
    ): void;
    onDocumentLockorUnlock?(
        doc: IDocumentRecord,
        newDocumentTableStatus?: DocumentTableStatuses,
    ): void;
    documentRecord: IUnifiedDocumentRecord;
    documentMetadata: IDocumentMetadata[] | undefined;
    eventBins?: ActivityEventBin[];
    isRequestDocument: boolean;
    isUserCandidate: boolean;
    screeningEventBins?: ActivityEventBin[];
    screeningId?: string;
    screeningPath?: ScreeningPaths;
    templateRequestRecords?: IScreeningTemplateRequestRecord[] | undefined;
    userTypes?: string[];
    getLatestAssociatedToken?: () => Promise<IDocumentToken>;
}

enum DocumentStatus {
    LOADING = 'loading',
    DOWNLOADING = 'downloading',
    SUCCESS = 'success',
    ERROR = 'error',
}

enum UploadStatus {
    UPLOADING = 'uploading',
    SUCCESS = 'success',
    ERROR = 'error',
}

function getMetadataFromDocument(
    documentRecord: IDocumentRecord,
    documentMetadata?: IDocumentMetadata[],
): IDocumentMetadata | undefined {
    if (documentMetadata) {
        return documentMetadata.find((x) => x.title === documentRecord.fileName);
    }
}

function ViewUploadedDocumentModal(props: ViewUploadedDocumentModalProps): JSX.Element {
    const {
        documentMetadata,
        documentRecord,
        isRequestDocument,
        isUserCandidate,
        onUpdateRequest,
        onFileUpdate,
        screeningId,
        screeningPath,
        templateRequestRecords,
        userTypes,
    } = props;
    const documentId = documentRecord.id;
    const downloadLink: MutableRefObject<HTMLAnchorElement | null> = useRef(null);
    const [documentUrl, setDocumentUrl] = useState<string>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isUpdateRequestModalOpen, setIsUpdateRequestModalOpen] = useState<boolean>(false);
    const authContext = useContext(AuthContext);
    const [isWordDocument, setIsWordDocument] = useState<boolean>(
        checkIfWordDocument(documentRecord.fileMimeType),
    );

    const [eventBins, setBinsEvents] = useState<ActivityEventBin[]>();
    const [documentStatus, setDocumentStatus] = useState<DocumentStatus>(DocumentStatus.LOADING);
    const [templateRequestRecord, setTemplateRequestRecord] = useState<
        IScreeningTemplateRequestRecord | undefined
    >();
    const [documentMetadataRecord, setDocumentMetadataRecord] = useState<IDocumentMetadata>();
    const [uploadFile, setUploadFile] = useState<File>();
    const [uploadStatus, setUploadStatus] = useState<UploadStatus>();
    const [isInvalidFile, setIsInvalidFile] = useState<boolean>(true);
    const [isLockedBtnDisabled, setIsLockBtnDisabled] = useState<boolean>(false);
    const [isDocumentLocked, setIsDocumentLocked] = useState<boolean>(
        DocumentsClient.isDocumentLocked(props.documentRecord),
    );
    const [isDeleted] = useState<boolean>(DocumentsClient.isDocumentDeleted(props.documentRecord));
    const [isUpdateRequested, setIsUpdateRequested] = useState<boolean>(false);

    const [canDownload, setCanDownload] = useState<boolean>(false);
    const [activityPanelIconName, setActivityPanelIconName] = useState<string>();

    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        const localDocumentMetadataRecord = getMetadataFromDocument(
            documentRecord,
            documentMetadata,
        );
        setDocumentMetadataRecord(localDocumentMetadataRecord);
        const requestRecord = templateRequestRecords?.find(
            (x) => x.documentId === localDocumentMetadataRecord?.documentId,
        );
        if (requestRecord !== undefined) {
            setTemplateRequestRecord(requestRecord);
        }
        const isLocked = DocumentsClient.isDocumentLocked(props.documentRecord);
        setIsDocumentLocked(isLocked);
    }, [documentId, documentRecord, documentMetadata]);

    useEffect(() => {
        if (isDeleted) {
            setActivityPanelIconName('PageRemove');
        } else {
            setActivityPanelIconName(getLockUnlockIconName(isDocumentLocked));
        }
    }, [isDeleted, isDocumentLocked]);

    useEffect(() => {
        if (templateRequestRecord) {
            setIsUpdateRequested(
                isIScreeningTemplateRequestRecordRequestingUpdate(templateRequestRecord),
            );
            if (props.screeningEventBins) {
                prepareActivityEvents(
                    findDocumentViewerEvents(
                        props.screeningEventBins,
                        templateRequestRecord,
                        documentRecord,
                    ),
                );
            }
        } else {
            setErrorMessage('Document Request Metadata not found.');
        }
    }, [templateRequestRecord]);

    useEffect(() => {
        if (isOpen) {
            if (templateRequestRecord && documentRecord && props.screeningEventBins) {
                prepareActivityEvents(
                    findDocumentViewerEvents(
                        props.screeningEventBins,
                        templateRequestRecord,
                        documentRecord,
                    ),
                );
            } else {
                getLatestEvents();
            }
            setErrorMessage(undefined);
        }
    }, [props.screeningEventBins, templateRequestRecord, documentRecord, isOpen]);

    async function prepareActivityEvents(sortedBins: ActivityEventBin[]): Promise<void> {
        // Insert Document Notes into timeline for Documents Viewer.
        if (sortedBins?.length !== 0 && !!props.documentRecord?.documentNotes) {
            // Convert Document Note into Activity object.
            const documentNoteActivityBin = { ...sortedBins[0] };
            const documentNoteActivity = { ...documentNoteActivityBin.activities[0] };
            documentNoteActivity.id = crypto.randomUUID();
            documentNoteActivity.event = 'document-added-notes';
            documentNoteActivity.directObject = {
                type: 'DocumentNotes',
                value: props.documentRecord.documentNotes,
            };

            documentNoteActivityBin.activities.splice(1, 0, documentNoteActivity);
            sortedBins[0] = documentNoteActivityBin;
        }

        setBinsEvents(sortedBins);
    }

    async function getLatestEvents(): Promise<void> {
        if (screeningId !== undefined && screeningId !== '' && screeningPath !== undefined) {
            // get the event timeline for Screening Details page
            if (props.screeningEventBins !== undefined && props.screeningEventBins?.length > 0) {
                const newEventBins: IActivity[] = [];
                props.screeningEventBins?.forEach((activityEvents) => {
                    if (activityEvents.activities.length !== 0) {
                        activityEvents.activities.forEach((activity) => {
                            if (activity.directObject.value === documentId) {
                                newEventBins.push(activity);
                            } else if (activity.referenceId === documentId) {
                                newEventBins.push(activity);
                            } else if (
                                activity.directObject.value === props.documentRecord.documentTitle
                            ) {
                                // add documents history that match the title
                                newEventBins.push(activity);
                            }
                        });
                    }
                });

                const sortedBins = binSortEvents(newEventBins);
                prepareActivityEvents(sortedBins);
            }
        }
    }

    function getLockUnlockIconName(isLock: boolean): string {
        return isLock ? 'Lock' : 'UnLock';
    }

    async function onUploadComplete(
        request: IScreeningTemplateRequestRecord | undefined,
    ): Promise<void> {
        if (request && onUpdateRequest) {
            request.requestedState = ScreeningTemplateRequestedState.UploadedState;
            try {
                let updatedRequest: IScreeningTemplateRequestRecord[] = [];
                if (screeningPath === ScreeningPaths.UsGov) {
                    updatedRequest = await TemplateClient.updateMultipleDocuments(authContext, [
                        request,
                    ]);
                } else if (screeningPath === ScreeningPaths.PublicTrust) {
                    updatedRequest = await PublicTrustDocumentClient.updateMultipleDocuments(
                        authContext,
                        [request],
                    );
                }

                onUpdateRequest(updatedRequest[0], DocumentTableStatuses.WithNST);
            } catch (e) {
                console.error('fail to upload complete', e);
            }
        }
    }

    async function onButtonLockApi(): Promise<void> {
        if (templateRequestRecord && onUpdateRequest) {
            const updatedTemplateRecord: IScreeningTemplateRequestRecord = {
                ...templateRequestRecord,
                requestedState: ScreeningTemplateRequestedState.ReRequestedCancel,
            };
            try {
                let result: IScreeningTemplateRequestRecord[] = [];

                if (screeningPath === ScreeningPaths.UsGov) {
                    result = await TemplateClient.updateMultipleDocuments(authContext, [
                        updatedTemplateRecord,
                    ]);
                } else if (screeningPath === ScreeningPaths.PublicTrust) {
                    result = await PublicTrustDocumentClient.updateMultipleDocuments(authContext, [
                        updatedTemplateRecord,
                    ]);
                }
                const resultValue = result.pop();
                if (resultValue) {
                    onUpdateRequest(resultValue, DocumentTableStatuses.Completed);
                }
            } catch (err) {
                setErrorMessage(
                    `Error attempting to update request status ${templateRequestRecord.id}`,
                );
            }
        }
    }

    async function onRequestUpdateBtnClick(): Promise<void> {
        if (
            templateRequestRecord?.requestedState ===
                ScreeningTemplateRequestedState.UploadedState ||
            templateRequestRecord?.requestedState ===
                ScreeningTemplateRequestedState.ReRequestedState ||
            templateRequestRecord?.requestedState === ScreeningTemplateRequestedState.Fulfilled
        ) {
            // update requested
            setIsUpdateRequestModalOpen(!isUpdateRequestModalOpen);
        }
    }

    async function onRequestLockBtnClick(): Promise<void> {
        setIsLockBtnDisabled(true);
        try {
            if (screeningId === undefined || screeningPath === undefined) {
                throw new Error(
                    `Unable to perform document lock. Document Viewer Screening properties screeningId: ${screeningId} and screeningPath: ${screeningPath}.`,
                );
            }

            const documentToken = await DocumentsClient.getDocumentToken(
                authContext,
                screeningId,
                screeningPath,
            );

            if (!documentToken || !documentToken.token) {
                throw new Error(
                    'unable to retrieve token for ' + isDocumentLocked
                        ? 'unlocking document'
                        : 'locking document',
                );
            }

            const result = await DocumentsClient.lockDocument(
                authContext,
                documentId,
                !isDocumentLocked,
                documentToken,
            );
            const isLocked = DocumentsClient.isDocumentLocked(result);
            setIsDocumentLocked(isLocked);
            if (
                isLocked &&
                templateRequestRecord?.requestedState ===
                    ScreeningTemplateRequestedState.ReRequestedState
            ) {
                await onButtonLockApi();
            }
            if (props.onDocumentLockorUnlock) {
                props.onDocumentLockorUnlock(
                    result,
                    isLocked ? DocumentTableStatuses.Completed : DocumentTableStatuses.WithNST,
                );
            }
        } catch (e) {
            const errorMessage = e.message
                ? e.message
                : isDocumentLocked
                ? 'unlocking document'
                : 'locking document';
            setErrorMessage(`Error ${errorMessage} file: ${props.documentRecord.fileName}`);
        }
        setIsLockBtnDisabled(false);
    }

    async function onCancelOrSubmitUpdateRequestModal(
        updatedTemplateData: IScreeningTemplateRequestRecord,
        isCancel: boolean,
    ): Promise<void> {
        setTemplateRequestRecord(updatedTemplateData);

        if (onUpdateRequest) {
            onUpdateRequest(
                updatedTemplateData,
                isCancel ? DocumentTableStatuses.WithNST : DocumentTableStatuses.WithNominee,
            );
        }
    }

    const modalProps: IModalProps = {
        isOpen: isOpen,
        onDismiss: (): void => {
            setIsOpen(false);
        },
        onDismissed: () => {
            setIsOpen(false);

            if (onFileUpdate) {
                onFileUpdate();
            }
        },
        styles: modalStyles,
    };

    const openModal = (): void => {
        setIsOpen(true);
        if (!documentUrl) {
            if (!!screeningId && screeningPath !== undefined) {
                handleGettingScreeningDocument(screeningId);
            } else {
                handleGettingDocument();
            }
        }
    };

    async function handleGettingDocument(): Promise<void> {
        try {
            let activitiesToken: IActivityToken | undefined = undefined;
            if (!!eventBins) {
                const docEventsBin = eventBins
                    ?.map((bin: ActivityEventBin) => {
                        const filteredActivities = bin.activities.filter((activity: IActivity) => {
                            return activity.referenceId === documentId;
                        });
                        return { key: bin.key, activities: filteredActivities };
                    })
                    .filter((bin: ActivityEventBin) => {
                        return bin.activities.length > 0;
                    });

                prepareActivityEvents(docEventsBin);
            } else {
                activitiesToken = await ActivitiesClient.getDocumentActivityToken(
                    authContext,
                    documentId,
                );
                // Attempt to get document activities
                const actvities = await ActivitiesClient.getAllDocumentActivitiesForDocumentId(
                    authContext,
                    documentId,
                    activitiesToken,
                );
                const sortedBins = binSortEvents(actvities);
                prepareActivityEvents(sortedBins);
            }

            let documentToken: IDocumentToken | undefined = undefined;
            if (
                documentRecord.associatedUserId !== undefined &&
                documentRecord.associatedUserId !== ''
            ) {
                documentToken = await UsGovScreeningClient.getAssociatedDocumentUserToken(
                    authContext,
                    documentRecord.associatedUserId,
                    ApplicationServices.profile,
                );
            } else if (activitiesToken !== undefined) {
                documentToken = activitiesToken;
            } else {
                documentToken = await ActivitiesClient.getDocumentActivityToken(
                    authContext,
                    documentId,
                );
            }

            const document = await DocumentsClient.downloadDocument(
                authContext,
                documentId,
                documentToken.token,
            );
            const url: string = window.webkitURL.createObjectURL(
                new Blob([document], { type: documentRecord.fileMimeType }),
            );
            setDocumentUrl(url);
            setDocumentStatus(DocumentStatus.SUCCESS);
            setCanDownload(true);
        } catch (e) {
            setCanDownload(false);
            if (!isDeleted) {
                setErrorMessage('File download error');
                setDocumentStatus(DocumentStatus.ERROR);
            } else {
                setDocumentStatus(DocumentStatus.SUCCESS);
            }
        }
    }

    async function handleGettingScreeningDocument(screeningId: string): Promise<void> {
        try {
            const documentToken = props.getLatestAssociatedToken
                ? (await props.getLatestAssociatedToken()).token
                : (
                      await UsGovScreeningClient.getAssociatedDocumentUserToken(
                          authContext,
                          screeningId,
                          ApplicationServices.screening,
                      )
                  ).token;
            const document = await DocumentsClient.downloadDocument(
                authContext,
                documentId,
                documentToken,
            );
            const url: string = createObjectURL(document, documentRecord.fileMimeType);
            setDocumentUrl(url);
            setDocumentStatus(DocumentStatus.SUCCESS);
            setCanDownload(true);
        } catch (e) {
            setCanDownload(false);
            if (!isDeleted) {
                setErrorMessage('File download error');
                setDocumentStatus(DocumentStatus.ERROR);
            } else {
                setDocumentStatus(DocumentStatus.SUCCESS);
            }
        }
    }

    function createObjectURL(document: Blob, type: string): string {
        if (window.webkitURL) {
            return window.webkitURL.createObjectURL(new Blob([document], { type: type }));
        } else if (window.URL && window.URL.createObjectURL) {
            return window.URL.createObjectURL(
                new Blob([document], { type: documentRecord.fileMimeType }),
            );
        } else {
            return '';
        }
    }

    async function download(): Promise<void> {
        if (documentUrl) {
            setDocumentStatus(DocumentStatus.DOWNLOADING);
            initiateBrowserDownload();
            setDocumentStatus(DocumentStatus.SUCCESS);
        }
    }

    function initiateBrowserDownload(): void {
        const downloadLinkRef: HTMLAnchorElement = downloadLink.current as HTMLAnchorElement;
        downloadLinkRef.href = documentUrl!;
        downloadLinkRef.download = documentRecord.fileName || '';
        downloadLinkRef.click();
        downloadLinkRef.href = '';
        downloadLinkRef.download = '';
    }

    async function onFileChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        if (event.target.files) {
            let file: File = event.target.files[0];
            if (file?.size === 0) {
                setIsInvalidFile(true);
                setErrorMessage('Invalid file: File is empty.');
            } else if (file && documentMetadataRecord) {
                setErrorMessage(undefined);
                file = changeFileName(file, documentMetadataRecord.title);
                const isValid: boolean = await isValidFileType(file, acceptedFileTypesForTemplate);
                if (isValid) {
                    setUploadFile(file);
                    setIsInvalidFile(false);
                } else {
                    setIsInvalidFile(true);
                    setErrorMessage(`Invalid file type. ${acceptedFileTypesTextForTemplate}`);
                }
            }
        }
    }

    async function upload(): Promise<void> {
        if (uploadFile && documentId) {
            setUploadStatus(UploadStatus.UPLOADING);
            try {
                await DocumentsClient.updateDocument(authContext, uploadFile, documentId);
                setErrorMessage(undefined);
                setUploadStatus(UploadStatus.SUCCESS);
                await onUploadComplete(templateRequestRecord);
                const url: string = window.webkitURL.createObjectURL(
                    new Blob([uploadFile], { type: uploadFile.type }),
                );
                setDocumentUrl(url);
                setUploadFile(undefined);
                setCanDownload(true);
                const uploadFileType: string = await getFileType(uploadFile);
                setIsWordDocument(checkIfWordDocument(uploadFileType));
            } catch (error) {
                let errMsg = 'File upload error';
                if (isDocumentLocked && error.status === 403) {
                    errMsg = 'Document cannot be uploaded while locked.';
                }
                setErrorMessage(errMsg);
                setUploadStatus(UploadStatus.ERROR);
                setUploadFile(undefined);
                setCanDownload(true);
            }
        }
    }

    const canUpdateRequest: boolean = useMemo(() => {
        return containsNSTUser(userTypes) && !isDocumentLocked && !isUserCandidate;
    }, [userTypes, isDocumentLocked, isUserCandidate]);

    const isNotAdhocDocument: boolean = useMemo(() => {
        return props.documentRecord.documentSourceType !== DocumentSourceType.Adhoc;
    }, [props.documentRecord, props.documentRecord.documentSourceType, props.documentRecord.id]);

    return (
        <>
            <ActionButton onClick={openModal} iconProps={{ iconName: 'TextDocument' }}>
                View
            </ActionButton>
            <Modal {...modalProps}>
                <div className={styles.body}>
                    <div className={styles.documentFrame}>
                        <div className={styles.document}>
                            {documentStatus === DocumentStatus.LOADING && <p>Loading...</p>}
                            {!isWordDocument && documentUrl && (
                                <iframe src={documentUrl} title={documentRecord.fileName} />
                            )}
                            {isWordDocument && (
                                <>
                                    <Icon iconName='WordDocument' />
                                    <p>
                                        This document cannot be securely viewed in the browser.
                                        Please download to view.
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={styles.activityPanel}>
                        <div className={styles.activityPanelHeader}>
                            <div className={styles.title}>{documentRecord.fileName}</div>
                            {props.isRequestDocument && <Icon iconName={activityPanelIconName} />}
                        </div>
                        {isDeleted && <div className={styles.statusBadge}>DELETED</div>}
                        {isDocumentLocked && <div className={styles.lockStatusBadge}>LOCKED</div>}
                        {isUpdateRequested && (
                            <div className={styles.statusBadge}>UPDATES REQUESTED</div>
                        )}

                        {eventBins &&
                            eventBins.map((obj: { key: number; activities: IActivity[] }) => {
                                return (
                                    <React.Fragment key={`event_datebin_${obj.key}`}>
                                        <div className={styles.eventDateRow}>
                                            <div className={styles.eventLine}></div>
                                            <div className={styles.eventRowGap}></div>
                                            <div>{dateToLocalLongDateFormat(obj.key)}</div>
                                        </div>
                                        {obj.activities.map((activity: IActivity) => (
                                            <Event
                                                key={`event_${activity.id}`}
                                                activity={activity}
                                                screeningId={screeningId ?? ''}
                                                screeningPath={screeningPath}
                                                provideDocumentsNameForDocumentAppActivities
                                            />
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                    </div>
                </div>

                {errorMessage && (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        onDismiss={(): void => setErrorMessage(undefined)}
                        dismissButtonAriaLabel='Close'>
                        {errorMessage}
                    </MessageBar>
                )}

                {(documentStatus === DocumentStatus.LOADING ||
                    documentStatus === DocumentStatus.DOWNLOADING ||
                    uploadStatus === UploadStatus.UPLOADING) && (
                    <ProgressIndicator styles={progressBarIndicatorStyles} barHeight={1.5} />
                )}

                <div className={styles.footer}>
                    <div className={styles.actionButtons}>
                        <DefaultButton
                            disabled={!canDownload}
                            iconProps={{ iconName: 'Download' }}
                            onClick={download}>
                            {documentStatus === DocumentStatus.DOWNLOADING
                                ? 'Downloading...'
                                : 'Download'}
                        </DefaultButton>
                        <a ref={downloadLink} hidden={true} />
                        {userTypes &&
                            containsNSTUser(userTypes) &&
                            !isUserCandidate &&
                            isNotAdhocDocument && (
                                <>
                                    <DefaultButton
                                        iconProps={{
                                            iconName: getLockUnlockIconName(!isDocumentLocked),
                                        }}
                                        disabled={isLockedBtnDisabled || props.isUserCandidate}
                                        onClick={onRequestLockBtnClick}>
                                        {!isDocumentLocked ? 'Lock Document' : 'Unlock Document'}
                                    </DefaultButton>
                                    <DefaultButton
                                        iconProps={{ iconName: 'Edit' }}
                                        onClick={onRequestUpdateBtnClick}
                                        disabled={
                                            isLockedBtnDisabled ||
                                            !templateRequestRecord ||
                                            isDocumentLocked
                                        }>
                                        {isUpdateRequested
                                            ? 'Modify Update Request'
                                            : 'Update Request'}
                                    </DefaultButton>
                                </>
                            )}
                        {isRequestDocument &&
                            userTypes &&
                            isNotAdhocDocument &&
                            (containsNSTUser(userTypes) || containsNomineeUser(userTypes)) && (
                                <DefaultButton
                                    iconProps={{ iconName: 'TextDocument' }}
                                    onClick={onRequestUpdateBtnClick}
                                    disabled={
                                        isLockedBtnDisabled ||
                                        !templateRequestRecord ||
                                        isDocumentLocked
                                    }>
                                    View Update Request
                                </DefaultButton>
                            )}
                        {isRequestDocument && onFileChange && isNotAdhocDocument && (
                            <div className={styles.uploadButton}>
                                <DefaultButton
                                    iconProps={{ iconName: 'OpenFile' }}
                                    type='file'
                                    disabled={isDocumentLocked}>
                                    {uploadFile?.name ?? 'Upload File'}
                                </DefaultButton>
                                <input
                                    className={styles.input}
                                    type='file'
                                    onChange={onFileChange}
                                    accept={fileInputAcceptStringForTemplate}
                                    disabled={isDocumentLocked}
                                />
                            </div>
                        )}
                        {uploadFile && !isInvalidFile && isNotAdhocDocument && (
                            <PrimaryButton
                                onClick={upload}
                                disabled={
                                    isDocumentLocked || uploadStatus === UploadStatus.UPLOADING
                                }>
                                {uploadStatus === UploadStatus.UPLOADING
                                    ? 'Uploading...'
                                    : 'Upload'}
                            </PrimaryButton>
                        )}
                    </div>
                    <DefaultButton onClick={(): void => setIsOpen(false)}>Close</DefaultButton>
                </div>
            </Modal>

            {isRequestDocument && screeningPath !== undefined && (
                <Modal
                    onDismiss={(): void => {
                        setIsUpdateRequestModalOpen(false);
                    }}
                    isOpen={isUpdateRequestModalOpen}>
                    <UpdateRequestModal
                        isEditable={canUpdateRequest}
                        templateData={templateRequestRecord}
                        onCancelOrSubmit={onCancelOrSubmitUpdateRequestModal}
                        onClose={(): void => setIsUpdateRequestModalOpen(false)}
                        screeningPath={screeningPath}
                    />
                </Modal>
            )}
        </>
    );
}

const modalStyles = mergeStyleSets({
    main: {
        maxWidth: '1400px',
        width: '90%',
        backgroundColor: '#F6F6F6',
    },
});

const progressBarIndicatorStyles = mergeStyleSets({
    itemProgress: {
        padding: '0',
    },
});

const styles = mergeStyleSets({
    body: {
        display: 'flex',
        flexGrow: '1',
    },
    documentFrame: {
        flexGrow: '1',
        paddingRight: '15px',
        overflow: 'hidden',
        boxSizing: 'border-box',
    },
    document: {
        backgroundColor: '#E6E6E6',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'rgb(102, 102, 102) 0px 0px 15px -5px',
        height: '100%',
        width: '100%',
        selectors: {
            iframe: {
                height: '100%',
                width: '100%',
                border: 'none',
            },
            i: {
                fontSize: FontSizes.mega,
                color: '#2b579a',
            },
            p: {
                textAlign: 'center',
                padding: '1rem',
            },
        },
    },
    activityPanel: {
        backgroundColor: '#F6F6F6',
        padding: '1rem',
        overflowY: 'auto',
        height: 'calc(80vh - 4rem)',
        boxSizing: 'border-box',
        maxWidth: '400px',
        minWidth: '340px',
    },
    activityPanelHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        selectors: {
            '> i': {
                fontSize: '1rem',
                lineHeight: '1rem',
                paddingTop: '0.3rem',
            },
        },
    },
    title: {
        fontWeight: FontWeights.semibold,
        fontSize: '1.5rem',
    },
    statusBadge: {
        display: 'inline-block',
        fontSize: '0.75rem',
        backgroundColor: 'rgb(212, 0, 0)',
        color: 'rgba(255, 255, 255, 0.867)',
        padding: '2px 7px',
        borderRadius: '1.5px',
    },
    lockStatusBadge: {
        display: 'inline-block',
        fontSize: '0.75rem',
        backgroundColor: SharedColors.cyanBlue10,
        color: 'rgba(255, 255, 255, 0.867)',
        padding: '2px 7px',
        borderRadius: '1.5px',
    },
    footer: {
        boxSizing: 'border-box',
        padding: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#FDFDFD',
        border: '1px solid rgba(0, 0, 0, 0.1)',
    },
    actionButtons: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto auto',
        gridGap: '0.5rem',
    },
    eventLine: {
        height: '1px',
        marginTop: '10px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        flexGrow: 1,
    },
    eventDateRow: {
        marginBottom: '10px',
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'row',
    },
    eventRowGap: {
        width: '10px',
    },
    uploadButton: {
        position: 'relative',
        overflow: 'hidden',
        display: 'inline-block',
    },
    input: {
        position: 'absolute',
        left: '0',
        top: '0',
        opacity: '0',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
    },
    fileName: {
        marginLeft: '10px',
    },
});

export default ViewUploadedDocumentModal;
