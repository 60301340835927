import Spacer from 'components/common/spacer';
import { mergeStyleSets, Stack, TextField } from '@fluentui/react';
import React from 'react';

interface ITemplateHTMLComponentProps {
    theTemplateLabelElement: () => JSX.Element;
    htmlBody: string;
    cleanedHtmlBody: string;
    setHtmlBody: React.Dispatch<React.SetStateAction<string>>;
}

export default function TemplateHTMLComponent(props: ITemplateHTMLComponentProps): JSX.Element {
    return (
        <Stack horizontal tokens={{ childrenGap: 20 }} horizontalAlign='space-between'>
            <div className={styles.separatorStyle}>
                {props.theTemplateLabelElement()}
                <Spacer marginTop={20} />
                <TextField
                    label='Body HTML'
                    value={props.htmlBody}
                    onChange={(event, value): void => props.setHtmlBody(value ?? '')}
                    multiline
                    ariaLabel='Body HTML'
                    rows={30}
                />
            </div>
            <div className={styles.separatorStyle}>
                <h4 className={styles.sectionTitle}>Preview HTML</h4>
                <div
                    className={styles.htmlWrapper}
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    dangerouslySetInnerHTML={{ __html: props.cleanedHtmlBody }}
                />
            </div>
        </Stack>
    );
}
const styles = mergeStyleSets({
    separatorStyle: {
        width: '50%',
    },
    sectionTitle: {
        marginTop: 5,
        marginBottom: 5,
    },
    htmlWrapper: {
        border: '1px solid black',
        background: 'white',
        minHeight: 50,
    },
});
