import {
    MessageBarType,
    Dialog,
    MessageBar,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
} from '@fluentui/react';
import { AttributePredefinedValue, DeletePredefinedValueRequest } from 'personnel-core-clients';
import { CoreAttributesClient } from 'clients/core/personnel-core-client-wrappers';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useState } from 'react';

export interface IDeletePredefinedValueDialogProps {
    hidden: boolean;
    attributeId: string;
    predefinedValue: AttributePredefinedValue;
    onDismissed: () => void;
    onSuccess: () => void;
}

const DEFAULT_MESSAGE = 'Caution, all assignments containing this value will also be deleted.';

export function DeletePredefinedValueDialog(props: IDeletePredefinedValueDialogProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [isDeleteConfirmed, setIsDeleteConfirmed] = useState<boolean>(false);
    const [messageType, setMessageType] = useState<MessageBarType>(MessageBarType.warning);
    const [message, setMessage] = useState<string>(DEFAULT_MESSAGE);

    const onClose = (): void => {
        props.onDismissed();
        setMessageType(MessageBarType.warning);
        setMessage(DEFAULT_MESSAGE);
    };

    const deletePredefinedValue = async (): Promise<void> => {
        try {
            setIsDeleteConfirmed(true);
            setMessage('Deleting predefined value...');
            const attributeClient = new CoreAttributesClient(authContext);
            const request = new DeletePredefinedValueRequest({
                predefinedValueId: props.predefinedValue.value,
            });
            await attributeClient.deletePredefinedValue(props.attributeId, request);
            setMessageType(MessageBarType.success);
            setMessage('Predefined value has been successfully deleted.');
            props.onSuccess();
        } catch (e) {
            console.log(e);
            setMessageType(MessageBarType.error);
            setMessage('An error occurred deleting the predefined value.');
        }
    };

    return (
        <Dialog
            hidden={props.hidden}
            onDismiss={props.onDismissed}
            dialogContentProps={{
                onDismiss: onClose,
                title: 'Delete predefined value',
                showCloseButton: true,
            }}
            minWidth={580}
            maxWidth={580}
            modalProps={{
                styles: {
                    main: {
                        borderRadius: '8px',
                    },
                },
            }}>
            <MessageBar messageBarType={messageType}>{message}</MessageBar>
            <div
                style={{
                    paddingTop: 10,
                }}>
                {!isDeleteConfirmed && (
                    <div>
                        Are you sure you want to delete the predefined value:
                        <b> {props.predefinedValue.value}</b>?
                    </div>
                )}
            </div>
            <DialogFooter>
                {!isDeleteConfirmed && (
                    <PrimaryButton onClick={deletePredefinedValue} text='Delete' />
                )}
                <DefaultButton onClick={onClose} text='Close' />
            </DialogFooter>
        </Dialog>
    );
}
