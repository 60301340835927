import React, { useContext, useState } from 'react';
import Modal, { ModalSizeType } from 'components/common/modal';
import { IFileInfo } from 'components/common/buttons/upload-csv-button';
import { MessageBar, MessageBarType } from '@fluentui/react';
import Spacer from 'components/common/spacer';
import FacilitiesClient, {
    EquipmentType,
    EquipmentTypes,
    EquipmentUploadStatusEnum,
    Region,
    Regions,
    UploadEquipmentRecord,
    UploadEquipmentRecordResult,
    UploadEquipmentColumns,
    Status,
} from 'clients/facilities-client';
import pluralize from 'pluralize';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import { convertCSVToArray, isGUID } from 'utils/string-utils';
import { ChooseFileButton } from 'components/common/buttons/choose-file-button';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { replaceTemplatedTimeStringInDownloadReport } from 'utils/reporting-utils';

type EquipmentBulkUploadModalPropsType = {
    setShowUploadModal: (showUploadModal: boolean) => void;
};

const MAX_RECORDS = 500;

type MessagesType = {
    infoMessage?: string;
    errorMessage?: string;
    successMessage?: string;
};

// The following column headers are required to be present in the CSV file,
// but they are not necessarily required to have a value on them on every row.
const expectedColumnNames = [
    UploadEquipmentColumns.facilityId, // always required for create
    UploadEquipmentColumns.ownerPersonnelId, // always required for create
    UploadEquipmentColumns.region, // always required for create
    UploadEquipmentColumns.type, // always required for create
    UploadEquipmentColumns.make,
    UploadEquipmentColumns.model,
    UploadEquipmentColumns.assetNumber, // always required for create
    UploadEquipmentColumns.status,
    UploadEquipmentColumns.issuedAt,
    UploadEquipmentColumns.expirationOnUtcMilliseconds,
    UploadEquipmentColumns.returnedOnUtcMilliseconds,
    UploadEquipmentColumns.reportedOnUtcMilliseconds,
    UploadEquipmentColumns.issuedBy, // always required for create
    UploadEquipmentColumns.clearanceId,
    UploadEquipmentColumns.contractId,
    UploadEquipmentColumns.id,
];

type ValidColumnNamesType = string;

const statusKeys = new Set(Object.keys(Status));
const regionsKeys = new Set(Object.keys(Regions));
const equipmentTypesKeys = new Set(Object.keys(EquipmentTypes));

export default function EquipmentBulkUploadModal(
    props: EquipmentBulkUploadModalPropsType,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    const [messages, setMessages] = useState<MessagesType>({});
    const [requestData, setRequestData] = useState<UploadEquipmentRecord[]>([]);
    const [isReadyToSubmit, setIsReadyToSubmit] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
    const [shouldShowDownloadButton, setShouldShowDownloadButton] = useState<boolean>(false);
    const [uploadResult, setUploadResult] = useState<UploadEquipmentRecordResult[]>([]);

    const initialize = (): void => {
        setMessages({});
        setIsReadyToSubmit(false);
    };

    const onFileLoadClick = (): void => {
        initialize();
    };

    function onFileLoaded(fileContents: any[], fileInfo: IFileInfo): void {
        const [row1, ...otherRows] = fileContents;
        const columnNames = row1 as string[];
        const data = otherRows as string[][];
        data.pop(); // remove the last row because it's empty.

        const {
            isDataItemsValid,
            columnIndexesResult: columnIndexes,
            errorMessage,
            badRowIx,
            badColumnIx,
        } = checkFileData(data, columnNames);

        if (!isDataItemsValid) {
            if (badRowIx === -1) {
                setMessages({ errorMessage });
            } else {
                // badRowIx + 2 because rowIx is zero-based and we need to account for the header row.
                setMessages({
                    errorMessage: `Invalid data item in row ${badRowIx + 2} column "${
                        columnNames[badColumnIx]
                    }".`,
                });
            }
            return;
        }

        const requestDataVar = prepareRequestData(data, columnIndexes);

        const len = requestDataVar.length;
        setMessages({
            infoMessage: `Read ${len} ${pluralize('row', len)} of data, ready to upload.`,
        });
        setRequestData(requestDataVar);
        setIsReadyToSubmit(true);
    }

    function checkFileData(
        data: string[][],
        columnNames: string[],
    ): {
        isDataItemsValid: boolean;
        columnIndexesResult: Record<string, number>;
        errorMessage: string;
        badRowIx: number;
        badColumnIx: number;
    } {
        const result = {
            isDataItemsValid: false,
            columnIndexesResult: {} as Record<string, number>,
            errorMessage: '',
            badRowIx: -1,
            badColumnIx: -1,
        };

        // Check there is at least some data
        if (data.length === 0) {
            result.errorMessage = 'No data found in file';
            return result;
        }
        // check there are at most MAX_RECORDS in the file
        if (data.length > MAX_RECORDS) {
            result.errorMessage = `Too many records in file. Maximum is ${MAX_RECORDS}`;
            return result;
        }

        // Check if the header row has provided all the expected column names.
        const theProvidedColumnNames: Set<string> = new Set<string>(columnNames);
        if (!expectedColumnNames.every((item) => theProvidedColumnNames.has(item))) {
            const themissingColumnNames = expectedColumnNames.filter(
                (columnName) => !theProvidedColumnNames.has(columnName),
            );
            result.errorMessage = `The following required columns are missing in the CSV file: ${Array.from(
                themissingColumnNames,
            ).join(', ')}.`;
            return result;
        }

        // Column headers are ok.
        const columnIndexesVar: Record<string, number> = {} as Record<ValidColumnNamesType, number>;
        columnNames.forEach((columnName, index) => {
            columnIndexesVar[columnName] = index;
        });

        // Where possible, check if the provided data is valid.
        let isDataItemsValid = true;
        let badRowIx = -1;
        let badColumnIx = -1;
        for (let rowIx = 0; isDataItemsValid && rowIx < data.length; rowIx++) {
            const row = data[rowIx];
            for (let columnIx = 0; isDataItemsValid && columnIx < row.length; columnIx++) {
                switch (columnIx) {
                    case columnIndexesVar[UploadEquipmentColumns.issuedBy]:
                    case columnIndexesVar[UploadEquipmentColumns.assetNumber]:
                    case columnIndexesVar[UploadEquipmentColumns.ownerPersonnelId]:
                        if (!row[columnIx]) {
                            isDataItemsValid = false;
                            [badRowIx, badColumnIx] = [rowIx, columnIx];
                        }
                        break;
                    case columnIndexesVar[UploadEquipmentColumns.facilityId]:
                        if (!row[columnIx] || !isGUID(row[columnIx])) {
                            isDataItemsValid = false;
                            [badRowIx, badColumnIx] = [rowIx, columnIx];
                        }
                        break;
                    case columnIndexesVar[UploadEquipmentColumns.region]:
                        if (!regionsKeys.has(row[columnIx])) {
                            isDataItemsValid = false;
                            [badRowIx, badColumnIx] = [rowIx, columnIx];
                        }
                        break;
                    case columnIndexesVar[UploadEquipmentColumns.type]:
                        if (!equipmentTypesKeys.has(row[columnIx])) {
                            isDataItemsValid = false;
                            [badRowIx, badColumnIx] = [rowIx, columnIx];
                        }
                        break;
                    case columnIndexesVar[UploadEquipmentColumns.status]:
                        // Since Status is not always mandatory, we need to check if it is provided at all,
                        // and where provided, if the value is valid.
                        if (!!row[columnIx] && !statusKeys.has(row[columnIx])) {
                            isDataItemsValid = false;
                            [badRowIx, badColumnIx] = [rowIx, columnIx];
                        }
                        break;
                    case columnIndexesVar[UploadEquipmentColumns.issuedAt]:
                    case columnIndexesVar[UploadEquipmentColumns.expirationOnUtcMilliseconds]:
                    case columnIndexesVar[UploadEquipmentColumns.reportedOnUtcMilliseconds]:
                    case columnIndexesVar[UploadEquipmentColumns.returnedOnUtcMilliseconds]:
                        // Since issued-on date, expiration date, reported date and returned on date are not always mandatory,
                        // we need to check if they are provided at all before determining if their value is valid.
                        if (row[columnIx] !== undefined && row[columnIx]) {
                            const dateStr = row[columnIx];
                            const date = new Date(dateStr);
                            if (date.toString() === 'Invalid Date' || isNaN(date.getTime())) {
                                isDataItemsValid = false;
                                [badRowIx, badColumnIx] = [rowIx, columnIx];
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        return {
            isDataItemsValid,
            columnIndexesResult: columnIndexesVar,
            errorMessage: '',
            badRowIx,
            badColumnIx,
        };
    }

    function prepareRequestData(
        data: string[][],
        columnIndexesParam: Record<ValidColumnNamesType, number>,
    ) {
        const requestDataResult = data.map((row) => {
            const equipment: UploadEquipmentRecord = {
                id: row[columnIndexesParam[UploadEquipmentColumns.id]],
                ownerPersonnelId: row[columnIndexesParam[UploadEquipmentColumns.ownerPersonnelId]],
                region: row[columnIndexesParam[UploadEquipmentColumns.region]] as Region, // Type assertion is safe. Value has been checked.
                facilityId: row[columnIndexesParam[UploadEquipmentColumns.facilityId]],
                type: row[columnIndexesParam[UploadEquipmentColumns.type]] as EquipmentType, // Type assertion is safe. Value has been checked.
                make: row[columnIndexesParam[UploadEquipmentColumns.make]],
                model: row[columnIndexesParam[UploadEquipmentColumns.model]],
                assetNumber: row[columnIndexesParam[UploadEquipmentColumns.assetNumber]],
                status: row[columnIndexesParam[UploadEquipmentColumns.status]] as Status, // Type assertion is safe. Value has been checked.
                issuedBy: row[columnIndexesParam[UploadEquipmentColumns.issuedBy]],
                issuedAt: new Date(
                    row[columnIndexesParam[UploadEquipmentColumns.issuedAt]],
                ).getTime(),
                contractId: row[columnIndexesParam[UploadEquipmentColumns.contractId]],
                clearanceId: row[columnIndexesParam[UploadEquipmentColumns.clearanceId]],
                expirationOnUtcMilliseconds: !!row[
                    columnIndexesParam[UploadEquipmentColumns.expirationOnUtcMilliseconds]
                ]
                    ? new Date(
                          row[
                              columnIndexesParam[UploadEquipmentColumns.expirationOnUtcMilliseconds]
                          ],
                      ).getTime()
                    : undefined,
                returnedOnUtcMilliseconds: !!row[
                    columnIndexesParam[UploadEquipmentColumns.returnedOnUtcMilliseconds]
                ]
                    ? new Date(
                          row[columnIndexesParam[UploadEquipmentColumns.returnedOnUtcMilliseconds]],
                      ).getTime()
                    : undefined,
                reportedOnUtcMilliseconds: !!row[
                    columnIndexesParam[UploadEquipmentColumns.reportedOnUtcMilliseconds]
                ]
                    ? new Date(
                          row[columnIndexesParam[UploadEquipmentColumns.reportedOnUtcMilliseconds]],
                      ).getTime()
                    : undefined,
                uploadStatus: row[
                    columnIndexesParam[UploadEquipmentColumns.uploadStatus]
                ] as EquipmentUploadStatusEnum,
            };
            return equipment;
        });
        return requestDataResult;
    }

    const onSubmit = async (): Promise<void> => {
        if (hasSubmitted) {
            props.setShowUploadModal(false);
            return;
        }
        setMessages({});
        try {
            setIsSubmitting(true);
            const submitResult = await FacilitiesClient.uploadEquipmentRecords(
                authContext,
                userContext,
                requestData,
            );
            const csvReplaced = replaceTemplatedTimeStringInDownloadReport(submitResult ?? '');
            const result = convertCSVToArray<UploadEquipmentRecordResult>(csvReplaced);
            setUploadResult(result);
            if (result.length === 0) {
                setMessages({ successMessage: 'All records have already been uploaded.' });
            } else if (
                result.every((r) => {
                    return r[UploadEquipmentColumns.uploadStatus] === 'Success';
                })
            ) {
                setMessages({
                    successMessage: `result.length ${pluralize(
                        'record',
                        result.length,
                    )} uploaded successfully`,
                });
            } else {
                const successCount = result.filter(
                    (r) => r[UploadEquipmentColumns.uploadStatus] === 'Success',
                ).length;
                const skipCount = result.filter(
                    (r) => r[UploadEquipmentColumns.uploadStatus] === 'Skipped',
                ).length;
                const failureCount = result.filter(
                    (r) => r[UploadEquipmentColumns.uploadStatus] === 'Failed',
                ).length;
                setMessages(() => {
                    const messages = {
                        successMessage: '',
                        errorMessage: '',
                    };
                    if (successCount > 0) {
                        messages.successMessage = `${successCount} ${pluralize(
                            'record',
                            successCount,
                        )} uploaded successfully. `;
                    }
                    if (skipCount > 0) {
                        messages.successMessage += `${skipCount} ${pluralize(
                            'record',
                            skipCount,
                        )} not uploaded.`;
                    }
                    if (failureCount > 0) {
                        messages.errorMessage = `${failureCount} ${pluralize(
                            'record',
                            failureCount,
                        )} failed to upload.`;
                    }
                    return messages;
                });
            }
            setShouldShowDownloadButton(true);
        } catch (error) {
            setMessages({ errorMessage: `Error submitting bulk upload: ${error}` });
        } finally {
            setIsSubmitting(false);
            setHasSubmitted(true);
        }
    };

    const getUploadResult = async (): Promise<UploadEquipmentRecordResult[]> => {
        return uploadResult;
    };

    function onFileOpenError(error: Error): void {
        setMessages({ errorMessage: error.message });
    }

    return (
        <Modal
            isOpen={true}
            size={ModalSizeType.size500}
            fixWidth={true}
            title='Upload equipment'
            onCancel={(): void => props.setShowUploadModal(false)}
            isSubmitButtonDisabled={!isReadyToSubmit || isSubmitting}
            showProgressIndicator={isSubmitting}
            submitButtonText={hasSubmitted ? 'Close' : 'Upload'}
            shouldHideCancelButton={hasSubmitted}
            onSubmit={onSubmit}>
            <ChooseFileButton
                disabled={isSubmitting || hasSubmitted}
                acceptedFileTypes={['text/csv']}
                onFileLoaded={onFileLoaded}
                onError={onFileOpenError}
                onClick={onFileLoadClick}
            />
            <div>Upload equipment CSV, max 1 file with 500 rows</div>
            {!!messages.infoMessage && (
                <div>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.info}>
                        <div>{messages.infoMessage}</div>
                    </MessageBar>
                </div>
            )}
            {!!messages.successMessage && (
                <div>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.success}>
                        <div>{messages.successMessage}</div>
                    </MessageBar>
                </div>
            )}
            {!!messages.errorMessage && (
                <div>
                    <Spacer marginTop={10} />
                    <MessageBar messageBarType={MessageBarType.error}>
                        <div>{messages.errorMessage}</div>
                    </MessageBar>
                </div>
            )}
            {shouldShowDownloadButton && uploadResult.length > 0 && (
                <>
                    <Spacer marginTop={10} />
                    <ExportToExcelButton<UploadEquipmentRecordResult>
                        buttonTitle='Download report'
                        buttonType='default'
                        getData={getUploadResult}
                        fileNamePrefix='equipment-upload'
                        formatHeader={false}
                    />
                </>
            )}
        </Modal>
    );
}
