import React, { useState, useEffect, useContext } from 'react';
import { Table } from 'components/common/table';
import HorizontalBar from 'components/common/horizontal-bar';
import {
    ActionButton,
    Stack,
    TeachingBubble,
    DirectionalHint,
    DefaultButton,
    Toggle,
    TooltipHost,
    Icon,
    MessageBar,
    MessageBarType,
    IColumn,
} from '@fluentui/react';
import { AuthContext } from 'contexts/auth-context';
import { IconNames } from 'assets/constants/global-constants';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import EmployeeClient, {
    IBasicEmployee,
    IEmployeeEditableResponse,
    transformPrehireToEmployeeObject,
} from 'clients/employee-client';
import { CacheContext } from 'contexts/cache-context';
import { isGUID } from 'utils/string-utils';
import { Dictionary } from 'assets/constants/global-constants';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import { useSortColumnHandler, strCmp, numCmp, SortDescending } from 'utils/sort-utils';
import ReportsClient, { IHierarchy } from 'clients/reports-client';
import { UserContext } from 'contexts/user-context';
import {
    generateLatestHierarchyReport,
    updateHierarchyCache,
} from 'components/screening/common/content-tabs-help';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import {
    getEmployeeType,
    IEmployeeNameAndType,
    PersonnelTypes,
} from 'components/common/employee/internal-employee-utils';
import { isUserContractOwnerOrNST } from 'components/screening/common/filters/filter-help';
import Spacer from 'components/common/spacer';
import CandidatesPageHelp from 'components/screening/common/components/candidate-page-help';
import { filterWidth, pageStyle } from 'components/screening/common/common-tab-styling';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import { determineContractType } from 'components/screening/screening-utils';
import { columnHeaderStyles, expandedTableWidth } from 'assets/styles/list-styles';
import {
    BrowserCacheKeys,
    generateCacheKeyContract,
    generateCacheKeyEmployeeNames,
} from 'utils/browser-cache-utils';
import {
    ISuitabilityRecord,
    ISuitabilityRecordResult,
    SuitabilityClient,
} from 'clients/suitability-client';
import {
    FiltersSuitabilityRecords,
    IFiltersSuitabilityRecords,
} from 'contexts/filters-suitability-records';
import { SuitabilityRecordsFilter } from 'components/screening/us-gov/candidates/listing/suitability-records-filter';
import SuitabilityRecordsChart from 'components/screening/us-gov/candidates-listing/metrics/suitability-records-chart';
import { getSuitabilityRecordsTableColumns } from 'components/screening/us-gov/candidates/listing/get-suitability-records-table-columns';
import {
    SuitabilityLevels,
    SuitabilityStatuses,
    SuitabilityTypes,
} from 'components/personnel-profile/suitability/profile-suitability-types';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/suitability/profile-suitability-utils';
import { ContinuousEvaluationTypes } from 'components/personnel-profile/common/common-types';
import ExportToExcelButton from 'components/common/buttons/export-to-excel-button';
import { TimeFormats } from 'utils/time-utils';
import {
    excelFormatHeader,
    replaceTemplatedTimeStringInDownloadReport,
} from 'utils/reporting-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import SuitabilityBulkUploadModal from 'components/screening/us-gov/candidates-listing/tabpanel-contents/suitability-bulk-upload-modal';

interface IContentSuitabilityRecordsTabProps {
    screeningPath: ScreeningPaths;
}

export default function ContentSuitabilityRecordsTab(
    props: IContentSuitabilityRecordsTabProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const cacheContext = useContext(CacheContext);
    const filtersContext = useContext(FiltersSuitabilityRecords);
    const userContext = useContext(UserContext);
    const cacheKey = `${filtersContext.location.pathname}${filtersContext.location.search}`;
    const cacheKeyContracts = generateCacheKeyContract(cacheKey);
    const cacheKeyEmployeeNames = generateCacheKeyEmployeeNames(filtersContext); // the URL of the page
    const suitabilityFeatureOn = useFeatureFlag(FeatureFlagKeys.suitabilityRecordsTab);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

    const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false);
    const [errorMessage, setMessage] = useState<string>('');
    const [filteredSuitabilities, setFilteredSuitabilities] = useState<ISuitabilityRecord[]>([]);
    const [hasErrorOccurred, setErrorOccurred] = useState<boolean>(false);
    const [hierarchyRecords, setHierarchyRecords] = useState<Dictionary<IHierarchy>>();
    const [isDoneLoadingSuitabilities, setDoneLoadingSuitabilities] = useState<boolean>(false);
    const [isExpandButtonShown, setisExpandButtonShown] = useState<boolean>(true);
    const [isPageFetchingInitialNominationData, setIsPageFetchingInitialNominationData] = useState<
        boolean
    >(false);
    const [rawSuitabilities, setRawSuitabilities] = useState<ISuitabilityRecord[]>([]);
    const [tableColumns, setTableColumns] = useState([] as IColumn[]);
    const [contractMap, setContractMap] = useState(new Map<string, string>());
    const [isTeachingBubbleOpen, setTeachingBubbleOpen] = useState(false);
    const [excelExportType, setExcelExportType] = useState<ExportToExcelDataType | undefined>(
        ExportToExcelDataType.Suitabilities,
    );
    const [
        employeeIdsWithFutureTerminationDates,
        setEmployeeIdsWithFutureTerminationDates,
    ] = useState<string[]>([]);
    const [employeeNames, setEmployeeNames] = useState<Map<string, IEmployeeNameAndType>>(
        new Map<string, IEmployeeNameAndType>(),
    );
    const [{ sortColumn, sortAscending }, sortColumnHandler] = useSortColumnHandler(
        SuitabilityColumnHeaders.LastModified,
        SortDescending,
    );

    /**
     * Fetches hierarchy data by using personnel ids of suitability records.
     */
    async function updateCacheWithLatestHierarchyData(): Promise<void> {
        const personnelIds = rawSuitabilities.map((record) => record.personnelId);

        if (!personnelIds || personnelIds.length <= 0) {
            return;
        }

        const reportData = await ReportsClient.getSelectHierarchy(authContext, personnelIds);
        setHierarchyRecords(reportData);
        if (Object.keys(reportData).length > 0) {
            updateHierarchyCache(reportData, localStorage);
        }
    }

    async function getLatestHierarchyReport(): Promise<void> {
        const storedRecords = localStorage.getItem(
            BrowserCacheKeys.hierarchyReportStorageAndCacheKey,
        );
        const recordTimestamp = localStorage.getItem(BrowserCacheKeys.hierarchyReportTimestampKey);

        try {
            const generatedReports = await generateLatestHierarchyReport(
                rawSuitabilities,
                authContext,
                storedRecords,
                recordTimestamp,
            );
            if (generatedReports) {
                if (
                    generatedReports.combinedHierarchyData &&
                    Object.keys(generatedReports.combinedHierarchyData).length > 0
                ) {
                    updateHierarchyCache(generatedReports.combinedHierarchyData, localStorage);
                    setHierarchyRecords(generatedReports.combinedHierarchyData);
                } else {
                    setHierarchyRecords(generatedReports.parsedHierarchyData);
                }
            } else {
                // if there is no cached report or if the latest report is from more than 24hrs
                // then just get latest hierarchy report for employees in aggregate report
                await updateCacheWithLatestHierarchyData();
            }
        } catch (e) {
            // if it exists, use whatever stored data is available
            if (recordTimestamp && storedRecords) {
                const parsedHierarchyData: Dictionary<IHierarchy> = JSON.parse(storedRecords);
                setHierarchyRecords(parsedHierarchyData);

                // log the error if they still was able to parse some hierarchy data
                console.error('Could not retrieve or request any hierarchy reports', e);
            } else {
                setErrorOccurred(true);
                setMessage('Could not retrieve or request any hierarchy reports');
            }
        }
    }

    async function getEmployeesWithFutureTerminationDates(personnelIds: string[]): Promise<void> {
        const isContractOwnerOrNST = isUserContractOwnerOrNST(userContext, ScreeningPaths.UsGov);

        if (isContractOwnerOrNST) {
            const results = await UsGovScreeningClient.getEmployeeIdsWithFutureTerminationDates(
                authContext,
                personnelIds,
            );
            setEmployeeIdsWithFutureTerminationDates(results);
        }
    }

    async function getInitialCandidates(filterCtx: IFiltersSuitabilityRecords): Promise<void> {
        setIsPageFetchingInitialNominationData(true);

        try {
            const results: ISuitabilityRecordResult = await SuitabilityClient.getSuitabilityRecordsByStatuses(
                authContext,
                filterCtx,
            );
            let suitabilityArray: ISuitabilityRecord[] = results.results;
            if (results.continuationToken) {
                suitabilityArray = await getMoreSuitabilities(
                    suitabilityArray,
                    filterCtx,
                    results.continuationToken,
                );
            }
            setFilteredSuitabilities(suitabilityArray);
            setRawSuitabilities(suitabilityArray);
            getEmployeesWithFutureTerminationDates(suitabilityArray.map((x) => x.personnelId));
            await batchFetchCandidateEmployeeData(suitabilityArray);
            setDoneLoadingSuitabilities(true);
            cacheContext.store<ISuitabilityRecord[]>(cacheKey, suitabilityArray);
        } catch (e) {
            setFilteredSuitabilities([]);
            setErrorOccurred(true);
            setMessage('No Suitabilities to show');
        }
    }

    async function getContractList(): Promise<void> {
        setIsPageFetchingInitialNominationData(true);

        try {
            const contractsList = await UsGovScreeningClient.getContracts(authContext, [
                determineContractType(props.screeningPath),
            ]);
            const newContractsList = new Map<string, string>();

            contractsList.results.forEach((contract) => {
                newContractsList.set(contract.id, contract.project);
            });
            setContractMap(newContractsList);
            cacheContext.store<Map<string, string>>(cacheKeyContracts, newContractsList);
        } catch (e) {
            setMessage('No Suitabilities to show');
        } finally {
            setIsPageFetchingInitialNominationData(false);
        }
    }

    async function getMoreSuitabilities(
        suitabilityArray: ISuitabilityRecord[],
        filterCtx: IFiltersSuitabilityRecords,
        continuationToken?: string,
    ): Promise<ISuitabilityRecord[]> {
        try {
            let results: ISuitabilityRecordResult;
            let useContinuationToken: string | undefined = continuationToken;
            do {
                results = await SuitabilityClient.getSuitabilityRecordsByStatuses(
                    authContext,
                    filterCtx,
                    useContinuationToken,
                );
                suitabilityArray = [...suitabilityArray, ...results.results];
                populateEmployeeDataCache(results.results);
                useContinuationToken = results.continuationToken;
            } while (useContinuationToken);
        } catch (e) {
            setErrorOccurred(true);
            setMessage('Failed to get all Suitability records');
        } finally {
            return suitabilityArray;
        }
    }

    useEffect(() => {
        const suitabilityArray = cacheContext.retrieve<ISuitabilityRecord[]>(cacheKey);
        const contractMap = cacheContext.retrieve<Map<string, string>>(cacheKeyContracts);
        if (suitabilityArray) {
            setFilteredSuitabilities(suitabilityArray);
            setRawSuitabilities(suitabilityArray);
            setIsPageFetchingInitialNominationData(false);
            getEmployeesWithFutureTerminationDates(suitabilityArray.map((x) => x.personnelId));
            setDoneLoadingSuitabilities(true);
        } else {
            getInitialCandidates(filtersContext);
        }
        if (contractMap) {
            setContractMap(contractMap);
        } else {
            getContractList();
        }
    }, []);

    useEffect(() => {
        function filterData(): void {
            let finalData = rawSuitabilities;
            // eslint-disable-next-line @typescript-eslint/ban-types
            filtersContext.filterFunctionsMap.forEach((filterFunctions: Function[]) => {
                if (filterFunctions!.length > 0) {
                    finalData = finalData.filter((data) => {
                        for (const filterFunction of filterFunctions!) {
                            if (filterFunction(data)) {
                                return true;
                            }
                        }
                        return false;
                    });
                }
            });
            setFilteredSuitabilities(finalData);
        }
        filterData();
    }, [filtersContext, rawSuitabilities]);

    useEffect(() => {
        if (
            isDoneLoadingSuitabilities &&
            userContext.isUsGovScreeningUserTypesLoaded &&
            isUserContractOwnerOrNST(userContext, ScreeningPaths.UsGov)
        ) {
            const storedRecords = localStorage.getItem(
                BrowserCacheKeys.hierarchyReportStorageAndCacheKey,
            );
            const hierarchyReportMap: Dictionary<IHierarchy> = storedRecords
                ? JSON.parse(storedRecords)
                : undefined;

            if (hierarchyReportMap) {
                setHierarchyRecords(hierarchyReportMap);
            }
            getLatestHierarchyReport();
        }
    }, [isDoneLoadingSuitabilities, userContext.isUsGovScreeningUserTypesLoaded]);

    /**
     * This is a greedy method just to pre-cache employee data, because it might be shown on the UI
     *
     * Very close to batchFetchCandidateEmployeeData but different in that this will not
     * capture the manager data and the UI will not be held up in rendering.
     * Primary used for data that is not going to be rendering right away.
     */
    async function populateEmployeeDataCache(candidates: ISuitabilityRecord[]): Promise<void> {
        try {
            const initialPersonnelIdsToFetch = new Set<string>();
            const preHireCheckPersonnelIdsToFetch = new Set<string>();
            candidates.forEach((screen: ISuitabilityRecord) => {
                if (screen.personnelId) {
                    if (!isGUID(screen.personnelId)) {
                        initialPersonnelIdsToFetch.add(screen.personnelId);
                    } else {
                        preHireCheckPersonnelIdsToFetch.add(screen.personnelId);
                    }
                } else {
                    console.log('no personnel id screening', screen);
                }
            });
            if (initialPersonnelIdsToFetch.size > 0) {
                EmployeeClient.getBasicEmployeesById(
                    authContext,
                    Array.from(initialPersonnelIdsToFetch),
                );
            }
        } catch (e) {
            // don't show the error, just log it instead
            console.error(
                'Error resolving alias/ids from employee-services in populateEmployeeDataCache',
                e,
            );
        }
    }

    /**
     * This is a greedy method to handle the first Page of Suitability records that do end up on the
     *  UI therefor also want to capture there managers info and make sure nothing renders until
     * this function is done.
     *
     * Very close to populateEmployeeDataCache, but different in that this is pulling manager
     * and will hold the UI from rendering until complete.
     */
    async function batchFetchCandidateEmployeeData(
        candidates: ISuitabilityRecord[],
    ): Promise<void> {
        try {
            const initialPersonnelIdsToFetch = new Set<string>();
            const managerAliasesToFetch = new Set<string>();
            const newNameMap = new Map(employeeNames);
            const initialEditableEmployeeIdsToFetch = new Set<string>();
            candidates.forEach((screen: ISuitabilityRecord) => {
                if (screen.personnelId) {
                    if (!isGUID(screen.personnelId)) {
                        initialPersonnelIdsToFetch.add(screen.personnelId);
                    } else {
                        initialEditableEmployeeIdsToFetch.add(screen.personnelId);
                    }
                } else {
                    console.log('no personnel id screening', screen);
                }
            });

            if (initialEditableEmployeeIdsToFetch.size > 0) {
                const initialEmployeeData: IEmployeeEditableResponse[] = await EmployeeClient.getMultipleEditableEmployeeDataByIdOrAliasOrGUID(
                    authContext,
                    Array.from(initialEditableEmployeeIdsToFetch),
                );

                initialEmployeeData.forEach((editableEmployee) => {
                    const employee = transformPrehireToEmployeeObject(editableEmployee);

                    if (!!editableEmployee.employeeEditableInfo.reportsToEmailName) {
                        managerAliasesToFetch.add(
                            editableEmployee.employeeEditableInfo.reportsToEmailName,
                        );
                    }

                    newNameMap.set(employee.id, {
                        displayName: getDisplayNameOrDefault(
                            employee,
                            employee.onPremisesSamAccountName,
                        ),
                        alias: employee.alias,
                        firstName: employee.firstName,
                        middleName: employee.middleName,
                        lastName: employee.lastName,
                        email: employee.email,
                        type: getEmployeeType(employee),
                    });

                    if (isGUID(editableEmployee?.employeeId || '')) {
                        newNameMap.set(editableEmployee.employeeId || '', {
                            displayName: getDisplayNameOrDefault(
                                employee,
                                employee.onPremisesSamAccountName,
                            ),
                            alias: employee.alias,
                            firstName: employee.firstName,
                            middleName: employee.middleName,
                            lastName: employee.lastName,
                            email: employee.email,
                            type: getEmployeeType(employee),
                        });
                    }
                });
            }

            if (initialPersonnelIdsToFetch.size > 0) {
                const initialEmployeeData: IBasicEmployee[] = await EmployeeClient.getBasicEmployeesById(
                    authContext,
                    Array.from(initialPersonnelIdsToFetch),
                );

                initialEmployeeData.forEach((emp: IBasicEmployee) => {
                    if (!!emp.reportsToEmailName) {
                        managerAliasesToFetch.add(emp.reportsToEmailName);
                    }
                });

                await EmployeeClient.getBasicEmployeesByAlias(
                    authContext,
                    Array.from(managerAliasesToFetch),
                );

                initialEmployeeData.forEach((basicEmployee) => {
                    newNameMap.set(basicEmployee.id, {
                        displayName: getDisplayNameOrDefault(
                            basicEmployee,
                            basicEmployee.onPremisesSamAccountName,
                        ),
                        alias: basicEmployee.onPremisesSamAccountName,
                        email: basicEmployee.mail,
                        firstName: basicEmployee.givenName,
                        middleName: basicEmployee.middleName,
                        lastName: basicEmployee.surname,
                        type: getEmployeeType(basicEmployee),
                    });
                });
            }

            setEmployeeNames(newNameMap);

            cacheContext.store<Map<string, IEmployeeNameAndType>>(
                cacheKeyEmployeeNames,
                newNameMap,
            );
        } catch (e) {
            // don't show the error instead log it
            console.error(
                'Error resolving alias/ids from employee-services in batchFetchCandidateEmployeeData',
                e,
            );
        }
        setIsPageFetchingInitialNominationData(false);
    }

    function getExportFileName(): string {
        switch (excelExportType) {
            default:
            case ExportToExcelDataType.Suitabilities:
                return 'Suitability_Records';
            case ExportToExcelDataType.SuitabilityHolders:
                return 'Suitability_Holders';
        }
    }

    function sortTableRows(rows: ISuitabilityRecord[]): ISuitabilityRecord[] {
        type R = ISuitabilityRecord;
        const determineSortFunc = (): ((r1: R, r2: R) => number) => {
            switch (sortColumn) {
                case SuitabilityColumnHeaders.Name:
                    return (r1: R, r2: R) => {
                        const e1 = employeeNames.get(r1?.personnelId);
                        const e2 = employeeNames.get(r2?.personnelId);

                        if (
                            e1?.type === PersonnelTypes.Terminated &&
                            e2?.type === PersonnelTypes.Terminated
                        )
                            return 0;
                        if (e1?.type === PersonnelTypes.Terminated) return 1;
                        if (e2?.type === PersonnelTypes.Terminated) return -1;

                        return sortAscending * strCmp(e1?.displayName || '', e2?.displayName || '');
                    };
                case SuitabilityColumnHeaders.SuitabilityLevel:
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        strCmp(
                            SuitabilityLevels[
                                r1.suitabilityLevel as keyof typeof SuitabilityLevels
                            ] || '',
                            SuitabilityLevels[
                                r2.suitabilityLevel as keyof typeof SuitabilityLevels
                            ] || '',
                        );
                case SuitabilityColumnHeaders.Type:
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        strCmp(
                            SuitabilityTypes[r1.suitabilityType as keyof typeof SuitabilityTypes] ||
                                '',
                            SuitabilityTypes[r2.suitabilityType as keyof typeof SuitabilityTypes] ||
                                '',
                        );
                case SuitabilityColumnHeaders.Status:
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        strCmp(
                            SuitabilityStatuses[r1.status as keyof typeof SuitabilityStatuses] ||
                                '',
                            SuitabilityStatuses[r2.status as keyof typeof SuitabilityStatuses] ||
                                '',
                        );
                case SuitabilityColumnHeaders.Agency:
                    return (r1: R, r2: R): number => {
                        const s1 = r1.requestingAgency
                            ? getAgencyEnumValueFromKey(r1.requestingAgency, true)
                            : '';
                        const s2 = r2.requestingAgency
                            ? getAgencyEnumValueFromKey(r2.requestingAgency, true)
                            : '';

                        return sortAscending * strCmp(s1, s2);
                    };
                case SuitabilityColumnHeaders.ContractID:
                    return (r1: R, r2: R): number =>
                        sortAscending * strCmp(r1?.contractId, r2?.contractId);
                case SuitabilityColumnHeaders.ProjectName:
                    return (r1: R, r2: R): number => {
                        return (
                            sortAscending *
                            strCmp(
                                contractMap.get(r1.contractId || ''),
                                contractMap.get(r2.contractId || ''),
                            )
                        );
                    };
                case SuitabilityColumnHeaders.ExpiresOn:
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        numCmp(
                            r1?.suitabilityExpirationDateTimeUTCMilliseconds,
                            r2?.suitabilityExpirationDateTimeUTCMilliseconds,
                        );
                case SuitabilityColumnHeaders.GrantedOn:
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        numCmp(r1?.grantDateUTCMilliseconds, r2?.grantDateUTCMilliseconds);
                case SuitabilityColumnHeaders.InvestigatedOn:
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        numCmp(
                            r1?.investigationDateUTCMilliseconds,
                            r2?.investigationDateUTCMilliseconds,
                        );
                case SuitabilityColumnHeaders.BriefedOn:
                    return (r1: R, r2: R): number =>
                        sortAscending *
                        numCmp(r1?.briefDateUTCMilliseconds, r2?.briefDateUTCMilliseconds);
                case SuitabilityColumnHeaders.ContinuousEvaluationType:
                    return (r1: R, r2: R): number => {
                        return (
                            sortAscending *
                            strCmp(
                                ContinuousEvaluationTypes[
                                    r1.continuousEvaluationType as keyof typeof ContinuousEvaluationTypes
                                ] || '',
                                ContinuousEvaluationTypes[
                                    r2.continuousEvaluationType as keyof typeof ContinuousEvaluationTypes
                                ] || '',
                            )
                        );
                    };
                case SuitabilityColumnHeaders.LastModified:
                    return (r1: R, r2: R) =>
                        sortAscending * numCmp(r1?.lastModified?.atUtc, r2?.lastModified?.atUtc);
                default:
                    return (r1: R, r2: R): number => 1;
            }
        };

        return (rows || []).sort(determineSortFunc());
    }

    useEffect(() => {
        setTableColumns(
            getSuitabilityRecordsTableColumns({
                contractMap,
                isExpandButtonClicked: !isExpandButtonShown || isTableExpanded,
                sortColumn,
                sortAscending: sortAscending === 1,
                sortColumnHandler,
            }),
        );
    }, [rawSuitabilities, isTableExpanded, isExpandButtonShown, sortColumn, sortAscending]);

    useEffect(() => {
        function handleResize(): void {
            if (window.innerWidth >= filterWidth + expandedTableWidth) {
                setisExpandButtonShown(false);
            } else {
                setisExpandButtonShown(true);
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize(); // check the size of the screen on page load

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    async function getSuitabilityExcelData(): Promise<string> {
        const reportData: string = await SuitabilityClient.getSuitabilityReport(
            authContext,
            filteredSuitabilities,
        );

        const csv = replaceTemplatedTimeStringInDownloadReport(reportData);
        return csv;
    }

    async function getSuitabilityHolderExcelData(): Promise<string> {
        const reportData: string = await SuitabilityClient.getSuitabilityHoldersReport(
            authContext,
            filteredSuitabilities,
        );

        const csv = replaceTemplatedTimeStringInDownloadReport(reportData);
        return csv;
    }

    return (
        <div className={pageStyle.page}>
            <div className={pageStyle.filterAndTable}>
                <div className={pageStyle.filterWrapper}>
                    <div className={pageStyle.filter}>
                        <SuitabilityRecordsFilter
                            suitabilityContractMap={contractMap}
                            hierarchyRecords={hierarchyRecords}
                            rawSuitabilities={rawSuitabilities}
                            showCounts={isDoneLoadingSuitabilities}
                            employeeNames={employeeNames}
                            employeeIdsWithFutureTerminationDates={
                                employeeIdsWithFutureTerminationDates
                            }
                        />
                    </div>
                    <Stack styles={{ root: { marginTop: 15, paddingBottom: 15 } }}>
                        {isTeachingBubbleOpen && (
                            <TeachingBubble
                                calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                                isWide={true}
                                styles={{ body: { lineHeight: '1.3' } }}
                                hasCloseButton={true}
                                closeButtonAriaLabel='close button'
                                onDismiss={(): void => {
                                    setTeachingBubbleOpen(false);
                                }}
                                hasCondensedHeadline={true}
                                target={`#aboutRecordsTeachingBubble`}
                                headline='About Suitability Records'>
                                This is a complete list of all suitability records in the system.
                            </TeachingBubble>
                        )}
                        <div>
                            <DefaultButton
                                style={{ height: 'auto', minHeight: '35px' }}
                                id='aboutRecordsTeachingBubble'
                                text='About Suitability Records'
                                onClick={(): void => setTeachingBubbleOpen(!isTeachingBubbleOpen)}
                            />
                        </div>
                    </Stack>
                </div>
                <div className={pageStyle.clearanceTable}>
                    {hasErrorOccurred && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel='Close'
                            onDismiss={closeMessage}
                            styles={{ root: { marginBottom: 15 } }}>
                            {errorMessage}
                        </MessageBar>
                    )}
                    <Spacer marginTop={20} />
                    <CandidatesPageHelp screeningPath={ScreeningPaths.UsGov} />
                    {rawSuitabilities.length > 0 && (
                        <>
                            <SuitabilityRecordsChart records={filteredSuitabilities} />
                        </>
                    )}
                    <Spacer marginTop={20} />
                    <HorizontalBar>
                        <Stack.Item grow={1}>
                            <span className={pageStyle.recordsCount}>
                                {`${filteredSuitabilities.length} of ${rawSuitabilities.length} Records`}
                            </span>
                        </Stack.Item>
                        <Stack.Item grow={100}>
                            <ClearFiltersActionButton clearFunc={filtersContext.clearFilters} />
                            {suitabilityFeatureOn && (
                                <ActionButton
                                    onClick={(): void => setShowUploadModal(true)}
                                    iconProps={{ iconName: IconNames.BulkUpload }}
                                    allowDisabledFocus>
                                    Upload
                                </ActionButton>
                            )}
                            {showUploadModal && (
                                <SuitabilityBulkUploadModal
                                    setShowUploadModal={setShowUploadModal}
                                />
                            )}
                        </Stack.Item>
                        <Stack.Item grow={0.001}>
                            <Toggle
                                label={
                                    <div>
                                        Export Dataset{' '}
                                        <TooltipHost
                                            content={
                                                excelExportType &&
                                                excelExportType ===
                                                    ExportToExcelDataType.SuitabilityHolders
                                                    ? 'Export Unique Suitability Holders'
                                                    : 'Export Displayed Suitability Records'
                                            }>
                                            <Icon iconName='Info' aria-label='Info tooltip' />
                                        </TooltipHost>
                                    </div>
                                }
                                styles={{ root: pageStyle.exportToggle }}
                                inlineLabel
                                onText='Unique Suitability Holders'
                                offText='Suitability Records'
                                onChange={(
                                    event: React.MouseEvent<HTMLElement>,
                                    checked?: boolean,
                                ): void => {
                                    setExcelExportType(
                                        checked
                                            ? ExportToExcelDataType.SuitabilityHolders
                                            : ExportToExcelDataType.Suitabilities,
                                    );
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item grow={0.001}>
                            {isExpandButtonShown && (
                                // show the expand button if there isn't enough space for the user to see all the columns
                                <ActionButton
                                    iconProps={
                                        isTableExpanded
                                            ? { iconName: IconNames.CalculatorSubtract }
                                            : { iconName: IconNames.Add }
                                    }
                                    onClick={(): void => setIsTableExpanded(!isTableExpanded)}>
                                    {`${isTableExpanded ? 'Collapse' : 'Expand'} Columns`}
                                </ActionButton>
                            )}
                            <ExportToExcelButton<string>
                                getData={async (): Promise<string> => {
                                    switch (excelExportType) {
                                        default:
                                        case ExportToExcelDataType.Suitabilities:
                                            return getSuitabilityExcelData();
                                        case ExportToExcelDataType.SuitabilityHolders:
                                            return getSuitabilityHolderExcelData();
                                    }
                                }}
                                fileNamePrefix={getExportFileName()}
                                fileNameTimeFormat={TimeFormats.MM_DD_YYYY_HHmm}
                                formatHeader={true}
                                formatType={excelFormatHeader.microsoftBrandCase}
                            />
                        </Stack.Item>
                    </HorizontalBar>
                    <Table<ISuitabilityRecord>
                        rows={sortTableRows(filteredSuitabilities)}
                        isFetchingData={isPageFetchingInitialNominationData}
                        shimmerLabel='Loading initial nominations...'
                        tableColumns={tableColumns}
                        tableName='Suitability Records'
                        detailsHeaderStyles={columnHeaderStyles}
                    />
                </div>
            </div>
        </div>
    );

    function closeMessage(): void {
        setErrorOccurred(false);
        setMessage('');
    }
}

enum ExportToExcelDataType {
    Suitabilities = 'Suitabilities',
    SuitabilityHolders = 'SuitabilityHolders',
}

export enum SuitabilityColumnHeaders {
    Name = 'Name',
    SuitabilityLevel = 'Suitability level',
    Type = 'Type',
    Status = 'Status',
    Agency = 'Agency',
    ContractID = 'Contract ID',
    ProjectName = 'Project name',
    ExpiresOn = 'Expires on',
    GrantedOn = 'Granted on',
    InvestigatedOn = 'Investigated on',
    BriefedOn = 'Briefed on',
    DebriefedOn = 'Debriefed on',
    ContinuousEvaluationType = 'Continuous evaluation type',
    LastModified = 'Last modified',
}
