import React, { useState, useEffect, useMemo } from 'react';
import BarChart, { ChartItem } from 'components/common/charts/bar-chart';
import { Stack } from '@fluentui/react';
import { CommunicationColors } from '@fluentui/theme';
import { IClearanceRecord } from 'clients/clearance-client';
import { ClearanceStatuses } from 'components/personnel-profile/clearance/profile-clearance-constants';
import {
    recordBarChartStack,
    recordTextBoxInfoStack,
    overallGraphStack,
} from 'components/screening/common/common-manage-profile-page-styling';

interface CandidatesScreeningChartProps {
    records: IClearanceRecord[];
}

interface IMetaData {
    activeRecords: number;
    filteredRecords: number;
    percentActiveRecords: string;
}

const FILTERED_STATUSES = [ClearanceStatuses.Active];

const initialMetaData: IMetaData = {
    activeRecords: 0,
    filteredRecords: 0,
    percentActiveRecords: '0%',
};

export default function ClearanceRecordsChart(props: CandidatesScreeningChartProps): JSX.Element {
    const [barChartData, setBarChartData] = useState<ChartItem[]>([]);
    const [metaData, setMetaData] = useState<IMetaData>(initialMetaData);

    const { statusLabels, initialBarChart } = useMemo(() => {
        const statusLabels = Object.keys(ClearanceStatuses)
            .filter((key) => !FILTERED_STATUSES.includes(ClearanceStatuses[key]))
            .map((key) => ClearanceStatuses[key]);

        const initialBarChart: ChartItem[] = new Array<ChartItem>(statusLabels.length)
            .fill({
                xAxis: '',
                yAxis: 0,
                color: CommunicationColors.primary,
            })
            .map((item: ChartItem, index: number) => {
                return {
                    ...item,
                    xAxis: statusLabels[index],
                };
            });

        return { statusLabels, initialBarChart };
    }, []);

    useEffect(() => {
        setBarChartData(initialBarChart);
    }, [initialBarChart]);

    useEffect(() => {
        const statusCount = new Map<string, number>();
        props.records.forEach((record) => {
            let status = record.status;

            // Sometimes statuses are stored with backend strings instead of front end strings
            // ie. the string for "record.status" is stored as, for example, "Upgraded to ISSA" and sometimes as "UpgradedToISSA"
            if (!statusLabels.includes(status)) status = ClearanceStatuses[status];

            const count = statusCount.get(status);
            if (count) {
                statusCount.set(status, count + 1);
            } else {
                statusCount.set(status, 1);
            }
        });

        const updatedChartData = [...initialBarChart];

        statusLabels.forEach((label, index) => {
            updatedChartData[index].yAxis = statusCount.get(label) || 0;
        });

        setBarChartData(updatedChartData);

        const updatedMetaData = { ...initialMetaData };

        updatedMetaData.filteredRecords = props.records.length;

        updatedMetaData.activeRecords = statusCount.get(ClearanceStatuses.Active) || 0;

        if (updatedMetaData.activeRecords === 0) {
            updatedMetaData.percentActiveRecords = '0%';
        } else {
            updatedMetaData.percentActiveRecords =
                `${
                    (updatedMetaData.activeRecords / updatedMetaData.filteredRecords) * 100
                }`.substring(0, 4) + '%';
        }

        setMetaData(updatedMetaData);
    }, [props.records, statusLabels, initialBarChart]);

    return (
        <Stack styles={overallGraphStack}>
            <Stack styles={recordBarChartStack}>
                <BarChart barChartData={barChartData} displayDataLabels={true} hideLegend={true} />
            </Stack>
            <Stack styles={recordTextBoxInfoStack}>
                <ul>
                    <li>{`${metaData?.percentActiveRecords} of Records Active`}</li>
                    <li>{`${metaData?.activeRecords} Records Active`}</li>
                </ul>
            </Stack>
        </Stack>
    );
}
