import React, { useState } from 'react';
import {
    mergeStyleSets,
    DefaultButton,
    PrimaryButton,
    FontWeights,
    FontSizes,
    TextField,
    Label,
    Dropdown,
    IDropdownOption,
    MessageBar,
    MessageBarType,
} from '@fluentui/react';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';

export interface WithdrawCandidateProps {
    screening: ICommonScreening;
    onClose: () => void;
    onWithdrawCandidate(screening: ICommonScreening): Promise<void>;
}

const withdrawReasonOptions: IDropdownOption[] = [
    { key: '-', text: 'Select reason for withdrawal' },
    { key: 'personal', text: 'Personal Reasons' },
    { key: 'clearance', text: 'Do not meet clearance pre-requisites' },
    { key: 'businessJustification', text: 'Business justification no longer valid' },
    { key: 'other', text: 'Other' },
];

export default function WithdrawCandidate(props: WithdrawCandidateProps): JSX.Element {
    const [withdrawalReason, setWithdrawalReason] = useState<IDropdownOption | undefined>(
        withdrawReasonOptions.find((x) => x.text === props.screening.withdrawalReason),
    );
    const [withdrawalNotes, setWithdrawalNotes] = useState<string>('');
    const [isValidationError, setIsValidationError] = useState<boolean>(false);

    function onWithdrawalReasonInputChange(
        event: React.FormEvent<HTMLDivElement>,
        item?: IDropdownOption,
    ): void {
        if (item) {
            if (item.key !== '-') {
                setWithdrawalReason(item);
            } else {
                setWithdrawalReason(undefined);
            }
        }
    }

    function onNotesInputChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void {
        if (newValue) {
            setWithdrawalNotes(newValue);
        }
    }

    async function onSubmitWithdrawCandidateModal(): Promise<void> {
        if (withdrawalReason) {
            const updatedScreening: ICommonScreening = {
                ...props.screening,
                withdrawalReason: withdrawalReason.text,
                withdrawalNotes: withdrawalNotes,
                withdrawalUtcMillis: new Date().getTime(),
            };
            await props.onWithdrawCandidate(updatedScreening);
            props.onClose();
        } else {
            setIsValidationError(true);
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <span>Withdraw from Screening</span>
            </div>
            <div className={styles.body}>
                <div className={styles.mainPanel}>
                    {isValidationError && (
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            overflowButtonAriaLabel='See more'
                            onDismiss={() => setIsValidationError(false)}>
                            A reason for withdrawal is required.
                        </MessageBar>
                    )}
                    <div>
                        You may request to withdraw from US Gov Clearance Screening at any point
                        prior to the completion of your investigation. Upon receipt of your request,
                        the National Security Team will review and act on your request.
                    </div>
                    <div className={styles.withdrawalInputs}>
                        <Dropdown
                            label='Withdrawal Reason'
                            defaultSelectedKey={withdrawalReason?.key}
                            onChange={onWithdrawalReasonInputChange}
                            placeholder='Select reason for withdrawal'
                            options={withdrawReasonOptions}
                            required
                        />
                    </div>
                    <div className={styles.withdrawalInputs}>
                        <Label>Notes</Label>
                        <TextField onChange={onNotesInputChange} multiline autoAdjustHeight />
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <span className={styles.footerBtn}>
                    <DefaultButton onClick={props.onClose}>Close</DefaultButton>
                </span>
                <span className={styles.footerBtn}>
                    <PrimaryButton onClick={onSubmitWithdrawCandidateModal}>Submit</PrimaryButton>
                </span>
            </div>
        </div>
    );
}

const styles = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 400,
        maxWidth: 600,
    },
    header: {
        flex: '1 1 auto',
        borderTop: `4px solid`,
        display: 'flex',
        fontSize: FontSizes.xLargePlus,
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '25px 30px 10px 30px',
        boxSizing: 'border-box',
    },
    body: {
        minWidth: 300,
        padding: '0 30px 30px 30px',
        display: 'flex',
        flexDirection: 'row',
    },
    mainPanel: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: 'rgb(253, 253, 253)',
        padding: '14px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    footerBtn: {
        marginRight: '10px',
    },
    withdrawalInputs: {
        marginTop: '10px',
    },
});
