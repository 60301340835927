import React, { useState } from 'react';
import { ActionButton, mergeStyleSets, PrimaryButton, Stack } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import DeleteModelKeyModalButton from 'components/email/buttons/delete-model-key-modal-button';
import Spacer from 'components/common/spacer';
import { useTextField } from 'components/common/use-input/use-textfield';

interface IModelKeysProps {
    modelFields?: string[];
    handleAddModelKey: (key: string) => void;
    handleDeleteModelKey: (key: string) => Promise<void>;
}

export default function ModelKeysComponent(props: IModelKeysProps) {
    const [showModelKeys, setShowModelKeys] = useState<boolean>(!props.modelFields?.length ?? true);
    const [showAddModelKey, setShowAddModelKey] = useState<boolean>(
        !props.modelFields?.length ?? true,
    );

    const {
        value: addModelKeyValue,
        initialize: initAddModelKey,
        theElement: theAddModelKeyElement,
    } = useTextField({
        label: 'Add Key',
        resizable: false,
        placeholder: 'key name',
    });

    const handleOnShowModelKeys = () => {
        setShowModelKeys((prevValue) => !prevValue);
        setShowAddModelKey((prevValue) => !prevValue);
    };

    return (
        <div>
            <ActionButton
                onClick={handleOnShowModelKeys}
                iconProps={{
                    iconName: showModelKeys ? IconNames.ChevronDown : IconNames.ChevronRight,
                }}>
                Model Keys
            </ActionButton>
            {props.modelFields && props.modelFields.length > 0 && showModelKeys && (
                <ul className={styles.modelKeyListWrapper}>
                    {props.modelFields.map((field, i) => (
                        <li key={`${i}_${field}`} className={styles.modelKeyListItem}>
                            <DeleteModelKeyModalButton
                                modelKey={field}
                                handleDeleteModelKey={props.handleDeleteModelKey}
                            />
                            {field}
                        </li>
                    ))}
                </ul>
            )}
            {showAddModelKey && (
                <>
                    <Stack className={styles.addModel} horizontal>
                        {theAddModelKeyElement()}
                        <ActionButton
                            iconProps={{ iconName: IconNames.Cancel }}
                            className={styles.cancelAddModelKeyButton}
                            onClick={(): void => {
                                initAddModelKey('');
                            }}>
                            Clear
                        </ActionButton>
                    </Stack>
                    <Spacer marginTop={10} />
                    <PrimaryButton
                        disabled={!addModelKeyValue}
                        text='Add key'
                        onClick={() => {
                            if (!!addModelKeyValue) {
                                props.handleAddModelKey(addModelKeyValue);
                                initAddModelKey('');
                            }
                        }}
                    />
                    <Spacer marginTop={20} />
                </>
            )}
        </div>
    );
}

const styles = mergeStyleSets({
    modelKeyListWrapper: {
        paddingLeft: 0,
        marginTop: 0,
    },
    addModel: {
        alignItems: 'flex-end',
    },
    cancelAddModelKeyButton: {
        position: 'relative',
        top: 5,
    },
    modelKeyListItem: {
        listStyleType: 'none',
        display: 'flex',
        alignItems: 'center',
    },
});
