import React from 'react';
import { TooltipHost, mergeStyles } from '@fluentui/react';

/**
 * @param {string | null | undefined } text The text that may be truncated with ellipsis.
 * @param {number | undefined} width To make ellipsis appear, you need to fix width of this
 * component's instance. When used with DetailsList, you can use column.currentWidth or
 * column.calculatedWidth as shown in the following example:
 * @example
 * Column definition of type IColumn:
 *      {
 *          key: "column key",
 *          name: "column name",
 *          minWidth: asDesired,
 *          maxWidth: asDesired,
 *          onRender: (row: IDataType, index?: number, column?: IColumn): JSX.Element => {
 *              return (
 *                  <TableCell>
 *                      <EllipsisTextCss width={column?.currentWidth} text={row.columnContent} />
 *                  </TableCell>
 *              );
 *          },
 *      },
 * @param {string?} tipText Will default to props.text if not specified.
 */

interface IEllipsisTextCssProps {
    text?: string | null;
    width?: number;
    tipText?: string;
}

export default function EllipsisTextCss(props: IEllipsisTextCssProps): JSX.Element {
    return (
        <TooltipHost
            content={props.tipText ?? props.text ?? ''}
            hostClassName={mergeStyles({
                width: props.width,
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            })}>
            <span aria-label={props.text as string | undefined} style={{ width: props.width }}>
                {props.text}
            </span>
        </TooltipHost>
    );
}
