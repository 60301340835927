export enum StepTime {
    'PAST',
    'CURRENT',
    'FUTURE',
}

export type ScreeningStatusLabel = {
    label: string;
    order: number;
};

export const ScreeningStatusLabels: { [key: string]: ScreeningStatusLabel } = {
    Nomination: {
        label: 'NOMINATION',
        order: 0,
    },
    Preparation: {
        label: 'PREPARATION',
        order: 1,
    },
    Submitted: {
        label: 'SUBMITTED',
        order: 2,
    },
    Adjudicated: {
        label: 'ADJUDICATED',
        order: 3,
    },
    Indoc: {
        label: 'INDOC',
        order: 4,
    },
    Completed: {
        label: 'COMPLETED',
        order: 5,
    },
    Withdrawn: {
        label: 'WITHDRAWN',
        order: 6,
    },
};

export interface IStep {
    label: string;
    time: StepTime;
}

export const StepIndicatorData: IStep[] = [
    {
        label: ScreeningStatusLabels.Nomination.label,
        time: StepTime.PAST,
    },
    {
        label: ScreeningStatusLabels.Preparation.label,
        time: StepTime.CURRENT,
    },
    {
        label: ScreeningStatusLabels.Submitted.label,
        time: StepTime.FUTURE,
    },
    {
        label: ScreeningStatusLabels.Adjudicated.label,
        time: StepTime.FUTURE,
    },
    {
        label: ScreeningStatusLabels.Indoc.label,
        time: StepTime.FUTURE,
    },
    {
        label: ScreeningStatusLabels.Completed.label,
        time: StepTime.FUTURE,
    },
];

export function transformStepIndicatorDataTimes(
    displayData: IStep[],
    currentStepTarget: string,
): IStep[] {
    currentStepTarget = currentStepTarget.toUpperCase();
    let currentStepTime = StepTime.PAST;
    return displayData.map((step) => {
        if (step.label === currentStepTarget) {
            currentStepTime = StepTime.CURRENT;
        } else if (step.label !== currentStepTarget && currentStepTime === StepTime.CURRENT) {
            currentStepTime = StepTime.FUTURE;
        }

        return {
            label: step.label,
            time: currentStepTime,
        };
    });
}
