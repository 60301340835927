/**
 * Team: Personnel - Screening Team
 * Summary: This is the 'Request Document' button and modal in Screening Details page. Used in document-table.tsx.
 * The modal will open up Document Requester which will actually have the logic for requesting a document.
 * Last Updated Summary: 10/7/2022 by andreli
 **/
import React, { useState, useContext } from 'react';
import {
    mergeStyleSets,
    DefaultButton,
    PrimaryButton,
    FontWeights,
    FontSizes,
    TextField,
} from '@fluentui/react';
import TemplateClient, {
    IScreeningTemplateRequestRecord,
    ScreeningTemplateRequestedState,
} from 'clients/template-client';
import { AuthContext } from 'contexts/auth-context';
import PublicTrustDocumentClient from 'clients/screening/public-trust-document-client';
import { ScreeningPaths } from 'components/screening/common/common-constants';

export interface UpdateRequestModalProps {
    screeningPath: ScreeningPaths;
    isEditable: boolean | undefined;
    templateData: IScreeningTemplateRequestRecord | undefined;
    onClose: () => void;
    onCancelOrSubmit: (template: IScreeningTemplateRequestRecord, isCancel: boolean) => void;
}

export default function UpdateRequestModal(props: UpdateRequestModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [templateData, setTemplateData] = useState<IScreeningTemplateRequestRecord | undefined>(
        props.templateData,
    );
    const [updateRequestNotes, setUpdateRequestNotes] = useState<string | undefined>(
        props.templateData?.message,
    );
    const [isUpdateRequestRecord, setUpdateRequestRecord] = useState<boolean>(false);
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

    function onNotesInputChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void {
        if (newValue != null) {
            setUpdateRequestNotes(newValue);
        }
    }

    async function onCancelUpdateRequestModal(): Promise<void> {
        setSubmitDisabled(true);
        try {
            if (templateData) {
                setUpdateRequestRecord(true);
                templateData.requestedState = ScreeningTemplateRequestedState.ReRequestedCancel;
                setTemplateData({ ...templateData });
                let updatedRequest: IScreeningTemplateRequestRecord[] = [];
                if (props.screeningPath === ScreeningPaths.UsGov) {
                    updatedRequest = await TemplateClient.updateMultipleDocuments(authContext, [
                        templateData,
                    ]);
                } else {
                    updatedRequest = await PublicTrustDocumentClient.updateMultipleDocuments(
                        authContext,
                        [templateData],
                    );
                }
                props.onCancelOrSubmit(updatedRequest[0], true);
                props.onClose();
            }
        } catch {
            setUpdateRequestRecord(false);
            setSubmitDisabled(false);
        }
    }

    async function onSubmitUpdateRequestModal(): Promise<void> {
        setSubmitDisabled(true);
        try {
            if (updateRequestNotes != null && templateData) {
                setUpdateRequestRecord(true);
                templateData.requestedState = ScreeningTemplateRequestedState.ReRequestedState;
                templateData.message = updateRequestNotes;
                setTemplateData({ ...templateData });

                let updatedRequest: IScreeningTemplateRequestRecord[] = [];
                if (props.screeningPath === ScreeningPaths.UsGov) {
                    updatedRequest = await TemplateClient.updateMultipleDocuments(authContext, [
                        templateData,
                    ]);
                } else {
                    updatedRequest = await PublicTrustDocumentClient.updateMultipleDocuments(
                        authContext,
                        [templateData],
                    );
                }
                props.onCancelOrSubmit(updatedRequest[0], false);
                props.onClose();
            }
        } catch {
            setUpdateRequestRecord(false);
            setSubmitDisabled(false);
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <span>
                    {props.isEditable
                        ? 'Update Document Request Notes'
                        : 'View Document Request Notes'}
                </span>
            </div>
            <div className={styles.body}>
                <div className={styles.mainPanel}>
                    <TextField
                        styles={{
                            field: {
                                color: 'black',
                                maxHeight: 500,
                                overflowY: 'auto',
                                maxWidth: 700,
                                overflowX: 'auto',
                            },
                        }}
                        value={updateRequestNotes || ''}
                        disabled={!props.isEditable}
                        onChange={onNotesInputChange}
                        multiline
                        rows={25}
                        autoAdjustHeight
                    />
                </div>
            </div>
            <div className={styles.footer}>
                {props.isEditable && (
                    <span className={styles.cancelFooterBtn}>
                        <DefaultButton
                            onClick={onCancelUpdateRequestModal}
                            disabled={isUpdateRequestRecord}>
                            Cancel Update Request
                        </DefaultButton>
                    </span>
                )}
                <span className={styles.footerBtn}>
                    <DefaultButton onClick={props.onClose}>Close</DefaultButton>
                </span>
                {props.isEditable && (
                    <span className={styles.footerBtn}>
                        <PrimaryButton
                            onClick={onSubmitUpdateRequestModal}
                            disabled={isUpdateRequestRecord || isSubmitDisabled}>
                            Submit
                        </PrimaryButton>
                    </span>
                )}
            </div>
        </div>
    );
}

const styles = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 650,
        maxWidth: 770,
        maxHeight: 700,
    },
    header: {
        flex: '1 1 auto',
        borderTop: `4px solid`,
        display: 'flex',
        fontSize: FontSizes.xLargePlus,
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '25px 30px 10px 30px',
        boxSizing: 'border-box',
    },
    body: {
        minWidth: 300,
        padding: '0 30px 30px 30px',
        display: 'flex',
        flexDirection: 'row',
    },
    mainPanel: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: 'rgb(253, 253, 253)',
        padding: '14px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    footerBtn: {
        marginRight: '10px',
    },
    cancelFooterBtn: {
        marginRight: 'auto',
    },
});
