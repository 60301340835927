import { IFiltersContext } from 'contexts/filters-context';
import { IUserContext, ServiceProviderType } from 'contexts/user-context';
import React from 'react';
import { IMultiChoiceFilterItem } from 'types/multi-choice-filter-Item';
import { milisecondsPerDayMinus1 } from 'utils/time-utils';
import {
    clearanceStr,
    employeeStatusStr,
    requestTypeStr,
    ScreeningPaths,
    StateName,
    UsGovScreeningUserType,
} from 'components/screening/common/common-constants';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';

/**
 * Determines if current user is a Contract Owner or an NST user type.
 * @param userContext User Context.
 * @returns Boolean indicating if user has access because they are
 * a Contract Owner or an NST user type.
 */
export function isUserContractOwnerOrNST(
    userContext: IUserContext,
    screeningPath: ScreeningPaths,
): boolean {
    if (userContext.isPublicTrustUserTypesLoaded && screeningPath === ScreeningPaths.PublicTrust) {
        return (
            userContext.hasPublicTrustUserType(UsGovScreeningUserType.NST) ||
            userContext.hasPublicTrustUserType(UsGovScreeningUserType.ContractOwner)
        );
    }
    if (userContext.isUsGovScreeningUserTypesLoaded && screeningPath === ScreeningPaths.UsGov) {
        return (
            userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST) ||
            userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.ContractOwner)
        );
    }
    return false;
}

export function onDeclinedStatusOptionChange(
    selectedDeclinedStates: string[],
    declinedStatus: string,
    setSelectedDeclinedStates: (value: React.SetStateAction<string[]>) => void,
    checked?: boolean,
): void {
    const isCurrentlySelected: boolean = selectedDeclinedStates.includes(declinedStatus);

    if (checked && !isCurrentlySelected) {
        setSelectedDeclinedStates((arr) => [...arr, declinedStatus]);
    } else if (!checked && isCurrentlySelected) {
        setSelectedDeclinedStates((arr) => arr.filter((x) => x !== declinedStatus));
    }
}

function getMillisecondsTime(isMilliseconds: boolean, time: number): number {
    // atUTC is in seconds in us gov
    // atUTC is in milliseconds in Public trust to be more consistent
    return isMilliseconds ? time : time * 1000;
}

// start of for 'Last Status Change' filter helper functions
export function getFilterfunction(
    isStart: boolean,
    date: Date | undefined,
    startDate: Date | undefined,
    endDate: Date | undefined,
    isMilliseconds: boolean,
): (data: ICommonScreening) => boolean {
    if (isStart) {
        return (data: ICommonScreening): boolean => {
            const statusChangedAtUtc = data.statusChangedAtUtc;
            if (statusChangedAtUtc) {
                const time = getMillisecondsTime(isMilliseconds, statusChangedAtUtc);
                if (date) {
                    if (endDate) {
                        return (
                            time >= date.getTime() &&
                            time <= endDate.getTime() + milisecondsPerDayMinus1
                        );
                    }
                    return time >= date.getTime();
                }
            } else if (date) {
                // if the user sets a valid date but the table row doesn't have statusChanged.atUTC then don't show it when we filter
                return false;
            }

            return true;
        };
    } else {
        return (data: ICommonScreening): boolean => {
            const statusChangedAtUtc = data.statusChangedAtUtc;
            if (statusChangedAtUtc) {
                const time = getMillisecondsTime(isMilliseconds, statusChangedAtUtc);
                if (date) {
                    if (startDate) {
                        return (
                            time <= date.getTime() + milisecondsPerDayMinus1 &&
                            time >= startDate.getTime()
                        );
                    }
                    return time <= date.getTime() + milisecondsPerDayMinus1;
                }
            } else if (date) {
                // if the user sets a valid date but the table row doesn't have statusChanged.atUTC then don't show it when we filter
                return false;
            }
            return true;
        };
    }
}

export function IsCheckboxChecked(
    filtersContext: IFiltersContext,
    group: string,
    label: string,
): boolean {
    let toTable: string[] = [];
    if (group === 'status') toTable = filtersContext.status.split(',');
    else if (group === clearanceStr) toTable = filtersContext.clearance.split(',');
    else if (group === requestTypeStr) toTable = filtersContext.requestType.split(',');
    else if (group === employeeStatusStr) toTable = filtersContext.employeeStatus.split(',');
    return toTable.includes(label);
}

export function labelFunction(
    label: string,
    dataArray: ICommonScreening[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterFunction: (data: any) => boolean,
    showCount: boolean,
): string {
    let retLabel = label;
    if (showCount) {
        let count = 0;

        dataArray.filter((test: ICommonScreening) => {
            return filterFunction(test);
        });
        if (Array.isArray(dataArray)) {
            count = dataArray.filter((data) => {
                return filterFunction(data);
            }).length;
        }
        retLabel = retLabel + ' (' + count + ')';
    }
    return retLabel;
}

export function updateFilterContext(
    value: IMultiChoiceFilterItem,
    adding: boolean,
    filterCtxKey: string,
    filterCtxSetter: (arg0: string) => void,
    filtersContext: IFiltersContext,
    screeningPath: ScreeningPaths = ScreeningPaths.UsGov,
): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentStatus: string = (filtersContext as any)[filterCtxKey];
    const label: string = value.label;
    if (adding) {
        if (currentStatus) {
            const currentStatusToTable = currentStatus.split(',');
            currentStatusToTable.push(label);
            filterCtxSetter(currentStatusToTable.toString());
        } else {
            filterCtxSetter(label);
        }
    } else {
        const currentStatusToTable = currentStatus.split(',');
        if (currentStatusToTable.length > 1) {
            const newTable = currentStatusToTable.filter((val) => val !== label);
            filterCtxSetter(newTable.toString());
        } else filterCtxSetter('');
    }

    if (screeningPath === ScreeningPaths.PublicTrust) {
        filtersContext.updateFilterFunctionsPublicTrust(filterCtxKey, value.filterFunction, adding);
    } else {
        filtersContext.updateFilterFunctions(filterCtxKey, value.filterFunction, adding);
    }
}

export function filterOnParentState(screening: ICommonScreening, childState: StateName[]): boolean {
    // We don't want "Declined" substates to show up under their parent states
    // TODO: Move these declined substates to Withdrawn?
    if (
        screening.stateName === StateName.ContractOwnerDenied ||
        screening.stateName === StateName.NomineeDeclined
    ) {
        return false;
    }
    if (screening.stateName === StateName.AdjudicatedDenied) {
        return false;
    }
    return childState.includes(screening.stateName);
}
