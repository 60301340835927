import { inputFieldErrorColor } from 'assets/constants/global-colors';
import { IconNames } from 'assets/constants/global-constants';
import {
    HoverCard,
    HoverCardType,
    Icon,
    IPlainCardProps,
    Label,
    mergeStyleSets,
    Stack,
} from '@fluentui/react';
import React, { ReactNode } from 'react';

interface InfoIconLabelProps {
    required?: boolean; // Will show a reddish asterisk next to the label
    iconName?: string;
    iconHoverContent: ReactNode; // The content that will pop up when cursor is rest on the icon
    ariaLabel?: string;
    children: ReactNode; // The contents of the <Label>
}

export default function LabelInfoIcon(props: InfoIconLabelProps): JSX.Element {
    const onRenderPlainCard = (): JSX.Element => (
        <div className={styles.content}>{props.iconHoverContent}</div>
    );
    const plainCardProps: IPlainCardProps = {
        onRenderPlainCard: onRenderPlainCard,
        renderData: <Icon iconName={IconNames.Info} />,
    };

    return (
        <div>
            <Stack horizontal verticalAlign='center'>
                <Label>{props.children}</Label>
                <HoverCard
                    plainCardProps={plainCardProps}
                    instantOpenOnClick={true}
                    type={HoverCardType.plain}>
                    <Stack horizontal verticalAlign='center'>
                        &nbsp;
                        <Icon iconName={props.iconName ?? IconNames.Info} className={styles.icon} />
                        &nbsp;
                    </Stack>
                </HoverCard>
                {props.required && (
                    <Stack.Item>
                        <span className={styles.required}>*</span>
                    </Stack.Item>
                )}
            </Stack>
        </div>
    );
}

const styles = mergeStyleSets({
    required: {
        // The following is the same color shown on a
        // Fluent label of a required input field.
        color: inputFieldErrorColor,
    },
    content: {
        padding: '10px 15px',
    },
    icon: {
        cursor: 'default',
    },
});
