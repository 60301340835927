import { ActionButton, FontIcon, mergeStyles } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import React, { ReactNode } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { elementWrapperStyle, FormElement } from 'components/forms/forms-common';

const cardButtonStyles = mergeStyles({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '4px',
    position: 'relative',
    marginBottom: '1rem',
});

const dragAndDropIconStyles = mergeStyles({
    fontSize: '23px',
    transform: 'rotate(90deg)',
    position: 'absolute',
    right: '50%',
});

const cardContainerStyles = mergeStyles(elementWrapperStyle, globalStyles.boxShadow);

type ElementCardProps = {
    displayIndex: number;
    element: FormElement;
    children?: JSX.Element | ReactNode | JSX.Element[] | ReactNode[];
    deleteElement: (id: string) => void;
    shiftElement: (id: string, direction: 'up' | 'down') => void;
    provided: { dragHandleProps: DraggableProvidedDragHandleProps };
};
export const ElementCard = (props: ElementCardProps): JSX.Element => {
    const { displayIndex, element, children, deleteElement, shiftElement, provided } = props;

    return (
        <div className={cardContainerStyles}>
            <div className={cardButtonStyles}>
                {displayIndex > 0 && <h3 style={{ marginRight: 'auto' }}>{displayIndex + '.'}</h3>}
                <div {...provided.dragHandleProps}>
                    <FontIcon
                        className={dragAndDropIconStyles}
                        title='Drag element'
                        aria-label='Drag'
                        iconName='GripperDotsVertical'
                    />
                </div>
                <ActionButton
                    title='Delete element'
                    aria-label='Delete'
                    iconProps={{ iconName: IconNames.Trash }}
                    onClick={() => deleteElement(element.id)}
                />
                <ActionButton
                    title='Move question up'
                    aria-label='Move question up'
                    iconProps={{ iconName: IconNames.SortUp }}
                    onClick={() => shiftElement(element.id, 'up')}
                />
                <ActionButton
                    title='Move question down'
                    aria-label='Move question down'
                    iconProps={{ iconName: IconNames.SortDown }}
                    onClick={() => shiftElement(element.id, 'down')}
                />
            </div>
            {children}
        </div>
    );
};
