import { SharedColors } from '@fluentui/theme';
import { ActionButton, IColumn } from '@fluentui/react';
import React, { MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import {
    ClearanceClient,
    IClearanceRecord,
    IPolygraphRecord,
    ISpecialAccessRecord,
} from 'clients/clearance-client';
import { IEmployeeWithEditableData } from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import { dateToLocalDate, getDateTimeForFilename } from 'utils/time-utils';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import { Table, TableCell } from 'components/common/table';
import ClearanceDetails from 'components/personnel-profile/clearance/clearance-details';
import {
    clearanceStatusSpan,
    findClearanceLevel,
    getAgencyEnumValueFromKey,
    isClearanceActive,
    isSpecialAccessActive,
    polygraphPassResult,
    polygraphShortHand,
    specialAccessShortHand,
} from 'components/personnel-profile/clearance/profile-clearance-constants';
import {
    firstColumnTableCellPadding,
    firstColumnTitlePadding,
} from 'components/personnel-profile/common/common-constants';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import { IPagedResults } from 'clients/http-options';
import { contentMaxHeight } from 'assets/styles/list-styles';
import { jsonToCSV } from 'react-papaparse';
import { transformHeaderToHeaderCase } from 'utils/string-utils';
import { initiateBrowserDownload } from 'utils/file-utils';
import { formatClearanceReport } from 'utils/internal-reporting-utils';
import EllipsisText from 'components/common/ellipsis-text';
import { CacheContext } from 'contexts/cache-context';
import { commonPersonnelPersonnelStyles } from 'components/personnel-profile/common-personnel-profile-styles';
import { ContractType, IContract } from 'components/screening/us-gov/IContract';
import { getDefaultColumnWidths } from 'utils/table-utils';

export interface PersonnelProfileClearanceTableProps {
    employee: IEmployeeWithEditableData | undefined;
    clearances: IClearanceRecord[];
    specialAccesses: ISpecialAccessRecord[] | undefined;
    polygraphs: IPolygraphRecord[] | undefined;
    setClearances: (records: IClearanceRecord[]) => void;
    setUpdatedClearance: (record?: IClearanceRecord) => void;
    deleteRecordFunction: (id: string) => Promise<boolean>;
    cacheKey?: string;
}

export default function PersonnelProfileClearanceTable(
    props: PersonnelProfileClearanceTableProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [contracts, setContracts] = useState<IContract[]>([]);
    const [hasClearance, setHasClearance] = useState<boolean>(false);
    const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
    const downloadLink: MutableRefObject<HTMLAnchorElement | null> = useRef(null);
    const cacheContext = useContext(CacheContext);

    useEffect(() => {
        let isMounted = true;
        async function getContracts(): Promise<void> {
            setIsFetchingData(true);

            const contractArray: IContract[] = [];
            let continuationToken: string | undefined = '';
            try {
                do {
                    const response: IPagedResults<IContract> = await UsGovScreeningClient.getContracts(
                        authContext,
                        Object.keys(ContractType), // Get all contract types.
                        continuationToken,
                    );
                    contractArray.push(...response.results);
                    continuationToken = response.continuationToken;
                } while (isMounted && continuationToken);

                if (isMounted) {
                    setContracts(contractArray);
                }
            } catch (e) {
                console.error(
                    'error with getting clearance data from employee ',
                    props.employee,
                    ' --- ',
                    e,
                );
            } finally {
                setIsFetchingData(false);
            }
        }

        getContracts();
        return (): void => {
            isMounted = false;
        };
    }, [authContext]);

    useEffect(() => {
        setHasClearance(props.clearances.length > 0);
    }, [props.clearances]);

    function createRightEtiquette(
        etiquetteClearances: IClearanceRecord[],
    ): { label: string; backgroundColor: string; maxLength: number } | undefined {
        const highestActiveAgencyLevelMap = new Map<string, string>();

        for (let i = 0; i < etiquetteClearances.length; i++) {
            const clearance = props.clearances[i];
            const agency = getAgencyEnumValueFromKey(clearance.agency);
            if (isClearanceActive(clearance) && agency) {
                if (!highestActiveAgencyLevelMap.has(agency)) {
                    highestActiveAgencyLevelMap.set(agency, clearance.level);
                } else {
                    if (
                        // Is the newLevel higher then the currentLevel for this agency?
                        (findClearanceLevel(highestActiveAgencyLevelMap.get(agency))?.order || 0) >
                        (findClearanceLevel(clearance.level)?.order || 0)
                    ) {
                        highestActiveAgencyLevelMap.set(agency, clearance.level);
                    }
                }
            }
        }

        const agencyLevelStrings: string[] = [];
        highestActiveAgencyLevelMap.forEach((level, agency) => {
            agencyLevelStrings.push(`${findClearanceLevel(level)?.shortHand}(${agency})`);
        });

        if (agencyLevelStrings.length > 0) {
            return {
                label: `ACTIVE CLEARANCES: ${agencyLevelStrings.join(' & ')}`,
                backgroundColor: SharedColors.cyanBlue10,
                maxLength: 102,
            };
        }
        return undefined;
    }

    function updateCachedClearanceData(updatedClearanceRecord: IClearanceRecord): void {
        if (props.cacheKey && updatedClearanceRecord) {
            const cachedScreeningArray = cacheContext.retrieve<IClearanceRecord[]>(props.cacheKey);
            if (cachedScreeningArray) {
                const updatedCachedScreeningRecords = cachedScreeningArray.map((clearanceRecord) =>
                    clearanceRecord.id === updatedClearanceRecord.id
                        ? updatedClearanceRecord
                        : clearanceRecord,
                );
                cacheContext.store<IClearanceRecord[]>(
                    props.cacheKey,
                    updatedCachedScreeningRecords,
                );
            }
        }
    }

    async function upsertClearanceRecord(record: IClearanceRecord): Promise<boolean> {
        try {
            const result = await ClearanceClient.upsertClearance(authContext, record);
            const newClearances = props.clearances.filter(
                (x) => result.findIndex((y) => x.id !== y.id) > -1,
            );
            newClearances.push(...result);
            props.setClearances(newClearances);
            updateCachedClearanceData(record);
            // Reflect the clearance changes to the associated polygraphs and special accesses
            if (result.length > 0) {
                props.setUpdatedClearance(result[0]);
            }
            return true;
        } catch (e) {
            console.log('error on upsert', e);
            return false;
        }
    }

    async function downloadClearanceReport(employeeId: string): Promise<void> {
        if (!isDownloadingReport) {
            try {
                setIsDownloadingReport(true);
                const reportData = await ClearanceClient.getClearanceReportForEmployee(
                    authContext,
                    employeeId,
                );

                if (reportData && reportData.length > 0 && downloadLink?.current) {
                    const formattedData = formatClearanceReport(reportData);
                    const csv: string = transformHeaderToHeaderCase(jsonToCSV(formattedData));
                    const report = new Blob([csv], { type: 'text/csv' });
                    const fileName = `${
                        'Clearance_Report_For_' + employeeId
                    }_${getDateTimeForFilename()}`;
                    initiateBrowserDownload(report, fileName, 'csv', downloadLink.current);
                }
                setIsDownloadingReport(false);
            } catch (e) {
                console.error('error downloading clearance report', e);
            }
        }
    }

    return (
        <>
            {props.employee && (
                <ContainerWithEtiquettes
                    leftEtiquetteLabel='Clearance records'
                    rightEtiquette={createRightEtiquette(props.clearances)}
                    bottomInfo={
                        <>
                            {hasClearance && (
                                <ActionButton
                                    styles={{ root: { maxHeight: contentMaxHeight } }}
                                    title='Download Clearance Report'
                                    iconProps={{ iconName: 'DownloadDocument' }}
                                    onClick={(): Promise<void> =>
                                        downloadClearanceReport(props!.employee!.data?.id)
                                    }>
                                    <a ref={downloadLink} hidden={true} />
                                    Download report
                                </ActionButton>
                            )}
                            <ClearanceDetails
                                buttonIcon='AddToShoppingList'
                                buttonText='Add'
                                modalTitle='Add Clearance'
                                employee={props.employee}
                                upsertRecordFunction={upsertClearanceRecord}
                                contracts={contracts}
                            />
                        </>
                    }>
                    {props.clearances.length === 0 && !isFetchingData ? (
                        <p className={commonPersonnelPersonnelStyles.noDataParagraphMorePadding}>
                            No clearance records to display
                        </p>
                    ) : (
                        <Table<IClearanceRecord>
                            rows={props.clearances}
                            isFetchingData={isFetchingData}
                            shimmerLines={2}
                            tableColumns={generateColumns(
                                props.employee,
                                upsertClearanceRecord,
                                props.deleteRecordFunction,
                                contracts,
                                props.specialAccesses,
                                props.polygraphs,
                            )}
                            tableName='Clearance Records'
                        />
                    )}
                </ContainerWithEtiquettes>
            )}
        </>
    );
}

function constructSubTitle(
    clearanceRecord: IClearanceRecord | undefined,
    specialAccessRecords: ISpecialAccessRecord[] | undefined,
    polygraphRecords: IPolygraphRecord[] | undefined,
): string {
    let subTitle = '';
    let hasSubTitleBeenAddedAlready = false;
    if (clearanceRecord && clearanceRecord.id) {
        if (specialAccessRecords) {
            const validSpecialAccesses = specialAccessRecords
                .filter((x) => x.clearanceId === clearanceRecord.id)
                .filter((x) => isSpecialAccessActive(x) === true);
            subTitle = subTitle + specialAccessShortHand(validSpecialAccesses, 'Active SCI(s) ');
            if (validSpecialAccesses.length > 0) {
                hasSubTitleBeenAddedAlready = true;
            }
        }
        if (polygraphRecords) {
            const validPolygraphs = polygraphRecords
                .filter((x) => x.clearanceId === clearanceRecord.id)
                .filter((x) => x.results?.toLocaleLowerCase() === polygraphPassResult);
            if (hasSubTitleBeenAddedAlready && validPolygraphs.length > 0) {
                subTitle = subTitle + ' and ';
            }
            subTitle = subTitle + polygraphShortHand(validPolygraphs, 'Active Polygraph(s) ');
        }
    }
    return subTitle;
}

function generateColumns(
    employee: IEmployeeWithEditableData,
    upsertClearanceRecord: { (record: IClearanceRecord): Promise<boolean> },
    deleteRecordFunction: (id: string) => Promise<boolean>,
    contracts: IContract[],
    specialAccessRecords: ISpecialAccessRecord[] | undefined,
    polygraphRecords: IPolygraphRecord[] | undefined,
): IColumn[] {
    const columnWidths = getDefaultColumnWidths(7);
    return [
        {
            key: 'clearance_level',
            name: 'Level',
            ariaLabel: 'Level',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            styles: { cellTitle: { paddingLeft: firstColumnTitlePadding } }, // DetailsList applies this to the header cell.
            onRender: (row: IClearanceRecord): JSX.Element => (
                <TableCell style={{ paddingLeft: firstColumnTableCellPadding }}>
                    <EllipsisText
                        text={findClearanceLevel(row.level)?.shortHand}
                        textLengthBeforeEllipsis={12}
                    />
                </TableCell>
            ),
        },
        {
            key: 'clearance_status',
            name: 'Status',
            ariaLabel: 'Status',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true, // Style is taken from get-candidates-table-columns.tsx#getStatusLabel()
            onRender: (row: IClearanceRecord): JSX.Element => (
                <TableCell>{clearanceStatusSpan(row, 8)}</TableCell>
            ),
        },
        {
            key: 'clearance_agency',
            name: 'Agency',
            ariaLabel: 'Agency',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: IClearanceRecord): JSX.Element => (
                <TableCell>
                    <EllipsisText
                        text={getAgencyEnumValueFromKey(row.agency)}
                        textLengthBeforeEllipsis={6}
                    />
                </TableCell>
            ),
        },
        {
            key: 'clearance_grantedOn',
            name: 'Granted on',
            ariaLabel: 'Granted On',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: IClearanceRecord): JSX.Element => (
                <TableCell>{dateToLocalDate(row.grantDateUTCMilliseconds)}</TableCell>
            ),
        },
        {
            key: 'clearance_briefedOn',
            name: 'Briefed on',
            ariaLabel: 'Briefed On',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: IClearanceRecord): JSX.Element => (
                <TableCell>{dateToLocalDate(row.briefDateUTCMilliseconds)}</TableCell>
            ),
        },
        {
            key: 'clearance_contract',
            name: 'Contract',
            ariaLabel: 'Contract',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: IClearanceRecord): JSX.Element => (
                <TableCell>
                    <EllipsisText text={row.contractId} textLengthBeforeEllipsis={20} />
                </TableCell>
            ),
        },
        {
            key: 'clearance_action',
            name: 'Action',
            ariaLabel: 'Action',
            minWidth: columnWidths.actionColumnWidth,
            maxWidth: columnWidths.actionColumnWidth,
            isRowHeader: true,
            onRender: (row: IClearanceRecord): JSX.Element => (
                <TableCell>
                    <ClearanceDetails
                        buttonIcon='EntryView'
                        clearance={row}
                        buttonText='View'
                        modalTitle={`Clearance: ${
                            findClearanceLevel(row.level)?.shortHand ?? row.level
                        } (${getAgencyEnumValueFromKey(row.agency) ?? row.agency})`}
                        modalSubTitle={constructSubTitle(
                            row,
                            specialAccessRecords,
                            polygraphRecords,
                        )}
                        employee={employee}
                        upsertRecordFunction={upsertClearanceRecord}
                        deleteRecordFunction={deleteRecordFunction}
                        contracts={contracts}
                    />
                </TableCell>
            ),
        },
    ];
}
