import { DatePicker, Stack, TextField, TimePicker, Toggle } from '@fluentui/react';
import React, { useMemo } from 'react';
import ElementFooter from 'components/forms/element-footer';
import { ElementType, ElementTypeProps, ValidatorOptions } from 'components/forms/forms-common';

const start = 'start';
const end = 'end';
type DateElementToggleTypes = 'range';
type DateElementLabelTypes = typeof start | typeof end;

export default function DateElement(props: ElementTypeProps): JSX.Element {
    const { element, updateForm } = props;
    const currentDate = useMemo(() => new Date().toLocaleDateString(), []);

    const toggleTime = (checked: boolean): void => {
        updateForm(element.id, checked, 'hasTime');
    };

    const toggleFutureDates = (checked: boolean): void => {
        updateForm(element.id, checked, 'isFutureDatesOnly');
    };

    const changeElementTypeString = (
        toggleType: DateElementToggleTypes,
        checked: boolean,
    ): string => {
        return `date${checked ? (toggleType as string) : ''}`;
    };

    const changeElementType = (
        event: React.MouseEvent<HTMLElement>,
        toggleType: 'range',
        checked?: boolean,
    ): void => {
        let newType;
        const typeAsString = element.type as string;
        if (!typeAsString.includes(toggleType) && checked) {
            newType = changeElementTypeString(toggleType, checked);
        } else if (typeAsString.includes(toggleType) && checked === false) {
            newType = changeElementTypeString(toggleType, checked);
        }
        updateForm(element.id, newType as keyof typeof ElementType, 'type');
    };

    const DateRangeToggle = (): JSX.Element => (
        <>
            <Toggle
                checked={(element.type as string).includes('range')}
                label={<div style={{ fontWeight: 400 }}>Range</div>}
                onChange={(ev, checked): void => changeElementType(ev, 'range', checked)}
            />
            {/* Max date range restriction is 9999. If a larger range is needed, leave text box empty. */}
            {(element.type as string).includes('range') && (
                <div style={{ padding: '.7rem', width: '15rem' }}>
                    <TextField
                        ariaLabel='# of Days Range'
                        placeholder='Empty for Unrestricted date range'
                        maxLength={4}
                        value={(element.validatorOptions?.value as string) || ''}
                        onChange={(ev, newValue): void => onLimitChange(newValue ?? '')}
                        onRenderLabel={(): JSX.Element => <div># of Days Range</div>}
                    />
                </div>
            )}
        </>
    );

    const DateTimeToggle = (): JSX.Element => (
        <Toggle
            checked={element.hasTime}
            label={<div style={{ fontWeight: 400 }}>Time</div>}
            onChange={(ev, checked): void => toggleTime(checked ?? false)}
        />
    );

    const onLimitChange = (newValue?: string): void => {
        if (newValue === undefined) {
            return;
        } else if (newValue === '') {
            updateForm(element.id, undefined, 'validatorOptions');
        }

        const parsedValue = parseInt(newValue, 10);
        if (isNaN(parsedValue) || parsedValue < 0) {
            return;
        }
        const newValidation = { type: 'limit', value: parsedValue } as ValidatorOptions;
        updateForm(element.id, newValidation, 'validatorOptions');
    };

    const FutureDateToggle = (): JSX.Element => (
        <Toggle
            checked={element.isFutureDatesOnly}
            label={<div style={{ fontWeight: 400 }}>Future Dates Only</div>}
            onChange={(ev, checked): void => toggleFutureDates(checked ?? false)}
        />
    );

    const dateElement = (): JSX.Element => {
        return (
            <div style={{ marginBottom: '1.5rem' }}>
                <TextField
                    value={element.label}
                    onChange={(ev, newValue): void => updateForm(element.id, newValue, 'label')}
                    placeholder='Enter a label...'
                    underlined
                />
                <div style={{ marginTop: '1rem' }}>
                    <DatePicker
                        ariaLabel='Current Date'
                        placeholder={`Please input date (${currentDate})`}
                        disabled
                    />
                </div>
            </div>
        );
    };

    const returnLabel = (key: string): string | undefined =>
        !element.rangeLabels
            ? undefined
            : key === start
            ? element.rangeLabels.start ?? ''
            : element.rangeLabels.end ?? '';

    const setLabel = (key: DateElementLabelTypes, newValue?: string): void => {
        if (newValue === undefined) {
            return;
        }
        const existingLabels = element.rangeLabels ? element.rangeLabels : { start: '', end: '' };
        if (key === start) {
            updateForm(element.id, { start: newValue, end: existingLabels.end }, 'rangeLabels');
        } else {
            updateForm(element.id, { start: existingLabels.start, end: newValue }, 'rangeLabels');
        }
    };

    const dateTimeElement = (): JSX.Element => {
        return (
            <>
                <TextField
                    value={element.label}
                    onChange={(ev, newValue): void => updateForm(element.id, newValue, 'label')}
                    placeholder='Enter a label'
                    underlined
                />
                <Stack horizontal>
                    <Stack.Item style={{ padding: '1rem', width: '30%' }}>
                        <DatePicker
                            ariaLabel='Current Date'
                            placeholder={`Please input date (${currentDate})`}
                            disabled
                        />
                        <TimePicker disabled placeholder='Please input time' />
                    </Stack.Item>
                </Stack>
            </>
        );
    };

    const dateTimeRangeElement = (): JSX.Element => {
        return (
            <Stack>
                <Stack.Item style={{ marginBottom: '1.5rem' }}>
                    <TextField
                        value={element.label}
                        onChange={(ev, newValue): void => updateForm(element.id, newValue, 'label')}
                        placeholder='Label for Date Range element goes here..'
                        underlined
                    />
                </Stack.Item>
                <Stack>
                    <Stack.Item>
                        <TextField
                            value={returnLabel(start)}
                            onChange={(event, newValue): void => setLabel(start, newValue)}
                            placeholder='Start date label..'
                            underlined
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Stack
                            horizontal
                            wrap
                            verticalAlign='end'
                            tokens={{ childrenGap: '1rem' }}
                            style={{ margin: '.5rem' }}>
                            <Stack.Item style={{ width: '300px' }}>
                                <DatePicker
                                    ariaLabel='Start Date'
                                    placeholder={`Please select start date (${currentDate})`}
                                    disabled
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <TimePicker disabled placeholder='Please input time' />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            value={returnLabel(end)}
                            onChange={(event, newValue): void => setLabel(end, newValue)}
                            placeholder='End date label..'
                            underlined
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <Stack
                            horizontal
                            wrap
                            verticalAlign='end'
                            tokens={{ childrenGap: '1rem' }}
                            style={{ margin: '.5rem' }}>
                            <Stack.Item style={{ width: '300px' }}>
                                <DatePicker
                                    ariaLabel='End Date'
                                    placeholder={`Please select end date (${currentDate})`}
                                    disabled
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <TimePicker disabled placeholder='Please input time' />
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
        );
    };

    const dateRangeElement = (): JSX.Element => {
        return (
            <Stack>
                <Stack.Item style={{ marginBottom: '1.5rem' }}>
                    <TextField
                        value={element.label}
                        onChange={(ev, newValue): void => updateForm(element.id, newValue, 'label')}
                        placeholder='Label for Date Range element goes here..'
                        underlined
                    />
                </Stack.Item>
                <Stack horizontal>
                    <Stack.Item style={{ padding: '1rem', width: '30%' }}>
                        <TextField
                            value={returnLabel(start) ?? ''}
                            onChange={(event, newValue): void => setLabel(start, newValue)}
                            placeholder='Start date label..'
                            underlined
                        />
                        <DatePicker
                            ariaLabel='Start Date'
                            placeholder={`Please input start date (${currentDate})`}
                            disabled
                        />
                    </Stack.Item>
                    <Stack.Item style={{ padding: '1rem', width: '30%' }}>
                        <TextField
                            value={returnLabel(end) ?? ''}
                            onChange={(event, newValue): void => setLabel(end, newValue)}
                            placeholder='End date label..'
                            underlined
                        />
                        <DatePicker
                            ariaLabel='End Date'
                            placeholder={`Please input end date (${currentDate})`}
                            disabled
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
        );
    };

    const toggleElements = (): JSX.Element => {
        return (
            <>
                {DateTimeToggle()}
                {FutureDateToggle()}
                {DateRangeToggle()}
            </>
        );
    };

    const renderDateElement = (): JSX.Element => {
        if (element.type === 'daterange') {
            if (element.hasTime) {
                return dateTimeRangeElement();
            } else {
                return dateRangeElement();
            }
        } else {
            if (element.hasTime) {
                return dateTimeElement();
            } else {
                return dateElement();
            }
        }
    };

    return (
        <>
            {renderDateElement()}
            <ElementFooter
                element={element}
                updateForm={updateForm}
                hasRequiredToggle={true}
                CustomElement={toggleElements}
            />
        </>
    );
}
