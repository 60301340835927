import {
    ActionButton,
    ConstrainMode,
    IColumn,
    IDetailsColumnStyleProps,
    IDetailsColumnStyles,
    IDetailsHeaderStyles,
    IGroup,
    IGroupHeaderProps,
    IGroupRenderProps,
    IStyleFunctionOrObject,
    mergeStyleSets,
    Modal,
    PrimaryButton,
    Stack,
} from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Dictionary, IconNames } from 'assets/constants/global-constants';
import { IEmployee } from 'clients/employee-client';
import { dateToDateStringFormat } from 'utils/time-utils';
import { ICollapseButton, Table, TableCell } from 'components/common/table';
import {
    getCloudScreeningAction,
    getCloudScreeningStatus,
    getCloudScreeningStatusLabelEmployee,
    isCloudScreeningStatusActive,
    CloudScreeningAction,
    getPieChartData,
    getPieChartSideInfo,
    ICloudScreenChartSideInfo,
    createScopesElementFromRecords,
    getLatestCloudScreening,
} from 'components/screening/cloud-screening/cloud-screening-utils';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import CloudScreeningClient, {
    ICloudScreening,
    IScopeRecord,
} from 'clients/cloud-screening-client';
import { AuthContext } from 'contexts/auth-context';
import { PieChartItem } from 'components/common/charts/pie-chart';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';

import CloudScreeningPieChart from 'components/screening/cloud-screening/direct-reports/cloud-screening-pie-chart';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { faintBlue } from 'assets/constants/global-colors';
import { useFeatureFlag } from 'utils/use-feature-flags';

export interface CloudScreeningReportsTableProps {
    isRetrievingReports: boolean;
    isRetrievingScopes: boolean;
    employees: IEmployee[];
    reportScreeningsDict: Dictionary<ICloudScreening[]>;
    scopeRecordsDict: Dictionary<IScopeRecord[]>;
    option: 'Direct Reports' | 'Entire Org';
    upsertScreening: (screening: ICloudScreening) => void;
}

interface IOrganizationCompliance {
    passed: number;
    failed: number;
}

enum Column {
    Name = 'name', // header
    OrganizationCompliance = 'organizationcompliance',
    IndividualCompliance = 'individualcompliance',
    Expiration = 'expiration',
    RequiredFor = 'requiredfor',
    Action = 'action',
}

const sortedColumns = [
    Column.Name,
    Column.OrganizationCompliance,
    Column.IndividualCompliance,
    Column.Expiration,
];

const tableIndentWidth = 30;

export default function CloudScreeningReportsTable(
    props: CloudScreeningReportsTableProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [isRequestDisabled, setRequestDisabled] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [isRemindDisabled, setRemindDisabled] = useState<boolean>(false);
    const [columnDescendingOrderDict, setColumnDescendingOrderDict] = useState<Dictionary<boolean>>(
        getDefaultColumnDescendingOrderDict(),
    );
    // used by 'Entire Org'
    const [items, setItems] = useState<IEmployee[]>([]);
    // used by 'Direct Reports'
    const [highestLevel, setHighestLevel] = useState<number>(0);
    const [isCollapsing, setCollapsing] = useState<boolean>(true);
    const [graph, setGraph] = useState<Dictionary<string[]>>({}); // key is manager alias and value is aliases of their reports
    const [groups, setGroups] = useState<IGroup[]>([]);
    const [aliasEmployeeDict, setAliasEmployeeDict] = useState<Dictionary<IEmployee>>({});
    const [orgComplianceDict, setOrgComplianceDict] = useState<Dictionary<IOrganizationCompliance>>(
        {},
    ); // key is employee alias
    const [pieChartData, setPieChartData] = useState<PieChartItem[]>([]);
    const [pieChartSideInfo, setPieChartSideInfo] = useState<ICloudScreenChartSideInfo>();

    const isBackgroundCheckEnabled = useFeatureFlag('cloudScreening/hireRightApi').enabled;

    useEffect(() => {
        if (!props.isRetrievingReports && !props.isRetrievingScopes) {
            const chartData = getPieChartData(props.employees);
            const sideInfo = getPieChartSideInfo(props.employees, props.scopeRecordsDict);
            setPieChartData(chartData);
            setPieChartSideInfo(sideInfo);
        }
    }, [
        props.employees,
        props.scopeRecordsDict,
        props.isRetrievingReports,
        props.isRetrievingScopes,
    ]);

    useEffect(() => {
        if (props.employees) {
            setCollapsing(true);
            setColumnDescendingOrderDict(getDefaultColumnDescendingOrderDict());
            props.option === 'Direct Reports'
                ? handleDirectReports(props.employees)
                : setItems(props.employees);
        }
    }, [props.employees, props.option]);

    /**
     * Direct Reports table keeps drawing a ms-GroupSpacer at the start of the table, this
     * throws spacing off on rendering, this effect is here to remove it.
     * (happens on switch from entire-org table to direct reports when filtering)
     */
    useEffect(() => {
        const presentationHeader = document.getElementsByClassName('ms-DetailsList-headerWrapper');
        if (presentationHeader.length > 0) {
            const groupSpacers = presentationHeader[0].getElementsByClassName('ms-GroupSpacer');
            if (groupSpacers.length > 0) {
                (groupSpacers[0] as HTMLElement).style['width'] = '0px';
            }
        }
    });

    async function sendManagerReminder(employee: IEmployee): Promise<void> {
        setRemindDisabled(true);
        try {
            const updatedScreening = await CloudScreeningClient.postManagerReminder(
                authContext,
                employee.id,
            );
            props.upsertScreening(updatedScreening);
        } catch (error) {
            console.error(`Error reminding ${employee.alias} to complete screening.`, error);
        } finally {
            setRemindDisabled(false);
        }
    }

    function upsertScreening(screening: ICloudScreening): void {
        props.upsertScreening(screening);
    }

    const collapseButton: ICollapseButton = {
        isVisible: true,
        isCollapsing: isCollapsing,
        style: { color: faintBlue },
        onToggleCollapseAll: (isAllCollapsed: boolean) => {
            collapseManagerDirectReports(isAllCollapsed);
            /* TODO: Figure out if we only want one level collapsing or nested collapsing, if the latter move forward with this logic
            collapseAllGroups(isAllCollapsed);
            */
        },
    };
    const expandButtonStyle = {
        color: faintBlue,
    };
    const noExpandButtonStyle = {
        height: 0,
        color: '#FFFFFF',
        backgroundColor: '#FFFFFF',
        opacity: 0.4,
        selectors: {
            ':hover': {
                color: 'rgb(243, 242, 241)',
                backgroundColor: 'rgb(243, 242, 241)',
            },
        },
    };

    // used to make column titles multi-lined
    const columnHeaderStyles: Partial<IDetailsHeaderStyles> = {
        root: {
            selectors: {
                '.ms-DetailsHeader-cell': {
                    whiteSpace: 'normal',
                    textOverflow: 'clip',
                    lineHeight: 'normal',
                },
                '.ms-DetailsHeader-cellTitle': {
                    height: '100%',
                    alignItems: 'center',
                },
            },
        },
    };

    const groupProps: IGroupRenderProps = {
        showEmptyGroups: true,
        onRenderHeader: renderHeaderRow,
    };

    function renderHeaderRow(
        prop?: IGroupHeaderProps,
        defaultRender?: (props?: IGroupHeaderProps) => JSX.Element | null,
    ): JSX.Element | null {
        {
            if (prop) {
                prop.styles = {
                    expand: isManager(prop) ? expandButtonStyle : noExpandButtonStyle,
                    expandIsCollapsed: isManager(prop) ? expandButtonStyle : noExpandButtonStyle,
                };
                /* TODO: Figure out if we only want one level collapsing or nested collapsing, if the latter move forward with this logic
                props.onToggleCollapse = (group) => {
                    if (group.isCollapsed !== undefined) {
                        collapseGroups(!group.isCollapsed, group?.key);
                    }
                };
                */
            }

            return (
                <>
                    {defaultRender!({
                        ...prop,
                        onRenderTitle: renderDirectReportsTitleRows,
                    })}
                </>
            );
        }
    }

    function renderDirectReportsTitleRows(prop?: IGroupHeaderProps): JSX.Element | null {
        return prop && prop.group ? (
            <Stack horizontal>
                <Stack.Item
                    styles={{
                        root: {
                            margin: '0 10px 0 10px',
                            alignSelf: 'center',
                            minWidth: getHeaderWidth(
                                prop.groupLevel,
                                directReportColumnStyles.name.width,
                            ),
                            maxWidth: getHeaderWidth(
                                prop.groupLevel,
                                directReportColumnStyles.name.width,
                            ),
                        },
                    }}>
                    <EmployeeBasicHoverCard personnelId={aliasEmployeeDict[prop.group.key].id} />
                </Stack.Item>

                <Stack.Item styles={directReportColumnStyles.organizationCompliance.stackStyles}>
                    {getOrgComplianceString(prop)}
                </Stack.Item>

                <Stack.Item styles={directReportColumnStyles.individualCompliance.stackStyles}>
                    {getCloudScreeningStatusLabelEmployee(aliasEmployeeDict[prop.group.key])}
                </Stack.Item>

                <Stack.Item styles={directReportColumnStyles.expiration.stackStyles}>
                    {dateToDateStringFormat(getCloudScreenExpireDate(prop))}
                </Stack.Item>

                <Stack.Item styles={directReportColumnStyles.requiredFor.stackStyles}>
                    {createScopesElementFromRecords(
                        props.scopeRecordsDict[aliasEmployeeDict[prop.group.key].id],
                    )}
                </Stack.Item>

                <Stack.Item styles={directReportColumnStyles.action.stackStyles}>
                    {getAction(aliasEmployeeDict[prop.group.key])}
                </Stack.Item>
            </Stack>
        ) : null;
    }

    // adjust the name (header) width based on the space taken by nested grouping indentation
    function getHeaderWidth(level: number | undefined, initialWidth: number): number {
        const calLevel = level !== undefined ? level : 0;
        return calLevel > 0 ? initialWidth - calLevel * tableIndentWidth : initialWidth;
    }

    function collapseManagerDirectReports(isAllCollapsed: boolean): void {
        const newGroups: IGroup[] = [];

        groups.forEach((x) => {
            const copiedGroup: IGroup = {
                ...x,
                isCollapsed: isAllCollapsed,
            };
            newGroups.push(copiedGroup);
        });
        setGroups(newGroups);
        setCollapsing(!isCollapsing);
    }

    /* TODO: Figure out if we only want one level collapsing or nested collapsing, if the latter move forward with this logic
    function collapseAllGroups(isAllCollapsed: boolean): void {
        const newGroups: IGroup[] = [];

        groups.forEach((x) => {
            newGroups.push(getCollapseAllGroup(x, isAllCollapsed));
        });

        setGroups(newGroups);
        setCollapsing(!isCollapsing);
    }

    function getCollapseAllGroup(originalGroup: IGroup, isAllCollapsed: boolean): IGroup {
        const copiedGroup: IGroup = {
            ...originalGroup,
            isCollapsed: isAllCollapsed,
            children: originalGroup.children ? [] : undefined,
        };

        originalGroup.children?.forEach((x) => {
            copiedGroup.children?.push(getCollapseAllGroup(x, isAllCollapsed));
        });

        return copiedGroup;
    }

    function collapseGroups(isAllCollapsed: boolean, managerAlias: string): void {
        const newGroups: IGroup[] = [];

        groups.forEach((x) => {
            newGroups.push(getCollapseGroup(x, managerAlias, isAllCollapsed, false));
        });

        setGroups(newGroups);
    }

    function getCollapseGroup(originalGroup: IGroup, managerAlias: string, isAllCollapsed: boolean, canCollapse: boolean): IGroup {
        // start collapsing/uncollapsing only once we've reached the manager
        if (originalGroup.key === managerAlias) {
            canCollapse = true;
        }

        const copiedGroup: IGroup = {
            ...originalGroup,
            isCollapsed: canCollapse ? isAllCollapsed : originalGroup.isCollapsed,
            children: originalGroup.children ? [] : undefined,
        };

        originalGroup.children?.forEach((x) => {
            copiedGroup.children?.push(getCollapseGroup(x, managerAlias, isAllCollapsed, canCollapse));
        });

        return copiedGroup;
    }
    */

    function getDefaultColumnDescendingOrderDict(): Dictionary<boolean> {
        const dict: Dictionary<boolean> = {};

        sortedColumns.forEach((x) => (dict[x] = true));

        return dict;
    }

    function handleDirectReports(employees: IEmployee[]): void {
        const newAliasEmployeeDict = getAliasEmployeeDict(employees);
        setAliasEmployeeDict(newAliasEmployeeDict);

        const employeeAliases = getEmployeeAliases(employees);

        const newGraph = getGraph(employees, employeeAliases);
        setGraph(newGraph);

        // the starting nodes used in DFS
        const managerDirectReportAliases = getManagerDirectReportAliases(
            employees,
            employeeAliases,
        );

        setOrgComplianceDict(
            getOrgComplianceDict(managerDirectReportAliases, newGraph, newAliasEmployeeDict),
        );

        setGroups(getGroups(managerDirectReportAliases, newGraph));

        setHighestLevel(getHighestLevel(managerDirectReportAliases, newGraph));
    }

    function getAliasEmployeeDict(employees: IEmployee[]): Dictionary<IEmployee> {
        const newAliasEmployeeDict: Dictionary<IEmployee> = {};

        employees.forEach((x) => {
            if (x.alias) {
                newAliasEmployeeDict[x.alias.toLowerCase()] = x;
            }
        });

        return newAliasEmployeeDict;
    }

    function getEmployeeAliases(employees: IEmployee[]): Set<string> {
        const employeeAliases = new Set<string>();

        employees.forEach((x) => {
            if (x.alias) {
                employeeAliases.add(x.alias.toLowerCase());
            }
        });

        return employeeAliases;
    }

    function getGraph(employees: IEmployee[], employeeAliases: Set<string>): Dictionary<string[]> {
        const newGraph: Dictionary<string[]> = {};

        employees.forEach((x) => {
            const managerAlias = x.reportsToEmailName?.toLowerCase() ?? '';
            if (employeeAliases.has(managerAlias)) {
                if (!newGraph[managerAlias]) {
                    newGraph[managerAlias] = [];
                }
                if (x.alias) {
                    newGraph[managerAlias].push(x.alias.toLowerCase());
                }
            }
        });

        return newGraph;
    }

    function getManagerDirectReportAliases(
        employees: IEmployee[],
        employeeAliases: Set<string>,
    ): string[] {
        const managerDirectReportAliases: string[] = [];

        employees.forEach((x) => {
            if (
                x.reportsToEmailName &&
                !employeeAliases.has(x.reportsToEmailName?.toLowerCase()) &&
                x.alias
            ) {
                managerDirectReportAliases.push(x.alias.toLowerCase());
            }
        });
        return managerDirectReportAliases;
    }

    function getOrgComplianceDict(
        managerDirectReportAliases: string[],
        varGraph: Dictionary<string[]>,
        varAliasEmployeeDict: Dictionary<IEmployee>,
    ): Dictionary<IOrganizationCompliance> {
        const newOrgComplianceDict: Dictionary<IOrganizationCompliance> = {};

        managerDirectReportAliases.forEach((x) => {
            getOrgCompliance(x, varGraph, varAliasEmployeeDict, newOrgComplianceDict);
        });

        return newOrgComplianceDict;
    }

    function getOrgCompliance(
        currentEmployeeAlias: string,
        varGraph: Dictionary<string[]>,
        varAliasEmployeeDict: Dictionary<IEmployee>,
        varOrgComplianceDict: Dictionary<IOrganizationCompliance>,
    ): IOrganizationCompliance {
        const compliance = createOrgCompliance(currentEmployeeAlias, varAliasEmployeeDict);

        const directReports = varGraph[currentEmployeeAlias];

        if (directReports) {
            directReports.forEach((x) => {
                const reportCompliance = getOrgCompliance(
                    x,
                    varGraph,
                    varAliasEmployeeDict,
                    varOrgComplianceDict,
                );
                compliance.passed += reportCompliance.passed;
                compliance.failed += reportCompliance.failed;
            });
        }

        varOrgComplianceDict[currentEmployeeAlias] = compliance;

        return compliance;
    }

    function createOrgCompliance(
        currentEmployeeAlias: string,
        varAliasEmployeeDict: Dictionary<IEmployee>,
    ): IOrganizationCompliance {
        const compliance: IOrganizationCompliance = {
            passed: 0,
            failed: 0,
        };

        const status = getCloudScreeningStatus(varAliasEmployeeDict[currentEmployeeAlias]);
        if (isCloudScreeningStatusActive(status)) {
            compliance.passed++;
        } else {
            compliance.failed++;
        }

        return compliance;
    }

    function getGroups(
        managerDirectReportAliases: string[],
        varGraph: Dictionary<string[]>,
    ): IGroup[] {
        const newGroups: IGroup[] = [];
        managerDirectReportAliases.forEach((x) => {
            const topLevelGroup = getGroup(x, 0, varGraph);
            if (topLevelGroup) {
                newGroups.push(topLevelGroup);
            } else {
                // indicates top level manager direct report isn't a manager
                newGroups.push({
                    key: x,
                    name: x,
                    startIndex: 0,
                    count: 0,
                    level: 0,
                    isCollapsed: true,
                });
            }
        });

        return newGroups;
    }

    function getGroup(
        currentEmployeeAlias: string,
        level: number,
        varGraph: Dictionary<string[]>,
    ): IGroup | null {
        const directReports = varGraph[currentEmployeeAlias];

        // determine if a manager or not
        if (!directReports) {
            return null;
        }

        const group: IGroup = {
            key: currentEmployeeAlias,
            name: currentEmployeeAlias,
            startIndex: 0,
            count: directReports.length,
            level: level,
            isCollapsed: true,
            children: [],
        };

        const newLevel = level + 1;

        directReports.forEach((x) => {
            if (varGraph[x]) {
                // manager
                const nestedGroup = getGroup(x, newLevel, varGraph);
                if (nestedGroup) {
                    group.children?.push(nestedGroup);
                }
            } else {
                // non-manager
                group.children?.push({
                    key: x,
                    name: x,
                    startIndex: 0,
                    count: 0,
                    level: newLevel,
                    isCollapsed: true,
                });
            }
        });

        return group;
    }

    function getHighestLevel(
        managerDirectReportAliases: string[],
        varGraph: Dictionary<string[]>,
    ): number {
        let newHighestLevel = 0;

        managerDirectReportAliases.forEach((x) => {
            const level = getDepth(x, varGraph);
            if (level > newHighestLevel) {
                newHighestLevel = level;
            }
        });

        return newHighestLevel;
    }

    function getDepth(currentEmployeeAlias: string, varGraph: Dictionary<string[]>): number {
        const directReports = varGraph[currentEmployeeAlias];

        if (!directReports) {
            return 0;
        }

        let deepestLevel = 0;

        directReports.forEach((x) => {
            const level = getDepth(x, varGraph);
            if (level > deepestLevel) {
                deepestLevel = level;
            }
        });

        return deepestLevel + 1;
    }

    const directReportColumnStyles = {
        name: {
            width: 394,
            styles: {
                root: { alignItems: 'flex-start', minWidth: 394, maxWidth: 394 },
            } as IStyleFunctionOrObject<IDetailsColumnStyleProps, IDetailsColumnStyles>,
            //Because name is dynamic positioning the stackStyle cannot be defined here.
        },
        organizationCompliance: {
            width: 100,
            stackStyles: {
                root: {
                    minWidth: 80,
                    padding: '4px 8px 4px 10px',
                    maxWidth: 80,
                },
            },
        },
        individualCompliance: {
            width: 175,
            styles: {
                root: { alignItems: 'flex-start', minWidth: 175, maxWidth: 175 },
            } as IStyleFunctionOrObject<IDetailsColumnStyleProps, IDetailsColumnStyles>,
            stackStyles: {
                root: {
                    minWidth: 130,
                    padding: '4px 8px 4px 30px',
                    maxWidth: 130,
                },
            },
        },
        expiration: {
            width: 120,
            stackStyles: {
                root: {
                    minWidth: 100,
                    padding: '4px 8px 4px 40px',
                    maxWidth: 100,
                },
            },
        },
        requiredFor: {
            width: 200,
            stackStyles: {
                root: {
                    minWidth: 180,
                    maxWidth: 180,
                    padding: '4px 8px 4px 30px',
                },
            },
        },
        action: {
            width: 200,
            stackStyles: {
                root: {
                    minWidth: 200,
                    alignContent: 'center',
                    padding: '4px 8px 4px 30px',
                },
            },
        },
    };

    const entireOrgColumnStyles = {
        header: {
            width: 394, // ideally we have at least enough space for name being indented for 6 levels
            margin: '0 10px 0 10px',
            alignSelf: 'center',
        },
        footer: {
            width: 150,
            margin: '0 10px 0 10px',
            alignSelf: 'center',
        },
    };

    return (
        <>
            <Stack>
                <Stack.Item>
                    {!props.isRetrievingReports &&
                        !props.isRetrievingScopes &&
                        props.employees.length > 0 && (
                            <CloudScreeningPieChart
                                pieChartData={pieChartData}
                                pieChartSideInfo={pieChartSideInfo}
                            />
                        )}
                </Stack.Item>
                <Stack.Item styles={{ root: { marginLeft: 15 } }}>
                    {props.employees.length > 0 ||
                    props.isRetrievingReports ||
                    props.isRetrievingScopes ? (
                        displayTable()
                    ) : (
                        <p>No Reports to Show for Employee.</p>
                    )}
                </Stack.Item>
            </Stack>
        </>
    );

    function displayTable(): JSX.Element {
        switch (props.option) {
            case 'Entire Org':
                return (
                    <Table
                        key={`entireOrg_table`}
                        rows={items}
                        isFetchingData={props.isRetrievingReports || props.isRetrievingScopes}
                        tableColumns={getEntireOrgColumns()}
                        detailsHeaderStyles={columnHeaderStyles}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        tableName='Entire Organization'
                    />
                );
            case 'Direct Reports':
                return (
                    <Table
                        key={`directReport_table`}
                        collapseButton={collapseButton}
                        rows={[]}
                        isFetchingData={props.isRetrievingReports || props.isRetrievingScopes}
                        tableColumns={getDirectReportsColumns()}
                        groups={groups}
                        groupProps={groupProps}
                        groupNestingDepth={highestLevel}
                        indentWidth={tableIndentWidth}
                        detailsHeaderStyles={columnHeaderStyles}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        tableName='Direct Reports'
                        rootStyle={styles.scrollBarsFix}
                    />
                );
        }
    }

    function isManager(localProps: IGroupHeaderProps): boolean {
        return localProps.group !== undefined && graph[localProps.group.key] !== undefined;
    }

    function getOrgComplianceString(localProps: IGroupHeaderProps): string {
        if (!localProps || !localProps.group || !orgComplianceDict[localProps.group.key]) {
            return '';
        }

        const compliance = orgComplianceDict[localProps.group.key];
        const total = compliance.passed + compliance.failed;

        return total > 0
            ? `${compliance.passed}/${total} ${Math.round((compliance.passed / total) * 100)}%`
            : '';
    }

    function getOrgCompliancePercentage(compliance: IOrganizationCompliance): number {
        const total = compliance.passed + compliance.failed;

        return total > 0 ? Math.round((compliance.passed / total) * 100) : 0;
    }

    function getCloudScreenExpireDate(localProps: IGroupHeaderProps): Date | undefined {
        if (
            !localProps ||
            !localProps.group ||
            !aliasEmployeeDict[localProps.group.key] ||
            !aliasEmployeeDict[localProps.group.key].cloudScreenExpireDate
        ) {
            return undefined;
        }

        return new Date(aliasEmployeeDict[localProps.group.key].cloudScreenExpireDate as string);
    }

    function getDirectReportsColumns(): IColumn[] {
        return [
            {
                key: Column.Name,
                name: 'Name',
                ariaLabel: 'Name',
                minWidth: directReportColumnStyles.name.width,
                maxWidth: directReportColumnStyles.name.width,
                isRowHeader: true,
                isSorted: true,
                isMultiline: false,
                fieldName: 'Name',
                isSortedDescending: columnDescendingOrderDict[Column.Name],
                styles: directReportColumnStyles.name.styles,
                onColumnClick: onColumnClick,
            },
            {
                key: Column.OrganizationCompliance,
                name: 'Organization Compliance',
                ariaLabel: 'Organization Compliance',
                minWidth: directReportColumnStyles.organizationCompliance.width,
                maxWidth: directReportColumnStyles.organizationCompliance.width,
                isSorted: true,
                isMultiline: false,
                isRowHeader: false,
                isSortedDescending: columnDescendingOrderDict[Column.OrganizationCompliance],
                onColumnClick: onColumnClick,
            },
            {
                key: Column.IndividualCompliance,
                name: 'Individual Compliance',
                ariaLabel: 'Individual Compliance',
                minWidth: directReportColumnStyles.individualCompliance.width,
                maxWidth: directReportColumnStyles.individualCompliance.width,
                isSorted: true,
                isMultiline: false,
                isRowHeader: false,
                isSortedDescending: columnDescendingOrderDict[Column.IndividualCompliance],
                onColumnClick: onColumnClick,
            },
            {
                key: Column.Expiration,
                name: 'Expiration',
                ariaLabel: 'Expiration',
                minWidth: directReportColumnStyles.expiration.width,
                maxWidth: directReportColumnStyles.expiration.width,
                isSorted: true,
                isMultiline: false,
                isRowHeader: false,
                isSortedDescending: columnDescendingOrderDict[Column.Expiration],
                onColumnClick: onColumnClick,
            },
            {
                key: Column.RequiredFor,
                name: 'Required For',
                ariaLabel: 'Required For',
                isMultiline: false,
                isRowHeader: false,
                minWidth: directReportColumnStyles.requiredFor.width,
                maxWidth: directReportColumnStyles.requiredFor.width,
            },
            {
                key: Column.Action,
                name: 'Action',
                ariaLabel: 'Action',
                isMultiline: false,
                isRowHeader: false,
                minWidth: directReportColumnStyles.action.width,
            },
        ];
    }

    function getEntireOrgColumns(): IColumn[] {
        return [
            {
                key: Column.Name,
                name: 'Name',
                ariaLabel: 'Name',
                minWidth: entireOrgColumnStyles.header.width,
                maxWidth: entireOrgColumnStyles.header.width,
                isMultiline: true,
                isRowHeader: true,
                isSorted: true,
                isSortedDescending: columnDescendingOrderDict[Column.Name],
                onColumnClick: onColumnClick,
                onRender: (row: IEmployee): JSX.Element => {
                    return (
                        <TableCell key={row.id}>
                            <EmployeeBasicHoverCard personnelId={row.id} />
                        </TableCell>
                    );
                },
            },
            {
                key: Column.IndividualCompliance,
                name: 'Individual Compliance',
                ariaLabel: 'Individual Compliance',
                minWidth: entireOrgColumnStyles.footer.width + 25,
                maxWidth: entireOrgColumnStyles.footer.width + 25,
                isSorted: true,
                isSortedDescending: columnDescendingOrderDict[Column.IndividualCompliance],
                onColumnClick: onColumnClick,
                onRender: (row: IEmployee): JSX.Element => {
                    return (
                        <TableCell style={{ 'paddingLeft': '20px' }}>
                            {getCloudScreeningStatusLabelEmployee(row)}
                        </TableCell>
                    );
                },
            },
            {
                key: Column.Expiration,
                name: 'Expiration',
                ariaLabel: 'Expiration',
                minWidth: entireOrgColumnStyles.footer.width,
                maxWidth: entireOrgColumnStyles.footer.width,
                isSorted: true,
                isSortedDescending: columnDescendingOrderDict[Column.Expiration],
                onColumnClick: onColumnClick,
                onRender: (row: IEmployee): JSX.Element => {
                    return (
                        <TableCell>
                            {dateToDateStringFormat(
                                row.cloudScreenExpireDate
                                    ? new Date(row.cloudScreenExpireDate)
                                    : undefined,
                            )}
                        </TableCell>
                    );
                },
            },
            {
                key: Column.RequiredFor,
                name: 'Required For',
                ariaLabel: 'Required For',
                minWidth: entireOrgColumnStyles.footer.width + 50,
                maxWidth: entireOrgColumnStyles.footer.width + 50,
                isMultiline: true,
                onRender: (row: IEmployee): JSX.Element => {
                    return (
                        <TableCell>
                            {createScopesElementFromRecords(props.scopeRecordsDict[row.id])}
                        </TableCell>
                    );
                },
            },
            {
                key: Column.Action,
                name: 'Action',
                ariaLabel: 'Action',
                minWidth: entireOrgColumnStyles.footer.width,
                onRender: (row: IEmployee): JSX.Element => <TableCell>{getAction(row)}</TableCell>,
            },
        ];
    }

    function getAction(employee: IEmployee): JSX.Element {
        const cloudScreeningAction = getCloudScreeningAction(
            employee,
            props.reportScreeningsDict[employee.id],
        );

        switch (cloudScreeningAction) {
            case CloudScreeningAction.RequestScreening:
                return (
                    <ModalActionButton<void>
                        containerStyle={styles.actionButtonStyle}
                        text={'Request Screening'}
                        iconName={IconNames.PreviewLink}
                        errorMsg={errorMessage}
                        modalTitle={'Request Screening'}
                        enableSubmit={!isRequestDisabled}
                        submitButtonText={'Request'}
                        onButtonClick={() => setErrorMessage('')}
                        onSubmit={(): Promise<void> => {
                            setRequestDisabled(true);
                            return requestScreening(employee.id);
                        }}
                        onModalConcluded={() => {
                            setErrorMessage('');
                            setRequestDisabled(false);
                        }}>
                        <span>
                            Are you sure you want to request a screening for{' '}
                            {`${getDisplayNameOrDefault(employee)}`}?
                        </span>
                    </ModalActionButton>
                );
            case CloudScreeningAction.AlreadyRequested:
                const latestScreening = getLatestCloudScreening(
                    props.reportScreeningsDict[employee.id],
                );
                if (!latestScreening?.dateManagerReminderSent) {
                    return (
                        <ActionButton
                            className={styles.actionButtonStyle}
                            disabled={isRemindDisabled}
                            iconProps={{ iconName: 'PreviewLink' }}
                            text='Remind'
                            onClick={(): Promise<void> => sendManagerReminder(employee)}
                            key={`action-btn-${employee.id}`}
                        />
                    );
                }
            default:
                return <span>{cloudScreeningAction}</span>;
        }
    }

    async function requestScreening(personnelId: string): Promise<void> {
        setRequestDisabled(true);

        try {
            await CloudScreeningClient.createBackgroundCheckRequest(
                authContext,
                personnelId,
                isBackgroundCheckEnabled,
            );
        } catch (err) {
            console.error('Error creating background check request', err);
            // Prevent screening record from being created if a background check request
            // couldn't be created because submitting a background check request dependends on
            // visibility of the button. If background check request is not created and screening
            // request is, the UI will no longer show the button, making it difficult to realize
            // that one needs to be created, and also making it difficult to create one.
            setErrorMessage('Error creating background check request');
            setRequestDisabled(false);
            return;
        }

        try {
            const scopes = props.scopeRecordsDict[personnelId]
                ? props.scopeRecordsDict[personnelId].map((x) => x.scope)
                : [];
            const createdScreening = await CloudScreeningClient.createScreening(
                authContext,
                personnelId,
                scopes,
            );
            upsertScreening(createdScreening);
        } catch (error) {
            console.error('Error creating screening', error);
            setErrorMessage('Error creating screening');
        } finally {
            setRequestDisabled(false);
        }
    }

    function onColumnClick(ev: React.MouseEvent<HTMLElement>, column: IColumn): void {
        if (props.option === 'Direct Reports') {
            sortDirectReports(column);
        } else {
            sortEntireOrg(column);
        }
    }

    function sortDirectReports(column: IColumn): void {
        const sortComparator = getSortComparator(column);
        const orderValue = getOrderValue(column);

        const newGroups: IGroup[] = [];

        groups.forEach((x) => {
            newGroups.push(copyGroupAndSortChildren(sortComparator, orderValue, x));
        });

        sortGroups(sortComparator, orderValue, newGroups);
        setGroups(newGroups);

        updateColumnDescendingOrderDict(column);
    }

    function copyGroupAndSortChildren(
        sortComparator: (orderValue: number, employee1: IEmployee, employee2: IEmployee) => number,
        orderValue: number,
        originalGroup: IGroup,
    ): IGroup {
        const copiedGroup: IGroup = {
            ...originalGroup,
            children: originalGroup.children ? [] : undefined,
        };

        originalGroup.children?.forEach((x) => {
            copiedGroup.children?.push(copyGroupAndSortChildren(sortComparator, orderValue, x));
        });

        sortGroups(sortComparator, orderValue, copiedGroup.children);

        return copiedGroup;
    }

    function sortGroups(
        sortComparator: (orderValue: number, employee1: IEmployee, employee2: IEmployee) => number,
        orderValue: number,
        varGroups?: IGroup[],
    ): void {
        if (!varGroups) {
            return;
        }

        varGroups.sort((a, b) =>
            sortComparator(orderValue, aliasEmployeeDict[a.key], aliasEmployeeDict[b.key]),
        );
    }

    function sortEntireOrg(column: IColumn): void {
        const sortComparator = getSortComparator(column);
        const orderValue = getOrderValue(column);

        const newItems = items.slice(0);
        newItems.sort((a, b) => sortComparator(orderValue, a, b));
        setItems(newItems);

        updateColumnDescendingOrderDict(column);
    }

    function updateColumnDescendingOrderDict(column: IColumn): void {
        const newColumnDescendingOrderDict = { ...columnDescendingOrderDict };
        newColumnDescendingOrderDict[column.key] = !newColumnDescendingOrderDict[column.key];
        setColumnDescendingOrderDict(newColumnDescendingOrderDict);
    }

    function getSortComparator(
        column: IColumn,
    ): (orderValue: number, employee1: IEmployee, employee2: IEmployee) => number {
        switch (column.key) {
            case Column.OrganizationCompliance:
                return (orderValue: number, employee1: IEmployee, employee2: IEmployee): number => {
                    return (
                        orderValue *
                        (getOrgCompliancePercentage(
                            orgComplianceDict[employee1.alias.toLowerCase()],
                        ) -
                            getOrgCompliancePercentage(
                                orgComplianceDict[employee2.alias.toLowerCase()],
                            ))
                    );
                };
            case Column.IndividualCompliance:
                return (orderValue: number, employee1: IEmployee, employee2: IEmployee): number => {
                    return (
                        orderValue *
                        (getCloudScreeningStatus(employee1).order -
                            getCloudScreeningStatus(employee2).order)
                    );
                };
            case Column.Expiration:
                return (orderValue: number, employee1: IEmployee, employee2: IEmployee): number => {
                    const date1 = employee1.cloudScreenExpireDate ?? '';
                    const date2 = employee2.cloudScreenExpireDate ?? '';
                    return orderValue * date1.localeCompare(date2);
                };
            case Column.Name:
            default:
                return (orderValue: number, employee1: IEmployee, employee2: IEmployee): number => {
                    return (
                        orderValue *
                        getDisplayNameOrDefault(employee1).localeCompare(
                            getDisplayNameOrDefault(employee2),
                        )
                    );
                };
        }
    }

    function getOrderValue(column: IColumn): number {
        return columnDescendingOrderDict[column.key] ? 1 : -1;
    }
}

const styles = mergeStyleSets({
    actionButtonStyle: {
        marginTop: '-8px',
        marginBottom: '-10px',
    },
    primaryButtonStyle: {
        marginTop: '-8px',
        marginBottom: '-10px',
        padding: '0px',
    },
    scrollBarsFix: {
        selectors: {
            '& [role=gridcell]': {
                padding: '0',
            },
        },
    },
});
