import {
    ActionButton,
    Dropdown,
    mergeStyles,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Separator,
} from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import React, { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import ElementToolbar from 'components/forms/element-toolbar';
import { DragDropContext, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import ElementList from 'components/forms/element-list';
import 'draft-js/dist/Draft.css';
import SectionElement from 'components/forms/element-types/section-element';
import FormsClient from 'clients/forms-client';
import { AuthContext } from 'contexts/auth-context';
import usePrevious, { useDebounce } from 'utils/misc-hooks';
import { GetOptionalKeys } from 'utils/object-utils';
import {
    ElementType,
    endOfForm,
    Form,
    FormElement,
    FormElementValues,
    formWrapperStyle,
    next,
    Section,
    UpdatableProperty,
} from 'components/forms/forms-common';
import HeaderElement from 'components/forms/element-types/header-element';
import { separatorStyles } from 'components/screening/common/filters/common-filter-styling';

const pageStyles = mergeStyles({
    background: '#FAFAFA',
});
const disabledPageStyles = mergeStyles(pageStyles, {
    pointerEvents: 'none',
    opacity: 0.6,
});

const topBarStyle = mergeStyles({
    position: 'fixed',
    zIndex: '2',
    left: '85%',
    top: '9rem',
});

const sectionBannerStyles = mergeStyles({
    margin: '0',
    fontStyle: 'italic',
    background: 'rgb(0, 120, 212)',
    color: 'white',
    padding: '8px 12px',
    width: 'fit-content',
    borderRadius: '10px 10px 0 0',
});

const customSeparatorStyles = mergeStyles(separatorStyles, {
    background: 'white',
});

export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const OptionalFormElementProps: Record<keyof GetOptionalKeys<FormElement>, unknown> = {
    options: [],
    validatorOptions: {},
    branches: false,
    value: '',
    hasTime: false,
    isFutureDatesOnly: false,
    rangeLabels: {},
    visible: true,
} as const;

type FormsEditProps = {
    form: Form;
    setForm: React.Dispatch<React.SetStateAction<Form>>;
};

export function FormsEdit(props: FormsEditProps): JSX.Element {
    const { form, setForm } = props;
    const previousForm = usePrevious(form);
    const authContext = useContext(AuthContext);
    const debounceTimeInMS = 1200;

    const getSectionBranchOptions = useCallback(
        (currIndex: number) => {
            const futureSections = form.sections
                .filter((section) => section.id > currIndex)
                .map((section) => ({
                    key: section.id.toString(),
                    text: 'Section ' + (section.id + 1) + '. ' + section.label,
                }));
            return [
                { key: next, text: 'Next Section' },
                { key: endOfForm, text: 'End of form' },
                ...futureSections,
            ];
        },
        [form.sections],
    );

    const autosave = async (): Promise<void> => {
        if (form !== previousForm && !form.isSoftDeleted) {
            await FormsClient.UpdateGenericForm(authContext, form);
        }
    };

    useDebounce(autosave, debounceTimeInMS, [form]);

    const reorder = (list: FormElement[], startIndex: number, endIndex: number): FormElement[] => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const addSection = (index: number): void => {
        setForm((prev) => {
            const newForm = { ...prev };
            // Increment section ID of sections being pushed back
            const newSections = newForm.sections.map((section) => {
                if (section.id < index) {
                    return section;
                }
                section.id += 1;
                return section;
            });
            const sectionToAdd = {
                id: index,
                label: 'Untitled Section',
                description: '',
                elements: [],
            };
            newSections.splice(index, 0, sectionToAdd);
            newForm.sections = newSections;
            return newForm;
        });
    };

    const deleteSection = (index: number): void => {
        setForm((prev) => {
            const newForm = { ...prev };
            // Decrement section ID of sections being pushed up
            const newSections = newForm.sections
                .filter((section) => section.id !== index)
                .map((section) => {
                    if (section.id < index) {
                        return section;
                    }
                    section.id -= 1;
                    return section;
                });
            newForm.sections = newSections;
            return newForm;
        });
    };

    const addElement = (sectionIndex: number, type: keyof typeof ElementType): void => {
        setForm((prev) => {
            const newForm = { ...prev };
            const currItems = [...prev.sections[sectionIndex].elements] ?? [];
            const key = crypto.randomUUID();
            const newItem: FormElement = {
                id: key,
                type: type,
                label: '',
                description: '',
                required: type === 'signature',
            };
            currItems.push(newItem);
            newForm.sections[sectionIndex].elements = currItems;
            return newForm;
        });
    };

    const deleteElement = (sectionIndex: number, id: string): void => {
        setForm((prev) => {
            const newForm = { ...prev };
            const newElements = newForm.sections[sectionIndex].elements.filter(
                (el) => el.id !== id,
            );
            newForm.sections[sectionIndex].elements = newElements;
            return newForm;
        });
    };

    const shiftElement = (sectionIndex: number, id: string, direction: 'up' | 'down'): void => {
        const currIndex = form.sections[sectionIndex].elements.findIndex((el) => el.id === id);
        const swapIndex = direction === 'up' ? currIndex - 1 : currIndex + 1;
        if (
            currIndex < 0 ||
            swapIndex < 0 ||
            swapIndex === form.sections[sectionIndex].elements.length
        ) {
            return;
        }
        const swapElement = form.sections[sectionIndex].elements[swapIndex];
        setForm((prev) => {
            const newForm = { ...prev };
            newForm.sections[sectionIndex].elements[swapIndex] =
                newForm.sections[sectionIndex].elements[currIndex];
            newForm.sections[sectionIndex].elements[currIndex] = swapElement;
            return newForm;
        });
    };

    const updateForm = useCallback(
        (
            sectionIndex: number,
            id: string,
            newValue: FormElementValues,
            operation: UpdatableProperty,
        ): void => {
            const section = form.sections[sectionIndex];
            const index = section.elements.findIndex((el) => el.id === id);
            if (newValue === undefined) {
                if (OptionalFormElementProps.hasOwnProperty(operation)) {
                    setForm((prev) => {
                        const newForm = { ...prev };
                        const newElement = { ...section.elements[index] };
                        delete newElement[operation];
                        newForm.sections[sectionIndex].elements[index] = newElement;
                        return newForm;
                    });
                }
                return;
            }
            setForm((prev) => {
                const newForm = { ...prev };
                const newElement = { ...section.elements[index], [operation]: newValue };
                newForm.sections[sectionIndex].elements[index] = newElement;
                return newForm;
            });
        },
        [form.sections],
    );

    const updateSection = useCallback(
        (
            id: number,
            newValue: string | undefined,
            operation: keyof Pick<Section, 'label' | 'description' | 'branchTarget'>,
        ): void => {
            if (newValue === undefined) {
                return;
            }
            setForm((prev) => {
                const newForm = { ...prev };
                newForm.sections[id][operation] = newValue;
                return newForm;
            });
        },
        [],
    );

    const onDragEnd = (sectionIndex: number, result: DropResult): void => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            form.sections[sectionIndex].elements,
            result.source.index,
            result.destination.index,
        );

        setForm((prev) => {
            const newForm = { ...prev };
            newForm.sections[sectionIndex].elements = items;
            return newForm;
        });
    };

    return (
        <div className={form.isSoftDeleted || form.isLocked ? disabledPageStyles : pageStyles}>
            {form.isSoftDeleted && (
                <MessageBar messageBarType={MessageBarType.warning}>
                    To edit this form, you must restore it in the settings tab.
                </MessageBar>
            )}
            <div className={topBarStyle}>
                <Link
                    to={(location: { pathname: string }): string => `${location.pathname}/preview`}>
                    <ActionButton
                        title='View preview'
                        aria-label='View preview'
                        iconProps={{ iconName: IconNames.View }}
                        onClick={autosave}>
                        Preview
                    </ActionButton>
                </Link>
            </div>
            <Separator className={customSeparatorStyles} />
            <HeaderElement form={form} setForm={setForm} isLocked={form.isLocked} />
            <div className={formWrapperStyle}>
                {form.sections.map((section, sectionIndex) => (
                    <div key={section.id}>
                        {section.id !== 0 && (
                            <div className={sectionBannerStyles}>
                                {`Section ${section.id + 1} of ${form.sections.length}`}
                            </div>
                        )}
                        <SectionElement
                            section={section}
                            updateSection={updateSection}
                            deleteSection={deleteSection}
                        />
                        <DragDropContext
                            onDragEnd={(result: DropResult) => onDragEnd(section.id, result)}>
                            <Droppable droppableId='list'>
                                {(provided: DroppableProvided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        <ElementList
                                            sectionId={section.id}
                                            sections={form.sections}
                                            shiftElement={shiftElement}
                                            deleteElement={deleteElement}
                                            updateForm={updateForm}
                                        />
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <ElementToolbar addNewElement={addElement} currentSection={section.id} />
                        <div
                            style={{
                                padding: '1rem',
                                marginTop: '1.5rem',
                                background: 'white',
                            }}>
                            <span>After this section, go to</span>
                            <Dropdown
                                selectedKey={section.branchTarget ?? next}
                                options={getSectionBranchOptions(sectionIndex)}
                                onChange={(ev, option) =>
                                    updateSection(
                                        sectionIndex,
                                        option?.key as string,
                                        'branchTarget',
                                    )
                                }
                            />
                        </div>
                        <PrimaryButton
                            style={{ width: 'fit-content', margin: '1.5rem 0 5rem 40%' }}
                            iconProps={{ iconName: 'Tab' }}
                            onClick={() => addSection(sectionIndex + 1)}>
                            Add new section
                        </PrimaryButton>
                    </div>
                ))}
            </div>
        </div>
    );
}
