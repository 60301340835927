import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getLocalTimeZone, timeZoneAbbr } from 'utils/time-utils';

function Clock() {
    const [time, setTime] = useState<string>();

    function refreshClock() {
        const displaytime = moment().format('hh:mm a');
        const timeZoneAbbreviation = timeZoneAbbr(getLocalTimeZone());
        setTime(`${displaytime} ${timeZoneAbbreviation}`);
    }

    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    return <>{time}</>;
}

export default Clock;
