import React, { useState, useContext, useEffect, FunctionComponent } from 'react';
import { AuthContext, IAuthContext } from 'contexts/auth-context';
import EmployeeClient from 'clients/employee-client';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';
import { generalIsMountedCode } from 'utils/misc-utils';

export interface EmployeeNameResolverIdProps {
    personnelId: string | number | undefined;
}
export const EmployeeNameResolverId: FunctionComponent<EmployeeNameResolverIdProps> = (props) => {
    const authContext = useContext(AuthContext);
    const [displayName, setDisplayName] = useState<string>('');

    const getName = async (isMountedFunc: () => boolean): Promise<void> => {
        if (props.personnelId) {
            const name = await getNameFromPersonnelId(authContext, props.personnelId);
            if (isMountedFunc()) {
                setDisplayName(name);
            }
        }
    };

    useEffect(() => {
        return generalIsMountedCode(getName);
    }, [props.personnelId]);

    return <> {displayName} </>;
};

export interface EmployeeNameResolverAliasProps {
    alias: string | undefined;
}
export const EmployeeNameResolverAlias: FunctionComponent<EmployeeNameResolverAliasProps> = (
    props,
) => {
    const authContext = useContext(AuthContext);
    const [displayName, setDisplayName] = useState<string>('');

    const getName = async (isMountedFunc: () => boolean): Promise<void> => {
        if (props.alias) {
            const name = await getNameFromAlias(authContext, props.alias);
            if (isMountedFunc()) {
                setDisplayName(name);
            }
        }
    };

    useEffect(() => {
        return generalIsMountedCode(getName);
    }, [props.alias]);

    return <> {displayName} </>;
};

export async function getNameFromPersonnelId(
    authContext: IAuthContext,
    personnelId: string | number,
): Promise<string> {
    const personnelIdStr = personnelId.toString();
    const basicResult = await EmployeeClient.getBasicEmployeesById(authContext, [personnelIdStr]);
    const employee = basicResult[0];
    if (employee) {
        return getDisplayNameOrDefault(employee, employee.onPremisesSamAccountName);
    }
    return '';
}

async function getNameFromAlias(authContext: IAuthContext, alias: string): Promise<string> {
    try {
        const basicResult = await EmployeeClient.getBasicEmployeesByAlias(authContext, [alias]);
        const employee = basicResult[0];
        if (employee) {
            return getDisplayNameOrDefault(employee, employee.onPremisesSamAccountName);
        }
    } catch {
        console.error('Error obtaining employee name');
    }
    return '';
}
