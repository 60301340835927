import { CloudStatus } from 'components/staffing/staffing-constants';
import { FilterFactory } from 'components/staffing/status-filters/filter-factory';
import { FilterSettings } from 'components/staffing/contexts/staffing-status-filter-context';
import { OrgTeamsObjectType } from 'components/staffing/staffing-page-types';
import { StatusFiltersContextType } from 'components/staffing/contexts/staffing-status-filter-context';
import { IStaffingClearanceRecordResponse } from 'clients/staffing-client';

export function getAllocationKey(allocation: IStaffingClearanceRecordResponse): string {
    return allocation.organization + allocation.team;
}

type FilterAllocationsParamsType = {
    filters: StatusFiltersContextType;
    orgNames: string[];
    orgTeams: OrgTeamsObjectType;
    allocations: IStaffingClearanceRecordResponse[];
};

export type FilterAllocationsReturnType = {
    orgNames: string[];
    orgTeams: OrgTeamsObjectType;
    allocations: IStaffingClearanceRecordResponse[];
};

export const emptyFilteredAllocations = {
    orgNames: [],
    orgTeams: {},
    allocations: [],
} as FilterAllocationsReturnType;

export const filterAllocations = (
    params: FilterAllocationsParamsType,
): FilterAllocationsReturnType => {
    const filtered: FilterAllocationsReturnType = {
        orgNames: [],
        orgTeams: {},
        allocations: [],
    };

    const orgNames = (allocations: IStaffingClearanceRecordResponse[]) =>
        Object.keys(
            allocations.reduce((acc, cur) => {
                acc[cur.organization] = true;
                return acc;
            }, {} as { [orgName: string]: true }),
        );

    const orgTeams = (orgNames: string[]) =>
        orgNames.reduce((acc, cur) => {
            acc[cur] = params.orgTeams[cur];
            return acc;
        }, {} as OrgTeamsObjectType);

    const filter = FilterFactory(
        params.filters.cloud.key as CloudStatus,
        params.filters?.filter?.key as FilterSettings,
    );
    filtered.allocations = filter.filter(params.allocations, params.orgTeams);
    filtered.orgNames = orgNames(filtered.allocations);
    filtered.orgTeams = orgTeams(filtered.orgNames);
    return filtered;
};
