import React, { useState, useEffect } from 'react';
import BarChart, { ChartItem } from 'components/common/charts/bar-chart';
import { Stack } from '@fluentui/react';
import {
    getParentState,
    IMetaData,
    initialBarChart,
    initialMetaData,
    ScreeningParentStateType,
    stateLabels,
    StateName,
} from 'components/screening/common/common-constants';
import {
    barChartStack,
    overallGraphStack,
    textBoxInfoStack,
} from 'components/screening/common/common-manage-profile-page-styling';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';

interface PublicTrustScreeningChartProps {
    screenings: ICommonScreening[];
    totalRecords: number;
}

export default function PublicTrustScreeningChart(
    props: PublicTrustScreeningChartProps,
): JSX.Element {
    const [barChartData, setBarChartData] = useState<ChartItem[]>(initialBarChart);
    const [metaData, setMetaData] = useState<IMetaData>(initialMetaData);

    useEffect(() => {
        if (props.totalRecords > 0) {
            const stateCount = new Map<string, number>();
            props.screenings
                .filter(
                    (x) =>
                        // Nomination counts should not inlucde nominee declined or contract owner denied status
                        x.stateName !== StateName.NomineeDeclined &&
                        x.stateName !== StateName.ContractOwnerDenied,
                )
                .forEach((screening) => {
                    const state = getParentState(screening.stateName);
                    const count = stateCount.get(state);
                    if (count) {
                        stateCount.set(state, count + 1);
                    } else {
                        stateCount.set(state, 1);
                    }
                });

            const updatedChartData = [...initialBarChart];

            stateLabels.forEach((label, index) => {
                updatedChartData[index].yAxis = stateCount.get(label) || 0;
            });

            setBarChartData(updatedChartData);

            const updatedMetaData = { ...initialMetaData };
            updatedMetaData.filteredRecords = props.screenings.length;
            updatedMetaData.completedRecords =
                stateCount.get(ScreeningParentStateType.Completed) || 0;
            updatedMetaData.withdrawnRecords =
                stateCount.get(ScreeningParentStateType.Withdrawn) || 0;

            if (updatedMetaData.completedRecords === 0) {
                updatedMetaData.percentCompletedRecords = '0%';
            } else {
                updatedMetaData.percentCompletedRecords =
                    `${
                        (updatedMetaData.completedRecords / updatedMetaData.filteredRecords) * 100
                    }`.substring(0, 4) + '%';
            }

            setMetaData(updatedMetaData);
        }
    }, [props.screenings]);
    return (
        <Stack styles={overallGraphStack}>
            <Stack styles={barChartStack}>
                <BarChart barChartData={barChartData} displayDataLabels={true} hideLegend={true} />
            </Stack>
            <Stack styles={textBoxInfoStack}>
                <ul>
                    <li>{`${metaData?.percentCompletedRecords} Completed`}</li>
                    <li>{`${metaData?.completedRecords} Screenings Completed`}</li>
                    <li>{`${metaData?.withdrawnRecords} Screenings Withdrawn`}</li>
                </ul>
            </Stack>
        </Stack>
    );
}
