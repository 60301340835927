import React, { ReactNode } from 'react';
import { Stack } from '@fluentui/react';

interface ButtonBarProps {
    children: ReactNode | ReactNode[];
}

export default function ButtonBar(props: ButtonBarProps): JSX.Element {
    return <Stack horizontal>{props.children}</Stack>;
}
