import { ChoiceGroup, Dropdown, IDropdownOption, Spinner } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import EligibilityClient from 'clients/eligibility-client';
import LabelInfoIcon from 'components/common/use-input/info-icon-label';
import { AuthContext } from 'contexts/auth-context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { SetStateFunc } from 'types/global-types';
import { Form } from 'components/forms/forms-common';
import { UpdatableSettings } from 'components/forms/settings/forms-edit-settings';

const whoCanFillOut = {
    anyone: 'anyone',
    restricted: 'restricted',
} as const;

const accessControlDropdownOptions = [
    { key: 'FTE', text: 'Full time employee' },
    { key: 'CITIZEN', text: 'US citizen' },
    { key: 'CLOUD_SCREEN', text: 'Cloud screen' },
];
const whoCanFillOutOptions = [
    { key: whoCanFillOut.anyone, text: 'Anyone' },
    { key: whoCanFillOut.restricted, text: 'Only people with the following criteria' },
];

type AccessControlSettingsProps = {
    form: Form;
    updateAndSaveForm: (
        property: keyof UpdatableSettings,
        value: string[] | boolean | string[][],
    ) => void;
    clearAccessControl: () => void;
    hasAccessControl: boolean;
    setHasAccessControl: React.Dispatch<React.SetStateAction<boolean>>;
    setErrorMessage: SetStateFunc<string | undefined>;
};

export function AccessControlSettings(props: AccessControlSettingsProps): JSX.Element {
    const {
        form,
        updateAndSaveForm,
        clearAccessControl,
        hasAccessControl,
        setHasAccessControl,
        setErrorMessage,
    } = props;

    const [attributeOptions, setAttributeOptions] = useState<IDropdownOption<unknown>[]>([]);
    const [eligibilityOptions, setEligibilityOptions] = useState<IDropdownOption<unknown>[]>([]);

    const authContext = useContext(AuthContext);

    const sortOptions = useCallback((options: IDropdownOption<unknown>[]) => {
        return [...options].sort((a, b) =>
            a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }),
        );
    }, []);

    const fetchEligibilities = async (): Promise<void> => {
        try {
            const eligibilitiesVar = await EligibilityClient.getEligibilities(authContext);
            const propsOptions = eligibilitiesVar.map((option) => {
                return { key: option.eligibilityCode, text: option.eligibilityName };
            });
            setEligibilityOptions(sortOptions(propsOptions));
        } catch {
            setErrorMessage('Error loading eligibilities. Please refresh to try again.');
        }
    };
    const fetchAttributes = async (): Promise<void> => {
        try {
            const attributes = await EligibilityClient.getAttributes(authContext);
            const propsOptions = attributes.map((option) => {
                return { key: option.attributeCode, text: option.attributeDesc };
            });
            setAttributeOptions(sortOptions(propsOptions));
        } catch {
            setErrorMessage('Error loading attributes. Please refresh to try again.');
        }
    };
    useEffect(() => {
        fetchEligibilities();
        fetchAttributes();
    }, []);

    const updateEmpPropsAccessControl = (item: IDropdownOption<unknown> | undefined): void => {
        if (!item) {
            return;
        }
        const currAttrs = [...form.accessControlEmployeeProperties];
        const newAttrs = item.selected
            ? [...currAttrs, item.key as string]
            : currAttrs.filter((key) => key !== item.key);
        updateAndSaveForm('accessControlEmployeeProperties', newAttrs);
    };

    const updateEligAttrAccessControl = (
        item: IDropdownOption<unknown> | undefined,
        property: keyof Pick<
            UpdatableSettings,
            'accessControlAttributes' | 'accessControlEligibilities'
        >,
    ): void => {
        if (!item) {
            return;
        }
        const currList = form[property].flat();
        const newList = item.selected
            ? [...currList, item.key as string]
            : currList.filter((key) => key !== item.key);
        const grouped = newList.map((el) => [el]);

        updateAndSaveForm(property, grouped);
    };
    if (attributeOptions.length === 0 || eligibilityOptions.length === 0) {
        return <Spinner />;
    }
    return (
        <>
            <ChoiceGroup
                selectedKey={hasAccessControl ? whoCanFillOut.restricted : whoCanFillOut.anyone}
                options={whoCanFillOutOptions}
                ariaLabelledBy={'label2'}
                onChange={(ev, selectedOption): void => {
                    if (!selectedOption) {
                        return;
                    }
                    if (selectedOption.key === whoCanFillOut.anyone) {
                        setHasAccessControl(false);
                        clearAccessControl();
                        return;
                    }
                    setHasAccessControl(true);
                }}
            />
            {hasAccessControl && (
                <>
                    <div style={{ marginTop: '1rem' }}>
                        <LabelInfoIcon
                            iconHoverContent={
                                <span>
                                    User must have <strong>all</strong> of the following employee
                                    properties to fill out this form.
                                </span>
                            }
                            iconName={IconNames.Info}>
                            Employee properties
                        </LabelInfoIcon>
                        <Dropdown
                            selectedKeys={form.accessControlEmployeeProperties ?? []}
                            multiSelect
                            placeholder='Select one or more'
                            options={accessControlDropdownOptions}
                            onChange={(ev, item) => updateEmpPropsAccessControl(item)}
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <LabelInfoIcon
                            iconHoverContent={
                                <span>
                                    User must have <strong>any</strong> of the following attributes
                                    to fill out this form.
                                </span>
                            }
                            iconName={IconNames.Info}>
                            Attributes
                        </LabelInfoIcon>
                        <Dropdown
                            selectedKeys={form.accessControlAttributes.flat() ?? []}
                            multiSelect
                            placeholder='Select one or more'
                            options={attributeOptions}
                            onChange={(ev, item) =>
                                updateEligAttrAccessControl(item, 'accessControlAttributes')
                            }
                        />
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <LabelInfoIcon
                            iconHoverContent={
                                <span>
                                    User must have <strong>any</strong> of the following
                                    eligibilities to fill out this form.
                                </span>
                            }
                            iconName={IconNames.Info}>
                            Eligibilities
                        </LabelInfoIcon>
                        <Dropdown
                            selectedKeys={form.accessControlEligibilities.flat() ?? []}
                            multiSelect
                            placeholder='Select one or more'
                            options={eligibilityOptions}
                            onChange={(ev, item) =>
                                updateEligAttrAccessControl(item, 'accessControlEligibilities')
                            }
                        />
                    </div>
                </>
            )}
        </>
    );
}
