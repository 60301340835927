import { mergeStyles, mergeStyleSets, SearchBox, Spinner } from '@fluentui/react';
import { CustomBreadcrumb, ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import Tabs, { TabbedContent } from 'components/common/tabs';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import FormsClient, { FormPermissions, GenericForm, GenericFormRecord } from 'clients/forms-client';
import { AuthContext } from 'contexts/auth-context';
import { makeBreadcrumb } from 'utils/string-utils';
import { FormsMySubmissions } from 'components/forms/forms-my-submissions';

import { FormsManageSearch } from 'components/forms/manage/forms-manage-search';
import { FormsTemplates } from 'components/forms/manage/forms-templates';
import { FormTile } from 'components/forms/forms-tile';
import { FormsUnavailableModal } from 'components/forms/forms-unavailable-modal';
import { FormsDecisionModal } from 'components/forms/forms-decision-modal';
import { FormsSelectDuplicateModal } from 'components/forms/forms-select-duplicate-modal';
import { genericFormRecordFormStates } from 'components/forms/forms-common';
import { FormsTeachingBubble } from 'components/forms/forms-teaching-bubble';
import { BrowserCacheKeys } from 'utils/browser-cache-utils';

const tabKeys = {
    available: 'available-forms',
    submissions: 'my-forms',
    manage: 'manage',
    templates: 'templates',
} as const;

export function Forms(): JSX.Element {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const breadCrumbContext = useContext(BreadCrumbContext);
    const { tab } = useParams<{ tab: string }>();
    const [availableForms, setAvailableForms] = useState<GenericForm[]>();
    const [filteredForms, setFilteredForms] = useState<GenericForm[]>();
    const [formRecords, setFormRecords] = useState<GenericFormRecord[]>();
    const [isFormRecordsLoading, setFormRecordsLoading] = useState(true);
    const [formTemplates, setFormTemplates] = useState<GenericForm[]>([]);
    const [isFormOwner, setIsFormOwner] = useState<boolean>();
    const [hasTemplateAccess, setTemplateAccess] = useState<boolean>();
    const [showDuplicateModal, setShowDuplicateModal] = useState<boolean>(false);
    const [showDecisionModal, setShowDecisionModal] = useState<boolean>(false);
    const [showUnavailableFormError, setShowUnavailableFormError] = useState<boolean>(false);
    const [showBubble, setShowBubble] = useState<boolean>(true);
    const [selectedForm, setSelectedForm] = useState<GenericForm>();

    // Only intended to be used once for teaching bubble callout
    const tileTeachingBubbleId = 'tileTeachingBubbleId';

    const onTabChange = useCallback(
        (itemKey?: string): void => {
            if (itemKey) {
                history.push(itemKey);
            }
        },
        [history],
    );

    const firstDuplicableFormRecord = useMemo(
        (): GenericFormRecord | undefined =>
            formRecords?.find(
                (record) =>
                    record.formState.toLowerCase() === genericFormRecordFormStates.completed ||
                    record.formState.toLowerCase() === genericFormRecordFormStates.accepted,
            ),
        [formRecords],
    );

    useEffect(() => {
        const tabCrumb = tab ? { title: makeBreadcrumb(tab), link: `/forms/${tab}` } : {};
        const crumbs = [{ title: 'Forms', link: '' }, tabCrumb].filter(
            (value) => Object.keys(value).length !== 0,
        ) as ICustomBreadcrumbItem[];
        breadCrumbContext.setBreadCrumbs(crumbs);
        // If the user switches tabs and they have not dismissed it five times already, show
        const shouldResetBubble = parseInt(
            localStorage.getItem(BrowserCacheKeys.formsTeachingBubble) ?? '0',
        );
        const hideLimitCount = 6;
        if (shouldResetBubble < hideLimitCount) {
            setShowBubble(true);
        }
    }, [tab]);

    const getAllForms = async (): Promise<void> => {
        const response = await FormsClient.GetAvailableGenericForms(authContext);
        setAvailableForms(response);
        setFilteredForms(response);
    };

    const getFormRecords = async (): Promise<void> => {
        try {
            const records = await FormsClient.GetMyGenericFormRecords(authContext);
            setFormRecords(records);
        } catch (error) {
            console.error('error getting form records');
            console.error(error);
        } finally {
            setFormRecordsLoading(false);
        }
    };

    const getFormPermissions = async (): Promise<void> => {
        let perms: FormPermissions = { isFormOwner: false, hasTemplateAccess: false };
        try {
            perms = await FormsClient.GetGenericFormPermissions(authContext);
            setIsFormOwner(perms.isFormOwner);
            setTemplateAccess(perms.hasTemplateAccess);
        } catch (error) {
            console.error('error getting form records');
            console.error(error);
            setIsFormOwner(false);
            setTemplateAccess(false);
        } finally {
            if (!perms.isFormOwner && tab === tabKeys.manage) {
                history.push(tabKeys.available);
            }
            if (!perms.hasTemplateAccess && tab === tabKeys.templates) {
                history.push(tabKeys.available);
            }
        }
    };

    useEffect(() => {
        getAllForms();
        getFormRecords();
        getFormPermissions();
    }, []);

    const searchForms = (value?: string) => {
        if (!value) {
            setFilteredForms(availableForms);
            return;
        }
        const matchingForms = availableForms?.filter(
            (form) =>
                form.title.toLowerCase().includes(value.toLowerCase()) ||
                form.description.toLowerCase().includes(value.toLowerCase()),
        );
        setFilteredForms(matchingForms ?? []);
    };
    if (isFormOwner === undefined || hasTemplateAccess === undefined) {
        return (
            <>
                <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                <Spinner style={{ marginTop: '10%' }} />
            </>
        );
    }

    return (
        <>
            <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
            <Tabs key={tab} defaultSelectedKey={tab} onChange={onTabChange}>
                <TabbedContent tabHeader='Available forms' itemKey={tabKeys.available}>
                    {isFormRecordsLoading || availableForms === undefined ? (
                        <Spinner style={{ margin: '10% 0 0 50%' }} />
                    ) : (
                        <>
                            <div>
                                <div className={searchContainer}>
                                    <div className={styles.searchBox}>
                                        <SearchBox
                                            placeholder='Find form by name or description...'
                                            onChange={(ev, newValue): void => searchForms(newValue)}
                                            onClear={(): void => searchForms(undefined)}
                                        />
                                    </div>
                                </div>
                                <div className={tileContainer}>
                                    {filteredForms?.map((form, index) => (
                                        <FormTile
                                            key={form.id}
                                            form={form}
                                            formRecords={formRecords}
                                            setSelectedForm={setSelectedForm}
                                            setShowDuplicateModal={setShowDuplicateModal}
                                            setShowDecisionModal={setShowDecisionModal}
                                            setShowUnavailableFormError={
                                                setShowUnavailableFormError
                                            }
                                            teachingBubbleId={
                                                form.name === firstDuplicableFormRecord?.name
                                                    ? tileTeachingBubbleId
                                                    : `tile-id-${index}`
                                            }
                                            showBubble={showBubble}
                                            setShowBubble={setShowBubble}
                                        />
                                    ))}
                                </div>
                                {firstDuplicableFormRecord && (
                                    <FormsTeachingBubble
                                        idTarget={`#${tileTeachingBubbleId}`}
                                        headline='Duplicate a form'
                                        bodyText='Select the ellipsis for more actions, including form duplication if you have a previously completed form.'
                                        showBubble={showBubble}
                                        setShowBubble={setShowBubble}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </TabbedContent>
                <TabbedContent tabHeader='My forms' itemKey={tabKeys.submissions}>
                    <FormsMySubmissions
                        isTableLoading={isFormRecordsLoading}
                        setTableLoading={setFormRecordsLoading}
                        formRecords={formRecords ?? []}
                        setFormRecords={setFormRecords}
                        showBubble={showBubble}
                        setShowBubble={setShowBubble}
                    />
                </TabbedContent>
                {isFormOwner && (
                    <TabbedContent tabHeader='Manage' itemKey={tabKeys.manage}>
                        <FormsManageSearch />
                    </TabbedContent>
                )}
                {hasTemplateAccess && (
                    <TabbedContent tabHeader='Templates' itemKey={tabKeys.templates}>
                        <FormsTemplates forms={formTemplates} setForms={setFormTemplates} />
                    </TabbedContent>
                )}
            </Tabs>
            {showDuplicateModal && selectedForm && (
                <FormsSelectDuplicateModal
                    selectedForm={selectedForm}
                    records={formRecords}
                    isOpen={showDuplicateModal}
                    setIsOpen={setShowDuplicateModal}
                />
            )}
            {showDecisionModal && selectedForm && (
                <FormsDecisionModal
                    selectedForm={selectedForm}
                    records={formRecords}
                    isOpen={showDecisionModal}
                    setIsOpen={setShowDecisionModal}
                />
            )}
            {showUnavailableFormError && ( // TODO: Migrate to long term solution documented here:
                // Forms: Settings / Block v-/b-/t-/a- users from submitting form
                // https://msazure.visualstudio.com/Microsoft%20Personnel/_workitems/edit/25160328
                <FormsUnavailableModal
                    isOpen={showUnavailableFormError}
                    setIsOpen={setShowUnavailableFormError}
                />
            )}
        </>
    );
}

const styles = mergeStyleSets({
    availableContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem',
        justifyContent: 'flex-start',
    },
    searchBox: {
        margin: '1rem 0 1rem auto',
        width: '17rem',
    },
    containerWidthOnViewport: {
        width: '1485px',
        selectors: {
            '@media (max-width: 1528px)': {
                width: '1106px',
            },
            '@media (max-width: 1152px)': {
                width: '727px',
            },
            '@media (max-width: 824px)': {
                width: '348px',
            },
        },
    },
    centered: {
        margin: '0 auto',
    },
});

const tileContainer = mergeStyles(
    styles.availableContainer,
    styles.containerWidthOnViewport,
    styles.centered,
);

const searchContainer = mergeStyles(styles.containerWidthOnViewport, styles.centered);
