import React, { useMemo } from 'react';
import { mergeStyleSets, Stack, getTheme } from '@fluentui/react';
import { mediumGrey } from 'assets/constants/global-colors';

/**
 * See suggested usage in stat-cards-row.tsx.
 */

interface IStatTileProps {
    title?: string; // Appears on the upper half
    text?: string; // Appears on the lower half
    color?: string; // applies to props.text
    background?: string; // applies to props.text

    highlight?: boolean; // If true, shows a pronounced shadow around the tile.

    disable?: boolean; // Default: false. Prevents onClick.
    onClick?: () => void;
    ariaLabel?: string; // Optional. If not provided, it will be set to `${props.title} ${props.text}`.
    role?: string; // Optional. If not provided, it will be set to 'button'.
    ariaPressed?: boolean; // Optional. If not provided, it will be set to false.
}

export default function StatTile(props: IStatTileProps): JSX.Element {
    const styles = useMemo(
        () =>
            mergeStyleSets({
                root: {
                    minWidth: 80,
                },
                container: [
                    {
                        cursor: !!props.disable ? undefined : 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 14,
                        boxShadow: getTheme().effects['elevation16'],
                        transition: 'box-shadow 300ms ease',
                        borderRadius: getTheme().effects.roundedCorner2,
                    },
                    props.highlight
                        ? {
                              boxShadow: `5px 0px 15px ${mediumGrey}, 0px 5px 15px ${mediumGrey}, -5px 0px 15px ${mediumGrey}, 0px -5px 15px ${mediumGrey}`,
                          }
                        : {},
                ],
                title: {
                    height: 34,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                },
                text: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxSizing: 'border-box',
                    height: 23,
                    padding: '0px 15px',
                    minWidth: 66, // Is equal to (root.minWidth - container.marginLeft)
                    fontSize: 11,
                    textTransform: 'uppercase',
                    color: props?.color,
                    backgroundColor: props?.background,
                },
            }),
        [props],
    );

    const onClick = (): void => {
        !props.disable && !!props.onClick && props.onClick();
    };

    return (
        <div className={styles.root}>
            <div
                onClick={onClick}
                onKeyDownCapture={(e): void => {
                    if (
                        ['enter', 'space', 'numpadEnter'].findIndex(
                            (x) => x.toLocaleLowerCase() === e.code.toLowerCase(),
                        ) !== -1
                    ) {
                        onClick();
                        e.preventDefault();
                    }
                }}
                className={styles.container}
                tabIndex={0}
                aria-label={props.ariaLabel ?? `${props.title} ${props.text}`}
                role={props.role ?? ''}
                aria-pressed={props.ariaPressed ?? undefined}>
                <Stack>
                    <div className={styles.title}>{props?.title ?? ''}</div>
                    <div className={styles.text}>{props?.text ?? ''}</div>
                </Stack>
            </div>
        </div>
    );
}
