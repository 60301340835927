import React, { useMemo, ReactNode } from 'react';
import { mergeStyleSets } from '@fluentui/react';

interface IndentProps {
    children: ReactNode;
    marginLeft?: number;
}

const defaults = {
    marginLeft: 30,
};

export default function Indent(props: IndentProps): JSX.Element {
    const styles = useMemo(
        () =>
            mergeStyleSets({
                indent: {
                    marginLeft: props.marginLeft ?? defaults.marginLeft,
                },
            }),
        [props.marginLeft],
    );

    return <div className={styles.indent}>{props.children}</div>;
}
