import React, { useState } from 'react';
import {
    DefaultButton,
    PrimaryButton,
    ProgressIndicator,
    MessageBar,
    MessageBarType,
    mergeStyles,
} from '@fluentui/react';

export const stepperFooterStyles = mergeStyles({
    backgroundColor: 'rgb(253, 253, 253)',
    padding: '14px',
    marginTop: 10,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
});

const finalIndicatorStyles = mergeStyles({
    padding: '5px 15px 5px 15px',
});

export interface ConfigurableButtonsStepperProps {
    renderContent: () => JSX.Element;
    shouldDisplayDefaultButtons?: boolean; // Default: true.
    renderButtons?: () => JSX.Element;
    isFirstStep: boolean;
    isLastStep: boolean;
    activeStep: number;
    finishText?: string;
    handleBack: (ev: React.MouseEvent<any>) => any;
    handleNext: (ev: React.MouseEvent<any>) => any;
    handleFinish?: (ev: React.MouseEvent<any>) => any;
    handleCancel?: (ev: React.MouseEvent<any>) => any;
    hasMessage: boolean;
    disableFinishButton?: boolean;
    finishIndicatorLabel?: string;
    messageText?: string;
    messageType?: MessageBarType;
    handleResetMessage: () => void;
}

function ConfigurableButtonsStepper(props: ConfigurableButtonsStepperProps): JSX.Element {
    const { shouldDisplayDefaultButtons = true } = props;

    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const finishIndicatorLabel = props.finishIndicatorLabel
        ? props.finishIndicatorLabel
        : 'Working...';
    const finalFooterStyles = mergeStyles(stepperFooterStyles, {
        justifyContent: props.isFirstStep ? 'flex-end' : 'space-between',
    });

    return (
        <div>
            {props.renderContent()}
            {props.hasMessage && (
                <MessageBar
                    styles={{
                        root: { marginTop: 25 },
                    }}
                    messageBarType={props.messageType}
                    isMultiline={true}
                    dismissButtonAriaLabel='Close'
                    overflowButtonAriaLabel='See more'
                    onDismiss={onDismissMessage}>
                    <div>{props.messageText}</div>
                </MessageBar>
            )}
            <div className={finalIndicatorStyles}>
                {isButtonDisabled && <ProgressIndicator label={finishIndicatorLabel} />}
            </div>
            {!shouldDisplayDefaultButtons && props.renderButtons && props.renderButtons()}
            {shouldDisplayDefaultButtons && (
                <ConfigurableStepperDefaultButtons
                    finalFooterStyles={finalFooterStyles}
                    isLastStep={props.isLastStep}
                    activeStep={props.activeStep}
                    finishText={props.finishText}
                    handleBack={props.handleBack}
                    handleNext={props.handleNext}
                    handleFinish={handleFinish}
                    handleCancel={props.handleCancel}
                    disableFinishButton={props.disableFinishButton}
                    isButtonDisabled={isButtonDisabled}
                />
            )}
        </div>
    );
    function onDismissMessage(): void {
        props.handleResetMessage();
    }
    function handleFinish(ev: React.MouseEvent<any>): void {
        if (props.handleFinish) {
            setButtonDisabled(true);
            props.handleFinish(ev);
        }
    }
}
export default ConfigurableButtonsStepper;

export type ConfigurableStepperDefaultButtonsProps = {
    finalFooterStyles: string;
    isLastStep: boolean;
    activeStep: number;
    finishText?: string;
    handleBack: (ev: React.MouseEvent<any>) => any;
    handleNext: (ev: React.MouseEvent<any>) => any;
    handleFinish?: (ev: React.MouseEvent<any>) => any;
    handleCancel?: (ev: React.MouseEvent<any>) => any;
    // eslint-disable-next-line react/boolean-prop-naming
    disableFinishButton?: boolean;
    isButtonDisabled: boolean;
};

export function ConfigurableStepperDefaultButtons(
    props: ConfigurableStepperDefaultButtonsProps,
): JSX.Element {
    return (
        <div className={props.finalFooterStyles}>
            <DefaultButton
                style={{ marginRight: '10px' }}
                text='Cancel'
                onClick={props.handleCancel}
                allowDisabledFocus
                disabled={props.isButtonDisabled}
            />
            <div>
                {props.activeStep > 0 && (
                    <DefaultButton
                        style={{ marginRight: '10px' }}
                        onClick={props.handleBack}
                        text='Back'
                        allowDisabledFocus
                        disabled={props.isButtonDisabled}
                    />
                )}
                <PrimaryButton
                    onClick={(ev) =>
                        props.isLastStep
                            ? props.handleFinish
                                ? props.handleFinish(ev)
                                : console.warn('No final step function provided')
                            : props.handleNext(ev)
                    }
                    text={
                        props.isLastStep
                            ? props.finishText !== ''
                                ? props.finishText
                                : 'Finish'
                            : 'Next'
                    }
                    allowDisabledFocus
                    disabled={
                        props.isLastStep && props.disableFinishButton
                            ? props.disableFinishButton
                            : props.isButtonDisabled
                    }
                />
            </div>
        </div>
    );
}
