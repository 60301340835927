import React, { ReactNode } from 'react';
import Spacer from 'components/common/spacer';
import HorizontalBar from 'components/common/horizontal-bar';

import { mergeStyles, mergeStyleSets, Stack, IStyle } from '@fluentui/react';

interface ICenterContentsHorizontallyProps {
    isVisible: boolean;
    children: ReactNode;
    // If you specify a width on innerStyle and outerStyle,
    // make them such that (2*innerStyle + outerStyle) = 100%
    // otherwise it may not center the contents (ie, props.children).
    // If not specified, each one will be 33%.
    innerStyle?: IStyle;
    outerStyle?: IStyle;
}

export default function HorizontalBarCenterContents(
    props: ICenterContentsHorizontallyProps,
): JSX.Element {
    if (!props.isVisible) {
        return <></>;
    }
    return (
        <div>
            <Spacer marginTop={10} />
            <HorizontalBar>
                <Stack.Item className={mergeStyles(styles.oneThird, props.outerStyle)}>
                    {' '}
                </Stack.Item>
                <Stack.Item className={mergeStyles(styles.oneThird, props.innerStyle)}>
                    {props.children}
                </Stack.Item>
                <Stack.Item className={mergeStyles(styles.oneThird, props.outerStyle)}>
                    {' '}
                </Stack.Item>
            </HorizontalBar>
        </div>
    );
}

const styles = mergeStyleSets({
    oneThird: {
        width: '33%',
    },
});
