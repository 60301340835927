import React from 'react';
import { TableCell } from 'components/common/table';
import { IColumn, Stack } from '@fluentui/react';
import { xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { TimeFormats, timeToString } from 'utils/time-utils';
import { GroupRole, IGroupMembership } from 'clients/group-client';
import Badge from 'components/common/badge';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import ManageGroupMemberDetailsModalActionButton from 'components/groups/common/manage-group-member-details-modal-action-button';
import ManageGroupMemberDeleteModalActionButton from 'components/groups/common/manage-group-member-delete-modal-action-button';
import { getCompliantColor, getCompliantStatus } from 'components/groups/groups-utils';
import { toTitleCase } from 'utils/string-utils';
import { GroupTableColumnKeys } from 'components/groups/manage-group/members/manage-group-members';

export const THE_SYSTEM = 'The system';

interface IManageGroupTableColumns {
    enableOwnerDemote: boolean;
    sortColumnHandler: (newColumnName: string) => void;
    sortColumn: string;
    onGroupMemberDeleted: (personnelId: string) => void;
    onUpdateMemberDetails: (personnelId: string) => void;
    foundStatusNumber: () => number;
    isAuditor: boolean;
    isDynamicGroup: boolean;
}

function manageGroupsTableColumns(props: IManageGroupTableColumns): IColumn[] {
    const columnWidths = {
        name: 80,
        role: 30,
        status: 42 * props.foundStatusNumber(),
        dateAdded: 80,
        addedBy: 130,
        actions: 150,
    };

    const columns: IColumn[] = [
        {
            key: GroupTableColumnKeys.Name,
            name: GroupTableColumnKeys.Name,
            ariaLabel: GroupTableColumnKeys.Name,
            minWidth: columnWidths.name,
            maxWidth: columnWidths.name * xLargeMaxWidthCoeff,

            // Groups service will return the principal id in the personnel id field for M2
            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell key={row.personnelId}>
                    <CoreEmployeeHoverCardFromPrincipalId principalId={row.personnelId} />
                </TableCell>
            ),
        },
        {
            key: GroupTableColumnKeys.Role,
            name: GroupTableColumnKeys.Role,
            ariaLabel: GroupTableColumnKeys.Role,
            minWidth: columnWidths.role,
            maxWidth: columnWidths.role * xLargeMaxWidthCoeff,

            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell>
                    <EllipsisTextCss text={toTitleCase(row.role)} />
                </TableCell>
            ),
        },
        {
            key: GroupTableColumnKeys.Status,
            name: GroupTableColumnKeys.Status,
            ariaLabel: GroupTableColumnKeys.Status,
            minWidth: columnWidths.status,
            maxWidth: columnWidths.status * xLargeMaxWidthCoeff,

            onRender: (row: IGroupMembership): JSX.Element => {
                const status = getCompliantStatus(!!row.compliant, false, false);
                const graceStatus = getCompliantStatus(!!row.compliant, true, false);
                const allowListStatus = getCompliantStatus(!!row.compliant, false, true);

                return (
                    <TableCell>
                        <Stack horizontal>
                            <Badge
                                text={status}
                                backgroundColor={getCompliantColor(!!row.compliant, false, false)}
                                marginRight='10px'
                            />

                            {row.compliant && row.hasViolationOnGracePeriod && (
                                <Badge
                                    text={graceStatus}
                                    marginRight='10px'
                                    backgroundColor={getCompliantColor(
                                        !!row.compliant,
                                        true,
                                        false,
                                    )}
                                />
                            )}

                            {row.inAllowList && (
                                <Badge
                                    text={allowListStatus}
                                    backgroundColor={getCompliantColor(
                                        !!row.compliant,
                                        false,
                                        true,
                                    )}
                                />
                            )}
                        </Stack>
                    </TableCell>
                );
            },
        },
        {
            key: GroupTableColumnKeys.DateAdded,
            name: GroupTableColumnKeys.DateAdded,
            ariaLabel: GroupTableColumnKeys.DateAdded,
            minWidth: columnWidths.dateAdded,
            maxWidth: columnWidths.dateAdded * xLargeMaxWidthCoeff,

            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell>
                    {timeToString(row?.addedTimestampUTC * 1000, TimeFormats.MMMDYYYY_hmmssA)}
                </TableCell>
            ),
        },
        {
            key: GroupTableColumnKeys.AddedBy,
            name: GroupTableColumnKeys.AddedBy,
            ariaLabel: GroupTableColumnKeys.AddedBy,
            minWidth: columnWidths.addedBy,
            maxWidth: columnWidths.addedBy * xLargeMaxWidthCoeff,
            onColumnClick: (): void => {
                props.sortColumnHandler(GroupTableColumnKeys.AddedBy);
            },
            // Groups service will return the principal id in the personnel id field for M2
            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell key={row.addedBy + '_addedBy'}>
                    <CoreEmployeeHoverCardFromPrincipalId
                        principalId={row.addedBy}
                        displayName={row.addedBy ? '' : THE_SYSTEM}
                    />
                </TableCell>
            ),
        },
        {
            key: GroupTableColumnKeys.Actions,
            name: GroupTableColumnKeys.Actions,
            ariaLabel: GroupTableColumnKeys.Actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions * xLargeMaxWidthCoeff,

            onRender: (row: IGroupMembership): JSX.Element => (
                <TableCell>
                    <Stack horizontal>
                        <ManageGroupMemberDetailsModalActionButton
                            onUpdateMemberDetails={props.onUpdateMemberDetails}
                            memberDetails={row}
                        />

                        {!props.isAuditor && (
                            <ManageGroupMemberDeleteModalActionButton
                                enable={
                                    (!props.isDynamicGroup || row.role !== GroupRole.MEMBER) && // Members can't be deleted from dynamic groups
                                    (row.role !== GroupRole.OWNER || props.enableOwnerDemote) // Owners can't be deleted if there are only 2 FTE owners (when FeatureFlagKeys.groupsMin2FteOwners is enabled)
                                }
                                memberDetails={row}
                                personnelId={row.personnelId}
                                onGroupMemberDeleted={props.onGroupMemberDeleted}
                            />
                        )}
                    </Stack>
                </TableCell>
            ),
        },
    ];
    return columns;
}
export default manageGroupsTableColumns;
