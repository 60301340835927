import { IComboBoxStyles, IStackTokens, mergeStyleSets } from '@fluentui/react';

export const separatorStyles = mergeStyleSets({
    root: {
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                opacity: 0.9,
            },
        },
    },
    content: {
        paddingLeft: 0,
        fontWeight: '500',
    },
});

export const filterMargin = { root: { marginBottom: 15 } };
export const filterMarginMarginTop10 = { root: { marginBottom: 15, marginTop: 10 } };
export const stackTokensChildSpace: IStackTokens = { childrenGap: 10 };
export const dropdownComboBox: Partial<IComboBoxStyles> = {
    root: { maxWidth: 300 },
    optionsContainer: { maxHeight: 540 },
};
