import { ActionButton } from '@fluentui/react';
import { IconNames } from 'assets/constants/global-constants';
import React from 'react';

interface IExpandColumnsActionButtonProps {
    isColumnsExpanded: boolean;
    onExpandColumnsClick: () => void;
}

export default function ExpandColumnsActionButton(
    props: IExpandColumnsActionButtonProps,
): JSX.Element {
    return (
        <ActionButton
            iconProps={
                props.isColumnsExpanded
                    ? { iconName: IconNames.CalculatorSubtract }
                    : { iconName: IconNames.Add }
            }
            onClick={props.onExpandColumnsClick}>
            {props.isColumnsExpanded ? 'Collapse columns' : 'Expand columns'}
        </ActionButton>
    );
}
