import { mergeStyles, mergeStyleSets, Stack } from '@fluentui/react';
import { IconNames, noDataText } from 'assets/constants/global-constants';
import { globalStyles } from 'assets/styles/global-styles';
import { IApplicationGroupMembership, IGroup } from 'clients/group-client';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { ModalSizeType } from 'components/common/modal';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import React from 'react';
import { doNothing, doNothingAsync } from 'utils/misc-utils';
import { TimeFormats, timeToString } from 'utils/time-utils';

interface IProps {
    app?: IApplicationGroupMembership;
    group: IGroup;
}

export default function AppDetailsModalActionButton(props: IProps): JSX.Element {
    const displayOneRow = (
        title: string,
        content: string | undefined | JSX.Element,
    ): JSX.Element => {
        return (
            <Stack horizontal verticalAlign='center' tokens={{ padding: 7 }}>
                <Stack.Item className={styles.left}>{title}</Stack.Item>
                <Stack.Item className={styles.right}>{content}</Stack.Item>
            </Stack>
        );
    };

    const displayTimeAdded = (): string => {
        if (props.app?.addedTimestampUTC) {
            return timeToString(props.app?.addedTimestampUTC * 1000, TimeFormats.MMMDDYYYY_hmmA);
        } else {
            return noDataText;
        }
    };

    return (
        <ModalActionButton
            text='Details'
            size={ModalSizeType.mediumLarge}
            enable={true}
            iconName={IconNames.View}
            modalTitle='App Details'
            enableSubmit={true}
            submitButtonText='Close'
            shouldHideCancelButton={true}
            onSubmit={doNothingAsync}
            onModalConcluded={doNothing}>
            {displayOneRow('App', props.app?.applicationName)}
            {displayOneRow('Group', props.group.name)}
            {displayOneRow(
                'Added By',
                <CoreEmployeeHoverCardFromPrincipalId
                    key={props.app?.addedBy}
                    principalId={props.app?.addedBy}
                />,
            )}
            {displayOneRow('Date Added', displayTimeAdded())}
            {displayOneRow('Justification', props.app?.justification || noDataText)}
        </ModalActionButton>
    );
}
const styles = mergeStyleSets({
    left: mergeStyles(
        {
            width: '30%',
        },
        globalStyles.boldFont,
    ),
    right: {
        width: '70%',
    },
});
