import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { descriptionStyles, Form } from 'components/forms/forms-common';
import 'draft-js/dist/Draft.css';
import { RichTextEditor } from 'components/forms/rich-text-editor/rich-text-editor';
import {
    CompositeDecorator,
    ContentBlock,
    ContentState,
    convertFromHTML,
    EditorState,
    RawDraftEntity,
} from 'draft-js';
import { convertToHTML, IConvertToHTMLConfig, Tag } from 'draft-convert';
import { mergeStyles, TextField } from '@fluentui/react';
import HeaderViewer from 'components/forms/element-viewer/header-viewer';
import { FocusContainer } from 'components/common/misc/focus-container';
import { globalStyles } from 'assets/styles/global-styles';

const HeaderEditStyles = mergeStyles({
    padding: '2rem',
    background: 'white',
});

type HeaderElementProps = {
    form: Form;
    setForm: React.Dispatch<React.SetStateAction<Form>>;
    isLocked?: boolean;
};

export default function HeaderElement(props: HeaderElementProps): JSX.Element {
    const { form, setForm, isLocked } = props;
    const [hasFocus, setFocus] = useState(false);

    const findLinkEntities = (
        contentBlock: ContentBlock,
        callback: (start: number, end: number) => void,
        contentState: ContentState,
    ): void => {
        contentBlock.findEntityRanges((character) => {
            const entityKey = character.getEntity();
            return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
        }, callback);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const LinkEntity = (props: any): JSX.Element => {
        const { url } = props.contentState.getEntity(props.entityKey).getData();
        return <a href={url}>{props.children}</a>;
    };

    const decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: LinkEntity,
        },
    ]);

    const getInitialEditorState = (): EditorState => {
        const fromHTML = convertFromHTML(form.description);
        const editorContentState = ContentState.createFromBlockArray(
            fromHTML.contentBlocks,
            fromHTML.entityMap,
        );
        return EditorState.createWithContent(editorContentState, decorator);
    };

    const [editorState, setEditorState] = useState(getInitialEditorState());

    const draftConvertOptions: IConvertToHTMLConfig = useMemo(() => {
        return {
            entityToHTML: (entity: RawDraftEntity, originalText: string): Tag => {
                if (entity.type === 'LINK') {
                    return (
                        <a
                            href={entity.data.url}
                            target='_blank'
                            rel='noreferrer'
                            className={globalStyles.link}>
                            {originalText}
                        </a>
                    );
                }
                return originalText;
            },
        };
    }, []);

    const updateFormDescription = useCallback((desc: string): void => {
        setForm((prev) => {
            const newForm = { ...prev };
            newForm.description = desc;
            return newForm;
        });
    }, []);

    useEffect(() => {
        const html = convertToHTML(draftConvertOptions)(editorState.getCurrentContent());
        updateFormDescription(html);
    }, [draftConvertOptions, editorState, updateFormDescription]);

    return (
        <FocusContainer onLoseFocus={() => setFocus(false)} onGainFocus={() => setFocus(true)}>
            {hasFocus ? (
                <div className={HeaderEditStyles}>
                    <TextField
                        value={form.title}
                        onChange={(ev, newValue): void =>
                            setForm((prev) => {
                                const newForm = { ...prev };
                                newForm.title = newValue ?? '';
                                return newForm;
                            })
                        }
                        placeholder='Form title...'
                        underlined
                    />
                    <div className={descriptionStyles}>
                        <RichTextEditor editorState={editorState} setEditorState={setEditorState} />
                    </div>
                </div>
            ) : (
                <HeaderViewer
                    title={form.title}
                    description={form.description}
                    saveText={'Saving message'}
                    isLocked={isLocked}
                />
            )}
        </FocusContainer>
    );
}
