import React, { useState, useEffect } from 'react';
import {
    mergeStyleSets,
    PrimaryButton,
    FontWeights,
    FontSizes,
    DefaultButton,
    Dropdown,
    Stack,
    IDropdownOption,
    IStackTokens,
    DatePicker,
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { ADJUDICATED_OPTIONS } from 'components/screening/common/employee/employee-screening-data';
import { getEnumFromString } from 'utils/enum-utils';
import {
    ClearanceLevelType,
    getParentState,
    ScreeningParentStateType,
    ScreeningStateLabels,
    ScreeningStateType,
    PublicTrustParentStateToChildrenMap,
    StateName,
    USGovParentStateToChildrenMap,
    ScreeningPaths,
} from 'components/screening/common/common-constants';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';

export interface ScreeningChangeStatusModalProps {
    screening: ICommonScreening;
    screeningPaths: ScreeningPaths;
    onClose: () => void;
    onSubmit: (updatedStatus: string, childState?: string, adjudicatedDate?: number) => void;
}

export default function ScreeningChangeStatusModal(
    props: ScreeningChangeStatusModalProps,
): JSX.Element {
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

    const [adjudicatedDate, setAdjudicatedDate] = useState<number | undefined>(
        props.screening.adjudicatedUtcMillis,
    );

    const [adjudicatedOption, setAdjudicatedOption] = useState<IDropdownOption | undefined>(
        ADJUDICATED_OPTIONS.find((x) => x.key === props.screening.adjudicatedResult),
    );

    const [selectedScreeningState, setSelectedScreeningState] = useState<
        ScreeningParentStateType | undefined
    >(
        getEnumFromString(
            ScreeningParentStateType,
            getParentState(props.screening.stateName),
            ScreeningParentStateType.Preparation,
        ),
    );

    const [selectedScreeningSubState, setSelectedScreeningSubState] = useState<
        StateName | undefined
    >(getEnumFromString(StateName, props.screening.stateName, StateName.Preparation));

    function onStateSelect(selectedKey: string | undefined): void {
        const parentState = getEnumFromString(ScreeningParentStateType, selectedKey);

        if (parentState) {
            const subStates = USGovParentStateToChildrenMap[parentState];
            setSelectedScreeningState(parentState);
            if (subStates && subStates.length > 0) {
                setSelectedScreeningSubState(subStates[0]);
            } else {
                setSelectedScreeningSubState(undefined);
            }
        }
    }

    function onSubStateSelect(selectedKey: string | undefined): void {
        const state = getEnumFromString(StateName, selectedKey);
        if (state) {
            setSelectedScreeningSubState(state);
        }
    }

    function setDate(newDate: Date | undefined | null): void {
        setAdjudicatedDate(newDate ? newDate.getTime() : undefined);
    }

    type ScreenStatusRender = {
        enableSubmit(
            adjudicatedValue: number | undefined,
            adjudicatedOption: IDropdownOption | undefined,
        ): void;
        subStateFilter(substate: ScreeningStateType | undefined): boolean;
        specificStatusRender(): JSX.Element;
    };

    const screeningStatusRenders: { [key in ScreeningParentStateType]?: ScreenStatusRender } = {
        Preparation: {
            enableSubmit: (): void => {
                setIsSubmitDisabled(false);
            },
            subStateFilter: (): boolean => {
                return true;
            },
            specificStatusRender: (): JSX.Element => {
                return <></>;
            },
        },
        Submitted: {
            enableSubmit: (): void => {
                setIsSubmitDisabled(false);
            },
            subStateFilter: (): boolean => {
                return true;
            },
            specificStatusRender: (): JSX.Element => {
                return <></>;
            },
        },
        Adjudicated: {
            enableSubmit: (
                adjudicatedValue: number | undefined,
                adjudicatedOption: IDropdownOption | undefined,
            ): void => {
                if (adjudicatedOption) {
                    setIsSubmitDisabled(false);
                } else {
                    setIsSubmitDisabled(true);
                }
            },
            subStateFilter: (): boolean => {
                return true;
            },
            specificStatusRender: (): JSX.Element => {
                return (
                    <>
                        {selectedScreeningState === ScreeningParentStateType.Adjudicated && (
                            <>
                                <div className={styles.row}>
                                    <div className={styles.keyCell}>
                                        Adjudicated Decision
                                        <span className={styles.requiredSpan}> *</span>
                                    </div>
                                    <div className={styles.valueCell}>
                                        <Dropdown
                                            key={'adjudicatedResult'}
                                            options={ADJUDICATED_OPTIONS}
                                            onChange={(
                                                event: React.FormEvent<HTMLDivElement>,
                                                option?: IDropdownOption,
                                            ): void => {
                                                setAdjudicatedOption(option);
                                            }}
                                            defaultSelectedKey={adjudicatedOption?.key}
                                        />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.keyCell}>Adjudicated Date</div>
                                    <div className={styles.valueCell}>
                                        <DatePicker
                                            ariaLabel='Adjudicated Date'
                                            value={
                                                adjudicatedDate
                                                    ? new Date(adjudicatedDate)
                                                    : undefined
                                            }
                                            onSelectDate={(newDate?: Date | null): void =>
                                                setDate(newDate)
                                            }
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                );
            },
        },
        Indoc: {
            enableSubmit: (): void => {
                setIsSubmitDisabled(false);
            },
            subStateFilter: (substate: ScreeningStateType): boolean => {
                const clearance = props.screening.clearance;
                if (props.screeningPaths === ScreeningPaths.UsGov) {
                    if (
                        substate === ScreeningStateType.IndocAwaitingUSG &&
                        clearance !== ClearanceLevelType.SCI
                    ) {
                        // The IndocAwaitingUSG substate can only be set for SCI level clearances
                        return false;
                    } else if (
                        substate === ScreeningStateType.IndocAwaitingNominee &&
                        clearance !== ClearanceLevelType.S &&
                        clearance !== ClearanceLevelType.TS
                    ) {
                        // The IndocAwaitingNominee substate can only be set for S/TS level clearances
                        return false;
                    }
                }
                return true;
            },
            specificStatusRender: (): JSX.Element => {
                return <></>;
            },
        },
        Completed: {
            enableSubmit: (): void => {
                setIsSubmitDisabled(false);
            },
            subStateFilter: (): boolean => {
                return true;
            },
            specificStatusRender: (): JSX.Element => {
                return <></>;
            },
        },
        Withdrawn: {
            enableSubmit: (): void => {
                setIsSubmitDisabled(false);
            },
            subStateFilter: (): boolean => {
                return true;
            },
            specificStatusRender: (): JSX.Element => {
                return <></>;
            },
        },
    };

    useEffect(() => {
        if (selectedScreeningState) {
            screeningStatusRenders[selectedScreeningState]?.enableSubmit(
                adjudicatedDate,
                adjudicatedOption,
            );
        }
    }, [selectedScreeningState, adjudicatedDate, adjudicatedOption]);

    function specificStatusRender(): JSX.Element {
        if (selectedScreeningState) {
            return screeningStatusRenders[selectedScreeningState]?.specificStatusRender() ?? <></>;
        }
        return <></>;
    }

    function onSubmitModal(): void {
        if (selectedScreeningState === ScreeningParentStateType.Adjudicated && adjudicatedOption) {
            props.onSubmit(selectedScreeningState, adjudicatedOption.text, adjudicatedDate);
        } else if (selectedScreeningState) {
            props.onSubmit(selectedScreeningState, selectedScreeningSubState);
        }
    }

    function renderSubStateDropdown(): JSX.Element {
        const subStatusOptions: IDropdownOption[] = [];
        if (selectedScreeningState) {
            PublicTrustParentStateToChildrenMap[selectedScreeningState]?.forEach(
                (subStatusLabel) => {
                    const shouldRenderSubstate =
                        selectedScreeningState !== ScreeningParentStateType.Nomination &&
                        selectedScreeningState !== ScreeningParentStateType.Adjudicated;
                    const showSubState = commonScreeningSubstates(
                        props.screening,
                        subStatusLabel,
                        props.screeningPaths,
                    );

                    if (shouldRenderSubstate && showSubState) {
                        subStatusOptions.push({
                            key: subStatusLabel,
                            text: ScreeningStateLabels[subStatusLabel],
                        });
                    }
                },
            );
        }

        if (subStatusOptions.length > 0) {
            return (
                <Dropdown
                    label='Sub Status'
                    defaultSelectedKey={selectedScreeningSubState}
                    options={subStatusOptions}
                    onChange={(
                        event: React.FormEvent<HTMLDivElement>,
                        option?: IDropdownOption,
                    ): void => {
                        onSubStateSelect(option?.key.toString());
                    }}
                />
            );
        } else {
            return <></>;
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <span>Change Status</span>
            </div>

            <div className={styles.body}>
                <Stack tokens={stackTokens}>
                    <Dropdown
                        label='Main Status'
                        defaultSelectedKey={selectedScreeningState}
                        options={mainStatusDropdownOptions}
                        onChange={(
                            event: React.FormEvent<HTMLDivElement>,
                            option?: IDropdownOption,
                        ): void => {
                            onStateSelect(option?.key.toString());
                        }}
                    />
                    {renderSubStateDropdown()}
                    {specificStatusRender()}
                </Stack>
            </div>

            <div className={styles.footer}>
                <DefaultButton
                    className={styles.footerBtn}
                    onClick={props.onClose}
                    text='Close'
                    allowDisabledFocus
                />
                <PrimaryButton
                    className={styles.footerBtn}
                    onClick={(): void => {
                        onSubmitModal();
                    }}
                    disabled={isSubmitDisabled}
                    text='Submit'
                    allowDisabledFocus
                />
            </div>
        </div>
    );
}

const stackTokens: IStackTokens = { childrenGap: 20 };

function commonScreeningSubstates(
    screening: ICommonScreening,
    subStatusLabel: StateName,
    screeningPath: ScreeningPaths,
): boolean {
    switch (screeningPath) {
        case ScreeningPaths.UsGov:
            switch (subStatusLabel) {
                // Public trust specific substates that we want to hide from US Gov screening
                case StateName.NominationSentToReseller:
                case StateName.ResellerSentNominationToUSG:
                    return false;
                // indoc awaiting nominee is not a valid state for SCI
                case StateName.IndocAwaitingNominee:
                    return screening.clearance !== ClearanceLevelType.SCI;
                // indoc awaiting usg is only for SCI
                case StateName.IndocAwaitingUSG:
                    return screening.clearance === ClearanceLevelType.SCI;
                default:
                    break;
            }
            break;
    }

    return true;
}

// We don't want to be able to switch to the Nomination state
const mainStatusDropdownOptions: IDropdownOption[] = Object.keys(ScreeningParentStateType)
    .filter((key) => key !== ScreeningParentStateType.Nomination)
    .map((key) => {
        return { key: key, text: ScreeningParentStateType[key as ScreeningParentStateType] };
    });

const styles = mergeStyleSets({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 400,
        maxWidth: 770,
    },
    header: {
        flex: '1 1 auto',
        borderTop: `4px solid ${SharedColors.cyanBlue10}`,
        display: 'flex',
        fontSize: FontSizes.xLargePlus,
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '25px 30px 10px 30px',
        boxSizing: 'border-box',
    },
    body: {
        padding: '0 30px 30px 30px',
    },
    radioGroupContainer: {
        marginRight: '10px',
        marginTop: '5px',
    },
    radioGroupRow: {
        marginBottom: '10px',
    },
    radioContainer: {
        display: 'inline-block',
    },
    stepContainer: {
        marginLeft: '5px',
        display: 'inline-block',
    },
    stepTitle: {
        marginTop: '5px',
        marginBottom: '5px',
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.large,
    },
    footer: {
        backgroundColor: 'rgb(253, 253, 253)',
        padding: '14px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    footerBtn: {
        marginRight: '10px',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '3px',
        paddingBottom: '3px',
        alignItems: 'center',
    },
    keyCell: {
        flexBasis: '50%',
        minWidth: 130,
        fontWeight: FontWeights.semibold,
    },
    valueCell: {
        flexBasis: '50%',
        minWidth: 130,
    },
    requiredSpan: {
        color: 'rgb(164, 38, 44)',
        paddingRight: '12px',
    },
});
