import React, { useContext, useEffect } from 'react';
import EmployeeCard, { IEmployeeCardStyle } from 'components/common/employee/employee-card';
import EmployeeMiniCard from 'components/common/employee/employee-mini-card';
import { HoverCard, HoverCardType, mergeStyleSets, PersonaSize } from '@fluentui/react';
import { IBasicEmployee } from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import {
    getDisplayNameOrDefault,
    useDetermineImage,
} from 'components/common/employee/employee-utils';
import { CacheContext } from 'contexts/cache-context';
import { isGUID } from 'utils/string-utils';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { useEmployeeBasicOrPrehireData } from 'components/common/employee/internal-employee-utils';

interface IPreHireInfo {
    preHireName: string;
}

export interface IEmployeeBasicHoverCard {
    // If you intend to let this module fetch employee
    // basic data, pass personnelId or alias to this
    // module. If, however, you have already fetched
    // basic data, pass it to employeeBasicData.
    // !! Don't pass information to more than one of them !!
    personnelId?: string;
    alias?: string;
    oid?: string;
    employeeBasicData?: IBasicEmployee;
    // If you intend to fetch the picture outside this module,
    // fetch it and pass it to the following prop. If the fetch
    // couldn't find the picture, there may be no picture to
    // fetch. In that case, pass a blank string such as ' ' to
    // the following prop to make it truthy and therefore prevent
    // the module from fetching the picture.
    //
    // If, however, you intend to let this module determine the
    // picture, leave the following prop unconnected.
    base64ImageString?: string;
    // If pre hire info is available, pass it to the following
    // prop. This would also mean there is no personnelId, alias
    // or employeeBasicData.
    preHireInfo?: IPreHireInfo;
    // If you don't want a hover card and only want to show the
    // card, set the following to true.
    // Tech Debt:
    //     Name of this component suggests that it opens a hover card.
    //     Therefore this parameter seems to defy that purpose. In
    //     addition, it would make name of this module confusing.
    //     A potential solution is to:
    //       - Change name of the component to EmployeeBasicCard,
    //       - Add an optional prop called showWithHoverCard, and
    //       - Update all instantiations that need the hover card
    //         so that they connect that prop and set it to true.
    noHoverCard?: boolean;
    // To prevent showing actions on the the employee card,
    // pass a false to this prop.
    displayActions?: boolean; // Default: true
    // If you need to pass additional styles to component
    // EmployeeCard, you can use the following.
    // As of this writing (June, 16, 21), it's only used when
    // noHoverCard is also true. In other words, at this time it
    // only affects instance of EmployeeCard when showing the
    // card permanently, not only during a mouse hover.
    employeeCardStyle?: IEmployeeCardStyle;
    // If you want the card NOT to show employee alias on the mini
    // card, pass a boolean "false" to the following prop.
    showMiniCardAlias?: boolean; // Default: true
    // If you want to display a default mini card when no employee data is available.
    // Useful for employees that no longer exist in employee database
    showDefaultMiniCard?: boolean; // Default: false
    // If you want to display a mini card when employee data IS available.
    showEmployeeMiniCard?: boolean; // Default: false
    // If you want to display a default display name.
    // Useful placeholder when data is loading or no employee record exists but you still have a valid
    // placeholder for the display name. Used in conjunction with showDefaultMiniCard
    defaultDisplayName?: string; // Default: null
    showFullName?: boolean; // Default: true
    // If you want the component to give you the employee basic
    // data that it has fetched, pass a function here.
    onEmployeeBasicData?: (data: IBasicEmployee | undefined) => void;
}

export default function EmployeeBasicHoverCard(props: IEmployeeBasicHoverCard): JSX.Element {
    const authContext = useContext(AuthContext);
    const cacheContext = useContext(CacheContext);

    const showMiniCardAlias = props.showMiniCardAlias ?? true;
    const showFullName = props.showFullName ?? true;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const displayActionsVar = props.displayActions ?? true;

    const { prehireData, employeeBasicData } = useEmployeeBasicOrPrehireData({
        authContext: authContext,
        personnelId: props.personnelId,
        alias: props.alias,
        employeeBasicData: props.employeeBasicData,
        oid: props.oid,
    });

    const image = useDetermineImage({
        authContext: authContext,
        employee: employeeBasicData,
        cacheContext: cacheContext,
        base64ImageString: props.base64ImageString,
    });

    useEffect(() => {
        if (props.onEmployeeBasicData) {
            props.onEmployeeBasicData(employeeBasicData);
        }
    }, [employeeBasicData]);

    function onRenderPlainCard(cardProps: {
        displayName: string;
        onPremisesSamAccountName: string;
        mail: string;
        department: string;
        managerName: string;
        reportsToEmailName: string;
        jobTitle: string;
        image: string;
        displayActions: boolean;
        oid: string;
    }): JSX.Element {
        return (
            <EmployeeCard
                employee={{
                    displayName: cardProps.displayName,
                    alias: cardProps.onPremisesSamAccountName,
                    email: cardProps.mail,
                    jobTitle: cardProps.jobTitle,
                    department: cardProps.department,
                    oid: cardProps.oid,
                }}
                manager={cardProps.managerName}
                managerAlias={cardProps.reportsToEmailName}
                displayActions={cardProps.displayActions}
                image={cardProps.image}
                style={props.employeeCardStyle}
                oid={props.oid}
            />
        );
    }

    const plainCardProps = {
        onRenderPlainCard: onRenderPlainCard,
        renderData: { ...employeeBasicData, image, displayActions: displayActionsVar },
    };

    const inlineStyle = mergeStyleSets({
        host: {
            display: 'inline-block',
        },
    });

    if (props.personnelId && isGUID(props.personnelId)) {
        return (
            <EmployeeHoverCard
                personnelId={prehireData.personnelId}
                displayActions={displayActionsVar}>
                <EmployeeMiniCard
                    imageSize={PersonaSize.size24}
                    showAlias={showMiniCardAlias}
                    showImage={true}
                    employee={{
                        alias: '',
                        displayName: prehireData.displayName,
                    }}
                    imageUrl={image}
                    key={`hover_card_${prehireData.personnelId}`}
                />
            </EmployeeHoverCard>
        );
    } else if (props.preHireInfo?.preHireName && !props.employeeBasicData?.id) {
        return (
            <EmployeeMiniCard
                imageSize={PersonaSize.size24}
                showAlias={showMiniCardAlias}
                showImage={true}
                employee={{
                    alias: '',
                    displayName: props.preHireInfo.preHireName,
                }}
                imageUrl={image}
                key={`hover_card_${props.preHireInfo?.preHireName}`}
            />
        );
    } else if (employeeBasicData === undefined) {
        if (props.showDefaultMiniCard) {
            return (
                <EmployeeMiniCard
                    imageSize={PersonaSize.size24}
                    showAlias={showMiniCardAlias}
                    showImage={true}
                    employee={{
                        alias:
                            props.defaultDisplayName ||
                            props.alias ||
                            props.personnelId ||
                            'Unknown',
                        displayName:
                            props.defaultDisplayName ||
                            props.alias ||
                            props.personnelId ||
                            'Unknown',
                    }}
                    imageUrl={image}
                />
            );
        }
        return <></>;
    } else if (props.showEmployeeMiniCard) {
        return (
            <EmployeeMiniCard // Note (+)
                imageSize={PersonaSize.size24}
                showAlias={true}
                showImage={true}
                employee={{
                    alias: employeeBasicData.onPremisesSamAccountName,
                    displayName: employeeBasicData.displayName,
                }}
                imageUrl={image}
                key={`hover_card_${employeeBasicData.id}`}
            />
        );
    } else if (props.noHoverCard) {
        return (
            <EmployeeCard
                employee={{
                    displayName: getDisplayNameOrDefault(employeeBasicData, employeeBasicData.id),
                    alias: employeeBasicData.onPremisesSamAccountName,
                    email: employeeBasicData.mail,
                    jobTitle: employeeBasicData.jobTitle,
                    department: employeeBasicData.department,
                    oid: employeeBasicData.oid,
                }}
                manager={employeeBasicData.managerName}
                managerAlias={employeeBasicData.reportsToEmailName}
                displayActions={displayActionsVar}
                image={image}
                key={`employee_card_${employeeBasicData.id}`}
                style={props.employeeCardStyle}
            />
        );
    } else {
        return (
            <HoverCard
                styles={inlineStyle}
                plainCardProps={plainCardProps}
                instantOpenOnClick={true}
                cardOpenDelay={300}
                expandedCardOpenDelay={3000}
                type={HoverCardType.plain}>
                <EmployeeMiniCard
                    imageSize={PersonaSize.size24}
                    showAlias={showMiniCardAlias}
                    showFullName={showFullName}
                    showImage={true}
                    employee={{
                        alias: employeeBasicData.onPremisesSamAccountName,
                        displayName: getDisplayNameOrDefault(
                            employeeBasicData,
                            employeeBasicData.id,
                        ),
                        isActiveEmployee: employeeBasicData.isActiveEmployee,
                    }}
                    imageUrl={image}
                    key={`employee_minicard_${employeeBasicData.id}`}
                />
            </HoverCard>
        );
    }
}
