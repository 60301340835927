/**
 * Converts a string to its corresponding Enum type, if there is a match.
 *
 * @param type the enum type to convert to
 * @param str the string to convert from
 * @returns the converted enum type, or undefined if there was no match or str is undefined
 */
export const getEnumFromString = <T extends Record<string, string>>(
    type: T,
    str?: string,
    defaultVal?: T[keyof T],
): T[keyof T] | undefined => {
    if (!str) {
        return defaultVal;
    }
    const enumEntries = Object.entries(type);
    for (const [key, val] of enumEntries) {
        if (val === str) {
            return type[key as keyof T];
        }
    }
    return defaultVal;
};

// Was EnumNameToDisplayStringPipe in Angular app
export const enumNameToDisplayString = (data: string): string => {
    const name: string = data[0].toUpperCase() + data.slice(1).toLowerCase();
    return name.replace(/_([a-z])/g, function (g) {
        return ' ' + g[1].toUpperCase();
    });
};
