import React, {
    useState,
    useContext,
    useEffect,
    MutableRefObject,
    useRef,
    ChangeEvent,
    useMemo,
} from 'react';
import {
    mergeStyleSets,
    Modal,
    IModalProps,
    DefaultButton,
    Icon,
    FontWeights,
    FontSizes,
    ActionButton,
    ProgressIndicator,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import { containsNSTUser, containsNomineeUser } from 'components/screening/us-gov/IScreening';
import ActivitiesClient, { IActivity } from 'clients/activities-client';
import DocumentsClient, { IDocumentRecord } from 'clients/documents-client';
import TemplateClient, {
    IDocumentMetadata,
    IScreeningTemplateRequestRecord,
    ScreeningTemplateRequestedState,
    isIScreeningTemplateRequestRecordRequestingUpdate,
} from 'clients/template-client';
import Event, {
    ActivityEventBin,
    binSortEvents,
    findDocumentViewerEvents,
} from 'components/common/event';
import {
    checkIfWordDocument,
    changeFileName,
    isValidFileType,
    acceptedFileTypesForTemplate,
    acceptedFileTypesTextForTemplate,
    getFileType,
    fileInputAcceptStringForTemplate,
} from 'utils/file-utils';
import { dateToLocalLongDateFormat } from 'utils/time-utils';
import { AuthContext } from 'contexts/auth-context';
import PublicTrustDocumentClient from 'clients/screening/public-trust-document-client';
import { Dictionary } from 'assets/constants/global-constants';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import UpdateRequestModalFlag from 'components/screening/common/screening-document-feature-flag/modals/update-request-modal-flag';

export interface ViewUploadedDocumentModalProps {
    onFileUpdate?: () => void;
    onUpdateRequest?(request: IScreeningTemplateRequestRecord): void;
    documentRecord: IDocumentRecord;
    documentMetadata: IDocumentMetadata[] | undefined;
    eventBins?: ActivityEventBin[];
    isRequestDocument?: boolean;
    isUserCandidate: boolean;
    screeningEventBins?: ActivityEventBin[];
    screeningId?: string;
    screeningPath?: ScreeningPaths;
    templateRequestRecords?: IScreeningTemplateRequestRecord[] | undefined;
    userTypes?: string[];
}

enum DocumentStatus {
    LOADING = 'loading',
    DOWNLOADING = 'downloading',
    SUCCESS = 'success',
    ERROR = 'error',
}

enum UploadStatus {
    UPLOADING = 'uploading',
    SUCCESS = 'success',
    ERROR = 'error',
}

function getMetadataFromDocument(
    documentRecord: IDocumentRecord,
    documentMetadata?: IDocumentMetadata[],
): IDocumentMetadata | undefined {
    if (documentMetadata) {
        return documentMetadata.find((x) => x.title === documentRecord.fileName);
    }
}

export const documentStatuses: Dictionary<string> = {
    withNomine: 'With Nominee',
};

function ViewUploadedDocumentModalFlag(props: ViewUploadedDocumentModalProps): JSX.Element {
    const {
        documentMetadata,
        documentRecord,
        isRequestDocument,
        isUserCandidate,
        onUpdateRequest,
        onFileUpdate,
        screeningEventBins,
        screeningId,
        screeningPath,
        templateRequestRecords,
        userTypes,
    } = props;
    const { id: documentId } = documentRecord;
    const downloadLink: MutableRefObject<HTMLAnchorElement | null> = useRef(null);
    const [documentUrl, setDocumentUrl] = useState<string>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isUpdateRequestModalOpen, setIsUpdateRequestModalOpen] = useState<boolean>(false);
    const authContext = useContext(AuthContext);
    const [isWordDocument, setIsWordDocument] = useState<boolean>(
        checkIfWordDocument(documentRecord.fileMimeType),
    );

    const [eventBins, setBinsEvents] = useState<ActivityEventBin[]>();
    const [documentStatus, setDocumentStatus] = useState<DocumentStatus>(DocumentStatus.LOADING);
    const [templateRequestRecord, setTemplateRequestRecord] = useState<
        IScreeningTemplateRequestRecord | undefined
    >();
    const [documentMetadataRecord, setDocumentMetadataRecord] = useState<IDocumentMetadata>();
    const [uploadFile, setUploadFile] = useState<File>();
    const [uploadStatus, setUploadStatus] = useState<UploadStatus>();
    const [isInvalidFile, setIsInvalidFile] = useState<boolean>(true);
    const [isLockedBtnDisabled, setIsLockBtnDisabled] = useState<boolean>(false);
    const [isDocumentLocked, setIsDocumentLocked] = useState<boolean>(
        DocumentsClient.isDocumentLocked(props.documentRecord),
    );
    const [isDeleted] = useState<boolean>(DocumentsClient.isDocumentDeleted(props.documentRecord));
    const [isUpdateRequested, setIsUpdateRequested] = useState<boolean>(false);

    const [canDownload, setCanDownload] = useState<boolean>(false);
    const [activityPanelIconName, setActivityPanelIconName] = useState<string>();

    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        setDocumentMetadataRecord(getMetadataFromDocument(documentRecord, documentMetadata));
        const requestRecord = templateRequestRecords?.find(
            (x) => x.documentServiceDocumentId === documentId,
        );
        setTemplateRequestRecord(requestRecord);
        const isLocked = DocumentsClient.isDocumentLocked(props.documentRecord);
        setIsDocumentLocked(isLocked);
    }, [documentRecord, documentMetadata]);

    useEffect(() => {
        if (isDeleted) {
            setActivityPanelIconName('PageRemove');
        } else {
            setActivityPanelIconName(getLockUnlockIconName(isDocumentLocked));
        }
    }, [isDeleted, isDocumentLocked]);

    useEffect(() => {
        if (templateRequestRecord) {
            setIsUpdateRequested(
                isIScreeningTemplateRequestRecordRequestingUpdate(templateRequestRecord),
            );
        } else {
            setErrorMessage('Document Request Metadata not found.');
        }
    }, [templateRequestRecord]);

    useEffect(() => {
        if (isOpen) {
            if (templateRequestRecord && documentRecord && screeningEventBins) {
                setBinsEvents(
                    findDocumentViewerEvents(
                        screeningEventBins,
                        templateRequestRecord,
                        documentRecord,
                    ),
                );
            }
            setErrorMessage(undefined);
        }
    }, [screeningEventBins, templateRequestRecord, documentRecord, isOpen]);

    useEffect(() => {
        return function cleanup(): void {
            setBinsEvents(undefined);
            setErrorMessage(undefined);
        };
    }, []);

    function getLockUnlockIconName(isLock: boolean): string {
        return isLock ? 'Lock' : 'UnLock';
    }

    async function onUploadComplete(
        request: IScreeningTemplateRequestRecord | undefined,
    ): Promise<void> {
        if (request && onUpdateRequest) {
            request.requestedState = ScreeningTemplateRequestedState.UploadedState;
            try {
                let updatedRequest: IScreeningTemplateRequestRecord[] = [];
                if (screeningPath === ScreeningPaths.UsGov) {
                    updatedRequest = await TemplateClient.updateMultipleDocuments(authContext, [
                        request,
                    ]);
                } else if (screeningPath === ScreeningPaths.PublicTrust) {
                    updatedRequest = await PublicTrustDocumentClient.updateMultipleDocuments(
                        authContext,
                        [request],
                    );
                }

                onUpdateRequest(updatedRequest[0]);
            } catch (e) {
                console.error('fail to upload complete', e);
            }
        }
    }

    async function cancelUpdateRequest(): Promise<void> {
        if (templateRequestRecord && onUpdateRequest) {
            const updatedTemplateRecord: IScreeningTemplateRequestRecord = {
                ...templateRequestRecord,
                requestedState: ScreeningTemplateRequestedState.ReRequestedCancel,
            };
            try {
                let result: IScreeningTemplateRequestRecord[] = [];

                if (screeningPath === ScreeningPaths.UsGov) {
                    result = await TemplateClient.updateMultipleDocuments(authContext, [
                        updatedTemplateRecord,
                    ]);
                } else if (screeningPath === ScreeningPaths.PublicTrust) {
                    result = await PublicTrustDocumentClient.updateMultipleDocuments(authContext, [
                        updatedTemplateRecord,
                    ]);
                }
                const resultValue = result.pop();
                if (resultValue) {
                    onUpdateRequest(resultValue);
                }
            } catch (err) {
                setErrorMessage(
                    `Error attempting to update request status ${templateRequestRecord.id}`,
                );
            }
        }
    }

    async function onRequestUpdateBtnClick(): Promise<void> {
        if (
            templateRequestRecord?.requestedState ===
                ScreeningTemplateRequestedState.UploadedState ||
            templateRequestRecord?.requestedState ===
                ScreeningTemplateRequestedState.ReRequestedState ||
            templateRequestRecord?.requestedState === ScreeningTemplateRequestedState.Fulfilled
        ) {
            // update requested
            setIsUpdateRequestModalOpen(!isUpdateRequestModalOpen);
        }
    }

    async function onRequestLockBtnClick(): Promise<void> {
        setIsLockBtnDisabled(true);
        try {
            if (screeningId === undefined || screeningPath === undefined) {
                throw new Error(
                    `Unable to perform document lock. Document Viewer Screening properties screeningId: ${screeningId} and screeningPath: ${screeningPath}.`,
                );
            }

            const documentToken = await DocumentsClient.getDocumentToken(
                authContext,
                screeningId,
                screeningPath,
            );

            if (!documentToken || !documentToken.token) {
                throw new Error(
                    'unable to retrieve token for ' + isDocumentLocked
                        ? 'unlocking document'
                        : 'locking document',
                );
            }

            const result = await DocumentsClient.lockDocument(
                authContext,
                documentId,
                !isDocumentLocked,
                documentToken,
            );
            const isLocked = DocumentsClient.isDocumentLocked(result);
            setIsDocumentLocked(isLocked);
            if (
                isLocked &&
                templateRequestRecord?.requestedState ===
                    ScreeningTemplateRequestedState.ReRequestedState
            ) {
                await cancelUpdateRequest();
            }
        } catch (e) {
            const errorMessage = e.message
                ? e.message
                : isDocumentLocked
                ? 'unlocking document'
                : 'locking document';
            setErrorMessage(`Error ${errorMessage} file: ${props.documentRecord.fileName}`);
        }
        setIsLockBtnDisabled(false);
    }

    async function onCancelOrSubmitUpdateRequestModal(
        updatedTemplateData: IScreeningTemplateRequestRecord,
    ): Promise<void> {
        setTemplateRequestRecord(updatedTemplateData);

        if (onUpdateRequest) {
            onUpdateRequest(updatedTemplateData);
        }
    }

    const modalProps: IModalProps = {
        isOpen: isOpen,
        onDismiss: (): void => {
            setIsOpen(false);
        },
        onDismissed: () => {
            setIsOpen(false);

            if (onFileUpdate) {
                onFileUpdate();
            }
        },
        styles: modalStyles,
    };

    const openModal = (): void => {
        setIsOpen(true);
        if (!documentUrl) {
            if (!!screeningId && screeningPath !== undefined) {
                handleGettingScreeningDocument(screeningId, screeningPath);
            } else {
                handleGettingDocument();
            }
        }
    };

    async function handleGettingDocument(): Promise<void> {
        try {
            const documentToken = await ActivitiesClient.getDocumentActivityToken(
                authContext,
                documentId,
            );

            if (!!eventBins) {
                const docEventsBin = eventBins
                    ?.map((bin: ActivityEventBin) => {
                        const filteredActivities = bin.activities.filter((activity: IActivity) => {
                            return activity.referenceId === documentId;
                        });
                        return { key: bin.key, activities: filteredActivities };
                    })
                    .filter((bin: ActivityEventBin) => {
                        return bin.activities.length > 0;
                    });

                setBinsEvents(docEventsBin);
            } else {
                // Attempt to get document activities
                const actvities = await ActivitiesClient.getAllDocumentActivitiesForDocumentId(
                    authContext,
                    documentId,
                    documentToken,
                );

                const sortedBins = binSortEvents(actvities);
                setBinsEvents(sortedBins);
            }

            const document = await DocumentsClient.downloadDocument(
                authContext,
                documentId,
                documentToken.token,
            );
            const url: string = window.webkitURL.createObjectURL(
                new Blob([document], { type: documentRecord.fileMimeType }),
            );
            setDocumentUrl(url);
            setDocumentStatus(DocumentStatus.SUCCESS);
            setCanDownload(true);
        } catch (e) {
            setCanDownload(false);
            if (!isDeleted) {
                setErrorMessage('File download error');
                setDocumentStatus(DocumentStatus.ERROR);
            } else {
                setDocumentStatus(DocumentStatus.SUCCESS);
            }
        }
    }

    async function handleGettingScreeningDocument(
        screeningId: string,
        screeningPath: ScreeningPaths,
    ): Promise<void> {
        try {
            const documentToken = (
                await DocumentsClient.getDocumentToken(authContext, screeningId, screeningPath)
            ).token;
            const document = await DocumentsClient.downloadDocument(
                authContext,
                documentId,
                documentToken,
            );
            const url: string = createObjectURL(document, documentRecord.fileMimeType);
            setDocumentUrl(url);
            setDocumentStatus(DocumentStatus.SUCCESS);
            setCanDownload(true);
        } catch (e) {
            setCanDownload(false);
            if (!isDeleted) {
                setErrorMessage('File download error');
                setDocumentStatus(DocumentStatus.ERROR);
            } else {
                setDocumentStatus(DocumentStatus.SUCCESS);
            }
        }
    }

    function createObjectURL(document: Blob, type: string): string {
        if (window.webkitURL) {
            return window.webkitURL.createObjectURL(new Blob([document], { type: type }));
        } else if (window.URL && window.URL.createObjectURL) {
            return window.URL.createObjectURL(
                new Blob([document], { type: documentRecord.fileMimeType }),
            );
        } else {
            return '';
        }
    }

    async function download(): Promise<void> {
        if (documentUrl) {
            setDocumentStatus(DocumentStatus.DOWNLOADING);
            initiateBrowserDownload();
            setDocumentStatus(DocumentStatus.SUCCESS);
        }
    }

    function initiateBrowserDownload(): void {
        const downloadLinkRef: HTMLAnchorElement = downloadLink.current as HTMLAnchorElement;
        downloadLinkRef.href = documentUrl!;
        downloadLinkRef.download = documentRecord.fileName || '';
        downloadLinkRef.click();
        downloadLinkRef.href = '';
        downloadLinkRef.download = '';
    }

    async function onFileChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        if (event.target.files) {
            let file: File = event.target.files[0];
            if (file?.size === 0) {
                setIsInvalidFile(true);
                setErrorMessage('Invalid file: File is empty.');
            } else if (file && documentMetadataRecord) {
                setErrorMessage(undefined);
                file = changeFileName(file, documentMetadataRecord.title);
                const isValid: boolean = await isValidFileType(file, acceptedFileTypesForTemplate);
                if (isValid) {
                    setUploadFile(file);
                    setIsInvalidFile(false);
                } else {
                    setIsInvalidFile(true);
                    setErrorMessage(`Invalid file type. ${acceptedFileTypesTextForTemplate}`);
                }
            }
        }
    }

    async function upload(): Promise<void> {
        if (uploadFile && documentId) {
            setUploadStatus(UploadStatus.UPLOADING);
            try {
                await DocumentsClient.updateDocument(authContext, uploadFile, documentId);
                setErrorMessage(undefined);
                setUploadStatus(UploadStatus.SUCCESS);
                await onUploadComplete(templateRequestRecord);
                const url: string = window.webkitURL.createObjectURL(
                    new Blob([uploadFile], { type: uploadFile.type }),
                );
                setDocumentUrl(url);
                setUploadFile(undefined);
                setCanDownload(true);
                const uploadFileType: string = await getFileType(uploadFile);
                setIsWordDocument(checkIfWordDocument(uploadFileType));
            } catch (error) {
                let errMsg = 'File upload error';
                if (isDocumentLocked && error.status === 403) {
                    errMsg = 'Document cannot be uploaded while locked.';
                }
                setErrorMessage(errMsg);
                setUploadStatus(UploadStatus.ERROR);
                setUploadFile(undefined);
                setCanDownload(true);
            }
        }
    }

    const canUpdateRequest: boolean = useMemo(() => {
        return containsNSTUser(userTypes) && !isDocumentLocked && !isUserCandidate;
    }, [userTypes, isDocumentLocked, isUserCandidate]);

    return (
        <>
            <ActionButton onClick={openModal} iconProps={{ iconName: 'TextDocument' }}>
                {props.isRequestDocument ? 'View Document' : 'View'}
            </ActionButton>
            <Modal {...modalProps}>
                <div className={styles.body}>
                    <div className={styles.documentFrame}>
                        <div className={styles.document}>
                            {documentStatus === DocumentStatus.LOADING && <p>Loading...</p>}
                            {!isWordDocument && documentUrl && (
                                <iframe src={documentUrl} title={documentRecord.fileName} />
                            )}
                            {isWordDocument && (
                                <>
                                    <Icon iconName='WordDocument' />
                                    <p>
                                        This document cannot be securely viewed in the browser.
                                        Please download to view.
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={styles.activityPanel}>
                        <div className={styles.activityPanelHeader}>
                            <div className={styles.title}>{documentRecord.fileName}</div>
                            {props.isRequestDocument && <Icon iconName={activityPanelIconName} />}
                        </div>
                        {isDeleted && <div className={styles.statusBadge}>DELETED</div>}
                        {isDocumentLocked && <div className={styles.lockStatusBadge}>LOCKED</div>}
                        {isUpdateRequested && (
                            <div className={styles.statusBadge}>UPDATES REQUESTED</div>
                        )}

                        {eventBins &&
                            eventBins.map((obj: { key: number; activities: IActivity[] }) => {
                                return (
                                    <React.Fragment key={`event_datebin_${obj.key}`}>
                                        <div className={styles.eventDateRow}>
                                            <div className={styles.eventLine}></div>
                                            <div className={styles.eventRowGap}></div>
                                            <div>{dateToLocalLongDateFormat(obj.key)}</div>
                                        </div>
                                        {obj.activities.map((activity: IActivity) => (
                                            <Event
                                                key={`event_${activity.id}`}
                                                activity={activity}
                                                screeningId={screeningId ?? ''}
                                                screeningPath={screeningPath}
                                                provideDocumentsNameForDocumentAppActivities={true}
                                            />
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                    </div>
                </div>

                {errorMessage && (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        onDismiss={(): void => setErrorMessage(undefined)}
                        dismissButtonAriaLabel='Close'>
                        {errorMessage}
                    </MessageBar>
                )}

                {(documentStatus === DocumentStatus.LOADING ||
                    documentStatus === DocumentStatus.DOWNLOADING ||
                    uploadStatus === UploadStatus.UPLOADING) && (
                    <ProgressIndicator styles={progressBarIndicatorStyles} barHeight={1.5} />
                )}

                <div className={styles.footer}>
                    <div className={styles.actionButtons}>
                        <DefaultButton
                            disabled={!canDownload}
                            iconProps={{ iconName: 'Download' }}
                            onClick={download}>
                            {documentStatus === DocumentStatus.DOWNLOADING
                                ? 'Downloading...'
                                : 'Download'}
                        </DefaultButton>
                        <a ref={downloadLink} hidden={true} />
                        {userTypes && containsNSTUser(userTypes) && !isUserCandidate && (
                            <>
                                <DefaultButton
                                    iconProps={{
                                        iconName: getLockUnlockIconName(!isDocumentLocked),
                                    }}
                                    disabled={isLockedBtnDisabled || props.isUserCandidate}
                                    onClick={onRequestLockBtnClick}>
                                    {!isDocumentLocked ? 'Lock Document' : 'Unlock Document'}
                                </DefaultButton>
                                <DefaultButton
                                    iconProps={{ iconName: 'Edit' }}
                                    onClick={onRequestUpdateBtnClick}
                                    disabled={
                                        isLockedBtnDisabled ||
                                        !templateRequestRecord ||
                                        isDocumentLocked
                                    }>
                                    {isUpdateRequested ? 'Modify Update Request' : 'Update Request'}
                                </DefaultButton>
                            </>
                        )}
                        {isRequestDocument &&
                            userTypes &&
                            (containsNSTUser(userTypes) || containsNomineeUser(userTypes)) && (
                                <DefaultButton
                                    iconProps={{ iconName: 'TextDocument' }}
                                    onClick={onRequestUpdateBtnClick}
                                    disabled={
                                        isLockedBtnDisabled ||
                                        !templateRequestRecord ||
                                        isDocumentLocked
                                    }>
                                    View Update Request
                                </DefaultButton>
                            )}
                        {isRequestDocument && onFileChange && (
                            <div className={styles.uploadButton}>
                                <DefaultButton
                                    iconProps={{ iconName: 'OpenFile' }}
                                    type='file'
                                    disabled={isDocumentLocked}>
                                    {uploadFile?.name ?? 'Upload File'}
                                </DefaultButton>
                                <input
                                    className={styles.input}
                                    type='file'
                                    onChange={onFileChange}
                                    accept={fileInputAcceptStringForTemplate}
                                    disabled={isDocumentLocked}
                                />
                            </div>
                        )}
                        {uploadFile && !isInvalidFile && (
                            <PrimaryButton
                                onClick={upload}
                                disabled={
                                    isDocumentLocked || uploadStatus === UploadStatus.UPLOADING
                                }>
                                {uploadStatus === UploadStatus.UPLOADING
                                    ? 'Uploading...'
                                    : 'Upload'}
                            </PrimaryButton>
                        )}
                    </div>
                    <DefaultButton onClick={(): void => setIsOpen(false)}>Close</DefaultButton>
                </div>
            </Modal>

            {isRequestDocument && screeningPath !== undefined && (
                <Modal
                    onDismiss={(): void => {
                        setIsUpdateRequestModalOpen(false);
                    }}
                    isOpen={isUpdateRequestModalOpen}>
                    <UpdateRequestModalFlag
                        isEditable={canUpdateRequest}
                        templateData={templateRequestRecord}
                        onCancelOrSubmit={onCancelOrSubmitUpdateRequestModal}
                        onClose={(): void => setIsUpdateRequestModalOpen(false)}
                        screeningPath={screeningPath}
                    />
                </Modal>
            )}
        </>
    );
}

const modalStyles = mergeStyleSets({
    main: {
        maxWidth: '1400px',
        width: '90%',
        backgroundColor: '#F6F6F6',
    },
});

const progressBarIndicatorStyles = mergeStyleSets({
    itemProgress: {
        padding: '0',
    },
});

const styles = mergeStyleSets({
    body: {
        display: 'flex',
        flexGrow: '1',
    },
    documentFrame: {
        flexGrow: '1',
        paddingRight: '15px',
        overflow: 'hidden',
        boxSizing: 'border-box',
    },
    document: {
        backgroundColor: '#E6E6E6',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'rgb(102, 102, 102) 0px 0px 15px -5px',
        height: '100%',
        width: '100%',
        selectors: {
            iframe: {
                height: '100%',
                width: '100%',
                border: 'none',
            },
            i: {
                fontSize: FontSizes.mega,
                color: '#2b579a',
            },
            p: {
                textAlign: 'center',
                padding: '1rem',
            },
        },
    },
    activityPanel: {
        backgroundColor: '#F6F6F6',
        padding: '1rem',
        overflowY: 'auto',
        height: 'calc(80vh - 4rem)',
        boxSizing: 'border-box',
        minWidth: '340px',
    },
    activityPanelHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        selectors: {
            '> i': {
                fontSize: '1rem',
                lineHeight: '1rem',
                paddingTop: '0.3rem',
            },
        },
    },
    title: {
        fontWeight: FontWeights.semibold,
        fontSize: '1.5rem',
    },
    statusBadge: {
        display: 'inline-block',
        fontSize: '0.75rem',
        backgroundColor: 'rgb(212, 0, 0)',
        color: 'rgba(255, 255, 255, 0.867)',
        padding: '2px 7px',
        borderRadius: '1.5px',
    },
    lockStatusBadge: {
        display: 'inline-block',
        fontSize: '0.75rem',
        backgroundColor: SharedColors.cyanBlue10,
        color: 'rgba(255, 255, 255, 0.867)',
        padding: '2px 7px',
        borderRadius: '1.5px',
    },
    footer: {
        boxSizing: 'border-box',
        padding: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: '#FDFDFD',
        border: '1px solid rgba(0, 0, 0, 0.1)',
    },
    actionButtons: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto auto auto',
        gridGap: '0.5rem',
    },
    eventLine: {
        height: '1px',
        marginTop: '10px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        flexGrow: 1,
    },
    eventDateRow: {
        marginBottom: '10px',
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'row',
    },
    eventRowGap: {
        width: '10px',
    },
    uploadButton: {
        position: 'relative',
        overflow: 'hidden',
        display: 'inline-block',
    },
    input: {
        position: 'absolute',
        left: '0',
        top: '0',
        opacity: '0',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
    },
    fileName: {
        marginLeft: '10px',
    },
});

export default ViewUploadedDocumentModalFlag;
