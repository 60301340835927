import React, { useState, useContext, useEffect } from 'react';
import Modal from 'components/common/modal';
import { IAllocationItem } from 'components/staffing/staffing-page-types';
import StaffingClient from 'clients/staffing-client';
import { AuthContext } from 'contexts/auth-context';

interface DeleteAllocationModalProps {
    allocation: IAllocationItem;
    isVisible: boolean;
    deleteAllocationCallback: (somethingModified: boolean) => void;
}

export default function DeleteAllocationModal(props: DeleteAllocationModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const { isVisible, deleteAllocationCallback, allocation } = props;
    const [errorMsg, setErrorMsg] = useState<string>();
    const [isDeleteDisabled, setDisableDelete] = useState(false);
    const [isSubmitInProgress, setIsSubmitInProgress] = useState<boolean>(false);

    const deleteAllocation = async (): Promise<void> => {
        const { PCN } = allocation;
        if (PCN) {
            setDisableDelete(true);
            setIsSubmitInProgress(true);
            try {
                await StaffingClient.deleteAllocation(authContext, PCN);
                await deleteAllocationCallback(true);
            } catch {
                setErrorMsg('Error occurred. Could not delete allocation.');
            } finally {
                setDisableDelete(false);
                setIsSubmitInProgress(false);
            }
        } else {
            deleteAllocationCallback(false);
        }
    };

    const resetErrorMsg = (): void => {
        setErrorMsg('');
    };

    useEffect(() => {
        resetErrorMsg();
    }, []);

    const cancel = (): void => {
        resetErrorMsg();
        deleteAllocationCallback(false);
    };

    const name = allocation.employee?.displayName;
    return (
        <Modal
            isOpen={isVisible}
            onCancel={cancel}
            onDismiss={cancel}
            onSubmit={deleteAllocation}
            onResetErrorMsg={resetErrorMsg}
            errorMsg={errorMsg}
            submitButtonText='Delete'
            isSubmitButtonDisabled={isDeleteDisabled}
            showProgressIndicator={isSubmitInProgress}
            title={'Delete Allocation'}>
            Are you sure you want to delete the allocation for{' '}
            <span style={{ fontWeight: 'bold' }}>{name}</span>
            <span>&nbsp;({allocation?.employee?.onPremisesSamAccountName?.toLowerCase()})</span>?
        </Modal>
    );
}
