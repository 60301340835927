import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Dictionary } from 'assets/constants/global-constants';

export const generateRandomKey = (keyForWhat?: string) => {
    // Example for parameter keyForWhat: "DetailsList"
    // Result will be DetailsList_RandomKey_ddddddd
    const keyPrefix = keyForWhat ? `${keyForWhat}_` : '';
    return `${keyPrefix}RandomKey_${Math.random().toString(36).substring(7)}`;
};

export function uniqueItemsList<T>(items: T[]): T[] {
    const uniqueSet = items.reduce((uniqueSet, item) => {
        uniqueSet.add(item);
        return uniqueSet;
    }, new Set<T>());

    const uniquified: T[] = [];

    uniqueSet.forEach((item) => uniquified.push(item));

    return uniquified;
}

export const validateNonEmptyString = (input: string | undefined = ''): boolean => input.length > 0;

// eslint-disable-next-line no-useless-escape
export const REGEX_PHONE = new RegExp(/^[\d\+\(\)\-\.\s]+[\x\#\*]?[\s]?\d+$/);
export const PHONE_NUMBER_MAX_LENGTH = 18;
export const PHONE_NUMBER_MIN_LENGTH = 10;

export function validatePhoneNumber(phoneNumber: string | undefined = ''): boolean {
    if (
        phoneNumber.match(REGEX_PHONE) &&
        phoneNumber.length <= PHONE_NUMBER_MAX_LENGTH &&
        phoneNumber.length >= PHONE_NUMBER_MIN_LENGTH
    )
        return true;
    return false;
}

// https://www.w3resource.com/javascript/form/email-validation.php
export const REGEX_EMAIL = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
);

export const REGEX_SSN = new RegExp(/^\d{3}-\d{2}-\d{4}$/);

export const REGEX_PCN = new RegExp(/^\d{1,10}$/);

/**
 * @param performTask
 * @param cleanupFunc
 * @returns () => void
 *
 * Example:
 *     async function fetchMoreEmployees(isMounted: () => boolean): Promise<void> {
 *         const moreEmployeesVar = await fetchEmployees();
 *         if (isMounted()) {
 *             setEmployees(employees.concat(moreEmployeesVar))
 *         }
 *     }
 *
 *     const clearTimers = () => {
 *         clearTimeout(timer1)
 *         clearTimeout(timer2)
 *     }
 *
 *     useEffect(() => {
 *         // IMPORTANT NOTE
 *         // Specifying the "return" statement in the following is necessary
 *         // because it will then return the cleanup function for useEffect.
 *         // Without it there will be no clean up and variable isMounted
 *         // will not be reset during unmount.
 *         return generalIsMountedCode(
 *             fetchMoreEmployees, // This is argument performTask
 *             clearTimers, // This is argument cleanupFunc
 *         )
 *     }, [loadMore])
 *
 * It's a handful, I know. It already fried my brain.
 */

/**
 * @deprecated The "isMounted" pattern is deprecated. Do not use.
 */
export const generalIsMountedCode = (
    performTask: (isMountedFunc: () => boolean) => void | Promise<void>,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    cleanupFunc: () => void = (): void => {},
): (() => void) => {
    let isMounted = true;
    const isMountedFunc = (): boolean => isMounted;

    performTask(isMountedFunc);

    return (): void => {
        isMounted = false;
        cleanupFunc();
    };
};

/**
 * @deprecated The "isMounted" pattern is deprecated. Do not use.
 */
export const generalIsMountedCodes = (
    performTask: ((isMountedFunc: () => boolean) => Promise<void>)[],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    cleanupFunc: () => void = (): void => {},
): (() => void) => {
    let isMounted = true;
    const isMountedFunc = (): boolean => isMounted;

    performTask.forEach((x): Promise<void> => x(isMountedFunc));

    return (): void => {
        isMounted = false;
        cleanupFunc();
    };
};

/**
 * @param error The error caught by a "catch" clause.
 * @returns true/false; whether the function readErrorMessageBody
 *          can be used to read the error message.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const canReadErrorMessageBody = (error: any): boolean => {
    return error.body !== undefined && error.body instanceof ReadableStream;
};

/**
 * Can be called by a "catch" clause to read and decode
 * body of the error message in the exception. Will work
 * only if the error has a "body" property and if it's
 * a ReadableStream.
 *
 * @param error The error caught by a "catch" clause.
 * @returns String value of error.body
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const readErrorMessageBody = async (error: any): Promise<string | undefined> => {
    if (!canReadErrorMessageBody(error)) {
        return undefined;
    }
    const eBody = await error.body.getReader().read();
    const errorMessage = new TextDecoder().decode(eBody.value).toString();
    return errorMessage;
};

/**
 * Extracts error message body.
 * Can handle errors sent as a JSON object or as a string.
 * @param response The response that's been received from backend.
 * @returns Promise<string | object | undefined>
 */
export const extractErrorMessage = async (
    response: Response,
): Promise<string | object | undefined> => {
    const errorMessageBody = await readErrorMessageBody(response);
    if (!errorMessageBody) {
        return undefined;
    }
    try {
        const jsonObject = JSON.parse(errorMessageBody);
        return jsonObject as object;
    } catch {
        return errorMessageBody as string;
    }
};

/**
 * Allows access to the URL params provided to react router
 * @returns URLSearchParams object to access query params
 */
export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

/**
 *
 * @param paramNames List of URL search parameter names
 * @returns Name and value of the URL search parameters specified in the input
 */
export function useUrlSearchParams(paramNames?: string[]): Dictionary<string[]> {
    const locationPath = useLocation();
    const result = useMemo(() => {
        const resultVar: Dictionary<string[]> = {};
        const urlSearchParams = new URLSearchParams(locationPath.search);
        paramNames?.forEach((paramName) => {
            resultVar[paramName] = urlSearchParams.getAll(paramName);
        });
        return resultVar;
    }, [locationPath.search]);

    return result;
}

/**
 * function makeDictFromList(list: Dictionary<any>[], key: string)
 * Mostly suitable for creating dictionaries out of static lists
 * such as reviewPeriodStatuses() in file 'sca/sca-constants.ts'
 * because when we're creating static lists such as the above example
 * we make sure it contains all the necessary data but when a list
 * is created dynamically, it may contain "holes" such as some
 * non-dictionary list items. Such holes can cause runtime errors. The
 * function contains some checks to avoid such holes but I don't know
 * if the checks I have put in place are comprehensive. If we are certain
 * the list contains all the necessary data, it's safe to use this
 * function to process them.
 *
 * The important thing is, the list should be a list of "dictionaries".
 * If all the list items are, indeed, dictionaries, running the function
 * shouldn't cause runtime error.
 *
 * @param list A list of dictionaries.
 *             Example: Returned value of reviewPeriodStatuses() in file 'sca/sca-constants.ts'.
 * @param key  The key based on which you want to create a dictionary of those items.
 *             The key must be one of the keys in the dictionaries.
 *             Value of the "key" for each item of the list must be unique.
 *             Example valid key: "personnelId", because we know it's going to be unique.
 *             Example (potentially) inavlid key: "age" because it may not be unique.
 * @returns    A dictionary of items.
 *             The keys are value of the above key for each item.
 *
 * Example: Given the following list
 *      [
 *          { state: "APPROVAL",
 *            sortOrder: 0,
 *          },
 *          { state: "PREPARATION",
 *            sortOrder: 1,
 *          },
 *      ]
 *
 * and the key "state", it will return the following:
 *
 *      {
 *          APPROVAL: {
 *              state: "APPROVAL",
 *              sortOrder: 0,
 *          },
 *          PREPARATION: {
 *              state: "PREPARATION",
 *              sortOrder: 1,
 *          },
 *      }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeDictFromList(list: Dictionary<any>[], key: string): Dictionary<any> {
    const dictionary = list.reduce((curDictionary, listItem) => {
        const item = listItem[key];
        if (typeof item === 'string' || typeof item === 'number') {
            return {
                ...curDictionary,
                [(item as unknown) as string | number]: listItem,
            };
        } else {
            return curDictionary;
        }
    }, {});
    return dictionary;
}

// Generates a unique list of items.
export function uniquifyArray(array: (string | number | undefined)[]): (string | number)[] {
    const dict: Dictionary<boolean> = {};
    array.reduce((curIdsDict, curItem) => {
        if (curItem !== undefined) {
            dict[curItem] = true;
        }
        return curIdsDict;
    }, {} as Dictionary<boolean>);
    return Object.keys(dict);
}

/**
 * Create a distinct list of elements based on the input.
 * @param elements The list of input elements to be made distinct.
 * @returns a distinct list of the input elements
 */
export function distinct(elements: any[]): any[] {
    return Array.from(new Set(elements));
}

/**
 * Creates an array of chunks of the original array at a given size.
 * @param array The list of input elements to chunk.
 * @param chunkSize The size of the chunks.
 * @returns an array where all but the last entry are of size chunkSize
 */
export function chunk<T>(array: T[], chunkSize: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
}

/**
 * Debounce a function by the specified wait time.
 * @param func The function to be debounced.
 * @param timeout The time to wait.
 * @returns a promise of generic type T, where T is the return type of the func parameter
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce<T>(func: Function, timeout = 500): () => Promise<T> {
    let timer: NodeJS.Timeout;
    return async (...args: any[]): Promise<T> => {
        clearTimeout(timer);
        return new Promise((resolve, reject) => {
            timer = setTimeout(() => {
                try {
                    const result = func(...args);
                    resolve(result);
                } catch (e) {
                    reject(e);
                }
            }, timeout);
        });
    };
}

/**
 * Debounce an async function by the specified wait time.
 * @param func The async function to be debounced.
 * @param timeout The time to wait.
 * @returns a promise of generic type T, where T is the return type of the func parameter
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function debounceAsync<T>(func: Function, timeout = 500): () => Promise<T> {
    let timer: NodeJS.Timeout;
    return async (...args: any[]): Promise<T> => {
        clearTimeout(timer);
        return new Promise(async (resolve, reject) => {
            timer = setTimeout(async () => {
                try {
                    const result = await func(...args);
                    resolve(result);
                } catch (e) {
                    reject(e);
                }
            }, timeout);
        });
    };
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const doNothing = (): void => {};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const doNothingAsync = async (): Promise<void> => {};

export type EnumEntryType = {
    key: string;
    value: string | number;
};

export const enumEntries = (obj: Dictionary<string | number>): EnumEntryType[] => {
    return Object.entries(obj).map((entry) => ({ key: entry[0], value: entry[1] }));
};
