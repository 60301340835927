import { IconNames } from 'assets/constants/global-constants';
import GroupClient, {
    GroupRuleType,
    IGroupMembership,
    IGroupRule,
    IUarEvaluation,
} from 'clients/group-client';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import BoldFont from 'components/common/misc/bold-font';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import { AuthContext } from 'contexts/auth-context';
import config from 'environments/environment';
import React, { useContext, useEffect, useState } from 'react';
import { useFetchSimple } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';

const featureFlags = config.groupServiceConfig.featureFlags;

interface IMemberDetailsModalActionButtonProps {
    memberDetails: IGroupMembership;
    principalId: string;
    policies: IUarEvaluation;
    groupId: string;
    onDeleteMemberFromTable: (principalId: string, ruleId: string) => void;
}

function ManageGroupMemberDeleteModalActionButton(props: IMemberDetailsModalActionButtonProps) {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);
    const [failedPolicy, setFailedPolicy] = useState<IUarEvaluation>();
    const [groupRules, setGroupRules] = useState<IGroupRule[]>();
    const [durationDays, setDurationDays] = useState<number>();
    const [fetchrulesError, setFetchrulesError] = useState<string>('');

    useFetchSimple<IGroupRule[]>({
        dependencies: [groupContext],
        canPerformFetch: true,
        fetchFunc: async () => GroupClient.listGroupRules(authContext, props.groupId),
        onSuccess: (result) => {
            setGroupRules(result);
        },

        onError: () => {
            setFetchrulesError('Error loading user access reviews');
        },
        onFinally: () => doNothing(),
    });

    const onHandleExtendAllowUnsigned = async (): Promise<void> => {
        const ruleId = props.policies.ruleId;

        if (!ruleId) {
            throw 'No UAR found';
        }

        try {
            if (groupContext.group) {
                await GroupClient.extendAgreement(
                    authContext,
                    groupContext.group?.id,
                    props.principalId,
                    ruleId,
                );
            }
        } catch (e) {
            console.error('Error extending member confirmation', e);
            throw 'Error approving member. Member must sign the UAR Contract Text for initial approval.';
        }
    };

    const onHandleExtendLegacy = async (): Promise<void> => {
        try {
            if (groupContext.group && failedPolicy) {
                await GroupClient.extendAgreement(
                    authContext,
                    groupContext.group?.id,
                    props.principalId,
                    failedPolicy?.ruleId,
                );
            }
        } catch (e) {
            console.error('Error extending member confirmation', e);
            throw 'Error approving member. Member must sign the UAR Contract Text for initial approval.';
        }
    };

    const onHandleExtend = featureFlags.allowApproveUnsigned
        ? onHandleExtendAllowUnsigned
        : onHandleExtendLegacy;

    const onModalConcluded = (modalConclusion: ModalConclusion): void => {
        if (modalConclusion === ModalConclusion.Done) {
            props.onDeleteMemberFromTable(props.principalId, props.policies.ruleId);
        }
    };

    useEffect(() => {
        if (groupRules) {
            const uarRule: IGroupRule | undefined = groupRules.find(
                (x) => x.type === GroupRuleType.USER_ACCESS_REVIEW_CHECK_RULE,
            );
            const durationDays = uarRule ? uarRule.durationDays : 0;
            setDurationDays(Number(durationDays));
        }
    }, [groupRules]);

    const onActionButtonClick = () => {
        const failedPolicyVar = props.policies;
        setFailedPolicy(failedPolicyVar);
    };

    if (fetchrulesError !== '') return <div>{fetchrulesError}</div>;

    return (
        <ModalActionButton<void>
            text={'Approve'}
            iconName={IconNames.Edit}
            modalTitle={'Extend Member Confirmation'}
            enableSubmit={true}
            submitButtonText={'Confirm'}
            onSubmit={onHandleExtend}
            onButtonClick={onActionButtonClick}
            onModalConcluded={onModalConcluded}>
            Please confirm you want to approve{' '}
            <BoldFont>
                {props.memberDetails.firstName} {props.memberDetails.lastName}
            </BoldFont>{' '}
            for another {durationDays} days.
        </ModalActionButton>
    );
}

export default ManageGroupMemberDeleteModalActionButton;
