import React, { useContext, useMemo, useState } from 'react';
import {
    ActionButton,
    DetailsListLayoutMode,
    IColumn,
    Icon,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
} from '@fluentui/react';
import { IScreening } from 'components/screening/us-gov/IScreening';
import { DEFAULT_SHIMMER_LINE_COUNT, TableCell } from 'components/common/table';
import { IBasicEmployee } from 'clients/employee-client';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import EmployeeBasicHoverCardSimple from 'components/common/employee/employee-basic-hover-card-simple';
import { detailsListStyles } from 'assets/styles/list-styles';
import { getScreeningStatusLabel } from 'components/screening/common/common-ui';
import {
    GetUSGovStateName,
    ScreeningRequestTypesLabels,
    getStatusDisplayByStateName,
    getSubStatusName,
} from 'components/screening/common/common-constants';
import { dateToFormattedDateTimeString } from 'utils/time-utils';
import { tableColumnStyles } from 'components/screening/common/common-tab-styling';
import { NavLink } from 'react-router-dom';
import { IconNames, largeMaxWidthCoeff } from 'assets/constants/global-constants';
import { IContract } from 'components/screening/us-gov/IContract';
import DetailCustomerMetricModal from 'components/screening/us-gov/candidates-listing/tabpanel-contents/detail-customer-metric-modal';
import { AuthContext } from 'contexts/auth-context';
import {
    ICommonScreening,
    convertIScreeningToCommonScreening,
} from 'components/screening/common/ICommonScreening';
import { ScreeningPageNames } from 'components/common/constants';
import ExpandableColumnsContainer from 'components/common/expandable-columns-container';
import { numCmp, strCmp, useSortColumnHandler } from 'utils/sort-utils';
import { getDisplayNameOrDefault } from 'components/common/employee/employee-utils';

interface IScreeningRecordsProps {
    isColumnsExpanded: boolean;
    isLoadingScreeningRecords: boolean;
    contractsMap: Map<string, IContract>;
    basicEmployeesMap: Map<string, IBasicEmployee>;
    employeesPictureMap: Map<string, string>;
    errorGettingRecords: string;
    screeningRecords: IScreening[];
}

export default function ScreeningRecords(props: IScreeningRecordsProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [isMetricsDetailsModalOpen, setIsMetricsDetailsModalOpen] = useState<boolean>(false);
    const [currentCommonScreeningRecord, setCurrentCommonScreeningRecord] = useState<
        ICommonScreening
    >();
    const [{ sortColumn, sortAscending: sortDirection }, sortColumnHandler] = useSortColumnHandler(
        '',
    ); // Default: no sorting
    const isSortedDescending = useMemo(() => sortDirection === -1, [sortDirection]);

    const columns: IColumn[] = [
        {
            name: columnNames.candidate,
            key: columnNames.candidate,
            minWidth: columnWidths.candidate,
            maxWidth: columnWidths.candidate * largeMaxWidthCoeff,
            isResizable: true,
            isSorted: sortColumn === columnNames.candidate,
            isSortedDescending,
            onColumnClick: (): void => {
                sortColumnHandler(columnNames.candidate);
            },
            onRender: (row: IScreening): JSX.Element => {
                if (row.preHire) {
                    return (
                        <TableCell>
                            <EmployeeBasicHoverCard
                                key={`candidate-${row.personnelId}`}
                                personnelId={row.personnelId}
                                showFullName={true}
                                showMiniCardAlias={true}
                            />
                        </TableCell>
                    );
                }
                return (
                    <TableCell>
                        <EmployeeBasicHoverCardSimple
                            key={`candidate-${row.personnelId}`}
                            employeeBasicData={props.basicEmployeesMap.get(row.personnelId)}
                            image={props.employeesPictureMap.get(row.personnelId) ?? ''}
                            showFullName={true}
                            showMiniCardAlias={true}
                        />
                    </TableCell>
                );
            },
        },
        {
            name: columnNames.clearance,
            key: columnNames.clearance,
            minWidth: columnWidths.clearance,
            maxWidth: columnWidths.clearance * largeMaxWidthCoeff,
            isResizable: true,
            isSorted: sortColumn === columnNames.clearance,
            isSortedDescending,
            onColumnClick: (): void => {
                sortColumnHandler(columnNames.clearance);
            },
            onRender: (row: IScreening): JSX.Element => {
                return <TableCell>{row.requestDetails.clearance}</TableCell>;
            },
        },
        {
            name: columnNames.requestType,
            key: columnNames.requestType,
            minWidth: columnWidths.requestType,
            maxWidth: columnWidths.requestType * largeMaxWidthCoeff,
            isMultiline: true,
            isResizable: true,
            isSorted: sortColumn === columnNames.requestType,
            isSortedDescending,
            onColumnClick: (): void => {
                sortColumnHandler(columnNames.requestType);
            },
            onRender: (row: IScreening): JSX.Element => {
                if (row.requestDetails.requestType) {
                    const requestTypeStr = row.requestDetails.requestType.replace(/ /g, '');
                    const requestType =
                        ScreeningRequestTypesLabels[
                            requestTypeStr as keyof typeof ScreeningRequestTypesLabels
                        ];
                    return <TableCell toolTipText={requestType}>{requestType}</TableCell>;
                }
                return <></>;
            },
        },
        {
            name: columnNames.contractId,
            key: columnNames.contractId,
            minWidth: columnWidths.contractId,
            maxWidth: columnWidths.contractId * largeMaxWidthCoeff,
            isResizable: true,
            isMultiline: true,
            isSorted: sortColumn === columnNames.contractId,
            isSortedDescending,
            onColumnClick: (): void => {
                sortColumnHandler(columnNames.contractId);
            },
            onRender: (row: IScreening): JSX.Element => {
                return (
                    <TableCell toolTipText={row.governmentContract}>
                        {row.governmentContract}
                    </TableCell>
                );
            },
        },
        {
            name: columnNames.projectName,
            key: columnNames.projectName,
            minWidth: columnWidths.projectName,
            maxWidth: columnWidths.projectName * largeMaxWidthCoeff,
            isResizable: true,
            isMultiline: true,
            isSorted: sortColumn === columnNames.projectName,
            isSortedDescending,
            onColumnClick: (): void => {
                sortColumnHandler(columnNames.projectName);
            },
            onRender: (row: IScreening): JSX.Element => {
                // TODO
                // Task 17855120: Populate the column "Project Name"
                // https://dev.azure.com/msazure/Microsoft%20Personnel/_sprints/taskboard/Screening/Microsoft%20Personnel/Gallium/CY23Q2/2Wk/2Wk01%20(Mar%2026%20-%20Apr%2008)?workitem=17855120
                const project = props.contractsMap?.get(row.governmentContract)?.project;
                if (project) {
                    return (
                        <TableCell toolTipText={project}>
                            <>{project}</>
                        </TableCell>
                    );
                }
                return <></>;
            },
        },
        {
            name: columnNames.status,
            key: columnNames.status,
            minWidth: columnWidths.status,
            maxWidth: columnWidths.status * largeMaxWidthCoeff,
            isResizable: true,
            isSorted: sortColumn === columnNames.status,
            isSortedDescending,
            onColumnClick: (): void => {
                sortColumnHandler(columnNames.status);
            },
            onRender: (row: IScreening): JSX.Element | string => {
                if (row.state) {
                    return (
                        <TableCell>
                            <div className={detailsListStyles.dataCellContainer}>
                                {getScreeningStatusLabel(row)}
                            </div>
                        </TableCell>
                    );
                } else {
                    return <></>;
                }
            },
        },
        {
            name: columnNames.subStatus,
            key: columnNames.subStatus,
            minWidth: columnWidths.subStatus,
            maxWidth: columnWidths.subStatus * largeMaxWidthCoeff,
            isResizable: true,
            isMultiline: true,
            isSorted: sortColumn === columnNames.subStatus,
            isSortedDescending,
            onColumnClick: (): void => {
                sortColumnHandler(columnNames.subStatus);
            },
            onRender: (row: IScreening): JSX.Element | string => {
                if (row.state) {
                    return (
                        <TableCell>
                            <div className={detailsListStyles.dataCellContainer}>
                                {getSubStatusName(row)}
                            </div>
                        </TableCell>
                    );
                } else {
                    return <></>;
                }
            },
        },
        {
            name: columnNames.processOwner,
            key: columnNames.processOwner,
            minWidth: columnWidths.processOwner,
            maxWidth: columnWidths.processOwner * largeMaxWidthCoeff,
            isResizable: true,
            isSorted: sortColumn === columnNames.processOwner,
            isSortedDescending,
            onColumnClick: (): void => {
                sortColumnHandler(columnNames.processOwner);
            },
            onRender: (row: IScreening): JSX.Element => {
                const employee = props.basicEmployeesMap.get(row.processOwnerId);
                if (employee) {
                    return (
                        <TableCell>
                            <EmployeeBasicHoverCardSimple
                                key={`processOwner-${row.processOwnerId}`}
                                employeeBasicData={employee}
                                image={props.employeesPictureMap.get(row.processOwnerId) ?? ''}
                                showFullName={true}
                                showMiniCardAlias={true}
                            />
                        </TableCell>
                    );
                }
                return <></>;
            },
        },
        {
            name: columnNames.statusChangedOn,
            key: columnNames.statusChangedOn,
            minWidth: columnWidths.statusChangedOn,
            maxWidth: columnWidths.statusChangedOn * largeMaxWidthCoeff,
            isResizable: true,
            isMultiline: true,
            isSorted: sortColumn === columnNames.statusChangedOn,
            isSortedDescending,
            onColumnClick: (): void => {
                sortColumnHandler(columnNames.statusChangedOn);
            },
            onRender: (row: IScreening): JSX.Element => {
                if (row.statusChanged?.atUtc) {
                    const timeString = dateToFormattedDateTimeString(
                        row.statusChanged?.atUtc * 1000,
                    );
                    return <TableCell toolTipText={timeString}>{timeString}</TableCell>;
                }
                return <></>;
            },
        },
        {
            name: columnNames.actions,
            key: columnNames.actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions * largeMaxWidthCoeff,
            isResizable: true,
            onRender: (row: IScreening, index?: number): JSX.Element => {
                return (
                    <TableCell>
                        <Stack horizontal className={tableColumnStyles.actionRow}>
                            <div className={tableColumnStyles.navLink}>
                                <NavLink
                                    key={`preview-link-${row.id ?? index}`}
                                    className={tableColumnStyles.viewScreeningLink}
                                    to={`/screening/us-gov/${row.id}`}>
                                    <Icon
                                        iconName='PreviewLink'
                                        className={tableColumnStyles.viewScreeningIcon}
                                    />
                                    &nbsp; Details
                                </NavLink>
                            </div>
                            <div className={tableColumnStyles.metrics}>
                                <ActionButton
                                    label={'Metrics'}
                                    text={'Metrics'}
                                    iconProps={{
                                        iconName: IconNames.BarChartVerticalFill,
                                    }}
                                    onClick={() => {
                                        setIsMetricsDetailsModalOpen(true);
                                        setCurrentCommonScreeningRecord(
                                            convertIScreeningToCommonScreening(row),
                                        );
                                    }}
                                />
                            </div>
                        </Stack>
                    </TableCell>
                );
            },
        },
    ];

    const sortedRecords = useMemo(() => {
        const sortRecords = (recordsParam: IScreening[]): IScreening[] => {
            type A = IScreening;

            const chooseSortCmp = (
                sortColumnParam: string,
                sortDirectionParam: number,
            ): ((r1: IScreening, r2: IScreening) => number) => {
                switch (sortColumnParam) {
                    case columnNames.candidate:
                        return (r1: A, r2: A): number =>
                            sortDirectionParam *
                            strCmp(
                                getDisplayNameOrDefault(
                                    props.basicEmployeesMap.get(r1.personnelId ?? ''),
                                ),
                                getDisplayNameOrDefault(
                                    props.basicEmployeesMap.get(r2.personnelId ?? ''),
                                ),
                            );
                    case columnNames.clearance:
                        return (r1: A, r2: A): number =>
                            sortDirectionParam *
                            strCmp(r1.requestDetails.clearance, r2.requestDetails.clearance);
                    case columnNames.requestType:
                        return (r1: A, r2: A): number => {
                            const r1Str = r1.requestDetails.requestType.replace(/ /g, '');
                            const r2Str = r2.requestDetails.requestType.replace(/ /g, '');
                            return sortDirectionParam * strCmp(r1Str, r2Str);
                        };
                    case columnNames.contractId:
                        return (r1: A, r2: A): number => {
                            const r1Str = r1.governmentContract
                                .toLocaleLowerCase()
                                .replace(/ /g, '');
                            const r2Str = r2.governmentContract
                                .toLocaleLowerCase()
                                .replace(/ /g, '');
                            return sortDirectionParam * strCmp(r1Str, r2Str);
                        };
                    case columnNames.projectName:
                        return (r1: A, r2: A): number => {
                            const r1Str =
                                props.contractsMap
                                    ?.get(r1.governmentContract)
                                    ?.project.toLocaleLowerCase()
                                    .replace(/ /g, '') ?? '';
                            const r2Str =
                                props.contractsMap
                                    ?.get(r2.governmentContract)
                                    ?.project.toLocaleLowerCase()
                                    .replace(/ /g, '') ?? '';
                            return sortDirectionParam * strCmp(r1Str, r2Str);
                        };
                    case columnNames.status:
                        return (r1: A, r2: A): number => {
                            const r1Str = getStatusDisplayByStateName(
                                GetUSGovStateName(r1.parentState, r1.state),
                            );
                            const r2Str = getStatusDisplayByStateName(
                                GetUSGovStateName(r2.parentState, r2.state),
                            );
                            return sortDirectionParam * strCmp(r1Str, r2Str);
                        };
                    case columnNames.subStatus:
                        return (r1: A, r2: A): number => {
                            const r1Str = getSubStatusName(r1);
                            const r2Str = getSubStatusName(r2);
                            return sortDirectionParam * strCmp(r1Str, r2Str);
                        };
                    case columnNames.processOwner:
                        return (r1: A, r2: A): number =>
                            sortDirectionParam *
                            strCmp(
                                getDisplayNameOrDefault(
                                    props.basicEmployeesMap.get(r1.processOwnerId ?? ''),
                                ),
                                getDisplayNameOrDefault(
                                    props.basicEmployeesMap.get(r2.processOwnerId ?? ''),
                                ),
                            );
                    case columnNames.statusChangedOn:
                        return (r1: A, r2: A): number =>
                            sortDirectionParam *
                            numCmp(r1.statusChanged?.atUtc, r2.statusChanged?.atUtc);
                    default:
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        return (r1: A, r2: A): number => 0;
                }
            };
            const sortCmp = chooseSortCmp(sortColumn, sortDirection);
            return [...recordsParam].sort(sortCmp);
        };
        return sortRecords(props.screeningRecords);
    }, [props.screeningRecords, sortColumn, sortDirection]);

    return (
        <>
            <DetailCustomerMetricModal
                isDetailWindowOpen={isMetricsDetailsModalOpen}
                closeModal={() => setIsMetricsDetailsModalOpen(false)}
                authContext={authContext}
                screening={currentCommonScreeningRecord}
                pageName={ScreeningPageNames.Manage}
            />
            <ExpandableColumnsContainer isColumnsExpanded={props.isColumnsExpanded}>
                <ShimmeredDetailsList
                    className={detailsListStyles.theList}
                    ariaLabelForShimmer={'Loading screening records'}
                    columns={columns}
                    enableShimmer={
                        props.isLoadingScreeningRecords && props.screeningRecords.length === 0
                    }
                    items={sortedRecords}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                    shimmerLines={DEFAULT_SHIMMER_LINE_COUNT}
                    ariaLabelForListHeader='Column headers'
                />
            </ExpandableColumnsContainer>
        </>
    );
}

const columnWidths = {
    candidate: 200,
    clearance: 65,
    requestType: 100,
    contractId: 100,
    projectName: 100,
    status: 100,
    subStatus: 100,
    processOwner: 100,
    statusChangedOn: 100,
    actions: 140,
};

const columnNames = {
    candidate: 'Candidate',
    clearance: 'Clearance',
    requestType: 'Request Type',
    contractId: 'Contract ID',
    projectName: 'Project Name',
    status: 'Status',
    subStatus: 'Sub-Status',
    processOwner: 'Process Owner',
    statusChangedOn: 'Status Changed On',
    actions: 'Actions',
};
