import { mergeStyles } from '@fluentui/react';
import { focusedLightFaintBlue, lightFaintBlue } from 'assets/constants/global-colors';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MenuAuthorization } from 'components/common/page-shell/menu-authorization';
import { IPrincipalUserContext, PrincipalUserContext } from 'contexts/principal-user-context';
import config from 'environments/environment';

const footerTextColorStyles = mergeStyles({
    color: '#605E5C', // Gray130
});

const footerSectionTitle = mergeStyles(footerTextColorStyles, {
    fontWeight: '600',
    marginBottom: '0.5rem',
    width: '266px',
    flexShrink: 1,
});

const bottomFooterStyle = mergeStyles(footerTextColorStyles, {
    display: 'flex',
    margin: '0 96px',
    justifyContent: 'flex-end',
    gap: '10px',
    height: '60px',
    alignItems: 'center',
    letterSpacing: '.01rem',
    fontWeight: '600',
});

const topFooterStyle = mergeStyles({
    display: 'flex',
    background: '#FAFAFA',
    padding: '3.25rem 96px 3.25rem 96px',
    flexWrap: 'wrap',
});

const footerLinkFocusIndicatorStyles = {
    color: '#11100f', // Gray220
    textDecoration: 'underline',
};

const footerLinkStyles = mergeStyles(footerTextColorStyles, {
    textDecoration: 'none',
    selectors: {
        ':hover': footerLinkFocusIndicatorStyles,
        ':active': footerLinkFocusIndicatorStyles,
        ':focus': footerLinkFocusIndicatorStyles,
    },
});

const footerVisuallyALinkFocusIndicatorStyles = {
    color: focusedLightFaintBlue,
};

const footerVisuallyALinkStyles = mergeStyles({
    color: lightFaintBlue, // need this to override Link element color
    selectors: {
        ':hover': footerVisuallyALinkFocusIndicatorStyles,
        ':active': footerVisuallyALinkFocusIndicatorStyles,
        ':focus': footerVisuallyALinkFocusIndicatorStyles,
    },
});

const subFooterItemStyles = mergeStyles({
    fontWeight: '400',
    fontSize: '12px',
});

type FooterItem = {
    text: string;
    link?: string;
    isVisible?: boolean;
    showAsLink?: boolean;
    subFooterItems?: FooterItem[];
};

const miniFooterPages = ['/support'];

export function Footer(): JSX.Element {
    const authContext = useContext(AuthContext);
    const principalUserContext = useContext(PrincipalUserContext) as
        | IPrincipalUserContext
        | undefined;
    const currentYear = new Date().getFullYear();
    const isKioskMode = authContext.isKioskLandingRenderMode() || authContext.isKioskRenderMode();
    const menuAuth = MenuAuthorization();
    const location = useLocation();

    const BottomFooter = () => {
        return (
            <div style={{ background: '#F3F3F3' }}>
                <div className={bottomFooterStyle}>
                    <span>© Microsoft {currentYear}</span>
                    {!isKioskMode && (
                        <>
                            |
                            <Link
                                className={footerLinkStyles}
                                to={{ pathname: config.privacyLinkUrl }}
                                target='_blank'>
                                {config.privacyLinkText}
                            </Link>
                        </>
                    )}
                </div>
            </div>
        );
    };

    const footerItems: FooterItem[] = useMemo(() => {
        return GetFooterItems(menuAuth, principalUserContext);
    }, [menuAuth, principalUserContext]);

    const CreateSubFooterItem = (item: FooterItem, subLevel: number) => {
        if (item.isVisible === false) {
            return <></>;
        }

        const padding = { paddingLeft: `${subLevel * 1.25}rem` };
        return (
            <div
                style={padding}
                className={subFooterItemStyles}
                key={`${item.text}-${subLevel}-container`}>
                {item.showAsLink ? (
                    <Link
                        to={{ pathname: item.link }}
                        className={footerVisuallyALinkStyles}
                        target='_blank'>
                        {item.text}
                    </Link>
                ) : (
                    <Link
                        to={item.link}
                        className={footerLinkStyles}
                        onClick={() => window.scrollTo(0, 0)}>
                        {item.text}
                    </Link>
                )}
            </div>
        );
    };

    if (isKioskMode || miniFooterPages.includes(location.pathname)) {
        return <BottomFooter />;
    }

    return (
        <>
            <div className={topFooterStyle}>
                {footerItems
                    .filter((item) => item.isVisible !== false)
                    .map((item) => (
                        <div key={item.text} className={footerSectionTitle}>
                            <h4 style={{ marginBottom: '0px' }}>{item.text}</h4>
                            {item.subFooterItems
                                ?.filter((subItem) => subItem.isVisible !== false)
                                .map((subItem) => CreateSubFooterItem(subItem, 0))}
                        </div>
                    ))}
            </div>
            <BottomFooter />
        </>
    );
}

const GetFooterItems = (
    menuAuth: { [k: string]: boolean },
    principalUserContext?: IPrincipalUserContext,
): FooterItem[] => {
    return [
        {
            text: 'Profile',
            isVisible: menuAuth.showProfile,
            subFooterItems: [
                {
                    text: 'My information',
                    isVisible: false,
                    link: '/profile/user/me/info',
                },
                {
                    text: 'Attributes',
                    link: '/profile/user/me/attributes',
                },
                {
                    text: 'Eligibilities',
                    isVisible: menuAuth.showEligibilities,
                    link: '/profile/user/me/eligibilities',
                },
            ],
        },
        {
            text: 'Attributes',
            isVisible: menuAuth.showAttributes,
            subFooterItems: [
                {
                    text: 'My attributes',
                    link: `/profile/attributes/user/${principalUserContext?.principalRecord?.id}`,
                },
                {
                    text: 'User assignments',
                    link: '/profile/attributes/user/search',
                },
                {
                    text: 'Attribute sets',
                    link: '/profile/attributesets',
                },
            ],
        },
        {
            text: 'Screening',
            isVisible: menuAuth.showScreening,
            subFooterItems: [
                {
                    text: 'Cloud',
                    link: '/screening/cloud/my-screenings',
                },
                {
                    text: 'US Government',
                    link: '/screening/us-gov/my-screenings',
                },
                {
                    text: 'Public Trust',
                    link: '/screening/public-trust/my-screenings',
                },
            ],
        },
        {
            text: 'Facilities',
            isVisible: menuAuth.showFacilitiesReservations,
            subFooterItems: [
                {
                    text: 'New reservations',
                    link: '/facilities/reservations/new',
                },
                {
                    text: 'My reservations',
                    link: '/facilities/reservations/my',
                },
            ],
        },
        {
            text: 'Groups',
            isVisible: menuAuth.showGroups,
            subFooterItems: [
                {
                    text: 'My groups',
                    link: '/groups',
                },
            ],
        },
        {
            text: 'Forms',
            isVisible: menuAuth.showForms,
            subFooterItems: [
                {
                    text: 'My forms',
                    link: '/forms/my-forms',
                },
                {
                    text: 'Available forms',
                    link: '/forms/available-forms',
                },
            ],
        },
        {
            text: 'SCA',
            isVisible: menuAuth.showSCA && menuAuth.showSCAMyOrg,
            subFooterItems: [
                {
                    text: 'My org',
                    link: '/sca/my-org',
                },
            ],
        },
        {
            text: 'Support',
            subFooterItems: [
                {
                    text: 'Resources',
                    link: '/support',
                },
                ...(menuAuth.shouldUseSupportLinkToAka
                    ? [
                          {
                              text: 'aka.ms/personnel/support',
                              link: 'https://aka.ms/personnel/support',
                              showAsLink: true,
                          },
                      ]
                    : []),
            ],
        },
    ];
};
