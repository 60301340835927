/**
 * @param {Blob} blob
 * @returns {Promise<string>}
 */
export function convertPhotoToBase64(blob: Blob): Promise<string> {
    const reader = new FileReader();

    reader.readAsDataURL(blob);
    return new Promise<string>((resolve, reject) => {
        reader.onloadend = (): void => {
            if (typeof reader.result === 'string') {
                resolve(reader.result);
            } else {
                reject('File reader result was not a string.');
            }
        };
    });
}

/**
 * @deprecated use graph-client.ts
 * @see graph-client.ts
 * Get images from who, which is now OBE, graph-client.ts and it's functions have been
 * built to support obtaining image data from necessary endpoints.
 */
export function getImageUrlFromAlias(alias: string | undefined): string {
    if (!alias) return '';
    return `https://who/photos/${alias.toUpperCase()}.jpg`;
}
