import config from 'environments/environment';
import { GetAadHttpOptions, JSON_CONTENT_TYPE, TEXT_PLAIN_TYPE } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import { Form, FormResponse, Section } from 'components/forms/forms-common';
const { formsServiceConfig } = config;

export const formRespondBaseUrl = '/forms/respond';
const getGenericFormsEndpoint = 'v1/forms/GenericForm';
const getGenericFormsEndpointById = 'v1/forms/GenericForm/{id}';
const toggleDeleteGenericFormsById = 'v1/forms/GenericForm/toggleDelete/{id}';
const getGenericFormPermissions = `${getGenericFormsEndpoint}/permissions`;
const genericFormsRecordsEndpoint = 'v1/forms/GenericFormRecord';
const getGenericFormRecordsEndpoint = `${genericFormsRecordsEndpoint}/query`;
const getQueryableGenericFormRecordTypes = `${genericFormsRecordsEndpoint}/availableFormTypes`;
const downloadFormsExcelReport = `${genericFormsRecordsEndpoint}/FormRecordExcelData`;
const getGenericFormRecordsByPersonnelIdEndpoint = `${genericFormsRecordsEndpoint}/personnel/{id}`;
const getGenericFormRecordEndpointById = `${genericFormsRecordsEndpoint}/{id}`;
const getMyGenericFormRecords = `${genericFormsRecordsEndpoint}/personnel/my`;
const cloneGenericFormRecords = `${genericFormsRecordsEndpoint}/clone/{id}`;
const getGenericFormRecordHistory = `${genericFormsRecordsEndpoint}/history/my/{id}`;

export type FormsError = {
    ClassName: string;
    Message: string;
    StackTraceString: string;
    Source: string;
};

export type GenericForm = {
    id: string;
    name: string;
    title: string;
    subtitle: string;
    description: string;
    image: string;
    schema: string;
    owners: string[];
    notificationList: string[];
    accessControlAttributes: string[][];
    accessControlEligibilities: string[][];
    accessControlEmployeeProperties: string[];
    isPublished: boolean;
    isSoftDeleted: boolean;
    lastModifiedAtUTC: number;
    createdAtUTC: number;
    createdBy: string;
    version: string;
    isLocked: boolean;
};

export type GenericFormRecordQueryResults = {
    results: GenericFormRecord[];
    continuationToken: string;
};

export type GenericFormRecord = {
    id: string;
    name: string;
    title: string;
    description: string;
    schema: string;
    lastModifiedAtUTC: number;
    lastModifiedBy: string;
    createdAtUTC: number;
    createdBy: string;
    submittedAtUTC: number;
    formState: string;
    label?: string;
    personnelId: string;
    clonedFromId: string;
    isMigrationRecord: boolean;
    requestUpdate: boolean;
    reviewStatusChangeAtUTC: number;
    reviewStatusChangedBy: string;
    version: string;
    comment: string;
    rowNumber?: number;
};

export type ExportFormReportQueryResults = {
    results: ExportFormReport[];
    continuationToken: string;
};

export type ExportFormReport = {
    id: string;
    formType: string;
    formName: string;
    label: string;
    reviewStatusChangedBy: string;
    reviewStatusChangeAtUTC: number;
    submittedBy: string;
    submittedOn: number;
    status: string;
    statusChangedBy: string;
    statusChangedOn: number;
    // form Record Creator info
    personnelId: string;
    lastName: string;
    firstName: string;
    middleName?: string;
    email: string;
    employeeTitle: string;
    organization: string;
    geographicLocation: string;
    officeLocation: string;
    reportTo: string;
    hierarchyLevel1: string;
    hierarchyLevel2: string;
    hierarchyLevel3: string;
    hierarchyLevel4: string;
    hierarchyLevel5: string;
    hierarchyLevel6: string;
};

export type GenericFormRecordQueryRequest = {
    id?: string;
    title?: string;
    formStates?: string[];
    submittedFrom?: string;
    submittedTo?: string;
    createdBy?: string;
    lastModifiedBy?: string;
    genericForms?: genericFormsQuery[];
    noSchema?: boolean;
};

export type genericFormsQuery = {
    name: string;
    version: string;
};

export type GenericFormRecordHistoryItem = {
    id: string;
    genericFormRecordId: string;
    eventTimeStampUtc: number;
    eventType: string;
    genericFormRecord: GenericFormRecord;
    personnelId: string;
};

export const convertFormRecordToForm = (
    formRecord: GenericFormRecord,
    formName: string,
): FormResponse => {
    let schemaData: Section[] = [];
    try {
        schemaData = JSON.parse(formRecord.schema);
    } catch {
        console.log('Malformed schema payload -- unable to parse record data');
    }

    const convertedForm: FormResponse = {
        id: formRecord.id,
        name: formName ?? '',
        title: formRecord.title,
        description: formRecord.description,
        label: formRecord.label,
        sections: schemaData,
    };
    return convertedForm;
};

export type ChangeStatusResponse = Pick<
    GenericFormRecord,
    'id' | 'formState' | 'comment' | 'requestUpdate'
>;

export default class FormsClient {
    static convertToForm(jsonForm: GenericForm): Form {
        return {
            ...jsonForm,
            sections: JSON.parse(jsonForm.schema),
        };
    }

    static convertFromForm(form: Form): string {
        return JSON.stringify({
            ...form,
            schema: JSON.stringify(form.sections),
        });
    }

    // Gets all generic form types
    static async GetAllGenericForms(authContext: IAuthContext): Promise<GenericForm[]> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getGenericFormsEndpoint;
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            console.error('error calling GetAllGenericForms');
            throw result;
        }
    }

    // Gets all available generic form types
    static async GetAvailableGenericForms(authContext: IAuthContext): Promise<GenericForm[]> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getGenericFormsEndpoint + '/available';
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            console.error('error calling GetAllGenericForms');
            throw result;
        }
    }

    // Creates a new, generic form
    static async SaveGenericForm(authContext: IAuthContext, form: Form): Promise<Form> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getGenericFormsEndpoint;
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'POST';
        httpOptions.body = FormsClient.convertFromForm(form);
        const result = await fetch(url, httpOptions);

        if (result.status === 200) {
            return FormsClient.convertToForm(await result.json());
        } else {
            console.error('error calling SaveGenericForm');
            throw result;
        }
    }

    // Gets a specific generic form given an id or name
    static async GetGenericForm(authContext: IAuthContext, id: string): Promise<Form> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getGenericFormsEndpointById.replace('{id}', id);
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return FormsClient.convertToForm(await result.json());
        } else {
            console.error('error calling GetGenericForm');
            throw result;
        }
    }

    static async GetGenericFormPermissions(authContext: IAuthContext): Promise<FormPermissions> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getGenericFormPermissions;
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            console.error('error calling GetGenericFormPermissions');
            throw result;
        }
    }

    // Deletes a specific generic form given an id
    static async ToggleSoftDeleteGenericForm(
        authContext: IAuthContext,
        id: string,
        shouldDelete: boolean,
    ): Promise<boolean> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + toggleDeleteGenericFormsById.replace('{id}', id);
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'PATCH';
        httpOptions.body = JSON.stringify({ delete: shouldDelete });
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            console.error('error calling ToggleSoftDeleteGenericForm');
            throw result;
        }
    }

    // Updates a generic form
    static async UpdateGenericForm(authContext: IAuthContext, form: Form): Promise<boolean> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getGenericFormsEndpoint;
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'PUT';
        httpOptions.body = FormsClient.convertFromForm(form);
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return true;
        } else {
            console.error('error calling UpdateGenericForm');
            throw result;
        }
    }

    // Gets all generic form records
    static async GetAllGenericFormRecords(
        authContext: IAuthContext,
    ): Promise<GenericFormRecordQueryResults> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getGenericFormRecordsEndpoint;
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify({});
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return await result.json();
        } else {
            console.error('error calling GetAllGenericForms');
            throw result;
        }
    }

    // Gets form types available for querying
    static async GetQueryableGenericFormRecordTypes(authContext: IAuthContext): Promise<any> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getQueryableGenericFormRecordTypes;
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return await result.json();
        } else {
            console.error('error calling GetQueryableGenericFormRecordTypes');
            throw result;
        }
    }

    // Gets all generic form records related to a specific formState.
    static async QueryGenericFormRecords(
        authContext: IAuthContext,
        genericFormRecordQueryRequest: GenericFormRecordQueryRequest,
        continuationToken?: string,
        signal?: AbortSignal,
    ): Promise<GenericFormRecordQueryResults> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getGenericFormRecordsEndpoint;
        const httpOptions = {
            body: JSON.stringify(genericFormRecordQueryRequest),
            method: 'POST',
            ...(await GetAadHttpOptions(
                authContext,
                formsServiceConfig.aadScopes,
                JSON_CONTENT_TYPE,
                continuationToken,
            )),
            signal,
        };
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return await result.json();
        } else {
            console.error('error calling GetAllGenericForms');
            throw result;
        }
    }

    // Gets all generic form records related to a specific formState.
    static async ExportFormReportToExcel(
        authContext: IAuthContext,
        genericFormRecordQueryRequest: GenericFormRecordQueryRequest,
    ): Promise<ExportFormReport[]> {
        // we will separate the time range query to 31 day chunks
        try {
            if (
                genericFormRecordQueryRequest.submittedFrom !== undefined &&
                genericFormRecordQueryRequest.submittedTo !== undefined
            ) {
                const start = new Date(genericFormRecordQueryRequest.submittedFrom);
                const end = new Date(genericFormRecordQueryRequest.submittedTo);
                const intervals: string[] = splitDateIntoEqualIntervals(start, end, 31);
                const promiseArr: Promise<ExportFormReport[]>[] = [];
                for (let i = 0; i < intervals.length - 1; i++) {
                    const intervalQuery: GenericFormRecordQueryRequest = {
                        ...genericFormRecordQueryRequest,
                        submittedFrom: intervals[i],
                        submittedTo: intervals[i + 1],
                    };
                    promiseArr.push(this._ExportFormReportToExcelQuery(authContext, intervalQuery));
                }
                const excelRecords = await Promise.all(promiseArr);
                let allExcelRecords: ExportFormReport[] = [];
                // to get the most recent submitted by date you need to start at the bottom of the array
                for (let i = excelRecords.length - 1; i >= 0; i--) {
                    const formRecordExcelBlock = excelRecords[i];
                    allExcelRecords = allExcelRecords.concat(formRecordExcelBlock);
                }
                return allExcelRecords;
            }
        } catch (e) {
            throw { message: e.message, status: e.status };
        }
        return [];
    }

    // Gets all generic form records related to a specific formState.
    // you should break it up to monthly separated queries beforehand
    private static async _ExportFormReportToExcelQuery(
        authContext: IAuthContext,
        genericFormRecordQueryRequest: GenericFormRecordQueryRequest,
    ): Promise<ExportFormReport[]> {
        let excel: ExportFormReport[] = [];
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + downloadFormsExcelReport;
        let continuationToken = '';
        do {
            const httpOptions = await buildFormExcelQuery(
                authContext,
                genericFormRecordQueryRequest,
                continuationToken,
            );
            const result = await fetch(url, httpOptions);
            const batch = await result.json();
            switch (result.status) {
                case 200:
                    if (batch.results) {
                        excel = excel.concat(batch.results as ExportFormReport);
                    }
                    if (batch.continuationToken) {
                        continuationToken = batch.continuationToken;
                    } else {
                        continuationToken = '';
                    }
                    break;
                case 400:
                    const error: FormsError = batch;
                    throw { message: error.Message, status: result.status };
                default:
                    console.error('error calling GetAllGenericForms');
                    throw result;
            }
        } while (continuationToken !== undefined && continuationToken !== '');
        return excel;
    }

    // Create a generic form record
    static async CreateGenericFormRecord(
        authContext: IAuthContext,
        body: object,
    ): Promise<GenericFormRecord> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + genericFormsRecordsEndpoint;
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(body);
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return await result.json();
        } else {
            console.error('error calling CreateGenericFormRecord');
            throw result;
        }
    }

    // Update generic form record
    static async UpdateGenericFormRecord(
        authContext: IAuthContext,
        body: object,
    ): Promise<GenericFormRecord> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + genericFormsRecordsEndpoint;
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(body);
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return await result.json();
        } else {
            console.error('error calling UpdateGenericFormRecord');
            throw result;
        }
    }

    // Gets all generic form records by personnel id
    static async GetGenericFormRecordsByPersonnelId(
        authContext: IAuthContext,
        id: string,
    ): Promise<GenericFormRecord[]> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getGenericFormRecordsByPersonnelIdEndpoint.replace('{id}', id);
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return await result.json();
        } else {
            console.error('error calling GetGenericFormRecordsByPersonnelId');
            throw result;
        }
    }

    // Gets all generic form records by personnel id
    static async GetGenericFormRecordById(
        authContext: IAuthContext,
        id: string,
    ): Promise<GenericFormRecord> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getGenericFormRecordEndpointById.replace('{id}', id);
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return await result.json();
        } else {
            console.error('error calling GetGenericFormRecordById');
            throw result;
        }
    }

    // Gets my generic form records by personnel id
    static async GetMyGenericFormRecords(authContext: IAuthContext): Promise<GenericFormRecord[]> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getMyGenericFormRecords;
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return await result.json();
        } else {
            console.error('error calling GetGenericFormRecordsByPersonnelId');
            throw result;
        }
    }

    // Deletes a specific generic form record given an id
    static async DeleteGenericFormRecordById(
        authContext: IAuthContext,
        id: string,
    ): Promise<boolean> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getGenericFormRecordEndpointById.replace('{id}', id);
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'DELETE';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            console.error('error calling DeleteGenericFormRecord');
            throw result;
        }
    }

    // Gets history
    static async GetMyGenericFormRecordHistory(
        authContext: IAuthContext,
        id: string,
    ): Promise<GenericFormRecordHistoryItem[]> {
        const { baseUrl } = config.formsServiceConfig;
        const url = baseUrl + getGenericFormRecordHistory.replace('{id}', id);
        const httpOptions = await GetAadHttpOptions(authContext, formsServiceConfig.aadScopes);
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            console.error('error calling DeleteGenericFormRecord');
            throw result;
        }
    }

    static async CloneGenericFormRecord(
        authContext: IAuthContext,
        genericFormRecordId: string,
    ): Promise<string> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            formsServiceConfig.aadScopes,
            TEXT_PLAIN_TYPE,
        );
        httpOptions.method = 'POST';
        const url =
            formsServiceConfig.baseUrl +
            cloneGenericFormRecords.replace('{id}', genericFormRecordId);
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return await result.text();
            case 400:
                console.error(`cannot clone another user's Record`);
                throw result;
            case 404:
                console.error(`Unable to find Generic Form record with ${genericFormRecordId}`);
                throw result;
            default:
                console.error(`error calling CloneGenericFormRecord with ${genericFormRecordId}`);
                throw result;
        }
    }
}

const splitDateIntoEqualIntervals = (
    startDate: Date,
    endDate: Date,
    intervialLength: number,
): string[] => {
    const intervals: string[] = [startDate.toISOString()];
    const nextIntervial = new Date(startDate);
    nextIntervial.setDate(nextIntervial.getDate() + intervialLength);
    while (nextIntervial.getTime() < endDate.getTime()) {
        intervals.push(nextIntervial.toISOString());
        nextIntervial.setDate(nextIntervial.getDate() + intervialLength);
    }
    intervals.push(endDate.toISOString());
    return intervals;
};

export interface IFormRecordResult {
    results: IFormRecord[];
    continuationToken: string;
}

export interface IFormRecord {
    clonedFromId: string;
    comment: string;
    createdAtUTC: Date;
    createdBy: string;
    formCode: FormCode;
    formState: FormState;
    id: string;
    label: string;
    lastModifiedAtUTC: Date;
    lastModifiedBy: string;
    personnelId: string;
    requestUpdate: boolean;
    reviewStatusChangeAtUTC: Date;
    reviewStatusChangedBy: string;
    submittedAtUTC: number;
    version: number;
}

export type FormPermissions = {
    hasTemplateAccess: boolean;
    isFormOwner: boolean;
};

export interface IItineraryDetail {
    date: Date;
    isDayTrip: boolean;
    locationName: string;
    locationAddress: string;
    natureOfTravel: NatureOfTravel;
    travelDetail: string;
    businessContext: string;
}
export interface ITravelLodging {
    lodgingName: string;
    lodgingPhone: string;
    lodgingAddress: string;
    checkInDate: Date;
    checkOutDate: Date;
}

export interface ITravelRental {
    rentalDescription: string;
    rentalCompany: string;
    reservationNumber: string;
    pickUpDate: Date;
    pickUpLocation: string;
    returnDate: Date;
    returnLocation: string;
}

export interface IForeignContact {
    fullName: string;
    citizenship: string;
    relationship: string;
    meetingAddress: string;
}

export interface IForeignGovernmentOfficial {
    fullName: string;
    meetingAddress: string;
}

export interface IForeignGovernmentFacility {
    facilityName: string;
    facilityAddress: string;
}
export interface ITravelCompanion {
    fullName: string;
    citizenship: string;
    relationship: string;
}

export enum FormCode {
    visitRequest = 'VISIT_REQUEST',
    foreignTravel = 'FOREIGN_TRAVEL',
    personalStatusChange = 'PERSONAL_STATUS_CHANGE',
    clearanceJustification = 'CLEARANCE_JUSTIFICATION',
    clearedServicesPMO = 'CLEARED_SERVICES_PMO',
    driReadiness = 'DRI_READINESS',
    foreignContact = 'FOREIGN_CONTACT',
    citizenshipVerification = 'CITIZENSHIP_VERIFICATION',
    itemRequest = 'ITEM_REQUEST',
}

export enum FormState {
    draft = 'DRAFT',
    submitted = 'SUBMITTED',
    underReview = 'UNDER_REVIEW',
    accepted = 'ACCEPTED',
    completed = 'COMPLETED',
    rejected = 'REJECTED',
}

export enum TransportationMode {
    air = 'AIR',
    boat = 'BOAT',
    rail = 'RAIL',
    road = 'ROAD',
}

export enum NatureOfTravel {
    businessOrProfessionalConference = 'BUSINESS_OR_PROFESSIONAL_CONFERENCE',
    education = 'EDUCATION',
    personal = 'PERSONAL',
    tourism = 'TOURISM',
    tradeShowConferenceOrSeminar = 'TRADE_SHOW_CONFERENCE_OR_SEMINAR',
    visitFriendsOrFamily = 'VISIT_FRIENDS_OR_FAMILY',
    other = 'OTHER',
}
const buildFormExcelQuery = async (
    authContext: IAuthContext,
    genericFormRecordQueryRequest: GenericFormRecordQueryRequest,
    continuationToken: string,
): Promise<RequestInit> => {
    const httpOptions = await GetAadHttpOptions(
        authContext,
        formsServiceConfig.aadScopes,
        JSON_CONTENT_TYPE,
        continuationToken,
    );
    httpOptions.method = 'POST';
    httpOptions.body = JSON.stringify(genericFormRecordQueryRequest);
    return httpOptions;
};
