import { ActionButton, mergeStyleSets, MessageBarType, Stack } from '@fluentui/react';
import { BadgeColorHex, faintBlue } from 'assets/constants/global-colors';
import { IconNames, noDataText } from 'assets/constants/global-constants';
import GroupClient, {
    IGroupMembership,
    IGroupPolicyViolationRule,
    IUpdateGroupMembershipRequest,
} from 'clients/group-client';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { ChartLegendDot } from 'components/common/charts/chart-legend';
import ContentCancelSubmit from 'components/common/content-cancel-submit';
import LabelAndEditButton from 'components/common/label-and-edit-button';
import Spacer from 'components/common/spacer';
import { useCorePrincipalIdPicker } from 'components/common/use-input/use-core-employee-picker';
import { useTextField } from 'components/common/use-input/use-textfield';
import useMessageBar from 'components/common/use-message-bar';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import ManageGroupMemberMoreInfoModalActionButton from 'components/groups/manage-group/members/buttons/manage-group-member-more-info-modal-action-button';
import { Role } from 'configs/roles';
import { AuthContext } from 'contexts/auth-context';
import React, { ReactNode, useContext, useState } from 'react';
import { SetStateFunc } from 'types/global-types';
import { doNothingAsync } from 'utils/misc-utils';
import { TimeFormats, timeToString } from 'utils/time-utils';

interface IMemberDetailsModalActionButtonProps {
    memberDetails: IGroupMembership;
    onUpdateMemberDetails?: (personnelId: string) => void;
}

enum ViolationDetailNames {
    Violations = 'Violations',
    GracePeriod = 'Grace Period',
    Compliant = 'Compliant',
}

function ManageGroupMemberDetailsModalActionButton(
    props: IMemberDetailsModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);

    const [failedPolicyViolations, setFailedPolicyViolations] = useState<
        IGroupPolicyViolationRule[]
    >();
    const [inGracePeriodPolicyViolations, setInGracePeriodPolicyViolations] = useState<
        IGroupPolicyViolationRule[]
    >();
    const [passedPolicyViolations, setPassedPolicyViolations] = useState<
        IGroupPolicyViolationRule[]
    >();

    const [isEditingSponsor, setIsEditingSponsor] = useState<boolean>(false);
    const [isEditingJustification, setIsEditingJustification] = useState<boolean>(false);
    const [isEditingNotes, setIsEditingNotes] = useState<boolean>(false);

    const canEdit =
        groupContext.isAdmin() ||
        groupContext.isManager() ||
        groupContext.isOwner() ||
        authContext.isInRole(Role.GroupAdmin);

    const canViewNotes = canEdit || groupContext.isAuditor();

    const {
        value: sponsor,
        isValid: isSponsorValid,
        hasChanged: hasSponsorChanged,
        initialize: initSponsorElement,
        theElement: pickSponsorElement,
    } = useCorePrincipalIdPicker({
        placeHolder: 'Employee name or alias',
        required: groupContext.group?.requireSponsor,
        disabled: !canEdit,
        blockSelfSponsor: false,
        validate: (): boolean => {
            return groupContext.group?.requireSponsor ? !!sponsor : true;
        },
        onError: () => {
            setUpdateErrMsg('Sponsor is required');
        },
    });

    const {
        value: justification,
        isValid: isJustificationValid,
        hasChanged: hasJustificationChanged,
        theElement: justificationElement,
        initialize: initJustificationElement,
    } = useTextField({
        multiline: true,
        disabled: !canEdit,
        resizable: false,
        rows: 5,
        validate: () => {
            return !!groupContext.group?.requireJustification ? !!justification : true;
        },
    });

    const {
        value: notes,
        hasChanged: hasNotesChanged,
        initialize: initNotesElement,
        theElement: notesElement,
    } = useTextField({
        label: 'Optional notes not visible to members',
        disabled: !canEdit,
        placeholder: 'Add notes',
        ariaLabel: 'Optional notes not visible to members',
        multiline: true,
        rows: 5,
    });

    const {
        theElement: updateErrMsgElement,
        setMessage: setUpdateErrMsg,
        clearMessage: clearUpdateErrMsg,
    } = useMessageBar({ type: MessageBarType.error });

    function resetSponsor(): void {
        setIsEditingSponsor(false);
        initSponsorElement(props.memberDetails.sponsorId ?? undefined);
    }

    function resetJustification(): void {
        setIsEditingJustification(false);
        initJustificationElement(props.memberDetails.justification);
    }

    function resetNotes(): void {
        setIsEditingNotes(false);
        initNotesElement(props.memberDetails.notes);
    }

    const fetchPolicyViolation = async (): Promise<void> => {
        resetSponsor();
        resetJustification();
        resetNotes();
        clearUpdateErrMsg();
        try {
            const policyViolation = await GroupClient.createPolicyViolation(
                authContext,
                props.memberDetails.groupId,
                props.memberDetails.personnelId,
            );
            setFailedPolicyViolations(policyViolation.failed);
            setInGracePeriodPolicyViolations(
                policyViolation.passed.filter((r) => r.isInGracePeriod),
            );
            setPassedPolicyViolations(policyViolation.passed.filter((r) => !r.isInGracePeriod));
            if (props.onUpdateMemberDetails) {
                props.onUpdateMemberDetails(policyViolation.personnelId);
            }
        } catch (e) {
            console.log('Failed to retrieve policy violation');
        }
    };

    const updateMembershipRecord = async (
        membershipRecord: IUpdateGroupMembershipRequest,
        fieldName: string,
        setIsEditingVariable: SetStateFunc<boolean>,
    ): Promise<void> => {
        clearUpdateErrMsg();
        try {
            await GroupClient.updateGroupMember(authContext, membershipRecord);
            // Without the following call to fetchPolicyViolation, if the user
            // immediately after updating a field tried to edit the same or
            // another field, they will get a 409 (conflict) unless user reloads
            // the page. The following helps avoid that.
            await fetchPolicyViolation();
            setIsEditingVariable(false);
        } catch (e) {
            let errMsg = '';
            if (typeof e === 'string') {
                errMsg = e;
            } else {
                errMsg = `Error updating ${fieldName}`;
            }
            setUpdateErrMsg(errMsg);
        }
    };

    const dateAdded = (): string => {
        return timeToString(
            props.memberDetails.addedTimestampUTC * 1000,
            TimeFormats.MMMDYYYY_hmmssA,
        );
    };

    const nullOrEmptyStringCheck = (value: string | null | undefined): string => {
        return !!value ? value : noDataText;
    };

    const oneRow = (
        title: string | JSX.Element,
        content: ReactNode,
        options?: {
            verticalAlign?: 'start';
        },
    ): JSX.Element => {
        return (
            <Stack
                tokens={{ padding: 7 }}
                horizontal
                wrap
                verticalAlign={options?.verticalAlign ?? 'center'}
                className={styles.tableStack}>
                <Stack.Item className={styles.itemNameColumn} tabIndex={0}>
                    {title}
                </Stack.Item>
                <Stack.Item className={styles.itemValueColumn} tabIndex={0}>
                    {content}
                </Stack.Item>
            </Stack>
        );
    };

    const displayOneStack = (
        badgeName: string,
        violationOrPassingRules: IGroupPolicyViolationRule[],
    ): JSX.Element => {
        return (
            <Stack horizontal wrap verticalAlign='start' className={styles.tableStack}>
                <Stack.Item className={styles.itemNameColumn} tabIndex={0}>
                    {badgeName}
                </Stack.Item>
                <Stack.Item verticalFill={true} className={styles.itemValueColumn} tabIndex={0}>
                    <ul className={styles.listWrapper}>
                        {violationOrPassingRules.map((violation: IGroupPolicyViolationRule) => (
                            <li className={styles.listItem} key={violation.ruleId} tabIndex={0}>
                                <Stack horizontal wrap key={violation.ruleId}>
                                    <ChartLegendDot
                                        backgroundColor={
                                            badgeName === ViolationDetailNames.Violations
                                                ? BadgeColorHex.RED
                                                : badgeName === ViolationDetailNames.GracePeriod
                                                ? BadgeColorHex.YELLOW
                                                : BadgeColorHex.GREEN
                                        }
                                    />
                                    <div className={styles.listItem} key={violation.ruleId}>
                                        {learnMore(badgeName, violation)}
                                    </div>
                                </Stack>
                            </li>
                        ))}
                    </ul>
                </Stack.Item>
            </Stack>
        );
    };

    const learnMore = (
        violationType: string,
        content: IGroupPolicyViolationRule | undefined,
    ): JSX.Element => {
        return (
            <Stack horizontal verticalAlign='center'>
                <Stack.Item className={styles.learnMoreTitle}>{content?.ruleName}</Stack.Item>
                {content?.remediationSteps && violationType !== ViolationDetailNames.Compliant && (
                    <Stack.Item>
                        <ManageGroupMemberMoreInfoModalActionButton
                            policyViolationRule={content}
                            buttonStyle={styles.learnMoreButton}
                        />
                    </Stack.Item>
                )}
                {content?.remediationSteps && violationType === ViolationDetailNames.Compliant && (
                    <ActionButton
                        text='View'
                        className={styles.learnMoreButton}
                        href={content?.remediationSteps}
                        target='_blank'
                    />
                )}
            </Stack>
        );
    };

    const onModalConcluded = (): void => {
        setFailedPolicyViolations([]);
        setInGracePeriodPolicyViolations([]);
        setPassedPolicyViolations([]);
    };

    return (
        <ModalActionButton<void>
            enableSubmit={true}
            shouldHideCancelButton={true}
            text={'Details'}
            iconName={IconNames.View}
            modalTitle={'Member Details'}
            submitButtonText={'Close'}
            onSubmit={doNothingAsync}
            onButtonClick={fetchPolicyViolation}
            onModalConcluded={onModalConcluded}>
            {oneRow('Group', groupContext.group?.name)}
            {oneRow(
                'Employee',
                <CoreEmployeeHoverCardFromPrincipalId
                    principalId={props.memberDetails.personnelId}
                />,
            )}
            {!!failedPolicyViolations &&
                failedPolicyViolations.length > 0 &&
                displayOneStack(ViolationDetailNames.Violations, failedPolicyViolations)}

            {!!inGracePeriodPolicyViolations &&
                inGracePeriodPolicyViolations.length > 0 &&
                displayOneStack(ViolationDetailNames.GracePeriod, inGracePeriodPolicyViolations)}

            {!!passedPolicyViolations &&
                passedPolicyViolations.length > 0 &&
                displayOneStack(ViolationDetailNames.Compliant, passedPolicyViolations)}

            {oneRow(
                'Added By',
                <CoreEmployeeHoverCardFromPrincipalId principalId={props.memberDetails.addedBy} />,
            )}
            {oneRow('Added Date', dateAdded())}
            {updateErrMsgElement()}
            {oneRow(
                canEdit ? (
                    <LabelAndEditButton
                        label='Sponsor'
                        required={groupContext?.group?.requireSponsor}
                        canEdit={canEdit}
                        hideEditButton={isEditingSponsor}
                        onClickEdit={(): void => setIsEditingSponsor(true)}
                    />
                ) : (
                    'Sponsor'
                ),
                isEditingSponsor ? (
                    <ContentCancelSubmit
                        onCancelClick={resetSponsor}
                        submitText='Update'
                        enableSubmit={!!isSponsorValid && !!hasSponsorChanged}
                        onSubmitClick={(): void => {
                            updateMembershipRecord(
                                Object.assign({}, props.memberDetails, {
                                    sponsorId: sponsor,
                                }),
                                'sponsor',
                                setIsEditingSponsor,
                            );
                        }}>
                        <Spacer marginTop={15} />
                        {pickSponsorElement()}
                    </ContentCancelSubmit>
                ) : !!props.memberDetails.sponsorId ? (
                    <CoreEmployeeHoverCardFromPrincipalId
                        principalId={props.memberDetails.sponsorId}
                    />
                ) : (
                    <span>N/A</span>
                ),
                isEditingSponsor ? { verticalAlign: 'start' } : undefined,
            )}
            {oneRow(
                canEdit ? (
                    <LabelAndEditButton
                        label='Justification'
                        required={groupContext?.group?.requireJustification}
                        canEdit={canEdit}
                        hideEditButton={isEditingJustification}
                        onClickEdit={() => setIsEditingJustification(true)}
                    />
                ) : (
                    'Justification'
                ),
                isEditingJustification ? (
                    <ContentCancelSubmit
                        onCancelClick={resetJustification}
                        submitText='Update'
                        enableSubmit={!!isJustificationValid && !!hasJustificationChanged}
                        onSubmitClick={() => {
                            updateMembershipRecord(
                                Object.assign({}, props.memberDetails, { justification }),
                                'justification',
                                setIsEditingJustification,
                            );
                        }}>
                        <Spacer marginTop={15} />
                        {justificationElement()}
                    </ContentCancelSubmit>
                ) : (
                    nullOrEmptyStringCheck(justification)
                ),
                isEditingJustification ? { verticalAlign: 'start' } : undefined,
            )}
            {canViewNotes &&
                oneRow(
                    canEdit ? (
                        <LabelAndEditButton
                            label='Notes'
                            canEdit={canEdit}
                            hideEditButton={isEditingNotes}
                            onClickEdit={() => setIsEditingNotes(true)}
                        />
                    ) : (
                        'Notes'
                    ),
                    isEditingNotes ? (
                        <ContentCancelSubmit
                            onCancelClick={resetNotes}
                            submitText='Update'
                            enableSubmit={!!hasNotesChanged}
                            onSubmitClick={() => {
                                updateMembershipRecord(
                                    Object.assign({}, props.memberDetails, { notes }),
                                    'notes',
                                    setIsEditingNotes,
                                );
                            }}>
                            {notesElement()}
                        </ContentCancelSubmit>
                    ) : (
                        nullOrEmptyStringCheck(notes)
                    ),
                    isEditingNotes ? { verticalAlign: 'start' } : undefined,
                )}
        </ModalActionButton>
    );
}

export default ManageGroupMemberDetailsModalActionButton;

const styles = mergeStyleSets({
    tableStack: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        lineHeight: 35,
        marginBottom: 20,
    },
    itemNameColumn: {
        width: 200,
        flexShrink: 0,
        alignItems: 'flex-start',
    },
    listWrapper: {
        margin: 0,
        padding: 0,
    },
    listItem: {
        listStyle: 'none',
    },
    itemValueColumn: {
        width: 400,
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
        marginBottom: 0,
    },
    learnMoreTitle: {
        paddingRight: 5,
    },
    learnMoreButton: {
        color: faintBlue,
        '& :first-letter': {
            textTransform: 'capitalize',
        },
        '& :hover': {
            textDecoration: 'underline',
        },
    },
});
