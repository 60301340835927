import useMessageBar from 'components/common/use-message-bar';
import { FiltersClearanceRecords } from 'contexts/filters-clearance-records';
import { filterContextKeys, FiltersContext, IFiltersContext } from 'contexts/filters-context';
import {
    ComboBox,
    IComboBox,
    IComboBoxOption,
    IDropdownOption,
    MessageBarType,
} from '@fluentui/react';
import React from 'react';
import { useContext, useState, useEffect } from 'react';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { dropdownComboBox } from 'components/screening/common/filters/common-filter-styling';
import { SuitabilityLevels } from 'components/personnel-profile/suitability/profile-suitability-types';
export interface SuitabilityLevelFilterProps {
    rawCandidates: ICommonScreening[];
    filterContext: IFiltersContext;
}
export function SuitabilityLevelFilter(props: SuitabilityLevelFilterProps): JSX.Element {
    const {
        theMessage: message,
        theElement: messageElement,
        setMessage: setMessage,
    } = useMessageBar({
        type: MessageBarType.error,
    });
    const filtersClearanceRecordsContext = useContext(FiltersClearanceRecords);
    const filtersCandidateContext = useContext(FiltersContext);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const suitabilityLevelOptions = getSuitabilityLevelOptions(props.rawCandidates) || [];
    useEffect(() => {
        if (filtersCandidateContext?.filterCleared || filtersClearanceRecordsContext?.filterCleared)
            setSelectedKeys([]);
    }, [filtersCandidateContext?.filterCleared, filtersClearanceRecordsContext?.filterCleared]);

    function getSuitabilityLevelOptions(rawCandidates: ICommonScreening[]): IDropdownOption[] {
        const validLevel = new Set(
            rawCandidates
                .filter((record): boolean => (record?.suitabilityLevel ? true : false))
                .map((x) => x.suitabilityLevel || ''),
        );
        return getOptionsFromValidLevels(validLevel);
    }

    function getOptionsFromValidLevels(validLevel: Set<string>): IDropdownOption[] {
        let options: IDropdownOption[] = [];
        validLevel.forEach((level) => {
            if (level) {
                const levelFullText = SuitabilityLevels[level as keyof typeof SuitabilityLevels];
                options.push({ key: level, text: levelFullText });
            }
        });
        options = options.sort((a, b) => {
            const aUpper = a.text.toUpperCase();
            const bUpper = b.text.toUpperCase();
            if (aUpper < bUpper) return -1;
            if (aUpper > bUpper) return 1;
            return 0;
        });
        return options;
    }

    return (
        <>
            <>
                <ComboBox
                    ariaLabel='Suitability Levels'
                    multiSelect
                    selectedKey={selectedKeys}
                    placeholder={'Suitability Level'}
                    autoComplete='on'
                    options={suitabilityLevelOptions}
                    onChange={onSuitabilityLevelSelect}
                    useComboBoxAsMenuWidth
                    allowFreeform
                    styles={dropdownComboBox}
                />
                {!!message && messageElement()}
            </>
        </>
    );

    function onSuitabilityLevelSelect(
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
    ): void {
        if (option && option.key) {
            const suitabilityLevel = option.key;
            try {
                const isAdding = !!option.selected;
                let newlySelected: string[] = [];
                setSelectedKeys((prevSelectedKeys) => {
                    newlySelected = isAdding
                        ? [...prevSelectedKeys, suitabilityLevel as string]
                        : prevSelectedKeys.filter((k) => k !== suitabilityLevel);
                    return newlySelected;
                });
                const newFilterFunctions: {
                    func: (arg: ICommonScreening) => boolean;
                    undefined: undefined;
                }[] = [];
                if (newlySelected.length > 0) {
                    const func = (screening: ICommonScreening): boolean => {
                        if (screening.suitabilityLevel) {
                            return newlySelected.includes(screening.suitabilityLevel);
                        }
                        return false;
                    };
                    newFilterFunctions.push({ func, undefined });
                }
                props.filterContext.setFilterFunctions(
                    filterContextKeys.suitabilityLevel,
                    newFilterFunctions,
                );
            } catch (error) {
                setMessage(
                    `An error occurred while loading the selected Suitability Level information.`,
                );
            }
        }
    }
}
export default SuitabilityLevelFilter;
