import {
    Checkbox,
    DatePicker,
    IDropdownOption,
    mergeStyleSets,
    Separator,
    Stack,
    ActionButton,
} from '@fluentui/react';
import React, { FormEvent } from 'react';
import {
    IFacilityRecord,
    IFacilityTimeslotItem,
    ReservationState,
} from 'clients/facilities-client';
import SecureWorkAreaDropdownSelector from 'components/facilities/common/secure-work-area-dropdown-selector';
import { IFacilityReservationTableColumn } from 'components/facilities/facilities-reservations/facilities-user-reservation-table-view/facilities-user-reservation-columns';
import { globalCheckboxStyles } from 'assets/styles/global-styles';
import { IFilterCriteria } from 'components/facilities/facilities-reservations/facilities-user-reservation-table-view/facilities-user-reservation-table-view';
import { IconNames } from 'assets/constants/global-constants';
export interface FacilitiesUserReservationFilterProps {
    facilities: IFacilityRecord[];
    unfilteredReservations?: IFacilityReservationTableColumn[];
    displayedReservations: IFacilityReservationTableColumn[];
    setDisplayedReservations: (fr: IFacilityReservationTableColumn[]) => void;
    filterCriteria: IFilterCriteria;
    setFilterCriteria: (s: IFilterCriteria) => void;
}

interface IStatusOption {
    value: string;
    text: string;
}

export default function FacilitiesUserReservationFilter(
    props: FacilitiesUserReservationFilterProps,
): JSX.Element {
    const statusOptions: IStatusOption[] = [
        {
            value: ReservationState.Preclaimed,
            text: 'Preclaimed',
        },
        {
            value: ReservationState.Confirmed,
            text: 'Confirmed',
        },
        {
            value: ReservationState.CheckedIn,
            text: 'Checked In',
        },
    ];

    const DateRangeTypes = {
        Start: 'start',
        End: 'end',
    };

    function facilityChange(
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption | undefined,
        index?: number | undefined,
    ): Promise<void> {
        if (option) {
            const newFilterCriteria = {
                ...props.filterCriteria,
                selectedFacility: { facilityId: option.key as string, facilityName: option.text },
            };
            props.setFilterCriteria(newFilterCriteria);
            applyFilterCriteria(newFilterCriteria);
        }
        return Promise.resolve();
    }

    function hasStatus(status: string): boolean {
        return props.filterCriteria.statuses?.includes(status) ?? false;
    }

    function clearFilters(): void {
        props.setFilterCriteria({ statuses: [] });
        if (props.unfilteredReservations) {
            props.setDisplayedReservations(props.unfilteredReservations);
        }
    }

    function applyFilterCriteria(filtersToApply: IFilterCriteria): void {
        if (props.unfilteredReservations) {
            let filtered = [...props.unfilteredReservations];
            if (filtersToApply && filtersToApply.startDate && filtersToApply.endDate) {
                const start = filtersToApply.startDate;
                const end = filtersToApply.endDate;
                filtered = filtered.filter((item) =>
                    isWithinRange(start, end, item.reservationRecord.reservationTimeSlot),
                );
            } else if (filtersToApply.startDate) {
                const start = filtersToApply.startDate;
                filtered = filtered.filter((item) =>
                    isAfterOrEqual(start, item.reservationRecord.reservationTimeSlot),
                );
            } else if (filtersToApply.endDate) {
                const end = filtersToApply.endDate;
                filtered = filtered.filter((item) =>
                    isBeforeOrEqual(end, item.reservationRecord.reservationTimeSlot),
                );
            }

            if (filtersToApply.statuses.length > 0) {
                filtered = filtered.filter((item) =>
                    filtersToApply.statuses.includes(item.reservationRecord.state),
                );
            }

            if (filtersToApply.selectedFacility) {
                filtered = filtered.filter(
                    (item) =>
                        item.facilityRecord?.facilityId ===
                        filtersToApply.selectedFacility?.facilityId,
                );
            }
            props.setDisplayedReservations(filtered);
        }
    }

    function checkStatus(status: string): void {
        const newFilterCriteria = { ...props.filterCriteria };
        newFilterCriteria.statuses.push(status);
        props.setFilterCriteria(newFilterCriteria);
        applyFilterCriteria(newFilterCriteria);
    }

    function isCriteriaEmpty(criteria: IFilterCriteria): boolean {
        return (
            criteria.statuses.length === 0 &&
            criteria.startDate === undefined &&
            criteria.endDate === undefined &&
            criteria.selectedFacility === undefined
        );
    }

    function uncheckStatus(status: string): void {
        const withRemovedStatus = props.filterCriteria.statuses.filter((x) => x !== status);
        const newFilterCriteria = { ...props.filterCriteria, statuses: withRemovedStatus };
        props.setFilterCriteria(newFilterCriteria);
        if (isCriteriaEmpty(newFilterCriteria) && props.unfilteredReservations) {
            props.setDisplayedReservations(props.unfilteredReservations);
        } else {
            applyFilterCriteria(newFilterCriteria);
        }
    }

    function isWithinRange(
        start: Date,
        end: Date,
        reservationTimeslot: IFacilityTimeslotItem,
    ): boolean {
        return (
            isAfterOrEqual(start, reservationTimeslot) && isBeforeOrEqual(end, reservationTimeslot)
        );
    }

    function dateOnly(dateAndTimeInMS: number): Date {
        const asDate = new Date(dateAndTimeInMS);
        return new Date(asDate.getFullYear(), asDate.getMonth(), asDate.getDate());
    }

    function isBeforeOrEqual(date: Date, reservationTimeslot: IFacilityTimeslotItem): boolean {
        return (
            dateOnly(reservationTimeslot.startDateTimeUTCMilliseconds) <= dateOnly(date.getTime())
        );
    }

    function isAfterOrEqual(date: Date, reservationTimeslot: IFacilityTimeslotItem): boolean {
        return (
            dateOnly(reservationTimeslot.startDateTimeUTCMilliseconds) >= dateOnly(date.getTime())
        );
    }

    function changeDate(rangeType: string, date: Date | null | undefined): void {
        if (date && rangeType === DateRangeTypes.End) {
            props.setFilterCriteria({ ...props.filterCriteria, endDate: date });
            applyFilterCriteria({ ...props.filterCriteria, endDate: date });
        }
        if (date && rangeType === DateRangeTypes.Start) {
            props.setFilterCriteria({ ...props.filterCriteria, startDate: date });
            applyFilterCriteria({ ...props.filterCriteria, startDate: date });
        }
    }

    function updateFilter(value: string): void {
        hasStatus(value) ? uncheckStatus(value) : checkStatus(value);
    }

    return (
        <Stack>
            <Stack.Item>
                <SecureWorkAreaDropdownSelector
                    onChange={facilityChange}
                    placeholder='Select a facility'
                    selectedKey={props.filterCriteria.selectedFacility?.facilityId ?? ''}
                    options={props.facilities?.map((x) => {
                        return {
                            key: x.id,
                            text: x.facilityName,
                        };
                    })}
                />
            </Stack.Item>
            <Stack.Item>
                <Separator styles={separatorStyles} alignContent='start'>
                    Start Date
                </Separator>
            </Stack.Item>
            <Stack.Item>
                <DatePicker
                    ariaLabel='Start Date'
                    placeholder='Start date'
                    allowTextInput={true}
                    maxDate={props.filterCriteria.endDate}
                    value={props.filterCriteria.startDate}
                    onSelectDate={(date: Date | null | undefined): void =>
                        changeDate(DateRangeTypes.Start, date)
                    }
                />
            </Stack.Item>
            <Stack.Item>
                <Separator styles={separatorStyles} alignContent='start'>
                    End Date
                </Separator>
            </Stack.Item>
            <Stack.Item>
                <DatePicker
                    ariaLabel='End Date'
                    placeholder='End date'
                    allowTextInput={true}
                    minDate={props.filterCriteria.startDate}
                    value={props.filterCriteria.endDate ?? undefined}
                    onSelectDate={(date: Date | null | undefined): void =>
                        changeDate(DateRangeTypes.End, date)
                    }
                />
            </Stack.Item>
            <Stack.Item>
                <Separator styles={separatorStyles} alignContent='start'>
                    Status
                </Separator>
            </Stack.Item>
            {statusOptions.map(
                (status): JSX.Element => (
                    <Stack.Item key={status.value}>
                        <Checkbox
                            label={status.text}
                            onChange={(): void => {
                                updateFilter(status.value);
                            }}
                            checked={hasStatus(status.value)}
                            styles={globalCheckboxStyles}
                        />
                    </Stack.Item>
                ),
            )}
            <Stack.Item style={{ padding: 5 }}>
                <ActionButton
                    iconProps={{ iconName: IconNames.ClearFilter }}
                    onClick={clearFilters}
                    disabled={isCriteriaEmpty(props.filterCriteria)}>
                    <span>Clear filters</span>
                </ActionButton>
            </Stack.Item>
        </Stack>
    );
}

const separatorStyles = mergeStyleSets({
    root: {
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                opacity: 0.9,
            },
        },
    },
    content: {
        paddingLeft: 0,
        fontWeight: '500',
    },
});
