import React, { useEffect, useState } from 'react';
import { mergeStyleSets, FontWeights, Separator, TextField, DatePicker } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import ContainerWithEtiquettes, {
    IContainerWithEtiquettesProps,
} from 'components/common/container-with-etiquettes';
import { setNestedKeyValueOfCloneObject } from 'utils/object-utils';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { IEmployeeEditableResponse } from 'clients/employee-client';
import config from 'environments/environment';
import { ICommonScreening } from 'components/screening/common/ICommonScreening';
import { ScreeningPaths } from 'components/screening/common/common-constants';

export interface PreHireDataProps {
    screening: ICommonScreening;
    isDataEditable: boolean;
    managerName: string;
    editableEmployee?: IEmployeeEditableResponse;
    onEditableFieldChange(screen: ICommonScreening): void;
    onEditableEmployeeFieldChange(editableEmployee: IEmployeeEditableResponse): void;
    screeningPath: ScreeningPaths;
}

export function PrehireData(props: PreHireDataProps): JSX.Element {
    const [screening, setScreening] = useState<ICommonScreening>(props.screening);
    const [editableEmployee, setEditableEmployee] = useState<
        IEmployeeEditableResponse | undefined
    >();
    const employeePrehirePCNFieldName = 'positionNumber';
    const employeePrehireManagerFieldName = 'reportsToEmailName';
    const employeePrehireJobTitleFieldName = 'standardTitle';
    const screeningPrehirePCNFieldName = 'pcn';
    const screeningPrehireManagerFieldName = 'manager';
    const screeningPrehireJobTitleFieldName = 'jobTitle';

    useEffect(() => {
        if (props.editableEmployee) {
            setEditableEmployee(props.editableEmployee);
        }
    }, [props.editableEmployee]);

    function onTextInputChange(
        e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void {
        const target: HTMLInputElement = e.target as HTMLInputElement;
        if (typeof newValue === 'string' && target.name) {
            const fieldName = target.name;
            const updatedScreening = setNestedKeyValueOfCloneObject<ICommonScreening>(
                screening,
                newValue,
                'preHire',
                remapTargetNameFromEmployeeToScreening(fieldName),
            );

            updateEditableEmployee(newValue, fieldName);

            if (updatedScreening) {
                setScreening(updatedScreening);
                props.onEditableFieldChange(updatedScreening);
            }
        }
    }

    function remapTargetNameFromEmployeeToScreening(inputTargetName: string): string {
        switch (inputTargetName) {
            case employeePrehirePCNFieldName:
                return screeningPrehirePCNFieldName;
            case employeePrehireManagerFieldName:
                return screeningPrehireManagerFieldName;
            case employeePrehireJobTitleFieldName:
                return screeningPrehireJobTitleFieldName;
            default:
                return inputTargetName;
        }
    }

    function onDateChange(selectedDate?: Date | null): void {
        if (selectedDate) {
            const updatedScreening = setNestedKeyValueOfCloneObject<ICommonScreening>(
                screening,
                selectedDate,
                'preHire',
                'startDate',
            );

            updateEditableEmployee(selectedDate, 'startDate');

            if (updatedScreening) {
                setScreening(updatedScreening);
                props.onEditableFieldChange(updatedScreening);
            }
        }
    }

    function updateEditableEmployee(newValue: string | Date, fieldName: string): void {
        if (editableEmployee) {
            const newUpdatedScreening = setNestedKeyValueOfCloneObject<IEmployeeEditableResponse>(
                editableEmployee,
                newValue,
                'employeeEditableInfo',
                fieldName,
            );

            if (newUpdatedScreening) {
                setEditableEmployee(newUpdatedScreening);
                props.onEditableEmployeeFieldChange(newUpdatedScreening);
            }
        }
    }

    const containerProps: IContainerWithEtiquettesProps = {
        leftEtiquetteLabel: 'Prehire Data',
        rightEtiquette: {
            label: 'Prehire',
            backgroundColor: SharedColors.green10,
        },
    };

    // TODO once prehire flag is enabled permanently, remove editableEmployee check and else case completely
    if (config.personnelProfile.prehireFlag && editableEmployee) {
        return (
            <ContainerWithEtiquettes {...containerProps}>
                <div className={styles.prehireData}>
                    <div className={styles.dataGroup}>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>First Name</div>
                            <div>
                                {props.isDataEditable ? (
                                    <TextField
                                        name='firstName'
                                        value={
                                            editableEmployee?.employeeEditableInfo?.firstName ?? ''
                                        }
                                        onChange={onTextInputChange}
                                    />
                                ) : (
                                    editableEmployee?.employeeEditableInfo?.firstName ?? ''
                                )}
                            </div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Middle Name</div>
                            <div>
                                {props.isDataEditable ? (
                                    <TextField
                                        name='middleName'
                                        value={
                                            editableEmployee?.employeeEditableInfo?.middleName ?? ''
                                        }
                                        onChange={onTextInputChange}
                                    />
                                ) : (
                                    editableEmployee?.employeeEditableInfo?.middleName ?? ''
                                )}
                            </div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Last Name</div>
                            <div>
                                {props.isDataEditable ? (
                                    <TextField
                                        name='lastName'
                                        value={
                                            editableEmployee?.employeeEditableInfo?.lastName ?? ''
                                        }
                                        onChange={onTextInputChange}
                                    />
                                ) : (
                                    editableEmployee?.employeeEditableInfo?.lastName ?? ''
                                )}
                            </div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Suffix</div>
                            <div>
                                {props.isDataEditable ? (
                                    <TextField
                                        name='suffix'
                                        value={editableEmployee?.employeeEditableInfo?.suffix ?? ''}
                                        onChange={onTextInputChange}
                                    />
                                ) : (
                                    editableEmployee?.employeeEditableInfo?.suffix ?? ''
                                )}
                            </div>
                        </div>
                    </div>
                    <Separator styles={separatorStyles} />
                    <div className={styles.dataGroup}>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>PCN</div>
                            <div>
                                {props.isDataEditable ? (
                                    <TextField
                                        name={employeePrehirePCNFieldName}
                                        value={
                                            editableEmployee?.employeeEditableInfo?.positionNumber?.toString() ??
                                            ''
                                        }
                                        onChange={onTextInputChange}
                                    />
                                ) : (
                                    editableEmployee?.employeeEditableInfo?.positionNumber?.toString() ??
                                    ''
                                )}
                            </div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Manager</div>
                            <div>
                                {props.isDataEditable ? (
                                    <TextField
                                        name={employeePrehireManagerFieldName}
                                        value={
                                            editableEmployee?.employeeEditableInfo
                                                ?.reportsToEmailName ?? ''
                                        }
                                        onChange={onTextInputChange}
                                    />
                                ) : (
                                    <EmployeeHoverCard
                                        personnelId={
                                            editableEmployee?.employeeEditableInfo
                                                ?.reportsToEmailName
                                        }>
                                        {props.managerName}
                                    </EmployeeHoverCard>
                                )}
                            </div>
                        </div>
                        <div className={styles.dataLine}>
                            <div className={styles.key}>Title</div>
                            <div>
                                {props.isDataEditable ? (
                                    <TextField
                                        name={employeePrehireJobTitleFieldName}
                                        value={
                                            editableEmployee?.employeeEditableInfo?.standardTitle ??
                                            ''
                                        }
                                        onChange={onTextInputChange}
                                    />
                                ) : (
                                    editableEmployee?.employeeEditableInfo?.standardTitle ?? ''
                                )}
                            </div>
                        </div>

                        <div className={styles.dataLine}>
                            {props.isDataEditable ? (
                                <>
                                    <div className={styles.key}>Expected Start Date</div>
                                    <DatePicker
                                        ariaLabel='Expected Start Date Input'
                                        defaultValue={undefined}
                                        value={
                                            editableEmployee?.employeeEditableInfo?.startDate
                                                ? new Date(
                                                      editableEmployee?.employeeEditableInfo?.startDate,
                                                  )
                                                : undefined
                                        }
                                        onSelectDate={onDateChange}
                                    />
                                </>
                            ) : (
                                <>
                                    <div className={styles.key}>Start Date</div>
                                    <div>
                                        {editableEmployee?.employeeEditableInfo?.startDate
                                            ? new Date(
                                                  editableEmployee?.employeeEditableInfo?.startDate,
                                              ).toDateString()
                                            : ''}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </ContainerWithEtiquettes>
        );
    } else {
        return (
            <ContainerWithEtiquettes {...containerProps}>
                {props.screeningPath === ScreeningPaths.UsGov && (
                    <div className={styles.prehireData}>
                        <div className={styles.dataGroup}>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>First Name</div>

                                <div>
                                    {props.isDataEditable ? (
                                        <TextField
                                            name='firstName'
                                            value={screening.preHire?.firstName ?? ''}
                                            onChange={onTextInputChange}
                                        />
                                    ) : (
                                        screening.preHire?.firstName ?? ''
                                    )}
                                </div>
                            </div>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>Middle Name</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <TextField
                                            name='middleName'
                                            value={screening.preHire?.middleName ?? ''}
                                            onChange={onTextInputChange}
                                        />
                                    ) : (
                                        screening.preHire?.middleName ?? ''
                                    )}
                                </div>
                            </div>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>Last Name</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <TextField
                                            name='lastName'
                                            value={screening.preHire?.lastName ?? ''}
                                            onChange={onTextInputChange}
                                        />
                                    ) : (
                                        screening.preHire?.lastName ?? ''
                                    )}
                                </div>
                            </div>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>Suffix</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <TextField
                                            name='suffix'
                                            value={screening.preHire?.suffix ?? ''}
                                            onChange={onTextInputChange}
                                        />
                                    ) : (
                                        screening.preHire?.suffix ?? ''
                                    )}
                                </div>
                            </div>
                        </div>
                        <Separator styles={separatorStyles} />
                        <div className={styles.dataGroup}>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>PCN</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <TextField
                                            name='pcn'
                                            value={screening.preHire?.pcn ?? ''}
                                            onChange={onTextInputChange}
                                        />
                                    ) : (
                                        screening.preHire?.pcn ?? ''
                                    )}
                                </div>
                            </div>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>Manager</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <TextField
                                            name='manager'
                                            value={screening.preHire?.manager ?? ''}
                                            onChange={onTextInputChange}
                                        />
                                    ) : (
                                        <EmployeeHoverCard personnelId={screening.preHire?.manager}>
                                            {props.managerName}
                                        </EmployeeHoverCard>
                                    )}
                                </div>
                            </div>
                            <div className={styles.dataLine}>
                                <div className={styles.key}>Title</div>
                                <div>
                                    {props.isDataEditable ? (
                                        <TextField
                                            name='jobTitle'
                                            value={screening.preHire?.jobTitle ?? ''}
                                            onChange={onTextInputChange}
                                        />
                                    ) : (
                                        screening.preHire?.jobTitle ?? ''
                                    )}
                                </div>
                            </div>

                            <div className={styles.dataLine}>
                                {props.isDataEditable ? (
                                    <>
                                        <div className={styles.key}>Expected Start Date</div>
                                        <DatePicker
                                            ariaLabel='Expected Start Date Input'
                                            value={
                                                screening.preHire?.startDate
                                                    ? new Date(screening.preHire?.startDate)
                                                    : new Date()
                                            }
                                            onSelectDate={onDateChange}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div className={styles.key}>Start Date</div>
                                        <div>
                                            {screening.preHire?.startDate
                                                ? new Date(
                                                      screening.preHire?.startDate,
                                                  ).toDateString()
                                                : ''}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </ContainerWithEtiquettes>
        );
    }
}

const separatorStyles = {
    root: {
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                opacity: 0.7,
            },
        },
    },
};

const styles = mergeStyleSets({
    prehireData: {
        display: 'grid',
        paddingBottom: 5,
        paddingTop: 5,
    },
    dataLine: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        selectors: {
            '&:not(:last-child):not(:first-child)': {
                marginTop: 7,
                marginBottom: 7,
            },
        },
    },
    key: {
        fontWeight: FontWeights.semibold,
    },
    dataGroup: {
        paddingTop: 2,
        paddingBottom: 2,
    },
});
