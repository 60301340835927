import React from 'react';
import {
    ChoiceGroup,
    IChoiceGroupOption,
    PrimaryButton,
    TextField,
    mergeStyleSets,
    mergeStyles,
} from '@fluentui/react';
import { ClipboardErrorFilled, CommentFilled, HeartFilled } from '@fluentui/react-icons';

export const FeedbackTypeKeys = {
    compliment: 'compliment',
    suggestion: 'suggestion',
    problem: 'problem',
} as const;

export type FeedbackDataType = {
    type?: keyof typeof FeedbackTypeKeys;
    message?: string;
    canContact?: boolean;
    email?: string;
    rating: number;
};

const placeholderText =
    'Please give as much detail as you can, but do not include any private or sensitive information.';

const contactOptions: IChoiceGroupOption[] = [
    { key: 'Yes', text: 'Yes' },
    { key: 'No', text: 'No' },
];

const ButtonText = (fType: keyof typeof FeedbackTypeKeys): string => {
    switch (fType) {
        case FeedbackTypeKeys.compliment:
            return 'Give a compliment';
        case FeedbackTypeKeys.suggestion:
            return 'Make a suggestion';
        case FeedbackTypeKeys.problem:
            return 'Report a problem';
        default:
            return '';
    }
};

const ButtonLabel = (fType: keyof typeof FeedbackTypeKeys): string => {
    switch (fType) {
        case FeedbackTypeKeys.compliment:
            return 'What did you like?';
        case FeedbackTypeKeys.suggestion:
            return 'How can we improve?';
        case FeedbackTypeKeys.problem:
            return 'What went wrong?';
        default:
            return '';
    }
};

// used in two places: GenericFeedbackForm and FeedbackActions
export const FeedbackButton = (
    feedbackType: keyof typeof FeedbackTypeKeys,
    onClick?: (feedbackType: keyof typeof FeedbackTypeKeys) => void,
): JSX.Element => {
    return (
        <PrimaryButton
            className={
                onClick
                    ? styles.feedbackActionStyle
                    : mergeStyles(styles.feedbackActionStyle, styles.nonClickableActionButton)
            }
            onClick={onClick ? (): void => onClick(feedbackType) : undefined}
            onRenderIcon={(): JSX.Element => {
                switch (feedbackType) {
                    case FeedbackTypeKeys.compliment:
                        return <HeartFilled className={styles.buttonIconStyle} />;
                    case FeedbackTypeKeys.suggestion:
                        return <CommentFilled className={styles.buttonIconStyle} />;
                    case FeedbackTypeKeys.problem:
                        return <ClipboardErrorFilled className={styles.buttonIconStyle} />;
                    default:
                        return <></>;
                }
            }}>
            {ButtonText(feedbackType)}
        </PrimaryButton>
    );
};

type GenericFormProps = {
    feedback: FeedbackDataType;
    setFeedback: (f: FeedbackDataType) => void;
};

export const GenericFeedbackForm = ({ feedback, setFeedback }: GenericFormProps): JSX.Element => {
    const radioStyle = {
        root: {
            marginTop: '15px',
        },
        flexContainer: {
            flexDirection: 'row',
            gap: '20px',
            display: 'flex',
        },
    };

    return (
        <>
            <div>{feedback.type && FeedbackButton(feedback.type)}</div>
            <TextField
                className={styles.feedbackCommonTextarea}
                required
                multiline
                rows={4}
                label={feedback.type ? ButtonLabel(feedback.type) : ''}
                placeholder={placeholderText}
                onChange={(ev, newValue): void => {
                    setFeedback({ ...feedback, message: newValue });
                }}
                value={feedback.message}
            />
            <ChoiceGroup
                id='contactChoice'
                styles={radioStyle}
                label='May we contact you about your feedback?'
                required
                options={contactOptions}
                selectedKey={feedback.canContact ? 'Yes' : 'No'}
                onChange={(ev, option): void => {
                    setFeedback({ ...feedback, canContact: option?.key === 'Yes' });
                }}
            />
            <div style={{ marginTop: '5px' }}>We will contact you at {feedback.email}</div>
        </>
    );
};

const styles = mergeStyleSets({
    feedbackCommonTextarea: {
        marginTop: '30px',
    },
    buttonIconStyle: {
        fontSize: '1.5em',
    },
    feedbackActionStyle: {
        height: '50px',
        paddingLeft: '92px',
        paddingRight: '92px',
        width: '100%',
    },
    nonClickableActionButton: {
        selectors: {
            ':hover': {
                cursor: 'default',
                backgroundColor: 'default',
            },
            ':active': {
                cursor: 'default',
                backgroundColor: 'default',
            },
        },
    },
});
