import {
    DefaultButton,
    Dialog,
    DialogFooter,
    MessageBar,
    MessageBarType,
    PrimaryButton,
} from '@fluentui/react';
import { CoreAttributeSetsClient } from 'clients/core/personnel-core-client-wrappers';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useState } from 'react';

export interface IAttributeSetActionModalProps {
    attributeSetId: string;
    attributeSetName: string;
    actionType: 'deactivate' | 'reactivate';
    onDismissed: () => void;
    onSuccess: () => void;
}

const DEFAULT_DEACTIVATE_MESSAGE =
    'Caution, deactivating this set will move it to the Deactivated Sets view.';
const DEFAULT_REACTIVATE_MESSAGE =
    'Caution, reactivating this set will move it to the Reactivated Sets view.';

export function AttributeSetActionModal(props: IAttributeSetActionModalProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const [isActionConfirmed, setIsActionConfirmed] = useState<boolean>(false);
    const [messageType, setMessageType] = useState<MessageBarType>(MessageBarType.warning);
    const [message, setMessage] = useState<string>(
        props.actionType === 'deactivate' ? DEFAULT_DEACTIVATE_MESSAGE : DEFAULT_REACTIVATE_MESSAGE,
    );

    const onClose = async (): Promise<void> => {
        props.onDismissed();
        setIsActionConfirmed(false);
        setMessageType(MessageBarType.warning);
        setMessage(
            props.actionType === 'deactivate'
                ? DEFAULT_DEACTIVATE_MESSAGE
                : DEFAULT_REACTIVATE_MESSAGE,
        );
    };

    const handleAttributeSetAction = async (): Promise<void> => {
        try {
            setIsActionConfirmed(true);
            setMessage(
                props.actionType === 'deactivate'
                    ? 'Deactivating attribute set...'
                    : 'Reactivating attribute set...',
            );
            const attributeSetClient = new CoreAttributeSetsClient(authContext);
            if (props.actionType === 'deactivate') {
                await attributeSetClient.deactivate(props.attributeSetId);
            } else {
                await attributeSetClient.reactivate(props.attributeSetId);
            }
            setMessageType(MessageBarType.success);
            setMessage(
                props.actionType === 'deactivate'
                    ? 'Attribute set has been successfully deactivated.'
                    : 'Attribute set has been successfully reactivated.',
            );
            props.onSuccess();
        } catch (e) {
            console.log(e);
            setMessageType(MessageBarType.error);
            setMessage(
                props.actionType === 'deactivate'
                    ? 'An error occurred deactivating the attribute set.'
                    : 'An error occurred reactivating the attribute set.',
            );
        }
    };

    return (
        <Dialog
            hidden={false}
            onDismiss={props.onDismissed}
            dialogContentProps={{
                onDismiss: onClose,
                title:
                    props.actionType === 'deactivate'
                        ? 'Deactivate Attribute Set'
                        : 'Reactivate Attribute Set',
                showCloseButton: true,
            }}
            modalProps={{
                styles: {
                    main: {
                        borderRadius: '8px',
                        width: '580px !important',
                        maxWidth: 'none !important',
                        minWidth: 'none !important',
                    },
                },
            }}>
            <MessageBar messageBarType={messageType}>{message}</MessageBar>
            <div
                style={{
                    paddingTop: 10,
                }}>
                {!isActionConfirmed && (
                    <div>
                        Are you sure you want to {props.actionType} the attribute set:
                        <b> {props.attributeSetName}</b>?
                    </div>
                )}
            </div>
            <DialogFooter>
                {!isActionConfirmed && (
                    <PrimaryButton
                        onClick={handleAttributeSetAction}
                        text={props.actionType === 'deactivate' ? 'Deactivate' : 'Reactivate'}
                    />
                )}

                <DefaultButton onClick={onClose} text='Close' />
            </DialogFooter>
        </Dialog>
    );
}
