import { Dictionary } from 'assets/constants/global-constants';
import {
    ISeatRecord,
    ISeatAvailabilityCalendarResponse,
    ITimeslotSeatStatus,
    ISeatsTimeslotStatusesForDate,
    SeatStatuses,
    AvailableSeatStatusArray,
} from 'clients/facilities-client';

export function countDateSlots(responses: ISeatAvailabilityCalendarResponse[]): number {
    let dateSlots = 0;
    for (const response of responses) {
        dateSlots += response.seatsTimeslotStatusesForDates.length;
    }
    return dateSlots;
}

export function timeslotsCount(responses: ISeatAvailabilityCalendarResponse[]): number {
    let timeslots = 0;
    for (const response of responses) {
        response.seatsTimeslotStatusesForDates.forEach((x) => {
            timeslots += x.dateTimeslots.timeslotItems.length;
        });
    }
    return timeslots;
}

export function seatCount(responses: ISeatAvailabilityCalendarResponse[]): number {
    let seatCount = 0;
    for (const response of responses) {
        response.seatsTimeslotStatusesForDates.forEach((x) => {
            seatCount += Object.keys(x.seatTimeslotsStatusesForDateDict).length;
        });
    }
    return seatCount;
}

export function definedSeats(responses: ISeatAvailabilityCalendarResponse[]): string[] {
    const seats = new Set<string>();
    for (const response of responses) {
        response.seatsTimeslotStatusesForDates.forEach((x) => {
            Object.keys(x.seatTimeslotsStatusesForDateDict).forEach((seatId) => {
                seats.add(seatId);
            });
        });
    }
    return [...seats];
}

export interface ISeatSlot {
    seatId: string;
    unitId?: string;
    utcStartTimeMilliseconds: number;
    utcEndTimeMilliseconds: number;
    status: string;
    timeslots: ITimeslotSeatStatus[];
}

export function seatSlotsForSeats(
    utcStartTimeMilliseconds: number,
    utcEndTimeMilliseconds: number,
    responses: ISeatAvailabilityCalendarResponse[],
    seats: ISeatRecord[],
): ISeatSlot[] {
    const seatSlots: Dictionary<ISeatSlot> = {};

    // initializing the seatSlots that are of interest.
    seats.forEach((seat) => {
        seatSlots[seat.id] = {
            seatId: seat.id,
            unitId: seat.unitId,
            utcStartTimeMilliseconds: utcStartTimeMilliseconds,
            utcEndTimeMilliseconds: utcEndTimeMilliseconds,
            status: 'Unknown',
            timeslots: [],
        };
    });

    for (const response of responses) {
        // Grouping the reponses timeslots into the needed seatSlots record
        for (const iseatTimeSlotStatusesForDate of response.seatsTimeslotStatusesForDates) {
            setSeatSlots(
                utcStartTimeMilliseconds,
                utcEndTimeMilliseconds,
                iseatTimeSlotStatusesForDate,
                seatSlots,
            );
        }
    }

    // Determine seat availability over specified time period. Result is taken as follows:
    // If all timeslot statuses are available (Available or AvailableForICM), take the last.
    // Otherwise take the first non-available status.
    for (const seatSlot of Object.values(seatSlots)) {
        let status = SeatStatuses.Available.value;
        for (
            let indx = 0;
            indx < seatSlot.timeslots.length &&
            AvailableSeatStatusArray.filter((x) => x.value === status).length > 0;
            indx++
        ) {
            status = seatSlot.timeslots[indx].seatStatus.status;
        }
        seatSlot.status = status;
    }

    return Object.values(seatSlots);
}

function setSeatSlots(
    utcStartTimeMilliseconds: number,
    utcEndTimeMilliseconds: number,
    iseatTimeslotStatusesForDate: ISeatsTimeslotStatusesForDate,
    seatSlots: Dictionary<ISeatSlot>,
): void {
    for (const seatInfo of Object.values(
        iseatTimeslotStatusesForDate.seatTimeslotsStatusesForDateDict,
    )) {
        for (const slot of seatInfo.filter(
            (x) =>
                x.timeslotItem.startDateTimeUTCMilliseconds >= utcStartTimeMilliseconds &&
                x.timeslotItem.endDateTimeUTCMilliseconds <= utcEndTimeMilliseconds,
        )) {
            if (seatSlots[slot.seatStatus.seatId]) {
                seatSlots[slot.seatStatus.seatId].timeslots.push(slot);
            }
        }
    }
}

export function snapToTimeBoundary(
    currentDateTime: Date | undefined,
    minutes: number,
): Date | undefined {
    if (currentDateTime) {
        const newDate = new Date(currentDateTime);
        newDate.setMinutes(Math.round(newDate.getMinutes() / minutes) * minutes, 0, 0);
        return newDate;
    }
    return undefined;
}
