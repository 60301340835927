import React from 'react';
import * as PapaParse from 'papaparse';

export interface IFileInfo {
    name: string;
    type: string;
}

type UploadCsvButtonProps = {
    label: string;
    onFileLoaded: (data: any[], fileInfo: IFileInfo) => any;
    onError: (error: Error) => void;
};
export default function UploadCsvButton(props: UploadCsvButtonProps): JSX.Element {
    const { label, onFileLoaded, onError } = props;

    const acceptedFileTypes = '.csv, text/csv';
    const parserOptions = {} as PapaParse.ParseConfig;
    const fileEncoding = 'UTF-8';
    const id = 'csv-button-id';

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const reader: FileReader = new FileReader();
        const files: FileList = e.target.files!;
        if (!files.length) {
            return;
        }
        const fileInfo: IFileInfo = {
            name: files[0].name,
            type: files[0].type,
        };

        if (acceptedFileTypes.indexOf(fileInfo.type) < 0) {
            onError(
                new Error(
                    `File type, '${fileInfo.type}', is not in accepted filetypes '${acceptedFileTypes}'`,
                ),
            );
            return;
        }

        reader.onload = (event: Event): void => {
            const csvData = PapaParse.parse(
                reader.result as string,
                Object.assign(parserOptions, {
                    error: onError,
                    encoding: fileEncoding,
                }),
            );
            onFileLoaded(csvData?.data ?? [], fileInfo);
        };

        reader.readAsText(files[0], fileEncoding);
    };
    return (
        <>
            <label htmlFor={id}>{label}</label>
            <input type='file' id={id} accept={acceptedFileTypes} onChange={onFileChange} />
        </>
    );
}
