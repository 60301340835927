import { IconNames } from 'assets/constants/global-constants';
import FacilitiesClient, { ILogBookRecord } from 'clients/facilities-client';
import ModalActionButton from 'components/common/buttons/modal-action-button';
import { useEmployeePicker } from 'components/common/use-input/use-employee-picker';
import { AuthContext } from 'contexts/auth-context';
import { UserContext } from 'contexts/user-context';
import React, { useContext } from 'react';
import { doNothing } from 'utils/misc-utils';

export interface AddLogBookEntryProps {
    facilityId: string | undefined;
    updateLogBookOnAdd: (logBookRecord: ILogBookRecord) => void;
}
export default function AddLogBookEntry(props: AddLogBookEntryProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    const createLogBookRecord = async (): Promise<void> => {
        try {
            if (props.facilityId) {
                const newLogBookRecord = await FacilitiesClient.createLogBookRecord(
                    authContext,
                    userContext,
                    props.facilityId,
                    selectedEmployee?.id,
                );

                props.updateLogBookOnAdd(newLogBookRecord);

                initalizeEmployee(undefined);
            }
        } catch (e) {
            throw 'An error occurred creating the log book record. NOTE: Duplicate records are not allowed.';
        }
    };

    const {
        value: selectedEmployee,
        theElement: employeeElement,
        initialize: initalizeEmployee,
    } = useEmployeePicker({
        label: 'Employee',
        selectedPersona: undefined,
    });

    return (
        <>
            <ModalActionButton<ILogBookRecord>
                text={'Create Log Book Entry'}
                iconName={IconNames.Add}
                modalTitle={'Create Log Book Entry'}
                enableSubmit={selectedEmployee !== undefined}
                onSubmit={createLogBookRecord}
                onModalConcluded={doNothing}>
                {employeeElement()}
            </ModalActionButton>
        </>
    );
}
