import { SharedColors } from '@fluentui/theme';
import { ActionButton, IColumn } from '@fluentui/react';
import React, { MutableRefObject, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IEmployeeWithEditableData } from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import { dateToLocalDate, getDateTimeForFilename } from 'utils/time-utils';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import { Table, TableCell } from 'components/common/table';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import { IPagedResults } from 'clients/http-options';
import { contentMaxHeight } from 'assets/styles/list-styles';
import EllipsisText from 'components/common/ellipsis-text';
import { CacheContext } from 'contexts/cache-context';
import { commonPersonnelPersonnelStyles } from 'components/personnel-profile/common-personnel-profile-styles';
import { ContractType, IContract } from 'components/screening/us-gov/IContract';
import { ISuitabilityRecord, SuitabilityClient } from 'clients/suitability-client';
import {
    getAgencyEnumValueFromKey,
    isSuitabilityActive,
    suitabilityStatusSpan,
} from 'components/personnel-profile/suitability/profile-suitability-utils';
import SuitabilityDetails from 'components/personnel-profile/suitability/suitability-details';
import {
    firstColumnTableCellPadding,
    firstColumnTitlePadding,
} from 'components/personnel-profile/common/common-constants';
import {
    SuitabilityAgencies,
    SuitabilityLevels,
} from 'components/personnel-profile/suitability/profile-suitability-types';
import { initiateBrowserDownload } from 'utils/file-utils';
import { replaceTemplatedTimeStringInDownloadReport } from 'utils/reporting-utils';
import { getDefaultColumnWidths } from 'utils/table-utils';

export interface PersonnelProfileSuitabilityTableProps {
    employee: IEmployeeWithEditableData | undefined;
    suitabilities: ISuitabilityRecord[];
    setSuitabilities: (records: ISuitabilityRecord[]) => void;
    setUpdatedSuitability: (record?: ISuitabilityRecord) => void;
    deleteRecordFunction: (id: string) => Promise<boolean>;
    cacheKey?: string;
}

export default function PersonnelProfileSuitabilityTable(
    props: PersonnelProfileSuitabilityTableProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [contracts, setContracts] = useState<IContract[]>([]);
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
    const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
    const hasSuitability = useMemo(() => props.suitabilities.length > 0, [props.suitabilities]);
    const downloadLink: MutableRefObject<HTMLAnchorElement | null> = useRef(null);
    const cacheContext = useContext(CacheContext);

    useEffect(() => {
        let isMounted = true;
        async function getContracts(): Promise<void> {
            setIsFetchingData(true);

            const contractArray: IContract[] = [];
            let continuationToken: string | undefined = '';
            try {
                do {
                    const response: IPagedResults<IContract> = await UsGovScreeningClient.getContracts(
                        authContext,
                        Object.keys(ContractType), // Get all contract types.
                        continuationToken,
                    );
                    contractArray.push(...response.results);
                    continuationToken = response.continuationToken;
                } while (isMounted && continuationToken);

                if (isMounted) {
                    setContracts(contractArray);
                }
            } catch (e) {
                console.error(
                    'error with getting suitability data from employee ',
                    props.employee?.data.id,
                    ' --- ',
                    e,
                );
            } finally {
                setIsFetchingData(false);
            }
        }

        getContracts();
        return (): void => {
            isMounted = false;
        };
    }, [authContext]);

    function createRightEtiquette(
        etiquetteSuitabilities: ISuitabilityRecord[],
    ): { label: string; backgroundColor: string; maxLength: number } | undefined {
        const highestActiveAgencyLevelMap = new Map<string, string>();

        for (let i = 0; i < etiquetteSuitabilities.length; i++) {
            const suitability = props.suitabilities[i];
            const agency =
                SuitabilityAgencies[
                    suitability.requestingAgency.replace(
                        /_/g,
                        '',
                    ) as keyof typeof SuitabilityAgencies
                ];
            if (isSuitabilityActive(suitability) && agency) {
                if (!highestActiveAgencyLevelMap.has(agency)) {
                    highestActiveAgencyLevelMap.set(agency, suitability.suitabilityLevel);
                } else {
                    if (
                        // Is the newLevel higher then the currentLevel for this agency?
                        Object.keys(SuitabilityLevels).indexOf(
                            highestActiveAgencyLevelMap.get(agency) as string,
                        ) > Object.keys(SuitabilityLevels).indexOf(suitability.suitabilityLevel)
                    ) {
                        highestActiveAgencyLevelMap.set(agency, suitability.suitabilityLevel);
                    }
                }
            }
        }

        const agencyLevelStrings: string[] = [];
        highestActiveAgencyLevelMap.forEach((level, agency) => {
            agencyLevelStrings.push(
                `${
                    SuitabilityLevels[level.replace(/\s/g, '') as keyof typeof SuitabilityLevels]
                }(${agency.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/_/g, ' ')})`,
            );
        });

        if (agencyLevelStrings.length > 0) {
            return {
                label: `ACTIVE SUITABILITIES: ${agencyLevelStrings.join(' & ')}`,
                backgroundColor: SharedColors.cyanBlue10,
                maxLength: 102,
            };
        }
        return undefined;
    }

    function updateCachedSuitabilityData(updatedSuitabilityRecord: ISuitabilityRecord): void {
        if (props.cacheKey && updatedSuitabilityRecord) {
            const cachedScreeningArray = cacheContext.retrieve<ISuitabilityRecord[]>(
                props.cacheKey,
            );
            if (cachedScreeningArray) {
                const updatedCachedScreeningRecords = cachedScreeningArray.map(
                    (suitabilityRecord) =>
                        suitabilityRecord.id === updatedSuitabilityRecord.id
                            ? updatedSuitabilityRecord
                            : suitabilityRecord,
                );
                cacheContext.store<ISuitabilityRecord[]>(
                    props.cacheKey,
                    updatedCachedScreeningRecords,
                );
            }
        }
    }

    async function upsertSuitabilityRecord(record: ISuitabilityRecord): Promise<boolean> {
        try {
            const result = await SuitabilityClient.upsertSuitability(authContext, record);
            const uniqueIds = new Set(result.map((y) => y.id));
            const newSuitabilities = props.suitabilities.filter((x) => !uniqueIds.has(x.id));
            newSuitabilities.push(...result);
            props.setSuitabilities(newSuitabilities);
            updateCachedSuitabilityData(record);
            // Reflect the suitability changes
            if (result.length > 0) {
                props.setUpdatedSuitability(result[0]);
            }
            return true;
        } catch (e) {
            console.log('error on upsert', e);
            return false;
        }
    }

    async function downloadSuitabilityReport(employeeId: string): Promise<void> {
        if (!isDownloadingReport) {
            try {
                setIsDownloadingReport(true);
                const reportData: string = await SuitabilityClient.getSuitabilityReportForEmployee(
                    authContext,
                    employeeId,
                );
                if (reportData && downloadLink?.current) {
                    const csv = replaceTemplatedTimeStringInDownloadReport(reportData);
                    const report = new Blob([csv], { type: 'text/csv' });
                    const fileName = `${
                        'Suitability_Report_For_' + employeeId
                    }_${getDateTimeForFilename()}`;
                    initiateBrowserDownload(report, fileName, 'csv', downloadLink.current);
                }
                setIsDownloadingReport(false);
            } catch (e) {
                console.error('error downloading suitability report', e);
            }
        }
    }

    return (
        <>
            {props.employee && (
                <ContainerWithEtiquettes
                    leftEtiquetteLabel='Suitability records'
                    rightEtiquette={createRightEtiquette(props.suitabilities)}
                    bottomInfo={
                        <>
                            {hasSuitability && (
                                <ActionButton
                                    styles={{ root: { maxHeight: contentMaxHeight } }}
                                    title='Download Suitability Report'
                                    iconProps={{ iconName: 'DownloadDocument' }}
                                    onClick={(): Promise<void> =>
                                        downloadSuitabilityReport(props!.employee!.data?.id)
                                    }>
                                    <a ref={downloadLink} hidden={true} />
                                    Download report
                                </ActionButton>
                            )}
                            <SuitabilityDetails
                                buttonIcon='AddToShoppingList'
                                buttonText='Add'
                                modalTitle='Add Suitability'
                                employee={props.employee}
                                upsertRecordFunction={upsertSuitabilityRecord}
                                contracts={contracts}
                            />
                        </>
                    }>
                    {props.suitabilities.length === 0 && !isFetchingData ? (
                        <p className={commonPersonnelPersonnelStyles.noDataParagraphMorePadding}>
                            No suitability records to display
                        </p>
                    ) : (
                        <Table<ISuitabilityRecord>
                            rows={props.suitabilities}
                            isFetchingData={isFetchingData}
                            shimmerLines={10}
                            tableColumns={generateColumns(
                                props.employee,
                                upsertSuitabilityRecord,
                                props.deleteRecordFunction,
                                contracts,
                            )}
                            tableName='Suitability Records'
                        />
                    )}
                </ContainerWithEtiquettes>
            )}
        </>
    );
}

function generateColumns(
    employee: IEmployeeWithEditableData,
    upsertSuitabilityRecord: { (record: ISuitabilityRecord): Promise<boolean> },
    deleteRecordFunction: (id: string) => Promise<boolean>,
    contracts: IContract[],
): IColumn[] {
    const columnWidths = getDefaultColumnWidths(7);

    return [
        {
            key: 'suitability_level',
            name: 'Level',
            ariaLabel: 'Level',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            styles: { cellTitle: { paddingLeft: firstColumnTitlePadding } }, // DetailsList applies this to the header cell.
            onRender: (row: ISuitabilityRecord): JSX.Element => (
                <TableCell style={{ paddingLeft: firstColumnTableCellPadding }}>
                    <EllipsisText
                        text={
                            SuitabilityLevels[
                                row.suitabilityLevel.replace(
                                    /\s/g,
                                    '',
                                ) as keyof typeof SuitabilityLevels
                            ]
                        }
                        textLengthBeforeEllipsis={12}
                    />
                </TableCell>
            ),
        },
        {
            key: 'suitability_status',
            name: 'Status',
            ariaLabel: 'Status',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true, // Style is taken from get-candidates-table-columns.tsx#getStatusLabel()
            onRender: (row: ISuitabilityRecord): JSX.Element => (
                <TableCell>{suitabilityStatusSpan(row, 8)}</TableCell>
            ),
        },
        {
            key: 'suitability_agency',
            name: 'Agency',
            ariaLabel: 'Agency',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ISuitabilityRecord): JSX.Element => (
                <TableCell>
                    <EllipsisText
                        text={getAgencyEnumValueFromKey(row.requestingAgency, true)}
                        textLengthBeforeEllipsis={6}
                    />
                </TableCell>
            ),
        },
        {
            key: 'suitability_grantedOn',
            name: 'Granted on',
            ariaLabel: 'Granted On',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ISuitabilityRecord): JSX.Element => (
                <TableCell>{dateToLocalDate(row.grantDateUTCMilliseconds)}</TableCell>
            ),
        },
        {
            key: 'suitability_briefedOn',
            name: 'Briefed on',
            ariaLabel: 'Briefed On',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ISuitabilityRecord): JSX.Element => (
                <TableCell>{dateToLocalDate(row.briefDateUTCMilliseconds)}</TableCell>
            ),
        },
        {
            key: 'suitability_contract',
            name: 'Contract',
            ariaLabel: 'Contract',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ISuitabilityRecord): JSX.Element => (
                <TableCell>
                    <EllipsisText text={row.contractId} textLengthBeforeEllipsis={20} />
                </TableCell>
            ),
        },
        {
            key: 'suitability_action',
            name: 'Action',
            ariaLabel: 'Action',
            minWidth: columnWidths.actionColumnWidth,
            maxWidth: columnWidths.actionColumnWidth,
            isRowHeader: true,
            onRender: (row: ISuitabilityRecord): JSX.Element => (
                <TableCell>
                    <SuitabilityDetails
                        buttonIcon='EntryView'
                        suitability={row}
                        buttonText='View'
                        modalTitle={`Suitability: ${
                            getAgencyEnumValueFromKey(row.requestingAgency, true) ??
                            row.requestingAgency
                        } ${
                            SuitabilityLevels[
                                row.suitabilityLevel.replace(
                                    /\s/g,
                                    '',
                                ) as keyof typeof SuitabilityLevels
                            ] ?? row.suitabilityLevel
                        }`}
                        employee={employee}
                        upsertRecordFunction={upsertSuitabilityRecord}
                        deleteRecordFunction={deleteRecordFunction}
                        contracts={contracts}
                    />
                </TableCell>
            ),
        },
    ];
}
