import React, { ReactNode, useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth-context';
import { ManageGroupContext } from 'components/groups/manage-group/manage-group-context';
import ModalActionButton, { ModalConclusion } from 'components/common/buttons/modal-action-button';
import { IconNames, Dictionary } from 'assets/constants/global-constants';
import GroupClient, { IGroupSecurityGroup, SecurityGroupRequestType } from 'clients/group-client';
import GraphClient from 'clients/graph-client';
import BoldFont from 'components/common/misc/bold-font';
import LargeFont from 'components/common/misc/large-font';
import config from 'environments/environment';
import { useIsMounted } from 'utils/misc-hooks';
import { doNothing, doNothingAsync } from 'utils/misc-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

enum DialogBoxSteps {
    PerformUnlink,
    UnlinkDone,
}

const firstStep = DialogBoxSteps.PerformUnlink;

interface IModalActionButtonSubset {
    modalTitle: string;
    submitButtonText: string;
    submitButtonIcon?: string;
    keepOpenAfterSubmit: boolean;
    onSubmit: () => Promise<void>;
    onModalConcluded: (conclusion: ModalConclusion) => void;
    children: ReactNode;
}

interface UnlinkSecurityGroupModalActionButtonProps {
    securityGroup: IGroupSecurityGroup;
}

export default function UnlinkSecurityGroupModalActionButton(
    props: UnlinkSecurityGroupModalActionButtonProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const groupContext = useContext(ManageGroupContext);

    const [currentStep, setCurrentStep] = useState<DialogBoxSteps>(firstStep);

    const isServicePrincpalLinkingEnabled = useFeatureFlag(
        FeatureFlagKeys.groupsLinkWithServicePrincipal,
    ).enabled;

    const isMounted = useIsMounted();

    const initVariables = (): void => {
        setCurrentStep(firstStep);
    };

    const onUnlink = async (): Promise<void> => {
        if (!groupContext.group || !props.securityGroup) {
            return;
        }
        try {
            try {
                let ownerOid = undefined;
                if (isServicePrincpalLinkingEnabled) {
                    const groupsServicePrincipal = await GraphClient.getServicePrincipal(
                        authContext,
                        config.groupServiceConfig.serviceIdentityAppId,
                    );
                    ownerOid = groupsServicePrincipal?.id;
                }

                const isOwnerExists = await GraphClient.checkOwnerExists(
                    authContext,
                    props.securityGroup.id,
                    ownerOid,
                );
                if (isOwnerExists) {
                    await GraphClient.removeOwner(authContext, props.securityGroup.id, ownerOid);
                }
            } catch (e) {
                console.error('Error removing owner', e);
            }
            await GroupClient.unlinkSecurityGroup(
                authContext,
                groupContext.group.id,
                props.securityGroup.id,
            );
            if (isMounted()) {
                setCurrentStep(DialogBoxSteps.UnlinkDone);
            }
        } catch {
            throw 'Error unlinking group';
        }
    };

    const onModalConcluded = (conclusion: ModalConclusion): void => {
        if (conclusion === ModalConclusion.Done) {
            groupContext.setLinkedSecurityGroups((currentValue) =>
                currentValue.filter((group) => group.id !== props.securityGroup.id),
            );
        }
    };

    const currentStepProps: Dictionary<() => IModalActionButtonSubset> = {};

    // Ask the user if they really want to unlink the group
    currentStepProps[DialogBoxSteps.PerformUnlink] = (): IModalActionButtonSubset => ({
        modalTitle: 'Unlink Group',
        submitButtonText: 'Unlink',
        onSubmit: onUnlink,
        keepOpenAfterSubmit: true,
        onModalConcluded: doNothing,
        children: (
            <div>
                <div>
                    <span>Unlinking security group </span>
                    <BoldFont>{props.securityGroup.name}</BoldFont>
                    <span> from Personnel Group </span>
                    <BoldFont>{groupContext.group?.name}</BoldFont>
                </div>
                <ul>
                    <li>
                        <span>Manually remove </span>
                        <BoldFont>
                            {getSyncObject(
                                (props.securityGroup
                                    ?.securityGroupType as unknown) as keyof typeof SecurityGroupRequestType,
                            )}
                        </BoldFont>
                        <span> from security group owners.</span>
                    </li>
                    <li>Members currently in the group will be left intact.</li>
                    <li>Sync will stop.</li>
                </ul>
            </div>
        ),
    });

    // Unlink successfully finished
    currentStepProps[DialogBoxSteps.UnlinkDone] = (): IModalActionButtonSubset => ({
        modalTitle: 'Group Unlinked',
        submitButtonText: 'Close',
        onSubmit: doNothingAsync,
        keepOpenAfterSubmit: false,
        onModalConcluded: onModalConcluded,
        children: (
            <div>
                <p>
                    <LargeFont>Done!</LargeFont>
                </p>
                <p>
                    <BoldFont>{props.securityGroup.name}</BoldFont>
                    <span> has been unlinked from the Personnel Group </span>
                    <BoldFont>{groupContext.group?.name}</BoldFont>
                    <span>.</span>
                </p>
            </div>
        ),
    });

    return (
        <ModalActionButton<void>
            {...currentStepProps[currentStep]()}
            text='Unlink'
            enable={true}
            iconName={IconNames.RemoveLinkChain}
            enableSubmit={!!props.securityGroup}
            onButtonClick={initVariables}></ModalActionButton>
    );
}

function getSyncObject(securityGroupType: keyof typeof SecurityGroupRequestType): string {
    const formattedEnumValue = SecurityGroupRequestType[securityGroupType];
    switch (formattedEnumValue) {
        case SecurityGroupRequestType.AZUREACTIVEDIRECTORY:
        case SecurityGroupRequestType.OFFICE:
            return config.emailAddress.groupSync;
        case SecurityGroupRequestType.ACTIVEDIRECTORY:
            return config.groupServiceConfig.coreIdentityManagementAppId;
        case SecurityGroupRequestType.MICROSOFTFEDERAL:
            return config.emailAddress.fednetSync;
        default:
            console.error(`Group type "${securityGroupType}" not recognized`);
            return '';
    }
}
